<!-- VIEW MODE -->
<div id="drawer-container" class="d-flex flex-column">
  <div class="ease" *ngIf="section && !isEditing">
    <section class="d-flex align-items-center mb-1">
      <h4 class="dkblue mb-0 mr-auto text-truncate mr-2">
        {{ section.name }}
        <small class="gray">Option 1</small>
      </h4>
      <button mat-button color="accent" class="ml-3" (click)="edit(section)">
        {{ canEdit ? 'Edit' : 'View' }} Section
      </button>
      <button
        mat-icon-button
        color="warn"
        class="ml-3"
        (click)="delete(section)"
        *ngIf="canEdit"
        matTooltip="Delete Section"
        matTooltipPosition="below"
      >
        <mat-icon>delete_outline</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        type="button"
        (click)="close()"
        class="gray"
        matTooltip="Close Panel"
        matTooltipPosition="below"
      >
        <mat-icon>close</mat-icon>
      </button>
    </section>
    <section class="d-flex align-items-center mb-5">
      <p
        class="border border-gray bg-shade-gray px-2 br-10 lh-20 dkblue mr-2 mb-0"
        *ngFor="let fs of section.funding_sources"
      >
        ${{ fs.abbreviation }}
      </p>
    </section>
    <section class="d-flex align-items-center mb-2 br-10 border border-gray p-3">
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">{{ section.name }}</h6>
        <p class="dkgray mb-0">{{ section.representative_first_name }} {{ section.representative_last_name }}</p>
      </div>
      <button
        mat-flat-button
        color="accent"
        type="button"
        *ngIf="section.tenantReviewCanStart && section.needs_tenant_approval"
        (click)="submitForReview()"
      >
        Submit For Review
      </button>
      <button
        mat-flat-button
        color="accent"
        type="button"
        *ngIf="!section.tenantReviewCanStart && section.tenantReviewHasStarted"
        (click)="openReviewTask()"
      >
        Tenant Review
      </button>
    </section>
    <section class="d-flex flex-column border-bottom border-ltgray py-3" [formGroup]="sectionFormGroup">
      <div class="d-flex align-items-top py-1 row">
        <ng-container *ngIf="!editingRentalRate">
          <p class="primary mb-0 mr-auto text-truncate col-12">
            <span class="mr-4">Rental Rate</span>
            <span class="float-right"
              >{{ section.rental_rate | USDollar }} / {{ section.yearlyRentalRate | USDollar }}/yr</span
            >
            <span class="ltblue pointer float-right mr-4 hover-u" (click)="editingRentalRate = true">Edit</span>
          </p>
        </ng-container>
        <ng-container *ngIf="editingRentalRate">
          <p class="primary mb-0 mr-auto text-truncate col-9" style="line-height: 40px">
            <span class="mr-4">Rental Rate</span>
            <span class="ltblue pointer float-right hover-u" (click)="saveRentalRate()">Save</span>
            <span class="gray pointer mr-4 float-right hover-u" (click)="discardRentalRate()">Cancel</span>
          </p>
          <mat-form-field class="full-width col-3 pl-0 text-right" appearance="outline">
            <mat-icon matPrefix>attach_money</mat-icon>
            <input matInput placeholder="0.00" type="number" formControlName="rental_rate" />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="d-flex align-items-top py-1 row">
        <ng-container *ngIf="!editingCirculationFactor">
          <p class="primary mb-0 mr-auto text-truncate col-12">
            <span class="mr-4">Circulation Factor</span>
            <span class="float-right">{{ 0.01 * section.circulation_factor | percent }}</span>
            <span class="ltblue pointer float-right mr-4 hover-u" (click)="editingCirculationFactor = true">Edit</span>
          </p>
        </ng-container>
        <ng-container *ngIf="editingCirculationFactor">
          <p class="primary mb-0 mr-auto text-truncate col-9" style="line-height: 40px">
            <span class="mr-4">Circulation Factor</span>
            <span class="ltblue pointer float-right hover-u" (click)="saveCirculationFactor()">Save</span>
            <span class="gray pointer mr-4 float-right hover-u" (click)="discardCirculationFactor()">Cancel</span>
          </p>
          <mat-form-field class="full-width col-3 pl-0 text-right" appearance="outline">
            <mat-icon matPrefix>percent</mat-icon>
            <input matInput placeholder="0" type="number" formControlName="circulation_factor" />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="d-flex align-items-top py-1">
        <p class="primary mb-0 mr-auto text-truncate">Occupied SF</p>
        <p class="dkgray mb-0 ml-4">{{ section.occupied_sqft }} sqft</p>
      </div>
      <div class="d-flex align-items-top py-1">
        <p class="primary mb-0 mr-auto text-truncate">
          Occupied SF w/ Circ ({{ 0.01 * section.circulation_factor | percent }})
        </p>
        <p class="dkgray mb-0 ml-4">{{ numberWithCommas(section?.osf_w_circ ?? 0) }} sqft</p>
      </div>
    </section>
    <section class="d-flex flex-column border-bottom border-ltgray py-3">
      <div class="d-flex align-items-top py-1" *ngFor="let fs of section.funding_sources">
        <p class="primary mb-0 mr-auto text-truncate">{{ fs.name }}</p>
        <p class="dkgray mb-0 ml-4">{{ fs.subtotal | USDollar }}</p>
      </div>
    </section>
    <section class="d-flex flex-column border-bottom border-ltgray py-3 mb-4">
      <div class="d-flex align-items-top py-1">
        <p class="primary font-weight-semibold mb-0 mr-auto text-truncate">Section Total</p>
        <p class="primary font-weight-semibold mb-0 ml-4">{{ section.subtotal | USDollar }}</p>
      </div>
    </section>
    <section class="d-flex border-bottom border-ltgray align-items-center py-3">
      <div class="d-flex flex-column mr-auto">
        <h5 class="primary mb-1">Required Documents</h5>
        <p class="dkgray mb-0">The documents listed below are required based on this section's funding sources.</p>
      </div>
    </section>
    <section class="d-flex border-bottom border-ltgray align-items-center py-3">
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Cover Letter</h6>
        <p class="dkgray mb-0">Required.</p>
      </div>
      <button
        *ngIf="!section.peb_cover_letter_text"
        mat-flat-button
        color="primary"
        type="button"
        (click)="openCoverLetter()"
      >
        <span class="pulse-dot-success"></span>
        Create
      </button>
      <p *ngIf="section.peb_cover_letter_text" class="dkgray mb-0 mr-4">
        <i class="fas fa-check-circle green mr-1 me-1"> </i>
        Created
      </p>
      <button *ngIf="section.peb_cover_letter_text" mat-button color="accent" type="button" (click)="openCoverLetter()">
        Edit
      </button>
    </section>
    <section class="d-flex border-bottom border-ltgray py-3">
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Bubble Drawing</h6>
        <p class="dkgray mb-0">Required.</p>
      </div>
      <app-split-button
        (clickEvent)="uploadBubbleDrawing()"
        class="ml-2"
        lineHeight="24"
        [matMenu]="bubbleDrawingMenu"
        [disabled]="!canEdit"
      >
        Upload
      </app-split-button>
      <mat-menu #bubbleDrawingMenu="matMenu" xPosition="before">
        <button mat-menu-item *ngIf="!section?.bubble_drawing_task_id" (click)="createTask(PebFileType.BubbleDrawings)">
          <mat-icon>note_add</mat-icon>
          <span>Request Bubble Drawing </span>
        </button>
        <button
          mat-menu-item
          *ngIf="section?.bubble_drawing_task_id"
          (click)="viewTask(section.bubble_drawing_task_id)"
        >
          <mat-icon>note_add</mat-icon>
          <span>View Task </span>
        </button>
      </mat-menu>
      <!-- <button mat-button class="ml-auto" color="accent" type="button" (click)="uploadBubbleDrawing()">Upload</button> -->
      <mat-chip-list *ngIf="section?.bubble_drawing_ids?.length > 0" class="ml-3">
        <ng-container *ngFor="let file of section?.bubble_drawing_ids; let last = last">
          <app-file-chip
            [file]="{ id: file }"
            [removable]="canEdit"
            (removeEvent)="removeBubbleDrawing(file)"
            [ngClass]="{ 'mb-1': section?.bubble_drawing_ids?.length > 1 && !last }"
          ></app-file-chip>
        </ng-container>
      </mat-chip-list>
    </section>
    <section
      class="d-flex border-bottom border-ltgray py-3"
      *ngIf="section?.approval_process?.isReimbursement || section?.reimbursement_file_id"
    >
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Reimbursement Agreement</h6>
        <p class="dkgray mb-0">Required if REIM is a funding source.</p>
      </div>
      <app-split-button
        *ngIf="!section?.reimbursement_file_id"
        (clickEvent)="uploadReimbursementAgreement()"
        class="ml-2"
        lineHeight="24"
        [matMenu]="reimbursementMenu"
        [disabled]="!canEdit"
      >
        Upload
      </app-split-button>
      <mat-menu #reimbursementMenu="matMenu" xPosition="before">
        <button *ngIf="!section?.reimbursement_task_id" mat-menu-item (click)="createTask(PebFileType.Reimbursement)">
          <mat-icon>note_add</mat-icon>
          <span>Request Reimbursement Agreement</span>
        </button>
        <button mat-menu-item *ngIf="section?.reimbursement_task_id" (click)="viewTask(section?.reimbursement_task_id)">
          <mat-icon>note_add</mat-icon>
          <span>View Task </span>
        </button>
      </mat-menu>
      <mat-chip-list *ngIf="section.reimbursement_file_id" class="ml-3">
        <app-file-chip
          [file]="{
            name: section.reimbursement_file_name,
            id: section.reimbursement_file_id
          }"
          [removable]="canEdit"
          (removeEvent)="removeReimbursement()"
        ></app-file-chip>
      </mat-chip-list>
    </section>
    <section
      class="d-flex border-bottom border-ltgray py-3"
      *ngIf="section.approval_process?.isSublease || section.sublease_contract_file_id"
    >
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Sublease Agreement</h6>
        <p class="dkgray mb-0">Required if Sublease is a funding source.</p>
      </div>
      <app-split-button
        *ngIf="!section.sublease_contract_file_id"
        [disabled]="!canEdit"
        (clickEvent)="uploadSubleaseAgreement()"
        class="ml-2"
        lineHeight="24"
        [matMenu]="subleaseMenu"
      >
        Upload
      </app-split-button>
      <mat-menu #subleaseMenu="matMenu" xPosition="before">
        <button
          mat-menu-item
          *ngIf="!section?.sublease_contract_task_id"
          (click)="createTask(PebFileType.SubleaseContract)"
        >
          <mat-icon>note_add</mat-icon>
          <span>Request Sublease Agreement </span>
        </button>
        <button
          mat-menu-item
          *ngIf="section?.sublease_contract_task_id"
          (click)="viewTask(section.sublease_contract_task_id)"
        >
          <mat-icon>note_add</mat-icon>
          <span>View Task </span>
        </button>
      </mat-menu>
      <mat-chip-list *ngIf="section.sublease_contract_file_id" class="ml-3">
        <app-file-chip
          [file]="{
            name: section.sublease_contract_file_name,
            id: section.sublease_contract_file_id
          }"
          [removable]="canEdit"
          (removeEvent)="removeSubleaseContract()"
        ></app-file-chip>
      </mat-chip-list>
    </section>
    <section
      class="d-flex border-bottom border-ltgray py-3"
      *ngIf="section.approval_process?.isSublease || section?.exhibit_b_file_id"
    >
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Exhibit B</h6>
        <p class="dkgray mb-0">The floorplan associated with the lease.</p>
      </div>
      <app-split-button
        *ngIf="!section?.exhibit_b_file_id"
        [disabled]="!canEdit"
        (clickEvent)="uploadExhibitB()"
        class="ml-2"
        lineHeight="24"
        [matMenu]="exhibitBMenu"
      >
        Upload
      </app-split-button>
      <mat-menu #exhibitBMenu="matMenu" xPosition="before">
        <button mat-menu-item *ngIf="!section?.exhibit_b_task_id" (click)="createTask(PebFileType.ExhibitB)">
          <mat-icon>note_add</mat-icon>
          <span>Request Exhibit B </span>
        </button>
        <button mat-menu-item *ngIf="section?.exhibit_b_task_id" (click)="viewTask(section?.exhibit_b_task_id)">
          <mat-icon>note_add</mat-icon>
          <span>View Task </span>
        </button>
      </mat-menu>
      <mat-chip-list *ngIf="section?.exhibit_b_file_id" class="ml-3">
        <app-file-chip
          [file]="{
            name: section?.exhibit_b_file_name,
            id: section?.exhibit_b_file_id
          }"
          [removable]="canEdit"
          (removeEvent)="removeExhibitB()"
        ></app-file-chip>
      </mat-chip-list>
    </section>
    <section
      class="d-flex border-bottom border-ltgray py-3"
      *ngIf="section?.approval_process?.isSublease || section?.amortization_file_id"
    >
      <div class="d-flex flex-column mr-auto">
        <h6 class="primary mb-0">Amortization Schedule</h6>
        <p class="dkgray mb-0">Payoff schedule for the initial buildout investment.</p>
      </div>
      <app-split-button
        *ngIf="!section?.amortization_file_id"
        [disabled]="!canEdit"
        (clickEvent)="uploadAmortizationSchedule()"
        class="ml-2"
        lineHeight="24"
        [matMenu]="amortizationMenu"
      >
        Upload
      </app-split-button>
      <mat-menu #amortizationMenu="matMenu" xPosition="before">
        <button mat-menu-item *ngIf="!section?.amortization_task_id" (click)="createTask(PebFileType.Amortization)">
          <mat-icon>note_add</mat-icon>
          <span>Request Amortization Schedule </span>
        </button>
        <button mat-menu-item *ngIf="section?.amortization_task_id" (click)="viewTask(section?.amortization_task_id)">
          <mat-icon>note_add</mat-icon>
          <span>View Task </span>
        </button>
      </mat-menu>
      <mat-chip-list *ngIf="section?.amortization_file_id" class="ml-3">
        <app-file-chip
          [file]="{
            name: section?.amortization_file_name,
            id: section?.amortization_file_id
          }"
          [removable]="canEdit"
          (removeEvent)="removeAmortizationSchedule()"
        ></app-file-chip>
      </mat-chip-list>
    </section>
  </div>
</div>
