<!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->
<div
  #mainScreen
  id="meetings"
  data-test-id="meetings"
  (window:resize)="onResize($event)"
  class="meetings container-fluid app-container p-4"
  [ngClass]="{ 'no-scroll': openDialog }"
>
  <div class="row align-items-center">
    <div class="col">
      <h3 data-test-id="meetings-headline" id="title" class="dkblue font-weight-semibold">Meetings</h3>
    </div>
    <div class="col-auto" *ngIf="authService.isStaffOnAnyModule">
      <button mat-flat-button color="success" (click)="openNewMeetingDialog()">+ Meeting</button>
    </div>
  </div>
  <br />
  <div class="row list-filters align-items-center">
    <div [ngClass]="{ col: showIpad, 'col-auto': showDesktop }">
      <mat-form-field appearance="outline" [ngClass]="{ 'full-width': showIpad }">
        <mat-select [(ngModel)]="dateRangeOption" (ngModelChange)="setDateRange(dateRangeOption)">
          <mat-option value="upcoming"> Upcoming </mat-option>
          <mat-option value="past"> Past </mat-option>
          <mat-option value="week"> This Week </mat-option>
          <mat-option value="nextweek"> Next Week </mat-option>
          <mat-option value="month"> This Month </mat-option>
          <mat-option value="custom"> Date Range </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div [ngClass]="{ col: showIpad, 'col-4': showDesktop }">
      <mat-form-field appearance="outline" class="full-width">
        <input matInput [(ngModel)]="searchTerm" placeholder="Search Meetings" />
        <mat-icon *ngIf="searchTerm" (click)="searchTerm = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
    </div>
    <div [ngClass]="{ col: showDesktop, 'col-12 mt-4': showIpad }">
      <button
        *ngIf="showDesktop && dateRangeOption !== 'custom'"
        mat-button
        color="accent"
        (click)="setDateRange('custom', startDate, endDate)"
        class="mr-5"
      >
        Select Date Range
      </button>
      <div class="mr-5 d-inline-block align-middle" *ngIf="dateRangeOption === 'custom'">
        <input
          matInput
          [(ngModel)]="startDate"
          (dateChange)="setDateRange('custom', startDatePicker.value)"
          placeholder="Start Date"
          (click)="startDatePicker.open()"
          [matDatepicker]="startDatePicker"
          autocomplete="off"
          autocomplete="off"
          style="visibility: hidden; width: 0"
        />
        <mat-datepicker #startDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        <button
          (click)="startDatePicker.open()"
          mat-stroked-button
          color="accent"
          class="bg-white font-weight-semibold"
        >
          <span *ngIf="startDate === null"> Start Date </span>
          <span *ngIf="startDate != null">
            {{ startDate | date: 'MM/dd/yyyy' }}
          </span>
        </button>
        &nbsp;
        <h6 class="d-inline-block align-middle ltgray margin-0 font-weight-semibold">TO</h6>
        &nbsp;
        <input
          matInput
          [(ngModel)]="endDate"
          (dateChange)="setDateRange('custom', endDatePicker.value)"
          placeholder="End Date"
          (click)="endDatePicker.open()"
          [matDatepicker]="endDatePicker"
          autocomplete="off"
          autocomplete="off"
          style="visibility: hidden; width: 0"
        />
        <mat-datepicker #endDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        <button (click)="endDatePicker.open()" mat-stroked-button color="accent" class="bg-white font-weight-semibold">
          <span *ngIf="endDate === null"> End Date </span>
          <span *ngIf="startDate != null">
            {{ endDate | date: 'MM/dd/yyyy' }}
          </span>
        </button>
      </div>
      <mat-slide-toggle
        *ngIf="authService.isStaffOnAnyModule"
        color="accent"
        labelPosition="after"
        [checked]="myMeetings"
        (change)="filterMyMeetings()"
        class="d-inline-block align-middle"
      >
        <span class="ltblue font-weight-semibold"> My Meetings Only </span>
      </mat-slide-toggle>
    </div>
  </div>
  <br /><br />
  <div *ngIf="meetingCount === 0" class="gray">
    <i>You have no meetings in the selected date range. </i>
  </div>

  <div *ngFor="let d of meetingDates | meetingDateFilter: meetings:searchTerm:myMeetings" class="mb-4">
    <div class="row m-0 align-items-center mb-2 pl-3 pr-3">
      <div class="col-3 pl-0 mr-3" style="margin-left: -1rem">
        <h5 class="m-0 dkblue font-weight-semibold">
          {{ d | date: 'EEEE, MMMM dd, yyyy' }}
        </h5>
      </div>
      <div class="col-2">
        <h6 class="m-0 dkblue">Time</h6>
      </div>
      <div class="col-2">
        <h6 class="m-0 dkblue">Workspace</h6>
      </div>
      <div class="col-2">
        <h6 class="m-0 dkblue">Location</h6>
      </div>
      <div class="col">
        <h6 class="m-0 dkblue">Attendees</h6>
      </div>
      <div class="col">
        <h6 class="m-0 dkblue">Agenda</h6>
      </div>
    </div>
    <div class="m-portlet" *ngIf="meetingCount > 0">
      <div
        *ngFor="let m of meetings[d] | meetingListFilter: searchTerm:myMeetings; let last = last"
        class="m-portlet__body item p-0 pointer"
        [ngClass]="{ 'item-last': last }"
      >
        <a [routerLink]="['/meetings/' + m.id]" class="row m-0 p-3 align-items-center text-decoration-none">
          <div class="col-3 d-flex align-items-center pl-0">
            <p class="m-0 ltblue font-weight-semibold mr-3">
              {{ m.title }}
            </p>
            <h6
              *ngIf="m.recurring_meeting_frequency_interval"
              class="main-label text-nowrap border border-gray px-2 br-5 m-0 lh-20 gray"
            >
              Every
              {{ m.recurring_meeting_frequency_number !== 1 ? ' ' + m.recurring_meeting_frequency_number : '' }}
              {{ m.recurring_meeting_frequency_interval | titlecase
              }}{{ m.recurring_meeting_frequency_number !== 1 ? 's' : '' }}
            </h6>
          </div>
          <div class="col-2">
            <p class="m-0 dkblue font-weight-normal d-inline-block align-middle">
              <!-- <i class="material-icons" style="font-size: 1rem;">access_time</i> -->
              {{ m.start_datetime | date: 'h:mm aa' | lowercase }} -
              {{ m.end_datetime | date: 'hh:mm aa' | lowercase }}
            </p>
          </div>
          <div class="col-2">
            <p class="dkblue font-weight-normal m-0" *ngIf="m.workspace_id">
              <!-- <i class="material-icons" style="font-size: 1rem;">room</i> -->
              {{ m.workspace_name }}
            </p>
          </div>
          <div class="col-2">
            <p class="dkblue font-weight-normal m-0 text-truncate" *ngIf="m.location">
              <!-- <i class="material-icons" style="font-size: 1rem;">room</i> -->
              {{ m.location }}
            </p>
          </div>
          <div class="col">
            <p class="m-0 dkblue font-weight-normal">
              {{ m.attendee_count }}
              <!-- Attendee{{ m.attendee_count === 1 ? '' : 's' }} -->
            </p>
          </div>
          <div class="col pr-0">
            <p class="m-0 dkblue font-weight-normal">
              {{ m.agenda_count }}
              <!-- Agenda Item{{ m.agenda_count === 1 ? '' : 's' }} -->
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button mat-button class="full-width" color="primary" *ngIf="next" (click)="refresh(true)">Show more</button>
    </div>
  </div>
</div>
