<header *ngIf="!showList" mat-dialog-title>
  <div class="row align-items-center mb-4">
    <div class="col">
      <!-- NEW, VIEW OR EDIT?  -->
      <h4 class="gray m-0 font-weight-semibold d-inline-block align-middle pointer" (click)="showList = true">
        {{ workspaceName }} Products
      </h4>
      <mat-icon class="gray ml-2 mr-2 mt-1 align-middle">keyboard_arrow_right</mat-icon>
      <h4 class="green m-0 font-weight-bold d-inline-block align-middle" *ngIf="!productId">New Product</h4>
      <!-- maybe have product name here? -->
      <h4 class="dkblue m-0 font-weight-bold d-inline-block align-middle" *ngIf="productId">View Product</h4>
    </div>
    <div class="col-auto text-right d-flex align-items-center">
      <!-- show if viewing product - not new product -->
      <button
        mat-stroked-button
        color="accent"
        class="lh-28 ml-3"
        *ngIf="productId && !editable"
        (click)="enableInputs()"
      >
        Edit
      </button>
      <a mat-dialog-close class="close-modal ml-4">
        <i class="la la-times gray"></i>
      </a>
    </div>
  </div>
  <div class="row align-items-center m-0 mb-4">
    <div class="col bg-shade-ltblue br-5 p-2 pl-3 pr-3">
      <p class="m-0 dkblue font-weight-normal">
        Product details should only be entered if they will be the same EVERY time. If a Product field can vary, add
        that information after adding the product to the project.
      </p>
    </div>
  </div>
</header>
<header *ngIf="showList">
  <div class="row align-items-center mb-4">
    <div class="col">
      <!-- NEW, VIEW OR EDIT?  -->
      <h4 class="dkblue m-0 font-weight-bold">{{ workspaceName }} Products</h4>
    </div>
    <div class="col-auto text-right d-flex align-items-center">
      <!-- show new product form -->
      <button mat-flat-button color="success" class="lh-28 ml-3 pl-3 pr-3" (click)="showProductForm(null, true)">
        + Product
      </button>
      <a mat-dialog-close class="close-modal ml-4">
        <i class="la la-times gray"></i>
      </a>
    </div>
  </div>
  <div class="row mt-1 mb-1 product-group-form">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <input matInput placeholder="Search Products" [(ngModel)]="searchTerm" (ngModelChange)="filterProducts()" />
      </mat-form-field>
    </div>
  </div>
</header>
<mat-dialog-content>
  <form *ngIf="!showList" [formGroup]="productFormGroup">
    <section class="product-group-form">
      <div class="row mb-4">
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Product Name *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="name" placeholder="Light Bulb, TV, Window Vinyl, etc." />
          </mat-form-field>
        </div>
        <div class="col-4">
          <h6 class="font-weight-semibold dkblue mb-2">Active / Inactive</h6>
          <mat-slide-toggle class="pt-2 mt-1" color="accent" formControlName="enabled">
            <span class="font-weight-semibold" [ngClass]="{ gray: !enabled.value, ltblue: enabled.value }">
              Product {{ enabled.value ? 'Active' : 'Inactive' }}
            </span>
          </mat-slide-toggle>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Size</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="size" placeholder="12in, 60w, 48in x 96in, etc." />
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Color/Finish</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="color" placeholder="White, Flourescent, Gloss, etc." />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Make</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="make" placeholder="Make" />
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Model #</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="model_number" placeholder="Model #" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Suggested Price</h6>
          <mat-form-field appearance="outline" class="full-width">
            <span class="gray" matPrefix>$&nbsp;</span>
            <input
              matInput
              formControlName="unit_price"
              placeholder="0.00"
              step=".01"
              (keyup)="unitPriceChanged()"
              (blur)="unitPriceBlur()"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Suppliers to Solicit</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="preferred_company_name" placeholder="Company Name" />
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Product URL</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="url" placeholder="https://www.website.com" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col textarea">
          <h6 class="font-weight-semibold dkblue mb-2">Description / Notes</h6>
          <mat-form-field appearance="outline" class="full-width">
            <textarea matInput formControlName="description" placeholder="Description / Notes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </section>
  </form>
  <section *ngIf="showList">
    <div class="row m-0 mt-2 pt-2 pb-2 bg-shade-ltblue product-header">
      <div class="col">
        <h6 class="m-0 dkblue font-weight-semibold">Product</h6>
      </div>
      <div class="col-2">
        <h6 class="m-0 dkblue font-weight-semibold">Price</h6>
      </div>
      <div class="col-2">
        <h6 class="m-0 dkblue font-weight-semibold">Status</h6>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row m-0 product align-items-center ease" *ngFor="let product of filteredProducts">
      <div class="col pointer" (click)="showProductForm(product.id, false)">
        <p class="m-0 dkblue font-weight-semibold d-block text-truncate">
          {{ product.name }}
        </p>
        <p class="m-0 gray font-weight-normal d-block text-truncate">
          {{ product.description }}
        </p>
      </div>
      <div class="col-2">
        <p class="m-0 dkblue">
          {{ product.unit_price | currency: 'USD' }}
        </p>
      </div>
      <div class="col-2">
        <p class="m-0 ltblue font-weight-semibold" [ngClass]="{ gray: !product.enabled }">
          <span>
            {{ product.enabled ? 'Active' : 'Inactive' }}
          </span>
        </p>
      </div>
      <div class="col-1 text-right" (click)="showProductForm(product.id, true)">
        <mat-icon matRipple>edit</mat-icon>
      </div>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-actions class="row" *ngIf="editable">
  <div class="col">
    <button mat-button mat-dialog-close class="gray full-width">Cancel</button>
  </div>
  <div class="col">
    <button mat-flat-button color="accent" class="full-width" (click)="saveProduct()">Save Product</button>
  </div>
</mat-dialog-actions>
