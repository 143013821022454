import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ModalService, ProjectService, UserService, UserSearchService } from 'src/app/services';

import { Task, User } from 'src/app/types';

@Component({
  selector: 'app-new-task-view',
  templateUrl: './new-task-view.component.html',
  styleUrls: ['./new-task-view.component.scss'],
})
export class NewTaskViewComponent implements OnInit {
  /**
   * What happens when the cancel button is clicked
   */
  @Output()
  public cancelButtonClicked = new EventEmitter<any>();

  @Output()
  public taskCreated = new EventEmitter<Task>();

  /**
   * What milestone does this task belong to
   */
  @Input()
  public milestoneId: number;

  @ViewChild('taskTitleInput', { static: true }) set taskTitleInputRef(ref: ElementRef) {
    if (!!ref) {
      // SetTimeout needed to prevent the ExpressionChanged error. This focuses the textbox when it is shown
      setTimeout(() => {
        ref.nativeElement.focus();
      });
    }
  }

  title = '';

  assignedUser: User;

  isSearching = false;

  constructor(
    private userService: UserService,
    private userSearchService: UserSearchService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.userSearchService.refreshUsers();
  }

  private createTask() {
    const newTask = {
      title: this.title,
      milestone_id: this.milestoneId,
      assigned_user_id: this.assignedUser ? this.assignedUser.id : null,
    };

    this.projectService.createTask(newTask).subscribe((task) => {
      this.title = '';
      this.assignedUser = null;
      this.taskCreated.emit(task);
    });
    this.snackbar.open(`Task added!`);
  }

  submit() {
    this.createTask();
  }

  cancelCreation() {
    this.cancelButtonClicked.emit();
    this.closeUserSearch();
  }

  closeUserSearch() {
    this.isSearching = false;
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  public assignUser(newUser: User) {
    this.assignedUser = newUser;
  }

  public openAssignUserDialog() {
    this.modalService
      .openUserSearchModal({
        title: this.title,
        assigned_user_id: this.assignedUser ? this.assignedUser.id : null,
      })
      .subscribe(async (selectedUser) => {
        if (selectedUser !== false) {
          this.assignedUser = selectedUser;
        }
      });
  }
}
