import { Location } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  // HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { BugReportDialogComponent, CqiDialogComponent } from 'src/app/components';
import { COMPARE, ProjectStatus, TaskStatus, UserType, WorkOrderStatus, Workspace } from 'src/app/enums';
import {
  AuthService,
  MeetingService,
  // MessagingSystemService,
  ModuleService,
  NotificationService,
  ProjectTaskService,
  RemindersService,
  RequestService,
  SearchService,
  SidenavLinksService,
  SidenavService,
  TimeLogService,
  UserService,
  WeeklyFocusService,
  WorkOrderService,
} from 'src/app/services';
import { Module, Reminder, User, WeeklyFocus } from 'src/app/types';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterContentInit, OnDestroy {
  avatarMenu = false;
  weeklyFocus = this.weeklyFocusService.weeklyFocus;
  currentUser: User;

  notifications = [];
  reminderFields = ['reminder_datetime'];
  incompleteReminders: Reminder[] = [];
  hasOverdueReminders = false;

  unreadNotificationCount: number;

  home: { routerLink: string; label: string; matIcon: string };

  dispatch: { routerLink: string; label: string; matIcon: string };

  reminders: { routerLink: string; label: string; matIcon: string };

  releaseNotes: { routerLink: string; label: string; matIcon: string };

  // public unreadMessageCount = 0;
  public pendingRequestsCount = 0;

  public windowWidth: any;
  showDesktop: boolean;
  showIpad: boolean;
  path: string;
  showWorkspace: boolean;
  showMyItems: boolean;

  reportingBug = false;

  divHeight: string;
  showWorkspaceMenu = true;
  showMyItemsMenu = true;
  showLinks = true;
  public meetingCount = 0;

  private currentSubscription;
  private woBadgeUpdateSubscription;
  private requestBadgeUpdateSubscription;
  private reminderBadgeUpdateSubscription;

  unassignedWorkOrderCount = 0;
  assignedTasksCount = 0;

  constructor(
    public authService: AuthService,
    private sidenavService: SidenavService,
    public notificationService: NotificationService,
    private userService: UserService,
    // public messagingService: MessagingSystemService,
    public _moduleService: ModuleService,
    public remindersService: RemindersService,
    private cdRef: ChangeDetectorRef,
    public searchService: SearchService,
    public dialog: MatDialog,
    private sidenavLinksService: SidenavLinksService,
    private location: Location,
    private requestService: RequestService,
    private _meetingService: MeetingService,
    public timeLogService: TimeLogService,
    private workOrderService: WorkOrderService,
    private weeklyFocusService: WeeklyFocusService,
    private projectTaskService: ProjectTaskService,
    private router: Router
  ) {}

  @ViewChild('lower', { static: true }) elementView: ElementRef;

  get isSidebarClosed(): boolean {
    return this.sidenavService.isSidenavClosed;
  }

  get isAppAdmin() {
    return this.authService.isAppAdmin;
  }

  get isStaffOnAnyModule() {
    return this.authService.isStaffOnAnyModule;
  }

  get isTenant() {
    return this.authService.isTenant;
  }

  get links() {
    return this.sidenavLinksService.workspaceLinks || [];
  }

  get myLinks() {
    if (
      ![UserType.Vendor, UserType.Tenant].includes(this.authService.currentUser?.user_type_id) &&
      +this.meetingCount > 0 &&
      !this.authService.isStaffOnAnyModule
    ) {
      return [...(this.sidenavLinksService?.linksByUserType || []), this.sidenavLinksService.meetings];
    } else {
      return this.sidenavLinksService.linksByUserType || [];
    }
  }

  async ngOnInit() {
    const sidenavLinks = document.querySelector('#sidenav-links');
    const perfectScrollbar = new PerfectScrollbar(sidenavLinks);
    perfectScrollbar.update();

    this.initStaticData();

    // Makes sure isStaffOnAnyModule has the correct value when using it as a param for initWorkspaces
    this.authService.setIsStaffUserOnAnyModule();

    setTimeout(async () => {
      // load the workspace data on load
      await this._moduleService.initWorkspaces(
        this.isStaffOnAnyModule,
        this.authService.isLoggedIn,
        this.authService.currentUser.default_module_id
      );

      await this.initData();

      // keep track of subscription so we can destroy it when the component is destroyed
      this.currentSubscription = this._moduleService.selectWorkspaceEvent.subscribe(async (data) => {
        await this.initData();
      });
      // subscribe to data changes that will affect the badges
      this.woBadgeUpdateSubscription = this.workOrderService.sideNavBadgeUpdateNeeded$.subscribe(() =>
        this.loadBadgeData()
      );
      this.requestBadgeUpdateSubscription = this.requestService.requestCountUpdateNeeded$.subscribe(() =>
        this.loadBadgeData()
      );
      this.reminderBadgeUpdateSubscription = this.remindersService.reminderCountUpdateNeeded$.subscribe(() =>
        this.loadBadgeData()
      );

      this.getDivHeight();
      this.meetingCount = (await this._meetingService.getMeetingsByUserIds([this.currentUser.id]).toPromise()).length;
    });

    this.weeklyFocusService.getWeeklyFocus().subscribe((weeklyFocus: WeeklyFocus) => {
      Object.assign(this.weeklyFocus, weeklyFocus);
    });
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
    if (this.woBadgeUpdateSubscription) {
      this.woBadgeUpdateSubscription.unsubscribe();
    }
    if (this.requestBadgeUpdateSubscription) {
      this.requestBadgeUpdateSubscription.unsubscribe();
    }
    if (this.reminderBadgeUpdateSubscription) {
      this.reminderBadgeUpdateSubscription.unsubscribe();
    }
  }

  private initStaticData() {
    this.home = this.sidenavLinksService.home;
    this.dispatch = this.sidenavLinksService.dispatch;
    this.reminders = this.sidenavLinksService.reminders;
    this.releaseNotes = this.sidenavLinksService.releaseNotes;
    this.path = this.location.path() || '/';
    this.currentUser = this.authService.getLoggedInUser();
  }

  private async initData() {
    const allLinks = this.myLinks.concat(this.links).concat([this.releaseNotes]);
    allLinks.forEach((link) => {
      if (link.routerLink === this.path.substring(0, link.routerLink.length)) {
        this.sidenavLinksService.selectLink(link);
      }
    });
    // Keep message count updated dependent on reload of AllMessages
    // this.messagingService.getAllLoadedMessagesObservable().subscribe((messages) => {
    //   this.unreadMessageCount = this.messagingService.getUnreadMessagesCount();
    // });
    await this.loadBadgeData();
  }

  async loadBadgeData() {
    // async load all this data for faster load
    const promises = [];
    promises.push(this.remindersService.updateIncompleteReminderCount().then());

    const now = moment().startOf('day').format('YYYY/MM/DD');
    promises.push(
      this.projectTaskService
        .getTaskCounts([
          { type: 'field', field: 'assigned_user_id', value: this.currentUser.id },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'due_date', value: now, match: '<=' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'status_id', match: '!=', value: TaskStatus.Complete },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_locked', match: '!=', value: 1 },
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'parent_id', match: '!=', value: null },
          { type: 'operator', value: 'OR' },
          {
            type: 'field',
            field: 'project_status_id',
            value: [ProjectStatus.ACTIVE, ProjectStatus.PLANNED].join('^'),
          },
          { type: 'operator', value: ')' },
        ])
        .toPromise()
        .then((count) => (this.assignedTasksCount = count))
    );

    // if we have workspace_id, queue up count api calls, otherwise set to 0
    if (this._moduleService?.workspace_id && +this._moduleService.workspace_id !== +Workspace.Dispatch) {
      this.requestService
        .getPendingRequestsCountForWorkspace(this._moduleService.workspace_id)
        .subscribe((value) => (this.pendingRequestsCount = value));
      promises.push(
        this.workOrderService
          .getWorkOrdersCount([
            { type: 'field', field: 'assigned_user_id', value: 'null' },
            { type: 'operator', value: 'AND' },
            {
              type: 'field',
              field: 'module_id',
              value: this._moduleService.workspace_id.toString(),
            },
            { type: 'operator', value: 'AND' },
            {
              type: 'field',
              field: 'status_id',
              value: `${WorkOrderStatus.CLOSED}`,
              match: COMPARE.NOT_EQUAL,
            },
            { type: 'operator', value: 'AND' },
            {
              type: 'field',
              field: 'status_id',
              value: `${WorkOrderStatus.ON_HOLD}`,
              match: COMPARE.NOT_EQUAL,
            },
            { type: 'operator', value: 'AND' },
            {
              type: 'field',
              field: 'status_id',
              value: `${WorkOrderStatus.PLANNED}`,
              match: COMPARE.NOT_EQUAL,
            },
          ])
          .subscribe((value) => {
            this.unassignedWorkOrderCount = value;
          })
      );
    } else {
      this.pendingRequestsCount = 0;
      this.unassignedWorkOrderCount = 0;
    }

    await Promise.all(promises);
  }

  ngAfterContentInit(): void {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 800) {
      setTimeout(() => {
        this.sidenavService.toggleSidebarVisibility();
        this.cdRef.detectChanges();
      });
    }
  }

  get selectedWorkspace(): Module {
    return this._moduleService?.workspace;
  }

  get selectedWorkspaceName(): string {
    return this.selectedWorkspace?.name || '';
  }

  get selectedWorkspaceIcon(): string {
    return this.selectedWorkspace?.icon || '';
  }

  get userWorkspaces(): Module[] {
    return this._moduleService?.userWorkspaces?.sort((a, b) => a.name.localeCompare(b.name));
  }

  get weeklyFocusPulsing(): boolean {
    return (
      this.weeklyFocusService?.weeklyFocus?.id &&
      this.weeklyFocusService?.storedWeeklyFocusTimestamp !== this.weeklyFocus?.timestamp
    );
  }

  toggleMainNav() {
    this.sidenavService.toggleSidebarVisibility();
    // this.unreadMessageCount = this.messagingService.getUnreadMessagesCount();
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  closePanels() {
    // this.searchService.searchPanelIsOpen = false;
    // this.messagingService.messagesPanelIsOpen = false;
    // this.notificationService.notificationPanelIsOpen = false;
    this.timeLogService.timeLogPanelIsOpen = false;
  }

  logout() {
    this.authService.logout().subscribe();
  }

  // @HostListener('document:keydown.control.m')
  // onMessageButtonClick() {
  //   this.searchService.searchPanelIsOpen = false;
  //   this.notificationService.notificationPanelIsOpen = false;
  //   this.messagingService.messagesPanelIsOpen = !this.messagingService.messagesPanelIsOpen;
  // }

  onSearchButtonClick() {
    // this.messagingService.messagesPanelIsOpen = false;
    this.notificationService.notificationPanelIsOpen = false;
    this.timeLogService.timeLogPanelIsOpen = false;
    this.searchService.toggleSearchPanel();
  }

  onAvatarClick() {
    // this.avatarMenu = !this.avatarMenu;
    this.searchService.searchPanelIsOpen = false;
    this.timeLogService.timeLogPanelIsOpen = false;
    // this.messagingService.messagesPanelIsOpen = false;
    this.notificationService.notificationPanelIsOpen = !this.notificationService.notificationPanelIsOpen;
  }

  public get selectedLink() {
    return this.sidenavLinksService.selectedLink;
  }

  public selectLink(link: { routerLink: string; label: string; matIcon: string } = null) {
    this.sidenavLinksService.selectLink(link);
    this.closePanels();
  }

  anOverlayPanelIsOpen(): boolean {
    // return (
    //   this.messagingService.messagesPanelIsOpen ||
    //   this.searchService.searchPanelIsOpen ||
    //   this.notificationService.notificationPanelIsOpen
    // );
    return (
      // this.searchService.searchPanelIsOpen ||
      // this.notificationService.notificationPanelIsOpen ||
      this.timeLogService.timeLogPanelIsOpen
    );
  }

  openErrorLogging() {
    this.reportingBug = true;
    const dialogRef = this.dialog.open(BugReportDialogComponent, {
      width: '540px',
    });

    dialogRef.afterClosed().subscribe(async (error) => {
      this.reportingBug = false;
    });
  }

  openNewCQI() {
    const dialogRef = this.dialog.open(CqiDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(async (error) => {
      this.reportingBug = false;
    });
  }

  getDivHeight() {
    this.divHeight = 'calc(100% - ' + (this.elementView.nativeElement.offsetHeight + 75) + 'px)';
  }

  openTimeLog() {
    this.searchService.searchPanelIsOpen = false;
    this.notificationService.notificationPanelIsOpen = false;
    this.timeLogService.toggleTimeLogDrawer();
  }

  endImpersonation(e) {
    e.stopPropagation();
    this.authService.endImpersonation();
  }

  handleWorkspaceSelect(workspace: Module): void {
    if (this._moduleService.workspace_id === workspace.id) {
      return; // do nothing if workspace is the same
    }

    void this._moduleService.setWorkspaceFromId(workspace.id);

    const currentUrl = this.router.url;
    const shouldRedirectToList = ['/tasks/', '/work-orders/', '/requests/', '/projects/', '/purchasing/arfs/'].some(
      (listRootPathToRedirect) => currentUrl.includes(listRootPathToRedirect)
    );

    if (shouldRedirectToList) {
      void this.router.navigate(['/' + currentUrl.split('/')[1]]);
    } else {
      void this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() => {
        if (currentUrl === '/dispatch') {
          void this.router.navigate(['/dashboard']);
        } else if (currentUrl === '/dashboard' && workspace.id === Workspace.Dispatch) {
          void this.router.navigate(['/dispatch']);
        } else {
          void this.router.navigate([currentUrl]);
        }
      });
    }
  }
}
