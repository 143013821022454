import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';

import { AppRoutingService, ProjectService, ProjectTaskService } from 'src/app/services';

import { ProjectTenantService } from 'src/app/workspaces/construction/services';

import { ProjectTenantPEBStatus } from 'src/app/workspaces/construction/enums';

import { Task } from 'src/app/types';
import { PEB, ProjectTenantConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-finalize-peb-dialog',
  templateUrl: './finalize-peb-dialog.component.html',
  styleUrls: ['./finalize-peb-dialog.component.scss'],
})
export class FinalizePEBDialogComponent implements OnInit {
  public pebTasks$: Observable<Task[]>;

  private pebTaskArray: Task[];

  public tenant: ProjectTenantConstruction;

  private finalizing: boolean;

  private tenantPebs: PEB[] = [];

  private selectedPeb: PEB;

  constructor(
    private dialogRef: MatDialogRef<FinalizePEBDialogComponent>,
    private projectTaskService: ProjectTaskService,
    private projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private snackbar: MatSnackBar,
    private routingService: AppRoutingService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.tenant = this.data.tenant;
    this.pebTasks$ = this.projectTenantService.getTenantPebTasks(this.tenant);
    this.pebTasks$.subscribe((tasks) => (this.pebTaskArray = tasks));

    if (!this.tenant.selected_peb_id && this.data.tenantPebs) {
      this.tenantPebs = this.data.tenantPebs;
    }
  }

  public submit() {
    this.finalizing = true;
    const projectTenantDataToUpdate: ProjectTenantConstruction = {
      peb_status: ProjectTenantPEBStatus.Finalized,
    };

    // Set the selected peb if one was selected
    if (this.selectedPeb && !this.tenant.selected_peb_id) {
      projectTenantDataToUpdate.selected_peb_id = this.selectedPeb.id;
    }
    // Make the update api call
    this.projectTenantService.updateProjectTenant(this.tenant.id, projectTenantDataToUpdate).subscribe((peb) => {
      this.finalizing = false;
      this.snackbar.open(`PEB Finalized!`);
      this.dialogRef.close(true);
    });
  }

  public cancel() {
    this.dialogRef.close();
  }

  /**
   * Open Tasks Page To View The Given Task Id
   * @param id Task Id
   */
  public viewTask(taskId: number) {
    this.routingService.gotoProjectTask(taskId);
    this.cancel();
  }

  public canFinalize(): boolean {
    if (!this.pebTaskArray || this.finalizing) {
      return false;
    }
    const allTasksAreComplete = this.pebTaskArray.find((task) => task.status_id !== 3) == null;
    const selectedPEB = this.tenant.selected_peb_id != null || this.selectedPeb != null;
    return allTasksAreComplete && selectedPEB;
  }

  public selectPeb(peb: PEB) {
    this.selectedPeb = peb;
  }
}
