import { ApprovalStatus } from 'src/app/enums';
import { ApprovalProcess } from 'src/app/models';
import { DateService, FileService, ModalService, ProjectTaskService, TaskActionsService } from 'src/app/services';
import { Task, TaskInfo } from 'src/app/types';
import { PEBFundingSourceTypeEnum, ProjectTenantPEBStatus } from 'src/app/workspaces/construction/enums';

// This class exists to simplify the peb approval process for each tenant
// Since each peb (with its internal and tenant review) is specific for each tenant, we can give the tenant and let this class handle the rest

export class PEBApprovalProcess {
  _tenant;
  _taskService;

  _staffReview: ApprovalProcess;
  _tenantReview: ApprovalProcess;
  _cfmoId: number;
  _FILES_MESSAGE =
    'Please attach Bubble Drawing, and at least one of Reimbursement Agreement, Sublease Contract, or Exhibit B if Applicable.';
  _NOT_SELECTED_PEB_MESSAGE =
    'Using the (Select PEB) button at the top of each PEB, please select the approved PEB to continue';

  constructor(
    tenant,
    taskService: ProjectTaskService,
    modalService: ModalService,
    cfmoId: number,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService,
    internalTask?: Task,
    tenantTask?: Task
  ) {
    this._taskService = taskService;
    this.loadData(tenant, modalService, fileService, dateService, taskActionsService, internalTask, tenantTask).then();
    this._cfmoId = cfmoId;
  }

  // private methods breakdown the approval process messages
  private _notStartedByStaffStatus() {
    if (!this._cfmoId) {
      return 'Please add a CFMO to this Project';
    } else if (!this.hasFiles && this._tenant.representative_id) {
      return this._FILES_MESSAGE;
      // return 'Please upload Contract/Reimbursement Agreement, and Bubble Drawing';
    } else if (!this.hasFiles && !this._tenant.representative_id) {
      return 'Please attach Bubble Drawing';
      // return 'Please upload Contract/Reimbursement Agreement, and Bubble Drawing';
    } else {
      return 'Submit for Internal Review to continue';
    }
  }

  private _notStartedByTenantStatus() {
    if (!this.hasFiles) {
      return this._FILES_MESSAGE;
      // return 'Please upload Contract/Reimbursement Agreement, and Bubble Drawing';
    } else {
      return 'Submit for Tenant Review to continue';
    }
  }

  private _waitingForStaffApprovalStatus() {
    if (this._staffReview.needsReview) {
      return 'Pending Internal Review';
    } else if (this._staffReview.acceptedNotComplete) {
      return 'Approved. Mark internal task complete to continue';
    }
  }

  private _waitingForTenantApprovalStatus() {
    if (this._tenantReview.acceptedNotComplete) {
      return 'Approved. Mark tenant task complete to continue';
    } else {
      return 'Pending Tenant Review';
    }
  }

  private _rejectedByStaffStatus() {
    return 'Internal review was rejected. Submit revision to proceed';
  }

  private _acceptedByTenantStatus() {
    if (!this.hasSelectedPEB) {
      return this._NOT_SELECTED_PEB_MESSAGE;
    } else if (!this.isFinalized) {
      return 'Ready to be Finalized';
    } else {
      return 'Finalized';
    }
  }

  private _acceptedByStaffTenantStatus() {
    if (this.needsTenantApproval) {
      switch (this._tenantReview.approvalStatus) {
        case ApprovalStatus.NOT_STARTED:
          return this._notStartedByTenantStatus();

        case ApprovalStatus.AWAITING_APPROVAL:
          return this._waitingForTenantApprovalStatus();

        case ApprovalStatus.ACCEPTED:
          return this._acceptedByTenantStatus();
        default:
          return '';
      }
    } else {
      if (!this.hasSelectedPEB) {
        return this._NOT_SELECTED_PEB_MESSAGE;
      } else if (!this.isFinalized) {
        return 'Ready to be Finalized';
      } else {
        return 'Finalized';
      }
    }
  }

  // loads data for the approval tasks
  public async loadData(
    tenant,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService,
    internalTask?: Task,
    tenantTask?: Task
  ) {
    this._tenant = tenant;
    let internal_task = internalTask ?? null;
    let tenant_task = tenantTask ?? null;
    if (!internal_task && this._tenant.peb_approval_task_id) {
      internal_task = await this.getTaskData(this._tenant.peb_approval_task_id);
    }
    if (!tenant_task && this._tenant.tenant_approval_task_id) {
      tenant_task = await this.getTaskData(this._tenant.tenant_approval_task_id);
    }

    this._staffReview = new ApprovalProcess(internal_task, modalService, fileService, dateService, taskActionsService);
    this._tenantReview = new ApprovalProcess(tenant_task, modalService, fileService, dateService, taskActionsService);
  }

  // generates text for the peb approval process status
  get statusText(): string {
    if (this._staffReview) {
      switch (this._staffReview.approvalStatus) {
        case ApprovalStatus.NOT_STARTED:
          return this._notStartedByStaffStatus();
        case ApprovalStatus.AWAITING_APPROVAL:
          return this._waitingForStaffApprovalStatus();
        case ApprovalStatus.REJECTED:
          return this._rejectedByStaffStatus();
        case ApprovalStatus.ACCEPTED:
          if (this._tenantReview) {
            return this._acceptedByStaffTenantStatus();
          }
      }
    }
    return '';
  }

  get hasStaffTask() {
    return this._staffReview && this._staffReview.hasTask;
  }

  get hasTenantTask() {
    return this._tenantReview && this._tenantReview.hasTask;
  }

  get staffApprovalIcon() {
    if (!this._staffReview) {
      return 'radio_button_unchecked';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get staffApprovalColor() {
    if (!this._staffReview) {
      return '#c5cfe4';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  get tenantApprovalIcon() {
    if (!this._tenantReview) {
      return 'radio_button_unchecked';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get tenantApprovalColor() {
    if (!this._tenantReview) {
      return '#c5cfe4';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  get needsTenantApprovalNew() {
    return this._tenant.needs_tenant_approval;
  }

  get needsTenantApproval() {
    return this._tenant.representative_id != null;
  }

  get hasSelectedPEB() {
    return this._tenant.selected_peb_id != null;
  }
  get canDeletePEB() {
    return this._staffReview && this._staffReview.canStart && !this.isFinalized;
  }
  get staffCanStartNew() {
    return this._staffReview && this._staffReview.canStart && !this.isFinalized && !!this._cfmoId;
  }
  get staffCanStart() {
    return this._staffReview && this._staffReview.canStart && !this.isFinalized && this.hasFiles && !!this._cfmoId;
  }
  get staffCanAddNewRevision() {
    return this._staffReview && this._staffReview.canAddNewRevision && !this.isFinalized;
  }
  get currentStaffReviewer() {
    return this._staffReview && this._staffReview.taskReviewer;
  }
  get tenantReviewAssignedUser() {
    return (
      this._tenantReview &&
      this._tenantReview.getTask && {
        id: this._tenantReview.getTask.assigned_user_id,
        firstName: this._tenantReview.getTask.assigned_user_first_name,
        lastName: this._tenantReview.getTask.assigned_user_last_name,
      }
    );
  }

  get tenantCanStart() {
    return (
      this._tenantReview &&
      this._tenantReview.canStart &&
      this._staffReview.isTaskComplete &&
      !this.isFinalized &&
      this.hasFiles
    );
  }
  get tenantCanAddNewRevision() {
    return this._tenantReview && this._tenantReview.canAddNewRevision && !this.isFinalized;
  }
  get isFinalized() {
    return this._tenant.peb_status === ProjectTenantPEBStatus.Finalized;
  }
  get isPending() {
    return this._staffReview && this._staffReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL;
  }

  get canFinalizeNew() {
    return (
      this._staffReview &&
      this._staffReview.canFinalize &&
      (!this.needsTenantApprovalNew || (this._tenantReview && this._tenantReview.canFinalize)) &&
      !this.isFinalized
    );
  }

  get canFinalize() {
    return (
      this._tenant.selected_peb_id &&
      this._staffReview &&
      this._staffReview.canFinalize &&
      (!this.needsTenantApproval || (this._tenantReview && this._tenantReview.canFinalize)) &&
      !this.isFinalized
    );
  }
  get canReset() {
    return (
      ((this._staffReview && this._staffReview.canReset) || (this._tenantReview && this._tenantReview.canReset)) &&
      !this.isFinalized
    );
  }
  get canSelectPEB() {
    return (
      this._staffReview &&
      this._staffReview.canFinalize &&
      (!this.needsTenantApproval || (this._tenantReview && this._tenantReview.canFinalize)) &&
      !this.isFinalized
    );
  }

  get hasCoverLetter() {
    return !!this._tenant.peb_cover_letter_text;
  }

  get hasFilesNew() {
    return (
      this.hasBubbleDrawing &&
      (!this.isReimbursement || this._tenant.reimbursement_file_id) &&
      (!this.isSublease ||
        (this._tenant.sublease_contract_file_id && this._tenant.exhibit_b_file_id && this._tenant.amortization_file_id))
    );
  }

  get isReimbursement() {
    return this._tenant.funding_sources?.find((fs) => fs.type_id === PEBFundingSourceTypeEnum.Reimbursement);
  }

  get isSublease() {
    return this._tenant.funding_sources?.find((fs) => fs.type_id === PEBFundingSourceTypeEnum.Sublease);
  }

  get hasFiles() {
    return (
      (!this._tenant.representative_id ||
        this._tenant.sublease_contract_file_id ||
        this._tenant.amortization_file_id ||
        this._tenant.reimbursement_file_id ||
        this._tenant.exhibit_b_file_id) &&
      this.hasBubbleDrawing
    );
  }
  get hasBubbleDrawing() {
    return this._tenant.bubble_drawing_ids?.length;
  }

  // can edit the peb if (staff review isn't started) && (either no tenant review yet, or it isn't started)
  get canEdit() {
    return (
      this._staffReview &&
      this._staffReview.status === ApprovalStatus.NOT_STARTED &&
      (!this._tenantReview || this._tenantReview.status === ApprovalStatus.NOT_STARTED)
    );
  }

  // returns the route for the staff task
  get getStaffTaskLink(): string {
    return this._staffReview.getTaskLink;
  }

  // returns the route for the tenant task
  get getTenantTaskLink(): string {
    return this._tenantReview.getTaskLink;
  }

  // creates the staff review task for the peb, and returns the task id
  public async beginStaffReview(data: TaskInfo): Promise<number> {
    data.isTenant = false;
    return await this._staffReview.createApprovalTask(data);
  }

  public async beginTenantReview(data: TaskInfo): Promise<number> {
    data.isTenant = true;
    return await this._tenantReview.createApprovalTask(data);
  }

  private async getTaskData(task_id: number): Promise<Task> {
    if (!task_id) {
      return null;
    }
    return this._taskService.loadTask(task_id).toPromise();
  }
}
