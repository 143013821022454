import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NewAccountModalComponent } from 'src/app/components';
import { AuthService, ModalService, ProgressIndicatorService, UserService } from 'src/app/services';
import { APIFilter, DirectoryAccountsPreferences, User } from 'src/app/types';
import { DirectoryUserType } from '../../enums';
import { PreferenceStorage } from '../../utils';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;

  private userFields = [
    'email',
    'first_name',
    'last_name',
    'full_name',
    'user_type_id',
    'user_type_name',
    'company_name',
    'department_name',
    'building_name',
    'floor_name',
    'suite_name',
    'title',
    'cell_phone',
    'office_phone',
    'is_guest_user',
    'is_enabled',
    'is_login_enabled',
    'is_ar',
  ];

  canReviewAccounts: boolean;
  public users: User[];
  public currentUser: User;

  public selectedUserType: DirectoryUserType;

  private _searchString = '';

  public showARsOnly = false;
  showInactiveUsers = false;

  public userTypes: { id: number; name: string }[];
  public userTypesCount: Record<number, number> = {};

  public fieldToSortBy: string;
  public sortDirection: SortDirection = 'desc';

  pageSize = 20;
  startIndex = 0;
  endIndex = this.pageSize;
  filteredCount = { count: 0 };

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  selectedView: string;

  private preferences = new PreferenceStorage<DirectoryAccountsPreferences>('preferences_directory_accounts', {
    version: 1,
  });

  readonly DirectoryUserType = DirectoryUserType;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedView = this.router.url;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Users..');
    this.currentUser = this.authService.getLoggedInUser();
    this.userTypes = await this.userService.getUserTypes().toPromise();
    this.userTypes.map((userType) => {
      if (userType.name === 'Staff') {
        userType.name = 'UHAT/1Call';
      }
    });
    this.userTypes.push({ id: DirectoryUserType.Guest, name: 'Guest' });

    await this.refresh();

    this.selectedUserType = this.preferences.currentValue.selectedUserType ?? 1;
    this.sortDirection = this.preferences.currentValue.sortDirection ?? null;
    this.fieldToSortBy = this.preferences.currentValue.fieldToSortBy ?? null;

    this.progressIndicatorService.close();

    this.canReviewAccounts = this.authService.isAppAdmin || this.authService.currentUserIsOfWorkspaceRole(2, null);
  }

  async refresh(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Users..');
    const userFilters: APIFilter[] = [];
    this.users = await this.userService.getUsers(userFilters, this.userFields, null, true).toPromise();
    this.getUserTypeCounts();
    this.progressIndicatorService.close();
  }

  selectionChange(event: { value: string }): void {
    void this.router.navigateByUrl(event.value);
  }

  get searchString(): string {
    return this._searchString?.trim() || '';
  }

  set searchString(value: string) {
    this._searchString = value?.trim() || '';
  }

  private getUserTypeCounts(): void {
    this.userTypes.forEach((userType) => {
      if (userType.id !== DirectoryUserType.Guest) {
        this.userTypesCount[userType.id] =
          (this.users &&
            this.users.filter((user) => {
              const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
              const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
              return user.user_type_id === userType.id && !user.is_guest_user && showARsOnly && showInactiveUsers;
            }).length) ||
          0;
      } else {
        this.userTypesCount[userType.id] =
          (this.users &&
            this.users.filter((user) => {
              const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
              const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
              return user.is_guest_user && showARsOnly && showInactiveUsers;
            }).length) ||
          0;
      }
    });

    this.userTypesCount[DirectoryUserType.Everyone] =
      this.users.filter((user) => {
        const showARsOnly = this.showARsOnly === true ? user.is_ar === 1 : true;
        const showInactiveUsers = this.showInactiveUsers === true ? true : user.is_enabled === 1;
        return showARsOnly && showInactiveUsers;
      }).length || 0;
  }

  addAccount(): void {
    const dialogRef = this.dialog.open(NewAccountModalComponent, {
      width: '540px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        void this.refresh();
      }
    });
  }

  public addSToUserType(userType: { id: number; name: string }): 's' | '' {
    return userType.name !== 'UHAT/1Call' ? 's' : '';
  }

  public setSearchString(searchString = ''): void {
    this.searchString = searchString;
    this.searchChanged(searchString);
  }

  public setUserType(selectedUserType: DirectoryUserType): void {
    this.selectedUserType = selectedUserType;
    this.preferences.setPartialValue({ selectedUserType });
    this.goToFirstPage();
  }

  impersonateUser(user: User): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Impersonation',
        descriptionText: `Are you sure you would like to begin impersonating ${user.first_name} ${user.last_name}?`,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          void this.authService.impersonateUser(user.id).toPromise();
        }
      });
  }

  inviteUser(user: User): void {
    this.modalService.openInviteUserModal({ users: [user] })?.subscribe();
  }

  public updateSortByField(fieldToSortBy: string): void {
    if (fieldToSortBy === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'asc';
    }

    this.fieldToSortBy = fieldToSortBy;
    this.preferences.setPartialValue({ sortDirection: this.sortDirection, fieldToSortBy });
  }

  pageChange(event: { pageIndex: number; pageSize: number }): void {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
  }

  goToFirstPage(): void {
    this.paginator.firstPage();
    this.startIndex = 0;
    this.endIndex = this.pageSize;
  }

  searchChanged(searchString: string): void {
    if (searchString && searchString.length > 0) {
      this.fieldToSortBy = null;
    } else {
      this.fieldToSortBy = this.preferences.currentValue.fieldToSortBy;
    }
    this.goToFirstPage();
  }

  filterARsOnly(): void {
    this.showARsOnly = !this.showARsOnly;
    this.getUserTypeCounts();
    this.goToFirstPage();
  }

  filterInactiveUsersOnly(): void {
    this.showInactiveUsers = !this.showInactiveUsers;
    this.getUserTypeCounts();
    this.goToFirstPage();
  }
}
