import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Task, TaskAccessoryData, UhatFileReference } from 'src/app/types';
import {
  DateService,
  DisplayReviewersService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import {
  BidPackage,
  ProjectConstruction,
  ProjectSubmittal,
  SubmittalCategory,
  SubmittalItem,
} from 'src/app/workspaces/construction/types';
import { uniqBy } from 'lodash';
import { ResourceType, TaskReviewStatus } from 'src/app/enums';
import { SubmittalsService } from 'src/app/workspaces/construction/services';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-upload-submittals-dialog',
  templateUrl: './upload-submittals-dialog.component.html',
  styleUrls: ['./upload-submittals-dialog.component.scss'],
})
export class UploadSubmittalsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<UploadSubmittalsDialogComponent>,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private handleErrorService: HandleErrorService,
    private modalService: ModalService,
    private progressIndicatorModal: ProgressIndicatorService,
    private projectService: ProjectService,
    private submittalsService: SubmittalsService,
    private taskService: ProjectTaskService,
    private dateService: DateService
  ) {}

  private bidPackageFields = [
    'id',
    'trade_name',
    'number',
    'submittals{spec{category,items{type_id}},selected_item_ids}',
    'transmittal_revision',
  ];

  private projectFields = ['id', 'project_manager_id', 'workspace_manager_id', 'architect_id'];

  // parentData
  public accessoryData: TaskAccessoryData;
  public task: Task;

  public bidPackage: BidPackage;
  public project: ProjectConstruction;
  public reviewFiles: UhatFileReference[] = [];
  public submittalCategories: SubmittalCategory[];
  public isLoading = true;

  async ngOnInit() {
    this.accessoryData = this.data?.accessoryData;
    this.task = this.data?.task;
    this.reviewFiles = this.accessoryData?.reviewFiles || [];
    this.bidPackage = await this.projectService
      .getBidPackageById(this.accessoryData?.parentId, this.bidPackageFields)
      .toPromise();

    const submittals = this.bidPackage.submittals;

    this.isLoading = false;
    // Gets all submittal categories being used
    this.submittalCategories = uniqBy(
      submittals.map((i) => ({
        id: i.spec.category.id,
        code: i.spec.category.code,
        name: i.spec.category.name,
        submittals: [],
      })),
      (c) => c.id
    );

    for (const submittal of submittals) {
      const category = this.submittalCategories.find((c) => c.id === submittal.spec.category_id);
      category.submittals.push(submittal);
    }

    this.project =
      this.projectService.currentSelectedProject ||
      (await this.projectService.getProjectById(this.task.project_id, this.projectFields).toPromise());
  }

  get ResourceType() {
    return ResourceType;
  }

  async openUploadFileModal(uploadedFiles = null) {
    if (uploadedFiles?.linkedFiles?.length) {
      for (const file of uploadedFiles.linkedFiles) {
        if (!this.reviewFiles.find((f) => (f.file_id || f.id) === (file.file_id || file.id))) {
          await this.fileService.linkFile(file.file_id, this.project?.id, ResourceType.Project).toPromise();
          this.reviewFiles.push(file);
        }
      }
    } else {
      const data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.project?.id,
        allowComment: false,
        preSelectedTags: [],
        project_id: this.project?.id,
        computerFiles: uploadedFiles?.computerFiles,
        filteredFiles: this.reviewFiles,
      };
      const files = await this.modalService.openFileAttachmentDialog(data).toPromise();

      if (files) {
        this.reviewFiles = [...this.reviewFiles, ...files];
      }
    }
  }

  public async removeFile(file) {
    this.reviewFiles = this.reviewFiles.filter((f) => (f.file_id || f.id) !== (file.file_id || file.id));
  }

  public async submit() {
    if (this.reviewFiles?.length) {
      this.progressIndicatorModal.openAwaitIndicatorModal();

      // If isReviewItem then this has been done before and things only need to be updated and not created.
      if (this.accessoryData.isReviewItem && this.accessoryData.reviewChain) {
        this.progressIndicatorModal.updateStatus('Updating Reviewers...');
        this.accessoryData.reviewChain.map((reviewer) => (reviewer.status = TaskReviewStatus.Pending));

        const transmittalData = {
          transmittal_revision: this.bidPackage.transmittal_revision + 1,
          transmittal_is_created: 0,
        };
        await this.projectService.updateTransmittalStatus(this.bidPackage.id, transmittalData).toPromise();
      } else {
        this.progressIndicatorModal.updateStatus('Adding Reviewers...');
        const includes = {
          pm: true,
          arch: true,
        };
        const reviewers = this.displayReviewersService.getInternalReviewers(includes, this.project);
        this.accessoryData.isReviewItem = true;
        this.accessoryData.reviewChain = reviewers.reviewIds;
        this.accessoryData.reviewCreator = reviewers.reviewIds[0].id || this.project.project_manager;
      }

      this.accessoryData.reviewFiles = this.reviewFiles;
      const taskData = {
        id: this.task.id,
        accessory_data: JSON.stringify(this.accessoryData),
        assigned_user_id: this.accessoryData.reviewChain[0].id || this.project.project_manager_id,
        due_date: this.dateService.addWeekdays(2).format('YYYY-MM-DD'),
      };

      await this.projectService
        .createNote(
          ResourceType.Task,
          taskData.id,
          `Uploaded Submittal File${this.accessoryData?.reviewFiles?.length === 1 ? '' : 's'}:<br />`
        )
        .toPromise()
        .then(async (result) => {
          for (const file of this.accessoryData?.reviewFiles) {
            await this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.Note).toPromise();
          }
        });

      const updatedTask = await this.projectService.updateTask(taskData).toPromise();

      await this.taskService.updateTask(updatedTask);
      this.taskService.selectTaskById(updatedTask.id, false).subscribe();

      this.progressIndicatorModal.close();
      this.cancel();
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
