<div class="project-modal">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">{{ action }} Reminder</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <form [formGroup]="reminderFormGroup">
    <div mat-dialog-content>
      <div class="row" *ngIf="reminder.created_by_id">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Created By</mat-label>
            <input
              matInput
              disabled
              type="text"
              [value]="reminder.created_by_first_name + ' ' + reminder.created_by_last_name"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="reminder.parent_name">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Linked to</mat-label>
            <input matInput disabled type="text" [value]="reminder.parent_name" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput required="true" type="text" formControlName="description" />
            <mat-error *ngIf="description.errors?.required">Description is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Reminder Date</mat-label>
            <input
              matInput
              required="true"
              (click)="reminderDatePicker.open()"
              [matDatepicker]="reminderDatePicker"
              autocomplete="off"
              formControlName="reminder_date"
            />
            <mat-datepicker-toggle matSuffix [for]="reminderDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reminderDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="reminder_date.errors?.required">Reminder date is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-5">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Reminder Time</mat-label>
            <input matInput required="true" type="time" formControlName="reminder_time" />
            <mat-error *ngIf="due_time.errors?.required">Reminder time is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!addDueDate" class="row pb-2 mb-4">
        <div class="col">
          <button
            (click)="addDueDate = true"
            mat-stroked-button
            color="accent"
            class="pl-4 pr-4"
            style="line-height: 44px"
          >
            Add Due Date
          </button>
        </div>
      </div>
      <div *ngIf="addDueDate" class="row">
        <div class="col-7">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Due Date</mat-label>
            <input
              matInput
              autoFocus
              (click)="dueDatePicker.open()"
              [matDatepicker]="dueDatePicker"
              autocomplete="off"
              formControlName="due_date"
            />
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-5">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Due Time</mat-label>
            <input matInput type="time" formControlName="due_time" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Priority</mat-label>
            <mat-select matNativeControl formControlName="priority_id">
              <mat-option *ngFor="let rp of reminderPriorities" [value]="rp.id">{{ rp.name }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button class="full-width" type="button" color="accent" (click)="cancel()">Cancel</button>
      </div>
      <div class="col">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">
          Save Reminder
        </button>
      </div>
    </div>
  </form>
</div>
