<app-project-budget
  #childBudget
  *ngIf="getChildBudget"
  [disableInit]="true"
  [projectId]="projectId"
  class="d-none"
></app-project-budget>
<div id="kendo-content" *ngIf="project">
  <kendo-pdf-export #pdf paperSize="Letter" [landscape]="true" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <!-- <section [ngStyle]="{ display: pageNum > 1 ? 'block' : 'none' }">
        </section> -->
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <!-- EXPORT HEADER -->
      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-6">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                </p>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>
                  <span>
                    {{ project.building_code }}
                  </span>
                  <span *ngIf="project.floor_code">-{{ project.floor_code }}</span>
                </p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Completion Date </label>
                <p>
                  {{ getFormattedDate(project.end_date) }}
                </p>
              </div>
              <div class="col-4" *ngIf="projectType === 1">
                <label class="main-label mt-0"> Architect </label>
                <p>{{ project.architect_first_name }} {{ project.architect_last_name }} &nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <!-- BUDGET REPORT HEADER -->
        <section class="form-title">
          <div class="row">
            <div class="col">
              <h1 class="black mb-2" style="font-weight: 700">Budget Report</h1>
            </div>
          </div>
        </section>
        <section class="form-highlights text-left">
          <div class="row">
            <div class="col">
              <label class="main-label mt-1 lh-8">Approved Construction Budget</label>
              <p class="m-0">
                {{ +budgetData?.constructionBudgetTotal || 0 | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Original Contract Sum</label>
              <p class="m-0">
                {{ +budgetData?.awardedBidTotal || 0 | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Change Order Net</label>
              <p class="m-0">
                {{ +budgetData?.changeOrderTotal || 0 | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Current Contract Sum</label>
              <p class="m-0">
                {{ (+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0) | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Retainage WH</label>
              <p class="m-0">
                {{ +budgetData?.invoicesRetainageTotal || 0 | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Billed-to-Date</label>
              <p class="m-0">
                {{ +budgetData?.invoicesBilledTotal || 0 | currency }}
              </p>
            </div>
            <div class="col">
              <label class="main-label mt-1 lh-8">Balance-To-Finish</label>
              <p class="m-0">
                {{
                  (+budgetData?.awardedBidTotal || 0) +
                    (+budgetData?.changeOrderTotal || 0) -
                    (+budgetData?.invoicesBilledTotal || 0) | currency
                }}
              </p>
            </div>
          </div>
        </section>
        <section class="form-table">
          <div class="row form-table-header mt-2 align-items-center">
            <div class="col-3 pl-0 pr-2">
              <label class="main-label m-0 mt-0 mr-3 py-2"> # </label>
              <label class="main-label m-0 mt-0 py-2"> TRADE/SUPPLIER </label>
            </div>
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> ORIG. CONTR. </label>
            </div>
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> CO NET </label>
            </div>
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> CURRENT CONTR. </label>
            </div>
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> RETAINAGE WH </label>
            </div>
            <!-- <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> PROCESSED </label>
            </div> -->
            <!-- <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> PENDING </label>
            </div> -->
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> BILLED-TO-DATE </label>
            </div>
            <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> BAL-TO-FINISH </label>
            </div>
          </div>
          <section>
            <!-- TRADE LINE ITEMS -->
            <section class="form-bid-package" *ngFor="let bp of budgetItems?.bid_packages">
              <div class="row d-flex form-table-line-item trade-line-item align-items-center" style="border-top: none">
                <div class="col-3 pl-0 pr-2 py-2 d-flex align-self-stretch">
                  <p class="m-0 py-1 mr-3">
                    <!-- Trade and Supplier -->
                    <b>{{ bp.number }}</b>
                  </p>
                  <p class="m-0 py-1">
                    <b
                      >{{ bp.trade_name }}{{ bp.awarded_company_name ? ': ' : '' }}{{ bp.awarded_company_name }}
                      {{ bp.child_project?.id ? '(PJ-' + bp.child_project?.code + ')' : '' }}</b
                    >
                  </p>
                </div>
                <div class="col text-right p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- ORIGINAL CONTRACT SUM -->
                    {{
                      (bp.child_request?.id || bp.child_project?.id
                        ? +bp.child_project?.budget_data?.awardedBidTotal || 0
                        : bp.awarded_amount || 0
                      ) | currency: 'USD'
                    }}
                  </p>
                </div>
                <div class="col text-right p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- CO NET CHANGE -->
                    {{ +bp.changeOrderTotal || 0 | currency: 'USD' }}
                  </p>
                </div>
                <div class="col text-right highlight p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- CURRENT CONTRACT SUM -->
                    {{
                      (bp.child_request?.id || bp.child_project?.id
                        ? (+bp.child_project?.budget_data?.awardedBidTotal || 0) + (+bp.changeOrderTotal || 0)
                        : (+bp.awarded_amount || 0) + (+bp.changeOrderTotal || 0)
                      ) | currency: 'USD'
                    }}
                  </p>
                </div>
                <div class="col text-right p-2 highlight align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- TOTAL RETAINAGE WH -->
                    {{ +bp.retainageTotal || 0 | currency: 'USD' }}
                  </p>
                </div>
                <!-- <div class="col text-right p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    {{ +bp.processedTotal || 0 | currency: 'USD' }}
                  </p>
                </div> -->
                <!-- <div class="col text-right highlight p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    {{ +bp.pendingTotal || 0 | currency: 'USD' }}
                  </p>
                </div> -->
                <div class="col text-right dark-highlight p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- TOTAL BILLED-TO-DATE -->
                    <b>{{ +bp.billedTotal || 0 | currency: 'USD' }}</b>
                  </p>
                </div>
                <div class="col text-right dark-highlight p-2 align-self-stretch">
                  <p class="m-0 py-1">
                    <!-- TOTAL BAL-TO-FINISH -->
                    <b>{{ +bp.balanceToFinish || 0 | currency: 'USD' }}</b>
                  </p>
                </div>
              </div>
              <!-- INVOICE LINE ITEMS PER TRADE -->
              <div *ngIf="bp?.change_orders?.length > 0 && !bp.child_request?.id && !bp.child_project?.id" class="row">
                <div class="col-12">
                  <div class="row form-table-header invoice-line-items">
                    <div class="col px-2">
                      <label class="main-label m-0 py-1 pl-4"> INVOICE </label>
                    </div>
                    <div class="col-3 px-2">
                      <label class="main-label m-0 py-1"> DESCRIPTION </label>
                    </div>
                    <div class="col px-2">
                      <label class="main-label m-0 py-1"> SUBMITTED </label>
                    </div>
                    <!-- <div class="col px-2"></div> -->
                    <div class="col px-2 text-right">
                      <label class="main-label m-0 py-1"> TOTAL </label>
                    </div>
                    <div class="col px-2 text-right">
                      <label class="main-label m-0 py-1"> RETAINAGE WH </label>
                    </div>
                    <div class="col px-2 text-right">
                      <label class="main-label m-0 py-1"> AMOUNT DUE </label>
                    </div>
                    <div class="col px-2 text-right">
                      <label class="main-label m-0 py-1"> BILLED-TO-DATE </label>
                    </div>
                    <div class="col px-2 text-right">
                      <label class="main-label m-0 py-1"> BAL-TO-FINISH </label>
                    </div>
                  </div>
                  <!-- Invoice Type -->
                  <section *ngFor="let co of bp.change_orders">
                    <div class="row form-table-line-item invoice-line-item">
                      <div class="col-5 pl-2 pr-0 py-2">
                        <span class="pl-3"
                          ><b>{{
                            co.id ? 'CO #' + co.local_index + ' - ' + co.short_description : 'Original Bid'
                          }}</b></span
                        >
                        <div class="pl-3 dkgray" style="font-size: 7px" *ngIf="co.id">
                          Initiated: {{ co.approval_datetime | date: 'MMM dd yyyy' }}
                        </div>
                      </div>
                      <div class="col px-2 py-2"></div>
                      <div class="col px-2 text-right py-2"></div>
                      <div class="col px-2 text-right py-2"></div>
                      <div class="col px-2 text-right py-2"></div>
                      <div class="col px-2 text-right py-2 dark-highlight">
                        <span>{{ +co.billedAmount | currency: 'USD' }}</span>
                      </div>
                      <div class="col px-2 text-right py-2 dark-highlight">
                        <span>{{ +co.expectedAmount - +co.billedAmount | currency: 'USD' }}</span>
                      </div>
                    </div>
                    <ng-container *ngFor="let i of co.invoices">
                      <div
                        *ngIf="[2, 3, 4].indexOf(i.status_id) > -1"
                        class="row form-table-line-item invoice-line-item d-flex"
                        [ngClass]="{
                          selectedInvoice: i.id === selectedInvoice?.id,
                          'pending-invoice': [2, 3].indexOf(i.status_id) > -1
                        }"
                      >
                        <div class="col py-2 px-2 align-self-stretch">
                          <span class="pl-4">{{ i.number }}</span>
                        </div>
                        <div class="col-3 py-2 px-2 align-self-stretch">
                          <span>
                            {{ i.title }}
                          </span>
                        </div>
                        <div class="col py-2 px-2 align-self-stretch">
                          <span>{{ i.created_datetime | date: 'MMM dd, yyyy' }}</span>
                        </div>
                        <!-- <div class="col py-2 px-2 align-self-stretch">
                        </div> -->
                        <div class="col text-right py-2 px-2 align-self-stretch">
                          <span>{{ +i.total || 0 | currency: 'USD' }}</span>
                        </div>
                        <div class="col text-right py-2 px-2 align-self-stretch">
                          <span>{{ +i.retainage || 0 | currency: 'USD' }}</span>
                        </div>
                        <div class="col text-right py-2 px-2 highlight align-self-stretch">
                          <span>{{ (+i.total || 0) - (+i.retainage || 0) | currency: 'USD' }}</span>
                        </div>
                        <div class="col py-2 px-2 align-self-stretch">
                          <span *ngIf="i.status_id === 1">New</span>
                          <span *ngIf="[2, 3].indexOf(i.status_id) > -1">Pending</span>
                          <span *ngIf="i.status_id === 4">Processed </span>
                          <span *ngIf="i.status_id === 5">Rejected</span>
                        </div>
                        <div class="col text-right py-2 px-2 align-self-stretch">
                          <span class="gray" *ngIf="i?.status_id === 4">
                            <span *ngIf="i?.processed_by?.first_name || i?.processed_by?.last_name">
                              {{ i?.processed_by?.first_name }} {{ i?.processed_by?.last_name }}
                            </span>
                            <br />
                            <span *ngIf="formatDate(i?.processed_datetime)">
                              {{ formatDate(i?.processed_datetime) }}</span
                            >
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </section>
                </div>
              </div>
            </section>
          </section>
        </section>
        <!-- GRAND TOTALS -->
        <div class="row form-table-header form-bid-package mt-2 align-items-center">
          <div class="col-3 pl-0 pr-2">
            <label class="main-label m-0 py-2">PROJECT TOTALS</label>
          </div>
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> ORIG. CONTR. </label>
          </div>
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> CO NET </label>
          </div>
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> CURRENT CONTR. </label>
          </div>
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> RETAINAGE WH </label>
          </div>
          <!-- <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> PROCESSED </label>
            </div> -->
          <!-- <div class="col text-right px-2">
              <label class="main-label m-0 py-2"> PENDING </label>
            </div> -->
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> BILLED-TO-DATE </label>
          </div>
          <div class="col text-right px-2">
            <label class="main-label m-0 py-2"> BAL-TO-FINISH </label>
          </div>
        </div>
        <section>
          <div class="row form-table-line-item invoice-line-item form-bid-package">
            <div class="col-3 py-2 pl-0 pr-2">
              <p>Project Totals</p>
            </div>
            <div class="col text-right py-2 px-2">
              <!-- ORIGINAL CONTRACT SUM -->
              <p>{{ +budgetData?.awardedBidTotal || 0 | currency }}</p>
            </div>
            <div class="col text-right py-2 px-2">
              <!-- CO NET CHANGE -->
              <p>{{ +budgetData?.changeOrderTotal || 0 | currency }}</p>
            </div>
            <div class="col text-right py-2 px-2">
              <!-- TOTAL CONTRACT SUM -->
              <p>
                {{ (+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0) || 0 | currency }}
              </p>
            </div>
            <div class="col text-right py-2 px-2">
              <!-- TOTAL RETAINAGE WH -->
              <p>{{ +budgetData?.invoicesRetainageTotal || 0 | currency }}</p>
            </div>
            <!-- <div class="col text-right py-2 px-2">
              <p>{{ +budgetData?.invoicesProcessedTotal || 0 | currency }}</p>
            </div>
            <div class="col text-right py-2 px-2">
              <p>{{ +budgetData?.invoicesPendingTotal || 0 | currency }}</p>
            </div> -->
            <div class="col text-right py-2 px-2">
              <!-- TOTAL BILLED-TO-DATE -->
              <p>{{ +budgetData?.invoicesBilledTotal || 0 | currency }}</p>
            </div>
            <div class="col text-right py-2 px-2">
              <!-- TOTAL BAL-TO-FINISH -->
              <p>
                {{
                  (+budgetData?.awardedBidTotal || 0) +
                    (+budgetData?.changeOrderTotal || 0) -
                    (+budgetData?.invoicesBilledTotal || 0) || 0 | currency
                }}
              </p>
            </div>
          </div>
        </section>
        <!-- <div *ngIf="1 < PEBsToExport.length - 1" class="page-break"></div> -->
      </div>
    </div>
  </kendo-pdf-export>
</div>
