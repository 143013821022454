import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ReleaseNoteBug, ReleaseNoteFeature, ReleaseNoteRelease, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  host: string = environment.serviceHost;

  releaseNoteBugsUrl = `${this.host}/api/v1/release-notes-bugs`;
  releaseNoteFeaturesUrl = `${this.host}/api/v1/release-notes-features`;
  releaseNoteReleasesUrl = `${this.host}/api/v1/release-notes-releases`;

  getReleaseNoteBugs(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ReleaseNoteBug[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.releaseNoteBugsUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const releaseNoteBugs: ReleaseNoteBug[] = result.data.release_notes_bugs;
          return releaseNoteBugs;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getReleaseNoteBugById(id, fields: string[]): Observable<ReleaseNoteBug> {
    return this.http.get(`${this.releaseNoteBugsUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const releaseNoteBugs: ReleaseNoteBug[] = result.data['release notes bugs'];
        return releaseNoteBugs[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createReleaseNoteBug(releaseNoteBug: ReleaseNoteBug): Observable<ReleaseNoteBug> {
    return this.http.post(this.releaseNoteBugsUrl, releaseNoteBug).pipe(
      map((result: ServiceResponse) => {
        const createdReleaseNoteBug: ReleaseNoteBug = result.data['release notes bugs'];
        return createdReleaseNoteBug;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateReleaseNoteBug(
    releaseNoteBugId: number,
    releaseNoteBug: ReleaseNoteBug,
    fields?: string[]
  ): Observable<ReleaseNoteBug> {
    return this.http
      .put(
        `${this.releaseNoteBugsUrl}/${releaseNoteBugId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        releaseNoteBug
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedReleaseNoteBug: ReleaseNoteBug = result.data['release notes bugs'];
          return updatedReleaseNoteBug;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteReleaseNoteBug(releaseNoteBugId: number): Observable<void> {
    return this.http.delete(`${this.releaseNoteBugsUrl}/${releaseNoteBugId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getReleaseNoteFeatures(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ReleaseNoteFeature[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.releaseNoteFeaturesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const releaseNoteFeatures: ReleaseNoteFeature[] = result.data.release_notes_features;
          return releaseNoteFeatures;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getReleaseNoteFeatureById(id, fields: string[]): Observable<ReleaseNoteFeature> {
    return this.http.get(`${this.releaseNoteFeaturesUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const releaseNoteFeatures: ReleaseNoteFeature[] = result.data['release notes features'];
        return releaseNoteFeatures[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createReleaseNoteFeature(releaseNoteFeature: ReleaseNoteFeature): Observable<ReleaseNoteFeature> {
    return this.http.post(this.releaseNoteFeaturesUrl, releaseNoteFeature).pipe(
      map((result: ServiceResponse) => {
        const createdReleaseNoteFeature: ReleaseNoteFeature = result.data['release notes features'];
        return createdReleaseNoteFeature;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateReleaseNoteFeature(
    releaseNoteFeatureId: number,
    releaseNoteFeature: ReleaseNoteFeature,
    fields?: string[]
  ): Observable<ReleaseNoteFeature> {
    return this.http
      .put(
        `${this.releaseNoteFeaturesUrl}/${releaseNoteFeatureId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        releaseNoteFeature
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedReleaseNoteFeature: ReleaseNoteFeature = result.data['release notes features'];
          return updatedReleaseNoteFeature;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteReleaseNoteFeature(releaseNoteFeatureId: number): Observable<void> {
    return this.http.delete(`${this.releaseNoteFeaturesUrl}/${releaseNoteFeatureId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getReleaseNoteReleases(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ReleaseNoteRelease[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.releaseNoteReleasesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const releaseNoteReleases: ReleaseNoteRelease[] = result.data.release_notes_releases;
          return releaseNoteReleases;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getReleaseNoteReleaseById(id, fields: string[]): Observable<ReleaseNoteRelease> {
    return this.http.get(`${this.releaseNoteReleasesUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const releaseNoteReleases: ReleaseNoteRelease[] = result.data['release notes releases'];
        return releaseNoteReleases[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createReleaseNoteRelease(releaseNoteRelease: ReleaseNoteRelease): Observable<ReleaseNoteRelease> {
    return this.http.post(this.releaseNoteReleasesUrl, releaseNoteRelease).pipe(
      map((result: ServiceResponse) => {
        const createdReleaseNoteRelease: ReleaseNoteRelease = result.data['release notes releases'];
        return createdReleaseNoteRelease;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateReleaseNoteRelease(
    releaseNoteReleaseId: number,
    releaseNoteRelease: ReleaseNoteRelease,
    fields?: string[]
  ): Observable<ReleaseNoteRelease> {
    return this.http
      .put(
        `${this.releaseNoteReleasesUrl}/${releaseNoteReleaseId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        releaseNoteRelease
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedReleaseNoteRelease: ReleaseNoteRelease = result.data['release notes releases'];
          return updatedReleaseNoteRelease;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteReleaseNoteRelease(releaseNoteReleaseId: number): Observable<void> {
    return this.http.delete(`${this.releaseNoteReleasesUrl}/${releaseNoteReleaseId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
