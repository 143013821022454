import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserType } from 'src/app/enums';
import { User } from 'src/app/types';
import { UserSelectModalComponent } from '..';

@Component({
  selector: 'app-add-followers',
  templateUrl: './add-followers.component.html',
  styleUrls: ['./add-followers.component.scss'],
})
export class AddFollowersComponent implements OnInit {
  @Input() displayTitle = 'Followers';
  @Input() height = 40;
  @Input() saveOnClose: boolean | string = true; // Help deal with seperating DB data and RAM data
  @Input() width = 40;
  @Input() allowedUserTypeIds;
  @Input() defaultUserTypeId = UserType.Tenant;
  public followers = new FormControl([]);

  constructor(private _dialog: MatDialog) {}

  ngOnInit(): void {
    // Because of string problems,
    // I rebase the boolean here
    // If you pass it within html, its passed as a string
    // If you pass it in ts, it comes as boolean
    if (
      (typeof this.saveOnClose === 'string' && this.saveOnClose === 'false') ||
      (typeof this.saveOnClose === 'boolean' && this.saveOnClose === false)
    ) {
      this.saveOnClose = false;
    } else {
      // All other cases
      this.saveOnClose = true;
    }
  }

  get listOfFollowers(): User[] {
    return this.followers?.value || [];
  }

  public add() {
    this._dialog
      .open(UserSelectModalComponent, {
        data: {
          preSelectedUsers: [...this.listOfFollowers],
          allowedUserTypeIds: this.allowedUserTypeIds,
          saveOnClose: this.saveOnClose,
          createUser: { title: 'Guest', guestUser: false },
          excludeVendors: true,
          defaultUserTypeId: this.defaultUserTypeId,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(({ selectedUsers }) => {
        if (selectedUsers) {
          this.followers.setValue(selectedUsers);
        }
      });
  }

  public remove(follower: User) {
    this.followers.setValue(
      this.listOfFollowers?.filter((existingFollower: User) => existingFollower.id !== follower.id)
    );
  }
}
