import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { sumBy, uniqBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ArfPurchaseTypeId, ArfStatus, Order, ResourceType, TaskStatus } from 'src/app/enums';
import { DateService, FileService, ModalService, ProgressIndicatorService, TaskActionsService } from 'src/app/services';
import { ApiFilterService } from 'src/app/services/api-filter.service';
import { HandleErrorService } from 'src/app/services/handle-error.service';
import {
  APIFilter,
  Arf,
  ArfCustodyChain,
  ArfInvoiceAmount,
  ArfProduct,
  Project,
  ProjectTenant,
  Quote,
  ServiceResponse,
  SubCostCodeBudget,
  Task,
  TaskAccessoryData,
} from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArfsService {
  host: string = environment.serviceHost;
  arfUrl = `${this.host}/api/v1/arfs`;
  arfCustodyChainField = 'custody_chain{wm_id,ac_id,ico_id,ocd_id,bsd_id,cto_id,cfmo_id,ceo_id,dfs_required}';
  arfModuleField = 'module{workspace_type_id,needs_dfs_approval,show_dfs_toggle}';
  arfCompanyField = 'company{id,name,verification_status}';
  arfStatusField = 'arf_status{id,name}';
  arfReviewTaskField = 'review_task{accessory_data,status_id,is_locked}';
  arfFields = [
    'title',
    'code',
    'description',
    this.arfCompanyField,
    'asset_tag',
    'tag_asset_task_id',
    'purchase_type',
    'purchase_task_id',
    'quote_file_id',
    'quote_file',
    'module_id',
    this.arfModuleField,
    'status_id',
    this.arfStatusField,
    'review_task_id',
    this.arfReviewTaskField,
    'revision',
    'fiscal_year',
    this.arfCustodyChainField,
    'products{name,description,quantity,unit_price,total_price,product_id,module_id,sub_cost_code_budget_id,sub_cost_code_budget{label,code,cost_code{label,code}}}',
    'invoices{invoice_date,invoice_end_date,total,number,approval_task_id,approval_task_accessory_data,status_id,revision,files,is_internally_funded,company_name,timeframe_name,arf_invoice_amounts{sub_cost_code_budget{code,label,cost_code{label,code}},amount},created_by_id}',
    'created_by{first_name,last_name,id}',
    'created_datetime',
  ];

  arfViewTaskFields = [
    'status_id',
    this.arfReviewTaskField,
    'purchase_type_id',
    'purchase_task_id',
    'asset_tag',
    'tag_asset_task_id',
    'custody_chain{bsd_id,wm_id,ico_id}',
    'code',
    this.arfCompanyField,
  ];
  arfListFields = ['title', 'code', 'module_id', 'purchase_type_id', 'created_by_id'];
  arfCustodyChainUrl = `${this.host}/api/v1/arf-custody-chain`;
  arfCustodyChainFields = ['id', 'arf_id', 'wm_id', 'ac_id', 'ico_id', 'bsd_id', 'cto_id', 'cfmo_id', 'ceo_id'];
  arfInvoiceAmountUrl = `${this.host}/api/v1/arf-invoice-amounts`;
  arfInvoiceAmountFields = [
    'id',
    'invoice_id',
    'amount',
    'sub_cost_code_budget_id',
    'sub_cost_code_budget{code,label,sub_cost_code_id,sub_cost_code{fiscal_year},cost_code{label,code}}',
  ];
  public refreshNeeded$ = new Subject<void>();
  public arfInvoiceAmountUpdated = new EventEmitter<ArfInvoiceAmount>();
  public arfInvoiceAmountDeleted = new EventEmitter<ArfInvoiceAmount>();
  public arfInvoiceAmountValueChanged = new EventEmitter<ArfInvoiceAmount>();

  constructor(
    private dateService: DateService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private taskActionsService: TaskActionsService
  ) {}

  getArfs(
    apiFilters: APIFilter[],
    fields?: string[],
    sortField = 'created_datetime',
    sortOrder: Order = Order.ASC
  ): Observable<Arf[]> {
    fields = fields || this.arfFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(`${this.arfUrl}?fields=${fields.join(',')}&${filterString}&limit=10000&sort=${sortField}&order=${sortOrder}`)
      .pipe(
        map((result: ServiceResponse) => {
          const arfs: Arf[] = result.data.arfs.map((arf) => ({ ...arf, arf_source: 'arfs' }));
          return arfs;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getLegacyArfs(): Observable<Arf[]> {
    function getArfStatusFromLegacyArf(
      budget_status_id: number,
      arf_task_status_id: number,
      project_status_id: number
    ): { id: number; name: string } {
      const isProjectClosed = project_status_id === 4;
      const isLegacyArfFinalized = budget_status_id === 2;
      const isApproved = arf_task_status_id === 3;
      const inReview = !!arf_task_status_id && !isApproved;
      const inDraft = !inReview && !isApproved;

      if (isProjectClosed) {
        return { id: 5, name: 'Closed' };
      } else if (isLegacyArfFinalized) {
        return { id: 4, name: 'Finalized' };
      } else if (isApproved) {
        return { id: 3, name: 'Approved' };
      } else if (inReview) {
        return { id: 2, name: 'In Review' };
      } else if (inDraft) {
        return { id: 1, name: 'Draft' };
      }
    }

    return this.http
      .get<
        {
          project_id: number;
          project_code: number;
          department_name: string;
          fiscal_year: number | null;
          product_names: string;
          company_name: string;
          module_id: number;
          module_name: string;
          created_datetime: string;
          total_price: string;
          budget_status_id: number;
          arf_task_status_id: number;
          project_status_id: number;
          sub_cost_codes: string;
        }[]
      >(`${this.arfUrl}/legacy-arfs`)
      .pipe(
        map((legacyArfs) => {
          const arfs: Arf[] = legacyArfs.map((legacyArf, index) => {
            const products: ArfProduct[] = legacyArf.total_price.split(',').map((total_price, index) => {
              return {
                total_price: +total_price,
                sub_cost_code_budget: { sub_cost_code: { code: legacyArf.sub_cost_codes.split(',')[index] } },
              };
            });

            return {
              id: null,
              parent_id: legacyArf.project_id,
              code: `${legacyArf.project_code} - ${legacyArf.department_name}`,
              fiscal_year: legacyArf.fiscal_year,
              title: legacyArf.product_names?.split(',').sort().join(', '),
              description: '',
              products,
              company: { name: legacyArf.company_name },
              module: {
                id: legacyArf.module_id,
                name: legacyArf.module_name,
              },
              created_datetime: legacyArf.created_datetime,
              arf_status: getArfStatusFromLegacyArf(
                legacyArf.budget_status_id,
                legacyArf.arf_task_status_id,
                legacyArf.project_status_id
              ),
              arf_source: 'quotes',
            };
          });
          return arfs;
        })
      );
  }

  public getArfById(id: number, fields?: string[]): Observable<Arf> {
    return this.http
      .get(`${this.arfUrl}/${id}?fields=${fields?.length ? fields.join(',') : this.arfFields.join(',')}`)
      .pipe(
        map((result: ServiceResponse) => {
          const arfs: Arf[] = result.data['arfs'];
          return arfs[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createArf(arf: Arf, fields: string[]): Observable<Arf> {
    return this.http.post(`${this.arfUrl}?fields=${fields.join(',')}`, arf).pipe(
      map((result: ServiceResponse) => {
        const arfToReturn: Arf = result.data['arfs'];
        return arfToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateArf(arfId: number, arf: Arf, fields?: string[]): Observable<Arf> {
    return this.http.put(`${this.arfUrl}/${arfId}?${fields ? `fields=${fields.join(',')}` : ''}`, arf).pipe(
      map((result: ServiceResponse) => {
        const arfToReturn: Arf = result.data['arfs'];
        return arfToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  async finalizeArf(arf: Arf, accessoryData: TaskAccessoryData): Promise<Arf | void> {
    let updatedArf: Arf;
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize ARF',
        headerText: 'Finalize ARF',
        confirmationButtonText: 'Finalize ARF',
        descriptionText: 'Are you sure you want to finalize this ARF?',
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Finalizing ARF..');
      let arfData: Arf = {
        status_id: ArfStatus.Finalized,
      };
      if (arf.review_task?.status_id !== TaskStatus.Complete) {
        await this.taskActionsService.projectService
          .updateTask({
            id: arf.review_task_id,
            status_id: TaskStatus.Complete,
            is_locked: 1,
          })
          .toPromise();
      }

      this.progressIndicatorService.updateStatus('Updating Purchase Type Task...');
      arfData = { ...arfData, ...((await this.changePurchaseTask(arf, null, accessoryData)) || {}) };

      this.progressIndicatorService.updateStatus('Updating Tag Asset Task...');
      arfData = { ...arfData, ...((await this.changeTagAssetTask(arf, null, accessoryData)) || {}) };

      this.progressIndicatorService.updateStatus('Updating ARF..');
      updatedArf = await this.updateArf(arf.id, arfData, [
        'status_id',
        'purchase_task_id',
        'tag_asset_task_id',
        this.arfStatusField,
      ]).toPromise();
      this.progressIndicatorService.close();
    }

    return updatedArf;
  }

  public async finalizePurchasePage(tenant: ProjectTenant, project: Project): Promise<boolean> {
    const isConfirmed: boolean = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize & Award Bids',
        headerText: 'Finalize & Award Bids',
        descriptionText:
          'Finalizing this purchase will award the selected bids AND give awarded suppliers access to the project. Do you want to continue?',
        confirmationButtonText: 'Finalize',
      })
      .toPromise();

    if (isConfirmed) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Finalizing Budget...');

      if (!tenant.selected_quotes) {
        tenant.selected_quotes = this.taskActionsService.productService.returnSelectedQuotes(tenant);
      }

      if (!project && tenant?.project_id) {
        project = await this.taskActionsService.projectService.getProjectById(tenant.project_id).toPromise();
      }

      for (const quote of tenant.selected_quotes) {
        for (const qi of quote.quote_items) {
          if (qi.project_product?.selected_quote_item_id === qi.id) {
            await this.taskActionsService.productService.updateQuoteItem(qi.id, { is_awarded: 1 }).toPromise();
          }
        }

        const taskData: Task = {
          id: quote.arf_approval_task_id,
          is_locked: 1,
        };

        if (quote.arf_approval_task?.status_id !== TaskStatus.Complete) {
          taskData.status_id = TaskStatus.Complete;
        }

        await this.taskActionsService.projectService.updateTask(taskData).toPromise();

        const accessoryData =
          quote?.arf_approval_task?.accessory_data && JSON.parse(quote.arf_approval_task.accessory_data);

        let quoteData: Quote;
        const purchaseTaskId = (await this.changePurchaseTask(null, quote, accessoryData, project)) || null;
        if (purchaseTaskId?.purchase_task_id && purchaseTaskId?.purchase_task_id !== quote.purchase_task_id) {
          quoteData = {};
          quoteData.purchase_task_id = purchaseTaskId?.purchase_task_id;
          quote.purchase_task_id = purchaseTaskId?.purchase_task_id;
        }
        const assetTagTaskId = (await this.changeTagAssetTask(null, quote, accessoryData, project)) || null;
        if (assetTagTaskId?.tag_asset_task_id && assetTagTaskId?.tag_asset_task_id !== quote.tag_asset_task_id) {
          quoteData = (!quoteData && {}) || quoteData;
          quoteData.tag_asset_task_id = assetTagTaskId?.tag_asset_task_id;
          quote.tag_asset_task_id = assetTagTaskId?.tag_asset_task_id;
        }
        this.taskActionsService.productService.finalizePurchaseEvent.emit(tenant);

        if (quoteData) {
          await this.taskActionsService.productService.updateQuote(quote.id, quoteData).toPromise();
        }
      }

      await this.taskActionsService.productService.projectTenantService
        .updateProjectTenant(tenant.id, { budget_status_id: 2 })
        .toPromise();
    }

    this.progressIndicatorService.close();
    return isConfirmed;
  }

  async changePurchaseTask(
    arf: Arf = null,
    quote: Quote = null,
    accessoryData: TaskAccessoryData,
    project: Project = null
  ): Promise<Arf | void> {
    let taskData: Task = {};
    const purchaseTypeId = arf?.purchase_type_id || quote?.arf_purchase_type_id;
    const assignedUser = arf?.custody_chain.bsd_id || project?.bsd_id;
    const code = arf?.code || quote?.id;
    const company = arf?.company || quote?.company;
    const purchaseTaskId = arf?.purchase_task_id || quote?.purchase_task_id;

    if (purchaseTypeId !== ArfPurchaseTypeId.Vendor) {
      taskData = {
        due_date: this.dateService.addWeekdays(2).format('YYYY-MM-DD'),
        assigned_user_id: assignedUser,
        is_locked: 0,
      };

      taskData.title =
        purchaseTypeId === ArfPurchaseTypeId.PurchaseOrder
          ? `Create and Upload Purchase Order for ${code}: ${company?.name}`
          : `Complete Credit Card Purchase for ${code}: ${company?.name} and upload receipt information`;
    } else {
      taskData.status_id = TaskStatus.Complete;
      taskData.is_locked = 1;
    }

    let newTask: Task;
    if (purchaseTaskId) {
      taskData.id = purchaseTaskId;
      await this.taskActionsService.projectService.updateTask(taskData).toPromise();

      const message =
        purchaseTypeId === ArfPurchaseTypeId.Vendor
          ? '<p>Arf purchase type has been switched to "Vendor Will Invoice".</p> <p>No further action is needed</p>'
          : 'ARF related files';
      const note = await this.taskActionsService.projectService
        .createNote(ResourceType.Task, purchaseTaskId, message)
        .toPromise();
      if (purchaseTypeId !== ArfPurchaseTypeId.Vendor) {
        await this.fileService.addFilesToNote(note, purchaseTaskId, [], accessoryData.reviewFiles);
      }
    } else if (purchaseTypeId !== ArfPurchaseTypeId.Vendor) {
      if (arf?.id) {
        taskData.parent_id = arf?.id;
        taskData.parent_resource_type_id = ResourceType.AcquisitionRequestForm;
      } else {
        const phaseInfo = await this.taskActionsService.projectService.getPhaseInfo(project.id, 'Production');
        taskData.milestone_id = phaseInfo.milestoneId;
      }

      newTask = await this.taskActionsService.createTaskAndAttachFiles(
        taskData,
        `ARF related files`,
        [],
        accessoryData.reviewFiles,
        []
      );
    }

    return newTask?.id && { purchase_task_id: newTask.id || purchaseTaskId || null };
  }

  async changeTagAssetTask(
    arf: Arf = null,
    quote: Quote = null,
    accessoryData: TaskAccessoryData,
    project: Project = null
  ): Promise<Arf | void> {
    let taskData: Task = {};
    const assetTag = arf?.asset_tag || quote?.asset_tagged;
    const tagAssetTaskId = arf?.tag_asset_task_id || quote?.tag_asset_task_id;
    const assignedUser = arf?.custody_chain?.ico_id || arf?.custody_chain?.wm_id || project.project_manager_id;
    const code = arf?.code || quote?.id;
    const company = arf?.company || quote?.company;

    if (assetTag) {
      taskData = {
        title: `Receive and tag asset(s) for ARF ${code}: ${company?.name}`,
        due_date: this.dateService.addWeekdays(2).format('YYYY-MM-DD'),
        assigned_user_id: assignedUser,
        is_locked: 0,
      };
    } else {
      taskData.status_id = TaskStatus.Complete;
      taskData.is_locked = 1;
    }

    let newTask: Task;
    if (tagAssetTaskId) {
      taskData.id = tagAssetTaskId;
      await this.taskActionsService.projectService.updateTask(taskData).toPromise();
      const message = !assetTag
        ? '<p>The ARF asset no longer needs to be tagged.</p> <p>No further action is needed</p>'
        : 'ARF related files';
      const note = await this.taskActionsService.projectService
        .createNote(ResourceType.Task, tagAssetTaskId, message)
        .toPromise();

      if (assetTag) {
        await this.fileService.addFilesToNote(note, tagAssetTaskId, [], accessoryData.reviewFiles);
      }
    } else if (assetTag) {
      if (arf?.id) {
        taskData.parent_id = arf?.id;
        taskData.parent_resource_type_id = ResourceType.AcquisitionRequestForm;
      } else {
        const phaseInfo = await this.taskActionsService.projectService.getPhaseInfo(project.id, 'Production');
        taskData.milestone_id = phaseInfo.milestoneId;
      }

      newTask = await this.taskActionsService.createTaskAndAttachFiles(
        taskData,
        `ARF related files`,
        [],
        accessoryData.reviewFiles,
        []
      );
    }

    return newTask?.id && { tag_asset_task_id: newTask.id || tagAssetTaskId || null };
  }

  // Arf Custody Chain
  createArfCustodyChain(arfCustodyChain: ArfCustodyChain, fields: string[]): Observable<ArfCustodyChain> {
    return this.http.post(`${this.arfCustodyChainUrl}?fields=${fields.join(',')}`, arfCustodyChain).pipe(
      map((result: ServiceResponse) => {
        const arfCustodyChainToReturn: ArfCustodyChain = result.data['arf custody chain'];
        return arfCustodyChainToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateArfCustodyChain(
    arfCustodyChainId: number,
    arfCustodyChain: ArfCustodyChain,
    fields?: string[]
  ): Observable<ArfCustodyChain> {
    return this.http
      .put(
        `${this.arfCustodyChainUrl}/${arfCustodyChainId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        arfCustodyChain
      )
      .pipe(
        map((result: ServiceResponse) => {
          const arfCustodyChainToReturn: ArfCustodyChain = result.data['arf custody chain'];
          return arfCustodyChainToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getArfInvoiceAmounts(apiFilters: APIFilter[], fields?: string[]): Observable<ArfInvoiceAmount[]> {
    fields = fields || this.arfInvoiceAmountFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.arfInvoiceAmountUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const arfInvoiceAmounts: ArfInvoiceAmount[] = result.data.arf_invoice_amounts;
        return arfInvoiceAmounts;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getArfInvoiceAmountsAndBudgets(
    products: any[],
    arfInvoiceAmounts: ArfInvoiceAmount[] = []
  ): { subCostCodeBudgets: SubCostCodeBudget[]; arfInvoiceAmounts: ArfInvoiceAmount[] } {
    let subCostCodeBudgets = [];

    if (products?.length || arfInvoiceAmounts?.length) {
      const productSubCostCodeBudgets =
        (products?.length && products?.map((p) => p?.sub_cost_code_budget)) ||
        arfInvoiceAmounts?.map((a) => a.sub_cost_code_budget) ||
        [];
      subCostCodeBudgets = uniqBy(productSubCostCodeBudgets, 'id');
      const allArfInvoiceAmounts: ArfInvoiceAmount[] = [];
      let index = 0;
      for (const s of subCostCodeBudgets) {
        if (s?.id) {
          if (products?.length) {
            const subCostCodeProducts = products.filter((p) => p.sub_cost_code_budget_id === s.id);
            s.arf_sub_cost_code_total = sumBy(subCostCodeProducts, 'total_price');
          }

          const arfInvoiceAmount = arfInvoiceAmounts?.find((a) => a.sub_cost_code_budget_id === s?.id) || {
            index,
            sub_cost_code_budget_id: s.id,
            sub_cost_code_budget: s,
            amount: null,
          };

          allArfInvoiceAmounts.push(arfInvoiceAmount);
          index++;
        }
      }

      arfInvoiceAmounts = allArfInvoiceAmounts;
    }

    return { subCostCodeBudgets, arfInvoiceAmounts };
  }

  createArfInvoiceAmount(arfInvoiceAmount: ArfInvoiceAmount, fields?: string[]): Observable<ArfInvoiceAmount> {
    fields = fields || this.arfInvoiceAmountFields;

    return this.http.post(`${this.arfInvoiceAmountUrl}?fields=${fields.join(',')}`, arfInvoiceAmount).pipe(
      map((result: ServiceResponse) => {
        const arfInvoiceAmountToReturn: ArfInvoiceAmount = result.data['arf nvoice amounts'];
        return arfInvoiceAmountToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateArfInvoiceAmount(
    arfInvoiceAmountId: number,
    arfInvoiceAmount: ArfInvoiceAmount,
    fields?: string[]
  ): Observable<ArfInvoiceAmount> {
    return this.http
      .put(
        `${this.arfInvoiceAmountUrl}/${arfInvoiceAmountId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        arfInvoiceAmount
      )
      .pipe(
        map((result: ServiceResponse) => {
          const arfInvoiceAmountToReturn: ArfInvoiceAmount = result.data['arf invoice amounts'];
          return arfInvoiceAmountToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public deleteArfInvoiceAmount(arfInvoiceAmountId: number): Observable<void> {
    return this.http.delete(`${this.arfInvoiceAmountUrl}/${arfInvoiceAmountId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
