<div
  data-test-id="page-not-found"
  id="page-not-found"
  class="page-not-found d-flex flex-column justify-content-center align-items-center"
>
  <!--NOT AUTHORIZED-->
  <h1 *ngIf="status === found_page.NOT_AUTHORIZED" class="dkblue mb-3">Access Denied</h1>
  <h4 *ngIf="status === found_page.NOT_AUTHORIZED" class="dkgray font-weight-normal mb-5">
    You don't have the necessary permissions to view this page.
  </h4>

  <!--NOT FOUND-->
  <h1 *ngIf="status === found_page.NOT_FOUND" class="dkblue mb-3">Page Not Found</h1>
  <h4 *ngIf="status === found_page.NOT_FOUND" class="dkgray font-weight-normal mb-5">
    If the link is valid, you may not have the necessary permissions to view this page.
  </h4>

  <!--NOT FOUND-->
  <h1 *ngIf="status === found_page.UN_KNOWN" class="dkblue mb-3">Unknown error</h1>
  <h4 *ngIf="status === found_page.UN_KNOWN" class="dkgray font-weight-normal mb-5">
    We couldn't find what you're looking for.
  </h4>
  <h5>
    <a class="ltblue font-weight-normal" href="/home">Return Home</a>
  </h5>
</div>
