<!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->
<div id="meeting" data-test-id="meeting" class="meeting container-fluid app-container meeting-container p-4">
  <div class="d-flex align-items-center border-bottom border-gray pb-4">
    <h3 class="m-0 font-weight-semibold dkblue mr-3">
      {{ shownMeeting ? shownMeeting.title : '' }} &nbsp;
      <small class="dkgray">
        <!-- {{ shownMeeting ? shownMeeting.code : '' }} -->
        MT-{{ shownMeeting ? shownMeeting.id : '' }}
      </small>
    </h3>
    <button mat-button (click)="openExportMeetingDialog()" class="ml-auto" color="accent">
      <span *ngIf="!downloading">Download Notes</span>
      <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="downloading"></i>
    </button>
    <button
      mat-stroked-button
      (click)="concludeMeeting()"
      class="ml-3 bg-white"
      color="accent"
      *ngIf="authService.isStaffOnAnyModule && !isConcluded"
    >
      Conclude Meeting
    </button>
    <button (click)="openAddAgendaItemDialog()" mat-flat-button class="ml-3" color="success" *ngIf="!isConcluded">
      + Agenda Item
    </button>
  </div>

  <div class="d-flex flex-row align-items-center mt-4 mb-3">
    <div class="d-flex align-items-center col-sm-12 col-md-4 col-xl-3 pr-4">
      <div (click)="details_expanded = !details_expanded" class="d-flex align-items-center pointer mr-5">
        <mat-icon class="primary mr-1">{{ details_expanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <h5 class="m-0 mr-auto">Meeting Details</h5>
      </div>
      <button
        (click)="openEditMeetingDialog()"
        *ngIf="isCreatorOrFacilitator && !isConcluded"
        mat-button
        color="accent"
        class="lh-30 px-3"
      >
        Edit Meeting
      </button>
    </div>
    <div class="d-flex align-items-end ml-4 col" *ngIf="agendaItems.length">
      <button mat-button type="button" class="lh-28 pl-2 gray ml-auto" (click)="expandAllAgendaItems()">
        <mat-icon>unfold_more</mat-icon> Expand All
      </button>
      <button mat-button type="button" class="lh-28 pl-2 gray" (click)="collapseAllAgendaItems()">
        <mat-icon>unfold_less</mat-icon> Collapse All
      </button>
    </div>
  </div>
  <div *ngIf="shownMeeting" class="flex-row d-flex justify-content-between">
    <div [hidden]="!details_expanded" class="col-sm-12 col-md-4 col-xl-3 pr-4">
      <div class="m-portlet p-0">
        <div class="m-portlet__body p-4 meeting-details">
          <div class="row">
            <div class="col meeting-objective">
              <div class="row mb-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Meeting Objective</h6>
                  <h5 class="dkblue m-0 font-weight-normal" [innerHTML]="shownMeeting.purpose"></h5>
                </div>
                <div class="col-3" *ngIf="isConcluded">
                  <h6 class="font-weight-semibold dkblue mb-2">Concluded On</h6>
                  <h5 class="dkgray font-weight-normal m-0">
                    {{ shownMeeting.concluded_datetime | date: 'MMM d yyyy' }}
                    {{ shownMeeting.concluded_datetime | date: 'h:mmaa' | lowercase }}
                  </h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Date/Time</h6>
                  <h5 class="dkblue m-0 font-weight-normal">
                    {{ shownMeeting.start_datetime | date: 'EEEE, MMM d yyyy' }}
                    <br />
                    {{ shownMeeting.start_datetime | date: 'h:mmaa' | lowercase }} -
                    {{ shownMeeting.end_datetime | date: 'h:mmaa' | lowercase }}
                  </h5>
                  <h6
                    *ngIf="shownMeeting.recurring_meeting_frequency_interval"
                    style="font-size: 0.7rem"
                    class="main-label border border-dkgray dkblue px-1 text-uppercase br-4 m-0 mt-2 lh-16"
                  >
                    Every{{
                      shownMeeting.recurring_meeting_frequency_number !== 1
                        ? ' ' + shownMeeting.recurring_meeting_frequency_number
                        : ''
                    }}
                    {{ shownMeeting.recurring_meeting_frequency_interval | titlecase
                    }}{{ shownMeeting.recurring_meeting_frequency_number !== 1 ? 's' : '' }}
                  </h6>
                </div>
              </div>
              <div *ngIf="shownMeeting.workspace_name" class="row mt-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Workspace</h6>
                  <h5 class="dkblue m-0 font-weight-normal">
                    {{ shownMeeting.workspace_name }}
                  </h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Location</h6>
                  <h5 class="dkblue font-weight-normal mb-2">
                    {{ shownMeeting.location }}
                  </h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Duration</h6>
                  <h5 class="m-0 dkblue">
                    Meeting:
                    <span class="font-weight-normal dkgray">
                      {{ meetingLength | formatMeetingTime }}
                    </span>
                  </h5>
                  <h5 class="m-0 dkblue">
                    Agenda:
                    <span *ngIf="agendaLength" class="font-weight-normal dkgray">
                      {{ agendaLength | formatMeetingTime }}
                    </span>
                    <span *ngIf="!agendaLength" class="font-weight-normal dkgray"> 0 mins </span>
                    <small
                      *ngIf="agendaMeetingDiff.diff"
                      [ngClass]="{
                        green: agendaMeetingDiff.over,
                        red: !agendaMeetingDiff.over
                      }"
                      >( {{ !agendaMeetingDiff.over ? '+' : '-'
                      }}{{ agendaMeetingDiff.diff | formatMeetingTime }} )</small
                    >
                  </h5>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h6 class="font-weight-semibold dkblue mb-2">Attendees:</h6>
                  <div class="meeting-attendees d-flex align-items-center flex-wrap">
                    <app-profile-thumbnail
                      [matMenuTriggerFor]="user_sub_menu"
                      *ngFor="let a of attendees"
                      [width]="36"
                      [height]="36"
                      [userId]="a.user_id"
                      class="mr-2 mb-2 pointer"
                      [matTooltip]="a.first_name + ' ' + a.last_name"
                      matTooltipPosition="above"
                      [matTooltipShowDelay]="300"
                      [overlayIconOpacity]="1"
                      [overlayIcon]="a.attendee_type_id === 1 ? 'star' : ''"
                      [overlayIconColor]="'white'"
                      [overlayIconBackground]="'bg-gray'"
                    >
                      <mat-menu #user_sub_menu="matMenu">
                        <button
                          mat-menu-item
                          *ngIf="
                            a.attendee_type_id === 3 &&
                            isCreatorOrFacilitator &&
                            a.user_id !== currentUser.id &&
                            a.user_id !== shownMeeting.created_by_id &&
                            !isConcluded
                          "
                          color="primary"
                          (click)="updateAttendeeRole(a, 1)"
                        >
                          <mat-icon>star</mat-icon>
                          <span> Make Facilitator </span>
                        </button>
                        <button
                          mat-menu-item
                          *ngIf="
                            a.attendee_type_id === 1 &&
                            isCreatorOrFacilitator &&
                            a.user_id !== currentUser.id &&
                            a.user_id !== shownMeeting.created_by_id &&
                            !isConcluded
                          "
                          color="primary"
                          (click)="updateAttendeeRole(a, 3)"
                        >
                          <mat-icon>star_border</mat-icon>
                          <span> Remove Facilitator Access </span>
                        </button>
                        <button mat-menu-item color="primary" [routerLink]="['/profile/' + a.user_id]">
                          <mat-icon>person</mat-icon>
                          <span> View Profile </span>
                        </button>
                        <button
                          mat-menu-item
                          color="primary"
                          (click)="removeAttendee(a)"
                          *ngIf="
                            a.attendee_type_id === 3 &&
                            !isConcluded &&
                            (isCreatorOrFacilitator || +a?.user_id === +currentUser?.id)
                          "
                        >
                          <mat-icon>delete</mat-icon>
                          <span> Remove Attendee </span>
                        </button>
                      </mat-menu>
                    </app-profile-thumbnail>
                  </div>
                  <button
                    mat-stroked-button
                    class="mt-2 mr-2"
                    color="accent"
                    (click)="openBulkSelectModal()"
                    *ngIf="authService.isStaffOnAnyModule && !isConcluded"
                  >
                    Manage Attendees
                  </button>
                  <button
                    *ngIf="authService.isStaffOnAnyModule && showAddMyselfButton"
                    type="button"
                    color="accent"
                    mat-button
                    (click)="handleAddMyself()"
                  >
                    Add Myself<span *ngIf="isFiveMinutesBeforeUntilEnd" class="pulse-after-dot-success"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- col-sm-12 col-md-4 col-xl-3 -->
    <div class="ease pl-2" [ngClass]="{ 'col-xl-9 col-md-8 col-sm-12': details_expanded, 'col-12': !details_expanded }">
      <div
        *ngIf="showNotification && shownMeeting.type_id === 3"
        class="m-portlet bg-transparent border border-ltblue p-0 mx-2 mb-3"
      >
        <div class="m-portlet__body pl-4 pr-4 pt-3 pb-3">
          <div class="row align-items-center">
            <div class="col">
              <a [routerLink]="['/requests']">
                <h5 class="m-0 ltblue font-weight-semibold pointer">
                  <span class="number-badge mr-3 bg-ltblue white align-middle">
                    {{ pendingRequestCount }}
                  </span>
                  <span class="d-inline-block align-middle"> Pending Construction Requests </span>
                </h5>
              </a>
            </div>
            <div class="col-auto text-right">
              <a (click)="hideNotification()">
                <h6 class="m-0 gray font-weight-semibold pointer">Dismiss</h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="showOverdueNotification && meetingIsPastEndTime && !isConcluded"
        class="m-portlet bg-transparent border-red p-0 mb-3 mx-2"
      >
        <div class="m-portlet__body pl-4 pr-4 pt-3 pb-3">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="m-0 red font-weight-semibold">
                <i class="material-icons red mr-3 align-middle">announcement</i>
                <span class="d-inline-block align-middle">
                  Meeting notes must be sent out by:
                  <span class="ml-2">{{ meetingDueDate }}</span>
                </span>
              </h5>
            </div>
            <div class="col-auto text-right">
              <a (click)="hideOverdueNotification()">
                <h6 class="m-0 red font-weight-semibold pointer">Dismiss</h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col"
          cdkDropList
          [cdkDropListConnectedTo]="[agendaList]"
          #agendaList="cdkDropList"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            cdkDrag
            [cdkDragDisabled]="isConcluded || !(authService.isStaffOnAnyModule || authService.isAppAdmin)"
            *ngFor="let i of agendaItems | agendaFilter; let index = index"
            class="p-0 pb-4"
          >
            <div class="agenda-placeholder" *cdkDragPlaceholder></div>
            <div class="m-portlet__body p-2">
              <div class="row align-items-center m-0 py-2 bg-shade-gray br-10">
                <div class="col-auto pr-0">
                  <h4
                    *ngIf="agendaItems.length > 1"
                    class="dkblue m-0 ml-3 d-inline-block align-middle font-weight-semibold"
                  >
                    {{ index + 1 }}.
                  </h4>
                  <button (click)="i.is_expanded = !i.is_expanded" mat-icon-button color="primary" type="button">
                    <mat-icon *ngIf="i.is_expanded">expand_more</mat-icon>
                    <mat-icon *ngIf="!i.is_expanded">chevron_right</mat-icon>
                  </button>
                </div>
                <div class="col pl-0 d-flex flex-column">
                  <h4
                    (click)="i.is_expanded = !i.is_expanded"
                    class="font-weight-semibold dkblue pointer m-0 mr-4 hover-u"
                  >
                    <strong>
                      {{ i.description }}
                    </strong>
                  </h4>
                </div>
                <div class="col-auto d-flex align-items-center text-right">
                  <p *ngIf="i.duration" class="m-0 dkgray mr-3">
                    {{ i.duration }} Min{{ i.duration === 1 ? '' : 's' }}
                  </p>
                  <app-profile-thumbnail
                    (click)="openAssignUserDialog(i)"
                    class="pointer ml-1"
                    [width]="32"
                    [height]="32"
                    [userId]="i.assigned_user_id ? i.assigned_user_id : i.created_by_id"
                    matTooltip="{{ i.assigned_user_id ? i.assigned_user_first_name : i.created_by_first_name }} {{
                      i.assigned_user_id ? i.assigned_user_last_name : i.created_by_last_name
                    }}"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="300"
                  ></app-profile-thumbnail>
                  <button
                    cdkDragHandle
                    [ngClass]="{ 'mr-5': !canDeleteAgendaItem(i) || isConcluded }"
                    class="gray draggable ml-2"
                    mat-icon-button
                    color="secondary"
                    type="button"
                    matTooltip="Move Agenda Item"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="300"
                  >
                    <mat-icon>drag_handle</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="agenda_item"
                    color="primary"
                    *ngIf="canDeleteAgendaItem(i) && !isConcluded"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #agenda_item="matMenu" xPosition="before">
                    <button mat-menu-item (click)="openEditAgendaItemDialog(i)" color="primary">
                      <mat-icon> edit </mat-icon>
                      <span> Edit Agenda Item </span>
                    </button>
                    <button mat-menu-item (click)="deactivateAgendaItem(i)" color="primary">
                      <mat-icon> delete </mat-icon>
                      <span> Remove Agenda Item </span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div class="mb-3 text-center full-width">
              <p
                *ngIf="i.is_expanded && i.desc"
                (click)="gotoAgendaParentLink(i)"
                class="m-0 ltblue pointer ease hover-u d-block"
              >
                <span>
                  {{ i.desc }}
                </span>
                &nbsp;
                <span *ngIf="i.smallDesc"> >&nbsp; {{ i.smallDesc }} </span>
              </p>
            </div>
            <div *ngIf="i.is_expanded" class="pt-4">
              <div *ngFor="let n of i.notes" class="px-4 py-0">
                <div class="row">
                  <div class="col d-flex align-items-start note px-4">
                    <div class="px-4">
                      <app-profile-thumbnail [height]="24" [width]="24" [userId]="n.created_by_id">
                      </app-profile-thumbnail>
                    </div>
                    <div class="d-flex flex-column full-width">
                      <div [hidden]="+noteToEdit?.id === +n?.id">
                        <div class="d-flex align-items-center mb-2 dkblue note-details">
                          <span class="dkgray opacity6 font-weight-semibold m-0 mr-2 lh-20">
                            {{ n.created_by_first_name }} {{ n.created_by_last_name }}
                          </span>
                          <span
                            class="gray font-weight-normal lh-20 m-0 pr-3"
                            [ngClass]="{ 'border-right border-gray': n.created_by_id === currentUser.id }"
                          >
                            {{ n.created_datetime | date: 'MMM dd yyyy • h:mma' }}
                          </span>
                          <a
                            *ngIf="n.created_by_id === currentUser.id && !isConcluded"
                            (click)="i.newNote = false; editAgendaNote(n)"
                            class="gray ml-3 note-icon"
                            [matTooltip]="'Edit Note'"
                            matTooltipPosition="above"
                            [matTooltipShowDelay]="300"
                          >
                            <mat-icon> edit </mat-icon>
                          </a>
                          <a
                            *ngIf="(isCreatorOrFacilitator || n.created_by_id === currentUser.id) && !isConcluded"
                            (click)="deactivateAgendaNote(n.id)"
                            class="gray note-icon ml-3"
                            [matTooltip]="'Delete Note'"
                            matTooltipPosition="above"
                            [matTooltipShowDelay]="300"
                          >
                            <mat-icon> delete </mat-icon>
                          </a>
                        </div>
                        <div class="d-flex flex-column mb-5">
                          <p class="dkblue mb-0" [innerHTML]="n.message | htmlEncodeDecode"></p>
                          <mat-chip-list *ngIf="n.files && n.files.length > 0" class="mt-2">
                            <app-file-chip *ngFor="let file of n.files" [file]="file"></app-file-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <!-- editing existing note-->
                      <div *ngIf="+noteToEdit?.id === +n?.id" class="d-flex flex-column mb-5">
                        <app-meeting-agenda-item-note
                          [agendaItem]="i"
                          [existingAgendaItemFiles]="
                            i?.id && agendaItemFileTracker ? agendaItemFileTracker[i.id] : null
                          "
                          [editNote]="+noteToEdit?.id === +n?.id"
                          [note]="n"
                          (refreshMeeting)="refresh()"
                          [isConcluded]="isConcluded"
                          (resetEditNote)="noteToEdit = null"
                        ></app-meeting-agenda-item-note>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--New Note button or editor-->
              <div
                *ngIf="authService.isStaffOnAnyModule && (!noteToEdit || +noteToEdit?.id !== +n?.id)"
                class="m-portlet__body px-5 py-4"
              >
                <app-meeting-agenda-item-note
                  *ngIf="!noteToEdit"
                  [newNote]="i?.newNote"
                  [existingAgendaItemFiles]="i?.id && agendaItemFileTracker ? agendaItemFileTracker[i.id] : null"
                  [agendaItem]="i"
                  (refreshMeeting)="refresh()"
                  [isConcluded]="isConcluded"
                  (resetNewNote)="i.newNote = false"
                >
                </app-meeting-agenda-item-note>
                <button
                  *ngIf="!i?.newNote"
                  mat-flat-button
                  color="success"
                  (click)="i.newNote = true; noteToEdit = null"
                  class="mr-3 ml-5"
                >
                  + Notes to Agenda
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EXPORT: need to unhide all of these -->

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 55 }">
    <div class="form-print-pdf p-4" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="gray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="gray m-0">
                {{ reportDateTime | date: 'shortTime' }}<br />
                {{ reportDateTime | date: 'mediumDate' }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="gray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <section *ngIf="shownMeeting" class="form-header">
        <div class="row">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> Meeting Number </label>
                <p>
                  {{ shownMeeting ? shownMeeting.code : '' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Date & Time </label>
                <p>
                  {{ shownMeeting.start_datetime | date: 'd MMMM yyyy' }}
                  <br />
                  {{ shownMeeting.start_datetime | date: 'h:mmaa' | lowercase }} -
                  {{ shownMeeting.end_datetime | date: 'h:mmaa' | lowercase }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>
                  {{ shownMeeting.location }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section *ngIf="shownMeeting" class="form-title">
        <div class="row">
          <div class="col">
            <h1 class="form-title-left black font-weight-bold margin-0">
              Meeting Notes &nbsp;
              <small>
                {{ shownMeeting ? shownMeeting.title : '' }}
              </small>
            </h1>
          </div>
        </div>
      </section>
      <section *ngIf="shownMeeting" class="form-highlights">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <label class="main-label">Meeting Purpose</label>
                <p class="margin-0" [innerHTML]="shownMeeting.purpose"></p>
              </div>
            </div>
            <br />
            <!-- <div class="row">
                    <div class="col-12">
                      <div class="row form-border-divider"></div>
                    </div>
                  </div> -->
            <div class="row">
              <div class="col-3">
                <label class="main-label">Facilitator</label>
                <div *ngFor="let a of attendees | filter: '1':['attendee_type_id']">
                  <p class="margin-0">{{ a.first_name }} {{ a.last_name }}</p>
                </div>
              </div>
              <div class="col">
                <label class="main-label">Attendees</label>
                <div class="row">
                  <div class="col-4" *ngFor="let a of attendees | filter: '3':['attendee_type_id']">
                    <p class="margin-0">{{ a.first_name }} {{ a.last_name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-info-section pt-4" *ngFor="let i of agendaItemsToExport; let last = last">
        <div class="row">
          <div class="col-12">
            <div class="row mb-3">
              <div class="col-9 d-flex flex-column">
                <label class="main-label">Agenda Item</label>
                <h5 class="black font-weight-bold mb-3">
                  {{ i.description }}
                </h5>
                <div class="d-flex flex-column" *ngIf="i.parent_id">
                  <label class="main-label">Linked to</label>
                  <p class="m-0 dkblue pointer">
                    <span *ngIf="i.desc">
                      {{ i.desc }}
                    </span>
                    <span class="mx-2" *ngIf="i.smallDesc"> > </span>
                    <span *ngIf="i.smallDesc">
                      {{ i.smallDesc }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <section [ngClass]="{ 'form-inside-section': !last }">
              <div class="row align-items-stretch d-flex flex-row-reverse mb-3" *ngFor="let n of i.notes">
                <div class="col-3 align-self-stretch">
                  <label class="main-label">Created</label>
                  <p class="m-0">
                    <small>
                      {{ n.created_by_first_name }} {{ n.created_by_last_name }}
                      <br />
                      {{ n.created_datetime | date: 'dd MMMM yyyy' }}
                      <br />
                      {{ n.created_datetime | date: 'h:mma' | lowercase }}
                    </small>
                  </p>
                </div>
                <div class="col-9 align-self-stretch mr-auto">
                  <label class="main-label">Note</label>
                  <p [innerHTML]="n.message"></p>
                  <div *ngFor="let file of n.files; let index = index">
                    <div *ngIf="attachmentIds.includes(+file.id)">
                      <label *ngIf="index === 0" class="main-label">Attachments</label>
                      <p>{{ file.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
<div class="timer-wrapper">
  <app-timer [endDate]="shownMeeting?.end_datetime" [startDate]="shownMeeting?.start_datetime"></app-timer>
</div>
