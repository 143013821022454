<div id="kendo-content" *ngIf="exportingInvoice && (project || arf)">
  <kendo-pdf-export paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }" #coverLetter>
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header" style="height: 120px">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> {{ parentType }} </label>
                <p class="text-truncate">
                  {{ code }} | {{ project?.building_code || '' }}-{{ project?.floor_code || '' }} | {{ title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project.building_code }}-{{ project.floor_code }}</p>
              </div> -->
              <div class="col-4" *ngIf="exportingInvoice?.project_id">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row" *ngIf="exportingInvoice?.project_id">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p>
                  {{ !project.square_footage ? 'Not Given' : (project.square_footage | number) }}
                </p> -->
                <!-- MOVING TAGS TO MAIN CONTENT AREA TO EMPHASIZE MORE - ADAM - 03/2024 -->
                <!-- <label class="main-label mt-0"> Project Tags</label>
                <p>
                  <span *ngFor="let tag of project?.tags || []; let i = index">
                    {{ tag.abbreviation }}{{ i !== project?.tags?.length - 1 ? ',' : '' }}&nbsp;</span
                  >
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project?.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4" *ngIf="currentWorkspace === Workspace.Construction">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project?.architect_first_name }}
                  {{ project?.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-3">
            <div class="row mb-4" *ngIf="exportingInvoice.status_id === InvoiceStatus.ReadyForPayment">
              <div class="col">
                <label *ngIf="exportingInvoice?.processed_datetime" class="main-label mt-0">
                  {{ exportingInvoice?.processed_datetime | date: 'MMM dd, yyyy - h:mm aa' }}
                </label>
                <p class="m-0 d-flex flex-column">
                  <b *ngIf="exportingInvoice?.processed_by"
                    >{{ exportingInvoice.processed_by.first_name }} {{ exportingInvoice.processed_by.last_name }}</b
                  >
                  <span *ngIf="exportingInvoice?.processed_by?.title">{{ exportingInvoice?.processed_by?.title }}</span>
                  <span class="white bg-dkgreen font-weight-semibold mt-1 mb-2 px-2 py-1 br-5">
                    Ready For Payment
                  </span>
                </p>
              </div>
            </div>
            <div class="row mb-4" *ngFor="let r of exportingInvoice.reviews">
              <div class="col">
                <label *ngIf="r.status" class="main-label mt-0">
                  {{ r.created_datetime | date: 'MMM dd, yyyy - h:mm aa' }}
                </label>
                <div class="m-0">
                  <p>
                    <b>{{ r.firstName }} {{ r.lastName }}</b>
                  </p>
                  <p>
                    <span>{{ r.title }}</span>
                  </p>
                  <p>
                    <span class="gray font-weight-semibold mt-2 mb-2" *ngIf="r.status === TaskReviewStatus.Pending">
                      Pending
                    </span>
                  </p>
                  <p>
                    <span class="green font-weight-semibold mt-2 mb-2" *ngIf="r.status === TaskReviewStatus.Approved">
                      Approved
                    </span>
                  </p>
                  <p>
                    <span class="red font-weight-semibold mt-2 mb-2" *ngIf="r.status === TaskReviewStatus.Rejected">
                      Rejected
                    </span>
                  </p>
                  <div
                    [ngClass]="{
                      approved: r.status === TaskReviewStatus.Approved,
                      rejected: r.status === TaskReviewStatus.Rejected
                    }"
                    *ngIf="r.comment && r.status"
                    class="dkgray"
                    [innerHTML]="r.comment"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9">
            <div class="row mb-4 mt-5">
              <div class="col">
                <h4>
                  <b>
                    Invoice {{ exportingInvoice.number }} -
                    {{
                      currentWorkspace === Workspace.Construction
                        ? exportingInvoice.company_name
                        : exportingInvoice.title
                    }}
                  </b>
                </h4>
                <div class="d-flex align-items-start">
                  <p class="bg-gray white px-1 lh-14 mr-2 br-4" *ngFor="let tag of project?.tags || []; let i = index">
                    {{ tag.abbreviation }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <label class="main-label mt-0"> Supplier </label><br />
                <h6 class="m-0" *ngIf="currentWorkspace === Workspace.Construction" style="display: inline-block">
                  <b>{{ exportingInvoice.company_name }}</b
                  ><br />
                  <span *ngIf="exportingInvoice.bid_contact_id"
                    >C/O {{ exportingInvoice.bid_contact_first_name }}
                    {{ exportingInvoice.bid_contact_last_name }}</span
                  ><br *ngIf="exportingInvoice.bid_contact_id" />
                  <span *ngIf="exportingInvoice.bid_contact_office_phone || exportingInvoice.bid_contact_cell_phone">{{
                    exportingInvoice.bid_contact_office_phone || exportingInvoice.bid_contact_cell_phone
                  }}</span
                  ><br *ngIf="exportingInvoice.bid_contact_office_phone || exportingInvoice.bid_contact_cell_phone" />
                  <span *ngIf="exportingInvoice.bid_contact_id">{{ exportingInvoice.bid_contact_email }}</span>
                </h6>
                <h6 class="m-0" *ngIf="currentWorkspace !== Workspace.Construction" style="display: inline-block">
                  <b>{{ exportingInvoice.company_name }}</b>
                </h6>
                <span *ngIf="exportingInvoice?.quote?.description">
                  &nbsp;| {{ exportingInvoice?.quote?.description }}</span
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <p class="mb-2">Invoice Date</p>
                <p class="mb-2">Submitted Date</p>
                <p class="mb-2">Invoice For</p>
                <p class="mb-2">Invoice Tenant</p>
              </div>
              <div class="col">
                <p class="mb-2">
                  {{ invoiceDate }} {{ invoiceEndDate ? ' - ' + invoiceEndDate : '' }}
                  {{ exportingInvoice.fiscal_year ? ' (FY' + exportingInvoice.fiscal_year + ')' : '' }}
                </p>
                <p class="mb-2">{{ submittedDate }}</p>
                <p class="mb-2">
                  <span *ngIf="exportingInvoice.change_order_id">
                    CO #{{ exportingInvoice.change_order_local_index }}
                  </span>
                  <span *ngIf="!exportingInvoice.change_order_id"> Original Bid </span>
                </p>
                <p class="mb-2">
                  {{ exportingInvoice.timeframe_name }}
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-3">
                <p class="mb-2">Funding Source</p>
                <p class="mb-2">Payment</p>
                <p *ngIf="isConstructionWorkspace" class="mb-2">Invoice Amount</p>
                <p *ngIf="isConstructionWorkspace" class="mb-2">Retainage Withheld</p>
              </div>
              <div class="col">
                <p class="mb-2">{{ exportingInvoice.is_internally_funded ? 'UHAT' : exportingInvoice.tenant_name }}</p>
                <p class="mb-2">
                  {{
                    purchaseType === ArfPurchaseTypeId.Vendor
                      ? 'Send to Supplier'
                      : purchaseType === ArfPurchaseTypeId.CreditCard
                      ? 'Credit Card'
                      : 'Purchase Order'
                  }}
                </p>
                <p *ngIf="isConstructionWorkspace" class="mb-2">{{ +exportingInvoice.total | currency: 'USD' }}</p>
                <p *ngIf="isConstructionWorkspace" class="mb-2">
                  {{ exportingInvoice.retainage || 0 | currency: 'USD' }}
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-3">
                <h6 class="mb-2">
                  <b> AMOUNT DUE </b>
                </h6>
              </div>
              <div class="col">
                <h6 class="mb-2">
                  <b>{{
                    exportingInvoice.total -
                      (exportingInvoice.retainage || 0 + exportingInvoice.shipping || 0 + exportingInvoice.tax || 0)
                      | currency: 'USD'
                  }}</b>
                </h6>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col">
                <div *ngIf="exportingInvoice?.arf_invoice_amounts?.length" class="row mb-4 mt-4">
                  <div class="col">
                    <label class="main-label mt-0"> Cost Codes</label>
                    <div class="row" *ngFor="let a of exportingInvoice.arf_invoice_amounts">
                      <div class="col-6">
                        <h6>
                          {{ a.sub_cost_code_budget?.code }} {{ a.sub_cost_code_budget?.label }} | FY{{
                            a.sub_cost_code_budget?.sub_cost_code?.fiscal_year
                          }}<br />
                          <small>
                            {{ a.sub_cost_code_budget?.cost_code?.code }} |
                            {{ a.sub_cost_code_budget?.cost_code?.label }}
                          </small>
                        </h6>
                      </div>
                      <div class="col-6">
                        <h6>{{ a.amount | USDollar }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>

<app-budget-report-export
  #coverLetterBudgetReport
  [selectedInvoice]="exportingInvoice"
  [disableInit]="true"
  [parentBudget]="parentBudget"
></app-budget-report-export>
