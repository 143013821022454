<div id="request-method" data-test-id="request-method" class="request-method row">
  <h6 *ngIf="showTitle" class="col-12 m-0 dkblue font-weight-semibold mb-2">Request Method</h6>
  <mat-form-field appearance="outline" class="col-12">
    <mat-select matNativeControl [formControl]="requestMethod">
      <mat-select-trigger>
        <span>
          {{ requestMethod?.value?.name }}
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let shownRequestMethod of shownRequestMethods" [value]="shownRequestMethod">
        <mat-icon>
          {{ shownRequestMethod?.icon }}
        </mat-icon>
        <span>
          {{ shownRequestMethod?.name }}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
