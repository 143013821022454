<mat-drawer-container
  autosize
  class="construction-budget container-fluid d-flex flex-column position-absolute h-100 p-4"
>
  <mat-drawer #drawer class="details-drawer p-4" mode="over" position="end" autoFocus="false">
    <app-cb-section-details-drawer
      #sectionDetailsDrawerComponent
      (closeDrawer)="drawer.close()"
      (submitForTenantReview)="submitForTenantReview($event)"
      (openTenantReviewTask)="viewTask($event)"
      (saveCoverLetterDescription)="saveCoverLetter($event)"
      (uploadConstructionDoc)="uploadConstructionDocument($event)"
      (removeConstructionDoc)="removeConstructionDocument($event)"
      [canEdit]="canEdit"
    >
    </app-cb-section-details-drawer>
  </mat-drawer>

  <div id="budget" #budget class="full-width">
    <!-- <app-project-budget-cb></app-project-budget-cb> -->

    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->
    <!-- OLD CD ================================================================ -->

    <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
      <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
        PEB
      </button>
      <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
        Bid Packages
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/construction-budget']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1 bg-white"
      >
        Construction Budget
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Proposal Requests
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/change-orders']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Change Orders
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/invoices']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Invoices
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/budget']"
        mat-button
        color="primary"
        class="flex-grow-1 ml-1"
      >
        Budget Overview
      </button>
    </div>
    <div class="d-flex full-width align-items-center mb-4 border-bottom border-gray">
      <div class="d-flex flex-column mr-auto">
        <h4 class="dkblue">
          Construction Budget
          <span *ngIf="isFinalized" class="bg-dkgreen white px-2 lh-24 font-weight-semibold ml-2 br-4">
            FINALIZED
          </span>
        </h4>
        <p class="dkgray">The Construction Budget represents the total of all awarded bids and fees.</p>
      </div>
      <button *ngIf="project?.uses_new_peb" mat-button color="primary" type="button" class="ml-3" (click)="export()">
        Download PDF
      </button>
      <div *ngIf="project?.uses_new_peb" class="border-gray border-left ml-3 d-flex align-items-center">
        <div class="d-flex">
          <button
            mat-stroked-button
            *ngIf="!isEditing && (canEdit || authService.isAppAdmin || authService.isAC)"
            color="accent"
            type="button"
            class="ml-3 px-3 bg-white"
            (click)="isEditing = true"
          >
            Edit CB
          </button>
          <button
            mat-button
            *ngIf="isEditing && !savingCB"
            color="primary"
            type="button"
            class="gray ml-3 px-3"
            (click)="discardCBChanges()"
          >
            Discard Changes
          </button>
          <button
            mat-flat-button
            *ngIf="isEditing"
            color="success"
            type="button"
            class="ml-3 px-3"
            (click)="saveCBChanges()"
            [disabled]="savingCB"
          >
            {{ savingCB ? 'Saving...' : 'Save CB' }}
          </button>
        </div>
        <div *ngIf="userIsReviewAdmin && reviewsHaveStarted && !isEditing" class="d-flex">
          <button
            mat-stroked-button
            *ngIf="CBApprovalTaskId"
            color="warn"
            type="button"
            class="ml-3 px-3 bg-white"
            (click)="isEditing = true"
          >
            Make A Change
          </button>
          <button
            mat-flat-button
            *ngIf="savedCBApprovalTaskId"
            color="success"
            type="button"
            class="ml-3 px-3"
            (click)="submitApproval(null, true)"
            [disabled]="!internalReviewCanRestart"
          >
            Resubmit
          </button>
          <button
            mat-flat-button
            *ngIf="tenantReviewCanRestart"
            color="success"
            type="button"
            class="ml-3 px-3"
            (click)="submitApproval(null, false)"
          >
            Resubmit Tenant Review
          </button>
          <button
            class="ml-3"
            mat-flat-button
            color="primary"
            type="button"
            *ngIf="canFinalize"
            (click)="finalizeCBNew()"
          >
            <span class="pulse-dot-success"></span>
            Finalize this CB
          </button>
          <button class="ml-3 font-italic" mat-flat-button color="primary" type="button" disabled *ngIf="isFinalized">
            Finalized
          </button>
        </div>
      </div>
    </div>

    <!-- <div [hidden]="!cbData" class="row align-items-center">
      <div class="col-auto">
        <h4 class="m-0 dkblue font-weight-semibold">Construction Budget</h4>
      </div>
      <div class="col m-3">
        <button mat-button class="gray" *ngIf="isEditing" (click)="discardChanges()">
          {{ unsavedChangesExist ? 'Discard' : 'Cancel' }}
        </button>
        <button mat-flat-button *ngIf="isEditing" (click)="saveChanges()"
          [color]="unsavedChangesExist ? 'success' : null">
          Save Changes
        </button>
        <button class="ml-3 bg-white" mat-stroked-button color="accent" *ngIf="!isEditing" (click)="isEditing = true">
          Edit
        </button>
      </div>
    </div> -->

    <div *ngIf="cbData" id="new-budget" class="d-flex align-items-top overflow-hidden">
      <ng-container>
        <div id="trade-column" class="d-flex flex-column">
          <div class="bg-white d-flex flex-column p-3" style="height: 80px">
            <div class="d-flex full-width align-items-center">
              <!-- <h5 class="dkblue mr-auto mb-0">{{ cbData?.title }}</h5> -->
              <h5 class="dkblue mr-auto mb-0">Construction Budget</h5>
            </div>
          </div>
          <div class="bg-white p-3">
            <h6 class="primary mb-0">TRADE</h6>
          </div>
          <div class="bg-white py-3 pl-3 pr-3 td d-flex align-items-center" *ngFor="let l of cbData?.lines">
            <h5 class="primary mb-0 pb-2 font-weight-normal text-truncate mr-2">
              {{ l.name }}
            </h5>
          </div>
          <div class="bg-white p-3" style="height: 52px"></div>
          <div class="bg-white p-3">
            <h6 class="dkblue text-uppercase mb-0 lh-16">Subtotals</h6>
          </div>
        </div>
        <div class="d-flex flex-grow-1 scroll-container">
          <div id="section-container" class="d-flex" *ngFor="let section of cbData?.sections; let i = index">
            <div
              *ngIf="section.isExpanded"
              style="display: inline-block"
              class="bg-white"
              [style.width.px]="(section.funding_sources?.length ?? 0) * 200 + 200"
            >
              <div class="row flex-nowrap text-nowrap m-0">
                <div id="section-header" class="col d-flex flex-column p-3" style="height: 80px">
                  <div class="d-flex full-width align-items-center mb-2">
                    <h5 class="dkblue mr-2 mb-0">{{ section.name }}</h5>
                  </div>
                  <div class="align-items-center row py-2">
                    <div class="dkgray" [class]="'col-' + (section?.funding_sources?.length > 1 ? '3' : '6')">
                      Construction SF <span class="dkblue font-weight-semibold">{{ section.construction_sqft }}</span>
                    </div>
                    <div class="dkgray" [class]="'col-' + (section?.funding_sources?.length > 1 ? '3' : '6')">
                      Occupied SF <span class="dkblue font-weight-semibold">{{ section.occupied_sqft }}</span>
                    </div>
                    <div class="dkgray" [class]="'col-' + (section?.funding_sources?.length > 1 ? '3' : '6')">
                      OSF w/ Circ ({{ 0.01 * section.circulation_factor | percent }})
                      <span class="dkblue font-weight-semibold">{{ section.osf_w_circ }}</span>
                    </div>
                    <!-- TODO: PEB - Rental Rate -->
                    <div
                      *ngIf="section.rental_rate > 0"
                      class="dkgray"
                      [class]="'col-' + (section?.funding_sources?.length > 1 ? '3' : '6')"
                    >
                      Rental Rate
                      <span class="dkblue font-weight-semibold">{{ section.rental_rate || 0 | USDollar: 2 }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3 d-flex">
                  <h6 class="primary text-uppercase mb-0 mr-auto">SF</h6>
                  <!-- <h6 class="gray m-0" *ngIf="isEditing">Refresh</h6> -->
                </div>
                <div class="col-auto p-3" *ngFor="let fs of section.funding_sources">
                  <h6 class="primary text-uppercase mb-0">{{ fs.abbreviation }}</h6>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto pt-1 px-3">
                  <div class="td" *ngFor="let l of section.lines">
                    <mat-form-field class="full-width" appearance="outline" *ngIf="l.type_id === 1">
                      <mat-icon class="primary" matPrefix>apartment</mat-icon>
                      <input
                        (click)="$event.target.select()"
                        class="primary"
                        [ngClass]="{ gray: l.sqft == 0 }"
                        matInput
                        appNumeric
                        [allowDecimal]="false"
                        [(ngModel)]="l.sqft"
                        (ngModelChange)="tenantLineChanged(l)"
                        [disabled]="!isEditing"
                      />
                      <p matSuffix class="gray m-0">{{ !l.sqft ? 0 : ((l.subtotal || 0) / l.sqft | USDollar) }}/sf</p>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-auto pt-1 px-3" *ngFor="let fs of section.funding_sources">
                  <div class="td" *ngFor="let i of fs.items">
                    <mat-form-field class="full-width" appearance="outline" *ngIf="i.line_type_id === 1">
                      <mat-icon [ngClass]="{ gray: i.value == 0 }" matPrefix>attach_money</mat-icon>
                      <input
                        (click)="$event.target.select()"
                        class="primary text-right"
                        [ngClass]="{ gray: i.value == 0 }"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [(ngModel)]="i.value"
                        (ngModelChange)="cbItemChanged(i, null, section.lines)"
                        (blur)="blurDollarItem(i, 'value')"
                        [disabled]="!isEditing"
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      *ngIf="feeTypeIds.indexOf(i.line_type_id) > -1"
                    >
                      <mat-icon
                        (click)="changePercentOrDollar(i)"
                        [ngClass]="{ gray: i.value == 0, 'cursor-pointer': [3, 5].indexOf(i.line_type_id) >= 0 }"
                        matPrefix
                        >{{
                          [3, 5].indexOf(i.line_type_id) >= 0
                            ? percentOrDollar === 'percent'
                              ? 'percent'
                              : 'attach_money'
                            : 'percent'
                        }}</mat-icon
                      >
                      <input
                        [hidden]="[3, 5].indexOf(i.line_type_id) >= 0 && percentOrDollar === 'dollar'"
                        (click)="$event.target.select()"
                        class="primary"
                        [ngClass]="{ gray: i.value == 0 }"
                        matInput
                        appNumeric
                        placeholder="0"
                        [(ngModel)]="i.percentage"
                        (ngModelChange)="cbItemChanged(i, fs, section.lines)"
                        [disabled]="!isEditing"
                      />
                      <input
                        [hidden]="[3, 5].indexOf(i.line_type_id) < 0 || percentOrDollar === 'percent'"
                        (click)="$event.target.select()"
                        class="primary"
                        [ngClass]="{ gray: i.value == 0 }"
                        matInput
                        appNumeric
                        placeholder="0"
                        [(ngModel)]="i.value"
                        (ngModelChange)="cbItemChanged(i, fs, section.lines)"
                        [disabled]="!isEditing"
                        (blur)="blurDollarItem(i, 'value')"
                      />
                      <p
                        matSuffix
                        class="gray m-0"
                        *ngIf="[3, 5].indexOf(i.line_type_id) < 0 || percentOrDollar === 'percent'"
                      >
                        {{ i.value || 0 | USDollar: 2:false }}
                      </p>
                      <p
                        matSuffix
                        class="gray m-0"
                        *ngIf="[3, 5].indexOf(i.line_type_id) >= 0 && percentOrDollar === 'dollar'"
                      >
                        {{ i.percentage || 0 | USDollar: 2:false }}%
                      </p>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap" style="height: 48px">
                <div class="col py-3 px-3">
                  <h6 class="white mb-0 lh-16">&nbsp;</h6>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3 text-right">
                  <h6 class="mb-0 lh-16">
                    {{ (parseNumber(section?.subtotal) || 0) / (section?.construction_sqft || 0) | USDollar }}/SF
                  </h6>
                </div>
                <div class="col-auto p-3 text-right" *ngFor="let fs of section.funding_sources">
                  <h6 class="dkblue font-weight-semibold mb-0 lh-16 pr-2">{{ fs.subtotal || 0 | USDollar: 2 }}</h6>
                </div>
              </div>
            </div>
            <div style="width: 200px; display: inline-block" class="bg-shade-gray">
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3 d-flex flex-column" style="height: 80px">
                  <div class="d-flex">
                    <button
                      (click)="openSectionDrawer(section)"
                      mat-stroked-button
                      color="accent"
                      class="bg-white flex-grow-1"
                      type="button"
                    >
                      <span *ngIf="dataIsAddedBySectionStatus[i]" class="pulse-dot-accent"></span>
                      View Details
                    </button>
                    <button
                      mat-button
                      color="primary"
                      class="ml-3 px-2"
                      type="button"
                      (click)="section.isExpanded = !section.isExpanded"
                    >
                      <mat-icon>{{ section.isExpanded ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3">
                  <h6 class="primary text-uppercase mb-0 ellipsis">
                    {{ section.isExpanded ? 'Subtotal' : section.name }}
                  </h6>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto py-1 px-3">
                  <div class="td" *ngFor="let l of section.lines">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-icon [ngClass]="{ gray: l.subtotal == 0 }" matPrefix>attach_money</mat-icon>
                      <input
                        disabled
                        matInput
                        placeholder="0.00"
                        class="primary text-right"
                        [ngClass]="{ gray: l.subtotal == 0 }"
                        [value]="l.subtotal || 0 | USDollar: 2:false"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3" style="height: 44px">
                  <h6 class="ltgray text-uppercase mb-0 lh-16">-</h6>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3 text-right">
                  <h6 class="dkblue text-uppercase font-weight-bold mb-0 lh-16 pr-2">
                    {{ section.subtotal | USDollar: 2 }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="total-column" class="d-flex flex-column bg-white">
          <div class="d-flex flex-column p-3" style="height: 80px">
            <button
              mat-stroked-button
              color="primary"
              type="button"
              class="full-width px-2 lh-26"
              (click)="internalReviewAction(CBApprovalTaskId || savedCBApprovalTaskId)"
              [disabled]="!reviewsHaveStarted && !internalReviewCanStart"
              *ngIf="!CBApprovalTaskId && !savedCBApprovalTaskId"
            >
              <span *ngIf="!reviewsHaveStarted && internalReviewCanStart" class="pulse-dot-success"></span>
              Start Internal Review
            </button>
            <app-open-task-button
              *ngIf="CBApprovalTaskId || savedCBApprovalTaskId"
              [taskId]="CBApprovalTaskId || savedCBApprovalTaskId"
              [title]="'Internal Review'"
              [lineHeight]="26"
              [fullWidth]="true"
            >
            </app-open-task-button>
            <button
              mat-stroked-button
              color="accent"
              class="full-width lh-26 mt-2"
              type="button"
              *ngIf="!noTenantReviews && tenantReviewCanStart && sectionsThatNeedTenantReviews?.length"
              (click)="tenantReviewAction()"
              [disabled]="!tenantReviewCanStart && !tenantReviewHasStarted"
            >
              Start Tenant Review{{ sectionsThatNeedTenantReviews?.length <= 1 ? '' : 's' }}
            </button>
            <app-open-task-button
              *ngIf="tenantTaskIds?.length && !sectionsThatNeedTenantReviews?.length"
              [taskIds]="tenantTaskIds"
              [title]="'Tenant Review' + (tenantTaskIds?.length === 1 ? '' : 's')"
              [lineHeight]="26"
              [fullWidth]="true"
              class="mt-2"
            >
            </app-open-task-button>
          </div>
          <div class="col-auto p-3">
            <h6 class="primary text-uppercase mb-0">Project Total</h6>
          </div>
          <div class="col-auto py-1 px-3">
            <div class="td" *ngFor="let l of cbData?.lines">
              <mat-form-field class="full-width" appearance="outline">
                <mat-icon
                  class="pointer primary"
                  [ngClass]="{ red: l.trade_id && l.awarded != l.total, green: l.trade_id && l.awarded == l.total }"
                  matPrefix
                  >{{
                    l.trade_id && l.awarded != l.total ? 'error' : l.trade_id ? 'check_circle' : 'attach_money'
                  }}</mat-icon
                >
                <input
                  disabled
                  matInput
                  placeholder="0.00"
                  [value]="(l.trade_id ? l.awarded : l.total) | currency: '':''"
                  class="primary font-weight-semibold text-right"
                />
                <!-- <mat-icon [ngClass]="{ red: l.trade_id && l.awarded != l.total }" class="pointer green" matSuffix
                  matTooltip="{{
                    l.trade_id && l.awarded != l.total ? 'Amount must be allocated perfectly' : 'Prefectly Allocated'
                  }}" matTooltipPosition="before" matTooltipShowDelay="300">{{ !l.trade_id ? '' : 'verified' }}
                </mat-icon> -->
                <!-- <mat-icon class="ltgray pointer" matSuffix>lock_open</mat-icon> -->
                <mat-hint *ngIf="l.trade_id && l.awarded && l.awarded != l.total" class="red" align="end"
                  >{{ l.awarded - l.total || 0 | USDollar: 2:false }} to allocate</mat-hint
                >
                <mat-hint *ngIf="l.trade_id && !l.awarded" class="red" align="end">Bid not awarded</mat-hint>
                <mat-hint *ngIf="l.awarded == l.total" class="green opacity7 font-italic" align="end"
                  >Perfectly Allocated</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
          <div class="col-auto p-3 lh-16" style="height: 46px"></div>
          <div class="px-3"></div>
          <div class="p-3 text-right">
            <h6 class="primary mb-0 pr-2 font-weight-bold">{{ cbData?.total || 0 | USDollar: 2 }}</h6>
          </div>
        </div>
      </ng-container>
    </div>

    <div [hidden]="cbData || !isDoneLoading" class="row">
      <div class="col">
        <mat-tab-group
          #tenantTabGroup
          *ngIf="projectTenants && projectTenants.length > 0"
          (selectedTabChange)="changeTenantTabUIOnly ? (changeTenantTabUIOnly = false) : selectTenant(null, $event)"
          animationDuration="0ms"
        >
          <mat-tab *ngFor="let tenant of projectTenants">
            <ng-template mat-tab-label>
              <span>{{ tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name }}</span>
              <!-- <mat-icon class="green" *ngIf="TODO.checkApproval">check_mark</mat-icon> -->
            </ng-template>
            <h5 class="col-12 mt-4 gray text-center font-weight-semibold" *ngIf="!this.pebIsFinalized">
              The Construction Budget is not available because this tenant's PEB has not yet been finalized.
            </h5>
            <div *ngIf="this.peb && this.pebIsFinalized" class="row">
              <div class="col">
                <div class="peb m-portlet m-portlet--mobile" m-portlet="true">
                  <div class="m-portlet__body" [ngClass]="{ 'border-ltblue': unsavedChangesExist }">
                    <div class="row dkblue peb-main">
                      <div class="col-12">
                        <div class="row align-items-center">
                          <div class="col">
                            <h3 class="dkblue m-0 font-weight-semibold d-inline-block align-middle">
                              Construction Budget
                            </h3>
                            <h6 class="m-0 dkblue font-weight-semibold">
                              <span class="gray mr-2 font-weight-normal"> Prepared For: </span>
                              {{ tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name }}
                            </h6>
                          </div>
                          <div class="col-auto text-right mb-3">
                            <button
                              class="ml-3 bg-white"
                              mat-button
                              color="accent"
                              *ngIf="!isEditing"
                              (click)="export()"
                            >
                              Download PDF
                            </button>
                            <button
                              class="ml-3 bg-white"
                              mat-stroked-button
                              color="accent"
                              *ngIf="!isEditing"
                              (click)="isEditing = true"
                              [disabled]="
                                (!tenant.saved_cb_approval_task_id && !!tenant?.cb_approval_task_id) ||
                                !userIsReviewAdmin ||
                                tenant.cb_approval_process?.isFinalized
                              "
                            >
                              Edit
                            </button>
                            <!-- TODO: Link menu items -->
                            <!-- <button class="ml-3 bg-white" mat-stroked-button color="accent" *ngIf="!isEditing">
                                  Tasks
                                </button>
                                <button mat-flat-button [matMenuTriggerFor]="review_menu" color="accent" *ngIf="!isEditing" class="ml-3"
                                  type="button">
                                  Submit for Review
                                </button>
                                <mat-menu #review_menu="matMenu" xPosition="before">
                                  <button mat-menu-item color="primary">
                                    <mat-icon>assignment_turned_in</mat-icon>
                                    <span>Internal Review</span>
                                  </button>
                                  <button mat-menu-item color="primary">
                                    <mat-icon>assignment_turned_in</mat-icon>
                                    <span>Tenant Review</span>
                                  </button>
                                </mat-menu>
                                <button class="ml-3 bg-white pr-2" [matMenuTriggerFor]="cbudget_menu" mat-stroked-button color="accent"
                                  *ngIf="!isEditing">
                                  <span>
                                    More
                                  </span>
                                  <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #cbudget_menu="matMenu" xPosition="before">
                                  <button mat-menu-item color="primary">
                                    <mat-icon>assignment_turned_in</mat-icon>
                                    <span>Create Action Task</span>
                                  </button>
                                  <button mat-menu-item color="primary">
                                    <mat-icon>save_alt</mat-icon>
                                    <span>Download PDF</span>
                                  </button>
                                  <button mat-menu-item color="primary">
                                    <mat-icon>find_in_page</mat-icon>
                                    <span>View PDF</span>
                                  </button>
                                  <button mat-menu-item color="primary" (click)="createMeeting()">
                                    <mat-icon>event</mat-icon>
                                    <span>
                                      Create Meeting
                                    </span>
                                  </button>
                                  <button mat-menu-item color="primary" (click)="createMeetingAgendaFromProject(shownProject)">
                                    <mat-icon>event_notes</mat-icon>
                                    <span>
                                      Add to Meeting Agenda
                                    </span>
                                  </button>
                                </mat-menu> -->
                            <button mat-button class="gray" *ngIf="isEditing" (click)="discardChanges()">
                              {{ unsavedChangesExist ? 'Discard' : 'Cancel' }}
                            </button>
                            <button
                              mat-flat-button
                              *ngIf="isEditing"
                              (click)="saveChanges()"
                              [color]="unsavedChangesExist ? 'success' : null"
                              [disabled]="!unsavedChangesExist"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!-- Construction Budget Header -->
                    <div class="row peb-header">
                      <a *ngIf="!hideDetails" (click)="hideDetails = !hideDetails" class="section-hide ltblue opacity5"
                        >Hide Details</a
                      >
                      <a *ngIf="hideDetails" (click)="hideDetails = !hideDetails" class="section-hide ltblue opacity5"
                        >Show Details</a
                      >
                      <div class="col-12">
                        <div class="row align-items-center">
                          <div class="col">
                            <label class="main-label">Total Project Cost</label>
                            <h5 class="dkblue font-weight-semibold">
                              {{ trustTotalCost + tenantTotalCost || 0 | USDollar: 2:true:true:true }}
                            </h5>
                          </div>
                          <div class="col">
                            <label class="main-label">Trust Investment</label>
                            <h5 class="dkblue font-weight-semibold">
                              {{ trustTotalCost || 0 | USDollar: 2:true:true:true }}
                            </h5>
                          </div>
                          <div class="col">
                            <label class="main-label">Tenant Allowance</label>
                            <h5 class="dkblue font-weight-semibold">
                              {{ this.peb.tenant_allowance_per_sqft || 0 | USDollar: 2:true:true:true }}
                            </h5>
                          </div>
                          <div class="col">
                            <label class="main-label">Total Rent / Yr</label>
                            <h5 class="dkblue font-weight-semibold">
                              {{ this.peb.total_rent_per_year || 0 | USDollar: 2:true:true:true }}
                            </h5>
                          </div>
                          <div class="col border br-5 border-dkgray pt-2 pb-2">
                            <label class="main-label">Tenant Cost</label>
                            <h5 class="dkblue font-weight-semibold">
                              {{ tenantTotalCost || 0 | USDollar: 2:true:true:true }}
                            </h5>
                          </div>
                        </div>
                        <div *ngIf="!hideDetails" class="row align-items-center peb-header-lower">
                          <div class="col-md-4">
                            <label class="main-label">Design Timeline</label>
                            <h6 class="dkblue font-weight-semibold" style="height: 34px">
                              {{ this.peb.design_timeline }}
                            </h6>
                            <label class="main-label">Construction Timeline</label>
                            <h6 class="dkblue font-weight-semibold" style="height: 34px">
                              {{ this.peb.construction_timeline }}
                            </h6>
                            <label class="main-label">Total Timeline</label>
                            <h6 class="dkblue font-weight-semibold" style="height: 34px">
                              {{ this.peb.total_timeline }}
                            </h6>
                          </div>
                          <div class="col-md-4">
                            <label class="main-label">Construction SF</label>
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{ this.peb.construction_sqft || 0 | number }}
                            </h6>
                            <label class="main-label">Occupied SF</label>
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{ this.peb.occupied_sqft || 0 | number }}
                            </h6>
                            <label class="main-label"
                              >Circulation SF ({{ project.building_circulation_factor || 0 }}%)</label
                            >
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{ this.peb.circulation_sqft || 0 | number }}
                            </h6>
                          </div>
                          <div class="col-md-4">
                            <label class="main-label">Rental SF w/ Circ</label>
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{ this.peb.rental_sqft_w_circ ? (this.peb.rental_sqft_w_circ | number) : '' }}
                            </h6>
                            <label class="main-label">Rental Rate</label>
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{ this.peb.rental_rate ? (this.peb.rental_rate || 0 | USDollar: 2:true:true:true) : '' }}
                            </h6>
                            <label class="main-label">Tenant Allowance</label>
                            <h6 class="font-weight-semibold dkblue" style="height: 34px">
                              {{
                                this.peb.tenant_allowance_per_sqft
                                  ? (this.peb.tenant_allowance_per_sqft || 0 | USDollar: 2:true:true:true)
                                  : ''
                              }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let s of cbSections">
                      <!-- Construction Budget Line Items -->
                      <div class="row align-items-center mt-4">
                        <div class="col-12">
                          <div class="row align-items-center peb-section-header">
                            <div class="col pl-0">
                              <h4 class="m-0 dkblue font-weight-semibold">
                                <b class="mr-2">
                                  {{ s.name }}
                                </b>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="row align-items-center peb-table-header">
                            <div class="col-auto text-center" style="width: 30px">
                              <label class="main-label font-weight-semibold"> # </label>
                            </div>
                            <div class="col">
                              <label class="main-label font-weight-semibold"> Trade / Costs & Fees </label>
                            </div>
                            <div class="col-2 text-right" style="width: 50px">
                              <label class="main-label font-weight-semibold"> PEB </label>
                            </div>
                            <!-- <div class="col-1 text-center" style="width:50px">
                                  <label class="main-label font-weight-semibold">
                                    % Responsible
                                  </label>
                                </div> -->
                            <div class="col-2 text-right">
                              <label class="main-label font-weight-semibold"> Total Cost </label>
                            </div>
                            <div class="col-2 text-right">
                              <label class="main-label font-weight-semibold"> Difference </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- SECTION ITEMS -->
                      <div
                        class="row"
                        *ngFor="
                          let i of s.owner_type_id === 1 ? tenantItems : s.owner_type_id === 2 ? trustItems : [];
                          index as elementIndex
                        "
                      >
                        <div class="col-12">
                          <div class="row align-items-center item ease peb-line-item">
                            <div class="col-auto text-center" style="width: 30px">
                              <h6 class="d-inline-block gray pointer">
                                {{ elementIndex + 1 }}
                              </h6>
                            </div>
                            <div class="col">
                              <h6 class="m-0 dkgray font-weight-semibold">
                                <span class="mr-2">{{ i.name }}</span>
                              </h6>
                            </div>
                            <div class="col-2 text-right">
                              <span class="dkblue" *ngIf="i.type_id !== 6">{{ i.budget || 0 | USDollar }}</span>
                              <mat-form-field
                                class="peb-input ltblue text-right full-width"
                                appearance="outline"
                                *ngIf="i.type_id === 6"
                              >
                                <input
                                  matInput
                                  type="number"
                                  [disabled]="!isEditing"
                                  [(ngModel)]="managementFeeItem.percent_cost"
                                  (ngModelChange)="modifyField(i, 'percent_cost')"
                                />
                                <span class="gray" matSuffix>&nbsp;%</span>
                              </mat-form-field>
                            </div>
                            <div class="col-2 text-right">
                              <mat-form-field
                                class="peb-input text-right full-width"
                                appearance="outline"
                                *ngIf="i.bid_package_id || i.peb_fee_item_id"
                              >
                                <span class="gray" matPrefix>$&nbsp;</span>
                                <input
                                  matInput
                                  type="number"
                                  [disabled]="!isEditing"
                                  [(ngModel)]="i.subtotal"
                                  (ngModelChange)="modifyField(i, 'subtotal')"
                                  (blur)="blurUSDollarInput(i.subtotal, i, 'subtotal')"
                                />
                              </mat-form-field>
                              <div *ngIf="i.type_id === 4">
                                <span class="dkblue mr-2">{{ i.subtotal || 0 | USDollar }}</span>
                              </div>
                              <div *ngIf="i.type_id === 6">
                                <span class="dkblue mr-2">{{ managementFeeItem.subtotal || 0 | USDollar }}</span>
                              </div>
                              <div
                                *ngIf="!i.bid_package_id && !i.peb_fee_item_id && [4, 6].indexOf(i.type_id) === -1"
                                class="gray"
                              >
                                No bid package
                              </div>
                            </div>
                            <div class="col-2 text-right">
                              <span class="gray" *ngIf="i.type_id !== 6">{{
                                +i.budget - +i.subtotal || 0 | USDollar
                              }}</span>
                              <span class="gray" *ngIf="i.type_id === 6"
                                >{{
                                  managementFeeItem.percent_cost > selectedTenant.peb_management_fee_percentage
                                    ? '+'
                                    : ''
                                }}{{ managementFeeItem.percent_cost - selectedTenant.peb_management_fee_percentage }}%
                                {{
                                  managementFeeItem.subtotal - selectedTenant.peb_management_fee_subtotal || 0
                                    | USDollar
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center pt-2 m-0">
                        <div class="col pl-0">
                          <h5 class="m-0 d-inline-block d-inline-block align-middle gray font-weight-semibold">
                            Subtotal: {{ s.name }}
                          </h5>
                        </div>
                        <div class="col-2 text-right">
                          <h5 class="m-0 gray font-weight-normal">
                            {{
                              (s.owner_type_id === 1
                                ? +tenantTotalBudget
                                : s.owner_type_id === 2
                                ? +trustTotalBudget
                                : 0) || 0 | USDollar: 2:true:true:true
                            }}
                          </h5>
                        </div>
                        <!-- <div class="col-2 text-right">
                                  <h5 class="m-0 dkblue opacity7">
                                    <b>{{totalActualAmount || 0 | USDollar:2:true:true:true}}</b>
                                  </h5>
                                </div> -->
                        <!-- <div class="col-1 text-center">
                              <h5 class="m-0 dkblue opacity7">
                                <b>{{(totalActualAmount ? (p.subtotal || 0) / totalActualAmount : 0) | percent:'1.1-1'}}</b>
                              </h5>
                            </div> -->
                        <div class="col-2 text-right">
                          <h5 class="m-0 dkblue">
                            <b>{{
                              (s.owner_type_id === 1
                                ? +tenantTotalCost
                                : s.owner_type_id === 2
                                ? +trustTotalCost
                                : 0) || 0 | USDollar: 2:true:true:true
                            }}</b>
                          </h5>
                        </div>
                        <div class="col-2 text-right">
                          <h5 class="m-0 gray font-weight-normal">
                            {{
                              (s.owner_type_id === 1
                                ? +tenantTotalBudget - +tenantTotalCost
                                : s.owner_type_id === 2
                                ? +trustTotalBudget - +trustTotalCost
                                : 0) || 0 | USDollar: 2:true:true:true
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <!-- Construction Budget Footer -->
                    <div class="row align-items-center pt-5 m-0">
                      <div class="col pl-0">
                        <h4 class="m-0 dkblue font-weight-bold">Totals</h4>
                      </div>
                      <div class="col-2 text-right">
                        <h4 class="m-0 dkblue font-weight-bold">
                          <b>{{ tenantTotalBudget + trustTotalBudget || 0 | USDollar: 2:true:true:true }}</b>
                        </h4>
                      </div>
                      <div class="col-2 text-right">
                        <h4 class="m-0 dkblue font-weight-bold">
                          <b>{{ tenantTotalCost + trustTotalCost || 0 | USDollar: 2:true:true:true }}</b>
                        </h4>
                      </div>
                      <!-- <div class="col-1 text-center">
                            <h5 class="m-0 dkblue font-weight-bold">
                              <b>{{(totalActualAmount ? (totalResponsibleAmount || 0) / totalActualAmount : 0) | percent:'1.1-1'}}</b>
                            </h5>
                          </div> -->
                      <div class="col-2 text-right">
                        <h4 class="m-0 dkblue font-weight-bold">
                          {{
                            tenantTotalBudget + trustTotalBudget - (tenantTotalCost + trustTotalCost) || 0
                              | USDollar: 2:true:true:true
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto" style="width: 400px">
                <div class="mb-5 mt-4">
                  <div class="row align-items-center mb-2">
                    <div class="col text-center">
                      <h5 class="m-0 font-weight-bold dkblue">
                        {{
                          !allBidsAllocated && tenant.cb_approval_process?.staffCanStart && userIsReviewAdmin
                            ? 'All awarded bids must be allocated perfectly before reviews can begin'
                            : getPebStatusText(tenant)
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <div class="row align-items-center mb-2">
                    <div class="col">
                      <h6 class="dkblue m-0 font-weight-bold">Reviews</h6>
                    </div>
                    <div class="col-auto">
                      <button
                        mat-button
                        style="line-height: 24px"
                        class="pl-2 pr-2"
                        color="accent"
                        type="button"
                        (click)="resetReview(tenant, true)"
                        *ngIf="tenant.cb_approval_process?.canReset && userIsReviewAdmin"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <div class="m-portlet m-0 br-10">
                    <div class="m-portlet__body p-3 item">
                      <div class="row align-items-center">
                        <div class="col">
                          <mat-icon
                            *ngIf="tenant.cb_approval_process && !tenant.cb_approval_process?.isPending"
                            class="mr-3 align-middle"
                            [ngStyle]="{ color: tenant.cb_approval_process?.staffApprovalColor }"
                          >
                            {{ tenant.cb_approval_process?.staffApprovalIcon }}
                          </mat-icon>
                          <app-profile-thumbnail
                            *ngIf="tenant.cb_approval_process?.isPending"
                            [noBorder]="true"
                            [matTooltip]="
                              tenant.cb_approval_process?.currentStaffReviewer.firstName +
                              ' ' +
                              tenant.cb_approval_process?.currentStaffReviewer.lastName
                            "
                            class="mr-3 d-inline-block align-middle"
                            [height]="26"
                            [width]="26"
                            [userId]="tenant.cb_approval_process?.currentStaffReviewer.id"
                          ></app-profile-thumbnail>
                          <h6
                            *ngIf="!tenant.saved_cb_approval_task_id && !tenant.cb_approval_task_id"
                            class="m-0 ltblue d-inline-block align-middle font-weight-bold pointer"
                          >
                            Internal Review
                          </h6>
                          <h6
                            *ngIf="tenant.saved_cb_approval_task_id || tenant.cb_approval_task_id"
                            [routerLink]="
                              '/projects/' +
                              project.id +
                              '/tasks/' +
                              (tenant.saved_cb_approval_task_id || tenant.cb_approval_task_id)
                            "
                            class="m-0 ltblue d-inline-block align-middle font-weight-bold pointer"
                          >
                            Internal Review
                          </h6>
                        </div>
                        <div class="col-auto text-right">
                          <button
                            mat-flat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="submitApproval(tenant, true)"
                            *ngIf="
                              tenant.cb_approval_process?.staffCanStart &&
                              !tenant.saved_cb_approval_task_id &&
                              userIsReviewAdmin
                            "
                            [disabled]="!allBidsAllocated || unsavedChangesExist"
                          >
                            Start
                          </button>
                          <button
                            mat-flat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="submitApproval(tenant, true)"
                            *ngIf="
                              !tenant.cb_approval_process?.staffCanStart &&
                              tenant.saved_cb_approval_task_id &&
                              userIsReviewAdmin
                            "
                            [disabled]="!allBidsAllocated || unsavedChangesExist"
                          >
                            Submit Revision
                          </button>
                          <button
                            mat-button
                            color="accent"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="viewTask(tenant.saved_cb_approval_task_id || tenant.cb_approval_task_id)"
                            *ngIf="
                              tenant.cb_approval_process?.hasStaffTask ||
                              (!userIsReviewAdmin && tenant.cb_approval_task_id)
                            "
                          >
                            View
                          </button>
                          <button
                            mat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="resetReview(tenant, false)"
                            *ngIf="
                              !!tenant.cb_approval_task_id && !tenant.saved_cb_approval_task_id && !!userIsReviewAdmin
                            "
                          >
                            + Revision
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="m-portlet__body p-3" *ngIf="tenant.cb_approval_process?.needsTenantApproval">
                      <div class="row align-items-center">
                        <div class="col">
                          <app-profile-thumbnail
                            *ngIf="
                              tenant.cb_approval_process?._tenantReview &&
                              tenant.cb_approval_process?._tenantReview.approvalStatus === 1
                            "
                            [noBorder]="true"
                            [matTooltip]="
                              tenant.cb_approval_process?.tenantReviewAssignedUser.firstName +
                              ' ' +
                              tenant.cb_approval_process?.tenantReviewAssignedUser.lastName
                            "
                            class="mr-3 d-inline-block align-middle"
                            [height]="26"
                            [width]="26"
                            [userId]="tenant.cb_approval_process?.tenantReviewAssignedUser.id"
                          ></app-profile-thumbnail>
                          <mat-icon
                            *ngIf="
                              tenant.cb_approval_process?._tenantReview &&
                              tenant.cb_approval_process?._tenantReview.approvalStatus !== 1
                            "
                            class="mr-3 align-middle"
                            [ngStyle]="{ color: tenant.cb_approval_process?.tenantApprovalColor }"
                          >
                            {{ tenant.cb_approval_process?.tenantApprovalIcon }}
                          </mat-icon>
                          <h6
                            *ngIf="!tenant.saved_cb_tenant_approval_task_id && !tenant.cb_tenant_approval_task_id"
                            class="m-0 ltblue d-inline-block align-middle font-weight-bold pointer"
                          >
                            Tenant Review
                          </h6>
                          <h6
                            *ngIf="tenant.saved_cb_tenant_approval_task_id || tenant.cb_tenant_approval_task_id"
                            [routerLink]="
                              '/projects/' +
                              project.id +
                              '/tasks/' +
                              (tenant.saved_cb_tenant_approval_task_id || tenant.cb_tenant_approval_task_id)
                            "
                            class="m-0 ltblue d-inline-block align-middle font-weight-bold pointer"
                          >
                            Tenant Review
                          </h6>
                        </div>
                        <div class="col-auto text-right">
                          <button
                            mat-flat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="submitApproval(tenant, false)"
                            *ngIf="
                              tenant.cb_approval_process?.tenantCanStart &&
                              !tenant.saved_cb_tenant_approval_task_id &&
                              userIsReviewAdmin
                            "
                          >
                            Start
                          </button>
                          <button
                            mat-flat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="submitApproval(tenant, false)"
                            *ngIf="
                              tenant.cb_approval_process?.tenantCanStart &&
                              tenant.saved_cb_tenant_approval_task_id &&
                              userIsReviewAdmin
                            "
                          >
                            Submit Revision
                          </button>
                          <button
                            mat-button
                            color="accent"
                            type="button"
                            style="line-height: 28px"
                            (click)="
                              viewTask(tenant.saved_cb_tenant_approval_task_id || tenant.cb_tenant_approval_task_id)
                            "
                            *ngIf="
                              tenant.cb_approval_process?.hasTenantTask ||
                              (!userIsReviewAdmin && tenant.cb_tenant_approval_task_id)
                            "
                          >
                            View
                          </button>
                          <button
                            mat-button
                            color="success"
                            type="button"
                            class="pl-3 pr-3"
                            style="line-height: 28px"
                            (click)="resetReview(tenant, false)"
                            *ngIf="tenant.cb_approval_process?.tenantCanAddNewRevision && userIsReviewAdmin"
                          >
                            + Revision
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <button
                        mat-flat-button
                        class="full-width"
                        color="accent"
                        type="button"
                        (click)="finalizeCB(tenant)"
                        *ngIf="tenant.cb_approval_process"
                        [disabled]="!tenant.cb_approval_process?.canFinalize"
                      >
                        Finalize Construction Budget
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <div class="row align-items-center mb-2">
                    <div class="col">
                      <h6 class="dkblue m-0 font-weight-bold">Supporting Documents</h6>
                    </div>
                    <div class="col-auto">
                      <button mat-button (click)="getPdfPackage(tenant)" color="accent" class="pl-2 pr-2 lh-24">
                        <i class="material-icons" style="font-size: 18px; height: 18px; width: 18px">get_app</i>
                        CB Package
                      </button>
                    </div>
                  </div>
                  <div class="m-portlet m-0 br-10">
                    <div class="m-portlet__body p-3">
                      <div class="row align-items-center">
                        <div class="col">
                          <p class="m-0 ltblue font-weight-bold">Cover Letter</p>
                        </div>
                        <div class="col-auto text-right">
                          <!-- <button
                                mat-icon-button
                                color="accent"
                                class="ml-3"
                                matTooltip="Download"
                                matTooltipPosition="above"
                                (click)="exportCoverLetter()"
                                [matTooltipShowDelay]="300"
                              >
                                <mat-icon>get_app</mat-icon>
                              </button> -->
                          <button
                            (click)="openCoverLetter()"
                            mat-button
                            type="button"
                            color="accent"
                            style="line-height: 28px"
                            class="pl-2 pr-2"
                            [disabled]="
                              (!tenant.saved_cb_approval_task_id && !!tenant?.cb_approval_task_id) ||
                              !userIsReviewAdmin ||
                              tenant.cb_approval_process?.isFinalized
                            "
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div *ngIf="bidPackages.length > 0" class="mb-5 mt-4 pt-3">
                  <h6 class="dkblue m-0 font-weight-bold mb-3">Awarded Bid Allocation</h6>
                  <div class="m-portlet m-0">
                    <div class="m-portlet__body item item-no-hover p-3">
                      <div class="row align-items-center">
                        <div class="col">
                          <label class="main-label">Trade</label>
                        </div>
                        <div class="col text-right pl-0">
                          <label class="main-label">Total Awarded</label>
                        </div>
                        <div class="col text-right pl-0">
                          <label class="main-label">Total Allocated</label>
                        </div>
                        <div class="col text-right pl-0">
                          <label class="main-label">Remaining</label>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let p of bidPackages" class="m-portlet__body item item-no-hover p-3">
                      <div class="row align-items-center">
                        <div class="col">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">
                            {{ p.trade_name }}
                          </p>
                        </div>
                        <div class="col text-right pl-0">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">
                            {{
                              numberWithCommas(
                                p.child_request?.id || p.child_project?.id
                                  ? p.child_project?.budget_data?.awardedBidTotal || 0
                                  : p.awarded_amount
                              ) || 0 | USDollar: 2:true:true:true
                            }}
                          </p>
                        </div>
                        <div class="col text-right pl-0">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">
                            {{ numberWithCommas(p.used_amount) || 0 | USDollar: 2:true:true:true }}
                          </p>
                        </div>
                        <div class="col text-right pl-0">
                          <p
                            class="m-0 font-weight-semibold green"
                            style="font-size: 1rem"
                            [ngClass]="{
                              red:
                                (p.child_request?.id || p.child_project?.id
                                  ? p.child_project?.budget_data?.awardedBidTotal || 0
                                  : p.awarded_amount) -
                                  p.used_amount !==
                                0
                            }"
                          >
                            {{
                              numberWithCommas(
                                (p.child_request?.id || p.child_project?.id
                                  ? p.child_project?.budget_data?.awardedBidTotal || 0
                                  : p.awarded_amount) - p.used_amount
                              ) || 0 | USDollar: 2:true:true:true
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="m-portlet__body bg-ltltltblue item p-3 item-last item-no-hover">
                      <div class="row align-items-center">
                        <div class="col">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">Totals</p>
                        </div>
                        <div class="col text-right pl-0">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">
                            {{ numberWithCommas(bidPackageTotals.awarded) || 0 | USDollar: 2:true:true:true }}
                          </p>
                        </div>
                        <div class="col text-right pl-0">
                          <p class="m-0 font-weight-semibold dkblue" style="font-size: 1rem">
                            {{ numberWithCommas(bidPackageTotals.allocated) || 0 | USDollar: 2:true:true:true }}
                          </p>
                        </div>
                        <div class="col text-right pl-0">
                          <p
                            class="m-0 font-weight-semibold green"
                            style="font-size: 1rem"
                            [ngClass]="{
                              red: bidPackageTotals.awarded - bidPackageTotals.allocated !== 0
                            }"
                          >
                            {{
                              numberWithCommas(bidPackageTotals.awarded - bidPackageTotals.allocated) || 0
                                | USDollar: 2:true:true:true
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</mat-drawer-container>
<!-- EXPORT -->
<div class="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/1call_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ getCurrentProject().code }} | {{ getCurrentProject().building_code }}-{{
                    getCurrentProject().floor_code
                  }}
                  | {{ getCurrentProject().title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ getCurrentProject().building_code }}-{{ getCurrentProject().floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ getCurrentProject().project_manager_first_name }}
                  {{ getCurrentProject().project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p *ngIf="exportingPEB">
                  {{
                    getCurrentProject().square_footage
                      ? numberWithCommas(getCurrentProject().square_footage)
                      : 'Not Given'
                  }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ getCurrentProject().end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ getCurrentProject().architect_first_name }}
                  {{ getCurrentProject().architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-3 mr-auto">
            <!-- REPEAT ROWS FOR EACH REVIEWER -->
            <div class="row mb-3" *ngFor="let reviewer of currentReviewers">
              <div class="col">
                <label class="main-label mt-0">
                  {{ (reviewer.status && reviewer.dateCreated) || 'Pending Review' }}
                </label>
                <p class="m-0">
                  <b>{{ reviewer.firstName }} {{ reviewer.lastName }}</b
                  ><br />
                  <span>{{ reviewer.title }}</span
                  ><br />
                  <span *ngIf="reviewer.status === 1" class="green font-weight-semibold mt-2 mb-2"> Approved </span>
                  <!-- <br />
                  <span class="dkgray">
                    {{ reviewer.comment }}
                  </span> -->
                </p>
              </div>
            </div>
            <div *ngIf="getCurrentProject().cost_codes" class="row">
              <div class="col bg-shade-gray mx-3 py-3" style="border-radius: 10px">
                <p><strong>FOR INTERNAL USE ONLY</strong></p>
                <p class="mt-2" style="font-size: 0.6rem">COST CODE(S)</p>
                <p class="mt-1" style="max-width: 50px">{{ getCurrentProject().cost_codes }}</p>
                <ng-container *ngIf="getCurrentProject().cost_code_description">
                  <p class="mt-2" style="font-size: 0.6rem">DESCRIPTION</p>
                  <p class="mt-1" [innerHTML]="getCurrentProject().cost_code_description | truncate: 150"></p>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-9 ml-auto pr-5">
            <div class="row mb-4 mt-2">
              <div class="col">
                <h4 class="m-0 font-weight-bold">Construction Budget</h4>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <label class="main-label mt-0"> Prepared For </label>
                <p class="m-0">
                  <b>{{ currentTenantInfo.name }}</b
                  ><br />
                  <span>{{ currentTenantInfo.title }}</span
                  ><br />
                  <span>{{ currentTenantInfo.department }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <p class="m-0">
                  <!-- TENANT REP FIRST NAME -->
                  {{ currentTenantInfo.first_name }}, <br /><br />
                  <!-- MESSAGE -->
                  <!--                  TODO save this is default?-->
                  <!--                  <span>-->
                  <!--                    We have developed a preliminary estimated budget for your project. Please review it carefully. If-->
                  <!--                    you approve of the defined budget, please check the appropriate box, sign, and re-attach the-->
                  <!--                    following budget page onto the task assigned to you. If you have any questions, please add your-->
                  <!--                    comments to the task. I will be notified of your comments and will respond accordingly. And lastly,-->
                  <!--                    if you would like to make a change to the budget, please check the reject box, sign and re-attach-->
                  <!--                    to the task.-->
                  <!--                    <br><br>-->
                  <!--                    Thank you for you prompt reply.-->
                  <!--                  </span>-->
                  <span style="white-space: pre-wrap" [innerHTML]="coverLetterDesc"> </span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: PROJECT MANAGER -->
                <p class="m-0">
                  <b>{{ pmInfo.name }}</b
                  ><br />
                  <span>{{ pmInfo.title }}</span
                  ><br />
                  <span>{{ pmInfo.email }}</span>
                  <br />
                  <span>{{ pmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: WORKSPACE MANAGER -->
                <p class="m-0" *ngIf="wmInfo">
                  <b>{{ wmInfo.name }}</b
                  ><br />
                  <span>{{ wmInfo.title }}</span
                  ><br />
                  <span>{{ wmInfo.email }}</span>
                  <br />
                  <span>{{ wmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: ARCHITECT -->
                <p class="m-0" *ngIf="architectInfo">
                  <!-- MIGHT PUT AN NGIF ON THE ARCHITECT TO ADD TIM'S CREDENTIALS?? -->
                  <b>{{ architectInfo.name }}</b
                  ><br />
                  <span>{{ architectInfo.title }}</span
                  ><br />
                  <span>{{ architectInfo.email }}</span>
                  <br />
                  <span>{{ architectInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>

<div class="kendo-content">
  <kendo-pdf-export #cbpdf paperSize="Letter" [landscape]="true" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content" *ngIf="project && cbData">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <section>
            <div class="row align-items-center mt-3">
              <div class="col-1">
                <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  <span class="accent"> 1call.cloud </span>
                  <br />
                  A service of University Hospital Authority & Trust
                </p>
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  1000 NE 13th St, Nicholson Tower, STE 6900
                  <br />
                  Oklahoma City, OK 73104
                </p>
              </div>
              <div class="col-2 pl-0">
                <p class="dkgray m-0">
                  ph 405.271.8001 <br />
                  fx 405.271.1301
                </p>
              </div>
              <div class="col-auto">
                <p class="dkgray m-0">
                  {{ getCurrentTime() }} <br />
                  {{ getCurrentDate() }}
                </p>
              </div>
              <div class="col-auto text-right">
                <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
              </div>
            </div>
          </section>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0 text-truncate">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> PEB Construction SF </label>
                <p>
                  {{ numberWithCommas(project.square_footage ?? 0) }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section class="form-approval mb-5">
          <div class="row align-items-top">
            <div class="col-3">
              <label class="main-label m-0">Your Review</label>
              <div class="mt-2">
                <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
                <p class="m-0 black d-inline-block align-middle">Approve this Budget</p>
              </div>
              <div class="mt-3">
                <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
                <p class="m-0 black d-inline-block align-middle">Request a Change</p>
              </div>
            </div>
            <div class="col">
              <label class="main-label m-0">Your Name (Print)</label>
              <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
              <label class="main-label">Your Signature</label>
              <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
            </div>
            <div class="col">
              <label class="main-label m-0">Funding Source</label>
              <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
              <label class="main-label">Signature Date</label>
              <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
            </div>
          </div>
        </section>

        <ng-container>
          <section class="form-title d-flex align-items-end mt-5">
            <h1 class="black mb-1">
              <b class="mr-4">Construction Budget</b>
              <small class="dkgray"> {{ selectedSection?.tenant_name }} </small>
            </h1>
            <h4 class="ml-auto mb-1">
              <small class="dkgray"> Section {{ selectedSectionIndex }}/{{ exportSectionsLength }} </small>
            </h4>
          </section>
          <section class="form-highlights">
            <div class="row">
              <div class="col">
                <label class="main-label mt-0">CONSTRUCTION SF</label>
                <p class="m-0 dkgray">{{ numberWithCommas(selectedSection?.construction_sqft ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">OCCUPIED SF</label>
                <p class="m-0 dkgray">{{ numberWithCommas(selectedSection?.occupied_sqft ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">CIRCULATION</label>
                <p class="m-0 dkgray">{{ project.building_circulation_factor ?? 0 }}%</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">RENTAL SF</label>
                <p class="m-0 font-weight-bold">{{ numberWithCommas(selectedSection?.osf_w_circ ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">Total Rent/ Yr</label>
                <!-- TODO: PEB | Adam - How do we calculate this? -->
                <p class="m-0 font-weight-bold"></p>
              </div>
            </div>
          </section>
          <section class="border-black border-bottom-heavy">
            <div class="row m-0 py-1 align-items-center">
              <div class="col-2 p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Trade Name</label>
              </div>
              <div class="col text-center p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">SF</label>
              </div>
              <div class="col text-right p-0" *ngFor="let fs of selectedSection?.funding_sources">
                <label class="main-label m-0 p-0 text-uppercase dkgray">{{ fs.abbreviation }}</label>
              </div>
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Cost/SF</label>
              </div>
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Total</label>
              </div>
            </div>
            <div class="row m-0" *ngFor="let l of selectedSection?.lines || []">
              <div class="col-2 p-0">
                <div class="border-primary border-top py-2">
                  <p class="m-0 full-width">{{ l.name }}</p>
                  <p class="gray">PEB Amount</p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-center">
                  <p class="m-0 full-width">{{ numberWithCommas(l.sqft || 0) || 0 }}</p>
                  <p class="gray">{{ l.peb_sqft === undefined ? 'N/A' : numberWithCommas(l.peb_sqft || 0) || 0 }}</p>
                </div>
              </div>
              <div class="col p-0" *ngFor="let fs of l.funding_sources; let fsi = index">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0 full-width">{{ fs?.item_value || 0 | USDollar }}</p>
                  <p class="gray">{{ fs.peb_value === undefined ? 'N/A' : (fs.peb_value | USDollar) }}</p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0">
                    {{ (!l.sqft ? 0 : (l.subtotal || 0) / l.sqft) | USDollar }}
                  </p>
                  <p class="gray">
                    {{ l.peb_sqft === undefined ? 'N/A' : ((l.peb_subtotal || 0) / l.peb_sqft | USDollar) }}
                  </p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0">
                    {{ l.subtotal | USDollar }}
                  </p>
                  <p class="gray">
                    {{ l.peb_subtotal === undefined ? 'N/A' : (l.peb_subtotal | USDollar) }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="row m-0">
              <div class="col-2 py-2 px-0">
                <p class="m-0 black">
                  <b> Total Budget </b>
                </p>
                <p class="gray">PEB Amounts</p>
              </div>
              <div class="col text-center py-2 px-0"></div>
              <div class="col py-2 px-0 text-right" *ngFor="let fs of selectedSection?.funding_sources">
                <p class="m-0">
                  <b>{{ fs.subtotal ?? 0 | USDollar }}</b>
                </p>
                <p class="gray">{{ fs.peb_subtotal === undefined ? 'N/A' : (fs.peb_subtotal ?? 0 | USDollar) }}</p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> {{ fs.abbreviation }} </label>
              </div>
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b
                    >{{
                      (parseNumber(selectedSection?.subtotal) || 0) / (selectedSection?.construction_sqft || 0)
                        | USDollar
                    }}
                  </b>
                </p>
                <p class="gray">
                  {{
                    (parseNumber(selectedSection?.peb_subtotal) || 0) / (selectedSection?.construction_sqft || 0)
                      | USDollar
                  }}
                </p>
                <label class="main-label m-0 py-2 px-0 text-uppercase dkgray"> COST/SF </label>
              </div>
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b>{{ selectedSection?.subtotal ?? 0 | USDollar }}</b>
                </p>
                <p class="gray">
                  {{
                    selectedSection?.peb_subtotal === undefined
                      ? 'N/A'
                      : (selectedSection?.peb_subtotal ?? 0 | USDollar)
                  }}
                </p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> TOTAL </label>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </kendo-pdf-export>
</div>
<div class="kendo-content">
  <kendo-pdf-export
    #summary
    paperSize="Letter"
    [landscape]="true"
    forcePageBreak=".page-break"
    [margin]="{ bottom: 50 }"
  >
    <div class="form-print-pdf" id="print-content" *ngIf="project && cbData">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <section>
            <div class="row align-items-center mt-3">
              <div class="col-1">
                <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  <span class="accent"> 1call.cloud </span>
                  <br />
                  A service of University Hospital Authority & Trust
                </p>
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  1000 NE 13th St, Nicholson Tower, STE 6900
                  <br />
                  Oklahoma City, OK 73104
                </p>
              </div>
              <div class="col-2 pl-0">
                <p class="dkgray m-0">
                  ph 405.271.8001 <br />
                  fx 405.271.1301
                </p>
              </div>
              <div class="col-auto">
                <p class="dkgray m-0">
                  {{ getCurrentTime() }} <br />
                  {{ getCurrentDate() }}
                </p>
              </div>
              <div class="col-auto text-right">
                <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
              </div>
            </div>
          </section>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0 text-truncate">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> PEB Construction SF </label>
                <p>
                  {{ numberWithCommas(project.square_footage ?? 0) }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ng-container>
          <section class="form-title d-flex align-items-end mt-5">
            <h1 class="black mb-1">
              <b class="mr-4">Construction Budget</b>
              <small class="dkgray"> Project Totals </small>
            </h1>
            <h4 class="ml-auto mb-1">
              <small class="dkgray"> All Sections </small>
            </h4>
          </section>
          <section class="border-black border-bottom-heavy">
            <div class="row m-0 py-1 align-items-center">
              <div class="col-2 p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Trade Name</label>
              </div>
              <div class="col text-right p-0" *ngFor="let s of cbData?.sections">
                <label class="main-label m-0 p-0 text-uppercase dkgray">{{ s.name }}</label>
              </div>
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Total</label>
              </div>
            </div>
            <div class="row m-0" *ngFor="let l of selectedSection?.lines || []; let i = index">
              <div class="col-2 p-0">
                <div class="border-primary border-top py-2">
                  <p class="m-0 full-width">{{ l.name }}</p>
                  <p class="gray">PEB Amount</p>
                </div>
              </div>
              <div class="col p-0" *ngFor="let s of cbData?.sections; let si = index">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0 full-width">{{ s.lines?.length && (s.lines[i]?.subtotal ?? 0) | USDollar }}</p>
                  <p class="gray">
                    {{
                      s.lines[i]?.peb_subtotal === undefined
                        ? 'N/A'
                        : (s.lines?.length && (s.lines[i]?.peb_subtotal ?? 0) | USDollar)
                    }}
                  </p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0">{{ cbData?.lines[i]?.total ?? 0 | USDollar }}</p>
                  <p class="gray">
                    {{
                      selectedSection?.lines[i].peb_total === undefined
                        ? 'N/A'
                        : (selectedSection?.lines[i].peb_total ?? 0 | USDollar)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="row m-0">
              <div class="col-2 py-2 pl-0">
                <p class="m-0 black">
                  <b> Total Budget </b>
                </p>
              </div>
              <!-- <div class="col text-center py-2 px-0"></div> -->
              <div class="col py-2 px-0 text-right" *ngFor="let s of cbData?.sections">
                <p class="m-0">
                  <b>{{ s.subtotal ?? 0 | USDollar }}</b>
                </p>
                <p class="gray">{{ s.peb_subtotal ?? 0 | USDollar }}</p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> {{ s.name }} </label>
              </div>
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b>{{ cbData?.total ?? 0 | USDollar }}</b>
                </p>
                <p class="gray">{{ cbData?.peb_total ?? 0 | USDollar }}</p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> TOTAL </label>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </kendo-pdf-export>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div *ngIf="this.peb && this.pebIsFinalized" class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ getCurrentProject().code }} | {{ getCurrentProject().building_code }}-{{
                    getCurrentProject().floor_code
                  }}
                  | {{ getCurrentProject().title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ getCurrentProject().building_code }}-{{ getCurrentProject().floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ getCurrentProject().project_manager_first_name }}
                  {{ getCurrentProject().project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p>
                  {{
                    getCurrentProject().square_footage
                      ? numberWithCommas(getCurrentProject().square_footage)
                      : 'Not Given'
                  }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ getCurrentProject().end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ getCurrentProject().architect_first_name }}
                  {{ getCurrentProject().architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-approval mb-5">
        <div class="row align-items-top">
          <div class="col-auto">
            <label class="main-label m-0">Your Review</label>
            <div class="mt-2">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Approve Construction Costs</p>
            </div>
            <div class="mt-3">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Request a Change</p>
            </div>
          </div>
          <div class="col">
            <label class="main-label m-0">Your Name (Print)</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Your Signature</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
          <div class="col">
            <label class="main-label m-0">Funding Source</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Signature Date</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
        </div>
      </section>
      <div>
        <section class="form-title">
          <div class="row">
            <div class="col">
              <h1 class="black font-weight-bold m-0">Construction Budget</h1>
              <p class="m-0">
                Prepared for:
                <b>
                  {{ selectedTenant.type_id === 3 ? 'UHAT' : selectedTenant.tenant_name }}
                </b>
              </p>
            </div>
          </div>
        </section>
        <section class="form-highlights">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <label class="main-label mt-0">Total Project</label>
                  <p class="m-0 font-weight-bold">
                    {{ trustTotalCost + tenantTotalCost || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Trust Investment</label>
                  <p class="m-0">
                    {{ trustTotalCost || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Tenant Allowance</label>
                  <p class="m-0">
                    {{ this.peb.tenant_allowance || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Tenant Investment</label>
                  <p class="m-0 font-weight-bold">
                    {{ tenantTotalCost || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Total Rent/ Yr</label>
                  <p class="m-0 font-weight-bold">
                    {{ this.peb.total_rent_per_year || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row form-border-divider"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="main-label mt-0">Design</label>
                  <h6 class="m-0">
                    {{ this.peb.design_timeline }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Construction</label>
                  <h6 class="m-0">
                    {{ this.peb.construction_timeline }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Constr. SF</label>
                  <h6 class="m-0">
                    {{ numberWithCommas(this.peb.construction_sqft) }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Occupied SF</label>
                  <h6 class="m-0">
                    {{ numberWithCommas(this.peb.occupied_sqft) }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Circulation</label>
                  <h6 class="m-0">{{ project.building_circulation_factor }}%</h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Rental SF</label>
                  <h6 class="m-0">{{ this.peb.rental_sqft_w_circ }} SF</h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Rental Rate</label>
                  <h6 class="m-0">
                    {{ this.peb.rental_rate || 0 | USDollar: 2:true:true:true }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="form-table mb-4 mt-3"
          *ngFor="let s of cbSections"
          [hidden]="
            (s.owner_type_id === 1 && +tenantTotalCost === 0) || (s.owner_type_id === 2 && +trustTotalCost === 0)
          "
        >
          <div class="row form-section-title">
            <div class="col">
              <p class="black m-0 font-weight-bold">
                {{ s.name }}
              </p>
            </div>
          </div>
          <div class="row form-table-header align-items-center">
            <div class="col-1 pl-0 pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Item </label>
            </div>
            <div class="col-5">
              <label class="main-label m-0 p-0 text-uppercase"> Trade / Cost </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Budget </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Total Cost </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Difference </label>
            </div>
          </div>
          <div
            class="row"
            *ngFor="
              let i of s.owner_type_id === 1 ? tenantItems : s.owner_type_id === 2 ? trustItems : [];
              index as elementIndex
            "
            [hidden]="+i.subtotal === 0 && !i.budget"
          >
            <div class="col-12">
              <div class="row form-table-line-item">
                <div class="col-1 pl-0 pr-0">
                  <p class="mdgray m-0 manage-content">
                    {{ elementIndex + 1 }}
                  </p>
                </div>
                <div class="col-5">
                  <p class="m-0 manage-content">
                    {{ i.name }}
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0">
                    <span class="dkblue">{{ i.budget || 0 | USDollar: 2:true:true:true }}</span>
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0 font-weight-semibold">
                    {{ i.subtotal || 0 | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0">
                    <span class="gray">{{ +i.budget - +i.subtotal || 0 | USDollar: 2:true:true:true }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div class="row mt-2 m-0 form-section-footer">
              <div class="col pl-0">
                <p style="font-size: 0.7rem; margin-right: 0px !important" class="d-inline-block font-weight-semibold">
                  Subtotal: {{ s.name }}
                </p>
              </div>
              <div class="col-2 text-right pr-0">
                <p style="font-size: 0.7rem; margin-right: 0px !important" class="">
                  {{
                    (s.owner_type_id === 1 ? +tenantTotalBudget : s.owner_type_id === 2 ? +trustTotalBudget : 0) || 0
                      | USDollar: 2:true:true:true
                  }}
                </p>
              </div>
              <div class="col-2 text-right pr-0">
                <p style="font-size: 0.7rem; margin-right: 0px !important" class="font-weight-bold">
                  {{
                    (s.owner_type_id === 1 ? +tenantTotalCost : s.owner_type_id === 2 ? +trustTotalCost : 0) || 0
                      | USDollar: 2:true:true:true
                  }}
                </p>
              </div>
              <div class="col-2 text-right pr-0">
                <p style="font-size: 0.7rem; margin-right: 0px !important" class="gray">
                  {{
                    (s.owner_type_id === 1
                      ? +tenantTotalBudget - +tenantTotalCost
                      : s.owner_type_id === 2
                      ? +trustTotalBudget - +trustTotalCost
                      : 0) || 0 | USDollar: 2:true:true:true
                  }}
                </p>
              </div>
            </div>
          </section>
        </section>
        <section class="form-totals">
          <div class="row m-0">
            <div class="col pl-0">
              <p style="font-size: 0.7rem; margin-right: 0px !important" class="m-0 black font-weight-bold">
                Total Construction Cost
              </p>
            </div>
            <div class="col-2 text-right pr-0">
              <p style="font-size: 0.7rem; margin-right: 0px !important" class="m-0 black font-weight-bold">
                {{ tenantTotalBudget + trustTotalBudget || 0 | USDollar: 2:true:true:true }}
              </p>
            </div>
            <div class="col-2 text-right pr-0">
              <p style="font-size: 0.7rem; margin-right: 0px !important" class="m-0 black font-weight-bold">
                {{ tenantTotalCost + trustTotalCost || 0 | USDollar: 2:true:true:true }}
              </p>
            </div>
            <div class="col-2 text-right pr-0">
              <p style="font-size: 0.7rem; margin-right: 0px !important" class="m-0 gray font-weight-bold">
                {{
                  tenantTotalBudget + trustTotalBudget - (tenantTotalCost + trustTotalCost) || 0
                    | USDollar: 2:true:true:true
                }}
              </p>
            </div>
          </div>
        </section>
        <section id="comments" class="mt-5 mb-5">
          <div class="row">
            <div class="col">
              <label class="main-label m-0">Comments</label>
              <div class="row m-0 border-bottom border-dark pb-3"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
            </div>
          </div>
        </section>
        <!-- <div *ngIf="1 < PEBsToExport.length - 1" class="page-break"></div> -->
      </div>
    </div>
  </kendo-pdf-export>
</div>
