import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/types';
import { AssignUserDialogComponent } from 'src/app/components';

@Component({
  selector: 'app-assigned-button',
  templateUrl: './assigned-button.component.html',
  styleUrls: ['./assigned-button.component.scss'],
})
export class AssignedButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() requested_by: User;
  @Output() attachRequester = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  get requester_id() {
    return this.requested_by?.id;
  }

  get requester_name() {
    return `${this.requested_by?.first_name || ''} ${this.requested_by?.last_name || ''}`.trim();
  }
  public openAssignUserModal() {
    const user = this.requested_by || {};
    this.dialog
      .open(AssignUserDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { ...user, displayLabel: 'Requester', displayTitle: 'Search Requester' },
      })
      .afterClosed()
      .subscribe(async (selectedUser) => {
        if (selectedUser) {
          this.attachRequester.emit(selectedUser);
        }
      });
  }
}
