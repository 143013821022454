import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services';
@Component({
  selector: 'app-routing-project',
  templateUrl: './project-routing.component.html',
  styleUrls: ['./project-routing.component.scss'],
})
export class ProjectRoutingComponent implements OnInit {
  constructor(private projectService: ProjectService, private route: ActivatedRoute) {}
  async ngOnInit() {
    this.route.params.subscribe(async ({ id }) => {
      if (!this.projectService.currentSelectedProject || +this.projectService.currentSelectedProject.id !== +id) {
        await this.projectService.selectProjectById(id);
      }
    });
  }

  get workspaceId(): number {
    return this.projectService?.currentSelectedProject?.module_id;
  }
}
