<app-base-dialog [title]="'New Account'" (closeDialog)="cancel()" [draggable]="false"> </app-base-dialog>
<div
  id="new-account-modal"
  data-test-id="new-account-modal"
  class="new-account-modal project-modal p-0"
  style="min-width: 380px"
>
  <div mat-dialog-content>
    <form [formGroup]="userInfoFormGroup">
      <div class="d-flex flex-column">
        <h4 class="dkblue my-4">Account Information</h4>
        <div class="row">
          <mat-form-field appearance="outline" class="col-lg-6">
            <mat-label>First Name</mat-label>
            <input matInput type="text" required formControlName="first_name" />
            <mat-error *ngIf="first_name.errors?.required">First name is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-6">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" required formControlName="last_name" />
            <mat-error *ngIf="last_name.errors?.required">Last name is required</mat-error>
          </mat-form-field>
        </div>
        <form [formGroup]="userContactFormGroup">
          <div class="row">
            <mat-form-field appearance="outline" class="col-lg-6">
              <mat-label>Email Address</mat-label>
              <input
                matInput
                type="text"
                required
                formControlName="email"
                appUniqueEmail
                (change)="emailTrim()"
                (blur)="emailChanged()"
              />
              <mat-error *ngIf="email.errors?.required">Email address is required</mat-error>
              <mat-error *ngIf="!email.errors?.required && email.errors?.email">Email address is invalid</mat-error>
              <mat-error *ngIf="!email.errors?.email && email.errors?.unique">Email already exists!</mat-error>
            </mat-form-field>
            <div [formGroup]="userTypeFormGroup" class="col-lg-6">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>User Type</mat-label>
                <mat-select matNativeControl required formControlName="user_type">
                  <mat-option *ngFor="let ut of acceptedUserTypes" [value]="ut">{{ ut.name }} </mat-option>
                </mat-select>
                <mat-error *ngIf="user_type.errors?.required">User type is required</mat-error>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="col-lg-6">
              <mat-label>Office Number</mat-label>
              <input matInput type="text" formControlName="office_phone" />
              <mat-error *ngIf="office_phone.errors?.required">Office number is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-lg-6">
              <mat-label>Cell Number</mat-label>
              <input matInput type="text" formControlName="cell_phone" />
              <mat-error *ngIf="cell_phone.errors?.required">Cell number is required</mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="row">
          <mat-form-field appearance="outline" class="col-lg-6">
            <mat-label>Company</mat-label>
            <input
              matInput
              type="text"
              [required]="user_type.value && (user_type.value.id === 1 || user_type.value.id === 3)"
              formControlName="company"
              [matAutocomplete]="companyAuto"
              (ngModelChange)="searchBoxCompanyValueChange()"
              (click)="searchBoxCompanyValueChange()"
            />
            <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="companySearchService.defaultCompanyMapper">
              <mat-option
                [value]="company"
                *ngFor="let company of filteredCompanies | filter: user_type.value.id:['type_id']"
                (onSelectionChange)="fireCompanyAssigneeSelectionChange(company)"
              >
                <span *ngIf="company">{{ company.name }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="company.errors?.required">Company is required</mat-error>
            <mat-error *ngIf="!company.errors?.required && company.errors?.isValidCompany"
              >Must be an existing company</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-6">
            <mat-label>Title</mat-label>
            <input matInput type="text" formControlName="title" />
            <mat-error *ngIf="title.errors?.required">Title is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-6" *ngIf="user_type.value.id !== 3">
            <mat-label>Manager</mat-label>
            <input
              matInput
              type="text"
              [required]="user_type.value && user_type.value.id === 1"
              [matAutocomplete]="managerAuto"
              formControlName="manager"
              (ngModelChange)="searchBoxManagerValueChange()"
            />
            <mat-autocomplete #managerAuto="matAutocomplete" [displayWith]="userSearchService.defaultUserMapper">
              <mat-option
                *ngFor="let user of filteredUsers"
                [value]="user"
                (onSelectionChange)="fireManagerAssigneeSelectionChange(user)"
              >
                <span *ngIf="user">{{ user.first_name }} {{ user.last_name }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="manager.errors?.required">Manager is required</mat-error>
            <mat-error *ngIf="!manager.errors?.required && manager.errors?.isValidManager"
              >Must be an existing user
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-lg-6" [hidden]="user_type.value.id !== UserType.Staff">
            <mat-label>Default Workspace</mat-label>
            <mat-select
              matNativeControl
              formControlName="default_module_id"
              [required]="user_type.value && user_type.value.id === UserType.Staff"
            >
              <mat-option *ngFor="let ws of workspaces" [value]="ws.id">{{ ws.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="default_module_id.errors?.required">Workspace is required</mat-error>
          </mat-form-field>
          <div class="col-lg-6" *ngIf="user_type.value.id === 1">
            <mat-slide-toggle class="py-3" color="accent" formControlName="is_email_enabled">
              Enable Emails
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="userLocationFormGroup">
      <h4 class="dkblue my-4">Location Information</h4>
      <div class="row">
        <mat-form-field appearance="outline" class="col-lg-6">
          <mat-label>Building</mat-label>
          <mat-select matNativeControl (selectionChange)="getFloors()" formControlName="building">
            <mat-option label="Building"></mat-option>
            <mat-option *ngFor="let b of buildings" [value]="b">{{ b.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="building.errors?.required">Building is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-6">
          <mat-label>Floor</mat-label>
          <mat-select matNativeControl (selectionChange)="getSuites()" formControlName="floor">
            <mat-option label="Floor"></mat-option>
            <mat-option *ngFor="let f of floors" [value]="f">{{ f.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="floor.errors?.required">Floor is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-6">
          <mat-label>Suite</mat-label>
          <mat-select matNativeControl (selectionChange)="getDepartments()" formControlName="suite">
            <mat-option label="Suite"></mat-option>
            <mat-option *ngFor="let s of suites" [value]="s">{{ s.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="suite.errors?.required">Suite is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-6">
          <mat-label>Department</mat-label>
          <mat-select matNativeControl formControlName="department">
            <mat-option label="Department"></mat-option>
            <mat-option *ngFor="let d of departments" [value]="d">{{ d.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="department.errors?.required">Department is required</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="row py-3">
    <div class="col">
      <button (click)="cancel()" mat-button type="button" class="gray full-width">Cancel</button>
    </div>
    <div *ngIf="!loaders.creatingUser" class="col">
      <button
        mat-flat-button
        class="full-width"
        color="success"
        type="submit"
        [disabled]="!validInfo"
        (click)="createUser()"
      >
        Submit Account
      </button>
    </div>
    <div *ngIf="loaders.creatingUser" class="col d-flex justify-content-center">
      <button mat-flat-button color="success" type="button">
        <span><i class="fas fa-circle-notch fa-spin"></i></span>
      </button>
    </div>
  </div>
</div>
