<!-- ChangeOrder modal accessory button. Clicking this will open a modal to display the change order -->
<span class="full-width" *ngIf="type == TaskAccessoryType.ChangeOrder">
  <div class="mt-2 m-portlet mb-4 full-width" *ngIf="showExtras">
    <div class="m-portlet__body p-3">
      <div class="row align-items-center">
        <div class="col-auto pr-0">
          <mat-icon color="accent" class="mt-1" style="font-size: 30px; height: 30px">description</mat-icon>
        </div>
        <div class="col">
          <span *ngIf="pco">
            <p (click)="openChangeOrderModal()" class="dkblue m-0 pointer">
              <span class="font-weight-semibold ltblue">Proposed Change Order</span>
              <br />
              <span class="d-block text-truncate" [innerHTML]="pco.short_description"></span>
            </p>
          </span>
        </div>
        <div class="col-auto">
          <button mat-button color="accent" type="button" class="pl-3 pr-3 lh-34" (click)="openChangeOrderModal()">
            View
          </button>
        </div>
      </div>
    </div>
  </div>
  <button
    *ngIf="reviewEnabledForUser && !showExtras"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openChangeOrderModal()"
    [disabled]="isLocked"
  >
    Review CO
  </button>
</span>

<!-- PEB accessory button. Clicking this will navigate to the peb page -->
<span *ngIf="type == TaskAccessoryType.PEB">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
    [disabled]="isLocked"
  >
    Review PEB
  </button>
  <!-- <button
    *ngIf="!startReview"
    mat-button
    type="button"
    color="accent"
    (click)="gotoPebPage()"
    class="mr-3"
    matTooltip="Go to PEB"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
  >
    Go to PEB
  </button> -->
</span>

<!-- Punchlist accessory button. Clicking this will navigate to the punchlist page -->
<span *ngIf="type == TaskAccessoryType.Punchlist">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
    [disabled]="isLocked"
  >
    Review PL
  </button>
  <!-- <button
    *ngIf="!startReview"
    mat-button
    type="button"
    color="accent"
    (click)="gotoPunchlistPage()"
    class="mr-3"
    matTooltip="Go to Punchlist"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
  >
    Punchlist
  </button> -->
</span>

<!-- Reviews no connected to any type -->
<span *ngIf="type == TaskAccessoryType.Other">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview && userIsCurrentReviewer"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
  >
    Add Your Review
  </button>
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview && isReviewerAdmin && !userIsCurrentReviewer"
    mat-flat-button
    color="warn"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
  >
    Expedite
  </button>
  <!-- <button
    *ngIf="!startReview"
    mat-button
    type="button"
    color="accent"
    (click)="openTaskReviewModal()"
    class="mr-3"
    [matTooltip]="
      !reviewEnabledForUser ? 'Must be the task assignee to review' : 'View Change Order'
    "
  >
    View Items
  </button> -->
</span>

<!-- Cb accessory button. Clicking this will navigate to the cb page -->
<span *ngIf="type == TaskAccessoryType.CB">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
    [disabled]="isLocked"
  >
    Review CB
  </button>
  <!-- <button
    *ngIf="!startReview"
    mat-button
    type="button"
    color="accent"
    (click)="gotoCbPage()"
    class="mr-3"
    matTooltip="Go to CB"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
  >
    Go to CB
  </button> -->
</span>

<!-- arf budget accessory button. Clicking this will navigate to the review arf budget modal -->
<span *ngIf="type == TaskAccessoryType.Arf">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openArfReviewModal()"
    [disabled]="isLocked || isLoading"
  >
    <span *ngIf="!isLoading"> Review ARF </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="accent"
      [diameter]="20"
    ></mat-spinner>
  </button>
</span>

<span *ngIf="type === TaskAccessoryType.KeyControls">
  <button mat-flat-button color="primary" type="button" class="pl-3 pr-3 lh-34" (click)="openKeyControlsReviewModal()">
    {{ task?.status_id === 3 ? 'Review' : 'View' }} Key Control
  </button>
</span>

<!-- budget accessory button. Clicking this will navigate to the review budget modal -->
<span *ngIf="type == TaskAccessoryType.Budget">
  <button
    *ngIf="data.isReviewItem && reviewEnabledForUser && startReview"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openTaskReviewModal()"
    [disabled]="isLocked"
  >
    Review Budget
  </button>
</span>

<span *ngIf="type === TaskAccessoryType.Submittals">
  <button
    mat-flat-button
    color="primary"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openSubmittalsReviewDialog()"
    [disabled]="isLocked || isLoading"
  >
    <span *ngIf="!isLoading"> Review Submittals </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="accent"
      [diameter]="20"
    ></mat-spinner>
  </button>
</span>

<!-- Cb accessory button. Clicking this will navigate to the cb page -->
<span class="full-width" *ngIf="type == TaskAccessoryType.ProposalRequest">
  <div class="mt-2 m-portlet mb-4 full-width" *ngIf="showExtras">
    <div class="m-portlet__body p-3">
      <div class="row align-items-center">
        <div class="col-auto pr-0">
          <mat-icon color="accent" class="mt-1" style="font-size: 30px; height: 30px">description</mat-icon>
        </div>
        <div class="col">
          <span *ngIf="proposalRequest">
            <p (click)="openViewPRModal()" class="dkblue m-0 pointer">
              <span class="font-weight-semibold ltblue">Proposal Request {{ proposalRequest.local_index }}</span>
              <br />
              <span class="d-block text-truncate">{{ proposalRequest.title }}</span>
            </p>
          </span>
        </div>
        <div class="col-auto">
          <button mat-button color="accent" type="button" class="pl-3 pr-3 lh-34" (click)="openViewPRModal()">
            View PR
          </button>
        </div>
      </div>
    </div>
  </div>
  <button
    *ngIf="reviewEnabledForUser && !showExtras"
    mat-flat-button
    color="success"
    type="button"
    class="pl-3 pr-3 lh-34"
    (click)="openAddPCOModal()"
    [disabled]="isLocked"
  >
    Add PCO
  </button>
</span>
<span class="full-width" *ngIf="type === TaskAccessoryType.ProjectTimeline">
  <button
    *ngIf="
      reviewEnabledForUser &&
      (currentActiveReviewChainItem.needs_signature || currentUserReviewChainItem?.needs_signature)
    "
    mat-flat-button
    class="align-middle bg-gold white"
    (click)="openProjectScheduleContractReview()"
    [disabled]="isLoading || isLocked"
  >
    <span *ngIf="!isLoading"> Review & Sign </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="primary"
      [diameter]="20"
    ></mat-spinner>
  </button>
  <button
    *ngIf="
      reviewEnabledForUser &&
      currentActiveReviewChainItem.id === currentUserId &&
      !currentActiveReviewChainItem.needs_signature
    "
    mat-flat-button
    class="align-middle bg-gold white"
    (click)="openTaskReviewModal()"
    [disabled]="isLoading || isLocked"
  >
    <span *ngIf="!isLoading"> Review Contract </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="primary"
      [diameter]="20"
    ></mat-spinner>
  </button>
</span>
<span
  class="full-width"
  *ngIf="
    type === TaskAccessoryType.BidContract && authService.currentUser.user_type_id === UserType.Vendor && !isLocked
  "
>
  <div class="m-portlet full-width p-3 d-flex align-items-center bg-shade-ltblue">
    <mat-icon class="gold mr-3 accessory-icon"> task </mat-icon>
    <div class="d-flex flex-column mr-auto text-truncate">
      <p class="font-weight-semibold dkblue m-0">Construction Contract</p>
      <p class="font-weight-normal dkgray m-0">{{ bidPackage?.number }} - {{ bid?.trade?.name }}</p>
    </div>
    <button
      *ngIf="reviewEnabledForUser && currentActiveReviewChainItem.needs_signature"
      mat-flat-button
      color="primary"
      class="bg-gold white"
      (click)="openBidContractDialog()"
      [disabled]="isLoading || isLocked || needsToBeRevised"
      [ngClass]="{
        'bg-gold': !isLocked && !needsToBeRevised,
        'bg-gray': isLocked || needsToBeRevised
      }"
    >
      <span *ngIf="!isLoading"> Review & Sign </span>
      <mat-spinner
        *ngIf="isLoading"
        class="d-inline-block align-middle m-0 ml-auto"
        color="primary"
        [diameter]="20"
      ></mat-spinner>
    </button>
  </div>
</span>
<span
  class="full-width"
  *ngIf="type === TaskAccessoryType.BidContract && authService.currentUser.user_type_id !== UserType.Vendor"
>
  <button
    *ngIf="
      reviewEnabledForUser &&
      (currentActiveReviewChainItem.needs_signature || currentUserReviewChainItem?.needs_signature)
    "
    mat-flat-button
    color="primary"
    class="bg-gold white"
    (click)="openBidContractDialog()"
    [disabled]="isLoading || isLocked"
  >
    <span *ngIf="!isLoading"> Review & Sign </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="primary"
      [diameter]="20"
    ></mat-spinner>
  </button>
  <button
    *ngIf="
      reviewEnabledForUser &&
      currentActiveReviewChainItem.id === currentUserId &&
      !currentActiveReviewChainItem.needs_signature
    "
    mat-flat-button
    color="primary"
    class="bg-gold white"
    (click)="openTaskReviewModal()"
    [disabled]="isLoading || isLocked"
  >
    <span *ngIf="!isLoading"> Review Contract </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="primary"
      [diameter]="20"
    ></mat-spinner>
  </button>
  <button
    *ngIf="
      bid?.bid_contract?.status_id === BidContractStatus.RevisionRequested && currentPM === authService.currentUser?.id
    "
    mat-flat-button
    color="primary"
    class="bg-gold white"
    (click)="openBidContractDialog()"
    [disabled]="isLoading"
  >
    <span *ngIf="!isLoading"> Resend Contract </span>
    <mat-spinner
      *ngIf="isLoading"
      class="d-inline-block align-middle m-0 ml-auto"
      color="primary"
      [diameter]="20"
    ></mat-spinner>
  </button>
</span>
<div *ngIf="type === TaskAccessoryType.Invoice && invoice?.approval_task_accessory_data">
  <app-invoice-review-buttons
    #invoiceButtons
    [invoice]="invoice"
    [disableReview]="false"
    [display]="false"
  ></app-invoice-review-buttons>
</div>
