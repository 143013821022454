import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal',
})
export class OrdinalPipe implements PipeTransform {
  transform(value: string | number, ...args: unknown[]): unknown {
    if (!value && value !== 0) {
      return '';
    }
    const stringValue = value.toString();
    const lastDigit = stringValue.length > 0 ? stringValue.charAt(stringValue.length - 1) : '';
    const tensDigit = stringValue.length > 1 ? stringValue.charAt(stringValue.length - 2) : '';
    let returnValue;
    switch (lastDigit) {
      case '0':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        returnValue = `${value}th`;
        break;
      case '1':
        if (tensDigit === '1') {
          returnValue = `${value}th`;
        } else {
          returnValue = `${value}st`;
        }
        break;
      case '2':
        if (tensDigit === '1') {
          returnValue = `${value}th`;
        } else {
          returnValue = `${value}nd`;
        }
        break;
      case '3':
        if (tensDigit === '1') {
          returnValue = `${value}th`;
        } else {
          returnValue = `${value}rd`;
        }
        break;
      default:
        returnValue = value;
        break;
    }
    return returnValue ?? value;
  }
}
