import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { orderBy, uniq } from 'lodash';
import { ProjectStatus, ResourceType } from 'src/app/enums';
import { ProjectService } from 'src/app/services';
import { APIFilter } from 'src/app/types';

@Component({
  selector: 'app-forms',
  templateUrl: './construction-access.component.html',
  styleUrls: ['./construction-access.component.scss'],
})
export class ConstructionAccessComponent implements OnInit {
  suppliers = [];
  ResourceType = ResourceType;
  formGroup: FormGroup = this.fb.group({
    manager: [null, [Validators.required]],
    project: [null, [Validators.required]],
    suppliers: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });
  projectsFilter: APIFilter[] = [{ type: 'field', field: 'status_id', value: ProjectStatus.CLOSED, match: '!=' }];
  constructor(private fb: FormBuilder, private projectService: ProjectService) {}

  ngOnInit(): void {
    this.formGroup.controls['project'].valueChanges.subscribe((p) => {
      this.projectSelected(p);
    });
  }

  displayFn(p) {
    if (!p) return;
    let displayString = p ? `PRJ ${p.code}${p.title ? ` - ${p.title}` : ''}` : '';
    displayString += p.building?.name ? ` | Building:  ${p.building?.name}` : '';
    displayString += p.floor?.name ? ` | Floor: ${p.floor?.name}` : '';
    return displayString;
  }

  suppliersDisplayFn(s) {
    return s?.name;
  }

  async projectSelected(project: any) {
    this.formGroup.controls['suppliers'].setValue(null);
    this.formGroup.controls['manager'].setValue(null);
    if (!project?.id) return;
    const bidFilter: APIFilter[] = [{ type: 'field', field: 'project_id', value: project.id }];
    const bids = await this.projectService.getBids(bidFilter, ['company']).toPromise();
    let uniqueCompanies = uniq(bids.map((b: any) => b.company?.name));
    this.suppliers = orderBy(uniqueCompanies);

    this.formGroup.controls['manager'].setValue(project.project_manager);
  }

  getValue() {
    const form = this.formGroup.value;
    const datePipe = new DatePipe('en-US');
    const value = [
      {
        label: 'Project',
        value: `PRJ ${form.project?.code}${form.project?.title ? ` - ${form.project?.title}` : ''}`,
      },
      {
        label: 'Building',
        value: form.project?.building?.name,
      },
      {
        label: 'Floor',
        value: form.project?.floor?.name,
      },
      {
        label: 'Project Manager',
        value: form.manager?.first_name + ' ' + form.manager?.last_name,
      },
      {
        label: 'Suppliers',
        value: form.suppliers ? form.suppliers.join(', ') : '',
      },
      {
        label: 'Start Date',
        value: datePipe.transform(form.startDate, 'MM/dd/yyyy'),
      },
      {
        label: 'End Date',
        value: datePipe.transform(form.endDate, 'MM/dd/yyyy'),
      },
    ];
    return value;
  }

  get formIsValid() {
    return this.formGroup.valid;
  }
}
