import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { BugReportService, FileService, ProjectService } from 'src/app/services';
import { BugReport, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-files-upload-modal',
  templateUrl: './files-upload-modal.component.html',
  styleUrls: ['./files-upload-modal.component.scss'],
})
export class FilesUploadModalComponent implements OnInit {
  // Input files that are to be uploaded, containing an upload status as well
  public filesToUpload: {
    status: 'uploading' | 'complete' | 'error' | 'waiting' | 'duplicate';
    fileData: File;
  }[] = [];

  // Files that uploaded successfully and returned a database result
  public uploadedFiles: UhatFileReference[] = [];

  // Files that failed to upload
  public failedFiles: File[] = [];

  // If the operations of this component are complete
  public operationComplete: boolean;

  // Resource type to set as the files parent
  private parentResourceType: ResourceType;

  // Resource Id to set as the files default parent
  private parentResourceId: number;

  // the tag ids to add to these uploaded files on creation
  private tagIds: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private errorService: BugReportService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.parentResourceType = this.data.parentResourceType;
    this.parentResourceId = this.data.parentResourceId;
    if (this.data.hasOwnProperty('tagIds')) {
      this.tagIds = this.data.tagIds;
    }
    if (!this.parentResourceType || !this.parentResourceId) {
      this.close();
      return;
    }
    if (this.data.files.length > 0) {
      this.filesToUpload = this.data.files.map((f) => ({ status: 'waiting', fileData: f }));
      this.beginUploading().then((result) => {
        this.operationComplete = true;
        if (this.data.autoClose) {
          this.submit();
        }
      });
    } else {
      // Just close if no files were given to upload
      this.operationComplete = true;
    }
  }

  submit() {
    this.dialogRef.close({
      successfulFiles: this.uploadedFiles,
      failedFiles: this.failedFiles,
    });
    this.uploadedFiles = [];
  }

  close() {
    this.dialogRef.close(false);
  }

  /**
   * Begins the upload process for all files. This will upload 1 file at a time, setting the files upload status for the view
   */
  private async beginUploading() {
    // Keep a list of files that were not successfully uploaded
    for (const fileToUpload of this.filesToUpload) {
      fileToUpload.status = 'uploading';
      await this.uploadFile(fileToUpload.fileData)
        .toPromise()
        .then((res) => {
          res.file_id = res.id; // The returned file is from the link (bridge) table, but we are going to use file_id to create additional links later
          fileToUpload.status = 'complete';
          if (this.tagIds) {
            res.tag_ids = this.tagIds;
          }
          this.uploadedFiles.push(res);
        })
        .catch((error) => {
          this.failedFiles.push(fileToUpload.fileData);
          if (!error.data || !error.data.duplicate) {
            fileToUpload.status = 'error';
            const errorSnack = this.snackbar.open(error.error.message, 'Report', {
              duration: 5000,
              panelClass: ['action'],
            });
            errorSnack.onAction().subscribe(async () => {
              const errorString = `Status: ${error.status}\nReason: ${error.error.error}\nMessage: ${error.error.message}`;
              const reportToSend: BugReport = {
                subject: 'Snackbar Report',
                inquiry_type_id: 4, // id 4 = snackbar report
                should_contact: 1,
                message: errorString,
                route: error.url,
                follower_ids: '[]',
              };
              // get the token directly to avoid circular dependencies
              await this.errorService.logError(reportToSend, !!localStorage.getItem('auth_token')).toPromise();
              this.snackbar.open(`This error has been reported and will be fixed as soon as possible!`);
            });
          } else {
            this.snackbar.open(`Error: File name already Exists`);
            fileToUpload.status = 'duplicate';
          }
        });
    }
  }

  /**
   * Debug method for testing the file spinners and things without uploading a file
   * @param file The file to "upload"
   */
  private debugFileTest(file: File): Observable<any> {
    const obs = new Observable((observable) => {
      const interval = setInterval(() => {
        observable.complete();
      }, 3000);
    });
    return obs;
  }

  /**
   * Upload a single file
   * @param file File to upload
   */
  public uploadFile(file: File): Observable<any> {
    const uploadedFile: any = file;
    if (this.tagIds) {
      uploadedFile.tag_ids = this.tagIds;
    }
    return this.fileService.createFile(uploadedFile, this.parentResourceId, this.parentResourceType);
  }
}
