<div class="container-fluid d-flex flex-column position-absolute h-100 p-4" autosize>
  <div id="budget" class="" #budget (window:resize)="onResize($event)">
    <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
      <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
        PEB
      </button>
      <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
        Bid Packages
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/construction-budget']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Construction Budget
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Proposal Requests
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/change-orders']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Change Orders
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/invoices']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Invoices
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/budget']"
        mat-button
        color="primary"
        class="flex-grow-1 bg-white ml-1"
      >
        Budget Overview
      </button>
    </div>
    <div>
      <div class="d-flex align-items-center full-width mb-4 border-bottom border-gray">
        <div class="d-flex full-width align-items-top">
          <div class="d-flex flex-column mr-auto">
            <h4 class="dkblue">Budget Overview</h4>
            <p class="dkgray">Overview of this project’s budget and billing progress</p>
          </div>
          <div>
            <button
              mat-button
              type="button"
              color="accent"
              class="mr-auto lh-28"
              *ngIf="isWorkspaceStaff"
              (click)="showHeader = !showHeader"
            >
              Show {{ showHeader ? 'Less' : 'More' }}
            </button>
            <button
              *ngIf="isWorkspaceStaff"
              (click)="loadingBudgetReportExport ? null : exportBudgetReport()"
              mat-button
              type="button"
              color="accent"
              class="ml-2 lh-28"
            >
              <mat-icon *ngIf="!loadingBudgetReportExport">get_app</mat-icon>
              <mat-spinner
                *ngIf="loadingBudgetReportExport"
                class="d-inline-block align-middle m-0"
                color="accent"
                [diameter]="20"
              ></mat-spinner>
              <span class="ml-2">Budget Report</span>
            </button>
          </div>
        </div>
      </div>

      <!-- SUPPLIER CARDS -->

      <div class="row" *ngIf="isWorkspaceStaff && showHeader">
        <div class="col-xl-4">
          <div class="m-portlet mb-4">
            <div class="m-portlet__body item-no-hover p-3">
              <h5 class="dkblue font-weight-bold mb-3">Project Cost</h5>
              <div class="row">
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Construction Budget </span>
                    <i [matMenuTriggerFor]="constructionBudgetHelp" class="help-icon material-icons gray pointer"
                      >help_outline</i
                    >
                    <mat-menu class="p-3 mt-2" #constructionBudgetHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Construction Budget</p>
                        <p class="m-0 dkgray font-weight-normal">The sum of all approved Construction Budgets</p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    {{ +budgetData?.constructionBudgetTotal || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Management Fee </span>
                    <i [matMenuTriggerFor]="managementFeeHelp" class="help-icon material-icons gray pointer"
                      >help_outline</i
                    >
                    <mat-menu class="p-3 mt-2" #managementFeeHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Management Fee</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Management Fee increases as the Total Project Cost increases and is divided among project
                          tenants.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold hover-u">
                    {{ +managementFee || 0 | currency: 'USD' }}
                  </h5>
                  <!-- [matMenuTriggerFor]="managementFeeTotals" -->
                  <!-- <mat-menu class="p-3 mt-2" #managementFeeTotals="matMenu" xPosition="after">
                    <div class="d-flex flex-column managementFeeMenu">
                      <p class="m-0 dkblue font-weight-semibold mb-4">Management Fee Totals</p>
                      <div *ngFor="let tenant of budgetItems?.tenants" class="d-flex align-items-top mb-3">
                        <p class="dkblue mr-auto mb-0">
                          {{ tenant.tenant_name }}
                        </p>
                        <p class="dkblue font-weight-semibold ml-4 mb-0">
                          {{ +tenant?.cb_management_fee_subtotal || 0 | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="d-flex align-items-top border-top border-gray pt-3">
                        <p class="dkblue mr-auto mb-0">Total</p>
                        <p class="dkblue font-weight-semibold ml-4 mb-0">
                          {{ +managementFee || 0 | currency: 'USD' }}
                        </p>
                      </div>
                    </div>
                  </mat-menu> -->
                  <mat-menu class="p-3 mt-2" #managementFeeHelp="matMenu" xPosition="after">
                    <div class="d-flex flex-column">
                      <p class="m-0 dkblue font-weight-semibold mb-3">Management Fee</p>
                      <p class="m-0 dkgray font-weight-normal">
                        The Management Fee increases as the Total Project Cost increases and is divided among project
                        tenants.
                      </p>
                    </div>
                  </mat-menu>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> TI Allowance </span>
                    <i [matMenuTriggerFor]="tiallowanceHelp" class="help-icon material-icons gray pointer"
                      >help_outline</i
                    >
                    <mat-menu class="p-3 mt-2" #tiallowanceHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">TI Allowance</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The TI Allowance is the amount of money that UHAT has committed to pay for any tenant(s).
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    <!-- {{ +budgetData?.tenantAllowanceTotal || 0 | currency: 'USD' }} -->
                    -
                  </h5>
                </div>
              </div>
            </div>
            <div class="m-portlet__body p-3">
              <div class="row">
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Current Project Cost </span>
                    <i [matMenuTriggerFor]="tpcHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #tpcHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Current Project Cost</p>
                        <p class="m-0 dkgray font-weight-normal">
                          Tenant Investment + UHAT Investment = Current Project Cost
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold d-flex align-items-center">
                    <!-- {{ totalInvestment | currency: 'USD' }} -->
                    -
                    <!-- <span
                      class="material-icons green change-history"
                      [ngClass]="{
                        red: totalInvestment > (+budgetData?.constructionBudgetTotal || 0)
                      }"
                    >
                      {{
                        totalInvestment > (+budgetData?.constructionBudgetTotal || 0)
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                      }}
                    </span> -->
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Tenant Investment </span>
                    <i [matMenuTriggerFor]="tenantInvestmentHelp" class="help-icon material-icons gray pointer"
                      >help_outline</i
                    >
                    <mat-menu class="p-3 mt-2" #tenantInvestmentHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Tenant Investment</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The sum of costs approved and assigned to a Tenant Funding Source
                          <br /><br />
                          This includes the approved construction budget sum, minus any unused contingency or general
                          conditions fees, plus any approved change orders for the Tenants.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    <!-- {{ tenantInvestment | currency: 'USD' }} -->
                    -
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> UHAT Investment </span>
                    <i [matMenuTriggerFor]="uhatInvestmentHelp" class="help-icon material-icons gray pointer"
                      >help_outline</i
                    >
                    <mat-menu class="p-3 mt-2" #uhatInvestmentHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">UHAT Investment</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The sum of all costs approved and assigned to a UHAT Funding Source. This would also include
                          the TI Allowance if present.
                          <br /><br />
                          This includes the approved construction budget sum, minus any unused contingency or general
                          conditions fees, plus any approved change orders for UHAT.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    <!-- {{ trustInvestment | currency: 'USD' }} -->
                    -
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="m-portlet mb-4">
            <div class="m-portlet__body p-3">
              <h5 class="dkblue font-weight-bold mb-3">Project Fees</h5>
              <div class="row">
                <div class="col-4 border-right border-gray">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Contingency </span>
                    <i [matMenuTriggerFor]="continHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #continHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Contingency</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The total amount allotted and approved to use as needed. The Contingency amount remaining
                          after the project is closed will not be charged to the tenant(s).
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    {{ +budgetData?.contingencyBudget || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4 pl-5">
                  <h6 class="mb-1 dkblue">
                    <i class="fa fa-square ltblue mr-1"></i>
                    Used
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ +budgetData?.contingencyUsed || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue">
                    <i class="fa fa-square ltltblue mr-1"></i>
                    Remaining
                  </h6>
                  <h5
                    class="m-0 dkblue font-weight-normal"
                    [ngClass]="{
                      red: (+budgetData?.contingencyBudget || 0) - (+budgetData?.contingencyUsed || 0) < 0
                    }"
                  >
                    {{ (+budgetData?.contingencyBudget || 0) - (+budgetData?.contingencyUsed || 0) | currency: 'USD' }}
                  </h5>
                </div>
              </div>
              <mat-progress-bar
                class="mt-2"
                color="accent"
                mode="determinate"
                [value]="
                  (+budgetData?.contingencyUsed || 0) > (+budgetData?.contingencyBudget || 0)
                    ? 100
                    : +budgetData?.contingencyBudget
                    ? (100 * +budgetData?.contingencyUsed) / +budgetData?.contingencyBudget
                    : 0
                "
              ></mat-progress-bar>
              <div class="row mt-3">
                <div class="col-4 border-right border-gray">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> General Conditions </span>
                    <i [matMenuTriggerFor]="gcHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #gcHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">General Conditions</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The total amount allotted and approved to use on General Conditions. The General Conditions
                          amount remaining after the project is closed will not be charged to the tenant(s).
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    {{ +budgetData?.generalConditionsBudget || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4 pl-5">
                  <h6 class="mb-1 dkblue">
                    <i class="fa fa-square ltblue mr-1"></i>
                    Used
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ +budgetData?.generalConditionsUsed || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue">
                    <i class="fa fa-square ltltblue mr-1"></i>
                    Remaining
                  </h6>
                  <h5
                    class="m-0 dkblue font-weight-normal"
                    [ngClass]="{
                      red: (+budgetData?.generalConditionsBudget || 0) - (+budgetData?.generalConditionsUsed || 0) < 0
                    }"
                  >
                    {{
                      (+budgetData?.generalConditionsBudget || 0) - (+budgetData?.generalConditionsUsed || 0)
                        | currency: 'USD'
                    }}
                  </h5>
                </div>
              </div>
              <mat-progress-bar
                class="mt-2"
                color="accent"
                mode="determinate"
                [value]="
                  (+budgetData?.generalConditionsUsed || 0) > (+budgetData?.generalConditionsBudget || 0)
                    ? 100
                    : +budgetData?.generalConditionsBudget
                    ? (100 * (+budgetData?.generalConditionsUsed || 0)) / +budgetData?.generalConditionsBudget
                    : 0
                "
              ></mat-progress-bar>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="m-portlet mb-4">
            <div class="m-portlet__body p-3">
              <h5 class="dkblue font-weight-bold mb-3">Supplier Billing</h5>
              <div class="row">
                <div class="col-4 border-right border-gray">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Current Contract Sum </span>
                    <i [matMenuTriggerFor]="tcsHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #tcsHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Current Contract Sum</p>
                        <p class="m-0 dkblue font-weight-normal mb-3">
                          Original Contract Sum + Change Order Net =
                          <span class="font-weight-semibold">Current Contract Sum</span>
                        </p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Current Contract Sum is the sum of all awarded bids (Original Contract Sum) and approved
                          change orders (net).
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-bold">
                    {{ (+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0) | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <i class="fa fa-square ltblue mr-1"></i>
                    <span class="mr-1"> Billed-To-Date </span>
                    <i [matMenuTriggerFor]="btdHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #btdHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Billed-To-Date</p>
                        <p class="m-0 dkgray font-weight-normal">
                          Billed-To-Date includes all
                          <span class="font-weight-semibold">Received</span> invoices and excludes all
                          <span class="font-weight-semibold">new (not received)</span> and
                          <span class="font-weight-semibold">rejected</span> invoices.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ +budgetData?.invoicesBilledTotal || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <i class="fa fa-square ltltblue mr-1"></i>
                    <span class="mr-1"> Bal-To-Finish </span>
                    <i [matMenuTriggerFor]="btfHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #btfHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Balance-To-Finish</p>
                        <p class="m-0 dkblue font-weight-normal mb-3">
                          Total Contract Sum - Billed-To-Date =
                          <span class="font-weight-semibold">Balance-To-Finish</span>
                        </p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Balance-To-Finish total is the Total Contract Sum minus the Billed-To-Date total.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5
                    class="m-0 dkblue font-weight-normal"
                    [ngClass]="{
                      red:
                        (+budgetData?.awardedBidTotal || 0) +
                          (+budgetData?.changeOrderTotal || 0) -
                          (+budgetData?.invoicesBilledTotal || 0) <
                        0
                    }"
                  >
                    {{
                      (+budgetData?.awardedBidTotal || 0) +
                        (+budgetData?.changeOrderTotal || 0) -
                        (+budgetData?.invoicesBilledTotal + closedBidPackagesBalanceToFinish || 0) | currency: 'USD'
                    }}
                  </h5>
                </div>
              </div>
              <mat-progress-bar
                class="py-2 billing-bar mt-2"
                color="accent"
                mode="determinate"
                [value]="
                  (+budgetData?.invoicesBilledTotal || 0) >
                  (+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0)
                    ? 100
                    : (+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0)
                    ? (100 * (+budgetData?.invoicesBilledTotal || 0)) /
                      ((+budgetData?.awardedBidTotal || 0) + (+budgetData?.changeOrderTotal || 0))
                    : 0
                "
              ></mat-progress-bar>
              <div class="row mt-3">
                <div class="col-4 border-right border-gray">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Orig. Contract Sum </span>
                    <i [matMenuTriggerFor]="ocsHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #ocsHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Original Contract Sum</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Original Contract Sum is the sum of all awarded bids.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-semibold">
                    {{ +budgetData?.awardedBidTotal || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Change Order Net </span>
                    <i [matMenuTriggerFor]="conHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #conHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Change Order Net</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Change Order Net is the net sum of all approved change orders.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ +budgetData?.changeOrderTotal || 0 | currency: 'USD' }}
                  </h5>
                </div>
                <div class="col-4">
                  <h6 class="mb-1 dkblue d-flex align-items-center">
                    <span class="mr-1"> Retainage WH </span>
                    <i [matMenuTriggerFor]="retHelp" class="help-icon material-icons gray pointer">help_outline</i>
                    <mat-menu class="p-3 mt-2" #retHelp="matMenu" xPosition="after">
                      <div class="d-flex flex-column">
                        <p class="m-0 dkblue font-weight-semibold mb-3">Retainage Withheld</p>
                        <p class="m-0 dkgray font-weight-normal">
                          The Retainage Withheld total is the sum of all retainage withheld from received invoices.
                        </p>
                      </div>
                    </mat-menu>
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ +budgetData?.invoicesRetainageTotal || 0 | currency: 'USD' }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex full-width align-items-center mb-4">
        <div class="d-flex align-items-center mr-auto">
          <mat-button-toggle-group type="button" class="mr-4" [value]="selectedView">
            <mat-button-toggle
              type="button"
              class="font-weight-semibold px-3"
              value="list"
              (click)="startInvoiceLoading('list')"
            >
              List
            </mat-button-toggle>
            <mat-button-toggle
              type="button"
              class="font-weight-semibold px-3"
              value="vendor"
              (click)="startInvoiceLoading('vendor')"
              *ngIf="isWorkspaceStaff"
            >
              Suppliers
            </mat-button-toggle>
            <mat-button-toggle
              type="button"
              class="font-weight-semibold px-3"
              value="investor"
              (click)="startInvoiceLoading('investor')"
              *ngIf="isWorkspaceStaff"
            >
              Investors
            </mat-button-toggle>
          </mat-button-toggle-group>
          <button
            mat-button
            color="success"
            type="button"
            class="mr-4"
            *ngIf="selectedView !== 'list' && invoiceStatusInfo[0]?.count > 0"
            (click)="selectedView = 'list'; selectStatus(invoiceStatusInfo[0])"
          >
            <i class="mr-2 fa fa-circle"> </i>
            <span class="dkgreen">
              {{ invoiceStatusInfo[0]?.count }} New Invoice{{ invoiceStatusInfo[0]?.count === 1 ? '' : 's' }}
            </span>
          </button>
        </div>
        <div class="d-flex align-items-center ml-auto">
          <button
            mat-button
            color="accent"
            type="button"
            class="ml-4"
            (click)="toggleAllView()"
            *ngIf="selectedView !== 'list' && sectionsCount"
          >
            {{ collapseAll ? 'Collapse' : 'Expand' }} All
          </button>
          <button
            mat-button
            color="accent"
            type="button"
            class="ml-4"
            (click)="hideRejected = !hideRejected"
            *ngIf="selectedView !== 'list'"
          >
            {{ hideRejected ? 'Show' : 'hide' }} Rejected
          </button>
          <mat-button-toggle-group
            #group="matButtonToggleGroup"
            class="button-group-filters font-weight-semibold ml-4"
            [(ngModel)]="selectedInvoiceStatus"
            (change)="selectStatus($event.value)"
            [value]="selectedInvoiceStatus"
            *ngIf="selectedView === 'list' && divWidth > 999"
          >
            <mat-button-toggle
              *ngFor="let s of invoiceStatusInfo"
              [value]="s"
              class="dkblue pl-4 pr-4 overflow-visible"
            >
              <span>
                {{ s.name }}
              </span>
              <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
                {{ s.count }}
              </span>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <button
            mat-stroked-button
            type="button"
            color="primary"
            class="dkblue px-5 overflow-visible bg-white"
            [matMenuTriggerFor]="selectInvoiceStatus"
            *ngIf="selectedView === 'list' && divWidth < 1000"
          >
            <span>
              {{ selectedInvoiceStatus.name }}
            </span>
            <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
              {{ selectedInvoiceStatus.count }}
            </span>
          </button>
          <mat-menu #selectInvoiceStatus="matMenu">
            <button mat-menu-item class="dkblue" *ngFor="let s of invoiceStatusInfo" (click)="selectStatus(s)">
              <span>
                {{ s.name }}
              </span>
              <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
                {{ s.count }}
              </span>
            </button>
          </mat-menu>
          <button
            mat-flat-button
            color="success"
            type="button"
            class="ml-4"
            *ngIf="canCreateInvoices"
            (click)="createInvoice()"
          >
            + Invoice
          </button>
        </div>
      </div>

      <!-- LIST TABLE -->

      <div class="m-portlet m-0 flex-grow-1 position-relative" style="max-height: 100%" *ngIf="selectedView === 'list'">
        <div class="m-portlet__body p-0" style="height: 100%; overflow-y: scroll; overflow-x: clip">
          <div class="row sticky align-items-center m-0 sub-header">
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Invoices</h6>
            </div>
            <div class="col-3 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Trade: Supplier</h6>
            </div>
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Submitted</h6>
            </div>
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Type</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Total Amt</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Retainage WH</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Billed-To-Date</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Status</h6>
            </div>
            <div class="col-auto py-3 px-2" style="width: 38px"></div>
          </div>
          <div *ngIf="!loading && !invoices?.length" class="p-3">There are no invoices on this project</div>
          <div
            class="d-flex justify-content-center align-items-center"
            style="height: 50%"
            *ngIf="invoicesAreLoading && invoices?.length"
          >
            <h2 class="dkblue">Loading Invoices...</h2>
          </div>
          <div>
            <div *ngIf="displayData">
              <!-- Invoice -->
              <ng-container *ngFor="let i of invoices; index as invoiceIndex; let index = index">
                <!-- Invoice Type -->
                {{ index + 1 === invoices.length ? endInvoiceLoading() : '' }}
                <span *ngIf="!invoicesAreLoading">
                  <div
                    class="row item align-items-center m-0 invoice-item"
                    *ngIf="invoiceIndex === 0 || invoices[invoiceIndex - 1]?.change_order_id !== i.change_order_id"
                  >
                    <div class="px-4 py-3 col">
                      <p class="m-0 dkblue font-weight-semibold">
                        {{
                          i.change_order_id
                            ? 'CO #' + i.change_order_local_index + ' - ' + i.change_order_short_description
                            : 'Original Contract'
                        }}
                      </p>
                    </div>
                    <div class="col-auto p-3" style="width: 38px"></div>
                  </div>
                  <div
                    class="row item align-items-center m-0"
                    *ngIf="
                      !selectedInvoiceStatus?.statusIds?.length ||
                      selectedInvoiceStatus?.statusIds?.indexOf(i.status_id) > -1
                    "
                  >
                    <div class="px-4 py-3 col-1">
                      <p class="m-0 ltblue hover-u pointer font-weight-semibold" (click)="viewInvoice(i)">
                        {{ i.number }}
                      </p>
                    </div>
                    <div class="px-4 py-3 col-3">
                      <p class="m-0 dkblue font-weight-normal">
                        {{ i.trade_name || '' }} -
                        {{ i.company_name || 'No Supplier Awarded' }}
                      </p>
                    </div>
                    <div class="px-4 py-3 col-1">
                      <p class="m-0 dkblue font-weight-normal">
                        {{ i.invoice_date | date: 'MMM dd yyyy' }}
                      </p>
                    </div>
                    <div class="px-4 py-3 col-1">
                      <p class="m-0 dkblue font-weight-normal">
                        {{ i.is_retainage ? 'Retainage' : 'Contract' }}
                      </p>
                    </div>
                    <div class="px-4 py-3 col text-right">
                      <p class="m-0 dkblue font-weight-normal">
                        {{ +i.total || 0 | currency: 'USD' }}
                      </p>
                    </div>
                    <div class="px-4 py-3 col text-right">
                      <p class="m-0 dkblue font-weight-normal">
                        <span class="gray">
                          {{ (+i?.total ? (+i.retainage || 0) / (+i.total || 0) : 0) | percent: '1.1-1' }}
                        </span>
                        <span class="ml-4">
                          {{ +i.retainage || 0 | currency: 'USD' }}
                        </span>
                      </p>
                    </div>
                    <div class="px-4 py-3 col text-right">
                      <p class="m-0 dkblue font-weight-semibold">
                        {{
                          (+i.total || 0) - (+i.retainage || 0) + (+i.shipping || 0) + (+i.tax || 0) | currency: 'USD'
                        }}
                      </p>
                    </div>
                    <div class="py-1 col-auto text-right">
                      <app-invoice-menu
                        [invoice]="i"
                        [project]="project"
                        [alignButtonsRight]="true"
                        [displayInvoiceButton]="true"
                        (updateInvoice)="refresh()"
                      >
                      </app-invoice-menu>
                    </div>
                  </div>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- SUPPLIER TABLE -->

      <div
        class="m-portlet m-0 flex-grow-1 position-relative"
        style="max-height: 100%"
        *ngIf="selectedView === 'vendor'"
      >
        <div class="m-portlet__body p-0" style="height: 100%; overflow-y: scroll; overflow-x: clip">
          <div class="row sticky align-items-center m-0 sub-header">
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Invoice</h6>
            </div>
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Type</h6>
            </div>
            <div class="col-2 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Submitted</h6>
            </div>
            <div class="col-2 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Funding</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Total Amt</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Retainage WH</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Billed-To-Date</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Bal-To-Finish</h6>
            </div>
            <div class="col-auto py-3 px-2" style="width: 38px"></div>
          </div>
          <div *ngIf="!loading && !budgetItems?.bid_packages?.length" class="p-3">
            There are no bid packages on this project
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            style="height: 50%"
            *ngIf="invoicesAreLoading && budgetItems?.bid_packages?.length"
          >
            <h2 class="dkblue">Loading Invoices...</h2>
          </div>
          <div *ngIf="displayData">
            <div *ngFor="let bp of budgetItems?.bid_packages; let i = index" class="vendor-item">
              {{ i + 1 === budgetItems?.bid_packages?.length ? endInvoiceLoading() : '' }}
              <div *ngIf="!invoicesAreLoading">
                <!-- Supplier -->
                <div class="row bg-white item-no-hover align-items-center m-0">
                  <div class="px-4 py-3 col-6 d-flex align-items-center">
                    <h5
                      class="m-0 my-2 lh-22 dkblue font-weight-semibold d-flex align-items-center pointer"
                      (click)="toggleItemView(bp)"
                    >
                      <span class="hover-u">
                        {{ bp.number || '' }} - {{ bp.trade_name || '' }} |
                        {{ bp.awarded_company_name || 'No Supplier Awarded' }}
                      </span>
                      <mat-icon
                        class="ml-2 ltblue mat-small-icon"
                        *ngIf="bp.child_request?.id && !bp.child_project?.id"
                      >
                        assignment_late</mat-icon
                      >
                      <mat-icon class="ml-2 mr-2 ltblue mat-small-icon" *ngIf="bp.child_project?.id">
                        assignment_turned_in
                      </mat-icon>
                      <mat-icon *ngIf="bp.hideInvoices" class="dkblue pointer mr-2">keyboard_arrow_right</mat-icon>
                      <mat-icon *ngIf="!bp.hideInvoices" class="dkblue pointer mr-2">keyboard_arrow_down</mat-icon>
                    </h5>
                    <a
                      href="#"
                      target="_blank"
                      [routerLink]="
                        bp.child_project?.id ? '/projects/' + bp.child_project?.id : '/requests/' + bp.child_request?.id
                      "
                      class="text-decoration-none ltblue pointer"
                      *ngIf="bp.child_request?.id || bp.child_project?.id"
                    >
                      <h6 class="font-weight-normal m-0 d-flex align-items-top hover-u" *ngIf="bp.child_project?.id">
                        <span class="mr-1 font-weight-semibold text-nowrap">PJ-{{ bp.child_project?.code }}</span>
                        <span *ngIf="bp.child_project?.title"> | {{ bp.child_project?.title }}</span>
                      </h6>
                      <h6
                        class="font-weight-normal m-0 d-flex align-items-top hover-u"
                        *ngIf="!bp.child_project?.id && bp.child_request?.id"
                      >
                        <span class="mr-1 font-weight-semibold text-nowrap">{{ bp.child_request?.code }}</span>
                        <span *ngIf="bp.child_request?.short_description">
                          | {{ bp.child_request?.short_description }}</span
                        >
                      </h6>
                    </a>
                  </div>
                  <div class="px-4 py-3 col text-right align-self-stretch">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{
                        (bp.child_request?.id || bp.child_project?.id
                          ? (+bp.child_project?.budget_data?.awardedBidTotal || 0) + (+bp.changeOrderTotal || 0)
                          : (+bp.awarded_amount || 0) + (+bp.changeOrderTotal || 0)
                        ) | currency: 'USD'
                      }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{ +bp.retainageTotal || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group align-self-stretch">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{ +bp.billedTotal || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group align-self-stretch">
                    <h5
                      class="m-0 dkblue font-weight-semibold my-3"
                      [ngClass]="{ red: (+bp.balanceToFinish || 0) < 0 }"
                    >
                      {{ (bp?.status_id !== BidPackageStatus.CLOSED && +bp.balanceToFinish) || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="col-auto px-2 py-3 d-flex justify-content-center" style="width: 38px">
                    <app-bid-package-invoice-menu
                      [bidPackage]="bp"
                      [currentWorkspace]="this.currentWorkspace"
                      (bidPackageUpdated)="updateBidPackageStatus($event)"
                    ></app-bid-package-invoice-menu>
                  </div>
                </div>
                <!-- Invoice Header -->
                <div *ngIf="!bp.hideInvoices">
                  <div *ngFor="let co of bp.change_orders">
                    <div class="row item align-items-center m-0 invoice-item">
                      <div class="px-4 py-3 col-6">
                        <p
                          (click)="co.id ? viewChangeOrder(co) : null"
                          class="m-0 dkblue font-weight-semibold ml-4 pointer"
                          [ngClass]="{ 'hover-u  pointer': co?.id }"
                        >
                          {{ co.id ? 'CO #' + co.local_index + ' - ' + co.short_description : 'Original Contract' }}
                        </p>
                        <span *ngIf="co.approval_datetime" class="m-0 text-sm dkgray font-weight-normal ml-4"
                          >Initiated: {{ co.approval_datetime | date: 'MMM dd yyyy' }}</span
                        >
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +co.expectedAmount | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +co.retainageTotal | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +co.billedAmount | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right align-self-stretch">
                        <p class="m-0 dkblue font-weight-semibold" [ngClass]="{ red: +co.balanceToFinish < 0 }">
                          {{ +co.balanceToFinish | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="col-auto p-3" style="width: 38px"></div>
                    </div>
                    <!-- Invoice -->
                    <ng-container *ngFor="let i of co.invoices">
                      <div class="row item align-items-center m-0" *ngIf="hideRejected ? i.status_id !== 5 : true">
                        <div class="pl-3 pr-4 py-3 col-1">
                          <p class="m-0 ltblue hover-u pointer font-weight-semibold" (click)="viewInvoice(i)">
                            <i *ngIf="i.status_id === 1" class="fa fa-circle green mr-2"></i>
                            <span [ngClass]="{ 'ml-4 pl-2': i.status_id !== 1 }">
                              {{ i.number }}
                            </span>
                          </p>
                        </div>
                        <div class="px-4 py-3 col-1">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ i.is_retainage ? 'Retainage' : 'Contract' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col-2">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ i.invoice_date | date: 'MMM dd yyyy' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col-2">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ getFundingSourceName(i) }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ +i.total || 0 | currency: 'USD' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-normal">
                            <span class="gray">
                              {{ (+i?.total ? (+i.retainage || 0) / (+i.total || 0) : 0) | percent: '1.1-1' }}
                            </span>
                            <span class="ml-4">
                              {{ +i.retainage || 0 | currency: 'USD' }}
                            </span>
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-semibold">
                            {{
                              (+i.total || 0) - (+i.retainage || 0) + (+i.shipping || 0) + (+i.tax || 0)
                                | currency: 'USD'
                            }}
                          </p>
                        </div>
                        <div class="py-1 col-auto text-right">
                          <app-invoice-menu
                            [invoice]="i"
                            [project]="project"
                            [alignButtonsRight]="true"
                            [displayInvoiceButton]="true"
                            (updateInvoice)="refresh()"
                          >
                          </app-invoice-menu>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- INVESTOR TABLE -->

      <div
        class="m-portlet m-0 flex-grow-1 position-relative"
        style="max-height: 100%"
        *ngIf="selectedView === 'investor'"
      >
        <div class="m-portlet__body p-0" style="height: 100%; overflow-y: scroll; overflow-x: clip">
          <div class="row sticky align-items-center m-0 sub-header">
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Invoice</h6>
            </div>
            <div class="col-3 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Trade: Supplier</h6>
            </div>
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Submitted</h6>
            </div>
            <div class="col-1 py-3 px-4 header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Type</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Total Amt</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Retainage WH</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Billed-To-Date</h6>
            </div>
            <div class="col py-3 px-4 text-right header-item align-self-stretch">
              <h6 class="m-0 dkblue font-weight-semibold">Bal-To-Finish</h6>
            </div>
            <div class="col-auto py-3 px-2" style="width: 38px"></div>
          </div>
          <div *ngIf="!loading && !budgetItems?.tenants?.length" class="p-3">
            There are no investors on this project
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            style="height: 50%"
            *ngIf="invoicesAreLoading && budgetItems?.tenants?.length"
          >
            <h2 class="dkblue">Loading Invoices...</h2>
          </div>
          <div *ngIf="displayData">
            <div *ngFor="let tenant of budgetItems?.tenants; let i = index" class="vendor-item">
              {{ i + 1 === budgetItems?.tenants?.length ? endInvoiceLoading() : '' }}
              <!-- Supplier -->
              <div *ngIf="!invoicesAreLoading">
                <div class="row bg-white item-no-hover align-items-center m-0">
                  <div class="px-4 py-3 col-6">
                    <h5
                      class="m-0 my-2 lh-22 dkblue font-weight-semibold d-flex align-items-center pointer"
                      (click)="toggleItemView(tenant)"
                    >
                      {{ tenant.tenant_name }}
                      <mat-icon *ngIf="tenant.hideInvoices" class="dkblue pointer mr-2">keyboard_arrow_right</mat-icon>
                      <mat-icon *ngIf="!tenant.hideInvoices" class="dkblue pointer mr-2">keyboard_arrow_down</mat-icon>
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right align-self-stretch">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{ +tenant.expectedAmount || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{ +tenant.retainageTotal || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group align-self-stretch">
                    <h5 class="m-0 dkblue font-weight-semibold my-3">
                      {{ +tenant.billedTotal || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="px-4 py-3 col text-right invoice-group align-self-stretch">
                    <h5 class="m-0 dkblue font-weight-semibold my-3" [ngClass]="{ red: +tenant.balanceToFinish < 0 }">
                      {{ +tenant.balanceToFinish || 0 | currency: 'USD' }}
                    </h5>
                  </div>
                  <div class="col-auto px-2 py-3" style="width: 38px"></div>
                </div>
                <!-- Invoice Header -->
                <div *ngIf="!tenant.hideInvoices">
                  <div *ngFor="let fs of tenant.funding_sources">
                    <!-- Invoice Type -->
                    <div class="row item align-items-center m-0 invoice-item">
                      <div class="px-4 py-3 col-6">
                        <p class="m-0 dkblue font-weight-semibold ml-4">
                          {{ fs.name }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +fs.expectedAmount | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +fs.retainageTotal | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right">
                        <p class="m-0 dkblue font-weight-semibold">
                          {{ +fs.billedAmount | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="px-4 py-3 col text-right align-self-stretch">
                        <p class="m-0 dkblue font-weight-semibold" [ngClass]="{ red: +fs.balanceToFinish < 0 }">
                          {{ +fs.balanceToFinish | currency: 'USD' }}
                        </p>
                      </div>
                      <div class="col-auto p-3" style="width: 38px"></div>
                    </div>
                    <!-- Invoice -->
                    <ng-container *ngFor="let i of fs.invoices">
                      <div class="row item align-items-center m-0" *ngIf="hideRejected ? i.status_id !== 5 : true">
                        <div class="px-4 py-3 col-1">
                          <p class="m-0 ltblue hover-u pointer font-weight-semibold ml-4" (click)="viewInvoice(i)">
                            {{ i.number }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col-3">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ i.trade_name || '' }} |
                            {{ i.company_name || 'No Supplier Awarded' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col-1">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ i.invoice_date | date: 'MMM dd yyyy' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col-1">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ i.is_retainage ? 'Retainage' : 'Contract' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-normal">
                            {{ +i.total || 0 | currency: 'USD' }}
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-normal">
                            <span class="gray">
                              {{ (+i?.total ? (+i.retainage || 0) / (+i.total || 0) : 0) | percent: '1.1-1' }}
                            </span>
                            <span class="ml-4">
                              {{ +i.retainage || 0 | currency: 'USD' }}
                            </span>
                          </p>
                        </div>
                        <div class="px-4 py-3 col text-right">
                          <p class="m-0 dkblue font-weight-semibold">
                            {{
                              (+i.total || 0) - (+i.retainage || 0) + (+i.shipping || 0) + (+i.tax || 0)
                                | currency: 'USD'
                            }}
                          </p>
                        </div>
                        <div class="py-1 col-auto text-right">
                          <app-invoice-menu
                            [invoice]="i"
                            [project]="project"
                            [alignButtonsRight]="true"
                            [displayInvoiceButton]="true"
                            (updateInvoice)="refresh()"
                          >
                          </app-invoice-menu>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-left">
      <app-budget-report-export #budgetReport [disableInit]="true" [parentBudget]="this"></app-budget-report-export>
    </div>
  </div>
</div>
