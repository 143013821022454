export const checkNameOfTheFile = (newFileName: string, existingFileNames: Set<string>): string => {
  // if the file does not exists, return the name
  if (!existingFileNames.has(newFileName)) {
    return newFileName;
  }

  let count = 0;
  let fileName = newFileName;
  const [prefix, extension] = newFileName.split('.');

  // run until we have no duplicate file names
  while (existingFileNames.has(fileName)) {
    // renames it, adding the duplicate file name
    fileName = `${prefix}-duplicate(${++count}).${extension}`;
  }

  return fileName;
};
