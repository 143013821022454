<div class="project-modal modal-wide">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">Request Details</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="close()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row" *ngIf="shownRequest">
    <div class="col-lg-12 col-md-12">
      <div class="row mb-4">
        <div class="col">
          <div class="row mb-4">
            <div class="col">
              <h2 class="dkblue">
                <b>{{ shownRequest.request_type_name }} Request</b>
                <small class="ml-3 gray">
                  {{ shownRequest.id }}
                </small>
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row mb-4">
                <div class="col-3">
                  <label class="main-label">Submitted</label>
                  <h5 class="dkblue" *ngIf="shownRequest.building_name">
                    {{ shownRequest.created_datetime | date: 'MM/dd/yyyy' }}
                  </h5>
                </div>
                <div class="col-3">
                  <label class="main-label">Est. Cost</label>
                  <h5 class="dkblue">{{ shownRequest.budget_description }}</h5>
                </div>
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.start_date">Start</label>
                  <h5 class="dkblue" *ngIf="shownRequest.start_date">
                    {{ shownRequest.start_date | date: 'MM/dd/yyyy' }}
                  </h5>
                  <label class="main-label" *ngIf="shownRequest.lease_term">Term</label>
                  <h5 class="dkblue" *ngIf="shownRequest.lease_term">
                    {{ shownRequest.lease_term }}
                  </h5>
                </div>
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.end_date">Move-In</label>
                  <h5 class="dkblue" *ngIf="shownRequest.end_date">
                    {{ shownRequest.end_date | date: 'MM/dd/yyyy' }}
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.building_name"
                    ><span *ngIf="shownRequest.request_type_id === 1">Preferred </span>Building</label
                  >
                  <h5 class="dkblue" *ngIf="shownRequest.building_name">
                    {{ shownRequest.building_name }}
                  </h5>
                </div>
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.floor_name"
                    ><span *ngIf="shownRequest.request_type_id === 1">Preferred </span>Floor</label
                  >
                  <h5 class="dkblue" *ngIf="shownRequest.floor_name">
                    {{ shownRequest.floor_name }}
                  </h5>
                </div>
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.suite_name">Suite</label>
                  <h5 class="dkblue" *ngIf="shownRequest.suite_name">
                    {{ shownRequest.suite_name }}
                  </h5>
                </div>
                <div class="col-3">
                  <label class="main-label" *ngIf="shownRequest.department_name">Dept.</label>
                  <h5 class="dkblue" *ngIf="shownRequest.department_name">
                    {{ shownRequest.department_name }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <!-- FOR RENOVATIONS -->

        <div class="col-lg-12" *ngIf="shownRequest.request_type_id === 2">
          <label class="main-label">Project Summary</label>
          <h5 class="dkblue lh2">{{ shownRequest.summary || 'No summary provided' }}</h5>
        </div>

        <!-- FOR NEW SPACE -->

        <div class="col room-list" *ngIf="shownRequest.request_type_id === 1">
          <label class="main-label">New space needs</label>
          <br /><br />
          <div class="row" *ngFor="let r of rooms">
            <div class="col-12">
              <h5 class="dkblue margin-0 lh2">
                <b>{{ r.quantity }} </b> {{ r.room_type.name }}{{ r.quantity === 1 ? '' : 's' }}
              </h5>
              <h6 class="ltblue lh2" *ngIf="r.comments">
                {{ r.comments }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="files && files.length > 0" class="row mb-4">
        <div class="col-12">
          <label class="main-label mb-3">Attachments</label>
          <br />
          <mat-chip-list class="d-inline-block align-middle">
            <mat-chip *ngFor="let f of files" class="dkblue" (click)="!f.loading ? downloadFile(f) : null">
              <span class="pointer">
                {{ f.name }}
              </span>
              &nbsp; &nbsp;
              <mat-icon
                matTooltip="Download Attachment"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                matChipRemove
                *ngIf="!f.loading"
                class="mat-chip-icon"
                (click)="!f.loading ? downloadFile(f) : null"
                >get_app</mat-icon
              >
              <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="f.loading"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-auto pr-5">
          <label class="main-label mb-2">Requestor</label>
          <br />
          <button
            (click)="openUserProfilePreview(shownRequest.requester_id)"
            mat-button
            type="button"
            color="accent"
            class="mr-3 pl-2 pr-3"
          >
            <app-profile-thumbnail
              class="d-inline-block align-middle mr-2"
              [width]="28"
              [height]="28"
              [userId]="shownRequest.requester_id"
              matTooltip="{{ shownRequest.requester_first_name }} {{ shownRequest.requester_last_name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            ></app-profile-thumbnail>
            <span class="d-inline-block align-middle">
              {{ shownRequest.requester_first_name }}
              {{ shownRequest.requester_last_name }}
            </span>
          </button>
        </div>
        <div class="col">
          <span *ngIf="contacts.length > 0">
            <label class="main-label mb-2">Contacts</label>
            <br />
            <button
              (click)="openUserProfilePreview(c.id)"
              mat-button
              type="button"
              class="mr-3 pl-2 pr-3"
              *ngFor="let c of contacts"
            >
              <app-profile-thumbnail
                class="d-inline-block align-middle mr-2"
                [width]="28"
                [height]="28"
                [userId]="c.id"
                matTooltip="{{ c.first_name }} {{ c.last_name }}"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
              ></app-profile-thumbnail>
              <span class="d-inline-block align-middle"> {{ c.first_name }} {{ c.last_name }}, </span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 mb-2">
    <div class="col">
      <button (click)="close()" mat-button class="gray full-width">Dismiss</button>
    </div>
  </div>
</div>
