import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TopicType, Workspace } from 'src/app/enums';
import { ModuleService, TopicService } from 'src/app/services';
import { Module, Topic } from 'src/app/types';
import { isTopicVisibleToDispatch } from '../../utils';

const DEFAULT_PROJECT_TYPE_NAME = 'Project';

@Component({
  selector: 'app-request-topic',
  templateUrl: './request-topic.component.html',
  styleUrls: ['./request-topic.component.scss'],
})
export class RequestTopicComponent implements OnInit {
  @Input() requestType = false;
  @Input() workOrderType = false;
  @Input() showTitle = true;

  private _fields =
    'id,name,topic_category_id,topic_category{id,name,topic_group_id,topic_group{id,name}},topic_type_id,topic_type{id,name},workspace_id,workspace{id,name},visible_to';

  public filteredTopics: Observable<Topic[]>;
  public module_id: number;
  public topics = new FormControl('', Validators.required);
  public topicTypeName = DEFAULT_PROJECT_TYPE_NAME;
  public workspace: Module = null;

  constructor(private _moduleService: ModuleService, private _topicService: TopicService) {}

  async ngOnInit() {
    await this._loadTopics();
  }

  get fields(): string {
    return this._fields;
  }

  get resolved_module_id(): number {
    return this.module_id || this._moduleService.workspace_id;
  }

  get workspaceName(): string {
    return this.workspace?.name || 'None';
  }

  private async _checkDuplicateTopics() {
    const topic_names = this._topicService.topics?.map((topic: Topic) => topic.name);
    this._topicService.topics = this._topicService.topics.map((topic: Topic) => {
      if (topic_names.indexOf(topic.name) !== topic_names.lastIndexOf(topic.name)) {
        return { ...topic, isDuplicate: true };
      }
      return { ...topic, isDuplicate: false };
    });
  }

  private _filter(entry: string): Topic[] {
    return this._topicService.topics.filter((topic) => topic?.name?.toLowerCase()?.includes(entry?.toLowerCase()));
  }

  private async _getAllTopics() {
    this._topicService.topics = this.addPropertyIsVisibleToDispatch(
      await this._topicService.getTopics([this._fields]).toPromise()
    );
    await this._checkDuplicateTopics();
    this._subscribe();
  }

  private async _getAllRequestTopics() {
    this._topicService.topics = await this._topicService
      .getTopics([this._fields], [{ type: 'field', field: 'topic_type_id', value: TopicType.Request.toString() }])
      .toPromise();
    this._subscribe();
  }

  private async _getAllWorkOrderTopics() {
    this._topicService.topics = await this._topicService
      .getTopics([this._fields], [{ type: 'field', field: 'topic_type_id', value: TopicType.WorkOrder.toString() }])
      .toPromise();
    if (this.resolved_module_id && +this.resolved_module_id !== +Workspace.Dispatch) {
      // filter topics
      this._topicService.topics = this._topicService.topics.filter(
        (topic: Topic) => +topic.workspace_id === +this.resolved_module_id
      );
    }
    await this._checkDuplicateTopics();
    this._subscribe();
  }

  private async _loadTopics() {
    if (this.workOrderType && !this.requestType) {
      await this._getAllWorkOrderTopics();
    } else if (this.requestType && !this.workOrderType) {
      await this._getAllRequestTopics();
    } else {
      await this._getAllTopics();
    }
  }

  private _subscribe() {
    this.filteredTopics = this.topics.valueChanges.pipe(
      startWith(''),
      map((value: string | Topic) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this._topicService.topics.slice()))
    );
  }

  public clearInput(event = null) {
    this.topics.setValue('');
    if (event) {
      event.stopPropagation();
    }

    this.resetWorkspace();
  }

  public async reloadAllTopics() {
    await this._getAllTopics();
  }

  public async reloadAllRequestTopics() {
    await this._getAllRequestTopics();
  }

  public async reloadAllWorkOrderTopics() {
    await this._getAllWorkOrderTopics();
  }

  private addPropertyIsVisibleToDispatch(topics: Topic[]): Topic[] {
    return topics.map((topic: Topic) => {
      return { ...topic, isVisibleToDispatch: isTopicVisibleToDispatch(topic) };
    });
  }

  public async reloadTopicsByCategory(categoryId: number) {
    this._topicService.topics = this.addPropertyIsVisibleToDispatch(
      await this._topicService
        .getTopics([this._fields], [{ type: 'field', field: 'topic_category_id', value: categoryId.toString() }])
        .toPromise()
    );
    this._subscribe();
  }

  public async reloadRequestTopicsByCategoryId(categoryId: number) {
    this._topicService.topics = await this._topicService
      .getTopics(
        [this._fields],
        [
          { type: 'field', field: 'topic_category_id', value: categoryId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'topic_type_id', value: TopicType.Request.toString() },
        ]
      )
      .toPromise();
    this._subscribe();
  }

  public async reloadWorkOrderTopicsByCategoryId(categoryId: number) {
    this._topicService.topics = await this._topicService
      .getTopics(
        [this._fields],
        [
          { type: 'field', field: 'topic_category_id', value: categoryId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'topic_type_id', value: TopicType.WorkOrder.toString() },
        ]
      )
      .toPromise();
    this._subscribe();
  }

  public resetWorkspace() {
    this.topicTypeName = DEFAULT_PROJECT_TYPE_NAME;
    this.workspace = null;
  }

  public topicMapper(topic: Topic): string {
    if (topic?.topic_type) {
      this.topicTypeName = topic?.topic_type?.name || DEFAULT_PROJECT_TYPE_NAME;
      this.workspace = null;
    }

    if (topic?.workspace) {
      this.workspace = topic?.workspace;
    }
    return topic?.name || '';
  }
}
