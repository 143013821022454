<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf p-5" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <!-- <section [ngStyle]="{ display: pageNum > 1 ? 'block' : 'none' }">
              </section> -->
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="red"> 271call.com </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                940 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <!-- EXPORT HEADER -->
      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-8">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-4">
            <label class="main-label mt-0"> Project Manager </label>
            <p>
              <span class="semibold"> {{ projectManager?.first_name }} {{ projectManager?.last_name }} </span>
              <br />
              <span class="font-weight-normal"> {{ projectManager?.company?.name }} </span>
            </p>
          </div>
        </div>
      </section>
      <div id="contract">
        <section class="form-title border-bottom-heavy">
          <div class="row">
            <div class="col">
              <h1 class="dkblue mb-2">
                <b>
                  Contract Addendum <br />
                  Established Project Schedule
                </b>
              </h1>
            </div>
          </div>
        </section>

        <section id="contract-cover-page">
          <div class="row m-0 py-2 mt-4">
            <div class="col-3 px-0 d-flex flex-column">
              <label class="main-label text-uppercase"> Prepared For </label>
              <h6 class="dkblue mb-0">
                {{ bidCompany?.name }}
              </h6>
              <p class="dkblue mb-4">
                {{ bidContact?.first_name }} {{ bidContact?.last_name }}<br />
                {{ bidContact?.title }}<br />
              </p>
              <label class="main-label text-uppercase"> Contract Sum </label>
              <h5 class="dkblue mb-4">{{ contractSum | USDollar }}</h5>
            </div>
            <div class="col-9 d-flex flex-column">
              <p class="dkblue mb-4">
                Per project schedule review during the
                {{ project?.code }} - {{ project?.title }} pre-construction meeting on
                {{ bid?.pre_construction_meeting?.start_datetime | date: 'MMMM d, YYYY' }}, the attached project
                schedule has been agreed upon by all parties for the construction timeline pending no further changes in
                project scope or unforeseen conditions.
              </p>
              <label class="main-label text-uppercase mt-0"> Project </label>
              <p class="dkblue font-weight-semibold mb-4">{{ project?.code }} - {{ project?.title }}</p>
              <label class="main-label text-uppercase mt-0"> Construction Start Date </label>
              <p class="dkblue font-weight-semibold mb-4">
                {{ project?.bid_details?.construction_start_date | date: 'MMMM d YYYY' }}
              </p>
              <label class="main-label text-uppercase mt-0"> Construction End Date </label>
              <p class="dkblue font-weight-semibold mb-4">
                {{ project?.bid_details?.construction_end_date | date: 'MMMM d YYYY' }}
              </p>
              <section id="trades-vendors">
                <div class="row mb-2 mx-0">
                  <div class="col-12 px-0">
                    <label class="main-label text-uppercase mt-0"> Trades / Suppliers </label>
                  </div>
                  <div class="col-6 pl-0 mb-0">
                    <p class="dkblue font-weight-semibold" *ngFor="let p of timelineTrades">
                      <b>{{ p.trade_name }}:</b>&nbsp;{{ p.awarded_company_name }}
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section class="signature-component">
          <div class="row m-0">
            <div class="col-6 d-flex flex-column align-items-top"></div>
            <div class="col-6 d-flex flex-column align-items-top ml-auto">
              <h1 class="mb-3 pb-0 border-bottom border-dkblue full-width signature">
                {{ bid?.timeline_vendor_signature_text }}
              </h1>
              <h6 class="dkblue mb-0 font-weight-semibold">CONTRACTOR SIGNATURE</h6>
              <p class="dkblue font-italic mb-4">
                {{ bid?.timeline_vendor_signed_datetime | date: 'MMMM d YYYY' }}
              </p>
              <h5 class="dxkblue mb-0">{{ bidContact?.first_name }} {{ bidContact?.last_name }}</h5>
              <p class="dkblue mb-4">
                {{ bidCompany?.name }}
              </p>
              <!-- <label class="main-label text-uppercase mb-2"> Contract Sum </label>
              <h5 class="dkblue mb-0">
                {{ contractSum | USDollar }}
              </h5> -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </kendo-pdf-export>
</div>
