import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { HandleErrorService } from 'src/app/services';
import { ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchPanelIsOpen = false;
  host: string = environment.serviceHost;
  searchUrl = `${this.host}/api/v1/search`;
  @Output() public searchPanelToggled = new EventEmitter();

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {}

  toggleSearchPanel() {
    this.searchPanelIsOpen = !this.searchPanelIsOpen;
    this.searchPanelToggled.emit();
  }

  search(searchTerm: string): Observable<any> {
    return this.http.get(`${this.searchUrl}?filter=${searchTerm}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data;
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  logClickthrough(searchTerm: string, item_id: number, section: string, followLink: string): Observable<any> {
    const body = {
      search_term: searchTerm,
      item_id,
      section,
      follow_link: followLink,
    };
    return this.http.post(`${this.searchUrl}`, body).pipe(
      map((result: ServiceResponse) => {
        const results = result.data;
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
