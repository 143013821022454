import { Injectable } from '@angular/core';

import { APIFilter } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ApiFilterService {
  constructor() {}

  getFilterString(filter: APIFilter[]) {
    let filterString = '';
    if (filter) {
      let filterPredicate = '';
      filter.forEach((f) => {
        if (f.value || f.value === 0) {
          f.value = f.value.toString();
        } else if (f.value === null) {
          f.value = 'null';
        } else if (f.value === '') {
          f.value = 'null';
        } else {
          throw new Error('Undefined API Filter value');
        }
        if (f.type === 'operator') {
          if (f.value === 'AND') {
            filterPredicate += ',';
          } else if (f.value === 'OR') {
            filterPredicate += '|';
          } else if (['(', ')'].indexOf(f.value) > -1) {
            filterPredicate += f.value;
          }
        } else if (f.type === 'field') {
          if (f.match === 'any') {
            filterPredicate += `${f.field}~*${f.value}*`;
          } else if (f.match === 'first') {
            filterPredicate += `${f.field}~${f.value}*`;
          } else if (f.match === '>') {
            filterPredicate += `${f.field}>${f.value}`;
          } else if (f.match === '>=') {
            filterPredicate += `${f.field}>=${f.value}`;
          } else if (f.match === '<') {
            filterPredicate += `${f.field}<${f.value}`;
          } else if (f.match === '<=') {
            filterPredicate += `${f.field}<=${f.value}`;
          } else if (f.match === '!=') {
            filterPredicate += `${f.field}!=${f.value}`;
          } else {
            filterPredicate += `${f.field}=${f.value}`;
          }
        }
      });
      filterString = `filter=${encodeURIComponent(filterPredicate)}`;
    }
    return filterString;
  }
}
