import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ArfStatus, DataType, ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import {
  ArfsService,
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ProductService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { APIFilter, Arf, Task } from '../types';
import { Bid } from '../workspaces/construction/types';

@Injectable({
  providedIn: 'root',
})
export class ReviewRevisionService {
  constructor(
    private arfService: ArfsService,
    private authService: AuthService,
    private dateService: DateService,
    private displayReviewersService: DisplayReviewersService,
    private eventService: ProjectEventService,
    private fileService: FileService,
    private productService: ProductService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private projectTaskService: ProjectTaskService,
    private projectTenantService: ProjectTenantService
  ) {}
  private fields: {
    revision: string;
    approval_task_id: string;
    saved_approval_task_id: string;
    approval_task?: string;
    saved_approval_task?: string;
    status_id?: string;
  };

  private internalPebFields = {
    revision: 'peb_revision',
    approval_task_id: 'peb_approval_task_id',
    saved_approval_task_id: 'saved_peb_approval_task_id',
  };

  private tenantPebFields = {
    revision: 'peb_revision',
    approval_task_id: 'tenant_approval_task_id',
    saved_approval_task_id: 'saved_tenant_approval_task_id',
  };

  private internalCbFields = {
    revision: 'cb_revision',
    approval_task_id: 'cb_approval_task_id',
    saved_approval_task_id: 'saved_cb_approval_task_id',
  };

  private tenantCbFields = {
    revision: 'cb_revision',
    approval_task_id: 'cb_tenant_approval_task_id',
    saved_approval_task_id: 'saved_cb_tenant_approval_task_id',
  };

  private internalPunchlistFields = {
    revision: 'punchlist_revision',
    approval_task_id: 'punchlist_approval_task_id',
    saved_approval_task_id: 'saved_punchlist_approval_task_id',
  };

  private tenantPunchlistFields = {
    revision: 'punchlist_revision',
    approval_task_id: 'punchlist_approval_task_id',
    saved_approval_task_id: 'saved_punchlist_approval_task_id',
  };

  private finalTenantPunchlistFields = {
    revision: 'punchlist_final_revision',
    approval_task_id: 'punchlist_final_approval_task_id',
    saved_approval_task_id: 'saved_punchlist_final_approval_task_id',
  };

  // For ARF reviews connected to a project
  private internalArfFields = {
    revision: 'arf_revision',
    approval_task_id: 'arf_approval_task_id',
    saved_approval_task_id: 'arf_saved_approval_task_id',
    approval_task: 'arf_approval_task',
    saved_approval_task: 'arf_saved_approval_task',
  };

  // For independent ARF reviews
  private arfFields = {
    revision: 'revision',
    approval_task_id: 'review_task_id',
    saved_approval_task_id: 'review_task_id',
    status_id: 'status_id',
  };

  private internalBudgetFields = {
    revision: 'budget_revision',
    approval_task_id: 'budget_approval_task_id',
    saved_approval_task_id: 'budget_saved_approval_task_id',
  };

  private tenantBudgetFields = {
    revision: 'budget_revision',
    approval_task_id: 'budget_tenant_approval_task_id',
    saved_approval_task_id: 'budget_tenant_saved_approval_task_id',
  };

  private bidContractFields = {
    revision: 'contract_revision',
    approval_task_id: 'contract_task_id',
    saved_approval_task_id: null,
  };

  private bidProjectScheduleFields = {
    revision: 'timeline_revision',
    approval_task_id: 'timeline_task_id',
    saved_approval_task_id: null,
  };

  reviewComplete: boolean;

  // Peb Revisions
  public async pebInternalRevision(
    tenant,
    reset: boolean,
    res: string,
    taskId: number = null,
    files = [],
    project_id?: number
  ) {
    this.fields = this.internalPebFields;
    await this.resetInternalReview(tenant, reset, res, taskId, DataType.Tenant, files, project_id || null);
  }

  public async pebTenantRevision(tenant, reset: boolean, res: string) {
    this.fields = this.tenantPebFields;
    await this.resetTenantReview(tenant, reset, res);
  }

  public async pebNewTenantRevision(section, reset: boolean, res: string) {
    this.fields = this.tenantPebFields;
    await this.resetTenantReview(section, reset, res);
  }

  // Cb Revisions
  public async cbInternalRevision(
    tenant,
    reset: boolean,
    res: string,
    taskId: number = null,
    files = [],
    project_id?: number
  ) {
    this.fields = this.internalCbFields;

    if (tenant) {
      tenant.id = tenant.id || tenant.tenant_id;
    }

    await this.resetInternalReview(tenant, reset, res, taskId, DataType.Tenant, files, project_id || null);
  }

  public async cbTenantRevision(tenant, reset: boolean, res: string) {
    this.fields = this.tenantCbFields;
    tenant.id = tenant.id || tenant.tenant_id;

    await this.resetTenantReview(tenant, reset, res);
  }

  // Punchlist Revisions
  public async punchlistInternalRevision(
    project,
    reset: boolean,
    res: string,
    taskId: number = null,
    files = [],
    project_id?: number
  ) {
    if (!project) {
      project = this.projectService.currentSelectedProject;
    }
    this.fields = this.internalPunchlistFields;
    await this.resetInternalReview(project, reset, res, taskId, DataType.Project, files, project_id || null);
  }

  public async punchlistTenantRevision(tenant, reset: boolean, res: string) {
    this.fields = this.tenantPunchlistFields;
    await this.resetTenantReview(tenant, reset, res);
  }

  public async punchlistFinalTenantRevision(tenant, reset: boolean, res: string) {
    this.fields = this.finalTenantPunchlistFields;
    await this.resetTenantReview(tenant, reset, res);
  }

  // Budget Revisions
  public async InternalARFRevision(
    quote,
    reset: boolean,
    res: string,
    taskId: number = null,
    files = [],
    project_id?: number
  ) {
    this.fields = this.internalArfFields;

    if (!quote && taskId) {
      const quoteFilter: APIFilter[] = [{ type: 'field', field: 'arf_approval_task_id', value: taskId.toString() }];
      const quotes = await this.productService
        .getQuotes(['arf_revision', 'arf_approval_task_id', 'arf_saved_approval_task_id'], quoteFilter)
        .toPromise();
      quote = quotes[0];
    }

    await this.resetInternalReview(quote, reset, res, taskId, DataType.Quote, files, project_id || null);
  }

  public async arfRevision(arf: Arf, reset: boolean, res: string, taskId: number = null, files = []): Promise<void> {
    this.fields = this.arfFields;

    if (!arf && taskId) {
      const arfFilter: APIFilter[] = [{ type: 'field', field: 'review_task_id', value: taskId.toString() }];
      const arfs = await this.arfService.getArfs(arfFilter, ['revision', 'review_task_id', 'status_id']).toPromise();
      arf = arfs[0];
    }

    await this.resetInternalReview(arf, reset, res, taskId, DataType.Arf, files);
  }

  public async budgetInternalRevision(
    tenant,
    reset: boolean,
    res: string,
    taskId: number = null,
    files = [],
    project_id?: number
  ): Promise<void> {
    this.fields = this.internalBudgetFields;
    await this.resetInternalReview(tenant, reset, res, taskId, DataType.Tenant, files, project_id || null);
  }

  public async budgetTenantRevision(tenant, reset: boolean, res: string): Promise<void> {
    this.fields = this.tenantBudgetFields;
    await this.resetTenantReview(tenant, reset, res);
  }

  public async bidContractRevision(bid: Bid, reset: boolean, res: string): Promise<void> {
    this.fields = this.bidContractFields;
    await this.resetInternalReview(bid, reset, res, bid.contract_task_id, DataType.Bid);
  }

  public async bidProjectScheduleRevision(bid: Bid, reset: boolean, res: string) {
    this.fields = this.bidProjectScheduleFields;
    await this.resetInternalReview(bid, reset, res, bid.timeline_task_id, DataType.Bid);
  }

  // resets an internal task. Allows for both hard resets and revisions
  public async resetInternalReview(
    data,
    reset: boolean,
    res: string,
    taskId: number = null,
    dataType: DataType = DataType.Tenant,
    files = [],
    project_id?: number
  ) {
    if (!data && taskId) {
      const tenants = await this.projectTenantService
        .getTenantsForProject(project_id || this.projectService.currentSelectedProjectId)
        .toPromise();
      data = tenants.find((t) => t[this.fields.approval_task_id] === taskId);
    }

    await this.projectService
      .getTaskById((data && data[this.fields.approval_task_id]) || taskId)
      .toPromise()
      .then(async (task) => {
        if (dataType !== DataType.Quote) {
          this.progressIndicatorService.updateStatus('Updating Approval Task');
        }
        if (task && task.accessory_data) {
          const accessoryData = JSON.parse(task.accessory_data);
          if (accessoryData.reviewChain) {
            let assignedUser = accessoryData.reviewCreator || task.created_by_id;
            if (accessoryData.type === TaskAccessoryType.BidContract) {
              const project = await this.projectService
                .getProjectById(task.project_id, ['project_manager_id'])
                .toPromise();
              assignedUser = project.project_manager_id;
            }
            accessoryData.reviewChain.forEach((reviewer) => (reviewer.status = 0));
            const taskInfo = {
              id: task.id,
              assigned_user_id: assignedUser,
              accessory_data: JSON.stringify(accessoryData),
              is_locked: reset || accessoryData.type === TaskAccessoryType.BidContract ? 0 : 1,
              status_id: TaskStatus.Open,
            };
            const updatedTask = await this.projectService.updateTask(taskInfo).toPromise();
            if (res) {
              await this.projectService
                .createNote(ResourceType.Task, task.id, `The review process was reset:<br />` + `${res}`)
                .toPromise()
                .then(async (result) => {
                  for (const file of files) {
                    await this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.Note).toPromise();
                  }
                });
            }
            if (!reset && data) {
              await this.selectUpdateFunction(data, dataType);
            }
            if (taskId && updatedTask) {
              await this.projectTaskService.updateTask(updatedTask);
              this.projectTaskService.selectTaskById(updatedTask.id, false).subscribe();
            }
          }
        }
      });
  }

  // Resets a tenant Review
  public async resetTenantReview(tenant, reset: boolean, res: string) {
    await this.projectService
      .getTaskById(tenant[this.fields.approval_task_id])
      .toPromise()
      .then(async (tenantTask) => {
        await this.projectService
          .updateTask({
            id: tenantTask.id,
            assigned_user_id: tenantTask.created_by_id,
            is_locked: reset ? 0 : 1,
            status_id: 1,
          })
          .toPromise();
        if (res) {
          await this.projectService
            .createNote(ResourceType.Task, tenantTask.id, `The review process was reset:<br />` + `${res}`)
            .toPromise();
        }
        if (!reset && tenant) {
          await this.updateTenant(tenant);
        }
      });
  }

  // Peb Submit Revision functions
  public async internalPebSubmitRevision(tenant, combinedFile, tagId, linkedFiles = []) {
    this.fields = this.internalPebFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, true, tagId, null, false, null, linkedFiles);
  }

  public async tenantPebSubmitRevision(tenant, combinedFile, tagId) {
    this.fields = this.tenantPebFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, false, tagId);
  }

  // Cb Submit Revision functions
  public async internalCbSubmitRevision(tenant, combinedFile, tagId, linkedFiles) {
    this.fields = this.internalCbFields;
    tenant.id = tenant.id || tenant.tenant_id;

    await this.submitRevision(tenant, combinedFile, DataType.Tenant, true, tagId, null, false, null, linkedFiles);
  }

  public async tenantCbSubmitRevision(tenant, combinedFile, tagId) {
    this.fields = this.tenantCbFields;
    tenant.id = tenant.id || tenant.tenant_id;

    await this.submitRevision(tenant, combinedFile, DataType.Tenant, false, tagId);
  }

  // Punchlist Submit Revision functions
  public async internalPunchlistSubmitRevision(tenant, combinedFile, tagId) {
    this.fields = this.internalPunchlistFields;
    const includes = {
      pm: true,
      wm: true,
      arch: true,
    };
    await this.submitRevision(tenant, combinedFile, DataType.Project, true, tagId, includes);
  }

  public async tenantPunchlistSubmitRevision(tenant, combinedFile, tagId) {
    this.fields = this.tenantPunchlistFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, false, tagId);
  }

  public async finalTenantPunchlistSubmitRevision(tenant, combinedFile, tagId) {
    this.fields = this.finalTenantPunchlistFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, false, tagId);
  }

  // Budget Submit Revision functions
  public async internalARFSubmitRevision(tenant, combinedFile, tagId, include = null, message = null) {
    this.fields = this.internalArfFields;
    await this.submitRevision(tenant, combinedFile, DataType.Quote, true, tagId, include, message);
  }

  public async arfSubmitRevision(arf, combinedFile, tagId, include) {
    this.fields = this.arfFields;
    await this.submitRevision(arf, combinedFile, DataType.Arf, true, tagId, include);
  }

  public async internalBudgetSubmitRevision(tenant, combinedFile, tagId, message = null) {
    this.fields = this.internalBudgetFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, true, tagId, null, true, message);
  }

  public async tenantBudgetSubmitRevision(tenant, combinedFile, tagId, message = null) {
    this.fields = this.tenantBudgetFields;
    await this.submitRevision(tenant, combinedFile, DataType.Tenant, false, tagId, null, false, message);
  }

  // Restarts the review process for a review
  private async submitRevision(
    data,
    combinedFile,
    dataType,
    isInternal,
    tagId,
    include = null,
    customReviewChain = false,
    message = null,
    linkedFiles = []
  ) {
    const attachedFiles = combinedFile;
    this.reviewComplete = false;

    const taskId = data[this.fields.saved_approval_task_id];
    const taskData: Task = {
      id: taskId,
      is_locked: 0,
      due_date: this.dateService.addWeekdays(0).format('YYYY-MM-DD'),
    };

    if (data[this.fields.saved_approval_task]?.description) {
      taskData.description = data[this.fields.saved_approval_task].description;
    }

    // Creates the task note along with attaching the files
    this.progressIndicatorService.updateStatus('Creating task note');
    const newNote = await this.projectService
      .createNote(ResourceType.Task, taskId, message || `Approval process has been restarted`)
      .toPromise();

    // Attaches the passed files to the note that was just created
    const createdFiles = await this.fileService.addFilesToNote(newNote, taskId, attachedFiles, linkedFiles);
    this.fileService.makeCurrent(tagId, taskId).subscribe();

    if (isInternal) {
      // Sets the assigned user. It also checks to see if the
      const accessoryInfo = await this.displayReviewersService.getCurrentReviewers(taskId, include, customReviewChain);

      const accessoryData = accessoryInfo.accessoryData;
      const isApproved = accessoryData && accessoryData.reviewChain[0]?.id === this.authService.currentUser?.id;

      if (dataType === DataType.Quote) {
        accessoryData.reviewCreator = this.authService.currentUser?.id;
      }

      if (accessoryData && accessoryData.reviewChain?.length && accessoryData.reviewChain[0]) {
        let assignedUser = accessoryData.reviewChain[0].id;
        if (isApproved) {
          if (accessoryData?.reviewChain?.length === 1) {
            this.reviewComplete = true;
          }

          accessoryData.reviewChain[0].status = TaskReviewStatus.Approved;
          accessoryData.reviewChain[0].date = moment().toDate();
          assignedUser = accessoryData.reviewChain[1]?.id || assignedUser;
          // If the user creating the review task is the same as the first reviewers this creates a task activity on the new task
          await this.eventService
            .createTaskApprovalEvent(taskId, TaskReviewStatus.Approved, 'Approved upon revision')
            .toPromise();
        }
        accessoryData.reviewFiles = createdFiles;
        taskData.accessory_data = JSON.stringify(accessoryData);
        taskData.assigned_user_id = assignedUser;
      }
    } else {
      const taskInfo = await this.projectService.getTaskById(taskId, ['id', 'accessory_data']).toPromise();
      const accessoryData = taskInfo?.accessory_data && JSON.parse(taskInfo.accessory_data);
      if (accessoryData) {
        accessoryData.reviewFiles = createdFiles;
        taskData.accessory_data = JSON.stringify(accessoryData);
      }

      taskData.assigned_user_id = data.representative_id;
    }

    // Updates the given fields for the object passed in
    await this.selectUpdateFunction(data, dataType, false);

    // Updates the review task
    this.progressIndicatorService.updateStatus('Unlocking task');
    await this.projectService.updateTask(taskData).toPromise();

    this.progressIndicatorService.close();
  }

  // Selects which update function should run
  private async selectUpdateFunction(data, dataType, revision = true) {
    switch (dataType) {
      case DataType.Tenant:
        await this.updateTenant(data, revision);
        break;
      case DataType.Section:
        await this.updateSection(data, revision);
        break;
      case DataType.Project:
        await this.updateProject(data, revision);
        break;
      case DataType.Quote:
        await this.updateQuote(data, revision);
        break;
      case DataType.Arf:
        await this.updateArf(data, revision);
        break;
      case DataType.Bid:
        // await this.updateBid(data, revision);
        break;
      default:
        await this.updateTenant(data, revision);
        break;
    }
  }

  // Makes an api call to update the ProjectTenant object
  private async updateTenant(tenant, revision = true) {
    await this.projectTenantService
      .updateProjectTenant(tenant.id, {
        [this.fields.revision]: revision ? tenant[this.fields.revision] + 1 : tenant[this.fields.revision],
        [this.fields.approval_task_id]: revision ? null : tenant[this.fields.saved_approval_task_id],
        [this.fields.saved_approval_task_id]: revision ? tenant[this.fields.approval_task_id] : null,
      })
      .toPromise();
  }

  // Makes an api call to update the ProjectTenant object
  private async updateSection(section, revision = true) {
    await this.projectTenantService
      .updatePEBSection(section.tenant_id, {
        [this.fields.revision]: revision ? section[this.fields.revision] + 1 : section[this.fields.revision],
        [this.fields.approval_task_id]: revision ? null : section[this.fields.saved_approval_task_id],
        [this.fields.saved_approval_task_id]: revision ? section[this.fields.approval_task_id] : null,
      })
      .toPromise();
  }

  // Makes an api call to update the Project object
  private async updateProject(project, revision = true) {
    await this.projectService
      .updateProject(project.id, {
        [this.fields.revision]: revision ? project[this.fields.revision] + 1 : project[this.fields.revision],
        [this.fields.approval_task_id]: revision ? null : project[this.fields.saved_approval_task_id],
        [this.fields.saved_approval_task_id]: revision ? project[this.fields.approval_task_id] : null,
      })
      .toPromise();
  }

  // Makes an api call to update the Quote object
  private async updateQuote(quote, revision = true) {
    await this.productService
      .updateQuote(quote.id, {
        [this.fields.revision]: revision ? quote[this.fields.revision] + 1 : quote[this.fields.revision],
        [this.fields.approval_task_id]: revision ? null : quote[this.fields.saved_approval_task_id],
        [this.fields.saved_approval_task_id]: revision ? quote[this.fields.approval_task_id] : null,
      })
      .toPromise();
  }

  private async updateArf(arf: Arf, revision = true) {
    await this.arfService
      .updateArf(arf.id, {
        [this.fields.revision]: revision ? arf[this.fields.revision] + 1 : arf[this.fields.revision],
        [this.fields.status_id]:
          arf.status_id === ArfStatus.Draft
            ? this.reviewComplete
              ? ArfStatus.ReviewComplete
              : ArfStatus.InReview
            : ArfStatus.Draft,
      })
      .toPromise();
  }

  private async updateBid(bid, revision = true) {
    await this.projectService
      .updateBid(bid.id, { [this.fields.revision]: revision ? bid.contract_revision + 1 : bid.contract_revision }, [
        'contract_revision',
      ])
      .toPromise();
  }
}
