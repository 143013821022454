<app-base-dialog [title]="'Reset Password'" (closeDialog)="close()"></app-base-dialog>
<mat-dialog-content class="d-flex flex-column forgot-password-dialog">
  <h3 class="dkblue font-weight-semibold mb-3 mt-4 lh-30">Forgot your password?</h3>
  <h5 class="dkgray font-weight-normal mb-3 lh-24">
    Don't worry - enter your email address and we will send you reset instructions.
  </h5>
  <mat-form-field appearance="outline" class="full-width pt-3 pb-1">
    <input matInput placeholder="Enter Email" [formControl]="email" />
  </mat-form-field>
  <div class="py-2 px-3 d-flex align-items-top my-2 bg-shade-red br-5">
    <i class="fa fa-exclamation-circle red mr-3 mt-1"></i>
    <p class="mb-0 dkblue">
      Your password for 1CALL Cloud is unique to 1CALL Cloud. If you are having trouble logging in, continue to reset
      your password for 1CALL Cloud ONLY.
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="pb-2 pt-5">
  <button (click)="close()" mat-button class="gray px-4 mb-2" type="button">Cancel</button>
  <button (click)="forgotPassword()" [disabled]="email.invalid" mat-flat-button color="accent" class="px-4 mb-2">
    Reset Password
  </button>
</mat-dialog-actions>
