<app-base-dialog [draggable]="true" [title]="dialogTitle" (closeDialog)="cancel()"></app-base-dialog>
<div
  mat-dialog-content
  data-test-id="edit-note-dialog"
  id="edit-note-dialog"
  class="project-modal edit-note-dialog px-4"
>
  <div class="row mb-3">
    <div class="col">
      <app-editor #editor placeholder="Edit note"></app-editor>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex align-items-center">
      <input
        [hidden]="true"
        #newConversationUploader
        multiple
        type="file"
        (change)="addFileToNewNote($event.target.files); newConversationUploader.value = null"
      />
      <button mat-icon-button color="accent">
        <mat-icon
          (click)="newConversationUploader.click()"
          matTooltip="Upload File"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          attach_file
        </mat-icon>
      </button>
      <mat-chip-list class="d-inline-block align-middle">
        <app-file-chip
          *ngFor="let file of noteFiles"
          [file]="file"
          [removable]="true"
          (removeEvent)="removeNewNoteFile(file)"
        >
        </app-file-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
<div mat-dialog-actions class="row mt-3 pb-3">
  <div class="col">
    <button (click)="cancel()" mat-flat-button class="full-width gray">Cancel</button>
  </div>
  <div class="col">
    <button
      mat-flat-button
      (click)="editAgendaItemNote()"
      class="full-width"
      color="success"
      [disabled]="(inValid || note.pristine) && !fileChanges"
    >
      Save Changes
    </button>
  </div>
</div>
