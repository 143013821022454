/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse, Topic, TopicAccess, TopicCategory, TopicGroup, TopicType } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  public host: string = environment.serviceHost;
  public topicCategoriesUrl = `${this.host}/api/v1/topic-categories`;
  public topicGroupsUrl = `${this.host}/api/v1/topic-groups`;
  public topicAccessUrl = `${this.host}/api/v1/topic-access`;
  public topicTypesUrl = `${this.host}/api/v1/topic-types`;
  public topicsUrl = `${this.host}/api/v1/topics`;
  public topicsWorkOrderChecklistTemplateUrl = `${this.host}/api/v1/work-order-checklist-templates`;

  // TODO, discuss a data sharing stragety (between topics, categories, groups and workspace).work-order
  // Affects many components, so, its a future task
  // Recomends only using the topics, category, groups date in WO/Dispatch
  public categories: TopicCategory[] = [];
  public topics: Topic[] = [];
  public topicGroups: TopicGroup[] = [];
  public topicTypes: TopicType[] = [];

  workspaceSettingsTopicFields = [
    `id`,
    `name`,
    `workspace{id,name}`,
    `topic_type_id`,
    `topic_type{id,name}`,
    'visible_to',
    'visible_to_access',
    'selectable_by',
    'selectable_by_access',
    'priority_id',
    `work_order_priority{id,name,abbreviation}`,
    `is_enabled`,
    'tooltip',
    'checklist_template{id,title}',
  ];

  workspaceSettingsTopicCategoryFields = [
    `id`,
    `name`,
    `is_enabled`,
    `topics{${this.workspaceSettingsTopicFields.join(',')}}`,
  ];
  workspaceSettingsTopicGroupFields = [
    `id`,
    `name`,
    `is_enabled`,
    `topic_categories{${this.workspaceSettingsTopicCategoryFields.join(',')}}`,
  ];

  public topicDialogFields = this.workspaceSettingsTopicFields;

  newRequestTopicGroupFields = [`id`, `name`, `building_ids`];
  newRequestTopicCategoryFields = [`id`, `name`, `topic_group{${this.newRequestTopicGroupFields.join(',')}}`];
  newRequestTopicFields = [
    `id`,
    `name`,
    `workspace_id`,
    `target_workspace_id`,
    `topic_category{${this.newRequestTopicCategoryFields.join(',')}}`,
    `topic_type_id`,
    `form{content}`,
    'visible_to',
    'selectable_by',
    'priority_id',
    'tooltip',
  ];

  quickRequestTopicFields = [
    `id`,
    `name`,
    `workspace_id`,
    `topic_category{${this.newRequestTopicCategoryFields.join(',')}}`,
    `topic_type_id`,
    `form{content}`,
    `visible_to`,
    `selectable_by`,
  ];

  constructor(
    private apiFilterService: ApiFilterService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService
  ) {
    this.getTopicTypes(['id', 'name']).subscribe((result) => (this.topicTypes = result));
  }

  getTopicById(topic_id: number, fields: string[]): Observable<Topic> {
    const filterString = this.apiFilterService.getFilterString([
      { type: 'field', field: 'id', value: topic_id.toString() },
    ]);
    return this.http.get(`${this.topicsUrl}?${filterString}&fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        return result.data?.topics?.[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTopics(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    includeDisabled = false,
    disabledIdsToInclude: number[] = []
  ): Observable<Topic[]> {
    const queryFields = fields.join(',');
    let newAPIFilters: APIFilter[] = [];
    if (apiFilters && apiFilters.length > 0) {
      newAPIFilters = [{ type: 'operator', value: '(' }, ...apiFilters, { type: 'operator', value: ')' }];
      if (!includeDisabled) {
        newAPIFilters.push({ type: 'operator', value: 'AND' });
      }
    }
    if (!includeDisabled) {
      const enabledFilters = [
        { type: 'operator', value: '(' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'category_is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'group_is_enabled', value: '1' },
        { type: 'operator', value: ')' },
      ];
      if (disabledIdsToInclude?.length) {
        newAPIFilters = [
          ...newAPIFilters,
          { type: 'operator', value: '(' },
          { type: 'field', field: 'id', value: uniq(disabledIdsToInclude).join('^') },
          { type: 'operator', value: 'OR' },
          ...enabledFilters,
          { type: 'operator', value: ')' },
        ];
      } else {
        newAPIFilters = [...newAPIFilters, ...enabledFilters];
      }
    }
    const filterString = this.apiFilterService.getFilterString(newAPIFilters);
    return this.http
      .get(
        `${this.topicsUrl}?limit=${limit || 1000}&fields=${queryFields}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : '&sort=name'}${sortOrder ? `&order=${sortOrder}` : '&order=asc'}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.topics;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createTopic(topic: Topic, fields?: string[]): Observable<Topic> {
    return this.http.post(`${this.topicsUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, topic).pipe(
      map((result: ServiceResponse) => {
        const createdTopic: Topic = result.data.topic;
        return createdTopic;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateTopic(topicId: number, topic: Topic, fields?: string[]): Observable<Topic> {
    return this.http.put(`${this.topicsUrl}/${topicId}?${fields ? `fields=${fields.join(',')}` : ''}`, topic).pipe(
      map((result: ServiceResponse) => {
        const updatedTopic: Topic = result.data.topic;
        return updatedTopic;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTopicGroups(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    includeDisabled = false,
    disabledIdsToInclude: number[] = []
  ): Observable<TopicGroup[]> {
    const queryFields = fields.join(',');
    let newAPIFilters: APIFilter[] = [];
    if (apiFilters && apiFilters.length > 0) {
      newAPIFilters = [{ type: 'operator', value: '(' }, ...apiFilters, { type: 'operator', value: ')' }];
      if (!includeDisabled) {
        newAPIFilters.push({ type: 'operator', value: 'AND' });
      }
    }
    if (!includeDisabled) {
      const enabledFilters = [{ type: 'field', field: 'is_enabled', value: '1' }];
      if (disabledIdsToInclude?.length) {
        newAPIFilters = [
          ...newAPIFilters,
          { type: 'operator', value: '(' },
          { type: 'field', field: 'id', value: uniq(disabledIdsToInclude).join('^') },
          { type: 'operator', value: 'OR' },
          ...enabledFilters,
          { type: 'operator', value: ')' },
        ];
      } else {
        newAPIFilters = [...newAPIFilters, ...enabledFilters];
      }
    }
    const filterString = this.apiFilterService.getFilterString(newAPIFilters);
    return this.http
      .get(
        `${this.topicGroupsUrl}?limit=${limit || 1000}&fields=${queryFields}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : '&sort=name'}${sortOrder ? `&order=${sortOrder}` : '&order=asc'}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.topic_groups;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateTopicGroup(topicGroupId: number, topicGroup: TopicGroup, fields?: string[]): Observable<TopicGroup> {
    return this.http
      .put(`${this.topicGroupsUrl}/${topicGroupId}?${fields ? `fields=${fields.join(',')}` : ''}`, topicGroup)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedTopicGroup: TopicGroup = result.data['topic group'];
          return updatedTopicGroup;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTopicCategories(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    includeDisabled = false,
    disabledIdsToInclude: number[] = []
  ): Observable<TopicCategory[]> {
    const queryFields = fields.join(',');
    let newAPIFilters: APIFilter[] = [];
    apiFilters = apiFilters?.filter((f) => f.value);
    if (apiFilters?.length) {
      newAPIFilters = [{ type: 'operator', value: '(' }, ...apiFilters, { type: 'operator', value: ')' }];
      if (!includeDisabled) {
        newAPIFilters.push({ type: 'operator', value: 'AND' });
      }
    }
    if (!includeDisabled) {
      const enabledFilters = [
        { type: 'operator', value: '(' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'group_is_enabled', value: '1' },
        { type: 'operator', value: ')' },
      ];
      if (disabledIdsToInclude?.length) {
        newAPIFilters = [
          ...newAPIFilters,
          { type: 'operator', value: '(' },
          { type: 'field', field: 'id', value: uniq(disabledIdsToInclude).join('^') },
          { type: 'operator', value: 'OR' },
          ...enabledFilters,
          { type: 'operator', value: ')' },
        ];
      } else {
        newAPIFilters = [...newAPIFilters, ...enabledFilters];
      }
    }
    const filterString = this.apiFilterService.getFilterString(newAPIFilters);
    return this.http
      .get(
        `${this.topicCategoriesUrl}?limit=${limit || 1000}&fields=${queryFields}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : '&sort=name'}${sortOrder ? `&order=${sortOrder}` : '&order=asc'}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.topic_categories;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createTopicCategory(topicCategory: TopicCategory, fields?: string[]): Observable<TopicCategory> {
    return this.http
      .post(`${this.topicCategoriesUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, topicCategory)
      .pipe(
        map((result: ServiceResponse) => {
          const createdTopicCategory: TopicCategory = result.data['topic category'];
          return createdTopicCategory;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateTopicCategory(
    topicCategoryId: number,
    topicCategory: TopicCategory,
    fields?: string[]
  ): Observable<TopicCategory> {
    return this.http
      .put(`${this.topicCategoriesUrl}/${topicCategoryId}?${fields ? `fields=${fields.join(',')}` : ''}`, topicCategory)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedTopicCategory: TopicCategory = result.data['topic category'];
          return updatedTopicCategory;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTopicTypes(fields: string[], apiFilters?: APIFilter[]): Observable<TopicType[]> {
    const queryFields = fields.join(',');
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.topicTypesUrl}?fields=${queryFields}${!filterString || filterString === '' ? '' : `&${filterString}`}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.topic_types;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTopicAccess(fields: string[], apiFilters?: APIFilter[]): Observable<TopicAccess[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.topicAccessUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.topic_access;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTopicWorkOrderCheckListTemplate(fields: string[], apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.topicsWorkOrderChecklistTemplateUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.work_order_checklist_templates;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createTopicChecklistTemplateItem(checklistTemplateItem, fields?: string[]): Observable<any> {
    return this.http
      .post(
        `${this.topicsWorkOrderChecklistTemplateUrl}?${fields ? `fields=${fields.join(',')}` : ''}`,
        checklistTemplateItem
      )
      .pipe(
        map((result: ServiceResponse) => {
          const createdChecklistTemplateItem = result.data['work order checklist template'];
          return createdChecklistTemplateItem;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateTopicChecklistTemplate(
    checklistTemplateItemId: number,
    checklistTemplateItem,
    fields?: string[]
  ): Observable<any> {
    return this.http
      .put(
        `${this.topicsWorkOrderChecklistTemplateUrl}/${checklistTemplateItemId}?${
          fields ? `fields=${fields.join(',')}` : ''
        }`,
        checklistTemplateItem
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedChecklistTemplateItem = result.data['work order checklist template'];
          return updatedChecklistTemplateItem;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteTopicChecklistTemplate(checklistTemplateItemId: number): Observable<void> {
    return this.http
      .delete(`${this.topicsWorkOrderChecklistTemplateUrl}/${checklistTemplateItemId}`)
      .pipe(catchError((e) => this.handleErrorService.handleError(e)));
  }
}
