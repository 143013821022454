import { Component, OnInit, Input, ViewChild, ApplicationRef } from '@angular/core';

import * as moment from 'moment';

import { ProjectService } from 'src/app/services';

import { ProjectConstruction } from 'src/app/workspaces/construction/types';
import { Invoice } from '../../types';

@Component({
  selector: 'app-budget-report-export',
  templateUrl: './budget-report-export.component.html',
  styleUrls: ['./budget-report-export.component.scss'],
})
export class BudgetReportExportComponent implements OnInit {
  @Input() selectedInvoice: Invoice;
  @Input() disableInit = false;
  @ViewChild('pdf', { static: false }) pdf;
  @ViewChild('childBudget') childBudget;
  @Input() parentBudget: any;
  getChildBudget = false;

  constructor(private appRef: ApplicationRef, private projectService: ProjectService) {}

  allPEBsAreFinalized = true;

  projectFields: string[] = [
    'code',
    'title',
    'building_code',
    'floor_code',
    'module_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'end_date',
    'architect_first_name',
    'architect_last_name',
    'square_footage',
  ];
  projectTenantFields: string[] = ['peb_status', 'selected_peb_id'];
  pebFields: string[] = ['tenant_id'];
  bidPackageFields: string[] = [
    'trade_name',
    'awarded_company_name',
    'awarded_amount',
    'budget_amount',
    'number',
    'child_request{id}',
    'child_project{id}',
  ];
  pebItemFields: string[] = ['trade_id', 'subtotal'];
  invoiceFields: string[] = [
    'bid_package_id',
    'change_order_id',
    'number',
    'trade_name',
    'company_name',
    'created_datetime',
    'total',
    'retainage',
    'title',
  ];
  changeOrderFields: string[] = [
    'code',
    'description',
    'short_description',
    'cost_change',
    'local_index',
    'trade_name',
    'company_name',
  ];

  project: ProjectConstruction;
  projectId: number;

  ngOnInit() {
    this.projectId = this.selectedInvoice?.project_id || this.projectService.currentSelectedProjectId;
    if (!this.disableInit) {
      this.getProjectData();
    }
    if (!this.parentBudget) {
      this.getChildBudget = true;
    }
  }

  async refresh() {
    await this.getProjectData();
    if (this.parentBudget) {
      await this.parentBudget.refresh(false);
    } else if (this.childBudget) {
      await this.childBudget.refresh();
    }
  }

  async getProjectData() {
    if (this.selectedInvoice?.project_id && this.projectId !== this.selectedInvoice.project_id) {
      this.projectId = this.selectedInvoice?.project_id;
    }

    this.project = await this.projectService.getProjectById(this.projectId, this.projectFields).toPromise();
  }

  public get budget() {
    return this.parentBudget ?? this.childBudget;
  }

  public get budgetedTotal() {
    return this.budget?.budgetedTotal;
  }

  public get originalContractSum() {
    return this.budget?.originalContractSum;
  }

  public get changeOrderTotal() {
    return this.budget?.changeOrderTotal;
  }

  public get retainageTotal() {
    return this.budget?.retainageTotal;
  }

  public get billedTotal() {
    return this.budget?.invoicesBilledTotal;
  }

  public get invoicesProcessedTotal() {
    return this.budget?.invoicesProcessedTotal;
  }

  public get invoicesPendingTotal() {
    return this.budget?.invoicesPendingTotal;
  }

  public get balanceToFinish() {
    return this.budget?.balanceToFinish;
  }

  public get bidPackages() {
    return this.budget?.bidPackages;
  }

  public get budgetData() {
    return this.budget?.budgetData;
  }

  public get budgetItems() {
    return this.budget?.budgetItems;
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  public getFormattedDate(date: Date): string {
    return moment(date).format('D MMMM YYYY');
  }

  async export() {
    await this.refresh();

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    const group = await this.pdf.export();
    return group;
  }

  async saveAs(fileName: string) {
    await this.refresh();
    await this.pdf.saveAs(fileName);
  }

  public formatDate(date: Date): string {
    return moment(date).format('MMM DD YYYY').toString();
  }
}
