export enum PEBStatus {
  PreApproval,
  SubmittedForInternalApproval,
  ApprovedByInternal,
  RejectedByInternal,
  SubmittedForTenantApproval,
  ApprovedByTenant,
  RejectedByTenant,
  Finalized,
}
