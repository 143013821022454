import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FOUND_PAGE } from '../enums';
import { Task } from '../types';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class AppRoutingService {
  @Output()
  public taskSelected = new EventEmitter<Task>();

  constructor(private projectService: ProjectService, private snackBar: MatSnackBar, private router: Router) {}

  /**
   * Route to the tasks page. If a projectId is not provided this will user the currentSelectedProjectId from the projectService.
   * @param taskId Task Id to be selected
   * @param projectId (optional) The Project id to select. If not provided, the current selected projects' id will be used
   */
  public async gotoProjectTask(taskId: number, projectId?: number): Promise<boolean> {
    let taskExists: Task;
    await this.projectService
      .getTaskByIdSuppressed(taskId)
      .toPromise()
      .then((task) => {
        taskExists = task;
      })
      .catch((e) => {
        taskExists = null;
      });

    if (taskExists) {
      const navigatePromise = this.router.navigate([`/projects/${projectId || taskExists.project_id}/tasks/${taskId}`]);
      await navigatePromise.then((c) => {
        this.taskSelected.emit(taskExists);
      });
      return navigatePromise;
    } else {
      this.snackBar.open('This task no longer exists');
      return false;
    }
  }

  /**
   * Route to the PEB Page. If a selectTenantId parameter is given this will select the correct tenant tab on the PEB page.
   * @param selectTenantId (optional) The tenant id to be selected on the PEB page
   */
  public gotoPebPage(selectTenantId?: number): Promise<boolean> {
    return this.router.navigate([`/projects/${this.projectService.currentSelectedProjectId}/peb`], {
      queryParams: { selectTenantId },
    });
  }

  /**
   * Route to the PEB Page. If a selectTenantId parameter is given this will select the correct tenant tab on the PEB page.
   * @param selectTenantId (optional) The tenant id to be selected on the PEB page
   */
  public gotoPunchlistPage(): Promise<boolean> {
    return this.router.navigate([`/projects/${this.projectService.currentSelectedProjectId}/punchlist`]);
  }

  /**
   * Route to the CB Page. If a selectTenantId parameter is given this will select the correct tenant tab on the CB page.
   * @param selectTenantId (optional) The tenant id to be selected on the CB page
   */
  public gotoCbPage(selectTenantId?: number): Promise<boolean> {
    return this.router.navigate([`/projects/${this.projectService.currentSelectedProjectId}/construction-budget`], {
      queryParams: { selectTenantId },
    });
  }

  /**
   * Route to a projects' directory page. If no projectId is provided then the current selected project id will be used.
   * @param projectId (optional) Project Id of the project to navigate to
   */
  public gotoProjectDirectoryPage(projectId?: number): Promise<boolean> {
    return this.router.navigate([
      `/projects/${projectId || this.projectService.currentSelectedProjectId}/project-directory`,
    ]);
  }

  public gotoWorkOrdersListPage(): Promise<boolean> {
    return this.router.navigate([`/work-orders/`]);
  }

  public errorRedirect(status) {
    let url, fp;
    [url, fp] =
      status === 401 || status === 403
        ? ['/401', FOUND_PAGE.NOT_AUTHORIZED]
        : status === 404
        ? ['/404', FOUND_PAGE.NOT_FOUND]
        : ['/404', FOUND_PAGE.UN_KNOWN];
    void this.router.navigate([url], {
      skipLocationChange: true,
      queryParams: {
        status: fp,
      },
    });
  }
}
