import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from 'src/app/services';

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss'],
})
export class FilePreviewDialogComponent implements OnInit {
  private _blob: Blob;
  private originalBlob: Blob;
  private fileExtension: string;
  private _fileName: string;
  private _fileUrl: string;

  public isCsv: any;

  public dialogTitle = 'File Preview';
  public isDocx: boolean;
  public isExcel: boolean;
  public isImage: boolean;
  public isPDF: boolean;
  public isTxt: boolean;
  public imageUrl: any;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _dialogRef: MatDialogRef<FilePreviewDialogComponent>,
    private _fileService: FileService,
    private _snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    if (this._data?.file?.name) {
      this.getFileType(this._data?.file);
      this._fileName = this._data?.file?.name;
      this.dialogTitle = this._fileName;
    }
  }

  getFileType(file) {
    if (!this.fileExtension) {
      this.fileExtension = file?.name.split('.').pop().toLowerCase();
      this.isImage = ['jpg', 'jpeg', 'png'].includes(this.fileExtension);
      this.isPDF = ['pdf'].includes(this.fileExtension);
      this.isExcel = ['xlsx', 'xls'].includes(this.fileExtension);
      this.isCsv = ['csv'].includes(this.fileExtension);
      this.isDocx = ['doc', 'docx'].includes(this.fileExtension);
      this.isTxt = ['txt'].includes(this.fileExtension);
    }
  }

  getFileInfo({ file, blob, originalBlob }) {
    if (!this.fileExtension) {
      this.getFileType(file);
      this._fileName = file.name;
    }
    this._blob = blob;
    this.originalBlob = originalBlob;
    this._fileUrl = file.path;
  }

  public close(): void {
    this._dialogRef.close();
  }

  public async downloadFile() {
    if (this._data?.file) {
      await this._fileService.download(this._data.file);
    }
  }

  public newWindow() {
    window.open(this._fileUrl, '_blank');
  }
}
