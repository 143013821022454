<app-base-dialog [title]="title" (closeDialog)="close()"> </app-base-dialog>

<div mat-dialog-content>
  <div class="m-portlet">
    <div class="p-4" *ngIf="!data?.relevantChanges?.length">No activity</div>
    <table class="mat-table" *ngIf="data?.relevantChanges?.length">
      <tr class="mat-header-row bg-shade-ltblue">
        <th class="mat-header-cell p-1 primary">Date</th>
        <th class="mat-header-cell p-1 primary">Sub Cost Code</th>
        <th class="mat-header-cell p-1 primary">Before</th>
        <th class="mat-header-cell p-1 primary">After</th>
        <th class="mat-header-cell p-1 primary">Diff</th>
        <th class="mat-header-cell p-1 primary"></th>
        <th class="mat-header-cell p-1 primary">User</th>
      </tr>
      <ng-container *ngFor="let change of data.relevantChanges">
        <tr
          class="mat-row border-top border-gray"
          *ngIf="
            change[data.budgetType + 'BudgetAfter'] !== change[data.budgetType + 'BudgetBefore'] ||
            change.isEnabledAfter !== change.isEnabledBefore
          "
        >
          <td class="mat-cell pr-3 pl-1 py-1">{{ change.date | date: 'short' }}</td>
          <td class="mat-cell pr-3 pl-1 py-1">{{ change.subCostCode.code }} - {{ change.subCostCode.label }}</td>
          <td class="mat-cell pr-3 pl-1 py-13">
            <ng-container
              *ngIf="
                change[data.budgetType + 'BudgetBefore'] !== change[data.budgetType + 'BudgetAfter'] ||
                  (change.isEnabledBefore && !change.isEnabledAfter);
                else budgetNotChanged
              "
            >
              {{ change[data.budgetType + 'BudgetBefore'] | currency }}
            </ng-container>
            <ng-template #budgetNotChanged>
              <span class="gray">{{ change[data.budgetType + 'BudgetAfter'] | currency }}</span>
            </ng-template>
          </td>
          <td class="mat-cell pr-3 pl-1 py-1">
            <ng-container
              *ngIf="
                change[data.budgetType + 'BudgetBefore'] !== change[data.budgetType + 'BudgetAfter'] ||
                  (!change.isEnabledBefore && change.isEnabledAfter);
                else budgetNotChanged
              "
            >
              {{ change[data.budgetType + 'BudgetAfter'] | currency }}
            </ng-container>
            <ng-template #budgetNotChanged>
              <span class="gray">{{ change[data.budgetType + 'BudgetAfter'] | currency }}</span>
            </ng-template>
          </td>
          <td class="mat-cell pr-3 pl-1 py-1">
            <ng-container *ngIf="change[data.budgetType + 'BudgetAfter'] - change[data.budgetType + 'BudgetBefore']">
              {{
                (change[data.budgetType + 'BudgetAfter'] - change[data.budgetType + 'BudgetBefore'] > 0 ? '+' : '') +
                  (change[data.budgetType + 'BudgetAfter'] - change[data.budgetType + 'BudgetBefore'] | currency)
              }}
            </ng-container>
          </td>
          <td class="mat-cell pr-3 pl-1 py-1">
            <ng-container *ngIf="change.isEnabledAfter && !change.isEnabledBefore">Activated</ng-container>
            <ng-container *ngIf="!change.isEnabledAfter && change.isEnabledBefore">Deactivated</ng-container>
          </td>
          <td class="mat-cell pr-1">{{ change.user.first_name }} {{ change.user.last_name }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="handleClose()">Close</button>
</div>
