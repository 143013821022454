export class TaskDashboardFilter {
  // Relates to My Tasks/ My Team/ Following
  public assignedTo: 'self' | 'team' | 'following' | 'unassigned' = 'self';
  // Relates to dropdown for All Tasks/ Overdue Only
  public taskType: 'all' | 'overdue_only' | 'approvals_only' = 'all';
  // Id of the current logged in user
  public userId: number;
  // Id array of the user's team
  public teamIds: number[];
  // all tasks need to be due between start and end date (or older than today)
  public startDate: Date | string;
  public endDate: Date | string;
  // whether we show complete tasks or not
  public showComplete = false;
  // whether we show tasks with no due date or not
  public showNoDueDate = false;
  // the project ids to search in, only applicable
  public projectIds: number[] = [];

  constructor(userId: number, teamIds: number[], startDate: Date | string, endDate: Date | string) {
    this.userId = userId;
    this.teamIds = teamIds;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
