<app-base-dialog
  [draggable]="true"
  [title]="'Select Agenda Items to include'"
  (closeDialog)="close()"
></app-base-dialog>
<div id="modal-container">
  <div class="row">
    <div class="col selections">
      <div class="d-flex p-3 br-10 bg-shade-accent align-items-top">
        <mat-icon class="primary mr-3">info</mat-icon>
        <p class="m-0 primary">
          Please indicate which agenda items you'd like to include along with their respective attachments. A PDF of the
          notes and a zip file containing all attachments will be downloaded.
        </p>
      </div>

      <mat-selection-list *ngIf="agendaItems.length > 0">
        <ng-container *ngFor="let item of agendaItems">
          <mat-list-option class="dkblue m-0" [selected]="item.selected" (click)="item.selected = !item.selected">
            <h6 class="m-0 dkblue d-inline-block align-middle">
              {{ item.description }}
            </h6>
          </mat-list-option>
          <mat-list-option
            *ngIf="item.hasAttachments && item.selected"
            [selected]="item.selectedAttachments"
            (click)="item.selectedAttachments = !item.selectedAttachments"
          >
            <p class="text-muted pl-4">Include attachments</p></mat-list-option
          >
        </ng-container>
      </mat-selection-list>
      <div *ngIf="agendaItems.length <= 0" class="no-items-panel">
        <div class="no-items-panel-content">
          <h1>No Agenda Items</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 align-items-center">
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold"></h6>
    </div>
    <div class="col-auto text-right">
      <button mat-button (click)="close()" class="ltgray mr-2">Cancel</button>
      <button mat-flat-button color="success" [disabled]="agendaItems.length < 1" (click)="submit()">
        Download Notes
      </button>
    </div>
  </div>
</div>
