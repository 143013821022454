import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  loginFormGroup: FormGroup = this.fb.group({
    email: [{ value: this.data ? this.data.email : '', disabled: true }],
    password: ['', [Validators.required]],
  });

  originalEmail: string;

  get email() {
    return this.loginFormGroup.get('email');
  }
  get password() {
    return this.loginFormGroup.get('password');
  }

  async ngOnInit() {
    this.originalEmail = this.data ? this.data.email : null;
  }

  submit(): void {
    if (this.loginFormGroup.valid) {
      const credentialsToSubmit = {
        email: this.originalEmail,
        password: this.password.value,
      };
      // check if we are on the login page. if so, navigate to home page, otherwise don't navigate
      // the reason for this is if the login dialog occurs on login page, it logs in without navigating
      let navigateAfterLogin = false;
      try {
        // check the URL of route. If an error occurs, just discard it
        const url = this.route.snapshot.queryParams.returnUrl;
        navigateAfterLogin = url.toLowerCase().includes('/login');
      } catch {}
      this.authService.login(credentialsToSubmit, true, navigateAfterLogin).subscribe(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          if (error.status === 0 && error.error instanceof ProgressEvent) {
            // A client-side or network error occurred.
            this.snackbar.open(navigator.onLine === true ? 'No Internet Connection' : 'Connection Error');
          } else if (error.error.message === 'Account not yet created') {
            this.snackbar.open('Please click Request Account to set up a 1CALL Cloud account.');
            this.dialogRef.close(false);
          } else {
            // clear password and trigger error
            const message = error.error.message || `Username and password are invalid!`;
            this.snackbar.open(message);
            this.password.setValue(null);
            this.password.setErrors({ invalid: true });
          }
        }
      );
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
