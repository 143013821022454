import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus } from '../../enums';
import {
  ArfsService,
  AuthService,
  DisplayReviewersService,
  ModalService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
} from '../../services';
import { ReviewChain, Task, TaskAccessoryData, UhatFileReference, User } from '../../types';
import { EditorComponent } from '../editor/editor.component';
import { FileAttachmentDialogComponent } from '../file-attachment-dialog/file-attachment-dialog.component';
import { UserProfilePreviewComponent } from '../user-profile-preview/user-profile-preview.component';

@Component({
  selector: 'app-review-sidebar',
  templateUrl: './review-sidebar.component.html',
  styleUrls: ['./review-sidebar.component.scss'],
})
export class ReviewSidebarComponent implements OnInit, OnDestroy {
  @Output() closeDialog = new EventEmitter<{
    approval: TaskReviewStatus;
    comment: string;
    files: UhatFileReference[];
    reviewChain: TaskAccessoryData['reviewChain'];
  }>();
  @Input() public task: Task;

  constructor(
    private arfService: ArfsService,
    private authService: AuthService,
    private dialog: MatDialog,
    public displayReviewersService: DisplayReviewersService,
    private modalService: ModalService,
    private taskService: ProjectTaskService,
    private projectService: ProjectService,
    private updateReviewerService: UpdateReviewerService
  ) {}

  public accessoryData: TaskAccessoryData;
  public approvalStatus: TaskReviewStatus;
  public attachedFiles: UhatFileReference[] = [];
  public currentUser: User;
  public currentReviewer: ReviewChain;
  public currentReviewerIndex: number;
  public isLoading: boolean;
  public isProjectManager: boolean;
  public isOpenReview: boolean;
  public reviewIsValid = true;
  public reviewers: ReviewChain[] = [];
  public reviewIsRejected: boolean;
  public taskSelectedRefresh: any;
  public undoLoading = false;
  public isReviewerAdmin = true;

  public approvalStatuses = [
    {
      color: 'bg-green',
      icon: 'check',
      status: TaskReviewStatus.Approved,
      title: 'No Exceptions',
    },
    {
      color: 'bg-orange',
      icon: 'check',
      status: TaskReviewStatus.NoteMarkings,
      title: 'Note Markings',
    },
    {
      color: 'bg-red',
      icon: 'close',
      status: TaskReviewStatus.Rejected,
      title: 'Revise and Resubmit',
    },
  ];

  public selectedStatus;
  public warningText;

  async ngOnInit() {
    this.parseAccessoryData();
    this.currentUser = this.authService.currentUser;
    this.isOpenReview = this.authService.isOpenReview(this.accessoryData?.type);
    await this.refresh();

    // Sets Project Manager
    let moduleId;
    if (this.task.project_id) {
      const project = await this.projectService.getProjectById(this.task?.project_id, ['module_id']).toPromise();
      moduleId = project.module_id;
    } else if (this.accessoryData.type === TaskAccessoryType.Arf) {
      const arf = await this.arfService.getArfById(this.accessoryData.parentId, ['module_id']).toPromise();
      moduleId = arf.module_id;
    }

    this.isProjectManager = this.authService.isModuleProjectManager(moduleId);

    // Listens for changes to the review process so the ui can stay up to date.
    this.taskSelectedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      if (data?.task?.id === this.task?.id && this.isOpenReview) {
        this.task.accessory_data = data.task.accessory_data;
        this.parseAccessoryData();
        await this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.taskSelectedRefresh.unsubscribe();
    } catch (e) {}
  }

  private async refresh(makeApiCall = true) {
    this.reviewIsRejected = !!this.accessoryData?.reviewChain.find(
      (reviewer) => reviewer.status === TaskReviewStatus.Rejected
    );

    if (
      this.isOpenReview &&
      !this.accessoryData?.reviewChain.find(
        (reviewer) => reviewer.id === this.currentUser.id || reviewer.status === TaskReviewStatus.Rejected
      )
    ) {
      this.accessoryData?.reviewChain.push({
        id: this.currentUser.id,
        status: TaskReviewStatus.Pending,
      });
    }

    if (makeApiCall) {
      this.reviewers = await this.displayReviewersService.addUserInfoToReviewChain(this.accessoryData?.reviewChain);
      this.currentReviewer = this.displayReviewersService.getCurrentReviewer(this.reviewers);
    }

    this.currentReviewerIndex = this.reviewers?.findIndex((reviewer) => reviewer?.id === this.currentReviewer?.id);
    this.isLoading = false;
  }

  private parseAccessoryData() {
    this.accessoryData = this.task?.accessory_data && JSON.parse(this.task.accessory_data);
  }

  get hasFiles() {
    return !!this.attachedFiles?.length;
  }

  public get rejectText() {
    return this.isOpenReview ? 'Flag for Review' : 'Reject';
  }

  public get TaskStatus() {
    return TaskStatus;
  }

  public get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  public get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  public get disableReview() {
    return this.isOpenReview && this.task.status_id !== TaskStatus.Complete;
  }

  async undoReview() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Retract Review',
        headerText: 'Retract Your Review',
        descriptionText:
          'Please add a reason for retracting this review. This will be recorded on the Key Control Task as a note.',
        userInput: {
          placeholder: 'Reason for retracting review',
          required: true,
        },
        confirmationButtonText: 'Retract Review',
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.undoLoading = true;
          this.accessoryData = await this.updateReviewerService.undoOpenReview(this.task?.id, this.accessoryData);
          await this.projectService
            .createNote(ResourceType.Task, this.task?.id, `A review has been retracted. Reason given: ${res}`)
            .toPromise();
          if (this.reviewers[this.reviewers.length - 1]) {
            this.reviewers[this.reviewers.length - 1].status = TaskReviewStatus.Pending;
          }
          await this.refresh(false);
          this.undoLoading = false;
        }
        this.close(null);
      });
  }

  public close(approval: TaskReviewStatus, editor?: EditorComponent, cancel = false) {
    this.selectedStatus = this.approvalStatuses.find((status) => status.status === approval);
    const comment = editor?.content?.value;
    const reviewChain =
      ((this.isOpenReview || this.accessoryData.type === TaskAccessoryType.Submittals) &&
        !cancel &&
        this.accessoryData?.reviewChain) ||
      null;

    if (this.isValid(comment, approval)) {
      this.isLoading = !!approval;
      this.closeDialog.emit({ approval, comment, files: this.attachedFiles, reviewChain });
    } else {
      this.warningText = approval === TaskReviewStatus.Rejected ? 'rejection' : 'note marking';
      this.approvalStatus = null;
    }
  }

  public resetReview() {
    const data = this.isOpenReview
      ? {
          titleBarText: 'Restart Audit Process',
          descriptionText:
            'Are you sure that you want to restart the audit process on this key control? Please enter the reason in the box below.',
          userInput: {
            required: true,
            placeholder: 'Reason For Restart',
          },
        }
      : {
          titleBarText: 'Reset Review Process',
          descriptionText:
            'Are you sure that you want to reset the approval process on this task? Please enter the reason in the box below.',
          userInput: {
            required: true,
            placeholder: 'Reason For Reset',
          },
          confirmationButtonText: 'Reset Review',
        };

    this.modalService
      .openConfirmationDialog(data)
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.isLoading = true;

          if (this.isOpenReview) {
            await this.updateReviewerService.resetOpenReview(this.task, res);
          } else {
            await this.updateReviewerService.resetReview(this.task?.id, this.accessoryData, res);
          }
        }
      });
  }

  public openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public openFileAttachmentDialog() {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.task.project_id,
          verifyFileExtension: this.accessoryData.type === TaskAccessoryType.Submittals,
          allowComment: false,
          // additionalParents,
          maxFiles,
          project_id: this.task.project_id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          const files: UhatFileReference[] = resultData;
          for (const file of files) {
            if (this.attachedFiles.find((f) => f?.id === file?.id) == null) {
              this.attachedFiles.push(file);
            }
          }
        }
      });
  }

  public removeFile(file) {
    this.attachedFiles = this.attachedFiles.filter((f) => f?.id !== file?.id);
  }

  public formatDate(date) {
    return moment(date).format('MM/DD/YY - h:mmA');
  }

  public isValid(comment, approval): boolean {
    this.reviewIsValid =
      approval === TaskReviewStatus.Approved ||
      ([TaskReviewStatus.Rejected, TaskReviewStatus.NoteMarkings].includes(approval) && (!!comment || this.hasFiles));

    return approval === TaskReviewStatus.Approved || this.reviewIsValid || !approval;
  }
}
