<div id="rfi" data-test-id="rfi" class="rfi container-fluid p-4">
  <div class="row">
    <div class="col">
      <h4 class="dkblue font-weight-semibold m-0">Requests for Information</h4>
    </div>
    <div class="col-auto text-right">
      <button mat-button color="accent" (click)="openSelectRfisDialog()">Download PDF</button>
      &nbsp;
      <button mat-button color="accent" *ngIf="hideClosed" (click)="hideClosed = false">Show Closed</button>
      <button mat-button color="accent" *ngIf="!hideClosed" (click)="hideClosed = true">Hide Closed</button>
      &nbsp;
      <button
        mat-flat-button
        color="success"
        (click)="openAddRFIDialog()"
        matTooltip="New RFI"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        *ngIf="isWorkspaceStaff"
      >
        + RFI
      </button>
    </div>
  </div>
  <br />
  <div class="portlet-table-header pt-0 pl-0 pl-2 pr-2">
    <div class="row m-0 align-items-center">
      <div class="col-auto" style="width: 100px">
        <label class="main-label">#</label>
      </div>
      <div class="col">
        <label class="main-label">RFI</label>
      </div>
      <div class="col-auto text-right mr-5">
        <label class="main-label">Due / Assigned</label>
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let r of rfis | rfiFilter: hideClosed">
    <div class="col">
      <div class="m-portlet mb-4 p-0">
        <div class="m-portlet__body pt-2 pb-2 pr-3 pl-3">
          <div class="row align-items-center">
            <div (click)="toggleRFIExpansion(r)" class="col-auto text-center pointer" style="width: 100px">
              <h5 class="m-0 dkblue font-weight-semibold">{{ r.local_index }}</h5>
            </div>
            <div (click)="toggleRFIExpansion(r)" class="col pointer">
              <h5 class="m-0 dkblue font-weight-semibold">
                {{ r.description }}
              </h5>
            </div>
            <div class="col-auto text-right">
              <p
                class="m-0 font-weight-semibold mr-2 ltblue d-inline-block align-middle"
                *ngIf="r.status_id === 1 && !r.answer"
                [ngClass]="{ red: r.daysUntilDueDate < 3 }"
              >
                {{ getDueDateText(r.daysUntilDueDate) }}
              </p>
              <p
                matTooltip="{{ getDueDateText(r.daysUntilDueDate) }}"
                matTooltipPosition="above"
                class="m-0 font-weight-semibold font-italic mr-2 gray d-inline-block align-middle"
                *ngIf="r.status_id === 1 && r.answer"
              >
                Answered
              </p>
              <p
                class="m-0 font-weight-semibold font-italic mr-2 green d-inline-block align-middle"
                *ngIf="r.status_id === 2"
              >
                Closed
              </p>

              <app-profile-thumbnail
                *ngIf="r.assigned_user_id"
                class="d-inline-block align-middle pl-3"
                [width]="32"
                [height]="32"
                [userId]="r.assigned_user_id"
                matTooltip="{{ r.assigned_user_first_name }} {{ r.assigned_user_last_name }}"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
              >
              </app-profile-thumbnail>
              <button
                *ngIf="canCloseRFIandCreatePR"
                mat-icon-button
                [matMenuTriggerFor]="task_menu"
                color="accent"
                matTooltip="More"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #task_menu="matMenu" xPosition="before">
                <button
                  (click)="closeRFI(r)"
                  mat-menu-item
                  class="ltblue font-weight-semibold"
                  *ngIf="r.status_id !== 2 && r.answer"
                >
                  <mat-icon>check_circle</mat-icon>
                  <span> Close RFI </span>
                </button>
                <button (click)="openEditRFIDialog(r)" mat-menu-item class="dkblue">
                  <mat-icon>edit</mat-icon>
                  <span> Edit RFI </span>
                </button>
                <button mat-menu-item class="dkblue" (click)="createReminderFromRFI(r)">
                  <mat-icon>add_alert</mat-icon>
                  <span> Create Reminder </span>
                </button>
                <button mat-menu-item class="dkblue" (click)="createMeetingAgendaFromRFI(r)">
                  <mat-icon>event_notes</mat-icon>
                  <span> Add to Meeting Agenda </span>
                </button>
                <button mat-menu-item class="dkblue" (click)="createPRFromRFI(r)">
                  <mat-icon>add_circle</mat-icon>
                  <span> Create PR </span>
                </button>
                <button (click)="deleteRFI(r)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span> Delete RFI </span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div *ngIf="r.is_expanded" [ngClass]="{ 'rfi-open': r.is_expanded }" class="row pb-4 pt-4">
            <div class="col">
              <div class="row mt-4 rfi-section">
                <div class="col-auto text-center" style="width: 100px">
                  <span class="badge badge-secondary">QUESTION</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-auto pr-0" style="width: 50px">
                      <app-profile-thumbnail
                        [userId]="r.question_from_id"
                        [width]="34"
                        [height]="34"
                      ></app-profile-thumbnail>
                    </div>
                    <div class="col">
                      <div class="row mb-3">
                        <div class="col">
                          <p class="m-0 dkblue font-weight-bold mb-1">
                            {{ r.question_from_first_name }} {{ r.question_from_last_name }}
                            <span *ngIf="r.question_from_company_name">({{ r.question_from_company_name }})</span>
                            <span class="gray font-weight-normal ml-2">
                              {{ r.created_datetime | date: 'MMM dd • h:mm a' }}
                            </span>
                          </p>
                          <h5 class="m-0 font-weight-normal mb-4 mt-3 dkblue lh2" [innerHTML]="r.question"></h5>
                        </div>
                      </div>
                      <div class="row mb-4" *ngIf="r.files">
                        <div class="col">
                          <mat-chip-list class="d-inline-block align-middle">
                            <app-file-chip *ngFor="let f of r.files" [file]="f"></app-file-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <!-- NEED THIS ONLY VISIBLE IF THE USER IS THE PM/WM -->
                      <div
                        *ngIf="
                          !r.answer &&
                          !r.showAnswer &&
                          (isWorkspaceStaff ||
                            (authService.isProjectEngineer(projectService.currentSelectedProjectId) &&
                              r.assigned_user_id === authService.getLoggedInUser().id))
                        "
                        class="row mb-5"
                      >
                        <div class="col">
                          <button (click)="toggleAnswerExpansion(r)" mat-flat-button class="mr-3" color="success">
                            Add Formal Response
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="r.answer" class="row rfi-section pt-5">
                <div class="col-auto text-center" style="width: 100px">
                  <span class="badge badge-success">ANSWER</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-auto pr-0" style="width: 50px">
                      <app-profile-thumbnail
                        [userId]="r.answer.created_by_id"
                        [width]="34"
                        [height]="34"
                      ></app-profile-thumbnail>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <p class="m-0 dkblue font-weight-bold mb-1">
                            {{ r.answer.created_by_first_name }}
                            {{ r.answer.created_by_last_name }} ({{ r.answer.created_by_company_name }})
                          </p>
                          <h5 class="m-0 font-weight-normal mb-4 mt-3 dkblue lh2" [innerHTML]="r.answer.message"></h5>
                        </div>
                      </div>
                      <div class="row mb-4" *ngIf="r.answer.files">
                        <div class="col">
                          <mat-chip-list class="d-inline-block align-middle">
                            <app-file-chip *ngFor="let f of r.answer.files" [file]="f"></app-file-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div *ngIf="canCloseRFIandCreatePR" class="col">
                          <button
                            mat-stroked-button
                            (click)="closeRFI(r)"
                            class="mr-4"
                            color="accent"
                            *ngIf="r.status_id !== 2"
                          >
                            Close RFI
                          </button>
                          <button
                            mat-button
                            *ngIf="r.status_id !== 2"
                            (click)="createPRFromRFI(r)"
                            class="mr-4"
                            color="accent"
                          >
                            Create PR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="r.showAnswer" class="row mt-5 mb-4">
                <div class="col-auto text-center" style="width: 100px">
                  <span class="badge badge-success opacity5">ANSWER</span>
                </div>
                <div class="col">
                  <div *ngIf="r.showAnswer" class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-auto pr-0" style="width: 50px">
                          <app-profile-thumbnail
                            [userId]="currentUser.id"
                            [width]="34"
                            [height]="34"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <p class="m-0 dkblue font-weight-bold mb-3">
                                {{ currentUser.first_name }} {{ currentUser.last_name }}
                                <span *ngIf="currentUser.company_name"> ({{ currentUser.company_name }})</span>
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <app-editor [id]="r.id" type="answer" placeholder="Formal Answer*"></app-editor>
                              <mat-error *ngIf="!r.validAnswer">An answer is required</mat-error>
                            </div>
                          </div>
                          <div *ngIf="r.showAnswer" class="row align-items-center mb-5">
                            <div class="col">
                              <button mat-flat-button color="success" (click)="createComment(r, true)">
                                Submit Answer
                              </button>
                              &nbsp;
                              <button mat-icon-button class="ltblue" (click)="openUploadModal('answer', r)">
                                <mat-icon>attach_file</mat-icon>
                              </button>
                              &nbsp;
                              <mat-chip-list class="d-inline-block align-middle">
                                <app-file-chip
                                  *ngFor="let f of r.newAnswer.files"
                                  [file]="f"
                                  [removable]="true"
                                  (removeEvent)="removeAnswerFile(f, r)"
                                ></app-file-chip>
                              </mat-chip-list>
                            </div>
                            <div class="col-4 text-right">
                              <button (click)="r.showAnswer = false" mat-button class="ltgray">Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <span class="pb-2 gray font-italic footnote lh-28">
                    * This section is visible to Staff and Suppliers
                  </span>
                </div>
              </div>

              <div *ngIf="isWorkspaceStaff || authService.isProjectEngineer(projectService.currentSelectedProjectId)">
                <div class="border-top">
                  <div class="row">
                    <div class="col text-right">
                      <span class="pb-2 gray font-italic footnote lh-28">
                        * This section is visible to Staff Only
                      </span>
                    </div>
                  </div>
                  <div class="row rfi-section pt-4 pb-4" *ngFor="let c of r.comments">
                    <div class="col-auto text-center" style="width: 100px">
                      <span class="badge badge-info">COMMENT</span>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col-auto pr-0" style="width: 50px">
                          <app-profile-thumbnail
                            [userId]="c.created_by_id"
                            [width]="34"
                            [height]="34"
                          ></app-profile-thumbnail>
                        </div>
                        <div class="col">
                          <div class="row mb-2">
                            <div class="col">
                              <p class="m-0 dkblue font-weight-bold d-inline-block align-middle mr-5">
                                {{ c.created_by_first_name }} {{ c.created_by_last_name }} ({{
                                  c.created_by_company_name
                                }})
                                <span class="gray font-weight-normal ml-2">
                                  {{ c.created_datetime | date: 'MMM dd • h:mma' }}</span
                                >
                              </p>
                            </div>
                          </div>
                          <div class="row mt-3 mb-3">
                            <div class="col">
                              <h5 class="m-0 dkblue font-weight-normal lh2" [innerHTML]="c.message"></h5>
                            </div>
                          </div>
                          <div *ngIf="c.files" class="row mt-2 mb-2">
                            <div class="col">
                              <mat-chip-list class="d-inline-block align-middle">
                                <app-file-chip *ngFor="let f of c.files" [file]="f"></app-file-chip>
                              </mat-chip-list>
                            </div>
                          </div>
                          <div class="row mt-4 mb-2">
                            <div class="col-auto pr-5">
                              <label class="main-label dkgray mb-2">Assigned to:</label>
                              <br />
                              <app-profile-thumbnail
                                *ngIf="c.assigned_user_id"
                                class="d-inline-block align-middle mr-2"
                                [userId]="c.assigned_user_id"
                                [width]="24"
                                [height]="24"
                              ></app-profile-thumbnail>
                              <h6
                                class="m-0 dkblue font-weight-semibold d-inline-block align-middle"
                                *ngIf="c.assigned_user_id"
                              >
                                {{ c.assigned_user_first_name }} {{ c.assigned_user_last_name }}
                              </h6>
                              <h6
                                class="m-0 dkblue font-weight-semibold d-inline-block align-middle"
                                *ngIf="!c.assigned_user_id"
                              >
                                {{ 'Not Assigned' }}
                              </h6>
                            </div>
                            <div class="col-auto">
                              <label class="main-label dkgray mb-2">New Due Date:</label>
                              <h6 class="dkblue font-weight-semibold pt-1" *ngIf="c.respond_by_date">
                                {{ c.respond_by_date | date: 'EEEE, MMMM d' }}
                              </h6>
                              <h6 class="dkblue font-weight-semibold pt-1" *ngIf="!c.respond_by_date">
                                {{ 'No Assigned Date' }}
                              </h6>
                            </div>
                            <div class="col">
                              <!-- <label class="main-label mb-1">Followers:</label>
                                  <br>
                                  <app-profile-thumbnail *ngFor="let u of c.to_users" class="d-inline-block align-middle" [userId]="u.id" [width]="24"
                                    [height]="24"></app-profile-thumbnail> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2 mt-2" *ngIf="canRespond(r.status_id)">
                <div class="col-auto text-center" style="width: 100px">
                  <!-- <span class="badge badge-info opacity5">COMMENT</span> -->
                </div>
                <div class="col mt-4">
                  <div class="row">
                    <div class="col-auto pr-0" style="width: 50px">
                      <app-profile-thumbnail
                        [userId]="currentUser.id"
                        [width]="34"
                        [height]="34"
                      ></app-profile-thumbnail>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <p class="m-0 dkblue font-weight-bold mb-2">
                            {{ currentUser.first_name }} {{ currentUser.last_name }}
                            <span *ngIf="currentUser.role_name || currentUser.company_name"
                              >({{ currentUser.role_name || currentUser.company_name }})</span
                            >
                          </p>
                        </div>
                      </div>
                      <div *ngIf="showResponseButton" class="row mt-4">
                        <div class="col">
                          <button (click)="showResponseForm()" mat-flat-button class="mr-3" color="accent">
                            Comment / Assign
                          </button>
                        </div>
                      </div>
                      <div *ngIf="showCommentForm" class="row">
                        <div class="col">
                          <div class="row mt-3">
                            <div class="col">
                              <app-editor [id]="r.id" type="comment" placeholder="Comment*"></app-editor>
                              <mat-error *ngIf="!r.validComment">A comment is required</mat-error>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-3">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Assign RFI to:</mat-label>
                                <mat-select [(ngModel)]="r.newComment.assignedUser">
                                  <mat-option *ngFor="let u of projectUsers" [value]="u"
                                    >{{ u.first_name }} {{ u.last_name }} ({{ roles[+u.roles[0].id].name }})
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-md-3">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Due Date:</mat-label>
                                <input
                                  matInput
                                  (click)="datePicker.open()"
                                  [(ngModel)]="r.newComment.respondByDate"
                                  [matDatepicker]="datePicker"
                                  autocomplete="off"
                                  placeholder="MM/DD/YYYY"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row align-items-center mb-4">
                            <div class="col">
                              <button
                                mat-stroked-button
                                class="ltblue mr-3"
                                matTooltip="Attach File"
                                matTooltipPosition="above"
                                [matTooltipShowDelay]="300"
                                (click)="openUploadModal('comment', r)"
                              >
                                Attach Files
                              </button>
                              &nbsp;
                              <mat-chip-list class="d-inline-block align-middle">
                                <app-file-chip
                                  *ngFor="let f of r.newComment.files"
                                  [file]="f"
                                  [removable]="true"
                                  (removeEvent)="removeCommentFile(f)"
                                ></app-file-chip>
                              </mat-chip-list>
                            </div>
                          </div>
                          <div class="row align-items-center mb-2">
                            <div class="col">
                              <button mat-flat-button color="success" (click)="createComment(r, false)">
                                Send Response
                              </button>
                              <button (click)="hideResponseForm()" mat-flat-button class="gray ml-3">Cancel</button>
                            </div>
                            <div class="col-auto text-right"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 55 }">
    <div class="form-print-pdf p-4" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer p-4 full-width">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="gray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="gray m-0">
                {{ reportDateTime | date: 'shortTime' }}<br />
                {{ reportDateTime | date: 'mediumDate' }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="gray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-3">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-9"></div>
        </div>
      </section>
      <div id="rfi_export">
        <section class="form-title">
          <div class="row">
            <div class="col">
              <h1 class="black font-weight-bold m-0">RFIs</h1>
            </div>
          </div>
        </section>
        <section class="form-highlights">
          <div class="row mt-3 mb-3 align-items-top">
            <div class="col-5">
              <label class="main-label mt-0">Project</label>
              <h6 class="m-0 font-weight-bold">{{ project?.code }} - {{ project?.title }}</h6>
            </div>
            <div class="col-7">
              <div class="row">
                <div class="col">
                  <label class="main-label mt-0">Location</label>
                  <h6 class="m-0">{{ project?.building_code }}-{{ project?.floor_code }}</h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Project Mgr</label>
                  <h6 class="m-0">
                    {{ project?.project_manager_first_name }}
                    {{ project?.project_manager_last_name }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Construction Mgr</label>
                  <h6 class="m-0">
                    {{ project?.workspace_manager_first_name }}
                    {{ project?.workspace_manager_last_name }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- NG FOR THIS SECTION ( BELOW ) FOR EACH EXPORTED RFI -->
        <!-- NG FOR THIS SECTION ( BELOW ) FOR EACH EXPORTED RFI -->
        <section *ngFor="let rfi of selectedRfis; let i = index">
          <div class="row m-0 border-bottom-heavy pb-4 pt-4">
            <div class="col-1 pl-0">
              <p class="m-0 mt-2 font-weight-bold">
                {{ rfi.local_index }}
              </p>
            </div>
            <div class="col-11 text-wrap">
              <div class="row m-0 pb-3">
                <div class="col p-0">
                  <label class="main-label m-0"> DESCRIPTION </label>
                  <p class="">
                    {{ rfi.description || '' }}
                  </p>
                </div>
              </div>
              <div class="row m-0 pb-3 border-bottom-gray">
                <div class="col p-0">
                  <label class="main-label m-0"> QUESTION </label>
                  <p class="" [innerHTML]="rfi.question || ''"></p>
                </div>
              </div>
              <div class="row m-0 mt-2 pb-3">
                <div class="col p-0 pr-4">
                  <label class="main-label m-0"> ANSWER </label>
                  <p
                    class="font-weight-bold"
                    [innerHTML]="(rfi.answer && rfi.answer.message) || 'Not yet answered'"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </kendo-pdf-export>
</div>
