import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appTextCollapseExpand]',
})
export class TextCollapseExpandDirective implements AfterViewInit, OnChanges {
  @Input() maxHeight: any;
  @Output() heightExceededEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() showMore = false;
  initialized = false;
  constructor(private container: ElementRef, private renderer: Renderer2, private _ngZone: NgZone) {}

  ngAfterViewInit() {
    const obs = new ResizeObserver(() => {
      this._ngZone.run(() => {
        if (this.container.nativeElement.scrollHeight >= this.maxHeight) {
          this.heightExceededEvent.emit(true);
        } else {
          this.heightExceededEvent.emit(false);
        }
      });
    });
    obs.observe(this.container.nativeElement);

    this.initialized = true;
    this.refreshContainer();
  }

  refreshContainer() {
    if (this.showMore === true) {
      this.renderer.setStyle(this.container.nativeElement, 'max-height', '');
    } else {
      this.renderer.setStyle(this.container.nativeElement, 'max-height', `${this.maxHeight}px`);
      this.renderer.setStyle(this.container.nativeElement, 'overflow', 'hidden');
    }
  }

  ngOnChanges() {
    if (this.initialized === true) {
      this.refreshContainer();
    }
  }
}
