<div id="topic-group" data-test-id="topic-group" class="topic-group">
  <h6 *ngIf="showTitle" class="dkblue font-weight-semibold mb-2">Group</h6>
  <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
    <input
      type="text"
      placeholder="Search Groups"
      aria-label="Topic Group"
      matInput
      [matAutocomplete]="topic_group_auto"
      [formControl]="topic_groups"
    />
    <mat-icon *ngIf="topic_groups?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-autocomplete #topic_group_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="topicGroupMapper">
      <mat-option
        *ngFor="let topicGroup of filteredTopicGroups | async"
        [value]="topicGroup"
        [disabled]="!topicGroup.isVisibleToDispatch"
      >
        <span class="d-flex flex-wrap align-items-center">
          <span class="mr-auto">{{ topicGroup?.name }}</span>
          <span
            class="badge badge-light gray br-5 font-weight-semibold text-uppercase text-truncate"
            *ngIf="!topicGroup.isVisibleToDispatch"
          >
            Unavailable
          </span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
