<div class="container-fluid app-container p-5" *ngIf="shownRequest">
  <div class="row align-items-center">
    <div class="col">
      <h3 id="title" class="dkblue font-weight-semibold" data-id-test="selected-request-type-id-headline">
        Request {{ shownRequest.id | padId }}
      </h3>
    </div>
    <div class="col-auto text-right">
      <button
        data-id-test="request-page-download-pdf-button"
        mat-button
        color="accent"
        (click)="downloadRequestFile(pdf, shownRequest.code)"
      >
        <!-- <mat-icon>
          save_alt
        </mat-icon> -->
        <span> Download PDF </span>
        <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="downloading"></i>
        <!-- &nbsp;
        <mat-icon>
          get_app
        </mat-icon> -->
      </button>
      <button
        data-id-test="request-page-previous-button"
        mat-stroked-button
        class="ml-4 bg-white"
        color="accent"
        [disabled]="!previousRequest"
        [routerLink]="previousRequest && previousRequest.id ? ['/requests/' + previousRequest.id] : null"
      >
        <span> Prev </span>
      </button>
      <button
        data-id-test="request-page-next-button"
        mat-stroked-button
        class="ml-4 bg-white"
        color="accent"
        [disabled]="!nextRequest"
        [routerLink]="nextRequest && nextRequest.id ? ['/requests/' + nextRequest.id] : null"
      >
        <span> Next </span>
      </button>
      <!--      Since there is currently only one option, disable the ability to transfer to dispatch if the request is already in dispatch-->
      <!--      Note to anyone editing the below: Move the ngif to the `Transfer Back to Dispatch` button if you add more options to this menu-->
      <button
        mat-icon-button
        [matMenuTriggerFor]="request_menu"
        class="ml-4"
        color="accent"
        *ngIf="shownRequest.workspace.id !== 2"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #request_menu="matMenu" xPosition="before">
        <button mat-menu-item class="dkblue" (click)="transferToDispatch()">
          <i class="la la-undo"></i>
          &nbsp; Transfer Back to Dispatch
        </button>
      </mat-menu>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-7 col-md-12" [ngClass]="{ 'm-auto': !isWorkspaceStaff }">
      <div class="m-portlet m-portlet--mobile p-0">
        <div class="m-portlet__body p-4">
          <div class="row mb-5">
            <div class="col">
              <div class="row">
                <div class="col-xl-4 col-lg-12">
                  <h2 data-id-test="selected-request-type-headline" class="dkblue">
                    <b>{{ shownRequest.request_type_name }} Request</b>
                  </h2>
                  <br />
                  <mat-chip-list data-id-test="converted-request-to-project-status-badge">
                    <mat-chip color="success" selected *ngIf="shownRequest.status_id === 3"
                      >CONVERTED TO PROJECT
                    </mat-chip>
                    <mat-chip color="success" selected *ngIf="shownRequest.status_id === 2">ACCEPTED</mat-chip>
                    <mat-chip color="warn" selected *ngIf="shownRequest.status_id === 4">DENIED</mat-chip>
                  </mat-chip-list>
                  <br /><br />
                </div>
                <div class="col-xl-8 col-lg-12">
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label">Submitted</label>
                      <h5 class="dkblue" *ngIf="shownRequest.building_name">
                        {{ shownRequest.created_datetime | date: 'MM/dd/yyyy' }}
                      </h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label">Est. Cost</label>
                      <h5 class="dkblue">{{ shownRequest.budget_description }}</h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.start_date">Start</label>
                      <h5 class="dkblue" *ngIf="shownRequest.start_date">
                        {{ shownRequest.start_date | date: 'MM/dd/yyyy' }}
                      </h5>
                      <label class="main-label" *ngIf="shownRequest.lease_term">Term</label>
                      <h5 class="dkblue" *ngIf="shownRequest.lease_term">
                        {{ shownRequest.lease_term }}
                      </h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.end_date">Move-In</label>
                      <h5 class="dkblue" *ngIf="shownRequest.end_date">
                        {{ shownRequest.end_date | date: 'MM/dd/yyyy' }}
                      </h5>
                    </div>
                    <br />
                  </div>
                  <br /><br />
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.building_name"
                        ><span *ngIf="shownRequest.request_type_id === 1">Preferred </span>Building</label
                      >
                      <h5 class="dkblue" *ngIf="shownRequest.building_name">
                        {{ shownRequest.building_name }}
                      </h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.floor_name"
                        ><span *ngIf="shownRequest.request_type_id === 1">Preferred </span>Floor</label
                      >
                      <h5 class="dkblue" *ngIf="shownRequest.floor_name">
                        {{ shownRequest.floor_name }}
                      </h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.suite_name">Suite</label>
                      <h5 class="dkblue" *ngIf="shownRequest.suite_name">
                        {{ shownRequest.suite_name }}
                      </h5>
                    </div>
                    <br />
                    <div class="col-lg-3 col-md-6">
                      <label class="main-label" *ngIf="shownRequest.department_name">Dept.</label>
                      <h5 class="dkblue" *ngIf="shownRequest.department_name">
                        {{ shownRequest.department_name }}
                      </h5>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <!-- FOR RENOVATIONS -->

            <div class="col-lg-12" *ngIf="shownRequest.request_type_id === 2">
              <label class="main-label">Project Summary</label>
              <h5 class="dkblue lh2">{{ shownRequest.summary || 'No summary provided' }}</h5>
            </div>

            <!-- FOR NEW SPACE -->

            <div class="col-lg-8 room-list" *ngIf="shownRequest.request_type_id === 1">
              <label class="main-label">New space needs</label>
              <br /><br />
              <div class="row" *ngFor="let r of rooms">
                <div class="col-12">
                  <h5 class="dkblue margin-0 lh2">
                    <b>{{ r.quantity }} </b> {{ r.room_type.name }}{{ r.quantity === 1 ? '' : 's' }}
                  </h5>
                  <h6 class="ltblue lh2" *ngIf="r.comments">
                    {{ r.comments }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12">
              <label class="main-label mb-3">Attachments</label>
              <br />
              <mat-chip-list class="d-inline-block align-middle">
                <mat-chip *ngFor="let f of files" class="dkblue" (click)="!f.loading ? downloadFile(f) : null">
                  <span class="pointer">
                    {{ f.name }}
                  </span>
                  &nbsp; &nbsp;
                  <mat-icon
                    matTooltip="Download Attachment"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="300"
                    matChipRemove
                    *ngIf="!f.loading"
                    class="mat-chip-icon"
                    (click)="!f.loading ? downloadFile(f) : null"
                    >get_app</mat-icon
                  >
                  <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="f.loading"></i>
                  <!--                  <mat-progress-spinner mode="indeterminate" diameter="15" *ngIf="f.loading" class="mat-chip-icon">-->
                  <!--                  </mat-progress-spinner>-->
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-auto pr-5">
              <label class="main-label mb-2">Requestor</label>
              <br />
              <button
                (click)="openUserProfilePreview(shownRequest.requester_id)"
                mat-button
                type="button"
                color="accent"
                class="mr-3 pl-2 pr-3"
              >
                <app-profile-thumbnail
                  class="d-inline-block align-middle mr-2"
                  [width]="28"
                  [height]="28"
                  [userId]="shownRequest.requester_id"
                  matTooltip="{{ shownRequest.requester_first_name }} {{ shownRequest.requester_last_name }}"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                ></app-profile-thumbnail>
                <span class="d-inline-block align-middle">
                  {{ shownRequest.requester_first_name }}
                  {{ shownRequest.requester_last_name }}
                </span>
              </button>
            </div>
            <div class="col">
              <label class="main-label mb-2">Contacts</label>
              <br />
              <button
                (click)="openUserProfilePreview(c.id)"
                mat-button
                type="button"
                class="mr-3 pl-2 pr-3"
                *ngFor="let c of contacts"
              >
                <app-profile-thumbnail
                  class="d-inline-block align-middle mr-2"
                  [width]="28"
                  [height]="28"
                  [userId]="c.id"
                  matTooltip="{{ c.first_name }} {{ c.last_name }}"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                ></app-profile-thumbnail>
                <span class="d-inline-block align-middle"> {{ c.first_name }} {{ c.last_name }}, </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isWorkspaceStaff" class="col-lg-5 col-md-12">
      <div class="m-portlet m-portlet--mobile p-0">
        <div class="m-portlet__body p-4">
          <form class="approval-process">
            <div class="row" *ngIf="isWorkspaceStaff">
              <div
                class="col"
                *ngIf="
                  shownRequest.status_id === 1 ||
                  ((shownRequest.status_id === 2 || shownRequest.status_id === 4) &&
                    (isWorkspaceAdmin || authService.isAppAdmin))
                "
              >
                <button
                  data-id-test="deny-converting-request-to-project-button"
                  mat-button
                  color="warn"
                  [disabled]="!(isWorkspaceAdmin || authService.isAppAdmin)"
                  class="full-width"
                  *ngIf="shownRequest.status_id === 1"
                  (click)="changeRequestStatus(4)"
                >
                  Deny
                </button>
                <button
                  data-id-test="undo-converted-request-to-project-button"
                  mat-button
                  class="full-width gray"
                  *ngIf="
                    (shownRequest.status_id === 2 || shownRequest.status_id === 4) &&
                    (isWorkspaceAdmin || authService.isAppAdmin)
                  "
                  (click)="changeRequestStatus(1)"
                >
                  Undo
                </button>
              </div>
              <div class="col">
                <button
                  data-id-test="accept-converting-request-to-project-button"
                  mat-flat-button
                  color="success"
                  [disabled]="!(isWorkspaceAdmin || authService.isAppAdmin)"
                  class="full-width"
                  *ngIf="shownRequest.status_id === 1"
                  (click)="changeRequestStatus(2)"
                >
                  Accept
                </button>
                <button
                  data-id-test="converted-request-to-project-status-button"
                  disabled
                  mat-stroked-button
                  [color]="[2, 3].indexOf(shownRequest.status_id) > -1 ? 'success' : 'warn'"
                  class="full-width"
                  *ngIf="[2, 3, 4].indexOf(shownRequest.status_id) > -1"
                >
                  {{
                    shownRequest.status_id === 2 ? 'Accepted' : shownRequest.status_id === 3 ? 'Converted' : 'DENIED'
                  }}
                </button>
              </div>
            </div>
            <div *ngIf="shownRequest.status_id === 2 || shownRequest.status_id === 3">
              <div class="row mt-4">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Project Manager</mat-label>
                    <mat-select
                      data-id-test="convert-request-to-project-select-project-manager"
                      matNativeControl
                      name="pmField"
                      [(ngModel)]="shownRequest.project_manager_id"
                      [disabled]="shownRequest.status_id === 3 || !(isWorkspaceAdmin || authService.isAppAdmin)"
                    >
                      <mat-option
                        data-id-test="convert-request-to-project-select-project-manager-option"
                        (click)="changeProjectManager(null)"
                      ></mat-option>
                      <mat-option
                        data-id-test="convert-request-to-project-select-project-manager-option"
                        *ngFor="let pm of projectManagers"
                        (click)="changeProjectManager(pm)"
                        [value]="pm.id"
                        >{{ pm.first_name }} {{ pm.last_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Construction Manager</mat-label>
                    <mat-select
                      data-id-test="convert-request-to-project-select-construction-manager"
                      matNativeControl
                      name="wmField"
                      [(ngModel)]="shownRequest.workspace_manager_id"
                      [disabled]="shownRequest.status_id === 3 || !(isWorkspaceAdmin || authService.isAppAdmin)"
                    >
                      <mat-option
                        data-id-test="convert-request-to-project-select-construction-manager-option"
                        (click)="changeWorkspaceManager(null)"
                      ></mat-option>
                      <mat-option
                        data-id-test="convert-request-to-project-select-construction-manager-option"
                        *ngFor="let wm of workspaceManagers"
                        (click)="changeWorkspaceManager(wm)"
                        [value]="wm.id"
                        >{{ wm.first_name }} {{ wm.last_name }}</mat-option
                      >
                    </mat-select>
                    <!-- <mat-error> Manager is required</mat-error> -->
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Architect</mat-label>
                    <mat-select
                      data-id-test="convert-request-to-project-select-architect"
                      matNativeControl
                      name="architectField"
                      [(ngModel)]="shownRequest.architect_id"
                      [disabled]="shownRequest.status_id === 3 || !(isWorkspaceAdmin || authService.isAppAdmin)"
                    >
                      <mat-option
                        data-id-test="convert-request-to-project-select-architect-option"
                        (click)="changeArchitect(null)"
                      ></mat-option>
                      <mat-option
                        data-id-test="convert-request-to-project-select-architect-option"
                        *ngFor="let a of architects"
                        (click)="changeArchitect(a)"
                        [value]="a.id"
                      >
                        {{ a.first_name }} {{ a.last_name }}</mat-option
                      >
                    </mat-select>
                    <!-- <mat-error>Architect is required</mat-error> -->
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12" *ngFor="let t of selectedTemplates; index as i">
                  <div class="template-item full-width dkblue">
                    <div class="row">
                      <div class="col-12">
                        {{ t.name }}
                        <!-- TODO fix the below ngif to not be based on the db id for base project template, in case that is changed for some reason -->
                        <!-- TODO this was used at the moment, as we will be switching to critical and extraneous template types, removing this issue -->
                        <i
                          class="la la-times red icon-click float-right"
                          *ngIf="
                            shownRequest.status_id !== 3 &&
                            (isWorkspaceAdmin || authService.isAppAdmin) &&
                            t.is_removable
                          "
                          (click)="removeTemplate(i)"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <mat-form-field appearance="outline" class="full-width" [hidden]="!showTemplateSelect">
                    <mat-label>Project Template</mat-label>
                    <mat-select matNativeControl #templateSelect>
                      <mat-option></mat-option>
                      <mat-option *ngFor="let t of availableTemplates" (click)="addTemplate(t)"
                        >{{ t.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    *ngIf="shownRequest.status_id !== 3 && (isWorkspaceAdmin || authService.isAppAdmin)"
                    [disabled]="!availableTemplates || availableTemplates.length <= 0"
                    mat-button
                    color="accent"
                    class="full-width"
                    type="button"
                    (click)="showTemplateSelect = true; templateSelect.open()"
                  >
                    <i class="la la-plus"></i>
                    Add Template *
                  </button>
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-lg-12">
                  <button
                    mat-flat-button
                    data-id-test="convert-request-to-project-button"
                    *ngIf="
                      shownRequest.status_id !== 3 &&
                      !loaders.convertRequest &&
                      selectedTemplates &&
                      selectedTemplates.length > 0
                    "
                    [ngClass]="{ 'mat-button-disabled-clickable': !validationErrors.valid }"
                    [disabled]="!(isWorkspaceAdmin || authService.isAppAdmin)"
                    [color]="validationErrors.valid ? 'success' : null"
                    class="full-width"
                    (click)="validationErrors.valid ? convertToProject() : (showValidationErrors = true)"
                    type="submit"
                  >
                    <span> Convert to Project </span>
                  </button>
                  <button
                    data-id-test="view-project-button"
                    mat-flat-button
                    *ngIf="shownRequest.status_id === 3 && !loaders.convertRequest"
                    [ngClass]="'mat-button-disabled-clickable'"
                    [disabled]="!(isWorkspaceAdmin || authService.isAppAdmin)"
                    [color]="'success'"
                    class="full-width"
                    (click)="goToProject()"
                    type="submit"
                  >
                    <span> View Project </span>
                  </button>
                  <button
                    mat-flat-button
                    *ngIf="loaders.convertRequest"
                    color="success"
                    class="full-width"
                    type="button"
                  >
                    <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break">
    <div *ngIf="shownRequest" class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header p-4">
        <div class="row align-items-top">
          <div class="col-3">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-4 mb-2">
                <label class="main-label"> REQUEST ID </label>
                <p>
                  {{ shownRequest.code }}
                </p>
              </div>
              <div class="col-4 mb-2">
                <label class="main-label"> SUBMITTED </label>
                <p>
                  {{ shownRequest.created_datetime | date: 'dd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label"> ESTIMATED COST </label>
                <p>
                  {{ shownRequest.budget_description }}
                </p>
              </div>
            </div>
            <div class="row">
              <div *ngIf="shownRequest.request_type_id != 2" class="col-4 mb-2">
                <label class="main-label"> TERM </label>
                <p>
                  {{ shownRequest.lease_term }}
                </p>
              </div>
              <div *ngIf="shownRequest.request_type_id === 2" class="col-4 mb-2">
                <label class="main-label"> Start </label>
                <p>
                  {{ shownRequest.start_date | date: 'MM/dd/yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label"> MOVE IN </label>
                <p>
                  {{ shownRequest.end_date | date: 'MM/dd/yyyy' }}
                </p>
              </div>
              <div *ngIf="shownRequest.request_type_name == 'Renovation'" class="col-4 mb-2">
                <label class="main-label"> LOCATION </label>
                <p>
                  Suite {{ shownRequest.suite_name }} <br />
                  {{ shownRequest.department_name }} <br />
                  {{ shownRequest.building_name }} <br />
                  Floor {{ shownRequest.floor_name }}
                </p>
              </div>
              <div *ngIf="shownRequest.request_type_name == 'New Space'" class="col-4 mb-2">
                <label class="main-label"> Preference </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section class="form-title ml-4 mr-4">
        <div class="row">
          <div class="col">
            <h1 class="black font-weight-bold m-0">{{ shownRequest.request_type_name }} Request</h1>
            <small>
              <span *ngIf="shownRequest.status_id === 3"> Converted to Project </span>
              <span *ngIf="shownRequest.status_id === 2"> Accepted </span>
              <span *ngIf="shownRequest.status_id === 4"> Denied </span>
            </small>
          </div>
        </div>
      </section>
      <section class="form-info-section ml-4 mr-4">
        <div class="row">
          <div class="col-12">
            <div *ngIf="shownRequest.request_type_name == 'Renovation'" class="row mb-4">
              <div class="col">
                <label class="main-label">PROJECT SUMMARY</label>
                <p class="m-0">
                  {{ shownRequest.summary }}
                </p>
              </div>
            </div>
            <div *ngIf="shownRequest.request_type_name == 'New Space'" class="row mb-4">
              <div class="col-12">
                <label class="main-label">NEW SPACE NEEDS</label>
              </div>
              <div class="col-12">
                <div *ngFor="let spaceNeed of []; index as ei">
                  <div class="col-3">
                    <label>
                      {{ spaceNeed }}
                    </label>
                  </div>
                  <div class="col-9">
                    <p>
                      {{ spaceNeed }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-tb">
              <div class="col-3">
                <label class="main-label"> Requestor </label>
                <p>
                  {{ shownRequest.requester_first_name + ' ' + shownRequest.requester_last_name }}
                  <br />
                  <small>
                    {{ shownRequest.requester_company_name }}
                  </small>
                </p>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <label class="main-label"> Contacts </label>
                  </div>
                </div>
                <div class="row">
                  <div *ngFor="let c of contacts" class="col-3">
                    <p>
                      {{ c.first_name }} {{ c.last_name }}
                      <br />
                      <small>
                        {{ c.company_name }}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-tb mt-4" *ngIf="filesHasImages()">
              <div class="col">
                <label class="main-label row mb-2">Attachments</label>
                <div class="row">
                  <span class="col-auto" *ngFor="let file of files">
                    <img *ngIf="file.base64" [src]="file.base64" [height]="100" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
