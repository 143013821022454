import { Pipe, PipeTransform } from '@angular/core';
import { ConstructionModule } from '../workspaces/construction/construction.module';

@Pipe({
  name: 'htmlEncodeDecode',
})
export class HtmlEncodeDecodePipe implements PipeTransform {
  transform(value: string, encode = false): string {
    let str = '';
    for (const character of value) {
      if (character.charCodeAt(0) > 126) {
        str += encode ? encodeURI(character) : decodeURI(character);
      } else {
        str += character;
      }
    }

    // The decoded string can still have either % as a string or an html entity which will contain %
    // Need to ignore % string but replace html entities with start with %, with the actual character
    if (!encode && str.includes('%')) {
      // will track were (start, end, name) of the html enties that are in the string
      const entities: { name: string; start: number; end: number }[] = [];

      // will use to indicate the name, start and end, when tracking, restting each time we reach the end
      let start: number = null;
      let end: number = null;
      let entityPrefix = '';

      // traversing the string, recording the name, start and end of the entites in the string
      for (let i = 0; i < str.length; i++) {
        if (
          str[i] === '%' &&
          !entityPrefix &&
          str[i + 1]?.trim()?.match(/^[0-9a-zA-Z]+$/) &&
          str[i + 2]?.trim()?.match(/^[0-9a-zA-Z]+$/)
        ) {
          // We only get in if we are at the start of an entity
          start = i;
          entityPrefix = `%${str[i + 1]}${str[i + 2]}`;
        } else if (
          str[i] === '%' &&
          entityPrefix &&
          str[i + 1]?.trim()?.match(/^[0-9a-zA-Z]+$/) &&
          str[i + 2]?.trim()?.match(/^[0-9a-zA-Z]+$/)
        ) {
          // We get in here if we have started tracking the entities
          // the entities follow a pattern %[CHARACTER][CHARACTER]
          entityPrefix += `%${str[i + 1]}${str[i + 2]}`;
          if (str[i + 3] !== '%') {
            // When the entity pattern breaks, we store the tracked information and reset the trackers
            entities.push({
              name: entityPrefix,
              start,
              end: i + 2,
            });
            entityPrefix = '';
            start = null;
            end = null;
          }
        }
      }

      // we have the locations of the entties in the string,
      // we use it to replaced with html entity with a decoded string
      let currStr = '';
      let currIndex = 0;
      for (const entity of entities) {
        currStr += str.substring(currIndex, entity.start) + decodeURI(entity.name);
        currIndex = entity.end + 1;
      }

      // replace the str, with the string containing decoded html entities
      // Also appending the rest of the string
      // substring is help us avoid out of bound index
      str = `${currStr}${str.substring(currIndex, str.length)}`;
    }

    return str;
  }
}
