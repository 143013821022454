import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { every, has, isEqual, isString, remove, round } from 'lodash';
import { AuthService, ModalService, ProductService } from 'src/app/services';
import {
  Company,
  CostCode,
  Module,
  Product,
  ProjectProduct,
  ProjectTenant,
  Quote,
  QuoteItem,
  SubCostCode,
} from 'src/app/types';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { CompanyVerificationStatus, QuoteItemStatus, WorkspaceType } from '../../enums';
import { convertDateToFiscalYear } from '../../utils';
import { TenantType } from '../../workspaces/construction/enums';

@Component({
  selector: 'app-project-product',
  templateUrl: './project-product.component.html',
  styleUrls: ['./project-product.component.scss'],
})
export class ProjectProductComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private productService: ProductService,
    private projectTenantService: ProjectTenantService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  productDisableSubscription;
  projectProductFormGroup: FormGroup = this.fb.group({
    product: ['', [Validators.required, ProductValidator()]],
    description: [''],
    quantity: [1],
    unit_price: [0],
    total_price: [0],
    is_taxable: [false],
    is_enabled: [true],
    is_in_stock: [false],
    sub_cost_code_budget_id: ['', [Validators.required]],
  });

  sub_cost_code_budget = new FormControl();

  get product() {
    return this.projectProductFormGroup.get('product');
  }

  get description() {
    return this.projectProductFormGroup.get('description');
  }

  get quantity() {
    return this.projectProductFormGroup.get('quantity');
  }

  get unit_price() {
    return this.projectProductFormGroup.get('unit_price');
  }

  get total_price() {
    return this.projectProductFormGroup.get('total_price');
  }

  get is_taxable() {
    return this.projectProductFormGroup.get('is_taxable');
  }

  get is_enabled() {
    return this.projectProductFormGroup.get('is_enabled');
  }

  get is_in_stock() {
    return this.projectProductFormGroup.get('is_in_stock');
  }

  get sub_cost_code_budget_id() {
    return this.projectProductFormGroup.get('sub_cost_code_budget_id');
  }

  @Input() public products: Product[];
  @Input() public module: Module;
  @Input() public productIndex: number;
  @Input() public projectProduct: ProjectProduct;
  @Input() public tenant: ProjectTenant;
  @Input() public selectedQuotes: Quote[];
  @Input() public costCodes: CostCode[] = [];
  @Output() public projectProductCreated = new EventEmitter<ProjectProduct>();
  @Output() public projectProductUpdated = new EventEmitter<ProjectProduct>();
  @Output() public projectProductDeleted = new EventEmitter<ProjectProduct>();
  @Output() public productListChanged = new EventEmitter();
  @Output() public selectedQuoteItemChanged = new EventEmitter<{
    quoteItem: QuoteItem;
    projectProduct: ProjectProduct;
    tenant: ProjectTenant;
  }>();
  public filteredProducts: Product[] = [];
  public filteredCostCodes: CostCode[] = [];
  private fixedProperties = [];
  private _componentLoaded = false;
  private _savingProjectProduct = false;
  private _waitingForSaveProjectProduct = false;
  public fiscalYear: number;

  public selectedCostCode: CostCode;

  private productFields = ['id', 'name', 'description', 'unit_price'];
  private projectProductFields = [
    'id',
    'name',
    'description',
    'quantity',
    'unit_price',
    'total_price',
    'fixed_properties',
    'is_taxable',
    'is_enabled',
    'is_in_stock',
    'sub_cost_code_budget_id',
  ];
  CompanyVerificationStatus = CompanyVerificationStatus;

  async ngOnInit() {
    this.filteredProducts = this.products;
    this.filteredCostCodes = this.costCodes;
    this.fiscalYear = convertDateToFiscalYear();
    if (this.projectProduct?.id) {
      // ensure all fields are present and API lookup with warning if not
      const requiredProjectProductFields = [
        ...this.projectProductFields,
        ...this.productFields.map((f) => `product.${f}`),
      ];
      const missingFields = [];
      const hasAllFields = every(requiredProjectProductFields, (field) => {
        if (field.includes('.') && !has(this.projectProduct, field.substring(0, field.indexOf('.')))) {
          return true;
        } else if (has(this.projectProduct, field)) {
          return true;
        } else {
          missingFields.push(field);
          return false;
        }
      });
      if (!hasAllFields) {
        console.warn(
          `ProjectProductComponent is missing the required fields: ${missingFields.join(
            ', '
          )}! Getting them from the API.`
        );
        await this.getProjectProduct(this.projectProduct?.id);
      }
      const projectProduct = {
        description: this.projectProduct.description,
        quantity: this.projectProduct.quantity,
        unit_price: this.roundDecimal(this.projectProduct.unit_price, 2),
        total_price: this.roundDecimal(this.projectProduct.total_price, 2),
        is_taxable: this.projectProduct.is_taxable,
        is_enabled: this.projectProduct.is_enabled,
        is_in_stock: this.projectProduct.is_in_stock,
        product: this.projectProduct.product ?? this.projectProduct.name ?? null,
        sub_cost_code_budget_id: this.projectProduct.sub_cost_code_budget_id || null,
      };

      if (this.projectProduct.sub_cost_code_budget_id) {
        this.selectedCostCode = this.costCodes.find((c) =>
          c.sub_cost_codes.find((s) => s.sub_cost_code_budget?.id === this.projectProduct.sub_cost_code_budget_id)
        );
        this.filteredCostCodes.find((cc) => {
          const scc = cc.sub_cost_codes.find(
            (scc) => scc.sub_cost_code_budget?.id == this.projectProduct.sub_cost_code_budget_id
          );
          if (scc) this.sub_cost_code_budget.setValue(scc);
        });
      }

      const fixedProperties = this.projectProduct.fixed_properties
        ? JSON.parse(this.projectProduct.fixed_properties)
        : [];
      this.fixedProperties = fixedProperties && fixedProperties.length >= 0 ? fixedProperties : [];
      this.projectProductFormGroup.setValue(projectProduct);
      // Leaving this here in case we add files back
      // this.productFiles = await this.fileService
      //   .getFilesByParentId(ResourceType.ProjectProduct, this.projectProduct?.id)
      //   .toPromise();
      // this.originalProductFiles = cloneDeep(this.productFiles);
      if (this.cantMakeChanges) {
        this.projectProductFormGroup.disable();
        if (!this.authService.isAppAdmin && !this.authService.isFinanceManager) this.sub_cost_code_budget.disable();
      }
    }
    this._componentLoaded = true;

    this.productDisableSubscription = this.productService.disableProductEvent.subscribe((selectedTenantId) => {
      if (this.tenant?.id === selectedTenantId && this.cantMakeChanges) {
        this.projectProductFormGroup.disable();
        if (!this.authService.isAppAdmin && !this.authService.isFinanceManager) this.sub_cost_code_budget.disable();
      } else if (this.tenant?.id === selectedTenantId && !this.cantMakeChanges) {
        this.projectProductFormGroup.enable();
        this.sub_cost_code_budget.enable();
      }
    });
  }

  ngOnDestroy() {
    if (this.productDisableSubscription) {
      this.productDisableSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.filterProducts();

    if (this.cantMakeChanges) {
      this.projectProductFormGroup.disable();
      if (!this.authService.isAppAdmin && !this.authService.isFinanceManager) this.sub_cost_code_budget.disable();
    } else if (!this.cantMakeChanges) {
      this.projectProductFormGroup.enable();
      this.sub_cost_code_budget.enable();
    }
  }

  get cantMakeChanges() {
    return (
      this.tenant?.budget_approval_process?.isFinalized ||
      this.tenant?.budget_tenant_approval_task_id ||
      (this.tenant.budget_approval_process._staffReview && this.tenant?.budget_approval_task_id) ||
      (this.tenant?.type_id === TenantType.Internal &&
        this.selectedQuotes.find((quote) => quote.arf_approval_task_id && !quote.task?.is_locked))
    );
  }

  get QuoteItemStatus() {
    return QuoteItemStatus;
  }

  async getProjectProduct(projectProductId: number) {
    this.projectProduct = await this.productService
      .getProjectProductById(projectProductId, [
        ...this.projectProductFields,
        `product{${this.productFields.join(',')}}`,
      ])
      .toPromise();
  }

  productValueMapper(product) {
    if (product?.id) {
      return product.name;
    } else if (isString(product)) {
      return product;
    } else {
      return null;
    }
  }

  subCostCodeValueMapper(scc) {
    return scc ? `${scc?.code} - ${scc?.label} - FY${scc?.fiscal_year}` : '';
  }

  openNewProductDialog() {
    this.modalService.openProductDialog().subscribe((createdProduct) => {
      if (createdProduct) {
        this.product.setValue(createdProduct);
        this.productListChanged.emit();
      }
    });
  }

  productChanged() {
    this.filterProducts();
    if (this.product?.value?.id) {
      if (this.product.value.description) {
        this.description.setValue(this.product.value.description);
      }

      if (this.product.value?.unit_price) {
        this.unit_price.setValue(this.roundDecimal(this.product.value.unit_price, 2));
        this.calculateValues('unit_price');
      } else if (!this.unit_price.value) {
        this.unit_price.setValue(this.roundDecimal(0, 2));
        this.calculateValues('unit_price');
      }
      this.saveProjectProduct();
    }
  }

  filterProducts() {
    if (this.product?.value?.id) {
      this.filteredProducts = [this.product.value];
    } else if (this.product?.value) {
      this.filteredProducts = this.products.filter(
        (p) => p.name && p.name.toLowerCase().includes(this.product.value.toLowerCase())
      );
    } else if (this.filteredProducts?.length !== this.products?.length) {
      this.filteredProducts = this.products;
    }
  }

  selectQuoteItem(quoteItem, projectProduct, tenant) {
    if (quoteItem?.quote?.company && !this.isCompanyAllowed(quoteItem?.quote?.company)) {
      return;
    }
    if (
      (projectProduct?.selected_quote_item_id || quoteItem?.id) &&
      projectProduct?.selected_quote_item_id !== quoteItem?.id
    ) {
      this.selectedQuoteItemChanged.emit({ quoteItem, projectProduct, tenant });
    }
  }

  /** Converts a number or string into an incomplete decimal format. This is generally used for USD inputs.
   *
   * @param value The value to convert to USD
   */
  formatDecimal(value, digits) {
    // Remove characters that aren't numbers or decimals
    const clean = value.toString().replace(/[^-?0-9.]+/g, '');
    // Remove all decimals after the first one
    const decPos = clean.indexOf('.');
    const dec = decPos >= 0 ? `${clean.substring(0, decPos)}.${clean.substring(decPos + 1).replace('.', '')}` : clean;
    // remove all characters after the hundredths place
    const res = decPos >= 0 && decPos < dec.length - digits - 1 ? dec.substring(0, decPos + digits + 1) : dec;
    return res;
  }

  roundDecimal(value, digits: number) {
    return round(value, digits).toFixed(digits);
  }

  quantityChanged() {
    this.quantity.setValue(this.formatDecimal(this.quantity.value, 2));
    this.calculateValues('quantity');
  }

  unitPriceChanged() {
    this.unit_price.setValue(this.formatDecimal(this.unit_price.value, 2));
    this.calculateValues('unit_price');
  }

  totalChanged() {
    this.total_price.setValue(this.formatDecimal(this.total_price.value, 2));
    this.calculateValues('total_price');
  }

  calculateValues(propertyChanged) {
    if (this.fixedProperties.indexOf(propertyChanged) === -1) {
      this.fixedProperties.push(propertyChanged);
    }
    const properties = [
      { name: 'quantity', value: this.quantity.value },
      { name: 'unit_price', value: this.unit_price.value },
      { name: 'total_price', value: this.total_price.value },
    ];
    const valueCount = properties.filter((p) => !!p.value).length;
    let propertyToCalculate;
    // if zero or one of them is filled, do not calculate
    // if two of them are filled, calculate the third
    if (propertyChanged === 'unit_price' && +this.unit_price.value === 0) {
      propertyToCalculate = 'total_price';
    } else if (valueCount === 2) {
      propertyToCalculate = properties.find((p) => !p.value)?.name;
    } else if (valueCount === 3) {
      if (this.fixedProperties.length === 3) {
        // if all three are filled and fixedProperties is full, calculate the least recent one
        propertyToCalculate =
          propertyChanged === this.fixedProperties[0] ? this.fixedProperties[1] : this.fixedProperties[0];
      } else if (this.fixedProperties.length === 2) {
        // if all three are filled and fixedProperties is missing an entry, calculate the missing one
        propertyToCalculate = properties.find((p) => this.fixedProperties.indexOf(p.name) === -1)?.name;
      } else {
        // else (meaning all three are filled and only the propertyChanged is fixed),
        // calculate the total_price unless it was the propertyChanged in which case, fall back to quantity
        propertyToCalculate = propertyChanged === 'total_price' ? 'quantity' : 'total_price';
      }
    }
    if (this.fixedProperties.length === 3) {
      if (propertyToCalculate) {
        // if fixedProperties is full and propertyToCalculate exists, remove propertyToCalculate from fixedProperties
        remove(this.fixedProperties, (p) => propertyToCalculate === p);
      } else {
        // if fixedProperties is full and propertyToCalculate doesn't exist, remove earliest entry from fixedProperties
        this.fixedProperties.shift();
      }
    }
    if (propertyToCalculate === 'quantity') {
      if (propertyChanged === 'unit_price') {
        this.calculateTotalPrice();
      } else {
        this.calculateUnitPrice();
      }
    } else if (propertyToCalculate === 'unit_price') {
      this.calculateUnitPrice();
    } else if (propertyToCalculate === 'total_price') {
      this.calculateTotalPrice();
    }
  }

  calculateQuantity() {
    let quantity;
    if (+this.unit_price.value === 0) {
      quantity = 0;
    } else {
      quantity = +this.total_price.value / +this.unit_price.value;
    }
    this.quantity.setValue(this.roundDecimal(quantity, 2));
  }

  calculateUnitPrice() {
    let unitPrice;
    if (+this.quantity.value === 0) {
      unitPrice = 0;
    } else {
      unitPrice = +this.total_price.value / +this.quantity.value;
    }
    this.unit_price.setValue(this.roundDecimal(unitPrice, 2));
  }

  calculateTotalPrice() {
    this.total_price.setValue(this.roundDecimal(+this.quantity.value * +this.unit_price.value, 2));
  }

  quantityBlur() {
    this.quantity.setValue(+this.quantity.value);
    this.saveProjectProduct();
  }

  unitPriceBlur() {
    this.unit_price.setValue(this.roundDecimal(this.unit_price.value, 2));
    this.saveProjectProduct();
  }

  totalBlur() {
    this.total_price.setValue(this.roundDecimal(this.total_price.value, 2));
    this.saveProjectProduct();
  }

  filterCostCodes(input): void {
    const sub_cost_code = input.target.value;
    if (sub_cost_code) {
      this.filteredCostCodes = this.costCodes
        .map((costCode) => ({
          ...costCode,
          sub_cost_codes: costCode?.sub_cost_codes?.filter(
            (subCostCode) =>
              costCode?.code?.toLowerCase()?.includes(sub_cost_code?.toString()?.trim()?.toLowerCase()) ||
              costCode?.label?.toLowerCase()?.includes(sub_cost_code?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.code?.toLowerCase()?.includes(sub_cost_code?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.label?.toLowerCase()?.includes(sub_cost_code?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.description?.toLowerCase()?.includes(sub_cost_code?.toString()?.trim()?.toLowerCase())
          ),
        }))
        ?.filter((costCodes) => costCodes?.sub_cost_codes?.length);
    } else {
      this.filteredCostCodes = this.costCodes;
      this.projectProduct.sub_cost_code_budget = null;
      this.sub_cost_code_budget_id.setValue(null);
      this.selectedCostCode = null;
      this.saveProjectProduct();
    }
  }

  setCostCodeId(subCostCode: SubCostCode): void {
    const subCostCodeBudget = subCostCode.sub_cost_code_budget;
    this.projectProduct.sub_cost_code_budget = subCostCodeBudget;
    this.selectedCostCode = subCostCode.cost_code;

    this.sub_cost_code_budget_id.setValue(subCostCodeBudget.id);
    this.saveProjectProduct();
  }

  async saveProjectProduct() {
    if (this.cantMakeChanges && !this.authService.isAppAdmin && !this.authService.isFinanceManager) {
      return;
    }

    this._waitingForSaveProjectProduct = this._savingProjectProduct;
    this._savingProjectProduct = true;
    if (!this._waitingForSaveProjectProduct) {
      try {
        this.projectProductFormGroup.markAllAsTouched();
        let productSaved = false;
        if (this._componentLoaded) {
          const projectProduct: ProjectProduct = {
            description: this.description.value,
            quantity: this.quantity.value,
            unit_price: +this.unit_price.value,
            total_price: +this.total_price.value,
            fixed_properties: JSON.stringify(this.fixedProperties),
            is_taxable: this.is_taxable.value ? 1 : 0,
            is_enabled: this.is_enabled.value ? 1 : 0,
            is_in_stock: this.is_in_stock.value ? 1 : 0,
            product_id: this.product?.value?.id ?? null,
            name: !this.product?.value?.id ? this.product?.value ?? null : null,
            sub_cost_code_budget_id: this.sub_cost_code_budget_id.value || null,
          };

          if (this.is_in_stock.value) {
            projectProduct.selected_quote_item_id = null;
          }

          if (this.projectProduct?.id) {
            const existingProjectProduct: ProjectProduct = {
              description: this.projectProduct.description,
              quantity: this.projectProduct.quantity,
              unit_price: this.projectProduct.unit_price,
              total_price: this.projectProduct.total_price,
              fixed_properties: this.projectProduct.fixed_properties,
              is_taxable: this.projectProduct.is_taxable,
              is_enabled: this.projectProduct.is_enabled,
              is_in_stock: this.projectProduct.is_in_stock,
              product_id: this.projectProduct.product_id,
              name: this.projectProduct.name,
              sub_cost_code_budget_id: this.projectProduct.sub_cost_code_budget_id,
            };

            if (this.is_in_stock.value) {
              existingProjectProduct.selected_quote_item_id = null;
            }

            if (!isEqual(projectProduct, existingProjectProduct)) {
              const updatedProject = await this.productService
                .updateProjectProduct(this.projectProduct?.id, projectProduct, [
                  ...this.projectProductFields,
                  `product{${this.productFields.join(',')}}`,
                ])
                .toPromise();

              if (existingProjectProduct.quantity !== projectProduct.quantity) {
                for (const quoteItem of this.projectProduct.quote_items) {
                  if (quoteItem?.id) {
                    const quoteItemTotal = +this.roundDecimal(+projectProduct.quantity * +quoteItem.unit_price, 2);
                    await this.productService
                      .updateQuoteItem(quoteItem.id, {
                        quantity: projectProduct.quantity,
                        total_price: quoteItemTotal,
                      })
                      .toPromise();

                    quoteItem.total_price = quoteItemTotal;
                    if (quoteItem.id === this.projectProduct.selected_quote_item_id) {
                      this.projectProduct.selected_quote_item = quoteItem;
                    }
                  }
                }
              }

              if (this.is_in_stock.value) {
                updatedProject.selected_quote_item = this.projectProduct.quote_items?.find((qi) => qi.is_budget);
              }

              this.projectProduct = { ...this.projectProduct, ...updatedProject };
              this.projectProductUpdated.emit(this.projectProduct);
              productSaved = true;
            }
          } else if (this.tenant?.id) {
            projectProduct.tenant_id = this.tenant.id;
            if (!this.tenant.project_id) {
              console.warn(`ProjectProductComponent is missing required tenant fields! Getting them from the API.`);
              this.tenant = await this.projectTenantService
                .getTenantById(this.tenant.id, ['id', 'project_id'])
                .toPromise();
            }
            projectProduct.project_id = this.tenant.project_id;
            projectProduct.rank = this.projectProduct.rank; // since it passed from parent, we add it on creation
            const updatedProjectProduct = await this.productService
              .createProjectProduct(projectProduct, [
                ...this.projectProductFields,
                `product{${this.productFields.join(',')}}`,
              ])
              .toPromise();

            this.projectProduct = { ...this.projectProduct, ...updatedProjectProduct };
            this.projectProductCreated.emit(this.projectProduct);
            productSaved = true;
          }
          if (productSaved) {
            await this.snackbar.open('Your changes have been saved.', '', {
              duration: 1000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            });
          }
          // Leaving this here in case we add files back
          // await this.updateProductFiles(projectProductId);
          // createdProjectProduct.files = this.productFiles;
        }
      } catch (e) {
        this._savingProjectProduct = false;
        throw e;
      }
      this._savingProjectProduct = false;
      if (this._waitingForSaveProjectProduct) {
        await this.saveProjectProduct();
      }
    }
  }

  public async deleteProjectProduct() {
    // TODO: check to see if deletion is allowed
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Product',
        descriptionText:
          'Removing this product from the list will also remove it from any corresponding vendor bids and will affect them accordingly. If this the only listed product, any corresponding vendor bids will also be deleted. Do you want to continue?',
        confirmationButtonText: 'Remove',
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          if (this.projectProduct.id) {
            await this.productService.deleteProjectProduct(this.projectProduct.id).toPromise();
          }
          this.projectProductDeleted.emit(this.projectProduct);
        }
      });
  }

  isCompanyAllowed(company: Company) {
    if (!company) {
      return;
    }
    return (
      (company.verification_status === CompanyVerificationStatus['UHAT Only'] &&
        (this.module?.workspace_type_id === WorkspaceType.Uhat ||
          this.module?.workspace_type_id === WorkspaceType.UhatAdmin)) ||
      company.verification_status === CompanyVerificationStatus['1CALL & UHAT']
    );
  }

  // Leaving these functions here in case we add files back

  // openUploadModal() {
  //   this.dialog
  //     .open(FileAttachmentDialogComponent, {
  //       data: {
  //         parentResourceType: ResourceType.Project,
  //         parentResourceId: this.projectId,
  //         allowComment: false,
  //         preSelectedTags: [{ id: 87 }],
  //       },
  //       disableClose: true,
  //     })
  //     .afterClosed()
  //     .subscribe(async (resultData) => {
  //       if (resultData) {
  //         this.productFiles = this.productFiles.concat(resultData);
  //       }
  //     });
  // }

  // removeFile(file) {
  //   this.productFiles.splice(this.productFiles.indexOf(file), 1);
  // }

  // async updateProductFiles(productId: number) {
  //   const filesToAdd = [];
  //   const filesToRemove = [];

  //   this.productFiles.forEach((file) => {
  //     if (!this.originalProductFiles.map((f) => +f.file_id).includes(+file.file_id)) {
  //       filesToAdd.push(file);
  //     }
  //   });

  //   this.originalProductFiles.forEach((file) => {
  //     if (!this.productFiles.map((f) => +f.file_id).includes(+file.file_id)) {
  //       filesToRemove.push(file);
  //     }
  //   });

  //   for (const file of filesToAdd) {
  //     await this.fileService
  //       .linkFile(file.file_id, productId, ResourceType.ProjectProduct)
  //       .toPromise();
  //   }

  //   for (const file of filesToRemove) {
  //     await this.fileService.unlinkFile(file.id).toPromise();
  //   }
  // }
}

/** Product must contain an id */
export function ProductValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalid = !control.value?.id && !isString(control.value);
    return invalid ? { invalidProduct: { value: control.value } } : null;
  };
}
