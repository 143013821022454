import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorConfig } from 'src/app/types';
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('ckEditor') ckEditor;
  @Input() disabled = false;
  @Input() auto_focus = false;
  @Input() has_max_length: boolean | string = false;
  @Input() heading = '';
  @Input() max_length = 255;
  @Input() max_height = 400;
  @Input() min_height = 300;
  @Input() class_height: 'default' | 'small' | 'small-fixed' | 'medium' | 'large' = 'default';
  @Input() id: any = null;
  @Input() placeholder = 'Start typing...';
  @Input() required: boolean | string = false;
  @Input() required_content = 'Content is required';
  @Input() toolbar: string[] | string = ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|'];
  @Input() type = 'textarea';
  // These two fields are used so that objects can be directly changed by the editor.
  @Input() item: any;
  @Input() field: string;
  @Input() initialValue: string;
  @Input() ValueChanges: string = null;
  @Output() itemUpdated = new EventEmitter<string>();

  public content = new FormControl('');
  public Editor = ClassicEditor;

  constructor() {}

  ngOnInit(): void {
    this._updateValidation();

    if ((this.item && this.field) || this.initialValue) {
      this.content.setValue((this.item && this.item[this.field]) || this.initialValue, { emitEvent: false });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.content.disable();
      this.required = false;
    } else {
      this.content.enable();
    }

    if ((this.ValueChanges || this.ValueChanges == '') && this.ValueChanges !== this.content.value) {
      this.content.setValue(this.ValueChanges, { emitEvent: false });
    }
  }

  ngAfterViewInit(): void {
    this.content.valueChanges.subscribe((value) => {
      if (this.item && this.field) {
        this.item[this.field] = value;
      }

      if (this.initialValue !== value) {
        this.itemUpdated.emit(value);
      }
    });
  }

  get config(): EditorConfig {
    return {
      placeholder: this.placeholder,
      toolbar: this.formattedToolbar,
      typing: {
        transformations: {
          include: [
            // Use only the 'quotes' and 'typography' groups.
            // 'quotes',
            // 'typography',
            // Plus some custom transformation.
            // { from: 'CKE', to: 'CKEditor' },
          ],
        },
      },
    };
  }

  get content_counter(): string {
    return `${this.content?.value?.length || 0} / ${this.max_length}`;
  }

  get formattedToolbar() {
    if (Array.isArray(this.toolbar)) {
      // array of options
      return this.toolbar;
    } else if (typeof this.toolbar === 'string' && this.toolbar?.includes(',')) {
      // multiple string option
      return this.toolbar.split(',');
    } else if (typeof this.toolbar === 'string' && !this.toolbar?.includes(',')) {
      // single option
      return [this.toolbar];
    }
    return [];
  }

  get invalid(): boolean {
    return this.required && !this.content.valid && this.content.touched;
  }

  get overflow(): boolean {
    return this.content?.value?.length > this.max_length;
  }

  // conditions to display the counter
  get showCounter(): boolean {
    // show counter if you are getting close to the max
    if (this.has_max_length && (this.content?.value?.length ?? 0) / this.max_length > 0.8) {
      return true;
    }
    return false;
  }

  private _updateValidation() {
    // for some reason, the passed in boolean is treated as a string, so, work around
    if (
      (this.has_max_length || this.has_max_length === 'true') &&
      (this.required === true || this.required === 'true')
    ) {
      this.content.setValidators([Validators.required, Validators.maxLength(this.max_length)]);
    } else if (this.has_max_length || this.has_max_length === 'true') {
      this.content.setValidators([Validators.maxLength(this.max_length)]);
    } else if (this.required === true || this.required === 'true') {
      this.content.setValidators([Validators.required]);
    }

    this.content.updateValueAndValidity();
  }

  onEditorReady($event: any) {
    if (this.auto_focus) {
      $event.editing.view.focus();
    }
  }

  public editorFocus() {
    setTimeout(() => {
      this.ckEditor.elementRef.nativeElement.children[0].focus();
    });
  }
}
