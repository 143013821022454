import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rfiFilter',
})
export class RFIFilterPipe implements PipeTransform {
  transform(rfis, hideClosed: boolean): any[] {
    if (rfis) {
      if (hideClosed) {
        return rfis.filter((rfi) => rfi.status_id !== 2);
      } else {
        return rfis;
      }
    }
  }
}
