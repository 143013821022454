import { ProjectStatus } from 'src/app/enums';
/**
 * Filter Object Used As Argument For ProjectListFilterPipe
 */
export class ProjectListFilter {
  public statusId: number = ProjectStatus.ACTIVE;
  public searchString = '';
  public userId: number;
  public fiveYearCapitalPlan = false;
  public projectManagers: string[] = [];
  public architects: string[] = [];
  public requesters: string[] = [];
  public buildings: string[] = [];
  public floors: string[] = [];
  public topics: string[] = [];
  public tags: number[] = [];
  public workspaceIds: number[] = [];
  public subStatuses: string[] = [];
}
