<div
  data-test-id="file-thumbnail"
  id="file-thumbnail"
  class="file-thumbnail d-flex align-items-center"
  [style.width.px]="width"
  [style.height.px]="height"
>
  <!-- <span *ngIf="!isSupportedExtension" class="thumbnail-image thumbnail-none d-inline-block align-top"> -->
  <!--  -->
  <mat-icon class="m-0 gray align-self-center" *ngIf="!isSupportedExtension">
    file_present
    <!-- <i
      class="la"
      [ngClass]="{
        'la-file': isCsv || isUnKnownFileType,
        'la-file-text-o': isTxt,
        'la-file-archive-o': isZip,
        'la-file-image-o': isUnSupportedImage
      }"
    ></i> -->
  </mat-icon>
  <img
    *ngIf="isLoaded && isSupportedExtension"
    class="thumbnail-image"
    [ngClass]="{ 'no-border': noBorder, 'border-active': borderActive }"
    [src]="thumbnailImage$ | async"
  />
  <mat-icon
    *ngIf="!isLoaded && isSupportedExtension"
    class="gray"
    [ngClass]="{ 'no-border': noBorder }"
    [src]="
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAdiAAAHYgE4epnbAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAFFQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALIimcAAAABp0Uk5TAAIEBwkLEDhBSFhoeIiPmKi4wMXH0Obw8/iv9z3FAAAAmElEQVQ4jbXTwQ6CMBCE4QEKuNBBREXp+z+oB5XslmqipP/5azqHFgAYohaBiUNjC3crSHsAobvJd9CIEQkAI1LAiCTQggRQepL0JQCEgSTPy+ET4LMLNUjEH0ErqnYL3HRVTe6PK3YDN+sXMSc21PpF1Fk2jCfVmNggnUpybCh6r+qLDah4VLHKsGEfiD/vq2EF8fd/RwAP6ckULD9ggPQAAAAASUVORK5CYII='
    "
    >file_present</mat-icon
  >
</div>
