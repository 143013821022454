<div class="row">
  <div class="col-2">
    <br />
    <div *ngFor="let item of items">
      <mat-checkbox [disabled]="disabled" [checked]="item.checked" (change)="checkboxUpdated($event.checked, item)">{{
        item.label
      }}</mat-checkbox
      ><br />
    </div>
  </div>
  <div class="col-10">
    <br />
    <mat-form-field *ngIf="requireNotes" class="full-width" appearance="outline">
      <mat-label>
        {{ notesPlaceholder || 'Further Notes' }}
      </mat-label>
      <textarea
        [disabled]="disabled"
        matInput
        placeholder="{{ notesPlaceholder || 'Further Notes' }}"
        rows="{{ items.length - 1 }}"
        [(ngModel)]="noteField"
        (blur)="noteFieldChange.emit(noteField)"
      ></textarea>
    </mat-form-field>
  </div>
</div>
