<div id="file-chip-list" data-test-id="file-chip-list" class="file-chip-list">
  <mat-chip-list class="pt-2 pb-2" *ngIf="files?.length > 0">
    <app-file-chip *ngFor="let file of files" [file]="file"></app-file-chip>
  </mat-chip-list>
  <p
    class="accent pointer hover-u py-1 d-flex align-items-center"
    [disabled]="downloading"
    *ngIf="files?.length > 1"
    (click)="downloadAll()"
  >
    <span>
      {{ downloading ? 'Downloading' : 'Download All' }}
    </span>
    <mat-spinner *ngIf="downloading" class="ml-2" color="accent" [diameter]="16"></mat-spinner>
  </p>
</div>
