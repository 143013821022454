import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';

import * as moment from 'moment';
import { cloneDeep, pullAllBy } from 'lodash';

import { saveAs } from 'file-saver';

import {
  AuthService,
  FileService,
  ProjectService,
  ProjectTemplateService,
  RequestService,
  UserService,
} from 'src/app/services';

import { UserProfilePreviewComponent } from 'src/app/components';

import { ApplicationRole, ResourceType } from 'src/app/enums';

import { APIFilter, ProjectTemplate, Request } from 'src/app/types';

@Component({
  selector: 'app-project-request-details-modal',
  templateUrl: './project-request-details-modal.component.html',
  styleUrls: ['./project-request-details-modal.component.scss'],
})
export class ProjectRequestDetailsModalComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private requestService: RequestService,
    private userService: UserService,
    private fileService: FileService,
    private route: ActivatedRoute,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ProjectRequestDetailsModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  shownRequest: Request;
  previousRequest: Request;
  nextRequest: Request;
  rooms = [];
  contacts = [];
  projectTemplates: ProjectTemplate[];
  selectedTemplates: ProjectTemplate[];
  availableTemplates: ProjectTemplate[];
  architects;
  workspaceManagers;
  projectManagers;
  validationErrors: any = {};
  files;
  downloading = false;
  loaders = {
    convertRequest: false,
  };

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.refresh();
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  async refresh() {
    const request: Request = await this.requestService
      .getRequestById(this.projectService.currentSelectedProject.request_id)
      .toPromise();
    const previousRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '<',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: '1' },
    ];
    this.requestService
      .getRequests(['id'], previousRequestFilters, 1, 'created_datetime', 'desc')
      .subscribe((requests) => {
        this.previousRequest = requests[0];
      });
    const nextRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '>',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: '1' },
    ];
    this.requestService.getRequests(['id'], nextRequestFilters, 1, 'created_datetime', 'asc').subscribe((requests) => {
      this.nextRequest = requests[0];
    });
    this.shownRequest = request;
    this.rooms = request.rooms ? JSON.parse(request.rooms) : [];
    const contactIds = request.contact_ids ? JSON.parse(request.contact_ids) : [];
    this.contacts = [];
    for (const cid of contactIds) {
      const user = await this.userService.getUserById(cid).toPromise();
      this.contacts.push(user);
    }

    this.projectTemplates = await this.projectTemplateService.getProjectTemplates(['id']).toPromise();
    this.selectedTemplates = [];
    const currentTemplates = JSON.parse(this.shownRequest.project_template_ids) || [];
    for (const templateId of currentTemplates) {
      this.selectedTemplates.push(this.projectTemplates.find((t) => t.id === templateId));
    }
    this.availableTemplates = pullAllBy(cloneDeep(this.projectTemplates), this.selectedTemplates, 'id');
    this.getValidationErrors();
    this.architects = await this.userService.getUsersByRole(ApplicationRole.ModuleArchitect, 1).toPromise();
    this.workspaceManagers = await this.userService.getUsersByRole(ApplicationRole.WorkspaceManager, 1).toPromise();
    this.projectManagers = await this.userService.getUsersByRole(ApplicationRole.ModuleProjectManager, 1).toPromise();
    this.files = await this.fileService.getFilesByParentId(ResourceType.Request, request.id, null, 20).toPromise();
  }

  downloadFile(file) {
    file.loading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.loading = false;
    });
  }

  getValidationErrors() {
    this.validationErrors = { valid: true };
    // if (!this.shownRequest || !this.shownRequest.workspace_manager_id) { this.validationErrors['valid'] = false; this.validationErrors['workspaceManager'] = true; }
    // if (!this.shownRequest || !this.shownRequest.architect_id) { this.validationErrors['valid'] = false; this.validationErrors['architect'] = true; }
    if (!this.shownRequest || this.shownRequest.status_id !== 2) {
      this.validationErrors.valid = false;
      this.validationErrors.accepted = true;
    }
    if (!this.selectedTemplates || this.selectedTemplates.length < 1) {
      this.validationErrors.valid = false;
      this.validationErrors.templateCount = true;
    }
    return this.validationErrors;
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }
}
