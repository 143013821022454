import { Injectable } from '@angular/core';

import { uniq } from 'lodash';

import { ProjectService, UserService } from 'src/app/services';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';

@Injectable({
  providedIn: 'root',
})
export class FilterUsersByProjectService {
  constructor(
    private projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private userService: UserService
  ) {}

  public async getProjectCompanyIds(projectId?: number) {
    const project = projectId || this.projectService.currentSelectedProjectId;
    if (project) {
      const bidPackages = await this.projectService
        .getBidPackages(
          [
            {
              type: 'field',
              field: 'project_id',
              value: project.toString(),
            },
          ],
          ['awarded_company_id']
        )
        .toPromise();

      return uniq(bidPackages.map((p) => p.awarded_company_id));
    }

    return null;
  }

  public async getTenantRepIds(projectId?: number) {
    const project = projectId || this.projectService.currentSelectedProjectId;
    const projectTenants = await this.projectTenantService.getTenantsForProject(project).toPromise();

    return uniq(projectTenants.map((t) => t.representative_id));
  }

  public async getTenantContactIds(projectId?: number) {
    const project = projectId || this.projectService.currentSelectedProjectId;
    const projectTenantContacts = await this.userService.getUsersByRole(22, project, []).toPromise();
    return projectTenantContacts.map((c) => c.id);
  }
}
