import { Component, OnInit, ViewChild } from '@angular/core';

import * as moment from 'moment';

import { ProjectService, ProgressIndicatorService } from 'src/app/services';
import { ProgramDataService } from 'src/app/workspaces/construction/services';

import { ProjectProgramData } from 'src/app/workspaces/construction/models';

import { CheckboxContainerItem } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-program-data',
  templateUrl: './program-data.component.html',
  styleUrls: ['./program-data.component.scss'],
})
export class ProgramDataComponent implements OnInit {
  @ViewChild('pdf', { static: false }) pdf;

  public data = new ProjectProgramData();

  public isEditing: boolean;

  public allowEdit: boolean;

  public downloading = false;

  private selectedProject: ProjectConstruction;
  private selectedProjectId: number;

  constructor(
    private programDataService: ProgramDataService,
    private projectService: ProjectService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit() {
    this.selectedProject = this.projectService.currentSelectedProject;
    this.selectedProjectId = this.projectService.currentSelectedProjectId;
    setTimeout(async () => {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Data..');
      await this.programDataService
        .getDataByProjectId(this.selectedProjectId)
        .toPromise()
        .then((data) => {
          if (data) {
            this.data = data;
            this.getTechnologyItemsStatus();
            this.getDoorTypeItemsStatus();
            this.allowEdit = true;
          } else {
            this.programDataService.createDataForProject(this.selectedProjectId).subscribe((created) => {
              this.data = created;
              this.getTechnologyItemsStatus();
              this.getDoorTypeItemsStatus();
              this.allowEdit = true;
            });
          }
        });
      this.progressIndicatorService.close();
    });
  }

  private getDoorTypeItemsStatus() {
    const trueIndexes = []; // TODO: Get true indices from the database
    for (const index of trueIndexes) {
      this.data.doorTypeCheckboxItems[index].checked = true;
    }
  }

  private getTechnologyItemsStatus() {
    const trueIndexes = []; // TODO: Get true indices from the database
    for (const index of trueIndexes) {
      this.data.technologyCheckboxItems[index].checked = true;
    }
  }

  /** Returns an array filled with the indices that are marked true from the provided items list */
  // TODO: USE THIS METHOD WHEN SAVING
  private getCheckboxDataTrueArrayIndices(items: CheckboxContainerItem[]): number[] {
    const indexList: number[] = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].checked) {
        indexList.push(i);
      }
    }
    return indexList;
  }

  public saveData() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    this.programDataService.updateData(this.data).subscribe((saved) => {
      this.isEditing = false;
      this.progressIndicatorService.close();
    });
  }

  onAdditionalRequiredItemListChanged(newItemList: string[]) {
    this.data.requiredItemsList = newItemList;
  }

  onAttendeesListChanged(newItemList: string[]) {
    this.data.attendeesList = newItemList;
  }

  onSpaceTypeChange(selectionValue) {
    this.data.isNewSpace = selectionValue ? false : true;
  }

  isClinicalSpaceChanged(isClinicalSpace: boolean) {
    this.data.isClinicalSpace = isClinicalSpace;
  }

  containsBiohazardMaterialsSelChange(event: boolean) {
    this.data.containsBiohazardMaterials = event;
  }

  healthcareSuppliesNeedInstallationSelChange(event: boolean) {
    this.data.healthcareSuppliesNeedInstall = event;
  }

  emergencyEyewashRequiredSelChange(event: boolean) {
    this.data.requiresEmergencyEyewash = event;
  }

  requiresEmergencyPowerSelChange(event: boolean) {
    this.data.requiresEmergencyPower = event;
  }

  requiresAccessControlsSelChange(event: boolean) {
    this.data.requiresAccessControls = event;
  }

  requiresDigitalSignageSelChange(event: boolean) {
    this.data.requiresDigitalSignage = event;
  }

  requiresOtherSignageSelChange(event: boolean) {
    this.data.requiresOtherSignage = event;
  }

  accentWallsRequired(event: boolean) {
    this.data.requiresAccentWalls = event;
  }

  strobesAffectedSelChange(event: boolean) {
    this.data.areStrobesAffected = event;
  }

  sprinklerHeadsAffectedSelChange(event: boolean) {
    this.data.areSprinkerHeadsAffected = event;
  }

  flooringToBeReplacedSelChange(event: boolean) {
    this.data.isFlooringToBeReplaced = event;
  }

  containsAsbestosSelChange(event: boolean) {
    this.data.containsAsbestos = event;
  }

  requireStructuralEngineerSelChange(event: boolean) {
    this.data.requiresStructuralEngineer = event;
  }

  onAddTechnologyLocation(newItemList: string[]) {
    this.data.technologyLocationsList = newItemList;
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  exportPDF() {
    this.downloading = true;
    this.pdf.saveAs(`Program_Data_${this.selectedProjectId}.pdf`);
    this.downloading = false;
  }
}
