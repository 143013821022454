import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse } from 'src/app/types';
import { ConstructionBudget, ConstructionBudgetItem } from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstructionBudgetService {
  host: string = environment.serviceHost;
  constructionBudgetUrl = `${this.host}/api/v1/construction-budgets`;
  constructionBudgetNEWUrl = `${this.host}/api/v1/cb`;
  constructionBudgetItemUrl = `${this.host}/api/v1/construction-budget-items`;

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getConstructionBudgets(fields: string[], apiFilters?: APIFilter[]): Observable<ConstructionBudget[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.constructionBudgetUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.construction_budgets;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getCBData(projectID: number): Observable<any> {
    return this.http.get(`${this.constructionBudgetNEWUrl}/${projectID}/data`).pipe(
      map((result: ServiceResponse) => {
        const pebData = result.data;
        return pebData;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateCB(projectID: number, cbData): Observable<any> {
    return this.http.put(`${this.constructionBudgetNEWUrl}/${projectID}/data`, cbData).pipe(
      map((result: ServiceResponse) => {
        const cbdata = result.data;
        return cbdata;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createConstructionBudget(constructionBudget: ConstructionBudget, fields: string[]): Observable<ConstructionBudget> {
    return this.http.post(`${this.constructionBudgetUrl}?fields=${fields.join(',')}`, constructionBudget).pipe(
      map((result: ServiceResponse) => {
        const constructionBudgetToReturn = result.data['construction budget'];
        return constructionBudgetToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateConstructionBudget(
    id: number,
    constructionBudget: ConstructionBudget,
    fields: string[]
  ): Observable<ConstructionBudget> {
    return this.http.put(`${this.constructionBudgetUrl}/${id}?fields=${fields.join(',')}`, constructionBudget).pipe(
      map((result: ServiceResponse) => {
        const constructionBudgetToReturn: ConstructionBudget = result.data['construction budget'];
        return constructionBudgetToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getConstructionBudgetItems(fields: string[], apiFilters?: APIFilter[]): Observable<ConstructionBudgetItem[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.constructionBudgetItemUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.construction_budget_items;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createConstructionBudgetItem(
    constructionBudgetItem: ConstructionBudgetItem,
    fields: string[]
  ): Observable<ConstructionBudgetItem> {
    return this.http.post(`${this.constructionBudgetItemUrl}?fields=${fields.join(',')}`, constructionBudgetItem).pipe(
      map((result: ServiceResponse) => {
        const constructionBudgetItemToReturn = result.data['construction budget item'];
        return constructionBudgetItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateConstructionBudgetItem(
    itemId: number,
    constructionBudgetItem: ConstructionBudgetItem,
    fields: string[]
  ): Observable<ConstructionBudgetItem> {
    return this.http
      .put(`${this.constructionBudgetItemUrl}/${itemId}?fields=${fields.join(',')}`, constructionBudgetItem)
      .pipe(
        map((result: ServiceResponse) => {
          const constructionBudgetItemToReturn: ConstructionBudgetItem = result.data['construction budget item'];
          return constructionBudgetItemToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
}
