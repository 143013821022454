<div class="modal-wide">
  <div class="row align-items-center">
    <div class="col">
      <h5 class="dkblue font-weight-semibold margin-0">Form Review</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row mb-4 mt-4">
    <div class="col">
      <p class="m-0 dkblue" style="white-space: pre-wrap">
        {{ reviewDescription }}
      </p>
    </div>
  </div>
  <hr />
  <div class="row mb-4">
    <div class="col">
      <mat-chip-list class="d-inline-block align-middle">
        <app-file-chip *ngFor="let file of filesToReview" [file]="file"></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <!-- <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Review Status</mat-label>
                <mat-select>
                    <mat-option value="No Exceptions Taken">
                        No Exceptions Taken
                    </mat-option>
                    <mat-option value="No Exceptions Taken">
                        Reviewed - Exceptions Taken
                    </mat-option>
                    <mat-option value="No Exceptions Taken">
                        Revise & Resubmit
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Select Approve/Reject</mat-label>
        <mat-select>
          <mat-option value="Approve" (click)="reviewSelection = 'Approve'"> No Exceptions Taken </mat-option>
          <mat-option value="Reject" (click)="reviewSelection = 'Reject'"> Revise and Resubmit </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Message{{ reviewSelection === 'Reject' ? '*' : '' }}</mat-label>
        <textarea
          matInput
          rows="4"
          placeholder="{{ reviewSelection === 'Reject' ? 'What needs to be changed?' : 'Additional Comments' }}"
          [(ngModel)]="reviewMessage"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <input
        mat-raised-button
        [hidden]="true"
        #uploader
        type="file"
        (change)="uploadFile($event.target.files); uploader.value = null"
      />
      <!--      <button mat-stroked-button (click)="uploader.click()" class="mr-2" color="accent">
          Upload
        </button>-->
      <!-- Maybe hide the search project button if user is a tenant -->
      <!-- <button mat-button (click)="openFileSelectDialog()" color="accent">
            Search Project
        </button> -->
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        mat-flat-button
        class="full-width"
        color="accent"
        [disabled]="
          reviewSelection == null || (reviewSelection === 'Reject' && (!reviewMessage || reviewMessage.length <= 0))
        "
        (click)="submitReview()"
      >
        Submit Review
      </button>
    </div>
  </div>
</div>
