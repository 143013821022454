<app-base-dialog
  title="Review ARF: {{ company?.name }} For {{ tenant?.tenant_name || 'UHAT' }}"
  (closeDialog)="cancel()"
></app-base-dialog>
<div class="d-flex flex-column align-items-center p-5 mx-auto" *ngIf="isLoading">
  <mat-spinner color="accent" [diameter]="40"></mat-spinner>
</div>
<form
  mat-dialog-content
  class="arf-review-dialog project-modal bg-shade-gray p-4"
  style="width: 1000px"
  id="arf-review-dialog"
  data-test-id="arf-review-dialog"
  *ngIf="!isLoading"
>
  <div class="row m-0">
    <div class="col-12">
      <div class="m-portlet p-0 m-0 br-10">
        <div class="m-portlet__body arf-container d-flex flex-column p-4 position-relative">
          <div class="d-flex align-items-top mb-2">
            <div class="d-flex flex-column mr-auto">
              <h4 class="m-0 dkblue font-weight-semibold">Acquisition Request Form</h4>
            </div>
            <h6 *ngIf="quote" (click)="viewQuote()" class="ltblue font-weight-semibold hover-u pointer mb-0 ml-3">
              View Supplier Quote
            </h6>
            <h6 (click)="downloadArf()" class="ltblue font-weight-semibold hover-u pointer mb-0 ml-4">Download ARF</h6>
          </div>
          <div class="d-flex align-items-top mb-5">
            <div class="d-flex flex-column mr-auto">
              <div class="d-flex align-items-center mt-1">
                <h6 class="bg-dkgray white lh-18 br-4 px-2 text-uppercase mr-2">FY{{ fiscalYear }}</h6>
                <h6 class="bg-gray white lh-18 br-4 px-2 text-uppercase mr-2">{{ workspace?.name }}</h6>
              </div>
              <h6 *ngIf="tenant?.tenant_name" class="mt-2 mb-0 dkblue font-weight-normal">
                Tenant Purchase: {{ tenant?.tenant_name }}
              </h6>
              <h6 *ngIf="!tenant?.tenant_name" class="mt-2 mb-0 dkblue font-weight-normal">UHAT Purchase</h6>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="border-bottom-heavy">
              <div class="mb-3 d-flex flex-column">
                <!-- ARF TITLE OR PROJECT  -->
                <h3 class="mb-2 dkblue">{{ code }} | {{ title }}</h3>
                <!-- ARF SUMMARY -->
                <p *ngIf="task?.description" class="dkgray m-0" [innerHTML]="task?.description"></p>
              </div>
            </div>
            <section class="border-bottom-heavy mb-0">
              <div class="row">
                <div class="col">
                  <div class="row align-items-center py-2">
                    <div class="col-3 d-flex align-items-center" *ngFor="let purchaseType of purchaseTypes">
                      <mat-checkbox
                        class="dkblue wrap-label"
                        [checked]="purchaseTypeId === purchaseType.id"
                        [disabled]="true"
                      >
                        <h6 class="dkblue m-0 font-weight-normal" style="font-size: 12px">
                          {{ purchaseType.name }}
                        </h6>
                      </mat-checkbox>
                    </div>
                    <div class="col-3 d-flex align-items-center">
                      <mat-checkbox [checked]="!!assetTagged" [disabled]="true">
                        <h6 class="dkblue m-0 font-weight-normal" style="font-size: 12px">Tag Asset</h6>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="m-0 border-bottom-heavy">
              <div class="d-flex flex-row m-0 py-2 align-items-center border-bottom border-gray">
                <div class="col pl-0 pr-0">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">Item</small>
                  </h6>
                </div>
                <div class="col-5">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">Product</small>
                  </h6>
                </div>
                <div class="col text-right pl-0 pr-0">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">QTY</small>
                  </h6>
                </div>
                <div class="col-3 pr-0">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">Supplier</small>
                  </h6>
                </div>
                <div class="col-1 pr-0 text-right">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">Price Per</small>
                  </h6>
                </div>
                <div class="col-2 text-right pr-0">
                  <h6 class="dkgray m-0 p-0 text-uppercase">
                    <small class="font-weight-semibold">{{ quote ? 'BID' : '' }} Total</small>
                  </h6>
                </div>
              </div>
              <section>
                <!-- PRODUCT LINE ITEMS PER GROUP -->
                <div
                  *ngFor="let p of products | productFilter: quote?.id; let i = index"
                  class="d-flex align-items-stretch"
                >
                  <div class="row m-0 py-3 d-flex full-width border-bottom border-gray">
                    <div class="col pl-0 pr-0">
                      <h6 class="m-0 dkblue">
                        {{ i + 1 }}
                      </h6>
                    </div>
                    <div class="col-5 d-flex flex-column">
                      <h6 class="mb-0 text-wrap dkblue font-weight-semibold">
                        <!-- Product Name -->
                        {{ p?.product?.name || p?.name }}
                      </h6>
                      <h6
                        *ngIf="p.description"
                        class="mt-2 text-wrap dkgray font-weight-normal"
                        [innerHTML]="p?.description"
                      >
                        <!-- Product Description -->
                      </h6>
                      <p class="primary lh-16 mt-3 mb-0" *ngIf="p?.sub_cost_code_budget">
                        {{ p?.sub_cost_code_budget?.code }} -
                        {{ p?.sub_cost_code_budget?.label }}
                        <br />
                        <small>
                          {{ p.sub_cost_code_budget?.cost_code?.code }} |
                          {{ p.sub_cost_code_budget?.cost_code?.label }}
                        </small>
                      </p>
                    </div>
                    <div class="col text-right pr-0 pl-0">
                      <h6 class="m-0 font-weight-semibold dkblue">
                        {{ p.quantity }}
                      </h6>
                    </div>
                    <div *ngIf="!quote" class="col-3 pr-0">
                      <h6 class="m-0 font-weight-semibold dkblue">
                        {{ company?.name }}
                      </h6>
                    </div>
                    <div *ngIf="!quote" class="col-1 pr-0 text-right">
                      <h6 class="m-0 font-weight-semibold dkblue">
                        {{ p.unit_price || 0 | USDollar: 2:true }}
                      </h6>
                    </div>
                    <div *ngIf="!quote" class="col-2 text-right pr-0">
                      <h6 class="m-0 font-weight-semibold dkblue">
                        {{ p.total_price || 0 | USDollar: 2:true }}
                      </h6>
                    </div>

                    <div class="col-5 pr-0 d-flex flex-column" *ngIf="quote">
                      <div
                        *ngFor="let quoteItem of p?.quote_items; let first = first"
                        class="d-flex align-items-center"
                        [ngClass]="{ 'mt-2': !first }"
                        [hidden]="
                          [QuoteItemStatus.BidReceived, QuoteItemStatus.DidNotBid].indexOf(quoteItem.status_id) === -1
                        "
                      >
                        <h6
                          class="m-0 text-truncate mr-auto dkblue"
                          [ngClass]="{
                            'gray font-weight-normal': quoteItem?.quote_id !== quote?.id
                          }"
                        >
                          {{ quoteItem?.quote?.company?.name || quoteItem?.label || 'No Name' }}
                        </h6>
                        <h6
                          *ngIf="quoteItem?.quote_id !== quote?.id"
                          class="m-0 ml-2 dkblue text-nowrap"
                          [ngClass]="{
                            'gray font-weight-normal': quoteItem?.quote_id !== quote?.id
                          }"
                        >
                          {{
                            quoteItem.status_id === QuoteItemStatus.DidNotBid
                              ? 'Did Not Bid'
                              : (quoteItem.total_price || 0 | USDollar: 2:true)
                          }}
                        </h6>
                        <h6
                          *ngIf="quoteItem?.quote_id === quote?.id"
                          class="m-0 ml-2 dkblue"
                          [ngClass]="{
                            'gray font-weight-normal': quoteItem?.quote_id !== quote?.id
                          }"
                        >
                          {{
                            quoteItem.status_id === QuoteItemStatus.DidNotBid
                              ? 'Did Not Bid'
                              : (quoteItem.total_price || 0 | USDollar: 2:true)
                          }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="p-0 d-flex py-3 align-items-stretch"
                  *ngIf="quote && productService.getSelectedQuoteTaxTotal(tenant, quote)"
                >
                  <div class="col-7 pr-0"></div>
                  <div class="col-5 d-flex pr-0">
                    <h6 class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Sales Tax</h6>
                    <h6 class="ml-3 mb-0 dkblue font-weight-semibold">
                      {{ productService.getSelectedQuoteTaxTotal(tenant, quote) | USDollar: 2:true }}
                    </h6>
                  </div>
                </div>
              </section>
            </div>
            <div class="d-flex align-items-center m-0 py-3 mb-5">
              <h5 class="dkblue mb-0 font-weight-semibold mr-auto">Total Purchase Price</h5>
              <h5 class="m-0 dkblue font-weight-bold">
                {{ total | USDollar: 2:true }}
              </h5>
            </div>
          </div>
          <div class="py-4"></div>
          <div class="d-flex flex-column mt-auto">
            <h6 *ngIf="arfCreator" class="dkgray font-weight-normal mb-1">
              <small> ARF created by: {{ arfCreator?.first_name }} {{ arfCreator?.last_name }} </small>
            </h6>
            <div class="d-flex p-2 bg-ltgray br-4">
              <label class="m-0 dkblue main-label font-weight-normal">
                $25,000.00+ Requires 3 Supplier Solicitations; $100,000.00+ Requires 5 Supplier Solicitations;
                $250,000.00+ Requires competitive bidding process outlined in Part IV (D) of Policy & Procedures; ICO
                Approval Required for $500.00+ IT related items; $2,500+ non-IT related items;
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-4 pl-0 pr-0 d-flex flex-column" *ngIf="task">
      <app-review-sidebar [task]="task" (closeDialog)="submitApproval($event)"></app-review-sidebar>
    </div> -->
  </div>
</form>
<div matDialogActions class="py-3 d-flex align-items-center justify-content-end">
  <div class="ml-3">
    <p class="dkgray text-right lh-16 mb-0" style="max-width: 460px">
      <small>
        By clicking "Approve", you are confirming your agreement with the details outlined above and our
        <a href="https://uhat.org/terms-of-service" class="ltblue hover-u">terms and conditions</a>. This action may
        create a signature on your behalf.
      </small>
    </p>
  </div>
  <button
    mat-flat-button
    color="warn"
    class="ml-3 px-5"
    [disabled]="isLoading"
    (click)="submitApproval(TaskReviewStatus.Rejected)"
  >
    Reject
  </button>
  <button
    mat-flat-button
    color="success"
    class="ml-3 px-5"
    [disabled]="isLoading"
    (click)="submitApproval(TaskReviewStatus.Approved)"
  >
    Approve
  </button>
</div>
<app-arf-export
  #arfFile
  [project]="project"
  [selectedTenant]="tenant"
  [selectedQuote]="quote"
  [arf]="arf"
></app-arf-export>
