<app-base-dialog [title]="'Cost Code Help'" (closeDialog)="cancel()"> </app-base-dialog>
<div id="cost-codes-help-dialog" data-test-id="cost-codes-help-dialog" class="cost-codes-help-dialog">
  <div class="d-flex justify-content-between mb-3">
    <mat-form-field appearance="outline" class="col-9">
      <mat-icon class="dkblue" matPrefix>search</mat-icon>
      <input matInput [(ngModel)]="searchTerm" placeholder="Search Cost Codes" />
      <mat-icon *ngIf="searchTerm" class="ltgray pointer" matSuffix (click)="searchTerm = ''">cancel</mat-icon>
    </mat-form-field>
    <button
      mat-stroked-button
      class="bg-white ml-3"
      color="primary"
      (click)="exportData()"
      *ngIf="authService.isStaffOnAnyModule"
    >
      <span class="d-inline-flex">
        <mat-spinner *ngIf="isExporting" color="accent" [diameter]="20"></mat-spinner>
        <i *ngIf="!isExporting" class="material-icons-outlined"> file_download </i>
      </span>
      <span> Download CSV </span>
    </button>
  </div>
  <mat-dialog-content>
    <div>
      <!-- FOR EACH COST CODE -->
      <ng-container *ngIf="costCodes.length; else loadingCostCodes">
        <section
          *ngFor="let costCode of costCodes | filterCostCodeBySearchTerm: searchTerm"
          class="mb-4 d-flex flex-column"
        >
          <div class="p-3 br-10 bg-shade-ltblue mb-4">
            <h5 class="primary m-0">
              {{ costCode.code }} <span [innerHTML]="costCode.label | highlight: searchTerm"></span>
            </h5>
          </div>
          <!-- repeat each sub cost code -->
          <div *ngFor="let subCostCode of costCode.sub_cost_codes" class="pl-5 mb-3">
            <h6 class="primary">
              {{ subCostCode.code }} <span [innerHTML]="subCostCode.label | highlight: searchTerm"></span> ({{
                subCostCode.sub_cost_code_type.name
              }})
            </h6>
            <p
              *ngIf="subCostCode.description"
              class="dkgray"
              [innerHTML]="subCostCode.description || '' | highlight: searchTerm"
            ></p>
          </div>
        </section>
      </ng-container>
      <ng-template #loadingCostCodes>
        <p class="text-center dkgray my-4">Loading cost codes...</p>
      </ng-template>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="">Close</button>
  </mat-dialog-actions>
</div>
