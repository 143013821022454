import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationService } from 'src/app/services';
import { Floor, LocationExist, APIFilter } from 'src/app/types';
import { LOCATION_NOT_FOUND } from 'src/app/utils';
@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss'],
})
export class FloorComponent implements OnInit {
  @Input() public notFound = false;
  @Input() public required = true;
  @Input() public showLabel = true;

  private _placeholder = 'Search Floors';

  public floor = new FormControl('');
  public filteredFloors: Observable<Floor[]>;

  constructor(private _locationService: LocationService) {}

  async ngOnInit(): Promise<void> {
    await this._updateValidations();
  }

  get placeholder() {
    return !this.showLabel && this.required ? `${this._placeholder}*` : this._placeholder;
  }

  private _filter(entry: string): Floor[] {
    return this._locationService.floors.filter(
      (floor) =>
        floor?.name?.toLowerCase()?.includes(entry?.toLowerCase()) || floor?.name?.toLowerCase()?.includes('not')
    );
  }

  private _filterExcludingNotFound(value: string): Floor[] {
    return this._locationService.floors.filter((floor: Floor) =>
      floor?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );
  }

  private async _subscribe() {
    this.filteredFloors = this.floor.valueChanges.pipe(
      startWith(''),
      map((value: string | Floor) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this._locationService.floors.slice()))
    );
  }

  private async _updateValidations() {
    if (this.required) {
      this.floor.setValidators([Validators.required]);
    }
  }

  public checkValue() {
    // times when user changes his mind, back and forth
    // value in input and does not select it,
    // This will select it
    if (this.floor?.value && typeof this.floor?.value === 'string') {
      const foundFloors = this._filterExcludingNotFound(this.floor.value);
      if (foundFloors.length === 1) {
        // we set it for them
        this.floor.setValue(foundFloors[0]);
      } else {
        this.floor.setErrors({ doesNotExist: true });
      }
    }
  }

  public async clearInput(event = null) {
    this.floor.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public async getFloorsByBuildId(buildingId: number, floorId?: number) {
    const floorFilters: APIFilter[] = !floorId
      ? [
          { type: 'field', field: 'building_id', value: buildingId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ]
      : [
          { type: 'field', field: 'building_id', value: buildingId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: floorId.toString() },
          { type: 'operator', value: ')' },
        ];

    this._locationService.floors = [
      ...(await this._locationService.getFloors(['id', 'name'], floorFilters).toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  public mapper(floor: Floor): string {
    return floor?.name || '';
  }

  public valueExist(value: string): LocationExist {
    const foundfloors = this._filterExcludingNotFound(value);
    if (foundfloors.length === 0) {
      this.floor.setErrors({ doesNotExist: true });
      return { exists: false, id: null, single: false };
    }

    const id = foundfloors.length === 1 ? foundfloors[0].id : null;
    const single = foundfloors.length === 1 ? true : false;
    return { exists: true, id, single };
  }
}
