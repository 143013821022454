import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function autoCompleteValidator<T>(fieldName: string, options: T[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValidOption = options.map((option: T): unknown => option[fieldName]).includes(control.value);
    if (isValidOption) {
      return null;
    } else {
      return { invalidAutoCompleteOption: true };
    }
  };
}
