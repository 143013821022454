import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, Company, CompanyType, ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';
import { CompanyItem } from '../types/company-item';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  private host: string = environment.serviceHost;

  private companyTypeUrl = `${this.host}/api/v1/company-types`;
  private companyUrl = `${this.host}/api/v1/companies`;
  private companyItemsUrl = `${this.host}/api/v1/company-items`;

  private companyTypeFields = ['id', 'name'];
  private companyFields = ['id', 'type_id', 'name', 'trade_ids'];

  getCompanyTypes(fields?: string[], apiFilters?: APIFilter[], limit?: number): Observable<CompanyType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.companyTypeUrl}?fields=${(fields || this.companyTypeFields).join(',')}&limit=${limit || 1000}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const companyTypes: CompanyType[] = result.data.company_types;
          return companyTypes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getCompanies(fields?: string[], apiFilters?: APIFilter[], limit?: number): Observable<Company[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.companyUrl}?fields=${(fields || this.companyFields).join(',')}&limit=${limit || 1000}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const companies: Company[] = result.data.companies;
          return companies;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getCompanyById(companyId: number, fields: string[]) {
    return this.http.get(`${this.companyUrl}/${companyId}?fields=${(fields || this.companyFields).join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const company: Company = result.data.company[0];
        return company;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createCompany(companyToCreate: Company, fields: string[]): Observable<Company> {
    return this.http.post(`${this.companyUrl}?fields=${fields.join(',')}`, companyToCreate).pipe(
      map((result: ServiceResponse) => {
        const companyToReturn: Company = result.data.company;
        return companyToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateCompany(companyId: number, companyToUpdate: Company, fields: string[]): Observable<Company> {
    return this.http.put(`${this.companyUrl}/${companyId}?fields=${fields.join(',')}`, companyToUpdate).pipe(
      map((result: ServiceResponse) => {
        const companyToReturn: Company = result.data.company;
        return companyToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createCompanyItem(companyItemToCreate: CompanyItem): Observable<CompanyItem> {
    return this.http.post(`${this.companyItemsUrl}`, companyItemToCreate).pipe(
      map((result: ServiceResponse) => {
        const companyItemToReturn: CompanyItem = result.data['company items'];
        return companyItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateCompanyItem(companyItemId: number, companyItemToUpdate: CompanyItem): Observable<CompanyItem> {
    return this.http.put(`${this.companyItemsUrl}/${companyItemId}`, companyItemToUpdate).pipe(
      map((result: ServiceResponse) => {
        const companyItemToReturn: CompanyItem = result.data['company items'];
        return companyItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteCompanyItem(itemId: number) {
    return this.http.delete(`${this.companyItemsUrl}/${itemId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
