import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MeetingService } from 'src/app/services';
import { AgendaItem, Meeting, User } from 'src/app/types';

@Component({
  selector: 'app-view-meeting-dialog',
  templateUrl: './view-meeting-dialog.component.html',
  styleUrls: ['./view-meeting-dialog.component.scss'],
})
export class ViewMeetingDialogComponent implements OnInit {
  public agendaItems: AgendaItem[] = [];
  public agendaLength: number;
  public attendees: User[] = [];
  public agendaMeetingDiff = { diff: 0, over: false };
  public meeting: Meeting;
  public meetingData;
  public meetingId: number;
  public meetingLength: number;

  constructor(
    public dialogRef: MatDialogRef<ViewMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private meetingService: MeetingService
  ) {}

  get endTime() {
    if (this?.meeting?.end_datetime) {
      return moment(this.meeting.end_datetime).format('h:mm A');
    }
  }

  get startDate() {
    if (this?.meeting?.start_datetime) {
      return moment(this.meeting.start_datetime).format('dddd, MMM DD YYYY');
    }
  }

  get startTime() {
    if (this?.meeting?.start_datetime) {
      return moment(this.meeting.start_datetime).format('h:mm A');
    }
  }

  async ngOnInit() {
    if (this.data?.id) {
      this.meetingId = this.data.id;
      this.meeting = await this.meetingService.getMeetingById(this.data.id).toPromise();
      this.meetingData = await this.meetingService.getInitDataMeetingById(this.data.id).toPromise();
      this.attendees = this.meetingData.attendees;
      this.agendaItems = this.meetingData.agendaItems;

      this.agendaItemsTotalTime();
      this.findMeetingLength();
      this.findAgendaMeetingDiff();
    }
  }

  private agendaItemsTotalTime() {
    if (this.agendaItems?.length) {
      let total = 0;
      this.agendaItems.forEach((agendaItem) => {
        total += agendaItem.duration;
      });
      this.agendaLength = total;
    }
  }

  private findAgendaMeetingDiff() {
    const diff = this.meetingLength - this.agendaLength;
    this.agendaMeetingDiff.diff = Math.abs(diff);
    this.agendaMeetingDiff.over = diff < 0 ? false : true;
  }

  private findMeetingLength() {
    if (this?.meeting?.start_datetime && this.meeting?.end_datetime) {
      const startTime = moment(this.meeting.start_datetime);
      const endTime = moment(this.meeting.end_datetime);
      const diff = startTime.diff(endTime, 'minutes');

      this.meetingLength = Math.abs(diff);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
