<div id="workspace-dropdown" data-test-id="workspace-dropdown" class="workspace-dropdown">
  <h6 *ngIf="showTitle" class="dkblue font-weight-semibold mb-2 mr-auto">Workspace</h6>
  <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Workspace"
      matInput
      [formControl]="workspace"
      [matAutocomplete]="workspace_name"
    />
    <mat-icon
      *ngIf="workspace?.value && !workspace?.disabled"
      matSuffix
      class="gray pointer"
      (click)="clearInput($event)"
      >cancel</mat-icon
    >
    <mat-error> {{ customPlaceholder || 'A workspace is required' }}</mat-error>
    <mat-autocomplete #workspace_name="matAutocomplete" [displayWith]="workspaceMapper">
      <mat-option *ngFor="let w of filteredWorkspaces | async" [value]="w">
        {{ w?.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
