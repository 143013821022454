import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import { InvoiceStatus, InvoiceType, ResourceType, TaskReviewStatus, Workspace } from 'src/app/enums';
import {
  ArfsService,
  AuthService,
  DateService,
  FileService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import {
  APIFilter,
  Arf,
  ArfInvoiceAmount,
  Invoice,
  ProjectTenant,
  Quote,
  SubCostCodeBudget,
  TaskAccessoryData,
} from 'src/app/types';
import { ViewChangeOrderDialogComponent } from 'src/app/workspaces/construction/components';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { BidPackage, ChangeOrder, InvoiceTimeframe } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-view-invoice-dialog',
  templateUrl: './view-invoice-dialog.component.html',
  styleUrls: ['./view-invoice-dialog.component.scss'],
})
export class ViewInvoiceDialogComponent implements OnInit {
  public invoice: Invoice;
  public bidPackage: BidPackage;
  public changeOrder: ChangeOrder;
  public quote: Quote;
  private invoiceId: number;
  public invoiceFiles: File[];
  public currentWorkspace: number;
  public isReviewItem: boolean;
  public isReviewAdmin: boolean;
  public isProjectInvoice = true;
  public approvalComment: string;
  public approvalStatus: TaskReviewStatus = TaskReviewStatus.Pending;
  public accessoryData: TaskAccessoryData;
  public eInvoiceType = InvoiceType;
  public eInvoiceStatus = InvoiceStatus;
  public timeframes: InvoiceTimeframe[];
  public fiscalYears: { value: string | number; isCurrentYear: boolean }[];
  private projectTenants: ProjectTenant[];
  public fundingSources: any[] = [];
  public dialogTitle: string;
  private projectBudgetData;
  public contingencyBudget = 0;
  public contingencyUsed = 0;
  public genConBudget = 0;
  public genConUsed = 0;
  private isFirstReviewer;
  loading = false;
  arfInvoiceAmounts: ArfInvoiceAmount[] = [];
  initialArfInvoiceAmounts: ArfInvoiceAmount[] = [];
  arfInvoiceAmountsToDelete: ArfInvoiceAmount[] = [];
  subCostCodeBudgets: SubCostCodeBudget[];
  arf: Arf;
  allowAdditionalCodes: boolean;

  private invoiceFields = [
    'approval_task_id',
    'approval_task_accessory_data',
    'bid_package_id',
    'project_id',
    'status_id',
    'status_name',
    'is_retainage',
    'title',
    'number',
    'total',
    'retainage',
    'shipping',
    'tax',
    'files',
    'created_datetime',
    'created_by_first_name',
    'created_by_last_name',
    'company_name',
    'change_order_id',
    'change_order_code',
    'change_order_local_index',
    'change_order_short_description',
    'change_order_cost_change',
    'change_order_funding_source_name',
    'change_order_funding_source_fee_type_id',
    'change_order_funding_source_tenant_id',
    'change_order_funding_source_tenant_type_id',
    'invoice_date',
    'invoice_end_date',
    'quote_id',
    'timeframe_id',
    'tenant_id',
    'is_internally_funded',
    'is_contingency_funded',
    'is_gencon_funded',
    'fiscal_year',
    'parent_id',
    'parent_resource_type_id',
  ];

  private bidPackageFields = [
    'code',
    'number',
    'trade_id',
    'trade_name',
    'project_id',
    'project_code',
    'cost_per_sqft',
    'square_footage',
    'budget_amount',
    'awarded_amount',
    'awarded_company_id',
    'awarded_company_name',
    'project{building_code,floor_code,title}',
  ];

  private quoteFields: string[] = [
    'id',
    'company{name}',
    'project{id,code,building_code,floor_code,title},description',
  ];

  invoiceFormGroup: FormGroup = this.fb.group({
    timeframe_id: [null],
    funding_source: [null],
    fiscal_year: [null],
  });

  public previousView = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public arfService: ArfsService,
    public authService: AuthService,
    public dateService: DateService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewInvoiceDialogComponent>,
    private fb: FormBuilder,
    private fileService: FileService,
    private modalService: ModalService,
    private projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private productService: ProductService,
    private progressIndicatorService: ProgressIndicatorService,
    private router: Router,
    private snackbar: MatSnackBar,
    private taskService: ProjectTaskService
  ) {}

  async ngOnInit() {
    this.load();

    this.arfService.arfInvoiceAmountUpdated.subscribe((arfInvoiceAmount) => {
      const arfInvoiceAmountIndex = this.arfInvoiceAmounts?.findIndex(
        (a) =>
          (arfInvoiceAmount?.id && a?.id === arfInvoiceAmount?.id) ||
          (!isNaN(arfInvoiceAmount?.index) && a?.index === arfInvoiceAmount?.index)
      );

      if (arfInvoiceAmountIndex > -1) {
        if (arfInvoiceAmount.id) {
          arfInvoiceAmount.update_required = true;
        }
        this.arfInvoiceAmounts[arfInvoiceAmountIndex] = {
          ...this.arfInvoiceAmounts[arfInvoiceAmountIndex],
          ...arfInvoiceAmount,
        };
      } else {
        this.arfInvoiceAmounts.push(arfInvoiceAmount);
        this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
      }
    });

    this.arfService.arfInvoiceAmountDeleted.subscribe((arfInvoiceAmount) => {
      const arfInvoiceAmountIndex = this.arfInvoiceAmounts?.findIndex(
        (a) =>
          (arfInvoiceAmount?.id && a?.id === arfInvoiceAmount?.id) ||
          (!isNaN(arfInvoiceAmount?.index) && a?.index === arfInvoiceAmount?.index)
      );

      this.arfInvoiceAmounts = this.arfInvoiceAmounts.filter(
        (a) => (a.id && a.id !== arfInvoiceAmount.id) || a.index !== arfInvoiceAmount.index
      );
      this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
      this.subCostCodeBudgets.splice(arfInvoiceAmountIndex, 1);

      if (arfInvoiceAmount?.id) {
        this.arfInvoiceAmountsToDelete.push(arfInvoiceAmount);
      }
    });
  }

  async load() {
    this.loading = true;
    this.isReviewItem = this.data.isReviewItem || false;
    this.isReviewAdmin = this.data.isReviewAdmin || false;
    this.isProjectInvoice = !!this.data.isProjectInvoice;
    // if you are moving through invoice, then the workspace has already been set
    if (!this.currentWorkspace) {
      this.currentWorkspace = this.data.currentWorkspace || Workspace.Construction;
    }
    this.invoiceFormGroup.get('funding_source').valueChanges.subscribe(async (value) => {
      if (this.projectBudgetData) {
        const selectedFundingSource = this.fundingSources?.length > value + 1 ? this.fundingSources[value] : null;

        if (selectedFundingSource) {
          const foundTenant = this.projectBudgetData?.tenants?.find((t) => t.id === selectedFundingSource.id);
          this.contingencyBudget = foundTenant?.contingencyBudget ?? 0;
          this.contingencyUsed = foundTenant?.contingencyUsed ?? 0;
          this.genConBudget = foundTenant?.generalConditionsBudget ?? 0;
          this.genConUsed = foundTenant?.generalConditionsUsed ?? 0;
        }
      }
    });

    if (!this.isReviewAdmin) {
      this.invoiceFormGroup.get('timeframe_id').disable();
      this.invoiceFormGroup.get('funding_source').disable();
      this.invoiceFormGroup.get('fiscal_year').disable();
    }
    this.timeframes = await this.projectService
      .getInvoiceTimeframes([{ type: 'field', field: 'is_enabled', value: '1' }], ['id', 'name'])
      .toPromise();

    if (this.data.invoiceId) {
      this.invoiceId = this.data.invoiceId;
      this.invoice = await this.projectService.getInvoiceById(this.invoiceId, this.invoiceFields).toPromise();

      if (this.invoice.parent_id && this.invoice.parent_resource_type_id === ResourceType.AcquisitionRequestForm) {
        this.arf = await this.arfService.getArfById(this.invoice.parent_id, this.arfService.arfListFields).toPromise();
      }
    }

    this.fiscalYears = this.dateService.getFiscalYearOptions(this.invoice.fiscal_year);

    if (this.isWorkspaceStaff && this.invoice.project_id) {
      this.projectBudgetData = await this.projectService
        .getBudgetData(
          this.invoice?.project_id ?? this.projectService.currentSelectedProjectId,
          this.currentWorkspace,
          null,
          this.invoice?.change_order_id
        )
        .toPromise();
    }

    if (this.invoice?.id) {
      this.changeOrder = {
        id: this.invoice?.change_order_id,
        code: this.invoice?.change_order_code,
        local_index: this.invoice?.change_order_local_index,
        short_description: this.invoice?.change_order_short_description,
        cost_change: this.invoice?.change_order_cost_change,
        funding_source_name: this.invoice?.change_order_funding_source_name,
        funding_source_fee_type_id: this.invoice?.change_order_funding_source_fee_type_id,
        funding_source_tenant_id: this.invoice?.change_order_funding_source_tenant_id,
        funding_source_tenant_type_id: this.invoice?.change_order_funding_source_tenant_type_id,
      };

      this.accessoryData =
        (this.invoice?.approval_task_accessory_data && JSON.parse(this.invoice.approval_task_accessory_data)) || null;
      this.isFirstReviewer = this.accessoryData?.reviewChain[0]?.id === this.authService.currentUser.id;

      this.timeframes.forEach((timeframe, index) => {
        if (timeframe.id === this.invoice.timeframe_id || (this.arf?.id && !index)) {
          this.invoiceFormGroup.get('timeframe_id').setValue(timeframe.id);
        }
      });

      this.invoiceFormGroup
        .get('fiscal_year')
        .setValue(this.invoice.fiscal_year || this.fiscalYears.find((fy) => fy.isCurrentYear).value);

      let projectId: number;
      let projectCode: string;
      let buildingCode: string;
      let floorCode: string;
      let title: string;
      if (this.currentWorkspace === Workspace.Construction && !this.arf?.id) {
        this.allowAdditionalCodes = this.isReviewAdmin;
        this.bidPackage = await this.projectService
          .getBidPackageById(this.invoice?.bid_package_id, this.bidPackageFields)
          .toPromise();
        projectId = this.bidPackage?.project_id;
        projectCode = this.bidPackage?.project_code;
        buildingCode = this.bidPackage?.project?.building_code;
        floorCode = this.bidPackage?.project?.floor_code;
        title = this.bidPackage?.project?.title;
      } else if (!this.arf?.id) {
        this.quote =
          this.invoice?.quote_id &&
          (await this.productService.getQuoteById(this.invoice.quote_id, this.quoteFields).toPromise());
        projectId = this.quote?.project?.id;
        projectCode = this.quote?.project?.code;
        buildingCode = this.quote?.project?.building_code;
        floorCode = this.quote?.project?.floor_code;
        title = this.quote?.project?.title;
      }

      const codeTitle = (projectCode && `Project ${projectCode}`) || `Arf ${this.arf?.code}`;
      const locationtitle = `${
        buildingCode && floorCode ? buildingCode + '-' + floorCode : buildingCode || floorCode || ''
      }`;
      // standalone arf do not have location, so we use the short description (arf.title) instead
      const combinedTitle = locationtitle
        ? `${codeTitle} | ${locationtitle} | ${title}`
        : `${codeTitle} | ${this.arf?.title || ''}`;
      this.dialogTitle = this.isReviewItem ? `Review Invoice - ${combinedTitle}` : `View Invoice - ${combinedTitle}`;

      this.fundingSources = [];
      const projectTenants: any[] =
        (projectId &&
          (await this.projectTenantService
            .getTenantsForProject(projectId, [
              'id',
              'type_id',
              'tenant_name',
              'selected_peb{items{owner_type_id,type_id,subtotal}}',
            ])
            .toPromise())) ||
        [];

      const trustTenant: any = projectTenants.find((t) => t.type_id === 3);
      this.projectTenants = projectTenants.filter((t) => t.type_id !== 3);

      for (const tenant of this.projectTenants) {
        this.fundingSources.push({
          id: tenant.id,
          is_internally_funded: 0,
          is_contingency_funded: 0,
          is_gencon_funded: 0,
          name: tenant.tenant_name,
        });
        if (this.currentWorkspace === Workspace.Construction) {
          if (
            this.invoice?.change_order_id ||
            (this.invoice?.tenant_id === tenant.id && this.invoice.is_contingency_funded)
          ) {
            this.fundingSources.push({
              id: tenant.id,
              is_internally_funded: 0,
              is_contingency_funded: 1,
              is_gencon_funded: 0,
              name: `${tenant.tenant_name}: Contingency Fee`,
            });
          }
          if (
            this.invoice?.change_order_id ||
            (this.invoice?.tenant_id === tenant.id && this.invoice.is_gencon_funded)
          ) {
            this.fundingSources.push({
              id: tenant.id,
              is_internally_funded: 0,
              is_contingency_funded: 0,
              is_gencon_funded: 1,
              name: `${tenant.tenant_name}: General Conditions Fee`,
            });
          }
        }
      }

      this.fundingSources.push({
        id: trustTenant?.id ?? null,
        is_internally_funded: 1,
        is_contingency_funded: 0,
        is_gencon_funded: 0,
        name: 'UHAT', // might need to adjust this for non-UHAT workspaces
      });

      if (this.currentWorkspace === Workspace.Construction && this.invoice?.change_order_id) {
        if (
          this.invoice?.change_order_id ||
          ((this.invoice?.tenant_id === trustTenant.id ||
            !this.invoice?.tenant_id ||
            this.invoice?.is_internally_funded) &&
            this.invoice.is_contingency_funded)
        ) {
          this.fundingSources.push({
            id: trustTenant?.id ?? null,
            is_internally_funded: 1,
            is_contingency_funded: 1,
            is_gencon_funded: 0,
            name: 'UHAT: Contingency Fee',
          });
        }
        if (
          this.invoice?.change_order_id ||
          ((this.invoice?.tenant_id === trustTenant.id ||
            !this.invoice?.tenant_id ||
            this.invoice?.is_internally_funded) &&
            this.invoice.is_gencon_funded)
        ) {
          this.fundingSources.push({
            id: trustTenant?.id ?? null,
            is_internally_funded: 1,
            is_contingency_funded: 0,
            is_gencon_funded: 1,
            name: 'UHAT: General Conditions Fee',
          });
        }
      }

      if (
        this.invoice.tenant_id === null &&
        this.invoice.is_internally_funded === null &&
        this.invoice.is_contingency_funded === null &&
        this.invoice.is_gencon_funded === null
      ) {
        if (this.invoice.change_order_funding_source_tenant_id) {
          this.invoice.tenant_id = this.invoice.change_order_funding_source_tenant_id;
        } else if (this.invoice.change_order_funding_source_name) {
          this.invoice.is_internally_funded = true;
        }
        if (this.invoice.change_order_funding_source_tenant_type_id === 3) {
          this.invoice.is_internally_funded = true;
        }
        if (this.invoice.change_order_funding_source_fee_type_id === 3) {
          this.invoice.is_contingency_funded = true;
        } else if (this.invoice.change_order_funding_source_fee_type_id === 5) {
          this.invoice.is_gencon_funded = true;
        }
      }
      this.fundingSources.forEach((fs, i) => {
        if (
          ((+fs.id === +this.invoice.tenant_id || (+fs.is_internally_funded && !this.invoice.tenant_id)) &&
            +fs.is_internally_funded === +this.invoice.is_internally_funded &&
            +fs.is_contingency_funded === +this.invoice.is_contingency_funded &&
            +fs.is_gencon_funded === +this.invoice.is_gencon_funded) ||
          (this.arf?.id && fs.is_internally_funded)
        ) {
          this.invoiceFormGroup.get('funding_source').setValue(i);
        }
      });
      if (this.isReviewItem && !this.isFirstReviewer) {
        const invoiceFiles =
          this.accessoryData?.reviewFiles ||
          (await this.taskService.getLatestTaskActivityNote(this.invoice.approval_task_id));
        this.invoiceFiles = (invoiceFiles?.length && invoiceFiles) || this.invoice?.files;
      } else {
        this.invoiceFiles = this.invoice?.files;
      }

      const arfFilter: APIFilter[] = [{ type: 'field', field: 'invoice_id', value: this.invoice.id, match: 'exact' }];
      this.arfInvoiceAmounts =
        (await this.arfService.getArfInvoiceAmounts(arfFilter, this.arfService.arfInvoiceAmountFields).toPromise()) ||
        [];

      const products = this.arf?.products || this.quote?.project_products;
      const arfInvoiceData = this.arfService.getArfInvoiceAmountsAndBudgets(products, this.arfInvoiceAmounts);
      if (
        !this.arf &&
        this.currentWorkspace === Workspace.Construction &&
        !arfInvoiceData.arfInvoiceAmounts?.length &&
        this.isReviewItem
      ) {
        arfInvoiceData.arfInvoiceAmounts.push({
          index: this.arfInvoiceAmounts.length,
          amount: null,
          sub_cost_code_budget_id: null,
        });

        arfInvoiceData.subCostCodeBudgets.push({});
      }

      this.subCostCodeBudgets = arfInvoiceData.subCostCodeBudgets;
      this.arfInvoiceAmounts = arfInvoiceData.arfInvoiceAmounts;
      this.initialArfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
    }
    this.loading = false;
  }

  public get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  public get eWorkspace() {
    return Workspace;
  }

  public get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.currentWorkspace);
  }

  get arfInvoiceTotal(): string {
    return (sumBy(this.arfInvoiceAmounts || [], 'amount') || 0).toFixed(2);
  }

  get invoiceTotalDoesNotMatchArfTotal(): boolean {
    return (
      Number((Number(this.invoice?.total || 0) - Number(this.invoice?.retainage || 0)).toFixed(2)) !==
      Number(this.arfInvoiceTotal || 0)
    );
  }

  get subCostCodesSelected(): boolean {
    return !this.arfInvoiceAmounts?.length || !this.arfInvoiceAmounts.find((a) => !a.sub_cost_code_budget_id);
  }

  public get usedFeeBudget() {
    const selectedFundingSource =
      this.fundingSources?.length > Number(this.fundingSource) + 1 ? this.fundingSources[this.fundingSource] : null;
    if (selectedFundingSource?.is_contingency_funded) {
      return +this.contingencyBudget || 0;
    } else if (selectedFundingSource?.is_gencon_funded) {
      return +this.genConBudget || 0;
    } else {
      return 0;
    }
  }

  public get usedFeeTotal() {
    const selectedFundingSource =
      this.fundingSources?.length > this.fundingSource + 1 ? this.fundingSources[this.fundingSource] : null;
    if (selectedFundingSource?.is_contingency_funded) {
      return +this.contingencyUsed || 0;
    } else if (selectedFundingSource?.is_gencon_funded) {
      return +this.genConUsed || 0;
    } else {
      return 0;
    }
  }

  public get usedFeePercentage() {
    return (+this.usedFeeBudget || 0) === 0 ? 0 : (+this.usedFeeTotal || 0) / (+this.usedFeeBudget || 0);
  }

  public get remainingFeeTotal() {
    return (+this.usedFeeBudget || 0) - (+this.usedFeeTotal || 0);
  }

  public get timeframeId() {
    return this.invoiceFormGroup.get('timeframe_id').value;
  }

  public get fundingSource() {
    return this.invoiceFormGroup.get('funding_source').value;
  }

  public get fiscalYear() {
    return this.invoiceFormGroup.get('fiscal_year').value;
  }

  public get invoiceDate(): string {
    return moment(this.invoice?.invoice_date).format('ll');
  }

  public get invoiceEndDate(): string {
    return this.invoice?.invoice_end_date && moment(this.invoice.invoice_end_date).format('ll');
  }

  public get submittedDate(): string {
    return moment(this.invoice?.created_datetime).format('lll');
  }

  public get invoiceFor(): string {
    if (this.invoice?.is_retainage) {
      return 'Final Retainage Invoice';
    } else if (this.invoice?.change_order_id) {
      return `CO #${this.invoice?.change_order_local_index}${
        this.invoice?.change_order_short_description ? ' - ' : ''
      }${this.invoice?.change_order_short_description || ''}`;
    } else {
      return 'Original Bid';
    }
  }

  public get isNotOnProjectInvoiceUrl(): boolean {
    return `/projects/${this.invoice?.project_id}/invoices` !== this.router.url;
  }

  public viewChangeOrder(changeOrderId: number): void {
    this.dialog.open(ViewChangeOrderDialogComponent, {
      width: '480px',
      disableClose: true,
      data: { changeOrderId },
    });
  }

  public async updateApprovalStatus(newStatus: TaskReviewStatus): Promise<void> {
    this.approvalStatus = newStatus;
    const isRejection = this.approvalStatus === TaskReviewStatus.Rejected;

    const { isValid, message } = this.isValid();
    if (!isValid) {
      this.approvalStatus = null;
      this.snackbar.open(message);
      return;
    }

    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: isRejection ? `Reject` : 'Approve',
        headerText: isRejection ? 'Reason for Rejection' : 'Approval Comment (Optional)',
        descriptionText: isRejection
          ? 'Please give a reason for your rejection below.'
          : 'You may add an optional comment to accompany your approval.',
        userInput: {
          placeholder: isRejection ? 'Rejection Reason' : 'Approval Comment (Optional)',
          required: isRejection,
        },
        confirmationButtonText: isRejection ? 'Submit Rejection' : 'Submit Approval',
      })
      .toPromise();

    const submitApproval = typeof res === 'string';
    this.approvalComment = res || '';
    if (!submitApproval) {
      return;
    }

    if (isRejection) {
      this.invoiceFormGroup.get('timeframe_id').setValidators(null);
      this.invoiceFormGroup.get('funding_source').setValidators(null);
      this.invoiceFormGroup.get('fiscal_year').setValidators(null);
      this.invoiceFormGroup.get('timeframe_id').updateValueAndValidity();
      this.invoiceFormGroup.get('funding_source').updateValueAndValidity();
      this.invoiceFormGroup.get('fiscal_year').updateValueAndValidity();
    } else {
      this.invoiceFormGroup.get('timeframe_id').setValidators([Validators.required]);
      this.invoiceFormGroup.get('funding_source').setValidators([Validators.required]);
      this.invoiceFormGroup.get('fiscal_year').setValidators([Validators.required]);
      this.invoiceFormGroup.get('timeframe_id').updateValueAndValidity();
      this.invoiceFormGroup.get('funding_source').updateValueAndValidity();
      this.invoiceFormGroup.get('fiscal_year').updateValueAndValidity();
    }

    if (newStatus === TaskReviewStatus.Rejected) {
      this.modalService
        .openConfirmationChoiceDialog({
          title: 'Invoice',
          heading: 'Reject Invoice',
          subHeading:
            'You are about to reject this review. You can either go ahead and reject the invoice and send this reason to the supplier (if applicable) OR assign this task to Project Manager for review of this rejection.',
          option1: { text: 'Reject Invoice', value: 1 },
          option2: { text: 'Assign Task to PM', value: 2 },
        })
        .subscribe((res) => {
          if (res) {
            this.submitApproval(res);
          }
        });
    } else {
      this.submitApproval();
    }
  }

  async submitApproval(rejectOption = null) {
    const { isValid, message } = this.isValid();
    if (!isValid) {
      this.snackbar.open(message);
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Updating Invoice..');

    const selectedFundingSource = this.fundingSources[this.fundingSource];

    const invoiceToUpdate: Invoice = {
      timeframe_id: this.timeframeId,
      is_internally_funded: selectedFundingSource?.is_internally_funded ?? null,
      is_contingency_funded: selectedFundingSource?.is_contingency_funded ?? null,
      is_gencon_funded: selectedFundingSource?.is_gencon_funded ?? null,
      tenant_id: selectedFundingSource?.id ?? null,
      fiscal_year: this.fiscalYear ?? null,
    };

    const isLastReviewer =
      this.accessoryData?.reviewChain[this.accessoryData.reviewChain.length - 1]?.id ===
      this.authService.currentUser.id;

    // Sets the correct status for the invoice
    if (this.approvalStatus !== TaskReviewStatus.Approved && rejectOption === 1) {
      invoiceToUpdate.status_id = InvoiceStatus.Rejected;
      invoiceToUpdate.review_comment = this.approvalComment;
    } else if (isLastReviewer && this.approvalStatus === TaskReviewStatus.Approved) {
      invoiceToUpdate.status_id = InvoiceStatus.Approved;
    } else if (this.invoice.status_id === InvoiceStatus.New) {
      invoiceToUpdate.status_id = InvoiceStatus.Received;
    }

    await this.projectService.updateInvoice(this.invoiceId, invoiceToUpdate).toPromise();

    for (const a of this.arfInvoiceAmounts || []) {
      if (a.id) {
        if (a.update_required) {
          const id = a.id;
          delete a.id;
          delete a.update_required;
          delete a.invoice_id;
          delete a.sub_cost_code_budget;
          await this.arfService.updateArfInvoiceAmount(id, a).toPromise();
        }
      } else if (a.amount) {
        delete a.index;
        a.invoice_id = this.invoiceId;
        await this.arfService.createArfInvoiceAmount(a).toPromise();
      }
    }

    for (const a of this.arfInvoiceAmountsToDelete) {
      await this.arfService.deleteArfInvoiceAmount(a.id).toPromise();
    }

    this.progressIndicatorService.close();
    this.dialogRef.close({
      approvalStatus: this.approvalStatus,
      approvalComment: this.approvalComment,
      invoiceStatusId: invoiceToUpdate.status_id,
      rejectOption,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  public isValid(): { isValid: boolean; message: string } {
    let isValid = true;
    let message = '';
    let needsComma = false;
    let fieldCount = 0;

    if (!this.timeframeId && this.approvalStatus !== TaskReviewStatus.Rejected) {
      message += ' Funding Source';
      isValid = false;
      needsComma = true;
      fieldCount++;
    }

    if (!this.fundingSource && this.fundingSource !== 0 && this.approvalStatus !== TaskReviewStatus.Rejected) {
      message += needsComma ? ', Invoice Tenant' : ' Invoice Tenant';
      isValid = false;
      needsComma = true;
      fieldCount++;
    }

    if (
      this.approvalStatus !== TaskReviewStatus.Rejected &&
      (this.invoiceTotalDoesNotMatchArfTotal || !this.subCostCodesSelected)
    ) {
      message += `${needsComma ? ',' : ''} Cost Codes`;
      isValid = false;
      fieldCount++;
    }

    message = `Field${fieldCount > 1 ? 's' : ''} required: ${message}`;
    return { isValid, message };
  }

  public refreshViewDialog(evt) {
    this.data = evt;
    this.previousView = evt.previousView;
    this.load();
  }

  public async viewTask(taskId: number): Promise<void> {
    if (taskId) {
      await this.modalService.openViewTaskModal(taskId).toPromise();
    }
  }

  public async goToInvoices(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading...');
    // this will force a cache bust if the component is cached for certain situations
    await this.router.navigate(['']);
    void this.router.navigateByUrl(`/projects/${this.invoice?.project_id || '#'}/invoices`);
    this.cancel();
  }
}
