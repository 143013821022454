import { Injectable } from '@angular/core';

import { io } from 'socket.io-client';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor() {}

  public socket;
  socketHost: string = environment.socketHost;

  getSocket() {
    if (this.socket) {
      return this.socket;
    } else {
      const token = localStorage.getItem('auth_token');
      if (token) {
        const socket = io(this.socketHost, { reconnection: true, extraHeaders: { Authorization: `Bearer ${token}` } });
        socket
          .on('connect_error', (error) => {
            console.warn('socket connect_error:', error.message);
          })
          .on('unauthorized', (error) => {
            console.warn('socket unauthorized:', error.message);
          })
          .on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
              // the disconnection was initiated by the server, you need to reconnect manually
              socket.connect();
            }
            // else the socket will automatically try to reconnect
          });
        this.socket = socket;
        return this.socket;
      }
    }
  }
}
