import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LocationService } from 'src/app/services';

import { Department } from 'src/app/types';

@Component({
  selector: 'app-tenant-select-dialog',
  templateUrl: './tenant-select-dialog.component.html',
  styleUrls: ['./tenant-select-dialog.component.scss'],
})
export class TenantSelectDialogComponent implements OnInit {
  selectedDepartments: Department[] = [];

  allDepartments: Department[] = [];

  searchInput: string;

  // I had to add this so the app would build -Austin
  processing = false;

  constructor(
    private locationService: LocationService,
    public dialogRef: MatDialogRef<TenantSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.locationService.getDepartments(['id', 'name']).subscribe((result) => {
      // If filter companies was input from the data object then we want to remove these entries from the array returned in result
      if (this.data.filterDepartments && result) {
        result = result.filter((el) => this.data.filterDepartments.find((dept) => dept.id === el.id) == null);
      }
      this.allDepartments = result || [];
    });
  }

  submit() {
    this.dialogRef.close(this.selectedDepartments);
  }

  close() {
    this.dialogRef.close(false);
  }

  getCompanySelectionNumberText(): string {
    const num = this.selectedDepartments.length || 0;
    if (num === 0) {
      return 'No Departments Selected';
    } else if (num === 1) {
      return this.selectedDepartments[0].name + ' Selected';
    } else {
      return this.selectedDepartments.length + ' Selected';
    }
  }

  public departmentIsSelected(company: Department): boolean {
    return this.selectedDepartments.find((u) => u.id === company.id) != null;
  }

  public toggleDepartmentSelection(department: Department) {
    const selectedDepartment = this.selectedDepartments.find((u) => u.id === department.id);
    if (selectedDepartment != null) {
      this.selectedDepartments = this.selectedDepartments.filter((u) => u.id !== selectedDepartment.id);
    } else {
      this.selectedDepartments.push(department);
    }
  }
}
