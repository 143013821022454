import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleErrorService } from 'src/app/services';
import { ServiceResponse, Task } from 'src/app/types';
import { KeyControlsBridge } from '../types/key-controls-bridge';
import { KeyControlsHistory } from '../types/key-controls-history';

@Injectable({
  providedIn: 'root',
})
export class KeyControlsService {
  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {}

  public keyControlCountUpdated = new EventEmitter<Task>();
  host: string = environment.serviceHost;
  keyControlsBridgeUrl = `${this.host}/api/v1/key-controls`;
  keyControlsHistoryUrl = `${this.host}/api/v1/key-controls/history`;
  keyControlsFields =
    'id,task_id,key_control_template_id,task{id,accessory_data,project_id},key_control_template{id,default_desc,abbreviated_name,sequence,key_control_name,display_data},task_project_id,is_disabled';
  keyControlHistoryFields =
    'id,event_id,event,key_control_bridge_id,created_by_id,created_by{first_name,last_name},created_datetime';

  // grabs all relevant key controls for this project
  getKeyControlsForProject(projectId: number, fields?: string[]): Observable<KeyControlsBridge[]> {
    const queryFields = fields ? fields.join(',') : this.keyControlsFields;
    return this.http.get(`${this.keyControlsBridgeUrl}?fields=${queryFields}&filter=task_project_id=${projectId}`).pipe(
      map(({ data: { key_controls_bridge } }: ServiceResponse): KeyControlsBridge[] => {
        return key_controls_bridge;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // grabs all relevant key controls for a list of projects
  getKeyControlsForProjects(projectIds: number[], fields?: string[]): Observable<KeyControlsBridge[]> {
    const queryFields = fields ? fields.join(',') : this.keyControlsFields;
    return this.http
      .get(`${this.keyControlsBridgeUrl}?fields=${queryFields}&filter=task_project_id=${projectIds.join('^')}`)
      .pipe(
        map(({ data: { key_controls_bridge } }: ServiceResponse): KeyControlsBridge[] => {
          return key_controls_bridge;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // this should always return only 1 key control, but allow functionality for more in future
  getKeyControlsForTask(taskId: number, fields?: string[]): Observable<KeyControlsBridge> {
    const queryFields = fields ? fields.join(',') : this.keyControlsFields;
    return this.http.get(`${this.keyControlsBridgeUrl}?fields=${queryFields}&filter=task_id=${taskId}`).pipe(
      map(({ data: { key_controls_bridge } }: ServiceResponse): KeyControlsBridge => {
        return key_controls_bridge;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // toggles a specific key control entry
  toggleKeyControlBridgeEntry(keyControlBridgeId: number): Observable<number> {
    return this.http.put(`${this.keyControlsBridgeUrl}/toggle-bridge/${keyControlBridgeId}`, null).pipe(
      map(({ statusCode }: ServiceResponse): number => {
        return statusCode;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // grabs the history for a specific key control
  getKeyControlHistory(keyControlId: number, fields?: string[]): Observable<KeyControlsHistory[]> {
    const queryFields = fields ? fields.join(',') : this.keyControlHistoryFields;
    return this.http
      .get(`${this.keyControlsHistoryUrl}?fields=${queryFields}&filter=key_control_bridge_id=${keyControlId}`)
      .pipe(
        map(({ data: { key_control_history } }: ServiceResponse): KeyControlsHistory[] => key_control_history),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // grabs the history for multiple key controls
  getKeyControlsHistories(keyControlIds: number[], fields?: string[]): Observable<KeyControlsHistory[]> {
    const queryFields = fields ? fields.join(',') : this.keyControlHistoryFields;
    return this.http
      .get(
        `${this.keyControlsHistoryUrl}?fields=${queryFields}&filter=key_control_bridge_id=${keyControlIds.join('^')}`
      )
      .pipe(
        map(({ data: { key_control_history } }: ServiceResponse): KeyControlsHistory[] => key_control_history),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // returns the current approval state of the key control entry
  // TODO parse the accessory data
  getBridgeEntryStatus(taskAccessoryData: string): boolean {
    return false;
  }
}
