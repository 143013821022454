import { Pipe, PipeTransform } from '@angular/core';

/**
 * String Shrink will take a character limit as a parameter and return a shrunk string
 * EX:  passing in   'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' with parameter of 5 characters
 *      will return  'AAAAA..'
 */
@Pipe({
  name: 'stringShrink',
})
export class StringShrinkPipe implements PipeTransform {
  transform(value: string, characterLimit: number): any {
    if (value && value.length > characterLimit) {
      return value.substring(0, characterLimit) + '..';
    } else {
      return value;
    }
  }
}
