import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { unionBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  NewAccountModalComponent,
  NewGuestAccountModalComponent,
} from 'src/app/components';
import { COMPARE, UserType } from 'src/app/enums';
import { AuthService, FilterUsersByProjectService, ProgressIndicatorService, UserService } from 'src/app/services';
import { APIFilter, User } from 'src/app/types';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.scss'],
})
export class UserSelectModalComponent implements OnInit {
  @ViewChild('search', { static: true }) input: MatInput;

  private _companyIdSearchString: string;
  private _fetchMorePageHeightScrolled = 0.5;
  private _hasProject = false;
  private _limit = 100;
  private _offset = new BehaviorSubject<User[]>([]);
  private _tenantIdSearchString: string;
  private _theEnd = false;
  private _userFields = [
    'id',
    'first_name',
    'last_name',
    'email',
    'user_type_id',
    'department_name',
    'company_name',
    'company_id',
    'is_login_enabled',
    'title',
    'is_enabled',
  ];
  private _userFilter: APIFilter[] = [];
  public searchInputField = new FormControl('');
  // component properties
  private _includeGuestUsers = true;
  public count = 0;
  public createUser = { title: 'Guest', guestUser: true };
  public cursor = null;
  public dialogTitle = '';
  public checkIfSelected: boolean;
  private isFetching = false;
  public isTypingSearchTerm = false;
  public maxSelected: number;
  // public searchUserTypeId: UserType = 0;
  public selectedSearchUserId = new FormControl(0);

  // If set to true (which is set when pre selected users are sent in with the injected "data") then deselecting all users will allow
  // the dialog to be submitted with no selected users (empty array). Otherwise, at least 1 user is required before the submit button
  // is enabled.
  public selectStyleIsAddOnly = false;
  public selectedUsers: User[] = [];
  public deSelectedUsers: User[] = [];
  public existingUsers: User[] = [];
  public allUsers: User[] = [];
  public loadingUsers = true;
  public onlyShowAllowedUserTypes = false;
  public companyId: number;
  public preDeSelectedUserIds: number[] = [];
  public preDeSelectedUsers: User[] = [];
  public preSelectedUsers: User[] = [];
  public preSelectedUserIds: number[] = [];
  public processing = false;
  public saveOnClose = true;
  public userType = UserType;
  public userTypes = [
    { value: UserType.Everyone, name: 'Everyone' },
    { value: UserType.Staff, name: 'UHAT/1CALL' },
    { value: UserType.Tenant, name: 'Tenants' },
    { value: UserType.Vendor, name: 'Suppliers' },
  ];
  public userStream: Observable<User[]> = this._offset.asObservable();
  public excludeUserType: UserType;
  public minimumRemovalPermission = true;
  public includePreSelectedUsers = false;

  // NOTE: the data.title currently specifically refers to the type of user you are selecting. At the moment, the two uses are 'Add Followers' and 'Add Attendees'.
  // This gets displayed on both the title and the submit button, so make sure this isn't a generic title.
  constructor(
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private _progressIndicatorService: ProgressIndicatorService,
    private _userFilterService: FilterUsersByProjectService,
    private _userService: UserService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    this.onlyShowAllowedUserTypes = this.data?.onlyShowAllowedUserTypes;
    this.companyId = this.data?.companyId;
    if (this.onlyShowAllowedUserTypes) {
      this.selectedSearchUserId.setValue(this.data.allowedUserTypeIds[0]);
    }
    this._subscribeToSearch();
    this._focusOnSearchInput();
    this._subscribeToUserType();
    if (this?.data) {
      if (this.data?.excludeVendors) {
        const defaultUserType =
          this.userTypes?.find((userType) => userType.value === +this.data.defaultUserTypeId) ||
          this.userTypes?.find((userType) => userType.value === UserType.Staff);

        if (defaultUserType.value === UserType.Everyone) {
          // because we excluding vendors but need everyone as default, it can't be vendors nor everyone
          this.userTypes = this.userTypes.filter((userType) => userType.value !== UserType.Vendor);
          this.excludeUserType = UserType.Vendor;
        } else {
          // because we excluding vendors but don't have default everyone, it can't be vendors nor everyone
          this.userTypes = this.userTypes.filter(
            (userType) => userType.value !== UserType.Everyone && userType.value !== UserType.Vendor
          );
        }
        // then we set the default value
        this.selectedSearchUserId.setValue(defaultUserType.value);
      }
      this._hasProject = this.data.hasProject ?? false;
      this._includeGuestUsers =
        typeof this.data?.includeGuestUsers === 'boolean' ? this.data.includeGuestUsers : this._includeGuestUsers;
      this.createUser = this.data?.createUser || this.createUser;
      this.dialogTitle = this?.data?.title || '';
      this.maxSelected = this.data?.maxSelected || null;
      this.selectStyleIsAddOnly = this.data?.preSelectedUsers == null;
      this.saveOnClose = this.data.saveOnClose ?? true;
      this.checkIfSelected = this.data?.checkIfSelected ?? false;
      this.includePreSelectedUsers = !!this.data?.includePreSelectedUsers;
      // if its set to true or false, it will pick up what was set, if not, then it will just use the default, true value
      this.minimumRemovalPermission = this.data?.minimumRemovalPermission ?? this.minimumRemovalPermission;
    }
    this._userFilter = this.data?.userFilter ? this.data.userFilter : this._baseFilter();
    this.preSelectedUserIds = this.data?.preSelectedUsers?.map((u: User) => +u.id);
    // this get the preselected users
    const dbPreSelectedUsers = await this._userService
      .getUsers(
        [{ type: 'field', field: 'id', value: this.preSelectedUserIds.join('^') }],
        this._userFields,
        null,
        this._includeGuestUsers
      )
      .toPromise();

    this.preSelectedUsers = dbPreSelectedUsers?.map((user) => ({
      ...user,
      canNotDeselect: this.data?.preSelectedUsers?.find((u) => u.id === user.id)?.canNotDeselect ?? false,
      is_selected: this.checkIfSelected && this.data?.preSelectedUsers.find((u) => u.id === user.id)?.is_selected,
    }));
    await this._loadIds();
    this._refresh();
  }

  public canNotDeselectPreselectedUser(preSelectedUser): boolean {
    // want the opposite of the action to disable
    if (
      !preSelectedUser.canNotDeselect &&
      !this.notAllowed &&
      (this.minimumRemovalPermission || +this._authService?.currentUser?.id === +preSelectedUser?.id)
    ) {
      return false;
    }

    // default
    return true;
  }

  get notAllowed(): boolean {
    return (
      !!(!this._authService?.isStaffOnAnyModule && this.maxSelected && this.selectedUsers.length >= this.maxSelected) ||
      false
    );
  }

  get searchInput(): string {
    return this.searchInputField.value || '';
  }

  get searchUserTypeId(): UserType {
    return this.selectedSearchUserId.value || 0;
  }

  private _addToDeSelectedUser(user: User) {
    if (!this.deSelectedUsers.find((deSelectedUser: User) => deSelectedUser.id === user.id)) {
      this.deSelectedUsers = [...this.deSelectedUsers, user];
    }
  }

  private _addToSelectedUser(user: User) {
    if (!this.selectedUsers.find((selectedUser: User) => selectedUser.id === user.id)) {
      this.selectedUsers = [...this.selectedUsers, user];
    }
  }

  private _filterFromDeSelectedUser(user: User) {
    this.deSelectedUsers = this.deSelectedUsers.filter((deSelectedUser: User) => deSelectedUser.id !== user.id);
  }

  private _filterFromSelectedUser(user: User) {
    this.selectedUsers = this.selectedUsers.filter((selectedUser: User) => selectedUser.id !== user.id);
  }

  private _focusOnSearchInput() {
    this.input.focus();
  }

  // Data Functions -------------------------------
  private _fetchUsers() {
    return this._userService
      .getUsersAndCursor(this._userFilter, this._userFields, this._includeGuestUsers, this.cursor, this._limit)
      .pipe(
        tap(({ count, cursor }: { count: number; cursor: string; users: User[] }) => {
          this.cursor = cursor;
          // only set the count once
          if (!this.count && count > 0) {
            this.count = count;
          }

          if (!cursor || cursor === 'null') {
            // loaded every things
            this._theEnd = true;
          }
        }),
        map(({ users }: { count: number; cursor: string; users: User[] }) => users)
      );
  }

  private async _getUserStream() {
    this.isFetching = true;
    const previousUsers = this._offset.value;
    const newUsers = await this._fetchUsers().toPromise();
    const combinedUsers = unionBy(previousUsers, newUsers, 'id');
    this._offset.next(combinedUsers);
    this.isFetching = false;
  }

  private async _loadIds() {
    if (this._hasProject) {
      this._openProgress('Loading data...');
      const companyIds = this.companyId ? [this.companyId] : await this._userFilterService.getProjectCompanyIds();
      // remove any null values, we ignore those
      this._companyIdSearchString = companyIds?.filter((companyId) => companyId).join('^');
      const tenantRepIds = (await this._userFilterService.getTenantRepIds())?.filter((tenantRepId) => tenantRepId);

      const tenantContactIds = (await this._userFilterService.getTenantContactIds())?.filter(
        (tenantContactId) => tenantContactId
      );

      this._tenantIdSearchString = [...tenantRepIds, ...tenantContactIds].join('^');
    }
  }

  public createUserType() {
    if (this.createUser.guestUser) {
      this._createGuest();
    } else {
      this._createPermanent();
    }
  }

  private _createGuest() {
    const dialogRef = this._dialog
      .open(NewGuestAccountModalComponent, {
        width: '540px',
        disableClose: true,
        data: {
          defaultUserTypeId: this.searchUserTypeId,
          allowedUserTypeIds: this.data ? this.data.allowedUserTypeIds : null,
          searchInput: this.searchInput,
        },
      })
      .afterClosed()
      .subscribe((createdUser) => {
        if (createdUser?.id) {
          // order matters here
          this._refresh();
          this._addToSelectedUser(createdUser);
        }
      });
  }

  private _createPermanent() {
    const dialogRef = this._dialog
      .open(NewAccountModalComponent, {
        width: '540px',
        disableClose: true,
        data: {
          userType: { id: this.searchUserTypeId },
          userInfo: this.searchInput,
        },
      })
      .afterClosed()
      .subscribe((createdUser) => {
        if (createdUser?.id) {
          // order matters here
          this._refresh();
          this._addToSelectedUser(createdUser);
        }
      });
  }

  // component functionality -------------------------------

  private _openProgress(action: string) {
    // this prevents the loading if a user is typying
    if (!this.isTypingSearchTerm) {
      this.loadingUsers = true;
      this._progressIndicatorService.openAwaitIndicatorModal();
      this._progressIndicatorService.updateStatus(action);
    }
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
    this.loadingUsers = false;
    this.isTypingSearchTerm = false;
  }

  private _baseFilter(): APIFilter[] {
    // if we don't have any preSelected users, just return
    if (!this.preSelectedUserIds?.length) {
      return this.searchUserTypeId
        ? [
            { type: 'field', field: 'user_type_id', value: `${this.searchUserTypeId}` },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
          ]
        : [{ type: 'field', field: 'is_enabled', value: '1' }];
    }

    // otherwise exclude the ids
    const excludePreselectedIdsFilter: APIFilter[] = this.preSelectedUserIds.reduce(
      (filterIds: APIFilter[], id: number) => {
        const filter = { type: 'field', field: 'id', value: `${id}`, match: COMPARE.NOT_EQUAL };
        if (filterIds.length === 0) {
          return [filter];
        }
        return [...filterIds, { type: 'operator', value: 'AND' }, filter];
      },
      []
    );

    return this.searchUserTypeId
      ? [
          ...excludePreselectedIdsFilter,
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'user_type_id', value: `${this.searchUserTypeId}` },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ]
      : [
          ...excludePreselectedIdsFilter,
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ];
  }

  private _setUserFilter() {
    if (this.companyId) {
      // if it has a project, filter by project otherwise, normal stuff
      this._userFilter = this._hasProject
        ? [
            ...this._baseFilter(),
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'company_id', value: `${this._companyIdSearchString}` },
          ]
        : this._baseFilter();
    } else if (this.searchUserTypeId === UserType.Tenant) {
      // if it has a project, filter by project otherwise, normal stuff
      this._userFilter = this._hasProject
        ? [
            ...this._baseFilter(),
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'id', value: `${this._tenantIdSearchString}` },
          ]
        : this._baseFilter();
    } else {
      // for staff and everyone
      this._userFilter = this._baseFilter();
    }
  }

  private async _reset() {
    this.cursor = null;
    this.count = 0;
    this._limit = 100;
    this._theEnd = false;
    this.isTypingSearchTerm = false;
    this._offset.next([]);

    // if everyone is chosen, the searchUserTypeId is null, so not filter
    this._setUserFilter();
    this._openProgress('Roll call...');
    await this._getUserStream();
    this._closeProgress();
  }

  private async _searchUsers(value: string) {
    let baseFilter = this._baseFilter();
    if (this.companyId) {
      const companyFilter = [
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'company_id', value: `${this._companyIdSearchString}` },
      ];
      baseFilter = [...baseFilter, ...companyFilter];
    }

    const userValueFilter = [
      { type: 'field', field: 'email', value, match: COMPARE.ANY },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'full_name', value, match: COMPARE.ANY },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'company_name', value, match: COMPARE.ANY },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'department_name', value, match: COMPARE.ANY },
    ];

    // Whether we are typing or changing user type
    this._userFilter = baseFilter.length
      ? [
          ...baseFilter,
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          ...userValueFilter,
          { type: 'operator', value: ')' },
        ]
      : [...userValueFilter];

    const searchResults: User[] = await this._userService.searchUsers(this._userFilter, this._userFields).toPromise();
    this._offset.next(searchResults);
  }

  private _selectedUsers() {
    if (this.saveOnClose) {
      const existingUserIds = this.existingUsers.map((existingUser: User) => existingUser.id);
      return this.selectedUsers.filter((selectedUser: User) => !existingUserIds.includes(selectedUser.id));
    }
    return this.selectedUsers;
  }

  private _subscribeToSearch() {
    this.searchInputField.valueChanges
      .pipe(
        tap(() => {
          this.isTypingSearchTerm = true;
          this.cursor = null;
        }),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(async (searchTerm) => {
        if (searchTerm?.trim()) {
          await this._searchUsers(searchTerm.toLowerCase());
          this.isTypingSearchTerm = false;
        } else if (!searchTerm?.trim() && this.isTypingSearchTerm) {
          // Happens when you clear by manually deleting what you typed in
          this._reset();
        } else {
          this.isTypingSearchTerm = false;
        }
      });
  }

  private _subscribeToUserType() {
    this.selectedSearchUserId.valueChanges.subscribe(async () => {
      if (this.searchInput) {
        this._openProgress('Loading User Accounts');
        await this._searchUsers(this.searchInput);
        this._closeProgress();
      } else {
        this.clearSearchInput();
        this._focusOnSearchInput();
      }
    });
  }

  public cantBeChanged(user: User): boolean {
    // this allows a tenant to un follow themselves
    if (user.id === this._authService.currentUser.id) {
      return false;
    }

    if (!this._authService.isStaffOnAnyModule) {
      return this.preSelectedUserIds.find((id: number) => id === user.id) != null;
    }
  }

  public async clearSearchInput() {
    this.searchInputField.setValue('');
    this._reset();
  }

  public submit() {
    this._dialogRef.close({
      selectedUsers: this.includePreSelectedUsers ? this.selectedUsers : this._selectedUsers(),
      deSelectedUsers: this.deSelectedUsers,
    });
  }

  public close() {
    this._dialogRef.close(false);
  }

  // Data Manipulation from User -------------------------------

  private _refresh() {
    // Same as preSelectedUsers
    if (this.checkIfSelected) {
      this.selectedUsers = this.preSelectedUsers.filter((user) => user.is_selected);
      this.existingUsers = this.preSelectedUsers.filter((user) => user.is_selected);
    } else {
      this.selectedUsers = this.preSelectedUsers;
      this.existingUsers = this.preSelectedUsers;
    }

    this._reset();
  }

  public async getNextBatch(evt) {
    if (!this.isFetching && !this._theEnd) {
      const scrollTop = evt.target.scrollTop;
      const scrollHeight = evt.target.scrollHeight;
      const offsetHeight = evt.target.offsetHeight;
      const scrollPosition = scrollTop + offsetHeight;
      const scrollTreshold = scrollHeight * this._fetchMorePageHeightScrolled;

      if (scrollPosition > scrollTreshold) {
        // increase the limit
        this._limit = this._limit * 2;

        this._getUserStream();
      }
    }
  }

  public getUserSelectionNumberText(): string {
    // if its one user, use the db info, otherwise, we care about the count
    const num = this.selectedUsers?.length || 0;
    if (num === 0) {
      return 'No One Selected';
    } else if (num === 1) {
      // coming from a new selection, have two options, has name or not
      if (this.selectedUsers[0]?.first_name && this.selectedUsers[0].last_name) {
        return `${this.selectedUsers[0].first_name} ${this.selectedUsers[0].last_name} is Selected`;
      }
      return '1 person is Selected';
    } else {
      return this.selectedUsers.length + ' People Selected';
    }
  }

  public toggleUserSelection(user: User) {
    this._focusOnSearchInput();
    // If not staff and max is passed, tenant can't add themselves
    if (this.notAllowed) {
      return;
    }

    const foundSelectedUser = this.selectedUsers.find((selectedUser: User) => selectedUser.id === user.id);

    const foundExistingUser = this.existingUsers.find((existingUser: User) => existingUser.id === user.id);

    // Two  scenarios, user is already a follower or not
    if (foundSelectedUser && foundExistingUser) {
      // exists in the db, and has a selection
      this._filterFromSelectedUser(user);
      this._addToDeSelectedUser(user);
    } else if (foundSelectedUser && !foundExistingUser) {
      // has a selection, but does not exist in db
      this._filterFromSelectedUser(user);
    } else if (!foundSelectedUser && foundExistingUser) {
      // Has no selection, but exists in db
      this._addToSelectedUser(user);
      this._filterFromDeSelectedUser(user);
    } else if (!foundSelectedUser && !foundExistingUser) {
      // does not exist in db and has not selection
      this._addToSelectedUser(user);
    }

    if (this.maxSelected && this.selectedUsers?.length > this.maxSelected) {
      const replacedUser = this.selectedUsers.shift();
      const replacedExistingUser = this.existingUsers.find((existingUser: User) => existingUser.id === replacedUser.id);
      if (replacedExistingUser) {
        this._addToDeSelectedUser(replacedUser);
      }
    }
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public userIsSelected(user: User): boolean {
    return !!this.selectedUsers.find((u) => u.id === user.id);
  }
}
