<div id="purchasing" class="container-fluid p-4">
  <div class="d-flex">
    <div class="d-flex flex-column mr-auto">
      <h1 class="primary mb-0">Purchasing</h1>
    </div>
  </div>

  <nav mat-tab-nav-bar class="mb-4">
    <a
      mat-tab-link
      *ngFor="let link of links"
      [routerLink]="link.route"
      [active]="routeUrl.includes(link.route)"
      [disabled]="link.disabled"
    >
      {{ link.text }}
    </a>
  </nav>
  <div id="arf-list-wrapper"><router-outlet></router-outlet></div>
</div>
