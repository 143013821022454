<nav
  #mainScreen
  (window:resize)="onResize($event)"
  data-test-id="project-nav"
  id="project-nav"
  class="project-nav d-flex flex-wrap align-items-center"
  [ngClass]="{
    'nav-top-banner': isClosedProject
  }"
>
  <ul *ngIf="divWidth > 800">
    <li
      *ngFor="let n of navLinks; let i = index"
      matRipple
      [ngClass]="{ selected: subcomponent === n.routerLink }"
      [routerLink]="['/projects/' + projectId + '/' + n.routerLink]"
    >
      <mat-icon>{{ n.icon }}</mat-icon>
      <div data-test-id="project-overview-item-button" class="d-inline-block align-middle">
        <span>
          {{ n.name }}
        </span>
      </div>
    </li>
  </ul>
  <ul *ngIf="divWidth < 801" class="full-width">
    <li [matMenuTriggerFor]="project_menu" matRipple class="full-width text-center">
      <mat-icon>menu</mat-icon>
      <span> Menu </span>
    </li>
    <mat-menu #project_menu="matMenu" xPosition="after" class="mx-5">
      <button
        *ngFor="let n of navLinks; let i = index"
        class="full-width"
        style="width: 300px !important"
        mat-menu-item
        [ngClass]="{ selected_vert: subcomponent === n.routerLink }"
        [routerLink]="['/projects/' + projectId + '/' + n.routerLink]"
      >
        <mat-icon class="ltblue">{{ n.icon }}</mat-icon>
        <span class="dkblue font-weight-semibold">{{ n.name }}</span>
      </button>
    </mat-menu>
  </ul>
</nav>
