import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { InvoiceStatus, ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus, UserType } from '../enums';
import { Invoice, Task, TaskAccessoryData, UhatFileReference } from '../types';
import { keyControlAuditCounts } from '../utils';
import { AuthService } from './auth.service';
import { DateService } from './date.service';
import { DisplayReviewersService } from './display-reviewers.service';
import { FileService } from './file.service';
import { KeyControlsService } from './key-controls.service';
import { ProductService } from './product.service';
import { ProjectEventService } from './project-event.service';
import { ProjectTaskService } from './project-task.service';
import { ProjectService } from './project.service';
import { ReviewRevisionService } from './review-revision.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateReviewerService {
  constructor(
    private authService: AuthService,
    private displayReviewersService: DisplayReviewersService,
    private eventService: ProjectEventService,
    private fileService: FileService,
    private keyControlsService: KeyControlsService,
    private productService: ProductService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private dateService: DateService,
    private reviewRevisionService: ReviewRevisionService
  ) {}

  public async createTaskApprovalActivity(
    task: Task,
    approvalStatus: TaskReviewStatus,
    comment: string,
    files?: UhatFileReference[],
    userId = null
  ) {
    const existingFiles = [];
    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
    const createEventResult = await this.eventService
      .createTaskApprovalEvent(task.id, approvalStatus, comment || '', accessoryData, userId)
      .toPromise();

    if (files?.length) {
      for (let file of files) {
        let fileExists = false;
        if (file.id || file.file_id) {
          const fileResult = await this.fileService.getIdAndName(file.file_id || file.id).toPromise();
          fileExists = !!fileResult;
        }
        if (!fileExists) {
          // we need to create each file if they aren't already linked
          file = await this.fileService.createFile(file, task.id, ResourceType.Task, file.name).toPromise();
        }
        // now link them to the note
        await this.fileService.linkFile(file.file_id || file.id, createEventResult.id, ResourceType.Event).toPromise();
        await this.fileService.linkFile(file.file_id || file.id, task.id, ResourceType.Task).toPromise();
        existingFiles.push({ id: file.file_id || file.id, name: file.name });
      }
    }
    await this.taskService.loadTaskActivityLog(task);

    return existingFiles;
  }

  public async reassignUser(
    task: Task,
    accessoryData: TaskAccessoryData,
    approvalStatus: TaskReviewStatus,
    comment: string = '',
    commentFiles: UhatFileReference[] = [],
    reviewFiles: UhatFileReference[] = []
  ) {
    const isReviewerAdmin = true;
    const reviewChain = accessoryData.reviewChain;

    // this used to be assignedUser, but could cause issues if manually reassigning before reviewing
    // refactoring to currentUser, to fix bug
    const currentUser = this.authService.getLoggedInUser().id;
    const createdById = accessoryData?.reviewCreator || task.created_by_id;
    const project =
      this.projectService.currentSelectedProject ||
      (accessoryData?.type === TaskAccessoryType.BidContract &&
        (await this.projectService.getProjectById(task.project_id, ['project_manager_id']).toPromise())) ||
      null;

    if (accessoryData && reviewChain) {
      const userIndex = reviewChain.findIndex((review) => review.id === currentUser);
      const nextInArray = reviewChain[userIndex + 1]?.id || createdById;
      const rejectionUser =
        (accessoryData?.type === TaskAccessoryType.Invoice && reviewChain[0].id) ||
        (accessoryData?.type === TaskAccessoryType.BidContract && project.project_manager_id) ||
        createdById;
      const assigned_user_id = [TaskReviewStatus.Approved, TaskReviewStatus.NoteMarkings].includes(approvalStatus)
        ? reviewChain.find((user) => user.id === currentUser)
          ? nextInArray
          : reviewChain[0].id
        : rejectionUser;

      if (reviewChain[userIndex]) {
        accessoryData.reviewChain[userIndex].status = approvalStatus;
      }
      const pendingUser = reviewChain.find((reviewer) => reviewer.status === TaskReviewStatus.Pending);
      if (isReviewerAdmin && pendingUser && pendingUser.id !== accessoryData.reviewChain[userIndex]?.id) {
        let index = 0;
        for (const reviewer of accessoryData?.reviewChain) {
          if (
            reviewer?.id !== accessoryData.reviewChain[userIndex].id &&
            reviewer.status === TaskReviewStatus.Pending &&
            index < userIndex
          ) {
            reviewer.status = approvalStatus;
            reviewer.date = moment().toDate();
            reviewer.comment = `<p>Reviewed by ${this.authService.currentUser?.first_name} ${this.authService.currentUser.last_name} on the behalf of ${reviewer?.first_name} ${reviewer?.last_name}</p>`;
            await this.createTaskApprovalActivity(task, approvalStatus, reviewer.comment, [], reviewer.id);
          }
          index++;
        }
      }
      accessoryData.reviewChain[userIndex].date = moment().toDate();
      accessoryData.reviewChain[userIndex].comment = comment;
      accessoryData.reviewChain[userIndex].files =
        commentFiles?.map((file) => ({
          id: file.file_id || file.id,
          name: file.name,
        })) || [];

      if (reviewFiles?.length) {
        accessoryData.reviewFiles = reviewFiles?.map((file) => ({
          id: file.file_id || file.id,
          name: file.name,
        }));
      }

      const reviewTurnAround = task?.review_turnaround ?? ((accessoryData?.type === TaskAccessoryType.Other && 1) || 0);
      await this.updateTask(
        {
          id: task.id,
          assigned_user_id,
          due_date: task?.due_date ? this.dateService.addWeekdays(reviewTurnAround).format('YYYY-MM-DD') : null,
        },
        accessoryData
      );
    }

    return accessoryData?.reviewChain || null;
  }

  public async resetReview(taskId, accessoryData, message: string) {
    let task: Task;
    let invoiceStatus = InvoiceStatus.Received;
    const taskFields = [
      'id',
      'accessory_data',
      'assigned_user_id',
      'assigned_user',
      'status_id',
      'due_date',
      'project_id',
    ];
    let invoiceFields = ['id', 'status_id', 'project_id'];

    const result: { task?: Task; invoice?: Invoice } = {};
    if (accessoryData?.type === TaskAccessoryType.Invoice) {
      if (this.authService.currentUser.user_type_id !== UserType.Vendor) {
        invoiceFields.push(`approval_task{${taskFields}}`);
      } else {
        invoiceFields = [...invoiceFields, ...['approval_task_id', 'approval_task_accessory_data']];
        invoiceStatus = InvoiceStatus.New;
      }
      const invoice = await this.projectService
        .resetInvoice(accessoryData?.parentId || accessoryData?.id, message, invoiceFields, invoiceStatus)
        .toPromise();
      result.invoice = invoice;
      task = invoice?.approval_task || {
        id: invoice.approval_task_id,
        accessory_data: invoice.approval_task_accessory_data,
        project_id: invoice.project_id,
      };
    } else if (accessoryData?.type === TaskAccessoryType.BidContract) {
      const bid =
        accessoryData?.parentId &&
        (await this.projectService
          .getBidById(accessoryData.parentId, ['bid_contract', 'contract_revision', 'contract_task_id'])
          .toPromise());
      if (bid.bid_contract?.id) {
        await this.projectService.reviseBidContract(bid.bid_contract.id).toPromise();
        await this.reviewRevisionService.bidContractRevision(bid, false, message);
      }
    } else {
      task = await this.taskService.resetTaskReviewers(taskId, message, taskFields).toPromise();
    }
    result.task = task;

    if (this.authService.currentUser.user_type_id !== UserType.Vendor && task) {
      await this.taskService.updateTask(task);
    }
    if (task) {
      this.taskService.taskSelectedEvent.emit({ task, navigate: false });
    }
    return result;
  }

  // Open Review functionality
  public async addReviewer(task: Task, approval, reviewChain) {
    const taskStatus = approval === TaskReviewStatus.Rejected ? 1 : null;

    reviewChain[reviewChain.length - 1].status = approval;
    reviewChain[reviewChain.length - 1].date = moment().toDate();

    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
    accessoryData.reviewChain = reviewChain;

    task.accessory_data = accessoryData && JSON.stringify(accessoryData);
    task.key_control_status_counts = keyControlAuditCounts([...reviewChain]);
    this.keyControlsService.keyControlCountUpdated.emit(task);

    const taskData: Task = {
      id: task.id,
      accessory_data: task.accessory_data,
    };

    if (taskStatus) {
      taskData.status_id = taskStatus;
    }

    await this.updateTask(taskData);
    await this.taskService.addFollowersToTask(task.id, [reviewChain[reviewChain.length - 1].id]);
  }

  public async resetOpenReview(task: Task, message) {
    await this.createResetNote(task.id, message);

    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
    accessoryData.reviewChain = [];

    task.key_control_status_counts = keyControlAuditCounts([]);
    this.keyControlsService.keyControlCountUpdated.emit(task);

    await this.updateTask({ id: task.id }, accessoryData);
  }

  public async undoOpenReview(taskId, accessoryData) {
    accessoryData?.reviewChain?.pop();
    await this.updateTask({ id: taskId }, accessoryData);

    return accessoryData;
  }

  public async reassignToPreviousReviewer(task: Task, accessoryData: TaskAccessoryData, userCurrentUser = true) {
    const reviewChain = accessoryData?.reviewChain;
    const currentReviewerLocation = reviewChain.findIndex((reviewer) =>
      userCurrentUser ? reviewer.id === this.authService.currentUser.id : reviewer.status === TaskReviewStatus.Pending
    );
    const previousReviewer = reviewChain[currentReviewerLocation - 1] || reviewChain[reviewChain.length - 1];
    if (previousReviewer) {
      previousReviewer.status = TaskReviewStatus.Pending;
      accessoryData.reviewChain = reviewChain;

      if (
        accessoryData?.type === TaskAccessoryType.Arf &&
        previousReviewer.id === reviewChain[reviewChain.length - 1]?.id
      ) {
        const quote = await this.productService.getQuoteById(accessoryData.parentId, ['arf_revision']).toPromise();
        await this.productService.updateQuote(quote.id, { arf_revision: quote.arf_revision + 1 }).toPromise();
      }

      if (accessoryData?.type === TaskAccessoryType.Invoice && previousReviewer.id === reviewChain[0].id) {
        const invoiceId = accessoryData.parentId || accessoryData.id;
        const invoice = await this.projectService.getInvoiceById(invoiceId, ['revision']).toPromise();
        await this.projectService.updateInvoice(invoiceId, { revision: invoice.revision + 1 }).toPromise();
      }

      if (
        accessoryData?.type === TaskAccessoryType.BidContract &&
        (previousReviewer.id === reviewChain[reviewChain.length - 1]?.id || previousReviewer.id === reviewChain[0].id)
      ) {
        const bid = await this.projectService.getBidById(accessoryData?.parentId, ['contract_revision']).toPromise();
        await this.projectService
          .updateBid(bid.id, { contract_revision: bid.contract_revision + 1 }, ['contract_revision'])
          .toPromise();
      }

      const taskData: Task = {
        id: task.id,
        assigned_user_id: previousReviewer.id,
      };

      if (task.status_id === TaskStatus.Complete) {
        taskData.status_id = TaskStatus.Open;
      }
      await this.updateTask(taskData, accessoryData);
    }
  }

  // Submittals functionality
  public async addEngineerToReview(task: Task, engineerId: number) {
    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
    const architectLocation = accessoryData.reviewChain.findIndex(
      (reviewer) => reviewer.id === this.authService.currentUser.id
    );

    accessoryData.reviewChain.splice(architectLocation, 0, {
      id: engineerId,
      status: TaskReviewStatus.Pending,
    });

    await this.taskService.addFollowerToTask(task.id, engineerId).toPromise();
    await this.updateTask({ id: task.id, assigned_user_id: engineerId }, accessoryData);
  }

  public async updateBidContractReviewer(res, task, bid, accessoryData) {
    if (res) {
      if (res.approval === TaskReviewStatus.Approved) {
        await this.reassignUser(task, accessoryData, res.approval, res.comment, res.commentFiles, res.reviewFiles);
        await this.createTaskApprovalActivity(task, res.approval, res.comment, [
          ...res.commentFiles,
          ...res.reviewFiles,
        ]);
      } else if (res.approval === TaskReviewStatus.Rejected) {
        await this.createTaskApprovalActivity(task, res.approval, res.comment, res.commentFiles);
        await this.reviewRevisionService.bidContractRevision(bid, false, res.comment);
      }
    }
  }

  // Internal Functionality
  private async createResetNote(taskId, message) {
    await this.projectService
      .createNote(ResourceType.Task, taskId, '<span>The review process was reset:</span><br />' + `${message}`)
      .toPromise();
  }

  public async updateTask(taskData: Task, accessoryData?: TaskAccessoryData) {
    if (accessoryData) {
      accessoryData.reviewChain = this.displayReviewersService.removeUserInfoFromReviewChain(
        accessoryData?.reviewChain
      );
      taskData.accessory_data = JSON.stringify(accessoryData);
    }
    const updatedTask = await this.projectService.updateTask(taskData, this.projectService.arfTaskFields).toPromise();

    if (
      this.authService.currentUser.user_type_id !== UserType.Vendor ||
      updatedTask.follower_ids.includes(this.authService?.currentUser?.id)
    ) {
      await this.taskService.updateTask(updatedTask);
      this.taskService.selectTaskById(updatedTask.id, false).subscribe();
    }
  }
}
