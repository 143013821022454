<mat-drawer-container
  #mainScreen
  data-test-id="dispatch-container"
  id="dispatch-container"
  class="p-0 m-0 dispatch-container"
>
  <section id="main" class="ease p-4">
    <header class="mb-4">
      <div class="row align-items-center mb-4">
        <div class="col">
          <h1 class="m-0 dkblue font-weight-semibold">Dispatch</h1>
        </div>
        <div class="col-4">
          <mat-form-field appearance="outline" class="full-width">
            <mat-icon class="dkblue" matPrefix>search</mat-icon>
            <input matInput [placeholder]="getSearchPlaceholder()" [formControl]="searchTerm" />
            <mat-icon *ngIf="filter.searchTerm" class="gray pointer" (click)="clearSearch()" matSuffix>cancel</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-auto">
          <button
            [hidden]="!hasASelctedItem"
            (click)="clearEditandCreate(); drawer.toggle()"
            mat-flat-button
            color="success"
            class="pl-5 pr-5"
          >
            CREATE
          </button>
          <button
            [hidden]="hasASelctedItem"
            (click)="drawer.toggle()"
            mat-flat-button
            color="success"
            class="pl-5 pr-5"
          >
            CREATE
          </button>
          <!-- <button
            [hidden]="!hasASelctedItem"
            (click)="drawer.toggle()"
            mat-flat-button
            color="success"
            class="pl-5 pr-5"
          >
            CREATE
          </button> -->
          <!-- <button (click)="drawer.toggle()" mat-flat-button color="success" class="pl-5 pr-5">CREATE</button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-3 pr-3 mb-4">
          <!-- CLICK TO SHOW WORK ORDERS CREATED TODAY -->
          <div
            class="m-portlet m-0"
            [ngClass]="{ selectedFilter: filter.submitted === filterKeys.DAY && filter.type === 3 }"
          >
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmitted(filterKeys.DAY, 3)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons green mr-2">assignment_returned</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Today's Items</p>
                <h4 class="m-0 green font-weight-semibold ml-auto">
                  {{ todaysItemsCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pr-3 mb-4">
          <!-- CLICK TO SHOW CURRENT ACTIVE WOS -->
          <div
            class="m-portlet m-0"
            [ngClass]="{
              selectedFilter: !filter.submitted && filter.type === 2 && filter.status_id === 1
            }"
          >
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmittedStatus(null, 2, 1)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons ltblue mr-2">assignment</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Active Work Orders</p>
                <h4 class="m-0 ltblue font-weight-semibold ml-auto">
                  {{ activeWorkOrdersCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 pr-3 mb-4">
          <!-- CLICK TO FILTER TO PENDING REQUESTS ONLY -->
          <div class="m-portlet m-0" [ngClass]="{ selectedFilter: !filter.submitted && filter.type === 1 }">
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmittedStatus(null, 1, 1)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons ltblue mr-2">assignment_late</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Pending Requests</p>
                <h4 class="m-0 ltblue font-weight-semibold ml-auto">
                  {{ pendingRequestsCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 pr-3 mb-4">
          <!-- CLICK TO SHOW ONLY DRAFTS -->
          <div class="m-portlet m-0" [ngClass]="{ selectedFilter: filter.submitted === null && filter.type === 0 }">
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmitted(null, 0)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons orange mr-2">create</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Drafts</p>
                <h4 class="m-0 orange font-weight-semibold ml-auto">
                  {{ draftsCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 pr-3 mb-4">
          <!-- CLICK TO FILTER TO ONLY TRANSFERRED ITEMS -->
          <div class="m-portlet m-0" [ngClass]="{ selectedFilter: !filter.submitted && filter.workspace_id === 2 }">
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByWorkspaceSubmitted(null, 2)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons red mr-2">arrow_forward</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Transferred</p>
                <h4 class="m-0 red font-weight-semibold ml-auto">
                  {{ transferredCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 pr-3 mb-4">
          <!-- CLICK TO FILTER TO ON HOLD ITEMS ONLY -->
          <div
            class="m-portlet m-0"
            [ngClass]="{
              selectedFilter: !filter.submitted && filter.type === 2 && filter.status_id === 2
            }"
          >
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmittedStatus(null, 2, 2)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons red mr-2">flag</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">On Hold</p>
                <h4 class="m-0 red font-weight-semibold ml-auto">
                  {{ onHoldWorkOrdersCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 mb-4">
          <!-- CLICK TO FILTER TO WOS WITHOUT ACTIVITY FOR 3+ DAYS -->
          <div
            class="m-portlet m-0"
            [ngClass]="{
              selectedFilter: !filter.submitted && filter.type === 4
            }"
          >
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmitted(null, 4)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons red mr-2">feedback</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">3+ Days No Comm</p>
                <h4 class="m-0 red font-weight-semibold ml-auto">
                  {{ noCommsCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-3 pr-3 mb-4">
          <!-- CLICK TO FILTER TO READY FOR PICKUP ITEMS ONLY -->
          <div
            class="m-portlet m-0"
            [ngClass]="{
              selectedFilter: !filter.submitted && filter.type === 2 && filter.status_id === 4
            }"
          >
            <div
              class="m-portlet__body item item-last p-3 d-flex flex-column"
              (click)="getItemsByTypeSubmittedStatus(null, 2, 4)"
            >
              <div class="d-flex align-items-center">
                <i class="material-icons dkgreen mr-2">fact_check</i>
                <p class="m-0 dkblue font-weight-semibold d-block text-truncate">Ready for Pickup</p>
                <h4 class="m-0 dkgreen font-weight-semibold ml-auto">
                  {{ readyForPickupWorkOrdersCount }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div *ngIf="!isLoading" class="col">
          <!-- # OF SHOWN AND # TOTAL ACTIVE ITEMS -->
          <h5 class="m-0 dkgray font-weight-normal d-inline-block align-middle">
            <span class="font-weight-semibold dkblue">{{ filteredItems.length }}</span> of
            <span *ngIf="!showClosed">
              {{
                pendingRequestsCount +
                  onHoldWorkOrdersCount +
                  activeWorkOrdersCount +
                  draftsCount +
                  readyForPickupWorkOrdersCount
              }}
              Active Items
            </span>
            <span *ngIf="showClosed"> {{ closedWorkOrdersCount }} Closed Work Orders</span>
          </h5>
        </div>
        <div class="col d-flex justify-content-end">
          <button mat-button color="primary" class="ml-3 d-inline-block align-middle lh-28" (click)="clearFilter()">
            View All Active
          </button>
          <button
            mat-button
            [class.mat-flat-button]="showClosed"
            color="primary"
            class="ml-3 d-inline-block align-middle lh-28"
            (click)="showClosedWorkOrders()"
          >
            Closed Work Orders
          </button>
        </div>
      </div>
    </header>
    <main>
      <div class="m-portlet mb-0">
        <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
          <div class="row align-items-center">
            <div
              class="col-auto pt-1 pb-1 pr-3 d-flex align-items-center header-item"
              style="width: 100px"
              [ngClass]="{ sortedBy: fieldToSortBy === 'code' }"
            >
              <h6 (click)="updateSortByField('code')" class="m-0 text-truncate header-title mr-auto">ID</h6>
              <i *ngIf="fieldToSortBy === 'code'" matRipple class="material-icons">
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
              <i matRipple [hidden]="showClosed" [matMenuTriggerFor]="item_menu" class="material-icons"> more_vert </i>
              <mat-menu #item_menu="matMenu" xPosition="after">
                <button
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': !filter.type && filter.type !== 0 }"
                  (click)="updateFilter(filterKeys.TYPE, null, true)"
                >
                  <mat-icon>all_inbox</mat-icon>
                  <span>All</span>
                </button>
                <button
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.type === 0 }"
                  (click)="updateFilter(filterKeys.TYPE, 0, true)"
                >
                  <mat-icon>description</mat-icon>
                  <span>Drafts</span>
                </button>
                <button
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.type === 1 }"
                  (click)="updateFilter(filterKeys.TYPE, 1, true)"
                >
                  <mat-icon>assignment_late</mat-icon>
                  <span>Project Requests</span>
                </button>
                <button
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.type === 2 }"
                  (click)="updateFilter(filterKeys.TYPE, 2, true)"
                >
                  <mat-icon>description</mat-icon>
                  <span>Work Orders</span>
                </button>
              </mat-menu>
            </div>
            <div
              class="col-auto p-1 pl-3 pr-3 d-flex align-items-center header-item"
              style="width: 105px"
              [ngClass]="{ sortedBy: fieldToSortBy === 'building.code' }"
            >
              <h6 (click)="updateSortByField('building.code')" class="m-0 text-truncate header-title mr-auto">
                Location
              </h6>
              <i *ngIf="fieldToSortBy === 'building.code'" matRipple class="material-icons">
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
            <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center">
              <h6 class="m-0 text-truncate header-title mr-auto">Short Description</h6>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
              style="max-width: 140px"
              [ngClass]="{ sortedBy: fieldToSortBy === 'priority.id' }"
            >
              <h6 (click)="updateSortByField('priority.id')" class="m-0 text-truncate header-title mr-auto">
                Priority
              </h6>
              <i
                (click)="updateSortByField('priority.id')"
                *ngIf="fieldToSortBy === 'priority.id'"
                matRipple
                class="material-icons"
              >
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'created_datetime' }"
            >
              <h6 (click)="updateSortByField('created_datetime')" class="m-0 text-truncate header-title mr-auto">
                Submitted
              </h6>
              <i
                (click)="updateSortByField('created_datetime')"
                *ngIf="fieldToSortBy === 'created_datetime'"
                matRipple
                class="material-icons"
              >
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
              <i matRipple [matMenuTriggerFor]="submitted_menu" class="material-icons"> more_vert </i>
              <mat-menu #submitted_menu="matMenu" xPosition="before">
                <button
                  color="primary"
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': !filter.submitted }"
                  (click)="
                    showClosed
                      ? filterClosedItems(filterKeys.SUBMITTED, null)
                      : updateFilter(filterKeys.SUBMITTED, null, true)
                  "
                >
                  <mat-icon class="primary">calendar_today</mat-icon>
                  <span>All</span>
                </button>
                <button
                  color="primary"
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.submitted === filterKeys.DAY }"
                  (click)="
                    showClosed
                      ? filterClosedItems(filterKeys.SUBMITTED, filterKeys.DAY)
                      : updateFilter(filterKeys.SUBMITTED, filterKeys.DAY, true)
                  "
                >
                  <mat-icon class="primary">today</mat-icon>
                  <span>Today</span>
                </button>
                <button
                  color="primary"
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.submitted === filterKeys.WEEK }"
                  (click)="
                    showClosed
                      ? filterClosedItems(filterKeys.SUBMITTED, filterKeys.WEEK)
                      : updateFilter(filterKeys.SUBMITTED, filterKeys.WEEK, true)
                  "
                >
                  <mat-icon class="primary">date_range</mat-icon>
                  <span>This Week</span>
                </button>
                <button
                  color="primary"
                  mat-menu-item
                  [ngClass]="{ 'bg-shade-gray': filter.submitted === filterKeys.MONTH }"
                  (click)="
                    showClosed
                      ? filterClosedItems(filterKeys.SUBMITTED, filterKeys.MONTH)
                      : updateFilter(filterKeys.SUBMITTED, filterKeys.MONTH, true)
                  "
                >
                  <mat-icon class="primary">event_note</mat-icon>
                  <span>This Month</span>
                </button>
              </mat-menu>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'requester.first_name' }"
            >
              <h6 (click)="updateSortByField('requester.first_name')" class="m-0 text-truncate header-title mr-auto">
                Requestor
              </h6>
              <i
                (click)="updateSortByField('requester.first_name')"
                *ngIf="fieldToSortBy === 'requester.first_name'"
                matRipple
                class="material-icons"
              >
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'assigned_user.first_name' }"
            >
              <h6
                (click)="updateSortByField('assigned_user.first_name')"
                class="m-0 text-truncate header-title mr-auto"
              >
                Assigned To
              </h6>
              <i
                (click)="updateSortByField('assigned_user.first_name')"
                *ngIf="fieldToSortBy === 'assigned_user.first_name'"
                matRipple
                class="material-icons"
              >
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'created_by.first_name' }"
            >
              <h6 (click)="updateSortByField('created_by.first_name')" class="m-0 text-truncate header-title mr-auto">
                Entered By
              </h6>
              <i
                (click)="updateSortByField('created_by.first_name')"
                *ngIf="fieldToSortBy === 'created_by.first_name'"
                matRipple
                class="material-icons"
              >
                {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
          </div>
        </div>
        <div
          matRipple
          class="m-portlet__body item p-3 pt-4 pb-4 pointer {{ itemListBackgroundColor(item) }}"
          [ngClass]="{
            selectedItem:
              item.id === dispatchRequest?.dispatchItem?.id && item.type === dispatchRequest?.dispatchItem?.type
          }"
          *ngFor="
            let item of filteredItems | orderByField: fieldToSortBy:sortDirection | slice: startIndex:endIndex;
            let i = index
          "
          (click)="editItem(item); drawer.toggle()"
        >
          <div class="row align-items-center">
            <div class="col-auto d-flex align-items-center data-item" style="width: 100px">
              <h6 class="m-0 mr-auto dkblue pointer font-weight-semibold d-block text-truncate">
                {{ item.code || '...' }}
              </h6>
            </div>
            <div class="col-auto d-flex align-items-center data-item" style="width: 105px">
              <h6 class="m-0 mr-auto dkblue pointer font-weight-semibold">
                {{ item.building?.code || '' }}{{ item.floor?.code ? '-' + item.floor?.code : '-' }}
              </h6>
            </div>
            <div class="col-3 d-flex flex-column data-item">
              <h6 class="m-0 dkblue font-weight-semibold d-block">
                {{ item.title || '-' }}
              </h6>
              <span class="m-0 dkgray font-weight-normal d-block">
                {{
                  item.topic_group?.name || item.topic?.topic_group?.name
                    ? (item.topic_group?.name || item.topic?.topic_group?.name) + ' >'
                    : ''
                }}
                {{
                  item.topic_category?.name || item.topic?.topic_category?.name
                    ? (item.topic_category?.name || item.topic?.topic_category?.name) + ' >'
                    : ''
                }}
                {{ item.topic?.name }}
              </span>
            </div>
            <div class="col d-flex align-items-center data-item" style="max-width: 140px">
              <h6
                class="m-0 badge text-uppercase pl-2 pr-2 mr-2 white lh-14"
                [ngClass]="{
                  'bg-dkred': item.priority?.id === 4,
                  'bg-red': item.priority?.id === 3,
                  'bg-ltblue': item.priority?.id === 2,
                  'bg-gray': item.priority?.id === 1
                }"
              >
                <span>
                  {{ item.priority?.abbreviation || '-' }}
                </span>
              </h6>
            </div>
            <div class="col d-flex data-item">
              <h6 class="m-0 dkblue font-weight-normal">
                {{ formatSubmittedDate(item.created_datetime) }}
              </h6>
            </div>
            <div class="col d-flex align-items-center data-item">
              <app-profile-thumbnail
                *ngIf="item.requester?.id"
                [noBorder]="true"
                class="d-inline-block mr-2"
                [height]="22"
                [width]="22"
                [userId]="item.requester?.id"
              ></app-profile-thumbnail>
              <h6 class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                {{ item.requester?.first_name }} {{ item.requester?.last_name }}
              </h6>
            </div>
            <div class="col d-flex data-item align-items-center">
              <app-profile-thumbnail
                *ngIf="item.assigned_user?.id"
                [noBorder]="true"
                class="d-inline-block mr-2"
                [height]="22"
                [width]="22"
                [userId]="item.assigned_user?.id"
              ></app-profile-thumbnail>
              <h6 class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                {{ item.assigned_user?.first_name }} {{ item.assigned_user?.last_name }}
              </h6>
              <div *ngIf="!item.assigned_user && item.type !== 1" class="d-flex align-items-center text-truncate">
                <i class="material-icons red mr-1" style="font-size: 18px">error</i>
                <h6 class="m-0 red font-weight-normal d-block text-truncate">Not Assigned</h6>
              </div>
            </div>
            <div class="col d-flex data-item align-items-center">
              <app-profile-thumbnail
                *ngIf="item.created_by?.id"
                [noBorder]="true"
                class="d-inline-block mr-2"
                [height]="22"
                [width]="22"
                [userId]="item.created_by?.id"
              ></app-profile-thumbnail>
              <h6 class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                {{ item.created_by?.first_name }} {{ item.created_by?.last_name }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <mat-paginator
        *ngIf="filteredItems.length > pageSize"
        class="bg-transparent dkblue"
        color="primary"
        #paginator
        [length]="filteredItems.length"
        [pageSize]="pageSize"
        (page)="pageChange($event)"
      >
      </mat-paginator>
    </main>
  </section>

  <mat-drawer #drawer mode="over" position="end" autoFocus="false">
    <app-dispatch-request
      (closeItem)="closeItem()"
      (refreshDispatch)="refresh(filter?.type)"
      (refreshEditItem)="reloadEditItem($event)"
    ></app-dispatch-request>
  </mat-drawer>
</mat-drawer-container>
