<mat-drawer-container autosize class="container-fluid d-flex flex-column position-absolute h-100 p-4">
  <mat-drawer #drawer class="p-4" mode="over" position="end" autoFocus="false">
    <app-peb-section-details-drawer
      #sectionDetailsDrawerComponent
      [canEdit]="canEdit"
      (closeDrawer)="drawer.close()"
      (editSection)="openPebSectionDialog($event)"
      (deleteSection)="deleteSection($event)"
      (submitForTenantReview)="submitForTenantReview($event)"
      (openTenantReviewTask)="viewTask($event)"
      (updateApprovalProcesses)="updateApprovalProcesses()"
      (saveCoverLetterDescription)="saveCoverLetter($event)"
      (uploadReimbursementAgreementEvent)="uploadReimbursementAgreement($event)"
      (uploadSubleaseAgreementEvent)="uploadSubleaseAgreement($event)"
      (uploadExhibitBEvent)="uploadExhibitB($event)"
      (uploadAmortizationScheduleEvent)="uploadAmortizationSchedule($event)"
      (uploadBubbleDrawingEvent)="uploadBubbleDrawing($event)"
      (removeBubbleDrawingEvent)="removeBubbleDrawing($event)"
      (removeReimbursementEvent)="removeReimbursement($event)"
      (removeSubleaseContractEvent)="removeSubleaseContract($event)"
      (removeExhibitBEvent)="removeExhibitB($event)"
      (removeAmortizationScheduleEvent)="removeAmortizationSchedule($event)"
    >
    </app-peb-section-details-drawer>
  </mat-drawer>
  <div #budget id="budget">
    <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
      <button
        [routerLink]="['/projects/' + project?.id + '/peb']"
        mat-button
        color="primary"
        class="flex-grow-1 mr-1 bg-white"
      >
        PEB
      </button>
      <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
        Bid Packages
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/construction-budget']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Construction Budget
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Proposal Requests
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/change-orders']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Change Orders
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/invoices']"
        mat-button
        color="primary"
        class="flex-grow-1 mx-1"
      >
        Invoices
      </button>
      <button
        [routerLink]="['/projects/' + project?.id + '/budget']"
        mat-button
        color="primary"
        class="flex-grow-1 ml-1"
      >
        Budget Overview
      </button>
    </div>
    <div class="d-flex full-width align-items-top border-bottom border-gray">
      <div class="d-flex flex-column mr-auto">
        <h4 class="dkblue">
          Preliminary Estimated Budget
          <span *ngIf="isFinalized" class="bg-dkgreen white px-2 lh-24 font-weight-semibold ml-2 br-4">
            FINALIZED
          </span>
        </h4>
        <p class="dkgray">The preliminary estimated budget represents a rough estimate for the cost of the project.</p>
      </div>
      <div class="d-flex align-items-center ml-3">
        <button
          mat-button
          color="primary"
          type="button"
          class="ml-3"
          (click)="exportPEB()"
          [disabled]="!this.selectedOption"
        >
          Download PDF
        </button>
        <button
          mat-stroked-button
          (click)="openPebSectionDialog()"
          color="success"
          class="bg-white ml-3"
          type="button"
          *ngIf="this.selectedOption && canEdit"
        >
          + Section
        </button>

        <div class="d-flex border-left border-gray ml-3" *ngIf="canEdit">
          <button
            mat-stroked-button
            *ngIf="this.selectedOption && !selectedOption?.isEditing"
            color="primary"
            type="button"
            class="ml-3 bg-white"
            (click)="selectedOption.isEditing = true"
          >
            Edit PEB
          </button>

          <button
            mat-button
            *ngIf="selectedOption?.isEditing"
            color="primary"
            type="button"
            class="gray ml-3"
            (click)="cancelEditing()"
          >
            Discard Changes
          </button>
          <button
            mat-flat-button
            *ngIf="selectedOption?.isEditing"
            [disabled]="!percentageSubtotalsAreValid"
            color="success"
            type="button"
            class="ml-3"
            (click)="savePEBOption()"
          >
            <!-- <span class="pulse-dot-success"></span> -->
            Save PEB
          </button>
        </div>
        <div
          *ngIf="isProjectAdmin && reviewsHaveStarted && !selectedOption?.isEditing"
          class="d-flex border-gray border-left ml-3"
        >
          <button
            mat-stroked-button
            *ngIf="PEBApprovalTaskId"
            color="warn"
            type="button"
            class="ml-3 bg-white"
            (click)="selectedOption.isEditing = true"
          >
            Make A Change
          </button>
          <button
            mat-flat-button
            *ngIf="savedPEBApprovalTaskId"
            color="success"
            type="button"
            class="ml-3"
            (click)="submitForInternalReview()"
            [disabled]="!requiredDataIsAdded"
          >
            Resubmit for Review
          </button>
          <button
            class="ml-3"
            mat-flat-button
            color="primary"
            type="button"
            *ngIf="canFinalize"
            (click)="finalizePEB()"
          >
            <span class="pulse-dot-success"></span>
            Finalize this PEB
          </button>
          <button class="ml-3 font-italic" mat-flat-button color="primary" type="button" disabled *ngIf="isFinalized">
            Finalized
          </button>
        </div>
      </div>
      <!-- TODO: PEB | Adam - Where should this statusText go? -->
      <!-- {{ statusText }} -->
    </div>
    <div class="d-flex my-3">
      <!-- <button
        mat-button
        color="primary"
        class="mr-3"
        [class]="o.id === selectedOption?.id ? 'bg-shade-dkblue' : 'bg-shade-gray'"
        type="button"
        *ngFor="let o of PEBOptions"
        (click)="selectOption(o)"
      >
        {{ o.title }}
        <mat-icon class="green ml-2" style="font-size: 16pt" *ngIf="o.id === selectedOption?.id && isFinalized">
          check_mark</mat-icon
        >
      </button> -->
      <button
        mat-button
        color="accent"
        class="mr-3"
        type="button"
        *ngIf="canEdit && !PEBOptions?.length"
        (click)="createPEBOption()"
      >
        + Option
      </button>
    </div>
    <mat-spinner color="accent" [diameter]="40" *ngIf="loaders.selectedOption"></mat-spinner>
    <div class="d-flex align-items-top overflow-hidden" *ngIf="selectedOption && !loaders.selectedOption">
      <ng-container *ngIf="!loaders.selectedOption">
        <div id="trade-column" class="d-flex flex-column">
          <div class="bg-white d-flex flex-column p-3" style="height: 80px">
            <div class="d-flex full-width align-items-center">
              <h5 class="dkblue mr-auto mb-0">{{ selectedOption.title }}</h5>
              <!-- <mat-icon
                matRipple
                [matMenuTriggerFor]="pebOptionMenu"
                class="line-item-button br-50 pointer ml-auto"
                *ngIf="!reviewsHaveStarted"
                >more_vert</mat-icon
              >
              <mat-menu xPosition="before" #pebOptionMenu="matMenu">
                <button mat-menu-item (click)="deletePEBOption(selectedOption)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete Option</span>
                </button>
              </mat-menu> -->
            </div>
          </div>
          <div class="bg-white p-3">
            <h6 class="primary mb-0">TRADE</h6>
          </div>
          <div class="bg-white py-3 pl-3 pr-3 td d-flex align-items-center" *ngFor="let l of selectedOption.lines">
            <h5 class="primary mb-0 py-1 font-weight-normal text-truncate mr-2">{{ l.name }}</h5>
            <mat-icon
              matRipple
              [matMenuTriggerFor]="lineMenu"
              class="line-item-button br-50 pointer ml-auto"
              style="min-width: 24px"
              *ngIf="canEdit && l.type_id === 1 && selectedOption.isEditing"
              >more_vert
            </mat-icon>
            <mat-menu xPosition="before" #lineMenu="matMenu">
              <button mat-menu-item (click)="deleteTrade(l)">
                <mat-icon>delete</mat-icon>
                <span>Delete Line</span>
              </button>
            </mat-menu>
          </div>
          <div class="bg-white p-3">
            <button
              mat-button
              type="button"
              color="accent"
              class="pointer mb-0 lh-16 px-0 py-1"
              [matMenuTriggerFor]="trade_menu"
              *ngIf="selectedOption.isEditing"
            >
              + Trade
            </button>
            <h6 class="mb-0 lh-16 py-1" *ngIf="!selectedOption.isEditing">&nbsp;</h6>
            <mat-menu #trade_menu="matMenu">
              <mat-option *ngFor="let t of filteredTrades" [value]="t" (click)="addPEBTradeLine(t)">{{
                t.name
              }}</mat-option>
            </mat-menu>
          </div>
          <div class="bg-white p-3" style="padding-top: 20px !important">
            <h6 class="dkblue text-uppercase mb-0">Subtotals</h6>
          </div>
        </div>
        <div class="d-flex flex-grow-1 scroll-container">
          <div id="section-container" class="d-flex" *ngFor="let s of selectedOption?.sections; let i = index">
            <div
              style="display: inline-block"
              class="bg-white"
              *ngIf="s.isExpanded"
              [style.width.px]="(s.funding_sources?.length ?? 0) * 200 + 200"
            >
              <div class="row flex-nowrap text-nowrap m-0">
                <div id="section-header" class="col d-flex flex-column p-3" style="height: 80px">
                  <div class="d-flex flex-wrap full-width align-items-center mb-0">
                    <h5 class="dkblue font-weight-semibold mr-auto mb-0">{{ s.name }}</h5>
                    <!-- <p class="border border-gray bg-shade-gray px-2 br-10 lh-20 dkblue mr-2 mb-0"
                      *ngFor="let fs of s.funding_sources">
                      ${{ fs.abbreviation }}
                    </p> -->
                    <!-- <p class="border border-gray bg-shade-gray px-2 br-10 lh-20 dkblue mr-2 mb-0">CAPX</p> -->
                    <div class="d-flex align-items-top">
                      <p
                        class="mb-0 d-flex align-items-center"
                        (click)="internalReviewAction(PEBApprovalTaskId ?? savedPEBApprovalTaskId)"
                        [class]="reviewsHaveStarted ? 'ltblue' : 'ltgray'"
                      >
                        <i
                          *ngIf="s.approval_process?._staffReview?.isTaskComplete"
                          class="green fa fa-check-circle mr-1"
                        >
                        </i>
                        <span class="pointer hover-u"> Internal Review </span>
                      </p>
                      <p class="mx-3 mb-0" [class]="s.tenantReviewHasStarted ? 'dkgray' : 'ltgray'">
                        <i
                          *ngIf="s.needs_tenant_approval"
                          class="fa fa-arrow-right"
                          [class]="s.tenantReviewHasStarted ? 'gray' : 'ltgray'"
                        ></i>
                      </p>
                      <p
                        *ngIf="s.needs_tenant_approval"
                        class="mb-0 d-flex align-items-center"
                        (click)="tenantReviewAction()"
                        [class]="s.tenantReviewHasStarted ? 'ltblue' : 'ltgray'"
                      >
                        <i
                          *ngIf="s.approval_process?._tenantReview?.isTaskComplete"
                          class="green fa fa-check-circle mr-1"
                        ></i>
                        <span class="pointer hover-u"> Tenant Review </span>
                      </p>
                    </div>
                  </div>
                  <div class="align-items-center row">
                    <div class="dkgray" [class]="'col-' + (s?.funding_sources?.length > 1 ? '3' : '6')">
                      Construction SF <span class="dkblue font-weight-semibold ml-1">{{ s.construction_sqft }}</span>
                    </div>
                    <!-- <div class="dkgray" [class]="'col-' + (s?.funding_sources?.length > 1 ? '3' : '6')">
                      Occupied SF <span class="dkblue font-weight-semibold">{{ s.occupied_sqft }}</span>
                    </div> -->
                    <div class="dkgray" [class]="'col-' + (s?.funding_sources?.length > 1 ? '3' : '6')">
                      OSF w/ Circ ({{ 0.01 * s.circulation_factor | percent }})
                      <span class="dkblue font-weight-semibold ml-1">{{ s.osf_w_circ }}</span>
                    </div>
                    <div
                      *ngIf="s.rental_rate > 0"
                      class="dkgray"
                      [class]="'col-' + (s?.funding_sources?.length > 1 ? '3' : '6')"
                    >
                      Rental Rate
                      <span class="dkblue font-weight-semibold ml-1">
                        <span class="mr-2"> {{ s.rental_rate | USDollar }}/ft </span>
                        {{ s.yearlyRentalRate | USDollar }}/yr</span
                      >
                    </div>
                    <!-- <div class="dkgray" [class]="'col-' + (s?.funding_sources?.length > 1 ? '3' : '6')">
                      Total Rent/YR <span class="dkblue font-weight-semibold">{{ s.yearlyRentalRate | USDollar }}</span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3 d-flex">
                  <h6 class="primary text-uppercase mb-0 mr-auto">SF</h6>
                  <h6
                    class="ltblue m-0 pointer hover-u"
                    (click)="refreshSectionSF(s)"
                    *ngIf="selectedOption?.isEditing"
                  >
                    Refresh
                  </h6>
                  <h6 class="gray m-0" *ngIf="!selectedOption?.isEditing">Refresh</h6>
                </div>
                <div class="col-auto p-3" *ngFor="let fs of s.funding_sources">
                  <h6 class="primary text-uppercase mb-0">{{ fs.abbreviation }}</h6>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto pt-1 px-3">
                  <div class="td" *ngFor="let l of s.lines">
                    <mat-form-field class="full-width" appearance="outline" *ngIf="l.type_id === 1">
                      <mat-icon class="primary" matPrefix>apartment</mat-icon>
                      <input
                        class="primary"
                        [ngClass]="{ gray: +l.sqft === 0 }"
                        matInput
                        appNumeric
                        [allowDecimal]="false"
                        [(ngModel)]="l.sqft"
                        (ngModelChange)="tenantLineChanged(l)"
                        [disabled]="!selectedOption.isEditing"
                      />
                      <p matSuffix class="gray m-0">{{ !l.sqft ? 0 : ((l.subtotal || 0) / l.sqft | USDollar) }}/sf</p>
                      <!-- <mat-icon class="ltgray pointer" matSuffix>lock_open</mat-icon> -->
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-auto pt-1 px-3" *ngFor="let fs of s.funding_sources">
                  <div class="td" *ngFor="let i of fs.items">
                    <mat-form-field class="full-width" appearance="outline" *ngIf="i.line_type_id === 1">
                      <mat-icon *ngIf="!selectedOption.isEditing" [ngClass]="{ gray: +i.value === 0 }" matPrefix>{{
                        i.calculation_type_id === 2 ? 'percentage' : 'attach_money'
                      }}</mat-icon>
                      <mat-icon
                        *ngIf="selectedOption.isEditing"
                        class="bg-shade-ltblue br-5 px-1 pointer"
                        [ngClass]="{ gray: +i.value === 0 }"
                        matPrefix
                        [matMenuTriggerFor]="prefixMenu"
                        >{{ i.calculation_type_id === 2 ? 'percentage' : 'attach_money' }}</mat-icon
                      >
                      <mat-menu #prefixMenu="matMenu">
                        <button mat-menu-item (click)="changeCalculationType(s, i, 1)">
                          <mat-icon>attach_money</mat-icon>
                          <span>Dollar Amt</span>
                        </button>
                        <button mat-menu-item (click)="changeCalculationType(s, i, 2)">
                          <mat-icon>percent</mat-icon>
                          <span>Percentage</span>
                        </button>
                      </mat-menu>
                      <!--Disabled value, for display purposes only-->
                      <input
                        *ngIf="i.calculation_type_id !== 2 && !selectedOption.isEditing"
                        class="primary text-right"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [ngModel]="i.value | USDollar: 2:false"
                        [disabled]="true"
                      />
                      <!--Enabled value input-->
                      <input
                        *ngIf="i.calculation_type_id !== 2 && selectedOption.isEditing"
                        (click)="$event.target.select()"
                        class="primary text-right"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [(ngModel)]="i.value"
                        (ngModelChange)="pebItemChanged(i)"
                        (blur)="blurDollarItem(i, 'value')"
                      />
                      <!--percentage input -->
                      <input
                        *ngIf="i.calculation_type_id === 2"
                        (click)="$event.target.select()"
                        class="primary pl-2"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [(ngModel)]="i.percentage"
                        (ngModelChange)="pebItemChanged(i, fs)"
                        [disabled]="!selectedOption.isEditing"
                        (blur)="blurPercentage(i, 'percentage')"
                      />
                      <p matSuffix class="gray m-0" *ngIf="i.calculation_type_id === 2">
                        {{ i.value | USDollar }}
                      </p>
                    </mat-form-field>
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      *ngIf="feeTypeIds.indexOf(i.line_type_id) > -1"
                    >
                      <mat-icon [ngClass]="{ gray: +i.value === 0 }" matPrefix>percent</mat-icon>
                      <input
                        (click)="$event.target.select()"
                        class="primary"
                        [ngClass]="{ gray: +i.value === 0 }"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [(ngModel)]="i.percentage"
                        (ngModelChange)="pebItemChanged(i, fs)"
                        [disabled]="!selectedOption.isEditing"
                      />
                      <p matSuffix class="gray m-0">
                        {{ i.value | USDollar }}
                      </p>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col py-3 px-3">
                  <h6 class="white text-uppercase mb-0 lh-16">&nbsp;</h6>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3 text-right" style="padding-top: 20px !important">
                  <h6 class="primary text-uppercase mb-0 lh-16">
                    {{ (parseNumber(s?.subtotal) || 0) / (s?.construction_sqft || 0) | USDollar }}/SF
                  </h6>
                </div>
                <div
                  class="col-auto p-3 text-right"
                  style="padding-top: 20px !important"
                  *ngFor="let fs of s.funding_sources"
                >
                  <h6 class="dkblue text-uppercase mb-0 lh-16 pr-2">{{ fs.subtotal || 0 | USDollar }}</h6>
                </div>
              </div>
            </div>
            <div *ngIf="s.funding_sources" style="width: 200px; display: inline-block" class="bg-shade-gray">
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3 d-flex flex-column" style="height: 80px">
                  <div class="d-flex">
                    <button
                      (click)="openSectionDrawer(s)"
                      mat-stroked-button
                      color="accent"
                      class="bg-white flex-grow-1"
                      type="button"
                    >
                      <span
                        *ngIf="dataIsAddedBySectionStatus[i] && !selectedOption.isEditing"
                        class="pulse-dot-accent"
                      ></span>
                      View Details
                    </button>
                    <button mat-button color="primary" class="px-2" type="button" (click)="toggleSectionExpansion(s)">
                      <mat-icon>{{ s.isExpanded ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto p-3">
                  <h6 class="primary text-uppercase mb-0 ellipsis">{{ s.isExpanded ? 'Subtotal' : s.name }}</h6>
                </div>
              </div>
              <div class="row flex-nowrap text-nowrap m-0">
                <div class="col-auto py-1 px-3">
                  <div class="td" *ngFor="let l of s.lines">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-icon
                        *ngIf="
                          l.calculation_type_id !== 2 || (l.calculation_type_id === 2 && l.percent_subtotal === 100)
                        "
                        [ngClass]="{ gray: +l.subtotal === 0 }"
                        matPrefix
                        >attach_money</mat-icon
                      >
                      <mat-icon
                        class="red pointer"
                        *ngIf="l.calculation_type_id === 2 && l.percent_subtotal !== 100"
                        matPrefix
                        matTooltip="Percent sum does not equal 100"
                        matTooltipPosition="above"
                        [matTooltipShowDelay]="300"
                        >error</mat-icon
                      >
                      <!-- Percentage line type, enabled input -->
                      <input
                        *ngIf="selectedOption.isEditing && l.calculation_type_id === 2"
                        class="primary text-right font-weight-semibold"
                        [ngClass]="{ gray: +l.subtotal === 0 }"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [(ngModel)]="l.subtotal"
                        (ngModelChange)="lineSubtotalChanged(s, l)"
                        (blur)="blurDollarItem(l, 'subtotal')"
                        [disabled]="!selectedOption.isEditing || l.calculation_type_id !== 2"
                      />
                      <!-- Dollar line type, disabled input -->
                      <input
                        *ngIf="!selectedOption.isEditing || l.calculation_type_id !== 2"
                        class="primary text-right font-weight-semibold"
                        [ngClass]="{ gray: +l.subtotal === 0 }"
                        matInput
                        appNumeric
                        placeholder="0.00"
                        [value]="l.subtotal || 0 | USDollar: 2:false"
                        (ngModelChange)="lineSubtotalChanged(s, l)"
                        (blur)="blurDollarItem(l, 'subtotal')"
                        [disabled]="!selectedOption.isEditing || l.calculation_type_id !== 2"
                      />

                      <mat-hint
                        class="mat-error green"
                        align="end"
                        *ngIf="l.calculation_type_id === 2 && selectedOption.isEditing"
                        [ngClass]="{
                          red: l.percent_subtotal !== 100,
                          'font-italic opacity7': l.percent_subtotal === 100
                        }"
                      >
                        {{ l.percent_subtotal }}%</mat-hint
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3">
                  <h6 class="ltgray text-uppercase mb-0 lh-16">-</h6>
                </div>
              </div>
              <div class="row m-0 flex-nowrap text-nowrap">
                <div class="col-auto p-3 text-right">
                  <h6 class="dkblue text-uppercase mb-0 lh-16 pr-2">{{ s.subtotal || 0 | USDollar }}</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- TODO: PEB | Adam - Style this -->
          <div *ngIf="!selectedOption?.sections?.length || selectedOption.isEditing" class="p-4 h-100 d-flex">
            <button
              mat-stroked-button
              (click)="openPebSectionDialog()"
              *ngIf="!reviewsHaveStarted"
              color="success"
              class="bg-white my-auto"
              type="button"
            >
              + Section
            </button>
          </div>
        </div>
        <div id="total-column" class="d-flex flex-column bg-white">
          <div class="d-flex flex-column p-3" style="height: 80px">
            <button
              mat-stroked-button
              color="primary"
              type="button"
              class="full-width px-2 lh-26"
              [disabled]="(!internalReviewCanStart && !reviewsHaveStarted) || selectedOption?.isEditing"
              *ngIf="!reviewsHaveStarted"
              (click)="internalReviewAction(PEBApprovalTaskId ?? savedPEBApprovalTaskId)"
            >
              <span *ngIf="!reviewsHaveStarted && internalReviewCanStart" class="pulse-dot-success"></span>
              <!-- ADAM TODO ADD TASK STATUS HERE -->
              <!-- <i class="green fa fa-check mr-1">
              </i> -->
              <span> Start Internal Review </span>
            </button>
            <app-open-task-button
              *ngIf="PEBApprovalTaskId || savedPEBApprovalTaskId"
              [taskId]="PEBApprovalTaskId || savedPEBApprovalTaskId"
              [title]="'Internal Review'"
              [lineHeight]="26"
              [fullWidth]="true"
            >
            </app-open-task-button>
            <button
              mat-stroked-button
              color="accent"
              class="full-width lh-26 mt-2"
              type="button"
              *ngIf="!noTenantReviews && tenantReviewCanStart && sectionsThatNeedTenantReviews?.length"
              (click)="tenantReviewAction()"
              [disabled]="(!tenantReviewCanStart && !tenantReviewHasStarted) || selectedOption?.isEditing"
            >
              <span *ngIf="tenantReviewCanStart" class="pulse-dot-success"></span>
              Start Tenant Review{{ this.sectionsThatNeedTenantReviews?.length <= 1 ? '' : 's' }}
            </button>
            <app-open-task-button
              *ngIf="tenantTaskIds?.length && !sectionsThatNeedTenantReviews?.length && tenantReviews?.length"
              [taskIds]="tenantTaskIds"
              [title]="'Tenant Review' + (tenantTaskIds?.length === 1 ? '' : 's')"
              [lineHeight]="26"
              [fullWidth]="true"
              class="mt-2"
            >
            </app-open-task-button>
          </div>
          <div class="p-3">
            <h6 class="primary text-uppercase mb-0">Project Total</h6>
          </div>
          <div class="col-auto py-1 px-3">
            <div class="td" *ngFor="let l of selectedOption.lines">
              <mat-form-field class="full-width text-right font-weight-semibold" appearance="outline">
                <mat-icon [ngClass]="{ gray: +l.total === 0 }" class="pointer" matPrefix>attach_money</mat-icon>
                <input
                  disabled
                  matInput
                  placeholder="0.00"
                  [value]="l.total || 0 | USDollar: 2:false"
                  class="primary"
                  [ngClass]="{ gray: +l.total === 0 }"
                />
                <!-- <mat-icon class="ltgray pointer" matSuffix>lock_open</mat-icon> -->
              </mat-form-field>
            </div>
          </div>
          <div class="col-auto p-3">
            <h6 class="white mb-0 lh-16">&nbsp;</h6>
          </div>
          <div class="p-3 text-right">
            <h6 class="primary font-weight-bold mb-0 pr-2">{{ selectedOption?.total || 0 | USDollar }}</h6>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="d-flex justify-content-end py-3">
      <button
        mat-button
        *ngIf="selectedOption?.isEditing"
        color="primary"
        type="button"
        class="gray ml-3"
        (click)="cancelEditing()"
      >
        Discard Changes
      </button>
      <button
        mat-flat-button
        *ngIf="selectedOption?.isEditing"
        [disabled]="!percentageSubtotalsAreValid"
        color="success"
        type="button"
        class="ml-3"
        (click)="savePEBOption()"
      >
        <!-- <span class="pulse-dot-success"></span> -->
        Save PEB
      </button>
    </div>
  </div>
</mat-drawer-container>
<!------------------------------------- PEB / COVER LETTER EXPORT ------------------------------------->

<div id="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-3 mr-auto">
            <!-- REPEAT ROWS FOR EACH REVIEWER -->
            <div class="row mb-3" *ngFor="let reviewer of currentReviewers">
              <div class="col">
                <label class="main-label mt-0">
                  {{ (reviewer.status && reviewer.dateCreated) || 'Pending Review' }}
                </label>
                <p class="m-0">
                  <b>{{ reviewer.firstName }} {{ reviewer.lastName }}</b
                  ><br />
                  <span>{{ reviewer.title }}</span
                  ><br />
                  <span *ngIf="reviewer.status === 1" class="green font-weight-semibold mt-2 mb-2"> Approved </span>
                  <!-- <br />
                  <span class="dkgray">
                    {{ reviewer.comment }}
                  </span> -->
                </p>
              </div>
            </div>
            <div *ngIf="project.cost_codes" class="row">
              <div class="col bg-shade-gray mx-3 py-3" style="border-radius: 10px">
                <p><strong>FOR INTERNAL USE ONLY</strong></p>
                <p class="mt-2" style="font-size: 0.6rem">COST CODE(S)</p>
                <p class="mt-1" style="max-width: 50px">{{ project.cost_codes }}</p>
                <ng-container *ngIf="project.cost_code_description">
                  <p class="mt-2" style="font-size: 0.6rem">DESCRIPTION</p>
                  <p class="mt-1" [innerHTML]="project.cost_code_description | truncate: 150"></p>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-9 ml-auto pr-5">
            <div class="row mb-4 mt-2">
              <div class="col">
                <h4 class="m-0 font-weight-bold">Preliminary Estimated Budget</h4>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <label class="main-label mt-0"> Prepared For </label>
                <p class="m-0">
                  <b>{{ coverLetterSection.name }}</b
                  ><br />
                  <span>{{ coverLetterSection.title }}</span
                  ><br />
                  <span>{{ coverLetterSection.department }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <p class="m-0">
                  <!-- TENANT REP FIRST NAME -->
                  {{ coverLetterSection.first_name }}, <br /><br />
                  <span [innerHTML]="coverLetterSection.peb_cover_letter_text" style="white-space: pre-wrap"> </span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: PROJECT MANAGER -->
                <p class="m-0">
                  <b>{{ pmInfo.name }}</b
                  ><br />
                  <span>{{ pmInfo.title }}</span
                  ><br />
                  <span>{{ pmInfo.email }}</span>
                  <br />
                  <span>{{ pmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: WORKSPACE MANAGER -->
                <p class="m-0" *ngIf="wmInfo">
                  <b>{{ wmInfo.name }}</b
                  ><br />
                  <span>{{ wmInfo.title }}</span
                  ><br />
                  <span>{{ wmInfo.email }}</span>
                  <br />
                  <span>{{ wmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: ARCHITECT -->
                <p class="m-0" *ngIf="architectInfo">
                  <!-- MIGHT PUT AN NGIF ON THE ARCHITECT TO ADD TIM'S CREDENTIALS?? -->
                  <b>{{ architectInfo.name }}</b
                  ><br />
                  <span>{{ architectInfo.title }}</span
                  ><br />
                  <span>{{ architectInfo.email }}</span>
                  <br />
                  <span>{{ architectInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" [landscape]="true" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <section>
            <div class="row align-items-center mt-3">
              <div class="col-1">
                <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  <span class="accent"> 1call.cloud </span>
                  <br />
                  A service of University Hospital Authority & Trust
                </p>
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  1000 NE 13th St, Nicholson Tower, STE 6900
                  <br />
                  Oklahoma City, OK 73104
                </p>
              </div>
              <div class="col-2 pl-0">
                <p class="dkgray m-0">
                  ph 405.271.8001 <br />
                  fx 405.271.1301
                </p>
              </div>
              <div class="col-auto">
                <p class="dkgray m-0">
                  {{ getCurrentTime() }} <br />
                  {{ getCurrentDate() }}
                </p>
              </div>
              <div class="col-auto text-right">
                <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
              </div>
            </div>
          </section>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0 text-truncate">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> PEB Construction SF </label>
                <p>
                  {{ numberWithCommas(selectedSection?.construction_sqft || 0) }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ng-container>
          <section class="form-approval mb-4">
            <div class="row align-items-top">
              <div class="col-3">
                <label class="main-label m-0">Your Review</label>
                <div class="mt-2">
                  <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
                  <p class="m-0 black d-inline-block align-middle">Approve this Budget</p>
                </div>
                <div class="mt-3">
                  <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
                  <p class="m-0 black d-inline-block align-middle">Request a Change</p>
                </div>
              </div>
              <div class="col">
                <label class="main-label m-0">Your Name (Print)</label>
                <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
                <label class="main-label">Your Signature</label>
                <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
              </div>
              <div class="col">
                <label class="main-label m-0">Funding Source</label>
                <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
                <label class="main-label">Signature Date</label>
                <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
              </div>
            </div>
          </section>
          <section class="form-title d-flex align-items-end mt-5">
            <h1 class="black mb-1">
              <b class="mr-4">Preliminary Estimated Budget</b>
              <small class="dkgray"> {{ selectedSection?.tenant_name }} </small>
            </h1>
            <h4 class="ml-auto mb-1">
              <small class="dkgray"> Section {{ selectedSectionIndex }}/{{ exportSectionsLength }} </small>
            </h4>
          </section>
          <section class="form-highlights">
            <div class="row">
              <div class="col">
                <label class="main-label mt-0">CONSTRUCTION SF</label>
                <p class="m-0 dkgray">{{ numberWithCommas(selectedSection?.construction_sqft ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">OCCUPIED SF</label>
                <p class="m-0 dkgray">{{ numberWithCommas(selectedSection?.occupied_sqft ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">CIRCULATION</label>
                <p class="m-0 dkgray">{{ project.building_circulation_factor ?? 0 }}%</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">RENTAL SF</label>
                <p class="m-0 font-weight-bold">{{ numberWithCommas(selectedSection?.osf_w_circ ?? 0) }}</p>
              </div>
              <div class="col">
                <label class="main-label mt-0">Total Rent/ Yr</label>
                <p class="m-0 font-weight-bold">
                  {{ selectedSection?.yearlyRentalRate | USDollar }}
                </p>
              </div>
            </div>
          </section>
          <section class="border-black border-bottom-heavy">
            <div class="row m-0 py-1 align-items-center">
              <div class="col-2 p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Trade Name</label>
              </div>
              <div class="col text-center p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">SF</label>
              </div>
              <div class="col text-right p-0" *ngFor="let fs of selectedSection?.funding_sources">
                <label class="main-label m-0 p-0 text-uppercase dkgray">{{ fs.abbreviation }}</label>
              </div>
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Cost/SF</label>
              </div>
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Total</label>
              </div>
            </div>
            <div class="row d-flex align-items-start m-0" *ngFor="let l of selectedSection?.lines">
              <div class="col-2 p-0">
                <div class="border-primary border-top py-2">
                  <p class="m-0 full-width">{{ l.name }}</p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-center">
                  <p class="m-0 full-width">{{ numberWithCommas(l.sqft || 0) || 0 }}</p>
                </div>
              </div>
              <div class="col p-0" *ngFor="let fs of l?.funding_sources">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0 full-width">{{ fs.item_value || 0 | USDollar }}</p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0">{{ (!l.sqft ? 0 : (l.subtotal || 0) / l.sqft) | USDollar }}</p>
                </div>
              </div>
              <div class="col p-0">
                <div class="border-primary border-top py-2 text-right">
                  <p class="m-0">{{ l.subtotal ?? 0 | USDollar }}</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="row m-0">
              <div class="col-2 py-2 px-0">
                <p class="m-0 black">
                  <b> Total Project Budget </b>
                </p>
              </div>
              <div class="col text-center py-2 px-0">
                <p class="m-0 dkgray">{{ numberWithCommas(selectedSection?.construction_sqft ?? 0) }}</p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> TOTAL SF </label>
              </div>
              <div class="col py-2 px-0 text-right" *ngFor="let fs of selectedSection?.funding_sources">
                <p class="m-0">
                  <b>{{ fs.subtotal ?? 0 | USDollar }}</b>
                </p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> {{ fs.abbreviation }} </label>
              </div>
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b
                    >{{
                      (parseNumber(selectedSection?.subtotal) || 0) / (selectedSection?.construction_sqft || 0)
                        | USDollar
                    }}
                  </b>
                </p>
                <label class="main-label m-0 py-2 px-0 text-uppercase dkgray"> TOTAL COST/SF </label>
              </div>
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b>{{ selectedSection?.subtotal ?? 0 | USDollar }}</b>
                </p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> TOTAL </label>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </kendo-pdf-export>

  <kendo-pdf-export
    #summary
    paperSize="Letter"
    [landscape]="true"
    forcePageBreak=".page-break"
    [margin]="{ bottom: 50 }"
  >
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <section>
            <div class="row align-items-center mt-3">
              <div class="col-1">
                <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  <span class="accent"> 1call.cloud </span>
                  <br />
                  A service of University Hospital Authority & Trust
                </p>
              </div>
              <div class="col pl-0">
                <p class="dkgray m-0">
                  1000 NE 13th St, Nicholson Tower, STE 6900
                  <br />
                  Oklahoma City, OK 73104
                </p>
              </div>
              <div class="col-2 pl-0">
                <p class="dkgray m-0">
                  ph 405.271.8001 <br />
                  fx 405.271.1301
                </p>
              </div>
              <div class="col-auto">
                <p class="dkgray m-0">
                  {{ getCurrentTime() }} <br />
                  {{ getCurrentDate() }}
                </p>
              </div>
              <div class="col-auto text-right">
                <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
              </div>
            </div>
          </section>
        </section>
      </ng-template>
      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0 text-truncate">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> PEB Construction SF </label>
                <p>
                  {{ numberWithCommas(selectedSection?.construction_sqft || 0) }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="full-width">
        <ng-container *ngIf="exportShowAllSections">
          <section class="form-title d-flex align-items-end mt-5 border-black border-bottom-heavy">
            <h1 class="black mb-1">
              <b class="mr-4">Preliminary Estimated Budget</b>
              <small class="dkgray"> Project Totals </small>
            </h1>
            <h4 class="ml-auto mb-1">
              <small class="dkgray"> All Sections </small>
            </h4>
          </section>
          <section class="border-black border-bottom-heavy">
            <div class="row m-0 py-1 align-items-center">
              <div class="col-3 p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Trade Name</label>
              </div>
              <!-- <div class="col text-center p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">SF</label>
              </div> -->
              <div class="col text-right p-0" *ngFor="let s of selectedOption?.sections">
                <label class="main-label m-0 p-0 text-uppercase dkgray">{{ s.name }}</label>
              </div>
              <!-- <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Cost/SF</label>
              </div> -->
              <div class="col text-right p-0">
                <label class="main-label m-0 p-0 text-uppercase dkgray">Total</label>
              </div>
            </div>
            <div
              class="row m-0 py-1 align-items-center border-primary border-top"
              *ngFor="let l of selectedOption?.lines; let i = index"
            >
              <div class="col-3 pt-1 pb-1 p-0">
                <p class="m-0 p-0 full-width">{{ l.name }}</p>
              </div>
              <div class="col p-0" *ngFor="let s of selectedOption?.sections">
                <div class="py-1 text-right">
                  <p class="m-0 full-width">{{ s.lines?.length && (s.lines[i]?.subtotal ?? 0) | USDollar }}</p>
                </div>
              </div>
              <div class="col text-right p-0">
                <div class="py-1 text-right">
                  <p class="m-0">{{ l.total ?? 0 | USDollar }}</p>
                </div>
              </div>
            </div>
            <!-- TODO: PEB | Adam - Sometimes these lines get offset when the section is interrupted by a page break -->
            <!-- <div class="col p-0">
                <div class="border-primary border-top py-2 text-center" *ngFor="let l of selectedOption?.lines">
                  <p class="m-0 full-width">-</p>
                </div>
              </div> -->
            <!-- <div class="col p-0">
                <div class="border-primary border-top py-2 text-right" *ngFor="let l of selectedOption?.lines">
                  <p class="m-0">-</p>
                </div>
              </div> -->
          </section>
          <section>
            <div class="row m-0">
              <div class="col-2 py-2 pl-0">
                <p class="m-0 black">
                  <b> Total Project Budget </b>
                </p>
              </div>
              <!-- <div class="col text-center py-2 px-0"></div> -->
              <div class="col py-2 px-0 text-right" *ngFor="let s of selectedOption?.sections">
                <p class="m-0">
                  <b>{{ s.subtotal ?? 0 | USDollar }}</b>
                </p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> {{ s.name }} </label>
              </div>
              <!-- <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b>{{ ((parseNumber(selectedOption?.total) || 0) / (selectedSection?.construction_sqft || 0)) | USDollar }} </b>
                </p>
                <label class="main-label m-0 py-2 px-0 text-uppercase dkgray"> TOTAL COST/SF </label>
              </div> -->
              <div class="col py-2 px-0 text-right">
                <p class="m-0">
                  <b>{{ selectedOption?.total ?? 0 | USDollar }}</b>
                </p>
                <label class="main-label m-0 p-0 text-uppercase dkgray"> TOTAL </label>
              </div>
            </div>
          </section>
        </ng-container>
      </section>
    </div>
  </kendo-pdf-export>
</div>
