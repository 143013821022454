import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { Task, TaskAccessoryData } from 'src/app/types';
import { KeyControlsService } from '../../services';

@Component({
  selector: 'app-key-control-icon-badge',
  templateUrl: './key-control-icon-badge.component.html',
  styleUrls: ['./key-control-icon-badge.component.scss'],
})
export class KeyControlIconBadgeComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  TASK_STATUS = TaskStatus;
  accessoryData: TaskAccessoryData;
  isFlagged: boolean;

  private countUpdated: any;
  constructor(private keyControlsService: KeyControlsService) {}

  ngOnInit() {
    this.getAccessoryData(this.task?.accessory_data);
    this.countUpdated = this.keyControlsService.keyControlCountUpdated.subscribe((task) => {
      if (task && task.id === this.task?.id) {
        this.task = task;

        this.getAccessoryData(this.task?.accessory_data);
      }
    });
  }

  ngOnDestroy() {
    try {
      this.countUpdated.unsubscribe();
    } catch (e) {}
  }

  getAccessoryData(accessoryData: any) {
    if (accessoryData && typeof accessoryData === 'string') {
      this.accessoryData = JSON.parse(accessoryData);
    } else {
      this.accessoryData = accessoryData;
    }

    if (this.accessoryData?.reviewChain?.length) {
      this.isFlagged = !!this.accessoryData.reviewChain.find((review) => review.status === TaskReviewStatus.Rejected);
    }
  }
}
