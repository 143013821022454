export class MeetingDashboardFilter {
  // Relates to Upcoming/ Recent
  public status: 'upcoming' | 'recent' | 'today' = 'upcoming';
  // all meetings need to be due between start and end date
  public startDate: Date | string;
  public endDate: Date | string;
  // how many days ago to where a meeting counts as a recent meeting
  public daysForRecent = 7;

  constructor(startDate: Date | string, endDate: Date | string, daysForRecent?: number) {
    this.startDate = startDate;
    this.endDate = endDate;
    if (daysForRecent) {
      this.daysForRecent = daysForRecent;
    }
  }
}
