import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArfsService, DateService } from 'src/app/services';
import { ArfInvoiceAmount, CostCode, SubCostCode, SubCostCodeBudget } from '../../types';

@Component({
  selector: 'app-cost-code-selection-form',
  templateUrl: './cost-code-selection-form.component.html',
  styleUrls: ['./cost-code-selection-form.component.scss'],
})
export class CostCodeSelectionFormComponent implements OnInit, OnChanges {
  @Input() arfInvoiceAmount: ArfInvoiceAmount;
  @Input() subCostCodeBudget: SubCostCodeBudget;
  @Input() costCodes: CostCode[] = [];
  @Input() allowAdditionalCodes: boolean;
  @Input() disabled = false;
  @Input() amount: number;
  @Output() filterAllCostCodes = new EventEmitter<{ oldSubCostCode: SubCostCode; newSubCostCode: SubCostCode }>();

  public costCodeFormGroup: FormGroup = this.fb.group({
    sub_cost_code_budget_id: ['', [Validators.required]],
    amount: [''],
    subCostCodeTitle: [],
  });

  get sub_cost_code_budget_id(): AbstractControl {
    return this.costCodeFormGroup.get('sub_cost_code_budget_id');
  }

  constructor(private arfService: ArfsService, private dateService: DateService, private fb: FormBuilder) {}

  filteredCostCodes: CostCode[] = [];
  fiscalYear: number;
  selectedCostCode: CostCode;
  selectedSubCostCode: SubCostCode;

  ngOnInit(): void {
    if (this.arfInvoiceAmount) {
      this.sub_cost_code_budget_id.setValue(this.arfInvoiceAmount.sub_cost_code_budget_id, { emitEvent: false });
      const amount = (this.amount || this.arfInvoiceAmount.amount)?.toFixed(2);
      this.costCodeFormGroup.get('amount').setValue(amount, { emitEvent: false });
      this.selectedCostCode = this.arfInvoiceAmount.sub_cost_code_budget?.cost_code;
      this.selectedSubCostCode = this.arfInvoiceAmount.sub_cost_code_budget?.sub_cost_code;
    }

    this.arfService.arfInvoiceAmountValueChanged.subscribe((arfInvoiceAmount) => {
      if (
        (arfInvoiceAmount.id && arfInvoiceAmount.id === this.arfInvoiceAmount.id) ||
        arfInvoiceAmount.index === this.arfInvoiceAmount.index
      ) {
        this.costCodeFormGroup.get('amount').setValue((arfInvoiceAmount.amount || 0).toFixed(2), { emitEvent: false });
      }
    });
    this.setSubCostCodeTitle();
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.costCodeFormGroup.disable({ emitEvent: false });
    } else {
      this.costCodeFormGroup.enable({ emitEvent: false });
    }

    this.filteredCostCodes = this.costCodes;

    this.costCodeFormGroup.valueChanges.subscribe((value) => {
      value.amount = Number(value.amount || 0);
      delete value.subCostCodeTitle;
      const updatedArfInvoiceAmount = { ...this.arfInvoiceAmount, ...value };

      this.arfService.arfInvoiceAmountUpdated.emit(updatedArfInvoiceAmount);
    });
  }

  setSubCostCodeTitle() {
    this.costCodeFormGroup
      .get('subCostCodeTitle')
      .setValue(
        (this.subCostCodeBudget?.code &&
          this.subCostCodeBudget?.label &&
          `${this.subCostCodeBudget?.code} - ${this.subCostCodeBudget?.label}${
            this.selectedSubCostCode?.fiscal_year ? ` - FY${this.selectedSubCostCode?.fiscal_year}` : ''
          }`) ||
          ''
      );
  }

  setCostCodeId(subCostCode: SubCostCode): void {
    this.selectedSubCostCode = subCostCode;
    const subCostCodeBudget = subCostCode.sub_cost_code_budget;
    if (subCostCodeBudget?.id !== this.sub_cost_code_budget_id.value) {
      this.selectedCostCode = subCostCode?.cost_code;
      this.sub_cost_code_budget_id.setValue(subCostCodeBudget?.id || null);
      if (!subCostCodeBudget?.id) {
        this.selectedCostCode = null;
      }
      this.subCostCodeBudget = subCostCodeBudget || null;
    }
    this.setSubCostCodeTitle();
  }

  filterCostCodes(value = null): void {
    const sub_cost_code = value;
    if (sub_cost_code) {
      this.filteredCostCodes = this.costCodes.filter((cc) => {
        const sccs = cc.sub_cost_codes.filter((scc) => {
          return (
            (scc.label && scc.label.toLowerCase().includes(sub_cost_code.toLowerCase())) ||
            (scc.code && scc.code.toLowerCase().includes(sub_cost_code.toLowerCase()))
          );
        });
        return sccs.length > 0;
      });
    } else {
      this.filteredCostCodes = this.costCodes;
      this.selectedSubCostCode = null;
      this.sub_cost_code_budget_id.setValue(null);
    }
  }

  removeSubCostCode() {
    this.arfService.arfInvoiceAmountDeleted.emit(this.arfInvoiceAmount);
  }
}
