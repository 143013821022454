import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HowToGuide } from 'src/app/types';

@Component({
  selector: 'app-how-to-guide-dialog',
  templateUrl: './how-to-guide-dialog.component.html',
  styleUrls: ['./how-to-guide-dialog.component.scss'],
})
export class HowToGuideDialogComponent implements OnInit {
  public instructions: HowToGuide[] = [];
  public title = '';
  constructor(private _dialogRef: MatDialogRef<HowToGuideDialogComponent>, @Inject(MAT_DIALOG_DATA) public _data) {}

  ngOnInit(): void {
    this.title = this._data.title ?? '';
    if (Array.isArray(this._data?.instructions)) {
      this.instructions = this._data.instructions;
    }
  }

  public close() {
    this._dialogRef.close();
  }
}
