import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleErrorService } from 'src/app/services/handle-error.service';
import { ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private _host: string = environment.serviceHost;
  private _homeUrl = `${this._host}/api/v1/home`;

  constructor(private _http: HttpClient, private _handleErrorService: HandleErrorService) {}

  public getHomeData(
    getTasks: boolean,
    getWorkOrders: boolean,
    getProjects: boolean,
    taskTimespan: string | number,
    taskType: string,
    taskRole: string,
    workOrderTimespan: string | number,
    workOrderRole: string,
    projectStatus: string,
    projectRole: string
  ): Observable<any> {
    return this._http
      .get(
        `${this._homeUrl}?tasks=${getTasks}&workOrders=${getWorkOrders}&projects=${getProjects}&taskTimespan=${taskTimespan}&taskType=${taskType}&taskRole=${taskRole}&workOrderTimespan=${workOrderTimespan}&workOrderRole=${workOrderRole}&projectStatus=${projectStatus}&projectRole=${projectRole}`
      )
      .pipe(
        map((response: ServiceResponse) => response.data),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }
}
