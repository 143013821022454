<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"> </app-base-dialog>
<mat-dialog-content id="file-preview-dialog" data-test-id="file-preview-dialog" class="file-preview-dialog">
  <app-file-preview [file]="_data.file" (fileLoaded)="getFileInfo($event)"></app-file-preview>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="isImage || isPDF || isExcel || isDocx || isTxt"
    mat-flat-button
    (click)="newWindow()"
    type="button"
    color="accent"
    class="ml-3 pl-2 pr-3"
  >
    <mat-icon> open_in_new </mat-icon>
    <span class="ml-1"> Open In New Tab </span>
  </button>
  <button mat-flat-button (click)="downloadFile()" type="button" color="primary" class="ml-3 pl-2 pr-3">
    <mat-icon> get_app </mat-icon>
    <span class="ml-1"> Download File </span>
  </button>
</mat-dialog-actions>
