<div
  id="base-dialog"
  data-test-id="base-dialog"
  class="base-dialog modal-wide"
  cdkDrag
  [cdkDragDisabled]="!draggable"
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary="body"
>
  <div class="row align-items-center mb-4">
    <div class="col">
      <h5 class="dkblue m-0 font-weight-semibold">
        {{ title }}
      </h5>
    </div>
    <div class="col-auto">
      <div *ngIf="draggable" class="base-dialog-handle" cdkDragHandle>
        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
      </div>
    </div>
    <div class="col-auto text-right pl-0">
      <a (click)="close()" class="close-modal ml-3">
        <i class="material-icons gray">clear</i>
      </a>
    </div>
  </div>
</div>
