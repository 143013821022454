import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/guards';

import { DashboardComponent } from 'src/app/workspaces/construction/pages';

import { TasksComponent } from 'src/app/pages';

const constructionRoutes: Routes = [
  {
    path: 'construction',
    children: [{ path: '', component: DashboardComponent, canActivate: [AuthGuard] }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(constructionRoutes)],
  exports: [RouterModule],
})
export class ConstructionRoutingModule {}
