import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserProfilePreviewComponent, UserSelectModalComponent } from 'src/app/components';
import { UserType } from 'src/app/enums';
import {
  AuthService,
  MessagingSystemService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  RequestService,
  UserService,
} from 'src/app/services';
import { ProjectUser, Task, User } from 'src/app/types';
import { ProjectDirectoryService, ProjectTenantService } from 'src/app/workspaces/construction/services';

@Component({
  selector: 'app-project-directory',
  templateUrl: './project-directory.component.html',
  styleUrls: ['./project-directory.component.scss'],
})
export class ProjectDirectoryComponent implements OnInit, OnDestroy {
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  public excludeVendors = true;
  public showDesktop: boolean;

  public showIpad: boolean;

  public divWidth: number;

  constructor(
    private userService: UserService,
    public projectService: ProjectService,
    private taskService: ProjectTaskService,
    private projectTenantService: ProjectTenantService,
    private requestService: RequestService,
    public authService: AuthService,
    private messagingService: MessagingSystemService,
    public dialog: MatDialog,
    private modalService: ModalService,
    public projectDirectoryService: ProjectDirectoryService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  currentUserDisplay: UserType = UserType.Staff;
  public requesterId: number;
  public userType = UserType;

  public fieldToSortBy: string;
  public sortDirection: 'asc' | 'desc' = 'desc';
  public searchString = '';

  private projectSelectedSubscription: Subscription;

  ngOnInit() {
    this.projectDirectoryService.users$[UserType.Staff] = new BehaviorSubject([]);
    this.projectDirectoryService.users$[UserType.Tenant] = new BehaviorSubject([]);
    this.projectDirectoryService.users$[UserType.Vendor] = new BehaviorSubject([]);
    setTimeout(async () => {
      if (!this.authService.isProjectVendor(this.projectService.currentSelectedProjectId)) {
        const project = await this.projectService
          .getProjectById(this.projectService.currentSelectedProjectId, ['requester_id'])
          .toPromise();

        this.requesterId = project.requester_id;
      }
      if (!this.projectService.currentSelectedProjectId || !this.projectService.currentSelectedProject) {
        this.projectSelectedSubscription = this.projectService.projectSelectedEvent.subscribe((project) =>
          this.projectDirectoryService.loadUsersForProject(project.id, project.request_id)
        );
      } else {
        await this.projectDirectoryService.loadUsersForProject(
          this.projectService.currentSelectedProjectId,
          this.projectService.currentSelectedProject.request_id
        );
      }
      const preferences = JSON.parse(localStorage.getItem('preferences'));
      this.sortDirection = preferences ? preferences.pd_sort_order : null;
      this.fieldToSortBy = preferences ? preferences.pd_sort_by_field : null;
      if (!this.fieldToSortBy) {
        localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('pd_sort_by_field', 'id')));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.projectSelectedSubscription) {
      this.projectSelectedSubscription.unsubscribe();
    }
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    this.showDesktop = this.divWidth > 800;
    this.showIpad = !this.showDesktop;
  }

  // public startConversation(toUser: User) {
  //   this.userService.getUserById(toUser.id).subscribe((user) =>
  //     this.messagingService.events.openCreateConversationPanelEvent.emit({
  //       subject: '',
  //       projectId: this.projectService.currentSelectedProjectId,
  //       followers: [user],
  //     })
  //   );
  // }

  // getProfileThumbnailUrl(userId: number) {
  //   return this.userService.getProfileThumbnailUrl(userId);
  // }

  get isAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }
  // private addUser(userId: number) {
  //   if(!this.existingStaffIds.includes(+userId)) {
  //     this.existingStaffIds.push(+userId);
  //     this.userService.getUserById(userId, ['first_name', 'last_name', 'company_name', 'id', 'title', 'trades', 'user_type_id', 'roles', 'role_id']).subscribe(user =>
  //       this.addProjectUser(this.getProjectUserFromUser(user))
  //     )
  //   }
  // }

  // converts a user data type to a ProjectUser
  // private getProjectUserFromUser(user: User) {
  //   const ret: ProjectUser = {
  //     id: user.id,
  //     firstName: user.first_name,
  //     lastName: user.last_name,
  //     company_id: user.company_id,
  //     type: user.user_type_id,
  //     title: user.title,
  //     roles: this.getRoleNames(user.role_id.filter(r => +r.resource_id === 3).map(r => r.role_id))
  //   };
  //   if (user.user_type_id === UserType.Vendor) {
  //     ret.trade = user.trades.map(ele => ele.name).join(', ');
  //   }
  //   return ret;
  // }

  // adds a projectUser to the appropriate bucket
  // private addProjectUser(user) {
  //   const arr = user.type === 1 ? this.staffUsers : user.type === 2 ? this.tenantUsers : this.vendorUsers;
  //   const foundUser = arr.find(u => +u.id === +user.id);
  //   // check if they are already in the list, and if so, just add the new role
  //   if (foundUser) {
  //     foundUser.roles = user.roles.concat(foundUser.roles);
  //   } else {
  //     arr.push(user);
  //   }
  // }

  // determines if the staff button is selected currently
  public isStaff() {
    return this.currentUserDisplay === UserType.Staff;
  }

  // sets the selected button to staff
  public setStaff() {
    // this.loadStaffForProject();
    this.currentUserDisplay = UserType.Staff;
  }

  // returns the staff users as an observable
  public getStaff(): Observable<ProjectUser[]> {
    return this.projectDirectoryService.users$[UserType.Staff].asObservable();
  }

  // determines if the tenants button is selected currently
  public isTenants() {
    return this.currentUserDisplay === UserType.Tenant;
  }

  // sets the tenant button to be currently selected
  public setTenant() {
    this.currentUserDisplay = UserType.Tenant;
  }

  // returns all the tenant users as an observable
  public getTenants(): Observable<ProjectUser[]> {
    // this.projectDirectoryService.users$[UserType.Tenant].asObservable().subscribe( tenants => console.log(tenants));
    return this.projectDirectoryService.users$[UserType.Tenant].asObservable();
  }

  // determines if the vendors button is selected currently
  public isVendors() {
    return this.currentUserDisplay === UserType.Vendor;
  }

  // sets the vendor button to be current selected
  public setVendor() {
    this.currentUserDisplay = UserType.Vendor;
  }

  public getUsers(): Observable<ProjectUser[]> {
    if (this.isStaff()) {
      return this.getStaff();
    } else if (this.isTenants()) {
      return this.getTenants();
    } else {
      return this.getVendors();
    }
  }

  // returns all the vendor users as an observable
  public getVendors(): Observable<ProjectUser[]> {
    return this.projectDirectoryService.users$[UserType.Vendor].asObservable();
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  public async removeUserConfirmation(user) {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: `Remove ${user.firstName}`,
        descriptionText: 'Are you sure you want to remove this tenant?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.removeFromProject(user.id);
        }
      });
  }

  private async removeFromProject(userId: number) {
    const currentProject = this.projectService.currentSelectedProjectId;

    await this.taskService
      .getTasksByUserIds([userId])
      .toPromise()
      .then(async (tasks: Task[]) => {
        tasks.map(async (task) => {
          const inCurrentProject = task.project_id === currentProject;
          const isAssignedToUser = task.assigned_user_id === userId;
          const userIsFollowing = task.followers && task.followers.filter((follower) => follower.id === userId);

          if (inCurrentProject && (isAssignedToUser || userIsFollowing)) {
            if (isAssignedToUser) {
              this.projectService.updateTask({ id: task.id, assigned_user_id: null }).subscribe((updatedTask) => {});
            }
            await this.taskService.removeFollowerFromTask(task.id, userId).toPromise();
          }
        });
        this.projectTenantService
          .getTenantsForProject(currentProject)
          .toPromise()
          .then((projectTenants) => {
            projectTenants.map(async (projectTenant) => {
              if (projectTenant.representative_id === userId) {
                await this.projectTenantService.unlinkTenantFromProject(projectTenant.id).toPromise();
              }
            });
          });
        await this.projectDirectoryService.loadUsersForProject(
          currentProject,
          this.projectService.currentSelectedProject.request_id
        );
      });
  }

  public async manageFollowers() {
    const project = await this.projectService
      .getProjectById(this.projectService.currentSelectedProjectId, ['followers'])
      .toPromise();
    const followers = project?.followers ?? [];

    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Update Followers',
          createUser: { title: 'Guest', guestUser: false },
          preSelectedUsers: [...followers.map((follower) => ({ ...follower, id: +follower.id }))],
          minimumRemovalPermission: this.authService.isAppAdmin || this.authService?.isWorkspaceManager,
          allowedUserTypeIds: [UserType.Staff, UserType.Tenant],
          excludeVendors: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (selectedUsers || deSelectedUsers) {
          const removalIds = deSelectedUsers?.map((deSelectedUser: User) => deSelectedUser.id) || [];
          const additionIds = selectedUsers?.map((selectedUser: User) => selectedUser.id) || [];
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Followers...');
          if (removalIds?.length > 0) {
            void (await this.projectService
              .updateFollowers(this.projectService?.currentSelectedProjectId, {
                followers: removalIds,
                action: 'remove',
              })
              .toPromise());
          }
          if (additionIds?.length > 0) {
            void (await this.projectService
              .updateFollowers(this.projectService.currentSelectedProjectId, {
                followers: additionIds,
                action: 'add',
              })
              .toPromise());
          }

          // refresh if removal or addition was done
          if (removalIds?.length || additionIds?.length) {
            await this.projectDirectoryService.loadUsersForProject(
              this.projectService.currentSelectedProjectId,
              this.projectService.currentSelectedProject.request_id
            );
            await this.projectService.refreshNeeded$.next();
            this.progressIndicatorService.close();
          } else {
            this.progressIndicatorService.close();
          }
        }
      });
  }

  public async openNewProjectContactDialog() {
    // attempt to load the request users and not allow them to be de-selected
    const requestContacts = [];
    try {
      const requestData = await this.requestService
        .getRequestById(this.projectService.currentSelectedProject.request_id, ['contact_ids'])
        .toPromise();
      const userIds = JSON.parse(requestData.contact_ids);
      userIds.forEach((id) => requestContacts.push({ id, canNotDeselect: false }));
    } catch (e) {}

    // open the user select dialog and update the project contacts
    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Modify Project Contacts',
          preSelectedUsers: requestContacts,
          createUser: { guestUser: false },
          selectStyleIsAddOnly: false,
          excludeVendors: this.excludeVendors,
          defaultUserTypeId: UserType.Tenant,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if ((selectedUsers && selectedUsers.length) || (deSelectedUsers && deSelectedUsers.length)) {
          // although loadUserForProject has a close for progressIndicator, I added another in case that function ever gets refactored
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Contacts...');
          this.projectService
            .modifyProjectContacts(
              this.projectService.currentSelectedProjectId,
              selectedUsers.map((user) => user.id),
              deSelectedUsers.map((user) => user.id)
            )
            .subscribe(() =>
              this.projectDirectoryService
                .loadUsersForProject(
                  this.projectService.currentSelectedProjectId,
                  this.projectService.currentSelectedProject.request_id
                )
                .then(() => this.progressIndicatorService.close())
            );
        }
      });
  }

  public openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  inviteUser(user: User) {
    this.modalService.openInviteUserModal({ users: [user] }).subscribe();
  }

  public updateSortByField(field: string) {
    if (this.isStaff()) {
      if (field === this.fieldToSortBy) {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
        localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('pd_sort_order', this.sortDirection)));
      }
      this.fieldToSortBy = field;
      localStorage.setItem(
        'preferences',
        JSON.stringify(this.addToPreferences('pd_sort_by_field', this.fieldToSortBy))
      );
    }
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }
}
