import { Component, Input, OnChanges } from '@angular/core';
import { ModalService, UserService } from '../../services';
import { User } from '../../types';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnChanges {
  @Input() public user: User;
  @Input() public date: any;
  @Input() public avatarSize = 30;
  @Input() public avatarBorder: boolean;
  @Input() public truncate = false;
  @Input() public avatarOverlayIcon = {
    opacity: 0,
    icon: '',
    iconColor: '',
    iconBackground: '',
    iconTooltip: '',
  };
  @Input() public additionalUserFields = [];
  @Input() private disableApiCall: boolean;
  @Input() openProfileDialog = true;

  // Default user info to display
  private allUserFields = [];
  public defaultUserFields = ['first_name', 'last_name'];
  private missingUserFields = false;

  constructor(private modalService: ModalService, private userService: UserService) {}

  async ngOnChanges() {
    this.allUserFields = [...this.defaultUserFields, ...(this.additionalUserFields || [])];
    if (this.user?.id) {
      const allFieldsPopulated = this.checkUserFields();
      if (!allFieldsPopulated && !this.disableApiCall && !this.missingUserFields) {
        this.user = await this.userService.getUserById(this.user.id, this.allUserFields).toPromise();
        this.missingUserFields = !this.checkUserFields();
      }
    }
  }

  checkUserFields() {
    let allFieldsPopulated = true;
    for (const field of this.allUserFields) {
      if (!this.user[field]) {
        allFieldsPopulated = false;
      }
    }

    return allFieldsPopulated;
  }
  async openUserProfileDialog() {
    if (this.openProfileDialog) {
      await this.modalService.openUserProfileDialog(this.user?.id).toPromise();
    }
  }
}
