import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatepickerHeaderComponent, ViewTaskDialogComponent } from 'src/app/components';
import { TaskStatus } from 'src/app/enums';
import { AuthService, ModalService, ProjectService, ProjectTaskService } from 'src/app/services';
import { Task } from 'src/app/types';

@Component({
  selector: 'app-dashboard-task',
  templateUrl: './dashboard-task.component.html',
  styleUrls: ['./dashboard-task.component.scss'],
})
export class DashboardTaskComponent implements OnInit {
  @Input() public task: Task;
  @Input() public showProject = true;

  @Output() forceReload: EventEmitter<any> = new EventEmitter();

  customHeader = DatepickerHeaderComponent;

  // checks if the task is overdue
  get isOverdue() {
    return this.getDaysUntilDue < 0;
  }
  // gets the correct phrase for the time of the task
  get timePhrase() {
    if (this.complete) {
      return 'Completed';
    }
    if (!this.getDaysUntilDue) {
      if (this.getDaysUntilDue === 0) {
        return 'Due today';
      } else {
        return 'Set due date';
      }
    } else {
      if (this.isOverdue) {
        return `Due ${-this.getDaysUntilDue} days ago`;
      } else {
        return `Due in ${this.getDaysUntilDue} day${this.getDaysUntilDue === 1 ? '' : 's'}`;
      }
    }
  }
  // checks if the task is complete
  get complete() {
    return this.task.status_name === 'Complete';
  }
  get taskStatus() {
    return TaskStatus;
  }

  constructor(
    private projectService: ProjectService,
    public authService: AuthService,
    private modalService: ModalService,
    private taskService: ProjectTaskService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  // returns how many days before the task is due
  get getDaysUntilDue() {
    if (!this.task.due_date) {
      return null;
    }
    return moment(this.task.due_date).startOf('day').diff(moment().startOf('day'), 'days');
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.task?.module_id);
  }

  public async changeTaskStatus(taskStatusId) {
    const newTask = await this.taskService.changeTaskStatus(this.task, taskStatusId);
    this.projectService.updateTask(newTask).subscribe((updatedTask) => {
      this.taskService.updateTask(updatedTask);
      if (this.task.id === updatedTask.id) {
        this.task = updatedTask;
      }
    });
  }

  // stops the click from going to the task page
  public stopPropagation(event) {
    event.stopPropagation();
  }

  public isApproval() {
    const accessoryData = JSON.parse(this.task.accessory_data);
    return accessoryData && accessoryData.isReviewItem;
  }

  public openAssignUserDialog() {
    this.modalService.openUserSearchModal(this.task).subscribe(async (selectedUser) => {
      // False is returned if the dialog was closed without a selection. Null is used to un-assign a task
      if (
        selectedUser !== false &&
        (!selectedUser ||
          selectedUser.is_login_enabled ||
          (await this.modalService
            .openInviteUserModal({
              users: [selectedUser],
              parentId: this.task.id,
              parentTypeId: 7,
            })
            .toPromise()))
      ) {
        // Assigned user id can be null if is to be cleared
        const assigned_user_id = selectedUser ? selectedUser.id : null;

        if (assigned_user_id) {
          this.task.assigned_user_id = selectedUser.id;
          this.task.assigned_user_email = selectedUser.email;
          this.task.assigned_user_first_name = selectedUser.first_name;
          this.task.assigned_user_last_name = selectedUser.last_name;
          this.task.assigned_user_login_enabled = selectedUser.is_login_enabled;
        } else {
          this.task.assigned_user_id =
            this.task.assigned_user_email =
            this.task.assigned_user_first_name =
            this.task.assigned_user_last_name =
            this.task.assigned_user_login_enabled =
              null;
        }

        // Update the task assignee
        this.projectService.updateTask({ id: this.task.id, assigned_user_id }).subscribe((task) => {
          this.taskService.updateTask(task);
          this.forceReload.emit();
        });
      }
    });
  }

  public changeTaskDueDate(dueDate) {
    this.task.due_date = dueDate;
    const formattedDueDate = (dueDate && moment(dueDate).format('YYYY-MM-DD')) || null;

    this.projectService
      .updateTask({
        id: this.task.id,
        due_date: formattedDueDate,
      })
      .subscribe((updatedTask) => {
        this.taskService.updateTask(updatedTask);
        this.forceReload.emit();
      });
  }

  openTaskDialog(taskId) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.taskChanged.subscribe((updatedTask) => {
      this.task = updatedTask;
    });
  }
}
