/**
 * Filter Object Used As Argument For ProjectListFilterPipe
 */
export class ProjectListFilter {
  public statusId = 1;

  public searchString = '';
  public byUserId: number;
  public fiveYearCapitalPlan: boolean;
  public fiveYearCapitalPlanYear: number;
}
