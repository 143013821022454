<!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->

<div
  #mainScreen
  (window:resize)="onResize($event)"
  id="requests"
  data-test-id="requests"
  class="requests app-container"
>
  <section id="main" class="main-closed ease p-4">
    <header class="mb-4">
      <div class="d-flex align-items-center">
        <h2 class="m-0 dkblue font-weight-semibold align-bottom mr-auto">Project Requests</h2>
        <div class="d-flex align-items-center"></div>
      </div>
      <p class="dkgray font-weight-normal mb-2">View project requests assigned to a workspace.</p>
    </header>

    <div class="row align-items-center">
      <div *ngIf="isDispatch" class="col-auto mb-4 pr-0">
        <app-workspace-selector (workspaceSelectedChanged)="updateWorkspaces($event)"></app-workspace-selector>
      </div>
      <div class="col-auto mb-4 list-filters">
        <mat-button-toggle-group name="requestStatus" aria-label="List">
          <mat-button-toggle
            [ngClass]="{ selectedTab: s.id === filter.statusId }"
            (click)="setSelectedRequest(s.id)"
            *ngFor="let s of requestStatuses"
            class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
          >
            <i *ngIf="s.id === 1" class="material-icons gray mr-2">assignment_late</i>
            <i *ngIf="s.id === 3" class="material-icons green mr-2">assignment_turned_in</i>
            <i *ngIf="s.id === 2" class="material-icons orange mr-2">check_circle_outline</i>
            <i *ngIf="s.id === 4" class="material-icons red mr-2">cancel</i>
            <i *ngIf="s.id === -1" class="material-icons gray mr-2">language</i>
            {{ s.name }}
            <span
              class="small-badge white ml-2 lh-18"
              [ngClass]="{
                'bg-ltblue': s.id === filter.statusId,
                'bg-gray': s.id !== filter.statusId,
                'bg-red': s.id === 1 && requestStatusesCount['1'] > 0
              }"
            >
              {{ requestStatusesCount[s.id] }}
            </span>
          </mat-button-toggle>
          <!-- <mat-button-toggle
            [ngClass]="{ selectedTab: filter.statusId === 0 }"
            (click)="setSelectedRequest(0)"
            class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
          >
            <i class="material-icons gray mr-2">all_inbox</i>
            All Requests
            <span
              class="small-badge white ml-2 lh-18"
              [ngClass]="{ 'bg-ltblue': filter.statusId === 0, 'bg-gray': filter.statusId !== 0 }"
            >
              {{ requestStatusesCount['0'] }}
            </span>
          </mat-button-toggle> -->
        </mat-button-toggle-group>
      </div>
      <div class="col mb-4"></div>
      <div class="col-auto mb-4 pl-0">
        <mat-form-field appearance="outline" class="ml-4">
          <mat-icon class="dkblue" matPrefix>search</mat-icon>
          <input
            matInput
            [(ngModel)]="filter.searchTerm"
            placeholder="Search Project Requests"
            (ngModelChange)="updateFilter()"
          />
          <mat-icon *ngIf="filter.searchTerm" (click)="updateSearchTerm('')" class="ltgray pointer" matSuffix
            >cancel
          </mat-icon>
        </mat-form-field>
      </div>
    </div>

    <main id="default_view">
      <div class="m-portlet mb-0">
        <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
          <div class="row align-items-center">
            <div
              class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
              style="width: 110px"
              [ngClass]="{ sortedBy: fieldToSortBy === 'sortCode' }"
            >
              <h6 (click)="updateSortByField('sortCode')" class="m-0 text-truncate header-title mr-auto">Code</h6>
              <i *ngIf="fieldToSortBy === 'sortCode'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
              style="max-width: 110px"
              [ngClass]="{ sortedBy: fieldToSortBy === 'building.code' }"
            >
              <h6 (click)="updateSortByField('building.code')" class="m-0 text-truncate header-title mr-auto">
                Location
              </h6>
              <i *ngIf="fieldToSortBy === 'building.code'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div>
            <div
              class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'short_description' }"
            >
              <h6 (click)="updateSortByField('short_description')" class="m-0 text-truncate header-title mr-auto">
                Short Description / Topic
              </h6>
              <i *ngIf="fieldToSortBy === 'short_description'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div>
            <!-- <div
              *ngIf="workspace?.id === Workspace.Construction"
              class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
              style="max-width: 110px;"
              [ngClass]="{ sortedBy: fieldToSortBy === 'request_type.name' }"
            >
              <h6
                (click)="updateSortByField('request_type.name')"
                class="m-0 text-truncate header-title mr-auto"
              >
                Type
              </h6>
              <i *ngIf="fieldToSortBy === 'request_type.name'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div> -->
            <div
              class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
              [ngClass]="{ sortedBy: fieldToSortBy === 'created_datetime' }"
            >
              <h6 (click)="updateSortByField('created_datetime')" class="m-0 text-truncate header-title mr-auto">
                Submitted
              </h6>
              <i *ngIf="fieldToSortBy === 'created_datetime'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div>
            <div
              class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
              [ngClass]="{ sortedBy: fieldToSortBy === 'requester.first_name' }"
            >
              <h6 (click)="updateSortByField('requester.first_name')" class="m-0 text-truncate header-title mr-auto">
                Requester
              </h6>
              <i *ngIf="fieldToSortBy === 'requester.first_name'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div>
            <!-- <div
              *ngIf="workspace?.id === Workspace.Construction"
              class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
              style="max-width: 110px;"
              [ngClass]="{ sortedBy: fieldToSortBy === 'budget.name' }"
            >
              <h6
                (click)="updateSortByField('budget.name')"
                class="m-0 text-truncate header-title mr-auto"
              >
                Budget
              </h6>
              <i *ngIf="fieldToSortBy === 'budget.name'" matRipple class="material-icons">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </i>
            </div> -->
          </div>
        </div>
        <div
          matRipple
          class="m-portlet__body item pointer p-3 pt-4 pb-4"
          [routerLink]="['/requests/' + r.id]"
          *ngFor="
            let r of allRequests
              | requestListFilter: filter
              | orderByField: fieldToSortBy:sortDirection
              | slice: startIndex:endIndex;
            let i = index
          "
          data-id-test="selected-request-type-wrapper"
        >
          <div class="row align-items-center">
            <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 110px">
              <p class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle">
                {{ r.code }}
              </p>
              <i *ngIf="r.status_id === 1" class="material-icons gray ml-auto">assignment_late</i>
              <i *ngIf="r.status_id === 3" class="material-icons green ml-auto">assignment_turned_in</i>
              <i *ngIf="r.status_id === 2" class="material-icons orange ml-auto">check_circle_outline</i>
              <i *ngIf="r.status_id === 4" class="material-icons red ml-auto">cancel</i>
            </div>
            <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
              <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
                {{ r.building?.code }}{{ r.floor?.code ? '-' : '' }}{{ r.floor?.code }}
              </p>
            </div>
            <div class="col-3 pl-3 pr-3 d-flex flex-column data-item">
              <p *ngIf="r.short_description" class="m-0 dkblue font-weight-semibold d-block">
                {{ r.short_description }}
                <span class="m-0 dkgray font-weight-normal d-block">
                  {{
                    r.topic_group?.name || r.topic?.topic_group?.name
                      ? (r.topic_group?.name || r.topic?.topic_group?.name) + ' >'
                      : ''
                  }}
                  {{
                    r.topic_category?.name || r.topic?.topic_category?.name
                      ? (r.topic_category?.name || r.topic?.topic_category?.name) + ' >'
                      : ''
                  }}
                  {{ r.topic?.name }}
                </span>
              </p>
              <p *ngIf="!r.short_description" class="m-0 dkblue font-weight-semibold d-block">
                {{
                  r.topic_group?.name || r.topic?.topic_group?.name
                    ? (r.topic_group?.name || r.topic?.topic_group?.name) + ' >'
                    : ''
                }}
                {{
                  r.topic_category?.name || r.topic?.topic_category?.name
                    ? (r.topic_category?.name || r.topic?.topic_category?.name) + ' >'
                    : ''
                }}
                {{ r.topic?.name }}
              </p>
            </div>
            <!-- <div
              *ngIf="workspace?.id === Workspace.Construction"
              class="col pl-3 pr-3 d-flex align-items-center data-item"
              style="max-width: 110px;"
            >
              <h6
                class="m-0 mr-auto dkblue pointer font-weight-semibold"
                [ngClass]="{ green: r.request_type?.id === 1 }"
              >
                {{ r.request_type?.name }}
              </h6>
            </div> -->
            <div class="col pl-3 pr-3 d-flex data-item">
              <p class="m-0 dkblue font-weight-normal d-block">
                {{ r.created_datetime | date: 'EEE, MMM dd, yyyy • hh:mm aa' }}
              </p>
            </div>
            <div class="col pl-3 pr-3 d-flex align-items-center data-item">
              <app-profile-thumbnail
                [noBorder]="true"
                class="d-inline-block mr-2"
                [height]="22"
                [width]="22"
                [userId]="r.requester?.id"
              ></app-profile-thumbnail>
              <p class="m-0 dkblue font-weight-normal pointer d-inline-block text-wrap text-truncate">
                {{ r.requester?.first_name }} {{ r.requester?.last_name }}
              </p>
            </div>
            <!-- <div
              class="col pl-3 pr-3 d-flex align-items-center data-item"
              style="max-width: 110px;"
            >
              <h6 class="m-0 mr-auto dkblue pointer font-weight-semibold">
                {{ r.budget?.name }}
              </h6>
            </div> -->
          </div>
          <!-- <div class="m-portlet__foot">
          <div class="row">
            <div class="col">
              <mat-paginator #paginator [length]="filesCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="getFiles($event.pageSize)">
              </mat-paginator>
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </main>
    <mat-paginator
      #paginator
      [hidden]="requestStatusesCount[selectedRequestStatus?.id] < pageSize"
      class="bg-transparent dkblue"
      color="primary"
      [length]="requestStatusesCount[selectedRequestStatus?.id]"
      [pageSize]="pageSize"
      (page)="pageChange($event)"
    >
    </mat-paginator>
    <div data-id-test="no-selected-request-type-wrapper" class="row">
      <div *ngIf="!requestStatusesCount[selectedRequestStatus?.id]" class="col text-center">
        <h5 class="gray m-0">
          <span> There are no {{ selectedRequestStatus?.name | titlecase }} Requests </span>
        </h5>
      </div>
    </div>
  </section>
</div>
