import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

import { AuthService } from 'src/app/services';
import { DashboardService } from 'src/app/workspaces/construction/services';

import { Task } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-dashboard-project',
  templateUrl: './dashboard-project.component.html',
  styleUrls: ['./dashboard-project.component.scss'],
})
export class DashboardProjectComponent implements OnInit {
  tasks: Task[];
  showDesktop;
  @Input() project: ProjectConstruction;

  // gets all tasks that are complete
  get completeTasks() {
    const ret = [];
    if (this.tasks) {
      this.tasks.forEach((task: Task) => {
        if (task.status_name === 'Complete') {
          ret.push(task);
        }
      });
    }
    return ret;
  }
  // gets all tasks
  get totalTasks() {
    if (this.tasks) {
      return this.tasks;
    } else {
      return [];
    }
  }
  // gets all overdue tasks
  get overdueTasks() {
    const ret = [];
    if (this.tasks) {
      this.tasks.forEach((task: Task) => {
        if (
          task.status_name !== 'Complete' &&
          moment(task.due_date).startOf('day').diff(moment().startOf('day'), 'days') < 0
        ) {
          ret.push(task);
        }
      });
    }
    return ret;
  }

  constructor(public dashboardService: DashboardService, public authService: AuthService) {}

  ngOnInit() {
    this.tasks = this.project.tasks;
  }

  getPercentValue(val1, val2) {
    return (val1 * 100) / val2;
  }
}
