<app-base-dialog [title]="'Authorized Requester History for ' + userFullName" (closeDialog)="cancel()">
</app-base-dialog>
<div mat-dialog-content>
  <div class="portlet-table-header">
    <div class="row">
      <div class="col-1">
        <label class="main-label"> Action </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Performed By </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Date </label>
      </div>
      <div class="col-3">
        <label class="main-label"> Note </label>
      </div>
      <div class="col-4">
        <label class="main-label"> AR Form </label>
      </div>
    </div>
  </div>

  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body request ease" *ngFor="let entry of history">
      <div class="row align-items-center">
        <div class="col-1">
          <p class="m-0">
            {{ entry.is_ar ? 'Added' : 'Removed' }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0">{{ entry.created_by.first_name }} {{ entry.created_by.last_name }}</p>
        </div>
        <div class="col-2">
          <p class="m-0">
            {{ entry.created_datetime | date: 'MMM d yyyy • h:mm aa' }}
          </p>
        </div>
        <div class="col-3">
          <p class="m-0" [innerHTML]="entry.note"></p>
        </div>
        <div class="col-4">
          <mat-chip-list class="m-2 d-inline-block align-middle" *ngIf="entry.files?.length">
            <app-file-chip
              *ngFor="let file of entry.files"
              [file]="file"
              [downloadable]="true"
              [removable]="false"
            ></app-file-chip>
          </mat-chip-list>
          <p class="m-0" *ngIf="!entry.files?.length">N/A</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="!history || history.length === 0" class="col text-center">
      <h5 class="gray m-0">
        <span> There are no history entries for this user. </span>
      </h5>
    </div>
  </div>
</div>
