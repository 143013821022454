<div class="d-flex align-items-center w-100 cost-code-selection mb-4">
  <h4 class="primary mr-auto mb-0">{{ title }}</h4>
  <button
    mat-flat-button
    color="success"
    class="px-3 lh-30 ml-3"
    *ngIf="allowAdditionalCodes"
    [disabled]="disabled || !costCodes?.length"
    (click)="addArfInvoiceAmount()"
  >
    + Line Item
  </button>
  <button
    [routerLink]="arfLink"
    mat-button
    color="accent"
    type="button"
    class="pr-2"
    (click)="closeDialog.emit()"
    *ngIf="parentId && displayArfLink"
  >
    <span class="mt-1 mr-1"> View ARF </span>
    <mat-icon>east</mat-icon>
  </button>

  <!--  <mat-form-field appearance="outline">-->
  <!--    <mat-select>-->
  <!--      <mat-option value="1"> Dollar Amount </mat-option>-->
  <!--      <mat-option value="2"> Percentage Amount </mat-option>-->
  <!--    </mat-select>-->
  <!--  </mat-form-field>-->
</div>
<app-cost-code-selection-form
  *ngFor="let s of subCostCodeBudgets; let i = index"
  [arfInvoiceAmount]="arfInvoiceAmounts[i]"
  [subCostCodeBudget]="s"
  [costCodes]="costCodes"
  [disabled]="disabled"
  [allowAdditionalCodes]="allowAdditionalCodes"
  [amount]="arfInvoiceAmounts[i]?.amount"
  (filterAllCostCodes)="filterCostCodes($event)"
>
</app-cost-code-selection-form>
