<div class="row mb-2">
  <div class="col-6 pr-0">
    <mat-form-field class="full-width" appearance="outline">
      <mat-select
        placeholder="Select Building"
        [(ngModel)]="suiteOccupancy.building_id"
        (selectionChange)="refreshFloors()"
      >
        <mat-option></mat-option>
        <mat-option *ngFor="let building of buildings" [value]="building.id">
          {{ building.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-3 pr-0">
    <mat-form-field class="full-width" appearance="outline">
      <mat-select placeholder="Floor" [(ngModel)]="suiteOccupancy.floor_id" (selectionChange)="refreshSuites()">
        <mat-option *ngFor="let floor of floors" [value]="floor.id">
          {{ floor.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-3 pr-0">
    <mat-form-field class="full-width" appearance="outline">
      <mat-select placeholder="Suite" [(ngModel)]="suiteOccupancy.suite_id">
        <mat-option *ngFor="let suite of filteredSuites" [value]="suite.id">
          {{ suite.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
