import { Pipe, PipeTransform } from '@angular/core';
import { floor, round } from 'lodash';

@Pipe({
  name: 'USDollar',
})
export class USDollarPipe implements PipeTransform {
  transform(
    value: any,
    decimalCount = 2,
    includeDollarSign = true,
    includeCommas = true,
    negativeParentheses = true,
    roundDown = false
  ): any {
    if (!value || Math.abs(value) < 1 / 10 ** decimalCount) {
      value = 0;
    }
    let counter = 0;
    const isNegative = value && value.toString().substring(0, 1) === '-';
    let strippedValue = value.toString().replace(/[^0-9.]|\./g, (x) => {
      if (x === '.' && counter === 0) {
        counter++;
        return '.';
      } else {
        return '';
      }
    });

    const reg = new RegExp('^-?\\d+(?:.\\d{0,' + decimalCount + '})?');
    strippedValue = Number(
      (roundDown ? floor(+strippedValue, decimalCount) : round(+strippedValue, decimalCount)).toString().match(reg)[0]
    ).toFixed(decimalCount < 2 ? 2 : decimalCount);

    let result;
    if (!includeCommas) {
      result = strippedValue;
    } else {
      let decimalPlace = strippedValue.indexOf('.');
      decimalPlace = decimalPlace < 0 ? strippedValue.length : decimalPlace;
      const start = ((decimalPlace - 1) % 3) + 1;
      result = strippedValue.slice(0, start);
      for (let index = start; index < decimalPlace; index += 3) {
        result += `,${strippedValue.slice(index, index + 3)}`;
      }
      result += strippedValue.slice(decimalPlace);
    }
    result = `${isNegative && !negativeParentheses ? '-' : ''}${includeDollarSign ? '$' : ''}${
      isNegative && negativeParentheses ? '(' : ''
    }${result}${isNegative && negativeParentheses ? ')' : ''}`;
    return result;
  }
}
