import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CostCodeFormValue } from '../types';

@Pipe({
  name: 'filterCostCodeFormArrayByModuleId',
})
export class FilterCostCodeFormArrayByModuleIdPipe implements PipeTransform {
  transform(controls: AbstractControl[], selectedModuleIds: number[]): AbstractControl[] {
    return controls.filter((control) => {
      const costCodeBudgetValue = control.value as CostCodeFormValue;

      return costCodeBudgetValue.subCostCodes.some(
        (subCostCodeBudgetValue) =>
          subCostCodeBudgetValue.isNewSubCostCode ||
          (selectedModuleIds.length
            ? subCostCodeBudgetValue.moduleIds.some((moduleId) => selectedModuleIds.includes(moduleId))
            : true)
      );
    });
  }
}
