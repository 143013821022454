import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { catchError, map } from 'rxjs/operators';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { ApplicationRole, ResourceType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
} from 'src/app/services';
import { ProjectDrawingsService } from 'src/app/workspaces/construction/services';
import { ProjectDrawing, ProjectDrawingVersion } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-drawings',
  templateUrl: './project-drawings.component.html',
  styleUrls: ['./project-drawings.component.scss'],
})
export class ProjectDrawingsComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private handleErrorService: HandleErrorService,
    public authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService,
    private projectDrawingService: ProjectDrawingsService,
    private fileService: FileService,
    private dialog: MatDialog
  ) {}

  searchString;
  pageLoad = true;
  loading = {};
  isComplete = {};

  projectDrawings: ProjectDrawing[] = [];
  projectDrawingFields: string[] = ['project_id', 'name', 'created_datetime'];
  projectDrawingVersionFields: string[] = [
    'project_id',
    'project_drawing_id',
    'version',
    'file_id',
    'file_name',
    'created_datetime',
  ];

  allowEdit = false;

  ngOnInit() {
    setTimeout(() => {
      this.refresh();
    });
  }

  async refresh() {
    this.pageLoad = true;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Drawings..');
    this.allowEdit =
      this.authService.isAppAdmin ||
      this.authService.isUserWorkspaceAdmin(this.projectService.currentSelectedProject?.module_id) ||
      this.authService.currentUserIsOfProjectRole(
        ApplicationRole.ProjectArchitect,
        this.projectService.currentSelectedProjectId
      );
    const projectDrawings = await this.projectDrawingService
      .getProjectDrawings(this.projectDrawingFields, [
        {
          type: 'field',
          field: 'project_id',
          value: this.projectService.currentSelectedProjectId.toString(),
        },
      ])
      .toPromise();
    const projectDrawingVersions = await this.projectDrawingService
      .getProjectDrawingVersions(this.projectDrawingVersionFields, [
        {
          type: 'field',
          field: 'project_id',
          value: this.projectService.currentSelectedProjectId.toString(),
        },
      ])
      .toPromise();
    projectDrawings.forEach((d) => {
      d.extension = d.file_name ? d.file_name.split('.')[1] : null;
      d.versions = projectDrawingVersions.filter((v) => v.project_drawing_id === d.id).sort((v) => v.version);
      d.versions.reverse();
      d.latestVersion = d.versions[0];
      const currentDrawing = this.projectDrawings.find((pd) => pd.id === d.id);
      d.showVersions = currentDrawing ? !!currentDrawing.showVersions : false;
      this.isComplete[d.latestVersion.id] = true;
      this.loading[d.latestVersion.id] = false;
    });
    this.projectDrawings = projectDrawings;
    this.progressIndicatorService.close();
    this.pageLoad = false;
  }

  createProjectDrawing() {
    this.modalService.openNewProjectDrawingModal().subscribe(() => {
      this.refresh();
    });
  }

  public async downloadVersion(version: ProjectDrawingVersion) {
    this.loading[version.id] = true;
    await this.fileService
      .downloadFile({ id: version.file_id })
      .pipe(
        map((result) => result),
        catchError((e) => {
          this.handleErrorService.handleError(e);
          return e;
        })
      )
      .toPromise()
      .then((downloadedFileResult) => {
        saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      });
    this.loading[version.id] = false;
  }

  async previewVersion(version) {
    this.isComplete[version.id] = false;
    await this.fileService.previewFile(version);
    this.isComplete[version.id] = true;
  }

  async updateDrawingName(drawing: ProjectDrawing) {
    await this.projectDrawingService.updateProjectDrawing(drawing.id, { name: drawing.name }, []).toPromise();
    this.snackbar.open('Project drawing updated!');
    this.refresh();
  }

  async uploadNewVersion(drawing: ProjectDrawing) {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          maxFiles: 1,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData && resultData[0]) {
          await this.projectDrawingService
            .createProjectDrawingVersion({ project_drawing_id: drawing.id, file_id: resultData[0].file_id }, [])
            .toPromise();
          this.snackbar.open('Version uploaded!');
          this.refresh();
        }
      });
  }

  async deleteDrawing(drawing: ProjectDrawing) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Project Drawing',
        descriptionText: `Are you sure you want to remove this drawing?

Removing it will only remove it from Project Drawings, it will still be available in Project Files.`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.projectDrawingService.deleteProjectDrawing(drawing.id).toPromise();
          this.snackbar.open('Project drawing deleted!');
          this.refresh();
        }
      });
  }
}
