import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Address,
  APIFilter,
  Building,
  Department,
  Floor,
  RoomType,
  ServiceResponse,
  Suite,
  SuiteOccupancy,
} from '../types';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  host: string = environment.serviceHost;
  buildingsUrl = `${this.host}/api/v1/buildings`;
  floorsUrl = `${this.host}/api/v1/floors`;
  suitesUrl = `${this.host}/api/v1/suites`;
  departmentsUrl = `${this.host}/api/v1/departments`;
  roomTypeUrl = `${this.host}/api/v1/room-types`;
  suitesOccupancyUrl = `${this.host}/api/v1/suite-occupancies`;
  addressesUrl = `${this.host}/api/v1/addresses`;

  public buildings: Building[] = [];
  public departments: Department[] = [];
  public floors: Floor[] = [];
  public suites: Suite[] = [];

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getBuildings(fields?: string[], apiFilters?: APIFilter[]): Observable<Building[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.buildingsUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const buildings: Building[] = result.data.buildings;
        return buildings;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getFloors(fields?: string[], apiFilters?: APIFilter[]): Observable<Floor[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.floorsUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const floors: Floor[] = result.data.floors;
        return floors;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getFloorsByBuilding(buildingId: number): Observable<Floor[]> {
    return this.http.get(`${this.floorsUrl}?filter=building_id=${buildingId}`).pipe(
      map((result: ServiceResponse) => {
        const floors: Floor[] = result.data.floors;
        return floors;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSuites(fields?: string[], apiFilters?: APIFilter[]): Observable<Suite[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.suitesUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const suites: Suite[] = result.data.suites;
        return suites;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSuitesByFloor(floorId: number): Observable<Suite[]> {
    return this.http.get(`${this.suitesUrl}?filter=floor_id=${floorId}`).pipe(
      map((result: ServiceResponse) => {
        const suites: Suite[] = result.data.suites;
        return suites;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getDepartmentsBySuite(suiteId: number): Observable<Department[]> {
    return this.http.get(`${this.departmentsUrl}?filter=suite_id=${suiteId}`).pipe(
      map((result: ServiceResponse) => {
        const departments: Department[] = result.data.departments;
        return departments;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getDepartments(fields?: string[], apiFilters?: APIFilter[]): Observable<Department[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.departmentsUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const departments: Department[] = result.data.departments;
        return departments;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getDepartmentByID(id: number, fields?: string[]): Observable<Department> {
    return this.http.get(`${this.departmentsUrl}/${id}?${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const department: Department = result.data.department[0];
        return department;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRoomTypes(): Observable<RoomType[]> {
    return this.http.get(`${this.roomTypeUrl}`).pipe(
      map((result: ServiceResponse) => {
        const roomTypes: RoomType[] = result.data.room_types;
        return roomTypes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getFloorsBySuite(floorId: number): Observable<Suite[]> {
    return this.http.get(`${this.suitesUrl}?filter=floor_id=${floorId}`).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getSuitesByDepartment(departmentId: number, fields?: string[]): Observable<Suite[]> {
    return this.http
      .get(`${this.suitesUrl}?filter=department_id=${departmentId}&${fields ? 'fields=' + fields.join(',') : ''}`)
      .pipe(
        map((result: ServiceResponse) => {
          const suites: Department[] = result.data.suites;
          return suites;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  addBuilding(buildingToAdd: Building): Observable<Building> {
    return this.http.post(`${this.buildingsUrl}`, buildingToAdd).pipe(
      map((result: ServiceResponse) => {
        const buildingToReturn: Building = result.data.building;
        return buildingToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateBuilding(buildingId: number, buildingToUpdate: Building): Observable<Building> {
    return this.http.put(`${this.buildingsUrl}/${buildingId}`, buildingToUpdate).pipe(
      map((result: ServiceResponse) => {
        const companyToReturn: Building = result.data.building;
        return companyToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteBuilding(buildingId: number): Observable<void> {
    return this.http.delete(`${this.buildingsUrl}/${buildingId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  addFloor(floorToAdd: Floor): Observable<Floor> {
    return this.http.post(`${this.floorsUrl}`, floorToAdd).pipe(
      map((result: ServiceResponse) => {
        const floorToReturn: Floor = result.data.floor;
        return floorToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateFloor(floorId: number, floorToUpdate: Floor): Observable<Floor> {
    return this.http.put(`${this.floorsUrl}/${floorId}`, floorToUpdate).pipe(
      map((result: ServiceResponse) => {
        const floorToReturn: Floor = result.data.floor;
        return floorToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteFloor(floorId: number): Observable<void> {
    return this.http.delete(`${this.floorsUrl}/${floorId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  addDepartment(departmentToAdd: Department): Observable<Department> {
    return this.http.post(`${this.departmentsUrl}`, departmentToAdd).pipe(
      map((result: ServiceResponse) => {
        const departmentToReturn: Department = result.data.department;
        return departmentToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateDepartment(departmentId: number, departmentToUpdate: Department, fields?: string[]): Observable<Department> {
    return this.http
      .put(`${this.departmentsUrl}/${departmentId}${fields ? '?fields=' + fields.join(',') : ''}`, departmentToUpdate)
      .pipe(
        map((result: ServiceResponse) => {
          const departmentToReturn: Department = result.data.department;
          return departmentToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteDepartment(departmentId: number): Observable<void> {
    return this.http.delete(`${this.departmentsUrl}/${departmentId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  addSuite(suiteToAdd: Suite): Observable<Suite> {
    return this.http.post(`${this.suitesUrl}`, suiteToAdd).pipe(
      map((result: ServiceResponse) => {
        const suiteToReturn: Suite = result.data.suite;
        return suiteToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateSuite(suiteId: number, suiteToUpdate: Suite): Observable<Suite> {
    return this.http.put(`${this.suitesUrl}/${suiteId}`, suiteToUpdate).pipe(
      map((result: ServiceResponse) => {
        const suiteToReturn: Suite = result.data.suite;
        return suiteToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteSuite(suiteId: number): Observable<void> {
    return this.http.delete(`${this.suitesUrl}/${suiteId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSuiteOccupancies(fields?: string[], apiFilters?: APIFilter[]): Observable<SuiteOccupancy[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(`${this.suitesOccupancyUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`)
      .pipe(
        map((result: ServiceResponse) => {
          const suiteOccupancies: SuiteOccupancy[] = result.data.suite_occupancy;
          return suiteOccupancies;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  addSuiteOccupancy(suiteOccupancyToAdd: SuiteOccupancy): Observable<SuiteOccupancy> {
    return this.http.post(`${this.suitesOccupancyUrl}`, suiteOccupancyToAdd).pipe(
      map((result: ServiceResponse) => {
        const suiteOccupancyToReturn: SuiteOccupancy = result.data.suite;
        return suiteOccupancyToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateSuiteOccupancy(suiteOccupancyId: number, suiteOccupancyToUpdate: SuiteOccupancy): Observable<SuiteOccupancy> {
    return this.http.put(`${this.suitesOccupancyUrl}/${suiteOccupancyId}`, suiteOccupancyToUpdate).pipe(
      map((result: ServiceResponse) => {
        const suiteOccupancyToReturn: SuiteOccupancy = result.data.suite_occupancy;
        return suiteOccupancyToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteSuiteOccupancy(suiteOccupancyId: number): Observable<void> {
    return this.http.delete(`${this.suitesOccupancyUrl}/${suiteOccupancyId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getAddresses(fields?: string[], apiFilters?: APIFilter[]): Observable<Address[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.addressesUrl}?${filterString}&${fields ? 'fields=' + fields.join(',') : ''}`).pipe(
      map((result: ServiceResponse) => {
        const addresses: Address[] = result.data.addresses;
        return addresses;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  async addAddress(addressToAdd: Address): Promise<Address> {
    const addressFields = ['address', 'city', 'state', 'zipcode'];
    const addressFilter: APIFilter[] = [];
    addressFields.forEach((af, index) => {
      addressFilter.push({ type: 'field', field: af, value: encodeURIComponent(addressToAdd[af]) });
      if (index !== addressFields.length - 1) {
        addressFilter.push({ type: 'operator', value: 'AND' });
      }
    });
    const existingAddress = await this.getAddresses(null, addressFilter).toPromise();
    if (existingAddress.length > 0) {
      return of(existingAddress[0]).toPromise();
    } else {
      return this.http
        .post(`${this.addressesUrl}`, addressToAdd)
        .pipe(
          map((result: ServiceResponse) => {
            const addressToReturn: Address = result.data.address;
            return addressToReturn;
          }),
          catchError((e) => this.handleErrorService.handleError(e))
        )
        .toPromise();
    }
  }
}
