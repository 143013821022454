<div class="row align-items-center m-0 item task ease pointer" (click)="openTaskDialog(task.id)">
  <div class="col-auto pl-0" style="width: 45px">
    <button
      [disabled]="task.status_id === 2"
      mat-icon-button
      *ngIf="task.status_id === 1 && !isApproval()"
      (click)="changeTaskStatus(3); stopPropagation($event)"
    >
      <mat-icon
        class="ltblue opacity4"
        matTooltip="Mark Complete"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        >radio_button_unchecked</mat-icon
      >
    </button>

    <button [disabled]="task.status_id === 2" *ngIf="task.status_id === 2 && !isApproval()" mat-icon-button>
      <mat-icon class="red">flag</mat-icon>
    </button>
    <button [disabled]="task.status_id === 2" *ngIf="isApproval() && task.status_id !== 3" mat-icon-button>
      <mat-icon class="ltgray">library_add_check</mat-icon>
    </button>

    <button
      [disabled]="task.status_id === 2"
      mat-icon-button
      *ngIf="task.status_id === 3"
      (click)="changeTaskStatus(1); stopPropagation($event)"
    >
      <mat-icon class="green" matTooltip="Mark Todo" matTooltipPosition="above" [matTooltipShowDelay]="300"
        >check_circle</mat-icon
      >
    </button>
  </div>
  <div class="col-auto task-info pl-0 pr-1">
    <div *ngIf="!task.assigned_user_id" class="addUser">
      <button
        (click)="openAssignUserDialog(); stopPropagation($event)"
        mat-icon-button
        class="ltgray"
        matTooltip="Assign Task"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        [disabled]="!isWorkspaceStaff"
      >
        <mat-icon>person_add</mat-icon>
      </button>
    </div>

    <app-profile-thumbnail
      (click)="openAssignUserDialog(); stopPropagation($event)"
      *ngIf="task.assigned_user_id"
      class="d-inline-block align-middle"
      [width]="32"
      [height]="32"
      userId="{{ task.assigned_user_id }}"
      [ngClass]="{ '': task.status_id === taskStatus.Complete }"
      matTooltip="{{ task.assigned_user_first_name }} {{ task.assigned_user_last_name }}"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
      [overlayIconTooltip]="'Account Pending'"
      [overlayIconOpacity]="1"
      [overlayIcon]="task.assigned_user_login_enabled === 0 ? 'priority_high' : ''"
      [overlayIconColor]="'white'"
      [overlayIconBackground]="'bg-red'"
    ></app-profile-thumbnail>
  </div>
  <div class="col">
    <div class="ml-1 d-inline-block align-middle">
      <h6 class="dkblue font-weight-semibold m-0">
        <span class="mr-2">
          {{ task.title }}
        </span>
        <!-- <small class="gray">
                {{task.phase_name}} Phase
              </small> -->
      </h6>
    </div>
  </div>
  <div class="col text-right" *ngIf="showProject">
    <p class="m-0 gray">
      <span class="gray mr-1">
        {{ task.project_code }}
      </span>
      {{ task.project_title }}
    </p>
  </div>
  <div class="col pr-0 text-right" style="max-width: 200px">
    <input
      matInput
      [matDatepicker]="dueDatePicker"
      [(ngModel)]="task.due_date"
      autocomplete="off"
      style="visibility: hidden; width: 0"
      (ngModelChange)="changeTaskDueDate(task.due_date)"
      [disabled]="!isWorkspaceStaff"
    />
    <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>

    <button
      (click)="dueDatePicker.open(); stopPropagation($event)"
      mat-button
      color="accent"
      [ngClass]="[task.status_id === taskStatus.Complete ? 'opacity4' : '']"
      class="font-weight-semibold task-due-date"
      matTooltip="{{ task.due_date | date: 'MM/dd/yyyy' }}"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      <div
        [ngClass]="{
          red: (isOverdue || getDaysUntilDue === 0) && !complete,
          dkblue: !isOverdue && !complete,
          green: complete
        }"
      >
        {{ timePhrase }}
      </div>
    </button>
  </div>
</div>
