import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { BuildingManagerDialogComponent } from 'src/app/components/building-manager-dialog/building-manager-dialog.component';
import { AuthService, ExportService, LocationService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { Building, Floor } from 'src/app/types';

@Component({
  selector: 'app-building-manager',
  templateUrl: './building-manager.component.html',
  styleUrls: ['./building-manager.component.scss'],
})
export class BuildingManagerComponent {
  @ViewChild('paginator', { static: true }) paginator: unknown;

  public buildingColumns = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Name' },
    { field: 'code', header: 'Code' },
    { field: 'physical_address_id', header: 'Physical Address ID' },
    { field: 'physical_address', header: 'Physical Address' }, // address(Object)
    { field: 'mailing_address_id', header: 'Mailing Address ID' },
    { field: 'mailing_address', header: 'Mailing Adress' }, // address(Object)
    { field: 'is_enabled', header: 'Active' },
    { field: 'floors', header: 'Floors' }, // floors[]
  ];
  public buildings = this.locationService.buildings;
  public filteredCount = { count: 0 };
  public pageSize: number;
  public pageEvent: PageEvent;
  public search: string;
  public showInactive = false;
  public sortProperty = 'name';
  public sortDirection: 'asc' | 'desc' = 'asc';

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private exportService: ExportService,
    private locationService: LocationService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar
  ) {}

  public get isBuildingsManager(): boolean {
    return this.authService.isBuildingsDepartmentsSuitesManager;
  }

  public exportBuildingData(): void {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Downloading Buildings...');

    const buildingRows = this.buildings
      ?.filter((building: Building) => {
        const searchTerm = this.search?.toLowerCase();
        if (searchTerm) {
          return (
            building.name?.toLowerCase()?.includes(searchTerm) || building.code?.toLowerCase()?.includes(searchTerm)
          );
        } else {
          return building;
        }
      })
      ?.map((building: Building) => {
        let buildingDataRows = '';

        for (const [index, buildingColumn] of this.buildingColumns.entries()) {
          let buildingField = building[buildingColumn.field];

          // make sure it exist
          if (buildingField) {
            if (buildingField instanceof Object && Array.isArray(buildingField)) {
              buildingField = buildingField?.map((floor: Floor) => `${floor.name || ''}`?.trim())?.join(' ');
            } else if (buildingField instanceof Object) {
              // this can be the physical or mailing address
              if (buildingColumn.field === 'physical_address' || buildingColumn.field === 'mailing_address') {
                // replace buildingField with the full address
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                buildingField = `${buildingField.address?.trim()?.replace(',', '') || ''} ${
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  buildingField.city?.trim()?.replace(',', '') || ''
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                } ${buildingField.state?.trim()?.replace(',', '') || ''} ${
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  buildingField.zipcode?.trim()?.replace(',', '') || ''
                }`;
              }
            } else {
              if (buildingColumn?.field?.toLowerCase() === 'is_enabled') {
                buildingField = buildingField ? 'Yes' : 'No';
              } else {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                buildingField = `${buildingField || ''}`?.trim()?.replace(',', '');
              }
            }
          } else {
            buildingField = 'N/A';
          }

          buildingDataRows += buildingField;
          if (index < this.buildingColumns.length - 1) {
            buildingDataRows += ',';
          }
        }
        return buildingDataRows;
      });

    const exportBuildingData = [
      `${this.buildingColumns
        ?.map((buildingColumn: { field: string; header: string }) => buildingColumn.header)
        .join(',')}`,
      ...buildingRows,
    ];

    const buildingFilename = `buildings_${moment().format('YYYY-MM-DD')}`;

    this.exportService.exportDataWithConfirmation(
      exportBuildingData,
      buildingFilename,
      'Confirm Data Export',
      `Building data export will use any provided search term. Are you sure you wish to continue?`
    );

    this.progressIndicatorService.close();
  }

  public async getBuildings(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading buildings...');
    await this.locationService
      .getBuildings([
        'id',
        'name',
        'code',
        'floors',
        'is_enabled',
        'mailing_address',
        'physical_address',
        'circulation_factor',
      ])
      .toPromise()
      .then((response) => {
        this.buildings = response;
      });
    this.filteredCount.count = this.buildings.length;
    this.progressIndicatorService.close();
  }

  public openBuildingDialog(action: 'Add' | 'Edit', building?: Building): void {
    if (!building) {
      building = {
        floors: [],
        mailing_address: {},
        physical_address: {},
      };
    } else {
      building = JSON.parse(JSON.stringify(building));
    }
    building.mailing_address = building.mailing_address || {};
    building.physical_address = building.physical_address || {};
    const dialogRef = this.dialog.open(BuildingManagerDialogComponent, {
      width: '580px',
      data: {
        action,
        building,
      },
    });

    dialogRef.afterClosed().subscribe((reload) => {
      if (reload === true) {
        void this.getBuildings();
      }
    });
  }

  public sort(property: string): void {
    if (this.sortProperty === property) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortProperty = property;
      this.sortDirection = 'asc';
    }
  }
}
