<div
  class="time-log-panel ease"
  [ngClass]="{
    'panel-closed': !isPanelOpen(),
    'panel-open': isPanelOpen(),
    'sidenav-closed': !isSidenavOpen()
  }"
>
  <div class="time-log-panel-container">
    <header class="border-bottom border-dkgray">
      <div class="d-flex time-log-panel_header align-items-center pl-4 pr-4">
        <h4 class="m-0 white font-weight-semibold mr-auto">Time Log</h4>
        <!-- <mat-button-toggle-group
          class="bg-dkblue"
          id="teamToggle"
          name="teamToggle"
          aria-label="Team Toggle"
        >
          <mat-button-toggle checked value="!teamToggle">ME</mat-button-toggle>
          <mat-button-toggle value="teamToggle">TEAM</mat-button-toggle>
        </mat-button-toggle-group> -->
        <a
          target="_self"
          href="assets/documents/UHT_Form_RequestForLeave_05.08.23-1.pdf"
          download="UHT_Form_RequestForLeave_05.08.23-1.pdf"
        >
          <button mat-button type="button" class="ltltblue">
            <mat-icon>sim_card_download</mat-icon>
            <span class="ml-2">Time Off Request</span>
          </button>
        </a>
        <button mat-flat-button color="success" class="ml-3" (click)="createTimeLogEntry()">+ Time Entry</button>
        <!-- <button mat-mini-fab color="success">
              <mat-icon>add</mat-icon>
            </button> -->
      </div>
      <section class="pt-4 pr-4 pl-4 pb-0 d-flex align-items-center">
        <div class="d-flex align-items-center">
          <h1
            class="dkblue font-weight-semibold mb-0 mt-1 mr-3"
            style="min-width: 64px; min-height: 39px; max-width: 100px; overflow: hidden; overflow-wrap: normal"
            [title]="timeForDateRange"
            [class.animated-background]="loading === true"
          >
            {{ loading === true ? '' : timeForDateRange }}
          </h1>
          <h5 class="m-0 dkblue font-weight-semibold align-top" style="overflow: hidden" [title]="dateRangeString">
            <small>Hours for</small><br />
            <!-- CURRENT TIME SPAN HERE -->
            <!-- WEEKS ARE MONDAY - SUNDAY -->
            {{ dateRangeString }}
          </h5>
        </div>
        <div class="ml-auto">
          <button mat-button color="accent" class="px-3 lh-34" (click)="goToToday()">
            {{ timeFrame === DateRange.Daily ? 'Today' : timeFrame === DateRange.Monthly ? 'This Month' : 'This Week' }}
          </button>
          <button
            mat-button
            color="accent"
            class="ml-2 lh-34"
            (click)="exportData()"
            *ngIf="authService.isStaffOnAnyModule"
          >
            <mat-icon> get_app </mat-icon>
            <span> Export </span>
          </button>
          <app-split-button class="ml-2" lineHeight="24" [matMenu]="menu">
            {{ timeFrame }}
          </app-split-button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button (click)="renderPage(DateRange.Daily)" mat-menu-item>
              <mat-icon>today</mat-icon>
              <span> {{ DateRange.Daily }} </span>
            </button>
            <button (click)="renderPage(DateRange.Weekly)" mat-menu-item>
              <mat-icon>date_range</mat-icon>
              <span> {{ DateRange.Weekly }} </span>
            </button>
            <button (click)="renderPage(DateRange.Monthly)" mat-menu-item>
              <mat-icon>event_note</mat-icon>
              <span> {{ DateRange.Monthly }} </span>
            </button>
          </mat-menu>
        </div>
      </section>
      <!-- FILTERS SECTION -->
      <section class="row d-flex align-items-center px-4 pt-3">
        <div class="col pr-2">
          <mat-form-field appearance="outline" class="m-0 dkblue full-width">
            <mat-select #workspaceSelect multiple [(ngModel)]="selectedWorkspaces" placeholder="0 Workspaces">
              <mat-select-trigger>
                <span *ngIf="selectedWorkspaces.length !== 1">
                  {{ allWorkspacesSelected ? 'All' : +selectedWorkspaces.length }} Workspaces
                </span>
                <span *ngIf="selectedWorkspaces.length === 1">
                  {{ selectedWorkspaces[0].name }}
                </span>
              </mat-select-trigger>
              <div>
                <mat-checkbox
                  class="mat-option mt-2"
                  color="primary"
                  [(ngModel)]="allWorkspacesSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllWorkspaces()"
                  >Toggle All Workspaces</mat-checkbox
                >
              </div>
              <mat-divider class="my-2"></mat-divider>
              <mat-option
                class="workspace-option d-flex align-items-center"
                *ngFor="let workspace of visibleWorkspaces"
                (click)="toggleWorkspace()"
                [value]="workspace"
              >
                <mat-icon class="mr-2 ml-3 dkblue">{{ workspace.icon }}</mat-icon>
                {{ workspace.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field appearance="outline" class="m-0 dkblue full-width">
            <mat-select #userSelect multiple [(ngModel)]="selectedUsers" placeholder="0 Staff">
              <mat-select-trigger>
                <span *ngIf="selectedUsers.length !== 1">
                  {{ allUsersSelected ? 'All' : selectedUsersLength }} Staff
                </span>
                <span *ngIf="selectedUsers.length === 1">
                  {{ selectedUsers[0].first_name }} {{ selectedUsers[0].last_name }}
                </span>
              </mat-select-trigger>
              <div>
                <mat-checkbox
                  class="mat-option mt-2"
                  color="primary"
                  [(ngModel)]="allUsersSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllUsers()"
                  >Toggle All Staff</mat-checkbox
                >
              </div>
              <div>
                <mat-checkbox
                  class="mat-option mt-2"
                  color="primary"
                  [(ngModel)]="myTimeSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleMyTime()"
                  >My Time Only</mat-checkbox
                >
              </div>
              <mat-divider class="my-2"></mat-divider>
              <mat-option *ngFor="let user of visibleUsers" (click)="toggleUser()" [value]="user">
                {{ user.first_name }} {{ user.last_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field appearance="outline" class="m-0 dkblue full-width">
            <mat-select #activitySelect multiple [(ngModel)]="selectedActivities" placeholder="0 Selected">
              <mat-select-trigger>
                <span *ngIf="selectedActivities.length !== 1">
                  {{ allActivitiesSelected ? 'All' : +selectedActivities.length }} Activities
                </span>
                <span *ngIf="selectedActivities.length === 1">
                  {{ selectedActivities[0].name }}
                </span>
              </mat-select-trigger>
              <div>
                <mat-checkbox
                  class="mat-option mt-2"
                  color="primary"
                  [(ngModel)]="allActivitiesSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllActivities()"
                  >Toggle All Activities</mat-checkbox
                >
              </div>
              <mat-divider class="my-2"></mat-divider>
              <mat-option *ngFor="let activity of visibleActivities" (click)="toggleActivity()" [value]="activity">
                {{ activity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col pl-2">
          <mat-form-field appearance="outline" class="m-0 dkblue full-width">
            <mat-select #companySelect multiple [(ngModel)]="selectedCompanies" placeholder="0 Selected">
              <mat-select-trigger>
                <span *ngIf="selectedCompanies.length !== 1">
                  {{ allCompaniesSelected ? 'All' : +selectedCompanies.length }} Companies
                </span>
                <span *ngIf="selectedCompanies.length === 1">
                  {{ selectedCompanies[0].name }}
                </span>
              </mat-select-trigger>
              <div>
                <mat-checkbox
                  class="mat-option mt-2"
                  color="primary"
                  [(ngModel)]="allCompaniesSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllCompanies()"
                  >Toggle All Companies</mat-checkbox
                >
              </div>
              <mat-divider class="my-2"></mat-divider>
              <mat-option *ngFor="let company of visibleCompanies" (click)="toggleCompany()" [value]="company">
                {{ company.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
      <section class="p-3">
        <div class="row m-0 d-flex align-items-center">
          <div matRipple class="col-auto item item-last p-2 br-5 pointer text-center" (click)="offsetDateBackwards()">
            <i class="ltblue material-icons mt-2">chevron_left</i>
          </div>
          <div
            matRipple
            class="day-selector col item item-last p-2 br-5 pointer text-center"
            *ngFor="let dateItem of dateRange"
            (click)="selectDate(dateItem)"
            [ngClass]="{
              'border border-ltblue': dateItem.date === currentSelectedDate.date,
              opacity5: dateItem.hours === '0:00'
            }"
          >
            <h6 class="m-0 dkblue font-weight-semibold">
              {{ dateItem.date }}
            </h6>
            <h4 class="dkblue font-weight-semibold" [class.animated-background]="loading === true">
              {{ dateItem.hours }}
            </h4>
            <i *ngIf="dateItem.isToday" class="fa fa-circle ltblue"></i>
          </div>
          <div matRipple class="col-auto item item-last p-2 br-5 pointer text-center" (click)="offsetDateForwards()">
            <i class="ltblue material-icons mt-2">chevron_right</i>
          </div>
        </div>
      </section>
    </header>
    <main *ngIf="!dataLoaded">
      <div class="d-flex flex-column align-items-center border-bottom border-gray bg-shade-ltblue">
        <h2 class="d-flex m-0 pt-2 pb-2 text-center">Please Wait - Data Loading...</h2>
      </div>
    </main>
    <main>
      <!--      Unfortunately I had to split daily and monthly displays, because of a scope change-->
      <!--      MONTHLY-->
      <!--      DAILY-->
      <cdk-virtual-scroll-viewport itemSize="auto" class="scroll-container">
        <div *ngIf="loading" class="d-flex flex-column py-4" style="border-bottom: 1px solid #d3d7e9">
          <div class="row d-flex m-0 align-items-center">
            <div class="col-auto animated-background" style="margin: auto 20px; height: 38px; width: 60px"></div>
            <div class="col pl-4 pr-4">
              <div style="height: 22px; width: 150px" class="mb-2 animated-background"></div>
              <div style="height: 22px; width: 250px" class="mb-2 animated-background"></div>
              <div style="height: 22px; width: 350px" class="mb-2 animated-background"></div>
            </div>
            <div class="col-auto">
              <div
                class="animated-background"
                style="
                  border-radius: 50%;
                  overflow: visible;
                  position: relative;
                  border: 2px solid #fff;
                  width: 40px;
                  height: 40px;
                "
              ></div>
            </div>
            <div class="d-flex align-items-center">
              <button mat-icon-button disabled>
                <mat-icon> more_vert </mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="timeFrame === DateRange.Daily && !loading"
          class="row m-0 border-bottom border-gray pt-2 bg-shade-ltblue pb-2"
        >
          <div class="col d-flex pl-4 pr-4">
            <h6 class="m-0 dkblue font-weight-semibold">
              {{ displayedCurrentDate }}
            </h6>
            <h6 class="m-0 dkblue font-weight-semibold ml-auto">
              {{ currentDate?.hours }}
            </h6>
          </div>
        </div>
        <!-- TIME ENTRY LINE ITEM -->
        <span *ngIf="timeFrame === DateRange.Daily">
          <div
            class="d-flex flex-column item pt-4 pb-4"
            *ngFor="let entry of displayedEntries | slice: 0:maxDisplayedEntries"
          >
            <div class="row d-flex m-0 align-items-center">
              <div class="col-auto pl-4 pr-0" style="width: 70px">
                <h4
                  class="m-0 dkblue font-weight-semibold"
                  style="line-height: 38px"
                  [ngClass]="getCSSClass(entry.activity_id)"
                >
                  {{ entry.hours_worked.toString().padStart(2, '0') }}:{{
                    entry.minutes_worked.toString().padStart(2, '0')
                  }}
                </h4>
              </div>
              <div class="col pl-4 pr-4">
                <h5 class="m-0 dkblue font-weight-semibold mb-1" [ngClass]="getCSSClass(entry.activity_id)">
                  {{ entry.activity.name }}
                  <small
                    class="gray ml-1 font-weight-semibold"
                    *ngIf="!(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)"
                  >
                    {{ entry.company.name }}
                  </small>
                </h5>
                <a
                  (click)="closePanel()"
                  class="mb-0 ltblue font-weight-normal hover-u d-inline-block"
                  *ngIf="
                    entry.parent_type_id === 3 &&
                    !(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)
                  "
                  routerLink="/projects/{{ entry.parent_id }}"
                >
                  PRJ {{ entry.parent_code }} - {{ entry.parent_title }}
                </a>
                <a
                  (click)="closePanel()"
                  class="mb-0 ltblue font-weight-normal hover-u d-inline-block"
                  *ngIf="
                    entry.parent_type_id === 34 &&
                    !(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)
                  "
                  routerLink="/work-orders/{{ entry.parent_id }}"
                >
                  {{ entry.parent_code }} - {{ entry.parent_title }}
                </a>
                <!--              Show General for PTO/Vacation, Sick TIme, Holiday-->
                <h6
                  class="mb-0 gray font-weight-normal"
                  *ngIf="!entry.parent_id && !ptoActivityIds.includes(+entry.activity.id)"
                >
                  General (No Project or Work Order)
                </h6>
              </div>
              <div class="col-auto pl-4 pr-0">
                <app-profile-thumbnail
                  [noBorder]="true"
                  [height]="30"
                  [width]="30"
                  [userId]="entry.worker_id"
                ></app-profile-thumbnail>
              </div>
              <div class="col-auto pl-4 pr-4 d-flex flex-column align-self-start" style="width: 70px">
                <div class="d-flex align-items-center">
                  <button
                    mat-icon-button
                    color="accent"
                    class="ml-auto"
                    [matMenuTriggerFor]="entry_menu"
                    *ngIf="isAppAdmin || entry.created_by_id === authService.currentUser.id"
                  >
                    <mat-icon> more_vert </mat-icon>
                  </button>
                  <mat-menu #entry_menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="editTimeLogEntry(entry)">
                      <mat-icon>edit</mat-icon>
                      <span> Edit Time Entry </span>
                    </button>
                    <button mat-menu-item (click)="deleteTimeLogEntry(entry.id)">
                      <mat-icon>delete</mat-icon>
                      <span> Delete Time Entry </span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <!-- IF NOTE EXISTS -->
            <div *ngIf="entry.notes" class="row m-0 pt-2">
              <div class="col-auto pl-4 pr-0" style="width: 70px"></div>
              <div class="col pl-4 pr-4">
                <p class="m-0 dkgray" [innerHTML]="entry.notes"></p>
              </div>
            </div>
          </div>
        </span>
        <span *ngIf="[DateRange.Monthly, DateRange.Weekly].includes(timeFrame) && !loading">
          <div *ngFor="let day of rangeData">
            <div class="row m-0 border-bottom border-gray pt-2 bg-shade-ltblue pb-2">
              <div class="col d-flex pl-4 pr-4">
                <h6 class="m-0 dkblue font-weight-semibold">
                  {{ day.date }}
                </h6>
                <h6 class="m-0 dkblue font-weight-semibold ml-auto">
                  {{ day.hours }}
                </h6>
              </div>
            </div>
            <!-- TIME ENTRY LINE ITEM -->
            <div class="d-flex flex-column item py-4" *ngFor="let entry of day.entries">
              <div class="row d-flex m-0 align-items-center">
                <div class="col-auto pl-4 pr-0" style="width: 70px">
                  <h4
                    class="m-0 dkblue font-weight-semibold"
                    style="line-height: 38px"
                    [ngClass]="getCSSClass(entry.activity_id)"
                  >
                    {{ entry.hours_worked.toString().padStart(2, '0') }}:{{
                      entry.minutes_worked.toString().padStart(2, '0')
                    }}
                  </h4>
                </div>
                <div class="col pl-4 pr-4">
                  <h5 class="m-0 dkblue font-weight-semibold mb-1" [ngClass]="getCSSClass(entry.activity_id)">
                    {{ entry.activity.name }}
                    <small
                      class="gray ml-1 font-weight-semibold"
                      *ngIf="!(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)"
                    >
                      {{ entry.company.name }}
                    </small>
                  </h5>
                  <a
                    (click)="closePanel()"
                    routerLink="/projects/{{ entry.parent_id }}"
                    class="mb-0 ltblue font-weight-normal hover-u d-inline-block"
                    *ngIf="
                      entry.parent_type_id === 3 &&
                      !(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)
                    "
                  >
                    PRJ {{ entry.parent_code }} - {{ entry.parent_title }}
                  </a>
                  <a
                    (click)="closePanel()"
                    routerLink="/work-orders/{{ entry.parent_id }}"
                    class="mb-0 ltblue font-weight-normal hover-u d-inline-block"
                    *ngIf="
                      entry.parent_type_id === 34 &&
                      !(entry.activity_id === 7 || entry.activity_id === 9 || entry.activity_id === 10)
                    "
                  >
                    {{ entry.parent_code }} - {{ entry.parent_title }}
                  </a>
                  <!--              Show General for PTO/Vacation, Sick TIme, Holiday-->
                  <h6
                    class="mb-0 gray font-weight-normal"
                    *ngIf="!entry.parent_id && !ptoActivityIds.includes(+entry.activity.id)"
                  >
                    General (No Project or Work Order)
                  </h6>
                </div>
                <div class="col-auto pl-4 pr-0">
                  <app-profile-thumbnail
                    [noBorder]="true"
                    [height]="30"
                    [width]="30"
                    [userId]="entry.worker_id"
                  ></app-profile-thumbnail>
                </div>
                <div class="col-auto pl-4 pr-4 d-flex flex-column align-self-start" style="width: 70px">
                  <div class="d-flex align-items-center">
                    <button
                      mat-icon-button
                      color="accent"
                      class="ml-auto"
                      [matMenuTriggerFor]="entry_menu"
                      *ngIf="isAppAdmin || entry.created_by_id === authService.currentUser.id"
                    >
                      <mat-icon> more_vert </mat-icon>
                    </button>
                    <mat-menu #entry_menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="editTimeLogEntry(entry)">
                        <mat-icon>edit</mat-icon>
                        <span> Edit Time Entry </span>
                      </button>
                      <button mat-menu-item (click)="deleteTimeLogEntry(entry.id)">
                        <mat-icon>delete</mat-icon>
                        <span> Delete Time Entry </span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <!-- IF NOTE EXISTS -->
              <div *ngIf="entry.notes" class="row m-0 pt-2">
                <div class="col-auto pl-4 pr-0" style="width: 70px"></div>
                <div class="col pl-4 pr-4">
                  <span [innerHTML]="entry.notes" class="m-0 dkgray"> </span>
                </div>
              </div>
            </div>
          </div>
        </span>
        <section
          class="d-flex align-items-center p-4"
          *ngIf="maxDisplayedEntries && maxDisplayedEntries < displayedEntries.length && !loading"
        >
          <button mat-stroked-button type="button" color="accent" class="mr-3" (click)="loadMore()">Load More</button>
          <button mat-button type="button" color="accent" class="mr-3" (click)="loadAll()">Load All</button>
          <mat-spinner *ngIf="loadingItems" class="ml-auto" color="accent" diameter="26"></mat-spinner>
        </section>
      </cdk-virtual-scroll-viewport>
    </main>
  </div>
</div>

<div class="ease" (click)="closePanel()" [ngClass]="{ 'time-log-panel-overlay': isPanelOpen() }"></div>
