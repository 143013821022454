<!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->
<div class="container-fluid p-4">
  <div class="row align-items-center">
    <div class="col">
      <h4 class="dkblue font-weight-semibold m-0">Program Data</h4>
    </div>
    <div class="col-auto text-right">
      <button mat-button color="accent" (click)="exportPDF()">
        <mat-icon> save_alt </mat-icon>
        <!--      TODO when this download button is hooked up, make sure to add downloading=true before, and downloading=false after to get the spinner-->
        <span> Download PDF </span>
        <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="downloading"></i>
      </button>
      <button
        class="ml-3 bg-white"
        mat-stroked-button
        color="accent"
        *ngIf="!isEditing"
        (click)="isEditing = true"
        [disabled]="false"
      >
        Edit
      </button>
      <button class="ml-3" mat-button class="gray" *ngIf="isEditing" (click)="isEditing = false" [disabled]="false">
        Cancel
      </button>
      <button
        class="ml-3"
        color="success"
        mat-flat-button
        *ngIf="isEditing"
        (click)="saveData()"
        [disabled]="!allowEdit"
      >
        Save Changes
      </button>
    </div>
  </div>
  <br />
  <div class="m-portlet" m-portlet="true">
    <div class="ease m-portlet__body">
      <h4 class="dkblue">Attendees</h4>
      <app-string-list-pill-container
        [disabled]="!isEditing"
        placeholder="Add attendee"
        (itemListChange)="onAttendeesListChanged($event)"
        [items]="data.attendeesList"
      >
      </app-string-list-pill-container>

      <app-yes-no-selection
        [disabled]="!isEditing"
        val1Label="Current Space"
        val2Label="New Space"
        label="What Is The Type Of Renovation?"
        (selectionChanged)="onSpaceTypeChange($event)"
        [defaultValue]="!this.data.isNewSpace"
      ></app-yes-no-selection>

      <div *ngIf="!data.isNewSpace">
        <app-yes-no-selection
          [disabled]="!isEditing"
          label="Are alarms or strobes affected?"
          (selectionChanged)="strobesAffectedSelChange($event)"
          [defaultValue]="this.data.areStrobesAffected"
        >
        </app-yes-no-selection>
        <app-yes-no-selection
          [disabled]="!isEditing"
          label="Are the sprinkler heads affected?"
          (selectionChanged)="sprinklerHeadsAffectedSelChange($event)"
          [defaultValue]="this.data.areSprinkerHeadsAffected"
        ></app-yes-no-selection>
        <app-yes-no-selection
          [disabled]="!isEditing"
          label="Will flooring need to be replaced?"
          [requireInputOnYes]="true"
          inputPlaceholder="What is the extent?"
          (selectionChanged)="flooringToBeReplacedSelChange($event)"
          [defaultValue]="this.data.isFlooringToBeReplaced"
          [(detailInputValue)]="data.flooringReplacementExtentNote"
        ></app-yes-no-selection>
      </div>

      <app-yes-no-selection
        [disabled]="!isEditing"
        label="According to the Asbestos report, does this space contain Asbestos?"
        [requireInputOnYes]="true"
        inputPlaceholder="How is the timeline and cost affected by this?"
        (selectionChanged)="containsAsbestosSelChange($event)"
        [defaultValue]="this.data.containsAsbestos"
        [(detailInputValue)]="data.containsAsbestosNote"
      ></app-yes-no-selection>

      <app-yes-no-selection
        [disabled]="!isEditing"
        label="Will there be any large equipment that will require a structural engineer?"
        [requireInputOnYes]="true"
        (selectionChanged)="requireStructuralEngineerSelChange($event)"
        [defaultValue]="this.data.requiresStructuralEngineer"
        [(detailInputValue)]="data.requiresStructuralEngineerNote"
      >
      </app-yes-no-selection>

      <h4 class="dkblue">Door Types</h4>
      <app-checkbox-container
        [(items)]="data.doorTypeCheckboxItems"
        [disabled]="!isEditing"
        [requireNotes]="true"
        notesPlaceholder="Please enter details about the doors check marked"
        [(noteField)]="data.doorTypeNoteField"
      >
      </app-checkbox-container>
      <br />

      <h4 class="dkblue">Technology</h4>
      <br />
      <h5 class="dkblue">Locations for TVs And Monitors</h5>
      <app-string-list-pill-container
        [disabled]="!isEditing"
        placeholder="Enter Location"
        (itemListChange)="onAddTechnologyLocation($event)"
        [items]="data.technologyLocationsList"
      >
      </app-string-list-pill-container>

      <app-checkbox-container
        [(items)]="data.technologyCheckboxItems"
        [disabled]="!isEditing"
        [requireNotes]="true"
        notesPlaceholder="Technology Notes"
        [(noteField)]="data.technologyNoteField"
      ></app-checkbox-container>

      <h4 class="dkblue">Additional Misc Equipment</h4>
      <app-string-list-pill-container
        placeholder="Add additional required item"
        [disabled]="!isEditing"
        (itemListChange)="onAdditionalRequiredItemListChanged($event)"
        [items]="data.requiredItemsList"
        [buttonItems]="[
          'Coffee Maker',
          'FS Refrigerator',
          'UC Refrigerator',
          'FS Ice Machine',
          'UC Ice Machine',
          'Lockers'
        ]"
      >
      </app-string-list-pill-container>

      <app-yes-no-selection
        label="Does any equipment require Emergency Power?"
        [disabled]="!isEditing"
        [requireInputOnYes]="true"
        (selectionChanged)="requiresEmergencyPowerSelChange($event)"
        [defaultValue]="this.data.requiresEmergencyPower"
        [(detailInputValue)]="data.requiresEmergencyPowerNote"
      >
      </app-yes-no-selection>
      <app-yes-no-selection
        label="Are there any requirements for Access Controls?"
        [disabled]="!isEditing"
        [requireInputOnYes]="true"
        (selectionChanged)="requiresAccessControlsSelChange($event)"
        [defaultValue]="this.data.requiresAccessControls"
        [(detailInputValue)]="data.requiresAccessControlsNote"
      >
      </app-yes-no-selection>
      <app-yes-no-selection
        label="Digital Signage Required?"
        [disabled]="!isEditing"
        [requireInputOnYes]="true"
        (selectionChanged)="requiresDigitalSignageSelChange($event)"
        [defaultValue]="this.data.requiresDigitalSignage"
        [(detailInputValue)]="data.requiresDigitalSignageNote"
      ></app-yes-no-selection>
      <app-yes-no-selection
        label="Other Signage Required?"
        [disabled]="!isEditing"
        [requireInputOnYes]="true"
        (selectionChanged)="requiresOtherSignageSelChange($event)"
        [defaultValue]="this.data.requiresOtherSignage"
        [(detailInputValue)]="data.requiresOtherSignageNote"
      ></app-yes-no-selection>
      <app-yes-no-selection
        label="Accent Walls Required?"
        [disabled]="!isEditing"
        [requireInputOnYes]="true"
        (selectionChanged)="accentWallsRequired($event)"
        [defaultValue]="this.data.requiresAccentWalls"
        [(detailInputValue)]="data.requiresAccentWallsNote"
      ></app-yes-no-selection>

      <app-yes-no-selection
        label="Is this a clinical space?"
        [disabled]="!isEditing"
        [requireInputOnYes]="false"
        (selectionChanged)="isClinicalSpaceChanged($event)"
        [defaultValue]="this.data.isClinicalSpace"
      >
      </app-yes-no-selection>
      <div *ngIf="data.isClinicalSpace">
        <app-yes-no-selection
          label="Will the room contain any biohazard materials?"
          [disabled]="!isEditing"
          [requireInputOnYes]="true"
          (selectionChanged)="containsBiohazardMaterialsSelChange($event)"
          [defaultValue]="this.data.containsBiohazardMaterials"
          [(detailInputValue)]="data.containsBiohazardMaterialsNote"
        ></app-yes-no-selection>
        <app-yes-no-selection
          label="Are there any Healthcare supplies or equipment that need to be installed?"
          [disabled]="!isEditing"
          [requireInputOnYes]="true"
          (selectionChanged)="healthcareSuppliesNeedInstallationSelChange($event)"
          [defaultValue]="this.data.healthcareSuppliesNeedInstall"
          [(detailInputValue)]="data.needsHealthcareSuppliesNote"
        ></app-yes-no-selection>
        <app-yes-no-selection
          label="Is emergency eyewash required?"
          [disabled]="!isEditing"
          [requireInputOnYes]="true"
          (selectionChanged)="emergencyEyewashRequiredSelChange($event)"
          [defaultValue]="this.data.requiresEmergencyEyewash"
          [(detailInputValue)]="data.requiresEmergencyEyewashNote"
        >
        </app-yes-no-selection>
      </div>
    </div>
  </div>
  <div class="row align-items-center mb-2">
    <div class="col text-right">
      <button
        class="ml-3 bg-white"
        mat-stroked-button
        color="accent"
        *ngIf="!isEditing"
        (click)="isEditing = true"
        [disabled]="false"
      >
        Edit
      </button>
      <span *ngIf="isEditing">
        <button class="ml-3" mat-button class="gray" (click)="isEditing = false" [disabled]="false">Cancel</button>
        <button class="ml-3" color="success" mat-flat-button (click)="saveData()" [disabled]="!allowEdit">
          Save Changes
        </button>
      </span>
    </div>
  </div>
</div>

<!-- PDF EXPORT -->
<div id="kendo-content" *ngIf="data.id">
  <kendo-pdf-export #pdf paperSize="Letter" keepTogether="section" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <section class="form-header p-4">
        <div class="row">
          <div class="col-3">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label"> SQ FT </label>
                <p>
                  {{ selectedProject.building_name }}
                  <br />
                  Floor {{ selectedProject.floor_name }}
                </p>
              </div> -->
              <div class="col-4">
                <label class="main-label"> WORKSPACE MANAGER </label>
                <p>
                  {{ selectedProject.workspace_manager_first_name + ' ' + selectedProject.workspace_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label"> Location </label>
                <p>
                  {{ selectedProject.square_footage }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label"> Completion Date </label>
                <p>31 August 2019</p>
              </div>
              <div class="col-4">
                <label class="main-label"> Architect </label>
                <p>
                  {{ selectedProject.architect_first_name + ' ' + selectedProject.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section class="form-title ml-4 mr-4">
        <div class="row">
          <div class="col">
            <h1 class="black font-weight-bold m-0">Program Data Sheet</h1>
          </div>
          <div class="col-auto">
            <h1 class="black font-weight-bold m-0"></h1>
          </div>
        </div>
      </section>
      <div class="form-info-section ml-4 mr-4">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-3">
                <label class="main-label">RENOVATION TYPE</label>
                <p class="m-0">
                  {{ data.isNewSpace ? 'New Space' : 'Current Space' }}
                </p>
              </div>
              <div class="col-9">
                <label class="main-label">ATTENDEES</label>
                <p class="m-0">
                  {{ data.attendeesList.join(', ') }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row">
                <div class="col">
                  <label class="main-label"> AFFECTED AREAS / NEEDS </label>
                </div>
              </div>
              <div class="row">
                <div class="col-6" *ngIf="data.areStrobesAffected">
                  <h5>Alarms / Strobes</h5>
                </div>
                <div class="col-6" *ngIf="data.containsAsbestos">
                  <h5>Asbestos Abatement</h5>
                  <p>{{ data.containsAsbestosNote }}</p>
                </div>
                <div class="col-6">
                  <h5>Sprinkler Heads</h5>
                </div>
                <div class="col-6" *ngIf="data.isFlooringToBeReplaced">
                  <h5>Flooring</h5>
                  <p>{{ data.flooringReplacementExtentNote }}</p>
                </div>
                <div class="col-6">
                  <h5>Large Equipment / Structural Engineer</h5>
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row">
                <div class="col">
                  <label class="main-label"> DOOR TYPES </label>
                  <div class="row">
                    <div class="col-3">
                      <p *ngFor="let doorType of data.doorTypeCheckboxItems" class="margin-0">
                        {{ doorType.checked ? doorType.label : '' }}
                      </p>
                    </div>
                    <div class="col-9">{{ data.doorTypeNoteField }}</div>
                  </div>
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row">
                <div class="col">
                  <label class="main-label"> LOCATION OF TVS & MONITORS </label>
                  <p>{{ data.technologyLocationsList.join(', ') }}</p>
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row">
                <div class="col-12">
                  <label class="main-label"> REQUESTED TECHNOLOGY </label>
                </div>
                <div class="col-3">
                  <p *ngFor="let rt of data.technologyCheckboxItems">
                    {{ rt.checked ? rt.label : '' }}
                  </p>
                </div>
                <div class="col-9">
                  {{ data.technologyNoteField }}
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row">
                <div class="col-12">
                  <label class="main-label"> MISC EQUIPMENT </label>
                </div>
                <div class="col">
                  <p>{{ data.requiredItemsList.join(', ') }}</p>
                </div>
                <div class="col" *ngIf="data.requiresEmergencyPower">
                  <p>Equipment Requires Emergency Power</p>
                </div>
              </div>
            </section>
            <section>
              <div class="row">
                <div class="col-12">
                  <label class="main-label"> OTHER NEEDS </label>
                </div>
                <div class="col-6" *ngIf="data.requiresDigitalSignage">
                  <p>Digital Signage</p>
                  <p>{{ data.requiresDigitalSignageNote }}</p>
                </div>
                <div class="col-6" *ngIf="data.requiresAccentWalls">
                  <p>Accent Walls</p>
                  <p>{{ data.requiresAccentWallsNote }}</p>
                </div>
                <div class="col-6" *ngIf="data.requiresOtherSignage">
                  <p>Other Signage</p>
                  <p>{{ data.requiresOtherSignageNote }}</p>
                </div>
              </div>
            </section>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <section>
              <div class="row" *ngIf="data.healthcareSuppliesNeedInstall">
                <div class="col-12">
                  <label class="main-label"> CLINICAL SPACE NEEDS </label>
                </div>
                <div class="col-6" *ngIf="data.containsBiohazardMaterials">
                  <p>Biohazard Materials</p>
                  <p *ngIf="data.containsBiohazardMaterialsNote">
                    {{ data.containsBiohazardMaterialsNote }}
                  </p>
                </div>
                <div class="col-6" *ngIf="data.requiresEmergencyEyewash">
                  <p>Emergency Eyewash</p>
                  <p *ngIf="data.requiresEmergencyEyewashNote">
                    {{ data.requiresEmergencyEyewashNote }}
                  </p>
                </div>
                <div class="col-6" *ngIf="data.healthcareSuppliesNeedInstall">
                  <p>Healthcare Supplies or Equipment</p>
                  <p>{{ data.needsHealthcareSuppliesNote }}</p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </kendo-pdf-export>
</div>
