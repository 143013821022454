import { Pipe, PipeTransform } from '@angular/core';

import { ProjectDrawing } from 'src/app/workspaces/construction/types';

@Pipe({
  name: 'projectDrawingFilter',
})
export class ProjectDrawingFilterPipe implements PipeTransform {
  transform(projectDrawings: ProjectDrawing[], searchTerm: string): any {
    if (projectDrawings && projectDrawings.length > 0) {
      let filteredProjectDrawings = projectDrawings;
      if (searchTerm) {
        filteredProjectDrawings = filteredProjectDrawings.filter((i) => i.name.toLowerCase().includes(searchTerm));
      }
      return filteredProjectDrawings;
    } else {
      return projectDrawings;
    }
  }
}
