<div class="project-modal p-0">
  <div class="row align-items-center mb-3">
    <div class="col">
      <h5 class="dkblue m-0 font-weight-semibold">Add Alt Bid</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="d-flex flex-column">
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="draftAlt.amount_type">
          <mat-option value="addition"> Added Cost </mat-option>
          <mat-option value="reduction"> Reduced Cost </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Alt Bid Description</mat-label>
        <input matInput type="text" [(ngModel)]="draftAlt.description" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Alt Bid Number</mat-label>
        <input matInput type="text" [(ngModel)]="draftAlt.number" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label *ngIf="draftAlt.amount_type != 'reduction'">Alt Bid Amount</mat-label>
        <mat-label *ngIf="draftAlt.amount_type === 'reduction'">Alt Bid Deduction</mat-label>
        <mat-icon class="ltgray" matPrefix>attach_money</mat-icon>
        <input matInput type="number" [(ngModel)]="draftAlt.amount" />
      </mat-form-field>
    </div>
    <div>
      <button
        mat-stroked-button
        class="bg-white"
        color="accent"
        (click)="openUploadModal('alt')"
        *ngIf="!draftAlt.file"
      >
        Upload PDF
      </button>
      <mat-chip-list class="d-inline-block align-middle">
        <app-file-chip
          *ngIf="draftAlt.file"
          [file]="draftAlt.file"
          [removable]="true"
          (removeEvent)="removeFileFromBidDetails('alt', draftAlt.file, true)"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row mt-5 mb-2">
    <div class="col">
      <button (click)="cancel()" mat-button class="full-width gray" type="button">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button class="full-width" color="success" (click)="addAlt()">Add Alt Bid</button>
    </div>
  </div>
</div>
