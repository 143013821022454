import { Injectable } from '@angular/core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService } from 'src/app/services';
import { User } from 'src/app/types';
import { UserType } from '../enums';

/**
 * Functionality for user searching. Provided in should possibly be moved so that it is not a singleton service.
 */
@Injectable({
  providedIn: 'root',
})
export class UserSearchService {
  private searchUserInput: Subject<string | any> = new Subject();

  private filteredUsers: User[] = [];

  constructor(private userService: UserService) {
    this.refreshUsers();
  }

  public refreshUsers() {
    this.searchUserInput.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
      if (searchTerm && !searchTerm.id) {
        this.userService
          .searchUsers([
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'email', value: searchTerm, match: 'any' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'full_name', value: searchTerm, match: 'any' },
            { type: 'operator', value: ')' },
          ])
          .subscribe((users) => {
            if (users) {
              this.filteredUsers = users;
            } else {
              this.filteredUsers = [];
            }
          });
      }
    });
  }

  public filterUsers(value) {
    this.searchUserInput.next(value);
  }

  public getFilteredUsers(userTypes: UserType[] = []): User[] {
    return userTypes.length > 0
      ? this.filteredUsers.filter((user) => userTypes.includes(+user.user_type_id))
      : this.filteredUsers;
  }

  public defaultUserMapper(user: User): string {
    return user
      ? (user.first_name || '') + (user.first_name || user.last_name ? ' ' : '') + (user.last_name || '')
      : '';
  }
}
