import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { saveAs } from 'file-saver';

import { UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-conclude-meeting-dialog',
  templateUrl: './conclude-meeting-dialog.component.html',
  styleUrls: ['./conclude-meeting-dialog.component.scss'],
})
export class ConcludeMeetingDialogComponent implements OnInit {
  downloading = false;
  subject;
  message;
  file: any; // This is UhatFileReference, but saveAs won't accept it
  downloadableFiles;

  constructor(public dialogRef: MatDialogRef<ConcludeMeetingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.file = data.file;
    this.downloadableFiles = data.downloadableFiles;
  }

  async ngOnInit() {}

  // send() {
  //   this.dialogRef.close({
  //     subject: this.subject,
  //     message: this.message,
  //     file: this.file,
  //     attendees: this.data.attendees,
  //   });
  // }

  close() {
    this.dialogRef.close(false);
  }
}
