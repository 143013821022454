import { ProjectProgramDataDbModel } from 'src/app/workspaces/construction/models';

/**
 * Data Model For Interacting With The Front End. This is converted from the backend response.
 */
export class ProjectProgramData {
  public id: number;
  public projectId: number;
  public isNewSpace: boolean;
  public isClinicalSpace = false;
  public containsBiohazardMaterials: boolean;
  public healthcareSuppliesNeedInstall: boolean;
  public requiresEmergencyEyewash: boolean;
  public requiresEmergencyPower: boolean;
  public requiresAccessControls: boolean;
  public requiresDigitalSignage: boolean;
  public requiresOtherSignage: boolean;
  public requiresAccentWalls: boolean;
  public areStrobesAffected: boolean;
  public areSprinkerHeadsAffected: boolean;
  public isFlooringToBeReplaced: boolean;
  public containsAsbestos: boolean;
  public requiresStructuralEngineer: boolean;
  public requiredItemsList: string[];
  public attendeesList: string[];
  public technologyLocationsList: string[];
  public technologyCheckboxItems = [
    { label: 'Data', checked: false },
    { label: 'Cable', checked: false },
    { label: 'Wifi', checked: false },
    { label: 'Phone', checked: false },
  ];
  public technologyNoteField: string;
  public doorTypeCheckboxItems = [
    { label: 'Flush', checked: false },
    { label: 'Full Glass', checked: false },
    { label: 'Half Glass', checked: false },
    { label: 'Narrow Lite', checked: false },
    { label: 'Frosted Glass', checked: false },
  ];
  public doorTypeNoteField: string;

  public flooringReplacementExtentNote: string;
  public containsAsbestosNote: string;
  public requiresStructuralEngineerNote: string;
  public requiresEmergencyPowerNote: string;
  public requiresAccessControlsNote: string;
  public requiresDigitalSignageNote: string;
  public requiresOtherSignageNote: string;
  public requiresAccentWallsNote: string;
  public containsBiohazardMaterialsNote: string;
  public needsHealthcareSuppliesNote: string;
  public requiresEmergencyEyewashNote: string;

  public static fromDatabaseModel(dbModel: ProjectProgramDataDbModel): ProjectProgramData {
    if (!dbModel) {
      return null;
    }
    const data = new ProjectProgramData();

    data.id = dbModel.id;
    data.projectId = dbModel.project_id;
    data.isClinicalSpace = dbModel.is_clinical_space;
    data.isNewSpace = dbModel.is_new_space;
    data.containsBiohazardMaterials = dbModel.contains_biohazard_materials;
    data.containsAsbestos = dbModel.contains_asbestos;
    data.healthcareSuppliesNeedInstall = dbModel.needs_healthcare_supplies_install;
    data.requiresEmergencyEyewash = dbModel.requires_emergency_eyewash;
    data.requiresEmergencyPower = dbModel.requires_emergency_power;
    data.requiresAccessControls = dbModel.requires_access_controls;
    data.requiresDigitalSignage = dbModel.requires_digital_signage;
    data.requiresOtherSignage = dbModel.requires_other_signage;
    data.requiresAccentWalls = dbModel.requires_accent_walls;
    data.areStrobesAffected = dbModel.are_strobes_affected;
    data.areSprinkerHeadsAffected = dbModel.are_sprinkler_heads_affected;
    data.isFlooringToBeReplaced = dbModel.is_flooring_to_be_replaced;
    data.containsAsbestos = dbModel.contains_asbestos;
    data.requiresStructuralEngineer = dbModel.requires_structural_engineer;
    data.requiredItemsList = dbModel.required_items.split(',').filter((item) => item.length > 0);
    data.technologyLocationsList = dbModel.technology_locations
      ? dbModel.technology_locations.split(',').filter((item) => item.length > 0)
      : [];
    data.attendeesList = dbModel.attendees.split(',').filter((item) => item.length > 0);

    const dbTechnologyCheckbox: string[] = dbModel.technology_checkboxes.split(',').filter((item) => item.length > 0);
    data.technologyCheckboxItems.forEach((item) => {
      item.checked = dbTechnologyCheckbox.includes(item.label);
    });
    data.technologyNoteField = dbModel.technology_note;

    const dbDoorTypeCheckboxes: string[] = dbModel.door_type_checkboxes.split(',').filter((item) => item.length > 0);
    data.doorTypeCheckboxItems.forEach((item) => {
      item.checked = dbDoorTypeCheckboxes.includes(item.label);
    });
    data.doorTypeNoteField = dbModel.door_type_note;

    data.containsAsbestosNote = dbModel.contains_asbestos_note;
    data.containsBiohazardMaterialsNote = dbModel.contains_biohazard_materials_note;
    data.needsHealthcareSuppliesNote = dbModel.needs_healthcare_supplies_note;
    data.requiresAccentWallsNote = dbModel.requires_accent_walls_note;
    data.requiresAccessControlsNote = dbModel.requires_access_controls_note;
    data.requiresEmergencyPowerNote = dbModel.requires_emergency_power_note;
    data.requiresStructuralEngineerNote = dbModel.requires_structural_engineer_note;
    data.requiresDigitalSignageNote = dbModel.requires_digital_signage_note;
    data.requiresOtherSignageNote = dbModel.requires_other_signage_note;
    data.requiresEmergencyEyewashNote = dbModel.requires_emergency_eyewash_note;
    data.flooringReplacementExtentNote = dbModel.flooring_replacement_extent_note;

    return data;
  }
}
