import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { convertDateToFiscalYear, getUniqueArray, isCurrentFiscalYear } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  // returns the date that is the given number of weekdays after the start date
  public addWeekdays(weekdaysToAdd: number, startDate = null): moment.Moment {
    const momentStartDate = startDate ? moment(startDate) : moment();
    if (momentStartDate.isValid()) {
      let weekdaysRemainingToAdd = weekdaysToAdd;
      let dateToCheck = momentStartDate;
      while (weekdaysRemainingToAdd > 0 || dateToCheck.isoWeekday() > 5) {
        dateToCheck = dateToCheck.add(1, 'days');
        if (dateToCheck.isoWeekday() <= 5) {
          weekdaysRemainingToAdd--;
        }
      }
      return dateToCheck;
    } else {
      return null;
    }
  }

  public getFiscalYearOptions(activeFiscalYear: string | number = ''): { value: string; isCurrentYear: boolean }[] {
    const currentFiscalYear = convertDateToFiscalYear(new Date());

    const fiscalYears = [
      {
        value: (currentFiscalYear - 1).toString(),
        isCurrentYear: false,
      },
      {
        value: currentFiscalYear.toString(),
        isCurrentYear: true,
      },
      {
        value: (currentFiscalYear + 1).toString(),
        isCurrentYear: false,
      },
    ];

    if (activeFiscalYear) {
      fiscalYears.push({
        value: activeFiscalYear.toString(),
        isCurrentYear: isCurrentFiscalYear(Number(activeFiscalYear)),
      });
    }

    return getUniqueArray(fiscalYears).sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}
