import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatepickerHeaderComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { FileService, ModalService, ProgressIndicatorService, ReleaseNotesService } from 'src/app/services';
import { ReleaseNoteBug, ReleaseNoteFeature, ReleaseNoteRelease, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-release-notes-add-edit-dialog',
  templateUrl: './release-notes-add-edit-dialog.component.html',
  styleUrls: ['./release-notes-add-edit-dialog.component.scss'],
})
export class ReleaseNotesAddEditDialogComponent implements OnInit {
  public customHeader = DatepickerHeaderComponent;
  releaseForm: ReleaseNoteRelease = { id: 0 };
  featureForm: ReleaseNoteFeature = { id: 0 };
  bugForm: ReleaseNoteBug = { id: 0 };
  selectedForm;
  selectedItem;
  public attachedFiles: File[] = [];
  private parentResourceType: ResourceType;
  private releaseNoteReleasesFields = ['id', 'title', 'release_date', 'description', 'files'];
  public files: UhatFileReference[];

  releasesFormGroup: FormGroup = this.fb.group({
    id: [0, []],
    title: ['', [Validators.required]],
    release_date: [null, [Validators.required]],
    description: ['', [Validators.required]],
    files: [null, []],
  });
  featuresFormGroup: FormGroup = this.fb.group({
    id: [0, []],
    title: ['', [Validators.required]],
    start_date: [null, [Validators.required]],
    end_date: [null, [Validators.required]],
    timeframe_id: [1, []],
    description: ['', [Validators.required]],
    created_datetime: [null, []],
    files: [null, []],
  });
  bugsFormGroup: FormGroup = this.fb.group({
    id: [0, []],
    title: ['', [Validators.required]],
    identified_date: [null, []],
    fixed_date: [null, []],
    timeframe_id: [0, []],
    description: ['', [Validators.required]],
    created_datetime: [null, []],
    files: [null, []],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private releaseNotesService: ReleaseNotesService,
    private fb: FormBuilder,
    private _progressIndicatorService: ProgressIndicatorService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ReleaseNotesAddEditDialogComponent>,
    private _fileService: FileService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    // we will always have data here
    this.selectedForm = this.data.form;
    // editing
    if (this.data?.item?.id) {
      this.files = this.data?.item?.files;
      if (this.selectedForm === 'release') {
        this.releaseForm = this.data.item;
        this.parentResourceType = ResourceType.ReleaseNoteRelease;
        const release = { ...this.data.item };
        this.releasesFormGroup.setValue(release);
      } else if (this.selectedForm === 'feature') {
        this.featureForm = this.data.item;
        this.parentResourceType = ResourceType.ReleaseNoteFeature;
        this.featuresFormGroup.setValue(this.data.item);
      } else if (this.selectedForm === 'bug') {
        this.bugForm = this.data.item;
        this.parentResourceType = ResourceType.ReleaseNoteBug;
        this.bugsFormGroup.setValue(this.data.item);
      }
    }
  }

  async saveRelease() {
    if (this.releasesFormGroup.valid) {
      const release = { ...this.releasesFormGroup.value };
      release.release_date = moment(release.release_date).format('yyyy-MM-DD');
      delete release.id;
      delete release.files;

      this._progressIndicatorService.openAwaitIndicatorModal();
      if (this.releaseForm.id > 0) {
        this._progressIndicatorService.updateStatus('Updating Release...');
        await this.releaseNotesService.updateReleaseNoteRelease(this.releaseForm.id, release).toPromise();
      } else {
        this._progressIndicatorService.updateStatus('Adding Release...');
        const createdItem = await this.releaseNotesService.createReleaseNoteRelease(release).toPromise();
        await this.saveFilesForItem(createdItem.id, ResourceType.ReleaseNoteRelease);
      }

      this.dialogRef.close(true);
    }
  }

  async saveFeature() {
    if (this.featuresFormGroup.valid) {
      const feature = { ...this.featuresFormGroup.value };
      feature.start_date = moment(feature.start_date).format('yyyy-MM-DD');
      feature.end_date = moment(feature.end_date).format('yyyy-MM-DD');
      delete feature.id;
      delete feature.files;
      delete feature.created_datetime;

      this._progressIndicatorService.openAwaitIndicatorModal();
      if (this.featureForm.id > 0) {
        this._progressIndicatorService.updateStatus('Updating Feature...');
        await this.releaseNotesService.updateReleaseNoteFeature(this.featureForm.id, feature).toPromise();
      } else {
        this._progressIndicatorService.updateStatus('Adding Feature...');
        const createdItem = await this.releaseNotesService.createReleaseNoteFeature(feature).toPromise();
        await this.saveFilesForItem(createdItem.id, ResourceType.ReleaseNoteFeature);
      }

      this.dialogRef.close(true);
    }
  }

  async saveBug() {
    if (this.bugsFormGroup.valid) {
      const bug = { ...this.bugsFormGroup.value };
      bug.fixed_date = bug.fixed_date ? moment(bug.fixed_date).format('yyyy-MM-DD') : null;
      bug.identified_date = bug.identified_date ? moment(bug.identified_date).format('yyyy-MM-DD') : null;
      delete bug.id;
      delete bug.files;
      delete bug.created_datetime;

      this._progressIndicatorService.openAwaitIndicatorModal();
      if (this.bugForm.id > 0) {
        this._progressIndicatorService.updateStatus('Updating Bug...');
        await this.releaseNotesService.updateReleaseNoteBug(this.bugForm.id, bug).toPromise();
      } else {
        this._progressIndicatorService.updateStatus('Adding Bug...');
        const createdItem = await this.releaseNotesService.createReleaseNoteBug(bug).toPromise();
        await this.saveFilesForItem(createdItem.id, ResourceType.ReleaseNoteBug);
      }

      this.dialogRef.close(true);
    }
  }

  private async saveFilesForItem(parentId: number, parentTypeId: ResourceType) {
    const promises = [];
    this.attachedFiles.forEach((file) =>
      promises.push(this._fileService.createFile(file, parentId, parentTypeId).toPromise())
    );
    await Promise.all(promises);
    this.attachedFiles = [];
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: !this.data?.item?.id ? null : this.parentResourceType,
          parentResourceId: this.data?.item?.id,
          allowSearchFromProject: false,
          hideTags: true,
          skipUpload: !this.data?.item?.id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          if (this.data?.item?.id) {
            this.files = [...this.files, ...resultData];
          } else {
            this.attachedFiles = [...this.attachedFiles, ...(resultData?.computerFiles || [])];
          }
        }
      });
  }

  async deleteFile(file) {
    this.files = this.files.filter((f) => (f.file_id || f.id) !== (file.file_id || file.id));
    await this._fileService.unlinkFile(file.id).toPromise();
    await this._fileService.deleteFileWithoutConfirmation(file).toPromise();
  }

  public removeAttachedFile(file: File) {
    this.attachedFiles = this.attachedFiles.filter((attachedFile: File) => attachedFile !== file);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
