import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Invoice } from '../types';

@Pipe({
  name: 'arfInvoices',
})
export class ArfInvoicesPipe implements PipeTransform {
  transform(invoices: Invoice[], sort: string, direction: 'asc' | 'desc'): unknown {
    let sortedInvoices = _.orderBy(invoices, [sort], [direction]);
    return sortedInvoices;
  }
}
