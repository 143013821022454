import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationService } from 'src/app/services';
import { LocationExist, Suite, APIFilter } from 'src/app/types';
import { LOCATION_NOT_FOUND } from 'src/app/utils';
@Component({
  selector: 'app-suite',
  templateUrl: './suite.component.html',
  styleUrls: ['./suite.component.scss'],
})
export class SuiteComponent implements OnInit {
  @Input() public notFound = false;
  @Input() public required = false;
  @Input() public showLabel = true;

  private _placeholder = 'Search Suites';

  public suite = new FormControl('');
  public filteredSuites: Observable<Suite[]>;

  constructor(private _locationService: LocationService) {}

  async ngOnInit(): Promise<void> {
    await this._updateValidations();
  }

  get placeholder() {
    return !this.showLabel && this.required ? `${this._placeholder}*` : this._placeholder;
  }

  private _filter(entry: string): Suite[] {
    return this._locationService.suites.filter(
      (suite) =>
        suite?.name?.toLowerCase()?.includes(entry?.toLowerCase()) || suite?.name?.toLowerCase()?.includes('not')
    );
  }

  private _filterExcludingNotFound(value: string): Suite[] {
    return this._locationService.suites.filter((suite: Suite) =>
      suite?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );
  }

  private async _subscribe() {
    this.filteredSuites = this.suite.valueChanges.pipe(
      startWith(''),
      map((value: string | Suite) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this._locationService.suites.slice()))
    );
  }

  private async _updateValidations() {
    if (this.required) {
      this.suite.setValidators([Validators.required]);
    }
  }

  public checkValue() {
    // times when user changes his mind, back and forth
    // value in input and does not select it,
    // This will select it
    if (this.suite?.value && typeof this.suite?.value === 'string') {
      const foundSuites = this._filterExcludingNotFound(this.suite.value);
      if (foundSuites.length === 1) {
        // we set it for them
        this.suite.setValue(foundSuites[0]);
      } else {
        this.suite.setErrors({ doesNotExist: true });
      }
    }
  }

  public async clearInput(event = null) {
    this.suite.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public async getSuitesByDepartmentId(departmentId: number) {
    this._locationService.suites = [
      ...(await this._locationService.getSuitesByDepartment(departmentId).toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  public async getSuitesByFloorId(floorId: number, suiteId?: number) {
    const suiteFilters: APIFilter[] = !suiteId
      ? [
          { type: 'field', field: 'floor_id', value: floorId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ]
      : [
          { type: 'field', field: 'floor_id', value: floorId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: suiteId.toString() },
          { type: 'operator', value: ')' },
        ];
    this._locationService.suites = [
      ...(await this._locationService.getSuites(['id', 'name'], suiteFilters).toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  public mapper(suite: Suite): string {
    return suite?.name || '';
  }

  public valueExist(value: string): LocationExist {
    const foundSuites = this._filterExcludingNotFound(value);
    if (foundSuites.length === 0) {
      this.suite.setErrors({ doesNotExist: true });
      return { exists: false, id: null, single: false };
    }

    const id = foundSuites.length === 1 ? foundSuites[0].id : null;
    const single = foundSuites.length === 1 ? true : false;
    return { exists: true, id, single };
  }
}
