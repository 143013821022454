import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DispatchType, TopicType } from 'src/app/enums';
import { TopicService } from 'src/app/services';
import { DispatchItem, Topic, TopicCategory, TopicGroup } from 'src/app/types';
import {
  ConfirmationDialogComponent,
  RequestCategoryComponent,
  RequestTopicComponent,
  TopicGroupComponent,
  WorkspaceDropdownComponent,
} from 'src/app/components';

@Component({
  selector: 'app-dispatch-topic',
  templateUrl: './dispatch-topic.component.html',
  styleUrls: ['./dispatch-topic.component.scss'],
})
export class DispatchTopicComponent implements OnInit {
  @Output() setWorkspace = new EventEmitter();
  @Output() convert = new EventEmitter();
  @ViewChild('topics', { static: true }) private _topics_component: RequestTopicComponent;
  @ViewChild('topics_categories', { static: true })
  private _topic_categories_component: RequestCategoryComponent;
  @ViewChild('topic_groups', { static: true }) private _topic_groups_component: TopicGroupComponent;
  @ViewChild('workspace', { static: true })
  private _workspace_component: WorkspaceDropdownComponent;

  private _dispatchItem: DispatchItem = null;

  public hasTopicEditValue = false;
  public topicGroup: FormGroup = this._fb.group({});

  constructor(private _fb: FormBuilder, private _dialog: MatDialog, private _topicService: TopicService) {}

  async ngOnInit() {
    await this._activateTopicCategoriesField();
    await this._activateTopicGroupsField();
    await this._activateTopicsField();
    await this._activateWorkspaceField();
  }

  get isDraft(): boolean {
    return !this._dispatchItem || +this._dispatchItem?.type === +DispatchType.Draft;
  }

  get isEditing(): boolean {
    return !!this._dispatchItem?.id || false;
  }

  get topic_categories(): AbstractControl {
    return this.topicGroup.get('topic_categories');
  }

  get topic_groups(): AbstractControl {
    return this.topicGroup.get('topic_groups');
  }

  get topics(): AbstractControl {
    return this.topicGroup.get('topics');
  }

  get workspace(): AbstractControl {
    return this.topicGroup.get('workspace');
  }

  private async _activateTopicCategoriesField() {
    this.topicGroup.addControl('topic_categories', this._topic_categories_component.topic_categories);
    this.topic_categories.valueChanges.subscribe(async (value: TopicCategory | string) => {
      if (typeof value === 'object' && value?.topic_group_id) {
        // check if category id is the same as topic category id, if not reset topics
        if (this.topics?.value && value.id !== this.topics?.value?.topic_category_id) {
          this.topics.reset();
        }

        if (!this.topic_groups?.value || this.topic_groups.value?.id !== value.topic_group_id) {
          const foundTopicGroup = this._topicService?.topicGroups?.find(
            (topicGroup: TopicGroup) => topicGroup.id === value?.topic_group_id
          );
          if (foundTopicGroup) {
            this.topic_groups.setValue(foundTopicGroup);
          }
        }
        // make a fresh list of work order topics
        this._topics_component.reloadTopicsByCategory(value.id);
      } else if ((typeof value === 'string' && !value?.trim()) || !value) {
        this.topics.reset();
        // make a fresh list of topics
        this._topics_component.reloadAllTopics();
      }
    });
  }

  private async _activateTopicGroupsField() {
    this.topicGroup.addControl('topic_groups', this._topic_groups_component.topic_groups);
    this.topic_groups.valueChanges.subscribe(async (value: TopicGroup | string) => {
      if (typeof value === 'object' && value?.id) {
        await this._topic_categories_component.populateCategoriesByGroup(this.topic_groups?.value?.topic_categories);
      } else if ((typeof value === 'string' && !value?.trim()) || !value) {
        await this._topic_categories_component.populateAllCategories();
        this.topic_categories.reset();
        this.workspace.reset();
      }
    });
  }

  private async _activateTopicsField() {
    this.topicGroup.addControl('topics', this._topics_component.topics);
    this.topics.valueChanges.subscribe((value: Topic) => {
      if (typeof value === 'object' && value?.topic_category) {
        if (this.isEditing && !this.isDraft && this.hasTopicEditValue) {
          this._checkTopicChange(value);
        } else {
          this.hasTopicEditValue = !this.isDraft && this.isEditing;
          this.topic_categories.setValue(value?.topic_category);
          this.workspace.setValue(value?.workspace);
        }
      }
    });
  }

  private async _activateWorkspaceField() {
    this.topicGroup.addControl('workspace', this._workspace_component?.workspace);
    // disable it so we can't change it manually
    this.workspace.disable();
  }

  private async _checkTopicChange({ id, topic_category, topic_type_id, workspace }: Topic) {
    if (this._dispatchItem?.topic_id !== id && this._dispatchItem?.type !== topic_type_id) {
      this._dialog
        .open(ConfirmationDialogComponent, {
          data: {
            titleBarText:
              +this._dispatchItem?.type === +TopicType.Request ? 'Change to Work Order' : 'Change to Request',
            descriptionText: `Warning: Are you sure you want to change ${
              +this._dispatchItem?.type === +TopicType.Request ? 'project request' : 'work order'
            } to ${+this._dispatchItem?.type === +TopicType.Request ? 'work order' : 'project request'}?`,
          },
        })
        .afterClosed()
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            this.convert.emit();
          } else {
            if (this._dispatchItem?.topic) {
              this.topics.setValue(this._dispatchItem?.topic);
            }
          }
        });
    } else {
      this.topic_categories.setValue(topic_category);
      this.workspace.setValue(workspace);
    }
  }

  public async populateTopicFields(dispatchItem: DispatchItem) {
    this._dispatchItem = dispatchItem;
    // If we have a topic, it will populate the rest
    if (dispatchItem?.topic_id) {
      await this._topics_component.reloadAllTopics();
      const topicItem = this._topicService?.topics?.find((topic: Topic) => topic.id === +dispatchItem.topic_id);

      this.topics.setValue(topicItem);
    }
  }
}
