import { Pipe, PipeTransform } from '@angular/core';
import { TimeLog } from '../types';
import { TimeLogFilterModel } from '../models';

import * as moment from 'moment';

@Pipe({
  name: 'timeLogFilter',
})
export class TimeLogFilterPipe implements PipeTransform {
  transform(timeLogs: TimeLog[], filter: TimeLogFilterModel): TimeLog[] {
    if (!timeLogs || !filter) {
      return timeLogs;
    }

    if (filter.start_date) {
      timeLogs = timeLogs.filter((entry) => moment(entry.date_worked).diff(moment(filter.start_date)) >= 0);
    }

    if (filter.end_date) {
      timeLogs = timeLogs.filter((entry) => moment(entry.date_worked).diff(moment(filter.end_date)) <= 0);
    }

    return timeLogs;
  }
}
