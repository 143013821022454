import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserType } from 'src/app/enums';
import { ModuleService, UserService } from 'src/app/services';
import { Module } from 'src/app/types';
import { CustomValidator } from 'src/app/utils';
import { LocationComponent } from '../location/location.component';

@Component({
  selector: 'app-request-account-dialog',
  templateUrl: './request-account-dialog.component.html',
  styleUrls: ['./request-account-dialog.component.scss'],
})
export class RequestAccountDialogComponent implements OnInit {
  @ViewChild('locations', { static: true }) private _location_component: LocationComponent;
  constructor(
    public dialogRef: MatDialogRef<RequestAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private moduleService: ModuleService
  ) {}

  workspaces: Module[] = [];
  private uhatEmail = { domain: 'uhat.org', ldap_name: 'UHAT' };
  private ouhscEmail = { domain: 'ouhsc.edu', ldap_name: 'OUHSC' };

  submitting: boolean;
  public userTypeId: UserType;

  contactInfoFormGroup: FormGroup = this.fb.group({
    first_name: [null, [Validators.required]],
    last_name: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    cell_phone: [null, [CustomValidator.phone]],
    office_phone: [null, [Validators.required, CustomValidator.phone]],
  });

  contactTypeFormGroup: FormGroup = this.fb.group({
    account_type: [null, [Validators.required]],
  });

  locationFormGroup: FormGroup = this.fb.group({
    company_name: [null, [Validators.required]],
    title: [null, [Validators.required]],
    manager_name: [null, [Validators.required]],
    default_module_id: [null],
  });

  get account_type() {
    return this.contactTypeFormGroup.get('account_type');
  }
  get first_name() {
    return this.contactInfoFormGroup.get('first_name');
  }
  get last_name() {
    return this.contactInfoFormGroup.get('last_name');
  }
  get email() {
    return this.contactInfoFormGroup.get('email');
  }
  get cell_phone() {
    return this.contactInfoFormGroup.get('cell_phone');
  }
  get office_phone() {
    return this.contactInfoFormGroup.get('office_phone');
  }
  get company_name() {
    return this.locationFormGroup.get('company_name');
  }
  get title() {
    return this.locationFormGroup.get('title');
  }
  get manager_name() {
    return this.locationFormGroup.get('manager_name');
  }

  get default_module_id() {
    return this.locationFormGroup.get('default_module_id');
  }

  get building() {
    return this.location?.get('building');
  }
  get department() {
    return this.location?.get('department');
  }

  get floor() {
    return this.location?.get('floor');
  }

  get location(): AbstractControl {
    return this.locationFormGroup.get('location');
  }

  get missing_building_name(): AbstractControl {
    return this.location?.get('missing_building_name');
  }

  get missing_floor_name(): AbstractControl {
    return this.location?.get('missing_floor_name');
  }

  get missing_suite_name(): AbstractControl {
    return this.location?.get('missing_suite_name');
  }

  get missing_department_name(): AbstractControl {
    return this.location?.get('missing_department_name');
  }

  get suite() {
    return this.location?.get('suite');
  }

  get userTypeIds() {
    return UserType;
  }

  get valid_submission() {
    return this.contactInfoFormGroup.valid && this.locationFormGroup.valid;
  }

  get isStaffEmail() {
    return this.email.value?.split('@').pop() === this.uhatEmail.domain;
  }

  async ngOnInit() {
    this.workspaces = await this.moduleService.getAllWorkspaces().toPromise();
    if (this.data.hasOwnProperty('email')) {
      this.contactInfoFormGroup.patchValue({ email: this.data.email });
    }
    await this._activateLocationFields();
    await this._location_component.populateLocation();
  }

  private async _activateLocationFields() {
    this.locationFormGroup.addControl('location', this._location_component.locationGroup);
  }

  private async _deActivateLocationFields() {
    this.locationFormGroup.removeControl('location');
  }

  setAccountType(typeId: number) {
    this.account_type.setValue(typeId);
    if (typeId === 3) {
      if (this.location) {
        this.location.reset();
        this._deActivateLocationFields();
      }
      this.company_name.setValidators([Validators.required]);
      this.manager_name.clearValidators();
    } else if (typeId === 1 || typeId === 2) {
      if (typeId === 1) {
        this.userTypeId = UserType.Staff;
      } else if (typeId === 2) {
        this.userTypeId = UserType.Tenant;
      }
      if (!this.location) {
        this._activateLocationFields();
      }
      this.company_name.clearValidators();
      this.manager_name.setValidators([Validators.required]);
    }
    this.company_name.updateValueAndValidity();
    this.manager_name.updateValueAndValidity();
  }

  async submit() {
    if (!this.valid_submission) {
      this.snackbar.open(`Please fill out all required fields`);
    }
    if (this.valid_submission && !this.submitting) {
      this.submitting = true;
      const accountToRequest = {
        type_id: this.account_type.value,
        first_name: this.first_name?.value?.trim(),
        last_name: this.last_name?.value?.trim(),
        email: this.email?.value?.trim(),
        cell_phone: this.cell_phone.value,
        office_phone: this.office_phone.value,
        company_name: this.company_name?.value?.trim(),
        title: this.title?.value?.trim(),
        manager_name: this.manager_name?.value?.trim(),
        building_id: this.building?.value?.id ?? null,
        floor_id: this.floor?.value?.id ?? null,
        suite_id: this.suite?.value?.id ?? null,
        department_id: this.department?.value?.id ?? null,
        missing_building_name: this.missing_building_name?.value?.trim() ?? null,
        missing_floor_name: this.missing_floor_name?.value?.trim() ?? null,
        missing_suite_name: this.missing_suite_name?.value?.trim() ?? null,
        missing_department_name: this.missing_department_name?.value?.trim() ?? null,
        default_module_id: this.account_type.value === UserType.Staff ? this.default_module_id.value : null,
      };

      const userResults = await this.userService.requestAccount(accountToRequest).toPromise();
      this.submitting = false;

      // unfortunately, since the user is created afterCreation, we can't get the user info here
      // so manually check their email and give information about their corresponding ldap
      const approvedDomains = [
        'oumedicine.com',
        'ouhsc.edu',
        'va.gov',
        'rmc-okc.org',
        'okdhs.org',
        'uhatok.com',
        'uhat.org',
        'ouhealth.com',
      ]; // keep this up-to-date with back end - search for TODO_APPROVED_DOMAINS
      if (userResults.email.includes(this.uhatEmail.domain)) {
        this.snackbar.open(
          `Request successful and account verified with ${this.uhatEmail.ldap_name}! You are now able to use your login credentials from ${this.uhatEmail.ldap_name} to login!`,
          null,
          { duration: 10000 }
        );
      } else if (userResults.email.includes(this.ouhscEmail.domain)) {
        this.snackbar.open(
          `Request successful and account verified with ${this.ouhscEmail.ldap_name}! You are now able to use your login credentials from ${this.ouhscEmail.ldap_name} to login!`,
          null,
          { duration: 10000 }
        );
      } else if (approvedDomains.indexOf(userResults.email.substring(userResults.email.indexOf('@') + 1)) > -1) {
        this.snackbar.open(
          'Request successful! Please check your email and follow the account confirmation link to set your password!',
          null,
          { duration: 5000 }
        );
      } else {
        this.snackbar.open('Your account has been requested!');
      }

      this.dialogRef.close(userResults);
    }
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public emailChanged(): void {
    if (this.email?.value !== this.email?.value?.trim()) {
      // reset email with trimmed space
      this.email?.setValue(this.email?.value?.trim());
    }
  }
}
