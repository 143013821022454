import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, round } from 'lodash';
import { FileService, ModalService, ProductService, ProgressIndicatorService } from 'src/app/services';
import { APIFilter, Product, ProjectProduct, UhatFileReference } from 'src/app/types';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { FileAttachmentDialogComponent, ProductValidator } from '..';
import { ResourceType } from '../../enums';

@Component({
  selector: 'app-project-product-dialog',
  templateUrl: './project-product-dialog.component.html',
  styleUrls: ['./project-product-dialog.component.scss'],
})
export class ProjectProductDialogComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private projectTenantService: ProjectTenantService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private modalService: ModalService,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    public dialogRef: MatDialogRef<ProjectProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public projectProduct: ProjectProduct
  ) {}

  productTemplateFormGroup: FormGroup = this.fb.group({
    // TODO: validate that a product is actually selected here
    product: ['', [Validators.required, ProductValidator()]],
  });

  projectProductFormGroup: FormGroup = this.fb.group({
    size: [''],
    color: [''],
    model_number: [''],
    description: [''],
    quantity: ['', [Validators.required]],
    unit_price: [{ value: '', disabled: true }, [Validators.required]],
    total_price: [{ value: '', disabled: true }, [Validators.required]],
  });

  get product() {
    return this.productTemplateFormGroup.get('product');
  }
  get size() {
    return this.projectProductFormGroup.get('size');
  }
  get color() {
    return this.projectProductFormGroup.get('color');
  }
  get model_number() {
    return this.projectProductFormGroup.get('model_number');
  }
  get description() {
    return this.projectProductFormGroup.get('description');
  }
  get quantity() {
    return this.projectProductFormGroup.get('quantity');
  }
  get unit_price() {
    return this.projectProductFormGroup.get('unit_price');
  }
  get total_price() {
    return this.projectProductFormGroup.get('total_price');
  }

  private productFields = ['id', 'name', 'unit_price', 'size', 'color', 'model_number', 'description'];
  private projectProductFields = [
    'id',
    `product{${this.productFields.join(',')}}`,
    'size',
    'color',
    'model_number',
    'description',
    'quantity',
    'unit_price',
    'total_price',
    'fixed_properties',
    'project{id,module_id}',
  ];
  private workspaceId: number;
  private allProducts: Product[];
  public filteredProducts: Product[];
  private fixedProperty = 'quantity';
  private unsavedChanges = false;

  private projectId;
  public productFiles: UhatFileReference[] = [];
  public originalProductFiles: UhatFileReference[] = [];

  async ngOnInit() {
    if (this.projectProduct?.id) {
      await this.setProjectProduct(this.projectProduct?.id);
    } else {
      const tenant = await this.projectTenantService
        .getTenantById(this.projectProduct.tenant_id, ['project_id', 'module_id'])
        .toPromise();
      this.projectId = tenant.project_id;
      this.projectProduct.project_id = tenant.project_id;
      this.workspaceId = tenant.module_id;
    }
    await this.getProducts();
  }

  async setProjectProduct(projectProductId: number) {
    const retrievedProduct = await this.productService
      .getProjectProductById(projectProductId, this.projectProductFields)
      .toPromise();
    const projectProduct = {
      // size: retrievedProduct.size,
      // color: retrievedProduct.color,
      // model_number: retrievedProduct.model_number,
      description: retrievedProduct.description,
      quantity: retrievedProduct.quantity,
      unit_price: this.roundDecimal(retrievedProduct.unit_price, 2),
      total_price: this.roundDecimal(retrievedProduct.total_price, 2),
    };
    this.fixedProperty = retrievedProduct.fixed_properties;
    const product = retrievedProduct.product;

    // TODO maybe handle if the below isn't valid? I think the product always has a project group though, and the group always has a project_id
    this.projectId = retrievedProduct.project_id;
    this.workspaceId = retrievedProduct.project?.module_id;
    if (this.projectProduct?.id) {
      this.productFiles = await this.fileService
        .getFilesByParentId(ResourceType.ProjectProduct, this.projectProduct?.id)
        .toPromise();
      this.originalProductFiles = cloneDeep(this.productFiles);
    }

    this.product.setValue(product);
    this.projectProductFormGroup.setValue(projectProduct);

    this.unsavedChanges = false;
  }

  async getProducts() {
    const productFilters: APIFilter[] = [
      { type: 'field', field: 'module_id', value: this.workspaceId.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'enabled', value: '1' },
    ];

    this.allProducts = await this.productService.getProducts(this.productFields, productFilters).toPromise();
    this.filteredProducts = this.allProducts;
  }

  productValueMapper(product) {
    return product ? product.name : null;
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectId,
          allowComment: false,
          preSelectedTags: [{ id: 87 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          this.productFiles = this.productFiles.concat(resultData);
        }
      });
  }

  removeFile(file) {
    this.productFiles.splice(this.productFiles.indexOf(file), 1);
  }

  openNewProductDialog() {
    this.modalService.openProductDialog().subscribe(async (result) => {
      if (result) {
        await this.getProducts();
        this.productChanged();
      }
    });
  }

  productChanged() {
    this.unsavedChanges = true;
    if (this.product?.value?.id) {
      this.size.setValue(this.product.value.size);
      this.color.setValue(this.product.value.color);
      this.model_number.setValue(this.product.value.model_number);
      this.description.setValue(this.product.value.description);
      this.unit_price.setValue(this.roundDecimal(this.product.value.unit_price, 2));
      if (this.unit_price.value) {
        if (this.total_price.value && !this.quantity.value) {
          this.calculateQuantity();
        } else if (this.quantity.value && !this.total_price.value) {
          this.calculateTotalPrice();
        } else if (this.quantity.value && this.total_price.value) {
          if (this.fixedProperty === 'total_price') {
            this.calculateQuantity();
          } else {
            // if fixedProperty is quantity or anything else, calculate total_price
            this.calculateTotalPrice();
          }
        }
      }
    } else if (this.product?.value) {
      this.filteredProducts = this.allProducts.filter(
        (p) => p.name && p.name.toLowerCase().includes(this.product.value.toLowerCase())
      );
    } else {
      this.filteredProducts = this.allProducts;
    }
  }

  /** Converts a number or string into an incomplete decimal format. This is generally used for USD inputs.
   *
   * @param value The value to convert to USD
   */
  formatDecimal(value, digits) {
    // Remove characters that aren't numbers or decimals
    const clean = value.toString().replace(/[^0-9.]+/g, '');
    // Remove all decimals after the first one
    const decPos = clean.indexOf('.');
    const dec = decPos >= 0 ? `${clean.substring(0, decPos)}.${clean.substring(decPos + 1).replace('.', '')}` : clean;
    // remove all characters after the hundredths place
    const res = decPos >= 0 && decPos < dec.length - digits - 1 ? dec.substring(0, decPos + digits + 1) : dec;
    return res;
  }

  roundDecimal(value, digits: number) {
    return round(value, digits).toFixed(digits);
  }

  quantityChanged() {
    this.unsavedChanges = true;
    this.fixedProperty = 'quantity';
    this.quantity.setValue(this.formatDecimal(this.quantity.value, 2));
    if (this.unit_price.value && this.quantity.value) {
      this.calculateTotalPrice();
    }
  }

  unitPriceChanged() {
    this.unsavedChanges = true;
    this.unit_price.setValue(this.formatDecimal(this.unit_price.value, 2));
    if (this.fixedProperty === 'quantity') {
      if (this.quantity.value) {
        this.calculateTotalPrice();
      }
    } else if (this.fixedProperty === 'total_price') {
      if (this.total_price.value) {
        this.calculateQuantity();
      }
    }
  }

  totalChanged() {
    this.unsavedChanges = true;
    this.fixedProperty = 'total_price';
    this.total_price.setValue(this.formatDecimal(this.total_price.value, 2));
    if (this.unit_price.value && this.total_price.value) {
      this.calculateQuantity();
    }
  }

  quantityBlur() {}

  unitPriceBlur() {
    this.unit_price.setValue(this.roundDecimal(this.unit_price.value, 2));
  }

  totalBlur() {
    this.total_price.setValue(this.roundDecimal(this.total_price.value, 2));
  }

  calculateQuantity() {
    this.quantity.setValue(this.roundDecimal(+this.total_price.value / +this.unit_price.value, 2));
  }

  calculateTotalPrice() {
    this.total_price.setValue(this.roundDecimal(+this.quantity.value * +this.unit_price.value, 2));
  }

  async discardChanges() {
    if (this.unsavedChanges) {
      await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Unsaved Changes',
          descriptionText: 'You have unsaved changes that will be discarded if you continue. Do you wish to continue?',
          confirmationButtonText: 'Continue',
        })
        .toPromise()
        .then(async (isConfirmed) => {
          if (isConfirmed) {
            this.close();
          }
        });
    } else {
      this.close();
    }
  }

  async saveProjectProduct() {
    this.projectProductFormGroup.markAllAsTouched();
    this.productTemplateFormGroup.markAllAsTouched();
    if (this.projectProductFormGroup.valid && this.productTemplateFormGroup.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Product Changes...');
      if (this.projectProduct?.id) {
        const projectProductToUpdate: ProjectProduct = this.projectProductFormGroup.value;
        projectProductToUpdate.product_id = this.product.value.id;
        projectProductToUpdate.fixed_properties = this.fixedProperty || 'quantity';
        const updatedProjectProduct = await this.productService
          .updateProjectProduct(this.projectProduct?.id, projectProductToUpdate)
          .toPromise();

        await this.updateProductFiles(this.projectProduct?.id);
        updatedProjectProduct.files = this.productFiles;

        if (updatedProjectProduct) {
          this.progressIndicatorService.close();
          this.snackbar.open('Product updated!');
          this.close(updatedProjectProduct);
        }
      } else {
        const newProjectProduct: ProjectProduct = this.projectProductFormGroup.value;
        newProjectProduct.product_id = this.product.value.id;
        newProjectProduct.tenant_id = this.projectProduct?.tenant_id;
        newProjectProduct.project_id = this.projectProduct?.project_id;
        newProjectProduct.fixed_properties = this.fixedProperty || 'quantity';
        const createdProjectProduct = await this.productService.createProjectProduct(newProjectProduct).toPromise();

        await this.updateProductFiles(createdProjectProduct.id);
        createdProjectProduct.files = this.productFiles;

        if (createdProjectProduct) {
          this.progressIndicatorService.close();
          this.snackbar.open('Product added!');
          this.close(createdProjectProduct);
        }
      }
    } else {
      this.snackbar.open(`Please fill out all required fields`);
    }
  }

  async updateProductFiles(productId: number) {
    const filesToAdd = [];
    const filesToRemove = [];

    this.productFiles.forEach((file) => {
      if (!this.originalProductFiles.map((f) => +f.file_id).includes(+file.file_id)) {
        filesToAdd.push(file);
      }
    });

    this.originalProductFiles.forEach((file) => {
      if (!this.productFiles.map((f) => +f.file_id).includes(+file.file_id)) {
        filesToRemove.push(file);
      }
    });

    for (const file of filesToAdd) {
      await this.fileService.linkFile(file.file_id, productId, ResourceType.ProjectProduct).toPromise();
    }

    for (const file of filesToRemove) {
      await this.fileService.unlinkFile(file.id).toPromise();
    }
  }

  close(data?) {
    this.dialogRef.close(data);
  }
}

/** Product must contain an id */
// export function ProductValidator(): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } | null => {
//     const invalid = !control.value?.id;
//     return invalid ? { invalidProduct: { value: control.value } } : null;
//   };
// }
