import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService, HandleErrorService } from 'src/app/services';
import { ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ProjectNotesService {
  private host: string = environment.serviceHost;

  private noteURL = `${this.host}/api/v1/notes`;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService
  ) {}

  public getNoteCountForTask(taskId: number): Observable<number> {
    return this.http.get(`${this.noteURL}?fields=parent_id&filter=parent_type_id=7,parent_id=${taskId}&limit=1`).pipe(
      map((result: ServiceResponse) => (result.count ? result.count : 0)),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
