import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-not-support',
  templateUrl: './browser-not-support.component.html',
  styleUrls: ['./browser-not-support.component.scss'],
})
export class BrowserNotSupportComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
