<!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->
<div id="addendum" data-test-id="addendum" class="addendum container-fluid p-4">
  <div class="row component-subheader align-items-center">
    <div class="col">
      <h4 class="dkblue font-weight-semibold m-0">
        Addendums &nbsp;
        <small class="ltblue font-weight-semibold" *ngIf="!loaders.gettingAddendums">{{
          addendums && addendums.length > 0 ? addendums.length : 0
        }}</small>
        <i class="la la-spinner la-spin ltblue" *ngIf="loaders.gettingAddendums"></i> &nbsp;
        <span *ngIf="loaders.updatingAddendum" class="ltblue"
          ><i class="la la-spinner la-spin ltblue"></i> Uploading...</span
        >
      </h4>
    </div>
    <div class="col-auto text-right">
      <div class="d-inline-block align-middle ml-4 text-center">
        <label class="main-label m-0">Bids Due</label>
        <p class="ltblue font-weight-semibold m-0">
          {{ bidDetails.bid_end_datetime | date: 'MMM dd yyyy • h:mmaa' }}
        </p>
      </div>
      <button mat-flat-button class="ml-5" color="success" (click)="addEditAddendum()" *ngIf="isWorkspaceStaff">
        + Addendum
      </button>
    </div>
  </div>
  <br />
  <div class="portlet-table-header pt-0 pl-0 pl-2 pr-2">
    <div class="row m-0 align-items-center">
      <div class="col-auto" style="width: 90px">
        <label class="main-label">#</label>
      </div>
      <div class="col">
        <label class="main-label">Addendum</label>
      </div>
      <div class="col-3 text-right">
        <label class="main-label">Posted to iSqFT</label>
      </div>
      <div class="col-auto" style="width: 110px"></div>
    </div>
  </div>

  <div class="m-portlet p-0 mb-4" m-portlet="true" *ngFor="let a of addendums">
    <div class="m-portlet__body p-2">
      <div class="row align-items-center m-0">
        <div class="col-auto" style="width: 90px">
          <h6 (click)="a.is_expanded = !a.is_expanded" class="dkblue font-weight-semibold m-0 pointer">
            {{ a.local_index }}
            <!-- &nbsp;
                <i *ngIf="!a.is_expanded" class="la la-angle-right"></i>
                <i *ngIf="a.is_expanded" class="la la-angle-down"></i> -->
          </h6>
        </div>
        <div class="col">
          <h6 (click)="a.is_expanded = !a.is_expanded" class="dkblue font-weight-semibold m-0 pointer">
            {{ a.title }}
          </h6>
        </div>
        <div class="col-3 text-right">
          <p class="gray m-0">
            {{ a.isqft_date | date: 'MMM dd yyyy' }}
          </p>
        </div>
        <div class="col-auto text-right">
          <button *ngIf="a.is_expanded" (click)="a.is_expanded = !a.is_expanded" mat-icon-button class="ltgray">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button *ngIf="!a.is_expanded" (click)="a.is_expanded = !a.is_expanded" mat-icon-button class="ltgray">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="addendum_main_menu" class="ltgray">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #addendum_main_menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="addEditAddendum(a)" *ngIf="isWorkspaceStaff">
              <i class="la la-edit"></i>
              &nbsp; Edit Addendum
            </button>
            <!-- <button mat-menu-item>
              <i class="la la-comments"></i>
              &nbsp; Start Conversation
            </button> -->
            <button mat-menu-item class="red" (click)="removeAddendum(a)" *ngIf="isWorkspaceStaff">
              <i class="la la-trash"></i>
              &nbsp; Remove Addendum
            </button>
          </mat-menu>
        </div>
      </div>
      <br *ngIf="a.is_expanded" />
      <div class="row m-0" *ngIf="a.is_expanded">
        <div class="col">
          <label class="gray main-label m-0">Description</label>
          <p class="dkblue" [innerHTML]="a.description"></p>
        </div>
      </div>
      <!-- <br *ngIf="a.is_expanded"> -->
      <div class="row m-0" *ngIf="a.is_expanded">
        <div class="col">
          <label class="gray main-label m-0">Notes</label>
          <p class="dkblue" [innerHTML]="a.notes"></p>
        </div>
      </div>
      <div class="row m-0 mb-4" *ngIf="a.is_expanded">
        <div class="col">
          <label class="gray main-label m-0 mb-2">Files</label>
          <br />
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip *ngFor="let f of a.files" [file]="f"></app-file-chip>
          </mat-chip-list>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
