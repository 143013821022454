import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService } from 'src/app/services';
import { APIFilter, BugReport, InquiryType, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class BugReportService {
  host: string = environment.serviceHost;
  bugReportUrl = `${this.host}/api/v1/bug-reports`;
  inquiryTypeUrl = `${this.host}/api/v1/inquiry-types`;
  bugReportFields =
    'subject,contact_email,wo_id,inquiry_type_id,should_contact,message,route,created_by_id,created_by_first_name,created_by_last_name,created_datetime';

  constructor(private http: HttpClient, private apiFilterService: ApiFilterService, private snackbar: MatSnackBar) {}

  // Gets the errors from the database if we ever want to email/display them
  // will need to increase the maximum limit or add a cursor
  // getErrors(): Observable<BugReport> {
  //   return this.http.get(`${this.bugReportUrl}?fields=${this.bugReportFields}`).pipe(
  //     map((result: ServiceResponse) => {
  //       return result.data.bug_report;
  //     })
  //   );
  // }

  getInquiryTypes(fields: string[], apiFilters?: APIFilter[]): Observable<InquiryType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.inquiryTypeUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.inquiry_types;
          return results;
        })
      );
  }

  // logs an error to the database
  logError(bugReport: BugReport, isAuthenticated: boolean): Observable<BugReport> {
    return this.http
      .post(
        `${this.bugReportUrl}${isAuthenticated ? '' : '/unauthenticated'}?fields=${this.bugReportFields}`,
        bugReport
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.bug_report;
        }),
        catchError((e) => {
          const bugReportErrorSnack = this.snackbar.open(
            'An error occurred. Please email 1call@uhat.org for support.',
            'Close',
            {
              duration: undefined,
            }
          );
          bugReportErrorSnack.onAction().subscribe(async () => {
            this.snackbar.dismiss();
          });
          throw new Error('Error creating bug report');
        })
      );
  }

  sendBugReportNotifications(bugReportId): Observable<void> {
    return this.http.post(`${this.bugReportUrl}/${bugReportId}/notify`, null).pipe(
      map((result: ServiceResponse) => {
        return;
      }),
      catchError((e) => {
        const bugReportErrorSnack = this.snackbar.open(
          'An error occurred. Please email 1call@uhat.org for support.',
          'Close',
          {
            duration: undefined,
          }
        );
        bugReportErrorSnack.onAction().subscribe(async () => {
          this.snackbar.dismiss();
        });
        throw new Error('Error sending bug report notifications');
      })
    );
  }
}
