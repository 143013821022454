import { ApplicationRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF, pdf } from '@progress/kendo-drawing';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import { BidContractStatus, BidContractType, ResourceType, UserType } from 'src/app/enums';
import { AuthService, DisplayReviewersService, FileService, ProjectService } from 'src/app/services';
import { BidContract, InsuranceRequirements } from 'src/app/types';
import { FieldChecker } from 'src/app/utils';

@Component({
  selector: 'app-bid-contract-export',
  templateUrl: './bid-contract-export.component.html',
  styleUrls: ['./bid-contract-export.component.scss'],
})
export class BidContractExportComponent implements OnChanges {
  constructor(
    private appRef: ApplicationRef,
    private authService: AuthService,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private projectService: ProjectService
  ) {}

  private projectFields: string[] = [
    `code`,
    `title`,
    `scope_of_work`,
    `building`,
    `floor`,
    `suite`,
    `department`,
    `project_manager{first_name,last_name,email,company{name}}`,
    `cfmo{first_name,last_name,title}`,
  ];
  private bidFields: string[] = [
    `bid_package_id`,
    `bid_package{number}`,
    `project{${this.projectFields.join(',')}}`,
    `trade{name}`,
    `contact{first_name,last_name,email,title}`,
    `company{name}`,
    `amount`,
    `description`,
  ];
  private bidContractFields: string[] = [
    `type_id`,
    `status_id`,
    `bid{${this.bidFields.join(',')}}`,
    `contract_date`,
    `proof_of_insurance`,
    `contract_sum_confirmed`,
    `summary_completed`,
    `allowances_completed`,
    `unit_prices_completed`,
    `insurance_policy_completed`,
    `summary`,
    `allowances{name,amount}`,
    `unit_prices{name,units,price_per_unit}`,
    `insurance_policy_id`,
    `insurance_policy{id,name}`,
    `insurance_requirement_ids`,
    `vendor_signature_text`,
    `vendor_signed_datetime`,
    `trust_signature_text`,
    `executed_datetime`,
  ];

  @Input() contract: BidContract = {};
  @Input() insuranceRequirements: InsuranceRequirements[] = [];
  private shownContractId: number;
  private shownContractBidId: number;
  public contractSum: number;
  public insuranceRequirementIds = [];

  get typeId() {
    return this.contract?.type_id;
  }
  get statusId() {
    return this.contract?.status_id;
  }
  get contractDate() {
    return this.contract?.contract_date;
  }
  get projectManager() {
    return this.contract?.bid?.project?.project_manager;
  }
  get cfmo() {
    return this.contract?.bid?.project?.cfmo;
  }
  get bidCompany() {
    return this.contract?.bid?.company;
  }
  get bidContact() {
    return this.contract?.bid?.contact;
  }
  get project() {
    return this.contract?.bid?.project;
  }

  public get trustSignee() {
    return this.contractSum < this.displayReviewersService.noCeoMax ? this.cfmo : this.authService.ceo;
  }

  @ViewChild('pdf', { static: true }) pdf;

  async ngOnChanges(): Promise<void> {
    pdf.defineFont({
      'Dawning of a New Day': 'assets/fonts/DawningofaNewDay-Regular.ttf',
    });

    if (this.contract?.id !== this.shownContractId || this.contract?.bid?.id !== this.shownContractBidId) {
      const hasAllFields = FieldChecker.hasAllFields(this.contract, this.bidContractFields);
      if (!hasAllFields?.result) {
        console.warn(
          `Bid Contract Export Component is missing the required bid contract fields: ${(
            hasAllFields.missingFields || []
          ).join(', ')}! Getting them from the API.`
        );
        await this.refreshContract();
      }
    }

    await this.updateContractInfo();
  }

  async updateContractInfo() {
    this.shownContractId = this.contract?.id;
    this.shownContractBidId = this.contract?.bid?.id;
    this.contractSum = this.contract?.bid?.amount + sumBy(this.contract?.altBids, 'amount');

    if (this.contract?.type_id === BidContractType.Small) {
      if (this.contract?.insurance_requirement_ids) {
        this.insuranceRequirementIds = this.contract.insurance_requirement_ids =
          (typeof this.contract?.insurance_requirement_ids === 'string' &&
            JSON.parse(this.contract.insurance_requirement_ids)) ||
          this.contract?.insurance_requirement_ids ||
          [];
      }
      if (!this.insuranceRequirements?.length) {
        this.insuranceRequirements = await this.projectService
          .getInsuranceRequirements([{ type: 'field', field: 'is_enabled', value: '1' }], ['id', 'name', 'value'])
          .toPromise();
      }
    }
  }

  async refreshContract() {
    if (this.contract?.id) {
      this.contract = await this.projectService
        .getBidContractById(this.contract.id, this.bidContractFields)
        .toPromise();
    } else if (this.contract?.bid_id) {
      this.contract = {
        allowances: [],
        unit_prices: [],
        // insurance_policy_id: 1 // TODO: default this based on matrix
      };
      this.contract.bid = await this.projectService.getBidById(this.contract.bid_id, this.bidFields).toPromise();
    }

    if (
      [BidContractStatus.NotSent, BidContractStatus.SentToVendor, BidContractStatus.RevisionRequested].indexOf(
        this.statusId
      ) > -1
    ) {
      // This is set to the current date until the contract is signed by the vendor
      this.contract.contract_date = moment().format('YYYY-MM-DD');
    }

    if (!this.contract?.bid || !this.contract?.bid?.project?.id) {
      let errorMessage = 'An unknown error occurred';
      if (!this.contract?.bid) {
        errorMessage = `Bid for this contract could not be found`;
      } else if (!this.contract?.bid?.project?.id) {
        errorMessage = `Project for this contract could not be found`;
      }
      return;
    }
    // get alt bids
    const selectedAltBidFilters = [
      { type: 'field', field: 'bid_package_id', value: this.contract?.bid.bid_package_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'company_id', value: this.contract?.bid.company_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'type_id', value: 2 },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_selected', value: 1 },
    ];
    const selectedAltBids = await this.projectService.getBids(selectedAltBidFilters, this.bidFields).toPromise();
    this.contract.altBids = selectedAltBids ?? [];
  }

  public async createPdfFile(project, bid, filesToCombine = [], addToProject = true, contract) {
    if (contract) {
      this.contract = contract;
    }
    await this.refreshContract();
    await this.updateContractInfo();

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    const status =
      this.contract.status_id === BidContractStatus.SignedByVendor &&
      this.authService.currentUser.user_type_id === UserType.Vendor
        ? 'Signed By Supplier'
        : this.contract.status_id === BidContractStatus.Executed
        ? 'Executed'
        : '';

    let contractFile;
    if (this.contract.status_id !== BidContractStatus.SignedByVendor) {
      contractFile = await this.pdf.export().then(async (groupData) => {
        let createdPDF: File;
        // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
        await new Promise<File>((resolve) => {
          pdf.toBlob(groupData, (res) => {
            createdPDF = new File(
              [res],
              `Project_${project.code}_Trade_${bid?.trade?.name || 'No_Trade'}${status ? '_' : ''}${status}_v${
                bid.contract_revision || 2
              }.pdf`
            );
            resolve(createdPDF);
          });
        });

        return createdPDF || null;
      });
    } else {
      const files = [];
      const contractGroup = await this.pdf?.export();
      const contractBase64 = (await exportPDF(contractGroup)).replace('data:application/pdf;base64,', '');

      const contractByteCharacters = atob(contractBase64);
      const contractData = new Array(contractByteCharacters.length);

      for (let i = 0; i < contractByteCharacters.length; i++) {
        contractData[i] = contractByteCharacters.charCodeAt(i);
      }
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(contractData)]),
        name: `Project_${project.code}_Trade_${bid?.trade?.name || 'No_Trade'}${status ? '_' : ''}${status}_v${
          bid.contract_revision || 2
        }.pdf`,
      });

      for (const f of filesToCombine) {
        if (f?.id) {
          const fileToAdd = await this.fileService.downloadFile({ id: f.id }).toPromise();
          files.push({
            file: new Blob([new Uint8Array(fileToAdd.file.data)]),
            name: fileToAdd.name,
          });
        }
      }

      let combinedFile;
      try {
        if (files.length > 1) {
          combinedFile = await this.fileService.combinePDFs(files).toPromise();
        }
      } catch (e) {
        const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
        errorSnack.onAction().subscribe(async () => {
          this.snackbar.dismiss();
        });
        return;
      }

      const blob = combinedFile
        ? new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' })
        : files[0].file;
      contractFile = new File(
        [blob],
        `Project_${project.code}_Trade_${bid?.trade?.name || 'No_Trade'}${status ? '_' : ''}${status}_v${
          bid.contract_revision || 2
        }.pdf`
      );
    }

    if (addToProject && contractFile) {
      contractFile = await this.fileService
        .createFile(contractFile, project.id, ResourceType.Project, contractFile.name)
        .toPromise();
    }

    return contractFile || null;
  }

  async contractExport() {
    this.appRef.tick();
    return await this.pdf.saveAs(
      `${this.contract?.bid?.trade?.name ?? 'unknown_trade'}_bid_contract_${`PRJ${this.project?.code}`}`
    );
  }

  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }
}
