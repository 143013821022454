import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthService, ModalService, UserService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-user-profile-preview',
  templateUrl: './user-profile-preview.component.html',
  styleUrls: ['./user-profile-preview.component.scss'],
})
export class UserProfilePreviewComponent implements OnInit {
  public currentUser: User;
  public guestInviteMessage: string;
  public loading = false;
  public user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UserProfilePreviewComponent>,
    private userService: UserService,
    private modalService: ModalService,
    public authService: AuthService
  ) {}

  async ngOnInit() {
    const userId = this.data.userId;
    this.currentUser = this.authService.getLoggedInUser();
    this.loading = true;
    const userFields = [
      'email',
      'first_name',
      'last_name',
      'company_id',
      'company_name',
      'manager_id',
      'manager_first_name',
      'manager_last_name',
      'user_type_id',
      'user_type_name',
      'title',
      'is_ar',
      'is_enabled',
      'is_login_enabled',
    ];
    const user = await this.userService.getUserById(userId, userFields).toPromise();
    this.user = user;
    this.loading = false;
    this.getProfilePictureUrl(user.id);
    // only check if a user is enabled and pending
    if (user?.is_enabled && !user?.is_login_enabled) {
      const guestInvite = await this.userService.getLastInvites([+userId]).toPromise();
      // now we can check if the user is invited
      if (guestInvite[userId]) {
        this.guestInviteMessage = `${guestInvite[user.id].invited_user_first_name} ${
          guestInvite[user.id].invited_user_last_name
        } was last invited on ${moment(guestInvite[user.id].created_datetime).format('dddd, h:mma (MMM Do)')} by ${
          guestInvite[user.id].sending_user_first_name
        } ${guestInvite[user.id].sending_user_last_name}`;
      } else {
        this.guestInviteMessage = `${user.first_name} ${user.last_name} has not yet been invited.`;
      }
    }
  }

  // private loadImage() {
  //   this.thumbnailImage$ = this.userService.getCachedProfileThumbnailAsync(this.user.id);
  //   this.thumbnailImage$.subscribe(base64Value => {
  //     this.isLoaded = base64Value && base64Value.toString().length > 0;
  //   });
  // }

  // messageUser() {
  //   this.messagingService.events.openCreateConversationPanelEvent.emit({
  //     subject: '',
  //     projectId: this.projectService.currentSelectedProjectId,
  //     followers: [this.user],
  //     toFollowers: [this.user],
  //   });
  // }

  getProfilePictureUrl(userId: number) {
    return this.userService.getProfilePictureUrl(userId);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  public impersonateUser(user: User): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Confirm Impersonation',
        descriptionText: `Are you sure you would like to begin impersonating ${user.first_name} ${user.last_name}?`,
      })
      .subscribe((isConfirmed: boolean): void => {
        if (isConfirmed) {
          void this.authService.impersonateUser(user.id).toPromise();
        }
      });
  }

  inviteUser(user: User) {
    this.modalService.openInviteUserModal({ users: [user] }).subscribe();
  }
}
