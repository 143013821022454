<div class="full-width conversation p-2 dkblue pointer ease" [ngClass]="{ 'active-conversation': isSelected() }">
  <div class="row m-0 full-width align-items-center">
    <div class="col-auto text-center pl-0 conversation-notifications" style="width: 15px">
      <div *ngIf="!createdByMe() && !isReadByMe()">
        <i
          (click)="markRead()"
          *ngIf="isMessageToMe()"
          class="fa fa-dot-circle green pointer"
          matTooltip="Mark Read"
          matTooltipPosition="after"
          [matTooltipShowDelay]="300"
        ></i>
        <i
          (click)="markRead()"
          *ngIf="!isMessageToMe()"
          class="fa fa-circle green pointer"
          matTooltip="Mark Read"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        ></i>
      </div>
      <div *ngIf="!createdByMe() && isReadByMe()">
        <i
          (click)="markUnread()"
          *ngIf="isMessageToMe()"
          class="fa fa-dot-circle ltgray opacity4 pointer"
          matTooltip="Mark Unread"
          matTooltipPosition="after"
          [matTooltipShowDelay]="300"
        ></i>
        <i
          (click)="markUnread()"
          *ngIf="!isMessageToMe()"
          class="fa fa-circle ltgray opacity4 pointer"
          matTooltip="Mark Unread"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        ></i>
      </div>
    </div>
    <div (click)="selectMe()" class="col p-0 pl-2">
      <p *ngIf="message" class="m-0 dkblue font-weight-semibold">
        {{ message.created_by_first_name }} {{ message.created_by_last_name }}
      </p>
    </div>
    <div (click)="selectMe()" class="col-auto pr-1 pl-2 text-right">
      <p class="m-0 gray d-inline-block">
        <small>
          {{ getDateText() }}
        </small>
      </p>
    </div>
  </div>
  <div (click)="selectMe()" class="row m-0 full-width">
    <div class="col-auto text-center pl-0 conversatoin-notifcations" style="width: 15px">
      <i *ngIf="message.files && message.files.length > 0" class="la la-paperclip gray"></i>
    </div>
    <div class="col pr-0 pl-2">
      <p *ngIf="myProjectName" class="dkblue m-0 preview font-weight-normal" innerHTML="{{ myProjectName }}"></p>
      <p
        *ngIf="message.conversation_subject"
        class="dkgray m-0 preview font-weight-normal"
        innerHTML="{{ message.conversation_subject | highlight: messageSearchTerm }}"
      ></p>
      <p *ngIf="!message.conversation_subject" class="dkgray font-weight-normal m-0 preview">(No Subject)</p>
      <p class="gray m-0 preview" innerHTML="{{ message.content | highlight: messageSearchTerm }}"></p>
    </div>
  </div>
</div>
