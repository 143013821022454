<div id="request-category" data-test-id="request-category" class="request-category">
  <h6 *ngIf="showTitle" class="m-0 dkblue font-weight-semibold mb-2">Category</h6>
  <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
    <input
      type="text"
      placeholder="Search Categories"
      aria-label="Category"
      matInput
      [formControl]="topic_categories"
      [matAutocomplete]="category_auto"
    />
    <mat-icon *ngIf="topic_categories?.value" matSuffix class="gray pointer" (click)="clearInput($event)">
      cancel
    </mat-icon>
    <mat-autocomplete #category_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="categoryMapper">
      <mat-option *ngIf="(filteredCategories | async)?.length === 0" disabled>
        <span class="d-flex flex-column">
          <p class="mb-0">No Category Available</p>
        </span>
      </mat-option>
      <mat-option
        *ngFor="let category of filteredCategories | async"
        [value]="category"
        [disabled]="!category.isVisibleToDispatch"
      >
        <span class="d-flex flex-wrap align-items-center">
          <span class="mr-auto">{{ category?.name }}</span>
          <span
            class="badge badge-secondary br-5 font-weight-semibold text-uppercase text-truncate"
            *ngIf="category.isDuplicate"
          >
            {{ category?.topic_group?.name }}
          </span>
          <span
            class="badge badge-light gray br-5 font-weight-semibold text-uppercase text-truncate"
            *ngIf="!category.isVisibleToDispatch"
          >
            Unavailable
          </span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
