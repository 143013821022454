<app-base-dialog [title]="'Contact Support'" (closeDialog)="cancel()" [draggable]="false"> </app-base-dialog>
<div
  id="bug-report-dialog"
  data-test-id="bug-report-dialog"
  class="bug-report-dialog d-flex flex-column"
  mat-dialog-content
>
  <div class="br-10 p-3 bg-shade-red mb-4 d-flex align-items-top">
    <mat-icon class="red mr-3"> error </mat-icon>
    <p class="m-0 dkblue flex-grow-1">
      This form will notify the UHAT Development Team of any 1CALL Cloud issues or new feature requests. Any facility
      requests or work orders submitted through this form will be dismissed.
      <br /><br />
      To submit a project request or work order, use the
      <a href="/new-request" class="ltblue font-weight-semibold hover-u">New Request Form</a>.
    </p>
  </div>
  <div class="row mb-3">
    <div class="col">
      <h6
        class="dkblue mb-2 font-weight-semibold"
        id="contact-support-email-label"
        data-test-id="contact-support-email-label"
      >
        Your Email
      </h6>
      <mat-form-field appearance="outline" class="full-width">
        <input
          id="contact-support-email-input"
          data-test-id="contact-support-email-input"
          matInput
          [(ngModel)]="email"
          placeholder="Email Address"
          [disabled]="currentUser && currentUser.email"
          maxlength="255"
          [required]="true"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <h6
        class="dkblue font-weight-semibold mb-2"
        id="contact-support-inquiry-type-label"
        data-test-id="contact-support-inquiry-type-label"
      >
        Support Topic
      </h6>
      <mat-form-field appearance="outline" class="full-width">
        <mat-select
          id="contact-support-input-type-select"
          data-test-id="contact-support-input-type-select"
          placeholder="Choose a Support Topic"
          matNativeControl
          [(ngModel)]="inquiryTypeId"
          [required]="true"
        >
          <mat-option
            data-test-id="contact-support-input-type-select-options"
            *ngFor="let t of inquiryTypes"
            [value]="t.id"
            >{{ t.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col text-area">
      <h6
        class="dkblue font-weight-semibold mb-2"
        id="contact-support-summary-label"
        data-test-id="contact-support-summary-label"
      >
        Summary
      </h6>
      <mat-form-field appearance="outline" class="full-width">
        <textarea
          id="contact-support-summary-textarea"
          data-test-id="contact-support-summary-textarea"
          matInput
          [(ngModel)]="message"
          rows="4"
          placeholder="Please enter a brief description of your issue"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-3" *ngIf="currentUser && currentUser.id">
    <div class="col">
      <mat-chip-list class="d-inline-block align-middle">
        <button mat-button color="accent" class="pl-3 pr-3 mr-2" (click)="openUploadModal()">
          <mat-icon>attach_file</mat-icon>
          <span *ngIf="files.length === 0" class="ml-2"> Attach any supporting files here </span>
        </button>
        <app-file-chip
          *ngFor="let file of files"
          [file]="file"
          [removable]="true"
          (removeEvent)="removeFile(file)"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row pt-2" [hidden]="!currentUser?.id || isVendor">
    <div class="col">
      <app-add-followers
        #followers
        displayTitle="Add Followes"
        saveOnClose="false"
        [allowedUserTypeIds]="allowedUserTypeIds"
        [defaultUserTypeId]="defaultUserTypeId"
      ></app-add-followers>
    </div>
  </div>
  <div class="row pt-4">
    <div class="col">
      <button
        id="contact-support-cancel-button"
        data-test-id="contact-support-cancel-button"
        mat-button
        type="button"
        class="full-width gray"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div class="col">
      <button
        id="contact-support-submit-button"
        data-test-id="contact-support-submit-button"
        mat-flat-button
        type="submit"
        color="success"
        class="full-width"
        [disabled]="!isBugReportValid()"
        (click)="submitting ? null : submit()"
      >
        <span *ngIf="!submitting">Submit</span>
        <span *ngIf="submitting"><i class="la la-spinner la-spin"></i></span>
      </button>
    </div>
  </div>
</div>
