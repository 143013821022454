<div id="time-log" data-test-id="time-log" #mainScreen class="time-log subcomponent container-fluid p-4">
  <div class="row">
    <main class="col">
      <div class="row align-items-center mb-4 mt-1">
        <div class="col-auto">
          <h4 class="m-0 dkblue font-weight-semibold">Time Log</h4>
        </div>
        <div class="col text-right">
          <button
            mat-button
            color="accent"
            class="lh-28 ml-3"
            (click)="setDateRange('all')"
            *ngIf="filter.selection !== 'all'"
          >
            All
          </button>
          <button mat-flat-button color="accent" class="lh-28 ml-3" *ngIf="filter.selection === 'all'">All</button>
          <button
            mat-button
            color="accent"
            class="lh-28 ml-3"
            (click)="setDateRange('week')"
            *ngIf="filter.selection !== 'week'"
          >
            This Week
          </button>
          <button mat-flat-button color="accent" class="lh-28 ml-3" *ngIf="filter.selection === 'week'">
            This Week
          </button>
          <button
            mat-button
            color="accent"
            class="lh-28 ml-3"
            (click)="setDateRange('month')"
            *ngIf="filter.selection !== 'month'"
          >
            This Month
          </button>
          <button mat-flat-button color="accent" class="lh-28 ml-3" *ngIf="filter.selection === 'month'">
            This Month
          </button>
          <!--        <button mat-button color="accent" class="lh-28 ml-3">-->
          <!--          Date Range-->
          <!--        </button>-->
        </div>
      </div>
      <div class="m-portlet">
        <div class="m-portlet__body item-no-hover alt-bg-gray pl-3 pr-3 pt-2 pb-2">
          <div class="row align-items-center">
            <div class="col-3">
              <h6 class="dkblue font-weight-semibold m-0">Person</h6>
            </div>
            <div class="col">
              <h6 class="dkblue font-weight-semibold m-0">Date</h6>
            </div>
            <div class="col">
              <h6 class="dkblue font-weight-semibold m-0">Company</h6>
            </div>
            <div class="col">
              <h6 class="dkblue font-weight-semibold m-0">Activity</h6>
            </div>
            <div class="col">
              <h6 class="dkblue font-weight-semibold m-0">Duration</h6>
            </div>
          </div>
        </div>
        <div
          class="m-portlet__body item p-3"
          *ngFor="let entry of timeLogEntries; let last = last"
          [ngClass]="{ 'item-last': last }"
        >
          <div class="row align-items-center">
            <div class="col-3 d-flex align-items-center">
              <app-profile-thumbnail
                [width]="30"
                [height]="30"
                class="mr-3"
                [userId]="entry.worker.id"
                matTooltip="First Last"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
              ></app-profile-thumbnail>
              <span class="d-flex flex-column">
                <p class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle">
                  {{ entry.worker.first_name }} {{ entry.worker.last_name }}
                </p>
                <h6 class="dkgray m-0 font-weight-normal">
                  {{ entry.worker.department_name }}
                </h6>
              </span>
            </div>
            <div class="col">
              <p class="dkblue font-weight-normal m-0">
                {{ entry.date_worked | date: 'EEE, MMM d y' }}
              </p>
            </div>
            <div class="col">
              <p class="dkblue font-weight-normal m-0">
                {{ entry.company?.name }}
              </p>
            </div>
            <div class="col d-flex align-items-center">
              <p class="dkblue font-weight-normal m-0">
                {{ entry.activity.name }}
              </p>
              <a
                href="#"
                target="_blank"
                class="text-decoration-none hover-u pointer"
                [routerLink]="['/work-orders/' + entry?.parent_id]"
              >
                <h6
                  *ngIf="entry.parent_type_id === ResourceType.WorkOrder"
                  class="badge white text-uppercase px-2 py-1 bg-orange mb-0 ml-2 pointer"
                >
                  {{ entry.parent_code }}
                </h6>
              </a>
            </div>
            <div class="col d-flex align-items-center">
              <p class="dkblue font-weight-semibold m-0 mr-auto d-inline-block align-middle">
                {{ entry.hours_worked }}h {{ entry.minutes_worked }}m
              </p>
              <button mat-icon-button [matMenuTriggerFor]="entry_menu" color="accent" class="ml-auto">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #entry_menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="editEntry(entry)">
                  <mat-icon>edit</mat-icon>
                  <span> Edit Time Entry </span>
                </button>
                <button mat-menu-item (click)="deleteEntry(entry.id)">
                  <mat-icon>delete</mat-icon>
                  <span> Delete Time Entry </span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </main>
    <aside class="col-auto">
      <button (click)="createEntry()" mat-flat-button color="success" class="full-width mb-3">New Time Entry</button>
      <hr />
      <section id="staffEntries" class="pt-3 pb-4">
        <!--      TODO add this in at some point. highlighting the user profile was being annoying-->
        <!--      <div class="row">-->
        <!--        <span>Filter:</span>-->
        <!--        <button mat-button class="text-right" *ngIf="selectedUserIds.length !== 0">Clear</button>-->
        <!--      </div>-->
        <!--      <div class="row pt-3 pb-4 pl-3">-->
        <!--        <app-profile-thumbnail-->
        <!--          *ngFor="let user of distinctUsers"-->
        <!--          [width]="32"-->
        <!--          [height]="32"-->
        <!--          [userId]="user.id"-->
        <!--          matTooltip="First Last"-->
        <!--          matTooltipPosition="above"-->
        <!--          [matTooltipShowDelay]="300"-->
        <!--          (click)="selectUser(user)"-->
        <!--          [ngClass]="{'border-dark': selectedUserIds.includes(+user.id)}"-->
        <!--        ></app-profile-thumbnail>-->
        <!--      </div>-->
        <div class="d-flex mb-1">
          <h5 class="dkblue font-weight-bold mr-auto">Staff Totals</h5>
          <h5 class="dkblue font-weight-bold">
            {{ staffTotal }}
          </h5>
        </div>
        <div class="m-portlet m-0">
          <div
            class="m-portlet__body item p-3"
            *ngFor="let entry of distinctTotals; let last = last"
            [ngClass]="{ 'item-last': last }"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <app-profile-thumbnail
                  [width]="32"
                  [height]="32"
                  [userId]="entry.worker.id"
                  matTooltip="{{ entry.worker.first_name }} {{ entry.worker.last_name }}"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                ></app-profile-thumbnail>
              </div>
              <div class="col pl-0" style="margin-top: -2px">
                <p class="m-0 dkblue font-weight-semibold d-inline-block align-middle">
                  {{ entry.worker.first_name }} {{ entry.worker.last_name }}
                </p>
                <h6 class="dkgray m-0 font-weight-normal">
                  {{ entry.worker.department_name }}
                </h6>
              </div>
              <div class="col-auto text-right">
                <h5 class="m-0 dkblue font-weight-semibold d-inline-block align-middle">
                  {{ formatTime(entry.hours_worked, entry.minutes_worked) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </aside>
  </div>
</div>
