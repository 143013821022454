<app-base-dialog [title]="'Add a Guest'" (closeDialog)="cancel()"></app-base-dialog>
<div class="new-guest-account">
  <div mat-dialog-content>
    <div class="d-flex p-3 br-10 bg-shade-ltblue mb-4">
      <p class="dkblue m-0">
        A <span class="font-weight-semibold">Guest</span> is not an active account with login credentials. This should
        only be used when adding a Meeting Attendee who does not have an active 1CALL Cloud account. This allows
        <span class="font-weight-semibold">Guests</span> to receive Meeting Notes via email, even without an active
        account.
      </p>
    </div>
    <form [formGroup]="userInfoFormGroup">
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue mb-2 font-weight-semibold">Account Type*</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select formControlName="userTypeId" placeholder="Choose Account Type">
              <mat-option *ngFor="let userTypeOption of userTypesOptions" [value]="userTypeOption.id">{{
                userTypeOption.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="userTypeId.errors?.required">Account type is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue mb-2 font-weight-semibold">First Name*</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput placeholder="First Name" type="text" formControlName="firstName" />
            <mat-error *ngIf="firstName.errors?.required">First name is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="dkblue mb-2 font-weight-semibold">Last Name*</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput placeholder="Last Name" type="text" formControlName="lastName" />
            <mat-error *ngIf="lastName.errors?.required">Last name is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold mb-2">Email Address*</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput type="email" placeholder="email@example.com" formControlName="email" />
            <mat-error *ngIf="email.errors?.required">Email address is required</mat-error>
            <mat-error *ngIf="(!email.errors?.required && email.errors?.email) || email.errors?.pattern">
              Email address is invalid
            </mat-error>
            <mat-error *ngIf="email.errors?.unavailable">Email address is already in use.</mat-error>
            <mat-icon *ngIf="email.valid" matSuffix>done</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="[UserType.Staff, UserType.Tenant].includes(userTypeId.value)" class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold">Department*</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input
              matInput
              type="text"
              placeholder="Search Departments"
              formControlName="department"
              [matAutocomplete]="departmentAuto"
            />
            <mat-autocomplete #departmentAuto="matAutocomplete" [displayWith]="departmentValueMapper">
              <mat-option *ngFor="let department of filteredDepartments" [value]="department">
                {{ department.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="department.errors?.required">Department is required.</mat-error>
            <mat-error *ngIf="!department.errors?.required && department.errors?.valueIsString">
              Choose a department from the search results or add a new department.
            </mat-error>
            <mat-icon *ngIf="department.valid" matSuffix>done</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="userTypeId.value === UserType.Vendor">
        <div class="row mb-4">
          <div class="col">
            <h6 class="dkblue font-weight-semibold">Company*</h6>
            <mat-form-field appearance="outline" class="full-width">
              <input
                matInput
                type="text"
                placeholder="Search Companies"
                formControlName="company"
                [matAutocomplete]="companyAuto"
              />
              <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="departmentValueMapper">
                <mat-option *ngFor="let company of filteredCompanies" [value]="company">
                  {{ company.name }}
                </mat-option>
                <mat-option
                  class="accent"
                  *ngIf="!loaders.companies && !company.value?.id && company.value?.length"
                  (click)="addCompany(company.value)"
                >
                  + Create {{ company.value }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="company.errors?.required">Company is required.</mat-error>
              <mat-error *ngIf="!company.errors?.required && company.errors?.valueIsString">
                Choose a company from the search results or add a new company.
              </mat-error>
              <mat-icon *ngIf="company.valid" matSuffix>done</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <!-- <div
          *ngIf="!loaders.companies && !filteredCompanies.length && !company.value?.id && company.value?.length"
          class="row mb-5"
        >
          <div class="col">
            <button mat-raised-button color="accent" style="width: 100%" (click)="addCompany(company.value)">
              Add Company {{ company.value }}
            </button>
          </div>
        </div> -->
      </ng-container>
    </form>
  </div>
  <div mat-dialog-actions class="row pb-4 pt-4">
    <div class="col">
      <button mat-button (click)="cancel()" class="gray full-width" type="button">Cancel</button>
    </div>
    <div class="col">
      <button
        mat-flat-button
        (click)="createGuestUser()"
        class="full-width"
        color="accent"
        [disabled]="this.userInfoFormGroup.invalid || loaders.creatingUser"
      >
        Add Guest
      </button>
    </div>
  </div>
</div>
