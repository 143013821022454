import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { ProgressIndicatorService, WorkOrderService } from '../../services';
import { WorkOrderChecklistItem } from '../../types';

@Component({
  selector: 'app-work-order-checklist',
  templateUrl: './work-order-checklist.component.html',
  styleUrls: ['./work-order-checklist.component.scss'],
})
export class WorkOrderChecklistComponent implements OnInit {
  @Input() woId: number;
  @Input() woStatus: number;
  @Input() canReactivate: boolean;
  @Output() checklistUpdated = new EventEmitter();

  private workOrderChecklistItems: WorkOrderChecklistItem[] = [];
  private isEditing = false;
  private editingItem: WorkOrderChecklistItem;
  public showCreate = false;

  public showChecklistView = true;
  checklistFormGroup: FormGroup = this.fb.group({
    title: ['', [Validators.required]],
  });

  get checklistItems() {
    return this.workOrderChecklistItems;
  }

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkOrderService,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private _route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this._route.params.subscribe(async (params: Params) => {
      this.woId = params?.id;
      this.workOrderChecklistItems = await this.workOrderService.getWorkOrderChecklistItems(this.woId).toPromise();
    });
    this.workOrderChecklistItems = await this.workOrderService.getWorkOrderChecklistItems(this.woId).toPromise();
  }

  public toggleChecklistView() {
    this.showChecklistView = !this.showChecklistView;
  }

  public addItem() {
    this.showCreate = true;
    this.isEditing = false;
    this.checklistFormGroup = this.fb.group({
      title: ['', [Validators.required]],
    });
  }

  public editItem(item: WorkOrderChecklistItem) {
    // this.showCreate = true;
    this.isEditing = true;
    this.checklistFormGroup = this.fb.group({
      title: [item.title, [Validators.required]],
    });
    this.editingItem = item;
  }

  public async deleteItem(item: WorkOrderChecklistItem) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Deleting Checklist Item...`);
    // async delete the item and update the list in place
    await this.workOrderService.deleteWorkOrderChecklistItem(item.id).toPromise();
    this.workOrderChecklistItems.splice(this.workOrderChecklistItems.indexOf(item), 1);
    this.progressIndicatorService.close();
    this.checklistUpdated.emit();
    this.snackbar.open('Checklist item deleted!');
  }

  public async submit() {
    // cancel if there are errors with the form
    if (!this.checklistFormGroup.valid) {
      this.snackbar.open('Title is required!');
      return;
    }
    // hide the edit/add box and generate the item from that data
    this.showCreate = false;

    this.progressIndicatorService.openAwaitIndicatorModal();
    // update or create
    if (this.isEditing) {
      this.progressIndicatorService.updateStatus(`Updating Checklist Item...`);
      // async update and edit the item here
      await this.workOrderService
        .updateWorkOrderChecklistItem(this.editingItem.id, {
          title: this.checklistFormGroup.get('title').value,
        })
        .toPromise();
      this.editingItem.title = this.checklistFormGroup.get('title').value;
      this.snackbar.open('Checklist item updated!');
      this.isEditing = false;
      this.editingItem = null;
    } else {
      this.progressIndicatorService.updateStatus(`Creating Checklist Item...`);
      // create the item and push it to the list
      const workOrderItem = {
        work_order_id: this.woId,
        title: this.checklistFormGroup.get('title').value,
      };
      const checklistItem = await this.workOrderService.createWorkOrderChecklistItem(workOrderItem).toPromise();
      this.workOrderChecklistItems.push(checklistItem);
      this.snackbar.open('Checklist item created!');
    }
    this.checklistUpdated.emit();
    this.progressIndicatorService.close();
  }

  public cancel() {
    this.showCreate = false;
    this.isEditing = false;
    this.editingItem = null;
  }

  public async updateStatus(item: WorkOrderChecklistItem, status_id: number) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Updating Checklist Status...`);
    // async update and edit the item status here
    const workOrderItem = { status_id };
    await this.workOrderService.updateWorkOrderChecklistItem(item.id, workOrderItem).toPromise();
    item.status_id = status_id;
    this.snackbar.open('Checklist status updated!');
    this.checklistUpdated.emit();
    this.progressIndicatorService.close();
  }

  //  Drag and drop functionality
  // commented out for now, due to taking too long to implement
  // async drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.workOrderChecklistItems, event.previousIndex, event.currentIndex);
  //   console.log(event);
  // }

  async refresh() {
    this.workOrderChecklistItems = await this.workOrderService.getWorkOrderChecklistItems(this.woId).toPromise();
  }
}
