import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
  FileAction,
  InvoiceStatus,
  ResourceType,
  TaskReviewStatus,
  TaskStatus,
  Workspace,
  WorkspaceType,
} from 'src/app/enums';
import {
  ArfsService,
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
  UserService,
} from 'src/app/services';
import { Arf, Invoice, ReviewChain, TaskAccessoryData, User } from 'src/app/types';
import { ProjectConstruction } from '../../workspaces/construction/types';

@Component({
  selector: 'app-invoice-review-buttons',
  templateUrl: './invoice-review-buttons.component.html',
  styleUrls: ['./invoice-review-buttons.component.scss'],
})
export class InvoiceReviewButtonsComponent implements OnInit, OnDestroy {
  @Input() public invoice: Invoice;
  @Input() public arf: Arf;
  @Input() public disableReview: boolean;
  @Input() public display: boolean;
  @Input() public hideAllButtons: boolean;
  @Input() public alignItemsRight: boolean;
  @Output() public invoiceChanged = new EventEmitter<Invoice | void>();

  @ViewChild('coverLetter', { static: true }) coverLetter;

  constructor(
    private arfService: ArfsService,
    public authService: AuthService,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    public projectService: ProjectService,
    private taskService: ProjectTaskService,
    private snackbar: MatSnackBar,
    private updateReviewService: UpdateReviewerService,
    private userService: UserService,
    private dateService: DateService
  ) {}

  // Review Process
  public accessoryData: TaskAccessoryData;
  public isCurrentReviewer: boolean;
  public currentUser: User;
  public currentReview: ReviewChain;
  public reviewStatus: TaskReviewStatus;
  public isReviewAdmin: boolean;
  public project: ProjectConstruction;

  public currentWorkspace: Workspace;
  public taskSelectedRefresh: any;
  public loading = false;

  async ngOnInit() {
    this.loading = true;
    this.accessoryData = this.invoice?.approval_task_accessory_data;
    await this.convertAccessoryData(this.accessoryData);

    // Get Current Workspace
    if (this.invoice?.project_id && !this.invoice.module_id) {
      this.project = this.projectService.currentSelectedProject;
      if (this.invoice?.project_id !== this.project?.id) {
        this.project = await this.projectService.getProjectById(this.invoice?.project_id).toPromise();
      }
    }

    this.isReviewAdmin = this.displayReviewersService.getIfUserIsReviewAdmin(
      this.accessoryData?.reviewChain,
      this.project
    );

    this.currentUser = this.authService.getLoggedInUser();

    if (
      this.invoice.parent_id &&
      this.invoice.parent_resource_type_id === ResourceType.AcquisitionRequestForm &&
      !this.arf
    ) {
      this.arf = await this.arfService.getArfById(this.invoice.parent_id, this.arfService.arfListFields).toPromise();
    }

    this.currentWorkspace = this.project?.module_id || this.invoice?.module_id || this.arf?.module_id;
    this.refresh();

    this.loading = false;

    // Subscribes to review changes
    this.taskSelectedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      if (data?.task?.accessory_data && data?.task?.id === this.invoice.approval_task_id) {
        await this.projectService
          .getInvoices([{ type: 'field', field: 'id', value: this.invoice.id.toString() }])
          .toPromise()
          .then((invoices) => {
            if (invoices?.length) {
              this.invoice = invoices[0];
              this.loading = false;
            }
          });

        await this.convertAccessoryData(data?.task?.accessory_data);
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.taskSelectedRefresh.unsubscribe();
    } catch (e) {}
  }

  // Updates review values as needed
  refresh() {
    this.currentReview = this.accessoryData?.reviewChain?.find(
      (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
    );

    this.isCurrentReviewer =
      this.currentReview?.status === TaskReviewStatus.Pending && this.currentReview?.id === this.currentUser.id;

    this.reviewStatus = this.currentReview?.status ?? TaskReviewStatus.Approved;
  }

  // If the accessory data is still a string converts it to an object
  async convertAccessoryData(accessoryData) {
    if (typeof accessoryData === 'string') {
      this.accessoryData = JSON.parse(accessoryData);

      // Remove this in the future when all invoices that use id are gone
      this.accessoryData.parentId = this.accessoryData.parentId || this.accessoryData.id;
    }

    if (this.accessoryData?.reviewChain) {
      this.accessoryData.reviewChain = await this.displayReviewersService.addUserInfoToReviewChain(
        this.accessoryData?.reviewChain
      );
    }
  }

  get invoiceInReview() {
    return !![InvoiceStatus.Received, InvoiceStatus.Approved].find((id) => id === this.statusId);
  }

  get InvoiceStatus() {
    return InvoiceStatus;
  }

  get isAdmin() {
    return (
      this.authService.isProjectAdmin(
        this.invoice?.project_id || this.projectService.currentSelectedProjectId,
        this.project?.module_id
      ) ||
      this.authService.isUserWorkspaceAdmin(this.invoice?.module_id) ||
      this.arf?.created_by_id === this.currentUser?.id ||
      this.isInvoiceCreator
    );
  }

  get isInvoiceCreator() {
    return this.invoice?.created_by_id === this.currentUser?.id;
  }

  get reviewHasNotStarted() {
    return this.accessoryData?.reviewChain[0]?.status === TaskReviewStatus.Pending;
  }

  get statusId() {
    return this.invoice?.status_id;
  }

  get taskId() {
    return this.invoice?.approval_task_id;
  }

  get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  // Manually creates a review task for an invoice. This should become obselete in the future.
  public async createInvoiceTask() {
    let initialReviewer;
    const reviewers = [];
    let isEngineering = false;
    const milestoneName = 'Invoices';

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating Task...');

    // There are different reviewers if the invoice is engineering vs not.
    if (this.invoice.trade_is_consultant) {
      isEngineering = true;
    }
    const currentProject =
      this.projectService.currentSelectedProject ||
      (await this.projectService.getProjectById(this.invoice.project_id).toPromise());

    const isDFSProject = currentProject?.module?.needs_dfs_approval;

    initialReviewer =
      isEngineering && currentProject.is_architect_required
        ? currentProject.architect_id
        : currentProject.project_manager_id;
    reviewers.push(initialReviewer);

    if (
      currentProject.module_id === Workspace.Construction &&
      currentProject.account_coordinator_id &&
      !reviewers.includes(currentProject.account_coordinator_id)
    ) {
      reviewers.push(currentProject.account_coordinator_id);
    }

    if (
      currentProject.module_id !== Workspace.Construction &&
      !reviewers.includes(currentProject.workspace_manager_id)
    ) {
      reviewers.push(currentProject.workspace_manager_id);
    }

    if (isDFSProject && currentProject.dfs_id && !reviewers.includes(currentProject.dfs_id)) {
      reviewers.push(currentProject.dfs_id);
    }

    const invoiceTotal = Number(this.invoice.total) - Number(this.invoice.retainage);
    if (
      !reviewers.includes(currentProject.cfmo_id) &&
      (isEngineering ||
        invoiceTotal > this.displayReviewersService.noCfmoMax ||
        (currentProject.module?.workspace_type_id === WorkspaceType.OneCall &&
          invoiceTotal > this.displayReviewersService.oneCallNoCfmoMax) ||
        currentProject.module_id === Workspace.OneCallAdmin)
    ) {
      reviewers.push(currentProject.cfmo_id);
    }

    const reviewChain = [];
    reviewers.forEach((r) => {
      reviewChain.push({ id: r, status: 0 });
    });

    const reviewCreator =
      ((currentProject.module_id === Workspace.Construction ||
        currentProject.module.workspace_type_id === WorkspaceType.OneCall) &&
        currentProject.account_coordinator_id) ||
      initialReviewer;
    const accessoryData = {
      type: 0,
      parentId: this.invoice.id,
      isReviewItem: 1,
      reviewChain,
      reviewCreator,
    };

    // Tries to get invoice milestone if it doesn't exist on the current phase it creates one.
    const milestone =
      (await this.projectService
        .getMilestoneByNameAndPhaseName(
          this.invoice.project_id || this.projectService.currentSelectedProjectId,
          milestoneName,
          currentProject.current_phase_name
        )
        .toPromise()) ||
      (await this.projectService
        .createMilestone({
          name: milestoneName,
          phase_id: currentProject.current_phase_id,
          sequence: 1,
        })
        .toPromise());

    const task = {
      title: `Review New ${this.invoice.trade_name} Invoice: ${this.invoice.title}`,
      milestone_id: milestone.id,
      assigned_user_id: initialReviewer,
      due_date: this.dateService.addWeekdays(0).format('YYYY-MM-DD'),
      description: 'A new invoice has been uploaded. Please mark Received.',
      can_delete: 0,
      accessory_data: JSON.stringify(accessoryData),
    };

    const newTask = await this.projectService.createTask(task).toPromise();

    if (initialReviewer) {
      await this.taskService.addFollowerToTask(newTask.id, initialReviewer).toPromise();
    }

    // Some older invoices are already along in the process so they need to be moved back to the beginning
    const newInvoice = await this.projectService
      .updateInvoice(this.invoice.id, {
        status_id: InvoiceStatus.New,
        revision: 1,
        approval_task_id: newTask.id,
      })
      .toPromise();

    this.invoice = newInvoice;
    this.progressIndicatorService.close();
    this.refresh();
  }

  // This edits the invoice if there has been a rejection
  public async openInvoiceRevisionDialog(updateInvoice = true) {
    const res = await this.modalService
      .openNewInvoiceModal(this.currentWorkspace, this.invoice, updateInvoice, this.arf)
      .toPromise();
    if (res) {
      this.loading = true;
      if (updateInvoice && !this.reviewHasNotStarted && !this.authService.isAC) {
        // Resets the review chain.
        await this.updateReviewService.resetReview(
          this.invoice.approval_task_id,
          this.accessoryData,
          'Revision made to invoice'
        );

        this.invoice.status_id = InvoiceStatus.Received;
      } else if (updateInvoice) {
        const task = this.invoice.approval_task || {
          id: this.invoice.approval_task_id,
          accessory_data: this.invoice.approval_task_accessory_data,
        };
        this.taskService.taskSelectedEvent.emit({ task, navigate: false });
      }

      this.loading = false;
      if (updateInvoice) {
        this.refresh();
        this.invoiceChanged.emit(res);
      }
    }

    return res;
  }

  // Opens the review dialog
  public async openInvoiceReviewDialog() {
    let openReviewDialog = true;
    if (this.isReviewAdmin && !this.isCurrentReviewer) {
      openReviewDialog = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Replace Reviewers',
          headerText: 'Expedite Review',
          descriptionText: `There are reviewers in front of you. Are you sure you want to expedite this review and review on the others' behalf?`,
          confirmationButtonText: 'Expedite Review',
        })
        .toPromise();
    }
    if (openReviewDialog) {
      const task = await this.projectService.getTaskById(this.invoice?.approval_task_id).toPromise();
      const showReviewDialog = this.accessoryData.isReviewItem && (this.isCurrentReviewer || this.isReviewAdmin);

      // Sees if the current user is an admin and the first reviewer. Certain things need to happen if this is the case.
      const receiveInvoice =
        this.isAdmin &&
        (this.accessoryData?.reviewChain[0]?.id === this.currentUser.id ||
          (this.isReviewAdmin && this.accessoryData?.reviewChain[0]?.status === TaskReviewStatus.Pending));

      const approvalResult = await this.modalService
        .openViewInvoiceModal(
          this.accessoryData.parentId,
          this.currentWorkspace,
          showReviewDialog,
          receiveInvoice,
          !this.arf
        )
        .toPromise();
      if (approvalResult) {
        const isApproved = approvalResult.approvalStatus === TaskReviewStatus.Approved;
        const comment = approvalResult.approvalComment;
        const rejectInvoice = approvalResult.rejectOption === 1;
        this.loading = true;
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Changing Review Status...');

        let attachedFiles = [];

        // Attaches the combined invoice file
        if (isApproved && receiveInvoice) {
          this.progressIndicatorService.updateStatus('Updating Invoice File...');
          const reviewInfo = [];
          for (const reviewer of this.accessoryData.reviewChain) {
            reviewInfo.push({
              id: reviewer.id,
              status: approvalResult.approvalStatus,
              comment:
                reviewer.id === this.currentUser?.id
                  ? comment
                  : `<p>Reviewed by ${this.currentUser?.first_name} ${this.currentUser.last_name} on the behalf of ${reviewer?.first_name} ${reviewer?.last_name}</p>`,
              date: moment().toDate(),
            });

            if (reviewer.id === this.currentUser?.id) {
              break;
            }
          }

          attachedFiles = await this.getCoverLetterFile(FileAction.Return, false, reviewInfo);
        }

        this.progressIndicatorService.updateStatus('Updating Review Task..');
        const reviewFiles = await this.updateReviewService.createTaskApprovalActivity(
          task,
          approvalResult.approvalStatus,
          comment,
          attachedFiles
        );

        await this.updateReviewService.reassignUser(
          task,
          this.accessoryData,
          approvalResult.approvalStatus,
          comment,
          [],
          reviewFiles
        );
        if (rejectInvoice) {
          await this.projectService
            .updateTask({ id: this.invoice.approval_task_id, status_id: TaskStatus.Complete })
            .toPromise();
        }

        this.progressIndicatorService.close();
        this.refresh();
        this.invoiceChanged.emit();
      }
    }
  }

  // Changes the invoice status to Rejected
  public async rejectInvoice() {
    const currentReviewUser: User =
      (this.currentReview?.id === this.currentUser?.id && this.currentUser) ||
      (await this.userService.getUserById(this.currentReview?.id, ['first_name', 'last_name']).toPromise());

    if (!this.currentReview.comment) {
      const reviewEvents = await this.displayReviewersService.displayReviewsByTask(this.invoice.approval_task_id);
      this.currentReview.comment = reviewEvents.find((event) => event.id === this.currentReview.id).comment;
    }
    const res = await this.modalService
      .openConfirmationDialog({
        confirmationButtonText: 'Reject Invoice',
        titleBarText: 'Reject Invoice',
        headerText: 'Confirm Rejection',
        descriptionText: `Please enter the reason for rejecting this invoice, below. The rejected review comment is shown to assist with your message to the supplier (If Applicable).`,
        accessoryContent: `Task review comment:<br/><br/>${this.currentReview?.comment}<br/><em>- ${currentReviewUser.first_name} ${currentReviewUser.last_name}</em>`,
        userInput: {
          placeholder: 'Reason for Rejection',
          required: true,
        },
      })
      .toPromise();
    if (res) {
      this.loading = true;

      // Completes the review task
      await this.projectService
        .updateTask({ id: this.invoice.approval_task_id, status_id: TaskStatus.Complete })
        .toPromise();

      // Changes the invoice status to rejected
      const newInvoice = await this.projectService
        .updateInvoice(
          this.invoice.id,
          {
            status_id: InvoiceStatus.Rejected,
            review_comment: res,
          },
          ['approval_task_id']
        )
        .toPromise();

      this.invoice = newInvoice;
      await this.projectService
        .createNote(ResourceType.Task, this.taskId, `<b>This invoice has been rejected.</b><br />` + `${res}`)
        .toPromise();

      this.refresh();
      this.invoiceChanged.emit();

      this.taskService.selectTaskById(this.invoice.approval_task_id, false).subscribe();
      this.loading = false;
    }
  }

  // Sets invoice Status to Paid
  public async processInvoice() {
    const isConfirmed = await this.modalService
      .openConfirmationDialog({
        confirmationButtonText: 'Download',
        headerText: 'Process Invoice',
        descriptionText:
          'To process this invoice, click the download button below. Once downloaded, please print and deliver to accounts payable to process payment.',
      })
      .toPromise();
    if (isConfirmed) {
      this.loading = true;
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Processing Invoice...');

      // Completed task
      await this.projectService
        .updateTask({ id: this.invoice.approval_task_id, status_id: TaskStatus.Complete })
        .toPromise();

      // Changes invoice status to Paid
      const newInvoiceStatus = await this.projectService
        .updateInvoice(this.invoice.id, { status_id: InvoiceStatus.ReadyForPayment })
        .toPromise();
      this.invoice.status_id = newInvoiceStatus.status_id;

      // Creates a note then grabs the most recent invoice file and attaches it to said note
      await this.projectService
        .createNote(ResourceType.Task, this.taskId, `Invoice has been approved`)
        .toPromise()
        .then(async (newNote) => {
          const linkedFiles = [];
          const attachedFiles = await this.getCoverLetterFile(FileAction.Both);

          await this.fileService.addFilesToNote(newNote, this.taskId, attachedFiles, linkedFiles);
        });

      this.progressIndicatorService.close();
      this.refresh();
      this.invoiceChanged.emit();
      this.taskService.invoiceUpdatedInTask.emit(this.invoice);
      this.loading = false;
    }
  }

  private async getCoverLetterFile(fileAction: FileAction, complete = true, reviewerInfo = []) {
    const currentProject =
      (this.projectService.currentSelectedProject?.id === this.invoice?.project_id &&
        this.projectService.currentSelectedProject) ||
      (this.invoice.project_id && (await this.projectService.getProjectById(this.invoice.project_id).toPromise()));

    const currentInvoice = await this.projectService
      .getInvoices([{ type: 'field', field: 'id', value: this.invoice.id.toString() }])
      .toPromise();

    this.invoice = currentInvoice[0];
    const newInvoiceFile = await this.coverLetter.exportInvoice(
      this.invoice,
      fileAction,
      currentProject,
      complete,
      reviewerInfo
    );

    return [newInvoiceFile];
  }

  public formatDate(date: Date): string {
    return moment(date).format('MMM DD YYYY').toString();
  }
}
