import { ApplicationRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF } from '@progress/kendo-drawing';
import { uniqBy } from 'lodash';
import * as moment from 'moment';
import { SubmittalReviewStatus, TaskReviewStatus, UserType } from '../../enums';
import { AuthService, DisplayReviewersService, FileService, UserService } from '../../services';
import { TaskAccessoryData, User } from '../../types';
import {
  BidPackage,
  ProjectConstruction,
  ProjectSubmittal,
  SubmittalCategory,
} from '../../workspaces/construction/types';

@Component({
  selector: 'app-submittal-transmittal',
  templateUrl: './submittal-transmittal.component.html',
  styleUrls: ['./submittal-transmittal.component.scss'],
})
export class SubmittalTransmittalComponent implements OnChanges {
  @Input() bidPackage: BidPackage;
  @Input() project: ProjectConstruction;
  @Input() accessoryData: TaskAccessoryData;

  @ViewChild('transmittal', { static: false }) transmittal;

  constructor(
    private authService: AuthService,
    private appRef: ApplicationRef,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private snackbar: MatSnackBar,
    private userService: UserService
  ) {}

  public reviewerData = {};
  public submittalCategories: SubmittalCategory[] = [];
  public submittals: ProjectSubmittal[];
  public vendorContact: User;
  public rejectedSubmittalExists: boolean;
  public revisedReviewIndex: number;

  async ngOnChanges() {
    if (this.bidPackage?.awarded_contact_id) {
      this.vendorContact = await this.userService.getUserById(this.bidPackage.awarded_contact_id).toPromise();
    }

    if (this.bidPackage?.submittals && this.authService.currentUser.user_type_id === UserType.Staff) {
      this.submittals = this.bidPackage.submittals || [];

      this.submittalCategories = uniqBy(
        this.submittals.map((i) => ({
          id: i.spec.category.id,
          code: i.spec.category.code,
          name: i.spec.category.name,
          submittals: [],
          reviewerInfo: [],
        })),
        (c) => c.id
      );

      const allUserInfo = [];
      for (const submittal of this.submittals) {
        const category = this.submittalCategories.find((c) => c.id === submittal.spec.category_id);
        category.submittals.push(submittal);

        submittal.accessory_data =
          submittal.accessory_data &&
          typeof submittal.accessory_data !== 'object' &&
          JSON.parse(submittal.accessory_data);

        if (submittal.accessory_data?.length) {
          let index = 0;
          for (const review of submittal.accessory_data) {
            const userIndex = category.reviewerInfo.findIndex((r) => r.user?.id === review.userId);
            if (userIndex !== -1) {
              review.submittal_name = submittal.spec?.name;
              review.submittal_code = submittal.spec?.code;
              category.reviewerInfo[userIndex].reviews.push(review);
            } else {
              if (!allUserInfo.find((user) => user.id === review.userId)) {
                const userInfo = await this.userService
                  .getUserById(review.userId, ['first_name', 'last_name', 'title', 'user_type_id'])
                  .toPromise();
                allUserInfo.push(userInfo);
                category.reviewerInfo.push({ user: userInfo });
              } else {
                const selectedUser = allUserInfo.find((user) => user.id === review.userId);
                category.reviewerInfo.push({ user: selectedUser });
              }
              review.submittal_name = submittal.spec?.name;
              review.submittal_code = submittal.spec?.code;
              category.reviewerInfo[category.reviewerInfo.length - 1].reviews = [review];
            }

            if (review.status === SubmittalReviewStatus.ReviseAndResubmit && !this.rejectedSubmittalExists) {
              this.revisedReviewIndex = index;
              this.rejectedSubmittalExists = true;
            }
            index++;
          }
        }
      }

      if (this.accessoryData?.reviewChain) {
        this.accessoryData.reviewChain = await this.displayReviewersService.addUserInfoToReviewChain(
          this.accessoryData?.reviewChain
        );
        const reviewData = await this.displayReviewersService.retrieveReviewers(this.accessoryData.reviewChain, false);
        reviewData?.forEach((r) => {
          this.reviewerData[r.id] = {
            firstName: r.firstName,
            lastName: r.lastName,
            title: r.title,
          };
        });
      }
    }
  }

  public get currentWorkspace() {
    return this.project?.module_id;
  }

  async export() {
    return await this.transmittal.export();
  }

  public getIconColor(status: TaskReviewStatus) {
    switch (status) {
      case TaskReviewStatus.Approved:
        return 'green';
      case TaskReviewStatus.NoteMarkings:
        return 'orange';
      case TaskReviewStatus.Rejected:
        return 'red';
      default:
        return 'gray';
    }
  }

  public getIconType(status: TaskReviewStatus) {
    if (status !== TaskReviewStatus.Rejected) {
      return 'fa-check-circle';
    } else {
      return 'fa-times-circle';
    }
  }

  public getStatusText(status: TaskReviewStatus) {
    switch (status) {
      case TaskReviewStatus.Approved:
        return 'No Exceptions Taken';
      case TaskReviewStatus.NoteMarkings:
        return 'Note Markings';
      case TaskReviewStatus.Rejected:
        return 'Revise & Resubmit';
      default:
        return 'Pending';
    }
  }

  public async downloadTransmittal() {
    await this.transmittal.saveAs(
      `Transmittal_for_${this.bidPackage.number}_${this.bidPackage.trade_name}_pr_${this.project.code}.pdf`
    );
  }

  public async exportTransmittal() {
    if (this.project && this.bidPackage) {
      const files = [];

      // refreshes the html component to assure all data is up-to-date.
      this.appRef.tick();

      // Generate Cover Letter Export
      const transmittalGroup = await this.transmittal.export();
      const transmittalBase64 = (await exportPDF(transmittalGroup)).replace('data:application/pdf;base64,', '');

      const transmittalByteCharacters = atob(transmittalBase64);
      const transmittalData = new Array(transmittalByteCharacters.length);

      for (let i = 0; i < transmittalByteCharacters.length; i++) {
        transmittalData[i] = transmittalByteCharacters.charCodeAt(i);
      }
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(transmittalData)]),
        name: 'submittal_cover_letter.pdf',
      });

      for (const reviewer of this.accessoryData?.reviewChain) {
        for (const reviewFile of reviewer?.files || []) {
          if (reviewer.status) {
            const fileData = await this.fileService.downloadFile({ id: reviewFile.id }).toPromise();
            files.push({
              file: new Blob([new Uint8Array(fileData.file.data)]),
              name: fileData.name,
            });
          }
        }
      }

      let combinedFile;
      try {
        if (files.length > 1) {
          combinedFile = await this.fileService.combinePDFs(files).toPromise();
        }
      } catch (e) {
        const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
        errorSnack.onAction().subscribe(async () => {
          this.snackbar.dismiss();
        });
        return;
      }

      const blob = combinedFile
        ? new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' })
        : files[0].file;
      const file = new File(
        [blob],
        `PRJ${this.project.code}_BP_${this.bidPackage.number}_v${this.bidPackage.transmittal_revision}.pdf`
      );

      return file || [];
    }
  }

  public formatDate(date: Date): string {
    return moment(date).format('LL').toString();
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }
}
