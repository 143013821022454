import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { remove } from 'lodash';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { DatepickerHeaderComponent, EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { FileService, ProjectService } from 'src/app/services';
import { Tag } from '../../../../types';

@Component({
  selector: 'app-proposal-request-dialog',
  templateUrl: './proposal-request-dialog.component.html',
  styleUrls: ['./proposal-request-dialog.component.scss'],
})
export class ProposalRequestDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  constructor(
    public dialogRef: MatDialogRef<ProposalRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private fileService: FileService
  ) {}

  proposalRequestFormGroup: FormGroup = this.fb.group({
    title: [
      {
        value: this.data && this.data.proposalRequest ? this.data.proposalRequest.title : '',
        disabled: this.data && this.data.hasOwnProperty('can_edit') && !this.data.can_edit,
      },
      [Validators.required],
    ],
    due_date: [
      {
        value: this.data && this.data.proposalRequest ? this.data.proposalRequest.due_date : null,
        disabled: this.data && this.data.hasOwnProperty('can_edit') && !this.data.can_edit,
      },
      [Validators.required],
    ],
    reason_id: [
      {
        value: this.data && this.data.proposalRequest ? this.data.proposalRequest.reason_id : null,
        disabled: this.data && this.data.hasOwnProperty('can_edit') && !this.data.can_edit,
      },
      [Validators.required],
    ],
    reason_option_id: [
      {
        value: this.data && this.data.proposalRequest ? this.data.proposalRequest.reason_option_id : null,
        disabled: this.data && this.data.hasOwnProperty('can_edit') && !this.data.can_edit,
      },
      [Validators.required],
    ],
  });
  existingFiles = [];
  files;
  updateFiles = [];
  proposalRequestReasons = new Set();
  proposalRequestReasonsOptions = [];
  selectedProposalRequestReasonsOptions = [];
  customHeader = DatepickerHeaderComponent;
  can_edit = true;

  preSelectedTags: Tag[];

  get title() {
    return this.proposalRequestFormGroup.get('title');
  }
  get description() {
    return this.proposalRequestFormGroup.get('description');
  }
  get due_date() {
    return this.proposalRequestFormGroup.get('due_date');
  }
  get reason_id() {
    return this.proposalRequestFormGroup.get('reason_id');
  }
  get reason_option_id() {
    return this.proposalRequestFormGroup.get('reason_option_id');
  }

  async ngOnInit() {
    await this._activateDescriptionField();
    if (this.data && this.data.hasOwnProperty('can_edit')) {
      this.can_edit = this.data.can_edit;
    }

    this.files = [];
    if (this.data && this.data.proposalRequest && this.data.proposalRequest.id) {
      this.existingFiles = await this.fileService
        .getFilesByParentId(ResourceType.ProposalRequest, this.data.proposalRequest.id)
        .toPromise();
    }
    // if the user doesn't have access to getFilesByParentId, then the below will still attach any files as needed (avoids duplicates)
    if (this.data && this.data.proposalRequest && this.data.proposalRequest.hasOwnProperty('files')) {
      for (const file of this.data?.proposalRequest?.files ?? []) {
        if (!this.existingFiles.map((f) => +f.file_id).includes(+file.id)) {
          this.existingFiles.push(file);
        }
      }
    }
    if (this.data && this.data.hasOwnProperty('preSelectedTags')) {
      this.preSelectedTags = this.data.preSelectedTags;
    }
    this.projectService
      .getProposalRequestReasons()
      .pipe(finalize(() => this.onChangeReason()))
      .subscribe((result: any) => {
        this.proposalRequestReasonsOptions = result;
        const proposal_request_reasons = result.map((r: { proposal_request_reasons }) => r.proposal_request_reasons);
        const uniqueSet = new Set();
        proposal_request_reasons.forEach((item) => {
          if (!uniqueSet.has(item.id)) {
            this.proposalRequestReasons.add(item);
            uniqueSet.add(item.id);
          }
        });
      });
  }

  onChangeReason() {
    this.reason_option_id.setValue(null);
    if (this.reason_id.invalid || this.reason_id.disabled || this.reason_id.value == null) {
      this.reason_option_id.disable();
    } else {
      this.reason_option_id.enable();
      this.selectedProposalRequestReasonsOptions = this.proposalRequestReasonsOptions.filter(
        (p: any) => p.proposal_request_reasons.id == this.reason_id.value
      );
    }
  }

  private async _activateDescriptionField() {
    this.proposalRequestFormGroup.addControl('description', this._editor_component.content);
    this.description.setValidators([Validators.required]);
    this.description.updateValueAndValidity();
    this.description.setValue(this.data?.proposalRequest?.description ?? '');

    // If a value is passed, we do what that says, othersise, we default it to not disabling
    if (this.data?.can_edit ?? false) {
      this.description.disable();
    }
  }

  removeFile(file, isExisting: boolean) {
    const files = isExisting ? this.existingFiles : this.files;
    remove(files, (f: any) => f.name === file.name);

    if (isExisting) {
      this.updateFiles.push(file);
    } else {
      remove(this.updateFiles, (f: any) => f.name === file.name);
    }
  }

  submit(): void {
    if (this.proposalRequestFormGroup.valid) {
      const proposalRequestToReturn = {
        title: this.title.value,
        description: this.description.value,
        due_date: this.due_date.value ? moment(this.due_date.value).format('YYYY-MM-DD') : null,
        reason_id: this.reason_id.value,
        reason_option_id: this.reason_option_id.value,
        files: this.updateFiles,
      };
      this.dialogRef.close(proposalRequestToReturn);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  // opens the upload modal, and passes the file on once it's uploaded, so it can be linked to the correct object
  openUploadModal() {
    let data;
    if (this.preSelectedTags) {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        allowComment: false,
        preSelectedTags: this.preSelectedTags,
      };
    } else {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        allowComment: false,
      };
    }

    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          for (const f of resultData) {
            if (this.files.find((fi) => fi.name === f.name)) {
              this.snackbar.open(`File with name '${f.name}' already exists!`);
            } else {
              this.files.push(f);
              this.updateFiles.push(f);
            }
          }
        }
      });
  }
}
