export enum TaskAccessoryType {
  Invoice = 0,
  PEB = 1,
  ChangeOrder = 2,
  Other = 3,
  Punchlist = 4,
  CB = 5,
  ProposalRequest = 6,
  Budget = 7,
  Arf = 8,
  KeyControls = 9,
  Submittals = 10,
  BidContract = 11,
  ProjectTimeline = 12,
}
