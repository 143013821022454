import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent implements OnInit {
  public mode: 'determinate' | 'indeterminate';

  public progressValue: number;

  public color = 'primary';

  public status: string;

  constructor(public dialogRef: MatDialogRef<ProgressIndicatorComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    if (data.mode) {
      this.mode = data.mode;
    }
    if (data.progressValue) {
      this.progressValue = data.progressValue;
    }
  }

  ngOnInit() {}

  public close() {
    this.dialogRef.close();
  }
}
