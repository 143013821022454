import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { ResourceType } from 'src/app/enums';
import { AuthService, FileService, ModalService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { AddendumDialogComponent } from 'src/app/workspaces/construction/components';
import { Addendum, ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-addendums',
  templateUrl: './addendums.component.html',
  styleUrls: ['./addendums.component.scss'],
})
export class AddendumsComponent implements OnInit {
  @Input() project: ProjectConstruction;

  constructor(
    private projectService: ProjectService,
    private fileService: FileService,
    private addendumDialog: MatDialog,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService
  ) {}

  private addendumFields = [
    'code',
    'title',
    'description',
    'notes',
    'isqft_date',
    'created_datetime',
    'files',
    'local_index',
  ];
  addendums: Addendum[];
  bidDetails: any = {};
  bidDetailsFields = ['bid_end_datetime'];
  loaders = {
    gettingAddendums: false,
    updatingAddendum: false,
  };

  ngOnInit() {
    setTimeout(() => {
      this.refresh();
    });
    if (this.project && this.project.id) {
    }
  }

  async refresh() {
    if (this.project && this.project.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Data..');
      this.loaders.gettingAddendums = true;
      this.bidDetails = await this.projectService
        .getProjectBidDetailsByProjectId(this.project.id, this.bidDetailsFields)
        .toPromise();
      const addendums = await this.projectService
        .getAddendums(
          [
            {
              type: 'field',
              field: 'project_id',
              value: this.project.id.toString(),
            },
          ],
          this.addendumFields
        )
        .toPromise();
      this.syncAddendumExpansion(addendums);

      addendums.sort((a, b) => +a.local_index - +b.local_index);

      this.addendums = addendums;
      this.loaders.gettingAddendums = false;
      this.progressIndicatorService.close();
    }
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  addEditAddendum(addendum?: Addendum) {
    const dialogRef = this.addendumDialog.open(AddendumDialogComponent, {
      disableClose: true,
      width: '550px',
      data: {
        bidEndDatetime: this.bidDetails ? this.bidDetails.bid_end_datetime : null,
        addendum: addendum || {},
      },
    });

    dialogRef.afterClosed().subscribe(async (returnedAddendum) => {
      if (returnedAddendum && this.project) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Data..');
        this.loaders.updatingAddendum = true;
        const newBidDueDate = returnedAddendum.bid_due_date;
        const newBidDueTime = returnedAddendum.bid_due_time;
        delete returnedAddendum.bid_due_date;
        delete returnedAddendum.bid_due_time;
        let message;
        if (returnedAddendum.id) {
          const addendumId = returnedAddendum.id;
          delete returnedAddendum.id;
          // map all the returned files to just the id (we don't care about the name)
          const returnedIds = returnedAddendum.files.map((file) => +file.id);
          // get all the currently linked files to the invoice
          const currentFiles = await this.fileService.getFilesByParentId(ResourceType.Addendum, addendumId).toPromise();
          // get the returned ids that aren't in the current ids, since we need to add these
          const fileIdsToAdd = returnedIds.filter((id) => !currentFiles.map((file) => +file.file_id).includes(id));
          // get the current ids that aren't in the returned ids, since we need to remove these
          const fileIdsToRemove = currentFiles.filter((file) => !returnedIds.includes(file.file_id));
          delete returnedAddendum.files;
          // update the invoice
          const updatedAsBuilt = await this.projectService
            .updateAddendum(addendumId, returnedAddendum, this.addendumFields)
            .toPromise();

          // link and unlink the appropriate files
          for (const f of fileIdsToRemove) {
            await this.fileService.unlinkFile(f.id).toPromise();
            this.fileService.removeTags(f.file_id || f.id, [1]).subscribe();
          }
          for (const f of fileIdsToAdd) {
            await this.fileService.linkFile(f, addendumId, ResourceType.Addendum).toPromise();
            await this.fileService.addTags(f, [1]).toPromise();
          }

          message = 'updated';
        } else if (this.project) {
          returnedAddendum.project_id = this.project.id;
          // get all the returned ids so we can link them
          const filesToAdd = returnedAddendum.files.map((file) => +file.id);
          delete returnedAddendum.files;
          const createdAddendum = await this.projectService
            .createAddendum(returnedAddendum, this.addendumFields)
            .toPromise();
          // link all the returned files to the new invoice
          for (const f of filesToAdd) {
            await this.fileService.linkFile(f, createdAddendum.id, ResourceType.Addendum).toPromise();
          }
          message = 'added';
        }
        if (newBidDueDate && newBidDueTime) {
          if (
            !this.bidDetails ||
            !this.bidDetails.bid_end_datetime ||
            moment(this.bidDetails.bid_end_datetime).format('YYYY-MM-DD HH:mm:ss') !==
              moment(`${newBidDueDate} ${newBidDueTime}`).format('YYYY-MM-DD HH:mm:ss')
          ) {
            const bidDetailsToUpdate = {
              id: this.bidDetails.id,
              bid_end_datetime: `${newBidDueDate} ${newBidDueTime}`,
              // bid_end_datetime: this.bidDetails.bid_end_date && this.bidDetails.bid_end_time ? moment(`${moment(this.bidDetails.bid_end_date).format('YYYY-MM-DD')} ${this.bidDetails.bid_end_time}`).format('YYYY-MM-DD HH:mm:ss') : null,|78j
            };
            await this.projectService.updateProjectBidDetails(bidDetailsToUpdate, this.bidDetailsFields).toPromise();
          }
        }
        this.loaders.updatingAddendum = false;
        this.snackbar.open(`Addendum ${message}!`);
        this.refresh();
      }
    });
  }

  removeAddendum(addendum: Addendum) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Addendum',
        descriptionText: 'Are you sure you want to remove this Addendum?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (addendum && addendum.id) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Data..');
            await this.projectService.deleteAddendum(addendum.id).toPromise();
            this.snackbar.open(`Addendum '${addendum.local_index}' removed!`);
            this.refresh();
          }
        }
      });
  }

  syncAddendumExpansion(addendums) {
    if (this.addendums) {
      const openAddendumIds = this.addendums.filter((a) => a.is_expanded).map((a) => a.id);
      if (openAddendumIds) {
        for (const a of addendums) {
          a.is_expanded = true;
          a.is_expanded = openAddendumIds.indexOf(a.id) > -1;
        }
      }
    }
  }
}
