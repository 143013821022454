export class Conversation {
  id?: number;
  subject?: string;
  channel_id?: number;
  followers?: any;
  channel_project_id?: number;
  channel_project_code?: any;
  channel_project_title?: string;
  created_by_first_name?: string;
  created_by_id?: number;
  created_by_last_name?: string;
  created_datetime?: string;
  task_id?: number;
  task_code?: any;

  // TODO: I had to add these so the app would build -Austin
  project?: any;
  reply_to_id?: any;
  message?: any;
}
