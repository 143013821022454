import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModuleService } from '../../services';
import { Workspace } from '../../types';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
})
export class WorkspaceSelectorComponent implements OnInit {
  // emits the ids of the selected workspaces
  @Output() workspaceSelectedChanged = new EventEmitter<number[]>();

  public selectedWorkspaceIds: number[] = [];

  constructor(private _moduleService: ModuleService, private fb: FormBuilder) {}

  public workspaceForm = this.fb.group({
    workspaces: [0],
  });

  async ngOnInit() {}

  get selectedWorkspaces() {
    return this.workspaceForm.get('workspaces');
  }

  get workspaces(): Workspace[] {
    return this._moduleService.allWorkspaces;
  }

  updateSelectedWorkspaces(workspaceId) {
    if (workspaceId === 0 || !this.selectedWorkspaces.value?.length) {
      this.selectedWorkspaces.setValue([0]);
    } else if (this.selectedWorkspaces.value.includes(0)) {
      this.selectedWorkspaces.setValue(this.selectedWorkspaces.value.filter((id) => id !== 0));
    }
    this.workspaceSelectedChanged.emit(this.selectedWorkspaces.value.filter((id) => id));
  }
}
