import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

import { Request } from 'src/app/types';

@Component({
  selector: 'app-dashboard-request',
  templateUrl: './dashboard-request.component.html',
  styleUrls: ['./dashboard-request.component.scss'],
})
export class DashboardRequestComponent implements OnInit {
  @Input() request: Request;

  constructor() {}

  ngOnInit() {}

  // returns the correctly formatted string which is the created time of the request
  getDayTime() {
    return moment(this.request.created_datetime).format('dddd, MMMM Do, YYYY');
  }
}
