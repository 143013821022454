<div #mainScreen (window:resize)="onResize($event)" class="container-fluid p-4">
  <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
    <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
      PEB
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
      Bid Packages
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/construction-budget']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Construction Budget
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Proposal Requests
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/change-orders']"
      mat-button
      color="primary"
      class="flex-grow-1 bg-white mx-1"
    >
      Change Orders
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/invoices']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Invoices
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/budget']" mat-button color="primary" class="flex-grow-1 ml-1">
      Budget Overview
    </button>
  </div>
  <header class="d-flex full-width align-items-top mt-1 border-bottom border-gray mb-4">
    <div class="d-flex flex-column mr-auto">
      <h4 class="dkblue">
        Approved Change Orders
        <small class="ml-4">
          {{ changeOrders && changeOrders.length ? changeOrders.length : 0 }}
        </small>
      </h4>
      <p class="dkgray">View and manage Change Orders here.</p>
    </div>
    <div class="text-right list-filters">
      <div class="d-inline-block align-middle ml-4 text-center">
        <h6 class="primary m-0">CO Net</h6>
        <h5 class="primary font-weight-normal m-0">
          {{ changeOrderTotalCost | currency }}
        </h5>
      </div>
      <button
        [disabled]="changeOrders?.length === 0"
        mat-stroked-button
        color="primary"
        type="button"
        class="ml-5 bg-white"
        (click)="exportChangeOrder()"
      >
        Export List
      </button>
    </div>
  </header>
  <br />
  <div class="portlet-table-header p-0 pl-4 pr-4">
    <div class="row">
      <div *ngIf="showDesktop" class="col-1">
        <label class="main-label">#</label>
      </div>
      <div class="col-2">
        <label class="main-label">Change Order</label>
      </div>
      <!-- <div *ngIf="showDesktop" class="col-1">
            <label class="main-label">PR</label>
          </div> -->
      <!-- <div *ngIf="showIpad" class="col-auto" style="width:96.84px">
            <label class="main-label">PR</label>
          </div> -->
      <div class="col-2">
        <label class="main-label">Supplier</label>
      </div>
      <div class="col-2">
        <label class="main-label">Funding</label>
      </div>
      <div class="col-1">
        <label class="main-label">Timeline</label>
      </div>
      <div class="col-1">
        <label class="main-label">Amount</label>
      </div>
      <div *ngIf="+authService?.currentUser?.user_type_id !== +userType.Vendor" class="col-3">
        <label class="main-label">File</label>
      </div>
      <!-- <div class="col-auto" style="width: 40px;"></div> -->
    </div>
  </div>

  <div class="pr-portlet m-portlet p-0" m-portlet="true">
    <div *ngFor="let c of changeOrders" (click)="viewChangeOrder(c)" class="m-portlet__body item p-4">
      <div class="row align-items-center">
        <div class="col-1">
          <h6 class="m-0 dkblue pointer">
            {{ c.local_index }}
          </h6>
        </div>
        <div class="col-2 d-flex flex-column">
          <p class="ltblue pointer font-weight-semibold m-0 hover-u" [innerHTML]="c.short_description"></p>
        </div>
        <div class="col-2">
          <p class="m-0 dkblue font-weight-semibold">
            <span> {{ c.company_name }} </span>
          </p>
          <p class="m-0 dkblue font-weight-normal">
            <span> {{ c.trade_name }} </span>
          </p>
          <!-- <p class="m-0 gray font-weight-normal">
                {{c.proposal_request_code}}
              </p> -->
        </div>
        <div class="col-2">
          <p class="m-0 dkblue font-weight-normal">
            {{ c.funding_source_fee_owner_type_id === 2 ? 'UHAT' : c.funding_source_tenant_name
            }}{{
              c.funding_source_fee_funding_source_id
                ? ' (' + c.funding_source_fee_funding_source_abbreviation + ')'
                : ''
            }}: {{ c.funding_source_fee_name || 'Added Cost' }}
          </p>
          <p *ngIf="!c.funding_source_fee_name && !c.funding_source_name" class="m-0 gray font-italic">Not Given</p>
        </div>
        <div class="col-1">
          <p class="m-0 dkblue font-weight-normal">
            {{ c.time_change_amount < 0 ? '' : '+ ' }}{{ c.time_change_amount }} {{ c.time_change_unit | titlecase }}
            {{ c.time_change_amount === 1 ? '' : 's' }}
          </p>
        </div>
        <div class="col-1">
          <p class="m-0 dkblue font-weight-semibold">
            <span *ngIf="c.cost_change > 0">
              {{ c.cost_change | currency }}
            </span>
            <span *ngIf="c.cost_change < 0">
              {{ c.cost_change | currency }}
            </span>
          </p>
        </div>
        <div *ngIf="+authService?.currentUser?.user_type_id !== +userType.Vendor" class="col-3">
          <mat-chip-list *ngIf="c?.files?.length && c.files_updated">
            <app-file-chip *ngFor="let file of c.files" [file]="file"></app-file-chip>
          </mat-chip-list>
          <mat-spinner *ngIf="!c.files_updated" color="accent" [diameter]="20"></mat-spinner>
        </div>
        <!-- <div class="col-auto" style="width: 40px;">
          <button mat-icon-button [matMenuTriggerFor]="pr_main_menu" class="ltblue pull-right">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #pr_main_menu="matMenu" xPosition="before">
                     TODO verify that edit CO needs to be removed for everyone
                     <button mat-menu-item (click)="editChangeOrder(c)" *ngIf="authService.isStaff">
                       <mat-icon>
                         edit
                       </mat-icon>
                       <span>
                         Edit Change Order
                       </span>
                     </button>
            <button mat-menu-item>
              <mat-icon>
                mail
              </mat-icon>
              <span>
                Start Conversation
              </span>
            </button>
            <button mat-menu-item class="red" (click)="removeChangeOrder(c)" *ngIf="authService.isStaff">
                  <mat-icon>
                    delete
                  </mat-icon>
                  <span>
                    Remove Change Order
                  </span>
                </button>
          </mat-menu>
        </div> -->
      </div>
      <!-- <div *ngIf="c.files" class="row mt-4">
            <div *ngIf="showDesktop" class="col-auto" style="width:100px"></div>
            <div class="col">
              <mat-chip-list class="d-inline-block align-middle">
                <app-file-chip *ngFor="let f of c.files" [file]="f"></app-file-chip>
              </mat-chip-list>
            </div>
          </div> -->
    </div>
  </div>
</div>
