<div>
  <div class="d-flex align-items-center mb-3">
    <span (click)="toggleChecklistView()" class="d-flex align-items-center pointer">
      <mat-icon class="dkblue mr-2">{{ showChecklistView ? 'expand_more' : 'chevron_right' }}</mat-icon>
      <h5 class="m-0 dkblue pointer">
        Checklist
        <span class="ml-2 gray font-weight-semibold">
          {{ checklistItems?.length }}
        </span>
      </h5>
    </span>
    <button
      *ngIf="showChecklistView"
      mat-flat-button
      color="success"
      class="ml-3 lh-28 pl-3 pr-3 ml-auto"
      (click)="addItem()"
      [disabled]="woStatus === 3"
    >
      + Item
    </button>
  </div>
  <div *ngIf="checklistItems.length === 0 && !showCreate" class="m-portlet bg-transparent">
    <div *ngIf="showChecklistView" class="m-portlet__body p-4 text-center">
      <p class="m-0 gray font-weight-normal">
        Click <span class="font-weight-semibold">+ Item</span> to Add a Checklist Item
      </p>
    </div>
  </div>
  <!--  <div class="m-portlet mt-1 p-0 example-list" cdkDropList (cdkDropListDropped)="drop($event)">-->
  <div *ngIf="checklistItems.length !== 0 || showCreate" class="m-portlet p-0">
    <span *ngIf="showChecklistView">
      <!--      <div-->
      <!--        class="m-portlet__body p-3 item file d-flex align-items-center example-box"-->
      <!--        *ngFor="let item of checklistItems"-->
      <!--        cdkDrag-->
      <!--        [cdkDragDisabled]="checklistItems.length < 2"-->
      <!--      >-->
      <div
        class="m-portlet__body p-0 item file"
        *ngFor="let item of checklistItems; let last = last"
        [ngClass]="{ 'item-last': last }"
      >
        <div *ngIf="editingItem !== item" class="d-flex align-items-center p-3">
          <div class="mr-3">
            <mat-icon
              matRipple
              class="br-50 ltgray pointer"
              matTooltip="Mark Complete"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              *ngIf="item.status_id === 1"
              (click)="updateStatus(item, 2)"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              matRipple
              class="br-50 green pointer"
              matTooltip="Mark Incomplete"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              *ngIf="item.status_id === 2"
              (click)="updateStatus(item, 1)"
              >check_circle</mat-icon
            >
            <mat-icon
              matRipple
              class="br-50 red pointer"
              matTooltip="Remove Hold"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              *ngIf="item.status_id === 3"
              (click)="updateStatus(item, 1)"
              >flag</mat-icon
            >
          </div>

          <div class="flex-grow-1">
            <p class="m-0 dkblue font-weight-semibold d-block pointer" *ngIf="item.status_id === 1">
              {{ item.title }}
            </p>
            <p class="m-0 dkblue opacity5 font-weight-semibold d-block pointer" *ngIf="item.status_id === 2">
              <s>{{ item.title }}</s>
            </p>
            <p class="m-0 dkblue opacity5 font-weight-semibold d-block pointer" *ngIf="item.status_id === 3">
              {{ item.title }}
            </p>
          </div>

          <div class="d-flex align-items-center ml-auto">
            <mat-icon
              *ngIf="item.from_topic_template"
              class="pointer ltgray"
              matTooltip="Template Item"
              matTooltipPosition="above"
              >playlist_add_check_circle</mat-icon
            >
            <mat-icon
              matRipple
              color="primary"
              [matMenuTriggerFor]="file_menu"
              matTooltip="More"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="ml-2 pointer br-50"
              >more_vert</mat-icon
            >
            <mat-menu #file_menu="matMenu">
              <button mat-menu-item (click)="editItem(item)">
                <mat-icon>edit</mat-icon>
                <span> Edit</span>
              </button>
              <button mat-menu-item *ngIf="item.status_id !== 3" (click)="updateStatus(item, 3)">
                <mat-icon>flag</mat-icon>
                <span>Place On Hold</span>
              </button>
              <button mat-menu-item *ngIf="item.status_id === 3" (click)="updateStatus(item, 1)">
                <mat-icon>flag</mat-icon>
                <span>Remove Hold</span>
              </button>
              <button mat-menu-item (click)="deleteItem(item)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="isEditing && editingItem === item" class="row align-items-center m-0 pt-2 pb-2">
          <div class="col pr-0">
            <form [formGroup]="checklistFormGroup">
              <mat-form-field appearance="outline" class="full-width mb-0">
                <input matInput #checklist_input placeholder="What needs to be done?" formControlName="title" />
              </mat-form-field>
            </form>
          </div>
          <div class="col-auto pl-3">
            <mat-icon
              matRipple
              matTooltip="Cancel"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="gray mt-2 pointer br-50"
              (click)="cancel()"
              >clear</mat-icon
            >
            <mat-icon
              matRipple
              matTooltip="Save"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="ml-3 mt-2 pointer green br-50"
              (click)="submit()"
              >check_circle</mat-icon
            >
          </div>
        </div>
      </div>
      <div
        class="
          m-portlet__body
          pt-2
          pb-2
          pl-3
          pr-3
          item-no-hover item-last
          file
          align-items-center
          border-top border-gray
        "
        *ngIf="showCreate"
      >
        <div class="row align-items-center">
          <div class="col pr-0">
            <form [formGroup]="checklistFormGroup">
              <mat-form-field appearance="outline" class="full-width mb-0">
                <input matInput placeholder="What needs to be done?" formControlName="title" />
              </mat-form-field>
            </form>
          </div>
          <div class="col-auto pl-3">
            <mat-icon
              matRipple
              matTooltip="Cancel"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="gray mt-2 pointer br-50"
              (click)="cancel()"
              >clear</mat-icon
            >
            <mat-icon
              matRipple
              matTooltip="Save"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="ml-3 mt-2 pointer green br-50"
              (click)="submit()"
              >check_circle</mat-icon
            >
          </div>
        </div>
      </div>
    </span>
  </div>
</div>
