import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FileService, HandleErrorService } from 'src/app/services';

@Component({
  selector: 'app-file-chip-list',
  templateUrl: './file-chip-list.component.html',
  styleUrls: ['./file-chip-list.component.scss'],
})
export class FileChipListComponent implements OnInit {
  @Input() diameter = 40;
  @Input() files = [];
  public downloading = false;

  constructor(
    private appFileService: FileService,
    private handleErrorService: HandleErrorService,
    private snackbarService: MatSnackBar
  ) {}

  ngOnInit(): void {}

  public async downloadAll() {
    if (!this.downloading) {
      this.downloading = true;
      for (const file of this.files) {
        await this.appFileService.download(file);
      }
      this.snackbarService.open('The files have been downloaded');
      this.downloading = false;
    }
  }
}
