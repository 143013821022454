import { Pipe, PipeTransform } from '@angular/core';

import { Meeting } from 'src/app/types';

@Pipe({
  name: 'meetingListFilter',
})
export class MeetingListFilterPipe implements PipeTransform {
  transform(meetings: Meeting[], searchTerm: string, myMeetings: number): any {
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();
      meetings = meetings.filter(
        (meeting) =>
          meeting.title.toLowerCase().includes(searchTerm) || meeting.location.toLowerCase().includes(searchTerm)
      );
    }

    if (myMeetings) {
      meetings = meetings.filter(
        (meeting) =>
          meeting.created_by_id === myMeetings ||
          (meeting.attendee_count > 1 && meeting.attendees.find((user) => user.user_id === myMeetings))
      );
    }

    return meetings;
  }
}
