export enum KeyControlTypes {
  BUDGET_FINALIZED_61 = 1,
  PROJECT_PROPERLY_BID_61,
  VENDOR_CONTRACTS_61,
  FIRE_MARSHAL_61,
  CONTRACT_COMPLETION_61,
  ALL_INVOICES__PROCESSED_61,
  BUDGET_APPROVED_74,
  PROJECT_PROPERLY_BID_74,
  ARFS_FINALIZED_74,
  ALL_INVOICES_PROCESSED_74,
  CUSTOMER_SATISFACTION_74,
}
