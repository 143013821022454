import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { DatepickerHeaderComponent } from 'src/app/components';
import { Order } from 'src/app/enums';
import { AuthService, MeetingService, ModalService, ModuleService, ProgressIndicatorService } from 'src/app/services';

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.scss'],
})
export class MeetingListComponent implements OnInit {
  constructor(
    private meetingService: MeetingService,
    public dialog: MatDialog,
    private router: Router,
    private modalService: ModalService,

    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private _workspaceService: ModuleService
  ) {}
  meetingFields = [
    'code',
    'title',
    'purpose',
    'is_mandatory',
    'start_datetime',
    'end_datetime',
    'location',
    'recurring_meeting_id',
    'recurring_meeting_frequency_number',
    'recurring_meeting_frequency_interval',
    'recurring_meeting_end_datetime',
    'agenda_count',
    'attendee_count',
    'type_id',
    'is_concluded',
    'concluded_datetime',
    'parent_type_id',
    'parent_id',
    'created_by_id',
    'attendees',
    'workspace_id',
    'workspace_name',
  ];
  meetings = {};
  meetingDates = [];
  startDate;
  endDate;
  dateRangeOption;
  customHeader = DatepickerHeaderComponent;
  searchTerm: string;
  meetingCount = 0;
  order: Order = Order.ASC;
  next: string;
  myMeetings: number;

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;

  ngOnInit() {
    const ps = new PerfectScrollbar(document.querySelector('#meetings'));
    setTimeout(() => {
      const preferences = JSON.parse(localStorage.getItem('preferences'));
      this.myMeetings = (preferences && preferences.filter_my_meetings) || null;

      this.setDateRange('upcoming');
      this.getDivWidth();
    });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  async refresh(next?: boolean) {
    if (!next) {
      this.meetings = {};
      this.meetingDates = [];
    }
    const meetingFilters = [];
    if (this.startDate) {
      const startFilter = {
        type: 'field',
        field: 'end_datetime',
        value: moment(this.startDate).startOf('second').format('YYYY-MM-DD hh:mm:ss'),
        match: '>=',
      };
      meetingFilters.push(startFilter);
    }
    if (this.endDate) {
      if (this.startDate) {
        meetingFilters.push({ type: 'operator', value: 'AND' });
      }
      const endFilter = {
        type: 'field',
        field: 'end_datetime',
        value: moment(this.endDate).endOf('second').format('YYYY-MM-DD HH:mm:ss'),
        match: '<=',
      };
      meetingFilters.push(endFilter);
    }

    const meetingData = await this.meetingService
      .getMeetings(meetingFilters, this.meetingFields, 12, this.order, next ? this.next : null)
      .toPromise();
    this.next = meetingData.next;
    for (const m of meetingData.data.meetings) {
      const meetingDate = moment(m.start_datetime).format('YYYY-MM-DD');
      if (!this.meetingDates.find((md) => md === meetingDate)) {
        this.meetingDates.push(meetingDate);
        this.meetings[meetingDate] = [];
      }
      this.meetings[meetingDate].push(m);
    }
    this.meetingCount = Object.keys(this.meetings).length;
  }

  async setDateRange(dateRangeOption, startDate?, endDate?) {
    this.order = Order.ASC;
    this.next = null;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Meetings..');
    if (dateRangeOption === 'upcoming') {
      this.dateRangeOption = 'upcoming';
      this.startDate = moment().toDate();
      this.endDate = null;
    } else if (dateRangeOption === 'past') {
      this.dateRangeOption = 'past';
      this.startDate = null;
      this.order = Order.DESC;
      this.endDate = moment().toDate();
    } else if (dateRangeOption === 'week') {
      this.dateRangeOption = 'week';
      this.startDate = moment().startOf('week').toDate();
      this.endDate = moment().endOf('week').toDate();
    } else if (dateRangeOption === 'month') {
      this.dateRangeOption = 'month';
      this.startDate = moment().startOf('month').toDate();
      this.endDate = moment().endOf('month').toDate();
    } else if (dateRangeOption === 'nextweek') {
      this.dateRangeOption = 'nextweek';
      this.startDate = moment().startOf('week').add(7, 'days').toDate();
      this.endDate = moment().endOf('week').add(7, 'days').toDate();
    } else if (dateRangeOption === 'custom') {
      this.dateRangeOption = 'custom';
      if (startDate) {
        this.startDate = moment(startDate).toDate();
        if ((!endDate && this.endDate < this.startDate) || (endDate && moment(endDate) < this.startDate)) {
          endDate = this.startDate;
        }
      }
      if (endDate) {
        this.endDate = moment(endDate).toDate();
        if (!startDate && this.startDate > this.endDate) {
          this.startDate = this.endDate.toDate();
        }
      }
    } else {
      this.dateRangeOption = 'week';
      this.startDate = moment().startOf('week').toDate();
      this.endDate = moment().endOf('week').toDate();
    }
    await this.refresh();
    this.progressIndicatorService.close();
  }

  public filterMyMeetings() {
    this.myMeetings ? (this.myMeetings = null) : (this.myMeetings = this.authService.getLoggedInUser().id);
    localStorage.setItem('preferences', JSON.stringify(this.addToPreferences('filter_my_meetings', this.myMeetings)));
  }

  public get openDialog() {
    return this.modalService.openMeetingDialog;
  }

  openNewMeetingDialog() {
    this.modalService.createMeeting({
      type_id: 1,
      parent_type_id: null,
      parent_id: null,
    });
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }
}
