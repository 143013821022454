import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-string-list-pill-container',
  templateUrl: './string-list-pill-container.component.html',
  styleUrls: ['./string-list-pill-container.component.scss'],
})
export class StringListPillContainerComponent implements OnInit {
  @Input() valuesArray: string[];

  @Input() placeholder = 'Single value to add';

  @Input() disabled: boolean;

  @Output() itemListChange = new EventEmitter<string[]>();

  @Input() buttonItems: string[];

  @Input() items: string[] = [];

  @ViewChild('inBox', { static: true }) input: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit() {}

  submitKeyEvent(inputBox) {
    if (!inputBox.value || inputBox.value === '') {
      return;
    }

    this.addItemButtonClicked(inputBox.value);
    inputBox.value = '';
  }

  removeEntry(item: string) {
    this.items = this.items.filter((e) => item !== e);
    this.fireItemListChangeEvent();
  }

  addItemButtonClicked(item) {
    if (!this.items.includes(item)) {
      this.items.push(item);
    }

    this.fireItemListChangeEvent();
  }

  autoCompleteSelection($event: MatAutocompleteSelectedEvent) {
    this.addItemButtonClicked($event.option.value);
    this.input.nativeElement.value = '';
  }

  private fireItemListChangeEvent() {
    this.itemListChange.emit(this.items);
  }
}
