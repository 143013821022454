export enum ProjectTenantPEBStatus {
  PreApproval = 1,
  InternalRejection = 2,
  TenantRejection = 3,
  AwaitingWMApproval = 4,
  AwaitingArchitectApproval = 5,
  AwaitingCFMOApproval = 6,
  ApprovedInternally = 7,
  AwaitingTenantApproval = 8,
  ApprovedByTenant = 9,
  ApprovedInternallySkipTenant = 10,
  Finalized = 11,
}
