<app-base-dialog [title]="title" (closeDialog)="close()"></app-base-dialog>
<div class="troubleshoot-dialog">
  <mat-dialog-content class="d-flex flex-column">
    <mat-form-field appearance="outline" class="full-width mb-2">
      <input matInput [(ngModel)]="email" placeholder="Enter Email Address" />
    </mat-form-field>
    <h6 *ngIf="invalidEmail" class="mb-2 font-weight-semibold dkblue">Invalid Email Address</h6>
    <h6 *ngIf="dataLoaded" class="mb-2 font-weight-semibold dkblue">Results</h6>
    <div *ngIf="dataLoaded" class="p-3 br-5 bg-shade-gray d-flex flex-column">
      <div class="d-flex align-items-center mb-2">
        <p class="dkblue font-weight-normal mr-2 mb-0" style="font-size: 1.1rem">Name:</p>
        <p class="dkblue font-weight-semibold mb-0" style="font-size: 1.1rem">
          {{ usersName }}
        </p>
      </div>
      <div class="d-flex align-items-center mb-2">
        <mat-icon class="mr-2 green" [ngClass]="{ red: !accountExists }">
          {{ accountExists ? 'done' : 'clear' }}
        </mat-icon>
        <p class="dkblue font-weight-semibold m-0">Account Exists</p>
      </div>
      <div class="d-flex align-items-center mb-2">
        <mat-icon class="mr-2 green" [ngClass]="{ red: !accountConfirmed }">
          {{ accountConfirmed ? 'done' : 'clear' }}
        </mat-icon>
        <p class="dkblue font-weight-semibold m-0">Account Confirmed</p>
      </div>
      <div class="d-flex align-items-center mb-2">
        <mat-icon class="mr-2 green" [ngClass]="{ red: !settingsCorrect }">
          {{ settingsCorrect ? 'done' : 'clear' }}
        </mat-icon>
        <p class="dkblue font-weight-semibold m-0">Settings Correct</p>
      </div>
      <div class="d-flex align-items-center mb-2">
        <mat-icon class="mr-2 green" [ngClass]="{ red: !usesAD }">
          {{ usesAD ? 'done' : 'clear' }}
        </mat-icon>
        <p class="dkblue font-weight-semibold m-0">Uses Active Directory</p>
      </div>
      <div class="d-flex align-items-center">
        <mat-icon class="mr-2 green" [ngClass]="{ red: !existsInAD }">
          {{ existsInAD ? 'done' : 'clear' }}
        </mat-icon>
        <p class="dkblue font-weight-semibold m-0">Exists in Active Directory</p>
      </div>
      <div class="d-flex align-items-center">
        <p class="dkblue font-weight-semibold m-0">LDAP Name: {{ ldapName }}</p>
      </div>
      <div class="d-flex align-items-center">
        <p class="dkblue font-weight-semibold m-0">LDAP Reset Link: {{ ldapResetLink }}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="pt-2 pb-2">
    <button mat-flat-button color="accent" class="full-width" (click)="submit()" type="submit">Troubleshoot</button>
    <button mat-button class="full-width gray" (click)="close()" type="button">Dismiss</button>
  </mat-dialog-actions>
</div>
