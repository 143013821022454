import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { Conversation, Message } from 'src/app/models';
import {
  AuthService,
  MessagingSystemService,
  ModalService,
  ProgressIndicatorService,
  UserSearchService,
} from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-new-conversation-message',
  templateUrl: './new-conversation-message.component.html',
  styleUrls: ['./new-conversation-message.component.scss'],
})
export class NewConversationMessageComponent implements OnInit {
  private _conversation: Conversation;
  private _files: File[] = [];
  private _followerTextSearchValue: string;
  private _newMessageContent: string;
  private _newMessageFollowers: User[] = [];
  private _replyingToMessage: Message = null;
  private _showNewMessageUserSearch: boolean;
  private _toFollowers: User[] = [];
  private _toFollowerSearchValue: string;

  @Output() public openPanelEvent = new EventEmitter();

  @Input() public panelIsOpen: boolean;

  @ViewChild('followerInput', { static: false }) followerInput;
  @ViewChild('messageTextArea', { static: false }) messageTextArea;
  @ViewChild('toFollowerInput', { static: false }) toFollowerInput;

  constructor(
    public messagingService: MessagingSystemService,
    public authService: AuthService,
    private snackbar: MatSnackBar,
    public userSearchService: UserSearchService,
    private snackbarService: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService
  ) {}
  get conversation(): Conversation {
    return this._conversation;
  }

  set conversation(newConversation: Conversation) {
    this._conversation = newConversation;
  }

  get files(): File[] {
    return this._files;
  }

  set files(newFiles: File[]) {
    this._files = newFiles;
  }

  get followerTextSearchValue(): string {
    return this._followerTextSearchValue;
  }

  set followerTextSearchValue(newSearch: string) {
    this._followerTextSearchValue = newSearch;
  }

  get newMessageContent(): string {
    return this._newMessageContent;
  }

  set newMessageContent(newMessage: string) {
    this._newMessageContent = newMessage;
  }

  get newMessageFollowers(): User[] {
    return this._newMessageFollowers;
  }

  set newMessageFollowers(newMessageFollowers: User[]) {
    this._newMessageFollowers = newMessageFollowers;
  }

  get replyingToMessage(): Message {
    return this._replyingToMessage;
  }

  set replyingToMessage(newReplyTo: Message) {
    this._replyingToMessage = newReplyTo;
  }

  get showNewMessageUserSearch(): boolean {
    return this._showNewMessageUserSearch;
  }

  set showNewMessageUserSearch(showNewSearch: boolean) {
    this._showNewMessageUserSearch = showNewSearch;
  }

  get toFollowers(): User[] {
    return this._toFollowers;
  }

  set toFollowers(newFollowers: User[]) {
    this._toFollowers = newFollowers;
  }

  get toFollowerSearchValue(): string {
    return this._toFollowerSearchValue;
  }

  set toFollowerSearchValue(newFollowerSearcchValue: string) {
    this._toFollowerSearchValue = newFollowerSearcchValue;
  }

  ngOnInit() {
    this.messagingService.events.onReplyToMessageEvent.subscribe((replyObj) => {
      if (replyObj && replyObj.message) {
        this.replyingToMessage = replyObj.message;
        this.toFollowers = this.conversation.followers.filter(({ id }) => +id === +replyObj?.message?.created_by_id);
      } else {
        this.replyingToMessage = null;

        // using it to not reply to ourselves
        const loggedInUserId = this.authService.getLoggedInUser().id;

        // get all messages by cnversation id, pick the recent comment not belogging to logged in user.
        // Since id autincrement, we pick the largest one
        this.messagingService.getMessagesObservable(this.conversation.id).subscribe((results) => {
          const recentComment = results.reduce((previousComment, currentComment) => {
            if (loggedInUserId === currentComment.created_by_id || previousComment.id > currentComment.id) {
              return previousComment;
            }
            return currentComment;
          }, {});

          this.toFollowers = this.conversation.followers.filter(
            ({ id }) => parseInt(id, 10) === recentComment.created_by_id
          );
        });
      }
    });

    this.conversation = this.messagingService.currentSelectedConversation;
    this.messagingService.events.onConversationSelectEvent.subscribe((selectedConversation) => {
      this.conversation = selectedConversation;
      this.cancelReply();
    });
  }

  onUserSearchSelection(event) {
    // console.log(event);
  }

  removeFileFromNewMessage(a: any) {}

  addFilesToNewMessage(files: FileList) {}

  public getToFollowersString(): string {
    let str = '';
    this.toFollowers.forEach((fol, i) => {
      if (i > 0) {
        str += ', ';
      }
      str += `${fol.first_name} ${fol.last_name}`;
    });
    return str;
  }

  public async sendMessage() {
    if (this.newMessageContent.length < 1) {
      return;
    }
    const toUsers = this.toFollowers.map((user) => parseInt(user.id.toString(), 10));

    const inviteUsers = this.toFollowers.filter((u) => !u.is_login_enabled);
    const promptInvite = inviteUsers.length > 0;
    if (
      promptInvite &&
      !(await this.modalService
        .openInviteUserModal({
          users: inviteUsers,
          parentId: this.conversation.id,
          parentTypeId: 13,
        })
        .toPromise())
    ) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Sending Message..');

    await this.messagingService
      .addAddOrRemoveConversationFollowers(
        this.conversation.id,
        this.newMessageFollowers.map((u) => u.id),
        'add'
      )
      .then(async (newConversationObject) => {
        await this.messagingService
          .createMessage(
            this.conversation.id,
            toUsers,
            this.replyingToMessage ? this.replyingToMessage.id : null,
            this.newMessageContent,
            this.files
          )
          .toPromise()
          .then(async (createdMessage) => {
            await this.messagingService.selectMessage(createdMessage);
            this.newMessageContent = '';
            this.toFollowers = [];
            this.newMessageFollowers = [];
            this.files = [];
            this.replyingToMessage = null;
            this.snackbar.open(`Message Sent!`);
          });
      });
    this.progressIndicatorService.close();
  }

  public addFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (!this.files.find((f) => f.name === files[i].name)) {
        this.files.push(files[i]);
      }
    }
  }

  public removeFile(file: File) {
    this.files = this.files.filter((f) => f.name !== file.name);
  }

  download(file) {
    saveAs(file as File);
    this.snackbarService.open(`${file.name} has been downloaded`);
  }

  // stops the parent click event
  public stopPropagation(event) {
    event.stopPropagation();
  }

  cancelReply() {
    this.replyingToMessage = null;
    this.toFollowers = [];
    this.files = [];
    this.newMessageContent = '';
    this.newMessageFollowers = [];
  }

  public tabPressed(event) {
    event.preventDefault();
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;

    const htmlEl = this.messageTextArea.nativeElement;
    // set textarea value to: text before caret + tab + text after caret
    htmlEl.value = htmlEl.value.substring(0, start) + '\t' + htmlEl.value.substring(end);

    // put caret at right position again
    event.target.selectionStart = event.target.selectionEnd = start + 1;
  }

  public addNewMessageFollower(user: User) {
    if (
      !this.newMessageFollowers.find((u) => u.id === user.id) &&
      !this.conversation.followers.find((u) => u.id === user.id)
    ) {
      this.newMessageFollowers.push(user);
    }
    this.followerInput.nativeElement.value = '';
    this.followerTextSearchValue = '';
  }

  public removeNewMessageFollower(follower: User) {
    this.newMessageFollowers = this.newMessageFollowers.filter((f) => f.id !== follower.id);
    this.removeToFollower(follower);
  }

  public toggleToFollower(user: User) {
    if (this.toFollowers.find((f) => f.id === user.id)) {
      this.removeToFollower(user);
    } else {
      this.addToFollower(user);
    }
  }

  public addToFollower(follower: User) {
    if (!this.toFollowers.find((f) => f.id === follower.id)) {
      this.toFollowers.push(follower);
      if (!this.conversation.followers.find((f) => f.id === follower.id)) {
        this.addNewMessageFollower(follower);
      }
    }
    this.toFollowerInput.nativeElement.value = '';
    this.toFollowerSearchValue = '';
  }

  public removeToFollower(follower: User) {
    this.toFollowers = this.toFollowers.filter((fol) => fol.id !== follower.id);
  }

  public searchBoxValueChange(value: string) {
    this.userSearchService.filterUsers(value);
  }

  get filteredUsers(): User[] {
    return this.userSearchService.getFilteredUsers();
  }

  public userIsToFollower(f: any): boolean {
    return this.toFollowers.find((u) => u.id === f.id) != null;
  }
}
