<div
  id="title-seventy-four-key-control-columns"
  data-test-id="title-seventy-four-key-control-columns"
  class="title-seventy-four-key-control-columns m-portlet"
>
  <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
    <div class="row align-items-center">
      <div
        class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
        style="width: 110px"
        [ngClass]="{ sortedBy: fieldToSortBy === 'code' }"
      >
        <h6 (click)="updateSortByField('code')" class="m-0 text-truncate header-title mr-auto">Code</h6>
        <i *ngIf="fieldToSortBy === 'code'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
        style="max-width: 110px"
        [ngClass]="{ sortedBy: fieldToSortBy === 'building_code' }"
      >
        <h6 (click)="updateSortByField('building_code')" class="m-0 text-truncate header-title mr-auto">Location</h6>
        <i *ngIf="fieldToSortBy === 'building_code'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center"
        [ngClass]="{ sortedBy: fieldToSortBy === 'title' }"
      >
        <h6 (click)="updateSortByField('title')" class="m-0 text-truncate header-title mr-auto">Short Description</h6>
        <i *ngIf="fieldToSortBy === 'title'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        *ngIf="isDispatch"
        class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
        [ngClass]="{ sortedBy: fieldToSortBy === 'module_name' }"
      >
        <h6 (click)="updateSortByField('module_name')" class="m-0 text-truncate header-title mr-auto">Workspace</h6>
        <i *ngIf="fieldToSortBy === 'module_name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
        [ngClass]="{ sortedBy: fieldToSortBy === 'fyc_approved_budget' }"
      >
        <h6 (click)="updateSortByField('fyc_approved_budget')" class="m-0 text-truncate header-title mr-auto">
          {{ KEY_CONTROL_HEADING.BUDGET_APPROVED_74 }}
        </h6>
        <i *ngIf="fieldToSortBy === 'fyc_approved_budget'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 (click)="updateSortByField('kc_bids')" class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.PROJECT_PROPERLY_BID_74 }}
        </h6>
        <i *ngIf="fieldToSortBy === 'kc_bids'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <!--STATE CONTRACT HIDDEN FOR NOW-->
      <!-- <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 (click)="updateSortByField('state_contract')" class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.VENDOR_ON_STATE_CONTRACT }}
        </h6>
        <i *ngIf="fieldToSortBy === 'state_contract'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div> -->
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 (click)="updateSortByField('fyc_approved_budget')" class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.ARFS_FINALIZED_74 }}
        </h6>
        <i *ngIf="fieldToSortBy === 'fyc_approved_budget'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 (click)="updateSortByField('kc_invoices')" class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.ALL_INVOICES_PROCESSED_74 }}
        </h6>
        <i *ngIf="fieldToSortBy === 'kc_invoices'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 (click)="updateSortByField('kc_substantial_completion')" class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.CUSTOMER_SATISFACTION_74 }}
        </h6>
        <i *ngIf="fieldToSortBy === 'kc_substantial_completion'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
    </div>
  </div>
  <div
    data-test-id="project-list-item"
    [routerLink]="['/projects/' + project.id]"
    *ngFor="let project of projects"
    class="m-portlet__body item-no-hover p-3 pt-4 pb-4"
  >
    <div class="row align-items-center">
      <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 110px">
        <p
          [routerLink]="['/projects/' + project.id]"
          class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle"
        >
          {{ project.code }}
        </p>
        <i *ngIf="project?.status?.id === 1" class="material-icons green ml-auto">label_important</i>
        <i *ngIf="project?.status?.id === 3" class="material-icons red ml-auto">flag</i>
        <i *ngIf="project?.status?.id === 2" class="material-icons ltblue ml-auto">next_plan</i>
        <i *ngIf="project?.status?.id === 4" class="material-icons gray ml-auto">done_all</i>
      </div>
      <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
        <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
          {{ project.building?.code }}{{ project.floor?.code ? '-' : '' }}{{ project.floor?.code }}
        </p>
      </div>
      <div class="col-3 pl-3 pr-3 d-flex flex-column data-item">
        <p class="m-0 dkblue font-weight-semibold d-block">
          {{ project?.title }}
        </p>
      </div>
      <div *ngIf="isDispatch" class="col pl-3 pr-3 d-flex align-items-center data-item">
        <p class="m-0 mr-auto dkblue font-weight-normal">
          {{ project?.module?.name }}
        </p>
      </div>
      <div
        (click)="openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.BUDGET_APPROVED_74)"
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.BUDGET_APPROVED_74?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.PROJECT_PROPERLY_BID_74)
        "
        class="col pl-3 pr-3 text-center align-items-center data-item"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.PROJECT_PROPERLY_BID_74?.task"
        ></app-key-control-icon-badge>
      </div>
      <!--STATE CONTRACT HIDDEN FOR NOW-->
      <!-- <div
        (click)="openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.VENDOR_ON_STATE_CONTRACT)"
        class="col pl-3 pr-3 text-center align-items-center data-item"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.VENDOR_ON_STATE_CONTRACT?.task"
        ></app-key-control-icon-badge>
      </div> -->
      <div
        (click)="openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.ARFS_FINALIZED_74)"
        class="col pl-3 pr-3 text-center align-items-center data-item"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.ARFS_FINALIZED_74?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.ALL_INVOICES_PROCESSED_74)
        "
        class="col pl-3 pr-3 text-center align-items-center data-item"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.ALL_INVOICES_PROCESSED_74?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.CUSTOMER_SATISFACTION_74)
        "
        class="col pl-3 pr-3 text-center align-items-center data-item"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.CUSTOMER_SATISFACTION_74?.task"
        ></app-key-control-icon-badge>
      </div>
    </div>
  </div>
</div>
<div class="not-found-prompt" *ngIf="filter?.searchString && filter?.statusId">
  <div class="row">
    <div class="col text-center">
      <h5 class="dkblue mt-4">Didn't find what you're looking for?</h5>
      <button mat-stroked-button color="accent" class="mt-2 bg-white" (click)="changeFilterStatus(0)">
        Search All Projects
      </button>
    </div>
  </div>
</div>
