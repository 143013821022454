import { Component, Input, OnInit, ViewChild } from '@angular/core';

import * as moment from 'moment';

import { AuthService, MessagingSystemService, ModalService, UserService } from 'src/app/services';

import { Message } from 'src/app/models';

@Component({
  selector: 'app-messaging-conversation-panel-message',
  templateUrl: './messaging-conversation-panel-message.component.html',
  styleUrls: ['./messaging-conversation-panel-message.component.scss'],
})
export class MessagingConversationPanelMessageComponent implements OnInit {
  @Input() message: Message;

  @ViewChild('messageElement', { static: true }) htmlElement;

  private loggedInUserId: number;

  constructor(
    private messagingService: MessagingSystemService,
    public authService: AuthService,
    private userService: UserService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.loggedInUserId = this.authService.getLoggedInUser().id;
    this.messagingService.events.onMessageSelectEvent.subscribe((message) => {
      if (this.isSelected) {
        this.htmlElement.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
    if (this.isSelected) {
      this.htmlElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  beginNewMessage() {
    this.messagingService.events.onReplyToMessageEvent.emit({
      message: this.message,
    });
  }

  beginForwardInConversation() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'New Conversation',
        headerText: 'Start A New Conversation',
        descriptionText: `Do you want to forward this message in a new conversation?`,
      })
      .subscribe((response) => {
        if (response) {
          this.messagingService.events.openCreateConversationPanelEvent.emit({
            projectId: this.message.channel_project_id,
            subject: `RE: ${this.message.conversation_subject}`,
            followers: [],
            files: this.message.files,
            message: this.message.content && this.message.content.length > 0 ? this.getReplyText() : '',
          });
        }
      });
  }

  beginReplyInConversation() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'New Conversation',
        headerText: 'Start A New Conversation',
        descriptionText: `Do you want to reply to this message in a new conversation?`,
      })
      .subscribe((response) => {
        if (response) {
          const followers = [
            {
              id: this.message.created_by_id,
              first_name: this.message.created_by_first_name,
              last_name: this.message.created_by_last_name,
            },
          ];
          this.messagingService.events.openCreateConversationPanelEvent.emit({
            projectId: this.message.channel_project_id,
            subject: `RE: ${this.message.conversation_subject}`,
            followers,
            toFollowers: followers,
            files: this.message.files,
            message: this.message.content && this.message.content.length > 0 ? this.getReplyText() : '',
          });
        }
      });
  }

  get isSelected() {
    return this.messagingService.currentSelectedMessage.id === this.message.id;
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  debug() {
    console.log(this.message);
  }

  markUnread() {
    this.messagingService.changeMessageStatus(this.message, false).subscribe((m) => {
      this.message.read_by_user_ids = m.read_by_user_ids;
    });
  }

  markRead() {
    this.messagingService.changeMessageStatus(this.message, true).subscribe((m) => {
      this.message.read_by_user_ids = m.read_by_user_ids;
    });
  }

  isRead() {
    return this.message.read_by_user_ids.includes(this.loggedInUserId);
  }

  getReadOrUnreadText() {
    return this.isRead() ? 'Mark Unread' : 'Mark Read';
  }

  toggleMessageStatus() {
    if (this.isRead()) {
      this.markUnread();
    } else {
      this.markRead();
    }
  }

  private getReplyText(): string {
    const messageCreatorName = this.message.created_by_last_name + ', ' + this.message.created_by_first_name;
    const createdDateTime = moment(this.message.created_datetime).format('LLL');
    return (
      '\n\n------------------------------------------------------------------------------------------------\n\n' +
      'On ' +
      createdDateTime +
      ', by ' +
      messageCreatorName +
      '\n\n' +
      this.message.content
    );
  }
}
