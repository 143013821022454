<div class="m-portlet__body item ease p-3 pointer" [routerLink]="['/meetings/' + meeting.id]">
  <div class="row align-items-center">
    <div class="col">
      <button mat-icon-button class="ltblue mr-2">
        <mat-icon>today</mat-icon>
      </button>
      <h5 class="dkblue font-weight-semibold m-0 ml-1 d-inline-block align-middle">
        <span class="mr-2">
          {{ meeting.title }}
        </span>
        <br />
        <small class="gray">
          {{ meeting.location }}
        </small>
      </h5>
    </div>
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold">
        {{ getDayTime() }}
        <span class="badge badge-secondary ml-2 opacity5" *ngIf="getFrequency() !== null">
          {{ getFrequency() }}
        </span>
      </h6>
    </div>
    <div class="col">
      <h6 class="m-0 ltblue font-weight-normal d-inline-block align-middle">
        <b>
          {{ meeting.attendee_count }}
        </b>
        Attendees
      </h6>
    </div>
    <div class="col">
      <h6 class="m-0 dkblue">
        <b>
          {{ meeting.agenda_count }}
        </b>
        Agenda Items
      </h6>
    </div>
  </div>
</div>
