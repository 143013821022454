<app-base-dialog [title]="'Create Task'" (closeDialog)="cancel()"></app-base-dialog>
<div id="create-task-dialog" data-test-id="create-task-dialog" class="create-task-dialog project-modal p-2">
  <div class="row">
    <div class="col-6">
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue mb-2">Title *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput placeholder="What needs to be done" [(ngModel)]="taskTitle" />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="phases?.length > 1" class="row mb-4">
        <div class="col">
          <h6 class="dkblue mb-2">Phase *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select
              matNativeControl
              [(value)]="selectedPhase"
              (selectionChange)="selectPhase($event.value)"
              (blur)="isPhase = false"
            >
              <mat-option *ngFor="let p of phases" [value]="p">{{ p.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="isPhase === false && !selectedPhase">Phase is required</mat-error>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue mb-2">Milestone *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select
              matNativeControl
              [(value)]="selectedMilestone"
              (selectionChange)="selectedMilestone = $event.value; isMilestone = true"
              [disabled]="selectedPhase == null"
              (blur)="isMilestone = false"
            >
              <mat-option *ngFor="let m of milestones" [value]="m">{{ m.name }}</mat-option>
              <mat-option (click)="openAddMilestoneDialog()" [value]="null"> + Add Milestone</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="isMilestone === false && !selectedMilestone">Milestone is required</mat-error>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col pr-3">
          <h6 class="dkblue mb-2">Assigned To</h6>
          <app-assign-user-button [task]="data || {}" (userSelected)="assignUser($event)"></app-assign-user-button>
        </div>
        <div class="col pl-3 d-flex flex-column">
          <h6 class="dkblue mb-2">Due Date</h6>

          <button matRipple (click)="picker.open()" class="m-portlet m-0 p-0 full-width text-left">
            <div class="m-portlet__body p-2 item item-last d-flex align-items-center">
              <button disabled mat-icon-button color="primary" class="mr-2">
                <!-- SHOW OVERLAY ICON HERE IF OVERDUE -->
                <mat-icon
                  [ngClass]="{ dkgray: dueDate, ltgray: !dueDate }"
                  style="margin-top: 6px; font-size: 30px; height: 30px; width: 30px"
                >
                  event
                </mat-icon>
              </button>
              <p class="m-0 d-flex flex-column lh-18" *ngIf="dueDate">
                <span class="dkblue font-weight-semibold">
                  {{ dueDate | date: 'EEEE' }}
                </span>
                <span class="dkgray font-weight-normal">
                  {{ dueDate | date: 'MMM d y' }}
                </span>
              </p>
              <p class="m-0 d-flex flex-column lh-18" *ngIf="!dueDate">
                <span class="gray font-weight-semibold"> Set Due Date </span>
              </p>
            </div>
          </button>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="dueDate"
            autocomplete="off"
            style="visibility: hidden; width: 0; height: 0"
          />
          <mat-datepicker #picker [calendarHeaderComponent]="customHeader"></mat-datepicker>
          <!-- <mat-form-field appearance="outline" class="full-width">
            <input matInput [matDatepicker]="picker" placeholder="When is it due?" [(ngModel)]="dueDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          </mat-form-field> -->
        </div>
      </div>
      <div class="row mb-3">
        <div class="col task-editor">
          <h6 class="dkblue mb-2">Description</h6>
          <app-editor #taskDescriptionEditor placeholder="What do you need to clarify?"></app-editor>
        </div>
      </div>
    </div>
    <div class="col-6 d-flex flex-column">
      <div class="d-flex mb-2">
        <h6
          class="dkblue mb-0 mr-auto"
          *ngIf="accessoryData?.reviewChain?.length || (!accessoryData?.reviewChain?.length && !isReviewersLocked)"
        >
          Review / Signature
        </h6>
        <button
          *ngIf="accessoryData?.reviewChain?.length"
          mat-button
          color="accent"
          type="button"
          class="ml-3 lh-20 px-2"
          (click)="addReviewClicked()"
        >
          {{ isReviewersLocked ? 'View' : 'Edit' }}
        </button>
      </div>
      <div class="m-portlet d-flex flex-column mb-4 p-3" *ngIf="accessoryData?.reviewChain?.length">
        <h6 class="mb-2 dkblue">File{{ reviewFiles.length > 1 ? 's' : '' }} to Review</h6>
        <mat-chip-list class="d-flex mb-4">
          <app-file-chip *ngFor="let file of reviewFiles" [file]="file"></app-file-chip>
        </mat-chip-list>
        <h6 class="dkblue mb-2">Reviewer{{ accessoryData.reviewChain?.length > 1 ? 's' : '' }}</h6>
        <div
          class="m-portlet p-2 br-6 border border-gray d-flex flex-column mb-2"
          *ngFor="let reviewer of accessoryData?.reviewChain"
        >
          <div class="d-flex align-items-center">
            <app-profile-thumbnail
              class="pointer pr-2"
              [matTooltip]="reviewer.first_name + ' ' + reviewer.last_name"
              [width]="34"
              [height]="34"
              [userId]="reviewer?.id"
            ></app-profile-thumbnail>
            <div class="d-flex flex-column mr-auto text-truncate">
              <p class="dkblue font-weight-semibold m-0 text-truncate lh-16">
                {{ reviewer.first_name }} {{ reviewer.last_name }}
              </p>
              <h6 class="font-weight-normal dkgray m-0 text-truncate lh-16">
                {{ reviewer.title || 'No Title' }}
              </h6>
            </div>
            <div *ngIf="reviewer.needs_signature" class="d-flex flex-column ml-3 align-items-end px-2">
              <span class="material-icons ltblue"> history_edu </span>
            </div>
          </div>
        </div>
        <h6 class="dkblue mt-3 mb-2">Turnaround Time</h6>
        <h5 class="dkblue font-weight-normal mb-0">
          {{ reviewTurnaround || 'Same' }} Day{{ reviewTurnaround > 1 ? 's' : '' }}
        </h5>
        <!-- <div class="col">
          <div class="col mb-4" *ngIf="reviewFiles?.length">
            <h6 class="row">File{{ reviewFiles.length > 1 ? 's' : '' }} to Review</h6>
            <mat-chip-list class="d-inline-block align-middle mt-2">
              <app-file-chip *ngFor="let file of reviewFiles" [file]="file"></app-file-chip>
            </mat-chip-list>
          </div>
          <div class="col mb-4" *ngIf="accessoryData?.reviewChain?.length">
            <h6 class="row">Reviewer{{ accessoryData.reviewChain?.length > 1 ? 's' : '' }}</h6>
            <div class="row">
              <app-profile-thumbnail
                *ngFor="let reviewer of accessoryData?.reviewChain"
                class="pointer pr-2"
                [width]="40"
                [height]="40"
                [matTooltip]="reviewer.first_name + ' ' + reviewer.last_name"
                [userId]="reviewer?.id"
                [overlayIcon]="reviewer.needs_signature ? 'circle' : null"
                [overlayIconColor]="'accent'"
              ></app-profile-thumbnail>
            </div>
          </div>
          <div class="col" *ngIf="reviewTurnaround">
            <h6 class="row">Turnaround Time</h6>
            {{ reviewTurnaround }} Day{{ reviewTurnaround > 1 ? 's' : '' }}
          </div>
          <div class="row mb-3">
            <div class="col">
              <button
                mat-button
                type="button"
                color="success"
                class="ml-4 mt-3 lh-30"
                style="max-width: 115px"
                (click)="addReviewClicked()"
              >
                {{ isReviewersLocked ? 'View Details' : '+ Reviewers' }}
              </button>
            </div>
          </div>
        </div> -->
      </div>
      <div
        class="m-portlet mb-4 p-3 bg-shade-accent text-center add-review"
        *ngIf="!accessoryData?.reviewChain?.length && !isReviewersLocked"
        (click)="addReviewClicked()"
      >
        <p class="accent mx-auto my-0 font-weight-semibold hover-u my-2" *ngIf="!accessoryData?.reviewChain?.length">
          Request Review or Signature
        </p>
      </div>

      <div class="row mb-4">
        <div class="col">
          <h6 class="mb-2 dkblue">Followers</h6>
          <app-profile-thumbnail
            [width]="36"
            [height]="36"
            [userId]="follower.id"
            class="follower mr-3"
            *ngFor="let follower of followers"
            [overlayIconTooltip]="'Remove Follower'"
            [overlayIconOpacity]="0.7"
            [overlayIcon]="'clear'"
            [overlayIconColor]="'white'"
            [overlayIconBackground]="'bg-gray'"
            (click)="removeFollower(follower.id)"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
          </app-profile-thumbnail>
          <button
            mat-icon-button
            (click)="addFollowers()"
            type="button"
            color="accent"
            class="d-inline-block align-middle"
            *ngIf="followers?.length"
          >
            <mat-icon>group_add</mat-icon>
          </button>
          <button mat-button (click)="addFollowers()" type="button" color="accent" *ngIf="!followers?.length">
            + Followers
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col task-editor">
          <h6 class="dkblue mb-2">Note / Attachments</h6>
          <app-editor #taskCommentEditor placeholder="Add a comment..."></app-editor>
          <button
            mat-icon-button
            color="accent"
            type="button"
            class="mt-2 mb-2"
            (click)="openFileAttachmentDialog()"
            *ngIf="areFilesLinked"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <mat-chip-list class="mt-2 mb-2 d-inline-block align-middle" *ngIf="areFilesLinked">
            <app-file-chip
              class="mr-2"
              *ngFor="let file of attachedFiles"
              [file]="file"
              [removable]="true"
              (removeEvent)="removeFile(file)"
            ></app-file-chip>
          </mat-chip-list>
          <mat-chip-list class="mt-2 mb-2 d-inline-block align-middle" *ngIf="!areFilesLinked">
            <app-file-chip
              class="mr-2"
              *ngFor="let file of attachedFiles"
              [file]="file"
              [removable]="!isReviewersLocked"
              (removeEvent)="remove(file)"
            ></app-file-chip>
          </mat-chip-list>
          <mat-chip-list class="mt-2 mb-2 d-inline-block align-middle">
            <app-file-chip class="mr-2" *ngFor="let file of linkedFiles" [file]="file"></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-2" style="margin-top: 0.5rem">
  <div class="col text-right">
    <button data-test-id="create-task-dialog-cancel-button" (click)="cancel()" mat-button type="button" class="gray">
      Cancel
    </button>
    <button
      mat-flat-button
      type="submit"
      color="success"
      class="ml-3"
      (click)="createTask()"
      [disabled]="!formIsValid()"
    >
      Create Task
    </button>
  </div>
</div>
