import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectStatus } from 'src/app/enums';
import {
  LinkedTaskService,
  NotificationService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  SearchService,
  SidenavLinksService,
  SidenavService,
} from 'src/app/services';
import { Project } from 'src/app/types';

@Component({
  selector: 'app-base-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  public project: Project;
  public projectId: number;
  public subcomponent = '';

  constructor(
    private _progressIndicatorService: ProgressIndicatorService,
    private _projectOverviewService: ProjectOverviewService,
    private _projectService: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackbar: MatSnackBar,
    public notificationService: NotificationService,
    public searchService: SearchService,
    private sidenavLinksService: SidenavLinksService,
    private _sidenavService: SidenavService,
    private linkedTaskService: LinkedTaskService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.projectView);
    });

    this._route.params.subscribe(async (params: Params) => {
      if (params?.id) {
        this.projectId = params?.id;
        let selectedTaskId = params.task_id;
        // now grabs the correct project id based on the selected task if there is one
        // this fixes an issue where going to a different task by notification would use the old project id
        // due to selectProjectById being called before the project id was refreshed
        if (selectedTaskId) {
          const task = await this._projectService.getTaskById(selectedTaskId, ['project_id']).toPromise();
          this.projectId = task.project_id;
        } else if (params.linked_task_id) {
          selectedTaskId = params.linked_task_id;
          const task = await this.linkedTaskService.getLinkedWOTask(selectedTaskId).toPromise();
          this.projectId = task.project_id;
        }
        // subcomponent selection must happen after project selection (which happens in refresh)
        await this._refresh();
        let foundSubcomponent;
        if (params?.task_id || params?.linked_task_id) {
          foundSubcomponent = 'tasks';
        }
        this.subcomponent = foundSubcomponent || params?.subcomponent || '';
      }
    });
  }

  get isSidenavClosed(): boolean {
    return this._sidenavService?.isSidenavClosed;
  }

  get isClosedProject(): boolean {
    return +this.project?.status_id === ProjectStatus.CLOSED;
  }

  private async _refresh() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Refreshing...');
    this.project = await this._projectService.selectProjectById(this.projectId, null, true);

    this._progressIndicatorService.close();
  }

  public async reActivateProject(message = '') {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Re-Activating Project...');
    const reactivatedProject = await this._projectService.reActivate(this.projectId).toPromise();
    if (+reactivatedProject.status_id === ProjectStatus.ACTIVE) {
      // document it as a project update
      this._progressIndicatorService.updateStatus('Creating a Project Update...');
      const projectUpdate = await this._projectOverviewService
        .createProjectUpdate({
          message,
          project_id: +this.projectId,
          access: JSON.stringify([1, 2]),
          notify_followers: 1,
          project_health_type_id: null,
        })
        .toPromise();
    }

    await this._refresh();
    this._progressIndicatorService.close();
  }

  async setProjectToActive() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Setting to active...');
    await this._projectService.updateProject(this.projectId, { status_id: 1 }).toPromise();
    await this._refresh();
    this._progressIndicatorService.close();
  }

  async markAsPlanned() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Marking as planned...');
    await this._projectService.updateProject(this.projectId, { status_id: 2 }).toPromise();
    await this._refresh();
    this._progressIndicatorService.close();
  }

  async placeOnHold() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Placing on hold...');
    await this._projectService.updateProject(this.projectId, { status_id: 3 }).toPromise();
    await this._refresh();
    this._progressIndicatorService.close();
  }

  async closeProject() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Closing project...');
    await this._projectService.updateProject(this.projectId, { status_id: 4 }).toPromise();
    await this._refresh();
    this._progressIndicatorService.close();
  }
}
