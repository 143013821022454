import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent implements OnInit {
  @Input() draggable = true;
  @Input() title = '';
  @Output() closeDialog = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.closeDialog.emit();
  }
}
