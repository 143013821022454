<mat-form-field appearance="outline">
  <input
    matInput
    matAutocompletePosition="below"
    #company
    type="text"
    [placeholder]="title"
    [formControl]="control"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="control.value" matSuffix (click)="clear($event)" class="gray pointer">cancel</mat-icon>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option [value]="null">None</mat-option>
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors">*{{ errorMessage() }}</mat-error>
</mat-form-field>
