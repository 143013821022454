import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { Conversation } from 'src/app/models';
import { ActivityType, EventType, ResourceType, TaskStatus, Workspace } from 'src/app/enums';
import { TasksFilter } from 'src/app/pipes';
import {
  ApiFilterService,
  AuthService,
  FileApprovalService,
  FileService,
  HandleErrorService,
  LinkedTaskService,
  // MessageService,
  // MessagingSystemService,
  ProjectEventService,
  ProjectService,
  UserService,
} from 'src/app/services';
import {
  APIFilter,
  Event,
  Invoice,
  LinkedWOTask,
  Milestone,
  Note,
  ProjectTenant,
  Quote,
  ServiceResponse,
  Task,
  TaskActivity,
  UhatFileReference,
  User,
} from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectTaskService {
  public static eventUrl = `${environment.serviceHost}/api/v1/events`;

  public static taskUrl = `${environment.serviceHost}/api/v1/tasks`;

  private static milestoneUrl = `${environment.serviceHost}/api/v1/milestones`;

  host: string = environment.serviceHost;

  eventFields = `id,created_datetime,created_by_first_name,created_by_id,created_by_last_name,message,related_id,comment,staff_only`;

  taskFields =
    'id,code,title,description,milestone_id,milestone_sequence,status_id,status_name,assigned_user_id,assigned_user_first_name,assigned_user_last_name,assigned_user_login_enabled,assigned_user_type_id,due_date,note_count,file_count,files,created_by_id,modified_by_id,phase_id,phase_name,project_id,project_code,project_title,follower_ids,followers,file_approvals,is_locked,accessory_data,display_order_hash,can_delete,review_turnaround,task_reminder_sender,task_reminder_datetime';

  private selectedTask: Task;

  private tasks = new Map<number, BehaviorSubject<Array<Task>>>();

  private taskFollowers = new Map<number, BehaviorSubject<Array<User>>>();

  private taskActivityLog = new Map<number, BehaviorSubject<Array<TaskActivity>>>();

  // keeps track of the activity keys so keep the activities unique
  private activityKeys = {};
  public taskReviewChanged = new EventEmitter();
  public taskSelectedEvent = new EventEmitter<{ task: Task; navigate?: boolean; focusAssigneeInput?: boolean }>();
  public linkedWOTaskSelectedEvent = new EventEmitter<{ task: LinkedWOTask; navigate?: boolean }>();
  public closeTask = new EventEmitter<{ close: boolean; taskId?: number; projectId?: number }>();
  public milestoneTaskEvent = new EventEmitter<Task | LinkedWOTask>();
  public invoiceUpdatedInTask = new EventEmitter<Invoice>();

  public filterSettings: TasksFilter = new TasksFilter();
  public quote: Quote;
  public tenant: ProjectTenant;

  constructor(
    private projectService: ProjectService,
    // private messagingSystemService: MessagingSystemService,
    private fileService: FileService,
    // private messageService: MessageService,
    private handleErrorService: HandleErrorService,
    private http: HttpClient,
    private location: Location,
    private approvalService: FileApprovalService,
    private eventService: ProjectEventService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private linkedTaskService: LinkedTaskService,
    private snackbarService: MatSnackBar,
    private apiFilterService: ApiFilterService
  ) {
    // We want to keep the selected task up to date in here and update the task url when a task is selected. Lets subscribe to that event.
    this.taskSelectedEvent.subscribe((data) => {
      if (data && data.navigate) {
        this.selectedTask = data.task;
        // Update URL with the new selected task id
        this.location.replaceState(
          `projects/${this.projectService.currentSelectedProjectId}/tasks/${this.selectedTask ? data.task.id : ''}`
        );
      } else if (!data) {
        this.selectedTask = null;
      }
    });
    // We also want to add task activity when a conversation is created that is linked to a task. Lets subscribe to the conversationCreatedEvent
    // this.messagingSystemService.events.conversationCreatedEvent.subscribe((conversation) => {
    //   if (conversation.task_id) {
    //     this.addTaskActivityFromConversation(conversation.task_id, conversation);
    //   }
    // });
  }

  get currentSelectedTask() {
    return this.selectedTask;
  }

  /** Load tasks from API call into the observable associated with all passed milestone ids */
  public loadTasksForMilestones(milestoneIds: number[], workspaceId: Workspace) {
    const observables = {};

    this.projectService.getTasksByMilestoneIds(milestoneIds).subscribe((milestones) => {
      if (
        (this.authService.isAppAdmin ||
          this.authService.isUserWorkspaceAdmin(workspaceId) ||
          this.authService.isUserWorkspaceStaff(workspaceId)) &&
        milestones.filter((t) => t.display_order_hash == null).length > 0
      ) {
        let currentMax = 5000000;
        // if we have any current values, grab the maximum to start from (so as to not place tasks in the middle of an existing list)
        if (milestones.filter((t) => t.display_order_hash != null).length > 0) {
          currentMax = Math.max(
            ...milestones.filter((m) => m.display_order_hash != null).map((m) => m.display_order_hash)
          );
        }
        for (let i = 0; i < milestones.length; i++) {
          if (milestones[i].display_order_hash == null) {
            // the storage space for the display hash is 10^11, so 100k space in between supports up to 1m tasks
            milestones[i].display_order_hash = currentMax + i * 100000;
            this.updateMilestoneDisplayOrderHash(milestones[i].id, milestones[i].display_order_hash).subscribe();
          }
        }
      }

      milestones.forEach((milestone) => {
        // If the tasks for this milestone have no had their display order hash set, set them (this is to make us not have to manually set them all for the conversion)
        if (
          (this.authService.isAppAdmin ||
            this.authService.isUserWorkspaceAdmin(workspaceId) ||
            this.authService.isUserWorkspaceStaff(workspaceId)) &&
          milestone.tasks.filter((t: Task) => t.display_order_hash == null).length > 0
        ) {
          let currentMax = 5000000;
          // if we have any current values, grab the maximum to start from (so as to not place tasks in the middle of an existing list)
          if (milestone.tasks.filter((t) => t.display_order_hash != null).length > 0) {
            currentMax = Math.max(
              ...milestone.tasks.filter((t) => t.display_order_hash != null).map((task) => task.display_order_hash)
            );
          }
          for (let i = 0; i < milestone.tasks.length; i++) {
            if (milestone.tasks[i].display_order_hash == null) {
              // the storage space for the display hash is 10^11, so 100k space in between supports up to 1m tasks
              milestone.tasks[i].display_order_hash = currentMax + i * 100000;
              this.updateTaskDisplayOrderHash(milestone.tasks[i].id, milestone.tasks[i].display_order_hash).subscribe();
            }
          }
        }

        if (milestone.tasks.length > 0) {
          observables[milestone.id] = this.getCreateMapValueIfNeeded(milestone.id);
          observables[milestone.id].next(milestone.tasks);
        }
      });
    });
  }

  /** Sorts tasks from API call into the observable associated with all passed milestone ids */
  public sortTasksForMilestones(milestones: Milestone[]) {
    const observables = {};

    if (
      (this.authService.isAppAdmin ||
        this.authService.isUserWorkspaceAdmin(this.projectService.currentSelectedProject?.module_id) ||
        this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id)) &&
      milestones.filter((t) => t.display_order_hash == null).length > 0
    ) {
      let currentMax = 5000000;
      // if we have any current values, grab the maximum to start from (so as to not place tasks in the middle of an existing list)
      if (milestones.filter((t) => t.display_order_hash != null).length > 0) {
        currentMax = Math.max(
          ...milestones.filter((m) => m.display_order_hash != null).map((m) => m.display_order_hash)
        );
      }
      for (let i = 0; i < milestones.length; i++) {
        if (milestones[i].display_order_hash == null) {
          // the storage space for the display hash is 10^11, so 100k space in between supports up to 1m tasks
          milestones[i].display_order_hash = currentMax + i * 100000;
          this.updateMilestoneDisplayOrderHash(milestones[i].id, milestones[i].display_order_hash).subscribe();
        }
      }

      milestones.forEach((milestone) => {
        // If the tasks for this milestone have no had their display order hash set, set them (this is to make us not have to manually set them all for the conversion)
        if (
          (this.authService.isAppAdmin ||
            this.authService.isUserWorkspaceAdmin(this.projectService.currentSelectedProject?.module_id) ||
            this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id)) &&
          milestone.tasks &&
          milestone.tasks.filter((t: Task) => t.display_order_hash == null).length > 0
        ) {
          // if we have any current values, grab the maximum to start from (so as to not place tasks in the middle of an existing list)
          if (milestone.tasks.filter((t) => t.display_order_hash != null).length > 0) {
            currentMax = Math.max(
              ...milestone.tasks.filter((t) => t.display_order_hash != null).map((task) => task.display_order_hash)
            );
          }
          for (let i = 0; i < milestone.tasks.length; i++) {
            if (milestone.tasks[i].display_order_hash == null) {
              // the storage space for the display hash is 10^11, so 100k space in between supports up to 1m tasks
              milestone.tasks[i].display_order_hash = currentMax + i * 100000;
              this.updateTaskDisplayOrderHash(milestone.tasks[i].id, milestone.tasks[i].display_order_hash).subscribe();
            }
          }
        }

        if (milestone.tasks && milestone.tasks.length > 0) {
          observables[milestone.id] = this.getCreateMapValueIfNeeded(milestone.id);
          observables[milestone.id].next(milestone.tasks);
        }
      });
    }
  }

  // makes the api call to move the current node in between the before and after node
  public moveNodeBefore(movedNode, newBeforeNode, newAfterNode) {
    if (movedNode) {
      movedNode = { id: movedNode.id, display_order_hash: movedNode.display_order_hash };
    }
    if (newBeforeNode) {
      newBeforeNode = {
        id: newBeforeNode.id,
        display_order_hash: newBeforeNode.display_order_hash,
      };
    }
    if (newAfterNode) {
      newAfterNode = { id: newAfterNode.id, display_order_hash: newAfterNode.display_order_hash };
    }
    return this.http
      .put(`${ProjectTaskService.taskUrl}/moveNodeBefore`, {
        movedNode,
        newBeforeNode,
        newAfterNode,
      })
      .subscribe();
  }

  public clearTasksForProject() {
    this.tasks.clear();
  }

  /** Loads all tasks for a given set of user ids, where the given user ids are part of the follower ids, assigned user ids, or created by ids */
  public getTasksByUserIds(userIds: number[]): Observable<User[]> {
    const ids = userIds.join('^');
    return this.http
      .get(
        `${ProjectTaskService.taskUrl}?fields=${this.taskFields}&filter=follower_ids=${ids}|assigned_user_id=${ids}|created_by_id=${ids}&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => result.data.tasks),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  /** Loads all tasks for a given set of project ids */
  public getTasksByProjectIds(projectIds: number[]): Observable<Task[]> {
    const ids = projectIds.join('^');
    return this.http.get(`${ProjectTaskService.taskUrl}?fields=${this.taskFields}&filter=project_id=${ids}`).pipe(
      map((result: ServiceResponse) => result.data.tasks),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Called to add a follower to a task. This function makes the API to push the follower to the database
   * If possible, use addFullFollowerToTask in order to avoid another database query
   */
  public addFollowerToTask(taskId: number, followerId: number): Observable<any> {
    // add the task followers to the db
    return this.http
      .put(`${ProjectTaskService.taskUrl}/${taskId}/followers`, {
        action: 'add',
        follower_id: +followerId,
      })
      .pipe(
        map((result: ServiceResponse) => {
          this.loadFollowers(taskId);
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  /** Add multiple followers to a task
   */
  public async addFollowersToTask(taskId: number, userIds: number[]) {
    for (const id of userIds) {
      await this.addFollowerToTask(taskId, id).toPromise();
    }
  }

  removeFollowerFromTask(taskId: number, followerId: number) {
    // add the task followers to the db
    return this.http
      .put(`${ProjectTaskService.taskUrl}/${taskId}/followers`, {
        action: 'remove',
        follower_id: +followerId,
      })
      .pipe(
        map((result: ServiceResponse) => {
          this.loadFollowers(taskId);
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getFollowerIdsByProjectId(projectId: number): Observable<number[]> {
    return this.http.get(`${ProjectTaskService.taskUrl}?fields=follower_ids&filter=project_id=${projectId}`).pipe(
      map((result: ServiceResponse) => {
        const followers = result.data.tasks;
        const ret = new BehaviorSubject<number[]>([]);
        // grab each tasks followers
        followers.forEach((data) => {
          // remove the brackets of the string array
          let arr = data.follower_ids.substr(1, data.follower_ids.length - 2);
          // if there's anything left, split by commas, and add unique value to ret
          if (arr.length > 0) {
            arr = arr.split(',');
            const current: number[] = ret.value;
            arr.forEach((value) => {
              if (!current.includes(value)) {
                current.push(value);
              }
            });
            ret.next(current);
          }
        });
        return ret.getValue();
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Resets the review chain of a task so that all statuses are set to Pending
   *  Also changes other fields including assigned_user due_date and status_id
   */
  public resetTaskReviewers(taskId: number, message: string, fields): Observable<any> {
    return this.http
      .put(`${ProjectTaskService.taskUrl}/${taskId}/reset-review?${fields ? `fields=${fields.join(',')}` : ''}`, {
        message,
      })
      .pipe(
        map((result: ServiceResponse) => {
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // returns all user ids for a project that are the assignee of a task
  getAssignedIdsByProjectId(projectId: number): Observable<number[]> {
    return this.http.get(`${ProjectTaskService.taskUrl}?fields=assigned_user_id&filter=project_id=${projectId}`).pipe(
      map((result: ServiceResponse) => {
        const ids = [];
        result.data.tasks.forEach((task) => {
          if (task.assigned_user_id != null && !ids.includes(task.assigned_user_id)) {
            ids.push(task.assigned_user_id);
          }
        });
        return ids;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // returns the ids for cfmo/pm/wm/architect/engineers for a project
  getOtherIdsByProjectId(
    projectId: number
  ): Observable<{ cfmo: number; pm: number; wm: number; architect: number; eng: number[] }> {
    return this.http
      .get(
        `${this.projectService.projectUrl}?fields=cfmo_id,project_manager_id,workspace_manager_id,architect_id,engineer_ids&filter=id=${projectId}?limit=1`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const project = result.data.projects[0];
          if (project) {
            const engs =
              project && project.engineer_ids
                ? project.engineer_ids
                    .substr(1, project.engineer_ids.length - 2)
                    .split(',')
                    .map((value) => +value)
                : [];
            return {
              cfmo: project.cfmo_id,
              pm: project.project_manager_id,
              wm: project.workspace_manager_id,
              architect: project.architect_id,
              eng: engs,
            };
          } else {
            return { cfmo: null, pm: null, wm: null, architect: null, eng: [] };
          }
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  /** Forces an update of the tasks followers, which updates the relevant BehaviorSubject of the taskFollowers object */
  public loadFollowers(taskId: number) {
    // grab all the followers for this task from the database
    this.http
      .get(`${ProjectTaskService.taskUrl}/${taskId}?fields=${this.taskFields}`)
      .pipe(
        map((result: ServiceResponse) => {
          return result?.data?.task[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe(async (arr) => {
        // once we get values for the followers back
        const approvedFollowers = await this.getApprovedFollowers(arr.followers ?? []);
        if (this.taskFollowers.has(taskId)) {
          this.taskFollowers.get(taskId).next(approvedFollowers);
        } else {
          this.taskFollowers.set(taskId, new BehaviorSubject<Array<User>>(approvedFollowers));
        }
      });
  }

  private async getApprovedFollowers(followers) {
    let approvedFollowers = followers;
    if (!this.authService.isStaffOnAnyModule) {
      for (const follower of followers) {
        await this.userService
          .getUserByIdSuppressed(follower.id)
          .toPromise()
          .then((res) => {})
          .catch((e) => {
            console.error('Access Denied');
            approvedFollowers = approvedFollowers.filter((currentFollower) => currentFollower.id !== follower.id);
          });
      }
    }
    return approvedFollowers;
  }

  /** Returns the appropriate BehaviorSubject for the task as an observable */
  public getFollowersObservable(taskId: number): Observable<Array<User>> {
    // if we don't have an array for the task, add an empty one
    if (!this.taskFollowers.has(taskId)) {
      this.taskFollowers.set(taskId, new BehaviorSubject<Array<User>>([]));
    }
    return this.taskFollowers.get(taskId).asObservable();
  }

  /** Returns the observable for the activity log */
  public getTaskActivityObservable(taskId: number): Observable<Array<TaskActivity>> {
    if (!this.taskActivityLog.has(taskId)) {
      this.taskActivityLog.set(taskId, new BehaviorSubject<Array<TaskActivity>>([]));
    }
    return this.taskActivityLog.get(taskId).asObservable();
  }

  public async getMostRecentTaskActivityFile(taskId: number): Promise<UhatFileReference[]> {
    const data = this.taskActivityLog.get(taskId);
    if (!data) {
      await this.loadTaskActivityLogAsync({
        id: taskId,
        file_approvals: null,
      });
    }
    const activities: TaskActivity[] = this.taskActivityLog.get(taskId).value;
    return activities.filter((a) => !!a.noteFiles)[0]?.noteFiles;
  }

  public async loadTaskActivityLogAsync(task: Task) {
    const taskId = task.id;
    const taskApprovals = task.file_approvals;
    if (!this.taskActivityLog.has(taskId)) {
      this.taskActivityLog.set(taskId, new BehaviorSubject<Array<TaskActivity>>([]));
    }
    await this.projectService
      .loadTaskActivity(taskId)
      .toPromise()
      .then((data) => {
        data.notes.forEach((element) => this.addTaskActivityFromNote(taskId, element));
        // data.conversations.forEach((element) =>
        //   this.addTaskActivityFromConversation(taskId, element)
        // );
        data.events.forEach((element) => this.addTaskActivityFromEvent(taskId, element));
      });
  }

  /** Load task data, which includes the associated note information (including any files linked to those notes),
   * as well as the conversation data, and any event data.
   * The function returns an Obversable containing an array of these TaskActivities.
   * In essence, this function serves to conglomerate all the activity log data into one structure.
   */
  public async loadTaskActivityLog(task: Task) {
    const taskId = task.id;
    const taskApprovals = task.file_approvals;
    if (!this.taskActivityLog.has(taskId)) {
      this.taskActivityLog.set(taskId, new BehaviorSubject<Array<TaskActivity>>([]));
    }

    this.projectService.loadTaskActivity(taskId).subscribe((data) => {
      data.notes.forEach((element) => this.addTaskActivityFromNote(taskId, element));
      // data.conversations.forEach((element) =>
      //   this.addTaskActivityFromConversation(taskId, element)
      // );
      data.events.forEach((element) => this.addTaskActivityFromEvent(taskId, element));
    });

    // for each approval event
    // it seems as though the below is replaced with the review chain and normals events, so I think this can be removed
    // I did some testing and could not find any locations with file_approvals, so add the below back in if this isn't the case
    // TODO we should probably also remove the file-approvals service since it too is obselete with the new reviewchain
    // if (taskApprovals) {
    //   taskApprovals.forEach((approval) => {
    //     this.approvalService.getApprovalActivity(approval.id).subscribe(async (events) => {
    //       if (events) {
    //         const ids = [];
    //         const eventsByUserId = {};
    //         events.forEach((event) => {
    //           // ids to look for
    //           if (!ids.includes(event.created_by_id)) {
    //             ids.push(event.created_by_id);
    //           }
    //           // events group by user id
    //           if (!eventsByUserId[event.created_by_id]) {
    //             eventsByUserId[event.created_by_id] = [];
    //           }
    //           eventsByUserId[event.created_by_id].push(event);
    //         });
    //
    //         // for each user id, populate all the events and push them to activity
    //         ids.forEach((id) =>
    //           this.userService.getUserById(id).subscribe((user) => {
    //             eventsByUserId[user.id].forEach((event) => {
    //               event.created_by_first_name = user.first_name;
    //               event.created_by_last_name = user.last_name;
    //               this.approvalService.getRejectedFiles(event.related_id).subscribe((files) => {
    //                 event.rejected_files = files;
    //                 this.addTaskActivityFromApproval(taskId, event);
    //               });
    //             });
    //           })
    //         );
    //       }
    //     });
    //   });
    // }
  }

  // adds a TaskActivity to the TaskActivitiesLog for the given taskId
  public addActivityToLog(taskId: number, activity: TaskActivity) {
    // if we don't yet have an array for the unique keys of this type, create it
    if (!this.activityKeys[activity.type]) {
      this.activityKeys[activity.type] = [];
    }
    // if we don't have this key yet
    if (!this.activityKeys[activity.type].includes(activity.id)) {
      // add the key and push the activity to the log
      this.activityKeys[activity.type].push(activity.id);
      this.taskActivityLog.get(taskId).next(this.taskActivityLog.get(taskId).getValue().concat(activity));
    }
  }

  // Takes a note and adds a TaskActivity by passing along the relevant properties
  public addTaskActivityFromNote(taskId: number, note: Note) {
    this.addActivityToLog(taskId, {
      id: note.id,
      created_datetime: moment(note.created_datetime),
      created_by_first_name: note.created_by_first_name,
      created_by_id: note.created_by_id,
      created_by_last_name: note.created_by_last_name,
      type: ActivityType.Note,
      noteText: note.message,
      noteFiles: note.files,
      staffOnly: !!note.staff_only,
    });
  }
  // Takes a note and adds a TaskActivity by passing along the relevant properties
  // public addTaskActivityFromConversation(taskId: number, conv: Conversation) {
  //   this.addActivityToLog(taskId, {
  //     id: conv.id,
  //     created_datetime: moment(conv.created_datetime),
  //     created_by_first_name: conv.created_by_first_name,
  //     created_by_id: conv.created_by_id,
  //     created_by_last_name: conv.created_by_last_name,
  //     type: ActivityType.Conversation,
  //     conversationSubject: conv.subject,
  //   });
  // }
  // Takes a note and adds a TaskActivity by passing along the relevant properties
  public async addTaskActivityFromEvent(taskId: number, event: Event) {
    let eventType;
    switch (event.message) {
      case 'follow':
        eventType = EventType.FOLLOW;
        break;
      case 'unfollow':
        eventType = EventType.UNFOLLOW;
        break;
      case '2':
        eventType = EventType.APPROVED;
        break;
      case '3':
        eventType = EventType.REJECTED;
        break;
      case '4':
        eventType = EventType.AUDITED;
        break;
      case '5':
        eventType = EventType.FLAGGED;
        break;
      case '6':
        eventType = EventType.CONTRACTSIGNED;
        break;
      case '7':
        eventType = EventType.REVISIONREQUESTED;
        break;
      case '8':
        eventType = EventType.NOTEMARKING;
        break;
      case '9':
        eventType = EventType.NOEXCEPTIONSTAKEN;
        break;
      case '10':
        eventType = EventType.REVISEANDRESUBMIT;
        break;
      case 'complete':
        eventType = EventType.COMPLETED;
        break;
      case 'incomplete':
        eventType = EventType.INCOMPLETE;
        break;
      case 'on_hold':
        eventType = EventType.ONHOLD;
        break;
      case 'off_hold':
        eventType = EventType.OFFHOLD;
        break;
      case 'notification':
        eventType = EventType.NOTIFICATION;
        break;
      default:
        eventType = EventType.FOLLOW;
        break;
    }
    if (event.related_id) {
      this.userService
        .getUserByIdSuppressed(event.related_id)
        .toPromise()
        .then((user) => {
          this.addActivityToLog(taskId, {
            id: event.id,
            created_datetime: moment(event.created_datetime),
            created_by_first_name: event.created_by_first_name,
            created_by_id: event.created_by_id,
            created_by_last_name: event.created_by_last_name,
            related_id: event.related_id,
            related_first_name: user.first_name,
            related_last_name: user.last_name,
            type: ActivityType.Event,
            comment: event.comment,
            eventType,
            eventText: event.message,
            staffOnly: !!event.staff_only,
          });
        })
        .catch((e) => {
          console.error('Access Denied');
        });
    } else {
      const files = await this.fileService.getFilesByParentId(ResourceType.Event, event.id).toPromise();
      this.addActivityToLog(taskId, {
        id: event.id,
        created_datetime: moment(event.created_datetime),
        created_by_first_name: event.created_by_first_name,
        created_by_id: event.created_by_id,
        created_by_last_name: event.created_by_last_name,
        type: ActivityType.Event,
        comment: event.comment,
        noteFiles: files,
        eventType,
        eventText: event.message,
        staffOnly: !!event.staff_only,
      });
    }
  }
  // Takes an approval event and adds a TaskActivity by passing along the relevant properties
  public async addTaskActivityFromApproval(taskId: number, event: Event) {
    const eventType = +event.message as EventType;

    this.addActivityToLog(taskId, {
      id: event.id,
      created_datetime: moment(event.created_datetime),
      created_by_first_name: event.created_by_first_name,
      created_by_id: event.created_by_id,
      created_by_last_name: event.created_by_last_name,
      related_id: event.related_id,
      type: ActivityType.ApprovalItem,
      parent_id: event.parent_id,
      eventType,
      eventText: event.message,
      rejected_files: event.rejected_files,
      staffOnly: !!event.staff_only,
    });
  }

  async getLatestTaskActivityNote(taskId: number, includeNotes = false) {
    let latestActivity = null;

    if (taskId) {
      const activities = await this.projectService.loadTaskActivity(taskId).toPromise();

      const approvals = [];

      for (const event of activities.events) {
        if (event.message === '2') {
          event.files = await this.fileService.getFilesByParentId(ResourceType.Event, event.id).toPromise();

          event.type = EventType.APPROVED;
          if (event?.files?.length) {
            approvals.push(event);
          }
        }
      }

      const allApprovals = includeNotes ? activities.notes.concat(approvals) : approvals;

      // Loops through activities to grab the latest one.
      allApprovals.forEach((activity) => {
        if (!latestActivity && activity?.files?.length) {
          latestActivity = activity;
        } else if (activity?.files?.length) {
          const timeDiff = moment(latestActivity.created_datetime).diff(moment(activity.created_datetime));
          if (timeDiff <= 0) {
            latestActivity = activity;
          }
        }
      });
    }

    return latestActivity?.files;
  }

  public async changeTaskStatus(task: Task | LinkedWOTask, taskStatusId: TaskStatus, projectId?: number) {
    const oldTaskStatusId = task.status_id;
    const taskUpdateData: Task = { id: task.id, status_id: taskStatusId };
    let message: string;
    // If updating to complete we want to assign the task if it is not already assigned
    if (taskStatusId === TaskStatus.Complete) {
      if (task.assigned_user_id == null) {
        taskUpdateData.assigned_user_id = this.authService.getLoggedInUser().id;
      }
      message = 'complete';
    } else if (taskStatusId === TaskStatus.Pending && !this.authService.isStaffOnAnyModule) {
      if (projectId) {
        const projectDetails = await this.projectService.getProjectById(projectId, ['project_manager_id']).toPromise();
        taskUpdateData.assigned_user_id = projectDetails.project_manager_id;
      } else {
        taskUpdateData.assigned_user_id = this.projectService.currentSelectedProject.project_manager_id;
      }
      message = 'complete';
    }
    if (taskStatusId === TaskStatus.Open && oldTaskStatusId !== TaskStatus.OnHold) {
      message = 'incomplete';
    } else if (taskStatusId === TaskStatus.OnHold) {
      message = 'on_hold';
    } else if (taskStatusId === TaskStatus.Open && oldTaskStatusId === TaskStatus.OnHold) {
      message = 'off_hold';
    }
    await this.eventService
      .createTaskStatusEvent(task.id, message)
      .toPromise()
      .then(async (res) => {
        if (taskStatusId === TaskStatus.Pending && !this.authService.isStaffOnAnyModule) {
          await this.eventService
            .createTaskStatusEvent(task.id, 'notification', taskUpdateData.assigned_user_id)
            .toPromise();
        }
      });
    // Call the updateTask API call
    return taskUpdateData;
  }

  // TODO the following function should maybe be refactored into project-events.service.ts
  /** Loads the events associated with a task */
  public getEventsForTaskId(taskId: number, eventType?: string): Observable<Event[]> {
    const filterByEvent = eventType ? `,message=${eventType}` : '';

    return this.http
      .get(
        `${ProjectTaskService.eventUrl}?filter=parent_id=${taskId},parent_type_id=7${filterByEvent}=${eventType}&fields=${this.eventFields}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const events: Event[] = result.data.events;
          return events;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  /** Load A Single Task From API Call */
  public loadTask(taskId: number, fields?: string[]): Observable<Task> {
    const obs = fields ? this.projectService.getTaskById(taskId, fields) : this.projectService.getTaskById(taskId);
    obs.toPromise().then((task) => {
      if (task) {
        this.updateTask(task);
      }
    });

    return obs;
  }

  /** Load A Single Linked WO Task From API Call */
  public loadLinkedWOTask(taskId: number): Observable<LinkedWOTask> {
    const obs = this.linkedTaskService.getLinkedWOTask(taskId);
    obs.toPromise().then((task) => {
      if (task) {
        this.updateLinkedWOTask(task);
      }
    });

    return obs;
  }

  /** Load A Single Task From API Call */
  public loadTaskSuppressed(taskId: number, fields?: string[]): Observable<Task> {
    const obs = fields
      ? this.projectService.getTaskByIdSuppressed(taskId, fields)
      : this.projectService.getTaskByIdSuppressed(taskId);
    obs.toPromise().then((task) => {
      if (task) {
        this.updateTask(task);
      }
    });

    return obs;
  }

  /** Push an update to the task observable */
  public async updateTask(task: Task) {
    const mileId = task.milestone_id;

    // The observable is no longer used in many places. The code below was added so tasks in the list view will update on changes from other views
    if (!task.milestone_id) {
      task = await this.projectService.getTaskById(task.id).toPromise();
    }
    this.milestoneTaskEvent.emit(task);
    this.taskReviewChanged.emit(task);

    const milestoneObservable = this.getCreateMapValueIfNeeded(mileId);
    // Update single task within the observables list
    let found = false;
    milestoneObservable.getValue().forEach((v, i) => {
      if (v.id === task.id) {
        milestoneObservable.value[i] = task;
        found = true;
      }
    });
    // If the task wasn't found to exist in the array then we want to push the new task into it.
    if (!found) {
      milestoneObservable.next(milestoneObservable.getValue().concat([task]));
    }
  }

  public sendTaskReminder(taskId: number): Observable<Task> {
    return this.http.put(`${ProjectTaskService.taskUrl}/${taskId}/remind`, null).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Push an update to the task observable */
  public async updateLinkedWOTask(task: LinkedWOTask) {
    const mileId = task.milestone_id;

    // The observable is no longer used in many places. The code below was added so tasks in the list view will update on changes from other views
    if (!task.milestone_id) {
      task = await this.linkedTaskService.getLinkedWOTask(task.id).toPromise();
    }
    this.milestoneTaskEvent.emit(task);

    const milestoneObservable = this.getCreateMapValueIfNeeded(mileId);
    // Update single task within the observables list
    let found = false;
    milestoneObservable.getValue().forEach((v, i) => {
      if (v.id === task.id) {
        milestoneObservable.value[i] = task;
        found = true;
      }
    });
    // If the task wasn't found to exist in the array then we want to push the new task into it.
    if (!found) {
      milestoneObservable.next(milestoneObservable.getValue().concat([task]));
    }
  }

  private getCreateMapValueIfNeeded(milestoneId): BehaviorSubject<Array<Task | LinkedWOTask>> {
    // Create new map entry if it doesnt exist
    if (!this.tasks.has(milestoneId)) {
      this.tasks.set(milestoneId, new BehaviorSubject<Array<Task>>([]));
    }
    // Return tasks BehaviourSubject (Pushable Observable)
    return this.tasks.get(milestoneId);
  }

  selectTaskById(taskId: any, open: boolean = true): Observable<Task> {
    const obs = this.loadTask(taskId);
    obs.subscribe((task) => {
      if (task) {
        this.taskSelectedEvent.emit({ task, navigate: open });
      }
    });
    return obs;
  }

  selectLinkedTaskById(taskId: any, open: boolean = true): Observable<LinkedWOTask> {
    const obs = this.loadLinkedWOTask(taskId);
    obs.subscribe((task) => {
      if (task) {
        this.linkedWOTaskSelectedEvent.emit({ task, navigate: open });
      }
    });
    return obs;
  }

  public getProjectPEBTasks(projectId: number): Observable<Task[]> {
    const subject = new ReplaySubject<Task[]>();
    this.projectService.getProjectPebTaskIds(projectId).subscribe((taskIds) => {
      const filter = [taskIds.internalApprovalTaskId, taskIds.tenantApprovalTaskId, taskIds.tenantPebSigningTaskId];
      this.http
        .get(
          `${ProjectTaskService.taskUrl}?filter=id=${filter.join(
            '^'
          )}&fields=id,title,follower_ids,status_id,status_name,files`
        )
        .pipe(
          map((result: ServiceResponse) => {
            const tasks: Task[] = result.data.tasks;
            return tasks;
          }),
          catchError((e) => this.handleErrorService.handleError(e))
        )
        .subscribe((tasks: Task[]) => {
          subject.next(tasks);
          subject.complete();
        });
    });
    return subject.asObservable();
  }

  public updateTaskDisplayOrderHash(taskId: number, hash: number): Observable<User> {
    // add the task followers to the db
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.http.put(`${ProjectTaskService.taskUrl}/${taskId}`, { display_order_hash: hash }).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateTaskRank(taskId: number, rank: string): Observable<Task> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.http.put(`${ProjectTaskService.taskUrl}/${taskId}`, { rank }).pipe(
      map((result: ServiceResponse) => {
        const updatedTask: Task = result.data.task;
        return updatedTask;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateTaskMilestoneId(taskId: number, milestoneId: number): Observable<Task> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.http.put(`${ProjectTaskService.taskUrl}/${taskId}`, { milestone_id: milestoneId }).pipe(
      map((result: ServiceResponse) => {
        const updatedTask: Task = result.data.task;
        return updatedTask;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateMilestoneDisplayOrderHash(milestoneId: number, hash: number): Observable<Milestone> {
    // add the task followers to the db
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.http.put(`${ProjectTaskService.milestoneUrl}/${milestoneId}`, { display_order_hash: hash }).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getTasks(fields, filter?: APIFilter[]): Observable<Task[]> {
    const filterString = this.apiFilterService.getFilterString(filter);
    return this.http.get(`${ProjectTaskService.taskUrl}?fields=${fields || this.taskFields}&${filterString}`).pipe(
      map((result: ServiceResponse) => result.data.tasks),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getTaskCounts(filter?: APIFilter[]): Observable<any> {
    const filterString = this.apiFilterService.getFilterString(filter);
    return this.http.get(`${ProjectTaskService.taskUrl}?limit=1${filter.length > 0 ? `&${filterString}` : ''}`).pipe(
      map((result: ServiceResponse) => result.count),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
