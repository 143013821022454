import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CostCodeFormValue, WorkspaceModule } from '../types';

@Pipe({
  name: 'filterCostCodeFormArrayBySearchTerm',
})
export class FilterCostCodeFormArrayBySearchTermPipe implements PipeTransform {
  transform(controls: AbstractControl[], searchTerm: string, workspaceModules: WorkspaceModule[]): AbstractControl[] {
    if (!searchTerm) return controls;

    return controls.filter((control) => {
      const costCodeBudgetValue: CostCodeFormValue = (control as FormGroup).getRawValue();
      return (
        costCodeBudgetValue.costCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
        costCodeBudgetValue.costCodeLabel.toLowerCase().includes(searchTerm.toLowerCase()) ||
        costCodeBudgetValue.subCostCodes.filter(
          (subCostCodeBudgetValue) =>
            subCostCodeBudgetValue.subCostCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
            subCostCodeBudgetValue.subCostCodeLabel.toLowerCase().includes(searchTerm.toLowerCase()) ||
            subCostCodeBudgetValue.subCostCodeDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
            this.getModuleNames(workspaceModules, subCostCodeBudgetValue.moduleIds)
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        ).length
      );
    });
  }

  private getModuleNames(workspaceModules: WorkspaceModule[] = [], moduleIds: number[] = []): string {
    if (workspaceModules.length) {
      return workspaceModules
        .filter((m) => moduleIds.includes(m.id))
        .reduce((acc: string[], m) => [...acc, m.name], [])
        .join(', ');
    }
  }
}
