import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-yes-no-selection',
  templateUrl: './yes-no-selection.component.html',
  styleUrls: ['./yes-no-selection.component.scss'],
})
export class YesNoSelectionComponent implements OnInit {
  @Input() label: string;

  @Input() val1Label: string;

  @Input() val2Label: string;

  @Input() defaultValue: boolean;

  @Input() requireInputOnYes: boolean;

  @Input() inputPlaceholder = 'More Details';

  @Input() disabled: boolean;

  @Output() selectionChanged = new EventEmitter<boolean>();

  @Input() detailInputValue: string;

  @Output() detailInputValueChange = new EventEmitter<string>();

  selectedValue: string;

  constructor() {}

  ngOnInit() {
    if (this.defaultValue) {
      this.selectedValue = 'yes';
    }
  }

  selectionChange(event: MatRadioChange) {
    if (!event.value) {
      return;
    }

    if (event.value === 'no') {
      this.detailInputValue = '';
    }

    this.selectedValue = event.value;
    this.selectionChanged.emit(event.value === 'yes');
  }

  detailTextChange(event: any) {
    this.detailInputValueChange.emit(this.detailInputValue);
  }

  getValue1Label() {
    return this.val1Label || 'Yes';
  }

  getValue2Label() {
    return this.val2Label || 'No';
  }

  getStartingValue(): string {
    return this.defaultValue ? 'yes' : 'no';
  }
}
