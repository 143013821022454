<div id="assigned-button" data-test-id="assigned-button" class="assigned-button">
  <button
    *ngIf="requested_by"
    (click)="openAssignUserModal()"
    class="col-auto assignee-button ease text-left font-weight-semibold"
    [disabled]="disabled"
  >
    <app-profile-thumbnail
      *ngIf="requester_id"
      [noBorder]="true"
      class="d-inline-block align-middle"
      [height]="20"
      [width]="20"
      [userId]="requester_id"
    ></app-profile-thumbnail>
    <span class="dkblue ml-1 font-weight-semibold d-inline-block align-middle">{{ requester_name }}</span>
  </button>
  <button
    *ngIf="!requested_by"
    (click)="openAssignUserModal()"
    class="col-auto assignee-button ease text-left font-weight-semibold"
    [disabled]="disabled"
  >
    <span class="m-0 font-weight-semibold d-inline-block align-middle">Search for Requester</span>
  </button>
</div>
