import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import { QuoteItemStatus, TaskReviewStatus } from '../../enums';
import {
  ArfsService,
  AuthService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectService,
  UserService,
} from '../../services';
import {
  APIFilter,
  Arf,
  ArfPurchaseType,
  Company,
  ProjectTenant,
  Quote,
  Task,
  TaskAccessoryData,
  User,
  Workspace,
} from '../../types';
import { ProjectConstruction } from '../../workspaces/construction/types';

@Component({
  selector: 'app-arf-review-dialog',
  templateUrl: './arf-review-dialog.component.html',
  styleUrls: ['./arf-review-dialog.component.scss'],
})
export class ArfReviewDialogComponent implements OnInit {
  @ViewChild('arfFile', { static: true }) arfFile;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private arfService: ArfsService,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ArfReviewDialogComponent>,
    private modalService: ModalService,
    public productService: ProductService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private userService: UserService
  ) {}

  // Data Passed in
  public project: ProjectConstruction;
  public quote: Quote;
  public task: Task;
  public tenant: ProjectTenant;

  // in house data
  public arf: Arf;
  public accessoryData: TaskAccessoryData;
  public approvalStatus: TaskReviewStatus = TaskReviewStatus.Pending;
  public arfCreator: User;
  public company: Company;
  public isDownloading = false;
  public purchaseTypes: ArfPurchaseType[];
  private purchaseTypeFields = ['id', 'name'];

  public code: string;
  public title: string;
  public purchaseTypeId: number;
  public assetTagged: number | boolean;
  public products: any;
  public total: number;
  public fiscalYear: number | string;
  public workspace: Workspace;
  public isLoading = true;

  async ngOnInit(): Promise<void> {
    this.project = this.data?.project;
    this.quote = this.data?.quote;
    this.task = this.data?.task;
    this.tenant = this.data?.tenant;

    if (this.task?.id && !this.project) {
      const arfFilter: APIFilter[] = [{ type: 'field', field: 'review_task_id', value: this.task.id.toString() }];
      const arfs: Arf[] = await this.arfService.getArfs(arfFilter, this.arfService.arfFields).toPromise();
      this.arf = arfs[0];
    }

    this.company = this.quote?.company || this.arf?.company;
    this.code = this.project?.code || this.arf?.code;
    this.title = this.project?.title || this.arf?.title;
    this.purchaseTypeId = this.quote?.arf_purchase_type_id || this.arf?.purchase_type_id;
    this.assetTagged = this.quote?.asset_tagged || this.arf?.asset_tag;
    this.products = this.tenant?.project_products || this.arf?.products;
    this.fiscalYear = this.quote?.fiscal_year || this.arf?.fiscal_year;
    this.workspace = this.project?.module || this.arf.module;
    this.total =
      (this.quote && this.productService.getSelectedQuotesTotal(this.tenant, this.quote)) ||
      sumBy(this.arf.products || [], 'total_price');

    this.purchaseTypes = await this.productService.getArfPurchaseTypes(this.purchaseTypeFields).toPromise();

    this.convertJsonToObject();

    if (this.accessoryData?.reviewCreator) {
      this.arfCreator = await this.userService
        .getUserById(this.accessoryData.reviewCreator, ['first_name', 'last_name'])
        .toPromise();
    }

    this.isLoading = false;
  }

  QuoteItemStatus = QuoteItemStatus;
  TaskReviewStatus = TaskReviewStatus;

  public convertJsonToObject(): void {
    if (typeof this.task?.accessory_data === 'string') {
      this.accessoryData = JSON.parse(this.task.accessory_data) as TaskAccessoryData;
    }
  }

  public async downloadArf(): Promise<void> {
    this.isDownloading = true;
    await this.arfFile.exportArf(this.accessoryData?.reviewChain);
    this.isDownloading = false;
  }

  public viewQuote() {
    this.modalService.openViewQuoteDialog(this.quote, true).subscribe((res) => {});
  }

  async submitApproval(approval: TaskReviewStatus) {
    const isRejection = approval === TaskReviewStatus.Rejected;
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: isRejection ? `Rejection Comment` : 'Approval Comment',
        headerText: isRejection ? 'Reason for Rejection' : 'Comment',
        descriptionText: isRejection
          ? 'Please give a reason for your rejection below.'
          : 'You may leave an optional comment below.',
        userInput: {
          placeholder: isRejection ? 'Rejection Reason' : 'Comment',
          required: isRejection,
        },
        confirmationButtonText: isRejection ? 'Reject' : 'Approve',
      })
      .toPromise();

    const submitApproval = typeof res === 'string';
    const comment = res || '';
    if (!submitApproval) {
      return;
    }

    if (approval && submitApproval) {
      let arfFile = [];
      this.approvalStatus = approval;
      const reviewChainLength = this.accessoryData.reviewChain.length - 1;
      const reviewIsComplete =
        this.accessoryData.reviewChain[reviewChainLength].id === this.authService.currentUser.id &&
        this.approvalStatus !== TaskReviewStatus.Rejected;

      if (reviewIsComplete) {
        const reviewChain = [];
        this.accessoryData.reviewChain.forEach((reviewer, index) => {
          if (index === reviewChainLength) {
            reviewer.status = TaskReviewStatus.Approved;
            reviewer.date = moment().toDate();
          }
          reviewChain.push(reviewer);
        });
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Creating Arf File..');
        arfFile = await this.arfFile.saveArfFile(reviewChain);
        this.progressIndicatorService.close();
      }

      this.dialogRef.close({
        approvalStatus: this.approvalStatus,
        approvalComment: comment,
        attachedFiles: [],
        reviewFiles: arfFile,
      });
    } else {
      this.cancel();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
