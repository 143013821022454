import { Pipe, PipeTransform } from '@angular/core';

import { UserType } from 'src/app/enums';
import { User } from 'src/app/types';

@Pipe({
  name: 'userSearchFilter',
})
export class UserSearchFilterPipe implements PipeTransform {
  transform(
    users: User[],
    userTypeId: UserType,
    searchString: string,
    companyIds: number[] = null,
    tenantRepIds: number[] = null,
    tenantContactIds: number[] = null
  ): any {
    if (!users) {
      return users;
    }

    if (userTypeId) {
      users = users.filter((user) => user.user_type_id === userTypeId);
    }

    if (userTypeId === UserType.Tenant) {
      if ((tenantRepIds && tenantRepIds.length) || (tenantContactIds && tenantContactIds.length)) {
        users = users.filter(
          (u) => (tenantRepIds || []).indexOf(u.id) > -1 || (tenantContactIds || []).indexOf(u.id) > -1
        );
      }
    }

    if (userTypeId === UserType.Vendor && companyIds && companyIds.length) {
      users = users.filter((u) => companyIds.indexOf(u.company_id) > -1);
    }

    if (searchString && searchString.length > 0) {
      searchString = searchString.toLowerCase();
      users = users.filter((user) => {
        const name = user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase();
        const deptOrCompanyName = user.department_name || user.company_name;
        return (
          name.includes(searchString) || (deptOrCompanyName && deptOrCompanyName.toLowerCase().includes(searchString))
        );
      });
    }

    return users;
  }
}
