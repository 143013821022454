import { Pipe, PipeTransform } from '@angular/core';
import { ProjectUser } from '../types';

@Pipe({
  name: 'projectUsersFilter',
})
export class ProjectUsersFilterPipe implements PipeTransform {
  transform(users: ProjectUser[], searchString: string): ProjectUser[] {
    if (!users) {
      return users;
    }

    if (searchString) {
      searchString = searchString.toLowerCase();
      users = users.filter(
        (user) =>
          (user.firstName + user.lastName).toLowerCase().includes(searchString) ||
          (user.company_name && user.company_name.toLowerCase().includes(searchString)) ||
          (user.title && user.title.toLowerCase().includes(searchString)) ||
          (user.roles && user.roles.filter((role) => role.toLowerCase().includes(searchString)).length)
      );
    }
    return users;
  }
}
