<div *ngIf="!showLanding" class="container-fluid app-container subdrawer p-4">
  <div class="row align-items-center">
    <div class="col">
      <h4 class="dkblue margin-0 font-weight-semibold d-inline-block align-middle">New Conversation</h4>
      <p class="red m-0 font-weight-semibold font-italic" style="font-size: 0.9rem">
        Remember, all conversations are visibile to all staff members.
      </p>
    </div>
    <div class="col-auto" *ngIf="containsStaffFollowers || containsTenantFollowers || containsVendorFollowers">
      <h6 class="dkblue margin-0 d-inline-block align-middle">
        Conversation contains: &nbsp;
        <span class="badge badge-warning white m-1" *ngIf="containsStaffFollowers"> UHAT/1Call </span>
        <span class="badge badge-secondary m-1" *ngIf="containsTenantFollowers"> Tenants </span>
        <span class="badge badge-danger m-1" *ngIf="containsVendorFollowers"> Suppliers </span>
      </h6>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Projects</mat-label>
            <mat-select [value]="parentProject">
              <mat-option (click)="clearFields()" [value]="null"></mat-option>
              <mat-option (click)="parentProject = p" [value]="p" *ngFor="let p of projects"
                >{{ p.code }} - {{ p.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="parentProject">
        <div class="col">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>To: </mat-label>
                    <mat-chip-list #toChipList aria-label="To Followers">
                      <mat-chip *ngFor="let f of toFollowers">
                        {{ f.first_name }} {{ f.last_name }}
                        <!-- <mat-icon matChipRemove (click)="removeToFollower(f)">cancel</mat-icon> -->
                        <mat-icon matChipRemove (click)="removeFollower(f)">cancel</mat-icon>
                      </mat-chip>
                      <input
                        type="text"
                        placeholder="Add To User"
                        #toFollowerInput
                        [matAutocomplete]="toAuto"
                        [matChipInputFor]="toChipList"
                        [matChipInputSeparatorKeyCodes]="[',']"
                        [matChipInputAddOnBlur]="true"
                        [(ngModel)]="toFollowerSearchValue"
                        (ngModelChange)="searchBoxValueChange($event)"
                      />
                    </mat-chip-list>
                    <mat-autocomplete
                      #toAuto="matAutocomplete"
                      [displayWith]="userSearchService.defaultUserMapper"
                      (optionSelected)="addToFollower($event.option.value)"
                    >
                      <mat-option *ngFor="let user of filteredUsers" [value]="user">
                        {{ user.first_name }} {{ user.last_name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <div class="add-actions">
                    <button mat-button class="ltgray" (click)="addAllTenantsAsToFollowers()">
                      Add All Tenants
                      <i class="la la-arrow-up"></i>
                    </button>
                    <button mat-button class="ltgray" (click)="addAllVendorsAsToFollowers()">
                      Add All Suppliers
                      <i class="la la-arrow-up"></i>
                    </button>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Followers (CC)</mat-label>
                    <mat-chip-list #chipList aria-label="Followers">
                      <div *ngFor="let f of followers">
                        <mat-chip
                          *ngIf="f != creatingUser && !isToFollower(f)"
                          [selectable]="false"
                          (click)="addToFollower(f)"
                        >
                          {{ f.first_name }} {{ f.last_name }}
                          <mat-icon matChipRemove (click)="removeFollower(f)">cancel</mat-icon>
                        </mat-chip>
                      </div>
                      <input
                        type="text"
                        placeholder="Add Recipient"
                        #followerInput
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="[',']"
                        [matChipInputAddOnBlur]="true"
                        [(ngModel)]="followerSearchValue"
                        (ngModelChange)="searchBoxValueChange($event)"
                      />
                    </mat-chip-list>
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="userSearchService.defaultUserMapper"
                      (optionSelected)="addFollower($event.option.value)"
                    >
                      <mat-option *ngFor="let user of filteredUsers" [value]="user">
                        {{ user.first_name }} {{ user.last_name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <div class="add-actions" *ngIf="parentProject">
                    <button mat-button class="ltgray" (click)="addAllTenantsAsFollowers()">
                      Add All Tenants
                      <i class="la la-arrow-up"></i>
                    </button>
                    <button mat-button class="ltgray" (click)="addAllVendorsAsFollowers()">
                      Add All Suppliers
                      <i class="la la-arrow-up"></i>
                    </button>
                  </div> -->
                </div>
              </div>
              <form [formGroup]="conversationFormGroup">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Subject</mat-label>
                  <input matInput formControlName="subject" placeholder="What will be talked about?" />
                  <mat-error *ngIf="conversationFormGroup.controls['subject'].errors?.required">
                    Subject is Required
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Message</mat-label>
                  <textarea
                    matInput
                    formControlName="messageText"
                    #messageTextArea
                    placeholder="What do you have to say?"
                    rows="8"
                    (keydown.tab)="tabPressed($event)"
                  ></textarea>
                  <mat-error *ngIf="conversationFormGroup.controls['messageText'].errors?.required">
                    Message is Required
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div *ngIf="reply_to_id" class="row">
            <div class="col-12">
              <h6 class="ltblue">
                <small (click)="showReply = !showReply" class="ltblue pointer">
                  <span *ngIf="reply_to_user"
                    >Replying to {{ reply_to_user.first_name }} {{ reply_to_user.last_name }}</span
                  >
                  &nbsp;
                  <span>
                    <i *ngIf="!showReply" class="fa fa-caret-right"></i>
                    <i *ngIf="showReply" class="fa fa-caret-down"></i>
                  </span>
                </small>
                <br />
                <small *ngIf="showReply">
                  {{ reply_content }}
                </small>
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input
                [hidden]="true"
                #newConversationUploader
                multiple
                type="file"
                (change)="addFile($event.target.files); newConversationUploader.value = null"
              />
              <button mat-icon-button color="accent">
                <mat-icon
                  (click)="newConversationUploader.click()"
                  matTooltip="Upload File"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                >
                  attach_file
                </mat-icon>
              </button>
              <mat-chip-list class="d-inline-block align-middle">
                <app-file-chip
                  *ngFor="let file of attachedFiles"
                  [removable]="true"
                  [file]="file"
                  (removeEvent)="removeFile(file)"
                ></app-file-chip>
              </mat-chip-list>
            </div>
            <div class="col-auto">
              <button (click)="cancelCreatingNewMessage()" mat-button class="ltgray">Cancel</button>
              &nbsp;
              <button
                mat-flat-button
                color="success"
                (click)="createConversation()"
                [disabled]="!conversationFormGroup.valid"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showLanding" class="row">
  <div class="col text-center" style="padding: 200px 100px">
    <h2 class="dkblue font-weight-semibold">As long as there is communication, everything can be solved.</h2>
    <h5 class="ltgray font-weight-semibold">- Robert Trujillo</h5>
    <br />
    <button (click)="showLanding = false" mat-flat-button color="success">Start a Conversation</button>
  </div>
</div>
