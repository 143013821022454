<main
  id="new-request"
  data-test-id="new-request"
  class="new-request p-5 request-form"
  [ngClass]="{ 'new-request-on-information': stepper.selectedIndex === 2 && selectedTopic?.form }"
>
  <div *ngIf="!showStepper" class="row">
    <div class="col">
      <section class="p-5">
        <div class="row">
          <div class="col text-center">
            <h1 class="m-0 font-weight-semibold dkblue mb-4 mt-5">How can we help?</h1>
          </div>
        </div>
        <div id="topic_input" class="row mb-4 pb-4">
          <div class="col-xl-8 col-xl-10 col-lg-11 col-md-12 m-auto p-2">
            <mat-form-field appearance="outline" class="full-width">
              <mat-icon class="ltblue" matPrefix>search</mat-icon>
              <input
                matInputAutofocus
                type="text"
                class="text-center"
                placeholder="Search 1Call categories and topics here..."
                matInput
                [matAutocomplete]="request_auto"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterTopics()"
              />
              <mat-autocomplete
                class="tall-autocomplete"
                autoActiveFirstOption
                #request_auto="matAutocomplete"
                (optionSelected)="selectTopic($event.option.value, 2)"
              >
                <div *ngFor="let topic of filteredTopics; index as i">
                  <mat-option
                    disabled
                    class="font-weight-bold"
                    *ngIf="
                      i === 0 ||
                      filteredTopics[i - 1]?.topic_category?.topic_group?.name !==
                        topic?.topic_category?.topic_group?.name
                    "
                  >
                    {{ topic?.topic_category?.topic_group?.name }}
                  </mat-option>
                  <mat-option
                    [disabled]="topic.requiresAR && !isAR && !isStaff"
                    class="pl-5"
                    [value]="topic"
                    [matTooltipDisabled]="!(topic.requiresAR && !isAR && !isStaff)"
                    matTooltip="Requires Authorized Requestor"
                    [ngClass]="{
                      disabled: topic.requiresAR && !isAR && !isStaff
                    }"
                  >
                    <span class="d-flex align-items-center">
                      <span class="font-weight-normal">
                        {{ topic?.topic_category?.name }}
                      </span>
                      <mat-icon class="m-0 mr-1 ml-1 gray option-icon">chevron_right</mat-icon>
                      <span class="font-weight-semibold">
                        {{ topic.name }}
                      </span>
                      <span *ngIf="topic.requiresAR" class="ar-item d-flex align-items-center ml-auto">
                        <mat-icon id="option-verified" class="m-0 gray opacity7">verified_user</mat-icon>
                      </span>
                    </span>
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div id="groups" class="row mt-5 mb-5">
          <div class="col-xl-10 col-lg-11 col-md-12 m-auto text-center">
            <h5 class="dkgray m-0 font-weight-normal mb-4">Or select a Topic Group to continue</h5>
            <div class="row">
              <div
                class="col-lg-3 col-md-4 p-2"
                *ngFor="let group of allTopicGroups; let i = index"
                matTooltip="Requires Authorized Requestor"
                [matTooltipDisabled]="!(group.requiresAR && !isAR && !isStaff)"
              >
                <div
                  class="m-portlet br-5 mb-0"
                  [ngClass]="{
                    selected: group.id === selectedGroup?.id,
                    opacity4: group.requiresAR && !isAR && !isStaff,
                    disabled: group.requiresAR && !isAR && !isStaff
                  }"
                >
                  <div
                    (click)="selectGroup(group)"
                    matRipple
                    class="m-portlet__body item item-last pointer p-4 align-items-center"
                  >
                    <h5 class="m-0 dkblue font-weight-normal">
                      {{ group.name }}
                    </h5>
                  </div>
                </div>
                <mat-icon *ngIf="group.requiresAR" class="ar-icon m-0 gray">verified_user</mat-icon>
              </div>
            </div>
            <!-- IF SELECTED GROUP - SELECT GROUP AND SHOW CONTINUE BUTTON TO CONTINUE -->
            <div class="row mt-5">
              <div class="col text-center">
                <button
                  mat-flat-button
                  color="success"
                  (click)="showStepper = true"
                  [disabled]="!selectedGroup"
                  [ngClass]="{ opacity0: !selectedGroup }"
                >
                  <span> Continue </span>
                  <mat-icon> arrow_forward </mat-icon>
                </button>
                <div class="ar-footnote full-width dkgray text-center my-5">
                  <mat-icon class="dkgray mr-2 d-inline-block align-middle">verified_user</mat-icon>
                  <span class="d-inline-block align-middle">Requires Authorized Requestor</span>
                </div>
                <div class="col-lg-10 mx-auto gray text-center">
                  <small class="dkgray">
                    The purpose of this request system is to gather ideas and proposals for property betterment and
                    long-term enhancements. If you come across any <b>physical or structural safety concerns</b>, it's
                    crucial to promptly inform the UHAT Architect. Moreover, any grievances involving fellow colleagues
                    or other human resources aspects should be directed to <b>Human Resources</b> and not channeled
                    through this request system.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div [hidden]="!showStepper" class="row stepper-form">
    <div class="col">
      <mat-horizontal-stepper linear labelPosition="bottom" #stepper (selectionChange)="stepChanged($event)">
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
        <mat-step [completed]="!!selectedCategory">
          <ng-template matStepLabel>Category</ng-template>
          <form *ngIf="showStepper">
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <h3 class="m-0 font-weight-semibold dkblue mb-4">
                    Please select from the following
                    <span class="font-weight-black">{{ selectedGroup?.name }}</span> options:
                  </h3>
                  <div id="categories" class="row">
                    <div
                      class="col-lg-3 col-md-4 p-2"
                      *ngFor="let category of filteredTopicCategories"
                      matTooltip="Requires Authorized Requestor"
                      [matTooltipDisabled]="!(category.requiresAR && !isAR && !isStaff)"
                    >
                      <div
                        class="m-portlet br-5 mb-0"
                        [ngClass]="{
                          selected: category.id === selectedCategory?.id,
                          opacity4: category.requiresAR && !isAR && !isStaff,
                          disabled: category.requiresAR && !isAR && !isStaff
                        }"
                      >
                        <div
                          (click)="selectCategory(category)"
                          matRipple
                          class="m-portlet__body item item-last pointer p-4 align-items-center"
                        >
                          <h5 class="m-0 dkblue font-weight-normal">
                            {{ category.name }}
                          </h5>
                        </div>
                      </div>
                      <mat-icon *ngIf="category.requiresAR" class="ar-icon m-0 gray">verified_user</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button matStepperPrevious color="accent" class="mr-2" (click)="showStepper = false">
                    <mat-icon>arrow_back</mat-icon>
                    <span> Topic Group </span>
                  </button>
                  <button mat-flat-button color="success" matStepperNext class="ml-2" [disabled]="!selectedCategory">
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                  <div class="ar-footnote full-width dkgray text-center my-5">
                    <mat-icon class="dkgray mr-2 d-inline-block align-middle">verified_user</mat-icon>
                    <span class="d-inline-block align-middle">Requires Authorized Requestor</span>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step [completed]="!!selectedTopic">
          <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
          </ng-template>
          <ng-template matStepLabel>Topic</ng-template>
          <form *ngIf="showStepper">
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <h3 class="m-0 font-weight-semibold dkblue mb-4">
                    Please select from the following
                    <span class="font-weight-black">{{ selectedGroup?.name }} > {{ selectedCategory?.name }}</span>
                    options:
                  </h3>
                  <div id="topics" class="row">
                    <div
                      class="col-lg-3 col-md-4 p-2"
                      *ngFor="let topic of filteredTopics"
                      matTooltip="Requires Authorized Requestor"
                      [matTooltipDisabled]="!(topic.requiresAR && !isAR && !isStaff)"
                    >
                      <div
                        class="m-portlet br-5 mb-0"
                        [ngClass]="{
                          selected: topic.id === selectedTopic?.id,
                          opacity4: topic.requiresAR && !isAR && !isStaff,
                          disabled: topic.requiresAR && !isAR && !isStaff
                        }"
                      >
                        <div
                          (click)="selectTopic(topic)"
                          matRipple
                          class="m-portlet__body item item-last pointer p-4 align-items-center"
                        >
                          <h5 class="m-0 dkblue font-weight-normal">
                            {{ topic.name }}
                          </h5>
                        </div>
                      </div>
                      <div class="icon-container">
                        <mat-icon *ngIf="topic.requiresAR" class="m-0 gray pointer" style="font-size: 18px"
                          >verified_user</mat-icon
                        >

                        <span *ngIf="topic.tooltip">
                          <i
                            [matMenuTriggerFor]="tooltip"
                            #tooltipTrigger="matMenuTrigger"
                            (mouseenter)="buttonEnter(tooltipTrigger, topic.id)"
                            (mouseleave)="buttonLeave(tooltipTrigger, topic.id)"
                            class="gray mr-1 pointer"
                            style="z-index: 1050"
                          >
                            <i class="help-icon material-icons" style="font-size: 18px"> help_outline </i>
                          </i>
                          <mat-menu #tooltip="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
                            <div
                              (mouseenter)="menuEnter(topic.id)"
                              (mouseleave)="menuLeave(tooltipTrigger, topic.id)"
                              class="p-3"
                              style="min-width: 250px"
                            >
                              <p class="m-0 dkblue font-weight-semibold mb-2">{{ topic.name }}</p>
                              <p class="m-0 dkgray font-weight-normal" [innerHTML]="topic.tooltip"></p>
                            </div>
                          </mat-menu>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button color="accent" matStepperPrevious class="mr-2">
                    <mat-icon>arrow_back</mat-icon>
                    <span> Topic Category </span>
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    matStepperNext
                    class="ml-2"
                    [disabled]="!selectedTopic"
                    (click)="completeTopicStep()"
                  >
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                  <div class="ar-footnote full-width dkgray text-center my-5">
                    <mat-icon class="dkgray mr-2 d-inline-block align-middle">verified_user</mat-icon>
                    <span class="d-inline-block align-middle">Requires Authorized Requestor</span>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <!-- REPLACE LOCATION IF SPECIAL FORM NEEDED -->
        <mat-step *ngIf="selectedTopic?.form?.content">
          <ng-template matStepLabel>Information</ng-template>
          <form>
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <div class="row mb-3">
                    <div class="col">
                      <h3 class="m-0 font-weight-semibold dkblue mb-2">
                        Please give us more information from the Card/Key Holder
                      </h3>
                      <span *ngIf="selectedTopic?.form" class="d-flex align-items-center fine-print">
                        <mat-icon class="red">report</mat-icon>
                        <p class="m-0 dkblue font-weight-normal">
                          When this card/key is ready for pickup, an email will also be sent to the email address
                          provided below.
                        </p>
                      </span>
                    </div>
                  </div>
                  <div class="m-portlet">
                    <div class="m-portlet__body p-4 align-items-center">
                      <app-form
                        #topicForm
                        [contained]="true"
                        [form]="selectedTopic?.form"
                        [useMatLabels]="false"
                      ></app-form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button matStepperPrevious color="accent" class="mr-2">
                    <mat-icon>arrow_back</mat-icon>
                    <span> Topic </span>
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    matStepperNext
                    class="ml-2"
                    [disabled]="!topicForm.formIsValid"
                  >
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step *ngIf="selectedTopic?.form?.id === 10">
          <ng-template matStepLabel>Information</ng-template>
          <form>
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <div class="m-portlet__body p-4 align-items-center">
                    <app-forms #topicForms></app-forms>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button matStepperPrevious color="accent" class="mr-2">
                    <mat-icon>arrow_back</mat-icon>
                    <span> Topic </span>
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    matStepperNext
                    class="ml-2"
                    [disabled]="!topicForms?.formIsValid"
                  >
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step [stepControl]="locationFormGroup">
          <ng-template matStepLabel>Location</ng-template>
          <form [formGroup]="locationFormGroup" *ngIf="showStepper">
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <div class="row mb-3">
                    <div class="col d-flex flex-column">
                      <h3 class="m-0 font-weight-semibold dkblue mb-2">Is your need here, in your department?</h3>
                      <span *ngIf="selectedTopic?.form" class="d-flex align-items-center fine-print">
                        <mat-icon class="red">report</mat-icon>
                        <p class="m-0 dkblue font-weight-normal">
                          If this request is for security access, keys, or cards, this step can be left as is.
                        </p>
                      </span>
                    </div>
                  </div>
                  <div class="m-portlet">
                    <div class="m-portlet__body p-4 align-items-center">
                      <div class="row align-items-center">
                        <div class="col">
                          <div class="row align-items-center">
                            <div class="col-3 pr-0">
                              <h6 class="m-0 dkblue font-weight-semibold mb-2">Building *</h6>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-select
                                  id="building-select"
                                  data-test-id="location-building-name"
                                  matNativeControl
                                  (selectionChange)="getFloors()"
                                  formControlName="building"
                                >
                                  <mat-option label="Building"></mat-option>
                                  <mat-option
                                    data-test-id="location-building-name-option"
                                    class="option-building"
                                    *ngFor="let b of buildings"
                                    [value]="b"
                                    >{{ b.name }}</mat-option
                                  >
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col pr-0">
                              <h6 class="m-0 dkblue font-weight-semibold mb-2">Floor *</h6>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-select
                                  id="floor-select"
                                  data-test-id="location-floor-name"
                                  matNativeControl
                                  (selectionChange)="getSuites()"
                                  formControlName="floor"
                                >
                                  <mat-option label="Floor"></mat-option>
                                  <mat-option
                                    data-test-id="location-floor-name-option"
                                    class="option-floor"
                                    *ngFor="let f of floors"
                                    [value]="f"
                                    >{{ f.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col pr-0">
                              <h6 class="m-0 dkblue font-weight-semibold mb-2">Suite</h6>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-select
                                  id="suite-select"
                                  data-test-id="location-suite-name"
                                  matNativeControl
                                  (selectionChange)="getDepartments()"
                                  formControlName="suite"
                                >
                                  <mat-option label="Suite"></mat-option>
                                  <mat-option
                                    data-test-id="location-suite-name-option"
                                    class="option-suite"
                                    *ngFor="let s of suites"
                                    [value]="s"
                                    >{{ s.name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-3 pr-0">
                              <h6 class="m-0 dkblue font-weight-semibold mb-2">Department</h6>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-select
                                  id="department-select"
                                  data-test-id="location-department-name"
                                  matNativeControl
                                  formControlName="department"
                                >
                                  <mat-option label="Department"></mat-option>
                                  <mat-option
                                    data-test-id="location-department-name-option"
                                    class="option-department"
                                    *ngFor="let d of departments"
                                    [value]="d"
                                    >{{ d.name }}</mat-option
                                  >
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-3">
                              <h6 class="m-0 dkblue d-block text-truncate font-weight-semibold mb-2">
                                Room # / Landmark
                              </h6>
                              <mat-form-field appearance="outline" class="full-width">
                                <input
                                  matInputAutofocus
                                  type="text"
                                  placeholder="Room # or Landmark"
                                  matInput
                                  formControlName="landmark"
                                />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button matStepperPrevious color="accent" class="mr-2">
                    <mat-icon>arrow_back</mat-icon>
                    <span>
                      {{ selectedTopic?.form ? 'Information' : 'Topic' }}
                    </span>
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    matStepperNext
                    [disabled]="!locationFormGroup.valid"
                    class="ml-2"
                  >
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step [completed]="!!summary.value">
          <ng-template matStepLabel>Comments</ng-template>
          <section class="p-5 mb-5 mt-5" [hidden]="!showStepper">
            <div class="row">
              <div class="col-xl-10 col-lg-11 col-md-12 mx-auto">
                <h3 class="m-0 font-weight-semibold dkblue mb-4">What information do we need to know?*</h3>
                <div class="row mb-2">
                  <div class="col">
                    <app-editor
                      id="request-summary-editor"
                      data-test-id="request-summary-editor"
                      #editor
                      placeholder="Please share anything that will help us with this request."
                      required_content=""
                      required="true"
                    ></app-editor>
                    <mat-error
                      class="mt-2"
                      *ngIf="summary?.errors?.required && summary?.touched"
                      data-test-id="request-summary-error"
                      >Summary is required
                    </mat-error>
                  </div>
                </div>
                <div class="row mx-0 my-4">
                  <div class="col px-1">
                    <mat-chip-list class="d-inline-block align-middle">
                      <button
                        mat-stroked-button
                        color="accent"
                        class="pl-2 pr-3 mr-2 bg-white"
                        (click)="openUploadModal()"
                      >
                        <mat-icon>attach_file</mat-icon>
                        <span class="ml-2"> Attach Files </span>
                      </button>
                      <app-file-chip
                        *ngFor="let file of attachedFiles"
                        [file]="file"
                        [removable]="true"
                        (removeEvent)="removeAttachedFile(file)"
                      ></app-file-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <button mat-button matStepperPrevious color="accent" class="mr-2">
                  <mat-icon>arrow_back</mat-icon>
                  <span> Location </span>
                </button>
                <button mat-flat-button color="success" matStepperNext class="ml-2" [disabled]="!summary.valid">
                  <span> Continue </span>
                  <mat-icon> arrow_forward </mat-icon>
                </button>
              </div>
            </div>
          </section>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Followers</ng-template>
          <form *ngIf="showStepper">
            <section class="p-5 mb-5 mt-5">
              <div class="row">
                <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                  <h3 class="m-0 font-weight-semibold dkblue mb-4">
                    Is there anyone else we should update on this request?
                  </h3>
                  <div class="row align-items-center mb-4">
                    <div class="col-4 pt-3 pb-3">
                      <div class="m-portlet m-0">
                        <div class="m-portlet__body p-3">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <app-profile-thumbnail
                                *ngIf="currentUser"
                                [noBorder]="true"
                                [height]="30"
                                [width]="30"
                                [userId]="currentUser?.id"
                              ></app-profile-thumbnail>
                            </div>
                            <div class="col pl-0">
                              <h5 class="m-0 dkblue font-weight-semibold">
                                {{ currentUser?.first_name }} {{ currentUser?.last_name }}
                              </h5>
                              <h6 class="m-0 dkblue font-weight-normal">Requestor (You)</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 pt-3 pb-3" *ngFor="let c of contacts">
                      <div class="m-portlet m-0">
                        <div class="m-portlet__body p-3">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <app-profile-thumbnail
                                [noBorder]="true"
                                [width]="30"
                                [height]="30"
                                [userId]="c.id"
                              ></app-profile-thumbnail>
                            </div>
                            <div class="col pl-0">
                              <h5 class="m-0 dkblue font-weight-semibold">{{ c.first_name }} {{ c.last_name }}</h5>
                              <p class="m-0 ltblue font-weight-normal">
                                <span> Contact </span>
                                <span class="gray ml-2 mr-2"> | </span>
                                <span class="gray pointer" (click)="removeContact(c)"> Remove </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 pt-3 pb-3">
                      <button mat-mini-fab color="success" (click)="openBulkSelectModal()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col text-center">
                  <button mat-button matStepperPrevious color="accent" class="mr-2">
                    <mat-icon>arrow_back</mat-icon>
                    <span> Comments </span>
                  </button>
                  <button mat-flat-button color="success" matStepperNext class="ml-2">
                    <span> Continue </span>
                    <mat-icon> arrow_forward </mat-icon>
                  </button>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Summary</ng-template>
          <section class="p-5 mb-5 mt-5" *ngIf="showStepper">
            <div class="row">
              <div class="col-xl-10 col-lg-11 col-md-12 m-auto">
                <h3 class="m-0 font-weight-semibold dkblue mb-4">Please review your selections before submitting:</h3>
                <div class="row align-items-center mb-4">
                  <div class="col">
                    <div class="m-portlet m-0">
                      <div class="m-portlet__body p-0">
                        <div class="row m-0 d-flex align-items-stretch">
                          <div (click)="showStepper = false" class="col-2 p-4 item pointer">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Group</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ selectedGroup?.name }}
                            </h5>
                          </div>
                          <div class="col-1 p-4 item-no-hover">
                            <mat-icon class="mt-3 ltgray">arrow_forward_ios</mat-icon>
                          </div>
                          <div (click)="move(stepper, 0)" class="col-2 p-4 item pointer">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Category</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ selectedCategory?.name }}
                            </h5>
                          </div>
                          <div class="col-1 p-4 item-no-hover">
                            <mat-icon class="mt-3 ltgray">arrow_forward_ios</mat-icon>
                          </div>
                          <div (click)="move(stepper, 1)" class="col p-4 item pointer">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Topic</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ selectedTopic?.name }}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <!-- FOR SPECIAL FORMS HERE BELOW -->
                      <div
                        (click)="move(stepper, 2)"
                        class="m-portlet__body item pointer p-0"
                        *ngIf="selectedTopic?.form"
                      >
                        <div class="row align-items-top m-0">
                          <div
                            class="col-3"
                            [ngClass]="{
                              'p-4': elementIndex < 4,
                              'pl-4 pr-4 pb-4 pt-2': elementIndex >= 4
                            }"
                            *ngFor="let e of topicFormElements; index as elementIndex"
                          >
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">
                              {{ e.label }}
                            </h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ e.value?.name || e.value?.label || e.value }}
                            </h5>
                          </div>
                          <!-- change p-4 to the below if after 4 items -->
                          <!-- <div class="col-3 pl-4 pr-4 pb-4 pt-2">
                              <h6 class="m-0 dkblue font-weight-semibold mb-2">
                                Email
                              </h6>
                              <h5 class="m-0 dkblue font-weight-normal">
                                Test
                              </h5>
                            </div> -->
                        </div>
                      </div>
                      <div (click)="move(stepper, 3)" class="m-portlet__body item pointer p-0">
                        <div class="row align-items-top m-0">
                          <div class="col-3 p-4">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Building</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ building.value?.name }}
                            </h5>
                          </div>
                          <div class="col p-4">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Floor</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ floor.value?.name }}
                            </h5>
                          </div>
                          <div class="col p-4">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Suite</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ suite.value?.name }}
                            </h5>
                          </div>
                          <div class="col-3 p-4">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Department</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ department.value?.name }}
                            </h5>
                          </div>
                          <div class="col-3 p-4">
                            <h6 class="m-0 dkblue font-weight-semibold mb-2">Room # / Landmark</h6>
                            <h5 class="m-0 dkblue font-weight-normal">
                              {{ landmark.value }}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div (click)="move(stepper, 3)" class="m-portlet__body item pointer p-4">
                        <div class="d-flex flex-column">
                          <h6 class="m-0 dkblue font-weight-semibold mb-2">Comments</h6>
                          <h5 class="m-0 dkblue font-weight-normal" [innerHTML]="summary.value"></h5>
                        </div>
                        <div *ngIf="attachedFiles" class="d-flex flex-column mt-4">
                          <mat-chip-list class="d-inline-block align-middle">
                            <app-file-chip
                              *ngFor="let file of attachedFiles"
                              [file]="file"
                              [removable]="true"
                              (removeEvent)="removeAttachedFile(file)"
                            ></app-file-chip>
                          </mat-chip-list>
                        </div>
                      </div>
                      <div (click)="move(stepper, 4)" class="m-portlet__body item pointer item-last p-4">
                        <div class="d-flex flex-column">
                          <h6 class="m-0 dkblue font-weight-semibold mb-2">Followers</h6>
                          <h5 *ngIf="contacts.length > 0" class="m-0 dkblue font-weight-normal">
                            <span *ngFor="let c of contacts; first as isFirst">
                              <span class="mr-2" *ngIf="!isFirst">, </span>
                              <span>{{ c.first_name }} {{ c.last_name }}</span>
                            </span>
                          </h5>
                          <h5 *ngIf="contacts.length === 0" class="m-0 gray font-italic font-weight-normal">
                            No additional followers have been added
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <button mat-button matStepperPrevious color="accent" class="mr-2">
                  <mat-icon>arrow_back</mat-icon>
                  <span> Contacts </span>
                </button>
                <button mat-flat-button color="success" (click)="submitRequest()" class="ml-2">
                  <span> Submit Request </span>
                </button>
              </div>
            </div>
          </section>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</main>
