import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

import { Meeting } from 'src/app/types';

@Component({
  selector: 'app-dashboard-meeting',
  templateUrl: './dashboard-meeting.component.html',
  styleUrls: ['./dashboard-meeting.component.scss'],
})
export class DashboardMeetingComponent implements OnInit {
  @Input() meeting: Meeting;

  constructor() {}

  ngOnInit() {}

  // converts the frequency to a valid display string
  getFrequency() {
    switch (this.meeting.recurring_meeting_frequency_interval) {
      case 'week':
        return 'WEEKLY';
      case 'day':
        return 'DAILY';
      case 'month':
        return 'MONTHLY';
      case 'year':
        return 'YEARLY';
      default:
        return null;
    }
  }

  // returns the correctly formatted start string
  getDayTime() {
    return moment(this.meeting.start_datetime).format('dddd, h:mma (MMM Do)');
  }
}
