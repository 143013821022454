<div id="user-select-dropdown" data-test-id="user-select-dropdown" class="user-select-dropdown">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="m-0 dkblue full-width">
        <mat-select
          class="dkblue"
          [formControl]="selectedUser"
          [placeholder]="
            selectedUser?.value
              ? selectedUser?.value?.first_name + ' ' + selectedUser?.value?.last_name
              : 'All Assignees'
          "
        >
          <mat-option [value]="undefined">All Assignees</mat-option>
          <mat-option *ngIf="assignedToMe" [value]="assignedToMe">
            <app-profile-thumbnail
              [noBorder]="true"
              class="d-inline-block align-middle mr-3"
              [height]="26"
              [width]="26"
              [userId]="assignedToMe?.id"
            ></app-profile-thumbnail>
            Assigned To Me
          </mat-option>
          <div *ngFor="let assignedUser of filteredAssignedUsers; index as index">
            <mat-option [value]="assignedUser">
              <app-profile-thumbnail
                [noBorder]="true"
                class="d-inline-block align-middle mr-3"
                [height]="26"
                [width]="26"
                [userId]="assignedUser?.id"
              ></app-profile-thumbnail>
              <p *ngIf="assignedUser?.id !== 0" class="dkblue m-0 d-inline-block align-middle">
                {{ assignedUser?.first_name }} {{ assignedUser?.last_name }}
              </p>
              <p *ngIf="assignedUser?.id === 0" class="dkblue m-0 d-inline-block align-middle">Not Assigned</p>
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
