import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserType } from '../enums';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NotTenantGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.currentUser.user_type_id === UserType.Tenant) {
      void this.router.navigate(['401']);
    } else {
      return true;
    }
  }
}
