import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse, ProjectTemplate } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ProjectTemplateService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  host: string = environment.serviceHost;
  projectTemplatesUrl = `${this.host}/api/v1/project-templates`;

  getProjectTemplates(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProjectTemplate[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectTemplatesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectTemplates: ProjectTemplate[] = result.data.project_templates;
          return projectTemplates;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectTemplateById(id, fields: string[]): Observable<ProjectTemplate> {
    return this.http.get(`${this.projectTemplatesUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const projectTemplates: ProjectTemplate[] = result.data['project template'];
        return projectTemplates[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createProjectTemplate(projectTemplate: ProjectTemplate, fields?: string[]): Observable<ProjectTemplate> {
    return this.http
      .post(`${this.projectTemplatesUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, projectTemplate)
      .pipe(
        map((result: ServiceResponse) => {
          const createdProjectTemplate: ProjectTemplate = result.data['project template'];
          return createdProjectTemplate;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateProjectTemplate(
    projectTemplateId: number,
    projectTemplate: ProjectTemplate,
    fields?: string[]
  ): Observable<ProjectTemplate> {
    return this.http
      .put(
        `${this.projectTemplatesUrl}/${projectTemplateId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        projectTemplate
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedProjectTemplate: ProjectTemplate = result.data['project template'];
          return updatedProjectTemplate;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteProjectTemplate(projectTemplateId: number): Observable<void> {
    return this.http.delete(`${this.projectTemplatesUrl}/${projectTemplateId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
