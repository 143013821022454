import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF, pdf } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { uniqBy } from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CoverLetterDialogComponent, FileAttachmentDialogComponent, ViewTaskDialogComponent } from 'src/app/components';
import { ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { TaskAccessoryDataFactory } from 'src/app/models';
import {
  AppRoutingService,
  AuthService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  ReviewRevisionService,
  UserService,
} from 'src/app/services';
import { Milestone, Phase, Task, TaskModalInjectionData, UhatFileReference, User } from 'src/app/types';
import { PunchlistInputModalComponent } from 'src/app/workspaces/construction/components';
import { PunchlistStatus } from 'src/app/workspaces/construction/enums';
import { Punchlist } from 'src/app/workspaces/construction/models';
import { ProjectTenantService, PunchlistService } from 'src/app/workspaces/construction/services';
import { Bid, BidPackage, ProjectConstruction, ProjectTenantConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-punchlist',
  templateUrl: './punchlist.component.html',
  styleUrls: ['./punchlist.component.scss'],
})
export class PunchlistComponent implements OnInit, OnDestroy {
  @ViewChild('pdf', { static: true }) pdf;
  @ViewChild('coverLetter', { static: true }) coverLetter;

  public downloadingPunchlist = false;

  public get projectDate(): string {
    return moment(this.project?.end_date).format('DD MMMM YYYY');
  }

  public get project(): ProjectConstruction {
    return this.projectService?.currentSelectedProject ?? {};
  }

  constructor(
    private dialog: MatDialog,
    private punchlistService: PunchlistService,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private snackbarService: MatSnackBar,
    private modalService: ModalService,
    private projectTenantService: ProjectTenantService,
    private displayReviewersService: DisplayReviewersService,
    private reviewRevisionService: ReviewRevisionService,
    private appRoutingService: AppRoutingService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private authService: AuthService,
    private eventService: ProjectEventService
  ) {}

  public punchlists$: Observable<Punchlist[]>;
  public bidData: { bidPackages: BidPackage[]; awardedBids: Bid[] } = {
    bidPackages: [],
    awardedBids: [],
  };

  public punchlistArray: Punchlist[];

  public projectTenants = [];
  public punchlistPdfs: UhatFileReference[];
  private tenantFiles = {};
  public currentTenant: { first_name: string; name: string; title: string; department: string };
  public coverLetterText: string;
  private internalReviewComplete: boolean;
  public currentInternalReviewer: User;
  public reviewNotStartedCount = 0;
  public inReviewCount = 0;
  public reviewPendingConfirmationCount = 0;
  public reviewCompletedCount = 0;

  // subscriptions
  private statusUpdatedEvent: any;

  ngOnInit() {
    setTimeout(async () => {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Tasks..');

      this.punchlists$ = this.punchlistService.getProjectPunchlistObservable(
        this.projectService.currentSelectedProjectId
      );

      this.punchlists$.subscribe(async (punchlists) => {
        punchlists.sort((a, b) => +a.local_index - +b.local_index);

        this.punchlistArray = punchlists;
        this.getVendorTaskCounts();
      });

      await this.loadProjectPunchlists();

      this.punchlistPdfs = [];
      if (this.project.punchlist_pdf_id) {
        await this.fileService
          .getIdAndName(this.project.punchlist_pdf_id)
          .toPromise()
          .then((file) => {
            if (file) {
              this.punchlistPdfs.push(file);
            }
          });
      }

      for (const tenant of this.projectTenants) {
        if (tenant.punchlist_approval_task_id) {
          this.tenantFiles[tenant.id] = [];
          await this.projectService
            .getNotesByTaskId(tenant.punchlist_approval_task_id)
            .toPromise()
            .then((notes) => {
              if (notes && notes.notes) {
                notes.notes.forEach((note) => {
                  if (note.files && note.files.length) {
                    note.files.forEach((file) => this.tenantFiles[tenant.id].push(file));
                  }
                });
              }
            });
        } else {
          this.tenantFiles[tenant.id] = [this.punchlistPdfs[0]];
        }
      }
      await this.getCurrentReviewer();
      this.progressIndicatorService.close();
    });

    this.statusUpdatedEvent = this.taskService.milestoneTaskEvent.subscribe(async (task) => {
      if (task) {
        const selectedTenant = this.getTenants.find(
          (tenant) =>
            tenant.punchlist_approval_task_id === task.id || tenant.punchlist_final_approval_task_id === task.id
        );
        const selectedPunchlist = this.punchlistArray?.find((punchlist) => punchlist.approval_task_id === task.id);

        if (task.id === this.project.punchlist_approval_task_id) {
          if (task.status_id !== this.project.punchlist_approval_task_status_id) {
            this.project.punchlist_approval_task_status_id = task.status_id;
          } else {
            const dataAccessoryData = task.accessory_data && JSON.parse(task.accessory_data);

            this.internalReviewComplete =
              dataAccessoryData?.reviewChain?.length &&
              !dataAccessoryData?.reviewChain?.find((reviewer) =>
                [TaskReviewStatus.Pending, TaskReviewStatus.Rejected].includes(reviewer.status)
              );
            const currentReviewerInfo =
              dataAccessoryData?.reviewChain?.find((reviewer) => reviewer.status === TaskReviewStatus.Pending) ||
              (dataAccessoryData?.reviewCreator && { id: dataAccessoryData.reviewCreator }) ||
              task.assigned_user_id;

            await this.getCurrentReviewer(currentReviewerInfo.id);
          }
        } else if (selectedTenant) {
          const selectedField =
            task.id === selectedTenant.punchlist_approval_task_id
              ? 'punchlist_approval_task'
              : 'punchlist_final_approval_task';
          if (task.status_id !== selectedTenant[selectedField]?.status_id) {
            selectedTenant[selectedField].status_id = task.status_id;
          } else if (selectedTenant && selectedTenant[selectedField].assigned_user?.id !== task.assigned_user_id) {
            const newAssigneduser = await this.userService
              .getUserById(task.assigned_user_id, ['first_name', 'last_name', 'user_type_id'])
              .toPromise();
            selectedTenant[selectedField].assigned_user = newAssigneduser;
          }
        } else if (selectedPunchlist) {
          if (selectedPunchlist.approval_task_status_id !== task.status_id) {
            selectedPunchlist.approval_task_status_id = task.status_id;
            this.getVendorTaskCounts();
          }
        }
      }
    });
  }

  ngOnDestroy() {
    // attempt to close all active subscriptions
    try {
      this.statusUpdatedEvent.unsubscribe();
    } catch (e) {}
  }

  get TaskStatus() {
    return TaskStatus;
  }
  public async getCurrentReviewer(currentReviewerId = null) {
    if (this.project.punchlist_approval_task_id) {
      let currentReviewerInfo;
      if (!currentReviewerId) {
        const currentTask = await this.projectService
          .getTaskById(this.project.punchlist_approval_task_id, ['accessory_data', 'assigned_user'])
          .toPromise();

        const internalAccessoryData = currentTask?.accessory_data && JSON.parse(currentTask.accessory_data);
        currentReviewerInfo =
          internalAccessoryData?.reviewChain?.find((reviewer) => reviewer.status === TaskReviewStatus.Pending) ||
          (internalAccessoryData?.reviewCreator && { id: internalAccessoryData?.reviewCreator }) ||
          currentTask.assigned_user;
        this.internalReviewComplete =
          internalAccessoryData?.reviewChain?.length &&
          !internalAccessoryData?.reviewChain?.find((reviewer) =>
            [TaskReviewStatus.Pending, TaskReviewStatus.Rejected].includes(reviewer.status)
          );
      }

      if (
        !this.currentInternalReviewer ||
        (!currentReviewerId && this.currentInternalReviewer.id !== currentReviewerInfo?.id) ||
        (currentReviewerId && currentReviewerId !== this.currentInternalReviewer.id)
      ) {
        this.currentInternalReviewer =
          (currentReviewerInfo?.first_name && currentReviewerInfo) ||
          (await this.userService
            .getUserById(currentReviewerId || currentReviewerInfo?.id, ['first_name', 'last_name'])
            .toPromise());
      }
    }
  }

  private getVendorTaskCounts() {
    let notStartedCount = 0;
    let inReviewCount = 0;
    let reviewPendingCount = 0;
    let reviewCompleteCount = 0;
    this.punchlistArray?.forEach((punchlist) => {
      if (punchlist.approval_task_status_id === TaskStatus.Open) {
        inReviewCount++;
      } else if (punchlist.approval_task_status_id === TaskStatus.Pending) {
        reviewPendingCount++;
      } else if (punchlist.approval_task_status_id === TaskStatus.Complete) {
        reviewCompleteCount++;
      } else {
        notStartedCount++;
      }
    });

    this.reviewNotStartedCount = notStartedCount;
    this.inReviewCount = inReviewCount;
    this.reviewPendingConfirmationCount = reviewPendingCount;
    this.reviewCompletedCount = reviewCompleteCount;
  }

  private async loadProjectPunchlists() {
    const punchlistData = await this.punchlistService.loadProjectPunchlists(
      this.projectService.currentSelectedProjectId
    );

    this.bidData.bidPackages = punchlistData.bidPackages;
    this.bidData.awardedBids = punchlistData.awardedBids;
    this.projectTenants = punchlistData.projectTenants;

    this.punchlistArray?.forEach((punchlist) => {
      punchlist.bid_package = this.bidData?.bidPackages?.find((bp) => bp.trade_id === punchlist.trade_id);
    });
  }

  openPunchlistInputModal() {
    this.dialog
      .open(PunchlistInputModalComponent, {
        disableClose: true,
        width: '540px',
        data: {},
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Saving Punchlist..');
          this.snackbarService.open('New Punchlist Task Created');
          this.punchlists$ = this.punchlistService.getProjectPunchlistObservable(
            this.projectService.currentSelectedProjectId
          );
          await this.loadProjectPunchlists();
          this.progressIndicatorService.close();
        }
      });
  }

  public openPunchlistTaskDialog(punch: Punchlist, viewOnly = false) {
    this.dialog
      .open(PunchlistInputModalComponent, {
        disableClose: true,
        width: '540px',
        data: {
          punchlistData: punch,
          viewOnly,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result && !viewOnly) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Punchlist..');
          await this.punchlistService.loadProjectPunchlists(this.projectService.currentSelectedProjectId);
          this.punchlists$ = this.punchlistService.getProjectPunchlistObservable(
            this.projectService.currentSelectedProjectId
          );
          this.snackbarService.open('Punchlist Saved');
          this.progressIndicatorService.close();
        }
      });
  }

  public async removeTask(punchlist: Punchlist) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Removing Punchlist..');
    await this.punchlistService
      .deletePunchlistItem(punchlist)
      .toPromise()
      .then((deleted) => {
        // TODO: Figure out why we have to reassign the observable for the view to update.
        this.punchlists$ = this.punchlistService.getProjectPunchlistObservable(
          this.projectService.currentSelectedProjectId
        );
      });
    this.progressIndicatorService.close();
  }

  downloadFullPunchlistPDF() {
    this.downloadingPunchlist = true;
    setTimeout(() => {
      this.pdf.saveAs('Full_Punchlist.pdf');
    });
    this.downloadingPunchlist = false;
  }

  public hasAdditionalInfo(punchlist: Punchlist): boolean {
    return (
      (punchlist.additional_notes != null && punchlist.additional_notes.length > 0) ||
      (punchlist.files != null && punchlist.files.length > 0)
    );
  }

  public async internalReview() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating Pdf');
    // Export PDF to in memory data
    let createdPDF: File;
    await this.pdf.export().then(async (groupData) => {
      // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
      await new Promise<File>((resolve) => {
        pdf.toBlob(groupData, (res) => {
          createdPDF = new File([res], `Punchlist.pdf`);
          resolve(createdPDF);
        });
      });
    });
    if (!createdPDF) {
      return;
    }
    // Upload PDF
    let linkedUhatPunchlist: UhatFileReference;
    // await this.fileService.checkIfFileExists(`PunchList_${this.project.title}_${moment().format('MMM-d-YY:LT')}.pdf`, this.project.id, ResourceType.Project).toPromise().then( res => {
    //
    // })
    await this.fileService
      .createFile(
        createdPDF,
        this.project.id,
        ResourceType.Project,
        `PunchList_${this.project.title}_${moment().format('MMM-d-YY:LT')}.pdf`
      )
      .toPromise()
      .then((file) => {
        if (!file) {
          return;
        }
        linkedUhatPunchlist = file;

        const data: TaskModalInjectionData = {
          attachedFiles: [linkedUhatPunchlist],
          accessoryData: { type: TaskAccessoryType.Other, isReviewItem: true },
          taskTitle: 'Internal Punch List Approval',
          taskNote: '<i>Punch List Files</i>',
          can_delete: 0,
        };
        this.progressIndicatorService.close();
        this.modalService.openCreateTaskModal(data).subscribe((task) => {
          if (task) {
            this.projectService
              .updateProject(this.project.id, {
                punchlist_approval_task_id: task.id,
                punchlist_pdf_id: linkedUhatPunchlist.id,
              })
              .toPromise()
              .then((project) => {
                if (project) {
                  this.project.punchlist_approval_task_id = task.id;
                  this.project.punchlist_pdf_id = linkedUhatPunchlist.id;
                }
              });
          }
        });
      })
      .catch((reason) => this.progressIndicatorService.close());
  }

  public tenantReview(tenant) {
    const data: TaskModalInjectionData = {
      attachedFiles: this.tenantFiles[tenant.id],
      accessoryData: { type: TaskAccessoryType.Other, isReviewItem: true },
      taskTitle: `${tenant.tenant_name} - Punch List Approval`,
      taskNote: '<i>Punch List Files</i>',
      isTenant: true,
      tenantRep: tenant.representative_id,
      can_delete: 0,
    };
    this.modalService.openCreateTaskModal(data).subscribe(async (task) => {
      if (task) {
        await this.projectTenantService
          .updateProjectTenant(tenant.id, { punchlist_approval_task_id: task.id })
          .toPromise()
          .then((updatedTenant) => {
            const currentTenant = this.projectTenants.findIndex((projectTenant) => projectTenant.id === tenant.id);
            this.projectTenants[currentTenant].punchlist_approval_task_id = task.id;
          });
      }
    });
  }

  get alreadyCreatedVendorTasks() {
    let allTasksCreated = true;
    if (!this.punchlistArray) {
      return false;
    }
    for (const punchlistItem of this.punchlistArray) {
      if (!punchlistItem.approval_task_id) {
        allTasksCreated = false;
      }
    }
    return allTasksCreated;
  }

  get allVendorTasksComplete() {
    return (
      !!this.punchlistArray?.length &&
      !this.punchlistArray?.find((punchlist) => punchlist.approval_task_status_id !== TaskStatus.Complete)
    );
  }

  get allCompleteTenantReviewsComplete() {
    return (
      !!this.getTenants.length &&
      !this.getTenants.find((tenant) => tenant.punchlist_final_approval_task?.status_id !== TaskStatus.Complete)
    );
  }

  public async createVendorTasks() {
    const tradesWithContactIds = this.getPunchlistTradeToContactIdMapping();

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Getting Phases');

    // Find or create the punchlist milestone for the tasks to be created under.
    const phaseName = 'Closeout';
    const milestoneName = 'Punchlist Items';
    let closeoutPhase: Phase;
    let milestone: Milestone;

    // Find or create the punchlist milestone for the tasks to be created under.
    closeoutPhase = await this.projectService
      .getPhaseByPhaseNameAndProject(this.projectService.currentSelectedProjectId, phaseName)
      .toPromise();

    milestone = await this.projectService
      .getMilestoneByNameAndPhaseName(this.projectService.currentSelectedProjectId, milestoneName, phaseName)
      .toPromise();

    milestone =
      milestone ||
      (await this.projectService
        .createMilestone({ name: milestoneName, phase_id: closeoutPhase.id, sequence: 1 })
        .toPromise());

    let taskCounter = 1;
    for (const punchlistItem of this.punchlistArray) {
      if (!punchlistItem.approval_task_id) {
        this.progressIndicatorService.updateStatus('Creating Task ' + `(${taskCounter}/${this.punchlistArray.length})`);
        const trade = tradesWithContactIds.find((t) => t.tradeId === punchlistItem.trade_id);
        const locationText = punchlistItem.location ? `<h5>Location</h5>${punchlistItem.location}<br><br>` : '';
        const additionalNotesText = punchlistItem.additional_notes
          ? `<h5>Additional Notes</h5>${punchlistItem.additional_notes}`
          : '';
        const taskData: Task = {
          title: `${punchlistItem.description} - Punch List Task`,
          milestone_id: milestone.id,
          assigned_user_id: trade.contactId,
          description: `${locationText}${additionalNotesText}`,
          can_delete: 0,
        };
        await this.projectService
          .createTask(taskData)
          .toPromise()
          .then(async (createdTask) => {
            // Create Note with attached files from punchlist item
            const shouldCreateNote = punchlistItem.files && punchlistItem.files.length > 0;
            if (shouldCreateNote) {
              this.projectService
                .createNote(ResourceType.Task, createdTask.id, '<i>Punch List files</i>')
                .subscribe(async (note) => {
                  for (const file of punchlistItem.files) {
                    await this.fileService.linkFile(file.file_id || file.id, note.id, ResourceType.Note).toPromise();
                  }
                });
            }
            // Update the punchlist items task id
            if (!punchlistItem.approval_task_id) {
              await this.punchlistService
                .updateItem(punchlistItem.id, { approval_task_id: createdTask.id })
                .toPromise()
                .then((updatedPunchlist) => {
                  if (updatedPunchlist) {
                    punchlistItem.approval_task_id = createdTask.id;
                  }
                });
            }
          });
      }
      taskCounter++;
    }

    // Trigger reload of punchlists and close the spinner
    await this.punchlistService.loadProjectPunchlists(this.projectService.currentSelectedProjectId);
    this.getVendorTaskCounts();
    this.progressIndicatorService.close();
  }

  async finalizePunchlist() {
    // Changes the status of punchlist in project to finalized
    this.project.punchlist_status_id = PunchlistStatus.FINALIZED;
    await this.projectService
      .updateProject(this.project.id, { punchlist_status_id: PunchlistStatus.FINALIZED })
      .toPromise();
  }

  public openFileAttachmentDialog(tenant) {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    const parentResourceType = tenant.punchlist_approval_task_id ? ResourceType.Task : ResourceType.Project;
    const parentResourceId = tenant.punchlist_approval_task_id || this.projectService.currentSelectedProjectId;
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType,
          parentResourceId,
          allowComment: false,
          // additionalParents,
          maxFiles,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          const files: UhatFileReference[] = resultData;
          // Only Do this if the tenant punchlist task does not exist
          if (!tenant.punchlist_approval_task_id) {
            files.forEach((file) => {
              this.tenantFiles[tenant.id].push(file);
            });
          } else {
            const newFiles = [];
            files.forEach((file) => {
              if (!this.tenantFiles[tenant.id].find((tenantFile) => tenantFile.id === file.id)) {
                this.tenantFiles[tenant.id].push(file);
                newFiles.push(file);
              }
            });
            this.projectService
              .createNote(parentResourceType, parentResourceId, `Punchlist File${newFiles.length === 1 ? '' : 's'}`)
              .subscribe(async (note) => {
                for (const file of newFiles) {
                  await this.fileService.linkFile(file.file_id || file.id, note.id, ResourceType.Note).toPromise();
                }
              });
          }
        }
      });
  }

  public scheduleFiveAttachmentDialog(tenant) {
    const maxFiles = 1;
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          maxFiles,
          verifyFileExtension: true,
          preSelectedTags: [{ id: 45 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          const file: UhatFileReference[] = resultData;
          const fileId = file[0].file_id;
          await this.projectTenantService.updateProjectTenant(tenant.id, { schedule_5_file_id: fileId }).toPromise();
          tenant.schedule_5_file_id = fileId;
          tenant.schedule_5_file_name = file[0].name;
          this.fileService.addTags(fileId, [45]).subscribe();
        }
      });
  }

  public removeScheduleFiveFromTenant(tenant: ProjectTenantConstruction) {
    this.modalService
      .openConfirmationDialog({
        headerText: 'Remove Schedule 5 File',
        descriptionText:
          'Are you sure you want to detach the Schedule 5 file? It will still be available in Project Files.',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.fileService.removeTags(tenant.schedule_5_file_id, [45]).subscribe();
          tenant.schedule_5_file_id = null;
          tenant.schedule_5_file_name = null;
          this.snackbar.open('Schedule 5 has been removed');
          this.projectTenantService.updateProjectTenant(tenant.id, { schedule_5_file_id: null }).subscribe();
        }
      });
  }

  // Only allows certain staff users to edit the review process
  get userIsReviewAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  get tenantApprovalComplete() {
    let status = true;
    this.getTenants.forEach((projectTenant) => {
      status = status && projectTenant.punchlist_approval_task?.status_id === TaskStatus.Complete;
    });
    return status;
  }

  removeFile(file: UhatFileReference, tenant) {
    this.punchlistPdfs = this.tenantFiles[tenant.id].filter((f) => f.id !== file.id);
    if (tenant.punchlist_approval_task_id) {
    }
  }

  public async updateScheduleStatus(tenant: ProjectTenantConstruction, event): Promise<void> {
    tenant.schedule_5_is_required = event?.checked ? 1 : 0;
    await this.projectTenantService
      .updateProjectTenant(tenant.id, { schedule_5_is_required: tenant.schedule_5_is_required })
      .toPromise();
  }

  public async finalize() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Finalizing...');

    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize Punchlist',
        descriptionText:
          'Warning: Finalizing is permanent. Please verify that the punchlist is 100% correct, as no more changes can be made after finalizing. Are you sure you want to finalize the punchlist?',
      })
      .subscribe(async (closeEvent) => {
        if (closeEvent) {
          this.project.punchlist_status_id = PunchlistStatus.FINALIZED;
          await this.projectService
            .updateProject(this.project.id, { punchlist_status_id: PunchlistStatus.FINALIZED })
            .toPromise();

          // Find or create the punchlist milestone for the tasks to be created under.
          const closeoutPhase: {
            milestones;
            phaseId;
          } = await this.projectService
            .getMilestonesByPhaseNameAndProjectId(this.projectService.currentSelectedProjectId, 'Closeout')
            .toPromise();
          const foundMilestone = closeoutPhase.milestones.find((m) => m.name === 'Punch List');
          let punchlistMilestone: Milestone;
          if (foundMilestone) {
            punchlistMilestone = foundMilestone;
          } else {
            this.progressIndicatorService.updateStatus('Creating punchlist phase...');
            punchlistMilestone = await this.projectService
              .createMilestone({ name: 'Punch List', phase_id: closeoutPhase.phaseId, sequence: 1 })
              .toPromise();
          }

          // Create task for each punchlist
          const punchlistsThatNeedTasks = this.punchlistArray.filter((p) => p.approval_task_id == null);
          let i = 1;
          for (const punchlist of punchlistsThatNeedTasks) {
            this.progressIndicatorService.updateStatus('Creating Task ' + `(${i++}/${punchlistsThatNeedTasks.length})`);
            const task: Task = {
              title: punchlist.description,
              description: punchlist.additional_notes,
              milestone_id: punchlistMilestone.id,
              assigned_user_id: this.bidData.bidPackages.filter((bid) => +bid.trade_id === +punchlist.trade_id)[0]
                .awarded_contact_id,
              can_delete: 0,
            };
            await this.projectService
              .createTask(task)
              .toPromise()
              .then(async (createdTask) => {
                await this.punchlistService.updateItem(punchlist.id, { approval_task_id: createdTask.id }).toPromise();
              });
          }

          // Trigger reload of punchlists and close the spinner
          await this.punchlistService.loadProjectPunchlists(this.projectService.currentSelectedProjectId);

          this.progressIndicatorService.close();
        }
      });
  }

  private getPunchlistTradeToContactIdMapping(): {
    tradeId: number;
    tradeName: string;
    contactId: number;
  }[] {
    const mapping: { tradeId: number; tradeName: string; contactId: number }[] = [];
    for (const punchItem of uniqBy(this.punchlistArray, (u) => u.trade_id)) {
      mapping.push({
        tradeId: punchItem.trade_id,
        tradeName: punchItem.trade_name,
        contactId: this.getContactIdForTrade(punchItem.trade_id),
      });
    }
    return mapping;
  }

  private getContactIdForTrade(tradeId: number) {
    for (const bidPackage of this.bidData.bidPackages) {
      if (bidPackage.trade_id === tradeId) {
        return bidPackage.awarded_contact_id;
      }
    }
    return null;
  }

  get allTradesAssigned() {
    return (
      this.bidData &&
      this.bidData.bidPackages &&
      this.bidData.bidPackages.filter((bid) => bid.trade_allows_bids && bid.awarded_contact_id == null).length === 0
    );
  }
  get canAddTask() {
    return !this.project?.punchlist_approval_task_id;
  }
  get isFinalized() {
    return this.project?.punchlist_status_id === PunchlistStatus.FINALIZED;
  }
  get canFinalize() {
    return (
      !this.isFinalized &&
      this.isInternalComplete &&
      this.isAllTenantsComplete &&
      this.allVendorTasksComplete &&
      this.allCompleteTenantReviewsComplete
    );
  }
  get canStartInternalApproval() {
    return !this.project?.punchlist_approval_task_id && this.punchlistArray && this.punchlistArray.length;
  }

  public canStartTenantApproval(tenant: ProjectTenantConstruction) {
    return (
      this.isInternalComplete &&
      !tenant.punchlist_approval_task_id &&
      !tenant.saved_punchlist_approval_task_id &&
      (tenant.schedule_5_file_id || !tenant.schedule_5_is_required)
    );
  }
  get canDoInternalNewRevision() {
    return !this.isFinalized && this.project?.punchlist_approval_task_id;
  }
  public canDoTenantNewRevision(tenant: ProjectTenantConstruction) {
    return !this.isFinalized && this.isInternalComplete && tenant.punchlist_approval_task_id != null;
  }
  get canReset() {
    return this.canDoInternalNewRevision && this.canDoTenantNewRevision;
  }
  get hasTenants() {
    return this.projectTenants.map((tenant) => tenant.type_id).filter((tenant_type) => tenant_type !== 3).length > 0;
  }

  get getTenants(): ProjectTenantConstruction[] {
    return this.projectTenants.filter((tenant) => tenant.type_id !== 3);
  }
  get getInternal(): ProjectTenantConstruction {
    return this.projectTenants.filter((tenant) => tenant.type_id === 3)[0];
  }

  get isInternalComplete() {
    return this.project?.punchlist_approval_task_status_id === 3;
  }
  // TODO test if no tenants
  get isAllTenantsComplete() {
    return (
      this.getTenants != null &&
      this.getTenants
        .map((tenant) => tenant.punchlist_approval_task?.status_id)
        .filter((status) => status !== TaskStatus.Complete).length === 0
    );
  }

  get canConvertVendorTasks() {
    return (
      (this.project.current_phase_name === 'Construction' || this.project.current_phase_name === 'Closeout') &&
      this.tenantApprovalComplete &&
      !this.alreadyCreatedVendorTasks
    );
  }

  get getStatus() {
    if (this.canStartInternalApproval) {
      return 'Start the approval process to continue';
    } else if (this.isFinalized) {
      return 'Approval Process Finalized';
    } else if (this.canFinalize) {
      return 'Ready to Be Finalized';
    } else if (this.project?.punchlist_approval_task_id) {
      if (this.internalReviewComplete && this.project.punchlist_approval_task_status_id !== TaskStatus.Complete) {
        return 'Approved - Mark Review Task Complete to Continue';
      } else if (this.project.punchlist_approval_task_status_id === TaskStatus.Complete) {
        if (!this.allTradesAssigned) {
          return 'Please award all remaining bids in order to Convert to Vendor Tasks';
        } else {
          if (!this.canConvertVendorTasks) {
            if (
              this.tenantApprovalComplete &&
              this.project.current_phase_name !== 'Construction' &&
              this.project.current_phase_name !== 'Closeout'
            ) {
              return 'Approval Process Complete - Move Current Project Phase to Construction or Closeout to Convert to Supplier Tasks';
            } else if (!this.tenantApprovalComplete) {
              return `Complete Tenant Approval${this.getTenants?.length > 1 ? 's' : ''}`;
            } else if (!this.allVendorTasksComplete) {
              return `Mark Supplier Task${this.punchlistArray?.length > 1 ? 's' : ''} Complete`;
            }
          } else {
            return 'Approval Process Complete - Convert to Supplier Tasks to finish';
          }
        }
      } else {
        return 'Approval Process in Progress - Use +Revision to Add New Tasks';
      }
    } else {
      return 'Please add punchlist tasks';
    }
  }

  public getTaskLink(tenant) {
    return `/projects/${this.project.id}/tasks/${tenant.punchlist_approval_task_id}`;
  }

  public getIcon(taskStatus: number): { icon: string; color: string } {
    if (taskStatus === 1) {
      return { icon: 'restore', color: '#c5cfe4' };
    } else if (taskStatus === 3) {
      return { icon: 'check_circle', color: '#24D19E' };
    } else {
      return { icon: 'radio_button_unchecked', color: '#c5cfe4' };
    }
  }

  public async submitInternalApproval() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Begin Approval Process',
        descriptionText:
          'Are you sure that you want to begin the approval process for the punchlist? To add a new task, you will need to reset the process, so make sure you are finished adding approval tasks!',
      })
      .subscribe(async (confirmationMessage) => {
        if (confirmationMessage) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Creating Review File...');

          const createdPDF = await this.getInternalFiles();
          const include = {
            pm: true,
            wm: true,
            arch: true,
          };

          const reviewers = this.displayReviewersService.getInternalReviewers(include);
          const reviewIds = reviewers.reviewIds;
          const selectedReviewers = reviewers.selectedReviewers;

          this.progressIndicatorService.close();

          const taskModalData: TaskModalInjectionData = {};
          taskModalData.attachedFiles = [createdPDF];
          taskModalData.taskNote = 'Master Punchlist File';
          taskModalData.taskTitle = `Punch List Staff Approval Review`;
          taskModalData.accessoryData = TaskAccessoryDataFactory.createPunchlistReviewApprovalData(true, reviewIds);
          taskModalData.taskDescription = `The punchlist below needs to be reviewed. Please click 'Begin Review' when it is your turn to review.`;
          taskModalData.isReviewersLocked = true;
          taskModalData.isTypeLocked = true;
          taskModalData.areFilesLinked = false;
          taskModalData.selectedReviewers = selectedReviewers;
          taskModalData.isTenant = false;
          taskModalData.phaseName = 'Closeout';
          taskModalData.milestoneName = 'Punch List';
          taskModalData.can_delete = 0;
          const returnTask = await this.modalService.openCreateTaskModal(taskModalData).toPromise();
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Review...');

          if (returnTask?.id != null) {
            await this.fileService.makeCurrent(50, returnTask.id).toPromise();
            // If the user creating the review task is the same as the first reviewers this creates a task activity on the new task
            if (this.authService.getLoggedInUser().id === selectedReviewers[0].id) {
              await this.eventService
                .createTaskApprovalEvent(returnTask.id, TaskReviewStatus.Approved, 'Approved upon creation')
                .toPromise();
            }

            this.project.punchlist_approval_task_id = returnTask.id;
            this.project.punchlist_status_id = PunchlistStatus.AWAITING_APPROVAL;
            await this.projectService
              .updateProject(this.project.id, {
                punchlist_approval_task_id: returnTask.id,
                punchlist_status_id: PunchlistStatus.AWAITING_APPROVAL,
              })
              .toPromise();
          }
          this.progressIndicatorService.close();
        }
      });
  }

  private async getInternalFiles() {
    return await this.pdf.export().then(async (groupData) => {
      let createdPDF: File;
      // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
      await new Promise<File>((resolve) => {
        pdf.toBlob(groupData, (res) => {
          createdPDF = new File([res], `Punchlist_v${this.project.punchlist_revision}.pdf`);
          resolve(createdPDF);
        });
      });
      return createdPDF || null;
    });
  }

  public async submitTenantApproval(tenant, isFinal = false) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Combining files...');
    const createdPDF = await this.combineFiles(tenant, isFinal);

    const user = await this.userService
      .getUserById(tenant.representative_id, [
        'id',
        'first_name',
        'last_name',
        'title',
        'user_type_id',
        'is_login_enabled',
      ])
      .toPromise();
    const assigned_user = {
      id: tenant.representative_id,
      firstName: user.first_name,
      lastName: user.last_name,
      title: user.title,
      reviewer: true,
      user_type_id: user.user_type_id,
      is_login_enabled: user.is_login_enabled,
    };

    this.progressIndicatorService.close();

    const taskModalData: TaskModalInjectionData = {};
    taskModalData.attachedFiles = [createdPDF];
    taskModalData.taskNote = 'Master Punchlist File';
    taskModalData.taskTitle = !isFinal
      ? `Punch List Tenant Approval Review for ${tenant.tenant_name}`
      : `Punch List Tenant Completion Review for ${tenant.tenant_name}`;
    taskModalData.taskDescription =
      'Please download the below files by clicking on them. Then, sign and reattach as a note. This can be done by clicking the paperclip below. Please mark the task complete when you are done so a UHAT/1Call user can approve the task. Thank you!';
    taskModalData.isReviewersLocked = true;
    taskModalData.isTypeLocked = true;
    taskModalData.areFilesLinked = false;
    taskModalData.assigned_user = assigned_user;
    taskModalData.isTenant = true;
    taskModalData.phaseName = 'Closeout';
    taskModalData.milestoneName = 'Punch List';
    taskModalData.can_delete = 0;
    const returnTask = await this.modalService.openCreateTaskModal(taskModalData).toPromise();

    if (returnTask?.id) {
      const punchlistField = isFinal ? 'punchlist_final_approval_task_id' : 'punchlist_approval_task_id';
      tenant[punchlistField] = returnTask.id;
      this.projectTenantService
        .updateProjectTenant(tenant.id, {
          [punchlistField]: returnTask.id,
        })
        .subscribe();

      if (isFinal) {
        const taskField = punchlistField.replace('_id', '');
        tenant[taskField] = returnTask;
      }
    }
  }

  public async newInternalRevision() {
    await this.resetApprovalProcess(null);
  }

  public async newTenantRevision(tenant) {
    await this.resetApprovalProcess(tenant);
  }

  /**
   * Clear approval process task ids, lock them, close them, and reset status
   * @param tenant The tenant to reset the process for
   * @param resetStaffTask True if you want to reset the approval task for the staff
   * @param resetTenantTask True if you want to reset the approval task for the tenant
   */
  public async resetApprovalProcess(tenant: ProjectTenantConstruction): Promise<boolean> {
    let res = false;
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        descriptionText:
          'You are about to reset the approval process, are you sure? This will invalidate any existing approvals, reset and lock any linked approval tasks, and require new approval.',
        confirmationButtonText: 'Submit',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise()
      .then(async (confirmResult) => {
        res = confirmResult;
        if (confirmResult) {
          await this.resetTask(tenant, confirmResult);
        }
      });
    return res;
  }

  private async combineFiles(tenant, isFinal = false) {
    const files = [];
    const tenantFileName = tenant.tenant_name.replace(/ /g, '_');
    // Generate Cover Letter Export
    await this.currentTenantInfo(tenant);
    this.coverLetterText = tenant.punchlist_cover_letter_text;
    const coverLetterGroup = await this.coverLetter.export();
    const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');
    const coverLetterByteCharacters = atob(coverLetterBase64);
    const coverLetterData = new Array(coverLetterByteCharacters.length);
    for (let i = 0; i < coverLetterByteCharacters.length; i++) {
      coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
    }
    const coverLetterTitle = `Cover_Letter_${tenantFileName}`;
    files.unshift({
      file: new Blob([new Uint8Array(coverLetterData)]),
      name: `${coverLetterTitle}_PRJ${this.project.code}.pdf`,
    });

    const punchlistGroup = await this.pdf.export();
    const punchlistBase64 = (await exportPDF(punchlistGroup)).replace('data:application/pdf;base64,', '');
    const punchlistByteCharacters = atob(punchlistBase64);
    const punchlistData = new Array(punchlistByteCharacters.length);
    for (let i = 0; i < punchlistByteCharacters.length; i++) {
      punchlistData[i] = punchlistByteCharacters.charCodeAt(i);
    }
    let punchlistTitle = 'punchlist';
    punchlistTitle = punchlistTitle.replace(/ /g, '_');
    files.push({
      file: new Blob([new Uint8Array(punchlistData)]),
      name: `${punchlistTitle}_${this.project.title}.pdf`,
    });

    if (tenant.schedule_5_file_id) {
      const scheduleFive = await this.fileService.downloadFile({ id: tenant.schedule_5_file_id }).toPromise();
      files.push({
        file: new Blob([new Uint8Array(scheduleFive.file.data)]),
        name: scheduleFive.name,
      });
    }

    let combinedFile;
    if (files?.length > 1) {
      try {
        combinedFile = await this.fileService.combinePDFs(files).toPromise();
      } catch (e) {
        const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
        errorSnack.onAction().subscribe(async () => {
          this.snackbar.dismiss();
        });
        return;
      }
    }

    const blob =
      files?.length > 1 ? new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' }) : files[0]?.file;
    const file = new File(
      [blob],
      `${isFinal ? 'completed_' : ''}punchlist_${tenant.id}_V${
        isFinal ? tenant.punchlist_final_revision : tenant.punchlist_revision
      }_PRJ${this.project.code}.pdf`
    );
    return file;
  }

  public async resetApprovals() {
    let res = false;
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        descriptionText:
          'You are about to reset the approval process, are you sure? This will invalidate any existing approvals, reset and lock any linked approval tasks, and require new approval.',
        confirmationButtonText: 'Submit',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise()
      .then(async (confirmResult) => {
        res = confirmResult;
        if (confirmResult) {
          const promises = [];

          if (this.getInternal.punchlist_approval_task_id) {
            promises.push(this.resetTask(this.getInternal, confirmResult));
          }
          this.getTenants.forEach((tenant) => {
            if (tenant.punchlist_approval_task_id) {
              promises.push(this.resetTask(tenant, confirmResult));
            }
          });

          await Promise.all(promises);
        }
      });
  }

  // stops the review process and adds to revision if not reset
  public async resetReview(data, reset: boolean, isInternal = true, isFinal = false) {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        descriptionText:
          'You are about to reset the approval process, are you sure? This will invalidate any existing approvals and require a new approval.',
        confirmationButtonText: 'Submit',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Resetting Approval');

          if (data.punchlist_approval_task_id && (reset || isInternal)) {
            await this.reviewRevisionService.punchlistInternalRevision(data, reset, res);
            if (!reset) {
              this.project.saved_punchlist_approval_task_id = data.punchlist_approval_task_id;
              this.project.punchlist_approval_task_id = null;
              this.project.punchlist_revision = this.project.punchlist_revision + 1;
            }
          }
          if (data.punchlist_approval_task_id && (reset || !isInternal)) {
            if (reset) {
              for (const tenant of this.projectTenants) {
                if (tenant.punchlist_approval_task_id) {
                  await this.reviewRevisionService.punchlistTenantRevision(tenant, reset, res);
                }
                if (tenant.punchlist_final_approval_task_id) {
                  await this.reviewRevisionService.punchlistFinalTenantRevision(tenant, reset, res);
                }
              }
            } else {
              if (isFinal) {
                await this.reviewRevisionService.punchlistFinalTenantRevision(data, reset, res);
              } else {
                await this.reviewRevisionService.punchlistTenantRevision(data, reset, res);
              }
            }
            this.projectTenants = await this.projectTenantService.getTenantsForProject(this.project.id).toPromise();
          }

          if (reset) {
            const taskId = data.punchlist_approval_task_id || data.saved_punchlist_approval_task_id;
            const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId);

            if (!updatedAccessoryData.isTheSame) {
              const taskData = {
                id: taskId,
                accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
                assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
                status_id: 1,
              };
              await this.projectService.updateTask(taskData).toPromise();
            }
          }

          this.progressIndicatorService.updateStatus('Refreshing Tenant Info');

          this.progressIndicatorService.close();
          this.snackbar.open('The review process has been reset');
        }
      });
  }

  private async restartReviewProcess(data, isInternal, isFinal = false) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating files for task creation...');
    this.snackbar.open('Restarting Review');

    const attachedFiles = [];
    if (!isInternal) {
      attachedFiles.push(await this.combineFiles(data, isFinal));
    } else {
      attachedFiles.push(await this.getInternalFiles());
    }
    if (isInternal) {
      await this.reviewRevisionService.internalPunchlistSubmitRevision(data, attachedFiles, 50);
      this.project.punchlist_approval_task_id = this.project.saved_punchlist_approval_task_id;
      this.project.saved_punchlist_approval_task_id = null;
      await this.getCurrentReviewer();
    } else {
      if (isFinal) {
        await this.reviewRevisionService.finalTenantPunchlistSubmitRevision(data, attachedFiles, 50);
        data.punchlist_final_approval_task_id = data.saved_punchlist_final_approval_task_id;
        data.saved_punchlist_final_approval_task_id = null;
        data.punchlist_final_approval_task = await this.projectService
          .getTaskById(data.punchlist_final_approval_task_id, ['status_id', 'assigned_user'])
          .toPromise();
      } else {
        await this.reviewRevisionService.tenantPunchlistSubmitRevision(data, attachedFiles, 50);
        data.punchlist_approval_task_id = data.saved_punchlist_approval_task_id;
        data.saved_punchlist_approval_task_id = null;
        data.punchlist_approval_task = await this.projectService
          .getTaskById(data.punchlist_approval_task_id, ['status_id', 'assigned_user'])
          .toPromise();
      }
    }
    this.progressIndicatorService.close();
  }

  public async resetTask(tenant, confirmResult) {
    let isProject = false;
    if (!tenant) {
      tenant = this.project;
      isProject = true;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Resetting task...');

    const oldTaskId = tenant.punchlist_approval_task_id;
    if (!isProject) {
      const tenantData: ProjectTenantConstruction = { punchlist_approval_task_id: null };
      tenant.punchlist_approval_task_id = null;
      tenant.punchlist_approval_task_status_id = null;

      await this.projectTenantService
        .updateProjectTenant(tenant.id, tenantData)
        .toPromise()
        .then(async (statusResult) => {
          this.snackbar.open('This Punchlist approval has been reset');
        });
    } else {
      this.project.punchlist_approval_task_id = null;
      this.project.punchlist_approval_task_status_id = null;
      await this.projectService.updateProject(this.project.id, { punchlist_approval_task_id: null }).toPromise();
    }

    await this.projectService
      .createNote(
        ResourceType.Task,
        +oldTaskId,
        // 'Task Locked. Approval Process Reset. Reason: ' + confirmResult
        'The review process was reset:<br />' + confirmResult
      )
      .toPromise();
    await this.projectService.updateTask({ id: +oldTaskId, is_locked: 1, status_id: 3 }).toPromise();

    this.progressIndicatorService.close();
  }

  public async openCoverLetter(tenant: ProjectTenantConstruction) {
    await this.currentTenantInfo(tenant);
    const dialogRef = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Punchlist',
        tenant: {
          ...this.currentTenant,
          tenant_id: tenant.id,
          type_id: tenant.type_id,
          cover_letter_text: tenant.punchlist_cover_letter_text,
        },
        pmInfo: this.pmInfo,
        architectInfo: this.architectInfo,
      },
    });

    dialogRef.afterClosed().subscribe(async (tenantInfo) => {
      if (tenantInfo) {
        if (tenantInfo.id) {
          this.saveCoverLetter(tenantInfo);
        }
      }
    });
  }

  public async saveCoverLetter(tenant) {
    this.snackbar.open('Your changes have been saved.');
    this.projectTenantService
      .updateProjectTenant(tenant.id, { punchlist_cover_letter_text: tenant.cover_letter_text })
      .subscribe();
    this.projectTenants.forEach((t) => {
      if (t.id === tenant.id) {
        t.punchlist_cover_letter_text = tenant.cover_letter_text;
      }
    });
  }

  public async exportCoverLetter(tenant: ProjectTenantConstruction) {
    await this.currentTenantInfo(tenant);
    this.coverLetterText = tenant.punchlist_cover_letter_text;
    setTimeout(async () => {
      this.coverLetter.saveAs(`Punchlist_Cover_Letter.pdf`);
    });
  }

  public async getPdfPackage(tenant) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating pdf package...');

    const file = await this.combineFiles(tenant);
    this.progressIndicatorService.updateStatus('Downloading File...');
    await saveAs(file);
    this.progressIndicatorService.close();
  }

  public async currentTenantInfo(tenant?: ProjectTenantConstruction) {
    if (tenant && this.projectTenants.find((t) => t.id === tenant.id).type_id !== 3) {
      const tenantRep = await this.userService.getUserById(tenant.representative_id).toPromise();
      this.currentTenant = {
        first_name: tenantRep.first_name,
        name: `${tenantRep.first_name} ${tenantRep.last_name}`,
        title: tenantRep.title,
        department: tenant.tenant_name,
      };
    } else if (
      tenant &&
      this.projectTenants &&
      tenant.id &&
      this.projectTenants.find((t) => t.id === tenant.id).type_id === 3
    ) {
      this.currentTenant = {
        first_name: this.project.cfmo_first_name,
        name: `${this.project.cfmo_first_name} ${this.project.cfmo_last_name}`,
        title: 'Chief Facilities Management Officer',
        department: 'University Hospitals Authority & Trust',
      };
    } else {
      this.currentTenant = { first_name: '', name: '', title: '', department: '' };
    }
  }

  get pmInfo(): { name: string; title: string; email: string } {
    return {
      name: `${this.project.project_manager_first_name} ${this.project.project_manager_last_name}`,
      title: 'Project Manager',
      email: this.project.project_manager_email,
    };
  }
  get architectInfo(): { name: string; title: string; email: string } {
    if (!this.project.architect_first_name) {
      return null;
    }
    return {
      name: `${this.project.architect_first_name} ${this.project.architect_last_name}`,
      title: 'Architect',
      email: this.project.architect_email,
    };
  }

  public getCurrentProject(): ProjectConstruction {
    return this.project;
  }

  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }

  public viewTask(taskId: number) {
    this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
  }
}
