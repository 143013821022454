import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, HandleErrorService } from 'src/app/services';
import { Event, ReviewChain, ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';
import { TaskAccessoryType, TaskReviewStatus } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class ProjectEventService {
  private host: string = environment.serviceHost;

  private eventURL = `${this.host}/api/v1/events`;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService
  ) {}

  public createTaskApprovalEvent(
    parent_id: number,
    approvalStatus: TaskReviewStatus,
    comment: string,
    accessoryData = null,
    userId = null
  ): Observable<any> {
    userId = userId || this.authService.currentUser.id;
    let message;
    if (accessoryData?.type !== TaskAccessoryType.KeyControls) {
      const currentReviewer: ReviewChain = accessoryData?.reviewChain?.find((review) => review.id === userId);
      if (currentReviewer?.needs_signature) {
        message =
          approvalStatus === TaskReviewStatus.Approved ? '6' : approvalStatus === TaskReviewStatus.Rejected ? '7' : '8';
      } else if (accessoryData?.type === TaskAccessoryType.Submittals) {
        message =
          approvalStatus === TaskReviewStatus.Approved
            ? '9'
            : approvalStatus === TaskReviewStatus.Rejected
            ? '10'
            : '8';
      } else {
        message =
          approvalStatus === TaskReviewStatus.Approved ? '2' : approvalStatus === TaskReviewStatus.Rejected ? '3' : '8';
      }
    } else {
      message =
        approvalStatus === TaskReviewStatus.Approved ? '4' : approvalStatus === TaskReviewStatus.Rejected ? '5' : '8';
    }

    const event: Event = {
      parent_id,
      parent_type_id: 7,
      message,
      comment,
    };

    if (accessoryData?.type === TaskAccessoryType.Submittals) {
      event.staff_only = 1;
    }

    return this.http.post(`${this.eventURL}`, event).pipe(
      map((result: ServiceResponse) => result.data.event),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public createTaskStatusEvent(parent_id: number, message: string, relatedId: number = null): Observable<void> {
    const event = {
      parent_id,
      parent_type_id: 7,
      message,
      related_id: relatedId,
    };
    return this.http.post(`${this.eventURL}`, event).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
