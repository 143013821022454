import { Component, Input, OnInit } from '@angular/core';
import { Building, Floor, Suite, SuiteOccupancy } from 'src/app/types';

@Component({
  selector: 'app-department-manager-dialog-suite',
  templateUrl: './department-manager-dialog-suite.component.html',
  styleUrls: ['./department-manager-dialog-suite.component.scss'],
})
export class DepartmentManagerDialogSuiteComponent implements OnInit {
  @Input() buildings: Building[] = [];
  @Input() suites: Suite[];
  @Input() suiteOccupancy: SuiteOccupancy = { building_id: null };
  floors: Floor[];
  filteredSuites: Suite[];
  // formGroup: FormGroup = this.fb.group({
  //   building_id: [null, [Validators.required]],
  //   floor_id: [null, [Validators.required]],
  //   suite_id: [null, [Validators.required]]
  // });

  constructor() {}

  // get building_id() {
  //   return this.formGroup.get('building_id');
  // }
  // get floor_id() {
  //   return this.formGroup.get('floor_id');
  // }
  // get suite_id() {
  //   return this.formGroup.get('suite_id');
  // }

  ngOnInit(): void {
    // this.building_id.setValue(this.suite.building_id);
    // this.floor_id.setValue(this.suite.floor_id);
    // this.suite_id.setValue(this.suite.id);
    this.getFloors();
  }

  getFloors() {
    const building = this.buildings.find((b) => b.id === this.suiteOccupancy.building_id);
    this.floors = building?.floors || [];
    this.getSuites();
  }

  refreshFloors() {
    this.getFloors();
    this.suiteOccupancy.suite_id = null;
    this.suiteOccupancy.floor_id = null;
    this.getSuites();
  }

  getSuites() {
    this.filteredSuites = this.suites?.filter((s) => s.floor_id === this.suiteOccupancy.floor_id);
  }

  refreshSuites() {
    this.suiteOccupancy.suite_id = null;
    this.getSuites();
  }
}
