<div class="modal-wide" style="width: auto">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="dkblue m-0 font-weight-semibold">Review And Finalize PEB</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row" *ngFor="let task of pebTasks$ | async">
    <div class="col-7">{{ task.title | stringShrink: 38 }}</div>
    <div class="col-3">{{ task.status_name }}</div>
    <button (click)="viewTask(task.id)">View</button>
  </div>
  <div class="row mt-4" style="padding-left: 15px; padding-right: 15px" *ngIf="!tenant.selected_peb_id">
    <div>Please make a PEB selection for this tenant *</div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-select [placeholder]="'Select A PEB'">
        <mat-option
          *ngFor="let peb of tenantPebs"
          [value]="peb"
          name="peb"
          ngDefaultControl
          [(ngModel)]="selectedPeb"
          (onSelectionChange)="selectPeb($event.source.value)"
        >
          {{ peb.title || 'PEB #' + peb.sequence }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row mt-4">
    <div class="col">
      <button mat-button (click)="cancel()" class="full-width gray">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button class="full-width" color="success" (click)="submit()" [disabled]="!canFinalize()">
        Finalize
      </button>
    </div>
  </div>
</div>
