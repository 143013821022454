import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService, ProgressIndicatorService } from 'src/app/services';
import { Building, Floor, Suite } from 'src/app/types';

@Component({
  selector: 'app-suites-manager-dialog',
  templateUrl: './suites-manager-dialog.component.html',
  styleUrls: ['./suites-manager-dialog.component.scss'],
})
export class SuitesManagerDialogComponent implements OnInit {
  buildings: Building[];
  floors: Floor[];
  suite = this.data.suite ? JSON.parse(JSON.stringify(this.data.suite)) : { building_id: null };
  action = this.data.action;
  suiteFormGroup: FormGroup = this.fb.group({
    name: [this.suite.name ? this.suite.name : '', [Validators.required]],
    building_id: [this.suite.building_id ? this.suite.building_id : null, [Validators.required]],
    floor_id: [this.suite.floor_id ? this.suite.floor_id : null, [Validators.required]],
    is_enabled: [this.suite.is_enabled],
  });
  loading = true;
  saving = false;

  constructor(
    public dialogRef: MatDialogRef<SuitesManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private locationService: LocationService,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  get name() {
    return this.suiteFormGroup.get('name');
  }
  get building_id() {
    return this.suiteFormGroup.get('building_id');
  }
  get floor_id() {
    return this.suiteFormGroup.get('floor_id');
  }

  async ngOnInit(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading suite...');
    const buildingFields = ['id', 'name', 'floors'];
    this.buildings = await this.locationService.getBuildings(buildingFields).toPromise();
    this.getFloors();
    this.loading = false;
    this.progressIndicatorService.close();
  }

  async getFloors() {
    const building = this.buildings.find((b) => b.id === this.building_id.value);
    this.floors = building?.floors || [];
  }

  async updateFloors() {
    this.getFloors();
    this.floor_id.setValue(null);
  }

  async save() {
    if (!this.suiteFormGroup.valid) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const suiteForm = this.suiteFormGroup.value;

    const suiteToSave: Suite = {};
    if (suiteForm.name !== this.data.suite.name) {
      suiteToSave.name = suiteForm.name;
    }
    if (suiteForm.is_enabled !== this.data.suite.is_enabled) {
      suiteToSave.is_enabled = suiteForm.is_enabled ? 1 : 0;
    }
    if (suiteForm.floor_id !== this.data.suite.floor_id) {
      suiteToSave.floor_id = suiteForm.floor_id;
    }

    if (Object.keys(suiteToSave).length > 0) {
      if (this.suite.id) {
        await this.locationService
          .updateSuite(this.suite.id, suiteToSave)
          .toPromise()
          .then(() => {});
      } else {
        delete suiteToSave.is_enabled;
        await this.locationService
          .addSuite(suiteToSave)
          .toPromise()
          .then((newSuite) => {
            this.suite.id = newSuite.id;
          });
      }
    }

    this.progressIndicatorService.close();
    this.close(true);
    this.snackbar.open('Saved');
  }

  close(reload = false) {
    this.dialogRef.close(reload);
  }
}
