<div class="container-fluid p-4">
  <div class="row align-items-center component-subheader">
    <div class="col">
      <h4 class="dkblue font-weight-semibold m-0">Project Timeline</h4>
    </div>
    <div class="col-auto text-right"></div>
  </div>
  <!-- ONLY AVAILABLE FOR PM OR WM -->
  <div class="mt-5 row">
    <div class="col">
      <div class="row mb-5">
        <div class="col-auto" *ngIf="isAdmin">
          <div class="row mb-3">
            <div class="col">
              <h6 class="m-0 font-weight-semibold dkblue">Project Status</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--            TODO Adam which color do we want for Planned?-->
              <button
                matRipple
                class="task-status-main text-capitalize font-weight-semibold white pointer ease"
                [ngClass]="{
                  'bg-green': project.status.id === ProjectStatus.ACTIVE,
                  'bg-ltblue': project.status.id === ProjectStatus.PLANNED,
                  'bg-red': project.status.id === ProjectStatus.ON_HOLD,
                  'bg-gray': project.status.id === ProjectStatus.CLOSED
                }"
              >
                {{ project.status.name }}
              </button>

              <!--            TODO Adam which color do we want for Planned?-->
              <button
                matRipple
                [matMenuTriggerFor]="task_status_menu"
                class="white task-status-drop pointer ease"
                [ngClass]="{
                  'bg-green': project.status.id === ProjectStatus.ACTIVE,
                  'bg-ltblue': project.status.id === ProjectStatus.PLANNED,
                  'bg-red': project.status.id === ProjectStatus.ON_HOLD,
                  'bg-gray': project.status.id === ProjectStatus.CLOSED
                }"
              >
                <i class="material-icons">keyboard_arrow_down</i>
              </button>

              <!--            TODO do we want functionality to set to planned here?-->
              <mat-menu #task_status_menu="matMenu" xPosition="after">
                <button (click)="changeProjectStatus(ProjectStatus.ACTIVE)" mat-menu-item color="primary">
                  <mat-icon>label_important</mat-icon>
                  <span> Set to Active </span>
                </button>
                <button (click)="changeProjectStatus(ProjectStatus.PLANNED)" mat-menu-item color="primary">
                  <mat-icon>emoji_objects</mat-icon>
                  <span> Mark as Planned </span>
                </button>
                <button (click)="changeProjectStatus(ProjectStatus.ON_HOLD)" mat-menu-item color="primary">
                  <mat-icon>flag</mat-icon>
                  <span> Place On Hold </span>
                </button>
                <button (click)="changeProjectStatus(ProjectStatus.CLOSED)" mat-menu-item color="primary">
                  <mat-icon>done_all</mat-icon>
                  <span> Close Project </span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="isAdmin || isArchitect">
          <div class="row mb-3">
            <div class="col">
              <h6 class="m-0 font-weight-semibold dkblue">Schedule</h6>
            </div>
          </div>
          <div class="row align-items-top">
            <div class="col-auto">
              <button
                (click)="setOnSchedule(true)"
                class="timeline-left pointer font-weight-semibold ease"
                [ngClass]="{ 'white bg-green': onSchedule }"
              >
                On Schedule
              </button>
              <button
                (click)="setOnSchedule(false)"
                class="timeline-right pointer font-weight-semibold ease"
                [ngClass]="{ 'white bg-red': !onSchedule }"
              >
                Behind Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isAdmin || isArchitect" class="row mb-5">
        <div class="col-auto pr-5">
          <span class="d-inline-block align-top">
            <h6 class="dkblue m-0 font-weight-semibold mb-1">Estimated Construction Start:</h6>
            <h5 (click)="startDatePicker.open()" class="m-0 ltblue pointer font-weight-semibold">
              <span *ngIf="constructionStartDate !== null" class="font-weight-normal ease">
                {{ constructionStartDate }}
              </span>
              <span *ngIf="constructionStartDate === null" class="gray font-weight-normal ease"> Not Set </span>
            </h5>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              [(ngModel)]="project.construction_start_date"
              (dateChange)="setStartDate(project.construction_start_date)"
              autocomplete="off"
              style="visibility: hidden; width: 0"
            />
            <mat-datepicker #startDatePicker [calendarHeaderComponent]="customDateHeader"></mat-datepicker>
          </span>
          <!-- ONLY AVAILABLE IF WM OR PM -->
          <button (click)="startDatePicker.open()" mat-icon-button color="accent" class="ml-4 d-inline-block align-top">
            <mat-icon>today</mat-icon>
          </button>
        </div>
        <div class="col">
          <span class="d-inline-block align-top">
            <h6 class="dkblue m-0 font-weight-semibold mb-1">Estimated Completion:</h6>
            <h5 (click)="completionDatePicker.open()" class="m-0 gray font-weight-semibold">
              <span class="font-weight-normal ease ltblue pointer">
                {{ projectEndDate }}
              </span>
            </h5>
            <input
              matInput
              [matDatepicker]="completionDatePicker"
              [(ngModel)]="project.completion_date"
              (dateChange)="setEndDate(project.completion_date)"
              autocomplete="off"
              style="visibility: hidden; width: 0"
            />
            <mat-datepicker #completionDatePicker [calendarHeaderComponent]="customDateHeaderNoClear"></mat-datepicker>
          </span>
          <!-- ONLY AVAILABLE IF WM OR PM -->
          <button
            (click)="completionDatePicker.open()"
            mat-icon-button
            color="accent"
            class="ml-4 d-inline-block align-top"
          >
            <mat-icon>today</mat-icon>
          </button>
        </div>
      </div>
      <!-- <div class="row mb-5" *ngIf="isAdmin">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <p class="m-0 font-weight-semibold dkgray">
                    Active Phase
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="phase-btn" *ngFor="let phase of phases; let first = first; let last = last" [ngClass]="{
                      active_phase: currentPhaseName === phase.name,
                      'phase-btn-left': first,
                      'phase-btn-right': last
                    }"
                    (click)="setPhase(phase)">
                    {{ phase.name }}
                  </button>
                </div>
              </div>
            </div>
          </div> -->
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <div class="row mb-3">
        <div class="col">
          <h6 class="m-0 font-weight-semibold dkgray">Timeline</h6>
        </div>
      </div>
      <div *ngIf="getTimelines().length > 0" class="row align-items-center">
        <div class="col-auto">
          <button
            *ngIf="getTimelines().length != 0 && isWorkspaceStaff"
            mat-flat-button
            (click)="openFileSelectDialog()"
            class="mr-3"
            color="success"
          >
            <i class="material-icons mr-3" *ngIf="!getLatest.downloading">cloud_upload</i>
            <span>Upload New</span>
          </button>
          <button mat-flat-button color="accent" (click)="downloadFile(getLatest)">
            <i class="material-icons mr-3" *ngIf="!getLatest.downloading">cloud_download</i>
            <i class="fas fa-circle-notch fa-spin white mr-3" *ngIf="getLatest.downloading"></i>
            <span>Download Latest</span>
          </button>
        </div>
        <div class="col">
          <h6 class="m-0 d-inline-block dkgray font-weight-semibold">
            <span>
              {{ getLatest.name }}
              <br />
              <small class="gray font-weight-normal">
                {{ getLatest.created_datetime | date: 'MMM d yyyy • h:mmaa' }}
              </small>
            </span>
          </h6>
        </div>
      </div>
      <div *ngIf="getTimelines().length === 0" class="row">
        <div class="col">
          <button mat-flat-button color="accent" (click)="openFileSelectDialog()" *ngIf="isWorkspaceStaff">
            <mat-icon> cloud_upload </mat-icon>
            &nbsp;
            <span> Upload Timeline </span>
          </button>
          <!-- <h6 class="m-0 gray pt-4">No timelines have been uploaded.</h6> -->
        </div>
      </div>
      <div *ngIf="getTimelines().length > 1" class="row mt-5">
        <div class="col">
          <div class="row mb-3">
            <div class="col">
              <h6 class="m-0 font-weight-semibold gray">Previous Versions</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-lg-6">
              <!-- <app-file-chip class="mr-3" *ngFor="let file of getTimelines()" [file]="file" [downloadable]="true"></app-file-chip> -->
              <div class="m-portlet m-portlet--mobile">
                <div
                  class="m-portlet__body item p-2 pl-3 pr-3 ease pointer"
                  *ngFor="let file of getTimelines() | slice: 1"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 dkblue font-weight-semibold">
                        {{ file.name }}
                        <br />
                        <small class="gray font-weight-normal">
                          {{ file.created_datetime | date: 'MMM d yyyy • h:mmaa' }}
                        </small>
                      </h6>
                    </div>
                    <div class="col-auto text-right">
                      <button mat-icon-button color="accent" class="ml-4 ltgray" (click)="downloadFile(file)">
                        <i class="material-icons" *ngIf="!file.downloading">get_app</i>
                        <i class="la la-spinner la-spin" style="font-size: 2em" *ngIf="file.downloading"></i>
                      </button>
                      <!-- ONLY AVAILABLE FOR PM OR WM -->
                      <button mat-icon-button color="accent" class="ltgray" *ngIf="isAdmin" (click)="deleteFile(file)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
