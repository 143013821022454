import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Order, ORDER_FIELD } from 'src/app/enums';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import {
  APIFilter,
  HealthType,
  ServiceResponse,
  WorkOrder,
  WorkOrderChecklistItem,
  WorkOrderChecklistStatus,
  WorkOrderFollower,
  WorkOrderPriority,
  WorkOrderStatus,
  WorkOrderUpdate,
} from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  private _host: string = environment.serviceHost;
  private _workOrderChecklistItemsUrl = `${this._host}/api/v1/work-order-checklist-items`;
  private _workOrderHealthTypessUrl = `${this._host}/api/v1/work-order-health-types`;
  private _workOrderPriorityUrl = `${this._host}/api/v1/work-order-priorities`;
  private _workOrderChecklistStatusUrl = `${this._host}/api/v1/work-order-checklist-statuses`;
  private _workOrderStatusUrl = `${this._host}/api/v1/work-order-statuses`;
  private _workOrderUpdateUrl = `${this._host}/api/v1/work-order-updates`;
  private _workOrderUrl = `${this._host}/api/v1/work-orders`;

  public refreshNeeded$ = new Subject<void>();
  public sideNavBadgeUpdateNeeded$ = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _handleErrorService: HandleErrorService,
    private _apiFilterService: ApiFilterService
  ) {}

  public workOrderFields = [
    'id',
    'code',
    'module_id',
    'module{id,name,workspace_type}',
    'title',
    'status{id,name}',
    'building{id,name,code}',
    'closed_datetime',
    'floor{id,name,code}',
    'suite',
    'department',
    'landmark',
    'due_date',
    'priority{id,name}',
    'summary',
    'topic_group{id,name,building_ids,topic_categories{id,name,topic_group_id,topic_group{id,name},topics{id,name,topic_group_id,topic_type_id,workspace_id}}}',
    'topic_category{id,name,topic_group_id,topic_group{id,name},topics{id,topic_category_id,name,topic_type_id,workspace_id}}',
    'topic{id,name,topic_category_id,topic_category{id,name,topic_group_id,topic_group{id,name,building_ids}},topic_type_id,topic_type{id,name},workspace_id,workspace{id,name}}',
    'followers',
    'assigned_user{id,first_name,last_name,title,department_name}',
    'converted_to_request{id,code}',
    'created_by',
    'created_datetime',
    'requester_id',
    'requester{id,first_name,last_name,title,department_name}',
    'files{id,name,created_datetime,tags,file_id}',
    'landmark',
    'form_submission',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
    'tags{is_enabled,resource_type_ids}',
  ];

  convertWorkOrderToRequest(workOrderId: number): Observable<number> {
    return this._http.post(`${this._workOrderUrl}/${workOrderId}/convert-to-request`, null).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.convertedId;
        return request;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public createWorkOrder(workOrder: WorkOrder, fields?: string[]): Observable<WorkOrder> {
    return this._http.post(`${this._workOrderUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, workOrder).pipe(
      map((result: ServiceResponse) => {
        const createdWorkOrder: WorkOrder = result.data['work order'];
        this.sideNavBadgeUpdateNeeded$.next();
        return createdWorkOrder;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public createWorkOrderUpdate(workOrderUpdate: WorkOrderUpdate, fields?: string[]): Observable<WorkOrderUpdate> {
    return this._http
      .post(`${this._workOrderUpdateUrl}${fields ? `?fields=${fields.join(',')}` : ''}`, workOrderUpdate)
      .pipe(
        map((result: ServiceResponse) => {
          const workOrderUpdateToReturn: WorkOrderUpdate = result.data['work order update'];
          return workOrderUpdateToReturn;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public deleteWorkOrder(workOrderId: number): Observable<void> {
    return this._http.delete(`${this._workOrderUrl}/${workOrderId}`).pipe(
      map(() => null),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public deleteWorkOrderUpdate(workOrderUpdateId: number): Observable<void> {
    return this._http.delete(`${this._workOrderUpdateUrl}/${workOrderUpdateId}`).pipe(
      map(() => null),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public getWorkOrderById(id, fields?: string[]): Observable<WorkOrder> {
    return this._http
      .get(`${this._workOrderUrl}/${id}?fields=${fields?.length ? fields.join(',') : this.workOrderFields.join(',')}`)
      .pipe(
        map((result: ServiceResponse) => {
          const workOrders: WorkOrder[] = result.data['work order'];
          return workOrders[0];
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrderByIdSuppressed(id, fields: string[]): Observable<WorkOrder> {
    return this._http.get(`${this._workOrderUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const workOrders: WorkOrder[] = result.data['work order'];
        return workOrders[0];
      })
    );
  }

  public getWorkOrdersCount(apiFilters: APIFilter[]): Observable<number> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(`${this._workOrderUrl}?limit=1${!filterString || filterString === '' ? '' : `&${filterString}`}`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.count;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrders(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<WorkOrder[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workOrderUrl}?limit=${limit || 10000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const workOrders: WorkOrder[] = result.data.work_orders;
          return workOrders;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrdersAndCursor(
    fields: string[],
    apiFilters: APIFilter[],
    cursor: string = '',
    limit: number = 20,
    sortField: string = ORDER_FIELD.CREATED_DATETIME,
    sortOrder: Order = Order.ASC
  ): Observable<{ count: number; cursor: string; workOrders: WorkOrder[] }> {
    const filter = this._apiFilterService.getFilterString(apiFilters);
    const filterString = filter.trim().split('=')[1] ? filter : '';

    const url = `${this._workOrderUrl}?limit=${limit}${
      sortField ? `&sort=${sortField}` : ''
    }&order=${sortOrder}&${filterString}&fields=${fields.join(',')}${cursor ? `&cursor=${cursor}` : ''}`;

    return this._http.get(url).pipe(
      map((result: ServiceResponse) => {
        const workOrdersAndCursor: { count: number; cursor: string; workOrders: WorkOrder[] } = {
          count: result.count,
          cursor: result.next,
          workOrders: result.data.work_orders,
        };

        return workOrdersAndCursor;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public getWorkOrderHealthTypes(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<HealthType[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workOrderHealthTypessUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { work_order_health_types } }: ServiceResponse): HealthType[] => {
          return work_order_health_types;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrderPriorities(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<WorkOrderPriority[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workOrderPriorityUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { work_order_priorities } }: ServiceResponse): WorkOrderPriority[] => {
          return work_order_priorities;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrderStatuses(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    showAll: boolean = false
  ): Observable<WorkOrderStatus[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workOrderStatusUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { work_order_statuses } }: ServiceResponse): WorkOrderStatus[] => {
          return showAll ? work_order_statuses.concat({ id: -1, name: 'View All' }) : work_order_statuses;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrderUpdates(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<WorkOrder[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._workOrderUpdateUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { work_order_updates } }: ServiceResponse): WorkOrderUpdate[] => {
          return work_order_updates;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public transferToDispatch(workOrderId: number, userComment?: string): Observable<null> {
    return this._http.post(`${this._workOrderUrl}/${workOrderId}/transfer-to-dispatch`, { comment: userComment }).pipe(
      map(() => null),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public updateWorkOrder(workOrderId: number, workOrder: WorkOrder, fields?: string[]): Observable<WorkOrder> {
    return this._http
      .put(`${this._workOrderUrl}/${workOrderId}?${fields ? `fields=${fields.join(',')}` : ''}`, workOrder)
      .pipe(
        map((result: ServiceResponse) => {
          const workOrderToReturn: WorkOrder = result.data['work order'];
          // emit if the assigned user changes, since the badges will need to be updated
          if (workOrder.hasOwnProperty('assigned_user_id')) {
            this.sideNavBadgeUpdateNeeded$.next();
          }
          return workOrderToReturn;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public reactivateWorkOrder(workOrderId: number, fields?: string[]): Observable<WorkOrder> {
    return this._http
      .put(`${this._workOrderUrl}/${workOrderId}/reactivate?${fields ? `fields=${fields.join(',')}` : ''}`, null)
      .pipe(
        map((result: ServiceResponse) => {
          const workOrderToReturn: WorkOrder = result.data['work order'];
          return workOrderToReturn;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public updateWorkOrderFollower(
    workOrderId: number,
    workOrderFollower: WorkOrderFollower,
    fields?: string[]
  ): Observable<WorkOrder> {
    return this._http
      .put(
        `${this._workOrderUrl}/${workOrderId}/followers${fields ? `?fields=${fields.join(',')}` : ''}`,
        workOrderFollower
      )
      .pipe(
        map((result: ServiceResponse) => {
          const workOrderToReturn: WorkOrder = result.data['work order'];
          return workOrderToReturn;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public updateWorkOrderUpdate(
    workOrderUpdateId: number,
    workOrderUpdate: WorkOrder,
    fields?: string[]
  ): Observable<WorkOrder> {
    return this._http
      .put(
        `${this._workOrderUpdateUrl}/${workOrderUpdateId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        workOrderUpdate
      )
      .pipe(
        map((result: ServiceResponse) => {
          const workOrderToReturn: WorkOrder = result.data['work order update'];
          return workOrderToReturn;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public getWorkOrderChecklistItems(woId: number): Observable<WorkOrderChecklistItem[]> {
    return this._http
      .get(
        `${this._workOrderChecklistItemsUrl}?filter=work_order_id=${woId}&fields=id,title,status_id,status,from_topic_template`
      )
      .pipe(
        map(({ data: { work_order_checklist_items } }: ServiceResponse): WorkOrderChecklistItem[] => {
          return work_order_checklist_items;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public createWorkOrderChecklistItem(checklistItem: WorkOrderChecklistItem): Observable<WorkOrderChecklistItem> {
    return this._http.post(`${this._workOrderChecklistItemsUrl}?fields=id,title,status_id,status`, checklistItem).pipe(
      map((result: ServiceResponse) => {
        const workOrderCheckListItemToReturn: WorkOrderChecklistItem = result.data['work order checklist item'];
        return workOrderCheckListItemToReturn;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public updateWorkOrderChecklistItem(
    workOrderChecklistItemId: number,
    checklistItem: WorkOrderChecklistItem
  ): Observable<WorkOrder> {
    return this._http.put(`${this._workOrderChecklistItemsUrl}/${workOrderChecklistItemId}`, checklistItem).pipe(
      map((result: ServiceResponse) => {
        const workOrderCheckListItemToReturn: WorkOrderChecklistItem = result.data['work order checklist item'];
        return workOrderCheckListItemToReturn;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public deleteWorkOrderChecklistItem(workOrderChecklistItemId: number): Observable<void> {
    return this._http.delete(`${this._workOrderChecklistItemsUrl}/${workOrderChecklistItemId}`).pipe(
      map(() => null),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public getWorkOrderChecklistStatuses(): Observable<WorkOrderChecklistStatus[]> {
    return this._http.get(`${this._workOrderChecklistStatusUrl}?&fields=id,name`).pipe(
      map(({ data: { work_order_checklist_statuses } }: ServiceResponse): WorkOrderChecklistStatus[] => {
        return work_order_checklist_statuses;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }

  public transferToWorkspace(
    workOrderId: number,
    workspaceId: number,
    userComment?: string,
    doUnassign = false
  ): Observable<null> {
    return this._http
      .post(`${this._workOrderUrl}/${workOrderId}/transfer-to-workspace`, {
        comment: userComment,
        workspace_id: workspaceId,
        do_unassign: doUnassign,
      })
      .pipe(
        map(() => null),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }

  public searchWorkOrders(
    fields: string[],
    apiFilters: APIFilter[],
    cursor: string = '',
    limit: number = 20,
    sortField: string = 'created_datetime',
    sortOrder: string = 'desc'
  ): Observable<{ count: number; cursor: string; workOrders: WorkOrder[] }> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);

    const url = `${this._workOrderUrl}?limit=${limit}${
      sortField ? `&sort=${sortField}` : ''
    }&order=${sortOrder}&${filterString}&fields=${fields.join(',')}${cursor ? `&cursor=${cursor}` : ''}`;

    return this._http.get(url).pipe(
      map((result: ServiceResponse) => {
        const workOrdersAndCursor: { count: number; cursor: string; workOrders: WorkOrder[] } = {
          count: result.count,
          cursor: result.next,
          workOrders: result.data.work_orders,
        };

        return workOrdersAndCursor;
      }),
      catchError((e) => this._handleErrorService.handleError(e))
    );
  }
}
