<div
  id="construction-request"
  data-test-id="construction-request"
  class="construction-request container-fluid app-container p-5"
>
  <div class="row align-items-center">
    <div class="col">
      <h3 id="title" class="m-0 font-weight-semibold dkblue">New Request</h3>
    </div>
    <div class="col-auto text-right"></div>
  </div>
  <br />
  <div class="row m-0">
    <div data-aos-delay="50" data-aos="fade-in" data-aos-duration="500" class="col m-portlet m-portlet--mobile">
      <div class="m-portlet__body">
        <div class="m-portlet__body-progress">Loading</div>
        <div class="row">
          <div class="col-lg-12 mx-auto text-center">
            <mat-horizontal-stepper labelPosition="bottom" linear #stepper>
              <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
              </ng-template>
              <mat-step *ngIf="showRequestType" [stepControl]="requestTypeFormGroup">
                <form [formGroup]="requestTypeFormGroup">
                  <ng-template matStepLabel>{{ request_type_labe }} </ng-template>
                  <br /><br /><br />
                  <h2 data-test-id="renovation-request-type-step-headline" class="dkblue font-weight-semibold">
                    Is this request for the space you occupy?
                  </h2>
                  <br /><br /><br />
                  <div class="row">
                    <div data-test-id="request-type-step-choice" class="col-lg-12">
                      <button
                        id="renovation-button"
                        data-test-id="renovation-request-wrapper"
                        (click)="selectRequestType(renovationRequestMethod)"
                        class="custom-btn-lg"
                        [ngClass]="{
                          'custom-btn-lg-selected': request_type.value && request_type.value.id === 2
                        }"
                      >
                        <h3 class="dkblue">Yes</h3>
                        <h5 class="ltblue">I need to renovate my current space.</h5>
                      </button>
                      <button
                        id="new-space-button"
                        data-test-id="new-space-request-wrapper"
                        (click)="selectRequestType(newSpaceRequestMethod)"
                        class="custom-btn-lg"
                        [ngClass]="{
                          'custom-btn-lg-selected': request_type.value && request_type.value.id === 1
                        }"
                      >
                        <h3 class="dkblue">No</h3>
                        <h5 class="ltblue">I need to request a new space.</h5>
                      </button>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div>
                    <button
                      id="step-type-continue"
                      data-test-id="request-type-step-continue-button"
                      [hidden]="!request_type?.value"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                  <br /><br />
                </form>
              </mat-step>

              <!-- LOCATION STEP ONLY REQUIRED FOR RENOVATIONS -->
              <!-- <mat-step [stepControl]="locationFormGroup" *ngIf="request_type.value && request_type.value.id === 2"> -->
              <mat-step [stepControl]="locationFormGroup">
                <form [formGroup]="locationFormGroup">
                  <ng-template matStepLabel>Location</ng-template>
                  <br /><br /><br />
                  <div>
                    <!-- RENO -->
                    <div *ngIf="request_type.value && request_type.value.id === 2">
                      <h2 data-test-id="renovation-location-step-headline" class="dkblue font-weight-semibold">
                        Is this the space you'd like to Renovate?
                      </h2>
                      <h5 data-test-id="renovation-location-step-headline-caption" class="gray">
                        If not, please select the correct location.
                      </h5>
                    </div>

                    <!-- SPACE -->
                    <div *ngIf="request_type.value && request_type.value.id === 1">
                      <h2 data-test-id="new-space-location-step-headline" class="dkblue font-weight-semibold">
                        Do you have a preferred location for the New Space?
                      </h2>
                      <h5 data-test-id="new-space-location-step-headline-caption" class="gray">
                        If this location is not available, we will present an alternate.
                      </h5>
                    </div>

                    <br /><br /><br />
                    <div class="row">
                      <div class="col-xl-8 col-lg-10 mx-auto">
                        <mat-form-field appearance="outline" class="col-lg-6">
                          <mat-label>Building</mat-label>
                          <mat-select
                            id="building-select"
                            data-test-id="location-building-name"
                            matNativeControl
                            (selectionChange)="getFloors()"
                            formControlName="building"
                          >
                            <mat-option label="Building"></mat-option>
                            <mat-option
                              data-test-id="location-building-name-option"
                              class="option-building"
                              *ngFor="let b of buildings"
                              [value]="b"
                              >{{ b.name }}</mat-option
                            >
                          </mat-select>
                          <mat-error *ngIf="building?.errors?.required" data-test-id="building-error"
                            >Building is required</mat-error
                          >
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-lg-6">
                          <mat-label>Floor</mat-label>
                          <mat-select
                            id="floor-select"
                            data-test-id="location-floor-name"
                            matNativeControl
                            (selectionChange)="getSuites()"
                            formControlName="floor"
                          >
                            <mat-option label="Floor"></mat-option>
                            <mat-option
                              data-test-id="location-floor-name-option"
                              class="option-floor"
                              *ngFor="let f of floors"
                              [value]="f"
                              >{{ f.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="floor?.errors?.required" data-test-id="floor-error"
                            >Floor is required</mat-error
                          >
                        </mat-form-field>
                        <mat-form-field
                          appearance="outline"
                          class="col-lg-6"
                          *ngIf="request_type.value && request_type.value.id === 2"
                        >
                          <mat-label>Suite</mat-label>
                          <mat-select
                            id="suite-select"
                            data-test-id="location-suite-name"
                            matNativeControl
                            (selectionChange)="getDepartments()"
                            formControlName="suite"
                          >
                            <mat-option label="Suite"></mat-option>
                            <mat-option
                              data-test-id="location-suite-name-option"
                              class="option-suite"
                              *ngFor="let s of suites"
                              [value]="s"
                              >{{ s.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field
                          appearance="outline"
                          class="col-lg-6"
                          *ngIf="request_type.value && request_type.value.id === 2"
                        >
                          <mat-label>Department</mat-label>
                          <mat-select
                            id="department-select"
                            data-test-id="location-department-name"
                            matNativeControl
                            formControlName="department"
                          >
                            <mat-option label="Department"></mat-option>
                            <mat-option
                              data-test-id="location-department-name-option"
                              class="option-department"
                              *ngFor="let d of departments"
                              [value]="d"
                              >{{ d.name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div>
                    <button
                      *ngIf="showRequestType"
                      mat-flat-button
                      matStepperPrevious
                      type="button"
                      data-test-id="location-step-back-button"
                    >
                      Back
                    </button>
                    &nbsp;
                    <!--Renovation-->
                    <button
                      id="renovation-step-location-continue"
                      data-test-id="location-step-continue-button"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                      *ngIf="request_type?.value?.id === 2 && building?.value?.id && floor?.value?.id"
                    >
                      Continue
                    </button>
                    <!--New Space Continue-->
                    <button
                      id="new-space-step-location-continue"
                      data-test-id="location-step-continue-button"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                      *ngIf="request_type?.value?.id === 1 && building?.value?.id"
                    >
                      Continue
                    </button>
                    <!--New Space Skip-->
                    <button
                      data-test-id="location-step-skip-button"
                      mat-button
                      color="accent"
                      matStepperNext
                      type="button"
                      *ngIf="request_type?.value?.id === 1 && !(building.value && building.value.id)"
                    >
                      Skip
                    </button>
                  </div>
                  <br /><br />
                </form>
              </mat-step>

              <mat-step [stepControl]="detailFormGroup">
                <form [formGroup]="detailFormGroup">
                  <ng-template matStepLabel>Details</ng-template>
                  <br /><br /><br />

                  <!-- RENOVATION -->
                  <div [hidden]="!has_rennovation_summary">
                    <!-- <div> -->
                    <h2 data-test-id="renovation-details-step-headline" class="dkblue font-weight-semibold">
                      Tell us about this Renovation.
                    </h2>
                    <h5 data-test-id="renovation-details-step-headline-caption" class="ltblue">
                      Please be as detailed as possible.
                    </h5>
                    <br /><br /><br />
                    <div class="row">
                      <div class="col">
                        <app-editor
                          id="renovation-summary-editor"
                          data-test-id="renovation-summary-editor"
                          #editor
                          placeholder="Renovation Summary*"
                        ></app-editor>
                        <mat-error
                          class="mt-2"
                          *ngIf="summary?.errors?.required && summary.touched"
                          data-test-id="renovation-summary-error"
                          >Summary is required</mat-error
                        >
                      </div>
                    </div>
                  </div>

                  <!-- SPACE REQUEST -->
                  <div *ngIf="request_type.value && request_type.value.id === 1">
                    <div class="row">
                      <div class="col-xl-8 col-lg-10 mx-auto" formArrayName="rooms">
                        <div class="row">
                          <div class="col-12">
                            <h2 data-test-id="new-space-details-step-headline" class="dkblue font-weight-semibold">
                              Tell us about the Space you need.
                            </h2>
                            <h5 data-test-id="new-space-details-step-headline-caption" class="gray">
                              Please list all necessary room types.
                            </h5>
                          </div>
                        </div>
                        <br /><br />
                        <div
                          data-id-test="new-space-room-item-wrapper"
                          class="row room-item"
                          *ngFor="let r of rooms.controls; let i = index; let last = last"
                          [ngClass]="{ 'room-item-last': last }"
                          [formGroupName]="i"
                        >
                          <mat-form-field appearance="outline" class="col-xl-4 col-lg-6 col-sm-6">
                            <mat-label data-id-test="new-space-room-item-type-label">Room Type</mat-label>
                            <mat-select
                              data-id-test="new-space-room-item-type-select"
                              required
                              matNativeControl
                              formControlName="room_type"
                            >
                              <mat-option
                                data-id-test="new-space-room-item-type-option-label"
                                label="Room"
                              ></mat-option>
                              <mat-option
                                data-id-test="new-space-room-item-type-option"
                                *ngFor="let rt of roomTypes"
                                [value]="rt"
                                >{{ rt.name }}
                              </mat-option>
                              <mat-option
                                data-id-test="new-space-room-item-type-option-other"
                                [value]="{ name: 'Other Room Type' }"
                                >Other</mat-option
                              >
                            </mat-select>
                            <mat-error *ngIf="r.get('room_type').errors?.required">Room type is required</mat-error>
                          </mat-form-field>
                          <mat-form-field required appearance="outline" class="col-xl-3 col-lg-6 col-sm-6">
                            <mat-label data-id-test="new-space-room-item-quantity-label">Quantity</mat-label>
                            <input
                              data-id-test="new-space-room-item-quantity-input"
                              required
                              matInput
                              type="number"
                              formControlName="quantity"
                            />
                            <mat-error *ngIf="r.get('quantity').errors?.required">Quantity is required</mat-error>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="col-xl-5 col-lg-12 col-md-12">
                            <mat-label data-id-test="new-space-room-item-comments-label">Comments (Optional)</mat-label>
                            <input
                              data-id-test="new-space-room-item-comments-input"
                              matInput
                              type="text"
                              formControlName="comments"
                            />
                            <i
                              data-id-test="new-space-room-item-remove-icon"
                              *ngIf="rooms.controls && rooms.controls.length > 1"
                              class="fa fa-times red icon-click remove-room"
                              (click)="removeRoomType(i)"
                            ></i>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-xl-8 col-lg-10 mx-auto">
                        <button
                          data-id-test="new-space-room-item-add-button"
                          mat-flat-button
                          class="full-width"
                          color="success"
                          (click)="addRoomType()"
                          type="button"
                        >
                          <i class="la la-plus"></i> Add Room Type
                        </button>
                        <br />
                      </div>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div>
                    <button data-test-id="details-step-back-button" mat-flat-button matStepperPrevious type="button">
                      Back
                    </button>
                    &nbsp;
                    <button
                      id="step-details-continue"
                      data-test-id="details-step-continue-button"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                      [disabled]="!summary.value && has_rennovation_summary"
                    >
                      Continue
                    </button>
                  </div>
                  <br /><br />
                </form>
              </mat-step>
              <!----
              <mat-step [stepControl]="budgetFormGroup">
                <form [formGroup]="budgetFormGroup">
                  <ng-template matStepLabel>Budget</ng-template>
                  <br /><br /><br />
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2
                        data-test-id="renovation-budget-step-headline"
                        class="dkblue font-weight-semibold"
                        *ngIf="request_type.value && request_type.value.id === 2"
                      >
                        What's your budget for this Renovation?
                      </h2>
              ---->
              <!-- SPACE REQUEST -->
              <!----
                      <h2
                        data-test-id="new-space-budget-step-headline"
                        class="dkblue font-weight-semibold"
                        *ngIf="request_type.value && request_type.value.id === 1"
                      >
                        What's your budget for this Space?
                      </h2>

                      <br /><br /><br />
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <mat-radio-group
                            aria-label="Select an option"
                            data-test-id="budget-amount-radio-group"
                            formControlName="project_budget"
                          >
                            <button
                              class="custom-btn-lg budget-button"
                              *ngFor="let pb of projectBudgets"
                              (click)="project_budget.setValue(pb)"
                              [ngClass]="{
                                'custom-btn-lg-selected': project_budget.value && project_budget.value.id === pb.id
                              }"
                            >
                              <h5 class="dkblue">{{ pb.description }}</h5>
                            </button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
              ---->
              <!-- THIS STEP WORKS FOR BOTH TYPES -->
              <!----
                  <br /><br /><br />
                  <div>
                    <button data-test-id="budget-step-back-button" mat-flat-button matStepperPrevious type="button">
                      Back
                    </button>
                    &nbsp;
                    <button
                      id="step-budget-continue"
                      data-test-id="budget-step-continue-button"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                  <br /><br /><br />
                </form>
              </mat-step>
              ---->

              <mat-step [stepControl]="timelineFormGroup">
                <form [formGroup]="timelineFormGroup">
                  <ng-template matStepLabel>Timeline</ng-template>
                  <br /><br /><br />

                  <!-- RENOVATION -->
                  <h2
                    data-test-id="timeline-renovation-headline"
                    class="dkblue font-weight-semibold"
                    *ngIf="request_type.value && request_type.value.id === 2"
                  >
                    When would you like your Renovation to start and finish?
                  </h2>

                  <!-- SPACE REQUEST -->
                  <h2
                    data-test-id="timeline-new-space-headline"
                    class="dkblue font-weight-semibold"
                    *ngIf="request_type.value && request_type.value.id === 1"
                  >
                    How long will you need this Space?
                  </h2>
                  <br /><br /><br />
                  <div class="row">
                    <div class="col-xl-8 col-lg-10 mx-auto">
                      <div class="row">
                        <!-- SPACE REQUEST -->
                        <mat-form-field
                          appearance="outline"
                          class="col-lg-6 mx-auto"
                          *ngIf="request_type.value && request_type.value.id === 1"
                        >
                          <mat-label data-test-id="timeline-new-space-lease-term-label">Lease Term</mat-label>
                          <mat-select
                            data-test-id="timeline-new-space-lease-term-select"
                            required
                            matNativeControl
                            formControlName="lease_term"
                          >
                            <mat-option label="Lease Term"></mat-option>
                            <mat-option
                              data-test-id="timeline-new-space-lease-term-option-less-1"
                              value="Less than 1 Year"
                              >Less than 1 Year</mat-option
                            >
                            <mat-option data-test-id="timeline-new-space-lease-term-option-1-3" value="1-3 Years"
                              >1-3 Years</mat-option
                            >
                            <mat-option data-test-id="timeline-new-space-lease-term-option-3-5" value="3-5 Years"
                              >3-5 Years</mat-option
                            >
                            <mat-option data-test-id="timeline-new-space-lease-term-option-5-10" value="5-10 Years"
                              >5-10 Years</mat-option
                            >
                            <mat-option data-test-id="timeline-new-space-lease-term-option-more-10" value="10 Years"
                              >10+ Years</mat-option
                            >
                          </mat-select>
                          <mat-error
                            data-test-id="timeline-new-space-lease-term-required-error"
                            *ngIf="lease_term.errors?.required"
                            >Lease term is required</mat-error
                          >
                        </mat-form-field>
                        <!-- RENOVATION -->
                        <mat-form-field
                          appearance="outline"
                          class="col-lg-8 mx-auto"
                          *ngIf="request_type.value && request_type.value.id === 2"
                        >
                          <mat-label data-test-id="timeline-renovation-start-date-label">Ideal Start</mat-label>
                          <input
                            data-test-id="timeline-renovation-start-date-input"
                            matInput
                            required
                            id="project-start-datepicker"
                            (click)="startPicker.open()"
                            [matDatepickerFilter]="startDatepickerFilter"
                            [matDatepicker]="startPicker"
                            placeholder="Choose a date"
                            formControlName="start_date"
                          />
                          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                          <mat-datepicker
                            #startPicker
                            [startAt]="startDateMinimum"
                            [calendarHeaderComponent]="customHeader"
                          ></mat-datepicker>
                          <mat-error
                            data-test-id="timeline-renovation-start-date-required-error"
                            *ngIf="start_date.errors?.required"
                            >Start date is required</mat-error
                          >
                        </mat-form-field>
                        <mat-form-field
                          appearance="outline"
                          class="mx-auto"
                          [ngClass]="{
                            'col-lg-8': request_type.value && request_type.value.id === 2,
                            'col-lg-6': request_type.value && request_type.value.id === 1
                          }"
                        >
                          <mat-label data-test-id="timeline-completion-label">Ideal Completion</mat-label>
                          <input
                            matInput
                            required
                            id="project-completion-datepicker"
                            data-test-id="timeline-completion-date-input"
                            (click)="endPicker.open()"
                            [matDatepickerFilter]="completionDatepickerFilter"
                            [matDatepicker]="endPicker"
                            placeholder="Choose a date"
                            formControlName="completion_date"
                          />
                          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                          <mat-datepicker
                            #endPicker
                            [startAt]="completionDateMinimum"
                            [calendarHeaderComponent]="customHeader"
                          ></mat-datepicker>
                          <mat-error
                            *ngIf="completion_date.errors?.required"
                            data-test-id="timeline-completion-date-required-error"
                            >Completion date is required</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div>
                    <button data-test-id="timeline-step-back-button" mat-flat-button matStepperPrevious type="button">
                      Back
                    </button>
                    &nbsp;
                    <button
                      id="timeline-step-continue-button"
                      data-test-id="timeline-step-continue-button"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                  <br /><br /><br />
                </form>
              </mat-step>

              <mat-step [stepControl]="extrasFormGroup">
                <form [formGroup]="extrasFormGroup">
                  <ng-template matStepLabel>Extras</ng-template>
                  <br /><br /><br />
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                          <h2 data-test-id="extra-step-headline" class="dkblue text-center font-weight-semibold">
                            Add Followers
                          </h2>
                          <h5 class="dkgray font-weight-normal text-center mb-4">
                            Add anyone to receive notifications about this request.
                          </h5>
                          <div>
                            <div class="row text-center align-items-center">
                              <div class="col" formArrayName="contacts">
                                <button *ngIf="currentUser" class="contact p-2 pl-4 pr-4">
                                  <div class="row align-items-center">
                                    <div class="col-auto">
                                      <app-profile-thumbnail
                                        class="d-inline-block align-middle"
                                        [width]="40"
                                        [height]="40"
                                        [userId]="currentUser.id"
                                      ></app-profile-thumbnail>
                                    </div>
                                    <div class="col text-left pl-0">
                                      <h5 class="dkblue font-weight-semibold m-0">
                                        {{ currentUser.first_name }} {{ currentUser.last_name }}
                                      </h5>
                                      <p class="m-0 ltblue font-weight-semibold">Requestor (You)</p>
                                    </div>
                                  </div>
                                </button>
                                <button
                                  *ngFor="let c of contacts.controls; let i = index"
                                  [formGroupName]="i"
                                  class="contact p-2 pl-4 pr-4"
                                >
                                  <div class="row align-items-center">
                                    <div class="col-auto">
                                      <app-profile-thumbnail
                                        class="d-inline-block align-middle"
                                        [width]="40"
                                        [height]="40"
                                        [userId]="c.value.id"
                                      ></app-profile-thumbnail>
                                    </div>
                                    <div class="col pl-0 text-left">
                                      <h5 class="dkblue font-weight-semibold m-0">
                                        {{ c.value.first_name }} {{ c.value.last_name }}
                                      </h5>
                                      <p class="m-0 ltblue font-weight-semibold">
                                        <span> Follower </span>
                                        <span class="gray ml-2 mr-2"> | </span>
                                        <span (click)="removeContact(i)" class="gray pointer"> Remove </span>
                                      </p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <button
                            data-test-id="extra-step-add-contacts-button"
                            class="mat-flat-button mat-success add-contact"
                            type="button"
                            (click)="openBulkSelectModal()"
                          >
                            Add Followers
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div class="row">
                    <div class="col-xl-8 col-lg-10 mx-auto">
                      <div class="row">
                        <div class="col-lg-8 mx-auto text-center">
                          <h2 class="dkblue text-center font-weight-semibold">Attachments</h2>
                          <h5 class="gray text-center mb-4">Upload any files pertaining to this request.</h5>
                          <div formArrayName="files">
                            <button *ngFor="let f of attachedFiles; let i = index" class="contact p-2 pl-4 pr-4">
                              <div *ngIf="f.name" class="row align-items-center">
                                <div class="col text-left">
                                  <h6 class="dkblue font-weight-semibold m-0">
                                    {{ f.name }}
                                  </h6>
                                  <p (click)="removeFile(i)" class="m-0 gray pointer font-weight-semibold">Remove</p>
                                </div>
                              </div>
                            </button>
                            <br />
                            <button
                              data-test-id="extra-step-upload-file-button"
                              mat-stroked-button
                              color="accent"
                              class="mt-4"
                              (click)="openUploadModal()"
                              type="button"
                            >
                              Upload File
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br /><br /><br /><br />
                  <div>
                    <button data-test-id="extra-step-back-button" mat-flat-button matStepperPrevious type="button">
                      Back
                    </button>
                    &nbsp;
                    <button
                      data-test-id="extra-step-continue-button"
                      id="step-extras-continue"
                      mat-flat-button
                      color="success"
                      matStepperNext
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                </form>
                <br /><br /><br />
              </mat-step>

              <mat-step>
                <form (ngSubmit)="createRequest()">
                  <ng-template matStepLabel>Overview</ng-template>
                  <br /><br /><br />
                  <div class="row">
                    <div class="col-xl-8 col-lg-10 mx-auto">
                      <div class="row">
                        <div class="col-xl-10 col-lg-12 mx-auto text-left">
                          <h2 class="dkblue text-center font-weight-semibold">Overview</h2>
                          <h5 class="gray text-center">Please review this information carefully.</h5>
                          <br /><br />
                          <mat-card>
                            <mat-card-content>
                              <div class="row">
                                <div class="col-sm-6">
                                  <label class="main-label">Type</label>
                                  <h5 class="dkblue">
                                    {{ request_type.value ? request_type.value.name : null }}
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div class="row">
                                <div class="col-sm-6" *ngIf="building.value">
                                  <label class="main-label uppercase">Building</label>
                                  <h5 class="dkblue">{{ building.value.name }}</h5>
                                </div>
                                <div class="col-sm-6" *ngIf="floor.value">
                                  <label class="main-label">Floor</label>
                                  <h5 class="dkblue">{{ floor.value.name }}</h5>
                                </div>
                              </div>
                              <br *ngIf="building.value || floor.value" />

                              <!-- <div class="row" *ngIf="project_budget.value">
                                <div class="col-sm-6">
                                  <label class="main-label">Project Budget</label>
                                  <h5 class="dkblue">
                                    {{ project_budget.value.description }}
                                  </h5>
                                </div>
                              </div>
                              <br /> -->
                              <div class="row">
                                <div
                                  class="col-sm-6 text-left"
                                  *ngIf="request_type.value && request_type.value.id === 1"
                                >
                                  <label class="main-label">Lease Term</label>
                                  <h5 class="dkblue">
                                    {{ lease_term.value || 'No lease term provided' }}
                                  </h5>
                                </div>
                                <div
                                  class="col-sm-6 text-left"
                                  *ngIf="request_type.value && request_type.value.id === 2"
                                >
                                  <label class="main-label">Project Start</label>
                                  <h5 class="dkblue">
                                    {{ (start_date.value | date: 'MMMM d, yyyy') || 'No start date provided' }}
                                  </h5>
                                </div>
                                <div class="col-sm-6 text-left" *ngIf="completion_date.value">
                                  <label class="main-label">Ideal Completion</label>
                                  <h5 class="dkblue">
                                    {{
                                      (completion_date.value | date: 'MMMM d, yyyy') || 'No completion date provided'
                                    }}
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div class="row">
                                <div class="col-sm-6 text-left">
                                  <label class="main-label d-block">Requestor</label>
                                  <h5 *ngIf="currentUser" class="d-inline-block dkblue sm-contact">
                                    {{ currentUser.first_name }} {{ currentUser.last_name }}
                                  </h5>
                                </div>
                                <div class="col-sm-6 text-left">
                                  <label class="main-label d-block">Added Followers</label>
                                  <h5 *ngIf="!contacts.controls || contacts.controls.length === 0">
                                    No followers provided
                                  </h5>
                                  <h5
                                    *ngFor="let c of contacts.controls; let i = index"
                                    class="d-inline-block dkblue sm-contact"
                                  >
                                    {{ c.value.first_name }} {{ c.value.last_name
                                    }}{{ i + 1 === contacts.controls.length ? '' : ',' }}
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div class="row">
                                <div class="col-sm-12 text-left">
                                  <label class="main-label d-block">Attachments</label>
                                  <h5 *ngIf="!attachedFiles?.length">No attachments provided</h5>
                                  <h5
                                    *ngFor="let a of attachedFiles; let i = index"
                                    class="d-inline-block dkblue sm-contact"
                                  >
                                    {{ a.name }}{{ i + 1 === attachedFiles.length ? '' : ',' }}
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <!-- NEW SPACE -->
                              <div *ngIf="request_type.value && request_type.value.id === 1">
                                <label class="main-label">Details</label>
                                <br />
                                <div class="row" *ngFor="let r of rooms.value">
                                  <div *ngIf="r.room_type">
                                    <div class="col-sm-12 text-left">
                                      <h5 class="dkblue">
                                        {{ r.quantity }} {{ r.room_type.name }}{{ r.quantity !== 1 ? 's' : '' }}
                                      </h5>
                                      <h6 class="gray">{{ r.comments }}</h6>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </div>
                              <!-- RENOVATION -->
                              <div *ngIf="has_rennovation_summary">
                                <!-- <div> -->
                                <div class="row">
                                  <div class="col-sm-12 text-left">
                                    <label class="main-label">Summary</label>
                                    <h6 class="dkblue" [innerHTML]="summary.value || 'No summary provided'"></h6>
                                  </div>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br /><br /><br />
                  <div>
                    <button data-test-id="overview-step-back-button" mat-flat-button matStepperPrevious type="button">
                      Back
                    </button>
                    &nbsp;
                    <button
                      mat-flat-button
                      id="submit-request-button"
                      data-test-id="overview-step-submit-button"
                      *ngIf="!loaders.submitRequest"
                      color="success"
                      type="submit"
                      style="width: 131px"
                    >
                      Submit Request
                    </button>
                    <button
                      data-test-id="overview-step-successful-submission-button"
                      mat-flat-button
                      *ngIf="loaders.submitRequest"
                      color="success"
                      type="button"
                      style="width: 131px"
                    >
                      <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
                    </button>
                  </div>
                </form>
                <br /><br /><br />
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
