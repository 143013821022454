import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-floor-dialog',
  templateUrl: './floor-dialog.component.html',
  styleUrls: ['./floor-dialog.component.scss'],
})
export class FloorDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FloorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}
  floor = this.data.floor;
  action = this.data.action;
  floorFormGroup: FormGroup = this.fb.group({
    name: [this.floor && this.floor.name ? this.floor.name : '', [Validators.required]],
    code: [this.floor && this.floor.code ? this.floor.code : ''],
  });

  get name() {
    return this.floorFormGroup.get('name');
  }
  get code() {
    return this.floorFormGroup.get('code');
  }

  ngOnInit(): void {}

  save() {
    if (!this.floorFormGroup.valid) {
      return;
    }
    this.dialogRef.close(this.floorFormGroup.value);
  }

  cancel() {
    this.dialogRef.close();
  }
}
