import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuleService } from '../../services';
import { Workspace } from '../../types';
import { WorkspaceType, Workspace as Ws } from '../../enums';
import { EditorComponent } from '..';

@Component({
  selector: 'app-transfer-work-order-dialog',
  templateUrl: './transfer-work-order-dialog.component.html',
  styleUrls: ['./transfer-work-order-dialog.component.scss'],
})
export class TransferWorkOrderDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  public currentWorkspace: Workspace;

  public selectedWorkspace: Workspace;
  public workspaces: Workspace[];
  public workspaceFields = ['id', 'name', 'workspace_type'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TransferWorkOrderDialogComponent>,
    private workspaceService: ModuleService
  ) {}

  async ngOnInit() {
    // Get all the workspaces from the backend
    this.workspaces = await this.workspaceService.getWorkspaces(this.workspaceFields).toPromise();

    // Defaults to Dispatch for the select input
    this.selectedWorkspace = this.workspaces.find((ws) => ws.id === Ws.Dispatch);

    // Stores The Work orders current workspace
    this.currentWorkspace = this.data.currentWorkspace || null;
  }

  // Filters out which work orders to display by workspace type.
  get filteredWorkspaces() {
    return this.workspaces?.filter((ws) => this.currentWorkspace.id !== ws.id);
  }

  get textAreaValue(): AbstractControl {
    return this._editor_component.content;
  }

  continue() {
    this.dialogRef.close({
      reason: this.textAreaValue.value,
      selectedWorkspace: this.selectedWorkspace,
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  isValidToSubmit(): boolean {
    return this.selectedWorkspace && this.textAreaValue.value;
  }

  public selectWorkspace(workspaceId) {
    this.selectedWorkspace = workspaceId;
  }
}
