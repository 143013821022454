<div class="container-fluid bg">
  <div class="row">
    <div class="col-lg-6 login_left" [style.backgroundImage]="'url(../../assets/img/login_bg_' + imageNumber + '.jpg)'">
      <img id="main_logo" src="../assets/img/login_logo.svg" alt="" />
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-6 login_right">
          <h1 class="dkblue">Greetings, {{ first_name.value }}.</h1>
          <br />
          <h5 class="gray" *ngIf="!ldap_id">
            You've been invited to the 1Call system. Please complete your information below.
          </h5>
          <h5 class="gray" *ngIf="ldap_id">
            You've been invited to the 1Call system. Your account has been created. Please click the button below to
            navigate to the login page.
          </h5>
          <br />
          <form [formGroup]="completeFormGroup" (ngSubmit)="confirmAccount()" *ngIf="!ldap_id">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="first_name" type="text" />
              <!-- <mat-icon matSuffix>mail_outline</mat-icon> -->
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="last_name" type="text" />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Company</mat-label>
              <input matInput formControlName="company_name" type="text" />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="email" type="text" />
              <!-- <mat-icon matSuffix>mail_outline</mat-icon> -->
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Create Password</mat-label>
              <input matInput id="password" formControlName="password" type="password" />
              <mat-error *ngIf="password.errors?.required">Password Is Required</mat-error>
              <mat-error *ngIf="!password.errors?.required && password.errors?.minlength"
                >Password Must Be At Least 8 Characters</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput id="confirm_password" formControlName="confirm_password" type="password" />
              <mat-error *ngIf="confirm_password.errors?.required">Password Confirmation is Required</mat-error>
              <mat-error *ngIf="confirm_password.errors?.match">Passwords Must Match</mat-error>
            </mat-form-field>
            <!-- <mat-checkbox class="keep-me-signed-in dkblue">Keep me signed in</mat-checkbox> -->
            <br /><br />
            <button mat-raised-button color="primary" type="submit" [disabled]="!completeFormGroup.valid">
              SUBMIT
            </button>
          </form>
          <button type="button" *ngIf="ldap_id" (click)="confirmAccount()">Continue to Login</button>
          <!-- <button mat-raised-button color="primary" (click)="debug()">DEBUG</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
