<div #mainScreen class="container-fluid p-4">
  <div #mainScreen class="row align-items-center mb-3">
    <div class="col-auto">
      <h4 class="dkblue m-0 font-weight-semibold">
        <span class="d-inline-block align-middle mr-2"> Drawings </span>
      </h4>
    </div>
    <div class="col list-filters text-right" *ngIf="projectDrawings.length > 0">
      <mat-form-field class="d-inline-block align-middle" style="width: 200px" appearance="outline">
        <mat-icon class="ltgray" matSuffix *ngIf="!searchString">search</mat-icon>
        <input matInput [(ngModel)]="searchString" placeholder="Search Drawings" />
        <mat-icon matSuffix *ngIf="searchString" (click)="searchString = ''" class="ltgray pointer">cancel</mat-icon>
      </mat-form-field>
      <button
        mat-flat-button
        type="button"
        color="success"
        class="ml-3"
        (click)="createProjectDrawing()"
        *ngIf="allowEdit"
      >
        <mat-icon> cloud_upload </mat-icon>
        &nbsp;
        <span> New Drawing </span>
      </button>
    </div>
  </div>
  <br />
  <!-- IF NO DRAWINGS HAVE BEEN UPLOADED -->
  <div class="row mt-5" *ngIf="!pageLoad && projectDrawings.length === 0">
    <div class="col text-center">
      <h3 class="dkblue font-weight-semibold m-0 mb-2">There are no drawings for this project.</h3>
      <h6 class="m-0 mb-4 gray" *ngIf="allowEdit">Click the button below to add the first drawing.</h6>
      <h6 class="m-0 mb-4 gray" *ngIf="!allowEdit">Contact the Project Architect to add drawings.</h6>
      <button mat-flat-button type="button" color="success" (click)="createProjectDrawing()" *ngIf="allowEdit">
        <mat-icon> cloud_upload </mat-icon>
        &nbsp;
        <span> New Drawing </span>
      </button>
    </div>
  </div>
  <div class="m-portlet" m-portlet="true" *ngIf="projectDrawings.length > 0">
    <div *ngFor="let d of projectDrawings | projectDrawingFilter: searchString">
      <div class="m-portlet__body item p-2 pl-3">
        <div class="row align-items-center line-item">
          <div class="col">
            <div class="row align-items-center">
              <!-- <div class="col-auto pr-0">
                    <h5 class="m-0 pl-2 mr-2 ltblue font-weight-semibold d-inline-block align-middle pointer">
                      <i class="dkblue mr-2 la la-file-pdf-o" *ngIf="['pdf'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-image-o" *ngIf="['png','jpg','tiff','gif','bmp'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-excel-o" *ngIf="['xls','xlsx'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-archive-o" *ngIf="['zip'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-word-o" *ngIf="['doc','docx'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-powerpoint-o" *ngIf="['ppt','pptx'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file-text-o" *ngIf="['txt'].indexOf(d.extension) >= 0"></i>
                      <i class="dkblue mr-2 la la-file" *ngIf="['pdf','png','jpg','tiff','gif','bmp','xls','xlsx','zip','doc','docx','ppt','pptx','txt'].indexOf(d.extension) === -1"></i>
                    </h5>
                  </div> -->
              <div *ngIf="d.editName" class="col">
                <mat-form-field class="d-inline-block align-middle full-width" appearance="outline">
                  <input
                    class="dkblue font-weight-semibold"
                    autofocus
                    matInput
                    placeholder="Drawing Name"
                    [(ngModel)]="d.name"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="!d.editName" class="col pointer" (click)="d.showVersions = !d.showVersions">
                <i class="material-icons dkblue d-inline-block align-middle mr-3">{{
                  d.showVersions ? 'keyboard_arrow_down' : 'chevron_right'
                }}</i>
                <h5 class="m-0 ltblue font-weight-semibold d-inline-block align-middle pointer">
                  {{ d.name }}
                  <span class="ml-2 badge badge-light opacity6" *ngIf="d.latestVersion"
                    >v{{ d.latestVersion.version }}</span
                  >
                </h5>
              </div>
            </div>
          </div>
          <div class="col-auto text-right">
            <p class="m-0 main-label dkgray font-weight-normal mr-3 d-inline-block align-middle">
              {{ d.created_datetime | date: 'MMM d yyyy • h:mma' }}
            </p>
            <div class="d-inline-block align-middle" *ngIf="!d.editName">
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Preview"
                matTooltipPosition="above"
                (click)="previewVersion(d.latestVersion)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon *ngIf="isComplete[d.latestVersion.id]">find_in_page</mat-icon>
                <mat-spinner
                  *ngIf="!isComplete[d.latestVersion.id]"
                  class="d-inline-block align-middle"
                  color="accent"
                  [diameter]="20"
                ></mat-spinner>
              </button>
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Download"
                matTooltipPosition="above"
                (click)="downloadVersion(d.latestVersion)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon *ngIf="!loading[d.latestVersion.id]">get_app</mat-icon>
                <mat-spinner
                  *ngIf="loading[d.latestVersion.id]"
                  class="d-inline-block align-middle"
                  color="accent"
                  [diameter]="20"
                ></mat-spinner>
              </button>
              <button
                (click)="d.editName = !d.editName"
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Rename"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                *ngIf="allowEdit"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="New Version"
                matTooltipPosition="above"
                (click)="uploadNewVersion(d)"
                [matTooltipShowDelay]="300"
                *ngIf="allowEdit"
              >
                <mat-icon>library_add</mat-icon>
              </button>
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Delete"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                (click)="deleteDrawing(d)"
                *ngIf="allowEdit"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="d-inline-block align-middle" *ngIf="d.editName">
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Cancel"
                matTooltipPosition="above"
                (click)="refresh()"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>close</mat-icon>
              </button>
              <button
                mat-icon-button
                class="green ml-2"
                matTooltip="Save"
                matTooltipPosition="above"
                (click)="updateDrawingName(d)"
                [matTooltipShowDelay]="300"
                *ngIf="allowEdit"
              >
                <mat-icon>check</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="d.showVersions">
        <div class="m-portlet__body item bg-ltltgray p-0 ease" *ngFor="let v of d.versions">
          <div class="row align-items-center p-2 pl-4">
            <div class="col-auto">
              <h6 class="m-0 gray">v{{ v.version }}</h6>
            </div>
            <div class="col">
              <h6 class="m-0 dkgray">{{ v.file_name }}</h6>
            </div>
            <div class="col-auto text-right">
              <p class="m-0 main-label gray font-weight-normal">
                {{ v.created_datetime | date: 'MMM d yyyy • h:mma' }}
              </p>
            </div>
            <div class="col-2 text-right">
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Preview"
                matTooltipPosition="above"
                (click)="fileService.previewFile(v)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>find_in_page</mat-icon>
              </button>
              <button
                mat-icon-button
                class="ltgray ml-2"
                matTooltip="Download"
                matTooltipPosition="above"
                (click)="downloadVersion(v)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>get_app</mat-icon>
              </button>
              <!-- <button mat-icon-button class="ltgray ml-2" matTooltip="Edit" matTooltipPosition="above" [matTooltipShowDelay]="300">
                    <mat-icon>edit</mat-icon>
                  </button> -->
              <!-- <button mat-icon-button class="ltgray ml-2" matTooltip="Delete" matTooltipPosition="above" [matTooltipShowDelay]="300" (click)="deleteFile(f)">
                    <mat-icon>delete</mat-icon>
                  </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
