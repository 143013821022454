import { Injectable } from '@angular/core';

import { AuthService, UserService } from 'src/app/services';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';
import { Workspace } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class LoadReviewersService {
  constructor(
    private authService: AuthService,
    public userService: UserService,
    public projectTenantService: ProjectTenantService
  ) {}

  private staffRep = [];
  private tenantRep = [];
  public allReviewers = { staffReviewers: [], tenantReviewers: [], engineerReviewers: [] };

  public async getAllReviewers(currentProject: ProjectConstruction, userIds = null) {
    await this.displayStaffReviewers(currentProject, userIds);
    await this.displayTenantReviewers(currentProject.id);

    return this.allReviewers;
  }

  private async displayStaffReviewers(currentProject: ProjectConstruction, userIds) {
    const projectEng = currentProject.engineer_ids && JSON.parse(currentProject.engineer_ids);
    const hasEngineerReviewer = userIds && projectEng?.find((e) => userIds.includes(e));
    const staffIds = [currentProject.project_manager_id, currentProject.workspace_manager_id, currentProject.cfmo_id];
    const additionalReviewers = userIds?.length && userIds.filter((id) => !staffIds.find((staffId) => staffId === id));

    let staffReviewersIds = (userIds?.length && [...staffIds, ...additionalReviewers]) || staffIds;

    const staffRoles = ['Project Manager', 'Workspace Manager', 'Chief Facilities Management Officer'];

    if (currentProject.module_id === Workspace.Construction) {
      staffReviewersIds = staffReviewersIds.filter((id) => id !== currentProject.architect_id);
      staffRoles.splice(2, 0, 'Architect');
      staffReviewersIds.splice(2, 0, currentProject.architect_id);
    }

    if (this.authService.needsDFSManager(currentProject.module_id) && currentProject.is_dfs_required) {
      staffReviewersIds = staffReviewersIds.filter((id) => id !== currentProject.dfs_id);
      staffRoles.splice(2, 0, 'Chief Technology Officer');
      staffReviewersIds.splice(2, 0, currentProject.dfs_id);
    }

    if (currentProject.project_manager_id === currentProject.workspace_manager_id) {
      staffReviewersIds.shift();
      staffRoles.shift();
    }

    if (projectEng?.length && !hasEngineerReviewer) {
      for (const engineerId of projectEng) {
        staffReviewersIds.push(engineerId);
      }
    }

    this.allReviewers.staffReviewers = [];
    this.allReviewers.engineerReviewers = [];
    await this.userService
      .getUserByIds(staffReviewersIds)
      .toPromise()
      .then((users) => {
        staffReviewersIds.forEach((userId, i) => {
          const index = users.findIndex((user) => user.id === userId);
          if (index !== -1) {
            if (projectEng?.includes(users[index].id) && !hasEngineerReviewer) {
              this.allReviewers.engineerReviewers.push({
                firstName: users[index].first_name,
                lastName: users[index].last_name,
                id: users[index].id,
                title: 'Engineer',
                reviewer: false,
              });
            } else {
              this.allReviewers.staffReviewers.push({
                firstName: users[index].first_name,
                lastName: users[index].last_name,
                id: users[index].id,
                title: (staffRoles && staffRoles[i]) || users[index].title,
                reviewer: false,
              });
            }
          }
        });
      });
  }

  private async displayTenantReviewers(currentProjectId: number) {
    const projectReps = this.tenantRep;

    if (!this.tenantRep.length) {
      const projectTenants = await this.projectTenantService.getTenantsForProject(currentProjectId).toPromise();

      projectTenants.map((tenant) => {
        if (!projectReps.includes(tenant.representative_id)) {
          projectReps.push(tenant.representative_id);
        }
      });
    }
    if (projectReps.length) {
      this.allReviewers.tenantReviewers = [];
      await this.userService
        .getUserByIds(projectReps)
        .toPromise()
        .then((users) => {
          users.map((user) => {
            this.allReviewers.tenantReviewers.push({
              firstName: user.first_name,
              lastName: user.last_name,
              id: user.id,
              title: user.department_name || 'No Department',
              reviewer: false,
            });
          });
        });
    }
  }
}
