<div
  #mainScreen
  (window:resize)="onResize()"
  data-test-id="files-page"
  id="files-page"
  class="files-page container-fliud p-4"
>
  <div class="row align-items-top">
    <div class="col">
      <div class="row align-items-center mb-4">
        <div class="col">
          <h4
            class="m-0 d-inline-block align-middle pr-4 border-right pointer"
            (click)="changeFileDisplay(false)"
            [ngClass]="{
              'dkblue font-weight-semibold': !displayProjectFiles,
              'gray font-weight-semibold': displayProjectFiles
            }"
          >
            <span> All Files </span>
          </h4>
          <h4
            class="m-0 d-inline-block align-middle pl-4 pr-4 pointer"
            (click)="changeFileDisplay(true)"
            [ngClass]="{
              'dkblue font-weight-semibold': displayProjectFiles && selectedPrimaryTags.length === 0,
              'gray font-weight-semibold': !displayProjectFiles || selectedPrimaryTags.length > 0
            }"
          >
            <span> Folders </span>
          </h4>
          <i
            class="material-icons gray d-inline-block align-middle mt-1"
            *ngIf="selectedPrimaryTags.length > 0 && displayProjectFiles"
          >
            chevron_right
          </i>
          <!--            TODO fix the below-->
          <h4
            class="m-0 gray d-inline-block align-middle pl-4 pr-4 pointer"
            (click)="clearSecondary()"
            *ngIf="selectedPrimaryTags.length > 0 && displayProjectFiles"
            [ngClass]="{
              'dkblue font-weight-semibold': selectedSecondaryTags.length === 0,
              'gray font-weight-semibold': selectedSecondaryTags.length !== 0
            }"
          >
            <span> {{ selectedPrimaryTagNames.join('/') }}</span>
          </h4>
          <i
            class="material-icons gray d-inline-block align-middle mt-1"
            *ngIf="selectedSecondaryTags.length > 0 && displayProjectFiles"
          >
            chevron_right
          </i>
          <h4
            class="m-0 d-inline-block align-middle pl-4 pr-4 pointer"
            *ngIf="selectedSecondaryTags.length > 0 && displayProjectFiles"
            [ngClass]="{
              'dkblue font-weight-semibold': selectedSecondaryTags.length > 0
            }"
          >
            <span>{{ selectedSecondaryTagNames.join('/') }}</span>
          </h4>
        </div>
        <div class="col-auto">
          <button
            *ngIf="shownFiles.length > 1"
            id="download-project-files"
            class="bg-white pl-2"
            mat-stroked-button
            color="accent"
            [matTooltip]="'Download ' + filesBeingDownloaded + ' files'"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            (click)="downloadAllFiles()"
            [disabled]="isDownloadingAllFiles"
          >
            <mat-icon> get_app </mat-icon>
            <span class="ml-2"> Download {{ filesBeingDownloaded }} </span>
          </button>
          <input [hidden]="true" #uploader (click)="openFileSelectDialog()" />
          <button
            mat-flat-button
            class="ml-3"
            color="success"
            (click)="uploader.click()"
            matTooltip="Upload File"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
          >
            <mat-icon> cloud_upload </mat-icon>
            <span class="ml-2"> Upload </span>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <mat-form-field class="full-width" appearance="outline">
          <mat-icon color="primary" matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="searchString" placeholder="Search Files" />
          <mat-icon matSuffix [hidden]="!searchString" (click)="searchString = ''" class="gray pointer"
            >cancel</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="m-portlet" m-portlet="true">
        <div *ngFor="let tag of displayFolders | filter: searchString" class="m-portlet__body item ease p-0 p-3">
          <div class="row align-items-center pointer" (click)="toggleTag(tag)">
            <div class="col-auto pr-0">
              <h1 class="m-0 ml-2">
                <i style="font-size: 2.5rem" class="ltblue fa fa-folder"></i>
              </h1>
            </div>
            <div class="col">
              <h6 class="m-0 dkblue font-weight-semibold">
                <span class="d-inline-block align-bottom">
                  {{ tag.folder_name }}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div
          *ngFor="
            let f of filteredFiles | filter: searchString | orderByField: projectFieldToSortBy:projectSortDirection
          "
          class="m-portlet__body item ease p-0 p-3"
        >
          <div class="row align-items-center">
            <div class="col-auto pr-0">
              <app-file-thumbnail width="40" height="40" [file]="f"></app-file-thumbnail>
            </div>
            <div class="col">
              <p
                class="m-0 dkblue font-weight-semibold"
                style="margin-bottom: -2px !important"
                [ngClass]="{ limitTitle: !showDesktop }"
              >
                {{ f.name }}
              </p>
              <label class="dkgray m-0 main-label font-weight-normal" style="font-size: 0.9rem">
                <span> Added {{ f.created_datetime | date: 'MMM d yyyy • h:mma' }} </span>
                <span class="ml-1 mr-1"> • </span>
                <span class="dkgray"> {{ f.created_by_first_name }} {{ f.created_by_last_name }} </span>
                <ng-container *ngIf="f.created_by_user_company">
                  <span class="ml-1 mr-1"> • </span>
                  <span class="dkgray"> {{ f.created_by_user_company }} </span>
                </ng-container>
              </label>
            </div>
            <div class="col-auto pr-0 text-right d-flex align-items-center">
              <span
                *ngFor="let tag of f.tags"
                class="
                  file-tag
                  ease
                  d-inline-block
                  align-middle
                  font-weight-semibold
                  pl-2
                  pr-2
                  bg-white
                  ltblue
                  border
                  ml-3
                  item item-last
                "
                [ngClass]="{ ltblue: +tag.tag_parent_id !== 0, dkblue: +tag.tag_parent_id === 0 }"
              >
                {{ tag.name }}
              </span>
              <span class="d-flex align-items-center ml-3" *ngIf="f.parent_type_id === ResourceType.WorkOrder">
                <mat-icon class="orange mr-2">description</mat-icon>
                <a
                  href="#"
                  target="_blank"
                  class="text-decoration-none ltblue font-weight-semibold hover-u pointer mr-auto"
                  [routerLink]="['/work-orders/' + f.parent_id]"
                >
                  <span class="font-weight-semibold h5 m-0">WO-{{ f.parent_id }}</span>
                </a>
              </span>
            </div>
            <div class="col-auto text-right d-flex align-items-center">
              <button
                *ngIf="isComplete[f.id]"
                mat-icon-button
                color="accent"
                matTooltip="View"
                matTooltipPosition="above"
                (click)="previewFile(f)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>find_in_page</mat-icon>
              </button>
              <mat-spinner
                *ngIf="!isComplete[f.id]"
                class="d-inline-block align-middle m-0 ml-2 mr-3"
                color="accent"
                [diameter]="20"
              ></mat-spinner>
              <button
                *ngIf="!f.isDownloading"
                mat-icon-button
                color="accent"
                matTooltip="Download"
                matTooltipPosition="above"
                (click)="downloadFile(f)"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>get_app</mat-icon>
              </button>
              <mat-spinner
                *ngIf="f.isDownloading"
                class="d-inline-block align-middle m-0 ml-2 mr-3"
                color="accent"
                [diameter]="20"
              ></mat-spinner>
              <button
                (click)="editFile(f)"
                mat-icon-button
                color="accent"
                matTooltip="Edit"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="accent"
                matTooltip="Delete"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                (click)="deleteFile(f)"
                *ngIf="allowedToDeleteFile(f)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div *ngIf="divWidth < 1201" class="col-auto text-right">
              <mat-spinner
                *ngIf="!isComplete[f.id] || f.isDownloading"
                class="d-inline-block align-middle m-0 ml-2 mr-3 ltgray"
                color="accent"
                [diameter]="20"
              ></mat-spinner>
              <button
                [hidden]="!isComplete[f.id] || f.isDownloading"
                mat-icon-button
                [matMenuTriggerFor]="file_menu"
                color="accent"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #file_menu="matMenu" xPosition="before">
                <button (click)="previewFile(f)" mat-menu-item>
                  <mat-icon>find_in_page</mat-icon>
                  <span>View</span>
                </button>
                <button (click)="downloadFile(f)" mat-menu-item>
                  <mat-icon>get_app</mat-icon>
                  <span>Download</span>
                </button>
                <button (click)="editFile(f)" mat-menu-item *ngIf="isWorkspaceStaff">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button (click)="deleteFile(f)" *ngIf="allowedToDeleteFile(f)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!displayProjectFiles" class="col-auto list-filters" style="width: 300px">
      <div class="row">
        <div class="col">
          <h5 class="dkblue mb-2 font-weight-semibold">Filter Tags</h5>
          <div class="m-portlet" m-portlet="true">
            <div class="m-portlet__body p-0 p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0 mt-2 mb-2 dkblue font-weight-semibold">Primary Tags</h6>
                </div>
                <div class="col-auto text-right">
                  <button
                    mat-button
                    type="button"
                    class="ltgray pl-2 pr-2"
                    style="line-height: 24px"
                    (click)="clearPrimary()"
                    *ngIf="selectedPrimaryTags.length > 0"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span
                    *ngFor="let tag of primaryTagsWithFiles || []"
                    matRipple
                    class="
                      file-tag
                      border
                      ease
                      d-inline-block
                      align-middle
                      font-weight-semibold
                      pointer
                      pl-2
                      pr-2
                      mr-2
                      mt-2
                      mb-2
                      item item-last
                    "
                    [ngClass]="{
                      'bg-dkblue white': tag.is_selected,
                      dkblue: !tag.is_selected
                    }"
                    (click)="toggleTag(tag)"
                  >
                    {{ tag.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="m-portlet" m-portlet="true" *ngIf="possibleSecondaryTags.length > 0">
            <div class="m-portlet__body p-0 p-3">
              <div class="row mb-3 align-items-center">
                <div class="col">
                  <h6 class="m-0 mt-2 mb-2 dkblue font-weight-semibold">Secondary Tags</h6>
                </div>
                <div class="col-auto text-right">
                  <button
                    mat-button
                    type="button"
                    class="ltgray pl-2 pr-2"
                    style="line-height: 24px"
                    (click)="clearSecondary()"
                    *ngIf="selectedSecondaryTags.length > 0"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <span
                        *ngFor="let tag of possibleSecondaryTags"
                        matRipple
                        class="
                          file-tag
                          border
                          ease
                          d-inline-block
                          align-middle
                          font-weight-semibold
                          pointer
                          pl-2
                          pr-2
                          mr-2
                          mt-2
                          mb-2
                          item item-last
                        "
                        [ngClass]="{
                          'bg-ltblue white': tag.is_selected,
                          ltblue: !tag.is_selected
                        }"
                        (click)="toggleTag(tag)"
                      >
                        {{ tag.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
