<div data-test-id="work-order-topic" id="work-order-topic" class="work-order-topic" [formGroup]="topicFormGroup">
  <div class="row mb-3 align-items-center">
    <div class="col-12">
      <h6 class="dkblue font-weight-semibold mb-2">Group</h6>
      <mat-form-field appearance="outline" class="full-width relative-button-clear-parent position-relative">
        <input
          type="text"
          placeholder="Search Topic Group"
          aria-label="Topic Group"
          matInput
          [matAutocomplete]="topic_group_auto"
          formControlName="topic_group"
        />
        <mat-spinner
          *ngIf="isClearingGroup"
          color="accent"
          style="position: absolute; right: 0; top: 30%"
          [diameter]="18"
        ></mat-spinner>
        <mat-icon
          *ngIf="topic_group?.value?.id && (workOrder?.id || workOrder?.linkedTaskId) && !isClearingGroup"
          matSuffix
          class="gray pointer"
          (click)="clearTopicGroup($event)"
          >cancel</mat-icon
        >
        <mat-autocomplete
          #topic_group_auto="matAutocomplete"
          (optionSelected)="selectedTopicGroupChanged()"
          [displayWith]="topicGroupMapper"
        >
          <mat-option *ngFor="let topicGroup of filteredTopicGroups$ | async" [value]="topicGroup">
            {{ topicGroup?.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-3 align-items-center">
    <div class="col-12">
      <h6 class="m-0 dkblue font-weight-semibold mb-2">Category</h6>
      <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
        <input
          type="text"
          placeholder="Search Category"
          aria-label="Category"
          matInput
          formControlName="topic_category"
          [matAutocomplete]="category_auto"
        />
        <mat-spinner
          *ngIf="isClearingCategory"
          color="accent"
          style="position: absolute; right: 0; top: 30%"
          [diameter]="18"
        ></mat-spinner>
        <mat-icon
          *ngIf="topic_category?.value.id && !isClearingCategory"
          matSuffix
          class="gray pointer"
          (click)="clearTopicCategory($event)"
          >cancel</mat-icon
        >

        <mat-autocomplete
          #category_auto="matAutocomplete"
          [displayWith]="topicCategoryMapper"
          (optionSelected)="selectedTopicCategoryChanged()"
        >
          <mat-option *ngIf="(filteredTopicCategories$ | async)?.length === 0">
            <span class="d-flex flex-column mute-text">
              <p class="dkblue mb-0 mute">No Category Available</p>
            </span>
          </mat-option>
          <mat-option *ngFor="let category of filteredTopicCategories$ | async" [value]="category" class="py-3">
            <span class="d-flex flex-column">
              <p class="dkblue mb-0">{{ category?.name }}</p>
              <h6 class="dkgray opacity9 font-weight-normal mb-0 lh-16 small-text">
                {{ category?.topic_group?.name }}
              </h6>
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-3 align-items-center">
    <div class="col-12">
      <span class="d-flex">
        <h6 class="dkblue font-weight-semibold mb-2 mr-auto">Topic*</h6>
      </span>
      <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
        <input
          type="text"
          placeholder="Search Topic"
          aria-label="Topic"
          matInput
          formControlName="topic"
          [matAutocomplete]="topic_auto"
        />
        <mat-spinner
          *ngIf="isClearingTopic"
          color="accent"
          style="position: absolute; right: 0; top: 30%"
          [diameter]="18"
        ></mat-spinner>
        <mat-icon
          *ngIf="topic?.value?.id && !isClearingTopic"
          matSuffix
          class="gray pointer"
          (click)="clearTopic($event)"
          >cancel</mat-icon
        >

        <mat-autocomplete
          #topic_auto="matAutocomplete"
          [displayWith]="topicMapper"
          (optionSelected)="selectedTopicChanged()"
        >
          <mat-option *ngIf="(filteredTopics$ | async)?.length === 0">
            <span class="d-flex flex-column mute-text">
              <p class="dkblue mb-0 mute">No Topic Available</p>
            </span>
          </mat-option>
          <mat-option *ngFor="let topic of filteredTopics$ | async" [value]="topic" class="py-3">
            <span class="d-flex flex-column">
              <p class="dkblue mb-0">{{ topic?.name }}</p>
              <h6 class="dkgray opacity9 font-weight-normal mb-0 lh-16 small-text">
                {{ topic?.topic_category?.topic_group?.name }} > {{ topic?.topic_category?.name }}
              </h6>
            </span>
          </mat-option>
        </mat-autocomplete>
        <mat-error>
          {{
            (filteredTopics$ | async)?.length === 0 ? 'There are no topics available' : 'A valid topic is required'
          }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-3 align-items-center">
    <div class="col-12">
      <app-workspace-dropdown #workspace></app-workspace-dropdown>
    </div>
  </div>
</div>
