<div id="conversations-container" xmlns="http://www.w3.org/1999/html">
  <div class="conversations-header">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Projects</mat-label>
      <mat-select [value]="messageFilterSettings.filterProjectId">
        <mat-option (click)="updateProjectIdFilter(null)" [value]="null">All Projects</mat-option>
        <mat-option (click)="updateProjectIdFilter(p.id)" [value]="p.id" *ngFor="let p of projects"
          >{{ p.code }} - {{ p.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="conversation-search">
    <input
      type="text"
      class="form-control"
      placeholder="Search Conversations"
      #messageSearch
      [(ngModel)]="messageFilterSettings.messageSearchString"
      (ngModelChange)="forceFilterPipeRefresh()"
    />
    &nbsp;
    <button
      style="flex-grow: 100; top: -3px; margin-left: 5px"
      (click)="onCreateConversationButtonClick()"
      mat-flat-button
      color="success"
      matTooltip="New Conversation"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      New
    </button>
  </div>
  <div class="conversation-filters">
    <a [matMenuTriggerFor]="status_menu" class="dkblue">
      {{ messageStatusFilter }}
      &nbsp;
      <i class="fa fa-caret-down"></i>
    </a>
    <mat-menu #status_menu="matMenu" xPosition="after">
      <button
        mat-menu-item
        (click)="makeFilterSelection('My Messages')"
        [ngClass]="{ 'filter-button-selected': messageFilterSettings.myMessages }"
      >
        &nbsp; My Messages &nbsp;
      </button>
      <button
        mat-menu-item
        (click)="makeFilterSelection('To Me')"
        [ngClass]="{ 'filter-button-selected': messageFilterSettings.toMe }"
      >
        &nbsp; To Me &nbsp;
      </button>
      <button
        mat-menu-item
        (click)="makeFilterSelection('Unread')"
        [ngClass]="{ 'filter-button-selected': messageFilterSettings.unread }"
      >
        &nbsp; Unread &nbsp;
      </button>
      <button
        mat-menu-item
        (click)="makeFilterSelection('Sent')"
        [ngClass]="{ 'filter-button-selected': messageFilterSettings.sent }"
      >
        &nbsp; Sent &nbsp;
      </button>
      <button mat-menu-item (click)="makeFilterSelection('All Messages')" *ngIf="authService.isStaffOnAnyModule">
        &nbsp; All Messages &nbsp;
      </button>
    </mat-menu>
    <i
      (click)="refreshMessages()"
      *ngIf="!refreshingMessages"
      class="refresh la la-refresh dkblue pointer"
      matTooltip="Refresh"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    ></i>
    <i
      *ngIf="refreshingMessages"
      class="refresh la la-refresh fa-spin dkblue pointer"
      matTooltip="Refreshing"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    ></i>
  </div>

  <!-- Conversation list -->
  <div *ngFor="let m of messages | async | messageFilter: messageFilterSettings">
    <div *ngIf="!m.is_event" class="row m-0">
      <app-messaging-inbox-message-box [message]="m" [projects]="projects"></app-messaging-inbox-message-box>
    </div>
  </div>
  <button class="full-width text-center" *ngIf="allMessagesHasMoreToLoad()" (click)="loadMoreMessagesIntoSideView()">
    Load More..
  </button>
</div>
