import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ReviewType, TaskAccessoryType, TaskReviewStatus, Workspace, WorkspaceType } from 'src/app/enums';
import { AuthService, ProjectService, ProjectTaskService, UserService } from 'src/app/services';
import { Arf, Event, Project, ReviewChain, Workspace as Module } from 'src/app/types';
import { ArfCustodyChain } from '../types/arf-custody-chain';
import { ProjectConstruction } from '../workspaces/construction/types';
import { ArfsService } from './arfs.service';

@Injectable({
  providedIn: 'root',
})
export class DisplayReviewersService {
  constructor(
    private arfService: ArfsService,
    private authService: AuthService,
    private projectService: ProjectService,
    private projectTaskService: ProjectTaskService,
    private userService: UserService
  ) {}

  private allEvents: Event[];
  private reviewTypes = ['peb_approval_task_id', 'cb_approval_task_id', 'punchlist_approval_task_id'];
  private userFields = ['first_name', 'last_name', 'title'];
  public oneCallAdminCfmoMax = 0;
  public oneCallNoCfmoMax = 500;
  public noCfmoMax = 2500;
  public noCeoMax = 10000;

  private include = {
    pm: true,
    wm: true,
    dfs: true,
    ocd: false,
    arch: true,
    ac: false,
    cfmo: true,
  };

  // Gets user information for reviewers from the tenants selected review task
  public async displayReviewers(tenants: any[], type: ReviewType) {
    const tenantReviews = {};
    const taskField = this.reviewTypes[type];
    for (const tenant of tenants) {
      const internalData = tenant[taskField] ? await this.retrieveTaskData(tenant[taskField]) : null;
      tenantReviews[tenant.id || tenant.tenant_id] =
        internalData && internalData.reviewChain ? await this.retrieveReviewers(internalData.reviewChain) : null;
    }
    return tenantReviews;
  }

  // Grabs reviewers data using the task id given
  public async displayReviewsByTask(taskId: number) {
    const internalData = taskId ? await this.retrieveTaskData(taskId) : null;
    const reviews =
      internalData && internalData.reviewChain ? await this.retrieveReviewers(internalData.reviewChain) : null;
    return reviews;
  }

  // Gets reviewers in a given tasks accessory data
  public async displayTaskReviewers(taskId: number) {
    const task = await this.projectService.getTaskById(taskId).toPromise();
    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);

    return accessoryData?.reviewChain || null;
  }

  // Gets users needed to start certain review processes
  public getInternalReviewers(include = null, project: ProjectConstruction = null) {
    project = project || this.projectService.currentSelectedProject;
    include = include || this.include;
    const reviewIds = [];
    const selectedReviewers = [];
    // seenIds is a list of processed users, to stop duplicates
    const seenIds = [];
    // add the pm
    if (project) {
      if (project.project_manager_id && !seenIds.includes(project.project_manager_id) && include.pm) {
        reviewIds.push({
          id: project.project_manager_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.project_manager_id,
          firstName: project.project_manager_first_name,
          lastName: project.project_manager_last_name,
          title: 'Project Manager',
          reviewer: true,
        });
        seenIds.push(project.project_manager_id);
      }
      // add the wm
      if (project.workspace_manager_id && !seenIds.includes(project.workspace_manager_id) && include.wm) {
        reviewIds.push({
          id: project.workspace_manager_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.workspace_manager_id,
          firstName: project.workspace_manager_first_name,
          lastName: project.workspace_manager_last_name,
          title: `${project?.module?.name || 'Workspace'} Manager`,
          reviewer: true,
        });
        seenIds.push(project.workspace_manager_id);
      }
      // add the dfs manager if its a workspace that needs one
      if (
        this.authService.needsDFSManager(project.module_id) &&
        project.dfs_id &&
        !seenIds.includes(project.dfs_id) &&
        include.dfs
      ) {
        reviewIds.push({
          id: project.dfs_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.dfs_id,
          firstName: project.dfs.first_name,
          lastName: project.dfs.last_name,
          title: 'Chief Technology Officer',
          reviewer: true,
        });
        seenIds.push(project.dfs_id);
      }
      if (
        project.module?.workspace_type_id === WorkspaceType.OneCall &&
        project.one_call_director_id &&
        !seenIds.includes(project.one_call_director_id) &&
        include.ocd
      ) {
        reviewIds.push({
          id: project.one_call_director_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.dfs_id,
          firstName: project.one_call_director.first_name,
          lastName: project.one_call_director.last_name,
          title: '1CALL Director',
          reviewer: true,
        });
        seenIds.push(project.one_call_director_id);
      }
      // add the architect if its a construction project
      if (project.architect_id && !seenIds.includes(project.architect_id) && include.arch) {
        reviewIds.push({
          id: project.architect_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.architect_id,
          firstName: project.architect_first_name,
          lastName: project.architect_last_name,
          title: 'Project Architect',
          reviewer: true,
        });
        seenIds.push(project.architect_id);
      }
      if (project.account_coordinator_id && !seenIds.includes(project.account_coordinator_id) && include.ac) {
        reviewIds.push({
          id: project.account_coordinator_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.architect_id,
          firstName: project.account_coordinator?.first_name,
          lastName: project.account_coordinator?.last_name,
          title: `${project.module?.name} Coordinator`,
          reviewer: true,
        });
        seenIds.push(project.account_coordinator_id);
      }
      // add the cfmo
      if (project.cfmo_id && !seenIds.includes(project.cfmo_id) && include.cfmo) {
        reviewIds.push({
          id: project.cfmo_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.cfmo_id,
          firstName: project.cfmo_first_name,
          lastName: project.cfmo_last_name,
          title: 'Chief Facilities Management Officer',
          reviewer: true,
        });
        seenIds.push(project.cfmo_id);
      }
      if (this.authService.ceo && !seenIds.includes(this.authService.ceo.id) && include.ceo) {
        reviewIds.push({
          id: this.authService.ceo?.id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: this.authService.ceo.id,
          firstName: this.authService.ceo.first_name,
          lastName: this.authService.ceo.last_name,
          title: 'Chief Executive Officer',
          reviewer: true,
        });
        seenIds.push(this.authService.ceo.id);
      }
      // add the inventory control officer if its is requested
      if (
        project.module_id !== Workspace.Construction &&
        project.ico_id &&
        !seenIds.includes(project.ico_id) &&
        include.ico
      ) {
        reviewIds.push({
          id: project.ico_id,
          status: TaskReviewStatus.Pending,
        });
        selectedReviewers.push({
          id: project.ico_id,
          firstName: project.ico.first_name,
          lastName: project.ico.last_name,
          title: 'Inventory Control Officer',
          reviewer: true,
        });
        seenIds.push(project.ico_id);
      }
    }
    return { reviewIds, selectedReviewers };
  }

  getArfReviewChain(includes, custodyChain: ArfCustodyChain): ReviewChain[] {
    const reviewChain: ReviewChain[] = [];

    if (includes.wm) {
      reviewChain.push({ id: custodyChain.wm_id, status: TaskReviewStatus.Pending });
    }

    if (includes.dfs && !reviewChain.find((r) => r.id === custodyChain.cto_id)) {
      reviewChain.push({ id: custodyChain.cto_id, status: TaskReviewStatus.Pending });
    }

    if (includes.ocd && !reviewChain.find((r) => r.id === custodyChain.ocd_id)) {
      reviewChain.push({ id: custodyChain.ocd_id, status: TaskReviewStatus.Pending });
    }

    if (includes.cfmo && !reviewChain.find((r) => r.id === custodyChain.cfmo_id)) {
      reviewChain.push({ id: custodyChain.cfmo_id, status: TaskReviewStatus.Pending });
    }

    if (includes.ceo && !reviewChain.find((r) => r.id === custodyChain.ceo_id)) {
      reviewChain.push({ id: custodyChain.ceo_id, status: TaskReviewStatus.Pending });
    }

    return reviewChain;
  }

  public setReviewers(total: number, include, type, project?: Project) {
    project = project || this.projectService.currentSelectedProject;
    if (total >= this.noCfmoMax || project?.module?.workspace_type_id === WorkspaceType.OneCall) {
      include.cfmo = true;
      if (total >= this.noCeoMax && type !== TaskAccessoryType.Invoice) {
        include.ceo = true;
      }
    }

    if (this.authService.needsDFSManager(project?.module_id)) {
      include.dfs = true;
    }

    return include;
  }

  public getReviewers(total: number, include, type, project?: Project) {
    const includedReviewers = this.setReviewers(total, include, type, project);
    return this.getInternalReviewers(includedReviewers, project);
  }

  public getArfReviewers(
    total: number | string,
    workspace: Module,
    needsICOReviewer = false,
    dfsRequired: 1 | 0 | null = null
  ) {
    const include = {
      pm: false,
      wm: true,
      dfs: false,
      ocd: false,
      arch: false,
      cfmo: false,
      ceo: false,
      ico: false,
    };

    const noCfmoMax =
      workspace?.id === Workspace.OneCallAdmin
        ? this.oneCallAdminCfmoMax
        : workspace?.workspace_type_id === WorkspaceType.OneCall
        ? this.oneCallNoCfmoMax
        : this.noCfmoMax;
    if (Number(total) >= noCfmoMax) {
      include.cfmo = true;
      if (Number(total) >= this.noCeoMax) {
        include.ceo = true;
      }
    }

    // If dfsRequired is not passed then it will fall back to using authService.needsDFSManager(workspace?.id) to determine if dfs review is required.
    if (dfsRequired === null) {
      if (this.authService.needsDFSManager(workspace?.id)) {
        include.dfs = true;
      }
    } else if (dfsRequired === 1) {
      include.dfs = true;
    }

    if (workspace?.workspace_type_id === WorkspaceType.OneCall) {
      include.ocd = true;
    }

    if (needsICOReviewer) {
      include.ico = true;
    }

    return include;
  }

  public getBidContractReviewers(contactId: number, contractSum) {
    const include = {
      ac: true,
      cfmo: true,
      ceo: this.noCeoMax <= contractSum,
    };

    let reviewIds: ReviewChain[] = [
      {
        id: contactId,
        status: TaskReviewStatus.Pending,
      },
    ];

    const internalReviewers = this.getInternalReviewers(include);
    reviewIds = [...reviewIds, ...internalReviewers.reviewIds];
    const nonSignatureReviewers = [this.projectService.currentSelectedProject.account_coordinator_id];
    if (include.ceo) {
      nonSignatureReviewers.push(this.projectService.currentSelectedProject.cfmo_id);
    }
    reviewIds.forEach((reviewer) => {
      const needsSignature = !nonSignatureReviewers.includes(reviewer.id);
      reviewer.needs_signature = needsSignature;
    });

    return reviewIds;
  }

  // Checks to see if the review chain of a task has the most recent reviewers. If it does not it updates it and returns the accessory data.
  public async getCurrentReviewers(taskId, include = null, customReviewChain = false) {
    const task = await this.projectService.getTaskById(taskId).toPromise();
    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
    let arf: Arf;
    if (!task.project_id) {
      arf = await this.arfService
        .getArfById(accessoryData.parentId, [this.arfService.arfCustodyChainField])
        .toPromise();
    }
    const currentReviewers = task.project_id
      ? this.getInternalReviewers(include).reviewIds
      : this.getArfReviewChain(include, arf.custody_chain);
    let isTheSame = true;
    if (accessoryData?.reviewChain && !customReviewChain) {
      if (accessoryData.reviewChain.length === currentReviewers.length) {
        accessoryData.reviewChain.forEach((review) => {
          if (!currentReviewers.find((current) => current.id === review.id)) {
            isTheSame = false;
          }
        });
      } else {
        isTheSame = false;
      }

      if (!isTheSame) {
        accessoryData.reviewChain = currentReviewers;
      }
    }

    return { isTheSame, accessoryData };
  }

  // Returns the accessory data of the task id given to it
  private async retrieveTaskData(taskId: number) {
    const task = await this.projectService.getTaskById(taskId, ['accessory_data']).toPromise();
    this.allEvents = await this.projectTaskService.getEventsForTaskId(taskId).toPromise();
    return JSON.parse(task.accessory_data);
  }

  //  Using review chain creates an array of users
  public async retrieveReviewers(reviewChain, grabEventInfo = true) {
    const userIds = [];
    const reviewInfo = {};
    const userInfo = [];

    reviewChain.forEach((reviewer) => {
      userIds.push(reviewer.id);
      if (!reviewer.hasOwnProperty('comment')) {
        const userEvent = this.getUserEvents(reviewer.id, grabEventInfo);
        reviewer.comment = +userEvent?.commentStatus === reviewer.status + 1 && userEvent?.comment;
      }

      reviewInfo[reviewer.id] = reviewer;
    });
    await this.userService
      .getUserByIds(userIds)
      .toPromise()
      .then((users) => {
        users.map((user) => {
          userInfo.push({
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            title: user.title,
            status: reviewInfo[user.id]?.status,
            dateCreated: moment(reviewInfo[user.id]?.date).format('LL'),
            created_datetime: reviewInfo[user.id]?.date,
            comment: reviewInfo[user.id]?.comment,
          });
        });
      });
    return userInfo;
  }

  private getUserEvents(userId: number, grabEventInfo = true) {
    let userEvent: {
      dateTime: string;
      comment: string;
      created_datetime: any;
      commentStatus: string;
    };
    if (grabEventInfo) {
      this.allEvents.forEach((event) => {
        if (userId === event.created_by_id && (event.message === '2' || event.message === '3')) {
          if (!userEvent || moment(userEvent.dateTime).diff(moment(event.created_datetime)) < 0) {
            userEvent = {
              dateTime: moment(event.created_datetime).format('LL'),
              comment: event.comment,
              created_datetime: event.created_datetime,
              commentStatus: event.message,
            };
          }
        }
      });
    }
    return userEvent || null;
  }

  // returns the corresponding icon for whatever status is passed to it
  public getStatusIcon(reviewStatus: TaskReviewStatus, isOpenReview = false): string {
    switch (reviewStatus) {
      case TaskReviewStatus.Approved:
        return 'check_circle';
      case TaskReviewStatus.NoteMarkings:
        return 'check_circle';
      case TaskReviewStatus.Rejected:
        return isOpenReview ? 'sync_problem' : 'cancel';
      default:
        return null;
    }
  }

  // returns the corresponding color for whatever status is passed to it
  public getStatusColor(review: ReviewChain, reviewerId?: number, currentReviewChainItemId?: number): string {
    switch (review?.status) {
      case TaskReviewStatus.Approved:
        if (review.needs_signature) {
          return 'gold';
        } else {
          return 'green';
        }
      case TaskReviewStatus.Rejected:
        return 'red';
      case TaskReviewStatus.NoteMarkings:
        return 'orange';
      case TaskReviewStatus.Pending:
        const pendingColor = currentReviewChainItemId === reviewerId ? 'ltblue' : 'gray';
        return pendingColor;
      default:
        return null;
    }
  }

  // returns the corresponding text for whatever status is passed to it
  public getStatusText(reviewer, currentReviewChainItemId: number, reviewIsRejected: boolean, isOpenReview = false) {
    switch (reviewer.status) {
      case TaskReviewStatus.Approved:
        return reviewer.needs_signature ? 'Signed' : 'Approved';
      case TaskReviewStatus.Rejected:
        return isOpenReview ? 'Flagged' : reviewer.needs_signature ? 'Requested Revision' : 'Rejected';
      case TaskReviewStatus.NoteMarkings:
        return 'Note Marking';
      case TaskReviewStatus.Pending:
        const pendingText =
          currentReviewChainItemId === reviewer.id && !reviewIsRejected
            ? this.authService.currentUser.id === reviewer.id
              ? ''
              : 'Pending'
            : 'Waiting';
        return pendingText;
      default:
        return null;
    }
  }

  public getCurrentReviewer(reviewers) {
    return reviewers?.find(
      (reviewer) => reviewer.status === TaskReviewStatus.Rejected || reviewer.status === TaskReviewStatus.Pending
    );
  }

  public async addUserInfoToReviewChain(reviewChain: ReviewChain[]) {
    if (reviewChain?.length) {
      const users =
        (await this.userService
          .getUserByIds(
            reviewChain.map((r) => r.id),
            this.userFields
          )
          .toPromise()) || [];

      reviewChain.forEach((reviewer, i) => {
        const userIndex = users.findIndex((user) => user.id === reviewer.id);
        reviewChain[i] = { ...reviewChain[i], ...users[userIndex] };
      });

      return reviewChain;
    } else {
      return [];
    }
  }

  public removeUserInfoFromReviewChain(reviewChain: ReviewChain[]) {
    if (reviewChain?.length) {
      return reviewChain.map((reviewer) => {
        const dataToSave = {};
        for (const key in reviewer) {
          if (reviewer.hasOwnProperty(key) && !this.userFields.includes(key)) {
            dataToSave[key] = reviewer[key];
          }
        }
        return dataToSave;
      });
    } else {
      return [];
    }
  }

  getIfUserIsReviewAdmin(reviewChain = [], project = null, custodyChain: ArfCustodyChain = null) {
    project = project || this.projectService.currentSelectedProject;

    let currentUserIsReviewAdmin = true;
    if (!this.authService.isAppAdmin) {
      const currentUserLevel = this.getUserAdminLevel(this.authService.currentUser?.id, project, custodyChain);
      for (const reviewer of reviewChain || []) {
        if (
          reviewer.id !== this.authService.currentUser?.id &&
          currentUserIsReviewAdmin &&
          reviewer.status === TaskReviewStatus.Pending
        ) {
          currentUserIsReviewAdmin =
            this.getUserAdminLevel(reviewer.id, project, custodyChain) < currentUserLevel && !reviewer.needs_signature;
        } else {
          break;
        }
      }
    }

    return currentUserIsReviewAdmin;
  }

  getUserAdminLevel(userId: number, project: ProjectConstruction, custodyChain: ArfCustodyChain = null) {
    const levelArray = project
      ? this.authService.rolesAdminLevels(project)
      : this.authService.noneProjectAdminLevels(custodyChain);
    return levelArray.indexOf(userId);
  }
}
