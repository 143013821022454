import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { cloneDeep, remove } from 'lodash';
import * as moment from 'moment';

import { ProjectService } from 'src/app/services';

import { FileAttachmentDialogComponent, DatepickerHeaderComponent, EditorComponent } from 'src/app/components';

import { ResourceType } from 'src/app/enums';

@Component({
  selector: 'app-addendum-dialog',
  templateUrl: './addendum-dialog.component.html',
  styleUrls: ['./addendum-dialog.component.scss'],
})
export class AddendumDialogComponent implements OnInit {
  @ViewChild('descriptionEditor', { static: true })
  private _description_editor_component: EditorComponent;
  @ViewChild('noteEditor', { static: true }) private _note_editor_component: EditorComponent;

  constructor(
    public dialogRef: MatDialogRef<AddendumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private projectService: ProjectService
  ) {}

  files = [];
  public customHeader = DatepickerHeaderComponent;

  addendumFormGroup: FormGroup = this.fb.group({
    title: [this.data.addendum ? this.data.addendum.title : '', [Validators.required]],
    isqft_date: [this.data.addendum ? this.data.addendum.isqft_date : '', []],
    // bid_due_date: [this.data.bidEndDatetime || '', [Validators.required]],
    bid_due_date: [
      { value: this.data.bidEndDatetime, disabled: !this.data.bidEndDatetime },
      this.data.bidEndDatetime ? [Validators.required] : [],
    ],
    bid_due_time: [
      {
        value: this.data.bidEndDatetime ? moment(this.data.bidEndDatetime).format('HH:mm') : null,
        disabled: true,
      },
      [Validators.required],
    ],
  });

  get title() {
    return this.addendumFormGroup.get('title');
  }
  get description() {
    return this.addendumFormGroup.get('description');
  }
  get notes() {
    return this.addendumFormGroup.get('notes');
  }
  get isqft_date() {
    return this.addendumFormGroup.get('isqft_date');
  }
  get bid_due_date() {
    return this.addendumFormGroup.get('bid_due_date');
  }
  get bid_due_time() {
    return this.addendumFormGroup.get('bid_due_time');
  }

  async ngOnInit() {
    this.data.addendum = cloneDeep(this.data?.addendum ?? {});
    this.files = this.data?.addendum?.files || [];
    this._activateEditors();
  }

  private _activateEditors() {
    this.addendumFormGroup.addControl('description', this._description_editor_component.content);
    this.description.setValidators([Validators.required]);
    if (this.data?.addendum?.description) {
      this.description.setValue(this.data.addendum.description);
    }
    this.description.updateValueAndValidity();

    this.addendumFormGroup.addControl('notes', this._note_editor_component.content);
    this.notes.clearValidators();
    if (this.data?.addendum?.notes) {
      this.notes.setValue(this.data.addendum.notes);
    }
    this.notes.updateValueAndValidity();
  }

  removeFile(file) {
    remove(this.files, (f) => f.name === file.name);
  }

  submit(): void {
    if (this.addendumFormGroup.valid) {
      const addendumToReturn: any = {
        title: this.title.value,
        description: this.description.value,
        notes: this.notes.value,
        isqft_date: this.isqft_date.value ? moment(this.isqft_date.value).format('YYYY-MM-DD') : null,
        files: this.files,
      };
      if (this.bid_due_date.value && this.bid_due_time.value) {
        addendumToReturn.bid_due_date = moment(this.bid_due_date.value).format('YYYY-MM-DD');
        addendumToReturn.bid_due_time = moment(
          `${moment(this.bid_due_date.value).format('YYYY-MM-DD')} ${this.bid_due_time.value}`
        ).format('HH:mm:ss');
      }
      if (this.data.addendum && this.data.addendum.id) {
        addendumToReturn.id = this.data.addendum.id;
      }
      this.dialogRef.close(addendumToReturn);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          preSelectedTags: [{ id: 1 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          for (const f of resultData) {
            if (this.files.find((fi) => fi.name === f.name)) {
              this.snackbar.open(`File with name '${f.name}' already exists!`);
            } else {
              this.files.push({ id: f.file_id, name: f.name });
            }
          }
        }
      });
  }
}
