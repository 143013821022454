<div class="col-12 p-0" *ngIf="conversation">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>To: </mat-label>
        <mat-chip-list #toChipList aria-label="To Followers">
          <mat-chip *ngFor="let f of toFollowers" style="margin-top: 2px; margin-bottom: 2px">
            {{ f.first_name }} {{ f.last_name }}
            <mat-icon matChipRemove (click)="removeToFollower(f)">cancel</mat-icon>
          </mat-chip>
          <input
            type="text"
            placeholder="Add To User"
            #toFollowerInput
            [matAutocomplete]="toAuto"
            [matChipInputFor]="toChipList"
            [matChipInputSeparatorKeyCodes]="[',']"
            [matChipInputAddOnBlur]="true"
            [(ngModel)]="toFollowerSearchValue"
            (ngModelChange)="searchBoxValueChange($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #toAuto="matAutocomplete"
          [displayWith]="userSearchService.defaultUserMapper"
          (optionSelected)="addToFollower($event.option.value)"
        >
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            {{ user.first_name }} {{ user.last_name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Followers (CC)</mat-label>
        <mat-chip-list #chipList aria-label="Followers">
          <div *ngFor="let f of conversation.followers">
            <mat-chip
              [selectable]="false"
              (click)="toggleToFollower(f)"
              [hidden]="userIsToFollower(f)"
              style="margin-top: 2px; margin-bottom: 2px"
            >
              {{ f.first_name }} {{ f.last_name }}
            </mat-chip>
          </div>
          <div *ngFor="let f of newMessageFollowers">
            <mat-chip [selectable]="false">
              {{ f.first_name }} {{ f.last_name }}
              <mat-icon matChipRemove (click)="removeNewMessageFollower(f)">cancel</mat-icon>
            </mat-chip>
          </div>
          <input
            type="text"
            placeholder="Add Recipient"
            #followerInput
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="[',']"
            [matChipInputAddOnBlur]="true"
            [(ngModel)]="followerTextSearchValue"
            (ngModelChange)="searchBoxValueChange($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="userSearchService.defaultUserMapper"
          (optionSelected)="addNewMessageFollower($event.option.value)"
        >
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            {{ user.first_name }} {{ user.last_name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Message</mat-label>
        <textarea
          trim="false"
          matInput
          type="text"
          #messageTextArea
          rows="8"
          [(ngModel)]="newMessageContent"
          (keydown.tab)="tabPressed($event)"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center pb-4">
    <div class="col">
      <input
        [hidden]="true"
        #newConversationUploader
        multiple
        type="file"
        (change)="addFile($event.target.files); newConversationUploader.value = null"
      />
      <button mat-icon-button color="accent">
        <mat-icon
          (click)="newConversationUploader.click()"
          matTooltip="Upload File"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          attach_file
        </mat-icon>
      </button>
      <mat-chip-list class="d-inline-block align-middle">
        <mat-chip
          *ngFor="let file of files"
          disableRipple
          class="dkblue mr-2 pointer"
          [matTooltip]="file.name"
          [matTooltipShowDelay]="300"
          matTooltipPosition="above"
          (click)="download(file)"
        >
          <span class="pointer file-name">
            {{ file.name }}
          </span>
          <mat-icon matChipRemove class="dkgray">get_app</mat-icon>
          <mat-icon matChipRemove (click)="removeFile(file); stopPropagation($event)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="col-auto text-right">
      <button *ngIf="replyingToMessage" mat-button color="accent" (click)="cancelReply()">Cancel</button>
      <button mat-flat-button color="success" (click)="sendMessage()">Send Message</button>
    </div>
  </div>
</div>
