import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleErrorService } from 'src/app/services';
import {
  CostCode,
  FiscalYear,
  ServiceResponse,
  SubCostCode,
  SubCostCodeBudget,
  SubCostCodeBudgetActivity,
  SubCostCodeBudgetChange,
  SubCostCodeType,
  WorkspaceModule,
} from 'src/app/types';
import { environment } from 'src/environments/environment';

function reduceActivityToChanges(
  costCodes: CostCode[],
  activity: SubCostCodeBudgetActivity[]
): SubCostCodeBudgetChange[] {
  const sortChangesByDateAndSubCostCode = (a: SubCostCodeBudgetChange, b: SubCostCodeBudgetChange) =>
    `${a.date?.getTime()} ${a.subCostCode?.code}` < `${b.date?.getTime()} ${b.subCostCode?.code}` ? 1 : -1;

  return activity
    .reduce<SubCostCodeBudgetChange[]>(
      (allChanges, { valuesNextParsed, valuesPreviousParsed, user, created_datetime: dateAsString }) => {
        const changes: SubCostCodeBudgetChange[] = [];

        for (let i = 0; i < valuesNextParsed.length; i++) {
          const {
            fiscal_year: fiscalYear,
            sub_cost_code_id: subCostCodeId,
            uhat_budget: uhatBudgetAfter,
            one_call_budget: oneCallBudgetAfter,
            is_enabled: isEnabledAfterAsNumber,
          } = valuesNextParsed[i];

          const {
            uhat_budget: uhatBudgetBefore,
            one_call_budget: oneCallBudgetBefore,
            is_enabled: isEnabledBeforeAsNumber,
          } = valuesPreviousParsed[i] || {};

          const costCode = costCodes.find(({ sub_cost_codes }) =>
            sub_cost_codes.find(({ id }) => id === subCostCodeId)
          );
          if (costCode) {
            const subCostCode = costCode.sub_cost_codes.find(({ id }) => id === subCostCodeId);
            changes.push({
              fiscalYear,
              costCode,
              subCostCode,
              uhatBudgetBefore,
              uhatBudgetAfter,
              oneCallBudgetBefore,
              oneCallBudgetAfter,
              isEnabledBefore: Boolean(isEnabledBeforeAsNumber),
              isEnabledAfter: Boolean(isEnabledAfterAsNumber),
              user,
              date: new Date(dateAsString),
            });
          }
        }

        return [...allChanges, ...changes];
      },
      []
    )
    .sort(sortChangesByDateAndSubCostCode);
}

@Injectable({ providedIn: 'root' })
export class PurchasingBudgetService {
  host: string = environment.serviceHost;
  endPointUrl = `${this.host}/api/v1`;
  subCostCodesUrl = `${this.host}/api/v1/sub-cost-codes`;
  subCostCodeBudgetsUrl = `${this.host}/api/v1/sub-cost-code-budgets`;

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {}

  saveSubCostCodes(subCostCodes: SubCostCode[]): Observable<SubCostCode[]> {
    return this.http.post<SubCostCode[]>(`${this.endPointUrl}/sub-cost-codes/bulk`, subCostCodes);
  }

  deleteSubCostCode(Id: number): Observable<void> {
    return this.http.delete(`${this.subCostCodesUrl}/${Id}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteSubCostCodeBudget(Id: number): Observable<void> {
    return this.http.delete(`${this.subCostCodeBudgetsUrl}/${Id}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  saveBudgetActivity(budgetActivity: SubCostCodeBudgetActivity): Observable<string> {
    return this.http.post(`${this.endPointUrl}/sub-cost-code-budget-activity`, budgetActivity, {
      responseType: 'text',
    });
  }

  saveBudgets(subCostCodeBudgets: SubCostCodeBudget[]): Observable<string> {
    return this.http.post(`${this.endPointUrl}/sub-cost-code-budgets/bulk`, subCostCodeBudgets, {
      responseType: 'text',
    });
  }

  getCostCodes(fields: string[]): Observable<CostCode[]> {
    return this.http
      .get<ServiceResponse<{ cost_codes: CostCode[] }>>(`${this.endPointUrl}/cost-codes`, {
        params: { fields: fields.join(',') },
      })
      .pipe(
        map((response) =>
          response.data.cost_codes.map((costCode) => {
            return {
              ...costCode,
              sub_cost_codes: (costCode.sub_cost_codes || []).map((subCostCode) => {
                return { ...subCostCode, module_ids: JSON.parse(subCostCode.module_ids as string) };
              }),
            };
          })
        )
      );
  }

  getBudgetActivity(fiscalYear: number, fields: string[] = []): Observable<SubCostCodeBudgetActivity[]> {
    return this.http
      .get<ServiceResponse<{ sub_cost_code_budget_activity: SubCostCodeBudgetActivity[] }>>(
        `${this.endPointUrl}/sub-cost-code-budget-activity/`,
        {
          params: { fields: fields.join(','), filter: `fiscal_year=${fiscalYear}` },
        }
      )
      .pipe(
        map((response) =>
          response.data.sub_cost_code_budget_activity.map((budget) => {
            return {
              ...budget,
              valuesPreviousParsed: JSON.parse(budget.values_previous),
              valuesNextParsed: JSON.parse(budget.values_next),
            };
          })
        )
      );
  }

  getBudgetChanges(costCodes: CostCode[], fiscalYear: number): Observable<SubCostCodeBudgetChange[]> {
    return this.getBudgetActivity(fiscalYear).pipe(
      map((activity) => {
        return reduceActivityToChanges(costCodes, activity).sort((a, b) =>
          `${a.date.toISOString()} ${a.subCostCode.code}` > `${b.date.toISOString()} ${b.subCostCode.code}` ? 1 : -1
        );
      })
    );
  }

  getSubCostCodeTypes(): Observable<SubCostCodeType[]> {
    return this.http
      .get<ServiceResponse<{ sub_cost_code_types: SubCostCodeType[] }>>(`${this.endPointUrl}/sub-cost-code-types`, {
        params: { fields: 'id,name,description' },
      })
      .pipe(map((response) => response.data.sub_cost_code_types));
  }

  getModules(): Observable<WorkspaceModule[]> {
    return this.http
      .get<ServiceResponse<{ modules: WorkspaceModule[] }>>(`${this.endPointUrl}/modules`, {
        params: { fields: 'id,name,icon', sort: 'name' },
      })
      .pipe(map((response) => response.data.modules));
  }

  getFiscalYears() {
    return this.http.get<ServiceResponse<{ fiscal_years: FiscalYear[] }>>(`${this.endPointUrl}/fiscal-years`).pipe(
      map((result: ServiceResponse) => {
        const fiscalYears: FiscalYear[] = result.data.fiscal_years;
        return fiscalYears;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createFiscalYear(fiscalYear) {
    return this.http.post(`${this.endPointUrl}/fiscal-years`, { year: fiscalYear }).pipe(
      map((result: ServiceResponse) => {
        const fiscalYear: FiscalYear = result.data.fiscal_year;
        return fiscalYear;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateFiscalYear(fiscalYear) {
    const id = fiscalYear.id;
    delete fiscalYear.id;
    return this.http.put(`${this.endPointUrl}/fiscal-years/${id}?fields=id,year,budget_finalized`, fiscalYear).pipe(
      map((result: ServiceResponse) => {
        const fiscalYear: FiscalYear = result.data['fiscal years'];
        return fiscalYear;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
