import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CompanyService, LocationService, ProgressIndicatorService, UserService } from 'src/app/services';
import { APIFilter, Company, Department, User } from 'src/app/types';
import { UserType } from '../../enums';
import { UserEmailIsAvailableValidator } from '../../validators';
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';

@Component({
  selector: 'app-new-guest-account-modal',
  templateUrl: './new-guest-account-modal.component.html',
  styleUrls: ['./new-guest-account-modal.component.scss'],
})
export class NewGuestAccountModalComponent implements OnInit, OnDestroy {
  userTypesOptions: { id: number; name: string }[] = [];
  filteredDepartments: Department[] = [];
  filteredCompanies: Department[] = [];
  loaders = {
    companies: false,
    creatingUser: false,
  };
  userInfoFormGroup = new FormGroup({
    userTypeId: new FormControl(this.data?.defaultUserTypeId ?? null, [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    department: new FormControl(this.data?.department ?? null),
    company: new FormControl(this.data?.company ?? null),
    email: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?"
        ),
      ],
      asyncValidators: [this.userEmailIsAvailableValidator.validate.bind(this.userEmailIsAvailableValidator)],
    }),
  });
  UserType = UserType;
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      department: Department;
      company: Company;
      allowedUserTypeIds: UserType[];
      defaultUserTypeId: UserType;
      searchInput: string;
    },
    private companyService: CompanyService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewGuestAccountModalComponent>,
    private fb: FormBuilder,
    private locationService: LocationService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar,
    private userEmailIsAvailableValidator: UserEmailIsAvailableValidator,
    private userService: UserService
  ) {}

  get userTypeId(): AbstractControl {
    return this.userInfoFormGroup.get('userTypeId');
  }

  get firstName(): AbstractControl {
    return this.userInfoFormGroup.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.userInfoFormGroup.get('lastName');
  }

  get email(): AbstractControl {
    return this.userInfoFormGroup.get('email');
  }

  get department(): AbstractControl {
    return this.userInfoFormGroup.get('department');
  }

  get company(): AbstractControl {
    return this.userInfoFormGroup.get('company');
  }

  ngOnInit(): void {
    if (this.data?.searchInput) {
      let names: string[];

      if (/\S+@/.test(this.data.searchInput)) {
        this.email.setValue(this.data.searchInput);
        return;
      } else {
        names = this.data.searchInput.split(' ');
      }

      this.firstName.setValue(names[0]);
      if (names.length > 1) {
        this.lastName.setValue(names[1]);
      }
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Account Types...');
    void this.userService
      .getUserTypes()
      .pipe(
        map((userTypeOptions) => {
          userTypeOptions.map((userType) => {
            if (userType.name === 'Staff') {
              userType.name = 'UHAT/1Call';
            }
          });

          if (this.data?.allowedUserTypeIds) {
            userTypeOptions = userTypeOptions.filter((userTypeOption) =>
              this.data.allowedUserTypeIds.includes(userTypeOption.id)
            );
          }

          this.progressIndicatorService.close();

          return userTypeOptions;
        })
      )
      .toPromise()
      .then((userTypeOptions) => (this.userTypesOptions = userTypeOptions ?? []));

    this.subscriptions.add(
      this.userTypeId.valueChanges.subscribe((userTypeId) => {
        if ([UserType.Staff, UserType.Tenant].includes(userTypeId)) {
          this.department.setValidators([Validators.required, this.valueNotStringValidator()]);
          this.department.updateValueAndValidity();
        } else {
          this.department.clearValidators();
          this.department.updateValueAndValidity();
        }

        if (UserType.Vendor === userTypeId) {
          this.company.setValidators([Validators.required, this.valueNotStringValidator()]);
          this.company.updateValueAndValidity();
        } else {
          this.company.clearValidators();
          this.company.updateValueAndValidity();
        }
      })
    );

    this.subscriptions.add(
      this.department.valueChanges.pipe(debounceTime(300)).subscribe((departmentValue) => {
        if (typeof departmentValue === 'string' && departmentValue.length) {
          const departmentFilter: APIFilter[] = [
            { type: 'field', field: 'name', value: departmentValue, match: 'any' },
          ];
          void this.locationService
            .getDepartments(['id', 'name'], departmentFilter)
            .toPromise()
            .then((departments) => {
              this.filteredDepartments = departments;
            });
        }
      })
    );

    this.subscriptions.add(
      this.company.valueChanges
        .pipe(
          tap(() => (this.loaders.companies = true)),
          debounceTime(300)
        )
        .subscribe((companyValue) => {
          if (typeof companyValue === 'string' && companyValue.length) {
            const companyFilter: APIFilter[] = [{ type: 'field', field: 'name', value: companyValue, match: 'any' }];
            void this.companyService
              .getCompanies(['id', 'name'], companyFilter)
              .toPromise()
              .then((companies) => {
                this.filteredCompanies = companies;
                this.loaders.companies = false;
              });
          } else {
            this.loaders.companies = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  departmentValueMapper(department: Department): string | null {
    return department ? department.name : null;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  addCompany(companyName: string): void {
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      width: '580px',
      data: { company: { name: companyName, type_id: 3 } },
    });

    dialogRef.afterClosed().subscribe((createdCompany) => {
      if (createdCompany && createdCompany.id) {
        this.company.setValue(createdCompany);
      }
    });
  }

  createGuestUser(): void {
    this.loaders.creatingUser = true;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Creating Guest User...');

    const userInfoFromForm: {
      userTypeId: number;
      firstName: string;
      lastName: string;
      email: string;
      department: Department;
      company: Company;
    } = this.userInfoFormGroup.value;

    const userToCreate: User = {
      user_type_id: userInfoFromForm.userTypeId,
      first_name: userInfoFromForm.firstName,
      last_name: userInfoFromForm.lastName,
      email: userInfoFromForm.email,
    };

    if (userInfoFromForm.department && [UserType.Staff, UserType.Tenant].includes(userInfoFromForm.userTypeId)) {
      userToCreate.department_id = userInfoFromForm.department.id;
    }

    if (userInfoFromForm.company && userInfoFromForm.userTypeId === UserType.Vendor) {
      userToCreate.company_id = userInfoFromForm.company.id;
    }

    void this.userService
      .createGuestUser(userToCreate)
      .toPromise()
      .then((createdUser: User) => {
        if (userInfoFromForm.department?.name) {
          createdUser.department_name = userInfoFromForm.department.name;
        }

        if (userInfoFromForm.company?.name) {
          createdUser.company_name = userInfoFromForm.company.name;
        }

        this.snackbar.open('Guest user created!');
        this.loaders.creatingUser = false;
        this.progressIndicatorService.close();
        this.dialogRef.close(createdUser);
      });
  }

  private valueNotStringValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueIsString = typeof control.value === 'string';
      return valueIsString ? { valueIsString: { value: true } } : null;
    };
  }
}
