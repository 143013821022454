import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressIndicatorService, TopicService, WorkOrderService } from '../../services';
import { APIFilter, Topic, TopicAccess, TopicType, WorkOrderPriority } from '../../types';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'app-topic-dialog',
  templateUrl: './topic-dialog.component.html',
  styleUrls: ['./topic-dialog.component.scss'],
})
export class TopicDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  showChecklistView = true;
  editingItem;

  constructor(
    public dialogRef: MatDialogRef<TopicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private progressIndicatorService: ProgressIndicatorService,
    private topicService: TopicService,
    private workOrderService: WorkOrderService
  ) {}

  public title: string;
  public topicAccess: TopicAccess[];
  public topicTypes: TopicType[];
  public workOrderPriorities: WorkOrderPriority[];
  public topic: Topic = JSON.parse(JSON.stringify(this.data?.topic));
  newItem = null;

  topicFormGroup: FormGroup = this.fb.group({
    name: [this.topic?.name, [Validators.required]],
    visible_to: [this.topic?.visible_to || [0]],
    topic_type_id: [this.topic?.topic_type_id, [Validators.required]],
    priority_id: [this.topic?.priority_id],
  });

  async ngOnInit() {
    this.title = this.topic?.id ? 'Edit' : 'Add';
    this.topicTypes = await this.topicService.getTopicTypes(['id', 'name']).toPromise();
    this.topicAccess = await this.topicService.getTopicAccess(['id', 'name']).toPromise();
    this.workOrderPriorities = await this.workOrderService
      .getWorkOrderPriorities(['id', 'name', 'abbreviation'])
      .toPromise();
    await this.activateToolTipField();
  }

  get name() {
    return this.topicFormGroup.get('name');
  }
  get visibleTo() {
    return this.topicFormGroup.get('visible_to');
  }
  get topicTypeId() {
    return this.topicFormGroup.get('topic_type_id');
  }
  get priorityId() {
    return this.topicFormGroup.get('priority_id');
  }
  get tooltip() {
    return this.topicFormGroup.get('tooltip');
  }

  private async activateToolTipField() {
    this.topicFormGroup.addControl('tooltip', this._editor_component.content);
    this.tooltip.setValue(this.topic?.tooltip ?? '');
  }

  setVisibleToEveryone() {
    this.visibleTo.setValue(null);
  }

  updateVisibleTo(accessId) {
    if (accessId === 0) {
      this.visibleTo.setValue([0]);
    } else if (this.visibleTo.value.includes(0)) {
      this.visibleTo.setValue(this.visibleTo.value.filter((id) => id !== 0));
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async submit() {
    if (this.topicFormGroup.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Topic..');
      const formData = this.topicFormGroup.value;
      let topicData: Topic = {};
      for (const key in formData) {
        if (formData.hasOwnProperty(key) && formData[key] !== this.topic[key]) {
          if (key === 'visible_to') {
            const visible_to_value = (formData[key] && formData[key][0] !== 0 && JSON.stringify(formData[key])) || null;
            if (visible_to_value !== this.topic.visible_to) {
              topicData[key] = visible_to_value;
              topicData.selectable_by = topicData[key];
            }
          } else {
            topicData[key] = formData[key];
          }
        }
      }
      let savedTopic;
      if (this.topic?.id && Object.keys(topicData).length > 0) {
        savedTopic = await this.topicService
          .updateTopic(this.topic.id, topicData, this.topicService.topicDialogFields)
          .toPromise();
      } else if (!this.topic?.id) {
        topicData = { ...this.topic, ...topicData };
        savedTopic = await this.topicService.createTopic(topicData, this.topicService.topicDialogFields).toPromise();
      }

      this.newItem = null;
      if (this.editingItem) {
        this.clearCheckListItem(this.editingItem);
      }

      if (this.topicTypeId.value === 2 && this.topic?.checklist_template) {
        const promises = [];
        for (const item of this.topic?.checklist_template) {
          if (!item.id) {
            const createPromise = await this.topicService
              .createTopicChecklistTemplateItem({ ...item, work_order_topic_id: savedTopic?.id || this.topic?.id })
              .toPromise();
            promises.push(createPromise);
          } else {
            const old = this.data.topic.checklist_template.find((i) => i.id === item.id);
            if (old?.title !== item.title) {
              const itemToUpdate = { title: item.title };
              const updatePromise = this.topicService.updateTopicChecklistTemplate(item.id, itemToUpdate).toPromise();
              promises.push(updatePromise);
            }
          }
        }

        const templateItemsToDelete = this.data.topic?.checklist_template?.filter(
          (t) => !this.topic?.checklist_template?.find((c) => c.id === t.id)
        );
        templateItemsToDelete?.forEach((templateItemToDelete) => {
          const deletePromise = this.topicService.deleteTopicChecklistTemplate(templateItemToDelete.id).toPromise();
          promises.push(deletePromise);
        });

        if (promises.length > 0) {
          // sometimes we don't update or create, grab a fresh copy then.
          if (!savedTopic) {
            const upToDateTopic = await this.topicService
              .getTopics(this.topicService.topicDialogFields, [{ type: 'field', field: 'id', value: this.topic?.id }])
              .toPromise();
            if (!upToDateTopic || upToDateTopic?.length !== 1) {
              return;
            }
            savedTopic = upToDateTopic[0];
          }
          const apiFilter: APIFilter[] = [{ type: 'field', field: 'work_order_topic_id', value: savedTopic.id }];
          await Promise.all(promises).then(async () => {
            const template = await this.topicService
              .getTopicWorkOrderCheckListTemplate(['id,title,work_order_topic_id'], apiFilter)
              .toPromise();
            savedTopic.checklist_template = template;
          });
        }
      }

      this.progressIndicatorService.close();
      this.dialogRef.close(savedTopic);
    }
  }

  addWorkOrderChecklistTemplateItem() {
    const item = { title: '' };
    this.topic.checklist_template.push(item);
    this.editingItem = item;
  }

  removeItem(item) {
    const index = this.topic.checklist_template.indexOf(item);
    this.topic.checklist_template.splice(index, 1);
  }

  clearCheckListItem(item) {
    if (!item.oldTitle) {
      this.removeItem(item);
    }
    item.title = item.oldTitle;
    this.editingItem = null;
  }

  editCheckListItem(item) {
    this.newItem = null;
    if (this.editingItem) {
      this.editingItem.title = this.editingItem.oldTitle;
    }
    item.oldTitle = item.title;
    this.editingItem = item;
  }

  addCheckListItem(item) {
    if (!item.title) {
      return;
    }
    this.editingItem = null;
  }

  saveCheckListItem() {
    if (!this.newItem?.title) {
      return;
    }
    if (!this.topic.checklist_template) {
      this.topic.checklist_template = [];
    }
    this.topic.checklist_template.push(this.newItem);
    this.newItem = null;
  }

  newChecklistItem() {
    if (this.editingItem) {
      this.editingItem.title = this.editingItem.oldTitle;
    }
    this.editingItem = null;
    this.newItem = { work_order_topic_id: this.topic.id };
  }

  blur() {
    this.saveCheckListItem();
  }
}
