import { Pipe, PipeTransform } from '@angular/core';
import { ProjectListFilter } from 'src/app/models';
import { FilterBySelectedTagsPipe } from 'src/app/pipes/filter-by-selected-tags.pipe';
import { Project } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Pipe({
  name: 'projectListFilter',
})
export class ProjectListFilterPipe implements PipeTransform {
  transform(projects: ProjectConstruction[], filter: ProjectListFilter, filteredCount?: any): any {
    if (!projects || !filter) {
      return projects;
    }

    if (filter.statusId && filter.statusId != -1) {
      projects = projects.filter((p) => p.status && p.status.id === filter.statusId);
    }

    if (filter.userId) {
      projects = projects.filter(
        (p) => (p.project_manager && p.project_manager.id === filter.userId) || p?.architect?.id === filter.userId
      );
    }

    if (filter.projectManagers.length) {
      projects = projects.filter(
        (project) =>
          project.project_manager &&
          project.project_manager.id &&
          filter.projectManagers.includes(project.project_manager.id.toString())
      );
    }

    if (filter.architects.length) {
      projects = projects.filter(
        (project) =>
          project.architect && project.architect.id && filter.architects.includes(project.architect.id.toString())
      );
    }

    if (filter.buildings.length) {
      projects = projects.filter(
        (project) =>
          project.building && project.building.id && filter.buildings.includes(project.building.id.toString())
      );
    }

    if (filter.floors.length) {
      projects = projects.filter(
        (project) => project.floor && project.floor.name && filter.floors.includes(project.floor.name)
      );
    }

    if (filter.topics.length) {
      projects = projects.filter((project) => project.topic_id && filter.topics.includes(project.topic_id.toString()));
    }

    if (filter.tags?.length) {
      const filterBySelectedTagsPipe = new FilterBySelectedTagsPipe();
      projects = filterBySelectedTagsPipe.transform(projects, filter.tags ?? []);
    }

    if (filter.workspaceIds.length > 0) {
      projects = projects.filter((p) => filter.workspaceIds.includes(+p.module_id));
    }

    if (filter.subStatuses.length > 0) {
      projects = projects.filter((p: ProjectConstruction) =>
        filter.subStatuses.includes(p.substatus_id?.toString() || '')
      );
    }

    if (filter.searchString?.length > 0) {
      const searchTerm = filter.searchString.toLowerCase();

      projects = projects.filter((project: Project) => {
        return (
          project.title?.toLowerCase()?.includes(searchTerm) ||
          project.building?.name?.toLowerCase()?.includes(searchTerm) ||
          project.building?.code?.toLowerCase()?.includes(searchTerm) ||
          project.floor?.code?.toLowerCase()?.includes(searchTerm) ||
          project.floor?.name?.toLowerCase()?.includes(searchTerm) ||
          `${project.building?.code}-${project.floor?.code}`.toLowerCase()?.includes(searchTerm) ||
          `${project.building?.code}-${project.floor?.name}`.toLowerCase()?.includes(searchTerm) ||
          (project.project_manager?.first_name &&
            project.project_manager?.last_name &&
            (project.project_manager?.first_name + ' ' + project.project_manager?.last_name)
              ?.toLowerCase()
              ?.includes(searchTerm)) ||
          (project.requester?.first_name &&
            project.requester?.last_name &&
            (project.requester?.first_name + ' ' + project.requester?.last_name)
              ?.toLowerCase()
              ?.includes(searchTerm)) ||
          project.priority_name?.toLowerCase()?.includes(searchTerm) ||
          project.current_phase_name?.toLowerCase()?.includes(searchTerm) ||
          project.code?.toLowerCase()?.includes(searchTerm)
        );
      });
    }

    if (filteredCount) {
      filteredCount.count = projects.length;
    }
    return projects;
  }
}
