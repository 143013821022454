<app-base-dialog [title]="'View Bid'" (closeDialog)="close()"></app-base-dialog>
<div class="project-modal p-0">
  <mat-dialog-content class="product-group-form">
    <div class="row mb-4">
      <div class="col">
        <h6 class="font-weight-semibold dkblue mb-2">Supplier Company</h6>
        <h5 class="m-0 dkblue font-weight-normal">
          {{ quote?.company?.name }}
        </h5>
      </div>
      <div class="col" *ngIf="quote?.contact_id">
        <h6 class="font-weight-semibold dkblue mb-2">Supplier Contact</h6>
        <h5 class="m-0 dkblue font-weight-normal">{{ quote?.contact?.first_name }} {{ quote?.contact?.last_name }}</h5>
      </div>
      <!-- IF SUPPLIER QUOTE -->
      <!-- <div class="col" *ngIf="quote?.contact_id">
        <h6 class="font-weight-semibold dkblue mb-2">
          Supplier Contact
        </h6>
        <div class="d-flex align-items-center pointer">
          <app-profile-thumbnail
            [noBorder]="true"
            class="mr-2"
            [height]="20"
            [width]="20"
            [userId]="quote?.contact_id"
          ></app-profile-thumbnail>
          <p class="m-0 ltblue font-weight-normal">
            {{ quote?.contact?.first_name }} {{ quote?.contact?.last_name }}
          </p>
        </div>
      </div> -->
      <!-- IF ONLINE/STORE -->
      <!-- <div class="col" *ngIf="quote?.product_url">
        <h6 class="font-weight-semibold dkblue mb-2">
          Product URL
        </h6>
        <div class="d-flex align-items-center pointer">
          <h5 class="m-0 ltblue hover-u font-weight-normal text-wrap">
            <a
              href="{{
                quote?.product_url.includes('http')
                  ? quote?.product_url
                  : 'https://' + quote?.product_url
              }}"
              target="_blank"
              >{{ quote?.product_url }}</a
            >
          </h5>
        </div>
      </div> -->
    </div>
    <div class="row mt-4 mb-4" *ngIf="quote?.description">
      <div class="col">
        <h6 class="font-weight-semibold dkblue mb-2">Short Description</h6>
        <h5 class="m-0 dkblue font-weight-normal">
          {{ quote?.description }}
        </h5>
      </div>
    </div>
    <section>
      <div class="m-portlet m-0">
        <div class="m-portlet__body alt-bg-gray item-no-hover pt-2 pb-2 pl-3 pr-3">
          <div class="row align-items-center">
            <div class="col-4">
              <h6 class="dkblue font-weight-semibold m-0">Product</h6>
            </div>
            <div class="col text-right">
              <h6 class="dkblue font-weight-semibold m-0">Quantity</h6>
            </div>
            <div class="col text-right">
              <h6 class="dkblue font-weight-semibold m-0">Price</h6>
            </div>
            <div class="col text-right">
              <h6 class="dkblue font-weight-semibold m-0">Total</h6>
            </div>
          </div>
        </div>
        <div class="m-portlet__body item p-3" *ngFor="let quoteItem of quote?.quote_items">
          <div class="row align-items-center">
            <div class="col-4">
              <p class="dkblue font-weight-semibold m-0">
                <i
                  *ngIf="showSelected && quoteItem.id === quoteItem.project_product?.selected_quote_item_id"
                  class="fa fa-check mr-1"
                ></i>
                {{ quoteItem.project_product?.product?.name || quoteItem.project_product?.name }}
              </p>
              <h6 class="dkblue m-0 font-weight-normal">
                {{ getProjectProductDetails(quoteItem.project_product) }}
              </h6>
            </div>
            <div class="col text-right">
              <p class="dkblue font-weight-semibold m-0">
                {{ quoteItem.project_product?.quantity }}
              </p>
            </div>
            <div class="col text-right">
              <p class="dkblue font-weight-semibold m-0">
                {{ quoteItem.status_id === 1 ? (quoteItem.unit_price || 0 | USDollar: 3:true) : 'DNB' }}
              </p>
            </div>
            <div class="col text-right">
              <p class="dkblue font-weight-semibold m-0">
                {{ quoteItem.status_id === 1 ? (quoteItem.total_price || 0 | USDollar: 2:true) : 'DNB' }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-top m-0 mt-3">
        <div class="col-7 pl-0">
          <div class="m-portlet">
            <div class="m-portlet__body item d-flex align-items-center p-2" *ngIf="quote?.quote_file">
              <p class="m-0 dkblue font-weight-semibold">Bid File</p>
              <mat-chip-list class="ml-auto">
                <app-file-chip [file]="quote.quote_file"></app-file-chip>
              </mat-chip-list>
            </div>
            <div class="m-portlet__body item d-flex align-items-center p-2" *ngIf="quote?.solicitation_file">
              <p class="m-0 dkblue font-weight-semibold">Solicitation File</p>
              <mat-chip-list class="ml-auto">
                <app-file-chip [file]="quote.solicitation_file"></app-file-chip>
              </mat-chip-list>
            </div>
            <div
              class="m-portlet__body item item-last p-2 d-flex flex-column text-left"
              *ngIf="quote?.files?.length > 0"
            >
              <p class="m-0 dkblue font-weight-semibold mb-2">Misc Files</p>
              <mat-chip-list>
                <app-file-chip *ngFor="let file of quote?.files" [file]="file"></app-file-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="col d-flex flex-column text-right">
          <h6 class="font-weight-semibold pb-3 dkblue m-0">Subtotal</h6>
          <h6 class="font-weight-semibold pb-3 dkblue m-0" *ngIf="quote?.tax > 0">Tax</h6>
          <h6 class="font-weight-semibold dkblue m-0">Total</h6>
        </div>
        <div class="col d-flex flex-column text-right">
          <h6 class="font-weight-semibold dkblue pb-3 m-0">
            {{ quote?.subtotal || 0 | USDollar: 2:true }}
          </h6>
          <h6 class="font-weight-normal dkblue pb-3 m-0" *ngIf="quote?.tax > 0">
            {{ quote?.tax || 0 | USDollar: 2:true }}
          </h6>
          <h6 class="font-weight-semibold dkblue m-0">
            {{ +quote?.subtotal + +quote?.tax | USDollar: 2:true }}
          </h6>
        </div>
      </div>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions class="row pt-4 pb-4">
    <div class="col">
      <button mat-button mat-dialog-close class="gray full-width">Dismiss</button>
    </div>
  </mat-dialog-actions>
</div>
