<section>
  <div
    *ngIf="
      !isOpenReview ||
      task.status_id === TaskStatus.Complete ||
      (reviewers?.length && reviewers[0].status !== TaskReviewStatus.Pending)
    "
  >
    <div class="d-flex flex-column reviewers mb-3" *ngFor="let reviewer of reviewers; let i = index">
      <div class="d-flex align-items-center">
        <app-profile-thumbnail
          class="pointer pr-2"
          (click)="openUserProfilePreview(reviewer?.id)"
          [matTooltip]="reviewer.first_name + ' ' + reviewer.last_name"
          [width]="36"
          [height]="36"
          [userId]="reviewer?.id"
        ></app-profile-thumbnail>
        <div class="d-flex flex-column mr-auto text-truncate">
          <p class="dkblue font-weight-semibold m-0 text-truncate">
            {{ reviewer.first_name }} {{ reviewer.last_name }}
          </p>
          <h6 class="font-weight-normal dkgray m-0 text-truncate" style="font-size: 11px">
            {{ reviewer.title }}
          </h6>
        </div>
        <div class="d-flex flex-column ml-3 align-items-end" *ngIf="!undoLoading">
          <p
            class="font-weight-semibold m-0 d-flex align-items-center text-right"
            [ngClass]="displayReviewersService.getStatusColor(reviewer, reviewer?.id, currentReviewer?.id)"
          >
            <i
              *ngIf="displayReviewersService.getStatusIcon(reviewer.status, isOpenReview)"
              class="material-icons d-inline-block align-middle"
              style="font-size: 14px"
              [ngClass]="displayReviewersService.getStatusColor(reviewer)"
            >
              {{ displayReviewersService.getStatusIcon(reviewer.status, isOpenReview) }}
            </i>
            <span class="m-0 font-weight-semibold ml-1">
              {{ displayReviewersService.getStatusText(reviewer, currentReviewer?.id, reviewIsRejected, isOpenReview) }}
            </span>
          </p>
          <h6
            *ngIf="
              currentReviewerIndex > i ||
              (currentReviewerIndex === i && reviewer.status !== TaskReviewStatus.Pending) ||
              (isOpenReview && reviewer.status === TaskReviewStatus.Approved)
            "
            class="font-weight-normal dkgray m-0 text-truncate"
            style="font-size: 11px"
          >
            {{ formatDate(reviewer.date) }}
          </h6>
        </div>
        <div class="d-flex flex-column ml-3 align-items-end">
          <mat-spinner
            *ngIf="undoLoading && reviewer.id === authService.currentUser.id"
            class="d-inline-block align-middle m-0"
            color="accent"
            [diameter]="24"
          ></mat-spinner>
        </div>
        <div
          *ngIf="
            reviewer.status !== TaskReviewStatus.Pending &&
            reviewer.id === authService.currentUser.id &&
            i === reviewers.length - 1 &&
            !undoLoading
          "
          class="undo-icon pointer"
          matTooltip="Retract Review"
          matTooltipPosition="above"
          (click)="undoReview()"
        >
          <mat-icon>sync</mat-icon>
        </div>
      </div>
      <div
        class="row mt-3"
        [hidden]="
          reviewer.status === TaskReviewStatus.Pending ||
          currentReviewerIndex === i ||
          (!reviewer.comment && !reviewer?.files?.length)
        "
      >
        <div class="col">
          <div class="ml-4" [innerHTML]="reviewer.comment"></div>
          <mat-chip-list class="d-inline-block align-middle ml-4" *ngIf="reviewer?.files?.length">
            <app-file-chip
              *ngFor="let file of reviewer.files || []"
              [file]="file"
              [removable]="true"
              [useParentPreview]="true"
            ></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
      <div
        class="row mt-3"
        [hidden]="
          reviewer.status !== TaskReviewStatus.Pending ||
          (currentReviewerIndex !== i && !isReviewerAdmin) ||
          reviewer.id !== authService.currentUser.id
        "
      >
        <div class="col">
          <app-editor #editor placeholder="Leave a comment"></app-editor>
          <span class="red lh-18" *ngIf="!reviewIsValid"> Comment required for {{ warningText }} </span>
        </div>
      </div>
      <div
        class="row mt-2"
        [hidden]="
          reviewer.status !== TaskReviewStatus.Pending ||
          (currentReviewerIndex !== i && !isReviewerAdmin) ||
          reviewer.id !== authService.currentUser.id
        "
      >
        <div class="col text-left">
          <div *ngIf="attachedFiles" class="row mb-2">
            <div class="col text-left">
              <button
                mat-button
                color="accent"
                type="button"
                class="lh-24 mb-2 px-2 mr-3"
                (click)="openFileAttachmentDialog()"
                [disabled]="disableReview"
              >
                + Attach Files
              </button>
              <mat-chip-list class="d-inline-block align-middle" *ngIf="attachedFiles?.length">
                <app-file-chip
                  *ngFor="let file of attachedFiles"
                  [file]="file"
                  [removable]="true"
                  (removeEvent)="removeFile(file)"
                ></app-file-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row mt-3"
        [hidden]="
          reviewer.status !== TaskReviewStatus.Pending ||
          (currentReviewerIndex !== i && !isReviewerAdmin) ||
          reviewer.id !== authService.currentUser.id
        "
      >
        <div class="col-6 pr-2" [hidden]="accessoryData.type === TaskAccessoryType.Submittals">
          <button
            mat-stroked-button
            (click)="close(TaskReviewStatus.Rejected, editor)"
            color="warn"
            class="full-width"
            type="button"
            [disabled]="disableReview || !editor?.content?.value"
          >
            {{ rejectText }}
          </button>
          <small *ngIf="!editor?.content?.value" class="gray lh-18">Comment required to Reject </small>
        </div>
        <div class="col-6 pl-2" [hidden]="accessoryData.type === TaskAccessoryType.Submittals">
          <button
            mat-flat-button
            (click)="close(TaskReviewStatus.Approved, editor)"
            color="success"
            class="full-width"
            type="button"
            [disabled]="disableReview"
          >
            Approve
          </button>
        </div>
        <div class="col" [hidden]="accessoryData.type !== TaskAccessoryType.Submittals">
          <button
            mat-flat-button
            color="primary"
            class="full-width mt-3 lh-36 px-3"
            *ngFor="let status of approvalStatuses"
            [value]="status.status"
            [ngClass]="status.color"
            (click)="close(status.status, editor)"
          >
            <span class="d-flex align-items-center">
              <mat-icon class="mr-2" [ngClass]="status.color">{{ status.icon }}</mat-icon>
              <span class="mr-auto">
                {{ status.title }}
              </span>
              <!-- <mat-icon class="ml-auto">
                check_box_outline_blank
              </mat-icon> -->
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="d-flex align-items-center">
    <mat-spinner color="accent" class="mx-auto" [diameter]="24"></mat-spinner>
  </div>
  <div
    *ngIf="
      isOpenReview &&
      task.status_id !== TaskStatus.Complete &&
      reviewers?.length &&
      reviewers[0].status === TaskReviewStatus.Pending &&
      !undoLoading &&
      !isLoading
    "
    class="m-portlet bg-transparent"
  >
    <div class="m-portlet__body text-center p-4 mb-3 d-flex align-items-center">
      <h6 class="font-weight-semibold m-0 dkgray lh-18 mb-1 mt-1 full-width">
        This Key Control task is not yet complete.
      </h6>
    </div>
  </div>
  <div class="d-flex">
    <button (click)="close(null, null, true)" mat-button type="button" color="accent" class="full-width gray mt-4">
      Close Window
    </button>
    <button
      *ngIf="reviewIsRejected && isProjectManager && !isOpenReview"
      (click)="resetReview()"
      mat-button
      type="button"
      color="accent"
      class="full-width mt-4"
    >
      Reset Review
    </button>
  </div>
</section>
