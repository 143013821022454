import { Component, Input, OnInit } from '@angular/core';

import * as moment from 'moment';

import { AuthService, MessagingSystemService } from 'src/app/services';
import { Message } from 'src/app/models';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-messaging-inbox-message-box',
  templateUrl: './messaging-inbox-message-box.component.html',
  styleUrls: ['./messaging-inbox-message-box.component.scss'],
})
export class MessagingInboxMessageBoxComponent implements OnInit {
  @Input() message: Message;

  @Input() projects: ProjectConstruction[];

  public myProjectName: string;

  public myProjectCode: string;

  private readonly loggedInUserId: number;

  // TODO: I had to add this so the app would build -Austin
  messageSearchTerm;

  constructor(public authService: AuthService, private messagingService: MessagingSystemService) {
    this.loggedInUserId = this.authService.getLoggedInUser().id;
  }

  ngOnInit() {
    if (this.projects && this.message.channel_project_id) {
      const foundProject = this.projects.find((p) => p.id === this.message.channel_project_id);
      if (foundProject) {
        this.myProjectName = foundProject.title;
        this.myProjectCode = foundProject.code;
      }
    }
  }

  isMessageToMe(): boolean {
    if (!this.message.to_users) {
      return false;
    }
    const found = this.message.to_users.find((u) => u.id === this.loggedInUserId);
    return found;
  }

  createdByMe(): boolean {
    return this.authService.getLoggedInUser().id === this.message.created_by_id;
  }

  markUnread() {
    this.messagingService.changeMessageStatus(this.message, false).subscribe((m) => {
      this.message.read_by_user_ids = m.read_by_user_ids;
    });
  }

  markRead() {
    this.messagingService.changeMessageStatus(this.message, true).subscribe((m) => {
      this.message.read_by_user_ids = m.read_by_user_ids;
    });
  }

  isSelected(): boolean {
    return this.messagingService.currentSelectedMessage === this.message;
  }

  isReadByMe(): boolean {
    if (!this.message.read_by_user_ids) {
      return false;
    }
    return this.message.read_by_user_ids.includes(this.loggedInUserId);
  }

  selectMe() {
    if (!this.isReadByMe()) {
      this.markRead();
    }
    this.messagingService.selectMessage(this.message);
  }

  getDateText(): string {
    const today = moment(new Date()).format('MM/DD/YY');
    const tomorrow = moment(new Date()).add(1, 'days').format('MM/DD/YY');
    const yesterday = moment(new Date()).add(-1, 'days').format('MM/DD/YY');
    const messageDate = moment(this.message.created_datetime).format('MM/DD/YY');
    if (messageDate === today) {
      return moment(this.message.created_datetime).format('hh:mm a');
    }
    if (messageDate === tomorrow) {
      return 'Tomorrow';
    }
    if (messageDate === yesterday) {
      return 'Yesterday';
    }
    return messageDate;
  }
}
