import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APIFilter, ServiceResponse } from '../types';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';

export enum AcivityItem {
  WorkOrder = 'Work Order',
}

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  host: string = environment.serviceHost;
  activityUrl = `${this.host}/api/v1/activity`;
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getActivity(apiFilters?: APIFilter[]) {
    const fields = 'id,item_name,item_id,user_id,user,impersonator_id,impersonator,data,created_datetime';
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.activityUrl}?fields=${fields}${!filterString ? '' : `&${filterString}`}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data.activity.map((a) => {
          a.data = JSON.parse(a.data);
          return a;
        });
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
