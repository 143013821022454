<app-base-dialog [title]="'Construction Schedule'" (closeDialog)="close()"></app-base-dialog>
<mat-dialog-content
  id="main"
  #main
  class="bg-shade-dkblue border-bottom border-top border-gray edit-contract-dialog p-3"
>
  <div class="row">
    <div
      *ngIf="loading"
      class="col-xl-8 col-lg-10 col-md-12 text-center font-weight-semibold p-5 my-5 mx-auto border border-gray bg-white"
    >
      <mat-spinner diameter="100" color="accent" class="mx-auto mb-5"> </mat-spinner>
      <h5 class="mx-auto accent mb-0">Loading Construction Schedule...</h5>
    </div>
    <div
      *ngIf="!loading"
      class="col-xl-7 col-lg-10 col-md-12 mx-auto my-3 border border-gray bg-white p-5 d-flex flex-column"
    >
      <section id="contract-cover-page">
        <div class="row mb-5 pb-5">
          <div class="col-4">
            <img id="contract-header-logo" src="../../assets/img/uhat_logo_forms.png" alt="" />
          </div>
          <div class="col"></div>
          <div class="col-4">
            <label class="main-label text-uppercase mb-2"> Project Manager </label>
            <h5 class="dkblue font-weight-semibold mb-0">
              {{ projectManager?.first_name }} {{ projectManager?.last_name }}
            </h5>
            <p class="dkblue font-weight-normal mb-0">{{ projectManager?.company?.name }}</p>
          </div>
        </div>
        <div class="row m-0 py-2 header-row mt-5">
          <div class="col px-0">
            <h1 class="dkblue mb-0 font-weight-bold">
              Contract Addendum <br />
              Established Project Schedule
            </h1>
          </div>
        </div>
        <div class="row m-0 py-2 mt-4">
          <div class="col-3 px-0">
            <label class="main-label text-uppercase mb-2"> Prepared For </label>
            <h5 class="dkblue font-weight-semibold mb-0">{{ bidCompany?.name }}</h5>
            <p class="dkblue mb-4">
              {{ bidContact?.first_name }} {{ bidContact?.last_name }}<br />
              {{ bidContact?.title }}<br />
            </p>
            <label class="main-label text-uppercase mb-2"> Trade </label>
            <h5 class="dkblue mb-4">{{ bidPackage?.number }} - {{ bidPackage?.trade?.name }}</h5>
          </div>
          <div class="col">
            <p class="dkblue lh-24 mb-4">
              Per project schedule review during the
              {{ project?.code }} - {{ project?.title }} pre-construction meeting on
              {{ bid?.pre_construction_meeting?.start_datetime | date: 'MMMM d, YYYY' }}, the attached project schedule
              has been agreed upon by all parties for the construction timeline pending no further changes in project
              scope or unforeseen conditions.
            </p>
            <label class="main-label text-uppercase mt-0"> Project </label>
            <p class="dkblue font-weight-semibold lh-24 mb-4">{{ project?.code }} - {{ project?.title }}</p>
            <label class="main-label text-uppercase mt-0"> Construction Start Date </label>
            <p class="dkblue font-weight-semibold lh-24 mb-4">
              {{ project?.bid_details?.construction_start_date | date: 'MMMM d YYYY' }}
            </p>
            <label class="main-label text-uppercase mt-0"> Construction End Date </label>
            <p class="dkblue font-weight-semibold lh-24 mb-4">
              {{ project?.bid_details?.construction_end_date | date: 'MMMM d YYYY' }}
            </p>
            <section id="trades-vendors">
              <div class="row mb-2 mx-0">
                <div class="col-12 px-0">
                  <label class="main-label text-uppercase mt-0"> Trades / Suppliers </label>
                </div>
                <div *ngFor="let p of timelineTrades" class="col-6 pl-0">
                  <p class="dkblue lh-24 mb-0">
                    <span class="font-weight-semibold"> {{ p.trade_name }}: </span>
                    {{ p.awarded_company_name }}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section id="signature-component">
        <div class="row mx-0 mb-4 d-flex align-items-top">
          <div class="col-12 px-0">
            <div class="row m-0">
              <div class="col-1"></div>
              <div class="col-5 d-flex flex-column pr-5">
                <h3 class="dkblue mb-3">Construction Schedule</h3>
                <p class="dkgray terms mb-4">
                  The latest Construction Schedule is attached here. Click on it to view or download.
                </p>
                <mat-chip-list *ngIf="project?.project_schedule_file_id">
                  <app-file-chip [file]="{ id: project?.project_schedule_file_id }"></app-file-chip>
                </mat-chip-list>
              </div>
              <div class="col-5 d-flex flex-column align-items-top pl-4">
                <!-- <div class="full-width mb-5 border border-gray br-5 p-2 pl-3 d-flex align-items-center">
                    <p class="dkblue font-weight-semibold mb-0 mr-2">Construction Timeline</p>
                    <mat-chip-list class="ml-auto" *ngIf="project?.project_schedule_file_id">
                      <app-file-chip [file]="{ id: project?.project_schedule_file_id }"></app-file-chip>
                    </mat-chip-list>
                  </div> -->
                <span class="mb-5">
                  <h3 class="dkblue mb-3">Sign Here</h3>
                  <mat-form-field class="full-width mb-3" appearance="outline" *ngIf="bid">
                    <input matInput placeholder="Type Name Here" [(ngModel)]="bid.timeline_vendor_signature_text" />
                  </mat-form-field>
                  <p class="dkgray terms">
                    This Agreement is legally binding and describes the terms governing your use of the 1CALL Cloud
                    services provided on this website, including content, updates, and releases.
                  </p>
                  <a
                    href="https://uhat.org/terms-of-service"
                    target="_blank"
                    class="ltblue hover-u font-weight-semibold"
                  >
                    Full Terms and Conditions</a
                  >
                </span>
                <h1 class="font-weight-semibold mb-3 pb-0 border-bottom border-dkblue full-width signature lh-34">
                  {{ bid?.timeline_vendor_signature_text ? bid?.timeline_vendor_signature_text : 'x' }}
                </h1>
                <h5 class="dkblue mb-0 font-weight-semibold mt-1">CONTRACTOR SIGNATURE</h5>
                <p class="dkblue font-italic mb-4">{{ bid?.timeline_vendor_signed_datetime | date: 'MMMM d YYYY' }}</p>
                <h5 class="dkblue mb-0">{{ bidContact?.first_name }} {{ bidContact?.last_name }}</h5>
                <p class="dkblue mb-4">{{ bidCompany?.name }}</p>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex align-items-center" align="end">
  <button (click)="close()" mat-button class="gray ml-4">Cancel</button>
  <button mat-button *ngIf="!loading" (click)="exportContract()" color="accent" class="ml-4">Download PDF</button>
  <button mat-button *ngIf="!loading" color="warn" class="ml-4" (click)="requestRevision()">Request Revision</button>
  <button
    mat-flat-button
    *ngIf="!loading"
    class="ml-4 bg-gold white"
    (click)="submitApproval(TaskReviewStatus.Approved)"
  >
    Sign Contract
  </button>
</mat-dialog-actions>

<app-project-schedule-contract-export
  #pdf
  [bid]="bid"
  [timelineTrades]="timelineTrades"
></app-project-schedule-contract-export>
