import { Pipe, PipeTransform } from '@angular/core';
import { ArfProduct } from 'src/app/types';
import { getUniqueArray } from 'src/app/utils';

@Pipe({
  name: 'getArfProductSubCostCodes',
})
export class GetArfProductSubCostCodesPipe implements PipeTransform {
  transform(arfProducts: ArfProduct[] = []): string {
    const subCostCodes: string[] = [];

    for (const product of arfProducts) {
      const code = product.sub_cost_code_budget?.sub_cost_code?.code;

      if (code && code !== 'not_found') {
        subCostCodes.push(code);
      }
    }

    return getUniqueArray(subCostCodes).join(', ');
  }
}
