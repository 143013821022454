<div
  data-test-id="project"
  id="project"
  class="project ease"
  [ngClass]="{
    'nav-closed': isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
    'nav-open': !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
    'notifications-sidenav-open':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,
    'notifications-sidenav-closed':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
  }"
>
  <app-project-header
    [project]="project"
    (closeProject)="closeProject()"
    (reActivateProject)="reActivateProject($event)"
    (setProjectToActive)="setProjectToActive()"
    (setProjectToPlanned)="markAsPlanned()"
    (setProjectToOnHold)="placeOnHold()"
    [isClosedProject]="isClosedProject"
  ></app-project-header>
  <app-project-nav [project]="project" [isClosedProject]="isClosedProject"></app-project-nav>
  <main
    [ngClass]="{
      'main-top-banner': isClosedProject
    }"
  >
    <!-- PAGE COMPONENTS GO HERE -->
    <!--    TODO something is wrong with the container here. The scrolling is off, and everything is too far to the edges-->
    <app-project-overview *ngIf="subcomponent === 'overview'"> </app-project-overview>
    <app-tasks *ngIf="subcomponent === 'tasks'"> </app-tasks>
    <app-files [project]="project" *ngIf="subcomponent === 'files'"></app-files>
    <!-- <app-project-overview *ngIf="subcomponent === 'overview'"> </app-project-overview>
    <app-project-tasks *ngIf="subcomponent === 'tasks'"> </app-project-tasks> -->
    <app-products *ngIf="subcomponent === 'purchasing'"></app-products>
    <app-invoices *ngIf="subcomponent === 'invoices'"></app-invoices>
    <app-time-log *ngIf="subcomponent === 'time-log'"></app-time-log>
    <app-project-directory *ngIf="subcomponent === 'directory'"></app-project-directory>
  </main>
</div>
