<div #mainScreen id="task-list" data-test-id="task-list" class="task-list app-container">
  <!--the backdrop closes the aside as well-->
  <div>
    <!--the backdrop closes the aside as well-->
    <section id="main-task-list-wrapper" class="ease p-4">
      <header class="mb-4">
        <div class="d-flex align-items-center">
          <span class="d-flex flex-column mr-auto">
            <h2 class="m-0 dkblue font-weight-semibold d-inline-block align-bottom">Tasks</h2>
          </span>
          <div class="d-flex">
            <!-- <button mat-stroked-button class="ml-3 bg-white lh-34" color="primary">
              <i class="material-icons-outlined"> file_download </i>
              <span> Download CSV </span>
            </button> -->
          </div>
        </div>
        <p class="dkgray font-weight-normal mb-2">View tasks assigned to you, others, or by workspace.</p>
      </header>
      <div class="row mb-4">
        <div class="col">
          <mat-button-toggle-group class="mr-3" name="taskStatus" aria-label="List" [(ngModel)]="daysDue">
            <mat-button-toggle
              [value]="-1"
              class="px-2 py-1 lh-34 font-weight-semibold align-items-center d-flex"
              (click)="refresh()"
            >
              <i class="material-icons success mr-2">check_circle</i>
              All Active Tasks
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{
                  'bg-ltblue': daysDue === -1,
                  'bg-gray': daysDue !== -1
                }"
              >
                {{ counts?.all_tasks || 0 }}
              </span>
            </mat-button-toggle>
            <mat-button-toggle
              [value]="1"
              class="px-2 py-1 lh-34 font-weight-semibold align-items-center d-flex"
              (click)="refresh()"
            >
              <i class="material-icons accent mr-2">event</i>
              Due Today
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{
                  'bg-ltblue': daysDue === 1,
                  'bg-gray': daysDue !== 1
                }"
              >
                {{ counts?.due_today || 0 }}
              </span>
            </mat-button-toggle>
            <mat-button-toggle
              [value]="7"
              class="px-2 py-1 lh-34 font-weight-semibold align-items-center d-flex"
              (click)="refresh()"
            >
              <i class="material-icons accent mr-2">date_range</i>
              Due this week
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{
                  'bg-ltblue': daysDue === 7,
                  'bg-gray': daysDue !== 7
                }"
              >
                {{ counts?.due_this_week || 0 }}
              </span>
            </mat-button-toggle>
            <mat-button-toggle
              [value]="30"
              class="px-2 py-1 lh-34 font-weight-semibold align-items-center d-flex"
              (click)="refresh()"
            >
              <i class="material-icons accent mr-2">calendar_month</i>
              Due this month
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{
                  'bg-ltblue': daysDue === 30,
                  'bg-gray': daysDue !== 30
                }"
              >
                {{ counts?.due_this_month || 0 }}
              </span>
            </mat-button-toggle>
            <mat-button-toggle
              [value]="0"
              class="px-2 py-1 lh-34 font-weight-semibold align-items-center d-flex"
              (click)="refresh()"
            >
              <i class="material-icons red mr-2">error</i>
              Overdue
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{
                  'bg-ltblue': daysDue === 0,
                  'bg-gray': daysDue !== 0
                }"
              >
                {{ counts?.overdue || 0 }}
              </span>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <!-- <button
            mat-stroked-button
            [matMenuTriggerFor]="my_work_orders"
            class="bg-white lh-38 pr-2 pl-3"
            color="primary"
            matBadgePosition="after"
            [matBadge]="
              following === true || assignedToCurrentUser === true
                ? (following === true ? 1 : 0) + (assignedToCurrentUser === true ? 1 : 0)
                : null
            "
            matBadgeColor="warn"
          >
            <span> My Tasks Only </span>
            <i class="material-icons">arrow_drop_down</i>
          </button>
          <mat-menu #my_work_orders="matMenu" class="mt-2" xPosition="before">
            <button mat-menu-item color="primary" (click)="toggleMyTasks()">
              <span class="px-2">
                {{ following === true || assignedToCurrentUser === true ? 'Deselect' : 'Select' }} All My Tasks</span
              >
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item color="primary" (click)="assignedToCurrentUserChanged()">
              <mat-icon *ngIf="assignedToCurrentUser">check_box</mat-icon>
              <mat-icon *ngIf="!assignedToCurrentUser">check_box_outline_blank</mat-icon>
              <span class="mr-3">Assigned to Me</span>
            </button>
            <button (click)="followingChanged()" mat-menu-item color="primary">
              <mat-icon *ngIf="following">check_box</mat-icon>
              <mat-icon *ngIf="!following">check_box_outline_blank</mat-icon>
              <span class="mr-3">I'm Following</span>
            </button>
          </mat-menu> -->
        </div>
      </div>
      <div class="row">
        <div class="col mb-4">
          <mat-form-field appearance="outline" class="mr-3">
            <mat-select
              multiple
              class="dkblue"
              [(ngModel)]="workspace"
              (selectionChange)="workspaceChanged()"
              [placeholder]="workspace?.length > 0 ? workspace[0].name : 'All Workspaces'"
            >
              <div *ngFor="let workspace of workspaces; index as index">
                <mat-option [value]="workspace">
                  {{ workspace.name }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-3 dkblue">
            <mat-select
              class="dkblue"
              [(ngModel)]="taskType"
              (selectionChange)="taskTypeChanged()"
              [placeholder]="taskType ? taskType : 'All Task Types'"
            >
              <mat-option>All Task Types</mat-option>
              <mat-option value="review">Review Tasks Only</mat-option>
              <mat-option value="action">Action Tasks Only</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-3 dkblue">
            <mat-select
              multiple
              class="dkblue"
              [(ngModel)]="assignedUsers"
              (selectionChange)="assignedUsersChanged()"
              placeholder="All Assignees"
            >
              <button mat-button style="width: 100%" (click)="toggleAssignedUsers()">
                {{ assignedUsers?.length > 0 ? 'Clear All' : 'Select All' }}
              </button>
              <mat-divider></mat-divider>
              <div *ngFor="let assignedUser of filteredAssignedUsers">
                <mat-option [value]="assignedUser">
                  <app-profile-thumbnail
                    [noBorder]="true"
                    class="d-inline-block align-middle ml-2 mr-3"
                    [height]="26"
                    [width]="26"
                    [userId]="assignedUser?.id"
                  ></app-profile-thumbnail>
                  <h5 *ngIf="assignedUser?.id !== 0" class="dkblue m-0 d-inline-block align-middle font-weight-normal">
                    {{ assignedUser?.first_name }} {{ assignedUser?.last_name }}
                  </h5>
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-slide-toggle color="accent" [checked]="following" (change)="followingChanged()">
            Tasks I'm Following
          </mat-slide-toggle>
        </div>

        <div class="col-auto mb-4 text-right">
          <mat-form-field appearance="outline">
            <mat-icon class="dkblue" matPrefix>search</mat-icon>
            <input matInput placeholder="Search Tasks" [(ngModel)]="search" (ngModelChange)="refresh()" />
            <mat-icon class="gray pointer" matSuffix *ngIf="search" (click)="search = null; refresh()">cancel</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <main>
        <div class="m-portlet mb-0">
          <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
            <div class="row d-flex align-items-center">
              <div class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
              </div>
              <div class="col-auto pt-1 pb-1 px-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto px-1">
                  <i class="material-icons primary">account_circle</i>
                </h6>
              </div>
              <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Task / Parent</h6>
              </div>
              <div
                class="col-auto pt-1 pb-1 pr-4 pl-3 d-flex align-items-center header-item"
                (click)="updateSortByField('due_date')"
              >
                <h6 class="m-0 text-truncate header-title mr-5 pr-5">Due Date</h6>
                <i *ngIf="fieldToSortBy === 'due_date'" matRipple class="material-icons">
                  {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                </i>
              </div>
              <div class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                <h6 class="m-0 text-truncate header-title mr-auto">Menu</h6>
              </div>
            </div>
          </div>
          <div *ngIf="filteredCount.count === 0 && !loading">
            <div class="row my-5">
              <div class="col-md-5 py-5 mx-auto text-center">
                <h5 class="dkblue lh-24">No Tasks found.</h5>
              </div>
            </div>
          </div>
          <div
            *ngFor="
              let task of tasks
                | taskLists: filters:filteredCount:counts
                | orderByField: fieldToSortBy:sortDirection
                | slice: startIndex:endIndex
            "
            class="item"
            data-test-id="task-list-item"
          >
            <app-task-view
              [preventTaskSelection]="true"
              (click)="openTask(task)"
              [showParent]="true"
              [taskData]="task"
              (taskAssignmentChanged)="taskAssignmentChanged(task, $event)"
              (taskUpdate)="taskUpdate(task, $event)"
            ></app-task-view>
          </div>
        </div>
        <mat-paginator
          #paginator
          class="bg-transparent dkblue"
          color="primary"
          [length]="filteredCount.count"
          [pageSize]="pageSize"
          (page)="pageChange($event)"
        >
        </mat-paginator>
      </main>
    </section>
  </div>
</div>
