import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ResourceType, TaskAccessoryType, TaskReviewStatus, TaskStatus, UserType } from 'src/app/enums';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
} from 'src/app/services';
import { Task, TaskAccessoryData, User } from 'src/app/types';
import { BidPackage, ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-submittal-buttons',
  templateUrl: './submittal-buttons.component.html',
  styleUrls: ['./submittal-buttons.component.scss'],
})
export class SubmittalButtonsComponent implements OnChanges, OnDestroy {
  @Input() public accessoryData: TaskAccessoryData;
  @Input() public task: Task;

  @ViewChild('transmittal', { static: false }) transmittal;

  constructor(
    private authService: AuthService,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private updateReviewerService: UpdateReviewerService,
    private dateService: DateService
  ) {}

  private bidPackageFields = [
    'id',
    'trade_name',
    'number',
    'awarded_company_name',
    'awarded_contact_id',
    'submittals{spec{category,items{type_id}},selected_item_ids,accessory_data}',
    'transmittal_revision',
    'transmittal_is_created',
  ];
  private projectFields = [
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'building_code',
    'code',
    'end_date',
    'engineer_ids',
    'floor_code',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'square_footage',
    'title',
  ];

  public bidPackage: BidPackage;
  public currentUser: User;
  public project: ProjectConstruction;
  public projectEngineers: number[];
  public reviewIsComplete: boolean;
  public reviewStatus: any;
  public taskSelectedRefresh: any;

  async ngOnChanges() {
    this.currentUser = this.authService.currentUser;
    this.reviewStatus = this.accessoryData?.reviewChain?.find(
      (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
    );
    this.reviewIsComplete = !this.reviewStatus || this.reviewStatus.status === TaskReviewStatus.Rejected;
    this.project = await this.projectService.getProjectById(this.task?.project_id, this.projectFields).toPromise();
    this.projectEngineers = (this.project.engineer_ids && JSON.parse(this.project.engineer_ids)) || null;

    if (this.accessoryData?.parentId && this.accessoryData.isReviewItem) {
      this.bidPackage = await this.projectService
        .getBidPackageById(this.accessoryData?.parentId, this.bidPackageFields)
        .toPromise();
    }

    // Listens for changes to the review process so the ui can stay up to date.
    this.taskSelectedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      if (data?.task?.id === this.task?.id) {
        this.task.accessory_data = data.task.accessory_data;
        this.accessoryData = this.task.accessory_data && JSON.parse(this.task.accessory_data);
      }
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.taskSelectedRefresh.unsubscribe();
    } catch (e) {}
  }

  get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  get UserType() {
    return UserType;
  }

  get currentUserIsArch() {
    return this.currentUser?.id === this.project?.architect_id;
  }

  get currentUserIsPm() {
    return this.currentUser?.id === this.project?.project_manager_id;
  }

  get engineerInReview() {
    return !!this.accessoryData?.reviewChain?.find((reviewer) =>
      this.projectEngineers?.find((engineerId) => engineerId === reviewer.id)
    );
  }

  get isStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(
      this.task?.module_id || this.projectService?.currentSelectedProject?.module_id
    );
  }

  get displayAddEngineer() {
    return (
      this.accessoryData?.isReviewItem &&
      this.currentUserIsArch &&
      !this.reviewIsComplete &&
      this.currentUser.id === this.reviewStatus.id &&
      this.projectEngineers?.length &&
      !this.engineerInReview
    );
  }

  public async export() {
    const file = await this.transmittal.downloadTransmittal();
  }

  public async createTransmittal() {
    if (this.bidPackage?.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Creating Transmittal File...');
      const transmittalFile = await this.transmittal.exportTransmittal();
      this.progressIndicatorService.updateStatus('Attaching File to Task...');
      const noteContent =
        this.reviewStatus?.status === TaskReviewStatus.Rejected
          ? 'Please review the attached submittal response and update your submittals accordingly.'
          : 'Please review the attached submittal response and, if no changes need to be made, please mark this task complete.';
      const newNote = await this.projectService.createNote(ResourceType.Task, this.task?.id, noteContent).toPromise();
      await this.fileService.addFilesToNote(newNote, this.task?.id, [transmittalFile], []);

      this.progressIndicatorService.updateStatus('Updating Information...');
      await this.projectService
        .updateBidPackage(this.bidPackage.id, { transmittal_is_created: 1 }, this.bidPackageFields)
        .toPromise();

      const taskData = {
        id: this.task.id,
        assigned_user_id: this.bidPackage.awarded_contact_id,
        due_date: this.dateService.addWeekdays(7).format('YYYY-MM-DD'),
      };
      const updatedTask = await this.projectService.updateTask(taskData).toPromise();
      await this.taskService.updateTask(updatedTask);
      this.taskService.selectTaskById(updatedTask.id, false).subscribe();
      await this.taskService.loadTaskActivityLog(updatedTask);
      this.progressIndicatorService.close();
    }
  }

  public async openSelectEngineerDialog() {
    this.modalService
      .openSelectUserFromListDialog(this.projectEngineers)
      .toPromise()
      .then(async (engineer) => {
        if (engineer?.length) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Adding Engineer...');
          await this.updateReviewerService.addEngineerToReview(this.task, engineer[0].id);
          this.progressIndicatorService.close();
        }
      });
  }

  public async openUploadSubmittalsDialog() {
    const submittalFiles = await this.modalService
      .openFileAttachmentDialog({
        parentResourceType: ResourceType.Project,
        parentResourceId: this.task?.project_id,
        allowComment: false,
        preSelectedTags: [],
        filteredFiles: this.accessoryData.reviewFiles || [],
      })
      .toPromise();

    if (submittalFiles?.length) {
      this.progressIndicatorService.openAwaitIndicatorModal();

      // If isReviewItem then this has been done before and things only need to be updated and not created.
      if (this.accessoryData.isReviewItem && this.accessoryData.reviewChain) {
        this.progressIndicatorService.updateStatus('Updating Reviewers...');
        this.accessoryData.reviewChain.map((reviewer) => (reviewer.status = TaskReviewStatus.Pending));

        const transmittalData = {
          transmittal_revision: this.bidPackage.transmittal_revision + 1,
          transmittal_is_created: 0,
        };
        await this.projectService.updateTransmittalStatus(this.bidPackage.id, transmittalData).toPromise();
      } else {
        this.progressIndicatorService.updateStatus('Adding Reviewers...');
        const includes = {
          pm: true,
          arch: true,
        };
        const reviewers = this.displayReviewersService.getInternalReviewers(includes, this.project);
        this.accessoryData.isReviewItem = true;
        this.accessoryData.reviewChain = reviewers.reviewIds;
        this.accessoryData.reviewCreator = reviewers.reviewIds[0].id || this.project.project_manager;
      }

      this.accessoryData.reviewFiles = submittalFiles;
      const taskData = {
        id: this.task.id,
        accessory_data: JSON.stringify(this.accessoryData),
        assigned_user_id: this.accessoryData.reviewChain[0].id || this.project.project_manager_id,
        due_date: this.dateService.addWeekdays(2).format('YYYY-MM-DD'),
      };

      await this.projectService
        .createNote(
          ResourceType.Task,
          taskData.id,
          `Uploaded Submittal File${this.accessoryData?.reviewFiles?.length === 1 ? '' : 's'}:<br />`
        )
        .toPromise()
        .then(async (result) => {
          for (const file of this.accessoryData?.reviewFiles) {
            await this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.Note).toPromise();
          }
        });

      const updatedTask = await this.projectService.updateTask(taskData).toPromise();

      await this.taskService.updateTask(updatedTask);
      this.taskService.selectTaskById(updatedTask.id, false).subscribe();

      this.progressIndicatorService.close();
    }
  }
}
