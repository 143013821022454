import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { RequestDashboardFilter } from 'src/app/workspaces/construction/models';
import { Request } from 'src/app/types';

@Pipe({
  name: 'requestDashboardFilter',
})
export class RequestDashboardFilterPipe implements PipeTransform {
  // determine whether the request is new
  private static isRecent(date, daysForRecent: number): boolean {
    return moment(date)
      .startOf('day')
      .isBetween(moment().startOf('day').subtract(daysForRecent, 'day'), moment().startOf('day'), null, '[]');
  }

  transform(requests: Request[], filter: RequestDashboardFilter): any {
    // filter by recent or upcoming
    switch (filter.status) {
      case 'pending':
        requests = requests.filter((request) => request.status_id === 1);
        break;
      case 'accepted':
        requests = requests.filter((request) => request.status_id === 2 || request.status_id === 3);
        break;
      case 'converted':
        requests = requests.filter((request) => request.status_id === 2 || request.status_id === 3);
        break;
      case 'recent':
        requests = requests.filter(
          (request: Request) =>
            RequestDashboardFilterPipe.isRecent(request.created_datetime, filter.daysForRecent) &&
            request.status_id === 1
        );
        break;
      default:
        break;
    }

    // sort by created date
    requests.sort((a, b) => moment(a.created_datetime).startOf('day').diff(moment(b.created_datetime).startOf('day')));

    return requests;
  }
}
