<div #mainScreen data-test-id="workspace-dashboard" id="workspace-dashboard" class="workspace-dashboard">
  <mat-drawer-container class="workspace-dashboard-container bg-transparent">
    <header
      [ngClass]="{
        'nav-closed':
          isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'nav-open':
          !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'notifications-sidenav-open':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,
        'notifications-sidenav-closed':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
      }"
      class="d-flex px-4 ease align-items-center"
    >
      <h1 *ngIf="workspace" class="dkblue font-weight-semibold mb-0 mr-auto text-nowrap mr-4">
        Dashboard
        <small class="ml-2 dkgray">
          {{ workspace.name }}
        </small>
      </h1>
      <mat-spinner *ngIf="isLoadingLatestUpdates" color="accent" [diameter]="18"></mat-spinner>
      <button (click)="openDrawer()" mat-button color="primary" type="button">See Latest Updates</button>
      <button
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="staff_menu"
        type="button"
        class="ml-3"
        [matBadge]="filteredUser?.id ? 1 : ''"
        matBadgeColor="warn"
        matBadgePosition="before"
      >
        <span> Staff </span>
        <i class="fa fa-caret-down ml-1"> </i>
      </button>
      <mat-menu #staff_menu="matMenu" xPosition="before">
        <button
          mat-stroked-button
          class="mx-4 px-5 my-3"
          (click)="filterAllByUserId(null)"
          *ngIf="filteredUser?.id"
          color="primary"
          type="button"
        >
          Remove Filter
        </button>
        <button
          *ngFor="let u of allStaffAssignees"
          (click)="filterAllByUserId(u.id)"
          [ngClass]="{
            opacity5: filteredUser?.id && filteredUser?.id !== u.id
          }"
          mat-menu-item
          class="d-flex align-items-center"
        >
          <div matRipple class="mr-2">
            <app-profile-thumbnail
              [height]="34"
              [width]="34"
              [userId]="u.id"
              matTooltip="{{ u.first_name }} {{ u.last_name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            ></app-profile-thumbnail>
          </div>
          <p
            class="dkblue font-weight-normal m-0"
            [ngClass]="{
              'font-weight-semibold': filteredUser?.id === u.id
            }"
          >
            {{ u.first_name }} {{ u.last_name }}
          </p>
        </button>
      </mat-menu>
      <button mat-stroked-button color="primary" type="button" routerLink="/settings" class="ml-3">Settings</button>
    </header>
    <main
      [ngClass]="{
        'nav-closed':
          isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'nav-open':
          !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'notifications-sidenav-open':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,
        'notifications-sidenav-closed':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
      }"
      class="d-flex ease"
    >
      <div class="p-4 main-container d-flex ease">
        <section class="full-width">
          <div class="row">
            <div class="col">
              <section>
                <h4 class="mb-3 dkblue font-weight-semibold">Summary</h4>
                <section class="row">
                  <div class="col-4" [ngClass]="{ 'col-12': divWidth < 900 }">
                    <div class="m-portlet main-card" (click)="goToWorkOrders()">
                      <div class="m-portlet__body pointer item item-last d-flex align-items-center p-3">
                        <mat-icon class="ltblue mr-3">description</mat-icon>
                        <div class="d-flex flex-grow-1 flex-column">
                          <h5 class="m-0 dkblue font-weight-semibold text-truncate">Active Work Orders</h5>
                          <span class="d-flex">
                            <h1 class="m-0 dkblue font-weight-normal">
                              {{ globalCounts.activeWorkOrders || 0 }}
                            </h1>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" [ngClass]="{ 'col-12': divWidth < 900 }">
                    <div class="m-portlet main-card" (click)="goToProjects()">
                      <div class="m-portlet__body pointer item item-last d-flex align-items-center p-3">
                        <mat-icon class="ltblue mr-3">assignment_turned_in</mat-icon>
                        <div class="d-flex flex-grow-1 flex-column">
                          <span class="d-flex align-items-center">
                            <h5 class="m-0 dkblue font-weight-semibold text-truncate">Active Projects</h5>
                          </span>
                          <span class="d-flex">
                            <h1 class="m-0 dkblue font-weight-normal">
                              {{ globalCounts.activeProjects || 0 }}
                            </h1>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" [ngClass]="{ 'col-12': divWidth < 900 }">
                    <div class="m-portlet main-card" (click)="goToRequests()">
                      <div class="m-portlet__body pointer item item-last d-flex align-items-center p-3">
                        <mat-icon class="orange mr-3">assignment_late</mat-icon>
                        <div class="d-flex flex-grow-1 flex-column">
                          <span class="d-flex align-items-center">
                            <h5 class="m-0 dkblue font-weight-semibold text-truncate">Pending Requests</h5>
                          </span>
                          <span class="d-flex">
                            <h1 class="m-0 dkblue font-weight-normal">
                              {{ globalCounts.pendingRequests || 0 }}
                            </h1>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="type_toggle" class="d-flex justify-content-center mb-5">
                  <span class="toggle-container position-relative">
                    <mat-button-toggle-group [(ngModel)]="selectedTab" (ngModelChange)="tabChanged()">
                      <mat-button-toggle value="workOrders" [ngClass]="{ selectedItem: selectedTab === 'workOrders' }"
                        >Work Orders</mat-button-toggle
                      >
                      <mat-button-toggle value="projects" [ngClass]="{ selectedItem: selectedTab === 'projects' }"
                        >Projects</mat-button-toggle
                      >
                      <mat-button-toggle value="tasks" [ngClass]="{ selectedItem: selectedTab === 'tasks' }"
                        >Project Tasks</mat-button-toggle
                      >
                    </mat-button-toggle-group>
                  </span>
                </section>
              </section>
              <section class="d-flex flex-column pt-4" *ngIf="selectedTab === 'workOrders'">
                <div class="d-flex mb-3 align-items-center">
                  <h4 class="m-0 dkblue font-weight-semibold mr-auto">Work Orders</h4>
                  <mat-form-field appearance="outline" class="m-0 dkblue ml-3" style="width: 200px">
                    <mat-label *ngIf="filteredUser && !filteredUser.isWorkOrderAssignee">
                      <span>{{ filteredUser?.first_name }} {{ filteredUser?.last_name }}</span>
                    </mat-label>
                    <mat-select
                      multiple
                      [(ngModel)]="selectedWorkOrderAssigneeIds"
                      (ngModelChange)="filterWorkOrders()"
                    >
                      <mat-select-trigger *ngIf="allWorkOrderAssigneesSelected">
                        <span>All Assignees</span>
                      </mat-select-trigger>
                      <div class="mat-option">
                        <mat-checkbox
                          [(ngModel)]="allWorkOrderAssigneesSelected"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllWorkOrderAssignees()"
                          color="primary"
                          >All</mat-checkbox
                        >
                      </div>
                      <mat-option [value]="0"> Not Assigned </mat-option>
                      <mat-option *ngFor="let a of workOrderAssignees" [value]="a.id">
                        {{ a.first_name }} {{ a.last_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="m-portlet mb-0">
                  <div class="m-portlet__body section-stats item-no-hover p-3">
                    <div class="row align-items-stretch">
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: workOrderCardClicked === 'active' }"
                          (click)="filterWorkOrders('active')"
                        >
                          <mat-icon class="green mr-3">label_important</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Active</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.activeWorkOrders }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: workOrderCardClicked === 'overdue' }"
                          (click)="filterWorkOrders('overdue')"
                        >
                          <mat-icon class="red mr-3">event_busy</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Overdue</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.overdueWorkOrders }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: workOrderCardClicked === 'on hold' }"
                          (click)="filterWorkOrders('on hold')"
                        >
                          <mat-icon class="red mr-3">flag</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">On Hold</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.onHoldWorkOrders }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: workOrderCardClicked === 'unassigned' }"
                          (click)="filterWorkOrders('unassigned')"
                        >
                          <mat-icon class="orange mr-3">person_outline</mat-icon>
                          <div class="d-flex flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Not Assigned</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.unassignedWorkOrders }}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-portlet__body bg-shade-ltblue table-header p-0 pl-3 pr-3">
                    <div class="row align-items-center">
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.CODE)"
                        class="pointer col-auto pt-2 pb-2 pr-3 d-flex align-items-center header-item"
                        style="width: 100px"
                        [ngClass]="{ sortedBy: fieldToSortWorkOrderBy === orderByField.CODE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Code</h6>
                        <i *ngIf="fieldToSortWorkOrderBy === orderByField.CODE" matRipple class="material-icons">
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.BUILDING_CODE)"
                        class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                        style="max-width: 110px"
                        [ngClass]="{ sortedBy: fieldToSortWorkOrderBy === orderByField.BUILDING_CODE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                        <i
                          *ngIf="fieldToSortWorkOrderBy === orderByField.BUILDING_CODE"
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.TITLE)"
                        class="pointer col-3 pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortWorkOrderBy === orderByField.TITLE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Short Description / Topic</h6>
                        <i *ngIf="fieldToSortWorkOrderBy === orderByField.TITLE" matRipple class="material-icons">
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.PRIORITY_ID)"
                        class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex align-items-center header-item"
                        style="max-width: 110px"
                        [ngClass]="{ sortedBy: fieldToSortWorkOrderBy === orderByField.PRIORITY_ID }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Priority</h6>
                        <i *ngIf="fieldToSortWorkOrderBy === orderByField.PRIORITY_ID" matRipple class="material-icons">
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.DUE_DATE)"
                        class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                        [ngClass]="{
                          sortedBy:
                            fieldToSortfieldToSortWorkOrderBy === 'due_date' ||
                            fieldToSortWorkOrderBy === orderByField.DUE_DATE
                        }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Due Date</h6>
                        <i
                          *ngIf="
                            fieldToSortWorkOrderBy === 'due_date' || fieldToSortWorkOrderBy === orderByField.DUE_DATE
                          "
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.ASSIGNED_USER_FIRST_NAME)"
                        class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortWorkOrderBy === orderByField.ASSIGNED_USER_FIRST_NAME }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Assigned To</h6>
                        <i
                          *ngIf="fieldToSortWorkOrderBy === orderByField.ASSIGNED_USER_FIRST_NAME"
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateWorkOrderSortByField(orderByField.UPDATE_DOT_CREATED_DATETIME)"
                        class="pointer col pt-2 pb-2 pl-3 pr-3 d-flex header-item align-items-center"
                        [ngClass]="{
                          sortedBy:
                            fieldToSortfieldToSortWorkOrderBy === 'update.created_datetime' ||
                            fieldToSortWorkOrderBy === orderByField.UPDATE_DOT_CREATED_DATETIME
                        }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Last Update</h6>
                        <i
                          *ngIf="
                            fieldToSortWorkOrderBy === 'update.created_datetime' ||
                            fieldToSortWorkOrderBy === orderByField.UPDATE_DOT_CREATED_DATETIME
                          "
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_work_order_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="m-portlet__body pointer item py-0 px-3"
                    *ngIf="!loading && filteredWorkOrders.length === 0"
                  >
                    <div class="row align-items-center">
                      <h5 class="m-0 p-3 dkgray font-weight-normal">No Work Orders Found</h5>
                    </div>
                  </div>
                  <div
                    class="m-portlet__body pointer item py-0 px-3"
                    [routerLink]="['/work-orders/' + wo.id]"
                    *ngFor="
                      let wo of filteredWorkOrders
                        | orderByField: customizedWorkOrderFieldToSortBy:dashboard_work_order_sort_direction
                        | slice: pageIndexes.workOrderStart:pageIndexes.workOrderEnd;
                      let i = index
                    "
                  >
                    <div class="row align-items-center">
                      <div class="col-auto p-3 d-flex data-item" style="width: 100px">
                        <p class="m-0 font-weight-semibold dkblue">
                          {{ wo.code }}
                        </p>
                        <i *ngIf="wo.status_id === statusIds.ACTIVE" class="material-icons green ml-auto"
                          >label_important</i
                        >
                        <i *ngIf="wo.status_id === statusIds.ON_HOLD" class="material-icons red ml-auto">flag</i>
                        <i *ngIf="wo.status_id === statusIds.CLOSED" class="material-icons gray ml-auto">done_all</i>
                        <i *ngIf="wo.status_id === statusIds.PLANNED" class="material-icons ltblue ml-auto"
                          >next_plan</i
                        >
                      </div>
                      <div class="col p-3 d-flex data-item" style="max-width: 100px">
                        <p class="m-0 font-weight-semibold dkblue" *ngIf="wo.building?.code">
                          {{ wo.building?.code }}-{{ wo.floor?.code }}
                        </p>
                      </div>
                      <div class="col-3 p-3 d-flex flex-column data-item">
                        <a
                          [routerLink]="['/work-orders/' + wo.id]"
                          (click)="stopPropagation($event)"
                          class="text-decoration-none hover-u"
                        >
                          <p class="m-0 font-weight-semibold dkblue">
                            {{ wo.title }}
                          </p>
                          <p class="m-0 font-weight-normal dkgray" *ngIf="wo.topic">
                            {{ wo.topic.topic_category?.topic_group?.name }} > {{ wo.topic.topic_category?.name }} >
                            {{ wo.topic.name }}
                          </p>
                        </a>
                      </div>
                      <div class="col p-3 d-flex" style="max-width: 110px">
                        <h6
                          class="m-0 white lh-20 br-5 px-2 font-weight-semibold d-block text-truncate mr-auto"
                          [ngClass]="{
                            'bg-dkred': wo.priority?.id === 4,
                            'bg-red': wo.priority?.id === 3,
                            'bg-ltblue': wo.priority?.id === 2,
                            'bg-gray': wo.priority?.id === 1
                          }"
                        >
                          {{ wo.priority?.name }}
                        </h6>
                      </div>
                      <div class="col p-3 d-flex align-items-center data-item">
                        <i *ngIf="isOverdue(wo.due_date)" class="material-icons red mr-2">error</i>
                        <p
                          class="m-0 dkblue font-weight-semibold d-block"
                          [ngClass]="{
                            gray: !wo.due_date,
                            red: isOverdue(wo.due_date),
                            dkblue: !isOverdue(wo.due_date)
                          }"
                        >
                          {{ wo.due_date ? (wo.due_date | date: 'EEE, MMM d yyyy') : 'Not Set' }}
                        </p>
                      </div>
                      <div class="col p-3 d-flex align-items-center data-item">
                        <app-profile-thumbnail
                          *ngIf="wo.assigned_user?.id"
                          class="d-inline-block align-middle mr-2"
                          [height]="26"
                          [width]="26"
                          [userId]="wo.assigned_user.id"
                        ></app-profile-thumbnail>
                        <i *ngIf="!wo.assigned_user?.id" class="material-icons red mr-2 ml-1">error</i>
                        <p
                          class="m-0 d-inline-block align-middle font-weight-normal text-wrap text-truncate"
                          [ngClass]="{ 'red font-weight-semibold': !wo.assigned_user }"
                        >
                          {{
                            wo.assigned_user
                              ? wo.assigned_user?.first_name + ' ' + wo.assigned_user?.last_name
                              : 'Not Assigned'
                          }}
                        </p>
                      </div>
                      <div class="col p-3 d-flex flex-column data-item">
                        <p class="m-0 font-weight-semibold gray" *ngIf="!wo.updates || !wo.updates[0]">No Updates</p>
                        <p
                          (click)="$event.stopPropagation()"
                          [matMenuTriggerFor]="workOrderUpdateMenu"
                          class="m-0 font-weight-semibold ltblue hover-u d-block"
                          *ngIf="wo.updates && wo.updates[0]"
                        >
                          {{ wo.updates[0].created_datetime | date: 'EEE, MMM d yyyy' }}
                        </p>
                        <h6
                          class="m-0 font-weight-normal font-italic dkgray"
                          style="font-size: 80%"
                          *ngIf="wo.updates && wo.updates[0] && wo.updates[0].created_by"
                        >
                          By {{ wo.updates[0].created_by.first_name }}
                          {{ wo.updates[0].created_by.last_name }}
                        </h6>
                        <mat-menu #workOrderUpdateMenu="matMenu" xPosition="before">
                          <div class="d-flex py-3 px-4" *ngIf="wo.updates[0]">
                            <app-profile-thumbnail
                              class="mr-3"
                              [noBorder]="true"
                              [height]="30"
                              [width]="30"
                              [userId]="wo.updates[0].created_by?.id"
                            ></app-profile-thumbnail>
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-start mb-3">
                                <div class="d-flex flex-column mr-4">
                                  <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                                    {{ wo.updates[0].created_by?.first_name }}
                                    {{ wo.updates[0].created_by?.last_name }}
                                  </h6>
                                  <h6 class="mb-3 dkgray font-weight-normal text-nowrap">
                                    {{ wo.updates[0].created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                                  </h6>
                                </div>
                                <p
                                  *ngIf="wo.updates[0].work_order_health_type?.id"
                                  class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold"
                                  [ngClass]="{
                                    'bg-green': wo.updates[0].work_order_health_type?.id === 1,
                                    'bg-orange': wo.updates[0].work_order_health_type?.id === 2,
                                    'bg-red': wo.updates[0].work_order_health_type?.id === 3
                                  }"
                                  style="font-size: 11px; line-height: 20px"
                                >
                                  {{ wo.updates[0].work_order_health_type?.name }}
                                </p>
                              </div>

                              <a
                                class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                                [routerLink]="['/work-orders/' + wo.id]"
                              >
                                {{ wo.code }}{{ wo.title ? ' - ' : '' }} {{ wo.title }}
                              </a>
                              <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="wo.updates[0].message"></p>
                            </div>
                          </div>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-paginator
                  class="bg-transparent dkblue"
                  color="primary"
                  #workOrderPaginator
                  [length]="filteredWorkOrders.length"
                  [pageSize]="pageSize"
                  (page)="pageChange($event, 'workOrder')"
                >
                </mat-paginator>
              </section>
              <section class="d-flex flex-column mb-3 pt-4" *ngIf="selectedTab === 'tasks'">
                <div class="d-flex mb-3 align-items-center">
                  <h4 class="m-0 dkblue font-weight-semibold mr-auto">Tasks</h4>
                  <mat-form-field appearance="outline" class="m-0 dkblue ml-3" style="width: 200px">
                    <mat-label *ngIf="filteredUser && !filteredUser.isTaskAssignee">
                      <span>{{ filteredUser?.first_name }} {{ filteredUser?.last_name }}</span>
                    </mat-label>
                    <mat-select multiple [(ngModel)]="selectedTaskAssigneeIds" (ngModelChange)="filterTasks()">
                      <mat-select-trigger *ngIf="allTaskAssigneesSelected">
                        <span>All Assignees</span>
                      </mat-select-trigger>
                      <div class="mat-option">
                        <mat-checkbox
                          [(ngModel)]="allTaskAssigneesSelected"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllTaskAssignees()"
                          color="primary"
                          >All</mat-checkbox
                        >
                      </div>
                      <mat-option [value]="0"> Not Assigned </mat-option>
                      <mat-option *ngFor="let a of taskAssignees" [value]="a.id">
                        {{ a.first_name }} {{ a.last_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="m-0 dkblue ml-3" style="width: 200px">
                    <mat-select [(ngModel)]="taskSortOption" (ngModelChange)="sortTasks()">
                      <mat-option *ngFor="let o of taskSortOptions" [value]="o"> Sort: {{ o.label }} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="m-portlet mb-0">
                  <div class="m-portlet__body section-stats item-no-hover p-3">
                    <div class="row align-items-stretch">
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: taskCardClicked === 'active' }"
                          (click)="filterTasks('active')"
                        >
                          <mat-icon class="green mr-3">check_circle_outline</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Active</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.activeTasks }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: taskCardClicked === 'overdue' }"
                          (click)="filterTasks('overdue')"
                        >
                          <mat-icon class="red mr-3">event_busy</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Overdue</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.overdueTasks }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: taskCardClicked === 'on hold' }"
                          (click)="filterTasks('on hold')"
                        >
                          <mat-icon class="red mr-3">flag</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">On Hold</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.onHoldTasks }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: taskCardClicked === 'unassigned' }"
                          (click)="filterTasks('unassigned')"
                        >
                          <mat-icon class="orange mr-3">person_outline</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Not Assigned</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.unassignedTasks }}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-portlet__body pointer item py-0 px-3" *ngIf="!loading && filteredTasks.length === 0">
                    <div class="row align-items-center">
                      <h5 class="m-0 p-3 dkgray font-weight-normal">No Tasks Found</h5>
                    </div>
                  </div>
                  <div
                    class="m-portlet__body pointer item p-0"
                    *ngFor="let t of filteredTasks | slice: pageIndexes.taskStart:pageIndexes.taskEnd"
                  >
                    <app-task-view
                      [taskData]="t"
                      [preventTaskSelection]="true"
                      [showUndoTaskStatus]="true"
                      (taskUpdate)="taskUpdate($event, t)"
                      (click)="viewTask(t.id)"
                      [showParent]="true"
                    ></app-task-view>
                  </div>
                </div>
                <mat-paginator
                  class="bg-transparent dkblue"
                  color="primary"
                  #taskPaginator
                  [length]="filteredTasks.length"
                  [pageSize]="pageSize"
                  (page)="pageChange($event, 'task')"
                >
                </mat-paginator>
              </section>
              <section class="d-flex flex-column pt-4" *ngIf="selectedTab === 'projects'">
                <div class="d-flex mb-3 align-items-center">
                  <h4 class="m-0 dkblue font-weight-semibold mr-auto">Projects</h4>
                  <mat-form-field appearance="outline" class="m-0 dkblue ml-3" style="width: 200px">
                    <!-- <mat-label *ngIf="filteredUser && !filteredUser.isProjectPM">
                      <span>{{ filteredUser?.first_name }} {{ filteredUser?.last_name }}</span>
                    </mat-label> -->
                    <mat-select multiple [(ngModel)]="selectedProjectPMIds" (ngModelChange)="filterProjects()">
                      <mat-select-trigger *ngIf="allProjectPMsSelected">
                        <span>All PMs</span>
                      </mat-select-trigger>
                      <div class="mat-option">
                        <mat-checkbox
                          [(ngModel)]="allProjectPMsSelected"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="toggleAllProjectPMs()"
                          color="primary"
                          >All</mat-checkbox
                        >
                      </div>
                      <mat-option [value]="0"> Not Assigned </mat-option>
                      <mat-option *ngFor="let pm of projectPMs" [value]="pm.id">
                        {{ pm.first_name }} {{ pm.last_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="m-portlet mb-0">
                  <div class="m-portlet__body section-stats item-no-hover p-3">
                    <div class="row align-items-stretch">
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: projectCardClicked === 'active' }"
                          (click)="filterProjects('active')"
                        >
                          <mat-icon class="green mr-3">label_important</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Active</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.activeProjects }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: projectCardClicked === 'behind schedule' }"
                          (click)="filterProjects('behind schedule')"
                        >
                          <mat-icon class="red mr-3">event_busy</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">Behind Schedule</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.behindScheduleProjects }}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="d-flex align-items-center p-2 br-5 item item-last pointer"
                          [ngClass]="{ selectedItem: projectCardClicked === 'on hold' }"
                          (click)="filterProjects('on hold')"
                        >
                          <mat-icon class="red mr-3">flag</mat-icon>
                          <div class="d-flex flex-grow-1 flex-column">
                            <h6 class="m-0 dkblue font-weight-semibold text-truncate">On Hold</h6>
                            <h1 class="m-0 dkblue font-weight-normal" *ngIf="!loading">
                              {{ filteredCounts.onHoldProjects }}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-portlet__body bg-shade-ltblue table-header py-0 px-3">
                    <div class="row align-items-center">
                      <div
                        (click)="updateProjectSortByField(orderByField.CODE)"
                        class="pointer col-auto py-2 pr-3 d-flex align-items-center header-item"
                        style="width: 100px"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.CODE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Code</h6>
                        <i *ngIf="fieldToSortProjectBy === orderByField.CODE" matRipple class="material-icons">
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.BUILDING_DOT_CODE)"
                        class="pointer col py-2 px-3 d-flex align-items-center header-item"
                        style="max-width: 110px"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.BUILDING_DOT_CODE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                        <i
                          *ngIf="fieldToSortProjectBy === orderByField.BUILDING_DOT_CODE"
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.TITLE)"
                        class="pointer col-3 py-2 px-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.TITLE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Short Description / Topic</h6>
                        <i *ngIf="fieldToSortProjectBy === orderByField.TITLE" matRipple class="material-icons">
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.PRIORITY_ID)"
                        class="pointer col py-2 px-3 d-flex align-items-center header-item"
                        style="max-width: 110px"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.PRIORITY_ID }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Priority</h6>
                        <i *ngIf="fieldToSortProjectBy === orderByField.PRIORITY_ID" matRipple class="material-icons">
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.END_DATE)"
                        class="pointer col py-2 px-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.END_DATE }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Est. Completion Date</h6>
                        <i *ngIf="fieldToSortProjectBy === orderByField.END_DATE" matRipple class="material-icons">
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.PROJECT_MANAGER_DOT_FIRST_NAME)"
                        class="pointer col py-2 px-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.PROJECT_MANAGER_DOT_FIRST_NAME }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Project Manager</h6>
                        <i
                          *ngIf="fieldToSortProjectBy === orderByField.PROJECT_MANAGER_DOT_FIRST_NAME"
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                      <div
                        (click)="updateProjectSortByField(orderByField.UPDATE_DOT_CREATED_DATETIME)"
                        class="pointer col py-2 px-3 d-flex header-item align-items-center"
                        [ngClass]="{ sortedBy: fieldToSortProjectBy === orderByField.UPDATE_DOT_CREATED_DATETIME }"
                      >
                        <h6 class="m-0 text-truncate header-title mr-auto">Last Updated</h6>
                        <i
                          *ngIf="fieldToSortProjectBy === orderByField.UPDATE_DOT_CREATED_DATETIME"
                          matRipple
                          class="material-icons"
                        >
                          {{ dashboard_project_sort_direction === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                        </i>
                      </div>
                    </div>
                  </div>
                  <div class="m-portlet__body pointer item py-0 px-3" *ngIf="!loading && filteredProjects.length === 0">
                    <div class="row align-items-center">
                      <h5 class="m-0 p-3 dkgray font-weight-normal">No Projects Found</h5>
                    </div>
                  </div>
                  <div
                    class="m-portlet__body pointer item py-0 px-3"
                    *ngFor="
                      let p of filteredProjects
                        | orderByField: customizedProjectFieldToSortBy:dashboard_project_sort_direction
                        | slice: pageIndexes.projectStart:pageIndexes.projectEnd;
                      let j = index
                    "
                    [routerLink]="['/projects/' + p.id]"
                  >
                    <div class="row align-items-center">
                      <div class="col-auto p-3 d-flex data-item" style="width: 110px">
                        <p class="m-0 font-weight-semibold dkblue">PJ-{{ p.code }}</p>
                        <i *ngIf="p.status_id === 1" class="material-icons green ml-auto">label_important</i>
                        <i *ngIf="p.status_id === 3" class="material-icons red ml-auto">flag</i>
                        <i *ngIf="p.status_id === 2" class="material-icons ltblue ml-auto">next_plan</i>
                        <i *ngIf="p.status_id === 4" class="material-icons gray ml-auto">done_all</i>
                      </div>
                      <div class="col p-3 d-flex data-item" style="max-width: 110px">
                        <p class="m-0 font-weight-semibold dkblue" *ngIf="p.building?.code">
                          {{ p.building?.code }}-{{ p.floor?.code }}
                        </p>
                      </div>
                      <div class="col-3 p-3 d-flex flex-column data-item">
                        <a
                          [routerLink]="['/projects/' + p.id]"
                          (click)="stopPropagation($event)"
                          class="text-decoration-none hover-u"
                        >
                          <p class="m-0 font-weight-semibold dkblue">
                            {{ p.title || 'Project ' + p.code }}
                          </p>
                          <p class="m-0 font-weight-normal dkgray" *ngIf="p.request?.topic">
                            {{ p.request?.topic?.topic_category?.topic_group?.name }} >
                            {{ p.request?.topic?.topic_category?.name }} >
                            {{ p.request?.topic?.name }}
                          </p>
                        </a>
                      </div>
                      <div class="col p-3 d-flex" style="max-width: 110px">
                        <h6
                          class="m-0 white lh-20 br-5 px-2 font-weight-semibold d-block text-truncate mr-auto"
                          [ngClass]="{
                            'bg-dkred': p.priority?.id === 4,
                            'bg-red': p.priority?.id === 3,
                            'bg-ltblue': p.priority?.id === 2,
                            'bg-gray': p.priority?.id === 1
                          }"
                        >
                          {{ p.priority?.name }}
                        </h6>
                      </div>
                      <div class="col p-3 d-flex align-items-center data-item">
                        <i *ngIf="isOverdue(p.end_date)" class="material-icons red mr-2">error</i>
                        <p
                          class="m-0 dkblue font-weight-semibold d-block"
                          [ngClass]="{
                            gray: !p.end_date,
                            red: isOverdue(p.end_date),
                            dkblue: !isOverdue(p.end_date)
                          }"
                        >
                          {{ p.end_date ? (p.end_date | date: 'EEE, MMM d yyyy') : 'Not Set' }}
                        </p>
                      </div>
                      <div class="col p-3 d-flex align-items-center data-item">
                        <app-profile-thumbnail
                          *ngIf="p.project_manager?.id"
                          class="d-inline-block align-middle mr-2"
                          [height]="26"
                          [width]="26"
                          [userId]="p.project_manager.id"
                        ></app-profile-thumbnail>
                        <i *ngIf="!p.project_manager?.id" class="material-icons red mr-2 ml-1">error</i>
                        <p
                          class="m-0 d-inline-block align-middle font-weight-normal text-wrap text-truncate"
                          [ngClass]="{ 'red font-weight-semibold': !p.project_manager }"
                        >
                          {{
                            p.project_manager
                              ? p.project_manager?.first_name + ' ' + p.project_manager?.last_name
                              : 'Not Assigned'
                          }}
                        </p>
                      </div>
                      <div class="col p-3 d-flex flex-column data-item">
                        <p class="m-0 font-weight-semibold gray" *ngIf="!p.updates || !p.updates[0]">No Updates</p>
                        <p
                          class="m-0 font-weight-semibold ltblue hover-u"
                          *ngIf="p.updates && p.updates[0]"
                          (click)="$event.stopPropagation()"
                          [matMenuTriggerFor]="projectUpdateMenu"
                        >
                          {{ p.updates[0].created_datetime | date: 'EEE, MMM d yyyy' }}
                        </p>
                        <h6
                          class="m-0 font-weight-normal font-italic dkgray"
                          style="font-size: 80%"
                          *ngIf="p.updates && p.updates[0] && p.updates[0].created_by"
                        >
                          By {{ p.updates[0].created_by.first_name }}
                          {{ p.updates[0].created_by.last_name }}
                        </h6>
                        <mat-menu #projectUpdateMenu="matMenu" xPosition="before">
                          <div class="d-flex py-3 px-4" *ngIf="p.updates[0]">
                            <app-profile-thumbnail
                              class="mr-3"
                              [noBorder]="true"
                              [height]="30"
                              [width]="30"
                              [userId]="p.updates[0].created_by?.id"
                            ></app-profile-thumbnail>
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-start mb-3">
                                <div class="d-flex flex-column mr-4">
                                  <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                                    {{ p.updates[0].created_by?.first_name }}
                                    {{ p.updates[0].created_by?.last_name }}
                                  </h6>
                                  <h6 class="mb-3 dkgray font-weight-normal text-nowrap">
                                    {{ p.updates[0].created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                                  </h6>
                                </div>
                                <p
                                  *ngIf="p.updates[0].project_health_type?.id"
                                  class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold"
                                  [ngClass]="{
                                    'bg-green': p.updates[0].project_health_type?.id === 1,
                                    'bg-orange': p.updates[0].project_health_type?.id === 2,
                                    'bg-red': p.updates[0].project_health_type?.id === 3
                                  }"
                                  style="font-size: 11px; line-height: 20px"
                                >
                                  {{ p.updates[0].project_health_type?.name }}
                                </p>
                              </div>
                              <a
                                class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                                [routerLink]="['/projects/' + p.id]"
                              >
                                PRJ-{{ p.code }}{{ p.title ? ' - ' : '' }} {{ p.title }}
                              </a>
                              <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="p.updates[0].message"></p>
                            </div>
                          </div>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-paginator
                  class="bg-transparent dkblue"
                  color="primary"
                  #projectPaginator
                  [length]="filteredProjects.length"
                  [pageSize]="pageSize"
                  (page)="pageChange($event, 'project')"
                >
                </mat-paginator>
              </section>
            </div>
          </div>
        </section>
      </div>
    </main>
    <mat-drawer #drawer mode="over" position="end" autoFocus="false">
      <app-latest-update
        *ngIf="drawer.opened"
        [allUpdates]="projectAndworkOrderUpdates"
        [projectUpdates]="projectUpdates"
        [selectedLatestUpdates]="selectedLatestUpdates"
        [workOrderUpdates]="workOrderUpdates"
        [drawer]="drawer"
        [isLoadingLatestUpdates]="isLoadingLatestUpdates"
        (selectedUpdateTabChangeds)="updateSelectedUpdateTab($event)"
      ></app-latest-update>
    </mat-drawer>
  </mat-drawer-container>
</div>
