<app-base-dialog [title]="'Account Not Active'" (closeDialog)="cancel()"></app-base-dialog>
<div class="project-modal p-3">
  <div class="row mb-4 pb-3">
    <div class="col">
      <h3 class="m-0 font-weight-semibold">Warning: Account Not Active</h3>
    </div>
  </div>
  <div *ngIf="message1" class="row mb-4">
    <div class="col">
      <h6 class="dkgray m-0 font-weight-normal lh2">
        {{ message1 }}
      </h6>
    </div>
  </div>
  <div *ngIf="message2" class="row mb-4">
    <div class="col">
      <h6 class="dkgray m-0 font-weight-normal lh2">
        {{ message2 }}
      </h6>
    </div>
  </div>
  <div *ngIf="recentInvites" class="row mt-5 pt-2 mb-4">
    <div class="col">
      <p *ngFor="let invite of recentInvites" class="dkblue m-0 font-weight-semibold lh2">
        {{ invite }}
      </p>
    </div>
  </div>
  <div class="row pt-5 mb-2">
    <div class="col text-right">
      <button mat-button (click)="cancel()" class="gray" type="button">
        {{ cancelButtonText }}
      </button>
      <button
        [hidden]="!parentId || !parentTypeId"
        mat-flat-button
        color="accent"
        class="ml-3"
        (click)="submit(false)"
        type="button"
      >
        {{ dontInviteButtonText }}
      </button>
      <button mat-flat-button color="primary" class="ml-3" (click)="submit(sendInvite)" type="button">
        {{ inviteButtonText }}
      </button>
    </div>
  </div>
</div>
