import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  AgendaItemDialogComponent,
  ConfirmationDialogComponent,
  DatepickerHeaderComponent,
  MeetingSelectDialogComponent,
  UserSelectModalComponent,
} from 'src/app/components';
import { ResourceType, WorkOrderStatus } from 'src/app/enums';
import {
  AuthService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ModuleService,
  NotificationService,
  ProgressIndicatorService,
  SearchService,
  SidenavService,
  WorkOrderService,
} from 'src/app/services';
import { Module, Request, User, WorkOrder, WorkOrderPriority, Workspace } from 'src/app/types';
import { LinkedWOTask } from 'src/app/types/linked-wo-task';
const NOT_SET_PRIORITY: WorkOrderPriority = { id: null, name: 'Not Set', abbreviation: 'None' };

@Component({
  selector: 'app-work-order-header',
  templateUrl: './work-order-header.component.html',
  styleUrls: ['./work-order-header.component.scss'],
})
export class WorkOrderHeaderComponent implements OnInit {
  private _workOrder: WorkOrder;
  @Input() set workOrder(wo: WorkOrder) {
    this._workOrder = wo;

    // only do this if there is an id
    if (this._workOrder?.id) {
      this._linkedTaskService.getLinkedWOTaskForWorkOrder(this._workOrder.id).subscribe((task: LinkedWOTask) => {
        // either exists or not
        this.linkedWOTask = task;
      });
    }
  }
  get workOrder() {
    return this._workOrder;
  }

  @Input() isWorkOrderAdmin: boolean;
  @Input() isWorkspaceStaff: boolean;
  @Output() changeWorkOrderAssignment = new EventEmitter();
  @Output() closeWorkOrder = new EventEmitter();
  @Output() openEditWorkOrder = new EventEmitter();
  @Output() followWorkOrder = new EventEmitter();
  @Output() unfollowWorkOrder = new EventEmitter();
  @Output() openWorkOrder = new EventEmitter();
  @Output() reactivateWorkOrder = new EventEmitter();
  @Output() putOnHoldWorkOrder = new EventEmitter();
  @Output() setWorkOrderToPlanned = new EventEmitter();
  @Output() readyForPickupWorkOrder = new EventEmitter();
  @Output() updateWorkOrderfollowers = new EventEmitter();
  @Output() freezeScroll = new EventEmitter();
  @Output() newWorkOrderUpdate = new EventEmitter();
  @Output() refresh = new EventEmitter();
  @Output() updateWorkOrderDueDate = new EventEmitter();
  @Output() updateWorkOrderPriority = new EventEmitter();
  @ViewChild(MatCalendar) _datePicker: MatCalendar<Date>;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  customHeader = DatepickerHeaderComponent;

  public linkedWOTask: LinkedWOTask = null;
  public divWidth: number;
  public meeting_type = 1;
  public overdue = false;
  public workOrderPriorities: WorkOrderPriority[] = [NOT_SET_PRIORITY];
  private workOrderSubmissionFields = ['access', 'message', 'notify_followers', 'work_order_id'];
  constructor(
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _meetingService: MeetingService,
    private _modalService: ModalService,
    private _moduleService: ModuleService,
    public notificationService: NotificationService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _router: Router,
    public searchService: SearchService,
    private _sidenavService: SidenavService,
    private _snackbar: MatSnackBar,
    private _workOrderService: WorkOrderService,
    private _linkedTaskService: LinkedTaskService
  ) {}

  async ngOnInit() {
    await this._getWorkOrderPriorities();
    this.getDivWidth();
  }

  changeDueDate($event) {
    const due_date = ($event && moment($event).format('YYYY-MM-DD')) ?? null;
    this.updateWorkOrderDueDate.emit({ due_date });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    setTimeout(() => {
      this.divWidth = this.elementView.nativeElement.offsetWidth;
    }, 300);
  }

  get amFollowing(): boolean {
    return this.followers.map((follower) => +follower.id).includes(+this._authService?.currentUser?.id);
  }

  get buildingCode(): string {
    return this.workOrder?.building?.code || '';
  }

  get buildAndFloorCodes(): string {
    if (this.buildingCode && this.floorCode) {
      return `${this.buildingCode}-${this.floorCode}`;
    }

    return this.buildingCode || this.floorCode || '';
  }

  get inDispatch(): boolean {
    return !this.workOrder || this.workOrder.module_id === 2;
  }
  get closedLessThan24Hours() {
    return moment().subtract(24, 'hours').isBefore(this.workOrder?.closed_datetime);
  }
  get closedDate() {
    return moment(this.workOrder?.closed_datetime).format('dddd, MMMM Do, YYYY');
  }
  get closedTime() {
    return moment(this.workOrder?.closed_datetime).format('h:mm a');
  }
  get code(): string {
    return this.workOrder?.code || '';
  }

  get dueDate(): string | Date {
    return this.workOrder?.due_date;
  }

  get floorCode(): string {
    return this.workOrder?.floor?.code || '';
  }

  get followers(): User[] {
    return this.workOrder?.followers || [];
  }

  get formattedDueDate(): string {
    return moment(this.dueDate).isValid() ? moment(this.dueDate).format('ddd, MMM DD, YYYY') : '';
  }

  get assignedToName(): string {
    return `${this.workOrder?.assigned_user?.first_name || ''} ${
      this.workOrder?.assigned_user?.last_name || ''
    }`.trim();
  }

  get canReactivate() {
    return (
      this.workOrder?.status_id === 3 &&
      !this.workOrder?.converted_to_request?.id &&
      (this.isStaff || (this.closedLessThan24Hours && this.isRequester))
    );
  }

  get isOverdue(): boolean {
    if (moment(this.dueDate).isValid() && moment() > moment(this.dueDate)) {
      return true;
    } else {
      return false;
    }
  }

  get isSidenavClosed(): boolean {
    return this._sidenavService?.isSidenavClosed;
  }

  get isOneCallWorkspace(): boolean {
    return false;
  }
  get isStaff(): boolean {
    return this._authService.isUserWorkspaceStaff(this.workOrder?.module_id);
  }

  get isRequester() {
    return this.workOrder?.requester.id === this._authService?.currentUser?.id;
  }

  get priority(): string {
    return this.workOrder?.priority?.name || '';
  }

  get priority_id(): number {
    return this.workOrder?.priority?.id;
  }

  get status(): string {
    return this.workOrder?.status?.name || '';
  }

  get statusId(): number {
    return this.workOrder?.status_id;
  }

  get workOrderTitle(): string {
    return this.workOrder?.title || '';
  }

  get convertedToRequest(): Request {
    return this.workOrder?.converted_to_request;
  }

  get WorkOrderStatus() {
    return WorkOrderStatus;
  }
  get workspace(): Workspace {
    return this.workOrder?.module;
  }

  get workspaceId(): number {
    return this.workspace?.id || null;
  }

  get workSpaceName(): string {
    return this.workspace?.name || '';
  }

  get workspaceAdmin(): boolean {
    return this._authService.isUserWorkspaceAdmin(this.workOrder?.module?.id);
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
  }

  private async _getWorkOrderPriorities() {
    const workOrderPriorities: WorkOrderPriority[] = await this._workOrderService
      .getWorkOrderPriorities(['id', 'name', 'abbreviation'])
      .toPromise();

    this.workOrderPriorities = [NOT_SET_PRIORITY, ...workOrderPriorities];
  }

  private _openProgress(action: string) {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  public updateFollowers() {
    this.freezeScroll.emit(true);
    this._dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Update Followers',
          createUser: { title: 'Guest', guestUser: false },
          preSelectedUsers: [...this.followers.map((follower) => ({ ...follower, id: +follower.id }))],
          minimumRemovalPermission:
            this._authService.isAppAdmin ||
            this._authService?.isWorkspaceManager ||
            this._authService.isWorkspaceManager,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(({ selectedUsers, deSelectedUsers }) => {
        this.freezeScroll.emit(false);
        if (selectedUsers?.length > 0 || deSelectedUsers?.length > 0) {
          this.updateWorkOrderfollowers.emit({ selectedUsers, deSelectedUsers });
        }
      });
  }

  public changeAssignment() {
    if (this.isStaff) {
      this.changeWorkOrderAssignment.emit();
    }
  }

  public closeWO() {
    this._dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Close WO',
          headerText: 'Close Work Order',
          confirmationButtonText: 'Close WO',
          descriptionText: 'Are you sure you want to close this work order?',
          cancelButtonText: 'Cancel',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async (answer) => {
        if (answer) {
          this.closeWorkOrder.emit();
        }
      });
  }

  public createMeeting() {
    this._modalService.createMeeting({
      type_id: this.meeting_type,
      parent_type_id: ResourceType.WorkOrder,
      parent_id: this.workOrder?.id,
    });
  }

  public createMeetingAgendaFromWorkOrder() {
    const meetingSelectDialogRef = this._dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.WorkOrder,
        parent_id: this.workOrder.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        this._dialog
          .open(AgendaItemDialogComponent, {
            width: '480px',
            data: {
              meeting_id: returnedMeeting.id,
              meeting_title: returnedMeeting.title,
              meeting_code: returnedMeeting.code,
            },
          })
          .afterClosed()
          .subscribe((agendaItem) => {
            if (agendaItem) {
              const agendaItemToAdd = {
                meeting_id: returnedMeeting.id,
                description: agendaItem.description,
                duration: agendaItem.duration,
                parent_type_id: ResourceType.WorkOrder,
                parent_id: this.workOrder.id,
              };
              this._meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
                if (newAgendaItem) {
                  this._snackbar.open('Agenda item added!');
                }
              });
            }
          });
      }
    });
  }

  public async createRequest() {
    this._dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Convert Work Order',
          headerText: `Convert to Project Request`,
          confirmationButtonText: 'Create Project Request',
          descriptionText: 'Are you sure you want to convert this work order to a project request?',
          cancelButtonText: 'Cancel',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async (answer) => {
        if (answer) {
          this._openProgress(`Creating Project Request`);
          const requestId = await this._workOrderService.convertWorkOrderToRequest(this.workOrder.id).toPromise();
          this._router.navigate([`/requests/${requestId || ''}`]);
          this._closeProgress();
        }
      });
  }

  public editWorkOrder() {
    this.openEditWorkOrder.emit();
  }

  public followWO() {
    this.followWorkOrder.emit();
  }

  public unfollowWO() {
    this.unfollowWorkOrder.emit();
  }

  public holdWO() {
    this.putOnHoldWorkOrder.emit();
  }

  public setAsPlanned() {
    this.setWorkOrderToPlanned.emit();
  }

  public isReadyForPickup() {
    this.readyForPickupWorkOrder.emit();
  }

  public openWO() {
    this.openWorkOrder.emit();
  }

  public reactivateWO() {
    this.reactivateWorkOrder.emit();
  }

  public transferWorkOrder() {
    const data = {
      currentWorkspace: this.workspace,
    };

    this._modalService.openTransferWorkOrderModal(data).subscribe(async (res) => {
      // not to make a request
      if (res && this.workOrder?.id) {
        this._openProgress('Transferring WO');

        await this._workOrderService
          .transferToWorkspace(
            this.workOrder.id,
            res.selectedWorkspace.id,
            res.reason,
            true // this determines if we want to
          )
          .toPromise();

        // Changes the work orders module data so information in the header will change correctly
        this.workOrder.module = res.selectedWorkspace;

        if (
          this._moduleService.userWorkspaces
            .map((userWorkspace: Module) => userWorkspace.id)
            .includes(res.selectedWorkspace.id)
        ) {
          // reload data
          this.refresh.emit();
        } else {
          // redirect to list
          this._router.navigate([`/work-orders`]);
        }
      }
    });
  }

  public updatePriority(priority) {
    this.updateWorkOrderPriority.emit(priority);
  }

  public openUserProfilePreview(userId: number) {
    // enable assignment if there is no user
    userId ? this._modalService.openUserProfileDialog(userId) : this.changeAssignment();
  }
}
