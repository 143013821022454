<app-base-dialog [title]="title + ' Bid: ' + bidPackage?.trade_name || ''" (closeDialog)="cancel()"></app-base-dialog>
<div mat-dialog-content id="bid-dialog" data-test-id="bid-dialog" class="bid-dialog">
  <form #newBidForm="ngForm">
    <div *ngIf="newBid" class="row mx-0 p-3 border border-gray br-10 mb-3">
      <div class="col-6 pl-0">
        <h6 class="mb-2 dkblue font-weight-semibold">Bidding Company/Contact</h6>
        <mat-form-field appearance="outline" class="full-width mb-4">
          <input
            matInput
            required
            placeholder="Search Companies"
            aria-label="Company"
            name="biddingCompany"
            #biddingCompany="ngModel"
            #company_auto_input
            [(ngModel)]="companySearchTerm"
            (ngModelChange)="filterCompanies(companySearchTerm)"
            [matAutocomplete]="companyAuto"
          />
          <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="companyValueMapper">
            <mat-option *ngFor="let c of filteredCompanies" [value]="c" [disabled]="c.verification_status === 4">
              <p class="m-0 d-flex align-items-center">
                <span class="mr-auto">
                  {{ c.name }}
                </span>
                <small class="ml-3">{{ CompanyVerificationStatus[c.verification_status] }}</small>
              </p>
            </mat-option>
            <mat-option
              class="green"
              *ngIf="companySearchTerm"
              (click)="addNewCompany(companySearchTerm)"
              [value]="companySearchTerm"
            >
              <mat-icon>add_circle</mat-icon>
              <span class="font-weight-semibold"> Create New Company </span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="biddingCompany.errors?.required">Bidding company is required</mat-error>
          <mat-error *ngIf="biddingCompany.errors?.invalidCompany">Must be an existing company</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width mb-0">
          <input
            matInput
            aria-label="Company Contact"
            name="companyContact"
            placeholder="Search Contacts"
            [(ngModel)]="draftBid.contact"
            [matAutocomplete]="contactAuto"
            [required]="!didNotBid"
            #companyContact="ngModel"
            [disabled]="!selectedCompanyContacts"
            (input)="filterCompanyContacts($event)"
          />
          <mat-autocomplete #contactAuto="matAutocomplete" [displayWith]="companyContactValueMapper">
            <mat-option *ngFor="let contact of filteredSelectedCompanyContacts" [value]="contact">
              {{ contact.first_name }} {{ contact.last_name }}
            </mat-option>
            <mat-option class="ltblue" (click)="addNewUser(selectedCompany)">
              <mat-icon>add</mat-icon>
              <span> New Contact </span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="companyContact.errors?.required">Company contact is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <h6 class="mb-2 dkblue font-weight-semibold">Date Received</h6>
        <mat-form-field appearance="outline" class="full-width mb-4" (click)="bidReceivedDatepicker.open()">
          <input
            matInput
            [matDatepicker]="bidReceivedDatepicker"
            required
            name="bidDateReceived"
            #bidDateReceived="ngModel"
            [(ngModel)]="draftBid.date_received"
          />
          <mat-datepicker-toggle matSuffix [for]="bidReceivedDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #bidReceivedDatepicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width mb-0">
          <mat-select
            required
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="draftBid.did_not_bid"
            (ngModelChange)="clearBidAmounts()"
            [value]="draftBid.did_not_bid"
          >
            <mat-option [value]="0"> Bid Received </mat-option>
            <mat-option [value]="1"> Did Not Bid </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3 pr-0">
        <h6 class="dkblue mb-2 font-weight-semibold">Time</h6>
        <mat-form-field appearance="outline" class="full-width mb-4">
          <input
            matInput
            type="time"
            required
            id="bidTime"
            name="bidTimeReceived"
            #bidTimeReceived="ngModel"
            [(ngModel)]="draftBid.time_received"
          />
          <mat-icon disabled class="time-picker position-absolute dkblue" matSuffix>schedule</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!newBid && !isEditingDetails" class="row align-items-top mb-4">
      <div class="col-auto" style="width: 105px">
        <div class="p-3 bg-ltblue br-10 text-center">
          <h1 class="white m-0 font-weight-bold py-2">{{ selectedCompany.name | slice: 0:1 }}</h1>
        </div>
      </div>
      <div class="col pl-0 pr-3 py-2">
        <h3 class="mb-0 text-wrap dkblue font-weight-bold d-flex align-items-center">
          <span class="mr-2">
            <span>
              {{ selectedCompany.name }}
            </span>
            <mat-icon class="green">verified</mat-icon>
          </span>
          <h6 *ngIf="draftBid.is_msa_agreement" class="px-1 d-inline-block lh-18 bg-gold br-4 white white m-0">MSA</h6>
        </h3>
        <h3 class="mb-2 text-wrap dkgray font-weight-normal">
          {{ draftBid?.contact?.first_name }} {{ draftBid?.contact?.last_name }}
        </h3>
      </div>
      <div *ngIf="companyAwarded || draftBid.did_not_bid" class="col-auto align-self-start">
        <span *ngIf="companyAwarded" class="d-inline-flex align-items-center px-3 br-5 bg-green white">
          <mat-icon class="mr-2"> verified </mat-icon>
          <h5 class="font-weight-semibold m-0 lh-32">Awarded</h5>
        </span>
        <span *ngIf="draftBid.did_not_bid" class="d-inline-flex align-items-center px-3 br-5 bg-gray white">
          <h5 class="font-weight-semibold m-0 lh-32">Did Not Bid</h5>
        </span>
      </div>
      <div *ngIf="!isEditingDetails && !companyAwarded && !draftBid.did_not_bid && isAdmin" class="col-auto">
        <button mat-stroked-button color="accent" (click)="editDetails()" type="button">Edit</button>
      </div>
    </div>
    <div *ngIf="isEditingDetails && !newBid && !bidPackageAwarded" class="row mx-0 p-3 border border-gray br-10 mb-3">
      <div class="col-6">
        <h6 class="mb-2 dkblue font-weight-semibold">Bidding Company/Contact</h6>
        <mat-form-field appearance="outline" class="full-width mb-3">
          <input
            matInput
            required
            aria-label="Company"
            name="biddingCompany"
            #biddingCompany="ngModel"
            [(ngModel)]="companySearchTerm"
            (ngModelChange)="filterCompanies(companySearchTerm)"
            [matAutocomplete]="companyAuto"
            disabled
          />
          <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="companyValueMapper">
            <mat-option *ngFor="let c of filteredCompanies" [value]="c">{{ c.name }} </mat-option>
            <mat-option
              class="gray"
              *ngIf="companySearchTerm"
              (click)="addNewCompany(companySearchTerm)"
              [value]="companySearchTerm"
            >
              <i class="la la-plus"></i> &nbsp; New Company
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="biddingCompany.errors?.required">Bidding company is required</mat-error>
          <mat-error *ngIf="biddingCompany.errors?.invalidCompany">Must be an existing company</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width mb-0">
          <input
            matInput
            aria-label="Company Contact"
            name="companyContact"
            [(ngModel)]="draftBid.contact"
            [matAutocomplete]="contactAuto"
            [required]="!didNotBid"
            #companyContact="ngModel"
            [disabled]="!selectedCompanyContacts"
            (input)="filterCompanyContacts($event)"
          />
          <mat-autocomplete #contactAuto="matAutocomplete" [displayWith]="companyContactValueMapper">
            <mat-option *ngFor="let contact of filteredSelectedCompanyContacts" [value]="contact">
              {{ contact.first_name }} {{ contact.last_name }}
            </mat-option>
            <mat-option class="ltblue" (click)="addNewUser(selectedCompany)">
              <mat-icon>add</mat-icon>
              <span> New Contact </span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="companyContact.errors?.required">Company contact is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <h6 class="mb-2 dkblue font-weight-semibold">Date Received</h6>
        <mat-form-field appearance="outline" class="full-width mb-3" (click)="bidReceivedDatepicker.open()">
          <input
            matInput
            [matDatepicker]="bidReceivedDatepicker"
            required
            name="bidDateReceived"
            #bidDateReceived="ngModel"
            [(ngModel)]="draftBid.date_received"
          />
          <mat-datepicker-toggle matSuffix [for]="bidReceivedDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #bidReceivedDatepicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width mb-0">
          <mat-select
            required
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="draftBid.did_not_bid"
            (ngModelChange)="clearBidAmounts()"
            [value]="draftBid.did_not_bid"
          >
            <mat-option [value]="0"> Bid Received </mat-option>
            <mat-option [value]="1"> Did Not Bid </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-3">
        <h6 class="dkblue mb-2 font-weight-semibold">Time</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            type="time"
            required
            name="bidTimeReceived"
            #bidTimeReceived="ngModel"
            [(ngModel)]="draftBid.time_received"
          />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!bidPackageAwarded && !viewOnly" class="row mx-0 p-3 border border-gray br-10 mb-3">
      <div class="col d-flex align-items-center p-0">
        <h5 class="mb-0 primary mr-auto">Master Service Agreement (MSA)</h5>
        <mat-slide-toggle
          name="isMSAAgreement"
          #isMSAAgreement="ngModel"
          [(ngModel)]="draftBid.is_msa_agreement"
          [checked]="draftBid.is_msa_agreement"
          class="float-right"
          color="accent"
        >
        </mat-slide-toggle>
        <!-- <mat-checkbox class="mb-0 bid-details-form-field" name="isMSAAgreement" #isMSAAgreement="ngModel"
          [(ngModel)]="draftBid.is_msa_agreement" [value]="draftBid.is_msa_agreement">
          <span class="font-weight-semibold">
            Master Service Agreement (MSA)
          </span>
        </mat-checkbox> -->
      </div>
    </div>
    <div *ngIf="!bidPackageAwarded && !viewOnly" class="row mx-0 p-3 border border-gray br-10 mb-3">
      <mat-checkbox
        class="col-3 pl-0 mb-0 font-weight-semibold"
        name="attendedMandatoryMeetings"
        #attendedMandatoryMeetings="ngModel"
        [(ngModel)]="draftBid.attended_mandatory_meetings"
        [value]="draftBid.attended_mandatory_meetings"
        >Mandatory Meetings</mat-checkbox
      >
      <mat-checkbox
        class="col-3 mb-0 font-weight-semibold"
        name="isSealedEnvelope"
        #isSealedEnvelope="ngModel"
        [(ngModel)]="draftBid.is_sealed_envelope"
        [value]="draftBid.is_sealed_envelope"
        >Sealed Envelope</mat-checkbox
      >
      <mat-checkbox
        class="col-3 mb-0 font-weight-semibold"
        name="isSigned"
        #isSigned="ngModel"
        [(ngModel)]="draftBid.is_signed"
        [value]="draftBid.is_signed"
        >Signed by Rep</mat-checkbox
      >
      <mat-checkbox
        class="col-3 pr-0 mb-0 font-weight-semibold"
        name="hasNonCollusion"
        #hasNonCollusion="ngModel"
        [(ngModel)]="draftBid.has_non_collusion"
        [value]="draftBid.has_non_collusion"
        >Non-collusion Cert</mat-checkbox
      >

      <!-- <mat-radio-group
        class="full-width d-flex align-items-center pl-3 mt-2"
        name="collateralType"
        #collateralType="ngModel"
        required
        [(ngModel)]="draftBid.collateral_type"
        (ngModelChange)="collateralChanged(draftBid.collateral_type)"
      >
        <mat-radio-button class="d-flex flex-grow-1" [value]="0">No Collateral</mat-radio-button>
        <mat-radio-button class="d-flex flex-grow-1" [value]="1">Cashiers Check</mat-radio-button>
        <mat-radio-button class="d-flex flex-grow-1" [value]="3">Bid Bond</mat-radio-button>
        <mat-radio-button class="d-flex flex-grow-1" [value]="2">Letter of Credit (5%) / Check</mat-radio-button>
      </mat-radio-group> -->
    </div>
    <div *ngIf="!bidPackageAwarded && !viewOnly" class="row mx-0 p-3 border border-gray br-10 mb-4">
      <div class="col-lg-6 px-0">
        <h6 class="primary">Collateral</h6>
        <mat-form-field appearance="outline" class="full-width mb-0">
          <mat-select
            required
            name="collateralType"
            #collateralType="ngModel"
            [(ngModel)]="draftBid.collateral_type"
            (ngModelChange)="collateralChanged(draftBid.collateral_type)"
          >
            <mat-option class="d-flex flex-grow-1" [value]="0">No Collateral</mat-option>
            <mat-option class="d-flex flex-grow-1" [value]="1">Cashiers Check</mat-option>
            <mat-option class="d-flex flex-grow-1" [value]="3">Bid Bond</mat-option>
            <mat-option class="d-flex flex-grow-1" [value]="2">Letter of Credit (5%) / Check</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 bond-input">
        <h6 [ngClass]="{ ltgray: collateralType.value !== 3 }" class="primary">Bond Company</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            class="full-width"
            [disabled]="draftBid.collateral_type != 3"
            placeholder="Bond Company"
            name="bondCompany"
            #bondCompany="ngModel"
            [(ngModel)]="draftBid.bond_company_name"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="bidPackageAwarded || viewOnly" class="row mb-4">
      <div class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: isBeforeBidEndDate,
              red: !isBeforeBidEndDate,
              gray: !isBeforeBidEndDate && draftBid.is_msa_agreement
            }"
          >
            {{ isBeforeBidEndDate ? 'check_circle' : 'cancel' }}
          </i>
          <span class="d-inline-block align-middle">
            {{ displayDate }}
          </span>
        </p>
      </div>
      <div *ngIf="over100k || draftBid.is_sealed_envelope" class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: draftBid.is_sealed_envelope,
              red: !draftBid.is_sealed_envelope,
              gray: !draftBid.is_sealed_envelope && draftBid.is_msa_agreement
            }"
          >
            {{ draftBid.is_sealed_envelope ? 'check_circle' : 'cancel' }}
          </i>
          <span class="d-inline-block align-middle">Sealed Envelope</span>
        </p>
      </div>
      <div *ngIf="over100k || draftBid.is_signed" class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: draftBid.is_signed,
              red: !draftBid.is_signed,
              gray: !draftBid.is_signed && draftBid.is_msa_agreement
            }"
          >
            {{ draftBid.is_signed ? 'check_circle' : 'cancel' }}
          </i>
          <span class="d-inline-block align-middle">Signed By Company Rep</span>
        </p>
      </div>
      <div *ngIf="over100k || draftBid.has_non_collusion" class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: draftBid.has_non_collusion,
              red: !draftBid.has_non_collusion,
              gray: !draftBid.has_non_collusion && draftBid.is_msa_agreement
            }"
          >
            {{ draftBid.has_non_collusion ? 'check_circle' : 'cancel' }}
          </i>
          <span class="d-inline-block align-middle">Non-Collusion Certification</span>
        </p>
      </div>
      <div *ngIf="hasMandatoryMeetings" class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: draftBid.attended_mandatory_meetings,
              red: !draftBid.attended_mandatory_meetings,
              gray: !draftBid.attended_mandatory_meetings && draftBid.is_msa_agreement
            }"
          >
            {{ draftBid.attended_mandatory_meetings ? 'check_circle' : 'cancel' }}
          </i>
          <span class="d-inline-block align-middle">Mandatory Meetings</span>
        </p>
      </div>
      <div *ngIf="over100k || draftBid.collateral_type > 0" class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons check mr-2 green d-inline-block align-middle"
            [ngClass]="{
              green: draftBid.collateral_type > 0,
              red: !draftBid?.is_msa_agreement && !draftBid.collateral_type,
              gray: draftBid?.is_msa_agreement && !draftBid.collateral_type
            }"
          >
            {{ draftBid.collateral_type > 0 ? 'check_circle' : 'cancel' }}
          </i>
          <span class="dkblue m-0" *ngIf="!draftBid?.collateral_type">No Collateral</span>
          <span class="dkblue m-0" *ngIf="draftBid.collateral_type == 1">Cashiers Check</span>
          <span class="dkblue m-0" *ngIf="draftBid.collateral_type == 2">Letter of Credit (5%) / Check</span>
          <span class="dkblue m-0" *ngIf="draftBid.collateral_type == 3"
            >Bid Bond <span *ngIf="draftBid?.bond_company_name">({{ draftBid?.bond_company_name }})</span></span
          >
        </p>
      </div>
      <div class="col-4 mb-2 bid-details-form-field">
        <p class="mb-0 align-items-center m-0 font-weight-semibold">
          <i
            class="material-icons mr-2 d-inline-block align-middle"
            [ngClass]="{
              green: draftBid?.company?.verification_status === 2 || draftBid?.company?.verification_status === 3,
              red: draftBid?.company?.verification_status === 1 || draftBid?.company?.verification_status === 4
            }"
          >
            {{
              draftBid?.company?.verification_status !== 1 && draftBid?.company?.verification_status !== 4
                ? 'check_circle'
                : 'cancel'
            }}
          </i>
          <span class="dkblue m-0"
            >Verification: {{ CompanyVerificationStatus[selectedCompany?.verification_status] }}</span
          >
        </p>
      </div>
    </div>
    <div class="row align-items-center m-0 mb-2">
      <div class="col-5">
        <h6 class="m-0 dkblue font-weight-semibold">Description</h6>
      </div>
      <div class="col-4 pl-0">
        <h6 class="m-0 dkblue font-weight-semibold">File</h6>
      </div>
      <div class="col pl-0 text-right">
        <h6 class="m-0 dkblue font-weight-semibold">Amount</h6>
      </div>
    </div>
    <div *ngFor="let bid of bids; let i = index" class="row align-items-center m-0 py-3 mb-3 br-5 bg-shade-ltblue">
      <div class="col-5" *ngIf="bid.type_id === BidType.Base">
        <h5 class="m-0 dkblue font-weight-semibold">Base Bid</h5>
      </div>
      <div *ngIf="bid.type_id === BidType.Alt" class="col-5 d-flex align-items-center">
        <mat-checkbox
          class="mr-3"
          (change)="toggleBidSelection(bid)"
          [checked]="!!bid.is_selected"
          [disabled]="bidPackageAwarded || viewOnly || didNotBid"
        ></mat-checkbox>
        <mat-form-field *ngIf="!bidPackageAwarded && !viewOnly" appearance="outline" class="full-width mb-0">
          <input
            matInput
            placeholder="Alt Bid Description"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="bid.description"
            [value]="bid.description"
            [disabled]="didNotBid"
          />
        </mat-form-field>
        <span *ngIf="bidPackageAwarded || viewOnly">
          <h5 class="m-0 dkblue font-weight-semibold py-2">
            {{ bid.description }}
          </h5>
        </span>
      </div>
      <div class="col-4 pl-0">
        <button
          mat-stroked-button
          class="bg-white"
          type="button"
          color="accent"
          (click)="openUploadFileModal(bid)"
          *ngIf="!bid.files && !bidPackageAwarded && !viewOnly"
        >
          <mat-icon>attach_file</mat-icon>
          <span> Attach File </span>
        </button>
        <mat-chip-list class="d-inline-block align-middle" *ngIf="bid.files">
          <app-file-chip
            *ngFor="let file of bid.files"
            [file]="file"
            [removable]="!bidPackageAwarded && !viewOnly"
            (removeEvent)="removeFile(bid)"
          ></app-file-chip>
        </mat-chip-list>
      </div>
      <div
        *ngIf="bid.type_id === BidType.Alt && !bidPackageAwarded && !viewOnly"
        class="col-auto pl-0 pr-2"
        style="width: 55px"
      >
        <mat-form-field appearance="outline" class="full-width">
          <mat-select
            (selectionChange)="toggleAmountValue(bid, $event.value)"
            [value]="bid.cost"
            [disabled]="didNotBid"
          >
            <mat-option [value]="true"> + </mat-option>
            <mat-option [value]="false"> - </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col pl-0 text-right">
        <mat-form-field
          *ngIf="!bidPackageAwarded && !viewOnly"
          appearance="outline"
          class="full-width font-weight-semibold text-right"
        >
          <span class="dkblue" matPrefix> $&nbsp; </span>
          <input
            matInput
            type="currency"
            required
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="bid.amount"
            (ngModelChange)="updateAmount(bid)"
            [value]="bid.amount"
            [disabled]="didNotBid"
          />
        </mat-form-field>
        <h5 class="m-0 dkblue font-weight-semibold py-2" *ngIf="bidPackageAwarded || viewOnly">
          {{ bid.amount || 0 | currency: 'USD' }}
        </h5>
        <i
          *ngIf="bid.type_id === BidType.Alt && !bidPackageAwarded && !viewOnly"
          class="material-icons gray pointer"
          style="position: absolute; top: -13px; right: -13px"
          (click)="removeAlternateBid(bid)"
        >
          cancel
        </i>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button
          *ngIf="!bidPackageAwarded && !viewOnly"
          (click)="addAlternateBid()"
          type="button"
          mat-button
          color="accent"
          [disabled]="didNotBid"
          class="mb-3"
        >
          Add Alternate Bid
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center p-3 bg-shade-ltblue mb-4 br-5">
      <h5 class="m-0 dkblue font-weight-semibold">Total Bid Amount</h5>
      <h5 class="m-0 dkblue font-weight-bold ml-auto">
        {{ bidTotal || 0 | currency: 'USD' }}
      </h5>
    </div>
  </form>
</div>
<div *ngIf="!bidPackageAwarded && !viewOnly" mat-dialog-actions class="py-4" align="end">
  <button mat-button type="button" class="gray px-5" (click)="cancel()">Cancel</button>
  <button type="button" mat-flat-button class="ml-4 px-5" color="success" (click)="addBaseBid(false, false)">
    Save Bid
  </button>
</div>
<div *ngIf="bidPackageAwarded || viewOnly" class="col">
  <button mat-button (click)="cancel()" color="accent" class="full-width gray" type="button">Dismiss</button>
</div>
