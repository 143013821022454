<form [formGroup]="projectProductFormGroup" class="row project-product d-flex align-items-stretch m-0 item-last">
  <div class="col-auto d-flex flex-column align-items-center pt-4 pr-2" style="width: 30px">
    <h4 class="dkblue font-weight-semibold py-2 mx-auto">
      {{ productIndex + 1 }}
    </h4>
    <div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="product_menu"
        color="accent"
        class="mx-auto"
        [disabled]="cantMakeChanges"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #product_menu="matMenu" xPosition="after">
        <!-- TODO: *ngIf="canDelete" -->
        <button mat-menu-item (click)="deleteProjectProduct()">
          <mat-icon>delete</mat-icon>
          <span>Remove Product</span>
        </button>
        <!-- TODO -->
        <!-- <button mat-menu-item>
          <mat-icon>error_outline</mat-icon>
          <span>Remove Quotes to Edit</span>
        </button> -->
      </mat-menu>
    </div>
  </div>
  <div class="col py-4 px-4 d-flex flex-column">
    <mat-form-field appearance="outline" class="full-width mb-3">
      <input
        type="text"
        placeholder="Choose Product"
        class="font-weight-semibold"
        matInput
        [matAutocomplete]="product_auto"
        formControlName="product"
        (ngModelChange)="productChanged()"
        (blur)="saveProjectProduct()"
      />
      <mat-error *ngIf="product.errors?.invalidProduct">Select a product from the list or create a new one</mat-error>
      <mat-autocomplete autoActiveFirstOption #product_auto="matAutocomplete" [displayWith]="productValueMapper">
        <mat-option *ngFor="let p of filteredProducts" [value]="p">
          {{ p.name }}
        </mat-option>
        <mat-option class="green" (click)="openNewProductDialog()">
          <mat-icon>add_circle</mat-icon>
          <span> Create New Product </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width mb-3">
      <input
        type="text"
        placeholder="Choose Cost Code"
        class="font-weight-semibold"
        matInput
        [matAutocomplete]="cost_code_auto"
        (input)="filterCostCodes($event)"
        [formControl]="sub_cost_code_budget"
      />
      <mat-autocomplete autoActiveFirstOption #cost_code_auto="matAutocomplete" [displayWith]="subCostCodeValueMapper">
        <div *ngFor="let c of filteredCostCodes | filterCostCodesByBudgetIsEnabled">
          <h6 class="primary w-100 p-3 m-0 bg-shade-gray">
            <span class="mr-2">{{ c.code }}</span>
            {{ c.label }}
          </h6>
          <mat-option
            class="d-flex align-items-center"
            style="padding: 0 5px !important"
            *ngFor="let s of c.sub_cost_codes"
            [value]="s"
            (click)="setCostCodeId(s)"
          >
            <div class="d-flex align-items-center p-1 ml-2">
              <span class="lh-30 mr-2"> {{ s.code }} - {{ s.label }} </span>
              <span class="ml-auto">
                <span class="lh-20 bg-ltgray dkgray py-1 px-3 br-4 mr-2 cc-badge">{{
                  s.sub_cost_code_type?.name
                }}</span>
                <span
                  class="lh-20 py-1 px-3 br-4 cc-badge"
                  [ngClass]="s.fiscal_year % 2 === 0 ? 'cc-badge-fy-green' : 'cc-badge-fy-blue'"
                  >FY{{ s.fiscal_year }}</span
                >
              </span>
            </div>
          </mat-option>
        </div>
      </mat-autocomplete>
      <mat-hint *ngIf="selectedCostCode?.code" class="font-weight-semibold dkgray">
        <!-- Test -->
        {{ selectedCostCode?.code || '' }} |
        {{ selectedCostCode?.label || '' }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width textarea mt-3">
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMaxRows="8"
        resizeToFitContent="true"
        cdkAutosizeMinRows="2"
        placeholder="Description / Notes"
        formControlName="description"
        (blur)="saveProjectProduct()"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="col-1 pl-0 pr-3 pt-4 pb-4 border-right border-gray">
    <mat-form-field appearance="outline" class="full-width mb-3">
      <input
        matInput
        class="text-right"
        pattern="^\d*(\.\d{0,2})?$"
        placeholder="QTY"
        formControlName="quantity"
        (keyup)="quantityChanged()"
        (focus)="$event.target.select()"
        (blur)="quantityBlur()"
      />
    </mat-form-field>
    <mat-checkbox formControlName="is_in_stock" class="my-1 d-flex align-items-center" (change)="saveProjectProduct()">
      <p class="dkblue font-weight-normal text-wrap lh-16 d-flex mb-0">Stock Item</p>
    </mat-checkbox>
    <h6 [matMenuTriggerFor]="inStockMenu" class="accent mb-0 pointer hover-u">
      <small> What's this? </small>
    </h6>
    <mat-menu #inStockMenu="matMenu" xPosition="after">
      <div class="d-flex flex-column p-4">
        <h6 class="primary">Stock Items</h6>
        <p class="dkgray lh-22 mb-0">
          Items that have been acquired and kept in inventory are referred to as
          <span class="font-weight-semibold font-italic">stock items</span>. Additionally, as these things have already
          been purchased, no invoices or bids are needed for them.
        </p>
      </div>
    </mat-menu>
    <mat-checkbox
      *ngIf="is_taxable?.value"
      formControlName="is_taxable"
      class="my-2 d-flex align-items-center"
      (change)="saveProjectProduct()"
    >
      <span class="dkblue font-weight-normal text-wrap check-box-label d-flex"> Apply sales tax </span>
    </mat-checkbox>
  </div>
  <div class="col-6 d-flex flex-column p-3">
    <div *ngFor="let quoteItem of projectProduct.quote_items">
      <div
        *ngIf="[QuoteItemStatus.BidReceived, QuoteItemStatus.DidNotBid].indexOf(quoteItem.status_id) > -1"
        class="d-flex pt-1 pb-1 align-items-center"
        style="min-height: 42px"
        [ngClass]="{
          'selected-bid':
            projectProduct.is_enabled &&
            !projectProduct.is_in_stock &&
            (quoteItem.id === projectProduct.selected_quote_item_id ||
              (!projectProduct?.selected_quote_item_id && quoteItem?.is_budget)),
          pointer:
            projectProduct.is_enabled &&
            !projectProduct.is_in_stock &&
            quoteItem.status_id === QuoteItemStatus.BidReceived &&
            (isCompanyAllowed(quoteItem?.quote?.company) || !quoteItem?.quote?.company),
          'bid-item-selectable':
            projectProduct.is_enabled &&
            !!projectProduct.is_in_stock &&
            quoteItem.status_id === QuoteItemStatus.BidReceived &&
            isCompanyAllowed(quoteItem?.quote?.company)
        }"
        matRipple
        [matRippleDisabled]="
          !projectProduct.is_enabled ||
          !!projectProduct.is_in_stock ||
          quoteItem.status_id !== QuoteItemStatus.BidReceived ||
          (quoteItem?.quote?.company && !isCompanyAllowed(quoteItem?.quote?.company))
        "
        (click)="selectQuoteItem(quoteItem, projectProduct, tenant)"
      >
        <div class="col-6 pl-3 d-flex align-items-center">
          <mat-icon
            class="mr-2"
            [ngClass]="[
              projectProduct.is_enabled &&
              !projectProduct.is_in_stock &&
              quoteItem.status_id === QuoteItemStatus.BidReceived &&
              (!projectProduct.selected_quote_item_id || quoteItem.id === projectProduct.selected_quote_item_id) &&
              isCompanyAllowed(quoteItem?.quote?.company)
                ? 'ltblue'
                : 'gray'
            ]"
            >{{
              projectProduct.is_enabled &&
              (!projectProduct.is_in_stock || (projectProduct.is_in_stock && quoteItem.is_budget)) &&
              (quoteItem?.id === projectProduct?.selected_quote_item_id ||
                (!projectProduct?.selected_quote_item_id && quoteItem?.is_budget))
                ? 'radio_button_checked'
                : 'radio_button_unchecked'
            }}
          </mat-icon>
          <span
            class="dkblue font-weight-semibold lh-16 text-truncate"
            [ngClass]="{ gray: !quoteItem?.label && !isCompanyAllowed(quoteItem?.quote?.company) }"
          >
            <span class="mr-2">
              {{ quoteItem?.label || quoteItem?.quote?.company?.name }}
              <span
                *ngIf="quoteItem?.quote.description"
                class="font-weight-normal text-truncate"
                [title]="quoteItem?.quote.description"
              >
                | {{ quoteItem?.quote.description }}
              </span>
            </span>
            <br />
            <span *ngIf="quoteItem?.label" class="gray font-weight-normal"> Optional </span>
            <div
              *ngIf="!quoteItem?.label && !isCompanyAllowed(quoteItem?.quote?.company)"
              class="gray font-weight-normal"
            >
              Verification Status: {{ CompanyVerificationStatus[quoteItem?.quote?.company?.verification_status] }}
            </div>
          </span>
        </div>
        <div class="col-3 px-2 py-1 d-flex flex-column text-right">
          <mat-form-field *ngIf="quoteItem.is_budget" appearance="outline" class="full-width text-right">
            <span class="dkblue" matPrefix>$&nbsp;</span>
            <input
              matInput
              class="text-right"
              pattern="^-?\d+(\.\d{1,2})?$"
              formControlName="unit_price"
              placeholder="0.00"
              step=".01"
              (keyup)="unitPriceChanged()"
              (focus)="$event.target.select()"
              (blur)="unitPriceBlur()"
              (click)="$event.stopPropagation()"
            />
          </mat-form-field>
          <p
            *ngIf="!quoteItem.is_budget"
            class="font-weight-semibold dkblue pr-2 pl-2 mb-0"
            [ngClass]="{ gray: !quoteItem?.label && !isCompanyAllowed(quoteItem?.quote?.company) }"
          >
            {{ quoteItem.status_id === 1 ? (quoteItem.unit_price || 0 | currency: 'USD') : 'DNB' }}
          </p>
        </div>
        <div class="col-3 pl-0 pr-2 d-flex flex-column text-right">
          <mat-form-field *ngIf="quoteItem.is_budget" appearance="outline" class="full-width text-right">
            <span class="dkblue" matPrefix>$&nbsp;</span>
            <input
              matInput
              class="text-right"
              formControlName="total_price"
              placeholder="0.00"
              step=".01"
              (keyup)="totalChanged()"
              (focus)="$event.target.select()"
              (blur)="totalBlur()"
              (click)="$event.stopPropagation()"
            />
          </mat-form-field>
          <p
            *ngIf="!quoteItem.is_budget"
            class="font-weight-semibold dkblue pr-2 pl-2 mb-0"
            [ngClass]="{ gray: !quoteItem?.label && !isCompanyAllowed(quoteItem?.quote?.company) }"
          >
            {{ quoteItem.status_id === 1 ? (quoteItem.total_price || 0 | currency: 'USD') : 'DNB' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
