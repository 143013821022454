<div id="department" data-test-id="department" class="department">
  <h6 *ngIf="showLabel" class="m-0 dkblue font-weight-semibold mb-2">Department<span *ngIf="required">*</span></h6>
  <mat-form-field appearance="outline" class="full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Department"
      matInput
      [matAutocomplete]="department_auto"
      [formControl]="department"
      (blur)="checkValue()"
    />
    <mat-icon *ngIf="department?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-error *ngIf="department?.errors?.required">A department is required</mat-error>
    <mat-error *ngIf="department?.errors?.doesNotExist">The department does not exist</mat-error>
    <mat-autocomplete #department_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="mapper">
      <mat-option *ngFor="let department of filteredDepartments | async" [value]="department">
        {{ department?.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
