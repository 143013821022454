import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {

  public static ASC: string = 'asc';

  public static DESC: string = 'desc';

  orderByFn<T>(a: T, b: T, propName: string|number): 1 | -1 | 0  {
    if (a[propName] < b[propName]) {
      return -1;
    } else if (a[propName] > b[propName]) {
      return 1;
    } else {
      return 0;
    }
  }

  transform<T>(value: T[], propName: string, order: string = SortByPipe.ASC): T[] {
    if (!value || !propName) {
      return value;
    }

    return value.sort((a, b) => {
      return order == SortByPipe.ASC ? this.orderByFn(a, b, propName) : this.orderByFn(b, a, propName);
    });
  }
}
