<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div id="file-attachment-dialog" data-test-id="file-attachment-dialog" class="file-attachment-dialog">
  <mat-dialog-content>
    <div class="row mb-4" *ngIf="linkedFiles.length + computerFiles.length < maxFiles">
      <app-file-upload-zone
        class="col"
        [filteredFiles]="filteredFiles"
        [maxFiles]="maxFiles"
        [parentResourceId]="parentResourceId"
        [parentResourceType]="parentResourceType"
        [projectParent]="projectParent"
        [uploadComputerFilesOnly]="workOrderFile || draftFile || !allowSearchFromProject"
        (filesUploaded)="uploadFiles($event)"
      >
      </app-file-upload-zone>
    </div>
    <div *ngIf="computerFiles.length + linkedFiles.length > 0" class="row file-row mt-2">
      <div class="col">
        <div *ngFor="let file of computerFiles" class="row align-items-center mb-4 pb-2">
          <div class="col">
            <mat-form-field class="full-width m-0" appearance="outline">
              <input
                class="pr-5"
                matInput
                [(ngModel)]="file.displayName"
                #val
                (focusout)="onNameChange(file, val.value)"
                (ngModelChange)="verifyFileNames()"
                placeholder="File Name"
                [ngClass]="{ red: !file.hasValidExtension || !file.isVerified || !file.isValidFileSize }"
              />
              <mat-hint *ngIf="file?.convertedFromHEICToPNG" class="dkgray">HEIC file format converted to PNG</mat-hint>
              <mat-hint *ngIf="!file.isVerified" class="red">File name already exists</mat-hint>
              <mat-hint *ngIf="!file.hasValidExtension" class="red">Invalid file extension</mat-hint>
              <mat-hint *ngIf="!file.isValidFileSize" class="red">File size exceeds 80MB</mat-hint>
              <mat-hint *ngIf="file.isEncrypted" class="red">File is encrypted</mat-hint>
              <mat-hint *ngIf="file.isCorrupt" class="red">File is corrupt</mat-hint>
              <span class="extension ltblue">{{ file.extension }}</span>
            </mat-form-field>
          </div>
          <div class="col-auto text-right">
            <i
              *ngIf="
                file.isVerified &&
                file.hasValidExtension &&
                file.isValidFileSize &&
                !file.isEncrypted &&
                !file.isCorrupt
              "
              class="material-icons green pointer ease file-icon"
              matTooltip="File name available"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >check_circle</i
            >
            <i
              *ngIf="!file.isVerified"
              class="material-icons red pointer ease file-icon"
              matTooltip="File name already exists"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >error</i
            >
            <i
              *ngIf="!file.hasValidExtension"
              class="material-icons red pointer ease file-icon"
              matTooltip="Invalid file extension"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >error</i
            >
            <i
              *ngIf="!file.isValidFileSize"
              class="material-icons red pointer ease file-icon"
              matTooltip="Exceeds 80mb limit"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >error</i
            >
            <i
              *ngIf="file.isEncrypted"
              class="material-icons red pointer ease file-icon"
              matTooltip="File is encrypted"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >error</i
            >
            <i
              *ngIf="file.isCorrupt"
              class="material-icons red pointer ease file-icon"
              matTooltip="File is is corrupt"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >error</i
            >
            <i
              class="material-icons gray pointer ease ml-4 file-icon"
              (click)="removeComputerFile(file)"
              matTooltip="Remove File"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >delete</i
            >
          </div>
        </div>
        <div *ngIf="linkedFiles.length > 0" class="row mb-4">
          <div class="col">
            <mat-chip-list>
              <div *ngFor="let file of linkedFiles">
                <app-file-chip
                  [file]="file"
                  [removable]="true"
                  [downloadable]="false"
                  (removeEvent)="removeProjectLinkedFile(file)"
                ></app-file-chip>
                <div class="d-inline-block align-middle">
                  <!-- <i
                  *ngIf="verifyFileExtension && file.hasValidExtension"
                  class="material-icons green d-inline-block align-middle mr-2"
                  matTooltip="Valid File Extension"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  >check</i
                > -->
                  <i
                    *ngIf="verifyFileExtension && !file.hasValidExtension"
                    class="material-icons red d-inline-block align-middle mr-2 file-icon"
                    matTooltip="Invalid file extension"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="300"
                    >error</i
                  >
                  <h6
                    *ngIf="verifyFileExtension && !file.hasValidExtension"
                    class="m-0 main-label font-weight-normal red d-inline-block align-middle"
                  >
                    Invalid File Extension - File must be pdf, jpg, jpeg, or png
                  </h6>
                </div>
              </div>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-2" *ngIf="!data?.hideTags && computerFiles.length > 0 && linkedFiles.length === 0">
      <div class="col">
        <label class="main-label dkblue mb-2"> Add Primary Tag </label>
        <div class="row">
          <div class="col">
            <span
              *ngFor="let tag of primaryTags"
              matRipple
              class="
                file-tag
                border
                br-5
                ease
                d-inline-block
                align-middle
                font-weight-semibold
                pointer
                pl-2
                pr-2
                bg-dkblue
                white
                mr-3
                mt-2
                mb-2
              "
              [ngClass]="{
                'bg-dkblue white': tag.is_selected,
                dkblue: !tag.is_selected
              }"
              (click)="togglePrimaryTag(tag)"
            >
              {{ tag.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-5"
      *ngIf="possibleSecondaryTags.length > 0 && computerFiles.length > 0 && linkedFiles.length === 0"
    >
      <div class="col">
        <label class="main-label dkblue mb-2"> Add Secondary Tags </label>
        <div class="row">
          <div class="col">
            <span
              *ngFor="let tag of possibleSecondaryTags"
              matRipple
              class="
                file-tag
                border
                br-5
                ease
                d-inline-block
                align-middle
                font-weight-semibold
                pointer
                pl-2
                pr-2
                mr-3
                mt-2
                mb-2
              "
              [ngClass]="{
                'bg-ltblue white': tag.is_selected,
                ltblue: !tag.is_selected
              }"
              (click)="toggleSecondaryTag(tag)"
            >
              {{ tag.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 dialog-form" [hidden]="!allowComment">
      <div class="col">
        <app-editor #editor placeholder="Add a comment about this upload."></app-editor>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex full-width my-3 align-items-center">
      <h5
        class="m-0 dkgray mr-auto font-weight-normal"
        [ngClass]="{ gray: linkedFiles.length + computerFiles.length < 1 }"
      >
        <b>{{ linkedFiles.length + computerFiles.length }}</b>
        File<span *ngIf="linkedFiles.length + computerFiles.length > 1">s</span>
      </h5>
      <button mat-button *ngIf="!processing" (click)="close()" class="gray ml-3">Cancel</button>
      <button
        *ngIf="!processing"
        mat-flat-button
        color="success"
        class="ml-3 px-4"
        (click)="submit()"
        [disabled]="
          isCorrupt ||
          isEncrypted ||
          !isValid ||
          !allFilesAreValidSize ||
          (linkedFiles?.length || 0 + computerFiles?.length || 0) < 1
        "
      >
        Upload Files
      </button>
      <div
        *ngIf="processing"
        style="height: 2em; float: right; display: inline-table; padding: 5px 10px; border-radius: 5px; opacity: 0.7"
      >
        <div style="float: left; margin-right: 1.6em; width: 2em; height: 2em" class="spinner-grow" role="status">
          <span class="sr-only">Processing...</span>
        </div>
        <span style="vertical-align: sub; font-size: 1.5em"
          >{{ processingStatus }}.. {{ processingPercentage ? processingPercentage + '%' : '' }}</span
        >
      </div>
    </div>
  </mat-dialog-actions>
</div>
