import { every, has } from 'lodash';

export class FieldChecker {
  public static hasAllFields(object: any, fields: string[]): { result: boolean; missingFields: string[] } {
    const missingFields = [];
    const result = every(fields, (field) => {
      const bracketPosition = field.indexOf('{');
      if (bracketPosition > 0) {
        // TODO: handle if field contains {
        const parentField = field.substring(0, bracketPosition);
        const childFieldString = field.substring(bracketPosition + 1, field.length - 1);
        const childFields = [];
        let bracketCount = 0;
        const split = childFieldString.split(',');
        split.forEach((c) => {
          if (bracketCount <= 0) {
            childFields.push(c);
          } else {
            childFields[childFields.length - 1] = childFields[childFields.length - 1].concat(`,${c}`);
          }
          const openCount = c.split('{').length - 1;
          const closeCount = c.split('}').length - 1;
          bracketCount = bracketCount + openCount - closeCount;
        });
        const objectLength = object[parentField]?.length;
        if (objectLength === 0) {
          return true;
        }
        const recursiveObject = objectLength > 0 ? object[parentField][0] : object[parentField];
        const recursiveCheck = FieldChecker.hasAllFields(recursiveObject, childFields);
        if (recursiveCheck.result) {
          return true;
        } else {
          for (const m of recursiveCheck.missingFields) {
            missingFields.push(`${parentField}.${m}`);
          }
          return false;
        }
      } else if (field.includes('.')) {
        // TODO: handle if field contains .
        return true;
      } else if (object === null || has(object, field)) {
        return true;
      } else {
        missingFields.push(field);
        return false;
      }
    });
    return { result, missingFields };
  }
}
