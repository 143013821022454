<div class="view-task-dialog">
  <div class="d-flex full-width align-items-center mb-3 mt-2">
    <div class="d-flex align-items-center text-truncate mr-5">
      <span class="taskID d-flex align-items-center ltblue m-0 text-truncate" *ngIf="!hasErrorLoadingTask">
        <mat-icon class="ltblue mr-2">assignment_turned_in</mat-icon>
        <a
          class="pointer hover-u ltblue font-weight-semibold text-truncate"
          [routerLink]="
            taskData?.project_id ? ['/projects/' + taskData?.project_id] : ['/purchasing/arfs/' + taskData?.arf?.id]
          "
          (click)="closeDialog($event)"
        >
          {{ code }}
          {{ taskData?.project?.building_code || taskData?.project?.floor_code ? '|' : '' }}
          {{ taskData?.project?.building_code || ''
          }}{{ (taskData?.project?.building_code && taskData?.project?.floor_code && '-') || ''
          }}{{ taskData?.project?.floor_code || '' }} |
          {{ title }}
        </a>
      </span>
    </div>
    <div class="d-flex align-items-center ml-auto" *ngIf="hasErrorLoadingTask">
      <button
        mat-icon-button
        class="gray ml-1"
        matTooltip="Close"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        mat-dialog-close
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center ml-auto" *ngIf="!hasErrorLoadingTask">
      <button
        mat-button
        color="accent"
        type="button"
        class="lh-24 px-3 ml-4"
        (click)="followTask()"
        *ngIf="!isFollowing() && isWorkspaceStaff"
      >
        Follow
      </button>
      <button
        mat-button
        color="accent"
        type="button"
        class="lh-24 px-2 ml-4"
        (click)="unfollowTask()"
        *ngIf="isFollowing() && isWorkspaceStaff"
      >
        Unfollow
      </button>
      <div
        *ngIf="(taskFollowers$ | async)?.length > 0"
        [matMenuTriggerFor]="follower_menu"
        class="pointer mr-3 text-nowrap ml-5"
      >
        <app-profile-thumbnail
          [width]="30"
          [height]="30"
          [userId]="follower.id"
          class="follower"
          *ngFor="let follower of taskFollowers$ | async | slice: 0:4"
        ></app-profile-thumbnail>
        <h5
          class="font-weight-semibold d-inline-block align-middle gray m-0 ml-1"
          *ngIf="(taskFollowers$ | async)?.length > 4"
        >
          +{{ (taskFollowers$ | async)?.length - 4 }}
        </h5>
      </div>
      <mat-menu #follower_menu="matMenu" xPosition="before">
        <button
          mat-menu-item
          color="primary"
          (click)="openUserProfilePreview(follower.id)"
          *ngFor="let follower of taskFollowers$ | async"
        >
          <div class="menu-user-avatar d-inline-block align-top">
            <img [src]="getProfileThumbnailUrl(follower.id)" />
          </div>
          &nbsp;
          <span class="pointer"> {{ follower.first_name }} {{ follower.last_name }} </span>
        </button>
        <!-- <button mat-menu-item color="primary" (click)="followTask()" *ngIf="!isFollowing() && isWorkspaceStaff">
            <mat-icon class="ltblue">person_add</mat-icon>
            <span class="ltblue font-weight-semibold"> Follow Task </span>
          </button>
          <button mat-menu-item color="primary" (click)="unfollowTask()" *ngIf="isFollowing() && isWorkspaceStaff">
            <mat-icon class="ltblue">person_remove</mat-icon>
            <span class="ltblue font-weight-semibold"> Unfollow Task </span>
          </button> -->
        <button mat-menu-item color="primary" *ngIf="isWorkspaceStaff" (click)="updateFollowerToTask()">
          <mat-icon>group_add</mat-icon>
          <span>Manage Followers</span>
        </button>
      </mat-menu>
      <button
        [matMenuTriggerFor]="complete_menu"
        mat-icon-button
        color="primary"
        matTooltip="More"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        class="ml-1"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #complete_menu="matMenu" xPosition="before">
        <button (click)="openAddReminderDialog()" mat-menu-item color="primary" *ngIf="isWorkspaceStaff">
          <mat-icon>add_alert</mat-icon>
          <span> Create Reminder </span>
        </button>
        <button
          (click)="remindTask()"
          mat-menu-item
          color="primary"
          *ngIf="isWorkspaceStaff && taskData.assigned_user_id && taskData.assigned_user_id !== currentUser.id"
        >
          <mat-icon>notifications_active</mat-icon>
          <span> Remind Assignee </span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item color="primary" (click)="createMeeting()" *ngIf="isWorkspaceStaff">
          <mat-icon>event</mat-icon>
          <span> Create Meeting </span>
        </button>
        <button mat-menu-item color="primary" (click)="createMeetingAgendaFromTask(taskData)" *ngIf="isWorkspaceStaff">
          <mat-icon>event_notes</mat-icon>
          <span> Add to Meeting Agenda </span>
        </button>
        <mat-divider></mat-divider>
        <button
          (click)="changeTaskStatus(taskStatus.OnHold)"
          *ngIf="isWorkspaceStaff && taskData && taskData.status_id !== taskStatus.OnHold"
          mat-menu-item
          color="primary"
        >
          <mat-icon>outlined_flag</mat-icon>
          <span> Place on Hold </span>
        </button>
        <button
          (click)="changeTaskStatus(1)"
          *ngIf="isWorkspaceStaff && taskData && taskData.status_id === taskStatus.OnHold"
          mat-menu-item
          color="primary"
        >
          <mat-icon>outlined_flag</mat-icon>
          <span> Remove Hold </span>
        </button>
        <button
          (click)="convertToLinkedWOTask(taskData)"
          *ngIf="
            taskData.status_id !== taskStatus.Complete &&
            isWorkspaceStaff &&
            taskData.can_delete &&
            !isTaskLocked &&
            !taskData.accessory_data
          "
          mat-menu-item
          color="primary"
        >
          <mat-icon>description</mat-icon>
          <span> Convert to Work Order </span>
        </button>
        <button
          (click)="convertTaskClicked()"
          mat-menu-item
          [disabled]="isTaskLocked"
          *ngIf="isWorkspaceStaff && this.reviewIsEditable"
        >
          <mat-icon>library_add_check</mat-icon>
          <span>
            {{ hasReview() ? 'Edit Review' : 'Add Review' }}
          </span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item color="primary" *ngIf="isWorkspaceStaff" (click)="updateFollowerToTask()">
          <mat-icon>group_add</mat-icon>
          <span> Manage Followers </span>
        </button>
        <!-- <button
              (click)="deactivateTask()"
              mat-menu-item
              *ngIf="isWorkspaceStaff"
              [disabled]="isTaskLocked"
            >
              <mat-icon>delete</mat-icon>
              <span>
                Delete Task
              </span>
            </button> -->
      </mat-menu>
      <button
        mat-icon-button
        class="gray ml-1"
        matTooltip="Close"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        mat-dialog-close
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="row mt-3 dkblue mb-3 justify-content-center align-items-center"
    style="width: 880px; height: 500px"
    *ngIf="hasErrorLoadingTask"
  >
    <div
      data-test-id="page-not-found"
      id="page-not-found"
      class="page-not-found d-flex flex-column justify-content-center align-items-center"
    >
      <h1 class="dkblue mb-3 justify-content-center align-items-center">Task no longer available</h1>
      <h4 class="dkgray font-weight-normal mb-5">This task may have been deleted or reassigned.</h4>
      <h5>
        <a class="pointer hover-u ltblue font-weight-normal" (click)="closeDialog($event)">Close</a>
      </h5>
    </div>
  </div>
  <div class="row mt-3" style="width: 880px" *ngIf="!hasErrorLoadingTask">
    <div id="taskDetails" class="col-6 border-right border-gray pr-4">
      <div class="row">
        <div class="col">
          <div *ngIf="isTaskLocked" class="p-2 d-flex align-items-center bg-gray white mb-4 br-5 d-flex">
            <mat-icon class="mr-1" style="font-size: 20px; height: 20px"> lock </mat-icon>
            <p class="white m-0 font-weight-semibold">This task is locked and awaiting action</p>
          </div>
          <span class="taskID d-flex align-items-center dkblue m-0 mr-4 mb-4">
            <mat-icon class="green mr-2">check_circle</mat-icon>
            <a
              class="ltblue font-weight-semibold pointer hover-u"
              [routerLink]="
                taskData.project_id
                  ? ['/projects/' + taskData.project_id + '/tasks/' + taskData.id]
                  : ['/task-list/' + taskData.id]
              "
              (click)="closeDialog($event)"
            >
              {{ taskData.code }}
            </a>
          </span>
          <h2
            (click)="editTitle()"
            *ngIf="!showTitleInput"
            class="task-title ease dkblue margin-0 font-weight-semibold"
          >
            {{ taskData.title }}
          </h2>
          <div id="titleInput" *ngIf="showTitleInput">
            <mat-form-field appearance="outline" class="full-width">
              <textarea
                matInput
                rows="2"
                (focusout)="saveTaskTitle(taskTitleEdit)"
                (keydown.enter)="$event.preventDefault(); saveTaskTitle(taskTitleEdit)"
                #taskTitleEdit
                value="{{ taskData.title }}"
                placeholder="Task Title"
              ></textarea>
            </mat-form-field>
            <span class="save-buttons">
              <button (click)="saveTaskTitle(taskTitleEdit)" class="save-btn-icon mr-2">
                <mat-icon>check</mat-icon>
              </button>
              <button (click)="showTitleInput = false" class="cancel-btn-icon">
                <mat-icon>clear</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center">
        <mat-icon class="red">flag</mat-icon>
        <h5 class="mb-0 red">Task is on Hold</h5>
      </div> -->
      <div class="row mb-4 mt-4">
        <div class="col-6 pr-3">
          <h6 class="font-weight-semibold dkblue m-0 mb-2">Assigned to</h6>
          <app-assign-user-button [task]="taskData" (userSelected)="assignUser($event)"></app-assign-user-button>
        </div>
        <div class="col-6 d-flex flex-column pl-3">
          <h6 class="font-weight-semibold dkblue m-0 mb-2">Due Date</h6>
          <button matRipple (click)="dueDatePicker.open()" class="m-portlet m-0 p-0 full-width text-left">
            <div class="m-portlet__body p-2 item item-last d-flex align-items-center">
              <button disabled mat-icon-button color="primary" class="mr-2">
                <!-- SHOW OVERLAY ICON HERE IF OVERDUE -->
                <mat-icon
                  [ngClass]="{ dkgray: taskData.due_date, ltgray: !taskData.due_date }"
                  style="margin-top: 6px; font-size: 30px; height: 30px; width: 30px"
                >
                  event
                </mat-icon>
              </button>
              <p class="m-0 d-flex flex-column lh-18" *ngIf="taskData.due_date">
                <span class="dkblue font-weight-semibold">
                  {{ taskData.due_date | date: 'EEEE' }}
                </span>
                <span class="dkgray font-weight-normal">
                  {{ taskData.due_date | date: 'MMM d y' }}
                </span>
              </p>
              <p class="m-0 d-flex flex-column lh-18" *ngIf="!taskData.due_date">
                <span class="gray font-weight-semibold"> Set Due Date </span>
              </p>
            </div>
          </button>
          <input
            matInput
            [matDatepicker]="dueDatePicker"
            [(ngModel)]="taskData.due_date"
            autocomplete="off"
            style="visibility: hidden; width: 0; height: 0"
            (ngModelChange)="changeTaskDueDate(taskData.due_date)"
          />
          <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        </div>
        <div class="col-12">
          <div class="d-flex mt-2">
            <!-- LAST REMINDER SENT TO ASSIGNEE TODAY, YESTERDAY, OR MMM DD -->
            <p class="dkgray opacity7 mb-0 font-italic" *ngIf="taskData?.task_reminder_datetime">
              <small
                >{{ taskData.task_reminder_sender.first_name }} {{ taskData.task_reminder_sender.last_name }} sent a
                reminder on {{ taskData.task_reminder_datetime | date: 'EEEE, MMM d, yyyy' }}</small
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <h6 class="font-weight-semibold dkblue mb-2">Description</h6>
          <h6
            (click)="editDescription(descriptionEditor)"
            [hidden]="showDescriptionInput"
            style="white-space: pre-wrap"
            [innerHTML]="taskDescriptionText || 'Add a Description...'"
            class="dkblue task-description ease font-weight-normal pointer"
            appTextCollapseExpand
            maxHeight="105"
            (heightExceededEvent)="showCollapseDescriptionViewButton = $event"
            [showMore]="!collapseDescriptionView"
          ></h6>
          <p
            id="collapse-detail-view-button"
            class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2"
            *ngIf="showCollapseDescriptionViewButton === true && !showDescriptionInput"
            (click)="collapseDescriptionView = !collapseDescriptionView"
          >
            <i class="fas {{ collapseDescriptionView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
            {{ collapseDescriptionView === true ? 'Show More' : 'Show Less' }}
          </p>
          <div id="taskDescriptionInput" [hidden]="!showDescriptionInput">
            <app-editor #descriptionEditor placeholder="What do you need to clarify?"></app-editor>
            <span class="position-absolute save-buttons">
              <button
                *ngIf="descriptionEditor?.content?.value"
                class="save-btn-icon mr-2"
                (click)="saveTaskDescription(descriptionEditor)"
              >
                <mat-icon>check</mat-icon>
              </button>
              <button (click)="disableMakeDescription()" class="cancel-btn-icon">
                <mat-icon>clear</mat-icon>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          taskData.accessory_data &&
          accessoryData?.isReviewItem &&
          accessoryData?.type !== TaskAccessoryType.Invoice &&
          accessoryData?.type !== TaskAccessoryType.Submittals &&
          accessoryData?.type !== TaskAccessoryType.BidContract &&
          accessoryData?.type !== TaskAccessoryType.ProjectTimeline
        "
        class="mb-3 full-width"
      >
        <app-task-accessory
          *ngIf="taskData?.project_id"
          class="d-inline-block align-middle"
          [task]="taskData"
          [data]="taskData.accessory_data"
          (taskChanged)="emitReviewChange(taskData)"
        ></app-task-accessory>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div *ngIf="isApprovalTask()">
    <div *ngIf="isTaskApprovalStatus(fileApprovalStatus.AwaitingApproval)">
      <button *ngIf="taskData.assigned_user_id !== myUserId && !canReview()" [disabled]="true" mat-button class="full-width">
        <span class="dkgray font-italic">
          Awaiting Approval..
        </span>
      </button>
    </div>
    <div *ngIf="isTaskApprovalStatus(fileApprovalStatus.AwaitingUpload)">
      <button *ngIf="taskData.assigned_user_id === myUserId" (click)="openUploadReviewFilesDialog()"
        mat-flat-button color="success" class="full-width">
        <span>
          Upload Files
        </span>
      </button>
      <button *ngIf="taskData.assigned_user_id !== myUserId" [disabled]="true" mat-button class="full-width">
        <span class="dkgray font-italic">
          Awaiting Files..
        </span>
      </button>
    </div>
  </div> -->
          <div class="d-flex flex-column dkred mb-3 br-10 bg-shade-red p-3" *ngIf="readyToSendBidContractEmail">
            <div class="d-flex align-items-start">
              <mat-icon class="mr-3">error</mat-icon>
              <p class="mb-2">
                This contract MUST be sent to the supplier email before this task can be marked complete.
              </p>
            </div>
            <div class="row align-items-center">
              <div class="col-auto">
                <a
                  href="mailto:{{ companyContact?.email }}"
                  class="ml-4 pl-4 font-weight-semibold accent"
                  (click)="downloadContract()"
                >
                  Download Contract & Send Email
                  <i class="fas fa-arrow-right ml-1"></i>
                </a>
              </div>
              <div class="col d-flex justify-content-end">
                <mat-spinner color="accent" diameter="20" *ngIf="downloadingContract" class="m-0"></mat-spinner>
              </div>
            </div>
          </div>
          <div class="pb-4">
            <button
              matRipple
              (click)="changeTaskStatus(taskStatus.Open)"
              *ngIf="taskData.status_id === taskStatus.Complete && !isInvoiceReview"
              matTooltip="Mark Incomplete"
              matTooltipPosition="above"
              class="bg-gray pointer white font-weight-semibold task-status-main ease"
              [matTooltipShowDelay]="300"
              [disabled]="isTaskLocked || !approvedUser || !approvedReviewUser"
            >
              Task Complete
            </button>

            <button
              matRipple
              *ngIf="taskData.status_id === taskStatus.OnHold && !hasReview()"
              class="font-weight-semibold white bg-red task-status-main ease"
              [disabled]="true"
            >
              On Hold
            </button>

            <button
              matRipple
              *ngIf="taskData.status_id === taskStatus.Pending && !isWorkspaceStaff"
              (click)="completeTaskClicked()"
              class="task-status-disabled font-weight-semibold ease"
              [disabled]="true"
            >
              Pending Confirmation
            </button>

            <button
              matRipple
              *ngIf="taskData.status_id === taskStatus.Pending && isWorkspaceStaff"
              (click)="completeTaskClicked()"
              class="task-status-main font-weight-semibold white bg-green pointer ease"
              [disabled]="isTaskLocked || !approvedReviewUser"
            >
              Confirm Completion
            </button>

            <button
              matRipple
              *ngIf="taskData.status_id === taskStatus.Open && approvedReviewUser"
              (click)="completeTaskClicked()"
              class="task-status-main font-weight-semibold white bg-green pointer ease"
              [disabled]="isTaskLocked || !approvedReviewUser || !approvedUser"
            >
              Complete Task
            </button>

            <button
              *ngIf="
                (taskData.status_id !== taskStatus.Open &&
                  taskData.status_id !== taskStatus.OnHold &&
                  !isInvoiceReview) ||
                approvedReviewUser
              "
              matRipple
              [matMenuTriggerFor]="task_status_menu"
              class="white task-status-drop pointer ease mr-4"
              [disabled]="isTaskLocked || !approvedReviewUser || !approvedUser"
              [ngClass]="{
                'bg-red': taskData.status_id === taskStatus.OnHold,
                'bg-green': taskData.status_id === taskStatus.Open || taskData.status_id === taskStatus.Pending,
                'bg-gray': taskData.status_id === taskStatus.Complete
              }"
            >
              <i class="material-icons">arrow_drop_down</i>
            </button>
            <button
              (click)="convertTaskClicked()"
              [disabled]="isTaskLocked"
              *ngIf="
                isWorkspaceStaff &&
                this.reviewIsEditable &&
                !taskData.accessory_data &&
                taskData.status_id === taskStatus.Open
              "
              mat-stroked-button
              color="accent"
              class="align-middle"
            >
              Add Review
            </button>
            <app-submittal-buttons
              *ngIf="taskData && accessoryData?.type === TaskAccessoryType.Submittals"
              [task]="taskData"
              [accessoryData]="accessoryData"
            >
            </app-submittal-buttons>
            <button
              *ngIf="
                accessoryData?.type === TaskAccessoryType.Arf && reviewIsComplete && !taskData?.is_locked && canFinalize
              "
              mat-flat-button
              color="primary"
              class="align-middle"
              (click)="finalizePurchase()"
              [disabled]="!isAdmin"
            >
              Finalize Purchase
            </button>
            <mat-menu #task_status_menu="matMenu" xPosition="before">
              <button
                (click)="changeTaskStatus(taskStatus.Open)"
                *ngIf="taskData.status_id !== taskStatus.Open && approvedUser"
                mat-menu-item
                color="primary"
                [disabled]="!approvedReviewUser"
              >
                <mat-icon>radio_button_unchecked</mat-icon>
                <span> Mark Incomplete </span>
              </button>
              <button
                (click)="completeTaskClicked()"
                *ngIf="taskData.status_id !== taskStatus.Complete && approvedUser"
                mat-menu-item
                color="primary"
                [disabled]="!approvedReviewUser"
              >
                <mat-icon>check_circle</mat-icon>
                <span> Mark Complete </span>
              </button>
              <button
                (click)="changeTaskStatus(taskStatus.OnHold)"
                *ngIf="taskData.status_id !== taskStatus.OnHold && approvedUser"
                mat-menu-item
                color="primary"
                [disabled]="!approvedReviewUser"
              >
                <mat-icon>flag</mat-icon>
                <span> Place on Hold </span>
              </button>
              <button
                (click)="changeTaskStatus(taskStatus.Open)"
                *ngIf="taskData.status_id === taskStatus.OnHold && approvedUser"
                mat-menu-item
                color="primary"
                [disabled]="!approvedReviewUser"
              >
                <mat-icon>outlined_flag</mat-icon>
                <span> Remove Hold </span>
              </button>
            </mat-menu>
          </div>
          <div class="full-width" *ngIf="taskData.accessory_data">
            <app-invoice-accessory-data [data]="taskData.accessory_data"></app-invoice-accessory-data>
          </div>
          <div *ngIf="taskData.accessory_data">
            <!-- <app-task-accessory class="d-inline-block align-middle" [task]="taskData" [data]="taskData.accessory_data"></app-task-accessory> -->
            <app-review-progress
              [task]="taskData"
              [data]="taskData.accessory_data"
              (taskChanged)="emitReviewChange(taskData)"
            ></app-review-progress>
          </div>
        </div>
      </div>
    </div>

    <div id="task-activity" class="col-6 pl-4">
      <div class="row">
        <div class="col d-flex flex-column">
          <div class="mb-2">
            <app-editor #noteEditor [class_height]="'short'" [placeholder]="'Leave a note...'"></app-editor>
            <div class="file-wrapper">
              <button (click)="openAttachFileDialog()" mat-button type="button" color="accent" class="lh-24 px-0">
                <mat-icon
                  matRipple
                  color="accent"
                  class="pointer"
                  matTooltip="Attach File"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  style="font-size: 20px; height: 20px"
                  >attach_file</mat-icon
                >
              </button>
            </div>
            <div *ngIf="noteEditor?.content?.value" class="d-flex flex-row-reverse mt-2">
              <button
                mat-flat-button
                color="success"
                [disabled]="!noteEditor?.content?.value"
                (click)="createNote(noteEditor)"
                class="lh-24 ml-2 px-2"
              >
                Add Note
              </button>
              <button mat-button type="button" (click)="clearNote(noteEditor)" class="gray lh-24 px-2">Cancel</button>
            </div>
          </div>
          <app-aidet class="mb-1" class="full-width"></app-aidet>
        </div>
      </div>
      <div id="activity" class="row mt-3">
        <div class="col">
          <div class="row" *ngIf="loadingActivity">
            <div class="col">
              <mat-spinner color="accent" [diameter]="36"></mat-spinner>
            </div>
          </div>
          <div class="row" *ngIf="hasActivity && !loadingActivity">
            <div class="col" style="max-height: 650px; overflow-y: auto">
              <app-task-view-activity
                *ngFor="let activity of allActivities$ | async | taskActivityFilter: activityFilter"
                [activityData]="activity"
                [projectId]="taskData.project_id"
              ></app-task-view-activity>
            </div>
          </div>
          <div class="row" *ngIf="!hasActivity && !loadingActivity">
            <div class="ease" style="width: 100%; padding-top: 2em; opacity: 0.1; text-align: center">
              <h1>No Activity</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
