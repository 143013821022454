import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { TroubleshootLoginDialogComponent } from 'src/app/components';
import { AuthService, SidenavService } from 'src/app/services';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss'],
})
export class DirectoryComponent implements OnInit, OnDestroy {
  routerSubscription;
  public isSidebarClosed: boolean;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  public showDesktop: boolean;
  public showIpad: boolean;
  public divWidth: number;
  private currentSubscription;
  selectedView;
  canReviewAccounts: boolean;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthService
  ) {
    this.isSidebarClosed = sidenavService.isSidenavClosed;
    // keep track of subscription so we can destroy it when the component is destroyed
    this.currentSubscription = sidenavService.sidenavVisibilityChange.subscribe((value) => {
      this.isSidebarClosed = value;
    });
  }

  ngOnInit(): void {
    this.selectedView = this.router.url;
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedView = this.router.url;
      }
    });

    // console.log(this.selectedView)
    const ps = new PerfectScrollbar(document.querySelector('#directory'));
    setTimeout(async () => {
      this.getDivWidth();
    });
    this.canReviewAccounts = this.authService.isAppAdmin || this.authService.currentUserIsOfWorkspaceRole(2, null);
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
    if (this.routerSubscription) this.routerSubscription?.unsubscribe();
  }

  selectionChange(event) {
    this.router.navigateByUrl(event.value);
  }

  onResize() {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    this.showDesktop = this.divWidth > 800;
    this.showIpad = !this.showDesktop;
  }

  public openLoginHelp() {
    this.dialog.open(TroubleshootLoginDialogComponent, {
      data: {},
      width: '400px',
    });
  }
}
