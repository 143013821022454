import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'formatMeetingTime',
})
export class FormatMeetingTimePipe implements PipeTransform {
  transform(time: number): any {
    if (time < 59 && time > 0) {
      return `${time} Min${time > 1 ? 's' : ''}`;
    } else if (time > 59) {
      return `${this.minToHr(time)} Hr${time > 119 ? 's' : ''}`;
    } else {
      return '';
    }
  }

  private minToHr(min: number) {
    return moment.utc(moment.duration(min, 'minutes').asMilliseconds()).format('H:mm');
  }
}
