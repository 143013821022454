import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as moment from 'moment';

import { environment } from 'src/environments/environment';

import { ApiFilterService, AuthService, HandleErrorService } from 'src/app/services';
import { APIFilter, Note, Reminder, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class RemindersService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService,
    private authService: AuthService
  ) {}

  host: string = environment.serviceHost;
  reminderUrl = `${this.host}/api/v1/reminders`;
  reminderPriorityUrl = `${this.host}/api/v1/reminder-priorities`;
  reminderStatusUrl = `${this.host}/api/v1/reminder-statuses`;
  reminderFields =
    'id,description,parent_type_id,parent_id,parent_name,status_id,status_name,reminder_datetime,due_datetime,priority_id,priority_name,note_count,created_datetime,created_by_id,created_by_first_name,created_by_last_name';
  noteUrl = `${this.host}/api/v1/notes`;
  noteFields = `created_by_id,created_by_first_name,created_by_last_name,created_datetime,message`;
  incompleteReminderCount: number;
  hasOverdueReminders = false;

  public reminderCountUpdateNeeded$ = new Subject<void>();

  getReminders(fields: string[], apiFilters?: APIFilter[], limit = 10000): Observable<Reminder[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.reminderUrl}?fields=${fields.join(',')}&limit=${limit}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.reminders;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getMyReminders(userId, sortProperty, sortDirection): Observable<Reminder[]> {
    return this.http
      .get(
        `${this.reminderUrl}?limit=10000&filter=created_by_id=${userId}&fields=${this.reminderFields}&sort=${sortProperty}&order=${sortDirection}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const reminders: Reminder[] = result.data.reminders;
          return reminders;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getReminderStatuses(): Observable<any[]> {
    return this.http.get(`${this.reminderStatusUrl}?limit=10000&fields=id,name`).pipe(
      map((result: ServiceResponse) => {
        const reminderStatuses: Reminder[] = result.data.reminder_statuses;
        return reminderStatuses;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getReminderPriorities(): Observable<any[]> {
    return this.http.get(`${this.reminderPriorityUrl}?limit=10000&fields=id,name`).pipe(
      map((result: ServiceResponse) => {
        const reminder_priorities: Reminder[] = result.data.reminder_priorities;
        return reminder_priorities;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createReminder(reminder: Reminder): Observable<Reminder> {
    const body = reminder;
    return this.http.post(`${this.reminderUrl}?fields=${this.reminderFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdReminder: Reminder = result.data.reminder;
        this.reminderCountUpdateNeeded$.next();
        return createdReminder;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateReminder(reminder: Reminder): Observable<Reminder> {
    const body = reminder;
    const reminderId = reminder.id;
    delete body.parent_type_id;
    delete body.parent_id;
    delete body.id;
    return this.http.put(`${this.reminderUrl}/${reminderId}?fields=${this.reminderFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedReminder: Reminder = result.data.reminder;
        this.reminderCountUpdateNeeded$.next();
        return updatedReminder;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deactivateReminder(reminderId: number): Observable<void> {
    return this.http.delete(`${this.reminderUrl}/${reminderId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getNotesByReminderId(reminderId: number, cursor?: string): Observable<any[]> {
    return this.http
      .get(
        `${this.noteUrl}?filter=parent_type_id=9,parent_id=${reminderId}&fields=${this.noteFields}&limit=1000${
          cursor ? `&cursor=${cursor}` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const resultToReturn: any = {};
          resultToReturn.notes = result.data.notes;
          resultToReturn.cursor = result.next;
          return resultToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createNoteOnReminder(note): Observable<Note> {
    note.parent_type_id = 9;
    const body = note;
    return this.http.post(`${this.noteUrl}?fields=${this.noteFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const noteToReturn: Note = result.data.note;
        return noteToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  async updateIncompleteReminderCount() {
    const currentUser = this.authService.getLoggedInUser();
    const incompleteReminderFilters: APIFilter[] = [
      { type: 'field', field: 'created_by_id', value: currentUser.id.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: '1' },
    ];
    const incompleteReminders = await this.getReminders(
      ['reminder_datetime'],
      incompleteReminderFilters,
      10000
    ).toPromise();
    this.incompleteReminderCount = incompleteReminders ? incompleteReminders.length || 0 : 0;
    this.hasOverdueReminders = !!incompleteReminders.find((r) => moment(r.reminder_datetime) < moment());
  }
}
