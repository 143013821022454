<div class="m-portlet__body item ease p-3 pointer" [routerLink]="['/requests/' + request.id]">
  <div class="row align-items-center">
    <div class="col">
      <button mat-icon-button class="ltblue mr-2">
        <mat-icon>assignment_late</mat-icon>
      </button>
      <h5 class="dkblue font-weight-semibold m-0 ml-1 d-inline-block align-middle">
        <span class="mr-2"> {{ request.building_name }} - {{ request.request_type_name }} </span>
        <br />
        <small class="gray">
          Request {{ request.code }}{{ request.floor_name ? ' • Floor ' : '' }}{{ request.floor_name
          }}{{ request.requester_company_name ? ' • ' : '' }}{{ request.requester_company_name }}
        </small>
      </h5>
    </div>
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold">
        {{ getDayTime() }}
      </h6>
    </div>
    <div class="col">
      <h6 class="m-0 ltblue font-weight-normal d-inline-block align-middle">
        {{ request.requester_first_name }} {{ request.requester_last_name }}
      </h6>
    </div>
    <div class="col">
      <h6 class="m-0 dkblue">
        {{ request.budget_name }}
      </h6>
    </div>
  </div>
</div>
