import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationRole, Order, WorkspaceType } from '../enums';
import { APIFilter, ArHistory, Company, ServiceResponse, User, UserRequest } from '../types';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar
  ) {}

  private host: string = environment.serviceHost;

  userUrl = `${this.host}/api/v1/users`;
  userRequestUrl = `${this.host}/api/v1/user-requests`;

  private userInviteCallbackUrl = `${this.host}/api/v1/user-invitations`;

  private userTypeUrl = `${this.host}/api/v1/user-types`;

  private userARHistoryUrl = `${this.host}/api/v1/ar-history`;

  private companyUrl = `${this.host}/api/v1/companies`;

  private debugUrl = `${this.host}/api/v1/debug/email`;

  public userFields =
    'id,email,first_name,last_name,company_id,company_name,title,manager_id,manager_first_name,manager_last_name,office_phone,cell_phone,building_id,department_id,department_name,floor_id,suite_id,user_type_name,user_type_id,is_guest_user,is_login_enabled,is_enabled';

  public userDisplayFields = ['id', 'first_name', 'last_name', 'title'];
  private userProfileImageUrls = new Map<number, BehaviorSubject<SafeResourceUrl>>(); // Cache Profile Image Urls To Prevent Database Hit (userId, url)

  private _filterString(apiFilters: APIFilter[], includeGuestUsers: boolean = false): string {
    if (!includeGuestUsers) {
      if (!apiFilters) {
        apiFilters = [];
      } else {
        apiFilters.push({ type: 'operator', value: 'AND' });
      }
      apiFilters.push({ type: 'field', field: 'is_guest_user', value: '0', match: 'exact' });
    }
    return this.apiFilterService.getFilterString(apiFilters);
  }

  public getUsersAndCursor(
    apiFilters: APIFilter[],
    fields: string[],
    includeGuestUsers: boolean = false,
    cursorPointer: string = null,
    limit: number = 100,
    sortField?: string,
    sortOrder?: Order
  ): Observable<{ cursor: string; users: User[] }> {
    const filterString = `${this._filterString(apiFilters, includeGuestUsers)}${sortField ? `&sort=${sortField}` : ''}${
      sortOrder ? `&order=${sortOrder}` : ''
    }&limit=${limit}`;

    const url = `${this.userUrl}?fields=${fields.join(',')}&${filterString}${
      cursorPointer ? `&cursor=${cursorPointer}` : ''
    }`;
    return this.http.get(url).pipe(
      map(
        ({ data: { users }, count, next: cursor }: ServiceResponse) => ({ count, cursor, users }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
    );
  }

  getUsers(
    apiFilters: APIFilter[],
    fields: string[],
    limit?: number,
    includeGuestUsers = false,
    sortField?: string,
    sortOrder?: Order
  ): Observable<User[]> {
    const filterString = this._filterString(apiFilters, includeGuestUsers);
    return this.http
      .get(
        `${this.userUrl}?fields=${fields.join(',')}&${filterString}${sortField ? `&sort=${sortField}` : ''}${
          sortOrder ? `&order=${sortOrder}` : ''
        }${limit ? `&limit=${limit}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;

          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUsersByCompany(companies: number[], fields: string[], is_enabled?: Boolean): Observable<User[]> {
    const companyFilter = companies.join('^');
    return this.http
      .get(
        `${this.userUrl}?fields=${fields.join(',')}&filter=company_id=${companyFilter}${
          is_enabled ? ',is_enabled=1' : ''
        }&limit=10000&sort=first_name,last_name`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUsersByDepartment(departments: number[], fields: string[]): Observable<User[]> {
    const departmentFilter = departments.join('^');
    return this.http
      .get(`${this.userUrl}?fields=${fields.join(',')}&filter=department_id=${departmentFilter}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUserById(userId: number, fields?: string[]): Observable<User> {
    return this.http
      .get(
        `${this.userUrl}/${userId}?fields=${
          fields
            ? fields.join(',')
            : 'email,first_name,last_name,company_id,department_id,company_name,user_type_id,title,is_login_enabled,user_type_id,is_enabled'
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const user: User = result.data.user[0];
          return user;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // sends an invite to the specified user, as well as creates a user invitation record on the backend
  inviteUser(userId: number, parent_id?: number, parent_type_id?: number): Observable<User> {
    const body = { parent_id, parent_type_id, manual_invite: 1 };
    return this.http.post(`${this.userUrl}/${userId}/invite`, body).pipe(
      map((result: ServiceResponse) => {
        return null;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the last recorded invite date for the given users
  // id: {invited_user_id: number, invited_user_first_name: string, invited_user_last_name: string, sending_user_first_name: string, sending_user_last_name: string, parent_id: number, parent_type_id: number, created_datetime: string}
  getLastInvites(userIds: number[]): Observable<{}> {
    return this.http
      .get(`${this.userInviteCallbackUrl}/last-invites?user_ids=${encodeURIComponent(userIds.join(','))}`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.lastInvites;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public sendInvites(users: User[], parentId: number, parentTypeId: number) {
    const unsuccessfulUsers = [];
    const promises = [];
    for (const user of users) {
      promises.push(
        this.inviteUser(user.id, parentId, parentTypeId)
          .toPromise()
          .then(null, () => {
            unsuccessfulUsers.push(user);
          })
      );
    }
    Promise.all(promises).then(() => {
      if (unsuccessfulUsers.length === 0) {
        this.snackBar.open(`User${users.length > 1 ? 's' : ''} Invited!`);
      } else {
        this.snackBar.open(
          `The following user${unsuccessfulUsers.length > 1 ? 's' : ''} could not be invited: ${unsuccessfulUsers
            .map((u) => `${u.first_name} ${u.last_name}`)
            .join(', ')}`
        );
      }
    });
  }

  // allows us to create an invite callback if we can't through the inviteUser function (i.e. if the id of the parent isn't returned)
  // no longer needed at the moment, just use the inviteUser modal but pass sendInvite = false
  // createUserInviteCallback(
  //   invitedUserId: number,
  //   currentUserId: number,
  //   parent_id: number,
  //   parent_type_id: number
  // ) {
  //   const body = {
  //     invited_user_id: invitedUserId,
  //     sending_user_id: currentUserId,
  //     parent_id,
  //     parent_type_id,
  //   };
  //   return this.http.post(`${this.userInviteCallbackUrl}`, body).pipe(
  //     map((result: ServiceResponse) => {
  //       return null;
  //     }),
  //     catchError((e) => this.handleErrorService.handleError(e))
  //   );
  // }

  // WARNING: This function is meant to not catch any errors and to instead allow the calling function to catch them
  getUserByIdSuppressed(userId: number, fields?: string[]): Observable<User> {
    return this.http
      .get(
        `${this.userUrl}/${userId}?fields=${
          fields
            ? fields.join(',')
            : 'email,first_name,last_name,company_id,department_id,company_name,user_type_id,title'
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const user: User = result.data.user[0];
          return user;
        })
      );
  }

  // gets all users whose ids are contained in userIds
  getUserByIds(
    userIds: number[],
    fields = [
      'building_id,cell_phone,company_id,company_name,department_id,department_name,email,first_name,floor_id,id,is_guest_user,is_login_enabled,last_name,manager_first_name,manager_id,manager_last_name,office_phone,suite_id,title,user_type_id,user_type_name,trades',
    ]
  ): Observable<User[]> {
    return this.http
      .get(
        `${this.userUrl}?fields=${(fields?.length && fields.join(',')) || this.userFields}&filter=id=${userIds.join(
          '^'
        )}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  searchUsers(apiFilters: APIFilter[], fields?: string[], sortField?: string, sortOrder?: Order): Observable<User[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    const userFields = fields ?? [
      'building',
      'building_id',
      'floor',
      'floor_id',
      'suite',
      'suite_id',
      'email',
      'user_type_id',
      'first_name',
      'last_name',
      'full_name',
      'company_id',
      'company_name',
      'department',
      'department_id',
      'department_name',
      'is_login_enabled',
    ];
    return this.http
      .get(
        `${this.userUrl}?fields=${userFields.join(',')}&${filterString}${sortField ? `&sort=${sortField}` : ''}${
          sortOrder ? `&order=${sortOrder}` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUserForConfirmation(email: string, hash: string): Observable<User> {
    return this.http.get(`${this.userUrl}/${email}/hash?hash=${hash}`).pipe(
      map((result: ServiceResponse) => {
        const user: User = result.data.user;
        return user;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  requestAccount(userRequest): Observable<UserRequest> {
    const body = userRequest;
    return this.http.post(this.userRequestUrl, body).pipe(
      map((result: ServiceResponse) => {
        const createdUserRequest: UserRequest = result.data['user request'];
        return createdUserRequest;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateUserRequest(userRequestId: number, userRequest: UserRequest) {
    return this.http.put(`${this.userRequestUrl}/${userRequestId}`, userRequest).pipe(
      map((result: ServiceResponse) => {
        const updatedUserRequest: UserRequest = result.data.user_request;
        return updatedUserRequest;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getUserRequests(fields: string[], apiFilters?: APIFilter[], limit?: number): Observable<UserRequest[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.userRequestUrl}?fields=${fields.join(',')}${limit ? `&limit=${limit}` : ''}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const userRequests: UserRequest[] = result.data.user_requests;
          return userRequests;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUserRequestById(userRequestId: number, fields: string[]): Observable<UserRequest> {
    return this.http.get(`${this.userRequestUrl}/${userRequestId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const userRequest: UserRequest = result.data['user request'][0];
        return userRequest;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createUser(user: User, fields?: string[]): Observable<User> {
    const body = user;
    return this.http.post(`${this.userUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdUser: User = result.data.user;
        return createdUser;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createGuestUser(user: User): Observable<User> {
    const body = user;
    body.is_guest_user = +true;
    return this.http.post(this.userUrl, body).pipe(
      map((result: ServiceResponse) => {
        const createdUser: User = result.data.user;
        return createdUser;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  confirmAccount(user: User, hash: string): Observable<void> {
    const body = {
      password: user.password,
    };
    return this.http.put(`${this.userUrl}/${user.email}/confirm-account?hash=${hash}`, body).pipe(
      map(() => {}),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  forgotPassword(email: string): Observable<{ message: string; link?: string }> {
    return this.http.put(`${this.userUrl}/${email}/request-password-reset`, null).pipe(
      map((response: ServiceResponse) => {
        return response.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  resetPassword(user: User, hash: string): Observable<void> {
    const body = {
      password: user.password,
    };
    return this.http.put(`${this.userUrl}/${user.email}/reset-password?hash=${hash}`, body).pipe(
      map(() => {}),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateUser(userId: number, user: User, fields?: string[]) {
    const body = user;
    return this.http.put(`${this.userUrl}/${userId}?${fields ? `fields=${fields.join(',')}` : ''}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedUser: User = result.data.user;
        return updatedUser;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateARPermissions(userId: number, body: ArHistory) {
    return this.http.put(`${this.userUrl}/${userId}/ar-permissions`, body).pipe(
      map((result: ServiceResponse) => {
        return result.data.history_entry_id;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getARHistory(userId: number) {
    return this.http
      .get(
        `${this.userARHistoryUrl}?filter=user_id=${userId}&fields=user,user_id,created_by,is_ar,created_datetime,files,note`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.ar_history;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUserTypes(): Observable<{ id: number; name: string }[]> {
    return this.http.get(`${this.userTypeUrl}`).pipe(
      map((result: ServiceResponse) => {
        return result.data.user_types;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getUsersByType(typeId: number) {
    return this.http
      .get(
        `${this.userUrl}?filter=user_type_id=${typeId}&fields=email,first_name,last_name,user_type_id,company_id,company_name,title,cell_phone`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUsersByRole(
    roleId: number,
    resourceId: number,
    fieldsArray?: string[],
    fieldsString?: string,
    allowDisabledUsers: boolean = false,
    selectedUserIds?: number[]
  ) {
    const defaultFields = [
      'email',
      'first_name',
      'last_name',
      'user_type_id',
      'company_id',
      'company_name',
      'title',
      'cell_phone',
      'is_login_enabled',
      'is_enabled',
    ];
    return this.http
      .get(
        `${this.userUrl}?filter=(role_id=${roleId},role_resource_id=${resourceId},is_enabled=1)${
          !allowDisabledUsers
            ? `,(is_enabled=1${selectedUserIds?.length > 0 ? `|id=${selectedUserIds.join('^')}` : ''})`
            : ''
        }&fields=${fieldsString || (fieldsArray || defaultFields).join(',')}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateCompany(companyId: number, company: Company) {
    return this.http.put(`${this.companyUrl}/${companyId}`, company).pipe(
      map((result: ServiceResponse) => {
        const updatedCompany: Company = result.data.company;
        return updatedCompany;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getProfilePictureUrl(userId: number) {
    return `${environment.serviceHost}/api/v1/users/${userId || 0}/profile-picture`;
  }

  public getProfileThumbnailUrl(userId: number) {
    return `${environment.serviceHost}/api/v1/users/${userId || 0}/profile-thumbnail`;
  }

  public updateProfilePicture(userId: number, file) {
    const formData = new FormData();
    formData.append('file', file, 'profile_picture.png');
    return this.http.put(`${this.userUrl}/${userId}/profile-picture`, formData).pipe(
      map((result: ServiceResponse) => {
        // const updatedFile: File = result.data.file;
        // return updatedFile;
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public resetProfilePhoto(userId: number) {
    return this.http.post(`${this.userUrl}/${userId}/profile-picture`, null);
  }

  /**
   * Returns an observable of SafeResourceUrl that is linked with the given userId. If no image is cached then it is fetched
   * @param userId UserId to get the thumbnail image of
   */
  public getCachedProfileThumbnailAsync(userId: number): Observable<SafeResourceUrl> {
    const needsFetch = !this.userProfileImageUrls.has(userId);
    if (needsFetch) {
      this.userProfileImageUrls.set(userId, new BehaviorSubject<SafeResourceUrl>(''));
      const getObservable = this.http.get(this.getProfileThumbnailUrl(userId), {
        responseType: 'blob',
      });
      // Subscribe to the request, on result we want to convert the Blob to an image/png type, as that is what it is, and cache the image
      getObservable.subscribe((result) => this.storeImage(userId, result.slice(0, result.size, 'image/png')));
    }
    return this.userProfileImageUrls.get(userId).asObservable();
  }

  testEmail() {
    return this.http.get(`${this.debugUrl}`).pipe(
      map((result: ServiceResponse) => {
        return null;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * Store image data from a blob into the cached profile images
   * @param userId Of the user
   * @param image Blob data of the image to be stored. This will be converted to base64
   */
  private storeImage(userId: number, image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const trustedImageData = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
        this.userProfileImageUrls.get(userId).next(trustedImageData);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getBSDUsers(workspaceType: WorkspaceType, selectedUserIds?: number[]) {
    let roleID;
    switch (workspaceType) {
      case WorkspaceType.OneCall:
        roleID = ApplicationRole.OneCallBSD;
        break;
      case WorkspaceType.OneCallAdmin:
        roleID = ApplicationRole.OneCallAdminBSD;
        break;
      case WorkspaceType.Uhat:
        roleID = ApplicationRole.UHATBSD;
        break;
      case WorkspaceType.UhatAdmin:
        roleID = ApplicationRole.UHATAdminBSD;
        break;
      default:
        break;
    }

    const defaultFields = [
      'id',
      'email',
      'first_name',
      'last_name',
      'user_type_id',
      'company_id',
      'company_name',
      'title',
      'cell_phone',
      'is_login_enabled',
      'is_enabled',
    ];
    return this.http
      .get(
        `${this.userUrl}?filter=role_id=${roleID}${
          selectedUserIds?.length > 0 ? `|id=${selectedUserIds.join('^')}` : ''
        },is_enabled=1&fields=${defaultFields.join(',')}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const users: User[] = result.data.users;
          return users;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
}
