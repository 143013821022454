import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { sumBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ApiFilterService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
} from 'src/app/services';
import {
  APIFilter,
  ArfProduct,
  ArfPurchaseType,
  Product,
  ProductFundingSourceType,
  ProductPurchaseType,
  ProjectProduct,
  ProjectTenant,
  Quote,
  QuoteItem,
  QuoteItemStatus,
  ServiceResponse,
} from 'src/app/types';
import { environment } from 'src/environments/environment';
import { TaskStatus } from '../enums';
import { ProjectTenantService } from '../workspaces/construction/services';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private apiFilterService: ApiFilterService,
    private fileService: FileService,
    private handleErrorService: HandleErrorService,
    private http: HttpClient,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    public projectTenantService: ProjectTenantService
  ) {}

  host: string = environment.serviceHost;
  disableProductEvent = new EventEmitter();
  finalizePurchaseEvent = new EventEmitter<ProjectTenant>();

  productsUrl = `${this.host}/api/v1/products`;
  productPurchaseTypesUrl = `${this.host}/api/v1/product-purchase-types`;
  productFundingSourceTypesUrl = `${this.host}/api/v1/product-funding-source-types`;
  arfProductsUrl = `${this.host}/api/v1/arf-products`;
  projectProductsUrl = `${this.host}/api/v1/project-products`;
  quotesUrl = `${this.host}/api/v1/quotes`;
  quoteItemsUrl = `${this.host}/api/v1/quote-items`;
  quoteItemStatusesUrl = `${this.host}/api/v1/quote-item-statuses`;
  arfPurchaseTypesUrl = `${this.host}/api/v1/arf-purchase-types`;

  public quoteFields: string[] = [
    'asset_tagged',
    'tag_asset_task_id',
    'arf_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data}',
    'arf_approval_task_id',
    'arf_saved_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data}',
    'arf_saved_approval_task_id',
    'arf_purchase_type',
    'purchase_task_id',
    'arf_revision',
    `company{id,name}`,
    'contact_id',
    `subtotal`,
    `tax`,
    'files',
    `quote_items{id,project_product{id,is_taxable,selected_quote_item_id,tenant_id},total_price}`, // don't use quoteItemFields here or we'll get a loop
  ];

  public quoteItemFields: string[] = [
    'project_product_id',
    'status_id',
    'unit_price',
    'total_price',
    `quote{company{name}}`,
    'is_awarded',
  ];

  public productFields = ['name', 'description', 'unit_price'];

  public projectProductFields: string[] = [
    `name`,
    `description`,
    `quantity`,
    `is_taxable`,
    `is_enabled`,
    `product{${this.productFields.join(',')}}`,
    `quote_items{${this.quoteItemFields.join(',')}}`,
    `selected_quote_item_id`,
    `selected_quote_item{quote_id,total_price}`,
  ];

  public arfProductFields: string[] = [
    'id',
    'name',
    'description',
    'quantity',
    'unit_price',
    'total_price',
    'product_id',
    'module_id',
    'sub_cost_code_budget_id',
    'sub_cost_code_budget{label,code,fiscal_year,cost_code{label,code}}',
  ];
  public tenantFields = [
    `project_products{${this.projectProductFields.join(',')}}`,
    `type_id`,
    `tenant_name`,
    `shipping_budget`,
    `tax_budget`,
    `quotes{${this.quoteFields}}`,
    'budget_tenant_approval_task{status_id}',
    'budget_approval_task{status_id}',
  ];

  public purchaseTypeFields = ['id', 'name', 'description'];

  getProducts(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<Product[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    fields = fields || this.productFields;
    return this.http
      .get(
        `${this.productsUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const products: Product[] = result.data.products;
          return products;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProductById(id, fields: string[]): Observable<Product> {
    return this.http.get(`${this.productsUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const products: Product[] = result.data.product;
        return products[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createProduct(product: Product): Observable<Product> {
    return this.http.post(this.productsUrl, product).pipe(
      map((result: ServiceResponse) => {
        const createdProduct: Product = result.data.product;
        return createdProduct;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProduct(productId: number, product: Product, fields?: string[]): Observable<Product> {
    return this.http
      .put(`${this.productsUrl}/${productId}?${fields ? `fields=${fields.join(',')}` : ''}`, product)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedProduct: Product = result.data.product;
          return updatedProduct;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProductPurchaseTypes(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProductPurchaseType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.productPurchaseTypesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const productPurchaseTypes: ProductPurchaseType[] = result.data.product_purchase_types;
          return productPurchaseTypes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProductFundingSourceTypes(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProductFundingSourceType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.productFundingSourceTypesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const productFundingSourceTypes: ProductFundingSourceType[] = result.data.product_funding_source_types;
          return productFundingSourceTypes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectProducts(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProjectProduct[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectProductsUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectProducts: ProjectProduct[] = result.data.project_products;
          return projectProducts;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectProductById(id, fields: string[]): Observable<ProjectProduct> {
    return this.http.get(`${this.projectProductsUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const projectProducts: ProjectProduct[] = result.data['project product'];
        return projectProducts[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createProjectProduct(projectProduct: ProjectProduct, fields?: string[]): Observable<ProjectProduct> {
    return this.http
      .post(`${this.projectProductsUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, projectProduct)
      .pipe(
        map((result: ServiceResponse) => {
          const createdProjectProduct: ProjectProduct = result.data['project product'];
          return createdProjectProduct;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateProjectProduct(
    projectProductId: number,
    projectProduct: ProjectProduct,
    fields?: string[]
  ): Observable<ProjectProduct> {
    return this.http
      .put(
        `${this.projectProductsUrl}/${projectProductId}?${fields ? `fields=${fields.join(',')}` : ''}`,
        projectProduct
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedProjectProduct: ProjectProduct = result.data['project product'];
          return updatedProjectProduct;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteArfProduct(arfProductId: number): Observable<ArfProduct> {
    return this.http.delete(`${this.arfProductsUrl}/${arfProductId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getArfProductById(id: number, fields: string[]): Observable<ArfProduct> {
    return this.http.get(`${this.arfProductsUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const arfProducts: ArfProduct[] = result.data['arf product'];
        return arfProducts[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createArfProduct(arfProduct: ArfProduct, fields?: string[]): Observable<ArfProduct> {
    return this.http
      .post(
        `${this.arfProductsUrl}?${
          fields ? `fields=${fields?.length ? fields.join(',') : this.arfProductFields.join(',')}` : ''
        }`,
        arfProduct
      )
      .pipe(
        map((result: ServiceResponse) => {
          const createdArfProduct: ArfProduct = result.data['arf product'];
          return createdArfProduct;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateArfProduct(arfProductId: number, arfProduct: ArfProduct, fields?: string[]): Observable<ArfProduct> {
    return this.http
      .put(`${this.arfProductsUrl}/${arfProductId}?${fields ? `fields=${fields.join(',')}` : ''}`, arfProduct)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedArfProduct: ArfProduct = result.data['arf product'];
          return updatedArfProduct;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteProjectProduct(projectProductId: number): Observable<void> {
    return this.http.delete(`${this.projectProductsUrl}/${projectProductId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getQuotes(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<Quote[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.quotesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const quotes: Quote[] = result.data.quotes;
          return quotes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getQuoteById(id, fields: string[]): Observable<Quote> {
    return this.http.get(`${this.quotesUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const quotes: Quote[] = result.data.quote;
        return quotes[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createQuote(quote: Quote): Observable<Quote> {
    return this.http.post(this.quotesUrl, quote).pipe(
      map((result: ServiceResponse) => {
        const createdQuote: Quote = result.data.quote;
        return createdQuote;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateQuote(quoteId: number, quote: Quote, fields?: string[]): Observable<Quote> {
    return this.http.put(`${this.quotesUrl}/${quoteId}?${fields ? `fields=${fields.join(',')}` : ''}`, quote).pipe(
      map((result: ServiceResponse) => {
        const updatedQuote: Quote = result.data.quote;
        return updatedQuote;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteQuote(quoteId: number): Observable<void> {
    return this.http.delete(`${this.quotesUrl}/${quoteId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // ----- None Api Quote Helper Functions
  public getQuoteAwardedSubtotal(tenant: ProjectTenant, quote: Quote) {
    let subtotal = 0;
    if (tenant && quote) {
      const selectedQuoteItems = (tenant.project_products || [])
        .filter((p) => p.is_enabled && p.selected_quote_item)
        .map((p) => p.selected_quote_item)
        .filter((i) => i.quote_id === quote.id);
      for (const quoteItem of selectedQuoteItems) {
        subtotal += quoteItem.total_price;
      }
    }
    return subtotal;
  }

  getSelectedQuoteTaxTotal(tenant: ProjectTenant, quote: Quote) {
    let totalTax = 0;
    if (quote?.quote_items?.length && tenant) {
      quote.quote_items.forEach((quoteItem) => {
        if (quoteItem?.project_product?.selected_quote_item_id === quoteItem.id) {
          totalTax += this.getQuoteItemTax(quoteItem, tenant, null, quote);
        }
      });
    }
    return totalTax;
  }

  public getSelectedQuotesTotal(tenant: ProjectTenant, quote: Quote) {
    const subtotal = this.getQuoteAwardedSubtotal(tenant, quote);
    const tax = this.getSelectedQuoteTaxTotal(tenant, quote);

    return subtotal + tax;
  }

  returnSelectedQuotes(tenant) {
    return (
      (tenant?.project_products &&
        (tenant?.quotes || []).filter(
          (quote) =>
            !!(tenant.project_products || []).find(
              (product) => product.is_enabled && product?.selected_quote_item?.quote_id === quote.id
            )
        )) ||
      []
    );
  }

  public getQuoteItemStatuses(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<QuoteItemStatus[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.quoteItemStatusesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const quoteItemStatuses: QuoteItemStatus[] = result.data.quote_item_statuses;
          return quoteItemStatuses;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getQuoteItems(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<QuoteItem[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.quoteItemsUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const quoteItems: QuoteItem[] = result.data.quote_items;
          return quoteItems;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getQuoteItemById(id, fields: string[]): Observable<QuoteItem> {
    return this.http.get(`${this.quoteItemsUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const quoteItems: QuoteItem[] = result.data['quote item'];
        return quoteItems[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createQuoteItem(quoteItem: QuoteItem): Observable<QuoteItem> {
    return this.http.post(this.quoteItemsUrl, quoteItem).pipe(
      map((result: ServiceResponse) => {
        const createdQuoteItem: QuoteItem = result.data['quote item'];
        return createdQuoteItem;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateQuoteItem(quoteItemId: number, quoteItem: QuoteItem, fields?: string[]): Observable<QuoteItem> {
    return this.http
      .put(`${this.quoteItemsUrl}/${quoteItemId}?${fields ? `fields=${fields.join(',')}` : ''}`, quoteItem)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedQuoteItem: QuoteItem = result.data['quote item'];
          return updatedQuoteItem;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteQuoteItem(quoteItemId: number): Observable<void> {
    return this.http.delete(`${this.quoteItemsUrl}/${quoteItemId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // ----- None Api helpers for Quote items
  public getQuoteItemTax(quoteItem: QuoteItem, tenant, projectProduct: ProjectProduct, quote: Quote = null) {
    if (projectProduct?.is_taxable || quoteItem?.project_product?.is_taxable) {
      let taxPercentage = 0;

      if (quoteItem?.id) {
        const foundQuote = quote || (tenant.quotes || []).find((q) => q.id === quoteItem?.quote_id);
        const taxableQuoteItems = (foundQuote?.quote_items || []).filter(
          (qi) => tenant.project_products.find((pp) => pp.id === qi.project_product_id)?.is_taxable
        );

        const taxableSubtotal = sumBy(taxableQuoteItems, 'total_price') || 0;
        if (taxableSubtotal) {
          taxPercentage = (+foundQuote?.tax || 0) / taxableSubtotal;
        }
      } else {
        taxPercentage = +tenant.tax_budget / 100;
      }

      return +quoteItem?.total_price * +taxPercentage;
    } else {
      return 0;
    }
  }

  // Arf Purchase Type Api calls
  getArfPurchaseTypes(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ArfPurchaseType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    fields = fields || this.purchaseTypeFields;

    return this.http
      .get(
        `${this.arfPurchaseTypesUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const arfPurchaseTypes: ArfPurchaseType[] = result.data.arf_purchase_types;
          return arfPurchaseTypes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  async getPurchaseData(task, accessoryData = null, idIsTenant = false, quote = null, tenant = null) {
    if (!accessoryData) {
      accessoryData = task?.accessory_data && JSON.parse(task.accessory_data);
    }

    if (quote && tenant) {
      quote.arf_approval_task = task;
      const quoteIndex = tenant.quotes.findIndex((q) => q.id === quote.id);
      if (quoteIndex !== -1) {
        tenant.quotes[quoteIndex].arf_approval_task = task;
      }
    } else {
      if (!idIsTenant && accessoryData?.parentId && !quote) {
        quote = await this.getQuoteById(accessoryData?.parentId, this.quoteFields).toPromise();
      }

      const tenantId = idIsTenant ? accessoryData?.parentId : quote?.quote_items[0]?.project_product?.tenant_id;
      tenant =
        (tenantId && (await this.projectTenantService.getTenantById(tenantId, this.tenantFields).toPromise())) || null;
    }

    return { quote, tenant };
  }

  async unlockArfTasks(idIsTenant, task, t = null, quote = null) {
    const { tenant } = await this.getPurchaseData(task, null, idIsTenant, t, quote);

    const selectedQuotes = this.returnSelectedQuotes(tenant) || [];
    if (
      !tenant?.budget_approval_task?.id ||
      tenant?.budget_approval_task?.status_id === TaskStatus.Complete ||
      tenant?.budget_tenant_approval_task?.status_id === TaskStatus.Complete
    ) {
      for (const q of selectedQuotes) {
        const taskData = {
          id: q.arf_approval_task_id,
          is_locked: 0,
        };
        if (taskData.id) {
          await this.projectService.updateTask(taskData).toPromise();
        }
      }
    }
  }
}
