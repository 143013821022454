import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { MeetingDashboardFilter } from 'src/app/workspaces/construction/models';
import { Meeting } from 'src/app/types';

@Pipe({
  name: 'meetingDashboardFilter',
})
export class MeetingDashboardFilterPipe implements PipeTransform {
  // determines if the due date is between startDate and endDate, inclusive with granularity of a day
  private static inDateRange(date, startDate, endDate): boolean {
    return moment(date).startOf('day').isBetween(startDate, endDate, null, '[]');
  }
  // determine whether the meeting happened recently
  private static isRecent(date, daysForRecent: number): boolean {
    return moment(date)
      .startOf('day')
      .isBetween(moment().startOf('day').subtract(daysForRecent, 'day'), moment().startOf('day'), null, '()');
  }
  // determine whether the meeting is happening today
  private static isToday(date): boolean {
    return moment(date).startOf('day').isSame(moment().startOf('day'), 'day');
  }

  transform(meetings: Meeting[], filter: MeetingDashboardFilter): any {
    // filter by recent or upcoming
    switch (filter.status) {
      case 'recent':
        meetings = meetings.filter((meeting) =>
          MeetingDashboardFilterPipe.isRecent(meeting.start_datetime, filter.daysForRecent)
        );
        break;
      case 'upcoming':
        meetings = meetings.filter((meeting) =>
          MeetingDashboardFilterPipe.inDateRange(meeting.start_datetime, filter.startDate, filter.endDate)
        );
        break;
      case 'today':
        meetings = meetings.filter((meeting) => MeetingDashboardFilterPipe.isToday(meeting.start_datetime));
        break;
      default:
        break;
    }

    // sort by start date
    meetings.sort((a, b) => moment(a.start_datetime).diff(moment(b.start_datetime)));

    return meetings;
  }
}
