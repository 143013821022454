<app-base-dialog [draggable]="false" [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div
  data-test-id="meeting-select-dialog"
  id="meeting-select-dialog"
  class="project-modal modal-wide meeting-select-dialog"
>
  <div class="row dialog-form">
    <div class="col mb-3">
      <mat-form-field appearance="outline" class="full-width">
        <input matInput [(ngModel)]="searchValue" (ngModelChange)="searchInputChange()" placeholder="Search Meetings" />
        <mat-icon *ngIf="searchValue" (click)="searchValue = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <button *ngIf="!showDateRange" (click)="showDateRange = true" mat-button type="button" color="accent">
        Search within a Date Range
      </button>
      <span *ngIf="showDateRange">
        <input
          matInput
          [(ngModel)]="startDate"
          (ngModelChange)="searchMeetings(searchValue)"
          placeholder="Start Date"
          (click)="startDatePicker.open()"
          [matDatepicker]="startDatePicker"
          autocomplete="off"
          autocomplete="off"
          style="visibility: hidden; width: 0"
        />
        <mat-datepicker #startDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        <button
          (click)="startDatePicker.open()"
          mat-stroked-button
          color="accent"
          class="bg-white font-weight-semibold"
        >
          <span *ngIf="!startDate"> Start Date </span>
          <span *ngIf="startDate">
            {{ startDate | date: 'MM/dd/yyyy' }}
          </span>
        </button>
        &nbsp;
        <h6 class="d-inline-block align-middle ltgray margin-0 font-weight-semibold">TO</h6>
        &nbsp;
        <input
          matInput
          [(ngModel)]="endDate"
          (ngModelChange)="searchMeetings(searchValue)"
          placeholder="End Date"
          (click)="endDatePicker.open()"
          [matDatepicker]="endDatePicker"
          autocomplete="off"
          autocomplete="off"
          style="visibility: hidden; width: 0"
        />
        <mat-datepicker #endDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
        <button (click)="endDatePicker.open()" mat-stroked-button color="accent" class="bg-white font-weight-semibold">
          <span *ngIf="!endDate"> End Date </span>
          <span *ngIf="endDate">
            {{ endDate | date: 'MM/dd/yyyy' }}
          </span>
        </button>
      </span>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="meeting-list" *ngIf="allMeetings && allMeetings.length > 0">
        <div
          *ngFor="let meeting of allMeetings"
          (click)="submit(meeting)"
          class="row align-items-center meeting-item p-2 pt-3 pb-3"
        >
          <div class="col-auto">
            <p class="m-0 dkblue font-weight-semibold">
              {{ meeting.title }}
            </p>
            <p class="m-0 dkgray">
              {{ meeting.location }}
            </p>
          </div>
          <div class="col text-right ltblue">
            <p class="m-0 dkblue">
              {{ meeting.start_datetime | date: 'EEEE, MMM d • h:mm a' }}
            </p>
            <p class="m-0 dkgray font-weight-normal">
              {{ meeting.workspace_name }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="allMeetings && allMeetings.length <= 0" class="no-items-panel">
        <div class="no-items-panel-content">
          <h1>No Meetings</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 align-items-center">
    <div class="col text-right">
      <button
        data-test-id="project-header-more-action-add-meeting-agenda-cancel-select-dialog-button"
        mat-button
        *ngIf="!processing"
        (click)="close()"
        class="ltgray mr-2"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
