import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AddSubmittalsDialogComponent,
  ArfPurchaseTypeDialogComponent,
  ArfReviewDialogComponent,
  CompanyDialogComponent,
  ConfirmationChoiceDialogComponent,
  ConfirmationDialogComponent,
  CostCodeHelpDialogComponent,
  CreateTaskDialogComponent,
  EditContractDialogComponent,
  EditStaffDialogComponent,
  FileAttachmentDialogComponent,
  FilePreviewDialogComponent,
  FileSelectModalComponent,
  HowToGuideDialogComponent,
  InviteUserDialogComponent,
  MeetingDialogComponent,
  NewInvoiceDialogComponent,
  NotificationDialogComponent,
  PasswordResetDialogComponent,
  ProductDialogComponent,
  ProductDialogData,
  ProjectDetailsDialogComponent,
  RequestReceiptDialogComponent,
  RequestReviewDialogComponent,
  SelectUserFromListDialogComponent,
  TaskReviewDialogComponent,
  TopicDialogComponent,
  TransferWorkOrderDialogComponent,
  UploadSubmittalsDialogComponent,
  UserProfilePreviewComponent,
  UserSearchComponent,
  UserSelectModalComponent,
  ViewInvoiceDialogComponent,
  ViewKeyControlsDialogComponent,
  ViewMeetingDialogComponent,
  ViewQuoteDialogComponent,
  ViewTaskDialogComponent,
  WorkOrderDialogComponent,
} from 'src/app/components';
import { ArfPurchaseTypeId, ProjectDetailsViews, ProjectStatus, TaskReviewStatus } from 'src/app/enums';
import {
  Arf,
  ArfCustodyChain,
  BidContract,
  Company,
  ConfirmationChoiceDialogInjectionData,
  ConfirmationDialogInjectionData,
  FileAttachmentDialogInjectionData,
  Invoice,
  LinkedWOTask,
  NotificationDialogInjectionData,
  Product,
  Quote,
  RequestReceiptDialogInjectionData,
  Task,
  TaskAccessoryData,
  TaskModalInjectionData,
  TransferWorkOrderDialogInjectionData,
  UhatFileReference,
  User,
  WorkOrder,
  Workspace,
} from 'src/app/types';
import {
  ChangeOrderModalComponent,
  ContractFilesDialogComponent,
  NewProjectDrawingDialogComponent,
  NewSolicitationDialogComponent,
  ProjectOverviewDialogComponent,
  ProjectScheduleContractDialogComponent,
  SelectItemsDialogComponent,
  ViewChangeOrderDialogComponent,
} from 'src/app/workspaces/construction/components';
import {
  ChangeOrder,
  NewSolicitationDialogInjectionData,
  ProjectConstruction,
  ProjectDrawing,
  ProposalRequest,
} from 'src/app/workspaces/construction/types';
import { ItemTypeDialogInjectionData } from 'src/app/workspaces/construction/types/item-type-dialog-injection-data';
import { AuthService } from './auth.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public openMeetingDialog = false;
  public meetingReceipt: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // -------------------------------------------------------------------------------------------
  // New Approval Process - Attempting to streamline process across the app (3/5/20)
  // -------------------------------------------------------------------------------------------

  async createReviewTask(data: TaskModalInjectionData) {
    // Open the create task modal with the given data
    this.openCreateTaskModal(data).subscribe((createdTask) => {
      if (!createdTask) {
        return;
      }
    });
  }

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private snackBar: MatSnackBar
  ) {}

  public openAddSubmittalsModal(bidPackage): Observable<number> {
    const config = {
      width: '740px',
      autoFocus: false,
      data: {
        bidPackage,
      },
    };
    const dialogRef = this.dialog.open(AddSubmittalsDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openArfPurchaseTypeModal(
    vendorName,
    purchaseType: ArfPurchaseTypeId = null,
    assetTagged = false,
    description: string = null,
    fiscalYear: number | string = null
  ): Observable<{}> {
    const config = {
      width: '740px',
      autoFocus: false,
      maxHeight: '95vh',
      data: {
        vendorName,
        purchaseType,
        assetTagged,
        description,
        fiscalYear,
      },
    };
    const dialogRef = this.dialog.open(ArfPurchaseTypeDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openArfReviewModal(project, quote, task, tenant): Observable<{}> {
    const config = {
      width: '1000px',
      autoFocus: false,
      data: {
        project,
        quote,
        task,
        tenant,
      },
    };
    const dialogRef = this.dialog.open(ArfReviewDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openCreateTaskModal(data?: TaskModalInjectionData): Observable<Task> {
    if (+this.projectService?.currentSelectedProject?.status_id === +ProjectStatus.CLOSED) {
      const config = {
        data: {
          titleBarText: 'Create Task',
          headerText: 'Reactivate Project',
          descriptionText:
            'This project has been closed. If you have access to reactivate it, please do so before creating a task. <br/><br/>If you are a Supplier, please contact the project manager to reactivate the project.',
          hideConfirmationButton: true,
          cancelButtonText: 'Close',
        },
        width: '500px',
        autoFocus: false,
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
      return dialogRef.afterClosed();
    } else {
      const config: any = {
        width: '780px',
        disableClose: true,
      };
      if (data) {
        config.data = data;
      }
      const dialogRef = this.dialog.open(CreateTaskDialogComponent, config);
      return dialogRef.afterClosed();
    }
  }

  public openViewInvoiceModal(
    invoiceId: number,
    currentWorkspace?: number,
    isReviewItem?: boolean,
    isReviewAdmin?: boolean,
    isProjectInvoice = true
  ): Observable<{
    approvalStatus: TaskReviewStatus;
    approvalComment: string;
    invoiceStatusId: number;
    rejectOption: number;
  }> {
    const config = {
      width: '1000px',
      data: {
        invoiceId,
        currentWorkspace,
        isReviewItem,
        isReviewAdmin,
        isProjectInvoice,
      },
    };
    const dialogRef = this.dialog.open(ViewInvoiceDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openNewInvoiceModal(currentWorkspace, invoice?: Invoice, updateInvoice = true, arf: Arf = null) {
    const config = {
      disableClose: true,
      width: '1000px',
      data: {
        currentWorkspace,
        invoice,
        updateInvoice,
        arf,
      },
    };
    const dialogRef = this.dialog.open(NewInvoiceDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openConfirmationDialog(data: ConfirmationDialogInjectionData) {
    const config = {
      data,
      width: '500px',
      autoFocus: false,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openPasswordResetDialog(email) {
    const config = {
      data: {
        email,
      },
      width: '500px',
    };
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openConfirmationChoiceDialog(data: ConfirmationChoiceDialogInjectionData) {
    const config = {
      data,
      width: '480px',
      autoFocus: false,
    };
    const dialogRef = this.dialog.open(ConfirmationChoiceDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openNotificationDialog(data: NotificationDialogInjectionData) {
    const config = {
      data,
    };
    const dialogRef = this.dialog.open(NotificationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openChangeOrderModal(
    proposalRequest: ProposalRequest,
    currentUser: User,
    changeOrderFields,
    changeOrder: ChangeOrder = null
  ) {
    const config = {
      width: '600px',
      disableClose: true,
      data: {
        proposalRequest,
        currentUser,
        changeOrderFields,
        changeOrder,
      },
    };
    const dialogRef = this.dialog.open(ChangeOrderModalComponent, config);
    return dialogRef.afterClosed();
  }

  public openViewChangeOrderModal(
    changeOrderId: number,
    isReviewItem: boolean
  ): Observable<{
    approvalStatus: TaskReviewStatus;
    approvalComment: string;
    attachedFiles?: UhatFileReference[];
  }> {
    const config = {
      width: isReviewItem ? '700px' : '480px',
      disableClose: true,
      data: {
        changeOrderId,
        isReviewItem,
      },
    };
    const dialogRef = this.dialog.open(ViewChangeOrderDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openTaskReviewModal(
    task: Task,
    project?: ProjectConstruction
  ): Observable<{
    approvalStatus: TaskReviewStatus;
    approvalComment: string;
    attachedFiles?: UhatFileReference[];
  }> {
    const config = {
      width: '80%',
      height: '80%',
      autoFocus: false,
      disableClose: false,
      data: {
        task,
        project,
      },
    };
    const dialogRef = this.dialog.open(TaskReviewDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openViewTaskModal(taskId: number) {
    const config = {
      autoFocus: false,
      width: '900px',
      data: {
        taskId,
      },
    };

    const dialogRef = this.dialog.open(ViewTaskDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openTaskOverviewDialog(data: {
    status?: string;
    mine?: any;
    tasks: Task[];
    showUsers?: boolean;
    users?: any;
    selectedUser?: User;
    taskUserFilter?: any;
    dontFilterTasks?: boolean;
  }) {
    const config = {
      autoFocus: false,
      width: '740px',
      data,
    };

    const dialogRef = this.dialog.open(ProjectOverviewDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openTransferWorkOrderModal(data: TransferWorkOrderDialogInjectionData): any {
    const config = {
      width: '440px',
      disableClose: true,
      data,
    };
    const dialogRef = this.dialog.open(TransferWorkOrderDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openWorkOrderDialog(data: WorkOrder) {
    const config = {
      width: '780px',
      disableClose: true,
      autoFocus: false,
      data,
    };
    const dialogRef = this.dialog.open(WorkOrderDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openFileAttachmentDialog(data: FileAttachmentDialogInjectionData) {
    const config: any = {
      autoFocus: false,
      width: '570px',
      disableClose: true,
    };
    if (data) {
      config.data = data;
    }
    const dialogRef = this.dialog.open(FileAttachmentDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openFileSelectDialog(data: { title: string; projectId; filterFiles: UhatFileReference[]; maxFiles: number }) {
    const config: any = {
      data,
    };
    const dialogRef = this.dialog.open(FileSelectModalComponent, config);
    return dialogRef.afterClosed();
  }

  public openUploadSubmittalsModal(task: Task, accessoryData: TaskAccessoryData): Observable<{}> {
    const config = {
      width: '780px',
      disableClose: true,
      data: {
        task,
        accessoryData,
      },
    };
    const dialogRef = this.dialog.open(UploadSubmittalsDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openUserSearchModal(task: Task | LinkedWOTask): Observable<User> {
    const config = {
      width: '600px',
      disableClose: true,
      data: {
        task,
      },
    };
    const dialogRef = this.dialog.open(UserSearchComponent, config);
    return dialogRef.afterClosed();
  }

  public openNewProjectDrawingModal(): Observable<ProjectDrawing> {
    const config = {
      width: '480px',
      disableClose: true,
    };
    const dialogRef = this.dialog.open(NewProjectDrawingDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openCompanyDialog(data: {
    company?: Company;
    showTrades?: boolean;
    companies?: Company[];
    showExistingCompanies?: boolean;
    disableCompanySelection?: boolean;
  }) {
    const config = {
      width: '580px',
      data,
      disableClose: true,
    };
    const dialogRef = this.dialog.open(CompanyDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openRequestTaskReviewDialog(data: {
    task?: Task;
    accessoryData?: TaskAccessoryData;
    isReviewersLocked?: boolean;
  }): Observable<{ task?: Task; accessoryData?: TaskAccessoryData }> {
    const config = {
      width: '80%',
      maxHeight: '80vh',
      autoFocus: false,
      data,
    };
    const dialogRef = this.dialog.open(RequestReviewDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openPreviewFileDialog(file) {
    const config = {
      autoFocus: false,
      disableClose: false,
      height: '90vh',
      width: '80vw',
      data: {
        file,
      },
    };
    const dialogRef = this.dialog.open(FilePreviewDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openSelectFilesDialog(taskId: number, files?: UhatFileReference[]) {
    const config = {
      width: '480px',
      autoFocus: false,
      data: {
        taskId,
        files,
      },
    };
    const dialogRef = this.dialog.open(ContractFilesDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openEditBidContractDialog(contract: BidContract, accessoryData: TaskAccessoryData) {
    const config = {
      autoFocus: false,
      maxHeight: '80vh',
      width: '80vw',
      disableClose: true,
      data: { contract, accessoryData },
    };

    const dialogRef = this.dialog.open(EditContractDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openProductDialog(productDialogData: ProductDialogData = {}): Observable<Product> {
    const config = {
      width: '600px',
      autoFocus: false,
      data: productDialogData,
    };

    const dialogRef = this.dialog.open(ProductDialogComponent, config);
    return dialogRef.afterClosed() as Observable<Product>;
  }

  public openProjectScheduleContractDialog(project, task?) {
    const config = {
      autoFocus: false,
      height: '80vh',
      width: '80vw',
      data: {
        project,
        task,
      },
    };

    const dialogRef = this.dialog.open(ProjectScheduleContractDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openProjectDetailsDialog(projectId, view?: ProjectDetailsViews, closeOnSave = false) {
    const config = {
      width: '880px',
      height: '680px',
      autoFocus: false,
      data: {
        projectId,
        view,
        closeOnSave,
      },
    };

    const dialogRef = this.dialog.open(ProjectDetailsDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openSolicitationDialog(data: NewSolicitationDialogInjectionData) {
    const config = {
      width: '640px',
      data,
    };
    const dialogRef = this.dialog.open(NewSolicitationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openSelectItemDialog(data: ItemTypeDialogInjectionData) {
    const config = {
      width: '500px',
      data,
    };
    const dialogRef = this.dialog.open(SelectItemsDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openTopicDialog(topic = null) {
    const config = {
      data: {
        topic,
      },
      disableClose: true,
      width: '480px',
    };
    const dialogRef = this.dialog.open(TopicDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openSelectUserFromListDialog(users: number[], selectLimit?: number) {
    const config = {
      width: '480px',
      data: {
        users,
        selectLimit,
      },
    };
    const dialogRef = this.dialog.open(SelectUserFromListDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openInviteUserModal(data: { users: User[]; parentId?: number; parentTypeId?: number; sendInvite?: boolean }) {
    const config = {
      width: '640px',
      autoFocus: false,
      data,
    };
    if (this.authService.canInviteUser) {
      return this.dialog.open(InviteUserDialogComponent, config).afterClosed();
    } else {
      this.snackBar.open('Invalid permissions to invite user!');
    }
  }

  public openViewKeyControlsDialog(projectId: number, keyControlId?: number) {
    const dialogRef = this.dialog.open(ViewKeyControlsDialogComponent, {
      width: '1000px',
      autoFocus: false,
      disableClose: true,
      data: { projectId, keyControlId },
    });
    return dialogRef.afterClosed();
  }

  public openRequestReceiptDialog(data: RequestReceiptDialogInjectionData) {
    const config = {
      width: '400px',
      autoFocus: false,
      data,
    };
    const dialogRef = this.dialog.open(RequestReceiptDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openUserProfileDialog(userId: number) {
    const dialogRef = this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
      data: { userId },
    });
    return dialogRef.afterClosed();
  }

  public openUserSelectModal(data) {
    const dialogRef = this.dialog.open(UserSelectModalComponent, {
      data,
    });
    return dialogRef.afterClosed();
  }

  public openViewQuoteDialog(quote: Quote, showSelected = false) {
    const config = {
      width: '700px',
      autoFocus: false,
      data: {
        quote,
        showSelected,
      },
    };
    const dialogRef = this.dialog.open(ViewQuoteDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openEditStaffDialog(workspace: Workspace, custodyChain: ArfCustodyChain = null, viewOnly = false) {
    const config = {
      width: '480px',
      disableClose: true,
      data: {
        workspace,
        custodyChain,
        viewOnly,
      },
    };
    const dialogRef = this.dialog.open(EditStaffDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public openHowToGuideDialog(data) {
    return this.dialog
      .open(HowToGuideDialogComponent, {
        width: '700px',
        autoFocus: false,
        data,
      })
      .afterClosed();
  }

  public openCostCodeHelpDialog(data: { moduleId: number | null }) {
    return this.dialog
      .open(CostCodeHelpDialogComponent, {
        width: '900px',
        autoFocus: false,
        data,
      })
      .afterClosed();
  }

  public createMeeting(data) {
    this.openMeetingDialog = true;
    if (data) {
      const meetingDialog = this.dialog.open(MeetingDialogComponent, {
        width: '600px',
        data,
      });

      return meetingDialog.afterClosed().subscribe((createdMeeting) => {
        this.openMeetingDialog = false;
        if (createdMeeting) {
          this.meetingReceipt.next(true);
          return this.dialog
            .open(ViewMeetingDialogComponent, {
              width: '400px',
              data: createdMeeting,
            })
            .afterClosed()
            .subscribe(() => {
              // resets
              this.meetingReceipt.next(false);
            });
        }
      });
    }
  }
}
