import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { remove } from 'lodash';
import * as moment from 'moment';
import { DatepickerHeaderComponent, EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { RFIService } from 'src/app/workspaces/construction/services';
import { ProjectService } from '../../../../services';

@Component({
  selector: 'app-rfi-dialog',
  templateUrl: './rfi-dialog.component.html',
  styleUrls: ['./rfi-dialog.component.scss'],
})
export class RFIDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  constructor(
    public dialogRef: MatDialogRef<RFIDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rfi,
    private fb: FormBuilder,
    private rfiService: RFIService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private projectService: ProjectService
  ) {}

  action: string;
  customHeader = DatepickerHeaderComponent;

  rfiFormGroup: FormGroup = this.fb.group({
    description: [this.rfi.id ? this.rfi.description || '' : '', [Validators.required]],
    due_date: [this.rfi.id ? this.rfi.due_date || null : null, [Validators.required]],
    assigned_user_id: [this.rfi.id ? this.rfi.assigned_user_id || null : null, [Validators.required]],
  });

  get description() {
    return this.rfiFormGroup.get('description');
  }
  get due_date() {
    return this.rfiFormGroup.get('due_date');
  }
  get assigned_user_id() {
    return this.rfiFormGroup.get('assigned_user_id');
  }
  get question() {
    return this.rfiFormGroup.get('question');
  }

  roles = {
    8: { name: 'Architect', is_project_role: false },
    9: { name: 'Architect', is_project_role: true },
    10: { name: 'Construction Manager', is_project_role: false },
    11: { name: 'Project Manager', is_project_role: true },
    17: { name: 'Engineer', is_project_role: true },
  };

  ngOnInit() {
    this._activateEditor();
    this.action = this.rfi && this.rfi.id ? 'Edit' : 'New';
  }

  private _activateEditor() {
    this.rfiFormGroup.addControl('question', this._editor_component.content);
    this.question.setValue(this.rfi.id ? this.rfi.question || null : null);
  }

  removeFile(file) {
    remove(this.rfi.files, (f: any) => f.name === file.name);
  }

  submit(): void {
    this.rfiFormGroup.markAllAsTouched();
    if (this.rfiFormGroup.valid) {
      const rfiToReturn = {
        id: this.rfi.id,
        description: this.description.value,
        due_date: this.due_date.value ? moment(this.due_date.value).format('YYYY-MM-DD') : null,
        question: this.question.value,
        files: this.rfi.files,
        assigned_user_id: this.assigned_user_id.value || null,
      };
      this.dialogRef.close(rfiToReturn);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  // opens the upload modal, and passes the file on once it's uploaded, so it can be linked to the correct object
  openUploadModal() {
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          preSelectedTags: [{ id: 2 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          if (this.rfi.files) {
            resultData.forEach((f) => this.rfi.files.push(f));
          } else {
            this.rfi.files = resultData;
          }
        }
      });
  }
}
