import { ApprovalStatus } from 'src/app/enums';
import { ApprovalProcess } from 'src/app/models';
import { DateService, FileService, ModalService, ProjectTaskService, TaskActionsService } from 'src/app/services';
import { Task, TaskInfo } from 'src/app/types';

// This class exists to simplify the peb approval process for each tenant
// Since each peb (with its internal and tenant review) is specific for each tenant, we can give the tenant and let this class handle the rest

export class CbApprovalProcess {
  _tenant;
  _taskService;

  _staffReview: ApprovalProcess;
  _tenantReview: ApprovalProcess;

  constructor(
    tenant,
    taskService: ProjectTaskService,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService
  ) {
    this._taskService = taskService;
    this.loadData(tenant, modalService, fileService, dateService, taskActionsService).then();
  }

  // loads data for the approval tasks
  public async loadData(
    tenant,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService
  ) {
    // delete tenant.cb_approval_process;
    this._tenant = tenant;
    const internal_task = await this.getTaskData(
      this._tenant.cb_approval_task_id || this._tenant.saved_cb_approval_task_id
    );
    const tenant_task = await this.getTaskData(
      this._tenant.cb_tenant_approval_task_id || this._tenant.saved_cb_tenant_approval_task_id
    );

    this._staffReview = new ApprovalProcess(internal_task, modalService, fileService, dateService, taskActionsService);
    this._tenantReview = new ApprovalProcess(tenant_task, modalService, fileService, dateService, taskActionsService);
  }

  // generates text for the peb approval process status
  get statusText(): string {
    if (this._staffReview) {
      if (this._staffReview.approvalStatus === ApprovalStatus.NOT_STARTED) {
        return 'Submit for Internal Review to continue';
      } else if (this._staffReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL) {
        if (this._staffReview.needsReview) {
          return 'Pending Internal Review';
        } else if (this._staffReview.acceptedNotComplete) {
          return 'Approved. Mark internal task complete to continue';
        }
      } else if (this._staffReview.approvalStatus === ApprovalStatus.REJECTED) {
        return 'Internal review was rejected. Submit another version to proceed';
      } else if (this._staffReview.approvalStatus === ApprovalStatus.ACCEPTED) {
        if (this.needsTenantApproval && this._tenantReview.approvalStatus !== ApprovalStatus.ACCEPTED) {
          if (this._tenantReview.approvalStatus === ApprovalStatus.NOT_STARTED) {
            return 'Begin Tenant Review to continue';
          } else if (this._tenantReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL) {
            if (this._tenantReview.acceptedNotComplete) {
              return 'Approved. Mark tenant task complete to continue';
            } else {
              return 'Pending Tenant Review';
            }
          }
        } else {
          if (!this.isFinalized) {
            return 'Ready to be Finalized';
          } else {
            return 'Finalized';
          }
        }
      }
    }
    return '';
  }

  get hasStaffTask() {
    return this._staffReview && this._staffReview.hasTask;
  }

  get hasTenantTask() {
    return this._tenantReview && this._tenantReview.hasTask;
  }

  get staffApprovalIcon() {
    if (!this._staffReview) {
      return 'radio_button_unchecked';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get staffApprovalColor() {
    if (!this._staffReview) {
      return '#c5cfe4';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  get tenantApprovalIcon() {
    if (!this._tenantReview) {
      return 'radio_button_unchecked';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get tenantApprovalColor() {
    if (!this._tenantReview) {
      return '#c5cfe4';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  get needsTenantApprovalNew() {
    return this._tenant.needs_tenant_approval;
  }

  get needsTenantApproval() {
    return this._tenant.representative_id != null;
  }

  get staffCanStart() {
    return this._staffReview && this._staffReview.canStart && !this.isFinalized;
  }
  get staffCanAddNewRevision() {
    return this._staffReview && this._staffReview.canAddNewRevision && !this.isFinalized;
  }
  get currentStaffReviewer() {
    return this._staffReview && this._staffReview.taskReviewer;
  }
  get tenantReviewAssignedUser() {
    return (
      this._tenantReview &&
      this._tenantReview.getTask && {
        id: this._tenantReview.getTask.assigned_user_id,
        firstName: this._tenantReview.getTask.assigned_user_first_name,
        lastName: this._tenantReview.getTask.assigned_user_last_name,
      }
    );
  }
  get tenantCanStart() {
    return this._tenantReview && this._tenantReview.canStart && this._staffReview.isTaskComplete && !this.isFinalized;
  }
  get tenantCanAddNewRevision() {
    return this._tenantReview && this._tenantReview.canAddNewRevision && !this.isFinalized;
  }
  get isFinalized() {
    return this._tenant.cb_is_finalized === 1;
  }
  get canFinalizeNew() {
    return (
      this._staffReview &&
      this._staffReview.canFinalize &&
      (!this.needsTenantApprovalNew || (this._tenantReview && this._tenantReview.canFinalize)) &&
      !this.isFinalized
    );
  }
  get canFinalize() {
    return (
      this._tenant.selected_peb_id &&
      this._staffReview &&
      this._staffReview.canFinalize &&
      (!this.needsTenantApproval || (this._tenantReview && this._tenantReview.canFinalize)) &&
      !this.isFinalized
    );
  }
  get isPending() {
    return this._staffReview && this._staffReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL;
  }
  get canReset() {
    return (
      ((this._staffReview && this._staffReview.canReset) || (this._tenantReview && this._tenantReview.canReset)) &&
      !this.isFinalized
    );
  }

  get hasCoverLetter() {
    return !!this._tenant.cb_cover_letter_text;
  }

  get hasFiles() {
    return !!this._tenant.construction_documents_ids?.length;
  }

  // returns the route for the staff task
  get getStaffTaskLink(): string {
    return this._staffReview.getTaskLink;
  }

  // returns the route for the tenant task
  get getTenantTaskLink(): string {
    return this._tenantReview.getTaskLink;
  }

  // creates the staff review task for the peb, and returns the task id
  public async beginStaffReview(data: TaskInfo): Promise<number> {
    data.isTenant = false;
    return await this._staffReview.createApprovalTask(data);
  }

  public async beginTenantReview(data: TaskInfo): Promise<number> {
    data.isTenant = true;
    return await this._tenantReview.createApprovalTask(data);
  }

  private async getTaskData(task_id: number): Promise<Task> {
    if (!task_id) {
      return null;
    }
    return this._taskService.loadTask(task_id).toPromise();
  }
}
