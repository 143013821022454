import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ModalService, ProjectService } from 'src/app/services';
import { APIFilter, Project } from 'src/app/types';

@Component({
  selector: 'app-capx-panel',
  templateUrl: './capx-panel.component.html',
  styleUrls: ['./capx-panel.component.scss'],
})
export class CapxPanelComponent implements OnInit {
  loading = false;
  capx;
  pageIndex = 0;
  pageSize = 3;
  projectList: Project[] = [];
  capxBudgets: any[] = [];
  projectFields = [
    'module_name,code,title,status_id,substatus_name,priority,capx_budget{capx_id,description},updates.sort(created_datetime).order(desc).limit(1){created_by,created_by_first_name,created_by_last_name,created_datetime,message}',
  ];
  constructor(private elRef: ElementRef, private projectService: ProjectService, public modalService: ModalService) {}
  @HostListener('click', ['$event'])
  onClick() {
    this.close();
  }
  ngOnInit() {
    this.projectService.capxIDEvent.subscribe((capx) => {
      this.capx = capx;
      this.open(capx.id);
    });
  }

  close() {
    this.elRef.nativeElement.style.display = 'none';
  }

  paginate(inc) {
    const pageSize = this.pageSize;
    const total = this.projectList.length;
    const maxPage = Math.ceil(total / pageSize);
    if (inc === 0) {
      this.pageIndex = 0;
    }
    if (this.pageIndex + inc < maxPage && this.pageIndex + inc >= 0) {
      this.pageIndex += inc;
    }
  }

  get paginationLabel() {
    const firstItem = this.pageIndex * this.pageSize + 1;
    const lastItem = this.projectList[firstItem + this.pageSize - 1]
      ? firstItem + this.pageSize - 1
      : this.projectList.length;
    return firstItem === lastItem
      ? `${firstItem} of ${this.projectList.length}`
      : `${firstItem} - ${lastItem} of ${this.projectList.length}`;
  }

  async open(capx_id: number) {
    this.pageIndex = 0;
    this.loading = true;
    const filter: APIFilter[] = [{ type: 'field', field: 'capx_id', value: capx_id }];
    this.projectList = await this.projectService.getProjects(this.projectFields, filter).toPromise();
    this.capxBudgets = await this.projectService.getCAPXBudgets(capx_id).toPromise();
    this.elRef.nativeElement.style.display = 'block';
    this.loading = false;
  }

  subTotal() {
    let total = 0;
    this.capxBudgets.forEach((c: any) => {
      total += Number(c.budget) || 0;
    });
    return total;
  }
}
