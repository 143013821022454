import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginDialogComponent } from 'src/app/components';
import { AuthService, ModalService } from 'src/app/services';

@Injectable()
// export class JWTInterceptor implements HttpInterceptor {
export class JWTInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  requestNo = 0;

  constructor(
    public authService: AuthService,
    private modalService: ModalService,
    public router: Router,
    private dialog: MatDialog
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getAuthToken();
    if (authToken && !request.url.toLowerCase().includes('/auth/login')) {
      request = this.addAuthToken(request, authToken);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        // add request method for logging purposes
        if (error.error) {
          error.error.reqMethod = request.method;
        }

        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !request.url.toLowerCase().includes('/auth/login')
        ) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  public addAuthToken(request, authToken) {
    if (authToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      return request;
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.toLowerCase().includes('/auth/refresh')) {
      // open login modal
      const currentUser = this.authService.getLoggedInUser();
      if (currentUser) {
        const dialogRef = this.dialog.open(LoginDialogComponent, {
          data: { email: currentUser.email },
          disableClose: true,
        });

        return dialogRef.afterClosed().pipe(
          switchMap((loginSuccessful) => {
            if (loginSuccessful) {
              this.refreshTokenInProgress = false;
              const newTokens = {
                auth_token: this.authService.getAuthToken(),
                refresh_token: this.authService.getRefreshToken(),
              };
              this.refreshTokenSubject.next(newTokens);
              return next.handle(this.addAuthToken(request, newTokens.auth_token));
            } else {
              this.refreshTokenInProgress = false;
              this.router.navigate(['/login'], {
                queryParams: !this.router.url.toLowerCase().includes('/login') ? { returnUrl: this.router.url } : null,
              });
              return next.handle(request);
            }
          })
        );
      } else {
        this.refreshTokenInProgress = false;
        this.router.navigate(['/login'], {
          queryParams: !this.router.url.toLowerCase().includes('/login') ? { returnUrl: this.router.url } : null,
        });
        return next.handle(request);
      }
    } else if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      const impersonationToken = localStorage.getItem('impersonation_token');
      const refreshToken = localStorage.getItem('refresh_token');
      if (impersonationToken) {
        this.modalService
          .openConfirmationDialog({
            titleBarText: 'Impersonation Expired',
            descriptionText: 'Your impersonation session has ended.',
            hideCancelButton: true,
            confirmationButtonText: 'OK',
          })
          .subscribe(async (isConfirmed) => {
            this.authService.endImpersonation();
          });
      } else if (refreshToken) {
        return this.authService.refreshTokens().pipe(
          switchMap((tokens: any) => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(tokens);
            this.authService.getProjectScopes();
            return next.handle(this.addAuthToken(request, tokens.auth_token));
          }),
          catchError((error) =>
            this.refreshTokenSubject.pipe(
              filter((tokens) => tokens !== null),
              take(1),
              switchMap((tokens) => {
                return next.handle(this.addAuthToken(request, tokens.auth_token));
              })
            )
          )
        );
      } else {
        this.refreshTokenInProgress = false;
        this.router.navigate(['/login'], {
          queryParams: !this.router.url.toLowerCase().includes('/login') ? { returnUrl: this.router.url } : null,
        });
        return next.handle(request);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((tokens) => tokens !== null),
        take(1),
        switchMap((tokens) => {
          return next.handle(this.addAuthToken(request, tokens.auth_token));
        })
      );
    }
  }
}
