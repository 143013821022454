<div [style.visibility]="loading ? 'hidden' : 'visible'" #mainScreen class="container app-container p-4">
  <div class="row align-items-center mb-4">
    <div class="col d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h2 id="title" class="dkblue font-weight-semibold">{{ company?.name }}</h2>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 text-uppercase py-0 px-2 br-4 mr-3 white lh-20 bg-dkgray d-inline-block">
            {{ company?.type.name }}
          </h6>
          <h6 *ngIf="company?.is_enabled == 1" class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-green">
            ACTIVE
          </h6>
          <h6 *ngIf="company?.is_enabled == 0" class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-gray">
            INACTIVE
          </h6>
          <h6
            title="Verification Status"
            *ngIf="showSupplierVerification()"
            class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-green"
          >
            {{ CompanyVerificationStatus[company.verification_status] }}
          </h6>
          <h6
            title="Verification Status"
            *ngIf="
              company?.type_id === CompanyType.Supplier &&
              (company.verification_status == 1 || company.verification_status == 4)
            "
            class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-gray"
          >
            {{ CompanyVerificationStatus[company.verification_status] }}
          </h6>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button (click)="editCompany()" mat-button type="button" color="accent">Edit Company</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div>
        <div class="m-portlet mt-2">
          <div class="m-portlet__body p-0">
            <div class="row d-flex justify-content-between">
              <div class="col flex-grow-1" [formGroup]="detailsFormGroup">
                <div class="row border-bottom border-gray m-0">
                  <div class="col-6 p-4 border-right border-gray">
                    <h6 class="mb-2 dkblue font-weight-semibold">Address</h6>
                    <h5 class="primary font-weight-normal mb-0" [innerHtml]="getFormattedAddress()"></h5>
                  </div>
                  <div class="col p-4 border-right border-gray">
                    <h6 class="mb-2 dkblue font-weight-semibold">Phone</h6>
                    <h5 class="primary font-weight-normal mb-0">
                      {{ company?.phone }}
                    </h5>
                  </div>
                  <div class="col p-4">
                    <h6 class="mb-2 dkblue font-weight-semibold">Website</h6>
                    <h5 class="m-0 ltblue font-weight-normal">
                      <a target="_blank" rel="noopener noreferrer" [href]="'//' + company?.website">{{
                        company?.website
                      }}</a>
                    </h5>
                  </div>
                </div>
                <div class="row m-0">
                  <!-- <div class="col-6 p-4 border-right border-gray">
                    <h6 class="mb-3 dkblue font-weight-semibold">Primary Contact</h6>
                    <app-profile-info
                      [user]="company?.primary_contact"
                      [avatarSize]="38"
                      [additionalUserFields]="['title']"
                    >
                    </app-profile-info>
                  </div> -->
                  <!-- <div class="col-6 p-4">
                    <h6 class="mb-3 dkblue font-weight-semibold">Signator</h6>
                    <app-profile-info [user]="company?.signator" [avatarSize]="38" [additionalUserFields]="['title']">
                    </app-profile-info>
                  </div> -->
                  <div class="col-6 p-4 border-right border-gray">
                    <h6 class="mb-3 dkblue font-weight-semibold">Contact Name</h6>
                    <h5 class="primary font-weight-normal mb-0">{{ company?.contact_name }}</h5>
                  </div>
                  <div class="col-6 p-4">
                    <h6 class="mb-3 dkblue font-weight-semibold">Contact Email</h6>
                    <h5 class="primary font-weight-normal mb-0">{{ company?.contact_email }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <mat-tab-group [selectedIndex]="0">
        <mat-tab label="Directory">
          <div class="row mt-4" *ngIf="company?.type_id == 1 || company?.type_id == 2">
            <div class="col-sm-12">
              <div *ngIf="company.departments.length == 0" class="m-portlet data-item p-5">no departments</div>
              <div *ngFor="let department of company?.departments | orderByField: 'name':'asc'">
                <h4 class="dkblue font-weight-semibold mb-3">{{ department.name }}</h4>
                <div class="m-portlet">
                  <div *ngIf="department?.users?.length === 0" class="data-item p-5">no users</div>
                  <div
                    class="m-portlet__body table-header bg-shade-ltblue border-bottom border-gray p-0 pl-3 pr-3"
                    *ngIf="department?.users?.length > 0"
                  >
                    <div class="row align-items-center">
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">Person</h6>
                      </div>
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">Email</h6>
                      </div>
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">1CC Account</h6>
                      </div>
                      <div
                        style="width: 70px"
                        class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="mat-ripple m-portlet__body item pointer p-3"
                    *ngFor="let user of department?.users | orderByField: 'first_name':'asc'"
                  >
                    <div class="row align-items-center">
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <app-profile-thumbnail
                          class="d-inline-block align-middle mr-3"
                          [height]="40"
                          [width]="40"
                          [userId]="user?.id"
                          [overlayIconOpacity]="1"
                          [overlayIcon]="user.is_ar ? 'verified_user' : ''"
                          [overlayIconColor]="'dkgray'"
                          [overlayIconBackground]="'white'"
                          [overlayIconTooltip]="'Authorized Requester'"
                        >
                        </app-profile-thumbnail>
                        <span class="d-inline-block align-middle">
                          <p class="dkblue m-0 d-block align-middle text-wrap text-truncate font-weight-semibold lh-18">
                            {{ user.first_name + ' ' + user.last_name }}
                          </p>
                          <p class="dkblue m-0 d-block align-middle font-weight-normal text-wrap text-truncate lh-18">
                            {{ user.title }}
                          </p>
                        </span>
                      </div>
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <p class="m-0 mr-auto dkblue pointer">{{ user.email }}</p>
                      </div>
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <p class="m-0 mr-auto dkblue pointer">{{ user.is_enabled == 1 ? 'Active' : 'Inactive' }}</p>
                      </div>
                      <div class="col-auto py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <h6
                          *ngIf="user.id == company?.primary_contact?.id"
                          class="mb-3 text-uppercase py-0 px-2 br-4 mr-1 white lh-20 bg-ltblue d-inline-block"
                        >
                          Primary Contact
                        </h6>
                        <button mat-button type="button" color="accent" class="lh-30 px-2">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" *ngIf="company?.type_id == 3">
            <div class="col-sm-12">
              <div>
                <h4 class="dkblue font-weight-semibold mb-3">Users</h4>
                <div class="m-portlet">
                  <div *ngIf="company?.users?.length == 0" class="data-item p-5">no users</div>
                  <div
                    class="m-portlet__body table-header bg-shade-ltblue border-bottom border-gray p-0 pl-3 pr-3"
                    *ngIf="company?.users?.length"
                  >
                    <div class="row align-items-center">
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">Person</h6>
                      </div>
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">Email</h6>
                      </div>
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">1CC Account</h6>
                      </div>
                      <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                        <h6 class="m-0 text-truncate header-title mr-auto">Key/Access</h6>
                      </div>
                      <div
                        style="width: 70px"
                        class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="mat-ripple m-portlet__body item pointer p-3"
                    *ngFor="let user of company?.users | orderByField: 'first_name':'asc'"
                  >
                    <div class="row align-items-center">
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <app-profile-thumbnail
                          class="d-inline-block align-middle mr-3"
                          [height]="40"
                          [width]="40"
                          [userId]="user?.id"
                          [overlayIconOpacity]="1"
                          [overlayIcon]="user.is_ar ? 'verified_user' : ''"
                          [overlayIconColor]="'dkgray'"
                          [overlayIconBackground]="'white'"
                          [overlayIconTooltip]="'Authorized Requester'"
                        >
                        </app-profile-thumbnail>
                        <span class="d-inline-block align-middle">
                          <p class="dkblue m-0 d-block align-middle text-wrap text-truncate font-weight-semibold lh-18">
                            {{ user.first_name + ' ' + user.last_name }}
                          </p>
                          <p class="dkblue m-0 d-block align-middle font-weight-normal text-wrap text-truncate lh-18">
                            {{ user.title }}
                          </p>
                        </span>
                      </div>
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <p class="m-0 mr-auto dkblue pointer">{{ user.email }}</p>
                      </div>
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <p class="m-0 mr-auto dkblue pointer">{{ user.is_enabled == 1 ? 'Active' : 'Inactive' }}</p>
                      </div>
                      <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <p class="m-0 mr-auto dkblue pointer">Active, Expires 12/31/2021</p>
                      </div>
                      <div class="col-auto py-0 pr-3 pl-3 d-flex align-items-center data-item">
                        <h6
                          *ngIf="user.id == company?.primary_contact?.id"
                          class="mb-3 text-uppercase py-0 px-2 br-4 mr-1 white lh-20 bg-ltblue d-inline-block"
                        >
                          Primary Contact
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Documents" *ngIf="company?.type_id == 3">
          <div class="row mt-4">
            <div class="col-sm-12">
              <div class="m-portlet">
                <div class="m-portlet__body table-header bg-shade-ltblue border-bottom border-gray p-0 pl-3 pr-3">
                  <div class="row align-items-center">
                    <div class="col-1 pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Type</h6>
                    </div>
                    <div class="col-1 pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
                    </div>
                    <div class="col-2 pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Issued Date</h6>
                    </div>
                    <div class="col-2 pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Expiration Date</h6>
                    </div>
                    <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">File</h6>
                    </div>
                    <div
                      style="width: 70px"
                      class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
                    ></div>
                  </div>
                </div>
                <div class="mat-ripple m-portlet__body item pointer p-3" *ngFor="let ci of companyItemTypeValues">
                  <div class="row align-items-center">
                    <div class="col-1 py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="dkblue m-0 d-block align-middle text-wrap text-truncate font-weight-semibold lh-18">
                        {{ companyItemTypes[ci.tid] }}
                      </p>
                    </div>
                    <div class="col-1 py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ companyItemStatuses[ci.fileItem?.status_id] }}</p>
                    </div>
                    <div class="col-2 py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ ci.fileItem?.issued_date | date }}</p>
                    </div>
                    <div class="col-2 py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ ci.fileItem?.expiration_date | date }}</p>
                    </div>
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">
                        <mat-chip-list *ngIf="ci.fileItem?.file">
                          <app-file-chip [file]="ci.fileItem?.file"></app-file-chip>
                        </mat-chip-list>
                      </p>
                    </div>
                    <div class="col-auto py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <mat-icon (click)="EditItem(ci)">edit</mat-icon>
                      <mat-icon *ngIf="ci.fileItem?.id" (click)="deleteItem(ci)">delete</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
