<div
  data-test-id="work-order-details-panel"
  id="work-order-details-panel"
  class="work-order-details-panel d-flex flex-column"
>
  <div class="d-flex align-items-center mb-4">
    <mat-icon class="orange mr-2">description</mat-icon>
    <a
      href="#"
      target="_blank"
      class="text-decoration-none ltblue font-weight-semibold hover-u pointer mr-auto"
      [routerLink]="['/work-orders/' + workOrder?.id]"
    >
      <h5 class="font-weight-semibold m-0">
        {{ workOrder?.code }}
      </h5>
    </a>
    <button *ngIf="workOrderFollowers?.length === 0" mat-button type="button" class="gray lh-24 px-2">
      0 Followers
    </button>
    <div *ngIf="workOrderFollowers?.length > 0" [matMenuTriggerFor]="follower_menu" class="pointer mr-3">
      <p
        class="font-weight-semibold d-inline-block align-middle ltblue m-0 mr-3"
        *ngIf="workOrderFollowers?.length > 4"
      >
        +{{ workOrderFollowers?.length - 4 }}
      </p>
      <app-profile-thumbnail
        [width]="30"
        [height]="30"
        [userId]="follower.id"
        class="follower"
        *ngFor="let follower of workOrderFollowers | slice: 0:4"
      ></app-profile-thumbnail>
    </div>
    <mat-menu #follower_menu="matMenu" xPosition="before">
      <button
        mat-menu-item
        color="primary"
        (click)="openUserProfilePreview(follower.id)"
        *ngFor="let follower of workOrderFollowers"
      >
        <div class="menu-user-avatar d-inline-block align-top">
          <img [src]="getProfileThumbnailUrl(follower.id)" />
        </div>
        &nbsp;
        <span class="pointer"> {{ follower.first_name }} {{ follower.last_name }} </span>
      </button>
    </mat-menu>
    <button
      mat-icon-button
      class="ml-2"
      type="button"
      color="primary"
      [matMenuTriggerFor]="task_menu"
      *ngIf="canEditWO"
    >
      <mat-icon> more_horiz </mat-icon>
    </button>
    <mat-menu #task_menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="editWorkOrder()" *ngIf="isStaff">
        <mat-icon>assignment</mat-icon>
        <span>Edit Work Order</span>
      </button>
      <button mat-menu-item (click)="createMeeting()" *ngIf="isStaff">
        <mat-icon>event</mat-icon>
        <span> Create Meeting </span>
      </button>
      <button mat-menu-item (click)="createMeetingAgendaFromWorkOrder()" *ngIf="isStaff">
        <mat-icon>event_notes</mat-icon>
        <span>Add to Meeting Agenda</span>
      </button>
    </mat-menu>
    <button mat-icon-button color="primary" class="ml-2 gray" type="button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2 class="dkblue font-weight-semibold pt-2">
    {{ workOrder?.title }}
  </h2>
  <h4 *ngIf="workOrder?.topic_name" class="dkgray mb-4 font-weight-normal pb-2">
    {{ workOrder?.topic_group_name }} > {{ workOrder?.topic_category_name }} >
    {{ workOrder?.topic_name }}
  </h4>
  <div class="d-flex align-items-center py-2 mb-4">
    <h6
      *ngIf="workOrder?.module?.name"
      class="m-0 br-4 lh-22 px-2 white bg-dkblue mr-3 text-uppercase"
      matTooltip="WS: {{ workOrder?.module?.name }}"
      matTooltipPosition="above"
    >
      {{ workOrder?.module?.name }}
    </h6>
    <h6
      *ngIf="workOrder?.status?.name"
      class="m-0 br-4 lh-22 px-2 white bg-green mr-3 text-uppercase"
      matTooltip="Status: {{ workOrder?.status?.name }}"
      matTooltipPosition="above"
      [ngClass]="{
        'bg-green': workOrder?.status?.id === 1,
        'bg-red': workOrder?.status?.id === 2,
        'bg-gray': workOrder?.status?.id === 3,
        'bg-dkgreen': workOrder?.status?.id === 4
      }"
    >
      {{ workOrder?.status?.name }}
    </h6>
    <h6
      *ngIf="workOrder?.priority?.name"
      class="m-0 br-4 lh-22 px-2 white bg-red mr-3 text-uppercase"
      [ngClass]="{
        'bg-dkred': workOrder?.priority?.id === 4,
        'bg-red': workOrder?.priority?.id === 3,
        'bg-ltblue': workOrder?.priority?.id === 2,
        'bg-ltgray': workOrder?.priority?.id === 1
      }"
      matTooltip="Priority: {{ workOrder?.priority?.name }}"
      matTooltipPosition="above"
    >
      {{ workOrder?.priority?.name }}
    </h6>
  </div>
  <div class="d-flex flex-column py-2" *ngIf="workOrder?.summary">
    <h6 class="mb-2 dkblue font-weight-semibold">Summary</h6>
    <p
      style="white-space: pre-wrap"
      [innerHTML]="workOrder?.summary"
      class="dkgray task-description ease font-weight-normal mb-4"
    ></p>
  </div>
  <div *ngIf="workOrder?.code" class="row py-2 mb-4">
    <div class="col">
      <h6 class="mb-2 dkblue font-weight-semibold">Assigned To</h6>
      <button
        matRipple
        (click)="changeWorkOrderAssignment()"
        [disabled]="!canEditWO"
        class="m-portlet m-0 p-0 full-width text-left"
      >
        <div class="m-portlet__body p-2 item item-last d-flex align-items-center">
          <div *ngIf="workOrder?.assigned_user" class="d-flex align-items-center">
            <app-profile-thumbnail
              [height]="40"
              [width]="40"
              [userId]="workOrder?.assigned_user?.id"
              class="mr-2"
            ></app-profile-thumbnail>
            <p class="m-0 d-flex flex-column lh-18">
              <span class="dkblue font-weight-semibold">
                {{ workOrder?.assigned_user?.first_name }} {{ workOrder?.assigned_user?.last_name }}
              </span>
              <span class="dkgray font-weight-normal">
                {{ workOrder?.assigned_user?.title }}
              </span>
            </p>
          </div>
          <div *ngIf="!workOrder?.assigned_user" class="d-flex align-items-center">
            <button disabled mat-icon-button color="primary" class="mr-2">
              <mat-icon class="gray" style="margin-top: 4px; font-size: 30px; height: 30px; width: 30px">
                account_circle
              </mat-icon>
            </button>
            <p class="m-0 d-flex flex-column lh-18">
              <span class="gray font-weight-semibold"> Assign Work Order </span>
            </p>
          </div>
        </div>
      </button>
    </div>
    <div class="col">
      <h6 class="mb-2 dkblue font-weight-semibold">Due Date</h6>
      <button
        matRipple
        (click)="dueDatePicker.open()"
        [disabled]="!canEditWO"
        class="m-portlet m-0 p-0 full-width text-left"
      >
        <div class="m-portlet__body p-2 item item-last d-flex align-items-center">
          <button disabled mat-icon-button color="primary" class="mr-2">
            <!-- SHOW OVERLAY ICON HERE IF OVERDUE -->
            <div class="overlay-icon-danger white" *ngIf="isOverdue">
              <mat-icon> priority_high </mat-icon>
            </div>
            <mat-icon
              [ngClass]="{ dkblue: workOrder?.due_date, gray: !workOrder?.due_date }"
              style="margin-top: 4px; font-size: 30px; height: 30px; width: 30px"
            >
              event
            </mat-icon>
          </button>
          <p class="m-0 d-flex flex-column lh-18" *ngIf="workOrder?.due_date">
            <span class="dkblue font-weight-semibold">
              {{ workOrder?.due_date | date: 'EEEE' }}
            </span>
            <span class="dkgray font-weight-normal">
              {{ workOrder?.due_date | date: 'MMM d y' }}
            </span>
          </p>
          <p class="m-0 d-flex flex-column lh-18" *ngIf="!workOrder?.due_date">
            <span class="gray font-weight-semibold"> Set Due Date </span>
          </p>
        </div>
      </button>
      <input
        matInput
        [matDatepicker]="dueDatePicker"
        [(ngModel)]="workOrder.due_date"
        autocomplete="off"
        style="visibility: hidden; width: 0"
        (ngModelChange)="changeTaskDueDate(workOrder.due_date)"
        [disabled]="!canEditWO"
      />
      <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
    </div>
  </div>
  <div class="d-flex flex-column py-4" *ngIf="workOrder?.files && workOrder?.files?.length > 0">
    <h6 class="mb-2 font-weight-semibold dkblue">Files</h6>
    <div class="d-flex">
      <mat-chip-list class="d-inline-block align-middle">
        <app-file-chip *ngFor="let file of workOrder?.files" [file]="file"></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
<hr class="my-4 border-gray" />
<div id="work-order-update-panel" class="work-order-update-panel d-flex flex-column">
  <div class="d-flex mb-4 align-items-center">
    <h4 class="dkblue mb-0 mr-auto font-weight-bold">Updates</h4>
    <button
      mat-flat-button
      color="success"
      type="button"
      class="px-2 lh-28"
      (click)="addWorkOrderUpdate()"
      *ngIf="canEditWO"
      [disabled]="!workOrder?.id"
    >
      + Update
    </button>
  </div>
  <h5 class="mb-2 mt-4 font-weight-normal gray" *ngIf="!workOrder?.updates || workOrder.updates?.length === 0">
    No updates
  </h5>
  <!-- UPDATE ITEM -->
  <div class="d-flex align-items-top mb-4" *ngFor="let update of workOrder?.updates">
    <app-profile-thumbnail
      [height]="40"
      [width]="40"
      [userId]="update.created_by?.id"
      class="mr-3"
    ></app-profile-thumbnail>
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column mr-auto">
          <p class="m-0 d-flex flex-column lh-18">
            <span class="dkblue font-weight-semibold">
              {{ update.created_by?.first_name }} {{ update.created_by?.last_name }}
            </span>
            <span class="dkgray font-weight-normal">
              {{ update?.created_datetime | date: 'E, LLL d y' }} • 
              {{ update?.created_datetime | date: 'hh:mma' }}
            </span>
          </p>
        </div>
      </div>
      <p
        style="white-space: pre-wrap"
        [innerHTML]="update.message"
        class="dkblue task-description ease font-weight-normal py-3 m-0"
      ></p>
      <mat-chip-list *ngIf="update.files" class="d-inline-block align-middle">
        <app-file-chip *ngFor="let file of update.files" [file]="file"></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
