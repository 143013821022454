import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { round, sumBy } from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { USDollarPipe } from 'src/app/pipes';
import { ApiFilterService, FileService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse, UhatFileReference } from 'src/app/types';
import {
  PEB,
  PEBFundingSource,
  PEBFundingSourceType,
  PEBItem,
  PEBItemNew,
  PEBItemType,
  PEBLine,
  PEBOption,
  PEBSection,
  PEBTenantLine,
} from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PEBService {
  host: string = environment.serviceHost;
  pebUrl = `${this.host}/api/v1/pebs`;
  pebFundingSourceTypeUrl = `${this.host}/api/v1/peb-funding-source-types`;
  pebItemUrl = `${this.host}/api/v1/peb-items`;
  pebItemTypeUrl = `${this.host}/api/v1/peb-item-types`;
  pebLineUrl = `${this.host}/api/v1/peb-lines`;
  pebNewUrl = `${this.host}/api/v1/pebs-new`;
  pebTenantLineUrl = `${this.host}/api/v1/peb-tenant-lines`;

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private fileService: FileService,
    private apiFilterService: ApiFilterService
  ) {}

  private itemFields: string[] = [
    'name',
    'peb_id',
    'owner_type_id',
    'trade_id',
    'trade_name',
    'type_id',
    'fixed_properties',
    'percent_cost',
    'sqft',
    'cost_per_sqft',
    'subtotal',
  ];

  feeTypeIds = [2, 3, 5, 6];
  private USDollarPipe = new USDollarPipe();

  getPEBById(pebId: number, fields: string[]) {
    return this.http.get(`${this.pebUrl}/${pebId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const peb: PEB = result.data.peb[0];
        return peb;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // TODO: Replace with single API call. Should return all PEBS for a project that will include all its data.
  getPEBs(fields: string[], apiFilters?: APIFilter[]): Observable<PEB[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    const obs = new ReplaySubject<PEB[]>();
    this.http
      .get(
        `${this.pebUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map(async (result: ServiceResponse) => {
          const results = result.data.pebs;
          for (const peb of results) {
            // For each returned PEB item we need to get the additional fields from the Items Table.
            // We await these to keep this operations syncronous, so that we only call .next on the observable after all operations have complete
            const itemFilters: APIFilter[] = [{ type: 'field', field: 'peb_id', value: peb.id.toString() }];
            const items: PEBItem[] = await this.getPEBItems(this.itemFields, itemFilters).toPromise();
            for (const i of items) {
              i.fixed_properties = i.fixed_properties ? JSON.parse(i.fixed_properties) : [];
            }
            peb.items = items;
          }
          obs.next(results);
          obs.complete();
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe();
    return obs.asObservable();
  }

  getPEBsNew(fields: string[], apiFilters?: APIFilter[]): Observable<PEBOption[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.pebNewUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.pebs_new;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createPEBNew(peb: PEB): Observable<PEB> {
    return this.http.post(`${this.pebNewUrl}`, peb).pipe(
      map((result: ServiceResponse) => {
        const pebToReturn = result.data;
        return pebToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createPEBLine(pebLine: PEBLine): Observable<PEBLine> {
    return this.http.post(`${this.pebLineUrl}`, pebLine).pipe(
      map((result: ServiceResponse) => {
        const pebLineToReturn = result.data['peb line'];
        return pebLineToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deletePEBLine(pebLineId: number): Observable<void> {
    return this.http.delete(`${this.pebLineUrl}/${pebLineId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createPEBTenantLine(pebTenantLine: PEBTenantLine): Observable<PEBTenantLine> {
    return this.http.post(`${this.pebTenantLineUrl}`, pebTenantLine).pipe(
      map((result: ServiceResponse) => {
        const pebTenantLineToReturn = result.data['peb tenant line'];
        return pebTenantLineToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deletePEBTenantLine(pebTenantLineId: number): Observable<void> {
    return this.http.delete(`${this.pebTenantLineUrl}/${pebTenantLineId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getPebByRevisionFileId(fileId: number): Observable<PEB> {
    return this.http.get(`${this.pebUrl}?fields=id&filter=current_revision_file_id=${fileId}`).pipe(
      map((result: ServiceResponse) => {
        const pebToReturn = result.data.pebs[0];
        return pebToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createPEB(peb: PEB, fields: string[]): Observable<PEB> {
    return this.http.post(`${this.pebUrl}?fields=${fields.join(',')}`, peb).pipe(
      map((result: ServiceResponse) => {
        const pebToReturn = result.data.peb;
        return pebToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updatePEB(pebId: number, peb: PEB, fields: string[]) {
    return this.http.put(`${this.pebUrl}/${pebId}?fields=${fields.join(',')}`, peb).pipe(
      map((result: ServiceResponse) => {
        const pebToReturn: PEB = result.data.peb;
        return pebToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deletePEB(pebId: number): Observable<void> {
    return this.http.delete(`${this.pebUrl}/${pebId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getPEBItemTypes(fields: string[], apiFilters?: APIFilter[]): Observable<PEBItemType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.pebItemTypeUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.peb_item_types;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getPEBItems(fields: string[], apiFilters?: APIFilter[]): Observable<PEBItem[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.pebItemUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.peb_items;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createPEBItem(pebItem: PEBItem, fields: string[]): Observable<PEBItem> {
    return this.http.post(`${this.pebItemUrl}?fields=${fields.join(',')}`, pebItem).pipe(
      map((result: ServiceResponse) => {
        const pebItemToReturn = result.data['peb item'];
        return pebItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updatePEBItem(itemId: number, pebItem: PEBItem, fields: string[]): Observable<PEBItem> {
    return this.http.put(`${this.pebItemUrl}/${itemId}?fields=${fields.join(',')}`, pebItem).pipe(
      map((result: ServiceResponse) => {
        const pebItemToReturn: PEBItem = result.data['peb item'];
        return pebItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deletePEBItem(itemId: number): Observable<void> {
    return this.http.delete(`${this.pebItemUrl}/${itemId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * Retrieve the PEB Project files for a project.
   * The data retrieved is
   *  pebFiles: UhatFileReference[]
   *  exhibitBFile: UhatFileReference
   *  subleaseContract: UhatFileReference
   *  amortizationSchedule: UhatFileReference
   * @param currentSelectedProjectId ProjectId to get the files from
   */
  public getProjectPEBFiles(currentSelectedProjectId: number): Observable<ProjectPEBFiles> {
    const subject = new ReplaySubject<ProjectPEBFiles>();
    this.http
      .get(
        `${this.host}/api/v1/projects/${currentSelectedProjectId}?fields=peb_files,reimbursement_file_id,reimbursement_file_name,sublease_contract_file_id,sublease_contract_file_name,amortization_file_id,amortization_file_name,exhibit_b_file_id,exhibit_b_file_name`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.project[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe(async (fileIdData) => {
        const pebFiles: { id: number; name: string }[] = [];
        // Using the peb file ids we need to retrieve the file names so that we can correctly display them to the user.
        if (fileIdData.peb_files) {
          for (const id of fileIdData.peb_files.split(',')) {
            await this.fileService
              .getIdAndName(id)
              .toPromise()
              .then((file) => pebFiles.push(file));
          }
        }
        const pebItemToReturn: ProjectPEBFiles = {
          pebFiles,
          reimbursementFile: { name: fileIdData.reimbursement_file_name, id: fileIdData.reimbursement_file_id },
          subleaseContractFile: {
            name: fileIdData.sublease_contract_file_name,
            id: fileIdData.sublease_contract_file_id,
          },
          exhibitBFile: { name: fileIdData.exhibit_b_file_name, id: fileIdData.exhibit_b_file_id },
          amortizationFile: { name: fileIdData.amortization_file_name, id: fileIdData.amortization_file_id },
        };
        subject.next(pebItemToReturn);
      });

    return subject.asObservable();
  }

  getPEBData(pebId: number): Observable<any> {
    return this.http.get(`${this.pebNewUrl}/${pebId}/data`).pipe(
      map((result: ServiceResponse) => {
        const pebData = result.data;
        return pebData;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getPEBFundingSourceTypes(fields: string[], apiFilters?: APIFilter[]): Observable<PEBFundingSourceType[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.pebFundingSourceTypeUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.peb_funding_source_types;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updatePEBData(peb: PEBOption): Observable<any> {
    if (!peb?.id) {
      console.error('Error updating PEB data - PEB id is missing');
      return of(null);
    } else {
      const body = peb;
      for (const s of body.sections) {
        delete s.approval_process;
      }
      return this.http.put(`${this.pebNewUrl}/${peb?.id}/data`, body).pipe(
        map((result: ServiceResponse) => {
          const pebToReturn: PEBItem = result.data;
          return pebToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
    }
  }

  mapBugetForComparison(budget) {
    return budget.sections.map((s) => {
      s = {
        sqft: s.lines.map((l) => +l.sqft),
        values: s.funding_sources.map((fs) => {
          fs = fs.items.map((i) => {
            return {
              id: i.id,
              funding_source_id: i.funding_source_id,
              line_id: i.line_id,
              value: +i.value,
              percentage: +i.percentage,
            };
          });
          return fs;
        }),
      };
      return s;
    });
  }

  private getFundingSourceTradeTotal(fundingSource: PEBFundingSource) {
    const tradeItems = fundingSource.items.filter((i) => i.line_type_id === 1);
    const tradeTotal = sumBy(tradeItems, (i) => +i.value);
    return tradeTotal;
  }

  formatDollar(oldValue: string | number, includeCommas = false): string {
    if (oldValue !== null) {
      oldValue = +oldValue;
      let oldDecimalCount;
      if (Math.floor(oldValue) === oldValue) {
        oldDecimalCount = 0;
      } else {
        if (!oldValue.toString().includes('.')) {
          oldDecimalCount = 0;
        } else {
          oldDecimalCount = oldValue.toString().split('.')[1].length || 0;
        }
      }
      let newDecimalCount;
      if (!oldDecimalCount || oldDecimalCount <= 2) {
        newDecimalCount = 2;
      } else if (oldDecimalCount <= 4) {
        newDecimalCount = oldDecimalCount;
      } else {
        newDecimalCount = 4;
      }
      const newValue = this.USDollarPipe.transform(oldValue, newDecimalCount, false, includeCommas, false);
      return newValue;
    } else {
      return null;
    }
  }

  private updatePercentageLineItems(option) {
    for (const s of option?.sections ?? []) {
      for (const fs of s.funding_sources) {
        const tradeTotal = this.getFundingSourceTradeTotal(fs);
        const nonManagementFeeTypes = this.feeTypeIds.filter((ftid) => ftid !== 6);
        const nonManagementFeeItems = fs.items.filter((fsi) => nonManagementFeeTypes.indexOf(fsi.line_type_id) > -1);
        let tradeAndFeeTotal = tradeTotal;
        for (const i of nonManagementFeeItems) {
          i.value = this.formatDollar(round((i.percentage / 100) * tradeTotal, 4));
          tradeAndFeeTotal += +i.value;
          i.is_modified = true;
        }
        const managementFeeItems = fs.items.filter((fsi) => fsi.line_type_id === 6);
        for (const i of managementFeeItems) {
          i.value = this.formatDollar(round((i.percentage / 100) * tradeAndFeeTotal, 4));
          i.is_modified = true;
        }
      }
    }
  }

  private calculateLineSubtotals(section: PEBSection) {
    section.percentageSubtotalsAreValid = true;
    for (const l of section.lines) {
      if (l.calculation_type_id !== 2 || (!l.subtotal && l.subtotal !== 0)) {
        let lineSubtotal = 0;
        for (const fs of section.funding_sources) {
          const foundItem = fs.items.find((fsi) => l.id === fsi.line_id);
          lineSubtotal = round(lineSubtotal + (foundItem ? +foundItem?.value : 0), 4);
        }
        l.subtotal = this.formatDollar(lineSubtotal);
      }

      if (l.calculation_type_id === 2) {
        l.percent_subtotal = 0;
        for (const fs of section.funding_sources) {
          const percentItems = fs.items.filter((fsi) => fsi.calculation_type_id === 2 && fsi.line_id === l.id);
          for (const i of percentItems) {
            l.percent_subtotal += +i.percentage;
            i.value = this.formatDollar(round((i.percentage / 100) * +l.subtotal, 4));
          }
        }
        l.percent_subtotal = round(l.percent_subtotal, 5);
        if (l.percent_subtotal !== 100) {
          section.percentageSubtotalsAreValid = false;
        }
      }
    }
  }

  private calculateFundingSourceSubtotals(section: PEBSection) {
    for (const fs of section.funding_sources) {
      fs.subtotal = round(
        sumBy(fs.items, (i: PEBItemNew) => +i.value),
        4
      );
    }
  }

  private calculateSectionSubtotals(section: PEBSection) {
    this.calculateLineSubtotals(section);
    this.calculateFundingSourceSubtotals(section);
    section.subtotal = round(
      sumBy(section.lines, (li: PEBLine) => +li.subtotal),
      4
    );
  }

  private calculateSectionYearlyRentalRate(section: PEBSection) {
    const yearlyRentalRate =
      Number(section.rental_rate) *
      (section.occupied_sqft + section.occupied_sqft * (section.circulation_factor * 0.01));
    section.yearlyRentalRate = round(yearlyRentalRate, 2);
  }

  calculateOptionTotals(option: PEBOption) {
    let percentageSubtotalsAreValid = true;
    this.updatePercentageLineItems(option);
    for (const s of option.sections) {
      this.calculateSectionSubtotals(s);
      this.calculateSectionYearlyRentalRate(s);
    }
    percentageSubtotalsAreValid = !option.sections.find((s) => !s.percentageSubtotalsAreValid);
    for (const l of option.lines) {
      let lineTotal = 0;
      for (const s of option.sections) {
        const foundLine = s.lines.find((sl) => l.id === sl.id);
        lineTotal = round(lineTotal + (foundLine ? +foundLine.subtotal : 0), 4);
      }
      l.total = lineTotal;
    }
    option.total = this.formatDollar(
      round(
        sumBy(option.sections, (s: any) => +s.subtotal),
        4
      ),
      true
    );

    for (const s of option.sections) {
      s.subtotal = this.formatDollar(s.subtotal, true);
      for (const fs of s.funding_sources) {
        fs.subtotal = this.formatDollar(fs.subtotal, true);
      }
    }
    for (const l of option.lines) {
      l.total = this.formatDollar(l.total, true);
    }

    return percentageSubtotalsAreValid;
  }
}

export interface ProjectPEBFiles {
  pebFiles: UhatFileReference[];
  reimbursementFile: UhatFileReference;
  subleaseContractFile: UhatFileReference;
  amortizationFile: UhatFileReference;
  exhibitBFile: UhatFileReference;
}
