import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { ConfirmationDialogInjectionData, UhatFileReference } from 'src/app/types';
import { EditorComponent } from '..';
import { CheckboxState } from '../../enums';
import { ModalService, ProjectService } from '../../services';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;

  public titleBarText = 'Confirmation';

  public headerText: string;

  public confirmationButtonText = 'Yes';
  public hideConfirmationButton = false;

  public cancelButtonText = 'Cancel';
  public hideCancelButton = false;

  public descriptionText: string;
  public accessoryContent: string;

  public attachedFiles: UhatFileReference[];
  public linkedFiles: UhatFileReference[];
  public addFiles: boolean;
  public fileAdded: { id: number; name: string };
  public downloading: boolean;
  public checkboxState = CheckboxState.Unchecked;
  public defaultText: string;

  public requiredFilesCount = 0;
  public allowedFilesCount = 0;
  public newFiles: File[] = [];
  public parentId: number;
  public resourceType: number;
  public fileOptional: boolean;

  public userInput: {
    required: boolean;
    placeholder: string;
    checkboxText?: string;
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInjectionData,
    projectService: ProjectService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    if (this.data.confirmationButtonText) {
      this.confirmationButtonText = this.data.confirmationButtonText;
    }
    if (this.data.hasOwnProperty('cancelButtonText')) {
      this.cancelButtonText = this.data.cancelButtonText;
    }
    if (this.data.descriptionText) {
      this.descriptionText = this.data.descriptionText;
    }
    if (this.data.accessoryContent) {
      this.accessoryContent = this.data.accessoryContent;
    }
    if (this.data.headerText) {
      this.headerText = this.data.headerText;
    }
    if (this.data.userInput) {
      this.userInput = this.data.userInput;
      this._removeEditorValidation();
    }
    if (this.data.titleBarText) {
      this.titleBarText = this.data.titleBarText;
    }
    if (this.data.fileOptional) {
      this.fileOptional = this.data.fileOptional;
    }
    if (this.data.hideConfirmationButton) {
      this.hideConfirmationButton = this.data.hideConfirmationButton;
    }
    if (this.data.hideCancelButton) {
      this.hideCancelButton = this.data.hideCancelButton;
    }
    this.attachedFiles = this.data.attachedFiles || [];
    this.linkedFiles = this.data.linkedFiles || [];
    // This is not being used but will be for future functionality
    this.addFiles = this.data.addFiles || false;

    this.requiredFilesCount = this.data.requiredFilesCount || 0;
    this.allowedFilesCount = this.data.allowedFilesCount || 0;

    this.parentId = this.data?.filesParentId;
    this.resourceType = this.data?.filesParentResourceType;
    this.defaultText = this.data?.userInput?.defaultText;
  }

  get CheckBoxState() {
    return CheckboxState;
  }

  get textAreaValue(): string {
    return this._editor_component?.content?.value || '';
  }

  get hasMultipleRes() {
    return (
      [
        this.userInput?.checkboxText,
        this.userInput?.placeholder || this.userInput?.required,
        this.requiredFilesCount,
        this.allowedFilesCount,
      ].filter((res) => res).length >= 2
    );
  }

  public toggleCheckboxState() {
    this.checkboxState = CheckboxState.Checked === this.checkboxState ? CheckboxState.Unchecked : CheckboxState.Checked;
  }

  private async _removeEditorValidation() {
    this._editor_component.content.clearValidators();
    this._editor_component.content.updateValueAndValidity();
  }

  continue() {
    if (!this.userInput && (this.allowedFilesCount > 0 || this.requiredFilesCount > 0 || this.fileOptional)) {
      this.dialogRef.close(this.newFiles);
    } else if (!this.userInput) {
      this.dialogRef.close(true);
    } else if (this.hasMultipleRes) {
      this.dialogRef.close({
        checkRes: this.checkboxState,
        res: this.textAreaValue || '',
        newFiles: this.newFiles,
      });
    } else {
      this.dialogRef.close(this.textAreaValue || '');
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  isValidToSubmit(): boolean {
    if ((!this.userInput || !this.userInput.required) && (this.requiredFilesCount === 0 || this.fileOptional)) {
      return true;
    }
    const val = this.textAreaValue;
    return (
      (!(this.userInput && this.userInput.required) || (val && val.length > 0)) &&
      (+this.requiredFilesCount === 0 || this.fileOptional || +this.requiredFilesCount <= this.newFiles.length)
    );
  }
  save(file) {
    this.downloading = true;
    saveAs(file);
    this.downloading = false;
  }

  getShortenedFileName(file) {
    if (!file.name) {
      return 'Name Unknown.unk';
    }
    if (file.name.length > 35) {
      return file.name.substring(0, 35) + '..';
    } else {
      return file.name;
    }
  }

  public openFile() {
    this.modalService
      .openFileAttachmentDialog({
        parentResourceId: this.parentId,
        parentResourceType: this.resourceType,
        preSelectedTags: [],
        filteredFiles: this.newFiles,
      })
      .subscribe((files) => {
        this.newFiles = files;
      });
  }

  public async openUploadModal() {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    for (const file of files?.computerFiles || []) {
      if (
        !this.newFiles.find((f) => f.name === file.name) &&
        this.newFiles.length < (this.allowedFilesCount || this.requiredFilesCount)
      ) {
        this.newFiles.push(file);
      }
    }
  }

  public removeFile(file: File) {
    this.newFiles = this.newFiles.filter((f) => f.name !== file.name);
  }

  download(file) {
    saveAs(file as File);
    this.snackbar.open(`${file.name} has been downloaded`);
  }

  // stops the parent click event
  public stopPropagation(event) {
    event.stopPropagation();
  }
}
