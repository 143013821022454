<app-base-dialog [title]="'Select Files to Attach'" (closeDialog)="close()"></app-base-dialog>
<div class="project-modal modal-wide">
  <div class="row dialog-form">
    <div class="col mb-3">
      <!-- Search form -->
      <mat-form-field appearance="outline" class="full-width">
        <input matInput [(ngModel)]="searchInput" placeholder="Search Files" />
        <mat-icon *ngIf="searchInput" (click)="searchInput = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
      <!-- <input class="form-control light-outline" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput"> -->
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-selection-list *ngIf="allFiles.length > 0">
        <mat-list-option
          *ngFor="let file of allFiles | filenameFilter: searchInput"
          [value]="file"
          [selected]="fileIsSelected(file)"
          (click)="toggleFileSelection(file)"
        >
          {{ file.name }}
        </mat-list-option>
      </mat-selection-list>
      <div *ngIf="allFiles.length <= 0 && !isLoading" class="no-items-panel">
        <div>
          <h3 class="gray font-weight-normal my-4">Oops. We didn't find anything here.</h3>
        </div>
      </div>
      <div *ngIf="isLoading" class="d-flex justify-content-center no-items-panel">
        <mat-spinner class="d-inline-block align-middle mt-4" color="accent" [diameter]="40"></mat-spinner>
      </div>
    </div>
  </div>
  <div class="row mt-4 align-items-center">
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold">
        {{ getFileSelectionNumberText() }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <button mat-button *ngIf="!processing" (click)="close()" class="ltgray mr-2">Cancel</button>
      <button mat-flat-button color="success" [disabled]="selectedFiles.length < 1" (click)="submit()">
        Attach Files
      </button>
    </div>
  </div>
</div>
