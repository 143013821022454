import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { DepartmentManagerDialogComponent } from 'src/app/components/department-manager-dialog/department-manager-dialog.component';
import { AuthService, ExportService, LocationService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { Department } from 'src/app/types';

@Component({
  selector: 'app-department-manager',
  templateUrl: './department-manager.component.html',
  styleUrls: ['./department-manager.component.scss'],
})
export class DepartmentManagerComponent {
  @ViewChild('paginator', { static: true }) paginator: unknown;

  public departmentColumns = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Name' },
    { field: 'company_id', header: 'Company ID' },
    { field: 'company_name', header: 'Company Name' },
    // { field: 'suite_occupancies', header: 'Suite Names' }, // suite_occupancies[]
    { field: 'building_id', header: 'Building ID' },
    { field: 'building_name', header: 'Building Name' },
    { field: 'floor_id', header: 'Floor ID' },
    { field: 'floor_name', header: 'Floor Name' },
    { field: 'is_enabled', header: 'Active' },
  ];
  departments = [];
  filteredCount = { count: 0 };
  pageEvent: PageEvent;
  pageSize: number;
  search: string;
  showInactive = false;
  sortDirection: 'asc' | 'desc' = 'asc';
  sortProperty = 'name';

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private exportService: ExportService,
    private locationService: LocationService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar
  ) {}

  public get isDepartmentsManager(): boolean {
    return this.authService.isBuildingsDepartmentsSuitesManager;
  }

  public delete(department: Department): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Delete ${department.name}`,
        descriptionText: 'Are you sure you want to delete this department?',
      })
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting department...');
          await this.locationService.deleteDepartment(department.id).toPromise();
          await this.getDepartments();
          this.snackbar.open('Department deleted');
          this.progressIndicatorService.close();
        }
      });
  }

  public exportDepartmentData(): void {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Downloading Data..');

    const departments = this.departments?.filter((department: Department) => {
      const searchTerm = this.search?.toLowerCase();
      if (searchTerm) {
        return (
          department.name?.toLowerCase()?.includes(searchTerm) ||
          department.building_name?.toLowerCase()?.includes(searchTerm) ||
          department.suite_name?.toLowerCase()?.includes(searchTerm) ||
          department.suite_names?.toLowerCase()?.includes(searchTerm) ||
          department.company_name?.toLowerCase()?.includes(searchTerm) ||
          department.floor_names?.toLowerCase()?.includes(searchTerm)
        );
      } else {
        return department;
      }
    });

    const departmentRows = departments?.map((department: Department) => {
      let departmentDataRows = '';

      for (const [index, departmentColumn] of this.departmentColumns.entries()) {
        let departmentField = department[departmentColumn.field];

        // make sure it exist
        if (departmentField) {
          if (departmentColumn?.field?.toLowerCase() === 'is_enabled') {
            departmentField = departmentField ? 'Yes' : 'No';
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            departmentField = `${departmentField || ''}`?.trim()?.replace(',', '');
          }
        } else {
          departmentField = 'N/A';
        }
        departmentDataRows += departmentField;
        if (index < this.departmentColumns.length - 1) {
          departmentDataRows += ',';
        }
      }
      return departmentDataRows;
    });

    const exportDepartmentData = [
      `${this.departmentColumns
        ?.map((departmentColumn: { field: string; header: string }) => departmentColumn.header)
        .join(',')}`,
      ...departmentRows,
    ];

    const departmentFilename = `departments_${moment().format('YYYY-MM-DD')}`;

    this.exportService.exportDataWithConfirmation(
      exportDepartmentData,
      departmentFilename,
      'Confirm Data Export',
      `Department data export will use any provided search term. Are you sure you wish to continue?`
    );

    this.progressIndicatorService.close();
  }

  public async getDepartments(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading departments...');
    const fields = [
      'id',
      'name',
      'building_id',
      'building_name',
      'floor_name',
      'floor_id',
      'is_enabled',
      'suite_occupancies{building,suite{floor},department{floor_name}}',
      'company{id,name,type_id}',
    ];
    const colors = ['bg-shade-ltblue', 'bg-shade-gray', 'bg-shade-ltpurple'];
    await this.locationService
      .getDepartments(fields)
      .toPromise()
      .then((response) => {
        this.departments = response.map((d) => {
          const department: any = d;
          let colorIndex = 0;
          department.suite_occupancies = department.suite_occupancies.map((so, i) => {
            so.color = colors[colorIndex % 3];
            if (so.building_id !== department.suite_occupancies[i - 1]?.building_id) {
              so.color = colors[++colorIndex % 3];
            }
            return so;
          });
          department.suite_names = department.suite_occupancies?.reduce(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            (acc, cur) => acc + (cur?.suite_name || ''),
            ''
          );
          department.floor_names = department.suite_occupancies?.reduce(
            (acc, cur) => acc + (cur?.department?.floor_name || ''),
            ''
          );
          department.company_name = department.company?.name || null;
          return department;
        });
      });
    this.filteredCount.count = this.departments.length;
    this.progressIndicatorService.close();
  }

  public openDepartmentDialog(action: 'Add' | 'Edit', department: Department = {}): void {
    const dialogRef = this.dialog.open(DepartmentManagerDialogComponent, {
      width: '480px',
      data: {
        action,
        department,
      },
    });

    dialogRef.afterClosed().subscribe((reload) => {
      if (reload === true) {
        void this.getDepartments();
      }
    });
  }

  public sort(property: string): void {
    if (this.sortProperty === property) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortProperty = property;
      this.sortDirection = 'asc';
    }
  }
}
