import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FileApprovalService, ProjectService } from 'src/app/services';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import { FileApproval } from 'src/app/models';
import { FileApprovalStatus } from 'src/app/enums';
import { FileApprovalItem, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-approval-review-dialog',
  templateUrl: './file-approval-review-dialog.component.html',
  styleUrls: ['./file-approval-review-dialog.component.scss'],
})
export class FileApprovalReviewDialogComponent implements OnInit {
  public reviewDescription = 'Please review these forms and submit a response';

  public filesToReview: UhatFileReference[] = [];

  public reviewMessage: string;

  public reviewSelection: 'Approve' | 'Reject';

  private reviewItem: FileApprovalItem;

  constructor(
    private dialogRef: MatDialogRef<FileApprovalReviewDialogComponent>,
    private approvalService: FileApprovalService,
    private projectTenantService: ProjectTenantService,
    private pebService: PEBService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    // Get approval detail data by querying for the approval id
    if (this.data.approval) {
      const fileApproval: FileApproval = this.data.approval;
      this.reviewDescription = fileApproval.description;
      this.filesToReview = fileApproval.files || [];
      this.approvalService.getApproval(+this.data.approval.id).subscribe((approval) => {
        this.reviewDescription = approval.description;
        this.filesToReview = approval.files || [];
        this.reviewItem = this.data.item;
      });
    }
  }

  public async submitReview() {
    if (this.reviewSelection === 'Approve') {
      this.approvalService
        .updateApprovalItemStatus(+this.reviewItem.id, FileApprovalStatus.Approved, this.reviewMessage)
        .subscribe((result) => {
          this.dialogRef.close(result);
        });
    } else {
      this.approvalService
        .updateApprovalItemStatus(+this.reviewItem.id, FileApprovalStatus.Rejected, this.reviewMessage)
        .subscribe((result) => {
          this.projectTenantService
            .rejectPeb(this.projectService.currentSelectedProjectId)
            .subscribe((rejectedResult) => {
              this.dialogRef.close(rejectedResult);
            });
        });
    }
  }

  public uploadFile(file) {
    // TODO
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
