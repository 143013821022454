import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserType } from 'src/app/enums';
import {
  AuthService,
  CompanySearchService,
  LocationService,
  ModuleService,
  UserSearchService,
  UserService,
} from 'src/app/services';
import { APIFilter, Building, Company, Department, Floor, Suite, User, Workspace } from 'src/app/types';
@Component({
  selector: 'app-new-account-modal',
  templateUrl: './new-account-modal.component.html',
  styleUrls: ['./new-account-modal.component.scss'],
})
export class NewAccountModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private userService: UserService,
    public companySearchService: CompanySearchService,
    private locationService: LocationService,
    private fb: FormBuilder,
    public userSearchService: UserSearchService,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<NewAccountModalComponent>,
    private moduleService: ModuleService,
    public authService: AuthService
  ) {}

  workspaces: Workspace[] = [];
  UserType = UserType;

  userTypeFormGroup: FormGroup = this.fb.group({
    user_type: [{ value: '', disabled: false }, [Validators.required]],
  });

  lastUser: User;
  lastCompany: Company;
  acceptedUserTypes;

  userInfoFormGroup: FormGroup = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    company: ['', [Validators.compose([Validators.required, this.companyValidator()])]],
    title: [''],
    manager: ['', [Validators.compose([Validators.required, this.managerValidator()])]],
    is_email_enabled: [1],
    default_module_id: [],
  });

  userContactFormGroup: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    office_phone: [''],
    cell_phone: [''],
  });

  userLocationFormGroup: FormGroup = this.fb.group({
    building: [''],
    floor: [''],
    suite: [''],
    department: [''],
  });

  get user_type() {
    return this.userTypeFormGroup.get('user_type');
  }

  get first_name() {
    return this.userInfoFormGroup.get('first_name');
  }
  get last_name() {
    return this.userInfoFormGroup.get('last_name');
  }
  get company() {
    return this.userInfoFormGroup.get('company');
  }
  get title() {
    return this.userInfoFormGroup.get('title');
  }

  get manager() {
    return this.userInfoFormGroup.get('manager');
  }

  get email() {
    return this.userContactFormGroup.get('email');
  }

  get office_phone() {
    return this.userContactFormGroup.get('office_phone');
  }
  get cell_phone() {
    return this.userContactFormGroup.get('cell_phone');
  }

  get building() {
    return this.userLocationFormGroup.get('building');
  }
  get department() {
    return this.userLocationFormGroup.get('department');
  }
  get floor() {
    return this.userLocationFormGroup.get('floor');
  }
  get suite() {
    return this.userLocationFormGroup.get('suite');
  }
  get default_module_id() {
    return this.userInfoFormGroup.get('default_module_id');
  }

  get validInfo(): boolean {
    return (
      this.userLocationFormGroup.valid &&
      this.userContactFormGroup.valid &&
      this.userInfoFormGroup.valid &&
      this.userTypeFormGroup.valid
    );
  }

  userTypes;
  userSearchValue;
  companySearchValue;

  buildings: Building[] = [];
  floors: Floor[] = [];
  suites: Suite[] = [];
  departments: Department[] = [];

  loaders = {
    creatingUser: false,
  };

  async ngOnInit() {
    this.getBuildings();
    this.workspaces = await this.moduleService.getAllWorkspaces().toPromise();

    await this.userService
      .getUserTypes()
      .toPromise()
      .then((userTypes) => {
        userTypes.map((userType) => {
          if (userType.name === 'Staff') {
            userType.name = 'UHAT/1Call';
          }
        });
        this.userTypes = userTypes;
      });

    this.acceptedUserTypes = this.userTypes.filter((userType) => userType.id !== 1);

    this.user_type.valueChanges.subscribe((userType) => {
      if (userType) {
        this.userTypeChanged(userType.id);
      }
    });

    if (this.data && this.data.userType && this.userTypes.map((ut) => ut.id).indexOf(this.data.userType.id) > -1) {
      this.user_type.setValue(this.userTypes.find((ut) => ut.id === this.data.userType.id));
    }

    if (this.data && this.data.company) {
      this.company.setValue(this.data.company);
    }

    if (this.data && this.data.department) {
      this.department.setValue(this.data.department);
    }

    if (this.data && this.data.userInfo) {
      this.addUserInfo();
    }
  }
  public emailTrim(): void {
    if (this.email?.value !== this.email?.value?.trim()) {
      // reset email with trimmed space
      this.email?.setValue(this.email?.value?.trim());
    }
  }

  public emailChanged(): void {
    const reg = /uhat.org$/;
    if (reg.test(this.email.value)) {
      this.acceptedUserTypes = this.userTypes;
    } else {
      this.acceptedUserTypes = this.acceptedUserTypes?.filter((userType) => userType.id !== 1);
    }
  }

  private addUserInfo() {
    const names = this.data.userInfo
      .split(' ')
      ?.map((name: string) => name?.trim())
      ?.filter((value: string) => !!value);
    if (/\S+@/.test(this.data.userInfo)) {
      this.email.setValue(this.data.userInfo);
    } else {
      this.first_name.setValue(names[0]);
      if (names.length > 1) {
        this.last_name.setValue(names[1]);
      }
    }
  }

  getBuildings() {
    const buildingFilters: APIFilter[] = [{ type: 'field', field: 'is_enabled', value: '1' }];
    this.locationService.getBuildings(['id', 'name'], buildingFilters).subscribe((buildings: Building[]) => {
      this.buildings = buildings;
    });
  }

  getFloors() {
    if (this.building.value) {
      const floorFilters: APIFilter[] = [
        { type: 'field', field: 'building_id', value: this.building.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floor.setValue(null);
        this.suite.setValue(null);
        this.department.setValue(null);
      });
    } else {
      this.floor.setValue(null);
      this.suite.setValue(null);
      this.department.setValue(null);
    }
  }

  getSuites() {
    if (this.floor.value) {
      const suiteFilters: APIFilter[] = [
        { type: 'field', field: 'floor_id', value: this.floor.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suite.setValue(null);
        this.department.setValue(null);
      });
    } else {
      this.suite.setValue(null);
      this.department.setValue(null);
    }
  }

  getDepartments() {
    if (this.suite.value) {
      const departmentFilters: APIFilter[] = [
        { type: 'field', field: 'suite_id', value: this.suite.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: ')' },
      ];
      this.locationService.getDepartments(['id', 'name'], departmentFilters).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.department.setValue(null);
      });
    } else {
      this.department.setValue(null);
    }
  }

  userTypeChanged(userTypeId) {
    this.company.setValue(null);
    if (userTypeId === 1) {
      this.manager.setValidators([Validators.compose([Validators.required, this.managerValidator()])]);
      this.company.setValidators([Validators.compose([Validators.required, this.companyValidator()])]);
    } else if (userTypeId === 2) {
      this.manager.setValidators(null);
      this.company.setValidators(null);
    } else if (userTypeId === 3) {
      this.manager.setValidators(null);
      this.company.setValidators([Validators.compose([Validators.required, this.companyValidator()])]);
    }
    this.updateValueAndValidity();
  }

  updateValueAndValidity() {
    this.first_name.updateValueAndValidity();
    this.last_name.updateValueAndValidity();
    this.company.updateValueAndValidity();
    this.title.updateValueAndValidity();
    this.manager.updateValueAndValidity();
    this.email.updateValueAndValidity();
    this.office_phone.updateValueAndValidity();
    this.cell_phone.updateValueAndValidity();
    this.building.updateValueAndValidity();
    this.department.updateValueAndValidity();
    this.floor.updateValueAndValidity();
    this.suite.updateValueAndValidity();
  }

  clearData() {
    this.first_name.setValue(null);
    this.last_name.setValue(null);
    this.company.setValue(null);
    this.title.setValue(null);
    this.manager.setValue(null);
    this.email.setValue(null);
    this.office_phone.setValue(null);
    this.cell_phone.setValue(null);
    this.building.setValue(null);
    this.department.setValue(null);
    this.floor.setValue(null);
    this.suite.setValue(null);
  }

  async createUser() {
    if (this.validInfo) {
      this.loaders.creatingUser = true;
      const userTypeFromForm = this.userTypeFormGroup.getRawValue();
      const userInfoFromForm = this.userInfoFormGroup.getRawValue();
      const userContactFromForm = this.userContactFormGroup.getRawValue();
      const userLocationFromForm = this.userLocationFormGroup.getRawValue();
      const userToCreate: User = {};
      if (userTypeFromForm.user_type) {
        userToCreate.user_type_id = userTypeFromForm.user_type.id;
      }
      if (userInfoFromForm.first_name?.trim()) {
        userToCreate.first_name = userInfoFromForm.first_name?.trim();
      }
      if (userInfoFromForm.last_name?.trim()) {
        userToCreate.last_name = userInfoFromForm.last_name?.trim();
      }
      userToCreate.company_id = userInfoFromForm.company?.id;

      if (userInfoFromForm.title?.trim()) {
        userToCreate.title = userInfoFromForm.title?.trim();
      }
      if (userInfoFromForm.manager) {
        userToCreate.manager_id = userInfoFromForm.manager.id;
      }
      if (userContactFromForm.email?.trim()) {
        userToCreate.email = userContactFromForm.email?.trim();
      }
      if (userContactFromForm.office_phone) {
        userToCreate.office_phone = userContactFromForm.office_phone;
      }
      if (userContactFromForm.cell_phone) {
        userToCreate.cell_phone = userContactFromForm.cell_phone;
      }
      if (userLocationFromForm.building) {
        userToCreate.building_id = userLocationFromForm.building.id;
      }
      if (userLocationFromForm.floor) {
        userToCreate.floor_id = userLocationFromForm.floor.id;
      }
      if (userLocationFromForm.suite) {
        userToCreate.suite_id = userLocationFromForm.suite.id;
      }
      if (userLocationFromForm.department) {
        userToCreate.department_id = userLocationFromForm.department.id;
      }

      userToCreate.default_module_id = userToCreate.user_type_id === 1 ? userInfoFromForm.default_module_id : null;
      userToCreate.is_email_enabled =
        userToCreate.user_type_id === UserType.Staff ? (userInfoFromForm.is_email_enabled ? 1 : 0) : 0;
      const createdUser: User = await this.userService.createUser(userToCreate).toPromise();
      this.loaders.creatingUser = false;
      this.snackbar.open('User created! They will receive an email invitation shortly.');
      this.dialogRef.close(createdUser);
    }
  }

  searchBoxManagerValueChange() {
    if (this.manager.value) {
      this.userSearchService.filterUsers(this.manager.value);
    }
  }

  get filteredUsers() {
    return this.userSearchService.getFilteredUsers();
  }

  fireManagerAssigneeSelectionChange(user: User) {
    this.lastUser = user;
  }

  searchBoxCompanyValueChange() {
    this.companySearchService.filterDepartments(this.company.value);
  }

  get filteredCompanies(): Company[] {
    return this.companySearchService.getFilteredCompanies();
  }

  fireCompanyAssigneeSelectionChange(company: Company) {
    this.lastCompany = company;
  }

  managerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && this.lastUser && control.value.id !== this.lastUser.id) {
        return { isValidManager: true };
      }
      return null;
    };
  }

  companyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && this.lastCompany && control.value.id !== this.lastCompany.id) {
        return { isValidCompany: true };
      }
      return null;
    };
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
