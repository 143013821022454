<div class="container-fluid p-4">
  <div class="row align-items-center component-subheader">
    <div class="col">
      <h4 class="dkblue font-weight-semibold">
        As-Builts &nbsp;
        <small class="ltblue" *ngIf="!loaders.gettingAsBuilts">
          {{ asBuilts && asBuilts.length ? asBuilts.length : 0 }}
        </small>
        <i class="la la-spinner la-spin ltblue" *ngIf="loaders.gettingAsBuilts"></i> &nbsp;
        <span *ngIf="loaders.updatingAsBuilt" class="ltblue"
          ><i class="la la-spinner la-spin ltblue"></i> Uploading...</span
        >
      </h4>
    </div>
    <div class="col-auto text-right">
      <button mat-flat-button color="success" (click)="addEditAsBuilt()">+ As-Built</button>
    </div>
  </div>
  <br />
  <div class="portlet-table-header p-0 pl-4 pr-2">
    <div class="row">
      <div class="col-auto" style="width: 100px">
        <label class="main-label"> # </label>
      </div>
      <div class="col">
        <label class="main-label"> Subject </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Date </label>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
  <div class="ab-portlet m-portlet p-0 mb-4" m-portlet="true" *ngFor="let a of asBuilts">
    <div class="ease m-portlet__body pl-4 pr-2 pt-2 pb-2">
      <div class="row align-items-center">
        <div class="col-auto" style="width: 100px">
          <h6 (click)="a.is_expanded = !a.is_expanded" class="dkblue font-weight-semibold m-0 pointer">
            {{ a.local_index }}
          </h6>
        </div>
        <div class="col">
          <h6 (click)="a.is_expanded = !a.is_expanded" class="dkblue font-weight-semibold pointer m-0">
            {{ a.title }}
          </h6>
        </div>
        <div class="col-2">
          <h6 class="gray pointer m-0">
            {{ a.created_datetime | date: 'MM/dd/yyyy' }}
          </h6>
        </div>
        <div class="col-3 text-right">
          <button *ngIf="!a.is_expanded" (click)="a.is_expanded = !a.is_expanded" mat-icon-button class="ltgray">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="a.is_expanded" (click)="a.is_expanded = !a.is_expanded" mat-icon-button class="ltgray">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="pl_main_menu" class="ltgray">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #pl_main_menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="addEditAsBuilt(a)">
              <i class="la la-edit"></i>
              &nbsp; Edit As-Built
            </button>
            <button mat-menu-item class="red" (click)="removeAsBuilt(a)">
              <i class="la la-trash"></i>
              &nbsp; Remove As-Built
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="a.is_expanded" class="row mt-2 mb-4">
        <div class="col">
          <label class="main-label dkblue">Description</label>
          <h6 class="dkgray lh2 m-0" [innerHTML]="a.description"></h6>
        </div>
      </div>
      <div *ngIf="a.is_expanded && a.files" class="row mb-4">
        <div class="col">
          <mat-chip-list>
            <app-file-chip *ngFor="let f of a.files" [file]="f"></app-file-chip>
          </mat-chip-list>
        </div>
        <div class="col-1 text-right"></div>
      </div>
    </div>
  </div>
</div>
