import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { FileService } from 'src/app/services';
import { Tag, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-rename-dialog',
  templateUrl: './file-rename-dialog.component.html',
  styleUrls: ['./file-rename-dialog.component.scss'],
})
export class FileRenameDialogComponent implements OnInit {
  public dialogTitle = 'Edit File';
  public file: UhatFileReference;
  public allTags: Tag[] = [];

  public extension: string;
  public displayName: string;
  // these fields are used to check if the rename is valid
  public originalName = '';
  private lastCheckedName = '';
  private lastCheckedStatus = true;

  get isVerified() {
    return this.lastCheckedStatus;
  }

  get primaryTags() {
    if (this.selectedPrimaryTags > 0) {
      return this.allTags.filter((t) => +t.tag_parent_id === 0 && t.is_selected);
    } else {
      return this.allTags.filter((t) => +t.tag_parent_id === 0 && (!t.is_system_generated || t.is_selected));
    }
  }

  get possibleSecondaryTags() {
    return this.allTags.filter(
      (t) =>
        +t.tag_parent_id !== 0 &&
        (!t.is_system_generated || t.is_selected) &&
        this.allTags[this.allTags.indexOf(this.allTags.find((parent) => +parent.id === +t.tag_parent_id))].is_selected
    );
  }

  // returns the count of the primary tags selected (minus the parent tags)
  get selectedPrimaryTags() {
    return this.allTags.filter((t) => +t.tag_parent_id === 0 && !t.is_system_generated && t.is_selected).length;
  }

  // separates the file name into just the extension
  getExtension(name) {
    return name.substr(name.lastIndexOf('.'), name.length - 1);
  }

  // separates the file name into just the name without the extension
  getName(name) {
    return name.substr(0, name.lastIndexOf('.'));
  }

  constructor(
    public dialogRef: MatDialogRef<FileRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackBar: MatSnackBar,
    private fileService: FileService
  ) {
    this.file = cloneDeep(data.file);
    if (this.file && !this.file?.tags) {
      this.file.tags = [];
    }

    this.allTags = cloneDeep(data.allTags || []);
    this.allTags.forEach((tag) => (tag.is_selected = false));
    this.file.tags.forEach((t: Tag) => {
      this.allTags.find((tag) => +tag.id === +t.id).is_selected = true;
    });
    this.allTags = this.allTags.filter((t) => t.is_selected || t.is_enabled);
    this.originalName = this.getName(this.file.name);
    this.extension = this.getExtension(this.file.name);
    this.displayName = this.originalName;
    this.lastCheckedName = this.originalName;
  }

  ngOnInit() {}

  async checkName(event) {
    if (event === this.originalName) {
      this.lastCheckedStatus = true;
    } else if (event !== this.lastCheckedName) {
      this.lastCheckedStatus = !(await this.fileService
        .checkIfFileExists(event + this.extension, null, null, this.file.file_id || this.file.id)
        .toPromise());
      this.lastCheckedName = event;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close({
      name: this.lastCheckedName + this.extension,
      tags: this.allTags.filter((t) => t.is_selected),
    });
  }

  togglePrimaryTag(tag) {
    if (tag.is_system_generated) {
      this.snackBar.open('Tags added by system cannot be removed.');
      return;
    }
    if (this.selectedPrimaryTags > 0 && !tag.is_selected) {
      this.snackBar.open('Cannot add more than one primary tag.');
      return;
    }
    tag.is_selected = !tag.is_selected;
    if (+tag.tag_parent_id === 0) {
      this.allTags.filter((t) => +t.tag_parent_id === +tag.id).forEach((t) => (t.is_selected = false));
    }
  }

  toggleSecondaryTag(tag) {
    if (tag.is_system_generated) {
      this.snackBar.open('Tags added by system cannot be removed.');
      return;
    }
    tag.is_selected = !tag.is_selected;
  }
}
