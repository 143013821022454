import { Pipe, PipeTransform } from '@angular/core';

import { intersection } from 'lodash';
import * as moment from 'moment';

import { TaskDashboardFilter } from 'src/app/workspaces/construction/models';
import { Task } from 'src/app/types';

@Pipe({
  name: 'taskDashboardFilter',
})
export class TaskDashboardFilterPipe implements PipeTransform {
  // determines if the task is overdue or not, while optionally allowing completes as well
  private static isOverdue(task: Task): boolean {
    return TaskDashboardFilterPipe.daysUntilDate(task.due_date) < 0;
  }

  // determine if task is complete
  private static isComplete(task: Task): boolean {
    return task.status_name === 'Complete';
  }

  private static isApproval(task: Task): boolean {
    const taskAccessoryData = task.accessory_data ? JSON.parse(task.accessory_data) : null;
    return taskAccessoryData && taskAccessoryData.isReviewItem;
  }

  // gets the difference in days for the given date
  private static daysUntilDate(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  }

  // determines if the due date is between startDate and endDate, inclusive with granularity of a day
  private static inDateRange(date, startDate, endDate): boolean {
    return moment(date).startOf('day').isBetween(startDate, endDate, 'day', '[]');
  }

  public transform(tasks: Task[], filter: TaskDashboardFilter): any {
    if (filter.projectIds.length > 0) {
      tasks = tasks.filter((task) => intersection([task.project_id], filter.projectIds).length > 0);
    }

    if (filter.taskType === 'overdue_only') {
      // only overdue tasks
      tasks = tasks.filter(
        (task) => TaskDashboardFilterPipe.isOverdue(task) && !TaskDashboardFilterPipe.isComplete(task)
      );
    } else if (filter.taskType === 'approvals_only') {
      // @ts-ignore
      tasks = tasks.filter((task) => TaskDashboardFilterPipe.isApproval(task));
    } else {
      tasks = tasks.filter(
        (task) =>
          TaskDashboardFilterPipe.isOverdue(task) ||
          TaskDashboardFilterPipe.inDateRange(task.due_date, filter.startDate, filter.endDate) ||
          !task.due_date
      );
    }
    if (!filter.showComplete) {
      tasks = tasks.filter((task) => !TaskDashboardFilterPipe.isComplete(task));
    }

    switch (filter.assignedTo) {
      // check if the task is assigned to the current user
      case 'self':
        tasks = tasks.filter((task) => task.assigned_user_id === filter.userId);
        break;
      // check if there is any intersection between the task follower ids and the user's team ids
      case 'team':
        tasks = tasks.filter((task) => intersection([task.assigned_user_id], filter.teamIds).length > 0);
        break;
      // check if the current user is a follower for the task
      case 'following':
        tasks = tasks.filter(
          (task) => task.follower_ids.includes(filter.userId) && task.assigned_user_id !== filter.userId
        );
        break;
      case 'unassigned':
        tasks = tasks.filter((task) => !task.assigned_user_id);
        break;
      default:
        break;
    }

    if (!filter.showNoDueDate) {
      tasks = tasks.filter((task) => task.due_date != null);
    }

    // order by due date (not complete or no due date) => no due date => complete

    // if this needs to be changed, here is an easy format to change the below
    // a = complete
    // if b == complete, ~ (sort by due date)
    // if b == no date, -1 (so a wins)
    // if b == standard date, -1 (so a wins)
    // a = no date
    // if b == complete, 1 (sort by due date)
    // if b == no date, -1 (so a wins)
    // if b == standard date, -1 (so a wins)
    // a = standard
    // if b == complete, ~ (sort by due date)
    // if b == no date, -1 (so a wins)
    // if b == standard date, -1 (so a wins)
    tasks.sort((a, b) => {
      if (TaskDashboardFilterPipe.isComplete(a)) {
        if (TaskDashboardFilterPipe.isComplete(b)) {
          return moment(a.due_date).startOf('day').diff(moment(b.due_date).startOf('day'));
        } else if (!b.due_date) {
          return -1;
        } else {
          return -1;
        }
      } else if (!a.due_date) {
        if (TaskDashboardFilterPipe.isComplete(b)) {
          return 1;
        } else if (!b.due_date) {
          return moment(a.due_date).startOf('day').diff(moment(b.due_date).startOf('day'));
        } else {
          return 1;
        }
      } else {
        if (TaskDashboardFilterPipe.isComplete(b)) {
          return 1;
        } else if (!b.due_date) {
          return -1;
        } else {
          return moment(a.due_date).startOf('day').diff(moment(b.due_date).startOf('day'));
        }
      }
    });

    return tasks;
  }
}
