<div
  id="title-sixty-one-key-control-columns"
  data-test-id="title-sixty-one-key-control-columns"
  class="title-sixty-one-key-control-columns m-portlet"
>
  <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
    <div class="row align-items-center">
      <div
        class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
        style="width: 110px"
        [ngClass]="{ sortedBy: fieldToSortBy === 'code' }"
      >
        <h6 (click)="updateSortByField('code')" class="m-0 text-truncate header-title mr-auto">Code</h6>
        <i *ngIf="fieldToSortBy === 'code'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
        style="max-width: 110px"
        [ngClass]="{ sortedBy: fieldToSortBy === 'building_code,floor_name' }"
      >
        <h6 (click)="updateSortByField('building_code,floor_name')" class="m-0 text-truncate header-title mr-auto">
          Location
        </h6>
        <i *ngIf="fieldToSortBy === 'building_code,floor_name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center"
        [ngClass]="{ sortedBy: fieldToSortBy === 'title' }"
      >
        <h6 (click)="updateSortByField('title')" class="m-0 text-truncate header-title mr-auto">Short Description</h6>
        <i *ngIf="fieldToSortBy === 'title'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div
        class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
        [ngClass]="{ sortedBy: fieldToSortBy === 'current_phase_name' }"
      >
        <h6 (click)="updateSortByField('current_phase_name')" class="m-0 text-truncate header-title mr-auto">Phase</h6>
        <i *ngIf="fieldToSortBy === 'current_phase_name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.BUDGET_FINALIZED_61 }}
        </h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.PROJECT_PROPERLY_BID_61 }}
        </h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.VENDOR_CONTRACTS_61 }}
        </h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.FIRE_MARSHAL_61 }}
        </h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.CONTRACT_COMPLETION_61 }}
        </h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 header-title mr-auto">
          {{ KEY_CONTROL_HEADING.ALL_INVOICES__PROCESSED_61 }}
        </h6>
      </div>
    </div>
  </div>
  <div
    data-test-id="project-list-item"
    [routerLink]="['/projects/' + project.id]"
    *ngFor="
      let project of projects
        | projectListFilter: filter
        | orderByField: fieldToSortBy:sortDirection
        | slice: startIndex:endIndex
    "
    class="m-portlet__body item-no-hover p-3 pt-4 pb-4"
  >
    <div class="row align-items-center">
      <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 110px">
        <h6
          [routerLink]="['/projects/' + project.id]"
          class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle"
        >
          {{ project.code }}
        </h6>
        <i *ngIf="project?.status?.id === 1" class="material-icons green ml-auto">label_important</i>
        <i *ngIf="project?.status?.id === 3" class="material-icons red ml-auto">flag</i>
        <i *ngIf="project?.status?.id === 2" class="material-icons ltblue ml-auto">next_plan</i>
        <i *ngIf="project?.status?.id === 4" class="material-icons gray ml-auto">done_all</i>
      </div>
      <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
        <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
          {{ project.building?.code }}{{ project.floor?.code ? '-' : '' }}{{ project.floor?.code }}
        </p>
      </div>
      <div class="col-3 pl-3 pr-3 d-flex flex-column data-item">
        <p class="m-0 dkblue font-weight-semibold d-block">
          {{ project.title }}
        </p>
      </div>
      <div class="col pl-3 pr-3 d-flex align-items-center data-item">
        <p class="m-0 mr-auto dkblue font-weight-normal">
          {{ (project.current_phase && project.current_phase_name) || '-' }}
        </p>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.BUDGET_FINALIZED_61)
        "
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.BUDGET_FINALIZED_61?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.PROJECT_PROPERLY_BID_61)
        "
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.PROJECT_PROPERLY_BID_61?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.VENDOR_CONTRACTS_61)
        "
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.VENDOR_CONTRACTS_61?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.FIRE_MARSHAL_61)"
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.FIRE_MARSHAL_61?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.CONTRACT_COMPLETION_61)
        "
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.CONTRACT_COMPLETION_61?.task"
        ></app-key-control-icon-badge>
      </div>
      <div
        (click)="
          openKeyControlsDialog($event, project.id, projectKeyControlTemplates[project?.id]?.ALL_INVOICES__PROCESSED_61)
        "
        class="col pl-3 pr-3 align-items-center data-item text-center"
      >
        <app-key-control-icon-badge
          [task]="projectKeyControlTemplates[project?.id]?.ALL_INVOICES__PROCESSED_61?.task"
        ></app-key-control-icon-badge>
      </div>
    </div>
  </div>
</div>
<div class="not-found-prompt" *ngIf="filter?.searchString && filter?.statusId">
  <div class="row">
    <div class="col text-center">
      <h5 class="dkblue mt-4">Didn't find what you're looking for?</h5>
      <button mat-stroked-button color="accent" class="mt-2 bg-white" (click)="changeFilterStatus(0)">
        Search All Projects
      </button>
    </div>
  </div>
</div>
