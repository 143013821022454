import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AuthService, ExportService, LocationService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { Suite } from 'src/app/types';
import { SuitesManagerDialogComponent } from '../suites-manager-dialog/suites-manager-dialog.component';

@Component({
  selector: 'app-suites-manager',
  templateUrl: './suites-manager.component.html',
  styleUrls: ['./suites-manager.component.scss'],
})
export class SuitesManagerComponent {
  @ViewChild('paginator', { static: true }) paginator: unknown;

  filteredCount = { count: 0 };
  pageEvent: PageEvent;
  pageSize: number;
  search: string;
  showInactive = false;
  public suiteColumns = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Number/Name' },
    { field: 'building_id', header: 'Building ID' },
    { field: 'building_name', header: 'Building Name' },
    { field: 'floor_id', header: 'Floor ID' },
    { field: 'floor_name', header: 'Floor Name' },
    { field: 'department_names', header: 'Occupying Department(s)' },
    { field: 'is_enabled', header: 'Active' },
  ];
  sortDirection: 'asc' | 'desc' = 'asc';
  sortProperty = 'name';
  suites: Suite[] = [];

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private exportService: ExportService,
    private locationService: LocationService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar
  ) {}

  public get isSuitesManager(): boolean {
    return this.authService.isBuildingsDepartmentsSuitesManager;
  }

  public delete(suite: Suite): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Delete ${suite.name}`,
        descriptionText: 'Are you sure you want to delete this suite?',
      })
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
      .subscribe(async (isConfirmed): Promise<void> => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting suite...');
          await this.locationService.deleteSuite(suite.id).toPromise();
          await this.getSuites();
          this.snackbar.open('Suite deleted');
          this.progressIndicatorService.close();
        }
      });
  }

  public exportSuiteData(): void {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Downloading Suites...');

    const suiteRows = this.suites
      ?.filter((suite: Suite) => {
        const searchTerm = this.search?.toLowerCase();
        if (searchTerm) {
          return (
            suite.name?.toLowerCase()?.includes(searchTerm) ||
            suite.building_name?.toLowerCase()?.includes(searchTerm) ||
            suite.department_names?.toLowerCase()?.includes(searchTerm) ||
            suite.floor_name?.toLowerCase()?.includes(searchTerm)
          );
        } else {
          return suite;
        }
      })
      ?.map((suite: Suite) => {
        let suiteDataRows = '';

        for (const [index, suiteColumn] of this.suiteColumns.entries()) {
          let suiteField = suite[suiteColumn.field];

          // make sure it exist
          if (suiteField) {
            if (suiteColumn?.field?.toLowerCase() === 'is_enabled') {
              suiteField = suiteField ? 'Yes' : 'No';
            } else {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              suiteField = `${suiteField || ''}`?.trim()?.replace(',', '');
            }
          } else {
            suiteField = 'N/A';
          }
          suiteDataRows += suiteField;
          if (index < this.suiteColumns.length - 1) {
            suiteDataRows += ',';
          }
        }
        return suiteDataRows;
      });

    const exportSuiteData = [
      `${this.suiteColumns?.map((suiteColumn: { field: string; header: string }) => suiteColumn.header).join(',')}`,
      ...suiteRows,
    ];

    const suiteFilename = `suites_${moment().format('YYYY-MM-DD')}`;

    this.exportService.exportDataWithConfirmation(
      exportSuiteData,
      suiteFilename,
      'Confirm Data Export',
      `Suite data export will use any provided search term. Are you sure you wish to continue?`
    );

    this.progressIndicatorService.close();
  }

  public async getSuites(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading suites...');
    await this.locationService
      .getSuites(['id', 'name', 'building', 'floor', 'is_enabled', 'suite_occupancies{department}'])
      .toPromise()
      .then((response) => {
        this.suites = response.map((s) => {
          const suite: Suite = s;
          suite.building_name = suite.building?.name;
          suite.floor_name = suite.floor?.name;
          suite.department_names = suite.suite_occupancies?.reduce(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            (acc, cur) => acc + (cur?.department?.name || ''),
            ''
          );
          return suite;
        });
      });
    this.filteredCount = { count: this.suites.length };
    this.progressIndicatorService.close();
  }

  public openSuiteDialog(action: 'Add' | 'Edit', suite?: Suite): void {
    if (!suite) {
      suite = {};
    } else {
      suite = JSON.parse(JSON.stringify(suite));
    }
    const dialogRef = this.dialog.open(SuitesManagerDialogComponent, {
      width: '480px',
      data: {
        action,
        suite,
      },
    });

    dialogRef.afterClosed().subscribe((reload) => {
      if (reload === true) {
        void this.getSuites();
      }
    });
  }

  public sort(property: string): void {
    if (this.sortProperty === property) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortProperty = property;
      this.sortDirection = 'asc';
    }
  }
}
