import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { uniqBy } from 'lodash';

import {
  AuthService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UserService,
} from 'src/app/services';
import { SubmittalsService } from 'src/app/workspaces/construction/services';

import { ResourceType, SubmittalStatus, TaskReviewStatus } from 'src/app/enums';

import { TaskAccessoryData } from 'src/app/types';
import { BidPackage, ProjectConstruction } from 'src/app/workspaces/construction/types';
import { catchError, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-submittals',
  templateUrl: './submittals.component.html',
  styleUrls: ['./submittals.component.scss'],
})
export class SubmittalsComponent implements OnInit, OnDestroy {
  @Input() project: ProjectConstruction;

  constructor(
    private fileService: FileService,
    private handleErrorService: HandleErrorService,
    private userService: UserService,
    private submittalsService: SubmittalsService,
    private taskService: ProjectTaskService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private submittalSelectDialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private authService: AuthService,
    private modalService: ModalService
  ) {}

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;

  divWidth: number;

  private bidPackageFields = [
    'id',
    'number',
    'awarded_company_id',
    'awarded_company_name',
    'submittals{spec{category,items{type_id}},selected_item_ids,accessory_data}',
    'submittal_task{id,accessory_data,status_id,assigned_user{id,first_name,last_name,user_type_id}}',
    'trade',
    'trade_name',
    'transmittal_revision',
  ];

  public approvedSubmittals = 0;
  public bidPackages: BidPackage[];
  public bidVendors: any[];
  public displayBidPackages: any[];
  public selectedBidPackage: { id: number; label: string } = { id: null, label: 'All Trades' };
  public selectedVendor: { id: number; label: string } = { id: null, label: 'All Suppliers' };
  public selectedStatus = SubmittalStatus.All;
  public selectedStatusLabel = 'All Statuses';
  public receivedSubmittals = 0;
  public searchTerm = new FormControl('');
  public statusData = {};
  public totalSubmittals = 0;
  public submittalUpdatedRefresh: any;

  async ngOnInit() {
    this.getDivWidth();
    await this.refresh();

    this.submittalUpdatedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      const accessoryData = (data?.task?.accessory_data && JSON.parse(data.task.accessory_data)) || null;

      const parentId = accessoryData?.parentId;
      const currentBidPackage = this.bidPackages.find((bp) => bp.id === parentId);
      currentBidPackage.submittal_task.accessory_data = data.task.accessory_data;
      this.getSubmittalTaskViewData(currentBidPackage, false);
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.submittalUpdatedRefresh.unsubscribe();
    } catch (e) {}
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Data...');
    this.project = this.projectService.currentSelectedProject;

    this.bidPackages = await this.projectService
      .getBidPackages(
        [
          {
            type: 'field',
            field: 'project_id',
            value: this.project.id.toString(),
          },
        ],
        this.bidPackageFields
      )
      .toPromise();

    this.bidPackages = this.bidPackages.filter((bp) => bp.submittals?.length);
    this.displayBidPackages = [];

    for (const bp of this.bidPackages) {
      this.totalSubmittals += bp.submittals?.length || 0;

      const categories = uniqBy(
        bp.submittals.map((i) => ({
          id: i.spec.category.id,
          code: i.spec.category.code,
          name: i.spec.category.name,
          submittals: [],
        })),
        (c) => c.id
      );

      for (const submittal of bp.submittals) {
        const category = categories.find((c) => c.id === submittal.spec.category_id);
        category.submittals.push(submittal);

        // Splits the submittal items by type_id
        submittal.display_items = [[], []];
        submittal.spec.items.forEach((item) => {
          const arrayIndex = item.type_id === 1 ? 1 : 0;
          submittal.display_items[arrayIndex].push(item);
        });

        // Sets submittal items so that ones that have been selected in the past will appear on load.
        submittal.selected_item_ids = JSON.parse(submittal.selected_item_ids);
        for (const item of submittal.spec.items) {
          if (submittal.selected_item_ids.includes(item.id)) {
            item.is_added = true;
          }
        }
      }

      this.getSubmittalTaskViewData(bp);

      bp.submittal_categories = categories;
      this.sortBidPackages(bp);
    }

    this.bidVendors = uniqBy(
      this.bidPackages.map((bp) => ({
        id: bp.awarded_company_id,
        name: bp.awarded_company_name,
      })),
      (c) => c.id
    );

    this.progressIndicatorService.close();
    for (const bp of this.bidPackages) {
      for (const submittal of bp.submittals) {
        const files = await this.fileService.getFilesByParentId(ResourceType.Submittal, submittal.id).toPromise();

        if (files?.length) {
          submittal.files = files;
        }
        submittal.is_not_loading = true;
      }
    }
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  get SubmittalStatus() {
    return SubmittalStatus;
  }

  // Gets the visual data for the submittal review task. Task icon, text, color
  private getSubmittalTaskViewData(bidPackage: BidPackage, onLoad = true) {
    const data: { color?: string; icon?: string; text?: string; userId?: number } = {};
    if (bidPackage.submittal_task_id) {
      const accessoryData: TaskAccessoryData =
        (bidPackage.submittal_task?.accessory_data && JSON.parse(bidPackage.submittal_task.accessory_data)) || null;
      const reviewStatus = accessoryData?.reviewChain?.find(
        (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
      );

      bidPackage.submittal_files = accessoryData?.reviewFiles || [];
      if (accessoryData?.isReviewItem) {
        this.receivedSubmittals += (onLoad && bidPackage.submittals?.length) || 0;
        if (!reviewStatus) {
          this.approvedSubmittals += bidPackage.submittals?.length || 0;

          data.color = 'green';
          data.icon = 'check_circle';
          data.text = 'Approved';
        } else if (reviewStatus?.status === TaskReviewStatus.Pending) {
          data.color = 'ltblue';
          data.text = 'In Review';
          data.userId = reviewStatus.id;
        } else if (reviewStatus?.status === TaskReviewStatus.Rejected) {
          data.color = 'red';
          data.icon = 'cancel';
          data.text = 'Rejected';
        }
      } else {
        data.color = 'gray';
        data.icon = 'radio_button_unchecked';
        data.text = 'Awaiting Supplier';
      }
    } else {
      data.color = 'red';
      data.icon = 'radio_button_unchecked';
      data.text = 'No Review Task';
    }

    this.statusData[bidPackage.id] = data;
  }

  private sortBidPackages(bidPackage: BidPackage) {
    const index = bidPackage.trade[0]?.is_consultant ? 0 : 1;
    if (
      (!this.selectedBidPackage.id || bidPackage.id === this.selectedBidPackage.id) &&
      (!this.selectedVendor.id || bidPackage.awarded_company_id === this.selectedVendor.id)
    ) {
      if (this.displayBidPackages[index]?.length) {
        this.displayBidPackages[index].push(bidPackage);
      } else {
        this.displayBidPackages[index] = [bidPackage];
      }
    }
  }

  public selectBidPackage(id: number, label: string) {
    this.selectedBidPackage = { id, label };
  }

  public selectVendor(id: number, label: string) {
    this.selectedVendor = { id, label };
  }

  public selectSubmittalStatus(status: SubmittalStatus) {
    this.selectedStatus = status;
    switch (status) {
      case SubmittalStatus.All:
        this.selectedStatusLabel = 'All Statuses';
        break;
      case SubmittalStatus.Received:
        this.selectedStatusLabel = 'Received';
        break;
      case SubmittalStatus.Approved:
        this.selectedStatusLabel = 'Approved';
        break;
      default:
        this.selectedStatusLabel = 'All Statuses';
    }
  }

  public toggleSubmittalView(bidPackage) {
    bidPackage.show_submittals = !bidPackage.show_submittals;
    // this.expandedBidPackages[bidPackage.id] = bidPackage.showBids;
    //
    // this.addToPreferences('expanded_bid_packages', this.expandedBidPackages);
  }

  public clearSearch() {
    this.searchTerm.reset();
  }

  public async previewFile(file) {
    await this.fileService.previewFile(file);
  }

  public downloadFile(file) {
    this.fileService
      .downloadFile(file)
      .pipe(
        map((result) => result),
        catchError((e) => {
          file.isDownloading = false;
          this.handleErrorService.handleError(e);
          return e;
        })
      )
      .subscribe((downloadedFileResult) => {
        saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      });
  }

  public async openViewTaskDialog(taskId: number) {
    if (taskId) {
      await this.modalService.openViewTaskModal(taskId).toPromise();
    } else {
      this.snackbar.open('Review task does not exist');
    }
  }
}
