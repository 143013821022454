import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WorkspaceDropdownComponent } from 'src/app/components';
import { TopicType, Workspace } from 'src/app/enums';
import { ModuleService, SidenavService, TopicService } from 'src/app/services';
import { APIFilter, Module, Topic, TopicCategory, TopicGroup, WorkOrder } from 'src/app/types';
@Component({
  selector: 'app-work-order-topic',
  templateUrl: './work-order-topic.component.html',
  styleUrls: ['./work-order-topic.component.scss'],
})
export class WorkOrderTopicComponent implements OnInit {
  @Input() module_id: number;
  @Input() _allowEditingWorkspace: boolean;
  @Input()
  get allowEditingWorkspace() {
    return this._allowEditingWorkspace;
  }
  set allowEditingWorkspace(canEdit: boolean) {
    if (canEdit !== null) {
      this._activateWorkspaceField();
      this._allowEditingWorkspace = canEdit;
      if (!canEdit) {
        this.workspace.setValue(this.workOrder?.module || this._moduleService.workspace);
        this.workspace.disable();
      } else {
        this.workspace.reset();
        this.workspace.enable();
      }
    }
  }
  @Input() workOrder: WorkOrder;
  // Used to trigger update of building list and priority associated with topic
  @Output() topicChanged = new EventEmitter<{ changeLevel: string; buildingIds: string; priorityId: string }>();
  @ViewChild('workspace', { static: true })
  private _workspace_component: WorkspaceDropdownComponent;

  private _workspaceTopicGroups: TopicGroup[] = []; // All topic groups in the workspace
  private _workspaceTopicCategories: TopicCategory[] = [];
  private _workspaceTopics: Topic[] = [];

  private _topicGroupFields =
    'id,name,building_ids,topic_categories{id,name,topic_group_id,topic_group{id,name},topics{id,name,topic_group_id,topic_type_id,workspace_id}}';
  private _topicCategoryFields =
    'id,name,topic_group_id,topic_group{id,name,building_ids},topics{id,topic_category_id,name,topic_type_id,workspace_id}';
  private _topicFields =
    'id,name,topic_category_id,topic_category{id,name,topic_group_id,topic_group{id,name,building_ids}},topic_type_id,topic_type{id,name},workspace_id,workspace{id,name},priority_id,work_order_priority{id,name}';

  public filteredTopicGroups$: Observable<TopicGroup[]>; // Workspace topic groups that have been filtered
  public filteredTopicCategories$: Observable<TopicCategory[]>;
  public filteredTopics$: Observable<Topic[]>;

  private _currentTopicCategories: TopicCategory[] = [];
  public currentTopics: Topic[] = [];

  private _lastTopicGroup: TopicGroup;
  private _lastTopicCategory;
  public lastTopic;
  public isClearingGroup = false;
  public isClearingCategory = false;
  public isClearingTopic = false;

  public topicFormGroup: FormGroup = this._fb.group({
    topic_group: [''],
    topic_category: [''],
    topic: ['', [Validators.required, this.topicValidator()]],
  });

  constructor(
    private _fb: FormBuilder,
    private _moduleService: ModuleService,
    private _sidenavService: SidenavService,
    private _topicService: TopicService
  ) {}

  async ngOnInit() {
    if (this.workOrder?.module_id) {
      const workspace = await this._moduleService
        .getWorkspaces(
          ['id', 'name', 'icon', 'workspace_type'],
          [{ type: 'field', field: 'id', value: `${this.workOrder.module_id}` }]
        )
        .toPromise();
      this.workspace.setValue(workspace[0]);
    }

    // subscribe to the various items
    this._autocompleteTopicGroups();
    this._autocompleteTopicCategories();
    this._autocompleteTopics();

    // Order is important here

    await this._getTopicsByWorkspace();
    await this._getTopicCategoriesByWorkspace();
    await this._getTopicGroupsByWorkspace();

    this._currentTopicCategories = this._workspaceTopicCategories;
    this.currentTopics = this._workspaceTopics;
    this.populateTopicFields();

    if (!this.topic_group?.value?.id && this.workOrder?.linkedTaskId && this.topic_group?.value?.length) {
      // replace it with its first child,
      this.topic_group.setValue(this.topic_group.value[0]);
    }
  }

  private _activateWorkspaceField() {
    this.topicFormGroup.addControl('workspace', this._workspace_component.workspace);

    this._workspace_component.hideCancel = true;
  }

  get topic_category(): AbstractControl {
    return this.topicFormGroup.get('topic_category');
  }

  get topic_group(): AbstractControl {
    return this.topicFormGroup.get('topic_group');
  }

  get topic(): AbstractControl {
    return this.topicFormGroup.get('topic');
  }

  get workspace(): AbstractControl {
    return this.topicFormGroup.get('workspace');
  }

  private async _getTopicsByWorkspace() {
    const topicsFilter: APIFilter[] = [
      { type: 'field', field: 'topic_type_id', value: TopicType.WorkOrder.toString() },
    ];
    if (this.workspace?.value?.id && +this.workspace.value.id !== +Workspace.Dispatch) {
      topicsFilter.push(
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'workspace_id', value: this.workspace.value.id.toString() }
      );
    }

    const selectedTopicIds = this.workOrder?.topic_id ? [this.workOrder.topic_id] : [];
    this._workspaceTopics = await this._topicService
      .getTopics([this._topicFields], topicsFilter, null, null, null, false, selectedTopicIds)
      .toPromise();
  }

  private async _getTopicCategoriesByWorkspace() {
    const workspaceTopicCategoryIds = uniq(this._workspaceTopics.map((topic: Topic) => topic.topic_category_id)) ?? [];
    const workspaceCategoryFilter = [{ type: 'field', field: 'id', value: workspaceTopicCategoryIds.join('^') }];
    const selectedTopicCategoryIds = this.workOrder?.topic?.topic_category_id
      ? [this.workOrder.topic.topic_category_id]
      : [];
    this._workspaceTopicCategories = await this._topicService
      .getTopicCategories(
        [this._topicCategoryFields],
        workspaceCategoryFilter,
        null,
        null,
        null,
        false,
        selectedTopicCategoryIds
      )
      .toPromise();
  }

  private async _getTopicGroupsByWorkspace() {
    const workspaceTopicGroupIds =
      uniq(this._workspaceTopicCategories.map((topicCategory: TopicCategory) => topicCategory.topic_group_id)) ?? [];
    const selectedTopicGroupIds = this.workOrder?.topic?.topic_category?.topic_group_id
      ? [this.workOrder.topic.topic_category.topic_group_id]
      : [];
    const workspaceGroupFilter = [{ type: 'field', field: 'id', value: workspaceTopicGroupIds.join('^') }];
    this._workspaceTopicGroups = await this._topicService
      .getTopicGroups(
        [this._topicGroupFields],
        workspaceGroupFilter,
        undefined,
        undefined,
        undefined,
        false,
        selectedTopicGroupIds
      )
      .toPromise();
  }

  public async populateTopicFields() {
    if (!this.workOrder?.topic) {
      this.currentTopics = this._workspaceTopics;
      this._currentTopicCategories = this._workspaceTopicCategories;

      this.topic_group.setValue(this._workspaceTopicGroups);
      this.topic_category.setValue(this._workspaceTopicCategories);
      this.topic.setValue(this._workspaceTopics);
    } else if (this.workOrder) {
      if (this.workOrder.module) {
        this.workspace.setValue(this.workOrder.module);
      } else if (this.workOrder.module_id) {
        const foundWorkspace = this._moduleService?.allWorkspaces?.find(
          (workspace: Module) => +workspace.id === +this.workOrder.module_id
        );
        if (foundWorkspace) {
          this.workspace.setValue(foundWorkspace);
        }
      }

      // topic
      if (this.workOrder.topic) {
        if (
          +this.workOrder.module_id !== +Workspace.Dispatch &&
          +this.workOrder.topic?.workspace_id !== +this.workOrder?.module_id
        ) {
          this._appendTopicToList(this.workOrder.topic);
        }
        this.topic_group.setValue(this.workOrder.topic_group, { emitEvent: false });
        this._lastTopicGroup = this.topic_group.value;
        this.topic_category.setValue(this.workOrder.topic_category, { emitEvent: false });
        this._lastTopicCategory = this.topic_category.value;
        this.topic.setValue(this.workOrder.topic, { emitEvent: false });
        this.lastTopic = this.topic.value;
      }
    }
  }

  private async _getTopicCategoriesByGroup(group_id: number) {
    this._currentTopicCategories = this._workspaceTopicCategories.filter(
      (topicCategory: TopicCategory) => topicCategory?.topic_group_id === group_id
    );
  }

  private async _getTopicsByGroup(groupId: number) {
    if (groupId) {
      this.currentTopics = this._workspaceTopics.filter(
        (topic: Topic) => topic?.topic_category?.topic_group_id === groupId
      );
    } else {
      this.currentTopics = this._workspaceTopics;
    }
  }

  private async _getTopicsByCategory(categoryId: number) {
    this.currentTopics = this._workspaceTopics.filter((topic: Topic) => topic?.topic_category_id === categoryId);
  }

  private _filterTopicGroups(entry: string): TopicGroup[] {
    return this._workspaceTopicGroups.filter((topicGroup: TopicGroup) =>
      topicGroup?.name?.toLowerCase()?.includes(entry?.toLowerCase())
    );
  }

  private _filterTopicCategories(entry: string): TopicCategory[] {
    if (this.topic_group) {
      return this._currentTopicCategories.filter(
        (topicCategory: TopicCategory) =>
          topicCategory?.name?.toLowerCase()?.includes(entry?.toLowerCase()) &&
          topicCategory?.topic_group.name === this.topic_group.value.name
      );
    } else {
      return this._currentTopicCategories.filter((topicCategory: TopicCategory) =>
        topicCategory?.name?.toLowerCase()?.includes(entry?.toLowerCase())
      );
    }
  }

  private _filterTopics(entry: string): Topic[] {
    return this.currentTopics.filter((topic) => topic?.name?.toLowerCase()?.includes(entry?.toLowerCase()));
  }

  private _autocompleteTopicGroups() {
    this.filteredTopicGroups$ = this.topic_group.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | TopicGroup) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((value) => {
        return value ? this._filterTopicGroups(value) : this._workspaceTopicGroups.slice();
      })
    );
  }

  private _autocompleteTopicCategories() {
    this.filteredTopicCategories$ = this.topic_category.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | TopicCategory) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((name) => {
        return name ? this._filterTopicCategories(name) : this._currentTopicCategories.slice();
      })
    );
  }

  private _autocompleteTopics() {
    this.filteredTopics$ = this.topic.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | Topic) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((name) => {
        return name ? this._filterTopics(name) : this.currentTopics.slice();
      })
    );
  }

  public async selectedTopicGroupChanged() {
    if (this.topic_group.value.id !== this._lastTopicGroup?.id) {
      await this._getTopicCategoriesByGroup(this.topic_group.value.id);
      this.topic_category.setValue(this._currentTopicCategories);
      await this._getTopicsByGroup(this.topic_group?.value?.id);
      this.topic.setValue(this.currentTopics);
      this._lastTopicGroup = this.topic_group.value;
      // since topic group changed we need to reset buildings field
      this.topicChanged.emit({
        changeLevel: 'group',
        buildingIds: this.topic_group.value.building_ids,
        priorityId: null,
      });
    }
  }

  public async selectedTopicCategoryChanged() {
    if (this.topic_category.value.id !== this._lastTopicCategory?.id) {
      if (this.topic_group.value.id !== this.topic_category.value.topic_group.id) {
        this.topic_group.setValue(this.topic_category.value.topic_group);
        await this._getTopicCategoriesByGroup(this.topic_group.value.id);
        // since topic group changed we need to reset buildings field
        this.topicChanged.emit({
          changeLevel: 'group',
          buildingIds: this.topic_group.value.building_ids,
          priorityId: null,
        });
      }
      await this._getTopicsByCategory(this.topic_category.value.id);
      this.topic.setValue(this.currentTopics);
      this._lastTopicCategory = this.topic_category.value;
    }
  }

  public selectedTopicChanged() {
    if (this.topic?.value?.id && this.topic.value.id !== this.lastTopic?.id) {
      if (this._lastTopicGroup?.id !== this.topic.value.topic_category.topic_group.id) {
        this.topic_group.setValue(this.topic.value.topic_category.topic_group);
        this._lastTopicGroup = this.topic_group.value;
        // since topic group changed we need to reset buildings field
        this.topicChanged.emit({
          changeLevel: 'group',
          buildingIds: this.topic_group.value.building_ids,
          priorityId: null,
        });
      }
      if (this._lastTopicCategory?.id !== this.topic.value.topic_category.id) {
        this.topic_category.setValue(this.topic.value.topic_category);
        this._lastTopicCategory = this.topic_category.value;
      }
      this.lastTopic = this.topic.value;
      // update priority based on topic
      this.topicChanged.emit({ changeLevel: 'topic', priorityId: this.topic.value.priority_id, buildingIds: null });

      // update the workspace if its in dispatch and a topic is chosen or times when topic workspace doesn't match the selected workspace
      if (
        (+this.workspace?.value?.id === +Workspace.Dispatch && this.topic.value?.workspace_id) ||
        +this.workspace?.value?.id !== this.topic.value?.workspace_id
      ) {
        const foundTopicWorkspace = this._moduleService?.allWorkspaces?.find(
          (workspace: Module) => +workspace.id === +this.topic.value?.workspace_id
        );
        if (foundTopicWorkspace) {
          this.workspace.setValue(foundTopicWorkspace);
        }
      }
    }
  }

  public async clearTopicGroup(event) {
    this.isClearingGroup = true;
    if (event) {
      event.stopPropagation();
    }

    // if you are converting a task, redo the topics
    if (this.workOrder.linkedTaskId) {
      this.workspace.setValue('');

      // redo topics
      await this._getTopicsByWorkspace();
      await this._getTopicCategoriesByWorkspace();
      await this._getTopicGroupsByWorkspace();
    }

    this._currentTopicCategories = this._workspaceTopicCategories;
    this.currentTopics = this._workspaceTopics;
    this.topic_group.setValue(this._workspaceTopicGroups);
    this.topic_category.setValue(this._workspaceTopicCategories);
    this.topic.setValue(this._workspaceTopics);
    this._lastTopicGroup = null;
    this._lastTopicCategory = null;
    this.lastTopic = null;

    // only do this if its new and in dispatch
    if (!this.workOrder?.id && +this._moduleService.workspace_id === +Workspace.Dispatch) {
      this.workspace.setValue(this._moduleService.workspace);
    }
    this.isClearingGroup = false;
  }

  public async clearTopicCategory(event) {
    this.isClearingCategory = true;
    if (event) {
      event.stopPropagation();
    }
    if (!this.topic_group.value.id) {
      this._currentTopicCategories = this._workspaceTopicCategories;
    } else {
      await this._getTopicCategoriesByGroup(this.topic_group.value.id);
    }
    this.topic_category.setValue(this._currentTopicCategories);
    this._getTopicsByGroup(this.topic_group?.value?.id);
    this.topic.setValue(this.currentTopics);
    this._lastTopicCategory = null;
    this.lastTopic = null;
    this.isClearingCategory = false;
  }

  public async clearTopic(event) {
    this.isClearingTopic = true;
    if (event) {
      event.stopPropagation();
    }
    if (!this.topic_category.value.id) {
      this._getTopicsByGroup(this.topic_group?.value?.id);
    } else {
      await this._getTopicsByCategory(this.topic_category.value.id);
    }
    this.topic.setValue(this.currentTopics);
    this.lastTopic = null;
    this.isClearingTopic = false;
  }

  private _appendTopicToList(topicToAppend: Topic) {
    // append topic, category, and group to each list
    if (topicToAppend) {
      const categoryToAppend = topicToAppend?.topic_category;
      const groupToAppend = topicToAppend?.topic_category?.topic_group;
      if (!this._workspaceTopicGroups.find((topicGroupData: TopicGroup) => +topicGroupData.id === +groupToAppend?.id)) {
        this._workspaceTopicGroups.push(groupToAppend);
      }
      if (
        !this._workspaceTopicCategories.find(
          (categoryData: TopicCategory) => +categoryData.id === +categoryToAppend?.id
        )
      ) {
        this._workspaceTopicCategories.push(categoryToAppend);
      }
      if (!this._workspaceTopics.find((topicData: Topic) => +topicData.id === +topicToAppend?.id)) {
        this._workspaceTopics.push(topicToAppend);
      }
    }
  }

  public topicCategoryMapper(category: TopicCategory): string {
    return category?.name || '';
  }

  public topicGroupMapper(topicGroup: TopicGroup): string {
    return topicGroup?.name || '';
  }

  public topicMapper(topic: Topic): string {
    return topic?.name || '';
  }

  public topicValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !this.currentTopics?.find((topic) => topic.id === control.value?.id) ? { topic: { value: true } } : null;
    };
  }
}
