import { Injectable } from '@angular/core';
import { StripHtmlPipe } from '../pipes';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public isExporting = false;

  static getWorkspaceNameFromEnum(e: any, value: number) {
    for (const key in e) {
      if (e[key] === value) {
        return key;
      }
    }
    return null;
  }

  constructor(private modalService: ModalService, private htmlStripPipe: StripHtmlPipe) {}

  // Takes an array of sanitized strings, the fileName to save as, and the text for the confirmationDialog
  public exportDataWithConfirmation(
    sanitizedData: string[],
    fileName: string,
    titleBarText: string,
    descriptionText: string
  ) {
    this.isExporting = true;
    this.modalService
      .openConfirmationDialog({
        titleBarText,
        descriptionText,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.exportToCsv(fileName, sanitizedData);
        }
        this.isExporting = false;
      });
  }

  // Sanitized an array of strings to be valid data
  // This includes stripping all commas out, and replacing the item with replacementOption,  if the data is null, use -
  public sanitizeItems(data: string[]): string {
    // copy the data so as to not modify the original data
    const dataCopy = [...data];
    const sanitizedLines = [];
    for (let dataPoint of dataCopy) {
      // replace commas and strip html, or push replacementOption if no data
      if (!dataPoint) {
        dataPoint = '-';
      } else {
        // strip html
        dataPoint = this.htmlStripPipe.transform(dataPoint).toString();
        // strip commas
        dataPoint = dataPoint.replace(/,/g, '');
        // replace newlines with spaces
        dataPoint = dataPoint.replace(/\n/g, ' ');
        // escape double quotes
        if (dataPoint.includes('"')) {
          dataPoint = '"' + dataPoint.replace(/"/g, '""') + '"';
        }
      }
      dataPoint = dataPoint ? this.htmlStripPipe.transform(dataPoint).toString().replace(/,/g, '') : '-';
      sanitizedLines.push(dataPoint);
    }
    return sanitizedLines.join(',');
  }

  // this creates the file and downloads it
  // adding the <a> element allows renaming the file (since you can't with window.open)
  public exportToCsv(filename: string, dataToWrite: string[]) {
    const blob = new Blob([dataToWrite.join(`\n`)], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
