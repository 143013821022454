<app-base-dialog [title]="'Cover Letter'" (closeDialog)="cancel()"></app-base-dialog>
<div
  id="cover-letter-dialog"
  data-test-id="cover-letter-dialog"
  class="cover-letter-dialog project-modal create-task-form p-2"
>
  <div class="d-flex mb-4">
    <h4 class="m-0 dkblue font-weight-semibold">
      {{ data.title }}
    </h4>
  </div>

  <!-- TODO: Link Cover Letter -->
  <div class="d-flex mb-3">
    <h5 class="m-0 dkblue font-weight-normal">{{ data.tenant?.first_name }},</h5>
  </div>
  <div class="row text-area mb-4">
    <div class="col">
      <app-editor #editor [class_height]="'large'" placeholder="Type your message here..."></app-editor>
    </div>
  </div>
  <div class="d-flex mb-4 flex-column">
    <h6 class="m-0 dkblue font-weight-semibold">
      {{ data.pmInfo?.name }}
    </h6>
    <h6 class="m-0 dkgray font-weight-normal">
      {{ data.pmInfo?.title }}
    </h6>
  </div>
  <div *ngIf="data.architectInfo" class="d-flex mb-4 flex-column">
    <h6 class="m-0 dkblue font-weight-semibold">
      {{ data.architectInfo?.name }}
    </h6>
    <h6 class="m-0 dkgray font-weight-normal">
      {{ data.architectInfo?.title }}
    </h6>
  </div>
  <div *ngIf="data.wmInfo" class="d-flex mb-4 flex-column">
    <h6 class="m-0 dkblue font-weight-semibold">
      {{ data.wmInfo?.name }}
    </h6>
    <h6 class="m-0 dkgray font-weight-normal">
      {{ data.wmInfo?.title }}
    </h6>
  </div>

  <div class="row mt-3">
    <div class="col text-right">
      <button (click)="cancel()" mat-button type="button" class="gray">Cancel</button>
      <button
        mat-flat-button
        type="submit"
        color="success"
        class="ml-3"
        (click)="save()"
        [disabled]="!cover_letter.value"
      >
        Save
      </button>
    </div>
  </div>
</div>
