<div id="workspace-selector" data-test-id="workspace-selector" class="workspace-selector">
  <mat-form-field class="full-width" appearance="outline" [formGroup]="workspaceForm">
    <mat-select
      matNativeControl
      required="true"
      formControlName="workspaces"
      [value]="selectedWorkspaces.value"
      multiple
    >
      <mat-option [value]="0" (click)="updateSelectedWorkspaces(0)"> All Workspaces</mat-option>
      <mat-option
        *ngFor="let workspace of workspaces"
        [value]="workspace.id"
        (click)="updateSelectedWorkspaces(workspace.id)"
        >{{ workspace.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
