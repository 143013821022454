import { Directive } from '@angular/core';
import { NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { AsyncValidator } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UserService } from '../services';

@Directive({
  selector: '[appUniqueEmail]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailDirective, multi: true }],
})
export class UniqueEmailDirective implements AsyncValidator {
  constructor(private _userService: UserService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (!control.value) {
      return null;
    }

    return this._userService.getUsers([{ type: 'field', field: 'email', value: control.value }], ['id']).pipe(
      debounceTime(500),
      map((val) => {
        return val.length > 0 ? { unique: 'Email already exists' } : null;
      })
    );
  }
}
