import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  // private _documentTagNames = new Set(['!doctype', 'doctype', 'body', 'link']);
  private _bodyTagNames = new Set([
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'base',
    'bdi',
    'bdo',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'head',
    'header',
    'hgroup',
    'hr',
    'html',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'main',
    'map',
    'mark',
    'math',
    'menu',
    'menuitem',
    'meta',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'param',
    'picture',
    'pre',
    'progress',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    'samp',
    'script',
    'section',
    'select',
    'slot',
    'small',
    'source',
    'span',
    'strong',
    'style',
    'sub',
    'summary',
    'sup',
    'svg',
    'table',
    'tbody',
    'td',
    'template',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'ul',
    'var',
    'video',
    'wbr',
    'xml',
  ]);

  private _singleLetterTags = new Set(['a', 'b', 'i', 'p', 'q', 's', 'u']);

  transform(value, ...args: unknown[]): string {
    // if it isn't a string, return it
    if (!(typeof value === 'string' || value instanceof String)) {
      return value;
    }

    const newValue = [...this._bodyTagNames, ...this._singleLetterTags].reduce((prevValue, tag) => {
      const openTag = `<${tag}>`;
      const closeTag = `</${tag}>`;

      return prevValue.split(openTag).join(' ').split(closeTag).join(' ');
    }, value);
    return newValue.replace(/&nbsp;/gi, '').trim();
  }
}
