import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService, ProjectService } from 'src/app/services';
import { ProjectDrawingsService } from 'src/app/workspaces/construction/services';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { ApplicationRole, ResourceType } from 'src/app/enums';
import { ProjectDrawing, ProjectDrawingVersion } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-new-project-drawing-dialog',
  templateUrl: './new-project-drawing-dialog.component.html',
  styleUrls: ['./new-project-drawing-dialog.component.scss'],
})
export class NewProjectDrawingDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewProjectDrawingDialogComponent>,
    private authService: AuthService,
    private projectService: ProjectService,
    private projectDrawingService: ProjectDrawingsService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  projectDrawingFields = ['id'];
  currentUser;
  userCompany;
  projectDrawing: ProjectDrawing = {};

  async ngOnInit() {}

  cancel(): void {
    this.dialogRef.close();
  }

  public userIsWorkspaceManager(): boolean {
    return this.authService.currentUserIsOfWorkspaceRole(
      ApplicationRole.WorkspaceManager,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  public userIsProjectManager(): boolean {
    return this.authService.currentUserIsOfProjectRole(
      ApplicationRole.ProjectManager,
      this.projectService.currentSelectedProjectId
    );
  }

  public userIsChiefFacilitiesManagementOfficer(): boolean {
    return this.authService.currentUserIsOfAppRole(ApplicationRole.ChiefFacilitiesManagementOfficer);
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          maxFiles: 1,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          this.projectDrawing.file_id = resultData[0].file_id;
          this.projectDrawing.file_name = resultData[0].name;
        }
      });
  }

  async submit() {
    if (this.projectDrawing.name && this.projectDrawing.file_id) {
      const projectDrawingToCreate: ProjectDrawing = {
        name: this.projectDrawing.name,
        project_id: this.projectService.currentSelectedProjectId,
      };
      const createdProjectDrawing = await this.projectDrawingService
        .createProjectDrawing(projectDrawingToCreate, this.projectDrawingFields)
        .toPromise();
      const projectDrawingVersionToCreate: ProjectDrawingVersion = {
        project_drawing_id: createdProjectDrawing.id,
        file_id: this.projectDrawing.file_id,
      };
      const createdProjectDrawingVersion = await this.projectDrawingService
        .createProjectDrawingVersion(projectDrawingVersionToCreate, [])
        .toPromise();
      this.snackbar.open('Project drawing created!');
      this.dialogRef.close();
    } else {
      this.snackbar.open(`Please fill out all required fields`);
    }
  }
}
