import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav/drawer';
import PerfectScrollbar from 'perfect-scrollbar';
import { ProjectUpdate, WorkOrderUpdate } from 'src/app/types';

@Component({
  selector: 'app-latest-update',
  templateUrl: './latest-update.component.html',
  styleUrls: ['./latest-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatestUpdateComponent implements OnInit, OnChanges {
  @Input() public allUpdates: ProjectUpdate[] | WorkOrderUpdate[] = [];
  @Input() public drawer: MatDrawer;
  @Input() public isLoadingLatestUpdates = false;
  @Input() public projectUpdates: ProjectUpdate[] = [];
  @Input() public selectedLatestUpdates: 'allUpdates' | 'projectUpdates' | 'workOrderUpdates';
  @Input() public workOrderUpdates: WorkOrderUpdate[] = [];
  @Output() selectedUpdateTabChangeds = new EventEmitter();
  @Output() panelOpened = new EventEmitter();

  public showScrollToTopButton = false;
  public updateCount: number;
  private updatePageSize = 30;

  // constructor() {}

  ngOnInit(): void {
    const updateList = document.querySelector('#latest-updates');
    const perfectScrollbar = new PerfectScrollbar(updateList);
    perfectScrollbar.update();
    this.panelOpened.emit();
  }

  ngOnChanges(): void {
    let total = 0;
    if (this.selectedLatestUpdates === 'allUpdates') {
      total = this.allUpdates?.length;
    } else if (this.selectedLatestUpdates === 'projectUpdates') {
      total = this.projectUpdates?.length;
    } else if (this.selectedLatestUpdates === 'workOrderUpdates') {
      total = this.workOrderUpdates.length;
    } else {
      total = this.workOrderUpdates?.length + this.projectUpdates?.length;
    }
    this.updateCount = total > this.updatePageSize ? this.updatePageSize : total;
    this.scrollToTheTop();
  }

  public async closeAside(): Promise<void> {
    await this.drawer?.close();
  }

  public onScroll(evt): void {
    if (!this.showScrollToTopButton && evt.target.scrollTop > 500) {
      this.showScrollToTopButton = true;
    }

    if (this.showScrollToTopButton && evt.target.scrollTop < 500) {
      this.showScrollToTopButton = false;
    }
  }

  public scrollToTheTop(): void {
    const element = document.querySelector('#latest-updates');
    element.scrollTo(0, 0);
  }

  public showMoreProjectUpdates(): void {
    if (this.updateCount + this.updatePageSize <= this.projectUpdates.length) {
      this.updateCount += this.updatePageSize;
    } else {
      // only happens if we exceed it
      this.updateCount = this.projectUpdates.length;
    }
  }

  public showMoreUpdates(): void {
    if (this.updateCount + this.updatePageSize <= this.allUpdates.length) {
      this.updateCount += this.updatePageSize;
    } else {
      // only happens if we exceed it
      this.updateCount = this.allUpdates.length;
    }
  }

  public showMoreWorkOrderUpdates(): void {
    if (this.updateCount + this.updatePageSize <= this.workOrderUpdates.length) {
      this.updateCount += this.updatePageSize;
    } else {
      // only happens if we exceed it
      this.updateCount = this.workOrderUpdates.length;
    }
  }

  public tabChanged(): void {
    this.selectedUpdateTabChangeds.emit(this.selectedLatestUpdates);
  }
}
