import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, properties?: string[], filteredCount?: any, exact?: boolean): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      if (filteredCount) {
        filteredCount.count = items.length;
      }
      return items;
    }

    searchText = searchText.toString().toLowerCase().trim();
    const result = items.filter((item) => {
      if (properties) {
        for (const p of properties) {
          const value = p.split('.').reduce((a, c) => (a = a?.[c]), item);
          if (value?.toString().toLowerCase().trim().includes(searchText)) {
            return true;
          }
        }
      } else {
        if (item.name) {
          return item.name.toString().toLowerCase().includes(searchText);
        } else {
          return item.toString().toLowerCase().includes(searchText);
        }
      }
    });

    if (filteredCount) {
      filteredCount.count = result.length;
    }
    return result;
  }
}
