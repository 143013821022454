import * as moment from 'moment';
import { ApprovalStatus, TaskAccessoryType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { TaskAccessoryDataFactory } from 'src/app/models';
import { DateService, FileService, ModalService, TaskActionsService } from 'src/app/services';
import { Task, TaskAccessoryData, TaskInfo } from 'src/app/types';

// This class handles a specific approval process for a task. It keeps track of the approval status chain,
// as well as handles any reset/creation/new revision.
// In essence, this is the interface to manage the approval task from any process.
export class ApprovalProcess {
  // the object we are tracking for approval
  _approvalTask: Task;
  // approvalDialog: MatDialog;
  modalService: ModalService;
  fileService: FileService;
  dateService: DateService;
  taskActionsService: TaskActionsService;
  status: ApprovalStatus;
  reviewChain;
  accessoryData: TaskAccessoryData;

  private fields: {
    dataName: string;
    dataType: string;
    phaseName: string;
    milestoneName: string;
    tagId: number;
    accessoryData: any;
  };

  private pebFields = {
    dataName: null,
    dataType: 'PEB',
    phaseName: 'Budget',
    milestoneName: 'Prelim Budget',
    tagId: 48,
    accessoryData: null,
  };

  private cbFields = {
    dataName: null,
    dataType: 'CB', // Construction Budget
    phaseName: 'Bidding',
    milestoneName: 'Project Bids',
    tagId: 49,
    accessoryData: null,
  };

  private budgetFields = {
    dataName: null,
    dataType: 'Budget',
    phaseName: null,
    milestoneName: null,
    tagId: 55,
    accessoryData: null,
  };

  private arfFields = {
    dataName: null,
    dataType: 'Arf',
    phaseName: null,
    milestoneName: null,
    tagId: 55,
    accessoryData: null,
  };

  constructor(
    approvalTask: Task,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService
  ) {
    // SubmitForApprovalDialog: MatDialog) {
    this._approvalTask = approvalTask;
    this.dateService = dateService;
    this.taskActionsService = taskActionsService;
    // this.approvalDialog = SubmitForApprovalDialog;
    this.modalService = modalService;
    this.fileService = fileService;
    this.accessoryData = this._approvalTask?.accessory_data && JSON.parse(this._approvalTask.accessory_data);
  }

  get approvalStatus(): ApprovalStatus {
    try {
      // this will fail if we have no task, accessory_data, or reviewchain, which is good as it default to NOT_STARTED then
      if (!this._approvalTask) {
        this.status = ApprovalStatus.NOT_STARTED;
      } else if (this.status == null) {
        this.status = ApprovalStatus.NOT_STARTED;
        if (this.accessoryData?.isReviewItem) {
          this.reviewChain = this.accessoryData?.reviewChain.map((item) => item.status);
          if (this.reviewChain.includes(2)) {
            this.status = ApprovalStatus.REJECTED;
          } else if (
            (this.reviewChain.includes(0) && this._approvalTask.status_id !== TaskStatus.Complete) ||
            this._approvalTask.status_id !== TaskStatus.Complete
          ) {
            this.status = ApprovalStatus.AWAITING_APPROVAL;
          } else {
            this.status = ApprovalStatus.ACCEPTED;
          }
        } else {
          if (+this._approvalTask.status_id === TaskStatus.Complete) {
            this.status = ApprovalStatus.ACCEPTED;
          } else {
            this.status = ApprovalStatus.AWAITING_APPROVAL;
          }
        }
      }
    } catch {}
    return this.status;
  }

  // checks if the task is accepted (so all review chain events are finished), but it is not yet marked complete
  get acceptedNotComplete(): boolean {
    if (this.accessoryData?.isReviewItem) {
      if (this.reviewChain == null) {
        this.reviewChain = this.accessoryData?.reviewChain.map((item) => item.status);
      }
      if (
        !this.reviewChain?.includes(2) &&
        !this.reviewChain?.includes(0) &&
        this._approvalTask?.status_id !== TaskStatus.Complete
      ) {
        return true;
      }
    } else {
      return +this._approvalTask?.status_id === 4;
    }
  }

  get needsReview(): boolean {
    if (this.reviewChain == null) {
      this.reviewChain = this.accessoryData?.reviewChain?.map((item) => item.status);
    }
    if (this.reviewChain?.includes(0)) {
      return true;
    }
  }

  get getTask(): Task {
    return this._approvalTask;
  }

  get taskReviewer() {
    return (
      this._approvalTask && {
        id: this._approvalTask.assigned_user_id,
        firstName: this._approvalTask.assigned_user_first_name,
        lastName: this._approvalTask.assigned_user_last_name,
      }
    );
  }
  // returns the route for the task
  get getTaskLink(): string {
    return `/projects/${this.getTask?.project_id}/tasks/${this.getTask?.id}`;
  }

  get hasTask(): boolean {
    return this._approvalTask != null;
  }

  get canReset(): boolean {
    return this.approvalStatus !== ApprovalStatus.NOT_STARTED;
  }

  // the below two functions are the same, but the naming would be confusing when using them if I made them the same name, so I am leaving them
  // the logic will make more sense when using the respective functions
  get canFinalize(): boolean {
    return this._approvalTask && this.approvalStatus === ApprovalStatus.ACCEPTED;
  }
  get isTaskComplete(): boolean {
    return this._approvalTask && this.approvalStatus === ApprovalStatus.ACCEPTED;
  }

  get canStart(): boolean {
    return this.approvalStatus === ApprovalStatus.NOT_STARTED;
  }

  get canAddNewRevision(): boolean {
    return this.canReset;
  }

  // creates a new approval task if one doesn't already exist, returning the newly created task id
  public async createApprovalTask(data): Promise<number> {
    if (data.isTenant) {
      return await this.openSubmitForTenantApprovalDialog(data);
    } else {
      return await this.openSubmitForApprovalDialog(data);
    }
  }

  /**
   * Open the dialog that is responsible for starting the Approvals Process. Creating the initial tasks, activities, and assigning followers.
   * @param tenant The tenant to start the process for
   * @param taskInfo This is the information we are passing to the approval dialog component to determine what to display
   */
  private async openSubmitForApprovalDialog(taskInfo: TaskInfo): Promise<number> {
    const selectedMilestone = await this.getTaskData(taskInfo, true);
    this.fields.accessoryData.reviewChain = taskInfo.reviewIds || [];
    let assignedUserId = this.fields.accessoryData.reviewChain[0]?.id;

    if (this.fields.accessoryData?.reviewChain[0]?.id === this.taskActionsService.authService.currentUser?.id) {
      this.fields.accessoryData.reviewChain[0].status = TaskReviewStatus.Approved;
      this.fields.accessoryData.reviewChain[0].date = moment().toDate();

      assignedUserId =
        this.fields.accessoryData.reviewChain[1]?.id ||
        this.fields.accessoryData.reviewCreator ||
        this.fields.accessoryData.reviewChain[0]?.id;
    }

    const taskData = {
      accessory_data: this.fields.accessoryData && JSON.stringify(this.fields.accessoryData),
      assigned_user_id: taskInfo.assigned_user?.id || assignedUserId,
      can_delete: 0,
      description: taskInfo.taskDescription || `The ${this.fields.dataType} below needs to be reviewed.`,
      milestone_id: selectedMilestone?.id,
      title: `${this.fields.dataType} Staff Review: ${this.fields.dataName}`,
    };

    const taskNote = this.taskActionsService.getTaskNoteText(this.fields.accessoryData?.type, taskInfo.files?.length);
    const returnTask = await this.taskActionsService.createTaskAndAttachFiles(
      taskData,
      taskNote,
      taskInfo.files || [],
      taskInfo.linkedFiles || []
    );
    return returnTask?.id;
  }

  /**
   * Open the dialog that is responsible for starting the Approvals Process. Creating the initial tasks, activities, and assigning followers.
   * @param tenant The tenant to start the process for
   * @param taskInfo This is the information we are passing to the approval dialog component to determine what to display
   */
  private async openSubmitForTenantApprovalDialog(taskInfo: TaskInfo): Promise<number> {
    const selectedMilestone = await this.getTaskData(taskInfo);

    const taskData = {
      accessory_data: this.fields.accessoryData && JSON.stringify(this.fields.accessoryData),
      assigned_user_id: taskInfo.assigned_user?.id,
      can_delete: 0,
      description:
        'Please download the below files by clicking on them. Then, sign and reattach as a note. This can be done by clicking the paperclip below. Please mark the task complete when you are done so a UHAT/1Call user can approve the task. Thank you!',
      due_date: taskInfo.dueDate || this.dateService.addWeekdays(5).toDate(),
      milestone_id: selectedMilestone?.id,
      title: `${this.fields.dataType} Tenant Review: ${this.fields.dataName}`,
    };
    const taskNote = this.taskActionsService.getTaskNoteText(this.fields.accessoryData?.type, taskInfo.files?.length);
    const returnTask = await this.taskActionsService.createTaskAndAttachFiles(
      taskData,
      taskNote,
      (!taskInfo.areFilesLinked && taskInfo.files) || [],
      (taskInfo.areFilesLinked && taskInfo.files) || []
    );
    return returnTask?.id;
  }

  private async getTaskData(taskInfo, isInternal = false) {
    this.setDataType(taskInfo, isInternal);

    const projectPhases = await this.taskActionsService.projectService
      .getPhasesByProjectId(this.taskActionsService.projectService.currentSelectedProjectId, [
        'id',
        'milestones',
        'name',
      ])
      .toPromise();
    const selectedPhase = projectPhases.find((phase) => phase.name === this.fields.phaseName);
    const selectedMilestone = selectedPhase.milestones.find(
      (milestone) => milestone.name === this.fields.milestoneName
    );

    return selectedMilestone;
  }

  private setDataType(taskInfo, isInternal = false) {
    if (taskInfo.pebName) {
      if (isInternal) {
        this.pebFields.accessoryData = TaskAccessoryDataFactory.createPebReviewApprovalData(
          true,
          taskInfo.reviewIds,
          taskInfo.parentId
        );
      } else {
        this.pebFields.accessoryData = TaskAccessoryDataFactory.createTenantReviewData(TaskAccessoryType.PEB);
      }
      this.pebFields.dataName = taskInfo.pebName;
      this.fields = this.pebFields;
    } else if (taskInfo.cbName) {
      if (isInternal) {
        this.cbFields.accessoryData = TaskAccessoryDataFactory.createCbReviewApprovalData(true, taskInfo.reviewIds);
      } else {
        this.cbFields.accessoryData = TaskAccessoryDataFactory.createTenantReviewData(TaskAccessoryType.CB);
      }
      this.cbFields.dataName = taskInfo.cbName;
      this.fields = this.cbFields;
    } else if (taskInfo.budgetName) {
      if (isInternal) {
        this.budgetFields.accessoryData = TaskAccessoryDataFactory.createBudgetReviewApprovalData(
          true,
          taskInfo.reviewIds,
          taskInfo.parentId
        );
      } else {
        this.budgetFields.accessoryData = TaskAccessoryDataFactory.createTenantReviewData(
          TaskAccessoryType.Budget,
          taskInfo.parentId
        );
      }
      this.budgetFields.phaseName = taskInfo.phaseName || null;
      this.budgetFields.milestoneName = taskInfo.milestoneName || null;
      this.budgetFields.dataName = taskInfo.budgetName;
      this.fields = this.budgetFields;
    } else if (taskInfo.arfName) {
      if (isInternal) {
        this.arfFields.accessoryData = TaskAccessoryDataFactory.createArfReviewApprovalData(
          true,
          taskInfo.reviewIds,
          taskInfo.parentId,
          taskInfo.reviewCreator
        );
      }
      this.arfFields.phaseName = taskInfo.phaseName || null;
      this.arfFields.milestoneName = taskInfo.milestoneName || null;
      this.arfFields.dataName = taskInfo.arfName;
      this.fields = this.arfFields;
    }
  }
}
