import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { saveAs } from 'file-saver';

import { ProjectService, FileService, ProgressIndicatorService, ModalService } from 'src/app/services';

import { AsBuiltDialogComponent } from 'src/app/workspaces/construction/components';

import { ResourceType } from 'src/app/enums';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';
import { AsBuilt } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-as-builts',
  templateUrl: './as-builts.component.html',
  styleUrls: ['./as-builts.component.scss'],
})
export class AsBuiltsComponent implements OnInit {
  @Input() project: ProjectConstruction;

  constructor(
    private projectService: ProjectService,
    private fileService: FileService,
    private asBuiltDialog: MatDialog,
    private confirmationDialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar,
    private modalService: ModalService
  ) {}

  private asBuiltFields = ['code', 'title', 'description', 'created_datetime', 'files', 'local_index'];
  loaders = {
    gettingAsBuilts: false,
    updatingAsBuilt: false,
  };

  asBuilts: AsBuilt[];

  ngOnInit() {
    setTimeout(() => {
      this.refresh();
    });
  }

  async refresh() {
    if (this.project && this.project.id) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Data..');
      this.loaders.gettingAsBuilts = true;
      const asBuilts = await this.projectService
        .getAsBuilts(
          [
            {
              type: 'field',
              field: 'project_id',
              value: this.project.id.toString(),
            },
          ],
          this.asBuiltFields
        )
        .toPromise();
      this.syncAsBuiltExpansion(asBuilts);

      asBuilts.sort((a, b) => +a.local_index - +b.local_index);

      this.asBuilts = asBuilts;
      this.loaders.gettingAsBuilts = false;
      this.progressIndicatorService.close();
    }
  }

  addEditAsBuilt(asBuilt?: AsBuilt) {
    if (asBuilt) {
      asBuilt.project_id = this.project.id;
    }
    const dialogRef = this.asBuiltDialog.open(AsBuiltDialogComponent, {
      disableClose: true,
      width: '500px',
      data: asBuilt || { project_id: this.project.id },
    });

    dialogRef.afterClosed().subscribe(async (returnedAsBuilt: AsBuilt) => {
      if (returnedAsBuilt && this.project) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Data..');
        this.loaders.updatingAsBuilt = true;
        if (returnedAsBuilt.id) {
          const asBuiltId = returnedAsBuilt.id;
          delete returnedAsBuilt.id;
          // map all the returned files to just the id (we don't care about the name)
          const returnedIds = returnedAsBuilt.files.map((file) => +file.id);
          // get all the currently linked files to the invoice
          const currentFiles = await this.fileService.getFilesByParentId(ResourceType.AsBuilt, asBuiltId).toPromise();
          // get the returned ids that aren't in the current ids, since we need to add these
          const fileIdsToAdd = returnedIds.filter((id) => !currentFiles.map((file) => +file.file_id).includes(id));
          // get the current ids that aren't in the returned ids, since we need to remove these
          const fileIdsToRemove = currentFiles.filter((file) => !returnedIds.includes(file.file_id));
          delete returnedAsBuilt.files;
          // update the invoice
          const updatedAsBuilt = await this.projectService
            .updateAsBuilt(asBuiltId, returnedAsBuilt, this.asBuiltFields)
            .toPromise();

          // link and unlink the appropriate files
          for (const f of fileIdsToRemove) {
            await this.fileService.unlinkFile(f.id).toPromise();
          }
          for (const f of fileIdsToAdd) {
            await this.fileService.linkFile(f, asBuiltId, ResourceType.AsBuilt).toPromise();
          }

          this.snackbar.open(`As Built updated!`);
        } else if (this.project) {
          returnedAsBuilt.project_id = this.project.id;
          // get all the returned ids so we can link them
          const filesToAdd = returnedAsBuilt.files.map((file) => +file.id);
          delete returnedAsBuilt.files;
          const createdAsBuilt = await this.projectService
            .createAsBuilt(returnedAsBuilt, this.asBuiltFields)
            .toPromise();
          // link all the returned files to the new invoice
          for (const f of filesToAdd) {
            await this.fileService.linkFile(f, createdAsBuilt.id, ResourceType.AsBuilt).toPromise();
          }
          this.snackbar.open(`As Built added!`);
        }
        this.loaders.updatingAsBuilt = false;
        this.refresh();
      }
    });
  }

  removeAsBuilt(asBuilt: AsBuilt) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove As Built',
        descriptionText: 'Are you sure you want to remove this as built?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (asBuilt && asBuilt.id) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Data..');
            await this.projectService.deleteAsBuilt(asBuilt.id).toPromise();
            this.snackbar.open(`As Built '${asBuilt.local_index}' removed!`);
            this.refresh();
          }
        }
      });
  }

  syncAsBuiltExpansion(asBuilts) {
    if (this.asBuilts) {
      const openAsBuiltIds = this.asBuilts.filter((a) => a.is_expanded).map((a) => a.id);
      if (openAsBuiltIds) {
        for (const a of asBuilts) {
          a.is_expanded = true;
          a.is_expanded = openAsBuiltIds.indexOf(a.id) > -1;
        }
      }
    }
  }

  downloadFile(file) {
    file.loading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.loading = false;
    });
  }
}
