import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse } from 'src/app/types';
import { ProjectDrawing, ProjectDrawingVersion } from 'src/app/workspaces/construction/types';

@Injectable({
  providedIn: 'root',
})
export class ProjectDrawingsService {
  constructor(
    private http: HttpClient,
    private apiFilterService: ApiFilterService,
    private handleErrorService: HandleErrorService
  ) {}

  host: string = environment.serviceHost;
  projectDrawingUrl = `${this.host}/api/v1/project-drawings`;
  projectDrawingVersionUrl = `${this.host}/api/v1/project-drawing-versions`;

  getProjectDrawings(fields: string[], apiFilters?: APIFilter[]): Observable<ProjectDrawing[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectDrawingUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.project_drawings;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createProjectDrawing(projectDrawing: ProjectDrawing, fields: string[]): Observable<ProjectDrawing> {
    return this.http.post(`${this.projectDrawingUrl}?fields=${fields.join(',')}`, projectDrawing).pipe(
      map((result: ServiceResponse) => {
        const projectDrawingToReturn = result.data['project drawing'];
        return projectDrawingToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectDrawing(
    projectDrawingId: number,
    projectDrawing: ProjectDrawing,
    fields: string[]
  ): Observable<ProjectDrawing> {
    return this.http
      .put(`${this.projectDrawingUrl}/${projectDrawingId}?fields=${fields.join(',')}`, projectDrawing)
      .pipe(
        map((result: ServiceResponse) => {
          const projectDrawingToReturn: ProjectDrawing = result.data['project drawing'];
          return projectDrawingToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteProjectDrawing(projectDrawingId: number): Observable<void> {
    return this.http.delete(`${this.projectDrawingUrl}/${projectDrawingId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjectDrawingVersions(fields: string[], apiFilters?: APIFilter[]): Observable<ProjectDrawingVersion[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectDrawingVersionUrl}?fields=${fields.join(',')}&limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.project_drawing_versions;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createProjectDrawingVersion(
    projectDrawingVersion: ProjectDrawingVersion,
    fields: string[]
  ): Observable<ProjectDrawingVersion> {
    return this.http.post(`${this.projectDrawingVersionUrl}?fields=${fields.join(',')}`, projectDrawingVersion).pipe(
      map((result: ServiceResponse) => {
        const projectDrawingVersionToReturn = result.data['project drawing version'];
        return projectDrawingVersionToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
