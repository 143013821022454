import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import * as moment from 'moment';

import { AuthService, FileService, HandleErrorService, ProductService, ProjectService } from 'src/app/services';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { EventType, ResourceType, TaskAccessoryType, TaskReviewStatus } from 'src/app/enums';
import { ProjectTenant, Task, TaskAccessoryData, UhatFileReference } from 'src/app/types';
import { ProjectConstruction } from '../../workspaces/construction/types';
import { ProjectTenantService } from '../../workspaces/construction/services';

@Component({
  selector: 'app-task-review-dialog',
  templateUrl: './task-review-dialog.component.html',
  styleUrls: ['./task-review-dialog.component.scss'],
})
export class TaskReviewDialogComponent implements OnInit {
  public myUserId: number;

  public accessoryData: TaskAccessoryData;

  public approvalStatus: TaskReviewStatus = TaskReviewStatus.Pending;
  public downloading: boolean;
  public fileHasBeenViewed: boolean[] = [];

  public task: Task = {};
  public reviewSigned: boolean;
  public project: ProjectConstruction;
  public tenant: ProjectTenant;

  public reviewComment: string;
  public reviewFiles: UhatFileReference[] = [];
  public attachedFiles: UhatFileReference[] = [];
  public selectedFile: UhatFileReference;

  constructor(
    public dialogRef: MatDialogRef<TaskReviewDialogComponent>,
    public authService: AuthService,
    private handleErrorService: HandleErrorService,
    private productService: ProductService,
    public projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private fileService: FileService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    this.myUserId = this.authService.getLoggedInUser()?.id;
    this.task = this.data?.task ?? {};
    this.accessoryData = this.task.accessory_data && JSON.parse(this.task.accessory_data);
    this.project = this.data?.project || (await this.projectService.getProjectById(this.task.project_id).toPromise());

    if (this.accessoryData?.reviewFiles) {
      this.reviewFiles = this.accessoryData.reviewFiles;
    } else {
      await this.projectService
        .loadTaskActivity(this.task.id)
        .toPromise()
        .then(async (activities) => {
          const approvals = [];

          // Loops through events and selects approval events with files attached to them.
          if (this.isSignatureReview) {
            for (const event of activities.events) {
              if (event.message === '2') {
                event.files = await this.fileService.getFilesByParentId(ResourceType.Event, event.id).toPromise();

                event.type = EventType.APPROVED;
                if (event?.files?.length) {
                  approvals.push(event);
                }
              }
            }
          }

          // Combines then loops through notes and approvals to find the most recent one with a file attached to it.
          let latestActivity = null;
          activities.notes.concat(approvals).forEach((activity) => {
            if (!latestActivity && activity?.files?.length) {
              latestActivity = activity;
            } else if (activity?.files?.length) {
              const timeDiff = moment(latestActivity.created_datetime).diff(moment(activity.created_datetime));
              if (timeDiff <= 0) {
                latestActivity = activity;
              }
            }
          });

          // Get the corresponding files for the most recent activity if it exists
          if (latestActivity) {
            const activityType = latestActivity?.type === EventType.APPROVED ? ResourceType.Event : ResourceType.Note;
            this.reviewFiles =
              (await this.fileService.getFilesByParentId(activityType, latestActivity.id).toPromise()) || [];
          }
        });
    }
    this.selectedFile = this.reviewFiles[0] || null;
  }

  get isSignatureReview() {
    return this.authService.reviewHasDigitalSignatures(this.accessoryData?.type);
  }

  get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  openFileAttachmentDialog() {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.task.project_id || this.projectService.currentSelectedProjectId,
          allowComment: false,
          // additionalParents,
          maxFiles,
          project_id: this.task.project_id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          const files: UhatFileReference[] = resultData;
          for (const file of files) {
            // default to checking for file_id, and fall back to id if not (this account for bridge files correctly)
            if (this.attachedFiles.find((f) => (f.file_id ?? f.id) === (file.file_id ?? file.id)) == null) {
              this.attachedFiles.push(file);
            }
          }
        }
      });
  }

  updateSelectedFile(file) {
    this.selectedFile = file;
  }

  public removeFile(file) {
    this.attachedFiles = this.attachedFiles.filter((f) => f.id !== file.id);
  }

  submitApproval({ approval, comment, files }) {
    this.approvalStatus = approval;
    this.attachedFiles = [...this.attachedFiles, ...files];
    this.reviewComment = comment;
    if (this.isValid()) {
      if (this.approvalStatus) {
        this.dialogRef.close({
          approvalStatus: this.approvalStatus,
          approvalComment: comment,
          attachedFiles: files,
        });
      } else {
        this.dialogRef.close();
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  get hasComment() {
    return !!this.reviewComment;
  }
  get hasFiles() {
    return !!this.attachedFiles?.length;
  }
  get rejectedReviewIsValid() {
    return (
      !this.approvalStatus ||
      ([TaskReviewStatus.Rejected, TaskReviewStatus.NoteMarkings].includes(this.approvalStatus) &&
        (this.hasComment || this.hasFiles))
    );
  }

  private allFilesHaveBeenViewed() {
    return !this.fileHasBeenViewed.find((file) => !file);
  }

  public isValid(): boolean {
    const hasSignatureIfNeeded = !this.isSignatureReview || (this.reviewSigned && this.allFilesHaveBeenViewed());

    return (this.approvalStatus === TaskReviewStatus.Approved && hasSignatureIfNeeded) || this.rejectedReviewIsValid;
  }
}
