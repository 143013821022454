import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuoteItemStatus } from 'src/app/enums';
import { ProductService } from 'src/app/services';
import { ProjectProduct, Quote, QuoteItem } from '../../types';

@Component({
  selector: 'app-view-quote-dialog',
  templateUrl: './view-quote-dialog.component.html',
  styleUrls: ['./view-quote-dialog.component.scss'],
})
export class ViewQuoteDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ViewQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private productService: ProductService
  ) {}

  public quote: Quote = {
    files: [],
    quote_file: null,
    solicitation_file: null,
  };

  private projectProductFields: string[] = [
    `id`,
    'name',
    `quantity`,
    `product{id,name}`,
    `size`,
    `color`,
    `model_number`,
    'selected_quote_item_id',
  ];
  private quoteItemFields: string[] = [
    `id`,
    `project_product{${this.projectProductFields.join(',')}}`,
    `status_id`,
    `quantity`,
    `unit_price`,
    `total_price`,
  ];
  private quoteFields: string[] = [
    `id`,
    `company`,
    'contact',
    'description',
    `tax`,
    `subtotal`,
    `quote_items{${this.quoteItemFields.join(',')}}`,
    `quote_file_id`,
    `quote_file{id,name}`,
    `solicitation_file_id`,
    `solicitation_file{id,name}`,
    'files',
  ];

  public showSelected: boolean;

  async ngOnInit() {
    if (this.data?.quote?.id) {
      const quote = await this.productService.getQuoteById(this.data.quote.id, this.quoteFields).toPromise();
      // we sort items here because the quote items have a different sort order than products
      quote.quote_items = quote.quote_items
        .filter((i) => [QuoteItemStatus.BidReceived, QuoteItemStatus.DidNotBid].indexOf(i.status_id) > -1)
        .sort((itemA: QuoteItem, itemB: QuoteItem) => {
          if (itemA?.project_product?.rank > itemB?.project_product?.rank) {
            return 1;
          }

          if (itemA?.project_product?.rank < itemB?.project_product?.rank) {
            return -1;
          }
          return 0;
        });
      this.quote = quote;
    }

    this.showSelected = this.data?.showSelected;
  }

  getProjectProductDetails(projectProduct: ProjectProduct) {
    const details = [];
    // if (projectProduct.size) {
    //   details.push(projectProduct.size);
    // }
    // if (projectProduct.color) {
    //   details.push(projectProduct.color);
    // }
    // if (projectProduct.model_number) {
    //   details.push(projectProduct.model_number);
    // }
    return details.join(' • ');
  }

  public close() {
    this.dialogRef.close();
  }
}
