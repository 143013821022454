import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, DispatchDraft, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class DispatchService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  host: string = environment.serviceHost;
  dispatchDraftUrl = `${this.host}/api/v1/dispatch-drafts`;
  dispatchDraftFields =
    'id,code,module_id,building_id,building,floor_id,floor,suite_id,suite,department_id,department,initial_update,summary,contact_ids,contacts,files,priority_id,priority,title,status_id,status,assigned_user_id,assigned_user,selected_topic_group_id,selected_topic_group,selected_category_id,selected_category,topic_id,topic,requester_id,requester,created_by_id,created_by,created_datetime,request_method_id,request_method{id,name,is_enabled,is_default,icon}';
  dispatchCountsUrl = `${this.host}/api/v1/dispatch/counts`;

  getDispatchDraft(dispatchId: number, fields?: string[]): Observable<DispatchDraft> {
    const queryFields = fields ? fields.join(',') : this.dispatchDraftFields;
    return this.http.get(`${this.dispatchDraftUrl}/${dispatchId}?fields=${queryFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data[`dispatch drafts`][0];
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getDispatchDrafts(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<DispatchDraft[]> {
    const queryFields = fields ? fields.join(',') : this.dispatchDraftFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.dispatchDraftUrl}?limit=${limit || 1000}&fields=${queryFields}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.dispatch_drafts;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createDispatchDraft(dispatchDraft: DispatchDraft): Observable<DispatchDraft> {
    const body = dispatchDraft;
    return this.http.post(`${this.dispatchDraftUrl}?fields=${this.dispatchDraftFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdDispatchDraft: DispatchDraft = result.data[`dispatch drafts`];
        return createdDispatchDraft;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateDispatchDraft(dispatchDraftId: number, dispatchDraft: DispatchDraft): Observable<DispatchDraft> {
    const body = dispatchDraft;
    return this.http.put(`${this.dispatchDraftUrl}/${dispatchDraftId}?fields=${this.dispatchDraftFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdDispatchDraft: DispatchDraft = result.data[`dispatch drafts`];
        return createdDispatchDraft;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteDispatchDraft(dispatchDraftId: number): Observable<void> {
    return this.http.delete(`${this.dispatchDraftUrl}/${dispatchDraftId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  convertDraft(draftId: number): Observable<number> {
    return this.http.post(`${this.dispatchDraftUrl}/${draftId}/convert`, null).pipe(
      map((result: ServiceResponse) => {
        const convertedItem = result.data.convertedId;
        return convertedItem;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getDispatchCounts(): Observable<any> {
    return this.http.get(`${this.dispatchCountsUrl}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data;
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
