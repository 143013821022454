import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FOUND_PAGE } from 'src/app/enums';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public found_page = FOUND_PAGE;
  public status: FOUND_PAGE.NOT_AUTHORIZED | FOUND_PAGE.NOT_FOUND | FOUND_PAGE.UN_KNOWN = FOUND_PAGE.UN_KNOWN;
  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe((params: Params): void => {
      this.status = params.status;
    });

    this._route.data.subscribe(({ status }: { status: FOUND_PAGE | undefined }) => {
      if (status) {
        this.status = status;
      }
    });
  }
}
