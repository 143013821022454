import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';
import {
  FileService,
  MeetingService,
  ProgressIndicatorService,
  ProjectOverviewService,
  WorkOrderService,
} from 'src/app/services';
import { EditorComponent, FileAttachmentDialogComponent } from '..';
import { ResourceType } from 'src/app/enums';
import { ProjectUpdate, WorkOrderUpdate } from 'src/app/types';
import { catchError, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HtmlEncodeDecodePipe } from 'src/app/pipes';
@Component({
  selector: 'app-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
  styleUrls: ['./edit-note-dialog.component.scss'],
})
export class EditNoteDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  private _action = 'Edit';
  private _dialogTitle = 'Note';
  private _htmlEncodeDecodePipe = new HtmlEncodeDecodePipe();

  public noteFiles = [];
  private originalFiles = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _agendaItemNote,
    private _dialogRef: MatDialogRef<EditNoteDialogComponent>,
    private _dialog: MatDialog,
    private _fileService: FileService,
    private _meetingService: MeetingService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _projectOverviewService: ProjectOverviewService,
    private _snackbar: MatSnackBar,
    private _workOrderService: WorkOrderService
  ) {}

  ngOnInit(): void {
    if (this._agendaItemNote?.message) {
      this.note.setValue(this._htmlEncodeDecodePipe.transform(this._agendaItemNote.message, false));
    }
    if (this._agendaItemNote?.files) {
      this.noteFiles = [...this._agendaItemNote.files];
      this.originalFiles = [...this._agendaItemNote.files];
    }
  }

  get dialogTitle(): string {
    return `${this._action} ${this._dialogTitle}`;
  }

  get inValid(): boolean {
    return !this.note.valid;
  }

  get note(): AbstractControl {
    return this._editor_component.content;
  }

  get noteId(): number {
    return this._agendaItemNote.id;
  }

  get fileChanges(): boolean {
    return this.noteFiles.length !== this.originalFiles.length || !isEqual(this.noteFiles, this.originalFiles);
  }

  private async _updateParentNoteOrUpdate() {
    switch (this._agendaItemNote?.created_item_type_id_from_agenda) {
      case ResourceType.ProjectUpdate:
        await this._updateProjectUpdate();
        break;
      case ResourceType.Task:
        await this._updateTask();
        break;
      case ResourceType.WorkOrderUpdate:
        await this._updateWorkOrderUpdate();
        break;
      default:
        return;
    }
  }

  // TODO uncomment when the reverse is connected in the future
  private async _updateProjectUpdate() {
    const projectUpdate: ProjectUpdate = {
      message: this.note.value,
    };

    await this._projectOverviewService
      .updateProjectUpdate(this._agendaItemNote?.created_item_id_from_agenda, projectUpdate)
      .pipe(
        map((result) => result),
        catchError((e) => {
          return e;
        })
      )
      .toPromise();
  }

  // TODO uncomment when the reverse is connected in the future
  private async _updateTask() {
    // TODO
  }

  // TODO uncomment when the reverse is connected in the future
  private async _updateWorkOrderUpdate() {
    const workOrderUpdate: WorkOrderUpdate = {
      message: this.note.value,
    };

    await this._workOrderService
      .updateWorkOrderUpdate(this._agendaItemNote?.created_item_id_from_agenda, workOrderUpdate)
      .pipe(
        map((result) => result),
        catchError((e) => {
          return e;
        })
      )
      .toPromise();
  }

  public cancel(): void {
    this._dialogRef.close();
  }

  public async editAgendaItemNote() {
    if (this.noteId && !this.inValid) {
      this._progressIndicatorService.openAwaitIndicatorModal();
      this._progressIndicatorService.updateStatus('Saving the edits...');
      const result = await this._meetingService
        .updateAgendaNote(this.noteId, {
          message: this._htmlEncodeDecodePipe.transform(this.note?.value ?? '', true),
        })
        .toPromise();
      await this.updateNoteFiles();
      // if we update the meeting note, we want the corresponding note to update
      // TODO uncomment when the reverse is connected in the future
      /*
      if (
        this._agendaItemNote?.created_item_id_from_agenda &&
        this._agendaItemNote?.created_item_type_id_from_agenda
      ) {
        await this._updateParentNoteOrUpdate();
      }
      */
      this._dialogRef.close(result);
      this._progressIndicatorService.close();
    }
  }

  private async updateNoteFiles() {
    const filesToAdd = [];
    for (const f of this.noteFiles) {
      if (!this.originalFiles.find((original) => original.id === f.id)) {
        filesToAdd.push(f);
      }
    }
    const filesToRemove = [];
    for (const f of this.originalFiles) {
      if (!this.noteFiles.find((current) => current.id === f.id)) {
        filesToRemove.push(f);
      }
    }

    for (const f of filesToAdd) {
      // need to surround in try/catch, as we can't use the uploadModal here, so we risk running into duplicate names
      try {
        // create the file by linking to parent (as files can't be directly created with note as parent, since note has no folder_path)
        const newFile = await this._fileService
          .createFile(f, this._agendaItemNote.parent_id, ResourceType.Agenda)
          .toPromise();
        // also link the file to the note
        await this._fileService.linkFile(newFile.id, this.noteId, ResourceType.Note).toPromise();
      } catch (e) {
        this._snackbar.open(
          'A file failed to upload, as there is another file with a duplicate name already uploaded to this agenda item!',
          'Accept',
          { duration: 5000 }
        );
      }
    }
    for (const f of filesToRemove) {
      await this._fileService
        .getBridgeFile(f.id, this.noteId, ResourceType.Note)
        .toPromise()
        .then(async (bridge) => {
          for (const file of bridge) {
            await this._fileService.unlinkFile(file.id).toPromise();
          }
        });
      await this._fileService
        .getBridgeFile(f.id, this._agendaItemNote.parent_id, ResourceType.Agenda)
        .toPromise()
        .then(async (bridge) => {
          for (const file of bridge) {
            await this._fileService.unlinkFile(file.id).toPromise();
          }
        });
      this._fileService.deleteFileWithoutConfirmation(f).subscribe();
    }

    // set the agenda item to have these files
    this._agendaItemNote.files = [...this.noteFiles];
  }

  // This set of functions serves to handle any temporary files uploaded to be attached to a new note
  public addFileToNewNote(files) {
    this.noteFiles = [...files, ...this.noteFiles];
  }
  public removeNewNoteFile(file) {
    this.noteFiles.splice(this.noteFiles.indexOf(file), 1);
  }
}
