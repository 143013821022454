import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArfStatus, Order } from 'src/app/enums';
import { GetArfProductsTotalPricePipe, GetArfProductSubCostCodesPipe } from 'src/app/pipes';
import {
  ArfsService,
  AuthService,
  ExportService,
  ModalService,
  ModuleService,
  ProgressIndicatorService,
} from 'src/app/services';
import { Arf, Preferences, SelectOption } from 'src/app/types';
import { convertDateToFiscalYear, getUniqueArray, PreferenceStorage } from 'src/app/utils';

export interface ArfListDynamicFilterValue {
  company: string;
  moduleIds: string[];
  fiscalYears: string[];
  search: string;
  statusId: number | string;
}

interface ArfListPreferences extends Preferences {
  moduleIds: string[];
  fiscalYears: string[];
  sort: Sort;
  statusId: string | number;
}

interface ArfListFilterOptions {
  modules: SelectOption[];
  fiscalYears: string[];
}

interface ArfListDynamicFilteredOptions {
  companies: string[];
}

@Component({
  selector: 'app-arf-list',
  templateUrl: './arf-list.component.html',
  styleUrls: ['./arf-list.component.scss'],
})
export class ArfListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) matSort?: MatSort;
  dataSource: MatTableDataSource<Arf> = new MatTableDataSource();
  displayedColumns = [
    'code',
    'fiscal_year',
    'title',
    'company',
    'created_datetime',
    'sub_cost_codes',
    'module',
    'total',
    'arf_status',
  ];
  filters = new FormGroup({
    company: new FormControl(''),
    moduleIds: new FormControl([]),
    purchaseType: new FormControl(),
    fiscalYears: new FormControl([]),
    search: new FormControl(''),
    statusId: new FormControl(''),
  });
  filterOptions: ArfListFilterOptions = {
    modules: [],
    fiscalYears: [],
  };
  dynamicFilterOptions: ArfListDynamicFilteredOptions = {
    companies: [],
  };
  showFilters = false;
  arfStatusOptions: SelectOption[] = [
    { name: 'Draft', value: '1' },
    { name: 'In Review', value: '2' },
    { name: 'Approved', value: '3' },
    { name: 'Finalized', value: '4' },
    { name: 'Closed', value: '5' },
  ];
  isAdmin = false;
  isDataAnalyst = false;
  filteredCompanyOptions$: Observable<string[]>;
  private arfQueryFields = [
    'id',
    'code',
    'title',
    'description',
    'products{total_price,sub_cost_code_budget{sub_cost_code{code}}}',
    'invoices{total}',
    'company',
    'module',
    'purchase_type',
    'fiscal_year',
    'arf_status{id,name}',
    'created_datetime',
  ];
  private subscriptions = new Subscription();
  private preferences = new PreferenceStorage<ArfListPreferences>('preferences_arf_list', {
    moduleIds: [],
    fiscalYears: [],
    sort: { active: 'created_datetime', direction: 'desc' },
    statusId: '',
    version: 3,
  });

  constructor(
    private arfService: ArfsService,
    private authService: AuthService,
    private exportService: ExportService,
    private modalService: ModalService,
    private moduleService: ModuleService,
    private progressIndicatorService: ProgressIndicatorService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.dataSource.filterPredicate = (data, filter): boolean => {
      const filterObject = filter as unknown as ArfListDynamicFilterValue;
      const includesCompany =
        !filterObject.company || data.company?.name.toLowerCase().includes(filterObject.company.trim().toLowerCase());
      const includesModule =
        !filterObject.moduleIds.length || filterObject.moduleIds.includes(data.module?.id.toString());
      const includesFiscalYear =
        !filterObject.fiscalYears.length ||
        filterObject.fiscalYears.includes(data.fiscal_year?.toString() || '( fiscal year not set )');
      const includesSearch =
        !filterObject.search ||
        `${data.code} ${data.title} ${data.company?.name} ${(data.products || [])
          .map((p) => p.sub_cost_code_budget?.sub_cost_code?.code)
          .join()}`
          .toLowerCase()
          .includes(filterObject.search.trim().toLowerCase());
      // when no filter is set, ie. 'All Active', then omit closed status IDs (5)
      const includesStatus = !filterObject.statusId
        ? data.arf_status.id !== 5
        : filterObject.statusId.toString() === data.arf_status.id.toString();
      return includesCompany && includesModule && includesFiscalYear && includesSearch && includesStatus;
    };

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      const arfProductsTotalPricePipe = new GetArfProductsTotalPricePipe();
      switch (sortHeaderId) {
        case 'company':
          return data.company?.name;
        case 'module':
          return data.module?.name;
        case 'purchase_type':
          return data.purchase_type?.name;
        case 'arf_status':
          return data.arf_status?.name;
        case 'total':
          return arfProductsTotalPricePipe.transform(
            data.products,
            data.status_id === ArfStatus.Closed ? data.invoices : null
          );
        default:
          return data[sortHeaderId] as string | number;
      }
    };
  }

  get currentFiscalYear(): string {
    return convertDateToFiscalYear().toString();
  }

  get isExporting(): boolean {
    return !!this.exportService?.isExporting;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.moduleService?.workspace_id);
  }

  get filtersAppliedCount(): number {
    let filtersAppliedCount = 0;
    filtersAppliedCount += this.filters?.get('company')?.value?.length ? 1 : 0;
    filtersAppliedCount += this.filters?.get('search')?.value?.length ? 1 : 0;
    return filtersAppliedCount;
  }

  ngOnInit(): void {
    void this.refresh();

    this.subscriptions.add(
      this.filters.valueChanges.subscribe((filters: ArfListDynamicFilterValue) => {
        this.preferences.setPartialValue({
          moduleIds: filters.moduleIds,
          fiscalYears: filters.fiscalYears,
          statusId: filters.statusId,
        });
        this.dataSource.filter = filters as unknown as string;
        this.dynamicFilterOptions = this.getDynamicFilterOptions(this.dataSource.filteredData);
        this.filteredCompanyOptions$ = this.getFilteredCompanyOptions();
      })
    );

    this.isAdmin = this.authService.isAppAdmin || this.authService.isCFMO || this.authService.isFinanceManager;
    this.isDataAnalyst = this.authService.isDataAnalyst;

    this.moduleService.executeAfterServiceInitialization(() => {
      let moduleIds;
      if (this.isAdmin || this.isDataAnalyst) {
        moduleIds = this.preferences.currentValue.moduleIds;
        if (this.preferences.currentValue.moduleIds.length === 0) {
          moduleIds = [this.moduleService.workspace_id.toString()];
        }
      } else {
        moduleIds = [this.moduleService.workspace_id.toString()];
      }

      this.filters.patchValue({
        moduleIds: moduleIds,
        fiscalYears: this.preferences.currentValue.fiscalYears,
        statusId: this.preferences.currentValue.statusId,
      });
    });

    this.filteredCompanyOptions$ = this.getFilteredCompanyOptions();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

    this.dataSource.sort = this.matSort;
    this.dataSource.sort.disableClear = true;

    this.subscriptions.add(
      this.dataSource.sort.sortChange.subscribe((sort) => this.preferences.setPartialValue({ sort }))
    );

    setTimeout(() => {
      this.matSort.sort({
        id: this.preferences.currentValue.sort.active,
        start: this.preferences.currentValue.sort.direction || 'asc',
        disableClear: true,
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async refresh(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Getting ARFs...');
    try {
      const arfQueryResponse = await this.arfService
        .getArfs([], this.arfQueryFields, 'created_datetime', Order.DESC)
        .toPromise();
      const legacyArfQueryResponse = await this.arfService.getLegacyArfs().toPromise();
      this.dataSource.data = [...arfQueryResponse, ...legacyArfQueryResponse];
      this.filterOptions = this.getFilterOptions(this.dataSource.data);
      this.dynamicFilterOptions = this.getDynamicFilterOptions(this.dataSource.filteredData);
    } catch (err) {
      console.error(err);
      this.snackBar.open('Something went wrong while getting ARFs', 'Close');
    } finally {
      this.progressIndicatorService.close();
    }
  }

  handleClearFilters(): void {
    this.filters.setValue({
      company: '',
      moduleIds: [this.moduleService.workspace_id.toString()],
      purchaseType: null,
      fiscalYears: [this.currentFiscalYear],
      search: '',
      statusId: '',
    });
  }

  handleSelectAll(formControlName: 'moduleIds' | 'fiscalYears'): void {
    const formControl = this.filters.get(formControlName);

    switch (formControlName) {
      case 'moduleIds':
        if (formControl.value.length === this.filterOptions.modules.length) {
          formControl.setValue([]);
        } else {
          formControl.setValue(this.filterOptions.modules.map((m) => m.value));
        }
        break;
      case 'fiscalYears':
        if (formControl.value.length === this.filterOptions.fiscalYears.length) {
          formControl.setValue([]);
        } else {
          formControl.setValue(this.filterOptions.fiscalYears);
        }
    }
  }

  private getFilterOptions(data: Arf[]): ArfListFilterOptions {
    return {
      modules: getUniqueArray(
        data.filter((arf) => !!arf.module).map((arf) => ({ name: arf.module?.name, value: arf.module?.id.toString() }))
      ).sort((a, b) => (a.name > b.name ? 1 : -1)),
      fiscalYears: [
        ...getUniqueArray(data.filter((arf) => !!arf.fiscal_year).map((arf) => arf.fiscal_year.toString()))
          .sort((a, b) => (a > b ? 1 : -1))
          .reverse(),
        '( fiscal year not set )',
      ],
    };
  }

  private getDynamicFilterOptions(data: Arf[]): ArfListDynamicFilteredOptions {
    return {
      ...this.dynamicFilterOptions,
      companies: getUniqueArray(data.filter((arf) => !!arf.company).map((arf) => arf.company?.name)).sort(),
    };
  }

  private getFilteredCompanyOptions(): Observable<string[]> {
    return this.filters.get('company').valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.dynamicFilterOptions.companies.filter((company) => company.toLowerCase().includes(value.toLowerCase()))
      )
    );
  }

  public exportARF(): void {
    try {
      const dataToReturn: string[] = [
        'Code, Fiscal Year, Title, Company, Created Datetime, Sub Cost Codes, Workspace, Total, Status',
      ];

      const arfProductsTotalPricePipe = new GetArfProductsTotalPricePipe();
      const productSubCostCode = new GetArfProductSubCostCodesPipe();

      for (const entry of this.dataSource.filteredData) {
        const dateOpened = entry.created_datetime ? moment(entry.created_datetime).format('MM/DD/YYYY HH:mm:ss') : null;
        const total =
          arfProductsTotalPricePipe
            .transform(entry.products, entry.status_id === ArfStatus.Closed ? entry.invoices : null)
            ?.toString() || '-';

        // sanitize and push the data
        dataToReturn.push(
          this.exportService.sanitizeItems([
            entry.code || '-',
            entry?.fiscal_year?.toString() || '-',
            entry.title || '-',
            entry.company?.name || '-',
            dateOpened || '-',
            productSubCostCode.transform(entry.products) || '-',
            entry.module?.name || '-',
            total,
            entry.arf_status?.name || '-',
          ])
        );
      }
      this.exportService.exportDataWithConfirmation(
        dataToReturn,
        `arf.csv`,
        `Confirm ARFs' Export`,
        `ARF export will use the currently selected filter settings. Are you sure you wish to continue?`
      );
    } catch (err) {
      this.snackBar.open(`Something went wrong while downloading ARFs`, `Close`);
    }
  }

  async createArf(): Promise<void> {
    const choice = await this.modalService
      .openConfirmationChoiceDialog({
        title: 'Create ARF',
        heading: 'ARF Reminder',
        subHeading: `A Project is required for: <br/> &nbsp;&nbsp;a. Tenant Reimbursements <br/> &nbsp;&nbsp;b. Total purchase price is greater than $25,000 and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;requires solicitations.`,
        option1: { text: 'Create Project Request', value: 1 },
        option2: { text: 'Create ARF', value: 2 },
      })
      .toPromise();
    if (choice === 2) {
      const arfData = {
        module_id: this.moduleService.workspace_id,
        fiscal_year: convertDateToFiscalYear().toString(),
        status_id: ArfStatus.Draft,
      };

      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Creating ARF...');

      const createdArf = await this.arfService.createArf(arfData, this.arfService.arfListFields).toPromise();

      if (createdArf?.id) {
        await this.router.navigateByUrl(`/purchasing/arfs/${createdArf.id}`);
      }

      this.progressIndicatorService.close();
    }
    if (choice === 1) {
      await this.router.navigateByUrl(`/new-request`);
    } else {
      this.progressIndicatorService.close();
    }
  }
}
