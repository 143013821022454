import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditorComponent } from 'src/app/components';
import { AuthService, ProgressIndicatorService, WeeklyFocusService } from 'src/app/services';
import { WeeklyFocus } from 'src/app/types';

@Component({
  selector: 'app-weekly-focus',
  templateUrl: './weekly-focus.component.html',
  styleUrls: ['./weekly-focus.component.scss'],
})
export class WeeklyFocusComponent implements OnInit {
  @ViewChild('creedEditor', { static: true }) private _creed_editor: EditorComponent;
  @ViewChild('cqiEditor', { static: true }) private _cqi_editor: EditorComponent;
  @ViewChild('principleQualityEditor', { static: true }) private _principle_quality_editor: EditorComponent;
  notify = true;

  public isEditing = false;
  public weeklyFocus = this._weeklyFocusService.weeklyFocus;

  constructor(
    public _authService: AuthService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _snackbar: MatSnackBar,
    private _weeklyFocusService: WeeklyFocusService
  ) {}

  ngOnInit(): void {
    this._weeklyFocusService.getWeeklyFocus().subscribe((weeklyFocus: WeeklyFocus) => {
      Object.assign(this.weeklyFocus, weeklyFocus);
    });
  }

  get weeklyFocusPulsing(): boolean {
    return (
      this._weeklyFocusService?.weeklyFocus?.id &&
      this._weeklyFocusService?.storedWeeklyFocusTimestamp !== this.weeklyFocus?.timestamp
    );
  }

  get isWeeklyFocusEditor(): boolean {
    return this._authService.isCreedUser;
  }

  public cancel(): void {
    this.isEditing = false;
  }

  public edit(): void {
    this.isEditing = true;
    if (this._creed_editor?.content) {
      this._creed_editor.content.setValue(this.weeklyFocus?.creedFocus || '');
    }
    if (this._cqi_editor?.content) {
      this._cqi_editor.content.setValue(this.weeklyFocus?.cqiTraining || '');
    }
    if (this._principle_quality_editor?.content) {
      this._principle_quality_editor.content.setValue(this.weeklyFocus?.principleOfQuality || '');
    }
  }

  public muteWeeklyFocus(): void {
    this._weeklyFocusService.muteWeeklyFocus();
  }

  public async save(): Promise<void> {
    const whatChanged = [];
    if (this._cqi_editor?.content?.value !== this.weeklyFocus?.cqiTraining) {
      whatChanged.push('cqi');
    }

    if (this._creed_editor?.content?.value !== this.weeklyFocus?.creedFocus) {
      whatChanged.push('creed');
    }

    if (this._principle_quality_editor?.content?.value !== this.weeklyFocus?.principleOfQuality) {
      whatChanged.push('quality');
    }

    // Only save if something changed
    if (whatChanged?.length) {
      this._progressIndicatorService.openAwaitIndicatorModal();
      this._progressIndicatorService.updateStatus('Saving weekly focus...');

      // use the existing one and modify it
      const weeklyFocusToUpdate: WeeklyFocus = {
        id: this.weeklyFocus.id,
        cqiTraining: whatChanged.includes('cqi') ? this._cqi_editor?.content.value : this.weeklyFocus.cqiTraining,
        creedFocus: whatChanged.includes('creed') ? this._creed_editor?.content.value : this.weeklyFocus?.creedFocus,
        principleOfQuality: whatChanged.includes('quality')
          ? this._principle_quality_editor?.content.value
          : this.weeklyFocus?.principleOfQuality,

        timestamp: this.notify ? Date.now().toString() : this.weeklyFocus.timestamp,
      };

      const updatedWeekFocus = await this._weeklyFocusService
        .updateWeeklyFocus(this.weeklyFocus.id, weeklyFocusToUpdate)
        .toPromise();

      Object.assign(this.weeklyFocus, updatedWeekFocus);
      this._progressIndicatorService.close();
      this._snackbar.open('Saved Weekly Focus!');
    }
    this.isEditing = false;
  }
}
