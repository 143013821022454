import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';

import { AppRoutingService, AuthService } from 'src/app/services';
import { ViewTaskDialogComponent } from 'src/app/components';

@Component({
  selector: 'app-project-overview-dialog',
  templateUrl: './project-overview-dialog.component.html',
  styleUrls: ['./project-overview-dialog.component.scss'],
})
export class ProjectOverviewDialogComponent implements OnInit {
  filteredTasks;
  selectedStatus;
  myId;
  users = [];
  constructor(
    public dialogRef: MatDialogRef<ProjectOverviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authService: AuthService,
    private appRoutingService: AppRoutingService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.myId = this.authService.getLoggedInUser().id;
    if (!this.data?.users?.allUsers && !this.data?.dontFilterTasks) {
      this.data.users.allUsers = (this.data.users.staff || [])
        .concat(this.data.users.tenants || [])
        .concat(this.data.users.vendors || []);
    }
    this.filterTasks(this.data.status);
  }

  filterTasks(status) {
    if (status) {
      this.selectedStatus = status;
    }
    if (!this.selectedStatus) {
      this.selectedStatus = 'To-Do';
    }
    const now = moment().format();
    this.filteredTasks =
      (this.data?.dontFilterTasks && this.data.tasks) ||
      this.data.tasks.filter((t) => {
        if (this.data.mine === true && this.myId !== t.assigned_user_id) {
          return false;
        }
        if (this.data.selectedUser && this.data.selectedUser.id !== t.assigned_user_id) {
          return false;
        }
        if (this.data.selectedUser) {
          return true;
        }
        if (this.selectedStatus === 'To-Do' || this.selectedStatus === 'Complete') {
          return t.status_name === this.selectedStatus;
        } else if (this.selectedStatus === 'Overdue' && t.status_name !== 'Complete' && t.due_date < now) {
          return true;
        } else if (this.selectedStatus === 'Coming Due' && t.status_name !== 'Complete' && t.due_date > now) {
          return true;
        }
        // else return true;
      });
  }

  public gotoTask(taskId: number) {
    this.appRoutingService.gotoProjectTask(taskId);
    this.cancel();
  }

  public viewTask(taskId: number) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.taskChanged.subscribe((changedTask) => {
      this.filteredTasks[this.filteredTasks.map((t) => +t.id).indexOf(+changedTask.id)] = changedTask;
    });
  }

  private getDueDateFormattedText(dueDate): string {
    if (!dueDate) {
      return;
    }
    const daysUntilDueDate = this.daysUntilDueDate(dueDate);
    const dueInFuture = daysUntilDueDate > 0;
    const pastDue = daysUntilDueDate < 0;
    const oneDayDiff = daysUntilDueDate === 1 || daysUntilDueDate === -1;
    const dueDateText = `Due${dueInFuture ? ' in' : ''} ${pastDue ? -1 * daysUntilDueDate : daysUntilDueDate} Day${
      oneDayDiff ? '' : 's'
    }${pastDue ? ' ago' : ''}`;
    return dueDateText;
  }

  daysUntilDueDate(dueDate) {
    if (dueDate === null) {
      return null;
    }
    return this.daysUntilDate(dueDate);
  }

  private daysUntilDate(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  }
  viewUsers(type) {
    this.data.taskUserFilter = type;
    this.data.showUsers = true;
    this.data.selectedUser = null;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
