import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Subscription } from 'rxjs';
import { LocationService, ProgressIndicatorService } from 'src/app/services';
import { Address, APIFilter, Building, Floor } from 'src/app/types';
import { US_States } from 'src/app/utils/constants';
import { isJsonEquivalent } from '../../utils';
import { FloorDialogComponent } from '../floor-dialog/floor-dialog.component';

@Component({
  selector: 'app-building-manager-dialog',
  templateUrl: './building-manager-dialog.component.html',
  styleUrls: ['./building-manager-dialog.component.scss'],
})
export class BuildingManagerDialogComponent implements OnInit, OnDestroy {
  action: string = this.data.action;
  building: Building = this.data.building;
  buildingFormGroup: FormGroup = this.fb.group({
    name: [this.building.name ?? '', [Validators.required]],
    code: [this.building.code ?? '', [Validators.required, Validators.minLength(2), Validators.maxLength(7)]],
    circulation_factor: [this.building.circulation_factor ?? null],
    is_enabled: [!!this.building.is_enabled ?? true],
    physical_address: this.fb.group({
      address: [this.building.physical_address?.address ?? ''],
      city: [this.building.physical_address?.city ?? ''],
      state: [this.building.physical_address?.state ?? ''],
      zipcode: [this.building.physical_address?.zipcode ?? ''],
    }),
    mailing_address: this.fb.group({
      address: [this.building.mailing_address?.address ?? ''],
      city: [this.building.mailing_address?.city ?? ''],
      state: [this.building.mailing_address?.state ?? ''],
      zipcode: [this.building.mailing_address?.zipcode ?? ''],
    }),
  });
  usStates = US_States;
  isPhysicalAddressActive = new FormControl(false);
  isMailingAddressSameAsPhysicalAddress = new FormControl(true);
  private sourceFloorDrag: Floor;
  private targetFloorDrag: Floor;
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<BuildingManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: string; building: Building },
    private fb: FormBuilder,
    private locationService: LocationService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  get name(): AbstractControl {
    return this.buildingFormGroup.get('name');
  }

  get code(): AbstractControl {
    return this.buildingFormGroup.get('code');
  }

  get physical_address(): FormGroup {
    return this.buildingFormGroup.get('physical_address') as FormGroup;
  }

  get mailing_address(): FormGroup {
    return this.buildingFormGroup.get('mailing_address') as FormGroup;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.isPhysicalAddressActive.valueChanges.subscribe((isActive: boolean) => {
        if (!isActive) {
          this.isMailingAddressSameAsPhysicalAddress.setValue(true);
        }
        this.handleAddressToggle(isActive, this.physical_address);
      })
    );

    this.subscriptions.add(
      this.isMailingAddressSameAsPhysicalAddress.valueChanges.subscribe((isSame: boolean) => {
        this.handleAddressToggle(!isSame, this.mailing_address);
      })
    );

    this.setInitialAddressToggleValues();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(): void {
    void (async () => {
      if (!this.buildingFormGroup.valid) {
        return;
      }
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving...');
      const formBuilding = this.buildingFormGroup.value as Building;
      const buildingToSave: Building = {
        name: formBuilding.name,
        code: formBuilding.code,
        circulation_factor: formBuilding.circulation_factor ?? null,
        is_enabled: formBuilding.is_enabled ? 1 : 0,
      };

      // update addresses
      const physicalAddressToSave = formBuilding.physical_address;
      const mailingAddressToSave = formBuilding.mailing_address;

      if (this.isPhysicalAddressActive.value) {
        const physicalAddress = await this.locationService.addAddress(physicalAddressToSave);
        buildingToSave.physical_address_id = physicalAddress.id;

        if (!this.isMailingAddressSameAsPhysicalAddress.value) {
          const mailingAddress = await this.locationService.addAddress(mailingAddressToSave);
          buildingToSave.mailing_address_id = mailingAddress.id;
        } else {
          buildingToSave.mailing_address_id = buildingToSave.physical_address_id;
        }
      } else {
        buildingToSave.physical_address_id = null;
        buildingToSave.mailing_address_id = null;
      }

      if (this.building.id) {
        await this.locationService.updateBuilding(this.building.id, buildingToSave).toPromise();
      } else {
        delete buildingToSave.is_enabled;
        await this.locationService
          .addBuilding(buildingToSave)
          .toPromise()
          .then((building) => {
            this.building.id = building.id;
          });
      }

      // update the sequence of floors to match the array order
      this.building.floors.map((f, i) => {
        f.sequence = i;
      });

      const filter: APIFilter = { type: 'field', field: 'building_id', value: this.building.id };
      let currentFloors: Floor[] = [];
      if (this.building.id) {
        currentFloors = await this.locationService.getFloors(null, [filter]).toPromise();
      }

      const requests = [];
      this.building.floors.forEach((f) => {
        if (!f.id) {
          const request = this.locationService.addFloor({ ...f, building_id: this.building.id });
          requests.push(request);
        } else {
          const currentFloor = currentFloors.find((cf) => cf.id === f.id);
          if (
            currentFloor &&
            (currentFloor.name !== f.name || currentFloor.code !== f.code || currentFloor.sequence !== f.sequence)
          ) {
            const request = this.locationService.updateFloor(f.id, {
              name: f.name,
              code: f.code,
              sequence: f.sequence,
            });
            requests.push(request);
          }
        }
      });

      currentFloors.filter((cf) => {
        if (!this.building.floors.find((f) => cf.id === f.id)) {
          const request = this.locationService.deleteFloor(cf.id);
          requests.push(request);
        }
      });

      await forkJoin(requests).toPromise();

      this.progressIndicatorService.close();
      this.close(true);
      this.snackbar.open('Saved!');
    })();
  }

  close(reload = false): void {
    this.dialogRef.close(reload);
  }

  removeFloor(floor: Floor): void {
    const index = this.building.floors.indexOf(floor);
    this.building.floors.splice(index, 1);
  }

  onDrop(): void {
    const sourceIndex = this.building.floors.indexOf(this.sourceFloorDrag);
    const targetIndex = this.building.floors.indexOf(this.targetFloorDrag);
    if (sourceIndex === targetIndex) {
      return;
    }
    const spliced = this.building.floors.splice(sourceIndex, 1);
    this.building.floors.splice(targetIndex, 0, spliced[0]);
  }

  dragOver(e: DragEvent, floor: Floor): void {
    e.preventDefault();
    this.targetFloorDrag = floor;
  }

  dragStart(floor: Floor): void {
    this.sourceFloorDrag = floor;
  }

  openFloorDialog(action: string, floor?: Floor): void {
    if (!this.building.floors) {
      this.building.floors = [];
    }
    const dialogRef = this.dialog.open(FloorDialogComponent, {
      width: '250px',
      data: {
        action,
        floor,
      },
    });
    dialogRef.afterClosed().subscribe((flr: Floor) => {
      if (flr) {
        flr.sequence = this.building.floors.length;
        flr.building_id = this.building.id;
        if (action === 'Add') {
          this.building.floors.push(flr);
        } else if (action === 'Edit') {
          floor.name = flr.name;
          floor.code = flr.code;
        }
      }
    });
  }

  private setInitialAddressToggleValues() {
    const emptyAddress: Address = { address: '', city: '', state: '', zipcode: '' };

    if (!isJsonEquivalent(this.building.mailing_address, emptyAddress)) {
      this.isMailingAddressSameAsPhysicalAddress.setValue(false);
    }

    if (!isJsonEquivalent(this.building.physical_address, emptyAddress)) {
      this.isPhysicalAddressActive.setValue(true);
    }

    if (isJsonEquivalent(this.building.physical_address, this.building.mailing_address)) {
      this.mailing_address.reset();
      this.isMailingAddressSameAsPhysicalAddress.setValue(true);
    }
  }

  private handleAddressToggle(isActive: boolean, addressFormGroup: FormGroup): void {
    Object.keys(addressFormGroup.controls).forEach((formControlName) => {
      const formControl = addressFormGroup.get(formControlName);
      if (isActive) {
        switch (formControlName) {
          case 'zipcode':
            formControl.setValidators([Validators.required, Validators.pattern(`[0-9]{5}`)]);
            break;
          default:
            formControl.setValidators([Validators.required]);
        }
      } else {
        formControl.clearValidators();
      }
      formControl.updateValueAndValidity();
    });
  }
}
