<div #mainScreen (window:resize)="onResize($event)" class="container-fluid app-container p-5">
  <div class="row align-items-center">
    <div class="col">
      <h3 data-test-id="projects-headline" id="title" class="dkblue font-weight-semibold">Projects</h3>
    </div>
  </div>
  <br />
  <div class="row list-filters align-items-center">
    <div class="col-xl-2 col-lg-4 col-md-4">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [ngModel]="filter.statusId" (selectionChange)="changeFilterStatus($event.value)">
          <mat-option [value]="0"> All Projects </mat-option>
          <mat-option [value]="1"> Active Projects </mat-option>
          <mat-option [value]="2"> Planned Projects </mat-option>
          <mat-option [value]="3"> On Hold Projects </mat-option>
          <mat-option [value]="4"> Closed Projects </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-5 col-md-6">
      <mat-form-field appearance="outline" class="full-width">
        <input
          matInput
          [(ngModel)]="filter.searchString"
          placeholder="Search Projects, Locations, etc."
          (ngModelChange)="updateFilter()"
        />
        <mat-icon *ngIf="filter.searchString" (click)="updateSearchString('')" class="ltgray pointer" matSuffix
          >cancel</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-slide-toggle
        *ngIf="isWorkspaceStaff"
        color="accent"
        labelPosition="after"
        [checked]="filter.byUserId"
        (change)="filterByCurrentUser()"
      >
        <span class="ltblue font-weight-semibold"> My Projects </span>
      </mat-slide-toggle>
    </div>
    <div class="col-auto">
      <mat-slide-toggle
        *ngIf="isWorkspaceStaff"
        color="accent"
        labelPosition="after"
        [checked]="filter.fiveYearCapitalPlan"
        (change)="filterByFiveYearCapitalPlan()"
      >
        <span class="ltblue font-weight-semibold"> 5 Yr Cap </span>
      </mat-slide-toggle>
    </div>
  </div>
  <br /><br />
  <div *ngIf="allProjects">
    <div class="portlet-table-header pl-4 pr-4">
      <div class="row">
        <div
          [ngClass]="{ 'col-auto small-column': divWidth < 1200, 'col-1': divWidth > 1199 }"
          (click)="updateSortByField('id')"
        >
          <label class="main-label pointer" [ngClass]="{ dkblue: projectFieldToSortBy === 'id' }">
            Code
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'id' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'id' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div
          class="pl-0"
          [ngClass]="{ 'col-auto small-column': divWidth < 1200, 'col-1': divWidth > 1199 }"
          (click)="updateSortByField('building_code')"
          *ngIf="isWorkspaceStaff"
        >
          <label class="main-label pointer">
            Location
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'building_code' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'building_code' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div class="col-3 pl-0" (click)="updateSortByField('title')">
          <label class="main-label pointer">
            Project
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'title' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'title' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div class="col pl-0 pointer" *ngIf="isWorkspaceStaff" (click)="updateSortByField('priority_id')">
          <label class="main-label pointer">
            Priority
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'priority_id' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'priority_id' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div class="col pl-0" (click)="updateSortByField('current_phase_name')">
          <label class="main-label pointer">
            Phase
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'current_phase_name' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'current_phase_name' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div
          class="col pl-0 pointer"
          (click)="updateSortByField('budget')"
          *ngIf="!authService.isVendorOnAnyProject && !authService.isEngineerOnAnyProject"
        >
          <label class="main-label pointer">
            Cost
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'budget' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'budget' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div class="col pl-0" (click)="updateSortByField('cost_per_sqft')">
          <label class="main-label pointer">
            Cost / SF
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'cost_per_sqft' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'cost_per_sqft' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div *ngIf="divWidth > 1000" class="col pl-0" (click)="updateSortByField('project_manager_first_name')">
          <label class="main-label pointer">
            PM
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'project_manager_first_name' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'project_manager_first_name' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div *ngIf="divWidth > 1000" class="col pl-0" (click)="updateSortByField('architect_first_name')">
          <label class="main-label pointer">
            Arch
            <i
              class="dkblue fa fa-caret-up ml-2"
              *ngIf="projectFieldToSortBy === 'architect_first_name' && projectSortDirection === 'asc'"
            ></i>
            <i
              class="dkblue fa fa-caret-down ml-2"
              *ngIf="projectFieldToSortBy === 'architect_first_name' && projectSortDirection === 'desc'"
            ></i>
          </label>
        </div>
        <div *ngIf="divWidth < 1001" class="col pl-0">
          <label class="main-label"> PM / Arch </label>
        </div>
      </div>
    </div>
    <div class="m-portlet m-portlet--mobile">
      <div
        class="m-portlet__body item p-4 ease-fast pointer"
        [routerLink]="['/projects/' + p.id]"
        *ngFor="
          let p of allProjects | projectListFilter: filter | orderByField: projectFieldToSortBy:projectSortDirection
        "
      >
        <div data-test-id="project-list-item" class="row pr-0 align-items-center">
          <div [ngClass]="{ 'col-auto small-column': divWidth < 1200, 'col-1': divWidth > 1199 }">
            <p class="dkblue font-weight-semibold m-0">
              {{ p.code }}
            </p>
          </div>
          <div
            class="pl-0"
            [ngClass]="{ 'col-auto small-column': divWidth < 1200, 'col-1': divWidth > 1199 }"
            *ngIf="isWorkspaceStaff"
          >
            <p class="m-0 dkblue font-weight-semibold" *ngIf="p.building_name">
              {{ p.building_code }}{{ p.floor_code ? '-' : '' }}{{ p.floor_code }}
            </p>
          </div>
          <div data-test-id="project-list-title" class="col-3 pl-0">
            <p class="dkblue m-0 font-weight-semibold manage-content text-wrap">
              {{ p.title || 'Project ' + p.code }}
            </p>
          </div>
          <div class="col pl-0" *ngIf="isWorkspaceStaff">
            <h6
              class="m-0 badge text-uppercase pl-2 pr-2 mr-2"
              style="line-height: 16px"
              [ngClass]="{
                'bg-shade-red red': p.priority_id === 3,
                'bg-shade-ltblue ltblue': p.priority_id === 2,
                'bg-shade-gray gray': p.priority_id === 1
              }"
            >
              <span *ngIf="p.priority_id != 2">
                {{ p.priority_name }}
              </span>
              <span *ngIf="p.priority_id === 2"> Med </span>
            </h6>
          </div>
          <div class="col pl-0">
            <p class="dkblue m-0 manage-content">
              {{ p.current_phase_name }}
            </p>
          </div>
          <div class="col pl-0" *ngIf="!authService.isVendorOnAnyProject && !authService.isEngineerOnAnyProject">
            <p *ngIf="p.budget" class="dkblue font-weight-normal m-0 manage-content">
              {{ p.budget | currency: 'USD' }}
            </p>
            <p *ngIf="!p.budget" class="dkblue font-weight-normal m-0">-</p>
          </div>
          <div class="col pl-0" *ngIf="!authService.isVendorOnAnyProject && !authService.isEngineerOnAnyProject">
            <p *ngIf="p.cost_per_sqft" class="dkblue font-weight-normal m-0 manage-content">
              {{ p.cost_per_sqft | USDollar }}
            </p>
            <p *ngIf="!p.cost_per_sqft" class="dkblue font-weight-normal m-0">-</p>
          </div>
          <div class="col pl-0" *ngIf="divWidth > 1000">
            <span
              [ngClass]="{
                opacity10: p.current_phase_name != 'Program' && p.current_phase_name != 'Design'
              }"
            >
              <app-profile-thumbnail
                [borderActive]="p.current_phase_name != 'Program' && p.current_phase_name != 'Design' ? true : false"
                class="d-inline-block align-middle"
                [height]="26"
                [width]="26"
                *ngIf="p.project_manager_id"
                [userId]="p.project_manager_id"
              ></app-profile-thumbnail>
              <p class="dkblue m-0 manage-content d-inline-block align-middle font-weight-semibold text-wrap ml-2">
                {{ p.project_manager_first_name }} {{ p.project_manager_last_name }}
              </p>
            </span>
          </div>
          <div
            class="col pl-0"
            *ngIf="divWidth > 1000 && !authService.isVendorOnAnyProject && !authService.isEngineerOnAnyProject"
          >
            <span
              [ngClass]="{
                opacity10: p.current_phase_name === 'Program' || p.current_phase_name === 'Design'
              }"
            >
              <app-profile-thumbnail
                [borderActive]="p.current_phase_name === 'Program' || p.current_phase_name === 'Design' ? true : false"
                class="d-inline-block align-middle"
                [height]="26"
                [width]="26"
                *ngIf="p.architect_id"
                [userId]="p.architect_id"
              ></app-profile-thumbnail>
              <p class="dkblue m-0 manage-content d-inline-block align-middle font-weight-semibold text-wrap ml-2">
                {{ p.architect_first_name }} {{ p.architect_last_name }}
              </p>
            </span>
          </div>
          <div
            class="col pl-0"
            *ngIf="divWidth < 1001 && !authService.isVendorOnAnyProject && !authService.isEngineerOnAnyProject"
          >
            <app-profile-thumbnail
              [borderActive]="p.current_phase_name != 'Program' && p.current_phase_name != 'Design' ? true : false"
              class="d-inline-block align-middle mr-2"
              [height]="26"
              [width]="26"
              *ngIf="p.project_manager_id"
              [userId]="p.project_manager_id"
            ></app-profile-thumbnail>
            <app-profile-thumbnail
              [borderActive]="p.current_phase_name === 'Program' || p.current_phase_name === 'Design' ? true : false"
              class="d-inline-block align-middle"
              [height]="26"
              [width]="26"
              *ngIf="p.architect_id"
              [userId]="p.architect_id"
            ></app-profile-thumbnail>
          </div>
        </div>
      </div>
    </div>
    <div class="not-found-prompt" *ngIf="filter.searchString && filter.status !== 'All'">
      <div class="row">
        <div class="col text-center">
          <h5 class="dkblue mt-4">Didn't find what you're looking for?</h5>
          <button mat-stroked-button color="accent" class="mt-2 bg-white" (click)="changeFilterStatus(0)">
            Search All Projects
          </button>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="showIpad" class="m-portlet m-portlet--mobile">
    <div
      class="m-portlet__body p-4 item project ease pointer"
      [routerLink]="['/projects/' + p.id]"
      *ngFor="
        let p of allProjects
          | projectListFilter: filter
          | orderByField: projectFieldToSortBy:projectSortDirection
      "
    >
      <div class="row align-items-center">
        <div class="col pointer">
          <h6 class="dkblue m-0 font-weight-semibold">
            {{ p.title || 'Project ' + p.code }}
          </h6>
          <p class="gray m-0 font-weight-normal">
            {{ p.code }}
          </p>
          <p class="ltblue m-0 font-weight-normal" *ngIf="p.building_name">
            {{ p.building_name }}{{ p.floor_name ? ' • Floor ' : '' }}{{ p.floor_name }}
          </p>
        </div>
        <div class="col-auto text-right">
          <h6 class="dkgray m-0">{{ p.current_phase_name }} Phase</h6>
          <p class="ltblue m-0 font-weight-semibold">
            <span class="gray">
              PM:
            </span>
            {{ p.project_manager_first_name }} {{ p.project_manager_last_name }}
          </p>
          <p class="ltblue m-0 font-weight-semibold">
            <span class="gray">
              Archt:
            </span>
            {{ p.architect_first_name }} {{ p.architect_last_name }}
          </p>
        </div>
      </div>
    </div>
  </div> -->
  </div>
</div>
