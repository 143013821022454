import { Pipe, PipeTransform } from '@angular/core';
import { ArfProduct, Invoice } from 'src/app/types';

@Pipe({
  name: 'getArfProductsTotalPrice',
})
export class GetArfProductsTotalPricePipe implements PipeTransform {
  transform(arfProducts?: ArfProduct[], arfInvoices?: Invoice[]): number {
    return !arfInvoices
      ? arfProducts?.reduce((acc, arfProduct) => acc + (arfProduct?.total_price || 0), 0)
      : arfInvoices?.reduce((acc, arfInvoices) => acc + (Number(arfInvoices?.total) || 0), 0);
  }
}
