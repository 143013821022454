<div class="subcomponent container-fluid p-4">
  <div class="row component-subheader align-items-center mb-4 pb-2">
    <div class="col-auto">
      <h4 class="dkblue m-0 font-weight-semibold">Project Directory</h4>
    </div>
    <div class="col text-right">
      <button mat-flat-button color="success" (click)="manageFollowers()" *ngIf="isAdmin">+ Follower</button>
    </div>
  </div>
  <div class="row mb-4 pb-2">
    <div class="col button-tabs">
      <button *ngIf="!isStaff()" (click)="setStaff()" mat-button color="accent">UHAT/1Call</button>
      <button *ngIf="isStaff()" (click)="setStaff()" mat-flat-button color="accent">UHAT/1Call</button>
      &nbsp;
      <button
        *ngIf="!isTenants() && (isWorkspaceStaff || authService.isTenant)"
        (click)="setTenant()"
        mat-button
        color="accent"
      >
        Tenants
      </button>
      <button
        *ngIf="isTenants() && (isWorkspaceStaff || authService.isTenant)"
        (click)="setTenant()"
        mat-flat-button
        color="accent"
      >
        Tenants
      </button>
      &nbsp;
      <button
        *ngIf="
          !isVendors() &&
          (isWorkspaceStaff ||
            authService.isProjectVendor(projectService.currentSelectedProjectId) ||
            authService.isProjectEngineer(projectService.currentSelectedProjectId))
        "
        (click)="setVendor()"
        mat-button
        color="accent"
      >
        Suppliers
      </button>
      <button
        *ngIf="
          isVendors() &&
          (isWorkspaceStaff ||
            authService.isProjectVendor(projectService.currentSelectedProjectId) ||
            authService.isProjectEngineer(projectService.currentSelectedProjectId))
        "
        (click)="setVendor()"
        mat-flat-button
        color="accent"
      >
        Suppliers
      </button>
    </div>
  </div>
  <main>
    <div class="m-portlet">
      <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
        <div class="row align-items-center">
          <div
            class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
            [ngClass]="{ sortedBy: fieldToSortBy === 'first_name' }"
          >
            <h6 (click)="updateSortByField('first_name')" class="m-0 text-truncate header-title mr-auto">Person</h6>
            <i *ngIf="fieldToSortBy === 'first_name'" matRipple class="material-icons">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </i>
          </div>
          <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center">
            <h6 class="m-0 text-truncate header-title mr-auto">Project Role</h6>
          </div>
          <div class="col p-1 pl-3 pr-3 d-flex align-items-center header-item">
            <h6 class="m-0 text-truncate header-title mr-auto">Account Status</h6>
          </div>
          <div
            class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
            [ngClass]="{ sortedBy: fieldToSortBy === 'status' }"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Actions</h6>
          </div>
        </div>
      </div>
      <div
        class="col-12 text-center"
        *ngIf="
          (isStaff() && !projectDirectoryService.hasStaff) ||
          (isTenants() && !projectDirectoryService.hasTenants) ||
          (isVendors() && !projectDirectoryService.hasVendors)
        "
      >
        <h5 class="m-0 gray font-weight-normal p-5">
          Oops! We didn't find any
          {{ isStaff() ? 'Staff' : isTenants() ? 'Tenants' : 'Suppliers' }} on this Project.
        </h5>
      </div>
      <div
        class="m-portlet__body item pointer p-3 pt-4 pb-4"
        data-test-id="work-order-list-item"
        *ngFor="
          let user of getUsers() | async | projectUsersFilter: searchString | orderByField: fieldToSortBy:sortDirection
        "
      >
        <div class="row pr-0 align-items-center">
          <div class="col d-flex pl-3 pr-3 d-flex align-items-center data-item">
            <app-profile-info
              [user]="user"
              [additionalUserFields]="['title', isStaff() || isVendors() ? 'company_name' : 'department_name']"
              [avatarSize]="35"
              [disableApiCall]="true"
            ></app-profile-info>
          </div>
          <div class="col-3 pl-3 pr-3 data-item">
            <div *ngIf="isVendors() && user.trades" class="d-flex">
              <p class="mr-2 dkblue font-weight-semibold mb-0">
                <span *ngFor="let trade of user.trades; last as isLast">
                  {{ trade?.name }}<span *ngIf="!isLast">,</span>
                </span>
              </p>
            </div>
            <div class="d-flex">
              <p class="m-0 dkblue font-weight-normal">
                <span *ngFor="let role of user.roles; last as isLast">
                  {{ role }}<span class="mr-2" *ngIf="!isLast">,</span>
                </span>
              </p>
            </div>
          </div>
          <div class="col pl-3 pr-3 d-flex data-item">
            <p class="m-0 dkblue font-weight-normal d-block" *ngIf="user.is_login_enabled">Active</p>
            <p class="m-0 dkblue font-weight-normal d-block" *ngIf="isWorkspaceStaff && !user.is_login_enabled">
              <button mat-flat-button (click)="inviteUser(user)" color="success" class="lh-28 pl-3 pr-3">Invite</button>
            </p>
          </div>
          <div class="col pl-3 pr-3 d-flex data-item">
            <p class="m-0 dkblue font-weight-normal d-block">
              <button
                mat-icon-button
                color="accent"
                [routerLink]="['/profile/' + user.id]"
                matTooltip="View Profile"
                matTooltipPosition="above"
              >
                <mat-icon>person</mat-icon>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
