import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService, FileService, HandleErrorService } from 'src/app/services';

import { Conversation, Message } from 'src/app/models';
import { ResourceType } from 'src/app/enums';
import { ServiceResponse, UhatFileReference, User } from 'src/app/types';

/**
 * Events to be referenced from the MessagingSystemService. This is just to keep all event emitters nice and tidy.
 */
class MessagingSystemServiceEvents {
  // TODO don't use the below EventEmitters unless you also unsubscribe from them - see refreshNeeded$ for an example
  public openCreateConversationPanelEvent = new EventEmitter<{
    projectId?: number;
    linkedTaskId?: number;
    subject: string;
    followers: User[];
    toFollowers?: User[];
    message?: string;
    files?: any[];
  }>();

  public conversationCreatedEvent = new EventEmitter<Conversation>();

  public onReplyToMessageEvent = new EventEmitter<{
    message: Message;
    openNewConversation?: boolean;
  }>();

  public onConversationSelectEvent = new EventEmitter<Conversation>();

  public onMessageSelectEvent = new EventEmitter<Message>();

  public updateProjectFilterEvent = new EventEmitter<number>();
}

/** Data structure to manage the handling of cached Message Data */
class MessagesDataStructure {
  private allLoadedMessages = new BehaviorSubject(new Array<Message>());

  private allLoadedMessagesMap = new Map<number, BehaviorSubject<Array<Message>>>();

  // This map is used to hold the cursor value for each conversation id.
  private messagesCursorMap = new Map<number, string>();

  private allMessagesCursor = '';

  /** Add a single message to the data structure. */
  public addMessage(message: Message) {
    if (!message) {
      console.error('No Message Found');
      return;
    }
    this.parseMessage(message);
    const obs = this.getCreateMapEntry(message.conversation_id);
    if (obs.value.find((m) => m.id === message.id) == null) {
      obs.next([message].concat(obs.value));
    }
    if (this.allLoadedMessages.value.find((m) => m.id === message.id) == null) {
      this.allLoadedMessages.next(this.allLoadedMessages.value.concat([message]));
    }
  }

  public addMessages(messages: Message[]) {
    if (!messages) {
      console.error('Messages array null');
      return;
    }
    for (const message of messages) {
      this.addMessage(message);
    }
  }

  public addMessagesToAll(messages: Array<Message>) {
    this.allLoadedMessages.next(this.allLoadedMessages.value.concat(messages));
  }

  /** Set all messages for all conversations. This is a hard refresh. Message data will be set based on the messages array passed in */
  /** NOTE: There may be a BUG with old conversation data not being erased if for some reason a conversation gets deleted and then this is refreshed */
  public setMessages(messages: Message[]) {
    // Update the All Loaded Messages Behaviour Subject
    this.allLoadedMessages.next(messages);

    // Create new mapping of messages with the key (conversationId) to Array of messages
    const messageMap = new Map<number, Message[]>([]);

    // Build message map, storing each message in the correct conversation container
    for (const message of messages) {
      if (!messageMap.has(message.conversation_id)) {
        messageMap[message.conversation_id] = [];
      }
      messageMap[message.conversation_id].push(message);
    }

    // Iterate all of the map keys, updating the map entry with the array values built in previous step
    for (const convIdKey of Array.from(messageMap.keys())) {
      this.updateMessagesForConversation(convIdKey, messageMap[convIdKey]);
    }
  }

  public getAllLoadedMessagesObservable(): Observable<Message[]> {
    return this.allLoadedMessages.asObservable();
  }

  public getConversationMessagesObservable(conversationId: number): Observable<Array<Message>> {
    if (!this.allLoadedMessagesMap.has(conversationId)) {
      this.allLoadedMessagesMap.set(conversationId, new BehaviorSubject<Array<Message>>([]));
    }
    return this.allLoadedMessagesMap.get(conversationId).asObservable();
  }

  public updateMessagesForConversation(conversationId: number, messages: Message[], cursorNext?: string) {
    if (cursorNext) {
      this.updateConversationCursor(conversationId, cursorNext);
    }
    this.getCreateMapEntry(conversationId).next(messages);
  }

  public parseMessage(message: Message) {
    message.read_by_user_ids = JSON.parse(message.read_by_user_ids.toString());
  }

  public updateMessage(message: Message) {
    const found = this.allLoadedMessages.value.find((m) => m.id === message.id);
    if (found) {
      found.read_by_user_ids = message.read_by_user_ids;
      this.allLoadedMessages.next(this.allLoadedMessages.value);
    }
    const messageMapObs = this.getCreateMapEntry(found.conversation_id);
    const foundObs = messageMapObs.value.find((m) => m.id === message.id);
    if (foundObs) {
      foundObs.read_by_user_ids = message.read_by_user_ids;
    }
  }

  /**
   * This will filter out and return all unread messages for a given userId
   * @param userId UserId to look for in the read array
   */
  public getUnreadMessagesForUserId(userId: number): User[] {
    // this is needed due to the way the follower ids are returned (as a string instead of an array)
    this.allLoadedMessages.value.forEach((m) => {
      if (m.conversation_follower_ids && !Array.isArray(m.conversation_follower_ids)) {
        m.conversation_follower_ids = m.conversation_follower_ids
          .replace(/"/g, '')
          .substring(1, m.conversation_follower_ids.length - 1)
          .split(',');
      }
    });
    // since all messages are now returned, checks if the user is either the To user, or a follower
    const users =
      this.allLoadedMessages.value.filter(
        (m) =>
          !m.is_event &&
          m.created_by_id !== userId &&
          Array.isArray(m.read_by_user_ids) &&
          m.read_by_user_ids.find((id) => id === userId) == null &&
          ((m.to_users && m.to_users.find((u) => +u.id === userId)) ||
            (m.conversation_follower_ids && m.conversation_follower_ids.find((u) => +u === +userId)))
      ) || [];
    return users;
  }

  private getCreateMapEntry(conversationId: number): BehaviorSubject<Array<Message>> {
    if (!this.allLoadedMessagesMap.has(conversationId)) {
      this.allLoadedMessagesMap.set(conversationId, new BehaviorSubject<Array<Message>>([]));
    }
    return this.allLoadedMessagesMap.get(conversationId);
  }

  public updateConversationCursor(conversationId: number, next: string) {
    this.messagesCursorMap.set(conversationId, next);
  }

  public getConversationCursor(conversationId: number): string {
    if (!this.messagesCursorMap.get(conversationId)) {
      return '';
    }
    return this.messagesCursorMap.get(conversationId);
  }

  /**
   * Set the cursor for the allMessages data structure. This cursor is a string value that can be used in the message query when wanting
   * to retrieve additional messages
   * @param next String
   */
  public setAllMessagesCursor(next: string) {
    this.allMessagesCursor = next;
  }

  public getAllMessagesCursor(): string {
    return this.allMessagesCursor;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MessagingSystemService {
  public readonly events: MessagingSystemServiceEvents = new MessagingSystemServiceEvents();

  private conversationFields = `id,subject,channel_type_id,followers,channel_project_id,channel_project_code,channel_project_title,task_id,task_code,created_by_id,created_by_first_name,created_by_last_name`;

  private messageFields = `content,conversation_id,conversation_subject,conversation_follower_ids,channel_project_id,channel_id,channel_type_id,to_users,read_by_user_ids,reply_to_id,reply_to_user_id,reply_to_first_name,reply_to_content,is_event,files,created_by_id,created_by_first_name,created_by_last_name,created_datetime`;

  allLoadedConversations = new BehaviorSubject(new Array<Conversation>());

  messagesDataStructure: MessagesDataStructure = new MessagesDataStructure();

  public messagesPanelIsOpen = false;

  private selectedMessage: Message;

  private selectedConversation: Conversation;

  private host: string = environment.serviceHost;

  private conversationURL = `${this.host}/api/v1/conversations`;
  private messageURL = `${this.host}/api/v1/messages`;

  /** Converts the array returned by database (i.e. '[18, 19]') to an array of numbers (i.e. [18, 19]) */
  static convertStringArrayToValidArray(arr: string): number[] {
    return arr
      .substr(1, arr.length - 2)
      .split(',')
      .map((num) => +num);
  }

  get currentSelectedMessage(): Message {
    return this.selectedMessage;
  }

  get currentSelectedConversation(): Conversation {
    return this.selectedConversation;
  }

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    public authService: AuthService,
    private snackbarService: MatSnackBar,
    private fileService: FileService
  ) {
    const userId = authService.getLoggedInUser()?.id;
    this.events.onMessageSelectEvent.subscribe((message) => {
      if (!message.read_by_user_ids.includes(userId)) {
        this.changeMessageStatus(message, true);
      }
    });

    this.events.openCreateConversationPanelEvent.subscribe((conv) => {
      this.messagesPanelIsOpen = true;
    });

    this.events.updateProjectFilterEvent.subscribe((projectId) => {
      this.messagesPanelIsOpen = true;
    });

    this.events.conversationCreatedEvent.subscribe((conv) => {
      this.selectConversationById(conv.id);
    });

    this.events.onReplyToMessageEvent.subscribe((replyObj) => {
      if (replyObj) {
        this.selectMessage(replyObj.message);
      }
    });
  }

  /**
   * Load all conversations for a specific user into our loadedConversations Observable. This will completely replace all previously loaded
   * conversations when .next() is called.
   * @param userId The userId to load conversations for.
   */
  public loadConversationsForUserId(userId: number) {
    this.http
      .get(`${this.conversationURL}?fields=${this.conversationFields}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.conversations;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((conversations) => {
        this.allLoadedConversations.next(conversations);
      });
  }

  /**
   * Load a single conversation into our list of all loaded conversations. If the conversation already exists then it will update the current
   * conversation instance data. If the conversation is not in the list then it will add it and call .next on the observable.
   * @param conversationId The id of the conversation to load.
   */
  public loadConversationById(conversationId: number): Observable<Conversation> {
    return this.http.get(`${this.conversationURL}?filter=id=${conversationId}&fields=${this.conversationFields}`).pipe(
      map((result: ServiceResponse) => {
        const conv = result.data.conversations[0];
        if (this.allLoadedConversations.value.find((c) => c.id === conv.id)) {
          // The conversation that is loaded already exists in the data structure, we just want to update its current value with the
          //    retrieved data.
          const allConversations = this.allLoadedConversations.value;
          allConversations.forEach((c) => {
            if (c.id === conversationId) {
              c = conv;
            }
          });
          this.allLoadedConversations.next(allConversations);
        } else {
          // The conversation that is loaded does not exist in our current data structure, we just concatenate it onto the current list
          this.allLoadedConversations.next(this.allLoadedConversations.value.concat([conv]));
        }
        return conv;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * Load all messages for a conversation. This will update the observable related to the given conversationId with all found messages.
   * @param conversationId The conversationId to load messages for
   * @param limit The maximum amount of messages to load for the given conversation.
   */
  public loadMessagesForConversationId(conversationId: number, limit?: number): Observable<Message[]> {
    return this.http
      .get(
        `${this.messageURL}?filter=conversation_id=${conversationId}&fields=${this.messageFields}&limit=${
          limit || 1000
        }&order=desc`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const messages = result.data.messages;
          this.messagesDataStructure.updateMessagesForConversation(conversationId, messages, result.next);
          return messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public loadMessagesForConversationIdFromCursor(conversationId: number, limit?: number): Observable<Message[]> {
    const cursor = this.messagesDataStructure.getConversationCursor(conversationId) || '';
    return this.http
      .get(
        `${this.messageURL}?cursor=${cursor}&filter=conversation_id=${conversationId}&fields=${
          this.messageFields
        }&limit=${limit || 1000}&order=desc`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const messages = result.data.messages;
          this.messagesDataStructure.addMessages(messages);
          this.messagesDataStructure.updateConversationCursor(conversationId, result.next);
          return messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  /** Load a single message by id */
  public loadMessageById(messageId: number): Observable<Message> {
    return this.http.get(`${this.messageURL}?filter=id=${messageId}&fields=${this.messageFields}`).pipe(
      map((result: ServiceResponse) => {
        const message: Message = result.data.messages[0];
        this.messagesDataStructure.addMessage(message);
        return message;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Load all messages for a user given the userId */
  public loadAllMessagesForUser(userId: number) {
    // grabs all messages where the conversation follower id is that of the user
    this.http
      .get(`${this.messageURL}?fields=${this.messageFields}&limit=10000&order=desc`)
      .pipe(
        map((result: ServiceResponse) => {
          this.messagesDataStructure.setAllMessagesCursor(result.next);
          return result.data.messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((messages) => {
        messages.forEach((message) => {
          message.read_by_user_ids = MessagingSystemService.convertStringArrayToValidArray(message.read_by_user_ids);
        });
        this.messagesDataStructure.setMessages(messages);
      });
  }

  /** Load all initial data for the conversation pane */
  public loadInitConversationData(): Observable<any> {
    // grabs all messages where the conversation follower id is that of the user
    return this.http.get(`${this.messageURL}/messageData`).pipe(
      map((result: any) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public loadAdditionalMessagesForUser(userId: number, numberOfMessages: number) {
    if (!this.messagesDataStructure.getAllMessagesCursor()) {
      return;
    }
    this.http
      .get(
        `${this.messageURL}?cursor=${this.messagesDataStructure.getAllMessagesCursor()}?fields=${
          this.messageFields
        }&limit=${numberOfMessages}&order=desc`
      )
      .pipe(
        map((result: ServiceResponse) => {
          // Set the returned next value to the all messages cursor
          this.messagesDataStructure.setAllMessagesCursor(result.next);
          return result.data.messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((messages) => {
        messages.forEach((message) => {
          message.read_by_user_ids = MessagingSystemService.convertStringArrayToValidArray(message.read_by_user_ids);
        });
        this.messagesDataStructure.addMessagesToAll(messages);
      });
  }

  public getAllLoadedMessagesObservable(): Observable<Message[]> {
    return this.messagesDataStructure.getAllLoadedMessagesObservable();
  }

  public getMessagesObservable(conversationId: number): Observable<Array<Message>> {
    return this.messagesDataStructure.getConversationMessagesObservable(conversationId);
  }

  public getConversationsObservable(): Observable<Conversation[]> {
    return this.allLoadedConversations.asObservable();
  }

  public async selectMessage(message: Message) {
    let newSelectedConvo = this.allLoadedConversations.value.find((c) => c.id === message.conversation_id);
    // If NOT found we want to load that conversation.
    if (!newSelectedConvo) {
      await this.loadConversationById(message.conversation_id).toPromise();
      newSelectedConvo = this.allLoadedConversations.value.find((c) => c.id === message.conversation_id);
      if (!newSelectedConvo) {
        console.error('Could not find selected conversation in All Conversations. It doesnt seem to exist');
        return;
      }
    }
    this.selectedMessage = message;
    this.events.onMessageSelectEvent.emit(message);

    if (newSelectedConvo !== this.selectedConversation) {
      this.loadMessagesForConversationId(message.conversation_id).subscribe((messages) => {
        this.selectedConversation = newSelectedConvo;
        this.events.onConversationSelectEvent.emit(this.selectedConversation);
      });
    }
  }

  public selectConversationById(conversationId: number) {
    const convo = this.allLoadedConversations.value.find((c) => c.id === conversationId);
    if (!convo) {
      const errorSnack = this.snackbarService.open(`Error: Conversation ${conversationId} not found.`, 'Report', {
        duration: 5000,
        panelClass: ['action'],
      });
      errorSnack
        .onAction()
        .subscribe(() => console.warn(`Conversation could not be found or is not loaded, convId: ${conversationId}`));
      return;
    }
    // Open Messages Panel
    this.messagesPanelIsOpen = true;
    // Load Messages For The Conversation
    this.loadMessagesForConversationId(conversationId).subscribe((messages) => {
      if (messages) {
        this.selectMessage(messages[messages.length - 1]); // Select Last Message In Conversation
      }
    });
  }

  public changeMessageStatus(message: Message, read: boolean): Observable<Message> {
    const body = { status: read ? 'read' : 'unread' };
    return this.http.put(`${this.messageURL}/${message.id}/status?fields=${this.messageFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedMessage = result.data.message;
        this.messagesDataStructure.parseMessage(updatedMessage);
        this.messagesDataStructure.updateMessage(updatedMessage);
        message.read_by_user_ids = updatedMessage.read_by_user_ids;
        return updatedMessage;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public addOrRemoveConversationFollower(
    conversationId: number,
    userId: number,
    action?: 'add' | 'remove'
  ): Observable<Conversation> {
    const body = { follower_id: +userId, action: action || 'add' };
    const obs = this.http
      .put(`${this.conversationURL}/${conversationId}/followers?fields=${this.conversationFields}`, body)
      .pipe(
        map((result: ServiceResponse) => {
          const conversation = result.data.conversation;
          const found = this.allLoadedConversations.value.find((c) => c.id === conversation.id);
          if (found) {
            found.followers = conversation.followers;
          }
          this.allLoadedConversations.next(this.allLoadedConversations.value);
          return conversation;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );

    return obs;
  }

  public async addAddOrRemoveConversationFollowers(
    conversationId: number,
    userIds: number[],
    action?: 'add' | 'remove'
  ) {
    let newConversation: Conversation = null;
    for (const userId of userIds) {
      await this.addOrRemoveConversationFollower(conversationId, userId, action)
        .toPromise()
        .then((conversation) => (newConversation = conversation));
    }
    return newConversation;
  }

  public createMessage(
    conversationId: number,
    toUserIds: number[],
    replyToId: number,
    content: string,
    files?: File[],
    isEvent?: boolean
  ): Observable<Message> {
    const messageToSend = {
      conversation_id: conversationId,
      to_user_ids: JSON.stringify(toUserIds),
      reply_to_id: replyToId,
      content,
      is_event: isEvent || 0,
    };
    return this.http.post(`${this.messageURL}?fields=${this.messageFields}`, messageToSend).pipe(
      map(async (result: ServiceResponse) => {
        const message = result.data.message;
        if (files) {
          for (const f of files) {
            await this.fileService
              .createFile(f, message.id, ResourceType.Message)
              .toPromise()
              .then(async (createdFile) => {
                if (message.files) {
                  message.files.push(createdFile);
                } else {
                  message.files = [createdFile];
                }
                await this.fileService.linkFile(createdFile.id, message.id, ResourceType.Message).toPromise();
              });
          }
        }
        this.messagesDataStructure.addMessage(message);
        return message;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // Conversation fields that are required: followers, subject, task_id, channel_id
  // Message fields that are required: to_users, reply_to_id, content
  public createNewConversationWithMessage(
    newConversation: Conversation,
    message: Message,
    files?: File[]
  ): Observable<Conversation> {
    const body = {
      follower_ids: JSON.stringify(newConversation.followers.map((f) => f.id)),
      subject: newConversation.subject,
      task_id: newConversation.task_id,
      channel_id: newConversation.channel_id,
    };
    return this.http.post(`${this.conversationURL}?fields=id`, body).pipe(
      map((result: ServiceResponse) => {
        const conversation = result.data.conversation;
        this.createMessage(conversation.id, message.to_users, message.reply_to_id, message.content, files).subscribe(
          (m) => {
            this.loadConversationById(conversation.id).subscribe((loadedConversation) => {
              if (loadedConversation) {
                this.events.conversationCreatedEvent.emit(loadedConversation);
              }
            });
          }
        );
        return conversation;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public createNewConversation(newConversation: Conversation): Observable<Conversation> {
    const body = {
      follower_ids: JSON.stringify(newConversation.followers.map((f) => f.id)),
      subject: newConversation.subject,
      task_id: newConversation.task_id,
      channel_id: newConversation.channel_id,
    };
    return this.http.post(`${this.conversationURL}?fields=id`, body).pipe(
      map((result: ServiceResponse) => {
        const conversation = result.data.conversation;
        this.loadConversationById(conversation.id).subscribe((loadedConversation) => {
          if (loadedConversation) {
            this.events.conversationCreatedEvent.emit(loadedConversation);
          }
        });
        return conversation;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateConversationFollowers(isFollowing: boolean) {}

  private getMessagesByConversationId(conversationIds: number[]): Observable<Message[]> {
    return this.http
      .get(
        `${this.messageURL}?filter=conversation_id=${conversationIds.join('^')}&fields=${
          this.messageFields
        }&limit=10000`
      ) // &sort=created_datetime&order=desc
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public getConversationCountForTask(taskId: number): Observable<number> {
    return this.http.get(`${this.conversationURL}?fields=id&filter=task_id=${taskId}&limit=1`).pipe(
      map((result: ServiceResponse) => result.count || 0),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getUnreadMessagesCount(): number {
    const currentUser = this.authService.getLoggedInUser();
    if (currentUser && currentUser.id) {
      return this.messagesDataStructure.getUnreadMessagesForUserId(+currentUser.id).length;
    } else {
      return 0;
    }
  }

  public allMessagesHasMoreToLoad(): boolean {
    const cursor = this.messagesDataStructure.getAllMessagesCursor();
    return cursor != null && cursor.length > 0;
  }

  public conversationHasMoreMessagesToLoad(conversationId: number): boolean {
    const cursor = this.messagesDataStructure.getConversationCursor(conversationId);
    return cursor != null && cursor.length > 0;
  }
}
