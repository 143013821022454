import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AgendaItemDialogComponent,
  DatepickerHeaderComponent,
  EditorComponent,
  FileApprovalReviewDialogComponent,
  FileAttachmentDialogComponent,
  FileSelectApprovalReviewDialogComponent,
  MeetingSelectDialogComponent,
  ReminderDialogComponent,
  UserProfilePreviewComponent,
  UserSelectModalComponent,
  WorkOrderDialogComponent,
} from 'src/app/components';
import {
  ApplicationRole,
  FileApprovalStatus,
  ResourceType,
  TaskAccessoryType,
  TaskReviewStatus,
  TaskStatus,
  UserType,
} from 'src/app/enums';
import { FileApproval, TaskActivityFilter, UhatFileBridgeLink } from 'src/app/models';
import {
  ArfsService,
  AuthService,
  FileApprovalService,
  FileService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectFilesService,
  ProjectNotesService,
  ProjectService,
  ProjectTaskService,
  RemindersService,
  TaskActionsService,
  UserSearchService,
  UserService,
} from 'src/app/services';
import {
  FileApprovalItem,
  LinkedWOTask,
  ProjectTenant,
  Quote,
  Task,
  TaskAccessoryData,
  TaskActivity,
  UhatFileReference,
  User,
} from 'src/app/types';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';

@Component({
  selector: 'app-project-task-panel',
  templateUrl: './project-task-panel.component.html',
  styleUrls: ['./project-task-panel.component.scss'],
})
export class ProjectTaskPanelComponent implements OnInit, OnDestroy {
  @Output() convertedToLinkedTask = new EventEmitter<{ task: LinkedWOTask; oldTaskId: number }>();
  @Output() closeDialog = new EventEmitter();
  collapseDescriptionView = true;
  showCollapseDescriptionViewButton = false;
  get fileApprovalStatus() {
    return FileApprovalStatus;
  } // Enum Access For HTML

  get filteredUsers() {
    return this.userSearchService.getFilteredUsers();
  }

  @ViewChild('taskTitleEdit', { static: false }) set taskTitleEditRef(ref: ElementRef) {
    if (ref) {
      // SetTimeout needed to prevent the ExpressionChanged error. This focuses the textbox when it is shown
      setTimeout(() => {
        ref.nativeElement.focus();
      });
    }
  }

  @ViewChild('noteEditor') noteEditor!: EditorComponent;

  public get taskStatus() {
    return TaskStatus;
  }

  public get isTaskLocked(): boolean {
    return this.taskData && +this.taskData.is_locked === 1;
  }

  public get taskDescriptionText(): string {
    return this.taskData?.description ?? '';
  }

  constructor(
    private arfService: ArfsService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private fileService: FileService,
    private pebService: PEBService,
    private noteService: ProjectNotesService,
    // private messagingService: MessagingSystemService,
    private projectFilesService: ProjectFilesService,
    public authService: AuthService,
    public userSearchService: UserSearchService,
    private remindersService: RemindersService,
    private snackbarService: MatSnackBar,
    private userService: UserService,
    private meetingService: MeetingService,
    private approvalService: FileApprovalService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private linkedTaskService: LinkedTaskService,
    private productService: ProductService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectTenantService: ProjectTenantService,
    private router: Router,
    private taskActionsService: TaskActionsService
  ) {}

  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

  public accessoryData: TaskAccessoryData;

  private taskFollowersArray: User[] = [];

  private commentAttachedFiles: UhatFileReference[] = [];

  public taskData: Task = {
    code: 10000000,
    title: 'Loading.. ',
    assigned_user_id: null,
    assigned_user_first_name: 'Loading..',
    assigned_user_last_name: 'Loading..',
  };
  public quoteFields: string[] = [
    'asset_tagged',
    'tag_asset_task_id',
    'arf_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data}',
    'arf_approval_task_id',
    'arf_saved_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data}',
    'arf_saved_approval_task_id',
    'arf_purchase_type',
    'purchase_task_id',
    'arf_revision',
    `company{id,name}`,
    'contact_id',
    `subtotal`,
    `tax`,
    'files',
    `quote_items{id,project_product{id,is_taxable,selected_quote_item_id,tenant_id},total_price}`, // don't use quoteItemFields here or we'll get a loop
  ];

  public quoteItemFields: string[] = [
    'project_product_id',
    'status_id',
    'unit_price',
    'total_price',
    `quote{company{name}}`,
    'is_awarded',
  ];

  public productFields = ['name', 'description', 'unit_price'];

  public projectProductFields: string[] = [
    `name`,
    `description`,
    `quantity`,
    `is_taxable`,
    `is_enabled`,
    `product{${this.productFields.join(',')}}`,
    `quote_items{${this.quoteItemFields.join(',')}}`,
    `selected_quote_item_id`,
    `selected_quote_item{quote_id,total_price}`,
  ];

  public tenantFields = [
    `project_products{${this.projectProductFields.join(',')}}`,
    `type_id`,
    `tenant_name`,
    `shipping_budget`,
    `tax_budget`,
    `quotes{${this.quoteFields}}`,
    'budget_tenant_approval_task{status_id}',
    'budget_approval_task{status_id}',
  ];

  public isAdmin: boolean;
  public quote: Quote;
  public tenant: ProjectTenant;
  public canFinalize = false;
  public taskFollowers$: Observable<User[]> = new Observable<User[]>();

  public files: Observable<Array<UhatFileReference>>;
  public showTitleInput: boolean;
  public isMakingDescription = false;
  public isMakingTitle = false;

  public showUserInput: boolean;

  public myUserId: number;

  public customHeader = DatepickerHeaderComponent;

  public allActivities$: Observable<TaskActivity[]> = new Observable<TaskActivity[]>();

  public activityFilter: TaskActivityFilter = new TaskActivityFilter();

  public hasActivity = false;

  public currentReviewItems: FileApprovalItem[] = [];

  public currentReviewItem: FileApprovalItem;

  public currentUser: User;
  public approvedUser: boolean;
  public approvedReviewUser: boolean;
  public cantUncomplete: boolean;
  public isOpenReview: boolean;
  public reviewIsEditable: boolean;
  public reviewStatus: any;
  public reviewIsComplete: boolean;
  public downloadingContract: boolean;
  public companyContact: User;

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.currentUser = this.authService.getLoggedInUser();

    // Will there ever be a time this equals true?
    if (!this.taskData) {
      this.taskData = this.taskService.currentSelectedTask;
    }

    this.subscriptions.add(
      this.projectService.refreshNeeded$.subscribe(() => {
        this.noteEditor.content.reset();

        (async () => {
          // if task is selected, we don't need this call
          if (this.taskData?.id) {
            this.taskData = await this.projectService
              .getTaskById(this.taskData.id, this.projectService.taskFields.split(','))
              .toPromise();
            await this.refresh();
          }
        })();
      })
    );

    this.subscriptions.add(
      this.taskService.taskSelectedEvent.subscribe((data) => {
        this.noteEditor.content.reset();

        (async () => {
          this.showUserInput = data ? data.focusAssigneeInput : false;
          this.isMakingDescription = false;
          this.showTitleInput = false;
          this.activityFilter.byActivityType = 'all';

          if (data?.task) {
            this.taskData = data.task;
            await this.refresh();
          }
        })();
      })
    );

    this.myUserId = this.currentUser?.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async refresh() {
    this.files = await this.projectFilesService.loadFilesForResourceType(ResourceType.Task, this.taskData.id);
    this.commentAttachedFiles = [];
    this.projectFilesService.loadFilesForResourceType(ResourceType.Task, this.taskData.id);
    this.allActivities$ = this.taskService.getTaskActivityObservable(this.taskData.id);
    this.allActivities$.subscribe((act) => (this.hasActivity = act.length > 0));
    this.taskService.loadTaskActivityLog(this.taskData);
    this.taskFollowers$ = this.taskService.getFollowersObservable(this.taskData.id);
    this.taskFollowers$.subscribe((followers) => (this.taskFollowersArray = followers));
    this.taskService.loadFollowers(this.taskData.id);

    this.accessoryData = this.taskData.accessory_data && JSON.parse(this.taskData.accessory_data);

    this.isOpenReview = this.authService.isOpenReview(this.accessoryData?.type);

    this.reviewIsEditable = this.authService.reviewIsEditable(this.accessoryData?.type);
    this.reviewStatus = this.accessoryData?.reviewChain?.find(
      (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
    );
    this.reviewIsComplete =
      this.accessoryData &&
      this.accessoryData.reviewChain &&
      (!this.reviewStatus || this.reviewStatus.status === TaskReviewStatus.Rejected);
    this.getApprovedUser(this.taskData.assigned_user_id);
    this.refreshApprovals();

    if (this.accessoryData?.type === TaskAccessoryType.Arf) {
      await this.getPurchaseData();
      this.canFinalize = this.setCanFinalize();
    }

    if (this.readyToSendBidContractEmail) {
      const parentBid = await this.projectService.getBidById(this.accessoryData.parentId, ['contact']).toPromise();
      this.companyContact = parentBid.contact;
    }
  }

  get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  get UserType() {
    return UserType;
  }

  get isWorkspaceStaff() {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }
  get isInvoiceReview() {
    return this.accessoryData?.type === TaskAccessoryType.Invoice;
  }

  get readyToSendBidContractEmail() {
    return (
      this.accessoryData?.type === TaskAccessoryType.BidContract &&
      this.reviewIsComplete &&
      this.taskData.status_id === TaskStatus.Open &&
      this.isAdmin
    );
  }

  private getApprovedUser(assignedUserId: number) {
    this.isAdmin = this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService?.currentSelectedProject?.module_id
    );

    this.approvedUser = this.isWorkspaceStaff || this.myUserId === assignedUserId;
    this.approvedReviewUser =
      (!this.accessoryData?.isReviewItem && this.accessoryData?.type !== TaskAccessoryType.Submittals) ||
      (!this.accessoryData?.isReviewItem && this.currentUser.user_type_id === UserType.Staff) ||
      (this.isOpenReview &&
        this.reviewStatus?.status !== TaskReviewStatus.Rejected &&
        this.taskData.status_id !== TaskStatus.Complete) ||
      (!this.isOpenReview &&
        this.reviewIsComplete &&
        (this.currentUser.user_type_id !== UserType.Vendor || !this.reviewStatus) &&
        (assignedUserId === this.currentUser.id || this.isAdmin) &&
        !this.isInvoiceReview);

    this.cantUncomplete = this.accessoryData?.type === TaskAccessoryType.Budget;
  }

  clearNote(noteEditor: EditorComponent) {
    noteEditor.content.setValue('');
  }

  public async downloadContract() {
    for (const file of this.accessoryData.reviewFiles || []) {
      this.downloadingContract = true;
      this.fileService
        .downloadFile(file)
        .pipe(
          map((result) => result),
          catchError((e) => {
            return e;
          })
        )
        .subscribe((result) => {
          saveAs(new Blob([new Uint8Array(result.file.data)]), result.name);
          this.snackbarService.open(`${result.name} has been downloaded`);
          this.downloadingContract = false;
        });
    }
  }

  public disableMakeDescription() {
    this.isMakingDescription = false;
  }

  public enableMakeDescription() {
    this.isMakingDescription = true;
  }

  public disableMakeTitle() {
    this.isMakingTitle = false;
  }

  public enableMakeTitle() {
    this.isMakingTitle = true;
  }

  public followTask() {
    if (this.isFollowing()) {
      return;
    }
    this.taskService.addFollowerToTask(this.taskData.id, this.myUserId).subscribe((data) => {
      this.taskService.updateTask(data.task);
    });
  }

  public unfollowTask() {
    if (!this.isFollowing()) {
      return;
    }
    this.taskService.removeFollowerFromTask(this.taskData.id, this.myUserId).subscribe((data) => {
      this.taskService.updateTask(data.task);
    });
  }

  public isFollowing(): boolean {
    if (!this.taskFollowersArray || this.taskFollowersArray.length <= 0) {
      return false;
    }
    return this.taskFollowersArray.find((follower) => follower.id.toString() === this.myUserId.toString()) != null;
  }

  public hasReview(): boolean {
    return !!this.accessoryData?.reviewChain?.length;
  }

  public async createNote(editor: EditorComponent) {
    if (editor?.content?.value) {
      this.projectService
        .createNote(ResourceType.Task, this.taskData.id, editor.content.value)
        .subscribe((createdNote) => {
          this.taskService.addTaskActivityFromNote(this.taskData.id, createdNote);

          // Updates the notes icon in the task list view
          this.taskService.updateTask({ id: this.taskData.id });
        });

      // reset
      editor.content.reset();
    }
  }

  public removeFile(f: UhatFileReference) {
    this.commentAttachedFiles = this.commentAttachedFiles.filter((file) => file.name !== f.name);
  }

  public editTitle() {
    if (this.isWorkspaceStaff) {
      this.showTitleInput = true;
    }
  }

  public completeTaskClicked() {
    if (this.authService.isStaffOnAnyModule) {
      // Staff user clicked complete
      this.changeTaskStatus(TaskStatus.Complete);
    } else {
      // Non Staff user clicked complete
      this.modalService
        .openConfirmationDialog({
          descriptionText:
            'Thank You! UHAT Staff has been notified of this task being completed. A staff member will review and finalize this task.',
          confirmationButtonText: 'Got it!',
          cancelButtonText: null,
        })
        .subscribe((result) => {
          this.changeTaskStatus(TaskStatus.Pending);
        });
    }
  }
  public convertTaskClicked() {
    this.modalService
      .openRequestTaskReviewDialog({
        task: this.taskData,
        accessoryData: null,
      })
      .subscribe(async ({ task }) => {
        if (task) {
          await this.taskService.updateTask(task);
          this.taskService.selectTaskById(task.id, false).subscribe();
        }
      });
  }

  public async changeTaskStatus(taskStatusId: TaskStatus) {
    this.taskData = await this.taskActionsService.changeTaskStatus(
      taskStatusId,
      this.taskData,
      this.accessoryData?.type
    );
  }

  public async remindTask() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Sending Reminder..');
    const updatedTask = await this.taskService.sendTaskReminder(this.taskData.id).toPromise();
    this.taskData.task_reminder_sender = updatedTask.task_reminder_sender;
    this.taskData.task_reminder_datetime = updatedTask.task_reminder_datetime;
    this.progressIndicatorService.close();
    this.snackbarService.open('Task reminder sent');
    this.refresh();
  }

  public async convertToLinkedWOTask(taskData) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`Loading...`);
    const taskInfo = await this.projectService
      .getTaskById(taskData.id, [
        'milestone_id',
        'display_order_hash',
        'module_id',
        'title',
        'description',
        'assigned_user_id',
        'due_date',
        'files',
        'project{building{id,name},floor{id,name},suite{id,name},department{id,name},landmark,request{short_description,topic_id}}',
      ])
      .toPromise();
    this.progressIndicatorService.close();

    // including topic causes module to be locked, so don't include module
    const workOrder = {
      building: taskInfo.project?.building,
      title: taskInfo.title,
      floor: taskInfo.project?.floor,
      suite: taskInfo.project?.suite,
      department: taskInfo.project?.department,
      landmark: taskInfo.project?.landmark,
      summary: taskInfo.description,
      // topic_id: taskInfo.project?.request?.topic_id,
      assigned_user_id: taskInfo.assigned_user_id,
      requester: this.authService.currentUser,
      due_date: taskInfo.due_date,
      follower_ids: `[${this.projectService.currentSelectedProject?.project_manager_id}]`,
      module_id: taskInfo.module_id,
    };

    this.dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
        data: {
          ...workOrder,
          linkedTaskId: taskData.id,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(async (createdWorkOrder) => {
        if (createdWorkOrder) {
          this.snackbarService
            .open('Work Order Created!', 'View Work Order', { duration: 8000 })
            .onAction()
            .subscribe(() => {
              this.router.navigateByUrl(`/work-orders/${createdWorkOrder.id}`);
            });

          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus(`Creating Linked Task...`);
          const newTask = await this.linkedTaskService.createLinkedWOTask(taskData.id, createdWorkOrder.id).toPromise();
          this.progressIndicatorService.close();
          this.convertedToLinkedTask.emit({ task: newTask, oldTaskId: taskData.id });
        }
      });
  }

  public changeTaskDueDate(dueDate) {
    this.taskData.due_date = dueDate;
    const formattedDueDate = (dueDate && moment(dueDate).format('YYYY-MM-DD')) || null;

    this.projectService.updateTask({ id: this.taskData.id, due_date: formattedDueDate }).subscribe((updatedTask) => {
      this.taskData = updatedTask;
      this.taskService.updateTask(updatedTask);
      this.snackbarService.open('Due Date Updated');
    });
  }

  get isOverdue(): boolean {
    return moment(this.taskData?.due_date).isValid() && moment() > moment(this.taskData?.due_date);
  }

  public deactivateTask() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Task',
        descriptionText: 'Are you sure that you want to remove this Task?',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.projectService.deactivateTask(this.taskData.id).subscribe(() => {
            this.closeTaskPanel();
            this.snackbarService.open('Task Removed');
          });
        }
      });
  }

  openAddReminderDialog() {
    const dialogRef = this.dialog.open(ReminderDialogComponent, {
      data: {
        parent_type_id: ResourceType.Task,
        parent_id: this.taskData.id,
        due_datetime: this.taskData.due_date
          ? moment(this.taskData.due_date).hour(8).format('YYYY-MM-DD HH:mm:ss')
          : null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Creating Reminder..');
        this.remindersService.createReminder(result).subscribe((reminder) => {
          this.snackbarService.open('Reminder created!');
          this.progressIndicatorService.close();
          this.refresh();
        });
        this.progressIndicatorService.close();
      }
    });
  }

  public createMeeting() {
    this.modalService.createMeeting({
      type_id: 1,
      parent_type_id: ResourceType.Task,
      parent_id: this.taskData.id,
    });
  }

  public createMeetingAgendaFromTask(task) {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.Task,
        parent_id: task.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.Task,
              parent_id: task.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
              this.snackbarService.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  public async fireAssigneeSelectionChange(user: User = null, currentTask = this.taskData) {
    if (user) {
      currentTask.assigned_user_id = user.id;
      currentTask.assigned_user_last_name = user.last_name;
      currentTask.assigned_user_first_name = user.first_name;
      currentTask.assigned_user_email = user.email;
    } else {
      currentTask.assigned_user_id = null;
      currentTask.assigned_user_last_name = null;
      currentTask.assigned_user_first_name = null;
      currentTask.assigned_user_email = null;
    }

    this.showUserInput = false;
    // TODO: Cleaner way to update and reload the task data?
    const td = currentTask;
    this.taskService.updateTask(td);
    this.projectService.updateTask({ id: td.id, assigned_user_id: td.assigned_user_id }).subscribe((task) => {
      if (this.taskService.currentSelectedTask?.id === task.id) {
        this.taskData = task;
      }
      if (user) {
        this.snackbarService.open(
          `Task ${task.code} has been assigned to ${task.assigned_user_first_name} ${task.assigned_user_last_name}`
        );
      } else {
        this.snackbarService.open(`Task ${task.code} has been unassigned`);
      }
      this.refreshApprovals();
    });
  }

  public searchBoxValueChange(value: string) {
    this.userSearchService.filterUsers(value);
  }

  public getDueDateText(): string {
    if (!this.taskData.due_date) {
      return 'Set Due Date';
    } else {
      return moment(this.taskData.due_date).format('dddd,  MMM D YYYY').toString();
    }
  }

  public saveTaskTitle(taskTitleEdit: HTMLInputElement) {
    setTimeout(() => {
      if (!this.showTitleInput) {
        return;
      }
      if (this.taskData.title !== taskTitleEdit.value) {
        this.taskData.title = taskTitleEdit.value;
        this.projectService
          .updateTask({ id: this.taskData.id, title: this.taskData.title })
          .subscribe((updatedTask) => {
            if (this.taskData.id === updatedTask.id) {
              this.taskData = updatedTask;
              this.taskService.updateTask(this.taskData); // TODO Move the task API calls from project service into the task service
            }
            this.snackbarService.open('Task Name Updated To ' + this.taskData.title);
          });
      }
      this.showTitleInput = false;
    }, 100);
  }

  public saveTaskDescription(editor: EditorComponent) {
    if (editor?.content?.value) {
      this.projectService
        .updateTask({ id: this.taskData.id, description: editor.content.value })
        .subscribe((updatedTask) => {
          // Only reset the task data if the task did not switch from the time that this function started and this request ended.
          // This can be determined if the ids match.
          if (this.taskData.id === updatedTask.id) {
            this.taskData = updatedTask;
            this.taskService.updateTask(this.taskData); // TODO Move the task API calls from project service into the task service
          }
          this.snackbarService.open('Task Description Updated');
        });
      // reset
      editor.content.reset();
      this.disableMakeDescription();
    }
  }

  public closeTaskPanel() {
    this.taskService.taskSelectedEvent.emit(null);
    this.closeDialog.emit();
  }

  // only allow the description to be edited if a staff user
  public editDescription(editor: EditorComponent) {
    if (this.isWorkspaceStaff && !this.taskData.is_locked && editor?.content) {
      editor.content.setValue(this.taskDescriptionText);
      this.enableMakeDescription();
    }
  }

  public getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  // public startConversation() {
  //   this.messagingService.events.openCreateConversationPanelEvent.emit({
  //     subject: this.taskData.code + ' - ' + this.taskData.title,
  //     projectId: this.projectService.currentSelectedProject.id,
  //     followers: this.taskFollowersArray,
  //     linkedTaskId: this.taskData.id,
  //   });
  // }

  // public changeActivityFilter(filter: 'all' | 'conversations' | 'files') {
  //   this.activityFilter.byActivityType = filter;
  //   this.activityFilter = { ...this.activityFilter };
  // }

  public changeActivityFilter(filter: 'all' | 'files') {
    this.activityFilter.byActivityType = filter;
    this.activityFilter = { ...this.activityFilter };
  }

  public async updateFollowerToTask(): Promise<void> {
    // gather the workspace managers for the project module
    const allWorkspaceManagerIds = (
      await this.userService
        .getUsersByRole(
          ApplicationRole?.WorkspaceManager,
          this.projectService?.currentSelectedProject?.module_id,
          null,
          null,
          false
        )
        .toPromise()
    ).map((workSpaceManager: User) => +workSpaceManager?.id);
    this.dialog
      .open(UserSelectModalComponent, {
        disableClose: true,
        data: {
          title: 'Update Followers',
          preSelectedUsers: this.taskFollowersArray,
          hasProject: true,
          createUser: { title: 'Guest', guestUser: false },
          minimumRemovalPermission:
            this.authService.isAppAdmin ||
            this.authService?.isWorkspaceManager ||
            +this?.taskData?.project_manager_id === +this.authService?.currentUser?.id ||
            allWorkspaceManagerIds?.includes(+this.authService?.currentUser?.id) ||
            this.authService.isWorkspaceManager,
        },
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (deSelectedUsers?.length) {
          for (const deSelected of deSelectedUsers) {
            await this.taskService.removeFollowerFromTask(this.taskData.id, deSelected.id).toPromise();
          }
        }
        if (selectedUsers?.length) {
          for (const selected of selectedUsers) {
            await this.taskService.addFollowerToTask(this.taskData.id, selected.id).toPromise();
          }
        }

        if (deSelectedUsers?.length || selectedUsers?.length) {
          this.taskService.loadTaskActivityLog(this.taskData);
        }
      });
  }

  public openAttachFileDialog() {
    // Since by default we 'allComment', this creates a note, and links the files to the created note, parent (task), and additionalParents (project)
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Task,
          parentResourceId: this.taskData.id,
          allowComment: true,
          additionalParents: [
            new UhatFileBridgeLink(this.projectService.currentSelectedProject.id, ResourceType.Project),
          ],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          const successfullyLinkedFiles = resultData.createdNote.files;
          const unlinkedFiles = resultData.unlinkedFiles;
          const createdNote = resultData.createdNote;
          this.taskService.addTaskActivityFromNote(this.taskData.id, createdNote);

          // Updates the notes icon in the task list view
          this.taskService.updateTask({ id: this.taskData.id });
        }
      });
  }

  private refreshApprovals() {
    this.currentReviewItem = null;
    this.currentReviewItems = [];
    if (this.taskData && Array.isArray(this.taskData.file_approvals)) {
      this.approvalService.getApproval(this.taskData.file_approvals[0].id).subscribe((approval) => {
        if (this.taskData.file_approvals.length <= 0) {
          this.taskData.file_approvals.push(approval);
        } else {
          this.taskData.file_approvals[0] = approval;
        }
        this.currentReviewItems = FileApproval.getNextApprovalItems(approval);
        if (Array.isArray(this.currentReviewItems)) {
          this.currentReviewItem = this.currentReviewItems.find(
            (item) =>
              +item.status === FileApprovalStatus.AwaitingApproval &&
              (this.authService.currentUserIsOfProjectRole(item.role_id, this.taskData.project_id) ||
                this.authService.currentUserIsOfWorkspaceRole(item.role_id, this.taskData.module_id) ||
                this.authService.currentUserIsOfAppRole(item.role_id) ||
                this.isTenantImpersonation(item.role_id))
          );
        }
      });
    }
  }

  // checks whether the current approval requires tenant approval, and if so, whether the current user is allowed to impersonate a tenant
  private isTenantImpersonation(role_id) {
    return this.authService.tenantRoles.includes(+role_id) && this.authService.canImpersonateTenant;
  }

  public canReview(): boolean {
    return this.currentReviewItem != null;
  }

  /**
   * ----------------------------------------------- APPROVALS --------------------------------------------------
   */
  public async openReviewDialog() {
    if (!this.taskData.file_approvals) {
      await this.taskService.loadTask(this.taskData.id).toPromise();
      this.refreshApprovals();
      return;
    }

    let dialogRef;
    const isFileSelectionApprovalType = this.taskData.file_approvals[0].num_files_to_select > 0;
    if (!isFileSelectionApprovalType) {
      dialogRef = this.dialog.open(FileApprovalReviewDialogComponent, {
        width: '460px',
        data: {
          approval: this.taskData.file_approvals[0],
          item: this.currentReviewItem,
        },
      });
    } else {
      dialogRef = this.dialog.open(FileSelectApprovalReviewDialogComponent, {
        width: '460px',
        data: {
          approval: this.taskData.file_approvals[0],
          item: this.currentReviewItem,
          task: this.taskData,
        },
      });
    }
    dialogRef.afterClosed().subscribe(async (result) => {
      if (isFileSelectionApprovalType && result && Array.isArray(result) && result.length > 0) {
        this.snackbarService.open(
          'You have selected a PEB. UHAT staff has been notified and will contact you shortly.',
          null,
          {
            duration: 10000,
          }
        );
      }
      // Refresh the task
      await this.taskService
        .loadTask(this.taskData.id)
        .toPromise()
        .then((task) => (this.taskData.is_locked = true)); // Reload the task
      this.taskService.loadTaskActivityLog(this.taskData); // Refresh Activity Feed For The Task
      this.refreshApprovals(); // Refresh the approvals attached to the task
    });
  }

  public openUploadReviewFilesDialog() {
    console.warn('Unimplemented');
  }

  public isApprovalTask(): boolean {
    return Array.isArray(this.taskData.file_approvals) && this.taskData.file_approvals.length > 0;
  }

  public isTaskApprovalStatus(status: FileApprovalStatus): boolean {
    return this.isApprovalTask() && this.taskData.file_approvals[0].status === status;
  }

  public shouldShowTaskCompletionButtons() {
    const isReviewTask = !this.accessoryData?.isReviewItem || this.taskData.assigned_user_id === this.currentUser.id;
    return (
      (!this.isApprovalTask() ||
        this.isTaskApprovalStatus(FileApprovalStatus.Approved) ||
        this.isTaskApprovalStatus(FileApprovalStatus.Rejected)) &&
      isReviewTask
    );
  }

  public getApprovalStatusText(): string {
    const approval = this.taskData.file_approvals[0];
    // TODO: Figure out why the approval status is not equivalent to the Enum value
    switch (+approval.status) {
      case FileApprovalStatus.AwaitingApproval:
        // const approvalItemRole = FileApproval.getNextApprovalItems(approval)[0];
        // const roleName = this.appRoleService.getRoleName(approvalItemRole.id);
        // const aOrAn = 'aeiou'.includes(roleName[0].toLowerCase()) ? 'an' : 'a';
        // return `Awaiting review from  ${aOrAn} ${roleName}`;
        return `Awaiting Review...`;
      case FileApprovalStatus.Approved:
        return 'Approved';
      case FileApprovalStatus.AwaitingUpload:
        return 'Awaiting Files...';
      case FileApprovalStatus.Rejected:
        return 'Approval rejected';
      default:
        return 'Awaiting Review..';
    }
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public assignUser({ user: newUser, task }) {
    this.showUserInput = true;
    if (newUser !== false) {
      this.fireAssigneeSelectionChange(newUser, task);
    }
    this.showUserInput = false;
  }

  openAssignUserModal() {
    this.showUserInput = true;
    this.modalService.openUserSearchModal(this.taskData).subscribe((selectedUser) => {
      // False is returned if the dialog was closed without a selection. Null is used to un-assign a task
      if (selectedUser === false) {
        return;
      } else {
        // Update the task
        this.fireAssigneeSelectionChange(selectedUser);
      }
      this.showUserInput = false;
    });
  }

  public async finalizePurchase(): Promise<void> {
    const project = await this.projectService.getProjectById(this.taskData?.project_id).toPromise();

    const res = await this.arfService.finalizePurchasePage(this.tenant, project);
    if (res) {
      this.taskData.is_locked = 1;
      this.taskData.status_id = TaskStatus.Complete;
      await this.taskService.updateTask(this.taskData);
    }
  }

  async getPurchaseData(idIsTenant = false) {
    const { quote, tenant } = await this.productService.getPurchaseData(this.taskData, this.accessoryData, idIsTenant);
    this.quote = quote;
    this.tenant = tenant;
  }

  setCanFinalize() {
    const selectedQuotes = this.productService.returnSelectedQuotes(this.tenant) || [];
    let canFinalize = true;
    if (selectedQuotes?.length) {
      selectedQuotes.forEach((quote) => {
        const accessoryData =
          quote.arf_approval_task?.accessory_data && JSON.parse(quote.arf_approval_task.accessory_data);
        if (accessoryData) {
          canFinalize =
            canFinalize &&
            !accessoryData?.reviewChain?.find(
              (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
            );
        } else {
          canFinalize = false;
        }
      });
    }

    return canFinalize;
  }

  async unlockArfTasks() {
    await this.getPurchaseData(true);
    const selectedQuotes = this.productService.returnSelectedQuotes(this.tenant) || [];
    if (
      !this.tenant?.budget_approval_task?.id ||
      this.tenant?.budget_approval_task?.status_id === TaskStatus.Complete ||
      this.tenant?.budget_tenant_approval_task?.status_id === TaskStatus.Complete
    ) {
      for (const quote of selectedQuotes) {
        const taskData = {
          id: quote.arf_approval_task_id,
          is_locked: 0,
        };
        if (taskData.id) {
          await this.projectService.updateTask(taskData).toPromise();
        }
      }
    }
  }
}
