<div id="floor" data-test-id="floor" class="floor">
  <h6 *ngIf="showLabel" class="dkblue font-weight-semibold mb-2">Floor<span *ngIf="required">*</span></h6>
  <mat-form-field appearance="outline" class="full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Floor"
      matInput
      [matAutocomplete]="floor_auto"
      [formControl]="floor"
      (blur)="checkValue()"
    />
    <mat-icon *ngIf="floor?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-error *ngIf="floor?.errors?.required">A floor is required</mat-error>
    <mat-error *ngIf="floor?.errors?.doesNotExist">The floor does not exist</mat-error>
    <mat-autocomplete #floor_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="mapper">
      <mat-option *ngFor="let floor of filteredFloors | async" [value]="floor">
        {{ floor?.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
