<main #company_list id="company_list">
  <div class="d-flex align-items-center mb-3">
    <div>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedView" (selectionChange)="selectionChange($event)">
          <mat-option value="/directory">Accounts</mat-option>
          <mat-option value="/directory/companies">Companies</mat-option>
          <mat-option value="/directory/departments">Departments</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ml-auto">
      <button
        mat-stroked-button
        (click)="showFilters = !showFilters; updatePreferences()"
        color="primary"
        class="mr-3 bg-white lh-40"
        type="button"
        matBadgePosition="before"
        [matBadge]="filtersApplied > 0 && !showFilters ? filtersApplied : null"
        matBadgeColor="warn"
      >
        <i class="material-icons mr-2" style="margin-top: -3px">filter_list</i>
        Filters
      </button>
      <mat-form-field appearance="outline">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <input matInput placeholder="Search Companies" [(ngModel)]="search" (ngModelChange)="paginator.pageIndex = 0" />
        <mat-icon *ngIf="search" class="gray pointer" (click)="search = null; paginator.pageIndex = 0" matSuffix
          >cancel
        </mat-icon>
      </mat-form-field>
      <button
        mat-flat-button
        (click)="addEditCompany()"
        class="ml-3"
        *ngIf="authService.isStaffOnAnyModule"
        color="success"
      >
        + Company
      </button>
    </div>
  </div>
  <div id="filters" *ngIf="showFilters" class="m-portlet mb-3 p-3 full-width bg-shade-ltblue">
    <div class="row d-flex align-items-end">
      <div class="col-2">
        <h6 class="dkblue">Verification Status</h6>
        <mat-form-field appearance="outline" class="full-width">
          <mat-select
            [(ngModel)]="verificationStatuses"
            multiple
            (selectionChange)="updatePreferences(); paginator.pageIndex = 0"
            placeholder="Verification Status"
          >
            <mat-option [value]="1">Pending</mat-option>
            <mat-option [value]="2">UHAT Only</mat-option>
            <mat-option [value]="3">UHAT & 1CALL</mat-option>
            <mat-option [value]="4">Suspended</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-2">
        <h6 class="dkblue">Company Type</h6>
        <mat-form-field appearance="outline" class="full-width">
          <mat-select
            [(ngModel)]="companyTypeFilter"
            multiple
            (selectionChange)="updatePreferences(); paginator.pageIndex = 0"
            placeholder="Company Type"
          >
            <mat-option *ngFor="let ct of companyTypes" [value]="ct.id">{{ ct.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-slide-toggle color="accent" [(ngModel)]="showInactive" (change)="updatePreferences()">
        <span class="font-weight-semibold">Show Inactive Companies</span>
      </mat-slide-toggle>
    </div>
    <div class="col text-right">
      <button mat-button color="primary" class="mb-2" type="button" (click)="showFilters = false; updatePreferences()">
        Hide
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="bg-white ml-3 mb-2"
        type="button"
        (click)="resetFilters(); paginator.pageIndex = 0"
      >
        Clear All
      </button>
    </div>
  </div>
  <div class="m-portlet mb-0">
    <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
      <div class="row align-items-center">
        <div
          class="col-4 p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'name' }"
        >
          <h6 (click)="updateSortByField('name')" class="m-0 text-truncate header-title mr-auto">Name</h6>
          <i *ngIf="fieldToSortBy === 'name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div
          class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'type' }"
        >
          <h6 (click)="updateSortByField('type.name')" class="m-0 text-truncate header-title mr-auto">Type</h6>
          <i *ngIf="fieldToSortBy === 'type.name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <!-- <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Contact Name</h6>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Contact Email</h6>
        </div> -->
        <!-- <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'primary_contact.first_name' }"
        >
          <h6 (click)="updateSortByField('primary_contact.first_name')" class="m-0 text-truncate header-title mr-auto">
            Primary Contact
          </h6>
          <i *ngIf="fieldToSortBy === 'primary_contact.first_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div> -->
        <!-- <div
          class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'signator.first_name' }"
        >
          <h6 (click)="updateSortByField('signator.first_name')" class="m-0 text-truncate header-title mr-auto">
            Signator
          </h6>
          <i *ngIf="fieldToSortBy === 'signator.first_name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div> -->
        <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Verification Status</h6>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
        </div>
      </div>
    </div>
    <div [style.height]="tableHeight + 'px'" style="overflow: auto">
      <div
        class="m-portlet__body item p-3 pt-4 pb-4"
        data-test-id="work-order-list-item"
        *ngFor="
          let company of companies
            | companyPipe: search:verificationStatuses:companyTypeFilter:showInactive:filteredCount
            | orderByField: fieldToSortBy:sortDirection
            | paginator: paginator.pageIndex:paginator.pageSize
        "
      >
        <div class="row pr-0 align-items-center">
          <a class="col-4 pl-3 pr-3 d-flex align-items-center" [routerLink]="'/company/' + company.id">
            <h6 class="primary m-0" [ngClass]="{ gray: company.is_enabled === 0 }">
              {{ company.name }}
            </h6>
          </a>
          <div class="col-1 pl-3 pr-3 d-flex align-items-center">
            {{ company.type.name }}
          </div>
          <!-- <div class="col pl-3 pr-3 d-flex align-items-center">
            {{ company.contact_name }}
          </div>
          <div class="col pl-3 pr-3 d-flex align-items-center">
            {{ company.contact_email }}
          </div> -->
          <!-- <div class="col pl-3 pr-3 d-flex align-items-center">
            {{ company.primary_contact?.first_name }} {{ company.primary_contact?.last_name }}
          </div>
          <div class="col pl-3 pr-3 d-flex align-items-center">
            {{ company.signator?.first_name }} {{ company.signator?.last_name }}
          </div> -->
          <div class="col pl-3 pr-3 d-flex align-items-center">
            <h6 class="m-0 lh-20 mr-3">
              <span
                title="Verification Status"
                class="white px-2 py-1 lh-20 br-10"
                [ngClass]="{
                  'bg-orange': company.verification_status === 1,
                  'bg-red': company.verification_status === 2,
                  'bg-ltblue': company.verification_status === 3,
                  'bg-gray': company.verification_status === 4
                }"
                *ngIf="
                  company.type_id === CompanyType.Supplier &&
                  (company.verification_status === 2 || company.verification_status === 3)
                "
              >
                {{ CompanyVerificationStatus[company.verification_status] }}
              </span>
              <span
                title="Verification Status"
                class="white px-2 py-1 lh-20 br-10"
                [ngClass]="{
                  'bg-orange': company.verification_status === 1,
                  'bg-red': company.verification_status === 2,
                  'bg-ltblue': company.verification_status === 3,
                  'bg-gray': company.verification_status === 4
                }"
                *ngIf="showSupplierVerification(company)"
              >
                {{ CompanyVerificationStatus[company.verification_status] }}
              </span>
            </h6>
          </div>
          <div class="col pl-3 pr-3 d-flex align-items-center">
            <div class="d-flex align-items-center mr-auto">
              <p class="m-0 lh-20 mr-3">
                <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="company.is_enabled == 1">
                  Active
                </span>
                <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="company.is_enabled !== 1">
                  Inactive
                </span>
              </p>
            </div>
            <button
              mat-icon-button
              color="accent"
              matTooltipPosition="above"
              (click)="addEditCompany(company)"
              style="margin-left: auto"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="+filteredCount?.count === 0" class="m-portlet__body">No Companies Found</div>
    </div>
  </div>
</main>
<mat-paginator
  class="bg-transparent dkblue"
  color="primary"
  #paginator
  [length]="filteredCount?.count"
  pageSize="25"
  (page)="pageChange($event)"
  showFirstLastButtons
>
</mat-paginator>
