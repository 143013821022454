import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { BugReportService, ProgressIndicatorService } from 'src/app/services';
import { BugReport } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(
    private snackbar: MatSnackBar,
    private errorService: BugReportService,
    private progressIndicatorModal: ProgressIndicatorService
  ) {}

  handleError(error: HttpErrorResponse, allowedErrors: number[] = [401, 409]) {
    if (!allowedErrors.includes(401)) {
      allowedErrors.push(401);
    }
    if (!allowedErrors.includes(409)) {
      allowedErrors.push(409);
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      if (this.progressIndicatorModal) {
        this.progressIndicatorModal.updateStatus('An error has occurred');
      }
    } else {
      if (allowedErrors.indexOf(error.status) === -1) {
        if (this.progressIndicatorModal) {
          this.progressIndicatorModal.close();
        }

        const errorSnack = this.snackbar.open(
          error.status === 403 ? `Access denied` : `An error occurred`,
          error.status === 403 ? null : 'Report',
          { duration: 10000, panelClass: ['action'] }
        );
        // IMPORTANT - this snackbar option will be anonymous if clicked by an unauthenticated user (ie: on the login page or similar)
        errorSnack.onAction().subscribe(async () => {
          const errorString = `Status: ${error.status}\nReason: ${error.error.error}\nMessage: ${error.error.message}`;
          const reportToSend: BugReport = {
            subject: 'Snackbar Report',
            inquiry_type_id: 4, // id 4 = snackbar report
            should_contact: 1,
            message: errorString,
            route: `${error?.error?.reqMethod} ${error.url}`,
            follower_ids: '[]',
          };
          // get the token directly to avoid circular dependencies
          await this.errorService.logError(reportToSend, !!localStorage.getItem('auth_token')).toPromise();
          this.snackbar.open(`This error has been reported and will be fixed as soon as possible!`);
        });
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      } else {
        return [];
      }
    }
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return throwError(error);
  }
}
