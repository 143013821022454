import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Pipe({
  name: 'projectDashboardFilter',
})
export class ProjectDashboardFilterPipe implements PipeTransform {
  transform(projects: ProjectConstruction[], filter: ProjectDashboardFilter): any {
    // filter by status
    projects = projects.filter((project) => project.status_id === filter.statusId);

    // sort by created date
    projects.sort((a, b) => moment(a.created_datetime).diff(moment(b.created_datetime)));

    return projects;
  }
}

export class ProjectDashboardFilter {
  // Relates to Active/ On Hold
  public statusId = 1;
}
