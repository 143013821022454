import { Pipe, PipeTransform } from '@angular/core';

import { orderBy } from 'lodash';
import * as moment from 'moment';

import { TaskActivityFilter } from 'src/app/models';
import { ActivityType, EventType } from 'src/app/enums';
import { TaskActivity } from 'src/app/types';

@Pipe({
  name: 'taskActivityFilter',
})
export class TaskActivityFilterPipe implements PipeTransform {
  transform(activities: TaskActivity[], filter: TaskActivityFilter): any {
    // if (filter.byActivityType === 'conversations') {
    //   activities = activities.filter((activity) => activity.type === ActivityType.Conversation);
    // }
    if (filter.byActivityType === 'files') {
      activities = activities.filter((activity) => activity.noteFiles && activity.noteFiles.length > 0);
    }

    activities.map((activity) => {
      if (activity.eventType === EventType.NOTIFICATION) {
        activity.created_datetime = moment(
          activity.created_datetime.add('1', 'seconds').toDate(),
          'YYYY-MM-DDTHH:mm:ss.SSSSZ'
        );
      }
    });

    if (filter.orderDescending) {
      return orderBy(activities, ['created_datetime'], ['desc']);
    } else {
      return orderBy(activities, ['created_datetime'], ['asc']);
    }
  }
}
