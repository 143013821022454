import { Pipe, PipeTransform } from '@angular/core';
import { DispatchFilter, DispatchItem } from '../types';
import * as moment from 'moment';
import { DispatchType } from '../enums';

@Pipe({
  name: 'dispatchFilter',
})
export class DispatchFilterPipe implements PipeTransform {
  transform(items: DispatchItem[], filter: DispatchFilter): DispatchItem[] {
    if (filter?.searchTerm) {
      const searchTerm = filter?.searchTerm?.toLowerCase();

      items = items.filter((dispatchItem: DispatchItem) => {
        return (
          dispatchItem?.title?.toLowerCase()?.includes(searchTerm) ||
          dispatchItem?.topic?.name.toLowerCase().includes(searchTerm) ||
          dispatchItem?.topic_category?.name.toLowerCase().includes(searchTerm) ||
          dispatchItem?.topic_group?.name.toLowerCase().includes(searchTerm) ||
          dispatchItem?.code?.toLowerCase()?.includes(searchTerm) ||
          dispatchItem?.floor_code?.toLowerCase()?.includes(searchTerm) ||
          `${dispatchItem.building?.code}-${dispatchItem.floor_code}`.toLowerCase()?.includes(searchTerm) ||
          `${dispatchItem.building?.code}-${dispatchItem.floor_name}`.toLowerCase()?.includes(searchTerm) ||
          `${dispatchItem?.requester?.first_name || ''} ${dispatchItem?.requester?.last_name || ''}`
            .trim()
            .toLowerCase()
            .includes(searchTerm) ||
          dispatchItem?.building_code?.toLowerCase()?.includes(searchTerm) ||
          `${dispatchItem?.created_by?.first_name || ''} ${dispatchItem?.created_by?.last_name || ''}`
            .trim()
            .toLowerCase()
            .includes(searchTerm) ||
          `${dispatchItem?.assigned_user?.first_name || ''} ${dispatchItem?.assigned_user?.last_name || ''}`
            .trim()
            .toLowerCase()
            .includes(searchTerm)
        );
      });
    }

    // Filters by date an item was submitted today, this week, this month
    if (filter?.submitted && items) {
      if (filter.submitted === 'day') {
        items = items.filter((dispatch) => moment().isSame(dispatch.created_datetime, 'day'));
      } else if (filter.submitted === 'week') {
        const startOfWeek = moment().startOf('isoWeek');
        items = items.filter((dispatch) => startOfWeek.isBefore(dispatch.created_datetime, 'hour'));
      } else if (filter.submitted === 'month') {
        const startOfMonth = moment().startOf('month');
        items = items.filter((dispatch) => startOfMonth.isBefore(dispatch.created_datetime, 'hour'));
      }
    }

    // Filters by item type Draft, Request, Work Order
    if (filter?.type || filter?.type === 0) {
      if (filter.type === DispatchType.Draft) {
        items = items.filter((item) => item.type === DispatchType.Draft);
      } else if (filter.type === DispatchType.ProjectRequest) {
        items = items.filter((item) => item.type === DispatchType.ProjectRequest);
      } else if (filter.type === DispatchType.WorkOrder || filter.type === DispatchType.NoCommsThreeDays) {
        items = items.filter((item) => item.type === DispatchType.WorkOrder);
      } else if (filter.type === DispatchType.RequestAndWorkOrder) {
        items = items.filter(
          (item) => item.type === DispatchType.WorkOrder || item.type === DispatchType.ProjectRequest
        );
      }
    }

    if (filter?.type && filter.status_id) {
      items = items.filter((item) => item.status_id === filter.status_id);
    }

    if (filter?.workspace_id) {
      items = items.filter((item) => item.workspace?.id === filter.workspace_id);
    }

    if (filter.type === DispatchType.NoCommsThreeDays) {
      const currentDay = moment().day();
      const totalDays = [5, 6, 7].includes(currentDay) ? (currentDay !== 7 ? 3 : 4) : 5;
      const threeWorkDaysAgo = moment().subtract(totalDays, 'days').startOf('day');
      items = items.filter(
        (item) =>
          (item.update && moment(threeWorkDaysAgo).isAfter(item.update.created_datetime)) ||
          (!item.update && moment(threeWorkDaysAgo).isAfter(item.created_datetime))
      );
    }

    return items;
  }
}
