import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { UserService } from '../services';
import { APIFilter } from '../types';

@Injectable({ providedIn: 'root' })
export class UserEmailIsAvailableValidator implements AsyncValidator {
  constructor(private userService: UserService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value && !control.errors?.email && !control.errors?.pattern) {
      const emailFilter: APIFilter[] = [{ type: 'field', field: 'email', value: control.value, match: 'any' }];
      return this.userService.searchUsers(emailFilter, ['email']).pipe(
        debounceTime(500),
        catchError(() => of(null)),
        map((userEmails) => {
          if (userEmails?.length) {
            control.markAsTouched();
            return { unavailable: true };
          } else {
            return null;
          }
        })
      );
    } else {
      return of(null as unknown as ValidationErrors);
    }
  }
}
