import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, AuthService, HandleErrorService } from 'src/app/services';

import { APIFilter, Project, ServiceResponse } from 'src/app/types';
import {
  ProjectSubmittal,
  SubmittalCategory,
  SubmittalItem,
  SubmittalSpec,
} from 'src/app/workspaces/construction/types';
import { uniqBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SubmittalsService {
  host: string = environment.serviceHost;
  submittalSpecsUrl = `${this.host}/api/v1/submittal-specs`;
  submittalItemsUrl = `${this.host}/api/v1/submittal-items`;
  projectSubmittalsUrl = `${this.host}/api/v1/project-submittals`;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getSubmittalItems(fields: string[], apiFilters?: APIFilter[]): Observable<SubmittalItem[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.submittalItemsUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.submittal_items;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getSubmittalSpecs(fields: string[], apiFilters?: APIFilter[]): Observable<SubmittalSpec[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.submittalSpecsUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.submittal_specs;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectSubmittals(fields: string[], apiFilters?: APIFilter[]): Observable<ProjectSubmittal[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectSubmittalsUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.project_submittals;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  addProjectSubmittal(projectSubmittal: any, fields: string[]): Observable<ProjectSubmittal> {
    return this.http.post(`${this.projectSubmittalsUrl}?fields=${fields.join(',')}`, projectSubmittal).pipe(
      map((result: ServiceResponse) => {
        const projectSubmittalToReturn = result.data['Project Submittal'];
        return projectSubmittalToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectSubmittal(projectSubmittalId, submittalItem, fields: string[]): Observable<ProjectSubmittal> {
    return this.http
      .put(`${this.projectSubmittalsUrl}/${projectSubmittalId}?fields=${fields.join(',')}`, submittalItem)
      .pipe(
        map((result: ServiceResponse) => {
          const projectSubmittalToReturn = result.data['Project Submittal'];
          return projectSubmittalToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateSubmittalItem(submittalItemId, submittalItem, fields: string[]): Observable<ProjectSubmittal> {
    return this.http.put(`${this.submittalItemsUrl}/${submittalItemId}?fields=${fields.join(',')}`, submittalItem).pipe(
      map((result: ServiceResponse) => {
        const submittalItemToReturn = result.data;
        return submittalItemToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  removeProjectSubmittal(projectSubmittalId): Observable<void> {
    return this.http.delete(`${this.projectSubmittalsUrl}/${projectSubmittalId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
