<div id="punchlist" data-test-id="punchlist" class="punchlist container-fluid p-4">
  <div class="row">
    <div class="col">
      <div class="row component-subheader">
        <div class="col">
          <h4 class="dkblue font-weight-semibold">
            Punch List &nbsp;
            <small class="ltblue">
              {{ (punchlists$ | async)?.length || 0 }}
            </small>
          </h4>
        </div>
        <div class="col-auto text-right">
          <button class="ml-3" mat-button color="accent" (click)="downloadFullPunchlistPDF()">
            <mat-icon> get_app </mat-icon>
            <span> Download PDF </span>
            <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="downloadingPunchlist"></i>
          </button>
          <button class="ml-3" (click)="openPunchlistInputModal()" *ngIf="canAddTask" mat-flat-button color="success">
            + Task
          </button>
        </div>
      </div>
      <span>
        <div class="portlet-table-header pl-3 pr-3">
          <div class="row">
            <div class="col-auto text-center" style="width: 60px">#</div>
            <div class="col">
              <label class="main-label"> Punch List Task </label>
            </div>
            <div class="col-3">
              <label class="main-label"> Trade </label>
            </div>
            <div class="col-2">
              <label class="main-label"> Supplier Task </label>
            </div>
            <div class="col-auto" style="width: 90px"></div>
          </div>
        </div>
        <div class="m-portlet" m-portlet="true">
          <div
            *ngFor="let punchlist of punchlists$ | async; let i = index"
            [attr.data-index]="i"
            class="p-3 item ease m-portlet__body cursor-pointer"
            (click)="openPunchlistTaskDialog(punchlist, true)"
          >
            <div class="row align-items-center">
              <div class="col-auto pl-4 text-center" style="width: 60px">
                <h6 class="m-0 dkblue font-weight-semibold">
                  {{ punchlist.local_index }}
                </h6>
              </div>
              <div class="col">
                <h6 class="dkblue font-weight-semibold m-0 cursor-pointer">
                  {{ punchlist.description || 'Task Description' }}
                  <!-- <small class="gray ml-2">
                      {{punchlist.code}}
                    </small> -->
                </h6>
                <p class="ltblue m-0">
                  {{ punchlist.location || 'Location' }}
                </p>
              </div>
              <div class="col-3">
                <p class="ltblue d-inline font-weight-semibold m-0">
                  {{ punchlist.trade_name || 'Unassigned' }}
                  {{
                    punchlist.trade_name && punchlist.bid_package?.awarded_company_name
                      ? '- ' + punchlist.bid_package.awarded_company_name
                      : ''
                  }}
                </p>
              </div>
              <!--      <div class="col-2">-->
              <!--        <p *ngIf="punchlist.files" class="gray d-inline font-weight-normal m-0">-->
              <!--          {{punchlist.files ? punchlist.files.length : '0'}} {{punchlist.files.length != 1 ? 'Files' : 'File'}}-->
              <!--        </p>-->
              <!--        <p *ngIf="!punchlist.files" class="gray d-inline font-weight-normal m-0">-->
              <!--          0 Files-->
              <!--        </p>-->
              <!--      </div>-->
              <div class="col-2">
                <app-open-task-button
                  *ngIf="punchlist.approval_task_id"
                  [taskId]="punchlist.approval_task_id"
                  lineHeight="28"
                  (click)="$event.stopPropagation()"
                ></app-open-task-button>
              </div>
              <div class="col-auto text-right" style="width: 90px">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="pl_main_menu"
                  class="ltgray"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #pl_main_menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="openPunchlistTaskDialog(punchlist)">
                    <mat-icon>edit</mat-icon>
                    <span> Edit Task </span>
                  </button>
                  <button mat-menu-item (click)="removeTask(punchlist)">
                    <mat-icon>delete</mat-icon>
                    <span> Remove Task </span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div style="margin-left: 4.5em; margin-top: 0.5em">
              <div class="row">
                <div class="col">
                  <p class="dkgray m-0">
                    <span *ngIf="punchlist.additional_notes" [innerHTML]="punchlist.additional_notes"></span>
                    <span
                      *ngIf="!punchlist.additional_notes"
                      style="user-select: none; font-style: italic; font-weight: lighter; opacity: 0.7"
                      >No Additional Notes</span
                    >
                  </p>
                </div>
              </div>
              <!--              <div *ngIf="punchlist.files" class="row">-->
              <!--                <div class="col">-->
              <!--                  <div class="mt-3 mb-1">-->
              <!--                    <mat-chip-list>-->
              <!--                      <app-file-chip *ngFor="let file of punchlist.files" [file]="file"></app-file-chip>-->
              <!--                    </mat-chip-list>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </span>
    </div>
    <div class="col-auto" style="width: 400px">
      <div class="mb-5 mt-4">
        <div class="row align-items-center mb-2">
          <div class="col text-center">
            <h5 class="m-0 font-weight-bold dkblue">
              {{ getStatus }}
            </h5>
          </div>
        </div>
      </div>
      <h6 class="dkblue m-0 mb-2 font-weight-bold" *ngIf="punchlistArray?.length">Task Status</h6>
      <div class="m-portlet m-0 mb-5" *ngIf="punchlistArray?.length">
        <div class="m-portlet__body item p-3">
          <div class="row">
            <div class="col-8">
              <h6 class="m-0 dkblue font-weight-semibold">Unassigned</h6>
            </div>
            <div class="col text-right">
              <h6 class="m-0 dkblue font-weight-semibold">
                {{ reviewNotStartedCount }} / {{ punchlistArray?.length || 0 }}
              </h6>
            </div>
          </div>
        </div>
        <div class="m-portlet__body item p-3">
          <div class="row">
            <div class="col-8">
              <h6 class="m-0 dkblue font-weight-semibold">Supplier In Progress</h6>
            </div>
            <div class="col text-right">
              <h6 class="m-0 dkblue font-weight-semibold">{{ inReviewCount }} / {{ punchlistArray?.length || 0 }}</h6>
            </div>
          </div>
        </div>
        <div class="m-portlet__body item p-3">
          <div class="row">
            <div class="col-8">
              <h6 class="m-0 dkblue font-weight-semibold">Pending UHAT Confirmation</h6>
            </div>
            <div class="col text-right">
              <h6 class="m-0 dkblue font-weight-semibold">
                {{ reviewPendingConfirmationCount }} / {{ punchlistArray?.length || 0 }}
              </h6>
            </div>
          </div>
        </div>
        <div class="m-portlet__body item p-3">
          <div class="row">
            <div class="col-8">
              <h6 class="m-0 dkblue font-weight-semibold">Complete</h6>
            </div>
            <div class="col text-right">
              <h6 class="m-0 dkblue font-weight-semibold">
                {{ reviewCompletedCount }} / {{ punchlistArray?.length || 0 }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="row align-items-center mb-2">
          <div class="col">
            <h6 class="dkblue m-0 font-weight-bold">Staff Approval</h6>
          </div>
          <div class="col-auto">
            <button
              mat-button
              style="line-height: 24px"
              class="pl-2 pr-2"
              color="accent"
              type="button"
              (click)="resetReview(project, true)"
              *ngIf="canReset"
            >
              Reset
            </button>
          </div>
        </div>
        <div class="m-portlet m-0 br-10 mb-5">
          <div class="m-portlet__body p-3 item">
            <div class="row align-items-center">
              <div class="col">
                <mat-icon
                  *ngIf="project.punchlist_approval_task_status_id !== TaskStatus.Open"
                  class="mr-3 align-middle"
                  [ngStyle]="{ color: getIcon(project.punchlist_approval_task_status_id).color }"
                >
                  {{ getIcon(project.punchlist_approval_task_status_id).icon }}
                </mat-icon>
                <app-profile-thumbnail
                  *ngIf="project.punchlist_approval_task_status_id === TaskStatus.Open && currentInternalReviewer"
                  [noBorder]="true"
                  [matTooltip]="currentInternalReviewer.first_name + ' ' + currentInternalReviewer.last_name"
                  class="mr-3 d-inline-block align-middle"
                  [height]="26"
                  [width]="26"
                  [userId]="currentInternalReviewer.id"
                ></app-profile-thumbnail>
                <h6 class="m-0 primary d-inline-block align-middle pointer">Internal Review</h6>
              </div>
              <div class="col-auto text-right">
                <span class="inner-button ml-3">
                  <button
                    mat-button
                    color="accent"
                    class="pl-3 pr-3"
                    type="button"
                    style="line-height: 28px"
                    (click)="viewTask(project.punchlist_approval_task_id || project.saved_punchlist_approval_task_id)"
                    *ngIf="
                      project.punchlist_approval_task_id ||
                      (!userIsReviewAdmin && project.saved_punchlist_approval_task_id)
                    "
                  >
                    View
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    type="button"
                    style="line-height: 28px"
                    class="pl-3 pr-3"
                    (click)="submitInternalApproval()"
                    *ngIf="canStartInternalApproval && !project.saved_punchlist_approval_task_id && userIsReviewAdmin"
                  >
                    Start
                  </button>
                  <button
                    mat-button
                    color="success"
                    type="button"
                    style="line-height: 28px"
                    class="pl-3 pr-3"
                    (click)="resetReview(project, false, true)"
                    *ngIf="canDoInternalNewRevision && userIsReviewAdmin"
                  >
                    + Revision
                  </button>
                  <button
                    mat-flat-button
                    color="success"
                    type="button"
                    class="pl-3 pr-3"
                    style="line-height: 28px"
                    (click)="restartReviewProcess(project, true)"
                    *ngIf="canStartInternalApproval && project.saved_punchlist_approval_task_id && userIsReviewAdmin"
                  >
                    Submit Revision
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasTenants">
          <div *ngFor="let tenant of getTenants; let i = index" class="row mb-5">
            <div class="col">
              <div class="row align-items-center mb-2">
                <div class="col">
                  <h6 class="dkblue m-0 font-weight-bold">
                    {{ tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name }}
                  </h6>
                </div>
                <div class="col-auto">
                  <button mat-button (click)="getPdfPackage(tenant)" color="accent" class="pl-2 pr-2 lh-24">
                    <i class="material-icons" style="font-size: 18px; height: 18px; width: 18px">get_app</i>
                    PL Package
                  </button>
                </div>
              </div>
              <div class="m-portlet m-0 br-10">
                <div class="m-portlet__body p-3 item">
                  <div class="row align-items-center">
                    <div class="col">
                      <app-profile-thumbnail
                        *ngIf="
                          tenant.punchlist_approval_task?.assigned_user?.id &&
                          tenant.punchlist_approval_task?.status_id === TaskStatus.Open
                        "
                        [noBorder]="true"
                        [matTooltip]="
                          tenant.punchlist_approval_task?.assigned_user?.first_name +
                          ' ' +
                          tenant.punchlist_approval_task?.assigned_user?.last_name
                        "
                        class="mr-3 d-inline-block align-middle"
                        [height]="26"
                        [width]="26"
                        [userId]="tenant.punchlist_approval_task?.assigned_user?.id"
                      ></app-profile-thumbnail>
                      <mat-icon
                        *ngIf="tenant.punchlist_approval_task?.status_id !== TaskStatus.Open"
                        class="mr-3 align-middle"
                        [ngStyle]="{
                          color: getIcon(tenant.punchlist_approval_task?.status_id).color
                        }"
                      >
                        {{ getIcon(tenant.punchlist_approval_task?.status_id).icon }}
                      </mat-icon>
                      <h6 class="m-0 primary d-inline-block align-middle pointer">Tenant Approval</h6>
                    </div>
                    <div class="col-auto text-right">
                      <span class="inner-button ml-3">
                        <button
                          mat-button
                          color="accent"
                          class="pl-3 pr-3"
                          type="button"
                          style="line-height: 28px"
                          (click)="
                            viewTask(tenant.punchlist_approval_task_id || tenant.saved_punchlist_approval_task_id)
                          "
                          *ngIf="
                            tenant.punchlist_approval_task_id ||
                            (!userIsReviewAdmin && tenant.saved_punchlist_approval_task_id)
                          "
                        >
                          View
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          style="line-height: 28px"
                          class="pl-3 pr-3"
                          (click)="submitTenantApproval(tenant)"
                          *ngIf="canStartTenantApproval(tenant) && userIsReviewAdmin"
                        >
                          Start
                        </button>
                        <button
                          mat-button
                          color="success"
                          type="button"
                          style="line-height: 28px"
                          class="pl-3 pr-3"
                          (click)="resetReview(tenant, false, false)"
                          *ngIf="
                            !isFinalized &&
                            isInternalComplete &&
                            tenant.punchlist_approval_task_id != null &&
                            userIsReviewAdmin
                          "
                        >
                          + Revision
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="restartReviewProcess(tenant, false)"
                          *ngIf="
                            !isFinalized &&
                            isInternalComplete &&
                            tenant.saved_punchlist_approval_task_id &&
                            userIsReviewAdmin
                          "
                        >
                          Submit Revision
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="m-portlet__body p-3 item">
                  <div class="row align-items-center">
                    <div class="col">
                      <app-profile-thumbnail
                        *ngIf="
                          tenant.punchlist_final_approval_task?.assigned_user?.id &&
                          tenant.punchlist_final_approval_task?.status_id === TaskStatus.Open
                        "
                        [noBorder]="true"
                        [matTooltip]="
                          tenant.punchlist_final_approval_task?.assigned_user?.first_name +
                          ' ' +
                          tenant.punchlist_final_approval_task?.assigned_user?.last_name
                        "
                        class="mr-3 d-inline-block align-middle"
                        [height]="26"
                        [width]="26"
                        [userId]="tenant.punchlist_final_approval_task?.assigned_user?.id"
                      ></app-profile-thumbnail>
                      <mat-icon
                        *ngIf="tenant.punchlist_final_approval_task?.status_id !== TaskStatus.Open"
                        class="mr-3 align-middle"
                        [ngStyle]="{
                          color: getIcon(tenant.punchlist_final_approval_task?.status_id).color
                        }"
                      >
                        {{ getIcon(tenant.punchlist_final_approval_task?.status_id).icon }}
                      </mat-icon>
                      <h6 class="m-0 primary d-inline-block align-middle pointer">Tenant Completion</h6>
                    </div>
                    <div class="col-auto text-right">
                      <span class="inner-button ml-3">
                        <button
                          mat-button
                          color="accent"
                          class="pl-3 pr-3"
                          type="button"
                          style="line-height: 28px"
                          (click)="
                            viewTask(
                              tenant.punchlist_final_approval_task_id || tenant.saved_punchlist_final_approval_task_id
                            )
                          "
                          *ngIf="
                            tenant.punchlist_final_approval_task_id || tenant.saved_punchlist_final_approval_task_id
                          "
                        >
                          View
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          style="line-height: 28px"
                          class="pl-3 pr-3"
                          (click)="submitTenantApproval(tenant, true)"
                          *ngIf="
                            !isFinalized &&
                            allVendorTasksComplete &&
                            this.project.punchlist_approval_task_status_id === TaskStatus.Complete &&
                            tenant.punchlist_approval_task?.status_id === TaskStatus.Complete &&
                            !tenant.punchlist_final_approval_task_id &&
                            !tenant.saved_punchlist_final_approval_task_id
                          "
                        >
                          Start
                        </button>
                        <button
                          mat-button
                          color="success"
                          type="button"
                          style="line-height: 28px"
                          class="pl-3 pr-3"
                          (click)="resetReview(tenant, false, false, true)"
                          *ngIf="
                            !isFinalized &&
                            isInternalComplete &&
                            tenant.punchlist_final_approval_task_id &&
                            userIsReviewAdmin
                          "
                        >
                          + Revision
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="restartReviewProcess(tenant, false, true)"
                          *ngIf="
                            !isFinalized &&
                            isInternalComplete &&
                            tenant.saved_punchlist_final_approval_task_id &&
                            userIsReviewAdmin
                          "
                        >
                          Submit Revision
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="m-portlet__body p-3 item">
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="m-0 primary d-inline-block align-middle pointer">Cover Letter</h6>
                    </div>
                    <div class="col-auto text-right">
                      <!-- <button
                            mat-icon-button
                            color="accent"
                            class="ml-3"
                            matTooltip="Download"
                            matTooltipPosition="above"
                            (click)="exportCoverLetter(tenant)"
                            [matTooltipShowDelay]="300"
                            *ngIf="tenant.punchlist_cover_letter_text"
                          >
                            <mat-icon>get_app</mat-icon>
                          </button> -->
                      <button
                        mat-flat-button
                        color="success"
                        *ngIf="!tenant.punchlist_cover_letter_text"
                        (click)="openCoverLetter(tenant)"
                        type="button"
                        style="line-height: 28px"
                        class="pl-3 pr-3"
                      >
                        Create
                      </button>
                      <button
                        mat-button
                        color="accent"
                        *ngIf="tenant.punchlist_cover_letter_text"
                        (click)="openCoverLetter(tenant)"
                        type="button"
                        style="line-height: 28px"
                        class="pl-3 pr-3"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div class="m-portlet__body p-3 item">
                  <div class="d-flex align-items-center">
                    <mat-slide-toggle
                      color="accent"
                      class="mr-3"
                      *ngIf="!tenant.schedule_5_file_id"
                      [checked]="!!tenant?.schedule_5_is_required"
                      (change)="updateScheduleStatus(tenant, $event)"
                      [disabled]="
                        !!tenant.punchlist_final_approval_task_id ||
                        !!tenant.saved_punchlist_final_approval_task_id ||
                        !!tenant?.schedule_5_file_id
                      "
                    >
                    </mat-slide-toggle>
                    <h6 class="m-0 primary d-inline-block align-middle font-weight-semibold pointer mr-auto">
                      Schedule 5
                    </h6>
                    <button
                      *ngIf="!tenant.schedule_5_file_id && tenant?.schedule_5_is_required"
                      mat-flat-button
                      color="success"
                      type="button"
                      style="line-height: 28px"
                      class="pl-3 pr-3"
                      (click)="scheduleFiveAttachmentDialog(tenant)"
                    >
                      Add
                    </button>
                    <mat-chip-list *ngIf="tenant.schedule_5_file_id" class="ml-3">
                      <app-file-chip
                        class="ml-auto"
                        [file]="{
                          name: tenant.schedule_5_file_name,
                          id: tenant.schedule_5_file_id
                        }"
                        [removable]="!tenant.punchlist_approval_task?.status_id"
                        (removeEvent)="removeScheduleFiveFromTenant(tenant)"
                      ></app-file-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <span *ngIf="canConvertVendorTasks">
              <button class="full-width" mat-flat-button color="accent" (click)="createVendorTasks()">
                Create Supplier Tasks
              </button>
            </span>
            <span *ngIf="canFinalize">
              <button class="full-width" mat-flat-button color="accent" (click)="finalizePunchlist()">
                Finalize Punchlist
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break">
    <div class="form-print-pdf p-4" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer p-4 full-width">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="gray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="gray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="gray m-0">
                {{ reportDateTime | date: 'shortTime' }}<br />
                {{ reportDateTime | date: 'mediumDate' }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="gray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ getCurrentProject().code }} | {{ getCurrentProject().building_code }}-{{
                    getCurrentProject().floor_code
                  }}
                  | {{ getCurrentProject().title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project.building_code }}-{{ project.building_id }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Coordinator </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }} &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p>
                  {{ project.square_footage || 0 }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Completion Date </label>
                <p>
                  {{ projectDate }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }} {{ project.architect_last_name }} &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="pl-4 pr-4" id="punchlist2">
        <section class="form-title row">
          <div class="col p-0">
            <h2 class="black font-weight-bold m-0">Punchlist</h2>
          </div>
        </section>
        <div class="row border-bottom-gray pb-1">
          <div class="col-1 pl-0">
            <label class="main-label"> ID </label>
          </div>
          <div class="col-11">
            <div class="row">
              <div class="col-8">
                <label class="main-label"> TITLE / DESCRIPTION </label>
              </div>
              <div class="col-4">
                <label class="main-label"> LOCATION </label>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let punchlist of punchlists$ | async; let i = index"
          class="row border-bottom-gray form-pdf-content pt-3 pb-3"
        >
          <div class="col-1 pl-0">
            <div class="row">
              <div class="col">
                <p class="font-weight-normal m-0">
                  {{ punchlist.local_index }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-11">
            <div class="row">
              <div class="col-8">
                <p class="font-weight-bold m-0">
                  <b> {{ punchlist.description || 'Task Description' }}</b>
                </p>
              </div>
              <div class="col-4">
                <p class="font-weight-normal m-0">
                  {{ punchlist.location || 'Location' }}
                </p>
              </div>
            </div>
            <div class="row task-description">
              <div class="col">
                <span [innerHTML]="punchlist.additional_notes" class="font-weight-normal m-0 mt-3"> </span>
              </div>
            </div>
            <!--            <div class="row">-->
            <!--              <span *ngFor="let file of punchlist.files">-->
            <!--                <div class="col mt-2" *ngIf="file.base64">-->
            <!--                  <img [src]="file.base64" [height]="100" />-->
            <!--                </div>-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </kendo-pdf-export>
</div>

<div id="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project.building_code }}-{{ project.floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }} &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p *ngIf="project">
                  {{ project.square_footage || 0 }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }} {{ project.architect_last_name }} &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-3">
            <!-- REPEAT ROWS FOR EACH REVIEWER -->
            <!--            <div class="row mb-4" *ngFor="let reviewer of currentReviewers">-->
            <!--              <div class="col">-->
            <!--                <label class="main-label mt-0">-->
            <!--                  {{ (reviewer.status && reviewer.dateCreated) || 'Pending Review' }}-->
            <!--                </label>-->
            <!--                <p class="m-0">-->
            <!--                  <b>{{ reviewer.firstName }} {{ reviewer.lastName }}</b-->
            <!--                  ><br />-->
            <!--                  <span>{{ reviewer.title }}</span-->
            <!--                  ><br />-->
            <!--                  <span *ngIf="reviewer.status === 1" class="green font-weight-semibold mt-2 mb-2"> Approved </span>-->
            <!--                  &lt;!&ndash; <br />-->
            <!--                  <span class="dkgray">-->
            <!--                    {{ reviewer.comment }}-->
            <!--                  </span> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="col-9">
            <div class="row mb-4 mt-2">
              <div class="col">
                <h4 class="m-0 font-weight-bold">Punchlist</h4>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <label class="main-label mt-0"> Prepared For </label>
                <p class="m-0">
                  <b>{{ currentTenant && currentTenant.name }}</b
                  ><br />
                  <span>{{ currentTenant && currentTenant.title }}</span
                  ><br />
                  <span>{{ currentTenant && currentTenant.department }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <p class="m-0">
                  <!-- TENANT REP FIRST NAME -->
                  {{ currentTenant && currentTenant.first_name }}, <br /><br />
                  <!-- MESSAGE -->
                  <!--                  TODO save this is default?-->
                  <!--                  <span>-->
                  <!--                    We have developed a preliminary estimated budget for your project. Please review it carefully. If-->
                  <!--                    you approve of the defined budget, please check the appropriate box, sign, and re-attach the-->
                  <!--                    following budget page onto the task assigned to you. If you have any questions, please add your-->
                  <!--                    comments to the task. I will be notified of your comments and will respond accordingly. And lastly,-->
                  <!--                    if you would like to make a change to the budget, please check the reject box, sign and re-attach-->
                  <!--                    to the task.-->
                  <!--                    <br><br>-->
                  <!--                    Thank you for you prompt reply.-->
                  <!--                  </span>-->
                  <span style="white-space: pre-wrap" [innerHTML]="coverLetterText || 'No text available'"> </span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: PROJECT MANAGER -->
                <p class="m-0">
                  <b>{{ pmInfo.name }}</b
                  ><br />
                  <span>{{ pmInfo.title }}</span
                  ><br />
                  <span>{{ pmInfo.email }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: ARCHITECT -->
                <p class="m-0" *ngIf="architectInfo">
                  <!-- MIGHT PUT AN NGIF ON THE ARCHITECT TO ADD TIM'S CREDENTIALS?? -->
                  <b>{{ architectInfo.name }}</b
                  ><br />
                  <span>{{ architectInfo.title }}</span
                  ><br />
                  <span>{{ architectInfo.email }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
