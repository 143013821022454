<div class="m-portlet m-portlet--mobile mb-4">
  <div (click)="dashboardService.toggleUser(user.id)" class="m-portlet__body item ease p-3 pointer">
    <div class="row align-items-center">
      <div class="col">
        <app-profile-thumbnail
          class="d-inline-block align-middle pointer ease mr-3"
          userId="{{ user.id }}"
          [height]="36"
          [width]="36"
          matTooltip="View Profile"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
          [routerLink]="['/profile/' + user.id]"
        ></app-profile-thumbnail>
        <h5 class="dkblue font-weight-semibold m-0 ml-1 d-inline-block align-middle">
          <span class="mr-2"> {{ user.first_name }} {{ user.last_name }} </span>
          <br />
          <small class="gray"> {{ user.company_name }}{{ user.title ? ' • ' : '' }}{{ user.title }} </small>
        </h5>
      </div>
      <div class="col-1">
        <h6 class="m-0">
          <h6 class="m-0">
            <span class="badge badge-warning">
              {{ user.user_type_name }}
            </span>
          </h6>
        </h6>
      </div>
      <div class="col-2">
        <h6 class="m-0 ltblue font-weight-semibold">{{ getProjectAndTaskData().totalProjects }} Projects</h6>
      </div>
      <div class="col">
        <h6 class="m-0 font-weight-normal dkblue d-inline-block mb-2 mr-2">
          <span class="font-weight-bold">
            {{ getProjectAndTaskData().tasksComplete }}
          </span>
          of {{ getProjectAndTaskData().totalTasks }}
        </h6>
        <p class="m-0 mr-3 d-inline-block dkblue">Tasks Complete;</p>
        <h6 class="m-0 font-weight-normal red d-inline-block mb-2 mr-2">
          <span class="font-weight-bold">
            {{ getProjectAndTaskData().tasksOverdue }}
          </span>
        </h6>
        <p class="m-0 d-inline-block red">Tasks Overdue</p>
        <mat-progress-bar
          color="accent"
          mode="buffer"
          [value]="getPercent(getProjectAndTaskData().tasksComplete, getProjectAndTaskData().totalTasks)"
          [bufferValue]="
            getPercent(
              getProjectAndTaskData().tasksComplete + getProjectAndTaskData().tasksOverdue,
              getProjectAndTaskData().totalTasks
            )
          "
        >
        </mat-progress-bar>
      </div>
      <div class="col-1 pr-3 text-right">
        <button
          *ngIf="!dashboardService.isUserExpanded(user.id)"
          mat-icon-button
          class="dkblue"
          matTooltip="More"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <button
          *ngIf="dashboardService.isUserExpanded(user.id)"
          mat-icon-button
          class="dkblue"
          matTooltip="More"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="dashboardService.isUserExpanded(user.id)">
    <app-dashboard-task *ngFor="let task of user.tasks" [task]="task"></app-dashboard-task>
  </div>
</div>
