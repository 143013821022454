import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  AssignUserDialogComponent,
  ConfirmationDialogComponent,
  EditorComponent,
  ProjectUpdateDialogComponent,
  UserProfilePreviewComponent,
} from 'src/app/components';
import { KeyControlTypes, ProjectDetailsViews, ResourceTags, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import {
  AuthService,
  DisplayReviewersService,
  FileService,
  KeyControlsService,
  ModalService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  ProjectTaskService,
  RequestService,
} from 'src/app/services';
import {
  KeyControlsBridge,
  Project,
  ProjectOverview,
  ProjectUpdate,
  RequestMethod,
  TaskAccessoryData,
  User,
} from 'src/app/types';
import { keyControlAuditCounts } from 'src/app/utils';
import { ProjectOverviewDialogComponent } from 'src/app/workspaces/construction/components';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    public displayReviewersService: DisplayReviewersService,
    private projectService: ProjectService,
    private projectOverviewService: ProjectOverviewService,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private snackbar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public keyControlsService: KeyControlsService,
    private requestService: RequestService,
    private taskService: ProjectTaskService
  ) {}

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  @ViewChild('projectSummaryEditor', { static: true })
  private _project_summary_editor_component: EditorComponent;

  private manuallyEnteredKeyControlTasks = [
    KeyControlTypes.FIRE_MARSHAL_61,
    KeyControlTypes.CONTRACT_COMPLETION_61,
    KeyControlTypes.PROJECT_PROPERLY_BID_74,
    KeyControlTypes.CUSTOMER_SATISFACTION_74,
  ];

  currentUser: User;
  isEditingProjectSummary = false;
  isEditingProjectTitle = false;
  projectId: number;
  project: Project;
  overview: ProjectOverview;
  projectTitle = new FormControl('', [Validators.required]);
  taskStatusFilter: 'all' | 'mine' | 'staff' | 'tenants' | 'vendors';
  taskUserFilter;
  shownStatusTasks: any[] | any;
  shownUserTasks: any;
  keyControlsSubscription: any;
  public TASK_REVIEW_STATUS = TaskReviewStatus;
  public TASK_STATUS = TaskStatus;
  public isStaff;
  public collapseDetailView = true;
  public showCollapseDetailViewButton = false;

  // only select fields editable from overview
  projectFields: string[] = [
    'module_id',
    'request_id',
    'request{created_datetime,request_method_id,request_method{id,name,is_enabled,is_default,icon}}',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'end_date',
    'end_date_estimate',
    'capx_id',
    'parent{building_code,floor_code}',
    'tag_ids',
  ];
  projectUpdateFields: string[] = [
    'id',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'created_by_user_type_id',
    'created_datetime',
    'project_health_type_id',
    'project_health_type_name',
    'message',
    'notify_followers',
    'access',
    'files',
  ];
  projectKeyControls: KeyControlsBridge[] = [];
  projectUpdates: ProjectUpdate[];
  tasksData;
  routeSubscription: Subscription;
  private refreshSubscription;
  capxBudgets = [];

  ngOnInit() {
    setTimeout(() => {
      this.getDivWidth();
      this.currentUser = this.authService.getLoggedInUser();
      this.refresh();
      this.routeSubscription = this.activeRoute.params.subscribe(async (value) => {
        if (+value.id !== +this.projectService.currentSelectedProjectId) {
          this.refresh(+value.id);
        }
      });
    });

    this.refreshSubscription = this.projectService.refreshNeeded$.subscribe(async () => {
      await this.refresh();
    });

    this.keyControlsSubscription = this.keyControlsService.keyControlCountUpdated.subscribe(async (task) => {
      const keyControlIndex = this.projectKeyControls.findIndex((keyControl) => keyControl.task?.id === task.id);
      if (keyControlIndex !== -1) {
        this.projectKeyControls[keyControlIndex].task.accessory_data = task.accessory_data;
        await this.populateKeyControlReviewers(keyControlIndex);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.keyControlsSubscription) {
      this.keyControlsSubscription.unsubscribe();
    }
  }

  get createdByName(): string {
    return `${this.project?.created_by_first_name || ''} ${this.project?.created_by_last_name || ''}`.trim();
  }

  get requester(): User {
    return {
      id: this.overview?.requester_id,
      first_name: this.overview?.requester?.first_name,
      last_name: this.overview?.requester?.last_name,
      title: this.overview?.requester?.title,
      department_name: this.overview?.requester?.department_name,
    };
  }

  get projectManager(): User {
    return this.overview?.project_manager;
  }

  get formattedOpenDate(): string {
    if (this.project?.request?.created_datetime) {
      return moment(this.project?.request?.created_datetime).format('dddd, MMMM Do YYYY');
    }
    return '';
  }

  get formattedOpenTime(): string {
    if (this.project?.request?.created_datetime) {
      return moment(this.project?.request?.created_datetime).format('LT');
    }
    return '';
  }
  get isWorkspaceStaff() {
    return this.authService?.isUserWorkspaceStaff(this.project?.module_id);
  }

  get isTenant() {
    return this.authService?.isTenant;
  }

  get requestMethod(): RequestMethod {
    return this.project?.request?.request_method || null;
  }

  get totalCAPX() {
    let selectedYears = this.capxBudgets.filter((fy) => fy.selected);
    selectedYears = selectedYears.length > 0 ? selectedYears : this.capxBudgets;
    const total = selectedYears.reduce((a, c) => a + c.budget, 0);
    const percentage = (this.overview?.awarded_quote_total / total) * 100;
    return [total, percentage];
  }

  public changeProjectRequester() {
    this.dialog
      .open(AssignUserDialogComponent, {
        autoFocus: false,
        width: '560px',
        disableClose: true,
        data: {
          displayLabel: 'User Requester',
          displayTitle: 'Project Requester',
          displayPlaceHolder: 'Requester',
          isARequestorUser: true,
        },
      })
      .afterClosed()
      .subscribe(async (selectedUser) => {
        // if undefined do nothing, canceled
        if (selectedUser?.id && +this.requester?.id !== +selectedUser.id && this.project?.request_id) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Changing project requester...');
          await this.projectService.changeProjectRequester(this.projectId, selectedUser.id).toPromise();
          this.refresh();
        }
        this.progressIndicatorService.close();
      });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  async refresh(projectId?: number) {
    if (this.progressIndicatorService) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Retrieving Data..');
    }
    this.projectId = projectId || this.projectService.currentSelectedProjectId;
    this.project = await this.projectService.getProjectById(this.projectId, this.projectFields).toPromise();
    this.isStaff = this.authService.isUserWorkspaceStaff(this.project.module_id);
    this.overview = await this.projectOverviewService.getProjectOverview(this.projectId).toPromise();

    this.changeTaskStatusFilter(this.taskStatusFilter || 'all');
    this.changeTaskUserFilter(this.taskUserFilter || 'allUsers');

    const projectUpdates = await this.projectOverviewService
      .getProjectUpdates(this.projectUpdateFields, [
        { type: 'field', field: 'project_id', value: this.projectId.toString() },
      ])
      .toPromise();

    // add in the fields for updates
    projectUpdates.forEach((projectUpdate: ProjectUpdate) => {
      projectUpdate.collapseMessageView = true;
      projectUpdate.showCollapseMessageViewButton = false;
    });

    this.projectUpdates = orderBy(projectUpdates, (u) => u.created_datetime, 'desc');
    if (this.overview?.request_summary || this.overview?.original_request_files?.length) {
      this.projectUpdates.push({
        is_original_request: true,
        created_by_id: this.overview.request_requester?.id || this.overview.requester?.id,
        created_by_first_name: this.overview.request_requester?.first_name || this.overview.requester?.first_name,
        created_by_last_name: this.overview.request_requester?.last_name || this.overview.requester?.last_name,
        created_datetime:
          this.overview.request_converted_from_wo_created_datetime || this.overview.request_created_datetime,
        message: this.overview.request_summary,
        access: [2, 3],
        files: this.overview?.original_request_files || [],
      });
    }

    // get all tasks data for dialog in advance
    this.projectService
      .getTasksByProject(this.projectId, ['id', 'code', 'title', 'due_date', 'status_name', 'assigned_user_id'])
      .subscribe((data) => {
        this.tasksData = data;
      });

    // get key controls
    if (this.isStaff) {
      await this._getProjectKeyControls();
    }

    this.capxBudgets = await this.projectService.getCAPXBudgets(this.project.capx_id).toPromise();

    this.progressIndicatorService.close();
  }

  private async _getProjectKeyControls() {
    let keyControlBridges: KeyControlsBridge[] = await this.keyControlsService
      .getKeyControlsForProject(this.projectId)
      .toPromise();

    keyControlBridges = keyControlBridges.sort(
      (a, b) => a.key_control_template?.sequence - b.key_control_template?.sequence
    );

    this.projectKeyControls = keyControlBridges.map((keyControlBridge: KeyControlsBridge) => {
      if (keyControlBridge?.task?.accessory_data) {
        const accessoryData: TaskAccessoryData = JSON.parse(keyControlBridge?.task?.accessory_data);
        // add it to the bridge
        keyControlBridge.task.key_control_accessory_data = accessoryData;

        // get count and add it to the bridge
        const AuditCounts = keyControlAuditCounts(accessoryData?.reviewChain);
        keyControlBridge.task.key_control_status_counts = AuditCounts;

        return keyControlBridge;
      }
      // do nothing and just return
      return keyControlBridge;
    });

    // because its async, do it seperately
    for (const index in this.projectKeyControls) {
      // This if is so i can use the index without lint errors
      if (this.projectKeyControls[index].task?.accessory_data) {
        await this.populateKeyControlReviewers(index);

        // only ran this for completed tasks
        if (+this.projectKeyControls[index]?.task?.status_id === TaskStatus.Complete) {
          // handle historic data if there is
          let keyControlTaskHistory = [];
          // Otherwise, use the completed tasks
          let keyControlCompleted = [];
          if (
            this.manuallyEnteredKeyControlTasks.find(
              (manuallyEnteredKCTask: KeyControlTypes) =>
                manuallyEnteredKCTask === this.projectKeyControls[index].key_control_template.display_data
            )
          ) {
            keyControlCompleted = await this.taskService
              .getEventsForTaskId(this.projectKeyControls[index].task_id, 'complete')
              .toPromise();
          } else {
            // try getting historical data using history service, if nothing, then try using the task service
            keyControlTaskHistory = await this.keyControlsService
              .getKeyControlHistory(this.projectKeyControls[index].id)
              .toPromise();

            // check if there is task history
            if (
              !keyControlTaskHistory?.length ||
              !keyControlTaskHistory.filter((entry) => entry.event_id === 1).length
            ) {
              // grab the task by task service
              keyControlCompleted = await this.taskService
                .getEventsForTaskId(this.projectKeyControls[index].task_id, 'complete')
                .toPromise();
            }
          }

          // if there is history data,
          // find the approval and get the person who created the event
          if (keyControlTaskHistory?.length && keyControlTaskHistory.filter((entry) => entry.event_id === 1).length) {
            const foundCompletedEvent = keyControlTaskHistory.find(
              (kcHistory) => +kcHistory.event_id === TaskReviewStatus.Approved
            );
            this.projectKeyControls[index].completed_datetime = foundCompletedEvent?.created_datetime;
            this.projectKeyControls[index].completed_by = {
              id: foundCompletedEvent.created_by_id,
              first_name: foundCompletedEvent?.created_by?.first_name,
              last_name: foundCompletedEvent?.created_by?.last_name,
            };
          } else if (keyControlCompleted?.length) {
            // sort it and pick the latest
            const sortedkeyControlCompleted = keyControlCompleted.sort((a, b) => {
              return moment(moment(b.completed_datetime)).diff(moment(a.completed_datetime));
            });

            this.projectKeyControls[index].completed_datetime = sortedkeyControlCompleted[0].created_datetime;
            this.projectKeyControls[index].completed_by = {
              id: sortedkeyControlCompleted[0].created_by_id,
              first_name: sortedkeyControlCompleted[0].created_by_first_name,
              last_name: sortedkeyControlCompleted[0].created_by_last_name,
            };
          }
        }
      }
    }
  }

  private async populateKeyControlReviewers(index) {
    const accessoryData: TaskAccessoryData = JSON.parse(this.projectKeyControls[index].task?.accessory_data);

    if (accessoryData?.reviewChain?.length) {
      const reviewers = await this.displayReviewersService.addUserInfoToReviewChain(accessoryData?.reviewChain);

      if (reviewers.length) {
        // Order them by date, from newest to old
        const orderedReviewers = reviewers.sort((a, b) => {
          return moment(moment(b.date)).diff(moment(a.date));
        });

        this.projectKeyControls[index].key_control_reviewers = [...orderedReviewers];
      }
    }
  }

  openProjectDetailsDialog() {
    this.modalService
      .openProjectDetailsDialog(this.project.id, ProjectDetailsViews.Details)
      .toPromise()
      .then((updatedProject) => {
        if (updatedProject) {
          this.project = updatedProject;
          this.refresh();
        }
      });
  }

  changeTaskStatusFilter(filter: 'all' | 'mine' | 'staff' | 'tenants' | 'vendors') {
    this.taskStatusFilter = filter;
    this.shownStatusTasks = this.overview.tasks[filter];
  }

  changeTaskUserFilter(filter) {
    this.taskUserFilter = filter;
    let allUserTasks;
    if (this.taskUserFilter === 'allUsers') {
      allUserTasks = (this.overview.tasks.staff || [])
        .concat(this.overview.tasks.tenants || [])
        .concat(this.overview.tasks.vendors || []);
    } else {
      allUserTasks = this.overview.tasks[this.taskUserFilter];
    }
    this.shownUserTasks = orderBy(allUserTasks, (u) => u.task_count, 'desc');
  }

  openOverviewDialog(taskStatus: string, showUsers = false, selectedUser = null) {
    this.dialog.open(ProjectOverviewDialogComponent, {
      width: '740px',
      data: {
        status: taskStatus,
        mine: this.taskStatusFilter === 'mine' && showUsers !== true && !selectedUser,
        tasks: this.tasksData,
        taskUserFilter: this.taskUserFilter,
        showUsers,
        users: this.overview.tasks,
        selectedUser,
      },
    });
  }

  getUserTitle(userId, userTypeId) {
    if (userId === this.overview.project_manager.id) {
      return 'Project Manager';
    } else if (userId === this.overview.workspace_manager.id) {
      return 'Workspace Manager';
    } else if (userId === this.overview.cfmo.id) {
      return 'Chief Facilities Management Officer';
    } else if (userTypeId === 1) {
      return 'Staff';
    } else if (userTypeId === 2) {
      return 'Tenant';
    } else if (userTypeId === 3) {
      return 'Vendor';
    }
  }

  addProjectUpdate() {
    const project_health_type_id =
      this.projectUpdates.filter((pu) => pu.project_health_type_id)[0]?.project_health_type_id || null;
    const dialogRef = this.dialog.open(ProjectUpdateDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        project_id: this.projectId,
        follower_ids: this.overview?.follower_ids,
        endDate: this?.project?.end_date,
        endDateEstimate: this.project?.end_date_estimate,
        project_health_type_id: project_health_type_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  public cancelEditingProjectSummary() {
    this.isEditingProjectSummary = false;
    this._project_summary_editor_component?.content?.setValue(this.overview?.scope_of_work);
  }

  public cancelEditingProjectTitle() {
    this.isEditingProjectTitle = false;
    this.projectTitle.setValue(this.overview?.title ?? '');
  }

  public editProjectSummary() {
    if (this.isStaff) {
      this.isEditingProjectSummary = true;
      this._project_summary_editor_component?.content?.setValue(this.overview?.scope_of_work);
    }
  }

  public editProjectTitle() {
    if (this.isStaff) {
      this.isEditingProjectTitle = true;
      this.projectTitle.setValue(this.overview?.title ?? '');
    }
  }

  public async updateProjectSummary() {
    if (this._project_summary_editor_component.content.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Project Summary...');
      await this.projectService
        .updateProject(this.projectId, { scope_of_work: this._project_summary_editor_component.content.value ?? '' }, [
          'scope_of_work',
        ])
        .toPromise();
      this.isEditingProjectSummary = false;
      await this.refresh();
      this.snackbar.open('Project Summary has been Updated!');
    }
  }

  public async updateProjectTitle() {
    if (this.projectTitle.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Project Title...');
      await this.projectService
        .updateProject(this.projectId, { title: this.projectTitle?.value ?? '' }, ['title'])
        .toPromise();
      this.isEditingProjectTitle = false;
      await this.refresh();
      this.snackbar.open('Project Title has been Updated!');
    }
  }

  editProjectUpdate(projectUpdate?: ProjectUpdate) {
    const project_health_type_id =
      this.projectUpdates.filter((pu) => pu.project_health_type_id)[0]?.project_health_type_id || null;
    const dialogRef = this.dialog.open(ProjectUpdateDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        ...projectUpdate,
        endDate: this?.project?.end_date,
        endDateEstimate: this.project?.end_date_estimate,
        update: true,
        project_health_type_id: project_health_type_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  async deleteUpdate(projectUpdate?: ProjectUpdate) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        confirmationButtonText: 'Delete',
        titleBarText: 'Delete Project Update',
        descriptionText: 'Are you sure you want to delete this update? This action cannot be undone.',
      },
    });

    dialogRef.afterClosed().subscribe(async (isConfirmed) => {
      if (isConfirmed) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Deleting Update..');
        if (projectUpdate.files) {
          projectUpdate.files.forEach((file) => {
            // for each file on this deleted update, remove the Update tag (id 77)
            this.fileService.removeTags(file.file_id || file.id, [77]).subscribe();
          });
        }
        await this.projectOverviewService.deleteProjectUpdate(projectUpdate.id).toPromise();
        this.snackbar.open(`Project Update deleted!`);
        this.refresh();
      }
    });
  }

  public formatDate(date) {
    return moment(date).format('MMMM D YYYY');
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  async viewKeyControls(keyControlId?: number) {
    await this.modalService.openViewKeyControlsDialog(this.project.id, keyControlId).toPromise();
  }

  get filteredYears() {
    return this.capxBudgets.filter((fy) => fy.selected);
  }

  fyFilter(e: any, fy: any) {
    e.stopPropagation();
    fy.selected = !fy.selected;
  }

  get projectHasCAPX() {
    return this.project?.tag_ids?.includes(ResourceTags.CAPX);
  }

  get parentProject() {
    const parent = this.project?.parent;
    const location = this.stringJoin([parent.building_code, parent.floor_code], '-');
    return this.stringJoin([parent?.code, location, parent?.title], ' | ');
  }

  public stringJoin(arr: string[], string): string {
    return arr.reduce((a, c) => (a ? (c ? a + string + c : a) : c), '');
  }
}
