import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatepickerHeaderComponent, FileAttachmentDialogComponent, NewAccountModalComponent } from 'src/app/components';
import { BidType, CompanyVerificationStatus, ResourceType } from 'src/app/enums';
import {
  AuthService,
  CompanyService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  UserService,
} from 'src/app/services';
import { Company, User } from 'src/app/types';
import { Bid, ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-bid-dialog',
  templateUrl: './bid-dialog.component.html',
  styleUrls: ['./bid-dialog.component.scss'],
})
export class BidDialogComponent implements OnInit {
  @ViewChild('newBidForm', { static: true }) newBidForm: NgForm;
  @ViewChild('company_auto_input', { read: MatAutocompleteTrigger }) autocomplete: MatAutocompleteTrigger;

  private scheduleFields = ['bid_start_date', 'bid_end_datetime'];

  public bidDetails;
  public bidFields: string[];
  public bidMeetings;
  public bidPackageAwarded: boolean;
  public bidPackage;
  public bids: Bid[];
  public bidsToRemove: Bid[] = [];
  public bondCompanies: Company[];
  public companyAwarded = false;
  public companyInput: Subject<string> = new Subject();
  public companySearchTerm: string;
  public customHeader = DatepickerHeaderComponent;
  public draftBid: any = {};
  public draftAlt;
  public filteredCompanies: Company[];
  public isEditingDetails = false;
  public loaders: any = {};
  public newBid = true;
  public project: ProjectConstruction;
  public ResourceType: ResourceType;
  public scheduleData: any;
  public selectedCompany;
  public selectedCompanyContacts: User[];
  public filteredSelectedCompanyContacts: User[];
  public showExtraFields: boolean;
  public title: string;
  public unsavedId = 1;
  public userFields = ['id', 'email', 'first_name', 'last_name', 'company_id'];
  public viewOnly: boolean;
  CompanyVerificationStatus = CompanyVerificationStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _companyService: CompanyService,
    private authService: AuthService,
    private _dialog: MatDialog,
    public _dialogRef: MatDialogRef<BidDialogComponent>,
    private _fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this.showExtraFields = false;
    this.bidPackage = this.data.bidPackage;
    this.bidPackageAwarded = !!this.data.bidPackage?.awardedBidCompanyId;
    this.viewOnly = this.data.viewOnly;
    if (!this.viewOnly) {
      this.isEditingDetails = true;
    }

    if (this.data.company) {
      // the search term will start out as a company object, needs refactor
      this.companySearchTerm = this.data.company;

      this.newBid = false;
      this.selectedCompany = this.data.company;
      this.companyAwarded = this.selectedCompany?.id === this.bidPackage?.awardedBidCompanyId;
      this.draftBid = this.selectedCompany.bids.find((bid) => bid.type_id === BidType.Base && bid.is_selected);
      this.draftBid.date_received = this.draftBid.datetime_received;
      this.draftBid.time_received = moment(this.draftBid.datetime_received).format('hh:mm:ss');
      this.draftBid.did_not_bid = this.draftBid.did_not_bid ?? 0;
      this.bids = this.selectedCompany.bids;

      this.bids.forEach((bid) => {
        bid.cost = (bid.amount && bid.amount > -1) || !bid.amount;
      });

      await this.updateCompanyUsers();
      this.draftBid.contact = this.selectedCompanyContacts.find((contact) => this.draftBid.contact_id === contact.id);
    } else if (this.data.draftBid) {
      this.draftBid = this.data.draftBid;
      this.draftBid.type_id = BidType.Base;
      this.draftBid.is_selected = 1;
      this.draftBid.cost = true;
      this.draftBid.did_not_bid = 0;
      this.bids = [this.draftBid];
    }

    this.title = this.newBid ? 'New' : this.viewOnly ? 'View' : 'Edit';

    if (+this.bidPackage?.trade_allows_bids === 0) {
      this._dialogRef.close();
    }

    this.bidMeetings = this.data.bidMeetings || [];
    this.bidDetails = {};
    this.project = this.data.project;
    this.bidFields = this.data.bidFields;
    this.bondCompanies = await this._companyService
      .getCompanies(null, [{ type: 'field', field: 'type_id', value: '4' }])
      .toPromise();

    this.companyInput.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (searchTerm: any) => {
      if (searchTerm) {
        if (!searchTerm.id) {
          this.biddingCompany.setErrors({ invalidCompany: true });
          this.filteredCompanies = await this._companyService
            .getCompanies(
              ['name', 'type_id', 'verification_status'],
              [
                {
                  type: 'field',
                  field: 'name',
                  value: searchTerm.toLowerCase(),
                  match: 'any',
                },
                { type: 'operator', value: 'AND' },
                { type: 'field', field: 'is_enabled', value: 1 },
              ],
              1000
            )
            .toPromise();
          this.draftBid.contact = null;
        } else if (searchTerm.id) {
          this.selectedCompany = searchTerm;
          this.filteredCompanies = [this.selectedCompany];
          await this.updateCompanyUsers();
        }
      } else {
        this.filteredCompanies = [];
      }
    });

    this.scheduleData = await this.projectService
      .getProjectBidDetailsByProjectId(this.project.id, this.scheduleFields)
      .toPromise();

    // set default
    this.draftBid.collateral_type = this.draftBid.collateral_type || 0;
  }

  get biddingCompany() {
    return this.newBidForm.form.get('biddingCompany');
  }

  get BidType() {
    return BidType;
  }

  get bidTotal() {
    return sumBy(
      this.bids?.filter((bid) => bid.type_id === BidType.Base || bid.is_selected),
      'amount'
    );
  }

  get didNotBid() {
    return !!this.draftBid.did_not_bid;
  }

  get displayDate() {
    return moment(this.draftBid?.datetime_received).format('ddd MMM DD • h:mm a');
  }

  get getContactName() {
    return this.draftBid.contact
      ? `${this.draftBid.contact.first_name} ${this.draftBid.contact.last_name}`
      : 'Choose a contact...';
  }

  get hasMandatoryMeetings(): boolean {
    return !!this.bidMeetings?.find((m) => m.is_mandatory);
  }

  get isBeforeBidEndDate() {
    const receivedDate = moment(this.draftBid.datetime_received);
    const bidEndDate = moment(this.scheduleData?.bid_end_datetime);

    return (
      this.bidPackage.has_re_bid ||
      (this.scheduleData?.bid_end_datetime && receivedDate.isBefore(bidEndDate)) ||
      !this.scheduleData?.bid_end_datetime
    );
  }

  get over100k() {
    return this.bidTotal >= 100000;
  }

  filterCompanies(value) {
    this.companyInput.next(value);
  }

  async updateCompanyUsers() {
    // grab the relevant users for each company to allow correct filtering for the dropdown
    if (this.selectedCompany.id) {
      this.selectedCompanyContacts = await this.userService
        .getUsersByCompany([this.selectedCompany.id], this.userFields, true)
        .toPromise();
      this.filteredSelectedCompanyContacts = this.selectedCompanyContacts;
    }
  }

  filterCompanyContacts(input): void {
    const companyName = input.target.value;
    if (companyName) {
      this.filteredSelectedCompanyContacts = this.selectedCompanyContacts.filter((c) =>
        `${c.first_name || ''} ${c.last_name || ''}`.toLowerCase().includes(companyName.toLowerCase())
      );
    } else {
      this.filteredSelectedCompanyContacts = this.selectedCompanyContacts;
    }
  }

  get isAdmin() {
    return this.authService.isProjectAdmin(this.project?.id, this.project?.module_id);
  }

  public displayAdditionalFields() {
    this.showExtraFields = !this.showExtraFields;
  }

  openUploadModal(fileType: 'rfp' | 'requirement' | 'circulation' | 'alt' | 'draft') {
    // let additionalParents = [];
    // if(fileType === 'requirement')
    //   additionalParents.push(new UhatFileBridgeLink(this.bidDetails.id, ResourceType.BidRequirement));

    let maxFiles = 10;
    if (fileType === 'requirement') {
      maxFiles = 30;
    }

    // since we don't allowComment, this just links the files to the parent and the additionalParents
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          // additionalParents,
          maxFiles,
          preSelectedTags: [{ id: 11 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          resultData.forEach((file) => this.addFileToBidDetails(fileType, { id: file.file_id, name: file.name }, true));
        }
      });
  }

  openUploadFileModal(bid) {
    // let additionalParents = [];
    // if(fileType === 'requirement')
    //   additionalParents.push(new UhatFileBridgeLink(this.bidDetails.id, ResourceType.BidRequirement));

    const maxFiles = 1;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          // additionalParents,
          maxFiles,
          preSelectedTags: [{ id: 11 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          bid.files = resultData;
        }
      });
  }

  removeFileFromBidDetails(
    type: 'rfp' | 'requirement' | 'circulation' | 'alt' | 'draft',
    file,
    shouldAlert?: boolean,
    loader?: string
  ) {
    if (loader) {
      this.loaders[loader] = true;
    }
    if (type === 'rfp') {
      delete this.bidDetails.rfp_file_id;
      delete this.bidDetails.rfp_file_name;
      delete this.bidDetails.rfp_file;
    } else if (type === 'circulation') {
      delete this.bidDetails.circulation_file_id;
      delete this.bidDetails.circulation_file_name;
      delete this.bidDetails.circulation_file;
    } else if (type === 'requirement') {
      if (this.bidDetails.requirement_files && this.bidDetails.requirement_files.length > 0) {
        const requirementFiles = this.bidDetails.requirement_files.filter((f) => f.id === file.id);
        requirementFiles.forEach((f) =>
          this.bidDetails.requirement_files.splice(this.bidDetails.requirement_files.indexOf(f), 1)
        );
      } else {
        throw new Error('No requirement files to delete');
      }
    } else if (type === 'alt') {
      this.draftAlt.file = null;
    } else if (type === 'draft') {
      this.draftBid.file = this.draftBid.file.filter((bidFile) => bidFile.id !== file.id);
    }
    if (shouldAlert) {
      this.snackbar.open(`File removed!`);
    }

    if (loader) {
      this.loaders[loader] = false;
    }
  }

  public async removeFile(bid) {
    if (this.bidPackageAwarded) {
      return;
    }

    if (!bid.files[0].file_id) {
      await this._fileService
        .getBridgeFile(bid.files[0].id, bid.id, ResourceType.Bid)
        .toPromise()
        .then(async (bridge) => {
          for (const file of bridge) {
            await this._fileService.unlinkFile(file.id).toPromise();
            await this._fileService.removeTags(bid.files[0].id, [11]).toPromise();
          }
        });
    }

    bid.files = null;
  }

  // adds the passed file to the appropriate object (doesn't update though until you hit save)
  addFileToBidDetails(
    type: 'rfp' | 'requirement' | 'circulation' | 'alt' | 'draft',
    file,
    shouldAlert?: boolean,
    loader?: string
  ) {
    if (loader) {
      this.loaders[loader] = true;
    }
    if (type === 'rfp') {
      this.bidDetails.rfp_file_id = file.id;
      this.bidDetails.rfp_file_name = file.name;
      this.bidDetails.rfp_file = file;
    } else if (type === 'circulation') {
      this.bidDetails.circulation_file_id = file.id;
      this.bidDetails.circulation_file_name = file.name;
      this.bidDetails.circulation_file = file;
    } else if (type === 'requirement') {
      this.bidDetails.requirement_files.push(file);
    } else if (type === 'alt') {
      this.draftAlt.file = file;
    } else if (type === 'draft') {
      this.draftBid.file = this.draftBid.file || [];
      this.draftBid.file.push(file);
    }

    if (shouldAlert) {
      this.snackbar.open(`File added!`);
    }

    if (loader) {
      this.loaders[loader] = false;
    }
  }

  public addAlternateBid() {
    const alternateBid: Bid = {
      bid_package_id: this.bidPackage.id,
      cost: true,
      is_selected: 1,
      type_id: BidType.Alt,
      unsavedId: this.unsavedId,
    };

    this.bids.push(alternateBid);
    this.unsavedId++;
  }

  public removeAlternateBid(bid) {
    if (!bid.id) {
      this.bids = this.bids.filter((b) => b.unsavedId !== bid.unsavedId);
    } else {
      this.bidsToRemove.push(bid);
      this.bids = this.bids.filter((b) => b.id !== bid.id);
    }
  }

  clearBidAmounts() {
    this.bids.forEach((bid) => {
      bid.amount = 0;
    });
  }

  toggleBidSelection(bid) {
    bid.is_selected = bid.is_selected ? 0 : 1;
  }

  toggleAmountValue(bid, cost) {
    bid.cost = cost;
    this.updateAmount(bid);
  }

  updateAmount(bid) {
    bid.amount = this.amountValue(bid.cost, bid.amount);
  }

  private amountValue(cost, amount) {
    return cost ? Math.abs(amount) : -Math.abs(amount);
  }

  async addBaseBid(openNewBid, openAltBid) {
    const foundCompany =
      this.bidPackage && this.bidPackage.companies && this.selectedCompany && this.selectedCompany.id
        ? this.bidPackage.companies.find((c) => c.id === this.selectedCompany.id)
        : null;

    if (!foundCompany || !this.newBid) {
      const invalidDraftBidFields = this.getInvalidDraftBidFields(this.bidPackage);
      if (invalidDraftBidFields && invalidDraftBidFields.length === 0) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Bid...');
        const bids = this.didNotBid ? [this.bids[0]] : this.bids;

        for (const bid of bids) {
          // Fields that can be changed for all bid types at all times.
          const bidToCreate: Bid = {
            amount: bid.amount,
            is_selected: bid.is_selected,
          };

          // Fields that need to be filled out when a new base bid is created, but no other time.
          if (this.newBid && bid.type_id === BidType.Base) {
            bidToCreate.datetime_received = `${moment(this.draftBid.date_received).format('YYYY-MM-DD')} ${
              this.draftBid.time_received
            }`;
          } else if (
            `${moment(this.draftBid.date_received).format('YYYY-MM-DD')} ${this.draftBid.time_received}` !==
            moment(this.draftBid.datetime_received).format('YYYY-MM-DD hh:mm:ss')
          ) {
            // actually changed
            bidToCreate.datetime_received = `${moment(this.draftBid.date_received).format('YYYY-MM-DD')} ${
              this.draftBid.time_received
            }`;
          }

          if (this.newBid || !bid.id) {
            // Fields that are needed to create a new bid regardless of type.
            bidToCreate.bid_package_id = bid.bid_package_id;
            bidToCreate.company_id = this.selectedCompany ? this.selectedCompany.id : null;
            bidToCreate.type_id = bid.type_id;
          }

          // This two fields can only be updated on Alt bids.
          if (bid.type_id === BidType.Alt) {
            bidToCreate.description = bid.description;
          }

          if (bid.type_id === BidType.Base) {
            bidToCreate.contact_id = this.draftBid.contact ? this.draftBid.contact.id : null;
            bidToCreate.did_not_bid = this.didNotBid ? 1 : 0;
            bidToCreate.is_sealed_envelope = this.draftBid.is_sealed_envelope ? 1 : 0;
            bidToCreate.attended_mandatory_meetings = this.draftBid.attended_mandatory_meetings ? 1 : 0;
            // bidToCreate.is_bid_bond = this.draftBid.is_bid_bond ? 1 : 0;
            // bidToCreate.is_letter_of_credit = this.draftBid.is_letter_of_credit ? 1 : 0;
            bidToCreate.collateral_type = +this.draftBid.collateral_type;
            bidToCreate.is_msa_agreement = this.draftBid.is_msa_agreement ? 1 : 0;
            bidToCreate.is_signed = this.draftBid.is_signed ? 1 : 0;
            bidToCreate.has_non_collusion = this.draftBid.has_non_collusion ? 1 : 0;
            bidToCreate.bond_company_name =
              this.draftBid.bond_company_name && +this.draftBid.collateral_type === 3
                ? this.draftBid.bond_company_name
                : null;
          }

          let createdBid;
          if (this.newBid || !bid.id) {
            createdBid = await this.projectService.createBid(bidToCreate, this.bidFields).toPromise();
          } else {
            const fieldsToUpdate = [];
            for (const field in bidToCreate) {
              if (bidToCreate.hasOwnProperty(field)) {
                fieldsToUpdate.push(field);
              }
            }

            createdBid = await this.projectService.updateBid(bid.id, bidToCreate, fieldsToUpdate).toPromise();
          }

          // Links files to their corresponding bids
          if (bid.files && bid.files.length) {
            for (const file of bid.files) {
              if (file.file_id) {
                await this._fileService.linkFile(+file.file_id, createdBid.id, ResourceType.Bid).toPromise();
                await this._fileService.addTags(+file.file_id, [11]).toPromise();
              }
            }
          }
        }

        // Remove any deleted alt bids from the database
        for (const b of this.bidsToRemove) {
          if (b.files?.length) {
            for (const file of b.files) {
              this._fileService.removeTags(+file.id, [11]).subscribe();
            }
          }

          await this.projectService.deleteBid(b.id).toPromise();
        }

        this.progressIndicatorService.close();
        this.snackbar.open('Bid added!');
        // if (openNewBid) {
        //   this.draftBid = { bid_package_id: this.bidPackage.id };
        //   this.draftAlt = null;
        // } else if (openAltBid) {
        //   this.draftAlt = { bid_package_id: this.bidPackage.id, company_id: this.selectedCompany.id };
        //   this.draftBid = { bid_package_id: this.bidPackage.id };
        // } else {
        // }
        this._dialogRef.close({
          openNewBid,
          openAltBid,
          company: this.selectedCompany,
          edited: this.isEditingDetails,
        });
      } else {
        this.snackbar.open(`Bid is invalid. Please complete the required fields: ${invalidDraftBidFields.join(', ')}`);
      }
    } else {
      this.snackbar.open(`A base bid has already been added to this bid package for company '${foundCompany.name}'`);
    }
  }

  getInvalidDraftBidFields(bidPackage): string[] {
    const invalidFields = [];
    if (!bidPackage || !bidPackage.id) {
      invalidFields.push('Bid Package ID');
    }
    if (this.draftBid) {
      if (!this.selectedCompany) {
        invalidFields.push('Bidding Company');
      }
      // if (!this.draftBid.description) { invalidFields.push('Bid Description'); }
      if (!this.draftBid.date_received && this.newBid) {
        invalidFields.push('Bid Received Date');
      }
      if (!this.draftBid.time_received && this.newBid) {
        invalidFields.push('Bid Received Time');
      }
      if (!this.draftBid.files?.length && !this.didNotBid) {
        invalidFields.push('File');
      }
      if (!this.didNotBid) {
        if (!this.draftBid.contact?.id) {
          invalidFields.push('Company Contact');
        }

        this.bids?.forEach((bid) => {
          if (!bid.amount && bid.amount !== 0) {
            const alreadyAdded = invalidFields.indexOf('Amount');
            if (alreadyAdded === -1) {
              invalidFields.push('Amount');
            }
          }
          if (bid.type_id === BidType.Alt && !bid.description) {
            const alreadyAdded = invalidFields.indexOf('Description');
            if (alreadyAdded === -1) {
              invalidFields.push('Description');
            }
          }
        });
      }
    } else {
      invalidFields.push('Draft Bid');
    }
    return invalidFields;
  }

  companyValueMapper(company) {
    return company ? company.name : null;
  }

  companyContactValueMapper(contact) {
    return contact ? contact.first_name + ' ' + contact.last_name : null;
  }

  addNewUser(company) {
    const dialogRef = this._dialog.open(NewAccountModalComponent, {
      width: '580px',
      disableClose: true,
      data: { company, userType: { id: 3 } },
    });

    dialogRef.afterClosed().subscribe(async (createdUser) => {
      await this.updateCompanyUsers();
      this.draftBid.contact = this.selectedCompanyContacts.find((c) => c.id === createdUser.id);
    });
  }

  addNewCompany(companyName) {
    const companyData = {
      company: { type_id: 3, name: companyName?.name || companyName, trade_ids: [this.bidPackage.trade_id] },
      showExistingCompanies: true,
    };
    this.modalService
      .openCompanyDialog(companyData)
      .toPromise()
      .then(async (createdCompany) => {
        if (createdCompany?.id) {
          this.selectedCompany = createdCompany;
          this.companySearchTerm = createdCompany;
          this.filteredCompanies = [this.selectedCompany];
          this.autocomplete.closePanel();
          await this.updateCompanyUsers();
        }
      });
  }

  collateralChanged(value) {
    if (value !== 3) {
      this.draftBid.bond_company_name = '';
    }
  }

  cancel(): void {
    this._dialogRef.close();
  }

  editDetails() {
    this.viewOnly = false;
    this.isEditingDetails = true;
  }
}
