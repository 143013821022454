import { Injectable } from '@angular/core';
import { Router, CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components';
import { ProjectComponent } from 'src/app/workspaces/construction/pages';

@Injectable({
  providedIn: 'root',
})
export class UnsavedGuard implements CanDeactivate<ProjectComponent> {
  constructor(private router: Router, private dialog: MatDialog) {}

  async canDeactivate(target: ProjectComponent) {
    let result = true;
    if (target.detailsComponent && target.detailsComponent.unsavedChangesExist === true) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unsaved Data',
          descriptionText: 'You have unsaved changes in the form, are you sure you want to leave the page?',
        },
      });

      await dialogRef
        .afterClosed()
        .toPromise()
        .then((res) => {
          result = res;
        });
    }
    return result;
  }
}
