import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { cloneDeep, pullAllBy } from 'lodash';
import * as moment from 'moment';
import { ConfirmationDialogComponent, UserProfilePreviewComponent } from 'src/app/components';
import { ApplicationRole, ResourceType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  ProjectService,
  ProjectTemplateService,
  RequestService,
  SidenavLinksService,
  UserService,
} from 'src/app/services';
import { APIFilter, ProjectTemplate, Request } from 'src/app/types';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
  constructor(
    private requestService: RequestService,
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private userService: UserService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    public authService: AuthService,
    private sidenavLinksService: SidenavLinksService
  ) {}

  shownRequest: Request;
  previousRequest: Request;
  nextRequest: Request;
  rooms = [];
  contacts = [];
  projectTemplates: ProjectTemplate[];
  selectedTemplates: ProjectTemplate[];
  availableTemplates: ProjectTemplate[];
  showTemplateSelect = false;
  architects;
  workspaceManagers;
  projectManagers;
  validationErrors: any = {};
  showValidationErrors = false;
  files;
  downloading = false;
  loaders = {
    convertRequest: false,
  };

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.refresh(params.id);
    });
  }

  async refresh(requestId) {
    const request: Request = await this.requestService.getRequestById(requestId).toPromise();
    const previousRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '<',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'module_id', value: request.module_id.toString() },
    ];
    this.requestService
      .getRequests(['id'], previousRequestFilters, 1, 'created_datetime', 'desc')
      .subscribe((requests) => {
        this.previousRequest = requests[0];
      });
    const nextRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '>',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'module_id', value: request.module_id.toString() },
    ];
    this.requestService.getRequests(['id'], nextRequestFilters, 1, 'created_datetime', 'asc').subscribe((requests) => {
      this.nextRequest = requests[0];
    });
    this.shownRequest = request;
    this.rooms = request.rooms ? JSON.parse(request.rooms) : [];
    const contactIds = request.contact_ids ? JSON.parse(request.contact_ids) : [];
    this.contacts = [];
    for (const cid of contactIds) {
      const user = await this.userService.getUserById(cid).toPromise();
      this.contacts.push(user);
    }

    this.projectTemplates = await this.projectTemplateService
      .getProjectTemplates(
        ['name', 'is_removable'],
        [
          {
            type: 'field',
            field: 'workspace_id',
            value: (this.shownRequest?.workspace?.id || '').toString(),
          },
        ]
      )
      .toPromise();
    this.selectedTemplates = [];
    const currentTemplates = JSON.parse(this.shownRequest.project_template_ids) || [];
    for (const templateId of currentTemplates) {
      this.selectedTemplates.push(this.projectTemplates.find((t) => t.id === templateId));
    }
    this.availableTemplates = pullAllBy(cloneDeep(this.projectTemplates), this.selectedTemplates, 'id');
    this.getValidationErrors();
    this.architects = await this.userService
      .getUsersByRole(ApplicationRole.ModuleArchitect, 1, null, null, false, [this.shownRequest.architect_id])
      .toPromise();
    this.workspaceManagers = await this.userService
      .getUsersByRole(ApplicationRole.WorkspaceManager, 1, null, null, false, [this.shownRequest.workspace_manager_id])
      .toPromise();
    this.projectManagers = await this.userService
      .getUsersByRole(ApplicationRole.ModuleProjectManager, 1, null, null, false, [
        this.shownRequest.project_manager_id,
      ])
      .toPromise();
    this.files = await this.fileService.getFilesByParentId(ResourceType.Request, request.id, null, 20).toPromise();
    for (const file of this.files) {
      this.fileService.fillFileWithBase64(file);
    }
  }

  get isWorkspaceAdmin() {
    return this.authService.isUserWorkspaceAdmin(this.shownRequest.module_id);
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.shownRequest.module_id);
  }

  downloadFile(file) {
    file.loading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.loading = false;
    });
  }

  changeRequestStatus(statusId: number) {
    this.requestService.changeRequestStatus(this.shownRequest.id, statusId).subscribe(() => {
      this.refresh(this.shownRequest.id);
    });
  }

  changeProjectManager(projectManager) {
    this.requestService
      .setRequestProjectManager(this.shownRequest.id, projectManager ? projectManager.id : null)
      .subscribe();
  }

  changeWorkspaceManager(workspaceManager) {
    this.requestService
      .setRequestWorkspaceManager(this.shownRequest.id, workspaceManager ? workspaceManager.id : null)
      .subscribe();
  }

  changeArchitect(architect) {
    this.requestService.setRequestArchitect(this.shownRequest.id, architect ? architect.id : null).subscribe();
  }

  addTemplate(projectTemplate: ProjectTemplate) {
    this.selectedTemplates.push(projectTemplate);
    this.showTemplateSelect = false;
    const selectedTemplateIds = this.selectedTemplates.map((t) => t.id);
    this.requestService
      .setRequestProjectTemplates(
        this.shownRequest.id,
        selectedTemplateIds && selectedTemplateIds.length > 0 ? selectedTemplateIds : null
      )
      .subscribe(() => {
        this.refresh(this.shownRequest.id);
      });
  }

  removeTemplate(index) {
    this.selectedTemplates.splice(index, 1);
    const selectedTemplateIds = this.selectedTemplates.map((t) => t.id);
    this.requestService
      .setRequestProjectTemplates(
        this.shownRequest.id,
        selectedTemplateIds && selectedTemplateIds.length > 0 ? selectedTemplateIds : null
      )
      .subscribe(() => {
        this.refresh(this.shownRequest.id);
      });
  }

  getValidationErrors() {
    this.validationErrors = { valid: true };
    // if (!this.shownRequest || !this.shownRequest.workspace_manager_id) { this.validationErrors['valid'] = false; this.validationErrors['workspaceManager'] = true; }
    // if (!this.shownRequest || !this.shownRequest.architect_id) { this.validationErrors['valid'] = false; this.validationErrors['architect'] = true; }
    if (!this.shownRequest || this.shownRequest.status_id !== 2) {
      this.validationErrors.valid = false;
      this.validationErrors.accepted = true;
    }
    if (!this.selectedTemplates || this.selectedTemplates.length < 1) {
      this.validationErrors.valid = false;
      this.validationErrors.templateCount = true;
    }
    return this.validationErrors;
  }

  convertToProject() {
    if (
      !this.shownRequest.project_manager_id ||
      !this.shownRequest.workspace_manager_id ||
      !this.shownRequest.architect_id
    ) {
      this.snackbar.open('Project Manager, Construction Manager, and Architect are all required fields.');
      return;
    }

    this.loaders.convertRequest = true;
    this.requestService.convertRequestToProject(this.shownRequest.id).subscribe(
      (project) => {
        this.loaders.convertRequest = false;
        this.snackbar.open('Request converted to project!');
      },
      (error) => {
        this.loaders.convertRequest = false;
      }
    );
    this.shownRequest.status_id = 3;
  }

  async goToProject() {
    const projectId = await this.projectService.getProjectIdByRequestId(this.shownRequest.id).toPromise();
    if (projectId) {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.projectView);
      this.router.navigateByUrl(`/projects/${projectId[0].id}/details`);
    }
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  public downloadRequestFile(pdf, requestCode) {
    this.downloading = true;
    pdf.saveAs('Request_' + requestCode + '.pdf');
    this.downloading = false;
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public filesHasImages(): boolean {
    return this.files && this.files.filter((f) => f.base64 != null).length > 0;
  }

  public transferToDispatch() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Transfer',
          headerText: `Transfer Request to Dispatch`,
          descriptionText: 'Are you sure you wish to transfer this request to dispatch?',
          cancelButtonText: 'Cancel',
          userInput: {
            required: true,
            placeholder: 'What is your reason for reassigning this request to dispatch?',
          },
        },
      })
      .afterClosed()
      .toPromise()
      .then(async (answer) => {
        if (answer) {
          this.shownRequest.workspace = { id: 2, name: 'Dispatch' };
          this.snackbar.open('Request successfully transferred back to dispatch.');
          this.requestService.transferToDispatch(this.shownRequest.id, answer).subscribe();
        }
      });
  }
}
