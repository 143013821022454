import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceResponse, WeeklyFocus } from '../types';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class WeeklyFocusService {
  weeklyFocus: WeeklyFocus = {
    id: 0,
    cqiTraining: null,
    creedFocus: null,
    principleOfQuality: null,
    timestamp: null,
  };
  host: string = environment.serviceHost;
  private blobsUrl = `${this.host}/api/v1/blobs`;
  storedWeeklyFocusTimestamp = null;

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService, public dialog: MatDialog) {
    this.storedWeeklyFocusTimestamp = localStorage.getItem('weekly-focus-timestamp');
  }

  public getWeeklyFocus(): Observable<WeeklyFocus | HttpErrorResponse | unknown> {
    return this.http.get(`${this.blobsUrl}?fields=id,type,data&filter=type=weekly-focus`).pipe(
      map((result: ServiceResponse) => {
        const blobs = result.data.blobs;
        const weeklyFocus: WeeklyFocus = blobs.length > 0 ? JSON.parse(blobs[0].data) : null;
        weeklyFocus.id = weeklyFocus ? blobs[0].id : 0;
        return weeklyFocus;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public muteWeeklyFocus(): void {
    if (this.weeklyFocus.timestamp) {
      localStorage.setItem('weekly-focus-timestamp', this.weeklyFocus.timestamp);
    }
    this.storedWeeklyFocusTimestamp = localStorage.getItem('weekly-focus-timestamp');
  }

  public updateWeeklyFocus(weeklyFocusId: number, weeklyFocus: WeeklyFocus): Observable<WeeklyFocus | unknown> {
    return this.http.put(`${this.blobsUrl}/${weeklyFocusId}`, { data: JSON.stringify(weeklyFocus) }).pipe(
      map((result: ServiceResponse) => {
        const blob = result.data.blob;
        const updatedweeklyFocus: WeeklyFocus = blob ? JSON.parse(blob.data) : null;
        updatedweeklyFocus.id = updatedweeklyFocus ? blob.id : 0;
        return updatedweeklyFocus;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
