import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleErrorService } from 'src/app/services/handle-error.service';
import { Form, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {}

  host: string = environment.serviceHost;
  formUrl = `${this.host}/api/v1/forms`;
  formTemplateUrl = `${this.host}/api/v1/form-templates`;

  formFields = `name,content,form_template_id,parent_type_id,parent_id,is_multi_submission`;

  getFormById(formId: number): Observable<Form> {
    return this.http
      .get(`${this.formUrl}/${formId}?fields=name,content,is_multi_submission,parent_type_id,parent_id`)
      .pipe(
        map((result: ServiceResponse) => {
          const form: Form = result.data.form[0];
          return form;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getFormTemplateById(formTemplateId: number): Observable<any> {
    return this.http.get(`${this.formTemplateUrl}/${formTemplateId}?fields=name,content,is_multi_submission`).pipe(
      map((result: ServiceResponse) => {
        const formTemplate: any = result.data['form template'][0];
        return formTemplate;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getFormsByParentId(parentTypeId, parentId): Observable<Form[]> {
    return this.http
      .get(
        `${this.formUrl}?filter=parent_type_id=${parentTypeId},parent_id=${parentId}&fields=name,content,form_template_id`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const forms: Form[] = result.data.forms;
          return forms;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getFormsByTemplateIdAndParentId(templateId, parentTypeId, parentId): Observable<Form[]> {
    return this.http
      .get(
        `${this.formUrl}?filter=form_template_id=${templateId},parent_type_id=${parentTypeId},parent_id=${parentId}&fields=${this.formFields}&order=asc`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const forms: Form[] = result.data.forms;
          return forms;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getOptions(url: string) {
    return this.http.get(`${this.host}${url}`).pipe(
      map((result: ServiceResponse) => {
        const keys = Object.keys(result.data);
        if (keys && keys.length > 0) {
          const options = result.data[keys[0]];
          return options;
        } else {
          return null;
        }
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateParent(url, parent) {
    return this.http.put(`${this.host}${url}`, parent).pipe(
      map((result: ServiceResponse) => {
        const form = result.data.form;
        return form;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createParent(url, parent) {
    return this.http.post(`${this.host}${url}`, parent).pipe(
      map((result: ServiceResponse) => {
        const form = result.data.form;
        return form;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getUSStates() {
    return [
      {
        name: 'Alabama',
        abbreviation: 'AL',
      },
      {
        name: 'Alaska',
        abbreviation: 'AK',
      },
      {
        name: 'Arizona',
        abbreviation: 'AZ',
      },
      {
        name: 'Arkansas',
        abbreviation: 'AR',
      },
      {
        name: 'California',
        abbreviation: 'CA',
      },
      {
        name: 'Colorado',
        abbreviation: 'CO',
      },
      {
        name: 'Connecticut',
        abbreviation: 'CT',
      },
      {
        name: 'Delaware',
        abbreviation: 'DE',
      },
      {
        name: 'Florida',
        abbreviation: 'FL',
      },
      {
        name: 'Georgia',
        abbreviation: 'GA',
      },
      {
        name: 'Hawaii',
        abbreviation: 'HI',
      },
      {
        name: 'Idaho',
        abbreviation: 'ID',
      },
      {
        name: 'Illinois',
        abbreviation: 'IL',
      },
      {
        name: 'Indiana',
        abbreviation: 'IN',
      },
      {
        name: 'Iowa',
        abbreviation: 'IA',
      },
      {
        name: 'Kansas',
        abbreviation: 'KS',
      },
      {
        name: 'Kentucky',
        abbreviation: 'KY',
      },
      {
        name: 'Louisiana',
        abbreviation: 'LA',
      },
      {
        name: 'Maine',
        abbreviation: 'ME',
      },
      {
        name: 'Maryland',
        abbreviation: 'MD',
      },
      {
        name: 'Massachusetts',
        abbreviation: 'MA',
      },
      {
        name: 'Michigan',
        abbreviation: 'MI',
      },
      {
        name: 'Minnesota',
        abbreviation: 'MN',
      },
      {
        name: 'Mississippi',
        abbreviation: 'MS',
      },
      {
        name: 'Missouri',
        abbreviation: 'MO',
      },
      {
        name: 'Montana',
        abbreviation: 'MT',
      },
      {
        name: 'Nebraska',
        abbreviation: 'NE',
      },
      {
        name: 'Nevada',
        abbreviation: 'NV',
      },
      {
        name: 'New Hampshire',
        abbreviation: 'NH',
      },
      {
        name: 'New Jersey',
        abbreviation: 'NJ',
      },
      {
        name: 'New Mexico',
        abbreviation: 'NM',
      },
      {
        name: 'New York',
        abbreviation: 'NY',
      },
      {
        name: 'North Carolina',
        abbreviation: 'NC',
      },
      {
        name: 'North Dakota',
        abbreviation: 'ND',
      },
      {
        name: 'Ohio',
        abbreviation: 'OH',
      },
      {
        name: 'Oklahoma',
        abbreviation: 'OK',
      },
      {
        name: 'Oregon',
        abbreviation: 'OR',
      },
      {
        name: 'Pennsylvania',
        abbreviation: 'PA',
      },
      {
        name: 'Rhode Island',
        abbreviation: 'RI',
      },
      {
        name: 'South Carolina',
        abbreviation: 'SC',
      },
      {
        name: 'South Dakota',
        abbreviation: 'SD',
      },
      {
        name: 'Tennessee',
        abbreviation: 'TN',
      },
      {
        name: 'Texas',
        abbreviation: 'TX',
      },
      {
        name: 'Utah',
        abbreviation: 'UT',
      },
      {
        name: 'Vermont',
        abbreviation: 'VT',
      },
      {
        name: 'Virginia',
        abbreviation: 'VA',
      },
      {
        name: 'Washington',
        abbreviation: 'WA',
      },
      {
        name: 'West Virginia',
        abbreviation: 'WV',
      },
      {
        name: 'Wisconsin',
        abbreviation: 'WI',
      },
      {
        name: 'Wyoming',
        abbreviation: 'WY',
      },
    ];
  }

  createForm(form) {
    form.content = JSON.stringify(form.content);
    return this.http.post(`${this.formUrl}`, form).pipe(
      map((result: ServiceResponse) => {
        const createdForm = result.data.form;
        return createdForm;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createFormFromTemplate(templateId, parentTypeId, parentId) {
    const form = {
      form_template_id: templateId,
      parent_type_id: parentTypeId,
      parent_id: parentId,
    };
    return this.http.post(`${this.formUrl}/create-from-template`, form).pipe(
      map((result: ServiceResponse) => {
        const createdForm = result.data.form;
        return createdForm;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateForm(formId: number, form) {
    form.content = JSON.stringify(form.content);
    return this.http.put(`${this.formUrl}/${formId}`, form).pipe(
      map((result: ServiceResponse) => {
        const updatedForm = result.data.form;
        return updatedForm;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createFormSubmission(formId: number, submission) {
    submission.content = JSON.stringify(submission.content);
    return this.http.post(`${this.formUrl}/${formId}/submissions`, submission).pipe(
      map((result: ServiceResponse) => {
        const createdSubmission = result.data.submission;
        return createdSubmission;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateFormSubmission(submissionId: number, submission) {
    submission.content = JSON.stringify(submission.content);
    return this.http.put(`${this.formUrl}/submissions/${submissionId}`, submission).pipe(
      map((result: ServiceResponse) => {
        const updatedSubmission = result.data.submission;
        return updatedSubmission;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSubmissionsByFormId(formId: number): Observable<any[]> {
    return this.http.get(`${this.formUrl}/submissions?filter=form_id=${formId}&fields=content`).pipe(
      map((result: ServiceResponse) => {
        const submissions: any[] = result.data.form_submissions;
        return submissions;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSubmissionById(submissionId: number): Observable<any> {
    return this.http.get(`${this.formUrl}/submissions/${submissionId}?fields=content`).pipe(
      map((result: ServiceResponse) => {
        const submission = result.data.submission;
        return submission;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
