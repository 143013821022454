import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ProjectService, FileService } from 'src/app/services';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';

@Component({
  selector: 'app-new-alt-bid-dialog',
  templateUrl: './new-alt-bid-dialog.component.html',
  styleUrls: ['./new-alt-bid-dialog.component.scss'],
})
export class NewAltBidDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<NewAltBidDialogComponent>,
    private projectService: ProjectService,
    private fileService: FileService,
    private snackbar: MatSnackBar
  ) {}

  draftAlt: any = {};

  ngOnInit() {}

  openUploadModal() {
    const maxFiles = 1;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.data.project_id,
          allowComment: false,
          // additionalParents,
          maxFiles,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          resultData.forEach((file) => (this.draftAlt.file = { id: file.file_id, name: file.name }));
        }
      });
  }

  removeFileFromBidDetails() {
    this.draftAlt.file = null;
    this.snackbar.open(`File removed!`);
  }

  async addAlt() {
    const bidToCreate = {
      bid_package_id: this.data.bidPackage_id,
      type_id: 2,
      company_id: this.data.company_id,
      // contact_name: this.draftAlt.contact,
      description: this.draftAlt.description,
      // datetime_received: `${moment(this.draftAlt.date_received).format('YYYY-MM-DD')} ${this.draftAlt.time_received}`,
      number: this.draftAlt.number ? this.draftAlt.number.toString() : null,
      amount: (this.draftAlt.amount_type === 'reduction' ? -1 : 1) * this.draftAlt.amount,
      is_selected: 0,
    };

    const createdAlt = await this.projectService.createBid(bidToCreate, this.data.bidFields).toPromise();

    if (this.draftAlt.file) {
      await this.fileService.linkFile(+this.draftAlt.file.id, createdAlt.id, ResourceType.Bid).toPromise();
    }
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
