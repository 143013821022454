import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService } from 'src/app/services';
import { ContinuousQualityImprovement, ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContinuousQualityImprovementService {
  host: string = environment.serviceHost;
  continuousQualityImprovementURL = `${this.host}/api/v1/continuous-quality-improvement`;
  continuousQualityImprovementFields = 'id';

  constructor(private http: HttpClient, private apiFilterService: ApiFilterService, private snackbar: MatSnackBar) {}

  createContinuousQualityImprovement(
    continuousQualityImprovement: ContinuousQualityImprovement
  ): Observable<ContinuousQualityImprovement> {
    return this.http
      .post(
        `${this.continuousQualityImprovementURL}?fields=${this.continuousQualityImprovementFields}`,
        continuousQualityImprovement
      )
      .pipe(
        map((result: ServiceResponse): ContinuousQualityImprovement | unknown => {
          return result.data.continuousQualityImprovement;
        }),
        catchError(() => {
          const errorSnack = this.snackbar.open(
            'An error occurred. Please email 1call@uhat.org for support.',
            'Close',
            {
              duration: undefined,
            }
          );
          errorSnack.onAction().subscribe(() => {
            this.snackbar.dismiss();
          });
          throw new Error('Error creating the  continuous quality improvement');
        })
      );
  }
}
