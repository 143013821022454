import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArHistory, User } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UhatFileBridgeLink } from '../../models';

@Component({
  selector: 'app-ar-manager-dialog',
  templateUrl: './ar-manager-dialog.component.html',
  styleUrls: ['./ar-manager-dialog.component.scss'],
})
export class ARManagerDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ARManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackbar: MatSnackBar
  ) {}

  historyUser: User;
  history: ArHistory[];

  get userFullName() {
    return this.historyUser ? `${this.historyUser.first_name} ${this.historyUser.last_name}` : '';
  }

  async ngOnInit() {
    this.history = this.data.history;
    this.historyUser = this.data.user;

    if (!this.historyUser || !this.history) {
      this.snackbar.open('An error occurred loading the history data.');
      this.cancel();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
