<button
  *ngIf="amFollowing === false"
  mat-stroked-button
  color="accent"
  (click)="followProject('Follow')"
  [disabled]="project?.status_id === ProjectStatus.CLOSED"
  class="lh-24 px-2 d-inline-block align-middle"
>
  Follow
</button>
<button
  *ngIf="amFollowing === true"
  mat-stroked-button
  color="accent"
  (click)="followProject('Unfollow')"
  class="lh-24 px-2 d-inline-block align-middle"
>
  Unfollow
</button>
<span
  *ngIf="project?.followers?.length > 0"
  class="ml-3 pointer d-inline-block align-middle"
  [matMenuTriggerFor]="follower_menu"
>
  <app-profile-thumbnail
    *ngFor="let f of project?.followers | slice: 0:5"
    [noBorder]="false"
    class="follower d-inline-block align-middle"
    [height]="26"
    [width]="26"
    [userId]="f.id"
  ></app-profile-thumbnail>
</span>
<span *ngIf="project?.followers?.length > 5" class="follower-count pointer" [matMenuTriggerFor]="follower_menu">
  +{{ project?.followers?.length - 5 }}
</span>
<mat-menu #follower_menu="matMenu">
  <button (click)="openUserProfilePreview(f.id)" *ngFor="let f of project?.followers" mat-menu-item>
    <app-profile-thumbnail
      [noBorder]="true"
      class="d-inline-block align-middle mr-3"
      [height]="26"
      [width]="26"
      [userId]="f.id"
    ></app-profile-thumbnail>
    <span class="d-inline-block align-middle">{{ f.first_name }} {{ f.last_name }}</span>
  </button>
  <button [disabled]="project?.id === 4" mat-menu-item (click)="editFollowers()">
    <mat-icon>group_add</mat-icon>
    <span>Manage Followers</span>
  </button>
</mat-menu>
