<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div id="work-order-update-dialog" data-test-id="work-order-update-dialog" class="work-order-update-dialog">
  <form mat-dialog-content [formGroup]="workOrderUpdateFormGroup">
    <div *ngIf="workOrderHealthTypes.length > 0 && isWorkspaceStaff" class="row mb-4">
      <div class="col">
        <h6 class="font-weight-semibold dkblue mb-3">Update Health</h6>
        <div class="row m-0 align-items-center">
          <div class="col-3 pl-0 pr-0" *ngFor="let workOrderHealthType of workOrderHealthTypes">
            <div
              matRipple
              class="p-2 pointer item item-last text-center br-5"
              [ngClass]="{ 'selected-health': workOrderHealthTypeId === workOrderHealthType.id }"
              (click)="selectHealth(workOrderHealthType.id)"
            >
              <div class="row">
                <div class="col">
                  <mat-icon
                    class="d-inline-block align-middle mr-2"
                    [ngClass]="{
                      ltgray: workOrderHealthType.id === null,
                      green: workOrderHealthType.id === 1,
                      orange: workOrderHealthType.id === 2,
                      red: workOrderHealthType.id === 3
                    }"
                  >
                    {{ workOrderHealthTypeId === workOrderHealthType.id ? 'check_circle' : 'lens' }}
                  </mat-icon>
                  <h6
                    class="m-0 gray d-inline-block align-middle font-weight-semibold"
                    [ngClass]="{
                      ltgray: workOrderHealthType.id === null,
                      green: workOrderHealthType.id === 1,
                      orange: workOrderHealthType.id === 2,
                      red: workOrderHealthType.id === 3
                    }"
                  >
                    {{ workOrderHealthType.name }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex flex-column">
        <div class="row mb-1">
          <div class="col update-textarea">
            <h6 class="dkblue font-weight-semibold mb-3">Update Message</h6>
            <app-editor
              [auto_focus]="auto_focus"
              #editor
              placeholder="How's this work order going?"
              required_content="Message is required"
            ></app-editor>
          </div>
        </div>
        <app-aidet class="mb-3" class="full-width"></app-aidet>
        <div class="row mt-3 mb-4">
          <div class="col update-textarea">
            <mat-chip-list
              *ngIf="!loadingLinkedWorkOrderTask; else attachFilesSpinner"
              class="d-inline-block align-middle px-1"
            >
              <button
                mat-stroked-button
                type="button"
                color="accent"
                class="mr-3 pl-2 pr-3"
                (click)="openUploadModal()"
              >
                <mat-icon>attach_file</mat-icon>
                <span *ngIf="existingFiles?.length === 0"> Attach Files </span>
              </button>
              <app-file-chip
                *ngFor="let f of existingFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeExistingFile(f)"
              ></app-file-chip>
              <app-file-chip
                *ngFor="let f of newFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeNewFile(f)"
              ></app-file-chip>
              <app-file-chip
                *ngFor="let f of linkedProjectFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeLinkedProjectFile(f)"
              ></app-file-chip>
            </mat-chip-list>
            <ng-template #attachFilesSpinner>
              <mat-spinner color="accent" [diameter]="30"></mat-spinner>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-3" *ngIf="!workOrderUpdateId && isWorkspaceStaff">
      <mat-radio-group class="col" formControlName="everyoneVisibility">
        <h6 class="font-weight-semibold dkblue mb-2">Update Visible To*</h6>
        <mat-radio-button class="mr-4 work-order-update-checkbox-item" [value]="1">
          <span class="dkblue">Everyone</span>
        </mat-radio-button>
        <mat-radio-button class="work-order-update-checkbox-item" [value]="0">
          <span class="dkblue">Staff Only</span>
        </mat-radio-button>
      </mat-radio-group>
      <mat-radio-group class="col" formControlName="notify_followers">
        <h6 class="font-weight-semibold dkblue mb-2">Send Notifications</h6>
        <mat-radio-button class="mr-4 work-order-update-checkbox-item" [value]="1">
          <span class="dkblue">Send</span>
        </mat-radio-button>
        <mat-radio-button class="work-order-update-checkbox-item" [value]="0">
          <span class="dkblue">Don't Send</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="d-flex flex-column" *ngIf="!workOrderUpdateId && isWorkspaceStaff && workOrderUsersToNotify?.length">
      <p class="dkgray mb-1">This update will notify:</p>
      <div class="d-flex align-items-top">
        <p class="dkblue m-0 mr-2">
          <span *ngFor="let user of workOrderUsersToNotify; let index = index; let last = last"
            >{{ index > 0 ? (last ? (index > 1 ? ', and ' : ' and ') : ', ') : '' }}{{ user?.first_name }}
            {{ user?.last_name }}</span
          >
        </p>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="row pt-5 pb-3 align-items-center">
    <div class="col">
      <button
        data-test-id="create-meeting-dialog-cancel-button"
        mat-button
        class="gray full-width"
        type="button"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
    <div class="col text-right">
      <button
        mat-flat-button
        *ngIf="!isLoading"
        class="full-width"
        color="success"
        type="submit"
        (click)="submit()"
        [disabled]="!validForSubmission"
      >
        <span *ngIf="!isUpdating">Save</span><span *ngIf="isUpdating">Update</span>
      </button>
      <button mat-flat-button *ngIf="isLoading" class="full-width" color="success" type="button">
        <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
      </button>
    </div>
  </div>
</div>
