import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { HandleErrorService } from 'src/app/services';
import { ProjectProgramData, ProjectProgramDataDbModel } from 'src/app/workspaces/construction/models';
import { ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ProgramDataService {
  private host: string = environment.serviceHost;

  public readonly PROGRAM_DATA_URL = `${this.host}/api/v1/program-data`;

  private readonly PROGRAM_DATA_FIELDS_ARRAY = [
    'id',
    'project_id',
    'is_new_space',
    'is_clinical_space',
    'contains_biohazard_materials',
    'needs_healthcare_supplies_install',
    'requires_emergency_eyewash',
    'requires_emergency_power',
    'requires_access_controls',
    'requires_digital_signage',
    'requires_other_signage',
    'requires_accent_walls',
    'are_strobes_affected',
    'are_sprinkler_heads_affected',
    'is_flooring_to_be_replaced',
    'contains_asbestos',
    'requires_structural_engineer',
    'required_items',
    'attendees',
    'technology_checkboxes',
    'technology_note',
    'door_type_checkboxes',
    'door_type_note',
    'contains_asbestos_note',
    'requires_structural_engineer_note',
    'requires_emergency_power_note',
    'requires_access_controls_note',
    'requires_digital_signage_note',
    'requires_other_signage_note',
    'requires_accent_walls_note',
    'contains_biohazard_materials_note',
    'needs_healthcare_supplies_note',
    'requires_emergency_eyewash_note',
    'technology_locations',
    'flooring_replacement_extent_note',
  ];

  public readonly PROGRAM_DATA_FIELDS;

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {
    this.PROGRAM_DATA_FIELDS = this.PROGRAM_DATA_FIELDS_ARRAY.join(',');
  }

  public getDataByProjectId(projectId: number): Observable<ProjectProgramData> {
    return this.http
      .get(`${this.PROGRAM_DATA_URL}?filter=project_id=${projectId}&fields=${this.PROGRAM_DATA_FIELDS}`)
      .pipe(
        map((result: ServiceResponse) => {
          const data = result.data.program_data[0];
          return data ? ProjectProgramData.fromDatabaseModel(data) : null;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public updateData(data: ProjectProgramData): Observable<ProjectProgramDataDbModel> {
    const dbData = ProjectProgramDataDbModel.fromClientObjectModel(data);
    const sendData = {
      is_new_space: dbData.is_new_space ? 1 : 0,
      is_clinical_space: dbData.is_clinical_space ? 1 : 0,
      contains_biohazard_materials: dbData.contains_biohazard_materials ? 1 : 0,
      needs_healthcare_supplies_install: dbData.needs_healthcare_supplies_install ? 1 : 0,
      requires_emergency_eyewash: dbData.requires_emergency_eyewash ? 1 : 0,
      requires_emergency_power: dbData.requires_emergency_power ? 1 : 0,
      requires_access_controls: dbData.requires_access_controls ? 1 : 0,
      requires_digital_signage: dbData.requires_digital_signage ? 1 : 0,
      requires_other_signage: dbData.requires_other_signage ? 1 : 0,
      requires_accent_walls: dbData.requires_accent_walls ? 1 : 0,
      requires_structural_engineer: dbData.requires_structural_engineer ? 1 : 0,
      are_strobes_affected: dbData.are_strobes_affected ? 1 : 0,
      is_flooring_to_be_replaced: dbData.is_flooring_to_be_replaced ? 1 : 0,
      are_sprinkler_heads_affected: dbData.are_sprinkler_heads_affected ? 1 : 0,
      contains_asbestos: dbData.contains_asbestos ? 1 : 0,
      attendees: dbData.attendees,
      required_items: dbData.required_items,
      door_type_checkboxes: dbData.door_type_checkboxes,
      door_type_note: dbData.door_type_note,
      technology_checkboxes: dbData.technology_checkboxes,
      technology_note: dbData.technology_note,
      technology_locations: dbData.technology_locations,
      contains_asbestos_note: dbData.contains_asbestos_note,
      requires_structural_engineer_note: dbData.requires_structural_engineer_note,
      requires_emergency_power_note: dbData.requires_emergency_power_note,
      requires_access_controls_note: dbData.requires_access_controls_note,
      requires_digital_signage_note: dbData.requires_digital_signage_note,
      requires_other_signage_note: dbData.requires_other_signage_note,
      requires_accent_walls_note: dbData.requires_accent_walls_note,
      contains_biohazard_materials_note: dbData.contains_biohazard_materials_note,
      needs_healthcare_supplies_note: dbData.needs_healthcare_supplies_note,
      requires_emergency_eyewash_note: dbData.requires_emergency_eyewash_note,
      flooring_replacement_extent_note: dbData.flooring_replacement_extent_note,
    };
    return this.http.put(`${this.PROGRAM_DATA_URL}/${data.id}`, sendData).pipe(
      map((result: ServiceResponse) => {
        return result.data.programdata;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public createDataForProject(projectId: number): Observable<ProjectProgramData> {
    const data = {
      project_id: parseInt(projectId.toString(), 10),
    };
    data.project_id = projectId;
    return this.http.post(`${this.PROGRAM_DATA_URL}?fields=${this.PROGRAM_DATA_FIELDS}`, data).pipe(
      map((result: ServiceResponse) => {
        const returnedData = result.data.programdata;
        return returnedData ? ProjectProgramData.fromDatabaseModel(returnedData) : null;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
