<app-base-dialog title="Tasks" (closeDialog)="cancel()"></app-base-dialog>
<div class="project-modal modal-wide p-0">
  <div class="row align-items-center mt-4 mb-4" *ngIf="!data?.dontFilterTasks">
    <div class="col-auto border-right">
      <button
        mat-button
        [matMenuTriggerFor]="byMenu"
        class="pl-2 pr-1"
        color="accent"
        type="button"
        style="line-height: 26px"
      >
        <span>
          {{ data.selectedUser || data.showUsers ? 'Task Assignee' : 'Task Status' }}
        </span>
        <mat-icon> arrow_drop_down </mat-icon>
      </button>
      <mat-menu #byMenu="matMenu">
        <button mat-menu-item (click)="data.showUsers = false; data.selectedUser = null; filterTasks('To-Do')">
          <mat-icon>check_circle</mat-icon>
          <span>Status</span>
        </button>
        <button mat-menu-item (click)="data.showUsers = true">
          <mat-icon>person</mat-icon>
          <span>Assignee</span>
        </button>
      </mat-menu>
    </div>
    <!-- FILTERS FOR STATUS OR ASSIGNEE -->
    <div class="col">
      <!-- FILTERS FOR STATUS -->
      <div *ngIf="data.showUsers === false && !data.selectedUser">
        <button
          (click)="filterTasks('To-Do')"
          mat-button
          class="mr-2 pl-2 pr-2 gray"
          [ngClass]="{ 'bg-gray white': selectedStatus == 'To-Do' }"
          type="button"
          style="line-height: 26px"
          [class.mat-flat-button]="selectedStatus == 'To-Do'"
        >
          To-Do
        </button>
        <button
          (click)="filterTasks('Coming Due')"
          mat-button
          class="mr-2 pl-2 pr-2 orange"
          [ngClass]="{ 'bg-orange white': selectedStatus == 'Coming Due' }"
          type="button"
          style="line-height: 26px"
          [class.mat-flat-button]="selectedStatus == 'Coming Due'"
        >
          Coming Due
        </button>
        <button
          (click)="filterTasks('Overdue')"
          mat-button
          class="mr-2 pl-2 pr-2"
          color="warn"
          type="button"
          style="line-height: 26px"
          [class.mat-flat-button]="selectedStatus == 'Overdue'"
        >
          Overdue
        </button>
        <button
          (click)="filterTasks('Complete')"
          mat-button
          class="mr-2 pl-2 pr-2 green"
          type="button"
          style="line-height: 26px"
          [ngClass]="{ 'white bg-green': selectedStatus == 'Complete' }"
        >
          Complete
        </button>
      </div>

      <!-- FILTERS FOR ASSIGNEE -->
      <div *ngIf="data.showUsers === true || data.selectedUser">
        <button
          mat-button
          [class.mat-flat-button]="data.taskUserFilter == 'staff'"
          class="mr-2 pl-2 pr-2"
          color="accent"
          type="button"
          style="line-height: 26px"
          (click)="viewUsers('staff')"
        >
          Staff
        </button>
        <button
          mat-button
          [class.mat-flat-button]="data.taskUserFilter == 'tenants'"
          class="mr-2 pl-2 pr-2"
          color="accent"
          type="button"
          style="line-height: 26px"
          (click)="viewUsers('tenants')"
        >
          Tenants
        </button>
        <button
          mat-button
          [class.mat-flat-button]="data.taskUserFilter == 'vendors'"
          class="mr-2 pl-2 pr-2"
          color="accent"
          type="button"
          style="line-height: 26px"
          (click)="viewUsers('vendors')"
        >
          Suppliers
        </button>
        <button
          mat-button
          [class.mat-flat-button]="data.taskUserFilter == 'allUsers'"
          class="mr-2 pl-2 pr-2"
          color="accent"
          type="button"
          style="line-height: 26px"
          (click)="viewUsers('allUsers')"
        >
          All
        </button>
      </div>
    </div>
    <!-- IF SPECIFIC USER IS SELECTED -->
    <div class="col-auto text-right pl-0">
      <button
        *ngIf="!data.selectedUser && data.showUsers == false"
        (click)="data.mine = false; filterTasks(null)"
        mat-button
        [class.mat-flat-button]="data.mine === false"
        class="ml-2 pl-2 pr-2"
        color="accent"
        type="button"
        style="line-height: 26px"
      >
        All
      </button>
      <button
        *ngIf="!data.selectedUser && data.showUsers == false"
        (click)="data.mine = true; filterTasks(null)"
        mat-button
        [class.mat-flat-button]="data.mine === true"
        class="ml-2 pl-2 pr-2"
        color="accent"
        type="button"
        style="line-height: 26px"
      >
        Mine
      </button>
      <button
        *ngIf="data.selectedUser"
        mat-button
        class="pl-3 pr-2 bg-ltltblue ltgray"
        color="accent"
        type="button"
        style="line-height: 26px"
      >
        <span class="mr-2 ltblue"> {{ data.selectedUser.first_name }} {{ data.selectedUser.last_name }} </span>
        <mat-icon
          (click)="data.selectedUser = null; filterTasks(null); data.showUsers = true"
          style="height: 18px; width: 18px; font-size: 17px"
        >
          cancel
        </mat-icon>
      </button>
    </div>
  </div>

  <div class="row pb-4 mb-2">
    <div class="col">
      <div class="m-portlet m-0">
        <div *ngIf="!data.showUsers">
          <div
            class="m-portlet__body item p-0 p-2 pointer"
            [ngClass]="{ 'item-last': last }"
            *ngFor="let task of data.dontFilterTasks ? data.tasks : filteredTasks; let last = last"
            (click)="viewTask(task.id)"
          >
            <div class="row align-items-center">
              <div class="col-auto pr-2">
                <app-task-status-icon [task]="task"> </app-task-status-icon>
              </div>
              <div class="col-auto task-info pl-0 pr-2">
                <app-profile-thumbnail
                  class="d-inline-block align-middle pointer"
                  matTooltip="{{ task.assigned_user_first_name }} {{ task.assigned_user_last_name }}"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  [width]="36"
                  [height]="36"
                  [userId]="task.assigned_user_id"
                >
                </app-profile-thumbnail>
              </div>
              <div class="col pointer pl-2">
                <div class="task-description d-inline-block align-middle">
                  <h5 class="full-width mb-0 dkblue font-weight-semibold" style="margin-top: -2px">
                    <span>
                      {{ task.title }}
                    </span>
                  </h5>
                </div>
              </div>
              <div class="col-auto text-right">
                <p class="full-width m-0 font-weight-semibold">
                  <span class="green" *ngIf="task.status_name == 'Complete'"> Complete </span>
                  <span
                    [ngClass]="{
                      ltblue: daysUntilDueDate(task.due_date) > 0,
                      red: daysUntilDueDate(task.due_date) < 0,
                      orange: daysUntilDueDate(task.due_date) < 8 && daysUntilDueDate(task.due_date) > 0
                    }"
                    *ngIf="daysUntilDueDate(task.due_date) !== 0 && task.status_name != 'Complete'"
                  >
                    {{ getDueDateFormattedText(task.due_date) }}
                  </span>
                  <span
                    class="ltgray"
                    *ngIf="!task.due_date && !daysUntilDueDate(task.due_date) && task.status_name != 'Complete'"
                  >
                    No Due Date
                  </span>
                  <span class="red" *ngIf="daysUntilDueDate(task.due_date) == 0 && task.status_name != 'Complete'">
                    Due Today
                  </span>
                </p>
              </div>
              <div class="col-auto text-right pl-0">
                <mat-icon class="ltblue d-inline-block align-middle">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data.showUsers === true">
          <div
            *ngFor="let user of data.users[data.taskUserFilter]"
            (click)="data.selectedUser = user; data.showUsers = false; data.mine = false; filterTasks(null)"
            class="m-portlet__body pointer item p-0 p-2 pl-3 pr-3"
          >
            <div class="row align-items-center">
              <div class="col-auto task-info pr-2">
                <app-profile-thumbnail
                  class="d-inline-block align-middle pointer"
                  [width]="32"
                  [height]="32"
                  [userId]="user.id"
                >
                </app-profile-thumbnail>
              </div>
              <div class="col pointer pl-2">
                <div class="task-description d-inline-block align-middle">
                  <h6 class="full-width mb-0 ltblue font-weight-semibold" style="margin-top: -2px">
                    <span> {{ user.first_name }} {{ user.last_name }} </span>
                    <!-- <small class="ml-2 dkgray">
                      Project Role or Company
                    </small> -->
                  </h6>
                </div>
              </div>
              <div class="col-auto text-right pl-0">
                <h6 class="full-width m-0 dkblue font-weight-semibold d-inline-block align-middle">
                  {{ user.task_count }}
                </h6>
              </div>
              <div class="col-auto text-right pl-0">
                <mat-icon class="ltblue d-inline-block align-middle">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
