<app-base-dialog [title]="action + ' Milestone'" (closeDialog)="close()"></app-base-dialog>
<div class="milestone-dialog" style="min-width: auto">
  <form [formGroup]="milestoneFormGroup">
    <div mat-dialog-content>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 font-weight-semibold dkblue">Milestone Name</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput required="true" type="text" formControlName="name" />
            <mat-error *ngIf="name.errors?.required">Name is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 font-weight-semibold dkblue">Start Date</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              (click)="startDatePicker.open()"
              [matDatepicker]="startDatePicker"
              autocomplete="off"
              formControlName="start_date"
            />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <!-- <mat-error *ngIf="start_date.errors?.required">Start date is required</mat-error> -->
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 font-weight-semibold dkblue">End Date</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              (click)="endDatePicker.open()"
              [matDatepicker]="endDatePicker"
              autocomplete="off"
              formControlName="end_date"
            />
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="milestoneFormGroup.hasError('invalidDates')"
              >End Date cannot be before Start Date</mat-error
            >
            <!-- <mat-error *ngIf="end_date.errors?.required">End date is required</mat-error> -->
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="action === 'Edit'" class="row my-4">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <h6 class="dkblue m-0 font-weight-semibold mr-auto">Set progress manually</h6>
            <mat-slide-toggle color="accent" formControlName="manual_progress"> </mat-slide-toggle>
          </div>
          <mat-slider
            color="primary"
            *ngIf="manual_progress?.value"
            class="full-width p-0"
            thumbLabel
            [min]="minValue"
            [max]="maxValue"
            [step]="step"
            formControlName="progress"
          >
          </mat-slider>
        </div>
      </div>
      <div *ngIf="milestone.id">
        <div class="row my-4">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <h6 class="dkblue m-0 font-weight-semibold mr-auto">Set Milestone Task due dates to chosen end date</h6>
              <mat-slide-toggle color="accent" formControlName="assign_end_date_to_tasks"> </mat-slide-toggle>
            </div>
            <!-- <mat-checkbox color="primary" formControlName="assign_end_date_to_tasks" class="dkblue">
              Set Milestone Task due dates to chosen end date
            </mat-checkbox> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 align-items-center">
      <div class="col">
        <button mat-button (click)="close()" class="gray full-width">Cancel</button>
      </div>
      <div class="col text-right">
        <button mat-flat-button color="success" class="full-width" (click)="submit()">Save</button>
      </div>
    </div>
  </form>
</div>
