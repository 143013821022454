import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VerifiedFile } from '../../models';
import { FileService, ModalService, ProgressIndicatorService } from '../../services';
import { UhatFileReference } from '../../types';
import { ResourceType } from '../../enums';

@Component({
  selector: 'app-file-upload-zone',
  templateUrl: './file-upload-zone.component.html',
  styleUrls: ['./file-upload-zone.component.scss'],
})
export class FileUploadZoneComponent implements OnInit {
  @Input() height = '180px';
  @Input() maxFiles = 10;
  @Input() parentResourceId: number;
  @Input() parentResourceType: ResourceType;
  @Input() projectParent: number;
  @Input() uploadComputerFilesOnly = false;
  @Input() taskId: number;
  @Input() filteredFiles: UhatFileReference[] = [];
  @Output() public filesUploaded = new EventEmitter<{
    computerFiles?: VerifiedFile[];
    linkedFiles?: UhatFileReference[];
  }>();

  constructor(
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  public computerFiles: VerifiedFile[] = [];
  public linkedFiles: UhatFileReference[] = [];

  ngOnInit(): void {
    if (this.parentResourceType === ResourceType.Project && !this.projectParent) {
      this.projectParent = this.parentResourceId;
    }
  }

  async uploadMultipleFiles(files) {
    for (const selectedFile of files) {
      this.computerFiles.push(
        new VerifiedFile(selectedFile, this.fileService, this.parentResourceId, this.parentResourceType)
      );
    }

    this.filesUploaded.emit({ computerFiles: this.computerFiles });

    this.computerFiles = [];
  }

  public openFileSelectDialog(isProject = true) {
    const data: any = {
      title: 'Select Files To Attach',
      filterFiles: [...this.linkedFiles, ...this.filteredFiles],
      maxFiles: this.maxFiles,
    };

    if (isProject) {
      data.projectId = this.projectParent;
    } else {
      data.taskId = this.taskId;
    }

    this.modalService
      .openFileSelectDialog(data)
      .toPromise()
      .then((res) => {
        if (res?.length) {
          this.linkedFiles = [...this.linkedFiles, ...res];
          this.filesUploaded.emit({ linkedFiles: res });
        }
      });
  }
}
