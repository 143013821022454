import { Component, Input, OnInit } from '@angular/core';
import { ReassignmentActivity } from '../../types';

@Component({
  selector: 'app-dispatch-activities',
  templateUrl: './dispatch-activities.component.html',
  styleUrls: ['./dispatch-activities.component.scss'],
})
export class DispatchActivitiesComponent implements OnInit {
  @Input() activity: ReassignmentActivity[] = [];

  constructor() {}

  ngOnInit(): void {}

  get reassignmentActivity(): ReassignmentActivity[] {
    return this.activity;
  }

  get transferActivity(): ReassignmentActivity[] {
    return this.activity?.filter((item) => item.previous_workspace_id);
  }
}
