<div id="location" data-test-id="location" class="location">
  <div class="row mb-4 align-items-center">
    <div class="col-6">
      <app-building
        [showLabel]="false"
        #buildings
        [buildingIds]="buildingIds"
        [customPlaceholder]="isDispatch ? 'A building is required to create item' : ''"
      ></app-building>
    </div>
    <div class="col-6">
      <app-floor [showLabel]="false" #floors></app-floor>
    </div>
  </div>
  <div class="row mb-4 align-items-center">
    <div class="col-6">
      <app-suite [showLabel]="false" #suites></app-suite>
    </div>
    <div class="col-6">
      <app-department [showLabel]="false" #departments></app-department>
    </div>
  </div>
  <div class="row" *ngIf="showLandmarkField">
    <mat-form-field appearance="outline" class="col-12">
      <input
        matInput
        type="text"
        [formControl]="landmark"
        data-test-id="landmark-input"
        placeholder="Room # or Landmark"
      />
      <mat-icon matRipple *ngIf="landmark?.value" matSuffix aria-label="Clear" (click)="clear_landmark()" class="gray"
        >cancel</mat-icon
      >
    </mat-form-field>
  </div>
  <div *ngIf="userTypeId === userTypeIds.Staff || userTypeId === userTypeIds.Tenant" class="row mb-4">
    <mat-form-field
      appearance="outline"
      class="col-12"
      *ngIf="building?.value && building?.value?.id === null && required_missing_building_name"
    >
      <mat-label>Building Name</mat-label>
      <input
        matInput
        type="text"
        required="true"
        [formControl]="missing_building_name"
        data-test-id="request-account-building-name-input"
      />
      <mat-error *ngIf="missing_building_name?.errors?.required" data-test-id="request-account-building-name-error"
        >Building Name is required</mat-error
      >
      <mat-icon
        matRipple
        *ngIf="missing_building_name?.value"
        matSuffix
        aria-label="Clear"
        (click)="clear_missing_building_name()"
        class="gray"
        >cancel</mat-icon
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col-12"
      *ngIf="floor?.value && floor.value?.id === null && required_missing_floor_name"
    >
      <mat-label>Floor Name</mat-label>
      <input
        matInput
        type="text"
        required="true"
        [formControl]="missing_floor_name"
        data-test-id="request-account-floor-name-input"
      />
      <mat-error *ngIf="missing_floor_name?.errors?.required" data-test-id="request-account-floor-name-error"
        >Floor Name is required</mat-error
      >
      <mat-icon
        matRipple
        *ngIf="missing_floor_name?.value"
        matSuffix
        aria-label="Clear"
        (click)="clear_missing_floor_name()"
        class="gray"
        >cancel</mat-icon
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col-12"
      *ngIf="suite?.value && suite?.value?.id === null && required_missing_suite_name"
    >
      <mat-label>Suite Name</mat-label>
      <input
        matInput
        type="text"
        required="true"
        [formControl]="missing_suite_name"
        data-test-id="request-account-suite-name-input"
      />
      <mat-error *ngIf="missing_suite_name?.errors?.required" data-test-id="request-account-suite-name-error"
        >Suite Name is required</mat-error
      >
      <mat-icon
        matRipple
        *ngIf="missing_suite_name?.value"
        matSuffix
        aria-label="Clear"
        (click)="clear_missing_suite_name()"
        class="gray"
        >cancel</mat-icon
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col-12"
      *ngIf="department?.value && department?.value?.id === null && required_missing_department_name"
    >
      <mat-label>Department Name</mat-label>
      <input
        matInput
        type="text"
        required="true"
        [formControl]="missing_department_name"
        data-test-id="request-account-department-name-input"
      />
      <mat-error *ngIf="missing_department_name?.errors?.required" data-test-id="request-account-department-name-error"
        >Department Name is required</mat-error
      >
      <mat-icon
        matRipple
        *ngIf="missing_department_name?.value"
        matSuffix
        aria-label="Clear"
        (click)="clear_missing_department_name()"
        class="gray"
        >cancel</mat-icon
      >
    </mat-form-field>
  </div>
</div>
