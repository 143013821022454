import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { catchError, finalize, map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AuthService, FileService, ProgressIndicatorService, ReleaseNotesService } from 'src/app/services';
import { ReleaseNoteBug, ReleaseNoteFeature, ReleaseNoteRelease } from 'src/app/types';
import { ReleaseNotesAddEditDialogComponent } from './release-notes-add-edit-dialog/release-notes-add-edit-dialog.component';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {
  public openDialog = false;
  public searchTerm = new FormControl('');
  public selectedTab = 'recent-releases';
  private releaseNoteReleasesFields = ['id', 'title', 'release_date', 'description', 'files'];
  private releaseNoteFeaturesFields = [
    'id',
    'title',
    'start_date',
    'end_date',
    'timeframe_id',
    'description',
    'files',
    'created_datetime',
  ];
  private releaseNoteBugsFields = [
    'id',
    'title',
    'description',
    'identified_date',
    'fixed_date',
    'timeframe_id',
    'files',
    'created_datetime',
  ];

  releases: ReleaseNoteRelease[] = [];
  features: ReleaseNoteFeature[] = [];
  bugs: ReleaseNoteBug[] = [];

  releaseForm = null;
  featureForm = null;
  bugForm = null;

  constructor(
    private authService: AuthService,
    private releaseNotesService: ReleaseNotesService,
    private _progressIndicatorService: ProgressIndicatorService,
    private dialog: MatDialog,
    private _fileService: FileService
  ) {}

  ngOnInit(): void {
    this.selectTab(this.selectedTab);
  }

  get isAppAdmin() {
    return this.authService.isAppAdmin;
  }

  selectTab(tab) {
    this.selectedTab = tab;
    if (tab === 'recent-releases') {
      this.getReleases();
    } else if (tab === 'planned-features') {
      this.getFeatures();
    } else if (tab === 'known-bugs') {
      this.getBugs();
    }
  }

  public clearSearchTerm() {
    this.searchTerm.reset();
  }

  async getReleases() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Loading Releases...');
    this.releases = await this.releaseNotesService.getReleaseNoteReleases(this.releaseNoteReleasesFields).toPromise();
    this._progressIndicatorService.close();
  }

  async getFeatures() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Loading Features...');
    this.features = await this.releaseNotesService.getReleaseNoteFeatures(this.releaseNoteFeaturesFields).toPromise();
    this._progressIndicatorService.close();
  }

  async getBugs() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Loading Bugs...');
    this.bugs = await this.releaseNotesService.getReleaseNoteBugs(this.releaseNoteBugsFields).toPromise();
    this._progressIndicatorService.close();
  }

  async addEditItem(form, item?) {
    this.openDialog = true;
    const dialogRef = this.dialog.open(ReleaseNotesAddEditDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        form,
        item,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.openDialog = false;
      if (res) {
        if (form === 'release') {
          this.getReleases();
        } else if (form === 'feature') {
          this.getFeatures();
        } else if (form === 'bug') {
          this.getBugs();
        }
      }
    });
  }

  async deleteRelease(id) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          headerText: `Delete Release`,
          descriptionText: 'Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this._progressIndicatorService.openAwaitIndicatorModal();
          this._progressIndicatorService.updateStatus('Deleting Release...');
          await this.releaseNotesService.deleteReleaseNoteRelease(id).toPromise();
          this.getReleases();
        }
      });
  }

  async deleteFeature(id) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          headerText: `Delete Feature`,
          descriptionText: 'Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this._progressIndicatorService.openAwaitIndicatorModal();
          this._progressIndicatorService.updateStatus('Deleting Feature...');
          await this.releaseNotesService.deleteReleaseNoteFeature(id).toPromise();
          this.getFeatures();
        }
      });
  }

  async deleteBug(id) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          headerText: `Delete Bug`,
          descriptionText: 'Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this._progressIndicatorService.openAwaitIndicatorModal();
          this._progressIndicatorService.updateStatus('Deleting Bug...');
          await this.releaseNotesService.deleteReleaseNoteBug(id).toPromise();
          this.getBugs();
        }
      });
  }

  downloadFile(file) {
    file.isDownloading = true;
    this._fileService
      .downloadFile(file)
      .pipe(
        map((result) => result),
        catchError((e) => {
          file.error = true;
          return e;
        }),
        finalize(() => {
          file.isDownloading = false;
        })
      )
      .subscribe((downloadedFileResult) => {
        saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
        file.isDownloading = false;
      });
  }
}
