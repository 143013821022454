import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import { UserType } from 'src/app/enums';
import {
  AuthService,
  ExportService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { ViewChangeOrderDialogComponent } from 'src/app/workspaces/construction/components';
import { ChangeOrder, ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-change-orders',
  templateUrl: './change-orders.component.html',
  styleUrls: ['./change-orders.component.scss'],
})
export class ChangeOrdersComponent implements OnInit {
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;
  userType = UserType;

  @Input() project: ProjectConstruction;

  constructor(
    private projectService: ProjectService,
    private ViewChangeOrderDialog: MatDialog,
    private snackbar: MatSnackBar,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService,
    private exportService: ExportService,
    private taskService: ProjectTaskService
  ) {}

  changeOrders: ChangeOrder[];
  changeOrderTotalCost: number;
  private changeOrderFields: string[] = [
    'code',
    'proposal_request_id',
    'proposal_request_code',
    'short_description',
    'summary',
    'trade_name',
    'company_name',
    'status_id',
    'cost_change',
    'time_change_amount',
    'time_change_unit',
    'funding_source_fee_id',
    'funding_source_fee_name',
    'funding_source_fee_owner_type_id',
    'funding_source_tenant_name',
    'funding_source_name',
    'files',
    'project_id',
    'local_index',
    'funding_source_fee_funding_source_id',
    'funding_source_fee_funding_source_abbreviation',
    'cfmo_task_id',
    'tenant_task_id',
    'wm_arch_task_id',
  ];

  async ngOnInit() {
    setTimeout(() => {
      this.getDivWidth();
      this.refresh();
    });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 1025) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  // TODO do we need to add files here? They are on changeOrders[X].files, and are displayed on the PR part of these COs
  // TODO In addition, we probably need to be able to remove or add new ones in the dialog for editing

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Orders..');
    const changeOrders = await this.projectService
      .getChangeOrders(
        [
          {
            type: 'field',
            field: 'project_id',
            value: this.project.id.toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'status_id', value: '2' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_request_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_project_id', value: null },
        ],
        this.changeOrderFields
      )
      .toPromise();

    changeOrders.sort((a, b) => +a.local_index - +b.local_index);

    this.changeOrders = changeOrders;
    this.changeOrderTotalCost = sumBy(this.changeOrders, 'cost_change');
    this.progressIndicatorService.close();

    // no need to load because of vendor permission
    if (+this.authService?.currentUser?.user_type_id !== +UserType.Vendor) {
      for (const changeOrder of this.changeOrders) {
        const taskId = changeOrder.cfmo_task_id || changeOrder?.tenant_task_id || changeOrder.wm_arch_task_id;
        if (taskId) {
          const task = await this.projectService.getTaskById(taskId, ['accessory_data']).toPromise();
          const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
          changeOrder.files =
            (accessoryData?.reviewFiles?.length && accessoryData.reviewFiles) ||
            (await this.taskService.getLatestTaskActivityNote(taskId)) ||
            changeOrder.files;
        }
        changeOrder.files_updated = true;
      }
    }
  }

  viewChangeOrder(co) {
    this.ViewChangeOrderDialog.open(ViewChangeOrderDialogComponent, {
      width: '480px',
      disableClose: true,
      data: { changeOrder: co },
    });
  }

  async exportChangeOrder() {
    const filename = `change_orders_${moment().format('YYYYMMDD')}`;
    const dataToReturn: string[] = ['#, Change Order, Trade Name, Vendor, Funding, Timeline, Amount, File Name'];
    let funding = '';
    for (const entry of this.changeOrders) {
      if (!entry.funding_source_fee_name && !entry.funding_source_name) {
        funding = 'Not Given';
      } else {
        funding = '';
        funding += entry.funding_source_fee_owner_type_id === 2 ? 'UHAT' : entry.funding_source_tenant_name;
        funding += entry.funding_source_fee_funding_source_id
          ? ' (' + entry.funding_source_fee_funding_source_abbreviation + ')'
          : '';
        funding += ': ' + (entry.funding_source_fee_name || 'Added Cost');
      }

      dataToReturn.push(
        this.exportService.sanitizeItems([
          `${entry.local_index}`,
          entry.short_description,
          entry.trade_name,
          entry.company_name,
          funding,
          `${!entry.funding_source_fee_name && !entry.funding_source_name ? 'Not Given' : ''} ${
            entry.time_change_amount < 0 ? '' : '+ '
          } ${entry.time_change_amount} ${entry.time_change_unit} ${entry.time_change_amount === 1 ? '' : 's'}`,
          `$${entry.cost_change}`,
          entry?.files?.map((file: UhatFileReference) => file?.name).join(','),
        ])
      );
    }

    this.exportService.exportToCsv(filename, dataToReturn);
  }

  // editChangeOrder(changeOrder: ChangeOrder) {
  //   const dialogRef = this.changeOrderDialog.open(ChangeOrderDialogComponent, {
  //     disableClose: true,
  //     data: changeOrder
  //   });

  //   dialogRef.afterClosed().subscribe(async (changeOrderToEdit: ChangeOrder) => {
  //     if (changeOrderToEdit && this.project) {
  //       const changeOrderId = changeOrderToEdit.id;
  //       delete changeOrderToEdit.id;
  //       // map all the returned files to just the id (we don't care about the name)
  //       const returnedIds = changeOrderToEdit.files.map(file => +file.id);
  //       // get all the currently linked files to the invoice
  //       const currentFiles = await this.fileService.getFilesByParentId(ResourceType.ChangeOrder, changeOrderId).toPromise();
  //       // get the returned ids that aren't in the current ids, since we need to add these
  //       const fileIdsToAdd = returnedIds.filter(id => !currentFiles.map(file => +file.file_id).includes(id));
  //       // get the current ids that aren't in the returned ids, since we need to remove these
  //       const fileIdsToRemove = currentFiles.filter(file => !returnedIds.includes(file.file_id));
  //       delete changeOrderToEdit.files;
  //       // update the invoice
  //       const updatedChangeOrder = await this.projectService.updateChangeOrder(changeOrderId, changeOrderToEdit, this.changeOrderFields).toPromise();

  //       // link and unlink the appropriate files
  //       for (const f of fileIdsToRemove) {
  //         await this.fileService.unlinkFile(f.id).toPromise();
  //       }
  //       for (const f of fileIdsToAdd) {
  //         await this.fileService.linkFile(f, changeOrderId, ResourceType.ChangeOrder).toPromise();
  //       }

  //       this.snackbar.open(`Change Order updated!`);
  //       this.refresh();
  //     }
  //   });
  // }
}
