<header class="my-4">
  <div class="d-flex align-items-center">
    <h4 class="primary m-0">Suites</h4>
  </div>
</header>
<div class="d-flex align-items-center mb-4">
  <mat-form-field appearance="outline">
    <mat-icon class="dkblue" matPrefix>search</mat-icon>
    <input matInput placeholder="Search Suites" [(ngModel)]="search" (ngModelChange)="paginator.pageIndex = 0" />
    <mat-icon *ngIf="search" class="gray pointer" (click)="search = null; paginator.pageIndex = 0" matSuffix
      >cancel
    </mat-icon>
  </mat-form-field>
  <mat-slide-toggle color="accent" [(ngModel)]="showInactive">
    <span class="font-weight-semibold">Show Inactive suites</span>
  </mat-slide-toggle>
  <button *ngIf="isSuitesManager" class="mr-3 bg-white" mat-stroked-button color="gray" (click)="exportSuiteData()">
    <i class="material-icons-outlined"> file_download </i>
    Download Suites CSV
  </button>
  <button mat-flat-button color="success" (click)="openSuiteDialog('Add')">Add Suite</button>
</div>

<div class="m-portlet">
  <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
    <div class="row align-items-center">
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('id')">
        <h6 class="m-0 text-truncate header-title mr-auto">ID</h6>
        <i *ngIf="sortProperty === 'id'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Number / Name</h6>
        <i *ngIf="sortProperty === 'name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('building.name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Building</h6>
        <i *ngIf="sortProperty === 'building.name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('floor.name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Floor</h6>
        <i *ngIf="sortProperty === 'floor.name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('building.name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Occupying Department(s)</h6>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('is_enabled')">
        <h6 class="m-0 text-truncate header-title mr-auto">Active</h6>
        <i *ngIf="sortProperty === 'is_enabled'" matRipple class="material-icons">
          {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
    </div>
  </div>
  <div>
    <div
      *ngFor="
        let suite of suites
          | isEnabled: showInactive
          | filter: search:['name', 'building.name', 'department_names', 'floor_name']:filteredCount
          | orderByField: sortProperty:sortDirection
          | paginator: paginator.pageIndex:paginator.pageSize
      "
      class="m-portlet__body item p-3 pt-4 pb-4"
      data-id-test="selected-request-type-wrapper"
    >
      <div class="row align-items-center">
        <div class="col-1 d-flex align-items-center data-item" [ngClass]="{ gray: suite.is_enabled !== 1 }">
          {{ suite.id }}
        </div>
        <div class="col-2 d-flex align-items-center data-item">
          <h6 class="primary m-0" [ngClass]="{ gray: suite.is_enabled !== 1 }">
            {{ suite.name }}
          </h6>
        </div>
        <div class="col-3 d-flex align-items-center data-item" [ngClass]="{ gray: suite.is_enabled !== 1 }">
          {{ suite.building?.name }}
        </div>
        <div class="col-1 d-flex align-items-center data-item" [ngClass]="{ gray: suite.is_enabled !== 1 }">
          {{ suite.floor?.name }}
        </div>
        <div class="col-3 d-flex flex-wrap" [ngClass]="{ gray: suite.is_enabled !== 1 }">
          <div
            *ngFor="let suiteOccupancy of suite.suite_occupancies"
            [ngClass]="{ 'dkblue bg-shade-ltblue px-2 py-1 lh-20 br-10 m-1': suiteOccupancy.department }"
          >
            {{ suiteOccupancy.department?.name }}
          </div>
        </div>
        <div class="col-1 d-flex align-items-center data-item">
          <p class="m-0 lh-20">
            <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="suite.is_enabled === 1"> Active </span>
            <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="suite.is_enabled !== 1"> Inactive </span>
          </p>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <button (click)="openSuiteDialog('Edit', suite)" mat-icon-button color="accent" type="button">
            <mat-icon>edit</mat-icon>
          </button>
          <!-- <button (click)="delete(suite)" mat-icon-button color="accent" type="button">
            <mat-icon>delete</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
    <div *ngIf="+filteredCount?.count === 0" class="m-portlet__body">no results found</div>
  </div>
  <div>
    <mat-paginator
      #paginator
      [length]="filteredCount?.count"
      pageSize="25"
      (page)="pageEvent = $event"
      aria-label="Select page"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </div>
</div>
