import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService } from 'src/app/services/api-filter.service';
import { HandleErrorService } from 'src/app/services/handle-error.service';
import { APIFilter, CostCode, ServiceResponse, SubCostCode, SubCostCodeBudget } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralLedgerService {
  host: string = environment.serviceHost;

  costCodesUrl = `${this.host}/api/v1/cost-codes`;
  costCodesFields = ['id', 'code', 'label', 'sub_cost_codes{code,label,sub_cost_code_budget{label,code}}'];

  subCostCodesUrl = `${this.host}/api/v1/sub-cost-codes`;
  subCostCodesFields = [
    'id',
    'fiscal_year',
    'code',
    'label',
    'sub_cost_code_type{id,name}',
    'cost_code{id,code,label}',
    'sub_cost_code_budget{label,code,is_enabled}',
  ];

  subCostCodeBudgetsUrl = `${this.host}/api/v1/sub-cost-code-budgets`;
  subCostCodeBudgetsFields = ['id', 'label'];

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getCostCodes(apiFilters: APIFilter[], fields?: string[]): Observable<CostCode[]> {
    fields = fields || this.costCodesFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.costCodesUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const costCodes: CostCode[] = result.data.cost_codes;
        return costCodes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSubCostCodes(apiFilters: APIFilter[], fields?: string[]): Observable<SubCostCode[]> {
    fields = fields || this.subCostCodesFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.subCostCodesUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const subCostCodes: SubCostCode[] = result.data.sub_cost_codes;
        return subCostCodes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  async getCostCodesByModuleId(moduleId: number): Promise<CostCode[]> {
    const apiFilter: APIFilter[] = [
      { type: 'field', field: `module_ids`, value: moduleId, match: 'exact' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: '1' },
    ];
    const subCostCodes = moduleId ? await this.getSubCostCodes(apiFilter).toPromise() : [];
    const costCodes: CostCode[] = [];
    const subCostCodesByCostCode = {};
    for (const s of subCostCodes) {
      // Done this way so that an infinite loop of information isn't created
      const costCode = { ...{}, ...s.cost_code };
      subCostCodesByCostCode[s.cost_code_id] = [...(subCostCodesByCostCode[s.cost_code_id] || []), ...[s]];
      costCodes.push(costCode);
    }

    const filteredCostCodes = uniqBy(costCodes, 'id').sort((a, b) => a.code.localeCompare(b.code));
    filteredCostCodes.forEach((c) => {
      c.sub_cost_codes = subCostCodesByCostCode[c.id];
    });

    return filteredCostCodes;
  }

  getSubCostCodeBudgets(apiFilters: APIFilter[], fields?: string[]): Observable<SubCostCodeBudget[]> {
    fields = fields || this.subCostCodeBudgetsFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.subCostCodeBudgetsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const subCostCodeBudgets: SubCostCodeBudget[] = result.data.sub_cost_code_budgets;
        return subCostCodeBudgets;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
