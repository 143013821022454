import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, maxBy, remove, sumBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  ConfirmationDialogComponent,
  DatepickerHeaderComponent,
  FileAttachmentDialogComponent,
  MeetingDialogComponent,
  ViewTaskDialogComponent,
} from 'src/app/components';
import {
  BidContractStatus,
  BidType,
  CompanyTypeEnum,
  ResourceType,
  TaskAccessoryType,
  TaskStatus,
} from 'src/app/enums';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
} from 'src/app/services';
import { BidContract, Company, Meeting, Milestone, Phase, Task, UhatFileReference } from 'src/app/types';
import {
  BidDialogComponent,
  BidPackageDialogComponent,
  NewAltBidDialogComponent,
} from 'src/app/workspaces/construction/components';
import { ProjectTenantPEBStatus } from 'src/app/workspaces/construction/enums';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { Bid, BidPackage, ProjectConstruction, Solicitation } from 'src/app/workspaces/construction/types';

// constants
const [NOT_AWARDED, NOT_EXECUTED_AWARDED, EXECUTED_AWARDED] = [
  'not yet awarded',
  'awarded, not executed',
  'awarded & executed',
];

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss'],
})
export class BidsComponent implements OnInit, OnDestroy {
  @Input() project: ProjectConstruction;

  constructor(
    private displayReviewersService: DisplayReviewersService,
    private projectService: ProjectService,
    private meetingService: MeetingService,
    private fileService: FileService,
    private bidPackageDialog: MatDialog,
    private snackbar: MatSnackBar,
    private dateService: DateService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private authService: AuthService,
    private modalService: ModalService,
    private taskService: ProjectTaskService,
    public projectTenantService: ProjectTenantService,
    private updateReviewerService: UpdateReviewerService
  ) {}

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('pdf', { static: true }) pdf;

  divWidth: number;

  draftMeeting: Meeting;
  bidPackages: BidPackage[];
  displayBidPackages: any[];
  biddableBidPackageCount: number;
  draftBid;
  draftAlt;
  expandedBidPackages: any;
  totalBudget;
  displayedBid;
  pebIsFinalized = false;
  cbIsFinalized = true;
  finalizedCBs = 0;
  removedRFPFileId: number;
  show_time_input = false;

  groupValue = undefined;

  public rfp_data;
  public rfpUnsavedChanges = false;
  public requirement_data;
  private original_requirement;
  public requirementUnsavedChanges = false;
  public schedule_data;
  public scheduleUnsavedChanges = false;
  public timelineFile: UhatFileReference;
  public meeting_data;
  public meetingUnsavedChanges = false;
  public solicitations_data;
  private solicitation_types: { id: number; name: string }[] = [];
  private original_solicitations: Solicitation[] = [];
  public solicitationUnsavedChanges = false;
  public customHeader = DatepickerHeaderComponent;
  public allBidsAwarded: boolean;
  public contractTaskUpdated: any;
  public currentContract: BidContract;

  loaders: any = {};
  searchTerm = new FormControl('');
  searchFields = ['awarded_company_name', 'budget_amount', 'trade_name', 'number'];

  private rfpFields = ['rfp_file_id', 'rfp_file_name'];

  private requirementsFields = ['additional_requirements', 'requirement_files'];

  private scheduleFields = ['bid_start_date', 'bid_end_datetime', 'construction_start_date', 'construction_end_date'];

  private bidDetailsFields = [
    'additional_requirements',
    'bid_start_date',
    'bid_end_datetime',
    'construction_start_date',
    'construction_end_date',
    'circulation_type_id',
    'circulation_start_date',
    'circulation_end_date',
    'rfp_file_id',
    'rfp_file_name',
    'circulation_file_id',
    'circulation_file_name',
    'requirement_files',
  ];

  private bidContractFields = [
    'type_id',
    'executed_datetime',
    'vendor_signed_datetime',
    'sent_to_vendor_datetime',
    'status_id',
  ];

  private bidFields = [
    'bid_package_id',
    'code',
    'project_id',
    'number',
    'type_id',
    'company{verification_status,type_id}',
    'company_id',
    'company_name',
    'contact_id',
    'contact{first_name,last_name,user_type_id,is_login_enabled}',
    'description',
    'datetime_received',
    'did_not_bid',
    'files',
    'amount',
    'is_selected',
    'is_awarded',
    'is_voided',
    'type_id',
    'is_sealed_envelope',
    'attended_mandatory_meetings',
    'is_msa_agreement',
    'collateral_type',
    'is_signed',
    'awarded_datetime',
    `bid_contract{${this.bidContractFields.join(',')}}`,
    'has_non_collusion',
    'bond_company_name',
    'contract_task_id',
    'contract_task{status_id,accessory_data}',
    'contract_revision',
    'timeline_task_id',
    'timeline_revision',
    'trade{name}',
  ];

  private bidPackageFields = [
    'code',
    'number',
    'trade_id',
    'trade_name',
    'trade_allows_bids',
    'trade{is_consultant}',
    'project_id',
    'cost_per_sqft',
    'square_footage',
    'budget_amount',
    'contract_task{status_id}',
    'awarded_bid_comment',
    'files',
    `bids{${this.bidFields.join(',')}}`,
    'contract_id',
    'contract_name',
    'executed_contract_id',
    'executed_contract_name',
    'executed_contract_task_id',
    'executed_contract_task{status_id}',
    'created_by_id',
    'has_re_bid',
    're_bid_message',
    'submittal_task_id',
    'submittal_task{status_id,accessory_data}',
    `child_request{id,code,short_description,building_code,floor_code,workspace{id,name},project_manager{id,first_name,last_name}}`,
    `child_project{id,code,title,budget_data,building_code,floor_code,module{id,name},status{id,name},priority{id,name},project_manager{id,first_name,last_name},end_date,updates.sort(created_datetime).order(desc).limit(1){message,project_health_type{id,name},created_datetime,created_by{id,first_name,last_name}}}`,
    `not_awarded_email_sent`,
    `manually_add_contract`,
  ];
  public bidFilters = [NOT_AWARDED, NOT_EXECUTED_AWARDED, EXECUTED_AWARDED];
  public selectedBidFilters = new FormControl('');

  public contractTaskIsComplete = {};
  public constructionCost: number;
  public numberOfAwardedContracts: number;
  public numberOfInitialContracts: number;
  public numberOfExecutedContracts: number;
  public linkedProjectCount: number;
  private _meetingReceiptSubscription: Subscription;
  private _consultantBidPackages: BidPackage[] = [];
  private _nonConsultantBidPackages: BidPackage[] = [];

  async ngOnInit() {
    this.getDivWidth();
    if (this.project && this.project.id) {
      await this.getBidDetails();
    }
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.expandedBidPackages = (preferences && preferences.expanded_bid_packages) || {};

    await this.refresh();

    this.contractTaskUpdated = this.taskService.milestoneTaskEvent.subscribe(async (data) => {
      this.bidPackages.forEach((bp) => {
        const awardedBid = bp.bids.find((bid) => bid.is_awarded);
        if (bp.contract_task_id === data.id) {
          const statusHasBeenUpdated = bp.contract_task.status_id !== data.status_id;

          bp.contract_task.status_id = data.status_id;
          this.numberOfInitialContracts +=
            data.status_id === 3 && statusHasBeenUpdated ? 1 : statusHasBeenUpdated ? -1 : 0;
        } else if (bp.executed_contract_task_id === data.id) {
          bp.executed_contract_task.status_id = data.status_id;
        } else if (awardedBid?.contract_task?.id === data.id) {
          const statusHasBeenUpdated = awardedBid.contract_task?.status_id !== data.status_id;
          this.numberOfExecutedContracts +=
            data.status_id === 3 && statusHasBeenUpdated ? 1 : statusHasBeenUpdated ? -1 : 0;
          awardedBid.contract_task.status_id = data.status_id;

          const oldAccessoryData =
            awardedBid.contract_task?.accessory_data && JSON.parse(awardedBid.contract_task?.accessory_data);
          const currentAccessoryData = data?.accessory_data && JSON.parse(data.accessory_data);
          let reviewsAreTheSame = true;
          let index = 0;
          oldAccessoryData.reviewChain.forEach((reviewer) => {
            if (reviewer?.status !== currentAccessoryData?.reviewChain[index]?.status) {
              reviewsAreTheSame = false;
            }
            index++;
          });
          if (awardedBid.contract_task?.assigned_user_id !== data.assigned_user_id || !reviewsAreTheSame) {
            awardedBid.contract_task.accessory_data = data.accessory_data;
            this.projectService
              .getBidContractById(awardedBid.bid_contract.id, this.bidContractFields)
              .toPromise()
              .then((contract) => {
                awardedBid.bid_contract = contract;
              });
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.contractTaskUpdated.unsubscribe();
    } catch (e) {}
  }

  get currentUserId(): number {
    return this.authService?.currentUser?.id;
  }

  get filteredDisplayBidPackages() {
    if (this.selectedBidFilters?.value?.length) {
      return [this._consultantBidPackages, this._nonConsultantBidPackages];
    }
    return this.displayBidPackages;
  }

  get filteredDisplayBidPackagesLength() {
    const constultantCount =
      this.filteredDisplayBidPackages && Array.isArray(this.filteredDisplayBidPackages[0])
        ? this.filteredDisplayBidPackages[0].length
        : 0;

    const nonConsultantCount =
      this.filteredDisplayBidPackages && Array.isArray(this.filteredDisplayBidPackages[1])
        ? this.filteredDisplayBidPackages[1]?.length
        : 0;

    return constultantCount + nonConsultantCount;
  }

  // Not awarded bids
  // Awarded bids: waiting for vendors, or pm
  // Executed bids
  public bidFilterChanged() {
    // Get the selected filters
    const selectedBidFilters = this.selectedBidFilters.value;
    const freshBidPackages = [[], []];

    // There bids are filtered using: 1. Not Awarded; 2. Awarded But Not Executed (Waiting On Vendor Or PM); Executed
    // Not Awarded Bids have no awardedBidCompanyId
    // Awarded Bids have an awardedBidCompanyId, removed exe executed from Awarded And Executed
    // There are two ways of checking Executed: OLD WAY (executed_contact_id); NEW WAY (awarded_bid_company->selctedBids[0]->contract_task->status_id)
    let index = 0;
    for (const bidPackages of this.displayBidPackages) {
      const filteredBidPackages = bidPackages?.filter(
        (bidPackage: BidPackage) =>
          // Not Awarded filter
          (selectedBidFilters?.includes(NOT_AWARDED) && !bidPackage?.awardedBidCompanyId) ||
          // Awarded not Executed Filter
          (selectedBidFilters?.includes(NOT_EXECUTED_AWARDED) &&
            bidPackage?.awardedBidCompanyId &&
            !(
              bidPackage?.executed_contract_id ||
              +bidPackage?.awarded_bid_company?.selectedBids?.[0]?.contract_task?.status_id === +TaskStatus.Complete
            )) ||
          // Awarded and Executed Filter
          (selectedBidFilters?.includes(EXECUTED_AWARDED) &&
            bidPackage?.awardedBidCompanyId &&
            (bidPackage?.executed_contract_id ||
              +bidPackage?.awarded_bid_company?.selectedBids?.[0]?.contract_task?.status_id === +TaskStatus.Complete))
      );

      freshBidPackages[index].push(...(filteredBidPackages || []));
      index += 1;
    }

    this._consultantBidPackages = freshBidPackages[0];
    this._nonConsultantBidPackages = freshBidPackages[1];
  }

  public removeBidFilters() {
    this.selectedBidFilters.reset();
  }

  public get projectSolicitations(): Solicitation[] {
    return this.solicitations_data?.filter((s) => !s.bid_package_id) || [];
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Bids..');
    const project = await this.projectService
      .getProjectById(this.project?.id, [
        `bid_packages{${this.bidPackageFields.join(',')}},tenants{peb_status,cb_is_finalized}`,
      ])
      .toPromise();
    this.project = { ...this.project, ...project };

    this.bidPackages = project.bid_packages.map((bidPackage) => {
      let manuallyAddContract: 1 | 0 = 0; // use digital signature contracts by default if not consultant
      if (bidPackage.trade?.is_consultant) {
        manuallyAddContract = 1;
      }
      return { ...bidPackage, manually_add_contract: bidPackage.manually_add_contract ?? manuallyAddContract }; // use default value if manually_add_contract is null in db
    });

    this.totalBudget = sumBy(this.bidPackages, (p) => p.budget_amount);

    this.constructionCost = 0;
    this.numberOfAwardedContracts = 0;
    this.numberOfInitialContracts = 0;
    this.numberOfExecutedContracts = 0;
    this.biddableBidPackageCount = 0;

    this.displayBidPackages = [];
    for (const p of this.bidPackages) {
      if (p.child_project?.id) {
        p.child_project.latestUpdate = p.child_project?.updates?.length > 0 ? p.child_project.updates[0] : null;
      }
      this.addBidPackageFields(p);
      this.biddableBidPackageCount += p.trade_allows_bids && !p.child_request?.id && !p.child_project?.id ? 1 : 0;
      this.numberOfAwardedContracts += p.awardedBidCompanyId && !p.child_request?.id && !p.child_project?.id ? 1 : 0;
      this.numberOfInitialContracts +=
        p.contract_id ||
        p.contract_task?.status_id === TaskStatus.Complete ||
        (p.bids.find((bid) => bid.is_awarded && bid.bid_contract?.vendor_signed_datetime) &&
          !p.child_request?.id &&
          !p.child_project?.id)
          ? 1
          : 0;

      this.numberOfExecutedContracts +=
        (p.executed_contract_id ||
          p.bids.find((bid) => bid.is_awarded && bid.contract_task?.status_id === TaskStatus.Complete)) &&
        !p.child_request?.id &&
        !p.child_project?.id
          ? 1
          : 0;

      if (p.contract_task) {
        this.contractTaskIsComplete[p.id] = p.contract_task.status_id === TaskStatus.Complete;
      }
      if (p.child_request?.id || p.child_project?.id) {
        this.constructionCost += p.child_project?.budget_data?.awardedBidTotal ?? 0;
      } else if (p.awardedBidCompanyId) {
        p.awarded_bid_company = p.companies.find((company) => company.id === p.awardedBidCompanyId);
        p.awarded_company_name = p.awarded_bid_company.name;

        p.companies = p.companies.filter((company) => company.id !== p.awardedBidCompanyId);
        p.companies.unshift(p.awarded_bid_company);

        this.constructionCost += p.awarded_bid_company.selectedBidsTotal;
      }

      // splits bids into consultant and non consultant - Engineering bids vs none
      const index = p.trade?.is_consultant ? 0 : 1;
      if (this.displayBidPackages[index]?.length) {
        this.displayBidPackages[index].push(p);
      } else {
        this.displayBidPackages[index] = [p];
      }
    }

    this.syncBidPackageExpansion(this.bidPackages);
    this.allBidsAwarded = this.allBidsAreAwarded();
    this.linkedProjectCount = this.bidPackages.filter((p) => !!p.child_request?.id || !!p.child_project?.id).length;

    const timelineFiles = await this.fileService.getFilesByParentId(ResourceType.Timeline, this.project.id).toPromise();
    this.timelineFile =
      timelineFiles?.length && timelineFiles.sort((a, b) => (a.created_datetime > b.created_datetime ? -1 : 1))[0];

    this.progressIndicatorService.close();

    let pebIsFinalized = true;
    this.finalizedCBs = 0;
    if (project.tenants?.length) {
      for (const t of project.tenants) {
        if (t.peb_status !== ProjectTenantPEBStatus.Finalized) {
          pebIsFinalized = false;
        }

        this.cbIsFinalized = !!t.cb_is_finalized && this.cbIsFinalized;
        this.finalizedCBs = (t.cb_is_finalized && ++this.finalizedCBs) || this.finalizedCBs;
      }
    }

    this.pebIsFinalized = pebIsFinalized;
  }

  get isAdmin() {
    return this.authService.isProjectAdmin(this.project?.id, this.project?.module_id);
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService?.currentSelectedProject?.module_id);
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  getDivWidthDelay() {
    setTimeout(() => {
      this.getDivWidth();
    }, 1000);
  }

  get BidContractStatus() {
    return BidContractStatus;
  }

  addBidPackageFields(bidPackage) {
    bidPackage.showBids = this.expandedBidPackages[bidPackage.id] || false;

    const awardedBid = bidPackage.bids.find((b) => b.is_awarded);
    bidPackage.awardedBidCompanyId = awardedBid ? awardedBid.company_id : null;
    bidPackage.contractView = !!bidPackage.awardedBidCompanyId;
    bidPackage.companies = uniqBy(
      map(bidPackage.bids, (b) => ({
        id: b.company_id,
        name: b.company_name,
        contact_id: b.contact_id,
        contact: b.contact,
        company_type_id: b.company?.type_id,
        verification_status: b.company.verification_status,
      })),
      'id'
    );
    bidPackage.companies.forEach((c) => {
      if (this.draftAlt && this.draftAlt.bid_package_id === bidPackage.id && this.draftAlt.company_id === c.id) {
        c.showCompanyBids = true;
      }
      c.bids = bidPackage.bids.filter((b) => b.company_id === c.id);
      c.selectedBids = c.bids.filter((b) => b.is_selected);
      this.getBidTotals(c);

      c.is_voided = !!c.bids.find((b) => b.is_voided);
    });
  }

  private getLatestSignedContract(bidPackageId: number) {
    const bidPackage = this.bidPackages.find((bp) => bp.id === bidPackageId);
    if (bidPackage && maxBy(bidPackage.files, 'created_datetime')) {
      this.numberOfInitialContracts += 1;
      return maxBy(bidPackage.files, 'created_datetime');
    }
    return null;
  }

  syncBidPackageExpansion(bidPackages) {
    if (this.bidPackages) {
      const openBidPackageIds = this.bidPackages.filter((p) => p.showBids).map((p) => p.id);
      if (openBidPackageIds) {
        for (const p of bidPackages) {
          p.showBids = true;
          p.showBids = openBidPackageIds.indexOf(p.id) > -1;
          const foundBidPackage = this.bidPackages.find((bp) => bp.id === p.id);
          const openCompanyIds = foundBidPackage
            ? foundBidPackage.companies.filter((c) => c.showCompanyBids).map((c) => c.id)
            : null;
          if (openCompanyIds) {
            for (const c of p.companies) {
              c.showCompanyBids = openCompanyIds.indexOf(c.id) > -1;
            }
          }
        }
      }
    }
  }

  public toggleBidView(bidPackage) {
    bidPackage.showBids = !bidPackage.showBids;
    this.expandedBidPackages[bidPackage.id] = bidPackage.showBids;

    this.addToPreferences('expanded_bid_packages', this.expandedBidPackages);
  }

  public toggleContractView(bidPackage) {
    bidPackage.contractView = !bidPackage.contractView;
  }

  // now that these 5 tabs are split up, we are loading the data individually per tab
  async getBidDetails() {
    const promises = [];
    promises.push(this.loadRFP());
    promises.push(this.loadRequirements());
    promises.push(this.loadSchedule());
    promises.push(this.loadMeetings());
    promises.push(this.loadSolicitations());
    await Promise.all(promises);
  }

  public allBidsAreAwarded(): boolean {
    let allAwarded = !!this.bidPackages.length;
    this.bidPackages.forEach((bidPackage) => {
      allAwarded = (bidPackage.awardedBidCompanyId && allAwarded) || false;
    });

    return allAwarded;
  }

  private getBidTotals(company) {
    company.baseBidTotal = 0;
    company.altBidTotal = 0;

    company.selectedBids.forEach((bid) => {
      if (bid.is_selected) {
        switch (bid.type_id) {
          case BidType.Base:
            company.baseBidTotal += bid.amount;
            break;
          case BidType.Alt:
            company.altBidTotal += bid.amount;
            break;
          default:
            company.baseBidTotal += bid.amount;
            break;
        }
      }
    });
    company.selectedBidsTotal = company.baseBidTotal + company.altBidTotal;
  }
  confirmCreateBidTasks(bidPackage) {
    // this.modalService.openConfirmationDialog({
    //   titleBarText: 'Create Bid Tasks',
    //   descriptionText: 'This will create a task for you to upload the contracts for each Supplier, do you want to continue?',
    //   confirmationButtonText: 'Continue'
    // }).subscribe(async isConfirmed => {
    //   if (isConfirmed) {
    //     await this.createBidTasks(bidPackage);
    //   }
    // });
  }

  public openContractFileUpload(bidPackage, executedContract) {
    const maxFiles = 1;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          maxFiles,
          preSelectedTags: [{ id: 80 }, { id: executedContract ? 84 : 85 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          // Allows this function to handle both contract types
          const fieldToUpdate = executedContract ? 'executed_contract_id' : 'contract_id';
          const fieldToUpdateName = executedContract ? 'executed_contract_name' : 'contract_name';

          const fileId = resultData[0].file_id || resultData[0].id;
          await this.projectService
            .updateBidPackage(bidPackage.id, { [fieldToUpdate]: fileId }, [fieldToUpdate])
            .toPromise();
          await this.fileService.addTags(fileId, [80, executedContract ? 84 : 85]).toPromise();
          bidPackage[fieldToUpdate] = fileId;
          bidPackage[fieldToUpdateName] = resultData[0].name;

          if (executedContract) {
            this.numberOfExecutedContracts += 1;
          } else {
            this.numberOfInitialContracts += 1;
          }
        }
      });
  }

  public async removeContractFile(bidPackage, executedContract: boolean = true) {
    const fieldToUpdate = executedContract ? 'executed_contract_id' : 'contract_id';
    const fieldToUpdateName = executedContract ? 'executed_contract_name' : 'contract_name';

    await this.fileService.removeTags(bidPackage[fieldToUpdate], [81, executedContract ? 84 : 85]).toPromise();
    await this.projectService.updateBidPackage(bidPackage.id, { [fieldToUpdate]: null }, [fieldToUpdate]).toPromise();

    bidPackage[fieldToUpdate] = null;
    bidPackage[fieldToUpdateName] = null;
    if (executedContract) {
      this.numberOfExecutedContracts -= 1;
    } else {
      this.numberOfInitialContracts -= 1;
    }
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public async openContractFilesDialog(bidPackageId: number, taskId: number, executedContract: boolean = true) {
    this.modalService.openSelectFilesDialog(taskId).subscribe(async (selectedFile) => {
      if (selectedFile) {
        // Allows this function to handle both contract types
        const fieldToUpdate = executedContract ? 'executed_contract_id' : 'contract_id';
        await this.projectService
          .updateBidPackage(bidPackageId, { [fieldToUpdate]: selectedFile }, [fieldToUpdate])
          .toPromise();
        await this.fileService.addTags(selectedFile, [80, executedContract ? 84 : 85]).toPromise();
        const currentBidPackage = this.bidPackages.find((bidPackage) => bidPackage.id === bidPackageId);
        currentBidPackage[fieldToUpdate] = selectedFile;
        if (executedContract) {
          this.numberOfExecutedContracts += 1;
        } else {
          this.numberOfInitialContracts += 1;
        }
      }
    });
  }

  public contractStatus(bidPackage: BidPackage) {
    const selectedCompany = bidPackage?.companies?.find((company) => company.id === bidPackage.awardedBidCompanyId);
    const contract: BidContract = selectedCompany?.selectedBids[0]?.bid_contract;

    if (
      (!contract && !bidPackage.contract_task_id && !bidPackage.contract_id) ||
      [BidContractStatus.RevisionRequested, BidContractStatus.NotSent].includes(contract?.status_id) ||
      (contract?.status_id === BidContractStatus.Executed &&
        bidPackage?.awarded_bid_company?.selectedBids?.[0]?.contract_task?.status_id !== TaskStatus.Complete)
    ) {
      return 'Waiting For PM';
    } else if (
      contract?.status_id === BidContractStatus.SentToVendor ||
      (bidPackage?.contract_task_id && bidPackage.contract_task?.status_id !== TaskStatus.Complete)
    ) {
      return 'Waiting for Supplier';
    } else if (
      contract?.status_id === BidContractStatus.SignedByVendor ||
      (bidPackage.contract_task?.status_id === TaskStatus.Complete && !bidPackage.executed_contract_id)
    ) {
      return 'Waiting for UHAT';
    } else if (
      bidPackage?.awarded_bid_company?.selectedBids?.[0]?.contract_task?.status_id === TaskStatus.Complete ||
      bidPackage.executed_contract_id
    ) {
      return 'Contracts Executed';
    }
  }

  public openExContractFileUpload(bidPackage) {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          maxFiles: 1,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          const fileId = resultData[0].file_id || resultData[0].id;
          // await this.fileService.addTags(fileId, [84]).toPromise();
          await this.projectService
            .updateBidPackage(bidPackage.id, { executed_contract_id: fileId }, ['executed_contract_id'])
            .toPromise();
          bidPackage.executed_contract_id = fileId;
          bidPackage.executed_contract_name = resultData[0].name;
        }
      });
  }

  public async createBidTask(bidPackage: BidPackage, taskField: string) {
    const phaseName = 'Construction';
    const milestoneName = 'Construction Contracts';
    let constructionPhase: Phase;
    let milestone: Milestone;

    constructionPhase = await this.projectService
      .getPhaseByPhaseNameAndProject(this.projectService.currentSelectedProjectId, phaseName)
      .toPromise();

    milestone = await this.projectService
      .getMilestoneByNameAndPhaseName(this.projectService.currentSelectedProjectId, milestoneName, phaseName)
      .toPromise();

    // Construction Contracts isn't a default milestone. So it must be created if this is the first time creating a task.
    if (!milestone) {
      await this.projectService
        .createMilestone({
          name: milestoneName,
          phase_id: constructionPhase.id,
          sequence: 1,
        })
        .toPromise();
    }

    const awardedCompany = bidPackage.companies.find((company) => company.id === bidPackage.awardedBidCompanyId);

    const selectedBid = awardedCompany.selectedBids[0];
    if (!bidPackage[taskField]) {
      const newTask = {
        // taskTitle: `Sign Contract and Upload Insurance Documentation ${bidPackage.number || bidPackage.code} - ${awardedCompany.name}`,
        taskTitle: `Sign Construction Contract - ${bidPackage.awarded_company_name ?? ''} - ${
          bidPackage.trade_name ?? ''
        }`,
        phaseName,
        milestoneName,
        // Removed isTypeLocked on 5/11/20 to allow the staff to potentially choose a different Supplier to upload the contract
        // isTypeLocked: true,
        // 5/11/20 Update and clarification for CreateTaskModal
        // From testing, it appears that the following happens in the createTask component
        // If isTypeLocked is true, then the assigned to user need to be in the following format
        assigned_user: taskField === 'executed_contract_task_id' ? null : selectedBid.contact,
        // if isTypeLocked is false, then use the below format
        // assignedUser: {
        //   id: selectedBid.contact_id,
        //   first_name: selectedBid.contact_first_name,
        //   last_name: selectedBid.contact_last_name,
        // },
        taskDescription: `${awardedCompany.name} has been awarded the bid for ${
          bidPackage.trade_name
        }. Please download the attached Contract, sign, and reupload using the paperclip button below. Once you've uploaded the signed copy AND your proof of insurance, please mark the task complete to notify the Project Manager.(${
          bidPackage.number || bidPackage.code
        })`,
        can_delete: 0,
      };
      this.modalService.openCreateTaskModal(newTask).subscribe(async (createdTask) => {
        if (createdTask) {
          await this.projectService.updateBidPackage(bidPackage.id, { [taskField]: createdTask.id }, []).toPromise();
          await this.refresh();
        }
      });
    }
  }

  // RFP section
  // store the current data, original data, as well as checking if there are differences
  // also separate functions per tab to load and save the data specific to that tab
  get hasUnsavedRFPChanges() {
    return this.rfpUnsavedChanges;
  }
  async loadRFP() {
    this.rfp_data = await this.projectService
      .getProjectBidDetailsByProjectId(this.project.id, this.rfpFields)
      .toPromise();
    if (this.rfp_data && this.rfp_data.rfp_file_name) {
      this.rfp_data.file = {
        name: this.rfp_data.rfp_file_name,
        id: this.rfp_data.rfp_file_id,
      };
    }
    this.rfpUnsavedChanges = false;
  }

  async saveRFP() {
    const bidDetailsToUpdate = {
      id: this.rfp_data.id,
      rfp_file_id: (this.rfp_data.file && this.rfp_data.file.id) || null,
    };
    if (bidDetailsToUpdate.rfp_file_id) {
      await this.fileService.addTags(bidDetailsToUpdate.rfp_file_id, [18]).toPromise();
    } else {
      this.fileService.removeTags(this.removedRFPFileId, [18]).subscribe();
    }
    await this.projectService.updateProjectBidDetails(bidDetailsToUpdate, this.bidDetailsFields).toPromise();
  }

  async closeRFP() {
    if (this.hasUnsavedRFPChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unsaved Changed',
          headerText: `Pending RFP Changes`,
          descriptionText:
            'Warning: You have unsaved changes in the RFP tab. Do you want to save before collapsing this tab?',
          cancelButtonText: 'No',
        },
      });

      await dialogRef
        .afterClosed()
        .toPromise()
        .then(async (answer) => {
          if (answer) {
            await this.saveRFP();
          } else {
            this.loadRFP();
          }
        });
    }
    this.groupValue = undefined;
  }

  // Requirements section
  // store the current data, original data, as well as checking if there are differences
  // also separate functions per tab to load and save the data specific to that tab
  get hasUnsavedRequirementsChanges() {
    return this.requirementUnsavedChanges;
  }
  async loadRequirements() {
    this.requirement_data = await this.projectService
      .getProjectBidDetailsByProjectId(this.project.id, this.requirementsFields)
      .toPromise();
    this.original_requirement = JSON.parse(JSON.stringify(this.requirement_data));
    this.requirementUnsavedChanges = false;
  }

  async saveRequirements() {
    const bidDetailsToUpdate = {
      id: this.requirement_data.id,
      additional_requirements: this.requirement_data.additional_requirements,
    };
    await this.projectService.updateProjectBidDetails(bidDetailsToUpdate, this.bidDetailsFields).toPromise();

    const currentFiles = this.requirement_data.requirement_files ? this.requirement_data.requirement_files : [];
    const originalFiles = this.original_requirement.requirement_files
      ? this.original_requirement.requirement_files
      : [];
    const filesToAdd = [];
    for (const f of currentFiles) {
      if (!originalFiles.map((val) => val.id).includes(f.id)) {
        filesToAdd.push(f);
      }
    }
    const filesToRemove = [];
    for (const f of originalFiles) {
      if (!currentFiles.map((val) => val.id).includes(f.id)) {
        filesToRemove.push(f);
      }
    }

    for (const f of filesToAdd) {
      await this.fileService.linkFile(f.id, this.requirement_data.id, ResourceType.BidRequirement).toPromise();
    }
    for (const f of filesToRemove) {
      const bridge = await this.fileService
        .getBridgeFile(f.id, this.requirement_data.id, ResourceType.BidRequirement)
        .toPromise();
      for (const file of bridge) {
        await this.fileService.unlinkFile(file.id).toPromise();
      }
    }

    // get the new bid details object and let the user know the update is complete
    // await this.loadRequirements();
  }

  async closeRequirement() {
    if (this.hasUnsavedRequirementsChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unsaved Changed',
          headerText: `Pending Requirement Changes`,
          descriptionText:
            'Warning: You have unsaved changes in the Requirements tab. Do you want to save before collapsing this tab?',
          cancelButtonText: 'No',
        },
      });

      await dialogRef
        .afterClosed()
        .toPromise()
        .then(async (answer) => {
          if (answer) {
            await this.saveRequirements();
          } else {
            this.loadRequirements();
          }
        });
    }
    this.groupValue = undefined;
  }

  // Schedule section
  // store the current data, original data, as well as checking if there are differences
  // also separate functions per tab to load and save the data specific to that tab
  get hasUnsavedScheduleChanges() {
    return this.scheduleUnsavedChanges;
  }
  async loadSchedule() {
    this.schedule_data = await this.projectService
      .getProjectBidDetailsByProjectId(this.project.id, this.scheduleFields)
      .toPromise();
    if (this.schedule_data?.bid_end_datetime) {
      this.schedule_data.bid_end_date = this.schedule_data.bid_end_datetime;
      this.schedule_data.bid_end_time = moment(this.schedule_data.bid_end_datetime).format('h:mm A');
    }
    this.scheduleUnsavedChanges = false;
  }

  async saveSchedule() {
    // This if statement is required. If it is commented out then
    if (!this.schedule_data.bid_end_date || !this.schedule_data.bid_end_time) {
      this.snackbar.open('Schedule updated, but both End Date and End Time are required');
    } else {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Schedule...');
      const bidDetailsToUpdate = {
        id: this.schedule_data.id,
        bid_start_date: this.schedule_data.bid_start_date
          ? moment(this.schedule_data.bid_start_date).format('YYYY-MM-DD')
          : null,
        bid_end_datetime:
          this.schedule_data.bid_end_date && this.schedule_data.bid_end_time
            ? moment(
                `${moment(this.schedule_data.bid_end_date).format('YYYY-MM-DD')} ${this.schedule_data.bid_end_time}`
              ).format('YYYY-MM-DD HH:mm:ss')
            : null,
        construction_start_date: this.schedule_data.construction_start_date
          ? moment(this.schedule_data.construction_start_date).format('YYYY-MM-DD')
          : null,
        construction_end_date: this.schedule_data.construction_end_date
          ? moment(this.schedule_data.construction_end_date).format('YYYY-MM-DD')
          : null,
      };
      await this.projectService.updateProjectBidDetails(bidDetailsToUpdate, this.bidDetailsFields).toPromise();

      // get the new bid details object and let the user know the update is complete
      await this.loadSchedule();
      this.snackbar.open('Schedule updated!');
      this.progressIndicatorService.close();
    }
  }

  async closeSchedule() {
    if (this.hasUnsavedScheduleChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unsaved Changed',
          headerText: `Pending Schedule Changes`,
          descriptionText:
            'Warning: You have unsaved changes in the Schedule tab. Do you want to save before collapsing this tab?',
          cancelButtonText: 'No',
        },
      });

      await dialogRef
        .afterClosed()
        .toPromise()
        .then(async (answer) => {
          if (answer) {
            await this.saveSchedule();
          } else {
            this.loadSchedule();
          }
        });
    }
    this.groupValue = undefined;
  }

  // Meetings section
  // store the current data, original data, as well as checking if there are differences
  // also separate functions per tab to load and save the data specific to that tab
  get hasUnsavedMeetingChanges() {
    return this.meetingUnsavedChanges;
  }
  async loadMeetings() {
    this.draftMeeting = null;
    const meeting_data = await this.meetingService
      .getMeetings([
        { type: 'field', field: 'type_id', value: '2' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'parent_type_id', value: '3' },
        { type: 'operator', value: 'AND' },
        {
          type: 'field',
          field: 'parent_id',
          value: this.project.id.toString(),
        },
      ])
      .toPromise();
    this.meeting_data = meeting_data.data.meetings;
    this.meetingUnsavedChanges = false;
  }

  async saveMeetings() {
    const invalidMeetingFields = this.getInvalidMeetingFields(this.draftMeeting);
    if (invalidMeetingFields && invalidMeetingFields.length === 0) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Meetings...');
      const meetingToAddOrUpdate: Meeting = {
        title: this.draftMeeting.title,
        start_datetime: moment(
          `${moment(this.draftMeeting.start_date).format('YYYY-MM-DD')} ${this.draftMeeting.start_time}`
        ).format('YYYY-MM-DD HH:mm:ss'),
        location: this.draftMeeting.location,
        is_mandatory: +this.draftMeeting.is_mandatory,
      };
      if (!this.draftMeeting.id) {
        meetingToAddOrUpdate.type_id = 2;
        meetingToAddOrUpdate.parent_type_id = 3;
        meetingToAddOrUpdate.parent_id = this.project.id;
        await this.meetingService.createMeeting(meetingToAddOrUpdate).toPromise();
        this.snackbar.open(`Bid meeting created!`);
      } else {
        meetingToAddOrUpdate.id = this.draftMeeting.id;
        await this.meetingService.updateMeeting(meetingToAddOrUpdate).toPromise();
        this.snackbar.open(`Bid meeting updated!`);
      }
      this.draftMeeting = null;
      await this.loadMeetings();
    } else {
      this.snackbar.open(
        `Bid meeting is invalid. Please complete the required fields: ${invalidMeetingFields.join(', ')}`
      );
    }
    this.progressIndicatorService.close();
  }

  async closeMeeting() {
    if (this.hasUnsavedMeetingChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unsaved Changed',
          headerText: `Pending Meeting Changes`,
          descriptionText:
            'Warning: You have unsaved changes in the Meeting tab. Do you want to save before collapsing this tab?',
          cancelButtonText: 'No',
        },
      });

      await dialogRef
        .afterClosed()
        .toPromise()
        .then(async (answer) => {
          if (answer) {
            await this.saveMeetings();
          } else {
            this.loadMeetings();
          }
        });
    }
    this.groupValue = undefined;
  }

  //////////////////////////////// Solicitations section
  // store the current data, original data, as well as checking if there are differences
  // also separate functions per tab to load and save the data specific to that tab
  get hasUnsavedSolicitationsChanges() {
    return this.solicitationUnsavedChanges;
  }
  async loadSolicitations() {
    this.solicitations_data = await this.projectService.getSolicitationsByProjectId(this.project.id).toPromise();

    this.solicitation_types = await this.projectService.getSolicitationTypes().toPromise();
    this.original_solicitations = JSON.parse(JSON.stringify(this.solicitations_data));
    this.solicitationUnsavedChanges = false;
  }

  removeSolicitation(solicitation) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Remove Solicitation`,
        confirmationButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        descriptionText: 'You are about to remove this solicitation. Are you sure you want to continue?',
      })
      .subscribe(async (confirmation) => {
        if (confirmation) {
          this.solicitations_data.splice(this.solicitations_data.indexOf(solicitation), 1);
          await this.projectService.deleteSolicitation(solicitation).toPromise();
        }
      });
  }

  hasMandatoryMeetings(): boolean {
    return this.meeting_data ? !!this.meeting_data.find((m) => !!m.is_mandatory) : false;
  }

  async openDraftBid(bidPackage, company = null, viewOnly = false) {
    this.draftBid = { bid_package_id: bidPackage.id };
    const newBidDialog = this.dialog.open(BidDialogComponent, {
      disableClose: true,
      width: '720px',
      data: {
        bidPackage,
        draftBid: this.draftBid,
        bidMeetings: this.meeting_data,
        project: this.project,
        bidFields: this.bidFields,
        company,
        refresh: this.refresh,
        viewOnly,
      },
    });

    newBidDialog.afterClosed().subscribe((res) => {
      if (res && (res.openNewBid || (res.openAltBid && res.company && res.company.id))) {
        if (res.openNewBid) {
          this.openDraftBid(bidPackage);
        } else if (res.openAltBid) {
          this.newAltBid(bidPackage, res.company);
        }
      } else if (res?.edited) {
        this.refresh();
      }
    });
  }

  async openRebidDialog(p) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: `Bid Packages`,
          headerText: `Rebid Bid Package`,
          descriptionText:
            'This will allow bids to be accepted and awarded AFTER the bid end date and time. Please enter an explanation for the rebid, below',
          confirmationButtonText: 'Confirm Rebid',
          userInput: {
            required: true,
            placeholder: 'Rebid explanation',
          },
        },
      })
      .afterClosed()
      .toPromise()
      .then(async (res) => {
        if (res) {
          await this.projectService
            .updateBidPackage(p.id, { has_re_bid: 1, re_bid_message: res }, ['has_re_bid,re_bid_message'])
            .toPromise();
          await this.refresh();
        }
      });
  }

  async toggleBidSelection(company, bidToUpdate, isSelected, bidPackage) {
    // select new bid
    if (
      bidToUpdate &&
      bidToUpdate.id &&
      !bidPackage.awardedBidCompanyId &&
      !company.is_voided &&
      this.isWorkspaceStaff
    ) {
      const updatedBid = await this.projectService
        .updateBid(bidToUpdate.id, { is_selected: isSelected ? 1 : 0 }, this.bidFields)
        .toPromise();
      bidToUpdate.is_selected = updatedBid.is_selected;
      if (isSelected) {
        if (company.selectedBids) {
          company.selectedBids.push(bidToUpdate);
        } else {
          company.selectedBids = [bidToUpdate];
        }
      } else {
        if (company.selectedBids) {
          remove(company.selectedBids, (b: Bid) => b.id === bidToUpdate.id);
        } else {
          company.selectedBids = [];
        }
      }
      company.selectedBidsTotal = sumBy(company.selectedBids, 'amount');
    }
  }

  async updateBidAward(bidPackage: BidPackage, company, action) {
    let canOpenDialog = true;
    if (this.finalizedCBs) {
      canOpenDialog = await this.modalService
        .openConfirmationDialog({
          // titleBarText: `Construction Budget${this.finalizedCBs === 1 ? '' : 's'} Finalized`,
          titleBarText: `Construction Budget`,
          headerText: 'Warning: A Construction Budget has already been finalized.',
          confirmationButtonText: `${action.charAt(0).toUpperCase() + action.slice(1)} Bid & Reset CB`,
          descriptionText: `A Construction budget has been finalized for ${this.finalizedCBs} tenant${
            this.finalizedCBs === 1 ? '' : 's'
          }.<br/><br/>To ${action} this bid, any finalized construction budgets will have to be reset and their respective reviews redone to account for the change. Do you want to continue?`,
        })
        .toPromise();
    }

    if (!bidPackage?.trade?.is_consultant && company.company_type_id !== CompanyTypeEnum.Staff && action === 'award') {
      // skip the check if its consultant or staff company,  otherwise, always open solicitation dialog
      canOpenDialog = await this.openSolicitationDialog(
        this.solicitations_data?.find((s) => s.bid_package_id === bidPackage.id) || null,
        bidPackage,
        company
      );
    }
    if (canOpenDialog && (action === 'award' || action === 'unaward')) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Bid...');
      if (action === 'award') {
        const selectedBidAmount = company.selectedBidsTotal;
        let lowestBid = company.selectedBidsTotal;
        let stop = false;

        bidPackage.companies.forEach((c) => {
          if (!c.selectedBids[0].did_not_bid && c.selectedBidsTotal < lowestBid) {
            lowestBid = c.selectedBidsTotal;
          }
        });

        if (lowestBid < selectedBidAmount) {
          await this.openBidComment(bidPackage.id).then((response) => {
            if (!response) {
              stop = true;
            }
          });
        }

        if (stop) {
          this.progressIndicatorService.close();
          return;
        }
      }

      let bidsToUpdate = [];
      if (action === 'award') {
        bidsToUpdate = company.selectedBids || [];
        this.numberOfAwardedContracts += 1;
      } else {
        // get awarded bids
        bidsToUpdate = company.bids ? company.bids.filter((b) => b.is_awarded) : [];
        this.numberOfAwardedContracts -= 1;
      }
      let updatedBidCount = 0;
      for (const b of bidsToUpdate) {
        if (b.id) {
          await this.projectService
            .updateBid(b.id, { is_awarded: action === 'award' ? 1 : 0 }, this.bidFields)
            .toPromise();
          updatedBidCount++;
        }
      }
      if (updatedBidCount > 0) {
        bidPackage.awardedBidCompanyId = action === 'award' ? company.id : null;
        this.snackbar.open(`${company.name}'s bid has been ${action === 'award' ? '' : 'un'}awarded!`);
        if (action === 'unaward') {
          await this.projectService
            .updateBidPackage(bidPackage.id, { awarded_bid_comment: null, contract_task_id: null }, [])
            .toPromise();
        }
        this.refresh();
      }
    }
  }

  async updateContractMethod(bidPackage: BidPackage, contractId: number) {
    let refreshPage;
    if (contractId) {
      const res = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Add Contract Manually',
          descriptionText:
            'Are you sure you want to add this contract manually? Doing so will delete the current digital signature and clear any progress.',
          confirmationButtonText: 'Add Manually',
        })
        .toPromise();
      if (res) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Removing bid contract...');
        await this.projectService.removeBidContractFromBid(contractId).toPromise();
        refreshPage = true;
      }
    }
    bidPackage.manually_add_contract = bidPackage.manually_add_contract ? 0 : 1;
    await this.projectService
      .updateBidPackage(bidPackage.id, { manually_add_contract: bidPackage.manually_add_contract }, [
        'manually_add_contract',
      ])
      .toPromise();
    if (refreshPage) {
      await this.refresh();
    }
  }

  async updateCompanyVoid(company, action) {
    if (action === 'void' || action === 'unvoid') {
      // foreach bid in the company, set is_voided to xx
      let updatedBidCount = 0;
      for (let b of company.bids) {
        b = await this.projectService
          .updateBid(b.id, { is_voided: action === 'void' ? 1 : 0 }, this.bidFields)
          .toPromise();
        updatedBidCount++;
      }
      if (updatedBidCount > 0) {
        company.is_voided = action === 'void' ? 1 : 0;
        this.snackbar.open(`${company.name}'s bid has been ${action === 'void' ? '' : 'un'}voided!`);
      }
    }
  }

  async changeCompanyContact(company, vendorContractTaskId) {
    if (company) {
      const oldUserId = company.contact_id;
      const data = {
        title: 'Switch Supplier',
        preSelectedUsers: [
          { id: company.contact_id, first_name: company.first_name, last_name: company.last_name, is_selected: true },
        ],
        checkIfSelected: true,
        companyId: company.id,
        maxSelected: 1,
        hasProject: true,
        createUser: { title: 'Guest', guestUser: false },
      };

      await this.modalService
        .openUserSelectModal(data)
        .toPromise()
        .then(async ({ selectedUsers }) => {
          if (selectedUsers) {
            const newUser = selectedUsers[0];
            company.contact_id = newUser.id;
            company.contact = newUser;
            company.selectedBids[0].contact_id = company.contact_id;
            company.selectedBids[0].contact = company.contact;

            const contractTask = company.selectedBids[0].contract_task;
            const accessoryData = contractTask?.accessory_data && JSON.parse(contractTask.accessory_data);

            if (
              [BidContractStatus.SentToVendor, BidContractStatus.RevisionRequested].includes(
                company.selectedBids[0].bid_contract?.status_id
              ) &&
              accessoryData.type === TaskAccessoryType.BidContract
            ) {
              this.progressIndicatorService.openAwaitIndicatorModal();
              this.progressIndicatorService.updateStatus(`Hold tight. We're writing up a new contract.`);
            }

            await this.projectService
              .updateBid(company.selectedBids[0].id, { contact_id: newUser?.id }, ['contact_id'])
              .toPromise();

            const tasksToUpdate = [
              [BidContractStatus.SentToVendor, BidContractStatus.RevisionRequested].includes(
                company.selectedBids[0].bid_contract?.status_id
              ) && contractTask?.id,
              vendorContractTaskId,
            ];
            for (const taskId of tasksToUpdate) {
              if (taskId) {
                const taskData: Task = {
                  id: taskId,
                  due_date: this.dateService.addWeekdays(3).format('YYYY-MM-DD'),
                };
                if (company.selectedBids[0].bid_contract?.status_id !== BidContractStatus.RevisionRequested) {
                  taskData.assigned_user_id = newUser.id;
                }

                if (taskId === contractTask?.id) {
                  if (accessoryData?.type === TaskAccessoryType.BidContract) {
                    company.selectedBids[0].contract_revision = company.selectedBids[0].contract_revision + 1;
                    this.currentContract = company.selectedBids[0]?.bid_contract;
                    await this.projectService
                      .updateBid(
                        company.selectedBids[0].id,
                        { contract_revision: company.selectedBids[0].contract_revision },
                        ['contract_revision']
                      )
                      .toPromise();
                    const createdPDF = [
                      await this.pdf.createPdfFile(
                        this.projectService.currentSelectedProject,
                        company.selectedBids[0],
                        [],
                        true,
                        this.currentContract
                      ),
                    ];
                    if (createdPDF?.length) {
                      const createdNote = await this.projectService
                        .createNote(
                          ResourceType.Task,
                          company.selectedBids[0].contract_task_id,
                          'Updated contract attached <br />'
                        )
                        .toPromise();
                      if (createdNote.id) {
                        for (const file of createdPDF) {
                          await this.fileService
                            .linkFile(file.file_id || file.id, createdNote.id, ResourceType.Note)
                            .toPromise();
                        }
                      }
                    }
                    for (const reviewer of accessoryData?.reviewChain || []) {
                      if (reviewer.id === oldUserId) {
                        reviewer.id = newUser.id;
                      }
                    }
                    taskData.accessory_data = JSON.stringify(accessoryData);
                    company.selectedBids[0].contract_task.accessory_data = taskData.accessory_data;
                    this.progressIndicatorService.close();
                  }
                }

                await this.projectService.updateTask(taskData).toPromise();
                // await this.taskService.removeFollowerFromTask(taskId, oldUserId).toPromise();
                await this.taskService.addFollowerToTask(taskId, newUser?.id).toPromise();
                this.taskService.taskSelectedEvent.emit({ task: { id: taskId }, navigate: false });
              }
            }
          }
        });
    }
  }
  // TODO deleting some of these don't seem to work on brief testing

  deleteBid(bid) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Bid',
        descriptionText: 'Are you sure you want to delete this bid?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (bid && bid.id) {
            await this.projectService.deleteBid(bid.id).toPromise();
            this.snackbar.open('Bid Removed!');
            this.refresh();
          }
        }
      });
  }

  removeBidPackage(bidPackage) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Bid Package',
        headerText: this.finalizedCBs ? 'Warning: A Construction Budget has already been finalized.' : null,
        descriptionText: this.finalizedCBs
          ? `A Construction budget has been finalized for ${this.finalizedCBs} tenant${
              this.finalizedCBs === 1 ? '' : 's'
            }.<br/><br/>To remove this bid package, any finalized construction budgets will have to be reset and their respective reviews redone to account for the change. Do you want to continue?`
          : 'Are you sure you want to remove this bid package?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (bidPackage && bidPackage.id) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Bid..');
            // remove the bid contract + current tag from the bid contract if it exists
            if (bidPackage.executed_contract_id || bidPackage.contract_id) {
              const contractIds = [bidPackage.executed_contract_id, bidPackage.contract_id];
              contractIds.forEach((fileId) => {
                if (fileId) {
                  this.fileService.removeTags(fileId, [80, 81, 84, 85]).subscribe();
                }
              });
            }
            // remove the bids tag from any bid files on the bid package
            if (bidPackage.companies) {
              for (const c of bidPackage.companies) {
                if (c.bids) {
                  for (const b of c.bids) {
                    if (b && b.files) {
                      for (const file of b.files) {
                        this.fileService.removeTags(+file.id, [11]).subscribe();
                      }
                    }
                    await this.projectService.deleteBid(b.id).toPromise();
                  }
                }
              }
            }
            await this.projectService.deleteBidPackage(bidPackage.id).toPromise();
            this.snackbar.open(`Bid Package '${bidPackage.number || bidPackage.code}' Removed!`);
            this.refresh();
          }
        }
      });
  }

  deleteCompanyBids(bidPackage, company) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Bid',
        descriptionText: `Are you sure you want to remove this company's bid?`,
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (bidPackage && bidPackage.id && company && company.id) {
            for (const b of company.bids) {
              if (b && b.files) {
                for (const file of b.files) {
                  this.fileService.removeTags(+file.id, [11]).subscribe();
                }
              }
              await this.projectService.deleteBid(b.id).toPromise();
            }
            this.refresh();
          }
        }
      });
  }

  private async openBidComment(id: number) {
    let commentAdded = false;
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Award Comment',
        descriptionText: 'You have chosen a bid that is not the lowest cost, please enter justification.',
        confirmationButtonText: 'Submit',
        addFiles: true,
        userInput: {
          required: true,
          placeholder: 'What is your reason for selecting this bid?',
        },
      })
      .toPromise()
      .then(async (result) => {
        if (result) {
          await this.projectService
            .updateBidPackage(id, { awarded_bid_comment: result }, ['awarded_bid_comment'])
            .toPromise();
          commentAdded = true;
        }
      });
    return commentAdded;
  }

  getInvalidDraftBidFields(bidPackage): string[] {
    const invalidFields = [];
    if (!bidPackage || !bidPackage.id) {
      invalidFields.push('Bid Package ID');
    }
    if (this.draftBid) {
      if (!this.draftBid.company) {
        invalidFields.push('Bidding Company');
      }
      if (!this.draftBid.contact) {
        invalidFields.push('Company Contact');
      }
      if (!this.draftBid.description) {
        invalidFields.push('Bid Description');
      }
      if (!this.draftBid.date_received) {
        invalidFields.push('Bid Received Date');
      }
      if (!this.draftBid.time_received) {
        invalidFields.push('Bid Received Time');
      }
      if (!this.draftBid.number) {
        invalidFields.push('Bid Number');
      }
      if (!this.draftBid.amount) {
        invalidFields.push('Bid Amount');
      }
    } else {
      invalidFields.push('Draft Bid');
    }
    return invalidFields;
  }

  // Should be able to combine in the future
  async updateBidPackage(bidPackage: BidPackage = null) {
    const dialogRef = this.bidPackageDialog.open(BidPackageDialogComponent, {
      disableClose: true,
      data: {
        bidPackageTradeIds: this.bidPackages?.map((p) => p.trade_id),
        bidPackage,
      },
    });

    dialogRef.afterClosed().subscribe(async (createdBidPackage) => {
      if (createdBidPackage) {
        await this.refresh();
      }
    });
  }

  async updateBidPackageNumber(bidPackage) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Updating Bid..');
    bidPackage = await this.projectService
      .updateBidPackage(bidPackage.id, { number: bidPackage.number }, this.bidPackageFields)
      .toPromise();
    bidPackage.editable = false;
    this.refresh();
  }

  absoluteValue(value) {
    return Math.abs(value);
  }

  public async openAddSubmittalsDialog(bidPackage: BidPackage) {
    await this.modalService
      .openAddSubmittalsModal(bidPackage)
      .toPromise()
      .then((res) => {
        if (res && res !== bidPackage.submittal_task_id) {
          bidPackage.submittal_task_id = res;
        }
      });
  }

  getInvalidMeetingFields(meeting): string[] {
    const invalidFields = [];
    if (!meeting.title) {
      invalidFields.push('Title');
    }
    if (!meeting.start_date) {
      invalidFields.push('Date');
    }
    if (!meeting.start_time) {
      invalidFields.push('Time');
    }
    if (meeting.is_mandatory == null) {
      invalidFields.push('Is Mandatory?');
    }
    return invalidFields;
  }

  // adds the passed file to the appropriate object (doesn't update though until you hit save)
  public async addFileToBidDetails(
    type: 'rfp' | 'requirement' | 'alt' | 'draft',
    file,
    shouldAlert?: boolean,
    loader?: string
  ) {
    if (loader) {
      this.loaders[loader] = true;
    }
    if (type === 'rfp') {
      this.rfp_data.file = file;
      await this.saveRFP();
    } else if (type === 'requirement') {
      if (!this.requirement_data.requirement_files) {
        this.requirement_data.requirement_files = [];
      }
      this.requirement_data.requirement_files.push(file);
      await this.saveRequirements();
    } else if (type === 'alt') {
      this.draftAlt.file = file;
    } else if (type === 'draft') {
      this.draftBid.file = file;
    }

    if (shouldAlert) {
      this.snackbar.open(`File added!`);
    }

    if (loader) {
      this.loaders[loader] = false;
    }
  }

  // remove the file from the bid details (doesn't update though until you hit save)
  public async removeFileFromBidDetails(
    type: 'rfp' | 'requirement' | 'alt' | 'draft',
    file,
    shouldAlert?: boolean,
    loader?: string
  ) {
    if (loader) {
      this.loaders[loader] = true;
    }
    if (type === 'rfp') {
      this.removedRFPFileId = this.rfp_data.file;
      delete this.rfp_data.file;
      await this.saveRFP();
    } else if (type === 'requirement') {
      if (this.requirement_data.requirement_files && this.requirement_data.requirement_files.length > 0) {
        const requirementFiles = this.requirement_data.requirement_files.filter((f) => f.id === file.id);
        requirementFiles.forEach((f) => {
          this.requirement_data.requirement_files.splice(this.requirement_data.requirement_files.indexOf(f), 1);
        });
        await this.saveRequirements();
      } else {
        throw new Error('No requirement files to delete');
      }
    } else if (type === 'alt') {
      this.draftAlt.file = null;
    } else if (type === 'draft') {
      this.draftBid.file = null;
    }
    if (shouldAlert) {
      this.snackbar.open(`File removed!`);
    }

    if (loader) {
      this.loaders[loader] = false;
    }
  }

  openTimelineSelectDialog() {
    // since we dont 'allowComment', this just links the files to the parent (timeline) and the additionalParents (project)
    this.modalService
      .openFileAttachmentDialog({
        parentResourceId: this.project.id,
        parentResourceType: ResourceType.Project,
        maxFiles: 1,
        preSelectedTags: [],
      })
      .subscribe(async (res) => {
        if (res) {
          for (const file of res) {
            const linkedFile = await this.fileService
              .linkFile(file.file_id, this.project.id, ResourceType.Timeline)
              .toPromise();
            if (!this.timelineFile) {
              this.timelineFile = linkedFile;
            }
          }

          this.timelineFile = res[0];
          this.project.project_schedule_file_id = this.timelineFile.file_id || this.timelineFile.id;
          await this.projectService
            .updateProject(this.project?.id, { project_schedule_file_id: this.project.project_schedule_file_id })
            .toPromise();
          this.snackbar.open('Timeline uploaded!');
        }
      });
  }

  async deleteTimelineFile(file) {
    this.timelineFile = null;
    this.project.project_schedule_file_id = null;

    await this.fileService.unlinkFile(file?.id).toPromise();
    await this.projectService.updateProject(this.project?.id, { project_schedule_file_id: null }).toPromise();

    this.snackbar.open('Timeline file removed');
  }

  // opens the upload modal, and passes the file on once it's uploaded, so it can be linked to the correct object
  openUploadModal(fileType: 'rfp' | 'requirement' | 'alt' | 'draft') {
    let maxFiles = 1;
    if (fileType === 'requirement') {
      maxFiles = 30;
    }

    let data;
    if (fileType === 'rfp') {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.project.id,
        allowComment: false,
        // additionalParents,
        maxFiles,
        preSelectedTags: [{ id: 18 }],
      };
    } else {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.project.id,
        allowComment: false,
        // additionalParents,
        maxFiles,
      };
    }

    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          for (const file of resultData) {
            this.addFileToBidDetails(fileType, { id: file.file_id, name: file.name }, true);
          }
        }
      });
  }
  public async openSolicitationDialog(
    solicitation = null,
    bidPackage: BidPackage = null,
    company: Company = null
  ): Promise<boolean> {
    if (!solicitation && bidPackage?.id) {
      solicitation = this.solicitations_data?.find((s) => s.bid_package_id === bidPackage.id) || null;
    }

    const res = await this.modalService
      .openSolicitationDialog({
        project: this.project,
        solicitation,
        bidPackage,
        company,
      })
      .toPromise();

    if (res) {
      const newSolicitation = this.solicitations_data.findIndex((sol) => sol.id === res.id);
      if (newSolicitation === -1) {
        this.solicitations_data.push(res);
      } else {
        this.solicitations_data.splice(newSolicitation, 1, res);
      }
    }

    return !!res;
  }

  newAltBid(bidPackage, company): void {
    const dialogRef = this.dialog.open(NewAltBidDialogComponent, {
      width: '400px',
      data: {
        project_id: this.project.id,
        company_id: company.id,
        bidPackage_id: bidPackage.id,
        bidFields: this.bidFields,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        this.snackbar.open('Alternate bid created!');
        this.refresh();
      }
    });
  }

  createMeeting() {
    this._meetingReceiptSubscription = this.modalService.meetingReceipt.subscribe((createdMeeting) => {
      if (createdMeeting) {
        // load meetings if the meeting was created
        this.loadMeetings();
      }
      // unsubscribe either way
      this._meetingReceiptSubscription?.unsubscribe();
    });
    this.modalService.createMeeting({
      type_id: 2,
      parent_type_id: 3,
      parent_id: this.project.id,
    });
  }

  editMeeting(meeting) {
    const dialogRef = this.dialog.open(MeetingDialogComponent, {
      width: '600px',
      data: meeting,
    });
    dialogRef.afterClosed().subscribe((createdMeeting) => {
      if (createdMeeting) {
        this.loadMeetings();
      }
    });
  }

  public viewTask(taskId: number) {
    this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
  }

  canAward(company, bidPackage): boolean {
    let canAward = true;
    const isConsultant = this.bidPackages.find((bp) => bp.id === company.bids[0].bid_package_id)?.trade?.is_consultant;
    if (!isConsultant && company.selectedBidsTotal >= 100000) {
      const thisBid = company.bids[0];

      canAward =
        thisBid.is_msa_agreement ||
        ((!this.hasMandatoryMeetings() || thisBid.attended_mandatory_meetings) &&
          thisBid.is_sealed_envelope &&
          thisBid.is_signed &&
          thisBid.has_non_collusion &&
          thisBid.collateral_type);
    }

    const receivedDate = moment(company.bids[0]?.datetime_received);
    const bidEndDate = moment(this.schedule_data?.bid_end_datetime);
    if (!bidPackage.has_re_bid && this.schedule_data?.bid_end_datetime && !receivedDate.isBefore(bidEndDate)) {
      canAward = false;
    }

    if (company.verification_status === 1 || company.verification_status === 4) {
      canAward = false;
    }

    return canAward;
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    localStorage.setItem('preferences', JSON.stringify(preferences));
  }

  async openEditContractDialog(contract, bid) {
    contract = contract ? { ...contract, bid } : { bid, bid_id: bid.id };

    const accessoryData = bid?.contract_task?.accessory_data && JSON.parse(bid.contract_task.accessory_data);
    this.modalService.openEditBidContractDialog(contract, accessoryData).subscribe(async (result) => {
      if (result?.contractEdited && result?.contract?.status_id !== BidContractStatus.NotSent) {
        if (result.approval) {
          await this.updateReviewerService.updateBidContractReviewer(result, bid.contract_task, bid, accessoryData);
        }
        await this.refresh();
        this.progressIndicatorService.close();
      }
    });
  }

  async sendVendorEmail(bidPackage) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Notify Suppliers',
        descriptionText:
          'This action will send an email to all suppliers whose bids were not awarded in this bid package. Are you sure you want to continue?',
        headerText: 'Notify Suppliers of of bid not awarded',
        confirmationButtonText: 'Notify Suppliers',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Sending Emails...');

          await this.projectService.sendNotAwardedVendorEmail(bidPackage.id).toPromise();
          bidPackage.not_awarded_email_sent = 1;

          this.snackbar.open('Supplier Emails Sent');
          this.progressIndicatorService.close();
        }
      });
  }

  openProjectScheduleConfirmationDialog() {
    if (this.project.project_schedule_file_id === this.project.sent_project_schedule_file_id) {
      this.snackbar.open('This schedule has already been sent!');
    }
    const titleBarText = 'Project Schedule';
    const descriptionText = !this.project.sent_project_schedule_file_id
      ? 'This action will create a signature review task for each awarded bid - assigned to the bid contact. Are you sure you want to continue?'
      : 'This action will reset any existing project schedule signature tasks with the amended schedule - Are you sure you want to continue?';
    this.modalService
      .openConfirmationDialog({
        titleBarText,
        descriptionText,
        headerText: !this.project.sent_project_schedule_file_id
          ? 'Send Project Schedule'
          : 'Project Schedule Amendment',
        confirmationButtonText: 'Send Schedule',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Sending Project Timeline...');
          await this.projectService.sendProjectTimeline(this.project?.id).toPromise();
          this.project.sent_project_schedule_file_id = this.project.project_schedule_file_id;
          this.snackbar.open('Project schedule sent to all awarded suppliers!');
          this.refresh();
        }
      });
  }

  public async toggleProjectScheduleIsNotApplicable() {
    const notApplicable = this.project.project_schedule_is_not_applicable ? '' : 'not ';
    const descriptionText = this.project.project_schedule_is_not_applicable
      ? 'Setting project schedule as applicable will require the need for construction schedule contracts. Do you want to continue?'
      : 'Setting project schedule as not applicable will disable the need for construction schedule contracts. Do you want to continue?';
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Project Schedule`,
        headerText: `Set Project Schedule to ${notApplicable}Applicable`,
        descriptionText,
        confirmationButtonText: `Set to ${notApplicable}applicable`,
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed && this.project) {
          this.project.project_schedule_is_not_applicable = this.project.project_schedule_is_not_applicable ? 0 : 1;
          await this.projectService
            .updateProject(
              this.project.id,
              { project_schedule_is_not_applicable: this.project.project_schedule_is_not_applicable },
              ['project_schedule_is_not_applicable']
            )
            .toPromise();
        }
      });
  }

  public async openTask(taskId: number) {
    await this.modalService.openViewTaskModal(taskId).toPromise();
  }

  public openUserProfilePreview(userId: number) {
    this.modalService.openUserProfileDialog(userId).toPromise();
  }
}
