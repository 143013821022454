<div
  data-test-id="app-container"
  id="app-container"
  class="d-flex align-self-stetch align-items-stretch full-width app-container"
>
  <div *ngIf="showDevBanner" id="dev-header" class="text-center">
    <p class="white m-0 font-weight-bold my-auto">
      <small class="white m-0 font-weight-bold">
        1CALL CLOUD DEVELOPMENT AND TEST SITE • NOT INTENDED FOR OFFICIAL WORK • FOR TESTING AND TRAINING ONLY
      </small>
    </p>
  </div>
  <app-sidenav data-test-id="app-sidenav" id="app-sidenav" class="d-flex" *ngIf="shouldShowNav()"></app-sidenav>
  <!--  <app-messaging-overlay-panel-->
  <!--    data-test-id="app-messaging-overlay-panel"-->
  <!--    id="app-messaging-overlay-panel"-->
  <!--    *ngIf="shouldShowNav()"-->
  <!--  ></app-messaging-overlay-panel>-->

  <app-search-overlay-panel
    data-test-id="app-search-overlay-panel"
    id="app-search-overlay-panel"
    *ngIf="shouldShowNav()"
  ></app-search-overlay-panel>

  <app-notification-overlay-panel
    data-test-id="app-notification-overlay-panel"
    id="app-notification-overlay-panel"
    *ngIf="shouldShowNav()"
  ></app-notification-overlay-panel>

  <app-time-log-panel
    data-test-id="app-time-log-panel"
    id="app-time-log-panel"
    *ngIf="authService.isStaffOnAnyModule && shouldShowNav()"
  ></app-time-log-panel>

  <div
    data-test-id="app-content-nav"
    id="app-content-nav"
    class="ease flex-grow-1"
    [ngClass]="{
      'app-content-nav-closed': shouldShowNav() && isSidenavClosed(),
      'app-content-nav-open': shouldShowNav() && !isSidenavClosed(),
      'app-content-no-nav': !shouldShowNav(),
      'app-content-notifications-open': notificationService.notificationPanelIsOpen && !isSidenavClosed(),
      'app-content-search-sidenav-closed': notificationService.notificationPanelIsOpen && isSidenavClosed(),
      'app-content-search-open': searchService.searchPanelIsOpen && !isSidenavClosed(),
      'app-content-notifications-sidenav-closed': searchService.searchPanelIsOpen && isSidenavClosed()
    }"
  >
    <div style="position: fixed; bottom: 5px; width: 100%; z-index: 1000000">
      <mat-progress-bar *ngIf="isZippingNotes" mode="indeterminate" color="accent"></mat-progress-bar>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
<app-update-available></app-update-available>
<app-capx-panel></app-capx-panel>
