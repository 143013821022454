<button
  *ngIf="task?.status_id === TaskStatus.Pending && !isApproval"
  class="d-inline-block align-middle pointer"
  [matTooltip]="!isLinkedTask ? 'Pending Confirmation' : 'Ready for Pickup'"
  matTooltipPosition="above"
  [matTooltipShowDelay]="300"
  mat-icon-button
  [disabled]="task?.is_locked"
>
  <mat-icon class="gray">check_circle</mat-icon>
</button>
<button
  *ngIf="isApproval && task?.status_id === TaskStatus.Open && approvalStatus?.status === TaskReviewStatus.Pending"
  class="d-inline-block align-middle pointer"
  [matTooltip]="'Pending Review'"
  matTooltipPosition="above"
  [matTooltipShowDelay]="300"
  mat-icon-button
>
  <mat-icon class="ltblue opacity6">pending</mat-icon>
</button>
<button
  *ngIf="
    isApproval &&
    task?.status_id === TaskStatus.Open &&
    ((!approvalStatus && !isOpenReview) || approvalStatus?.status === TaskReviewStatus.Rejected)
  "
  (click)="changeTaskStatus(TaskStatus.Complete); stopPropagation($event)"
  class="d-inline-block align-middle pointer"
  [matTooltip]="!approvalStatus ? 'Review complete' : isOpenReview ? 'Flagged' : 'Review rejected'"
  matTooltipPosition="above"
  [matTooltipShowDelay]="300"
  mat-icon-button
>
  <mat-icon class="green" *ngIf="!approvalStatus && !isOpenReview"> check_circle_outline </mat-icon>
  <mat-icon class="red" *ngIf="approvalStatus?.status === TaskReviewStatus.Rejected && !isOpenReview">
    cancel
  </mat-icon>
  <mat-icon class="red" *ngIf="approvalStatus?.status === TaskReviewStatus.Rejected && isOpenReview">
    sync_problem
  </mat-icon>
</button>
<button
  *ngIf="task?.status_id === TaskStatus.Complete"
  (click)="changeTaskStatus(TaskStatus.Open); stopPropagation($event)"
  class="ltblue d-inline-block align-middle pointer"
  [matTooltip]="!isLinkedTask ? (isApproval ? 'Approval Task Complete' : 'Mark To-Do') : 'Closed'"
  matTooltipPosition="above"
  [disabled]="!!task?.is_locked || !isWorkspaceStaff || task?.status_id === TaskStatus.OnHold || isLinkedTask"
  [matTooltipShowDelay]="300"
  mat-icon-button
>
  <mat-icon class="green">check_circle</mat-icon>
</button>
<button
  [disabled]="task?.status_id === TaskStatus.OnHold || !isWorkspaceStaff || isLinkedTask"
  mat-icon-button
  *ngIf="task?.status_id === TaskStatus.Open && (!isApproval || (isOpenReview && !approvalStatus))"
  (click)="changeTaskStatus(TaskStatus.Complete); stopPropagation($event)"
>
  <mat-icon
    class="ltblue opacity6"
    [matTooltip]="!isLinkedTask ? 'Mark Complete' : null"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
    >radio_button_unchecked</mat-icon
  >
</button>
<button
  class="pointer"
  [disabled]="task?.status_id === TaskStatus.OnHold || isLinkedTask"
  *ngIf="task?.status_id === TaskStatus.OnHold"
  mat-icon-button
>
  <mat-icon
    class="red"
    [matTooltip]="!isLinkedTask ? 'Task On Hold' : 'On Hold'"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
    >flag</mat-icon
  >
</button>
<mat-spinner
  *ngIf="showUndoTaskStatus && previousTaskStatusId && task?.status_id === TaskStatus.Complete"
  color="success"
  class="position-absolute opacity6"
  [diameter]="31"
  style="left: 4px; top: 10px"
></mat-spinner>
