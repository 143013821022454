import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResourceType } from 'src/app/enums';
import { FileService, HandleErrorService } from 'src/app/services';
import { ServiceResponse, Task, UhatFileReference } from 'src/app/types';
import { IdObservableContainer, Punchlist } from 'src/app/workspaces/construction/models';
import { Trade } from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PunchlistService {
  private readonly HOST: string = environment.serviceHost;

  private readonly PUNCHLIST_URL = this.HOST + '/api/v1/punchlists';

  private readonly BIDPACKAGES_URL = this.HOST + '/api/v1/bid-packages';

  // Mapping from a projectId to a list of trades
  private punchlistTrades = new IdObservableContainer<number, Task>();

  // Mapping from a projectId to a list of its punchlists
  private projectPunchlists = new IdObservableContainer<number, Punchlist>();

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private handleErrorService: HandleErrorService
  ) {}

  public getProjectPunchlistObservable(projectId: number): Observable<Punchlist[]> {
    return this.projectPunchlists.getObservableForId(projectId);
  }

  /** Retrieve Punchlists For The Givent Project */
  public async loadProjectPunchlists(projectId: number, getImageData?: boolean): Promise<any> {
    return await this.http
      .get(`${this.PUNCHLIST_URL}/punchlistData/${projectId}`)
      .pipe(
        map((result: ServiceResponse) => {
          const punchlists = result.data.punchlists;
          if (getImageData) {
            for (const punch of punchlists) {
              if (punch.files && punch.files.length > 0) {
                for (const file of punch.files) {
                  this.fileService.fillFileWithBase64(file);
                }
              }
            }
          }
          this.projectPunchlists.pushDataForId(projectId, punchlists);
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .toPromise();
  }

  public createNewPunchlistItem(
    projectId: number,
    description: string,
    tradeId: number,
    location: string,
    additionalNotes: string,
    filesToLink: UhatFileReference[]
  ): Observable<Punchlist> {
    const obs = new ReplaySubject<Punchlist>();
    this.http
      .post(
        `${this.PUNCHLIST_URL}?fields=id,code,description,trade_id,trade_name,location,additional_notes,project_id,files,local_index`,
        {
          description,
          location,
          trade_id: tradeId,
          additional_notes: additionalNotes,
          project_id: projectId,
        }
      )
      .pipe(
        map((result: ServiceResponse) => result.data.punchlist),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((createdPunchlist: Punchlist) => {
        // For each file we need to create the file and link it
        if (filesToLink?.length) {
          filesToLink.forEach((f) => {
            this.fileService
              .linkFile(f.file_id, createdPunchlist.id, ResourceType.Punchlist)
              .subscribe((linkedFile) => {
                if (createdPunchlist.files) {
                  createdPunchlist.files.push(f);
                } else {
                  createdPunchlist.files = [f];
                }
              });
          });
        }

        this.projectPunchlists.appendDataForId(projectId, [createdPunchlist]);
        obs.next(createdPunchlist);
      });
    return obs.asObservable();
  }

  public updatePunchlistItem(
    punchlistId: number,
    projectId: number,
    description: string,
    tradeId: number,
    location: string,
    additionalNotes: string,
    filesToAttach: UhatFileReference[],
    filesToRemove: UhatFileReference[]
  ) {
    const obs = new ReplaySubject<Punchlist>(1);
    // For each removed file we need to remove the existing link
    if (filesToRemove) {
      filesToRemove.forEach((file) => {
        // Unfortunately.. We have to go find the file id for the given punchlist and file so that we can unlink it from the bridge table
        this.getBridgeTableFileIdForPunchlist(punchlistId, file.id).subscribe((id) => {
          this.fileService.unlinkFile(id).subscribe((unlinked) => this.loadProjectPunchlists(projectId));
        });
      });
    }
    this.http
      .put(
        `${this.PUNCHLIST_URL}/${punchlistId}?fields=id,description,trade_id,trade_name,location,additional_notes,project_id,files,local_index`,
        {
          description,
          location,
          trade_id: tradeId,
          additional_notes: additionalNotes,
          project_id: projectId,
        }
      )
      .pipe(
        map((result: ServiceResponse) => result.data.punchlist),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((updatedPunchlist: Punchlist) => {
        // For each file we need to create the file and link it
        if (filesToAttach && filesToAttach.length > 0) {
          filesToAttach.forEach((f) => {
            this.fileService
              .linkFile(f.file_id, updatedPunchlist.id, ResourceType.Punchlist)
              .subscribe((linkedFile) => {
                if (updatedPunchlist.files) {
                  updatedPunchlist.files.push(linkedFile);
                } else {
                  updatedPunchlist.files = [linkedFile];
                }
                this.loadProjectPunchlists(projectId);
                obs.next(updatedPunchlist);
              });
          });
        } else {
          this.loadProjectPunchlists(projectId);
          obs.next(updatedPunchlist);
        }
      });
    return obs.asObservable();
  }

  public updateItem(punchlistId: number, punchlist: Punchlist): Observable<Punchlist> {
    return this.http
      .put(
        `${this.PUNCHLIST_URL}/${punchlistId}?fields=id,description,trade_id,trade_name,location,additional_notes,project_id,files,approval_task_id,local_index`,
        punchlist
      )
      .pipe(
        map((result: ServiceResponse) => result.data.punchlist),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public deletePunchlistItem(punchlist: Punchlist): Observable<any> {
    return this.http.delete(`${this.PUNCHLIST_URL}/${punchlist.id}`).pipe(
      map((result: ServiceResponse) => {
        this.loadProjectPunchlists(punchlist.project_id);
        this.projectPunchlists.removeItemById(punchlist.id);
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getBridgeTableFileIdForPunchlist(punchlistId: number, fileId: number): Observable<number> {
    return this.http
      .get(
        `${this.HOST}/api/v1/files?fields=id&filter=parent_id=${punchlistId},parent_type_id=${ResourceType.Punchlist},file_id=${fileId}`
      )
      .pipe(
        map((result: ServiceResponse) => (result.data.file ? result.data.file[0].id : null)),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public getProjectPunchlistTradesObservable(projectId: number): Observable<Trade[]> {
    return this.punchlistTrades.getObservableForId(projectId);
  }

  /** Retrieve Punchlists For The Givent Project */
  public loadProjectPunchlistTrades(projectId: number): Observable<Trade[]> {
    return this.http.get(`${this.BIDPACKAGES_URL}?fields=id,trade_id,trade_name&filter=project_id=${projectId}`).pipe(
      map((result: ServiceResponse) => {
        // Convert result data from bid_packages table into Trade objects and return the resulting array
        const trades = result.data.bid_packages.map((bpTrade) => {
          return { id: bpTrade.trade_id, name: bpTrade.trade_name };
        });
        this.punchlistTrades.pushDataForId(projectId, trades);
        return trades;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
