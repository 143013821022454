<app-base-dialog
  [title]="(projectProduct?.id ? 'Edit' : 'Add') + ' Product'"
  (closeDialog)="discardChanges()"
></app-base-dialog>
<div class="project-product-dialog project-modal p-0">
  <form [formGroup]="projectProductFormGroup">
    <div class="row mb-4">
      <form [formGroup]="productTemplateFormGroup" class="col">
        <h6 class="font-weight-semibold dkblue mb-2">Product*</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input
            type="text"
            placeholder="Select a Product"
            matInput
            [matAutocomplete]="product_auto"
            formControlName="product"
            (ngModelChange)="productChanged()"
          />
          <mat-error *ngIf="product.errors?.invalidProduct"
            >Select a product from the list or create a new one</mat-error
          >
          <mat-autocomplete autoActiveFirstOption #product_auto="matAutocomplete" [displayWith]="productValueMapper">
            <mat-option *ngFor="let p of filteredProducts" [value]="p">
              {{ p.name }}
            </mat-option>
            <mat-option class="green" (click)="openNewProductDialog()">
              <mat-icon>add_circle</mat-icon>
              <span> Create New Product </span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div class="row mb-4">
      <div class="col-4 pr-0">
        <h6 class="font-weight-semibold dkblue mb-2">Size</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput formControlName="size" placeholder="Size" />
        </mat-form-field>
      </div>
      <div class="col-4 pr-0">
        <h6 class="font-weight-semibold dkblue mb-2">Color/Finish</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput formControlName="color" placeholder="Color/Finish" />
        </mat-form-field>
      </div>
      <div class="col-4">
        <h6 class="font-weight-semibold dkblue mb-2">Model Number</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput formControlName="model_number" placeholder="Model #" />
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <span class="textarea">
          <h6 class="font-weight-semibold dkblue mb-2">Description/Notes</h6>
          <mat-form-field appearance="outline" class="full-width">
            <textarea
              matInput
              cdkTextareaAutosize
              resizeToFitContent="true"
              cdkAutosizeMaxRows="8"
              formControlName="description"
              style="min-height: 26px !important"
              placeholder="Description/Notes"
            ></textarea>
          </mat-form-field>
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col pr-0">
        <h6 class="font-weight-semibold dkblue mb-2">Quantity*</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            pattern="^\d*(\.\d{0,2})?$"
            class="text-right"
            formControlName="quantity"
            placeholder="Quantity"
            (keyup)="quantityChanged()"
            (blur)="quantityBlur()"
          />
        </mat-form-field>
      </div>
      <div class="col pr-0">
        <h6 class="font-weight-semibold dkblue mb-2">Unit Price</h6>
        <mat-form-field appearance="outline" class="full-width">
          <span class="gray" matPrefix>$&nbsp;</span>
          <input
            matInput
            pattern="^\d*(\.\d{0,2})?$"
            class="text-right"
            formControlName="unit_price"
            placeholder="0.00"
            step=".01"
            (keyup)="unitPriceChanged()"
            (blur)="unitPriceBlur()"
          />
        </mat-form-field>
      </div>
      <div class="col">
        <h6 class="font-weight-semibold dkblue mb-2">Total Price</h6>
        <mat-form-field appearance="outline" class="full-width">
          <span class="gray" matPrefix>$&nbsp;</span>
          <input
            matInput
            class="text-right"
            formControlName="total_price"
            placeholder="0.00"
            step=".01"
            (keyup)="totalChanged()"
            (blur)="totalBlur()"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <button mat-stroked-button color="accent" (click)="openUploadModal()">
          <span> Attach Files </span>
        </button>
        &nbsp;
        <mat-chip-list class="d-inline-block">
          <app-file-chip
            *ngFor="let file of productFiles"
            [file]="file"
            [removable]="true"
            (removeEvent)="removeFile(file)"
          ></app-file-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <button mat-button (click)="discardChanges()" mat-button type="button" class="gray full-width">Cancel</button>
      </div>
      <div class="col">
        <button mat-flat-button type="button" (click)="saveProjectProduct()" color="success" class="full-width">
          Save Product
        </button>
      </div>
    </div>
  </form>
</div>
