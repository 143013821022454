import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationService } from 'src/app/services';
import { Building, LocationExist } from 'src/app/types';
import { LOCATION_NOT_FOUND } from 'src/app/utils';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
})
export class BuildingComponent implements OnInit {
  @Input() public notFound: boolean;
  @Input() public required = true;
  @Input() public showLabel = true;
  @Input() public buildingIds: number[];
  @Input() public customPlaceholder: string;

  private _placeholder = 'Search Buildings';

  public building = new FormControl('');
  public filteredBuildings: Observable<Building[]>;
  public initialBuildingId: number;

  constructor(private _locationService: LocationService) {}

  async ngOnInit() {
    await this._updateValidations();
  }

  get placeholder() {
    return !this.showLabel && this.required ? `${this._placeholder}*` : this._placeholder;
  }

  private _filter(entry: string): Building[] {
    if (this.buildingIds && this.building?.value?.id && this.buildingIds.indexOf(this.building?.value?.id) === -1) {
      this.building.setValue(null);
    }
    return this._locationService.buildings.filter(
      (b) =>
        (b?.name?.toLowerCase()?.includes(entry?.toLowerCase()) || b?.isNotFound) &&
        (this.buildingIds ? this.buildingIds.indexOf(b?.id) > -1 : true) &&
        (b?.is_enabled === 1 || b?.id === this.initialBuildingId)
    );
  }

  private _filterExcludingNotFound(value: string): Building[] {
    if (this.buildingIds && this.building?.value?.id && this.buildingIds.indexOf(this.building?.value?.id) === -1) {
      this.building.setValue(null);
    }
    return this._locationService.buildings.filter(
      (building: Building) =>
        building?.name?.toLowerCase()?.includes(value?.toLowerCase()) &&
        (this.buildingIds ? this.buildingIds.indexOf(building?.id) > -1 : true)
    );
  }

  public async loadAllBuildings() {
    this._locationService.buildings = [
      ...(await this._locationService.getBuildings(['id', 'name', 'code', 'is_enabled']).toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  private async _subscribe() {
    this.filteredBuildings = this.building.valueChanges.pipe(
      startWith(''),
      map((value: string | Building) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => {
        if (name) {
          return this._filter(name);
        } else {
          return this._locationService.buildings.slice().filter((b) => {
            if (this.buildingIds && this.buildingIds.indexOf(b?.id) === -1) {
              return false;
            }
            if (!b.is_enabled && b?.id !== this.initialBuildingId) {
              return false;
            }
            return true;
          });
        }
      })
    );
  }

  private async _updateValidations() {
    if (this.required) {
      this.building.setValidators([Validators.required]);
    }
  }

  public checkValue() {
    // times when user changes his mind, back and forth
    // value in input and does not select it,
    // This will select it
    if (this.building?.value && typeof this.building?.value === 'string') {
      const foundBuildings = this._filterExcludingNotFound(this.building.value);
      if (foundBuildings.length === 1) {
        // we set it for them
        this.building.setValue(foundBuildings[0]);
      } else {
        this.building.setErrors({ doesNotExist: true });
      }
    }
  }

  public async clearInput(event = null) {
    this.building.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public mapper(building: Building): string {
    return building?.name || '';
  }

  public valueExist(value: string): LocationExist {
    const foundBuildings = this._filterExcludingNotFound(value);
    if (foundBuildings.length === 0) {
      this.building.setErrors({ doesNotExist: true });
      return { exists: false, id: null, single: false };
    }

    const id = foundBuildings.length === 1 ? foundBuildings[0].id : null;
    const single = foundBuildings.length === 1 ? true : false;
    return { exists: true, id, single };
  }
}
