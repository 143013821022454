<div class="m-portlet mt-1 p-0">
  <div
    *ngFor="let file of files; let last = last"
    class="m-portlet__body p-3 item file full-width d-flex align-items-center"
    [ngClass]="{ 'item-last': last }"
  >
    <app-file-thumbnail class="mr-3" width="36" height="36" [file]="file"></app-file-thumbnail>
    <div class="d-flex flex-column text-truncate mr-auto">
      <p (click)="previewFile(file)" class="dkblue hover-u m-0 lh-18 font-weight-semibold pointer text-truncate">
        {{ file.name }}
      </p>
    </div>
    <div class="d-flex align-items-center ml-auto">
      <mat-icon
        matRipple
        color="accent"
        (click)="previewFile(file)"
        class="ml-2 pointer"
        matTooltip="Preview"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        find_in_page</mat-icon
      >
      <mat-icon
        matRipple
        color="accent"
        (click)="downloadFile(file)"
        class="ml-2 pointer"
        matTooltip="Download"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        >get_app
      </mat-icon>
    </div>
  </div>
</div>
