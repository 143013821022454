import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalService, ProjectTaskService } from 'src/app/services';
import { Task, TaskAccessoryData } from 'src/app/types';
import { TaskReviewStatus, TaskStatus } from '../../enums';

@Component({
  selector: 'app-open-task-button',
  templateUrl: './open-task-button.component.html',
  styleUrls: ['./open-task-button.component.scss'],
})
export class OpenTaskButtonComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  @Input() lineHeight: number;
  @Input() fullWidth: boolean;
  @Input() title: string;
  @Input() taskIds: number[];
  public task: Task;
  public tasks: Task[] = [];
  public userId: number;
  public statusId: number;
  public accessoryData: TaskAccessoryData;
  public isNoteMarking: boolean;
  private taskUpdatedEvent: any;
  private statusUpdatedEvent: any;
  @Output() statusChanged = new EventEmitter<number>();

  constructor(private dialog: MatDialog, private modalService: ModalService, private taskService: ProjectTaskService) {}

  ngOnInit() {
    this.title = this.title || 'View Task';
    if (this.taskIds?.length === 1) {
      this.taskId = this.taskIds[0];
      this.taskIds = null;
    }

    this.getTaskDetails();

    this.taskUpdatedEvent = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      const selectedTask = this.task || this.tasks?.find((t) => data?.task?.id === t.id);
      if (data?.task?.id === selectedTask?.id) {
        this.getTaskDetails([selectedTask.id]);
      }
    });

    this.statusUpdatedEvent = this.taskService.milestoneTaskEvent.subscribe(async (task) => {
      const selectedTask = this.task || this.tasks?.find((t) => task.id === t.id);
      if (task?.id === selectedTask?.id && task.status_id !== selectedTask?.status_id) {
        this.getTaskDetails([selectedTask.id]);
      }
    });
  }

  ngOnDestroy() {
    // attempt to close all active subscriptions
    try {
      this.taskUpdatedEvent.unsubscribe();
      this.statusUpdatedEvent.unsubscribe();
    } catch (e) {}
  }

  public async getTaskDetails(taskIds: number[] = null): Promise<void> {
    const originalStatusId = this.statusId;
    const ids = taskIds || [...([this.taskId] || []), ...(this.taskIds || [])];

    for (const id of ids) {
      if (id) {
        const task = await this.taskService.loadTask(id).toPromise();
        if (task) {
          this.accessoryData = task.accessory_data ? JSON.parse(task.accessory_data) : null;

          if (this.taskId) {
            this.task = task;
          } else {
            const taskIndex = this.tasks.findIndex((t) => t.id === id);
            if (taskIndex !== -1) {
              this.tasks[taskIndex] = task;
            } else {
              this.tasks.push(task);
            }
          }

          if (this.accessoryData?.isReviewItem) {
            // Check for rejection - rejection trumps all other statuses
            if (this.accessoryData.reviewChain.filter((review) => review.status === TaskReviewStatus.Rejected).length) {
              this.statusId = 0;
            }
          }
        }
      }
    }

    if (this.taskId) {
      this.userId = this.task?.assigned_user_id;
      this.statusId = this.task?.status_id;
    } else {
      this.statusId =
        (this.statusId !== 0 &&
          this.tasks.find((t) => [TaskStatus.Open, TaskStatus.Pending].includes(t?.status_id))?.status_id) ||
        TaskStatus.Complete ||
        this.statusId;
    }

    if (originalStatusId !== this.statusId) {
      this.statusChanged.emit(this.statusId);
    }
  }

  public async viewTask(): Promise<void> {
    let res;
    if (this.taskId) {
      res = await this.modalService.openViewTaskModal(this.taskId).toPromise();
    } else {
      const data = {
        tasks: this.tasks,
        dontFilterTasks: true,
      };
      res = await this.modalService.openTaskOverviewDialog(data).toPromise();
    }

    if (res) {
      this.getTaskDetails();
    }
  }
}
