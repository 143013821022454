import { Pipe, PipeTransform } from '@angular/core';
import { ArfListDynamicFilterValue } from 'src/app/pages';
import { Arf } from 'src/app/types';

@Pipe({
  name: 'arfStatusCount',
})
export class ArfStatusCountPipe implements PipeTransform {
  transform(arf: Arf[], statusId: string | number, filters: ArfListDynamicFilterValue): number {
    const filterFunction = (data): boolean => {
      const includesCompany =
        !filters.company || data.company?.name.toLowerCase().includes(filters.company.trim().toLowerCase());
      const includesModule = !filters.moduleIds.length || filters.moduleIds.includes(data.module?.id.toString());
      const includesFiscalYear =
        !filters.fiscalYears.length || filters.fiscalYears.includes(data.fiscal_year?.toString() || 'no fiscal year');
      const includesSearch =
        !filters.search ||
        (data.title && data.title.toLowerCase().includes(filters.search.trim().toLowerCase())) ||
        (data.description && data.description.toLowerCase().includes(filters.search.trim().toLowerCase()));
      return includesCompany && includesModule && includesFiscalYear && includesSearch;
    };
    // when no filter is set, ie. 'All Active', then omit closed status IDs (5)
    return arf
      .filter((arf) =>
        statusId === 'all' ? arf.arf_status.id !== 5 : arf.arf_status.id.toString() === statusId.toString()
      )
      .filter(filterFunction).length;
  }
}
