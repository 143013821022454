<app-base-dialog [draggable]="false" [title]="dialogTitle" (closeDialog)="cancel()"></app-base-dialog>
<div data-test-id="agenda-item-dialog" id="agenda-item-dialog" class="project-modal agenda-item-dialog">
  <form [formGroup]="agendaItemFormGroup">
    <div mat-dialog-content>
      <div class="row mb-4 pb-2" *ngIf="agendaItem.meeting_id">
        <div class="col">
          <h5 class="dkblue m-0 font-weight-semibold">
            <mat-icon class="icon-meeting mr-2">event_note</mat-icon>
            <span>
              {{ agendaItem.meeting_title }}
            </span>
          </h5>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="main-label dkblue mb-2"> Description </label>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              placeholder="What needs to be discussed?"
              required="true"
              type="text"
              formControlName="description"
            />
            <mat-error *ngIf="description.errors?.required">Description is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label class="main-label dkblue mb-2"> Duration </label>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="# of Minutes" required="false" type="number" formControlName="duration" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4" style="margin-top: -10px">
        <div class="col-6">
          <button
            mat-button
            [disabled]="duration.value <= 0"
            color="accent"
            class="full-width"
            (click)="addDurationMinutes(-5)"
          >
            - 5 Mins
          </button>
        </div>
        <div class="col-6">
          <button mat-button color="accent" class="full-width" (click)="addDurationMinutes(5)">+ 5 Mins</button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="row pt-4 pb-4 align-items-center">
      <div class="col">
        <button mat-button class="gray full-width" (click)="cancel()">Cancel</button>
      </div>
      <div class="col">
        <button mat-flat-button color="success" class="full-width" (click)="submit()">
          {{ agendaItem.id ? 'Update' : 'Add' }} Agenda Item
        </button>
      </div>
    </div>
  </form>
</div>
