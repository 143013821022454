export enum ResourceType {
  App = 1,
  Module = 2,
  Project = 3,
  Company = 4,
  Phase = 5,
  Milestone = 6,
  Task = 7,
  Request = 8,
  Reminder = 9,
  Meeting = 10,
  Agenda = 11,
  Channel = 12,
  Conversation = 13,
  Message = 14,
  BidPackage = 15,
  Bid = 16,
  ProposalRequest = 17,
  ChangeOrder = 18,
  AsBuilt = 19,
  Note = 20,
  Addendum = 21,
  Invoice = 22,
  Timeline = 23,
  Punchlist = 24,
  RFI = 25,
  RFIComment = 26,
  FileApproval = 27,
  FileApprovalItem = 28,
  BidRequirement = 29,
  FileSelectedItem = 30,
  Solicitation = 31,
  ProjectUpdate = 32,
  BugReport = 33,
  WorkOrder = 34,
  ProjectProduct = 36,
  Quote = 37,
  DispatchDraft = 38,
  Event = 39,
  WorkOrderUpdate = 40,
  Department = 50,
  Submittal = 78,
  AccountRequest = 108,
  ReleaseNoteBug = 122,
  ReleaseNoteFeature = 123,
  ReleaseNoteRelease = 124,
  ARHistory = 129,
  BidContract = 130,
  CompanyItems = 132,
  CompanyInsurance = 133,
  AcquisitionRequestForm = 134,
  Users = 110,
}
