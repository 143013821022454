import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskReviewStatus, TaskStatus } from '../../enums';
import { AuthService, ProductService, ProjectService, TaskActionsService } from '../../services';
import { TaskAccessoryData } from '../../types';

@Component({
  selector: 'app-task-status-icon',
  templateUrl: './task-status-icon.component.html',
  styleUrls: ['./task-status-icon.component.scss'],
})
export class TaskStatusIconComponent implements OnInit {
  @Input() accessoryData: TaskAccessoryData;
  @Input() task: any;
  @Output() taskStatusChanged = new EventEmitter();

  public previousTaskStatusId: TaskStatus;
  public showUndoTaskStatus: boolean;
  public isOpenReview: boolean;

  constructor(
    public authService: AuthService,
    private projectService: ProjectService,
    private productService: ProductService,
    private snackbar: MatSnackBar,
    private taskActionsService: TaskActionsService
  ) {}

  ngOnInit(): void {
    if (!this.accessoryData) {
      this.accessoryData = this.task?.accessory_data && JSON.parse(this.task.accessory_data);
    }

    this.isOpenReview = this.authService.isOpenReview(this.accessoryData?.type);
  }

  public get approvalStatus() {
    return this.accessoryData?.reviewChain?.find(
      (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
    );
  }

  public get isApproval() {
    return this.accessoryData?.isReviewItem;
  }

  public get isLinkedTask() {
    return this.task?.work_order_id;
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.task?.module_id);
  }
  public async changeTaskStatus(taskStatusId) {
    this.task = await this.taskActionsService.changeTaskStatus(taskStatusId, this.task, this.accessoryData?.type);
    this.taskStatusChanged.emit(this.task);
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }
}
