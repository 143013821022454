import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { momentDays, ProjectStatus } from 'src/app/enums';
import {
  AuthService,
  HandleErrorService,
  MeetingService,
  ProjectService,
  ProjectTaskService,
  RequestService,
  UserService,
} from 'src/app/services';
import { Meeting, Request, ServiceResponse, Task, User } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private allTasks: Task[] = [];
  private allProjects: ProjectConstruction[] = [];
  private allMeetings: Meeting[] = [];
  private allRequests: Request[] = [];
  private allUsers: User[] = [];

  // dashboard data for maintaining place on page

  // the number of days to offset the days to filter tasks, and the phrase displayed to the user
  startDays;
  endDays;
  timeframePhrase: 'today' | 'this week' | 'next week' | 'this month';

  // possible selection for the drop down buttons under tasks
  taskSelector: 'all' | 'approvals_only' | 'overdue_only' = 'all';
  filterSelector: 'date' | 'assignee' = 'date';
  showTasksTab = true;
  showRequestsTab = false;
  showProjectsTab = false;
  showMeetingsTab = false;
  // the selected tab for the dashboard
  selectedTab: 'tasks' | 'team' | 'following' | 'unassigned' = 'tasks';
  // whether the show complete button is pressed under tasks
  showComplete = false;
  // whether the tasks with no due date are shown
  showNoDueDate = false;
  // whether we are showing active or on hold projects in the project tab
  showActive = true;
  // whether we are showing upcoming or recent meetings in the meeting tab
  showUpcoming = true;
  // whether we are showing pending or accepted requests in the requests tab
  showPending = true;

  // array of all project ids that are currently expanded
  expandedProjects: number[] = [];

  // array of all user ids that are currently expanded
  expandedUsers: number[] = [];

  // whether we have reloaded the data
  dataLoaded = false;

  host: string = environment.serviceHost;
  dashboardUrl = `${this.host}/api/v1/dashboard`;

  get myTasks() {
    return this.selectedTab === 'tasks';
  }
  get myTeam() {
    return this.selectedTab === 'team';
  }
  get following() {
    return this.selectedTab === 'following';
  }
  get unassigned() {
    return this.selectedTab === 'unassigned';
  }

  // finds the first occurrence of the passed day, starting the search after the offset (where searchAfter is +- X days from today)
  // lookFor: 0 = mon, 1 = tues, 6 = sun
  public static daysUntilNext(lookFor: momentDays, searchAfter: number = 0) {
    // get today's date
    let today = moment().startOf('day').add(searchAfter, 'days');
    // if the day we are looking for happened earlier this week, add a week
    if (today.isoWeekday() >= lookFor) {
      today = today.add(1, 'weeks');
    }
    // find the first occurrence of the day of the week we want in this week
    const found = today.isoWeekday(lookFor);

    // get the difference between the day we want and today
    return found.diff(moment().startOf('day'), 'days');
  }

  // gets the number of days until the last day of the month (offset by searchAfter)
  public static getDaysToEndOfMonth(searchAfter = 0) {
    // get the current day, add a month, and then get the last day of the previous month by .date(0)
    const lastDay = moment().startOf('day').add(searchAfter, 'days').add(1, 'month').date(0);
    const today = moment().startOf('day');
    // find the difference between the day and today
    return lastDay.diff(today, 'days');
  }

  get currentUserId() {
    return this.authService.getLoggedInUser().id;
  }
  // returns an array of tasks, use this with taskFilter for Tasks Due - By Date
  // returns all tasks where created by or follower ids intersect with userId or followerIds
  get tasks() {
    return this.allTasks;
  }

  constructor(
    public authService: AuthService,
    private taskService: ProjectTaskService,
    private projectService: ProjectService,
    private userService: UserService,
    private meetingService: MeetingService,
    private requestService: RequestService,
    private http: HttpClient,
    private handleErrorService: HandleErrorService
  ) {
    this.setDays('this week');
  }

  // gets all team and user ids
  public async getAllRelevantUserIds(): Promise<number[]> {
    // get all staff ids
    return await this.getSelectedTeamIds();
  }

  // gets all team and user ids
  public async getAllTeamIds(): Promise<number[]> {
    // get all staff ids
    const teamIds = await this.getSelectedTeamIds();
    // remove the current user
    teamIds.splice(teamIds.indexOf(this.currentUserId), 1);
    return teamIds;
  }

  // loads all the tasks for these users and projects for the tasks
  public async loadAllTasksAndProjects() {
    const dashboardData = await this.getDashboardData().toPromise();

    this.allProjects = dashboardData.allProjects ? dashboardData.allProjects : [];
    this.allTasks = dashboardData.allTasks ? dashboardData.allTasks : [];
    this.allUsers = dashboardData.allUsers ? dashboardData.allUsers : [];
    this.allMeetings = dashboardData.allMeetings ? dashboardData.allMeetings : [];
    this.allRequests = dashboardData.allRequests ? dashboardData.allRequests : [];

    this.dataLoaded = true;
  }

  getDashboardData() {
    return this.http.get(`${this.dashboardUrl}/`).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // returns the team ids as selected in the icons to the left of All Tasks
  // currently returns nothing as teams aren't implemented
  // TODO add this in
  async getSelectedTeamIds(): Promise<number[]> {
    let ret = [];
    await this.userService
      .getUsersByType(1)
      .toPromise()
      .then((users) => (ret = users.map((u) => u.id)));
    return ret;
  }

  // returns all projects that have tasks from getTasks
  getProjects(): ProjectConstruction[] {
    return this.allProjects;
  }

  // returns all meetings where the attendees or organizer is the current user id
  getMeetings(): Meeting[] {
    return this.allMeetings;
  }

  // returns all requests where the requester, workspace manager, architect, created_by
  getRequests(): Request[] {
    return this.allRequests;
  }

  // returns all users relevant to these tasks
  getUsers(): User[] {
    return this.allUsers;
  }

  // sets the expanded state for a project
  toggleProject(id: number) {
    if (!this.expandedProjects.includes(id)) {
      this.expandedProjects.push(id);
    } else {
      this.expandedProjects.splice(this.expandedProjects.indexOf(id), 1);
    }
  }
  isProjectExpanded(id: number): boolean {
    return this.expandedProjects.includes(id);
  }

  // sets the expanded state for a user
  toggleUser(id: number) {
    if (!this.expandedUsers.includes(id)) {
      this.expandedUsers.push(id);
    } else {
      this.expandedUsers.splice(this.expandedUsers.indexOf(id), 1);
    }
  }
  isUserExpanded(id: number): boolean {
    return this.expandedUsers.includes(id);
  }

  // formats the correct date string for display on the dashboard
  get timeExpression() {
    if (this.timeframePhrase === 'today') {
      return `${moment().add(this.startDays, 'days').format('ddd, MMM Do')}`;
    } else {
      return `${moment().add(this.startDays, 'days').format('ddd, MMM Do')} - ${moment()
        .add(this.endDays, 'days')
        .format('ddd, MMM Do')}`;
    }
  }

  // converts the bool for the page to the correct string for the filter
  get assignedTo() {
    if (this.myTeam) {
      return 'team';
    } else if (this.following) {
      return 'following';
    } else if (this.unassigned) {
      return 'unassigned';
    } else {
      return 'self';
    }
  }
  // converts the bool for the page to the correct string for the filter
  get selectedProjectStatusId() {
    if (this.showActive) {
      return ProjectStatus.ACTIVE;
    } else {
      return ProjectStatus.ON_HOLD;
    }
  }
  // converts the bool for the page to the correct string for the filter
  get selectedMeetingStatus() {
    if (this.showUpcoming) {
      return 'upcoming';
    } else {
      return 'recent';
    }
  }
  // converts the bool for the page to the correct string for the filter
  get selectedRequestStatus() {
    if (this.showPending) {
      return 'pending';
    } else {
      return 'accepted';
    }
  }
  // converts the bool for the page to the correct string for the filter
  get taskSelectorPhrase() {
    if (this.taskSelector === 'approvals_only') {
      return 'Approvals';
    } else if (this.taskSelector === 'overdue_only') {
      return 'Overdue';
    } else {
      return 'All Tasks';
    }
  }
  // converts the bool for the page to the correct string for the filter
  get filterSelectorPhrase() {
    if (this.filterSelector === 'assignee') {
      return 'By Assignee';
    } else {
      return 'By Date';
    }
  }

  // called when a new date range is selected
  // sets the correct phrase for the display and sets the start and end dates appropriately
  setDays(phrase: 'today' | 'this week' | 'next week' | 'this month') {
    this.timeframePhrase = phrase;
    switch (phrase) {
      case 'today':
        this.startDays = 0;
        this.endDays = 0;
        break;
      case 'this week':
        this.startDays = 0;
        this.endDays = DashboardService.daysUntilNext(momentDays.SUNDAY);
        break;
      case 'next week':
        this.startDays = DashboardService.daysUntilNext(momentDays.MONDAY);
        this.endDays = DashboardService.daysUntilNext(momentDays.SUNDAY, this.startDays);
        break;
      case 'this month':
        this.startDays = 0;
        this.endDays = DashboardService.getDaysToEndOfMonth();
        break;
      default:
        break;
    }
    return this.timeframePhrase;
  }

  // workspace dashboard data
  getWorkspaceDashboardData(
    module_id: number,
    getWorkOrders: boolean,
    getTasks: boolean,
    getProjects: boolean,
    getProjectUpdates = false,
    getWorkOrderUpdates = false,
    limit: number = 100
  ) {
    return this.http
      .get(
        `${this.dashboardUrl}/${module_id}?workOrders=${getWorkOrders}&tasks=${getTasks}&projects=${getProjects}&projectUpdates=${getProjectUpdates}&workOrderUpdates=${getWorkOrderUpdates}&limit=${limit}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
}
