export enum EventType {
  FOLLOW = 0,
  UNFOLLOW = 1,
  APPROVED = 2,
  REJECTED = 3,
  COMPLETED = 4,
  INCOMPLETE = 5,
  ONHOLD = 6,
  OFFHOLD = 7,
  NOTIFICATION = 8,
  AUDITED = 9,
  FLAGGED = 10,
  REVISIONREQUESTED = 11,
  CONTRACTSIGNED = 12,
  NOTEMARKING = 13,
  NOEXCEPTIONSTAKEN = 14,
  REVISEANDRESUBMIT = 15,
}
