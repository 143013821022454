import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { cloneDeep, orderBy, pullAllBy } from 'lodash';
import * as moment from 'moment';
import { UserProfilePreviewComponent } from 'src/app/components';
import {
  ApplicationRole,
  RequestStatus as RequestStatusEnum,
  ResourceType,
  Workspace as WS,
  WorkspaceType,
} from 'src/app/enums';
import {
  AuthService,
  FileService,
  ModalService,
  ModuleService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTemplateService,
  RequestService,
  SidenavLinksService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import { APIFilter, ProjectTemplate, Request, Workspace } from 'src/app/types';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit, OnDestroy {
  constructor(
    private requestService: RequestService,
    private projectService: ProjectService,
    private projectTemplateService: ProjectTemplateService,
    private userService: UserService,
    private fileService: FileService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    public authService: AuthService,
    private sidenavLinksService: SidenavLinksService,
    // private messagingService: MessagingSystemService,
    private moduleService: ModuleService,
    private progressIndicatorService: ProgressIndicatorService,
    private workOrderService: WorkOrderService
  ) {}

  requestFields = [
    'id',
    'code',
    'workspace{id,name,needs_dfs_approval,show_dfs_toggle,workspace_type_id}',
    'topic{id,name,topic_category{id,name,topic_group{id,name}}}',
    'cfmo',
    'created_datetime',
    'budget_description',
    'building{id,name,code}',
    'bsd',
    'dfs',
    'ico',
    'end_date',
    'floor{id,name,code}',
    'department{id,name}',
    'lease_term',
    'suite{id,name}',
    'short_description',
    'start_date',
    'summary',
    'requester{id,first_name,last_name,department{id,name}}',
    'request_type_id',
    'request_type_name',
    'request_status_log{updated_by{first_name,last_name},updated_status{name},created_datetime}',
    'rooms',
    'contact_ids',
    'status{id,name}',
    'project_manager_id',
    'workspace_manager_id',
    'architect_id',
    'is_architect_required',
    'account_coordinator_id',
    'is_dfs_required',
    'project_template_ids',
    'landmark',
    'parent_bid_package_id',
    'parent_bid_package_project_id',
    'parent_bid_package_project_code',
    'converted_from_work_order{id}',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
    'work_order_id',
  ];
  userFields = ['id', 'first_name', 'last_name'];

  is_expanded = false;
  request: Request;
  previousRequest: Request;
  nextRequest: Request;
  contacts = [];
  rooms = [];
  emailLink: string;
  projectTemplates: ProjectTemplate[];
  selectedTemplates: ProjectTemplate[];
  availableTemplates: ProjectTemplate[];
  showTemplateSelect = false;
  public cfmos;
  public bsds;
  public dfsManagers;
  public icos;
  public projectManagers;
  public workspaceManagers;
  public architects;
  public accountCoordinators;
  public oneCallDirectors;
  validationErrors: any = {};
  showValidationErrors = false;
  public shortDescChanged = false;
  public shortDescription;
  files;
  downloading = false;
  loaders = {
    convertRequest: false,
  };
  workspaces: Workspace[];
  workspaceToReroute: Workspace;
  private refreshSubscription;

  get isWorkspaceAdmin() {
    return (
      this.request &&
      this.request.module_id &&
      (this.authService.isUserWorkspaceAdmin(this.request.module_id) ||
        (this.request.module_id !== WS.Construction && this.authService.isModuleProjectManager(this.request.module_id)))
    );
  }

  get isStaff() {
    return this.authService.isStaffOnAnyModule;
  }

  get isWorkspaceStaff() {
    return this.request && this.request.module_id && this.authService.isUserWorkspaceStaff(this.request.module_id);
  }

  ngOnInit() {
    setTimeout(() => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.requests);
    });

    this.route.params.subscribe((params: Params) => {
      this.refresh(params.id);
    });
    // now also watches for refreshNeeded (to fix issue where routing to same link from notifications doesn't refresh page/data)
    this.refreshSubscription = this.requestService.refreshNeeded$.subscribe(async () => {
      await this.refresh(this.request.id);
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  async refresh(requestId) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading request...');
    const request: Request = await this.requestService.getRequestById(requestId, this.requestFields).toPromise();
    this.shortDescription = request?.short_description || '';
    const previousRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '<',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: `${RequestStatusEnum.PENDING.toString()}` },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'module_id', value: request.workspace?.id.toString() },
    ];
    this.requestService
      .getRequests(['id'], previousRequestFilters, 1, 'created_datetime', 'desc')
      .subscribe((requests) => {
        this.previousRequest = requests[0];
      });
    const nextRequestFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'created_datetime',
        value: moment(request.created_datetime).format('YYYY-MM-DD hh:mm:ss'),
        match: '>',
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', value: `${RequestStatusEnum.PENDING.toString()}` },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'module_id', value: request.workspace?.id.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'id', value: request.id.toString(), match: '!=' },
    ];
    this.requestService.getRequests(['id'], nextRequestFilters, 1, 'created_datetime', 'asc').subscribe((requests) => {
      this.nextRequest = requests[0];
    });
    this.request = request;
    this.rooms = request.rooms ? JSON.parse(request.rooms) : [];
    const contactIds = request.contact_ids ? JSON.parse(request.contact_ids) : [];
    this.contacts = [];
    for (const cid of contactIds) {
      const user = await this.userService
        .getUserById(cid, ['id', 'first_name', 'last_name', 'department{id,name}', 'email'])
        .toPromise();
      this.contacts.push(user);
    }

    if (this.request.status.id === RequestStatusEnum.REJECTED) {
      this.emailLink = this.getEmailLink();
    }

    this.projectTemplates = await this.projectTemplateService
      .getProjectTemplates(
        ['name', 'is_removable', 'is_hidden', 'workspace_id', 'phases{milestones{tasks}}'],
        [
          {
            type: 'field',
            field: 'workspace_id',
            value: `${(this.request?.workspace?.id || '').toString()}^null`,
          },
        ]
      )
      .toPromise();
    for (const t of this.projectTemplates) {
      let milestoneCount = 0;
      let taskCount = 0;
      for (const p of t.phases || []) {
        for (const m of p.milestones || []) {
          milestoneCount++;
          taskCount += (m.tasks || []).length;
        }
      }
      t.milestoneCount = milestoneCount;
      t.taskCount = taskCount;
    }
    this.selectedTemplates = [];
    const currentTemplateIds = this.request.project_template_ids ? JSON.parse(this.request.project_template_ids) : [];
    for (const templateId of currentTemplateIds) {
      const foundTemplate = this.projectTemplates.find((t) => t.id === templateId);
      if (foundTemplate) {
        this.selectedTemplates.push(foundTemplate);
      }
    }
    // since the template ids are stored accordung to which one was added first, we have to order here when they are found in the ordered list of project templates
    this.selectedTemplates = orderBy(this.selectedTemplates, [(template) => template?.name?.toLocaleLowerCase()]);
    this.availableTemplates = pullAllBy(
      cloneDeep(this.projectTemplates.filter((t) => !t.is_hidden)),
      this.selectedTemplates,
      'id'
    );
    this.getValidationErrors();
    this.cfmos = await this.userService
      .getUsers(
        [
          {
            type: 'field',
            field: 'role_id',
            value: ApplicationRole.ChiefFacilitiesManagementOfficer.toString(),
          },
        ],
        this.userFields
      )
      .toPromise();

    if (
      request.status_id === RequestStatusEnum.ACCEPTED &&
      !this.request.cfmo_id &&
      this.cfmos.length === 1 &&
      this.isStaff
    ) {
      this.request.cfmo_id = this.cfmos[0].id;
      this.changeUser('cfmo_id', this.cfmos[0]?.id);
    }

    this.projectManagers = await this.userService
      .getUsersByRole(ApplicationRole.ModuleProjectManager, request.module_id, this.userFields, null, false, [
        this.request?.project_manager_id,
      ])
      .toPromise();

    if (
      request.status_id === RequestStatusEnum.ACCEPTED &&
      !this.request.project_manager_id &&
      this.projectManagers.length === 1 &&
      this.isStaff
    ) {
      this.request.project_manager_id = this.projectManagers[0].id;
      this.changeProjectManager(this.projectManagers[0]);
    }

    this.workspaceManagers = await this.userService
      .getUsersByRole(ApplicationRole.WorkspaceManager, request.module_id, this.userFields, null, false, [
        this.request?.workspace_manager_id,
      ])
      .toPromise();

    if (
      request.status_id === RequestStatusEnum.ACCEPTED &&
      !this.request.workspace_manager_id &&
      this.workspaceManagers.length === 1 &&
      this.isStaff
    ) {
      this.request.workspace_manager_id = this.workspaceManagers[0].id;
      this.changeWorkspaceManager(this.workspaceManagers[0]);
    }

    this.accountCoordinators = await this.userService
      .getUsersByRole(ApplicationRole.AccountCoordinator, request.module_id, this.userFields)
      .toPromise();

    if (
      request.status_id === RequestStatusEnum.ACCEPTED &&
      !this.request.account_coordinator_id &&
      this.accountCoordinators.length === 1 &&
      this.isStaff
    ) {
      this.request.account_coordinator_id = this.accountCoordinators[0]?.id;
      this.changeUser('account_coordinator_id', this.accountCoordinators[0]?.id);
    }

    if (this.isConstruction) {
      this.architects = await this.userService
        .getUsersByRole(ApplicationRole.ModuleArchitect, request.module_id, this.userFields, null, false, [
          this.request?.architect_id,
        ])
        .toPromise();

      if (
        request.status_id === RequestStatusEnum.ACCEPTED &&
        !this.request.architect_id &&
        this.architects.length === 1 &&
        this.isStaff
      ) {
        this.request.architect_id = this.architects[0].id;
        this.changeArchitect(this.architects[0]);
      }
    }

    if (this.needsDFSManager) {
      this.dfsManagers = await this.userService
        .getUsersByRole(ApplicationRole.DirectorOfFacilitySolutions, request.module_id, this.userFields, null, false, [
          this.request?.dfs_id,
        ])
        .toPromise();

      if (
        request.status_id === RequestStatusEnum.ACCEPTED &&
        !this.request.dfs_id &&
        this.request.is_dfs_required &&
        this.dfsManagers.length === 1 &&
        this.isStaff
      ) {
        this.request.dfs_id = this.dfsManagers[0].id;
        this.changeUser('dfs_id', this.dfsManagers[0].id);
      }
    }

    if (this.isOneCallWorkspace) {
      this.oneCallDirectors = await this.userService
        .getUsers(
          [
            {
              type: 'field',
              field: 'role_id',
              value: ApplicationRole.OneCallDirector.toString(),
            },
          ],
          this.userFields
        )
        .toPromise();

      if (
        request.status_id === RequestStatusEnum.ACCEPTED &&
        !this.request.one_call_director_id &&
        this.oneCallDirectors.length === 1 &&
        this.isStaff
      ) {
        this.request.one_call_director_id = this.oneCallDirectors[0].id;
        this.changeUser('one_call_director_id', this.oneCallDirectors[0].id);
      }
    }

    if (!this.isConstruction) {
      this.icos = await this.userService
        .getUsersByRole(ApplicationRole.InventoryControlOfficer, 1, this.userFields, null, false, [
          this.request?.ico_id,
        ])
        .toPromise();

      if (
        request.status_id === RequestStatusEnum.ACCEPTED &&
        !this.request.ico_id &&
        this.icos.length === 1 &&
        this.isStaff
      ) {
        this.request.ico_id = this.icos[0].id;
        this.changeUser('ico_id', this.icos[0].id);
      }

      this.bsds = await this.userService
        .getBSDUsers(this.request?.workspace?.workspace_type_id, [this.request?.bsd_id])
        .toPromise();

      if (
        request.status_id === RequestStatusEnum.ACCEPTED &&
        !this.request.bsd_id &&
        this.bsds.length === 1 &&
        this.isStaff
      ) {
        this.request.bsd_id = this.bsds[0].id;
        this.changeUser('bsd_id', this.bsds[0].id);
      }
    }
    this.files = await this.fileService.getFilesByParentId(ResourceType.Request, request.id, null, 20).toPromise();
    // for (const file of this.files) {
    //   this.fileService.fillFileWithBase64(file);
    // }
    this.workspaces = this.moduleService.allWorkspaces;
    this.workspaceToReroute = this.workspaces?.find((w) => w.id === WS.Dispatch);
    this.progressIndicatorService.close();
  }

  get landmark(): string {
    return this.request?.landmark || '-';
  }

  get RequestStatusEnum() {
    return RequestStatusEnum;
  }

  downloadFile(file) {
    file.loading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.loading = false;
    });
  }

  public async changeRequestStatus(statusId: RequestStatusEnum) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Changing request status...');

    await this.requestService.changeRequestStatus(this.request.id, statusId).toPromise();
    await this.refresh(this.request.id);

    this.progressIndicatorService.close();
  }

  changeProjectManager(projectManager) {
    this.requestService
      .setRequestProjectManager(this.request.id, projectManager ? projectManager.id : null)
      .subscribe();
  }

  changeWorkspaceManager(workspaceManager) {
    this.requestService
      .setRequestWorkspaceManager(this.request.id, workspaceManager ? workspaceManager.id : null)
      .subscribe();
  }

  changeArchitect(architect) {
    this.request.architect_id = architect?.id;
    this.requestService.setRequestArchitect(this.request.id, architect ? architect.id : null).subscribe();
  }

  changeUser(userKey, userId) {
    this.requestService.setRequestUser(this.request.id, userKey, userId).subscribe();
  }

  async changeArchitectRequired() {
    this.request.is_architect_required = this.request?.is_architect_required ? 0 : 1;
    const requestData: Request = {
      is_architect_required: this.request.is_architect_required,
    };

    if (!this.request.is_architect_required) {
      this.request.architect_id = null;
      requestData.architect_id = null;
    }

    await this.requestService.updateRequest(this.request.id, requestData).toPromise();
  }

  async changeDFSRequired() {
    this.request.is_dfs_required = this.request?.is_dfs_required ? 0 : 1;
    const requestData: Request = {
      is_dfs_required: this.request.is_dfs_required,
    };

    if (!this.request.is_dfs_required) {
      this.request.dfs_id = null;
      requestData.dfs_id = null;
    }

    const updatedRequest = await this.requestService
      .updateRequest(this.request.id, requestData, ['is_dfs_required', 'dfs_id'])
      .toPromise();
  }

  async changeShortDescription(shortDesc) {
    this.shortDescChanged = false;
    this.request.short_description = shortDesc;
    await this.requestService.updateRequest(this.request.id, { short_description: shortDesc }).toPromise();
  }

  addTemplate(projectTemplate: ProjectTemplate) {
    this.selectedTemplates.push(projectTemplate);
    this.showTemplateSelect = false;
    const selectedTemplateIds = this.selectedTemplates.map((t) => t.id);
    this.requestService
      .setRequestProjectTemplates(
        this.request.id,
        selectedTemplateIds && selectedTemplateIds.length > 0 ? selectedTemplateIds : null
      )
      .subscribe(() => {
        this.refresh(this.request.id);
      });
  }

  removeTemplate(index) {
    this.selectedTemplates.splice(index, 1);
    const selectedTemplateIds = this.selectedTemplates.map((t) => t.id);
    this.requestService
      .setRequestProjectTemplates(
        this.request.id,
        selectedTemplateIds && selectedTemplateIds.length > 0 ? selectedTemplateIds : null
      )
      .subscribe(() => {
        this.refresh(this.request.id);
      });
  }

  getValidationErrors() {
    this.validationErrors = { valid: true };
    if (!this.request || this.request.status_id !== RequestStatusEnum.ACCEPTED) {
      this.validationErrors.valid = false;
      this.validationErrors.accepted = true;
    }
    if (!this.selectedTemplates || this.selectedTemplates.length < 1) {
      this.validationErrors.valid = false;
      this.validationErrors.templateCount = true;
    }
    return this.validationErrors;
  }

  async convertToProject() {
    // Note that only the PM is required
    if (!this.request.project_manager_id) {
      this.snackbar.open('Project Manager is required');
      return;
    }

    if (this.isConstruction && this.request?.is_architect_required && !this.request?.architect_id) {
      this.snackbar.open('Architect Required');
      return;
    }

    this.loaders.convertRequest = true;
    await this.changeShortDescription(this.shortDescription);

    const project = await this.requestService.convertRequestToProject(this.request.id).toPromise();
    if (project) {
      this.loaders.convertRequest = false;
      this.snackbar.open('Request converted to project!');
      this.refresh(this.request.id);
    } else {
      this.loaders.convertRequest = false;
    }
  }

  async goToProject() {
    const projectId = await this.projectService.getProjectIdByRequestId(this.request.id).toPromise();
    if (projectId) {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.projectView);
      this.router.navigateByUrl(`/projects/${projectId[0].id}`);
    }
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  public get isConstruction() {
    return +this.request?.module_id === WS.Construction;
  }

  public get needsDFSManager(): boolean {
    return !!this.request?.workspace?.needs_dfs_approval;
  }

  public get isOneCallWorkspace(): boolean {
    return this.request?.workspace?.workspace_type_id === WorkspaceType.OneCall;
  }

  public get canToggleDFSManager(): boolean {
    return !!this.request?.workspace?.show_dfs_toggle;
  }

  public get allUsersAreSelected(): boolean {
    return (
      !!this.request?.project_manager_id &&
      !!this.request?.workspace_manager_id &&
      !!this.request?.account_coordinator_id &&
      !!this.request?.cfmo_id &&
      (this.isConstruction || (!!this.request?.ico_id && !!this.request?.bsd_id)) &&
      (!this.isOneCallWorkspace || !!this.request?.one_call_director_id) &&
      ((!this.isConstruction && !this.canToggleDFSManager) ||
        !this.request?.is_dfs_required ||
        !!this.request?.dfs_id ||
        ((!this.request?.is_architect_required || !!this.request?.architect_id) &&
          !!this.request?.account_coordinator_id))
    );
  }

  public downloadRequestFile(pdf, requestCode) {
    this.downloading = true;
    pdf.saveAs('Request_' + requestCode + '.pdf');
    this.downloading = false;
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public filesHasImages(): boolean {
    return this.files && this.files.filter((f) => f.base64 != null).length > 0;
  }

  public getEmailLink() {
    const ccEmails = this.contacts.map((contact) => contact.email).join(';');
    const displayEmails = this.contacts.length ? `cc=${ccEmails}&` : '';

    return `mailto:${this.request?.requester?.email}?${displayEmails}subject=Project Request ${this.request?.code} has been Rejected`;
  }

  public async convertRequest() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Convert Project Request',
        headerText: 'Convert to Work Order',
        descriptionText: `Are you sure you want to convert this Project Request to a Work Order? This Project Request will no longer be available`,
        confirmationButtonText: 'Convert to Work Order',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Converting Request...');
          const workOrder = await this.requestService.convertRequestToWorkOrder(this.request.id).toPromise();
          await this.router.navigate([`/work-orders/${workOrder || ''}`]);
          this.snackbar.open('Request successfully converted to work order');
          this.progressIndicatorService.close();
        }
      });
  }

  public transferToWorkspace(workspace) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Transferring request...');
    this.requestService.transferToWorkspace(this.request.id, workspace.id, 'Rejected').subscribe(() => {
      this.progressIndicatorService.close();
      this.snackbar.open(`Request successfully transferred to ${workspace.name}!`);
      this.refresh(this.request.id);
    });
  }

  public async openUserProfileDialog(userId: number): Promise<void> {
    if (userId) {
      await this.modalService.openUserProfileDialog(userId).toPromise();
    }
  }
}
