import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyPipe',
})
export class CompanyPipePipe implements PipeTransform {
  transform(
    items: any[],
    search: string,
    verificationStatuses: number[],
    companyTypes: number[],
    showInactive: boolean,
    filteredCount?: any
  ): any[] {
    const result =
      items?.filter((item) => {
        let searched = false;
        if (search?.trim()) {
          ['name', 'contact_name', 'contact_email', 'phone'].forEach((field) => {
            if (item[field]?.toLowerCase()?.trim().includes(search.toLowerCase().trim())) searched = true;
          });
        }
        return (
          (search?.trim() ? searched : true) &&
          (showInactive ? true : item.is_enabled == 1) &&
          (verificationStatuses?.length > 0
            ? verificationStatuses && verificationStatuses.indexOf(item.verification_status) >= 0
            : true) &&
          (companyTypes?.length > 0 ? companyTypes.indexOf(item.type_id) >= 0 : true)
        );
      }) || [];

    if (filteredCount) {
      filteredCount.count = result.length;
    }
    return result;
  }
}
