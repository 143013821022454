import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-choice-dialog',
  templateUrl: './confirmation-choice-dialog.component.html',
  styleUrls: ['./confirmation-choice-dialog.component.scss'],
})
export class ConfirmationChoiceDialogComponent implements OnInit {
  private _placeHolderTitle = 'Options';
  public dialogTitle = '';
  public heading = '';
  public subHeading = '';
  public option1: { text: string; value: any };
  public option2: { text: string; value: any };
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _dialogRef: MatDialogRef<ConfirmationChoiceDialogComponent>
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this._data?.title ?? this._placeHolderTitle;
    this.heading = this._data?.heading ?? '';
    this.subHeading = this._data?.subHeading ?? '';
    this.option1 = this._data?.option1 ?? '';
    this.option2 = this._data?.option2 ?? '';
  }

  public cancel(): void {
    this._dialogRef.close();
  }

  public choice({ value }) {
    this._dialogRef.close(value);
  }
}
