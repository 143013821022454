import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AuthService, ModalService, UserService } from 'src/app/services';
import { User } from 'src/app/types';
import { ResourceType, UserType } from '../../enums';

@Component({
  selector: 'app-assign-user-button',
  templateUrl: './assign-user-button.component.html',
  styleUrls: ['./assign-user-button.component.scss'],
})
export class AssignUserButtonComponent implements OnChanges {
  @Input()
  public task: any;

  @Output()
  public userSelected = new EventEmitter<{ user: any; task: any }>();

  public assignedUser: User;

  constructor(public authService: AuthService, private modalService: ModalService, private userService: UserService) {}

  async ngOnChanges() {
    if (this.task?.assigned_user_id !== this.assignedUser?.id) {
      if (this.task?.assigned_user_id) {
        this.assignedUser = {
          id: this.task.assigned_user_id,
          first_name: this.task.assigned_user_first_name || this.task.assigned_user?.first_name,
          last_name: this.task.assigned_user_last_name || this.task.assigned_user?.last_name,
          user_type_id: this.task.assigned_user_type_id || this.task.assigned_user?.user_type_id,
          is_login_enabled: this.task.assigned_user_login_enabled || this.task.assigned_user?.is_login_enabled,
        };
      } else {
        this.assignedUser = null;
      }
    }
  }

  get UserType() {
    return UserType;
  }

  get userFields() {
    return this.assignedUser?.user_type_id && this.assignedUser?.user_type_id === UserType.Staff
      ? 'title'
      : this.assignedUser?.user_type_id === UserType.Vendor
      ? 'company_name'
      : 'department_name';
  }

  get isWorkspaceStaff() {
    return this.authService.isUserWorkspaceStaff(this.task?.module_id);
  }
  openAssignUserModal() {
    const currentTask = this.task;
    this.modalService.openUserSearchModal(this.task).subscribe(async (selectedUser) => {
      // the user canceled the search
      if (selectedUser === false) {
        return;
      }
      // otherwise, set the user (null = unassigned)
      if (
        selectedUser == null ||
        selectedUser.is_login_enabled ||
        (await this.modalService
          .openInviteUserModal({
            users: [selectedUser],
            parentId: this.task.id,
            parentTypeId: ResourceType.Task,
          })
          .toPromise())
      ) {
        this.assignedUser = selectedUser;
        currentTask.assigned_user = selectedUser;
        currentTask.assigned_user_first_name = selectedUser?.first_name;
        currentTask.assigned_user_last_name = selectedUser?.last_name;
        currentTask.assigned_user_id = selectedUser?.id;
        currentTask.assigned_user_type_id = selectedUser?.user_type_id;

        this.userSelected.emit({ user: this.assignedUser, task: currentTask });
      }
    });
  }
}
