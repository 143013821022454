<div id="task-view-activity" data-test-id="task-view-activity" class="task-view-activity">
  <!-- Note Activity Type. These are "Comments" that are left by a user in the Leave a note... box -->
  <div *ngIf="activityData?.type === activityType.Note && canViewActivity" class="row note">
    <div style="width: 46px" class="col-auto">
      <app-profile-thumbnail [userId]="activityData.created_by_id" [width]="34" [height]="34"></app-profile-thumbnail>
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <p class="m-0 dkblue font-weight-semibold" style="margin-bottom: -4px !important">
            <span> {{ activityData.created_by_first_name }} {{ activityData.created_by_last_name }} </span>
            <span class="dkgray font-weight-normal"> added a note </span>
          </p>
          <span class="dkgray act-date font-weight-normal">
            {{ getNoteDateFormatText(activityData.created_datetime) }}
          </span>
        </div>
        <div *ngIf="activityData.staffOnly" class="col-auto text-right">
          <span
            class="mb-0 pl-2 pr-2 br-4 mr-2 orange font-italic font-weight-semibold d-inline-block align-middle"
            style="font-size: 11px; line-height: 20px"
          >
            Staff Only
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <div class="note-content">
            <p
              class="dkblue m-0 mt-2"
              style="white-space: pre-wrap; word-break: break-word"
              [innerHTML]="activityData.noteText"
              appTextCollapseExpand
              maxHeight="105"
              (heightExceededEvent)="showCollapseDetailViewButton = $event"
              [showMore]="!collapseDetailView"
            ></p>
            <p
              id="collapse-detail-view-button"
              class="lh-18 hover-u pointer ltblue m-0 font-weight-normal"
              *ngIf="showCollapseDetailViewButton === true && !isEditingSummary"
              (click)="collapseDetailView = !collapseDetailView"
            >
              <i class="fas {{ collapseDetailView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
              {{ collapseDetailView === true ? 'Show More' : 'Show Less' }}
            </p>
          </div>
        </div>
      </div>
      <div class="pt-2">
        <app-file-chip-list
          *ngIf="activityData?.noteFiles?.length"
          [files]="activityData?.noteFiles"
          [diameter]="40"
        ></app-file-chip-list>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="activityData.type === activityType.Conversation" class="row note">
  <div style="width: 46px;" class="col-auto">
    <app-profile-thumbnail
      [userId]="activityData.created_by_id"
      [width]="34"
      [height]="34"
    ></app-profile-thumbnail>
  </div>
  <div class="col">
    <div class="conversation-notification">
      <div class="row">
        <div class="col">
          <p class="m-0 dkblue font-weight-normal" style="margin-bottom: -4px !important;">
            {{ activityData.created_by_first_name }} {{ activityData.created_by_last_name }}
            <span class="dkgray">
              started a conversation
            </span>
          </p>
          <span class="gray act-date font-weight-semibold">
            {{ getNoteDateFormatText(activityData.created_datetime) }}
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button mat-stroked-button (click)="openActivityConversation()" color="accent">
            <span>
              {{ activityData.conversationSubject }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->

  <!-- Base task activity types. Follow Actions and Manually created Approval activities that are not linked to an approval -->
  <div *ngIf="activityData?.type === activityType.Event && canViewActivity" class="row align-items-top note">
    <div style="width: 46px" class="col-auto">
      <app-profile-thumbnail
        *ngIf="
          activityData.eventType !== eventType.FOLLOW &&
          activityData.eventType !== eventType.UNFOLLOW &&
          activityData.eventType !== eventType.NOTIFICATION
        "
        [userId]="activityData.created_by_id"
        [width]="34"
        [height]="34"
      ></app-profile-thumbnail>
      <app-profile-thumbnail
        *ngIf="
          activityData.eventType === eventType.FOLLOW ||
          activityData.eventType === eventType.UNFOLLOW ||
          activityData.eventType === eventType.NOTIFICATION
        "
        [userId]="activityData.related_id"
        [width]="34"
        [height]="34"
      ></app-profile-thumbnail>
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <p class="m-0 dkblue font-weight-semibold" style="margin-bottom: -4px !important">
            <span>
              {{ getEventActivityUserName() }}
            </span>
            <span
              class="font-weight-normal"
              [ngClass]="{
                green: !!isApprovedEventActivity || activityData.eventType === eventType.COMPLETED,
                red: !!isRejectedEventActivity || activityData.eventType === eventType.ONHOLD,
                gold: !!isApprovalContract,
                orange: !!isNoteMarking,
                'dkgray font-weight-normal': !isApprovalEventActivity
              }"
            >
              <i
                *ngIf="isApprovedEventActivity || isApprovalContract || isNoteMarking"
                class="fa fa-check mr-1"
                [ngClass]="{ green: isApprovedEventActivity, gold: isApprovalContract, orange: isNoteMarking }"
              ></i>
              <i *ngIf="isRejectedEventActivity" class="fa fa-times red mr-1"></i>
              <span
                *ngIf="activityData.eventType !== eventType.ONHOLD && activityData.eventType !== eventType.COMPLETED"
                >{{ eventText }}</span
              >
              <span
                class="dkgray"
                *ngIf="activityData.eventType === eventType.ONHOLD || activityData.eventType === eventType.COMPLETED"
              >
                {{ eventText[0] }}
              </span>
              <span
                *ngIf="activityData.eventType === eventType.ONHOLD || activityData.eventType === eventType.COMPLETED"
                class="font-weight-semibold"
                [ngClass]="{
                  green: activityData.eventType === eventType.COMPLETED,
                  red: activityData.eventType === eventType.ONHOLD
                }"
              >
                <i *ngIf="activityData.eventType === eventType.ONHOLD" class="fa fa-flag red ml-1 mr-1"></i>
                <i
                  *ngIf="activityData.eventType === eventType.COMPLETED"
                  class="fa fa-check-circle green ml-1 mr-1"
                ></i>
                {{ eventText[1] }}
              </span>
            </span>
          </p>
          <span class="dkgray act-date font-weight-normal">
            {{ getNoteDateFormatText(activityData.created_datetime) }}
          </span>
        </div>
        <div *ngIf="activityData.staffOnly" class="col-auto text-right">
          <span
            class="mb-0 pl-2 pr-2 br-4 mr-2 orange font-italic font-weight-semibold d-inline-block align-middle"
            style="font-size: 11px; line-height: 20px"
          >
            Staff Only
          </span>
        </div>
      </div>
      <div *ngIf="activityData.comment && activityData.comment.length > 0" class="row">
        <div class="col-auto">
          <div class="note-content">
            <p
              class="dkblue m-0"
              style="white-space: pre-line"
              [innerHTML]="activityData.comment | safeHtml: isTrusted"
            ></p>
          </div>
        </div>
      </div>
      <div class="pt-2">
        <app-file-chip-list *ngIf="activityData?.noteFiles?.length" [files]="activityData?.noteFiles" [diameter]="40">
        </app-file-chip-list>
      </div>
    </div>
  </div>

  <!-- Approval Item Activity. These are linked to File Approvals -->
  <div *ngIf="activityData?.type === activityType.ApprovalItem && canViewActivity" class="row align-items-top note">
    <div style="width: 46px" class="col-auto">
      <app-profile-thumbnail [userId]="activityData.created_by_id" [width]="34" [height]="34"></app-profile-thumbnail>
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <p class="m-0 dkblue font-weight-semibold" style="margin-bottom: -4px !important">
            <span> {{ activityData.created_by_first_name }} {{ activityData.created_by_last_name }} </span>
            <span
              [ngClass]="{
                green: !!isApprovedEventActivity,
                red: !!isRejectedEventActivity,
                gold: !!isApprovalContract
              }"
            >
              <i *ngIf="isApprovedEventActivity" class="fa fa-check green ml-1 mr-1"></i>
              <i *ngIf="isApprovalContract" class="fa fa-check red ml-1 mr-1"></i>
              <i *ngIf="isRejectedEventActivity" class="fa fa-times red ml-1 mr-1"></i>
              {{ getEventText() }}
            </span>
          </p>
          <span class="dkgray act-date font-weight-normal">
            {{ getNoteDateFormatText(activityData.created_datetime) }}
          </span>
          <div class="row">
            <div class="col-auto">
              <div class="note-content">
                <p class="dkblue m-0">
                  {{ getApprovalComment() }}
                </p>
              </div>
            </div>
            <div class="pt-2">
              <app-file-chip-list
                *ngIf="activityData?.rejected_files?.length"
                [files]="activityData?.rejected_files"
                [diameter]="40"
              ></app-file-chip-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
