import { Pipe, PipeTransform } from '@angular/core';
import { BidPackage } from '../workspaces/construction/types';
import { SubmittalStatus, TaskReviewStatus } from '../enums';
import { TaskAccessoryData } from '../types';
import { FilterPipe } from './filter.pipe';

@Pipe({
  name: 'submittalsFilter',
})
export class SubmittalsFilterPipe implements PipeTransform {
  transform(
    bidPackages: BidPackage[],
    selectedBidPackage: { id: number; label: string },
    selectedVendor: { id: number; label: string },
    selectedStatus: SubmittalStatus,
    searchTerm: string
  ) {
    bidPackages = bidPackages?.filter(
      (bidPackage) =>
        (!selectedBidPackage.id || bidPackage.id === selectedBidPackage.id) &&
        (!selectedVendor.id || bidPackage.awarded_company_id === selectedVendor.id)
    );

    if (selectedStatus) {
      const selectedBidPackages = [];
      bidPackages?.forEach((bidPackage) => {
        if (bidPackage.submittal_task_id) {
          const accessoryData: TaskAccessoryData =
            (bidPackage.submittal_task?.accessory_data && JSON.parse(bidPackage.submittal_task.accessory_data)) || null;
          const reviewStatus = accessoryData?.reviewChain?.find(
            (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
          );
          if (accessoryData?.isReviewItem) {
            if (selectedStatus === SubmittalStatus.Received) {
              selectedBidPackages.push(bidPackage);
            }
            if (selectedStatus === SubmittalStatus.Approved && !reviewStatus) {
              selectedBidPackages.push(bidPackage);
            }
          }
        }
      });

      bidPackages = selectedBidPackages;
    }

    if (searchTerm) {
      const filterPipe = new FilterPipe();
      bidPackages?.forEach((bp) => {
        bp.submittal_categories.forEach((category) => {
          category.filtered_submittals = category.submittals.filter(
            (s) => filterPipe.transform([...s.spec.items, s.spec], searchTerm, [`name`, 'code']).length
          );
        });
      });
    } else {
      bidPackages?.forEach((bp) => {
        bp.submittal_categories.forEach((category) => {
          category.filtered_submittals = category.submittals;
        });
      });
    }

    return bidPackages || [];
  }
}
