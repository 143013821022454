import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { every, has } from 'lodash';
import { InvoiceStatus, Workspace } from 'src/app/enums';
import { AuthService, ModalService, ProductService, ProjectService } from 'src/app/services';
import { APIFilter, Invoice, Quote } from 'src/app/types';
import { FieldChecker } from 'src/app/utils';
import { BidPackage, BudgetData, ChangeOrder } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss'],
})
export class InvoiceDataComponent implements OnChanges {
  @Output() reloadInvoiceDialog = new EventEmitter<{
    invoiceId: number;
    workspace: Workspace;
    previousView: boolean;
  }>();
  @Input() bidPackage: BidPackage;
  @Input() quote: Quote;
  @Input() changeOrder: ChangeOrder;
  @Input() invoice: Invoice;
  @Input() currentWorkspace: Workspace;
  @Input() previousView: boolean;
  @ViewChild('invoicePaginator') invoicePaginator: MatPaginator;
  @Input() isLoading: boolean;

  constructor(
    private projectService: ProjectService,
    private productService: ProductService,
    public authService: AuthService,
    private modalService: ModalService
  ) {}

  loading = false;
  budgetData: BudgetData;
  shownBidPackageId: number;
  shownQuoteId: number;
  shownInvoiceId: number;
  shownChangeOrderId: number;
  view = {
    vendor: true,
    currentInvoice: true,
  };
  invoices: Invoice[] = [];
  invoicePageSize = 5;
  invoiceStartIndex = 0;
  invoiceEndIndex = this.invoicePageSize;
  vendorRetainageTotalBefore = 0;

  bidPackageFields = [
    'project_id',
    'awarded_company_id',
    'awarded_company_name',
    'trade_id',
    'trade_name',
    'project_code',
  ];
  quoteFields = ['project{id,code}', 'company{id,name}'];
  invoiceFields = ['is_retainage', 'total', 'retainage', 'shipping', 'tax', 'number', 'invoice_date', 'status_id'];

  ngOnChanges() {
    this.refresh();
  }

  async refresh(
    getInvoiceParent: boolean = false,
    getShownInvoice: boolean = false,
    getBudgetData: boolean = false,
    getPreviousInvoices: boolean = false
  ) {
    this.loading = true;
    // don't refresh bid package if it hasn't changed
    if (
      (this.currentWorkspace === Workspace.Construction && this.bidPackage?.id !== this.shownBidPackageId) ||
      (this.changeOrder?.id ?? null) !== (this.shownChangeOrderId ?? null) ||
      (this.currentWorkspace !== Workspace.Construction && this.quote?.id !== this.shownQuoteId)
    ) {
      getInvoiceParent = true;
      getPreviousInvoices = true;
      getBudgetData = true;
    }
    if (this.invoice?.id !== this.shownInvoiceId) {
      getShownInvoice = true;
    }
    if (getInvoiceParent) {
      if (this.currentWorkspace === Workspace.Construction) {
        this.shownBidPackageId = this.bidPackage?.id;
        if (!this.bidPackage?.id) {
          this.bidPackage = null;
        } else {
          const requiredBidPackageFields = this.bidPackageFields;
          const hasAllFields = FieldChecker.hasAllFields(this.bidPackage, requiredBidPackageFields);
          if (!hasAllFields?.result) {
            console.warn(
              `Invoice Data Component is missing the required bid package fields: ${(
                hasAllFields.missingFields || []
              ).join(', ')}! Getting them from the API.`
            );
            this.bidPackage = await this.projectService
              .getBidPackageById(this.bidPackage.id, this.bidPackageFields)
              .toPromise();
          }
        }
      } else {
        this.shownQuoteId = this.quote?.id;
        if (!this.quote?.id) {
          this.quote = null;
        } else {
          const hasAllFields = FieldChecker.hasAllFields(this.quote, this.quoteFields);
          if (!hasAllFields?.result) {
            console.warn(
              `Invoice Data Component is missing the required quote fields: ${(hasAllFields.missingFields || []).join(
                ', '
              )}! Getting them from the API.`
            );
            this.quote = await this.productService.getQuoteById(this.quote.id, this.quoteFields).toPromise();
          }
        }
      }
      this.view.currentInvoice = !this.previousView;
    }
    if (getShownInvoice) {
      this.shownInvoiceId = this.invoice?.id;
      if (this.invoice?.id) {
        const requiredInvoiceFields = this.invoiceFields;
        const missingFields = [];
        const hasAllFields = every(requiredInvoiceFields, (field) => {
          if (has(this.invoice, field)) {
            return true;
          } else {
            missingFields.push(field);
            return false;
          }
        });
        if (!hasAllFields) {
          console.warn(
            `Invoice Data Component is missing the required invoice fields: ${missingFields.join(
              ', '
            )}! Getting them from the API.`
          );
          this.invoice = await this.projectService.getInvoiceById(this.invoice.id, this.invoiceFields).toPromise();
        }
      }
    }
    if (getBudgetData) {
      this.shownChangeOrderId = this.changeOrder?.id;
      this.budgetData = await this.projectService
        .getBudgetData(
          this.bidPackage?.project_id || this.quote?.project?.id || this.projectService.currentSelectedProjectId,
          this.currentWorkspace,
          this.view.vendor ? this.bidPackage?.id || this.quote?.id : null,
          this.changeOrder?.id ?? null
        )
        .toPromise();
      if (this.view.vendor) {
        this.vendorRetainageTotalBefore = this.retainageTotalBefore;
      }
    }
    if (getPreviousInvoices) {
      let invoiceFilters: APIFilter[];
      if (this.view.vendor) {
        if (this.currentWorkspace === Workspace.Construction) {
          invoiceFilters = [{ type: 'field', field: 'bid_package_id', value: this.bidPackage?.id.toString() }];
        } else {
          invoiceFilters = [{ type: 'field', field: 'quote_id', value: this.quote?.id.toString() }];
        }
      } else {
        invoiceFilters = [
          {
            type: 'field',
            field: 'project_id',
            value: (
              this.bidPackage?.project_id ||
              this.quote?.project?.id ||
              this.projectService.currentSelectedProjectId
            ).toString(),
          },
        ];
      }
      invoiceFilters = [
        ...invoiceFilters,
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'bid_package_child_request_id', value: null },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'bid_package_child_project_id', value: null },
      ];

      if (this.bidPackage?.id || this.quote?.id) {
        this.invoices = await this.projectService.getInvoices(invoiceFilters, this.invoiceFields).toPromise();
      }

      if (this.invoices.length === 0) {
        this.view.currentInvoice = true;
      }
    }
    this.loading = false;
  }

  public get eWorkspace() {
    return Workspace;
  }

  public get projectCode() {
    return this.bidPackage?.id || this.quote?.id
      ? this.bidPackage?.project_code || this.quote?.project?.code
      : this.projectService?.currentSelectedProject?.code;
  }

  public get originalContractSum() {
    return +this.budgetData?.awardedBidTotal || 0;
  }

  public get changeOrderTotal() {
    return +this.budgetData?.changeOrderTotal || 0;
  }

  public get changeOrderTotalDeduction() {
    return +this.budgetData?.changeOrderTotalDeduction || 0;
  }

  public get totalContractSum() {
    return +this.originalContractSum + +this.changeOrderTotal;
  }

  public get billedTotalBefore() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return (
        (+this.budgetData?.invoicesBilledTotal || 0) -
        (+this.invoice?.total || 0) +
        (+this.invoice?.retainage || 0) -
        (+this.invoice?.shipping || 0) -
        (+this.invoice?.tax || 0)
      );
    } else {
      return +this.budgetData?.invoicesBilledTotal || 0;
    }
  }

  public get billedPercentageBefore() {
    return +this.totalContractSum > 0 ? +this.billedTotalBefore / +this.totalContractSum : 0;
  }

  public get retainageTotalBefore() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      // if this is a retainage invoice, retainageTotalBefore = budgetDataRetainageTotal - invoice.total
      // else retainageTotalBefore = budgetDataRetainageTotal - invoice.retainage
      return (
        (+this.budgetData?.invoicesRetainageTotal || 0) -
        (this.invoice?.is_retainage ? -1 * +this.invoice?.total : +this.invoice?.retainage)
      );
    } else {
      return +this.budgetData?.invoicesRetainageTotal || 0;
    }
  }

  public get retainageTotalPercentageBefore() {
    return +this.retainageTotalBefore / (+this.retainageTotalBefore + +this.billedTotalBefore);
  }

  public get balanceToFinishBefore() {
    return +this.totalContractSum - +this.billedTotalBefore;
  }

  public get billedTotalAfter() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return +this.budgetData?.invoicesBilledTotal || 0;
    } else {
      return (
        +this.billedTotalBefore +
        (+this.invoice?.total || 0) -
        (+this.invoice?.retainage || 0) +
        (+this.invoice?.shipping || 0) +
        (+this.invoice?.tax || 0)
      );
    }
  }

  public get billedPercentageAfter() {
    return +this.totalContractSum > 0 ? +this.billedTotalAfter / +this.totalContractSum : 0;
  }

  public get retainageTotalAfter() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return +this.budgetData?.invoicesRetainageTotal || 0;
    } else {
      // if this is a retainage invoice, retainageTotalAfter = oldRetainageTotal - invoice.total
      // else retainageTotalAfter = oldRetainageTotal + invoice.retainage
      return (
        +this.retainageTotalBefore +
        (this.invoice?.is_retainage ? -1 * (+this.invoice?.total || 0) : +this.invoice?.retainage || 0)
      );
    }
  }

  public get retainageTotalPercentageAfter() {
    return +this.retainageTotalAfter / (+this.retainageTotalAfter + +this.billedTotalAfter);
  }

  public get balanceToFinishAfter() {
    return +this.totalContractSum - +this.billedTotalAfter;
  }

  public get invoiceForContractSum() {
    if (this.changeOrder) {
      return this.changeOrder?.id ? +this.changeOrder?.cost_change : this.originalContractSum;
    } else {
      return 0;
    }
  }

  public get invoiceForBilledTotalBefore() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return (
        (+this.budgetData?.changeOrderInvoicesBilledTotal || 0) -
        (+this.invoice?.total || 0) +
        (+this.invoice?.retainage || 0) -
        (+this.invoice?.shipping || 0) -
        (+this.invoice?.tax || 0)
      );
    } else {
      return +this.budgetData?.changeOrderInvoicesBilledTotal || 0;
    }
  }

  public get invoiceForBilledTotalAfter() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return +this.budgetData?.changeOrderInvoicesBilledTotal || 0;
    } else {
      return (
        +this.invoiceForBilledTotalBefore +
        (+this.invoice?.total || 0) -
        (+this.invoice?.retainage || 0) +
        (+this.invoice?.shipping || 0) +
        (+this.invoice?.tax || 0)
      );
    }
  }

  public get invoiceForBilledPercentageBefore() {
    return +this.invoiceForContractSum > 0 ? +this.invoiceForBilledTotalBefore / +this.invoiceForContractSum : 0;
  }

  public get invoiceForBilledPercentageAfter() {
    return +this.invoiceForContractSum > 0 ? +this.invoiceForBilledTotalAfter / +this.invoiceForContractSum : 0;
  }

  public get invoiceForRetainageTotalBefore() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      // if this is a retainage invoice, retainageTotalBefore = budgetDataRetainageTotal - invoice.total
      // else retainageTotalBefore = budgetDataRetainageTotal - invoice.retainage
      return (
        (+this.budgetData?.changeOrderInvoicesRetainageTotal || 0) -
        (this.invoice?.is_retainage ? -1 * +this.invoice?.total : +this.invoice?.retainage)
      );
    } else {
      return +this.budgetData?.changeOrderInvoicesRetainageTotal || 0;
    }
  }

  public get invoiceForRetainageTotalAfter() {
    if (
      [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(this.invoice?.status_id) >
      -1
    ) {
      // if this invoice is received, then it's already counted in budgetData
      return +this.budgetData?.changeOrderInvoicesRetainageTotal || 0;
    } else {
      // if this is a retainage invoice, retainageTotalAfter = oldRetainageTotal - invoice.total
      // else retainageTotalAfter = oldRetainageTotal + invoice.retainage
      return (
        +this.invoiceForRetainageTotalBefore +
        (this.invoice?.is_retainage ? -1 * (+this.invoice?.total || 0) : +this.invoice?.retainage || 0)
      );
    }
  }

  public get invoiceForRetainageTotalPercentageAfter() {
    return (
      +this.invoiceForRetainageTotalAfter / (+this.invoiceForRetainageTotalAfter + +this.invoiceForBilledTotalAfter)
    );
  }

  public get invoiceForBalanceToFinishBefore() {
    return +this.invoiceForContractSum - +this.invoiceForBilledTotalBefore;
  }

  public get invoiceForBalanceToFinishAfter() {
    return +this.invoiceForContractSum - +this.invoiceForBilledTotalAfter;
  }

  public vendorViewChanged() {
    this.refresh(false, false, true, true);
  }

  public setCurrentInvoiceView(value: boolean) {
    this.view.currentInvoice = value;
  }

  public getStatusName(statusId: number) {
    switch (statusId) {
      case InvoiceStatus.New:
      case InvoiceStatus.Received:
      case InvoiceStatus.Approved:
        return 'Pending';
      case InvoiceStatus.ReadyForPayment:
        return 'Processed';
      case InvoiceStatus.Rejected:
        return 'Rejected';
    }
  }

  invoicePageChange(event) {
    this.invoiceStartIndex = event.pageIndex * event.pageSize;
    this.invoiceEndIndex = this.invoiceStartIndex + event.pageSize;
  }

  goToFirstInvoicePage() {
    this.invoicePaginator.firstPage();
    this.invoiceStartIndex = 0;
    this.invoiceEndIndex = this.invoicePageSize;
  }

  viewChangeOrder(co) {
    this.modalService.openViewChangeOrderModal(co.id, false);
  }

  async changeInvoice(invoice: Invoice) {
    if (+this.invoice.id !== +invoice.id) {
      this.reloadInvoiceDialog.emit({ invoiceId: invoice.id, workspace: this.currentWorkspace, previousView: true });
    }
  }
}
