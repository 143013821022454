<app-base-dialog [title]="'Request Account'" (closeDialog)="close()"></app-base-dialog>
<div id="request-account" data-test-id="request-account" class="request-account project-modal p-0">
  <div class="row">
    <div class="col"></div>
  </div>
  <mat-horizontal-stepper class="full-width" labelPosition="bottom" linear #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [stepControl]="contactInfoFormGroup">
      <form [formGroup]="contactInfoFormGroup">
        <ng-template matStepLabel>Contact Info</ng-template>
        <div class="row mt-4">
          <div class="col">
            <h3 class="dkblue mb-4 font-weight-semibold">How can we contact you?</h3>
            <div class="row mb-4">
              <div class="col">
                <h6 class="primary mb-2">First Name</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    placeholder="First Name"
                    formControlName="first_name"
                    data-test-id="request-account-first-name-input"
                  />
                  <mat-error *ngIf="first_name.errors?.required" data-test-id="request-account-first-name-error"
                    >First Name is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="primary mb-2">Last Name</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    placeholder="Last Name"
                    formControlName="last_name"
                    data-test-id="request-account-last-name-input"
                  />
                  <mat-error *ngIf="last_name.errors?.required" data-test-id="request-account-last-name-error"
                    >Last Name is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="primary mb-2">Email Address</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    placeholder="Email Address"
                    required="true"
                    formControlName="email"
                    data-test-id="request-account-email-input"
                    (change)="emailChanged()"
                  />
                  <mat-error *ngIf="email.errors?.required" data-test-id="request-account-email-error-required"
                    >Email Address is required</mat-error
                  >
                  <mat-error
                    *ngIf="!email.errors?.required && email.errors?.email"
                    data-test-id="request-account-email-error-invalid"
                    >Email Address is invalid</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="primary mb-2">Office Phone</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    placeholder="123-456-7890"
                    formControlName="office_phone"
                    data-test-id="request-account-office-phone-input"
                  />
                  <mat-error
                    *ngIf="office_phone.errors?.required"
                    data-test-id="request-account-office-phone-error-required"
                    >Office Phone is required</mat-error
                  >
                  <mat-error
                    *ngIf="office_phone.errors?.phone && !office_phone.errors?.required"
                    data-test-id="request-account-office-phone-error-invalid"
                    >Office Phone is invalid</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="primary mb-2">Cell Phone</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    formControlName="cell_phone"
                    placeholder="123-456-7890"
                    data-test-id="request-account-cell-phone-input"
                  />
                  <mat-error *ngIf="cell_phone.errors?.phone" data-test-id="request-account-cell-phone-error-invalid"
                    >Cell Phone is invalid</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <button
              mat-button
              type="button"
              class="gray mr-2"
              (click)="close()"
              data-test-id="request-account-cancel-button"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              matStepperNext
              class="ml-2"
              [disabled]="!contactInfoFormGroup.valid"
              [color]="contactInfoFormGroup.valid ? 'success' : null"
              type="button"
              data-test-id="request-account-step-2-next"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Account Type</ng-template>
      <div class="row mt-4">
        <div class="col">
          <h4 class="dkblue mb-4 font-weight-semibold">What type of account do you need?</h4>
          <div class="row mb-5">
            <div class="col">
              <button
                mat-stroked-button
                *ngIf="isStaffEmail"
                (click)="setAccountType(1)"
                class="full-width br-10 py-4 mb-3"
                [ngClass]="{
                  'border-primary': account_type.value === 1
                }"
                data-test-id="request-account-type-1-button"
              >
                <h4 class="dkblue mb-2">Staff</h4>
                <p class="dkgray font-weight-normal mb-0 lh-20">I will be managing Projects and Work Orders.</p>
              </button>
              <button
                mat-stroked-button
                (click)="setAccountType(2)"
                class="full-width br-10 py-4 mb-3"
                [ngClass]="{
                  'border-primary': account_type.value === 2
                }"
                data-test-id="request-account-type-2-button"
              >
                <h4 class="dkblue mb-2">Tenant</h4>
                <p class="dkgray font-weight-normal mb-0 lh-20">I will be requesting Projects and Work Orders.</p>
              </button>
              <button
                mat-stroked-button
                (click)="setAccountType(3)"
                class="full-width br-10 py-4 mb-3"
                [ngClass]="{
                  'border-primary': account_type.value === 3
                }"
                data-test-id="request-account-type-3-button"
              >
                <h4 class="dkblue">Supplier</h4>
                <p class="dkgray font-weight-normal mb-0 lh-20">I will be bidding on Projects.</p>
              </button>
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <button
                mat-button
                type="button"
                class="gray mr-2"
                matStepperPrevious
                data-test-id="request-account-step-2-back"
              >
                Back
              </button>
              <button
                mat-flat-button
                class="ml-2"
                [disabled]="!contactTypeFormGroup.valid"
                [color]="contactTypeFormGroup.valid ? 'success' : null"
                matStepperNext
                data-test-id="request-account-step-1-next"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="locationFormGroup">
      <form [formGroup]="locationFormGroup" (ngSubmit)="submit()">
        <ng-template matStepLabel>Location</ng-template>
        <div class="row">
          <div class="col">
            <h4 class="dkblue font-weight-semibold mb-4 mt-4">Some additional info</h4>
            <div class="row mb-4">
              <div class="col-6" *ngIf="account_type.value === 2 || account_type.value === 3">
                <h6 class="primary">Company Name</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    formControlName="company_name"
                    data-test-id="request-account-company-name-input"
                  />
                  <mat-error *ngIf="company_name.errors?.required" data-test-id="request-account-company-name-error"
                    >Company Name is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div [ngClass]="'col-lg' + (account_type.value === 3 ? '-6' : '')">
                <h6 class="primary">Title</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    placeholder="Position Title"
                    formControlName="title"
                    data-test-id="request-account-title-input"
                  />
                  <mat-error *ngIf="title.errors?.required" data-test-id="request-account-title-error"
                    >Title is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="account_type.value !== 3">
              <div class="col">
                <h6 class="primary">Manager Name</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    matInput
                    type="text"
                    required="true"
                    placeholder="Full Name"
                    formControlName="manager_name"
                    data-test-id="request-account-manager-name-input"
                  />
                  <mat-error *ngIf="manager_name.errors?.required" data-test-id="request-account-manager-name-error"
                    >Manager Name is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" [hidden]="account_type.value !== userTypeIds.Staff">
              <div class="col">
                <h6 class="primary">Default Workspace</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="default_module_id"
                    [required]="account_type.value === userTypeIds.Staff"
                  >
                    <mat-option *ngFor="let ws of workspaces" [value]="ws.id">{{ ws.name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="default_module_id.errors?.required">Workspace is required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div [hidden]="account_type.value === 3" class="row mb-4">
              <div class="col">
                <h6 class="primary">Location</h6>
                <app-location
                  #locations
                  [options]="{
                    labels: false,
                    notFound: true,
                    required: { building: true, department: false, floor: true, suite: false }
                  }"
                  [userTypeId]="userTypeId"
                ></app-location>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 text-center">
          <div class="col">
            <button mat-flat-button class="gray mr-2" matStepperPrevious data-test-id="request-account-step-3-back">
              Back
            </button>
            &nbsp;
            <button
              mat-flat-button
              matStepperNext
              class="ml-2"
              color="success"
              [disabled]="!valid_submission"
              type="button"
              (click)="submit()"
              data-test-id="request-account-submit-button"
            >
              <span *ngIf="!submitting">Submit</span>
              <mat-spinner
                *ngIf="submitting"
                class="d-inline-block align-middle"
                color="accent"
                [diameter]="20"
              ></mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
