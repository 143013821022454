import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, Request, RequestStatus, RequestType, ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  host: string = environment.serviceHost;

  public refreshNeeded$ = new Subject<void>();
  public requestCountUpdateNeeded$ = new Subject<void>();

  requestUrl = `${this.host}/api/v1/requests`;
  requestTypeUrl = `${this.host}/api/v1/request-types`;
  requestStatusUrl = `${this.host}/api/v1/request-statuses`;

  requestFields = `code,status_id,module_id,workspace{id,name,workspace_type_id},request_type_id,request_type_name,requester_id,requester_first_name,requester_last_name,requester_company_name,created_datetime,building_name,budget_name,floor_name,suite_name,department_name,budget_name,budget_description,start_date,end_date,lease_term,rooms,contact_ids,summary,architect_id,project_manager_id,workspace_manager_id,project_template_ids,files,reassignment_activity,request{request_method_id,request_method{id,name,is_enabled,is_default,icon}},converted_from_work_order{created_datetime}`;

  getRequests(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<Request[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.requestUrl}?limit=${limit || 1000}&fields=${fields ? fields.join(',') : this.requestFields}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const requests: Request[] = result.data.requests;
          return requests;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getRequestCount(apiFilters?: APIFilter[]): Observable<number> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.requestUrl}?${filterString || ''}&limit=1`).pipe(
      map((result: ServiceResponse) => result.count ?? 0),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRequestById(id, fields?: string[]): Observable<Request> {
    return this.http.get(`${this.requestUrl}/${id}?fields=${fields ? fields.join(',') : this.requestFields}`).pipe(
      map((result: ServiceResponse) => {
        const requests: Request[] = result.data.request;
        return requests[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // returns the ids for requester/contacts for a project
  getOtherIdsByRequestId(requestId: number): Observable<{ requester: number; contacts: number[] }> {
    return this.http.get(`${this.requestUrl}?fields=requester_id,contact_ids&filter=id=${requestId}?limit=1`).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.requests[0];
        if (request) {
          const contacts =
            request && request.contact_ids && request.contact_ids !== '[]'
              ? request.contact_ids
                  .substr(1, request.contact_ids.length - 2)
                  .split(',')
                  .map((value) => +value)
              : [];
          return { requester: request.requester_id, contacts };
        } else {
          return { requester: null, contacts: [] };
        }
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets all requests where the passed userIds intersect with the requestor/workspace manager/architect/created_by_id/or contact_ids
  getRequestsByUserIds(userIds: number[]): Observable<Request[]> {
    const users = userIds.join('^');
    return this.http
      .get(
        `${this.requestUrl}?fields=${this.requestFields}&filter=requester_id=${users}|contact_ids=${users}|workspace_manager_id=${users}|architect_id=${users}|created_by_id=${users}&limit=100`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.requests;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getPendingRequestsCountForWorkspace(workspace_id: number): Observable<number> {
    return this.http.get(`${this.requestUrl}?filter=module_id=${workspace_id},status_id=1&limit=1`).pipe(
      map((result: ServiceResponse) => result.count ?? 0),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRequestsByStatus(statusId: number): Observable<Request[]> {
    return this.http
      .get(`${this.requestUrl}?filter=status_id=${statusId}&fields=${this.requestFields}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          const requests: Request[] = result.data.requests;
          return requests;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getRequestStatuses(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    showAll: boolean = false
  ): Observable<RequestStatus[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.requestStatusUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const requestStatuses: RequestStatus[] = result.data.request_statuses;
          return showAll ? requestStatuses.concat({ id: -1, name: 'View All' }) : requestStatuses;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  changeRequestStatus(requestId: number, statusId: number): Observable<any> {
    const body = {
      status_id: statusId,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        this.requestCountUpdateNeeded$.next();
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRequestTypes(): Observable<RequestType[]> {
    return this.http.get(`${this.requestTypeUrl}?fields=name,description`).pipe(
      map((result: ServiceResponse) => {
        const requestTypes: RequestType[] = result.data.request_types;
        return requestTypes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createRequest(request: Request, fields?: string[]): Observable<Request> {
    const body = request;
    return this.http.post(`${this.requestUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdRequest: Request = result.data.request;
        this.requestCountUpdateNeeded$.next();
        return createdRequest;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setRequestProjectManager(requestId: number, projectManagerId: number): Observable<Request> {
    const body = {
      project_manager_id: projectManagerId,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setRequestWorkspaceManager(requestId: number, workspaceManagerId: number): Observable<Request> {
    const body = {
      workspace_manager_id: workspaceManagerId,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setRequestArchitect(requestId: number, architectId: number): Observable<Request> {
    const body = {
      architect_id: architectId,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setRequestUser(requestId: number, userKey: string, userId: number) {
    const body = {
      [userKey]: userId,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setRequestProjectTemplates(requestId: number, projectTemplateIds: number[]): Observable<Request> {
    const body = {
      project_template_ids: projectTemplateIds ? JSON.stringify(projectTemplateIds) : null,
    };
    return this.http.put(`${this.requestUrl}/${requestId}`, body).pipe(
      map((result: ServiceResponse) => {
        const request = result.data.request;
        return request;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  convertRequestToProject(requestId: number): Observable<Request> {
    return this.http.post(`${this.requestUrl}/${requestId}/convert-to-project`, null).pipe(
      map((result: ServiceResponse) => {
        const project = result.data.project;
        return project;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  convertRequestToWorkOrder(requestId: number): Observable<number> {
    return this.http.post(`${this.requestUrl}/${requestId}/convert-to-workorder`, null).pipe(
      map((result: ServiceResponse) => {
        const workOrder = result.data.convertedId;
        return workOrder;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateRequest(requestId: number, request: Request, fields?: string[]): Observable<Request> {
    return this.http.put(`${this.requestUrl}/${requestId}?${fields ? `fields=${fields.join(',')}` : ''}`, request).pipe(
      map((result: ServiceResponse) => {
        const requestToReturn: Request = result.data.request;
        return requestToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public transferToDispatch(requestId: number, userComment?: string): Observable<null> {
    return this.http.post(`${this.requestUrl}/${requestId}/transfer-to-dispatch`, { comment: userComment }).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public transferToWorkspace(requestId: number, workspaceId: number, userComment?: string): Observable<null> {
    return this.http
      .post(`${this.requestUrl}/${requestId}/transfer-to-workspace`, {
        comment: userComment,
        workspace_id: workspaceId,
      })
      .pipe(
        map(() => null),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
}
