import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
  DatepickerHeaderComponent,
  EditorComponent,
  UserSelectModalComponent,
  WorkspaceDropdownComponent,
} from 'src/app/components';
import { MeetingAttendeeType, ResourceType } from 'src/app/enums';
import {
  AuthService,
  LocationService,
  MeetingService,
  ModuleService,
  ProjectService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import { Meeting, MeetingAttendee, User, Workspace } from 'src/app/types';

@Component({
  selector: 'app-meeting-dialog',
  templateUrl: './meeting-dialog.component.html',
  styleUrls: ['./meeting-dialog.component.scss'],
})
export class MeetingDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  @ViewChild('workspace', { static: true })
  private _workspace_component: WorkspaceDropdownComponent;
  private _dialogTitle = 'Meeting';
  private _workspaces: Workspace[] = [];
  public attendees: User[] = [];
  public existingAttendeeIds = {};
  constructor(
    public dialogRef: MatDialogRef<MeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public meeting,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private fb: FormBuilder,
    private locationService: LocationService,
    private meetingService: MeetingService,
    private _projectService: ProjectService,
    private snackbar: MatSnackBar,
    private _userService: UserService,
    private _workOrderService: WorkOrderService,
    private _workspaceService: ModuleService
  ) {}

  action: string;
  buildings = [];
  savedEndDate;
  customHeader = DatepickerHeaderComponent;
  public maximumTitleLength = 255;

  meetingFormGroup: FormGroup = this.fb.group({
    title: [
      this.meeting && this.meeting.id ? this.meeting.title : '',
      [Validators.required, Validators.maxLength(this.maximumTitleLength)],
    ],
    // purpose: [this.meeting && this.meeting.id ? this.meeting.purpose : '', [Validators.required]],
    date: [this.meeting && this.meeting.id ? this.meeting.start_datetime : '', [Validators.required]],
    start_time: [
      this.meeting && this.meeting.id ? moment(this.meeting.start_datetime).format('HH:mm:ss') : '',
      [Validators.required],
    ],
    end_time: [
      this.meeting && this.meeting.id ? moment(this.meeting.end_datetime).format('HH:mm:ss') : '',
      [Validators.required],
    ],
    location: [this.meeting && this.meeting.id ? this.meeting.location : ''],
    recurrence_frequency: [''],
    recurrence_end_date: [this.meeting && this.meeting.id ? this.meeting.recurring_meeting_end_datetime : ''],
    mandatory: [this.meeting && this.meeting.id ? this.meeting.is_mandatory : false, [Validators.required]],
  });

  loaders = {
    meeting: false,
  };

  get title() {
    return this.meetingFormGroup.get('title');
  }
  get title_counter(): string {
    return `${this.title?.value?.length || 0} / ${this.maximumTitleLength}`;
  }
  get purpose() {
    return this.meetingFormGroup.get('purpose');
  }
  get date() {
    return this.meetingFormGroup.get('date');
  }
  get dialogTitle(): string {
    return `${this.action} ${this._dialogTitle}`;
  }
  get start_time() {
    return this.meetingFormGroup.get('start_time');
  }
  get end_time() {
    return this.meetingFormGroup.get('end_time');
  }
  get location() {
    return this.meetingFormGroup.get('location');
  }
  get recurrence_frequency() {
    return this.meetingFormGroup.get('recurrence_frequency');
  }
  get recurrence_end_date() {
    return this.meetingFormGroup.get('recurrence_end_date');
  }
  get isConcluded() {
    return this.meeting.is_concluded;
  }

  get isMandatory() {
    return this.meetingFormGroup.get('mandatory');
  }

  // added to disable the end date field if the meeting is not recurring
  get isRecurring() {
    return this.recurrence_frequency.value && this.recurrence_frequency.value !== '';
  }

  get workspace(): AbstractControl {
    return this.meetingFormGroup.get('workspace');
  }

  async ngOnInit() {
    await this._activateWorkspaceField();
    if (this.meeting?.id) {
      this.action = 'Edit';

      // create the hash map and return an array of ids
      // The returned array will be used to get users for the profile
      const existingAttendeeUserIds: number[] = this.meeting.attendees.map((attendee: MeetingAttendee) => {
        // we build
        this.existingAttendeeIds[attendee.user_id] = attendee.id;
        return +attendee.user_id;
      });

      this.attendees = await this._userService
        .getUsers(
          [{ type: 'field', field: 'id', value: existingAttendeeUserIds.join('^') }],
          ['id', 'first_name', 'last_name']
        )
        .toPromise();
    } else {
      this.action = 'Add';
      this.attendees = [{ ...this._authService.currentUser }];
    }
    this.buildings = await this.locationService.getBuildings().toPromise();
    if (this.meeting.recurring_meeting_id) {
      let recurrenceFrequency;
      if (
        this.meeting.recurring_meeting_frequency_number === 1 &&
        this.meeting.recurring_meeting_frequency_interval === 'day'
      ) {
        recurrenceFrequency = 'Every Day';
      } else if (
        this.meeting.recurring_meeting_frequency_number === 1 &&
        this.meeting.recurring_meeting_frequency_interval === 'week'
      ) {
        recurrenceFrequency = 'Every Week';
      } else if (
        this.meeting.recurring_meeting_frequency_number === 2 &&
        this.meeting.recurring_meeting_frequency_interval === 'week'
      ) {
        recurrenceFrequency = 'Every 2 Weeks';
      } else {
        recurrenceFrequency = 'Never';
      }
      this.recurrence_frequency.setValue(recurrenceFrequency);
    } else {
      this.recurrence_end_date.disable();
    }

    this._activateMeetingPurposeField();
  }

  private _activateMeetingPurposeField() {
    this.meetingFormGroup.addControl('purpose', this._editor_component.content);
    this.purpose.setValidators([Validators.required]);
    this.purpose.updateValueAndValidity();
    this.purpose.setValue(this.meeting.purpose ?? '');
  }

  private async _activateWorkspaceField() {
    this._workspaces = await this._workspaceService.getWorkspaces().toPromise();
    this.meetingFormGroup.addControl('workspace', this._workspace_component?.workspace);
    if (this.meeting?.id) {
      // update
      // case workspace_id use that
      // case no workspace, but parent and type id, look up
      // none use the current workspace
      if (this.meeting.workspace_id) {
        this.workspace.setValue(this._findWorkspace(this.meeting.workspace_id));
        this.workspace.disable();
        this._workspace_component.hideCancel = true;
      } else if (this.meeting?.parent_id && this.meeting?.parent_type_id) {
        // look up parent, and use module id, from the old meeting
        await this._lookUpParentWorkspace();
        // disable choice
        this.workspace.disable();
        this._workspace_component.hideCancel = true;
      } else {
        // use the current workspace
        this._setWorkspace(this._workspaceService.workspace);
      }
    } else {
      // create
      if (this.meeting?.parent_id && this.meeting?.parent_type_id) {
        // look up parent, and use module id
        await this._lookUpParentWorkspace();
        // disable choice
        this.workspace.disable();
        this._workspace_component.hideCancel = true;
      } else {
        // use the current work space
        // but do not disable choice
        this._setWorkspace(this._workspaceService.workspace);
      }
    }
  }

  private async _addAttendees(meeting_id: number) {
    if (this.meeting.id) {
      // complex
      const attendeeIds = this.attendees.map((attendee: User) => attendee.id);
      const removeAttendeeIds = Object.keys(this.existingAttendeeIds).filter(
        (existingAttendeeId) => !attendeeIds.includes(+existingAttendeeId)
      );

      for (const removeAttendeeId of removeAttendeeIds) {
        await this.meetingService.removeAttendee(+this.existingAttendeeIds[removeAttendeeId]).toPromise();
      }

      const newAttendeeIds = this.attendees
        .filter((attendee: User) => !this.existingAttendeeIds[attendee.id])
        .map((attendee: User) => attendee.id);

      for (const attendeeId of newAttendeeIds) {
        await this.meetingService
          .addAttendee({
            meeting_id,
            user_id: attendeeId,
            attendee_type_id: MeetingAttendeeType.Participant,
          })
          .toPromise();
      }
    } else {
      // new meeting, straight forward, without the author
      for (const attendee of this.attendees.filter((user) => +user.id !== +this._authService.currentUser.id)) {
        await this.meetingService
          .addAttendee({
            meeting_id,
            user_id: attendee.id,
            attendee_type_id: MeetingAttendeeType.Participant,
          })
          .toPromise();
      }
    }
  }

  private _findWorkspace(workspaceId: number): Workspace {
    return this._workspaces.find((workspace: Workspace) => +workspace.id === +workspaceId);
  }

  private _isModerator(userId: number) {
    return (
      +this.meeting?.attendees?.find((attendee: MeetingAttendee) => +attendee.user_id === +userId)?.attendee_type_id ===
      MeetingAttendeeType.Moderator
    );
  }

  private _isOrganizer(userId: number) {
    return (
      +this.meeting?.attendees?.find((attendee: MeetingAttendee) => +attendee.user_id === +userId)?.attendee_type_id ===
      MeetingAttendeeType.Organizer
    );
  }

  private async _lookUpParentWorkspace() {
    const currentWorkspace = this._workspaceService.workspace;
    if (this.meeting?.parent_type_id && this.meeting?.parent_id) {
      switch (+this.meeting.parent_type_id) {
        case ResourceType.Project:
          const projectResult = await this._projectService
            .getProjectById(this.meeting.parent_id, ['module_id'])
            .toPromise();
          if (projectResult) {
            this._setWorkspace(this._findWorkspace(projectResult?.module_id));
          } else {
            this._setWorkspace(currentWorkspace);
          }
          return;
        case ResourceType.Task:
          const taskResult = await this._projectService.getTaskById(this.meeting.parent_id, ['module_id']).toPromise();
          if (taskResult) {
            this._setWorkspace(this._findWorkspace(taskResult?.module_id));
          } else {
            this._setWorkspace(currentWorkspace);
          }
          return;
        case ResourceType.WorkOrder:
          const workOrderResult = await this._workOrderService
            .getWorkOrderById(this.meeting.parent_id, ['id', 'module_id'])
            .toPromise();
          if (workOrderResult) {
            this._setWorkspace(this._findWorkspace(workOrderResult?.module_id));
          } else {
            this._setWorkspace(currentWorkspace);
          }
          return;
        default:
          this._setWorkspace(currentWorkspace);
          return;
      }
    } else {
      this._setWorkspace(currentWorkspace);
    }
  }

  private _removeDeselectedAttendee(deSelectedUsers: User[]): User[] {
    const deselecteds = deSelectedUsers.map((deselectedUser: User) => deselectedUser.id);
    return this.attendees.filter((attendee: User) => !deselecteds.includes(attendee.id));
  }

  private _setWorkspace(workspace: Workspace) {
    this.workspace.setValue(workspace);
  }

  public addAttendee() {
    const preSelectedUsers = this.meeting?.id
      ? this.attendees.map((attendee) => {
          const canNotDeselect = this._isModerator(attendee.id) || this._isOrganizer(attendee.id);
          return {
            ...attendee,
            canNotDeselect,
          };
        })
      : [
          {
            ...(this._authService?.currentUser ?? {}),
            canNotDeselect: true,
          },
        ];
    this._dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Add Attendees',
          createUser: { title: 'Guest', guestUser: true },
          includeGuestUsers: true,
          preSelectedUsers,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (users) => {
        if (users) {
          const { selectedUsers, deSelectedUsers } = users;
          if (deSelectedUsers?.length && selectedUsers?.length) {
            this.attendees = [...this._removeDeselectedAttendee(deSelectedUsers), ...selectedUsers];
          } else if (selectedUsers?.length) {
            this.attendees = [...this.attendees, ...selectedUsers];
          } else if (deSelectedUsers?.length) {
            deSelectedUsers.map((deselectedUser: User) => deselectedUser.id);
            this.attendees = this._removeDeselectedAttendee(deSelectedUsers);
          }
        }
      });
  }

  changeRecurrenceFrequency() {
    if (this.recurrence_frequency.value) {
      if (this.savedEndDate) {
        this.recurrence_end_date.setValue(this.savedEndDate);
      }
      this.recurrence_end_date.enable();
    } else {
      if (this.recurrence_end_date.value) {
        this.savedEndDate = this.recurrence_end_date.value;
      }
      this.recurrence_end_date.setValue(null);
      this.recurrence_end_date.disable();
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public icon(attendeeId) {
    let icon = 'clear';
    if (this.meeting?.id) {
      if (this._isOrganizer(+attendeeId) || this._isModerator(+attendeeId)) {
        icon = 'star';
      }
    } else {
      if (+this._authService?.currentUser?.id === +attendeeId) {
        icon = 'star';
      }
    }
    return icon;
  }

  public removeAttendee(attendeeId: number) {
    if (
      !this._isOrganizer(+attendeeId) &&
      !this._isModerator(+attendeeId) &&
      +this._authService?.currentUser?.id !== +attendeeId
    ) {
      this.attendees = this.attendees.filter((attendee: User) => attendee.id !== attendeeId);
    }
  }

  async submit() {
    if (this.meetingFormGroup.valid) {
      this.loaders.meeting = true;
      const meetingToSend: Meeting = {
        title: this.title.value,
        purpose: this.purpose.value,
        start_datetime: moment(`${moment(this.date.value).format('YYYY-MM-DD')} ${this.start_time.value}`).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        end_datetime: moment(`${moment(this.date.value).format('YYYY-MM-DD')} ${this.end_time.value}`).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        location: this.location.value,
        is_mandatory: +!!this.isMandatory.value,
        workspace_id: this.workspace?.value?.id ?? null,
      };
      let newMeeting;
      if (this.meeting.id) {
        // update existing meeting
        meetingToSend.id = this.meeting.id;
        newMeeting = await this.meetingService.updateMeeting(meetingToSend).toPromise();
      } else if (!this.recurrence_frequency.value) {
        // create meeting
        meetingToSend.type_id = this.meeting.type_id;
        meetingToSend.parent_type_id = this.meeting.parent_type_id;
        meetingToSend.parent_id = this.meeting.parent_id;
        newMeeting = await this.meetingService.createMeeting(meetingToSend).toPromise();
      }

      // adding attendees here
      if (newMeeting) {
        this._addAttendees(newMeeting.id);
      }

      if (this.recurrence_frequency.value && !this.meeting.recurring_meeting_id) {
        let recurrenceFrequency;
        switch (this.recurrence_frequency.value) {
          case 'Every Day':
            recurrenceFrequency = { number: 1, interval: 'day' };
            break;
          case 'Every Week':
            recurrenceFrequency = { number: 1, interval: 'week' };
            break;
          case 'Every 2 Weeks':
            recurrenceFrequency = { number: 2, interval: 'week' };
            break;
          // case 'Every Month':
          //   recurrenceFrequency = { number: 1, interval: 'month' };
          //   break;
          // case 'Every Year':
          //   recurrenceFrequency = { number: 1, interval: 'year' };
          //   break;
          default:
            recurrenceFrequency = { number: 1, interval: 'week' };
        }
        const recurringMeetingToSend: any = {
          frequency_number: recurrenceFrequency.number,
          frequency_interval: recurrenceFrequency.interval,
          recurrence_end_date: moment(this.recurrence_end_date.value).format('YYYY-MM-DD'),
          title: meetingToSend.title,
          purpose: meetingToSend.purpose,
          start_time: moment(meetingToSend.start_datetime).format('HH:mm:ss'),
          end_time: moment(meetingToSend.end_datetime).format('HH:mm:ss'),
          location: meetingToSend.location,
        };
        // TODO: recurrence start date should only be edited if the meeting start date has changed
        if (
          (!this.meeting.start_datetime && meetingToSend.start_datetime) ||
          (this.meeting.start_datetime &&
            meetingToSend.start_datetime &&
            moment(meetingToSend.start_datetime).format('YYYY-MM-DD') !==
              moment(this.meeting.start_datetime).format('YYYY-MM-DD'))
        ) {
          recurringMeetingToSend.recurrence_start_date = moment(meetingToSend.start_datetime).format('YYYY-MM-DD');
        }
        if (this.meeting.recurring_meeting_id) {
          recurringMeetingToSend.id = this.meeting.recurring_meeting_id;
          recurringMeetingToSend.reference_meeting_id = newMeeting.id;
          await this.meetingService.updateRecurringMeeting(recurringMeetingToSend).toPromise();
        } else {
          const recurringMeeting = await this.meetingService.createRecurringMeeting(recurringMeetingToSend).toPromise();
          const newRecurringMeetings = await this.meetingService
            .getMeetings([{ type: 'field', field: 'recurring_meeting_id', value: `${recurringMeeting?.id}` }])
            .toPromise();

          if (+newRecurringMeetings?.statusCode === 200) {
            newMeeting = newRecurringMeetings?.data?.meetings[0];
            this._addAttendees(newMeeting.id);
          }
        }
      }
      // TODO: remove recurrence if necessary
      this.snackbar.open(`Meeting ${this.meeting.id ? 'updated' : 'created'}!`);
      this.loaders.meeting = false;
      this.dialogRef.close(newMeeting);
    } else if (!this.purpose.touched) {
      // mark as touched so as to trigger an error
      this.purpose.markAsTouched();
    }
  }
}
