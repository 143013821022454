import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { intersection } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  FilterCostCodeFormArrayByModuleIdPipe,
  FilterCostCodeFormArrayBySearchTermPipe,
  FilterSubCostCodeFormArrayByModuleIdPipe,
} from 'src/app/pipes';
import { AuthService, ExportService, ModalService, ModuleService, ProgressIndicatorService } from 'src/app/services';
import { PurchasingBudgetService } from 'src/app/services/purchasing-budget.service';
import {
  CostCode,
  CostCodeFormValue,
  FiscalYear,
  SubCostCode,
  SubCostCodeBudget,
  SubCostCodeBudgetChange,
  SubCostCodeFormValue,
  SubCostCodeType,
  WorkspaceModule,
} from 'src/app/types';
import { convertDateToFiscalYear, getUniqueArray, isJsonEquivalent, PreferenceStorage } from 'src/app/utils';
import { BudgetChangesDialogComponent } from './budget-changes-dialog/budget-changes-dialog.component';

type OriginalBudgetValueRecord = Record<
  string,
  {
    originalUhatBudget: number;
    originalOneCallBudget: number;
    subCostCodes: Record<string, { originalUhatBudget: number; originalOneCallBudget: number }>;
  }
>;

@Component({
  selector: 'app-purchasing-budget-list',
  templateUrl: './purchasing-budget-list.component.html',
  styleUrls: ['./purchasing-budget-list.component.scss'],
  providers: [CurrencyPipe],
})
export class PurchasingBudgetListComponent implements OnInit, OnDestroy {
  @ViewChild('fiscalYear') fiscalYear;
  isEditModeEnabled = false;
  isEditModeActivating = false;
  fields: string[] = [
    'id',
    'code',
    'label',
    'sub_cost_codes{id,code,label,description,fiscal_year,sub_cost_code_type_id,module_ids,sub_cost_code_budget{id,uhat_budget,one_call_budget,is_enabled}}',
  ];
  costCodes: CostCode[] = [];
  subCostCodeTypes: SubCostCodeType[] = [];
  workspaceModules: WorkspaceModule[] = [];
  fiscalYearOptions: FiscalYear[] = [];
  fiscalYearControl = new FormControl();
  selectedModuleIds = new FormControl();
  showDeactivatedCCs = new FormControl();
  costCodeFormArray = new FormArray([]);
  searchTerm = '';
  initialValues?: CostCodeFormValue[] = [];
  moduleServiceIsLoading = true;
  originalValues: OriginalBudgetValueRecord = {};
  private subscriptions = new Subscription();
  private filterCostCodeFormArrayByModuleIdPipe = new FilterCostCodeFormArrayByModuleIdPipe();
  private filterCostCodeFormArrayBySearchTermPipe = new FilterCostCodeFormArrayBySearchTermPipe();
  private filterSubCostCodeFormArrayByModuleIdPipe = new FilterSubCostCodeFormArrayByModuleIdPipe();
  private isNewFiscalYear = true;
  private subCostCodeBudgetChanges: SubCostCodeBudgetChange[];
  private preferences = new PreferenceStorage<{
    fiscalYear: number;
    selectedModuleIds: number[];
    showDeactivatedCCs: boolean;
    version: 1;
  }>('preferences_purchasing_budget_list', {
    fiscalYear: convertDateToFiscalYear(),
    selectedModuleIds: [],
    showDeactivatedCCs: false,
    version: 1,
  });
  uhatBudgetChange;
  uhatTotal;
  oneCallBudgetChange;
  oneCallTotal;
  totalChange;
  total;
  sccToDelete = [];

  constructor(
    private budgetService: PurchasingBudgetService,
    private dialog: MatDialog,
    private exportService: ExportService,
    private moduleService: ModuleService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private cp: CurrencyPipe,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    this.selectedModuleIds.setValue(this.preferences.currentValue.selectedModuleIds);
    this.showDeactivatedCCs.setValue(this.preferences.currentValue.showDeactivatedCCs);
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading Budgets...');

    // await this.getBudgets();
    this.fiscalYearOptions = await this.budgetService.getFiscalYears().toPromise();
    this.workspaceModules = await this.budgetService.getModules().toPromise();
    this.subCostCodeTypes = await this.budgetService.getSubCostCodeTypes().toPromise();

    this.subscriptions
      .add(
        this.fiscalYearControl.valueChanges.subscribe(async (fiscalYear) => {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Building budget table...');
          this.isEditModeEnabled = false;
          await this.getActivityHistory(fiscalYear.year);
          await this.setBudgetForm(fiscalYear.year);
          this.preferences.setPartialValue({ fiscalYear: fiscalYear.year });
          this.calculateBudgetTotals();
          this.progressIndicatorService.close();
        })
      )
      .add(
        this.selectedModuleIds.valueChanges.subscribe((selectedModuleIds) => {
          this.calculateBudgetTotals();
          this.preferences.setPartialValue({ selectedModuleIds: selectedModuleIds });
        })
      )
      .add(
        this.showDeactivatedCCs.valueChanges.subscribe(() => {
          this.preferences.setPartialValue({ showDeactivatedCCs: this.showDeactivatedCCs.value });
        })
      );
    const fy = this.fiscalYearOptions.find((fy) => fy.year === this.preferences.currentValue.fiscalYear);
    this.fiscalYearControl.setValue(fy || this.fiscalYearOptions?.[0]);
    this.moduleServiceIsLoading = false;
    this.progressIndicatorService.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get isExporting(): boolean {
    return this.exportService.isExporting;
  }

  shouldShowSubCostCodeRow(
    subCostCodeFormValue: SubCostCode & SubCostCodeFormValue,
    selectedModuleIds: number[]
  ): boolean {
    // this must be a method instead of a pipe because angular does not detect changes to
    // nested form arrays when adding new sub cost codes? (ref: occ-1459 "brittany-gate")

    return (
      subCostCodeFormValue.isNewSubCostCode ||
      ((selectedModuleIds.length
        ? subCostCodeFormValue.moduleIds.some((moduleId) => selectedModuleIds.includes(moduleId))
        : true) &&
        (this.showDeactivatedCCs.value === true ? true : subCostCodeFormValue.budgetIsEnabled == true))
    );
  }

  // calculateTotals() {
  //   let oneCallBudget = 0,
  //     uhatBudget = 0;
  //   this.costCodeFormArray.controls.forEach((cc) => {
  //     cc.value.subCostCodes.forEach((scc) => {
  //       if (
  //         scc.budgetIsEnabled === true &&
  //         scc.fiscalYear === this.fiscalYearControl.value.year &&
  //         (this.selectedModuleIds.value.length === 0 ||
  //           scc.moduleIds.some((moduleId) => this.selectedModuleIds.value.includes(moduleId)))
  //       ) {
  //         oneCallBudget += scc.oneCallBudget;
  //         uhatBudget += scc.uhatBudget;
  //       }
  //     });
  //   });
  //   return { oneCallBudget, uhatBudget };
  // }

  calculateCostCodeTotal(formControlName: string): number {
    return this.filterCostCodeFormArrayByModuleIdPipe
      .transform(this.costCodeFormArray.controls, this.selectedModuleIds.value)
      .map((control) => this.calculateSubCostCodesTotal(control, formControlName))
      .reduce((acc, x) => acc + x, 0);
  }

  calculateSubCostCodesTotal(costCode: AbstractControl, formControlName: string): number {
    return this.filterSubCostCodeFormArrayByModuleIdPipe
      .transform((costCode.get('subCostCodes') as FormArray).controls, this.selectedModuleIds.value)
      .map((formGroup) => +formGroup.get(formControlName).value)
      .reduce((acc, x) => acc + x, 0);
  }

  getSubCostCodeTypeName(subCostCodeTypeId: number): string {
    return this.subCostCodeTypes.find((subCostCodeType) => subCostCodeType.id === subCostCodeTypeId)?.name;
  }

  getModuleNames(moduleIds: number[]): string[] {
    return this.workspaceModules
      .filter((m) => moduleIds.includes(m.id))
      .reduce((acc: string[], m) => [...acc, m.name], []);
  }

  handleEditClicked(): void {
    this.isEditModeActivating = true;
    setTimeout(() => {
      this.isEditModeEnabled = true;
    });
  }

  reactivateButtons(): void {
    setTimeout(() => {
      this.isEditModeActivating = false;
    });
  }

  handleSelectAll(formControlName: 'moduleIds'): void {
    switch (formControlName) {
      case 'moduleIds':
        if (this.selectedModuleIds.value.length === this.workspaceModules.length) {
          this.selectedModuleIds.setValue([]);
        } else {
          this.selectedModuleIds.setValue(getUniqueArray(this.workspaceModules.map((m) => m.id)).sort());
        }
    }
  }

  handleToggleAll(): void {
    const someAreDisabled = this.costCodeFormArray.controls.some((costCodeControl) =>
      (costCodeControl.get('subCostCodes') as FormArray).controls.some(
        (subCostCodeControl) => !subCostCodeControl.get('budgetIsEnabled').value
      )
    );

    this.costCodeFormArray.controls.forEach((costCodeControl) =>
      (costCodeControl.get('subCostCodes') as FormArray).controls.forEach((subCostCodeControl) =>
        subCostCodeControl.get('budgetIsEnabled').setValue(someAreDisabled)
      )
    );
  }

  handleToggleGroup(costCodeFormGroup: AbstractControl): void {
    const someAreDisabled = (costCodeFormGroup.get('subCostCodes') as FormArray).controls.some(
      (control) => !(control.get('budgetIsEnabled').value as boolean)
    );
    (costCodeFormGroup.get('subCostCodes') as FormArray).controls.forEach((control) =>
      control.get('budgetIsEnabled').setValue(someAreDisabled)
    );
  }

  handleAddSubCostCode(costCodeFormGroup: AbstractControl): void {
    const costCodeCode = costCodeFormGroup.get('costCode').value as string;

    (costCodeFormGroup.get('subCostCodes') as FormArray).push(
      this.createSubCostCodeFormGroup(
        costCodeCode,
        {
          code: `${costCodeCode.split('-')[0]}-`,
          label: '',
          id: null,
          description: '',
          sub_cost_code_type_id: null,
          module_ids: [],
          fiscal_year: this.fiscalYearControl.value.year,
        },
        { id: null, uhat_budget: 0, one_call_budget: 0, is_enabled: false },
        true
      )
    );

    costCodeFormGroup.markAllAsTouched();
  }

  handleNewSubCostCodeDelete(subCostCodes: AbstractControl, subCostCode: AbstractControl): void {
    this.sccToDelete.push(subCostCode);
    (subCostCodes as FormArray).removeAt(
      (subCostCodes as FormArray).controls.findIndex((control) => isJsonEquivalent(control.value, subCostCode.value))
    );
  }

  handleCancelClicked(): void {
    this.sccToDelete = [];
    this.setBudgetForm(this.fiscalYearControl.value.year);
    this.isEditModeEnabled = false;
  }

  handleSaveClicked(): void {
    const afterSave = () => {
      this.calculateBudgetTotals();
      this.progressIndicatorService.close();
      this.isEditModeEnabled = false;
    };

    void (async () => {
      // TO DO
      // const valuesToSave = [];
      // this.costCodeFormArray.controls.forEach((CCs) => {
      //   if (CCs.dirty) {
      //     const SCCs = (CCs as FormGroup).controls.subCostCodes;
      //     (SCCs as FormArray).controls.forEach((scc) => {
      //       if (scc.dirty) valuesToSave.push(scc.value);
      //     });
      //   }
      // });

      if (isJsonEquivalent(this.initialValues, this.costCodeFormArray.getRawValue())) {
        afterSave();
      } else {
        const costCodeFormValues: CostCodeFormValue[] = this.costCodeFormArray.getRawValue();

        const subCostCodes: SubCostCode[] = costCodeFormValues
          .map((costCodeFormValue) => {
            {
              return costCodeFormValue.subCostCodes.map((subCostCodeFormValue) => ({
                id: subCostCodeFormValue.subCostCodeId,
                code: subCostCodeFormValue.subCostCode,
                label: subCostCodeFormValue.subCostCodeLabel,
                description: subCostCodeFormValue.subCostCodeDescription,
                cost_code_id: costCodeFormValue.costCodeId,
                sub_cost_code_type_id: subCostCodeFormValue.subCostCodeTypeId,
                module_ids: subCostCodeFormValue.moduleIds,
                fiscal_year: subCostCodeFormValue.fiscalYear,
              }));
            }
          })
          .flat();

        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Cost Codes ...');

        const deletePromises = [];
        this.sccToDelete.forEach((scc) => {
          if (scc.value.budgetId) {
            deletePromises.push(this.budgetService.deleteSubCostCodeBudget(scc.value.budgetId).toPromise());
          }
          if (scc.value.subCostCodeId) {
            deletePromises.push(this.budgetService.deleteSubCostCode(scc.value.subCostCodeId).toPromise());
          }
        });
        await Promise.all(deletePromises);
        this.sccToDelete = [];

        try {
          const newSubCostCodes = await this.budgetService.saveSubCostCodes(subCostCodes).toPromise();

          const previousBudgetFormValues: SubCostCodeFormValue[] = [];

          const nextBudgetFormValues: SubCostCodeFormValue[] = costCodeFormValues
            .map((costCodeFormValue) => {
              return costCodeFormValue.subCostCodes.map((subCostCodeFormValue) => {
                if (subCostCodeFormValue.isNewSubCostCode) {
                  const newSubCostCode = newSubCostCodes.find(
                    (newSubCostCode) => newSubCostCode.code === subCostCodeFormValue.subCostCode
                  );

                  if (newSubCostCode) {
                    subCostCodeFormValue.subCostCodeId = newSubCostCode.id;
                  } else {
                    throw new Error('Could not retrieve new sub cost code');
                  }
                }

                return subCostCodeFormValue;
              });
            })
            .flat()
            .filter((subCostCodeFormValue) => {
              const initialValue: SubCostCodeFormValue | undefined = (
                this.initialValues.find(({ subCostCodes }) =>
                  subCostCodes.find(({ subCostCodeId }) => subCostCodeId === subCostCodeFormValue.subCostCodeId)
                ) ?? { subCostCodes: [] }
              ).subCostCodes?.find(({ subCostCodeId }) => subCostCodeId === subCostCodeFormValue.subCostCodeId);

              if (initialValue && !isJsonEquivalent(initialValue, subCostCodeFormValue)) {
                previousBudgetFormValues.push(initialValue);
                return true;
              } else return this.isNewFiscalYear || subCostCodeFormValue.isNewSubCostCode;
            });

          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Saving Budget...');

          try {
            if (nextBudgetFormValues.length > 0) {
              await this.budgetService
                .saveBudgets(this.convertSubCostCodeFormValuesToBudgets(nextBudgetFormValues))
                .toPromise();
              if (this.fiscalYearControl.value.budget_finalized === 1) {
                const previousActivityToSave = this.convertSubCostCodeFormValuesToBudgets(previousBudgetFormValues);
                const newActivityToSave = this.convertSubCostCodeFormValuesToBudgets(nextBudgetFormValues);
                await this.budgetService
                  .saveBudgetActivity({
                    fiscal_year: this.fiscalYearControl.value.year,
                    values_next: JSON.stringify(newActivityToSave),
                    values_previous: JSON.stringify(previousActivityToSave),
                  })
                  .toPromise();
              }
              await this.getActivityHistory(this.fiscalYearControl.value.year);
              await this.setBudgetForm(this.fiscalYearControl.value.year);
            }
          } catch (error) {
            console.error(error);
            this.snackBar.open('An error has occurred when saving budget.', 'Close');
          } finally {
            this.progressIndicatorService.close();
          }
        } catch (error) {
          console.error(error);
          this.snackBar.open('An error has occurred when saving sub cost codes.', 'Close');
        } finally {
          afterSave();
        }
      }
    })();
  }

  async saveBugetActivity() {
    const activity = [];
    this.costCodeFormArray.controls.forEach((CCs) => {
      const SCCs = (CCs as FormGroup).controls.subCostCodes;
      (SCCs as FormArray).controls.forEach((scc) => {
        if (scc.value.budgetIsEnabled) {
          activity.push({
            id: scc.value.budgetId,
            sub_cost_code_id: scc.value.subCostCodeId,
            fiscal_year: scc.value.fiscalYear,
            uhat_budget: scc.value.uhatBudget,
            one_call_budget: scc.value.oneCallBudget,
            is_enabled: 0,
          });
        }
      });
    });

    const newActivityToSave = activity.map((a) => {
      const newa = { ...a };
      newa.is_enabled = 1;
      return newa;
    });
    await this.budgetService
      .saveBudgetActivity({
        fiscal_year: this.fiscalYearControl.value.year,
        values_next: JSON.stringify(newActivityToSave),
        values_previous: JSON.stringify(activity),
      })
      .toPromise();
  }

  handleBudgetDifferenceClicked(
    costCodeType: 'allCodes' | 'costCodes' | 'subCostCodes',
    budgetType: 'uhat' | 'oneCall',
    code?
  ): void {
    if ((costCodeType == 'costCodes' || costCodeType == 'subCostCodes') && !code) return;
    let relevantChanges: SubCostCodeBudgetChange[];
    let titleBody = '';

    switch (costCodeType) {
      case 'allCodes':
        relevantChanges = this.subCostCodeBudgetChanges.filter(
          ({ fiscalYear }) => fiscalYear === this.fiscalYearControl.value.year
        );
        titleBody = 'All Cost Code Activity';
        break;
      case 'costCodes':
        const cc = code?.get('costCode').value;
        relevantChanges = this.subCostCodeBudgetChanges.filter(
          ({ costCode, fiscalYear }) => fiscalYear === this.fiscalYearControl.value.year && costCode.code === cc
        );
        titleBody = cc + ' - ' + code.get('costCodeLabel').value;
        break;
      case 'subCostCodes':
        const scc = code?.get('subCostCode').value;
        relevantChanges = this.subCostCodeBudgetChanges.filter(
          ({ subCostCode, fiscalYear }) => fiscalYear === this.fiscalYearControl.value.year && subCostCode.code === scc
        );
        titleBody = scc + ' - ' + code.get('subCostCodeLabel').value;
        break;
    }

    relevantChanges = relevantChanges.filter(
      (rc) =>
        this.selectedModuleIds.value.length === 0 ||
        intersection(rc.subCostCode.module_ids as number[], this.selectedModuleIds.value).length > 0
    );

    this.dialog.open(BudgetChangesDialogComponent, {
      minWidth: 600,
      data: {
        costCodeType,
        budgetType,
        relevantChanges: relevantChanges.reverse(),
        titleBody: titleBody,
      },
    });
  }

  getOriginalBudgetTotal(budgetType: 'Uhat' | 'OneCall'): number {
    return Object.values(this.originalValues).reduce<number>(
      (acc, x) => acc + (x[`original${budgetType}Budget`] as number),
      0
    );
  }

  private async getActivityHistory(fiscalYear: number) {
    this.costCodes = await this.budgetService.getCostCodes(this.fields).toPromise();
    this.subCostCodeBudgetChanges = await this.budgetService.getBudgetChanges(this.costCodes, fiscalYear).toPromise();

    this.originalValues = this.getOriginalBudgetValues(this.costCodes, this.subCostCodeBudgetChanges, fiscalYear);
  }

  private async getBudgets(): Promise<void> {
    this.costCodes = await this.budgetService.getCostCodes(this.fields).toPromise();
    this.subCostCodeTypes = await this.budgetService.getSubCostCodeTypes().toPromise();
  }

  private createSubCostCodeFormGroup(
    costCodeCode: string,
    subCostCode: SubCostCode,
    subCostCodeBudget: SubCostCodeBudget,
    isCostCodeNew = false
  ): FormGroup {
    return new FormGroup({
      isNewSubCostCode: new FormControl(isCostCodeNew),
      subCostCode: new FormControl(
        { value: subCostCode.code, disabled: subCostCode.id && this.fiscalYearControl.value.budget_finalized === 1 },
        [
          Validators.required,
          RxwebValidators.unique(),
          RxwebValidators.startsWith({ value: costCodeCode }),
          RxwebValidators.minLength({ value: 8 }),
          RxwebValidators.maxLength({ value: 9 }),
        ]
      ),
      subCostCodeLabel: new FormControl(
        { value: subCostCode.label, disabled: subCostCode.id && this.fiscalYearControl.value.budget_finalized === 1 },
        [Validators.required]
      ),
      subCostCodeId: new FormControl(subCostCode.id),
      subCostCodeDescription: new FormControl(subCostCode.description),
      subCostCodeTypeId: new FormControl(subCostCode.sub_cost_code_type_id, [Validators.required]),
      moduleIds: new FormControl(subCostCode.module_ids, [Validators.required]),
      budgetId: new FormControl(subCostCodeBudget?.id || null),
      uhatBudget: new FormControl(subCostCodeBudget?.uhat_budget || 0, [Validators.min(0)]),
      oneCallBudget: new FormControl(subCostCodeBudget?.one_call_budget || 0, [Validators.min(0)]),
      budgetIsEnabled: new FormControl(!!subCostCodeBudget?.is_enabled),
      fiscalYear: new FormControl(subCostCode?.fiscal_year),
    });
  }

  private async setBudgetForm(fiscalYear: number): Promise<void> {
    this.costCodeFormArray.clear();

    const sortByCode = (a: CostCode, b: CostCode) => (a.code > b.code ? 1 : -1);

    for (const costCode of this.costCodes.sort(sortByCode)) {
      const subCostCodeFormGroups = costCode.sub_cost_codes
        .filter((scc) => scc.fiscal_year === fiscalYear)
        .sort(sortByCode)
        .reduce<FormGroup[]>((acc, subCostCode) => {
          const foundSubCostCodeBudget: SubCostCodeBudget | undefined = subCostCode.sub_cost_code_budget;
          return [...acc, this.createSubCostCodeFormGroup(costCode.code, subCostCode, foundSubCostCodeBudget)];
        }, []);

      this.costCodeFormArray.push(
        new FormGroup({
          costCode: new FormControl(costCode.code),
          costCodeId: new FormControl(costCode.id),
          costCodeLabel: new FormControl(costCode.label),
          subCostCodes: new FormArray(subCostCodeFormGroups),
        })
      );
    }

    this.initialValues = this.costCodeFormArray.getRawValue();

    this.isNewFiscalYear = this.initialValues.every(({ subCostCodes }) =>
      subCostCodes.every(({ uhatBudget, oneCallBudget }) => !uhatBudget && !oneCallBudget)
    ); // is new fiscal year if all values are zero

    this.costCodeFormArray.controls = [...this.costCodeFormArray.controls]; // needed to detect changes on FY change

    this.costCodeFormArray.markAllAsTouched();
  }

  private resetCostCodeFormArrayToInitialValue(): void {
    // this filters out all unsaved new sub cost codes so that the costCodeFormArray has the same number of indexes as initialData
    for (const costCodeFormGroup of this.costCodeFormArray.controls as FormGroup[]) {
      const subCostCodeFormArray = costCodeFormGroup.controls.subCostCodes as FormArray;

      subCostCodeFormArray.controls = (subCostCodeFormArray.controls as FormGroup[]).filter(
        (subCostCodeFormGroup) => !subCostCodeFormGroup.value.isNewSubCostCode
      );
    }
    this.costCodeFormArray.setValue(this.initialValues);
  }

  private convertSubCostCodeFormValuesToBudgets(subCostCodeFormValues: SubCostCodeFormValue[]): SubCostCodeBudget[] {
    return subCostCodeFormValues.map((subCostCodeFormValue) => {
      return {
        id: subCostCodeFormValue.budgetId,
        sub_cost_code_id: subCostCodeFormValue.subCostCodeId,
        fiscal_year: +this.fiscalYearControl.value.year,
        uhat_budget: subCostCodeFormValue.uhatBudget || 0,
        one_call_budget: subCostCodeFormValue.oneCallBudget || 0,
        is_enabled: subCostCodeFormValue.budgetIsEnabled ? 1 : 0,
      };
    });
  }

  private removeNewBudgetsWithoutPreviousValue(
    newBudgets: SubCostCodeBudget[],
    previousBudgets: SubCostCodeBudget[]
  ): SubCostCodeBudget[] {
    return newBudgets.filter((newBudget) =>
      previousBudgets.some((previousBudget) => previousBudget.sub_cost_code_id === newBudget.sub_cost_code_id)
    );
  }

  private getOriginalBudgetValues(
    costCodes: CostCode[],
    subCostCodeBudgetChanges: SubCostCodeBudgetChange[],
    fiscalYear: number
  ): OriginalBudgetValueRecord {
    const originalValues: OriginalBudgetValueRecord = {};

    for (const costCode of costCodes) {
      originalValues[costCode.code] = { originalUhatBudget: null, originalOneCallBudget: null, subCostCodes: {} };

      for (const subCostCode of costCode.sub_cost_codes) {
        if (
          (this.selectedModuleIds.value.length > 0 &&
            intersection(subCostCode.module_ids as number[], this.selectedModuleIds.value).length <= 0) ||
          subCostCode.sub_cost_code_budget?.is_enabled === 0 ||
          subCostCode.fiscal_year !== fiscalYear
        )
          continue;
        originalValues[costCode.code].subCostCodes[subCostCode.code] = {
          originalUhatBudget: null,
          originalOneCallBudget: null,
        };

        originalValues[costCode.code].subCostCodes[subCostCode.code] = {
          originalUhatBudget: (
            subCostCodeBudgetChanges.filter(
              (change) => change.subCostCode.code === subCostCode.code && change.isEnabledAfter === true
            )[0] || {
              uhatBudgetAfter: null,
            }
          ).uhatBudgetAfter,
          originalOneCallBudget: (
            subCostCodeBudgetChanges.filter(
              (change) => change.subCostCode.code === subCostCode.code && change.isEnabledAfter === true
            )[0] || {
              oneCallBudgetAfter: null,
            }
          ).oneCallBudgetAfter,
        };
      }

      const originalUhatBudget: number = Object.values(originalValues[costCode.code].subCostCodes).reduce<number>(
        (total, { originalUhatBudget }) => total + originalUhatBudget,
        0
      );

      const originalOneCallBudget: number = Object.values(originalValues[costCode.code].subCostCodes).reduce<number>(
        (total, { originalOneCallBudget }) => total + originalOneCallBudget,
        0
      );

      const changesDetected = (
        subCostCodes: Record<string, { originalUhatBudget: number; originalOneCallBudget: number }>,
        budgetType: 'Uhat' | 'OneCall'
      ): boolean => {
        return Object.values(subCostCodes).some((subCostCode) => subCostCode[`original${budgetType}Budget`]);
      };

      originalValues[costCode.code] = {
        ...originalValues[costCode.code],
        originalUhatBudget: changesDetected(originalValues[costCode.code].subCostCodes, 'Uhat')
          ? originalUhatBudget
          : null,
        originalOneCallBudget: changesDetected(originalValues[costCode.code].subCostCodes, 'OneCall')
          ? originalOneCallBudget
          : null,
      };
    }
    return originalValues;
  }

  public exportARF(): void {
    try {
      // get the filtered trnsformed cost codes controls
      const filteredCostCodeControls: AbstractControl[] = this.filterCostCodeFormArrayBySearchTermPipe.transform(
        this.filterCostCodeFormArrayByModuleIdPipe.transform(
          this.costCodeFormArray.controls,
          this.selectedModuleIds.value
        ),
        this.searchTerm,
        this.workspaceModules
      );
      // extract the values from the filtered controls
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const filteredCostCodes: CostCode[] = filteredCostCodeControls.map(
        (control: AbstractControl): CostCode => ({
          code: control?.get('costCode')?.value,
          label: control?.get('costCodeLabel')?.value,
          sub_cost_codes: control?.get('subCostCodes')?.value,
        })
      );
      // spread the parent with the children
      const formatedCostCodes = [];
      for (const costCode of filteredCostCodes) {
        const subCostCodes = costCode?.sub_cost_codes
          ?.filter((unFIlteredSubCostCode: SubCostCode & SubCostCodeFormValue) =>
            this.shouldShowSubCostCodeRow(unFIlteredSubCostCode, this.selectedModuleIds.value)
          )
          .map((subCostCode: SubCostCode & SubCostCodeFormValue) => ({
            parent_cost_code: costCode?.code || '',
            parent_label: costCode?.label || '',
            sub_cost_code: subCostCode.subCostCode || '',
            sub_cost_code_label: subCostCode?.subCostCodeLabel || '',
            sub_cost_code_description: subCostCode?.subCostCodeDescription || '',
            sub_cost_code_type: this.getSubCostCodeTypeName(subCostCode?.subCostCodeTypeId || 0) || '',
            workspaces: '"' + this.getModuleNames(subCostCode?.moduleIds)?.join(', ') + '"', //comma seperated value in a single csv column.
          }));
        formatedCostCodes.push(...subCostCodes);
      }

      if (formatedCostCodes?.length) {
        const dataToReturn: string[] = [
          'Parent Cost Code, Parent Label, Sub Cost Code, Sub Cost Code Label, Sub Cost Code Description, Type, Workspaces',
        ];

        for (const entry of formatedCostCodes) {
          dataToReturn.push(
            this.exportService
              .sanitizeItems([
                entry.parent_cost_code || '',
                entry.parent_label || '',
                entry.sub_cost_code || '',
                entry.sub_cost_code_label || '',
                entry.sub_cost_code_description || '',
                entry.sub_cost_code_type || '',
              ])
              .concat(',')
              .concat(entry.workspaces || '') //comma seperated value in a single csv column.
          );
        }

        this.exportService.exportDataWithConfirmation(
          dataToReturn,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `cost_codes_export_${moment().format('MM/DD/YY')}.csv`,
          'Confirm Data Export',
          `${this.searchTerm ? 'Data export will use the current search term. ' : ''}Are you sure you wish to continue?`
        );
      }
    } catch (error) {
      this.snackBar.open(`Something went wrong while downloading cost codes`, `Close`);
    }
  }

  getCostCodeActivityChange(costCode, type?) {
    let total = type == 'uhatBudget' || !type ? this.calculateSubCostCodesTotal(costCode, 'uhatBudget') : 0;
    total += type == 'oneCallBudget' || !type ? this.calculateSubCostCodesTotal(costCode, 'oneCallBudget') : 0;
    let original =
      type == 'uhatBudget' || !type ? this.originalValues[costCode.get('costCode').value]?.originalUhatBudget : 0;
    original +=
      type == 'oneCallBudget' || !type ? this.originalValues[costCode.get('costCode').value]?.originalOneCallBudget : 0;
    const diff = total - original;
    return diff > 0 ? `+${this.cp.transform(diff)}` : 'No Net Change';
  }

  getSubCostCodeActivityChange(costCode, subCostCode, type?) {
    if (
      this.originalValues[costCode.get('costCode').value]?.subCostCodes[subCostCode.get('subCostCode').value]
        ?.originalUhatBudget == null ||
      this.originalValues[costCode.get('costCode').value]?.subCostCodes[subCostCode.get('subCostCode').value]
        ?.originalOneCallBudget == null
    )
      return 'No Net Change';
    let total = type == 'uhatBudget' || !type ? subCostCode.get('uhatBudget').value : 0;
    total += type == 'oneCallBudget' || !type ? subCostCode.get('oneCallBudget').value : 0;
    let original =
      type == 'uhatBudget' || !type
        ? this.originalValues[costCode.get('costCode').value]?.subCostCodes[subCostCode.get('subCostCode').value]
            ?.originalUhatBudget
        : 0;
    original +=
      type == 'oneCallBudget' || !type
        ? this.originalValues[costCode.get('costCode').value]?.subCostCodes[subCostCode.get('subCostCode').value]
            ?.originalOneCallBudget
        : 0;
    const diff = total - original;
    return diff === 0 ? 'No Net Change' : `${diff > 0 ? '+' : ''}${this.cp.transform(diff)}`;
  }

  calculateBudgetTotals() {
    const uhatCostCodeTotal = this.calculateCostCodeTotal('uhatBudget');
    const uhatTotal = this.getOriginalBudgetTotal('Uhat');
    const uhatBudgetChange = uhatCostCodeTotal - uhatTotal;
    const oneCallCostCodeTotal = this.calculateCostCodeTotal('oneCallBudget');
    const oneCallTotal = this.getOriginalBudgetTotal('OneCall');
    const oneCallBudgetChange = oneCallCostCodeTotal - oneCallTotal;
    this.uhatBudgetChange = this.formatTotal(uhatBudgetChange);
    this.oneCallBudgetChange = this.formatTotal(oneCallBudgetChange);
    this.totalChange = this.formatTotal(uhatBudgetChange + oneCallBudgetChange);
    this.uhatTotal = this.cp.transform(uhatCostCodeTotal);
    this.oneCallTotal = this.cp.transform(oneCallCostCodeTotal);
    this.total = this.cp.transform(uhatCostCodeTotal + oneCallCostCodeTotal);
  }

  formatTotal(total: number) {
    return total === 0 ? 'No Net Change' : `${total > 0 ? '+' : ''}${this.cp.transform(total)}`;
  }

  addFiscalYear() {
    this.fiscalYear.close();
    let fiscalYear = this.fiscalYearOptions[this.fiscalYearOptions.length - 1]?.year;
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Confirm`,
        headerText: `Create New Budget`,
        descriptionText: `Create a new budget for fiscal year ${++fiscalYear}`,
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          await this.budgetService.createFiscalYear(fiscalYear).toPromise();
          this.fiscalYearOptions = await this.budgetService.getFiscalYears().toPromise();
          const fy = this.fiscalYearOptions.find((fy) => fy.year === fiscalYear);
          if (fy) this.fiscalYearControl.setValue(fy);
        }
      });
  }

  async handleFinalizeClicked() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Confirm`,
        headerText: `Finalize Budget Year`,
        descriptionText: `Finalize Budget Year ${this.fiscalYearControl.value.year}?`,
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          const toUpdate = { id: this.fiscalYearControl.value.id, budget_finalized: 1 };
          const updatedFY = await this.budgetService.updateFiscalYear(toUpdate).toPromise();
          let fyOption = this.fiscalYearOptions.find((fy) => fy.id === updatedFY.id);
          fyOption.budget_finalized = 1;
          await this.saveBugetActivity();
          await this.getActivityHistory(this.fiscalYearControl.value.year);
          this.calculateBudgetTotals();
        }
      });
  }
}
