<div class="project-modal" style="min-width: 380px">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">Credentials Expired</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <form [formGroup]="loginFormGroup">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" required="true" formControlName="password" />
            <mat-error *ngIf="password.errors?.required">Password is required</mat-error>
            <mat-error *ngIf="password.errors?.invalid">Password is invalid</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button type="button" class="full-width gray" color="accent" (click)="cancel()">Change User</button>
      </div>
      <div class="col">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">Login</button>
      </div>
    </div>
  </form>
</div>

<!-- <style>
  ::ng-deep .bidPackageDialogForm .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0rem;
  }
</style> -->
