import { Pipe, PipeTransform } from '@angular/core';

import { orderBy } from 'lodash';

import { AuthService, MessagingSystemService } from 'src/app/services';
import { MessageFilterSettings, Message } from 'src/app/models';

@Pipe({
  name: 'messageFilter',
})
export class MessageFilterPipe implements PipeTransform {
  constructor(public authService: AuthService, private messageService: MessagingSystemService) {}

  /** This is very expensive with the way the pipe is updating. Need to figure out how to run this only explicitely to not bog down the app */
  transform(messages: Message[], filter: MessageFilterSettings): Message[] {
    if (!messages) {
      return messages;
    }

    const userId = this.authService?.getLoggedInUser()?.id;

    if (filter.filterProjectId) {
      messages = messages.filter((m) => m.channel_project_id === filter.filterProjectId);
    }
    if (filter.myMessages) {
      messages = messages.filter((m) => {
        // needs to convert the follower string to an array
        if (m.conversation_follower_ids && !Array.isArray(m.conversation_follower_ids)) {
          m.conversation_follower_ids = m.conversation_follower_ids
            .replace(/"/g, '')
            .substring(1, m.conversation_follower_ids.length - 1)
            .split(',');
        }
        return (
          (m.to_users && m.to_users.find((u) => +u.id === userId)) ||
          (m.conversation_follower_ids && m.conversation_follower_ids.find((u) => +u === +userId))
        );
      });
    }
    if (filter.toMe) {
      messages = messages.filter((m) => m.to_users && m.to_users.find((u) => +u.id === +userId));
    }
    if (filter.unread) {
      messages = messages.filter(
        (m) => !Array.isArray(m.read_by_user_ids) || m.read_by_user_ids.find((id) => id === userId) == null
      );
    }
    if (filter.sent) {
      messages = messages.filter((m) => m.created_by_id === userId);
    } else {
      messages = messages.filter((m) => m.created_by_id !== userId);
    }
    const userCategories = [];
    if (filter.allowStaff) {
      userCategories.push(1);
    }
    if (filter.allowVendors) {
      userCategories.push(2);
    }
    if (filter.allowTenants) {
      userCategories.push(3);
    }
    messages = messages.filter((m) => userCategories.includes(m.channel_type_id));

    // Search every message for the search term. This may be pretty slow. Maybe not, who knows?
    let searchString = filter.messageSearchString;
    if (searchString) {
      searchString = searchString.toLowerCase();
    }
    if (searchString && searchString.length > 0) {
      messages = messages.filter((m) => {
        return (
          (m.created_by_first_name + m.created_by_last_name).toLowerCase().includes(searchString) ||
          m.conversation_subject.toLowerCase().includes(searchString) ||
          m.content.toLowerCase().includes(searchString)
        );
      });
    }

    if (filter.displayByDateDescending) {
      return orderBy(messages, ['created_datetime'], ['desc']);
    } else {
      return orderBy(messages, ['created_datetime'], ['asc']);
    }
  }
}
