<app-base-dialog [draggable]="true" [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div data-test-id="meeting-dialog" id="meeting-dialog" class="project-modal create-meeting-form p-0 meeting-dialog">
  <form [formGroup]="meetingFormGroup">
    <div mat-dialog-content>
      <div
        class="d-flex align-items-top mb-3 p-3 br-10 bg-shade-red"
        *ngIf="recurrence_frequency.value && meeting.recurring_meeting_id"
      >
        <i class="fa fa-exclamation-circle dkred mr-2 py-1"></i>
        <p class="dkred mb-0">
          This will update this meeting ONLY. If you need to make a change to the remaining meetings in this recurring
          schedule, please contact the Dev Team via the Feedback Link in the side navigation.
        </p>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="font-weight-semibold dkblue mb-2">Meeting Title</h6>
          <mat-form-field class="full-width mb-2" appearance="outline">
            <!-- <mat-label>Meeting Title</mat-label> -->
            <input matInput required="true" type="text" formControlName="title" />
            <mat-hint
              class="mt-4"
              align="end"
              [ngClass]="{
                red: title?.value?.length > maximumTitleLength,
                gray: title?.value?.length <= maximumTitleLength
              }"
              >{{ title_counter }}</mat-hint
            >
            <mat-error *ngIf="title.errors?.required">Title is required</mat-error>
            <mat-error *ngIf="title.errors?.maxlength"
              >Exceeded the max length of {{ maximumTitleLength }} characters</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row mb-3">
        <div class="col-12 meeting-textarea">
          <h6 class="font-weight-semibold dkblue mb-2">
            Meeting Purpose
          </h6>
          <mat-form-field class="full-width" appearance="outline">
            <textarea
              matInput
              required="true"
              type="text"
              rows="3"
              formControlName="purpose"
            ></textarea>
            <mat-error *ngIf="purpose.errors?.required">Purpose is required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <div class="row mb-3">
        <div class="col-12 meeting-textarea">
          <label class="main-label dkblue mb-2"> Meeting Purpose </label>
          <app-editor #editor placeholder="What is the purpose of the meeting?"></app-editor>
        </div>
      </div>
      <!--    <div class="row">-->
      <!--      <div class="col-12">-->
      <!--        <mat-form-field class="full-width" appearance="outline">-->
      <!--          <mat-label>Project</mat-label>-->
      <!--          <mat-select matNativeControl formControlName="project">-->
      <!--            <mat-option [value]="null">Project 1</mat-option>-->
      <!--          </mat-select>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="row mb-3">
        <div class="col-5">
          <h6 class="font-weight-semibold dkblue mb-2">Meeting Date</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>Date</mat-label> -->
            <input
              matInput
              required="true"
              (click)="datePicker.open()"
              [matDatepicker]="datePicker"
              autocomplete="off"
              formControlName="date"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="date.errors?.required">Date is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col pl-0">
          <h6 class="font-weight-semibold dkblue mb-2">Start Time</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>Start Time</mat-label> -->
            <input matInput required="true" type="time" formControlName="start_time" />
            <mat-error *ngIf="start_time.errors?.required">Start time is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col pl-0">
          <h6 class="font-weight-semibold dkblue mb-2">End Time</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>End Time</mat-label> -->
            <input matInput required="true" type="time" formControlName="end_time" />
            <mat-error *ngIf="end_time.errors?.required">End time is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-5">
          <app-workspace-dropdown #workspace [required]="false"></app-workspace-dropdown>
        </div>
        <div class="col pl-0">
          <h6 class="font-weight-semibold dkblue mb-2">Meeting Location</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>Location</mat-label> -->
            <input matInput placeholder="Building, room, online, etc." type="text" formControlName="location" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-5">
          <h6 class="font-weight-semibold dkblue mb-2">Repeat Meeting</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>Repeat Meeting</mat-label> -->
            <mat-select
              matNativeControl
              formControlName="recurrence_frequency"
              (selectionChange)="changeRecurrenceFrequency()"
            >
              <mat-option [value]="null">Never</mat-option>
              <mat-option value="Every Day">Every Day</mat-option>
              <mat-option value="Every Week">Every Week</mat-option>
              <mat-option value="Every 2 Weeks">Every 2 Weeks</mat-option>
              <!-- <mat-option value="Every Month">Every Month</mat-option>
              <mat-option value="Every Year">Every Year</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isRecurring" class="col pl-0">
          <h6 class="font-weight-semibold dkblue mb-2" [ngClass]="{ gray: !isRecurring }">Recurring End Date</h6>
          <mat-form-field class="full-width" appearance="outline">
            <!-- <mat-label>End Date</mat-label> -->
            <input
              matInput
              required="true"
              (click)="recurrenceEndDatePicker.open()"
              [matDatepicker]="recurrenceEndDatePicker"
              autocomplete="off"
              formControlName="recurrence_end_date"
            />
            <mat-datepicker-toggle matSuffix [for]="recurrenceEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #recurrenceEndDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="recurrence_end_date.errors?.required">End Date is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <h6 class="font-weight-semibold dkblue mb-3 pb-1">Is this meeting Mandatory?</h6>
          <div>
            <mat-checkbox formControlName="mandatory">Mandatory</mat-checkbox>
          </div>
        </div>
        <div class="col pl-0">
          <h6 class="dkblue font-weight-semibold mb-2">Attendees</h6>
          <p
            *ngIf="recurrence_frequency?.value && !meeting?.recurring_meeting_id && attendees?.length > 1"
            class="small"
          >
            The selected attendees will only be added to the first meeting
          </p>
          <app-profile-thumbnail
            *ngFor="let attendee of attendees"
            [noBorder]="true"
            class="d-inline-block align-middle mr-3 mb-3 pointer"
            [height]="40"
            [width]="40"
            [userId]="attendee?.id"
            [overlayIconOpacity]="1"
            [overlayIcon]="icon(attendee.id)"
            [overlayIconColor]="'white'"
            [overlayIconBackground]="'bg-gray'"
            (click)="removeAttendee(attendee.id)"
            matTooltip="{{ attendee.first_name }} {{ attendee.last_name }} "
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
          ></app-profile-thumbnail>
          <button
            type="button"
            mat-mini-fab
            color="success"
            class="mr-3 mb-3 d-inline-block align-middle"
            (click)="addAttendee()"
          >
            <mat-icon style="margin-top: -3px">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="row pb-4 pt-5 align-items-center">
      <div class="col-6">
        <button
          data-test-id="create-meeting-dialog-cancel-button"
          mat-button
          class="gray full-width"
          type="button"
          (click)="close()"
        >
          Cancel
        </button>
      </div>
      <div class="col-6">
        <button
          mat-flat-button
          *ngIf="!loaders.meeting"
          class="full-width"
          color="success"
          type="submit"
          (click)="submit()"
        >
          Save
        </button>
        <button mat-flat-button *ngIf="loaders.meeting" class="full-width" color="success" type="button">
          <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
        </button>
      </div>
    </div>
  </form>
</div>
