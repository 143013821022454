<div class="container-fluid app-container p-5">
  <div class="row mb-4 align-items-center">
    <div class="col d-flex align-items-center">
      <h3 class="dkblue mb-0 font-weight-semibold mr-auto">Authorized Requestors</h3>
      <button [routerLink]="'/user-directory'" mat-button color="accent">Back to Directory</button>
    </div>
  </div>
  <br />
  <div class="row list-filters align-items-center mb-4">
    <div class="col-4">
      <!--search input for all none ar users-->
      <mat-form-field
        *ngIf="isSearchingNonArUsers && (authService.isAppAdmin || authService.isARManager)"
        appearance="outline"
        class="full-width"
      >
        <input matInput [formControl]="searchAllUsersTerm" placeholder="Search Non-ARs" />
        <mat-icon *ngIf="searchAllUsersTerm?.value && !isFetching" (click)="clear()" class="ltgray pointer" matSuffix
          >cancel</mat-icon
        >
        <mat-progress-spinner
          matSuffix
          *ngIf="searchAllUsersTerm?.value && isFetching"
          mode="indeterminate"
          diameter="20"
        ></mat-progress-spinner>
      </mat-form-field>
      <!--search input for all ar users-->
      <mat-form-field *ngIf="!isSearchingNonArUsers" appearance="outline" class="full-width">
        <input matInput [formControl]="searchAuthorizedUsersTerm" placeholder="Search ARs" />
        <mat-icon
          *ngIf="searchAuthorizedUsersTerm?.value && !isFetching"
          (click)="clear()"
          class="ltgray pointer"
          matSuffix
          >cancel</mat-icon
        >
        <mat-progress-spinner
          matSuffix
          *ngIf="searchAuthorizedUsersTerm?.value && isFetching"
          mode="indeterminate"
          diameter="20"
        ></mat-progress-spinner>
      </mat-form-field>
    </div>
    <div class="col text-right">
      <mat-form-field
        *ngIf="!isSearchingNonArUsers && (authService.isAppAdmin || authService.isARManager)"
        appearance="outline"
        class="m-0 dkblue ml-3"
      >
        <mat-select multiple [(ngModel)]="selectedUserColumns" placeholder="Export Columns">
          <mat-select-trigger>Export Columns</mat-select-trigger>
          <mat-option *ngFor="let userColumn of userColumns" [value]="userColumn">
            {{ userColumn.header }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        *ngIf="!isSearchingNonArUsers && (authService.isAppAdmin || authService.isARManager)"
        mat-button
        color="accent"
        class="ml-3"
        (click)="exportData()"
        [disabled]="selectedUserColumns?.length === 0"
      >
        <mat-icon> get_app </mat-icon>
        <span> Export </span>
      </button>
      <button
        mat-flat-button
        class="ml-3"
        *ngIf="!isSearchingNonArUsers && (authService.isAppAdmin || authService.isARManager)"
        color="success"
        (click)="addAnARIsClicked()"
      >
        Add An AR
      </button>
      <!--brings us back to ar-->
      <button
        mat-flat-button
        class="ml-3"
        *ngIf="isSearchingNonArUsers && (authService.isAppAdmin || authService.isARManager)"
        color="accent"
        (click)="fetchARUsers()"
      >
        Back To ARs
      </button>
    </div>
  </div>
  <div *ngIf="isSearchingNonArUsers" class="row mb-4 mt-2">
    <div class="col-6">
      <h5 class="mb-0 font-weight-normal dkgray lh-24">
        Use the field above to find the person who needs to be an Authorized Requestor. After granting access, click the
        <span class="font-weight-semibold">Back To ARs</span>
        button above to go back to the list of current ARs.
      </h5>
    </div>
  </div>
  <div [hidden]="isSearchingNonArUsers && !searchAllUsersTerm?.value" class="portlet-table-header">
    <div class="row">
      <div class="col-2">
        <label class="main-label"> Name </label>
      </div>
      <div class="col-1">
        <label class="main-label"> User Type </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Company/Department </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Building </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Email Address </label>
      </div>
      <div class="col-2">
        <label class="main-label"> Action </label>
      </div>
      <div class="col-1">
        <label class="main-label"> </label>
      </div>
    </div>
  </div>

  <div [hidden]="isSearchingNonArUsers && !searchAllUsersTerm?.value" class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body request ease item" *ngFor="let u of filteredUsers">
      <div class="row align-items-center">
        <div class="col-2 d-flex align-items-center">
          <app-profile-thumbnail
            [noBorder]="true"
            class="mr-4"
            [height]="30"
            [width]="30"
            [userId]="u.id"
            [overlayIconOpacity]="1"
            [overlayIcon]="u.is_ar ? 'verified_user' : ''"
            [overlayIconColor]="'dkgray'"
            [overlayIconBackground]="'white'"
            [overlayIconTooltip]="'Authorized Requester'"
          ></app-profile-thumbnail>
          <div class="d-flex flex-column">
            <p class="m-0 dkblue font-weight-semibold lh-18">{{ u.first_name }} {{ u.last_name }}</p>
            <p class="m-0 dkgray lh-18">{{ u.title }}</p>
          </div>
        </div>
        <div class="col-1">
          <p class="m-0 dkblue">
            {{ u.user_type_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0 dkblue">
            {{ u.company_name || u.department_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0 dkblue">
            {{ u.building_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0 dkblue">
            {{ u.email }}
          </p>
        </div>
        <div class="col-2 text-center">
          <button
            *ngIf="!u.is_ar"
            type="button"
            mat-flat-button
            color="success"
            [disabled]="!isAppAdmin && !isARManager"
            (click)="addOrRemoveARPermission(u)"
          >
            Add AR Access
          </button>
          <button
            *ngIf="u.is_ar"
            type="button"
            mat-button
            color="warn"
            [disabled]="!isAppAdmin && !isARManager"
            (click)="addOrRemoveARPermission(u)"
          >
            Remove AR Access
          </button>
        </div>
        <div class="col-1 text-center">
          <button type="button" mat-button color="accent" class="px-2 lh-24" (click)="viewHistory(u)">History</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      *ngIf="searchAllUsersTerm?.value && isSearchingNonArUsers && +filteredUsers.length === 0"
      class="col text-center"
    >
      <h5 class="gray m-0">
        <span> There are no users matching the search criteria. </span>
      </h5>
    </div>
    <div
      *ngIf="searchAuthorizedUsersTerm?.value && !isSearchingNonArUsers && +filteredUsers.length === 0"
      class="col text-center"
    >
      <h5 class="gray m-0">
        <span> There are no authorized users matching the search criteria. </span>
      </h5>
    </div>
  </div>
</div>
