<app-base-dialog [title]="taskId ? 'Task Files' : 'Files'" (closeDialog)="cancel()"></app-base-dialog>
<div class="project-modal p-0">
  <div class="d-flex flex-column pt-3 mb-4">
    <h4 class="m-0 dkblue font-weight-semibold mb-3">
      {{ taskId ? 'Select the Executed Contract' : 'Select a file' }}
    </h4>
    <h5
      *ngIf="files?.length"
      class="dkgray font-weight-normal m-0"
      style="line-height: 1.8rem; white-space: pre-wrap"
      [innerHTML]="taskId ? 'Please select the Executed Contract from the files below.' : 'Please select a file below'"
    ></h5>
    <h5 *ngIf="!files?.length" class="dkgray font-weight-normal m-0" style="line-height: 1.8rem">
      There are currently no files attached to the associated contract task.
    </h5>
  </div>
  <div>
    <mat-radio-group aria-label="Select an option" labelPosition="after">
      <div class="d-flex align-items-center mb-3" *ngFor="let file of files">
        <mat-radio-button class="mr-2 mt-1" value="{{ file.file_id || file.id }}" (change)="selectContract($event)">
        </mat-radio-button>
        <app-file-chip [file]="file" class="mr-auto"></app-file-chip>
      </div>
    </mat-radio-group>
  </div>
  <div class="row mt-5">
    <div class="col m-0">
      <button mat-button class="gray full-width m-0" (click)="cancel()" type="button">Cancel</button>
    </div>
    <div *ngIf="files?.length" class="col m-0">
      <button mat-flat-button color="success" class="full-width m-0" (click)="submit()" type="button">
        Select File
      </button>
    </div>
  </div>
</div>
