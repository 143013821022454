<!--if we want to re-enable the toggle, remove the router link-->
<div
  (click)="dashboardService.toggleProject(project.id)"
  [routerLink]="['/projects/' + project.id]"
  class="m-portlet__body item ease p-3 pointer"
>
  <div class="row align-items-center">
    <!--    <div [routerLink]="['/projects/' + project.id]" class="col">-->
    <div class="col">
      <h6 class="dkblue font-weight-semibold m-0">
        <span>
          {{ project.title ? project.title : 'Project' + ' ' + project.code }}
        </span>
      </h6>
      <label class="main-label m-0 gray">
        <span
          class="badge white text-uppercase mr-2"
          [ngClass]="{
            'bg-red': project.priority_id === 3,
            'bg-ltblue': project.priority_id === 2,
            'bg-ltgray': project.priority_id === 1
          }"
        >
          {{ project.priority_name }}
        </span>
        <span class="d-inline-block align-middle">
          {{ project.code }}{{ project.building_code ? ' • ' : '' }}{{ project.building_code
          }}{{ project.floor_code ? ' • Floor ' : '' }}{{ project.floor_code }}
        </span>
      </label>
    </div>
    <div class="col">
      <mat-chip-list class="mat-chip-sm d-inline-block align-middle mr-4" style="margin-top: -2px">
        <mat-chip>
          <i
            class="fa fa-circle d-inline-block align-bottom"
            [ngClass]="{
              green: project.on_schedule,
              red: !project.on_schedule
            }"
          ></i>
          <span class="d-inline-block align-bottom ml-2 mr-2">
            {{ project.current_phase_name }}
          </span>
          <!--            TODO this should be a component (also change in tasks)-->
          <div *ngIf="showDesktop" id="timeline">
            <div class="row">
              <div class="step bg-dkblue step-first"></div>
              <div
                class="step"
                [ngClass]="{
                  'bg-dkblue':
                    project.current_phase_name === 'Budget' ||
                    project.current_phase_name === 'Design' ||
                    project.current_phase_name === 'Bidding' ||
                    project.current_phase_name === 'Construction' ||
                    project.current_phase_name === 'Closeout'
                }"
              ></div>
              <div
                class="step"
                [ngClass]="{
                  'bg-dkblue':
                    project.current_phase_name === 'Design' ||
                    project.current_phase_name === 'Bidding' ||
                    project.current_phase_name === 'Construction' ||
                    project.current_phase_name === 'Closeout'
                }"
              ></div>
              <div
                class="step"
                [ngClass]="{
                  'bg-dkblue':
                    project.current_phase_name === 'Bidding' ||
                    project.current_phase_name === 'Construction' ||
                    project.current_phase_name === 'Closeout'
                }"
              ></div>
              <div
                class="step"
                [ngClass]="{
                  'bg-dkblue':
                    project.current_phase_name === 'Construction' || project.current_phase_name === 'Closeout'
                }"
              ></div>
              <div
                class="step step-last"
                [ngClass]="{
                  'bg-dkblue': project.current_phase_name === 'Closeout'
                }"
              ></div>
            </div>
          </div>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="col">
      <p class="m-0 font-weight-normal dkblue d-inline-block mb-2 mr-2">
        <span class="font-weight-bold">
          {{ completeTasks.length }}
        </span>
        of {{ totalTasks.length }}
      </p>
      <label class="main-label m-0 mr-3 d-inline-block dkblue"> Tasks Complete; </label>
      <p class="m-0 font-weight-normal red d-inline-block mb-2 mr-2">
        <span class="font-weight-bold">
          {{ overdueTasks.length }}
        </span>
      </p>
      <label class="main-label m-0 d-inline-block red"> Tasks Overdue </label>
      <mat-progress-bar
        color="accent"
        mode="buffer"
        [value]="getPercentValue(completeTasks.length, totalTasks.length)"
        [bufferValue]="getPercentValue(completeTasks.length + overdueTasks.length, totalTasks.length)"
      >
      </mat-progress-bar>
    </div>
    <!-- <div class="col-auto pr-3 text-right" style="width:45px;">
      <button *ngIf="!dashboardService.isProjectExpanded(project.id)" mat-icon-button class="dkblue" matTooltip="More"
        matTooltipPosition="above" [matTooltipShowDelay]="300">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button *ngIf="dashboardService.isProjectExpanded(project.id)" mat-icon-button class="dkblue" matTooltip="More"
        matTooltipPosition="above" [matTooltipShowDelay]="300">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
    </div> -->
  </div>
</div>
<!-- <div *ngIf="dashboardService.isProjectExpanded(project.id)">
  <app-dashboard-task *ngFor="let task of tasks" [task]="task" [showProject]="false"></app-dashboard-task>
  <span *ngIf="tasks.length === 0">No Tasks for this Project</span>
</div> -->
