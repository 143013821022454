import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivityType, EventType, TaskAccessoryType } from 'src/app/enums';
import {
  AuthService,
  DisplayReviewersService,
  MessagingSystemService,
  ModalService,
  ProjectTaskService,
  UserService,
} from 'src/app/services';
import { TaskActivity } from 'src/app/types';

@Component({
  selector: 'app-task-view-activity',
  templateUrl: './task-view-activity.component.html',
  styleUrls: ['./task-view-activity.component.scss'],
})
export class TaskViewActivityComponent implements OnInit {
  @Input() public activityData: TaskActivity;
  @Input() public projectId: number;
  @Input() public taskType: TaskAccessoryType;

  isTrusted: boolean;

  get activityType() {
    return ActivityType;
  }

  get eventType() {
    return EventType;
  }

  get isApprovalEventActivity() {
    return [
      EventType.APPROVED,
      EventType.REJECTED,
      EventType.AUDITED,
      EventType.FLAGGED,
      EventType.CONTRACTSIGNED,
      EventType.REVISIONREQUESTED,
      EventType.NOTEMARKING,
      EventType.REVISEANDRESUBMIT,
      EventType.NOEXCEPTIONSTAKEN,
    ].includes(this.activityData?.eventType);
  }

  get isApprovedEventActivity() {
    return [EventType.APPROVED, EventType.AUDITED, EventType.NOEXCEPTIONSTAKEN].includes(this.activityData?.eventType);
  }

  get isApprovalContract() {
    return [EventType.CONTRACTSIGNED].includes(this.activityData?.eventType);
  }

  get isNoteMarking() {
    return [EventType.NOTEMARKING].includes(this.activityData?.eventType);
  }

  get isRejectedEventActivity() {
    return [EventType.REJECTED, EventType.FLAGGED, EventType.REVISIONREQUESTED, EventType.REVISEANDRESUBMIT].includes(
      this.activityData?.eventType
    );
  }
  approvalComment: string;
  public eventText: any;
  public collapseDetailView = true;
  public showCollapseDetailViewButton = false;

  constructor(
    private authService: AuthService,
    public displayReviewersService: DisplayReviewersService,
    private userService: UserService,
    private modalService: ModalService,
    private messagingService: MessagingSystemService,
    private taskService: ProjectTaskService
  ) {}

  ngOnInit() {
    this.isTrusted = this.isApprovalEventActivity;
    this.eventText = this.getEventText();
  }

  get isEngineer(): boolean {
    return (
      !!this.authService.currentUser?.scopes?.project &&
      this.authService.currentUser?.scopes?.project[this.projectId]?.includes(17)
    );
  }

  get isStaff() {
    return this.authService.isStaffOnAnyModule;
  }

  get canViewActivity() {
    return !this.activityData?.staffOnly || this.isStaff || this.isEngineer;
  }

  public getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  // public openActivityConversation() {
  //   this.messagingService.selectConversationById(this.activityData.id);
  // }

  public getNoteDateFormatText(dateTimeMoment: moment.Moment) {
    // Jul 31 • 3:23 PM
    return dateTimeMoment.format('MMM DD yyyy • h:mm a');
  }

  public getEventIcon(): string {
    switch (this.activityData?.eventType) {
      case EventType.FOLLOW:
        return `group_add`;
      case EventType.UNFOLLOW:
        return `person`;
      default:
        return 'error';
    }
  }

  public getEventText(): any {
    switch (this.activityData?.eventType) {
      case EventType.FOLLOW:
        // return `${this.activityData.related_user_first_name} ${this.activityData.related_user_last_name} followed this task`;
        return `followed this task`;
      case EventType.UNFOLLOW:
        // return `${this.activityData.related_user_first_name} ${this.activityData.related_user_last_name} stopped following this task`;
        return `unfollowed this task`;
      case EventType.APPROVED:
        // return `${this.activityData.related_user_first_name} ${this.activityData.related_user_last_name} stopped following this task`;
        return `Approved`;
      case EventType.REJECTED:
        // return `${this.activityData.related_user_first_name} ${this.activityData.related_user_last_name} stopped following this task`;
        return 'Rejected';
      case EventType.AUDITED:
        return `Audited`;
      case EventType.FLAGGED:
        return `Flagged`;
      case EventType.CONTRACTSIGNED:
        return `Signed a Document`;
      case EventType.REVISIONREQUESTED:
        return `Requested a Revision`;
      case EventType.NOEXCEPTIONSTAKEN:
        return `No Exception Taken`;
      case EventType.REVISEANDRESUBMIT:
        return `Revise and Resubmit`;
      case EventType.NOTEMARKING:
        return `Note Marking`;
      case EventType.COMPLETED:
        return [`marked this task`, `Complete`];
      case EventType.INCOMPLETE:
        return `marked this task To-Do`;
      case EventType.ONHOLD:
        return [`placed this task`, `On Hold`];
      case EventType.OFFHOLD:
        return `removed the hold from this task`;
      case EventType.NOTIFICATION:
        return `task is awaiting approval`;
      default:
        return 'unregistered event type';
    }
  }

  public getApprovalComment(): string {
    if (!this.approvalComment) {
      if (!this.taskService.currentSelectedTask || !this.taskService.currentSelectedTask.file_approvals) {
        return '';
      }
      const approvals = this.taskService.currentSelectedTask.file_approvals;
      const activityApprovals = approvals.filter((approval) => +approval.id === +this.activityData?.parent_id);
      let items;
      if (activityApprovals && activityApprovals.length > 0) {
        items = activityApprovals[0].approval_items;
      }
      if (!items) {
        return '';
      }
      const item = items.filter((i) => +i.id === +this.activityData?.related_id)[0];
      this.approvalComment = item.comment;
    }
    return this.approvalComment;
  }

  getEventActivityUserName() {
    const currentEvent = this.activityData?.eventType;
    if ((currentEvent <= 1 && currentEvent >= 0) || currentEvent === 8) {
      return this.activityData?.related_first_name + ' ' + this.activityData?.related_last_name;
    } else if (currentEvent >= 2) {
      return this.activityData?.created_by_first_name + ' ' + this.activityData?.created_by_last_name;
    } else {
      return 'A User';
    }
  }

  openUserProfileDialog(userId) {
    this.modalService.openUserProfileDialog(userId).toPromise();
  }
}
