<div id="request-topic" data-test-id="request-topic" class="request-topic">
  <span class="d-flex">
    <h6
      *ngIf="showTitle"
      [ngClass]="{
        red: topics?.errors
      }"
      class="dkblue font-weight-semibold mb-2 mr-auto"
    >
      Topic*
    </h6>
  </span>
  <mat-form-field appearance="outline" class="full-width relative-button-clear-parent">
    <input
      type="text"
      placeholder="Search Topics"
      aria-label="Topic"
      matInput
      [formControl]="topics"
      [matAutocomplete]="topic_auto"
    />
    <mat-icon *ngIf="topics?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-error>
      {{
        (filteredTopics | async)?.length === 0 ? 'There are no topics available' : 'A topic is required to create item'
      }}
    </mat-error>
    <mat-autocomplete #topic_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="topicMapper.bind(this)">
      <mat-option *ngIf="(filteredTopics | async)?.length === 0" disabled>
        <span class="flex-column">
          <p class="dkblue mb-0">No Topic Available</p>
        </span>
      </mat-option>
      <mat-option *ngFor="let topic of filteredTopics | async" [value]="topic" [disabled]="!topic.isVisibleToDispatch">
        <span class="d-flex flex-wrap align-items-center">
          <span class="mr-auto">{{ topic?.name }}</span>
          <span
            class="badge badge-secondary br-5 font-weight-semibold text-uppercase text-truncate"
            *ngIf="topic.isDuplicate"
          >
            {{ topic?.topic_category?.name }}
          </span>
          <span
            class="badge badge-light gray br-5 font-weight-semibold text-uppercase text-truncate"
            *ngIf="!topic.isVisibleToDispatch"
          >
            Unavailable
          </span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
