import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import PerfectScrollbar from 'perfect-scrollbar';

import * as moment from 'moment';

import { Bid, BidPackage, ProjectConstruction } from 'src/app/workspaces/construction/types';
import {
  AuthService,
  FileService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
} from 'src/app/services';
import { ResourceType, TaskReviewStatus } from 'src/app/enums';
import { Company, User } from 'src/app/types';

@Component({
  selector: 'app-project-schedule-contract-dialog',
  templateUrl: './project-schedule-contract-dialog.component.html',
  styleUrls: ['./project-schedule-contract-dialog.component.scss'],
})
export class ProjectScheduleContractDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ProjectScheduleContractDialogComponent>,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private meetingService: MeetingService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  @ViewChild('pdf', { static: true }) pdf;

  private projectFields = [
    `code`,
    `title`,
    `scope_of_work`,
    `building`,
    `floor`,
    `suite`,
    `department`,
    `project_manager{first_name,last_name,email,company{name}}`,
    `cfmo{first_name,last_name,title}`,
    `project_schedule_file_id`,
    `bid_details{construction_start_date,construction_end_date}`,
    `bid_packages{trade{name},awarded_bid{company{name}}}`,
  ];

  private bidFields = [
    'timeline_revision',
    `bid_package_id`,
    `bid_package{number,trade{name}}`,
    `project{${this.projectFields.join(',')}}`,
    `trade{name}`,
    `contact{first_name,last_name,email,title}`,
    `company{name}`,
    `amount`,
    `description`,
    `contract_revision`,
    'contract_task_id',
    'contract_task{status_id,accessory_data}',
    'timeline_vendor_signature_text',
    'timeline_vendor_signed_datetime',
  ];

  currentUserId: number;
  bid: Bid;
  loading = true;
  timelineTrades: BidPackage[];
  get project(): ProjectConstruction {
    return this.bid?.project;
  }
  get projectManager(): User {
    return this.project?.project_manager;
  }
  get bidPackage(): BidPackage {
    return this.bid?.bid_package;
  }
  get bidCompany(): Company {
    return this.bid?.company;
  }
  get bidContact(): User {
    return this.bid?.contact;
  }

  get TaskReviewStatus() {
    return TaskReviewStatus;
  }
  async ngOnInit() {
    const main = document.querySelector('#main');
    const ps = new PerfectScrollbar(main);
    this.currentUserId = this.authService.getLoggedInUser().id;
    const accessoryData = this.data?.task?.accessory_data ? JSON.parse(this.data?.task?.accessory_data) : null;
    let bid;
    if (accessoryData?.parentId) {
      bid = await this.projectService.getBidById(accessoryData?.parentId, this.bidFields).toPromise();
    }
    if (!bid?.id) {
      const errorSnack = this.snackbar.open('Bid for this contract could not be found', 'Close Contract', {
        duration: undefined,
      });
      return;
    }
    // change this if we ever allow the schedule dialog to be viewed at a time other than awaiting vendor signature
    bid.timeline_vendor_signed_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    const bidMeetings = await this.projectService.getTimelineMeetings(bid?.id).toPromise();
    bid.pre_construction_meeting = bidMeetings[0] ?? null;
    this.bid = bid;
    this.timelineTrades = await this.projectService.getTimelineTrades(this.bid?.id).toPromise();

    this.loading = false;
  }

  async exportContract() {
    return await this.pdf.contractExport();
  }
  async createTimelinePdf() {
    const createdPDF = await this.pdf.createPdfFile(this.project, this.bid.timeline_revision);
    return await this.fileService
      .createFile(createdPDF, this.project?.id, ResourceType.Project, createdPDF.name)
      .toPromise();
  }

  public requestRevision() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Request Revision',
        headerText: 'Request Revision',
        descriptionText: 'Please provide a short description for what needs to be revised.',
        confirmationButtonText: 'Request Revision',
        userInput: {
          placeholder: 'What needs to be revised?',
          required: true,
        },
        allowedFilesCount: 10,
        filesParentId: this.project.id,
        filesParentResourceType: ResourceType.Project,
      })
      .subscribe(({ res, newFiles }) => {
        if (res) {
          this.submitApproval(TaskReviewStatus.Rejected, res, newFiles);
        }
      });
  }
  public async submitApproval(approval: TaskReviewStatus, comment = '', commentFiles = []) {
    const reviewFiles = [];
    if (approval === TaskReviewStatus.Approved) {
      if (this.currentUserId !== this.bidContact?.id) {
        this.snackbar.open('Only the bid contact is allowed to sign the contract');
        return;
      } else if (!this.bid?.timeline_vendor_signature_text) {
        this.snackbar.open('Please enter your name to sign the contract');
        return;
      }
      const isConfirmed = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Sign Contract',
          headerText: 'Accept & Sign Contract',
          descriptionText:
            'By selecting the “I Accept” or “Approve” button, you agree to be bound by any digital documents, to include but not limited to, construction contracts, change orders, service agreements or lease agreements created, managed and stored by 1CALL Cloud. <br><br>Are you sure you want to continue? This cannot be undone.',
          confirmationButtonText: 'I Accept',
        })
        .toPromise();
      if (isConfirmed) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating Contract...');
        await this.projectService
          .signProjectScheduleAsVendor(this.bid?.id, {
            timeline_vendor_signature_text: this.bid?.timeline_vendor_signature_text,
          })
          .toPromise();
        // This is where the file pdf needs to be created and then added to files
        reviewFiles.push(await this.createTimelinePdf());
        this.progressIndicatorService.close();
      }
    }
    this.dialogRef.close({
      approval,
      comment,
      commentFiles,
      reviewFiles,
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
