import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatus } from 'src/app/enums';
@Pipe({
  name: 'taskLists',
})
export class TaskListsPipe implements PipeTransform {
  transform(items: any[], filters: any, filteredCount: any, dueDateCounts): any[] {
    if (!items) {
      return [];
    }

    const result = items.filter((it) => {
      return (
        it.assigned_user_id !== null &&
        it.status_id !== TaskStatus.Complete &&
        it.due_date !== null &&
        it.key_controls?.length === 0 &&
        (filters?.assigned_user_ids?.length > 0 ? filters.assigned_user_ids.indexOf(it.assigned_user_id) >= 0 : true) &&
        (filters?.workspace_ids?.length > 0 ? filters.workspace_ids.indexOf(it.module_id) >= 0 : true) &&
        (filters?.is_review_task
          ? filters.is_review_task === 'review'
            ? it.isReviewItem === 'review'
            : filters.is_review_task === 'action'
            ? it.isReviewItem === 'action'
            : false
          : true) &&
        this.search(it, filters.searchText) &&
        (filters?.following ? JSON.parse(it.follower_ids || []).indexOf(filters.currentUserID) >= 0 : true) &&
        (filters?.assignedToCurrentUser ? it.assigned_user_id === filters.currentUserID : true)
      );
    });
    const now = new Date();
    const finalResult = result.filter((it) => {
      return this.filterByDueDates(it.due_date, now, filters?.daysDue);
    });

    if (dueDateCounts) {
      dueDateCounts.all_tasks = result.length;
      dueDateCounts.due_today = 0;
      dueDateCounts.due_this_week = 0;
      dueDateCounts.due_this_month = 0;
      dueDateCounts.overdue = 0;
      result.forEach((it) => {
        this.filterByDueDates(it.due_date, now, 1) === true ? dueDateCounts.due_today++ : null;
        this.filterByDueDates(it.due_date, now, 7) === true ? dueDateCounts.due_this_week++ : null;
        this.filterByDueDates(it.due_date, now, 30) === true ? dueDateCounts.due_this_month++ : null;
        this.filterByDueDates(it.due_date, now, 0) === true ? dueDateCounts.overdue++ : null;
      });
    }

    filteredCount.count = finalResult.length;
    return finalResult;
  }

  filterByDueDates(dueDate, now, daysDue) {
    const due_date = new Date(dueDate).setHours(0, 0, 0, 0);
    const startDate = new Date(now).setHours(0, 0, 0, 0);
    const endDate = new Date(now).setDate(now.getDate() + daysDue);
    const due =
      daysDue === 0 ? due_date < startDate : due_date >= startDate && due_date < new Date(endDate).setHours(0, 0, 0, 0);
    return daysDue === -1 ? true : due;
  }

  search(it, searchText) {
    if (!searchText) return true;
    searchText = searchText.toLowerCase();
    return (
      it.title.toLowerCase().includes(searchText) ||
      searchText == it.id ||
      it.project?.code.toLowerCase().includes(searchText) ||
      it.project?.title?.toLowerCase().includes(searchText) ||
      it.project?.building?.code.toLowerCase() == searchText ||
      it.project?.building?.name.toLowerCase().includes(searchText) ||
      it.arf?.title.toLowerCase().includes(searchText) ||
      it.arf?.code.toLowerCase() == searchText
    );
  }
}
