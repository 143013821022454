<div class="progress-indicator project-modal text-center">
  <mat-progress-spinner
    class="m-auto"
    color="accent"
    [mode]="mode || 'indeterminate'"
    [value]="progressValue"
  ></mat-progress-spinner>
  <h5 class="m-0 ltblue font-weight-semibold mt-5">
    {{ status }}
  </h5>
</div>
