import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy, sortBy, uniqBy } from 'lodash';
import { UserSelectModalComponent } from 'src/app/components';
import { ResourceType, TopicType, UserType, Workspace } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LocationService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  RequestService,
  TopicService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import {
  APIFilter,
  Building,
  Department,
  Floor,
  Request,
  Suite,
  Topic,
  TopicCategory,
  TopicGroup,
  User,
  WorkOrder,
} from 'src/app/types';
import { BidPackage } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-quick-request',
  templateUrl: './quick-request.component.html',
  styleUrls: ['./quick-request.component.scss'],
})
export class QuickRequestComponent implements OnInit {
  allTopicGroups: TopicGroup[] = [];
  allTopicCategories: TopicCategory[] = [];
  filteredTopicCategories: TopicCategory[] = [];
  allTopics: Topic[] = [];
  allDepartments: Department[] = [];
  filteredTopics: Topic[] = [];
  filteredDepartments: Department[] = [];
  selectedGroup: TopicGroup;
  selectedCategory: TopicCategory;
  selectedTopic: Topic;
  topicSearchTerm: any;
  departmentSearchTerm: any;
  isStaff: boolean;
  isAR: boolean;
  isOUPD: boolean;
  buildings: Building[] = [];
  floors: Floor[] = [];
  departments: Department[] = [];
  currentUser: User;
  attachedFiles: any[] = [];
  building: Building;
  allBuildings: Building[] = [];
  floor: Floor;
  suite: Suite;
  department: Department;
  floorsBuildingIdFilter: number;
  departmentsFloorIdFilter: number;
  contacts: User[] = [];
  shortDescription: string;
  comments: string;
  linkedBidPackage: BidPackage;
  departmentFields = [
    'id',
    'name',
    'is_enabled',
    'building_id',
    'building_name',
    'building_is_enabled',
    'floor_id',
    'floor_code',
    'floor_sequence',
    'floor_is_enabled',
    'suite_id',
    'suite_name',
    'suite_is_enabled',
    'suite_occupancy_is_enabled',
  ];

  step = 1;

  constructor(
    private topicService: TopicService,
    private authService: AuthService,
    private userService: UserService,
    private locationService: LocationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private requestService: RequestService,
    private fileService: FileService,
    private workOrderService: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading..');
    const departmentFilters: APIFilter[] = [
      { type: 'field', field: 'building_is_enabled', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'floor_is_enabled', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'suite_is_enabled', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: '1' },
    ];
    const allDepartments = await this.locationService
      .getDepartments(this.departmentFields, departmentFilters)
      .toPromise();
    for (const d of allDepartments) {
      if (!d.building_id) {
        d.building_name = 'No Building';
      }
    }
    this.allDepartments = sortBy(allDepartments, (d) => [
      !d.building_id,
      d.building_name,
      d.floor_sequence,
      d.suite_name,
      d.name,
    ]);
    this.filteredDepartments = this.allDepartments;

    this.currentUser = await this.userService
      .getUserById(this.authService.getLoggedInUser().id, [
        'first_name',
        'last_name',
        'building{id,name}',
        'floor{id,name}',
        'suite{id,name}',
        'department{id,name}',
      ])
      .toPromise();
    await this.populateLocationData();

    if (this.route?.snapshot?.queryParams?.linkedBidPackageId) {
      const bidPackageFields = [
        'id',
        'child_request{id}',
        'project{id,code,building{id,code},floor{id,code},suite{id},department{id},title}',
      ];
      try {
        const linkedBidPackage = await this.projectService
          .getBidPackageById(this.route?.snapshot?.queryParams?.linkedBidPackageId, bidPackageFields)
          .toPromise();

        if (linkedBidPackage.child_request?.id) {
          this.snackbar.open(`This bid package is already linked to a request`);
        } else {
          this.linkedBidPackage = linkedBidPackage;

          if (this.linkedBidPackage?.project?.building?.id) {
            const foundBuilding = this.buildings.find((b) => b.id === this.linkedBidPackage?.project?.building?.id);
            if (foundBuilding) {
              await this.selectBuilding(foundBuilding);
            }
          }
          if (this.linkedBidPackage?.project?.floor?.id) {
            const foundFloor = this.floors.find((f) => f.id === this.linkedBidPackage?.project?.floor?.id);
            if (foundFloor) {
              this.floor = foundFloor;
              await this.selectFloor(foundFloor);
            }
          }
          if (this.linkedBidPackage?.project?.department?.id) {
            const foundDepartment = this.filteredDepartments.find(
              (d) => d.id === this.linkedBidPackage?.project?.department?.id
            );
            if (foundDepartment) {
              this.selectDepartment(foundDepartment);
            }
          }
        }
      } catch (error) {
        this.snackbar.open(`Unable to link a new request to that bid package`);
      }
    }
    this.isStaff = this.authService.isStaffOnAnyModule;
    this.isAR = this.authService.isAR;
    this.isOUPD = this.authService.isOUPD;
    const visibleTo = ['null'];
    if (this.isStaff) {
      visibleTo.push('1');
    }
    if (this.isAR) {
      visibleTo.push('2');
    }
    if (this.isOUPD) {
      visibleTo.push('4');
    }
    const topicFilter: APIFilter[] = [
      { type: 'field', field: 'visible_to', value: visibleTo.join('^') },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'form_template_id', value: null },
    ];
    if (this.linkedBidPackage) {
      topicFilter.push({ type: 'operator', value: 'AND' });
      topicFilter.push({
        type: 'field',
        field: 'topic_type_id',
        value: TopicType.Request.toString(),
      });
    }

    const allTopics = await this.topicService
      .getTopics(this.topicService.quickRequestTopicFields, topicFilter)
      .toPromise();
    this.allTopics = sortBy(allTopics, (t) => [t.topic_category?.topic_group?.name, t.topic_category?.name, t.name]);
    for (const t of allTopics) {
      if (t.visible_to) {
        t.visible_to = JSON.parse(t.visible_to);
      }
      if (t.selectable_by) {
        t.selectable_by = JSON.parse(t.selectable_by);
        if (t.selectable_by?.length > 0 && t.selectable_by?.indexOf(2) > -1) {
          t.requiresAR = true;
        }
      }
    }
    this.filteredTopics = this.allTopics;
    this.allTopicCategories = sortBy(
      uniqBy(
        this.allTopics.map((t) => t.topic_category),
        'id'
      ),
      'name'
    );
    for (const c of this.allTopicCategories) {
      if (!this.allTopics.find((t) => !t.requiresAR && t.topic_category?.id === c.id)) {
        c.requiresAR = true;
      }
    }
    this.allTopicGroups = sortBy(
      uniqBy(
        this.allTopicCategories.map((c) => c.topic_group),
        'id'
      ),
      'name'
    );
    for (const g of this.allTopicGroups) {
      if (!this.allTopicCategories.find((c) => !c.requiresAR && c.topic_group?.id === g.id)) {
        g.requiresAR = true;
      }
    }
    this.progressIndicatorService.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  selectGroup(group: TopicGroup, fromCategory = false) {
    if (group?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    if (!fromCategory && this.selectedGroup?.id !== group?.id) {
      this.selectCategory(null);
    }
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.filteredTopicCategories = this.allTopicCategories.filter((c) => c.topic_group_id === this.selectedGroup.id);
      if (this.selectedGroup.building_ids) {
        const buildingIds = JSON.parse(this.selectedGroup.building_ids);
        this.buildings = this.allBuildings.filter((b) => buildingIds.indexOf(b.id) > -1);
      } else {
        this.buildings = this.allBuildings;
      }
      if (!this.buildings.find((b) => b.id === this.building?.id)) {
        this.selectBuilding(null);
      }
    } else {
      this.filteredTopics = this.allTopics;
      this.selectCategory(null);
      this.selectTopic(null);
      this.selectBuilding(null);
    }
  }

  selectCategory(category: TopicCategory, fromTopic = false) {
    if (category?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    if (!fromTopic && this.selectedCategory?.id !== category?.id) {
      this.selectTopic(null);
    }
    this.selectedCategory = category;
    if (this.selectedCategory) {
      this.filteredTopics = this.allTopics.filter((t) => t.topic_category_id === this.selectedCategory.id);
      if (this.selectedCategory?.topic_group) {
        this.selectGroup(this.selectedCategory.topic_group, true);
      }
    } else {
      this.selectTopic(null);
    }
  }

  selectTopic(topic: Topic) {
    if (topic?.requiresAR && !this.isAR && !this.isStaff) {
      return;
    }
    if (topic?.id === 46) {
      this.router.navigate([`/construction-request`, { requestTypeId: 1 }]);
    } else if (topic?.id === 47) {
      this.router.navigate([`/construction-request`, { requestTypeId: 2 }]);
    } else if (topic?.workspace_id === Workspace.Construction) {
      this.router.navigate([`/construction-request`]);
    }
    this.selectedTopic = topic;
    if (this.selectedTopic?.topic_category) {
      this.selectCategory(this.selectedTopic.topic_category, true);
    }
  }

  async selectBuilding(building: Building) {
    if (building?.id !== this.building?.id) {
      this.floor = null;
      this.suite = null;
      this.department = null;
      this.departmentSearchTerm = '';
    }
    this.building = building;
    await this.populateLocationData();
  }

  async selectFloor(floor: Floor) {
    if (floor?.id !== this.floor?.id) {
      this.suite = null;
      this.department = null;
      this.departmentSearchTerm = '';
    }
    this.floor = floor;
    await this.populateLocationData();
  }

  async selectDepartment(department: Department) {
    this.department = department;
    this.building = { id: department?.building_id, name: department?.building_name };
    this.floor = {
      id: department?.floor_id,
      name: department?.floor_name,
      code: department?.floor_code,
    };
    this.suite = { id: department?.suite_id, name: department?.suite_name };
  }

  async populateLocationData() {
    if (!this.allBuildings?.length) {
      const buildingFilters: APIFilter[] = [{ type: 'field', field: 'is_enabled', value: '1' }];
      this.allBuildings = await this.locationService.getBuildings(['id', 'name', 'code'], buildingFilters).toPromise();
    }
    if (this.selectedGroup?.building_ids) {
      const buildingIDs = JSON.parse(this.selectedGroup.building_ids);
      this.buildings = this.allBuildings.filter((b) => buildingIDs.indexOf(b.id) > -1);
    } else {
      this.buildings = this.allBuildings;
    }
    if (!this.building?.id) {
      [this.floors, this.departments] = [[{ id: null, name: null }], [{ id: null, name: null }]];
    } else {
      if (this.building?.id !== this.floorsBuildingIdFilter) {
        const floorFilters: APIFilter[] = [
          { type: 'field', field: 'building_id', value: this.building.id.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ];
        // this.departmentsFloorIdFilter = null;
        this.floors = await this.locationService.getFloors(['id', 'name', 'code'], floorFilters).toPromise();
        this.floorsBuildingIdFilter = this.building.id;
      }
      if (!this.floor?.id) {
        this.departments = [{ id: null, name: null }];
      } else if (this.floor?.id !== this.departmentsFloorIdFilter) {
        const departmentFilters: APIFilter[] = [
          { type: 'field', field: 'floor_id', value: this.floor.id.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'suite_is_enabled', value: '1' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ];
        this.departments = orderBy(
          await this.locationService.getDepartments(this.departmentFields, departmentFilters).toPromise(),
          ['suite_name', 'name'],
          'asc'
        );
        this.departmentsFloorIdFilter = this.floor.id;
      }
    }
  }

  filterDepartments() {
    if (this.departmentSearchTerm) {
      if (this.departmentSearchTerm.id) {
        this.departmentSearchTerm = this.departmentSearchTerm.name;
      } else {
        this.filteredDepartments = this.allDepartments.filter((d) =>
          (d.name || '').toLowerCase().includes(this.departmentSearchTerm.toLowerCase())
        );
      }
    } else {
      this.filteredDepartments = this.allDepartments;
    }
  }

  filterTopics() {
    if (this.topicSearchTerm) {
      if (this.topicSearchTerm.id) {
        this.topicSearchTerm = this.topicSearchTerm.name;
      } else {
        this.filteredTopics = this.allTopics.filter(
          (t) =>
            (t.name || '').toLowerCase().includes(this.topicSearchTerm.toLowerCase()) ||
            (t.topic_category?.name || '').toLowerCase().includes(this.topicSearchTerm.toLowerCase())
        );
      }
    } else {
      this.filteredTopics = this.allTopics;
    }
  }

  public async openUploadModal() {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this.attachedFiles = [...this.attachedFiles, ...files.computerFiles];
    }
  }

  public removeAttachedFile(file) {
    this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
  }

  openBulkSelectModal() {
    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Add Followers',
          createUser: { title: 'Guest', guestUser: false },
          includeGuestUsers: true,
          allowedUserTypeIds: [UserType.Staff, UserType.Tenant],
          preSelectedUsers: [this.currentUser, ...this.contacts],
          excludeVendors: true,
          defaultUserTypeId: UserType.Everyone,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (deSelectedUsers?.length) {
          for (const deSelectedUser of deSelectedUsers) {
            this.removeContact(deSelectedUser);
          }
        }

        if (selectedUsers?.length) {
          for (const selectedUser of selectedUsers) {
            this.addContact(selectedUser);
          }
        }
      });
  }

  addContact(user) {
    const foundContact = this.contacts?.find((c) => c.id === user.id);
    if (foundContact || user.id === this.currentUser.id) {
      this.snackbar.open(`One or more users have already been added`);
    } else {
      // pure
      this.contacts = [
        ...this.contacts,
        {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
      ];
    }
  }

  removeContact(contact) {
    this.contacts = this.contacts?.filter((c) => c.id !== contact.id);
  }

  get isRequestValid() {
    return (
      this.currentUser &&
      this.selectedTopic &&
      this.building?.id &&
      this.floor?.id &&
      this.shortDescription &&
      this.comments
    );
  }

  private _resetFields() {
    this.attachedFiles = [];
    this.building = undefined;
    this.comments = undefined;
    this.contacts = [];
    this.department = undefined;
    this.departmentsFloorIdFilter = undefined;
    this.filteredTopicCategories = [];
    this.floor = undefined;
    this.floorsBuildingIdFilter = undefined;
    this.selectedCategory = undefined;
    this.selectedGroup = undefined;
    this.selectedTopic = undefined;
    this.shortDescription = undefined;
    this.step = 1;
    this.suite = undefined;
  }

  async submitRequest() {
    // TODO: form_submission
    // TODO: should we add landmark?
    if (this.isRequestValid) {
      try {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Submitting..');
        if (this.selectedTopic.topic_type_id === TopicType.Request) {
          const request: Request = {
            requester_id: this.currentUser.id,
            module_id: this.selectedTopic.workspace_id,
            building_id: this.building?.id,
            floor_id: this.floor?.id,
            suite_id: this.suite?.id,
            department_id: this.department?.id,
            // landmark: this.landmark,
            short_description: this.shortDescription,
            summary: this.comments,
            contact_ids: JSON.stringify(this.contacts.map((c) => c.id)),
            topic_id: this.selectedTopic.id,
          };
          if (this.linkedBidPackage) {
            request.parent_bid_package_id = this.linkedBidPackage.id;
          }
          const createdRequest = await this.requestService.createRequest(request).toPromise();

          for (const file of this.attachedFiles) {
            await this.fileService.createFile(file, createdRequest.id, ResourceType.Request).toPromise();
          }

          const requestData = {
            request: createdRequest,
            files: this.attachedFiles,
            contacts: this.contacts,
          };
          this.modalService.openRequestReceiptDialog(requestData).subscribe(() => this._resetFields());
        } else if (this.selectedTopic.topic_type_id === TopicType.WorkOrder) {
          const workOrder: WorkOrder = {
            module_id: this.selectedTopic.workspace_id,
            building_id: this.building?.id,
            floor_id: this.floor?.id,
            suite_id: this.suite?.id,
            department_id: this.department?.id,
            // landmark: this.landmark,
            title: this.shortDescription,
            summary: this.comments,
            follower_ids: JSON.stringify(this.contacts.map((c) => c.id)),
            topic_id: this.selectedTopic.id,
            requester_id: this.currentUser.id,
            // form_submission: this.selectedTopic.form
            //   ? JSON.stringify({
            //       form_template_id: this.selectedTopic.form.id,
            //       elements: this.topicFormElements.map((e) => ({ id: e.id, value: e.value })),
            //     })
            //   : null,
          };
          const createdWorkOrder = await this.workOrderService.createWorkOrder(workOrder).toPromise();

          for (const file of this.attachedFiles) {
            await this.fileService.createFile(file, createdWorkOrder.id, ResourceType.WorkOrder).toPromise();
          }

          const workOrderData = {
            workOrder: createdWorkOrder,
            files: this.attachedFiles,
            contacts: this.contacts,
          };

          this.modalService.openRequestReceiptDialog(workOrderData).subscribe(() => this._resetFields());
        }
        this.progressIndicatorService.close();
      } catch (e) {
        this.progressIndicatorService.close();
        throw e;
      }
    } else {
      this.snackbar.open('Please fill out required fields');
    }
  }
}
