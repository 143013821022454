<!-- VIEW MODE -->
<div id="drawer-container" class="d-flex flex-column" *ngIf="section && !isEditing">
  <section class="d-flex align-items-center mb-1">
    <h4 class="dkblue mb-0 mr-auto text-truncate mr-2">
      {{ section.name }}
    </h4>
    <button mat-icon-button color="primary" type="button" (click)="close()" class="gray">
      <mat-icon>close</mat-icon>
    </button>
  </section>
  <section class="d-flex align-items-center mb-5">
    <p
      class="border border-gray bg-shade-gray px-2 br-10 lh-20 dkblue mr-2 mb-0"
      *ngFor="let fs of section.funding_sources"
    >
      ${{ fs.abbreviation }}
    </p>
  </section>
  <section class="d-flex align-items-center mb-2 br-10 border border-gray p-3">
    <div class="d-flex flex-column mr-auto">
      <h6 class="primary mb-0">{{ section.name }}</h6>
      <p class="dkgray mb-0">{{ section.representative_first_name }} {{ section.representative_last_name }}</p>
    </div>
    <button
      mat-flat-button
      color="accent"
      type="button"
      *ngIf="section.needs_tenant_approval && section?.cb_approval_process?.tenantCanStart"
      (click)="submitForReview()"
    >
      Submit For Review
    </button>
    <button
      mat-flat-button
      color="accent"
      type="button"
      *ngIf="
        !section?.cb_approval_process?.tenantCanStart &&
        (!!section?.cb_tenant_approval_task_id || !!section?.saved_cb_tenant_approval_task_id)
      "
      (click)="openReviewTask()"
    >
      Tenant Review
    </button>
  </section>
  <section class="d-flex flex-column border-bottom border-ltgray py-3">
    <div class="d-flex align-items-top py-1">
      <p class="primary mb-0 mr-auto text-truncate">Occupied SF</p>
      <p class="dkgray mb-0 ml-4">{{ section.occupied_sqft }} sqft</p>
    </div>
    <!-- <div class="d-flex align-items-top py-1">
        <p class="primary mb-0 mr-auto text-truncate">Rental Rate</p>
        <p class="dkgray mb-0 ml-4">$25.00 / $120,000.00/yr</p>
      </div> -->
  </section>
  <section class="d-flex flex-column border-bottom border-ltgray py-3">
    <div class="d-flex align-items-top py-1" *ngFor="let fs of section.funding_sources">
      <p class="primary mb-0 mr-auto text-truncate">{{ fs.name }}</p>
      <p class="dkgray mb-0 ml-4">{{ fs.subtotal | USDollar }}</p>
    </div>
  </section>
  <section class="d-flex flex-column border-bottom border-ltgray py-3 mb-3">
    <div class="d-flex align-items-top py-1">
      <p class="primary font-weight-semibold mb-0 mr-auto text-truncate">Section Total</p>
      <p class="primary font-weight-semibold mb-0 ml-4">{{ section.subtotal | USDollar }}</p>
    </div>
  </section>
  <section class="d-flex border-bottom border-ltgray align-items-center py-3">
    <div class="d-flex flex-column mr-auto">
      <h5 class="primary mb-1">Required Documents</h5>
      <p class="dkgray mb-0">The documents listed below are required based on this section's funding sources.</p>
    </div>
  </section>
  <section class="d-flex align-items-center border-bottom border-ltgray py-3">
    <div class="d-flex flex-column mr-auto">
      <h6 class="primary mb-0">Cover Letter</h6>
      <p class="dkgray mb-0">Required.</p>
    </div>
    <button
      *ngIf="!section.cb_cover_letter_text"
      mat-flat-button
      color="primary"
      type="button"
      (click)="openCoverLetter()"
    >
      <span class="pulse-dot-success"></span>
      Create
    </button>
    <p *ngIf="section.cb_cover_letter_text" class="dkgray mb-0 mr-4">
      <i class="fas fa-check-circle green mr-1 me-1"> </i>
      Created
    </p>
    <button *ngIf="section.cb_cover_letter_text" mat-button color="accent" type="button" (click)="openCoverLetter()">
      Edit
    </button>
  </section>
  <section class="d-flex border-bottom border-ltgray py-3">
    <div class="d-flex flex-column flex-grow-1 mr-auto">
      <h6 class="primary mb-0">Construction Documents</h6>
      <!-- TODO: PEB | Adam - replace placeholder text -->
      <p class="dkgray mb-0">Required.</p>
    </div>
    <app-split-button
      *ngIf="canEdit"
      (clickEvent)="uploadConstructionDocument()"
      class="ml-2"
      lineHeight="24"
      [matMenu]="ConstructionDocumentsMenu"
    >
      Upload
    </app-split-button>
    <mat-menu #ConstructionDocumentsMenu="matMenu" xPosition="before">
      <button mat-menu-item *ngIf="!section?.construction_documents_task_id" (click)="createTask()">
        <mat-icon>note_add</mat-icon>
        <span>Request Construction Documents </span>
      </button>
      <button
        mat-menu-item
        *ngIf="section?.construction_documents_task_id"
        (click)="viewTask(section.construction_documents_task_id)"
      >
        <mat-icon>note_add</mat-icon>
        <span>View Task </span>
      </button>
    </mat-menu>
    <!-- <button mat-button class="ml-auto" color="accent" type="button" (click)="uploadBubbleDrawing()">Upload</button> -->
    <!-- TODO: PEB | Adam - uploading multiple files causes weird alignment -->
    <mat-chip-list *ngIf="section.construction_documents_ids.length" class="ml-4">
      <ng-container *ngFor="let file of section.construction_documents_ids; let last = last">
        <app-file-chip
          [file]="{ id: file }"
          [removable]="canEdit"
          (removeEvent)="removeConstructionDocument(file)"
          [ngClass]="{ 'mb-1': section.construction_documents_ids.length > 1 && !last }"
        ></app-file-chip>
      </ng-container>
    </mat-chip-list>
  </section>
</div>
