<div class="m-portlet">
  <div class="m-portlet__body p-4 align-items-center">
    <form [formGroup]="formGroup">
      <div class="row mb-4">
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Project *</h6>
          <app-resource-search
            [control]="formGroup.controls['project']"
            [resourceType]="3"
            [displayFn]="displayFn"
            [fields]="['id', 'code', 'title', 'building', 'floor', 'project_manager']"
            [filters]="projectsFilter"
          ></app-resource-search>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Project Manager *</h6>
          <app-resource-search
            [control]="formGroup.controls['manager']"
            [resourceType]="ResourceType.Users"
          ></app-resource-search>
        </div>
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Suppliers *</h6>
          <mat-form-field appearance="outline" class="form-element">
            <mat-select formControlName="suppliers" multiple placeholder="Suppliers">
              <mat-select-trigger>
                {{ formGroup.controls['suppliers'].value ? formGroup.controls['suppliers'].value[0] : '' }}
                <span *ngIf="formGroup.controls['suppliers'].value?.length > 1">
                  {{ formGroup.controls['suppliers'].value }}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let supplier of suppliers" [value]="supplier">{{ supplier }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">Start Date *</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              required="true"
              (click)="startDatePicker.open()"
              [matDatepicker]="startDatePicker"
              autocomplete="off"
              formControlName="startDate"
              placeholder="Start Date"
            />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col">
          <h6 class="m-0 dkblue font-weight-semibold mb-2">End Date *</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              required="true"
              (click)="endDatePicker.open()"
              [matDatepicker]="endDatePicker"
              autocomplete="off"
              formControlName="endDate"
              placeholder="End Date"
            />
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <span class="d-flex align-items-center fine-print">
            <mat-icon class="red">report</mat-icon>
            <p class="m-0 dkblue font-weight-normal">
              Please attach a copy of floor plans with required doors circled when you reach the Comments section.
            </p>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
