<div class="login-container container-fluid">
  <div class="row bg align-items-center">
    <div class="col login_left" [style.backgroundImage]="'url(../../assets/img/login_bg_' + imageNumber + '.jpg)'">
      <img id="main_logo" src="../assets/img/login_logo.svg" />
    </div>
    <div class="col-xl-6 col-lg-8 col-md-9 col-sm-12">
      <div class="row pt-5 pb-5">
        <div class="col-lg-8 col-md-10 col-sm-12 mx-auto login_right pt-5 mt-5">
          <div class="row">
            <div class="col">
              <h1 class="dkblue m-0">
                Teamwork is the best work. <br />
                We're here to help.
              </h1>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col">
              <h5 class="dkgray lh2 m-0 font-weight-normal">
                1Call strives to build and maintain the best facilities. Whether a quick fix or a new construction
                project, we’re here to help.
              </h5>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <form class="login-form" [formGroup]="loginFormGroup" (ngSubmit)="login()">
                <div class="row">
                  <div class="col">
                    <mat-form-field class="full-width mb-4" appearance="outline">
                      <input
                        id="email-input"
                        matInput
                        placeholder="Email Address"
                        formControlName="email"
                        type="text"
                        autofocus
                        data-test-id="email-input"
                      />
                      <mat-error *ngIf="email.errors?.required" data-test-id="login-required-email-error"
                        >Email is required</mat-error
                      >
                      <mat-error
                        *ngIf="!email.errors?.required && email.errors?.email"
                        data-test-id="login-invalid-email-error"
                        >Email address is invalid</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field class="full-width mb-3" appearance="outline">
                      <input
                        placeholder="Password"
                        id="password-input"
                        matInput
                        type="password"
                        formControlName="password"
                        data-test-id="password-input"
                      />
                      <mat-error *ngIf="password.errors?.required" data-test-id="login-required-password-error"
                        >Password is required</mat-error
                      >
                      <mat-error
                        *ngIf="!password.errors?.required && password.errors?.invalid"
                        data-test-id="login-invalid-credentials-error"
                        >{{ errorMessage }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <mat-checkbox
                      class="keep-me-signed-in dkblue"
                      formControlName="staySignedIn"
                      data-test-id="remember-me-login-checkbox"
                      >Remember Me</mat-checkbox
                    >
                  </div>
                  <div class="col text-right">
                    <a
                      (click)="openPasswordResetDialog()"
                      data-test-id="forgot-password-button"
                      class="ltblue font-weight-normal m-0 d-inline-block align-middle hover-u ease"
                    >
                      <h6 class="m-0">Forgot Password</h6>
                    </a>
                  </div>
                </div>
                <br />
                <div class="row mt-5 mb-5 pb-5">
                  <div class="col">
                    <button
                      mat-flat-button
                      id="login-button"
                      type="submit"
                      color="primary"
                      class="mr-3"
                      [disabled]="!loginFormGroup.valid"
                      data-test-id="login-button"
                    >
                      <span class="mr-2"> Sign In </span>
                      <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                    <button
                      mat-button
                      id="login-sign-in-help-button"
                      type="button"
                      color="accent"
                      (click)="loginHelp()"
                      data-test-id="login-sign-in-help-button"
                    >
                      Help Me Sign In
                    </button>
                  </div>
                </div>
                <div class="row links mt-5 pt-5 align-items-center">
                  <div class="col">
                    <a
                      id="request-account-button"
                      (click)="requestAccount()"
                      data-test-id="request-account-button"
                      class="ltblue font-weight-normal m-0 mr-5 d-inline-block align-middle hover-u ease"
                    >
                      <h6 class="m-0">Request Account</h6>
                    </a>
                    <a
                      id="login-contact-support-button"
                      data-test-id="login-contact-support-button"
                      (click)="openBugReportDialog()"
                      class="ltblue font-weight-normal m-0 mr-5 d-inline-block align-middle hover-u ease"
                    >
                      <h6 class="m-0">Contact Support</h6>
                    </a>
                    <a
                      href="https://help.271call.com/"
                      target="_blank"
                      id="login-help-button"
                      data-test-id="login-help-button"
                      class="ltblue font-weight-normal m-0 d-inline-block align-middle hover-u ease"
                    >
                      <h6 class="m-0">Help Docs</h6>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
