import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { round } from 'lodash';
import { ModuleService, ProductService, ProjectService } from 'src/app/services';
import { Module, Product, Project } from 'src/app/types';

export interface ProductDialogData {
  name?: string;
  showList?: boolean;
  workspaceId?: number;
}

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
})
export class ProductDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ProductDialogData,
    private productService: ProductService,
    private moduleService: ModuleService,
    private snackbar: MatSnackBar,
    private projectService: ProjectService
  ) {}

  public showList: boolean;
  public editable: boolean;
  private allProducts: Product[];
  public filteredProducts: Product[];
  public workspaceId: number;
  public workspaceName = '';
  public searchTerm = '';
  public productId: number;
  private productFields = [
    'id',
    'name',
    'size',
    'color',
    'make',
    'model_number',
    'preferred_company_name',
    'unit_price',
    'description',
    'url',
    'enabled',
  ];

  public productFormGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    size: [''],
    color: [''],
    make: [''],
    model_number: [''],
    preferred_company_name: [''],
    unit_price: [''],
    description: [''],
    url: [''],
    enabled: [true],
  });

  get name() {
    return this.productFormGroup.get('name');
  }
  get size() {
    return this.productFormGroup.get('size');
  }
  get color() {
    return this.productFormGroup.get('color');
  }
  get make() {
    return this.productFormGroup.get('make');
  }
  get model_number() {
    return this.productFormGroup.get('model_number');
  }
  get preferred_company_name() {
    return this.productFormGroup.get('preferred_company_name');
  }
  get unit_price() {
    return this.productFormGroup.get('unit_price');
  }
  get description() {
    return this.productFormGroup.get('description');
  }
  get url() {
    return this.productFormGroup.get('url');
  }
  get enabled() {
    return this.productFormGroup.get('enabled');
  }

  async ngOnInit() {
    if (this.data?.showList) {
      this.showList = true;
    } else {
      this.showProductForm(null, true);
    }

    await this.setWorkspaceProperties();

    this.allProducts = await this.productService
      .getProducts(
        ['id', 'name', 'description', 'unit_price', 'enabled'],
        [{ type: 'field', field: 'module_id', value: this.workspaceId.toString() }]
      )
      .toPromise();

    this.filteredProducts = this.allProducts;
  }

  public async showProductForm(productId?: number, editable: boolean = false) {
    this.showList = false;
    if (productId) {
      // set fields
      this.productId = productId;
      const retrievedProduct = await this.productService.getProductById(productId, this.productFields).toPromise();
      const product = {
        name: retrievedProduct.name,
        size: retrievedProduct.size,
        color: retrievedProduct.color,
        make: retrievedProduct.make,
        model_number: retrievedProduct.model_number,
        preferred_company_name: retrievedProduct.preferred_company_name,
        unit_price: retrievedProduct.unit_price,
        description: retrievedProduct.description,
        url: retrievedProduct.url,
        enabled: retrievedProduct.enabled,
      };
      this.productFormGroup.setValue(product);
      this.unitPriceBlur();
    } else {
      // reset fields
      this.productId = null;
      this.productFormGroup.reset();
      // if there is a name, use it
      if (this.data?.name) {
        this.name.setValue(this.data.name);
      }
      this.enabled.setValue(true);
    }
    if (editable) {
      this.enableInputs();
    } else {
      this.disableInputs();
    }
  }

  private disableInputs() {
    this.editable = false;
    this.productFormGroup.disable();
  }

  public enableInputs() {
    this.editable = true;
    this.productFormGroup.enable();
  }

  public filterProducts() {
    this.filteredProducts = this.allProducts.filter((p) => {
      return (
        p.name?.toLowerCase().includes(this.searchTerm?.toLowerCase()) ||
        p.description?.toLowerCase().includes(this.searchTerm?.toLowerCase())
      );
    });
  }

  /** Converts a number or string into an incomplete decimal format. This is generally used for USD inputs.
   *
   * @param value The value to convert to USD
   */
  private formatDecimal(value, digits) {
    // Remove characters that aren't numbers or decimals
    const clean = value.toString().replace(/[^0-9.]+/g, '');
    // Remove all decimals after the first one
    const decPos = clean.indexOf('.');
    const dec = decPos >= 0 ? `${clean.substring(0, decPos)}.${clean.substring(decPos + 1).replace('.', '')}` : clean;
    // remove all characters after the hundredths place
    const res = decPos >= 0 && decPos < dec.length - digits - 1 ? dec.substring(0, decPos + digits + 1) : dec;
    return res;
  }

  private roundDecimal(value, digits: number) {
    return round(value, digits).toFixed(digits);
  }

  public unitPriceChanged() {
    this.unit_price.setValue(this.formatDecimal(this.unit_price.value, 2));
  }

  public unitPriceBlur() {
    this.unit_price.setValue(this.roundDecimal(this.unit_price.value, 2));
  }

  public async saveProduct() {
    if (this.productFormGroup.valid) {
      const product = this.productFormGroup.value;
      // control the spaces
      product.name = product?.name?.replace(/\s+/g, ' ')?.trim();
      if (this.productId) {
        product.enabled = this.enabled.value ? 1 : 0;
        const updatedProduct = await this.productService.updateProduct(this.productId, product).toPromise();
        this.snackbar.open('Product Updated');
        this.dialogRef.close(updatedProduct);
      } else {
        product.module_id = this.workspaceId;
        product.enabled = this.enabled.value ? 1 : 0;
        const newProduct = await this.productService.createProduct(product).toPromise();
        this.snackbar.open('Product Created');
        this.dialogRef.close(newProduct);
      }
    }
  }

  private async setWorkspaceProperties(): Promise<void> {
    if (this.data?.workspaceId) {
      this.workspaceId = this.data.workspaceId;
      const modules = await this.moduleService.getWorkspaces().toPromise();
      const module: Module = modules.filter((module: Module) => module.id === this.workspaceId)[0] ?? {
        name: 'unknown',
      };
      this.workspaceName = module.name;
    } else if (this.projectService.currentSelectedProjectId) {
      const project: Project = await this.projectService
        .getProjectById(this.projectService.currentSelectedProjectId, ['module{id,name}'])
        .toPromise();
      this.workspaceId = project.module.id;
      this.workspaceName = project.module.name;
    } else {
      this.workspaceId = this.moduleService.workspace.id;
      this.workspaceName = this.moduleService.workspace.name;
    }
  }
}
