import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services';

@Injectable({
  providedIn: 'root',
})
export class StaffGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  // Note: Staff guard now checks if the user is a staff member on any project, in order to not clear urls on refresh (due to workspace not being set for isStaff)
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAppAdmin || this.authService.isStaffOnAnyModule) {
      return true;
    } else {
      return this.router.parseUrl('/401'); // returns UrlTree
    }
  }
}
