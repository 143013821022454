<div *ngIf="isLoading || loading" class="mx-auto mt-4 mb-3">
  <mat-spinner class="ml-auto" color="accent" diameter="26"></mat-spinner>
</div>
<div *ngIf="!isLoading && !loading">
  <mat-button-toggle-group
    class="full-width mb-2"
    type="button"
    [(ngModel)]="view.vendor"
    (ngModelChange)="vendorViewChanged()"
    *ngIf="authService.isStaffOnAnyModule"
  >
    <mat-button-toggle style="width: 50%" type="button" class="font-weight-semibold" [value]="true">
      This Supplier
    </mat-button-toggle>
    <mat-button-toggle style="width: 50%" type="button" class="font-weight-semibold" [value]="false">
      Overall Project
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="view.vendor && currentWorkspace !== eWorkspace.Construction">
    <div *ngIf="quote?.company?.id">
      <h4 class="mb-2 font-weight-semibold dkblue">
        {{ quote?.company?.name }}
      </h4>
    </div>
    <h6 class="dkgray font-weight-semibold text-center" *ngIf="!quote?.company">
      {{ quote?.id ? 'This quote has no supplier' : 'Select a supplier to view the budget' }}
    </h6>
  </div>
  <div *ngIf="!view.vendor || bidPackage?.awarded_company_id || quote?.company?.id">
    <section class="py-2 d-flex flex-column" *ngIf="view.currentInvoice">
      <div class="m-portlet mb-3 p-0">
        <div class="m-portlet__body full-width d-flex flex-column p-3">
          <div class="d-flex flex-column full-width mb-3">
            <div class="d-flex">
              <h5 class="dkblue mb-0 mr-auto">Current Contract Sum</h5>
              <button
                (click)="setCurrentInvoiceView(false)"
                mat-button
                type="button"
                color="accent"
                class="ml-auto lh-18 px-2"
              >
                <span> Previous Invoices </span>
              </button>
            </div>
            <p *ngIf="view.vendor" class="dkgray mb-0">
              {{ !view.vendor || bidPackage?.trade_name ? bidPackage?.trade_name + ' Bid Package' : '' }}
            </p>
          </div>
          <div class="d-flex align-items-bottom mb-2">
            <p class="dkblue font-weight-semibold mb-0">{{ billedPercentageAfter | percent: '1.1-1' }} Billed</p>
            <p class="dkblue mb-0 font-weight-semibold ml-auto">
              <span class="dkgray font-weight-normal">
                {{ billedTotalAfter | currency: 'USD' }}
              </span>
              / {{ totalContractSum | currency: 'USD' }}
            </p>
          </div>

          <mat-progress-bar class="" mode="determinate" color="accent" [value]="100 * +billedPercentageAfter">
          </mat-progress-bar>
          <div class="d-flex align-items-center mb-1 mt-2">
            <p class="dkblue m-0 text-truncate">Original Contract Sum</p>
            <p class="dkblue m-0 ml-auto">
              {{ originalContractSum | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center mb-1" *ngIf="currentWorkspace === eWorkspace.Construction">
            <p class="dkblue m-0 text-truncate">Change Order Net</p>
            <p class="dkblue m-0 ml-auto">
              {{ changeOrderTotal | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center mb-1">
            <p class="dkblue m-0 text-truncate mr-auto">Previously Billed</p>
            <p class="dkgray opacity9 m-0 ml-3">
              {{ billedPercentageBefore | percent: '1.1-1' }}
            </p>
            <p class="dkblue m-0 ml-3">
              {{ billedTotalBefore | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center mb-1" *ngIf="currentWorkspace === eWorkspace.Construction">
            <p class="dkblue m-0 text-truncate mr-auto">Total Retainage WH</p>
            <!-- <p class="dkgray opacity9 m-0 ml-3">
              {{ retainageTotalPercentageAfter | percent: '1.1-1' }}
            </p> -->
            <p class="dkblue m-0 ml-3">
              {{ retainageTotalAfter | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center" *ngIf="currentWorkspace === eWorkspace.Construction">
            <p class="dkblue m-0 font-weight-bold text-truncate mr-auto" [ngClass]="{ red: balanceToFinishAfter < 0 }">
              Balance-To-Finish
            </p>
            <p class="dkblue m-0 font-weight-bold ml-3" [ngClass]="{ red: balanceToFinishAfter < 0 }">
              {{ balanceToFinishAfter | currency: 'USD' }}
            </p>
          </div>
        </div>
      </div>

      <!-- INVOICE FOR ITEM STATS GO HERE -->

      <div class="m-portlet mb-3 p-0 border-primary" *ngIf="changeOrder">
        <div class="m-portlet__body full-width d-flex flex-column p-3">
          <div class="d-flex flex-column full-width mb-3">
            <div class="d-flex">
              <h5
                *ngIf="changeOrder?.id"
                (click)="viewChangeOrder(changeOrder)"
                class="ltblue hover-u pointer mb-0 mr-auto"
              >
                {{ 'CO#' + changeOrder.local_index + ' - ' + changeOrder.short_description }}
              </h5>
              <h5 *ngIf="!changeOrder?.id" class="dkblue mb-0 mr-auto">Original Bid</h5>
            </div>
            <p class="dkgray mb-0">
              {{ !view.vendor || bidPackage?.trade_name ? bidPackage?.trade_name + ' Bid Package' : '' }}
            </p>
          </div>
          <div class="d-flex align-items-bottom mb-2">
            <p class="dkblue font-weight-semibold mb-0">
              {{
                invoiceForBilledTotalAfter /
                  (invoiceForContractSum + (!changeOrder?.id ? +changeOrderTotalDeduction : 0)) | percent: '1.1-1'
              }}
              Billed
            </p>
            <p class="dkblue mb-0 font-weight-semibold ml-auto">
              <span class="dkgray font-weight-normal">
                {{ invoiceForBilledTotalAfter | currency: 'USD' }}
              </span>
              <span>
                / {{ invoiceForContractSum + (!changeOrder?.id ? +changeOrderTotalDeduction : 0) | currency: 'USD' }}
              </span>
            </p>
          </div>
          <mat-progress-bar
            class=""
            mode="determinate"
            color="accent"
            [value]="
              100 *
              (invoiceForBilledTotalAfter /
                (invoiceForContractSum + (!changeOrder?.id ? +changeOrderTotalDeduction : 0)))
            "
          ></mat-progress-bar>
          <!-- <div class="d-flex align-items-center mb-1 mt-2">
            <p class="dkblue m-0 text-truncate mr-auto">Billed-To-Date</p>
            <p class="dkgray opacity9 m-0 ml-3">
              {{ invoiceForBilledPercentageAfter | percent: '1.1-1' }}
            </p>
            <p class="dkblue m-0 ml-3">
              {{ invoiceForBilledTotalAfter | currency: 'USD' }}
            </p>
          </div> -->
          <div *ngIf="!changeOrder?.id" class="d-flex align-items-center mt-2 mb-1">
            <p class="dkblue m-0 text-truncate">Original Contract Sum</p>
            <p class="dkblue m-0 ml-auto">
              {{ originalContractSum | currency: 'USD' }}
            </p>
          </div>
          <div
            class="d-flex align-items-center mb-1"
            *ngIf="currentWorkspace === eWorkspace.Construction && +changeOrderTotalDeduction < 0 && !changeOrder?.id"
          >
            <p class="dkblue m-0 text-truncate mr-auto">Deductions</p>
            <p class="dkblue m-0 ml-3">
              {{ changeOrderTotalDeduction | currency: 'USD' }}
            </p>
          </div>
          <div [ngClass]="{ 'mt-2': changeOrder?.id }" class="d-flex align-items-center mb-1">
            <p class="dkblue m-0 text-truncate mr-auto">Previously Billed</p>
            <p class="dkgray opacity9 m-0 ml-3">
              {{
                invoiceForBilledTotalBefore /
                  (originalContractSum + (!changeOrder?.id ? +changeOrderTotalDeduction : 0)) | percent: '1.1-1'
              }}
            </p>
            <p class="dkblue m-0 ml-3">
              {{ invoiceForBilledTotalBefore | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center mb-1" *ngIf="currentWorkspace === eWorkspace.Construction">
            <p class="dkblue m-0 text-truncate mr-auto">Total Retainage WH</p>
            <!-- <p class="dkgray opacity9 m-0 ml-3">
              {{ invoiceForRetainageTotalPercentageAfter | percent: '1.1-1' }}
            </p> -->
            <p class="dkblue m-0 ml-3">
              {{ invoiceForRetainageTotalAfter | currency: 'USD' }}
            </p>
          </div>
          <div class="d-flex align-items-center" *ngIf="currentWorkspace === eWorkspace.Construction">
            <p
              class="dkblue m-0 font-weight-bold text-truncate mr-auto"
              [ngClass]="{
                red:
                  (!changeOrder.id && changeOrderTotalDeduction < 0
                    ? invoiceForBalanceToFinishAfter + changeOrderTotalDeduction
                    : invoiceForBalanceToFinishAfter) < 0
              }"
            >
              Balance-To-Finish
            </p>
            <p
              class="dkblue m-0 font-weight-bold ml-3"
              [ngClass]="{
                red:
                  (!changeOrder.id && changeOrderTotalDeduction < 0
                    ? invoiceForBalanceToFinishAfter + changeOrderTotalDeduction
                    : invoiceForBalanceToFinishAfter) < 0
              }"
            >
              {{
                (!changeOrder.id && changeOrderTotalDeduction < 0
                  ? invoiceForBalanceToFinishAfter + changeOrderTotalDeduction
                  : invoiceForBalanceToFinishAfter
                ) | currency: 'USD'
              }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-3 d-flex flex-column" *ngIf="!view.currentInvoice">
      <div class="m-portlet">
        <div class="m-portlet__body full-width d-flex flex-column p-0">
          <div class="d-flex align-items-center p-3">
            <h5 class="mb-0 font-weight-semibold dkblue">Previous Invoices</h5>
            <h6 class="bg-gray white br-5 ml-2 px-2 lh-18 mb-0">{{ invoices?.length || 0 }}</h6>
            <button
              (click)="setCurrentInvoiceView(true)"
              mat-button
              type="button"
              color="accent"
              class="ml-auto lh-18 px-2"
            >
              <mat-icon>navigate_before</mat-icon>
              <span> Current Invoice </span>
            </button>
          </div>
          <div class="d-flex flex-column">
            <div
              *ngFor="let i of invoices | slice: invoiceStartIndex:invoiceEndIndex; index as invoiceIndex"
              class="item-no-hover p-3 d-flex flex-column pointer"
              [ngClass]="{
                'item-first': invoiceIndex === 0,
                'bg-ltltltblue': i.id === invoice?.id
              }"
              (click)="changeInvoice(i)"
            >
              <div class="d-flex align-items-center mb-1">
                <h6 class="dkblue m-0 mr-auto">Invoice {{ i.number }}</h6>

                <h6 class="dkblue m-0">
                  {{ +i.total - +i.retainage + +i.shipping + +i.tax | currency: 'USD' }}
                </h6>
              </div>
              <div class="d-flex align-items-center">
                <h6 class="dkblue font-weight-normal m-0 mr-auto">
                  {{ i.invoice_date | date: 'MMM d, yyyy' }}
                </h6>
                <h6 class="font-weight-normal m-0 dkgray">
                  {{ getStatusName(i.status_id) }}
                </h6>
              </div>
            </div>
            <mat-paginator
              class="bg-transparent dkblue"
              color="primary"
              #invoicePaginator
              [length]="invoices?.length || 0"
              [pageSize]="invoicePageSize"
              [hidePageSize]="true"
              (page)="invoicePageChange($event)"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
