import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEnabled',
})
export class IsEnabledPipe implements PipeTransform {
  transform(data: any, is_enabled: number | boolean) {
    const filtered = data?.filter((d) => {
      return d.is_enabled === 1;
    });
    return is_enabled ? data : filtered;
  }
}
