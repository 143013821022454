<div class="browser-not-support-container container-fluid">
  <div
    class="row bg align-items-center justify-content-center"
    [style.backgroundImage]="'url(../../assets/img/login_bg_1.jpg)'"
  >
    <div class="browser-not-support-container-title">
      Browser not supported, please use
      <a href="https://www.google.com/intl/en_us/chrome/" target="__blank">Google Chrome</a>
    </div>
  </div>
</div>
