import { Pipe, PipeTransform } from '@angular/core';
import { PurchasingInvoice, PurchasingInvoicesFilterValues } from 'src/app/pages';

@Pipe({
  name: 'purchasingInvoiceListStatusCount',
})
export class PurchasingInvoiceListStatusCountPipe implements PipeTransform {
  transform(value: PurchasingInvoice[], statusId: string | number, filters: PurchasingInvoicesFilterValues): unknown {
    const filterFunction = (value: PurchasingInvoice) => {
      const includesModule = filters.modules.length === 0 || filters.modules.includes(value.module_id.toString());
      const includesFiscalYear =
        filters.fiscalYears.length === 0 || filters.fiscalYears.includes(value.fiscal_year || 'no fiscal year');
      const includesCompany =
        !filters.company || (value.company_name || 'no_company').toLowerCase().includes(filters.company.toLowerCase());
      const includesProject =
        !filters.project ||
        ((value.project_id && `${value.project_code} ${value.project_title}`) || 'no_company')
          .toLowerCase()
          .includes(filters.project.toLowerCase());
      const greaterThanStartDate =
        !filters.dateRange.startDate ||
        (value.created_datetime && value.created_datetime >= filters.dateRange.startDate.toJSON());
      const lessThanEndDate =
        !filters.dateRange.endDate ||
        (value.created_datetime && value.created_datetime <= filters.dateRange.endDate.toJSON());
      const includesType = filters.types.length === 0 || filters.types.includes(value.query_join_type.toString());
      const includesSearch =
        !filters.search ||
        `${value.company_name} ${value.project_code} ${value.project_title} ${value.invoice_number}`
          .toLowerCase()
          .includes(filters.search.toLowerCase());

      return (
        includesModule &&
        includesFiscalYear &&
        includesCompany &&
        includesProject &&
        greaterThanStartDate &&
        lessThanEndDate &&
        includesType &&
        includesSearch
      );
    };

    return value.filter((i) => statusId === 'all' || i.invoice_status_id.toString() === statusId).filter(filterFunction)
      .length;
  }
}
