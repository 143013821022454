import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import * as Sentry from '@sentry/angular-ivy';
// Internal modules
import { AppRoutingModule } from 'src/app/app-routing.module';
// components
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/modules/core.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
// pages
import {
  AccountReviewListComponent,
  AccountsComponent,
  ARManagerComponent,
  BlankPageComponent,
  ConfirmAccountComponent,
  DepartmentsComponent,
  DirectoryComponent,
  DispatchComponent,
  HomeComponent,
  LoginComponent,
  MeetingComponent,
  MeetingListComponent,
  NewRequestComponent,
  PageNotFoundComponent,
  PasswordResetComponent,
  ProductsComponent,
  ProfileComponent,
  ProjectComponent,
  ProjectListComponent,
  ProjectOverviewComponent,
  ProjectRoutingComponent,
  PurchasingComponent,
  QuickRequestComponent,
  ReleaseNotesComponent,
  RemindersComponent,
  RequestComponent,
  RequestListComponent,
  TaskListComponent,
  TimeLogComponent,
  WeeklyFocusComponent,
  WorkOrderComponent,
  WorkOrderListComponent,
  WorkspaceDashboardComponent,
} from 'src/app/pages';
import { ConstructionModule } from 'src/app/workspaces/construction/construction.module';
import { SignageModule } from 'src/app/workspaces/signage/signage.module';
import { environment } from '../environments/environment';
import { EditContractDialogComponent } from './components/edit-contract-dialog/edit-contract-dialog.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { RequestReceiptDialogComponent } from './components/request-receipt-dialog/request-receipt-dialog.component';
import { ViewContractDialogComponent } from './components/view-contract-dialog/view-contract-dialog.component';
import { httpInterceptorProviders } from './http-interceptors';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { DepartmentProfileComponent } from './pages/department-profile/department-profile.component';
import { BudgetChangesDialogComponent } from './pages/purchasing-budget-list/budget-changes-dialog/budget-changes-dialog.component';
import { ReleaseNotesAddEditDialogComponent } from './pages/release-notes/release-notes-add-edit-dialog/release-notes-add-edit-dialog.component';
import { WorkspaceSettingsComponent } from './pages/workspace-settings/workspace-settings.component';
import { CompanyPipePipe } from './pipes/company-pipe.pipe';

let appProviders: Provider[] = [httpInterceptorProviders];

if (environment.sentry) {
  const sentryProviders = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];

  appProviders = [...appProviders, ...sentryProviders];
}

@NgModule({
  declarations: [
    AccountReviewListComponent,
    AccountsComponent,
    ARManagerComponent,
    AppComponent,
    ConfirmAccountComponent,
    DepartmentsComponent,
    DirectoryComponent,
    DispatchComponent,
    HomeComponent,
    LoginComponent,
    MeetingComponent,
    MeetingListComponent,
    NewRequestComponent,
    PageNotFoundComponent,
    PasswordResetComponent,
    ProfileComponent,
    ProjectComponent,
    ProductsComponent,
    ProjectListComponent,
    ProjectOverviewComponent,
    ProjectRoutingComponent,
    PurchasingComponent,
    ReleaseNotesComponent,
    QuickRequestComponent,
    RemindersComponent,
    RequestComponent,
    RequestListComponent,
    TimeLogComponent,
    WorkOrderComponent,
    WorkOrderListComponent,
    WorkspaceDashboardComponent,
    QuickRequestComponent,
    RequestReceiptDialogComponent,
    WorkspaceSettingsComponent,
    ReleaseNotesComponent,
    ReleaseNotesAddEditDialogComponent,
    EditContractDialogComponent,
    ViewContractDialogComponent,
    TaskListComponent,
    WeeklyFocusComponent,
    BlankPageComponent,
    CompaniesComponent,
    DepartmentsComponent,
    CompanyProfileComponent,
    DepartmentProfileComponent,
    FilesListComponent,
    BudgetChangesDialogComponent,
    CompanyPipePipe,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ConstructionModule,
    CoreModule,
    MaterialModule,
    NgbModule,
    SharedModule,
    SignageModule,
    ScrollingModule,
    RxReactiveFormsModule,

    // IMPORTANT: Keep this at the bottom of the imports
    AppRoutingModule,
    CdkTableModule,
  ],
  providers: [...appProviders], // edit appProviders array at the top of the file to add providers.
  bootstrap: [AppComponent],
})
export class AppModule {}
