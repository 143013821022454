export class RequestDashboardFilter {
  // Relates to Upcoming/ Recent
  public status: 'pending' | 'accepted' | 'recent' | 'converted' = 'pending';
  // all requests need to be due between start and end date
  public startDate: Date | string;
  public endDate: Date | string;
  // how many days ago to where a request counts as a recent request
  public daysForRecent = 7;

  constructor(startDate: Date | string, endDate: Date | string, daysForRecent?: number) {
    this.startDate = startDate;
    this.endDate = endDate;
    if (daysForRecent) {
      this.daysForRecent = daysForRecent;
    }
  }
}
