import { Pipe, PipeTransform } from '@angular/core';
import { ProjectProduct } from '../types';

@Pipe({
  name: 'productFilter',
})
export class ProductFilterPipe implements PipeTransform {
  transform(products: ProjectProduct[], selectedQuoteId: number, runProductFilter?: number) {
    if (selectedQuoteId) {
      products = products?.filter(
        (product) => product.is_enabled && product?.selected_quote_item?.quote_id === selectedQuoteId
      );
    }

    return products;
  }
}
