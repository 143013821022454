import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WorkspaceDropdownComponent } from 'src/app/components';
import { TopicType, Workspace } from 'src/app/enums';
import { ModuleService, ProjectService, TopicService } from 'src/app/services';
import { APIFilter, Project, Topic, TopicCategory, TopicGroup } from 'src/app/types';
@Component({
  selector: 'app-project-topic',
  templateUrl: './project-topic.component.html',
  styleUrls: ['./project-topic.component.scss'],
})
export class ProjectTopicComponent implements OnInit {
  @Input() projectId: number;
  @ViewChild('workspace', { static: true })
  private _workspace_component: WorkspaceDropdownComponent;
  private _topicGroupFields =
    'id,name,topic_categories{id,name,is_enabled,topic_group_id,topic_group{id,name},topics{id,topic_type_id,name,topic_group_id}}';
  private _topicCategoryFields =
    'id,name,topic_group_id,is_enabled,topic_group{id,name},topics{id,topic_type_id,topic_category_id,name,workspace_id}';
  private _topicFields =
    'id,name,topic_type_id,topic_group_id,topic_category_id,topic_category{id,name,is_enabled,topic_group_id,topic_group{id,name}}';
  private _categoryIds: number[] = [];
  private _groupIds: number[] = [];

  public allTopicGroups: TopicGroup[] = [];
  public currentTopicGroups: TopicGroup[] = [];
  public allTopicCategories: TopicCategory[] = [];
  public currentTopicCategories: TopicCategory[] = [];
  public allTopics: Topic[] = [];
  public currentTopics: Topic[] = [];
  public filteredTopicGroups$: Observable<TopicGroup[]>; // Workspace topic groups that have been filtered
  public filteredTopicCategories$: Observable<TopicCategory[]>;
  public filteredTopics$: Observable<Topic[]>;
  public isClearingCategory = false;
  public isClearingGroup = false;
  public isClearingTopic = false;
  public project: Project;
  public topicFormGroup: FormGroup = this._fb.group({
    topic_group: [''],
    topic_category: [''],
    topic: ['', [Validators.required, this.topicValidator()]],
  });
  constructor(
    private _fb: FormBuilder,
    private _moduleService: ModuleService,
    private _projectService: ProjectService,
    private _topicService: TopicService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.projectId) {
      // await this.load();
    }
  }

  public get projectFields(): string[] {
    return [this._projectService.projectFields];
  }

  public get topic_category(): AbstractControl {
    return this.topicFormGroup.get('topic_category');
  }

  public get topic_group(): AbstractControl {
    return this.topicFormGroup.get('topic_group');
  }

  public get topic(): AbstractControl {
    return this.topicFormGroup.get('topic');
  }

  public get workspace(): AbstractControl {
    return this.topicFormGroup.get('workspace');
  }

  private _filterTopicGroups(entry: string): Topic[] {
    return this.currentTopicGroups.filter((topicGroup: TopicGroup) =>
      topicGroup?.name?.toLowerCase()?.includes(entry?.toLowerCase())
    );
  }

  private _filterTopicCategories(entry: string): Topic[] {
    return this.currentTopics.filter((topicCategory: TopicCategory) =>
      topicCategory?.name?.toLowerCase()?.includes(entry?.toLowerCase())
    );
  }

  private _filterTopics(entry: string): Topic[] {
    return this.currentTopics.filter((topic: Topic) => topic?.name?.toLowerCase()?.includes(entry?.toLowerCase()));
  }

  public async _getTopicCategories(): Promise<void> {
    // setup up filters
    const topicCategoriesFilter: APIFilter[] = [{ type: 'field', field: 'id', value: this._categoryIds?.join('^') }];

    // retrieve the categories
    this.currentTopicCategories = await this._topicService
      .getTopicCategories([this._topicCategoryFields], topicCategoriesFilter, null, null, null, false)
      .toPromise();

    // make a copy of the topic categories
    this.allTopicCategories = this.currentTopicCategories;

    // setup autocompletion
    this.filteredTopicCategories$ = this.topic_category.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | TopicCategory) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((name) => {
        return name ? this._filterTopicCategories(name) : this.currentTopicCategories.slice();
      })
    );
  }

  public async _getTopicGroups(): Promise<void> {
    // setup up filters
    const topicGroupsFilter: APIFilter[] = [{ type: 'field', field: 'id', value: this._groupIds?.join('^') }];

    // retrieve the groups
    this.currentTopicGroups = await this._topicService
      .getTopicGroups([this._topicGroupFields], topicGroupsFilter, undefined, undefined, undefined, false)
      .toPromise();

    // make a copy of the topic groups
    this.allTopicGroups = this.currentTopicGroups;

    // setup autocompletion
    this.filteredTopicGroups$ = this.topic_group.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | TopicGroup) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((name) => {
        return name ? this._filterTopicGroups(name) : this.currentTopicGroups.slice();
      })
    );
  }

  public async _getTopics(): Promise<void> {
    // create a topic filter
    const topicsFilter: APIFilter[] = [
      { type: 'field', field: 'topic_type_id', value: TopicType?.Request?.toString() },
    ];

    // modify the api filter for not dispatch workspace
    if (+this.project.module_id !== +Workspace.Dispatch) {
      topicsFilter.push(
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'workspace_id', value: this.project?.module_id?.toString() }
      );
    }

    // include the project topic if it exists
    const selectedTopicIds = this.project?.topic_id ? [this.project.topic_id] : [];
    this.currentTopics = await this._topicService
      .getTopics([this._topicFields], topicsFilter, null, null, null, false, selectedTopicIds)
      .toPromise();
    // make a copy of the topics
    this.allTopics = this.currentTopics;

    // use this to create unique category ids and group ids
    this._categoryIds = uniq(this.currentTopics?.map((topic: Topic) => topic.topic_category_id)) ?? [];
    this._groupIds = uniq(this.currentTopics?.map((topic: Topic) => topic.topic_group_id)) ?? [];

    // filter the topics by category
    if (this.topic_category?.value?.id) {
      this.currentTopics = this.currentTopics?.filter(
        (topic: Topic) => +topic?.topic_category_id == +this.topic_category?.value?.id
      );
    }

    // setup autocompletion
    this.filteredTopics$ = this.topic.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((value: string | Topic) => (typeof value === 'string' ? value : value?.name ?? '')),
      map((name) => {
        return name ? this._filterTopics(name) : this.currentTopics.slice();
      })
    );
  }

  public clearTopicGroup(event = null): void {
    this.topic_group.setValue('');
    if (event) {
      event.stopPropagation();
    }
    // clear topic category as well
    this.clearTopicCategory();
    this.filteredTopicCategories$ = this.filteredTopicCategories$.pipe(map(() => this.allTopicCategories));
  }

  public clearTopicCategory(event = null): void {
    this.topic_category.setValue('');
    if (event) {
      event.stopPropagation();
    }
    // clear topics as well
    this.clearTopic();
    this.filteredTopics$ = this.filteredTopics$.pipe(map(() => this.allTopics));
  }

  public clearTopic(event = null): void {
    this.topic.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public async load(): Promise<void> {
    // get the project which will have the module or workspace id and the topic id if any
    this.project = await this._projectService.selectProjectById(this.projectId, this.projectFields, true);

    // get topics, categories and groups before the setting of inputs
    // get topics, categories and groups
    await this._getTopics();
    await this._getTopicCategories();
    await this._getTopicGroups();

    // fill in topic group
    if (this.project?.topic_group_id && this.project?.topic_group) {
      this.topic_group?.setValue(this.project.topic_group);
      // this.currentTopicGroups.push(this.project.topic_group);
    }

    // fill in topic category
    if (this.project.topic_category_id && this.project.topic_category) {
      this.topic_category.setValue(this.project.topic_category);
      // this.currentTopicCategories.push(this.project.topic_category);
    }

    // fill in topic
    if (this.project.topic_category_id && this.project.topic_category) {
      this.topic.setValue(this.project.topic);
    }

    // register the workspace
    this.topicFormGroup.addControl('workspace', this._workspace_component.workspace);
    if (this.project?.module_id) {
      const workspace = await this._moduleService
        .getWorkspaces(
          ['id', 'name', 'icon', 'workspace_type'],
          [{ type: 'field', field: 'id', value: `${this.project.module_id}` }]
        )
        .toPromise();
      this.workspace.setValue(workspace[0] || this._moduleService.workspace);
    } else {
      this.workspace.setValue(this._moduleService.workspace);
    }
    this.workspace?.disable();
  }

  public async resetAndReload(): Promise<void> {
    this.topic_group?.reset();
    this.topic_category?.reset();
    this.topic?.reset();
    this.allTopics = [];
    this.currentTopicGroups = [];
    this.allTopicCategories = [];
    this.currentTopicCategories = [];
    this.allTopics = [];
    this.currentTopics = [];
    await this.load();
  }

  public selectedTopicGroupChanged(): void {
    this.currentTopicCategories = this.allTopicCategories?.filter(
      (topicCategory: TopicCategory) => +topicCategory.topic_group_id === +this.topic_group?.value?.id
    );
    this.currentTopics = this.allTopics?.filter(
      (topic: Topic) => +topic?.topic_group_id === +this.topic_group?.value?.id
    );

    this.filteredTopicCategories$ = this.filteredTopicCategories$.pipe(map(() => this.currentTopicCategories));
    this.filteredTopics$ = this.filteredTopics$.pipe(map(() => this.currentTopics));
  }

  public selectedTopicCategoryChanged(): void {
    if (+this.topic_group?.value?.id !== +this.topic_category?.value?.topic_group?.id) {
      this.topic_group?.setValue(this.topic_category?.value?.topic_group);
    }
    this.currentTopics = this.allTopics?.filter(
      (topic: Topic) => +topic?.topic_category_id === +this.topic_category?.value?.id
    );
    this.filteredTopics$ = this.filteredTopics$.pipe(map(() => this.currentTopics));
  }

  public selectedTopicChanged(): void {
    // if the topic is set, update the category and group
    if (+this.topic_group?.value?.id !== +this.topic.value?.topic_category?.topic_group?.id) {
      // update the group
      this.topic_group?.setValue(this.topic.value?.topic_category?.topic_group);
    }

    if (+this.topic_category?.value?.id !== +this.topic.value?.topic_category) {
      // update the category
      this.topic_category?.setValue(this.topic.value?.topic_category);
    }
  }

  public topicCategoryMapper(category: TopicCategory): string {
    return category?.name || '';
  }

  public topicGroupMapper(group: TopicGroup): string {
    return group?.name || '';
  }

  public topicMapper(topic: Topic): string {
    return topic?.name || '';
  }

  public topicValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return !this.currentTopics?.find((topic) => topic.id === control.value?.id) ? { topic: { value: true } } : null;
    };
  }
}
