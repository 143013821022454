<div class="purchasing-arf-list">
  <form class="" [formGroup]="filters">
    <div class="mb-4 d-flex align-items-start flex-wrap">
      <mat-form-field class="mr-3 mt-1" appearance="outline" style="width: 120px">
        <mat-select formControlName="fiscalYears" placeholder="FY" multiple>
          <button mat-button style="width: 100%" (click)="handleSelectAll('fiscalYears')">
            {{
              filters.get('fiscalYears').value.length === filterOptions.fiscalYears.length
                ? 'Select None'
                : 'Select All'
            }}
          </button>
          <mat-option *ngFor="let fiscalYear of filterOptions.fiscalYears" [value]="fiscalYear">
            {{ fiscalYear }}
          </mat-option>
        </mat-select>

        <mat-icon
          class="gray pointer"
          *ngIf="!(filters.get('fiscalYears').value | isJsonEquivalent: [currentFiscalYear])"
          matSuffix
          (click)="filters.get('fiscalYears').setValue([currentFiscalYear]); $event.stopPropagation()"
          >cancel
        </mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="isAdmin || isDataAnalyst" class="mr-3 mt-1" appearance="outline">
        <mat-select formControlName="moduleIds" placeholder="Workspace" multiple>
          <button mat-button style="width: 100%" (click)="handleSelectAll('moduleIds')">
            {{ filters.get('moduleIds').value.length === filterOptions.modules.length ? 'Select None' : 'Select All' }}
          </button>
          <mat-option *ngFor="let module of filterOptions.modules" [value]="module.value">
            {{ module.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          *ngIf="!(filters.get('moduleIds').value | isJsonEquivalent: [moduleService.workspace_id?.toString()])"
          matSuffix
          (click)="
            filters.get('moduleIds').setValue([moduleService.workspace_id?.toString()]); $event.stopPropagation()
          "
          class="gray pointer"
          >cancel
        </mat-icon>
      </mat-form-field>
      <mat-button-toggle-group formControlName="statusId" class="lh-38">
        <mat-button-toggle value="" class="px-2">
          All Active
          <span
            class="badge badge-pill"
            [ngClass]="filters.get('statusId').value === '' ? 'badge-primary' : 'badge-secondary'"
          >
            {{ dataSource.data | arfStatusCount: 'all':filters.value }}
          </span>
        </mat-button-toggle>
        <mat-button-toggle *ngFor="let status of arfStatusOptions" [value]="status.value" class="px-2">
          {{ status.name }}
          <span
            class="badge badge-pill"
            [ngClass]="filters.get('statusId').value === status.value ? 'badge-primary' : 'badge-secondary'"
          >
            {{ dataSource.data | arfStatusCount: status.value:filters.value }}
          </span>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- ARF Status should go here - button group -->

      <button
        mat-stroked-button
        color="primary"
        class="bg-white ml-auto lh-40"
        type="button"
        (click)="showFilters = !showFilters"
        [matBadge]="filtersAppliedCount > 0 && !showFilters ? filtersAppliedCount : null"
        matBadgeColor="warn"
      >
        <mat-icon class="mr-2">filter_list</mat-icon>
        Filters
      </button>
      <mat-form-field class="ml-3 mt-1" appearance="outline">
        <input matInput placeholder="Search ARFs" formControlName="search" />
        <button
          type="button"
          *ngIf="filters.get('search').value"
          matSuffix
          mat-icon-button
          (click)="filters.get('search').setValue(null)"
        >
          <mat-icon class="gray">cancel</mat-icon>
        </button>
      </mat-form-field>
      <button
        color="primary"
        class="ml-3 bg-white border border-gray"
        mat-icon-button
        type="button"
        (click)="exportARF()"
        [disabled]="isExporting"
        matTooltip="Export List to CSV"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        <mat-icon *ngIf="!isExporting" color="primary"> file_download</mat-icon>
        <span class="d-inline-flex">
          <mat-spinner *ngIf="isExporting" color="accent" [diameter]="20"></mat-spinner>
        </span>
      </button>
      <button class="ml-3" *ngIf="isWorkspaceStaff" (click)="createArf()" mat-flat-button color="success" type="button">
        + Create ARF
      </button>
    </div>
    <div *ngIf="showFilters" class="mb-4 row align-items-center m-0 p-4 border border-gray br-10 bg-shade-ltblue">
      <div class="col-2 pl-0">
        <h6 class="primary">Supplier Company</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input matInput formControlName="company" [matAutocomplete]="autoSupplierCompany" />
          <mat-autocomplete #autoSupplierCompany="matAutocomplete">
            <mat-option *ngFor="let company of filteredCompanyOptions$ | async" [value]="company">
              {{ company }}
            </mat-option>
          </mat-autocomplete>
          <button
            *ngIf="filters.get('company').value"
            matSuffix
            mat-icon-button
            (click)="filters.get('company').setValue(''); $event.stopPropagation()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col text-right">
        <button mat-stroked-button color="primary" class="ml-3 bg-white" type="button" (click)="handleClearFilters()">
          Clear All
        </button>
      </div>
    </div>
  </form>
  <div class="m-portlet bg-white mb-0" xmlns="http://www.w3.org/1999/html">
    <div class="table-responsive">
      <table class="table mb-0" cdk-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="code">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
          <td mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="row.arf_source">
              <span [innerHTML]="row.code | highlight: filters.get('search').value"></span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="fiscal_year">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Fiscal Year
          </th>
          <td mat-cell *matCellDef="let row">{{ row.fiscal_year }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Title
          </th>
          <td mat-cell *matCellDef="let row">
            <a
              [href]="row.arf_source === 'quotes' ? '/projects/' + row.parent_id : '/purchasing/arfs/' + row.id"
              class="ltblue font-weight-semibold"
              [innerHTML]="row.title | truncate: 50 | highlight: filters.get('search').value"
            >
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Supplier
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.company?.name | truncate: 50 | highlight: filters.get('search').value"
          ></td>
        </ng-container>

        <ng-container matColumnDef="sub_cost_codes">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef>Cost Codes</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="
              row.products || [] | getArfProductSubCostCodes | truncate: 50 | highlight: filters.get('search').value
            "
          ></td>
        </ng-container>

        <ng-container matColumnDef="module">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Workspace
          </th>
          <td mat-cell *matCellDef="let row">{{ row.module?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="created_datetime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="border-right border-bottom border-gray">
            Created
          </th>
          <td mat-cell *matCellDef="let row">{{ row.created_datetime | date: 'EEE, MMM d YYYY h:mm aa' }}</td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Total
          </th>
          <td mat-cell *matCellDef="let row">
            <b>
              {{ row.products | getArfProductsTotalPrice: (row.arf_status?.id === 5 ? row.invoices : null) | currency }}
              <ng-container *ngIf="row.arf_status?.id === 5 && row.arf_source === 'quotes'">*</ng-container>
            </b>
          </td>
        </ng-container>

        <ng-container matColumnDef="arf_status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">
            <span
              class="px-2 lh-20 br-10"
              [ngClass]="{
                'bg-shade-ltblue accent': row.arf_status?.id === 1,
                'bg-shade-orange dkorange': row.arf_status?.id === 2,
                'bg-shade-green dkgreen': row.arf_status?.id === 3,
                'bg-shade-ltblue primary': row.arf_status?.id === 4,
                'bg-gray white': row.arf_status?.id === 5
              }"
            >
              {{ row.arf_status?.name }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="hover-u"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [routerLink]="row.arf_source === 'quotes' ? ['/projects', row.parent_id, 'purchasing'] : [row.id]"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td *ngIf="dataSource.data.length" class="mat-cell text-center" colspan="8">
            No results found.
            <button mat-button color="accent" type="button" (click)="handleClearFilters()">Clear Filters</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <mat-paginator class="bg-transparent" pageSize="40"></mat-paginator>
</div>
