import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { remove, sortBy } from 'lodash';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { LocationService, ProgressIndicatorService, ProjectService, UserService } from 'src/app/services';
import { APIFilter, Department, User } from 'src/app/types';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import { PEBFundingSourceTypeEnum } from '../../enums';
import { PEBFundingSourceType, PEBSection } from '../../types';

@Component({
  selector: 'app-peb-section-dialog',
  templateUrl: './peb-section-dialog.component.html',
  styleUrls: ['./peb-section-dialog.component.scss'],
})
export class PEBSectionDialogComponent implements OnInit {
  projectId: number;
  sections: PEBSection[];
  fundingSourceTypes: PEBFundingSourceType[];
  fundingSourceTenantApprovalRequiredIds = [];
  allDepartments: Department[];
  filteredDepartments: Department[];
  users: User[];
  filteredRepresentatives: User[];
  selectedSectionIndex: number;
  isLoading = true;
  canEdit: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData,
    private _dialogRef: MatDialogRef<PEBSectionDialogComponent>,
    private pebService: PEBService,
    private fb: FormBuilder,
    private locationService: LocationService,
    private userService: UserService,
    private projectTenantService: ProjectTenantService,
    private snackbar: MatSnackBar,
    private projectService: ProjectService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  sectionFormGroup: FormGroup = this.fb.group({
    department: [null, [Validators.required]],
    representative: [null, [Validators.required]],
    construction_sqft: [null],
    occupied_sqft: [null],
    needs_tenant_approval: [null],
  });

  get department() {
    return this.sectionFormGroup.get('department');
  }
  get representative() {
    return this.sectionFormGroup.get('representative');
  }
  get constructionSF() {
    return this.sectionFormGroup.get('construction_sqft');
  }
  get occupiedSF() {
    return this.sectionFormGroup.get('occupied_sqft');
  }
  get needsTenantApproval() {
    return this.sectionFormGroup.get('needs_tenant_approval');
  }

  get departmentIsValid() {
    return !this.department.invalid;
  }
  get representativeIsValid() {
    return !this.representative.invalid;
  }
  get sectionFormIsValid() {
    return this.department.value && this.departmentIsValid && this.representative.value && this.representativeIsValid;
  }

  async ngOnInit() {
    const fundingSourceTypeSortOrder = {
      [PEBFundingSourceTypeEnum.UHAT]: 1,
      [PEBFundingSourceTypeEnum.CAPX]: 2,
      [PEBFundingSourceTypeEnum.Sublease]: 3,
      [PEBFundingSourceTypeEnum._340B]: 4,
      [PEBFundingSourceTypeEnum.ARPA]: 5,
      [PEBFundingSourceTypeEnum.Reimbursement]: 6,
    };

    this.canEdit = this.inputData.canEdit;
    // TODO: PEB - also show funding source types where is_enabled = 0 if they are currently selected
    const fundingSourceTypes = await this.pebService
      .getPEBFundingSourceTypes(
        ['id', 'name', 'abbreviation', 'description', 'needs_tenant_approval'],
        [
          {
            type: 'field',
            field: 'is_enabled',
            value: '1',
          },
        ]
      )
      .toPromise();

    this.fundingSourceTypes = sortBy(fundingSourceTypes, (i) => {
      return fundingSourceTypeSortOrder[i.id];
    });
    for (const fs of this.fundingSourceTypes) {
      if (fs.needs_tenant_approval) {
        this.fundingSourceTenantApprovalRequiredIds.push(fs.id);
      }
    }

    this.sections = this.inputData.sections;
    if (this.canEdit && !this.sections.find((s) => s.isNew)) {
      this.sections.push({ isNew: true });
    }
    this.projectId = this.inputData.projectId;

    for (const s of this.sections) {
      if (!s.isNew) {
        s.selectedFundingSourceIds = s.funding_sources.map((fs) => fs.type_id);
        s.previouslySelectedFundingSourceIds = [...s.selectedFundingSourceIds];
      } else {
        s.selectedFundingSourceIds = [];
        s.previouslySelectedFundingSourceIds = [];
      }
    }

    const departmentFilter: APIFilter[] = [
      { type: 'field', field: 'id', value: '132', match: '!=' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'id', value: '133', match: '!=' },
    ];
    this.allDepartments = await this.locationService.getDepartments(['id', 'name'], departmentFilter).toPromise();

    if (this.inputData.sectionIdToSelect) {
      const sectionIndexToSelect = this.sections.findIndex((s) => s.tenant_id === this.inputData.sectionIdToSelect);
      if (sectionIndexToSelect > -1) {
        this.selectedSectionIndex = sectionIndexToSelect;
      }
    }

    this.selectedSectionIndex = this.selectedSectionIndex ?? this.sections?.length - 1;
    this.selectSection(this.sections[this.selectedSectionIndex]);

    this.isLoading = false;
    this.department.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm: any) => {
          if (searchTerm && searchTerm.id) {
            // check to see if department is already selected
            return of(null);
          } else {
            if (searchTerm) {
              return of(this.allDepartments.filter((d) => d.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())));
            } else {
              return of(this.allDepartments);
            }
          }
        })
      )
      .subscribe((departments: any) => {
        if (departments) {
          this.filteredDepartments = departments;
        } else {
          this.filteredDepartments = [];
        }
      });

    this.representative.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm && searchTerm.id) {
            // check to see if user is already selected
            return of(null);
          } else {
            if (searchTerm) {
              return this.userService.searchUsers([
                { type: 'field', field: 'email', value: searchTerm, match: 'any' },
                { type: 'operator', value: 'OR' },
                { type: 'field', field: 'full_name', value: searchTerm, match: 'any' },
              ]);
            } else {
              return of(this.users);
            }
          }
        })
      )
      .subscribe((users) => {
        if (users) {
          this.filteredRepresentatives = users;
        } else {
          this.filteredRepresentatives = [];
        }
      });
  }

  sectionTabChanged(selectEvent?) {
    if (selectEvent?.index > -1) {
      const sectionToSelect = this.sections[selectEvent.index];
      this.selectSection(sectionToSelect);
    }
  }

  selectSection(sectionToSelect: PEBSection) {
    const tenantReviewRequired = sectionToSelect.selectedFundingSourceIds.find((id) =>
      this.fundingSourceTenantApprovalRequiredIds.includes(id)
    );
    if (sectionToSelect?.isNew) {
      this.department.enable();
      this.representative.enable();
      this.department.setValue(null);
      this.representative.setValue(null);
      this.constructionSF.setValue(null);
      this.occupiedSF.setValue(null);
      this.needsTenantApproval.setValue(null);

      if (tenantReviewRequired) {
        this.needsTenantApproval.setValue(true);
      }
    } else if (sectionToSelect) {
      this.department.disable();
      this.representative.disable();
      const foundDepartment = this.allDepartments?.find((d) => d.id === sectionToSelect.department_id);
      this.department.setValue(foundDepartment);
      this.representative.setValue({
        id: sectionToSelect.representative_id,
        first_name: sectionToSelect.representative_first_name,
        last_name: sectionToSelect.representative_last_name,
      });

      this.constructionSF.setValue(sectionToSelect.construction_sqft);
      this.occupiedSF.setValue(sectionToSelect.occupied_sqft);
      this.needsTenantApproval.setValue(!!sectionToSelect.needs_tenant_approval);
    }

    if (tenantReviewRequired) {
      this.needsTenantApproval.disable();
    } else {
      this.needsTenantApproval.enable();
    }

    if (!this.canEdit) {
      this.constructionSF.disable();
      this.occupiedSF.disable();
    }
  }

  departmentValueMapper(department) {
    return department ? department.name : null;
  }

  async departmentChanged() {
    if (this.department.value && !this.department.value.id) {
      this.department.setErrors({ invalidDepartment: true });
    }
    if (this.department.value && (await this.isExistingTenant(this.department.value))) {
      this.department.setErrors({ alreadyExists: true });
    }
  }

  representativeValueMapper(representative) {
    return representative ? `${representative.first_name} ${representative.last_name}` : null;
  }

  representativeChanged() {
    if (this.representative.value && !this.representative.value.id) {
      this.representative.setErrors({ invalidRepresentative: true });
    }
  }

  // this functions checks if the preloaded list of existing tenants already includes the selected tenant
  private async isExistingTenant(department) {
    return this.sections.map((s) => s.department_id).includes(department.id);
  }

  fundingSourceChanged(section, fs: PEBFundingSourceType, isAdding) {
    const isAlreadySelected = section.selectedFundingSourceIds.indexOf(fs.id) > -1;
    if (isAdding) {
      if (!isAlreadySelected) {
        section.selectedFundingSourceIds.push(fs.id);

        if (fs.needs_tenant_approval) {
          this.needsTenantApproval.setValue(true);
          this.needsTenantApproval.disable();
        }
      }
    } else {
      if (isAlreadySelected) {
        remove(section.selectedFundingSourceIds, (fsid) => fsid === fs.id);
        if (!section.selectedFundingSourceIds.find((id) => this.fundingSourceTenantApprovalRequiredIds.includes(id))) {
          this.needsTenantApproval.enable();
        }
      }
    }
  }

  async submitPEBSection(sectionToSubmit) {
    // This custom function is used instead of this.sectionFormGroup.valid because disabled fields cannot be valid so the built in check will never be true for already created sections.
    let tenantId = sectionToSubmit?.tenant_id;

    if (this.sectionFormIsValid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus(`${sectionToSubmit.tenant_id ? 'Updating' : 'Creating'}: Tenant...`);
      sectionToSubmit.fundingSourcesToUpdate = [
        ...((sectionToSubmit.previouslySelectedFundingSourceIds?.length &&
          sectionToSubmit.selectedFundingSourceIds.filter(
            (fId) => !sectionToSubmit.previouslySelectedFundingSourceIds?.includes(fId)
          )) ||
          sectionToSubmit.selectedFundingSourceIds),
        ...((sectionToSubmit.previouslySelectedFundingSourceIds?.length &&
          sectionToSubmit.previouslySelectedFundingSourceIds.filter(
            (pId) => !sectionToSubmit.selectedFundingSourceIds.includes(pId)
          )) ||
          []),
      ];

      const tenantData: PEBSection = {
        construction_sqft: this.constructionSF?.value,
        occupied_sqft: this.occupiedSF?.value,
        needs_tenant_approval: this.needsTenantApproval?.value ? 1 : 0,
      };

      if (!tenantId) {
        tenantData.project_id = this.projectId;
        tenantData.department_id = this.department.value?.id;
        tenantData.representative_id = this.representative.value?.id;
        tenantData.type_id = 1;

        const createdTenant = await this.projectTenantService.createPEBSection(tenantData).toPromise();
        tenantId = createdTenant.id;
      } else {
        await this.projectTenantService.updatePEBSection(tenantId, tenantData).toPromise();
      }
      await this.projectTenantService
        .updateProjectTenantFundingSources(tenantId, sectionToSubmit.fundingSourcesToUpdate)
        .toPromise();
      this.progressIndicatorService.close();
      this.close(true);
    } else {
      this.snackbar.open(`Please fill out all required fields`);
    }
  }

  public close(shouldRefresh = false): void {
    this._dialogRef.close(shouldRefresh);
  }
}
