// Singleton pattern
// Only put modules that will only be import from the app module
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTInterceptor } from '../services/jwt-interceptor.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [HttpClientModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true }],
})
export class CoreModule {}
