<app-base-dialog title="{{ reviewType }} Review" (closeDialog)="cancel()"></app-base-dialog>
<mat-dialog-content class="request-review-dialog bg-shade-secondary position-relative p-4">
  <div class="flex-row d-flex align-items-stretch m-0 main-row position-relative">
    <div class="col align-self-stretch px-4 pb-5 pt-0">
      <app-file-preview [file]="selectedFile" *ngIf="selectedFile" style="height: 100% !important"> </app-file-preview>
      <app-file-upload-zone
        *ngIf="!selectedFile"
        class="full-width"
        [height]="'400px'"
        [parentResourceId]="project?.id"
        [parentResourceType]="ResourceType.Project"
        [taskId]="task.id"
        (filesUploaded)="openUploadFileModal($event)"
      >
      </app-file-upload-zone>
    </div>
    <div class="col-auto sidebar align-self-stretch pr-0 d-flex flex-column sidebar-scroll">
      <div class="d-flex flex-column">
        <div class="m-portlet mb-4">
          <div class="m-portlet__body d-flex flex-column p-3">
            <div class="d-flex align-items-top mb-3">
              <mat-icon color="accent" class="mr-1"> upload_file </mat-icon>
              <div class="d-flex flex-column">
                <h5 class="m-0 dkblue font-weight-semibold ml-2 mb-0">Files to Review</h5>
                <p class="dkgray ml-2 mb-0">What Needs to be reviewed?</p>
              </div>
            </div>
            <div class="d-flex align-items-center ml-5">
              <p *ngIf="!reviewFiles?.length" class="dkgray mb-0">Use the container on the left to add files</p>
              <mat-chip-list class="d-inline-block align-middle mt-2">
                <app-file-chip
                  *ngFor="let file of reviewFiles"
                  [file]="file"
                  [removable]="true"
                  [useParentPreview]="true"
                  [isSelected]="(selectedFile?.file_id || selectedFile?.id) === (file.file_id || file.id)"
                  (removeEvent)="removeFile(file)"
                  (parentPreviewFile)="updateSelectedFile(file)"
                ></app-file-chip>
                <button
                  mat-button
                  color="accent"
                  type="button"
                  class="lh-30"
                  (click)="openUploadFileModal()"
                  *ngIf="reviewFiles?.length"
                  [disabled]="disableReviewOptions || !isTaskReview"
                >
                  + Files
                </button>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="m-portlet mb-4">
          <div class="m-portlet__body d-flex flex-column p-3">
            <div class="d-flex mb-3">
              <mat-icon color="accent" class="mr-1"> group_add </mat-icon>
              <div class="d-flex flex-column ml-2">
                <h5 class="m-0 dkblue font-weight-semibold text-truncate mr-auto">Reviewers</h5>
                <p class="dkgray m-0">Who needs to look at this? Drag to reorder.</p>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div
                *ngIf="reviewChain"
                cdkDropList
                cdkDropListOrientation="vertical"
                class="col-12 pl-0 pr-0"
                [ngClass]="{ opacity5: disableReviewOptions }"
                (cdkDropListDropped)="drop($event)"
              >
                <div
                  cdkDrag
                  [cdkDragDisabled]="disableReviewOptions"
                  class="d-flex flex-column reviewers mb-3 draggable"
                  *ngFor="let reviewer of reviewChain; let i = index"
                >
                  <div class="d-flex align-items-center">
                    <app-profile-thumbnail
                      class="pointer pr-2"
                      (click)="openUserProfilePreview(reviewer?.id)"
                      [matTooltip]="reviewer.first_name + ' ' + reviewer.last_name"
                      [width]="34"
                      [height]="34"
                      [userId]="reviewer?.id"
                    ></app-profile-thumbnail>
                    <div class="d-flex flex-column mr-auto text-truncate">
                      <p class="dkblue font-weight-semibold m-0 text-truncate lh-16">
                        {{ reviewer.first_name }} {{ reviewer.last_name }}
                      </p>
                      <h6 class="font-weight-normal dkgray m-0 text-truncate lh-16">
                        {{ reviewer.title || 'No Title' }}
                      </h6>
                    </div>
                    <!--                    Uncomment once this is ready to be merged with digital signatures-->
                    <!--                    <div class="d-flex flex-column ml-3 align-items-end">-->
                    <!--                      <mat-checkbox-->
                    <!--                        class="ltblue"-->
                    <!--                        style="font-size: 12px"-->
                    <!--                        color="accent"-->
                    <!--                        (change)="toggleSignature(reviewer)"-->
                    <!--                        [checked]="reviewer.needs_signature"-->
                    <!--                        [disabled]="disableReviewOptions"-->
                    <!--                      >-->
                    <!--                        <span class="font-weight-semibold mr-3"> Signature </span>-->
                    <!--                      </mat-checkbox>-->
                    <!--                    </div>-->
                    <mat-icon class="remove-reviewer bg-white br-50" (click)="removeReviewer(reviewer.id)"
                      >cancel</mat-icon
                    >
                  </div>
                  <div class="d-flex full-width p-5 mb-3 bg-shade-ltblue border-none br-10" *cdkDragPlaceholder></div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  mat-flat-button
                  type="button"
                  color="success"
                  class="ml-5"
                  (click)="addReviewers()"
                  *ngIf="!reviewChain?.length"
                  [disabled]="disableReviewOptions"
                >
                  + Reviewers
                </button>
                <button
                  mat-button
                  color="success"
                  type="button"
                  (click)="addReviewers()"
                  *ngIf="reviewChain?.length"
                  [disabled]="disableReviewOptions"
                >
                  + Reviewers
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="m-portlet mb-4">
          <div class="m-portlet__body d-flex flex-column p-3">
            <div class="d-flex mb-3">
              <mat-icon color="accent" class="mr-1"> more_time </mat-icon>
              <div class="d-flex flex-column ml-2">
                <h5 class="m-0 dkblue font-weight-semibold text-truncate mr-auto">Review Turnaround</h5>
                <p class="dkgray">How long should each reviewer have to complete?</p>
              </div>
            </div>
            <div class="d-flex pl-5">
              <mat-form-field appearance="outline">
                <mat-select
                  matNativeControl
                  (selectionChange)="updateTurnaround($event.value)"
                  [value]="task?.review_turnaround"
                  [disabled]="disableReviewOptions"
                >
                  <mat-option [value]="0"> Same Day</mat-option>
                  <mat-option [value]="1"> 1 Day</mat-option>
                  <mat-option [value]="3"> 3 Days</mat-option>
                  <mat-option [value]="7"> 1 Week</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center full-width mt-auto mb-0">
          <button (click)="cancel()" mat-button type="button" class="gray flex-fill mr-4">Cancel</button>
          <button
            mat-flat-button
            type="submit"
            color="accent"
            class="flex-fill"
            (click)="submit()"
            [disabled]="disableReviewOptions"
          >
            {{ this.reviewType }} Review
          </button>
        </div> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex align-items-center full-width mb-0">
    <button (click)="cancel()" mat-button type="button" class="gray ml-auto">Cancel</button>
    <button
      mat-flat-button
      type="submit"
      color="accent"
      class="ml-3 px-4"
      (click)="submit()"
      [disabled]="disableReviewOptions"
    >
      {{ this.reviewType }} Review
    </button>
  </div>
</mat-dialog-actions>
