<div #mainScreen (window:resize)="onResize($event)" class="container-fluid app-container p-4">
  <div class="row align-items-center">
    <div class="col">
      <h3 data-test-id="reminders-headline" id="title" class="dkblue font-weight-semibold">Reminders</h3>
    </div>
    <div class="col-auto">
      <button mat-flat-button color="success" (click)="openAddReminderDialog()">+ Reminder</button>
    </div>
  </div>
  <br />
  <div class="row list-filters align-items-center">
    <div class="col-xl-2 col-lg-4 col-md-6">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [(ngModel)]="selectedReminderStatus" (selectionChange)="refresh()">
          <mat-option *ngFor="let rs of reminderStatuses" [value]="rs.id">
            {{ rs.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-5 col-md-6 pl-0">
      <mat-form-field appearance="outline" class="full-width">
        <!-- <mat-icon class="ltgray" matPrefix>search</mat-icon> -->
        <input matInput [(ngModel)]="searchTerm" placeholder="Search Reminders" />
        <mat-icon *ngIf="searchTerm" (click)="searchTerm = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
        <!-- <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" class="ltgray" (click)="searchTerm=''">
                <mat-icon>close</mat-icon>
              </button> -->
      </mat-form-field>
    </div>
    <div *ngIf="showIpad" class="col-md-2 pl-0">
      <!-- <button mat-button color="accent">
        <span>
          Sort
        </span>
        <mat-icon>
          expand_more
        </mat-icon>
      </button> -->
    </div>
  </div>
  <br /><br />
  <!-- <div class="m-content col"> -->
  <!-- <mat-tab-group color="accent" dynamicHeight>
      <mat-tab [label]="rs.name" *ngFor="let rs of reminderStatuses"> -->
  <div class="portlet-table-header p-0 pl-3 pr-4">
    <div class="row">
      <div class="col-auto" style="width: 55px">
        <label class="main-label">
          Status
          <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                        class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col">
        <label class="main-label">
          <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-4">
        <label class="main-label">
          Date/Time
          <!-- <span *ngIf="sortProperty === 'reminder_datetime'">
                  <i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                </span> -->
        </label>
      </div>
      <!-- <div class="col-1">
        <label class="main-label">
          Priority
        </label>
      </div> -->
      <div class="col-auto" style="width: 150px"></div>
    </div>
  </div>
  <div class="m-portlet reminder-portlet m-portlet--mobile">
    <div class="m-portlet__body item pl-4 pt-2 pb-2 pr-4 ease" *ngFor="let r of reminders[selectedReminderStatus]">
      <div class="row align-items-center">
        <div class="col-auto" style="width: 55px">
          <button class="d-inline-block complete-reminder align-middle" mat-icon-button>
            <mat-icon
              class="ltgray"
              *ngIf="r.status_id === 1"
              (click)="changeReminderStatus(r.id, 2)"
              matTooltip="Mark Complete"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >radio_button_unchecked</mat-icon
            >
            <mat-icon
              class="green"
              *ngIf="r.status_id === 2"
              (click)="changeReminderStatus(r.id, 1)"
              matTooltip="Mark Not Started"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              >check</mat-icon
            >
          </button>
        </div>
        <div class="col pl-0">
          <h6 class="dkblue m-0 font-weight-semibold pointer d-inline-block align-middle">
            {{ r.description }}
          </h6>
          <p
            [routerLink]="[getParentLink(r)]"
            *ngIf="r.parent_id"
            class="m-0 ml-4 ltblue d-inline-block align-middle agenda-parent pointer ease"
            matTooltip="Go to Item"
            matTooltipPosition="above"
            [matTooltipShowDelay]="100"
          >
            {{ r.parent_name }}
          </p>
        </div>
        <div class="col-4">
          <div class="d-inline-block align-top" *ngIf="r.reminder_datetime">
            <p class="main-label dkblue font-weight-normal m-0">
              <span class="font-weight-semibold mr-2"> Reminder: </span>
              {{ r.reminder_datetime | date: 'EEEE, MMMM d' }} •
              {{ r.reminder_datetime | date: 'h:mm aa' }}
            </p>
            <br *ngIf="r.due_datetime" />
            <p *ngIf="r.due_datetime" class="main-label red font-weight-normal m-0">
              <span class="font-weight-semibold mr-2"> Due Date: </span>
              {{ r.due_datetime | date: 'EEEE, MMMM d' }} • {{ r.due_datetime | date: 'h:mm aa' }}
            </p>
          </div>
        </div>
        <div class="col-auto text-right" style="width: 150px">
          <h6 class="m-0 mr-2 d-inline-block align-middle">
            <span
              class="badge white text-uppercase"
              [ngClass]="{
                'bg-red': r.priority_id === 1,
                'bg-ltblue': r.priority_id === 2,
                'bg-ltgray': r.priority_id === 3
              }"
            >
              {{ r.priority_name }}
            </span>
          </h6>
          <button
            mat-icon-button
            [matMenuTriggerFor]="reminder_menu"
            class="ltgray"
            matTooltip="More"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #reminder_menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openEditReminderDialog(r)" class="dkblue">
              <mat-icon>edit</mat-icon>
              Edit Reminder
            </button>
            <button mat-menu-item (click)="deactivateReminder(r.id)">
              <mat-icon>delete</mat-icon>
              Delete Reminder
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- <div class="row" *ngIf="openReminder.id === r.id">
        <div class="col-12">
          <h6 class="font-weight-semibold" style="margin-bottom:12px">Notes</h6>
          <div class="row">
            <div class="col-auto">
            </div>
            <div class="col">
              <div class="note-input">
                <button mat-icon-button class="ltgray post-note" (click)="createNoteOnReminder(noteToPost, r.id)"
                  matTooltip="Post Note" matTooltipPosition="above" [matTooltipShowDelay]="300">
                  <mat-icon>send</mat-icon>
                </button>
                <textarea class="ease full-width note-area" [(ngModel)]="noteToPost" placeholder="Write a Note..." rows="1"
                  (keydown.enter)="$event.preventDefault(); createNoteOnReminder(noteToPost, r.id)"></textarea>
              </div>
            </div>
          </div>
          <div class="row note" *ngFor="let n of shownNotes">
            <div class="col-auto">
              <div class="notes-avatar">
                <img [src]="getProfileThumbnailUrl(n.created_by_id)">
              </div>
            </div>
            <div class="col">
              <div>
                <h6 class="margin-0 dkblue d-inline-block font-weight-bold">{{n.created_by_first_name}}{{n.created_by_first_name
                  && n.created_by_first_name ? ' ' : ''}}{{n.created_by_last_name}}
                  &nbsp;
                  <small class="gray">
                    {{n.created_datetime | date:'MM/dd/yyyy - h:mma' |
                    lowercase}}
                  </small>
                </h6>
              </div>
              <div>
                <h6 class="dkblue margin-0">
                  {{n.message}}
                </h6>
              </div>
            </div>
          </div>
          <div class="full-width">
            <button *ngIf="shownNotes && shownNotes.length >= 5" mat-button class="full-width ltblue" [disabled]="!notesCursor"
              (click)="getMoreNotes()">View
              More</button>
          </div>
        </div>
      </div> -->
      <!-- <div class="row">
              <div class="col-12 hover-none" *ngIf="openReminder.id === r.id">
                <button mat-icon-button class="m-dropdown__toggle post-note" (click)="createNoteOnReminder(noteToPost, r.id)"
                  color="accent" matTooltip="Post Note" matTooltipPosition="above" [matTooltipShowDelay]="300">
                  <mat-icon>arrow_downward</mat-icon>
                </button>
                <input class="form-control" placeholder="Add a Note about this Reminder" type="text" [(ngModel)]="noteToPost"
                  (keydown.enter)="$event.preventDefault(); createNoteOnReminder(noteToPost, r.id)">
                <div class="note" *ngFor="let n of shownNotes">
                  <div class="notes-avatar table-avatar">
                  <img [src]="getProfileThumbnailUrl(n.created_by_id)">
                  </div>
                  <div class="notes-content">
                    <div>
                      <h5 class="d-inline-block font-weight-semibold">{{n.created_by_first_name}}{{n.created_by_first_name
                        && n.created_by_first_name ? ' ' : ''}}{{n.created_by_last_name}}</h5>
                      &nbsp;
                      <p class="d-inline-block ltblue">{{n.created_datetime | date:'MM/dd/yyyy h:mma' | lowercase}}</p>
                    </div>
                    <div style="margin-top:-15px;">
                      <p style="margin-bottom:0">{{n.message}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
    </div>
  </div>
  <!-- </mat-tab>
    </mat-tab-group> -->
  <!-- </div> -->
</div>
