<app-base-dialog [title]="'Assign Task'" (closeDialog)="close()"></app-base-dialog>
<div class="project-modal modal-wide">
  <div class="row mb-5 align-items-top">
    <div class="col">
      <h6 class="mb-2 font-weight-semibold dkblue">Task Title</h6>
      <h5 class="m-0 dkblue font-weight-normal">
        {{ task.title }}
      </h5>
    </div>
    <div *ngIf="task.id" class="col">
      <h6 class="mb-1 font-weight-semibold dkblue">Assignee</h6>
      <span class="d-flex align-items-center">
        <app-profile-thumbnail
          [width]="24"
          [height]="24"
          [userId]="task.assigned_user_id"
          class="mr-2"
        ></app-profile-thumbnail>
        <h5 *ngIf="task.assigned_user_first_name" class="m-0 dkblue font-weight-normal">
          {{ task.assigned_user_first_name }} {{ task.assigned_user_last_name }}
        </h5>
        <h5 *ngIf="!task.assigned_user_first_name" class="m-0 gray font-weight-normal">Not Assigned</h5>
      </span>
    </div>
  </div>
  <div class="row mb-3 align-items-center">
    <div class="col">
      <button
        class="mr-1"
        *ngIf="searchUserType !== userType.Staff"
        (click)="clickUserTypeButton($event, userType.Staff)"
        mat-button
        color="accent"
      >
        UHAT/1Call
      </button>
      <button
        class="mr-1"
        *ngIf="searchUserType === userType.Staff"
        mat-flat-button
        (focusin)="changeSearchUserType(userType.Staff)"
        color="accent"
      >
        UHAT/1Call
      </button>
      <button
        class="mr-1"
        *ngIf="searchUserType !== userType.Tenant"
        (click)="clickUserTypeButton($event, userType.Tenant)"
        mat-button
        color="accent"
      >
        Tenants
      </button>
      <button class="mr-1" *ngIf="searchUserType === userType.Tenant" mat-flat-button color="accent">Tenants</button>
      <button
        *ngIf="searchUserType !== userType.Vendor"
        (click)="clickUserTypeButton($event, userType.Vendor)"
        mat-button
        color="accent"
      >
        Suppliers
      </button>
      <button *ngIf="searchUserType === userType.Vendor" mat-flat-button color="accent">Suppliers</button>
    </div>
    <div class="col-auto text-right">
      <button mat-flat-button color="success" (click)="assignSelf()">Assign to me</button>
      <button
        mat-stroked-button
        class="ml-3"
        color="accent"
        *ngIf="!task || !task.id || task.assigned_user_id != null"
        (click)="clearAssignment()"
      >
        Unassign
      </button>
    </div>
  </div>
  <div class="row assign-task-form">
    <div class="col">
      <!-- Search form -->
      <mat-form-field appearance="outline" class="full-width">
        <input
          matInput
          #searchTextInput
          [matAutocompleteDisabled]="!searchInput || searchInput.length <= 0"
          [matAutocomplete]="userAuto"
          [(ngModel)]="searchInput"
          placeholder="Who will complete this task?"
          (keyup)="inputKeyUp($event)"
        />
        <mat-icon *ngIf="searchInput" (click)="searchInput = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>

      <mat-autocomplete #userAuto="matAutocomplete" [displayWith]="mapper">
        <mat-option
          [value]="user"
          *ngFor="
            let user of users$
              | async
              | userSearchFilter: searchUserType:searchInput:companyIds:tenantRepIds:tenantContactIds
          "
          (onSelectionChange)="userSelected(user)"
        >
          <span class="d-flex align-items-center" *ngIf="user">
            <p class="dkblue m-0 mr-auto lh-16 text-nowrap">{{ user.first_name }} {{ user.last_name }}</p>
            <p class="dkgray m-0 ml-3 lh-16 text-nowrap text-truncate">
              <small>
                {{ user.company_name || user.department_name | stringShrink: 45 }}
              </small>
            </p>
          </span>
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
</div>
