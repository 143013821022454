import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NotificationDialogInjectionData } from 'src/app/types';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent implements OnInit {
  public textAreaValue: string; // Binding for textArea box

  public titleBarText = 'Notification';

  public headerText: string;

  public notificationButtonText = 'Dismiss';

  public descriptionText: string;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationDialogInjectionData
  ) {}

  ngOnInit() {
    if (this.data.notificationButtonText) {
      this.notificationButtonText = this.data.notificationButtonText;
    }
    if (this.data.descriptionText) {
      this.descriptionText = this.data.descriptionText;
    }
    if (this.data.headerText) {
      this.headerText = this.data.headerText;
    }
    if (this.data.titleBarText) {
      this.titleBarText = this.data.titleBarText;
    }
  }

  continue() {
    this.dialogRef.close(true);
  }
}
