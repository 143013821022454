<app-base-dialog [title]="dialogTitle" [draggable]="false" (closeDialog)="close()"></app-base-dialog>
<div id="file-rename-dialog" data-test-id="file-rename-dialog" class="file-rename-dialog">
  <div class="row mt-4 file-row">
    <div class="col">
      <label class="main-label dkblue mb-3"> File Name </label>
      <div class="row align-items-center mb-4 pb-2">
        <div class="col">
          <mat-form-field class="full-width m-0" appearance="outline">
            <input
              class="pr-5"
              matInput
              #val
              [ngModel]="displayName"
              placeholder="File Name"
              (ngModelChange)="checkName($event)"
            />

            <h5 matSuffix class="ltblue">{{ extension }}</h5>
          </mat-form-field>
        </div>
        <div class="col-auto text-right">
          <i
            *ngIf="isVerified"
            class="material-icons green pointer ease file-icon"
            matTooltip="File name available"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            >check</i
          >
          <i
            *ngIf="!isVerified"
            class="material-icons red pointer ease file-icon"
            matTooltip="File name already exists"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            >error</i
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <label class="main-label dkblue mb-2"> Primary Tags </label>
      <div class="row">
        <div class="col">
          <span
            *ngFor="let tag of primaryTags"
            matRipple
            class="
              file-tag
              border
              ease
              d-inline-block
              align-middle
              font-weight-semibold
              pointer
              pl-2
              pr-2
              bg-dkblue
              white
              mr-3
              mt-2
              mb-2
            "
            [ngClass]="{
              'bg-dkblue white': tag.is_selected,
              dkblue: !tag.is_selected
            }"
            (click)="togglePrimaryTag(tag)"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5" *ngIf="possibleSecondaryTags.length > 0">
    <div class="col">
      <label class="main-label dkblue mb-2"> Secondary Tags </label>
      <div class="row">
        <div class="col">
          <span
            *ngFor="let tag of possibleSecondaryTags"
            matRipple
            class="
              file-tag
              border
              ease
              d-inline-block
              align-middle
              font-weight-semibold
              pointer
              pl-2
              pr-2
              mr-3
              mt-2
              mb-2
            "
            [ngClass]="{
              'bg-ltblue white': tag.is_selected,
              ltblue: !tag.is_selected
            }"
            (click)="toggleSecondaryTag(tag)"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 mb-2 align-items-center">
    <div class="col">
      <p class="m-0 gray font-italic text-wrap text-break">
        <span class="font-weight-semibold mr-2"> Editing: </span>
        {{ file.name }}
      </p>
    </div>
    <div class="col-auto text-right">
      <button mat-button (click)="close()" class="gray mr-2">Cancel</button>
      <button mat-flat-button color="success" (click)="submit()">Save Changes</button>
      <!-- <div *ngIf="processing" style="height: 2em; float: right; display: inline-table; padding: 5px 10px; border-radius: 5px; opacity: .7">
        <div style="float: left; margin-right: 1.6em; width: 2em; height: 2em;" class="spinner-grow" role="status">
          <span class="sr-only">Processing...</span>
        </div>
        <span style="vertical-align: sub; font-size: 1.5em;">{{processingStatus}}.. {{processingPercentage ?
          processingPercentage + '%' : ''}}</span>
      </div> -->
    </div>
  </div>
</div>
