import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  ExportService,
  ModalService,
  ModuleService,
  ProgressIndicatorService,
  SidenavService,
  TimeLogService,
  UserService,
} from 'src/app/services';
import { cloneDeep, difference, isEqual, uniqWith } from 'lodash';

import { ConfirmationDialogComponent, DatepickerHeaderComponent, TimeLogDialogComponent } from 'src/app/components';
import { APIFilter, TimeLog, TimeLogActivity, User } from 'src/app/types';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DateRange } from 'src/app/enums';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-time-log-panel',
  templateUrl: './time-log-panel.component.html',
  styleUrls: ['./time-log-panel.component.scss'],
})
export class TimeLogPanelComponent implements OnInit, OnDestroy {
  @ViewChild('workspaceSelect') workspaceSelect: MatSelect;
  public allWorkspacesSelected = false;
  public noWorkspaceSelected = false;
  @ViewChild('userSelect') userSelect: MatSelect;
  public allUsersSelected = true;
  public myTimeSelected = false;
  @ViewChild('activitySelect') activitySelect: MatSelect;
  public allActivitiesSelected = true;
  @ViewChild('companySelect') companySelect: MatSelect;
  public allCompaniesSelected = true;

  customHeader = DatepickerHeaderComponent;

  public dataLoaded = false;
  public loadingItems = false;

  timeFrame: DateRange = DateRange.Daily;
  public dateRange: {
    date: string;
    hours: string;
    realDate: moment.Moment;
    realEndDate?: moment.Moment;
    isToday?: boolean;
  }[] = [];
  private dailyDateOffset = 0;
  private weeklyDateOffset = 0;
  private quarterlyDateOffset = 0;
  private NUM_DAY_RANGE = 7;
  private NUM_WEEK_RANGE = 4;
  private NUM_MONTH_RANGE = 3;
  public currentSelectedDate: { date: string; hours: string; realDate: moment.Moment; realEndDate?: moment.Moment };
  public maxDisplayedEntries = 10;

  private allEntries: TimeLog[] = [];
  public displayedEntries: TimeLog[] = [];
  public rangeData: {
    date: string;
    hours: string;
    entries: TimeLog[];
    totalMinutes: number;
  }[] = [];
  public timeForDateRange = '';
  public selectedWorkspaces = [];
  public selectedUsers = [];
  public selectedActivities = [];
  public selectedCompanies = [];

  private currentSubscription;

  private allUsers: User[] = [];
  private allActivities: TimeLogActivity[] = [];
  private allCompanies: { id: number; name: string }[];
  private allUsersAndModules: any[] = [];
  loading = false;
  timer;
  timeLogsSubscription;

  public get ptoActivityIds(): number[] {
    return (
      this.allActivities?.filter((unfilteredActivity) => unfilteredActivity.is_pto).map((activity) => +activity.id) ??
      []
    );
  }
  public get visibleWorkspaces() {
    return this.moduleService?.userWorkspaces;
  }
  public get visibleUsers() {
    return this.allUsers.filter((user) =>
      this.allUsersAndModules
        .filter((e) => this.selectedWorkspaces.map((ws) => ws.id).includes(e.module_id))
        .map((e) => +e.id)
        .includes(+user.id)
    );
  }
  public get DateRange() {
    return DateRange;
  }
  public get selectedUsersLength() {
    return +this.selectedUsers.length;
  }
  public get visibleActivities() {
    return this.allActivities;
  }
  public get visibleCompanies() {
    return this.allCompanies;
  }
  public get currentDate() {
    return this.currentSelectedDate;
  }
  public get dateRangeString() {
    if (this.dateRange.length > 0) {
      return `${this.dateRange[0].realDate.format(
        [DateRange.Daily, DateRange.Weekly].includes(this.timeFrame) ? 'MMM Do' : 'MMMM'
      )} - ${this.dateRange[this.dateRange.length - 1][
        this.timeFrame === DateRange.Weekly ? `realEndDate` : `realDate`
      ].format([DateRange.Daily, DateRange.Weekly].includes(this.timeFrame) ? 'MMM Do' : 'MMMM')}`;
    } else if (this.dateRange.length === 1) {
      // this case shouldn't ever happen, but it's here just in case the way the dateRange changes down the road
      return `${this.dateRange[0].realDate.format(this.timeFrame === DateRange.Daily ? 'MMM Do' : 'MMMM')}`;
    } else {
      return 'Invalid Range';
    }
  }
  public get displayedCurrentDate() {
    if (this.timeFrame === DateRange.Daily && this.currentSelectedDate) {
      return this.currentSelectedDate.realDate.format('dddd, MMMM Do');
    } else if (this.timeFrame === DateRange.Monthly && this.currentSelectedDate) {
      return this.currentSelectedDate.date;
    } else {
      return 'Invalid Date';
    }
  }
  // only app admin/module admin/cfmo/workspace admin/creator can edit/delete time log, so check the first four roles here
  // canImpersonateTenant matches these 4 roles so I'm using it here
  public get isAppAdmin() {
    return this.authService.canImpersonateTenant;
  }

  constructor(
    private sidenavService: SidenavService,
    private timeLogService: TimeLogService,
    private moduleService: ModuleService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    // keep track of subscription so we can destroy it when the component is destroyed
    this.currentSubscription = this.timeLogService.openTimeLogPanelEvent.subscribe(async () => {
      if (!this.dataLoaded) {
        await this.initData();
      } else {
        this.refreshData();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
    if (this.timeLogsSubscription) {
      this.timeLogsSubscription.unsubscribe();
    }
  }

  // general drawer visibility functions
  public closePanel() {
    this.timeLogService.timeLogPanelIsOpen = false;
  }
  public isPanelOpen() {
    return this.timeLogService.timeLogPanelIsOpen;
  }
  public isSidenavOpen() {
    return !this.sidenavService.isSidenavClosed;
  }

  public get momentDateRange() {
    return this.timeFrame === DateRange.Daily ? 'day' : this.timeFrame === DateRange.Monthly ? 'month' : 'isoWeek';
  }

  // get time logs for a specified range to avoid loading all data into the frontend
  // uses a 500ms timer to avoid multiple calls to the backend
  // switched to an observable + callback to be able to cancel duplicate requests
  getLogEntries(callback) {
    if (this.timeLogsSubscription) {
      this.timeLogsSubscription.unsubscribe();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.loading = true;
    this.timer = setTimeout(() => {
      const startTime = this.dateRange[0].realDate.clone().startOf(this.momentDateRange);
      const endTime = this.dateRange[this.dateRange.length - 1].realDate.clone().endOf(this.momentDateRange);
      const timeLogFilter: APIFilter[] = [
        { type: 'field', field: 'date_worked', value: startTime.format('YYYY-MM-DD HH:mm:ss'), match: '>=' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'date_worked', value: endTime.format('YYYY-MM-DD HH:mm:ss'), match: '<=' },
      ];
      this.timeLogsSubscription = this.timeLogService
        .getTimeLogs(timeLogFilter)
        .pipe(
          tap((entries) => {
            this.allEntries = entries;
            callback(entries);
          }),
          finalize(() => {
            clearTimeout(this.timer);
            this.timeLogsSubscription.unsubscribe();
            this.loading = false;
          })
        )
        .subscribe();
    }, 500);
  }

  // called when daily/monthly is swapped
  public setDateRange(range: DateRange) {
    this.timeFrame = range;
    const tempRange: {
      date: string;
      hours: string;
      realDate: moment.Moment;
      realEndDate?: moment.Moment;
      isToday?: boolean;
    }[] = [];
    if (range === DateRange.Daily) {
      // we are having the weeks start on Monday rather than Sunday, so add an extra day here
      const startDate = moment().startOf('week').add(1, 'day').add(this.dailyDateOffset, 'day');
      for (let i = 0; i < this.NUM_DAY_RANGE; i++) {
        tempRange.push({
          date: startDate.format('ddd D'),
          hours: '',
          realDate: startDate.clone(),
          isToday: startDate.isSame(new Date(), 'day'),
        });
        startDate.add(1, 'day');
      }
    } else if (range === DateRange.Weekly) {
      const startDate = moment().isoWeekday(1).add(this.weeklyDateOffset, 'week');
      for (let i = 0; i < this.NUM_WEEK_RANGE; i++) {
        tempRange.push({
          date: `${startDate.format('DD MMM')} - ${moment(startDate).add(6, 'days').format('DD MMM')}`,
          hours: '',
          realDate: startDate.clone(),
          realEndDate: startDate.clone().add(6, 'days'),
          isToday: startDate.isSame(new Date(), 'isoWeek'),
        });
        startDate.add(1, 'week');
      }
    } else if (range === DateRange.Monthly) {
      const startDate = moment().startOf('quarter').add(this.quarterlyDateOffset, 'month');
      for (let i = 0; i < this.NUM_MONTH_RANGE; i++) {
        tempRange.push({
          date: startDate.format('MMMM YYYY'),
          hours: '',
          realDate: startDate.clone(),
          isToday: startDate.isSame(new Date(), 'month'),
        });
        startDate.add(1, 'month');
      }
    }
    this.dateRange = tempRange;

    // if the selected date doesn't exist, or falls out of the dateRange, set to the middle displayed item
    if (!this.currentSelectedDate || !this.dateRange.map((d) => d.date).includes(this.currentSelectedDate.date)) {
      // set the time range
      let todayFound = false;
      for (const rangeToTest of this.dateRange) {
        const startTime = rangeToTest.realDate.clone().startOf(this.momentDateRange);
        const endTime = rangeToTest.realDate.clone().endOf(this.momentDateRange);

        // select today if that is an option
        const today = moment();
        if (today.isBetween(startTime, endTime) || today.isSame(startTime) || today.isSame(endTime)) {
          this.currentSelectedDate = rangeToTest;
          todayFound = true;
        }
      }
      // otherwise select the middle day
      if (!todayFound) {
        this.currentSelectedDate = this.dateRange[Math.ceil(this.dateRange.length / 2.0) - 1];
      }
    }

    this.writeFilters('range');
  }

  // returns the correct color for the time log entry based on activity
  public getCSSClass(activity_id: number) {
    return {
      dkgreen: activity_id === 10,
      dkorange: activity_id === 7,
      red: activity_id === 9,
      dkblue: activity_id !== 10 && activity_id !== 7 && activity_id !== 9,
    };
  }

  // called when choosing a specific date range
  public selectDate(dateItem) {
    this.currentSelectedDate = dateItem;
    this.setDateRange(this.timeFrame);
    this.recalculateData();

    // when selecting date, if there are more than 50 entries, set the maxDisplayed to 10 to reduce lag
    if (this.displayedEntries.length > 50 && !this.maxDisplayedEntries) {
      this.maxDisplayedEntries = 10;
    }
  }

  public goToToday() {
    if (this.timeFrame === DateRange.Daily) {
      this.dailyDateOffset = 0;
    } else if (this.timeFrame === DateRange.Monthly) {
      this.quarterlyDateOffset = 0;
    } else if (this.timeFrame === DateRange.Weekly) {
      this.weeklyDateOffset = 0;
    }
    this.currentSelectedDate = {
      date: moment().startOf('day').format('MMM D'),
      hours: '',
      realDate: moment().startOf('day'),
    };
    this.renderPage();

    // when selecting date, if there are more than 50 entries, set the maxDisplayed to 10 to reduce lag
    if (this.displayedEntries.length > 50 && !this.maxDisplayedEntries) {
      this.maxDisplayedEntries = 10;
    }
  }

  // when clicking the chevron, offset the appropriate date
  public async offsetDateBackwards() {
    if (this.timeFrame === DateRange.Daily) {
      this.dailyDateOffset -= this.NUM_DAY_RANGE;
    } else if (this.timeFrame === DateRange.Monthly) {
      this.quarterlyDateOffset -= this.NUM_MONTH_RANGE;
    } else if (this.timeFrame === DateRange.Weekly) {
      this.weeklyDateOffset -= this.NUM_WEEK_RANGE;
    }
    await this.renderPage();
  }

  public async offsetDateForwards() {
    if (this.timeFrame === DateRange.Daily) {
      this.dailyDateOffset += this.NUM_DAY_RANGE;
    } else if (this.timeFrame === DateRange.Monthly) {
      this.quarterlyDateOffset += this.NUM_MONTH_RANGE;
    } else if (this.timeFrame === DateRange.Weekly) {
      this.weeklyDateOffset += this.NUM_WEEK_RANGE;
    }
    this.renderPage();
  }

  renderPage(timeFrame?: DateRange) {
    this.rangeData = [];
    this.displayedEntries = [];
    this.setDateRange(timeFrame ? timeFrame : this.timeFrame);
    this.getLogEntries(() => this.recalculateData());
  }

  // loads the initial data when the app is first served
  private async initData() {
    // load the initial data for the dropdowns
    this.allCompanies = await this.timeLogService.getTimeLogCompanies().toPromise();
    this.allActivities = await this.timeLogService.getTimeLogActivities().toPromise();
    this.allUsersAndModules = await this.timeLogService.getUsers().toPromise();
    this.allUsers = uniqWith(
      this.allUsersAndModules.map((u) => ({ id: u.id, first_name: u.first_name, last_name: u.last_name })),
      (a, b) => a.id === b.id
    );

    this.currentSelectedDate = {
      date: moment().startOf('day').format('MMM D'),
      hours: '',
      realDate: moment().startOf('day'),
    };
    // set the filters for selected WS/users/activities/companies/range
    this.initFilters();

    this.renderPage();

    // let the page know all data has been initially loaded (this removed the loading message)
    this.dataLoaded = true;
  }

  // this checks if any changes have occurred with the data, and makes a swap in-place as needed
  private async refreshData() {
    // load the initial data for the dropdowns
    this.getLogEntries((entries) => {
      // save the old calculated data
      const oldCalculatedData = cloneDeep(this.displayedEntries).map((u) => u.id);

      // refresh the data
      this.allEntries = entries;

      // recalculate
      this.recalculateData();

      const newData = this.displayedEntries.map((u) => u.id);
      // if there's a difference, say something
      if (difference(oldCalculatedData, newData).length > 0 || difference(newData, oldCalculatedData).length > 0) {
        this.snackBar.open('New data loaded for time log drawer!');
      }
    });
  }

  // anytime the filters are changed, check if any displayed data changes and update accordingly
  private recalculateData() {
    this.displayedEntries = [];
    this.rangeData = [];
    // filter all entries by WS/staff/activity/company filters
    const entries = cloneDeep(this.allEntries).filter(
      (e) =>
        this.selectedWorkspaces.map((ws) => ws.id).includes(e.module_id) &&
        this.selectedUsers.map((u) => u.id).includes(e.worker_id) &&
        this.selectedActivities.map((a) => a.id).includes(e.activity_id) &&
        this.selectedCompanies.map((c) => c.id).includes(e.company_id)
    );

    // sort the entries by time
    entries.sort((a, b) => {
      return moment(a.date_worked).isSameOrBefore(b.date_worked) ? 1 : -1;
    });

    let totalTimeForRange = 0;
    const todaysEntries: TimeLog[] = [];

    for (const date of this.dateRange) {
      let rangeMinutesTotal = 0;
      const startTime = date.realDate.clone().startOf(this.momentDateRange);
      const endTime = date.realDate.clone().endOf(this.momentDateRange);

      for (const entry of entries) {
        const dateWorked = moment(entry.date_worked);
        if (dateWorked.isBetween(startTime, endTime) || dateWorked.isSame(startTime) || dateWorked.isSame(endTime)) {
          rangeMinutesTotal += entry.minutes_worked;
          rangeMinutesTotal += entry.hours_worked * 60;

          if (
            this.currentSelectedDate.realDate.isBetween(startTime, endTime) ||
            this.currentSelectedDate.realDate.isSame(startTime) ||
            this.currentSelectedDate.realDate.isSame(endTime)
          ) {
            todaysEntries.push(entry);
          }
        }
      }

      date.hours = `${Math.floor(rangeMinutesTotal / 60)}:${(rangeMinutesTotal % 60).toString().padStart(2, '0')}`;
      totalTimeForRange += rangeMinutesTotal;
    }

    if ([DateRange.Weekly, DateRange.Monthly].includes(this.timeFrame)) {
      this.calculateRangeData(todaysEntries);
    }

    this.displayedEntries = todaysEntries;
    this.timeForDateRange = `${Math.floor(totalTimeForRange / 60)}:${(totalTimeForRange % 60)
      .toString()
      .padStart(2, '0')}`;
  }

  // this was added to fix an issue where I was grouping all monthly data together
  // this function will split the data into daily chunks for easier display
  private calculateRangeData(entries: TimeLog[]) {
    // split today's entries into arrays of entries by date (since today's entries really means this month's entries)
    const data: { date: string; hours: string; entries: TimeLog[]; totalMinutes: number }[] = [];

    // split each entry into its appropriate day
    const entriesToSort = this.maxDisplayedEntries ? cloneDeep(entries).splice(0, this.maxDisplayedEntries) : entries;
    for (const entry of entriesToSort) {
      const day = moment(entry.date_worked).startOf('day').format('dddd, MMMM Do');
      const monthlyEntry = data.find((d) => d.date === day);
      if (!monthlyEntry) {
        data.push({
          date: day,
          totalMinutes: entry.hours_worked * 60 + entry.minutes_worked,
          entries: [entry],
          hours: '',
        });
      } else {
        monthlyEntry.totalMinutes += entry.hours_worked * 60 + entry.minutes_worked;
        monthlyEntry.entries.push(entry);
      }
    }

    // set the displayed hours for each section
    for (const entry of data) {
      entry.hours = `${Math.floor(entry.totalMinutes / 60)}:${(entry.totalMinutes % 60).toString().padStart(2, '0')}`;

      // sort the entries by time
      entry.entries.sort((a, b) => {
        return moment(a.date_worked).isSameOrBefore(b.date_worked) ? 1 : -1;
      });
    }

    this.rangeData = data;
  }

  // load more options for the time log display (limited to 5 at start)
  public loadMore() {
    this.loadingItems = true;
    this.maxDisplayedEntries += 5;
    this.recalculateData();
    this.loadingItems = false;
  }
  public loadAll() {
    this.loadingItems = true;
    this.maxDisplayedEntries = undefined;
    this.recalculateData();
    this.loadingItems = false;
  }

  // loads the dropdowns from localstorage if applicable, and set dropdown to correct initial value
  private initFilters() {
    const pref_work_spaces = this.loadFromLocalStorage('time-log-drawer-workspace');
    const pref_users = this.loadFromLocalStorage('time-log-drawer-user');
    const pref_activities = this.loadFromLocalStorage('time-log-drawer-activity');
    const pref_companies = this.loadFromLocalStorage('time-log-drawer-company');
    let pref_range = this.loadFromLocalStorage('time-log-drawer-range');
    if (['daily', 'weekly', 'monthly'].includes(pref_range)) {
      pref_range = pref_range.charAt(0).toUpperCase() + pref_range.slice(1);
    }

    // set the default WS dropdown values (localstorage value, or current selected workspace)
    const WS_ids = pref_work_spaces || [+this.moduleService.workspace.id];
    this.initFilter('workspace', WS_ids);

    // set the default user dropdown values (localstorage value, or all users)
    const user_ids = pref_users || this.visibleUsers.map((u) => u.id);
    this.initFilter('user', user_ids);
    this.updateUsers(user_ids);

    // set the default activity dropdown values (localstorage value, or all activities)
    const activity_ids = pref_activities || this.allActivities.map((a) => a.id);
    this.initFilter('activity', activity_ids);

    // set the default company dropdown values (localstorage value, or all companies)
    const company_ids = pref_companies || this.allCompanies?.map((c) => c.id);
    this.initFilter('company', company_ids);

    if (pref_range) {
      this.timeFrame = pref_range;
    }
  }

  // automatically loads the selected key from localstorage and handles any potential errors
  // if the json errors when parsing, we also clear the key from localstorage
  private loadFromLocalStorage(key: string) {
    let ret;
    try {
      ret = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
    }
    return ret;
  }

  // sets the initial values of the dropdowns so they look correct on load
  private initFilter(type: 'workspace' | 'user' | 'activity' | 'company', ids: number[]) {
    let selectComponent;
    if (type === 'workspace') {
      selectComponent = this.workspaceSelect;
    } else if (type === 'user') {
      selectComponent = this.userSelect;
    } else if (type === 'activity') {
      selectComponent = this.activitySelect;
    } else {
      selectComponent = this.companySelect;
    }

    // toggle each relevant option
    selectComponent.options.forEach((item: MatOption) => {
      if (ids.includes(+item.value.id)) {
        item.select();
      }
    });

    // set AllSelected for the relevant item
    if (type === 'workspace') {
      this.allWorkspacesSelected =
        selectComponent.selected.length === selectComponent.options.length && selectComponent.options.length > 0;
      this.noWorkspaceSelected = selectComponent.selected.length === 0;
    } else if (type === 'user') {
      this.allUsersSelected =
        (selectComponent.options.filter((o) => o.selected).length === selectComponent.options.length &&
          selectComponent.options.length > 0) ||
        isEqual(this.selectedUsers, this.visibleUsers);
      this.myTimeSelected =
        this.selectedUsers.length === 1 && +this.selectedUsers[0].id === +this.authService.currentUser.id;
    } else if (type === 'activity') {
      this.allActivitiesSelected =
        selectComponent.selected.length === selectComponent.options.length && selectComponent.options.length > 0;
    } else if (type === 'company') {
      this.allCompaniesSelected =
        selectComponent.selected.length === selectComponent.options.length && selectComponent.options.length > 0;
    }
  }

  // writes the relevant filter to localstorage
  private writeFilters(type: 'workspace' | 'user' | 'activity' | 'company' | 'range') {
    let array;
    if (type === 'workspace') {
      array = this.selectedWorkspaces.map((w) => w.id);
    } else if (type === 'user') {
      array = this.selectedUsers.map((u) => u.id);
    } else if (type === 'activity') {
      array = this.selectedActivities.map((a) => a.id);
    } else if (type === 'company') {
      array = this.selectedCompanies.map((c) => c.id);
    } else {
      array = this.timeFrame;
    }
    localStorage.setItem(`time-log-drawer-${type}`, JSON.stringify(array));
  }

  // adds a user to user filter and selects them
  private addUserToFilter(user: User) {
    // if the passed user isn't already in the list, add them
    if (!this.allUsers.map((u) => +u.id).includes(+user.id)) {
      this.allUsers.push(user);
    }
    const user_ids = [...this.selectedUsers.map((u) => +u.id), user.id];
    this.initFilter('user', user_ids);
    this.updateUsers(user_ids);
  }

  // ---------------------------------------
  // time log manipulation, create/edit/delete
  // ---------------------------------------
  public createTimeLogEntry() {
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          canEditParent: true,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Creating entry..');
          // modify the data to match the api and update the db value
          result.activity_id = result.activity.id;
          delete result.activity;
          result.worker_id = result.worker.id;
          delete result.worker;
          result.company_id = result.company.id;
          delete result.company;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          const newEntry = await this.timeLogService.createTimeLogEntry(result).toPromise();
          this.allEntries.push(newEntry);
          this.addUserToFilter(newEntry.worker);
          this.recalculateData();
          this.progressIndicatorService.close();
          this.snackBar.open('Time log entry created!');
        }
      });
  }

  public editTimeLogEntry(entry: TimeLog) {
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          timeLog: entry,
          canEditParent: true,
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          // update the local entry to the new values
          for (const e of this.allEntries) {
            if (+e.id === +entry.id) {
              e.worker = result.worker;
              e.worker_id = result.worker.id;
              e.activity = result.activity;
              e.activity_id = result.activity.id;
              e.company = result.company;
              e.company_id = result.company.id;
              e.date_worked = result.date_worked;
              e.hours_worked = result.hours_worked;
              e.minutes_worked = result.minutes_worked;
              e.parent_id = result.parent_id;
              e.parent_type_id = result.parent_type_id;
              e.parent_code = result.parent_code;
              e.parent_title = result.parent_title;
              e.module_id = result.module_id;
              e.notes = result.notes;
            }
          }

          const newUser = result.worker;
          // modify the data to match the api and update the db value
          result.id = entry.id;
          result.activity_id = result.activity.id;
          delete result.activity;
          result.worker_id = result.worker.id;
          delete result.worker;
          result.company_id = result.company.id;
          delete result.company;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          this.timeLogService.updateTimeLogEntry(result).subscribe();
          // attempt to add the user in case they are new
          this.addUserToFilter(newUser);
          this.recalculateData();
          this.snackBar.open('Time log entry updated!');
        }
      });
  }

  public deleteTimeLogEntry(entryId: number) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Time Log',
          headerText: `Delete Time Log Entry`,
          descriptionText:
            'Warning: You will not be able to recover time log entry. Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.timeLogService.deactivateTimeLogEntry(entryId).subscribe();
          this.allEntries.splice(
            this.allEntries.findIndex((entry) => +entry.id === +entryId),
            1
          );
          this.recalculateData();
          this.snackBar.open('Time log entry deleted!');
        }
      });
  }

  // --------------------------------------------------------------------------
  // these functions handle the mat-selects, specifically with toggle all/one
  // --------------------------------------------------------------------------
  toggle(type: 'workspace' | 'user' | 'activity' | 'company'): boolean {
    let newStatus = true;
    let selectComponent;
    if (type === 'workspace') {
      selectComponent = this.workspaceSelect;
    } else if (type === 'user') {
      selectComponent = this.userSelect;
    } else if (type === 'activity') {
      selectComponent = this.activitySelect;
    } else {
      selectComponent = this.companySelect;
    }
    selectComponent.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.recalculateData();
    this.writeFilters(type);
    return newStatus;
  }

  private updateUsers(user_ids?: number[]) {
    const currentSelectedIds = user_ids || this.selectedUsers.map((u) => +u.id);
    this.selectedUsers = this.visibleUsers.filter((u) => currentSelectedIds.includes(u.id));

    for (const option of this.userSelect.options) {
      if (currentSelectedIds.includes(option.value.id)) {
        option.select();
      } else {
        option.deselect();
      }
    }

    // also need to set the user booleans, since workspace changes the visible users
    this.allUsersSelected =
      (this.userSelect.options.filter((o) => o.selected).length === this.userSelect.options.length &&
        this.userSelect.options.length > 0) ||
      isEqual(this.selectedUsers, this.visibleUsers);
    this.myTimeSelected =
      this.selectedUsers.length === 1 && +this.selectedUsers[0].id === +this.authService.currentUser.id;
    this.writeFilters('user');
  }

  toggleWorkspace() {
    this.allWorkspacesSelected = this.toggle('workspace');
    this.noWorkspaceSelected = this.workspaceSelect.options.filter((o) => o.selected).length === 0;

    this.updateUsers();
  }
  toggleUser() {
    this.allUsersSelected = this.toggle('user');
    this.myTimeSelected =
      this.selectedUsers.length === 1 && +this.selectedUsers[0].id === +this.authService.currentUser.id;
  }
  toggleActivity() {
    this.allActivitiesSelected = this.toggle('activity');
  }
  toggleCompany() {
    this.allCompaniesSelected = this.toggle('company');
  }

  toggleAll(type: 'workspace' | 'user' | 'activity' | 'company', isAllSelected: boolean) {
    let selectComponent;
    if (type === 'workspace') {
      selectComponent = this.workspaceSelect;
    } else if (type === 'user') {
      selectComponent = this.userSelect;
    } else if (type === 'activity') {
      selectComponent = this.activitySelect;
    } else {
      selectComponent = this.companySelect;
    }
    if (isAllSelected) {
      selectComponent.options.forEach((item: MatOption) => item.select());
    } else {
      selectComponent.options.forEach((item: MatOption) => item.deselect());
    }
    this.writeFilters(type);
    this.recalculateData();
  }

  toggleAllWorkspaces() {
    this.toggleAll('workspace', this.allWorkspacesSelected);
    this.noWorkspaceSelected = this.workspaceSelect.options.filter((o) => o.selected).length === 0;

    this.updateUsers();
  }
  toggleAllUsers() {
    this.myTimeSelected = false;
    this.toggleAll('user', this.allUsersSelected);
  }
  toggleMyTime() {
    let user_ids;
    if (this.myTimeSelected) {
      user_ids = [+this.authService.currentUser.id];
    } else {
      user_ids = [...this.selectedUsers.filter((u) => +u.id !== +this.authService.currentUser.id)];
    }

    this.updateUsers(user_ids);
    this.recalculateData();
  }
  toggleAllActivities() {
    this.toggleAll('activity', this.allActivitiesSelected);
  }
  toggleAllCompanies() {
    this.toggleAll('company', this.allCompaniesSelected);
  }
  // toggleGeneralTime() {
  //   this.writeFilters('workspace');
  //   this.recalculateData();
  // }
  toggleNoWorkspace() {
    if (!this.noWorkspaceSelected) {
      this.workspaceSelect.options.forEach((item: MatOption) => item.select());
      this.allWorkspacesSelected = true;
      this.writeFilters('workspace');
      this.recalculateData();
    } else {
      this.workspaceSelect.options.forEach((item: MatOption) => item.deselect());
      this.allWorkspacesSelected = false;
      this.writeFilters('workspace');
      this.recalculateData();
    }
    this.updateUsers();
  }

  // ------------------------------------------------------------------
  // exporting functionality
  // ------------------------------------------------------------------

  public exportData() {
    if (!this.displayedEntries || this.displayedEntries.length === 0) {
      this.snackBar.open('No entries available to export with the current filter selection!');
      return;
    }
    let displayDate = this.currentSelectedDate.realDate
      ? this.currentSelectedDate.realDate.format(
          `M/${[DateRange.Daily, DateRange.Weekly].includes(this.timeFrame) ? 'D/' : ''}YYYY`
        )
      : this.currentSelectedDate.date;

    let formattedDate = this.currentSelectedDate.realDate
      ? this.currentSelectedDate.realDate.format(
          `YYYY-MM${[DateRange.Daily, DateRange.Weekly].includes(this.timeFrame) ? '-DD' : ''}`
        )
      : this.currentSelectedDate.date;

    if (DateRange.Weekly === this.timeFrame) {
      displayDate += ` - ${this.currentSelectedDate.realEndDate.format('M/D/YYYY')}`;
      formattedDate += `-${this.currentSelectedDate.realEndDate.format('YYYY-MM-DD')}`;
    }

    this.exportService.exportDataWithConfirmation(
      this.getExportData(),
      `time_log_export_${formattedDate}.csv`,
      'Confirm Data Export',
      `Data export will use the currently selected filter settings for the date: ${displayDate}. Are you sure you wish to continue?`
    );
  }

  // format the current displayed data in csv
  private getExportData() {
    const dataToReturn: string[] = ['Date Worked, Worker Name, Time Worked, Activity, Company, PRJ/WO, Status, Notes'];
    let totalTime = 0;
    for (const entry of this.displayedEntries) {
      let timeLogStatus = '-';

      // project status
      if (entry.parent_type_id === 3) {
        if (+entry.parent_status_id === 1) {
          // both wo and project active
          timeLogStatus = 'Active';
        } else if (+entry.parent_status_id === 2) {
          timeLogStatus = 'Planned';
        } else if (+entry.parent_status_id === 3) {
          timeLogStatus = 'On Hold';
        } else if (+entry.parent_status_id === 4) {
          timeLogStatus = 'Closed';
        }
      }

      // work order status
      if (+entry.parent_type_id === 34) {
        if (+entry.parent_status_id === 1) {
          // both wo and project active
          timeLogStatus = 'Active';
        } else if (+entry.parent_status_id === 2) {
          timeLogStatus = 'On Hold';
        } else if (+entry.parent_status_id === 3) {
          timeLogStatus = 'Closed';
        } else if (+entry.parent_status_id === 4) {
          timeLogStatus = 'Ready For Pick Up';
        } else if (+entry.parent_status_id === 5) {
          timeLogStatus = 'Planned';
        }
      }

      // sanitize and push the data
      dataToReturn.push(
        this.exportService.sanitizeItems([
          moment(entry.date_worked).format('MM/DD/YYYY'),
          `${entry.worker.first_name} ${entry.worker.last_name}`,
          `${entry.hours_worked.toString().padStart(2, '0')}:${entry.minutes_worked.toString().padStart(2, '0')} (${
            entry.hours_worked
          }.${Math.floor((entry.minutes_worked * 100.0) / 60.0)
            .toString()
            .padStart(2, '0')})`,
          entry.activity.name,
          entry.company.name,
          `${
            entry.parent_id
              ? entry.parent_type_id === 3
                ? `PRJ ${entry.parent_code} - ${entry.parent_title}`
                : `${entry.parent_code} - ${entry.parent_title}`
              : '-'
          }`,
          timeLogStatus,
          entry.notes,
        ])
      );
      totalTime += entry.hours_worked * 60 + entry.minutes_worked;
    }

    // push a line item for total counts
    const totalHours = Math.floor(totalTime / 60);
    const totalMinutes = totalTime % 60;
    dataToReturn.push('');
    dataToReturn.push(
      this.exportService.sanitizeItems([
        `Total Entries: ${this.displayedEntries.length}`,
        `Total Hours Worked: ${totalHours.toString().padStart(2, '0')}:${totalMinutes
          .toString()
          .padStart(2, '0')} (${totalHours}.${Math.floor((totalMinutes * 100.0) / 60.0)
          .toString()
          .padStart(2, '0')})`,
      ])
    );

    return dataToReturn;
  }
}
