import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatepickerHeaderIncrement } from '../datepicker-header.component';

@Component({
  selector: 'app-datepicker-header-template-common',
  templateUrl: './datepicker-header-template-common.component.html',
  styleUrls: ['./datepicker-header-template-common.component.scss'],
})
export class DatepickerHeaderTemplateCommonComponent {
  @Input() periodLabel: string;

  @Input() showClearDate: boolean = true;

  @Output() previousClicked = new EventEmitter<'month' | 'year'>();

  @Output() nextClicked = new EventEmitter<'month' | 'year'>();

  @Output() incrementDate = new EventEmitter<DatepickerHeaderIncrement>();

  @Output() clearDate = new EventEmitter<void>();
}
