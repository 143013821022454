<app-base-dialog [title]="action + ' Building'" (closeDialog)="close()"> </app-base-dialog>
<div class="building-modal">
  <form [formGroup]="buildingFormGroup">
    <div mat-dialog-content>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="primary mb-2">Building Name*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput required="true" placeholder="e.g. Nicholson Tower" type="text" formControlName="name" />
            <mat-error *ngIf="name.errors?.required">Building Name is Required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-6">
          <h6 class="primary mb-2">Building Code*</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput required="true" type="text" placeholder="e.g. NIC" formControlName="code" />
            <mat-error *ngIf="code.errors?.required">Must be between 2 and 6 characters.</mat-error>
            <mat-error *ngIf="code.errors?.minlength">
              Cannot be less than {{ code.errors.minlength['requiredLength'] }} characters.
            </mat-error>
            <mat-error *ngIf="code.errors?.maxlength">
              Cannot be more than {{ code.errors.maxlength['requiredLength'] }} characters.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <h6 class="primary mb-2">Circulation %</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="number" placeholder="e.g. 20" formControlName="circulation_factor" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="dkblue mb-2 font-weight-semibold">Floors</h6>
          <div id="floors-container">
            <div
              [id]="floor.id"
              class="lh-28 pl-3 pr-2 br-10 bg-shade-ltblue ltblue d-flex align-items-center m-2"
              *ngFor="let floor of building?.floors"
              draggable="true"
              (dragover)="dragOver($event, floor)"
              (dragstart)="dragStart(floor)"
              (drop)="onDrop()"
              (click)="openFloorDialog('Edit', floor)"
            >
              <span
                matTooltip="Drag to Reorder"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                class="font-weight-semibold draggable"
                >{{ floor.name }}</span
              >
              <mat-icon
                matTooltip="Remove Floor"
                matTooltipPosition="above"
                [matTooltipShowDelay]="300"
                class="remove-mat-icon pointer ml-2"
                (click)="removeFloor(floor)"
                >close</mat-icon
              >
            </div>
            <span
              class="accent p-2 font-weight-semibold pointer hover-u"
              id="add-mat-icon"
              (click)="openFloorDialog('Add')"
              >+ Floor</span
            >
          </div>
          <p class="dkgray my-1 px-2 ml-1">
            <small> Drag floors to reorder, click floor to edit </small>
          </p>
        </div>
      </div>

      <div class="p-3 border border-gray br-10 mb-4">
        <div class="d-flex align-items-center">
          <h5 id="physicalAddressLabel" class="primary mb-0 mr-auto">Physical Address</h5>
          <mat-slide-toggle
            aria-labelledby="physicalAddressLabel"
            class="ml-auto"
            color="accent"
            [formControl]="isPhysicalAddressActive"
          ></mat-slide-toggle>
        </div>
        <ng-container *ngIf="isPhysicalAddressActive.value">
          <form formGroupName="physical_address">
            <div class="row my-3">
              <div class="col-12 mb-2">
                <h6 class="primary mb-2">Address Line 1*</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput type="text" placeholder="Address Line 1" formControlName="address" />
                  <mat-error *ngIf="physical_address.get('address').errors?.required">Address is required</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-2">
                <h6 class="primary mb-2">City*</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput type="text" placeholder="e.g. Oklahoma City" formControlName="city" />
                  <mat-error *ngIf="physical_address.get('city').errors?.required">City is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 mb-2 pl-0">
                <h6 class="primary mb-2">State*</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-select placeholder="State" formControlName="state">
                    <mat-select-trigger>{{ physical_address.value.state }}</mat-select-trigger>
                    <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">
                      {{ state.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="physical_address.get('state').errors?.required">State is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 mb-2 pl-0">
                <h6 class="primary mb-2">Zip Code*</h6>
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput type="text" placeholder="Zip Code" formControlName="zipcode" />
                  <mat-error *ngIf="physical_address.get('zipcode').errors?.required">Zip Code is required</mat-error>
                  <mat-error *ngIf="physical_address.get('zipcode').errors?.pattern">Zip Code is invalid</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <mat-checkbox
                class="font-weight-normal primary mt-3"
                color="accent"
                [formControl]="isMailingAddressSameAsPhysicalAddress"
                >Mailing Address is same as Physical Address</mat-checkbox
              >
            </div>
          </div>
          <ng-container *ngIf="!isMailingAddressSameAsPhysicalAddress.value">
            <form class="mt-5" formGroupName="mailing_address">
              <div class="d-flex align-items-center">
                <h5 class="primary mb-0 mr-auto">Mailing Address</h5>
              </div>
              <div class="row my-3">
                <div class="col-12 mb-2">
                  <h6 class="primary mb-2">Address Line 1*</h6>
                  <mat-form-field class="full-width" appearance="outline">
                    <input matInput type="text" placeholder="Address Line 1" formControlName="address" />
                    <mat-error *ngIf="mailing_address.get('address').errors?.required">Address is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mb-2">
                  <h6 class="primary mb-2">City*</h6>
                  <mat-form-field class="full-width" appearance="outline">
                    <input matInput type="text" placeholder="e.g. Oklahoma City" formControlName="city" />
                    <mat-error *ngIf="mailing_address.get('city').errors?.required">City is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-3 mb-2 pl-0">
                  <h6 class="primary mb-2">State*</h6>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-select placeholder="State" formControlName="state">
                      <mat-select-trigger>{{ mailing_address.value.state }}</mat-select-trigger>
                      <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">
                        {{ state.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="mailing_address.get('state').errors?.required">State is required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-3 mb-2 pl-0">
                  <h6 class="primary mb-2">Zip Code*</h6>
                  <mat-form-field class="full-width" appearance="outline">
                    <input matInput type="text" placeholder="Zip Code" formControlName="zipcode" />
                    <mat-error *ngIf="mailing_address.get('zipcode').errors?.required">Zip Code is required</mat-error>
                    <mat-error *ngIf="mailing_address.get('zipcode').errors?.pattern">Zip Code is invalid</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="action === 'Edit'" class="row my-4">
        <div class="col-12 slide-container">
          <mat-slide-toggle color="accent" formControlName="is_enabled">
            <span class="font-weight-semibold">Building is Active</span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button class="full-width" type="button" color="accent" (click)="close()">Cancel</button>
      </div>
      <div class="col">
        <button
          mat-flat-button
          type="submit"
          color="accent"
          class="full-width"
          (click)="save()"
          [disabled]="!buildingFormGroup.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
