import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { UserService } from 'src/app/services/';

@Component({
  selector: 'app-profile-thumbnail',
  templateUrl: './profile-thumbnail.component.html',
  styleUrls: ['./profile-thumbnail.component.scss'],
})
export class ProfileThumbnailComponent implements OnInit {
  public internalUserId: number;

  @Input() set userId(value: number) {
    this.internalUserId = value;
    this.loadImage();
  }

  @Input() public height = 30;

  @Input() public width = 30;

  @Input() public noBorder: boolean;

  @Input() public borderActive: boolean;

  @Input() public overlayIcon;

  @Input() public overlayIconOpacity = 1;

  @Input() public overlayIconTooltip = '';

  @Input() public overlayIconColor = 'green';

  @Input() public overlayIconBackground = 'green';

  @Output() public overlayIconClickEvent = new EventEmitter();

  public thumbnailImage$: Observable<SafeResourceUrl>;

  public isLoaded: boolean;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.loadImage();
  }

  private loadImage() {
    this.thumbnailImage$ = this.userService.getCachedProfileThumbnailAsync(this.internalUserId);
    this.thumbnailImage$.subscribe((base64Value) => {
      this.isLoaded = base64Value && base64Value.toString().length > 0;
    });
  }

  public onIconClick() {
    this.overlayIconClickEvent.emit();
  }
}
