import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-purchasing',
  templateUrl: './purchasing.component.html',
  styleUrls: ['./purchasing.component.scss'],
})
export class PurchasingComponent implements OnInit {
  links: { route: string; text: string; disabled?: boolean }[] = [
    { route: '/purchasing/arfs', text: 'ARFs' },
    { route: '/purchasing/invoices', text: 'Invoices' },
    {
      route: '/purchasing/budget',
      text: 'Cost Codes',
      disabled:
        !this.authService.isWorkspaceManagerOfAnyModule &&
        !this.authService.isAppAdmin &&
        !this.authService.isFinanceManager &&
        !this.authService.isCFMO,
    },
  ];

  constructor(private router: Router, public authService: AuthService) {}

  get routeUrl(): string {
    return this.router.url;
  }

  ngOnInit(): void {
    const mainWorkOrderListWrapper = document.querySelector('#purchasing');
    const ps = new PerfectScrollbar(mainWorkOrderListWrapper);
  }
}
