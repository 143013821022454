<div id="proposal-request" data-test-id="proposal-request" class="proposal-request container-fluid p-4">
  <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
    <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
      PEB
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
      Bid Packages
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/construction-budget']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Construction Budget
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
      mat-button
      color="primary"
      class="flex-grow-1 bg-white mx-1"
    >
      Proposal Requests
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/change-orders']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Change Orders
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/invoices']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Invoices
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/budget']" mat-button color="primary" class="flex-grow-1 ml-1">
      Budget Overview
    </button>
  </div>
  <header class="d-flex full-width align-items-top mt-1 border-bottom border-gray mb-4">
    <div class="d-flex flex-column mr-auto">
      <h4 class="dkblue">
        Proposal Requests
        <small class="ml-4">
          {{ proposalRequests ? proposalRequests.length : 0 }}
        </small>
      </h4>
      <p class="dkgray">View and manage Change Orders here.</p>
    </div>
    <div class="text-right list-filters">
      <a target="_self" href="assets/documents/Change_Order_Template_For_Subs.pdf" download="Change_Order_Form.pdf">
        <button mat-button type="button" color="accent">
          <mat-icon>get_app</mat-icon>
          <span class="ml-2">Change Order Form</span>
        </button>
      </a>
      <button mat-stroked-button type="button" color="primary" class="ml-3 bg-white" (click)="toggleProposalRequests()">
        {{ hasAnExpandedProposalRequest ? 'Collapse All' : 'Expand All' }}
      </button>
      <button mat-flat-button class="ml-3" color="success" (click)="addProposalRequest()" *ngIf="isWorkspaceStaff">
        + PR
      </button>
    </div>
  </header>
  <br />
  <div class="portlet-table-header pt-0 pl-0 pl-2 pr-2">
    <div class="row m-0 align-items-center">
      <div class="col-auto" style="width: 90px">
        <label class="main-label">#</label>
      </div>
      <div class="col pl-0">
        <label class="main-label">Proposal Request</label>
      </div>
      <div class="col-3 text-right mr-5 pr-5">
        <label class="main-label">Due</label>
      </div>
    </div>
  </div>
  <div class="m-portlet p-0 mb-4" m-portlet="true" *ngFor="let p of proposalRequests">
    <div class="m-portlet__body p-2">
      <div class="row align-items-center m-0">
        <div class="col-auto" style="width: 90px">
          <h5 class="m-0 dkblue font-weight-semibold">
            {{ p.local_index }}
          </h5>
        </div>
        <div class="col pl-0">
          <h5 (click)="p.is_expanded = !p.is_expanded" class="dkblue font-weight-semibold pointer m-0">
            <span class="mr-2">
              {{ p.title }}
            </span>
            <small class="gray">
              {{ p.changeOrders ? p.changeOrders.length : 0 }} CO{{
                p.changeOrders && p.changeOrders.length === 1 ? '' : 's'
              }}
            </small>
          </h5>
        </div>
        <div class="col-auto text-right pr-0">
          <div
            (click)="p.is_expanded = !p.is_expanded"
            class="font-weight-semibold pointer m-0 d-inline-block align-middle"
          >
            <span *ngIf="p.changeOrderStatusCounts.new" class="bg-gray white pt-1 pb-1 pl-2 pr-2 br-5 mr-4">
              <b>{{ p.changeOrderStatusCounts.new }}</b> New CO{{ p.changeOrderStatusCounts.new > 1 ? "'s" : '' }}
            </span>
            <span *ngIf="p.changeOrderStatusCounts.inReview" class="bg-gray white pt-1 pb-1 pl-2 pr-2 br-5 mr-4">
              <b>{{ p.changeOrderStatusCounts.inReview }}</b> In Review CO{{
                p.changeOrderStatusCounts.inReview > 1 ? "'s" : ''
              }}
            </span>
            <span *ngIf="p.changeOrderStatusCounts.approved" class="bg-green white pt-1 pb-1 pl-2 pr-2 br-5 mr-4">
              <b>{{ p.changeOrderStatusCounts.approved }}</b> Approved CO{{
                p.changeOrderStatusCounts.approved > 1 ? "'s" : ''
              }}
            </span>
            <span *ngIf="p.changeOrderStatusCounts.finalized" class="bg-green white pt-1 pb-1 pl-2 pr-2 br-5 mr-4">
              <b>{{ p.changeOrderStatusCounts.finalized }}</b> Finalized CO{{
                p.changeOrderStatusCounts.finalized > 1 ? "'s" : ''
              }}
            </span>
            <span
              *ngIf="p.daysUntilDueDate < 0 && (p.changeOrderStatusCounts.new || p.changeOrderStatusCounts.inReview)"
              class="red"
            >
              {{ displayDueDate(p.daysUntilDueDate) }}
            </span>
          </div>
          <button *ngIf="p.is_expanded" mat-icon-button class="ltgray" (click)="p.is_expanded = !p.is_expanded">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button *ngIf="!p.is_expanded" mat-icon-button class="ltgray" (click)="p.is_expanded = !p.is_expanded">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="pr_main_menu" class="ltblue" *ngIf="isWorkspaceStaff">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #pr_main_menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="editProposalRequest(p)" *ngIf="isWorkspaceStaff">
              <mat-icon>edit</mat-icon>
              <span> Edit PR </span>
            </button>
            <!-- <button mat-menu-item>
                      <mat-icon>mail</mat-icon>
                      <span>
                        Start a Conversation
                      </span>
                    </button> -->
            <button mat-menu-item class="red" (click)="removeProposalRequest(p)" *ngIf="isWorkspaceStaff">
              <mat-icon>delete</mat-icon>
              <span> Delete PR </span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="p.is_expanded" class="row p-3 pb-2">
        <div class="col">
          <p class="m-0 ltblue font-weight-semibold">
            <span class="dkblue font-weight-normal"> Reason: </span>
            {{ p.reason_name + (p.reason_option?.name ? ' | ' + p.reason_option?.name : '') }}
          </p>
          <p class="dkblue lh2 m-0" [innerHTML]="p.description"></p>
        </div>
      </div>
      <div *ngIf="p.is_expanded" class="row p-3 pt-0 align-items-center">
        <div class="col">
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip *ngFor="let f of p.files" [file]="f"></app-file-chip>
          </mat-chip-list>
        </div>
        <div class="col-4 text-right">
          <button (click)="createPCOTask(p)" mat-button color="accent" *ngIf="isWorkspaceStaff">Request a PCO</button>
          <button (click)="openChangeOrderModal(p)" mat-flat-button color="success" class="ml-3">Add CO</button>
        </div>
      </div>
    </div>
    <div *ngIf="p.is_expanded">
      <div class="co-table-header p-2 pl-3 pr-3" *ngIf="p.changeOrders && p.changeOrders.length > 0">
        <div class="row">
          <div class="col">
            <label class="main-label">Change Order</label>
          </div>
          <div class="col">
            <label class="main-label">Cost</label>
          </div>
          <div class="col" *ngIf="isWorkspaceStaff">
            <label class="main-label">CM / Arch</label>
          </div>
          <div class="col" *ngIf="isWorkspaceStaff">
            <label class="main-label">Tenant</label>
          </div>
          <div class="col" *ngIf="isWorkspaceStaff">
            <label class="main-label">CFMO</label>
          </div>
          <!--        <div class="col-1"></div>-->
          <div class="col pl-0" *ngIf="isWorkspaceStaff">
            <!-- <label class="main-label">Actions</label> -->
          </div>
        </div>
      </div>
      <div class="co ease m-portlet__body p-3 item" *ngFor="let co of p.changeOrders">
        <div class="row align-items-center">
          <div class="col pointer d-flex justify-content-start align-items-center">
            <span>
              <h5 class="m-0 mr-3 dkblue font-weight-semibold">
                {{ co.local_index }}
              </h5>
            </span>
            <span>
              <p (click)="openViewChangeOrderModal(co.id, false)" class="m-0 font-weight-semibold ltblue">
                {{ co.trade_name }}
              </p>
              <h6 class="dkgray font-weight-normal m-0" *ngIf="co.company_name">
                {{ co.company_name }}
              </h6>
            </span>
          </div>
          <div class="col">
            <p class="m-0 dkblue">{{ co.cost_change >= 0 ? '+' : '-' }} {{ Math.abs(co.cost_change) | currency }}</p>
            <h6 *ngIf="co.funding_source_name" class="m-0 dkblue font-weight-normal">
              {{ co.funding_source_tenant_name || 'UHAT'
              }}{{
                co.funding_source_fee_funding_source_id
                  ? ' (' + co.funding_source_fee_funding_source_abbreviation + ')'
                  : ''
              }}:
              {{ co.funding_source_fee_id ? co.funding_source_name : 'Added Cost' }}
              <span
                *ngIf="!co.wm_arch_task_id && co.need_wm_arch_approval"
                class="m-0 px-2 font-weight-bold ltblue cursor-pointer"
                (click)="openFundingSourceModal(co)"
              >
                Edit
              </span>
            </h6>
            <h6
              *ngIf="!co.funding_source_name"
              (click)="openFundingSourceModal(co)"
              class="m-0 pointer ltblue font-weight-semibold"
            >
              Set Funding Source
            </h6>
          </div>
          <div class="col" *ngIf="isWorkspaceStaff">
            <app-open-task-button
              *ngIf="co.wm_arch_task_id && co.need_wm_arch_approval"
              [taskId]="co.wm_arch_task_id"
              lineHeight="34"
              (statusChanged)="updateTaskStatus(co, $event)"
            ></app-open-task-button>

            <app-split-button
              *ngIf="!co.wm_arch_task_id && co.need_wm_arch_approval"
              [disabled]="!co.funding_source_name"
              lineHeight="28"
              (clickEvent)="createApprovalTask('wm_arch_task_id', co)"
              [matMenu]="menu"
            >
              Create Task
            </app-split-button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                mat-menu-item
                (click)="setNoTask('need_wm_arch_approval', co)"
                [disabled]="!co.funding_source_name"
              >
                CM/Arch Approval N/A
              </button>
            </mat-menu>
            <div class="d-inline-flex align-items-center" *ngIf="!co.need_wm_arch_approval">
              <p class="m-0 dkblue font-weight-normal mr-auto">CM/Arch Approval N/A</p>
              <span
                *ngIf="co.status_id !== 2"
                matRipple
                [matMenuTriggerFor]="task_status_menu"
                class="m-0 ltblue pointer ml-5"
              >
                <i class="material-icons small-icon">more_vert</i>
              </span>
              <mat-menu #task_status_menu="matMenu" xPosition="before">
                <button
                  (click)="createApprovalTask('wm_arch_task_id', co)"
                  mat-menu-item
                  color="primary"
                  [disabled]="!co.funding_source_name"
                >
                  <span> Create Task </span>
                </button>
              </mat-menu>
            </div>
          </div>

          <!--        TODO add auth for the below once we have access to isProjectAdmin-->
          <div class="col" *ngIf="isWorkspaceStaff">
            <app-open-task-button
              *ngIf="co.tenant_task_id"
              [taskId]="co.tenant_task_id"
              lineHeight="34"
              (statusChanged)="updateTaskStatus(co, $event, true)"
            ></app-open-task-button>

            <app-split-button
              *ngIf="!co.tenant_task_id && co.need_tenant_approval"
              [disabled]="(co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) || !co.funding_source_name"
              lineHeight="28"
              (clickEvent)="createApprovalTask('tenant_task_id', co)"
              [matMenu]="menu"
            >
              Create Task
            </app-split-button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                mat-menu-item
                (click)="setNoTask('need_tenant_approval', co)"
                [disabled]="(co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) || !co.funding_source_name"
              >
                Tenant Approval N/A
              </button>
            </mat-menu>

            <div class="d-inline-flex align-items-center" *ngIf="!co.need_tenant_approval">
              <p class="m-0 dkblue font-weight-normal mr-auto">Tenant Approval N/A</p>
              <span
                *ngIf="co.status_id !== 2"
                matRipple
                [matMenuTriggerFor]="task_status_menu"
                class="m-0 ltblue pointer ml-5"
              >
                <i class="material-icons small-icon">more_vert</i>
              </span>
              <mat-menu #task_status_menu="matMenu" xPosition="before">
                <button
                  (click)="createApprovalTask('tenant_task_id', co)"
                  mat-menu-item
                  color="primary"
                  [disabled]="(co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) || !co.funding_source_name"
                >
                  <span> Create Task </span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="col" *ngIf="isWorkspaceStaff">
            <app-open-task-button
              *ngIf="co.cfmo_task_id"
              [taskId]="co.cfmo_task_id"
              lineHeight="34"
              (statusChanged)="updateTaskStatus(co, $event)"
            ></app-open-task-button>

            <app-split-button
              *ngIf="!co.cfmo_task_id && co.need_cfmo_approval"
              [disabled]="
                (co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) ||
                (co.tenant_status_id !== 3 && !!co.need_tenant_approval) ||
                !co.funding_source_name
              "
              lineHeight="28"
              (clickEvent)="createApprovalTask('cfmo_task_id', co)"
              [matMenu]="menu"
            >
              {{ co.wm_arch_task_id ? 'Add CFMO' : 'Create Task' }}
            </app-split-button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                mat-menu-item
                (click)="setNoTask('need_cfmo_approval', co)"
                [disabled]="
                  (co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) ||
                  (co.tenant_status_id !== 3 && !!co.need_tenant_approval) ||
                  !co.funding_source_name
                "
              >
                CFMO Approval N/A
              </button>
            </mat-menu>

            <div class="d-inline-flex align-items-center" *ngIf="!co.need_cfmo_approval">
              <p class="m-0 dkblue font-weight-normal mr-auto">CFMO Approval N/A</p>
              <span
                *ngIf="co.status_id !== 2"
                matRipple
                [matMenuTriggerFor]="task_status_menu"
                class="m-0 ltblue pointer ml-5"
              >
                <i class="material-icons small-icon">more_vert</i>
              </span>
              <mat-menu #task_status_menu="matMenu" xPosition="before">
                <button
                  (click)="createApprovalTask('cfmo_task_id', co)"
                  mat-menu-item
                  color="primary"
                  [disabled]="
                    (co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) ||
                    (co.tenant_status_id !== 3 && !!co.need_tenant_approval) ||
                    !co.funding_source_name
                  "
                >
                  <span> {{ co.wm_arch_task_id ? 'Add CFMO' : 'Create Task' }} </span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="col align-items-center text-right pl-0" *ngIf="isWorkspaceStaff">
            <button
              *ngIf="co.status_id !== 2"
              mat-flat-button
              color="success"
              (click)="approveChangeOrder(co)"
              [disabled]="
                !isWorkspaceStaff ||
                (co.wm_arch_status_id !== 3 && !!co.need_wm_arch_approval) ||
                (co.tenant_status_id !== 3 && !!co.need_tenant_approval) ||
                (co.cfmo_status_id !== 3 && !!co.need_cfmo_approval)
              "
              matTooltip="Finalize CO"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="lh-28 pl-3 pr-3"
            >
              Finalize CO
            </button>
            <button *ngIf="co.status_id === 2" mat-icon-button color="success">
              <mat-icon>check_circle</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="co_menu" color="accent" class="ml-1">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #co_menu="matMenu" xPosition="before">
              <!--            <button mat-menu-item>-->
              <!--              <mat-icon>mail</mat-icon>-->
              <!--              <span>-->
              <!--                Start Conversation-->
              <!--              </span>-->
              <!--            </button>-->
              <button
                mat-menu-item
                (click)="openChangeOrderModal(p, co)"
                *ngIf="(!co.has_approved_invoices && isWorkspaceStaff) || authService.isAC"
              >
                <mat-icon>edit</mat-icon>
                <span> Edit CO </span>
              </button>
              <button mat-menu-item (click)="removeChangeOrder(co)" *ngIf="isWorkspaceStaff">
                <mat-icon>delete</mat-icon>
                <span> Remove CO </span>
              </button>
              <button
                mat-menu-item
                (click)="openResetCOConfirmation(co)"
                *ngIf="co.status_id === 1 && isWorkspaceStaff"
              >
                <mat-icon>autorenew</mat-icon>
                <span> Reset CO </span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
