<div class="project-modal modal-wide">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">
        {{ data.title }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="close()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row dialog-form">
    <div class="col mb-3">
      <!-- Search form -->
      <mat-form-field appearance="outline" class="full-width">
        <input matInput [(ngModel)]="searchInput" placeholder="Search Trades" />
        <mat-icon *ngIf="searchInput" (click)="searchInput = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
      <!-- <input class="form-control light-outline" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput"> -->
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-selection-list
        *ngIf="allTrades.length > 0"
        #trades
        (selectionChange)="onSelection($event, trades.selectedOptions)"
      >
        <mat-list-option *ngFor="let trade of allTrades | filter: searchInput" [value]="trade">
          {{ trade.name }}
        </mat-list-option>
      </mat-selection-list>
      <div *ngIf="allTrades.length <= 0" class="no-items-panel">
        <div class="no-items-panel-content">
          <h1>No Trades</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 align-items-center">
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold">
        {{ getTradeSelectionNumberText() }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <button mat-button *ngIf="!processing" (click)="close()" class="ltgray mr-2">Cancel</button>
      <button
        mat-flat-button
        [color]="selectedTrades.length >= 1 ? 'success' : ''"
        [disabled]="selectedTrades.length < 1"
        (click)="submit()"
      >
        Select Trades
      </button>
    </div>
  </div>
</div>
