<div id="building" data-test-id="building" class="building">
  <h6 *ngIf="showLabel" class="dkblue font-weight-semibold mb-2">Building<span *ngIf="required">*</span></h6>
  <mat-form-field appearance="outline" class="full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Building"
      matInput
      [matAutocomplete]="building_auto"
      [formControl]="building"
      (blur)="checkValue()"
    />
    <mat-icon *ngIf="building?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-error *ngIf="building?.errors?.required">{{ customPlaceholder || 'A building is required' }}</mat-error>
    <mat-error *ngIf="building?.errors?.doesNotExist">The building does not exist</mat-error>
    <mat-autocomplete #building_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="mapper">
      <mat-option *ngFor="let building of filteredBuildings | async" [value]="building">
        {{ building?.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
