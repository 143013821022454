<mat-button-toggle-group>
  <button mat-button [disabled]="disabled" [ngStyle]="{ 'line-height': lineHeight + 'px' }" (click)="callParent()">
    <ng-content></ng-content>
  </button>
  <mat-button-toggle
    class="drop-down-button"
    [ngStyle]="{ 'line-height': lineHeight + 'px' }"
    [ngClass]="{ 'pointer-style': disabled }"
    [matMenuTriggerFor]="matMenu"
    [disabled]="disabled"
  >
    <mat-icon [ngClass]="{ gray: disabled, accent: !disabled }"> arrow_drop_down </mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>

<!-- BELOW IS HOW TO USE -->

<!-- <app-split-button class="ml-4" lineHeight="24" (clickEvent)="mainFunction()" [matMenu]="menu">
  MAIN ACTION
</app-split-button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="exportProjectDetails()">Download PDF</button>
  <button mat-menu-item (click)="toggleFormEdit()" *ngIf="projectDetailsForm.disabled && isAdmin">
    Edit
  </button>
</mat-menu> -->
