<div
  class="messaging-overlay-panel"
  style="display: none"
  [ngClass]="{
    'panel-closed': !isPanelOpen(),
    'panel-open': isPanelOpen(),
    'sidenav-closed': !isSidenavOpen()
  }"
>
  <div class="message-overlay-left-panel">
    <app-messaging-inbox-display></app-messaging-inbox-display>
  </div>
  <div class="message-overlay-right-panel">
    <app-messaging-conversation-panel
      [hidden]="state !== 'viewConversation'"
      (resetView)="resetView()"
    ></app-messaging-conversation-panel>
    <app-messaging-create-conversation-panel
      [hidden]="state !== 'createConversation'"
    ></app-messaging-create-conversation-panel>
  </div>
  <div
    matTooltip="Close Messages"
    matTooltipPosition="left"
    [matTooltipShowDelay]="300"
    (click)="closeMessagingPanel()"
    id="messaging-panel-tab"
  >
    <mat-icon class="dkblue pointer">keyboard_arrow_left</mat-icon>
  </div>
</div>
