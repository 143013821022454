<div
  [matMenuTriggerFor]="aidet_menu"
  class="d-flex align-items-center px-2 br-4 bg-shade-red text-center aidet-container pointer hover-u"
>
  <p class="dkblue m-0 mr-auto lh-22">
    Use <b>AIDET</b>: <b>A</b>cknowledge, <b>I</b>ntroduce, <b>D</b>uration, <b>E</b>xplanation, <b>T</b>hank You
  </p>
  <p class="gray ml-auto m-0 lh-22">
    <i class="fa fa-question-circle"> </i>
  </p>
</div>
<mat-menu #aidet_menu="matMenu" xPosition="before">
  <div class="p-4 d-flex flex-column">
    <p class="font-weight-semibold dkblue mb-0">Acknowledge</p>
    <p class="font-weight-normal dkgray mb-3">
      Greet the person by name. Attitude is everything, we should strive to leave a lasting, positive impression.
    </p>
    <p class="font-weight-semibold dkblue mb-0">Introduce</p>
    <p class="font-weight-normal dkgray mb-3">
      Introduce yourself politely. Tell them who you are, why you are reaching out, and how you are going to help them.
      Explain your credibility and gain their confidence.
    </p>
    <p class="font-weight-semibold dkblue mb-0">Duration</p>
    <p class="font-weight-normal dkgray mb-3">
      Describe what steps you are going to take and how long each step will take. This will help alleviate uncertainty
      and help them feel more comfortable.
    </p>
    <p class="font-weight-semibold dkblue mb-0">Explanation</p>
    <p class="font-weight-normal dkgray mb-3">
      Go into detail on the important aspects of what you are doing. Always remember that an educated customer is a
      happy customer.
    </p>
    <p class="font-weight-semibold dkblue mb-0">Thank You</p>
    <p class="font-weight-normal dkgray mb-0">
      Thank them for choosing our services. A simple “Thank You” can go a long way. Foster an attitude of gratitude
      while using reward and recognition tools.
    </p>
  </div>
</mat-menu>
