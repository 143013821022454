<div class="project-modal p-0">
  <div class="row align-items-center mb-3">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">New Project Drawing</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Nick Name</mat-label>
        <input matInput placeholder="Ex: Floor Plan" [(ngModel)]="projectDrawing.name" />
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col">
      <button mat-stroked-button type="button" color="accent" (click)="openUploadModal()">Upload File</button>
      &nbsp;
      <mat-chip-list class="d-inline-block align-middle" *ngIf="projectDrawing.file_id">
        <app-file-chip
          [file]="{ id: projectDrawing.file_id, name: projectDrawing.file_name }"
          [removable]="false"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <button mat-button (click)="cancel()" mat-button type="button" class="gray full-width">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button type="button" (click)="submit()" color="success" class="full-width">Save Drawing</button>
    </div>
  </div>
</div>
