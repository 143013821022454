<div #mainScreen (window:resize)="onResize($event)" class="app-container p-0 m-0">
  <div *ngIf="!projectLoading && !shownProject">Project not found</div>
  <div style="position: relative" *ngIf="!projectLoading && shownProject">
    <div
      class="project-header ease"
      [ngClass]="{
        'nav-closed':
          isSidenavClosed() && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'nav-open':
          !isSidenavClosed() && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'notifications-sidenav-open':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed(),
        'notifications-sidenav-closed':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed()
      }"
    >
      <div
        *ngIf="statusId === ProjectStatus.CLOSED"
        class="bg-red d-flex justify-content-center align-items-center"
        style="height: 52px"
      >
        <p class="m-0 white font-weight-semibold d-inline-block align-middle px-2">
          <span>CLOSED on {{ closedDate }} at {{ closedTime }}</span>
        </p>
        <button
          mat-flat-button
          (click)="setProjectToActive()"
          type="button"
          class="mx-2 lh-28 d-inline-block align-middle px-3 text-danger"
        >
          Reactivate
        </button>
      </div>
      <header class="project-header-details ease">
        <div class="d-flex align-items-center mb-1">
          <h4
            data-test-id="project-header-title-or-id"
            #projectTitle
            class="dkblue m-0 mr-2 project-title text-truncate"
            style="font-size: 1.6rem"
          >
            <span class="font-weight-semibold dkgray">
              {{ shownProject.code }}
            </span>
            <span class="ml-1 mr-1 font-weight-semibold gray"> | </span>
            <span class="font-weight-semibold dkgray">
              {{ shownProject.building_code }}{{ shownProject.floor_code ? '-' : '' }}{{ shownProject.floor_code }}
            </span>
            <span class="ml-1 mr-1 font-weight-semibold gray"> | </span>
            <span class="font-weight-bold">
              {{ shownProject.title ? shownProject.title : 'Project' + ' ' + shownProject.code }}
            </span>
          </h4>
          <h6 class="m-0 white bg-ltblue rounded-pill px-4 py-1 ml-3">
            {{ shownProject.end_date_estimate ? shownProject.end_date_estimate : '0' }}% Complete
          </h6>
          <div class="ml-auto d-flex align-items-center pl-3 project-actions">
            <app-split-button
              *ngIf="statusId === 1 && isWorkspaceStaff"
              class="ml-2"
              lineHeight="24"
              [matMenu]="statusMenu"
              (clickEvent)="closeProject()"
            >
              Close Project
            </app-split-button>
            <app-split-button
              *ngIf="statusId !== 1"
              class="ml-2"
              lineHeight="24"
              [matMenu]="statusMenu"
              (clickEvent)="setProjectToActive()"
            >
              Set to Active
            </app-split-button>
            <mat-menu #statusMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="setProjectToActive()" *ngIf="isWorkspaceStaff && statusId !== 1">
                <mat-icon>label_important</mat-icon>
                <span> Set to Active </span>
              </button>
              <button mat-menu-item *ngIf="isWorkspaceStaff && statusId !== 2" (click)="markAsPlanned()">
                <mat-icon>next_plan</mat-icon>
                <span> Mark As Planned </span>
              </button>
              <button mat-menu-item *ngIf="isWorkspaceStaff && statusId !== 3" (click)="placeOnHold()">
                <mat-icon>flag</mat-icon>
                <span> Place On Hold </span>
              </button>
              <button *ngIf="isWorkspaceStaff && statusId !== 4" (click)="closeProject()" mat-menu-item>
                <mat-icon>done_all</mat-icon>
                <span> Close Project </span>
              </button>
            </mat-menu>
            <button
              data-test-id="project-header-more-action-button"
              mat-button
              [matMenuTriggerFor]="project_master_menu"
              class="ml-2 lh-34 pl-2 pr-2 bg-ltltltblue"
              color="accent"
              [disabled]="!isWorkspaceStaff"
            >
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #project_master_menu="matMenu" class="mt-2" xPosition="before">
              <button
                data-test-id="project-header-edit-project-button"
                mat-menu-item
                color="primary"
                (click)="openProjectDetailsDialog()"
              >
                <mat-icon>assignment</mat-icon>
                <span *ngIf="isWorkspaceStaff">Edit Project Details</span>
                <span *ngIf="!isWorkspaceStaff">View Project Details</span>
              </button>
              <button
                data-test-id="project-header-more-action-create-task-button"
                mat-menu-item
                color="primary"
                (click)="createTask()"
              >
                <mat-icon>assignment_turned_in</mat-icon>
                <span> Create Task </span>
              </button>
              <button
                data-test-id="project-header-more-action-create-meeting-button"
                mat-menu-item
                color="primary"
                (click)="createMeeting()"
              >
                <mat-icon>event</mat-icon>
                <span> Create Meeting </span>
              </button>
              <button
                data-test-id="project-header-more-action-add-meeting-agenda-button"
                mat-menu-item
                color="primary"
                (click)="createMeetingAgendaFromProject(shownProject)"
              >
                <mat-icon>event_notes</mat-icon>
                <span> Add to Meeting Agenda </span>
              </button>
              <button
                [disabled]="statusId === ProjectStatus.CLOSED"
                mat-menu-item
                (click)="projectFollowers.editFollowers()"
              >
                <mat-icon>group_add</mat-icon>
                <span>Manage Followers</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="project-details align-items-center d-flex flex-wrap">
          <div class="d-flex align-items-center pr-3 border-right border-gray" *ngIf="shownProject.status">
            <h6
              class="m-0 badge white bg-dkblue text-uppercase px-2 mr-3"
              [matTooltip]="'WS: ' + workspaceName"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              {{ workspaceName }}
            </h6>
            <!--            TODO Adam which color do we want for Planned?-->
            <h6
              class="m-0 badge white text-uppercase px-2 mr-3"
              [ngClass]="{
                'bg-green': shownProject.status.id === 1,
                'bg-ltblue': shownProject.status.id === 2,
                'bg-red': shownProject.status.id === 3,
                'bg-gray': shownProject.status.id === 4
              }"
              matTooltip="Status: {{ shownProject.status.name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              {{ shownProject.status.name }}
            </h6>
            <h6
              class="m-0 white d-inline-block align-middle badge text-uppercase px-2 mr-3 me-3"
              [ngClass]="{
                'bg-dkred': shownProject.priority_id === 4,
                'bg-red': shownProject.priority_id === 3,
                'bg-ltblue': shownProject.priority_id === 2,
                'bg-gray': shownProject.priority_id === 1
              }"
              matTooltip="Priority: {{ shownProject.priority_name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              {{ shownProject.priority_name }}
            </h6>
            <h6
              class="bg-gray white m-0 badge text-uppercase px-2 lh-20"
              matTooltip="Current Phase"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              {{ shownProject.current_phase_name }}
            </h6>
          </div>
          <div
            *ngIf="divWidth > 1100 && (isWorkspaceStaff || authService.isTenant)"
            class="d-flex align-items-center px-3 border-left border-right border-gray"
          >
            <h6
              *ngIf="shownProject.end_date"
              class="m-0 mr-3 ltblue bg-shade-ltblue br-4 px-2"
              matTooltip="Estimated Completion Date: {{ shownProject.end_date | date: 'MMM dd, yyyy' }} - {{
                shownProject.on_schedule ? 'On Schedule' : 'Behind Schedule'
              }} - {{ shownProject.end_date_estimate ? shownProject.end_date_estimate : '0' }}% Complete"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              <span class="dkblue"> ECD: </span>
              &nbsp;{{ shownProject.end_date | date: 'MMM dd yyyy' }}
            </h6>
            <h6
              class="m-0 ltblue bg-shade-ltblue pointer br-4 px-2 mr-3"
              matTooltip="Project Manager: {{ shownProject.project_manager_first_name }} {{
                shownProject.project_manager_last_name
              }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="1000"
              (click)="openUserProfilePreview(shownProject.project_manager_id)"
            >
              <span class="dkblue"> PM: </span>
              &nbsp;
              {{ shownProject.project_manager_first_name }}
              {{ shownProject.project_manager_last_name }}
            </h6>
            <h6
              class="m-0 ltblue bg-shade-ltblue pointer br-4 px-2"
              matTooltip="Architect: {{ shownProject.architect_first_name }} {{ shownProject.architect_last_name }} {{
                !shownProject.architect_first_name && !shownProject.architect_last_name ? 'Not Needed' : ''
              }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="1000"
              (click)="openUserProfilePreview(shownProject.architect_id)"
            >
              <span class="dkblue"> AR: </span>
              &nbsp;
              <span *ngIf="shownProject.is_architect_required"
                >{{ shownProject.architect_first_name }} {{ shownProject.architect_last_name }}</span
              >
              <span *ngIf="!shownProject.is_architect_required"> Not Needed</span>
            </h6>
          </div>
          <!-- TAGS -->
          <div class="d-flex align-items-center px-3 border-left border-gray" *ngIf="isStaff">
            <h6
              *ngFor="let tag of shownProject?.tags || []"
              class="m-0 mr-3 ltblue border border-gray tag px-2"
              matTooltip="{{ tag.name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              {{ tag.abbreviation }}
            </h6>
          </div>
          <div class="d-flex align-items-center ml-auto">
            <app-project-followers #projectFollowers [project]="shownProject" *ngIf="!isVendor"></app-project-followers>
          </div>
        </div>
      </header>
      <header id="project-header-links" class="project-header-links ease">
        <button
          matRipple
          [routerLink]="['/projects/' + projectId + '/overview']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'overview' }"
          class="ease"
        >
          <mat-icon>view_day</mat-icon>
          <div data-test-id="project-overview-item-button" class="d-inline-block align-middle">Overview</div>
        </button>
        <button
          matRipple
          data-test-id="project-overview-item-tasks-button"
          [routerLink]="['/projects/' + projectId + '/tasks']"
          [ngClass]="{
            'dkblue subcomponent ': subcomponent.name === 'tasks' || subcomponent.name === 'linked-tasks'
          }"
          class="ease"
        >
          <mat-icon>assignment_turned_in</mat-icon>
          <div class="d-inline-block align-middle">Tasks</div>
        </button>
        <button
          matRipple
          data-test-id="project-overview-item-forms-menu-button"
          class="ease pr-3"
          [matMenuTriggerFor]="forms_menu"
          *ngIf="(isWorkspaceStaff ||
              authService.isProjectVendor(projectService.currentSelectedProjectId) ||
              authService.isProjectEngineer(projectService.currentSelectedProjectId))
          "
        >
          <mat-icon>list_alt</mat-icon>
          <div class="d-inline-block align-middle">Forms</div>
          <mat-icon class="m-0 ml-2 arrow_drop_down">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #forms_menu="matMenu" xPosition="after">
          <button
            *ngIf="isWorkspaceStaff"
            data-test-id="project-details-option-button"
            (click)="openProjectDetailsDialog()"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>assignment</mat-icon>
            <span class="font-weight-semibold dkgray">Project Details</span>
          </button>
          <button
            *ngIf="isWorkspaceStaff"
            data-test-id="program-data-option-button"
            [routerLink]="['/projects/' + projectId + '/programming']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>fact_check</mat-icon>
            <span class="font-weight-semibold dkgray">Program Data</span>
          </button>
          <mat-divider></mat-divider>
          <button
            data-test-id="drawings-option-button"
            *ngIf="isWorkspaceStaff || authService.isProjectEngineer(projectService.currentSelectedProjectId)"
            [routerLink]="['/projects/' + projectId + '/drawings']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>architecture</mat-icon>
            <span class="font-weight-semibold dkgray">Drawings</span>
          </button>
          <button
            data-test-id="addendums-option-button"
            [routerLink]="['/projects/' + projectId + '/addendums']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>published_with_changes</mat-icon>
            <span class="font-weight-semibold dkgray">Addendums</span>
          </button>
          <mat-divider></mat-divider>
          <button
            data-test-id="rfis-option-button"
            [routerLink]="['/projects/' + projectId + '/rfi']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>question_answer</mat-icon>
            <span class="font-weight-semibold dkgray">RFIs</span>
          </button>
          <mat-divider></mat-divider>
          <button
            data-test-id="submittals-option-button"
            [routerLink]="['/projects/' + projectId + '/submittals']"
            mat-menu-item
            class="primary ease pr-5"
            *ngIf="isWorkspaceStaff"
          >
            <mat-icon>list_alt</mat-icon>
            <span class="font-weight-semibold dkgray">Submittals</span>
          </button>
          <mat-divider></mat-divider>
          <button
            data-test-id="punch-list-option-button"
            *ngIf="isWorkspaceStaff || authService.isProjectEngineer(projectService.currentSelectedProjectId)"
            [routerLink]="['/projects/' + projectId + '/punchlist']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>playlist_add_check</mat-icon>
            <span class="font-weight-semibold dkgray">Punch List</span>
          </button>
          <button
            data-test-id="as-builts-option-button"
            [routerLink]="['/projects/' + projectId + '/as-builts']"
            mat-menu-item
            class="primary ease pr-5"
          >
            <mat-icon>receipt_long</mat-icon>
            <span class="font-weight-semibold dkgray">As Builts</span>
          </button>
        </mat-menu>
        <!-- <button
          matRipple
          data-test-id="project-overview-item-budget-button"
          [routerLink]="['/projects/' + projectId + '/budget']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'budget' }"
          class="ease"
          *ngIf="isWorkspaceStaff"
        >
          <mat-icon>monetization_on</mat-icon>
          <div class="d-inline-block align-middle">Budget</div>
        </button>
        <button
          data-test-id="project-overview-item-forms-menu-button"
          class="ease pr-3"
          [matMenuTriggerFor]="budget_menu"
          *ngIf="(isWorkspaceStaff ||
              authService.isProjectVendor(projectService.currentSelectedProjectId) ||
              authService.isProjectEngineer(projectService.currentSelectedProjectId))
          "
        >
          <mat-icon>list_alt</mat-icon>
          <div class="d-inline-block align-middle">Budget</div>
          <mat-icon class="m-0 ml-2">arrow_drop_down</mat-icon>
        </button> -->

        <button
          matRipple
          data-test-id="project-overview-item-budget-button"
          [routerLink]="['/projects/' + projectId + '/budget']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'budget' }"
          class="ease"
          *ngIf="isWorkspaceStaff"
        >
          <mat-icon>monetization_on</mat-icon>
          <div class="d-inline-block align-middle">Budget</div>
        </button>
        <button
          matRipple
          [matMenuTriggerFor]="menu"
          data-test-id="project-overview-item-budget-menu-button"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'budget' }"
          class="ease px-2 mx-0 border-left-none"
          *ngIf="isWorkspaceStaff"
        >
          <mat-icon class="m-0 arrow_drop_down">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="after">
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/peb']">
            <!-- <mat-icon>price_change</mat-icon> -->
            <mat-icon>list_alt</mat-icon>
            <span class="font-weight-semibold dkgray">PEB</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/bids']">
            <mat-icon>format_list_numbered</mat-icon>
            <span class="font-weight-semibold dkgray">Bid Packages</span>
          </button>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/construction-budget']">
            <mat-icon>price_check</mat-icon>
            <span class="font-weight-semibold dkgray">Construction Budget</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/proposal-requests']">
            <mat-icon>price_change</mat-icon>
            <span class="font-weight-semibold dkgray">Proposal Requests</span>
          </button>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/change-orders']">
            <mat-icon>price_change</mat-icon>
            <span class="font-weight-semibold dkgray">Change Orders</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/invoices']">
            <mat-icon>request_quote</mat-icon>
            <span class="font-weight-semibold dkgray">Invoices</span>
          </button>
          <button mat-menu-item [routerLink]="['/projects/' + projectId + '/budget']">
            <mat-icon>analytics</mat-icon>
            <span class="font-weight-semibold dkgray">Budget Overview</span>
          </button>
        </mat-menu>
        <button
          matRipple
          data-test-id="project-overview-item-invoices-button"
          [routerLink]="['/projects/' + projectId + '/invoices']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'invoices' }"
          class="ease"
          *ngIf="authService.isProjectVendor(projectService.currentSelectedProjectId)"
        >
          <mat-icon>monetization_on</mat-icon>
          <div class="d-inline-block align-middle">Invoices</div>
        </button>
        <button
          matRipple
          data-test-id="project-overview-item-timeline-button"
          [hidden]="divWidth < 676"
          [routerLink]="['/projects/' + projectId + '/schedule']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'schedule' }"
          class="ease"
          *ngIf="(isWorkspaceStaff ||
              authService.isProjectVendor(projectService.currentSelectedProjectId) ||
              authService.isProjectEngineer(projectService.currentSelectedProjectId))
          "
        >
          <mat-icon>timeline</mat-icon>
          <div class="d-inline-block align-middle">Timeline</div>
        </button>
        <button
          matRipple
          data-test-id="project-overview-item-files-button"
          *ngIf="isWorkspaceStaff && divWidth > 770"
          [routerLink]="['/projects/' + projectId + '/files']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'files' }"
          class="ease"
        >
          <mat-icon>insert_drive_file</mat-icon>
          <div class="d-inline-block align-middle">Files</div>
        </button>
        <button
          matRipple
          data-test-id="project-overview-item-directory-button"
          *ngIf="divWidth > 900"
          [routerLink]="['/projects/' + projectId + '/project-directory']"
          [ngClass]="{ 'dkblue subcomponent ': subcomponent.name === 'project-directory' }"
          class="ease"
        >
          <mat-icon>people</mat-icon>
          <div class="d-inline-block align-middle">Directory</div>
        </button>
        <button
          data-test-id="project-overview-item-more-button"
          *ngIf="divWidth < 901"
          [matMenuTriggerFor]="more_menu"
          class="ease"
        >
          <span class="d-inline-block align-middle">More</span>
          <mat-icon class="m-0 ml-2">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #more_menu="matMenu" xPosition="before">
          <button
            *ngIf="divWidth < 676"
            [routerLink]="['/projects/' + projectId + '/schedule']"
            [ngClass]="subcomponent.name === 'schedule' ? 'subcomponent-menu-button' : 'ltblue'"
            mat-menu-item
            color="primary"
          >
            <mat-icon [ngClass]="subcomponent.name === 'schedule' ? 'dkblue' : 'ltblue'">timeline</mat-icon>
            &nbsp;
            <span [ngClass]="subcomponent.name === 'schedule' ? 'dkblue' : 'ltblue'"> Timeline </span>
          </button>
          <button
            *ngIf="isWorkspaceStaff && divWidth < 771"
            [routerLink]="['/projects/' + projectId + '/files']"
            [ngClass]="subcomponent.name === 'files' ? 'subcomponent-menu-button' : 'ltblue'"
            mat-menu-item
            color="primary"
          >
            <mat-icon [ngClass]="subcomponent.name === 'files' ? 'dkblue' : 'ltblue'">insert_drive_file</mat-icon>
            &nbsp;
            <span [ngClass]="subcomponent.name === 'files' ? 'dkblue' : 'ltblue'"> Files </span>
          </button>
          <button
            *ngIf="divWidth < 901"
            [routerLink]="['/projects/' + projectId + '/project-directory']"
            [ngClass]="subcomponent.name === 'project-directory' ? 'subcomponent-menu-button' : 'ltblue'"
            mat-menu-item
            color="primary"
          >
            <mat-icon [ngClass]="subcomponent.name === 'project-directory' ? 'dkblue' : 'ltblue'">people</mat-icon>
            &nbsp;
            <span [ngClass]="subcomponent.name === 'project-directory' ? 'dkblue' : 'ltblue'"> Directory </span>
          </button>
        </mat-menu>
      </header>
    </div>
    <div class="under-header-padding"></div>
    <div
      class="project-content ease"
      [ngClass]="{
        'project-content-top-banner': statusId === ProjectStatus.CLOSED,
        'nav-closed':
          isSidenavClosed() && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'nav-open':
          !isSidenavClosed() && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,
        'notifications-sidenav-open':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed(),
        'notifications-sidenav-closed':
          (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed()
      }"
    >
      <!-- SUBCOMPONENTS-->

      <app-project-overview *ngIf="subcomponent.name === 'overview'" class="col-12"></app-project-overview>

      <app-tasks *ngIf="subcomponent.name === 'tasks'" (phaseUpdated)="phaseUpdated($event)"></app-tasks>

      <app-submittals [project]="shownProject" style="padding: 0" *ngIf="subcomponent.name === 'submittals'">
      </app-submittals>

      <div *ngIf="subcomponent.name !== 'tasks' && subcomponent.name !== 'submittals'">
        <app-project-meetings *ngIf="subcomponent.name === 'meetings'"></app-project-meetings>

        <app-project-budget [project]="shownProject" *ngIf="subcomponent.name === 'budget'"></app-project-budget>

        <app-files [project]="shownProject" *ngIf="subcomponent.name === 'files'"></app-files>

        <app-rfi [project]="shownProject" *ngIf="subcomponent.name === 'rfi'"></app-rfi>

        <app-bids [project]="shownProject" *ngIf="subcomponent.name === 'bids'"></app-bids>

        <app-construction-budget *ngIf="subcomponent.name === 'construction-budget'"></app-construction-budget>

        <app-peb-old [project]="shownProject" *ngIf="subcomponent.name === 'peb' && !usesNewPEB"></app-peb-old>
        <app-peb-new [project]="shownProject" *ngIf="subcomponent.name === 'peb' && usesNewPEB"></app-peb-new>

        <app-project-directory *ngIf="subcomponent.name === 'project-directory'"></app-project-directory>
        <app-project-details #detailsComponent [project]="shownProject" *ngIf="subcomponent.name === 'details'">
        </app-project-details>

        <app-project-timeline
          [project]="shownProject"
          *ngIf="subcomponent.name === 'schedule'"
          (phaseUpdated)="phaseUpdated($event)"
        ></app-project-timeline>

        <app-program-data *ngIf="subcomponent.name === 'programming'"></app-program-data>

        <app-invoices *ngIf="subcomponent.name === 'invoices'"></app-invoices>

        <app-proposal-requests [project]="shownProject" *ngIf="subcomponent.name === 'proposal-requests'">
        </app-proposal-requests>

        <app-change-orders [project]="shownProject" *ngIf="subcomponent.name === 'change-orders'"></app-change-orders>

        <app-addendums [project]="shownProject" *ngIf="subcomponent.name === 'addendums'"></app-addendums>

        <app-project-drawings *ngIf="subcomponent.name === 'drawings'"></app-project-drawings>

        <app-punchlist *ngIf="subcomponent.name === 'punchlist'"></app-punchlist>

        <app-as-builts [project]="shownProject" *ngIf="subcomponent.name === 'as-builts'"></app-as-builts>
      </div>
    </div>
  </div>
</div>
