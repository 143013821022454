import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-items-dialog',
  templateUrl: './select-items-dialog.component.html',
  styleUrls: ['./select-items-dialog.component.scss'],
})
export class SelectItemsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SelectItemsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  public items: any;
  public itemType: string;
  public submitText: string;

  async ngOnInit() {
    if (this.data) {
      this.items = this.data.items || [];
      this.itemType = this.data.itemType || '';
      this.submitText = this.data.submitText || 'Export PDF';
    }

    this.items.forEach((item) => {
      item.selected = true;
    });
  }

  selectAll() {
    this.items.forEach((item) => {
      item.selected = true;
      item.selectedAttachments = true;
    });
  }

  deselectAll() {
    this.items.forEach((item) => {
      item.selected = false;
      item.selectedAttachments = false;
    });
  }

  submit() {
    this.dialogRef.close(this.items.filter((i) => i.selected === true));
  }

  close() {
    this.dialogRef.close(false);
  }
}
