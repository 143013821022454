<header class="my-4">
  <div class="d-flex align-items-center">
    <h4 class="primary m-0">Buildings</h4>
  </div>
</header>
<div class="d-flex align-items-center mb-4">
  <mat-form-field appearance="outline">
    <mat-icon class="dkblue" matPrefix>search</mat-icon>
    <input matInput placeholder="Search Buildings" [(ngModel)]="search" (ngModelChange)="paginator.pageIndex = 0" />
    <mat-icon *ngIf="search" class="gray pointer" (click)="search = null; paginator.pageIndex = 0" matSuffix
      >cancel
    </mat-icon>
  </mat-form-field>
  <mat-slide-toggle color="accent" [(ngModel)]="showInactive">
    <span class="font-weight-semibold">Show Inactive Buildings</span>
  </mat-slide-toggle>
  <button
    *ngIf="isBuildingsManager"
    class="mr-3 bg-white"
    mat-stroked-button
    color="gray"
    (click)="exportBuildingData()"
  >
    <i class="material-icons-outlined"> file_download </i>
    Download Buildings CSV
  </button>
  <button mat-flat-button color="success" (click)="openBuildingDialog('Add')">Add Building</button>
</div>

<div class="m-portlet">
  <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
    <div class="row align-items-center">
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('id')">
        <h6 class="m-0 text-truncate header-title mr-auto">ID</h6>
        <i *ngIf="sortProperty === 'id'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Name</h6>
        <i *ngIf="sortProperty === 'name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('code')">
        <h6 class="m-0 text-truncate header-title mr-auto">Code</h6>
        <i *ngIf="sortProperty === 'code'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('code')">
        <h6 class="m-0 text-truncate header-title mr-auto">Physical Address</h6>
        <i *ngIf="sortProperty === 'code'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('is_enabled')">
        <h6 class="m-0 text-truncate header-title mr-auto">Active</h6>
        <i *ngIf="sortProperty === 'is_enabled'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Floors</h6>
      </div>
    </div>
  </div>
  <div>
    <div
      *ngFor="
        let building of buildings
          | isEnabled: showInactive
          | filter: search:['name', 'code']:filteredCount
          | orderByField: sortProperty:sortDirection
          | paginator: paginator.pageIndex:paginator.pageSize
      "
      class="m-portlet__body item p-3 pt-4 pb-4"
      data-id-test="selected-request-type-wrapper"
    >
      <div class="row align-items-center">
        <div class="col-1 d-flex align-items-center data-item" [ngClass]="{ gray: building.is_enabled !== 1 }">
          {{ building.id }}
        </div>
        <div class="col-3 d-flex align-items-center data-item">
          <h6
            class="primary m-0 hover-u pointer"
            (click)="openBuildingDialog('Edit', building)"
            [ngClass]="{ gray: building.is_enabled !== 1 }"
          >
            {{ building.name }}
          </h6>
        </div>
        <div class="col-1 d-flex align-items-center data-item">
          <h6 class="primary m-0" [ngClass]="{ gray: building.is_enabled !== 1 }">
            {{ building.code }}
          </h6>
        </div>
        <div class="col-3 d-flex align-items-center data-item">
          <h6 class="primary font-weight-normal m-0" [ngClass]="{ gray: building.is_enabled !== 1 }">
            <ng-container *ngIf="building.physical_address">
              {{ building.physical_address?.address || '' }} <br />
              {{ building.physical_address?.city || '' }}, {{ building.physical_address?.state || '' }},
              {{ building.physical_address?.zipcode || '' }}
            </ng-container>
          </h6>
        </div>
        <div class="col-1 d-flex align-items-center data-item">
          <p class="m-0 lh-20">
            <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="building.is_enabled === 1"> Active </span>
            <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="building.is_enabled !== 1"> Inactive </span>
          </p>
        </div>
        <div class="col d-flex flex-wrap align-items-center data-item">
          <span
            *ngFor="let floor of building.floors; let index = index; let last = last"
            class="px-2 py-1 lh-20 br-10 m-1"
            [ngClass]="{
              'gray bg-shade-gray': building.is_enabled !== 1,
              'dkblue bg-shade-ltblue': building.is_enabled === 1
            }"
            >{{ floor.code }}</span
          >
        </div>
        <div class="col-1 d-flex justify-content-end">
          <button (click)="openBuildingDialog('Edit', building)" mat-icon-button color="accent" type="button">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="+filteredCount?.count === 0" class="m-portlet__body">No Buildings Found</div>
  </div>
  <div>
    <mat-paginator
      #paginator
      [length]="filteredCount?.count"
      pageSize="25"
      (page)="pageEvent = $event"
      aria-label="Select page"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </div>
</div>
