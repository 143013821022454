<app-base-dialog
  title="{{ isLoading ? '' : isEditing ? 'Edit' : 'Add' }} Submittals - {{ bidPackage?.number }} {{
    bidPackage?.number ? '-' : ''
  }} {{ bidPackage?.trade_name }}"
  (closeDialog)="cancel()"
></app-base-dialog>
<div id="add-submittals-dialog" data-test-id="add-submittals-dialog" class="add-submittals-dialog">
  <div class="d-flex flex-column align-items-center view-key-controls-dialog p-5 mx-auto" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="40"></mat-spinner>
  </div>
  <div class="row">
    <div class="col-lg-12 submittals-data">
      <section [hidden]="c.specs.length === 0" class="section mb-4 pb-2" *ngFor="let c of submittalCategories">
        <div class="d-flex align-items-center mb-2">
          <div (click)="c.is_collapsed = !c.is_collapsed" class="d-flex align-items-center mr-auto pointer">
            <mat-icon class="pointer" *ngIf="c.is_collapsed">chevron_right</mat-icon>
            <mat-icon class="pointer" *ngIf="!c.is_collapsed">expand_more</mat-icon>
            <h5 class="dkblue m-0 ml-3 font-weight-bold">{{ c.code }}<span class="ml-3 mr-3">•</span>{{ c.name }}</h5>
          </div>
          <button
            *ngIf="!c.all_specs_selected && !c.is_collapsed"
            mat-button
            color="accent"
            type="button"
            class="lh-30 px-2"
            (click)="selectCategorySpecs(c)"
          >
            Select All
          </button>
          <button
            *ngIf="c.all_specs_selected && !c.is_collapsed"
            mat-button
            color="accent"
            type="button"
            class="lh-30 px-2"
            (click)="deselectCategorySpecs(c)"
          >
            Deselect All
          </button>
        </div>
        <div *ngIf="!c.is_collapsed" class="m-portlet d-flex flex-column full-width mb-4">
          <div
            matRipple
            *ngFor="let s of c.specs; let last = last"
            class="m-portlet__body d-flex item ease p-2 px-3 pointer"
            [ngClass]="{ 'bg-shade-green': s.is_added, 'item-last': last }"
            (click)="toggleSpecSelection(s)"
          >
            <div class="d-flex full-width align-items-center">
              <h5 class="m-0 mr-3 font-weight-semibold dkgray text-nowrap">
                {{ s.code }}
              </h5>
              <h5 class="m-0 dkblue mr-3 font-weight-semibold text-truncate text-nowrap">
                {{ s.name }}
              </h5>
              <button mat-icon-button color="success" class="ml-auto" type="button">
                <mat-icon>{{ !s.is_added ? 'add_circle' : 'check_circle' }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="row mb-2 mt-4 align-items-center">
    <div class="col font-weight-bold" *ngIf="selectedSpecIds?.length">
      <p class="dkblue font-weight-normal mb-0">
        {{ selectedSpecIds.length }} submittal{{ selectedSpecIds.length === 1 ? '' : 's' }} for
        {{ bidPackage?.trade_name }}
      </p>
    </div>
    <div class="col text-right">
      <button (click)="cancel()" mat-button type="button" class="gray">Cancel</button>
      <button
        (click)="submit()"
        mat-flat-button
        type="submit"
        color="accent"
        class="ml-3"
        [disabled]="!selectedSpecIds?.length && !removeSpecIds?.length"
      >
        {{ isLoading ? '' : isEditing ? 'Edit' : 'Request' }} Submittals
      </button>
    </div>
  </div>
</div>
