import { Pipe, PipeTransform } from '@angular/core';
import { RequestListFilter } from '../models';
import { Request } from '../types';

@Pipe({
  name: 'requestListFilter',
})
export class RequestListFilterPipe implements PipeTransform {
  transform(requests: Request[], filter: RequestListFilter): any {
    if (!requests) {
      return [];
    }

    if (filter.statusId && filter.statusId !== -1) {
      requests = requests.filter((item) => item.status_id === filter.statusId);
    }

    if (filter.workspaceIds.length > 0) {
      requests = requests.filter((r) => filter.workspaceIds.includes(+r.module_id));
    }

    if (!filter?.searchTerm) {
      return requests;
    } else {
      const searchTerm = filter?.searchTerm?.toLowerCase();

      return requests.filter((request: Request) => {
        return (
          request?.building?.name?.toLowerCase()?.includes(searchTerm) ||
          request?.building?.code?.toLowerCase()?.includes(searchTerm) ||
          request.floor?.code?.toLowerCase()?.includes(searchTerm) ||
          request.floor?.name?.toLowerCase()?.includes(searchTerm) ||
          `${request.building?.code}-${request.floor?.code}`.toLowerCase()?.includes(searchTerm) ||
          `${request.building?.code}-${request.floor?.name}`.toLowerCase()?.includes(searchTerm) ||
          request.short_description?.toLowerCase()?.includes(searchTerm) ||
          request.request_type?.name?.toLowerCase()?.includes(searchTerm) ||
          request.budget?.name?.toLowerCase()?.includes(searchTerm) ||
          `${request.requester?.first_name + ' ' + request.requester?.last_name}`
            ?.toLowerCase()
            ?.includes(searchTerm) ||
          request.code?.toLowerCase()?.includes(searchTerm)
        );
      });
    }
  }
}
