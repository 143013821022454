import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as moment from 'moment';

import { ConfirmationDialogComponent, DatepickerHeaderComponent } from 'src/app/components';
import { TaskStatus } from 'src/app/enums';
import { ProjectService } from '../../services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-milestone-dialog',
  templateUrl: './milestone-dialog.component.html',
  styleUrls: ['./milestone-dialog.component.scss'],
})
export class MilestoneDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MilestoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public milestone,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private dialog: MatDialog
  ) {}

  action: string;
  public customHeader = DatepickerHeaderComponent;

  minValue = 0;
  maxValue = 100;
  step = 1;

  milestoneFormGroup: FormGroup = this.fb.group(
    {
      name: [this.milestone ? this.milestone.name : '', [Validators.required]],
      start_date: [this.milestone.start_date ? new Date(this.milestone.start_date) : ''],
      end_date: [this.milestone.end_date ? new Date(this.milestone.end_date) : ''],
      progress: [this.percentageOfTasksDone],
      assign_end_date_to_tasks: false,
      manual_progress: [!!this.isProgressManuallySet],
    },
    { validator: this.validateDates }
  );

  validateDates(group: FormGroup) {
    if (group) {
      const start_date = group.controls.start_date;
      const end_date = group.controls.end_date;
      if (start_date.value && end_date.value && start_date.value > end_date.value) {
        start_date.setErrors({ match: true });
        end_date.setErrors({ match: true });
        return { invalidDates: true };
      } else {
        start_date.setErrors(null);
        end_date.setErrors(null);
        return null;
      }
    }

    return null;
  }

  public get isProgressManuallySet(): boolean {
    if (this.milestone?.progress === 0) {
      return true;
    }

    if (this.milestone?.progress) {
      return true;
    }

    return false;
  }

  get name() {
    return this.milestoneFormGroup.get('name');
  }

  get start_date() {
    return this.milestoneFormGroup.get('start_date');
  }

  get end_date() {
    return this.milestoneFormGroup.get('end_date');
  }

  get assign_end_date_to_tasks() {
    return this.milestoneFormGroup.get('assign_end_date_to_tasks');
  }

  get manual_progress() {
    return this.milestoneFormGroup.get('manual_progress');
  }

  get progress() {
    return this.milestoneFormGroup.get('progress');
  }

  public get percentageOfTasksDone() {
    if (this.isProgressManuallySet) {
      return +this.milestone.progress;
    }

    // Dynamic calculation
    // check if its a valid number
    const totalTasks = this.milestone?.tasks?.length;
    if (isNaN(totalTasks) || totalTasks === 0) {
      return 0;
    }

    // this will always be a number
    const doneTaskCount = this.milestone?.tasks
      ?.filter(({ status_id }) => +status_id === TaskStatus.Complete)
      .reduce((count) => {
        return (count += 1);
      }, 0);

    return Math.round((doneTaskCount / totalTasks) * 100);
  }

  ngOnInit() {
    this.action = this.milestone && this.milestone.id ? 'Edit' : 'Add';
  }

  submit(): void {
    if (this.milestoneFormGroup.valid) {
      if (this.assign_end_date_to_tasks.value) {
        const tasksToUpdate = this.milestone.tasks.filter(
          (t) => moment(t.due_date).startOf('day') !== moment(this.end_date.value).startOf('day')
        );
        if (tasksToUpdate?.length > 0) {
          this.dialog
            .open(ConfirmationDialogComponent, {
              data: {
                titleBarText: 'Set Due Dates',
                headerText: `Set task due dates to milestone end date`,
                descriptionText: 'Warning: any existing due dates will be overwritten. This action cannot be undone.',
                confirmationButtonText: 'Set Due Dates',
              },
            })
            .afterClosed()
            .subscribe(async (isConfirmed) => {
              if (isConfirmed) {
                for (const task of this.milestone?.tasks ?? []) {
                  const dueDate = moment(this.end_date.value).format('YYYY-MM-DD');
                  task.due_date = dueDate;

                  await this.projectService
                    .updateTask({
                      id: task.id,
                      due_date: dueDate,
                    })
                    .toPromise();
                }
                this.updateMilestone();
                this.dialogRef.close(this.milestone);
              }
            });
        }
      } else {
        this.updateMilestone();
        this.dialogRef.close(this.milestone);
      }
    }
  }

  updateMilestone() {
    // the user can toggle between manual and dynamic
    // manual sets the progress value
    // dynamic sets the progress to null
    this.milestone.progress = this.manual_progress?.value ? this.progress?.value || 0 : null;
    this.milestone.name = this.name.value;
    this.milestone.start_date = this.start_date.value ? moment(this.start_date.value).format('YYYY-MM-DD') : null;
    this.milestone.end_date = this.end_date.value ? moment(this.end_date.value).format('YYYY-MM-DD') : null;
  }

  close(): void {
    this.dialogRef.close();
  }
}
