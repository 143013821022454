import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { remove } from 'lodash';

import { EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { AsBuilt } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-as-built-dialog',
  templateUrl: './as-built-dialog.component.html',
  styleUrls: ['./as-built-dialog.component.scss'],
})
export class AsBuiltDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  newFiles = [];

  constructor(
    public dialogRef: MatDialogRef<AsBuiltDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public asBuilt: AsBuilt,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  asBuiltFormGroup: FormGroup = this.fb.group({
    title: [this.asBuilt ? this.asBuilt.title : '', [Validators.required]],
  });

  get title() {
    return this.asBuiltFormGroup.get('title');
  }
  get description() {
    return this.asBuiltFormGroup.get('description');
  }

  async ngOnInit() {
    this._activateDescriptionEditor();
    this.newFiles.concat(this.asBuilt.files);
  }

  private _activateDescriptionEditor() {
    this.asBuiltFormGroup.addControl('description', this._editor_component.content);
    this.description.setValidators([Validators.required]);
    this.description.updateValueAndValidity();

    if (this.asBuilt?.description) {
      this.description.setValue(this.asBuilt.description);
    }
  }
  removeFile(file) {
    remove(this.newFiles, (f) => f.name === file.name);
  }

  submit(): void {
    if (this.asBuiltFormGroup.valid) {
      const asBuiltToReturn: AsBuilt = {
        title: this.title.value,
        description: this.description.value,
        files: this.newFiles || [],
      };
      if (this.asBuilt && this.asBuilt.id) {
        asBuiltToReturn.id = this.asBuilt.id;
      }
      this.dialogRef.close(asBuiltToReturn);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.asBuilt.project_id,
          allowComment: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          for (const f of resultData) {
            if (this.newFiles.find((fi) => fi.name === f.name)) {
              this.snackbar.open(`File with name '${f.name}' already exists!`);
            } else {
              this.newFiles.push({ id: f.file_id, name: f.name });
            }
          }
        }
      });
  }
}
