import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { ProjectService, ProgressIndicatorService } from 'src/app/services';

import { USDollarPipe } from 'src/app/pipes';

import { MilestoneDialogComponent } from 'src/app/components';
import { BidPackage } from '../../types';

@Component({
  selector: 'app-bid-package-dialog',
  templateUrl: './bid-package-dialog.component.html',
  styleUrls: ['./bid-package-dialog.component.scss'],
})
export class BidPackageDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MilestoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar
  ) {}

  bidPackage: BidPackage;
  newBidPackage: boolean;
  title: string;
  trades;
  USDollarPipe = new USDollarPipe();

  bidPackageFormGroup: FormGroup = this.fb.group({
    trade_id: [this.data.bidPackage ? this.data.bidPackage.trade_id : null, [Validators.required]],
    number: [this.data.bidPackage ? this.data.bidPackage.number : null, [Validators.required]],
    square_footage: [this.data.bidPackage ? this.data.bidPackage.square_footage : null],
    cost_per_sqft: [this.data.bidPackage ? this.data.bidPackage.cost_per_sqft : null],
    budget_amount: [this.data.bidPackage ? this.data.bidPackage.budget_amount : null],
  });

  get trade_id() {
    return this.bidPackageFormGroup.get('trade_id');
  }
  get number() {
    return this.bidPackageFormGroup.get('number');
  }
  get square_footage() {
    return this.bidPackageFormGroup.get('square_footage');
  }
  get cost_per_sqft() {
    return this.bidPackageFormGroup.get('cost_per_sqft');
  }
  get budget_amount() {
    return this.bidPackageFormGroup.get('budget_amount');
  }

  async ngOnInit() {
    const allTrades = await this.projectService.getTrades().toPromise();
    const bidPackageTradeIds = this.data.bidPackageTradeIds || [];
    this.bidPackage = (this.data?.bidPackage?.id && this.data?.bidPackage) || null;
    this.newBidPackage = !this.bidPackage?.id;
    this.title = this.newBidPackage ? 'Add' : 'Edit';

    if (!this.newBidPackage) {
      // Finds the trade associated with the passed in bid package
      this.trades = allTrades.filter((t) => this.bidPackage.trade_id === t.id);
      this.bidPackageFormGroup.get('trade_id').disable();

      // Sets the values of bidpackage fields from the passed bid package
      for (const key in this.bidPackageFormGroup.value) {
        if (this.bidPackageFormGroup.value.hasOwnProperty(key)) {
          this.bidPackageFormGroup.get(key).setValue(this.bidPackage[key]);
        }
      }
    } else {
      // Removes any trade associated with an already existing bid package
      this.trades = allTrades.filter((t) => bidPackageTradeIds.indexOf(t.id) === -1);
    }
    // this.square_footage.valueChanges.subscribe(() => this.calculateFields('square_footage'));
    // this.budget_amount.valueChanges.subscribe(() => this.calculateFields('budget_amount'));
    // this.cost_per_sqft.valueChanges.subscribe(() => this.calculateFields('cost_per_sqft'));
  }

  calculateFields(propertyChanged: string) {
    if (
      propertyChanged !== 'square_footage' &&
      !this.square_footage.value &&
      this.cost_per_sqft.value &&
      this.budget_amount.value
    ) {
      this.square_footage.setValue((+this.budget_amount.value / +this.cost_per_sqft.value).toFixed(1), {
        emitEvent: false,
      });
    } else if (propertyChanged !== 'budget_amount' && this.square_footage.value && this.cost_per_sqft.value) {
      this.budget_amount.setValue(this.formatDollar(+this.square_footage.value * +this.cost_per_sqft.value), {
        emitEvent: false,
      });
    } else if (propertyChanged !== 'cost_per_sqft' && this.square_footage.value && this.budget_amount.value) {
      this.cost_per_sqft.setValue(this.formatDollar(+this.budget_amount.value / +this.square_footage.value), {
        emitEvent: false,
      });
    }
  }

  public blurDecimalInput(propertyName) {
    const oldValue = this[propertyName].value || 0;
    const newValue = +oldValue.toFixed(1);

    this[propertyName].setValue(newValue);
    this.calculateFields(propertyName);
  }

  public blurUSDollarInput(propertyName) {
    const oldValue = this[propertyName].value;
    const newValue = this.formatDollar(oldValue);
    // only format input if property was changed during formatting
    if (oldValue !== newValue) {
      this[propertyName].setValue(newValue);
      this.calculateFields(propertyName);
    }
  }

  public formatDollar(oldValue, object?, propertyName?) {
    if (oldValue) {
      const newValue = this.USDollarPipe.transform(oldValue, 2, false, false);
      if (object && propertyName) {
        object[propertyName] = newValue;
      }
      return newValue;
    } else {
      return null;
    }
  }

  async submit() {
    if (this.bidPackageFormGroup.valid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Bid Package..');

      const fieldsToUpdate = [];
      const bidPackageData: BidPackage = {};

      // Loops through the inputs and grabs any value that needs to be updated.
      for (const key in this.bidPackageFormGroup.value) {
        if (this.bidPackageFormGroup.value.hasOwnProperty(key)) {
          if (this.newBidPackage || this.bidPackageFormGroup.get(key).value !== this.bidPackage[key]) {
            bidPackageData[key] = this.bidPackageFormGroup.get(key).value;
            fieldsToUpdate.push(key);
          }
        }
      }

      // Warns the user and stops any calls to the api if no changes are detected. This is specifically for updates.
      if (!fieldsToUpdate.length) {
        this.snackbar.open(`No Changes Detected`);
        this.progressIndicatorService.close();
        this.dialogRef.close();

        return;
      }

      let newBidPackage;
      if (this.newBidPackage) {
        // Fields that are not in the form and must be manually added.
        bidPackageData.project_id = this.projectService.currentSelectedProjectId;
        newBidPackage = await this.projectService.createBidPackage(bidPackageData).toPromise();
      } else {
        newBidPackage = await this.projectService
          .updateBidPackage(this.bidPackage.id, bidPackageData, fieldsToUpdate)
          .toPromise();
      }

      this.snackbar.open(`Bid Package ${this.newBidPackage ? 'added' : 'updated'}!`);
      this.progressIndicatorService.close();
      this.dialogRef.close(newBidPackage);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
