import { Component } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthService, LocationService, ProgressIndicatorService } from 'src/app/services';
import { Department } from 'src/app/types';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent {
  tableHeight;
  departments: Department[];
  departmentFields = [
    'id',
    'name',
    'building_id',
    'building_name',
    'floor_name',
    'floor_id',
    'is_enabled',
    'suite_occupancies{building,suite{floor}}',
  ];
  search;
  showInactive;
  filteredCount = { count: 0 };
  pageSizeOptions: number[] = [5, 10, 20];
  pageSize = 20;
  startIndex = 0;
  endIndex = this.pageSize;
  selectedView;
  public fieldToSortBy: string;
  public sortDirection: SortDirection = 'desc';
  constructor(
    public authService: AuthService,
    public locationService: LocationService,
    private progressIndicatorService: ProgressIndicatorService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedView = this.router.url;

    await this.getDepartments();
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.sortDirection = preferences ? preferences.departments_sort_directory_order : null;
    this.fieldToSortBy = preferences ? preferences.departments_sort_directory_by_field : null;
  }

  async getDepartments() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving departments..');
    this.departments = await this.locationService.getDepartments(this.departmentFields).toPromise();
    const colors = ['bg-shade-ltblue', 'bg-shade-gray', 'bg-shade-ltpurple'];
    this.departments = this.departments.map((d) => {
      let department: any = d;
      department.suite_names = department.suite_occupancies?.reduce((acc, cur) => acc + (cur?.suite_name || ''), '');
      department.company_name = department.company?.name || null;
      let colorIndex = 0;
      department.suite_occupancies = department.suite_occupancies.map((so, i) => {
        so.color = colors[colorIndex % 3];
        if (so.building_id !== department.suite_occupancies[i - 1]?.building_id) {
          so.color = colors[++colorIndex % 3];
        }
        return so;
      });
      return department;
    });
    this.progressIndicatorService.close();
  }

  pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      localStorage.setItem(
        'preferences',
        JSON.stringify(this.addToPreferences('departments_sort_directory_order', this.sortDirection))
      );
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('departments_sort_directory_by_field', this.fieldToSortBy))
    );
  }

  selectionChange(event) {
    this.router.navigateByUrl(event.value);
  }
}
