<form [formGroup]="productFormGroup" class="p-4 arf-product item">
  <div class="row align-items-center m-0 mb-3">
    <div class="col-6 px-0">
      <mat-form-field appearance="outline" class="full-width">
        <input
          type="text"
          placeholder="Choose Product"
          class="font-weight-semibold"
          matInput
          [matAutocomplete]="product_auto"
          formControlName="name"
          (ngModelChange)="filterProducts($event)"
          (focus)="$event.target.select()"
          (change)="changeProductData({ name: $event.target.value })"
        />
        <mat-autocomplete autoActiveFirstOption #product_auto="matAutocomplete" [displayWith]="productValueMapper">
          <mat-option *ngFor="let p of filteredProducts" [value]="p.name" (click)="updateSelectedProduct(p)">
            {{ p.name }}
          </mat-option>
          <mat-option
            *ngIf="!name?.value || !filteredProducts?.length"
            [value]="name?.value"
            class="green"
            (click)="openNewProductDialog($event)"
          >
            <mat-icon>add_circle</mat-icon>
            <span> Create New Product </span>
          </mat-option>
        </mat-autocomplete>
        <mat-error class="mt-3" *ngIf="name?.errors?.required">
          <i class="fa fa-exclamation-circle red mr-2" style="font-size: 1em"></i
          ><span>A product is required</span></mat-error
        >
      </mat-form-field>
    </div>
    <div class="col-auto pr-0" style="width: 70px">
      <mat-form-field appearance="outline" class="full-width">
        <input
          matInput
          class="text-right"
          pattern="^\d*(\.\d{0,2})?$"
          placeholder="QTY"
          formControlName="quantity"
          (click)="$event.target.select()"
          (change)="changeProductData({ quantity: $event.target.value })"
        />
      </mat-form-field>
    </div>
    <div class="col pr-0">
      <mat-form-field appearance="outline" class="full-width text-right">
        <input
          matInput
          class="text-right"
          pattern="^-?\d+(\.\d{1,2})?$"
          formControlName="unit_price"
          placeholder="0.00"
          step=".01"
          (click)="$event.target.select()"
          (change)="changeProductData({ unit_price: $event.target.value })"
        />
        <mat-icon color="primary" matPrefix>attach_money</mat-icon>
      </mat-form-field>
    </div>
    <div class="col pr-0">
      <mat-form-field appearance="outline" class="full-width text-right">
        <mat-icon color="primary" matPrefix>attach_money</mat-icon>
        <input
          matInput
          class="text-right"
          formControlName="total_price"
          placeholder="0.00"
          step=".01"
          (click)="$event.target.select()"
          (change)="changeProductData({ total_price: $event.target.value })"
        />
      </mat-form-field>
    </div>
    <!-- <div class="col-auto pr-0">
      <mat-icon matRipple class="gray pointer br-50">delete</mat-icon>
    </div> -->
  </div>
  <div class="row m-0">
    <div class="col-6 px-0">
      <app-editor
        #editor
        placeholder="Summarize this purchase with the what and why"
        (itemUpdated)="changeProductData({ description: $event })"
        [initialValue]="initialDescription"
        [ValueChanges]="updatedDescription"
        [disabled]="!canEdit"
      ></app-editor>
    </div>
    <div class="col-6 pr-0">
      <mat-form-field appearance="outline" class="full-width">
        <!-- <mat-select-trigger>
          {{product?.sub_cost_code_budget?.code.value}} | {{product?.sub_cost_code_budget?.label.value}}
        </mat-select-trigger> -->
        <input
          type="text"
          placeholder="Choose Cost Code"
          class="font-weight-semibold"
          [required]="true"
          matInput
          [matAutocomplete]="cost_code_auto"
          (input)="filterCostCodes($event.target.value)"
          [value]="costCodeTitle"
          (focus)="$event.target.select()"
          [disabled]="!canEdit"
        />
        <mat-autocomplete autoActiveFirstOption #cost_code_auto="matAutocomplete">
          <div
            *ngFor="
              let c of filteredCostCodes
                | filterCostCodesByBudgetIsEnabled: this.fiscalYear
                | filterCostCodeBySearchTerm: searchInputTerm
            "
          >
            <h6 class="primary w-100 p-3 m-0 bg-shade-gray">
              <span class="mr-2">{{ c.code }}</span>
              {{ c.label }}
            </h6>
            <mat-option class="d-flex align-items-center" *ngFor="let s of c.sub_cost_codes" (click)="setCostCodeId(s)">
              <div class="d-flex align-items-start">
                <span class="lh-20 mr-2 flex-wrap"> {{ s.code }} - {{ s.label }} </span>
                <span class="ml-auto">
                  <span class="lh-20 bg-ltgray dkgray px-1 br-4 mr-2 cc-badge">
                    {{ s.sub_cost_code_type?.name }}
                  </span>
                  <span
                    class="lh-20 dkgray px-1 br-4 cc-badge"
                    [ngClass]="s.fiscal_year % 2 === 0 ? 'cc-badge-fy-green' : 'cc-badge-fy-blue'"
                  >
                    FY{{ s.fiscal_year }}
                  </span>
                </span>
              </div>
            </mat-option>
          </div>
        </mat-autocomplete>

        <mat-error *ngIf="sub_cost_code_budget_id.errors?.doesNotExist"> Not a valid cost code </mat-error>
        <mat-hint *ngIf="sub_cost_code_budget_id.value" class="font-weight-semibold dkgray">
          {{ selectedCostCode?.code || '' }} |
          {{ selectedCostCode?.label || '' }}
        </mat-hint>
        <mat-hint class="d-flex align-items-center red" *ngIf="!sub_cost_code_budget_id?.value">
          <i class="fa fa-exclamation-circle red mr-2" style="font-size: 1em"></i>
          <span>A sub-cost-code is required</span>
        </mat-hint>
      </mat-form-field>

      <div class="d-flex align-items-center mt-4">
        <button
          mat-stroked-button
          color="warn"
          class="ml-auto"
          (click)="deleteProduct(product?.id)"
          [disabled]="!canEdit || adminEditingStatus === ArfAdminEditingStatus.EditingAsAdmin"
          type="button"
        >
          Remove Product
        </button>
      </div>
    </div>
  </div>
</form>
