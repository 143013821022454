import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { cloneDeep, map, maxBy, remove, uniqBy } from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  AgendaItemDialogComponent,
  DatepickerHeaderComponent,
  EditorComponent,
  FileAttachmentDialogComponent,
  MeetingSelectDialogComponent,
  ReminderDialogComponent,
} from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  RemindersService,
  UserService,
} from 'src/app/services';
import { APIFilter, UhatFileReference, User } from 'src/app/types';
import { ProposalRequestDialogComponent, RFIDialogComponent } from 'src/app/workspaces/construction/components';
import { RFIService } from 'src/app/workspaces/construction/services';
import { ProjectConstruction, ProposalRequest } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-rfi',
  templateUrl: './rfi.component.html',
  styleUrls: ['./rfi.component.scss'],
})
export class RFIComponent implements OnInit, AfterViewInit {
  @ViewChildren(EditorComponent) private _editors_component: QueryList<EditorComponent>;
  @ViewChild('pdf', { static: true }) pdf;
  @Input() project: ProjectConstruction;

  constructor(
    public authService: AuthService,
    private rfiService: RFIService,
    private userService: UserService,
    private fileService: FileService,
    private remindersService: RemindersService,
    public projectService: ProjectService,
    private meetingService: MeetingService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private confirmationDialog: MatDialog,
    private modalService: ModalService
  ) {}

  rfis = [];
  selectedRfis = [];
  filteredUsers: User[];
  hideClosed = false;
  showCommentForm = false;
  showResponseButton = true;
  userInput: Subject<string> = new Subject();
  currentUser;
  assignedUser: User;
  projectUsers: User[];
  customHeader = DatepickerHeaderComponent;
  public downloadingRFIs = false;
  roles = {
    8: { name: 'Architect', is_project_role: false },
    9: { name: 'Architect', is_project_role: true },
    10: { name: 'Construction Manager', is_project_role: false },
    11: { name: 'Project Manager', is_project_role: true },
    17: { name: 'Engineer', is_project_role: true },
  };

  rfiFields = [
    'code',
    'due_date',
    'description',
    'status_id',
    'question_from_first_name',
    'question_from_last_name',
    'question_from_id',
    'question_from_company_name',
    'question',
    'assigned_user_id',
    'assigned_user_first_name',
    'assigned_user_last_name',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'created_datetime',
    'modified_by_first_name',
    'modified_by_last_name',
    'modified_datetime',
    'files',
    'local_index',
    'project_code',
    'project_title',
  ];
  rfiCommentFields = [
    'rfi_id',
    'assigned_user_id',
    'assigned_user_first_name',
    'assigned_user_last_name',
    'respond_by_date',
    'message',
    'to_user_ids',
    'to_users',
    'is_answer',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'created_by_company_name',
    'created_datetime',
    'files',
  ];

  get canCloseRFIandCreatePR() {
    return this.authService.isProjectAdmin(this.project.id, this.project.module_id);
  }
  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.project.module_id);
  }
  async ngOnInit() {
    const userFilters: APIFilter[] = [];
    Object.keys(this.roles).forEach((k, index) => {
      const r = this.roles[+k];
      if (index > 0) {
        userFilters.push({ type: 'operator', value: 'OR' });
      }
      userFilters.push(
        { type: 'operator', value: '(' },
        { type: 'field', field: 'role_id', value: k },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' }
      );
      if (r.is_project_role) {
        userFilters.push(
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'role_resource_type_id', value: '3' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'role_resource_id', value: this.project?.id.toString() }
        );
      }
      userFilters.push({ type: 'operator', value: ')' });
    });
    this.projectUsers = await this.userService
      .getUsers(userFilters, [
        'id',
        'first_name',
        'last_name',
        'roles',
        'user_type_id',
        'is_login_enabled',
        'is_enabled',
      ])
      .toPromise();
    this.projectUsers = uniqBy(this.projectUsers, (u) => u.id);
    this.currentUser = this.authService.getLoggedInUser();
    if (this.currentUser) {
      const foundProjectUser = this.projectUsers.find((u) => u.id === this.currentUser.id);
      if (foundProjectUser) {
        const role = this.roles[+foundProjectUser.roles[0].id];
        this.currentUser.role_name = role ? role.name : null;
      }
    }
    this.userInput.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
      this.userService
        .searchUsers([
          { type: 'field', field: 'email', value: searchTerm, match: 'any' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'full_name', value: searchTerm, match: 'any' },
        ])
        .subscribe((users) => {
          if (users) {
            this.filteredUsers = users;
          } else {
            this.filteredUsers = [];
          }
        });
    });
    setTimeout(() => {
      this.refresh();
    });
  }

  ngAfterViewInit() {
    this._editors_component.changes.subscribe((editors) => {
      editors.forEach((editor: EditorComponent) => {
        const foundRfi = this.rfis.find((rfi) => rfi.id === editor.id);

        editor.content.valueChanges.subscribe((value) => {
          // sync the messages
          if (editor.type === 'answer') {
            foundRfi.newAnswer.message = value;
            foundRfi.validAnswer = editor.content.valid;
          } else if (editor.type === 'comment') {
            foundRfi.newComment.message = value;
            foundRfi.validComment = editor.content.valid;
          }
        });
      });
    });
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Requests..');
    if (this.project && this.project.id) {
      const commentFilters: APIFilter[] = [{ type: 'field', field: 'project_id', value: this.project.id.toString() }];
      const comments = await this.rfiService.getRFIComments(this.rfiCommentFields, commentFilters).toPromise();
      for (const c of comments) {
        const foundProjectUser = this.projectUsers.find((u) => u.id === c.created_by_id);
        if (foundProjectUser) {
          const role = this.roles[+foundProjectUser.roles[0].id];
          c.created_by_role = role ? role.name : null;
        }
      }
      const rfiFilters: APIFilter[] = [{ type: 'field', field: 'project_id', value: this.project.id.toString() }];

      const rfis = await this.rfiService.getRFIs(this.rfiFields, rfiFilters).toPromise();

      rfis.sort((a, b) => +a.local_index - +b.local_index);

      for (const r of rfis) {
        const currentRFI = this.rfis.find((rfi) => rfi.id === r.id);
        r.daysUntilDueDate = this.daysUntilDate(r.due_date);
        r.newComment = {};
        r.validComment = true;
        r.newAnswer = {};
        r.validAnswer = true;
        r.answer = comments.find((c) => c.is_answer && c.rfi_id === r.id);
        r.comments = comments.filter((c) => !c.is_answer && c.rfi_id === r.id);
        r.is_expanded = currentRFI ? currentRFI.is_expanded : false;
      }
      this.rfis = rfis;
    }
    this.progressIndicatorService.close();
  }

  canRespond(status_id: number): boolean {
    return (
      status_id === 1 &&
      (this.isWorkspaceStaff || this.authService.isProjectEngineer(this.projectService.currentSelectedProjectId))
    );
  }
  daysUntilDate(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  }

  toggleRFIExpansion(rfi) {
    rfi.is_expanded = !rfi.is_expanded;
  }

  filterUsers(value) {
    this.userInput.next(value);
  }

  addToUser(rfi, user) {
    if (rfi.newComment.toUsers && rfi.newComment.toUsers.length > 0) {
      rfi.newComment.toUsers.push(user);
    } else {
      rfi.newComment.toUsers = [user];
    }
  }

  private _hasMessage(commentObj: { message?: string }): boolean {
    if (commentObj.message) {
      return true;
    }
    return false;
  }

  public getDueDateText(daysUntilDueDate) {
    return `Due ${
      daysUntilDueDate === 0
        ? 'Today'
        : `${Math.abs(daysUntilDueDate)}
       Day${daysUntilDueDate === 1 || daysUntilDueDate === -1 ? '' : 's'}
       ${daysUntilDueDate < 0 ? ' Ago' : ''}`
    }`;
  }

  async createComment(rfi, isAnswer = false) {
    const messageBody = isAnswer ? rfi.newAnswer : rfi.newComment;
    if (this._hasMessage(messageBody)) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Comment..');
      let commentToCreate;
      let files = [];
      let assignToUser;
      if (isAnswer) {
        if (rfi.newAnswer.files) {
          files = rfi.newAnswer.files;
        }
        commentToCreate = {
          rfi_id: rfi.id,
          message: rfi.newAnswer.message,
          is_answer: 1,
        };
        assignToUser = rfi.created_by_id;
      } else {
        if (rfi.newComment.files) {
          files = rfi.newComment.files;
        }
        commentToCreate = {
          rfi_id: rfi.id,
          assigned_user_id: rfi.newComment.assignedUser ? rfi.newComment.assignedUser.id : null,
          message: rfi.newComment.message,
          respond_by_date: rfi.newComment.respondByDate
            ? moment(rfi.newComment.respondByDate).format('YYYY-MM-DD')
            : null,
          to_user_ids: rfi.newComment.toUsers ? JSON.stringify(map(rfi.newComment.toUsers, 'id')) : '[]',
          is_answer: 0,
        };
        assignToUser = rfi.newComment.assignedUser ? rfi.newComment.assignedUser.id : null;
      }

      // verify the assigned to user has created their account
      if (
        assignToUser &&
        rfi.newComment &&
        rfi.newComment.assignedUser &&
        !rfi.newComment.assignedUser.is_login_enabled &&
        !(await this.modalService
          .openInviteUserModal({
            users: [rfi.newComment.assignedUser],
            parentId: rfi.id,
            parentTypeId: 26,
          })
          .toPromise())
      ) {
        return;
      }

      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Comment..');

      const newComment = await this.rfiService.createRFIComment(commentToCreate, this.rfiCommentFields).toPromise();
      for (const file of files) {
        await this.fileService.linkFile(file.file_id, newComment.id, ResourceType.RFIComment).toPromise();
        await this.fileService.addTags(file.file_id, [2]).toPromise();
      }

      if (assignToUser && assignToUser !== rfi.assigned_user_id) {
        await this.rfiService.updateRFI(rfi.id, { assigned_user_id: assignToUser }, []).toPromise();
      }

      this.hideResponseForm();
      this.snackbar.open(`${isAnswer ? 'Answer' : 'Comment'} submitted!`);
      this.refresh();
    } else {
      // trigger a message to user
      this.snackbar.open(`Please enter ${isAnswer ? 'an answer' : 'a comment'} to continue!`);
    }
  }

  toggleAnswerExpansion(rfi) {
    rfi.showAnswer = !rfi.showAnswer;
    if (rfi.showAnswer) {
      const latestComment: any = rfi.comments ? maxBy(rfi.comments, 'id') : null;
      rfi.newAnswer.message = latestComment ? latestComment.message : '';
    }
  }

  closeRFI(rfi) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Closing Request..');
    this.rfiService.updateRFI(rfi.id, { status_id: 2 }, this.rfiFields).subscribe((updatedRFI) => {
      this.refresh();
    });
  }

  showResponseForm() {
    this.showCommentForm = true;
    this.showResponseButton = false;
  }

  hideResponseForm() {
    this.showCommentForm = false;
    this.showResponseButton = true;
  }

  openAddRFIDialog() {
    const dialogRef = this.dialog.open(RFIDialogComponent, {
      disableClose: true,
      width: '500px',
      data: {
        projectUsers: this.projectUsers,
      },
    });

    dialogRef.afterClosed().subscribe(async (returnedRFI) => {
      if (returnedRFI) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Request..');
        const rfiToCreate = {
          description: returnedRFI.description,
          due_date: returnedRFI.due_date,
          question: returnedRFI.question,
          project_id: this.project.id,
          question_from_id: this.currentUser.id,
          assigned_user_id: returnedRFI.assigned_user_id,
        };
        const createdRFI = await this.rfiService.createRFI(rfiToCreate, this.rfiFields).toPromise();
        if (returnedRFI.files) {
          for (const f of returnedRFI.files) {
            await this.fileService.linkFile(f.file_id, createdRFI.id, ResourceType.RFI).toPromise();
            await this.fileService.addTags(f.file_id, [2]).toPromise();
          }
        }
        this.snackbar.open('RFI Created!');
        this.refresh();
      }
    });
  }

  async openEditRFIDialog(rfi) {
    const rfiForEditing = cloneDeep(rfi);
    this.assignedUser = null;
    const userFields = ['id', 'first_name', 'last_name', 'roles', 'user_type_id', 'is_enabled'];
    rfiForEditing.projectUsers = cloneDeep(this.projectUsers);

    this.assignedUser = await this.userService.getUserById(rfiForEditing.assigned_user_id, userFields).toPromise();

    // If assigned user is not enabled keep in list
    if (!this.assignedUser.is_enabled) {
      rfiForEditing.projectUsers.push(this.assignedUser);
    }

    const dialogRef = this.dialog.open(RFIDialogComponent, {
      disableClose: true,
      width: '500px',
      data: rfiForEditing,
    });

    dialogRef.afterClosed().subscribe(async (returnedRFI) => {
      if (returnedRFI && returnedRFI.id) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating Request..');
        this.snackbar.open('Updating RFI...');
        const rfiToUpdate = {
          description: returnedRFI.description,
          due_date: returnedRFI.due_date,
          question: returnedRFI.question,
          assigned_user_id: returnedRFI.assigned_user_id,
        };
        const updatedRFI = await this.rfiService.updateRFI(returnedRFI.id, rfiToUpdate, this.rfiFields).toPromise();

        // link the files that were added
        if (returnedRFI.files) {
          const filesToLink = returnedRFI.files.filter((f) => f.file_id);
          for (const f of filesToLink) {
            await this.fileService.addTags(f.file_id, [2]).toPromise();
            await this.fileService.linkFile(f.file_id, updatedRFI.id, ResourceType.RFI).toPromise();
          }
        }

        // unlink the files that were removed
        if (updatedRFI.files) {
          const currentFiles = await this.fileService.getFilesByParentId(ResourceType.RFI, updatedRFI.id).toPromise();
          const filesToRemove = updatedRFI.files
            .filter((f) => !returnedRFI.files.find((fi) => fi.id === f.id))
            .map((f) => +f.id);
          for (const file of currentFiles) {
            if (filesToRemove.includes(+file.file_id)) {
              await this.fileService.unlinkFile(+file.id).toPromise();
              this.fileService.removeTags(+file.file_id || +file.id, [2]).subscribe();
            }
          }
        }

        this.snackbar.open('RFI Updated!');
        this.refresh();
      }
    });
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  addAnswerFiles(files, rfi) {
    if (rfi) {
      for (const f of files) {
        if (rfi.newAnswer && rfi.newAnswer.files && rfi.newAnswer.files.find((fi) => fi.name === f.name)) {
          this.snackbar.open(`File with name '${f.name}' already exists!`);
        } else {
          if (rfi && rfi.newAnswer && rfi.newAnswer.files) {
            rfi.newAnswer.files.push(f);
          } else if (!rfi.newAnswer) {
            rfi.newAnswer = { files: [f] };
          } else {
            rfi.newAnswer.files = [f];
          }
        }
      }
    }
  }

  removeAnswerFile(file, rfi) {
    if (rfi && rfi.newAnswer && rfi.newAnswer.files) {
      remove(rfi.newAnswer.files, (f: UhatFileReference) => f.name === file.name);
    }
  }

  addCommentFiles(files, rfi) {
    if (rfi) {
      for (const f of files) {
        if (rfi.newComment && rfi.newComment.files && rfi.newComment.files.find((fi) => fi.name === f.name)) {
          this.snackbar.open(`File with name '${f.name}' already exists!`);
        } else {
          if (rfi && rfi.newComment && rfi.newComment.files) {
            rfi.newComment.files.push(f);
          } else if (!rfi.newComment) {
            rfi.newComment = { files: [f] };
          } else {
            rfi.newComment.files = [f];
          }
        }
      }
    }
  }

  removeCommentFile(file, rfi) {
    if (rfi && rfi.newComment && rfi.newComment.files) {
      remove(rfi.newComment.files, (f: UhatFileReference) => f.name === file.name);
    }
  }

  downloadFile(file) {
    file.loading = true;
    this.fileService.downloadFile(file).subscribe((downloadedFileResult) => {
      saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      file.loading = false;
    });
  }

  async deleteRFI(rfi) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete RFI',
        descriptionText: 'Are you sure you want to delete this RFI?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          if (rfi && rfi.id) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Request..');
            if (rfi.files) {
              for (const file of rfi.files) {
                this.fileService.removeTags(file.file_id || file.id, [2]).subscribe();
              }
            }
            if (rfi.answer && rfi.answer.files) {
              for (const file of rfi.answer.files) {
                this.fileService.removeTags(file.file_id || file.id, [2]).subscribe();
              }
            }
            if (rfi.comments && rfi.comments) {
              for (const comment of rfi.comments) {
                if (comment.files) {
                  for (const file of comment.files) {
                    this.fileService.removeTags(file.file_id || file.id, [2]).subscribe();
                  }
                }
              }
            }
            await this.rfiService.deleteRFI(rfi.id).toPromise();
            this.snackbar.open('RFI deleted!');
            this.refresh();
          }
        }
      });
  }

  createReminderFromRFI(rfi) {
    const dueDatetime = rfi.due_date ? moment(rfi.due_date).hour(8).format('YYYY-MM-DD HH:mm') : null;
    const dialogRef = this.dialog.open(ReminderDialogComponent, {
      data: {
        description: `${rfi.local_index} in ${rfi.project_title} due`,
        parent_type_id: ResourceType.RFI,
        parent_id: rfi.id,
        due_datetime: dueDatetime,
        reminder_datetime: dueDatetime,
      },
    });

    dialogRef.afterClosed().subscribe((returnedReminder) => {
      const reminderToCreate = returnedReminder;
      if (reminderToCreate) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Adding Reminder..');
        this.remindersService.createReminder(reminderToCreate).subscribe((reminder) => {
          this.snackbar.open('Reminder created!');
          this.refresh();
        });
      }
    });
  }

  createMeetingAgendaFromRFI(rfi) {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.RFI,
        parent_id: rfi.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe(async (agendaItem) => {
          if (agendaItem) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Adding Agenda Item..');
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.RFI,
              parent_id: rfi.id,
            };
            await this.meetingService
              .addAgendaItem(agendaItemToAdd)
              .toPromise()
              .then(() => {
                this.snackbar.open('Agenda item added!');
              });
            this.progressIndicatorService.close();
          }
        });
      }
    });
  }

  createPRFromRFI() {
    const dialogRef = this.dialog.open(ProposalRequestDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(async (proposalRequestToCreate: ProposalRequest) => {
      if (proposalRequestToCreate && this.project) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Creating PR..');
        proposalRequestToCreate.project_id = this.project.id;
        let filesToCreate;
        if (proposalRequestToCreate.files) {
          filesToCreate = proposalRequestToCreate.files;
          delete proposalRequestToCreate.files;
        }
        const createdProposalRequest = await this.projectService
          .createProposalRequest(proposalRequestToCreate, ['code'])
          .toPromise();

        for (const f of filesToCreate) {
          await this.fileService
            .linkFile(f.file_id, createdProposalRequest.id, ResourceType.ProposalRequest)
            .toPromise();
        }

        this.snackbar.open(`Proposal Request added!`);
        this.refresh();
      }
    });
  }

  // opens the upload modal, and passes the file on once it's uploaded, so it can be linked to the correct object
  // TODO figure out what r is?
  openUploadModal(fileType: 'comment' | 'answer', r) {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          preSelectedTags: [{ id: 2 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          if (fileType === 'comment') {
            this.addCommentFiles(resultData, r);
          } else {
            this.addAnswerFiles(resultData, r);
          }
        }
      });
  }

  downloadRFIList() {
    this.downloadingRFIs = true;
    setTimeout(() => {
      this.pdf.saveAs(this.project.code + '_RFI_List.pdf');
    });
    this.downloadingRFIs = false;
  }

  openSelectRfisDialog() {
    const rfis = this.rfis.map((rfi) => {
      rfi.hasAttachments =
        rfi.files?.length > 0 ||
        rfi.answer?.files?.length > 0 ||
        rfi.comments?.filter((c) => c.files?.length > 0)?.length > 0;
      return rfi;
    });
    this.modalService
      .openSelectItemDialog({
        items: rfis,
        itemType: `RFIs`,
        submitText: `Download PDF`,
      })
      .subscribe(async (result) => {
        if (result) {
          this.selectedRfis = result;
          this.downloadingRFIs = true;
          setTimeout(() => {
            this.pdf.saveAs(this.project.code + '_RFI_List.pdf');
          });
          // download attachments in a zip
          const attachmentIds = this.selectedRfis
            .filter((rfi) => rfi.selectedAttachments)
            ?.reduce((accummelatedFiles, rfi) => {
              const commentsFiles = rfi.comments.reduce((a, i) => a.concat(i.files || []), []);
              return accummelatedFiles
                .concat(...(rfi.files || []))
                .concat(commentsFiles || [])
                .concat(rfi.answer?.files || []);
            }, [])
            .map((attachment) => +attachment.id);
          const zip = new JSZip();
          const fileName = `RFI-${this.project.id}_${this.project.title}.zip`;
          if (attachmentIds?.length > 0) {
            if (typeof Worker !== 'undefined') {
              const worker = new Worker(new URL('src/app/workers/zip.worker', import.meta.url));
              worker.onmessage = async () => {
                this.snackbar.open('Zipping your files after pdf download. Will notify you when done...!');
                for (const id of attachmentIds) {
                  const file = await this.fileService.downloadFile({ id }).toPromise();
                  zip.file(file.name, file.file.data);
                }
                zip.generateAsync({ type: 'blob' }).then((content) => {
                  saveAs(content, fileName);
                });
                this.downloadingRFIs = false;
                this.snackbar.open('Your zip file is done downloading...!');
              };
              worker.postMessage('done');
            } else {
              // Web workers are not supported in this environment.
              // You should add a fallback so that your program still executes correctly.
              this.snackbar.open(
                `Web workers are not supported in this web browser. You should update the browser or contact customer service to get a zip of your file notes`
              );
            }
          }
        }
      });
  }
}
