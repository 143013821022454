<div class="row" style="padding-bottom: 0.5em">
  <div class="col-12">
    <div class="row ml-0">
      <mat-chip-list class="d-inline-block align-middle">
        <mat-chip *ngFor="let item of items" class="dkblue">
          <span class="pointer">
            {{ item }}
          </span>
          <mat-icon *ngIf="!disabled" matChipRemove (click)="removeEntry(item)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="input-group mb-3">
      <input
        [disabled]="disabled"
        #inBox
        type="text"
        class="form-control"
        placeholder="{{ placeholder }}"
        [matAutocomplete]="auto"
        aria-describedby="basic-addon2"
        (keyup.enter)="submitKeyEvent(inBox)"
      />
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autoCompleteSelection($event)">
      <mat-option *ngFor="let item of buttonItems | filter: inBox.value" [value]="item">
        {{ item }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
