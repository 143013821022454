<app-base-dialog [title]="title" (closeDialog)="close()"> </app-base-dialog>
<div id="how-to-guide-dialog" data-test-id="how-to-guide-dialog" class="how-to-guide-dialog" mat-dialog-content>
  <div
    [ngClass]="{ 'border-bottom': i + 1 < instructions?.length }"
    class="row py-2"
    *ngFor="let instruction of instructions; let i = index"
  >
    <div class="col-12 dkblue font-weight-semibold mb-2">{{ i + 1 }}. {{ instruction.header }}</div>
    <div class="col-12 dkblue font-weight-semibold mb-2">{{ instruction.copy }}</div>
  </div>
</div>
