import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { uniqBy } from 'lodash';

import { FileService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { ConfirmationDialogComponent } from 'src/app/components';
import { ResourceType } from '../../enums';

@Component({
  selector: 'app-file-select-modal',
  templateUrl: './file-select-modal.component.html',
  styleUrls: ['./file-select-modal.component.scss'],
})
export class FileSelectModalComponent implements OnInit {
  selectedFiles: UhatFileReference[] = [];

  allFiles: UhatFileReference[] = [];

  filterFiles: UhatFileReference[] = [];

  isLoading = true;

  searchInput: string;

  maxFiles: number;

  // I had to add this so the app would build -Austin
  processing = false;

  constructor(
    private fileService: FileService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    if (this.data.hasOwnProperty('maxFiles')) {
      this.maxFiles = this.data.maxFiles;
    }
    if (this.data.hasOwnProperty('filterFiles')) {
      this.filterFiles = this.data.filterFiles;
    }

    this.allFiles = this.data.projectId
      ? (await this.fileService.getFilesByProjectId(this.data.projectId).toPromise()) || []
      : (await this.fileService.getFilesByParentId(ResourceType.Task, this.data.taskId).toPromise()) || [];
    for (const file of this.filterFiles) {
      this.allFiles = this.allFiles.filter((f) => f.id !== file.id);
    }
    // Remove duplicates of file_id
    this.allFiles = uniqBy(this.allFiles, 'file_id');
    this.isLoading = false;
  }

  submit() {
    this.dialogRef.close(this.selectedFiles);
  }

  close() {
    this.dialogRef.close(false);
  }

  fileIsSelected(file: UhatFileReference) {
    return this.selectedFiles.find((f) => f.id === file.id) != null;
  }

  getFileSelectionNumberText(): string {
    const num = this.selectedFiles.length || 0;
    if (num === 0) {
      return 'No File Selected';
    } else if (num === 1) {
      return this.selectedFiles[0].name + ' Selected';
    } else {
      return this.selectedFiles.length + ' Files Selected';
    }
  }

  toggleFileSelection(file: UhatFileReference) {
    const selectedFile = this.selectedFiles.find((f) => f.id === file.id);
    if (selectedFile != null) {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== selectedFile.id);
    } else {
      this.selectedFiles.push(file);
    }
    if (this.selectedFiles.length > this.maxFiles) {
      this.selectedFiles.shift();
    }
  }
}
