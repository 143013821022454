<header class="my-4">
  <div class="d-flex align-items-center">
    <h4 class="primary m-0">Departments</h4>
  </div>
</header>
<div class="d-flex align-items-center mb-4">
  <mat-form-field appearance="outline">
    <mat-icon class="dkblue" matPrefix>search</mat-icon>
    <input matInput placeholder="Search Departments" [(ngModel)]="search" (ngModelChange)="paginator.pageIndex = 0" />
    <mat-icon *ngIf="search" class="gray pointer" (click)="search = null; paginator.pageIndex = 0" matSuffix
      >cancel
    </mat-icon>
  </mat-form-field>
  <mat-slide-toggle color="accent" [(ngModel)]="showInactive">
    <span class="font-weight-semibold">Show Inactive Departments</span>
  </mat-slide-toggle>
  <button
    *ngIf="isDepartmentsManager"
    class="mr-3 bg-white"
    mat-stroked-button
    color="gray"
    (click)="exportDepartmentData()"
  >
    <i class="material-icons-outlined"> file_download </i>
    Download Departments CSV
  </button>
  <button mat-flat-button color="success" (click)="openDepartmentDialog('Add')">Add Department</button>
</div>

<div class="m-portlet">
  <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
    <div class="row align-items-center">
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('id')">
        <h6 class="m-0 text-truncate header-title mr-auto">ID</h6>
        <i *ngIf="sortProperty === 'id'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center" (click)="sort('name')">
        <h6 class="m-0 text-truncate header-title mr-auto">Name</h6>
        <i *ngIf="sortProperty === 'name'" matRipple class="material-icons">
          {{ sortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
        </i>
      </div>
      <div class="col-1 p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Company</h6>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Buildings</h6>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Floors</h6>
      </div>
      <div class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Suites</h6>
      </div>
      <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
        <h6 class="m-0 text-truncate header-title mr-auto">Active</h6>
      </div>
    </div>
  </div>
  <div>
    <div
      *ngFor="
        let department of departments
          | isEnabled: showInactive
          | filter: search:['name', 'building_name', 'suite_names', 'company_name', 'floor_names']:filteredCount
          | orderByField: sortProperty:sortDirection
          | paginator: paginator.pageIndex:paginator.pageSize
      "
      class="m-portlet__body item p-3 pt-4 pb-4"
      data-id-test="selected-request-type-wrapper"
    >
      <div class="row align-items-center">
        <div class="col-1 d-flex align-items-center data-item" [ngClass]="{ gray: department.is_enabled !== 1 }">
          {{ department.id }}
        </div>
        <div class="col-2 d-flex align-items-center data-item">
          <h6 class="primary m-0" [ngClass]="{ gray: department.is_enabled !== 1 }">
            {{ department.name }}
          </h6>
        </div>
        <div class="col-1 d-flex align-items-center data-item" [ngClass]="{ gray: department.is_enabled !== 1 }">
          {{ department.company?.name }}
        </div>

        <div class="col-6 data-item" [ngClass]="{ gray: department.is_enabled !== 1 }">
          <div
            class="row"
            [ngClass]="{ 'mt-2': department.suite_occupancies[index - 1]?.building.id !== so.building.id }"
            *ngFor="let so of department.suite_occupancies; let index = index"
          >
            <div class="col mb-2">
              <span
                class="px-2 py-1 py-1 lh-20 br-10 m-1"
                [ngClass]="so.color"
                *ngIf="department.suite_occupancies[index - 1]?.building.id !== so.building.id"
              >
                {{ so.building.name }}
              </span>
            </div>
            <div class="col mb-2">
              <span
                class="px-2 py-1 py-1 lh-20 br-10 m-1"
                [ngClass]="so.color"
                *ngIf="department.suite_occupancies[index - 1]?.suite.floor.id !== so.suite.floor.id"
              >
                {{ so.suite.floor.name }}
              </span>
            </div>
            <div class="col mb-2">
              <span class="px-2 py-1 py-1 lh-20 br-10 m-1" [ngClass]="so.color">
                {{ so.suite.name }}
              </span>
            </div>
          </div>
        </div>

        <div class="col d-flex align-items-center data-item">
          <p class="m-0 lh-20">
            <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="department.is_enabled === 1">
              Active
            </span>
            <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="department.is_enabled !== 1"> Inactive </span>
          </p>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <button (click)="openDepartmentDialog('Edit', department)" mat-icon-button color="accent" type="button">
            <mat-icon>edit</mat-icon>
          </button>
          <!-- <button (click)="delete(department)" mat-icon-button color="accent" type="button">
            <mat-icon>delete</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
    <div *ngIf="+filteredCount?.count === 0" class="m-portlet__body">no results found</div>
  </div>
  <div>
    <mat-paginator
      #paginator
      [length]="filteredCount?.count"
      pageSize="25"
      (page)="pageEvent = $event"
      aria-label="Select page"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </div>
</div>
