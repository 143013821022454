<div
  data-test-id="key-control-icon-badge"
  id="key-control-icon-badge"
  class="key-control-icon-badge d-flex p-2 br-10 ease"
>
  <!-- No status id, Open, pending -->
  <i
    *ngIf="
      (!task?.status_id || +task?.status_id === TASK_STATUS.Open || +task?.status_id === TASK_STATUS.Pending) &&
      !isFlagged
    "
    matRipple
    class="material-icons ltgray"
  >
    check_circle
  </i>
  <!-- Approved -->
  <i *ngIf="+task?.status_id === TASK_STATUS.Complete && !isFlagged" matRipple class="material-icons green">
    check_circle
  </i>
  <!-- On Hold -->
  <i *ngIf="+task?.status_id === TASK_STATUS.OnHold || isFlagged" matRipple class="material-icons red">
    sync_problem
  </i>
  <!-- Not Rejected, then show audit count-->
  <h6
    *ngIf="task?.status_id === TASK_STATUS.Complete"
    class="m-0 number-of-audits font-weight-bold br-50 white text-center"
    [ngClass]="{
      'bg-red': task?.key_control_status_counts?.completed_key_controls === 0,
      'bg-orange': task?.key_control_status_counts?.completed_key_controls === 1,
      'bg-green': task?.key_control_status_counts?.completed_key_controls > 1
    }"
  >
    {{ task?.key_control_status_counts?.completed_key_controls || 0 }}
  </h6>
</div>
