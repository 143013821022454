import { ApplicationRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { pdf } from '@progress/kendo-drawing';
import * as moment from 'moment';

import { FieldChecker } from 'src/app/utils';

import { ProjectService } from 'src/app/services';

import { Company, User } from 'src/app/types';
import { Bid, BidPackage, ProjectConstruction } from 'src/app/workspaces/construction/types';
import { sumBy } from 'lodash';

@Component({
  selector: 'app-project-schedule-contract-export',
  templateUrl: './project-schedule-contract-export.component.html',
  styleUrls: ['./project-schedule-contract-export.component.scss'],
})
export class ProjectScheduleContractExportComponent implements OnInit, OnChanges {
  constructor(private appRef: ApplicationRef, private projectService: ProjectService) {}

  @ViewChild('pdf', { static: true }) pdf;

  private projectFields = [
    `code`,
    `title`,
    `scope_of_work`,
    `building`,
    `floor`,
    `suite`,
    `department`,
    `project_manager{first_name,last_name,email,company{name}}`,
    `cfmo{first_name,last_name,title}`,
    `project_schedule_file_id`,
    `bid_details{construction_start_date,construction_end_date}`,
    `bid_packages{trade{name},awarded_bid{company{name}}}`,
  ];

  private bidFields = [
    'timeline_revision',
    `bid_package_id`,
    `bid_package{number,trade{name}}`,
    `project{${this.projectFields.join(',')}}`,
    `trade{name}`,
    `contact{first_name,last_name,email,title}`,
    `company{name}`,
    `amount`,
    `description`,
    `contract_revision`,
    'contract_task_id',
    'contract_task{status_id,accessory_data}',
    'timeline_vendor_signature_text',
    'timeline_vendor_signed_datetime',
  ];

  @Input() bid: Bid = {};
  @Input() timelineTrades: BidPackage[] = [];
  shownBidId: number;
  contractSum: number;

  get project(): ProjectConstruction {
    return this.bid?.project;
  }
  get projectManager(): User {
    return this.project?.project_manager;
  }
  get bidPackage(): BidPackage {
    return this.bid?.bid_package;
  }
  get bidCompany(): Company {
    return this.bid?.company;
  }
  get bidContact(): User {
    return this.bid?.contact;
  }

  ngOnInit(): void {
    pdf.defineFont({
      'Dawning of a New Day': 'assets/fonts/DawningofaNewDay-Regular.ttf',
    });
  }

  async ngOnChanges(): Promise<void> {
    if (this.bid?.id && this.bid?.id !== this.shownBidId) {
      const hasAllFields = FieldChecker.hasAllFields(this.bid, this.bidFields);
      if (!hasAllFields?.result) {
        console.warn(
          `Project Schedule Contract Export Component is missing the required bid fields: ${(
            hasAllFields.missingFields || []
          ).join(', ')}! Getting them from the API.`
        );
        await this.refreshContract();
      }
      this.shownBidId = this.bid?.id;
      await this.getAltBids();
    }
  }

  async refreshContract() {
    const bid = await this.projectService.getBidById(this.bid?.id, this.bidFields).toPromise();
    // change this if we ever allow the schedule dialog to be viewed at a time other than awaiting vendor signature
    bid.timeline_vendor_signed_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    const bidMeetings = await this.projectService.getTimelineMeetings(bid?.id).toPromise();
    bid.pre_construction_meeting = bidMeetings[0] ?? null;
    this.bid = bid;
    this.timelineTrades = await this.projectService.getTimelineTrades(this.bid?.id).toPromise();
  }

  async getAltBids() {
    const selectedAltBidFilters = [
      { type: 'field', field: 'bid_package_id', value: this.bid.bid_package_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'company_id', value: this.bid.company_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'type_id', value: 2 },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_selected', value: 1 },
    ];
    const selectedAltBids = await this.projectService.getBids(selectedAltBidFilters, ['id', 'amount']).toPromise();
    this.bid.altBids = selectedAltBids ?? [];
    this.contractSum = this.bid?.amount + sumBy(this.bid?.altBids, 'amount');
  }

  async contractExport() {
    return await this.pdf.saveAs(
      `${this.bid?.trade?.name ?? 'trade_unknown'}_Schedule_Contract_Project_${this.project?.code}`
    );
  }

  public async createPdfFile(project, revision) {
    project = project;

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    return await this.pdf.export().then(async (groupData) => {
      let createdPDF: File;
      // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
      await new Promise<File>((resolve) => {
        pdf.toBlob(groupData, (res) => {
          createdPDF = new File(
            [res],
            `Project_${project.code}_Trade_${this.bid?.trade?.name}_Schedule_v${revision || 1}.pdf`
          );
          resolve(createdPDF);
        });
      });
      return createdPDF || null;
    });
  }

  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }
}
