import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent, UserProfilePreviewComponent, UserSelectModalComponent } from 'src/app/components';
import { UserType } from 'src/app/enums';
import { Conversation, Message } from 'src/app/models';
import { AuthService, MessagingSystemService, ProjectTaskService, UserService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-messaging-conversation-panel',
  templateUrl: './messaging-conversation-panel.component.html',
  styleUrls: ['./messaging-conversation-panel.component.scss'],
})
export class MessagingConversationPanelComponent implements OnInit {
  @ViewChild('splitComponent', { static: true }) splitComponent: SplitComponent;

  @ViewChild('saNewMessageView', { static: true }) saNewMessageView: SplitAreaDirective;

  @Output() resetView = new EventEmitter<boolean>();

  public MIN_NEW_MESSAGE_PXL = 75;

  public OPEN_MESSAGE_PXL = 562;

  conversation: Conversation;

  messagesObservable: Observable<Array<Message>>;

  newMessagePanelOpen = false;

  currentUserId: number;

  isFollowing: boolean;

  searching: boolean; // TODO: I had to add this so the app would build -Austin

  public containsStaffFollowers: boolean;

  public containsTenantFollowers: boolean;

  public containsVendorFollowers: boolean;

  constructor(
    private messagingService: MessagingSystemService,
    private taskService: ProjectTaskService,
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog,
    private userService: UserService,
    public snackbar: MatSnackBar,
    private newMessageService: MessagingSystemService
  ) {
    this.currentUserId = authService.getLoggedInUser().id;
  }

  ngOnInit() {
    if (this.conversation) {
      this.messagesObservable = this.messagingService.getMessagesObservable(this.conversation.id);
    }

    // Set appropriate initial size of split panel
    if (this.newMessagePanelOpen) {
      this.splitComponent.setVisibleAreaSizes([
        this.OPEN_MESSAGE_PXL - this.MIN_NEW_MESSAGE_PXL,
        this.OPEN_MESSAGE_PXL,
      ]);
    } else {
      this.splitComponent.setVisibleAreaSizes(['*', this.MIN_NEW_MESSAGE_PXL]);
    }

    this.splitComponent.useTransition = true;

    this.messagingService.events.onReplyToMessageEvent.subscribe((message) => {
      this.newMessagePanelOpen = true;
      this.expandMessagePanel();
    });

    this.messagingService.events.onConversationSelectEvent.subscribe((conversation) => {
      this.conversation = conversation;
      this.refreshIncludedUserTypes();
      this.messagesObservable = this.messagingService.getMessagesObservable(this.conversation.id);
      this.isFollowing = conversation.followers.find((f) => f.id === this.currentUserId.toString());
      if (this.newMessagePanelOpen) {
        this.toggleConversationPanelSize();
      }
    });
  }

  toggleConversationPanelSize() {
    this.newMessagePanelOpen = !this.newMessagePanelOpen;

    if (this.newMessagePanelOpen) {
      this.expandMessagePanel();
    } else {
      this.splitComponent.setVisibleAreaSizes(['*', this.MIN_NEW_MESSAGE_PXL]);
    }
  }

  onSplitBarDragReleased(event) {
    const sizes = event.sizes;
    const area1 = sizes[0];
    const area2 = sizes[1];
    if (area2 >= 430) {
      this.newMessagePanelOpen = true;
    } else if (area2 <= 200) {
      this.newMessagePanelOpen = false;
    }
  }

  private expandMessagePanel() {
    this.splitComponent.setVisibleAreaSizes(['*', this.OPEN_MESSAGE_PXL]);
  }

  getConversationSubheaderText() {
    if (this.conversation) {
      return `${this.conversation.channel_project_code} - ${this.conversation.channel_project_title}`;
    } else {
      return '---- -- ----';
    }
  }

  gotoTask() {
    this.router.navigate([`/projects/${this.conversation.channel_project_id}`]).then((c) => {
      this.taskService.selectTaskById(this.conversation.task_id).subscribe();
    });
  }

  gotoProject() {
    this.router.navigate([`/projects/${this.conversation.channel_project_id}`]);
  }

  async toggleFollow() {
    if (this.isFollowing && !this.authService.isStaffOnAnyModule) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          titleBarText: 'Unfollow Conversation',
          descriptionText:
            'Warning: Are you sure you want to un-follow this conversation? You will no longer be able to view messages in this conversation.',
        },
      });

      dialogRef.afterClosed().subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.swapFollowStatus();
          this.newMessageService.loadAllMessagesForUser(this.currentUserId);
          this.resetView.emit(true);
        }
      });
    } else {
      this.swapFollowStatus();
    }
  }

  private async swapFollowStatus() {
    this.isFollowing = !this.isFollowing;
    await this.messagingService
      .addOrRemoveConversationFollower(this.conversation.id, this.currentUserId, this.isFollowing ? 'add' : 'remove')
      .toPromise();
  }

  async addConversationFollower($event: User) {
    if (!this.conversation.followers.find((f) => f.id === $event.id)) {
      this.messagingService.addOrRemoveConversationFollower(this.conversation.id, $event.id, 'add').subscribe();
    }
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  replyAll() {
    if (!this.newMessagePanelOpen) {
      this.toggleConversationPanelSize();
    }
    this.messagingService.events.onReplyToMessageEvent.emit(null);
  }

  private refreshIncludedUserTypes() {
    this.containsStaffFollowers = false;
    this.containsTenantFollowers = false;
    this.containsVendorFollowers = false;
    this.conversation.followers.forEach((follower) => {
      const thisFollowerUserType = follower.user_type_id;
      switch (thisFollowerUserType) {
        case UserType.Staff:
          this.containsStaffFollowers = true;
          break;
        case UserType.Tenant:
          this.containsTenantFollowers = true;
          break;
        case UserType.Vendor:
          this.containsVendorFollowers = true;
          break;
      }
    });
  }

  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public loadMoreMessages() {
    this.messagingService.loadMessagesForConversationIdFromCursor(this.conversation.id).subscribe((result) => {});
  }

  public conversationMessagesHaveMoreToLoad(conversationId: number) {
    return this.messagingService.conversationHasMoreMessagesToLoad(conversationId);
  }

  public addFollowers() {
    this.searching = !this.searching;
    if (this.searching) {
      this.dialog
        .open(UserSelectModalComponent, {
          disableClose: true,
          data: {
            title: 'Add Followers',
            preSelectedUsers: this.conversation.followers,
            createUser: { title: 'Guest', guestUser: false },
          },
        })
        .afterClosed()
        .subscribe(async ({ deSelectedUsers, selectedUsers }) => {
          if (deSelectedUsers?.length) {
            for (const deselected of deSelectedUsers) {
              await this.messagingService
                .addOrRemoveConversationFollower(this.conversation.id, deselected.id, 'remove')
                .toPromise();
            }
          }
          if (selectedUsers?.length) {
            for (const selected of selectedUsers) {
              await this.messagingService
                .addOrRemoveConversationFollower(this.conversation.id, selected.id, 'add')
                .toPromise();
            }
          }
          this.searching = !this.searching;
        });
    }
  }
}
