import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditorComponent } from 'src/app/components';

@Component({
  selector: 'app-cover-letter-dialog',
  templateUrl: './cover-letter-dialog.component.html',
  styleUrls: ['./cover-letter-dialog.component.scss'],
})
export class CoverLetterDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  message;
  constructor(public dialogRef: MatDialogRef<CoverLetterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    this.cover_letter.setValue(this.data?.tenant?.cover_letter_text ?? '');
  }

  get cover_letter(): AbstractControl {
    return this._editor_component.content;
  }

  save(): void {
    this.dialogRef.close({
      id: this.data?.tenant?.tenant_id,
      cover_letter_text: this.cover_letter.value,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
