<div class="project-modal" style="max-width: 400px; min-width: 400px">
  <div class="row align-items-center mb-5">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">
        {{ titleBarText }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="continue()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="row mb-4" *ngIf="headerText">
      <div class="col">
        <h5 class="m-0 dkblue font-weight-semibold">
          {{ headerText }}
        </h5>
      </div>
    </div>
    <!-- Informational Message -->
    <div class="row mb-5" *ngIf="descriptionText && descriptionText.length > 0">
      <div class="col-12">
        <h5 class="dkblue m-0" style="line-height: 1.8rem">
          {{ descriptionText }}
        </h5>
      </div>
    </div>
  </div>
  <div class="row align-items-center mt-3 mb-2">
    <div class="col">
      <button mat-button (click)="continue()" class="full-width" color="accent">
        {{ notificationButtonText }}
      </button>
    </div>
  </div>
</div>
