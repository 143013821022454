import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService, UserService } from 'src/app/services';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss'],
})
export class ConfirmAccountComponent implements OnInit {
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private authService: AuthService
  ) {}

  imageNumber: number;

  completeFormGroup = this.fb.group(
    {
      first_name: [{ value: '', disabled: true }],
      last_name: [{ value: '', disabled: true }],
      company_name: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]],
    },
    { validator: this.mustMatch('password', 'confirm_password') }
  );

  ldap_id = false;
  email: string;
  hash: string;

  get first_name() {
    return this.completeFormGroup.get('first_name');
  }
  get password() {
    return this.completeFormGroup.get('password');
  }
  get confirm_password() {
    return this.completeFormGroup.get('confirm_password');
  }

  debug() {}

  ngOnInit() {
    this.imageNumber = Math.floor(Math.random() * 7 + 1);
    [this.email, this.hash] = [this.route.snapshot.queryParams.email, this.route.snapshot.queryParams.hash];
    if (this.email && this.hash) {
      this.authService.removeTokens();
      this.userService.getUserForConfirmation(this.email, this.hash).subscribe(
        (user: any) => {
          this.ldap_id = user.ldap_id;
          delete user.ldap_id;
          this.setFormData(user);
        },
        () => {
          this.snackbar.open('Invitation link is invalid');
          this.router.navigateByUrl('/login');
        }
      );
    } else {
      this.snackbar.open('Invitation link is invalid');
      this.router.navigateByUrl('/login');
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.match) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ match: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  setFormData(user) {
    user.password = null;
    user.confirm_password = null;
    this.completeFormGroup.setValue(user);
    this.password.setErrors(null);
    this.confirm_password.setErrors(null);
  }

  confirmAccount() {
    const formUser = this.completeFormGroup.getRawValue();
    const userToComplete = {
      email: formUser.email,
      password: formUser.password,
    };
    this.userService.confirmAccount(userToComplete, this.hash).subscribe(() => {
      // this.setFormData(updatedUser);
      this.snackbar.open('Your account has been created! Please login with your new password.');
      this.router.navigateByUrl('/login');
    });
  }
}
