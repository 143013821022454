import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TopicType, Workspace } from 'src/app/enums';
import { ModuleService, TopicService } from 'src/app/services';
import { Topic, TopicCategory, TopicGroup } from 'src/app/types';
import { isTopicVisibleToDispatch } from '../../utils';

@Component({
  selector: 'app-topic-group',
  templateUrl: './topic-group.component.html',
  styleUrls: ['./topic-group.component.scss'],
})
export class TopicGroupComponent implements OnInit {
  @Input() requestType = false;
  @Input() workOrderType = false;
  @Input() showTitle = true;

  private _fields =
    'id,name,building_ids,topic_categories{id,name,is_enabled,topic_group_id,topic_group{id,name},topics{id,name,topic_group_id,topic_type_id,workspace_id,visible_to}}';

  public filteredTopicGroups: Observable<TopicGroup[]>;
  public module_id: number;
  public topic_groups = new FormControl('');

  constructor(private _moduleService: ModuleService, private _topicService: TopicService) {}

  async ngOnInit() {
    await this._loadTopicGroups();
  }

  get resolved_module_id(): number {
    return this.module_id || this._moduleService.workspace_id;
  }

  private async _loadAllRequestTopicGroups() {
    this._topicService.topicGroups = (await this._topicService.getTopicGroups([this._fields]).toPromise())
      ?.map((topicGroup: TopicGroup) => {
        const filtered = topicGroup.topic_categories.filter((topicCategory: TopicCategory) =>
          topicCategory?.topics?.some((topic: Topic) => +topic?.topic_type_id === +TopicType.Request)
        );
        if (filtered.length > 0) {
          return { ...topicGroup, topic_categories: filtered };
        }
        return null;
      })
      .filter((requestTopicGroup: TopicGroup) => requestTopicGroup);
    this._subscribe();
  }

  private addPropertyIsVisibleToDispatch(topicGroups: TopicGroup[]) {
    return topicGroups.map((topicGroup: TopicGroup) => {
      const isVisibleToDispatch = topicGroup.topic_categories.some((topicCategory: TopicCategory) => {
        return topicCategory.topics.some((topic: Topic) => {
          return isTopicVisibleToDispatch(topic);
        });
      });

      return { ...topicGroup, isVisibleToDispatch };
    });
  }

  private async _loadAllTopicGroups() {
    this._topicService.topicGroups = this.addPropertyIsVisibleToDispatch(
      await this._topicService.getTopicGroups([this._fields]).toPromise()
    );
    this._subscribe();
  }

  private async _loadAllWorkOrderTopicGroups() {
    this._topicService.topicGroups = (await this._topicService.getTopicGroups([this._fields]).toPromise())
      ?.map((topicGroup: TopicGroup) => {
        const filtered = topicGroup.topic_categories.filter((topicCategory: TopicCategory) =>
          topicCategory?.topics?.some((topic: Topic) => {
            if (+this.resolved_module_id === +Workspace.Dispatch) {
              return +topic.topic_type_id === +TopicType.WorkOrder;
            }

            return +topic.topic_type_id === +TopicType.WorkOrder && +topic.workspace_id === +this.resolved_module_id;
          })
        );
        if (filtered.length > 0) {
          return { ...topicGroup, topic_categories: filtered };
        }
        return null;
      })
      .filter((workOrderTopicGroup: TopicGroup) => workOrderTopicGroup);
    this._subscribe();
  }

  private async _loadTopicGroups() {
    if (this.workOrderType && !this.requestType) {
      await this._loadAllWorkOrderTopicGroups();
    } else if (this.requestType && !this.workOrderType) {
      await this._loadAllRequestTopicGroups();
    } else {
      await this._loadAllTopicGroups();
    }
  }

  private _subscribe(): void {
    this.filteredTopicGroups = this.topic_groups.valueChanges.pipe(
      startWith(''),
      map((value: string | TopicGroup) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this._topicService.topicGroups.slice()))
    );
  }

  public async clearInput(event = null) {
    this.topic_groups.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  private _filter(entry: string): TopicGroup[] {
    return this._topicService.topicGroups.filter((topicGroup) =>
      topicGroup?.name?.toLowerCase()?.includes(entry?.toLowerCase())
    );
  }

  public async getTopicGroupById(topicGroupId: number): Promise<TopicGroup> {
    const topicGroup = await this._topicService
      .getTopicGroups([this._fields], [{ type: 'field', field: 'id', value: topicGroupId.toString() }])
      .toPromise();
    return topicGroup.length > 0 ? topicGroup[0] : null;
  }

  public async reloadAllTopicGroups() {
    await this._loadTopicGroups();
  }

  public async reLoadAllWorkOrderTopicGroups() {
    await this._loadAllWorkOrderTopicGroups();
  }

  public topicGroupMapper(topicGroup: TopicGroup): string {
    return topicGroup?.name || '';
  }
}
