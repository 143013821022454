<div class="row align-items-center pt-1 pb-1 newTask m-0" id="m_table_1">
  <div class="col-auto pl-0" style="width: 45px">
    <button mat-icon-button>
      <mat-icon class="green" matTooltip="New Task" matTooltipPosition="above" [matTooltipShowDelay]="300"
        >assignment_turned_in</mat-icon
      >
    </button>
  </div>
  <div class="col-auto pl-0 pr-1">
    <button
      (click)="openAssignUserDialog()"
      mat-icon-button
      *ngIf="!assignedUser"
      class="ltgray addUser"
      matTooltip="Assign Task"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
    >
      <mat-icon>person_add</mat-icon>
    </button>
    <span *ngIf="assignedUser && assignedUser.id" style="margin-right: 11px">
      <app-profile-thumbnail
        matRipple
        (click)="openAssignUserDialog()"
        class="d-inline-block align-middle pointer"
        [width]="32"
        [height]="32"
        [userId]="assignedUser.id"
      ></app-profile-thumbnail>
    </span>
  </div>
  <div class="col pl-0">
    <mat-form-field appearance="outline" class="full-width">
      <input
        matInput
        autofocus
        #taskTitleInput
        [(ngModel)]="title"
        class="dkblue font-weight-semibold"
        placeholder="What needs to be done?"
        (keydown.enter)="$event.preventDefault(); submit()"
      />
    </mat-form-field>
  </div>
  <div class="col-auto text-right">
    <button
      mat-icon-button
      class="ltgray"
      matTooltip="Cancel New Task"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
      (click)="cancelCreation()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="success"
      class="ml-4"
      matTooltip="Add Task"
      matTooltipPosition="above"
      [matTooltipShowDelay]="300"
      (click)="submit()"
    >
      Save Task
    </button>
  </div>
</div>
