import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ApplicationRole, UserType } from 'src/app/enums';
import {
  AppRoleService,
  AuthService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UserService,
} from 'src/app/services';
import { APIFilter, ApplicationRole as Role, Project, ProjectUser } from 'src/app/types';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';
import { ModuleService } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class ProjectDirectoryService {
  constructor(
    private roleService: AppRoleService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private userService: UserService,
    private moduleService: ModuleService,
    public projectTenantService: ProjectTenantService,
    private authService: AuthService
  ) {}
  users$: BehaviorSubject<ProjectUser[]>[] = [];
  roles: Role[] = [];
  currentProject: Project | ProjectConstruction;
  staffUsers: ProjectUser[];
  tenantUsers: ProjectUser[];
  vendorUsers: ProjectUser[];

  public hasStaff = false;
  public hasTenants = false;
  public hasVendors = false;

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  // Loads all vendor users for the current project, converts them to Vendors, and adds them to users Vendor
  public async loadUsersForProject(projectId: number, requestId) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Users..');
    this.currentProject = this.projectService.currentSelectedProject;
    await this.getAllRoles();

    let followers;
    if (this.isWorkspaceStaff) {
      // this loads all userIds from users who follow any of the tasks for the project
      followers = await this.taskService.getFollowerIdsByProjectId(projectId).toPromise();
    }
    const users = await this.taskService.getOtherIdsByProjectId(projectId).toPromise();

    // in order to load the tenants, the user must be staff or tenant
    let projectTenants;
    if (this.authService.isTenant || this.isWorkspaceStaff) {
      projectTenants = await this.projectTenantService.getTenantsForProject(projectId).toPromise();
    }

    let user;
    if (this.isWorkspaceStaff) {
      // get all users with tasks assigned to in the project
      user = await this.taskService.getAssignedIdsByProjectId(projectId).toPromise();
    }

    let allUserIds: number[] = [];
    const allUsers = {};
    if (followers) {
      followers.forEach((id) => allUserIds.push(+id));
    }
    if (users.cfmo) {
      allUserIds.push(+users.cfmo);
    }
    if (users.pm) {
      allUserIds.push(+users.pm);
    }
    if (users.wm) {
      allUserIds.push(+users.wm);
    }
    if (users.architect) {
      allUserIds.push(+users.architect);
    }
    if (users.eng) {
      users.eng.forEach((id) => allUserIds.push(+id));
    }
    if (projectTenants) {
      projectTenants.forEach((tenant) => allUserIds.push(+tenant.representative_id));
    }
    if (user) {
      user.forEach((id) => allUserIds.push(+id));
    }

    allUserIds = uniq(allUserIds);

    await this.userService
      .getUserByIds(allUserIds)
      .toPromise()
      .then((allRelevantUsers) => {
        allRelevantUsers?.forEach((relevantUser) => (allUsers[relevantUser.id] = relevantUser));
      });
    this.staffUsers = [];
    this.tenantUsers = [];
    this.vendorUsers = [];

    if (this.isWorkspaceStaff) {
      // this loads all userIds from users who follow any of the tasks for the project
      for (const userId of followers) {
        this.addFollowerUser(allUsers[userId]);
      }
    }

    // get the cfmo,wm,pm,architects for the project
    if (users.cfmo) {
      this.addStaffRoleUser(allUsers[users.cfmo], ApplicationRole.ChiefFacilitiesManagementOfficer);
    }
    if (users.pm) {
      this.addStaffRoleUser(allUsers[users.pm], ApplicationRole.ProjectManager);
    }
    if (users.wm) {
      this.addStaffRoleUser(allUsers[users.wm], ApplicationRole.WorkspaceManager);
    }
    if (users.architect) {
      this.addStaffRoleUser(allUsers[users.architect], ApplicationRole.ProjectArchitect);
    }
    // as a staff member, load all engineers and their coworkers for the vendors tab
    if (this.isWorkspaceStaff) {
      for (const engineer of users.eng) {
        this.addVendorRoleUser(allUsers[engineer], ApplicationRole.ProjectEngineer);
      }

      // added 7/7/20
      // load all users in the companies that have awarded bids on the project
      // If project type is construction
      if (this.currentProject.module_id === 1) {
        const filters: APIFilter[] = [
          {
            type: 'field',
            field: 'project_id',
            value: this.projectService.currentSelectedProjectId.toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_awarded', value: '1' },
        ];
        const company_ids = await this.projectService.getBids(filters, ['company_id']).toPromise();
        const distinct_ids = [];
        for (const bid of company_ids) {
          if (!distinct_ids.includes(+bid.company_id)) {
            distinct_ids.push(+bid.company_id);
          }
        }
        await this.addOtherVendorsInCompanies(distinct_ids);
      } else {
        // Gets vendors another way
      }
    } else if (
      this.authService.isProjectVendor(this.projectService.currentSelectedProjectId) ||
      this.authService.isProjectEngineer(this.projectService.currentSelectedProjectId)
    ) {
      // if the user is a vendor, and they have access to the project, show their company's users
      const userData = await this.userService.getUserById(this.authService.getLoggedInUser().id).toPromise();
      await this.addOtherVendorsInCompanies([userData.company_id]);
    }

    // in order to load the tenants, the user must be staff or tenant
    if (this.authService.isTenant || this.isWorkspaceStaff) {
      const projectRequesters = await this.userService
        .getUsersByRole(5, projectId, null, this.userService.userFields)
        .toPromise();
      for (const requester of projectRequesters) {
        this.addTenantRoleUser(requester, ApplicationRole.ProjectRequester);
      }
      const projectContacts = await this.userService
        .getUsersByRole(22, projectId, null, this.userService.userFields)
        .toPromise();
      for (const contact of projectContacts) {
        this.addTenantRoleUser(contact, ApplicationRole.ProjectContact);
      }

      // find the unique user ids for the project tenant reps
      const rep_ids = [];
      for (const tenant of projectTenants) {
        if (tenant.representative_id && !rep_ids.includes(tenant.representative_id)) {
          rep_ids.push(tenant.representative_id);
        }
      }

      // for each rep user, map all of their project-tenant departments
      for (const id of rep_ids) {
        this.addTenantRoleUser(
          allUsers[id],
          ApplicationRole.CustomerProjectRepresentative,
          projectTenants.filter((p) => +p.representative_id === +id).map((p) => p.tenant_name),
          true
        );
      }
    }

    if (this.isWorkspaceStaff) {
      // get all users with tasks assigned to in the project
      for (const userId of user) {
        this.addAssignedUser(allUsers[userId]);
      }
    }

    this.hasStaff = this.staffUsers.length > 0;
    this.hasTenants = this.tenantUsers.length > 0;
    this.hasVendors = this.vendorUsers.length > 0;
    this.users$[UserType.Staff].next(this.staffUsers);
    this.users$[UserType.Tenant].next(this.tenantUsers);
    this.users$[UserType.Vendor].next(this.vendorUsers);
    this.progressIndicatorService.close();
  }

  private async addOtherVendorsInCompanies(passedUsers) {
    // filter out any users without a company id
    const company_ids = passedUsers.filter((u) => u);

    // then get all the users from the companies of userData
    if (company_ids.length > 0) {
      const users = await this.userService
        .getUsersByCompany(company_ids, [
          'id,role_id,first_name,last_name,department_name,company_name,title,trades,is_login_enabled',
        ])
        .toPromise();
      // for each user, add them to the vendor list by their role
      for (const user of users) {
        let isEngineer = false;
        user.role_id?.forEach((role) => {
          if (
            (+role.role_id === ApplicationRole.ModuleEngineer &&
              +role.resource_id === this.projectService.currentSelectedProject?.module_id) ||
            (+role.role_id === ApplicationRole.ProjectEngineer &&
              +role.resource_id === this.projectService.currentSelectedProjectId)
          ) {
            isEngineer = true;
          }
        });
        if (isEngineer) {
          this.addVendorRoleUser(user, ApplicationRole.ProjectEngineer);
        } else {
          this.addVendorRoleUser(user, ApplicationRole.ProjectVendor);
        }
      }
    }
  }

  private async getAllRoles() {
    this.roles = await this.roleService.loadRoles().toPromise();
  }
  private getRoleNames(roles): string[] {
    const names = [];
    roles.forEach((role) => {
      names.push(this.roles.find((r) => +r.id === +role));
    });
    return names.map((role) => role.name);
  }

  private addStaffRoleUser(user, roleId: number) {
    this.addProjectUser({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      department_name: user.department_name,
      company_name: user.company_name,
      type: 1,
      typeId: user.user_type_id,
      title: user.title,
      roles: this.getRoleNames([roleId]),
      is_login_enabled: user.is_login_enabled,
      trades: user.trades,
    });
  }

  private addTenantRoleUser(user, roleId: number, departmentNames?: string[], override = false) {
    let department = user.department_name;
    if (departmentNames) {
      department = departmentNames.join('/');
    }
    this.addProjectUser(
      {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        department_name: department,
        company_name: user.company_name,
        type: 2,
        typeId: user.user_type_id,
        title: user.title,
        roles: this.getRoleNames([roleId]),
        is_login_enabled: user.is_login_enabled,
        trades: user.trades,
      },
      override
    );
  }

  private addVendorRoleUser(user, roleId: number, departmentName?: string) {
    this.addProjectUser({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      department_name: departmentName || user.department_name,
      company_name: user.company_name,
      type: 3,
      typeId: user.user_type_id,
      title: user.title,
      roles: this.getRoleNames([roleId]),
      is_login_enabled: user.is_login_enabled,
      trades: user.trades,
    });
  }

  private addFollowerUser(user) {
    this.addProjectUser({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      department_name: user.department_name,
      company_name: user.company_name,
      type: user.user_type_id,
      title: user.title,
      roles: ['Task Follower'],
      is_login_enabled: user.is_login_enabled,
      trades: user.trades,
    });
  }

  private addAssignedUser(user) {
    this.addProjectUser({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      department_name: user.department_name,
      company_name: user.company_name,
      type: user.user_type_id,
      title: user.title,
      roles: ['Task Assignee'],
      is_login_enabled: user.is_login_enabled,
      trades: user.trades,
    });
  }

  private addProjectUser(user, override = false) {
    const arr = user.type === 1 ? this.staffUsers : user.type === 2 ? this.tenantUsers : this.vendorUsers;
    const foundUser = arr.find((u) => +u.id === +user.id);
    // check if they are already in the list, and if so, just add the new role
    if (foundUser) {
      user.roles?.forEach((role) => {
        if (!foundUser.roles.includes(role)) {
          foundUser.roles.push(role);
        }
      });
      if (override) {
        foundUser.department_name = user.department_name;
      }
    } else {
      arr.push(user);
    }
  }
}
