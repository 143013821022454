<app-base-dialog
  title="Upload Submittals - {{ bidPackage?.number }} {{ bidPackage?.number ? '-' : '' }} {{ bidPackage?.trade_name }}"
  (closeDialog)="cancel()"
></app-base-dialog>
<div id="add-submittals-dialog" data-test-id="add-submittals-dialog" class="add-submittals-dialog">
  <div class="d-flex flex-column align-items-center view-key-controls-dialog p-5 mx-auto" *ngIf="isLoading">
    <mat-spinner color="accent" [diameter]="40"></mat-spinner>
  </div>
  <div class="submittals-data">
    <section
      [hidden]="c.submittals.length === 0"
      class="section d-flex full-width flex-column mb-4 pb-2"
      *ngFor="let c of submittalCategories"
    >
      <div (click)="c.is_expanded = !c.is_expanded" class="d-flex full-width align-items-center mb-3 pointer">
        <mat-icon class="pointer" *ngIf="c.is_expanded">chevron_right</mat-icon>
        <mat-icon class="pointer" *ngIf="!c.is_expanded">expand_more</mat-icon>
        <h5 class="dkblue m-0 ml-3 font-weight-bold">{{ c.code }}<span class="ml-3 mr-3">•</span>{{ c.name }}</h5>
      </div>

      <div *ngIf="!c.is_expanded" class="m-portlet mb-4">
        <div
          *ngFor="let s of c.submittals; let last = last"
          class="m-portlet__body item ease p-3 pointer"
          [ngClass]="{ 'item-last': last }"
        >
          <div class="row align-items-center">
            <div class="col d-flex align-items-center">
              <h5 class="m-0 font-weight-semibold dkgray text-nowrap text-truncate">
                {{ s.spec.code }}<span class="ml-3 primary">{{ s.spec.name }}</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="col align-self-stretch px-4 pb-5 pt-0" *ngIf="!isLoading && project?.id">
      <app-file-upload-zone
        class="full-width"
        [parentResourceId]="project?.id"
        [parentResourceType]="ResourceType.Project"
        (filesUploaded)="openUploadFileModal($event)"
      >
      </app-file-upload-zone>
      <mat-chip-list>
        <app-file-chip
          *ngFor="let file of reviewFiles"
          [file]="file"
          [removable]="true"
          (removeEvent)="removeFile(file)"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="d-flex align-items-center full-width mb-2 mt-5">
    <div class="col text-right">
      <button (click)="cancel()" mat-button type="button" class="gray">Cancel</button>
      <button
        (click)="submit()"
        mat-flat-button
        type="submit"
        color="success"
        class="ml-3"
        [disabled]="!reviewFiles?.length"
      >
        Submit for Review
      </button>
    </div>
  </div>
</div>
