import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent, EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { FileService, ProjectService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { Punchlist } from 'src/app/workspaces/construction/models';
import { PunchlistService } from 'src/app/workspaces/construction/services';
import { Trade } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-punchlist-input-modal',
  templateUrl: './punchlist-input-modal.component.html',
  styleUrls: ['./punchlist-input-modal.component.scss'],
})
export class PunchlistInputModalComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  public projectTrades$: Observable<Trade[]>;

  public selectedTrade: Trade;

  private projectId: number;

  public description = '';
  public description_length = 75;

  public location = '';

  public addedFiles: UhatFileReference[] = [];

  public existingFiles: UhatFileReference[] = [];

  private filesToRemove: UhatFileReference[] = [];

  public isEditing = false;
  public viewOnly: boolean;

  constructor(
    private fileService: FileService,
    private projectService: ProjectService,
    private punchlistService: PunchlistService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.projectId = this.projectService.currentSelectedProjectId;
    const punchlistData = this.data.punchlistData;
    this.viewOnly = this.data?.viewOnly;
    if (punchlistData) {
      this.selectedTrade = punchlistData.trade_id;
      this.description = punchlistData.description;
      this.location = punchlistData.location;
      this.additionalNotes.setValue(punchlistData.additional_notes);
      this.existingFiles = punchlistData.files || [];
      this.isEditing = true;
    }
    this.projectTrades$ = this.punchlistService.getProjectPunchlistTradesObservable(this.projectId);
    this.punchlistService.loadProjectPunchlistTrades(this.projectId).subscribe((trades: Trade[]) => {
      // Set the correct initial value for the punchlist input field.
      if (this.data.punchlistData && this.data.punchlistData.trade_id) {
        this.selectedTrade = trades.find((trade) => trade.id === this.data.punchlistData.trade_id);
      }
    });
  }

  get additionalNotes(): AbstractControl {
    return this._editor_component.content;
  }

  get description_counter(): string {
    return `${this.description?.length || 0} / ${this.description_length}`;
  }

  public saveTaskAndClear() {
    if (!this.isValid()) {
      return;
    }

    this.saveTask().subscribe((createdPunchlist) => {
      if (createdPunchlist) {
        // Punchlist is created. Not closing so we will clear the fields
        this.clearFields();
      } else {
        console.warn('Null Punchlist Returned');
      }
    });
  }

  public saveTaskAndClose() {
    if (!this.isValid()) {
      return;
    }

    this.saveTask().subscribe((createdPunchlist) => {
      if (createdPunchlist) {
        this.dialogRef.close(createdPunchlist);
      } else {
        console.warn('Null Punchlist Returned');
      }
    });
  }

  public openFileAttachmentDialog() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectId,
          allowComment: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((filesToAttach: UhatFileReference[]) => {
        if (filesToAttach) {
          filesToAttach.forEach((f) => this.addFile(f));
        }
      });
  }

  public addFile(file: UhatFileReference) {
    if (this.addedFiles.find((f) => f.name === file.name) != null) {
      return;
    }
    this.addedFiles.push(file);
  }

  public removeAddedFile(file: File) {
    this.addedFiles = this.addedFiles.filter((f) => f.name !== file.name);
  }

  public removeExistingFile(file: UhatFileReference) {
    this.existingFiles = this.existingFiles.filter((f) => f.name !== file.name);
    this.filesToRemove.push(file);
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  private saveTask(): Observable<Punchlist> {
    const selectedTradeId = this.selectedTrade ? this.selectedTrade.id : null;
    if (!this.isEditing) {
      return this.punchlistService.createNewPunchlistItem(
        this.projectId,
        this.description,
        selectedTradeId,
        this.location,
        this.additionalNotes.value,
        this.addedFiles
      );
    } else {
      return this.punchlistService.updatePunchlistItem(
        this.data.punchlistData.id,
        this.projectId,
        this.description,
        selectedTradeId,
        this.location,
        this.additionalNotes.value,
        this.addedFiles,
        this.filesToRemove
      );
    }
  }

  public isValid(): boolean {
    return (
      this.projectId != null &&
      this.projectId > 0 &&
      this.description &&
      this.description.length > 0 &&
      this.description.length <= this.description_length &&
      this.selectedTrade != null
    );
  }

  private clearFields() {
    this.additionalNotes.setValue('');
    this.location = '';
    this.addedFiles = [];
    this.filesToRemove = [];
    this.existingFiles = [];
    this.selectedTrade = null;
    this.description = '';
    this.isEditing = false;
  }

  public selectTrade(selection: Trade) {
    if (!selection) {
      this.selectedTrade = null;
      return;
    }
    this.selectedTrade = selection;
  }
}
