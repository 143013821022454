<div id="rfi-dialog" data-test-id="rfi-dialog" class="rfi-dialog">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="dkblue m-0 font-weight-semibold">{{ action }} RFI</h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times gray"></i>
      </a>
    </div>
  </div>
  <form [formGroup]="rfiFormGroup">
    <div mat-dialog-content>
      <div class="row" *ngIf="rfi.created_by_id">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Created By</mat-label>
            <input matInput disabled type="text" [value]="rfi.created_by_first_name + ' ' + rfi.created_by_last_name" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput required="true" type="text" formControlName="description" />
            <mat-error *ngIf="description.errors?.required">Description is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Due Date</mat-label>
            <input
              matInput
              required="true"
              (click)="dueDatePicker.open()"
              [matDatepicker]="dueDatePicker"
              autocomplete="off"
              formControlName="due_date"
            />
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="due_date.errors?.required">Due date is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Assign RFI to:</mat-label>
            <mat-select formControlName="assigned_user_id">
              <mat-option *ngFor="let u of rfi.projectUsers" [value]="u.id"
                >{{ u.first_name }} {{ u.last_name }} ({{ roles[+u.roles[0].id].name }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-editor
            #editor
            placeholder="Question*"
            required="true"
            required_content="Question is required"
          ></app-editor>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <p class="m-0 dkblue">If necessary, please attach any files to support this RFI.</p>
          <button mat-icon-button color="accent" class="mr-3" (click)="openUploadModal()">
            <mat-icon>attach_file</mat-icon>
          </button>
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip
              *ngFor="let f of rfi.files"
              [file]="f"
              [removable]="true"
              (removeEvent)="removeFile(f)"
            ></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div class="row" mat-dialog-actions>
      <div class="col">
        <button mat-button type="button" class="gray full-width" color="accent" (click)="cancel()">Cancel</button>
      </div>
      <div class="col">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">Save RFI</button>
      </div>
    </div>
  </form>
</div>
