import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { MeetingService, ModuleService } from 'src/app/services';
import { ConfirmationDialogComponent, DatepickerHeaderComponent } from 'src/app/components';
import { Meeting, APIFilter, AgendaItem } from 'src/app/types';
import { ResourceType } from 'src/app/enums';
import { startCase } from 'lodash';

@Component({
  selector: 'app-meeting-select-dialog',
  templateUrl: './meeting-select-dialog.component.html',
  styleUrls: ['./meeting-select-dialog.component.scss'],
})
export class MeetingSelectDialogComponent implements OnInit {
  allMeetings: Meeting[];

  searchValue: string;
  searchInput: Subject<string> = new Subject();
  startDate;
  endDate;
  customHeader = DatepickerHeaderComponent;

  showDateRange;

  processing = false;

  constructor(
    private meetingService: MeetingService,
    public dialogRef: MatDialogRef<MeetingSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _dialog: MatDialog,
    private _workspaceService: ModuleService
  ) {}

  ngOnInit() {
    this.searchInput.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
      this.searchMeetings(searchTerm);
    });
    this.searchInputChange();
  }

  get dialogTitle(): string {
    return this.data?.title || '';
  }

  private _parentName(parent_type_id: ResourceType): string {
    switch (parent_type_id) {
      case ResourceType.Milestone:
        return 'milestone';
      case ResourceType.Project:
        return 'project';
      case ResourceType.RFI:
        return 'rfi';
      case ResourceType.Task:
        return 'task';
      case ResourceType.WorkOrder:
        return 'work order';
      default:
        return 'item';
    }

    return '';
  }
  searchInputChange() {
    this.searchInput.next(this.searchValue);
  }

  searchMeetings(searchTerm) {
    const meetingFilters: APIFilter[] = [];
    // if this.startDate is null, it will resolve to today
    meetingFilters.push({
      type: 'field',
      field: 'end_datetime',
      value: moment(this.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      match: '>=',
    });
    if (this.endDate) {
      meetingFilters.push({ type: 'operator', value: 'AND' });
      meetingFilters.push({
        type: 'field',
        field: 'end_datetime',
        value: moment(this.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        match: '<=',
      });
    }
    if (searchTerm) {
      const searchFields = ['code', 'title'];
      meetingFilters.push({ type: 'operator', value: 'AND' });
      let index = 0;
      meetingFilters.push({ type: 'operator', value: '(' });
      for (const f of searchFields) {
        if (index !== 0) {
          meetingFilters.push({ type: 'operator', value: 'OR' });
        }
        meetingFilters.push({
          type: 'field',
          field: f,
          value: searchTerm.toLowerCase(),
          match: 'any',
        });
        index++;
      }
      meetingFilters.push({ type: 'operator', value: ')' });
    }
    meetingFilters.push({ type: 'operator', value: 'AND' });
    meetingFilters.push({
      type: 'field',
      field: 'is_concluded',
      value: '0',
      match: 'exact',
    });
    this.meetingService
      .getMeetings(meetingFilters, [
        'code',
        'parent_id',
        'parent_type_id',
        'start_datetime',
        'title',
        'location',
        'workspace_id',
        'workspace_name',
      ])
      .subscribe((result) => {
        this.allMeetings = result.data.meetings;
      });
  }

  async submit(meeting) {
    const meetingData = await this.meetingService.getInitDataMeetingById(meeting.id).toPromise();

    // find the meeting agenda item
    const foundAgendaItem = meetingData?.agendaItems?.find(
      (agendaItem: AgendaItem) =>
        +agendaItem?.parent_id &&
        +agendaItem.parent_type_id === +this.data?.parent_type_id &&
        +agendaItem.parent_id === +this.data?.parent_id
    );

    // if the meeting has a parent, ask the user to see if thats what they want
    if (+foundAgendaItem?.parent_id && +foundAgendaItem.parent_id === +this.data?.parent_id) {
      this._dialog
        .open(ConfirmationDialogComponent, {
          data: {
            titleBarText: 'Add Agenda Item',
            confirmationButtonText: 'Add To Meeting',
            headerText: 'Add ' + startCase(this._parentName(this.data?.parent_type_id)) + ' to Meeting Agenda',
            descriptionText: `This ${startCase(
              this._parentName(this.data?.parent_type_id)
            )} has already been added to this meeting's agenda. Do you still want to add it to the meeting?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.dialogRef.close(meeting);
          } else {
            this.close();
          }
        });
    } else {
      this.dialogRef.close(meeting);
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}
