<app-base-dialog [title]="'Transfer'" (closeDialog)="cancel()"></app-base-dialog>
<div
  id="transfer-wo-dialog"
  data-test-id="transfer-wo-dialog"
  class="pt-4 transfer-wo-dialog"
  style="max-width: 400px; min-width: 400px"
>
  <div mat-dialog-content>
    <div class="row mb-4">
      <div class="col">
        <h4 class="m-0 dkblue font-weight-semibold d-flex align-items-center">
          <span class="mr-2"> Transfer Work Order </span>
          <mat-icon>east</mat-icon>
        </h4>
      </div>
    </div>
    <!-- Informational Message -->
    <div class="row mb-4">
      <div class="col-12">
        <h5
          class="dkgray font-weight-normal mb-3"
          style="line-height: 1.8rem; white-space: pre-wrap"
          [innerHTML]="'To transfer this Work Order, define the new workspace and reason for transferring below.'"
        ></h5>
        <h5
          class="dkgray font-weight-normal mb-2"
          style="line-height: 1.8rem; white-space: pre-wrap"
          [innerHTML]="
            'If this transer revokes your access to this work order, you will be redirected to the Work Order list.'
          "
        ></h5>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <h6 class="mb-2 dkblue font-weight-semibold">Transfer To</h6>
        <mat-form-field appearance="outline" class="full-width">
          <mat-select
            matNativeControl
            [(value)]="selectedWorkspace"
            (selectionChange)="selectWorkspace($event.value)"
            [disabled]="!filteredWorkspaces?.length"
            placeholder="Select Workspace"
          >
            <mat-option *ngFor="let w of filteredWorkspaces" [value]="w">{{ w.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Text Area Enabled -->
    <div class="row">
      <div class="col textarea">
        <h6 class="mb-2 dkblue font-weight-semibold">Transfer Reason*</h6>
        <app-editor #editor placeholder="Why do you need to transfer this WO?"></app-editor>
      </div>
    </div>
  </div>
  <div class="row align-items-center mt-3 mb-2">
    <div class="col">
      <button mat-button (click)="cancel()" class="gray full-width">Cancel Transfer</button>
    </div>
    <div class="col">
      <button mat-flat-button (click)="continue()" class="full-width" [disabled]="!isValidToSubmit()" color="accent">
        Transfer WO
      </button>
    </div>
  </div>
</div>
