import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { HandleErrorService } from './handle-error.service';

import { ApplicationRole, ServiceResponse } from '../types';
/**
 * Role Service is responsible for interaction with the roles table in the database, caching the data, and performing local role comparison
 * and data retrieval operations.
 */

@Injectable({
  providedIn: 'root',
})
export class AppRoleService {
  private host: string = environment.serviceHost;

  public readonly ROLES_URL = `${this.host}/api/v1/roles`;

  private roles = new BehaviorSubject<ApplicationRole[]>([]);

  private hasLoaded: boolean;

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {
    this.loadRoles().subscribe();
  }

  public loadRoles(): Observable<ApplicationRole[]> {
    const roleFields = ['id', 'name', 'resource_type_id', 'entity_type_id'];
    return this.http.get(`${this.ROLES_URL}?fields=${roleFields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        // Convert result data from bid_packages table into Trade objects and return the resulting array
        this.roles.next(result.data.roles);
        this.hasLoaded = true;
        return result.data.roles;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getRoleName(roleId: number): string {
    if (!this.hasLoaded) {
      this.loadRoles().subscribe((loaded) => (this.hasLoaded = true));
      return 'someone';
    } else {
      return this.roles.value.filter((r) => r.id === roleId)[0].name || 'someone';
    }
  }

  public getRolesObservable(): Observable<ApplicationRole[]> {
    if (!this.hasLoaded) {
      this.loadRoles().subscribe();
    }
    return this.roles.asObservable();
  }
}
