import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ARManagerDialogComponent } from 'src/app/components';
import { FOUND_PAGE, ResourceType } from 'src/app/enums';
import {
  AuthService,
  ExportService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  UserService,
} from 'src/app/services';
import { ArHistory, User } from 'src/app/types';

@Component({
  selector: 'app-ar-manager',
  templateUrl: './ar-manager.component.html',
  styleUrls: ['./ar-manager.component.scss'],
})
export class ARManagerComponent implements OnInit {
  public isFetching = false;
  public isSearchingNonArUsers = false;
  public authorizedUsers: User[] = [];
  public unAuthorizedUsers: User[] = [];
  public filteredUsers: User[] = [];
  public searchAllUsersTerm = new FormControl('');
  public searchAuthorizedUsersTerm = new FormControl('');
  public selectedUserColumns = [];
  public userFields = [
    'first_name',
    'last_name',
    'email',
    'title',
    'company_name',
    'department_name',
    'user_type_name',
    'is_ar',
    'office_phone',
    'cell_phone',
    'building_name',
  ];

  // use to export the csv
  public userColumns = [
    { field: 'first_name', header: 'First Name' },
    { field: 'last_name', header: 'Last Name' },
    { field: 'email', header: 'Email' },
    { field: 'company_name', header: 'Company' },
    { field: 'department_name', header: 'Department' },
    { field: 'building_name', header: 'Building' },
    { field: 'user_type_name', header: 'Type' },
    { field: 'cell_phone', header: 'Cell Phone' },
    { field: 'office_phone', header: 'Office Phone' },
  ];

  get isAppAdmin() {
    return this.authService.isAppAdmin;
  }

  get isARManager() {
    return this.authService.isARManager;
  }

  constructor(
    private userService: UserService,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private fileService: FileService,
    private router: Router,
    private exportService: ExportService
  ) {}

  async ngOnInit() {
    if (!this.isAppAdmin && !this.isARManager) {
      this.router.navigate(['/404'], {
        queryParams: {
          status: FOUND_PAGE.NOT_AUTHORIZED,
        },
      });
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Gathering Accounts..');
    this.authorizedUsers = await this.userService
      .getUsers([{ type: 'field', field: 'is_ar', value: `1` }], this.userFields)
      .toPromise();
    this.searchAllUsersTerm.valueChanges
      .pipe(
        tap(() => {
          this.isFetching = true;
        }),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        await this.searchAllUsers();
        this.isFetching = false;
      });
    this.searchAuthorizedUsersTerm.valueChanges
      .pipe(
        tap(() => {
          this.isFetching = true;
        }),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        this.filterUsers();
        this.isFetching = false;
      });
    this.filterUsers();
    this.progressIndicatorService.close();

    this.selectedUserColumns = this.userColumns;
  }

  async filterUsers() {
    if (this.searchAllUsersTerm?.value) {
      this.searchAllUsersTerm.reset();
    }

    if (this.searchAuthorizedUsersTerm?.value) {
      this.filteredUsers = this.authorizedUsers.filter(
        (authorizedUser) =>
          authorizedUser?.first_name
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.last_name
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.email
            ?.toString()
            .toLowerCase()
            .includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.cell_phone
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.office_phone
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser.company_name
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.department_name
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase()) ||
          authorizedUser?.title
            ?.toString()
            ?.toLowerCase()
            ?.includes(this.searchAuthorizedUsersTerm?.value?.toLowerCase())
      );
    } else {
      this.clear();
    }
  }

  addOrRemoveARPermission(user: User) {
    const isRemoving = user.is_ar;
    const titleBarText = isRemoving ? 'Remove AR Permissions' : 'Add AR Permissions';
    const descriptionText = `To ${isRemoving ? 'remove' : 'add'} authorized requester permissions for ${
      user.first_name
    } ${user.last_name}, please enter your reasoning below${
      isRemoving ? '.' : ' and use the button to attach the required AR Form.'
    }`;
    const confirmationData = {
      requiredFilesCount: 1,
      titleBarText,
      descriptionText,
      headerText: isRemoving ? 'Remove AR Access' : 'Add AR Access',
      fileOptional: !!isRemoving,
      confirmationButtonText: isRemoving ? 'Remove AR Access' : 'Add AR Access',
      userInput: {
        placeholder: isRemoving ? 'Reason for removing user' : 'Add note here',
        required: !!isRemoving,
      },
    };

    this.modalService.openConfirmationDialog(confirmationData).subscribe(async (res) => {
      if (res) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating user access..');
        const historyData: ArHistory = { is_ar: isRemoving ? 0 : 1, note: res.res };
        const historyEntryId = await this.userService.updateARPermissions(user.id, historyData).toPromise();
        const promises = [];
        for (const f of res.newFiles ?? []) {
          promises.push(this.fileService.createFile(f, historyEntryId, ResourceType.ARHistory).toPromise());
        }
        await Promise.all(promises);
        user.is_ar = !isRemoving;
        // get a new batch
        await this.fetchARUsers();
      }
    });
  }

  async viewHistory(user: User) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading history for user..');

    const history = await this.userService.getARHistory(user.id).toPromise();
    this.dialog.open(ARManagerDialogComponent, {
      width: '900px',
      autoFocus: false,
      data: { user, history },
    });

    this.progressIndicatorService.close();
  }

  async searchAllUsers() {
    if (this.searchAuthorizedUsersTerm?.value) {
      this.searchAuthorizedUsersTerm.reset();
    }

    if (this.searchAllUsersTerm?.value) {
      // will contain the field we are  search on
      this.unAuthorizedUsers = await this.userService
        .getUsers(
          [
            { type: 'field', field: 'is_ar', value: `0` },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            {
              type: 'field',
              field: 'email',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'full_name',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'company_name',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'department_name',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'title',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'user_type_name',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'cell_phone',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: 'OR' },
            {
              type: 'field',
              field: 'office_phone',
              value: `${this.searchAllUsersTerm?.value.toLowerCase() || ''}`,
              match: 'any',
            },
            { type: 'operator', value: ')' },
          ],
          this.userFields
        )
        .toPromise();
      this.filteredUsers = this.unAuthorizedUsers;
    } else {
      this.clear();
    }
  }

  clear() {
    if (this.searchAllUsersTerm?.value) {
      this.searchAllUsersTerm.reset();
    }

    if (this.searchAuthorizedUsersTerm?.value) {
      this.searchAuthorizedUsersTerm.reset();
    }
    this.filteredUsers = this.authorizedUsers;
  }

  public async exportData() {
    const selectedHeaders = this.selectedUserColumns.map((column) => column.header);
    const selectedFields = this.selectedUserColumns.map((column) => column.field);
    const totalSelectedField = selectedFields.length;

    // only pick the choosen columns
    const userRows = this.authorizedUsers.map((arUser: User) => {
      let dataPoint = '';

      selectedFields.forEach((field: string, fieldIndex: number) => {
        dataPoint += `${arUser[field] ?? ''}`.trim().replace(',', '');
        if (fieldIndex < totalSelectedField - 1) {
          dataPoint += ',';
        }
      });
      return dataPoint;
    });
    const exportData = [`${selectedHeaders.join(',')}`, ...userRows];

    const filename = `authorized_users_${moment().format('YYYYMMDD')}`;

    // export
    this.exportService.exportToCsv(filename, exportData);
  }

  public async fetchARUsers() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading accounts..');
    this.isSearchingNonArUsers = false;
    this.isFetching = false;
    this.authorizedUsers = await this.userService
      .getUsers([{ type: 'field', field: 'is_ar', value: `1` }], this.userFields)
      .toPromise();
    this.filterUsers();
    this.clear();
    this.progressIndicatorService.close();
  }

  public addAnARIsClicked() {
    this.isSearchingNonArUsers = true;
    this.isFetching = false;

    this.clear();
  }
}
