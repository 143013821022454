import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { HandleErrorService } from 'src/app/services';
import { InboxMessageFilter, Conversation, Message } from 'src/app/models';
import { Channel, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) {}

  host: string = environment.serviceHost;

  conversationUrl = `${this.host}/api/v1/conversations`;
  messageUrl = `${this.host}/api/v1/messages`;
  channelUrl = `${this.host}/api/v1/channels`;

  conversationFields = `id,subject,channel_type_id,followers,channel_project_id,channel_project_code,channel_project_title,task_id,task_code,created_datetime,created_by_id,created_by_first_name,created_by_last_name`;
  messageFields = `content,conversation_id,conversation_subject,channel_project_id,channel_id,channel_type_id,to_users,read_by_user_ids,reply_to_id,reply_to_user_id,reply_to_first_name,reply_to_content,is_event,files,created_by_id,created_by_first_name,created_by_last_name,created_datetime`;
  channelFields = `name,project_id,type_id,company_id,department_id`;

  getMessages(limit?, filters?: InboxMessageFilter[], sort?, order?): Observable<any[]> {
    const filterString = filters.join(',');

    // return this.http.get(`${this.messageUrl}?fields=${this.messageFields}${formattedFilters && formattedFilters[0] ? `&filter=${formattedFilters.join(',')}`: ''}&limit=${limit || 25}&${sort ? 'sort=' + sort : ''}&${order ? 'order=' + order : ''}`)
    return this.http
      .get(
        `${this.messageUrl}?fields=${this.messageFields}${filterString !== '' ? `&filter=${filterString}` : ''}&limit=${
          limit || 1000
        }&${sort ? 'sort=' + sort : ''}&${order ? 'order=' + order : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const messages = result.data.messages;
          return messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getMessageById(messageId): Observable<any[]> {
    return this.http.get(`${this.messageUrl}/${messageId}?fields=${this.messageFields}`).pipe(
      map((result: ServiceResponse) => {
        const message = result.data.message[0];
        return message;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getMessagesByConversationIds(conversationIds, limit?, excludeEvents?, sort?, order?): Observable<any[]> {
    return this.http
      .get(
        `${this.messageUrl}?filter=conversation_id=${conversationIds.join('^')}${
          excludeEvents ? ',is_event=0' : ''
        }&fields=${this.messageFields}&limit=${limit || 1000}&${sort ? 'sort=' + sort : ''}&${
          order ? 'order=' + order : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const messages: Message[] = result.data.messages;
          return messages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getConversationById(conversationId): Observable<any[]> {
    return this.http.get(`${this.conversationUrl}/${conversationId}?fields=${this.conversationFields}`).pipe(
      map((result: ServiceResponse) => {
        const conversations = result.data.conversation[0];
        return conversations;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /** Loads the conversations associated with a task */
  public getConversationsByTaskId(taskId: number): Observable<Conversation[]> {
    return this.http.get(`${this.conversationUrl}?filter=task_id=${taskId}&fields=${this.conversationFields}`).pipe(
      map((result: ServiceResponse) => {
        const conversations: Conversation[] = result.data.conversations;
        return conversations;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getConversationsByChannelId(channelId: number): Observable<Conversation[]> {
    return this.http
      .get(`${this.conversationUrl}?filter=channel_id=${channelId}&fields=${this.conversationFields}`)
      .pipe(
        map((result: ServiceResponse) => {
          const conversations: Conversation[] = result.data.conversations;
          return conversations;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getChannelsByProjectId(projectId: number, channelTypeId?: number): Observable<Channel[]> {
    return this.http
      .get(
        `${this.channelUrl}?filter=project_id=${projectId}${channelTypeId ? `,type_id=${channelTypeId}` : ''}&fields=${
          this.channelFields
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const channels: Channel[] = result.data.channels;
          return channels;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  changeMessageStatus(message, status) {
    const body = {
      status,
    };
    return this.http.put(`${this.messageUrl}/${message.id}/status?fields=${this.messageFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedMessage = result.data.message;
        return updatedMessage;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createMessage(message) {
    const body = message;
    return this.http.post(`${this.messageUrl}?fields=${this.messageFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdMessage = result.data.message;
        return createdMessage;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createConversation(conversation) {
    const body = conversation;
    return this.http.post(`${this.conversationUrl}?fields=${this.conversationFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdConversation = result.data.conversation;
        return createdConversation;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  editFollower(userId, conversationId, action) {
    const body = {
      follower_id: userId,
      action,
    };
    return this.http
      .put(`${this.conversationUrl}/${conversationId}/followers?fields=${this.conversationFields}`, body)
      .pipe(
        map((result: ServiceResponse) => {
          const conversation = result.data.conversation;
          return conversation;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
}
