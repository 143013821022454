<mat-form-field appearance="outline" class="full-width">
  <input
    type="text"
    [placeholder]="selectedResource?.placeholder"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="control.value && !searching" class="gray pointer" (click)="clear()" matSuffix>cancel</mat-icon>
  <mat-progress-spinner
    *ngIf="control?.value && searching"
    matSuffix
    mode="indeterminate"
    diameter="20"
  ></mat-progress-spinner>
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn || selectedResource?.displayFn"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      <span style="text-overflow: ellipsis; white-space: nowrap">{{
        displayFn ? displayFn(option) : selectedResource?.displayFn(option)
      }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors">*{{ errorMessage() }}</mat-error>
</mat-form-field>
