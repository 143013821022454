export class MessageFilterSettings {
  public myMessages = true;
  public toMe = false;
  public unread = false;
  public read = false;
  public sent = false;
  public filterProjectId: number;
  public messageSearchString: string;
  public displayByDateDescending = true;
  public allowStaff = true;
  public allowVendors = true;
  public allowTenants = true;
}
