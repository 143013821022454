import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { FilterCostCodeBySearchTermPipe } from 'src/app/pipes';
import { AuthService, ExportService } from 'src/app/services';
import { PurchasingBudgetService } from 'src/app/services/purchasing-budget.service';
import { CostCode, SubCostCode } from 'src/app/types';

@Component({
  selector: 'app-cost-code-help-dialog',
  templateUrl: './cost-code-help-dialog.component.html',
  styleUrls: ['./cost-code-help-dialog.component.scss'],
})
export class CostCodeHelpDialogComponent implements OnInit {
  public costCodes: CostCode[] = [];
  public searchTerm = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { moduleId: number | null },
    private _dialogRef: MatDialogRef<CostCodeHelpDialogComponent>,
    private _exportService: ExportService,
    private _purchasingBudgetService: PurchasingBudgetService,
    private _snackBar: MatSnackBar,
    public authService: AuthService
  ) {}

  get isExporting(): boolean {
    return this._exportService.isExporting;
  }

  ngOnInit(): void {
    void (async () => {
      const sortByCode = (a, b) => (a.code > b.code ? 1 : -1);
      const sortedCostCodes = (
        await this._purchasingBudgetService
          .getCostCodes([
            'code',
            'label',
            'sub_cost_codes{code,label,description,module_ids,sub_cost_code_type{name,description}}',
          ])
          .toPromise()
      )
        .map((costCode) => ({
          ...costCode,
          sub_cost_codes: costCode.sub_cost_codes.sort(sortByCode),
        }))
        .sort(sortByCode);

      if (this._data.moduleId) {
        this.costCodes = sortedCostCodes.reduce((acc, costCode) => {
          const matchingSubCostCodes = costCode.sub_cost_codes.filter((subCostCode) =>
            (subCostCode.module_ids as number[]).includes(this._data.moduleId)
          );

          if (matchingSubCostCodes.length) {
            return [...acc, { ...costCode, sub_cost_codes: matchingSubCostCodes }];
          } else {
            return acc;
          }
        }, [] as CostCode[]);
      } else {
        this.costCodes = sortedCostCodes;
      }
    })();
  }

  public cancel(): void {
    this._dialogRef.close();
  }

  public exportData(): void {
    try {
      // get the filtered cost codes
      const filteredCostCodes: CostCode[] = new FilterCostCodeBySearchTermPipe().transform(
        this.costCodes,
        this.searchTerm
      );
      // merge the parent cost codes with their child, by spreading them out
      const formatedCostCodes = [];
      for (const costCode of filteredCostCodes) {
        const subCostCode = costCode.sub_cost_codes.map((subCostCode: SubCostCode) => ({
          parent_cost_code: costCode.code,
          parent_label: costCode.label,
          sub_cost_code: subCostCode.code,
          sub_cost_code_label: subCostCode.label,
          sub_cost_code_description: subCostCode.description,
        }));
        formatedCostCodes.push(...subCostCode);
      }
      if (formatedCostCodes?.length) {
        const dataToReturn: string[] = [
          'Parent Cost Code, Parent Label, Sub Cost Code, Sub Cost Code Label, Sub Cost Code Description',
        ];

        for (const entry of formatedCostCodes) {
          dataToReturn.push(
            this._exportService.sanitizeItems([
              entry.parent_cost_code || '',
              entry.parent_label || '',
              entry.sub_cost_code || '',
              entry.sub_cost_code_label || '',
              entry.sub_cost_code_description || '',
            ])
          );
        }

        this._exportService.exportDataWithConfirmation(
          dataToReturn,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `cost_codes_export_${moment().format('MM/DD/YY')}.csv`,
          'Confirm Data Export',
          `${this.searchTerm ? 'Data export will use the current search term. ' : ''}Are you sure you wish to continue?`
        );
      }
    } catch (err) {
      this._snackBar.open(`Something went wrong while downloading cost codes`, `Close`);
    }
  }
}
