import { FileApprovalSelectType, FileApprovalStatus } from 'src/app/enums';
import { UhatFileReference, FileApprovalItem, Event } from 'src/app/types';

export class FileApproval {
  id?: number;
  parent_id?: number;
  parent_type_id?: number;
  status?: FileApprovalStatus;
  description?: string;
  files?: UhatFileReference[];
  events?: Event[];
  approval_items?: FileApprovalItem[];
  created_by_id?: number;
  created_by_first_name?: string;
  created_by_last_name?: string;
  created_by_email?: string;
  created_datetime?: string;
  modified_by_id?: number;
  modified_by_first_name?: string;
  modified_by_last_name?: string;
  modified_by_email?: string;
  modified_datetime?: string;

  num_files_to_select?: number;
  select_type?: FileApprovalSelectType;

  // go through the approval items for this approval, and find the first group awaiting approval
  // returns all approval items in this group
  static getNextApprovalItems(approval: FileApproval): FileApprovalItem[] {
    // if we even have approval items for this approval
    if (approval.approval_items) {
      // sort them by group_id (asc)
      const orderedItems = approval.approval_items.sort((a, b) => {
        if (a.group_id < b.group_id) {
          return -1;
        } else if (a.group_id > b.group_id) {
          return 1;
        }
        return 0;
      });
      // go through each item, and find the first group_id that is awaiting approval. Return all items in that group_id
      for (const item of orderedItems) {
        if (+item.status === FileApprovalStatus.AwaitingApproval) {
          return approval.approval_items.filter((approvalItem) => +approvalItem.group_id === +item.group_id);
        }
      }
    }
    // if we found nothing
    return null;
  }
}
