import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';

import { ProjectService } from 'src/app/services';

import { Trade } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-trade-select-modal',
  templateUrl: './trade-select-modal.component.html',
  styleUrls: ['./trade-select-modal.component.scss'],
})
export class TradeSelectModalComponent implements OnInit {
  selectedTrades: Trade[] = [];

  allTrades: Trade[] = [];

  searchInput: string;

  // I had to add this so the app would build -Austin
  processing = false;

  constructor(
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<TradeSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    const tradeFilters = [];
    const parameters = ['allow_bids', 'allow_companies'];
    for (const p of parameters) {
      if (this.data && (this.data[p] === true || this.data[p] === false)) {
        if (tradeFilters && tradeFilters.length > 0) {
          tradeFilters.push({ type: 'operator', value: 'AND' });
        }
        tradeFilters.push({ type: 'field', field: p, value: this.data[p] ? 1 : 0 });
      }
    }
    this.projectService.getTrades(tradeFilters).subscribe((result) => {
      this.allTrades = result;
    });
  }

  submit() {
    this.dialogRef.close(this.selectedTrades);
  }

  close() {
    this.dialogRef.close(false);
  }

  onSelection($event: MatSelectionListChange, selectedOptions: SelectionModel<MatListOption>) {
    this.selectedTrades = selectedOptions.selected.map((item) => item.value);
  }

  getTradeSelectionNumberText(): string {
    const num = this.selectedTrades.length || 0;
    if (num === 0) {
      return 'No Trade Selected';
    } else if (num === 1) {
      return this.selectedTrades[0].name + ' Selected';
    } else {
      return this.selectedTrades.length + ' Trades Selected';
    }
  }
}
