<main id="quick-request" data-test-id="quick-request" class="quick-request p-4">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      <div class="m-portlet bg-ltblue border-none mb-4" *ngIf="linkedBidPackage">
        <div class="m-portlet__body p-3 d-flex align-items-center text-truncate">
          <mat-icon class="white mr-3"> assignment_turned_in </mat-icon>
          <h5 class="white mr-auto mb-0 font-weight-semibold text-truncate">
            <span class="opacity9 mr-1"> PJ-{{ linkedBidPackage.project?.code }} | </span>
            <span class="opacity9 mr-1">
              {{ linkedBidPackage.project?.building?.code }}-{{ linkedBidPackage.project?.floor?.code }}
              |
            </span>
            <span class="white mr-1">
              {{ linkedBidPackage.project?.title }}
            </span>
          </h5>
          <a [routerLink]="'/projects/' + linkedBidPackage.project_id + '/bids'">
            <button mat-button class="white lh-26 px-2" type="button">
              <span class="mr-1"> Back to Project </span>
              <mat-icon> east </mat-icon>
            </button>
          </a>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 1 || step === 2 || step === 3" (opened)="setStep(1)">
          <mat-expansion-panel-header class="p-4">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <mat-icon class="mr-2">live_help</mat-icon>
                <h3 class="mb-0 mr-3">Topic</h3>
                <h6 *ngIf="selectedTopic" class="dkgray font-weight-normal mb-0 ml-auto">
                  <span *ngIf="selectedGroup">
                    {{ selectedGroup?.name }}
                  </span>
                  <span class="gray mx-2" *ngIf="selectedCategory"> > </span>
                  <span *ngIf="selectedCategory">
                    {{ selectedCategory?.name }}
                  </span>
                  <span class="gray mx-2" *ngIf="selectedTopic"> > </span>
                  <span *ngIf="selectedTopic">
                    {{ selectedTopic?.name }}
                  </span>
                </h6>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="step === 1">
            <mat-form-field appearance="outline" class="full-width">
              <mat-icon class="dkblue" matPrefix>search</mat-icon>
              <input
                type="text"
                class="text-center"
                placeholder="Search 1Call categories and topics here..."
                matInput
                [matAutocomplete]="request_auto"
                [(ngModel)]="topicSearchTerm"
                (ngModelChange)="filterTopics()"
              />
              <mat-autocomplete
                class="tall-autocomplete"
                autoActiveFirstOption
                #request_auto="matAutocomplete"
                (optionSelected)="selectTopic($event.option.value); setStep(4)"
              >
                <div *ngFor="let topic of filteredTopics; index as i">
                  <mat-option
                    disabled
                    class="font-weight-bold"
                    *ngIf="
                      i === 0 ||
                      filteredTopics[i - 1]?.topic_category?.topic_group?.name !==
                        topic?.topic_category?.topic_group?.name
                    "
                  >
                    {{ topic?.topic_category?.topic_group?.name }}
                  </mat-option>
                  <mat-option [disabled]="topic.requiresAR && !isAR && !isStaff" class="pl-5" [value]="topic">
                    <span class="d-flex align-items-center">
                      <span class="font-weight-normal">
                        {{ topic?.topic_category?.name }}
                      </span>
                      <mat-icon class="m-0 mr-1 ml-1 gray option-icon">chevron_right</mat-icon>
                      <span class="font-weight-semibold">
                        {{ topic.name }}
                      </span>
                      <span *ngIf="topic.requiresAR" class="ar-item d-flex align-items-center ml-auto">
                        <mat-icon
                          id="option-verified"
                          class="m-0 gray opacity7"
                          matTooltip="Requires Authorized Requestor"
                          matTooltipPosition="above"
                          [matTooltipShowDelay]="300"
                          >verified_user</mat-icon
                        >
                      </span>
                    </span>
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>

            <h5 class="text-center dkblue mb-4">Or select a Topic Group</h5>

            <div class="row mb-4">
              <div class="col-md-4 col-sm-6 mb-3" *ngFor="let group of allTopicGroups; let i = index">
                <button
                  [disabled]="group.requiresAR && !isAR && !isStaff"
                  (click)="selectGroup(group); setStep(2)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="full-width"
                  [ngClass]="{
                    selected: group.id === selectedGroup?.id
                  }"
                >
                  {{ group.name }}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="step === 2">
            <h5 class="text-center dkblue mb-5">
              <span *ngIf="selectedGroup" (click)="setStep(1)" class="ltblue breadcrum-item hover-u pointer">
                {{ selectedGroup.name }}
              </span>
              <span *ngIf="selectedGroup" class="gray mx-3"> > </span>
              Select a Topic Category
            </h5>

            <div class="row mb-4">
              <div class="col-md-4 col-sm-6 mb-3" *ngFor="let category of filteredTopicCategories; let i = index">
                <button
                  [disabled]="category.requiresAR && !isAR && !isStaff"
                  (click)="selectCategory(category); setStep(3)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="full-width"
                  [ngClass]="{
                    selected: category.id === selectedCategory?.id
                  }"
                >
                  {{ category.name }}
                </button>
              </div>
            </div>

            <!-- <div class="options-container mt-4 mb-4">
                <div class="m-portlet mb-0" *ngFor="let category of filteredTopicCategories; let i = index" [ngClass]="{
                        selected: category.id === selectedCategory?.id,
                        opacity4: category.requiresAR && !isAR && !isStaff,
                        'no-border': i < 2,
                        'no-border-right': i % 2 !== 0
                      }">
                  <div (click)="selectCategory(category)" matRipple class="m-portlet__body item item-last pointer px-1 py-3 align-items-center">
                    <h5 class="m-0 dkblue font-weight-normal">
                      {{ category.name }}
                    </h5>
                  </div>
                </div>
              </div> -->
          </div>

          <div *ngIf="step === 3">
            <h5 class="text-center dkblue mb-5">
              <span *ngIf="selectedGroup" (click)="setStep(1)" class="ltblue breadcrum-item hover-u pointer">
                {{ selectedGroup.name }}
              </span>
              <span *ngIf="selectedGroup" class="gray mx-3"> > </span>
              <span *ngIf="selectedCategory" (click)="setStep(2)" class="ltblue breadcrum-item hover-u pointer ml-3">
                {{ selectedCategory.name }}
              </span>
              <span *ngIf="selectedCategory" class="gray mx-3"> > </span>
              Select a Topic
            </h5>

            <div class="row mb-4">
              <div class="col-md-4 col-sm-6 mb-3" *ngFor="let topic of filteredTopics; let i = index">
                <button
                  [disabled]="topic.requiresAR && !isAR && !isStaff"
                  (click)="selectTopic(topic); setStep(4)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="full-width"
                  [ngClass]="{
                    selected: topic.id === selectedTopic?.id
                  }"
                >
                  {{ topic.name }}
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
          [expanded]="step === 4 || step === 5 || step === 6"
          (opened)="setStep(4)"
          style="margin-top: -1px"
        >
          <mat-expansion-panel-header class="p-4">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <mat-icon class="mr-2">add_location_alt</mat-icon>
                <h3 class="mb-0">Location</h3>
                <h6 *ngIf="floor" class="dkgray font-weight-normal mb-0 ml-auto">
                  <span *ngIf="building">
                    {{ building?.name }}
                  </span>
                  <span class="gray mx-2" *ngIf="floor"> > </span>
                  <span *ngIf="floor">
                    {{ floor?.code }}
                  </span>
                  <span class="gray mx-2" *ngIf="suite"> > </span>
                  <span *ngIf="suite">
                    {{ suite?.name }}
                  </span>
                  <span class="gray" *ngIf="department"> > </span>
                  <span *ngIf="department">
                    {{ department?.name }}
                  </span>
                </h6>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="step === 4">
            <mat-form-field appearance="outline" class="full-width">
              <mat-icon class="dkblue" matPrefix>search</mat-icon>
              <input
                type="text"
                class="text-center"
                placeholder="Search Departments"
                matInput
                [matAutocomplete]="department_auto"
                [(ngModel)]="departmentSearchTerm"
                (ngModelChange)="filterDepartments()"
              />
              <mat-autocomplete
                class="tall-autocomplete"
                autoActiveFirstOption
                #department_auto="matAutocomplete"
                (optionSelected)="selectDepartment($event.option.value); setStep(7)"
              >
                <div *ngFor="let department of filteredDepartments; index as i">
                  <mat-option
                    disabled
                    class="font-weight-bold"
                    *ngIf="i === 0 || filteredDepartments[i - 1]?.building_id !== department?.building_id"
                  >
                    {{ department?.building_name }}
                  </mat-option>
                  <mat-option class="pl-5" [value]="department">
                    <span class="d-flex align-items-center">
                      <span class="font-weight-normal" *ngIf="department.floor_code">
                        {{ department.floor_code }}
                      </span>
                      <mat-icon class="m-0 mr-1 ml-1 gray option-icon" *ngIf="department.floor_code"
                        >chevron_right</mat-icon
                      >
                      <span class="font-weight-normal" *ngIf="department.suite_id">
                        {{ department.suite_name }}
                      </span>
                      <mat-icon class="m-0 mr-1 ml-1 gray option-icon" *ngIf="department.suite_id"
                        >chevron_right</mat-icon
                      >
                      <span class="font-weight-semibold">
                        {{ department.name }}
                      </span>
                    </span>
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>

            <h5 class="text-center dkblue mb-4">Or select a Building</h5>

            <div class="row mb-4">
              <div class="col-md-3 col-sm-4 col-xs-6 mb-3" *ngFor="let b of buildings; let i = index">
                <button
                  (click)="selectBuilding(b); setStep(5)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="py-0 full-width"
                  matTooltip="{{ b.name }}"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  [ngClass]="{
                    selected: b.id === building?.id
                  }"
                >
                  {{ b.code }}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="step === 5">
            <h5 class="text-center dkblue mb-5">
              <span *ngIf="building" (click)="setStep(4)" class="ltblue breadcrum-item hover-u pointer">
                {{ building.name }}
              </span>
              <span *ngIf="building" class="gray mx-3"> > </span>
              Select a Floor
            </h5>

            <div class="row mb-4">
              <div class="col-md-4 col-sm-6 mb-3" *ngFor="let f of floors; let i = index">
                <button
                  (click)="selectFloor(f); setStep(6)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="full-width"
                  [ngClass]="{
                    selected: f.id === floor?.id
                  }"
                >
                  {{ f.name }}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="step === 6">
            <h5 class="text-center dkblue mb-5">
              <span *ngIf="building" (click)="setStep(4)" class="ltblue breadcrum-item hover-u pointer">
                {{ building.name }}
              </span>
              <span *ngIf="building" class="gray mx-3"> > </span>
              <span *ngIf="floor" (click)="setStep(5)" class="ltblue breadcrum-item hover-u pointer">
                {{ floor.code }}
              </span>
              <span *ngIf="floor" class="gray mx-3"> > </span>
              Select a Department
            </h5>

            <div class="row mb-4">
              <div *ngIf="departments.length === 0" class="col text-center">
                <h4 class="m-0 primary font-weight-normal mt-4 mb-4">
                  Oops. We didn't find any departments on that floor.
                </h4>
                <button mat-button (click)="setStep(5)" type="button" class="mb-4 mx-2" color="primary">
                  Select a Different Floor
                </button>
                <button mat-stroked-button (click)="setStep(7)" type="button" class="mb-4 mx-2" color="primary">
                  Continue to Additional Details
                </button>
              </div>
              <div class="col-12 mb-3" *ngFor="let d of departments">
                <button
                  (click)="selectDepartment(d); setStep(7)"
                  matRipple
                  mat-stroked-button
                  color="primary"
                  class="full-width"
                >
                  {{ d.suite_name }} - {{ d.name }}
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" style="margin-top: -1px">
          <mat-expansion-panel-header class="p-4">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <mat-icon class="mr-2">playlist_add_check</mat-icon>
                <h3 class="mb-0">Additional Details</h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <h6 class="font-weight-semibold mb-2">Short Description *</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput placeholder="Short Description" [(ngModel)]="shortDescription" />
          </mat-form-field>

          <h6 class="font-weight-semibold mb-2">Comments *</h6>
          <mat-form-field class="full-width" appearance="outline">
            <span class="textarea">
              <textarea matInput rows="5" placeholder="Comments" [(ngModel)]="comments"></textarea>
            </span>
          </mat-form-field>

          <h6 class="dkblue font-weight-semibold mb-3">Attachments</h6>
          <div class="d-flex">
            <mat-chip-list class="d-inline-block align-middle mb-5">
              <button mat-stroked-button color="accent" class="mr-3" (click)="openUploadModal()">Upload Files</button>
              <app-file-chip
                *ngFor="let file of attachedFiles"
                [file]="file"
                [removable]="true"
                (removeEvent)="removeAttachedFile(file)"
              ></app-file-chip>
            </mat-chip-list>
          </div>

          <h6 class="dkblue font-weight-semibold mb-2">Followers</h6>

          <div class="d-flex align-items-center mb-4">
            <app-profile-thumbnail
              *ngIf="currentUser"
              class="mr-3"
              [noBorder]="true"
              [height]="40"
              [width]="40"
              [userId]="currentUser?.id"
              matTooltip="{{ currentUser?.first_name }} {{ currentUser?.last_name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              [overlayIconOpacity]="1"
              [overlayIcon]="'grade'"
              [overlayIconColor]="'white'"
              [overlayIconBackground]="'bg-ltblue'"
              [overlayIconTooltip]="'Requester (You)'"
            >
            </app-profile-thumbnail>
            <app-profile-thumbnail
              *ngFor="let c of contacts"
              class="mr-3"
              [noBorder]="true"
              [height]="40"
              [width]="40"
              [userId]="c?.id"
              matTooltip="{{ c?.first_name }} {{ c?.last_name }}"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              [overlayIcon]="'close'"
              [overlayIconColor]="'white'"
              [overlayIconBackground]="'bg-gray'"
              [overlayIconTooltip]="'Remove Follower'"
              (overlayIconClickEvent)="removeContact(c)"
            ></app-profile-thumbnail>
            <button mat-mini-fab color="success" (click)="openBulkSelectModal()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <button
            [disabled]="!isRequestValid"
            mat-flat-button
            color="success"
            class="full-width mt-4 mb-2"
            (click)="submitRequest()"
          >
            Submit {{ selectedTopic?.topic_type_id === 2 ? 'Work Order' : 'Project Request' }}
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</main>
