<app-base-dialog [title]="action + ' Floor'" (closeDialog)="cancel()"> </app-base-dialog>
<section class="floor-modal">
  <form [formGroup]="floorFormGroup">
    <h6 class="primary mb-2">Floor Name</h6>
    <mat-form-field class="full-width mb-4" appearance="outline">
      <input matInput placeholder="e.g. Lower Level or 1" required="true" type="text" formControlName="name" />
      <mat-error *ngIf="name?.errors?.required">Name is required</mat-error>
    </mat-form-field>
    <h6 class="primary mb-2">Floor Abbreviation</h6>
    <mat-form-field class="full-width mb-4" appearance="outline">
      <input matInput required="true" placeholder="e.g. LL or 01" type="text" formControlName="code" maxlength="2" />
      <mat-error *ngIf="code?.errors?.required">Code is required</mat-error>
      <mat-hint> Must be 2 Characters </mat-hint>
    </mat-form-field>
    <div class="row mt-3">
      <div class="col">
        <button mat-button class="full-width gray" type="button" (click)="cancel()">Cancel</button>
      </div>
      <div class="col">
        <button
          mat-flat-button
          class="full-width"
          type="submit"
          color="success"
          (click)="save()"
          [disabled]="!floorFormGroup.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</section>
