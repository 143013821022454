<app-base-dialog [title]="title + ' Bid Package'" (closeDialog)="cancel()"></app-base-dialog>
<div class="bid-package-dialog" style="min-width: 380px">
  <form [formGroup]="bidPackageFormGroup">
    <div class="bidPackageDialogForm" mat-dialog-content>
      <div class="d-flex flex-column mb-4">
        <h6 class="primary mb-2">BP Trade</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select matNativeControl placeholder="Select Trade" required="true" formControlName="trade_id">
            <mat-option *ngFor="let t of trades" [value]="t.id">{{ t.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="trade_id.errors?.required">Trade is required</mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column mb-4">
        <h6 class="primary mb-2">BP Number</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" required="true" placeholder="BP Number" formControlName="number" />
          <mat-error *ngIf="number.errors?.required">Bid Package Number is required</mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column mb-4">
        <h6 class="primary mb-2">BP Square Footage</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input
            matInput
            type="number"
            value="0"
            placeholder="BP Square Footage"
            formControlName="square_footage"
            (blur)="blurDecimalInput('square_footage')"
          />
          <mat-error *ngIf="square_footage.errors?.required">Square Footage is required</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="row">
        <div class="col-12 bidPackageDialogForm">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Estimated Cost/Sqft</mat-label>
            <span class="gray" matPrefix>$&nbsp;</span>
            <input matInput type="number" formControlName="cost_per_sqft" (blur)="blurUSDollarInput('cost_per_sqft')" />
            <mat-error *ngIf="cost_per_sqft.errors?.required">Estimated Cost/Sqft is required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-12 bidPackageDialogForm">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Budget Amount</mat-label>
            <span class="gray" matPrefix>$&nbsp;</span>
            <input matInput type="number" formControlName="budget_amount" (blur)="blurUSDollarInput('budget_amount')" />
            <mat-error *ngIf="budget_amount.errors?.required">Budget Amount is required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
    </div>
    <div class="row m-0">
      <div class="col pl-0">
        <button mat-button type="button" class="full-width gray" color="accent" (click)="cancel()">Cancel</button>
      </div>
      <div class="col pr-0">
        <button
          mat-flat-button
          type="submit"
          color="success"
          class="full-width"
          (click)="submit()"
          [disabled]="!bidPackageFormGroup.valid"
        >
          Save Bid Package
        </button>
      </div>
    </div>
  </form>
</div>

<style>
  ::ng-deep .bidPackageDialogForm .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0rem;
  }
</style>
