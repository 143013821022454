import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { EditorComponent } from 'src/app/components/editor/editor.component';
import { ResourceType } from 'src/app/enums';
import { HtmlEncodeDecodePipe } from 'src/app/pipes';
import { FileService, MeetingService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { AgendaItem, Note, UhatFileReference } from 'src/app/types';
import { checkNameOfTheFile, renameFile } from 'src/app/utils';

@Component({
  selector: 'app-meeting-agenda-item-note',
  templateUrl: './meeting-agenda-item-note.component.html',
  styleUrls: ['./meeting-agenda-item-note.component.scss'],
})
export class MeetingAgendaItemNoteComponent implements OnInit, AfterViewInit {
  @Input() agendaItem: AgendaItem;
  @Input() existingAgendaItemFiles: Set<string> = new Set();
  @Input() editNote = false;
  @Input() isConcluded;
  @Input() newNote = false;
  @Input() note: Note;
  @Output() refreshMeeting = new EventEmitter();
  @Output() resetEditNote = new EventEmitter();
  @Output() resetNewNote = new EventEmitter();
  @ViewChild('newConversationUploader') newConversationUploader: ElementRef<HTMLElement>;
  @ViewChild('agendaNoteEditor', { static: false }) set editorRef(ref: EditorComponent) {
    if (!!ref) {
      this._editorComponent = ref;
      // SetTimeout needed to prevent the ExpressionChanged error. This focuses the textbox when it is shown
      setTimeout(() => {
        ref.content?.valueChanges?.subscribe((val) => {
          this.draftNote = val;
        });
      });
    }
  }
  // using this for the edit editor
  @ViewChildren(EditorComponent)
  private _agenda_item_note_editors_components!: QueryList<EditorComponent>;

  private _editorComponent: EditorComponent;

  private _existingNoteFilesToRemove: UhatFileReference[] = [];
  private _existingNoteFiles: UhatFileReference[] = [];
  private _htmlEncodeDecodePipe = new HtmlEncodeDecodePipe();
  private _newNoteFiles: UhatFileReference[] = [];

  draftNote = null;

  constructor(
    private progressIndicator: ProgressIndicatorService,
    private meetingService: MeetingService,
    private modalService: ModalService,
    private fileService: FileService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.editNote && this.note?.files?.length) {
      this._existingNoteFiles = this.note.files;
    }
  }

  ngAfterViewInit() {
    if (this.editNote) {
      this.editContent.setValue(this._htmlEncodeDecodePipe.transform(this.note.message, false));
    }
  }

  get fileChanges(): boolean {
    return !isEqual(this._newNoteFiles, this._existingNoteFiles);
  }

  public get files(): UhatFileReference[] {
    return [...(this._newNoteFiles ?? []), ...(this._existingNoteFiles ?? [])];
  }

  public get editContent(): AbstractControl {
    return this._agenda_item_note_editors_components?.last?.content;
  }

  private async _addFiles(noteId: number): Promise<UhatFileReference[]> {
    const addedFiles: UhatFileReference[] = [];

    for (const f of this._newNoteFiles) {
      // need to surround in try/catch, as we can't use the uploadModal here, so we risk running into duplicate names
      try {
        let fileToSave = f;
        // rename duplicates if they exist on the other agenda item notes
        const newFileName = checkNameOfTheFile(f.name, this.existingAgendaItemFiles);
        // if the names are different, rename then file
        if (newFileName !== f.name) {
          fileToSave = renameFile(f as File, newFileName);
        }
        // add it to the existing file list, if is exists, it will be ignored
        this.existingAgendaItemFiles.add(newFileName);

        // create the file by linking to parent (as files can't be directly created with note as parent, since note has no folder_path)
        const newFile = await this.fileService
          .createFile(fileToSave, this.agendaItem.id, ResourceType.Agenda)
          .toPromise();
        addedFiles.push(newFile);
        // also link the file to the note
        this.fileService.linkFile(newFile.id, noteId, ResourceType.Note).subscribe();
      } catch (e) {
        this.snackbar.open(
          'A file failed to upload, as there is another file with a duplicate name already uploaded to this agenda item!',
          'Accept',
          { duration: 5000 }
        );
      }
    }
    return addedFiles;
  }

  private async _removeFiles(noteId: number) {
    for (const f of this._existingNoteFilesToRemove) {
      await this.fileService
        .getBridgeFile(f.id, noteId, ResourceType.Note)
        .toPromise()
        .then(async (bridge) => {
          for (const file of bridge) {
            await this.fileService.unlinkFile(file.id).toPromise();
          }
        });
      await this.fileService
        .getBridgeFile(f.id, this.agendaItem.parent_id, ResourceType.Agenda)
        .toPromise()
        .then(async (bridge) => {
          for (const file of bridge) {
            await this.fileService.unlinkFile(file.id).toPromise();
          }
        });
      this.fileService.deleteFileWithoutConfirmation(f).subscribe();
    }
  }

  public async addNoteToAgendaItem() {
    if (this.draftNote || this._newNoteFiles?.length) {
      // if the text is null set to default
      if (!this.draftNote) {
        this.draftNote = `Attached ${this._newNoteFiles.length} file(s)`;
      } else {
        this.draftNote = this._htmlEncodeDecodePipe.transform(this.draftNote, true);
      }

      const noteToAdd = {
        parent_type_id: ResourceType.Agenda,
        parent_id: this.agendaItem.id,
        message: this.draftNote,
      };

      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Adding Note..');
      const createdNote = await this.meetingService.addAgendaItemNote(noteToAdd).toPromise();
      const addedFiles = await this._addFiles(createdNote.id);
      createdNote.files = addedFiles;
      this._newNoteFiles = [];
      this.refreshMeeting.emit();
    }
  }

  public async openUploadModal() {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
      currentAttachedFiles: this._newNoteFiles,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this._newNoteFiles = [...this._newNoteFiles, ...files.computerFiles];
    }
  }

  public removeNoteFile(file: UhatFileReference) {
    if (this.newNote) {
      this._newNoteFiles = this._newNoteFiles.filter((newNoteile: UhatFileReference) => newNoteile !== file);
    } else {
      const existingNoteFilesIds = this._existingNoteFilesToRemove.map((existingNoteFile) => existingNoteFile.id);
      if (file.id && !existingNoteFilesIds.includes(file.id)) {
        this._existingNoteFilesToRemove.push(file);
        this._existingNoteFiles = this._existingNoteFiles.filter(
          (existingNoteFile: UhatFileReference) => existingNoteFile !== file
        );
      } else {
        this._newNoteFiles = this._newNoteFiles.filter((newNoteile: UhatFileReference) => newNoteile !== file);
      }
    }
  }

  public downloadNewNoteFile(file: File) {
    saveAs(new Blob([file]), file.name);
  }

  public openFileDialog() {
    const el: HTMLElement = this.newConversationUploader.nativeElement;
    el.click();
  }

  public cancel() {
    this._newNoteFiles = [];
    if (this.newNote) {
      this._editorComponent?.content?.reset();
      this.resetNewNote.emit();
    } else {
      this._agenda_item_note_editors_components?.last?.content?.reset();
      this.resetEditNote.emit();
    }
  }

  public async editAgendaItemNote() {
    if (this.note?.id) {
      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Saving the edits...');
      await this.meetingService
        .updateAgendaNote(this.note.id, {
          message: this._htmlEncodeDecodePipe.transform(this.editContent?.value ?? '', true),
        })
        .toPromise();
      if (this._existingNoteFilesToRemove.length) {
        await this._removeFiles(this.note.id);
      }

      if (this._newNoteFiles.length) {
        const addedFiles = await this._addFiles(this.note.id);
        this.note.files = addedFiles;
        this._newNoteFiles = [];
      }

      this.progressIndicator.close();
      this.refreshMeeting.emit();
    }
  }
}
