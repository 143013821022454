import { NgModule } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';
import { SignageRoutingModule } from './signage-routing.module';
import { SignageComponent } from './signage.component';

@NgModule({
  declarations: [SignageComponent],
  imports: [SignageRoutingModule, SharedModule],
  exports: [SignageComponent],
})
export class SignageModule {}
