import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aidet',
  templateUrl: './aidet.component.html',
  styleUrls: ['./aidet.component.scss'],
})
export class AidetComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
