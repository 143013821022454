import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { cloneDeep, orderBy } from 'lodash';

import { AuthService, ProgressIndicatorService, RemindersService, UserService } from 'src/app/services';

import { ReminderDialogComponent } from 'src/app/components';

import { Reminder } from 'src/app/types';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
})
export class RemindersComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private remindersService: RemindersService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    private userService: UserService
  ) {}

  reminders = {};
  reminderPriorities: any[];
  openReminder: Reminder = {};
  notesCursor;
  shownNotes;
  noteToPost;
  sortProperty;
  sortDirection;
  currentUser;
  // reminderStatuses: any[];
  reminderStatuses = [];
  selectedReminderStatus: any;
  searchTerm;

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;

  ngOnInit() {
    setTimeout(() => {
      this.getDivWidth();
      this.sortProperty = 'created_datetime';
      this.sortDirection = 'desc';
      this.remindersService.getReminderStatuses().subscribe((reminderStatuses) => {
        this.reminderStatuses = reminderStatuses;
      });
      this.selectedReminderStatus = 1;
      this.currentUser = this.authService.getLoggedInUser();
      this.refresh();
    });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Reminders..');
    await this.remindersService
      .getMyReminders(this.currentUser.id, this.sortProperty, this.sortDirection)
      .toPromise()
      .then((reminders) => {
        const newReminders = {};
        reminders.forEach((r) => {
          if (!newReminders[r.status_id]) {
            newReminders[r.status_id] = [];
          }
          newReminders[r.status_id].push(r);
        });
        this.reminders = newReminders;
      });

    await this.remindersService
      .getReminderPriorities()
      .toPromise()
      .then((reminderPriorities) => {
        this.reminderPriorities = reminderPriorities;
      });

    if (this.openReminder && this.openReminder.id) {
      await this.remindersService
        .getNotesByReminderId(this.openReminder.id, this.notesCursor)
        .toPromise()
        .then((result: any) => {
          this.shownNotes = result.notes;
          this.notesCursor = result.cursor;
        });
    }
    await this.remindersService.updateIncompleteReminderCount();
    this.progressIndicatorService.close();
  }

  toggleReminder(reminder: Reminder) {
    if (!reminder || reminder.id === this.openReminder.id) {
      this.openReminder = {};
    } else {
      this.noteToPost = null;
      this.openReminder = reminder;
      this.shownNotes = null;
      this.remindersService.getNotesByReminderId(reminder.id).subscribe((result: any) => {
        this.shownNotes = result.notes;
        this.notesCursor = result.cursor;
      });
    }
  }

  changeReminderStatus(reminderId, reminderStatusId) {
    this.remindersService.updateReminder({ id: reminderId, status_id: reminderStatusId }).subscribe((reminder) => {
      this.refresh();
    });
  }

  changeReminderPriority(reminderId, reminderPriorityId) {
    this.remindersService.updateReminder({ id: reminderId, priority_id: reminderPriorityId }).subscribe((reminder) => {
      this.refresh();
    });
  }

  async deactivateReminder(reminderId) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Deleting Reminder..');
    await this.remindersService
      .deactivateReminder(reminderId)
      .toPromise()
      .then(() => {
        this.refresh();
        this.snackbar.open('Reminder removed!');
      });
  }

  openAddReminderDialog() {
    const dialogRef = this.dialog.open(ReminderDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Reminder..');
        this.remindersService.createReminder(result).subscribe((reminder) => {
          this.refresh();
        });
      }
    });
  }

  openEditReminderDialog(reminder) {
    const editedReminder = cloneDeep(reminder);
    const dialogRef = this.dialog.open(ReminderDialogComponent, {
      data: editedReminder,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating Reminder..');
        this.remindersService.updateReminder(result).subscribe((updatedReminder) => {
          this.refresh();
        });
      }
    });
  }

  createNoteOnReminder(message, reminderId) {
    const noteToCreate = {
      parent_id: reminderId,
      message,
    };
    this.remindersService.createNoteOnReminder(noteToCreate).subscribe((note) => {
      this.noteToPost = null;
      this.notesCursor = null;
      this.refresh();
    });
  }

  sortReminders(reminderStatus, property) {
    if (this.sortProperty === property) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortDirection = 'asc';
      this.sortProperty = property;
    }

    this.refresh();
    // todo: make this happen in the API first
    this.reminders[reminderStatus.id] = orderBy(
      this.reminders[reminderStatus.id],
      [this.sortProperty],
      [this.sortDirection]
    );
  }

  getParentLink(reminder) {
    if (reminder.parent_type_id === 7) {
      return `/tasks/${reminder.parent_id}`;
    } else if (reminder.parent_type_id === 14) {
      return `/inbox/${reminder.parent_id}`;
    }
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }
}
