import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { orderBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { ConfirmationDialogComponent, ViewTaskDialogComponent } from 'src/app/components';
import {
  ApplicationRole,
  BidPackageStatus,
  FileAction,
  InvoiceStatus,
  InvoiceType,
  ProjectStatus,
  TaskReviewStatus,
  TaskStatus,
  Workspace,
} from 'src/app/enums';
import {
  AuthService,
  FileService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
} from 'src/app/services';
import { Invoice, Project, Quote } from 'src/app/types';
import { ProjectTenantPEBStatus } from 'src/app/workspaces/construction/enums';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import { BidPackage, ProjectConstruction, ProjectTenantConstruction } from 'src/app/workspaces/construction/types';

export interface InvoiceBidPackage extends BidPackage {
  expanded?: boolean;
  hasNewInvoice?: boolean;
  invoiceCount?: number;
  filteredInvoiceCount?: number;
  label?: string;
  total_contract_sum?: number;
  billed?: number;
  retainage?: number;
  invoiceTypes?: any[];
  status_id?: BidPackageStatus;
  project_id?: number;
}
export interface InvoiceQuote extends Quote {
  expanded?: boolean;
  hasNewInvoice?: boolean;
  invoiceCount?: number;
  filteredInvoiceCount?: number;
  label?: string;
  total_contract_sum?: number;
  billed?: number;
  retainage?: number;
  invoiceTypes?: any[];
  child_request?: any;
  child_project?: any;
  status_id?: BidPackageStatus;
}

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  constructor(
    private projectService: ProjectService,
    private productService: ProductService,
    public authService: AuthService,
    public modalService: ModalService,
    private fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private pebService: PEBService,
    private projectTenantService: ProjectTenantService,
    private taskService: ProjectTaskService,
    private updateReviewersService: UpdateReviewerService
  ) {}

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;

  private invoiceFields = [
    // confirmed fields
    'id',
    'bid_package_id',
    'quote_id',
    'quote{arf_purchase_type_id,contact_id,description}',
    'project_id',
    'status_id',
    'is_retainage',
    'title',
    'number',
    'received_date',
    'total',
    'retainage',
    'shipping',
    'tax',
    'approval_task{accessory_data}',
    'approval_task_id',
    'approval_task_accessory_data',
    'files',
    'created_by_id',
    'review_user_id',
    'review_user_first_name',
    'review_user_last_name',
    'review_comment',
    'change_order_id',
    'change_order_local_index',
    'arf_invoice_amounts{sub_cost_code_budget{code,label,cost_code{label,code},sub_cost_code{fiscal_year}},amount}',

    // need to try to remove some of the fields below to speed up the API call
    // many fields are used in further processes, so lots of testing is required
    'status_name',
    'invoice_date',
    'invoice_end_date',
    'approval_task_status_id',
    'created_datetime',
    'revision',
    'trade_name',
    'trade_allows_nonbid_invoices',
    'company_id',
    'company_name',
    'bid_contact_id',
    'bid_contact_first_name',
    'bid_contact_last_name',
    'bid_contact_email',
    'bid_contact_office_phone',
    'bid_contact_cell_phone',
    'change_order_code',
    'is_internally_funded',
    'is_contingency_funded',
    'is_gencon_funded',
    'processed_by{first_name,last_name,title}',
    'processed_by_id',
    'processed_datetime',
    'tenant_id',
    'tenant_name',
    'timeframe_id',
    'timeframe_name',
    'trade_name',
    'fiscal_year',
  ];
  private bidPackageFields: string[] = [
    'id',
    'number',
    'status_id',
    'trade_name',
    'awarded_amount',
    'awarded_company_id',
    'awarded_company_name',
    'awarded_contact_id',
    'trade_allows_nonbid_invoices',
    'approved_change_order_total',
    'child_request{id,code,short_description}',
    'child_project{id,module_id,code,title,budget_data}',
    'close_reason',
    'project_id',
  ];
  private quoteFields: string[] = [
    'company{name}',
    'awarded_amount',
    'code',
    'contact_id',
    'arf_purchase_type_id',
    'tenant{id,tenant_name}',
    'description',
  ];
  private projectFields = [
    'id',
    'code',
    'title',
    'module',
    'module_id',
    'building_code',
    'floor_code',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'workspace_manager_id',
    'cfmo_id',
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'square_footage',
    'end_date',
    'status_id',
  ];

  project: ProjectConstruction | Project;
  currentWorkspace: Workspace;
  public taskSelectedRefresh: any;
  public projectSelectedRefresh: any;
  bidPackages: InvoiceBidPackage[];
  quotes: InvoiceQuote[];

  invoices: Invoice[];
  searchString;
  BidPackageStatus = BidPackageStatus;

  invoiceStatusInfo = [
    {
      count: 0,
      name: 'New',
      statusIds: [InvoiceStatus.New],
      isSelected: false,
    },
    {
      count: 0,
      name: 'Unpaid',
      statusIds: [InvoiceStatus.Received, InvoiceStatus.Approved],
      isSelected: false,
    },
    {
      count: 0,
      name: 'Paid',
      statusIds: [InvoiceStatus.ReadyForPayment],
      isSelected: false,
    },
    {
      count: 0,
      name: 'Rejected',
      statusIds: [InvoiceStatus.Rejected],
      isSelected: false,
    },
    {
      count: 0,
      name: 'All',
      statusIds: [],
      isSelected: true,
    },
  ];

  allPEBsAreFinalized: boolean;

  awardedParents: InvoiceBidPackage[] | InvoiceQuote[] = [];
  filteredParents: InvoiceBidPackage[] | InvoiceQuote[] = [];
  allParentsOption = { id: 0, label: `All Trades`, awarded_company_name: null };
  expandedParentIds: number[];
  selectedParent: InvoiceBidPackage | InvoiceQuote = this.allParentsOption;

  selectedInvoiceStatus = this.invoiceStatusInfo[this.invoiceStatusInfo.length - 1];
  selectedInvoiceStatuses: any = [this.invoiceStatusInfo[this.invoiceStatusInfo.length - 1]];
  filteredStatusIds: number[] = [];
  exportingInvoice: Invoice;

  public sortByField: string;
  public sortDirection: 'asc' | 'desc';

  public totals = [
    { title: 'Total Contract Sum', type: 'total-contract-sum' },
    { title: 'Retainage WH', type: 'retainage-wh' },
    { title: 'Billed To Date', type: 'billed-to-date' },
    { title: 'Balance to Finish', type: 'balance-to-finish' },
  ];

  async ngOnInit() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.expandedParentIds = (preferences && preferences.expanded_trades) || [];
    this.sortByField = (preferences && preferences.sort_invoice_by_field) || 'number';
    this.sortDirection = (preferences && preferences.sort_invoice_order) || 'asc';

    if (preferences?.invoice_statuses?.length) {
      this.selectedInvoiceStatuses = [];
      for (const s of preferences?.invoice_statuses) {
        this.selectedInvoiceStatuses.push(this.invoiceStatusInfo[s]);
        this.filteredStatusIds = [...this.filteredStatusIds, ...this.invoiceStatusInfo[s]?.statusIds];
      }

      this.invoiceStatusInfo.forEach((status) => {
        status.isSelected = !!this.selectedInvoiceStatuses.find((s) => s.name === status.name);
      });
    }

    if (this.currentWorkspace !== this.Workspace.Construction) {
      this.allParentsOption.label = 'All Suppliers';
    }

    this.getDivWidth();
    await this.refresh();

    // Subscribes to review changes
    // TODO: check this
    this.taskSelectedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      const accessoryData = (data?.task?.accessory_data && JSON.parse(data.task.accessory_data)) || null;

      const parentId = accessoryData?.parentId || accessoryData?.id;
      if (parentId) {
        if (this.invoices?.find((i) => i.id === parentId)) {
          await this.refresh();
        }
      }
    });

    // get a fresh copy if the project has changed
    this.projectSelectedRefresh = this.projectService.projectSelectedEvent.subscribe((p) => {
      if (p?.status_id !== this.project?.status_id) {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      this.taskSelectedRefresh.unsubscribe();
      this.projectSelectedRefresh.unsubscribe();
    } catch (e) {}
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Invoices..');
    // Switch back to this.projectService.currentSelectedProjectId
    this.project = this.projectService.currentSelectedProject;

    this.currentWorkspace = this.project.module_id;

    const invoices: Invoice[] = await this.projectService
      .getInvoices(
        [
          {
            type: 'field',
            field: 'project_id',
            value: this.projectService.currentSelectedProjectId.toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_request_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'bid_package_child_project_id', value: null },
        ],
        this.invoiceFields
      )
      .toPromise();

    this.invoices = invoices;

    if (this.currentWorkspace === Workspace.Construction) {
      this.bidPackages = await this.projectService
        .getBidPackages(
          [
            {
              type: 'field',
              field: 'project_id',
              value: this.projectService.currentSelectedProjectId.toString(),
            },
          ],
          this.bidPackageFields
        )
        .toPromise();

      this.bidPackageArray();
    } else {
      await this.quoteArray();
    }
    this.filterInvoices();

    await this.getPebStatus();
    this.getInvoiceCounts();
    this.progressIndicatorService.close();
  }

  private bidPackageArray() {
    this.awardedParents = [];
    for (const bp of this.bidPackages) {
      if (!!bp.awarded_company_id || bp.trade_allows_nonbid_invoices || bp.child_project?.id) {
        if (bp.child_project?.id) {
          bp.total_contract_sum =
            (+bp?.child_project?.budget_data?.awardedBidTotal || 0) +
            (+bp?.child_project?.budget_data?.changeOrderTotal || 0);
        } else if (bp.child_request?.id) {
          bp.total_contract_sum = 0;
        } else {
          bp.total_contract_sum = (+bp?.awarded_amount || 0) + (+bp?.approved_change_order_total || 0);
        }
        bp.label = bp.trade_name;
        this.awardedParents.push(bp);
      }
    }

    for (const bidPackage of this.bidPackages) {
      if (bidPackage.child_project?.id) {
        bidPackage.billed = +bidPackage?.child_project?.budget_data?.invoicesBilledTotal || 0;
        bidPackage.retainage = 0;
      } else if (bidPackage.child_request?.id) {
        bidPackage.billed = 0;
        bidPackage.retainage = 0;
      } else {
        const bidPackageInvoices = this.invoices.filter((i) => i.bid_package_id === bidPackage.id);
        let hasNewInvoice = false;
        bidPackage.billed = 0;
        bidPackage.retainage = 0;
        for (const invoice of bidPackageInvoices) {
          if (!hasNewInvoice && invoice.status_id === InvoiceStatus.New) {
            hasNewInvoice = true;
          }
          if (
            [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(
              invoice?.status_id
            ) > -1
          ) {
            bidPackage.billed +=
              (+invoice.total || 0) - (+invoice.retainage || 0) + (+invoice.shipping || 0) + (+invoice.tax || 0);
            bidPackage.retainage += invoice.is_retainage ? -1 * (+invoice.total || 0) : +invoice.retainage || 0;
          }
        }
        bidPackage.expanded = bidPackage.expanded || this.expandedParentIds.indexOf(bidPackage?.id) > -1;
        bidPackage.hasNewInvoice = bidPackage.hasNewInvoice || hasNewInvoice;
        bidPackage.invoiceCount = (bidPackageInvoices || []).length;
        bidPackage.can_close =
          !!bidPackageInvoices?.length &&
          !bidPackageInvoices.find((i) =>
            [InvoiceStatus.New, InvoiceStatus.Received, InvoiceStatus.Approved].includes(i.status_id)
          );

        bidPackage.invoiceTypes = [];
        const originalBidInvoices = (bidPackageInvoices || []).filter((i) => !i.change_order_id && !i.is_retainage);
        if (originalBidInvoices.length > 0) {
          bidPackage.invoiceTypes.push({
            type_id: InvoiceType.OriginalBid,
            invoices: originalBidInvoices,
          });
        }
        const changeOrderInvoices = (bidPackageInvoices || []).filter((i) => !!i.change_order_id);
        if (changeOrderInvoices.length > 0) {
          const changeOrders = orderBy(
            uniqBy(
              changeOrderInvoices.map((i) => ({
                id: i.change_order_id,
                local_index: i.change_order_local_index,
              })),
              (co) => co.id
            ),
            (co) => co.local_index
          );
          for (const co of changeOrders) {
            bidPackage.invoiceTypes.push({
              type_id: InvoiceType.ChangeOrder,
              invoices: changeOrderInvoices.filter((i) => i.change_order_id === co.id),
              change_order_local_index: co.local_index,
            });
          }
        }
        const retainageInvoices = (bidPackageInvoices || []).filter((i) => i.is_retainage);
        if (retainageInvoices.length > 0) {
          bidPackage.invoiceTypes.push({
            type_id: InvoiceType.Retainage,
            invoices: retainageInvoices,
          });
        }
      }
    }
  }

  private async quoteArray() {
    this.quotes = await this.productService
      .getQuotes(this.quoteFields, [
        {
          type: 'field',
          field: 'project_id',
          value: this.projectService.currentSelectedProjectId.toString(),
        },
      ])
      .toPromise();

    this.awardedParents = this.quotes.filter((q) => q.awarded_amount && q.awarded_amount !== 0);
    for (const quote of this.awardedParents) {
      quote.label = quote.code;
      const quoteInvoices = this.invoices.filter((i) => i.quote_id === quote.id);
      let hasNewInvoice = false;
      quote.billed = 0;
      for (const invoice of quoteInvoices) {
        if (!hasNewInvoice && invoice.status_id === InvoiceStatus.New) {
          hasNewInvoice = true;
        }
        if (
          [InvoiceStatus.Received, InvoiceStatus.Approved, InvoiceStatus.ReadyForPayment].indexOf(invoice?.status_id) >
          -1
        ) {
          quote.billed +=
            (+invoice.total || 0) - (+invoice.retainage || 0) + (+invoice.shipping || 0) + (+invoice.tax || 0);
        }
      }
      quote.expanded = quote.expanded || this.expandedParentIds.indexOf(quote?.id) > -1;
      quote.hasNewInvoice = quote.hasNewInvoice || hasNewInvoice;
      quote.invoiceCount = (quoteInvoices || []).length;

      quote.invoiceTypes = [];
      const originalBidInvoices = (quoteInvoices || []).filter((i) => !i.change_order_id && !i.is_retainage);
      if (originalBidInvoices.length > 0) {
        quote.invoiceTypes.push({
          type_id: InvoiceType.OriginalBid,
          invoices: originalBidInvoices,
        });
      }
      const changeOrderInvoices = (quoteInvoices || []).filter((i) => !!i.change_order_id);
      if (changeOrderInvoices.length > 0) {
        const changeOrders = orderBy(
          uniqBy(
            changeOrderInvoices.map((i) => ({
              id: i.change_order_id,
              local_index: i.change_order_local_index,
            })),
            (co) => co.id
          ),
          (co) => co.local_index
        );
        for (const co of changeOrders) {
          quote.invoiceTypes.push({
            type_id: InvoiceType.ChangeOrder,
            invoices: changeOrderInvoices.filter((i) => i.change_order_id === co.id),
            change_order_local_index: co.local_index,
          });
        }
      }
    }
  }

  public handleSearchStringCancel() {
    this.searchString = '';
    this.filterInvoices();
  }

  public filterInvoices() {
    const awardedParents: any[] = this.awardedParents;
    this.filteredParents = this.selectedParent?.id
      ? awardedParents.filter((p) => p.id === this.selectedParent?.id)
      : awardedParents;
    for (const p of this.filteredParents) {
      p.filteredInvoiceCount = 0;
      for (const t of p?.invoiceTypes ?? []) {
        t.filteredInvoices = t.invoices.filter((i) => {
          let keep = true;
          if (this.filteredStatusIds?.length > 0 && this.filteredStatusIds.indexOf(i.status_id) === -1) {
            keep = false;
          }
          if (this.searchString?.trim() && !i.title.toLowerCase().includes(this.searchString?.trim()?.toLowerCase())) {
            keep = false;
          }
          return keep;
        });
        t.filteredInvoiceCount = t.filteredInvoices?.length;
        p.filteredInvoiceCount += t.filteredInvoiceCount;
      }
    }
  }

  // Gets the total for each backend invoice status.
  private getInvoiceCounts() {
    const invoiceCount = { all: 0 };
    this.invoices.forEach((invoice) => {
      // Gets the counts for the invoices by status
      invoiceCount.all = (invoiceCount.all || 0) + 1;
      invoiceCount[invoice.status_id] = (invoiceCount[invoice.status_id] || 0) + 1;
    });

    this.addUpInvoiceCounts(invoiceCount);
  }

  // Takes the total by backend status and makes it match up with the types of invoices in the menu.
  private addUpInvoiceCounts(invoiceCount) {
    this.invoiceStatusInfo.forEach((status) => {
      let total = 0;
      if (status.statusIds.length) {
        status.statusIds.forEach((statusId) => {
          total += invoiceCount[statusId] || 0;
        });
      } else {
        total = invoiceCount.all || 0;
      }
      status.count = total;
    });
  }

  public get hasAOpenInvoice(): boolean {
    return this.awardedParents?.some((awardedParent) => awardedParent.expanded);
  }

  get isAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  get canCreateInvoices() {
    return (
      this.isAdmin ||
      this.authService.currentUserIsOfProjectRole(
        ApplicationRole.ProjectVendor,
        this.projectService.currentSelectedProjectId
      ) ||
      this.authService.currentUserIsOfProjectRole(
        ApplicationRole.ProjectEngineer,
        this.projectService.currentSelectedProjectId
      ) ||
      this.isWorkspaceStaff
    );
  }

  get FileAction() {
    return FileAction;
  }

  get InvoiceStatus() {
    return InvoiceStatus;
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get Workspace() {
    return Workspace;
  }

  // If it is a construction project checks to see if Pebs are finalized
  private async getPebStatus() {
    if (this.currentWorkspace !== Workspace.Construction) {
      this.allPEBsAreFinalized = true;
      return;
    }

    // TODO: PEB - Invoices
    const pebs = await this.pebService
      .getPEBs(
        ['tenant_id'],
        [
          {
            type: 'field',
            field: 'project_id',
            value: this.projectService.currentSelectedProjectId.toString(),
          },
        ]
      )
      .toPromise();
    const projectTenants: ProjectTenantConstruction[] = await this.projectTenantService
      .getTenantsForProject(this.projectService.currentSelectedProjectId, ['peb_status', 'selected_peb_id'])
      .toPromise();
    this.allPEBsAreFinalized = true;
    const pebTenantIds = pebs.map((p) => p.tenant_id);
    const projectTenantsWithPEBs = projectTenants.filter((t) => pebTenantIds.indexOf(t.id) > -1);
    if (projectTenantsWithPEBs.find((t) => t.peb_status != null && t.peb_status !== ProjectTenantPEBStatus.Finalized)) {
      this.allPEBsAreFinalized = false;
    } else {
      this.allPEBsAreFinalized = true;
    }
  }

  updateBidPackageStatus(bidPackage: BidPackage): void {
    const bidPackageIndex = this.bidPackages.findIndex((bp) => bp.id === bidPackage?.id);
    this.bidPackages.splice(bidPackageIndex, 1, bidPackage);
    this.bidPackageArray();
    this.filterInvoices();
  }

  // * Invoice actions * //
  async createInvoice() {
    if (+this.project.status_id === +ProjectStatus.CLOSED || +this.project.status_id === +ProjectStatus.ON_HOLD) {
      // The correct text will be used and the wrong one will be empty
      const closedDescriptionPrefix =
        +this.project.status_id === +ProjectStatus.CLOSED ? 'This project has been closed' : '';
      const onHoldDescriptionPredfix =
        +this.project.status_id === +ProjectStatus.ON_HOLD ? 'This project is on hold' : '';

      this.modalService
        .openConfirmationDialog({
          titleBarText: 'New Invoice',
          headerText: 'Reactivate Project',
          descriptionText: `${closedDescriptionPrefix}${onHoldDescriptionPredfix}. If you have access to reactivate it, please do so before adding a new Invoice. <br/><br/>If you are a supplier, please contact the project manager to reactivate the project!`,
          hideConfirmationButton: true,
          cancelButtonText: 'Close',
        })
        .subscribe();
    } else {
      this.modalService.openNewInvoiceModal(this.currentWorkspace).subscribe(async (res) => {
        if (res) {
          await this.refresh();
        }
      });
    }
  }

  viewInvoice(invoice: Invoice): void {
    this.modalService.openViewInvoiceModal(invoice.id, this.currentWorkspace);
  }

  public getInvoiceTypeTitle(invoiceType) {
    let invoiceTitle = '';
    switch (invoiceType.type_id) {
      case InvoiceType.OriginalBid:
        invoiceTitle = 'Original Bid';
        break;
      case InvoiceType.ChangeOrder:
        invoiceTitle = `Change Order - ${
          invoiceType.change_order_local_index ? `#${invoiceType.change_order_local_index}` : 'Unknown Number'
        }`;
        break;
      case InvoiceType.Retainage:
        invoiceTitle = 'Retainage';
        break;
      default:
        invoiceTitle = 'Unknown Invoice Type';
        break;
    }

    return invoiceTitle;
  }

  async processInvoice(invoice) {
    await this.projectService.updateInvoice(invoice.id, { status_id: InvoiceStatus.ReadyForPayment }).toPromise();
  }

  async viewRejection(invoice) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      data: {
        titleBarText: 'Invoices',
        headerText: `Invoice ${invoice.number} was rejected`,
        // hideConfirmationButton : true,
        confirmationButtonText: 'Upload Another',
        cancelButtonText: 'Dismiss',
        descriptionText: `<span>${invoice.review_comment}</span>
        <br /><p> ${
          invoice.review_user_id ? '- ' + invoice.review_user_first_name + ' ' + invoice.review_user_last_name : ''
        }</p>`,
      },
    });
    dialogRef.afterClosed().subscribe(async (isConfirmed) => {
      if (isConfirmed) {
        await this.refresh();
        await this.createInvoice();
      }
    });
  }

  public selectStatus(status): void {
    const statusContainsAll = status.find((s) => s.name === 'All');
    if (statusContainsAll && !this.selectedInvoiceStatuses?.find((s) => s.name === 'All')) {
      this.selectedInvoiceStatuses = [statusContainsAll];
    } else {
      this.selectedInvoiceStatuses = status.filter((s) => s.name !== 'All');
    }

    if (!this.selectedInvoiceStatuses?.length) {
      this.selectedInvoiceStatuses.push(this.invoiceStatusInfo[this.invoiceStatusInfo.length - 1]);
    }

    this.invoiceStatusInfo.forEach((status) => {
      status.isSelected = !!this.selectedInvoiceStatuses.find((s) => s.name === status.name);
    });

    this.filteredStatusIds = [];
    for (const s of this.selectedInvoiceStatuses) {
      this.filteredStatusIds = [...this.filteredStatusIds, ...s.statusIds];
    }

    const statusIndexes = [];
    for (const ss of this.selectedInvoiceStatuses) {
      statusIndexes.push(this.invoiceStatusInfo.findIndex((si) => si.name === ss.name));
    }
    this.addToPreferences('invoice_statuses', statusIndexes);
    this.filterInvoices();
  }

  preventDefault(e: Event): void {
    e.preventDefault();
  }

  public selectParent(parent: InvoiceBidPackage | InvoiceQuote): void {
    this.selectedParent = parent;
    this.filterInvoices();
  }

  public toggleParentExpansion(parent: InvoiceBidPackage | InvoiceQuote) {
    if (!parent.child_request?.id && !parent.child_project?.id) {
      parent.expanded = !parent.expanded;

      if (parent.expanded) {
        this.expandedParentIds.push(parent.id);
      } else {
        this.expandedParentIds = this.expandedParentIds.filter((id) => id !== parent.id);
      }

      this.addToPreferences('expanded_trades', this.expandedParentIds);
    }
  }

  public updateSortByField(field: string) {
    if (this.sortByField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.addToPreferences('sort_invoice_order', this.sortDirection);
    } else {
      this.sortByField = field;
      this.addToPreferences('sort_invoice_by_field', this.sortByField);
    }
  }

  // * Boolean Functions * //
  public createdByCurrentUser(invoice) {
    const currentUser = this.authService.getLoggedInUser();
    return currentUser.id === invoice.created_by_id;
  }

  public userIsPartOfAwardedCompany(invoice) {
    const currentUser = this.authService.getLoggedInUser();
    return invoice.company_id === currentUser?.company_id;
  }

  public isInvoiceContact(invoice) {
    const currentUser = this.authService.getLoggedInUser();
    return currentUser.id === (invoice?.bid_contact_id || invoice?.quote?.contact_id);
  }

  showReviewTaskOption(invoice: Invoice) {
    return invoice.approval_task_id && this.isWorkspaceStaff;
  }

  showExportOption(invoice: Invoice) {
    return [InvoiceStatus.ReadyForPayment].indexOf(invoice.status_id) > -1;
  }

  showChangeOption(invoice: Invoice) {
    return (
      ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.status_id) &&
        this.userIsPartOfAwardedCompany(invoice)) ||
      this.isAdmin
    );
  }

  public getTaskReviewStatus(status: string): number {
    if (status === 'Approved') {
      return TaskReviewStatus.Approved;
    } else if (status === 'Rejected') {
      return TaskReviewStatus.Rejected;
    } else if (status === 'Pending') {
      return TaskReviewStatus.Pending;
    } else {
      console.error(`'${status}' is not a TaskReviewStatus`);
    }
  }

  public viewTask(taskId: number) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.reviewChanged.subscribe((data) => {});
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }

  // Gets the first phase and its first milestone for non construction projects.
  private async getPhaseInfo() {
    const phases = await this.projectService.getPhasesByProjectId(this.project.id).toPromise();
    const phase = phases[0];
    const milestones = await this.projectService.getMilestonesByPhaseId(phase.id).toPromise();

    return {
      phaseName: phase.name,
      milestoneName: milestones[0].name,
    };
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    localStorage.setItem('preferences', JSON.stringify(preferences));
  }

  public toggleViewInvoices() {
    // get the current state and toggle
    const newState = !this.hasAOpenInvoice;

    // toggle all the invoice views
    this.awardedParents = this.awardedParents.map((awardedParent: InvoiceBidPackage) => ({
      ...awardedParent,
      expanded: newState,
    }));

    // collect the ids for removal or addition
    const awardedParentIds = this.awardedParents.map((awardedParent: InvoiceBidPackage) => awardedParent.id);

    // we use a set so we don't care about the starting state
    const expandedParentIds = new Set(this.expandedParentIds);

    if (newState) {
      // add to expanded
      awardedParentIds.forEach((id) => expandedParentIds.add(id));
    } else {
      // remove from expanded
      awardedParentIds.forEach((id) => expandedParentIds.delete(id));
    }

    // update the state
    this.expandedParentIds = [...expandedParentIds];

    // update local storage
    this.addToPreferences('expanded_trades', this.expandedParentIds);
    this.filterInvoices();
  }
}
