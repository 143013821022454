import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, get as lodashGet } from 'lodash';
import { SortDirection } from '@angular/material/sort';
import { Order, ORDER_FIELD } from 'src/app/enums';

@Pipe({
  name: 'orderByField',
  pure: false,
})
export class OrderByFieldPipe implements PipeTransform {
  transform(dataArray: any, field: string, order: Order | SortDirection): any {
    const stringOrderArray = [order === Order.ASC ? Order.ASC : Order.DESC];
    const arrayOfFields: string[] = field ? [field] : [];

    // The location field combines two different fields. The second field needs to be added to insure everything is sorted
    if (field === ORDER_FIELD.BUILDING_CODE) {
      arrayOfFields.push(ORDER_FIELD.FLOOR_DOT_CODE);
      stringOrderArray.push(stringOrderArray[0]);
    } else if (field === ORDER_FIELD.FIRST_NAME) {
      arrayOfFields.push(ORDER_FIELD.LAST_NAME);
      stringOrderArray.push(stringOrderArray[0]);
    } else if (field === ORDER_FIELD.ASSIGNED_USER_FIRST_NAME) {
      arrayOfFields.push(ORDER_FIELD.ASSIGNED_USER_LAST_NAME);
      stringOrderArray.push(stringOrderArray[0]);
    } else if (field === ORDER_FIELD.REQUESTER_FIRST_NAME) {
      arrayOfFields.push(ORDER_FIELD.REQUESTER_LAST_NAME);
      stringOrderArray.push(stringOrderArray[0]);
    } else if (field === ORDER_FIELD.NUMBER && !dataArray?.is_consultant) {
      dataArray.forEach((item) => {
        const toNumber = Number(item[ORDER_FIELD.NUMBER]);
        if (toNumber !== 0 && !isNaN(toNumber)) {
          item[ORDER_FIELD.NUMBER] = toNumber;
        }
      });
    }

    let orderedArray: any[] = orderBy(dataArray, arrayOfFields, stringOrderArray);

    // This moves any blank fields to the end
    if (order === Order.DESC) {
      const emptyFields = orderedArray.filter((f) => !lodashGet(f, field));

      if (emptyFields[0] && emptyFields[0].id === orderedArray[0].id) {
        orderedArray.splice(0, emptyFields.length);
        orderedArray = [...orderedArray, ...emptyFields];
      }
    }
    return orderedArray;
  }
}
