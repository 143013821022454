<div id="main-nav" class="main-nav no-print ease" data-test-id="main-nav" [class.navToggleClosed]="isSidebarClosed">
  <div class="main-nav__logo-bar d-flex align-items-center">
    <img [routerLink]="['/']" id="nav_logo" class="mr-auto" src="../../assets/img/main_logo3.png" alt="" />
    <a
      id="main-brand-icon-toggler"
      data-test-id="main-brand-icon-toggler"
      (click)="toggleMainNav()"
      class="m-brand__icon m-brand__toggler"
    >
      <span></span>
    </a>
  </div>
  <div id="sidenav-links" class="upper scroll" data-scroll="true" [ngStyle]="{ height: divHeight }">
    <ul>
      <li
        class="nav-item pointer d-flex align-items-center"
        style="background-color: #11192f"
        [matTooltip]="isSidebarClosed ? 'Search' : ''"
        matRipple
        matTooltipPosition="right"
        (click)="onSearchButtonClick()"
        [ngClass]="{ 'selected-link': searchService.searchPanelIsOpen }"
      >
        <div class="" [ngClass]="{ 'no-bg': !searchService.searchPanelIsOpen }"></div>
        <mat-icon class="">search</mat-icon>
        <span class="label ml-2" [hidden]="isSidebarClosed">Search</span>
      </li>
      <li>
        <a
          data-test-id="sidebar-item-link"
          class="pointer nav-item mt-3 mb-3 d-flex align-items-center"
          style="outline: none"
          matRipple
          [routerLink]="[home.routerLink]"
          [matTooltip]="isSidebarClosed ? home.label : ''"
          matTooltipPosition="right"
          (click)="selectLink(home)"
          [ngClass]="{
            'selected-link': selectedLink === home && !anOverlayPanelIsOpen(),
            'hide-selected': selectedLink === home && anOverlayPanelIsOpen(),
            'text-center': isSidebarClosed
          }"
        >
          <mat-icon class="">{{ home.matIcon }}</mat-icon>
          <span class="label" [hidden]="isSidebarClosed">{{ home.label }}</span>
        </a>
      </li>
      <section *ngIf="(isStaffOnAnyModule || isAppAdmin) && _moduleService.userWorkspaces?.length">
        <div
          matRipple
          (click)="showWorkspaceMenu = !showWorkspaceMenu"
          class="section-header row m-0 align-items-center pointer"
        >
          <div [hidden]="isSidebarClosed" class="col-auto">
            <p class="m-0 gray font-weight-normal mb-1">Workspace</p>
          </div>
          <div class="col text-right" [ngClass]="{ 'text-center': isSidebarClosed }">
            <i *ngIf="showWorkspaceMenu" class="material-icons m-0 gray" style="height: 24px; font-size: 24px">
              expand_more
            </i>
            <i *ngIf="!showWorkspaceMenu" class="material-icons m-0 gray" style="height: 24px; font-size: 24px">
              chevron_right
            </i>
          </div>
        </div>
        <!-- WORKSPACE MENU -->
        <span *ngIf="showWorkspaceMenu">
          <li
            class="mb-2 ws-selector d-flex align-items-center"
            [matMenuTriggerFor]="workspace_menu"
            [matTooltip]="isSidebarClosed ? selectedWorkspaceName : ''"
            matRipple
            matTooltipPosition="right"
            [ngClass]="{ 'text-center': isSidebarClosed }"
          >
            <mat-icon>{{ selectedWorkspaceIcon }}</mat-icon>
            <span class="label font-weight-semibold">{{ selectedWorkspaceName }}</span>
          </li>
          <mat-menu class="bg-dkblue" #workspace_menu="matMenu">
            <button
              *ngFor="let workspace of userWorkspaces"
              mat-menu-item
              (click)="handleWorkspaceSelect(workspace)"
              class="workspace-menu-item"
            >
              <mat-icon class="ltblue">{{ workspace.icon }}</mat-icon>
              <span class="white font-weight-semibold">{{ workspace.name }}</span>
            </button>
          </mat-menu>
          <li>
            <a
              data-test-id="sidebar-item-link"
              class="pointer nav-item d-flex align-items-center"
              style="outline: none"
              matRipple
              *ngFor="let link of links"
              [routerLink]="[link.routerLink]"
              [matTooltip]="isSidebarClosed ? link.label : ''"
              matTooltipPosition="right"
              (click)="selectLink(link)"
              [ngClass]="{
                'selected-link': selectedLink === link && !anOverlayPanelIsOpen(),
                'hide-selected': selectedLink === link && anOverlayPanelIsOpen(),
                'text-center': isSidebarClosed
              }"
            >
              <mat-icon
                [ngClass]="{
                  pink: selectedLink?.label === 'Requests' && link.label === 'Requests',
                  ltblue: selectedLink?.label === 'Projects' && link.label === 'Projects',
                  orange: selectedLink?.label === 'Work Orders' && link.label === 'Work Orders',
                  olive: selectedLink?.label === 'Purchasing' && link.label === 'Purchasing'
                }"
                >{{ link.matIcon }}</mat-icon
              >
              <span class="label text-nowrap" [hidden]="isSidebarClosed">{{ link.label }}</span>
              <span
                class="label sidenav-badge bg-red white lh-18"
                *ngIf="link.label === 'Requests' && pendingRequestsCount > 0 && isStaffOnAnyModule"
                >{{ pendingRequestsCount }}</span
              >
              <span
                *ngIf="link.label === 'Requests' && pendingRequestsCount > 0 && isStaffOnAnyModule && isSidebarClosed"
                class="sidenav-notify bg-red lh-18"
              ></span>
              <span
                class="label sidenav-badge bg-red white lh-18"
                *ngIf="link.label === 'Work Orders' && unassignedWorkOrderCount > 0 && isStaffOnAnyModule"
                >{{ unassignedWorkOrderCount }}</span
              >
              <span
                *ngIf="
                  link.label === 'Work Orders' && unassignedWorkOrderCount > 0 && isStaffOnAnyModule && isSidebarClosed
                "
                class="sidenav-notify bg-red lh-18"
              ></span>
              <span
                class="label sidenav-badge bg-red white lh-18"
                *ngIf="link.label === 'Tasks' && assignedTasksCount > 0 && isStaffOnAnyModule"
                >{{ assignedTasksCount }}</span
              >
              <span
                *ngIf="link.label === 'Tasks' && assignedTasksCount > 0 && isStaffOnAnyModule && isSidebarClosed"
                class="sidenav-notify bg-red lh-18"
              ></span>
            </a>
          </li>
        </span>
      </section>
      <section>
        <div
          matRipple
          (click)="showMyItemsMenu = !showMyItemsMenu"
          class="section-header row m-0 align-items-center pointer"
        >
          <div [hidden]="isSidebarClosed" class="col-auto">
            <p class="m-0 gray font-weight-normal mb-1">Items</p>
          </div>
          <div class="col text-right" [ngClass]="{ 'text-center': isSidebarClosed }">
            <i *ngIf="showMyItemsMenu" class="material-icons m-0 gray" style="height: 24px; font-size: 24px">
              expand_more
            </i>
            <i *ngIf="!showMyItemsMenu" class="material-icons m-0 gray" style="height: 24px; font-size: 24px">
              chevron_right
            </i>
          </div>
        </div>
        <!-- USER LINKS -->
        <span *ngIf="showMyItemsMenu">
          <li>
            <a
              data-test-id="sidebar-item-link"
              class="pointer nav-item d-flex align-items-center"
              style="outline: none"
              matRipple
              *ngFor="let link of myLinks"
              [routerLink]="[link.routerLink]"
              [matTooltip]="isSidebarClosed ? link.label : ''"
              matTooltipPosition="right"
              (click)="selectLink(link)"
              [ngClass]="{
                'selected-link': selectedLink === link && !anOverlayPanelIsOpen(),
                'hide-selected': selectedLink === link && anOverlayPanelIsOpen(),
                'text-center': isSidebarClosed
              }"
            >
              <mat-icon
                [ngClass]="{
                  purple: selectedLink?.label === 'Meetings' && link.label === 'Meetings'
                }"
                >{{ link.matIcon }}</mat-icon
              >
              <span class="label text-nowrap" [hidden]="isSidebarClosed">{{ link.label }}</span>
            </a>
          </li>
          <li
            *ngIf="isStaffOnAnyModule"
            data-test-id="sidebar-item-link"
            class="pointer nav-item d-flex align-items-center"
            style="outline: none"
            matRipple
            [matTooltip]="isSidebarClosed ? 'Time Log' : ''"
            matTooltipPosition="right"
            (click)="openTimeLog()"
            [ngClass]="{
              'selected-link': timeLogService.timeLogPanelIsOpen,
              'text-center': isSidebarClosed
            }"
          >
            <mat-icon>alarm_add</mat-icon>
            <span class="label text-nowrap" [hidden]="isSidebarClosed">Time Log</span>
          </li>
          <!-- <span
          *ngIf="remindersService.incompleteReminderCount > 0"
          class="sidenav-notify"
          [ngClass]="{
            'bg-red': remindersService.hasOverdueReminders
          }"
        ></span> -->
        </span>
      </section>
      <section>
        <div matRipple (click)="showLinks = !showLinks" class="section-header row m-0 align-items-center pointer">
          <div [hidden]="isSidebarClosed" class="col-auto">
            <p class="m-0 gray font-weight-normal mb-1">Links</p>
          </div>
          <div class="col text-right" [ngClass]="{ 'text-center': isSidebarClosed }">
            <i *ngIf="showLinks" class="material-icons m-0 gray" style="height: 24px; font-size: 24px"> expand_more </i>
            <i *ngIf="!showLinks" class="material-icons m-0 gray" style="height: 24px; font-size: 24px">
              chevron_right
            </i>
          </div>
        </div>
        <span *ngIf="showLinks">
          <li *ngIf="isStaffOnAnyModule">
            <a
              class="nav-item pointer d-flex align-items-center"
              [matTooltip]="isSidebarClosed ? 'Employee Portal' : ''"
              matRipple
              matTooltipPosition="right"
            >
              <a href="https://staff.271call.com/" target="_blank">
                <mat-icon class="">badge</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Employee Portal</span>
              </a>
            </a>
          </li>
          <li *ngIf="isStaffOnAnyModule">
            <a
              class="nav-item pointer d-flex align-items-center"
              [matTooltip]="isSidebarClosed ? 'Tableau' : ''"
              matRipple
              matTooltipPosition="right"
            >
              <a href="https://prod-useast-b.online.tableau.com/#/site/universityhospitalstrust/home" target="_blank">
                <mat-icon class="">bar_chart</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Tableau</span>
              </a>
            </a>
          </li>
          <!-- <li>
            <a class="nav-item pointer d-flex align-items-center" [matTooltip]="isSidebarClosed ? 'Nominations' : ''"
              matRipple matTooltipPosition="right">
              <a href="https://staff.271call.com/employee-nomination/" target="_blank">
                <mat-icon class="">emoji_events</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Nominations</span>
              </a>
            </a>
          </li> -->
          <li
            class="nav-item pointer d-flex align-items-center"
            [matTooltip]="isSidebarClosed ? releaseNotes.label : ''"
            matRipple
            matTooltipPosition="right"
            *ngIf="isStaffOnAnyModule"
          >
            <a href="https://kb.271call.com/books/release-notes" target="_blank">
              <mat-icon class="">{{ releaseNotes.matIcon }}</mat-icon>
              <span class="label" [hidden]="isSidebarClosed">{{ releaseNotes.label }}</span>
            </a>
          </li>
          <li>
            <a
              class="nav-item pointer d-flex align-items-center"
              [matTooltip]="isSidebarClosed ? 'Help Docs' : ''"
              matRipple
              matTooltipPosition="right"
            >
              <a href="https://help.271call.com/" target="_blank">
                <mat-icon class="">help</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Help Docs</span>
              </a>
            </a>
          </li>
          <li>
            <a
              class="nav-item pointer d-flex align-items-center"
              [matTooltip]="isSidebarClosed ? 'Privacy Policy' : ''"
              matRipple
              matTooltipPosition="right"
            >
              <a href="https://uhat.org/privacy-statement/" target="_blank">
                <mat-icon class="">lock</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Privacy Policy</span>
              </a>
            </a>
          </li>
          <!-- <li>
            <a class="nav-item pointer d-flex align-items-center" [matTooltip]="isSidebarClosed ? 'Knowledge Base' : ''"
              matRipple matTooltipPosition="right">
              <a href=" https://kb.271call.com/" target="_blank">
                <mat-icon class="">book</mat-icon>
                <span class="label" [hidden]="isSidebarClosed">Knowledge Base</span>
              </a>
            </a>
          </li> -->
        </span>
      </section>
    </ul>
    <div class="fade-overlay"></div>
  </div>
  <!-- LOWER SECTION - ABSOLUTE BOTTOM -->
  <div #lower class="lower" [ngClass]="{ 'lower-closed': !isSidebarClosed }">
    <!-- USER ACTIONS -->
    <ul *ngIf="!isStaffOnAnyModule" class="pt-3 pb-3">
      <li
        id="not-staff-sidenav-contact-support-item"
        data-test-id="not-staff-sidenav-contact-support-item"
        class="d-flex gray nav-item pointer align-items-center"
        matRipple
        [matTooltip]="isSidebarClosed ? 'Report Issue' : ''"
        matTooltipPosition="right"
        [matTooltipShowDelay]="300"
        (click)="openErrorLogging()"
      >
        <mat-icon>feedback</mat-icon>
        <span class="label ml-1">Contact Support</span>
      </li>
    </ul>
    <div *ngIf="isStaffOnAnyModule" class="row m-0 mb-3 pt-3">
      <div *ngIf="!isSidebarClosed" class="col text-center widgets p-0">
        <button
          id="staff-open-sidenav-contact-support-button"
          data-test-id="staff-open-sidenav-contact-support-button"
          (click)="openErrorLogging()"
          [matTooltip]="'Contact Support'"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
          mat-button
          class="pl-2 pr-2 br-6"
        >
          <mat-icon>feedback</mat-icon>
        </button>
        &nbsp;
        <a
          mat-button
          class="pl-2 pr-2 br-6"
          style="padding-bottom: 2px"
          [matTooltip]="'New CQI'"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
          (click)="openNewCQI()"
        >
          <mat-icon class="cqi-icon"></mat-icon>
          <!-- <img class="gray" src="assets/img/cqi-icon.svg" alt="CQI"> -->
        </a>
        &nbsp;
        <a
          mat-button
          [routerLink]="'/weekly-focus'"
          class="creed-link pl-2 pr-2 br-6"
          [matTooltip]="'Weekly Focus'"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          <mat-icon>diversity_2</mat-icon>
          <span *ngIf="weeklyFocusPulsing" class="pulse-dot-danger-right"></span>
        </a>
      </div>
      <div *ngIf="isStaffOnAnyModule && isSidebarClosed" class="col text-center" style="padding: 0 10px">
        <a
          data-test-id="closed-sidenav-staff-menu"
          mat-button
          [matMenuTriggerFor]="widget_menu"
          class="gray pl-2 pr-2 br-6"
        >
          <mat-icon>more_horiz</mat-icon>
        </a>
        <mat-menu #widget_menu="matMenu" yPosition="above" xPosition="before">
          <li
            id="staff-closed-sidenav-contact-support-button"
            data-test-id="staff-closed-sidenav-contact-support-button"
            (click)="openErrorLogging()"
            mat-menu-item
            color="primary"
          >
            <mat-icon class="dkblue">feedback</mat-icon>
            <span class="font-weight-semibold">Contact Support</span>
          </li>
          <li
            id="staff-closed-sidenav-contact-support-button"
            data-test-id="staff-closed-sidenav-contact-support-button"
            (click)="openNewCQI()"
            mat-menu-item
            color="primary"
          >
            <mat-icon class="cqi-icon bg-primary"></mat-icon>
            <span class="font-weight-semibold">New CQI Suggestion</span>
          </li>
          <li [routerLink]="'/weekly-focus'" mat-menu-item color="primary">
            <mat-icon class="dkblue">diversity_2</mat-icon>
            <span *ngIf="weeklyFocusPulsing" class="pulse-dot-danger-right m-2"></span>
            <span class="font-weight-semibold">Weekly Focus</span>
          </li>
        </mat-menu>
      </div>
    </div>
    <!-- NEW REQUEST BUTTON -->
    <div *ngIf="isStaffOnAnyModule || isTenant" class="row m-0">
      <div class="d-flex align-items-center" style="padding: 0 12px">
        <a
          id="create-request-link-condensed"
          data-test-id="create-request-link-condensed"
          *ngIf="isSidebarClosed"
          [routerLink]="['/new-request']"
          color="success"
          mat-mini-fab
          [matTooltip]="isSidebarClosed ? 'New Request' : ''"
          matTooltipPosition="right"
          class="full-button"
        >
          <mat-icon>add</mat-icon>
        </a>
        <a
          id="create-request-button-expanded"
          data-test-id="create-request-button-expanded"
          mat-raised-button
          [routerLink]="['/new-request']"
          (click)="selectLink()"
          *ngIf="!isSidebarClosed"
          color="success"
          style="height: 40px; border-radius: 20px"
          class="full-button"
        >
          <mat-icon>add</mat-icon>
          <span> New Request </span>
        </a>
      </div>
    </div>
    <!-- QUICK REQUEST -->
    <div *ngIf="isStaffOnAnyModule" class="row m-0 mt-3">
      <div class="d-flex align-items-center" style="padding: 0 12px">
        <a
          id="create-request-link-condensed"
          data-test-id="create-request-link-condensed"
          *ngIf="isSidebarClosed"
          [routerLink]="['/quick-request']"
          color="accent"
          mat-mini-fab
          [matTooltip]="isSidebarClosed ? 'New Request' : ''"
          matTooltipPosition="right"
          class="full-button"
        >
          <mat-icon>flash_on</mat-icon>
        </a>
        <a
          id="create-request-button-expanded"
          data-test-id="create-request-button-expanded"
          mat-raised-button
          [routerLink]="['/quick-request']"
          (click)="selectLink()"
          *ngIf="!isSidebarClosed"
          color="accent"
          style="height: 40px; border-radius: 20px"
          class="full-button"
        >
          <mat-icon>flash_on</mat-icon>
          <span style="padding-right: 11px"> Quick Entry </span>
        </a>
      </div>
    </div>
    <!-- <div class="d-flex text-center">
      <a [routerLink]="['/quick-request']" class="gray my-1 mx-auto">
        Quick Request
      </a>
    </div> -->
    <!-- CURRENT USER -->
    <div (click)="onAvatarClick()" class="row m-0 my-2" style="padding-left: 13px; overflow: visible">
      <div
        matRipple
        data-test-id="avatar-click"
        id="avatar"
        class="col pl-0 pr-0 pb-2 pt-3 d-flex align-items-center pointer br-5"
      >
        <app-profile-thumbnail
          [matBadge]="
            notificationService && notificationService.unreadNotificationCount > 0
              ? notificationService.unreadNotificationCount
              : null
          "
          matBadgeColor="warn"
          class="pointer"
          [width]="38"
          [height]="38"
          [userId]="currentUser?.id"
        >
        </app-profile-thumbnail>
        <h6 *ngIf="!isSidebarClosed" id="currentUserName" class="m-0 white font-weight-semibold ml-3 text-wrap">
          {{ currentUser?.first_name }} {{ currentUser?.last_name }}
        </h6>
      </div>
      <div *ngIf="authService.isImpersonating" (click)="endImpersonation($event)" id="end-impersonation">
        <button mat-flat-button color="warn">
          <mat-icon>bug_report</mat-icon>
          End Impersonation
        </button>
      </div>
    </div>
  </div>
</div>
