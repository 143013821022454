import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoverLetterDialogComponent } from 'src/app/components';
import { DateService, ModalService, ProjectService } from 'src/app/services';
import { TaskModalInjectionData } from 'src/app/types';
import { ProjectTenantService } from '../../services';
import { CBSection } from '../../types';

@Component({
  selector: 'app-cb-section-details-drawer',
  templateUrl: './cb-section-details-drawer.component.html',
  styleUrls: ['./cb-section-details-drawer.component.scss'],
})
export class CbSectionDetailsDrawerComponent implements OnInit {
  @Output() closeDrawer = new EventEmitter();
  @Output() openTenantReviewTask = new EventEmitter();
  @Output() submitForTenantReview = new EventEmitter();
  @Output() saveCoverLetterDescription = new EventEmitter();
  @Output() uploadConstructionDoc = new EventEmitter();
  @Output() removeConstructionDoc = new EventEmitter();
  @Input() canEdit: boolean;
  loading = false;
  isEditing = false;
  section: CBSection;
  constructor(
    private projectTenantService: ProjectTenantService,
    private dateService: DateService,
    private projectService: ProjectService,
    private modalService: ModalService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  load(section: CBSection) {
    this.loading = true;
    this.section = section;
    this.section.id = this.section.tenant_id;
    this.loading = false;
  }

  close() {
    this.closeDrawer.emit();
  }

  submitForReview() {
    this.submitForTenantReview.emit(this.section);
  }

  openCoverLetter(): void {
    // use the peb cover letter as default text, replacing peb with construction or set the default
    const defaultCoverLetterText =
      this.section?.peb_cover_letter_text?.replace(/preliminary estimated/gi, 'Construction') ||
      `Enclosed is the Construction Budget for project ${this.projectService.currentSelectedProject.code} - ${this.projectService.currentSelectedProject.title}. Before any work can begin, please review, approve, and sign the attached Construction Budget. When the Construction Budget is approved, please upload the signed copy to the 1CALL System.  We will review it to ensure all information required has been received and then coordinate with you regarding our next steps.`;

    const COVER_LETTER_TEXT = this.section?.cb_cover_letter_text || defaultCoverLetterText;
    const coverLetterDialog = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Construction Budget',
        tenant: {
          first_name: this.section.representative_first_name,
          tenant_id: this.section.tenant_id,
          type_id: this.section.type_id,
          cover_letter_text: this.section?.cb_cover_letter_text || defaultCoverLetterText,
        },
        pmInfo: this.pmInfo,
        architectInfo: this.architectInfo,
      },
    });

    coverLetterDialog.afterClosed().subscribe(async (tenant) => {
      if (tenant?.id) {
        if (!this.section?.cb_cover_letter_text || tenant.cover_letter_text !== COVER_LETTER_TEXT) {
          this.saveCoverLetterDescription.emit(tenant);
        }
      }
    });
  }

  openReviewTask() {
    this.openTenantReviewTask.emit(
      this.section.cb_tenant_approval_task_id || this.section.saved_cb_tenant_approval_task_id
    );
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.project_manager_first_name
      ? this.projectService.currentSelectedProject.project_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.project_manager_last_name
      ? this.projectService.currentSelectedProject.project_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.project_manager_email
      ? this.projectService.currentSelectedProject.project_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.project_manager_office_phone
      ? this.projectService.currentSelectedProject.project_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }

  get architectInfo(): { name: string; title: string; email: string; phone: string } {
    if (!this.projectService.currentSelectedProject.architect_first_name) {
      return null;
    }
    const first = this.projectService.currentSelectedProject.architect_first_name
      ? this.projectService.currentSelectedProject.architect_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.architect_last_name
      ? this.projectService.currentSelectedProject.architect_last_name
      : '';
    const email = this.projectService.currentSelectedProject.architect_email
      ? this.projectService.currentSelectedProject.architect_email
      : '';
    const phone = this.projectService.currentSelectedProject.architect_office_phone
      ? this.projectService.currentSelectedProject.architect_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Architect',
      email,
      phone,
    };
  }

  uploadConstructionDocument() {
    this.uploadConstructionDoc.emit(this.section);
  }

  public removeConstructionDocument(fileId: number) {
    this.removeConstructionDoc.emit({ section: this.section, fileId: fileId });
  }

  public async createTask() {
    const taskData: TaskModalInjectionData = {
      taskTitle: 'Construction Documents',
      taskDescription: 'Upload any construction documents to this task',
      dueDate: this.dateService.addWeekdays(3).toDate(),
      milestoneName: 'Project Bids',
      can_delete: 0,
    };

    const task = await this.modalService.openCreateTaskModal(taskData).toPromise();
    if (task?.id) {
      const sectionData = {
        construction_documents_task_id: task.id,
      };

      await this.projectTenantService.updateProjectTenant(this.section.tenant_id, sectionData).toPromise();

      this.section.construction_documents_task_id = task.id;
    }
  }

  public async viewTask(taskId: number) {
    await this.modalService.openViewTaskModal(taskId).toPromise();
  }
}
