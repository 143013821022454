<app-base-dialog [title]="'New CQI Suggestion'" (closeDialog)="close()" [draggable]="false"> </app-base-dialog>
<div id="cqi-dialog" data-test-id="cqi-dialog" class="cqi-dialog d-flex flex-column" mat-dialog-content>
  <div class="text-center mb-5">
    <img class="cqi-wheel" src="/assets/img/cqi-wheel.svg" alt="" />
  </div>
  <div class="row mb-4">
    <div class="col">
      <h6 class="dkblue mb-2 font-weight-semibold" id="cqi-email-label" data-test-id="contact-support-email-label">
        Your Email
      </h6>
      <mat-form-field appearance="outline" class="full-width">
        <input
          id="continuous-quality-inprovement-email-input"
          data-test-id="continuous-quality-inprovement-email-input"
          matInput
          placeholder="Email Address"
          [formControl]="email"
        />
        <mat-error>A email is required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <h6 class="dkblue font-weight-semibold mb-2">CQI Topic</h6>
      <mat-spinner *ngIf="loadingTopics" color="accent" [diameter]="40"></mat-spinner>
      <mat-form-field *ngIf="!loadingTopics" appearance="outline" class="full-width position-relative">
        <mat-select
          placeholder="Select assignment group"
          matNativeControl
          [formControl]="continuousQualityImprovementTopic"
        >
          <mat-select-trigger>
            {{ continuousQualityImprovementTopic?.value?.name }} -
            {{ continuousQualityImprovementTopic?.value?.topic_category?.topic_group?.name }}
          </mat-select-trigger>
          <mat-option *ngFor="let topic of filteredContinuousQualityImprovementTopics$ | async" [value]="topic">
            <p class="dkblue mb-0">{{ topic?.name }}</p>
            <h6 class="dkgray font-weight-normal mb-0 lh-16">
              {{ topic?.topic_category?.topic_group?.name }}
            </h6>
          </mat-option>
        </mat-select>
        <mat-error>A topic is required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col text-area">
      <h6 class="dkblue font-weight-semibold mb-2">Summary</h6>
      <mat-form-field appearance="outline" class="full-width">
        <textarea
          [formControl]="summary"
          matInput
          rows="4"
          placeholder="Please enter a brief description of your suggestion"
        ></textarea>
        <mat-error>A summary is required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-4 mt-2">
    <div class="col">
      <mat-chip-list class="d-inline-block align-middle">
        <button mat-button color="accent" class="pl-2 pr-3 mr-2 bg-shade-ltblue lh-34" (click)="openUploadModal()">
          <mat-icon>attach_file</mat-icon>
          <span class="ml-2"> Attach Files </span>
        </button>
        <app-file-chip
          *ngFor="let file of files"
          [file]="file"
          [removable]="true"
          (removeEvent)="removeFile(file)"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mx-auto gray text-center">
      <small class="dkgray">
        The purpose of the CQI process is to gather ideas and proposals for property betterment and long-term
        enhancements. If you come across any <b>physical or structural safety concerns</b>, it's crucial to promptly
        inform the UHAT Architect. Moreover, any grievances involving fellow colleagues or other human resources aspects
        should be directed to <b>Human Resources</b> and not channeled through this request system.
      </small>
    </div>
  </div>
</div>
<div mat-dialog-actions class="row pt-4">
  <div class="col">
    <button mat-button type="button" class="full-width gray" (click)="close()">Cancel</button>
  </div>
  <div class="col d-flex justify-content-center">
    <mat-spinner *ngIf="submittingCQI" color="accent" [diameter]="30"></mat-spinner>
    <button
      *ngIf="!submittingCQI"
      mat-flat-button
      type="submit"
      color="success"
      class="full-width"
      (click)="submitCqiSuggestion()"
      [disabled]="!email?.value || !continuousQualityImprovementTopic?.value?.name || !summary?.value"
    >
      Submit CQI
    </button>
  </div>
</div>
