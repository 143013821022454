import { NgModule } from '@angular/core';
import { NumericDirective } from 'src/app/directives/numeric.directive';
import { SharedModule } from 'src/app/modules/shared.module';
import {
  AddendumDialogComponent,
  AsBuiltDialogComponent,
  BidDialogComponent,
  BidPackageDialogComponent,
  CbSectionDetailsDrawerComponent,
  ChangeOrderApprovalDialogComponent,
  ChangeOrderModalComponent,
  ContractFilesDialogComponent,
  CreatePayAppDialogComponent,
  DashboardAssigneeComponent,
  DashboardMeetingComponent,
  DashboardProjectComponent,
  DashboardRequestComponent,
  DashboardTaskComponent,
  FinalizePEBDialogComponent,
  NewAltBidDialogComponent,
  NewProjectDrawingDialogComponent,
  NewSolicitationDialogComponent,
  PEBExportDialogComponent,
  PEBSectionDetailsDrawerComponent,
  PEBSectionDialogComponent,
  ProjectOverviewDialogComponent,
  ProjectRequestDetailsModalComponent,
  ProjectScheduleContractDialogComponent,
  ProjectSelectComponent,
  ProposalRequestDialogComponent,
  PunchlistInputModalComponent,
  RFIDialogComponent,
  SelectItemsDialogComponent,
  SubmittalSelectDialogComponent,
  TaskDialogComponent,
  TenantSelectDialogComponent,
  TradeSelectModalComponent,
  ViewChangeOrderDialogComponent,
} from 'src/app/workspaces/construction/components';
import { ConstructionRoutingModule } from 'src/app/workspaces/construction/construction-routing.module';
// pages
import {
  AddendumsComponent,
  AsBuiltsComponent,
  BidsComponent,
  // BudgetReportComponent,
  ChangeOrdersComponent,
  ConstructionBudgetComponent,
  ConstructionRequestComponent,
  DashboardComponent,
  PEBNewComponent,
  PEBOldComponent,
  ProgramDataComponent,
  ProjectComponent,
  ProjectDetailsComponent,
  ProjectDrawingsComponent,
  ProjectListComponent,
  ProjectOverviewComponent,
  ProjectTimelineComponent,
  ProposalRequestsComponent,
  PunchlistComponent,
  RequestComponent,
  RFIComponent,
  SubmittalsComponent,
} from 'src/app/workspaces/construction/pages';
import {
  MeetingDashboardFilterPipe,
  ProjectDashboardFilterPipe,
  ProjectDrawingFilterPipe,
  RequestDashboardFilterPipe,
  RFIFilterPipe,
  TaskDashboardFilterPipe,
} from 'src/app/workspaces/construction/pipes';
import { ProjectScheduleContractExportComponent } from './components/project-schedule-contract-export/project-schedule-contract-export.component';

@NgModule({
  declarations: [
    // components
    AddendumDialogComponent,
    AddendumsComponent,
    AsBuiltDialogComponent,
    AsBuiltsComponent,
    BidDialogComponent,
    BidPackageDialogComponent,
    BidsComponent,
    // BudgetReportComponent,
    CbSectionDetailsDrawerComponent,
    ChangeOrderApprovalDialogComponent,
    ChangeOrderModalComponent,
    ChangeOrdersComponent,
    ConstructionBudgetComponent,
    ContractFilesDialogComponent,
    CreatePayAppDialogComponent,
    ConstructionRequestComponent,
    DashboardAssigneeComponent,
    DashboardComponent,
    DashboardMeetingComponent,
    DashboardProjectComponent,
    DashboardRequestComponent,
    DashboardTaskComponent,
    FinalizePEBDialogComponent,
    NewAltBidDialogComponent,
    NewProjectDrawingDialogComponent,
    NewSolicitationDialogComponent,
    PEBNewComponent,
    PEBOldComponent,
    PEBExportDialogComponent,
    PEBSectionDetailsDrawerComponent,
    PEBSectionDialogComponent,
    ProgramDataComponent,
    ProjectComponent,
    ProjectDetailsComponent,
    ProjectDrawingsComponent,
    ProjectListComponent,
    ProjectOverviewComponent,
    ProjectOverviewDialogComponent,
    ProjectRequestDetailsModalComponent,
    ProjectSelectComponent,
    ProjectScheduleContractDialogComponent,
    ProjectTimelineComponent,
    ProposalRequestDialogComponent,
    ProposalRequestsComponent,
    PunchlistComponent,
    PunchlistInputModalComponent,
    RequestComponent,
    RFIComponent,
    RFIDialogComponent,
    SelectItemsDialogComponent,
    SubmittalsComponent,
    SubmittalSelectDialogComponent,
    TaskDialogComponent,
    TenantSelectDialogComponent,
    TradeSelectModalComponent,
    ViewChangeOrderDialogComponent,

    // pipes
    MeetingDashboardFilterPipe,
    ProjectDashboardFilterPipe,
    ProjectDrawingFilterPipe,
    RequestDashboardFilterPipe,
    RFIFilterPipe,
    TaskDashboardFilterPipe,

    // directives
    NumericDirective,

    ProjectScheduleContractExportComponent,
  ],
  imports: [ConstructionRoutingModule, SharedModule],
  exports: [ProjectComponent],
})
export class ConstructionModule {}
