import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { CompanyService, LocationService, ProgressIndicatorService } from 'src/app/services';
import { APIFilter, Building, Company, CompanyType, Department, Suite, SuiteOccupancy } from 'src/app/types';

@Component({
  selector: 'app-department-manager-dialog',
  templateUrl: './department-manager-dialog.component.html',
  styleUrls: ['./department-manager-dialog.component.scss'],
})
export class DepartmentManagerDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DepartmentManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private locationService: LocationService,
    private snackbar: MatSnackBar,
    private progressIndicatorService: ProgressIndicatorService,
    public companyService: CompanyService
  ) {}

  buildings: Building[];
  suites: Suite[];
  companies: Company[];
  companyTypes: CompanyType[];
  department = this.data.department ? JSON.parse(JSON.stringify(this.data.department)) : {};
  action = this.data.action;
  suiteOccupancies: SuiteOccupancy[] = [];
  departmentFormGroup: FormGroup = this.fb.group({
    name: [this.department.name ? this.department.name : '', [Validators.required]],
    company_type_id: [this.department.company?.type_id ? this.department.company?.type_id : '', [Validators.required]],
    company_id: [this.department.company_id ? this.department.company_id : '', [Validators.required]],
    building_id: [null],
    floor_id: [null],
    suite_id: [null],
    is_enabled: [this.department.is_enabled],
  });
  loading = true;
  saving = false;
  addingSuite = false;

  get name() {
    return this.departmentFormGroup.get('name');
  }
  get suite_id() {
    return this.departmentFormGroup.get('suite_id');
  }
  get suite_name() {
    return this.departmentFormGroup.get('suite_name');
  }
  get building_id() {
    return this.departmentFormGroup.get('building_id');
  }
  get floor_id() {
    return this.departmentFormGroup.get('floor_id');
  }
  get is_enabled() {
    return this.departmentFormGroup.get('is_enabled');
  }

  async ngOnInit(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading department...');
    const buildingFields = ['id', 'name', 'floors'];
    this.buildings = await this.locationService.getBuildings(buildingFields).toPromise();
    this.suites = await this.locationService.getSuites(['id', 'name', 'floor_id']).toPromise();
    this.companyTypes = await this.companyService
      .getCompanyTypes(['id', 'name'], [{ type: 'field', field: 'id', value: 4, match: '!=' }])
      .toPromise();
    await this.getCompanies();
    if (this.department.id) {
      const fields = ['id', 'building_id', 'floor_id', 'suite_id'];
      const apiFilters: APIFilter[] = [{ type: 'field', field: 'department_id', value: this.department.id }];
      this.department.suiteOccupancies = await this.locationService.getSuiteOccupancies(fields, apiFilters).toPromise();
      this.suiteOccupancies = JSON.parse(JSON.stringify(this.department.suiteOccupancies));
    } else {
      this.department.suiteOccupancies = [];
    }
    if (this.suiteOccupancies.length === 0) {
      this.addSuite();
    }

    this.loading = false;
    this.progressIndicatorService.close();
  }

  async getCompanies() {
    const company_type_id = this.departmentFormGroup.get('company_type_id').value;
    const companyFilters: APIFilter[] = [{ type: 'field', field: 'type_id', value: company_type_id }];
    await this.companyService
      .getCompanies(['id', 'name'], companyFilters)
      .toPromise()
      .then((companies: Company[]) => {
        this.companies = companies;
      });
  }

  companyTypeChanged() {
    this.getCompanies();
    this.departmentFormGroup.get('company_id').setValue(null);
  }

  async save() {
    if (!this.departmentFormGroup.valid) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');

    const departmentForm = this.departmentFormGroup.value;
    const departmentToSave: Department = {};
    if (departmentForm.name !== this.data.department.name) {
      departmentToSave.name = departmentForm.name;
    }
    if (departmentForm.company_id !== this.data.department.company_id) {
      departmentToSave.company_id = departmentForm.company_id;
    }
    if (departmentForm.is_enabled !== this.data.department.is_enabled) {
      departmentToSave.is_enabled = departmentForm.is_enabled ? 1 : 0;
    }
    if (Object.keys(departmentToSave).length > 0) {
      if (this.department.id) {
        await this.locationService
          .updateDepartment(this.department.id, departmentToSave)
          .toPromise()
          .then(() => {});
      } else {
        delete departmentToSave.is_enabled;
        await this.locationService
          .addDepartment(departmentToSave)
          .toPromise()
          .then((newDepartment) => {
            this.department.id = newDepartment.id;
          });
      }
    }

    const requests = [];
    this.suiteOccupancies.forEach((so) => {
      if (!so.suite_id || !this.department.id) {
        return;
      }
      const suiteOccupancyToAddOrUpdate = { department_id: this.department.id, suite_id: so.suite_id };
      if (!so.id) {
        const request = this.locationService.addSuiteOccupancy(suiteOccupancyToAddOrUpdate);
        requests.push(request);
      } else {
        const currentSuiteOccupancy = this.department.suiteOccupancies.find((cso) => cso.id === so.id);
        if (
          currentSuiteOccupancy &&
          (currentSuiteOccupancy.building_id !== so.building_id ||
            currentSuiteOccupancy.floor_id !== so.floor_id ||
            currentSuiteOccupancy.suite_id !== so.suite_id)
        ) {
          const request = this.locationService.updateSuiteOccupancy(so.id, suiteOccupancyToAddOrUpdate);
          requests.push(request);
        }
      }
    });

    this.department.suiteOccupancies.filter((cso) => {
      if (!this.suiteOccupancies.find((so) => so.id === cso.id)) {
        const request = this.locationService.deleteSuiteOccupancy(cso.id);
        requests.push(request);
      }
    });
    await forkJoin(requests)
      .toPromise()
      .then(() => {});

    this.progressIndicatorService.close();
    this.close(true);
    this.snackbar.open('Saved');
  }

  close(reload = false) {
    this.dialogRef.close(reload);
  }

  removeSuiteOccupancy(suiteOccupancy) {
    const index = this.suiteOccupancies.indexOf(suiteOccupancy);
    this.suiteOccupancies.splice(index, 1);
  }

  addSuite() {
    this.suiteOccupancies.push({});
  }
}
