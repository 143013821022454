import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss'],
})
export class PasswordResetDialogComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private dialogRef: MatDialogRef<PasswordResetDialogComponent>,
    private dialog: MatDialog,
    private userService: UserService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.email.setValue(this.data.email);
  }

  close() {
    this.dialogRef.close(null);
  }

  forgotPassword() {
    if (this.email.value) {
      this.userService.forgotPassword(this.email.value).subscribe((data) => {
        this.snackbar.open(data.message);
        this.close();
        if (data.link) {
          this.dialog
            .open(ConfirmationDialogComponent, {
              maxWidth: '500px',
              data: {
                titleBarText: 'Forgot Password',
                hideCancelButton: true,
                descriptionText: `We will now open a new tab directly to your organization's account management site. Once you are done changing your password, come back to this tab and you will be able to login with your new password.`,
                confirmationButtonText: 'Continue',
              },
            })
            .afterClosed()
            .subscribe((isConfirmed) => {
              if (isConfirmed) {
                window.open(data.link);
              }
            });
        }
      });
    }
  }
}
