<form [formGroup]="costCodeFormGroup" class="align-items-center w-100 mb-4 cost-code-selection">
  <div class="d-flex flex-row align-items-center">
    <div class="col pl-0 pr-2 mb-1">
      <!--      <mat-form-field appearance="outline" class="full-width mb-3">-->
      <!--        <input-->
      <!--          type="text"-->
      <!--          placeholder="Choose Item"-->
      <!--          class="font-weight-semibold"-->
      <!--          matInput-->
      <!--          [value]="subCostCodeBudget?.code + ' - ' + subCostCodeBudget?.label"-->
      <!--          [disabled]="true"-->
      <!--        />-->
      <!--        <mat-hint class="font-weight-semibold dkgray">-->
      <!--          {{ subCostCodeBudget?.cost_code?.code || '' }} - -->
      <!--          {{ subCostCodeBudget?.cost_code?.label || '' }}-->
      <!--        </mat-hint>-->
      <!--      </mat-form-field>-->
      <mat-form-field appearance="outline" class="full-width">
        <!-- <mat-select-trigger>
          {{product?.sub_cost_code_budget?.code.value}} | {{product?.sub_cost_code_budget?.label.value}}
        </mat-select-trigger> -->
        <input
          type="text"
          placeholder="Choose Cost Code"
          class="font-weight-semibold"
          required="true"
          matInput
          [matAutocomplete]="cost_code_auto"
          (input)="filterCostCodes($event.target.value)"
          formControlName="subCostCodeTitle"
          (focus)="$event.target.select()"
        />
        <mat-autocomplete autoActiveFirstOption #cost_code_auto="matAutocomplete">
          <div *ngFor="let c of filteredCostCodes | filterCostCodesByBudgetIsEnabled: fiscalYear">
            <h6 class="primary w-100 p-3 m-0 bg-shade-gray">
              <span class="mr-2">{{ c.code }}</span>
              {{ c.label }}
            </h6>
            <mat-option *ngFor="let s of c.sub_cost_codes" (click)="setCostCodeId(s)">
              <div class="d-flex align-items-start">
                <span class="lh-20 mr-2 flex-wrap"> {{ s.code }} - {{ s.label }} </span>
                <span class="ml-auto">
                  <span class="lh-20 bg-ltgray dkgray px-1 br-4 mr-2 cc-badge">
                    {{ s.sub_cost_code_type?.name }}
                  </span>
                  <span
                    class="lh-20 bg-ltgray dkgray px-1 br-4 cc-badge"
                    [ngClass]="s.fiscal_year % 2 === 0 ? 'cc-badge-fy-green' : 'cc-badge-fy-blue'"
                  >
                    FY{{ s.fiscal_year }}
                  </span>
                </span>
              </div>
            </mat-option>
          </div>
        </mat-autocomplete>
        <mat-hint class="font-weight-semibold red" *ngIf="!sub_cost_code_budget_id.value"> Cost code required</mat-hint>
        <mat-hint *ngIf="sub_cost_code_budget_id.value" class="font-weight-semibold dkgray">
          {{ selectedCostCode?.code || '' }} |
          {{ selectedCostCode?.label || '' }}
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-4 pl-0 pr-0 mb-1">
      <mat-form-field appearance="outline" class="w-100 text-right">
        <input matInput placeholder="0.00" pattern="^\d*(\.\d{0,2})?$" formControlName="amount" />
        <mat-icon matPrefix color="primary">attach_money</mat-icon>
      </mat-form-field>
    </div>
    <div *ngIf="allowAdditionalCodes" class="col-1 mb-1">
      <mat-icon matRipple (click)="removeSubCostCode()" class="gray pointer" matSuffix>delete</mat-icon>
    </div>
  </div>
</form>
