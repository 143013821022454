import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ApplicationRole, ResourceType } from 'src/app/enums';
import { AuthService, FileService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { ChangeOrder, ProposalRequest } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-change-order-modal',
  templateUrl: './change-order-modal.component.html',
  styleUrls: ['./change-order-modal.component.scss'],
})
export class ChangeOrderModalComponent implements OnInit {
  @ViewChild('summary_editor', { static: true }) private _summary_editor_component: EditorComponent;
  proposalRequest: ProposalRequest = {};
  currentUser;
  draftChangeOrder: ChangeOrder = {};
  updateChangeOrder: boolean;
  loaders: any = {};
  changeOrderFields;
  files = [];
  linkedFiles = [];
  bidPackages;
  bidPackageFields = ['id', 'trade_name', 'awarded_company_name', 'awarded_company_id'];
  selectedBid;
  public short_description_length = 90;
  public short_description = new FormControl('', [
    Validators.required,
    Validators.maxLength(this.short_description_length),
  ]);

  constructor(
    private projectService: ProjectService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<ChangeOrderModalComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  async ngOnInit() {
    this.currentUser = this.data.currentUser;
    this.proposalRequest = this.data?.proposalRequest ?? {};
    this.changeOrderFields = this.data.changeOrderFields;
    this.updateChangeOrder = !!this.data.changeOrder;
    this.draftChangeOrder = this.data?.changeOrder || {};

    this.bidPackages = await this.projectService
      .getBidPackages(
        [
          {
            type: 'field',
            field: 'project_id',
            value: this.data?.proposalRequest?.project_id?.toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'child_request_id', value: null },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'child_project_id', value: null },
        ],
        this.bidPackageFields
      )
      .toPromise();

    if (this.data.hasOwnProperty('selected_company_id')) {
      this.bidPackages = this.bidPackages.filter((b) => +b.awarded_company_id === +this.data.selected_company_id);
    }

    if (this.updateChangeOrder) {
      this.short_description.setValue(this.draftChangeOrder.short_description);
      this._summary_editor_component.content.setValue(this.draftChangeOrder.summary);
      this.files = this.draftChangeOrder?.files || [];
      this.linkedFiles = this.files.slice() || [];
      this.draftChangeOrder.cost_change_type = this.draftChangeOrder.cost_change < 0 ? 'reduced' : 'additional';
    }
  }

  get summary(): AbstractControl {
    return this._summary_editor_component?.content;
  }

  getInvalidDraftChangeOrderFields(proposalRequest: ProposalRequest): string[] {
    const invalidFields = [];
    if (!proposalRequest?.id) {
      invalidFields.push('Proposal Request ID');
    }

    if (!this.summary.value) {
      invalidFields.push('Summary');
    }

    if (!this.short_description.value) {
      invalidFields.push('Short Description');
    }

    if (this.draftChangeOrder) {
      if (!this.draftChangeOrder.bid_package_id) {
        invalidFields.push('Bid Package Trade');
      }

      if (!this.draftChangeOrder.cost_change_type) {
        invalidFields.push('Cost Change Type');
      }
      if (this.draftChangeOrder.cost_change == null) {
        invalidFields.push('Cost Change Amount');
      }
      if (!this.draftChangeOrder.time_change_amount && this.draftChangeOrder.time_change_amount !== 0) {
        invalidFields.push('Time Adjustment (#)');
      }
      if (!this.draftChangeOrder.time_change_unit) {
        invalidFields.push('Time Adjustment (Duration)');
      }
      if (!this.files?.length) {
        invalidFields.push('Change Order Estimate PDF (scroll down)');
      }
    } else {
      invalidFields.push('Draft Change Order');
    }
    return invalidFields;
  }

  async addChangeOrder() {
    this.loaders.newChangeOrder = true;
    const invalidDraftChangeOrderFields: string[] = this.getInvalidDraftChangeOrderFields(this.proposalRequest);
    if (invalidDraftChangeOrderFields && invalidDraftChangeOrderFields.length === 0) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus(`${this.updateChangeOrder ? 'Updating' : 'Adding'} Change Order`);

      const changeOrderToCreate: ChangeOrder = {
        proposal_request_id: this.proposalRequest.id,
        summary: this.summary?.value || '',
        short_description: this.short_description?.value || '',
        bid_package_id: this.draftChangeOrder.bid_package_id,
        cost_change: !(this.draftChangeOrder.cost_change == null)
          ? (this.draftChangeOrder.cost_change_type.toLowerCase() === 'reduced' ? -1 : 1) *
            this.draftChangeOrder.cost_change
          : null,
        time_change_amount: this.draftChangeOrder.time_change_amount,
        time_change_unit: this.draftChangeOrder.time_change_unit,
      };

      let changeOrder: ChangeOrder = {};
      if (this.updateChangeOrder && this.draftChangeOrder?.id) {
        delete changeOrderToCreate.proposal_request_id;
        delete changeOrderToCreate.bid_package_id;
        changeOrder = await this.projectService
          .updateChangeOrder(this.draftChangeOrder.id, changeOrderToCreate, this.changeOrderFields)
          .toPromise();
      } else {
        changeOrder = await this.projectService
          .createChangeOrder(changeOrderToCreate, this.changeOrderFields)
          .toPromise();
      }

      const changeOrderId = changeOrder.id;
      const removedLinkedFiles = this.linkedFiles.filter(
        (file) => !this.files.find((f) => Number(f.id) === Number(file.id))
      );

      for (const file of removedLinkedFiles) {
        const bridgeFiles = await this.fileService
          .getBridgeFile(file.id, this.draftChangeOrder?.id, ResourceType.ChangeOrder)
          .toPromise();
        for (const bridgeFile of bridgeFiles) {
          if (bridgeFile.id) {
            await this.fileService.unlinkFile(bridgeFile.id).toPromise();
          }
        }
        await this.fileService.removeTags(file.id, [4]).toPromise();
        this.linkedFiles.filter((f) => f.id !== file.id);
      }

      for (const f of this.files) {
        if (!this.linkedFiles?.length || this.linkedFiles.find((file) => file.id !== f.id)) {
          await this.fileService.linkFile(f.id, changeOrderId, ResourceType.ChangeOrder).toPromise();
          await this.fileService.addTags(f.id, [4]).toPromise();
        }
      }

      this.progressIndicatorService.close();
      this.dialogRef.close(true);
    } else {
      this.snackbar.open(
        `Change Order is invalid. Please complete the required fields: ${invalidDraftChangeOrderFields.join(', ')}`
      );
    }
    this.loaders.newChangeOrder = false;
  }

  openUploadModal() {
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.projectService.currentSelectedProjectId,
          allowComment: false,
          maxFiles: 1,
          preSelectedTags: [{ id: 4 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          for (const f of resultData) {
            if (this.files.find((fi) => fi.name === f.name)) {
              this.snackbar.open(`File with name '${f.name}' already exists!`);
            } else {
              this.files.push({ id: +f.file_id, name: f.name });
            }
          }
        }
      });
  }

  async removeFile(file) {
    this.files = this.files?.filter((f) => f.id !== file.id);
  }

  public userIsWorkspaceManager(): boolean {
    return this.authService.currentUserIsOfWorkspaceRole(
      ApplicationRole.WorkspaceManager,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  public userIsProjectManager(): boolean {
    return this.authService.currentUserIsOfProjectRole(
      ApplicationRole.ProjectManager,
      this.projectService.currentSelectedProjectId
    );
  }

  public userIsChiefFacilitiesManagementOfficer(): boolean {
    return this.authService.currentUserIsOfAppRole(ApplicationRole.ChiefFacilitiesManagementOfficer);
  }

  close() {
    this.dialogRef.close(null);
  }
}
