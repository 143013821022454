<mat-chip
  id="file_chip"
  disableRipple
  [matMenuTriggerFor]="file_menu"
  class="dkblue mr-2 pointer p-2"
  [matTooltip]="file.name"
  [matTooltipShowDelay]="300"
  matTooltipPosition="above"
  [selected]="isSelected"
>
  <app-file-thumbnail width="22" height="22" [file]="file" class="mr-2"></app-file-thumbnail>
  <span class="pointer file-name mr-1 lh-30">
    {{ getShortenedFileName() }}
  </span>
  <i class="fas fa-circle-notch fa-spin ltblue ml-2" *ngIf="!isComplete"></i>
  <mat-icon matChipRemove class="ltblue" *ngIf="removable" (click)="remove()">cancel</mat-icon>
  <mat-icon matChipRemove class="ltblue" (click)="download()" *ngIf="!downloading && !removable && isComplete"
    >get_app</mat-icon
  >
  <i class="fas fa-circle-notch fa-spin ltblue ml-2" *ngIf="downloading"></i>
  <!-- TODO SHOW CHECKMARK IF FILE HAS APPROVED, NO EXCEPTIONS, TAGS -->
  <!--  <mat-icon class="green approved-check">check_circle</mat-icon>-->
</mat-chip>
<mat-menu #file_menu="matMenu" xPosition="after">
  <section class="p-1 pb-2" style="width: 200px">
    <div class="row m-0 mb-3 mt-2">
      <div class="col">
        <app-file-thumbnail width="165" height="110" [file]="file"></app-file-thumbnail>
      </div>
    </div>
    <div class="row m-0 mb-0 mt-2">
      <div class="col">
        <h6 class="dkblue m-0 font-weight-semibold text-break">
          {{ file.name }}
        </h6>
      </div>
    </div>
    <div class="row m-0 mt-2">
      <div class="col">
        <span
          *ngFor="let tag of sortedTags"
          class="file-tag ease d-inline-block align-middle font-weight-semibold white pl-2 pr-2 mr-2 mb-2"
          [ngClass]="{
            'bg-dkblue': +tag.tag_parent_id === 0,
            'bg-ltblue': +tag.tag_parent_id !== 0
          }"
        >
          {{ tag.name }}
        </span>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <button (click)="preview(file)" mat-menu-item>
    <mat-icon>find_in_page</mat-icon>
    <span>View</span>
  </button>
  <button (click)="download()" mat-menu-item>
    <mat-icon>get_app</mat-icon>
    <span class="pr-2">Download</span>
  </button>
  <mat-divider></mat-divider>
  <button (click)="renameFile()" mat-menu-item *ngIf="authService.isStaffOnAnyModule && !inMemoryFile">
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>
</mat-menu>
