import { ApprovalStatus, TaskStatus } from '../enums';
import { DateService, FileService, ModalService, ProjectTaskService, TaskActionsService } from '../services';
import { Task, TaskInfo } from '../types';
import { ApprovalProcess } from './approval-process.model';

export class BudgetApprovalProcess {
  _tenant;
  _taskService;

  _staffReview: ApprovalProcess;
  _quoteReviews = {};
  public quoteTasks = {};
  _tenantReview: ApprovalProcess;

  constructor(
    tenant,
    taskService: ProjectTaskService,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService: TaskActionsService
  ) {
    this._taskService = taskService;
    this.loadData(tenant, modalService, fileService, dateService, taskActionsService).then();
  }

  // loads data for the approval tasks
  public async loadData(
    tenant,
    modalService: ModalService,
    fileService: FileService,
    dateService: DateService,
    taskActionsService
  ) {
    this._tenant = tenant;
    const internal_task = await this.getTaskData(this._tenant.budget_approval_task_id);
    const tenant_task = await this.getTaskData(this._tenant.budget_tenant_approval_task_id);

    this._staffReview = new ApprovalProcess(internal_task, modalService, fileService, dateService, taskActionsService);
    this._tenantReview = new ApprovalProcess(tenant_task, modalService, fileService, dateService, taskActionsService);
  }

  // generates text for the peb approval process status
  get statusText(): string {
    if (this._staffReview) {
      if (this._staffReview.approvalStatus === ApprovalStatus.NOT_STARTED && !this.allProductsAssigned) {
        return 'Select Quotes for Budget to Continue';
      } else if (this._staffReview.approvalStatus === ApprovalStatus.NOT_STARTED) {
        return 'Start Staff Review to Continue';
      } else if (this._staffReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL) {
        if (this._staffReview.needsReview) {
          return 'Pending Staff Review';
        } else if (this._staffReview.acceptedNotComplete) {
          return 'Mark Staff Review Complete';
        }
      } else if (this._staffReview.approvalStatus === ApprovalStatus.REJECTED) {
        return 'Staff Review Rejected';
      } else if (this._staffReview.approvalStatus === ApprovalStatus.ACCEPTED && this._tenantReview) {
        if (this.needsTenantApproval) {
          if (this._tenantReview.approvalStatus === ApprovalStatus.NOT_STARTED) {
            return 'Begin Tenant Review to continue';
          } else if (this._tenantReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL) {
            if (this._tenantReview.acceptedNotComplete) {
              return 'Mark Tenant Review Complete';
            } else {
              return 'Pending Tenant Review';
            }
          } else if (this._tenantReview.approvalStatus === ApprovalStatus.ACCEPTED) {
            if (!this.isFinalized) {
              return 'Approve Budget & Award Quotes';
            } else {
              return 'Budget Approved';
            }
          }
        }
      }
    }
    return '';
  }

  get hasStaffTask() {
    return this._staffReview && this._staffReview.hasTask;
  }

  get hasTenantTask() {
    return this._tenantReview && this._tenantReview.hasTask;
  }

  get staffApprovalIcon() {
    if (!this.hasStaffTask) {
      return 'radio_button_unchecked';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get staffApprovalColor() {
    if (!this._staffReview) {
      return '#c5cfe4';
    }
    switch (this._staffReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  get tenantApprovalIcon() {
    if (!this._tenantReview) {
      return 'radio_button_unchecked';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return 'radio_button_unchecked';
      case ApprovalStatus.AWAITING_APPROVAL:
        return 'restore';
      case ApprovalStatus.ACCEPTED:
        return 'check_circle';
      case ApprovalStatus.REJECTED:
        return 'highlight_off';
      default:
        return 'radio_button_unchecked';
    }
  }

  get tenantApprovalColor() {
    if (!this._tenantReview) {
      return '#c5cfe4';
    }
    switch (this._tenantReview.approvalStatus) {
      case ApprovalStatus.NOT_STARTED:
        return '#c5cfe4';
      case ApprovalStatus.AWAITING_APPROVAL:
        return '#c5cfe4';
      case ApprovalStatus.ACCEPTED:
        return '#24D19E';
      case ApprovalStatus.REJECTED:
        return '#f44336 ';
      default:
        return '#c5cfe4';
    }
  }

  // once merged with Austin's branch (which removes uhat as a tenant), then the last two checks will be redundant and impossible to encounter
  // the change will be to remove uhat as an option to add as tenant, and instead change the rep_id to be null if uhat is the tenant
  // this contains all 3 tests for the time being, while waiting for the branch merge
  get needsTenantApproval() {
    return (
      this._tenant.representative_id != null &&
      this._tenant.tenant_name !== 'UHAT' &&
      this._tenant.tenant_name !== 'University Hospitals Authority & Trust'
    );
  }

  get needsStaffApproval() {
    return this._tenant.budget_review_required;
  }

  get arfCanStart() {
    return (
      this.allProductsAssigned &&
      this._tenant.quotes &&
      (!this.needsTenantApproval || this._tenantReview?.canFinalize) &&
      (!this.needsStaffApproval || this._staffReview?.canFinalize) &&
      !this.isFinalized
    );
  }
  get staffCanAddNewRevision() {
    return this._staffReview && this._staffReview.canAddNewRevision && !this.isFinalized;
  }
  get currentStaffReviewer() {
    return this._staffReview && this._staffReview.taskReviewer;
  }
  get tenantReviewAssignedUser() {
    return (
      this._tenantReview &&
      this._tenantReview.getTask && {
        id: this._tenantReview.getTask.assigned_user_id,
        firstName: this._tenantReview.getTask.assigned_user_first_name,
        lastName: this._tenantReview.getTask.assigned_user_last_name,
      }
    );
  }
  get tenantCanStart() {
    return this._tenantReview && this._tenantReview.canStart && !this.isFinalized;
  }
  get staffCanStart() {
    return this._staffReview && this._staffReview.canStart && !this.isFinalized;
  }
  get tenantCanAddNewRevision() {
    return this._tenantReview && this._tenantReview.canAddNewRevision && !this.isFinalized;
  }
  get isFinalized() {
    return this._tenant.budget_status_id === 2;
  }

  get reviewInProgress() {
    return !this.noStaffReviewTasks();
  }
  get canFinalize() {
    return (
      (this.allProductsAreInStock || this.arfTasksAreComplete) &&
      (!this.needsTenantApproval || (this._tenantReview && this._tenantReview.canFinalize)) &&
      (!this.hasStaffTask || this._staffReview.canFinalize) &&
      !this.isFinalized
    );
  }
  get isPending() {
    return this._staffReview && this._staffReview.approvalStatus === ApprovalStatus.AWAITING_APPROVAL;
  }
  get canReset() {
    return (
      ((this._staffReview && this._staffReview.canReset) || (this._tenantReview && this._tenantReview.canReset)) &&
      !this.isFinalized
    );
  }

  get tenantTaskIsComplete() {
    return this._tenantReview?.isTaskComplete;
  }

  get staffTaskIsComplete() {
    return this._staffReview?.isTaskComplete;
  }
  // returns the route for the staff task
  // get getStaffTaskLink(): string {
  //   return this._staffReview.getTaskLink;
  // }

  // returns the route for the tenant task
  get getTenantTaskLink(): string {
    return this._tenantReview.getTaskLink;
  }

  // creates the staff review task for the purchasing tenant, and returns the task id
  public async beginStaffReview(data: TaskInfo): Promise<number> {
    data.isTenant = false;
    return await this._staffReview.createApprovalTask(data);
  }

  public async beginTenantReview(data: TaskInfo): Promise<number> {
    data.isTenant = true;
    return await this._tenantReview.createApprovalTask(data);
  }

  private async getTaskData(task_id: number): Promise<Task> {
    if (!task_id) {
      return null;
    }
    return this._taskService.loadTask(task_id).toPromise();
  }

  public noStaffReviewTasks() {
    const quotes = this._tenant?.quotes;
    let noStaffTasks = true;

    if (quotes) {
      quotes.forEach((quote) => {
        if (quote.arf_approval_task_id) {
          noStaffTasks = false;
        }
      });
    }
    return noStaffTasks;
  }

  get noARFReviewTasksStarted() {
    return !this._tenant?.quotes?.find((quote) => quote.arf_approval_task_id || quote.arf_saved_approval_task_id);
  }

  // Checks to see if all staff tasks have been completed
  get arfTasksAreComplete() {
    const quoteTasks = this._tenant.quotes
      .filter((quote) => quote.arf_approval_task_id)
      .map((quote) => quote.arf_approval_task);
    let areComplete = false;
    if (quoteTasks?.length) {
      areComplete = true;
      quoteTasks.forEach((task) => {
        if (task?.status_id !== TaskStatus.Complete) {
          areComplete = false;
        }
      });

      return areComplete;
    }
  }

  get allProductsAreInStock() {
    return this._tenant.project_products?.length && !this._tenant.project_products.find((pp) => !pp.is_in_stock);
  }

  // Verifies that all products have a corresponding quote/bid
  public get allProductsAssigned() {
    const products = this._tenant.project_products || [];
    let allProductsAssigned = false;

    if (products.length) {
      for (const product of products) {
        if (product.selected_quote_item_id || product.is_in_stock) {
          allProductsAssigned = true;
        } else if (product.is_enabled) {
          allProductsAssigned = false;
          break;
        }
      }
    }
    return allProductsAssigned;
  }
}
