import { WorkOrderStatus } from 'src/app/enums';

export class WorkOrderListFilter {
  public userId?: number;
  public assignedToCurrentUser?: number;
  public requestedByCurrentUser?: number;
  public createdByCurrentUser?: number;
  public statusId?: number = WorkOrderStatus.ACTIVE;
  public workspaceIds?: number[] = [];
  public notAssigned?: boolean;
  public topics?: number[] = [];
  public assignees?: number[] = [];
  public requesters?: number[] = [];
  public searchTerm?: string;
  public buildings?: number[] = [];
  public floors?: number[] = [];
  public tags?: number[] = [];
}
