<div id="as-built-dialog" data-test-id="as-built-dialog" class="as-built-dialog project-modal">
  <div class="row align-items-center mb-2">
    <div class="col">
      <h5 class="dkblue m-0 font-weight-semibold">New As-Built</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <br />
  <form [formGroup]="asBuiltFormGroup">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput type="text" required="true" formControlName="title" />
            <mat-error *ngIf="title.errors?.required">Title is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <app-editor #editor placeholder="description*"></app-editor>
          <mat-error *ngIf="description?.errors?.required && description.touched">Description is required</mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button mat-stroked-button color="primary" (click)="openUploadModal()">Upload Files</button>
          &nbsp;
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip
              *ngFor="let f of newFiles"
              [file]="f"
              [removable]="true"
              (removeEvent)="removeFile(f)"
            ></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button mat-button type="button" class="full-width ltgray" color="accent" (click)="cancel()">Cancel</button>
      </div>
      <div class="col-6">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">
          Submit As-Built
        </button>
      </div>
    </div>
  </form>
</div>
