<app-base-dialog [title]="action + ' document'" (closeDialog)="cancel()"> </app-base-dialog>
<div class="documents-modal">
  <div [formGroup]="form">
    <div class="row mb-4">
      <div class="col">
        <h6 class="primary mb-3">Status</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select placeholder="Status" formControlName="status_id">
            <mat-option *ngFor="let t of companyItemStatusValues" [value]="t">{{ companyItemStatuses[t] }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <h6 class="primary mb-3">Issued Date</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput placeholder="MM/DD/YYYY" [matDatepicker]="issueDatepicker" formControlName="issued_date" />
          <mat-datepicker-toggle matSuffix [for]="issueDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #issueDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <h6 class="primary mb-3">Experiation Date</h6>
        <mat-form-field appearance="outline" class="full-width">
          <input
            matInput
            placeholder="MM/DD/YYYY"
            [matDatepicker]="experiationDatepicker"
            formControlName="expiration_date"
          />
          <mat-datepicker-toggle matSuffix [for]="experiationDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #experiationDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <h6 class="primary mb-3">File</h6>
        <mat-form-field appearance="outline" class="full-width" (click)="addFile()">
          <button mat-icon-button matSuffix>
            <mat-icon>attach_file</mat-icon>
          </button>
          <input type="text" readonly matInput placeholder="Select File" formControlName="file_name" />
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <h6 class="primary mb-3">Description</h6>
        <mat-form-field class="full-width" appearance="outline">
          <textarea matInput placeholder="description" formControlName="description" maxlength="255"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button mat-button type="button" class="full-width gray" color="accent" (click)="cancel()">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button type="submit" color="accent" class="full-width" (click)="save()">Save</button>
    </div>
  </div>
</div>
