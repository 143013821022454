import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserType } from 'src/app/enums';
import { Conversation, Message } from 'src/app/models';
import {
  AuthService,
  MessageService,
  MessagingSystemService,
  ModalService,
  ProjectService,
  UserSearchService,
  UserService,
} from 'src/app/services';
import { UhatFileReference, User } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-messaging-create-conversation-panel',
  templateUrl: './messaging-create-conversation-panel.component.html',
  styleUrls: ['./messaging-create-conversation-panel.component.scss'],
})
export class MessagingCreateConversationPanelComponent implements OnInit {
  public projects: ProjectConstruction[];

  public parentProject: ProjectConstruction;

  public linkedTaskId: number; // Task Id To Link To

  public containsVendorFollowers: boolean;

  public containsStaffFollowers: boolean;

  public containsTenantFollowers: boolean;

  public attachedFiles: File[] = [];

  public followers: User[] = [];

  public toFollowers: User[] = [];

  public replyToId = null;

  private conversationFormGroup = this.formBuilder.group({
    subject: ['', [Validators.required]],
    messageText: ['', [Validators.required]],
  });

  public showLanding = true;

  public followerSearchValue: string;

  public toFollowerSearchValue: string;

  public creatingUser: User;

  @ViewChild('followerInput', { static: false }) followerInput;

  @ViewChild('toFollowerInput', { static: false }) toFollowerInput;

  @ViewChild('messageTextArea', { static: false }) messageTextArea;

  constructor(
    private messagingService: MessagingSystemService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private userSearchService: UserSearchService,
    public authService: AuthService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.creatingUser = this.authService.getLoggedInUser();
    this.userService.getUserById(this.creatingUser.id).subscribe((user) => {
      this.creatingUser.user_type_id = user.user_type_id;
      this.creatingUser.company_id = user.company_id;
      this.creatingUser.department_id = user.department_id;
      this.creatingUser.is_login_enabled = user.is_login_enabled;
    });
    this.projectService.getProjects(['id', 'code', 'title']).subscribe((projects) => {
      this.projects = projects;
    });
    // When a new conversation event fires we should fill out the form data that is given
    this.messagingService.events.openCreateConversationPanelEvent.subscribe((conversation) => {
      if (!conversation) {
        this.clearFields();
        this.showLanding = false;
        return;
      }
      this.selectProjectById(conversation.projectId);
      this.conversationFormGroup.patchValue({
        subject: conversation.subject,
        messageText: conversation.message,
      });
      this.toFollowers = conversation.toFollowers || [];
      this.followers = [this.creatingUser];
      if (conversation.followers) {
        conversation.followers.forEach((f) => this.addFollower(f));
      }
      this.showLanding = false;
      this.linkedTaskId = conversation.linkedTaskId;
      this.attachedFiles = conversation.files || [];
    });
    this.clearFields();
  }

  public selectProjectById(projectId: number) {
    const proj = this.projects.find((p) => p.id === projectId);
    this.parentProject = proj;
  }

  public async createConversation() {
    if (!this.followers || this.followers.length < 2 || !this.conversationFormGroup.valid) {
      return;
    }

    const inviteUsers = this.followers.filter((u) => !u.is_login_enabled);
    const promptInvite = inviteUsers.length > 0;
    if (
      promptInvite &&
      !(await this.modalService.openInviteUserModal({ users: inviteUsers, sendInvite: false }).toPromise())
    ) {
      return;
    }

    // Setup New Conversation Data
    const newConversation = new Conversation();
    newConversation.followers = this.followers;
    newConversation.subject = this.conversationFormGroup.get('subject').value;

    await this.messageService
      .getChannelsByProjectId(this.parentProject.id)
      .toPromise()
      .then((channels) => {
        const foundChannel = channels.find((c) => c.type_id === 1);
        newConversation.channel_id = foundChannel ? foundChannel.id : null;
      });

    if (newConversation.followers.indexOf(this.creatingUser) <= -1) {
      newConversation.followers.push(this.creatingUser);
    }
    if (this.linkedTaskId) {
      newConversation.task_id = this.linkedTaskId;
    }
    const followerIds = this.toFollowers.map((f) => f.id);

    const newMessage = new Message();
    newMessage.to_users = followerIds;
    newMessage.reply_to_id = this.replyToId;
    newMessage.content = this.conversationFormGroup.get('messageText').value.toString();

    // Create our new conversation
    this.messagingService
      .createNewConversationWithMessage(newConversation, newMessage, this.attachedFiles)
      .subscribe(async (returnedConvo) => {
        if (this.authService.isAppAdmin) {
          // create callback entries for currentUser for inviteUsers with the returnedConvoId
          this.userService.sendInvites(inviteUsers, returnedConvo.id, 13);
        }

        this.snackbar.open(`Message Sent!`);
        this.clearFields();
      });
  }

  addFile(files: FileList) {
    this.attachedFiles.push(files[0]);
  }

  public addAllTenantsAsToFollowers() {
    this.userService.getUsersByType(UserType.Tenant).subscribe((users) => {
      users.forEach((user) => {
        this.addFollower(user);
        this.addToFollower(user);
      });
      this.refreshIncludedUserTypes();
    });
  }

  public addAllVendorsAsToFollowers() {
    this.userService.getUsersByType(UserType.Vendor).subscribe((users) => {
      users.forEach((user) => {
        this.addFollower(user);
        this.addToFollower(user);
      });
      this.refreshIncludedUserTypes();
    });
  }

  public addAllTenantsAsFollowers(arr: User[]) {
    this.userService.getUsersByType(UserType.Tenant).subscribe((users) => {
      users.forEach((user) => {
        this.addFollower(user);
      });
      this.refreshIncludedUserTypes();
    });
  }

  public addAllVendorsAsFollowers(arr: User[]) {
    this.userService.getUsersByType(UserType.Vendor).subscribe((users) => {
      users.forEach((user) => {
        this.addFollower(user);
      });
      this.refreshIncludedUserTypes();
    });
  }

  public removeFile(fileToRemove: UhatFileReference) {
    this.attachedFiles = this.attachedFiles.filter((f) => f.name !== fileToRemove.name);
  }

  public getToFollowersString(): string {
    if (this.toFollowers.length <= 0) {
      return 'Select Followers From Above To Send The Initial Message To';
    }
    let str = 'To ';
    this.toFollowers.forEach((fol, i) => {
      if (i > 0) {
        str += ', ';
      }
      str += `${fol.first_name} ${fol.last_name}`;
    });
    return str;
  }

  public addToFollower(follower: User) {
    if (this.toFollowerInput && this.toFollowerInput.nativeElement) {
      this.toFollowerInput.nativeElement.value = null;
    }
    const entryExists = this.toFollowers.find((f) => f.id === follower.id);
    if (!entryExists) {
      this.toFollowers.push(follower);
      this.addFollower(follower);
    }
  }

  public removeToFollower(f: User) {
    this.toFollowers = this.toFollowers.filter((u) => u !== f);
  }

  public addFollower(user: User) {
    if (this.followerInput && this.followerInput.nativeElement) {
      this.followerInput.nativeElement.value = null;
    }
    const entryExists = this.followers.find((f) => f.id === user.id);
    if (!entryExists) {
      this.followers.push(user);
    }
    this.refreshIncludedUserTypes();
  }

  public removeFollower(user: User) {
    this.followers = this.followers.filter((u) => u !== user);
    this.removeToFollower(user);
    this.refreshIncludedUserTypes();
  }

  public searchBoxValueChange(value: string) {
    this.userSearchService.filterUsers(value);
  }

  get filteredUsers(): User[] {
    return this.userSearchService.getFilteredUsers();
  }

  private refreshIncludedUserTypes() {
    this.containsStaffFollowers = false;
    this.containsTenantFollowers = false;
    this.containsVendorFollowers = false;
    this.followers.forEach((follower) => {
      const thisFollowerUserType = follower.user_type_id;
      switch (thisFollowerUserType) {
        case UserType.Staff:
          this.containsStaffFollowers = true;
          break;
        case UserType.Tenant:
          this.containsTenantFollowers = true;
          break;
        case UserType.Vendor:
          this.containsVendorFollowers = true;
          break;
      }
    });
  }

  private clearFields() {
    this.followers = [this.creatingUser];
    this.toFollowers = [];
    this.attachedFiles = [];
    this.parentProject = null;
    this.linkedTaskId = null;
    this.conversationFormGroup.reset();
    this.cdr.detectChanges();
    this.refreshIncludedUserTypes();
  }

  cancelCreatingNewMessage() {
    this.showLanding = true;
    this.clearFields();
  }
  public tabPressed(event) {
    event.preventDefault();
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;

    const htmlEl = this.messageTextArea.nativeElement;
    // set textarea value to: text before caret + tab + text after caret
    htmlEl.value = htmlEl.value.substring(0, start) + '\t' + htmlEl.value.substring(end);

    // put caret at right position again
    event.target.selectionStart = event.target.selectionEnd = start + 1;
  }

  public isToFollower(user: User): boolean {
    return this.toFollowers.find((f) => f.id === user.id) != null;
  }
}
