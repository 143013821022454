import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginator',
})
export class PaginatorPipe implements PipeTransform {
  transform(data: any, pageIndex: number = 0, pageSize: number) {
    const start = pageIndex * pageSize;
    const end = (pageIndex + 1) * pageSize;
    const filtered = data.filter((d: any, i: any) => {
      return i >= start && i < end;
    });
    return filtered;
  }
}
