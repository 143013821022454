import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public isSidenavClosed: boolean;
  sidenavVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.sidenavVisibilityChange.subscribe((value) => {
      this.isSidenavClosed = value;
    });
  }

  toggleSidebarVisibility() {
    this.sidenavVisibilityChange.next(!this.isSidenavClosed);
  }
}
