import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { AuthService, FilterUsersByProjectService, UserService } from 'src/app/services';
import { UserType } from 'src/app/enums';
import { User, Task, APIFilter } from 'src/app/types';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {
  @ViewChild(MatAutocomplete, { static: true }) userAuto: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

  @ViewChild('searchTextInput', { static: true }) searchTextInput: ElementRef;

  private usersArray: User[];

  public task: Task;

  public users$: Observable<User[]>;

  public searchInput: string;

  public searchUserType: UserType = UserType.Staff;

  public companyIds: number[];
  public tenantRepIds: number[];
  public tenantContactIds: number[];

  get userType() {
    return UserType;
  } // Ability to use the enum in the html template

  public mapper(user: User): string {
    return user ? user.first_name + ' ' + user.last_name : '';
  }

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<UserSearchComponent>,
    private userFilterService: FilterUsersByProjectService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    const userFilters: APIFilter[] = !this.data?.task?.assigned_user_id
      ? [{ type: 'field', field: 'is_enabled', value: '1' }]
      : [
          { type: 'operator', value: '(' },
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: this.data.task.assigned_user_id },
          { type: 'operator', value: ')' },
        ];
    this.users$ = this.userService.getUsers(
      userFilters,
      [
        'id',
        'first_name',
        'last_name',
        'user_type_id',
        'company_name',
        'company_id',
        'department_name',
        'is_login_enabled',
        'title',
        'is_enabled',
      ],
      10000
    );
    this.users$.subscribe((users) => {
      this.usersArray = users;
    });

    if (this.data.task) {
      this.task = this.data.task;
    }

    const projectId = this.task ? this.task.project_id : null;

    this.companyIds = await this.userFilterService.getProjectCompanyIds(projectId);
    this.tenantRepIds = await this.userFilterService.getTenantRepIds(projectId);
    this.tenantContactIds = await this.userFilterService.getTenantContactIds(projectId);
  }

  close() {
    this.dialogRef.close(false);
  }

  userSelected(user: User) {
    this.dialogRef.close(user);
  }

  public changeSearchUserType(type: UserType) {
    this.searchUserType = type;
    this.searchInput = this.searchInput;
    this.searchTextInput.nativeElement.focus();
    if (this.searchInput && this.searchInput.length > 0) {
      this.autocomplete.openPanel();
    }
  }

  public clickUserTypeButton(event: Event, type: UserType) {
    event.preventDefault();
    this.changeSearchUserType(type);
    if (this.searchInput && this.searchInput.length > 0) {
      setTimeout(() => this.autocomplete.openPanel(), 40);
    }
  }

  inputKeyUp(event): void {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return;
    }
    // Close the autocomplete if the search is cleared
    if (event.key === 'Backspace' && this.searchInput.length < 1) {
      this.autocomplete.closePanel();
      return;
    } else {
      // Open mat autocomplete if the panel is not open and there is search input
      if (this.searchInput && !this.autocomplete.panelOpen) {
        this.autocomplete.openPanel();
      }
    }
    // Highlight the first item
    this.userAuto._keyManager.setFirstItemActive();
  }

  @HostListener('document:keydown', ['$event'])
  public cycleSearchUserType(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
      return;
    }
    if (event.key === 'Tab') {
      event.preventDefault();
      if (this.searchUserType > UserType.Tenant) {
        this.changeSearchUserType(UserType.Staff);
      } else {
        this.changeSearchUserType(this.searchUserType + 1);
      }
    }
  }

  public assignSelf() {
    if (!this.usersArray) {
      return;
    }

    const myId = this.authService.getLoggedInUser().id;
    const meUser = this.usersArray.find((u) => u.id === myId);

    if (meUser) {
      this.userSelected(meUser);
    }
  }

  clearAssignment() {
    this.userSelected(null);
  }
}
