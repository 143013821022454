export class Message {
  id?: number;
  content?: string;
  conversation_id?: number;
  conversation_subject?: string;
  channel_project_id?: number;
  channel_id?: number;
  channel_type_id?: number;
  to_users?: any;
  conversation_follower_ids?: any;
  read_by_user_ids?: number[];
  reply_to_id?: number;
  reply_to_user_id?: number;
  reply_to_first_name?: string;
  reply_to_content?: string;
  is_event?: boolean;
  files?: any;
  created_by_id?: number;
  created_by_first_name?: string;
  created_by_last_name?: string;
  created_datetime?: any;
}
