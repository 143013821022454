<div *ngIf="!loading && !shownProject">Could not load project details</div>
<div
  id="project-details"
  data-test-id="project-details"
  class="project-details container-fluid p-4"
  [hidden]="!shownProject"
>
  <div class="row align-items-center">
    <div class="col">
      <h4 data-test-id="project-details-headline" class="dkblue font-weight-semibold m-0">
        Project Details <i class="la la-spinner la-spin" *ngIf="loading"></i>
      </h4>
    </div>
    <div class="col-auto text-right">
      <button
        data-test-id="project-details-view-request-button"
        (click)="viewProjectDetails()"
        class="ml-3"
        mat-button
        color="accent"
      >
        <span> View Request </span>
      </button>
      <button
        data-test-id="project-details-download-pdf-button"
        (click)="exportProjectDetails()"
        class="ml-3"
        mat-button
        color="accent"
      >
        <span> Download PDF </span>
        <i class="fas fa-circle-notch fa-spin gray ml-3" *ngIf="downloading"></i>
      </button>
      <button
        data-test-id="project-details-top-edit-button"
        mat-stroked-button
        *ngIf="projectDetailsForm.disabled && isAdmin"
        color="accent"
        class="bg-white ml-3"
        (click)="toggleFormEdit()"
      >
        Edit
      </button>
      <button
        data-test-id="project-details-top-cancel-button"
        mat-button
        *ngIf="unsavedChangesExist || projectDetailsForm.enabled"
        class="ml-3 gray"
        type="button"
        (click)="refresh()"
      >
        Cancel
      </button>
      <!-- <button mat-flat-button class="ml-3" [color]="unsavedChangesExist ? 'success' : null" [disabled]="!unsavedChangesExist"
        (click)="saveProjectDetails()">
        <span *ngIf="!loading">Save Changes</span><span *ngIf="loading"><i class="la la-spinner la-spin"></i></span>
      </button> -->
      <button
        data-test-id="project-details-top-save-changes-button"
        mat-flat-button
        *ngIf="projectDetailsForm.enabled"
        class="ml-3"
        color="success"
        (click)="saveProjectDetails()"
        [disabled]="!unsavedChangesExist"
      >
        <span *ngIf="!loading">Save Changes</span><span *ngIf="loading"><i class="la la-spinner la-spin"></i></span>
      </button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <div class="m-portlet m-0 mb-4 p-0" m-portlet="true">
        <div class="m-portlet__body p-5" [ngClass]="{ 'border-ltblue': unsavedChangesExist }">
          <div class="row">
            <div class="col-lg-12">
              <form [formGroup]="projectDetailsForm" (ngSubmit)="saveProjectDetails()" class="full-width">
                <!-- SECTION -->
                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4 class="dkblue section-title font-weight-semibold">Description</h4>
                    <h6 class="gray section-subtitle">Describe the project</h6>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <!-- ELEMENT -->
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-title-label">Title</mat-label>
                          <input
                            data-test-id="project-detail-title-input"
                            matInput
                            type="text"
                            formControlName="title"
                            (ngModelChange)="unsavedChangesExist = true"
                          />
                          <mat-error data-test-id="project-detail-title-required-error" *ngIf="title?.errors?.required"
                            >Title is required</mat-error
                          >
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-scope-of-work-label">Scope of Work</mat-label>
                          <textarea
                            data-test-id="project-detail-scope-of-work-textarea"
                            matInput
                            formControlName="scopeOfWork"
                            (ngModelChange)="unsavedChangesExist = true"
                          ></textarea>
                          <mat-error
                            data-test-id="project-detail-scope-of-work-required-error"
                            *ngIf="scopeOfWork?.errors?.required"
                            >Scope of Work is required</mat-error
                          >
                        </mat-form-field>
                        <mat-error
                          data-test-id="project-detail-scope-of-work-required-error"
                          *ngIf="scopeOfWork?.errors?.required"
                          >Scope of Work is required</mat-error
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4 class="dkblue section-title font-weight-semibold">Project Priority</h4>
                    <h6 class="gray section-subtitle">Define the Project's Priority Level</h6>
                  </div>
                  <div class="col-lg-9">
                    <div class="row pb-5">
                      <!-- ELEMENT -->
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <!-- <h6 class="section-subtitle">Specify if Project is a Five Year Capital Plan</h6> -->

                        <div class="m-portlet m-0 fyc-fields">
                          <div class="m-portlet__body pl-4 pt-4 pr-4 pb-0">
                            <div class="row">
                              <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="form-element">
                                  <mat-label data-test-id="project-detail-title-label">Reason</mat-label>
                                  <input
                                    data-test-id="fyc-reason-input"
                                    matInput
                                    type="text"
                                    formControlName="fycReason"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12 col-md-4">
                                <mat-form-field appearance="outline" class="full-width">
                                  <mat-label data-test-id="fyc-approved-fiscal-year-label"
                                    >Approved Fiscal Year</mat-label
                                  >
                                  <mat-select
                                    data-test-id="fyc-approved-fiscal-year-select"
                                    formControlName="fycApprovedFiscalYear"
                                    placeholder="Approved Fiscal Year"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  >
                                    <mat-option [value]="null">-</mat-option>
                                    <mat-option
                                      data-test-id="fyc-approved-fiscal-year-option"
                                      *ngFor="let year of years"
                                      [value]="year"
                                    >
                                      {{ year }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 col-md-4">
                                <mat-form-field appearance="outline" class="full-width">
                                  <mat-label data-test-id="fyc-project-year-label">Project Year</mat-label>
                                  <mat-select
                                    data-test-id="fyc-project-year-select"
                                    formControlName="fycProjectYear"
                                    placeholder="Project Year"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  >
                                    <mat-option [value]="null">-</mat-option>
                                    <mat-option
                                      data-test-id="fyc-project-year-option"
                                      *ngFor="let year of years"
                                      [value]="year"
                                    >
                                      {{ year }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 col-md-4">
                                <mat-form-field appearance="outline" class="form-element">
                                  <mat-label data-test-id="fyc-approved-budget-title-label">Approved Budget</mat-label>
                                  <input
                                    data-test-id="fyc-approved-budget-input"
                                    matInput
                                    type="number"
                                    formControlName="fycApprovedBudget"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12 col-md-3">
                                <mat-form-field appearance="outline" class="form-element">
                                  <mat-label data-test-id="fyc-priority-label">5 Year Cap Priority</mat-label>
                                  <input
                                    data-test-id="fyc-priority-input"
                                    matInput
                                    type="number"
                                    formControlName="fycPriority"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  />
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 col-md-3">
                                <mat-form-field appearance="outline" class="full-width">
                                  <mat-label data-test-id="fyc-impact-label">Impact</mat-label>
                                  <mat-select
                                    data-test-id="fyc-impact-select"
                                    formControlName="fycImpact"
                                    placeholder="Impact"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  >
                                    <mat-option [value]="null">-</mat-option>
                                    <mat-option
                                      data-test-id="fyc-impact-option"
                                      *ngFor="let num of getNumberRange(10)"
                                      [value]="num"
                                    >
                                      {{ num }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 col-md-3">
                                <mat-form-field appearance="outline" class="full-width">
                                  <mat-label data-test-id="fyc-likely-label">Likely</mat-label>
                                  <mat-select
                                    data-test-id="fyc-likely-select"
                                    formControlName="fycLikely"
                                    placeholder="Likely"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  >
                                    <mat-option [value]="null">-</mat-option>
                                    <mat-option
                                      data-test-id="fyc-likely-option"
                                      *ngFor="let num of getNumberRange(10)"
                                      [value]="num"
                                    >
                                      {{ num }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-12 col-md-3">
                                <mat-form-field appearance="outline" class="form-element">
                                  <mat-label data-test-id="fyc-matrix-label">Matrix</mat-label>
                                  <input
                                    data-test-id="fyc-matrix-input"
                                    matInput
                                    type="number"
                                    readonly
                                    [value]="fycMatrix"
                                    [disabled]="!isEditing"
                                    (ngModelChange)="unsavedChangesExist = true"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>

                        <mat-form-field appearance="outline" class="full-width mt-5">
                          <mat-label data-test-id="project-detail-priority-label">Project Priority</mat-label>
                          <mat-select
                            data-test-id="project-detail-priority-select"
                            formControlName="priorityLevelId"
                            placeholder="Project Priority"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option
                              data-test-id="project-detail-priority-option"
                              *ngFor="let priority of allPriorities"
                              [value]="priority.id"
                            >
                              {{ priority.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4 class="dkblue section-title font-weight-semibold">Space Details</h4>
                    <h6 class="gray section-subtitle">Provide details about the physical space</h6>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div class="form-element padded">
                          <p class="dkblue font-weight-normal m-0 mb-4">Request Type</p>
                          <mat-radio-group
                            data-test-id="project-detail-request-type-radio-group"
                            formControlName="requestType"
                            class="row"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <div *ngFor="let rt of requestTypes" ngClass="col-lg-2">
                              <mat-radio-button
                                data-test-id="project-detail-request-type-radio-option"
                                [value]="rt.id"
                                >{{ rt.name }}</mat-radio-button
                              >
                            </div>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-building-label">Building</mat-label>
                          <mat-select
                            data-test-id="project-detail-building-select"
                            matNativeControl
                            formControlName="buildingId"
                            (selectionChange)="unsavedChangesExist = true; getFloors(buildingId.value)"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-building-option"
                              *ngFor="let b of buildings"
                              [value]="b.id"
                              >{{ b.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-floor-label">Floor</mat-label>
                          <mat-select
                            data-test-id="project-detail-floor-select"
                            matNativeControl
                            formControlName="floorId"
                            (selectionChange)="unsavedChangesExist = true; getSuites(floorId.value)"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-floor-option"
                              *ngFor="let f of floors"
                              [value]="f.id"
                              >{{ f.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-suite-label">Suite</mat-label>
                          <mat-select
                            data-test-id="project-detail-suite-select"
                            matNativeControl
                            formControlName="suiteId"
                            (selectionChange)="unsavedChangesExist = true; getDepartments(suiteId.value)"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-suite-option"
                              *ngFor="let s of suites"
                              [value]="s.id"
                              >{{ s.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-department-label">Department</mat-label>
                          <mat-select
                            data-test-id="project-detail-department-select"
                            matNativeControl
                            formControlName="departmentId"
                            (selectionChange)="unsavedChangesExist = true"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-department-option"
                              *ngFor="let d of departments"
                              [value]="d.id"
                              >{{ d.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-square-footage-label"
                            >Total Construction SF</mat-label
                          >
                          <input
                            data-test-id="project-detail-square-footage-input"
                            matInput
                            type="number"
                            min="0"
                            formControlName="squareFootage"
                            (ngModelChange)="unsavedChangesExist = true"
                          />
                          <mat-error
                            data-test-id="project-detail-square-footage-required-error"
                            *ngIf="squareFootage?.errors?.required"
                            >Total Construction SF is required</mat-error
                          >
                          <mat-error
                            data-test-id="project-detail-square-footage-min-error"
                            *ngIf="squareFootage?.errors?.min"
                            >Minimum value is 0</mat-error
                          >
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4 class="dkblue section-title font-weight-semibold">Trades</h4>
                    <h6 class="gray m-0 section-subtitle">Select applicable trades.</h6>
                    <label data-test-id="project-detail-trades-label" class="gray m-0 mb-4">
                      * Selected trades will be added by default to the PEB. Please add all that may apply.
                    </label>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-element padded">
                          <div class="row">
                            <div *ngFor="let t of trades" ngClass="col-lg-4 pt-2 pb-2">
                              <mat-checkbox
                                data-test-id="project-detail-trades-checkbox"
                                [checked]="t.is_selected"
                                (change)="toggleTradeSelection(t, $event.checked)"
                                [disabled]="projectDetailsForm.disabled"
                              >
                                <span style="font-size: 1.1rem">
                                  {{ t.name }}
                                </span>
                              </mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4
                      data-test-id="project-detail-section-title-project-roles"
                      class="dkblue section-title font-weight-semibold"
                    >
                      Project Roles
                    </h4>
                    <h6 class="gray section-subtitle">Define the applicable roles</h6>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-cfmo-role-label"
                            >Chief Facilities Management Officer</mat-label
                          >
                          <mat-select
                            data-test-id="project-detail-cfmo-role-select"
                            matNativeControl
                            formControlName="cfmoId"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-cfmo-role-option"
                              *ngFor="let cfmo of allCFMOs"
                              [value]="cfmo.id"
                              >{{ cfmo.first_name }} {{ cfmo.last_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-pm-role-label">Project Manager</mat-label>
                          <mat-select
                            data-test-id="project-detail-pm-role-select"
                            matNativeControl
                            formControlName="projectManagerId"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-pm-role-option"
                              *ngFor="let pm of allProjectManagers"
                              [value]="pm.id"
                              >{{ pm.first_name }} {{ pm.last_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-wm-role-label">Construction Manager</mat-label>
                          <mat-select
                            data-test-id="project-detail-wm-role-select"
                            matNativeControl
                            formControlName="workspaceManagerId"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-wm-role-option"
                              *ngFor="let wm of allWorkspaceManagers"
                              [value]="wm.id"
                              >{{ wm.first_name }} {{ wm.last_name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-architect-role-label">Architect</mat-label>
                          <mat-select
                            data-test-id="project-detail-architect-role-select"
                            matNativeControl
                            formControlName="architectId"
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option [value]="null">-</mat-option>
                            <mat-option
                              data-test-id="project-detail-architect-role-option"
                              *ngFor="let a of allArchitects"
                              [value]="a.id"
                              >{{ a.first_name }} {{ a.last_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" class="form-element">
                          <mat-label data-test-id="project-detail-engineer-role-label">Engineers</mat-label>
                          <mat-select
                            data-test-id="project-detail-engineer-role-select"
                            matNativeControl
                            formControlName="engineerIds"
                            multiple
                            (ngModelChange)="unsavedChangesExist = true"
                          >
                            <mat-option
                              data-test-id="project-detail-engineer-role-option"
                              *ngFor="let e of allEngineers"
                              [value]="e.id"
                              >{{ e.first_name }} {{ e.last_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-section row mb-5 pb-5">
                  <div class="col-lg-3">
                    <h4 class="dkblue section-title font-weight-semibold">Drawings</h4>
                    <h6 class="gray section-subtitle">Upload drawing files</h6>
                  </div>
                  <div class="col-lg-9">
                    <div class="row mb-5 pb-4">
                      <div class="col-12">
                        <p class="dkblue font-weight-semibold m-0 mb-3">Floor Plans</p>
                        <div>
                          <app-file-chip
                            *ngFor="let file of floorPlans"
                            [file]="file"
                            (removeEvent)="removeFloorPlanFile(file)"
                            [removable]="!projectDetailsForm.disabled"
                          ></app-file-chip>
                          <button
                            data-test-id="project-detail-floor-plans-file-upload-item-button"
                            mat-stroked-button
                            color="accent"
                            class="d-block mt-3"
                            type="button"
                            (click)="openFileSelectDialog('floor_plan')"
                            *ngIf="!projectDetailsForm.disabled"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <p class="dkblue font-weight-semibold m-0 mb-3">Misc Files</p>
                        <div>
                          <app-file-chip
                            *ngFor="let file of miscFiles"
                            [file]="file"
                            (removeEvent)="removeMiscFile(file)"
                            [removable]="!projectDetailsForm.disabled"
                          ></app-file-chip>
                          <button
                            data-test-id="project-detail-misc-file-upload-item-button"
                            mat-stroked-button
                            color="accent"
                            class="d-block mt-3"
                            type="button"
                            (click)="openFileSelectDialog('misc')"
                            *ngIf="!projectDetailsForm.disabled"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col text-right">
    <span *ngIf="projectDetailsForm.enabled">
      <button
        data-test-id="project-details-bottom-cancel-button"
        mat-button
        class="gray"
        type="button"
        (click)="refresh()"
      >
        Cancel
      </button>
      <button
        data-test-id="project-details-bottom-save-changes-button"
        mat-flat-button
        class="ml-3"
        color="success"
        (click)="saveProjectDetails()"
        [disabled]="!unsavedChangesExist"
      >
        <span *ngIf="!loading">Save Changes</span
        ><span *ngIf="loading"><i class="refresh la la-refresh fa-spin"></i></span>
      </button>
    </span>
    <button
      data-test-id="project-details-bottom-edit-button"
      mat-stroked-button
      *ngIf="projectDetailsForm.disabled && isAdmin"
      color="accent"
      class="bg-white ml-3"
      (click)="toggleFormEdit()"
    >
      Edit
    </button>
  </div>
</div>

<!-- PDF EXPORT -->

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break">
    <div *ngIf="shownProject" class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>
      <section class="form-header p-4">
        <div class="row">
          <div class="col-3">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label"> Project </label>
                <p class="text-truncate">
                  {{ shownProject.code }} | {{ shownProject.building_code }}-{{ shownProject.floor_code }} |
                  {{ shownProject.title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label"> Location </label>
                <p>
                  {{ shownProject.building_name }}
                  <br />
                  Floor: {{ shownProject.floor_name }}
                </p>
              </div> -->
              <div class="col-4">
                <label class="main-label"> Project Manager </label>
                <p>
                  {{ shownProject.project_manager_first_name + ' ' + shownProject.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label"> Total Construction SF </label>
                <p>
                  {{ shownProject.square_footage }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label"> Completion Date </label>
                <p>
                  <!-- 31 August 2019 -->
                  {{ shownProject.end_date | date: 'dd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label"> Architect </label>
                <p>
                  {{ shownProject.architect_first_name + ' ' + shownProject.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section class="form-title ml-4 mr-4">
        <div class="row">
          <div class="col">
            <h1 class="black font-weight-bold m-0">Project Details</h1>
          </div>
          <div class="col-auto">
            <h1 class="black font-weight-bold m-0"></h1>
          </div>
        </div>
      </section>
      <section class="form-info-section ml-4 mr-4">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <label class="main-label">Project Title</label>
                <p class="m-0">
                  {{ shownProject.title }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="main-label">Scope of Work</label>
                <p class="m-0">
                  {{ shownProject.scope_of_work }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="main-label"> Project Type </label>
                <p>
                  {{ shownProject.request_type_name }}
                </p>
              </div>
              <div class="col">
                <label class="main-label"> SF </label>
                <p>
                  {{ shownProject.square_footage }}
                </p>
              </div>
              <div class="col">
                <label class="main-label"> Dept. </label>
                <p>
                  {{ shownProject.department_name }}
                </p>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col">
                <label class="main-label"> Building </label>
                <p>
                  {{ shownProject.building_name }}
                </p>
              </div>
              <div class="col">
                <label class="main-label"> Floor </label>
                <p>
                  {{ shownProject.floor_name }}
                </p>
              </div>
              <div class="col">
                <!--                <label class="main-label">-->
                <!--                  Dept.-->
                <!--                </label>-->
                <!--                <p>-->
                <!--                  {{ shownProject.department_name }}-->
                <!--                </p>-->
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="main-label"> Trades </label>
                <div class="row">
                  <div class="col-3" *ngFor="let trade of shownProject.selected_trades">
                    <p class="margin-0">
                      {{ trade.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label class="main-label"> Construction Manager </label>
                <p>
                  {{ shownProject.workspace_manager_first_name }}
                  {{ shownProject.workspace_manager_last_name }}
                </p>
              </div>
              <div class="col-3">
                <label class="main-label"> Project Manager </label>
                <p>
                  {{ shownProject.project_manager_first_name }}
                  {{ shownProject.project_manager_last_name }}
                </p>
              </div>
              <div *ngFor="let engineer of shownProject.engineers; index as ei" class="col-3">
                <label class="main-label"> Architect </label>
                <p>{{ shownProject.architect_first_name }} {{ shownProject.architect_last_name }}</p>
              </div>
              <div *ngFor="let engineer of shownProject.engineers; index as ei" class="col-3">
                <label class="main-label"> Engineer {{ ei + 1 }} </label>
                <p>
                  {{ engineer.first_name + ' ' + engineer.last_name }}
                  <br />
                  <small>
                    {{ engineer.company_name }}
                  </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row form-section-divider"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- --------- ------------ --------------- Page 2 ---------- ----------- ------------- -->
      <span *ngIf="projectDetailsHasImages()" class="page-break">
        <!-- <section class="form-title ml-4 mr-4">
          <div class="row">
            <div class="col">
              <h1 class="black font-weight-bold m-0">
                Project Detail Photos
              </h1>
            </div>
            <div class="col-auto">
              <h1 class="black font-weight-bold m-0">

              </h1>
            </div>
          </div>
        </section> -->

        <section class="form-info-section ml-4 mr-4 mt-5">
          <span *ngIf="hasImageFilesWithBase64(floorPlans)">
            <label class="main-label">Floor Plans</label>
            <div class="row mb-4">
              <span *ngFor="let file of floorPlans">
                <div class="col mt-2" *ngIf="file.base64">
                  <img [src]="file.base64" [height]="150" />
                </div>
              </span>
            </div>
          </span>

          <span *ngIf="hasImageFilesWithBase64(miscFiles)">
            <label class="main-label">Misc Photos</label>
            <div class="row mb-4">
              <span *ngFor="let file of miscFiles">
                <div class="col mt-2" *ngIf="file.base64">
                  <img [src]="file.base64" [height]="150" />
                </div>
              </span>
            </div>
          </span>
        </section>
      </span>
    </div>
  </kendo-pdf-export>
</div>
