<div class="avatar-container" [style.width.px]="width" [style.height.px]="height">
  <span
    *ngIf="overlayIcon"
    style="
      position: absolute;
      z-index: 1;
      background-color: white;
      border-color: white;
      border-radius: 50%;
      border: 2px solid #fff;
      right: -7px;
      top: -5px;
      height: 20px;
      width: 20px;
    "
    [style.opacity]="overlayIconOpacity"
    [ngClass]="overlayIconBackground"
    class="pointer profile-icon ease d-block"
  >
    <mat-icon
      style="overflow: hidden; font-size: 14px; line-height: 17px; padding-left: 0.08em"
      [ngClass]="overlayIconColor"
      [matTooltip]="overlayIconTooltip"
      (click)="onIconClick()"
    >
      {{ overlayIcon }}
    </mat-icon>
  </span>
  <img
    *ngIf="isLoaded"
    class="avatar-image d-inline-block align-top"
    [ngClass]="{ 'no-border': noBorder, 'border-active': borderActive }"
    [src]="thumbnailImage$ | async"
  />
  <img
    *ngIf="!isLoaded"
    class="avatar-image avatar-none d-inline-block align-top"
    [ngClass]="{ 'no-border': noBorder }"
    style="color: grey"
    [src]="
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAAxUlEQVR4Ae3RJWJDURRF0ROWZR5HO4+CLbvilMowhZIp+zJzXUzA7pgw35fE/eUvHjXGQ4QlXgF4ZZGI3DDIPfnuGZAdPi4pdoZPVsxTzrSsuKacM1mRoJyorIg3q9GV5TT7s6dkhb9s/H7ZMcQt+W4ZlBsiLPIKwAdLRNQKHsIMs8o272S8s8MyI4RUHwJMsE+SSuLsM0ZA1eBjlk/q8cksPpVHNydYHNKjUrTzgNUD7SrGOi42VIx/XPyqGI68RrWpbp4UthpG0vg67cEAAAAASUVORK5CYII='
    "
  />
</div>
