import { TaskReviewStatus } from '../enums';
import { KeyControlsStatusCount, ReviewChain } from '../types';

export const keyControlAuditCounts = (reviewChain: ReviewChain[]) => {
  return reviewChain?.reduce(
    (statusCount: KeyControlsStatusCount, chain: ReviewChain) => {
      if (+chain.status === TaskReviewStatus.Approved) {
        statusCount.completed_key_controls += 1;
      } else if (+chain.status === TaskReviewStatus.Pending) {
        statusCount.pending_key_controls += 1;
      } else if (+chain.status === TaskReviewStatus.Rejected) {
        statusCount.rejecteted_key_controls += 1;
      }
      return statusCount;
    },
    {
      completed_key_controls: 0,
      pending_key_controls: 0,
      rejecteted_key_controls: 0,
    }
  );
};
