<div *ngIf="!loading" id="cont" (click)="$event.stopPropagation()">
  <mat-icon id="close-btn" (click)="close()">close</mat-icon>
  <div class="mb-3">
    <h2 class="mb-1">CAPX ID Project Report</h2>
    <span class="capx-pill">{{ capx?.capx_id }} | {{ capx?.description }}</span>
  </div>
  <p class="large-text mt-2 mb-2">Below find a list of all projects connected to your CAPX ID</p>
  <div class="d-flex align-items-center">
    <button mat-icon-button color="primary" type="button" class="ml-auto" (click)="paginate(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <h6 class="mx-2 mb-0 gray font-weight-semibold" *ngIf="projectList?.length">
      {{ paginationLabel }}
    </h6>
    <button mat-icon-button color="primary" type="button" (click)="paginate(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div class="capx-budget" *ngFor="let p of projectList | paginator: pageIndex:pageSize">
    <div class="fixed-width">
      <span style="display: inline-flex" class="align-items-center">
        <span class="mr-2">{{ p.code }}</span>
        <i *ngIf="p.status_id === 1" class="material-icons green ml-auto">label_important</i>
        <i *ngIf="p.status_id === 2" class="material-icons ltblue ml-auto">next_plan</i>
        <i *ngIf="p.status_id === 3" class="material-icons red ml-auto">flag</i>
        <i *ngIf="p.status_id === 4" class="material-icons gray ml-auto">done_all</i>
      </span>
      <h3>{{ p.title }}</h3>
      <div class="pills">
        <span class="pill bg-dkblue text-uppercase">{{ p.module_name }}</span>
        <span
          class="pill bgc-blue"
          [ngClass]="{
            'bg-dkred': p.priority.id === 4,
            'bg-red': p.priority.id === 3,
            'bg-ltblue': p.priority.id === 2,
            'bg-gray': p.priority.id === 1
          }"
          >{{ p.priority.name }}</span
        >
        <span *ngIf="p.substatus_name" class="pill bgc-grey">{{ p.substatus_name }}</span>
      </div>
      <div *ngIf="p.updates[0]">
        <b>Latest Update • </b> {{ p.updates[0]?.created_datetime | date: 'MMM d yyyy' }} by
        <span class="blue pointer" (click)="modalService.openUserProfileDialog(p.updates[0]?.created_by.id)"
          >{{ p.updates[0]?.created_by.first_name }} {{ p.updates[0].created_by?.last_name }}</span
        >
      </div>
      <div [innerHtml]="p.updates[0]?.message"></div>
    </div>
    <span (click)="close()" [routerLink]="['/projects/' + p.id]" class="bgc-blue button-pill"
      ><mat-icon>arrow_forward</mat-icon></span
    >
  </div>

  <h2 class="mt-5">Budget Report</h2>
  <div>
    <div class="capx-budget-row">
      <div>
        <h6 class="font-weight-semibold dkblue mb-2">Fiscal Year</h6>
      </div>
      <div>
        <h6>Budget</h6>
      </div>
    </div>
    <div *ngFor="let budget of capxBudgets" class="row capx-budget-row capx-budget-background">
      <div>
        <h4 class="font-weight-semibold ltblue mb-2">{{ budget.fiscal_year }}</h4>
      </div>
      <div>
        <span>
          {{ budget.budget | currency }}
        </span>
      </div>
    </div>
    <div style="text-align: right; margin: 20px">
      <b>SUBTOTAL: {{ subTotal() | currency }}</b>
    </div>
  </div>
</div>
