export enum KeyControlDisplayData {
  BUDGET_FINALIZED_61 = 'Budget Finalized',
  PROJECT_PROPERLY_BID_61 = 'Project Properly Bid',
  VENDOR_CONTRACTS_61 = 'Supplier Contracts Signed / Received',
  FIRE_MARSHAL_61 = 'Fire Marshal / OSDH Approval',
  CONTRACT_COMPLETION_61 = 'Substantial / Contract Completion',
  ALL_INVOICES__PROCESSED_61 = 'All Invoices Processed',
  BUDGET_APPROVED_74 = 'Budget Approved',
  PROJECT_PROPERLY_BID_74 = 'Properly Bid',
  ARFS_FINALIZED_74 = 'ARFs Finalized',
  ALL_INVOICES_PROCESSED_74 = 'Invoices Processed',
  CUSTOMER_SATISFACTION_74 = 'Customer Satisfaction',
  VENDOR_ON_STATE_CONTRACT = 'State Contract',
}
