<div #mainScreen (window:resize)="onResize($event)" id="submittals" data-test-id="submittals" class="pt-4 submittals">
  <div class="position-absolute pl-4 pr-4 overflow-auto" style="width: calc(100% - 400px); height: 100%">
    <div class="d-flex align-items-center mb-4">
      <div class="col">
        <h4 class="m-0 dkblue font-weight-semibold mr-auto">Submittals</h4>
      </div>
      <div class="col-auto text-right d-flex align-items-center">
        <div class="col">
          <button
            mat-button
            type="button"
            color="primary"
            class="pl-3 pr-2 mr-2"
            [matMenuTriggerFor]="selectParentMenu"
          >
            <span class="text-truncate submittal-select-text">
              {{ selectedBidPackage.label }}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #selectParentMenu="matMenu">
            <button mat-menu-item class="dkblue" (click)="selectBidPackage(null, 'All Trades')">All Trades</button>
            <button
              mat-menu-item
              class="dkblue d-flex flex-column py-2"
              *ngFor="let p of bidPackages"
              (click)="selectBidPackage(p.id, p.trade_name)"
            >
              <h6 class="dkblue font-weight-semibold mb-1">
                {{ p.trade_name }}
              </h6>
              <h6 class="dkgray font-weight-normal m-0">
                {{ p.awarded_company_name }}
              </h6>
            </button>
          </mat-menu>
        </div>
        <div class="col">
          <button
            mat-button
            type="button"
            color="primary"
            class="pl-3 pr-2 mr-2"
            [matMenuTriggerFor]="selectVendorMenu"
          >
            <span class="text-truncate submittal-select-text">
              {{ selectedVendor.label }}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #selectVendorMenu="matMenu">
            <button mat-menu-item class="dkblue" (click)="selectVendor(null, 'All Vendors')">All Suppliers</button>
            <button
              mat-menu-item
              class="dkblue"
              *ngFor="let vendor of bidVendors"
              (click)="selectVendor(vendor.id, vendor.name)"
            >
              {{ vendor.name }}
            </button>
          </mat-menu>
        </div>
        <div class="col">
          <button
            mat-button
            type="button"
            color="primary"
            class="pl-3 pr-2 mr-2"
            [matMenuTriggerFor]="selectStatusMenu"
          >
            <span class="text-truncate submittal-select-text">
              {{ selectedStatusLabel }}
            </span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #selectStatusMenu="matMenu">
            <button mat-menu-item class="dkblue" (click)="selectSubmittalStatus(SubmittalStatus.All)">
              All Statuses
            </button>
            <button mat-menu-item class="dkblue" (click)="selectSubmittalStatus(SubmittalStatus.Received)">
              Received
            </button>
            <button mat-menu-item class="dkblue" (click)="selectSubmittalStatus(SubmittalStatus.Approved)">
              Approved
            </button>
          </mat-menu>
        </div>
        <div class="ml-auto">
          <mat-form-field appearance="outline" class="full-width">
            <mat-icon class="dkblue" matPrefix>search</mat-icon>
            <input matInput [placeholder]="'Search'" [formControl]="searchTerm" />
            <mat-icon *ngIf="searchTerm.value" class="gray pointer" (click)="clearSearch()" matSuffix>cancel</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngFor="let bidTypes of displayBidPackages; let last = last">
          <div
            [hidden]="!p.submittal_categories?.length"
            *ngFor="
              let p of bidTypes
                | submittalsFilter: selectedBidPackage:selectedVendor:selectedStatus:searchTerm.value
                | orderByField: 'number':'asc'
            "
            class="m-portlet p-0 mb-4 col-12"
            m-portlet="true"
          >
            <div class="m-portlet__body item-no-hover p-3 d-flex align-items-center">
              <div (click)="toggleSubmittalView(p)" class="d-flex align-items-center mr-2">
                <mat-icon *ngIf="p.show_submittals" class="dkblue pointer mr-2">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="!p.show_submittals" class="dkblue pointer mr-2">keyboard_arrow_right</mat-icon>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <h5
                    (click)="toggleSubmittalView(p)"
                    class="mb-0 bp-trade text-wrap dkblue font-weight-bold hover-u pointer mr-2"
                  >
                    {{ p.number }} - {{ p.trade_name }}
                  </h5>
                </div>
                <h6 class="gray font-weight-semibold m-0">
                  {{ p.square_footage | number }} SF • {{ p.cost_per_sqft | USDollar }}/SF •
                  {{ p.budget_amount | USDollar }} PEB
                </h6>
              </div>
              <div class="d-flex ml-auto align-items-center">
                <button
                  mat-button
                  class="mr-3 {{ statusData[p.id]?.color }}"
                  (click)="openViewTaskDialog(p.submittal_task_id)"
                >
                  <app-profile-thumbnail
                    *ngIf="statusData[p.id]?.userId"
                    [noBorder]="true"
                    class="mr-1 d-inline-block align-middle"
                    [height]="22"
                    [width]="22"
                    [userId]="statusData[p.id]?.userId"
                  ></app-profile-thumbnail>
                  <i *ngIf="statusData[p.id].icon" class="material-icons d-inline-block align-middle status-icons">
                    {{ statusData[p.id]?.icon }}
                  </i>
                  <span>
                    {{ statusData[p.id]?.text }}
                  </span>
                </button>
                <mat-chip-list class="d-inline-block align-middle" *ngIf="p.submittal_files?.length === 1">
                  <app-file-chip *ngFor="let file of p.submittal_files" [file]="file"></app-file-chip>
                </mat-chip-list>
                <div
                  *ngIf="p.submittal_files?.length > 1"
                  class="ltblue font-weight-semibold file-button d-flex align-items-center"
                  [matMenuTriggerFor]="view_files"
                >
                  {{ p.submittal_files.length }} Files
                  <mat-icon class="d-inline-block align-middle pointer">expand_more</mat-icon>
                </div>
                <mat-menu #view_files="matMenu" xPosition="before">
                  <div *ngFor="let file of p.submittal_files">
                    <button mat-menu-item [matMenuTriggerFor]="attachment_menu">
                      <mat-icon>description</mat-icon>
                      <span>{{ file.name }}</span>
                    </button>
                    <mat-menu #attachment_menu="matMenu" xPosition="before">
                      <button (click)="previewFile(file)" mat-menu-item>
                        <mat-icon>find_in_page</mat-icon>
                        <span>View</span>
                      </button>
                      <button (click)="downloadFile(file)" mat-menu-item>
                        <mat-icon>get_app</mat-icon>
                        <span class="pr-2">Download</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div class="m-portlet__body pl-3 py-0 pr-3 border-top border-dkgray" *ngIf="p.show_submittals">
              <section
                [hidden]="c.submittals.length === 0"
                class="section mb-4 pb-2"
                *ngFor="let c of p.submittal_categories"
              >
                <div class="row align-items-center pointer py-2 mb-2 bg-shade-ltblue">
                  <div (click)="c.is_expanded = !c.is_expanded" class="col-auto d-flex align-items-center">
                    <h5 class="dkblue m-0 ml-3 font-weight-semibold">
                      <b>{{ c.code }}</b>
                      <span class="ml-3 mr-3">•</span>
                      {{ c.name }}
                    </h5>
                  </div>
                </div>
                <div>
                  <div *ngFor="let s of c.filtered_submittals" class="ease p-2 pl-4 pr-2 pointer ml-2">
                    <div class="row mb-2">
                      <div class="col-auto pr-0" style="width: 120px">
                        <h5 class="m-0 font-weight-bold dkblue">
                          {{ s.spec.code }}
                        </h5>
                      </div>
                      <div class="col pl-0">
                        <h5 class="m-0 font-weight-semibold">
                          {{ s.spec.name }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" *ngFor="let row of s.display_items; let i = index">
                        <h6 class="primary">{{ !i ? 'Buildout' : 'Closeout' }}</h6>
                        <div *ngFor="let item of row" class="mt-1 text-truncate">
                          <span class="ml-2 mr-1">•</span>
                          {{ item.code }} - {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div *ngIf="!last && bidTypes?.length" class="m-portlet"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="pl-4 pr-4 position-absolute overflow-auto" style="width: 400px; right: 0; height: 100%">
    <section class="mb-5">
      <div class="row">
        <div class="col">
          <div class="row align-items-center mb-3">
            <div class="col">
              <h5 class="m-0 dkblue font-weight-semibold">Submittals Summary</h5>
            </div>
          </div>
          <div class="m-portlet m-0">
            <div class="m-portlet__body item p-3">
              <div class="row">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold">Submittals Received</h6>
                </div>
                <div class="col text-right">
                  <h6 class="m-0 dkblue font-weight-semibold">{{ receivedSubmittals }} / {{ totalSubmittals }}</h6>
                </div>
              </div>
            </div>
            <div class="m-portlet__body item p-3">
              <div class="row">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold">Submittals Approved</h6>
                </div>
                <div class="col text-right">
                  <h6 class="m-0 dkblue font-weight-semibold">{{ approvedSubmittals }} / {{ totalSubmittals }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
