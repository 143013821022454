import { Pipe, PipeTransform } from '@angular/core';

import { UhatFileReference } from 'src/app/types';

@Pipe({
  name: 'filenameFilter',
})
export class FilenameFilterPipe implements PipeTransform {
  transform(files: UhatFileReference[], searchString: string): UhatFileReference[] {
    if (!files || !searchString || searchString.length <= 0) {
      return files;
    }

    searchString = searchString.toLowerCase();
    return files.filter((f) => f.name.toLowerCase().includes(searchString));
  }
}
