export enum COMPARE {
  ANY = 'any',
  FIRST = 'first',
  GREATER = '>',
  GREATER_OR_EQUAL = '>=',
  LESS = '<',
  LESS_OR_EQUAL = '<=',
  EXACT = 'exact',
  NOT_EQUAL = '!=',
}
