<button
  id="assignee-button"
  matRipple
  (click)="openAssignUserModal()"
  [disabled]="!isWorkspaceStaff || task?.is_locked"
  class="m-portlet m-0 p-0 full-width text-left"
>
  <div class="m-portlet__body p-2 item item-last d-flex align-items-center">
    <app-profile-info
      *ngIf="assignedUser && assignedUser?.user_type_id"
      [avatarSize]="40"
      [avatarBorder]="true"
      [truncate]="true"
      [user]="assignedUser"
      [additionalUserFields]="[
        assignedUser?.user_type_id === UserType.Staff
          ? 'title'
          : assignedUser?.user_type_id === UserType.Vendor
          ? 'company_name'
          : 'department_name'
      ]"
      [openProfileDialog]="false"
      class="full-width"
    ></app-profile-info>
    <div *ngIf="!assignedUser" class="d-flex align-items-center">
      <div class="mr-3">
        <mat-icon class="ltgray" style="margin-top: 4px; margin-left: 4px; font-size: 30px; height: 30px; width: 30px">
          account_circle
        </mat-icon>
      </div>
      <p class="m-0 d-flex flex-column lh-18">
        <span class="gray font-weight-semibold"> Assign Task </span>
      </p>
    </div>
  </div>
</button>

<span
  *ngIf="assignedUser && !assignedUser?.is_login_enabled"
  matTooltip="Account Pending"
  matTooltipPosition="above"
  [matTooltipShowDelay]="300"
  class="user-not-found bg-red"
>
  <i class="material-icons white"> priority_high </i>
</span>
