import { Component, OnInit } from '@angular/core';

import { MessagingSystemService, SidenavService } from 'src/app/services';

@Component({
  selector: 'app-messaging-overlay-panel',
  templateUrl: './messaging-overlay-panel.component.html',
  styleUrls: ['./messaging-overlay-panel.component.scss'],
})
export class MessagingOverlayPanelComponent implements OnInit {
  public state: 'createConversation' | 'viewConversation' = 'createConversation';

  constructor(private sidenavService: SidenavService, private messageService: MessagingSystemService) {}

  ngOnInit() {
    this.messageService.events.openCreateConversationPanelEvent.subscribe((e) => {
      this.setRightPanel('createConversation');
      this.state = 'createConversation';
    });
    this.messageService.events.onMessageSelectEvent.subscribe((message) => {
      if (this.state === 'viewConversation') {
        return;
      }
      this.setRightPanel('viewConversation');
    });
  }

  public closeMessagingPanel() {
    this.messageService.messagesPanelIsOpen = !this.messageService.messagesPanelIsOpen;
  }

  public resetView() {
    this.setRightPanel('createConversation');
  }

  public isPanelOpen() {
    return this.messageService.messagesPanelIsOpen;
  }

  public isSidenavOpen() {
    return !this.sidenavService.isSidenavClosed;
  }

  public setRightPanel(view: 'createConversation' | 'viewConversation') {
    this.state = view;
  }
}
