import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Papa } from 'ngx-papaparse';
import heic2any from 'heic2any';
import { FileService, ProgressIndicatorService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnChanges {
  @Input() file: UhatFileReference | any;
  @Output() public fileLoaded = new EventEmitter<{ file: UhatFileReference; blob: any; originalBlob?: any }>();

  private _blob: Blob;
  public originalBlob: Blob;
  private _fileData: UhatFileReference;
  private _fileName: string;
  private _fileUrl: string;

  public csv: any;
  public csvErrors;
  public csvRows = [];

  public docx: any;
  public safeDocxUrl: any;
  public excel: any;
  public imageUrl: any;
  public loading = false;
  public pdfSrc: any;
  public safePdfUrl: any;
  public txt: string;

  constructor(
    private _fileService: FileService,
    private _papa: Papa,
    private _progressIndicatorService: ProgressIndicatorService,
    private _sanitizer: DomSanitizer,
    private _snackbar: MatSnackBar
  ) {}

  async ngOnChanges() {
    // Since this can be used by the component multiple times these fields need to be reset otherwise two different files can be shown at one time if they aren't the same type
    this.pdfSrc = null;
    this.imageUrl = null;
    this.csvRows = [];
    this.docx = null;
    this.txt = null;
    this.excel = null;

    if (this.file?.name?.split('.').pop().toLowerCase() === 'heic' && (this.file?.id || this.file?.file_id)) {
      this.loading = true;
      this._fileData = (await this._fileService.downloadFile(this.file).toPromise()) as UhatFileReference;
      const createdNewBlob = new Blob([new Uint8Array(this._fileData.file.data)], { type: 'image/png' });

      this._blob = (await heic2any({ blob: createdNewBlob, toType: 'image/png', quality: 100 })) as Blob;
      const newFile = new File([this._blob], `${this.file.name.split('.').shift().toLowerCase()}.png`, {
        lastModified: new Date().getTime(),
        type: this._blob.type,
      });

      this._fileUrl = URL.createObjectURL(newFile);
      this.pdfSrc = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
      this.safePdfUrl = this.previewUrl(this.pdfSrc);
      this.loading = false;
    } else if (this.file?.id || this.file?.file_id) {
      this.loading = true;
      this._fileService.downloadFile(this.file).subscribe(async (fileData) => {
        this._fileData = fileData;
        this._fileName = fileData.name;
        const fileExtension = fileData.name.split('.').pop().toLowerCase();
        const isAnImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
        const isPDF = ['pdf'].includes(fileExtension);
        const isExcel = ['xlsx', 'xls'].includes(fileExtension);
        const isCsv = ['csv'].includes(fileExtension);
        const isDocx = ['doc', 'docx'].includes(fileExtension);
        const isTxt = ['txt'].includes(fileExtension);

        if (isAnImage) {
          const type = `image/${fileExtension}`;
          this._blob = new Blob([new Uint8Array(fileData.file.data)], { type });
          this._fileUrl = URL.createObjectURL(this._blob);
          this.imageUrl = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
          this.loading = false;
        } else if (isExcel) {
          const convertedXlsx = await this._fileService.makeFilePreviewable(this.file).toPromise();
          this.originalBlob = new Blob([new Uint8Array(fileData.file.data)]);
          this._blob = new Blob([new Uint8Array(await convertedXlsx.fileBinary.data)], {
            type: 'text/html',
          });
          this._fileUrl = URL.createObjectURL(this._blob);
          const excelSource = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
          this.excel = this.previewUrl(excelSource);
          this.loading = false;
        } else if (isPDF) {
          this._blob = new Blob([new Uint8Array(fileData.file.data)], {
            type: 'application/pdf',
          });
          this._fileUrl = URL.createObjectURL(this._blob);
          this.pdfSrc = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
          this.safePdfUrl = this.previewUrl(this.pdfSrc);
          this.loading = false;
        } else if (isCsv) {
          // refuses to open in new window
          this._blob = new Blob([new Uint8Array(fileData.file.data)], {
            type: 'text/csv',
          });

          this._fileUrl = URL.createObjectURL(this._blob);

          this._papa.parse(this._blob, {
            complete: (results) => {
              this.csvRows = results?.data || [];
              this.csvErrors = results?.errors || [];
            },
          });
          this.csv = this._sanitizer.bypassSecurityTrustResourceUrl(this._fileUrl);
          this.loading = false;
        } else if (isDocx) {
          this.originalBlob = new Blob([new Uint8Array(fileData.file.data)]);
          const convertedDocx = await this._fileService.makeFilePreviewable(this.file).toPromise();
          this._blob = new Blob([new Uint8Array(await convertedDocx.fileBinary.data)], {
            type: 'application/pdf',
          });

          this._fileUrl = URL.createObjectURL(this._blob);
          this.pdfSrc = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
          this.safePdfUrl = this.previewUrl(this.pdfSrc);
          this.docx = true;
          this.loading = false;
        } else if (isTxt) {
          this._blob = new Blob([new Uint8Array(fileData.file.data)], {
            type: 'text/plain',
          });

          this._fileUrl = URL.createObjectURL(this._blob);
          this._blob.text().then((text) => {
            this.txt = text;
            this.loading = false;
          });
        } else {
          this._blob = new Blob([new Uint8Array(fileData.file.data)]);
          this.loading = false;
        }

        const file = {
          name: this._fileName,
          extension: fileExtension,
          path: this._fileUrl,
        };

        this.fileLoaded.emit({ file, blob: this._blob, originalBlob: this.originalBlob });
      });
    } else if (this.file) {
      this._blob = new Blob([new Uint8Array(await this.file.arrayBuffer())], {
        type: 'application/pdf',
      });
      this._fileUrl = URL.createObjectURL(this._blob);
      this.pdfSrc = this._sanitizer.bypassSecurityTrustUrl(this._fileUrl);
      this.safePdfUrl = this.previewUrl(this.pdfSrc);
    }
  }

  public previewUrl(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url.changingThisBreaksApplicationSecurity);
  }
}
