import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import {
  AgendaItemDialogComponent,
  AssignUserDialogComponent,
  DatepickerHeaderComponent,
  MeetingSelectDialogComponent,
  UserProfilePreviewComponent,
  WorkOrderDialogComponent,
  WorkOrderUpdateDialogComponent,
} from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import {
  AuthService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectTaskService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import { User, WorkOrder, WorkOrderUpdate } from 'src/app/types';

@Component({
  selector: 'app-work-order-details-panel',
  templateUrl: './work-order-details-panel.component.html',
  styleUrls: ['./work-order-details-panel.component.scss'],
})
export class WorkOrderDetailsPanelComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Output() deleteLinkedTask = new EventEmitter();
  @Output() openEditWorkOrder = new EventEmitter();
  private _workOrder: WorkOrder = null;
  public meeting_type = 1;

  customHeader = DatepickerHeaderComponent;

  public get canEditWO() {
    return this.workOrder?.module_id && this.authService?.isUserWorkspaceStaff(this.workOrder?.module_id);
  }

  get isOverdue(): boolean {
    return moment(this.workOrder?.due_date).isValid() && moment() > moment(this.workOrder?.due_date);
  }
  @Input() taskData: { project_id: number; created_by_id: number };

  @Input()
  set workOrder(t: WorkOrder) {
    if (t) {
      if (t.updates) {
        t.updates = t.updates.sort(
          (a, b) => moment(b.created_datetime).diff(moment(a.created_datetime)) || b.id - a.id
        );
      }
    } else {
      t = { title: `This work order has been deleted.` };
    }
    this._workOrder = t;
  }

  get workOrder() {
    return this._workOrder;
  }

  get workOrderFollowers() {
    return this.workOrder?.followers ?? [];
  }

  constructor(
    private _dialog: MatDialog,
    private authService: AuthService,
    private linkedTaskService: LinkedTaskService,
    private meetingService: MeetingService,
    private modalService: ModalService,
    private snackbar: MatSnackBar,
    private taskService: ProjectTaskService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private _progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit() {}

  public addWorkOrderUpdate() {
    this._dialog
      .open(WorkOrderUpdateDialogComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {
          work_order_id: this.workOrder.id,
        },
      })
      .afterClosed()
      .subscribe((workOrderUpdate: WorkOrderUpdate) => {
        if (workOrderUpdate?.id) {
          workOrderUpdate.created_by = this.authService.currentUser;
          this.workOrder.updates = [workOrderUpdate, ...this.workOrder.updates];
          if (workOrderUpdate.files) {
            this.workOrder.files = [...this.workOrder.files, ...workOrderUpdate.files];
          }
        }
      });
  }

  public close() {
    this.closeDialog.emit();
  }

  public changeWorkOrderAssignment() {
    if (!this.canEditWO) {
      return;
    }
    if (this.workOrder?.status_id === 3) {
      this.snackbar.open('Unable to change assigned user. Work order is closed.');
    }
    this._dialog
      .open(AssignUserDialogComponent, {
        autoFocus: false,
        width: '560px',
        disableClose: true,
        data: {
          displayLabel: 'User Assignment',
          displayTitle: 'Assign Work Order',
          initialValueId: this.workOrder?.assigned_user_id,
        },
      })
      .afterClosed()
      .subscribe(async (selectedUser: User) => {
        // this modal returns undefined if you hit the X to close, and null if you choose Unassign
        if (selectedUser !== undefined) {
          const userId = selectedUser?.id || null;
          this.workOrderService.updateWorkOrder(this.workOrder.id, { assigned_user_id: userId }).subscribe();
          this._workOrder.assigned_user_id = userId;
          this._workOrder.assigned_user = selectedUser;

          const linkedTask = await this.linkedTaskService.getLinkedWOTaskForWorkOrder(this.workOrder.id).toPromise();
          this.taskService.updateLinkedWOTask({
            ...linkedTask,
            assigned_user_id: userId,
            assigned_user_first_name: selectedUser?.first_name,
            assigned_user_last_name: selectedUser?.last_name,
            status_id: linkedTask.work_order.status_id,
            title: linkedTask.work_order.title,
            due_date: linkedTask.work_order.due_date,
          });
          this.snackbar.open('Changed user Assignment!');
        }
      });
  }

  changeTaskDueDate(dueDate) {
    this.workOrder.due_date = dueDate || null;
    const formattedDueDate = (dueDate && moment(dueDate).format('YYYY-MM-DD')) || null;
    this.workOrderService
      .updateWorkOrder(this.workOrder.id, { due_date: formattedDueDate })
      .subscribe(async (updatedWorkOrderTask) => {
        const linkedTask = await this.linkedTaskService
          .getLinkedWOTaskForWorkOrder(updatedWorkOrderTask.id)
          .toPromise();
        this.taskService.updateLinkedWOTask({
          ...linkedTask,
          assigned_user_id: linkedTask.work_order.assigned_user_id,
          assigned_user_first_name: linkedTask.work_order.assigned_user.first_name,
          assigned_user_last_name: linkedTask.work_order.assigned_user.last_name,
          status_id: linkedTask.work_order.status_id,
          title: linkedTask.work_order.title,
          due_date: dueDate,
        });
        this.snackbar.open('Due Date Updated');
      });
  }

  deactivateTask() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Task',
        headerText: 'Delete Task',
        descriptionText: 'Are you sure that you want to delete this task? This action cannot be undone.',
        confirmationButtonText: 'Delete Task',
        cancelButtonText: 'Never Mind',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.deleteLinkedTask.emit();
        }
      });
  }

  public getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  public openUserProfilePreview(userId) {
    this._dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public createMeeting() {
    this.modalService.createMeeting({
      type_id: this.meeting_type,
      parent_type_id: ResourceType.WorkOrder,
      parent_id: this.workOrder?.id,
    });
  }

  public createMeetingAgendaFromWorkOrder() {
    const meetingSelectDialogRef = this._dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.WorkOrder,
        parent_id: this.workOrder.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        this._dialog
          .open(AgendaItemDialogComponent, {
            width: '480px',
            data: {
              meeting_id: returnedMeeting.id,
              meeting_title: returnedMeeting.title,
              meeting_code: returnedMeeting.code,
            },
          })
          .afterClosed()
          .subscribe((agendaItem) => {
            if (agendaItem) {
              const agendaItemToAdd = {
                meeting_id: returnedMeeting.id,
                description: agendaItem.description,
                duration: agendaItem.duration,
                parent_type_id: ResourceType.WorkOrder,
                parent_id: this.workOrder.id,
              };
              this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
                if (newAgendaItem) {
                  this.snackbar.open('Agenda item added!');
                }
              });
            }
          });
      }
    });
  }

  async editWorkOrder() {
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Loading work order...');
    const workOrder = await this.workOrderService.getWorkOrderById(this.workOrder.id).toPromise();
    this._progressIndicatorService.close();
    this._dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
        data: {
          ...workOrder,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(async (updatedWorkOrder) => {
        if (updatedWorkOrder) {
          const linkedTask = await this.linkedTaskService.getLinkedWOTaskForWorkOrder(this.workOrder.id).toPromise();
          Object.assign(this.workOrder, linkedTask.work_order);
          if (!linkedTask.work_order.assigned_user_id) {
            this.workOrder.assigned_user = null;
          }
          if (!linkedTask.work_order.priority_id) {
            this.workOrder.priority = null;
          }
          this.taskService.updateLinkedWOTask({
            ...linkedTask,
            assigned_user_id: linkedTask.work_order.assigned_user?.id,
            assigned_user_first_name: linkedTask.work_order.assigned_user?.first_name,
            assigned_user_last_name: linkedTask.work_order.assigned_user?.last_name,
            status_id: linkedTask.work_order.status_id,
            title: linkedTask.work_order.title,
            due_date: linkedTask.work_order.due_date,
          });
          this.snackbar.open('Work Order Updated!');
        }
      });
  }

  isStaff() {
    return this.authService.isUserWorkspaceStaff(this.workOrder?.module_id);
  }
}
