import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService, MessageService, MessagingSystemService, ProjectService } from 'src/app/services';
import { Conversation, Message, MessageFilterSettings } from 'src/app/models';
import { User } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-messaging-inbox-display',
  templateUrl: './messaging-inbox-display.component.html',
  styleUrls: ['./messaging-inbox-display.component.scss'],
})
export class MessagingInboxDisplayComponent implements OnInit {
  public conversations = new Observable<Conversation[]>();

  public messages = new Observable<Message[]>();

  public projects: ProjectConstruction[] = [];

  public messageStatusFilter = 'My Messages';

  public messageFilterSettings: MessageFilterSettings = new MessageFilterSettings();

  public refreshingMessages: boolean;

  private currentUser: User;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private newMessageService: MessagingSystemService,
    public authService: AuthService,
    private projectService: ProjectService
  ) {}

  async ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();

    await this.newMessageService.loadInitConversationData().subscribe((data) => {
      this.projects = data.projects;
      // set the messages
      data.messages.forEach((message) => {
        message.read_by_user_ids = MessagingSystemService.convertStringArrayToValidArray(message.read_by_user_ids);
      });
      this.newMessageService.messagesDataStructure.setMessages(data.messages);

      // set the conversations
      this.newMessageService.allLoadedConversations.next(data.conversations);
    });

    this.projectService.getProjects(['id', 'code', 'title']).subscribe((projects) => (this.projects = projects));

    this.messages = this.newMessageService.getAllLoadedMessagesObservable();
    this.messages.subscribe((m) => (this.refreshingMessages = false));

    this.newMessageService.events.updateProjectFilterEvent.subscribe((projectId) => {
      this.messageFilterSettings.filterProjectId = projectId;
      this.forceFilterPipeRefresh();
    });

    this.conversations = this.newMessageService.getConversationsObservable();
  }

  makeFilterSelection(filter: 'My Messages' | 'To Me' | 'Sent' | 'Read' | 'Unread' | 'All Messages') {
    this.messageFilterSettings.myMessages = filter === 'My Messages';
    this.messageFilterSettings.toMe = filter === 'To Me';
    this.messageFilterSettings.sent = filter === 'Sent';
    this.messageFilterSettings.read = filter === 'Read';
    this.messageFilterSettings.unread = filter === 'Unread';
    this.messageStatusFilter = filter;

    this.forceFilterPipeRefresh();
  }

  public onCreateConversationButtonClick() {
    this.newMessageService.events.openCreateConversationPanelEvent.emit();
  }

  public forceFilterPipeRefresh() {
    this.messageFilterSettings = { ...this.messageFilterSettings };
  }

  public updateProjectIdFilter(projectId: number) {
    this.messageFilterSettings.filterProjectId = projectId;
    this.forceFilterPipeRefresh();
  }

  public refreshMessages() {
    this.newMessageService.loadAllMessagesForUser(this.currentUser.id);
    this.refreshingMessages = true;
  }

  public loadMoreMessagesIntoSideView() {
    this.newMessageService.loadAdditionalMessagesForUser(this.currentUser.id, 20);
  }

  /** Does the all messages data structure have more to load. This is the message list on the left side of the messaging display */
  public allMessagesHasMoreToLoad(): boolean {
    return this.newMessageService.allMessagesHasMoreToLoad();
  }
}
