import { BehaviorSubject } from 'rxjs';

export class IdObservableContainer<IdType, ContainedType = any> {
  // Mapping from id to an Observable containing a list of the specified type
  private dataMap = new Map<IdType, BehaviorSubject<ContainedType[]>>();

  /** Get an observable containing the Contained Type of data */
  public getObservableForId(id: IdType) {
    const subject = this.getCreateMapEntry(id);
    return subject.asObservable();
  }

  /** Send an update to the Observable For a Specific Id */
  public pushDataForId(id: IdType, data: ContainedType[]) {
    const subject = this.getCreateMapEntry(id);
    subject.next(data);
  }

  public appendDataForId(id: IdType, data: ContainedType[]) {
    if (!id) {
      return;
    }
    const subject = this.getCreateMapEntry(id);
    subject.next(subject.value.concat(data));
  }

  public removeItemById(id: IdType) {
    if (!id) {
      return;
    }
    const subject = this.getCreateMapEntry(id);
    subject.next(subject.value.filter((i: any) => i.id !== id));
  }

  private getCreateMapEntry(id: IdType): BehaviorSubject<ContainedType[]> {
    if (!this.dataMap.has(id)) {
      this.dataMap.set(id, new BehaviorSubject<ContainedType[]>([]));
    }
    return this.dataMap.get(id);
  }
}
