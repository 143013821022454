import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RequestMethodService } from 'src/app/services';
import { RequestMethod } from 'src/app/types';

@Component({
  selector: 'app-request-method',
  templateUrl: './request-method.component.html',
  styleUrls: ['./request-method.component.scss'],
})
export class RequestMethodComponent implements OnInit {
  @Input() showTitle = true;
  @Input() defaultRequestMethodId: number;
  @Output() ready = new EventEmitter();

  public defaultRequestMethod: RequestMethod;
  public requestMethod = new FormControl(null);
  public requestMethods: RequestMethod[] = [];
  // will be set by the component depending on whats enabled, disabled and old data
  public shownRequestMethods: RequestMethod[] = [];

  constructor(private _requestMethodService: RequestMethodService) {}

  async ngOnInit() {
    this.requestMethods = await this._requestMethodService
      .getRequestMethods(['id', 'name', 'is_default', 'is_enabled', 'icon'])
      .toPromise();

    // get the default request method
    this.defaultRequestMethod = !!this.defaultRequestMethodId
      ? this.requestMethods.find((rm) => rm.id == this.defaultRequestMethodId)
      : this.requestMethods.find((rm) => !!rm.is_default);
    this.ready.emit();
  }
}
