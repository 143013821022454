import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as moment from 'moment';

import { RemindersService } from 'src/app/services';
import { DatepickerHeaderComponent } from 'src/app/components';

@Component({
  selector: 'app-reminder-dialog',
  templateUrl: './reminder-dialog.component.html',
  styleUrls: ['./reminder-dialog.component.scss'],
})
export class ReminderDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public reminder,
    private fb: FormBuilder,
    private remindersService: RemindersService,
    private snackbar: MatSnackBar
  ) {}

  action: string;
  reminderPriorities;
  addDueDate;
  customHeader = DatepickerHeaderComponent;

  reminderFormGroup: FormGroup = this.fb.group({
    description: [this.reminder && this.reminder.description ? this.reminder.description : '', [Validators.required]],
    reminder_date: [
      this.reminder && this.reminder.reminder_datetime ? moment(this.reminder.reminder_datetime).toDate() : null,
      [Validators.required],
    ],
    reminder_time: [
      this.reminder && this.reminder.reminder_datetime ? moment(this.reminder.reminder_datetime).format('HH:mm') : null,
      [Validators.required],
    ],
    due_date: [this.reminder && this.reminder.due_datetime ? moment(this.reminder.due_datetime).toDate() : null],
    due_time: [this.reminder && this.reminder.due_datetime ? moment(this.reminder.due_datetime).format('HH:mm') : null],
    priority_id: [this.reminder && this.reminder.priority_id ? this.reminder.priority_id : 2, [Validators.required]],
  });

  get description() {
    return this.reminderFormGroup.get('description');
  }
  get due_date() {
    return this.reminderFormGroup.get('due_date');
  }
  get due_time() {
    return this.reminderFormGroup.get('due_time');
  }
  get reminder_date() {
    return this.reminderFormGroup.get('reminder_date');
  }
  get reminder_time() {
    return this.reminderFormGroup.get('reminder_time');
  }
  get priority_id() {
    return this.reminderFormGroup.get('priority_id');
  }

  ngOnInit() {
    this.action = this.reminder && this.reminder.id ? 'Edit' : 'Add';

    this.remindersService.getReminderPriorities().subscribe((reminderPriorities) => {
      this.reminderPriorities = reminderPriorities;
    });

    if (this.reminder_date.value) {
      if (!this.reminder_time.value) {
        this.reminder_time.setValue('8:00');
      }
      if (!this.due_date.value) {
        this.due_date.setValue(this.reminder_date.value);
      }
      if (!this.due_time.value) {
        if (this.reminder_time.value) {
          this.due_time.setValue(this.reminder_time.value);
        } else {
          this.due_time.setValue('8:00am');
        }
      }
      if (moment(this.reminder_date.value) < moment()) {
        this.reminder_date.setValue(null);
        this.reminder_time.setValue(null);
      }
    }

    // set the reminder group with the updated values
    this.reminderFormGroup = this.fb.group({
      description: [this.reminder && this.reminder.description ? this.reminder.description : '', [Validators.required]],
      reminder_date: [
        this.reminder && this.reminder.reminder_datetime ? moment(this.reminder.reminder_datetime).toDate() : null,
        [Validators.required],
      ],
      reminder_time: [
        this.reminder && this.reminder.reminder_datetime
          ? moment(this.reminder.reminder_datetime).format('HH:mm')
          : null,
        [Validators.required],
      ],
      due_date: [this.reminder && this.reminder.due_datetime ? moment(this.reminder.due_datetime).toDate() : null],
      due_time: [
        this.reminder && this.reminder.due_datetime ? moment(this.reminder.due_datetime).format('HH:mm') : null,
      ],
      priority_id: [this.reminder && this.reminder.priority_id ? this.reminder.priority_id : 2, [Validators.required]],
    });

    // set whether we have a due date
    this.addDueDate = this.reminder && this.reminder.due_datetime && this.reminder && this.reminder.due_datetime;
  }

  submit(): void {
    if (this.reminderFormGroup.valid) {
      const reminderToReturn = {
        id: this.reminder.id,
        description: this.description.value,
        due_datetime: this.due_date.value
          ? `${moment(this.due_date.value).format('YYYY-MM-DD')}${this.due_time.value ? ` ${this.due_time.value}` : ''}`
          : null,
        reminder_datetime: this.reminder_date.value
          ? `${moment(this.reminder_date.value).format('YYYY-MM-DD')}${
              this.reminder_time.value ? ` ${this.reminder_time.value}` : ''
            }`
          : null,
        priority_id: this.priority_id.value || this.reminder.priority_id,
        parent_type_id: this.reminder.parent_type_id,
        parent_id: this.reminder.parent_id,
      };
      this.dialogRef.close(reminderToReturn);
      this.snackbar.open('Submitted!');
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
