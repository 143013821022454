import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padId',
})
export class PadIdPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    } else {
      return value.toString().padStart(5, '0');
    }
  }
}
