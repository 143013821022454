import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subscription, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { MEETING_STATUS } from 'src/app/enums';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnDestroy, OnChanges {
  @Input() private endDate: string;
  @Input() private startDate: string;

  private _duration: number;
  private _postEndMilliSeconds: number;
  private _preStartMilliSeconds: number;
  private _timerSubscription$: Subscription;

  public percentage = 0;
  public remaining = 0;
  public shouldFlash = false;
  public status = MEETING_STATUS.NOT_SET;
  public statusType = MEETING_STATUS;
  public TEN_MINUTES = 10 * 60 * 1000;
  public FIVE_MINUTES = 5 * 60 * 1000;
  public FIVE_SECONDS = 5 * 1000;

  public get isValidDuration(): boolean {
    return this._duration > 0;
  }

  ngOnDestroy(): void {
    // remove the subscription
    this._timerSubscription$?.unsubscribe();
  }

  ngOnChanges(): void {
    // reset subscriptions
    this._timerSubscription$?.unsubscribe();
    // set defaults
    this._setDefaultTimers();
    // create subscriptions if there is a valid start date and end date
    if (moment(this.startDate).isValid() && moment(this.endDate).isValid() && this._duration > 0) {
      this._subscribe();
    }
  }

  private _setDefaultTimers() {
    this._duration = moment(this.endDate)?.diff(moment(this.startDate));
    this.percentage = 0;
    this.remaining = 0;
    this.shouldFlash = false;
    this.status = MEETING_STATUS.NOT_SET;
    this._preStartMilliSeconds = null;
    this._postEndMilliSeconds = null;
  }

  private _subscribe(): void {
    this._timerSubscription$ = timer(0, 1000)
      .pipe(
        map(() => {
          this._preStartMilliSeconds = moment(this.startDate)?.diff(moment()) || Infinity;
          this._postEndMilliSeconds = moment(this.endDate)?.diff(moment()) || Infinity;

          // before the start of a meeting
          if (this._preStartMilliSeconds > this.TEN_MINUTES) {
            // more than 10 minutes
            this.status = MEETING_STATUS.NOT_SET;
            this.remaining = this._preStartMilliSeconds;
          } else if (this._preStartMilliSeconds >= 0) {
            // 10 minutes to 0
            this.status = MEETING_STATUS.ABOUT_TO_START;
            this.remaining = this._preStartMilliSeconds;
          } else if (this._preStartMilliSeconds < 0 && this._postEndMilliSeconds >= this.FIVE_MINUTES) {
            // more than 5 minutes
            this.status = MEETING_STATUS.MEETING;
            this.remaining = this._postEndMilliSeconds;
          } else if (
            this._preStartMilliSeconds < 0 &&
            this._postEndMilliSeconds >= 0 &&
            this._postEndMilliSeconds < this.FIVE_MINUTES
          ) {
            // 5 minutes to the end
            this.status = MEETING_STATUS.ABOUT_TO_END;
            this.remaining = this._postEndMilliSeconds;
          } else if (this._postEndMilliSeconds < 0 && this._postEndMilliSeconds >= -this.TEN_MINUTES) {
            // ended but less than 10 minutes
            this.status = MEETING_STATUS.ENDED;
            this.remaining = 0;
          } else {
            this.status = MEETING_STATUS.NOT_SET;
          }

          // set the duration percentage
          if (this._preStartMilliSeconds > 0) {
            this.percentage = 100;
          } else if (this._postEndMilliSeconds < 0) {
            this.percentage = 0;
          } else {
            this.percentage = Math.floor((this.remaining / this._duration) * 100);
          }

          // set the flash
          if (this._postEndMilliSeconds < 0 && this._postEndMilliSeconds > -this.FIVE_SECONDS) {
            // ended but for only 5 seconds
            this.shouldFlash = true;
          } else {
            this.shouldFlash = false;
          }

          return this._postEndMilliSeconds > -this.TEN_MINUTES; // stop subscription if the ending is more than 10 minutes
        }),
        takeWhile((value: boolean) => value)
      )
      .subscribe();
  }

  public get title(): string {
    switch (this.status) {
      case this.statusType.ABOUT_TO_START:
        return 'Meeting Starts In';
      case this.statusType.ENDED:
        return "Time's Up!";
      case this.statusType.MEETING:
      case this.statusType.ABOUT_TO_END:
        return 'Meeting Time Remaining';
      default:
        return '';
    }
  }
}
