import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, AuthService, HandleErrorService } from 'src/app/services';
import { APIFilter, Notification, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private authService: AuthService,
    private apiFilterService: ApiFilterService
  ) {}

  public notificationPanelIsOpen = false;
  host: string = environment.serviceHost;
  notificationsUrl = `${this.host}/api/v1/notifications`;
  public unreadNotificationCount;

  getNotificationById(notificationId, fields: string[]): Observable<Notification> {
    return this.http.get(`${this.notificationsUrl}/${notificationId}?fields=${fields}`).pipe(
      map((result: ServiceResponse) => {
        const notification: Notification = result.data.notification;
        return notification[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getNotifications(
    apiFilters: APIFilter[],
    fields: string[],
    limit?: number,
    cursor?: string
  ): Observable<ServiceResponse> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.notificationsUrl}?fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&order=desc${limit ? `&limit=${limit}` : ''}${cursor ? `&cursor=${cursor}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateNotification(notificationId, notificationToUpdate, fields?: string[]): Observable<Notification> {
    const body = notificationToUpdate;
    return this.http
      .put(`${this.notificationsUrl}/${notificationId}?${fields ? `fields=${fields.join(',')}` : ''}`, body)
      .pipe(
        map((result: ServiceResponse) => {
          const notification: Notification = result.data.notification;
          return notification;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  markAllNotificationsAsRead(myActions): Observable<void> {
    return this.http.put(`${this.notificationsUrl}/read-all?${myActions ? 'type=my_actions' : ''}`, null).pipe(
      map((result: ServiceResponse) => {}),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateUnreadNotificationCount() {
    const currentUser = this.authService.getLoggedInUser();
    if (currentUser && currentUser.id) {
      return this.http.get(`${this.notificationsUrl}?filter=for_user_id=${currentUser.id},is_read=0&limit=1`).pipe(
        map((result: ServiceResponse) => {
          this.unreadNotificationCount = result.count || 0;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
    } else {
      return of(null);
    }
  }
}
