<app-base-dialog [title]="'Project Key Controls'" (closeDialog)="close()" [draggable]="false"></app-base-dialog>
<div class="d-flex flex-column align-items-center view-key-controls-dialog p-5 mx-auto" *ngIf="!dataLoaded">
  <mat-spinner color="accent" [diameter]="40"></mat-spinner>
</div>
<div class="d-flex flex-column view-key-controls-dialog" *ngIf="dataLoaded">
  <h3 class="dkblue">
    {{ projectTitle }}
  </h3>
  <!-- COMMENTING OUT SCOPE OF WORK BECAUSE WHEN LONG, HIDES PART OF DIALOG, AND WE MAY NOT NEED IT HERE - ADAM - 03/2024 -->
  <!-- <p *ngIf="project?.scope_of_work" class="dkgray mb-4" [innerHTML]="project?.scope_of_work"></p> -->
  <!-- <h5 class="dkblue font-weight-semibold mb-4">Key Controls</h5> -->
  <mat-horizontal-stepper labelPosition="bottom" [selectedIndex]="stepperIndex" (selectionChange)="changeIndex($event)">
    <ng-template matStepperIcon="edit" let-index="index">
      <app-key-control-icon-badge [task]="keyControls[index].task"></app-key-control-icon-badge>
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
      <app-key-control-icon-badge [task]="keyControls[index].task"></app-key-control-icon-badge>
    </ng-template>
    <mat-step class="p-0" *ngFor="let kc of keyControls; let i = index">
      <ng-template matStepLabel class="text-wrap"> {{ kc.key_control_template?.abbreviated_name }}</ng-template>
      <div class="d-flex flex-row align-items-top pt-5">
        <div class="col-7">
          <div class="m-portlet">
            <div class="m-portlet__body d-flex flex-column p-4">
              <div class="d-flex flex-column text-center align-items-center mb-3">
                <!-- ICON FOR IF KEY CONTROL IS COMPLETE OR NOT -->
                <!-- RADIO_BUTTON_UNCHECKED IF NOT COMPLETE -->
                <mat-icon *ngIf="kc.task.status_id === 3" class="green my-2 large-icon mb-3">check_circle</mat-icon>
                <mat-icon *ngIf="kc.task.status_id !== 3" class="my-2 gray large-icon mb-3"
                  >radio_button_unchecked</mat-icon
                >
                <!-- KEY CONTROL TITLE -->
                <h4 class="dkblue font-weight-semibold mb-1">
                  {{ kc.key_control_template?.key_control_name }}
                </h4>
                <!-- DATE COMPLETED W/ NAME -->
                <p class="dkgray mb-1" *ngIf="kc.task.status_id === 3">
                  Completed {{ formatDate(kc.completed_datetime) }} by
                  <span class="ltblue hover-u pointer" (click)="openUserProfilePreview(kc.completed_by?.id)">
                    {{ kc.completed_by?.first_name }} {{ kc.completed_by?.last_name }}</span
                  >
                </p>
                <!-- ROUTE TO PARENT PAGE -->
                <div class="d-flex align-items-center mb-3">
                  <a
                    [routerLink]="['/projects/' + projectId + '/peb']"
                    target="_blank"
                    class="
                      mx-3
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="kc.key_control_template.display_data === KeyControlTypes.BUDGET_FINALIZED_61"
                  >
                    Go to PEB
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/bids']"
                    target="_blank"
                    class="
                      mx-3
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="
                      kc.key_control_template.display_data === KeyControlTypes.PROJECT_PROPERLY_BID_61 ||
                      kc.key_control_template.display_data === KeyControlTypes.VENDOR_CONTRACTS_61
                    "
                  >
                    Go to Bids
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/purchasing']"
                    target="_blank"
                    class="
                      mx-3
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="
                      kc.key_control_template.display_data === KeyControlTypes.BUDGET_APPROVED_74 ||
                      kc.key_control_template.display_data === KeyControlTypes.ARFS_FINALIZED_74
                    "
                  >
                    Go to Purchasing
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/purchasing']"
                    target="_blank"
                    class="
                      mx-3
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="kc.key_control_template.display_data === KeyControlTypes.PROJECT_PROPERLY_BID_74"
                  >
                    Go to Purchasing
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/invoices']"
                    target="_blank"
                    class="
                      mx-3
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="
                      kc.key_control_template.display_data === KeyControlTypes.ALL_INVOICES__PROCESSED_61 ||
                      kc.key_control_template.display_data === KeyControlTypes.ALL_INVOICES_PROCESSED_74
                    "
                  >
                    Go to Invoices
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/tasks/' + kc.task_id]"
                    target="_blank"
                    class="
                      mx-2
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="isTaskBasedControl && kc.task_id"
                  >
                    Go to Task
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                  <a
                    [routerLink]="['/projects/' + projectId + '/tasks/' + kc.task_id]"
                    target="_blank"
                    class="
                      mx-2
                      ltblue
                      pointer
                      d-flex
                      align-items-center
                      font-weight-semibold
                      text-decoration-none
                      hover-u
                    "
                    *ngIf="!isTaskBasedControl && kc.task?.id"
                  >
                    Go to Task
                    <!-- <i class="material-icons ml-1"> arrow_right_alt </i> -->
                  </a>
                </div>
              </div>
              <div
                class="d-flex flex-column key-control-data"
                *ngIf="kc.key_control_template.display_data !== KeyControlTypes.PROJECT_PROPERLY_BID_61"
              >
                <div
                  class="d-flex align-items-center border border-gray br-5 p-3 mb-3"
                  *ngFor="let item of kc.data; let last = last"
                >
                  <h5 class="dkblue mb-0 font-weight-semibold mr-auto text-truncate">
                    {{ item.title }}
                  </h5>
                  <mat-chip-list class="d-inline-block align-middle ml-3" *ngIf="item.files?.length">
                    <app-file-chip *ngFor="let file of item.files" [file]="file"></app-file-chip>
                  </mat-chip-list>
                  <h5
                    class="dkblue mb-0 font-weight-semibold text-truncate text-right"
                    *ngIf="item.amount || item.amount === 0"
                  >
                    {{ item.amount || 0 | USDollar: 2:true }}
                  </h5>

                  <div class="text-right d-flex flex-row" *ngIf="item.isQouteDetail">
                    <h5 class="dkblue mb-0 mr-1">
                      {{ item?.numberOfQuotes || 0 }} {{ item.numberOfQuotes === 1 ? 'Bid' : 'Bids' }}
                    </h5>
                    <h5 class="dkblue mb-0" *ngIf="item.isQouteDetail">
                      -
                      {{ item?.awardedTotal || 0 | USDollar: 2:true }}
                    </h5>
                  </div>

                  <h5
                    class="mb-0 font-weight-semibold text-truncate text-right"
                    *ngIf="item.linked_request?.id || item.linked_project?.id"
                  >
                    <a
                      target="_blank"
                      [routerLink]="
                        item.linked_project?.id
                          ? '/projects/' + item.linked_project.id
                          : '/requests/' + item.linked_request.id
                      "
                      >{{ item.linked_project?.id ? 'PJ-' + item.linked_project?.code : item.linked_request?.code }}</a
                    >
                  </h5>
                </div>
              </div>
              <div
                class="d-flex flex-column key-control-data"
                *ngIf="kc.key_control_template.display_data === KeyControlTypes.PROJECT_PROPERLY_BID_61"
              >
                <div class="d-flex align-items-center border border-gray br-5 p-3 mb-3" *ngFor="let bp of kc.data">
                  <h5 class="dkblue mb-0 font-weight-semibold mr-auto text-truncate d-flex align-middle">
                    {{ bp.number || '' }} {{ bp.number ? '-' : '' }} {{ bp.trade_name }}
                    <mat-icon
                      *ngIf="bp.awarded_company_id && !bp.child_request?.id && !bp.child_project?.id"
                      class="green ml-1"
                      style="height: 20px; font-size: 20px"
                    >
                      verified
                    </mat-icon>
                    <mat-icon
                      *ngIf="bp.child_request?.id || bp.child_project?.id"
                      class="ltblue ml-1"
                      style="height: 20px; font-size: 20px"
                    >
                      {{ bp.child_project?.id ? 'assignment_turned_id' : 'assignment_late' }}
                    </mat-icon>
                  </h5>
                  <div class="text-right d-flex flex-row">
                    <div class="dkblue mr-1" style="font-size: 14px">
                      {{ bp?.bids?.length || 0 }} {{ bp?.bids?.length === 1 ? 'Bid' : 'Bids' }}
                    </div>
                    <div class="dkblue" style="font-size: 14px" *ngIf="bp.awarded_company_id">
                      -
                      {{
                        (bp.child_request?.id || bp.child_project?.id
                          ? bp.child_project?.budget_data?.awardedBidTotal || 0
                          : bp.awarded_amount
                        ) | USDollar: 2:true
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="text-center mt-4" *ngIf="!kc.data">
                <mat-spinner *ngIf="!kc.data" class="mx-auto" color="accent" [diameter]="30"></mat-spinner>
              </h4>
              <p class="gray text-center mt-4" *ngIf="kc.data && !kc.data.length">No Data to Display</p>
            </div>
          </div>
        </div>
        <div class="col-5" *ngIf="kc.task">
          <!-- PULL IN REVIEW UI FROM ARF REVIEW DIALOG -->
          <app-review-sidebar *ngIf="kc.task" [task]="kc.task" (closeDialog)="submitApproval($event)">
          </app-review-sidebar>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
