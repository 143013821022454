import { Component, OnInit, Input } from '@angular/core';

import { Phase } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
})
export class ProjectSelectComponent implements OnInit {
  @Input() projects: ProjectConstruction[];

  selectedProject: ProjectConstruction;
  selectedPhases: Phase[] = [];

  constructor() {}

  ngOnInit() {}

  selectProject(project) {
    if (!this.selectedProject || this.selectedProject.id !== project.id || this.selectedPhases.length === 0) {
      this.selectedPhase(project.phases.filter((phase) => phase.id === project.current_phase_id)[0], project);
      this.selectedProject = project;
    }
  }

  selectedPhase(phase, project) {
    if (!this.selectedProject || this.selectedProject.id !== project.id) {
      this.selectedProject = project;
      this.selectedPhases = [phase];
    } else {
      if (!this.isSelected(phase)) {
        this.selectedPhases = this.selectedPhases.concat(phase);
      } else {
        this.selectedPhases.splice(this.selectedPhases.indexOf(phase), 1);
      }
    }
  }

  getProjectText(project) {
    return `PRJ ${project.code}: ${project.title} (${project.current_phase_name})`;
  }

  get visibleTasks() {
    return this.selectedProject && this.selectedProject.tasks
      ? this.selectedProject.tasks.filter((task) =>
          this.selectedPhases.map((phase) => phase.id).includes(task.phase_id)
        )
      : [];
  }

  isSelected(phase) {
    return this.selectedPhases.map((p) => p.id).includes(phase.id);
  }

  // stops the parent click event
  public stopPropagation(event) {
    event.stopPropagation();
  }
}
