import { TaskReviewStatus, TaskAccessoryType } from 'src/app/enums';
import { ReviewChain, TaskAccessoryData } from 'src/app/types';
/**
 * Utility class for easily creating data of different types for use as Task Accessories
 */
export class TaskAccessoryDataFactory {
  /**
   * Create Task Accessory Data for Invoice Viewing
   */
  public static createInvoiceData(
    invoiceId: number,
    requiresReview: boolean,
    reviewIds: TaskAccessoryData['reviewChain']
  ): TaskAccessoryData {
    return {
      type: TaskAccessoryType.Invoice,
      parentId: invoiceId,
      isReviewItem: requiresReview,
      reviewChain: reviewIds,
    };
  }

  public static createPebApprovalData(tenantId: number): TaskAccessoryData {
    return { type: TaskAccessoryType.PEB, tenantId };
  }

  // copied from the original (so as to not break the original function as it is used in one place). hopefully this function replaces it at some point
  public static createPebReviewApprovalData(
    requiresReview: boolean,
    reviewIds: TaskAccessoryData['reviewChain'],
    parentId = null
  ): TaskAccessoryData {
    return { type: TaskAccessoryType.PEB, parentId, isReviewItem: requiresReview, reviewChain: reviewIds };
  }

  // copied from the original (so as to not break the original function as it is used in one place). hopefully this function replaces it at some point
  public static createPunchlistReviewApprovalData(
    requiresReview: boolean,
    reviewIds: TaskAccessoryData['reviewChain']
  ): TaskAccessoryData {
    return {
      type: TaskAccessoryType.Punchlist,
      isReviewItem: requiresReview,
      reviewChain: reviewIds,
    };
  }

  public static createChangeOrderData(changeOrderId: number, requiresReview: boolean): TaskAccessoryData {
    return {
      type: TaskAccessoryType.ChangeOrder,
      parentId: changeOrderId,
      isReviewItem: requiresReview,
    };
  }

  public static createCbReviewApprovalData(requiresReview: boolean, reviewIds: ReviewChain[]): TaskAccessoryData {
    return { type: TaskAccessoryType.CB, isReviewItem: requiresReview, reviewChain: reviewIds };
  }

  public static createBudgetReviewApprovalData(
    requiresReview: boolean,
    reviewIds: ReviewChain[],
    parentId
  ): TaskAccessoryData {
    return { type: TaskAccessoryType.Budget, isReviewItem: requiresReview, reviewChain: reviewIds, parentId };
  }

  public static createArfReviewApprovalData(
    isReviewItem: boolean,
    reviewChain: ReviewChain[],
    parentId: number,
    reviewCreator: number
  ): TaskAccessoryData {
    return { type: TaskAccessoryType.Arf, isReviewItem, parentId, reviewCreator, reviewChain };
  }

  public static createKeyControlsApprovalData(
    parentId: number,
    reviewCreator: number,
    reviewChain = [],
    isReviewItem = true
  ): TaskAccessoryData {
    return {
      type: TaskAccessoryType.KeyControls,
      isReviewItem,
      parentId,
      reviewCreator,
      reviewChain,
    };
  }

  public static createSubmittalsData(parentId: number, isReviewItem = false): TaskAccessoryData {
    return {
      type: TaskAccessoryType.Submittals,
      isReviewItem,
      parentId,
    };
  }

  public static createTenantReviewData(type: number, parentId?): TaskAccessoryData {
    return {
      type,
      isReviewItem: false,
      parentId,
    };
  }
  public static createAddPCOData(proposalRequestId: number): TaskAccessoryData {
    return { type: TaskAccessoryType.ProposalRequest, parentId: proposalRequestId };
  }
}
