import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { ProjectListFilter } from 'src/app/workspaces/construction/models';
import { ProjectDisplayData } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private progressIndicatorService: ProgressIndicatorService,
    public authService: AuthService
  ) {}

  public allProjects: ProjectDisplayData[];

  public filter: ProjectListFilter = new ProjectListFilter();

  public projectSortDirection: 'asc' | 'desc' = 'desc';

  public projectFieldToSortBy: string;

  private projectFields = [
    'id',
    'code',
    'title',
    'status',
    'building_name',
    'building_code',
    'floor_code',
    'floor_name',
    'priority_id',
    'priority_name',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'current_phase_name',
    'change_orders{cost_change,status_id}',
    'bid_packages{trade_allows_bids,awarded_amount,budget_amount}',
    'peb_items{peb_id,subtotal}',
    'tenants{selected_peb_id}',
    'square_footage',
  ];

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;

  ngOnInit() {
    setTimeout(() => {
      this.refresh();
      this.getDivWidth();
      const preferences = JSON.parse(localStorage.getItem('preferences'));
      this.filter.byUserId = preferences ? preferences.filter_project_by_user : null;
      this.filter.fiveYearCapitalPlan = preferences ? preferences.fiveYearCapitalPlan : null;
      this.projectSortDirection = preferences ? preferences.sort_project_order : null;
      this.projectFieldToSortBy = preferences ? preferences.sort_project_by_field : null;
      if (!this.projectFieldToSortBy) {
        this.updateSortByField('id');
      }
    });
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    if (this.divWidth > 800) {
      this.showDesktop = true;
      this.showIpad = false;
    } else {
      this.showDesktop = false;
      this.showIpad = true;
    }
  }

  public async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving Projects..');
    this.allProjects = await this.projectService.getProjects(this.projectFields).toPromise();
    this.getProjectBudgets();
    this.progressIndicatorService.close();
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff();
  }

  public updateSortByField(field: string) {
    if (field === this.projectFieldToSortBy) {
      this.projectSortDirection = this.projectSortDirection === 'desc' ? 'asc' : 'desc';
      localStorage.setItem(
        'preferences',
        JSON.stringify(this.addToPreferences('sort_project_order', this.projectSortDirection))
      );
    }
    this.projectFieldToSortBy = field;
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('sort_project_by_field', this.projectFieldToSortBy))
    );
  }

  public updateSearchString(s: string) {
    this.filter.searchString = s;
    this.updateFilter();
  }

  public changeFilterStatus(filterStatusId: number) {
    this.filter.statusId = filterStatusId;
    this.updateFilter();
  }

  public filterByCurrentUser() {
    this.filter.byUserId
      ? (this.filter.byUserId = null)
      : (this.filter.byUserId = this.authService.getLoggedInUser().id);
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('filter_project_by_user', this.filter.byUserId))
    );
    this.updateFilter();
  }

  public filterByFiveYearCapitalPlan() {
    this.filter.fiveYearCapitalPlan
      ? (this.filter.fiveYearCapitalPlan = null)
      : (this.filter.fiveYearCapitalPlan = true);
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences('fiveYearCapitalPlan', this.filter.fiveYearCapitalPlan))
    );
    this.updateFilter();
  }

  public async getProjectBudgets() {
    for (const project of this.allProjects) {
      const changeOrderTotal = this.projectService.getChangeOrderTotal(
        project.change_orders.filter((co) => +co.status_id === 2)
      );
      const constructionBudget = this.projectService.getConstructionBudget(project.bid_packages);
      // set the project budget and push the project
      project.budget =
        constructionBudget != null && changeOrderTotal != null
          ? constructionBudget + changeOrderTotal
          : this.getPebBudget(project);

      // Rather then run the project for loop again adding the function here. Since it is related
      this.getCostPerSF(project);
    }
  }

  private getCostPerSF(project) {
    const costPerSF = project.budget / project.square_footage;

    project.cost_per_sqft = isFinite(costPerSF) && costPerSF;
  }

  private getPebBudget(project: ProjectDisplayData) {
    return this.projectService.getPebTotal(project.tenants, project.peb_items);
  }

  /**
   * Force Pipe Refresh
   */
  public updateFilter() {
    this.filter = { ...this.filter };
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }
}
