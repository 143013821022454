<div
  class="notification-overlay-panel ease"
  [ngClass]="{
    'panel-closed': !isPanelOpen(),
    'panel-open': isPanelOpen(),
    'sidenav-closed': !isSidenavOpen()
  }"
>
  <div class="notification-panel-container">
    <div class="d-flex m-0 py-4 pr-4 pl-3 notification_header align-items-center">
      <h3 (click)="closeNotificationPanel()" class="m-0 white font-weight-semibold pointer mr-auto">Notifications</h3>
      <app-profile-thumbnail
        (mouseover)="hover = true"
        (focus)="hover = true"
        (mouseleave)="hover = false"
        [matMenuTriggerFor]="avatarMenu"
        *ngIf="currentUser"
        matRipple
        class="pointer ml-2"
        [height]="36"
        [width]="36"
        [userId]="currentUser.id"
        [ngClass]="{ opacity7: hover }"
      ></app-profile-thumbnail>
      <mat-menu #avatarMenu="matMenu" xPosition="after">
        <button mat-menu-item [routerLink]="['/profile']">
          <mat-icon>person</mat-icon>
          <span class="mr-5">My Profile</span>
        </button>
        <button mat-menu-item *ngIf="authService.isImpersonating" (click)="authService.endImpersonation()">
          <mat-icon>bug_report</mat-icon>
          <span class="mr-5">End Impersonation</span>
        </button>
        <button
          mat-menu-item
          type="button"
          (click)="logout()"
          data-test-id="notification-overlay-logout-button"
          *ngIf="!authService.isImpersonating"
        >
          <mat-icon> logout </mat-icon>
          <span> Sign Out </span>
        </button>
      </mat-menu>
    </div>
    <div class="d-flex px-3 notification-tabs border-bottom align-items-center">
      <button
        mat-button
        color="primary"
        type="button"
        class="mr-4 px-2 br-0"
        (click)="toggleMyActions(false)"
        [ngClass]="{
          'border-accent border-bottom-thick': !myActions,
          'border-bottom-transparent': myActions
        }"
      >
        <span class="d-inline-block align-middle mr-2">All Notifications</span>
        <span class="label" class="button-badge bg-ltblue white">{{
          +generalUnreadCount + +myActionsUnreadCount || 0
        }}</span>
      </button>
      <button
        mat-button
        color="primary"
        type="button"
        class="mr-auto px-2 br-0"
        (click)="toggleMyActions(true)"
        [ngClass]="{
          'border-accent border-bottom-thick': myActions,
          'border-bottom-transparent': !myActions
        }"
      >
        <span class="d-inline-block align-middle mr-2">Assigned To Me</span>
        <span class="label" class="button-badge bg-ltblue white">
          {{ myActionsUnreadCount || 0 }}
        </span>
      </button>
      <button (click)="closeNotificationPanel()" type="button" mat-button class="gray ml-auto lh-28 px-2">Close</button>
      <!-- <mat-icon
        matRipple
        [matMenuTriggerFor]="help_menu"
        class="ltgray pointer br-50"
        style="margin-right: 10px; margin-top: 2px; margin-bottom: -2px;"
        >help_outline</mat-icon
      >
      <mat-menu class="p-3 mt-2" #help_menu="matMenu" xPosition="before">
        <div class="row mb-3">
          <div class="col">
            <p class="m-0 dkblue font-weight-semibold">
              All Notifications
            </p>
            <p class="m-0 dkgray font-weight-normal">
              These include notifications that do not necessarily require action from you, rather
              notifying you of activity you are following.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="m-0 dkblue font-weight-semibold">
              Assigned to Me
            </p>
            <p class="m-0 dkgray font-weight-normal">
              These are notifications requiring action from you.
            </p>
          </div>
        </div>
      </mat-menu> -->
    </div>

    <div class="notification-list" id="notification-list">
      <div class="d-flex justify-content-center align-items-center mt-5" *ngIf="isLoading">
        <mat-spinner color="accent" [diameter]="60"></mat-spinner>
      </div>
      <section
        [hidden]="isLoading"
        *ngFor="let state of ['unread', 'read']"
        [ngClass]="{ 'pb-4': state === 'read' }"
        class="overflow-hidden"
      >
        <div class="row align-items-center m-0 p-0 pt-3">
          <div class="col">
            <h5
              matRipple
              (click)="notifications[state].open = !notifications[state].open"
              class="m-0 d-inline-block dkblue font-weight-semibold pointer pt-2 pb-2 pr-3 br-5"
            >
              <mat-icon class="d-inline-block align-middle">
                {{ notifications[state].open ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
              </mat-icon>
              <span class="ml-2 d-inline-block align-middle">
                {{ state === 'read' ? 'Read' : 'Unread' }}
              </span>
            </h5>
          </div>
          <div class="col-auto text-right">
            <button
              *ngIf="state === 'unread' && notifications[state].open"
              mat-button
              color="accent"
              class="gray pl-2 pr-2"
              type="button"
              (click)="markAllRead()"
              style="line-height: 26px"
            >
              Mark All Read
            </button>
          </div>
        </div>
        <div
          *ngIf="state === 'unread' && notifications[state].open && notifications[state].list.length === 0"
          class="row"
        >
          <div class="col text-left">
            <h6 class="gray m-0 mt-3 mb-3 ml-3 font-italic">You're all caught up!</h6>
          </div>
        </div>
        <div *ngIf="notifications[state].open" class="d-flex flex-column notification-day">
          <div *ngFor="let n of notifications[state].list">
            <div class="d-flex align-items-center border-bottom bg-ltltltblue py-2" *ngIf="n.day_label">
              <h6 class="m-0 pl-4 dkblue font-weight-semibold">
                {{ n.day_label }}
              </h6>
            </div>

            <div class="d-flex notification item-no-hover align-items-top px-3 pr-4 py-3">
              <div class="d-flex pr-3">
                <img
                  *ngIf="!n.from_user?.id"
                  src="../../../assets/img/cloud_avatar.png"
                  style="width: 34px; height: 34px; padding: 2px"
                  alt="no user"
                />
                <app-profile-thumbnail
                  *ngIf="n.from_user?.id"
                  class="pointer"
                  style="height: 34px"
                  [height]="34"
                  [width]="34"
                  [userId]="n.from_user?.id"
                  [ngClass]="{ pointer: n.from_user?.id }"
                  (click)="openUserProfileDialog(n?.from_user?.id)"
                ></app-profile-thumbnail>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="d-flex mb-2 notification-header">
                  <p class="m-0 font-weight-semibold mr-auto">
                    <span
                      class="dkblue"
                      [ngClass]="{
                        'pointer hover-u': n?.type?.header_is_from_user_name && n.from_user?.id
                      }"
                      (click)="openUserProfileDialog(n?.from_user?.id)"
                      >{{ n.header }}</span
                    >
                    <span [class]="n.type?.background_color">&nbsp;{{ n.type?.subject }}</span>
                  </p>
                  <p class="m-0 font-weight-normal gray">
                    {{ n.created_datetime | date: 'h:mm a' }}
                  </p>
                  <mat-icon
                    *ngIf="!n.is_read"
                    (click)="updateNotificationStatus(n, 1); $event.stopPropagation()"
                    class="ltblue pointer ml-2"
                  >
                    fiber_manual_record
                  </mat-icon>
                  <mat-icon
                    *ngIf="!!n.is_read"
                    (click)="updateNotificationStatus(n, 0); $event.stopPropagation()"
                    class="gray pointer ml-2"
                  >
                    radio_button_unchecked
                  </mat-icon>
                </div>
                <div
                  class="d-flex align-items-top br-10 p-3"
                  [class]="'bg-shade-' + n.type?.background_color"
                  *ngIf="n.title || n.message || n.showReplyAndAcknowledge || n.type?.menuItems?.length > 0"
                >
                  <div class="d-flex mr-2">
                    <mat-icon [class]="n.type?.icon_color">{{ n.type?.icon }}</mat-icon>
                  </div>
                  <div class="d-flex flex-grow-1 flex-column" style="margin-top: 1.5px">
                    <div class="d-flex mb-1 align-items-top">
                      <!--if it has a resource link use link-->
                      <a
                        *ngIf="n.resourceLink"
                        class="dkblue mb-0 mr-auto font-weight-semibold text-break"
                        [ngClass]="{ 'pointer hover-u': n.titleIsLinked }"
                        [innerHTML]="n.title"
                        [routerLink]="n.resourceLink"
                      ></a>
                      <!-- otherwise click the button-->
                      <p
                        *ngIf="!n.resourceLink"
                        class="dkblue mb-0 mr-auto font-weight-semibold text-break"
                        [ngClass]="{ 'pointer hover-u': n.titleIsLinked }"
                        [innerHTML]="n.title"
                        (click)="n.routeToTitle()"
                      ></p>
                      <mat-icon
                        matRipple
                        [matMenuTriggerFor]="contentMenu"
                        class="dkblue ml-3 pointer br-50"
                        *ngIf="n.type?.menuItems?.length > 0"
                        style="width: 28px"
                      >
                        more_horiz
                      </mat-icon>
                      <mat-menu #contentMenu="matMenu" xPosition="before">
                        <div *ngIf="!updatingNotificationMenuItem">
                          <button
                            mat-menu-item
                            *ngFor="let m of n.type?.menuItems"
                            (click)="updateNotification($event, m, n)"
                            [disabled]="updatingNotificationMenuItem"
                          >
                            <mat-icon>{{ m.icon }}</mat-icon>
                            <span>{{ m.label }}</span>
                          </button>
                        </div>
                        <div *ngIf="updatingNotificationMenuItem" class="d-flex justify-content-center mt-3">
                          <mat-spinner color="accent" [diameter]="18"></mat-spinner>
                        </div>
                      </mat-menu>
                    </div>
                    <p
                      class="dkblue m-0 font-weight-normal text-break"
                      *ngIf="n.message"
                      [innerHTML]="n.message"
                      appTextCollapseExpand
                      maxHeight="110"
                      (heightExceededEvent)="n.showCollapseMessageViewButton = $event"
                      [showMore]="!n.collapseMessageView"
                    ></p>
                    <p
                      id="collapse-detail-view-button"
                      class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2"
                      *ngIf="n.showCollapseMessageViewButton === true"
                      (click)="n.collapseMessageView = !n.collapseMessageView"
                    >
                      <i class="fas {{ n.collapseMessageView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
                      {{ n.collapseMessageView === true ? 'Show More' : 'Show Less' }}
                    </p>
                    <div class="d-flex pt-3" [class]="n.type?.background_color">
                      <button
                        *ngIf="!n.is_read"
                        mat-stroked-button
                        (click)="updateNotificationStatus(n, 1); $event.stopPropagation()"
                        type="button"
                        color="accent"
                        class="px-3 lh-28 mr-3"
                      >
                        <!-- <mat-icon class="mr-1" style="font-size: 16px; height: 16px; width: 16px;"
                          >thumb_up_off_alt</mat-icon
                        > -->
                        <span> Mark As Read </span>
                      </button>
                      <button
                        mat-stroked-button
                        type="button"
                        color="accent"
                        class="px-3 lh-28 mr-3"
                        *ngIf="n.showReplyAndAcknowledge && n.replyFunction"
                        (click)="n.replyFunction()"
                      >
                        <mat-icon class="mr-1" style="font-size: 16px; height: 16px; width: 16px">forum</mat-icon>
                        <span> View </span>
                      </button>
                    </div>
                  </div>
                </div>
                <h6
                  class="m-0 parent-link ease font-weight-semibold d-flex align-items-center mt-2 opacity5"
                  [class]="n.parentColor || 'dkblue'"
                  *ngIf="n.parentString && n.parentString !== 'PRJ null'"
                >
                  <i class="material-icons mr-1" style="height: 16px; font-size: 16px">{{ n.parentIcon }}</i>
                  <!--It has a parent link-->
                  <a
                    *ngIf="n.parentLink"
                    [routerLink]="n.parentLink"
                    [ngClass]="{ 'pointer hover-u': n.parentIsLinked }"
                  >
                    {{ n.parentString }}
                  </a>
                  <!-- Otherwise click the button-->
                  <span
                    *ngIf="!n.parentLink"
                    [ngClass]="{ 'pointer hover-u': n.parentIsLinked }"
                    (click)="n.routeToParent()"
                  >
                    {{ n.parentString }}
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div *ngIf="notifications[state].list.length < notifications[state].count" class="row pb-5 mt-2">
            <div class="col text-center">
              <button
                mat-button
                color="accent"
                (click)="notifications[state].loading ? null : loadMoreNotifications(state === 'read')"
                type="button"
              >
                <span *ngIf="!notifications[state].loading"
                  >Load
                  {{
                    notifications[state].list.length + pageSize > notifications[state].count
                      ? notifications[state].count - notifications[state].list.length
                      : pageSize
                  }}
                  More</span
                >
                <mat-spinner
                  *ngIf="notifications[state].loading"
                  class="d-inline-block align-middle m-0 ml-2 mr-3 ltgray"
                  color="accent"
                  [diameter]="20"
                ></mat-spinner>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
