<div id="punchlist-input-modal" data-test-id="punchlist-input-modal" class="punchlist-input-modal row">
  <div class="col-12">
    <div class="row align-items-center mb-2">
      <div class="col">
        <h5 class="dkblue m-0 font-weight-semibold">
          <!-- {{data && data.punchlistData && data.punchlistData.code ? data.punchlistData.code : 'PL000'}} - -->
          {{ viewOnly ? 'Viewing Task' : isEditing ? 'Editing Task' : 'Creating New Task' }}
        </h5>
      </div>
      <div class="col-auto text-right">
        <a (click)="cancel()" class="close-modal">
          <i class="la la-times ltgray"></i>
        </a>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width pb-2" appearance="outline">
          <mat-label>Task Title *</mat-label>
          <input
            matInput
            type="text"
            placeholder="What needs to be done?"
            [(ngModel)]="description"
            [ngClass]="{
              'text-danger': description?.length > description_length
            }"
            [disabled]="viewOnly"
          />
          <mat-hint
            align="end"
            [ngClass]="{
              red: description?.length > description_length,
              gray: description?.length < description_length || description?.length == description_length
            }"
            >{{ description_counter }}</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Assign Trade</mat-label>
          <mat-select (selectionChange)="selectTrade($event.value)" [value]="selectedTrade" [disabled]="viewOnly">
            <mat-option> None </mat-option>
            <mat-option *ngFor="let trade of projectTrades$ | async" selected [value]="trade">
              {{ trade.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Task Location</mat-label>
          <input
            matInput
            type="text"
            placeholder="Room Number, Hallway, Wall, etc."
            [(ngModel)]="location"
            [disabled]="viewOnly"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-editor #editor placeholder="What do you need to clarify?" [disabled]="viewOnly"></app-editor>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-auto" *ngIf="!viewOnly">
        <button mat-stroked-button color="accent" class="align-middle" (click)="openFileAttachmentDialog()">
          Attach File
        </button>
      </div>
      <div class="col">
        <mat-chip-list class="d-inline-block align-middle">
          <app-file-chip
            *ngFor="let file of addedFiles"
            [file]="file"
            [removable]="!viewOnly"
            (removeEvent)="removeAddedFile(file)"
          ></app-file-chip>
          <app-file-chip
            *ngFor="let file of existingFiles"
            [file]="file"
            [removable]="!viewOnly"
            (removeEvent)="removeExistingFile(file)"
          ></app-file-chip>
        </mat-chip-list>
      </div>
    </div>
    <br /><br />
    <div class="row">
      <div class="col-8">
        <!-- [disabled]="!isValid()" -->
        <button mat-flat-button color="success" (click)="saveTaskAndClose()" *ngIf="!viewOnly" [disabled]="!isValid()">
          Save Task
        </button>
        &nbsp; &nbsp;
        <button
          mat-stroked-button
          color="accent"
          (click)="saveTaskAndClear()"
          *ngIf="!viewOnly"
          [disabled]="!isValid()"
        >
          <span class="mr-2"> Save </span>
          <mat-icon>arrow_forward</mat-icon>
          <span class="ml-2"> New Task </span>
        </button>
      </div>
      <div class="col-4 text-right">
        <button (click)="cancel()" mat-button class="ltgray">Close</button>
      </div>
    </div>
  </div>
</div>
