import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';

import * as moment from 'moment';

import { AuthService, ProgressIndicatorService, ProjectService, SidenavService } from 'src/app/services';

import { DashboardService } from 'src/app/workspaces/construction/services';

import {
  MeetingDashboardFilterPipe,
  ProjectDashboardFilter,
  ProjectDashboardFilterPipe,
  RequestDashboardFilterPipe,
  TaskDashboardFilterPipe,
} from 'src/app/workspaces/construction/pipes';

import {
  MeetingDashboardFilter,
  RequestDashboardFilter,
  TaskDashboardFilter,
} from 'src/app/workspaces/construction/models';

import { ProjectStatus } from 'src/app/enums';

import { Meeting, Request, Task, User } from 'src/app/types';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  // generic information for the page
  currentUser: User;
  timeOfDay: string;
  asideSubscription: any;
  isSidebarClosed: boolean;

  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  divWidth: number;
  showDesktop: boolean;
  showIpad: boolean;
  titleWidth: number;
  pmProjectIds = [];
  forceReload = false;
  private filteredTasks = {};

  unassignedProjects: ProjectConstruction[] = [];
  initialLoading = false;
  private prevUnassignedTaskIds = [];

  // converts the dates to the correct format
  get startDate() {
    return moment().startOf('day').add(this.dashboardService.startDays, 'days').toDate();
  }
  get endDate() {
    return moment().startOf('day').add(this.dashboardService.endDays, 'days').toDate();
  }
  // checks whether we are filtering by assignee or date
  get byAssignee() {
    return this.dashboardService.filterSelector === 'assignee';
  }
  get byDate() {
    return this.dashboardService.filterSelector === 'date';
  }
  // gets the total number of meetings for the day for the user
  get totalMeetings() {
    return this.getUpcomingMeetings().length + this.getRecentMeetings().length;
  }

  // internal functions
  taskPipe = new TaskDashboardFilterPipe();
  projectPipe = new ProjectDashboardFilterPipe();
  projectFilter = new ProjectDashboardFilter();
  taskFilter = new TaskDashboardFilter(this.dashboardService.currentUserId, [], this.startDate, this.endDate);
  meetingPipe = new MeetingDashboardFilterPipe();
  meetingFilter = new MeetingDashboardFilter(this.startDate, this.endDate);
  requestPipe = new RequestDashboardFilterPipe();
  requestFilter = new RequestDashboardFilter(this.startDate, this.endDate);

  constructor(
    public authService: AuthService,
    private sidenavService: SidenavService,
    public dashboardService: DashboardService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService
  ) {
    this.isSidebarClosed = sidenavService.isSidenavClosed;
    this.asideSubscription = sidenavService.sidenavVisibilityChange.subscribe((value) => {
      this.isSidebarClosed = value;
    });
  }

  async ngOnInit() {
    this.getDivWidth();
    const hour = new Date().getHours();
    if (hour < 11) {
      this.timeOfDay = 'morning';
    } else if (hour < 18) {
      this.timeOfDay = 'afternoon';
    } else {
      this.timeOfDay = 'evening';
    }
    this.currentUser = this.authService.getLoggedInUser();

    setTimeout(async () => {
      this.pmProjectIds = this.authService.getPMProjectIds();
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading data...');
      this.dashboardService.dataLoaded = false;
      this.filteredTasks = {};
      await this.dashboardService.loadAllTasksAndProjects().then(async (data) => {
        await this.dashboardService.getAllTeamIds().then((teamIds) => {
          this.taskFilter.teamIds = teamIds;

          this.progressIndicatorService.close();
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.asideSubscription) {
      this.asideSubscription.unsubscribe();
    }
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
    this.showDesktop = this.divWidth > 900;
    this.showIpad = !this.showDesktop;
  }

  onResize(event) {
    this.getDivWidth();
  }

  // does all filtering for the tasks
  // useful functions
  public getMyTaskData(filter: 'all' | 'overdue_only' | 'approvals_only' = this.dashboardService.taskSelector): Task[] {
    return this.getTasks('self', filter);
  }
  public getTeamData(filter: 'all' | 'overdue_only' | 'approvals_only' = this.dashboardService.taskSelector): Task[] {
    return this.getTasks('team', filter);
  }
  public getFollowingData(
    filter: 'all' | 'overdue_only' | 'approvals_only' = this.dashboardService.taskSelector
  ): Task[] {
    return this.getTasks('following', filter);
  }
  public getUnassignedData(
    filter: 'all' | 'overdue_only' | 'approvals_only' = this.dashboardService.taskSelector
  ): Task[] {
    return this.getTasks('unassigned', filter, this.pmProjectIds);
  }
  // returns all users that are the assignee of the visible tasks
  private getTasksByAssignee(tasks = this.getTasks()): User[] {
    const userIds = tasks.map((task) => task.assigned_user_id);
    return this.dashboardService.getUsers().filter((user) => {
      if (userIds.includes(user.id)) {
        user.tasks = tasks.filter((task) => task.assigned_user_id === user.id);
        return user;
      }
    });
  }

  public getTasks(
    assignedTo: 'team' | 'following' | 'self' | 'unassigned' = this.dashboardService.assignedTo,
    filter: 'all' | 'overdue_only' | 'approvals_only' = this.dashboardService.taskSelector,
    myProjects: number[] = []
  ): Task[] {
    this.taskFilter.startDate = this.startDate;
    this.taskFilter.endDate = this.endDate;
    this.taskFilter.assignedTo = assignedTo;
    this.taskFilter.showComplete = this.dashboardService.showComplete;
    this.taskFilter.showNoDueDate = this.dashboardService.showNoDueDate;
    this.taskFilter.taskType = filter;
    this.taskFilter.projectIds = myProjects;
    // check if this filter config was already filtered for
    if (!this.hasFilterConfig() && this.dashboardService.dataLoaded) {
      this.forceReload = false;
      // if we haven't seen this config, store it after filtering
      const tasks = this.taskPipe.transform(this.dashboardService.tasks, this.taskFilter);
      this.addFilteredConfig(tasks);

      // calls the reload anytime the filters get changed (the function uses caching so it's ok if we call it more often than when just certain filters are changed)
      if (assignedTo === 'unassigned') {
        this.loadUnassignedData(this.getUnassignedData());
      }
    }
    return this.getFilterConfig();
  }

  // checks if this filter was already applied, and if so, retrieve the task array
  private getFilterString(): string {
    return `${moment(this.taskFilter.endDate).startOf('day')}${moment(this.taskFilter.startDate).startOf('day')}${
      this.taskFilter.taskType
    }${this.taskFilter.assignedTo}${this.taskFilter.teamIds.join('')}`;
  }
  private hasFilterConfig(): boolean {
    return !!this.filteredTasks[this.getFilterString()];
  }
  private getFilterConfig(): Task[] {
    return this.filteredTasks[this.getFilterString()] ? this.filteredTasks[this.getFilterString()] : [];
  }
  private addFilteredConfig(tasks: Task[]) {
    this.filteredTasks[this.getFilterString()] = tasks;
  }

  // does all filtering for the projects
  // useful functions
  public getActiveProjects(): ProjectConstruction[] {
    return this.getProjects(ProjectStatus.ACTIVE);
  }
  public getOnHoldProjects(): ProjectConstruction[] {
    return this.getProjects(ProjectStatus.ON_HOLD);
  }
  private getProjects(statusId: number = this.dashboardService.selectedProjectStatusId): ProjectConstruction[] {
    this.projectFilter.statusId = statusId;
    return this.projectPipe.transform(this.dashboardService.getProjects(), this.projectFilter);
  }

  // does all filtering for the meetings
  // useful functions
  public getUpcomingMeetings(): Meeting[] {
    return this.getMeetings('upcoming');
  }
  public getRecentMeetings(): Meeting[] {
    return this.getMeetings('recent');
  }
  public getTodaysMeetings(): Meeting[] {
    return this.getMeetings('today');
  }
  private getMeetings(
    status: 'upcoming' | 'recent' | 'today' = this.dashboardService.selectedMeetingStatus
  ): Meeting[] {
    this.meetingFilter.startDate = this.startDate;
    this.meetingFilter.endDate = this.endDate;
    this.meetingFilter.status = status;
    return this.meetingPipe.transform(this.dashboardService.getMeetings(), this.meetingFilter);
  }

  // does all filtering for the requests
  // useful functions
  public getNewRequests(): Request[] {
    return this.getRequests('recent');
  }
  public getAcceptedRequests(): Request[] {
    // return this.getRequests('accepted').concat(this.getRequests('converted'));
    return this.getRequests('accepted');
  }
  public getPendingRequests(): Request[] {
    return this.getRequests('pending');
  }
  private getRequests(
    status: 'pending' | 'accepted' | 'recent' | 'converted' = this.dashboardService.selectedRequestStatus
  ): Request[] {
    this.requestFilter.startDate = this.startDate;
    this.requestFilter.endDate = this.endDate;
    this.requestFilter.status = status;
    return this.requestPipe.transform(this.dashboardService.getRequests(), this.requestFilter);
  }

  public changeTaskFilter(str: 'all' | 'overdue_only' | 'approvals_only') {
    this.dashboardService.taskSelector = str;
    this.loadUnassignedData(this.getUnassignedData());
  }

  public toggleShowComplete() {
    this.filteredTasks = {};
    this.dashboardService.showComplete = !this.dashboardService.showComplete;
    this.loadUnassignedData(this.getUnassignedData());
  }

  public toggleShowNoDueDate() {
    this.filteredTasks = {};
    this.dashboardService.showNoDueDate = !this.dashboardService.showNoDueDate;
    this.loadUnassignedData(this.getUnassignedData());
  }

  public setDays(days) {
    this.dashboardService.setDays(days);
    this.loadUnassignedData(this.getUnassignedData());
  }

  public changeTab(tab: 'tasks' | 'team' | 'following' | 'unassigned') {
    this.dashboardService.selectedTab = tab;
    if (tab === 'unassigned') {
      this.dashboardService.filterSelector = 'date';
    }
    if (this.forceReload) {
      this.filteredTasks = {};
    }
  }
  get getUnassignedProjectData() {
    if (!this.initialLoading) {
      this.initialLoading = true;
      this.loadUnassignedData(this.getUnassignedData());
    }
    return this.unassignedProjects;
  }

  // called on initial load + when the filter conditions change
  // orders the unassigned task data by project to display on the unassigned tab
  // verifies the data isn't still good before loading project details (compares task id array - new vs old)
  private loadUnassignedData(tasks) {
    // if the new vs old task ids don't match, reload the project data
    const newTaskIds = tasks.map((task) => +task.id);
    if (JSON.stringify(newTaskIds) !== JSON.stringify(this.prevUnassignedTaskIds)) {
      this.prevUnassignedTaskIds = newTaskIds;

      // get the project ids, and load the relevant data
      let ids = tasks.map((task) => +task.project_id);
      ids = ids.filter((a, b) => ids.indexOf(a) === b);
      this.projectService.getProjectsByProjectIds(ids).subscribe((projects) => {
        projects.forEach((project) => {
          project.tasks = tasks.filter((task) => +task.project_id === +project.id);
          this.projectService.getPhasesByProjectId(project.id).subscribe((phases) => {
            project.phases = phases;
            project.phases.forEach((phase) => {
              phase.num_tasks = project.tasks.filter((task) => +task.phase_id === +phase.id).length;
            });
            this.replaceProject(project);
          });
        });
      });
    }
  }

  // replaces the projects directly, to avoid weird visual issues where the data briefly becomes 0/undefined
  private replaceProject(project) {
    const index = this.unassignedProjects.map((proj) => proj.id).indexOf(project.id);
    if (index !== -1) {
      this.unassignedProjects[index] = project;
    } else {
      this.unassignedProjects.push(project);
    }
  }

  public forceReloadByTask() {
    this.forceReload = true;
  }
}
