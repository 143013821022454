import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import heic2any from 'heic2any';
import { EncryptedPDFError, PDFDocument } from 'pdf-lib';
import { EditorComponent, FileSelectModalComponent, FilesUploadModalComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { VerifiedFile } from 'src/app/models';
import { FileService, ProgressIndicatorService, ProjectService } from 'src/app/services';
import { Tag, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-attachment-dialog',
  templateUrl: './file-attachment-dialog.component.html',
  styleUrls: ['./file-attachment-dialog.component.scss'],
})
export class FileAttachmentDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;

  public dialogTitle = 'Choose Files from...';
  public workOrderFile = false; // TODO fix this when we redo this component, this is a temp fix
  public draftFile = false; // TODO fix this when we redo this component, this is a temp fix
  public maxFiles = 30; // The maximum number of files that can be uploaded

  public allowSearchFromProject = true; // Whether a user is allowed to upload files from the project

  public allowComment: boolean; // Whether a user is allowed to type a comment for the files

  public linkedFiles: UhatFileReference[] = []; // Files that already exist, but in which we want to create references of for this note

  public computerFiles: VerifiedFile[] = []; // Files to be uploaded

  public filteredFiles: UhatFileReference[] = [];

  private verifyFileExtension = false; // Pass true for this field (through the passed data) to force file extension check (used in peb/punch list/cb/invoices)
  private approvedFileExtensions: string[];

  private skipUpload = false; // Pass true for this field (through the passed data) to prevent the file from being uploaded so it can be handled away from this dialog. This is typically used when selecting a file to be attached after an item is created.

  private skipUploadReturnObject: {
    linkedFiles: UhatFileReference[];
    computerFiles: File[];
  } = {
    linkedFiles: [],
    computerFiles: [],
  };

  public projectParent: number;

  public processing: boolean; // Is the upload process started?

  public processingPercentage: number; // What is the percentage of the upload process?

  public processingStatus: string;

  public parentResourceType: ResourceType; // The parent type of the note to be created (FileReferences are created to reference this created note)

  public parentResourceId: number;

  private additionalParents: UhatFileBridgeLink[];

  private _isValid = true;

  private allTags: Tag[] = [];

  private project_id: number;

  set isValid(bool: boolean) {
    this._isValid = bool;
  }

  get isValid() {
    return this._isValid;
  }

  // Get Text in the comment box
  get commentText(): string {
    return this._editor_component.content.value;
  }

  // Set Text in the comment box
  set commentText(value: string) {
    this._editor_component.content.setValue(value);
  }

  get allFilesAreValidSize() {
    return !this.computerFiles?.length || !this.computerFiles?.find((file) => !file.isValidFileSize);
  }

  get isEncrypted() {
    return this.computerFiles?.find((file) => file.isEncrypted === true);
  }

  get isCorrupt() {
    return this.computerFiles?.find((file) => file.isCorrupt === true);
  }

  // verifies that the names for all linked files are valid
  async verifyFileNames(): Promise<boolean> {
    let allVerified = true;
    for (const file of this.computerFiles) {
      const verifiedFiles =
        this.data?.currentAttachedFiles?.map(
          (file: File) => new VerifiedFile(file, this.fileService, this.parentResourceId, this.parentResourceType)
        ) ?? [];
      const files = [...this.computerFiles, ...verifiedFiles];
      await file.verifyFileName(file.displayName, files);
      if (!file.isVerified || (this.verifyFileExtension && !file.hasValidExtension)) {
        allVerified = false;
      }
    }
    for (const file of this.linkedFiles) {
      this.verifyExtension(file);
      if (this.verifyFileExtension && !file.hasValidExtension) {
        allVerified = false;
      }
    }
    this.isValid = allVerified;

    return allVerified;
  }

  get primaryTags() {
    if (this.selectedPrimaryTags > 0) {
      return this.allTags.filter((t) => +t.tag_parent_id === 0 && t.is_selected);
    } else {
      return this.allTags.filter((t) => +t.tag_parent_id === 0 && (!t.is_system_generated || t.is_selected));
    }
  }

  get possibleSecondaryTags() {
    return this.allTags.filter(
      (t) =>
        +t.tag_parent_id !== 0 &&
        (!t.is_system_generated || t.is_selected) &&
        this.allTags[this.allTags.indexOf(this.allTags.find((parent) => +parent.id === +t.tag_parent_id))].is_selected
    );
  }

  // returns the count of the primary tags selected (minus the parent tags)
  get selectedPrimaryTags() {
    return this.allTags.filter((t) => +t.tag_parent_id === 0 && !t.is_system_generated && t.is_selected).length;
  }

  // Parents id, this Id is the parent_id of the note to be created

  constructor(
    public dialogRef: MatDialogRef<FileAttachmentDialogComponent>,
    public dialog: MatDialog,
    public projectService: ProjectService,
    public fileService: FileService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    if (this.data.skipUpload) {
      if (!this.data.project_id) {
        // When using skip upload, project_id is required to get this.allTags via workspace.
        // If project_id isn't applicable, maybe we should refactor to hide tags.
        // this.snackBar.open('Error: Project ID is required when skipping upload');
        // this.close();
      } else {
        this.projectParent = this.data.project_id;
      }
    }
    if (this.data.parentResourceType && this.data.parentResourceId) {
      this.parentResourceType = this.data.parentResourceType;
      this.parentResourceId = this.data.parentResourceId;
      if (this.parentResourceType === ResourceType.Project) {
        this.projectParent = this.parentResourceId;
      }
    } else if (!this.data?.skipUpload) {
      this.snackBar.open('Error: File Parent Type and ID are required');
      this.close();
      return;
    }
    if (this.data.hasOwnProperty('workOrderFile')) {
      this.workOrderFile = this.data.workOrderFile;
    }
    if (this.data.hasOwnProperty('draftFile')) {
      this.draftFile = this.data.draftFile;
    }
    if (this.data.hasOwnProperty('maxFiles')) {
      this.maxFiles = this.data.maxFiles;
    }
    if (this.data.hasOwnProperty('allowSearchFromProject')) {
      this.allowSearchFromProject = this.data.allowSearchFromProject;
    }
    if (this.data.hasOwnProperty('allowComment')) {
      this.allowComment = this.data.allowComment;
    }

    if (this.data.hasOwnProperty('verifyFileExtension')) {
      this.verifyFileExtension = this.data.verifyFileExtension;
    }

    if (this.data.hasOwnProperty('computerFiles') || this.data.hasOwnProperty('linkedFiles')) {
      await this.uploadFiles({
        computerFiles: this.data.computerFiles || [],
        linkedFiles: this.data?.linkedFiles || [],
      });
    }

    if (this.data.hasOwnProperty('filteredFiles')) {
      this.filteredFiles = this.data.filteredFiles;
    }

    if (this.data.hasOwnProperty('skipUpload')) {
      this.skipUpload = this.data.skipUpload;
    }

    this.approvedFileExtensions = this.data?.approvedFileExtensions || this.fileService.combinableExtensions;

    const parents = [];
    parents.push({ parent_id: this.parentResourceId, parent_type_id: this.parentResourceType });
    if (this.data.additionalParents) {
      this.additionalParents = this.data.additionalParents;
      this.additionalParents.forEach((parent) => {
        parents.push({ parent_id: parent.id, parent_type_id: parent.resourceType });

        if (this.parentResourceType !== ResourceType.Project && parent.resourceType === ResourceType.Project) {
          this.projectParent = parent.id;
        }
      });
    }

    // TODO remove this if we can get the actual project id here
    // this is a temporary fix to allow uploading project files on the dashboard
    if (this.data.hasOwnProperty('project_id')) {
      this.project_id = this.data.project_id;
      parents.push({ parent_id: this.project_id, parent_type_id: ResourceType.Project });
    }
    if (parents.length) {
      const parentModules = await this.fileService.getParentsModules(parents).toPromise();
      const tagFilters = [{ type: 'field', field: 'is_enabled', value: '1' }];
      this.allTags = await this.fileService.getTags(parentModules, tagFilters).toPromise();
    }

    // set the selected tags to the passed value
    if (this.data.hasOwnProperty('preSelectedTags')) {
      this.data.preSelectedTags.forEach((t: Tag) => {
        const currentTag = this.allTags.find((tag) => +tag.id === +t.id);
        if (currentTag) {
          currentTag.is_selected = true;
        }
      });
    }
  }

  public get ResourceType() {
    return ResourceType;
  }

  public async uploadFiles({
    computerFiles,
    linkedFiles,
  }: {
    computerFiles?: VerifiedFile[];
    linkedFiles?: UhatFileReference[];
  }) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Uploading files..');

    for (const computerFile of computerFiles || []) {
      await this.addComputerFile(computerFile);
    }

    for (const linkedFile of linkedFiles || []) {
      await this.addProjectLinkedFile(linkedFile);
    }
    this.progressIndicatorService.close();
  }

  /**
   * Submit, begin upload and complete operations. Open the Files Upload Modal Component If Computer Files Are Attached.
   * If not, then just complete the linked files.
   */
  public async submit() {
    this.processing = true;
    this.processingStatus = 'Uploading';

    const verifiedFiles = await this.verifyFileNames();
    if (this.computerFiles.length + this.linkedFiles.length <= 0 || !verifiedFiles) {
      return;
    }

    if (this.skipUpload) {
      this.skipUploadReturnObject.linkedFiles = this.linkedFiles;
    } else {
      for (const file of this.linkedFiles) {
        await this.fileService.linkFile(file.file_id, this.parentResourceId, this.parentResourceType).toPromise();
      }
    }

    if (this.computerFiles.length > 0) {
      // Open files upload modal that takes care of uploading and notifying the user of upload progress.
      const files = [];
      this.computerFiles.forEach((f) => {
        files.push(new File([f.file], f.displayName + f.extension));
      });
      if (this.skipUpload) {
        this.skipUploadReturnObject.computerFiles = files;
        this.finish();
      } else {
        const tagIds = this.allTags.filter((t) => t.is_selected).map((t) => +t.id);
        this.dialog
          .open(FilesUploadModalComponent, {
            data: {
              files,
              parentResourceId: this.parentResourceId,
              parentResourceType: this.parentResourceType,
              tagIds: `[${tagIds.join(',')}]`,
              autoClose: true,
            },
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              const successfulFiles: UhatFileReference[] = result.successfulFiles;
              const failedFiles: File[] = result.failedFiles;

              this.linkedFiles = this.linkedFiles.concat(successfulFiles);
              this.computerFiles = [];

              // If files failed to upload then we want to give the user a chance to correct
              // We move the successful files into the 'linkedFiles' list because they are already uploaded and we remove the failed files from
              // the chip list so that they can try to rename and relink. Finally, we reset the processing state and status.
              if (failedFiles.length > 0) {
                this.processing = false;
                this.processingStatus = null;
                return; // We want to return so the note doesnt get created and the dialog does not close
              }
            }
            this.finish();
          });
      }
    } else {
      // Complete without opening files upload modal
      this.finish();
    }
  }

  /**
   * Finish the file attach process.
   * If we allow comments, then we are creating a note. As such, this creates the note, and then links the files to the note, the parent, and all additional parents
   * If we don't allow comments, then we only link the files to the parent and additional parents
   */
  private finish() {
    if (this.skipUpload) {
      this.dialogRef.close(this.skipUploadReturnObject);
    } else if (this.allowComment) {
      // Files are uploaded, update percentage and move on to creating the note as needed
      this.processingStatus = 'Creating Note';

      // Create Default Message Text If Comment Box Is Left Empty
      if (this.commentText == null || this.commentText.length < 2) {
        this.commentText = `Attached ${this.linkedFiles.length} file(s) to the task.`;
      }

      // Create Note In Database
      const successfullyLinkedFiles: any[] = [];
      const unlinkedFiles: any[] = [];

      this.projectService
        .createNote(this.parentResourceType, this.parentResourceId, this.commentText)
        .subscribe(async (createdNote) => {
          if (!createdNote) {
            console.error('Note not created, cannot link files');
            return;
          }
          // Note creation successful, update the processing percentage
          this.processingStatus = 'Linking Files';
          // Create File References From Returned Note For Each File, updating the processing percentage as needed
          for (let i = 0; i < this.linkedFiles.length; i++) {
            const fileRef = this.linkedFiles[i];
            await this.fileService
              .linkFile(fileRef.file_id, createdNote.id, ResourceType.Note)
              .toPromise()
              .then((f) => successfullyLinkedFiles.push(fileRef))
              .catch((reason) => unlinkedFiles.push(fileRef));
            // link any additional parents
            this.linkAdditionalParentsForFile(fileRef);
            this.processingPercentage = i / this.linkedFiles.length;
          }
          createdNote.files = successfullyLinkedFiles;
          this.dialogRef.close({ unlinkedFiles, createdNote });
        });
    } else {
      // Verify we have the right number of files
      if (this.linkedFiles.length === 0) {
        return;
      }
      // Update status
      this.processingStatus = 'Linking File';
      for (let i = 0; i < this.linkedFiles.length; i++) {
        const fileRef = this.linkedFiles[i];
        this.linkAdditionalParentsForFile(fileRef);
        this.processingPercentage = i / this.linkedFiles.length;
      }
      this.dialogRef.close(this.linkedFiles);
    }
  }

  /** Take all additional parents and create links for them */
  private linkAdditionalParentsForFile(fileRef: UhatFileReference) {
    if (this.additionalParents) {
      this.additionalParents.forEach((link) => {
        if (link.id && link.resourceType) {
          this.fileService.linkFile(fileRef.file_id, link.id, link.resourceType).subscribe();
        }
      });
    }
  }

  public close() {
    this.dialogRef.close(false);
  }

  public async removeProjectLinkedFile(file: UhatFileReference) {
    this.linkedFiles = this.linkedFiles.filter((f) => f.id !== file.id);
    await this.verifyFileNames();
  }

  public async removeComputerFile(file: VerifiedFile) {
    // we only need to remove one of them
    this.computerFiles.splice(this.computerFiles.indexOf(file), 1);
    await this.verifyFileNames();
  }

  public verifyExtension(file: UhatFileReference) {
    const extension = file.name.substr(file.name.lastIndexOf('.'), file.name.length - 1);
    file.hasValidExtension = this.approvedFileExtensions.includes(extension.toLowerCase().replace('.', ''));
  }

  public async addProjectLinkedFile(file: UhatFileReference) {
    if (this.verifyFileExtension) {
      this.verifyExtension(file);
    }

    this.linkedFiles.push(file);
    await this.verifyFileNames();
  }

  private async convertToPng(file: File) {
    return heic2any({ blob: file, toType: 'image/png', quality: 100 }).then(
      (pngBlob: Blob) =>
        new File([pngBlob], `${file.name.split('.')[0]}.png`, {
          type: pngBlob.type,
        })
    );
  }

  // sets the display name to the name without extension
  public async addComputerFile(newVerifiedFile: VerifiedFile) {
    let verifiedFile: VerifiedFile;

    // specifically for heic extentension, transform them into png
    if (newVerifiedFile?.extension?.toLowerCase() === '.heic') {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Converting HEIC file format to png...');
      const convertedFile = await this.convertToPng(newVerifiedFile.file);
      verifiedFile = new VerifiedFile(convertedFile, this.fileService, this.parentResourceId, this.parentResourceType);
      verifiedFile.convertedFromHEICToPNG = true;
      this.progressIndicatorService.close();
    } else {
      verifiedFile = newVerifiedFile;
    }

    await verifiedFile.verifyFileName(verifiedFile.displayName, this.computerFiles);
    if (this.verifyFileExtension) {
      await verifiedFile.verifyFileExtension(verifiedFile.extension, this.approvedFileExtensions);
    }

    verifiedFile.verifyFileSize(verifiedFile.fileSize);

    this.computerFiles.push(verifiedFile);
    await this.verifyFileNames();
    this.verifyFile(newVerifiedFile);

    // update 2 - 7/21/20 - removed the setting of isValid, since the above verifyFileNames does already
    // update 7/8/20
    // note that file.hasValidExtension is always true, unless file.verifyFileExtension(file.extension) is run, see above.
    // this.isValid = this.isValid && file.hasValidExtension && file.isVerified;
  }

  verifyFile(f: VerifiedFile) {
    switch (f.extension.toLowerCase()) {
      case '.pdf':
        const reader = new FileReader();
        reader.readAsArrayBuffer(f.file);
        reader.onload = async () => {
          try {
            await PDFDocument.load(reader.result);
          } catch (error) {
            if (error instanceof EncryptedPDFError) {
              f.isEncrypted = true;
            } else {
              f.isCorrupt = true;
            }
          }
        };
        break;

      default:
        break;
    }
  }

  // TODO: verify these methods
  public openFileSelectDialog(maxFiles) {
    this.dialog
      .open(FileSelectModalComponent, {
        data: {
          title: 'Select Files To Attach',
          projectId: this.project_id || this.projectService.currentSelectedProject.id,
          filterFiles: this.linkedFiles,
          maxFiles,
        },
      })
      .afterClosed()
      .subscribe((results) => {
        if (results) {
          // Dialog was validated and submitted
          results.forEach((file) => this.addProjectLinkedFile(file));
        }
      });
  }

  async uploadMultipleFiles(files) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Uploading files..');

    const filePromises = [];

    for (const selectedFile of files) {
      filePromises.push(
        this.addComputerFile(
          new VerifiedFile(selectedFile, this.fileService, this.parentResourceId, this.parentResourceType)
        )
      );
    }

    await Promise.all(filePromises);

    this.progressIndicatorService.close();
  }

  async uploadFile(files: FileList) {
    const selectedFile = files[0];
    this.addComputerFile(
      new VerifiedFile(selectedFile, this.fileService, this.parentResourceId, this.parentResourceType)
    );
  }

  // whenever the typed name is changed in the textbox, update the file item
  async onNameChange(f, val) {
    let index = -1;
    for (let i = 0; i < this.computerFiles.length; i++) {
      if (this.computerFiles[i].file.name === f.name) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      this.computerFiles[index] = new VerifiedFile(
        new File([f], val + f.extension),
        this.fileService,
        this.parentResourceId,
        this.parentResourceType
      );
      this.computerFiles[index].displayName = val;
    }
  }

  togglePrimaryTag(tag) {
    if (tag.is_system_generated) {
      this.snackBar.open('Tags added by system cannot be removed.');
      return;
    }
    if (this.selectedPrimaryTags > 0 && !tag.is_selected) {
      this.snackBar.open('Cannot add more than one primary tag.');
      return;
    }
    tag.is_selected = !tag.is_selected;
    if (+tag.tag_parent_id === 0) {
      this.allTags.filter((t) => +t.tag_parent_id === +tag.id).forEach((t) => (t.is_selected = false));
    }
  }

  toggleSecondaryTag(tag) {
    if (tag.is_system_generated) {
      this.snackBar.open('Tags added by system cannot be removed.');
      return;
    }
    tag.is_selected = !tag.is_selected;
  }
}

export class UhatFileBridgeLink {
  id: number;
  resourceType: ResourceType;

  constructor(id: number, resourceType: ResourceType) {
    this.id = id;
    this.resourceType = resourceType;
  }
}
