<app-base-dialog [title]="dialogTitle" (closeDialog)="cancel()"></app-base-dialog>
<div id="confirmation-choice-dialog" data-test-id="confirmation-choice-dialog" class="confirmation-choice-dialog">
  <div class="d-flex flex-column">
    <h4 *ngIf="heading" class="m-0 dkblue font-weight-semibold my-4">
      {{ heading }}
    </h4>
    <h5 *ngIf="subHeading" class="dkgray font-weight-normal mb-5 lh-30" [innerHTML]="subHeading"></h5>
  </div>
  <div *ngIf="option1.text && option2.text" class="row">
    <div class="col">
      <button mat-button (click)="choice(option1)" class="bg-orange white full-width">
        {{ option1.text }}
      </button>
    </div>
    <div class="col">
      <button mat-flat-button (click)="choice(option2)" class="full-width" color="accent">
        {{ option2.text }}
      </button>
    </div>
  </div>
</div>
