import { FormControl } from '@angular/forms';

export class CustomValidator {
  static phoneRegex = /^[\d]?[\s-(]*\d{3}[\s-)]*\d{3}[\s-]*\d{4}([\s]?(ext|extension)?[\s]\d{1,5})?$/;
  static asciiRegex = /^[\x00-\x7F]*$/;

  static validCharacter(control: FormControl) {
    const str = control?.value?.trim();
    if (str) {
      let result = { invalidCharacter: true };
      for (const char of str) {
        if (CustomValidator.asciiRegex.test(char)) {
          result = null;
        }
      }
      return result;
    }
    return null;
  }

  static phone(control: FormControl) {
    if (!control?.value?.trim()) {
      return null;
    }

    if (CustomValidator.phoneRegex.test(control?.value?.trim())) {
      return null;
    }

    return { phone: true };
  }

  static userId(control: FormControl) {
    if (!control?.value || (typeof control?.value === 'object' && control?.value?.id)) {
      return null;
    }

    return { userId: true };
  }
}
