<div class="files-upload-modal-content">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h5 class="dkblue m-0 font-weight-semibold">Uploading {{ filesToUpload.length }} Files</h5>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let file of filesToUpload">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="m-0 dkblue font-weight-normal">
                {{ file.fileData.name }}
              </h5>
              <!-- <p *ngIf="file.status === 'complete'" class="green m-0">
                Upload Successful
              </p>
              <p *ngIf="file.status === 'error'" class="red m-0">
                Error Uploading, Not Uploaded
              </p>
              <p *ngIf="file.status === 'duplicate'" class="red m-0">
                This file is a duplicate, not uploaded
              </p> -->
            </div>
            <div class="col-auto text-right">
              <i *ngIf="file.status === 'waiting'" class="material-icons gray status-icon p-2 pr-4">arrow_upward</i>
              <i *ngIf="file.status === 'complete'" class="material-icons green status-icon p-2 pr-4">check_circle</i>
              <i *ngIf="file.status === 'error'" class="material-icons red status-icon p-2 pr-4">error</i>
              <i *ngIf="file.status === 'duplicate'" class="material-icons red status-icon p-2 pr-4">file_copy</i>
              <span *ngIf="file.status === 'uploading'" class="spinner-border ltblue" role="status"> </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-4 mb-2">
    <div class="col">
      <button *ngIf="operationComplete" mat-button color="accent" class="full-width" (click)="submit()" type="button">
        Dismiss
      </button>
    </div>
  </div>
</div>
