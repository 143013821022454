import { ApplicationRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ArfPurchaseTypeId, FileAction, InvoiceStatus, ResourceType, TaskReviewStatus, Workspace } from 'src/app/enums';
import { DisplayReviewersService, FileService } from 'src/app/services';
import { Arf, Invoice } from 'src/app/types';
import { PEBFinalizationError } from 'src/app/workspaces/construction/models';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-invoice-cover-letter',
  templateUrl: './invoice-cover-letter.component.html',
  styleUrls: ['./invoice-cover-letter.component.scss'],
})
export class InvoiceCoverLetterComponent implements OnInit {
  @Input() exportingInvoice: Invoice;
  @Input() project: ProjectConstruction;
  @Input() parentBudget: any;
  @Input() arf: Arf;

  @ViewChild('coverLetter', { static: false }) coverLetter;
  @ViewChild('coverLetterBudgetReport', { static: true }) budgetReport;

  constructor(
    private appRef: ApplicationRef,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private snackbar: MatSnackBar
  ) {}

  parentType: string;
  code: string;
  title: string;
  purchaseType: ArfPurchaseTypeId;
  ngOnInit(): void {}

  public get currentWorkspace() {
    return this.project?.module_id;
  }

  public get invoiceDate(): string {
    return moment(this.exportingInvoice?.invoice_date).format('ll');
  }

  public get invoiceEndDate(): string {
    return this.exportingInvoice?.invoice_end_date && moment(this.exportingInvoice.invoice_end_date).format('ll');
  }

  public get submittedDate(): string {
    return moment(this.exportingInvoice?.created_datetime).format('ll');
  }

  public get isConstructionWorkspace() {
    return +this?.currentWorkspace === Workspace.Construction;
  }

  public get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  public get Workspace() {
    return Workspace;
  }

  public get ArfPurchaseTypeId() {
    return ArfPurchaseTypeId;
  }

  public get InvoiceStatus() {
    return InvoiceStatus;
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment().format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment().format('LL');
  }

  async export() {
    return await this.coverLetter.export();
  }

  async exportInvoice(
    invoice: Invoice,
    fileAction = FileAction.Download,
    project = null,
    complete = false,
    reviewInfo = [],
    arf = null
  ) {
    this.exportingInvoice = invoice;
    this.project = project || this.project;
    this.arf = arf || this.arf;

    this.parentType =
      this.exportingInvoice?.parent_resource_type_id === ResourceType.AcquisitionRequestForm ? 'ARF' : 'Project';
    this.code = this.project?.code || this.arf?.code;
    this.title = this.project?.title || this.arf?.title;
    const parentTypeCode =
      this.exportingInvoice?.parent_resource_type_id === ResourceType.AcquisitionRequestForm ? '' : 'PRJ';
    this.purchaseType = this.exportingInvoice.quote?.arf_purchase_type_id || this.arf?.purchase_type_id;

    if (invoice.approval_task_id) {
      this.exportingInvoice.reviews = await this.displayReviewersService.displayReviewsByTask(invoice.approval_task_id);
      if (reviewInfo?.length && this.exportingInvoice?.reviews?.length) {
        for (const reviewer of reviewInfo) {
          const currentReviewIndex = this.exportingInvoice.reviews.findIndex((review) => review.id === reviewer.id);
          this.exportingInvoice.reviews[currentReviewIndex] = {
            ...this.exportingInvoice.reviews[currentReviewIndex],
            ...reviewer,
          };
        }
      }
    }

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    if (invoice && invoice.files) {
      invoice.loadingExport = true;
      let breakout = false;
      const files = [];

      // Generate Cover Letter Export
      const coverLetterGroup = await this.coverLetter.export();
      const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');

      const coverLetterByteCharacters = atob(coverLetterBase64);
      const coverLetterData = new Array(coverLetterByteCharacters.length);

      for (let i = 0; i < coverLetterByteCharacters.length; i++) {
        coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
      }
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(coverLetterData)]),
        name: 'invoice_cover_letter.pdf',
      });

      for (const f of invoice.files) {
        const file = await this.fileService.downloadFile({ id: f.id }).toPromise();
        files.push({
          file: new Blob([new Uint8Array(file.file.data)]),
          name: file.name,
        });
      }

      if (this.currentWorkspace === Workspace.Construction) {
        const budgetReportGroup = await this.budgetReport.export().catch((error) => {
          if (error instanceof PEBFinalizationError) {
            this.snackbar.open(`All tenants' PEBs must be finalized!`);
          }
          invoice.loadingExport = false;
          breakout = true;
        });
        const budgetReportBase64 = (await exportPDF(budgetReportGroup)).replace('data:application/pdf;base64,', '');
        const budgetReportByteCharacters = atob(budgetReportBase64);
        const budgetReportData = new Array(budgetReportByteCharacters.length);
        for (let i = 0; i < budgetReportByteCharacters.length; i++) {
          budgetReportData[i] = budgetReportByteCharacters.charCodeAt(i);
        }

        files.push({
          file: new Blob([new Uint8Array(budgetReportData)]),
          name: 'invoice_budget_report.pdf',
        });
      }

      if (breakout) {
        return;
      }

      let combinedFile;
      try {
        combinedFile = await this.fileService.combinePDFs(files).toPromise();
      } catch (e) {
        const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
        errorSnack.onAction().subscribe(async () => {
          this.snackbar.dismiss();
        });
        return;
      }

      if (!fileAction || fileAction === FileAction.Download) {
        saveAs(
          new Blob([new Uint8Array(combinedFile.data)]),
          `${parentTypeCode}${this.code}_invoice_${invoice.number}_v${invoice.revision}.pdf`
        );
        this.snackbar.open('Invoice has been downloaded');
        // await this.refresh();
      }
      invoice.loadingExport = false;
      if (!fileAction || fileAction === FileAction.Return) {
        const blob = new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' });
        const file = new File(
          [blob],
          `${parentTypeCode}${this.code}_invoice_${invoice.number}_v${invoice.revision}${
            complete ? '_processed' : ''
          }.pdf`
        );

        return file || [];
      }
    } else {
      if (fileAction === FileAction.Download) {
        this.snackbar.open(`No files were uploaded for this invoice!`);
      } else {
        return [];
      }
    }
  }
}
