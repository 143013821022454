import { Pipe, PipeTransform } from '@angular/core';
import { CostCode } from 'src/app/types';

@Pipe({
  name: 'filterCostCodeBySearchTerm',
})
export class FilterCostCodeBySearchTermPipe implements PipeTransform {
  transform(costCodes: CostCode[], searchTerm: string): CostCode[] {
    if (searchTerm) {
      return costCodes
        .map((costCode) => ({
          ...costCode,
          sub_cost_codes: costCode?.sub_cost_codes?.filter(
            (subCostCode) =>
              costCode?.code?.toLowerCase()?.includes(searchTerm?.toString()?.trim()?.toLowerCase()) ||
              costCode?.label?.toLowerCase()?.includes(searchTerm?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.code?.toLowerCase()?.includes(searchTerm?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.label?.toLowerCase()?.includes(searchTerm?.toString()?.trim()?.toLowerCase()) ||
              subCostCode?.description?.toLowerCase()?.includes(searchTerm?.toString()?.trim()?.toLowerCase())
          ),
        }))
        ?.filter((costCodes) => costCodes?.sub_cost_codes?.length);
    } else {
      return costCodes;
    }
  }
}
