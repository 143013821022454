import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { MeetingDialogComponent } from 'src/app/components';

@Component({
  selector: 'app-agenda-item-dialog',
  templateUrl: './agenda-item-dialog.component.html',
  styleUrls: ['./agenda-item-dialog.component.scss'],
})
export class AgendaItemDialogComponent implements OnInit {
  private _dialogTitle = 'Agenda Item';
  constructor(
    public dialogRef: MatDialogRef<MeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public agendaItem,
    private fb: FormBuilder
  ) {}

  action: string;

  agendaItemFormGroup: FormGroup = this.fb.group({
    description: [this.agendaItem && this.agendaItem.id ? this.agendaItem.description : '', [Validators.required]],
    duration: [this.agendaItem && this.agendaItem.id ? this.agendaItem.duration : '', [Validators.required]],
  });

  get description() {
    return this.agendaItemFormGroup.get('description');
  }
  get dialogTitle(): string {
    return `${this.action} ${this._dialogTitle}`;
  }
  get duration() {
    return this.agendaItemFormGroup.get('duration');
  }

  ngOnInit() {
    this.action = this.agendaItem && this.agendaItem.id ? 'Edit' : 'Add';
  }

  addDurationMinutes(minutes: number) {
    if (!this.duration.value) {
      this.duration.setValue(0);
    }
    const newValue = this.duration.value + minutes;
    this.duration.setValue(newValue < 0 ? 0 : newValue);
  }

  submit(): void {
    if (this.agendaItemFormGroup.valid) {
      const agendaItem: any = {
        description: this.description.value,
        duration: this.duration.value < 0 ? 0 : this.duration.value,
      };
      if (this.agendaItem && this.agendaItem.id) {
        agendaItem.id = this.agendaItem.id;
      }
      this.dialogRef.close(agendaItem);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
