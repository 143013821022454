<app-base-dialog [draggable]="true" [title]="'Item'" (closeDialog)="cancel()"></app-base-dialog>

<div *ngIf="selectedForm == 'release'" class="release-notes-dialog" mat-dialog-content>
  <form class="d-flex flex-column" [formGroup]="releasesFormGroup">
    <h6 class="mb-2 dkblue">Release Title</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <input matInput placeholder="Enter Release Title" formControlName="title" />
    </mat-form-field>
    <!--TODO add back in RELEASETAGS-->
    <!--          <div class="mb-4">-->
    <!--            <h6 class="mb-2 dkblue">Tags</h6>-->
    <!--            <mat-form-field appearance="outline" class="full-width">-->
    <!--              <mat-chip-list #chipList aria-label="Fruit selection">-->
    <!--                <mat-chip color="accent" [removable]="removable">-->
    <!--                  Test-->
    <!--                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
    <!--                </mat-chip>-->
    <!--                <input-->
    <!--                  placeholder="Search or Add Tags"-->
    <!--                  #tagInput-->
    <!--                  [matAutocomplete]="auto"-->
    <!--                  [matChipInputFor]="chipList"-->
    <!--                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
    <!--                  (matChipInputTokenEnd)="add($event)"-->
    <!--                />-->
    <!--              </mat-chip-list>-->
    <!--              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
    <!--                <mat-option> Test </mat-option>-->
    <!--              </mat-autocomplete>-->
    <!--              &lt;!&ndash; <input matInput placeholder="Search or Add Tags" /> &ndash;&gt;-->
    <!--            </mat-form-field>-->
    <!--          </div>-->
    <h6 class="mb-2 dkblue">Description of Release</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <textarea matInput placeholder="Enter a Description of the Release" formControlName="description"></textarea>
    </mat-form-field>

    <h6 class="mb-2 dkblue">Release Date</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <input matInput [matDatepicker]="releaseDate" placeholder="Enter Release Date" formControlName="release_date" />
      <mat-datepicker-toggle matSuffix [for]="releaseDate" color="accent"></mat-datepicker-toggle>
      <mat-datepicker #releaseDate [calendarHeaderComponent]="customHeader"></mat-datepicker>
    </mat-form-field>
  </form>
</div>

<div *ngIf="selectedForm == 'feature'" class="release-notes-dialog" mat-dialog-content>
  <form [formGroup]="featuresFormGroup">
    <div class="d-flex flex-column">
      <h6 class="mb-2 dkblue">Feature Title</h6>
      <mat-form-field appearance="outline" class="full-width mb-4">
        <input matInput placeholder="Enter Feature Title" formControlName="title" />
      </mat-form-field>

      <h6 class="mb-2 dkblue">Start Date</h6>
      <mat-form-field appearance="outline" class="full-width mb-4">
        <input matInput [matDatepicker]="startDate" placeholder="Enter Start Date" formControlName="start_date" />
        <mat-datepicker-toggle matSuffix [for]="startDate" color="accent"></mat-datepicker-toggle>
        <mat-datepicker #startDate [calendarHeaderComponent]="customHeader"></mat-datepicker>
      </mat-form-field>

      <h6 class="mb-2 dkblue">End Date</h6>
      <mat-form-field appearance="outline" class="full-width mb-4">
        <input matInput [matDatepicker]="endDate" placeholder="Enter End Date" formControlName="end_date" />
        <mat-datepicker-toggle matSuffix [for]="endDate" color="accent"></mat-datepicker-toggle>
        <mat-datepicker #endDate [calendarHeaderComponent]="customHeader"></mat-datepicker>
      </mat-form-field>

      <h6 class="mb-2 dkblue">Description of Feature</h6>
      <mat-form-field appearance="outline" class="full-width mb-4">
        <textarea matInput placeholder="Enter a Description of the Feature" formControlName="description"></textarea>
      </mat-form-field>

      <h6 class="mb-2 dkblue">Time Frame</h6>
      <div class="d-flex">
        <mat-button-toggle-group
          class="mb-4 d-flex"
          name="fontStyle"
          aria-label="Font Style"
          formControlName="timeframe_id"
        >
          <mat-button-toggle [value]="1" class="px-3">On Schedule</mat-button-toggle>
          <mat-button-toggle [value]="0" class="px-3">Behind Schedule</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</div>

<div *ngIf="selectedForm == 'bug'" class="release-notes-dialog" mat-dialog-content>
  <form class="d-flex flex-column" [formGroup]="bugsFormGroup">
    <h6 class="mb-2 dkblue">Bug Title</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <input matInput placeholder="Enter Bug Title" formControlName="title" />
    </mat-form-field>

    <h6 class="mb-2 dkblue">Identified Date</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <input
        matInput
        [matDatepicker]="startDate"
        placeholder="Enter Identified Date"
        formControlName="identified_date"
      />
      <mat-datepicker-toggle matSuffix [for]="startDate" color="accent"></mat-datepicker-toggle>
      <mat-datepicker #startDate [calendarHeaderComponent]="customHeader"></mat-datepicker>
    </mat-form-field>

    <h6 class="mb-2 dkblue">Fixed Date</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <input matInput [matDatepicker]="endDate" placeholder="Enter Fixed Date" formControlName="fixed_date" />
      <mat-datepicker-toggle matSuffix [for]="endDate" color="accent"></mat-datepicker-toggle>
      <mat-datepicker #endDate [calendarHeaderComponent]="customHeader"></mat-datepicker>
    </mat-form-field>

    <h6 class="mb-2 dkblue">Description of Bug</h6>
    <mat-form-field appearance="outline" class="full-width mb-4">
      <textarea matInput placeholder="Enter a Description of the Bug" formControlName="description"></textarea>
    </mat-form-field>

    <h6 class="mb-2 dkblue">Time Frame</h6>
    <div class="d-flex">
      <mat-button-toggle-group name="bug_time_frame" class="mb-4" formControlName="timeframe_id">
        <mat-button-toggle [value]="0" class="px-3">In Progress</mat-button-toggle>
        <mat-button-toggle [value]="1" class="px-3">Fixed</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</div>
<!-- Edit release document-->
<div class="d-flex flex-column mb-4" class="release-notes-dialog" *ngIf="data?.item?.id">
  <h6 class="mb-2 dkblue">Attachments</h6>
  <mat-chip-list class="mb-2">
    <button mat-button color="accent" class="mr-3 pl-2 pr-3" (click)="openUploadModal()">
      <mat-icon>attach_file</mat-icon>
      <span>Attach Files</span>
    </button>
    <app-file-chip *ngFor="let file of files" [file]="file" [removable]="true" (removeEvent)="deleteFile(file)">
    </app-file-chip>
  </mat-chip-list>
</div>

<!-- New release document -->
<div class="d-flex flex-column mb-4" *ngIf="!data?.item?.id">
  <h6 class="mb-2 dkblue">Attachments</h6>
  <mat-chip-list>
    <button type="button" mat-button color="accent" class="mr-2 pl-2 pr-3" (click)="openUploadModal()">
      <mat-icon>attach_file</mat-icon>
      <span>Attach Files</span>
    </button>
    <app-file-chip
      *ngFor="let file of attachedFiles"
      [file]="file"
      [removable]="true"
      (removeEvent)="removeAttachedFile(file)"
    ></app-file-chip>
  </mat-chip-list>
</div>
<div *ngIf="selectedForm == 'release'" mat-dialog-actions class="d-flex py-4">
  <button mat-button class="gray mr-2 flex-fill" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="success" class="ml-2 flex-fill" (click)="saveRelease()">Save Release</button>
</div>

<div *ngIf="selectedForm == 'feature'" mat-dialog-actions class="d-flex py-4">
  <button mat-button class="gray mr-2 flex-fill" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="success" class="ml-2 flex-fill" (click)="saveFeature()">Save Feature</button>
</div>

<div *ngIf="selectedForm == 'bug'" mat-dialog-actions class="d-flex py-4">
  <button mat-button class="gray mr-2 flex-fill" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="success" class="ml-2 flex-fill" (click)="saveBug()">Save Bug</button>
</div>
