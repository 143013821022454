import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ERROR_STATUS, FOUND_PAGE } from 'src/app/enums';
import { AuthService, LinkedTaskService, ProjectService, ProjectTaskService } from 'src/app/services';

const REDIRECT_APAGE = '/404';
@Injectable({
  providedIn: 'root',
})
export class ProjectGuard implements CanActivate {
  private _module_id: number;
  constructor(
    private _authService: AuthService,
    private _projectService: ProjectService,
    private _projectTaskService: ProjectTaskService,
    private _linkedTaskService: LinkedTaskService,
    private _router: Router
  ) {}

  private _checkError(err) {
    if (err.status === ERROR_STATUS.NOT_FOUND) {
      this._notFound();
      return false;
    }

    if (err.status === ERROR_STATUS.NOT_AUTHORIZED || err.status === ERROR_STATUS.FORBIDDEN) {
      this._notAuthorized();
      return false;
    }

    this._unknown();
    return false;
  }

  private _notAuthorized(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_AUTHORIZED,
      },
    });
  }

  private _notFound(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.NOT_FOUND,
      },
    });
  }

  private async _projectExist(projectId: number) {
    try {
      return this._projectService
        .getProjectByIdSuppressed(projectId, ['module_id'])
        .toPromise()
        .then(({ module_id }) => {
          if (module_id) {
            this._module_id = module_id;
            return true;
          }
          // Unknown reason
          this._unknown();
          return false;
        })
        .catch((err) => {
          return this._checkError(err);
        });
    } catch (error) {
      return this._checkError(error);
    }
  }

  private async _taskValidation(taskId: number, projectId: number): Promise<boolean | UrlTree> {
    return this._projectTaskService
      .loadTaskSuppressed(taskId, ['project_id'])
      .toPromise()
      .then(({ project_id }) => {
        return +project_id === +project_id;
      })
      .then((prev) => {
        if (prev) {
          // validate project
          return this._projectExist(projectId);
        }
        return false;
      })
      .catch((err) => {
        return this._checkError(err);
      });
  }

  private async _linkedTaskValidation(taskId: number, projectId: number): Promise<boolean | UrlTree> {
    return this._linkedTaskService
      .loadLinkedTaskSuppressed(taskId, ['project_id'])
      .toPromise()
      .then(({ project_id }) => {
        // TODO this looks to be wrong, but for the moment I'm copying from _taskValidation
        return +project_id === +project_id;
      })
      .then((prev) => {
        if (prev) {
          // validate project
          return this._projectExist(projectId);
        }
        return false;
      })
      .catch((err) => {
        return this._checkError(err);
      });
  }

  private _unknown(): void {
    void this._router.navigate([REDIRECT_APAGE], {
      queryParams: {
        status: FOUND_PAGE.UN_KNOWN,
      },
    });
  }

  private async _validateStaff(projectId: number): Promise<boolean> {
    if (this._authService?.isAppAdmin) {
      return this._projectExist(projectId);
    }

    const projectExists = await this._projectExist(projectId);

    if (projectExists && this._authService?.isUserWorkspaceStaff(this._module_id)) {
      return true;
    }

    this._notAuthorized();
    return false;
  }

  private async _validateTenant(projectId: number): Promise<boolean> {
    // if a tenant is able to see a project, this will pass
    return this._projectExist(projectId);
  }

  private async _validateVendor(projectId: number): Promise<boolean> {
    if (this._authService.isProjectVendor(projectId) || this._authService.isProjectEngineer(projectId)) {
      return this._projectExist(projectId);
    }
    this._notAuthorized();
    return false;
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (this._authService.isLoggedIn) {
      if (route?.queryParams?.task === 'valid') {
        return this._projectExist(route.params?.id);
      } else if (route?.params?.id && route?.params?.task_id) {
        return this._taskValidation(route.params.task_id, route.params.id);
      } else if (route?.params?.id && route?.params?.linked_task_id) {
        return this._linkedTaskValidation(route.params.linked_task_id, route.params.id);
      } else {
        const result = await this._projectExist(route.params?.id);
        return result;
      }
    }
  }
}
