import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { PebFileType, ResourceType, TaskAccessoryType, TaskStatus } from '../enums';
import { Task, TaskModalInjectionData } from '../types';
import { AuthService } from './auth.service';
import { DateService } from './date.service';
import { FileService } from './file.service';
import { ProductService } from './product.service';
import { ProjectTaskService } from './project-task.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionsService {
  constructor(
    public authService: AuthService,
    private dateService: DateService,
    private fileService: FileService,
    public productService: ProductService,
    public projectService: ProjectService,
    public taskService: ProjectTaskService,
    private snackbarService: MatSnackBar
  ) {}

  async createTaskAndAttachFiles(task, taskNote, unlinkedFiles = [], linkedFiles = [], followers = []): Promise<Task> {
    task.due_date = moment(task.due_date || this.dateService.addWeekdays(0)).format('YYYY-MM-DD');
    // Add all tenant department users to the task as followers
    const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);

    task.assigned_user_id =
      task.assigned_user_id ||
      accessoryData?.reviewChain?.find((review) => review.id !== this.authService.currentUser?.id)?.id ||
      null;

    const createdTask = await this.projectService.createTask(task).toPromise();
    let newTask: Task;
    if (createdTask) {
      const followerUserIds = followers.map((f) => f.id);
      await this.taskService.addFollowersToTask(createdTask.id, followerUserIds);

      if (taskNote) {
        const createdNote = await this.projectService
          .createNote(ResourceType.Task, createdTask.id, taskNote)
          .toPromise();

        if (createdNote) {
          if (accessoryData) {
            accessoryData.reviewFiles = (accessoryData.reviewFiles?.length && accessoryData.reviewFiles) || [];
          }

          const createdFiles =
            (await this.fileService.addFilesToNote(createdNote, createdTask.id, unlinkedFiles, linkedFiles)) || [];
          for (const f of createdFiles) {
            accessoryData?.reviewFiles?.push({
              id: f.file_id || f.id,
              name: f.name,
            });
          }

          if (unlinkedFiles?.length && accessoryData?.reviewFiles?.length) {
            accessoryData.reviewFiles = accessoryData.reviewFiles.map((file) => ({
              id: file.file_id || file.id,
              name: file.name,
            }));
            newTask = await this.projectService
              .updateTask({
                id: createdTask.id,
                accessory_data: (accessoryData && JSON.stringify(accessoryData)) || null,
              })
              .toPromise();
          }
        }
      }
      await this.taskService.loadTaskActivityLog(newTask || createdTask);
    }

    return newTask || createdTask;
  }

  public async changeTaskStatus(taskStatusId: TaskStatus, task: any, type: TaskAccessoryType) {
    const newTask = await this.taskService.changeTaskStatus(task, taskStatusId);
    if (type === TaskAccessoryType.Budget && newTask.status_id === TaskStatus.Complete) {
      newTask.is_locked = 1;

      await this.productService.unlockArfTasks(true, task);
    }
    const updatedTask = await this.projectService.updateTask(newTask).toPromise();
    await this.taskService.updateTask(updatedTask);
    this.snackbarService.open('Task Updated');

    // In some cases the selected task could change during the update call. We don't want to reset the task data if this is the case.
    if (task.id === updatedTask.id) {
      task = updatedTask;
    }
    await this.taskService.loadTaskActivityLog(task);

    return task;
  }

  public getTaskNoteText(type: TaskAccessoryType, numberOfFiles: number) {
    let fileType = '';
    switch (type) {
      case TaskAccessoryType.Arf:
        fileType = 'ARF';
        break;
      case TaskAccessoryType.CB:
        fileType = 'CB';
        break;
      case TaskAccessoryType.PEB:
        fileType = 'PEB';
        break;
      default:
        break;
    }

    fileType += ' ';
    return (
      (numberOfFiles && `Attached ${numberOfFiles}${fileType}file${numberOfFiles === 1 ? '' : 's'} to the task.`) ||
      null
    );
  }

  public getPebFileTaskData(fileType: PebFileType) {
    // Add data that will be the same for all file tasks here
    const taskData: TaskModalInjectionData = {
      dueDate: this.dateService.addWeekdays(3).toDate(),
      milestoneName: 'PEB Files',
      can_delete: 0,
    };

    // Add data that is file type specific here
    switch (fileType) {
      case PebFileType.BubbleDrawings:
        taskData.taskTitle = 'Bubble Drawings';
        taskData.assigned_user = this.projectService.currentSelectedProject.architect_id;
        taskData.taskDescription = 'This is a task description for Bubble Drawings';
        break;
      case PebFileType.SubleaseContract:
        taskData.taskTitle = 'Sublease';
        break;
      case PebFileType.ExhibitB:
        taskData.taskTitle = 'Exhibit B';
        break;
      case PebFileType.Reimbursement:
        taskData.taskTitle = 'Reimbursement';
        break;
      case PebFileType.Amortization:
        taskData.taskTitle = 'Amortization';
        break;
      default:
        taskData.taskTitle = 'Bubble Drawings';
        break;
    }
    return taskData;
  }
}
