import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { remove } from 'lodash';
import { AddFollowersComponent } from 'src/app/components';
import { ResourceType, UserType } from 'src/app/enums';
import { AuthService, BugReportService, FileService, ModalService } from 'src/app/services';
import { APIFilter, BugReport, InquiryType, UhatFileReference, User } from 'src/app/types';

@Component({
  selector: 'app-bug-report-dialog',
  templateUrl: './bug-report-dialog.component.html',
  styleUrls: ['./bug-report-dialog.component.scss'],
})
export class BugReportDialogComponent implements OnInit {
  @ViewChild('followers', { static: true })
  private _followers_component: AddFollowersComponent;
  constructor(
    public dialogRef: MatDialogRef<BugReportDialogComponent>,
    private router: Router,
    private bugReportService: BugReportService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private fileService: FileService,
    private modalService: ModalService
  ) {}

  // stores the error message submitted by the user
  files = [];
  subject: string;
  email: string;
  inquiryTypes: InquiryType[];
  inquiryTypeId: number;
  shouldContact: boolean;
  message: string;
  currentUser: User;
  submitting = false;
  isVendor = false;
  allowedUserTypeIds = [UserType.Everyone, UserType.Staff, UserType.Tenant];
  defaultUserTypeId = UserType.Everyone;

  async ngOnInit(): Promise<void> {
    const inquiryTypeFilters: APIFilter[] = [{ type: 'field', field: 'id', value: '4', match: '!=' }];
    this.inquiryTypes = await this.bugReportService.getInquiryTypes(['name'], inquiryTypeFilters).toPromise();
    this.currentUser = this.authService.getLoggedInUser();
    this.email = this.currentUser ? this.currentUser.email : null;
    // this.inquiryTypeId = 1;
    this.shouldContact = true;
    this.isVendor = this.authService?.currentUser?.user_type_id === +UserType.Vendor;
  }

  public get followers(): AbstractControl {
    return this._followers_component?.followers;
  }

  public async openUploadModal(): Promise<void> {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this.files = [...this.files, ...files.computerFiles];
    }
  }

  public removeFile(file: UhatFileReference): void {
    remove(this.files, (f) => f.name === file.name);
  }

  public isBugReportValid(): number {
    return this.message && this.email && this.inquiryTypeId;
  }

  // logs the error with the service before returning
  public async submit(): Promise<void> {
    if (!this.submitting && this.isBugReportValid()) {
      this.submitting = true;
      try {
        const reportToSend: BugReport = {
          subject: 'Bug Report',
          inquiry_type_id: this.inquiryTypeId,
          should_contact: this.shouldContact ? 1 : 0,
          message: this.message,
          route: this.router.url,
          file_count: this.files.length || 0,
          follower_ids: JSON.stringify(this.followers?.value?.map((follower: User) => follower.id) || []),
        };
        if (!this.currentUser || !this.currentUser.id) {
          reportToSend.contact_email = this.email;
        }
        const createdReport = await this.bugReportService
          .logError(reportToSend, !!(this.currentUser && this.currentUser.id))
          .toPromise();
        if (this.files && this.files.length > 0) {
          for (const f of this.files) {
            const file = await this.fileService.createFile(f, createdReport.id, ResourceType.BugReport).toPromise();
            await this.fileService
              .linkFile(file.file_id || file.id, createdReport.wo_id, ResourceType.WorkOrder)
              .toPromise();
          }
          await this.bugReportService.sendBugReportNotifications(createdReport.id).toPromise();
        }
        this.snackbar.open(`Your information was submitted!`);
        this.dialogRef.close();
      } catch (error) {
        this.submitting = false;
        throw error;
      }
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
