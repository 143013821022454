import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FileService, FileApprovalService, ProjectService } from 'src/app/services';
import { PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';

import { FileApproval } from 'src/app/models';
import { FileApprovalStatus, ResourceType } from 'src/app/enums';
import { PEBStatus } from 'src/app/workspaces/construction/enums';
import { FileApprovalItem, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-select-approval-review-dialog',
  templateUrl: './file-select-approval-review-dialog.component.html',
  styleUrls: ['./file-select-approval-review-dialog.component.scss'],
})
export class FileSelectApprovalReviewDialogComponent implements OnInit {
  public reviewDescription = 'Please review these forms and submit a response';

  public filesToReview: UhatFileReference[] = [];

  public selectedFiles: UhatFileReference[] = [];

  public reviewMessage: string;

  private reviewItem: FileApprovalItem;

  constructor(
    private dialogRef: MatDialogRef<FileSelectApprovalReviewDialogComponent>,
    private approvalService: FileApprovalService,
    private fileService: FileService,
    private projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private pebService: PEBService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    // Get approval detail data by querying for the approval id
    if (this.data.approval) {
      const fileApproval: FileApproval = this.data.approval;
      this.reviewDescription = fileApproval.description;
      this.filesToReview = fileApproval.files || [];
      this.approvalService.getApproval(+this.data.approval.id).subscribe((approval) => {
        this.reviewDescription = approval.description;
        this.filesToReview = approval.files || [];
        this.reviewItem = this.data.item;
      });
    }
  }

  public async submitReview() {
    // Update This Approval Items Status
    this.approvalService
      .updateApprovalItemStatus(+this.reviewItem.id, FileApprovalStatus.Approved, this.reviewMessage)
      .subscribe();

    // Link All Selected Files For This Approval
    for (const file of this.selectedFiles) {
      await this.fileService.linkFile(+file.id, this.reviewItem.id, ResourceType.FileSelectedItem).toPromise();
    }
    const selectedPebFile: UhatFileReference = this.selectedFiles[0];
    // Update Project PEB Status
    await this.projectService
      .updateProject(this.projectService.currentSelectedProjectId, { peb_status: PEBStatus.ApprovedByTenant }, [
        'peb_status',
      ])
      .toPromise();
    await this.pebService
      .getPebByRevisionFileId(selectedPebFile.id)
      .toPromise()
      .then(async (peb) => {
        if (peb && peb.id) {
          const stringToAppend =
            this.reviewMessage && this.reviewMessage.length > 0
              ? ` They left the comment(s) below.</i>\n\n${this.reviewMessage}`
              : '</i>';
          const finalCommentString = `<i>The tenant has selected PEB ${selectedPebFile.name}.${stringToAppend}`;
          await this.projectTenantService
            .approveAndSelectPeb(this.data.task.id, peb.id, finalCommentString)
            .toPromise();
          await this.projectService
            .updateProject(this.projectService.currentSelectedProjectId, {
              selected_peb_id: peb.id,
            })
            .toPromise();
        }
      });

    this.dialogRef.close(this.selectedFiles);
  }

  public rejectReview() {
    // Update Approval Item Status
    this.approvalService
      .updateApprovalItemStatus(+this.reviewItem.id, FileApprovalStatus.Rejected, this.reviewMessage)
      .subscribe(async (rejectReviewResult) => {
        // Update ProjectTenantConstruction PEB Status
        const comment = `<i>This tenant has rejected the PEBs for the reason(s) below.</i>\n\n${this.reviewMessage}`;
        await this.projectTenantService.rejectPeb(this.data.task.id, comment).toPromise();
        // Close dialog
        this.dialogRef.close(rejectReviewResult);
      });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public selectFile(file: UhatFileReference) {
    const selectedFile = this.selectedFiles.find((f) => f.id === file.id);
    if (selectedFile != null) {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== selectedFile.id);
    } else {
      this.selectedFiles.push(file);
    }
    if (this.selectedFiles.length > this.data.approval.num_files_to_select) {
      this.selectedFiles.shift();
    }
  }

  public canSubmit(): boolean {
    return this.selectedFiles.length === this.data.approval.num_files_to_select;
  }

  public isFileSelected(file: UhatFileReference): boolean {
    return this.selectedFiles.find((f) => f.id === file.id) != null;
  }
}
