<div
  id="account-review-dialog"
  data-test-id="account-review-dialog"
  class="account-review-dialog project-modal"
  style="min-width: 380px"
>
  <app-base-dialog title="Account Review" (closeDialog)="close()"></app-base-dialog>

  <div mat-dialog-content>
    <mat-horizontal-stepper labelPosition="bottom" linear #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="contactInfoFormGroup">
        <form [formGroup]="contactInfoFormGroup">
          <ng-template matStepLabel>Contact Info</ng-template>
          <h2 *ngIf="userExists" class="dkblue font-weight-semibold my-4">The user already has an account!</h2>
          <h2 *ngIf="!userExists" class="dkblue font-weight-semibold my-4">Confirm Information</h2>
          <div class="row mb-4">
            <div class="col">
              <h6 class="primary">Email</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" required="true" formControlName="email" />
                <mat-error *ngIf="email.errors?.required">Email Address is required</mat-error>
                <mat-error *ngIf="!email.errors?.required && email.errors?.email">Email address is invalid</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <h6 class="primary">Account Type</h6>
              <mat-form-field class="full-width" appearance="outline">
                <mat-select matNativeControl formControlName="account_type" (ngModelChange)="setAccountType()">
                  <mat-option *ngFor="let ut of userTypes" [value]="ut.id">{{ ut.name }} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6">
              <h6 class="primary">First Name</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" required="true" formControlName="first_name" />
                <mat-error *ngIf="first_name.errors?.required">First Name is required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <h6 class="primary">Last Name</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" required="true" formControlName="last_name" />
                <mat-error *ngIf="last_name.errors?.required">Last Name is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6">
              <h6 class="primary">Office Phone</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" formControlName="office_phone" />
                <!-- <mat-error *ngIf="office_phone.errors?.required">Office Phone is required</mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-6">
              <h6 class="primary">Cell Phone</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" formControlName="cell_phone" />
                <!-- <mat-error *ngIf="cell_phone.errors?.required">Cell Phone is required</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4" *ngIf="account_type.value !== 3">
            <div class="col">
              <h6 class="primary mb-1" [ngClass]="{ 'mb-2': !numberOfManagersFound || manager?.value?.id }">Manager</h6>
              <p class="dkgray mb-3" *ngIf="numberOfManagersFound && !manager?.value?.id">
                <i class="fa fa-exclamation-circle"></i>
                There are {{ numberOfManagersFound }} accounts with the name
                <span class="font-weight-semibold mr-1"
                  >{{ filteredManagers[0]?.first_name }} {{ filteredManagers[0]?.last_name }}</span
                >
              </p>
              <mat-form-field class="full-width" appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="Search Accounts"
                  formControlName="manager"
                  (ngModelChange)="managerChanged()"
                  [matAutocomplete]="managerAuto"
                />
                <mat-autocomplete #managerAuto="matAutocomplete" [displayWith]="managerValueMapper">
                  <mat-option
                    class="d-flex align-items-center mb-1"
                    *ngFor="let user of filteredManagers"
                    [value]="user"
                  >
                    <span class="d-flex flex-column py-2">
                      <h6 class="m-0 dkblue font-weight-semibold">{{ user.first_name }} {{ user.last_name }}</h6>
                      <h6 class="m-0 dkgray font-weight-normal">
                        <span
                          *ngIf="(user?.first_name || user?.last_name) && (user?.department_name || user?.company_name)"
                        >
                          {{ user?.user_type_id === 3 ? user?.company_name : user?.department_name }}
                        </span>
                        <span
                          *ngIf="(user?.first_name || user?.last_name) && (user?.department_name || user?.company_name)"
                          class="ml-1 mr-1"
                        >
                          |
                        </span>
                        <span *ngIf="(user?.first_name || user?.last_name) && user?.email">
                          {{ user?.email }}
                        </span>
                      </h6>
                    </span>
                  </mat-option>
                  <!-- <mat-option
[value]="null"
class="gray"
*ngIf="filteredManagers && (!manager.value || (manager.value && !manager.value.id))"
>
<i class="la la-plus"></i> &nbsp; New User
</mat-option> -->
                </mat-autocomplete>
                <!-- <mat-error *ngIf="account_type.value === 3 && (company.errors?.required || company.errors?.invalidCompany)">Company is required to select a manager</mat-error>
<mat-error *ngIf="(account_type.value === 1 || account_type.value === 2) && department_id.errors?.required">Department is required to select a manager</mat-error>
<mat-error *ngIf="((account_type.value === 3 && !company.errors?.required && !company.errors?.invalidCompany) || ((account_type.value === 1 || account_type.value === 2) && !department_id.errors?.required)) && manager.errors?.required">Manager is required</mat-error> -->
                <mat-error *ngIf="manager?.errors?.invalidManager">Must be an existing account</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-5">
            <button
              *ngIf="!userExists && !isLoading"
              mat-flat-button
              matStepperNext
              [disabled]="!contactInfoFormGroup.valid"
              [color]="contactInfoFormGroup.valid ? 'success' : null"
              type="button"
            >
              Next
            </button>
            <button *ngIf="userExists && !isLoading" mat-flat-button color="warn" (click)="rejectRequest()">
              Reject Request
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="locationFormGroup">
        <form [formGroup]="locationFormGroup" (ngSubmit)="submit()">
          <ng-template matStepLabel>Location</ng-template>
          <h2 *ngIf="userExists" class="dkblue font-weight-semibold my-4">The user already has an account!</h2>
          <h2 *ngIf="!userExists" class="dkblue font-weight-semibold my-4">Confirm Location</h2>
          <div class="row mb-4" *ngIf="account_type.value === 1 || account_type.value === 2">
            <div class="col-6 mb-4">
              <h6 class="primary">Building</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select matNativeControl (selectionChange)="getFloors()" formControlName="building_id">
                  <mat-option label="Building"></mat-option>
                  <mat-option *ngFor="let b of buildings" [value]="b.id">{{ b.name }} </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="building_id.errors?.required">Building is required</mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-6 mb-4">
              <h6 class="primary">Floor</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select matNativeControl (selectionChange)="getSuites()" formControlName="floor_id">
                  <mat-option label="Floor"></mat-option>
                  <mat-option *ngFor="let f of floors" [value]="f.id">{{ f.name }} </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="floor_id.errors?.required">Floor is required</mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-6">
              <h6 class="primary">Suite</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select matNativeControl (selectionChange)="getDepartments()" formControlName="suite_id">
                  <mat-option label="Suite"></mat-option>
                  <mat-option *ngFor="let s of suites" [value]="s.id">{{ s.name }} </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="suite_id.errors?.required">Suite is required</mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-6">
              <h6 class="primary">Department</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select matNativeControl formControlName="department_id">
                  <mat-option label="Department"></mat-option>
                  <mat-option *ngFor="let d of departments" [value]="d.id">{{ d.name }} </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="department_id.errors?.required">Department is required</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col" *ngIf="account_type.value === 2 || account_type.value === 3">
              <h6 class="primary">Company</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="company"
                  (ngModelChange)="companyChanged()"
                  [matAutocomplete]="companyAuto"
                />
                <mat-autocomplete #companyAuto="matAutocomplete" [displayWith]="companyValueMapper">
                  <mat-option *ngFor="let c of filteredCompanies" [value]="c">{{ c.name }} </mat-option>
                  <mat-option
                    [value]="null"
                    class="gray"
                    *ngIf="filteredCompanies && (!company.value || (company.value && !company.value.id))"
                    (click)="addNewCompany()"
                  >
                    <i class="la la-plus"></i> &nbsp; New Company
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="company.errors?.required">Company is required</mat-error>
                <mat-error *ngIf="company.errors?.invalidCompany">Must be an existing company</mat-error>
              </mat-form-field>
            </div>
            <div [ngClass]="'col-lg' + (account_type.value === 3 ? '-6' : '')">
              <h6 class="primary">Title</h6>
              <mat-form-field class="full-width" appearance="outline">
                <input matInput type="text" formControlName="title" />
                <!-- <mat-error *ngIf="title.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-4" [hidden]="account_type.value !== UserType.Staff">
            <div class="col">
              <h6 class="primary">Default Workspace</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select
                  matNativeControl
                  formControlName="default_module_id"
                  [required]="account_type.value === UserType.Staff"
                >
                  <mat-option *ngFor="let ws of workspaces" [value]="ws.id">{{ ws.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="default_module_id.errors?.required">Workspace is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row">
          <div class="col">
          <mat-form-field class="full-width" appearance="outline" *ngIf="account_type.value === 3">
          <mat-label>Company Address</mat-label>
          <input matInput type="text" required="true" formControlName="company_address">
          <mat-error *ngIf="company_address.errors?.required">Company Address is required</mat-error>
          </mat-form-field>
          </div>
          </div> -->
          <div class="mt-5">
            <button mat-flat-button matStepperPrevious>Back</button> &nbsp;
            <button
              *ngIf="!userExists"
              mat-flat-button
              matStepperNext
              [disabled]="!locationFormGroup.valid"
              [color]="locationFormGroup.valid ? 'success' : null"
              type="button"
            >
              Next
            </button>
            <button *ngIf="userExists" mat-flat-button color="warn" (click)="rejectRequest()">Reject Request</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="rolesFormGroup">
        <form [formGroup]="rolesFormGroup" (ngSubmit)="submit()">
          <ng-template matStepLabel>Roles</ng-template>
          <br />
          <h2 *ngIf="userExists" class="dkblue font-weight-semibold">The user already has an account!</h2>
          <h2 *ngIf="!userExists" class="dkblue font-weight-semibold">Select roles for this user</h2>
          <br />
          <mat-label>Roles</mat-label>
          <div class="row" *ngIf="roles && roles.length > 0">
            <div *ngFor="let r of roles" class="col-lg-12">
              <mat-checkbox (change)="editUserRoles(r, $event.checked)" [checked]="r.is_checked" [disabled]="userExists"
                >{{ r.name }}
              </mat-checkbox>
            </div>
          </div>
          <div class="row" *ngIf="!roles || roles.length === 0">
            <div>No roles are available</div>
          </div>
          <!-- <mat-error *ngIf="module_access.errors?.required">Roles is required</mat-error> -->
          <br /><br />
          <div>
            <button mat-flat-button matStepperPrevious>Back</button> &nbsp;
            <button
              *ngIf="!userExists"
              mat-flat-button
              matStepperNext
              color="success"
              type="button"
              (click)="submit()"
              [disabled]="userExists"
            >
              Submit
            </button>
            <button *ngIf="userExists" mat-flat-button color="warn" (click)="rejectRequest()">Reject Request</button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
