import { Component, Input, OnInit } from '@angular/core';
import { FileService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent implements OnInit {
  @Input() files;
  constructor(private _fileService: FileService) {}

  ngOnInit(): void {}

  public async previewFile(file: UhatFileReference) {
    await this._fileService.previewFile(file);
  }
}
