import { Pipe, PipeTransform } from '@angular/core';
import { MeetingListFilterPipe } from 'src/app/pipes';

@Pipe({
  name: 'meetingDateFilter',
})
export class MeetingDateFilterPipe implements PipeTransform {
  transform(dates: any, meetings: any, searchTerm: string, myMeetings: number): any {
    if (searchTerm || myMeetings) {
      dates = dates.filter(
        (date) => new MeetingListFilterPipe().transform(meetings[date], searchTerm, myMeetings).length
      );
    }

    return dates;
  }
}
