<div #mainScreen (window:resize)="onResize($event)" class="container-fliud project-invoices p-4">
  <div
    *ngIf="currentWorkspace === Workspace.Construction && authService?.currentUser?.user_type_id === 1"
    class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4"
  >
    <button [routerLink]="['/projects/' + project?.id + '/peb']" mat-button color="primary" class="flex-grow-1 mr-1">
      PEB
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
      Bid Packages
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/construction-budget']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Construction Budget
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Proposal Requests
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/change-orders']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Change Orders
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/invoices']"
      mat-button
      color="primary"
      class="flex-grow-1 bg-white mx-1"
    >
      Invoices
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/budget']" mat-button color="primary" class="flex-grow-1 ml-1">
      Budget Overview
    </button>
  </div>
  <header class="d-flex full-width align-items-top mt-1 border-bottom border-gray mb-4">
    <div class="d-flex flex-column mr-auto">
      <h4 class="dkblue">Invoices</h4>
      <p class="dkgray">View and manage invoices from each supplier.</p>
    </div>
    <div class="text-right list-filters">
      <button
        mat-flat-button
        color="success"
        class="ml-3"
        *ngIf="canCreateInvoices"
        (click)="createInvoice()"
        [disabled]="currentWorkspace !== Workspace.Construction && !awardedParents?.length"
      >
        + Invoice
      </button>
    </div>
  </header>
  <div class="row mb-4">
    <ng-container *ngFor="let total of totals">
      <div
        class="col"
        *ngIf="
          (total.type === 'retainage-wh' && currentWorkspace === Workspace.Construction) ||
          total.type !== 'retainage-wh'
        "
      >
        <div class="card br-10">
          <div class="card-body">
            <h6 class="primary">{{ total.title }}</h6>
            <div class="h2 mb-0">
              {{ filteredParents | projectInvoiceTotals: currentWorkspace:total.type | USDollar: 2:true }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <header class="d-flex component-subheader align-items-start mb-3 position-relative">
    <span *ngIf="invoiceStatusInfo[0].count" class="pulse-dot-danger"></span>
    <button
      mat-stroked-button
      type="button"
      color="primary"
      class="dkblue px-5 overflow-visible bg-white"
      [matMenuTriggerFor]="selectInvoiceStatus"
      *ngIf="divWidth < 1000"
    >
      <span>
        {{ selectedInvoiceStatus.name }}
      </span>
      <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
        {{ selectedInvoiceStatus.count }}
      </span>
    </button>
    <mat-menu #selectInvoiceStatus="matMenu">
      <button mat-menu-item class="dkblue" *ngFor="let s of invoiceStatusInfo" (click)="selectStatus(s)">
        <span>
          {{ s.name }}
        </span>
        <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
          {{ s.count }}
        </span>
      </button>
    </mat-menu>
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      (change)="selectStatus($event.value)"
      class="button-group-filters font-weight-semibold mr-3"
      [value]="selectedInvoiceStatuses"
      *ngIf="divWidth >= 1000"
      multiple
    >
      <mat-button-toggle
        *ngFor="let s of invoiceStatusInfo"
        [value]="s"
        class="dkblue px-3 overflow-visible d-flex align-items-center"
      >
        <mat-checkbox
          class="mr-2"
          style="margin-top: -3px"
          [checked]="s.isSelected"
          (click)="preventDefault($event)"
        ></mat-checkbox>
        <span>
          {{ s.name }}
        </span>
        <span class="ml-1 bg-ltltltblue dkblue br-5 pl-2 pr-2">
          {{ s.count }}
        </span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="ml-auto d-flex align-items-center">
      <button mat-button class="ml-3" color="primary" type="button" (click)="toggleViewInvoices()">
        {{ hasAOpenInvoice ? 'Collapse All' : 'Expand All' }}
      </button>
      <button
        mat-stroked-button
        type="button"
        color="primary"
        class="pl-3 pr-2 ml-3 bg-white"
        [matMenuTriggerFor]="selectParentMenu"
      >
        <span> {{ selectedParent.label }} </span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #selectParentMenu="matMenu">
        <button mat-menu-item class="dkblue font-weight-semibold" (click)="selectParent(allParentsOption)">
          {{ allParentsOption.label }}
        </button>
        <button
          mat-menu-item
          class="dkblue d-flex flex-column py-3"
          style="height: auto"
          *ngFor="let p of awardedParents"
          (click)="selectParent(p)"
        >
          <h6 class="dkblue m-0">
            {{ currentWorkspace === Workspace.Construction ? p.awarded_company_name : p.company?.name }}
          </h6>
          <h6 class="dkgray font-weight-normal mb-1">
            {{ currentWorkspace === Workspace.Construction ? p.trade_name : p.code }}
          </h6>
        </button>
      </mat-menu>
      <mat-form-field class="ml-3" appearance="outline">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <mat-icon *ngIf="searchString" (click)="handleSearchStringCancel()" class="ltgray pointer" matSuffix>
          cancel
        </mat-icon>
        <input matInput [(ngModel)]="searchString" (ngModelChange)="filterInvoices()" placeholder="Search Invoices" />
      </mat-form-field>
    </div>
  </header>
  <div class="m-portlet mb-4" *ngFor="let p of filteredParents">
    <div class="m-portlet__body px-3 py-3 item-no-hover position-relative" [ngClass]="{ 'item-last': !p.expanded }">
      <div class="row align-items-center">
        <div
          (click)="p.child_request?.id || p.child_project?.id ? null : toggleParentExpansion(p)"
          class="col-4 d-flex align-items-center"
          [ngClass]="{ pointer: !p.child_request?.id && !p.child_project?.id }"
        >
          <button mat-icon-button color="primary" class="mr-2" *ngIf="!p.child_request?.id && !p.child_project?.id">
            <span *ngIf="p.hasNewInvoice && !p.expanded" class="pulse-dot-danger"></span>
            <mat-icon>
              {{ p.expanded ? 'expand_more' : 'keyboard_arrow_right' }}
            </mat-icon>
          </button>
          <mat-icon *ngIf="p.child_request?.id || p.child_project?.id" class="ltblue ml-2 mr-4">{{
            p.child_project?.id ? 'assignment_turned_in' : 'assignment_late'
          }}</mat-icon>
          <span>
            <h5
              class="mb-1 dkblue font-weight-semibold d-flex align-items-center"
              *ngIf="!p.child_request?.id && !p.child_project?.id"
            >
              {{ p.awarded_company_name || p.company?.name }}
              <span *ngIf="p.description"> &nbsp;| {{ p.description }}</span>
            </h5>
            <h6 style="display: inline-block">
              <span *ngIf="currentWorkspace === Workspace?.Construction">{{ p?.trade_name }}</span>
              <span *ngIf="currentWorkspace !== Workspace?.Construction">{{
                p?.tenant?.tenant_name ? p.tenant.tenant_name : 'UHAT'
              }}</span>
            </h6>
            <a
              href="#"
              target="_blank"
              class="text-decoration-none ltblue pointer"
              *ngIf="p.child_request?.id || p.child_project?.id"
              [routerLink]="
                p.child_project?.id ? '/projects/' + p.child_project?.id : '/requests/' + p.child_request?.id
              "
            >
              <h6 class="font-weight-normal m-0 d-flex align-items-center hover-u" *ngIf="p.child_project?.id">
                <span class="mr-1 font-weight-semibold"> PJ-{{ p.child_project?.code }} </span>
                <span> | {{ p.child_project?.title }} </span>
              </h6>
              <h6
                class="font-weight-normal m-0 d-flex align-items-center hover-u"
                *ngIf="!p.child_project?.id && p.child_request?.id"
              >
                <span class="mr-1 font-weight-semibold"> {{ p.child_request?.code }} </span>
                <span> | {{ p.child_request?.short_description }} </span>
              </h6>
            </a>
          </span>
          <span
            class="ml-auto bg-ltltltblue dkblue br-5 px-2 lh-18 font-weight-semibold"
            style="font-size: 0.9rem"
            *ngIf="!p.child_request?.id && !p.child_project?.id"
          >
            {{ p.filteredInvoiceCount || 0 }} of {{ p.invoiceCount || 0 }}
          </span>
        </div>
        <div class="col">
          <h6 class="dkblue mb-1 font-weight-semibold">
            Total Contract Sum
            <i
              *ngIf="currentWorkspace !== Workspace.Construction"
              class="fa fa-info-circle pointer ml-1 gray"
              [matMenuTriggerFor]="tcs_info"
            ></i>
            <mat-menu #tcs_info="matMenu" xPosition="before">
              <div class="p-2 d-flex flex-column" style="width: 200px">
                <p class="dkblue mb-2 font-weight-semibold">Total Contract Sum</p>
                <p class="dkgray mb-2 font-weight-normal">Total Contract Sum does not include Shipping or Sales Tax.</p>
              </div>
            </mat-menu>
          </h6>
          <h5 class="m-0 dkblue font-weight-normal">
            {{
              +p.total_contract_sum || (p.child_request?.id || p.child_project?.id ? 0 : +p.awarded_amount) || 0
                | USDollar: 2:true
            }}
          </h5>
        </div>
        <div *ngIf="currentWorkspace === Workspace.Construction" class="col">
          <h6 class="dkblue mb-1 font-weight-semibold">Retainage WH</h6>
          <h5 class="m-0 dkblue font-weight-normal">
            {{ +p.retainage || 0 | USDollar: 2:true }}
          </h5>
        </div>
        <div class="col">
          <h6 class="dkblue mb-1 font-weight-semibold">Billed-To-Date</h6>
          <h5 class="m-0 dkblue font-weight-normal">
            {{ +p.billed || 0 | USDollar: 2:true }}
          </h5>
        </div>
        <div class="col">
          <h6 class="dkblue mb-1 font-weight-semibold">Balance-To-Finish</h6>
          <h5 class="m-0 dkblue font-weight-normal">
            {{
              (p.status_id !== BidPackageStatus.CLOSED &&
                (+p.total_contract_sum || (p.child_request?.id || p.child_project?.id ? 0 : +p.awarded_amount) || 0) -
                  (+p.billed || 0)) ||
                0 | USDollar: 2:true
            }}
          </h5>
        </div>
        <div class="col-auto">
          <app-bid-package-invoice-menu
            [currentWorkspace]="currentWorkspace"
            [bidPackage]="p"
            (bidPackageUpdated)="updateBidPackageStatus($event)"
          >
          </app-bid-package-invoice-menu>
        </div>
      </div>
    </div>
    <span *ngIf="p.expanded && p.filteredInvoiceCount > 0">
      <div class="m-portlet__body table-header bg-shade-ltblue item-no-hover p-0 pl-3 pr-3">
        <div class="row align-items-center">
          <div
            class="col-1 pt-1 pb-1 d-flex align-items-center header-item"
            [ngClass]="{ sortedBy: sortByField === 'number' }"
          >
            <h6 class="m-0 text-truncate header-title mr-auto" (click)="updateSortByField('number')">Inv #</h6>
            <i *ngIf="sortByField === 'number'" matRipple class="material-icons" (click)="updateSortByField('number')">
              {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
            </i>
          </div>
          <div
            class="col-3 pt-1 pb-1 d-flex header-item align-items-center"
            [ngClass]="{ sortedBy: sortByField === 'title' }"
            (click)="updateSortByField('title')"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Description</h6>
            <i *ngIf="sortByField === 'title'" matRipple class="material-icons">
              {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
            </i>
          </div>
          <div
            class="col pt-1 pb-1 d-flex header-item align-items-center"
            [ngClass]="{ sortedBy: sortByField === 'received_date' }"
            (click)="updateSortByField('received_date')"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Submitted</h6>
            <i *ngIf="sortByField === 'received_date'" matRipple class="material-icons">
              {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
            </i>
          </div>
          <div
            class="col pt-1 pb-1 d-flex header-item align-items-center"
            [ngClass]="{ sortedBy: sortByField === 'total' }"
            (click)="updateSortByField('total')"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Total Amount</h6>
            <i *ngIf="sortByField === 'total'" matRipple class="material-icons">
              {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
            </i>
          </div>
          <div
            class="col pt-1 pb-1 d-flex header-item align-items-center"
            *ngIf="currentWorkspace === Workspace.Construction"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Retainage</h6>
          </div>
          <div
            class="col pt-1 pb-1 d-flex header-item align-items-center"
            [ngClass]="{ sortedBy: sortByField === 'amount_due' }"
            (click)="updateSortByField('amount_due')"
          >
            <h6 class="m-0 text-truncate header-title mr-auto">Amount Due</h6>
            <i *ngIf="sortByField === 'amount_due'" matRipple class="material-icons">
              {{ sortDirection === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
            </i>
          </div>
          <div class="col-2 pt-1 pb-1 d-flex align-items-center border-none" style="width: 205px">
            <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
          </div>
        </div>
      </div>
      <section *ngFor="let invoiceType of p.invoiceTypes" [hidden]="!invoiceType?.filteredInvoiceCount">
        <div class="m-portlet__body table-header bg-shade-ltblue item-no-hover p-0 pl-3 pr-3">
          <div class="row align-items-center">
            <div class="col pt-1 pb-1 d-flex align-items-center header-item">
              <h6 class="m-0 text-truncate header-title mr-auto">
                {{ getInvoiceTypeTitle(invoiceType) }}
              </h6>
            </div>
          </div>
        </div>
        <div
          class="m-portlet__body item pl-3 pr-3 pt-2 pb-2"
          [ngClass]="{
            'bg-shade-red': i.status_id === InvoiceStatus.New && isWorkspaceStaff
          }"
          *ngFor="
            let i of invoiceType.filteredInvoices | orderByField: sortByField:sortDirection;
            let last = last;
            let index = index
          "
        >
          <div class="row align-items-center">
            <div class="col-1 d-flex text-left">
              <p (click)="viewInvoice(i)" class="m-0 ltblue pointer font-weight-semibold hover-u d-block text-truncate">
                {{ i.number }}
              </p>
            </div>
            <div class="col-3">
              <p (click)="viewInvoice(i)" class="m-0 dkblue font-weight-semibold hover-u">
                {{ i.title }}
              </p>
            </div>
            <div class="col text-left">
              <p class="m-0 dkblue">
                {{ i.received_date | date: 'MMM dd, yyyy' }} {{ i.fiscal_year ? '(FY' + i.fiscal_year + ')' : '' }}
              </p>
            </div>
            <div class="col text-left">
              <p class="m-0 dkblue font-weight-normal">
                {{ i.total | currency: 'USD' }}
              </p>
            </div>
            <div class="col text-left" *ngIf="currentWorkspace === Workspace.Construction">
              <p class="m-0 dkblue font-weight-normal">
                {{ i.retainage | currency: 'USD' }}
              </p>
            </div>
            <div class="col text-left">
              <p class="m-0 dkblue font-weight-semibold">
                {{ +i.total - +i.retainage + +i.tax + +i.shipping | currency: 'USD' }}
              </p>
            </div>
            <div class="col-2 d-flex align-items-center">
              <app-invoice-menu
                [invoice]="i"
                [project]="project"
                (updateInvoice)="refresh()"
                [displayInvoiceButton]="true"
                style="width: 100%"
              >
              </app-invoice-menu>
            </div>
          </div>
        </div>
      </section>
    </span>
  </div>
  <!-- <div *ngIf="project && project.module_id !== Workspace.Construction" class="row">
    <div class="col text-center">
      <h5 class="m-0 dkblue font-weight-normal mt-5 pointer lh-28">
        Project Invoices is currently <strong>Under Construction</strong>.
        <br />
        We should have it back up soon. Thank you for your patience.
      </h5>
    </div>
  </div> -->
</div>
