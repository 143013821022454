<ng-container *ngIf="isReadyToRender">
  <div data-test-id="milestone-task-container" id="milestone-task-container" class="milestone-task-container pl-2">
    <div
      class="mb-2"
      [ngClass]="{ 'mb-5': isProjectVendor }"
      *ngIf="(tasks | tasksFilter: taskService.filterSettings).length > 0 || userIsStaff"
    >
      <div class="d-flex milestone-header align-items-center mb-1">
        <div class="d-flex mr-auto">
          <mat-icon (click)="toggleExpansion()" class="d-inline-block align-middle pointer" *ngIf="!isExpanded"
            >chevron_right</mat-icon
          >
          <mat-icon (click)="toggleExpansion()" class="d-inline-block align-middle pointer" *ngIf="isExpanded"
            >expand_more</mat-icon
          >
          <h5
            (click)="toggleExpansion()"
            class="font-weight-semibold dkblue m-0 d-inline-block align-middle"
            [ngClass]="{
              'not-allowed': taskRanksAreUpdating,
              pointer: !taskRanksAreUpdating
            }"
          >
            <span class="mr-3"> {{ milestoneData?.name || '' }}</span>
            <small class="dkgray">{{ taskNumberLabelText }}</small>
          </h5>
          <span class="milestone-status">
            <mat-icon
              class="green d-inline-block align-middle m-0 ml-3"
              *ngIf="milestoneStatus === MilestoneStatus.Complete"
              matTooltip="Milestone Complete"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              check
            </mat-icon>
            <mat-icon
              class="red d-inline-block align-middle m-0 ml-3"
              *ngIf="milestoneStatus === MilestoneStatus.OnHold"
              matTooltip="Milestone On Hold"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
            >
              flag
            </mat-icon>
          </span>
        </div>
        <div
          matRipple
          (click)="openEditMilestoneDialog()"
          *ngIf="isStaffOnlyModule"
          class="d-flex flex-column justify-content-center p-2 br-5 pointer milestone-progress ease"
          matTooltip="{{ percentageOfTasksDone }}% Complete"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          <mat-spinner *ngIf="taskRanksAreUpdating" color="accent" [diameter]="15"></mat-spinner>
          <mat-progress-bar
            *ngIf="!taskRanksAreUpdating"
            class="ml-4 full-width"
            color="primary"
            [value]="percentageOfTasksDone"
          >
          </mat-progress-bar>
        </div>

        <div class="d-flex align-items-center">
          <button
            (click)="openEditMilestoneDialog()"
            [disabled]="!userIsStaff"
            *ngIf="milestoneData.start_date || milestoneData.end_date"
            mat-button
            color="primary"
            class="lh-26 ml-2 px-2 dkblue"
          >
            {{ milestoneData.start_date | date: (milestoneData.end_date ? 'MM/dd' : 'MM/dd/yyyy')
            }}{{ milestoneData.start_date && milestoneData.end_date ? ' - ' : ''
            }}{{ milestoneData.end_date | date: 'MM/dd/yyyy' }}
          </button>
          <button
            (click)="openEditMilestoneDialog()"
            *ngIf="userIsStaff && !milestoneData.start_date && !milestoneData.end_date"
            mat-button
            color="warn"
            class="lh-26 ml-2 px-2"
          >
            Set Milestone Dates
          </button>
          <button
            mat-icon-button
            [matMenuTriggerFor]="milestone_menu"
            color="primary"
            matTooltip="More"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            [disabled]="!userIsStaff"
            class="ml-1"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #milestone_menu="matMenu" xPosition="before">
            <button mat-menu-item class="dkblue" (click)="openEditMilestoneDialog()">
              <mat-icon>edit</mat-icon>
              <span> Edit Milestone </span>
            </button>
            <button mat-menu-item class="dkblue" (click)="createMeetingAgendaFromMilestone(milestoneData)">
              <mat-icon>event_notes</mat-icon>
              <span> Add to Meeting Agenda </span>
            </button>
            <!-- <button mat-menu-item class="dkblue" (click)="startConversation(milestoneData.name)">
          <mat-icon>mail</mat-icon>
          <span>
            Start a Conversation
          </span>
        </button> -->
            <button mat-menu-item *ngIf="milestoneStatus !== MilestoneStatus.Complete" (click)="completeMilestone()">
              <mat-icon>check_circle</mat-icon>
              <span> Complete All Milestone Tasks </span>
            </button>
            <button mat-menu-item (click)="deactivateMilestone()">
              <mat-icon>delete</mat-icon>
              <span> Remove Milestone </span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div
        class="m-portlet mb-1"
        cdkDropList
        [cdkDropListData]="{ mileStoneId: milestoneData.id, tasks: tasks }"
        (cdkDropListDropped)="handleTaskMove($event)"
        m-portlet="true"
      >
        <!-- Expanded Tasks View -->
        <div *ngIf="isExpanded">
          <div
            [hidden]="false"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragData]="task"
            [cdkDragDisabled]="!isProjectAdmin || taskRanksAreUpdating"
            *ngFor="let task of tasks | tasksFilter: taskService.filterSettings; index as i; let last = last"
            [id]="'taskElement' + task.id"
            class="m-portlet__body task p-0 bg-white border-bottom border-gray"
            [ngClass]="{
              'task-first': i === 0,
              'task-last': last,
              'open-task': selectedTaskId === task.id,
              'task-selected': selectedTaskId === task.id,
              'task-complete': task.status_id === 3,
              draggable: editTasks,
              'not-allowed': taskRanksAreUpdating
            }"
          >
            <!-- List of Tasks -->
            <app-task-view
              (taskUpdate)="refreshMilestoneStatus()"
              [taskData]="task"
              [isUpdatingTaskRank]="taskRanksAreUpdating"
              (convertedToLinkedTask)="onTaskConverted($event)"
              (clickedTask)="clickedTask($event)"
              (taskDeleted)="taskDeletedEvent($event)"
            ></app-task-view>
            <!-- <div class="task-placeholder ease" *cdkDragPlaceholder></div> -->
          </div>
          <div class="m-portlet__body newTask p-0" *ngIf="isCreatingTask">
            <!-- ADD NEW TASK -->
            <app-new-task-view
              [milestoneId]="milestoneData.id"
              (taskCreated)="onTaskAdded($event)"
              (cancelButtonClicked)="closeCreateTaskComponent()"
            ></app-new-task-view>
          </div>
        </div>
      </div>
      <div class="row m-0" *ngIf="!isCreatingTask && userIsStaff && isExpanded">
        <a class="col add-task green ease font-weight-semibold full-width" (click)="createTask()"> + Task </a>
      </div>
    </div>
  </div>
</ng-container>
