export enum DISPATCH_FILTER_KEY {
  DAY = 'day',
  ISO_WEEK = 'isoWeek',
  MONTH = 'month',
  STATUS_ID = 'status_id',
  SUBMITTED = 'submitted',
  TYPE = 'type',
  WEEK = 'week',
  WORKSPACE_ID = 'workspace_id',
}
