import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { sumBy } from 'lodash';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { catchError, map } from 'rxjs/operators';
import { FileAttachmentDialogComponent } from 'src/app/components';
import {
  BidContractStatus,
  BidContractType,
  ResourceType,
  TaskAccessoryType,
  TaskReviewStatus,
  UserType,
} from 'src/app/enums';
import { USDollarPipe } from 'src/app/pipes';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  HandleErrorService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { BidContract, InsuranceRequirements, Task, TaskAccessoryData } from 'src/app/types';

@Component({
  selector: 'app-edit-contract-dialog',
  templateUrl: './edit-contract-dialog.component.html',
  styleUrls: ['./edit-contract-dialog.component.scss'],
})
export class EditContractDialogComponent implements OnInit {
  private projectFields: string[] = [
    `code`,
    `title`,
    `scope_of_work`,
    `building`,
    `floor`,
    `suite`,
    `department`,
    `project_manager{first_name,last_name,email,company{name}}`,
    `cfmo{first_name,last_name,title}`,
  ];
  private bidFields: string[] = [
    `bid_package_id`,
    `bid_package{number}`,
    `project{${this.projectFields.join(',')}}`,
    `trade{name}`,
    `contact{first_name,last_name,email,title}`,
    `company{name}`,
    `amount`,
    `description`,
    `contract_revision`,
    'contract_task_id',
    'contract_task{status_id,accessory_data}',
    'files',
  ];
  private bidContractFields: string[] = [
    `type_id`,
    `status_id`,
    `bid{${this.bidFields.join(',')}}`,
    `contract_date`,
    `proof_of_insurance_file_id`,
    `proof_of_insurance`,
    `proof_of_insurance_file_ids`,
    `proof_of_insurance_files`,
    `performance_bond_file_id`,
    `performance_bond`,
    `defect_bond_file_id`,
    `defect_bond`,
    `payment_bond_file_id`,
    `payment_bond`,
    `insurance_requirement_ids`,
    `contract_sum_confirmed`,
    `summary_completed`,
    `allowances_completed`,
    `unit_prices_completed`,
    `insurance_policy_completed`,
    `summary`,
    `allowances{name,amount}`,
    `unit_prices{name,units,price_per_unit}`,
    `insurance_policy_id`,
    `insurance_policy{id,name}`,
    `vendor_signature_text`,
    `vendor_signed_datetime`,
    `trust_signature_text`,
    `executed_datetime`,
  ];

  public insuranceRequirementIds = [];
  private USDollarPipe = new USDollarPipe();

  private currentUserId: number;
  public loading: boolean;
  public unsavedChangesExist: boolean;
  public totalStepCount: number;
  public contract: BidContract;
  public contractSum: number;
  public insurancePolicyOptions = [];
  public insuranceRequirements: InsuranceRequirements[] = [];
  public isCurrentSigner: boolean;
  public expeditingReview: boolean;

  @ViewChild('pdf', { static: true }) pdf;

  get typeId() {
    return this.contract?.type_id;
  }
  get statusId() {
    return this.contract?.status_id;
  }
  get contractDate() {
    return this.contract?.contract_date;
  }
  get projectManager() {
    return this.contract?.bid?.project?.project_manager;
  }
  get cfmo() {
    return this.contract?.bid?.project?.cfmo;
  }
  get bidCompany() {
    return this.contract?.bid?.company;
  }
  get bidContact() {
    return this.contract?.bid?.contact;
  }
  get project() {
    return this.contract?.bid?.project;
  }
  get completedStepCount() {
    const isMedOrLarge = [BidContractType.Medium, BidContractType.Large].indexOf(this.contract?.type_id) > -1;
    return (
      (this.awaitingReview && this.contract?.contract_sum_confirmed ? 1 : 0) +
      (this.awaitingReview && this.contract?.summary_completed ? 1 : 0) +
      (this.awaitingReview && isMedOrLarge && this.contract?.allowances_completed ? 1 : 0) +
      (this.awaitingReview && isMedOrLarge && this.contract?.unit_prices_completed ? 1 : 0) +
      (this.awaitingReview && isMedOrLarge && this.contract?.insurance_policy_completed ? 1 : 0) +
      (this.statusId === BidContractStatus.SentToVendor && this.contract?.vendor_signature_text ? 1 : 0) +
      (this.statusId === BidContractStatus.SentToVendor && this.insuranceFilesUploaded ? 1 : 0) +
      (this.statusId === BidContractStatus.SignedByVendor && this.contract?.trust_signature_text ? 1 : 0)
    );
  }
  get isReadyToSubmit() {
    return this.completedStepCount === this.totalStepCount;
  }
  get isReadyToSignByVendor() {
    return (
      this.currentUserId === this.bidContact?.id &&
      this.contract?.vendor_signature_text &&
      (this.contract?.proof_of_insurance || this.contract?.proof_of_insurance_files?.length)
    );
  }
  get isReadyToExecute() {
    return this.currentUserId === this.trustSignee?.id && this.contract?.trust_signature_text;
  }
  get currentContract(): BidContract {
    return {
      bid_id: this.contract.bid.id,
      type_id: this.typeId,
      status_id: this.statusId,
      contract_sum_confirmed: this.contract.contract_sum_confirmed ? 1 : 0,
      summary_completed: this.contract.contract_sum_confirmed ? 1 : 0,
      allowances_completed: this.contract.allowances_completed ? 1 : 0,
      unit_prices_completed: this.contract.unit_prices_completed ? 1 : 0,
      insurance_policy_completed: this.contract.insurance_policy_completed ? 1 : 0,
      summary: this.contract.summary,
      allowances: this.contract.allowances,
      unit_prices: this.contract.unit_prices,
      insurance_policy_id: this.contract.insurance_policy_id,
      insurance_requirement_ids: this.insuranceRequirementIds,
      contract_date: this.contract.contract_date,
    };
  }

  async exportContract() {
    this.contract = { ...this.contract };
    return await this.pdf.contractExport();
  }

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private dialogRef: MatDialogRef<EditContractDialogComponent>,
    private dialog: MatDialog,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: { contract: BidContract; accessoryData: TaskAccessoryData },
    private handleErrorService: HandleErrorService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private taskService: ProjectTaskService,
    private modalService: ModalService
  ) {}

  async ngOnInit(): Promise<void> {
    const main = document.querySelector('#main');
    const ps = new PerfectScrollbar(main);
    ps.update();
    this.loading = true;
    this.currentUserId = this.authService.getLoggedInUser().id;
    if (this.data?.contract?.id) {
      this.contract = await this.projectService
        .getBidContractById(this.data?.contract?.id, this.bidContractFields)
        .toPromise();
    } else if (this.data?.contract?.bid_id) {
      this.contract = {
        allowances: [],
        unit_prices: [],
        status_id: BidContractStatus.NotSent,
        // insurance_policy_id: 1 // TODO: default this based on matrix
      };
      this.contract.bid = await this.projectService.getBidById(this.data?.contract?.bid_id, this.bidFields).toPromise();
    }

    if (typeof this.contract?.proof_of_insurance_file_ids === 'string') {
      this.contract.proof_of_insurance_file_ids = JSON.parse(this.contract.proof_of_insurance_file_ids);
    }
    if (this.data?.accessoryData?.reviewChain?.length) {
      const currentReviewer = this.data.accessoryData.reviewChain.find(
        (reviewer) => reviewer.status === TaskReviewStatus.Pending
      );
      const currentUsersReview = this.data.accessoryData.reviewChain.find(
        (reviewer) => reviewer.id === this.currentUserId
      );

      const isReviewerAdmin = this.displayReviewersService.getIfUserIsReviewAdmin(
        this.data.accessoryData.reviewChain,
        this.project
      );

      this.expeditingReview = currentReviewer?.id !== currentUsersReview?.id;

      this.isCurrentSigner =
        (currentReviewer?.needs_signature && currentReviewer?.id === this.authService.currentUser.id) ||
        (isReviewerAdmin && !!currentUsersReview?.needs_signature);
    }

    if (
      [BidContractStatus.NotSent, BidContractStatus.SentToVendor, BidContractStatus.RevisionRequested].indexOf(
        this.statusId
      ) > -1
    ) {
      // This is set to the current date until the contract is signed by the vendor
      this.contract.contract_date = moment().format('YYYY-MM-DD');
    }

    if (!this.contract.bid || !this.contract.bid?.project?.id) {
      let errorMessage = 'An unknown error occurred';
      if (!this.contract.bid) {
        errorMessage = `Bid for this contract could not be found`;
      } else if (!this.contract.bid?.project?.id) {
        errorMessage = `Project for this contract could not be found`;
      }
      const errorSnack = this.snackbar.open(errorMessage, 'Close Contract', {
        duration: undefined,
      });
      errorSnack.onAction().subscribe(async () => {
        this.close();
      });
      return;
    }
    // get alt bids
    const selectedAltBidFilters = [
      { type: 'field', field: 'bid_package_id', value: this.contract.bid.bid_package_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'company_id', value: this.contract.bid.company_id },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'type_id', value: 2 },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_selected', value: 1 },
    ];
    const selectedAltBids = await this.projectService.getBids(selectedAltBidFilters, this.bidFields).toPromise();
    this.contract.altBids = selectedAltBids ?? [];
    this.contractSum = this.contract.bid.amount + sumBy(this.contract.altBids, 'amount');
    if (!this.contract.id) {
      if (this.contractSum < 25000) {
        this.contract.type_id = BidContractType.Small;
      } else if (this.contractSum < 100000) {
        this.contract.type_id = BidContractType.Medium;
      } else if (this.contractSum >= 10000) {
        this.contract.type_id = BidContractType.Large;
      }
      const bidNumber = this.contract?.bid?.bid_package?.number ? `#${this.contract?.bid?.bid_package?.number} - ` : '';

      this.contract.summary = `${bidNumber}${this.contract?.bid?.trade?.name} - ${this.formatDollar(this.contractSum)}`;
    }
    if (this.awaitingReview) {
      if (this.contract.type_id === BidContractType.Small) {
        this.totalStepCount = 2;
      } else if (this.contract.type_id === BidContractType.Medium || this.contract.type_id === BidContractType.Large) {
        this.totalStepCount = 5;
        this.insurancePolicyOptions = await this.projectService
          .getBidContractInsurancePolicies(null, ['id', 'name'])
          .toPromise();
      }
    } else if (this.statusId === BidContractStatus.SentToVendor) {
      this.totalStepCount = 2;
    } else if (this.statusId === BidContractStatus.SignedByVendor) {
      this.totalStepCount = 1;
    }

    if (this.typeId === BidContractType.Small) {
      if (typeof this.contract?.insurance_requirement_ids === 'string') {
        this.insuranceRequirementIds = this.contract.insurance_requirement_ids =
          JSON.parse(this.contract.insurance_requirement_ids) || [];
      } else if (!this.contract.insurance_requirement_ids) {
        this.contract.insurance_requirement_ids = [];
      }

      this.insuranceRequirements = await this.projectService
        .getInsuranceRequirements(
          [{ type: 'field', field: 'is_enabled', value: '1' }],
          ['id', 'name', 'value', 'is_required']
        )
        .toPromise();

      // Automatically adds any mandatory requirement to the contract.
      const mandatoryInsuranceRequirements = this.insuranceRequirements.filter((r) => r.is_required);
      for (const mr of mandatoryInsuranceRequirements) {
        if (!this.insuranceRequirementIds.find((r) => r === mr.id)) {
          this.insuranceRequirementIds.push(mr.id);
          this.contract.insurance_requirement_ids.push(mr.id);
        }
      }
    }
    // this is done to trigger the change detection on the bid contract export component
    // don't remove this unless you can confirm that the bid export works for bid contracts that haven't been created
    this.contract = { ...this.contract };
    this.loading = false;
  }

  get BidContractStatus() {
    return BidContractStatus;
  }

  public get awaitingReview() {
    return [BidContractStatus.NotSent, BidContractStatus.RevisionRequested].includes(this.statusId);
  }

  public get insuranceFilesUploaded() {
    return (
      (this.contract?.type_id !== BidContractType.Large && this.contract?.proof_of_insurance_file_id) ||
      this.contract?.proof_of_insurance_file_ids?.length ||
      ((this.contract?.proof_of_insurance_file_id || this.contract?.proof_of_insurance_file_ids?.length) &&
        this.contract?.performance_bond_file_id &&
        this.contract?.defect_bond_file_id &&
        this.contract?.payment_bond_file_id)
    );
  }

  public get trustSignee() {
    return this.contractSum < this.displayReviewersService.noCeoMax ? this.cfmo : this.authService.ceo;
  }

  formatDollar(oldValue, object?, propertyName?, includeDollarSign = true, includeCommas = true) {
    if (oldValue || oldValue === 0) {
      const newValue = this.USDollarPipe.transform(oldValue, 2, includeDollarSign, includeCommas);
      if (object && propertyName) {
        object[propertyName] = newValue;
      }
      return newValue;
    } else {
      return null;
    }
  }

  updateInsurance(policyId: number) {
    this.contract.insurance_policy_completed = 1;
    this.contract.insurance_policy = this.insurancePolicyOptions.find((policy) => policy.id === policyId);
    this.dataChanged();
  }
  dataChanged() {
    this.unsavedChangesExist = true;
  }

  toggleContractProperty(property: string) {
    this.dataChanged();

    if (property !== 'insurance_policy_completed') {
      this.contract[property] = !this.contract[property];
    }
  }

  addAllowance() {
    this.dataChanged();
    this.contract.allowances.push({});
  }

  deleteAllowance(index) {
    this.dataChanged();
    if (this.contract.allowances[index]?.id) {
      this.contract.allowances[index].action = 'delete';
    } else {
      this.contract.allowances.splice(index, 1);
    }
  }

  addUnitPrice() {
    this.dataChanged();
    this.contract.unit_prices.push({});
  }

  deleteUnitPrice(index) {
    this.dataChanged();
    if (this.contract.unit_prices[index]?.id) {
      this.contract.unit_prices[index].action = 'delete';
    } else {
      this.contract.unit_prices.splice(index, 1);
    }
  }

  async createContractPdf(filesToAdd = []) {
    this.contract = { ...this.contract };
    const createdPDF = await this.pdf.createPdfFile(this.contract.bid.project, this.contract.bid, filesToAdd);

    return createdPDF;
  }

  public setTrustSignatureText() {
    const currentUserName = `${this.authService.currentUser.first_name} ${this.authService.currentUser.last_name}`;

    if (this.contract) {
      this.contract.trust_signature_text = currentUserName;
    }
  }

  public updateInsuranceRequirements(checked: boolean, id: number) {
    if (checked) {
      this.insuranceRequirementIds.push(id);
    } else {
      this.insuranceRequirementIds = this.insuranceRequirementIds.filter((requirementId) => requirementId !== id);
    }

    this.contract.insurance_requirement_ids = this.insuranceRequirementIds;
    this.contract = { ...this.contract };
    this.dataChanged();
  }

  async sendContract() {
    if (!this.isReadyToSubmit) {
      this.snackbar.open('Please complete all contract steps');
      return;
    } else {
      const snackbarMessage = 'Contract sent to vendor!';
      let contract: BidContract;

      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Sending Contract...');
      if (this.contract.id) {
        contract = await this.updateContract(BidContractStatus.SentToVendor, snackbarMessage);
      } else {
        contract = await this.createContract(BidContractStatus.SentToVendor, snackbarMessage);
      }
      this.progressIndicatorService.updateStatus('Creating Contract Pdf...');

      let reviewFiles;
      let accessoryData =
        this.data?.accessoryData ||
        (contract?.bid?.contract_task?.accessory_data && JSON.parse(contract?.bid?.contract_task?.accessory_data));
      let task;
      if (contract.bid.contract_task_id) {
        const updatedBid = await this.projectService
          .updateBid(contract.bid.id, { contract_revision: (contract.bid.contract_revision || 0) + 1 }, [
            'contract_revision',
          ])
          .toPromise();
        contract.bid.contract_revision = updatedBid.contract_revision;
        reviewFiles = [await this.pdf.createPdfFile(contract.bid.project, contract.bid)];
        accessoryData.reviewFiles = reviewFiles;

        const taskData = {
          id: contract.bid.contract_task_id,
          is_locked: 0,
          assigned_user_id: accessoryData?.reviewChain[0].id,
          accessory_data: JSON.stringify(accessoryData),
        };

        task = await this.projectService.updateTask(taskData).toPromise();
        await this.taskService.taskSelectedEvent.emit({ task, navigate: false });
      } else {
        reviewFiles = [await this.pdf.createPdfFile(contract.bid.project, contract.bid)];
        this.progressIndicatorService.updateStatus('Getting task info...');
        const phaseInfo = await this.projectService.getPhaseInfo(this.project?.id, 'Construction Contracts', 'Bidding');
        const reviewers = this.displayReviewersService.getBidContractReviewers(
          contract.bid?.contact_id,
          this.contractSum
        );
        accessoryData = {
          type: TaskAccessoryType.BidContract,
          isReviewItem: true,
          parentId: contract.bid.id,
          reviewCreator: this.projectService.currentSelectedProject.account_coordinator_id,
          reviewChain: reviewers,
          reviewFiles,
        };

        const taskData: Task = {
          title: `Sign Construction Contract - ${contract.bid.company.name ?? ''} - ${contract.bid.trade.name ?? ''}`,
          description: `Use the 'Review & Sign' button below to review and sign the construction contract for this project. A copy has been attached to this task if needed.`,
          accessory_data: JSON.stringify(accessoryData),
          milestone_id: phaseInfo.milestoneId,
          assigned_user_id: accessoryData.reviewChain[0].id,
          due_date: this.dateService.addWeekdays(3).format('YYYY-MM-DD'),
          can_delete: 0,
        };

        this.progressIndicatorService.updateStatus('Creating Contract Task...');
        task = await this.projectService.createTask(taskData).toPromise();

        contract.bid.contract_task_id = task.id;
        await this.projectService.updateBid(contract.bid.id, { contract_task_id: task.id }, this.bidFields).toPromise();
      }

      if (reviewFiles?.length) {
        const createdNote = await this.projectService
          .createNote(ResourceType.Task, contract.bid.contract_task_id, 'Construction contract attached <br />')
          .toPromise();
        if (createdNote.id) {
          for (const file of [...reviewFiles, ...(this.contract?.bid?.files || [])]) {
            await this.fileService.linkFile(file.file_id || file.id, createdNote.id, ResourceType.Note).toPromise();
          }
        }
      }

      await this.taskService.loadTaskActivityLog(task);

      this.dialogRef.close({ contractEdited: true, contract });
    }
  }

  submitVendorSignature() {
    // TODO: proof of insurance check
    if (this.currentUserId !== this.bidContact?.id) {
      this.snackbar.open('Only the bid contact is allowed to sign the contract');
      return;
    } else if (!this.contract?.vendor_signature_text) {
      this.snackbar.open('Please enter your name to sign the contract');
      return;
    }
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Sign Contract',
        headerText: 'Accept & Sign Contract',
        descriptionText:
          'By selecting the “I Accept” or “Approve” button, you agree to be bound by any digital documents, to include but not limited to, construction contracts, change orders, service agreements or lease agreements created, managed and stored by 1CALL Cloud. <br><br>Are you sure you want to continue? This cannot be undone.',
        confirmationButtonText: 'I Accept',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.projectService
            .signBidContractAsVendor(this.contract?.id, { vendor_signature_text: this.contract?.vendor_signature_text })
            .toPromise();
          await this.submitApproval(TaskReviewStatus.Approved);
        }
      });
  }

  async executeContract() {
    if (!this.trustSignee?.id) {
      this.snackbar.open('The CFMO must be selected in Project Details before executing the contract');
      return;
    } else if (this.currentUserId !== this.trustSignee?.id) {
      this.snackbar.open('You do not have permission to execute the contract');
      return;
    } else if (!this.contract?.trust_signature_text) {
      this.snackbar.open('Please enter your name to execute the contract');
      return;
    }

    let openContractDialog = true;
    if (this.expeditingReview) {
      openContractDialog = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Replace Reviewers',
          headerText: 'Expedite Review',
          descriptionText: `There are reviewers in front of you. Are you sure you want to expedite this review and review on the others' behalf?`,
          confirmationButtonText: 'Expedite Review',
        })
        .toPromise();
    }
    if (openContractDialog) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Execute Contract',
          headerText: 'Accept & Sign Contract',
          descriptionText:
            'By selecting the “I Accept” or “Approve” button, you agree to be bound by any digital documents, to include but not limited to, construction contracts, change orders, service agreements or lease agreements created, managed and stored by 1CALL Cloud. <br><br>Are you sure you want to continue? This cannot be undone.',
          confirmationButtonText: 'I Accept',
        })
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            await this.projectService
              .signBidContractAsTrust(this.contract?.id, { trust_signature_text: this.contract?.trust_signature_text })
              .toPromise();
            await this.submitApproval(TaskReviewStatus.Approved);
            this.snackbar.open('Contract executed!');
          }
        });
    }
  }

  async createContract(statusId: number, snackbarMessage?: string) {
    const contractToCreate: BidContract = this.currentContract;
    contractToCreate.status_id = statusId;
    contractToCreate.insurance_requirement_ids =
      contractToCreate.insurance_requirement_ids && JSON.stringify(contractToCreate.insurance_requirement_ids);
    if (statusId === BidContractStatus.SentToVendor) {
      contractToCreate.sent_to_vendor_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    const contract = await this.projectService.createBidContract(contractToCreate, this.bidContractFields).toPromise();
    this.snackbar.open(snackbarMessage ?? 'Contract Saved!');

    return contract;
  }

  async updateContract(statusId: number, snackbarMessage?: string) {
    const contractToUpdate: BidContract = this.currentContract;
    delete contractToUpdate.bid_id;
    delete contractToUpdate.type_id;
    contractToUpdate.status_id = statusId;
    contractToUpdate.insurance_requirement_ids =
      contractToUpdate.insurance_requirement_ids && JSON.stringify(contractToUpdate.insurance_requirement_ids);
    contractToUpdate.contract_date = moment(contractToUpdate.contract_date || Date.now()).format('YYYY-MM-DD');
    if (statusId === BidContractStatus.SentToVendor) {
      contractToUpdate.sent_to_vendor_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    const contract = await this.projectService
      .updateBidContract(this.contract?.id, contractToUpdate, this.bidContractFields)
      .toPromise();
    this.snackbar.open(snackbarMessage ?? 'Contract updated!');

    return contract;
  }

  uploadProofOfInsurance(fieldToUpdate: string) {
    const maxFiles = fieldToUpdate === 'proof_of_insurance_file_ids' ? 10 : 1;
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          allowComment: false,
          verifyFileExtension: true,
          approvedFileExtensions: ['pdf'],
          allowSearchFromProject: false,
          project_id: this.contract.bid?.project?.id,
          parentResourceType: ResourceType.BidContract,
          parentResourceId: this.contract?.id,
          maxFiles,
          preSelectedTags: [{ id: 94 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (resultData) => {
        if (resultData) {
          if (fieldToUpdate === 'proof_of_insurance_file_ids') {
            await this.updateMultipleInsuranceFiles(fieldToUpdate, resultData);
          } else {
            await this.updateInsuranceFile(fieldToUpdate, resultData[0]);
          }
        }
      });
  }

  async removeProofOfInsurance(fieldToUpdate, fileId = null) {
    if (this.statusId === BidContractStatus.SentToVendor && this.isCurrentSigner) {
      if (fileId) {
        const fileField = fieldToUpdate.replace('_id', '');
        const files = this.contract[fileField].filter((f) => f.id !== fileId) || [];
        await this.updateMultipleInsuranceFiles(fieldToUpdate, files, true);
      } else {
        await this.updateInsuranceFile(fieldToUpdate);
      }
    }
  }

  async updateInsuranceFile(fieldToUpdate, file = null) {
    const fileField = fieldToUpdate.replace('_file_id', '');
    this.contract[fileField] = file ?? null;
    this.contract[fieldToUpdate] = file?.id ?? null;

    await this.projectService
      .updateBidContractInsuranceFile(this.contract?.id, { [fieldToUpdate]: file?.id || null })
      .toPromise();
  }

  async updateMultipleInsuranceFiles(fieldToUpdate: string, files = null, removeFiles = false): Promise<void> {
    const fileField = fieldToUpdate.replace('_id', '');
    this.contract[fileField] = [...((!removeFiles && this.contract[fileField]) || []), ...(files || [])];
    this.contract[fieldToUpdate] =
      (this.contract[fileField]?.length && this.contract[fileField].map((f) => f.id)) || [];

    await this.projectService
      .updateBidContractInsuranceFile(this.contract?.id, {
        [fieldToUpdate]: JSON.stringify(this.contract[fieldToUpdate]),
      })
      .toPromise();
  }

  public async requestRevision() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Request Revision',
        headerText: 'Request Revision',
        descriptionText: 'Please provide a short description for what needs to be revised.',
        confirmationButtonText: 'Request Revision',
        userInput: {
          placeholder: 'What needs to be revised?',
          required: true,
        },
        allowedFilesCount: 10,
        filesParentId: this.contract?.bid?.project_id,
        filesParentResourceType: ResourceType.Project,
      })
      .subscribe(async ({ res, newFiles }) => {
        if (res) {
          await this.projectService.reviseBidContract(this.contract.id).toPromise();
          await this.submitApproval(TaskReviewStatus.Rejected, res, newFiles);
        }
      });
  }

  public async submitApproval(approval: TaskReviewStatus, comment = '', commentFiles = []) {
    const reviewFiles = [];
    if (approval === TaskReviewStatus.Approved) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Updating Contract...');
      // This is where the file pdf needs to ba created and then added to files
      let proofOfInsuranceFiles = [];
      if (
        this.authService.currentUser.user_type_id === UserType.Vendor &&
        (this.contract.proof_of_insurance?.id || this.contract?.proof_of_insurance_files?.length)
      ) {
        proofOfInsuranceFiles.push(this.contract.proof_of_insurance);
        proofOfInsuranceFiles.push(this.contract.performance_bond);
        proofOfInsuranceFiles.push(this.contract.defect_bond);
        proofOfInsuranceFiles.push(this.contract.payment_bond);
        proofOfInsuranceFiles = [...(proofOfInsuranceFiles || []), ...(this.contract.proof_of_insurance_files || [])];
      }
      reviewFiles.push(await this.createContractPdf(proofOfInsuranceFiles));
    }
    this.progressIndicatorService.close();
    this.dialogRef.close({
      contractEdited: approval === TaskReviewStatus.Approved,
      approval,
      comment,
      commentFiles,
      reviewFiles,
    });
  }

  async close() {
    if (this.unsavedChangesExist) {
      const choice = await this.modalService
        .openConfirmationChoiceDialog({
          title: 'Unsaved Changes',
          heading: 'Warning: Unsaved Changes',
          subHeading: `You have unsaved changes. Would you like to save this progress or discard the changes? To return to the contract, close this dialog by clicking the X above.`,
          option1: { text: 'Discard Changes', value: 1 },
          option2: { text: 'Save Progress', value: 2 },
        })
        .toPromise();
      if (choice === 2) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Saving Changes...');
        let contract;
        if (this.contract.id) {
          contract = await this.updateContract(BidContractStatus.NotSent);
          this.dialogRef.close({ contractEdited: true, contract });
        } else {
          contract = await this.createContract(BidContractStatus.NotSent);
          this.dialogRef.close({ contractEdited: true, contract });
        }
        this.progressIndicatorService.close();
      } else {
        this.dialogRef.close({ contractEdited: false });
      }
    } else {
      this.dialogRef.close({ contractEdited: false });
    }
  }

  scrollToElement($element): void {
    document.getElementById($element).scrollIntoView();
  }

  public async previewFile(file): Promise<void> {
    await this.fileService.previewFile(file);
  }

  public downloadFile(file): void {
    this.fileService
      .downloadFile(file)
      .pipe(
        map((result) => result),
        catchError((e) => {
          file.isDownloading = false;
          this.handleErrorService.handleError(e);
          return e;
        })
      )
      .subscribe((downloadedFileResult) => {
        saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      });
  }
}
