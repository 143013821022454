import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, find, map, remove, sortBy } from 'lodash';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormService } from 'src/app/services';
import { Form } from 'src/app/types';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Input() form: Form;
  @Input() title: string;
  @Input() formId: number;
  @Input() contained: boolean;
  @Input() parents;
  @Input() formSubmissionId: number;
  @Input() useMatLabels = true;
  @Output() refreshParent: EventEmitter<any> = new EventEmitter();
  @ViewChild('pdf') pdf;

  constructor(private formService: FormService, private fb: FormBuilder, private snackbar: MatSnackBar) {}

  math = Math;

  shownForm;
  dynamicForm;
  elements = [];
  shownSubmission;
  unsavedChangesExist = false;
  formLoading = false;
  formIsValid = false;
  formValidator: Subject<any> = new Subject();
  invalidElements = [];
  value;
  buildingDepartmentDisplayFn = (o) => {
    return o ? o.name : '';
  };

  async ngOnInit() {
    this.formValidator.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      this.formChanged();
    });
    await this.refresh();
    this.formChanged();
  }

  async refresh() {
    this.shownForm = null;
    this.dynamicForm = null;
    this.elements = [];
    // this.unsavedChangesExist = false;
    this.formLoading = true;

    const form = this.form?.content ? this.form : await this.formService.getFormById(this.formId).toPromise();
    let submissionElements;
    if (!form.is_multi_submission) {
      const formSubmissions = await this.formService.getSubmissionsByFormId(this.formId).toPromise();
      if (formSubmissions[0]) {
        this.shownSubmission = formSubmissions[0];
        submissionElements = JSON.parse(formSubmissions[0].content).elements;
      }
    } else if (this.formSubmissionId) {
      const formSubmissions = await this.formService.getSubmissionById(this.formSubmissionId).toPromise();
      this.shownSubmission = formSubmissions[0];
      submissionElements = JSON.parse(formSubmissions[0].content).elements;
    }
    form.content = typeof form.content === 'string' ? JSON.parse(form.content) : form.content;
    if (form.content) {
      let parent_ctrl, parent_element, child_ctrl, child_element;
      if (form.content.sections) {
        this.dynamicForm = this.fb.group({});
        let sectionIndex = 1;
        for (const s of form.content.sections) {
          s.section_name = `s${sectionIndex}`;
          if (s.elements) {
            this.dynamicForm.controls[s.section_name] = this.fb.group({});
            let elementIndex = 1;
            s.elements = sortBy(s.elements, 'sort');
            for (const e of s.elements) {
              const foundElement = submissionElements ? find(submissionElements, (se) => se.id === e.id) : null;
              let value;
              if ((!form.is_multi_submission || this.formSubmissionId) && e.linked_property) {
                if (this.parents) {
                  const parent =
                    this.parents[
                      (e.parent_submissions ? e.parent_submissions[0] : null) || Object.keys(this.parents)[0]
                    ];
                  if (parent) {
                    value = parent[e.linked_property];
                    if (value && [6].indexOf(e.type) > -1) {
                      value = JSON.parse(value);
                    }
                  }
                }
              } else if (foundElement) {
                value = foundElement.value;
              } else if (e.default_value) {
                value = e.default_value;
              }
              e.element_name = `e${elementIndex}`;
              e.section_name = s.section_name;
              if ([1, 2, 8, 10, 12, 13].indexOf(e.type) > -1) {
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(value);
              } else if (e.type === 3) {
                e.options = await this.generateOptions(e);
                const selectedOption = e.options.find((o) => (o.value || o.value === 0 ? o.value : o.id) === value);
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(selectedOption);
              } else if (e.type === 4) {
                let searchValue;
                if (value) {
                  let url = e.api_endpoint;
                  // todo: only add ? and fields and filter labels if necessary
                  url += `?fields=${e.search_field},${e.display_field}&filter=id=${value}`;
                  const options = await this.formService.getOptions(url).toPromise();
                  e.options = map(options, (o) => ({
                    id: o.id,
                    label: o[e.display_field || e.search_field],
                  }));
                }

                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(value);
                this.dynamicForm.controls[s.section_name].controls[e.element_name].valueChanges
                  .pipe(
                    debounceTime(300),
                    switchMap((newValue) => {
                      searchValue = newValue;
                      if (newValue) {
                        if (e.api_endpoint && e.search_field && e.display_field) {
                          let url = e.api_endpoint;
                          // todo: only add ? and fields and filter labels if necessary
                          url += `?fields=${e.search_field},${e.display_field}&filter=${e.search_field}~%${newValue}%`;
                          if (e.api_limit) {
                            url += `&limit=${e.api_limit}`;
                          }
                          return this.formService.getOptions(url);
                        } else {
                          return of([]);
                        }
                      } else {
                        return of(null);
                      }
                    })
                  )
                  .subscribe((options) => {
                    let retrievedOptions = [];
                    if (options) {
                      retrievedOptions = options;
                    }
                    if (e.custom_options) {
                      if (e.search_field) {
                        const customOptions = filter(e.custom_options, (o) => o[e.search_field].includes(searchValue));
                        retrievedOptions = retrievedOptions.concat(customOptions);
                      }
                    }
                    if (e.options_to_remove) {
                      for (const r of e.options_to_remove) {
                        remove(retrievedOptions, (o) => r.id === o.id);
                      }
                    }
                    const optionsToReturn = [];
                    for (const o of retrievedOptions) {
                      optionsToReturn.push({
                        id: o.id,
                        label: o[e.display_field || e.search_field],
                      });
                    }
                    e.options = optionsToReturn;
                  });
                e.valueMapper = (key) => {
                  if (key && e.options) {
                    const foundOption = e.options.find((o) => o.id === key);
                    return foundOption.label;
                  } else {
                    return null;
                  }
                };
              } else if (e.type === 5) {
                e.datepickerFilter = (d: Date): boolean => {
                  let valid = true;
                  if (e.minimum) {
                    if (moment(e.minimum) > moment(d)) {
                      valid = false;
                    }
                  }
                  if (e.maximum) {
                    if (moment(e.maximum) < moment(d)) {
                      valid = false;
                    }
                  }
                  return valid;
                };
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(value);
              } else if (e.type === 6) {
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = this.fb.group({});
                const options = await this.generateOptions(e);
                let optionIndex = 1;
                for (const o of options) {
                  o.option_name = `${e.element_name}o${optionIndex}`;
                  this.dynamicForm.controls[s.section_name].controls[e.element_name].controls[o.option_name] =
                    new FormControl(find(value, (v) => v === o.id));
                  optionIndex++;
                }
                e.options = options;
              } else if (e.type === 7) {
                const validators = [];
                if (e.required) {
                  validators.push((control) => {
                    return !control.value ? { required: true } : null;
                  });
                }
                e.options = await this.generateOptions(e);
                const selectedOption = e.options.find((o) => (o.value || o.value === 0 ? o.value : o.id) === value);
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(
                  selectedOption,
                  validators
                );
              } else if (e.type === 9) {
                const validators = [];
                if (e.required) {
                  validators.push(Validators.required);
                }
                if (e.minimum) {
                  validators.push(Validators.min(e.minimum));
                }
                if (e.maximum) {
                  validators.push(Validators.max(e.maximum));
                }
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(value, validators);
              } else if (e.type === 11) {
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(value);
                e.options = this.formService.getUSStates();
              } else if (e.type === 15) {
                if (e.required) {
                  const acceptanceValidators: ValidatorFn[] = [];
                  if (e.required) {
                    acceptanceValidators.push(Validators.pattern('true'));
                    acceptanceValidators.push(Validators.required);
                  }
                  this.dynamicForm.controls[s.section_name].controls[`${e.element_name}_acceptance`] = new FormControl(
                    value ? value.acceptance : null,
                    acceptanceValidators
                  );

                  if (e.signature) {
                    const signatureValidators = [];
                    signatureValidators.push((control) => {
                      return !control.value ? { required: true } : null;
                    });
                    this.dynamicForm.controls[s.section_name].controls[`${e.element_name}_signature`] = new FormControl(
                      value ? value.signature : null,
                      signatureValidators
                    );
                  }
                }
              } else if (e.type === 16) {
                const validators = [];
                if (e.required) {
                  validators.push(Validators.required);
                }
                let url = `${e.api_endpoint}`; // todo: only add ? and fields and filters labels if necessary
                if (e.api_limit) {
                  url += `&limit=${e.api_limit}`;
                }
                let options: any[] = await this.formService.getOptions(url).toPromise();
                e.options = options;
                const selectedOption = e.options.find((o) => (o.value || o.value === 0 ? o.value : o.id) === value);
                this.dynamicForm.controls[s.section_name].controls[e.element_name] = new FormControl(
                  selectedOption,
                  validators
                );
                if (e.parent) {
                  parent_ctrl = this.dynamicForm.controls[s.section_name].controls[e.element_name];
                  parent_element = e;
                }
                if (e.child) {
                  child_ctrl = this.dynamicForm.controls[s.section_name].controls[e.element_name];
                  child_element = e;
                  child_ctrl.valueChanges.subscribe((v) => {
                    this.formValidator.next({ child_element, value: v });
                  });
                }
              }
              this.elements.push(e);
              elementIndex++;
            }
          }
          sectionIndex++;
        }
        if (parent_ctrl) {
          parent_ctrl.valueChanges.subscribe((v) => {
            child_ctrl.setValue(null);
            this.formValidator.next({ parent_element, value: this.getElementValue(parent_element) });
            if (child_element.child.through_property) {
              child_element.filtered_options = child_element.options.filter((option) =>
                option[child_element.child.through_property].some(
                  (through_property) => through_property[child_element.child.field] === v?.[parent_element.parent.field]
                )
              );
            } else {
              child_element.filtered_options = child_element.options.filter(
                (o) => o[child_element.child.field] === v?.[parent_element.parent.field]
              );
            }
          });
        }
      }
    }
    this.shownForm = form;
    this.formLoading = false;
  }

  async generateOptions(element) {
    if (element.api_endpoint && element.display_field) {
      let url = `${element.api_endpoint}?fields=${element.display_field}`; // todo: only add ? and fields and filters labels if necessary
      if (element.api_limit) {
        url += `&limit=${element.api_limit}`;
      }
      let options: any[] = await this.formService.getOptions(url).toPromise();
      if (element.custom_options) {
        options = options.concat(element.custom_options);
      }
      if (element.options_to_remove) {
        for (const r of element.options_to_remove) {
          remove(options, (o) => r.id === o.id);
        }
      }
      const optionsToReturn = [];
      options.forEach((o) => {
        optionsToReturn.push({ id: o.id, label: o[element.display_field] });
      });
      return optionsToReturn;
    } else {
      if (element.custom_options) {
        element.custom_options.forEach((o) => {
          o.label = o[element.display_field || 'name'];
        });
        return element.custom_options;
      } else {
        return [];
      }
    }
  }

  // async uploadFile(files: FileList, element) {
  //   element.file = files[0];
  //   this.dynamicForm.get(element.section_name).get(element.element_name).setValue({ name: element.file_name });
  // }

  // downloadFile(file) {
  //   this.fileService.downloadFile(file).subscribe(downloadedFileResult => {
  //     saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
  //   });
  // }

  getSubmissionValue() {
    const submission = { content: { elements: [] } };
    const parentValues: any = {};
    for (const e of this.elements) {
      let value;
      if (e.linked_field) {
        let condition = false;
        if (e.linked_field.condition) {
          const referenceElement =
            e.linked_field.condition.id === e.id
              ? e
              : this.elements.find((el) => el.id === e.linked_field.condition.id);

          const referenceValue = this.getElementValue(referenceElement);
          const linkedValue = e.linked_field.value;
          let isLinked;
          switch (e.linked_field.condition.operator) {
            case '=':
              isLinked = referenceValue === linkedValue;
              break;
            case '!=':
              isLinked = referenceValue !== linkedValue;
              break;
            case '<':
              isLinked = referenceValue < linkedValue;
              break;
            case '<=':
              isLinked = referenceValue <= linkedValue;
              break;
            case '>':
              isLinked = referenceValue > linkedValue;
              break;
            case '>=':
              isLinked = referenceValue > linkedValue;
              break;
            default:
              isLinked = referenceValue === linkedValue;
              break;
          }
          condition = isLinked;
        }

        if (!condition) {
          value = this.getElementValue(e);
        } else {
          const referenceElement =
            e.linked_field.id === e.id ? e : this.elements.find((el) => el.id === e.linked_field.id);
          value = this.getElementValue(referenceElement);
        }
      } else {
        value = this.getElementValue(e);
      }

      if (e.linked_property) {
        let linkedValue;
        if (e.type === 6) {
          linkedValue = JSON.stringify(value);
        } else {
          linkedValue = value;
        }
        if (e.parent_submissions) {
          for (const s of e.parent_submissions) {
            if (!parentValues[s]) {
              if (
                this.shownForm.content &&
                this.shownForm.content.parent_submissions &&
                this.shownForm.content.parent_submissions[s]
              ) {
                parentValues[s] = this.shownForm.content.parent_submissions[s];
              } else {
                parentValues[s] = {};
              }
            }
            parentValues[s][e.linked_property] = linkedValue;
          }
        } else {
          if (!parentValues.default_submission) {
            parentValues.default_submission = {};
          }
          parentValues.default_submission[e.linked_property] = linkedValue;
        }
      } else {
        const element = { id: e.id, value };
        submission.content.elements.push(element);
      }
    }
    return [submission, parentValues];
  }

  async submitForm() {
    this.formChanged();
    if (this.invalidElements.length > 0) {
      let invalidMessage = `The following ${
        this.invalidElements.length !== 1 ? `${this.invalidElements.length} fields are` : 'field is'
      } invalid:`;
      this.invalidElements.forEach((e) => {
        invalidMessage += `, ${e.label}`;
      });
      // todo: mark form as touched/dirty
      this.snackbar.open(invalidMessage);
    } else {
      const [submission, parentValues] = this.getSubmissionValue();
      let newSubmission;
      if (this.shownForm.is_multi_submission) {
        newSubmission = await this.formService.createFormSubmission(this.shownForm.id, submission).toPromise();
      } else if (this.shownSubmission && this.shownSubmission.id) {
        newSubmission = await this.formService.updateFormSubmission(this.shownSubmission.id, submission).toPromise();
      }

      let parentUpdated = false;
      if (Object.keys(parentValues).length > 0) {
        for (const key of Object.keys(parentValues)) {
          const parentValue = parentValues[key];
          if (this.shownForm.is_multi_submission) {
            if (newSubmission && newSubmission.id && this.shownForm.content.include_form_submission_id) {
              parentValue.form_submission_id = newSubmission.id;
            }
            await this.formService
              .createParent(`${this.shownForm.content.parent_api_endpoint}`, parentValue)
              .toPromise();
          } else if (this.parents) {
            for (const k of Object.keys(this.parents)) {
              const parent = this.parents[k];
              if (parent.id) {
                await this.formService
                  .updateParent(`${this.shownForm.content.parent_api_endpoint}/${parent.id}`, parentValue)
                  .toPromise();
              }
            }
          }
        }
        parentUpdated = true;
      }

      if (!this.contained) {
        this.snackbar.open(this.shownForm.is_multi_submission ? 'Form submission created!' : 'Form saved!');
      }
      this.refresh();
      if (parentUpdated && this.refreshParent) {
        this.refreshParent.emit();
      }
    }
  }

  getElementValue(element) {
    let value;
    if ([1, 2, 9, 11, 13].indexOf(element.type) > -1) {
      value = this.dynamicForm.get(element.section_name).get(element.element_name).value;
    } else if ([3, 4, 7, 16].indexOf(element.type) > -1) {
      const objectValue = this.dynamicForm.get(element.section_name).get(element.element_name).value;
      if (objectValue) {
        if (element.return_full_object) {
          value = objectValue;
        } else if (objectValue.value || objectValue.value === 0) {
          value = objectValue.value;
        } else {
          value = objectValue.id;
        }
      } else {
        value = null;
      }
    } else if (element.type === 5) {
      const originalValue = this.dynamicForm.get(element.section_name).get(element.element_name).value;
      value = moment(originalValue).format('YYYY-MM-DD hh:mm:ss');
    } else if (element.type === 6) {
      value = [];
      element.options.forEach((o) => {
        const optionValue = this.dynamicForm
          .get(element.section_name)
          .get(element.element_name)
          .get(o.option_name).value;
        if (optionValue) {
          const foundOption = find(element.options, (eo) => eo.option_name === o.option_name);
          value.push(foundOption.id);
        }
      });
    } else if (element.type === 8) {
      // todo: don't upload if it's already there
      // if (e.file) {
      //   const uploadedFile = await this.fileService.uploadFile(e.file,
      //     { typeId: this.shownForm.parent_type_id, id: this.shownForm.parent_id }, e.file_name).toPromise();
      //   value = { id: uploadedFile.id, name: uploadedFile.name };
      // } else {
      //   value = this.dynamicForm.get(e.section_name).get(e.element_name).value;
      // }
    } else if (element.type === 10) {
      const originalValue = this.dynamicForm.get(element.section_name).get(element.element_name).value;
      value = originalValue ? originalValue.replace(/[^0-9]/g, '') : null;
    } else if (element.type === 12) {
      const originalValue = this.dynamicForm.get(element.section_name).get(element.element_name).value;
      value = originalValue ? originalValue.replace(/[^0-9.]/g, '') : null;
    } else if (element.type === 15) {
      value = {
        acceptance: this.dynamicForm.get(element.section_name).get(`${element.element_name}_acceptance`).value,
        signature: element.signature
          ? this.dynamicForm.get(element.section_name).get(`${element.element_name}_signature`).value
          : null,
      };
    }
    return value;
  }

  formChanged() {
    let isFormValid = true;
    let formIsTouched = false;
    const invalidElements = [];
    for (const e of this.elements) {
      // update status
      if (this.formSubmissionId) {
        this.disableElement(e);
      } else if (e.status && e.status.disabled) {
        const referenceElement =
          e.status.disabled.id === e.id ? e : this.elements.find((el) => el.id === e.status.disabled.id);

        const referenceValue = this.getElementValue(referenceElement);
        const disabledValue = e.status.disabled.value;
        let isDisabled;
        switch (e.status.disabled.operator) {
          case '=':
            isDisabled = referenceValue === disabledValue;
            break;
          case '!=':
            isDisabled = referenceValue !== disabledValue;
            break;
          case '<':
            isDisabled = referenceValue < disabledValue;
            break;
          case '<=':
            isDisabled = referenceValue <= disabledValue;
            break;
          case '>':
            isDisabled = referenceValue > disabledValue;
            break;
          case '>=':
            isDisabled = referenceValue > disabledValue;
            break;
          default:
            isDisabled = referenceValue === disabledValue;
            break;
        }
        if (isDisabled) {
          this.disableElement(e);
        } else {
          if (e.type === 15) {
            this.dynamicForm.get(e.section_name).controls[`${e.element_name}_acceptance`].enable();
            if (e.signature) {
              this.dynamicForm.get(e.section_name).controls[`${e.element_name}_signature`].enable();
            }
          } else if (e.type !== 14) {
            this.dynamicForm.get(e.section_name).controls[e.element_name].enable();
          }
        }
      }
      if (e.status && e.status.hidden) {
        const referenceElement =
          e.status.hidden.id === e.id ? e : this.elements.find((el) => el.id === e.status.hidden.id);

        const referenceValue = this.getElementValue(referenceElement);
        const hiddenValue = e.status.hidden.value;
        let isHidden;
        switch (e.status.hidden.operator) {
          case '=':
            isHidden = referenceValue === hiddenValue;
            break;
          case '!=':
            isHidden = referenceValue !== hiddenValue;
            break;
          case '<':
            isHidden = referenceValue < hiddenValue;
            break;
          case '<=':
            isHidden = referenceValue <= hiddenValue;
            break;
          case '>':
            isHidden = referenceValue > hiddenValue;
            break;
          case '>=':
            isHidden = referenceValue > hiddenValue;
            break;
          default:
            isHidden = referenceValue === hiddenValue;
            break;
        }
        e.hidden = isHidden;
      }
      // validate
      if (e.type === 15) {
        const foundAcceptanceElement = this.dynamicForm.get(e.section_name).get(`${e.element_name}_acceptance`);
        const foundSignatureElement = e.signature
          ? this.dynamicForm.get(e.section_name).get(`${e.element_name}_signature`)
          : null;
        if (foundAcceptanceElement?.touched || foundSignatureElement?.touched) {
          formIsTouched = true;
        }
        if (
          !foundAcceptanceElement ||
          (e.signature && !foundSignatureElement) ||
          (foundAcceptanceElement && foundAcceptanceElement.invalid) ||
          (foundSignatureElement && foundSignatureElement.invalid)
        ) {
          invalidElements.push(e);
          isFormValid = false;
        }
      } else if (e.type !== 14) {
        const foundElement = this.dynamicForm.get(e.section_name).get(e.element_name);
        if (foundElement?.touched) {
          formIsTouched = true;
        }
        if (!foundElement || (foundElement && foundElement.invalid)) {
          invalidElements.push(e);
          isFormValid = false;
        }
      }
    }
    this.formIsValid = isFormValid && formIsTouched;
    this.invalidElements = invalidElements;
    return invalidElements;
  }

  elementChanged(element) {
    this.formValidator.next({ element, value: this.getElementValue(element) });
  }

  disableElement(element) {
    if (element.type === 15) {
      this.dynamicForm.get(element.section_name).controls[`${element.element_name}_acceptance`].disable();
      if (element.signature) {
        this.dynamicForm.get(element.section_name).controls[`${element.element_name}_signature`].disable();
      }
    } else if (element.type !== 14) {
      this.dynamicForm.get(element.section_name).controls[element.element_name].disable();
    }
  }

  checkValidation(sectionName, elementName, validatorName) {
    return this.dynamicForm?.get(sectionName)?.controls[elementName]?.errors?.[validatorName];
  }
}
