<div
  #latestUpdate
  id="latest-update"
  data-test-id="latest-update"
  class="latest-update border-left border-gray d-flex ease"
>
  <section class="w-100 position-relative">
    <div id="updates_header" class="px-4 py-3 border-bottom border-gray bg-white">
      <div class="d-flex w-100 align-items-center">
        <h4 class="m-0 dkblue font-weight-semibold mr-auto" #top>Latest Updates</h4>
        <button (click)="this.closeAside()" mat-icon-button color="primary" type="button">
          <mat-icon> close </mat-icon>
        </button>
      </div>
      <section *ngIf="selectedLatestUpdates" id="type_toggle" class="d-flex my-3">
        <span class="toggle-container w-100">
          <mat-button-toggle-group
            class="row m-0 full-width"
            [(ngModel)]="selectedLatestUpdates"
            (ngModelChange)="tabChanged()"
          >
            <mat-button-toggle
              class="col"
              value="allUpdates"
              [ngClass]="{ selectedItem: selectedLatestUpdates === 'allUpdates' }"
              >All Updates</mat-button-toggle
            >
            <mat-button-toggle
              class="col"
              value="projectUpdates"
              [ngClass]="{ selectedItem: selectedLatestUpdates === 'projectUpdates' }"
              >Projects
            </mat-button-toggle>
            <mat-button-toggle
              class="col"
              value="workOrderUpdates"
              [ngClass]="{ selectedItem: selectedLatestUpdates === 'workOrderUpdates' }"
              >Work Orders
            </mat-button-toggle>
          </mat-button-toggle-group>
        </span>
      </section>
      <div
        *ngIf="isLoadingLatestUpdates"
        class="d-flex justify-content-center align-items-center my-5 position-absolute top-50 start-50 w-100"
      >
        <mat-spinner color="accent" [diameter]="40"></mat-spinner>
      </div>
    </div>
    <section
      id="latest-updates"
      class="latest-updates w-100"
      [ngClass]="{
        'latest-updates-dashboard': selectedLatestUpdates
      }"
      (scroll)="onScroll($event)"
    >
      <!--WORK ORDER UPDATES-->
      <div *ngIf="workOrderUpdates?.length" class="work-order-update-wrapper update-list">
        <div class="work-order-update item p-4" *ngFor="let workOrderUpdate of workOrderUpdates | slice: 0:updateCount">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-top mb-3">
              <app-profile-info
                class="mr-auto"
                [user]="workOrderUpdate.created_by"
                [date]="workOrderUpdate.created_datetime"
                [avatarSize]="34"
              ></app-profile-info>
              <span class="ml-auto ml-1">
                <p
                  *ngIf="workOrderUpdate.work_order_health_type?.id"
                  class="mb-0 px-2 br-4 bg-green white font-weight-semibold"
                  [ngClass]="{
                    'bg-green': workOrderUpdate.work_order_health_type?.id === 1,
                    'bg-orange': workOrderUpdate.work_order_health_type?.id === 2,
                    'bg-red': workOrderUpdate.work_order_health_type?.id === 3
                  }"
                  style="font-size: 11px; line-height: 20px"
                >
                  {{ workOrderUpdate.work_order_health_type?.name }}
                </p>
              </span>
            </div>
            <div class="d-flex flex-column pl-5">
              <a [routerLink]="'/work-orders/' + workOrderUpdate.work_order_id" class="mb-3 pl-2 text-decoration-none">
                <p class="m-0 ltblue font-weight-semibold hover-u">
                  {{ workOrderUpdate.work_order?.code }}{{ workOrderUpdate.work_order?.title ? ' - ' : ''
                  }}{{ workOrderUpdate.work_order?.title }}
                </p>
              </a>
              <p
                class="m-0 pl-2 dkblue font-weight-normal text-wrap text-break"
                [innerHTML]="workOrderUpdate.message"
                appTextCollapseExpand
                maxHeight="80"
                (heightExceededEvent)="workOrderUpdate.showCollapseMessageViewButton = $event"
                [showMore]="!workOrderUpdate.collapseMessageView"
              ></p>
              <p
                id="collapse-detail-view-button"
                class="lh-28 pl-2 hover-u pointer ltblue m-0 font-weight-normal"
                *ngIf="workOrderUpdate.showCollapseMessageViewButton === true"
                (click)="workOrderUpdate.collapseMessageView = !workOrderUpdate.collapseMessageView"
              >
                {{ workOrderUpdate.collapseMessageView === true ? '...Show More' : 'Show Less' }}
              </p>
              <app-file-chip-list
                class="mt-3 pl-2"
                *ngIf="workOrderUpdate?.files?.length"
                [files]="workOrderUpdate.files"
                [diameter]="40"
              ></app-file-chip-list>
            </div>
          </div>
        </div>
        <div *ngIf="workOrderUpdates?.length" class="d-flex align-items-center p-4">
          <button
            *ngIf="updateCount < workOrderUpdates?.length"
            mat-stroked-button
            color="accent"
            type="button"
            (click)="showMoreWorkOrderUpdates()"
            class="mr-4"
          >
            Load More Updates
          </button>
          <div class="primary" [ngClass]="{ 'ml-2': updateCount < workOrderUpdates.length }">
            {{ updateCount }} of {{ workOrderUpdates?.length }}
          </div>
        </div>
      </div>

      <!--PROJECT UPDATES-->
      <div *ngIf="projectUpdates?.length" class="project-update-wrapper">
        <div class="project-update item p-4" *ngFor="let projectUpdate of projectUpdates | slice: 0:updateCount">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-top mb-3">
              <app-profile-info
                [user]="projectUpdate?.created_by"
                [date]="projectUpdate?.created_datetime"
                [avatarSize]="30"
              ></app-profile-info>
              <span class="ml-auto">
                <p
                  *ngIf="projectUpdate.project_health_type?.id"
                  class="mb-0 px-2 br-4 bg-green white font-weight-semibold"
                  [ngClass]="{
                    'bg-green': projectUpdate.project_health_type?.id === 1,
                    'bg-orange': projectUpdate.project_health_type?.id === 2,
                    'bg-red': projectUpdate.project_health_type?.id === 3
                  }"
                  style="font-size: 11px; line-height: 20px"
                >
                  {{ projectUpdate.project_health_type?.name }}
                </p>
              </span>
            </div>
            <div class="d-flex flex-column pl-5 ml-1">
              <a [routerLink]="'/projects/' + projectUpdate?.project_id" class="mb-3 text-decoration-none">
                <p class="m-0 ltblue font-weight-semibold hover-u">
                  {{ projectUpdate.project_code }}{{ projectUpdate.project_title ? ' - ' : ''
                  }}{{ projectUpdate.project_title }}
                </p>
              </a>
              <p
                class="m-0 dkblue font-weight-normal text-wrap text-break"
                [innerHTML]="projectUpdate.message"
                appTextCollapseExpand
                maxHeight="80"
                (heightExceededEvent)="projectUpdate.showCollapseMessageViewButton = $event"
                [showMore]="projectUpdate.collapseMessageView"
              ></p>
              <p
                id="collapse-detail-view-button"
                class="lh-28 hover-u pointer ltblue m-0 font-weight-semibold mt-2"
                *ngIf="projectUpdate.showCollapseMessageViewButton === true"
                (click)="projectUpdate.collapseMessageView = !projectUpdate.collapseMessageView"
              >
                <i *ngIf="projectUpdate.collapseMessageView" class="fas fa-angle-up mr-2"></i>
                {{ !projectUpdate.collapseMessageView ? '...Show More' : 'Show Less' }}
              </p>
              <app-file-chip-list
                class="mt-3"
                *ngIf="projectUpdate?.files?.length"
                [files]="projectUpdate.files"
                [diameter]="40"
              ></app-file-chip-list>
            </div>
          </div>
        </div>
        <div *ngIf="projectUpdates?.length" class="d-flex align-items-center p-4">
          <button
            *ngIf="updateCount < projectUpdates?.length"
            mat-stroked-button
            color="accent"
            type="button"
            (click)="showMoreProjectUpdates()"
            class="mr-4"
          >
            Load More Updates
          </button>
          <div class="primary" [ngClass]="{ 'ml-2': updateCount < projectUpdates.length }">
            {{ updateCount }} of {{ projectUpdates?.length }}
          </div>
        </div>
      </div>

      <!--COMBINATION OF PROJECT AND WORK ORDER UPDATES-->
      <div *ngIf="allUpdates?.length" class="all-update-wrapper">
        <div class="all-update item p-4" *ngFor="let update of allUpdates | slice: 0:updateCount">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-top mb-3">
              <app-profile-info
                [user]="update?.created_by"
                [date]="update?.created_datetime"
                [avatarSize]="30"
              ></app-profile-info>
              <span class="ml-auto">
                <!--project-->
                <p
                  *ngIf="update.project_id"
                  class="mb-0 px-2 br-4 bg-green white font-weight-semibold"
                  [ngClass]="{
                    'bg-green': update.project_health_type?.id === 1,
                    'bg-orange': update.project_health_type?.id === 2,
                    'bg-red': update.project_health_type?.id === 3
                  }"
                  style="font-size: 11px; line-height: 20px"
                >
                  {{ update.project_health_type?.name }}
                </p>
                <!--work order-->
                <p
                  *ngIf="update.work_order_id"
                  class="mb-0 px-2 br-4 bg-green white font-weight-semibold"
                  [ngClass]="{
                    'bg-green': update.work_order_health_type?.id === 1,
                    'bg-orange': update.work_order_health_type?.id === 2,
                    'bg-red': update.work_order_health_type?.id === 3
                  }"
                  style="font-size: 11px; line-height: 20px"
                >
                  {{ update.work_order_health_type?.name }}
                </p>
              </span>
            </div>
            <div class="d-flex flex-column pl-5 ml-1">
              <!--project-->
              <a
                *ngIf="update?.project_id"
                [routerLink]="'/projects/' + update?.project_id"
                class="mb-3 text-decoration-none"
              >
                <p class="m-0 ltblue font-weight-semibold hover-u">
                  {{ update.project_code }}{{ update.project_title ? ' - ' : '' }}{{ update.project_title }}
                </p>
              </a>
              <!--work order-->
              <a
                *ngIf="update?.work_order_id"
                [routerLink]="'/work-orders/' + update?.work_order_id"
                class="mb-3 pl-2 text-decoration-none"
              >
                <p class="m-0 ltblue font-weight-semibold hover-u">
                  {{ update.work_order?.code }}{{ update.work_order?.title ? ' - ' : '' }}{{ update.work_order?.title }}
                </p>
              </a>
              <p
                class="m-0 dkblue font-weight-normal text-wrap text-break"
                [innerHTML]="update.message"
                appTextCollapseExpand
                maxHeight="80"
                (heightExceededEvent)="update.showCollapseMessageViewButton = $event"
                [showMore]="!update.collapseMessageView"
              ></p>
              <p
                id="collapse-detail-view-button"
                class="lh-28 hover-u pointer ltblue m-0 font-weight-semibold mt-2"
                *ngIf="update.showCollapseMessageViewButton === true"
                (click)="update.collapseMessageView = !update.collapseMessageView"
              >
                <i *ngIf="update.collapseMessageView === false" class="fas fa-angle-up mr-2"></i>
                {{ update.collapseMessageView === true ? '...Show More' : 'Show Less' }}
              </p>
              <app-file-chip-list
                class="mt-3"
                *ngIf="update?.files?.length"
                [files]="update.files"
                [diameter]="40"
              ></app-file-chip-list>
            </div>
          </div>
        </div>
        <div *ngIf="allUpdates?.length" class="d-flex align-items-center p-4">
          <button
            *ngIf="updateCount < allUpdates?.length"
            mat-stroked-button
            color="accent"
            type="button"
            (click)="showMoreUpdates()"
            class="mr-4"
          >
            Load More Updates
          </button>
          <div class="primary" [ngClass]="{ 'ml-2': updateCount < allUpdates?.length }">
            {{ updateCount }} of {{ allUpdates?.length }}
          </div>
        </div>
      </div>

      <div *ngIf="showScrollToTopButton" id="scroll-to-top-wrapper">
        <button class="scroll-btn" mat-mini-fab color="primary" (click)="scrollToTheTop()">
          <div class="scroll-icon"><mat-icon>arrow_upward</mat-icon></div>
        </button>
      </div>
    </section>
  </section>
</div>
