<div id="add-followers" data-test-id="add-followers" class="add-followers d-flex align-items-center">
  <app-profile-thumbnail
    *ngFor="let follower of listOfFollowers"
    [noBorder]="true"
    class="d-inline-block align-middle mr-3 mb-3 pointer"
    [height]="'32'"
    [width]="'32'"
    [userId]="follower?.id"
    [overlayIconOpacity]="1"
    [overlayIcon]="'clear'"
    [overlayIconColor]="'white'"
    [overlayIconBackground]="'bg-gray'"
    (overlayIconClickEvent)="remove(follower)"
    [matTooltip]="follower?.first_name + ' ' + follower?.last_name"
    [overlayIconTooltip]="'Remove Follower'"
    matTooltipPosition="above"
    [matTooltipShowDelay]="300"
  ></app-profile-thumbnail>
</div>
<button type="button" mat-button color="accent" class="mr-3 lh-32" (click)="add()">+ Followers</button>
