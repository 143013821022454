import { FileService } from '../services/file.service';

export class VerifiedFile {
  private _displayName: string;
  private _extension: string;
  private _isVerified = false;
  private _hasValidExtension = true;
  private _hasValidFileSize = true;
  private _fileSize = 0;
  private _isEncrypted = false;
  private _isCorrupt = false;
  public fileUploadLimit = 80000000;
  public convertedFromHEICToPNG = false;

  constructor(
    private _file: File,
    private _fileService: FileService,
    private _parentResourceId: number,
    private _parentResourceType: number
  ) {
    this._setDisplayName();
    this._setExtension();
    this._setFileSize();
  }

  private _setExtension() {
    this._extension = this.file.name.substr(this.file.name.lastIndexOf('.'), this.file.name.length - 1);
  }

  private _setDisplayName() {
    this._displayName = this.file.name.substr(0, this.file.name.lastIndexOf('.'));
  }

  private _setFileSize() {
    this._fileSize = this.file.size || 0;
  }

  set displayName(value: string) {
    this._displayName = value;
  }

  get displayName(): string {
    return this._displayName;
  }

  get extension(): string {
    return this._extension;
  }

  get file(): File {
    return this._file;
  }

  get fileSize(): number {
    return this._fileSize;
  }

  get fileService(): FileService {
    return this._fileService;
  }

  set isValidFileSize(bool: boolean) {
    this._hasValidFileSize = bool;
  }

  get isValidFileSize() {
    return this._hasValidFileSize;
  }

  set isVerified(bool: boolean) {
    this._isVerified = bool;
  }

  get isVerified() {
    return this._isVerified;
  }

  get parentResourceId(): number {
    return this._parentResourceId;
  }

  get parentResourceType(): number {
    return this._parentResourceType;
  }

  set hasValidExtension(bool: boolean) {
    this._hasValidExtension = bool;
  }

  get hasValidExtension(): boolean {
    return this._hasValidExtension;
  }

  get isEncrypted(): boolean {
    return this._isEncrypted;
  }

  set isEncrypted(bool: boolean) {
    this._isEncrypted = bool;
  }

  get isCorrupt(): boolean {
    return this._isCorrupt;
  }

  set isCorrupt(bool: boolean) {
    this._isCorrupt = bool;
  }

  // separates the file name into just the extension
  getExtension() {
    return this.file.name.substr(this.file.name.lastIndexOf('.'), this.file.name.length - 1);
  }

  // separates the file name into just the name without the extension
  getName() {
    return this.file.name.substr(0, this.file.name.lastIndexOf('.'));
  }

  // verifies that the given name is not already taken
  async verifyFileName(newName: string, existingFiles: VerifiedFile[]) {
    if (this.parentResourceId && this.parentResourceType) {
      await this.fileService
        .checkIfFileExists(newName + this.extension, this.parentResourceId, this.parentResourceType)
        .toPromise()
        .then((data) => {
          // if the file exists, we want verified to be false
          const existsInModal =
            existingFiles.filter(
              (file: VerifiedFile) => `${file.displayName}${file.extension}` === `${newName}${this.extension}`
            ).length > 1;

          this.isVerified = !data && !existsInModal;
        });
    } else {
      // if the file parent resource doesn't exist, but you upload it twice, it should run this
      const existsInModal =
        existingFiles.filter(
          (file: VerifiedFile) => `${file.displayName}${file.extension}` === `${newName}${this.extension}`
        ).length > 1;

      this.isVerified = !existsInModal;
    }
  }

  async verifyFileExtension(fileExtension: string, approvedFileExtensions?: string[]) {
    approvedFileExtensions = approvedFileExtensions || this.fileService._combinableExtensions;
    // Extension list has not dots at the beginning, so, I just removed them here
    this.hasValidExtension = approvedFileExtensions.includes(fileExtension.toLowerCase().replace('.', ''));
  }

  verifyFileSize(fileSize) {
    this.isValidFileSize = fileSize <= this.fileUploadLimit;
  }
}
