export enum ORDER_FIELD {
  ARCHITECTURE_DOT_FIRST_NAME = 'architect.first_name',
  ASSIGNED_USER_FIRST_NAME = 'assigned_user_first_name',
  ASSIGNED_USER_ID = 'assigned_user_id',
  ASSIGNED_USER_LAST_NAME = 'assigned_user_last_name',
  BUILDING_DOT_CODE = 'building.code',
  BUILDING_CODE = 'building_code',
  CLOSED_DATETIME = 'closed_datetime',
  CODE = 'code',
  COST_PER_SQFT = 'cost_per_sqft',
  CREATED_DATETIME = 'created_datetime',
  DUE_DATE = 'due_date',
  END_DATE = 'end_date',
  FIRST_NAME = 'first_name',
  FLOOR_DOT_CODE = 'floor.code',
  FLOOR_CODE = 'floor_code',
  LAST_NAME = 'last_name',
  MATRIX = 'matrix',
  NUMBER = 'number',
  PRIORITY_ID = 'priority_id',
  PROJECT_MANAGER_DOT_FIRST_NAME = 'project_manager.first_name',
  REQUESTER_FIRST_NAME = 'requester_first_name',
  REQUESTER_LAST_NAME = 'requester_last_name',
  SORT_CODE = 'sortCode',
  TITLE = 'title',
  UPDATE_DOT_CREATED_DATETIME = 'latest_update_created_datetime',
}
