<div class="container-fluid bg">
  <div class="row">
    <div
      class="col-lg-6 login_left"
      data-aos="fade-in"
      data-aos-duration="300"
      [style.backgroundImage]="'url(../../assets/img/login_bg_' + imageNumber + '.jpg)'"
    >
      <img id="main_logo" src="../assets/img/login_logo.svg" data-aos="fade-in" data-aos-duration="300" />
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-6 login_right" data-aos-delay="300" data-aos="fade-left" data-aos-duration="1000">
          <h2 class="dkblue">Hi {{ firstName }}, <br />Let's reset your password.</h2>
          <br />
          <h5 class="ltblue">{{ email }}</h5>
          <br />
          <form [formGroup]="resetFormGroup" (ngSubmit)="resetPassword()" class="example-form">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>New Password</mat-label>
              <input matInput id="password" formControlName="password" type="password" />
              <mat-error *ngIf="password.errors?.required">Password Is Required</mat-error>
              <mat-error *ngIf="!password.errors?.required && password.errors?.minlength"
                >Password Must Be At Least 8 Characters</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput id="confirm_password" formControlName="confirm_password" type="password" />
              <mat-error *ngIf="confirm_password.errors?.required">Password Confirmation is Required</mat-error>
              <mat-error *ngIf="confirm_password.errors?.match">Passwords Must Match</mat-error>
            </mat-form-field>
            <br /><br /><br /><br />
            <button mat-raised-button color="primary" type="submit" [disabled]="!resetFormGroup.valid">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
