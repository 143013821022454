<ngx-dropzone
  class="mb-3 ease br-10"
  (change)="uploadMultipleFiles($event.addedFiles)"
  [disableClick]="true"
  [multiple]="maxFiles > 1 ? true : false"
  [style.height]="height"
  *ngIf="linkedFiles.length + computerFiles.length < maxFiles"
>
  <ngx-dropzone-label class="dkblue d-flex flex-column text-center">
    <mat-icon class="my-3 mx-auto gray">upload_file</mat-icon>
    <span class="primary"> Drop files here, or choose files from: </span>
    <div class="button-list d-flex text-center mx-auto">
      <button
        (click)="uploader.click()"
        mat-stroked-button
        color="primary"
        class="pl-2 pr-3 d-flex align-items-center m-2 dkgray"
      >
        <mat-icon class="mr-1">computer</mat-icon>
        <span> Computer </span>
      </button>
      <button
        *ngIf="!uploadComputerFilesOnly"
        (click)="openFileSelectDialog()"
        mat-stroked-button
        color="primary"
        class="pl-2 pr-3 d-flex align-items-center m-2 dkgray"
      >
        <mat-icon class="mr-1"> assignment_turned_in </mat-icon>
        This Project
      </button>
      <button
        *ngIf="!uploadComputerFilesOnly && taskId"
        (click)="openFileSelectDialog(false)"
        mat-stroked-button
        color="primary"
        class="pl-2 pr-3 d-flex align-items-center m-2 dkgray"
      >
        <mat-icon class="mr-1"> check_circle </mat-icon>
        This Task
      </button>
    </div>
    <h6 class="font-weight-normal dkgray mt-3" *ngIf="maxFiles === 1">Limit 1 File</h6>
  </ngx-dropzone-label>
</ngx-dropzone>
<input
  mat-raised-button
  [hidden]="true"
  #uploader
  type="file"
  [attr.multiple]="maxFiles > 1 ? 'multiple' : null"
  (change)="uploadMultipleFiles($event.target.files); uploader.value = null"
/>
