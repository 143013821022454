import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent, TimeLogDialogComponent } from 'src/app/components';
import {
  LinkedTaskService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  TimeLogService,
} from '../../services';
import { APIFilter, TimeLog, User } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeLogFilterModel } from '../../models';
import { TimeLogFilterPipe } from '../../pipes';

import * as moment from 'moment';
import { ResourceType } from '../../enums';

@Component({
  selector: 'app-time-log',
  templateUrl: './time-log.component.html',
  styleUrls: ['./time-log.component.scss'],
})
export class TimeLogComponent implements OnInit {
  public originalLogEntries: TimeLog[] = [];
  public timeLogEntries: TimeLog[] = [];
  public filter: TimeLogFilterModel = { selection: 'all' };
  public timeLogFilter = new TimeLogFilterPipe();

  public selectedUserIds: number[] = [];
  public distinctUsers: User[] = [];
  public distinctTotals: { worker: User; hours_worked: number; minutes_worked: number }[] = [];
  public staffTotal = '';
  private linkedTaskFields = ['project_id', 'work_order'];

  constructor(
    private dialog: MatDialog,
    private linkedTaskService: LinkedTaskService,
    private modalService: ModalService,
    private timeLogService: TimeLogService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackBar: MatSnackBar,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  get ResourceType() {
    return ResourceType;
  }
  private calculateTotals() {
    this.timeLogEntries = this.timeLogFilter.transform(this.originalLogEntries, this.filter);

    this.distinctUsers = [];
    const seenIds = [];
    const usersToFilter = this.timeLogEntries.map((entry) => entry.worker);
    for (const user of usersToFilter) {
      if (!seenIds.includes(+user.id)) {
        seenIds.push(+user.id);
        this.distinctUsers.push(user);
      }
    }

    this.distinctTotals = [];
    this.distinctUsers.forEach((user) => {
      let minutes = 0;
      let hours = 0;
      const entriesForUser = this.timeLogEntries.filter((entry) => +entry.worker.id === +user.id);

      for (const entry of entriesForUser) {
        // This line will always be true for now, since no option to select users at the moment
        if (this.selectedUserIds.length === 0 || this.selectedUserIds.includes(+user.id)) {
          minutes += entry.minutes_worked;
          hours += entry.hours_worked;
        }
      }
      this.distinctTotals.push({ worker: user, hours_worked: hours, minutes_worked: minutes });
    });

    let hours_total = 0;
    let minutes_total = 0;
    this.distinctTotals.forEach((value) => {
      hours_total += value.hours_worked;
      minutes_total += value.minutes_worked;
    });

    this.staffTotal = this.formatTime(hours_total, minutes_total);
  }

  private async refresh() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading entries..');
    const linkedWOTasksFilter = [
      {
        type: 'field',
        field: 'project_id',
        value: this.projectService?.currentSelectedProjectId?.toString(),
      },
    ];
    const linkedWOTasks =
      (await this.linkedTaskService.getLinkedWOTasks(this.linkedTaskFields, linkedWOTasksFilter).toPromise()) || [];
    const timeLogFilter: APIFilter[] = [
      {
        type: 'field',
        field: 'parent_id',
        value: this.projectService?.currentSelectedProjectId?.toString(),
      },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'parent_type_id', value: ResourceType.Project.toString() },
    ];

    linkedWOTasks.forEach((linkedTask) => {
      if (linkedTask?.work_order_id) {
        const linkedTaskFilter = [
          { type: 'operator', value: 'OR' },
          {
            type: 'field',
            field: 'parent_id',
            value: linkedTask?.work_order_id?.toString(),
          },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'parent_type_id', value: ResourceType.WorkOrder.toString() },
        ];

        timeLogFilter.push(...linkedTaskFilter);
      }
    });

    this.originalLogEntries = await this.timeLogService.getTimeLogs(timeLogFilter).toPromise();
    this.calculateTotals();

    this.progressIndicatorService.close();
  }

  public formatTime(totalHours, totalMinutes) {
    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes = totalMinutes % 60;
    }

    return `${totalHours}h ${totalMinutes}m`;
  }

  public createEntry() {
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          initialParent: {
            id: this.projectService?.currentSelectedProjectId,
            type_id: 3,
            code: this.projectService?.currentSelectedProject?.code,
            title: this.projectService?.currentSelectedProject?.title,
            module_id: this.projectService?.currentSelectedProject.module_id,
          },
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Creating entry..');
          // modify the data to match the api and update the db value
          result.activity_id = result.activity.id;
          delete result.activity;
          result.worker_id = result.worker.id;
          delete result.worker;
          result.company_id = result.company.id;
          delete result.company;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          this.originalLogEntries.push(await this.timeLogService.createTimeLogEntry(result).toPromise());
          this.progressIndicatorService.close();
          this.snackBar.open('Time log entry created!');
          this.calculateTotals();
        }
      });
  }

  public editEntry(entry: TimeLog) {
    this.dialog
      .open(TimeLogDialogComponent, {
        width: '600px',
        data: {
          timeLog: entry,
          initialParent: {
            id: entry?.parent_id,
            type_id: entry?.parent_type_id,
            code: entry?.parent_code,
            title: entry?.parent_title,
            module_id: this.projectService?.currentSelectedProject.module_id,
          },
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          // update the local entry to the new values
          const entryIndex = this.originalLogEntries.findIndex((e) => e.id === entry.id);
          entry = { ...entry, ...result };
          this.originalLogEntries[entryIndex] = entry;

          // modify the data to match the api and update the db value
          result.id = entry.id;
          result.activity_id = result.activity.id;
          delete result.activity;
          result.company_id = result.company.id;
          delete result.company;
          result.worker_id = result.worker.id;
          delete result.worker;
          delete result.parent_id;
          delete result.parent_type_id;
          delete result.parent_code;
          delete result.parent_title;
          delete result.module_id;
          this.timeLogService.updateTimeLogEntry(result).subscribe();
          this.snackBar.open('Time log entry updated!');
          this.calculateTotals();
        }
      });
  }

  public deleteEntry(entryId: number) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          titleBarText: '',
          headerText: `Delete Time Log Entry`,
          descriptionText:
            'Warning: You will not be able to recover time log entry. Are you sure you want to permanently delete this entry?',
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.timeLogService.deactivateTimeLogEntry(entryId).subscribe();
          this.originalLogEntries.splice(
            this.originalLogEntries.findIndex((entry) => +entry.id === +entryId),
            1
          );
          this.snackBar.open('Time log entry deleted!');
          this.calculateTotals();
        }
      });
  }

  public setDateRange(range: 'all' | 'week' | 'month') {
    this.filter.selection = range;
    if (range === 'all') {
      this.filter.start_date = null;
      this.filter.end_date = null;
    } else if (range === 'week') {
      this.filter.start_date = moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
      this.filter.end_date = moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
    } else if (range === 'month') {
      this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
      this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
    }

    this.calculateTotals();
  }

  // TODO add these back in when we can filter totals by user
  // public clearSelectedUsers() {
  //   this.selectedUserIds = [];
  //   this.calculateTotals();
  // }
  //
  // public selectUser(user: User) {
  //   if (this.selectedUserIds.includes(+user.id)) {
  //     this.selectedUserIds.splice(this.selectedUserIds.indexOf(+user.id), 1);
  //   } else {
  //     this.selectedUserIds.push(+user.id);
  //   }
  //
  //   this.calculateTotals();
  // }
}
