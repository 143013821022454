<app-base-dialog [title]="'Change Order'" (closeDialog)="close()"></app-base-dialog>
<mat-dialog-content>
  <div id="change-order-modal" data-test-id="change-order-modal" class="row change-order-modal">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-12">
          <p class="dkblue p-3 bg-shade-red br-10">
            Be sure your upload contains the following: a detailed breakdown of cost (showing quantities and sizes of
            materials), unit cost, labor, equipment, profit and overhead, and other expense items.
          </p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <p class="m-0 dkblue font-weight-semibold">
            Proposal Request #{{ proposalRequest.local_index }}: {{ proposalRequest.title }}
          </p>
          <p class="m-0 dkblue" [innerHTML]="proposalRequest?.description"></p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="mb-2 dkblue font-weight-semibold">Trade *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select
              matNativeControl
              [(ngModel)]="draftChangeOrder.bid_package_id"
              [value]="draftChangeOrder.bid_package_id"
              [disabled]="updateChangeOrder"
            >
              <mat-option class="d-flex flex column" *ngFor="let b of bidPackages" [value]="b.id">
                <p class="m-0 lh-20 dkblue font-weight-semibold">{{ b.trade_name }}&nbsp;</p>
                <p class="m-0 lh-20 dkgray" *ngIf="b.awarded_company_name">-&nbsp;{{ b.awarded_company_name }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="dkblue mb-2 font-weight-semibold">Short Description *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput placeholder="Please enter a short description" [formControl]="short_description" />
            <mat-error *ngIf="short_description?.errors?.required">A short description is required</mat-error>
            <mat-error *ngIf="short_description.errors?.maxlength"
              >Max length of {{ short_description_length }} characters</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="dkblue mb-2 font-weight-semibold">Summary *</h6>
          <app-editor
            #summary_editor
            [has_max_length]="false"
            [placeholder]="'Type a summary'"
            [required]="true"
            [required_content]="'A summary is required'"
          ></app-editor>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <h6 class="dkblue mb-2 font-weight-semibold">Cost Change *</h6>
          <mat-radio-group
            required
            [(ngModel)]="draftChangeOrder.cost_change_type"
            name="draftChangeOrderCostChange"
            [value]="draftChangeOrder.cost_change_type"
          >
            <mat-radio-button class="dkblue" value="additional">Additional Cost</mat-radio-button>
            <br />
            <mat-radio-button class="dkblue" value="reduced">Reduced Cost</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-8">
          <h6 class="dkblue mb-2 font-weight-semibold">Amount *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <span matPrefix class="font-weight-semibold gray mr-1">$</span>
            <input matInput type="number" [(ngModel)]="draftChangeOrder.cost_change" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <h6 class="dkblue mb-2 font-weight-semibold">Time Adjustment (#) *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput type="number" [(ngModel)]="draftChangeOrder.time_change_amount" />
          </mat-form-field>
        </div>
        <div class="col-8">
          <h6 class="dkblue mb-2 font-weight-semibold">Time Adjustment (Duration) *</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select matNativeControl [(ngModel)]="draftChangeOrder.time_change_unit">
              <mat-option value="null">-</mat-option>
              <mat-option value="Day">Days</mat-option>
              <mat-option value="Week">Weeks</mat-option>
              <mat-option value="Month">Months</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6 class="dkblue mb-1 font-weight-semibold">Change Order Estimate PDF *</h6>
          <a
            class="ltblue m-0 font-weight-normal pointer text-decoration-none hover-u"
            target="_self"
            href="assets/documents/Change_Order_Template_For_Subs.pdf"
            download="Change_Order_Form.pdf"
          >
            <span>Click Here to download Change Order Form</span>
          </a>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <button mat-flat-button class="mr-3" color="accent" (click)="openUploadModal()" *ngIf="!files?.length">
            Attach PDF
          </button>
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip
              *ngFor="let file of files"
              [file]="file"
              [removable]="true"
              (removeEvent)="removeFile(file)"
            ></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="row pb-4">
  <div class="col">
    <button (click)="close()" mat-button class="gray full-width">Cancel</button>
  </div>
  <div class="col">
    <button mat-flat-button color="success" class="full-width" (click)="addChangeOrder()">Submit CO</button>
  </div>
</div>
