import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, HealthType, ProjectUpdate, ServiceResponse } from 'src/app/types';
import { ProjectOverviewConstruction } from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectOverviewService {
  host: string = environment.serviceHost;
  projectOverviewUrl = `${this.host}/api/v1/project-overview`;
  projectHealthTypeUrl = `${this.host}/api/v1/project-health-types`;
  projectUpdateUrl = `${this.host}/api/v1/project-updates`;

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getProjectOverview(projectId: number): Observable<ProjectOverviewConstruction> {
    return this.http.get(`${this.projectOverviewUrl}/${projectId}`).pipe(
      map((result: ServiceResponse) => {
        const projectOverview: ProjectOverviewConstruction = result.data;
        return projectOverview;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjectHealthTypes(fields: string[], apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectHealthTypeUrl}?fields=${fields.join(',')}&limit=10000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectHealthTypes: HealthType[] = result.data.project_health_types;
          return projectHealthTypes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectUpdates(
    fields: string[],
    apiFilters?: APIFilter[],
    limit = 10000,
    sort = 'id',
    order = 'desc',
    offset = 0
  ): Observable<ProjectUpdate[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectUpdateUrl}?fields=${fields.join(
          ','
        )}&limit=${limit}&sort=${sort}&order=${order}&offset${offset}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectUpdates: ProjectUpdate[] = result.data.project_updates;
          return projectUpdates;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createProjectUpdate(projectUpdate: ProjectUpdate, fields?: string[]): Observable<ProjectUpdate> {
    return this.http.post(`${this.projectUpdateUrl}${fields ? `?fields=${fields.join(',')}` : ''}`, projectUpdate).pipe(
      map((result: ServiceResponse) => {
        const projectUpdateToReturn = result.data['project update'];
        return projectUpdateToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectUpdate(projectUpdateId: number, projectUpdate: ProjectUpdate, fields?: string[]) {
    return this.http
      .put(`${this.projectUpdateUrl}/${projectUpdateId}${fields ? `?fields=${fields.join(',')}` : ''}`, projectUpdate)
      .pipe(
        map((result: ServiceResponse) => {
          const projectUpdateToReturn: ProjectUpdate = result.data['project update'];
          return projectUpdateToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteProjectUpdate(projectUpdateId: number): Observable<void> {
    return this.http.delete(`${this.projectUpdateUrl}/${projectUpdateId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
