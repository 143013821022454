import { Injectable } from '@angular/core';
import { SidenavLinksConfig } from 'src/app/models';
import { AuthService, ModuleService } from 'src/app/services';
import { Workspace } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class SidenavLinksService {
  public selectedLink: { routerLink: string; label: string; matIcon: string };
  public home = SidenavLinksConfig.home;
  public dispatch = SidenavLinksConfig.dispatchDashboard;
  public dashboard = SidenavLinksConfig.workspaceDashboard;
  public reminders = SidenavLinksConfig.reminders;
  public directory = SidenavLinksConfig.directory;
  public releaseNotes = SidenavLinksConfig.releaseNotes;
  public projectView = SidenavLinksConfig.projectsView;
  public requests = SidenavLinksConfig.projectRequests;
  public tenantRequests = SidenavLinksConfig.tenantProjectRequests;
  public meetings = SidenavLinksConfig.meetings;
  public workOrders = SidenavLinksConfig.workordersView;

  public purchasing = SidenavLinksConfig.purchasingView;

  constructor(private authService: AuthService, private moduleService: ModuleService) {}

  public get workspaceLinks(): { routerLink: string; label: string; matIcon: string }[] {
    return (
      ((this.authService.isStaffOnAnyModule || this.authService.isAppAdmin) &&
        this.moduleService.workspace_id === Workspace.Dispatch && [
          this.dispatch,
          ...SidenavLinksConfig.workspaceLinks,
        ]) ||
      ((this.authService.isStaffOnAnyModule || this.authService.isAppAdmin) && [
        this.dashboard,
        ...SidenavLinksConfig.workspaceLinks,
      ])
    );
  }

  public get linksByUserType(): { routerLink: string; label: string; matIcon: string }[] {
    return (
      ((this.authService.isStaffOnAnyModule || this.authService.isAppAdmin) && SidenavLinksConfig.staffLinks) ||
      (this.authService.isTenant && SidenavLinksConfig.tenantLinks) ||
      ((this.authService.isVendorOnAnyProject || this.authService.isEngineerOnAnyProject) &&
        SidenavLinksConfig.vendorLinks)
    );
  }

  selectLink(link: { routerLink: string; label: string; matIcon: string }) {
    this.selectedLink = link;
  }
}
