<app-base-dialog title="{{ dialogTitle }}" (closeDialog)="cancel()"></app-base-dialog>

<form mat-dialog-content [formGroup]="invoiceFormGroup" class="view-invoice-dialog project-modal bg-shade-gray p-4">
  <div class="row m-0">
    <div class="pl-0 col-7" [ngClass]="{ 'mx-auto': !isProjectInvoice }">
      <div class="m-portlet p-0 mb-3">
        <div class="m-portlet__body border-bottom border-gray d-flex flex-column p-4">
          <div class="d-flex align-items-top mb-5">
            <div class="d-flex flex-column mr-auto">
              <h4 class="mb-1 dkblue font-weight-semibold">Invoice {{ invoice?.number }}</h4>
              <p class="mb-0 dkgray">Submitted: {{ submittedDate }}</p>
              <p class="mb-1 dkgray">By: {{ invoice?.created_by_first_name }} {{ invoice?.created_by_last_name }}</p>
            </div>
            <div>
              <div *ngIf="invoice" class="mb-2 pr-2">
                <app-invoice-review-buttons
                  class="full-width"
                  [invoice]="invoice"
                  [disableReview]="true"
                  [display]="true"
                  [alignItemsRight]="true"
                ></app-invoice-review-buttons>
              </div>
              <div>
                <button
                  mat-button
                  color="accent"
                  type="button"
                  class="pr-2 lh-30"
                  (click)="viewTask(invoice.approval_task_id)"
                  *ngIf="invoice?.approval_task_id"
                >
                  <span class="mt-1 mr-1"> View Task </span>
                  <mat-icon>east</mat-icon>
                </button>
                <!--this link is only available if you are not on the parent's project invoices page-->
                <button
                  mat-button
                  color="accent"
                  *ngIf="this.invoice?.project_id && isNotOnProjectInvoiceUrl"
                  (click)="goToInvoices()"
                >
                  Go to Invoices
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 pr-5">
            <h3 class="mb-2 dkblue font-weight-semibold">{{ invoice?.company_name }} | {{ quote?.description }}</h3>
            <div class="d-flex align-items-center">
              <h6
                *ngIf="currentWorkspace === eWorkspace.Construction"
                class="mb-0 bg-shade-gray dkblue br-5 px-2 lh-22 mr-3"
              >
                {{ bidPackage?.trade_name }}
              </h6>
              <p class="mb-0 dkgray">
                Date of Service: {{ invoiceDate }} {{ invoiceEndDate ? ' - ' + invoiceEndDate : '' }}
              </p>
            </div>
          </div>
        </div>
        <div class="m-portlet__body border-bottom border-gray d-flex flex-column p-4">
          <div class="d-flex flex-column align-items-top m-0">
            <div class="d-flex align-items-top py-2">
              <!-- NEED TO ADD INVOICE TYPE TO THIS LINE ==   INVOICEFOR - TYPE -->
              <h5 class="dkblue m-0 font-weight-normal mr-auto text-wrap" [innerHTML]="invoiceFor"></h5>
              <h5 class="dkblue m-0 font-weight-semibold ml-5">
                {{ invoice?.total | currency: 'USD' }}
              </h5>
            </div>
            <div class="d-flex align-items-top py-2" *ngIf="currentWorkspace === eWorkspace.Construction">
              <h5 class="dkblue mb-0 font-weight-normal mr-auto text-wrap">Retainage Withheld</h5>
              <h5 class="gray m-0 ml-5 font-weight-normal">
                {{ (+invoice?.total ? (+invoice?.retainage || 0) / +invoice?.total : 0) | percent: '1.1-1' }}
              </h5>
              <h5 class="dkblue m-0 font-weight-semibold ml-4">({{ invoice?.retainage | currency: 'USD' }})</h5>
            </div>
            <div
              *ngIf="currentWorkspace !== eWorkspace.Construction && invoice?.shipping"
              class="d-flex align-items-top py-2"
            >
              <h5 class="dkblue mb-0 font-weight-normal mr-auto">Shipping</h5>
              <h5 class="m-0 dkblue font-weight-semibold ml-5">
                {{ +invoice?.shipping || 0 | currency: 'USD' }}
              </h5>
            </div>
            <div
              *ngIf="currentWorkspace !== eWorkspace.Construction && invoice?.tax"
              class="d-flex align-items-top py-2"
            >
              <h5 class="dkblue mb-0 font-weight-normal mr-auto">Sales Tax</h5>
              <h5 class="m-0 dkblue font-weight-semibold ml-5">
                {{ +invoice?.tax || 0 | currency: 'USD' }}
              </h5>
            </div>
          </div>
        </div>
        <div class="m-portlet__body p-0 row m-0 align-items-stretch">
          <div class="col-6 p-4 border-right border-gray">
            <h6 class="mb-2 dkblue font-weight-semibold">Invoice Files</h6>
            <mat-chip-list>
              <app-file-chip *ngFor="let file of invoiceFiles" [file]="file"></app-file-chip>
            </mat-chip-list>
          </div>
          <div class="d-flex flex-column col-6 p-4 bg-shade-ltblue text-right">
            <p class="dkblue mb-2 mt-0 font-weight-semibold lh-18">Amount Due</p>
            <!-- <p class="gray mb-0 font-weight-normal lh-18" *ngIf="currentWorkspace === eWorkspace.Construction">
                Total - Ret = Amount Due
              </p> -->
            <h2 class="dkblue m-0">
              {{
                (+invoice?.total || 0) - (+invoice?.retainage || 0) + (+invoice?.shipping || 0) + (+invoice?.tax || 0)
                  | currency: 'USD'
              }}
            </h2>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center p-5 mx-auto" *ngIf="loading">
        <mat-spinner color="accent" [diameter]="40"></mat-spinner>
      </div>
      <div *ngIf="!loading && isWorkspaceStaff && !arf?.id" class="m-portlet p-0 mb-3">
        <div class="m-portlet__body d-flex flex-column p-4">
          <h4 class="primary mr-auto mb-2">Funding</h4>
          <div class="d-flex align-items-top m-0">
            <div class="d-flex flex-column flex-fill pr-4">
              <h6 class="mb-2 dkblue font-weight-semibold">Invoice Tenant</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select formControlName="timeframe_id">
                  <mat-option *ngFor="let t of timeframes" [value]="t?.id">
                    {{ t.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-column flex-fill pl-4">
              <h6 class="mb-2 dkblue font-weight-semibold">Funding Source</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select formControlName="funding_source">
                  <mat-option *ngFor="let fs of fundingSources; let i = index" [value]="i">
                    {{ fs.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="m-portlet p-0 mb-3">
        <div class="m-portlet__body d-flex flex-column p-4">
          <div class="d-flex align-items-top m-0">
            <div class="d-flex flex-column flex-fill">
              <h6 class="mb-2 dkblue font-weight-semibold">Fiscal Year</h6>
              <mat-form-field appearance="outline" class="full-width">
                <mat-select formControlName="fiscal_year">
                  <mat-option *ngFor="let fy of fiscalYears" [value]="fy.value">
                    {{ fy.value }}<ng-container *ngIf="fy.isCurrentYear">&nbsp;(Current)</ng-container>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div> -->
      <div *ngIf="!loading && isWorkspaceStaff && (this.arfInvoiceAmounts.length || isReviewItem)" class="m-portlet">
        <div class="m-portlet__body p-4">
          <app-cost-code-selection
            [parentId]="invoice?.project_id || arf?.id"
            [displayArfLink]="!!arf?.id"
            [arfInvoiceAmounts]="initialArfInvoiceAmounts"
            [subCostCodeBudgets]="subCostCodeBudgets"
            [allowAdditionalCodes]="allowAdditionalCodes"
            [disabled]="!isReviewAdmin"
            [moduleId]="currentWorkspace"
            (closeDialog)="cancel()"
          >
          </app-cost-code-selection>
        </div>
        <div class="m-portlet__body d-flex p-4 align-items-center bg-shade-accent border-top border-gray">
          <div *ngIf="isReviewItem" class="d-flex align-items-center">
            <mat-icon
              class="mr-2"
              [ngClass]="{
                green: !invoiceTotalDoesNotMatchArfTotal,
                red: invoiceTotalDoesNotMatchArfTotal
              }"
              >{{ invoiceTotalDoesNotMatchArfTotal ? 'cancel' : 'check_circle' }}</mat-icon
            >
            <p
              class="mb-0 mr-auto"
              [ngClass]="{
                green: !invoiceTotalDoesNotMatchArfTotal,
                red: invoiceTotalDoesNotMatchArfTotal
              }"
            >
              {{
                !invoiceTotalDoesNotMatchArfTotal ? 'Perfectly allocated' : 'Invoice total must be allocated perfectly'
              }}
            </p>
          </div>
          <h4 class="primary ml-auto mb-0">{{ arfInvoiceTotal || 0 | USDollar }}</h4>
        </div>
      </div>
    </div>
    <div *ngIf="isProjectInvoice" [hidden]="!isProjectInvoice" class="col pl-2 pr-0 d-flex flex-column">
      <app-invoice-data
        *ngIf="!loading"
        class="d-flex flex-column pb-2"
        [bidPackage]="bidPackage"
        [quote]="quote"
        [invoice]="invoice"
        [previousView]="previousView"
        [changeOrder]="changeOrder"
        [currentWorkspace]="currentWorkspace"
        (reloadInvoiceDialog)="refreshViewDialog($event)"
      ></app-invoice-data>
    </div>
  </div>
</form>
<div matDialogActions class="py-3 d-flex align-items-center justify-content-end">
  <div *ngIf="isReviewItem" class="ml-3">
    <p class="dkgray text-right lh-16 mb-0" style="max-width: 460px">
      <small>
        By clicking "Approve", you are confirming your agreement with the details outlined above and our
        <a href="https://uhat.org/terms-of-service" class="ltblue hover-u">terms and conditions</a>. This action may
        create a signature on your behalf.
      </small>
    </p>
  </div>
  <button
    *ngIf="isReviewItem"
    mat-flat-button
    color="warn"
    class="ml-3 px-5"
    (click)="updateApprovalStatus(TaskReviewStatus.Rejected)"
  >
    Reject
  </button>
  <button
    *ngIf="isReviewItem"
    mat-flat-button
    color="success"
    class="ml-3 px-5"
    (click)="updateApprovalStatus(TaskReviewStatus.Approved)"
  >
    Approve
  </button>
  <button *ngIf="!isReviewItem" (click)="cancel()" mat-button type="button" color="accent">Close Window</button>
</div>
