<button
  [disabled]="!!task?.is_locked || !project?.id"
  *ngIf="
    (isStaff || currentUser?.id === task?.assigned_user_id) &&
    task?.status_id === TaskStatus.Open &&
    (!accessoryData?.isReviewItem || reviewStatus?.status === TaskReviewStatus.Rejected)
  "
  mat-flat-button
  color="primary"
  class="align-middle"
  (click)="openUploadSubmittalsDialog()"
>
  {{ !accessoryData?.isReviewItem ? 'Upload Submittals' : 'Update Submittals' }}
</button>

<button
  [disabled]="!!task?.is_locked"
  *ngIf="displayAddEngineer"
  mat-flat-button
  color="accent"
  class="align-middle mb-2"
  (click)="openSelectEngineerDialog()"
>
  <span class="mr-2"> Forward to Engineer </span>
  <mat-icon>east</mat-icon>
</button>

<button
  [disabled]="!!task?.is_locked"
  *ngIf="reviewIsComplete && (currentUserIsPm || currentUserIsArch) && bidPackage && !bidPackage.transmittal_is_created"
  mat-flat-button
  color="primary"
  class="align-middle"
  (click)="createTransmittal()"
>
  Send Transmittal
</button>

<button
  [disabled]="!!task?.is_locked"
  mat-flat-button
  color="success"
  class="align-middle"
  (click)="export()"
  *ngIf="false"
>
  Export Transmittal
</button>

<app-submittal-transmittal #transmittal [project]="project" [bidPackage]="bidPackage" [accessoryData]="accessoryData">
</app-submittal-transmittal>
