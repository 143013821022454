import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocationService } from 'src/app/services';
import { Department, LocationExist, APIFilter } from 'src/app/types';
import { LOCATION_NOT_FOUND } from 'src/app/utils';
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent implements OnInit {
  @Input() public notFound = false;
  @Input() public required = false;
  @Input() public showLabel = true;

  private _placeholder = 'Search Departments';

  public department = new FormControl('');
  public filteredDepartments: Observable<Department[]>;

  constructor(private _locationService: LocationService) {}

  async ngOnInit() {
    await this._updateValidations();
  }

  get placeholder() {
    return !this.showLabel && this.required ? `${this._placeholder}*` : this._placeholder;
  }

  private _filter(entry: string): Department[] {
    return this._locationService.departments.filter(
      (department) =>
        department?.name?.toLowerCase()?.includes(entry?.toLowerCase()) ||
        department?.name?.toLowerCase()?.includes('not')
    );
  }

  private _filterExcludingNotFound(value: string): Department[] {
    return this._locationService.departments.filter((department: Department) =>
      department?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );
  }

  private async _loadAllDepartments() {
    this._locationService.departments = [
      ...(await this._locationService.getDepartments().toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  private async _subscribe() {
    this.filteredDepartments = this.department.valueChanges.pipe(
      startWith(''),
      map((value: string | Department) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this._locationService.departments.slice()))
    );
  }

  private async _updateValidations() {
    if (this.required) {
      this.department.setValidators([Validators.required]);
    }
  }

  public checkValue() {
    // times when user changes his mind, back and forth
    // value in input and does not select it,
    // This will select it
    if (this.department?.value && typeof this.department?.value === 'string') {
      const foundFloors = this._filterExcludingNotFound(this.department.value);
      if (foundFloors.length === 1) {
        // we set it for them
        this.department.setValue(foundFloors[0]);
      } else {
        this.department.setErrors({ doesNotExist: true });
      }
    }
  }

  public async clearInput(event = null) {
    this.department.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public async getAllDepartments() {
    this._loadAllDepartments();
    this.department.disable();
  }

  public async getDepartmentsBySuiteId(suiteId: number, departmentId?: number) {
    const departmentFilters: APIFilter[] = !departmentId
      ? [
          { type: 'field', field: 'suite_id', value: suiteId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: ')' },
        ]
      : [
          { type: 'field', field: 'suite_id', value: suiteId.toString() },
          { type: 'operator', value: 'AND' },
          { type: 'operator', value: '(' },
          { type: 'operator', value: '(' },
          { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: ')' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: departmentId.toString() },
          { type: 'operator', value: ')' },
        ];
    this._locationService.departments = [
      ...(await this._locationService.getDepartments(['id', 'name', 'is_enabled'], departmentFilters).toPromise()),
      ...(this.notFound ? [LOCATION_NOT_FOUND] : []),
    ];
    this._subscribe();
  }

  public mapper(department: Department): string {
    return department?.name || '';
  }

  public valueExist(value: string): LocationExist {
    const foundDepartments = this._filterExcludingNotFound(value);
    if (foundDepartments.length === 0) {
      this.department.setErrors({ doesNotExist: true });
      return { exists: false, id: null, single: false };
    }

    const id = foundDepartments.length === 1 ? foundDepartments[0].id : null;
    const single = foundDepartments.length === 1 ? true : false;
    return { exists: true, id, single };
  }
}
