<div
  class="row align-items-center"
  [ngClass]="{ 'mt-4 pt-2': data.reviewChain && approvals === data.reviewChain.length }"
  *ngIf="data.isReviewItem && showReviewChainData"
>
  <div class="col pointer" (click)="toggleReviewWindow()">
    <h5 class="m-0 dkblue font-weight-semibold d-inline-block align-middle">
      <i class="fa fa-chevron-right" style="width: 18px" *ngIf="!reviewsOpen"></i>
      <i class="fa fa-chevron-down" style="width: 18px" *ngIf="reviewsOpen"></i> Reviews
      <small class="gray font-weight-semibold ml-3">
        {{ approvals }} of {{ data.reviewChain && data.reviewChain.length }}
        <span class="ml-1"> Approvals </span>
      </small>
    </h5>
  </div>
  <div class="col-auto text-right">
    <span *ngIf="userCanEdit && (reviewIsEditable || isOpenReview)">
      <!-- <button mat-button type="button" color="accent" class="pl-2 pr-2 ml-2 ltgray" color="accent" (click)="resetReview()"
        matTooltip="Edit Review" matTooltipPosition="above" [matTooltipShowDelay]="300" style="line-height:24px;">
        Edit
      </button> -->
      <button
        mat-button
        type="button"
        color="accent"
        class="pl-2 pr-2 ml-2"
        (click)="editReview()"
        matTooltip="Edit Review"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        style="line-height: 24px"
        [disabled]="isLocked"
        *ngIf="!isOpenReview && ![TaskAccessoryType.Submittals, TaskAccessoryType.BidContract].includes(data.type)"
      >
        Edit
      </button>
      <button
        mat-button
        type="button"
        color="accent"
        class="pl-2 pr-2 ml-2"
        (click)="resetReview()"
        matTooltip="Reset Review"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        [disabled]="isLocked"
        style="line-height: 24px"
      >
        Reset
      </button>
    </span>
  </div>
</div>
<div class="row mt-2 reviewer-container" *ngIf="data.isReviewItem && showReviewChainData">
  <div class="col">
    <div *ngIf="reviewers && reviewers.length === 0" class="row">
      <div class="col">
        <p class="m-0 gray font-italic">Click Edit to add reviewers</p>
      </div>
    </div>
    <div
      class="reviewer d-flex mt-2 m-0 p-3 align-items-center w-100"
      [ngClass]="{
        'current-review': currentReviewChainItem?.id === reviewer.id
      }"
      *ngFor="let reviewer of displayReviewers; let i = index"
    >
      <app-profile-info [user]="reviewer" [avatarSize]="40" [additionalUserFields]="['title']" class="mr-auto">
      </app-profile-info>
      <div
        class="d-flex align-items-center text-center pr-0 ml-3"
        *ngIf="!retractLoading || reviewer.id !== authService.currentUser.id"
      >
        <span
          class="d-flex align-items-center"
          [ngClass]="displayReviewersService.getStatusColor(reviewer, reviewer.id, currentReviewChainItem?.id)"
          *ngIf="
            !currentUserIsReviewAdmin || reviewer.status !== TaskReviewStatus.Pending || reviewer.id !== currentUser.id
          "
        >
          <h6
            *ngIf="
              reviewer.status !== TaskReviewStatus.Pending &&
              reviewer.id === authService.currentUser.id &&
              !reviewers[i + 1]?.status &&
              !task.is_locked
            "
            class="ltblue hover-u pointer mr-3 me-3 mb-0"
            matTooltip="Undo Review"
            matTooltipPosition="above"
            (click)="retractReview()"
          >
            Undo
          </h6>
          <i
            *ngIf="displayReviewersService.getStatusIcon(reviewer.status, isOpenReview)"
            class="material-icons d-inline-block align-middle"
            [ngClass]="displayReviewersService.getStatusColor(reviewer)"
          >
            {{ displayReviewersService.getStatusIcon(reviewer.status, isOpenReview) }}
          </i>
          <h6 class="m-0 ml-2 d-inline-block align-middle">
            {{
              displayReviewersService.getStatusText(
                reviewer,
                currentReviewChainItem?.id,
                reviewIsRejected,
                isOpenReview
              )
            }}
          </h6>
        </span>

        <app-task-accessory
          *ngIf="
            currentReviewChainItem?.status === TaskReviewStatus.Pending &&
            (currentReviewChainItem?.id === reviewer.id ||
              (currentUserIsReviewAdmin && reviewer.status === TaskReviewStatus.Pending)) &&
            reviewer.id === currentUser.id &&
            task &&
            data
          "
          class="d-inline-block align-middle"
          [task]="task"
          [data]="data"
          [startReview]="true"
          [custodyChain]=""
          (taskChanged)="emitReviewChange()"
          [showExtras]="false"
        ></app-task-accessory>
      </div>
      <mat-spinner
        *ngIf="retractLoading && reviewer.id === authService.currentUser.id"
        class="d-inline-block align-middle m-0 ml-2"
        color="accent"
        [diameter]="20"
      ></mat-spinner>
    </div>
  </div>
</div>
<div *ngIf="!showReviewChainData && (reviewedByVendor || canSendContract)">
  <app-task-accessory
    *ngIf="
      currentReviewChainItem?.status === TaskReviewStatus.Pending &&
      (currentReviewChainItem?.id === currentUser.id || canSendContract) &&
      task &&
      data
    "
    class="full-width"
    [task]="task"
    [data]="data"
    [startReview]="true"
    (taskChanged)="emitReviewChange()"
    [showExtras]="false"
  ></app-task-accessory>
</div>
