import { Pipe, PipeTransform } from '@angular/core';
import { BidPackageStatus, Workspace } from 'src/app/enums';
import { InvoiceBidPackage, InvoiceQuote } from 'src/app/pages';

@Pipe({
  name: 'projectInvoiceTotals',
})
export class ProjectInvoiceTotalsPipe implements PipeTransform {
  transform(
    invoices: InvoiceBidPackage[] | InvoiceQuote[],
    workspace: Workspace,
    totalType: 'total-contract-sum' | 'retainage-wh' | 'billed-to-date' | 'balance-to-finish'
  ): number {
    const reductionFunction = (acc: number, invoice: InvoiceBidPackage | InvoiceQuote) => {
      switch (totalType) {
        case 'total-contract-sum':
          return (acc +=
            +invoice.total_contract_sum ||
            (invoice.child_request?.id || invoice.child_project?.id ? 0 : +invoice.awarded_amount) ||
            0);
        case 'retainage-wh':
          return (acc += +invoice.retainage || 0);
        case 'billed-to-date':
          return (acc += +invoice.billed || 0);
        case 'balance-to-finish':
          return (acc +=
            invoice.status_id === BidPackageStatus.CLOSED
              ? 0
              : (+invoice.total_contract_sum ||
                  (invoice.child_request?.id || invoice.child_project?.id ? 0 : +invoice.awarded_amount) ||
                  0) - (+invoice.billed || 0));
        default:
          return acc;
      }
    };

    if (workspace === Workspace.Construction) {
      return (invoices as InvoiceBidPackage[]).reduce(reductionFunction, 0);
    } else {
      return (invoices as InvoiceQuote[]).reduce(reductionFunction, 0);
    }
  }
}
