import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArfPurchaseTypeId } from '../../enums';
import { DateService, ProductService } from '../../services';
import { ArfPurchaseType } from '../../types';
import { convertDateToFiscalYear } from '../../utils';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'app-arf-purchase-type-dialog',
  templateUrl: './arf-purchase-type-dialog.component.html',
  styleUrls: ['./arf-purchase-type-dialog.component.scss'],
})
export class ArfPurchaseTypeDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private editorComponent: EditorComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dateService: DateService,
    public dialogRef: MatDialogRef<ArfPurchaseTypeDialogComponent>,
    private productService: ProductService
  ) {}

  // imported data
  public assetTagged: boolean;
  public purchaseType: ArfPurchaseTypeId;
  public vendorName: string;
  public selectedYear: string;

  // local data
  public purchaseTypes: ArfPurchaseType[];
  public fiscalYears: { value: string | number; isCurrentYear: boolean }[] = [];

  async ngOnInit() {
    this.assetTagged = this.data?.assetTagged || false;
    this.purchaseType = this.data?.purchaseType ?? ArfPurchaseTypeId.Vendor;
    this.vendorName = this.data?.vendorName;

    this.selectedYear = this.selectedYear?.toString() || convertDateToFiscalYear(new Date()).toString();

    this.fiscalYears = this.dateService.getFiscalYearOptions(this.selectedYear);

    if (this.data?.description) {
      this.editorComponent.content.setValue(this.data.description);
    }

    this.purchaseTypes = await this.productService
      .getArfPurchaseTypes(this.productService.purchaseTypeFields)
      .toPromise();
  }

  public setArfPurchaseType(purchaseType: ArfPurchaseTypeId) {
    this.purchaseType = purchaseType;
  }

  public setTagStatus(tagStatus: boolean) {
    this.assetTagged = tagStatus;
  }

  public setFiscalYear(year: string): void {
    this.selectedYear = year;
  }

  get purchaseInformation() {
    return this.editorComponent.content?.value;
  }

  submit() {
    this.dialogRef.close({
      arfPurchaseType: this.purchaseType,
      assetTagged: this.assetTagged,
      purchaseInformation: this.purchaseInformation,
      fiscalYear: this.selectedYear,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
