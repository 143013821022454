import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserProfilePreviewComponent } from 'src/app/components';
import { ApplicationRole, ProjectStatus, UserType } from 'src/app/enums';
import { AuthService, ProgressIndicatorService, ProjectService, UserService } from 'src/app/services';
import { Project, User } from 'src/app/types';
import { ProjectDirectoryService } from 'src/app/workspaces/construction/services';
import { UserSelectModalComponent } from '../user-select-modal/user-select-modal.component';

@Component({
  selector: 'app-project-followers',
  templateUrl: './project-followers.component.html',
  styleUrls: ['./project-followers.component.scss'],
})
export class ProjectFollowersComponent implements OnChanges {
  @Input() project: Project;
  ProjectStatus = ProjectStatus;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    public projectDirectoryService: ProjectDirectoryService,
    public projectService: ProjectService,
    private router: Router,
    private snackbar: MatSnackBar,
    private userService: UserService
  ) {}
  amFollowing: boolean;
  ngOnChanges(): void {
    if (this.project) {
      this.amFollowing =
        this.project?.followers?.map((follower) => +follower.id).includes(+this.authService?.currentUser?.id) || false;
    }
  }

  public get followersCount(): number {
    return this.project?.followers.length - 5;
  }

  public async followProject(follow: 'Follow' | 'Unfollow'): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(follow === 'Follow' ? 'Following' : 'Unfollowing' + ' project...');
    this.project.followers = await this.projectService
      .updateFollowers(this.project.id, {
        followers: [this.authService.currentUser?.id],
        action: follow === 'Follow' ? 'add' : 'remove',
      })
      .toPromise();

    this.amFollowing =
      this.project.followers?.map((follower) => +follower.id).includes(+this.authService?.currentUser?.id) || false;
    // check if its a directory page so, we can update it
    const urlArr = this.router?.url?.split('/');
    const url = urlArr?.length ? urlArr[urlArr.length - 1]?.toLocaleLowerCase() : '';
    if (url === 'directory') {
      await this.projectDirectoryService.loadUsersForProject(
        this.projectService.currentSelectedProjectId,
        this.projectService.currentSelectedProject.request_id
      );
      this.progressIndicatorService.close();
    } else {
      this.progressIndicatorService.close();
    }
  }

  public async editFollowers(): Promise<void> {
    // gather the workspace managers for the project module
    const allWorkspaceManagerIds = (
      await this.userService
        .getUsersByRole(ApplicationRole?.WorkspaceManager, this.project?.module_id, null, null, false)
        .toPromise()
    ).map((workSpaceManager: User) => +workSpaceManager?.id);
    this.project.followers = this.project.followers || [];
    this.dialog
      .open(UserSelectModalComponent, {
        data: {
          title: 'Update Followers',
          createUser: { title: 'Guest', guestUser: false },
          preSelectedUsers: [...this.project.followers.map((follower) => ({ ...follower, id: +follower.id }))],
          minimumRemovalPermission:
            this.authService.isAppAdmin ||
            this.authService?.isWorkspaceManager ||
            +this?.project?.project_manager_id === +this.authService?.currentUser?.id ||
            allWorkspaceManagerIds?.includes(+this.authService?.currentUser?.id) ||
            this.authService.isWorkspaceManager,
          allowedUserTypeIds: [UserType.Staff, UserType.Tenant],
          excludeVendors: true,
        },
        disableClose: true,
      })
      .afterClosed()
      // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        const removalIds = deSelectedUsers?.map((deSelectedUser: User) => deSelectedUser.id) || [];
        const additionIds = selectedUsers?.map((selectedUser: User) => selectedUser.id) || [];
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Updating Followers...');
        if (removalIds?.length > 0) {
          this.project.followers = await this.projectService
            .updateFollowers(this.project.id, { followers: removalIds, action: 'remove' })
            .toPromise();
        }
        if (additionIds?.length > 0) {
          this.project.followers = await this.projectService
            .updateFollowers(this.project.id, { followers: additionIds, action: 'add' })
            .toPromise();
        }

        this.amFollowing =
          this.project.followers?.map((follower) => +follower.id).includes(+this.authService?.currentUser?.id) || false;
        // check if its a directory page so, we can update it
        const urlArr = this.router?.url?.split('/');
        const url = urlArr?.length ? urlArr[urlArr.length - 1]?.toLocaleLowerCase() : '';
        if (url === 'directory') {
          await this.projectDirectoryService.loadUsersForProject(
            this.projectService.currentSelectedProjectId,
            this.projectService.currentSelectedProject.request_id
          );
          this.progressIndicatorService.close();
        } else {
          this.progressIndicatorService.close();
        }
        if (removalIds?.length > 0 || additionIds?.length > 0) {
          this.snackbar.open(`You have updated this project's followers!`);
        }
      });
  }

  public openUserProfilePreview(userId: number): void {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }
}
