<app-base-dialog [draggable]="false" [title]="'Select Users'" (closeDialog)="cancel()"></app-base-dialog>
<div
  class="row m-0 pointer align-items-center pt-2 pb-2 reviewer"
  matRipple
  *ngFor="let user of usersToDisplay; let i = index"
>
  <div class="col pl-3 d-flex" (click)="toggleUser(user)">
    <app-profile-thumbnail [width]="32" [height]="32" [userId]="user.id" class="follower"></app-profile-thumbnail>
    <span class="d-inline-block align-middle ml-2">
      <p class="dkblue font-weight-semibold" style="margin-bottom: -5px">{{ user.first_name }} {{ user.last_name }}</p>
      <span class="main-label font-weight-normal gray m-0">
        {{ user.title }}
      </span>
    </span>
  </div>
  <div class="col-auto text-right pr-3">
    <mat-checkbox
      class="mr-2"
      (change)="toggleUser(user)"
      [checked]="user.is_selected"
      [disabled]="selectionLimitReached && !user.is_selected"
    ></mat-checkbox>
  </div>
</div>
<div class="d-flex justify-content-end mt-5">
  <button mat-button class="gray" (click)="cancel()" type="button">Cancel</button>
  <button
    mat-flat-button
    color="success"
    class="ml-4"
    (click)="submit()"
    type="button"
    [disabled]="!selectedUsers.length"
  >
    Select
  </button>
</div>
