<div data-test-id="dispatch-topic" id="dispatch-topic" class="dispatch-topic">
  <div class="row mb-4 align-items-center">
    <div class="col-3">
      <h6 class="dkblue m-0">Group/Category</h6>
    </div>
    <div class="col">
      <app-topic-group [showTitle]="false" #topic_groups></app-topic-group>
    </div>
    <div class="col">
      <app-request-category [showTitle]="false" #topics_categories></app-request-category>
    </div>
  </div>

  <div class="row mb-4 align-items-center">
    <div class="col-3">
      <h6 class="m-0 dkblue">Topic*</h6>
    </div>
    <div class="col-9">
      <app-request-topic [showTitle]="false" #topics></app-request-topic>
    </div>
  </div>

  <div class="row mb-4 align-items-center">
    <div class="col-3">
      <h6 class="dkblue m-0">Workspace*</h6>
    </div>
    <div class="col-9">
      <app-workspace-dropdown
        [showTitle]="false"
        #workspace
        [selectedTopic]="topicGroup?.value"
        (clearTopic)="topicGroup.reset()"
        [customPlaceholder]="'A workspace is required to create item'"
        [placeholder]="'---'"
      >
      </app-workspace-dropdown>
    </div>
  </div>
</div>
