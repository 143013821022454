<div class="purchasing-invoice-list" id="purchasing-invoice-list">
  <form [formGroup]="filters">
    <div class="d-flex flex-wrap align-items-center">
      <mat-form-field appearance="outline" class="mr-3 mb-4" style="width: 120px">
        <mat-select formControlName="fiscalYears" placeholder="Fiscal Year" multiple>
          <button mat-button style="width: 100%" (click)="handleSelectAll('fiscalYears')">
            {{
              filters.get('fiscalYears').value?.length === filterOptions.fiscalYears?.length
                ? 'Select None'
                : 'Select All'
            }}
          </button>
          <mat-option *ngFor="let fiscalYears of filterOptions.fiscalYears" [value]="fiscalYears.value">
            {{ fiscalYears.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          class="gray pointer"
          *ngIf="!(filters.get('fiscalYears').value | isJsonEquivalent: [currentFiscalYear])"
          matSuffix
          (click)="filters.get('fiscalYears').setValue([currentFiscalYear]); $event.stopPropagation()"
          >cancel</mat-icon
        >
      </mat-form-field>

      <mat-form-field *ngIf="isAdmin || isDataAnalyst" appearance="outline" class="mr-3 mb-4">
        <mat-select formControlName="modules" placeholder="Workspace" multiple>
          <button mat-button style="width: 100%" (click)="handleSelectAll('modules')">
            {{ filters.get('modules').value.length === filterOptions.modules.length ? 'Select None' : 'Select All' }}
          </button>
          <mat-option *ngFor="let module of filterOptions.modules" [value]="module.value">
            {{ module.name }}
          </mat-option>
        </mat-select>

        <mat-icon
          class="gray pointer"
          *ngIf="!(filters.get('modules').value | isJsonEquivalent: [this.moduleService.workspace_id?.toString()])"
          matSuffix
          (click)="
            filters.get('modules').setValue([this.moduleService.workspace_id?.toString()]); $event.stopPropagation()
          "
          >cancel</mat-icon
        >
      </mat-form-field>
      <mat-button-toggle-group formControlName="status" class="lh-38 mb-4">
        <mat-button-toggle value="" class="px-2">
          All
          <span class="badge badge-pill" [ngClass]="selectedStatus === '' ? 'badge-primary' : 'badge-secondary'">
            {{ dataSource.data | purchasingInvoiceListStatusCount: 'all':filters.value }}
          </span>
        </mat-button-toggle>
        <mat-button-toggle *ngFor="let status of statusOptions" [value]="status.value" class="px-2">
          {{ status.name }}
          <span
            class="badge badge-pill"
            [ngClass]="selectedStatus === status.value ? 'badge-primary' : 'badge-secondary'"
          >
            {{ dataSource.data | purchasingInvoiceListStatusCount: status.value:filters.value }}
          </span>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button
        mat-stroked-button
        class="ml-auto bg-white lh-40 mb-4"
        color="primary"
        type="button"
        (click)="showFilters = !showFilters"
        [matBadge]="filtersAppliedCount > 0 && !showFilters ? filtersAppliedCount : null"
        matBadgeColor="warn"
      >
        <mat-icon class="mr-2"> filter_list </mat-icon>
        Filters
      </button>
      <mat-form-field appearance="outline" class="ml-3 mb-4">
        <input type="text" placeholder="Search Invoices" matInput formControlName="search" />
        <button
          type="button"
          *ngIf="filters.get('search').value"
          matSuffix
          mat-icon-button
          (click)="filters.get('search').setValue(null)"
        >
          <mat-icon class="gray">cancel</mat-icon>
        </button>
      </mat-form-field>
      <button
        color="primary"
        class="mb-4 ml-3 bg-white border border-gray"
        mat-icon-button
        type="button"
        (click)="exportInvoices()"
        [disabled]="isExporting"
        matTooltip="Export List to CSV"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        <mat-icon *ngIf="!isExporting" color="primary"> file_download </mat-icon>
        <span class="d-inline-flex">
          <mat-spinner *ngIf="isExporting" color="accent" [diameter]="20"></mat-spinner>
        </span>
      </button>
    </div>
    <div *ngIf="showFilters" class="m-portlet mb-4 p-4 bg-shade-ltblue">
      <div class="row align-items-center">
        <div class="col-2 pr-0">
          <h6 class="primary">Supplier Company</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input type="text" matInput formControlName="company" [matAutocomplete]="autoCompany" />
            <mat-autocomplete #autoCompany="matAutocomplete">
              <mat-option *ngFor="let option of filteredCompanyOptions$ | async" [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
            <button
              *ngIf="filters.get('company').value?.length"
              matSuffix
              mat-icon-button
              (click)="filters.get('company').setValue(''); $event.stopPropagation()"
            >
              <mat-icon class="gray">cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-2 pr-0">
          <h6 class="primary">Date Range</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
              <input matStartDate formControlName="startDate" placeholder="Start date" />
              <input matEndDate formControlName="endDate" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="filters.get('dateRange')['controls'].startDate.hasError('matStartDateInvalid')">
              Invalid start date
            </mat-error>
            <mat-error *ngIf="filters.get('dateRange')['controls'].endDate.hasError('matEndDateInvalid')">
              Invalid end date
            </mat-error>
            <button
              *ngIf="filters.get('dateRange').get('startDate').value || filters.get('dateRange').get('endDate').value"
              matSuffix
              mat-icon-button
              (click)="
                filters.get('dateRange').get('startDate').setValue(null);
                filters.get('dateRange').get('endDate').setValue(null);
                $event.stopPropagation()
              "
            >
              <mat-icon class="gray">cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-2 pr-0">
          <h6 class="primary">Parent</h6>
          <mat-form-field appearance="outline" class="full-width">
            <input type="text" matInput formControlName="project" [matAutocomplete]="autoProject" />
            <mat-autocomplete #autoProject="matAutocomplete">
              <mat-option *ngFor="let option of filteredProjectOptions$ | async" [value]="option.name">
                {{ option.name | truncate: 30 }}
              </mat-option>
            </mat-autocomplete>
            <button
              *ngIf="filters.get('project').value?.length"
              matSuffix
              mat-icon-button
              (click)="filters.get('project').setValue(''); $event.stopPropagation()"
            >
              <mat-icon class="gray">cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-2 pr-0">
          <h6 class="primary">Type</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select formControlName="types" multiple>
              <mat-option value="bid">T61</mat-option>
              <mat-option value="quote">T74</mat-option>
              <mat-option value="arf">Standalone</mat-option>
            </mat-select>
            <button
              *ngIf="filters.get('types').value?.length"
              matSuffix
              mat-icon-button
              (click)="filters.get('types').setValue([]); $event.stopPropagation()"
            >
              <mat-icon class="gray">cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col text-right">
          <button mat-button color="primary" type="button" (click)="showFilters = false">Hide</button>
          <button color="primary" class="bg-white" type="button" mat-stroked-button (click)="handleResetFilters()">
            Clear All
          </button>
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="selection?.selected?.length" class="row mb-4 align-items-center">
    <div class="col-12">
      <p class="mb-2 dkgray">
        <span *ngIf="dataSource?.data?.length !== dataSource.filteredData?.length">
          Selected {{ selectedFilteredRows?.length }} of {{ dataSource.filteredData?.length }} filtered rows.
        </span>
        Selected {{ selection?.selected?.length }} of {{ dataSource?.data?.length }} total rows
      </p>
    </div>
    <div class="col-12">
      <button mat-flat-button color="primary" type="button">Mark As Paid</button>
    </div>
  </div>

  <div class="m-portlet bg-white mb-0">
    <div class="table-responsive">
      <table class="table mb-0" cdk-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? handleToggleAllFilteredRowSelections() : null"
              [checked]="selection?.hasValue() && isAllFilteredRowsSelected"
              [indeterminate]="selection?.hasValue() && !isAllFilteredRowsSelected"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let invoice">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(invoice) : null"
              [checked]="selection.isSelected(invoice)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="invoice">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Invoice
          </th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap" [class.text-danger]="!invoice.company_name">
            <p
              class="pointer hover-u m-0 p-0 text-left"
              (click)="handleInvoiceNumberClick(invoice.id, invoice.module_id, invoice.query_join_type)"
            >
              <span
                class="font-weight-semibold accent"
                [innerHTML]="invoice.company_name | highlight: filters.get('search').value"
              >
              </span>
              <br />
              <span class="accent" [innerHTML]="invoice.invoice_number | highlight: filters.get('search').value"></span>
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_datetime">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Created Date
          </th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            {{ invoice.created_datetime | date: 'EEE, MMM d YYYY h:mm aa' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fiscal_year">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>FY</th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            {{ invoice.fiscal_year }}
          </td>
        </ng-container>

        <ng-container matColumnDef="linked_item">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef>Linked Item</th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            <ng-container [ngSwitch]="invoice.query_join_type">
              <a
                class="accent"
                *ngSwitchCase="'arf'"
                [routerLink]="['/purchasing', 'arfs', invoice.project_id]"
                target="_blank"
                [innerHTML]="
                  invoice.project_code + ' ' + invoice.project_title | truncate | highlight: filters.get('search').value
                "
              >
              </a>
              <a
                class="accent"
                *ngSwitchDefault
                [routerLink]="['/projects/' + invoice.project_id] + '/invoices'"
                target="_blank"
                [innerHTML]="
                  invoice.project_code + ' ' + invoice.project_title | truncate | highlight: filters.get('search').value
                "
              >
              </a>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="module_name">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Workspace
          </th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            {{ invoice.module_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th class="border-right border-bottom border-gray" mat-header-cell *matHeaderCellDef mat-sort-header>
            Amount
          </th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            <span class="font-weight-bold">
              {{ invoice.payment_amount || 0 | currency }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="invoice_status_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="border-right border-bottom border-gray">
            Status
          </th>
          <td mat-cell *matCellDef="let invoice" [ngSwitch]="invoice?.invoice_status_id" class="text-nowrap">
            <span *ngSwitchCase="InvoiceStatus.New" class="px-3 py-1 lh-20 br-10 bg-frozen-landscape">
              {{ invoice.invoice_status_name }}
            </span>
            <span *ngSwitchCase="InvoiceStatus.Received" class="px-3 py-1 lh-20 br-10 bg-shade-ltblue primary">
              {{ invoice.invoice_status_name }}
            </span>
            <span *ngSwitchCase="InvoiceStatus.Approved" class="px-3 py-1 lh-20 br-10 bg-shade-green dkgreen">
              {{ invoice.invoice_status_name }}
            </span>
            <span *ngSwitchCase="InvoiceStatus.ReadyForPayment" class="px-3 py-1 lh-20 br-10 bg-green">
              {{ invoice.invoice_status_name }}
            </span>
            <span *ngSwitchCase="InvoiceStatus.Rejected" class="px-3 py-1 lh-20 br-10 bg-shade-red red">
              {{ invoice.invoice_status_name }}
            </span>
            <span *ngSwitchDefault class="px-3 py-1 lh-20 br-10">{{ invoice.invoice_status_name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="query_join_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="query_join_type">Type</th>
          <td mat-cell *matCellDef="let invoice" class="text-nowrap">
            <ng-container [ngSwitch]="invoice.query_join_type">
              <ng-container *ngSwitchCase="'bid'">T61</ng-container>
              <ng-container *ngSwitchCase="'quote'">T74</ng-container>
              <ng-container *ngSwitchCase="'arf'">ARF</ng-container>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td *ngIf="dataSource?.data?.length" class="mat-cell" colspan="9" class="text-center">
            <ng-container *ngIf="filtersAreActive">
              <h5 class="dkblue lh-24">Well, this is awkward... Your search didn't yield any results.</h5>
              <p class="dkgray lh-24">Perhaps broaden your search, or double-check your spelling.</p>
              <button mat-stroked-button color="accent" class="mt-2 bg-white" (click)="handleResetFilters()">
                Clear Filters & Search
              </button>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <mat-paginator class="bg-transparent" pageSize="25" aria-label="Select page of users"></mat-paginator>
</div>
