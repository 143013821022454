import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import PerfectScrollbar from 'perfect-scrollbar';
import { DispatchType, ResourceType, TopicType, UserType } from 'src/app/enums';
import {
  DispatchService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  RequestService,
  WorkOrderService,
} from 'src/app/services';
import { DispatchItem, RequestMethod, RequestReceiptDialogInjectionData, UhatFileReference, User } from 'src/app/types';
import { CustomValidator } from 'src/app/utils';
import {
  AddFollowersComponent,
  ConfirmationDialogComponent,
  DispatchTopicComponent,
  EditorComponent,
  FileAttachmentDialogComponent,
  LocationComponent,
  SearchUserInputComponent,
} from '../';
import { RequestMethodComponent } from '../request-method/request-method.component';

const DEFAULT_STATUS = { id: 1, name: 'Active' };
const NOT_SET_PRIORITY = { id: null, name: 'Not Set', Abbreviation: 'None' };
const SHORT_DESCRIPTION_MAX = 75;
@Component({
  selector: 'app-dispatch-request',
  templateUrl: './dispatch-request.component.html',
  styleUrls: ['./dispatch-request.component.scss'],
})
export class DispatchRequestComponent implements OnInit {
  @Input() openDrawer: boolean;
  @Output() closeItem = new EventEmitter();
  @Output() refreshDispatch = new EventEmitter();
  @Output() refreshEditItem = new EventEmitter(true);
  @ViewChild('assigned_user', { static: true })
  private _assigned_user_component: SearchUserInputComponent;
  @ViewChild('commentEditor', { static: true })
  private _comment_editor_component: EditorComponent;
  @ViewChild('contacts', { static: true })
  private _contacts_component: AddFollowersComponent;
  @ViewChild('dispatchTopic', { static: true })
  private _dispatch_topic_component: DispatchTopicComponent;
  @ViewChild('editor', { static: true })
  private _editor_component: EditorComponent;
  @ViewChild('locations', { static: true })
  private _location_component: LocationComponent;
  @ViewChild('requested_by', { static: true })
  private _requested_by_component: SearchUserInputComponent;
  @ViewChild('requestMethod', { static: true })
  public _requestMethodComponent: RequestMethodComponent;
  @ViewChild('workOrderInitialUpdateEditor', { static: true })
  private _work_order_initial_update_editor_component: EditorComponent;

  private _filesToUnlink: UhatFileReference[] = [];
  public assignToPlaceholder = 'Assignee';
  public attachedFiles: File[] = [];
  public buildingIds: number[];
  public dispatchFormGroup: FormGroup = this._initialFormFields();
  public dispatchItem: DispatchItem = null;
  public existingFiles: UhatFileReference[] = [];
  public followersDisplayTitle = 'Contacts (Followers)';
  public isLoading = false;
  public newFiles: UhatFileReference[] = [];
  public notVendors = [UserType.Staff, UserType.Tenant];
  public priorities = [
    NOT_SET_PRIORITY,
    { id: 1, name: 'Low', Abbreviation: 'Low' },
    { id: 2, name: 'Medium', Abbreviation: 'Med' },
    { id: 3, name: 'High', Abbreviation: 'High' },
    { id: 4, name: 'Critical', Abbreviation: 'Crit' },
  ];
  public requestorPlaceholder = 'Search People';
  public showWorkOrderInitialUpdateEditor = false;
  public short_description_length = SHORT_DESCRIPTION_MAX;
  public staffOnly = [UserType.Staff];
  public statusTypes = [DEFAULT_STATUS, { id: 2, name: 'On Hold' }, { id: 3, name: 'Closed' }];
  public initialAssignedUserId = null;
  public initialRequestingUserId = null;
  public readonly UserType = UserType;

  constructor(
    private _dialog: MatDialog,
    private _dispatchService: DispatchService,
    private _fb: FormBuilder,
    private _fileService: FileService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _requestService: RequestService,
    private _snackbar: MatSnackBar,
    private _workOrderService: WorkOrderService,
    private _modalService: ModalService
  ) {}

  async ngOnInit() {
    // adds the scrollbar to the top section
    const requestSection = document.querySelector('#item-form');
    const perfectScrollbar = new PerfectScrollbar(requestSection);
    perfectScrollbar.update();
    await this._activateAssignedUserField();
    await this._activateCommentEditor();
    await this._activateContactField();
    await this._activateLocationFields();
    await this._activateRequestedByField();
    await this._activateWorkOrderInitialUpdateEditor();

    // add request method
    this.dispatchFormGroup.addControl('request_method', this._requestMethodComponent?.requestMethod);
    await this._activateTopicFields();
    this.topic_groups.valueChanges.subscribe(() => {
      if (this.topic_groups?.value?.building_ids) {
        this.buildingIds = JSON.parse(this.topic_groups?.value?.building_ids);
      } else {
        this.buildingIds = null;
      }
      if (!this.buildingIds || this.buildingIds?.indexOf(this.building?.value?.id) === -1) {
        this._location_component.populateLocation(this.dispatchItem);
      }
    });
    // dispatch need this subscription for all data to load before populating the request method
    this._requestMethodComponent.ready.subscribe(async () => await this._populateSelectedRequestMethod());

    // only do this for not items, not edits
    this.requested_by.valueChanges.subscribe((user: User) => {
      // handle auto fill in for location if not editing
      if (!this.dispatchItem?.id && user?.id) {
        // get building, floor, suite, department
        this._location_component.populateLocation({ ...user });
      } // we can clear the location fields in an else if(!user?.id), but choosing another requestor will hable the location
    });
  }

  get assigned_user(): AbstractControl {
    return this.dispatchFormGroup.get('assigned_user');
  }

  get assignedUserAndTitleData() {
    return {
      assigned_user_id: this.assigned_user?.value?.id || null,
      title: this.short_description?.value || '',
    };
  }

  get building(): AbstractControl {
    return this.location?.get('building');
  }

  get comments(): AbstractControl {
    return this.dispatchFormGroup.get('comments');
  }

  get common_data() {
    return {
      building_id: this.building?.value?.id || null,
      department_id: this.department?.value?.id || null,
      floor_id: this.floor?.value?.id || null,
      requester_id: this.requested_by?.value?.id || null,
      request_method_id: this.requestMethod?.value?.id || null,
      suite_id: this.suite?.value?.id || null,
      summary: this.comments?.value || '',
      topic_id: this.topics?.value?.id || null,
      landmark: this.landmark?.value || null,
    };
  }

  get contacts(): AbstractControl {
    return this.dispatchFormGroup.get('contacts');
  }

  get conversionText(): string {
    if (!this.requested_by?.value?.id) {
      return 'Search For A  Requestor To Convert';
    }
    if (!this.topic_type_id) {
      return 'Select A Topic To Convert';
    }

    if (!this.building?.value?.id) {
      return 'Select A Building To Convert';
    }

    if (!this.floor?.value?.id) {
      return 'Select A Floor To Convert';
    }

    if (!this.short_description?.value?.trim()) {
      return 'Enter a Description To Convert';
    }

    return `Convert Draft To A ${+this.topic_type_id === +DispatchType.ProjectRequest ? 'Request' : ''} ${
      +this.topic_type_id === +DispatchType.WorkOrder ? 'Work Order' : ''
    }`;
  }

  get department(): AbstractControl {
    return this.location?.get('department');
  }

  get landmark(): AbstractControl {
    return this.location?.get('landmark');
  }
  get disableConversion(): boolean {
    return (
      !this.requested_by?.value?.id ||
      !this.topic_type_id ||
      !this.building?.value?.id ||
      !this.floor?.value?.id ||
      !this.short_description?.value?.trim() ||
      !this.dispatchFormGroup.valid
    );
  }

  get draftSpecificData() {
    return {
      selected_category_id: this.topic_categories?.value?.id || null,
      selected_topic_group_id: this.topic_groups?.value?.id || null,
      priority_id: this.priority?.value?.id || null,
      status_id: this.status?.value?.id || 1,
      module_id: this.workspace?.value?.id || null,
      contact_ids: JSON.stringify(this.contacts?.value?.map((contact: User) => contact.id) || []),
    };
  }

  get floor(): AbstractControl {
    return this.location?.get('floor');
  }

  get isClosedWorkOrder(): boolean {
    return +this.dispatchItem?.type === +DispatchType.ClosedWorkOrder;
  }

  get isDraft(): boolean {
    return !this.dispatchItem || +this.dispatchItem?.type === +DispatchType.Draft;
  }

  get isEditing(): boolean {
    return !!this.dispatchItem?.id || false;
  }

  get isProjectRequest(): boolean {
    return +this.dispatchItem?.type === +DispatchType.ProjectRequest;
  }

  get isWorkOrder(): boolean {
    return +this.dispatchItem?.type === +DispatchType.WorkOrder;
  }

  get isWorkOrderTopic(): boolean {
    if (+this.dispatchItem?.type === +DispatchType.WorkOrder) {
      return true;
    }

    if (+this.dispatchItem?.type === +DispatchType.ProjectRequest) {
      return false;
    }
    return +this.topic_type_id === +TopicType.WorkOrder;
  }

  get location(): AbstractControl {
    return this.dispatchFormGroup.get('location');
  }

  get missing_a_required_field(): string {
    if (!this.requested_by?.value?.id) {
      return '*A requestor is needed for a conversion';
    }
    if (!this.topic_type_id) {
      return '*A topic is needed for a conversion';
    }

    if (!this.building.value) {
      return '*A building is needed for a conversion';
    }

    if (!this.floor.value) {
      return '*A floor is needed for a conversion';
    }
    return '';
  }

  get parentResourceType(): number {
    switch (this.dispatchItem?.type) {
      case +TopicType.Draft:
        return ResourceType.DispatchDraft;
      case +TopicType.Request:
        return ResourceType.Request;
      case +TopicType.WorkOrder:
        return ResourceType.WorkOrder;

      default:
        return null;
    }
  }

  get priority(): AbstractControl {
    return this.dispatchFormGroup.get('priority');
  }

  get priorityAbbreviation(): number {
    return this.priority?.value?.abbreviation;
  }

  get priorityId(): number {
    return this.priority?.value?.id;
  }

  get priorityName(): string {
    return this.priority?.value?.name || '';
  }

  get request_data() {
    return {
      ...this.request_draft_data,
      short_description: this.short_description?.value || '',
      module_id: this.workspace?.value?.id || null,
    };
  }

  get requested_by(): AbstractControl {
    return this.dispatchFormGroup.get('requested_by');
  }

  get request_code(): string {
    return this.dispatchItem?.code || '';
  }

  get request_draft_data() {
    return {
      ...this.common_data,
      contact_ids: JSON.stringify(this.contacts?.value?.map((contact: User) => contact.id) || []),
    };
  }

  get requestMethod(): AbstractControl {
    return this.dispatchFormGroup.get('request_method');
  }

  get saveDraftText(): string {
    if (!this.requested_by?.value?.id) {
      return `Search for a requestor`;
    }

    if (!this.short_description?.value) {
      return `Enter a description`;
    }

    return this.isEditing ? 'Update Draft' : 'Save As Draft';
  }

  get saveText(): string {
    if (this.parentResourceType === ResourceType.Project) {
      return 'Update Project Request';
    }

    if (this.parentResourceType === ResourceType.WorkOrder) {
      return 'Update Work Order';
    }

    if (!this.requested_by?.value?.id) {
      return `Search for a requestor to ${this.isEditing ? 'update' : 'Create'} Item`;
    }

    if (!this.topic_type_id) {
      return `Select topic to ${this.isEditing ? 'update' : 'Create'} Item`;
    }

    if (!this.building?.value?.id) {
      return `Select building to ${this.isEditing ? 'update' : 'Create'} Item`;
    }

    if (!this.floor?.value?.id) {
      return `Select floor to ${this.isEditing ? 'update' : 'Create'} Item`;
    }

    if (!this.short_description?.value) {
      return `Enter a short description to ${this.isEditing ? 'update' : 'Create'} Item`;
    }

    return `${this.isEditing ? 'update' : 'save'} ${
      +this.topic_type_id === +TopicType.Request ? 'project request' : 'work order'
    }`;
  }

  get short_description(): AbstractControl {
    return this.dispatchFormGroup.get('short_description');
  }

  get short_description_counter(): string {
    return `${this.short_description?.value?.length || 0} / ${this.short_description_length}`;
  }

  get status(): AbstractControl {
    return this.dispatchFormGroup.get('status');
  }

  get statusId(): number {
    return this.status?.value?.id;
  }

  get statusName(): string {
    return this.status?.value?.name || '';
  }

  get suite(): AbstractControl {
    return this.location?.get('suite');
  }

  get topic(): AbstractControl {
    return this.dispatchFormGroup?.get('topic');
  }

  get topic_categories(): AbstractControl {
    return this.topic?.get('topic_categories');
  }

  get topic_groups(): AbstractControl {
    return this.topic?.get('topic_groups');
  }

  get topics(): AbstractControl {
    return this.topic?.get('topics');
  }

  get topic_name() {
    if (this.isDraft) {
      return 'Draft';
    }

    if (this.isProjectRequest) {
      return 'Request';
    }

    if (this.isWorkOrder) {
      return 'Work Order';
    }
  }

  get topic_type_id(): number {
    return this.topics?.value?.topic_type_id;
  }

  get valid_draft_submission(): boolean {
    const data =
      +this.topic_type_id === +DispatchType.ProjectRequest ? this.request_draft_data : this.work_order_draft_data;
    const draftData = { ...(this.draftSpecificData || {}), ...(this.assignedUserAndTitleData || {}), ...(data || {}) };
    let validFields = 0;
    for (const key in draftData) {
      if (draftData.hasOwnProperty(key)) {
        if (key === 'contact_ids') {
          validFields += !this.contacts?.value?.length ? 0 : 1;
        } else {
          validFields += !draftData[key] ? 0 : 1;
        }
      }
    }

    validFields += !this.attachedFiles?.length ? 0 : 1;

    return validFields > 2;
  }

  get valid_submission(): boolean {
    return this.dispatchFormGroup.valid;
  }

  get viewItemText(): string {
    if (+this.dispatchItem?.type === +DispatchType.ProjectRequest) {
      return 'Open Request';
    }

    if (+this.dispatchItem?.type === +DispatchType.WorkOrder) {
      return 'Open Work Order';
    }

    if (+this.dispatchItem?.type === +DispatchType.ClosedWorkOrder) {
      return 'Open Closed Work Order';
    }

    // For some reason, there is no type
    return 'View Item';
  }

  get work_order_data() {
    const data = {
      ...this.work_order_draft_data,
      follower_ids: JSON.stringify(this.contacts?.value?.map((contact: User) => contact.id) || []),
      module_id: this.workspace?.value?.id || null,
      priority_id: this.priority?.value?.id ?? null,
      status_id: this.status?.value?.id || 1,
    };
    return data;
  }

  get work_order_draft_data() {
    return {
      ...this.common_data,
      initial_update: this.workOrderInitialUpdate?.value || null,
      assigned_user_id: this.assigned_user?.value?.id || null,
    };
  }

  get workOrderInitialUpdate(): AbstractControl {
    return this.dispatchFormGroup.get('work_order_initial_update');
  }

  get workspace(): AbstractControl {
    return this.topic?.get('workspace');
  }

  private async _activateAssignedUserField() {
    this.dispatchFormGroup.addControl('assigned_user', this._assigned_user_component.searchEntry);
  }

  private async _activateCommentEditor() {
    this.dispatchFormGroup.addControl('comments', this._comment_editor_component.content);
  }

  private async _activateContactField() {
    this.dispatchFormGroup.addControl('contacts', this._contacts_component.followers);
  }

  private async _activateLocationFields() {
    this.dispatchFormGroup.addControl('location', this._location_component.locationGroup);
  }

  private async _activateRequestedByField() {
    this.dispatchFormGroup.addControl('requested_by', this._requested_by_component.searchEntry);
    this.requested_by.setValidators([Validators.required, CustomValidator.userId]);
    this.requested_by.updateValueAndValidity();
  }

  private async _activateTopicFields() {
    this.dispatchFormGroup.addControl('topic', this._dispatch_topic_component.topicGroup);
  }

  private async _activateWorkOrderInitialUpdateEditor() {
    this.dispatchFormGroup.addControl(
      'work_order_initial_update',
      this._work_order_initial_update_editor_component.content
    );
  }

  private _addFile(file: UhatFileReference) {
    // Take out this file from files to unlink, no need to unlink,
    // No need to unlink because you now want it linked
    // Add it to existing files
    if (this._filesToUnlink?.find((unlinkFile: UhatFileReference) => unlinkFile?.id === file?.id)) {
      this._filesToUnlink = this._filesToUnlink?.filter((unlinkFile) => unlinkFile?.id !== file?.id);
      this.existingFiles = [...this.existingFiles, file];
    } else {
      this.newFiles = [...this.newFiles, { id: file?.file_id, name: file?.name }];
    }
  }

  public _clear() {
    this.dispatchItem = null;
    this.dispatchFormGroup.reset();
    this._populateSelectedRequestMethod(); /// clears the default, so we need to repopulate
    this._resetFiles();
  }

  private async _closeProgress() {
    this._progressIndicatorService.close();
    this.isLoading = false;
  }

  private async _create() {
    // stuff you want to go into project request
    const projectRequestData = {
      ...this.request_data,
    };

    // stuff you want to  go into work order
    const workOrderData = {
      ...this.work_order_data,
      ...this.assignedUserAndTitleData,
    };

    // since initial_update is inserted in with draft, we can remove it to the data passed to the wo
    // and make a seperate update, if it has a value
    delete workOrderData.initial_update;
    const result =
      +this.topic_type_id === +DispatchType.ProjectRequest
        ? await this._requestService.createRequest(projectRequestData).toPromise()
        : await this._workOrderService.createWorkOrder(workOrderData).toPromise();

    if (result?.id && this.work_order_data?.initial_update) {
      await this._openProgress(`Creating A Work Order Update`);
      await this._workOrderService
        .createWorkOrderUpdate({
          message: this.work_order_data.initial_update,
          notify_followers: 1,
          work_order_health_type_id: null,
          work_order_id: result.id,
        })
        .toPromise();
    }

    if (result && this.attachedFiles) {
      this.attachedFiles.forEach((file) => {
        this._fileService
          .createFile(
            file,
            result.id,
            +this.topic_type_id === +DispatchType.ProjectRequest ? ResourceType.Request : ResourceType.WorkOrder
          )
          .subscribe();
      });
    }

    return result;
  }

  private async _createDraft() {
    const data =
      +this.topic_type_id === +DispatchType.ProjectRequest ? this.request_draft_data : this.work_order_draft_data;

    const result = await this._dispatchService
      .createDispatchDraft({
        ...data,
        ...this.draftSpecificData,
        ...this.assignedUserAndTitleData,
      })
      .toPromise();

    if (result && this.attachedFiles) {
      this.attachedFiles.forEach((file) => {
        this._fileService.createFile(file, result.id, ResourceType.DispatchDraft).subscribe();
      });
    }

    return result;
  }

  private async _handleFiles() {
    // link files
    this.newFiles.forEach((file: UhatFileReference) => this._linkFile(file?.id));

    // files to unlink
    this._filesToUnlink.forEach((file: UhatFileReference) => this._unlinkFile(file));
  }

  private async _getFiles() {
    this.existingFiles = await this._fileService
      .getFilesByParentId(this.parentResourceType, this.dispatchItem?.id)
      .toPromise();
  }

  private _initialFormFields() {
    return this._fb.group({
      priority: [NOT_SET_PRIORITY],
      short_description: [null, [Validators.required, Validators.maxLength(SHORT_DESCRIPTION_MAX)]], // title
      status: [DEFAULT_STATUS],
    });
  }

  private async _linkFile(fileId: number) {
    await this._fileService.linkFile(fileId, this.dispatchItem.id, this.parentResourceType).toPromise();
  }

  private async _openProgress(action: string) {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  private async _populateAssignedUser() {
    if (this.dispatchItem?.assigned_user) {
      this.assigned_user.setValue(this.dispatchItem?.assigned_user);
      this.initialAssignedUserId = this.dispatchItem?.assigned_user_id;
    }
  }

  private async _populateContacts() {
    // If I don't include the parse int,
    // the current followers are not selected when editing
    if (this.dispatchItem?.contacts) {
      this.contacts.setValue(
        this.dispatchItem?.contacts?.map((contact) => {
          return { ...contact, id: parseInt(`${contact.id}`, 10) };
        })
      );
    }
  }

  private async _populateRequestedBy() {
    if (this.dispatchItem?.requester) {
      this.requested_by.setValue(this.dispatchItem?.requester);
      this.initialRequestingUserId = this.dispatchItem?.requester?.id;
    }
  }

  private async _populateSelectedRequestMethod() {
    // filter disabled except our id
    this._requestMethodComponent.shownRequestMethods = this._requestMethodComponent.requestMethods.filter(
      (requestMethod: RequestMethod) =>
        requestMethod.is_enabled ||
        (+this.dispatchItem?.request_method_id && +requestMethod?.id === +this.dispatchItem?.request_method_id)
    );

    if (this.dispatchItem?.request_method_id) {
      const foundRequestMethod = this._requestMethodComponent.shownRequestMethods.find(
        (requestMethod: RequestMethod) => +requestMethod.id === +this.dispatchItem.request_method_id
      );
      this.requestMethod.setValue(foundRequestMethod);
    } else {
      this.requestMethod.setValue(this._requestMethodComponent?.defaultRequestMethod);
    }
  }

  private _resetFiles() {
    this.existingFiles = [];
    this.newFiles = [];
    this.attachedFiles = [];
    this._filesToUnlink = [];
  }

  private async _unlinkFile(file: UhatFileReference) {
    await this._fileService.unlinkFile(file?.id).toPromise();
  }

  private async _update() {
    await this._handleFiles();
    // stuff you want to go into project request
    const projectRequestData = {
      ...this.request_data,
    };

    // stuff you want to  go into work order
    const workOrderData = {
      ...this.work_order_data,
      ...this.assignedUserAndTitleData,
    };

    // remove initial update
    delete workOrderData.initial_update;
    const result =
      +this.dispatchItem.type === +DispatchType.ProjectRequest
        ? await this._requestService.updateRequest(this.dispatchItem.id, projectRequestData).toPromise()
        : await this._workOrderService.updateWorkOrder(this.dispatchItem.id, workOrderData).toPromise();

    return result;
  }

  private async _updateDraft() {
    const data =
      +this.topic_type_id === +DispatchType.ProjectRequest ? this.request_draft_data : this.work_order_draft_data;

    const result = await this._dispatchService
      .updateDispatchDraft(this.dispatchItem.id, {
        ...data,
        ...this.draftSpecificData,
        ...this.assignedUserAndTitleData,
      })
      .toPromise();
    return result;
  }

  public clear() {
    // we only want to prompt, if something has changed
    if (!this.dispatchFormGroup.pristine) {
      this._dialog
        .open(ConfirmationDialogComponent, {
          data: {
            titleBarText: 'Clear the form',
            descriptionText: 'Warning: Are you sure you want to clear the form?',
          },
        })
        .afterClosed()
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            this._clear();
          }
        });
    }
  }

  public clearComments() {
    this.comments.reset();
  }

  public clearEdit() {
    // we only want to prompt, if something has changed
    if (!this.dispatchFormGroup.pristine && this.openDrawer) {
      this._dialog
        .open(ConfirmationDialogComponent, {
          data: {
            titleBarText: 'Clear Edits',
            descriptionText: 'Warning: Are you sure you want to clear edits?',
          },
        })
        .afterClosed()
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            this._clear();
          }
        });
    } else {
      this._clear();
    }
  }

  public clearShortDescription() {
    this.short_description.reset();
  }

  public close() {
    this.closeItem.emit();
    if (this.isEditing) {
      this._clear();
    }
  }

  public async convert() {
    if (this.valid_submission) {
      let itemId: number;
      await this._openProgress(`Updating ${this.topic_name}`);

      if (this.isDraft) {
        await this._updateDraft();
        await this._openProgress(
          `Converting ${this.topic_type_id === TopicType.Request ? 'to Request' : ''} ${
            +this.topic_type_id === +TopicType.WorkOrder ? 'to Work Order' : ''
          }`
        );
        itemId = await this._dispatchService.convertDraft(this.dispatchItem.id).toPromise();

        // create an update if its a workorder draft and has an update value
        if (+this.topic_type_id === +TopicType.WorkOrder && this.workOrderInitialUpdate?.value) {
          await this._openProgress(`Creating Update To Work Order Draft`);
          await this._workOrderService
            .createWorkOrderUpdate({
              message: this.workOrderInitialUpdate.value,
              notify_followers: 1,
              work_order_health_type_id: null,
              work_order_id: itemId,
            })
            .toPromise();
        }
      } else if (this.isProjectRequest) {
        // if project request but needs to convert to WO
        if (+this.topic_type_id === +TopicType.WorkOrder) {
          await this._update();
          await this._openProgress(`Converting Request to Work Order`);
          itemId = await this._requestService.convertRequestToWorkOrder(this.dispatchItem.id).toPromise();
        }
      } else if (this.isWorkOrder) {
        // if work order but need to convert to project request
        if (+this.topic_type_id === +TopicType.Request) {
          await this._update();
          await this._openProgress(`Converting Work Order to Request`);
          itemId = await this._workOrderService.convertWorkOrderToRequest(this.dispatchItem.id).toPromise();
        }
      }

      if (itemId) {
        const receiptResponse = await this.openReceiptDialog({ id: itemId });
        if (!receiptResponse.reroute) {
          this.refreshEditItem.emit({
            itemId,
            topic_type: this.topic_type_id,
            isNewRequest: receiptResponse.newRequest,
          });
          if (receiptResponse.newRequest) {
            this._clear();
            const dispatchRequestElement = document.getElementById('item-form');
            dispatchRequestElement.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            this.closeItem.emit();
          }

          await this._closeProgress();
        }
      }
    } else {
      this._snackbar.open('Enter all the required fields!!');
    }
  }

  public async delete() {
    if (this.dispatchItem.id) {
      this._modalService
        .openConfirmationDialog({
          titleBarText: 'Warning: Delete',
          descriptionText: 'You are about to permanently delete this item. Are you sure you want to continue?',
        })
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            this._openProgress('Deleting item...');

            // unlink files
            this.existingFiles.forEach((file: UhatFileReference) => this._unlinkFile(file));

            const toDeleteId = this.dispatchItem.id;
            this._clear();
            this.close();

            // delete item
            await this._dispatchService.deleteDispatchDraft(toDeleteId).toPromise();

            this._closeProgress();

            this.refreshDispatch.emit();
          }
        });
    } else {
      this._snackbar.open('Failed to delete item!!');
    }
  }

  public async editItem(item: DispatchItem) {
    this._clear();
    this.dispatchItem = item;
    await this.populateForm();
  }

  public openUploadModal() {
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: this.dispatchItem?.id ? this.parentResourceType : null,
          parentResourceId: this.dispatchItem?.id,
          preSelectedTags: [],
          allowComment: false,
          draftFile: this.isDraft,
          workOrderFile: this.isWorkOrder,
          skipUpload: !this.dispatchItem?.id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((files: any) => {
        if (files) {
          if (this.dispatchItem?.id) {
            for (const file of files || []) {
              this._addFile(file);
            }
          } else {
            this.attachedFiles = [...this.attachedFiles, ...files.computerFiles];
          }
          const message = files.length > 1 ? 'Files attached!' : 'File attached!';
          this._snackbar.open(message);
        }
      });
  }

  public async populateForm() {
    if (this.dispatchItem) {
      this._openProgress('Loading...');
      // requester
      this._populateRequestedBy();

      // request method
      this._populateSelectedRequestMethod();

      // Topic
      if (this.dispatchItem?.topic_id) {
        // we send the whole item, so we can detect if the topic type changes
        await this._dispatch_topic_component.populateTopicFields(this.dispatchItem);
      }

      // Location
      await this._location_component.populateLocation(this.dispatchItem);

      // Assigned user
      await this._populateAssignedUser();

      // Contacts
      await this._populateContacts();

      // Files
      await this._getFiles();

      // the rest of the form
      this.short_description.setValue(this.dispatchItem?.title);
      this.comments.setValue(this.dispatchItem?.summary);

      if (this.dispatchItem.initial_update) {
        this.showWorkOrderInitialUpdateEditor = true;
        this.workOrderInitialUpdate.setValue(this.dispatchItem.initial_update);
      }

      if (this.dispatchItem?.priority) {
        this.priority.setValue(this.priorities.find((priority) => priority.id === this.dispatchItem?.priority?.id));
      } else {
        this.priority.setValue(NOT_SET_PRIORITY);
      }
      if (this.dispatchItem?.status) {
        this.status.setValue(this.statusTypes.find((statusType) => statusType.id === this.dispatchItem?.status?.id));
      } else {
        this.status.setValue(DEFAULT_STATUS);
      }
    }
    this._closeProgress();
  }

  public removeAttachedFile(file: File) {
    this.attachedFiles = this.attachedFiles.filter((attachedFile: File) => attachedFile !== file);
  }

  public removeExistingFile(file: UhatFileReference) {
    // remove from existing files
    this.existingFiles = this.existingFiles?.filter((existingFile: UhatFileReference) => existingFile?.id !== file?.id);

    // add it to files to unlink
    this._filesToUnlink = [...this._filesToUnlink, file];
  }

  public removeNewFile(file: UhatFileReference) {
    this.newFiles = this.newFiles.filter((f: UhatFileReference) => f.id !== file.id);
  }

  public async save(ready = false) {
    if (!ready && !this.valid_draft_submission) {
      return this._snackbar.open('A short description or comments is required to make a draft!!');
    }

    if (ready && !this.dispatchFormGroup.valid) {
      return this._snackbar.open('Please enter all the required fields!!!');
    }
    const action = `${this.isEditing ? 'Editing' : 'Saving'} ${
      +this.topic_type_id === +DispatchType.ProjectRequest ? 'project' : 'work order'
    } ${ready ? '...' : 'draft...'}`;
    this._openProgress(action);

    let receiptResponse;
    let result: any;
    if (ready) {
      result = this.isEditing ? await this._update() : await this._create();
      receiptResponse = await this.openReceiptDialog(result);
    } else {
      result = this.isEditing ? await this._updateDraft() : await this._createDraft();
    }

    this._closeProgress();
    // if success
    if (result?.id) {
      this._clear();
      if (!receiptResponse?.newRequest) {
        this.close();
      } else {
        const dispatchRequestElement = document.getElementById('item-form');
        dispatchRequestElement.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.refreshDispatch.emit();
    }
  }

  private async openReceiptDialog(result: any) {
    const receiptData: RequestReceiptDialogInjectionData = {
      contacts: result.contact_ids && JSON.parse(result.contact_ids),
      files: this.attachedFiles,
    };

    if (+this.topic_type_id === +DispatchType.ProjectRequest) {
      receiptData.request = result;
    } else {
      receiptData.workOrder = result;
    }

    return await this._modalService.openRequestReceiptDialog(receiptData).toPromise();
  }

  public statusLabel(status) {
    if (status?.id === 1) {
      return 'label_important';
    }
    if (status?.id === 2) {
      return 'flag';
    }
    if (status?.id === 3) {
      return 'done_all';
    }
  }

  public viewItem() {
    if (this.dispatchItem?.type === DispatchType.ProjectRequest) {
      window.open(`/requests/${this.dispatchItem?.id || ''}`);
    } else if (
      this.dispatchItem?.type === DispatchType.WorkOrder ||
      this.dispatchItem?.type === DispatchType.ClosedWorkOrder
    ) {
      window.open(`/work-orders/${this.dispatchItem?.id || ''}`);
    }
  }

  public scrollToTop() {
    const dispatchRequestElement = document.getElementById('item-form');
    dispatchRequestElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
