import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-user-select-dropdown',
  templateUrl: './user-select-dropdown.component.html',
  styleUrls: ['./user-select-dropdown.component.scss'],
})
export class UserSelectDropdownComponent implements OnInit, OnChanges {
  @Input() public assignedUsers: User[];
  @Output() public addUserIdTasksFilter = new EventEmitter();
  @Output() public removeUserIdTasksFilter = new EventEmitter();

  public selectedUser: FormControl = new FormControl(undefined);

  constructor(private _authService: AuthService) {}

  ngOnInit() {
    this._subscribeToSelection();
  }

  ngOnChanges(change) {
    if (change?.assignedUsers?.currentValue?.length && !change?.assignedUsers?.firstChange) {
      const user_filter_id_array = JSON.parse(localStorage.getItem('preferences'))?.filter_task_by_user;
      const foundUser = this.assignedUsers?.find(
        (assignedUser: User) => user_filter_id_array?.length && +assignedUser.id === user_filter_id_array[0]
      );

      if (foundUser) {
        // set the values using the preferences
        this.selectedUser.setValue(foundUser);
      }
    }
  }

  public get assignedToMe(): User {
    // if currently logged user has assigned tasks
    return this.assignedUsers?.find((user: User) => +user?.id === +this._authService?.currentUser?.id);
  }

  public get filteredAssignedUsers(): User[] {
    return this.assignedUsers?.filter((user: User) => +user?.id !== +this._authService?.currentUser?.id);
  }

  get t() {
    return this.assignedUsers;
  }
  private _subscribeToSelection() {
    this.selectedUser.valueChanges.subscribe((user: User) => {
      if (!user) {
        this.removeUserIdTasksFilter.emit();
      } else {
        this.addUserIdTasksFilter.emit(user.id);
      }
    });
  }
}
