<div
  id="timer"
  data-test-id="timer"
  class="timer border br-10 pt-3 pb-3 px-4 d-flex flex-column"
  [ngClass]="{
    'blue-timer border-accent': status === statusType.ABOUT_TO_START || status === statusType.MEETING,
    'red-timer border-red': status === statusType.ABOUT_TO_END || status === statusType.ENDED,
    invisible: !isValidDuration || status === statusType.NOT_SET
  }"
>
  <div class="d-flex flex-column">
    <div>{{ title }}</div>
    <h2
      class="m-0 font-weight-bold"
      [ngClass]="{
        'text-primary': status === statusType.ABOUT_TO_START,
        'text-danger': status === statusType.ABOUT_TO_END || status === statusType.ENDED,
        blink: shouldFlash
      }"
    >
      {{ remaining | date: 'HH:mm:ss':'UTC' }}
    </h2>
    <mat-progress-bar
      [color]="
        status === statusType.ABOUT_TO_START || status === statusType.MEETING
          ? 'accent'
          : status === statusType.ABOUT_TO_END || status === statusType.ENDED
          ? 'warn'
          : 'gray'
      "
      mode="determinate"
      [value]="percentage"
    ></mat-progress-bar>
  </div>
</div>
