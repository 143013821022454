<div
  id="meeting-agenda-item-note"
  data-test-id="meeting-agenda-item-note"
  class="meeting-agenda-item-note full-width d-flex flex-column"
  [ngClass]="{ 'pl-4': newNote }"
>
  <div class="d-flex flex-column full-width textarea" [ngClass]="{ 'pl-4': newNote }" *ngIf="newNote || editNote">
    <!-- NEW NOTE -->
    <app-editor
      *ngIf="newNote"
      #agendaNoteEditor
      [id]="agendaItem"
      placeholder="What's being discussed?"
      [class_height]="'medium'"
      [auto_focus]="newNote"
      class="mt-3"
    ></app-editor>
    <!-- EDIT NOTE -->
    <app-editor
      *ngIf="editNote"
      #editAgendaNoteEditor
      [id]="agendaItem"
      placeholder="What's being discussed?"
      [class_height]="'medium'"
      [auto_focus]="editNote"
      class="mt-3"
    ></app-editor>
    <div class="d-flex align-items-center mt-3">
      <button (click)="openUploadModal()" mat-stroked-button color="accent" class="px-3 mr-4 bg-white">
        <mat-icon> attach_file </mat-icon>
        <span> Attach Files </span>
      </button>
      <mat-chip-list class="d-inline-block align-middle">
        <app-file-chip *ngFor="let file of files" [file]="file" [removable]="true" (removeEvent)="removeNoteFile(file)">
        </app-file-chip>
      </mat-chip-list>
    </div>
    <div class="d-flex align-items-center mt-3">
      <!--Save Button-->
      <button
        *ngIf="newNote"
        mat-flat-button
        (click)="addNoteToAgendaItem()"
        color="success"
        class="mr-3"
        [disabled]="isConcluded || (!draftNote && files.length === 0)"
      >
        Save Note
      </button>
      <!-- Edit Button-->
      <button *ngIf="editNote" mat-flat-button (click)="editAgendaItemNote()" color="success" class="mr-3">
        Save Changes
      </button>
      <button (click)="cancel()" mat-button class="gray" type="button">Cancel {{ newNote ? 'Draft' : 'Edit' }}</button>
    </div>
  </div>
</div>
