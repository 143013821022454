import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CheckboxContainerItem } from 'src/app/types';

@Component({
  selector: 'app-checkbox-container',
  templateUrl: './checkbox-container.component.html',
  styleUrls: ['./checkbox-container.component.scss'],
})
export class CheckboxContainerComponent implements OnInit {
  @Input() requireNotes: boolean;

  @Input() items: CheckboxContainerItem[] = [];

  @Input() disabled: boolean;

  @Output() itemsChange = new EventEmitter<CheckboxContainerItem[]>();

  @Input() noteField: string;

  @Output() noteFieldChange = new EventEmitter<string>();

  @Input() notesPlaceholder: string;

  constructor() {}

  ngOnInit() {}

  checkboxUpdated(checked: boolean, item: any) {
    const foundItem = this.items.find((e) => e === item);
    if (!foundItem) {
      console.error(`No Item Found In Array For ${foundItem}`);
      return;
    }

    foundItem.checked = checked;
    this.itemsChange.emit(this.items);
  }
}
