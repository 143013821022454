import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { DispatchRequestComponent } from 'src/app/components';
import { DispatchType, DISPATCH_FILTER_KEY, DISPATCH_LOCAL_STORAGE_KEY, WorkOrderStatus } from 'src/app/enums';
import { DispatchFilterPipe } from 'src/app/pipes';
import {
  AuthService,
  DispatchService,
  ModalService,
  NotificationService,
  ProgressIndicatorService,
  RequestService,
  SearchService,
  SidenavLinksService,
  SidenavService,
  SocketService,
  WorkOrderService,
} from 'src/app/services';
import { APIFilter, DispatchDraft, DispatchFilter, DispatchItem, Request, WorkOrder } from 'src/app/types';

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss'],
})
export class DispatchComponent implements OnInit, OnDestroy {
  @ViewChild(DispatchRequestComponent) private _dispatchRequest: DispatchRequestComponent;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('drawer') drawer: any;

  private _closedWorkOrders: WorkOrder[] = [];
  private _dispatchDraftFields = [
    'id',
    'module_id',
    'assigned_user',
    'assigned_user_id',
    'building{code,id,name}',
    'building_id',
    'code',
    'contacts{id,company_id,company_name,first_name,last_name,department_name,user_type_id}',
    'contact_ids',
    'created_datetime',
    'created_by',
    'department{id,name}',
    'department_id',
    'floor{building_id,code,id,name}',
    'floor_id',
    'initial_update',
    'landmark',
    'priority{id,name,abbreviation}',
    'priority_id',
    'requester{id,first_name,last_name,department_name,company_name}',
    'selected_category{id,name}',
    'selected_topic_group{id,name}',
    'status',
    'status_id',
    'suite{floor_id,id,name}',
    'suite_id',
    'summary',
    'title',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
    'topic_category{id,name,topic_group_id}',
    'topic_group{id,name}',
    'topic_id',
    'module{id,name}',
    'reassignment_activity{parent_id,parent_type_id,previous_workspace{id,name},current_workspace{id,name},comment,created_by_id,created_by{first_name,last_name},created_datetime}',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
  ];

  private _requestsFields = [
    'id',
    'building{code,id,name}',
    'building_id',
    'building_code',
    'building_id',
    'building_name',
    'code',
    'contacts{id,company_id,company_name,first_name,last_name,department_name,user_type_id}',
    'contact_ids',
    'created_datetime',
    'created_by_id',
    'created_by_first_name',
    'created_by_last_name',
    'department{id,name}',
    'department_id',
    'department_name',
    'floor{building_id,code,id,name}',
    'floor_code',
    'floor_id',
    'floor_name',
    'landmark',
    'requester{id,first_name,last_name,department_name,company_name}',
    'short_description',
    'status_id',
    'suite',
    'suite_id',
    'suite_name',
    'summary',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
    'topic_category{id,name,topic_group_id}',
    'topic_group{id,name}',
    'topic_id',
    'workspace{id,name}',
    'reassignment_activity{parent_id,parent_type_id,previous_workspace{id,name},current_workspace{id,name},comment,created_by_id,created_by{first_name,last_name},created_datetime}',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
  ];

  private _timeOptions = {
    none: 0,
    day: 1,
    week: 2,
    month: 3,
    all: 4,
  };
  private _lastApiCall = {
    dispatchDraft: this._timeOptions.none,
    request: this._timeOptions.none,
    workOrder: this._timeOptions.none,
  };
  private _workOrderFields = [
    'id',
    'assigned_user',
    'assigned_user_id',
    'building{code,id,name}',
    'building_id',
    'code',
    'created_datetime',
    'created_by',
    'department{id,name}',
    'department_id',
    'floor{building_id,code,id,name}',
    'floor_id',
    'landmark',
    'followers{id,company_id,company_name,first_name,last_name,department_name,user_type_id}',
    'follower_ids',
    'module{id,name}',
    'priority{id,name,abbreviation}',
    'priority_id',
    'requester{id,first_name,last_name,department_name,company_name}',
    'status{id,name}',
    'status_id',
    'suite{floor_id,id,name}',
    'suite_id',
    'summary',
    'title',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
    'topic_category{id,name,topic_group_id}',
    'topic_group{id,name}',
    'topic_id',
    'reassignment_activity{parent_id,parent_type_id,previous_workspace{id,name},current_workspace{id,name},comment,created_by_id,created_by{first_name,last_name},created_datetime}',
    'updates.sort(created_datetime).order(desc).limit(1){created_datetime}',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
  ];

  private _itemFilter = new DispatchFilterPipe();

  public asideOpen = false;
  public dispatchDrafts: DispatchDraft[] = [];
  public dispatchFormGroup = this._fb.group({});
  public divWidth: number;
  public endIndex: number;
  public filter: DispatchFilter = {};
  public filterKeys = DISPATCH_FILTER_KEY;
  public fieldToSortBy: string;
  public items: DispatchItem[] = [];
  public isLoading = false;
  public length = 100;
  public newRequestor: any;
  public pageSizeOptions: number[] = [5, 10, 20, 100, 200];
  public requests: Request[] = [];
  public searchTerm = new FormControl('');
  public showClosed = false;
  public sortDirection: SortDirection;
  public startIndex = 0;
  public workOrders: WorkOrder[] = [];

  // CountFields
  public activeWorkOrdersCount = 0;
  public closedWorkOrdersCount = 0;
  public draftsCount = 0;
  public noCommsCount = 0;
  public onHoldWorkOrdersCount = 0;
  public readyForPickupWorkOrdersCount = 0;
  public pendingRequestsCount = 0;
  public todaysItemsCount = 0;
  public transferredCount = 0;

  constructor(
    private _authService: AuthService,
    private _dispatchService: DispatchService,
    private _fb: FormBuilder,
    private _modalService: ModalService,
    public notificationService: NotificationService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _requestService: RequestService,
    public searchService: SearchService,
    private _sidenavService: SidenavService,
    private _sidenavLinksService: SidenavLinksService,
    private _socketService: SocketService,
    private _workOrderService: WorkOrderService
  ) {}

  async ngOnInit() {
    this.endIndex = this.pageSize;
    setTimeout(async () => {
      this._sidenavLinksService.selectLink(this._sidenavLinksService.dispatch);
    });

    this.searchTerm.valueChanges.subscribe((value) => {
      this.filter.searchTerm = value;
      this.filter = { ...this.filter };
      this._goToFirstPage();
    });

    // this._getDivWidth();
    this._setPreferences();

    await this.refresh(this.filter.type);

    // Define what to do when new dispatch item comes in via web socket
    this._socketService.socket.on('dispatchItem', async (receivedPacket) => {
      if (receivedPacket.created_by_id !== +this._authService.currentUser.id) {
        // get the item here based on type, process it, and add to the relevant array
        if (receivedPacket.event === 'create' || receivedPacket.event === 'update') {
          if (receivedPacket.type === 'draft') {
            // load the draft and process the fields
            const draft = await this._dispatchService
              .getDispatchDraft(receivedPacket.id, this._dispatchDraftFields)
              .toPromise();

            // update the item in place if it exists, otherwise push to array
            if (this.dispatchDrafts.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.dispatchDrafts[this.dispatchDrafts.map((item) => +item.id).indexOf(+receivedPacket.id)] = draft;
            } else {
              this.dispatchDrafts.push(draft);
            }
          } else if (receivedPacket.type === 'request') {
            // load the request and process the fields
            const request = await this._requestService
              .getRequestById(receivedPacket.id, this._requestsFields)
              .toPromise();

            // update the item in place if it exists, otherwise push to array
            if (this.requests.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.requests[this.requests.map((item) => +item.id).indexOf(+receivedPacket.id)] = request;
            } else {
              this.requests.push(request);
            }
          } else if (receivedPacket.type === 'workorder') {
            // load the wo and process the fields
            const wo = await this._workOrderService
              .getWorkOrderById(receivedPacket.id, this._workOrderFields)
              .toPromise();

            // update the item in place if it exists, otherwise push to array
            if (this.workOrders.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.workOrders[this.workOrders.map((item) => +item.id).indexOf(+receivedPacket.id)] = wo;
            } else {
              this.workOrders.push(wo);
            }
          }
        } else if (receivedPacket.event === 'delete') {
          if (receivedPacket.type === 'draft') {
            // delete the draft from the list
            if (this.dispatchDrafts.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.dispatchDrafts.splice(this.dispatchDrafts.map((item) => +item.id).indexOf(+receivedPacket.id), 1);
            }
          } else if (receivedPacket.type === 'request') {
            // delete the request from the list
            if (this.requests.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.requests.splice(this.requests.map((item) => +item.id).indexOf(+receivedPacket.id), 1);
            }
          } else if (receivedPacket.type === 'workorder') {
            // delete the work order from the list
            if (this.workOrders.map((item) => +item.id).includes(+receivedPacket.id)) {
              this.workOrders.splice(this.workOrders.map((item) => +item.id).indexOf(+receivedPacket.id), 1);
            }
          }
        }

        if (+this._dispatchRequest?.dispatchItem?.id === +receivedPacket.id && this.asideOpen) {
          if (receivedPacket.event === 'delete') {
            this._modalService
              .openConfirmationDialog({
                titleBarText: 'Warning: Item Deleted',
                descriptionText:
                  'Another user has deleted the item you are currently editing. The drawer will now close.',
                hideCancelButton: true,
                confirmationButtonText: 'OK',
              })
              .subscribe(() => {
                this.closeItem();
              });
          } else if (receivedPacket.event === 'update') {
            this._modalService
              .openConfirmationDialog({
                titleBarText: 'Warning: Item Updated',
                descriptionText:
                  'Another user has updated the item you are currently editing. If you save your changes, it will discard their changes and save yours instead.',
                hideCancelButton: true,
                confirmationButtonText: 'OK',
              })
              .subscribe();
          }
        }

        this._processItems();
        this._getCounts();
      }
    });

    this.drawer.closedStart.subscribe(() => {
      this.dispatchRequest.scrollToTop();
    });
  }

  ngOnDestroy(): void {
    this._socketService?.socket?.removeEventListener('dispatchItem');
  }

  get dispatchRequest(): DispatchRequestComponent {
    return this._dispatchRequest;
  }

  get displayCount(): number {
    return (
      (this.pendingRequestsCount || 0) +
      (this.onHoldWorkOrdersCount || 0) +
      (this.activeWorkOrdersCount || 0) +
      (this.draftsCount || 0) +
      (this.readyForPickupWorkOrdersCount || 0)
    );
  }

  get filteredItems(): DispatchItem[] {
    return this._itemFilter.transform(this.items, this.filter);
  }

  get hasASelctedItem(): boolean {
    return this._dispatchRequest?.dispatchItem ? true : false;
  }

  get pageSize(): number {
    return this.pageSizeOptions[3];
  }
  get startPage(): number {
    return this?.startIndex || 1;
  }
  get isSidenavClosed(): boolean {
    return this._sidenavService?.isSidenavClosed;
  }

  private _addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;

    return preferences;
  }

  private _clearLocalStorageFilters() {
    this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.SUBMITTED, null);
    this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.TYPE, null);
    this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.STATUS_ID, null);
    this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.WORKSPACE_ID, null);
  }

  private _closeProgress() {
    this.isLoading = false;
    this._progressIndicatorService.close();
  }

  // Returns the date filter field
  private _dateFilterField(length): APIFilter {
    let selectedLength = null;

    switch (length) {
      case DISPATCH_FILTER_KEY.DAY:
        selectedLength = DISPATCH_FILTER_KEY.DAY;
        break;
      case DISPATCH_FILTER_KEY.WEEK:
        selectedLength = DISPATCH_FILTER_KEY.ISO_WEEK;
        break;
      case DISPATCH_FILTER_KEY.MONTH:
        selectedLength = DISPATCH_FILTER_KEY.MONTH;
        break;
      default:
        selectedLength = DISPATCH_FILTER_KEY.MONTH;
        break;
    }

    return {
      type: 'field',
      field: 'created_datetime',
      value: moment().startOf(length).format('YYYY-MM-DD HH:mm:ss'),
      match: '>',
    };
  }

  private async _getClosedWorkOrders() {
    this._closedWorkOrders = await this._workOrderService
      .getWorkOrders(
        this._workOrderFields,
        [{ type: 'field', field: 'status_id', value: WorkOrderStatus.CLOSED.toString() }],
        10000
      )
      .toPromise();
  }

  // Gets Dispatch Draft items from the database
  private async _getDispatchDrafts(length = null, sort) {
    // When filtering items this checks to see if items from longer in the past are needed. If not it does not make an api call
    if (sort && this._lastApiCall.dispatchDraft >= this._timeOptions[length || 'all']) {
      return;
    }
    // These are added here instead of the refresh so nothing is displayed when no api calls are made
    this._openProgress('Retrieving Drafts...');

    // default is an api call with no filters
    const dispatchDraftFilter: APIFilter[] = [{ type: 'field', field: 'status_id', value: '1' }];

    // saves what the date range of this api call. So in the future uneeded calls wont be made.
    this._lastApiCall.dispatchDraft = this._timeOptions[length || 'all'];

    // Checks to see if there is a date range if so it adds the filter
    if (length) {
      if (dispatchDraftFilter.length) {
        dispatchDraftFilter.push({ type: 'operator', value: 'AND' });
      }
      dispatchDraftFilter.push(this._dateFilterField(length));
    }

    // Makes the api call
    this.dispatchDrafts = await this._dispatchService
      .getDispatchDrafts(this._dispatchDraftFields, dispatchDraftFilter)
      .toPromise();
  }

  // private _getDivWidth() {
  //   this.divWidth = this.elementView.nativeElement.offsetWidth;
  // }

  private async _getCounts() {
    const counts = await this._dispatchService.getDispatchCounts().toPromise();
    this.closedWorkOrdersCount = counts.closedWorkOrders;
    this.activeWorkOrdersCount = counts.activeWorkOrders;
    this.onHoldWorkOrdersCount = counts.onHoldWorkOrders;
    this.readyForPickupWorkOrdersCount = counts.readyForPickupWorkOrders;
    this.draftsCount = counts.allDrafts;
    this.noCommsCount = counts.noCommWorkOrders;
    this.pendingRequestsCount = counts.pendingRequests;
    this.todaysItemsCount = counts.todaysWorkOrders + counts.todaysRequests;
    this.transferredCount = counts.transferredWorkOrders + counts.transferredRequests;
  }

  private async _getRequests(length = null, sort) {
    // When filtering items this checks to see if items from longer in the past are needed. If not it does not make an api call
    if (
      sort &&
      this._lastApiCall.request >= this._timeOptions[length || 'all'] &&
      this.filter.type !== DispatchType.RequestAndWorkOrder
    ) {
      return;
    }

    // These are added here instead of the refresh so nothing is displayed when no api calls are made
    this._openProgress('Retrieving request...');

    // default is an api call with no filters
    const requestFilter: APIFilter[] =
      this.filter.type === DispatchType.RequestAndWorkOrder ? [] : [{ type: 'field', field: 'status_id', value: '1' }];

    // saves what the date range of this api call. So in the future uneeded calls wont be made.
    this._lastApiCall.request = this._timeOptions[length || 'all'];

    // Checks to see if there is a date range if so it adds the filter
    if (length) {
      if (requestFilter.length) {
        requestFilter.push({ type: 'operator', value: 'AND' });
      }
      requestFilter.push(this._dateFilterField(length));
    }

    // Makes the api call
    this.requests = await this._requestService.getRequests(this._requestsFields, requestFilter).toPromise();
  }

  private async _getWorkOrders(length = null, sort) {
    // When filtering items this checks to see if items from longer in the past are needed. If not it does not make an api call
    if (
      sort &&
      this._lastApiCall.workOrder >= this._timeOptions[length || 'all'] &&
      this.filter.type !== DispatchType.RequestAndWorkOrder
    ) {
      return;
    }

    // These are added here instead of the refresh so nothing is displayed when no api calls are made
    this._openProgress('Retrieving Work Orders...');

    // default is an api call with no filters
    let workOrdersFilter: APIFilter[] =
      this.filter.type === DispatchType.RequestAndWorkOrder
        ? []
        : [{ type: 'field', field: 'status_id', value: '1^2^4' }];

    // saves what the date range of this api call. So in the future uneeded calls wont be made.
    this._lastApiCall.workOrder = this._timeOptions[length || 'all'];

    // Checks to see if there is a date range if so it adds the filter
    if (length) {
      workOrdersFilter = [this._dateFilterField(length)];
    }

    this.workOrders = await this._workOrderService.getWorkOrders(this._workOrderFields, workOrdersFilter).toPromise();
  }

  private _goToFirstPage() {
    this.paginator?.firstPage();
    this.startIndex = 0;
    this.endIndex = this.pageSize;
  }

  private _filterBust() {
    this.filter = {};
    this._clearLocalStorageFilters();
    this._goToFirstPage();
  }

  // opens the create draft sidebar
  private _openItem() {
    // this._getDivWidth();
    this.asideOpen = true;
  }

  private _openProgress(action: string) {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  private _processClosedWorkOrderData() {
    return (
      this._closedWorkOrders?.map((closedWorkOrder: WorkOrder) => {
        // defaults
        const closedWorkOrderObject: DispatchItem = { type: DispatchType.ClosedWorkOrder };

        // populate with whats from the db
        this._workOrderFields.forEach((closedWorkOrderFieldName: string) => {
          // remove {  and only use the first part
          const closedWorkOrderKeyName = closedWorkOrderFieldName.split('{')[0];

          if (closedWorkOrderKeyName === 'module') {
            closedWorkOrderObject.workspace = closedWorkOrder[closedWorkOrderKeyName];
          } else if (closedWorkOrderKeyName === 'contacts' || closedWorkOrderKeyName === 'followers') {
            closedWorkOrderObject.contacts =
              typeof closedWorkOrder[closedWorkOrderKeyName] === 'string'
                ? JSON.parse(closedWorkOrder[closedWorkOrderKeyName])
                : closedWorkOrder[closedWorkOrderKeyName];
          } else if (closedWorkOrderKeyName === 'contact_ids' || closedWorkOrderKeyName === 'follower_ids') {
            closedWorkOrderObject.contact_ids =
              typeof closedWorkOrder[closedWorkOrderKeyName] === 'string'
                ? JSON.parse(closedWorkOrder[closedWorkOrderKeyName])
                : closedWorkOrder[closedWorkOrderKeyName];
          } else if (closedWorkOrderKeyName === 'updates.sort(created_datetime).order(desc).limit(1)') {
            closedWorkOrderObject.update = closedWorkOrder.updates && closedWorkOrder.updates[0];
          } else {
            closedWorkOrderObject[closedWorkOrderKeyName] = closedWorkOrder[closedWorkOrderKeyName];
          }
        });
        return closedWorkOrderObject;
      }) || []
    );
  }

  private _processDraftData() {
    return (
      this.dispatchDrafts?.map((dispatchDraft: DispatchDraft) => {
        // defaults
        const draftObject: DispatchItem = { type: DispatchType.Draft, workspace: null };
        // populate with whats from the db
        this._dispatchDraftFields.forEach((draftFieldName: string) => {
          // remove {  and only use the first part
          const draftKeyName = draftFieldName.split('{')[0];

          if (draftKeyName === 'contacts' || draftKeyName === 'followers') {
            draftObject.contacts =
              typeof dispatchDraft[draftKeyName] === 'string'
                ? JSON.parse(dispatchDraft[draftKeyName])
                : dispatchDraft[draftKeyName];
          } else if (draftKeyName === 'contact_ids' || draftKeyName === 'follower_ids') {
            draftObject.contact_ids =
              typeof dispatchDraft[draftKeyName] === 'string'
                ? JSON.parse(dispatchDraft[draftKeyName])
                : dispatchDraft[draftKeyName];
          } else if (draftKeyName === 'selected_topic_group') {
            draftObject.topic_group = dispatchDraft[draftKeyName];
          } else if (draftKeyName === 'selected_category') {
            draftObject.topic_category = dispatchDraft[draftKeyName];
          } else if (draftKeyName === 'module') {
            draftObject.workspace = dispatchDraft[draftKeyName];
          } else {
            draftObject[draftKeyName] = dispatchDraft[draftKeyName];
          }
        });
        return draftObject;
      }) || []
    );
  }

  private _processItems() {
    if (this.showClosed) {
      this.items = [...this._processClosedWorkOrderData()];
    } else {
      this.items = [...this._processDraftData(), ...this._processRequestData(), ...this._processWorkOrderData()];
    }
  }

  private _processRequestData() {
    return (
      this.requests?.map((request: Request) => {
        // defaults
        const requestObject: DispatchItem = { type: DispatchType.ProjectRequest, created_by: {} };
        // populate with whats from the db
        this._requestsFields.forEach((requestFieldName: string) => {
          // remove {  and only use the first part
          const requestKeyName = requestFieldName.split('{')[0];
          // hack for created_by
          if (
            requestKeyName === 'created_by_first_name' ||
            requestKeyName === 'created_by_id' ||
            requestKeyName === 'created_by_last_name'
          ) {
            requestObject.created_by[requestKeyName.replace('created_by_', '')] = request[requestKeyName];
          } else if (requestKeyName === 'short_description') {
            requestObject.title = request[requestKeyName];
          } else if (requestKeyName === 'contacts' || requestKeyName === 'followers') {
            requestObject.contacts =
              typeof request[requestKeyName] === 'string'
                ? JSON.parse(request[requestKeyName])
                : request[requestKeyName];
          } else if (requestKeyName === 'contact_ids' || requestKeyName === 'follower_ids') {
            requestObject.contact_ids =
              typeof request[requestKeyName] === 'string'
                ? JSON.parse(request[requestKeyName])
                : request[requestKeyName];
          } else {
            requestObject[requestKeyName] = request[requestKeyName];
          }
        });
        return requestObject;
      }) || []
    );
  }

  private _processWorkOrderData() {
    return (
      this.workOrders?.map((workOrder: WorkOrder) => {
        // defaults
        const workOrderObject: DispatchItem = { type: DispatchType.WorkOrder };
        // populate with whats from the db
        this._workOrderFields.forEach((workOrderFieldName: string) => {
          // remove {  and only use the first part
          const workOrderKeyName = workOrderFieldName.split('{')[0];
          if (workOrderKeyName === 'module') {
            workOrderObject.workspace = workOrder[workOrderKeyName];
          } else if (workOrderKeyName === 'contacts' || workOrderKeyName === 'followers') {
            workOrderObject.contacts =
              typeof workOrder[workOrderKeyName] === 'string'
                ? JSON.parse(workOrder[workOrderKeyName])
                : workOrder[workOrderKeyName];
          } else if (workOrderKeyName === 'contact_ids' || workOrderKeyName === 'follower_ids') {
            workOrderObject.contact_ids =
              typeof workOrder[workOrderKeyName] === 'string'
                ? workOrder[workOrderKeyName]?.length
                  ? JSON.parse(workOrder[workOrderKeyName])
                  : null
                : workOrder[workOrderKeyName];
          } else if (workOrderKeyName === 'updates.sort(created_datetime).order(desc).limit(1)') {
            workOrderObject.update = workOrder.updates && workOrder.updates[0];
          } else {
            workOrderObject[workOrderKeyName] = workOrder[workOrderKeyName];
          }
        });
        return workOrderObject;
      }) || []
    );
  }

  // Saves filters fields to local storage and allows for the fact that local_storage field names are different then the ones in the filter object
  private _setFilterInLocalStorage(key: DISPATCH_FILTER_KEY, value: DISPATCH_LOCAL_STORAGE_KEY) {
    let localStorageKey: string;
    switch (key) {
      case DISPATCH_FILTER_KEY.SUBMITTED:
        localStorageKey = DISPATCH_LOCAL_STORAGE_KEY.DISPATCH_SUBMITTED;
        break;
      case DISPATCH_FILTER_KEY.TYPE:
        localStorageKey = DISPATCH_LOCAL_STORAGE_KEY.DISPATCH_TYPE;
        break;
      case DISPATCH_FILTER_KEY.STATUS_ID:
        localStorageKey = DISPATCH_LOCAL_STORAGE_KEY.DISPATCH_STATUS_ID;
        break;
      case DISPATCH_FILTER_KEY.WORKSPACE_ID:
        localStorageKey = DISPATCH_LOCAL_STORAGE_KEY.DISPATCH_WORKSPACE_ID;
        break;

      default:
        return;
    }

    localStorage.setItem('preferences', JSON.stringify(this._addToPreferences(localStorageKey, value)));
  }

  private _setPreferences() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    if (preferences) {
      this.fieldToSortBy = preferences.sort_dispatch_by_field || null;
      this.sortDirection = preferences.dispatch_sort_direction || null;
      this.filter.status_id = preferences.dispatch_status_id || null;
      this.filter.submitted = preferences.dispatch_submitted || null;
      this.filter.type = preferences.dispatch_type === 0 ? 0 : null || preferences.dispatch_type;
      this.filter.workspace_id = preferences.dispatch_workspace_id || null;
    }
  }

  public clearEditandCreate() {
    this._dispatchRequest.clearEdit();
    this.create();
  }

  public clearFilter() {
    if (!isEmpty(this.filter) || this.showClosed) {
      this._filterBust();
      this.showClosed = false;
      this.refresh();
    }
  }

  public clearSearch() {
    this.searchTerm.reset();
  }

  public closeItem() {
    // this._getDivWidth();
    this.asideOpen = false;
    this.drawer.close();
  }

  public create() {
    if (this.asideOpen && !this._dispatchRequest.dispatchItem) {
      // that means, a fresh create is in progress, so clear it
      this._dispatchRequest.clear();
    } else {
      this._openItem();
    }
  }

  public editItem(item: DispatchItem) {
    this._openItem();
    this._dispatchRequest.editItem(item);
  }

  public filterClosedItems(key: DISPATCH_FILTER_KEY, value: DISPATCH_FILTER_KEY) {
    if (key && value) {
      this.filter[key] = value;
    } else {
      this._filterBust();
    }

    this._processItems();
  }

  // Turns a date object into a readable format
  public formatSubmittedDate(date: Date): string {
    return moment(date).format('ddd,  MMM D YYYY • h:mm a').toString();
  }

  // Filters the item array by the time argument and type. Draft, Request, Workspace
  public getItemsByTypeSubmitted(submitted: DISPATCH_FILTER_KEY, type: number) {
    this.updateFilter(DISPATCH_FILTER_KEY.SUBMITTED, submitted);
    this.updateFilter(DISPATCH_FILTER_KEY.TYPE, type, true);
  }

  // Filters the item array by the time argument type and Status. Pending Request, Active Work Order
  public getItemsByTypeSubmittedStatus(submitted: string, type: number, status_id: number) {
    this.updateFilter(DISPATCH_FILTER_KEY.SUBMITTED, submitted);
    this.updateFilter(DISPATCH_FILTER_KEY.TYPE, type);
    this.updateFilter(DISPATCH_FILTER_KEY.STATUS_ID, status_id, true);
  }

  // Filters the item array by the time argument and workspace id
  public getItemsByWorkspaceSubmitted(submitted: string, workspace_id: number) {
    this.updateFilter(DISPATCH_FILTER_KEY.SUBMITTED, submitted);
    this.updateFilter(DISPATCH_FILTER_KEY.TYPE, null);
    this.updateFilter(DISPATCH_FILTER_KEY.WORKSPACE_ID, workspace_id, true);
  }

  public getSearchPlaceholder() {
    let timeframeName;
    switch (this.filter.submitted) {
      case 'day':
        timeframeName = `Today's`;
        break;
      case 'week':
        timeframeName = `This Week's`;
        break;
      case 'month':
        timeframeName = `This Month's`;
        break;
    }
    let statusName = timeframeName ? '' : 'All';
    let typeName;
    switch (this.filter?.type) {
      case 0:
        typeName = 'Drafts';
        break;
      case 1:
        typeName = 'Requests';
        if (this.filter?.status_id === 1) {
          statusName = 'Pending';
        }
        break;
      case 2:
        typeName = 'Work Orders';
        if (this.filter?.status_id === 1) {
          statusName = 'Active';
        } else if (this.filter?.status_id === 2) {
          statusName = 'On Hold';
        } else if (this.filter?.status_id === 4) {
          statusName = 'Ready for Pickup';
        }
        break;
      default:
        typeName = 'Items';
        break;
    }
    return `Search${timeframeName ? ` ${timeframeName}` : ''}${statusName ? ` ${statusName}` : ''}${
      this.filter?.workspace_id === 2 ? ` Transferred` : ''
    }${typeName ? ` ${typeName}` : ''}`;
  }

  public itemListBackgroundColor(item: DispatchItem = null) {
    if (item.type === DispatchType.Draft) {
      return 'bg-shade-orange';
    } else if (item.workspace?.id === 2) {
      return 'bg-shade-red';
    }
  }

  public itemListTagColor(item: DispatchItem) {
    if (item.type === DispatchType.Draft) {
      return 'bg-orange white';
    } else if (item.workspace?.id === 2) {
      return 'bg-red white';
    } else {
      return 'bg-shade-gray dkgray';
    }
  }

  // public onResize(event) {
  //   this._getDivWidth();
  // }

  public pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
  }

  public async refresh(dispatchType: DispatchType = null, sort = false) {
    if (dispatchType === null || dispatchType === DispatchType.Draft) {
      await this._getDispatchDrafts(this.filter.submitted, sort);
    }
    if (
      dispatchType === null ||
      dispatchType === DispatchType.ProjectRequest ||
      dispatchType === DispatchType.RequestAndWorkOrder
    ) {
      await this._getRequests(this.filter.submitted, sort);
    }
    if (
      dispatchType === null ||
      dispatchType === DispatchType.WorkOrder ||
      dispatchType === DispatchType.RequestAndWorkOrder ||
      dispatchType === DispatchType.NoCommsThreeDays
    ) {
      await this._getWorkOrders(this.filter.submitted, sort);
    }

    // process the data to match a certain shape
    this._processItems();

    // Get counts for top buttons
    if (!sort) {
      await this._getCounts();
    }
    this._closeProgress();
  }

  public async reloadEditItem({ itemId, topic_type, isNewRequest }) {
    await this.refresh();
    if (!isNewRequest) {
      this._dispatchRequest.editItem(
        this.items
          .filter((filtererItem) => filtererItem.type === topic_type)
          .find((foundItem) => foundItem.id === itemId)
      );
    }
  }

  public async showClosedWorkOrders() {
    this._filterBust();
    this._openProgress('Retrieving Closed Items...');
    this.showClosed = true;
    await this._getClosedWorkOrders();
    this._processItems();
    this._closeProgress();
  }

  // Updates a filter using the arguments given to it
  public updateFilter(key: DISPATCH_FILTER_KEY, value = null, refresh = false) {
    this._goToFirstPage();
    // resets
    this.showClosed = false;
    // automatically resets status so its not accidentally included when not needed
    if (this.filter?.status_id && key !== DISPATCH_FILTER_KEY.STATUS_ID) {
      this.filter.status_id = null;
      this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.STATUS_ID, null);
    }

    if (this.filter?.workspace_id && key !== DISPATCH_FILTER_KEY.WORKSPACE_ID) {
      this.filter.workspace_id = null;
      this._setFilterInLocalStorage(DISPATCH_FILTER_KEY.WORKSPACE_ID, null);
    }

    if (key) {
      this.filter[key] = value;
      this._setFilterInLocalStorage(key, value);
      this.filter.searchTerm = null;
    }

    if (refresh) {
      this.refresh(this.filter.type, true);
    }
    this.filter = { ...this.filter };
  }

  // Updates and then saves the current sort field argument to local storage
  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      localStorage.setItem(
        'preferences',
        JSON.stringify(this._addToPreferences('dispatch_sort_direction', this.sortDirection))
      );
    }
    this.fieldToSortBy = field;
    localStorage.setItem(
      'preferences',
      JSON.stringify(this._addToPreferences('sort_dispatch_by_field', this.fieldToSortBy))
    );
  }
}
