import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ModalService, ModuleService } from 'src/app/services';
import { Workspace as Module } from 'src/app/enums';
import { Topic, TopicCategory, TopicGroup, Workspace } from 'src/app/types';

@Component({
  selector: 'app-workspace-dropdown',
  templateUrl: './workspace-dropdown.component.html',
  styleUrls: ['./workspace-dropdown.component.scss'],
})
export class WorkspaceDropdownComponent implements OnInit {
  @Input() required = true;
  @Input() showTitle = true;
  @Input() selectedTopic: {
    topic_categories: TopicCategory;
    topic_groups: TopicGroup;
    topics: Topic;
    workspace: Workspace;
  };
  @Input() customPlaceholder: string;
  @Input() placeholder = 'Search Workspaces';
  @Output() clearTopic = new EventEmitter();

  public filteredWorkspaces: Observable<Workspace[]>;
  public hideCancel = false;
  public workspaces: Workspace[] = [];
  public workspace = new FormControl('');

  constructor(private _workspaceService: ModuleService, private modalService: ModalService) {}

  async ngOnInit() {
    if (this.required) {
      this.workspace.setValidators([Validators.required]);
    }

    this.workspaces = await this._workspaceService.getWorkspaces().toPromise();

    await this._subscribe();
  }

  public clearInput(event = null) {
    this.workspace.setValue('');

    if (event) {
      event.stopPropagation();
    }

    if (this.selectedTopic) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Clear Workspace',
          headerText: 'Do you want to clear the Workspace?',
          descriptionText:
            'Are you sure you want to clear the workspace? This will clear the Group/Category and Topic fields.',
          confirmationButtonText: 'Clear',
        })
        .toPromise()
        .then((res) => {
          if (res) {
            this.clearTopic.emit();
          } else {
            this.workspace.setValue(this.selectedTopic.workspace);
          }
        });
    }
  }

  private _filter(entry: string): Workspace[] {
    return this.workspaces.filter((workspace) => workspace?.name?.toLowerCase()?.includes(entry?.toLowerCase()));
  }

  private async _subscribe() {
    this.filteredWorkspaces = this.workspace.valueChanges.pipe(
      startWith(''),
      map((value: string | Workspace) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) => (name ? this._filter(name) : this.workspaces.slice()))
    );
  }

  public workspaceMapper(workspace: Workspace): string {
    return workspace?.name || '';
  }
}
