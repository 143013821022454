import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  KeyControlsService,
  ModalService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
} from '../../services';
import { Project, TaskAccessoryData } from '../../types';
import { KeyControlsBridge } from '../../types/key-controls-bridge';
import { MatStepper } from '@angular/material/stepper';
import { KeyControlTypes, TaskReviewStatus, TaskStatus, Workspace } from '../../enums';
import * as moment from 'moment';
import { ProjectTenantService } from '../../workspaces/construction/services';
import { keyControlAuditCounts } from '../../utils';
import { BidPackage } from '../../workspaces/construction/types';
import { OrderByFieldPipe } from '../../pipes';

@Component({
  selector: 'app-view-key-controls-dialog',
  templateUrl: './view-key-controls-dialog.component.html',
  styleUrls: ['./view-key-controls-dialog.component.scss'],
})
export class ViewKeyControlsDialogComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private dialogRef: MatDialogRef<ViewKeyControlsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private keyControlService: KeyControlsService,
    private modalService: ModalService,
    private projectService: ProjectService,
    private projectTenantService: ProjectTenantService,
    private sortPipe: OrderByFieldPipe,
    private taskService: ProjectTaskService,
    private updateReviewerService: UpdateReviewerService
  ) {}

  private bidPackageFields = [
    'awarded_amount',
    'awarded_company_id',
    'bids{id,is_awarded,contract_task{accessory_data,status_id}}',
    'executed_contract_id',
    'executed_contract_name',
    'executed_contract_task',
    'number',
    'trade_name',
    'trade{is_consultant}',
    'child_request{id,code}',
    'child_project{id,code,budget_data}',
  ];

  private tenantFields61 = [
    'tenant_name',
    'bubble_drawing_ids',
    'exhibit_b_file_id',
    'sublease_contract_file_id',
    'amortization_file_id',
    'reimbursement_file_id',
    'peb_approval_task_id',
  ];

  private tenantFields74 = [
    'tenant_name',
    'budget_tenant_approval_task_id',
    'quotes{quote_items{id,project_product{id,is_taxable,selected_quote_item_id,tenant_id},total_price,is_awarded},arf_approval_task,company{name}}',
    'type_id',
  ];

  private bidPackages: BidPackage[];
  public keyControls: KeyControlsBridge[];
  public project: Project;
  public stepperIndex = 0;
  public currentIndex = 0;
  public isConstruction: boolean;
  public dataLoaded = false;
  private taskBasedControls = [
    KeyControlTypes.FIRE_MARSHAL_61,
    KeyControlTypes.CONTRACT_COMPLETION_61,
    KeyControlTypes.PROJECT_PROPERLY_BID_74,
    KeyControlTypes.CUSTOMER_SATISFACTION_74,
  ];

  get projectId() {
    return this.data?.projectId || this.projectService.currentSelectedProjectId;
  }

  async ngOnInit() {
    const promises = [];
    promises.push(
      this.projectService
        .getProjectById(this.projectId)
        .toPromise()
        .then((project) => {
          this.project = project;
          this.isConstruction = this.project.module_id === Workspace.Construction;
        })
    );
    promises.push(
      this.keyControlService
        .getKeyControlsForProject(this.projectId)
        .toPromise()
        .then(
          (keyControls) =>
            (this.keyControls = keyControls.sort(
              (a, b) => a.key_control_template?.sequence - b.key_control_template?.sequence
            ))
        )
    );
    await Promise.all(promises);
    this.dataLoaded = true;

    setTimeout(() => {
      const keyControlIndex = this.keyControls.findIndex((kc) => kc.id === this.data?.keyControlId);
      this.stepperIndex = keyControlIndex !== -1 ? keyControlIndex : 0;
      this.currentIndex = this.stepperIndex;

      // load the visible key control, and then load the rest
      this.loadKeyControlData(this.keyControls[this.stepperIndex]).then(() => {
        for (let i = 0; i < this.keyControls.length; i++) {
          if (i !== this.stepperIndex) {
            this.loadKeyControlData(this.keyControls[i]).then();
          }
        }
      });
    });
  }

  get KeyControlTypes() {
    return KeyControlTypes;
  }

  get isTaskBasedControl() {
    const currentKeyControl = this.keyControls[this.currentIndex];
    return this.taskBasedControls.find((type) => type === currentKeyControl.key_control_template.display_data);
  }

  private async loadKeyControlData(kc: KeyControlsBridge) {
    const promises = [];
    if (kc.task?.status_id === TaskStatus.Complete) {
      const accessoryData: TaskAccessoryData = kc?.task?.accessory_data && JSON.parse(kc?.task?.accessory_data);
      kc.task.key_control_status_counts = keyControlAuditCounts(accessoryData?.reviewChain);

      if (!this.taskBasedControls.find((controlType) => controlType === kc.key_control_template.display_data)) {
        promises.push(
          this.keyControlService
            .getKeyControlHistory(kc.id)
            .toPromise()
            .then((history) => {
              const completedEvent = history?.find((h) => h.event_id === 1);
              if (completedEvent) {
                kc.completed_by = completedEvent.created_by;
                kc.completed_datetime = completedEvent.created_datetime;
              } else {
                this.taskService.getEventsForTaskId(kc.task_id, 'complete').subscribe((taskCompletedEvents) => {
                  if (taskCompletedEvents?.length) {
                    kc.completed_datetime = taskCompletedEvents[0].created_datetime;
                    kc.completed_by = {
                      id: taskCompletedEvents[0].created_by_id,
                      first_name: taskCompletedEvents[0].created_by_first_name,
                      last_name: taskCompletedEvents[0].created_by_last_name,
                    };
                  }
                });
              }
            })
        );
      }

      if (this.taskBasedControls.find((controlType) => controlType === kc.key_control_template.display_data)) {
        promises.push(
          this.taskService.getEventsForTaskId(kc.task_id, 'complete').subscribe((taskCompletedEvents) => {
            if (taskCompletedEvents?.length) {
              kc.completed_datetime = taskCompletedEvents[0].created_datetime;
              kc.completed_by = {
                id: taskCompletedEvents[0].created_by_id,
                first_name: taskCompletedEvents[0].created_by_first_name,
                last_name: taskCompletedEvents[0].created_by_last_name,
              };
            }
          })
        );
      }
    }

    if (this.isConstruction) {
      promises.push(
        this.constructionBasedKeyControls(kc.key_control_template.display_data, kc.task).then(
          (data) => (kc.data = data)
        )
      );
    } else {
      promises.push(
        this.nonConstructionBasedKeyControls(kc.key_control_template.display_data, kc.task).then(
          (data) => (kc.data = data)
        )
      );
    }

    await Promise.all(promises);
  }

  private async constructionBasedKeyControls(keyControlId, task) {
    let keyControlData = [];
    switch (keyControlId) {
      case KeyControlTypes.BUDGET_FINALIZED_61:
        const tenants = await this.projectTenantService
          .getTenantsForProject(this.projectId, this.tenantFields61)
          .toPromise();
        for (const tenant of tenants) {
          const tenantName = tenant.tenant_name || 'UHAT';
          const pebTask = tenant.peb_approval_task_id
            ? await this.projectService.getTaskById(tenant.peb_approval_task_id).toPromise()
            : null;
          if (pebTask) {
            const accessoryData = pebTask?.accessory_data && JSON.parse(pebTask.accessory_data);
            const pebLatestFiles =
              accessoryData?.reviewFiles || (await this.taskService.getLatestTaskActivityNote(pebTask.id, true));

            keyControlData.push({ title: `PEB (${tenantName})`, files: pebLatestFiles });
            if (tenant.bubble_drawing_ids?.length) {
              const bubbleDrawinIds = [];
              tenant.bubble_drawing_ids.forEach((id) => bubbleDrawinIds.push({ id }));
              keyControlData.push({
                title: `Bubble Drawings (${tenantName})`,
                files: bubbleDrawinIds,
              });
            }
            if (tenant.exhibit_b_file_id) {
              keyControlData.push({
                title: `Exhibit B (${tenantName})`,
                files: [{ id: tenant.exhibit_b_file_id }],
              });
            }
            if (tenant.sublease_contract_file_id) {
              keyControlData.push({
                title: `Sublease (${tenantName})`,
                files: [{ id: tenant.sublease_contract_file_id }],
              });
            }
            if (tenant.amortization_file_id) {
              keyControlData.push({
                title: `Amortization (${tenantName})`,
                files: [{ id: tenant.amortization_file_id }],
              });
            }
            if (tenant.reimbursement_file_id) {
              keyControlData.push({
                title: `Reimbursement (${tenantName})`,
                files: [{ id: tenant.reimbursement_file_id }],
              });
            }
          }
        }
        return keyControlData;

      case KeyControlTypes.PROJECT_PROPERLY_BID_61:
        // Gets bid packages for Project Properly Bid and Supplier Contracts Signed
        return await this.getSortedBidPackageData();

      case KeyControlTypes.VENDOR_CONTRACTS_61:
        keyControlData = [];
        const sortedBidPackages = await this.getSortedBidPackageData();
        for (const bp of sortedBidPackages) {
          const kcDataItem: any = {
            title: `${bp.number || ''} ${bp.number ? '-' : ''} ${bp.trade_name}`,
          };

          if (bp.child_request?.id || bp.child_project?.id) {
            if (bp.child_request?.id) {
              kcDataItem.linked_request = {
                id: bp.child_request?.id,
                code: bp.child_request?.code,
              };
            }
            if (bp.child_project?.id) {
              kcDataItem.linked_project = {
                id: bp.child_project?.id,
                code: bp.child_project?.code,
              };
            }
          } else {
            const files = [];
            const awardedBid = bp.bids?.find((bid) => bid.is_awarded);
            if (bp.executed_contract_id || awardedBid?.contract_task?.status_id === TaskStatus.Complete) {
              const accessoryData =
                awardedBid.contract_task?.accessory_data && JSON.parse(awardedBid.contract_task?.accessory_data);
              const file = {
                id: bp.executed_contract_id || accessoryData.reviewFiles[0]?.id,
                name: bp.executed_contract_name || accessoryData.reviewFiles[0]?.name,
              };

              files.push(file);
            }
            kcDataItem.files = files;
          }
          keyControlData.push(kcDataItem);
        }
        return keyControlData;

      case KeyControlTypes.FIRE_MARSHAL_61:
        keyControlData = [];
        // Gets files related to Fire Marshal
        const fireMarshalLatestFiles = (await this.taskService.getLatestTaskActivityNote(task?.id, true)) || [];
        fireMarshalLatestFiles.forEach((fileId, i) => {
          keyControlData.push({ title: `Attachment ${i + 1}`, files: [fileId] });
        });
        return keyControlData;

      case KeyControlTypes.CONTRACT_COMPLETION_61:
        keyControlData = [];
        // Gets files related to Contract Completion
        const contractCompletionLatestFiles = (await this.taskService.getLatestTaskActivityNote(task?.id, true)) || [];
        contractCompletionLatestFiles.forEach((fileId, i) => {
          keyControlData.push({ title: `Attachment ${i + 1}`, files: [fileId] });
        });
        return keyControlData;

      case KeyControlTypes.ALL_INVOICES__PROCESSED_61:
        return this.getInvoiceData();
      default:
        return [];
    }
  }

  private async getSortedBidPackageData() {
    const splitBidPackages = [[], []];
    if (!this.bidPackages) {
      this.bidPackages = await this.projectService
        .getBidPackages(
          [
            {
              type: 'field',
              field: 'project_id',
              value: this.projectId.toString(),
            },
          ],
          this.bidPackageFields
        )
        .toPromise();
    }

    this.bidPackages.forEach((bp) => {
      const index = bp.trade?.is_consultant ? 0 : 1;
      splitBidPackages[index].push(bp);
    });

    return this.sortPipe.transform(splitBidPackages[0].concat(splitBidPackages[1]) || [], 'number', 'asc');
  }

  private async nonConstructionBasedKeyControls(keyControlId, task) {
    const keyControlData = [];
    const tenants = await this.projectTenantService
      .getTenantsForProject(this.projectId, this.tenantFields74)
      .toPromise();

    switch (keyControlId) {
      case KeyControlTypes.BUDGET_APPROVED_74:
        for (const tenant of tenants) {
          const tenantName = tenant.tenant_name || 'UHAT';
          const budgetTask =
            (tenant.budget_tenant_approval_task_id &&
              (await this.projectService.getTaskById(tenant.budget_tenant_approval_task_id).toPromise())) ||
            null;
          if (budgetTask?.status_id === TaskStatus.Complete) {
            const latestFiles = await this.taskService.getLatestTaskActivityNote(budgetTask.id, true);
            keyControlData.push({ title: tenantName, files: latestFiles });
          }
        }

        return keyControlData;

      case KeyControlTypes.PROJECT_PROPERLY_BID_74:
        for (const [index, tenant] of Object.entries(tenants)) {
          // adds up or the awarded bids to get the total
          const awardedTotal = tenant.quotes.reduce(
            (quoteTotal, currentQuote) =>
              quoteTotal +
              currentQuote.quote_items.reduce(
                (quoteItemTotal, currentQuoteItem) =>
                  currentQuoteItem.is_awarded
                    ? Number(quoteItemTotal) + Number(currentQuoteItem.total_price)
                    : quoteItemTotal,
                0
              ),
            0
          );

          keyControlData.push({
            title: `${Number(index) + 1} - ${tenant?.tenant_name ?? 'UHAT'}`,
            numberOfQuotes: tenant?.quotes?.length || 0,
            awardedTotal,
            isQouteDetail: true,
          });
        }
        const projectProperlyBidLatestFiles = (await this.taskService.getLatestTaskActivityNote(task?.id, true)) || [];
        projectProperlyBidLatestFiles.forEach((fileId, i) => {
          keyControlData.push({ title: `Attachment ${i + 1}`, files: [fileId] });
        });

        return keyControlData;

      case KeyControlTypes.ARFS_FINALIZED_74:
        for (const tenant of tenants) {
          for (const quote of tenant.quotes) {
            const quoteIsAwarded = !!quote.quote_items.find((qi) => qi.is_awarded);

            if (quoteIsAwarded) {
              const latestFiles = await this.taskService.getLatestTaskActivityNote(quote.arf_approval_task_id, true);

              keyControlData.push({
                title: `${quote?.company?.name} (${tenant.tenant_name || 'UHAT'})`,
                files: latestFiles,
              });
            }
          }
        }
        return keyControlData;

      case KeyControlTypes.ALL_INVOICES_PROCESSED_74:
        return this.getInvoiceData();

      case KeyControlTypes.CUSTOMER_SATISFACTION_74:
        const customerSatisfactionLatestFiles =
          (await this.taskService.getLatestTaskActivityNote(task?.id, true)) || [];
        customerSatisfactionLatestFiles.forEach((fileId, i) => {
          keyControlData.push({ title: `Attachment ${i + 1}`, files: [fileId] });
        });
        return keyControlData;
      default:
        return [];
    }
  }

  private async getInvoiceData() {
    const invoiceData = await this.projectService
      .getBudgetData(this.projectId, this.project.module_id, null)
      .toPromise();
    const keyControlData = [];
    const originalContractSum = invoiceData.awardedBidTotal;
    const changeOrderAmount = invoiceData.changeOrderTotal || 0;
    const totalContractSum = originalContractSum + changeOrderAmount;
    const invoiceBilledTotal = invoiceData.invoicesBilledTotal;

    keyControlData.push({ title: 'Original Contract Sum', amount: originalContractSum });
    if (this.isConstruction) {
      keyControlData.push({ title: 'CO Net', amount: changeOrderAmount });
      keyControlData.push({ title: 'Total Contract Sum', amount: totalContractSum });
      keyControlData.push({
        title: 'Retainage WH',
        amount: invoiceData.invoicesRetainageTotal,
      });
    }
    keyControlData.push({ title: 'Billed-To-Date', amount: invoiceBilledTotal });
    keyControlData.push({
      title: 'Balance-To-Finish',
      amount: totalContractSum - invoiceBilledTotal,
    });

    return keyControlData;
  }

  public changeIndex(e) {
    this.currentIndex = e.selectedIndex;
  }

  get projectTitle() {
    const location = `${this.project?.building_code || ''}${
      this.project?.building_code && this.project?.floor_code ? `-${this.project?.floor_code} |` : ''
    }`;
    return `${this.project?.code || ''} ${this.project?.code ? '|' : ''} ${location}  ${this.project?.title || ''} `;
  }

  public formatDate(date) {
    return moment(date).format('MMMM D YYYY');
  }

  public async openUserProfilePreview(userId) {
    await this.modalService.openUserProfileDialog(userId).toPromise();
  }

  public async submitApproval({ approval, comment, files, reviewChain }) {
    if (approval) {
      const task = this.keyControls[this.currentIndex].task;
      const isApproved = approval === TaskReviewStatus.Approved;

      if (!isApproved) {
        task.status_id = TaskStatus.Open;
      }

      await this.updateReviewerService.addReviewer(task, approval, reviewChain);
      await this.updateReviewerService.createTaskApprovalActivity(task, approval, comment, files);
    } else {
      this.close();
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
