<button
  mat-button
  color="accent"
  (click)="viewTask()"
  class="border border-ltgray bg-white d-flex align-items-center {{ fullWidth ? 'full-width' : '' }}"
  style="line-height: {{ lineHeight }}px"
>
  <app-profile-thumbnail
    *ngIf="userId && taskId"
    class="mr-2"
    [border]
    [width]="24"
    [height]="24"
    [userId]="userId"
  ></app-profile-thumbnail>

  <mat-icon class="status-icon red mr-2" *ngIf="!userId && taskIds?.length && statusId === 0">cancel</mat-icon>
  <mat-icon class="status-icon gray mr-2" *ngIf="!userId && taskIds?.length && statusId === 1">pending</mat-icon>
  <mat-icon class="status-icon red mr-2" *ngIf="!userId && taskIds?.length && statusId === 2">flag</mat-icon>
  <mat-icon class="status-icon green mr-2" *ngIf="!userId && taskIds?.length && statusId === 3">check</mat-icon>
  <!-- <mat-icon *ngIf="statusId === 3" class="task-status green mr-2">check_circle</mat-icon> -->

  <!-- <span>Task {{ taskId }}</span> -->
  <!-- TESTING NEW DESIGN - ADAM - 3/15/23 -->
  <span>{{ title }}</span>

  <span
    class="top-right-badge"
    [ngClass]="{
      'bg-gray': statusId === 1,
      'bg-red': statusId === 0 || statusId === 2,
      'bg-green': statusId === 3
    }"
  >
    <mat-icon *ngIf="statusId === 0">clear</mat-icon>
    <mat-icon *ngIf="statusId === 1">more_horiz</mat-icon>
    <mat-icon *ngIf="statusId === 2">flag</mat-icon>
    <mat-icon *ngIf="statusId === 3">check</mat-icon>
  </span>
</button>
