<div id="suite" data-test-id="suite" class="suite">
  <h6 *ngIf="showLabel" class="dkblue font-weight-semibold mb-2">Suite<span *ngIf="required">*</span></h6>
  <mat-form-field appearance="outline" class="full-width">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Suite"
      matInput
      [matAutocomplete]="suite_auto"
      [formControl]="suite"
      (blur)="checkValue()"
    />
    <mat-icon *ngIf="suite?.value" matSuffix class="gray pointer" (click)="clearInput($event)">cancel</mat-icon>
    <mat-error *ngIf="suite?.errors?.required">A suite is required</mat-error>
    <mat-error *ngIf="suite?.errors?.doesNotExist">The suite does not exist</mat-error>
    <mat-autocomplete #suite_auto="matAutocomplete" autoActiveFirstOption="true" [displayWith]="mapper">
      <mat-option *ngFor="let suite of filteredSuites | async" [value]="suite">
        {{ suite?.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
