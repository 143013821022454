import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountReviewDialogComponent } from 'src/app/components';
import { AuthService, ModalService, UserService } from 'src/app/services';
import { APIFilter, UserRequest } from 'src/app/types';

@Component({
  selector: 'app-account-review-list',
  templateUrl: './account-review-list.component.html',
  styleUrls: ['./account-review-list.component.scss'],
})
export class AccountReviewListComponent implements OnInit {
  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private accountReviewDialog: MatDialog,
    private authService: AuthService
  ) {}

  get isAppAdmin() {
    return this.authService.isAppAdmin;
  }

  userTypes: { id: number; name: string }[] = [];
  userRequests: UserRequest[] = [];
  filteredUserRequests: UserRequest[] = [];
  userRequestFields = [
    'id',
    'type_id',
    'status_id',
    'type_name',
    'first_name',
    'last_name',
    'full_name',
    'email',
    'cell_phone',
    'office_phone',
    'company_name',
    'department_name',
    'title',
    'manager_name',
    'building_id',
    'floor_id',
    'suite_id',
    'department_id',
    'created_datetime',
    'default_module_id',
  ];
  selectedUserType = 0;
  searchTerm: string;

  ngOnInit() {
    this.refresh();
  }

  async refresh() {
    this.userTypes = await this.userService.getUserTypes().toPromise();
    const userRequestFilters: APIFilter[] = [{ type: 'field', field: 'status_id', value: '1^2' }];
    if (this.selectedUserType) {
      userRequestFilters.push({ type: 'operator', value: 'AND' });
      userRequestFilters.push({
        type: 'field',
        field: 'type_id',
        value: this.selectedUserType.toString(),
      });
    }
    this.userRequests = await this.userService.getUserRequests(this.userRequestFields, userRequestFilters).toPromise();
    this.filterUserRequests();
  }

  filterUserRequests() {
    if (this.searchTerm) {
      this.filteredUserRequests = this.userRequests.filter(
        (r) =>
          (r.full_name && r.full_name.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.email && r.email.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.cell_phone && r.cell_phone.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.office_phone && r.office_phone.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.company_name && r.company_name.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.department_name && r.department_name.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (r.title && r.title.toString().toLowerCase().includes(this.searchTerm.toLowerCase()))
      );
    } else {
      this.filteredUserRequests = this.userRequests;
    }
  }

  openAccountReview(userRequest: UserRequest) {
    const accountReviewDialog = this.accountReviewDialog.open(AccountReviewDialogComponent, {
      data: { ...userRequest, rejectRequest: this.rejectRequest },
    });

    accountReviewDialog.afterClosed().subscribe(async (result) => {
      this.refresh();
    });
  }

  async rejectRequest(userRequest: UserRequest) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reject Request',
        descriptionText: 'Are you sure you want to reject this account request?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.userService.updateUserRequest(userRequest.id, { status_id: 4 }).toPromise();
          this.refresh();
        }
      });
  }
}
