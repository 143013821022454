import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { intersection, isEmpty, xor } from 'lodash';
import { FileRenameDialogComponent } from 'src/app/components';
import { ResourceType } from 'src/app/enums';
import { AuthService, FileService } from 'src/app/services';
import { Tag, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-file-chip',
  templateUrl: './file-chip.component.html',
  styleUrls: ['./file-chip.component.scss'],
})
export class FileChipComponent implements OnChanges {
  @Input() file: UhatFileReference | any;

  @Input() showName = true;

  @Input() removable: boolean;

  @Input() downloadable = true;

  @Input() maxNameLength = 35;
  @Input() useParentPreview = false;
  @Input() isSelected = false;

  @Output() removeEvent = new EventEmitter<UhatFileReference>();
  @Output() parentPreviewFile = new EventEmitter<UhatFileReference>();

  downloading = false;
  public isComplete = true;

  // this is true if the file has no id, which means it was just uploaded (but not to the back end)
  public inMemoryFile = false;

  get sortedTags() {
    return this.file.tags;
  }

  // This sorts each files tags in order to have
  // parent Tag => children tags, for each parent tag, so they display correctly
  sortTags(tags: Tag[]): Tag[] {
    const _tags = [];
    if (!tags) {
      return [];
    }
    const parentTags = tags.filter((t) => +t.tag_parent_id === 0);
    parentTags.forEach((t) => {
      _tags.push(t);
      _tags.push(...tags.filter((childTag) => +childTag.tag_parent_id === +t.id));
    });
    return _tags;
  }

  constructor(
    public appFileService: FileService,
    private dialog: MatDialog,
    private snackbarService: MatSnackBar,
    public authService: AuthService
  ) {}

  async ngOnChanges() {
    if (!this.file.name) {
      this.file = await this.appFileService.getIdAndName(this.file.id).toPromise();
    }

    if (!this.file?.extension && this.file?.name) {
      this.file.extension = this.file.name.substr(this.file.name.lastIndexOf('.') + 1, this.file.name.length - 1);
    }

    const id = this.file.file_id || this.file.id;
    if (id) {
      // if we don't have the current tags, we need to get them to pass in
      if (!this.file.tags) {
        const file = await this.appFileService.getTagsForFile(id).toPromise();
        this.file.tags = file.tags ? file.tags : [];
      }
      // const parents = await this.appFileService.getParentsForFiles([id]).toPromise();
      // this.addParentTags(id, this.file, parents);
      this.file.tags = this.sortTags(this.file.tags);
    } else {
      this.file.tags = [];
      this.inMemoryFile = true;
    }
  }

  // Update 6/8/20. This is being reworked to have the tags attached to the file in the database.
  // Before, these tags were added based on the file_parent_bridge,
  // but due to needing to add/remove these on adding/removing file in places, this is no longer possible
  // As such, these are now added directly to the file tags when applicable
  // the possible parents are Addendum, RFI, PR, Change Order, As-Built, Invoice
  private addParentTags(id, file, parents) {
    // get all unique parent ids for that file id
    const uniqueParentIds = Array.from(
      new Set(parents.filter((p) => +p.file_id === +id).map((p) => +p.parent_type_id))
    );
    const parentTagIds = [
      ResourceType.Addendum,
      ResourceType.RFI,
      ResourceType.ProposalRequest,
      ResourceType.ChangeOrder,
      ResourceType.AsBuilt,
      ResourceType.Invoice,
    ];
    if (intersection(uniqueParentIds, parentTagIds).length > 0) {
      if (uniqueParentIds.includes(ResourceType.Addendum)) {
        file.tags.push({
          id: 1,
          name: 'Addendum',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
      if (uniqueParentIds.includes(ResourceType.RFI)) {
        file.tags.push({
          id: 2,
          name: 'RFI',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
      if (uniqueParentIds.includes(ResourceType.ProposalRequest)) {
        file.tags.push({
          id: 3,
          name: 'PR',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
      if (uniqueParentIds.includes(ResourceType.ChangeOrder)) {
        file.tags.push({
          id: 4,
          name: 'Change Order',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
      if (uniqueParentIds.includes(ResourceType.AsBuilt)) {
        file.tags.push({
          id: 5,
          name: 'As-Built',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
      if (uniqueParentIds.includes(ResourceType.Invoice)) {
        file.tags.push({
          id: 6,
          name: 'Invoice',
          tag_parent_id: 0,
          is_system_generated: true,
        });
      }
    }
  }

  remove() {
    this.removeEvent.emit(this.file);
  }

  async download() {
    if (!this.downloadable) {
      return;
    }

    if (!this.inMemoryFile) {
      this.downloading = true;
      await this.appFileService.download(this.file);
      this.downloading = false;
    } else {
      saveAs(this.file as File);
      this.snackbarService.open(`${this.file.name} has been downloaded`);
    }
  }

  public async preview(file) {
    if (this.useParentPreview) {
      this.parentPreviewFile.emit(file);
    } else {
      this.isComplete = false;
      await this.appFileService.previewFile(file);
      this.isComplete = true;
    }
  }

  getShortenedFileName() {
    if (!this.file.name) {
      return 'Name Unknown.unk';
    }
    if (this.file.name.length > this.maxNameLength) {
      return this.file.name.substring(0, this.maxNameLength) + '..';
    } else {
      return this.file.name;
    }
  }

  async renameFile() {
    // in order to rename a file, we need to pass in the possible tags to choose from
    const moduleIds = await this.appFileService.getFileParentsModules(this.file.file_id || this.file.id).toPromise();
    const allTags = await this.appFileService.getTags(moduleIds).toPromise();
    const dialogRef = this.dialog.open(FileRenameDialogComponent, {
      width: '480px',
      data: {
        file: this.file,
        allTags,
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
      if (event) {
        // if the name isn't the same, or the tag array ids have switched, then update the file
        if (
          this.file.name !== event.name ||
          !isEmpty(
            xor(
              this.file.tags.map((t) => +t.id),
              event.tags.map((t) => +t.id)
            )
          )
        ) {
          const tag_ids = `[${event.tags.map((t) => +t.id).join(',')}]`;
          const updateFile = {
            name: event.name,
            tag_ids,
          };
          this.file.name = updateFile.name;
          this.file.tag_ids = event.tags;
          this.appFileService.updateFileFields(this.file.file_id || this.file.id, updateFile).subscribe();
          this.file.name = event.name;
          this.file.tags = event.tags ? event.tags : [];
        }
      }
    });
  }
}
