import { ProjectProgramData } from 'src/app/workspaces/construction/models';

/**
 * Data model that is returned from the backend server.
 */
export class ProjectProgramDataDbModel {
  public id: number;
  public project_id: number;
  public is_new_space: boolean;
  public is_clinical_space: boolean;
  public contains_biohazard_materials: boolean;
  public needs_healthcare_supplies_install: boolean;
  public requires_emergency_eyewash: boolean;
  public requires_emergency_power: boolean;
  public requires_access_controls: boolean;
  public requires_digital_signage: boolean;
  public requires_other_signage: boolean;
  public requires_accent_walls: boolean;
  public requires_structural_engineer: boolean;
  public are_strobes_affected: boolean;
  public is_flooring_to_be_replaced: boolean;
  public are_sprinkler_heads_affected: boolean;
  public contains_asbestos: boolean;
  public contains_asbestos_note: string;
  public requires_structural_engineer_note: string;
  public requires_emergency_power_note: string;
  public requires_access_controls_note: string;
  public requires_digital_signage_note: string;
  public requires_other_signage_note: string;
  public requires_accent_walls_note: string;
  public contains_biohazard_materials_note: string;
  public needs_healthcare_supplies_note: string;
  public requires_emergency_eyewash_note: string;
  public attendees: string;
  public technology_checkboxes: string;
  public technology_note = '';
  public door_type_checkboxes: string;
  public door_type_note = '';
  public required_items: string;
  public technology_locations: string;
  public flooring_replacement_extent_note: string;

  public static fromClientObjectModel(programData: ProjectProgramData): ProjectProgramDataDbModel {
    const dbModel = new ProjectProgramDataDbModel();
    dbModel.id = programData.id;
    dbModel.project_id = programData.projectId;
    dbModel.is_clinical_space = programData.isClinicalSpace;
    dbModel.is_new_space = programData.isNewSpace;
    dbModel.contains_biohazard_materials = programData.containsBiohazardMaterials;
    dbModel.contains_asbestos = programData.containsAsbestos;
    dbModel.needs_healthcare_supplies_install = programData.healthcareSuppliesNeedInstall;
    dbModel.requires_emergency_eyewash = programData.requiresEmergencyEyewash;
    dbModel.requires_emergency_power = programData.requiresEmergencyPower;
    dbModel.requires_access_controls = programData.requiresAccessControls;
    dbModel.requires_digital_signage = programData.requiresDigitalSignage;
    dbModel.requires_other_signage = programData.requiresOtherSignage;
    dbModel.requires_accent_walls = programData.requiresAccentWalls;
    dbModel.are_strobes_affected = programData.areStrobesAffected;
    dbModel.are_sprinkler_heads_affected = programData.areSprinkerHeadsAffected;
    dbModel.is_flooring_to_be_replaced = programData.isFlooringToBeReplaced;
    dbModel.contains_asbestos = programData.containsAsbestos;
    dbModel.requires_structural_engineer = programData.requiresStructuralEngineer;
    dbModel.required_items = programData.requiredItemsList.join(',');
    dbModel.technology_locations = programData.technologyLocationsList.join(',');
    dbModel.attendees = programData.attendeesList.join(',');

    const checkedItems = programData.technologyCheckboxItems.filter((item) => item.checked);
    dbModel.technology_checkboxes = checkedItems.map((item) => item.label).join(',');
    dbModel.technology_note = programData.technologyNoteField;

    const checkedDoorTypes = programData.doorTypeCheckboxItems.filter((item) => item.checked);
    dbModel.door_type_checkboxes = checkedDoorTypes.map((item) => item.label).join(',');
    dbModel.door_type_note = programData.doorTypeNoteField;

    // Notes
    dbModel.contains_asbestos_note = programData.containsAsbestosNote;
    dbModel.requires_structural_engineer_note = programData.requiresStructuralEngineerNote;
    dbModel.requires_emergency_power_note = programData.requiresEmergencyEyewashNote;
    dbModel.requires_access_controls_note = programData.requiresAccessControlsNote;
    dbModel.requires_digital_signage_note = programData.requiresDigitalSignageNote;
    dbModel.requires_other_signage_note = programData.requiresOtherSignageNote;
    dbModel.requires_accent_walls_note = programData.requiresAccentWallsNote;
    dbModel.contains_biohazard_materials_note = programData.containsBiohazardMaterialsNote;
    dbModel.needs_healthcare_supplies_note = programData.needsHealthcareSuppliesNote;
    dbModel.requires_emergency_eyewash_note = programData.requiresEmergencyEyewashNote;
    dbModel.flooring_replacement_extent_note = programData.flooringReplacementExtentNote;

    return dbModel;
  }
}
