import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { APIFilter, ResourceTag, ServiceResponse } from '../types';
import { ApiFilterService } from './api-filter.service';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceTagsService {
  host: string = environment.serviceHost;
  resourceTagsUrl = `${this.host}/api/v1/resource-tags`;
  resourceTagFields = ['id', 'name', 'abbreviation'];

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  getResourceTags(apiFilters: APIFilter[], fields?: string[]): Observable<ResourceTag[]> {
    fields = fields || this.resourceTagFields;
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.resourceTagsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const resourceTags: ResourceTag[] = result.data.resource_tags;
        return resourceTags;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createResourceTag(resourceTag: ResourceTag, fields: string[]) {
    return this.http.post(`${this.resourceTagsUrl}?fields=${fields.join(',')}`, resourceTag).pipe(
      map((result: ServiceResponse) => {
        const resourceTagToReturn: ResourceTag = result.data['resource tags'];
        return resourceTagToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
