export enum NotificationType {
  AssignedProject = 1,
  ProjectPhaseChanged = 2,
  ProjectStatusChangedToActive = 3,
  ProjectStatusChangedToOnHold = 4,
  ProjectStatusChangedToClosed = 5,
  AssignedTask = 6,
  TaskStatusChangedToToDo = 7,
  TaskStatusChangedToOnHold = 8,
  TaskStatusChangedToPending = 9,
  TaskStatusChangedToComplete = 10,
  TaskStatusChangedToRejected = 11,
  Reminder = 12,
  Awarded61Bid = 13,
  ApprovedChangeOrder = 14,
  CreatedTaskNote = 15,
  Processed61Invoice = 16,
  Rejected61Invoice = 17,
  CreatedRFI = 18,
  AssignedRFI = 19,
  ClosedRFI = 20,
  WorkOrderStatusChangedToActive = 21,
  WorkOrderStatusChangedToOnHold = 22,
  WorkOrderStatusChangedToClosed = 23,
  WorkOrderStatusChangedToReadyForPickup = 24,
  CreatedWorkOrderUpdate = 25,
  AddedAsMeetingAttendee = 26,
  Awarded74Bid = 27,
  Processed74Invoice = 28,
  Rejected74Invoice = 29,
  CreatedProjectUpdate = 30,
  Deleted61Invoice = 31,
  Deleted74Invoice = 32,
  AssignedWorkOrder = 33,
  TaskAssignedToCeo = 35,
  TaskReminderSent = 36,
  ArfCostCodeUpdated = 37,
  ProjectCostCodeUpdated = 38,
  RequestReceived = 39,
  RequestRejected = 40,
  RequestApproved = 41,
  RequestConverted = 42,
  WorkOrderConverted = 43,
  RequestConvertedToWO = 44,
  UpdatedRFI = 45,
}
