<div
  *ngIf="accessoryData?.parentId && accessoryData?.type === TaskAccessoryType.Invoice"
  class="d-flex flex-column mb-5"
>
  <h6 class="dkblue font-weight-semibold mb-2">Invoice</h6>
  <div class="d-flex task-accessory br-10 mb-0">
    <div class="d-flex flex-column p-3 full-width">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column text-truncate mr-3">
          <p class="mb-0 dkblue font-weight-semibold lh-20 text-truncate">
            {{ invoice?.company_name }} | {{ invoice?.quote?.description }}
          </p>
          <p class="mb-0 dkblue font-weight-normal lh-20 text-truncate">
            {{ invoice?.title }}
          </p>
        </div>
        <h2 class="m-0 dkblue font-weight-semibold ml-auto">
          {{ +invoice?.total - +invoice?.retainage + +invoice?.shipping + +invoice?.tax || 0 | USDollar: 2:true:true }}
        </h2>
      </div>
      <div class="d-flex align-items-end">
        <div class="d-flex align-items-center mt-3">
          <h6 *ngIf="invoice?.trade_name" class="px-2 br-4 lh-22 white mb-0 mr-2 bg-ltblue">
            {{ invoice?.trade_name }}
          </h6>
          <h6 *ngIf="!invoice?.change_order_id" class="px-2 br-4 lh-22 white mb-0 text-nowrap bg-ltblue">
            Original Bid
          </h6>
          <h6 *ngIf="invoice?.change_order_id" class="px-2 br-4 lh-22 white mb-0 text-nowrap bg-ltblue">
            {{ invoice?.change_order_code || 'Not Found' }}
          </h6>
        </div>
        <div *ngIf="invoice?.status_id && accessoryData" class="d-flex ml-auto">
          <app-invoice-review-buttons
            [invoice]="invoice"
            [disableReview]="true"
            [display]="true"
          ></app-invoice-review-buttons>
        </div>
      </div>
    </div>
  </div>
</div>
