import { Pipe, PipeTransform } from '@angular/core';
import Fuse from 'fuse.js';
import { User } from 'src/app/types';
import { DirectoryUserType } from '../enums';

@Pipe({
  name: 'directoryUsersFilter',
})
export class DirectoryUsersFilterPipe implements PipeTransform {
  transform(
    users: User[],
    userType: DirectoryUserType,
    searchString: string,
    isAR: boolean,
    filteredCount: { count: number }
  ): User[] {
    if (!users) {
      return users;
    }

    if (userType && userType === DirectoryUserType.Guest) {
      users = users.filter((user) => user.is_guest_user === 1);
    } else if (userType) {
      users = users.filter((user) => user.user_type_id === userType && user.is_guest_user === 0);
    }

    if (searchString) {
      const fuse = new Fuse<User>(users, {
        keys: [
          { name: 'full_name', weight: 2 },
          { name: 'company_name', weight: 1 },
          { name: 'department_name', weight: 1 },
          { name: 'building_name', weight: 1 },
          { name: 'floor_name', weight: 1 },
          { name: 'suite_name', weight: 1 },
          { name: 'cell_phone', weight: 1 },
          { name: 'title', weight: 1 },
          { name: 'email', weight: 1 },
        ],
        minMatchCharLength: 1,
        threshold: 0.5,
        fieldNormWeight: 1,
      });
      users = fuse.search(searchString).map((fuseUser) => fuseUser.item);
    }

    if (isAR) {
      users = users.filter((user) => user.is_ar);
    }
    filteredCount.count = users.length;
    return users;
  }
}
