import { Pipe, PipeTransform } from '@angular/core';
import { CostCode } from 'src/app/types';

@Pipe({
  name: 'filterCostCodesByBudgetIsEnabled',
})
export class FilterCostCodesByBudgetIsEnabledPipe implements PipeTransform {
  transform(costCodes: CostCode[], fiscalYear: number): CostCode[] {
    const filteredCostCodes: CostCode[] = [];
    for (const costCode of costCodes) {
      const filteredSubCostCodes = costCode.sub_cost_codes.filter(
        (subCostCode) =>
          (fiscalYear ? subCostCode.fiscal_year === +fiscalYear : true) && subCostCode.sub_cost_code_budget?.is_enabled
      );

      if (filteredSubCostCodes.length) {
        filteredCostCodes.push({ ...costCode, sub_cost_codes: filteredSubCostCodes });
      }
    }
    return filteredCostCodes;
  }
}
