<!--spinner-->
<div mat-dialog-content id="file-preview" data-test-id="file-preview" class="file-preview">
  <div *ngIf="loading" class="my-5 d-flex justify-content-center align-items-center">
    <mat-progress-spinner
      matSuffix
      mode="indeterminate"
      diameter="50"
      class="p-5"
      color="accent"
    ></mat-progress-spinner>
  </div>
</div>
<!--image-->
<div *ngIf="imageUrl && !loading" class="d-flex full-width mb-4 image-preview">
  <img class="full-width" [src]="imageUrl" />
</div>
<!--pdf or docx-->
<div *ngIf="pdfSrc && !loading" class="d-flex full-width mb-4 pdf-preview">
  <iframe *ngIf="pdfSrc" [src]="safePdfUrl" frameborder="0" height="100%" width="100%"> </iframe>
</div>
<!--csv-->
<div class="row mt-2" *ngFor="let row of csvRows">
  <div class="col d-flex justfy-content-" *ngFor="let cell of row">
    {{ cell }}
  </div>
</div>
<!--excel-->
<div *ngIf="excel && !loading" class="row mt-2 excel-preview">
  <iframe *ngIf="excel" [src]="excel" frameborder="0" height="100%" width="100%"> </iframe>
</div>
<!--txt-->
<div *ngIf="txt && !loading" class="row mt-2">
  <div class="d-flex">
    <div class="col" [innerHTML]="txt"></div>
  </div>
</div>
<!--no preview-->
<div *ngIf="file && !imageUrl && !pdfSrc && !excel && !csv && !docx && !txt && !loading" class="row flex-column py-5">
  <h3 class="d-flex justify-content-center align-items-center">No Preview Available</h3>
  <div class="d-flex justify-content-center align-items-center">Please download file to view.</div>
</div>
