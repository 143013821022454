import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Fuse from 'fuse.js';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { AuthService, UserService } from 'src/app/services';
import { User } from 'src/app/types';
import { UserType } from '../../enums';
import { NewAccountModalComponent } from '../new-account-modal/new-account-modal.component';

@Component({
  selector: 'app-search-user-input',
  templateUrl: './search-user-input.component.html',
  styleUrls: ['./search-user-input.component.scss'],
})
export class SearchUserInputComponent implements OnInit {
  @Input() arialLabel = 'Search user';
  @Input() assignment_option = false;
  @Input() assignToMeText = 'Assign To me';
  @Input() close_on_selection = false;
  @Input() disabled = false;
  @Input() height = 24;
  @Input() isARequestorUser = false;
  @Input() placeholder = 'Search user';
  @Input() selectedUserType: UserType = UserType.Staff;
  @Input() show_department = false;
  @Input() userTypes: UserType[] = [];
  @Input() width = 24;
  @Input() initialValueId: number | null = null;
  @Input() showAddAccount = false;
  @Input() fuzzySearch = false;
  @Output() selection = new EventEmitter();
  loading = false;

  private _separator = '|';
  private _users: User[] = [];

  public searchEntry = new FormControl('');

  public filteredUsers$: Observable<User[]>;

  constructor(
    private authService: AuthService,
    private _userService: UserService,
    private dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.searchEntry.disable();
    }

    void this.getUsers();

    this.filteredUsers$ = this.searchEntry.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map((searchEntry) => this.filterUsers(searchEntry))
    );
  }

  get hasPassedInUserTypes(): boolean {
    return this.userTypes.length > 0;
  }

  get selectStaff(): boolean {
    return this.selectedUserType === UserType.Staff;
  }

  get selectTenant(): boolean {
    return this.selectedUserType === UserType.Tenant;
  }

  get selectVendor(): boolean {
    return this.selectedUserType === UserType.Vendor;
  }

  get selectedUser(): User | null {
    return this.searchEntry?.value?.id ? (this.searchEntry?.value as User) : null;
  }

  get staff(): UserType {
    return UserType.Staff;
  }

  get tenant(): UserType {
    return UserType.Tenant;
  }

  get userTypeList(): UserType[] {
    return this.selectedUserType ? [this.selectedUserType] : [];
  }

  get vendor(): UserType {
    return UserType.Vendor;
  }

  handleAddAccount(): void {
    const dialogRef = this.dialog.open(NewAccountModalComponent, {
      width: '540px',
      disableClose: true,
      data: {
        userInfo: this.searchEntry.value,
      },
    });
    dialogRef.afterClosed().subscribe((createdUser: User) => {
      if (createdUser) {
        void this.getUsers();
      }
    });
  }

  private filterUsersByUserType(userTypes: UserType[] = []): User[] {
    if (userTypes.length > 0) {
      return this._users.filter((user) => userTypes.includes(+user.user_type_id));
    } else {
      return this._users;
    }
  }

  private async getUsers(): Promise<void> {
    const userFilter = [];

    if (this.initialValueId) {
      userFilter.push(
        { type: 'operator', value: '(' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'id', value: this.initialValueId },
        { type: 'operator', value: ')' }
      );
    } else {
      userFilter.push({ type: 'field', field: 'is_enabled', value: '1' });
    }

    this._users = (await this._userService.searchUsers(userFilter).toPromise()) || [];
  }

  private filterUsers(searchEntry: string | User): User[] {
    if (typeof searchEntry !== 'string') {
      return [];
    }

    const sanitizedSearchEntry = searchEntry.trim().toLowerCase();

    const userTypes = this.hasPassedInUserTypes ? this.userTypes : this.userTypeList;

    if (this.fuzzySearch) {
      const fuse = new Fuse(this.filterUsersByUserType(userTypes), {
        keys: [
          { name: 'full_name', weight: 2 },
          { name: 'email', weight: 1 },
        ],
        minMatchCharLength: 1,
        threshold: 0.5,
        fieldNormWeight: 1,
      });

      return fuse.search(sanitizedSearchEntry).map((fuseUser) => fuseUser.item);
    } else {
      return this.filterUsersByUserType(userTypes).filter((user) => {
        return (
          `${user.first_name} ${user.last_name}`.trim().toLowerCase().includes(sanitizedSearchEntry) ||
          user.last_name.trim().toLowerCase().includes(sanitizedSearchEntry) ||
          user.email.trim().toLowerCase().includes(sanitizedSearchEntry)
        );
      });
    }
  }

  public assignToMe(): void {
    this.searchEntry.setValue(this.authService.currentUser);
    this.selected(this.authService.currentUser);
  }

  public clearInput(): void {
    this.searchEntry.reset();
  }

  public selected(user: User): void {
    if (this.close_on_selection) {
      this.selection.emit(user);
    }
  }

  public toggleUserType(selectedUserType: UserType): void {
    this.selectedUserType = this.selectedUserType === selectedUserType ? null : selectedUserType;
  }

  public unAssign(): void {
    this.clearInput();
    if (this.close_on_selection) {
      this.selection.emit(null);
    }
  }

  public userMapper(user: User): string {
    if (user) {
      return `${user.first_name || ''} ${user.last_name || ''} ${
        this.show_department && (user.company_name || user.department_name) ? this._separator : ''
      } ${user.user_type_id === 3 ? user.company_name || '' : user.department_name || ''}`.trim();
    } else {
      return '';
    }
  }
}
