<div id="weekly-focus" data-test-id="weekly-focus" class="weekly-focus weeklyFocus container p-4">
  <section class="pt-4 mt-4 mb-3 d-flex align-items-start">
    <span class="d-flex flex-column mr-auto">
      <h1 class="primary mb-3">Weekly Focus</h1>
    </span>
    <span class="ml-4 d-flex align-items-center">
      <!-- SHOW IF EDITING CONTENT -->
      <mat-slide-toggle *ngIf="isEditing" [(ngModel)]="notify" color="success">
        <h5 class="m-0 dkgray">Notify of change</h5>
      </mat-slide-toggle>
      <button
        *ngIf="weeklyFocusPulsing && !isEditing"
        mat-flat-button
        color="success"
        type="button"
        class="bg-gold lh-30 px-3 ml-auto"
        (click)="muteWeeklyFocus()"
      >
        Mute To Next Week
      </button>
      <button *ngIf="isEditing" (click)="cancel()" mat-button type="button" class="dkgray ml-4">Cancel</button>
      <button *ngIf="isEditing" (click)="save()" mat-flat-button color="success" type="button" class="ml-4">
        Save
      </button>
      <!-- SHOW IF NOT EDITING -->
      <button
        *ngIf="isWeeklyFocusEditor && !isEditing"
        (click)="edit()"
        mat-stroked-button
        color="accent"
        type="button"
        class="bg-white ml-4"
      >
        Edit Content
      </button>
    </span>
  </section>
  <section class="d-flex flex-column mb-4">
    <p class="dkgray font-weight-normal lh-24 mb-0">
      In this weekly focus, we provide training topics covering our Creed, Continuous Quality Improvement (CQI), and 10
      Principles of Quality.
    </p>
    <p class="dkgray font-weight-normal lh-24 mb-0">
      <b>Let's make quality improvement a daily habit, 1% at a time!</b>
    </p>
  </section>
  <!--
  <section class="mb-5">
    <button mat-stroked-button color="accent" type="button" class="bg-white mr-3">+ Suggest CQI</button>
    <button mat-stroked-button color="accent" type="button" class="bg-white mr-3">UHAT Creed</button>
    <button mat-stroked-button color="accent" type="button" class="bg-white mr-3">CQI Docs</button>
    <button mat-stroked-button color="accent" type="button" class="bg-white mr-3">10 Principles</button>
  </section>
  -->
  <section>
    <div class="m-portlet p-4">
      <div class="d-flex align-items-top">
        <span class="d-flex flex-column mr-auto">
          <h3 class="primary">Creed Focus</h3>
          <p class="dkgray">
            We are committed to setting a standard of excellence and view ourselves as champions of these facilities
            with an aim to deliver excellent service, high performance, great communication and outstanding value.
          </p>
        </span>
      </div>
      <div *ngIf="!isEditing" class="p-4 bg-shade-gold br-10">
        <div class="creed-content gold" [innerHTML]="weeklyFocus?.creedFocus"></div>
      </div>
      <div [hidden]="!isEditing" class="p-2 br-10">
        <app-editor class_height="medium" #creedEditor></app-editor>
      </div>
    </div>
  </section>
  <section>
    <div class="m-portlet p-4">
      <div class="d-flex align-items-top">
        <span class="d-flex flex-column mr-auto">
          <h3 class="primary">10 Principles of Quality</h3>
          <p class="dkgray">
            Using our 10 Principles of Quality, we can create a culture of continuous improvement that fosters a
            commitment to quality and excellence, and leads to better performance and customer satisfaction over time.
          </p>
        </span>
      </div>
      <div *ngIf="!isEditing" class="p-4 bg-shade-gold br-10">
        <div class="creed-content gold" [innerHTML]="weeklyFocus?.principleOfQuality"></div>
      </div>
      <div [hidden]="!isEditing" class="p-2 br-10">
        <app-editor class_height="medium" #principleQualityEditor></app-editor>
      </div>
    </div>
  </section>
  <section>
    <div class="m-portlet p-4">
      <div class="d-flex align-items-top">
        <span class="d-flex flex-column mr-auto">
          <h3 class="primary">CQI Training</h3>
          <p class="dkgray">
            Continuous Quality Improvement consists of daily habits that meet and exceed expectations, resulting in
            making something better or more effective.
          </p>
        </span>
      </div>
      <div *ngIf="!isEditing" class="p-4 bg-shade-gold br-10">
        <div class="creed-content gold" [innerHTML]="weeklyFocus?.cqiTraining"></div>
      </div>
      <div [hidden]="!isEditing" class="p-2 br-10">
        <app-editor class_height="medium" #cqiEditor></app-editor>
      </div>
    </div>
  </section>
</div>
