<app-base-dialog title="Meeting {{ meeting?.code }}" (closeDialog)="close()"></app-base-dialog>
<div
  id="view-meeting-dialog"
  data-test-id="view-meeting-dialog"
  #mainScreen
  class="view-meeting-dialog d-flex flex-column project-modal p-0"
>
  <h3 class="dkblue mb-3 mt-3">
    {{ meeting?.title }}
  </h3>
  <h5 class="dkgray m-0 font-weight-normal mb-1">{{ startDate }}</h5>
  <h5 class="dkgray m-0 font-weight-normal mb-5">{{ startTime }} - {{ endTime }}</h5>

  <span class="d-flex flex-column mb-3">
    <h6 class="dkblue mb-2">Objective</h6>
    <h5 class="dkgray font-weight-normal mb-0 lh-24" [innerHTML]="meeting?.purpose || ''"></h5>
  </span>
  <span *ngIf="meeting?.recurring_meeting_frequency_interval" class="d-flex flex-column mb-4">
    <h6 class="dkblue mb-2">Repeat</h6>
    <h5 class="dkgray m-0 font-weight-normal">
      Every{{
        meeting.recurring_meeting_frequency_number !== 1 ? ' ' + meeting.recurring_meeting_frequency_number : ''
      }}
      {{ meeting.recurring_meeting_frequency_interval | titlecase
      }}{{ meeting.recurring_meeting_frequency_number !== 1 ? 's' : '' }}
    </h5>
  </span>
  <span class="d-flex flex-column mb-4">
    <h6 class="dkblue mb-2">Location</h6>
    <h5 class="dkgray m-0 font-weight-normal">{{ meeting?.location }}</h5>
  </span>
  <span class="d-flex flex-column mb-4">
    <h6 class="dkblue mb-2">Attendees:</h6>
    <div class="meeting-attendees d-flex align-items-center flex-wrap">
      <app-profile-thumbnail
        *ngFor="let attendee of attendees"
        [width]="36"
        [height]="36"
        [userId]="attendee.user_id"
        class="mr-2 mb-2 pointer"
        [matTooltip]="attendee.first_name + ' ' + attendee.last_name"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        [overlayIconOpacity]="1"
        [overlayIcon]="attendee.attendee_type_id === 1 ? 'star' : ''"
        [overlayIconColor]="'white'"
        [overlayIconBackground]="'bg-gray'"
      >
      </app-profile-thumbnail>
    </div>
  </span>
  <div mat-dialog-actions class="row pb-4 pt-5 align-items-center">
    <div class="col-6">
      <button
        data-test-id="view-meeting-dialog-cancel-button"
        mat-button
        class="gray full-width"
        type="button"
        (click)="close()"
      >
        Dismiss
      </button>
    </div>
    <div class="col-6">
      <button
        mat-flat-button
        class="full-width"
        color="success"
        type="button"
        [routerLink]="['/meetings/' + meetingId]"
        (click)="close()"
      >
        View Meeting
      </button>
    </div>
  </div>
</div>
