import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map } from 'rxjs/operators';
import { ResourceType, TAG_IDS, UserType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LinkedTaskService,
  ProgressIndicatorService,
  ProjectService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import {
  FileAttachmentDialogInjectionData,
  HealthType,
  LinkedWOTask,
  UhatFileReference,
  User,
  WorkOrderUpdate,
} from 'src/app/types';
import { FileAttachmentDialogComponent } from '..';
import { UhatFileBridgeLink, VerifiedFile } from '../../models';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'app-work-order-update-dialog',
  templateUrl: './work-order-update-dialog.component.html',
  styleUrls: ['./work-order-update-dialog.component.scss'],
})
export class WorkOrderUpdateDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  private _filesToDelete: UhatFileReference[] = [];
  private _filesToUnlink: UhatFileReference[] = [];
  private _workOrderHealthTypesFields = ['id', 'name'];
  private _workOrderSubmissionFields = ['message', 'notify_followers', 'work_order_health_type_id'];
  private _workOrderUsersToNotify: User[] = [];

  public dialogTitle = 'Add Update';
  public auto_focus = true;
  public existingFiles: UhatFileReference[] = [];
  public isLoading = false;
  public isUpdating = false;
  public newFiles: (File | VerifiedFile)[] = [];
  public linkedProjectFiles: UhatFileReference[] = [];
  public workOrderHealthTypes: HealthType[] = [];
  public workOrderHealthTypeId: number;
  public workOrderId: number;
  public workOrderUpdateId: number;
  public workOrderUpdateFormGroup: FormGroup = this._fb.group({
    everyoneVisibility: [1],
    notify_followers: [1],
  });
  private linkedWorkOrderTask?: LinkedWOTask;
  private existingProjectFileIds: number[] = [];
  public loadingLinkedWorkOrderTask: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _dialogRef: MatDialogRef<WorkOrderUpdateDialogComponent>,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _fileService: FileService,
    private _progressIndicatorService: ProgressIndicatorService,
    private _snackbar: MatSnackBar,
    private _userService: UserService,
    private _workOrderService: WorkOrderService,
    public authService: AuthService,
    private projectService: ProjectService,
    private linkedTaskService: LinkedTaskService
  ) {}

  async ngOnInit(): Promise<any> {
    this._activateEditor();
    this.workOrderHealthTypes = await this._workOrderService
      .getWorkOrderHealthTypes(this._workOrderHealthTypesFields)
      .toPromise();
    if (this.workOrderHealthTypes.length > 0) {
      // insert in a not set
      this.workOrderHealthTypes = [{ id: null, name: 'Not Set' }, ...this.workOrderHealthTypes];
    }

    this.workOrderId = this._data?.work_order_id;

    if (this?._data?.update) {
      this.isUpdating = true;
      this.dialogTitle = 'Edit Work Order Update';
      this.workOrderHealthTypeId = this._data?.work_order_health_type_id;
      this.workOrderUpdateId = this._data?.id;
      this.message.setValue(this._data?.message || '');

      // get files
      this.existingFiles = await this._fileService
        .getFilesByParentId(ResourceType.WorkOrderUpdate, this._data?.id)
        .toPromise();
    } else {
      this.workOrderHealthTypeId = null;
    }

    // only do this on new updates
    if (!this.workOrderUpdateId || (this.workOrderUpdateId && this.notify_followers.value)) {
      // follower_ids, assignee, requestor
      const workOrderUserFieldIds = ['assigned_user_id', 'requester_id'];
      const workOrderUserIds = [];
      const workOrder = await this._workOrderService
        .getWorkOrderById(this.workOrderId, [...workOrderUserFieldIds, 'follower_ids'])
        .toPromise();

      for (const key of workOrderUserFieldIds) {
        if (!workOrderUserIds.includes(Number(workOrder[key]))) {
          workOrderUserIds.push(Number(workOrder[key]));
        }
      }

      for (const followerId of JSON.parse(workOrder?.follower_ids as string)) {
        if (!workOrderUserIds.includes(Number(followerId))) {
          workOrderUserIds.push(Number(followerId));
        }
      }

      // guest ignored
      this._workOrderUsersToNotify = await this._userService
        .getUsers(
          [{ type: 'field', field: 'id', value: `${workOrderUserIds?.join('^')}` }],
          ['id', 'first_name', 'last_name', 'user_type_id']
        )
        .toPromise();
    }

    if (!this.projectService.currentSelectedProject?.id) {
      this.linkedWorkOrderTask = await this.linkedTaskService.getLinkedWOTaskForWorkOrder(this.workOrderId).toPromise();
      this.loadingLinkedWorkOrderTask = false;
    } else {
      this.loadingLinkedWorkOrderTask = false;
    }

    if (this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id) {
      this.existingProjectFileIds = (
        await this._fileService
          .getFilesByParentId(
            ResourceType.Project,
            this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id
          )
          .toPromise()
      ).map((file: UhatFileReference) => file.file_id);
    }
  }

  get everyoneVisibility(): AbstractControl {
    return this.workOrderUpdateFormGroup?.get('everyoneVisibility');
  }

  get message(): AbstractControl {
    return this.workOrderUpdateFormGroup?.get('message');
  }

  get notify_followers(): AbstractControl {
    return this.workOrderUpdateFormGroup?.get('notify_followers');
  }

  get workOrderUsersToNotify() {
    if (!this?.notify_followers.value) {
      return [];
    }

    // checks for access
    if (this.everyoneVisibility?.value) {
      return this._workOrderUsersToNotify.filter(
        (workOrderUser: User) => workOrderUser.user_type_id > 0 && workOrderUser.id !== this.authService.currentUser?.id
      );
    } else {
      // staff only
      return this._workOrderUsersToNotify.filter(
        (workOrderUser: User) =>
          workOrderUser.user_type_id === UserType.Staff && workOrderUser.id !== this.authService.currentUser?.id
      );
    }
  }

  get validForSubmission(): boolean {
    return this.workOrderUpdateFormGroup.valid;
  }

  get workOrderUpdate(): WorkOrderUpdate {
    return this._data;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  private _accessHelper() {
    if (this.authService.currentUser.user_type_id === UserType.Staff && this.everyoneVisibility.value) {
      return JSON.stringify([UserType.Staff, UserType.Tenant, UserType.Vendor]);
    }

    if (this.authService.currentUser.user_type_id === UserType.Tenant) {
      return JSON.stringify([UserType.Staff, UserType.Tenant]);
    }

    return JSON.stringify([UserType.Staff]);
  }

  private async _activateEditor() {
    this.workOrderUpdateFormGroup.addControl('message', this._editor_component.content);
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
    this.isLoading = false;
  }

  private async _handleFiles(workOrderUpdateId) {
    const workOrderFiles: UhatFileReference[] = await this._fileService
      .getFilesByParentId(ResourceType.WorkOrder, this.workOrderId)
      .toPromise();

    const addedFiles = [];

    // create files
    for (const file of this.newFiles) {
      await this._fileService
        .createFile(file, workOrderUpdateId, ResourceType.WorkOrderUpdate)
        .toPromise()
        .then(async (f) => {
          addedFiles.push(f);
          await this._linkTags(f.id, [TAG_IDS.Update]);
          await this._fileService.linkFile(f.id, this.workOrderId, ResourceType.WorkOrder).toPromise();
        });
    }

    // link project files
    for (const file of this.linkedProjectFiles) {
      addedFiles.push(file);
      await this._linkTags(file.file_id, [TAG_IDS.Update]);
      await this._fileService.linkFile(file.file_id, workOrderUpdateId, ResourceType.WorkOrderUpdate).toPromise();
      await this._fileService.linkFile(file.file_id, this.workOrderId, ResourceType.WorkOrder).toPromise();
    }

    // files to delete
    for (const file of this._filesToDelete) {
      await this._fileService.deleteFileWithoutConfirmation(file).toPromise();
    }

    // files to unlink
    for (const file of this._filesToUnlink) {
      await this._fileService.unlinkFile(file.id).toPromise();

      const workOrderFile = workOrderFiles.find((workOrderFile) => workOrderFile.file_id === file.file_id);

      if (workOrderFile) {
        await this._fileService.unlinkFile(workOrderFile.id).toPromise();
      }
    }

    return addedFiles;
  }

  private async _linkTags(fileId: number, tagIds: number[]) {
    await this._fileService.addTags(fileId, tagIds).toPromise();
  }

  private _openProgress(action: string) {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  public openUploadModal() {
    let data: FileAttachmentDialogInjectionData = {
      parentResourceType: null,
      parentResourceId: null,
      additionalParents: undefined,
      preSelectedTags: [{ id: TAG_IDS.Update }],
      allowComment: false,
      workOrderFile: true,
      skipUpload: true,
    };

    // Enable Project Link Files
    if (this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id) {
      data = {
        ...data,
        parentResourceType: ResourceType.WorkOrderUpdate,
        parentResourceId: this.workOrderUpdateId,
        additionalParents: [
          new UhatFileBridgeLink(
            this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id,
            ResourceType.Project
          ),
        ],
        workOrderFile: false,
      };
    }

    this._dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (files: FileAttachmentDialogInjectionData) => {
        if (files?.computerFiles?.length) {
          this.newFiles = [...this.newFiles, ...files.computerFiles];
        }

        if (files?.linkedFiles?.length) {
          this.linkedProjectFiles = [...this.linkedProjectFiles, ...files.linkedFiles];
        }
      });
  }

  private _save(workOrderUpdate: WorkOrderUpdate) {
    if (workOrderUpdate.work_order_id) {
      this._workOrderService
        .createWorkOrderUpdate(workOrderUpdate, [
          ...this._workOrderSubmissionFields,
          'access',
          'notify_followers',
          'work_order_id',
        ])
        .pipe(
          map((result) => result),
          catchError((e) => {
            this._closeProgress();
            return e;
          })
        )
        .subscribe(async (result: WorkOrderUpdate) => {
          this.workOrderUpdateId = result?.id;
          try {
            result.files = await this._handleFiles(this.workOrderUpdateId);
          } catch (e) {
            console.log('error attaching files', e);
          }
          this._closeProgress();
          this._dialogRef.close(result);
        });
    } else {
      this._snackbar.open('Missing work order id!!');
    }
  }

  private async _update(workOrderUpdate: WorkOrderUpdate) {
    await this._handleFiles(this.workOrderUpdateId);
    this._workOrderService
      .updateWorkOrderUpdate(this.workOrderUpdateId, workOrderUpdate, this._workOrderSubmissionFields)
      .pipe(
        map((result) => result),
        catchError((e) => {
          this._closeProgress();
          return e;
        })
      )
      .subscribe((result) => {
        this._closeProgress();
        this._dialogRef.close(result);
      });
  }

  public close(): void {
    this._dialogRef.close();
  }

  public removeExistingFile(file: UhatFileReference) {
    // remove from existing files
    this.existingFiles = this.existingFiles?.filter((existingFile: UhatFileReference) => existingFile?.id !== file?.id);

    if (this.existingProjectFileIds.includes(file?.file_id)) {
      this._filesToUnlink = [...this._filesToUnlink, file];
    } else {
      this._filesToDelete = [...this._filesToDelete, file];
    }
  }

  public removeNewFile(file: File) {
    this.newFiles = this.newFiles.filter((attachedFile: File) => attachedFile !== file);
  }

  public removeLinkedProjectFile(file: UhatFileReference) {
    this.linkedProjectFiles = this.linkedProjectFiles?.filter(
      (existingFile: UhatFileReference) => existingFile?.id !== file?.id
    );
  }

  public selectHealth(healthId: number) {
    this.workOrderHealthTypeId = healthId;
  }

  public async submit() {
    if (this.validForSubmission) {
      this._openProgress(`${this.isUpdating ? 'Updating' : 'Creating'} a Work Order update...`);

      const workOrderUpdate: WorkOrderUpdate = {
        message: this.workOrderUpdateFormGroup?.value?.message || '',
        work_order_health_type_id: this.workOrderHealthTypeId,
      };

      if (this.isUpdating) {
        this._update(workOrderUpdate);
      } else {
        this._save({
          ...workOrderUpdate,
          work_order_id: this.workOrderId,
          access: this._accessHelper(),
          notify_followers: this.notify_followers.value,
        });
      }
    }
  }
}
