import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { KeyControlDisplayData, Workspace } from 'src/app/enums';
import { ProjectListFilter } from 'src/app/models';
import { OrderByFieldPipe, ProjectListFilterPipe } from 'src/app/pipes';
import { ModuleService } from 'src/app/services';
import { KeyControlsBridge, Project } from 'src/app/types';
@Component({
  selector: 'app-title-sixy-one-key-control-columns',
  templateUrl: './title-sixy-one-key-control-columns.component.html',
  styleUrls: ['./title-sixy-one-key-control-columns.component.scss'],
})
export class TitleSixyOneKeyControlColumnsComponent implements OnInit {
  @Input() projectKeyControlTemplates;
  @Input() fieldToSortBy: string;
  @Input() filter: ProjectListFilter;
  @Input() projectFilter: ProjectListFilterPipe;
  @Input() projects: Project[];
  @Input() sortDirection: SortDirection = 'desc';
  @Input() startIndex = 0;
  @Input() pageSize = 50;
  @Input() endIndex = this.pageSize;
  @Output() emitOpenViewKeyControlsDialog = new EventEmitter();
  @Output() emitUpdateSortByField = new EventEmitter();

  constructor(private _moduleService: ModuleService, public sortPipe: OrderByFieldPipe) {}

  ngOnInit(): void {}

  public get currentWorkspace() {
    return this._moduleService?.workspace_id;
  }

  public get isDispatch() {
    return +this._moduleService?.workspace_id === Workspace.Dispatch;
  }

  public get KEY_CONTROL_HEADING() {
    return KeyControlDisplayData;
  }

  public openKeyControlsDialog(event, projectId: number, controlTemplate: KeyControlsBridge) {
    event.stopPropagation();
    this.emitOpenViewKeyControlsDialog.emit({ projectId, keyControlId: controlTemplate?.id });
  }

  public updateSortByField(sortField: string) {
    this.emitUpdateSortByField.emit(sortField);
  }
}
