import { Component, Input, OnChanges } from '@angular/core';
import { ProjectService } from '../../services';
import { Invoice, TaskAccessoryData } from '../../types';
import { TaskAccessoryType } from '../../enums';

@Component({
  selector: 'app-invoice-accessory-data',
  templateUrl: './invoice-accessory-data.component.html',
  styleUrls: ['./invoice-accessory-data.component.scss'],
})
export class InvoiceAccessoryDataComponent implements OnChanges {
  @Input() public data: TaskAccessoryData | string;

  constructor(private projectService: ProjectService) {}

  private currentAccessoryDataId: number;
  public accessoryData: TaskAccessoryData;
  public invoice: Invoice;

  async ngOnChanges() {
    if (typeof this.data === 'string') {
      this.accessoryData = JSON.parse(this.data);
    }
    this.accessoryData.parentId = this.accessoryData?.parentId || this.accessoryData?.id;

    if (
      this.accessoryData?.type === TaskAccessoryType.Invoice &&
      this.accessoryData?.parentId &&
      this.accessoryData?.parentId !== this.currentAccessoryDataId
    ) {
      this.currentAccessoryDataId = this.accessoryData?.parentId;
      await this.projectService
        .getInvoices([{ type: 'field', field: 'id', value: this.accessoryData.parentId.toString() }])
        .toPromise()
        .then((invoices) => {
          if (invoices?.length) {
            this.invoice = invoices[0];
          }
        });
    }
  }

  get TaskAccessoryType() {
    return TaskAccessoryType;
  }
}
