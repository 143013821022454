import { Preferences, PreferenceStorageKey } from 'src/app/types';

export class PreferenceStorage<T extends Preferences> {
  constructor(private readonly key: PreferenceStorageKey, private readonly defaultValue: T) {}

  get currentValue(): T {
    let preferences = JSON.parse(localStorage.getItem(this.key) ?? '{"version": -1}') as T;

    if (preferences.version !== this.defaultValue.version) {
      preferences = this.defaultValue;

      localStorage.setItem(this.key, JSON.stringify(preferences));
    }

    return preferences;
  }

  setPartialValue(value: Partial<T>): void {
    localStorage.setItem(this.key, JSON.stringify({ ...this.currentValue, ...value }));
  }
}
