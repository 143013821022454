<div
  #mainScreen
  (window:resize)="onResize($event)"
  id="work-order-list"
  data-test-id="work-order-list"
  class="work-order-list app-container"
>
  <mat-drawer-container
    class="work-order-list-container full-height bg-transparent"
    cdkScrollable="true"
    hasBackdrop="true"
    autosize
  >
    <!--the backdrop closes the aside as well-->
    <mat-drawer-content>
      <!--the backdrop closes the aside as well-->
      <section id="main-work-order-list-wrapper" class="ease p-4">
        <header class="mb-4">
          <div class="d-flex align-items-center">
            <span class="d-flex flex-column mr-auto">
              <h2 class="m-0 dkblue font-weight-semibold d-inline-block align-bottom">Work Orders</h2>
            </span>
            <div class="d-flex">
              <button (click)="drawer.toggle()" mat-button color="primary" type="button" class="ml-3">
                See Latest Updates
              </button>
              <button
                mat-stroked-button
                class="ml-3 bg-white lh-34"
                color="primary"
                (click)="exportData()"
                *ngIf="authService.isStaffOnAnyModule"
              >
                <i class="material-icons-outlined"> file_download </i>
                <span> Download CSV </span>
              </button>
              <button
                *ngIf="isWorkspaceStaff"
                mat-flat-button
                color="accent"
                class="ml-3 lh-34"
                (click)="openNewWorkOrderDialog()"
              >
                + Work Order
              </button>
            </div>
          </div>
          <p class="dkgray font-weight-normal mb-2">View work orders assigned to you or a workspace.</p>
        </header>
        <div class="row align-items-center list-filters">
          <div *ngIf="isDispatch" class="col-auto pr-0 mb-4">
            <app-workspace-selector (workspaceSelectedChanged)="updateWorkspaces($event)"></app-workspace-selector>
          </div>
          <div class="col-auto mb-4 pr-0">
            <mat-button-toggle-group name="woStatus" aria-label="List">
              <mat-button-toggle
                [ngClass]="{ selectedTab: s.id === apiWorkOrderFilter.statusId }"
                (click)="changeFilterStatus(s.id)"
                *ngFor="let s of workOrderStatuses | slice: 0:1"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
                value="Active"
              >
                <i *ngIf="s.id === statusIds.ACTIVE" class="material-icons green mr-2">label_important</i>
                {{ s.name }}
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{
                    'bg-ltblue': s.id === apiWorkOrderFilter.statusId,
                    'bg-gray': s.id !== apiWorkOrderFilter.statusId
                  }"
                >
                  {{ workOrderStatusCounts[s.id] || 0 }}
                </span>
              </mat-button-toggle>
              <mat-button-toggle
                (click)="updateNotAssignedFilter()"
                [ngClass]="{ selectedTab: apiWorkOrderFilter.notAssigned }"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
                value="Active"
              >
                <i class="material-icons red mr-2">error</i>
                Not Assigned
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{ 'bg-red': unassignedWorkOrderCount > 0, 'bg-gray': unassignedWorkOrderCount === 0 }"
                >
                  {{ unassignedWorkOrderCount || 0 }}
                </span>
              </mat-button-toggle>
              <mat-button-toggle
                [ngClass]="{ selectedTab: s.id === apiWorkOrderFilter.statusId }"
                (click)="changeFilterStatus(s.id)"
                *ngFor="let s of workOrderStatuses | slice: 3:4"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
                value="Active"
              >
                <i *ngIf="s.id === statusIds.PLANNED" class="material-icons ltblue mr-2">next_plan</i>
                {{ s.name }}
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{
                    'bg-ltblue': s.id === apiWorkOrderFilter.statusId,
                    'bg-gray': s.id !== apiWorkOrderFilter.statusId
                  }"
                >
                  {{ workOrderStatusCounts[s.id] || 0 }}
                </span>
              </mat-button-toggle>
              <mat-button-toggle
                [ngClass]="{ selectedTab: s.id === apiWorkOrderFilter.statusId }"
                (click)="changeFilterStatus(s.id)"
                *ngFor="let s of workOrderStatuses | slice: 1:3"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
                value="Active"
              >
                <i *ngIf="s.id === statusIds.ON_HOLD" class="material-icons red mr-2">flag</i>
                <i *ngIf="s.id === statusIds.CLOSED" class="material-icons gray mr-2">done_all</i>
                <i *ngIf="s.id === statusIds.PLANNED" class="material-icons ltblue mr-2">next_plan</i>
                {{ s.name }}
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{
                    'bg-ltblue': s.id === apiWorkOrderFilter.statusId,
                    'bg-gray': s.id !== apiWorkOrderFilter.statusId
                  }"
                >
                  {{ workOrderStatusCounts[s.id] || 0 }}
                </span>
              </mat-button-toggle>

              <mat-button-toggle
                [ngClass]="{ selectedTab: s.id === apiWorkOrderFilter.statusId }"
                (click)="changeFilterStatus(s.id)"
                *ngFor="let s of workOrderStatuses | slice: 4:5"
                class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
                value="Active"
              >
                <i *ngIf="s.id === apiWorkOrderFilter.statusId" class="material-icons gray mr-2">language</i>
                {{ s.name }}
                <span
                  class="small-badge white ml-2 lh-18"
                  [ngClass]="{
                    'bg-ltblue': s.id === apiWorkOrderFilter.statusId,
                    'bg-gray': s.id !== apiWorkOrderFilter.statusId
                  }"
                >
                  {{ workOrderStatusCounts[s.id] || 0 }}
                </span>
              </mat-button-toggle>

              <!-- <mat-button-toggle
              [ngClass]="{ selectedTab: apiWorkOrderFilter.statusId === 0 }"
              (click)="changeFilterStatus(0)"
              class="px-2 lh-34 font-weight-semibold align-items-center d-flex"
              value="Active"
            >
               <i *ngIf="apiWorkOrderFilter.statusId === 0" class="material-icons gray mr-2">all_inbox</i>
              All Work Orders
              <span
                class="small-badge white ml-2 lh-18"
                [ngClass]="{ 'bg-ltblue': apiWorkOrderFilter.statusId === 0, 'bg-gray': apiWorkOrderFilter.statusId !== 0 }"
              >
                {{ workOrderStatusCounts['0'] || 0 }}
              </span>
            </mat-button-toggle> -->
            </mat-button-toggle-group>
          </div>
          <div class="col-auto mb-4">
            <button
              mat-stroked-button
              [matMenuTriggerFor]="my_work_orders"
              class="bg-white d-flex align-items-center lh-38 pr-2 pl-3"
              color="primary"
              matBadgePosition="after"
              [matBadge]="myFiltersApplied.length > 0 ? myFiltersApplied?.length : null"
              matBadgeColor="warn"
            >
              <span> My WOs Only </span>
              <i class="material-icons">arrow_drop_down</i>
            </button>
            <mat-menu #my_work_orders="matMenu" class="mt-2" xPosition="before">
              <button mat-menu-item color="primary" (click)="filterByCurrentUser(MyWorkOrderFilters.ToggleAll)">
                <span> {{ myFiltersApplied?.length ? 'Deselect' : 'Select' }} All My Work Orders</span>
              </button>
              <mat-divider></mat-divider>
              <button
                mat-menu-item
                color="primary"
                (click)="filterByCurrentUser(MyWorkOrderFilters.AssignedToUser)"
                [disabled]="apiWorkOrderFilter.notAssigned"
              >
                <mat-icon *ngIf="apiWorkOrderFilter.assignedToCurrentUser">check_box</mat-icon>
                <mat-icon *ngIf="!apiWorkOrderFilter.assignedToCurrentUser">check_box_outline_blank</mat-icon>
                <span>Assigned to Me</span>
              </button>
              <button mat-menu-item color="primary" (click)="filterByCurrentUser(MyWorkOrderFilters.RequestedByUser)">
                <mat-icon *ngIf="apiWorkOrderFilter.requestedByCurrentUser">check_box</mat-icon>
                <mat-icon *ngIf="!apiWorkOrderFilter.requestedByCurrentUser">check_box_outline_blank</mat-icon>
                <span>Requested by Me</span>
              </button>
              <button (click)="filterByCurrentUser(MyWorkOrderFilters.CreatedByUser)" mat-menu-item color="primary">
                <mat-icon *ngIf="apiWorkOrderFilter.createdByCurrentUser">check_box</mat-icon>
                <mat-icon *ngIf="!apiWorkOrderFilter.createdByCurrentUser">check_box_outline_blank</mat-icon>
                <span>Created by Me</span>
              </button>
            </mat-menu>
          </div>
          <div class="col mb-4"></div>
          <div class="col-auto mb-4">
            <button
              mat-stroked-button
              (click)="showFilters = !showFilters"
              color="primary"
              class="mr-3 bg-white lh-40"
              type="button"
              matBadgePosition="before"
              [matBadge]="filtersApplied > 0 && !showFilters ? filtersApplied : null"
              matBadgeColor="warn"
            >
              <i class="material-icons mr-2" style="margin-top: -3px">filter_list</i>
              Filters
            </button>
            <mat-form-field appearance="outline" style="width: 230px">
              <mat-icon class="dkblue" matPrefix>search</mat-icon>
              <input matInput placeholder="Search Work Orders" [formControl]="search" />
              <mat-icon
                *ngIf="search?.value && !isTypingSearchTerm"
                class="gray pointer"
                (click)="clearSearchString()"
                matSuffix
                >cancel</mat-icon
              >
              <mat-progress-spinner
                matSuffix
                *ngIf="search?.value && isTypingSearchTerm"
                mode="indeterminate"
                diameter="20"
              ></mat-progress-spinner>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="showFilters" class="m-portlet mb-3 p-3 full-width bg-shade-ltblue">
          <div class="row d-flex align-items-end">
            <div class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Topic</h6>
              <form [formGroup]="selectedTopics" class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select formControlName="topics" multiple (selectionChange)="topicChanged()" placeholder="Topic">
                    <mat-option *ngFor="let topic of allTopics" [value]="topic.id">
                      {{ topic?.topic_group?.name }} > {{ topic?.topic_category?.name }} > {{ topic.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="!apiWorkOrderFilter?.notAssigned" class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Assignee</h6>
              <form [formGroup]="selectedAssignees" class="d-flex">
                <mat-form-field appearance="outline" class="full-width assignee-filter">
                  <mat-select
                    formControlName="assignees"
                    multiple
                    (selectionChange)="assigneeChanged()"
                    placeholder="Assignee"
                  >
                    <mat-option *ngFor="let assignee of allAssignees" [value]="assignee.id">
                      {{ assignee?.first_name }} {{ assignee?.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <div *ngIf="showFilters" class="col-lg-2 pt-2 pb-3">
              <h6 class="dkblue">Requestor</h6>
              <form [formGroup]="selectedRequesters" class="d-flex">
                <mat-form-field appearance="outline" class="full-width assignee-filter">
                  <mat-select
                    formControlName="requesters"
                    multiple
                    (selectionChange)="requesterChanged()"
                    placeholder="Requestor"
                  >
                    <mat-option *ngFor="let requester of allRequesters" [value]="requester.id">
                      {{ requester?.first_name }} {{ requester?.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>

            <div class="col-lg-3">
              <div class="row">
                <div class="col-7 pr-0 pt-2 pb-3">
                  <h6 class="dkblue">Building</h6>
                  <form [formGroup]="selectedBuildings" class="d-flex">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-select
                        formControlName="buildings"
                        multiple
                        (selectionChange)="buildingChanged()"
                        placeholder="Building"
                      >
                        <mat-option *ngFor="let building of allBuildings" [value]="building.id">
                          {{ building?.name }} - {{ building?.code }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
                <div class="col-5 pt-2 pb-3">
                  <h6 class="dkblue">Floor</h6>
                  <form [formGroup]="selectedFloors" class="d-flex">
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-select
                        formControlName="floors"
                        multiple
                        (selectionChange)="floorChanged()"
                        placeholder="Floor"
                      >
                        <mat-option *ngFor="let floor of allFloors" [value]="floor.id">{{ floor.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-lg-2 pt-2 pb-3" *ngIf="isWorkspaceStaff">
              <h6 class="dkblue">Tags</h6>
              <form [formGroup]="selectedTags" class="d-flex">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select multiple placeholder="Tags" formControlName="tags" (selectionChange)="tagsChanged()">
                    <mat-select-trigger>
                      <span *ngIf="selectedTags.value.tags?.length !== 1">
                        {{
                          allTags.length === selectedTags.value.tags?.length ? 'All' : +selectedTags.value.tags?.length
                        }}
                        Tags
                      </span>
                      <span *ngIf="selectedTags.value.tags?.length === 1">
                        {{ firstSelectedTag.abbreviation }}
                      </span>
                    </mat-select-trigger>
                    <ng-container *ngIf="selectedTags.value.tags?.length > 0">
                      <mat-option (click)="deselectAllTags()">Deselect All</mat-option>
                      <mat-divider class="my-0"></mat-divider>
                    </ng-container>
                    <mat-option *ngFor="let tag of allTags" [value]="tag.id">
                      {{ tag.abbreviation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <div class="col-lg-3 pt-2 pb-3">
              <ng-template [ngIf]="+apiWorkOrderFilter?.statusId === statusIds.CLOSED" [ngIfElse]="otherStatus">
                <h6 class="dkblue d-flex">Closed Date</h6>
              </ng-template>
              <ng-template #otherStatus>
                <h6 class="dkblue d-flex">Created Date</h6>
              </ng-template>
              <div [formGroup]="dateRange" class="d-flex" style="width: 250px">
                <mat-form-field appearance="outline" class="full-width mr-2">
                  <mat-date-range-input [rangePicker]="picker" [min]="minCreatedDate" [max]="maxCreatedDate">
                    <input matStartDate formControlName="start" placeholder="MM/DD/YYYY" (dateInput)="dateChanged()" />
                    <input matEndDate formControlName="end" placeholder="MM/DD/YYYY" (dateInput)="dateChanged()" />
                  </mat-date-range-input>
                  <button
                    mat-icon-button
                    (click)="resetDateRange()"
                    *ngIf="dateRangeStart.value && dateRangeEnd.value"
                    matSuffix
                    class="gray pointer br-50"
                    style="position: absolute; right: 32px"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                  <mat-datepicker-toggle matSuffix class="gray" [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </div>
            <div class="col text-right">
              <button mat-button color="primary" class="mb-2" type="button" (click)="showFilters = false">Hide</button>
              <button
                mat-stroked-button
                color="primary"
                class="bg-white ml-3 mb-2"
                type="button"
                (click)="resetFilters()"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>

        <h6 *ngIf="filtersApplied || showFilters" class="mb-3 dkgray font-weight-normal">
          Showing
          <span class="font-weight-semibold dkblue">{{
            (allWorkOrders | filterBySelectedTags: selectedTagIds).length
          }}</span>
          of
          <!-- not assigned -->
          <span *ngIf="!apiWorkOrderFilter?.statusId">{{ unassignedWorkOrderCount || 0 }} </span>
          <!-- the others -->
          <span *ngIf="apiWorkOrderFilter?.statusId"
            >{{ (workOrderStatusCounts && workOrderStatusCounts[apiWorkOrderFilter.statusId]) || 0 }}
          </span>
        </h6>

        <main *ngIf="allWorkOrders">
          <div class="m-portlet mb-0">
            <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
              <div class="row align-items-center">
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.CODE)"
                  class="col-auto pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"
                  style="width: 110px"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.CODE }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Code</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.CODE" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.BUILDING_CODE)"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  style="max-width: 110px"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.BUILDING_CODE }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Location</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.BUILDING_CODE" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.TITLE)"
                  class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.TITLE }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Short Description / Topic</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.TITLE" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.PRIORITY_ID)"
                  *ngIf="isWorkspaceStaff"
                  class="col-auto p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  style="width: 95px"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.PRIORITY_ID }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Priority</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.PRIORITY_ID" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div *ngIf="isWorkspaceStaff" class="col-1 p-1 pl-3 pr-3 d-flex align-items-center header-item">
                  <h6 class="m-0 text-truncate header-title mr-auto">Tags</h6>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.CREATED_DATETIME)"
                  *ngIf="divWidth > 1000"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.CREATED_DATETIME }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Created</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.CREATED_DATETIME" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <!--ALternate between closed(closed date) and not closed (due date) - due date-->
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.DUE_DATE)"
                  *ngIf="isWorkspaceStaff && apiWorkOrderFilter.statusId !== statusIds.CLOSED"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === 'due_date' || fieldToSortBy === ORDER_BY_FIELD.DUE_DATE
                  }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Due Date</h6>
                  <i
                    *ngIf="fieldToSortBy === 'due_date' || fieldToSortBy === ORDER_BY_FIELD.DUE_DATE"
                    matRipple
                    class="material-icons"
                  >
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <!--ALternate between closed(closed date) and not closed (due date) - closed-->
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.CLOSED_DATETIME)"
                  *ngIf="isWorkspaceStaff && apiWorkOrderFilter.statusId === statusIds.CLOSED"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === 'closed_datetime' || fieldToSortBy === ORDER_BY_FIELD.CLOSED_DATETIME
                  }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Closed Date</h6>
                  <i
                    *ngIf="fieldToSortBy === 'closed_datetime' || fieldToSortBy === ORDER_BY_FIELD.CLOSED_DATETIME"
                    matRipple
                    class="material-icons"
                  >
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.ASSIGNED_USER_FIRST_NAME)"
                  class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.ASSIGNED_USER_FIRST_NAME }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Assigned To</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.ASSIGNED_USER_FIRST_NAME" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.REQUESTER_FIRST_NAME)"
                  *ngIf="divWidth > 1000"
                  class="col p-1 pl-3 pr-3 d-flex header-item align-items-center"
                  [ngClass]="{ sortedBy: fieldToSortBy === ORDER_BY_FIELD.REQUESTER_FIRST_NAME }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Requester</h6>
                  <i *ngIf="fieldToSortBy === ORDER_BY_FIELD.REQUESTER_FIRST_NAME" matRipple class="material-icons">
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
                <div
                  (click)="updateSortByField(ORDER_BY_FIELD.UPDATE_DOT_CREATED_DATETIME)"
                  *ngIf="isWorkspaceStaff"
                  class="col p-1 pl-3 pr-3 d-flex align-items-center header-item"
                  [ngClass]="{
                    sortedBy: fieldToSortBy === ORDER_BY_FIELD.UPDATE_DOT_CREATED_DATETIME
                  }"
                >
                  <h6 class="m-0 text-truncate header-title mr-auto">Last Update</h6>
                  <i
                    *ngIf="
                      fieldToSortBy === 'update.created_datetime' ||
                      fieldToSortBy === ORDER_BY_FIELD.UPDATE_DOT_CREATED_DATETIME
                    "
                    matRipple
                    class="material-icons"
                  >
                    {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                  </i>
                </div>
              </div>
            </div>
            <div *ngIf="isFiltering" class="d-flex justify-content-center my-5">
              <mat-spinner color="accent" [diameter]="40"></mat-spinner>
            </div>
            <div *ngIf="!isFiltering">
              <div
                matRipple
                class="m-portlet__body item pointer p-3 pt-4 pb-4"
                data-test-id="work-order-list-item"
                [routerLink]="['/work-orders/' + wo.id]"
                *ngFor="
                  let wo of allWorkOrders
                    | orderByField: customizedFieldToSortBy:sortDirection
                    | slice: startIndex:endIndex;
                  let i = index
                "
                [ngClass]="{
                  'bg-shade-orange': isOverdue(wo.due_date) && wo.status.id !== 3,
                  'bg-shade-red': !wo.assigned_user
                }"
              >
                <div class="row pr-0 align-items-center">
                  <div class="col-auto pr-3 d-flex align-items-center data-item" style="width: 110px">
                    <p class="m-0 dkblue pointer font-weight-semibold d-inline-block align-middle">
                      {{ wo.code }}
                    </p>
                    <i *ngIf="wo.status.id === statusIds.ACTIVE" class="material-icons green ml-auto"
                      >label_important</i
                    >
                    <i *ngIf="wo.status.id === statusIds.ON_HOLD" class="material-icons red ml-auto">flag</i>
                    <i *ngIf="wo.status.id === statusIds.CLOSED" class="material-icons gray ml-auto">done_all</i>
                    <i *ngIf="wo.status.id === statusIds.PLANNED" class="material-icons ltblue ml-auto">next_plan</i>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item" style="max-width: 110px">
                    <p class="m-0 mr-auto dkblue pointer font-weight-semibold">
                      {{ wo.building?.code }}{{ wo.floor?.code ? '-' : '' }}{{ wo.floor?.code }}
                    </p>
                  </div>
                  <div class="col-2 pl-3 pr-3 d-flex flex-column data-item">
                    <a
                      [routerLink]="['/work-orders/' + wo.id]"
                      (click)="stopPropagation($event)"
                      class="text-decoration-none hover-u"
                    >
                      <p class="m-0 dkblue font-weight-semibold d-block">
                        {{ wo.title }}
                        <span class="m-0 dkgray font-weight-normal d-block">
                          {{
                            wo.topic_group?.name || wo.topic?.topic_group?.name
                              ? (wo.topic_group?.name || wo.topic?.topic_group?.name) + ' >'
                              : ''
                          }}
                          {{
                            wo.topic_category?.name || wo.topic?.topic_category?.name
                              ? (wo.topic_category?.name || wo.topic?.topic_category?.name) + ' >'
                              : ''
                          }}
                          {{ wo.topic?.name }}
                        </span>
                      </p>
                    </a>
                  </div>
                  <div *ngIf="isWorkspaceStaff" class="col-auto pl-3 pr-3 d-flex data-item" style="width: 95px">
                    <h6
                      *ngIf="wo.priority"
                      class="m-0 white text-uppercase pl-2 pr-2 mr-2"
                      [ngClass]="{
                        'bg-dkred': wo.priority.id === 4,
                        'bg-red': wo.priority.id === 3,
                        'bg-ltblue': wo.priority.id === 2,
                        'bg-gray': wo.priority.id === 1
                      }"
                    >
                      <span>
                        {{ wo.priority.abbreviation }}
                      </span>
                    </h6>
                  </div>
                  <div class="col-1 pl-3 pr-3 d-flex data-item" *ngIf="isWorkspaceStaff">
                    <p class="m-0 dkgray">
                      <span *ngFor="let tag of wo.tags || []"> {{ tag.abbreviation }} </span>
                    </p>
                  </div>
                  <div *ngIf="divWidth > 1000" class="col pl-3 pr-3 d-flex data-item">
                    <p
                      class="m-0 dkblue font-weight-normal d-block"
                      [ngClass]="{
                        gray: !wo.created_datetime
                      }"
                    >
                      {{ wo.created_datetime ? formatDateTime(wo.created_datetime) : '-' }}
                    </p>
                  </div>
                  <!--Due Date-->
                  <div
                    *ngIf="isWorkspaceStaff && wo.status.id !== statusIds.CLOSED"
                    class="col pl-3 pr-3 d-flex data-item"
                  >
                    <i *ngIf="isOverdue(wo.due_date)" class="material-icons red mr-2">error</i>
                    <p
                      class="m-0 dkblue font-weight-semibold d-block"
                      [ngClass]="{
                        gray: !wo.due_date,
                        red: isOverdue(wo.due_date) && wo.status.id !== statusIds.CLOSED,
                        dkblue: !isOverdue(wo.due_date) || wo.status.id === statusIds.CLOSED
                      }"
                    >
                      {{ wo.due_date ? formatDate(wo.due_date) : 'Not Set' }}
                    </p>
                  </div>
                  <!--Closed Date-->
                  <div
                    *ngIf="isWorkspaceStaff && wo.status.id === statusIds.CLOSED"
                    class="col pl-3 pr-3 d-flex data-item"
                  >
                    <p class="m-0 dkgray font-italic font-weight-normal d-block">
                      {{ wo.closed_datetime ? formatDateTime(wo.closed_datetime) : '-' }}
                    </p>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item">
                    <i *ngIf="!wo.assigned_user" class="material-icons red mr-2 ml-1">error</i>
                    <app-profile-thumbnail
                      class="d-inline-block align-middle mr-2"
                      [height]="26"
                      [width]="26"
                      *ngIf="wo.assigned_user"
                      [userId]="wo.assigned_user.id"
                    ></app-profile-thumbnail>
                    <p
                      class="dkblue m-0 d-inline-block align-middle font-weight-normal text-wrap text-truncate"
                      [ngClass]="{ 'red font-weight-semibold': !wo.assigned_user }"
                    >
                      <span *ngIf="wo.assigned_user">
                        {{ wo.assigned_user?.first_name }} {{ wo.assigned_user?.last_name }}
                      </span>
                      <span *ngIf="!wo.assigned_user"> Not Assigned </span>
                    </p>
                  </div>
                  <div class="col pl-3 pr-3 d-flex align-items-center data-item" *ngIf="divWidth > 1000">
                    <app-profile-thumbnail
                      class="d-inline-block align-middle mr-2"
                      [height]="26"
                      [width]="26"
                      *ngIf="wo.requester"
                      [userId]="wo.requester.id"
                    ></app-profile-thumbnail>
                    <p class="dkblue m-0 d-inline-block align-middle font-weight-normal text-wrap text-truncate">
                      {{ wo.requester?.first_name }} {{ wo.requester?.last_name }}
                    </p>
                  </div>
                  <div *ngIf="isWorkspaceStaff" class="col pl-3 d-flex flex-column data-item">
                    <p class="m-0 font-weight-semibold gray" *ngIf="!wo.update">No Updates</p>
                    <p
                      (click)="$event.stopPropagation()"
                      [matMenuTriggerFor]="workOrderUpdateMenu"
                      class="m-0 font-weight-semibold ltblue hover-u d-block"
                      *ngIf="wo.update"
                    >
                      {{ wo.update.created_datetime | date: 'EEE, MMM d yyyy' }}
                    </p>
                    <p
                      class="m-0 font-weight-normal font-italic dkgray"
                      style="font-size: 80%"
                      *ngIf="wo.update && wo.update.created_by"
                    >
                      By {{ wo.update.created_by.first_name }} {{ wo.update.created_by.last_name }}
                    </p>
                    <mat-menu #workOrderUpdateMenu="matMenu" xPosition="before">
                      <div class="d-flex py-3 px-4" *ngIf="wo.update">
                        <app-profile-thumbnail
                          class="mr-3"
                          [noBorder]="true"
                          [height]="30"
                          [width]="30"
                          [userId]="wo.update.created_by?.id"
                        ></app-profile-thumbnail>
                        <div class="flex-grow-1">
                          <div class="d-flex align-items-start mb-3">
                            <div class="d-flex flex-column mr-4">
                              <h6 class="mb-0 dkblue font-weight-semibold text-nowrap">
                                {{ wo.update.created_by?.first_name }}
                                {{ wo.update.created_by?.last_name }}
                              </h6>
                              <h6 class="mb-0 dkgray font-weight-normal text-nowrap">
                                {{ wo.update.created_datetime | date: 'EEE, MMM d • h:mmaa' }}
                              </h6>
                            </div>
                            <p
                              *ngIf="wo.updates[0].work_order_health_type?.id"
                              class="mb-0 px-2 br-4 ml-auto bg-green white font-weight-semibold"
                              [ngClass]="{
                                'bg-green': wo.update.work_order_health_type?.id === 1,
                                'bg-orange': wo.update.work_order_health_type?.id === 2,
                                'bg-red': wo.update.work_order_health_type?.id === 3
                              }"
                              style="font-size: 11px; line-height: 20px"
                            >
                              {{ wo.update.work_order_health_type?.name }}
                            </p>
                          </div>
                          <a
                            class="m-0 ltblue font-weight-semibold hover-u mb-3 text-decoration-none"
                            [routerLink]="['/work-orders/' + wo.id]"
                          >
                            {{ wo.code }}{{ wo.title ? ' - ' : '' }} {{ wo.title }}
                          </a>
                          <p class="m-0 mt-3 dkblue font-weight-normal" [innerHTML]="wo.update.message"></p>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-paginator
            *ngIf="workOrderTotalCount > pageSize"
            class="bg-transparent dkblue"
            color="primary"
            #paginator
            [length]="workOrderTotalCount"
            [pageSize]="pageSize"
            (page)="pageChange($event)"
          >
          </mat-paginator>
          <div
            class="not-found-prompt"
            [hidden]="isLoading || isFiltering || (allWorkOrders | filterBySelectedTags: selectedTagIds).length !== 0"
          >
            <div class="row my-5">
              <div class="col-md-5 py-5 mx-auto text-center">
                <h5 class="dkblue lh-24">Well, this is awkward... Your search didn't yield any results.</h5>
                <p class="dkgray lh-24">Perhaps broaden your search, or double-check your spelling.</p>
                <button
                  *ngIf="filtersApplied > 0 || search.value"
                  mat-stroked-button
                  color="accent"
                  class="mt-2 bg-white"
                  (click)="resetFilters()"
                >
                  Clear Filters & Search
                </button>
              </div>
            </div>
          </div>
        </main>
      </section>
    </mat-drawer-content>
    <mat-drawer #drawer mode="over" position="end" autoFocus="false">
      <app-latest-update
        *ngIf="drawer.opened"
        [workOrderUpdates]="workOrderUpdates"
        [drawer]="drawer"
      ></app-latest-update>
    </mat-drawer>
  </mat-drawer-container>
</div>
