import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { difference, filter, map, sortBy } from 'lodash';
import * as moment from 'moment';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { ApplicationRole, ResourceType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LocationService,
  ProgressIndicatorService,
  ProjectService,
  RequestService,
  UserService,
} from 'src/app/services';
import { Building, Department, Floor, Suite, UhatFileReference, User } from 'src/app/types';
import {
  ProjectRequestDetailsModalComponent,
  TenantSelectDialogComponent,
} from 'src/app/workspaces/construction/components';
import { ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  @Input() project: ProjectConstruction;
  @ViewChild('pdf', { static: true }) pdf;

  constructor(
    private projectService: ProjectService,
    private requestService: RequestService,
    private locationService: LocationService,
    private userService: UserService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private authService: AuthService,
    private progressIndicatorService: ProgressIndicatorService,
    private fileService: FileService
  ) {}

  loading;
  unsavedChangesExist;
  shownProject;
  projectFields = [
    'title',
    'code',
    'scope_of_work',
    'request_type_id',
    'request_type_name',
    'building_id',
    'building_name',
    'floor_id',
    'floor_name',
    'suite_id',
    'suite_name',
    'department_id',
    'department_name',
    'square_footage',
    'trades',
    'cfmo_id',
    'cfmo_first_name',
    'cfmo_last_name',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'workspace_manager_id',
    'workspace_manager_first_name',
    'workspace_manager_last_name',
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'engineer_ids',
    'floor_plans',
    'floor_plan_ids',
    'priority_id',
    'end_date',
    'misc_files',
    'fyc_reason',
    'fyc_approved_fiscal_year',
    'fyc_project_year',
    'fyc_approved_budget',
    'fyc_priority_id',
    'fyc_impact',
    'fyc_likely',
  ];
  requestTypes;
  buildings;
  floors;
  suites;
  departments;
  trades;
  allWorkspaceManagers: User[];
  allArchitects: User[];
  allEngineers: User[];
  allCFMOs: User[];
  allProjectManagers: User[];
  floorPlans = [];
  miscFiles = [];
  allPriorities: { id: number; name: string }[];
  printPreview;
  downloading = false;
  isEditing = false;
  years = [];

  projectDetailsForm: FormGroup = this.fb.group({
    title: [null, [Validators.required]],
    scopeOfWork: [null, [Validators.required]],
    priorityLevelId: [null],
    requestType: [null],
    buildingId: [null],
    floorId: [null],
    suiteId: [null],
    departmentId: [null],
    squareFootage: [null, [Validators.required, Validators.min(0)]],
    workspaceManagerId: [null],
    projectManagerId: [null],
    cfmoId: [null],
    architectId: [null],
    engineerIds: [null],
    fycReason: [null],
    fycApprovedFiscalYear: [null],
    fycProjectYear: [null],
    fycApprovedBudget: [null],
    fycPriority: [null],
    fycImpact: [null],
    fycLikely: [null],
  });

  get title() {
    return this.projectDetailsForm.get('title');
  }
  get scopeOfWork() {
    return this.projectDetailsForm.get('scopeOfWork');
  }
  get priorityLevelId() {
    return this.projectDetailsForm.get('priorityLevelId');
  }
  get requestType() {
    return this.projectDetailsForm.get('requestType');
  }
  get buildingId() {
    return this.projectDetailsForm.get('buildingId');
  }
  get floorId() {
    return this.projectDetailsForm.get('floorId');
  }
  get suiteId() {
    return this.projectDetailsForm.get('suiteId');
  }
  get departmentId() {
    return this.projectDetailsForm.get('departmentId');
  }
  get squareFootage() {
    return this.projectDetailsForm.get('squareFootage');
  }
  get workspaceManagerId() {
    return this.projectDetailsForm.get('workspaceManagerId');
  }
  get projectManagerId() {
    return this.projectDetailsForm.get('projectManagerId');
  }
  get cfmoId() {
    return this.projectDetailsForm.get('cfmoId');
  }
  get architectId() {
    return this.projectDetailsForm.get('architectId');
  }
  get engineerIds() {
    return this.projectDetailsForm.get('engineerIds');
  }
  get fycReason() {
    return this.projectDetailsForm.get('fycReason');
  }
  get fycApprovedFiscalYear() {
    return this.projectDetailsForm.get('fycApprovedFiscalYear');
  }
  get fycProjectYear() {
    return this.projectDetailsForm.get('fycProjectYear');
  }
  get fycApprovedBudget() {
    return this.projectDetailsForm.get('fycApprovedBudget');
  }
  get fycPriority() {
    return this.projectDetailsForm.get('fycPriority');
  }
  get fycImpact() {
    return this.projectDetailsForm.get('fycImpact');
  }
  get fycLikely() {
    return this.projectDetailsForm.get('fycLikely');
  }
  get fycMatrix() {
    return this.fycImpact.value * this.fycLikely.value;
  }

  get isAdmin() {
    return this.authService.isProjectAdmin(this.project.id, this.project.module_id);
  }

  ngOnInit() {
    setTimeout(async () => {
      await this.getStaticData();
      this.refresh();
    });
  }

  async getStaticData() {
    this.requestTypes = await this.requestService.getRequestTypes().toPromise();

    const trades = await this.projectService.getTrades().toPromise();
    this.trades = sortBy(trades, (trade) => trade.name);

    this.allArchitects = await this.userService.getUsersByRole(ApplicationRole.ModuleArchitect, 1).toPromise();
    this.allWorkspaceManagers = await this.userService.getUsersByRole(ApplicationRole.WorkspaceManager, 1).toPromise();
    this.allCFMOs = await this.userService
      .getUsersByRole(ApplicationRole.ChiefFacilitiesManagementOfficer, 1)
      .toPromise();
    this.allProjectManagers = await this.userService
      .getUsersByRole(ApplicationRole.ModuleProjectManager, 1)
      .toPromise();
    this.allEngineers = await this.userService.getUsersByRole(ApplicationRole.ModuleEngineer, 1).toPromise();

    // TODO get these from the database. Currently there is no api for these, but there needs to be if we add in other statuses
    this.allPriorities = [
      { id: 1, name: 'Low' },
      { id: 2, name: 'Medium' },
      { id: 3, name: 'High' },
    ];

    this.getYears();
  }

  async refresh(project?: ProjectConstruction) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Refreshing...');
    this.projectDetailsForm.disable();
    this.loading = true;
    if (project) {
      this.shownProject = project;
    } else if (this.project && this.project.id) {
      this.shownProject = await this.projectService
        .getProjectById(this.project.id, this.projectFields, true)
        .toPromise();
    }
    if (this.shownProject) {
      this.title.setValue(this.shownProject.title);
      this.scopeOfWork.setValue(this.shownProject.scope_of_work);
      this.priorityLevelId.setValue(this.shownProject.priority_id);
      this.requestType.setValue(this.shownProject.request_type_id);
      this.buildingId.setValue(this.shownProject.building_id);
      this.floorId.setValue(this.shownProject.floor_id);
      this.suiteId.setValue(this.shownProject.suite_id);
      this.departmentId.setValue(this.shownProject.department_id);
      this.populateLocationData();
      this.squareFootage.setValue(this.shownProject.square_footage);
      this.architectId.setValue(this.shownProject.architect_id);
      this.workspaceManagerId.setValue(this.shownProject.workspace_manager_id);
      this.cfmoId.setValue(this.shownProject.cfmo_id);
      this.projectManagerId.setValue(this.shownProject.project_manager_id);
      this.engineerIds.setValue(JSON.parse(this.shownProject.engineer_ids));
      if (this.shownProject.floor_plans) {
        this.floorPlans = JSON.parse(JSON.stringify(this.shownProject.floor_plans));
      }
      if (this.shownProject.misc_files) {
        this.miscFiles = JSON.parse(JSON.stringify(this.shownProject.misc_files));
      }
      this.fycReason.setValue(this.shownProject.fyc_reason);
      this.fycApprovedFiscalYear.setValue(this.shownProject.fyc_approved_fiscal_year);
      this.fycProjectYear.setValue(this.shownProject.fyc_project_year);
      this.fycApprovedBudget.setValue(this.shownProject.fyc_approved_budget);
      this.fycPriority.setValue(this.shownProject.fyc_priority_id);
      this.fycImpact.setValue(this.shownProject.fyc_impact);
      this.fycLikely.setValue(this.shownProject.fyc_likely);
      this.shownProject.engineers = filter(
        this.allEngineers,
        (e) => this.shownProject.engineer_ids && this.shownProject.engineer_ids.indexOf(e.id) > -1
      );
      const projectTrades = JSON.parse(this.shownProject.trades);
      this.shownProject.selected_trades = filter(
        this.trades,
        (t) => this.shownProject.trades && projectTrades.indexOf(+t.id) > -1
      );
      if (projectTrades) {
        for (const t of this.trades) {
          t.is_selected = projectTrades.indexOf(t.id) > -1;
        }
      }
    }

    this.unsavedChangesExist = false;
    this.loading = false;
    this.isEditing = false;
    this.progressIndicatorService.close();
  }

  async populateLocationData() {
    [this.buildings, this.floors] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildings: Building[] = await this.locationService.getBuildings().toPromise();
    const floors: Floor[] = await this.locationService.getFloorsByBuilding(this.buildingId.value).toPromise();
    const suites: Suite[] = await this.locationService.getSuitesByFloor(this.floorId.value).toPromise();
    const departments: Department[] = await this.locationService.getDepartmentsBySuite(this.suiteId.value).toPromise();
    this.buildings = buildings;
    this.floors = floors;
    this.suites = suites;
    this.departments = departments;
  }

  getBuildings() {
    this.locationService.getBuildings().subscribe((buildings: Building[]) => {
      this.buildings = buildings;
    });
  }

  getFloors(buildingId: number) {
    if (buildingId) {
      this.locationService.getFloorsByBuilding(buildingId).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floorId.setValue(null);
        this.suites = [];
        this.suiteId.setValue(null);
        this.departments = [];
        this.departmentId.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floorId.setValue(null);
      this.suites = [];
      this.suiteId.setValue(null);
      this.departments = [];
      this.departmentId.setValue(null);
    }
  }

  getSuites(floorId: number) {
    if (floorId) {
      this.locationService.getSuitesByFloor(floorId).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suiteId.setValue(null);
        this.departments = [];
        this.departmentId.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suiteId.setValue(null);
      this.departments = [];
      this.departmentId.setValue(null);
    }
  }

  getDepartments(suiteId: number) {
    if (suiteId) {
      this.locationService.getDepartmentsBySuite(suiteId).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.departmentId.setValue(null);
      });
    } else {
      this.departments = null;
      this.departmentId.setValue(null);
    }
  }

  getYears() {
    const currentYear = new Date().getFullYear();
    this.years.push(currentYear);
    for (let i = 1; i <= 5; i++) {
      this.years.push(currentYear - i);
    }
    for (let i = 1; i <= 10; i++) {
      this.years.push(currentYear + i);
    }

    this.years = this.years.sort((n1, n2) => n1 - n2);
  }

  getNumberRange(end: number): number[] {
    const nums = [];
    for (let i = 1; i <= end; i++) {
      nums.push(i);
    }
    return nums;
  }

  openFileSelectDialog(file: 'floor_plan' | 'misc') {
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.project.id,
          allowComment: false,
          allowSearchFromProject: true,
          preSelectedTags: [{ id: file === 'floor_plan' ? 78 : 16 }],
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          resultData.forEach((f) => {
            if (file === 'floor_plan') {
              this.selectFloorPlan(f);
            } else {
              this.selectMiscFile(f);
            }
          });
        }
      });
  }

  selectFloorPlan(file) {
    if (file) {
      this.unsavedChangesExist = true;
      this.floorPlans.push(file);
    }
  }

  removeFloorPlanFile(file) {
    if (file) {
      this.unsavedChangesExist = true;
      this.floorPlans.splice(
        this.floorPlans.findIndex((f) => f.id === file.id),
        1
      );
    }
  }

  selectMiscFile(file) {
    if (file) {
      this.unsavedChangesExist = true;
      this.miscFiles.push(file);
    }
  }

  removeMiscFile(file) {
    if (file) {
      this.unsavedChangesExist = true;
      this.miscFiles.splice(
        this.miscFiles.findIndex((f) => f.id === file.id),
        1
      );
    }
  }

  toggleTradeSelection(trade, isSelected) {
    this.unsavedChangesExist = true;
    trade.is_selected = isSelected;
  }

  async saveProjectDetails() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const projectToUpdate: ProjectConstruction = {};
    if (this.unsavedChangesExist && this.shownProject && this.shownProject.id) {
      this.loading = true;
      if (this.title.value && this.shownProject.title !== this.title.value) {
        projectToUpdate.title = this.title.value;
      }
      if (this.scopeOfWork.value && this.shownProject.scope_of_work !== this.scopeOfWork.value) {
        projectToUpdate.scope_of_work = this.scopeOfWork.value;
      }
      if (this.priorityLevelId.value && this.shownProject.priority_id !== this.priorityLevelId.value) {
        projectToUpdate.priority_id = this.priorityLevelId.value;
      }
      if (this.shownProject.fyc_reason !== this.fycReason.value) {
        projectToUpdate.fyc_reason = this.fycReason.value;
      }
      if (this.shownProject.fyc_approved_fiscal_year !== this.fycApprovedFiscalYear.value) {
        projectToUpdate.fyc_approved_fiscal_year = this.fycApprovedFiscalYear.value;
      }
      if (this.shownProject.fyc_project_year !== this.fycProjectYear.value) {
        projectToUpdate.fyc_project_year = this.fycProjectYear.value;
      }
      if (this.shownProject.fyc_approved_budget !== this.fycApprovedBudget.value) {
        projectToUpdate.fyc_approved_budget = this.fycApprovedBudget.value;
      }
      if (this.shownProject.fyc_priority_id !== this.fycPriority.value) {
        projectToUpdate.fyc_priority_id = this.fycPriority.value;
      }
      if (this.shownProject.fyc_impact !== this.fycImpact.value) {
        projectToUpdate.fyc_impact = this.fycImpact.value;
      }
      if (this.shownProject.fyc_likely !== this.fycLikely.value) {
        projectToUpdate.fyc_likely = this.fycLikely.value;
      }
      if (this.requestType.value && this.shownProject.request_type_id !== this.requestType.value) {
        projectToUpdate.request_type_id = this.requestType.value;
      }
      if (this.buildingId.value && this.shownProject.building_id !== this.buildingId.value) {
        projectToUpdate.building_id = this.buildingId.value;
      }
      if (this.floorId.value && this.shownProject.floor_id !== this.floorId.value) {
        projectToUpdate.floor_id = this.floorId.value;
      }
      if (this.suiteId.value && this.shownProject.suite_id !== this.suiteId.value) {
        projectToUpdate.suite_id = this.suiteId.value;
      }
      if (this.departmentId.value && this.shownProject.department_id !== this.departmentId.value) {
        projectToUpdate.department_id = this.departmentId.value;
      }
      if (this.squareFootage.value && this.shownProject.square_footage !== this.squareFootage.value) {
        projectToUpdate.square_footage = this.squareFootage.value;
      }
      if (
        this.trades &&
        this.shownProject.trades !==
          JSON.stringify(
            map(
              filter(this.trades, (t) => t.is_selected),
              'id'
            )
          )
      ) {
        projectToUpdate.trades = JSON.stringify(
          map(
            filter(this.trades, (t) => t.is_selected),
            'id'
          )
        );
      }
      if (this.workspaceManagerId.value && this.shownProject.workspace_manager_id !== this.workspaceManagerId.value) {
        projectToUpdate.workspace_manager_id = this.workspaceManagerId.value;
      }
      if (this.projectManagerId.value && this.shownProject.project_manager_id !== this.projectManagerId.value) {
        projectToUpdate.project_manager_id = this.projectManagerId.value;
      }
      if (this.cfmoId.value && this.shownProject.cfmo_id !== this.cfmoId.value) {
        projectToUpdate.cfmo_id = this.cfmoId.value;
      }
      if (this.architectId.value && this.shownProject.architect_id !== this.architectId.value) {
        projectToUpdate.architect_id = this.architectId.value;
      }
      if (this.engineerIds.value && this.shownProject.engineer_ids !== JSON.stringify(this.engineerIds.value)) {
        projectToUpdate.engineer_ids = JSON.stringify(this.engineerIds.value);
      }
      if (this.shownProject && this.shownProject.id) {
        // 78 is the floor plan tag id
        await this.updateFileTags(this.shownProject.floor_plans, this.floorPlans, 78);
        await this.projectService
          .updateProjectFloorPlans(
            this.shownProject.id,
            this.floorPlans.length > 0 ? this.floorPlans.map((f) => f.file_id || f.id) : []
          )
          .toPromise();
      }
      if (this.shownProject && this.shownProject.id) {
        // 16 is the misc file tag id
        await this.updateFileTags(this.shownProject.misc_files, this.miscFiles, 16);
        await this.projectService
          .updateProjectMiscFiles(
            this.shownProject.id,
            this.miscFiles.length > 0 ? this.miscFiles.map((f) => f.file_id || f.id) : []
          )
          .toPromise();
      }
      let updatedProject;
      if (Object.keys(projectToUpdate).length > 0) {
        updatedProject = await this.projectService
          .updateProject(this.shownProject.id, projectToUpdate, this.projectFields)
          .toPromise();
      }
      this.snackbar.open('Project Details Saved!');
      this.refresh(updatedProject);
    }
    this.progressIndicatorService.close();
  }

  private async updateFileTags(originalValues, newValues, tagId) {
    // map the ids
    const originalIds = originalValues ? originalValues.map((file) => +file.file_id || +file.id) : [];
    const newIds = newValues ? newValues.map((file) => +file.file_id || +file.id) : [];

    // find the differences between the original files and the current files
    const toAdd: number[] = difference(newIds, originalIds);
    const toRemove: number[] = difference(originalIds, newIds);

    // add the appropriate tag to all new files
    for (const file of toAdd) {
      await this.fileService.addTags(file, [tagId]).toPromise();
    }
    // remove the appropriate tag from all old files. No need to await, since the file won't be displayed anyways
    for (const file of toRemove) {
      this.fileService.removeTags(file, [tagId]).subscribe();
    }
  }

  exportProjectDetails() {
    this.downloading = true;
    if (this.unsavedChangesExist) {
      this.snackbar.open('You have unsaved changes. The latest saved version of the form will be exported.');
    }
    this.pdf.saveAs(`Project_Details_${this.shownProject.code}.pdf`);
    this.downloading = false;
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  toggleFormEdit(): void {
    if (this.projectDetailsForm.disabled) {
      this.projectDetailsForm.enable();
      this.isEditing = true;
    } else {
      this.projectDetailsForm.disable();
      this.isEditing = false;
    }
  }

  public openTenantSelectionDialog() {
    this.dialog
      .open(TenantSelectDialogComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((result) => {
        // console.log('Tenant Select Result', result);
      });
  }

  viewProjectDetails() {
    this.dialog
      .open(ProjectRequestDetailsModalComponent)
      .afterClosed()
      .subscribe((result) => {});
  }

  public hasImageFilesWithBase64(files: UhatFileReference[]): boolean {
    return files && files.filter((f) => f.base64 != null).length > 0;
  }

  public projectDetailsHasImages(): boolean {
    return this.hasImageFilesWithBase64(this.miscFiles) || this.hasImageFilesWithBase64(this.floorPlans);
  }
}
