<div id="update-pop-up" *ngIf="updateFound">
  <h3>Update Available</h3>
  <div>
    <p>
      A new version of 1CALL Cloud is available. Please refresh your browser to access the latest features and
      improvements.
    </p>
  </div>
  <div>
    <button mat-button color="accent" (click)="reload()">Refresh</button>
    <button mat-button (click)="cancel()">Later</button>
  </div>
</div>
