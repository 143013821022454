<app-base-dialog [title]="(data?.invoice?.id ? 'Edit' : 'New') + ' Invoice'" (closeDialog)="cancel()"></app-base-dialog>

<form
  mat-dialog-content
  class="new-invoice-dialog project-modal bg-shade-gray p-4"
  style="width: 1000px"
  [formGroup]="invoiceFormGroup"
>
  <div class="row m-0">
    <div class="col-7 pl-0" [ngClass]="{ 'mx-auto': this.arf?.id }">
      <div class="m-portlet p-0 mb-3">
        <div class="m-portlet__body invoice-header d-flex flex-column p-4">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-auto">
              <h5 class="m-0 dkblue font-weight-semibold mr-3">Invoice #</h5>
              <mat-form-field appearance="outline" style="width: 100px">
                <input matInput placeholder="#" formControlName="invoice_number" />
              </mat-form-field>
            </div>
            <div class="d-flex flex-column text-right ml-auto">
              <h6 class="mb-2 dkblue font-weight-semibold">Submitted Date</h6>
              <h5 class="m-0 dkblue font-weight-normal">
                {{ data?.invoice?.created_datetime || today | date: 'MM/dd/yyyy' }}
              </h5>
            </div>
          </div>
        </div>
        <div
          class="m-portlet__body d-flex flex-column px-4 pb-4 pt-2 border-bottom border-gray"
          [ngClass]="{ 'pt-4': updateInvoice }"
        >
          <div class="d-flex flex-column">
            <p class="gray mb-4 text-center" *ngIf="!updateInvoice">
              <small> Editing any field below this line will require the review to be reset </small>
            </p>
            <div class="d-flex align-items-start mb-4">
              <!-- I THINK THIS CAN BE USED FOR TITLE 74 PROJECTS INSTEAD OF THE TRADE/SUPPLIER SELECT -->
              <!-- <div *ngIf="currentUser && currentUser.company_name && !authService.isStaff" class="d-flex flex-column flex-grow-1 pr-5">
                    <h6 class="dkblue mb-2 font-weight-semibold">
                      Supplier Company
                    </h6>
                    <h5 class="dkgray font-weight-normal m-0">
                      {{ currentUser.company_name }}
                    </h5>
                  </div> -->
              <div class="d-flex flex-md-column flex-fill pr-2">
                <h6 class="dkblue font-weight-semibold mb-2">
                  {{ currentWorkspace === eWorkspace.Construction ? 'Trade/Supplier' : 'Supplier' }}
                </h6>
                <mat-form-field appearance="outline" *ngIf="currentWorkspace === eWorkspace.Construction && !arf?.id">
                  <mat-select formControlName="bid_package_id" (ngModelChange)="bidPackageChanged()">
                    <mat-option
                      class="d-flex flex-column align-items-start"
                      *ngFor="let bp of awardedBidPackages"
                      [value]="bp.id"
                    >
                      <p class="m-0 lh-20 dkblue font-weight-semibold">
                        {{ bp.trade_name }}
                      </p>
                      <p class="dkgray m-0 lh-20">- {{ bp.awarded_company_name }}</p>
                    </mat-option>
                    <mat-option *ngFor="let bp of noBidPackages" [value]="bp.id">
                      <p class="m-0 lh-20 dkblue font-weight-semibold">
                        {{ bp.trade_name }}
                      </p>
                    </mat-option>
                    <mat-option *ngFor="let t of allowedTradesWithoutBidPackages" (click)="promptNewBidPackage(t.id)">
                      <p class="m-0 lh-20 dkblue font-weight-semibold">
                        {{ t.name }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="currentWorkspace !== eWorkspace.Construction && !arf?.id">
                  <mat-select formControlName="quote_id" (ngModelChange)="quoteChanged(true)">
                    <mat-option
                      class="d-flex flex-column align-items-start"
                      *ngFor="let aq of awardedQuotes"
                      [value]="aq.id"
                    >
                      <p class="m-0 dkblue font-weight-semibold lh-20">{{ aq.company?.name }}&nbsp;</p>
                      <p class="m-0 dkgray font-weight-normal lh-20">
                        {{ aq.awarded_amount | currency }} | {{ aq?.tenant_name }}
                        {{ aq.description ? ' | ' + aq.description : '' }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="arf?.id">
                  <input matInput type="text" [value]="arf.company?.name" [disabled]="true" />
                </mat-form-field>
              </div>
              <div class="d-flex flex-md-column flex-fill pl-2">
                <h6 class="mb-2 dkblue font-weight-semibold">Date of Service</h6>
                <mat-form-field appearance="outline" *ngIf="is_single_date?.value">
                  <input
                    matInput
                    required="true"
                    placeholder="MM/DD/YYYY"
                    (click)="invoiceDatePicker.open()"
                    [matDatepicker]="invoiceDatePicker"
                    autocomplete="off"
                    formControlName="invoice_date"
                  />
                  <mat-datepicker-toggle matSuffix class="dkgray" [for]="invoiceDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #invoiceDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="!is_single_date?.value">
                  <mat-date-range-input [rangePicker]="picker">
                    <input (focus)="picker.open()" matStartDate formControlName="invoice_date" placeholder="Start" />
                    <input (focus)="picker.open()" matEndDate formControlName="invoice_end_date" placeholder="End" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix class="dkgray" [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <p class="accent hover-u pointer py-2 m-0" (click)="toggleInvoiceDateType()">
                  {{ is_single_date?.value ? 'Select Date Range' : 'Select Single Date' }}
                </p>
              </div>
            </div>
            <div class="d-flex mb-2">
              <h6 class="mb-0 dkblue font-weight-semibold">Invoice For</h6>
            </div>
            <div class="row align-items-center m-0">
              <div class="col-4 pl-0">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-select formControlName="change_order" [compareWith]="changeOrderCompare">
                    <mat-option [value]="{ id: null }"> Original Bid </mat-option>
                    <mat-option [value]="co" *ngFor="let co of changeOrders">
                      CO #{{ co.local_index }} - <span [innerHTML]="co.short_description"></span> ({{
                        co.cost_change > 0 ? '+' : ''
                      }}{{ co.cost_change | currency: 'USD' }})
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="pl-0 pr-0 col-5">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  *ngIf="currentWorkspace !== eWorkspace.Construction"
                >
                  <input
                    matInput
                    placeholder="Describe this Invoice"
                    formControlName="title"
                    [ngClass]="{
                      'text-danger': title?.value?.length > title_length
                    }"
                  />
                  <mat-hint
                    align="end"
                    [ngClass]="{
                      red: title?.value?.length > title_length,
                      gray: title?.value?.length < title_length || title?.value?.length == title_length
                    }"
                    >{{ title_counter }}</mat-hint
                  >
                  <!-- <mat-error *ngIf="title.errors?.maxlength">Max length of {{ title_length }} characters</mat-error> -->
                </mat-form-field>
                <!-- IF CONSTRUCTION -->
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  *ngIf="currentWorkspace === eWorkspace.Construction"
                >
                  <mat-select formControlName="is_retainage" (ngModelChange)="isRetainageChanged()">
                    <mat-option [value]="0">
                      <div class="d-flex flex-column">
                        <h6 class="dkblue mb-0 font-weight-semibold">Original Contract Sum</h6>
                        <!-- <span class="dkgray font-weight-normal">
                          {{
                            +invoiceData?.invoiceForBalanceToFinishBefore
                              | currency: 'USD'
                          }}
                        </span> -->
                      </div>
                    </mat-option>
                    <mat-option *ngIf="+invoiceData?.retainageTotalBefore !== 0" [value]="1">
                      <div class="d-flex flex-column">
                        <h6 class="dkblue mb-0 font-weight-semibold">Retainage</h6>
                        <span class="dkgray font-weight-normal">
                          {{ +invoiceData?.invoiceForRetainageTotalBefore | currency: 'USD' }}
                        </span>
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-3 pr-0 text-right">
                <mat-form-field class="full-width text-right" appearance="outline">
                  <span class="primary" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    placeholder="0.00"
                    type="number"
                    step="0.01"
                    formControlName="total"
                    (blur)="totalBlur()"
                    (input)="setArfInvoiceAmount()"
                  />
                </mat-form-field>
              </div>
            </div>

            <!-- <div
              class="row align-items-center m-0 pt-3 align-items-stretch"
              *ngIf="currentWorkspace !== eWorkspace.Construction && shipping.value > 0"
            >
              <div class="col pl-0"></div>
              <div class="col-5 pb-3 pl-0 border-bottom border-gray d-flex flex-column">
                <p class="dkblue mb-0 mt-3 font-weight-semibold lh-18">Shipping</p>
              </div>
              <div class="col-3 pr-0 text-right pb-3 border-bottom border-gray">
                <mat-form-field class="full-width text-right" appearance="outline">
                  <span class="gray" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    placeholder="0.00"
                    type="number"
                    step="0.01"
                    formControlName="shipping"
                    (blur)="shippingBlur()"
                  />
                </mat-form-field>
              </div>
            </div> -->
            <!-- <div
              class="row align-items-center m-0 pt-3 align-items-stretch"
              *ngIf="currentWorkspace !== eWorkspace.Construction && tax.value > 0"
            >
              <div class="col pl-0"></div>
              <div class="col-5 pb-3 pl-0 border-bottom border-gray d-flex flex-column">
                <p class="dkblue mb-0 mt-3 font-weight-semibold lh-18">Sales Tax</p>
              </div>
              <div class="col-3 pr-0 text-right pb-3 border-bottom border-gray">
                <mat-form-field class="full-width text-right" appearance="outline">
                  <span class="gray" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    placeholder="0.00"
                    type="number"
                    step="0.01"
                    formControlName="tax"
                    (blur)="taxBlur()"
                  />
                </mat-form-field>
              </div>
            </div> -->
          </div>
        </div>
        <div
          *ngIf="currentWorkspace === eWorkspace.Construction && !this.arf?.id"
          class="m-portlet__body d-flex flex-column p-4 border-bottom border-gray"
        >
          <div class="row align-items-center m-0 align-items-stretch">
            <div class="col pl-0"></div>
            <div class="col-5 pl-0 d-flex flex-column">
              <p class="dkblue mb-0 mt-0 font-weight-semibold lh-18">Retainage Withheld</p>
              <p class="gray mb-0 font-weight-normal lh-18">5% Retainage Required</p>
            </div>
            <div class="col-3 pr-0 text-right">
              <mat-form-field class="full-width text-right" appearance="outline">
                <span
                  [ngClass]="{
                    green: retainagePercentage >= 0.049999,
                    red: retainagePercentage < 0.049999
                  }"
                  matPrefix
                  >{{ retainagePercentage | percent: '1.1-1' }}&nbsp;</span
                >
                <input
                  matInput
                  placeholder="0.00"
                  type="number"
                  step="0.01"
                  formControlName="retainage"
                  (blur)="retainageBlur()"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="m-portlet__body p-0 row m-0 align-items-stretch">
          <div class="col-6 p-4 border-right border-gray">
            <button
              *ngIf="!isLoading && existingFiles.length + newFiles.length < 1"
              mat-flat-button
              type="button"
              color="accent"
              (click)="openUploadModal()"
              class="pl-2 pr-3"
            >
              <mat-icon>attach_file</mat-icon>
              <span class="ml-1"> Attach Invoice </span>
            </button>
            <mat-chip-list class="d-inline-block align-middle">
              <app-file-chip
                *ngFor="let f of existingFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeExistingFile(f)"
              ></app-file-chip>
              <app-file-chip
                *ngFor="let f of newFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeNewFile(f)"
              ></app-file-chip>
            </mat-chip-list>
            <br />
            <h6
              class="m-0 mt-2 font-weight-normal"
              *ngIf="!isLoading"
              [ngClass]="{
                red: existingFiles.length + newFiles.length < 1,
                green: existingFiles.length + newFiles.length > 0
              }"
            >
              <i *ngIf="existingFiles.length + newFiles.length > 0" class="fa fa-check green mr-1"></i>
              {{
                existingFiles.length + newFiles.length < 1
                  ? 'Required. Must be PDF, JPG, or PNG.'
                  : 'Valid file extension'
              }}
            </h6>
          </div>
          <div class="d-flex flex-column col-6 p-4 bg-shade-ltblue text-right">
            <p class="dkblue mb-2 mt-0 font-weight-semibold lh-18">Amount Due</p>
            <!-- <p class="gray mb-0 font-weight-normal lh-18" *ngIf="currentWorkspace === eWorkspace.Construction">
                Total - Ret = Amount Due
              </p> -->
            <h2 class="dkblue m-0">
              {{
                (+total.value || 0) - (+retainage.value || 0) + (+shipping.value || 0) + (+tax.value || 0)
                  | currency: 'USD'
              }}
            </h2>
          </div>
        </div>
        <!-- <div>
          <div>
            <div class="row align-items-start m-0 pt-3 mb-4">
              <div class="col pl-0"></div>
              <div class="col-5 pb-3 pl-0 d-flex flex-column"></div>
              <div class="col-3 pr-0 text-right pb-3">
                <h4 class="dkblue mb-0 mt-0 pr-2">
                  {{
                    (+total.value || 0) - (+retainage.value || 0) + (+shipping.value || 0) + (+tax.value || 0)
                      | currency: 'USD'
                  }}
                </h4>
              </div>
            </div>
            <div class="row align-items-center m-0 mb-4">
              <div class="col pl-0"></div>
              <div class="col-8 pb-2 pl-0"></div>
            </div>
          </div>
        </div> -->
      </div>
      <div
        class="d-flex flex-column align-items-center p-5 mx-auto"
        *ngIf="!initialArfInvoiceAmounts && (arf?.products?.length || selectedQuoteHasCostCodes)"
      >
        <mat-spinner color="accent" [diameter]="40"></mat-spinner>
      </div>
      <div
        [hidden]="isSupplier"
        class="m-portlet p-0 m-0"
        *ngIf="
          initialArfInvoiceAmounts &&
          ((allowAdditionalCodes && authService.isStaffOnAnyModule) ||
            arf?.products?.length ||
            selectedQuoteHasCostCodes)
        "
      >
        <div class="m-portlet__body d-flex flex-column p-4">
          <app-cost-code-selection
            [parentId]="data?.invoice?.project_id || arf?.id"
            [arfInvoiceAmounts]="initialArfInvoiceAmounts"
            [subCostCodeBudgets]="subCostCodeBudgets"
            [allowAdditionalCodes]="allowAdditionalCodes"
            [moduleId]="data?.invoice?.module_id || currentWorkspace"
            (closeDialog)="cancel()"
          ></app-cost-code-selection>
        </div>
        <div class="m-portlet__body d-flex p-4 border-top border-gray bg-shade-accent align-items-center">
          <mat-icon
            class="mr-2"
            [ngClass]="{
              green: !invoiceTotalDoesNotMatchArfTotal,
              red: invoiceTotalDoesNotMatchArfTotal
            }"
            >{{ invoiceTotalDoesNotMatchArfTotal ? 'cancel' : 'check_circle' }}</mat-icon
          >
          <p
            class="mb-0 mr-auto"
            [ngClass]="{
              green: !invoiceTotalDoesNotMatchArfTotal,
              red: invoiceTotalDoesNotMatchArfTotal
            }"
          >
            {{
              !invoiceTotalDoesNotMatchArfTotal ? 'Perfectly allocated' : 'Invoice total must be allocated perfectly'
            }}
          </p>
          <h4 class="primary ml-3 mb-0">{{ this.arfInvoiceTotal || 0 | currency: 'USD' }}</h4>
        </div>
      </div>
    </div>
    <div class="col-5 pl-2 pr-0" *ngIf="!this.arf?.id">
      <app-invoice-data
        #invoiceData
        class="px-0 d-flex flex-column"
        [bidPackage]="selectedBidPackage"
        [quote]="selectedQuote"
        [changeOrder]="change_order.value"
        [invoice]="currentInvoice"
        [currentWorkspace]="currentWorkspace"
        [isLoading]="data?.invoice?.id && !selectedBidPackage?.id && !selectedQuote?.id"
      ></app-invoice-data>
    </div>
  </div>
</form>
<mat-dialog-actions align="end" class="p-4">
  <button mat-button (click)="cancel()" mat-button type="button" class="gray">Cancel</button>
  <button
    mat-flat-button
    type="button"
    (click)="confirmSubmit()"
    color="success"
    class="ml-4"
    [disabled]="
      !this.invoiceFormGroup.valid ||
      this.existingFiles.length + this.newFiles.length <= 0 ||
      ((!subCostCodesSelected || (!!arfInvoiceAmounts?.length && invoiceTotalDoesNotMatchArfTotal)) && !isSupplier)
    "
  >
    {{ data?.invoice?.id ? 'Save' : 'Submit' }} Invoice
  </button>
</mat-dialog-actions>
