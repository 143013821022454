import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { FileService, ProjectService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { EventType, ResourceType } from '../../../../enums';

@Component({
  selector: 'app-contract-files-dialog',
  templateUrl: './contract-files-dialog.component.html',
  styleUrls: ['./contract-files-dialog.component.scss'],
})
export class ContractFilesDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContractFilesDialogComponent>,
    private fileService: FileService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  taskId: number;
  files: UhatFileReference[] = [];
  selectedFileId: number;

  async ngOnInit() {
    this.taskId = this.data?.taskId;

    if (this.taskId) {
      const activities = await this.projectService.loadTaskActivity(this.taskId).toPromise();
      const notes = activities.notes;
      const approvals = [];

      for (const event of activities.events) {
        if (event.message === '2') {
          event.files = await this.fileService.getFilesByParentId(ResourceType.Event, event.id).toPromise();

          event.type = EventType.APPROVED;
          if (event?.files?.length) {
            approvals.push(event);
          }
        }
      }

      notes.concat(approvals).forEach((activity) => {
        if (activity.files && activity.files.length) {
          activity.files.forEach((file) => {
            if (!this.files.length) {
              this.files.push(file);
            } else if (!this.files.filter((f) => f.id === file.id).length) {
              this.files.push(file);
            }
          });
        }
      });
    } else {
      this.files = this.data?.files || [];
    }
  }

  public selectContract(event) {
    this.selectedFileId = event.value;
  }

  public submit() {
    this.dialogRef.close(this.selectedFileId);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
