<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"> </app-base-dialog>
<div id="project-update-dialog" data-test-id="project-update-dialog" class="project-update-dialog">
  <form mat-dialog-content [formGroup]="projectUpdateFormGroup">
    <div *ngIf="projectHealthTypes.length > 0 && isWorkspaceStaff" class="row mb-4">
      <div class="col">
        <h6 class="font-weight-semibold dkblue mb-3">Project Health</h6>
        <div class="d-flex align-items-center br-5 p-1 border border-gray m-0 align-items-center">
          <div class="col-3 pl-0 pr-0" *ngFor="let projectHealthType of projectHealthTypes">
            <div
              matRipple
              class="p-2 pointer text-center br-5"
              [ngClass]="{
                'bg-gray white': projectHealthType.id === null && projectHealthTypeId === projectHealthType.id,
                'bg-green white': projectHealthType.id === 1 && projectHealthTypeId === projectHealthType.id,
                'bg-orange white': projectHealthType.id === 2 && projectHealthTypeId === projectHealthType.id,
                'bg-red white': projectHealthType.id === 3 && projectHealthTypeId === projectHealthType.id
              }"
              (click)="selectHealth(projectHealthType.id)"
            >
              <h6
                class="m-0"
                [ngClass]="{
                  white: projectHealthTypeId === projectHealthType.id,
                  white: projectHealthTypeId === projectHealthType.id,
                  white: projectHealthTypeId === projectHealthType.id,
                  white: projectHealthTypeId === projectHealthType.id
                }"
              >
                {{ projectHealthType.name }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col d-flex flex-column">
        <div class="row mb-1">
          <div class="col update-textarea">
            <h6 class="dkblue font-weight-semibold mb-3">Update Message</h6>
            <app-editor #editor [auto_focus]="auto_focus" placeholder="How's the project going?"></app-editor>
          </div>
        </div>
        <app-aidet class="full-width mb-3"></app-aidet>
        <div class="row mt-3 mb-4">
          <div class="col">
            <mat-chip-list class="d-inline-block align-middle px-1">
              <button
                mat-stroked-button
                type="button"
                color="accent"
                class="mr-3 pl-2 pr-3"
                (click)="openUploadModal(projectUpdateId)"
              >
                <mat-icon>attach_file</mat-icon>
                <span *ngIf="!existingFiles?.length"> Attach Files </span>
              </button>
              <app-file-chip
                *ngFor="let f of existingFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeExistingFile(f)"
              ></app-file-chip>
              <app-file-chip
                *ngFor="let f of addedFiles"
                [file]="f"
                [removable]="true"
                (removeEvent)="removeNewFile(f)"
              ></app-file-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isWorkspaceStaff" class="d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column mr-auto">
          <h6 class="dkblue m-0">Project Completion</h6>
          <p class="dkgray mb-0">Confirm current percentage complete to best estimation</p>
        </div>
        <!--if set even 0% is accounted for-->
        <h5 *ngIf="projectEndDateEstimate?.value !== null" class="m-0 p-2 br-5 bg-ltgray dkblue">
          {{ projectEndDateEstimate?.value }}%
        </h5>
        <!-- if it has not value set-->
        <h5 *ngIf="projectEndDateEstimate?.value === null" class="m-0 p-2 br-5 bg-ltgray dkblue">Not set</h5>
      </div>
      <mat-slider
        color="accent"
        class="full-width mb-2"
        thumbLabel
        [min]="minValue"
        [max]="maxValue"
        [step]="projectEndDateEstimateStep"
        [formControl]="projectEndDateEstimate"
        (change)="updateEndDateEstimation()"
      >
        <!-- <input matSliderThumb [value]="projectEndDateEstimate?.value" #slider /> --><!--Not needed??-->
      </mat-slider>
    </div>
    <div *ngIf="isWorkspaceStaff" class="d-flex flex-column">
      <div class="d-flex flex-column mr-auto">
        <h6 class="dkblue m-0">Estimated Completion Date</h6>
        <p class="dkgray mb-0">Confirm the current estimated completion date</p>
      </div>
      <div class="d-flex">
        <span class="d-flex align-items-center">
          <!-- <h6 class="dkblue m-0 font-weight-semibold mb-1">Estimated Completion:</h6> -->
          <h4 (click)="completionDatePicker?.open()" class="m-0 gray font-weight-semibold">
            <span *ngIf="projectEndDate?.value" class="font-weight-normal ease ltblue pointer">
              {{ formatedProjectEndDate }}
            </span>
          </h4>
          <input
            matInput
            [matDatepicker]="completionDatePicker"
            (dateChange)="updateEndDate()"
            autocomplete="off"
            style="visibility: hidden; width: 0"
            [formControl]="projectEndDate"
          />
          <mat-datepicker #completionDatePicker [calendarHeaderComponent]="customDateHeaderNoClear"></mat-datepicker>
        </span>
        <button
          (click)="completionDatePicker.open()"
          mat-icon-button
          color="accent"
          class="ml-4 d-inline-block align-top"
        >
          <mat-icon>today</mat-icon>
        </button>
      </div>
    </div>
    <div class="row mt-4 mb-3" *ngIf="!projectUpdateId && isWorkspaceStaff">
      <mat-radio-group class="col" formControlName="everyoneVisibility">
        <h6 class="font-weight-semibold dkblue mb-2">Update Visible To*</h6>
        <mat-radio-button matNativeControl class="mr-4 project-update-checkbox-item" [value]="1">
          <span class="dkblue">Everyone</span>
        </mat-radio-button>
        <mat-radio-button class="project-update-checkbox-item" [value]="0">
          <span class="dkblue">Staff Only</span>
        </mat-radio-button>
      </mat-radio-group>
      <mat-radio-group class="col" formControlName="notify_followers">
        <h6 class="font-weight-semibold dkblue mb-2">Send Notifications*</h6>
        <mat-radio-button class="mr-4 project-update-checkbox-item" [value]="1">
          <span class="dkblue">Send</span>
        </mat-radio-button>
        <mat-radio-button class="project-update-checkbox-item" [value]="0">
          <span class="dkblue">Don't Send</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div
      class="d-flex flex-column p-3 br-10 bg-shade-gray"
      *ngIf="!projectUpdateId && isWorkspaceStaff && notify_followers?.value && projectUsersToNotify?.length"
    >
      <p class="dkgray mb-1">This update will notify:</p>
      <div class="d-flex align-items-top">
        <p class="dkblue m-0 text-wrap font-weight-semibold">
          <!--1 user: no commas, no and, 2 users: and, mrore than 2 users: commas and a comma before and-->
          <span *ngFor="let user of projectUsersToNotify; let index = index; let last = last"
            >{{ index > 0 ? (last ? (index > 1 ? ', and ' : ' and ') : ', ') : '' }}{{ user?.first_name }}
            {{ user?.last_name }}</span
          >
        </p>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="row pt-5 pb-3 align-items-center">
    <div class="col">
      <button
        data-test-id="create-meeting-dialog-cancel-button"
        mat-button
        class="gray full-width"
        type="button"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
    <div class="col text-right">
      <button
        mat-flat-button
        *ngIf="!isLoading"
        class="full-width"
        color="success"
        type="submit"
        (click)="submit()"
        [disabled]="!validForSubmission"
      >
        <span *ngIf="!isUpdating">Send Update</span><span *ngIf="isUpdating">Update</span>
      </button>
      <button mat-flat-button *ngIf="isLoading" class="full-width" color="success" type="button">
        <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
      </button>
    </div>
  </div>
</div>
