import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, countBy, has, orderBy } from 'lodash';
import { Subscription } from 'rxjs';
import { AgendaItemDialogComponent, MeetingSelectDialogComponent, MilestoneDialogComponent } from 'src/app/components';
import { MilestoneStatus, ResourceType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import {
  AuthService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { CombinedTask, LinkedWOTask, Milestone, Task, TaskDragDrop } from 'src/app/types';
import { getDefaultRanks, getRankBetween } from 'src/app/utils';

@Component({
  selector: 'app-milestone-task-container',
  templateUrl: './milestone-task-container.component.html',
  styleUrls: ['./milestone-task-container.component.scss'],
})
export class MilestoneTaskContainerComponent implements OnInit, OnDestroy {
  @Input() milestoneData: Milestone = {}; // The milestone data of this view
  @Input() taskSelectedFromEmitter: LinkedWOTask | Task = null;
  @Input() editTasks: boolean; // whether the tasks can be dragged
  @Input() projectManagerId: number;
  @Input() taskRanksAreUpdating = false;
  @Output() isUpdatingTaskRank = new EventEmitter<boolean>();
  @Output() milestoneDeactivateEvent = new EventEmitter<Milestone>();
  @Output() phaseUpdated = new EventEmitter();
  @Output() taskClicked = new EventEmitter();
  isCreatingTask = false;
  milestoneStatus: MilestoneStatus;
  MilestoneStatus = MilestoneStatus;
  tasks: Array<Task | LinkedWOTask> = [];
  taskNumberLabelText = '';
  isProjectVendor = true;
  percentageOfTasksDone = 0;
  userIsStaff = false;
  isStaffOnlyModule = true;
  isProjectAdmin = false;
  isReadyToRender = false;
  isExpanded: boolean;
  selectedTaskId: number;
  private subscriptions = new Subscription();

  constructor(
    public projectService: ProjectService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public authService: AuthService,
    public taskService: ProjectTaskService,
    private meetingService: MeetingService,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService,
    private linkedTaskService: LinkedTaskService,
    private location: Location
  ) {}

  ngOnInit(): Promise<void> {
    this.isExpanded = !this.milestoneData.is_closed;
    if (this.milestoneData.id === null) {
      console.warn('Milestone view does not have an id assigned. Cannot get tasks');
      return;
    }

    // combine milestoneData.tasks & milestoneData.linked_wo_tasks in to this.tasks and then check statuses
    void this.combineAllTasks().then(() => {
      // verify the current selected task is available to show and close drawer if not
      if (
        this.taskSelectedFromEmitter?.milestone_id === this.milestoneData.id &&
        !this.tasks.find((t) => t.id === this.taskSelectedFromEmitter?.id)
      ) {
        void this.clickedTask(null);
      }

      // set all initial statuses
      this.refreshMilestoneStatus();
      this.setTaskNumberLabelText();
      this.setPercentageOfTasksDone();

      this.isReadyToRender = true;
    });

    this.subscriptions.add(
      this.taskService.taskSelectedEvent.subscribe((data) => {
        if (data?.task) {
          this.selectedTaskId = data.task.id;
        } else {
          this.selectedTaskId = undefined;
        }
      })
    );

    this.subscriptions.add(
      this.taskService.milestoneTaskEvent.subscribe((updatedTask) => {
        if (updatedTask.milestone_id === this.milestoneData.id) {
          const taskIndex = this.tasks.findIndex((task) => task.id === updatedTask.id && !has(task, 'work_order_id'));
          if (taskIndex < 0) {
            this.tasks.push(updatedTask);
          } else {
            this.tasks[taskIndex] = updatedTask;
          }
          this.refreshMilestoneStatus();
          this.setPercentageOfTasksDone();
          this.setTaskNumberLabelText();
        }
      })
    );

    this.subscriptions.add(
      this.projectService.taskCreatedEvent.subscribe((task) => {
        if (+this.milestoneData.id === +task?.milestone_id) {
          this.tasks.push(task);
          this.refreshMilestoneStatus();
          this.setPercentageOfTasksDone();
          this.setTaskNumberLabelText();
        }
      })
    );

    this.subscriptions.add(
      this.projectService.deleteTaskEvent.subscribe((taskId) => {
        this.removeTask(taskId);
        this.refreshMilestoneStatus();
        this.setPercentageOfTasksDone();
        this.setTaskNumberLabelText();
      })
    );

    this.subscriptions.add(
      this.taskService.closeTask.subscribe(({ close, taskId }) => {
        if (taskId) {
          this.projectService.deleteTaskEvent.emit(taskId);
        }
        if (close) {
          void this.clickedTask(null);
        }
      })
    );

    this.selectedTaskId = this.taskSelectedFromEmitter?.id ?? this.taskService.currentSelectedTask?.id ?? undefined;
    this.isProjectVendor = this.authService.isProjectVendor(this.projectService.currentSelectedProjectId);
    this.userIsStaff = this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
    this.isStaffOnlyModule = this.authService.isStaffOnAnyModule;
    this.isProjectAdmin = this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject.module_id
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private get isProgressManuallySet(): boolean {
    if (this.milestoneData?.progress === 0) {
      return true;
    }

    return !!this.milestoneData?.progress;
  }

  setPercentageOfTasksDone(): void {
    if (this.isProgressManuallySet) {
      this.percentageOfTasksDone = +this.milestoneData.progress;
    } else {
      const totalTasks = this.tasks.length;

      const doneTaskCount = this.tasks.filter(
        (task) => task?.status_id && +task.status_id === TaskStatus.Complete
      ).length;

      this.percentageOfTasksDone = Math.round((doneTaskCount / totalTasks) * 100);
    }
  }

  private async combineAllTasks(): Promise<void> {
    let tasks = this.milestoneData.tasks || [];

    if (this.milestoneData.linked_wo_tasks) {
      // grab the status of all tasks with a work_order_id but no work_order. This means the work order has been deleted, or the user has invalid access to GET it
      const woIdsToCheck = this.milestoneData.linked_wo_tasks
        .filter((task) => !task.work_order)
        .map((task) => +task.work_order_id);
      const deletedWorkOrders = await this.linkedTaskService.checkWorkOrderStatus(woIdsToCheck).toPromise();
      this.milestoneData.linked_wo_tasks.forEach((task) => {
        // if the associated work order isn't inaccessible to the user, display it
        if (!woIdsToCheck.includes(task.work_order_id) || deletedWorkOrders.includes(task.work_order_id)) {
          task.status_id = task.work_order?.status?.id;
          if (deletedWorkOrders.includes(task.work_order_id)) {
            task.title = 'Work Order Deleted';
          } else {
            task.title = task.work_order?.title;
          }
          task.due_date = task.work_order?.due_date;
          task.assigned_user_id = task.work_order?.assigned_user?.id;
          task.assigned_user_first_name = task.work_order?.assigned_user?.first_name;
          task.assigned_user_last_name = task.work_order?.assigned_user?.last_name;
          task.assigned_user_login_enabled = true;
          tasks.push(task);
        }
      });
    }

    tasks = orderBy(tasks, (task: Task) => task.rank, ['asc']);

    this.tasks = tasks;
  }

  private async rankTasks(tasks: CombinedTask[] = [], currentIndex?: number): Promise<CombinedTask[]> {
    if (tasks.length) {
      if (this.areAllTasksRanked(tasks) && currentIndex != null) {
        // only update the rank that has changed
        tasks[currentIndex] = this.assignNewTaskRank(tasks, currentIndex);

        await this.updateTaskRank(tasks[currentIndex], tasks[currentIndex].rank);
      } else {
        // update all the ranks
        tasks = this.assignNewTaskRanks(tasks);

        for (const task of tasks) {
          await this.updateTaskRank(task, task.rank);
        }
      }
    }
    return tasks;
  }

  async handleTaskMove(event: TaskDragDrop): Promise<void> {
    this.isUpdatingTaskRank.emit(true);

    if (event.previousContainer === event.container) {
      // move task within milestone
      moveItemInArray(event.container.data.tasks, event.previousIndex, event.currentIndex);

      // Change ranks, save to db, and replace this.tasks with result
      this.tasks = await this.rankTasks(event.container.data.tasks, event.currentIndex);
    } else {
      // Move task to new milestone
      transferArrayItem(
        event.previousContainer.data.tasks,
        event.container.data.tasks,
        event.previousIndex,
        event.currentIndex
      );

      // Change ranks, save to db, and replace this.tasks with result
      this.tasks = await this.rankTasks(event.container.data.tasks, event.currentIndex);

      // Update milestone id
      await this.updateTaskMilestoneId(
        event.container.data.tasks[event.currentIndex],
        event.container.data.mileStoneId
      );
    }

    this.isUpdatingTaskRank.emit(false);
  }

  private areAllTasksRanked(tasks: CombinedTask[]) {
    return tasks?.every((task) => task.rank);
  }

  private assignNewTaskRank(tasks: CombinedTask[], currentIndex: number): CombinedTask {
    const beforeTaskRank = tasks[currentIndex - 1]?.rank;
    const afterTaskRank = tasks[currentIndex + 1]?.rank;
    const newRank = getRankBetween(beforeTaskRank, afterTaskRank);
    return { ...tasks[currentIndex], rank: newRank };
  }

  private assignNewTaskRanks(tasks: CombinedTask[]): CombinedTask[] {
    const ranks = getDefaultRanks(tasks.length);
    return tasks?.map((task, index: number) => ({ ...task, rank: ranks[index] }));
  }

  private async updateTaskRank(task: CombinedTask, newRank: string): Promise<void> {
    if (has(task, 'work_order_id')) {
      await this.linkedTaskService.updateLinkedTaskRank(task.id, newRank).toPromise();
    } else {
      await this.taskService.updateTaskRank(task.id, newRank).toPromise();
    }
  }

  private async updateTaskMilestoneId(task: CombinedTask, mileStoneId: number): Promise<void> {
    if (has(task, 'work_order_id')) {
      await this.linkedTaskService.updateLinkedTaskMilestoneId(task.id, mileStoneId).toPromise();
    } else {
      await this.taskService.updateTaskMilestoneId(task.id, mileStoneId).toPromise();
    }
  }

  refreshMilestoneStatus(): void {
    this.milestoneStatus = this.calculateMilestoneStatus();
  }

  private calculateMilestoneStatus() {
    if (this.tasks) {
      const statusCount = countBy(this.tasks, 'status_id');
      const taskCount = this.tasks.length;
      if (statusCount[1] === taskCount) {
        return MilestoneStatus.NotStarted;
      } else if (statusCount[3] === taskCount) {
        return MilestoneStatus.Complete;
      } else if (statusCount[2] === taskCount) {
        return MilestoneStatus.OnHold;
      } else if (statusCount[2]) {
        return MilestoneStatus.InProgressOnHold;
      } else {
        return MilestoneStatus.InProgress;
      }
    } else {
      return MilestoneStatus.NotStarted;
    }
  }

  async completeMilestone(): Promise<void> {
    if (this.hasIncompleteReviews()) {
      // If there is a review task that is not finished the milestone cannot be completed
      this.modalService
        .openNotificationDialog({
          titleBarText: 'Unfinished Review Task',
          descriptionText: 'All reviews must be finished before this milestone can be completed',
          notificationButtonText: 'Ok',
        })
        .subscribe(async (confirmed) => {});
    } else {
      // Goes through each task creates a complete event, updates the task to complete then updates the ui.
      for (const task of this.tasks) {
        if (!task.hasOwnProperty('work_order_id') && task.status_id !== TaskStatus.Complete) {
          const newTask = await this.taskService.changeTaskStatus(task as Task, TaskStatus.Complete);
          const updatedTask = await this.projectService.updateTask(newTask).toPromise();
          await this.taskService.updateTask(updatedTask);
        }
      }
    }
  }

  deactivateMilestone(): void {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Milestone',
        descriptionText: 'Are you sure that you want to remove this Milestone?',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          if (
            !this.tasks.length ||
            this.tasks.filter((task) => 'can_delete' in task && !task.can_delete).length === 0
          ) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Milestone..');
            // TODO we really should deactivate all tasks in this milestone as well CPM-1579
            await this.projectService
              .deactivateMilestone(this.milestoneData.id)
              .toPromise()
              .then(() => {
                this.milestoneDeactivateEvent.emit(this.milestoneData);
                this.snackbar.open('Milestone Removed');
              });
            this.progressIndicatorService.close();
          } else {
            this.snackbar.open('Failed to Remove - Milestone contains tasks that cannot be deleted');
          }
        }
      });
  }

  openEditMilestoneDialog(): void {
    // Remove linked wo tasks from milestone tasks
    const milestoneData: Milestone = {
      ...this.milestoneData,
      tasks: this.tasks.filter((task) => !has(task, 'work_order_id')),
    };

    const milestoneDialogRef = this.dialog.open(MilestoneDialogComponent, {
      width: '420px',
      data: cloneDeep(milestoneData),
    });

    milestoneDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projectService.updateMilestone(result).subscribe((updatedMilestone) => {
          this.milestoneData.start_date = updatedMilestone.start_date;
          this.milestoneData.end_date = updatedMilestone.end_date;
          this.milestoneData.name = updatedMilestone.name;
          this.milestoneData.progress = updatedMilestone.progress;
          this.phaseUpdated.emit();
        });

        // Combine the tasks from result with the linked WO tasks
        const tasks = [...result.tasks, ...this.tasks.filter((task) => has(task, 'work_order_id'))];

        this.tasks = orderBy(tasks, (task: Task) => task.rank, ['asc']);
      }
    });
  }

  // Checks to see if any of the tasks in the milestone are incomplete reviews.
  private hasIncompleteReviews() {
    let hasIncompleteReview = false;
    this.tasks.forEach((task) => {
      if ('accessory_data' in task && task.accessory_data && task.status_id !== TaskStatus.Complete) {
        const accessoryData = JSON.parse(task.accessory_data);
        if (accessoryData.isReviewItem && accessoryData.reviewChain.length) {
          hasIncompleteReview = !!accessoryData.reviewChain.find(
            (reviewer: { status?: TaskReviewStatus }) => reviewer.status !== TaskReviewStatus.Approved
          );
        }
      }
    });
    return hasIncompleteReview;
  }

  /** Toggle the expansion of the milestone view */
  toggleExpansion(): void {
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences(this.milestoneData.id.toString(), this.isExpanded || undefined))
    );
    this.isExpanded = !this.isExpanded;
    this.milestoneData.is_closed = !this.isExpanded;
    if (!this.isExpanded) {
      this.isCreatingTask = false;
    }
  }

  async clickedTask(task: CombinedTask & { project_manager_id?: number }): Promise<void> {
    // if there is no project manager id, and it's passed through, then set it
    if (task && !task?.project_manager_id && this.projectManagerId) {
      task.project_manager_id = this.projectManagerId;
    }
    const isLinkedTask = task && has(task, 'work_order_id');
    if (isLinkedTask) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading task...');
      const linkedTask = await this.linkedTaskService.getLinkedWOTask(task.id).toPromise();
      this.progressIndicatorService.close();
      this.taskService.taskSelectedEvent.emit(null);
      this.selectedTaskId = linkedTask.id;
      this.taskClicked.emit(linkedTask);
    } else {
      // this.taskService.taskSelectedEvent.emit(task);
      this.taskSelectedFromEmitter = null;
      this.taskClicked.emit(task);
    }

    if (!task) {
      this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/tasks`);
    }
  }

  taskDeletedEvent(id: number): void {
    const foundTask = this.tasks.find((task) => +task.id === +id && !has(task, 'work_order_id'));
    if (foundTask) {
      this.tasks.splice(this.tasks.indexOf(foundTask), 1);
    }
  }

  createTask(): void {
    this.modalService
      .openCreateTaskModal({
        phaseName: this.milestoneData.phase_name,
        milestoneName: this.milestoneData.name,
      })
      .subscribe(async (task) => {
        if (task) {
          const freshTaskCopy = await this.projectService.getTaskById(task.id).toPromise();

          if (task.milestone_id === this.milestoneData.id) {
            this.tasks.push(freshTaskCopy);
            this.setTaskNumberLabelText();
            this.setPercentageOfTasksDone();
          } else {
            await this.taskService.updateTask(freshTaskCopy);
          }
        }
      });
  }

  closeCreateTaskComponent(): void {
    this.isCreatingTask = false;
  }

  onTaskAdded(task: Task): void {
    this.closeCreateTaskComponent();
    this.tasks.push(task);
    void this.taskService.updateTask(task);
    this.taskService.selectTaskById(task.id, false).subscribe();
  }

  removeTask(taskId: number): void {
    if (this.tasks.length) {
      const task = this.tasks.find((task) => +task.id === +taskId && !has(task, 'work_order_id'));
      if (task) {
        this.tasks.splice(this.tasks.indexOf(task), 1);
      }
    }
  }

  onTaskConverted(data: { linkedWOTask: LinkedWOTask; oldTaskId: number }): void {
    const { linkedWOTask, oldTaskId } = data;
    const allTasksWereRankedBeforeConversion = this.areAllTasksRanked(this.tasks);

    if (!allTasksWereRankedBeforeConversion) {
      this.tasks = this.assignNewTaskRanks(this.tasks);
    }

    const taskIndex = this.tasks.findIndex((task) => task.id === oldTaskId && !has(task, 'work_order_id'));
    if (taskIndex >= 0) {
      const oldTaskRank = this.tasks[taskIndex].rank;
      this.tasks[taskIndex] = { ...linkedWOTask, rank: oldTaskRank };

      if (allTasksWereRankedBeforeConversion) {
        void this.updateTaskRank(this.tasks[taskIndex], this.tasks[taskIndex].rank);
      } else {
        for (const task of this.tasks) {
          void this.updateTaskRank(task, task.rank);
        }
      }
    }
  }

  setTaskNumberLabelText(): void {
    const myTasks = this.tasks;
    this.taskNumberLabelText = myTasks ? `${myTasks.length}` + (myTasks.length === 1 ? ' Task' : ' Tasks') : '0 Tasks';
  }

  createMeetingAgendaFromMilestone(milestone): void {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.Milestone,
        parent_id: milestone.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.Milestone,
              parent_id: milestone.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe(() => {
              this.snackbar.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  private addToPreferences(
    key: string,
    value: boolean | undefined
  ): { closed_milestones: Record<string, boolean | undefined> } {
    const preferences: { closed_milestones: Record<string, boolean | undefined> } =
      JSON.parse(localStorage.getItem('preferences')) || {};
    const closedMilestones = (preferences && preferences.closed_milestones) || {};

    closedMilestones[key] = value;
    preferences.closed_milestones = closedMilestones;

    return preferences;
  }
}
