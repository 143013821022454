<div class="modal-wide">
  <div class="row align-items-center">
    <div class="col">
      <h5 class="dkblue font-weight-semibold margin-0">File Approval Selection</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row mb-4 mt-4">
    <div class="col">
      <p class="m-0 dkblue" style="white-space: pre-wrap">
        {{ reviewDescription }}
      </p>
    </div>
  </div>
  <hr />
  <div class="row mb-2">
    <div class="col">
      <mat-selection-list class="pt-0">
        <mat-list-option
          *ngFor="let file of filesToReview"
          [value]="file"
          [selected]="isFileSelected(file)"
          (click)="selectFile(file)"
        >
          <app-file-chip style="width: fit-content" [file]="file" (click)="$event.stopPropagation()"></app-file-chip>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ !canSubmit() ? 'Provide Rejection Reason *' : 'Message' }}</mat-label>
        <textarea
          matInput
          rows="4"
          placeholder="{{
            canSubmit()
              ? 'Leave a comment about these files and/or this approval'
              : 'Please provide the reason you are rejecting these files.'
          }}"
          [(ngModel)]="reviewMessage"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button *ngIf="canSubmit()" mat-flat-button class="full-width" color="accent" (click)="submitReview()">
        Submit Approval
      </button>
      <button
        *ngIf="!canSubmit()"
        mat-flat-button
        class="full-width"
        color="warn"
        [disabled]="!reviewMessage || reviewMessage.length <= 0"
        (click)="rejectReview()"
      >
        Reject
      </button>
    </div>
  </div>
</div>
