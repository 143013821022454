<app-base-dialog [title]="'Profile Preview'" (closeDialog)="cancel()"></app-base-dialog>
<div id="user-profile-preview" data-test-id="user-profile-preview" class="user-profile-preview">
  <div *ngIf="loading" class="row">
    <div class="col mt-5 mb-5 pt-5 text-center">
      <h2 class="m-0 ltblue mb-4">
        <i style="font-size: 2rem" class="fas fa-circle-notch fa-spin"></i>
      </h2>
      <h5 class="m-0 ltblue">Looking for Waldo...</h5>
      <!-- <mat-progress-spinner class="m-auto" color="accent" mode="indeterminate">
      </mat-progress-spinner> -->
    </div>
  </div>
  <div class="row" *ngIf="user && user.id">
    <div class="col">
      <div *ngIf="user" class="row mb-4">
        <div class="col-auto m-auto">
          <div id="avatar" [style.background-image]="'url(' + getProfilePictureUrl(user.id) + ')'"></div>
        </div>
      </div>
      <div *ngIf="user" class="row mb-5">
        <div class="col text-center">
          <h3 class="m-0 dkblue font-weight-semibold">{{ user.first_name + ' ' + user.last_name }}</h3>
          <h5 class="m-0 dkgray font-weight-normal mb-2">
            {{ user.title }}
          </h5>
          <div *ngIf="user?.is_ar" class="d-flex justify-content-center">
            <p class="dkgray font-weight-normal d-flex align-items-center">
              <mat-icon class="mr-1" style="font-size: 17px; height: 17px; width: 17px">verified_user</mat-icon>
              <span> Authorized Requestor </span>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="user && user.company_name" class="row mb-4">
        <div class="col">
          <h6 class="primary mb-1">Company</h6>
          <h5 class="m-0 primary font-weight-normal">
            {{ user.company_name }}
          </h5>
        </div>
      </div>
      <div *ngIf="user" class="row mb-3">
        <div *ngIf="user.manager_first_name && user.manager_last_name" class="col">
          <h6 class="primary mb-1">Manager</h6>
          <h5 class="m-0 primary font-weight-normal">{{ user.manager_first_name }} {{ user.manager_last_name }}</h5>
        </div>
        <div class="col">
          <h6 class="primary mb-1">Account Type</h6>
          <h5 class="m-0 primary font-weight-normal">
            {{ user.user_type_id === 1 ? 'UHAT/1CALL' : user.user_type_name }}
          </h5>
        </div>
      </div>
      <!-- <div *ngIf="user && user.user_type_name" class="row mb-4">
        <div class="col">
          <h6 class="m-0 br-4 lh-24 text-uppercase d-inline-flex" [ngClass]="{ 'bg-gold' : user.user_type_name === 'Staff', 'bg-gray' : user.user_type_name === 'Tenant', 'bg-red' : user.user_type_name === 'Vendor' }">
            <span
              class="white px-2"
              *ngIf="user.user_type_name === 'Staff'"
            >
              UHAT/1Call
            </span>
            <span
              class="white px-2"
              *ngIf="user.user_type_name === 'Tenant'"
            >
              Tenant
            </span>
            <span class="white px-2" *ngIf="user.user_type_name === 'Vendor'">
              Supplier
            </span>
          </h6>
        </div>
      </div> -->
      <mat-divider class="my-4"></mat-divider>
      <div *ngIf="user" class="row mb-3">
        <div class="col d-flex align-items-top">
          <span class="d-flex flex-column mr-auto">
            <h6 class="primary mb-2">Account Status</h6>
            <p class="m-0 lh-24">
              <span
                class="dkgreen bg-shade-green px-2 py-1 lh-24 br-10"
                *ngIf="user.is_enabled && user.is_login_enabled"
              >
                Active
              </span>
              <span
                class="dkorange bg-shade-orange px-2 py-1 lh-24 br-10"
                *ngIf="user.is_enabled && !user.is_login_enabled"
              >
                Pending
              </span>
              <span class="gray bg-shade-gray px-2 py-1 lh-24 br-10" *ngIf="!user.is_enabled"> Disabled </span>
            </p>
          </span>
          <span>
            <button
              mat-flat-button
              (click)="inviteUser(user)"
              *ngIf="authService.isStaffOnAnyModule && !user.is_login_enabled && user.is_enabled"
              color="success"
              class="lh-28 px-2"
            >
              Send Invite
            </button>
          </span>
        </div>
      </div>
      <div *ngIf="user.is_enabled && !user.is_login_enabled" class="row mb-3 dkgray">
        <div class="col">{{ guestInviteMessage }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="user" class="row mt-5">
    <div class="col" *ngIf="authService.isAppAdmin && (!currentUser || currentUser.id !== user.id)">
      <button mat-button class="full-width" color="accent" (click)="impersonateUser(user)">
        <mat-icon>bug_report</mat-icon>
        Impersonate
      </button>
    </div>
    <div class="col">
      <button
        mat-stroked-button
        class="full-width"
        color="accent"
        [routerLink]="['/profile/' + user.id]"
        (click)="dialogRef.close()"
      >
        View Profile
      </button>
    </div>
    <!-- <div class="col-6">
      <button
        mat-flat-button
        class="full-width"
        color="accent"
        (click)="messageUser(); dialogRef.close()"
      >
        Message
      </button>
    </div> -->
  </div>
</div>
