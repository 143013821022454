<header
  data-test-id="work-order-header"
  #mainScreen
  (window:resize)="onResize($event)"
  id="work-order-header"
  class="work-order-header"
  [ngClass]="{
    'header-top-banner': statusId === WorkOrderStatus.CLOSED,
    'nav-closed': isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,

    'nav-open': !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,

    'notifications-sidenav-open':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,

    'notifications-sidenav-closed':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
  }"
>
  <section id="header-top" class="d-flex align-items-center">
    <h4 data-test-id="header-title-container" #title class="dkblue m-0 mr-auto d-block text-truncate">
      <span *ngIf="code" class="font-weight-semibold dkgray">
        {{ code }}
      </span>
      <span *ngIf="buildAndFloorCodes" class="ml-1 mr-1 font-weight-semibold gray"> | </span>
      <span *ngIf="buildAndFloorCodes" class="font-weight-semibold dkgray">
        {{ buildAndFloorCodes }}
      </span>
      <span *ngIf="workOrderTitle" class="ml-1 mr-1 font-weight-semibold gray"> | </span>
      <span *ngIf="workOrderTitle" class="font-weight-bold">
        {{ workOrderTitle }}
      </span>
    </h4>
    <div class="d-flex align-items-center">
      <span *ngIf="statusId === WorkOrderStatus.READY_FOR_PICKUP" class="d-flex align-items-center ml-3 mr-3">
        <mat-icon class="mr-2 green">fact_check</mat-icon>
        <h6 class="mb-0 dkblue font-weight-semibold" style="margin-top: 1px">Ready For Pickup</h6>
      </span>
      <!-- LINK TO PARENT PROJECT - LINK TO WO TASK ITEM -->
      <div *ngIf="linkedWOTask">
        <a [routerLink]="['/projects/' + linkedWOTask.project_id + '/linked-tasks/' + linkedWOTask.id]">
          <button mat-stroked-button color="accent" class="ml-3 px-3" type="button">
            <mat-icon class="mr-1"> assignment_turned_in </mat-icon>
            <span> PJ-{{ linkedWOTask.project_code }} </span>
          </button>
        </a>
      </div>
      <!-- <button (click)="reactivateWO()" *ngIf="canReactivate" mat-stroked-button color="warn" class="ml-3">
        Reactivate WO
      </button> -->
      <app-split-button
        *ngIf="isStaff && (statusId === WorkOrderStatus.ACTIVE || statusId === WorkOrderStatus.READY_FOR_PICKUP)"
        class="ml-2"
        lineHeight="24"
        (clickEvent)="closeWO()"
        [matMenu]="menu"
      >
        Close Work Order
      </app-split-button>
      <app-split-button
        *ngIf="isStaff && [WorkOrderStatus.ON_HOLD, WorkOrderStatus.PLANNED].includes(statusId)"
        class="ml-2"
        lineHeight="24"
        (clickEvent)="openWO()"
        [matMenu]="menu"
      >
        Set To Active
      </app-split-button>
      <app-split-button
        *ngIf="isStaff && statusId === WorkOrderStatus.CLOSED"
        [disabled]="!canReactivate"
        class="ml-2 font-italic"
        lineHeight="24"
        [matMenu]="menu"
      >
        WO Closed
      </app-split-button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button
          *ngIf="(isStaff || canReactivate) && statusId !== WorkOrderStatus.ACTIVE"
          (click)="openWO()"
          mat-menu-item
        >
          <mat-icon>label_important</mat-icon>
          <span> Set to Active </span>
        </button>
        <button *ngIf="statusId !== WorkOrderStatus.READY_FOR_PICKUP" (click)="isReadyForPickup()" mat-menu-item>
          <mat-icon>fact_check</mat-icon>
          <span> Ready For Pickup </span>
        </button>
        <button *ngIf="isStaff && statusId !== WorkOrderStatus.PLANNED" (click)="setAsPlanned()" mat-menu-item>
          <mat-icon>next_plan</mat-icon>
          <span> Set to Planned </span>
        </button>
        <button *ngIf="isStaff && statusId !== WorkOrderStatus.ON_HOLD" (click)="holdWO()" mat-menu-item>
          <mat-icon>flag</mat-icon>
          <span> Place On Hold </span>
        </button>
        <button *ngIf="isStaff && statusId !== WorkOrderStatus.CLOSED" (click)="closeWO()" mat-menu-item>
          <mat-icon>done_all</mat-icon>
          <span> Close Work Order </span>
        </button>
      </mat-menu>
      <button
        mat-button
        [matMenuTriggerFor]="wo_menu"
        class="ml-2 ltblue bg-ltltltblue pl-2 pr-2"
        aria-label="top right button with a menu"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #wo_menu="matMenu">
        <button *ngIf="isStaff" mat-menu-item (click)="editWorkOrder()">
          <mat-icon>assignment</mat-icon>
          <span>Edit Work Order</span>
        </button>
        <button *ngIf="isStaff" mat-menu-item (click)="createMeeting()">
          <mat-icon>event</mat-icon>
          <span>Create Meeting</span>
        </button>
        <button *ngIf="isStaff" mat-menu-item (click)="createMeetingAgendaFromWorkOrder()">
          <mat-icon>event_notes</mat-icon>
          <span>Add to Meeting Agenda</span>
        </button>
        <button
          *ngIf="(isWorkspaceStaff || workspaceAdmin) && !this.linkedWOTask"
          [disabled]="statusId === WorkOrderStatus.CLOSED"
          mat-menu-item
          (click)="createRequest()"
        >
          <mat-icon>assignment_late</mat-icon>
          <span>Convert to Project Request</span>
        </button>
        <button
          *ngIf="isWorkspaceStaff || workspaceAdmin"
          [disabled]="statusId === WorkOrderStatus.CLOSED"
          mat-menu-item
          (click)="transferWorkOrder()"
        >
          <mat-icon>arrow_forward</mat-icon>
          <span>Transfer Work Order</span>
        </button>
        <button [disabled]="statusId === WorkOrderStatus.CLOSED" mat-menu-item (click)="updateFollowers()">
          <mat-icon>group_add</mat-icon>
          <span>Manage Followers</span>
        </button>
      </mat-menu>
    </div>
  </section>
  <section id="header-bottom" class="d-flex align-items-center">
    <div class="d-flex align-items-center full-width">
      <div class="d-flex align-items-center pr-3">
        <h6
          *ngIf="workSpaceName"
          class="m-0 lh-20 white bg-dkblue mr-3"
          matTooltip="WS: {{ workSpaceName }}"
          matTooltipPosition="above"
        >
          {{ workSpaceName }}
        </h6>
        <h6
          *ngIf="status"
          class="m-0 white mr-3 lh-20"
          matTooltip="Status: {{ status }}"
          matTooltipPosition="above"
          [ngClass]="{
            'bg-green': statusId === WorkOrderStatus.ACTIVE,
            'bg-red': statusId === WorkOrderStatus.ON_HOLD,
            'bg-gray': statusId === WorkOrderStatus.CLOSED,
            'bg-dkgreen': statusId === WorkOrderStatus.READY_FOR_PICKUP,
            'bg-ltblue': statusId === WorkOrderStatus.PLANNED
          }"
        >
          {{ status }}
        </h6>
        <p *ngIf="!priority && isStaff" class="ltblue pointer hover-u" [matMenuTriggerFor]="priority_menu">
          + Priority
        </p>
        <h6
          [matMenuTriggerFor]="priority_menu"
          *ngIf="priority"
          class="m-0 white lh-20 pointer"
          [ngClass]="{
            'bg-dkred': priority_id === 4,
            'bg-red': priority_id === 3,
            'bg-ltblue': priority_id === 2,
            'bg-ltgray': priority_id === 1,
            disabled: !isStaff
          }"
          matTooltip="Priority: {{ priority }}"
          matTooltipPosition="above"
        >
          {{ priority }}
        </h6>
        <mat-menu #priority_menu="matMenu">
          <button
            mat-menu-item
            type="button"
            class="font-weight-semibold"
            *ngFor="let priorityOption of workOrderPriorities"
            [ngClass]="{
              gray: !priorityOption?.id,
              dkgray: priorityOption?.id === 1,
              ltblue: priorityOption?.id === 2,
              red: priorityOption?.id === 3,
              dkred: priorityOption?.id === 4
            }"
            (click)="updatePriority(priorityOption)"
          >
            <span>{{ priorityOption.name }}</span>
          </button>
        </mat-menu>
      </div>
      <div *ngIf="workOrder" class="d-flex align-items-center px-3 border-left border-gray">
        <span
          (click)="dueDatePicker.open()"
          class="m-0 mr-3 ltblue bg-shade-ltblue br-4 px-2 lh-20 pointer d-flex align-items-center"
          [ngClass]="{ disabled: !isStaff, 'bg-shade-red': isOverdue }"
          *ngIf="divWidth > 700"
        >
          <i *ngIf="isOverdue" class="material-icons red mr-1" style="font-size: 15px">error</i>
          <p class="dkblue d-inline-block m-0 align-middle font-weight-semibold mr-2 pointer">Due:</p>
          <span>
            <input
              matInput
              [matDatepicker]="dueDatePicker"
              autocomplete="off"
              style="visibility: hidden; width: 0"
              [(ngModel)]="workOrder.due_date"
              (ngModelChange)="changeDueDate($event)"
            />
            <mat-datepicker #dueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
          </span>
          <p
            class="m-0 dkblue text-capitalize pointer hover-u"
            [ngClass]="{
              gray: !dueDate || statusId === WorkOrderStatus.CLOSED,
              ltblue: !isOverdue,
              disabled: !isStaff
            }"
          >
            {{ dueDate ? formattedDueDate : 'Not Set' }}
          </p>
        </span>
        <span
          (click)="openUserProfilePreview(workOrder?.assigned_user?.id)"
          class="m-0 ltblue bg-shade-ltblue br-4 px-2 lh-20 pointer d-flex align-items-center"
          [ngClass]="{ disabled: !isStaff, 'bg-shade-red': !assignedToName }"
          matTooltip="Assigned To: {{ assignedToName ? assignedToName : 'Unassigned' }}"
          matTooltipPosition="above"
        >
          <i *ngIf="!assignedToName" class="material-icons red mr-1" style="font-size: 15px">error</i>
          <p class="dkblue m-0 font-weight-semibold mr-2">Assigned To:</p>
          <p
            class="m-0 dkblue text-capitalize hover-u pointer"
            [ngClass]="{
              ltblue: assignedToName
            }"
          >
            {{ assignedToName ? assignedToName : 'Not Assigned' }}
          </p>
        </span>
      </div>
      <!-- TAGS -->
      <div class="d-flex align-items-center px-3 border-left border-gray" *ngIf="isStaff">
        <h6
          *ngFor="let tag of workOrder?.tags || []"
          class="m-0 mr-3 ltblue border border-gray br-10 px-2"
          matTooltip="{{ tag.name }}"
          matTooltipPosition="above"
          [matTooltipShowDelay]="300"
        >
          {{ tag.abbreviation }}
        </h6>
      </div>
      <div class="d-flex align-items-center ml-auto">
        <button
          *ngIf="!amFollowing"
          mat-stroked-button
          color="accent"
          (click)="followWO()"
          [disabled]="statusId === WorkOrderStatus.CLOSED"
          class="lh-24 pl-3 pr-3 d-inline-block align-middle"
        >
          Follow
        </button>
        <button
          *ngIf="amFollowing"
          mat-stroked-button
          color="accent"
          (click)="unfollowWO()"
          class="lh-24 pl-3 pr-3 d-inline-block align-middle"
        >
          Unfollow
        </button>
        <span
          *ngIf="followers.length > 0"
          class="ml-4 pointer d-inline-block align-middle"
          [matMenuTriggerFor]="follower_menu"
        >
          <app-profile-thumbnail
            *ngFor="let f of followers | slice: 0:5"
            [noBorder]="false"
            class="d-inline-block align-middle"
            [height]="26"
            [width]="26"
            [userId]="f.id"
          ></app-profile-thumbnail>
        </span>
        <span
          *ngIf="followers.length > 5"
          class="follower-count pointer"
          [matMenuTriggerFor]="follower_menu"
          style="z-index: 1001"
        >
          +{{ followers?.length - 5 }}
        </span>
        <mat-menu #follower_menu="matMenu">
          <button *ngFor="let f of followers" mat-menu-item>
            <app-profile-thumbnail
              [noBorder]="true"
              class="d-inline-block align-middle mr-3"
              [height]="26"
              [width]="26"
              [userId]="f.id"
            ></app-profile-thumbnail>
            <span class="d-inline-block align-middle">{{ f.first_name }} {{ f.last_name }}</span>
          </button>
          <button [disabled]="statusId === WorkOrderStatus.CLOSED" mat-menu-item (click)="updateFollowers()">
            <mat-icon>group_add</mat-icon>
            <span>Manage Followers</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </section>
</header>

<div
  *ngIf="statusId === WorkOrderStatus.CLOSED"
  class="status-banner bg-red d-flex justify-content-center align-items-center"
  [ngClass]="{
    'nav-closed': isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,

    'nav-open': !isSidenavClosed && !notificationService.notificationPanelIsOpen && !searchService.searchPanelIsOpen,

    'notifications-sidenav-open':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && !isSidenavClosed,

    'notifications-sidenav-closed':
      (notificationService.notificationPanelIsOpen || searchService.searchPanelIsOpen) && isSidenavClosed
  }"
>
  <p class="m-0 white font-weight-semibold d-inline-block align-middle px-2">
    <span *ngIf="!convertedToRequest?.id">CLOSED on {{ closedDate }} at {{ closedTime }}</span
    ><span *ngIf="convertedToRequest?.id"
      >CONVERTED to Project Request
      <a [routerLink]="['/requests/' + convertedToRequest.id]" class="white">{{ convertedToRequest.code }}</a></span
    >
  </p>
  <button
    mat-flat-button
    (click)="reactivateWO()"
    *ngIf="canReactivate"
    type="button"
    class="mx-2 lh-28 d-inline-block align-middle px-3 text-danger"
  >
    Reactivate
  </button>
</div>
