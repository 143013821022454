<div id="kendo-content" *ngIf="bidPackage && project" xmlns="http://www.w3.org/1999/html">
  <kendo-pdf-export paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }" #transmittal>
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project.building_code }}-{{ project.floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p>
                  {{ !project.square_footage ? 'Not Given' : (project.square_footage | number) }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ project.end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ project.architect_first_name }}
                  {{ project.architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-body pl-5 pt-4">
        <div class="d-flex flex-column mb-5">
          <h3 class="font-weight-bold mb-3">Submittal Response</h3>
          <p>{{ bidPackage?.awarded_company_name }}</p>
          <p>{{ this.vendorContact?.first_name }} {{ this.vendorContact?.last_name }}</p>
          <p>
            {{ this.vendorContact?.office_phone || this.vendorContact?.cell_phone | phoneNumber }}
          </p>
          <p>{{ this.vendorContact?.email }}</p>
        </div>
        <div class="row">
          <div class="col-4 mr-auto">
            <!-- REPEAT ROWS FOR EACH REVIEWER -->
            <div class="d-flex mb-4" *ngFor="let reviewer of accessoryData?.reviewChain">
              <div class="col">
                <label class="main-label mt-0">
                  {{ (reviewer.status && formatDate(reviewer.date)) || 'Pending Review' }}
                </label>
                <p class="m-0"></p>
                <p>
                  <b>{{ reviewer.first_name }} {{ reviewer.last_name }}</b>
                </p>
                <p>{{ reviewer.title }}</p>
                <p class="my-3" *ngIf="reviewer.status">
                  <span
                    class="{{ getIconColor(reviewer.status) }} {{ getIconType(reviewer.status) }} fas ml-0 mr-4"
                    style="font-size: 14px"
                  ></span>
                  <span class="{{ getIconColor(reviewer.status) }}">
                    {{ getStatusText(reviewer.status) }}
                  </span>
                </p>
                <p *ngIf="reviewer.status" class="dkgray" [innerHTML]="reviewer.comment"></p>
                <p *ngIf="reviewer.status && reviewer?.files?.length">
                  Attached file{{ reviewer?.files?.length === 1 ? '' : 's' }}
                </p>
                <p *ngFor="let file of reviewer.files" class="gray" [hidden]="!reviewer.status">
                  {{ file.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-8 ml-auto pr-5 mb-2">
            <div class="d-flex flex-row mb-2" *ngFor="let c of submittalCategories">
              <h6 class="font-weight-semibold">{{ c.code }} - {{ c.name }}</h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
