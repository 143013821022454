<app-base-dialog [title]="'Meeting'" (closeDialog)="close()"></app-base-dialog>
<div id="modal-container">
  <div class="row mt-4 mb-4 align-items-center">
    <div class="col">
      <h4 class="mb-4 dkblue">Conclude Meeting</h4>
      <h5 class="m-0 dkgray font-weight-normal lh-24 mb-3">
        Automated emails will be implemented in the coming weeks. For the moment, please download the attached agenda
        items and manually email them to the contacts below. Thanks for your patience!
      </h5>
    </div>
  </div>
  <!--  TODO comment the below back in to allow subject/desc-->
  <!--  <div class="row">-->
  <!--    <div class="col-12">-->
  <!--      <mat-form-field appearance="outline" class="full-width">-->
  <!--        <mat-label>Subject</mat-label>-->
  <!--        <label class="full-width">-->
  <!--          <textarea matInput rows="1" [(ngModel)]="subject"></textarea>-->
  <!--        </label>-->
  <!--      </mat-form-field>-->
  <!--      <mat-form-field appearance="outline" class="full-width">-->
  <!--        <mat-label>Additional Comments</mat-label>-->
  <!--        <label class="full-width">-->
  <!--          <textarea matInput rows="4" [(ngModel)]="message"></textarea>-->
  <!--        </label>-->
  <!--      </mat-form-field>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="row mt-4 mb-5">
    <div class="col">
      <h6 class="dkblue mb-2 font-weight-semibold">Attendees:</h6>
      <p *ngFor="let attendee of data.attendees; let ai = index" class="dkblue mb-1">
        {{ attendee.first_name + ' ' + attendee.last_name + ' - ' + attendee.email }}
      </p>
      <div *ngIf="data.attendees.length <= 0" class="no-items-panel">
        <div class="no-items-panel-content">
          <h1>No Attendees</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-chip-list>
        <app-file-chip [file]="file" [downloadable]="true" [removable]="false"></app-file-chip>
      </mat-chip-list>
      <mat-chip-list>
        <app-file-chip
          *ngFor="let file of downloadableFiles"
          [file]="file"
          [downloadable]="true"
          [removable]="false"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
  <!--  TODO comment the below back in to allow the cancel/send buttons-->
  <!--  <div class="row mt-4 align-items-center">-->
  <!--    <div class="col-auto text-right">-->
  <!--      <button mat-button (click)="close()" class="ltgray mr-2">-->
  <!--        Cancel-->
  <!--      </button>-->
  <!--      <button mat-flat-button color="success" (click)="send()">-->
  <!--        Send-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
