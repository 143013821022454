import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { TimeLog, ServiceResponse, APIFilter, TimeLogActivity } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class TimeLogService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  public timeLogPanelIsOpen = false;
  @Output() public openTimeLogPanelEvent = new EventEmitter();

  host: string = environment.serviceHost;
  timeLogUrl = `${this.host}/api/v1/time-logs`;
  timeLogFields =
    'id,worker_id,worker,date_worked,parent_status_id,parent_code,parent_title,activity_id,activity{id,name},company_id,company{id,name},hours_worked,minutes_worked,notes,created_datetime,created_by_id,created_by,module_id,parent_type_id,parent_id';
  timeLogActivityUrl = `${this.host}/api/v1/time-log-activities`;
  timeLogActivityFields = 'id,name,is_pto';
  timeLogCompanyUrl = `${this.host}/api/v1/time-log-companies`;
  timeLogCompanyFields = 'id,name';

  public toggleTimeLogDrawer() {
    this.timeLogPanelIsOpen = !this.timeLogPanelIsOpen;
    if (this.timeLogPanelIsOpen) {
      this.openTimeLogPanelEvent.emit();
    }
  }

  getTimeLogEntry(timeLogId: number, fields?: string[]): Observable<TimeLog> {
    const queryFields = fields ? fields.join(',') : this.timeLogFields;
    return this.http.get(`${this.timeLogUrl}/${timeLogId}?fields=${queryFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data['time logs'][0];
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimeLogs(apiFilters?: APIFilter[], fields?: string[]): Observable<TimeLog[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    const queryFields = fields ? fields.join(',') : this.timeLogFields;
    return this.http
      .get(`${this.timeLogUrl}?fields=${queryFields}${!filterString || filterString === '' ? '' : `&${filterString}`}`)
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.time_logs;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getUsers(apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(`${this.timeLogUrl}/users${!filterString || filterString === '' ? '' : `?${filterString}`}`)
      .pipe(
        map((result: ServiceResponse) => {
          const results = result.data.users;
          return results;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createTimeLogEntry(timeLog: TimeLog): Observable<TimeLog> {
    const body = timeLog;
    return this.http.post(`${this.timeLogUrl}?fields=${this.timeLogFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdTimeLog: TimeLog = result.data['time logs'];
        return createdTimeLog;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateTimeLogEntry(timeLog: TimeLog): Observable<TimeLog> {
    const body = timeLog;
    const timeLogId = timeLog.id;
    delete body.id;
    return this.http.put(`${this.timeLogUrl}/${timeLogId}?fields=${this.timeLogFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedTimeLog: TimeLog = result.data['time logs'];
        return updatedTimeLog;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deactivateTimeLogEntry(timeLogId: number): Observable<void> {
    return this.http.delete(`${this.timeLogUrl}/${timeLogId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimeLogActivity(timeLogActivityId: number): Observable<TimeLogActivity> {
    return this.http.get(`${this.timeLogActivityUrl}/${timeLogActivityId}?fields=${this.timeLogActivityFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data['time log activities'][0];
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimeLogActivities(): Observable<TimeLogActivity[]> {
    return this.http.get(`${this.timeLogActivityUrl}?fields=${this.timeLogActivityFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data.time_log_activities;
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimeLogCompany(timeLogCompanyId: number): Observable<{ id: number; name: string }> {
    return this.http.get(`${this.timeLogCompanyUrl}/${timeLogCompanyId}?fields=${this.timeLogActivityFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data['time log activities'][0];
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimeLogCompanies(): Observable<{ id: number; name: string }[]> {
    return this.http.get(`${this.timeLogCompanyUrl}?fields=${this.timeLogCompanyFields}`).pipe(
      map((result: ServiceResponse) => {
        const results = result.data.time_log_companies;
        return results;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
