import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class RFIService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  host: string = environment.serviceHost;

  rfiUrl = `${this.host}/api/v1/rfis`;
  rfiCommentsUrl = `${this.host}/api/v1/rfi-comments`;

  getRFIs(fields: string[], apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.rfiUrl}?limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&fields=${fields.join(',')}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const rfis: any[] = result.data.rfis;
          return rfis;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // WARNING: This function is meant to not catch any errors and to instead allow the calling function to catch them
  getRFIsSuppressed(fields: string[], apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.rfiUrl}?limit=1000${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }&fields=${fields.join(',')}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const rfis: any[] = result.data.rfis;
          return rfis;
        })
      );
  }

  createRFI(rfi, fields: string[]): Observable<any> {
    const body = rfi;
    return this.http.post(`${this.rfiUrl}?fields=${fields.join(',')}`, body).pipe(
      map((result: ServiceResponse) => {
        const rfiToReturn = result.data.rfi;
        return rfiToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateRFI(rfiId, rfi, fields: string[]): Observable<any> {
    const body = rfi;
    return this.http.put(`${this.rfiUrl}/${rfiId}?fields=${fields.join(',')}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedRFI = result.data.rfi;
        return updatedRFI;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteRFI(rfiId): Observable<any> {
    return this.http.delete(`${this.rfiUrl}/${rfiId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRFIComments(fields: string[], apiFilters?: APIFilter[]): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.rfiCommentsUrl}?limit=1000&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const rfiComments: any[] = result.data.rfi_comments;
          return rfiComments;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createRFIComment(comment, fields: string[]): Observable<any> {
    const body = comment;
    return this.http.post(`${this.rfiCommentsUrl}?fields=${fields.join(',')}`, body).pipe(
      map((result: ServiceResponse) => {
        const commentToReturn = result.data['rfi comment'];
        return commentToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
