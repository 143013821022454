import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CompanyService } from 'src/app/services';
import { APIFilter, Company } from 'src/app/types';

/**
 * Functionality for user searching. Provided in should possibly be moved so that it is not a singleton service.
 */
@Injectable({
  providedIn: 'root',
})
export class CompanySearchService {
  private searchCompanyInput: Subject<string> = new Subject();

  private filteredCompanies: Company[] = [];

  constructor(private companyService: CompanyService) {
    this.refreshCompanies();
  }

  public refreshCompanies() {
    this.searchCompanyInput.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
      const companyFilters: APIFilter[] = [
        { type: 'field', field: 'name', value: searchTerm, match: searchTerm ? 'any' : '!=' },
      ];
      this.companyService.getCompanies(['id', 'name', 'type_id'], companyFilters).subscribe((companies) => {
        if (companies) {
          this.filteredCompanies = companies;
        } else {
          this.filteredCompanies = [];
        }
      });
    });
  }

  public filterDepartments(value) {
    this.searchCompanyInput.next(value);
  }

  public getFilteredCompanies(): Company[] {
    return this.filteredCompanies;
  }

  public defaultCompanyMapper(company: Company): string {
    return company ? company.name : '';
  }
}
