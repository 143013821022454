<div class="row">
  <div class="col-4">
    <div
      *ngFor="let project of projects"
      (click)="selectProject(project)"
      class="m-portlet m-portlet--mobile mb-3 pointer item ease"
      [ngClass]="{
        'bg-ltltblue selected-project': selectedProject === project
      }"
    >
      <div *ngIf="project.id" class="m-portlet__body p-3">
        <div class="row mb-3">
          <div class="col">
            <h6 class="m-0 dkblue font-weight-semibold">
              {{ getProjectText(project) }}
            </h6>
          </div>
        </div>
        <div class="row" style="margin: 0 -0.5rem">
          <div class="col pl-2 pr-2" *ngFor="let phase of project.phases">
            <button
              mat-stroked-button
              color="accent"
              type="button"
              matTooltip="{{ phase.name }} Phase"
              matTooltipPosition="above"
              [matTooltipShowDelay]="150"
              (click)="selectedPhase(phase, project); stopPropagation($event)"
              [ngClass]="{ 'bg-ltblue white': isSelected(phase) }"
              class="col pointer text-center"
            >
              <p
                class="main-label m-0"
                [ngClass]="{
                  ltgray: isSelected(phase),
                  gray: !isSelected(phase)
                }"
              >
                {{ phase.name.substr(0, 1) }}
              </p>
              <h6 class="font-weight-semibold pb-2" style="margin-top: -6px">
                {{ phase.num_tasks }}
              </h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div *ngIf="selectedProject && visibleTasks.length != 0" class="row">
      <div class="col">
        <div class="m-portlet">
          <app-dashboard-task
            *ngFor="let task of visibleTasks"
            [task]="task"
            [showProject]="false"
          ></app-dashboard-task>
        </div>
      </div>
    </div>
    <div *ngIf="!selectedProject || visibleTasks.length === 0" class="row">
      <div class="col text-center">
        <h6 *ngIf="!selectedProject" class="m-0 dkblue mt-4">Please select a project to view its unassigned tasks.</h6>
        <h6 *ngIf="selectedProject && visibleTasks.length === 0" class="m-0 gray mt-4">
          No unassigned tasks for this projects with the selected phases.
        </h6>
      </div>
    </div>
  </div>
</div>
