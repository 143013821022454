<div #messageElement>
  <div
    class="message row ease bg-white m-0 p-4"
    [ngClass]="{
      'message-not-selected': isSelected != null && !isSelected,
      'selected-message': isSelected
    }"
    *ngIf="!message.is_event"
  >
    <div class="col-auto pl-0" style="padding-right: 5px">
      <div
        class="user-avatar d-inline-block align-middle pointer"
        [matTooltip]="message.created_by_first_name + ' ' + message.created_by_last_name"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
      >
        <img [src]="getProfileThumbnailUrl(message.created_by_id)" />
      </div>
    </div>
    <div class="col">
      <div class="row align-items-top">
        <div class="col">
          <p class="dkblue font-weight-bold margin-0">
            {{ message.created_by_first_name }} {{ message.created_by_last_name }}
          </p>
          <p class="m-0 ltblue font-weight-semibold" style="margin-top: -6px !important" *ngIf="message.to_users">
            <small>
              To:
              <span *ngFor="let u of message.to_users; index as userIndex"
                >{{ userIndex !== 0 ? ', ' : '' }}{{ u.first_name }} {{ u.last_name }}</span
              >
            </small>
          </p>
        </div>
        <div class="col-auto" style="margin: -0.9rem 0">
          <p class="gray m-0 mr-3 d-inline-block align-middle">
            <small>{{ message.created_datetime | date: 'MMM d yyyy • h:mm aa' }}</small>
            <small class="ml-4">
              <!-- IF THE CURRENT USER = TO_USER -->
              <!-- <i class="fa fa-circle-dot green" style="font-size:.9rem;" *ngIf="!isRead()" matTooltip="Mark Read"></i> -->

              <span *ngIf="message.created_by_id != authService.getLoggedInUser().id">
                <i
                  (click)="toggleMessageStatus()"
                  class="pointer fa fa-circle green"
                  style="font-size: 0.9rem"
                  *ngIf="!isRead()"
                  matTooltip="Mark Read"
                  matToolTipPosition="above"
                  [matTooltipShowDelay]="300"
                ></i>
                <i
                  (click)="toggleMessageStatus()"
                  class="pointer fa fa-circle ltgray opacity4"
                  style="font-size: 0.9rem"
                  *ngIf="isRead()"
                  matTooltip="Mark Unread"
                  matToolTipPosition="above"
                  [matTooltipShowDelay]="300"
                ></i>
              </span>
            </small>
          </p>
          <button
            mat-icon-button
            class="ltgray"
            matTooltip="Reply Here"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            (click)="beginNewMessage()"
          >
            <mat-icon>reply_all</mat-icon>
          </button>
          <!-- <button mat-icon-button class="ltgray" matTooltip="New Conversation" matTooltipPosition="above"
            [matTooltipShowDelay]="300" (click)="beginNewConversation()">
            <mat-icon>forward</mat-icon>
          </button> -->
          <button
            mat-icon-button
            [matMenuTriggerFor]="message_menu"
            class="ltgray"
            matTooltip="More"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #message_menu="matMenu" xPosition="before">
            <button mat-menu-item class="dkblue" (click)="toggleMessageStatus()">
              <mat-icon class="gray" *ngIf="!isRead()" matTooltip="Mark Read"> radio_button_checked </mat-icon>
              <mat-icon class="gray" *ngIf="isRead()" matTooltip="Mark Unread"> radio_button_checked </mat-icon>
              <span>
                {{ getReadOrUnreadText() }}
              </span>
            </button>
            <button (click)="beginReplyInConversation()" mat-menu-item class="dkblue" (click)="toggleMessageStatus()">
              <mat-icon class="gray"> reply </mat-icon>
              <span> Reply (New Conversation) </span>
            </button>
            <button (click)="beginForwardInConversation()" mat-menu-item class="dkblue" (click)="toggleMessageStatus()">
              <mat-icon class="gray"> forward </mat-icon>
              <span> Forward (New Conversation) </span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="row message-content">
        <div class="col">
          <h6 class="dkblue margin-0" style="white-space: pre-wrap">
            {{ message.content }}
          </h6>
        </div>
      </div>
      <div *ngIf="message.files" class="row message-content mt-4 mb-2">
        <div class="col">
          <mat-chip-list>
            <app-file-chip *ngFor="let file of message.files" [file]="file"></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
  <div class="messageEvent" *ngIf="message.is_event">
    <div class="message-notification text-center">
      <p class="ltblue margin-0 font-weight-semibold">{{ message.content }}</p>
    </div>
  </div>
</div>
