import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, find, remove } from 'lodash';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CompanyDialogComponent } from 'src/app/components';
import { UserType } from 'src/app/enums';
import {
  AppRoleService,
  AuthService,
  CompanyService,
  LocationService,
  ModalService,
  ModuleService,
  UserService,
} from 'src/app/services';
import {
  Access,
  APIFilter,
  ApplicationRoleDisplay,
  Building,
  Company,
  Department,
  Floor,
  Module,
  Suite,
  User,
  UserRequest,
} from 'src/app/types';

@Component({
  selector: 'app-account-review-dialog',
  templateUrl: './account-review-dialog.component.html',
  styleUrls: ['./account-review-dialog.component.scss'],
})
export class AccountReviewDialogComponent implements OnInit {
  UserType = UserType;
  workspaces: Module[] = [];
  userRequestFields = [
    'id',
    'type_id',
    'status_id',
    'type_name',
    'first_name',
    'last_name',
    'full_name',
    'email',
    'cell_phone',
    'office_phone',
    'company_name',
    'department_name',
    'title',
    'manager_name',
    'building_id',
    'floor_id',
    'suite_id',
    'department_id',
    'created_user_id',
    'created_datetime',
  ];
  userFields = [
    'id',
    'user_type_id',
    'first_name',
    'last_name',
    'email',
    'cell_phone',
    'office_phone',
    'company_name',
    'department_name',
    'title',
    'building_id',
    'floor_id',
    'suite_id',
    'department_id',
  ];
  accessFields = ['id', 'user_id', 'role_id', 'resource_type_id', 'resource_id'];
  public isLoading = false;
  public userExists = false;
  public numberOfManagersFound = 0;

  constructor(
    public dialogRef: MatDialogRef<AccountReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userRequest,
    public fb: FormBuilder,
    private snackbar: MatSnackBar,
    private locationService: LocationService,
    public roleService: AppRoleService,
    public companyService: CompanyService,
    public userService: UserService,
    public authService: AuthService,
    public moduleService: ModuleService,
    public dialog: MatDialog,
    private modalService: ModalService
  ) {}

  contactInfoFormGroup: FormGroup = this.fb.group({
    account_type: [this.userRequest ? this.userRequest.type_id : null, [Validators.required]],
    first_name: [this.userRequest ? this.userRequest.first_name : null, [Validators.required]],
    last_name: [this.userRequest ? this.userRequest.last_name : null, [Validators.required]],
    email: [this.userRequest ? this.userRequest.email : null, [Validators.required, Validators.email]],
    cell_phone: [this.userRequest ? this.userRequest.cell_phone : null],
    office_phone: [this.userRequest ? this.userRequest.office_phone : null],
    manager: [this.userRequest ? this.userRequest.manager : null],
  });

  locationFormGroup: FormGroup = this.fb.group({
    company: [this.userRequest ? { name: this.userRequest.company_name } : null],
    title: [this.userRequest ? this.userRequest.title : null],
    building_id: [this.userRequest ? this.userRequest.building_id : null],
    floor_id: [this.userRequest ? this.userRequest.floor_id : null],
    suite_id: [this.userRequest ? this.userRequest.suite_id : null],
    department_id: [this.userRequest ? this.userRequest.department_id : null],
    default_module_id: [this.userRequest.default_module_id],
  });

  rolesFormGroup: FormGroup = this.fb.group({
    module_access: [null],
  });

  userTypes = [];
  buildings: Building[] = [];
  floors: Floor[] = [];
  suites: Suite[] = [];
  departments: Department[] = [];

  roles: ApplicationRoleDisplay[];
  currentModuleAccess = [];
  companies: Company[];
  filteredCompanies: Company[];
  companyUsers: User[];
  filteredManagers: User[];

  get account_type() {
    return this.contactInfoFormGroup.get('account_type');
  }
  get first_name() {
    return this.contactInfoFormGroup.get('first_name');
  }
  get last_name() {
    return this.contactInfoFormGroup.get('last_name');
  }
  get email() {
    return this.contactInfoFormGroup.get('email');
  }
  get cell_phone() {
    return this.contactInfoFormGroup.get('cell_phone');
  }
  get office_phone() {
    return this.contactInfoFormGroup.get('office_phone');
  }
  get company() {
    return this.locationFormGroup.get('company');
  }
  get title() {
    return this.locationFormGroup.get('title');
  }
  get manager() {
    return this.contactInfoFormGroup.get('manager');
  }
  get building_id() {
    return this.locationFormGroup.get('building_id');
  }
  get floor_id() {
    return this.locationFormGroup.get('floor_id');
  }
  get suite_id() {
    return this.locationFormGroup.get('suite_id');
  }
  get department_id() {
    return this.locationFormGroup.get('department_id');
  }

  get module_access() {
    return this.rolesFormGroup.get('module_access');
  }

  get default_module_id() {
    return this.locationFormGroup.get('default_module_id');
  }

  async ngOnInit() {
    this.isLoading = true;
    this.workspaces = await this.moduleService.getAllWorkspaces().toPromise();
    this.userTypes = await this.userService.getUserTypes().toPromise();
    this.userRequest = await this.userService
      .getUserRequestById(this.userRequest.id, this.userRequestFields)
      .toPromise();

    // company filed is for Supplier account so we're only grabbing Supplier companies
    const companyFilters: APIFilter[] = [];
    await this.companyService
      .getCompanies(['id', 'name'], companyFilters)
      .toPromise()
      .then((companies: Company[]) => {
        this.companies = companies;
      });

    if (!this.userRequest.created_user_id) {
      // check if the user email is already taken
      await this._checkUserEmail(this.userRequest.email);
    }

    this.setAccountType();
    if (this.userRequest.created_user_id) {
      this.email.disable();
      const currentUserInfo = await this.userService
        .getUserById(this.userRequest.created_user_id, this.userFields)
        .toPromise();
      this.userRequest.type_id = currentUserInfo.user_type_id;
      this.userRequest.first_name = currentUserInfo?.first_name?.trim();
      this.userRequest.last_name = currentUserInfo?.last_name?.trim();
      this.userRequest.email = currentUserInfo?.email?.trim();
      this.userRequest.cell_phone = currentUserInfo.cell_phone;
      this.userRequest.office_phone = currentUserInfo.office_phone;
      this.userRequest.company_name = currentUserInfo?.company_name?.trim();
      this.userRequest.department_name = currentUserInfo?.department_name?.trim();
      this.userRequest.title = currentUserInfo?.title?.trim();
      this.userRequest.building_id = currentUserInfo.building_id;
      this.userRequest.floor_id = currentUserInfo.floor_id;
      this.userRequest.suite_id = currentUserInfo.suite_id;
      this.userRequest.department_id = currentUserInfo.department_id;
      const accessFilters = [
        {
          type: 'field',
          field: 'user_id',
          value: this.userRequest.created_user_id.toString(),
        },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'resource_type_id', value: 2 },
      ];
      this.currentModuleAccess = await this.authService.getAccess(this.accessFields, accessFilters, 1000).toPromise();
      this.module_access.setValue(cloneDeep(this.currentModuleAccess));
      for (const r of this.roles) {
        r.is_checked = !!this.currentModuleAccess.find((a) => a.role_id === r.id);
      }
    }
    this.populateLocationData(this.userRequest);
    this.markFormGroupTouched(this.contactInfoFormGroup);
    this.markFormGroupTouched(this.locationFormGroup);

    this.company.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm?.id) {
            // check to see if company is already selected
            return of(null);
          } else {
            if (typeof searchTerm === 'string' && this.companies) {
              return of(this.companies.filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase())));
            } else {
              return of(this.companies);
            }
          }
        })
      )
      .subscribe((companies) => {
        if (companies) {
          this.filteredCompanies = companies;
        } else {
          this.filteredCompanies = [];
        }
      });

    // find the manager using the string name
    const managerName = this.userRequest?.manager_name?.trim();
    const foundManagers = managerName
      ? await this.userService
          .searchUsers([
            { type: 'field', field: 'full_name', value: managerName, match: 'any' },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: 1 },
          ])
          .toPromise()
      : null;
    // if we found only one, it's a match, so we set the manager field
    if (foundManagers?.length === 1) {
      this.manager.setValue(foundManagers[0]);
      this.filteredManagers = foundManagers;
    } else if (foundManagers?.length > 1) {
      this.filteredManagers = foundManagers;
      this.numberOfManagersFound = foundManagers?.length;
    }

    this.manager.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          /*
          if (searchTerm && searchTerm.id) {
            // check to see if company is already selected
            return of(null);
          } else {
            if (searchTerm && this.companyUsers) {
              return of(
                this.companyUsers.filter((u) =>
                  `${u.first_name} ${u.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
                )
              );
            } else {
              return of(this.companyUsers);
            }
          }
          */
          // searches all users, need a strategy
          if (searchTerm && !searchTerm?.id) {
            return this.userService.searchUsers([
              // { type: 'field', field: 'email', value: searchTerm?.trim(), match: 'any' },
              // { type: 'operator', value: 'OR' },
              { type: 'field', field: 'full_name', value: searchTerm?.trim(), match: 'any' },
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'is_enabled', value: 1 },
            ]);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((users) => {
        if (users?.length) {
          this.filteredManagers = users;
        } else {
          this.filteredManagers = foundManagers;
        }
      });
    this.isLoading = false;
  }

  private async _checkUserEmail(email: string) {
    const result = await this.userService
      .getUsers([{ type: 'field', field: 'email', value: email }], ['id'])
      .toPromise();

    if (result.length > 0) {
      this.userExists = true;
      // disable forms
      this.contactInfoFormGroup.disable();
      this.locationFormGroup.disable();
      this.rolesFormGroup.disable();
    }
  }

  populateLocationData(userRequest: UserRequest) {
    [this.buildings, this.floors, this.suites, this.departments] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildingFilters: APIFilter[] = !userRequest.building_id
      ? [{ type: 'field', field: 'is_enabled', value: '1' }]
      : [
          { type: 'field', field: 'is_enabled', value: '1' },
          { type: 'operator', value: 'OR' },
          { type: 'field', field: 'id', value: userRequest.building_id.toString() },
        ];
    this.locationService.getBuildings(['id', 'name'], buildingFilters).subscribe((buildings: Building[]) => {
      this.buildings = buildings;
      const foundBuilding = find(this.buildings, { id: userRequest.building_id });
      this.building_id.setValue(foundBuilding ? foundBuilding.id : null);
      if (this.building_id.value) {
        const floorFilters: APIFilter[] = !userRequest.floor_id
          ? [
              { type: 'field', field: 'building_id', value: userRequest.building_id.toString() },
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'is_enabled', value: '1' },
            ]
          : [
              { type: 'field', field: 'building_id', value: userRequest.building_id.toString() },
              { type: 'operator', value: 'AND' },
              { type: 'operator', value: '(' },
              { type: 'field', field: 'is_enabled', value: '1' },
              { type: 'operator', value: 'OR' },
              { type: 'field', field: 'id', value: userRequest.floor_id.toString() },
              { type: 'operator', value: ')' },
            ];
        this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
          this.floors = floors;
          const foundFloor = find(this.floors, { id: userRequest.floor_id });
          this.floor_id.setValue(foundFloor ? foundFloor.id : null);
          if (this.floor_id.value) {
            const suiteFilters: APIFilter[] = !userRequest.suite_id
              ? [
                  { type: 'field', field: 'floor_id', value: userRequest.floor_id.toString() },
                  { type: 'operator', value: 'AND' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                ]
              : [
                  { type: 'field', field: 'floor_id', value: userRequest.floor_id.toString() },
                  { type: 'operator', value: 'AND' },
                  { type: 'operator', value: '(' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                  { type: 'operator', value: 'OR' },
                  { type: 'field', field: 'id', value: userRequest.suite_id.toString() },
                  { type: 'operator', value: ')' },
                ];
            this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
              this.suites = suites;
              const foundSuite = find(this.suites, {
                id: userRequest.suite_id,
              });
              this.suite_id.setValue(foundSuite ? foundSuite.id : null);
              const departmentFilters: APIFilter[] = !userRequest.department_id
                ? [
                    { type: 'field', field: 'suite_id', value: userRequest.suite_id?.toString() },
                    { type: 'operator', value: 'AND' },
                    { type: 'operator', value: '(' },
                    { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                    { type: 'operator', value: 'AND' },
                    { type: 'field', field: 'is_enabled', value: '1' },
                    { type: 'operator', value: ')' },
                  ]
                : [
                    { type: 'field', field: 'suite_id', value: userRequest.suite_id.toString() },
                    { type: 'operator', value: 'AND' },
                    { type: 'operator', value: '(' },
                    { type: 'operator', value: '(' },
                    { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                    { type: 'operator', value: 'AND' },
                    { type: 'field', field: 'is_enabled', value: '1' },
                    { type: 'operator', value: ')' },
                    { type: 'operator', value: 'OR' },
                    { type: 'field', field: 'id', value: userRequest.department_id.toString() },
                    { type: 'operator', value: ')' },
                  ];
              if (this.suite_id.value) {
                this.locationService
                  .getDepartments(['id', 'name'], departmentFilters)
                  .subscribe((departments: Department[]) => {
                    this.departments = departments;
                    const foundDepartment = find(this.departments, {
                      id: userRequest.department_id,
                    });
                    this.department_id.setValue(foundDepartment ? foundDepartment.id : null);
                  });
              }
            });
          }
        });
      }
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getBuildings() {
    this.locationService.getBuildings().subscribe((buildings: Building[]) => {
      this.buildings = buildings;
    });
  }

  getFloors() {
    if (this.building_id.value) {
      const floorFilters: APIFilter[] = !this.userRequest.floor_id
        ? [
            { type: 'field', field: 'building_id', value: this.building_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
          ]
        : [
            { type: 'field', field: 'building_id', value: this.building_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.userRequest.floor_id.toString() },
            { type: 'operator', value: ')' },
          ];
      this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floor_id.setValue(null);
        this.suites = [];
        this.suite_id.setValue(null);
        this.departments = [];
        this.department_id.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floor_id.setValue(null);
      this.suites = [];
      this.suite_id.setValue(null);
      this.departments = [];
      this.department_id.setValue(null);
    }
  }

  getSuites() {
    if (this.floor_id.value) {
      const suiteFilters: APIFilter[] = !this.userRequest.suite_id
        ? [
            { type: 'field', field: 'floor_id', value: this.floor_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
          ]
        : [
            { type: 'field', field: 'floor_id', value: this.floor_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.userRequest.suite_id.toString() },
            { type: 'operator', value: ')' },
          ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suite_id.setValue(null);
        this.departments = [];
        this.department_id.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suite_id.setValue(null);
      this.departments = [];
      this.department_id.setValue(null);
    }
  }

  getDepartments() {
    if (this.suite_id.value) {
      const departmentFilters: APIFilter[] = !this.userRequest.department_id
        ? [
            { type: 'field', field: 'suite_id', value: this.suite_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: ')' },
          ]
        : [
            { type: 'field', field: 'suite_id', value: this.suite_id.value.toString() },
            { type: 'operator', value: 'AND' },
            { type: 'operator', value: '(' },
            { type: 'operator', value: '(' },
            { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
            { type: 'operator', value: 'AND' },
            { type: 'field', field: 'is_enabled', value: '1' },
            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
            { type: 'field', field: 'id', value: this.userRequest.department_id.toString() },
            { type: 'operator', value: ')' },
          ];
      this.locationService.getDepartments(['id', 'name'], departmentFilters).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.department_id.setValue(null);
      });
    } else {
      this.departments = null;
      this.department_id.setValue(null);
    }
  }

  setAccountType() {
    if (this.userRequest.company_name) {
      this.company.setValue({ name: this.userRequest.company_name });
    } else {
      this.company.setValue(null);
    }

    if (this.account_type.value === UserType.Vendor) {
      this.company.setValidators([Validators.required, this.validCompany()]);
    } else if (this.account_type.value === UserType.Tenant) {
      this.company.setValidators([this.validCompany()]);
    } else {
      this.company.clearValidators();
    }
    this.company.updateValueAndValidity();
    this.roleService.getRolesObservable().subscribe((roles: ApplicationRoleDisplay[]) => {
      this.roles = roles.filter((r) => r.entity_type_id === this.account_type.value && r.resource_type_id === 2);
      this.module_access.setValue([]);
    });
  }

  private validCompany(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = typeof control.value === 'string' ? control.value : control.value?.name;
      const validCompany = this.companies.find((c) => c.name === name);
      return name && !validCompany ? { invalidCompany: { value: true } } : null;
    };
  }

  companyValueMapper(company) {
    return company ? company.name : null;
  }

  companyChanged() {
    if (this.company.value) {
      if (this.company.value.id) {
        const userFilters: APIFilter[] = [{ type: 'field', field: 'company_id', value: this.company.value.id }];
        this.userService.getUsers(userFilters, ['id', 'first_name', 'last_name']).subscribe((users) => {
          this.companyUsers = users;
        });
      } else if (this.company.value.name) {
        const company = this.companies.find((c) => c.name === this.company.value.name);
        if (company) {
          this.company.setValue(company);
        } else {
          // this.company.setErrors({ invalidCompany: true });
        }
      } else {
        // this.company.setErrors({ invalidCompany: true });
      }
    }
  }

  managerValueMapper(manager) {
    return manager ? `${manager.first_name} ${manager.last_name}` : null;
  }

  managerChanged() {
    if (this.manager.value && !this.manager.value.id) {
      this.manager.setErrors({ invalidManager: true });
    }
  }

  editUserRoles(role: ApplicationRoleDisplay, isSelected: boolean) {
    role.is_checked = isSelected;
    if (isSelected) {
      const foundAccess = this.module_access.value.find((a) => a.role_id === role.id);
      if (!foundAccess) {
        this.module_access.value.push({
          role_id: role.id,
          resource_id: this.moduleService?.workspace_id,
        });
      }
    } else {
      remove(this.module_access.value, (a: Access) => a.role_id === role.id);
    }
  }

  addNewCompany() {
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      width: '580px',
      disableClose: true,
      data: { company: { type_id: 3 } },
    });

    dialogRef.afterClosed().subscribe(async (createdCompany) => {
      if (createdCompany && createdCompany.id) {
        this.company.setValue(createdCompany);
      }
    });
  }

  async submit() {
    if (this.contactInfoFormGroup.valid && this.locationFormGroup.valid && this.rolesFormGroup.valid) {
      const userToSend: User = {
        first_name: this.first_name.value,
        last_name: this.last_name.value,
        cell_phone: this.cell_phone.value,
        office_phone: this.office_phone.value,
        company_id:
          (this.account_type.value === UserType.Vendor || this.account_type.value === UserType.Tenant) &&
          this.company?.value
            ? this.company.value.id
            : null,
        title: this.title.value,
        manager_id: this.account_type.value !== UserType.Vendor && this.manager?.value ? this.manager.value.id : null,
        building_id: this.account_type.value !== UserType.Vendor ? this.building_id.value : null,
        floor_id: this.account_type.value !== UserType.Vendor ? this.floor_id.value : null,
        suite_id: this.account_type.value !== UserType.Vendor ? this.suite_id.value : null,
        department_id: this.account_type.value !== UserType.Vendor ? this.department_id.value : null,
        user_type_id: this.account_type.value,
        default_module_id: this.account_type.value === UserType.Staff ? this.default_module_id.value : null,
      };
      let returnedUser: User;
      if (this.userRequest.created_user_id) {
        returnedUser = await this.userService
          .updateUser(this.userRequest.created_user_id, userToSend, this.userFields)
          .toPromise();
      } else {
        userToSend.email = this.email.value;
        returnedUser = await this.userService.createUser(userToSend, this.userFields).toPromise();
      }
      const accessToAdd = this.module_access.value.filter(
        (a) => !this.currentModuleAccess.find((ca) => ca.role_id === a.role_id)
      );
      for (const a of accessToAdd) {
        a.user_id = returnedUser.id;
        this.authService.grantAccess(a).subscribe();
      }
      const accessToRemove = this.currentModuleAccess.filter(
        (ca) => !this.module_access.value.find((a) => a.role_id === ca.role_id)
      );
      for (const a of accessToRemove) {
        this.authService.revokeAccess(a.id).subscribe();
      }
      this.snackbar.open(
        this.userRequest.created_user_id ? `User Updated!` : `User Created! An invitation has been sent to their email.`
      );
      const updatedRequest = await this.userService
        .updateUserRequest(this.userRequest.id, { status_id: 3 })
        .toPromise();
      this.dialogRef.close();
    } else {
      this.snackbar.open(`Please fill out all required fields`);
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  public rejectRequest() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reject Request',
        descriptionText: 'Are you sure you want to reject this account request?',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          await this.userService.updateUserRequest(this.userRequest.id, { status_id: 4 }).toPromise();
          this.close();
        }
      });
  }
}
