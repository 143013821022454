<div
  id="release-notes"
  data-test-id="release-notes"
  class="container-fluid app-container release-notes p-5"
  [ngClass]="{ 'no-scroll': openDialog }"
>
  <div class="row align-items-center mb-4">
    <div class="col">
      <h1 class="dkblue font-weight-semibold">Release Notes</h1>
    </div>
    <!-- <div class="col-auto">
      <button mat-button color="accent">Upload New Roadmap</button>
      <button mat-stroked-button color="accent" class="ml-3 bg-white">Download Roadmap</button>
    </div> -->
  </div>
  <div class="row align-items-center mb-4">
    <div class="col-auto">
      <button
        mat-button
        color="primary"
        [ngClass]="{ 'selected-tab': selectedTab == 'recent-releases' }"
        (click)="selectTab('recent-releases')"
      >
        Recent Releases
      </button>
    </div>
    <div class="col-auto">
      <button
        mat-button
        color="primary"
        [ngClass]="{ 'selected-tab': selectedTab == 'planned-features' }"
        (click)="selectTab('planned-features')"
      >
        Planned Features
      </button>
    </div>
    <div class="col-auto">
      <button
        mat-button
        color="primary"
        [ngClass]="{ 'selected-tab': selectedTab == 'known-bugs' }"
        (click)="selectTab('known-bugs')"
      >
        Known Bugs
      </button>
    </div>
  </div>

  <div
    class="row align-items-center search-recent-releases mb-4"
    *ngIf="releaseForm == null || featureForm == null || bugForm == null"
  >
    <div [ngClass]="{ col: isAppAdmin, 'col-12': !isAppAdmin }">
      <mat-form-field appearance="outline" class="full-width">
        <input matInput placeholder="Search Recent Releases" class="m-0" [formControl]="searchTerm" />
        <mat-icon *ngIf="searchTerm.value" class="gray pointer" (click)="clearSearchTerm()" matSuffix>cancel</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-auto pr-0">
      <!-- <button
        mat-stroked-button
        color="primary"
        class="bg-white pr-2"
        [matMenuTriggerFor]="filterMenu"
      >
        Filter List
        <mat-icon class="dropdown-arrow">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #filterMenu="matMenu">
        <button mat-menu-item>
          <span>Option 1</span>
        </button>
        <button mat-menu-item>
          <span>Option 2</span>
        </button>
      </mat-menu> -->
    </div>
    <div class="col-auto">
      <button
        *ngIf="selectedTab == 'recent-releases' && isAppAdmin"
        mat-flat-button
        color="success"
        (click)="addEditItem('release')"
      >
        + New Release
      </button>
      <button
        *ngIf="selectedTab == 'planned-features' && isAppAdmin"
        mat-flat-button
        color="success"
        (click)="addEditItem('feature')"
      >
        + New Feature
      </button>
      <button
        *ngIf="selectedTab == 'known-bugs' && isAppAdmin"
        mat-flat-button
        color="success"
        (click)="addEditItem('bug')"
      >
        + New Bug
      </button>
    </div>
  </div>

  <!-- Recent Releases -->
  <div *ngIf="selectedTab == 'recent-releases'">
    <div *ngIf="releaseForm == null">
      <div
        class="m-portlet"
        *ngFor="
          let release of releases
            | filter: searchTerm.value:releaseNoteReleasesFields
            | orderByField: 'release_date':'desc'
        "
      >
        <div class="m-portlet__body p-4">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="dkblue font-weight-semibold m-0 mb-3">{{ release.title }}</h4>
            </div>
            <div class="col-auto pr-0">
              <span
                ><em>{{ release.release_date | date: 'EEEE, MMM dd yyyy' }}</em></span
              >
            </div>
            <div class="col-auto">
              <button
                mat-icon-button
                [matMenuTriggerFor]="release_menu"
                color="primary"
                class="ml-2"
                *ngIf="isAppAdmin"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #release_menu="matMenu">
                <button mat-menu-item (click)="addEditItem('release', release)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Release</span>
                </button>
                <button mat-menu-item (click)="deleteRelease(release.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete Release</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <!--TODO add back in RELEASETAGS-->
          <!--          <div class="row align-items-center mb-4">-->
          <!--            <div class="col">-->
          <!--              <mat-chip-list>-->
          <!--                <mat-chip color="accent">Staff Only</mat-chip>-->
          <!--                <mat-chip color="accent">New Requests</mat-chip>-->
          <!--              </mat-chip-list>-->
          <!--            </div>-->
          <!--          </div>-->

          <p class="dkblue">
            {{ release.description }}
          </p>

          <div class="mt-4" *ngIf="release.files.length > 0">
            <h6 class="mb-2 dkblue">Attachments</h6>
            <!-- <mat-chip-list class="d-inline-block align-middle">
              <app-file-chip *ngFor="let f of release.files" [file]="f"></app-file-chip>
            </mat-chip-list> -->
            <mat-chip-list>
              <app-file-chip *ngFor="let file of release.files" [file]="file"></app-file-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Recent Releases End -->

  <!-- Planned Features -->
  <div *ngIf="selectedTab == 'planned-features'">
    <div *ngIf="featureForm == null">
      <div
        class="m-portlet"
        *ngFor="
          let feature of features
            | filter: searchTerm.value:releaseNoteFeaturesFields
            | orderByField: 'created_datetime':'desc'
        "
      >
        <div class="m-portlet__body p-4">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="dkblue font-weight-semibold m-0 mb-3">{{ feature.title }}</h4>
            </div>
            <div class="col-auto">
              <button
                mat-icon-button
                [matMenuTriggerFor]="feature_menu"
                color="primary"
                class="ml-2"
                *ngIf="isAppAdmin"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #feature_menu="matMenu">
                <button mat-menu-item (click)="addEditItem('feature', feature)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Feature</span>
                </button>
                <button mat-menu-item (click)="deleteFeature(feature.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete Feature</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="row align-items-center mb-4">
            <div class="col-auto">
              <div *ngIf="feature.timeframe_id == 1" class="status bg-success font-weight-semibold">On Schedule</div>
              <div *ngIf="feature.timeframe_id == 0" class="status bg-orange font-weight-semibold">Behind Schedule</div>
            </div>
            <div class="col-auto pl-0">
              <div class="dkgray">
                {{ feature.start_date | date: 'MMMM d yyyy' }} -
                {{ feature.end_date | date: 'MMMM d yyyy' }}
              </div>
            </div>
          </div>
          <!--TODO add back in RELEASETAGS-->
          <!--          <div class="row align-items-center mb-4">-->
          <!--            <div class="col">-->
          <!--              <mat-chip-list>-->
          <!--                <mat-chip color="accent">Staff Only</mat-chip>-->
          <!--                <mat-chip color="accent">New Requests</mat-chip>-->
          <!--              </mat-chip-list>-->
          <!--            </div>-->
          <!--          </div>-->

          <p class="dkblue mb-0">
            {{ feature.description }}
          </p>

          <div class="mt-4" *ngIf="feature.files.length > 0">
            <h6 class="mb-2 dkblue">Attachments</h6>
            <mat-chip-list class="d-inline-block align-middle">
              <app-file-chip *ngFor="let f of feature.files" [file]="f"></app-file-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Planned Features End -->

  <!-- Known Bugs -->
  <div *ngIf="selectedTab == 'known-bugs'">
    <div *ngIf="bugForm == null">
      <div
        class="m-portlet"
        *ngFor="
          let bug of bugs | filter: searchTerm.value:releaseNoteBugsFields | orderByField: 'created_datetime':'desc'
        "
      >
        <div class="m-portlet__body p-4">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="dkblue font-weight-semibold mb-3">{{ bug.title }}</h4>
            </div>
            <div class="col-auto">
              <button mat-icon-button [matMenuTriggerFor]="bugs_menu" color="primary" class="ml-2" *ngIf="isAppAdmin">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #bugs_menu="matMenu">
                <button mat-menu-item (click)="addEditItem('bug', bug)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Bug</span>
                </button>
                <button mat-menu-item (click)="deleteBug(bug.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete Bug</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div class="row align-items-center mb-4">
            <div class="col-auto" *ngIf="bug.timeframe_id != null">
              <div *ngIf="bug.timeframe_id == 0" class="status bg-orange font-weight-semibold">In Progress</div>
              <div *ngIf="bug.timeframe_id == 1" class="status bg-success font-weight-semibold">Fixed</div>
            </div>
            <div class="col-auto" *ngIf="bug.identified_date">
              <div class="identified">
                <mat-icon color="warn">error</mat-icon>
                <span class="dkblue">{{ bug.identified_date | date: 'MMMM d yyyy' }}</span>
              </div>
            </div>
            <div class="col-auto" *ngIf="bug.fixed_date">
              <div class="fixed">
                <mat-icon color="success">check_circle</mat-icon>
                <span class="dkblue">{{ bug.fixed_date | date: 'MMMM d yyyy' }}</span>
              </div>
            </div>
          </div>
          <!--TODO add back in RELEASETAGS-->
          <!--          <div class="mb-4">-->
          <!--            <mat-chip-list>-->
          <!--              <mat-chip color="accent">Staff Only</mat-chip>-->
          <!--              <mat-chip color="accent">New Requests</mat-chip>-->
          <!--            </mat-chip-list>-->
          <!--          </div>-->

          <p class="dkblue">
            {{ bug.description }}
          </p>

          <div class="mt-4" *ngIf="bug.files.length > 0">
            <h6 class="mb-2 dkblue">Attachments</h6>
            <mat-chip-list class="d-inline-block align-middle">
              <app-file-chip *ngFor="let f of bug.files" [file]="f"></app-file-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Known Bugs End -->
</div>
