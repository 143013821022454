<div
  id="editor"
  data-test-id="editor"
  class="editor"
  [ngClass]="{
    'has-error': invalid
  }"
>
  <h6
    *ngIf="heading"
    class="m-0 dkblue font-weight-semibold mb-2"
    [ngClass]="{ red: content?.errors && content.touched }"
  >
    {{ heading }}
  </h6>
  <ckeditor
    tagName="textarea"
    [formControl]="content"
    class="error editor"
    [ngClass]="{
      default: class_height === 'default',
      small: class_height === 'small',
      'small-fixed': class_height === 'small-fixed',
      medium: class_height === 'medium',
      large: class_height === 'large',
      disabled: disabled
    }"
    [editor]="Editor"
    [config]="config"
    (ready)="onEditorReady($event)"
    #ckEditor
  ></ckeditor>
</div>
<mat-error class="mt-1 mx-3" *ngIf="content.errors?.required && content.touched">
  {{ required_content }}
</mat-error>
<mat-hint
  *ngIf="showCounter"
  class="d-flex justify-content-end mx-3"
  [ngClass]="{
    red: overflow,
    gray: !overflow,
    'justify-content-between': overflow && content.errors?.maxlength
  }"
  ><span *ngIf="content.errors?.maxlength">Exceeded the maximum length allowed</span
  ><span>{{ content_counter }}</span></mat-hint
>
