import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { FileService, HandleErrorService, ProjectService } from 'src/app/services';

import { FileApproval } from 'src/app/models';

import { APPROVAL_TEMPLATE, FileApprovalSelectType, FileApprovalStatus, ResourceType } from 'src/app/enums';

import { ApprovalTemplate, Event, FileApprovalItem, ServiceResponse, Task, UhatFileReference } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class FileApprovalService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private taskService: ProjectService,
    private fileService: FileService
  ) {}

  host: string = environment.serviceHost;
  approvalsUrl = `${this.host}/api/v1/file-approvals`;
  approvalTemplatesUrl = `${this.host}/api/v1/approval-templates`;
  approvalItemsUrl = `${this.host}/api/v1/file-approval-items`;

  // Old approval process - TODO clean up the below and convert to the above

  // TODO remove for final prod
  // this function is used for testing an approval item when the approval id is known
  // specifically, this function sets the role ids for all approval items of the approval to 1 (app admin)
  async setApprovalRoleByApprovalId(approvalId: number) {
    await this.getApprovalItems(approvalId).subscribe((items) => {
      items.forEach((item) => this.updateApprovalItem(item.id, { role_id: 1 }).subscribe());
    });
  }
  // TODO remove for final prod
  // this function is used for testing an approval item when the project id is known
  // specifically, this function sets the role ids for all approval items of the approval to 1 (app admin)
  async setApprovalRoleByProjectId(projectId: number) {
    await this.taskService
      .getProjectById(projectId, ['approval_task_id', 'tenant_approval_task_id'])
      .subscribe((project) => {
        if (project.approval_task_id) {
          this.taskService.getTaskById(project.approval_task_id, ['file_approvals']).subscribe((task) => {
            task.file_approvals.forEach((approval) => this.setApprovalRoleByApprovalId(approval.id));
          });
        }
        if (project.tenant_approval_task_id) {
          this.taskService.getTaskById(project.tenant_approval_task_id, ['file_approvals']).subscribe((task) => {
            task.file_approvals.forEach((approval) => this.setApprovalRoleByApprovalId(approval.id));
          });
        }
      });
  }

  // creates a new approval for a set of files, also creates the approvalItems for this approval
  // required fields for approval:
  //  parent_id
  //  num_files_to_select (use -1 for all)
  //  type - this is the Approval_Template (which just means PEB or generic approval)
  createApproval(approval: FileApproval, linkedFileIds: number[] = []): Observable<FileApproval> {
    if (
      !approval.hasOwnProperty('parent_id') ||
      !approval.hasOwnProperty('num_files_to_select') ||
      !approval.hasOwnProperty('select_type')
    ) {
      this.handleErrorService.handleError(
        new HttpErrorResponse({ statusText: 'Invalid query - missing required fields' })
      );
    }
    const toCreate: FileApproval = {
      parent_id: approval.parent_id,
      num_files_to_select: approval.num_files_to_select,
      select_type: approval.select_type,
    };
    if (approval.hasOwnProperty('parent_type_id')) {
      toCreate.parent_type_id = approval.parent_type_id;
    }
    if (approval.hasOwnProperty('description')) {
      toCreate.description = approval.description;
    }
    if (approval.hasOwnProperty('status')) {
      toCreate.status = approval.status;
    }
    return this.http.post(`${this.approvalsUrl}`, toCreate).pipe(
      map((result: ServiceResponse) => {
        const res: FileApproval = Array.isArray(result.data.fileapprovals)
          ? result.data.fileapprovals[0]
          : result.data.fileapprovals;
        if (approval.approval_items) {
          approval.approval_items.forEach((item: FileApprovalItem) => {
            item.parent_approval_id = res.id;
            this.addApprovalItem(item).subscribe();
          });
        }
        linkedFileIds.forEach((fileId) =>
          this.fileService.linkFile(fileId, res.id, ResourceType.FileApproval).subscribe()
        );
        return res;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // use template id 1 for PEB template
  // approval requires parent_id (which is the taskID) and num_files_to_select and select_type, all other approval fields are optional
  // fileIds will automatically link the files to this approval
  // status of the approval is 1 if no files, and 2 if files
  // also creates all the new approval items (specified by the template)
  // TODO await the item creation, as currently if you use getApproval directly after, the items won't be linked
  createApprovalFromTemplate(
    approval: FileApproval,
    templateId: APPROVAL_TEMPLATE = APPROVAL_TEMPLATE.PEB_APPROVAL,
    fileIds: number[] = []
  ): Observable<FileApproval> {
    approval.status = fileIds.length > 0 ? FileApprovalStatus.AwaitingApproval : FileApprovalStatus.AwaitingUpload;
    return this.createApproval(approval, fileIds).pipe(
      map((createdApproval: FileApproval) => {
        this.getTemplateItem(templateId).subscribe((toAddItems) => {
          toAddItems.forEach((item) =>
            this.addApprovalItem({
              role_id: item.role_id,
              parent_approval_id: createdApproval.id,
              group_id: item.group_id,
            }).subscribe()
          );
        });
        return createdApproval;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // use template id 1 for PEB template
  // pass in the approval id to reset this approval
  // resetting works by setting all approval items for this approval with status of 1 or 2 (awaiting) to active=0
  // after disabling all invalid old approval items, it links the old files to the last approval item that was rejected (status = 4)
  // then, it creates all the new approval items (specified by the template)
  // and then links all the new files to the approval and resets the approval status of the approval to 1 (if no files), or 2 (if files)
  // TODO await the item creation, as currently if you use getApproval directly after, the items won't be linked
  resetApprovalFromTemplate(
    approvalId: number,
    templateId: APPROVAL_TEMPLATE,
    fileIds: number[] = []
  ): Observable<FileApproval> {
    return this.getApproval(approvalId).pipe(
      map((approval: FileApproval) => {
        this.getApprovalItems(approvalId).subscribe((approvalItems) => {
          let max_group = 0;
          // set active to 0 for any with status 1 or 2
          approvalItems.forEach((item) => {
            if (+item.status === 1 || +item.status === 2) {
              this.disableApprovalItem(item.id).subscribe();
            }
            if (+item.group_id > max_group) {
              max_group = +item.group_id;
            }
          });

          // link old files to oldest item with status 4
          const toLinkItems = approvalItems.filter((item) => item.status === 4 && !item.rejected_files);
          const orderedByGroupDesc = toLinkItems.sort(
            (a: FileApprovalItem, b: FileApprovalItem) => b.group_id - a.group_id
          );
          // go from last to first, and assign all old files to the last item with status of 4
          if (approval.files) {
            if (orderedByGroupDesc && orderedByGroupDesc[0]) {
              approval.files.forEach((file) =>
                this.fileService.linkFile(file.id, orderedByGroupDesc[0].id, ResourceType.FileApprovalItem).subscribe()
              );
            }

            // remove old file links
            approval.files.forEach((file) =>
              this.fileService
                .getBridgeFile(file.id, approval.id, ResourceType.FileApproval)
                .subscribe((files: UhatFileReference[]) =>
                  files.forEach((f) => this.fileService.unlinkFile(f.id).subscribe())
                )
            );
          }

          // add new file links
          fileIds.forEach((id) => this.fileService.linkFile(id, approval.id, ResourceType.FileApproval).subscribe());

          // reset approval status to 1 or 2 (depending on if we have files to link)
          this.updateApprovalStatus(
            approvalId,
            fileIds.length > 0 ? FileApprovalStatus.AwaitingApproval : FileApprovalStatus.AwaitingUpload
          ).subscribe();

          // creates the new approval items (add the max group number so it adds the new ones on)
          this.getTemplateItem(templateId).subscribe((toAddItems) => {
            toAddItems.forEach((item) =>
              this.addApprovalItem({
                role_id: item.role_id,
                parent_approval_id: approval.id,
                group_id: max_group + item.group_id,
              }).subscribe()
            );
          });
        });
        return approval;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the template items for a given template id
  getTemplateItem(templateId: APPROVAL_TEMPLATE = APPROVAL_TEMPLATE.PEB_APPROVAL): Observable<ApprovalTemplate[]> {
    return this.http.get(`${this.approvalTemplatesUrl}?filter=template_id=${templateId}`).pipe(
      map((result: ServiceResponse) => {
        const items: ApprovalTemplate[] = result.data.approval_templates;
        return items;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the template items for a given template id
  getTemplateItems(): Observable<ApprovalTemplate[]> {
    return this.http.get(`${this.approvalTemplatesUrl}`).pipe(
      map((result: ServiceResponse) => {
        const items: ApprovalTemplate[] = result.data.approval_templates;
        return items;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // creates a task, while also creating the approval (with approval items) and links it to the task
  // required fields for task are title and milestone_id
  // if you also pass in a file_approvals field, it will create the file_approvals for the tasks (field required is parent_id)
  /*
   * to call this function, task requires the following format
   * task: {
   *    title: string,
   *    milestone_id: number,
   *    file_approvals: FileApproval[]
   * }
   * feel free to pass in any other optional parameters for task or file_approvals
   * pass in templateId to create the file_approvals with approval_items specified by the template
   *
   * the default values for the approval are PEB + verify all (-1)
   * override the defaults as needed (numFilesToChoose, selectType)
   *
   * project id is required so we know where to link the approval_task_id and tenant_approval_task_id on the appropriate project if selectType is PEB
   */
  createApprovalTask(
    task: Task,
    projectId: number,
    templateId?: APPROVAL_TEMPLATE,
    uhatFileIds: number[] = [],
    numFilesToChoose: number = -1,
    selectType: FileApprovalSelectType = FileApprovalSelectType.PEB_APPROVAL
  ): Observable<Task> {
    // check for fields
    if (
      !(task.hasOwnProperty('title') && task.hasOwnProperty('milestone_id') && task.hasOwnProperty('file_approvals'))
    ) {
      throw new Error('Missing fields for creating task');
    }

    const toCreate: Task = { title: task.title, milestone_id: task.milestone_id };
    const obs = new ReplaySubject();
    if (task.hasOwnProperty('description')) {
      toCreate.description = task.description;
    }
    if (task.hasOwnProperty('assigned_user_id')) {
      toCreate.assigned_user_id = task.assigned_user_id;
    }
    if (task.hasOwnProperty('due_date')) {
      toCreate.due_date = task.due_date;
    }
    if (task.hasOwnProperty('follower_ids')) {
      toCreate.follower_ids = task.follower_ids;
    }
    // creates the task based off the passed data
    this.taskService.createTask(toCreate).subscribe((createdTask) => {
      // creates the approvals from a template if one was passed in, otherwise creates the approvals based off their file_approval_items field
      task.file_approvals.forEach((approval) => {
        approval.parent_id = createdTask.id;
        approval.num_files_to_select = numFilesToChoose;
        approval.select_type = selectType;
        if (templateId != null) {
          this.createApprovalFromTemplate(approval, templateId, uhatFileIds).subscribe((createdApproval) => {
            this.updateApprovalId(projectId, createdApproval.id, createdTask.id, selectType);
          });
        } else {
          this.createApproval(approval, uhatFileIds).subscribe((createdApproval) =>
            this.updateApprovalId(projectId, createdApproval.id, createdTask.id, selectType)
          );
        }
      });
      obs.next(createdTask);
    });
    return obs.asObservable();
  }

  // updates the approval id or tenant approval id on the project, depending on what select type is used
  private updateApprovalId(projectId: number, approvalId: number, taskId: number, selectType: FileApprovalSelectType) {
    this.http
      .put(`${this.approvalsUrl}/${approvalId}`, { select_type: selectType })
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.fileapprovals;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe();
  }

  // adds a new approval item (user) to the approval
  // role_id and parent_approval_id are required
  addApprovalItem(approvalItem: FileApprovalItem) {
    return this.http.post(`${this.approvalItemsUrl}`, approvalItem).pipe(
      map((result: ServiceResponse) => {
        return result.data.fileapprovalitems;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the status of an approval item (i.e. this user has approved the files)
  updateApprovalItemStatus(approvalItemId: number, newStatus: FileApprovalStatus, newComment: string = '') {
    const body = { status: newStatus, comment: newComment };
    return this.http.put(`${this.approvalItemsUrl}/${approvalItemId}`, body).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates an approval item
  updateApprovalItem(approvalItemId: number, approvalItem: FileApprovalItem) {
    return this.http.put(`${this.approvalItemsUrl}/${approvalItemId}`, approvalItem).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the status of an approval item (i.e. this user has approved the files)
  updateApprovalStatus(approvalId: number, newStatus: FileApprovalStatus) {
    const body = { status: newStatus };
    return this.http.put(`${this.approvalsUrl}/${approvalId}`, body).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the status of an approval item (i.e. this user has approved the files)
  private disableApprovalItem(approvalItemId: number) {
    return this.http.delete(`${this.approvalItemsUrl}/${approvalItemId}`).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the status of an approval item (i.e. this user has approved the files)
  private updateApprovalItemGroup(groupId: number, approvalItemId: number) {
    const body = { groupId };
    return this.http.put(`${this.approvalItemsUrl}/${approvalItemId}`, body).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the order of the approval process
  // the order is determined by the group id, so make sure those are filled out
  // basically each item gets updated and has its group id changes
  updateApprovalOrder(approvalItems: FileApprovalItem[]) {
    approvalItems.forEach((item: FileApprovalItem) => this.updateApprovalItemGroup(item.group_id, item.id).subscribe());
  }

  // gets the activity related to the approval
  getApprovalActivity(approvalId: number): Observable<Event[]> {
    return this.getApproval(approvalId).pipe(
      map((result: FileApproval) => {
        return result.events;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getRejectedFiles(approvalItemId: number): Observable<UhatFileReference[]> {
    return this.getApprovalItem(approvalItemId).pipe(
      map((result: FileApprovalItem) => {
        const files: UhatFileReference[] = result.rejected_files;
        return files;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the approval data
  getApproval(approvalId: number): Observable<FileApproval> {
    return this.http.get(`${this.approvalsUrl}/${approvalId}?limit=1`).pipe(
      map((result: ServiceResponse) => {
        const approval: FileApproval = result.data.fileapprovals[0];
        return approval;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the approval data
  getApprovals(): Observable<FileApproval[]> {
    return this.http.get(`${this.approvalsUrl}?limit=10000`).pipe(
      map((result: ServiceResponse) => {
        return result.data.file_approvals;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets all the approval items for the given approval
  getApprovalItems(approvalId: number): Observable<FileApprovalItem[]> {
    return this.http.get(`${this.approvalItemsUrl}?filter=parent_approval_id=${approvalId}`).pipe(
      map((result: ServiceResponse) => {
        const items: FileApprovalItem[] = result.data.file_approval_items;
        return items;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets the appropriate approval item
  // TODO verify result.data
  getApprovalItem(approvalItemId: number): Observable<FileApprovalItem> {
    return this.http.get(`${this.approvalItemsUrl}/${approvalItemId}?limit=1`).pipe(
      map((result: ServiceResponse) => {
        const items: FileApprovalItem = result.data.fileapprovalitems[0];
        return items;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
