<div id="select-items-dialog" data-test-id="select-items-dialog" class="select-items-dialog">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h5 class="ltblue m-0 font-weight-semibold">{{ itemType }} List</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="close()" class="close-modal">
        <i class="la la-times gray"></i>
      </a>
    </div>
  </div>
  <mat-dialog-content>
    <div class="row align-items-bottom mb-2">
      <div class="col-8">
        <h5 class="m-0 lh-24 dkblue font-weight-normal">
          Select the {{ itemType }} you'd like to be included in the export
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <button (click)="deselectAll()" mat-button color="accent" class="lh-24 pl-2 pr-2">Deselect All</button>
        <button (click)="selectAll()" mat-button color="accent" class="lh-24 pl-2 pr-2 ml-2">Select All</button>
      </div>
    </div>
    <div class="row">
      <div class="col selections">
        <mat-selection-list *ngIf="items.length > 0">
          <ng-container *ngFor="let item of items">
            <mat-list-option class="dkblue" [selected]="item.selected" (click)="item.selected = !item.selected">
              <h6 class="m-0 dkblue d-inline-block align-middle">
                <b *ngIf="item.local_index" class="mr-3">
                  {{ item.local_index }}
                </b>
                <span>
                  {{ item.description }}
                </span>
              </h6>
            </mat-list-option>
            <mat-list-option
              *ngIf="item.hasAttachments && item.selected"
              [selected]="item.selectedAttachments"
              (click)="item.selectedAttachments = !item.selectedAttachments"
            >
              <p class="text-muted pl-4">Include attachments</p></mat-list-option
            >
          </ng-container>
        </mat-selection-list>
        <div *ngIf="items.length <= 0" class="no-items-panel">
          <div class="no-items-panel-content">
            <h1>No {{ itemType }}</h1>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="row pt-4 pb-4 align-items-center">
    <div class="col">
      <button mat-button (click)="close()" class="gray full-width">Cancel</button>
    </div>
    <div class="col">
      <button
        mat-flat-button
        cdkFocusInitial
        class="full-width"
        color="success"
        [disabled]="items.length < 1"
        (click)="submit()"
      >
        {{ submitText }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
