import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, uniqBy } from 'lodash';
import { TaskAccessoryDataFactory } from 'src/app/models';
import {
  DateService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
} from 'src/app/services';
import { APIFilter } from 'src/app/types';
import { SubmittalsService } from 'src/app/workspaces/construction/services';
import { BidPackage, ProjectSubmittal, SubmittalCategory, SubmittalSpec } from 'src/app/workspaces/construction/types';
import { ResourceType } from '../../enums';

@Component({
  selector: 'app-add-submittals-dialog',
  templateUrl: './add-submittals-dialog.component.html',
  styleUrls: ['./add-submittals-dialog.component.scss'],
})
export class AddSubmittalsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddSubmittalsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private modalService: ModalService,
    private progressIndicatorModal: ProgressIndicatorService,
    public projectService: ProjectService,
    private submittalsService: SubmittalsService,
    private taskService: ProjectTaskService,
    private dateService: DateService,
    public router: Router
  ) {}

  private projectSubmittalFields: string[] = ['id', 'bid_package_id', 'spec{id,name}'];
  private submittalSpecFields: string[] = ['id', 'code', 'name', 'category'];

  public bidPackage: BidPackage;
  public isLoading = true;
  public projectSubmittals: ProjectSubmittal[];
  public submittalCategories: SubmittalCategory[];
  public submittalSpecs: SubmittalSpec[];
  public removeSpecIds: number[] = [];
  public selectedSpecIds: number[] = [];
  public usedSpecIds: number[] = [];
  public isEditing = false;

  async ngOnInit() {
    this.bidPackage = this.data?.bidPackage;
    this.submittalSpecs = await this.submittalsService.getSubmittalSpecs(this.submittalSpecFields).toPromise();

    const projectSubmittalFilters: APIFilter[] = [
      { type: 'field', field: 'bid_package_id', value: this.bidPackage.id.toString() },
    ];
    this.projectSubmittals =
      (await this.submittalsService
        .getProjectSubmittals(this.projectSubmittalFields, projectSubmittalFilters)
        .toPromise()) || [];

    this.isLoading = false;

    const submittalCategories: SubmittalCategory[] = uniqBy(
      this.submittalSpecs.map((i) => ({
        id: i.category_id,
        code: i.category.code,
        name: i.category.name,
        specs: null,
      })),
      (c) => c.id
    );

    for (const spec of this.submittalSpecs) {
      if (this.projectSubmittals.find((ps) => ps.spec_id === spec.id)) {
        spec.is_added = true;
        this.isEditing = true;
        this.usedSpecIds.push(spec.id);
      }
    }

    for (const category of submittalCategories) {
      category.specs = filter(this.submittalSpecs, (s) => s.category_id === category.id);
      category.all_specs_selected = !category.specs?.find((spec) => !spec.is_added);
    }

    this.submittalCategories = submittalCategories;
    this.selectedSpecIds = [...(this.selectedSpecIds || []), ...(this.usedSpecIds || [])];
  }

  deselectCategorySpecs(category: SubmittalCategory) {
    category.specs.forEach((spec) => {
      const specIndex = this.selectedSpecIds.indexOf(spec.id);
      if (specIndex !== -1) {
        spec.is_added = false;
        this.selectedSpecIds.splice(specIndex, 1);
        this.removeSpecIds.push(spec.id);
      }
    });

    category.all_specs_selected = false;
  }

  selectCategorySpecs(category: SubmittalCategory) {
    category.specs.forEach((spec) => {
      if (!spec.is_added) {
        spec.is_added = true;
        this.selectedSpecIds.push(spec.id);
        this.removeSpecIds = this.removeSpecIds?.filter((id) => id !== spec.id);
      }
    });

    category.all_specs_selected = true;
  }

  // Adds or removes project submittals that need to be created on submission
  public toggleSpecSelection(spec: SubmittalSpec) {
    spec.is_added = !spec.is_added;
    if (spec.is_added) {
      this.selectedSpecIds.push(spec.id);
      this.removeSpecIds = this.removeSpecIds.filter((id) => id !== spec.id);
    } else {
      this.selectedSpecIds = this.selectedSpecIds.filter((id) => id !== spec.id);
      this.removeSpecIds.push(spec.id);
    }

    const category = this.submittalCategories.find((c) => c.id === spec.category_id);
    category.all_specs_selected = !category.specs?.find((s) => !s.is_added);
  }

  public async submit() {
    const projectSubmittalsToAdd = this.selectedSpecIds.filter((id) => !this.usedSpecIds.includes(id));
    let submittalsText = '';
    let descriptionText =
      '<p class="mb-4">This action will create a task - assigned to the awarded supplier contact - to upload submittals for the items listed below. </p>';
    this.submittalSpecs.forEach((s) => {
      if (s.is_added) {
        submittalsText += `<small>• ${s.code}&nbsp;&nbsp;&nbsp;<span class="font-weight-semibold">${s.name}</span> </small> <br/>`;
      }
    });
    descriptionText += submittalsText;

    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Submittals',
        headerText: `${this.isEditing ? 'Update' : 'Request'} Submittals`,
        descriptionText,
        confirmationButtonText: `${this.isEditing ? 'Update' : 'Request'} Submittals`,
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.progressIndicatorModal.openAwaitIndicatorModal();
          this.progressIndicatorModal.updateStatus('Updating Submittals...');
          const projectSubmittalsToRemove = this.usedSpecIds.filter((id) => this.removeSpecIds.includes(id));

          for (const specId of projectSubmittalsToAdd) {
            const projectSubmittalData = {
              bid_package_id: this.bidPackage.id,
              spec_id: specId,
            };
            await this.submittalsService
              .addProjectSubmittal(projectSubmittalData, ['id', 'bid_package_id', 'spec_id'])
              .toPromise();
          }

          for (const specId of projectSubmittalsToRemove) {
            const projectSubmittalToRemove = this.projectSubmittals.find((ps) => ps.spec_id === specId);
            await this.submittalsService.removeProjectSubmittal(projectSubmittalToRemove.id).toPromise();
          }

          if (!this.bidPackage.submittal_task_id) {
            this.progressIndicatorModal.updateStatus('Creating vendor task...');
            const phaseInfo = await this.getPhaseInfo();

            const taskData = {
              title: `Upload Submittals - ${this.bidPackage.awarded_company_name} - ${this.bidPackage.trade_name}`,
              description: `Please use the button below to add files for the requested submittals: </br> ${submittalsText}`,
              assigned_user_id:
                this.bidPackage.awarded_bid_company?.contact_id ||
                this.projectService.currentSelectedProject.project_manager_id,
              milestone_id: phaseInfo.milestoneId,
              accessory_data: JSON.stringify(TaskAccessoryDataFactory.createSubmittalsData(this.bidPackage.id)),
              can_delete: 0,
              due_date: this.dateService.addWeekdays(7).format('YYYY-MM-DD'),
            };

            const createdTask = await this.projectService.createTask(taskData).toPromise();

            if (createdTask?.id && this.bidPackage?.awarded_bid_company?.contact_id) {
              await this.taskService
                .addFollowerToTask(createdTask.id, this.bidPackage.awarded_bid_company.contact_id)
                .toPromise();
            }

            this.progressIndicatorModal.updateStatus('Updating bid package...');
            await this.projectService
              .updateBidPackage(this.bidPackage.id, { submittal_task_id: createdTask.id }, ['submittal_task_id'])
              .toPromise();
            this.bidPackage.submittal_task_id = createdTask.id;
          } else {
            const taskData = {
              id: this.bidPackage.submittal_task_id,
              assigned_user_id:
                this.bidPackage.awarded_bid_company?.contact_id ||
                this.projectService.currentSelectedProject.project_manager_id,
              due_date: this.dateService.addWeekdays(7).format('YYYY-MM-DD'),
              description: `Please use the button below to add files for the requested submittals: </br> ${submittalsText}`,
              accessory_data: JSON.stringify(TaskAccessoryDataFactory.createSubmittalsData(this.bidPackage.id)),
              status_id: 1,
            };
            await this.projectService.updateTask(taskData).toPromise();
            await this.projectService
              .createNote(
                ResourceType.Task,
                this.bidPackage.submittal_task_id,
                'Review reset: <br/>Changed submittals requested'
              )
              .toPromise();
            this.reloadComponent(true);
          }

          this.progressIndicatorModal.close();
          this.dialogRef.close(this.bidPackage.submittal_task_id);
        }
      });
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        console.log(`Component Reloaded!`);
      });
    });
  }

  private async getPhaseInfo(milestoneName = 'Supplier Submittals') {
    const phases = await this.projectService
      .getPhasesByProjectId(this.projectService.currentSelectedProjectId)
      .toPromise();
    const phase = phases.find((p) => p.name === 'Construction');

    const milestones = (phase && (await this.projectService.getMilestonesByPhaseId(phase.id).toPromise())) || null;
    const milestone =
      milestones.find((m) => m.name === milestoneName) ||
      (phase &&
        (await this.projectService
          .createMilestone({ name: milestoneName, phase_id: phase.id, sequence: 1 })
          .toPromise())) ||
      null;

    return {
      phaseName: phase?.name,
      milestoneName: milestone?.name,
      milestoneId: milestone?.id,
    };
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
