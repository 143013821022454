<div class="message-conversation-panel">
  <div class="row panel-conversation-header align-items-middle">
    <div class="col">
      <h4 class="dkblue margin-0 font-weight-semibold d-inline-block align-middle">
        {{ conversation ? conversation.subject : 'No Conversation Selected' }}
      </h4>
      <p class="margin-0 d-inline-block align-middle">
        &nbsp; &nbsp;
        <span class="badge badge-warning white m-1" *ngIf="containsStaffFollowers"> UHAT/1Call </span>
        <span class="badge badge-secondary m-1" *ngIf="containsTenantFollowers"> Tenants </span>
        <span class="badge badge-danger m-1" *ngIf="containsVendorFollowers"> Suppliers </span>
      </p>
      <div style="display: flex">
        <p class="m-0 ltblue" *ngIf="conversation && conversation.channel_project_id">
          <a class="ltblue m-0 d-inline-block align-middle" (click)="gotoProject()">
            <i class="fa fa-clipboard-check"></i> Project {{ conversation.channel_project_code }}
            {{ conversation.channel_project_title ? '-' : '' }}
            {{ conversation.channel_project_title }}
          </a>
        </p>
        <p class="m-0 ltblue" *ngIf="conversation && conversation.task_id">
          &nbsp;
          <span class="ltgray d-inline-block align-middle">/</span> &nbsp;
          <a class="ltblue m-0 d-inline-block align-middle" (click)="gotoTask()">
            <i class="la la-share"></i> {{ conversation.task_code }}
          </a>
        </p>
      </div>
    </div>
    <div class="addUser showBefore d-inline-block align-middle" style="padding-top: 2px">
      <button
        mat-icon-button
        class="ltgray"
        matTooltip="Add Follower"
        matTooltipPosition="above"
        [matTooltipShowDelay]="300"
        (click)="addFollowers()"
      >
        <mat-icon *ngIf="!searching">person_add</mat-icon>
        <mat-icon *ngIf="searching">cancel</mat-icon>
      </button>
    </div>
    <div class="col-auto" style="padding: 5px 15px !important">
      <div *ngIf="conversation" class="d-inline-block align-middle">
        <div class="pointer" [matMenuTriggerFor]="avatar_menu">
          <app-profile-thumbnail
            *ngFor="let follower of conversation.followers | slice: 0:4"
            class="d-inline-block align-middle"
            [width]="32"
            [height]="32"
            [userId]="follower.id"
            matTooltip="{{ follower.first_name }} {{ follower.last_name }}"
            matTooltipPosition="below"
            [matTooltipShowDelay]="300"
            style="margin-left: -13px"
          ></app-profile-thumbnail>
          <p
            *ngIf="conversation.followers.length > 4"
            class="ltblue font-weight-semibold m-0 d-inline-block align-middle pointer pl-2"
          >
            + {{ conversation.followers.length - 4 }}
          </p>
        </div>
        <mat-menu #avatar_menu="matMenu" xPosition="before">
          <button
            *ngFor="let follower of conversation.followers"
            (click)="openUserProfilePreview(follower.id)"
            mat-menu-item
            color="primary"
          >
            <div class="menu-user-avatar d-inline-block align-top">
              <img [src]="getProfileThumbnailUrl(follower.id)" />
            </div>
            &nbsp;
            <span class="d-inline-block align-middle pointer">
              {{ follower.first_name }} {{ follower.last_name }}
            </span>
          </button>
        </mat-menu>
      </div>
      &nbsp;
      <button mat-stroked-button color="accent" (click)="toggleFollow()">
        {{ this.isFollowing ? 'Unfollow' : 'Follow' }}
      </button>
    </div>
  </div>
  <div style="height: 100%">
    <as-split
      style="background-color: rgb(234, 236, 240); border-top: 6px solid rgb(236, 238, 243)"
      unit="pixel"
      direction="vertical"
      (gutterClick)="toggleConversationPanelSize()"
      (dragEnd)="onSplitBarDragReleased($event)"
      #splitComponent
    >
      <as-split-area size="*">
        <div class="panel-conversation-messages">
          <div
            class="full-width text-center mb-2"
            *ngIf="conversation && conversationMessagesHaveMoreToLoad(conversation.id)"
            (click)="loadMoreMessages()"
          >
            <i>View Older Messages</i>
          </div>
          <!-- TODO ADAM STYLE -->
          <app-messaging-conversation-panel-message
            *ngFor="let message of messagesObservable | async | orderByField: 'created_datetime':'asc'"
            [message]="message"
          >
          </app-messaging-conversation-panel-message>
        </div>
        <!-- THIS ROW WILL ONLY BE SHOWN IF THE NEW MESSAGE DRAWER IS MINIMIZED, THESE BUTTON WILL MIMIC THE REPLY ALL/FORWARD ACTIONS OF THE LAST MESSAGE  -->
        <div *ngIf="!newMessagePanelOpen" class="row pt-4 pb-5">
          <div class="col" style="padding-left: 87px">
            <button mat-flat-button class="mr-4" color="success" (click)="replyAll()">
              <mat-icon>reply_all</mat-icon>
              <span class="ml-2"> Reply Here </span>
            </button>
          </div>
        </div>
      </as-split-area>
      <as-split-area
        size="{{ newMessagePanelOpen ? OPEN_MESSAGE_PXL : MIN_NEW_MESSAGE_PXL }}"
        #saNewMessageView
        minSize="{{ MIN_NEW_MESSAGE_PXL }}"
      >
        <div class="panel-conversation-new-message bg-white">
          <div class="row p-4">
            <app-new-conversation-message
              class="col"
              (openPanelEvent)="toggleConversationPanelSize()"
              [panelIsOpen]="newMessagePanelOpen"
            ></app-new-conversation-message>
          </div>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>
