<!-- makes this draggable-->
<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div id="assign-user-dialog" data-test-id="assign-user-dialog" class="assign-user-dialog">
  <div mat-dialog-content>
    <div class="row mt-3">
      <div class="col">
        <app-search-user-input
          #assigned_user
          [assignToMeText]="assignToMeText"
          [isARequestorUser]="isARequestorUser"
          [placeholder]="dialogPlaceholder"
          [selectedUserType]="selectedUserType"
          [show_department]="true"
          (selection)="userSelected($event)"
          [close_on_selection]="true"
          [assignment_option]="true"
          [initialValueId]="initialValueId"
        ></app-search-user-input>
      </div>
    </div>
  </div>
</div>
