<div id="directory" data-test-id="directory" #mainScreen (window:resize)="onResize()" class="directory app-container">
  <section id="main" class="ease p-4">
    <header class="mb-4 d-flex align-items-start">
      <div class="d-flex mr-auto">
        <span class="d-flex flex-column">
          <h2 class="m-0 dkblue font-weight-semibold d-inline-block align-bottom mr-auto">Directory</h2>
          <p class="dkgray font-weight-normal mb-2">View accounts, departments, or companies.</p>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <button
          mat-button
          color="accent"
          class="ml-3"
          matRipple
          [routerLink]="['/account-review']"
          *ngIf="canReviewAccounts"
        >
          <span>Account Requests</span>
        </button>
        <button
          mat-button
          color="accent"
          class="ml-3"
          *ngIf="this.authService.isAppAdmin"
          matRipple
          (click)="openLoginHelp()"
        >
          <span>Login Help</span>
        </button>
        <button
          mat-button
          class="ml-3"
          *ngIf="authService.isAppAdmin || authService.isARManager"
          color="accent"
          [routerLink]="['/ar-manager']"
        >
          AR Manager
        </button>
      </div>
    </header>
    <router-outlet></router-outlet>
  </section>
</div>
