import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, RequestMethod, ServiceResponse } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class RequestMethodService {
  private _host: string = environment.serviceHost;
  private _requestMethodUrl = `${this._host}/api/v1/request-methods`;

  constructor(
    private _http: HttpClient,
    private _handleErrorService: HandleErrorService,
    private _apiFilterService: ApiFilterService
  ) {}

  public getRequestMethods(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<RequestMethod[]> {
    const filterString = this._apiFilterService.getFilterString(apiFilters);
    return this._http
      .get(
        `${this._requestMethodUrl}?limit=${limit || 10000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const requestMethod: RequestMethod[] = result.data.request_methods;
          return requestMethod;
        }),
        catchError((e) => this._handleErrorService.handleError(e))
      );
  }
}
