import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { FileService } from 'src/app/services';

import { ResourceType } from 'src/app/enums';
import { UhatFileReference } from 'src/app/types';

@Injectable({
  providedIn: 'root',
})
export class ProjectFilesService {
  private files = new Map<ResourceType, Map<number, BehaviorSubject<UhatFileReference[]>>>();

  constructor(private fileService: FileService) {}

  // public getFilesObservable(parentResourceType: ResourceType, resourceParentId: number): Observable<UhatFileReference[]> {
  //   const resourceGroup = this.getOrCreateResourceTypeGroup(parentResourceType, resourceParentId);
  //   return resourceGroup.asObservable();
  // }

  public loadFilesForResourceType(
    parentResourceType: ResourceType,
    resourceParentId: number
  ): Observable<UhatFileReference[]> {
    return this.fileService.getFilesByParentId(parentResourceType, resourceParentId);
  }

  getFilesCount(resourceType: ResourceType, parentId: number) {
    if (!this.files.has(resourceType) || !this.files.get(resourceType).has(parentId)) {
      return 0;
    }

    return this.files.get(resourceType).get(parentId).value.length;
  }

  // private getOrCreateResourceTypeGroup(resourceType: ResourceType, parentId: number): BehaviorSubject<UhatFileReference[]> {
  //   if (!this.files.has(resourceType)) { this.files.set(resourceType, new Map<number, BehaviorSubject<UhatFileReference[]>>()); }
  //   const resourceGroup = this.files.get(resourceType);
  //   if (!resourceGroup.has(parentId)) { resourceGroup.set(parentId, new BehaviorSubject<UhatFileReference[]>([])); }
  //
  //   return resourceGroup.get(parentId);
  // }

  /** Loads the files associated with a note */
  // public getFilesForNoteId(taskId: number): Observable<UhatFileReference[]> {
  //   return this.getFilesObservable(ResourceType.Note, taskId);
  // }
}
