import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleErrorService, ProjectEventService, ProjectTaskService } from 'src/app/services';
import { ProjectTenant, ServiceResponse, Task } from 'src/app/types';
import { PEBSection, ProjectTenantConstruction, TenantType } from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectTenantService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private eventService: ProjectEventService
  ) {}

  public static PROJECT_MANAGER_APPROVAL_COMMENT =
    '<i>Project Manager approval is complete. Awaiting Construction Manager approval.</i>';
  public static WORKSPACE_MANAGER_APPROVAL_COMMENT =
    '<i>Construction Manager approval is complete. Awaiting Architect approval.</i>';

  private host: string = environment.serviceHost;

  private projectTenantUrl = `${this.host}/api/v1/project-tenants`;
  private tenantTypeUrl = `${this.host}/api/v1/tenant-types`;

  private projectTenantFields =
    'project_id,department_id,type_id,representative_id,tenant_name,peb_status,peb_management_fee_percentage,peb_approval_task_id,saved_peb_approval_task_id,tenant_approval_task_id,saved_tenant_approval_task_id,peb_revision,selected_peb_id,sublease_contract_file_id,sublease_contract_file_name,amortization_file_id,amortization_file_name,exhibit_b_file_id,exhibit_b_file_name,tenant_peb_signing_task_id,reimbursement_file_id,reimbursement_file_name,punchlist_approval_task_id,punchlist_final_approval_task_id,punchlist_approval_task{status_id,assigned_user},punchlist_final_approval_task{status_id,assigned_user},bubble_drawing_ids,punchlist_cover_letter_text,saved_punchlist_approval_task_id,saved_punchlist_final_approval_task_id,punchlist_revision,punchlist_final_revision,peb_cover_letter_text,cb_cover_letter_text,cb_approval_task_id,cb_tenant_approval_task_id,cb_is_finalized,cb_revision,schedule_5_file_id,schedule_5_file_name,budget_approval_task_id,budget_saved_approval_task_id,budget_revision,budget_tenant_approval_task_id,budget_tenant_saved_approval_task_id';

  getTenantById(id, fields: string[]): Observable<ProjectTenant> {
    return this.http.get(`${this.projectTenantUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const projectTenants: ProjectTenant[] = result.data['project tenant'];
        return projectTenants[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets all tenants associated with the given project
  getTenantsForProject(projectId: number, fields?: string[]): Observable<ProjectTenantConstruction[]> {
    return this.http
      .get(
        `${this.projectTenantUrl}?fields=${
          fields ? fields.join(',') : this.projectTenantFields
        }&filter=project_id=${projectId}&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectTenants: ProjectTenantConstruction[] = result.data.project_tenants.map((tenant) => {
            // converts the bubble drawing ids into an array of numbers rather than strings
            if (tenant.bubble_drawing_ids != null && typeof tenant.bubble_drawing_ids === 'string') {
              const ids: string = tenant.bubble_drawing_ids;
              if (ids !== '[]') {
                tenant.bubble_drawing_ids = ids
                  .substr(1, ids.length - 2)
                  .split(',')
                  .map((field) => +field);
              } else {
                tenant.bubble_drawing_ids = [];
              }
            }

            // title 61 projects wont have project products so, skip them
            if (tenant.project_products) {
              tenant.project_products = tenant.project_products.sort((product1, product2) => {
                if (product1.rank > product2.rank) {
                  return 1;
                }
                if (product1.rank < product2.rank) {
                  return -1;
                }
                return 0;
              });
            }

            return tenant;
          });
          return projectTenants;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // gets all projects associated with the given tenant
  getProjectsForTenant(departmentId: number): Observable<ProjectTenantConstruction[]> {
    return this.http
      .get(
        `${this.projectTenantUrl}?fields=${this.projectTenantFields}&filter=department_id=${departmentId}&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectTenants: ProjectTenantConstruction[] = result.data.project_tenants;
          return projectTenants;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTenantPebTasks(tenant: ProjectTenantConstruction): Observable<Task[]> {
    const subject = new ReplaySubject<Task[]>();
    const filter = [tenant.peb_approval_task_id, tenant.tenant_approval_task_id, tenant.tenant_peb_signing_task_id];
    this.http
      .get(
        `${ProjectTaskService.taskUrl}?filter=id=${filter.join(
          '^'
        )}&fields=id,title,follower_ids,status_id,status_name,files`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const tasks: Task[] = result.data.tasks;
          return tasks;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      )
      .subscribe((tasks: Task[]) => {
        subject.next(tasks);
      });
    return subject.asObservable();
  }

  // adds a new entry for the given project and tenant
  linkTenantToProject(
    projectId: number,
    departmentId: number,
    representativeId?: number,
    typeId?: number,
    fiscalYear?: number
  ): Observable<ProjectTenantConstruction> {
    const body: any = { project_id: projectId, department_id: departmentId };
    if (representativeId) {
      body.representative_id = representativeId;
    }
    if (typeId) {
      body.type_id = typeId;
    }
    if (fiscalYear) {
      body.fiscal_year = fiscalYear;
    }
    return this.http.post(`${this.projectTenantUrl}`, body).pipe(
      map((result: ServiceResponse) => {
        return result.data['project tenant'];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // deletes the entries associated with the given project and tenant
  // be sure to use the id from the project_tenants table here
  unlinkTenantFromProject(projectTenantId: number): Observable<void> {
    return this.http.delete(`${this.projectTenantUrl}/${projectTenantId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  unlinkTenantByProjectId(projectId: number, departmentId: number): Observable<void> {
    const body = { project_id: projectId, department_id: departmentId };
    return this.http.put(`${this.projectTenantUrl}/unlink`, body).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectTenant(projectTenantId: number, projectTenantData: ProjectTenantConstruction) {
    return this.http.put(`${this.projectTenantUrl}/${projectTenantId}`, projectTenantData).pipe(
      map((result: ServiceResponse) => {
        const projectTenant = result.data['project tenant'];
        return projectTenant;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getPEBSectionByTenantId(id, fields?: string[]): Observable<ProjectTenant> {
    fields = fields || [
      'peb_approval_task_id',
      'saved_peb_approval_task_id',
      'saved_tenant_approval_task_id',
      'tenant_approval_task_id',
      'peb_revision',
    ];
    return this.http.get(`${this.projectTenantUrl}/${id}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const projectTenants: ProjectTenant[] = result.data['project tenant'];
        return projectTenants[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createPEBSection(tenantToCreate: PEBSection): Observable<ProjectTenantConstruction> {
    return this.http.post(`${this.projectTenantUrl}`, tenantToCreate).pipe(
      map((result: ServiceResponse) => {
        return result.data['project tenant'];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updatePEBSection(projectTenantId: number, projectTenantData: PEBSection) {
    return this.http.put(`${this.projectTenantUrl}/${projectTenantId}`, projectTenantData).pipe(
      map((result: ServiceResponse) => {
        const projectTenant = result.data.projectTenant;
        return projectTenant;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectTenantFundingSources(projectTenantId: number, fundingSourceTypeIds: number[]) {
    const projectTenantData = { funding_source_ids: fundingSourceTypeIds };
    return this.http.put(`${this.projectTenantUrl}/${projectTenantId}/funding-sources`, projectTenantData).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * Rejects a PEB, resetting project data on the backend to allow restarting the PEB process
   * @param projectId ProjectId of the PEB to reject
   */
  public rejectPeb(approvalTaskId: number, comment?: string) {
    return this.http.put(`${this.host}/api/v1/project-tenants/${approvalTaskId}/rejectPEB`, { comment }).pipe(
      map((result: ServiceResponse) => {
        // console.log(result);
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public approveAndSelectPeb(approvalTaskId: number, pebId: number, comment?: string) {
    return this.http
      .put(`${this.host}/api/v1/project-tenants/${approvalTaskId}/approvePEB`, {
        peb_id: pebId,
        comment,
      })
      .pipe(
        map((result: ServiceResponse) => {
          // console.log(result);
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }
  // gets all projects associated with the given tenant
  getTenantTypes(): Observable<TenantType[]> {
    return this.http.get(`${this.tenantTypeUrl}?fields=name,disabled,sequence&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const tenantTypes: TenantType[] = result.data.tenant_types;
        return tenantTypes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
