import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, FileService, RequestService, WorkOrderService } from 'src/app/services';
import { Request, WorkOrder } from 'src/app/types';

@Component({
  selector: 'app-request-receipt-dialog',
  templateUrl: './request-receipt-dialog.component.html',
  styleUrls: ['./request-receipt-dialog.component.scss'],
})
export class RequestReceiptDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RequestReceiptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authService: AuthService,
    private fileService: FileService,
    private requestService: RequestService,
    private workOrderService: WorkOrderService
  ) {}
  workOrderFields = [
    'code',
    'topic_name',
    'topic_category_name',
    'topic_group_name',
    'building_name',
    'floor_code',
    'suite_name',
    'department_name',
    'title',
    'summary',
  ];
  requestFields = [
    'code',
    'topic_name',
    'topic_category_name',
    'topic_group_name',
    'building_name',
    'floor_code',
    'suite_name',
    'department_name',
    'short_description',
    'summary',
  ];

  currentUserId: number;
  files;
  request: Request;
  workOrder: WorkOrder;

  ngOnInit(): void {
    this.currentUserId = this.authService.getLoggedInUser().id;
    this.refresh();
  }

  async refresh() {
    if (this.data?.request?.id) {
      this.request = await this.requestService.getRequestById(this.data?.request?.id, this.requestFields).toPromise();
    } else if (this.data?.workOrder?.id) {
      this.workOrder = await this.workOrderService
        .getWorkOrderById(this.data?.workOrder?.id, this.workOrderFields)
        .toPromise();
    }

    this.files = this.data?.files || [];
  }

  reloadPage(): void {
    window.location.reload();
  }

  // get files() {
  //   return this.data?.files;
  // }

  get contacts() {
    return this.data?.contacts;
  }

  close(reroute = false, newRequest = false): void {
    this.dialogRef.close({
      reroute,
      newRequest,
    });
  }
}
