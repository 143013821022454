import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserType } from 'src/app/enums';
import { UserSearchService } from 'src/app/services';
import { User } from 'src/app/types';
import { SearchUserInputComponent } from '..';

@Component({
  selector: 'app-assign-user-dialog',
  templateUrl: './assign-user-dialog.component.html',
  styleUrls: ['./assign-user-dialog.component.scss'],
})
export class AssignUserDialogComponent implements OnInit {
  @ViewChild('assigned_user', { static: true })
  private _searchUserInputComponent: SearchUserInputComponent;

  public assignToMeText = 'Assign To Me';
  public dialogLabel = '';
  public dialogPlaceholder = 'Assignee';
  public dialogTitle = '';
  // re-using assign user so we ne this
  public isARequestorUser = false;
  public selectedUserType = UserType.Staff;
  public initialValueId = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<AssignUserDialogComponent>,
    private userSearchService: UserSearchService
  ) {}

  ngOnInit(): void {
    this._initDialog();
  }

  get filteredUsers(): User[] {
    return this.userSearchService.getFilteredUsers();
  }

  private _initDialog() {
    if (this.data) {
      this.dialogTitle = this.data?.displayTitle || '';
      this.dialogLabel = this.data?.displayLabel || '';
      this.initialValueId = this.data?.initialValueId || '';
      this.dialogPlaceholder = this.data?.displayPlaceHolder || this.dialogPlaceholder;
      this.isARequestorUser = this.data?.isARequestorUser ?? this.isARequestorUser;
      // default to tenant for requestor and set the set to me text
      if (this.isARequestorUser) {
        this.selectedUserType = UserType.Tenant;
        this.assignToMeText = 'Set To Me';
      }
      // gather current user
      const { id, first_name, last_name, user_type_id, title, email, department_name } = this.data;
      this._searchUserInputComponent?.searchEntry.setValue({
        id,
        first_name,
        last_name,
        user_type_id,
        title,
        email,
        department_name,
      });
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public searchBoxValueChange(value: string) {
    this.userSearchService.filterUsers(value);
  }

  public mapper(user: User) {
    if (user?.first_name && user?.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return user?.first_name || user?.last_name || '';
  }

  public userSelected(user: User) {
    this.dialogRef.close(user);
  }
}
