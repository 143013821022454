import { Pipe, PipeTransform } from '@angular/core';

import { AgendaItem } from 'src/app/types';

/**
 * This pipe is for filtering the tasks of the tasks page.
 * TODO: Performance can be incresed by allowing it to be "pure". Currently, the pipe is refreshed on every detection change. Happens too much.
 */
@Pipe({
  name: 'agendaFilter',
  pure: false,
})
export class AgendaFilterPipe implements PipeTransform {
  transform(agendaItems: AgendaItem[]): any {
    agendaItems.sort((a, b) => {
      return a.display_order_hash >= b.display_order_hash ? 1 : -1;
    });

    return agendaItems;
  }
}
