<app-base-dialog
  [title]="(contract?.id ? 'View' : 'New') + ' Construction Contract'"
  (closeDialog)="close()"
></app-base-dialog>

<mat-dialog-content
  class="edit-contract-dialog border-top border-bottom bg-shade-dkblue border-gray position-relative p-0"
>
  <div class="row m-0 d-flex full-width position-relative" style="height: 100%">
    <div
      *ngIf="loading"
      class="col-xl-8 col-lg-10 col-md-12 text-center font-weight-semibold p-5 my-5 mx-auto border border-gray bg-white"
    >
      <mat-spinner diameter="100" color="accent" class="mx-auto mb-5"> </mat-spinner>
      <h5 class="mx-auto accent mb-0">Loading Contract...</h5>
    </div>
    <div *ngIf="!loading" class="col-lg-2 col-md-0 align-self-stretch">
      <div class="py-4 d-flex flex-column">
        <h5 class="dkblue font-weight-bold my-4 ml-2">Steps To Complete</h5>
        <div
          *ngIf="awaitingReview && [1, 2, 3].indexOf(typeId) > -1"
          (click)="scrollToElement('contractSumElement')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract.contract_sum_confirmed ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Contract Sum</h6>
        </div>
        <div
          *ngIf="awaitingReview && [1, 2, 3].indexOf(typeId) > -1"
          (click)="scrollToElement('summary')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract.summary_completed ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Summary</h6>
        </div>
        <div
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          (click)="scrollToElement('allowances')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract.allowances_completed ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Allowances</h6>
        </div>
        <div
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          (click)="scrollToElement('unitPrices')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract.unit_prices_completed ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Unit Prices</h6>
        </div>
        <div
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          (click)="scrollToElement('insurance')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract.insurance_policy_completed ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Insurance</h6>
        </div>
        <div
          *ngIf="statusId === 2"
          (click)="scrollToElement('signatures')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            contract?.vendor_signature_text ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Supplier Signature</h6>
        </div>
        <div
          *ngIf="statusId === 2"
          (click)="scrollToElement('signatures')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green mr-2">{{
            insuranceFilesUploaded ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">Upload Documents</h6>
        </div>
        <div
          *ngIf="statusId === 3"
          (click)="scrollToElement('signatures')"
          class="d-flex align-items-center pointer mb-3 p-2 br-5 ease stepper-step"
        >
          <i class="material-icons green pointer mr-2">{{
            contract?.trust_signature_text ? 'check_circle' : 'radio_button_unchecked'
          }}</i>
          <h6 class="dkblue mb-0 mr-2">UHAT Signature</h6>
        </div>
      </div>
    </div>
    <div id="main" #main class="col-lg-10 col-md-12 d-flex flex-column contract-container align-self-stretch">
      <div class="row d-flex" *ngIf="!loading && contract">
        <div class="col-xl-10 col-lg-12">
          <div class="full-width my-3 border border-gray bg-white p-5 d-flex flex-column">
            <section id="contract-cover-page">
              <div class="row mb-5 pb-5">
                <div class="col-4">
                  <img id="contract-header-logo" src="../../assets/img/uhat_logo_forms.png" alt="" />
                </div>
                <div class="col"></div>
                <div class="col-4">
                  <label class="main-label text-uppercase mb-2"> Project Manager </label>
                  <h5 class="dkblue font-weight-semibold mb-0">
                    {{ projectManager?.first_name }}
                    {{ projectManager?.last_name }}
                  </h5>
                  <p class="dkblue font-weight-normal mb-0">{{ projectManager?.company?.name }}</p>
                </div>
              </div>

              <div class="row m-0 py-2 header-row mt-5">
                <div class="col px-0">
                  <!-- TODO: Remove typeId -->
                  <h1 class="dkblue mb-0 font-weight-bold">Construction Contract</h1>
                </div>
              </div>
              <div class="row m-0 py-2 mt-4">
                <div class="col-3 px-0">
                  <label class="main-label text-uppercase mb-2"> Prepared For </label>
                  <h5 class="dkblue font-weight-semibold mb-0">{{ bidCompany?.name }}</h5>
                  <p class="dkblue mb-4">
                    {{ bidContact?.first_name }} {{ bidContact?.last_name }}<br />
                    {{ bidContact?.title }}<br />
                  </p>
                  <label #contractSumElement id="contractSumElement" class="main-label text-uppercase mb-2">
                    Bid Package / Trade
                  </label>
                  <h5 class="dkblue mb-4">
                    {{ contract?.bid?.bid_package?.number }} - {{ contract?.bid?.trade?.name }}
                  </h5>
                  <label #contractSumElement id="contractSumElement" class="main-label text-uppercase mb-2">
                    Contract Sum
                  </label>
                  <h5 class="dkblue mb-4">{{ contractSum | USDollar }}</h5>
                  <button
                    mat-button
                    [class.mat-flat-button]="!contract.contract_sum_confirmed"
                    [class.mat-stroked-button]="contract.contract_sum_confirmed"
                    color="success"
                    class="lh-36 pr-2 mb-3"
                    [ngClass]="{ white: !contract.contract_sum_confirmed }"
                    style="border-radius: 18px"
                    (click)="toggleContractProperty('contract_sum_confirmed')"
                    *ngIf="awaitingReview"
                  >
                    <!-- <span *ngIf="!contract.contract_sum_confirmed" class="pulse-dot-success"></span> -->
                    <span class="mr-2"> {{ contract.contract_sum_confirmed ? 'Confirmed' : 'Confirm Sum' }} </span>
                    <mat-icon>
                      {{ contract.contract_sum_confirmed ? 'check_circle' : 'radio_button_unchecked' }}
                    </mat-icon>
                  </button>
                </div>
                <div class="col">
                  <p *ngIf="typeId === 1" class="dkblue mb-4">
                    This document, along with the attached terms and conditions, shall serve as a written contract for
                    Trust acquisitions of minor construction work not to exceed $25,000.00, or for other minor
                    construction work as determined by the Trust.
                  </p>
                  <p class="dkblue mb-4">
                    This Construction Contract is made this {{ contractDate | date: 'd' | ordinal }} day of
                    {{ contractDate | date: 'MMMM' }} {{ contractDate | date: 'YYYY' }} by and between the University
                    Hospitals Trust, a Public Trust of the State of Oklahoma ("Trust"), and
                    {{ bidCompany?.name }}
                    ("the Supplier") (both of them a "Party" and collectively "Parties"), for the following Project:
                  </p>
                  <label class="main-label text-uppercase mt-0"> Project </label>
                  <p class="dkblue font-weight-semibold mb-4">{{ project?.code }} - {{ project?.title }}</p>
                  <label class="main-label text-uppercase mt-0"> Project Summary </label>
                  <p class="dkblue font-weight-semibold mb-4" [innerHTML]="project?.scope_of_work"></p>
                  <label class="main-label text-uppercase mt-0"> Location </label>
                  <p class="dkblue font-weight-semibold mb-5">
                    {{ project?.building?.code }}-{{ project?.floor?.code }}, {{ project?.department?.name }} <br />{{
                      project?.building?.name
                    }}
                    <!-- TODO: uncomment once we get building addresses -->
                    <!-- <br />{{ project?.building?.address }} -->
                  </p>
                  <div *ngIf="typeId === 1" class="d-flex flex-column mt-5">
                    <!-- <div class="col-1 px-0">
                      <div
                        class="full-width d-flex align-items-center lh-30 py-0 px-1 text-wrap pointer"
                        [ngClass]="{
                          'bg-green': !contract.summary_completed,
                          'bg-white border border-gray': contract.summary_completed
                        }"
                        style="border-radius: 15px"
                        (click)="toggleContractProperty('summary_completed')"
                        *ngIf="awaitingReview"
                      >
                        <mat-icon
                          matRipple
                          class="pointer br-50"
                          [ngClass]="{ green: contract.summary_completed, white: !contract.summary_completed }"
                        >
                          {{ contract.summary_completed ? 'check_circle' : 'radio_button_unchecked' }}
                        </mat-icon>
                      </div>
                    </div> -->
                    <label class="main-label text-uppercase mt-0 mb-2"> Scope of Work </label>
                    <mat-form-field class="full-width mb-4" appearance="outline" *ngIf="awaitingReview">
                      <textarea
                        matInput
                        rows="4"
                        placeholder="Summary Of Work"
                        [(ngModel)]="contract.summary"
                        (ngModelChange)="dataChanged()"
                      ></textarea>
                    </mat-form-field>
                    <span *ngIf="!awaitingReview" class="mb-4">
                      {{ contract.summary }}
                    </span>
                    <label class="main-label text-uppercase mt-0 mb-2"> Insurance Requirements </label>
                    <mat-checkbox
                      class="mb-2"
                      *ngFor="let requirement of insuranceRequirements"
                      (change)="updateInsuranceRequirements($event.checked, requirement.id)"
                      [checked]="insuranceRequirementIds?.includes(requirement.id)"
                      [disabled]="!awaitingReview || !!requirement.is_required"
                    >
                      <span class="d-flex align-items-center">
                        <span class="mr-4"> {{ requirement.name }} </span>
                        <span> {{ requirement.value }} </span>
                      </span>
                    </mat-checkbox>
                    <!-- Updating per COO's instruction 2/16/23 -->
                    <!-- <p class="dkgray font-weight-normal mb-4 lh-14">
                      <small class="lh-14">
                        * Company may substitute a sworn affidavit certifying an exemption to the requirement of
                        worker’s compensation insurance coverage.
                      </small>
                    </p> -->
                    <p class="dkgray font-weight-normal mb-4 lh-14">
                      <small class="lh-14"> * Worker’s Compensation insurance coverage ALWAYS required. </small>
                    </p>
                    <p class="dkblue font-weight-normal mb-5">
                      Owner’s representative to verify each requirement above prior to signing this Agreement.
                    </p>
                    <p class="dkblue font-weight-bold mb-2">Payment to Supplier</p>
                    <p class="dkblue font-weight-normal mb-4">
                      Supplier agrees to provide all labor and material, and pay for all applicable permits, fees and
                      taxes required to complete the work in accordance with applicable laws and regulations, for the
                      lump sum price of: <b>{{ contractSum | USDollar }}</b>
                    </p>
                    <p class="dkblue font-weight-normal mb-4">
                      Owner agrees to pay supplier in full upon satisfactory completion of the work and will submit
                      invoice to the address listed above.
                    </p>
                    <p class="dkblue mb-4">
                      <b>Oklahoma Taxpayer and Citizen Protection Act of 2007.</b> The Supplier certifies that it and
                      all proposed subcontractors and suppliers, whether known or unknown at the time this contract is
                      executed or awarded, will comply with the provisions of the Oklahoma Taxpayer and Citizen
                      Protection Act of 2007 and participate in the Status Verification System. The Status Verification
                      System is defined at 25 §1312.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="contract-articles" *ngIf="[2, 3].indexOf(typeId) > -1">
              <div class="row m-0">
                <div class="col-12 px-0">
                  <p class="dkblue mb-0">Trust and the Supplier mutually agree as follows.</p>
                  <p class="dkblue font-weight-semibold">Table of Articles</p>
                </div>
              </div>

              <div id="tableOfContents" class="d-flex flex-column mb-5">
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Definitions</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? 'Contract Documents' : typeId === 2 ? 'Scope of Work' : 'Unknown' }}
                    </p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Contract Sum</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Payments</p>
                  </div>
                </div>
                <div class="row mx-0" *ngIf="typeId === 3">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">5</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Bonds</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '6' : typeId === 2 ? '5' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Insurance</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '7' : typeId === 2 ? '6' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">General Provisions</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '8' : typeId === 2 ? '7' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">General Responsibilities of the Trust</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '9' : typeId === 2 ? '8' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">General Responsibilities of the Supplier</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '10' : typeId === 2 ? '9' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Chief Facilities Management Officer</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '11' : typeId === 2 ? '10' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Change Orders</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '12' : typeId === 2 ? '11' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Time</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '13' : typeId === 2 ? '12' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Protection of Persons and Property</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '14' : typeId === 2 ? '13' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Correction of Work</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '15' : typeId === 2 ? '14' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Miscellaneous Provisions</p>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">
                      {{ typeId === 3 ? '16' : typeId === 2 ? '15' : 'Unknown' }}
                    </p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Termination of the Contract</p>
                  </div>
                </div>
                <div class="row mx-0" *ngIf="typeId === 3">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">17</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">Other Terms and Conditions</p>
                  </div>
                </div>
              </div>

              <div class="row mx-0 mb-4">
                <div class="col-12 px-0">
                  <section>
                    <div class="row mx-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Article 1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Definitions</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">1.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Architectural Design & Construction Team" (or "ADC") is a team assembled within the Trust
                          that may include the CFMO, an Architect, Project Manager, Superintendent, and/or other
                          applicable member.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">1.2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Authority Having Jurisdiction" (or "AHJ") means the University of Oklahoma employee who works
                          on behalf of the Oklahoma State Fire Marshall, performing all building inspections and code
                          review based on Oklahoma State Fire Marshall guidelines.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">1.3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Bidding Documents" include the Solicitation for Bids, the Instructions for Bidders, the bid
                          forms, other sample bidding and contract forms, and the proposed contract documents including
                          any Addenda issued prior to the receipt of Bids.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          1.{{ typeId === 3 ? '4' : typeId === 2 ? '3' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Chief Facilities Management Officer" (or "CFMO") means the Chief Facilities Management
                          Officer or comparable position who reports to the Chief Executive Officer and manages the
                          day-to-day operations and maintenance of UHAT's facilities.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          1.{{ typeId === 3 ? '5' : typeId === 2 ? '4' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Code" means the minimum and applicable building code or codes.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          1.{{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p *ngIf="typeId === 3" class="dkblue font-weight-normal lh-20 m-0">
                          "Contract" means the solicitation, subcontractor's bid, this negotiation document verifying an
                          award and encumbering funds, and any written Change Orders issued pursuant to Article 11
                          herein.
                        </p>
                        <p *ngIf="typeId === 2" class="dkblue font-weight-normal lh-20 m-0">
                          “Contract” means this negotiation document and any written Change Orders issued pursuant to
                          Article 11 herein.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">1.7</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Contract Documents" means the solicitation, the Supplier's bid, this negotiation document
                          verifying an award and encumbering funds, and any written Change Orders issued pursuant to
                          Article 11 herein.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">1.8</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          "Project" means the scope of services for which competitive proposals are requested from
                          interested construction services suppliers and more particularly refers to the specific bid
                          package or packages upon which the services suppliers issued a bid or bids.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          1.{{ typeId === 3 ? '9' : typeId === 2 ? '6' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p *ngIf="typeId === 2" class="dkblue font-weight-normal lh-20 m-0">
                          A “Unit Price” means the price per unit of measurement for materials or services.
                        </p>
                        <p *ngIf="typeId === 3" class="dkblue m-0">
                          A "Unit Price" means the amount stated in the Bid as a price per unit of measurement for
                          materials or services as described in the Bid Documents or in the proposed contract documents.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          1.{{ typeId === 3 ? '10' : typeId === 2 ? '7' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          “Work” means the construction and services required by the Contract and includes all labor,
                          materials, equipment, and services provided, or to be provided, by the Supplier/Subcontractor
                          to fulfill obligations.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Article 2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0" *ngIf="typeId === 3">Contract Documents</p>
                        <p class="dkblue font-weight-bold lh-20 m-0" *ngIf="typeId === 2">Scope of Work</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">2.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 3">
                          The Subcontractor shall complete the Work described in the Contract Documents for the Project.
                        </p>
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 2">
                          <!-- DON'T HAVE ANYTHING HERE YET STILL -->
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Article 3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Contract Sum</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4 d-flex align-items-top">
                      <div class="col-1 px-0">
                        <div class="text-wrap">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">3.1</p>
                        </div>
                      </div>
                      <div class="col-11 pr-0 pt-1">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          The Contract Sum shall include all items and services necessary for the proper execution and
                          completion of the Work. Subject to additions and deductions in accordance with Article
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }} herein, the Contract Sum is
                          <b>{{ contractSum | USDollar }}</b
                          >.
                        </p>
                      </div>
                    </div>

                    <div #summary id="summary" class="row mx-0 mb-4 d-flex align-items-top">
                      <div class="col-1 px-0">
                        <div
                          class="full-width d-flex align-items-center lh-30 py-0 px-1 text-wrap pointer"
                          [ngClass]="{
                            'bg-green': !contract.summary_completed,
                            'bg-white border border-gray': contract.summary_completed
                          }"
                          style="border-radius: 15px"
                          (click)="toggleContractProperty('summary_completed')"
                          *ngIf="awaitingReview"
                        >
                          <p class="dkblue font-weight-semibold m-0 mx-auto">3.2</p>
                          <mat-icon
                            matRipple
                            class="pointer br-50"
                            [ngClass]="{ green: contract.summary_completed, white: !contract.summary_completed }"
                          >
                            {{ contract.summary_completed ? 'check_circle' : 'radio_button_unchecked' }}
                          </mat-icon>
                        </div>
                        <div class="text-wrap" *ngIf="!awaitingReview">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">3.2</p>
                        </div>
                      </div>
                      <div class="col-11 pr-0 pt-1 textarea">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          For purposes of payment, the Contract Sum includes the following detailed breakdown of costs
                          expressed in values related to portions of the Work:
                        </p>
                        <mat-form-field class="full-width" appearance="outline" *ngIf="awaitingReview">
                          <textarea
                            matInput
                            rows="4"
                            placeholder="Summary Of Work"
                            [(ngModel)]="contract.summary"
                            (ngModelChange)="dataChanged()"
                          ></textarea>
                        </mat-form-field>
                        <!-- TODO: style -->
                        <p class="dkblue mb-3" *ngIf="!awaitingReview">{{ contract.summary }}</p>
                        <p class="gray font-italic font-weight-normal lh-20 mb-3">
                          Reference Construction Documents for more detailed info.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4 d-flex align-items-top" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <div class="text-wrap">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">3.3</p>
                        </div>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          The Contract Sum is based upon the following alternate bids, if any, which are described in
                          the Contract Documents:
                        </p>
                        <div
                          class="row d-flex align-items-center"
                          *ngFor="let a of contract.altBids; index as altBidIndex"
                        >
                          <div class="col">
                            <p class="dkblue font-weight-semibold lh-20 mb-3">
                              {{ altBidIndex + 1 }}. {{ a.description }}
                            </p>
                          </div>
                          <div class="col-3">
                            <p class="dkblue font-weight-semibold lh-20 mb-3">
                              <b>{{ a.amount | USDollar }}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div #allowances id="allowances" class="row mx-0 mb-4 d-flex align-items-top">
                      <div class="col-1 px-0">
                        <div
                          class="full-width d-flex align-items-center lh-30 bg-green py-0 px-1 text-wrap pointer"
                          [ngClass]="{
                            'bg-green': !contract.allowances_completed,
                            'bg-white border border-gray': contract.allowances_completed
                          }"
                          style="border-radius: 15px"
                          (click)="toggleContractProperty('allowances_completed')"
                          *ngIf="awaitingReview"
                        >
                          <p class="dkblue font-weight-semibold m-0 mx-auto">
                            3.{{ typeId === 3 ? '4' : typeId === 2 ? '3' : '#' }}
                          </p>
                          <mat-icon
                            matRipple
                            class="white pointer br-50"
                            [ngClass]="{ green: contract.allowances_completed, white: !contract.allowances_completed }"
                            >{{ contract.allowances_completed ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon
                          >
                        </div>
                        <div class="text-wrap" *ngIf="!awaitingReview">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">
                            3.{{ typeId === 3 ? '4' : typeId === 2 ? '3' : '#' }}
                          </p>
                        </div>
                      </div>
                      <div class="col-11 pr-0 pt-1">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          Allowances, if any, included in the Contract Sum are as follows:
                        </p>

                        <ng-container *ngFor="let a of contract.allowances; index as allowanceIndex; let last = last">
                          <div
                            *ngIf="awaitingReview && a?.action !== 'delete'"
                            class="row d-flex align-items-center mb-3"
                          >
                            <div class="col">
                              <mat-form-field class="full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Allowance Name"
                                  [(ngModel)]="a.name"
                                  (ngModelChange)="dataChanged()"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-3 d-flex">
                              <mat-form-field class="full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Amount"
                                  [(ngModel)]="a.amount"
                                  (ngModelChange)="dataChanged()"
                                />
                              </mat-form-field>
                              <button
                                mat-icon-button
                                color="accent"
                                class="ltgray"
                                (click)="deleteAllowance(allowanceIndex)"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                          </div>
                          <div
                            *ngIf="!awaitingReview"
                            class="d-flex full-width border-gray py-2 align-items-center"
                            [ngClass]="{ 'border-bottom': !last }"
                          >
                            <p class="mb-0 dkblue font-weight-semibold mr-auto">
                              {{ a.name }}
                            </p>
                            <p class="mb-0 dkblue font-weight-semibold ml-5">
                              {{ a.amount | USDollar }}
                            </p>
                          </div>
                        </ng-container>

                        <div class="row d-flex align-items-center" *ngIf="awaitingReview">
                          <div class="col">
                            <button mat-button color="success" class="dkgreen lh-24 px-2 mr-4" (click)="addAllowance()">
                              + Allowance
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div #unitPrices id="unitPrices" class="row mx-0 mb-4 d-flex align-items-top">
                      <div class="col-1 px-0">
                        <div
                          class="full-width d-flex align-items-center lh-30 bg-green py-0 px-1 text-wrap pointer"
                          [ngClass]="{
                            'bg-green': !contract.unit_prices_completed,
                            'bg-white border border-gray': contract.unit_prices_completed
                          }"
                          style="border-radius: 15px"
                          (click)="toggleContractProperty('unit_prices_completed')"
                          *ngIf="awaitingReview"
                        >
                          <p class="dkblue font-weight-semibold m-0 mx-auto">
                            3.{{ typeId === 3 ? '5' : typeId === 2 ? '4' : '#' }}
                          </p>
                          <mat-icon
                            matRipple
                            class="white pointer br-50"
                            [ngClass]="{
                              green: contract.unit_prices_completed,
                              white: !contract.unit_prices_completed
                            }"
                            >{{ contract.unit_prices_completed ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon
                          >
                        </div>
                        <div class="text-wrap" *ngIf="!awaitingReview">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">
                            3.{{ typeId === 3 ? '5' : typeId === 2 ? '4' : '#' }}
                          </p>
                        </div>
                      </div>
                      <div class="col-11 pr-0 pt-1">
                        <p class="dkblue font-weight-normal lh-20 mb-3">Unit Prices, if any, are as follows:</p>

                        <ng-container *ngFor="let u of contract.unit_prices; index as unitPriceIndex; let last = last">
                          <div
                            *ngIf="awaitingReview && u?.action !== 'delete'"
                            class="row d-flex align-items-center mb-3"
                          >
                            <div class="col">
                              <mat-form-field class="full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Unit Name"
                                  [(ngModel)]="u.name"
                                  (ngModelChange)="dataChanged()"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-3">
                              <mat-form-field class="full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Units/Limitations"
                                  type="number"
                                  [(ngModel)]="u.units"
                                  (ngModelChange)="dataChanged()"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                              <mat-form-field class="full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Price/Unit"
                                  type="number"
                                  [(ngModel)]="u.price_per_unit"
                                  (ngModelChange)="dataChanged()"
                                />
                              </mat-form-field>
                              <button
                                mat-icon-button
                                color="accent"
                                class="ltgray"
                                (click)="deleteUnitPrice(unitPriceIndex)"
                              >
                                <mat-icon>close</mat-icon>
                              </button>
                            </div>
                          </div>
                          <!-- TODO: style -->
                          <div *ngIf="!awaitingReview">
                            <div
                              class="row m-0 border-gray py-2 align-items-center"
                              [ngClass]="{ 'border-bottom': !last }"
                            >
                              <div class="col-6 pl-0">
                                <p class="mb-0 dkblue font-weight-semibold mr-auto">
                                  {{ u.name }}
                                </p>
                              </div>
                              <div class="col-3 text-right">
                                <p class="mb-0 dkblue font-weight-semibold">
                                  {{ u.units }}
                                </p>
                              </div>
                              <div class="col-3 text-right pr-0">
                                <p class="mb-0 dkblue font-weight-semibold">
                                  {{ u.price_per_unit | USDollar }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <div class="row d-flex align-items-center" *ngIf="awaitingReview">
                          <div class="col">
                            <button mat-button color="success" class="dkgreen lh-24 px-2 mr-4" (click)="addUnitPrice()">
                              + Unit Price
                            </button>
                          </div>
                          <div class="col-3 d-flex align-items-center"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Article 4</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Payments</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Contract Sum stated in the Agreement, including authorized adjustments, is the total
                          amount payable by the Trust to the Supplier for performance of the Work
                          <span *ngIf="typeId === 3">under the Contract Documents</span>.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Requests for Payment. The Supplier shall be required to complete the detailed breakdown of
                          costs as specified at Article 3.2 herein. This breakdown shall be used throughout the job and
                          cannot be changed without approval from the CFMO and/or his designee. When the Supplier seeks
                          a partial or final payment, the Supplier shall prepare a rough draft and shall meet with the
                          CFMO and/or his designee to agree on the percentages of work completed and to then finalize
                          the form (“Request for Payment”). The Supplier shall furnish to the CFMO and/or his designee
                          one (1) electronic copy of a Request for Payment with all supporting documentation.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Certificates for Payment. The CFMO and /or his designee will, within seven days after receipt
                          of the Supplier’s Request for Payment either (1) issue a Certificate for Payment in the full
                          amount of the Request for Payment; (2) issue a Certificate for Payment for such amount as the
                          CFMO and/or his designee determines is properly due, and notify the Supplier in writing of the
                          CFMO and/or his designee’s reasons for withholding payment in part; or (3) withhold
                          certification of the entire Request for Payment and notify the Supplier of the CFMO and/or his
                          designee’s reason for withholding certification in whole. If certification or notification is
                          not made within seven days, the Supplier may, upon seven additional days’ written notice to
                          the Trust and the CFMO and/or his designee, stop the Work until payment of the amount owing
                          has been received. The Contract Time and the Contract Sum shall be equitably adjusted due to
                          the delay.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.4</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Partial Payments</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.4.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Partial payments for Work completed and materials stored on site may be submitted once each
                          month. A detailed procedure to be followed is as follows:
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.4.2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier should meet with the CFMO and/or his designee in accordance with Article
                          {{ typeId === 2 ? '3.2' : '4.2' }} and submit the Request for Payment as provided therein.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.4.3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          An itemized listing of materials stored on site at the end of the period covered in the
                          Request for Payment shall be prepared on the Supplier’s letterhead. Copies of paid invoices
                          are to be attached. The date on the listing must agree with the date on the Request for
                          Payment. The materials must be stored at the site and cannot be removed after the payment has
                          been made. The detailed description of stored materials should show quantities and sizes of
                          materials. The usual terms of reference such as tons of steel and types of windows shall be
                          acceptable.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.4.4</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the Supplier deems it necessary, and the CFMO and/or his designee recommend, the Supplier
                          may store material off-site. To do so, there must be a written agreement between the Trust and
                          the Supplier which provides:
                          <br />
                          a. proof of applicable insurance;
                          <br />
                          b. a written guarantee of delivery to the job site;
                          <br />
                          c. photos; and
                          <br />
                          d. written title to all materials covered by the request of payment, which shall pass to the
                          Trust; and
                          <br />
                          e. for particularly large projects, the Trust may require an on-site inspection of facilities
                          by the CFMO and/or his designee to verify the authenticity of quantities of stored materials.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.5</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Final Payment</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.5.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Once the Supplier determines that all Work under the Contract Documents is complete, the
                          Supplier shall submit pursuant to Article 4.2 herein to the CFMO and/or his designee a Request
                          for Payment for 100 percent of the Contract Sum less any amounts previously paid. If the CFMO
                          and/or his designee issues a Certificate for Payment, payment shall be for 95 percent of the
                          Contract Sum; 5 percent of the Contract Sum shall be held back until all items on the punch
                          list are complete, at which time the remaining 5 percent shall be issued.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.5.2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Final payment pursuant to the issuance of a Certificate for Payment shall not become due until
                          the Supplier submits to the CFMO and/or his designee releases and waivers of liens and data
                          establishing payment of satisfaction of obligations, such as receipts, claims, security
                          interests, or encumbrances arising out of the Contract.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.5.3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Acceptance of final payment by the Supplier shall constitute a waiver of claims by the
                          Supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.6</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier warrants that title to all Work covered by a Request for Payment will pass to the
                          Trust no later than the time of payment. The Supplier further warrants that upon submission of
                          a Request for Payment, all Work for which payments have been previously issued and payments
                          received from the Trust shall, to the best of the Supplier’s information, knowledge, and
                          belief, be free and clear of liens, claims, security interests, or other encumbrances adverse
                          to the Trust’s interests.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.7</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall promptly pay each subcontractor and supplier, upon receipt of payment from
                          the Trust, an amount determined in accordance with the terms of the applicable subcontracts
                          and purchase orders made by the Supplier. The Trust shall have no responsibility for payments
                          to a subcontractor or a supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">4.8</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          A Certificate for Payment, a partial payment, or final payment shall not constitute acceptance
                          of Work not in accordance with the requirements of the Contract
                          {{ typeId === 2 ? '' : 'Documents' }}.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section *ngIf="typeId === 3">
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Article 5</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Bonds</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">5.1</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          A bond is required for all contracts with a value exceeding Fifty Thousand Dollars
                          ($50,000.00) that includes coverage for (1) Performance – to insure the completion of the Work
                          in accordance with the Contract in the time stipulated; (2) Defect – to provide for defects in
                          construction or materials for a period of one year from the date of acceptance of the
                          completed Work; and (3) Payment – to assure the State of Oklahoma is protected from the action
                          of suppliers, subcontractors, suppliers, and employees for unpaid debts of the foregoing.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">5.2</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          All bonds are for the full value of the Contract.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">5.3</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          All bonds must be on the forms prescribed and issued by the Trust.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="typeId === 2" class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">5.4</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The letters of credit must be issued by a financial institution insured by the Federal Deposit
                          Insurance Corporation or the Federal Savings and Loan Insurance Corporation.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="typeId === 2" class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">5.5</p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          All bonds must be on the forms prescribed and issued by the Trust.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Insurance</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall maintain the following types and limits of insurance until the expiration
                          of the period for correction of Work as set forth in Section {{ typeId === 2 ? '13' : '14' }},
                          subject to the terms and conditions set forth in this Section
                          {{ typeId === 2 ? '5.1' : '6.1' }}:
                        </p>
                      </div>
                    </div>

                    <div #insurance id="insurance" class="row mx-0 mb-4 d-flex align-items-top">
                      <div class="col-1 px-0">
                        <div
                          class="full-width d-flex align-items-center lh-30 bg-green py-0 px-1 text-wrap pointer"
                          [ngClass]="{
                            'bg-green': !contract.insurance_policy_completed,
                            'bg-white border border-gray': contract.insurance_policy_completed
                          }"
                          style="border-radius: 15px"
                          (click)="toggleContractProperty('insurance_policy_completed')"
                          *ngIf="awaitingReview"
                        >
                          <p class="dkblue font-weight-semibold m-0 mx-auto">
                            {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.1.1
                          </p>
                          <mat-icon
                            matRipple
                            class="white pointer br-50"
                            [ngClass]="{
                              green: contract.insurance_policy_completed,
                              white: !contract.insurance_policy_completed
                            }"
                            >{{
                              contract.insurance_policy_completed ? 'check_circle' : 'radio_button_unchecked'
                            }}</mat-icon
                          >
                        </div>
                        <div class="text-wrap" *ngIf="!awaitingReview">
                          <p class="dkblue font-weight-semibold m-0 mr-auto">
                            {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.1.1
                          </p>
                        </div>
                      </div>
                      <div class="col-11 pr-0 pt-1">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          Commercial General Liability insurance for the Project with policy limits of not less than
                          <b>{{ contract.insurance_policy ? contract.insurance_policy?.name : '_________________' }}</b>
                          each occurrence. See Appendix A for further requirements on insurance.
                        </p>
                        <div class="row d-flex align-items-center mb-3">
                          <div class="col">
                            <mat-form-field class="full-width" appearance="outline" *ngIf="awaitingReview">
                              <mat-select
                                [(ngModel)]="contract.insurance_policy_id"
                                (ngModelChange)="updateInsurance($event)"
                              >
                                <mat-option *ngFor="let o of insurancePolicyOptions" [value]="o.id">
                                  {{ o.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col"></div>
                        </div>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.1.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier may achieve the required limits and coverage for Commercial General Liability
                          and, where applicable, Automobile Liability through a combination of primary and excess or
                          umbrella liability insurance, provided that such primary and excess or umbrella insurance
                          policies result in the same or greater coverage than the primary policy. The excess policy
                          shall not require exhaustion of the underlying limits only through the actual payment by the
                          underlying insurers.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.1.3
                        </p>
                      </div>
                      <div class="col-11 pr-0" *ngIf="typeId === 2 || typeId === 3">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall carry on his work in accordance with the Worker’s Compensation Act (85 O.S.
                          § 1, et seq.) of the State of Oklahoma and shall not reject the provisions thereof during the
                          life of the Contract
                          {{
                            typeId === 2
                              ? 'or, in the alternative, shall provide a sworn affidavit certifying an exemption'
                              : ''
                          }}.
                        </p>
                        <!-- removed 2/16/23 per COO's instruction, ALWAYS require workers comp-->
                        <!-- <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 2">
                          The Contractor shall carry on his work in accordance with the Worker's Compensation Act (85
                          O.S. § 1, et seq.) of the State of Oklahoma and shall not reject the provisions thereof during
                          the life of the Contract or, in the alternative, shall provide a sworn affidavit certifying an
                          exemption to the requirement of workers' compensation insurance coverage.
                        </p> -->
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Trust shall be responsible for purchasing and maintaining Trust’s usual liability and property
                          insurance.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall obtain an endorsement to its Commercial General Liability insurance policy
                          to provide coverage for the Supplier’s obligations under Article 9.13 (Indemnification).
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Prior to commencement of the Work, the Supplier shall provide certificates of insurance
                          showing the respective coverages.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.5
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Unless specifically precluded by the Trust’s property insurance policy, the Trust and the
                          Supplier waive all rights against each other and any of their suppliers, subcontractors,
                          suppliers, agents, and employees, each of the other for damages caused by fire or other causes
                          of loss to the extent those losses are covered by property insurance or other insurance
                          applicable to the Project, except insofar as such fire or other causes of loss are a result of
                          the Supplier’s own negligence and except such rights as they have to the proceeds of such
                          insurance.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.6
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Insurance Additionally Required for External Projects
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.6.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Automobile Liability covering vehicles owned, and non-owned vehicles used by the Supplier,
                          with policy limits of not less than $100,000/300,000 per accident, for bodily injury, death of
                          any person, and property damage arising out of the ownership, maintenance, and use of those
                          motor vehicles along with any other statutorily-required automobile coverage.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '6' : typeId === 2 ? '5' : '#' }}.6.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall provide builder’s risk insurance of not less than $50,000/100,000 for the
                          life of the Contract.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">General Provisions</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Contract represents the entire and integrated agreement between the Parties and supersedes
                          prior negotiations, representations, or agreements, either written or oral. The Contract may
                          be amended or modified only by a written modification in accordance with Article
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }} herein.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p *ngIf="typeId === 2" class="dkblue font-weight-normal lh-20 m-0">
                          The Work means the required construction and services required to complete the Scope of Work
                          and includes all labor, materials, equipment, and services provided, or to be provided, by the
                          Supplier to fulfill its obligations.
                        </p>
                        <p *ngIf="typeId === 3" class="dkblue font-weight-normal lh-20 m-0">
                          The Work means the construction and services required by the Contract Documents, and includes
                          all other labor, materials, equipment, and services provided, or to be provided, by the
                          Supplier to fulfill its obligations.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 3">
                          The intent of the Contract Documents is to include all items necessary for the proper
                          execution and completion of the Work by the Supplier. The Contract Documents are
                          complementary, and what is required by one shall be binding as if required by all.
                        </p>
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 2">
                          The intent of the Contract is to include all items necessary for the proper execution and
                          completion of the Work by the Supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 mb-3">
                          Electronic Notice. Written notice under this Agreement may be given by one Party to the other
                          by email as set forth below:
                        </p>
                        <div class="row d-flex align-items-top">
                          <div class="col d-flex align-items-top">
                            <p class="dkblue font-weight-semibold lh-20 mb-3 mr-auto">
                              TRUST: {{ projectManager?.first_name }} {{ projectManager?.last_name }}, Project Manager
                            </p>
                            <p class="dkblue font-weight-semibold lh-20 mb-3">{{ projectManager?.email }}</p>
                          </div>
                        </div>
                        <div class="row d-flex align-items-top">
                          <div class="col d-flex align-items-top">
                            <p class="dkblue font-weight-semibold lh-20 mb-3 mr-auto">
                              CONTRACTOR: {{ bidContact?.first_name }} {{ bidContact?.last_name }},
                              {{ bidCompany?.name }}
                            </p>
                            <p class="dkblue font-weight-semibold lh-20 mb-3">{{ bidContact?.email }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.5
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Electronic Signature/Counterparts. The Parties agree that this Contract, and any documents to
                          be signed in connection herewith, may be electronically signed and the use by a Party of an
                          electronic signature shall, for the purposes of validity, enforceability, and admissibility,
                          be conclusive evidence of that Party’s intention to be legally bound as if such signature had
                          been written by hand, pursuant to the Oklahoma Uniform Electronic Transactions Act. The
                          Party’s agree this Contract may be executed in one or more counterparts, each of which shall
                          be deemed to be an original and all of which together shall constitute one and the same
                          agreement. A counterpart bearing an electronic signature shall satisfy the requirements of
                          this provision. This provision specifically applies to a Party’s use of UHT’s 1CALL Cloud
                          system.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.6
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          1CALL Cloud System. Supplier agrees to use UHT’s 1CALL Cloud system according to the terms of
                          this Contract. 1CALL Cloud is a cloud-based facility management system used to manage all
                          Trust Title 61 Construction projects, Title 74 acquisition and purchasing projects, and
                          facility management requests. A Party’s 1CALL Cloud user account consists of the registrant’s
                          email address and password, serves as the registrant’s official signature for purposes of
                          using the 1CALL system. Once registered, the registrant is responsible and accountable for all
                          documents signed using that email address and password. The Parties agree that no
                          certification authority or other third-party verification is necessary to validate an
                          electronic signature, and that the lack of such certification or third-party verification will
                          not in any way affect the enforceability of an electronic signature or any resulting agreement
                          between a Party and the 1CALL Facility Services or Trust. By selecting the “I Accept” or
                          “Approve” button, a Party is signing and agrees to be bound by any digital documents, to
                          include but not limited to, construction contracts, change orders, service agreements or lease
                          agreements created, managed and stored by 1CALL Cloud. 1CALL Cloud user accounts and emails
                          are required to receive and sign all digital documents. Each 1CALL Cloud user account is
                          created using an email and the password known only to the system account holder. In all cases,
                          the name of the authorized signatory shall be identified as visible on the document.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '7' : typeId === 2 ? '6' : '#' }}.7
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          1CALL Policies and Guidelines. By signing this Contract, Supplier agrees that it shall adhere
                          to UHT’s Privacy Statement and Terms of Service, incorporated into this Contract by reference,
                          as set forth on the Trust’s website, https://uhat.org. Supplier is responsible for reviewing
                          and relaying this policy to the Supplier’s employees, agents, representatives or
                          subcontractors. Trust reserves the right to amend these policies at any time by posting the
                          amended policy on its website.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">General Responsibilities of the Trust</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the Supplier fails to correct Work which is not in accordance with the Contract Documents,
                          the Trust may direct the Supplier in writing to stop the Work until the correction is made.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the Supplier defaults or neglects to carry out the Work in accordance with the Contract
                          Documents and fails within a seven-day period after receipt of written notice from the Trust
                          to commence and continue correction of such default or neglect with diligence and promptness,
                          the Trust may, without prejudice to other remedies, correct such deficiencies. In such case,
                          the CFMO and/or his designee may withhold or nullify a Certificate for Payment in whole or in
                          part, to the extent reasonably necessary to reimburse the Trust for the cost of correction,
                          provided the actions of the Trust and amounts charged to the Supplier were approved by the
                          CFMO and/or his designee.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Trust reserves the right to perform construction or operations related to the Project with
                          the Trust’s own forces, and to award separate contracts in connection with other portions of
                          the Project.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4" *ngIf="typeId === 3">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall coordinate and cooperate with the Trust’s own forces and separate suppliers
                          employed by the Trust.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">General Responsibilities of the Supplier</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Review of Contract Documents and Field Conditions
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.1.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Execution of the Contract by the Supplier is a representation that the Supplier has visited
                          the site, become familiar with local conditions under which the Work is to be performed, and
                          correlated personal observations with requirements of the Contract Documents.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.1.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 3">
                          The Supplier shall carefully study and compare the Contract Documents with each other and with
                          information furnished by the Trust. Before commencing activities, the Supplier shall (1) take
                          field measurements and verify field conditions; (2) carefully compare this and other
                          information known to the Supplier with the Contract Documents; and (3) promptly report errors,
                          inconsistencies, or omissions discovered to the CFMO and/or his designee.
                        </p>
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 2">
                          Before commencing activities, the Supplier shall (1) take field measurements and verify field
                          conditions; (2) carefully compare this and other information known to the Supplier with any
                          Contract Documents; and (3) promptly report errors, inconsistencies, or omissions discovered
                          to the CFMO and/or his designee.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Construction Schedule <br />
                          The “Construction Schedule” means the listing of a project’s milestones, activities, and
                          deliverables with intended start and finish dates. Because this Project includes multiple
                          phases, the Supplier agrees to attend a Pre-Construction Meeting so that the Trust has
                          information necessary to determine a Construction Schedule. Once the Trust and the Supplier
                          agree to the Construction Schedule as finally determined by the Trust, the Trust shall prepare
                          an Addendum to this negotiation document memorializing the Construction Schedule which both
                          the Trust and the Supplier shall date and sign.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Supervision and Construction Procedures.</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.3.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall supervise and direct the Work using its best skill and attention. The
                          Supplier shall be solely responsible for and have control over construction means, methods,
                          techniques, sequences, and procedures, and for coordinating its portion of the Work.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.3.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier, as soon as practicable after award of the Contract, shall furnish in writing to
                          the Trust, through the CFMO and/or his designee, the names of any subcontractors or suppliers
                          for each portion of the Work. The Supplier shall not contract with any subcontractor or
                          supplier to whom the Trust has made a timely and reasonable objection.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Labor and Materials</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.4.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Unless otherwise provided in the Contract {{ typeId === 2 ? '' : 'Documents' }}, the Supplier
                          shall provide and pay for labor, materials, equipment, tools, utilities, all transportation,
                          and other facilities and services necessary for proper execution and completion of the Work.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.4.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall enforce strict discipline and good order among the Supplier’s employees and
                          other persons carrying out the Contract Work. The Supplier shall not permit employment of
                          unfit persons or persons not skilled in tasks assigned to them.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.5
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Warranty. The Supplier warrants to the Trust that: (1) materials and equipment furnished under
                          the Contract will be new and of good quality unless otherwise required or permitted by the
                          Contract {{ typeId === 2 ? '' : 'Documents' }}; (2) the Work will be free from defects not
                          inherent in the quality required or permitted; and (3) the Work will conform to the
                          requirements in the Contract {{ typeId === 2 ? '' : 'Documents' }}. Any material or equipment
                          warranties required by the Contract {{ typeId === 2 ? '' : 'Documents' }} shall be issued in
                          the name of the Trust, or shall be transferable to the Trust, and shall commence one year from
                          Project Completion. Project Completion for purposes of this Article
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.5 is the date upon which final payment is
                          released.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.6
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Taxes. The Supplier shall pay sales, consumer, use, and any similar taxes that are legally
                          required.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.7
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Permits and Reviews</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.7.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Except for permits necessary for fire alarm and fire suppression, the Trust shall obtain and
                          pay for necessary permits obtained through the Authority Having Jurisdiction and shall obtain
                          and pay for inspections necessary for proper execution and completion of the Work. The
                          Supplier awarded the Project for installation of fire alarms and fire suppression shall obtain
                          and pay for the permits necessary for fire alarm and fire suppression.
                        </p>
                      </div>
                    </div>

                    <div *ngIf="typeId === 3" class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.7.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Supplier shall comply with all applicable state and federal laws and regulations as now in
                          effect and as such laws or regulations as may be amended during the term of the Contract. If
                          the Supplier performs Work knowing it to be contrary to applicable laws, statutes, ordinances,
                          codes, rules and regulations, or lawful orders of public authorities, the Supplier shall
                          promptly notify the CFMO and/or his designee in writing of any known inconsistences
                          {{
                            typeId === 2
                              ? ''
                              : 'in the Contract Documents with such governmental laws, rules, and regulations'
                          }}. Additionally, Supplier shall comply with any relevant audit requirements under any
                          applicable state or federal law, or any reasonable equivalent procedures and requirements that
                          may be prescribed while carrying out the Contract.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.7.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Trust facilities shall be constructed, renovated, or improved in such a manner as to provide
                          reasonable safety from fire, smoke, panic, and related hazards and to ensure a high level of
                          health comfort, and well-being for all occupants.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.8
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Submittals. The Supplier shall promptly review, approve in writing, and submit to the CFMO
                          and/or his designee shop drawings, product data, samples, and similar submittals
                          (“Submittals”) required by the Contract {{ typeId === 2 ? '' : 'Documents' }}. Submittals are
                          not Contract Documents.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.9
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 3">
                          Supplier shall use Trust’s online project management system, 1Call Cloud, for all
                          project-related billing and communication. As such, Supplier acknowledges and agrees that
                          Supplier must create a 1Call Cloud profile through which Supplier agrees to submit all
                          invoices, submittals, Requests for Information, Change Orders, and all correspondence with
                          Trust regarding the Project.
                        </p>
                        <p class="dkblue font-weight-normal lh-20 m-0" *ngIf="typeId === 2">
                          1Call Cloud. Supplier shall use Trust's online project management system, 1Call Cloud, for all
                          project-related billing and communication. As such, Supplier acknowledges and agrees that
                          Supplier must create a 1Call Cloud profile through which Supplier agrees to submit all
                          invoices, submittals, Requests for Information, Change Orders, and all correspondence with
                          Trust regarding the Project.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.10
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Use of Site. The Supplier shall confine operations at the site to areas permitted by law,
                          ordinances, permits, the Contract {{ typeId === 2 ? '' : 'Documents' }}, and/or the Trust.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.11
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Cutting and Patching. The Supplier shall be responsible for cutting, fitting, or patching
                          required to complete the Work or to make parts fit together properly.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.12
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Cleaning Up. The Supplier shall keep the premises and surrounding area free from accumulation
                          of debris and trash related to the Work, including by clearing the premises and surrounding
                          area at the end of every work shift. At the completion of the Work, the Supplier shall remove
                          its tools, construction equipment, machinery, and surplus material, and shall properly dispose
                          of waste materials.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.13
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Indemnification. To the fullest extent permitted by law, the Supplier shall indemnify and hold
                          harmless the Trust, its CFMO, and agents and employees of any of them, from and against
                          claims, damages, losses and expenses, including but not limited to attorneys’ fees, arising
                          out of or resulting from performance of the Work, provided that such claim, damage, loss, or
                          expense is attributable to bodily injury, sickness, disease or death, or to injury to or
                          destruction of tangible property (other than the Work itself), but only to the extent caused
                          by the negligent acts or omissions of the Supplier, a subcontractor, or anyone directly or
                          indirectly employed by them or anyone for whose acts they may be liable, regardless of whether
                          or not such claim, damage, loss or expense is caused in part by a party indemnified hereunder.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">CFMO</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee will provide administration of the Contract
                          {{ typeId === 2 ? '' : 'as described in the Contract Documents' }}. The CFMO may designate
                          another, including the Project Manager, to provide administration of the Contract
                          {{ typeId === 2 ? '' : 'as described in the Contract Documents' }}.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee will visit the site at intervals appropriate to the stage of
                          construction to become generally familiar with the progress and quality of the Work.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee will not have control over or charge of, and will not be
                          responsible for, construction means, methods, techniques, sequences or procedures, or for
                          safety precautions and programs in connection with the Work, since these are solely the
                          Supplier’s responsibility. The CFMO and/or his designee will not be responsible for the
                          Supplier’s failure to carry out the Work in accordance with the Contract Documents,
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Based on the CFMO and/or his designee’s observations and evaluations of the Supplier’s
                          Requests for Payment, the CFMO and/or his designee will review and certify amounts due to the
                          Supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.5
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee has authority to reject Work that does not conform to the
                          Contract {{ typeId === 2 ? '' : 'Documents' }}.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.6
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee will promptly review and approve or take appropriate action upon
                          the Supplier’s Submittals, but only for the limited purpose of checking for conformance with
                          information given and
                          {{
                            typeId === 2
                              ? 'any design concept expressed'
                              : 'the design concept expressed in the Contract Documents'
                          }}.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '10' : typeId === 2 ? '9' : '#' }}.7
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee will promptly interpret and decide matters concerning performance
                          under, and requirements of, the Contract Documents on written request from the Supplier.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Change Orders</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Without invalidating the Contract, Change Orders in the Work may be requested within the
                          general scope of the Contract consisting of additions, deletions, or other revisions.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          When the Trust has determined a change in the Work is necessary or approves a change and prior
                          to the issuance of any Change Order, the CFMO and/or his designee shall issue a Proposal
                          Request to the Supplier. The Proposal Request shall be sent to the Supplier and a Proposed
                          Change Order shall be created which shall fully describe the scope of work explaining
                          completely what each item entails and the cost, credit, and time extension involved. The
                          Supplier shall provide a detailed breakdown of cost, showing quantities and sizes of
                          materials; unit cost, labor, equipment, profit and overhead; and other expense items. The ADC
                          will consider the Proposed Change Order. If approved, the CFMO and/or his designee will issue
                          a signed Change Order that includes adjustments to Contract Sum and Contract Time.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p *ngIf="typeId === 3" class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee may authorize or order minor changes to the Work that are
                          consistent with the intent of the Contract Documents and do not involve an adjustment in the
                          Contract Sum or an extension of the Contract Time. Such authorization or order shall be in
                          writing and shall be binding on the Trust and the Supplier. The Supplier shall proceed with
                          such minor changes promptly. Change Orders necessitating an extension of the Contract Sum or
                          the Contract Time must be agreed upon by the Trust and the Supplier.
                        </p>
                        <p *ngIf="typeId === 2" class="dkblue font-weight-normal lh-20 m-0">
                          The CFMO and/or his designee may authorize or order minor changes in the Work that are
                          consistent with the Scope of Work and do not involve an adjustment in the Contract Sum or an
                          extension of the Contract Time. Such authorization or order shall be in writing and shall be
                          binding on the Trust and the Supplier. The Supplier shall proceed with such minor changes
                          promptly. Change Orders necessitating an extension of the Contract Sum or the Contract Time
                          must be agreed upon by the Trust and the Supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '11' : typeId === 2 ? '10' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If concealed or unknown physical conditions are encountered at the site that differ materially
                          from those indicated in the Contract Documents or from those conditions ordinarily found to
                          exist, the Contract Sum and Contract Time shall be subject to equitable adjustment.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Time</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Time limits stated in the Contract {{ typeId === 2 ? '' : 'Documents' }} are of the essence of
                          the Contract.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the Supplier is delayed at any time in progress of the Work by changes ordered in the Work,
                          or by labor disputes, fire, unusual delay in deliveries, unavoidable casualties or other
                          causes beyond the Supplier’s control, the Contract Time shall be subject to equitable
                          adjustment.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Time extensions, if justified shall be granted by Change Order only. Time extensions for
                          changes in the Work must be included on the Change Order form authorizing such change.
                          Substantiating data must be submitted with the requests for time extensions. Failure to
                          complete the Work in the time specified by the Trust at Article
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.2 may, at the Trust's election, trigger
                          Termination for Cause as identified at Article
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2 herein.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Costs caused by delays or by improperly timed activities or defective construction shall be
                          borne by the responsible party.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '13' : typeId === 2 ? '12' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Protection of Persons and Property</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '13' : typeId === 2 ? '12' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall be responsible for initiating, maintaining and supervising all safety
                          precautions and programs, including all those required by law in connection with performance
                          of the Contract. The Supplier shall take reasonable precautions to prevent damage, injury, or
                          loss to employees on the Work and other persons who may be affected thereby, the Work and
                          materials and equipment to be incorporated therein, and other property at the site or adjacent
                          thereto. The Supplier shall promptly remedy damage and loss to property caused in whole or in
                          part by the Supplier, or by anyone for whose acts the Supplier may be liable.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '13' : typeId === 2 ? '12' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Any Supplier employees or other persons carrying out the Contract Work (for purposes of this
                          Article {{ typeId === 3 ? '13' : typeId === 2 ? '12' : '#' }}.2 "personnel") must wear the
                          required Personal Protective Equipment ("PPE") upon entering the job site and at all times
                          while present at the job site. Appropriate use of PPE will protect personnel from potential
                          hazards to the respiratory system, skin, eyes, face, hands, feet, head, body, and hearing that
                          may be encountered on the job site. All personnel must wear Trust-provided security name tags
                          at all times, thereby informing tenants that such personnel is approved to be inside the
                          facility.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '14' : typeId === 2 ? '13' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Correction of Work</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '14' : typeId === 2 ? '13' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Supplier shall promptly correct Work rejected by the CFMO and/or his designee as failing
                          to conform to the requirements of the Contract Documents. The Supplier shall bear the cost of
                          correcting such rejected Work, including the costs of uncovering, replacement, and additional
                          testing.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '14' : typeId === 2 ? '13' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          In addition to the Supplier’s other obligations including warranties under the Contract, the
                          Supplier shall, for a period of one year after Substantial Completion, correct Work not
                          conforming to the requirements of the Contract Documents.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '14' : typeId === 2 ? '13' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the Supplier fails to correct nonconforming Work within a reasonable time, not to exceed 30
                          days following written notice from the Trust, the Trust may correct it in accordance with
                          Article
                          {{ typeId === 3 ? '8' : typeId === 2 ? '7' : '#' }}.2
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Miscellaneous Provisions</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Assignment of Contract. Neither party to the Contract shall assign the Contract as a whole
                          without written consent of the other.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Amendments. This document shall not be changed or modified in whole or in part unless such
                          change or modification is in writing and signed by the Trust and the Supplier.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Tests and Inspections</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.3.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          At the appropriate times, the Supplier shall arrange and bear cost of tests, inspections and
                          approvals of portions of the Work required by the Contract Documents or by laws, statutes,
                          ordinances, codes, rules and regulations, or lawful orders of public authorities.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.3.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the CFMO and/or his designee requires additional testing, the Supplier shall perform those
                          tests.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.3.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Trust shall bear cost of tests, inspections, or approvals that do not become requirements
                          until after the Contract is executed. The Trust shall directly arrange and pay for tests,
                          inspections, or approvals where building codes or applicable laws or regulations so require.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '15' : typeId === 2 ? '14' : '#' }}.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Governing Law. This Agreement shall be governed by and construed in accordance with the laws
                          of the State of Oklahoma. Exclusive venue for any dispute arising hereunder is the federal or
                          state courts for Oklahoma City, Oklahoma.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="row mx-0 mb-0">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">
                          Article {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-bold lh-20 m-0">Termination of the Contract</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Termination by the Supplier. If the Work is stopped under Article
                          {{ typeId === 3 ? '12' : typeId === 2 ? '11' : '#' }}.2 for a period of 14 days through no
                          fault of the Supplier, the Supplier may, upon seven additional days' written notice to the
                          Trust, terminate the Contract and recover from the Trust payment for Work executed including
                          reasonable overhead and profit, and costs incurred by reason of such termination.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">Termination for Cause by the Trust</p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2.1
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          The Trust may terminate the Contract if the Supplier <br />
                          .1 refuses or fails to supply enough properly skilled workers or proper materials; <br />
                          .2 fails to make payment to subcontractors for materials or labor in accordance with the
                          respective agreements between the Supplier and the subcontractors; <br />
                          .3 disregards applicable laws, statutes, ordinances, codes, rules and regulations, or lawful
                          orders of a public authority; <br />
                          .4 fails to complete Work pursuant to the agreed-upon timeline as identified at Article
                          {{ typeId === 3 ? '9' : typeId === 2 ? '8' : '#' }}.2 herein; or <br />
                          .5 is otherwise guilty of substantial breach of a provision of the Contract
                          {{ typeId === 2 ? '' : 'Documents' }}.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2.2
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          When any of the above reasons exist, the Trust may without prejudice to any its other rights
                          or remedies and after giving the Supplier and the Supplier's surety, if any, seven days'
                          written notice, terminate employment of the Supplier and may <br />
                          .1 take possession of all materials properly belonging to the Trust pursuant to Article 4, and
                          <br />
                          .2 finish the Work by whatever reasonable method the Trust may deem expedient.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          When the Trust terminates the Contract for one of the reasons stated in Section
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2.1, the Supplier shall not be entitled
                          to receive further payment until the Work is finished.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.2.4
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          If the unpaid balance of the Contract Sum exceeds costs of finishing the Work, such excess
                          shall be paid to the Supplier. If such costs exceed the unpaid balance, the Supplier shall pay
                          the difference to the Trust. This obligation for payment shall survive termination of the
                          Contract.
                        </p>
                      </div>
                    </div>

                    <div class="row mx-0 mb-4">
                      <div class="col-1 px-0">
                        <p class="dkblue font-weight-semibold lh-20 m-0">
                          {{ typeId === 3 ? '16' : typeId === 2 ? '15' : '#' }}.3
                        </p>
                      </div>
                      <div class="col-11 pr-0">
                        <p class="dkblue font-weight-normal lh-20 m-0">
                          Termination by the Trust for Convenience. The Trust may, at any time, terminate the Contract
                          for the Trust’s convenience and without cause. The Supplier shall be entitled to receive
                          payment for Work executed, and costs incurred by reason of such termination, along with
                          reasonable overhead and profit on the Work not executed.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <section id="contract-articles" *ngIf="typeId === 1">
              <div class="row m-0">
                <div class="col-12 px-0">
                  <p class="dkblue font-weight-semibold lh-24 mb-0">TERMS AND CONDITIONS</p>
                  <p class="dkblue lh-24">Trust and the Supplier mutually agree as follows.</p>
                </div>
              </div>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Contract Sum and Payment</p>
                  </div>
                </div>

                <div #summary id="summary" class="row mx-0 mb-4 d-flex align-items-top">
                  <div class="col-1 px-0">
                    <div
                      class="full-width d-flex align-items-center lh-30 py-0 px-1 text-wrap pointer"
                      [ngClass]="{
                        'bg-green': !contract.summary_completed,
                        'bg-white border border-gray': contract.summary_completed
                      }"
                      style="border-radius: 15px"
                      (click)="toggleContractProperty('summary_completed')"
                      *ngIf="awaitingReview"
                    >
                      <p class="dkblue font-weight-semibold m-0 mx-auto">1.1</p>
                      <mat-icon
                        matRipple
                        class="pointer br-50"
                        [ngClass]="{ green: contract.summary_completed, white: !contract.summary_completed }"
                      >
                        {{ contract.summary_completed ? 'check_circle' : 'radio_button_unchecked' }}
                      </mat-icon>
                    </div>
                    <div class="text-wrap" *ngIf="!awaitingReview">
                      <p class="dkblue font-weight-semibold m-0 mr-auto">1.1</p>
                    </div>
                  </div>
                  <div class="col-11 pr-0 pt-1 textarea">
                    <p class="dkblue font-weight-normal lh-20 mb-3">
                      1.1 The Contract Sum shall include all items and services necessary for the proper execution and
                      completion of the Work as described in the Scope of Work.
                    </p>
                    <mat-form-field class="full-width" appearance="outline" *ngIf="awaitingReview">
                      <textarea
                        matInput
                        rows="4"
                        placeholder="Summary Of Work"
                        [(ngModel)]="contract.summary"
                        (ngModelChange)="dataChanged()"
                      ></textarea>
                    </mat-form-field>
                    <!-- TODO: style -->
                    <p class="dkblue mb-3" *ngIf="!awaitingReview">{{ contract.summary }}</p>
                    <!-- <p class="gray font-italic font-weight-normal lh-20 mb-3">
                      Reference Construction Documents for more detailed info.
                    </p> -->
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">1.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      The Contract Sum stated in the Contract is the total amount payable by the Trust to the Supplier
                      for performance of the Work.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">1.3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Once the Supplier determines that all Work under the Contract Documents is complete, the Supplier
                      shall submit a Request for Payment for 100 percent (100%) of the Contract Sum less any amounts
                      previously paid. If the Trust issues a Certificate for Payment, payment shall be for 95 percent
                      (95%) of the Contract Sum; 5 percent (5%) of the Contract Sum shall be held back until all items
                      on the punch list are complete, at which time the remaining 5 percent (5%) shall be issued.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">1.4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Acceptance of final payment by the Supplier shall constitute a waiver of claims by the Supplier.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">General Provisions</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">2.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      The Contract represents the entire and integrated agreement between the Parties and supersedes
                      prior negotiations, representations, or agreements, either written or oral. The Contract may be
                      amended or modified only by a written modification signed by the Trust and the Supplier.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">2.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Electronic Notice. Written notice under this Agreement may be given by one Party to the other by
                      email.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Work Stoppage</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">3.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      If the Supplier fails to correct Work which is not in accordance with the Contract Documents, the
                      Trust may direct the Supplier in writing to stop the Work until the correction is made.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">3.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      If the Supplier defaults or neglects to carry out the Work in accordance with the Contract
                      Documents and fails within a seven (7) day period after receipt of written notice from the Trust
                      to commence and continue correction of such default or neglect with diligence and promptness, the
                      Trust may, without prejudice to other remedies, correct such deficiencies. In such case, a
                      Certificate for Payment may be withheld or nullified in whole or in part, to the extent reasonably
                      necessary to reimburse the Trust for the cost of correction.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">General Responsibilities of the Contractor</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      The Supplier shall supervise and direct the Work using its best skill and attention. The Supplier
                      shall be solely responsible for and have control over construction means, methods, techniques,
                      sequences, and procedures, and for coordinating its portion of the Work.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Unless otherwise provided in the Contract, the Supplier shall provide and pay for labor,
                      materials, equipment, tools, utilities, all transportation, and other facilities and services
                      necessary for proper execution and completion of the Work.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      The Supplier shall enforce strict discipline and good order among the Supplier’s employees and
                      other persons carrying out the Contract Work. The Supplier shall not permit employment of unfit
                      persons or persons not skilled in tasks assigned to them.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Warranty. The Supplier warrants to the Trust that: (1) materials and equipment furnished under the
                      Contract will be new and of good quality unless otherwise required or permitted by the Contract;
                      (2) the Work will be free from defects not inherent in the quality required or permitted; and (3)
                      the Work will conform to the requirements in the Contract.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.5</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Taxes. The Supplier shall pay sales, consumer, use, and any similar taxes that are legally
                      required.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.6</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Supplier shall comply with all applicable state and federal laws and regulations as now in effect
                      and as such laws or regulations may be amended during the term of this contract. If the Supplier
                      performs Work knowing it to be contrary to applicable laws, statutes, ordinances, codes, rules and
                      regulations, or lawful orders of public authorities, the Supplier shall promptly notify the Trust
                      in writing of any known inconsistences. Additionally, Supplier shall comply with any relevant
                      audit requirements under any applicable state or federal law, or any reasonable equivalent
                      procedures and requirements that may be prescribed while carrying out the Contract.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.7</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Trust facilities shall be constructed, renovated, or improved in such a manner as to provide
                      reasonable safety from fire, smoke, panic, and related hazards and to ensure a high level of
                      health comfort, and well-being for all occupants.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.8</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Use of Site. The Supplier shall confine operations at the site to areas permitted by law,
                      ordinances, permits, the Contract, and/or the Trust.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.9</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Cutting and Patching. The Supplier shall be responsible for cutting, fitting, or patching required
                      to complete the Work or to make parts fit together properly.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.10</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Cleaning Up. The Supplier shall keep the premises and surrounding area free from accumulation of
                      debris and trash related to the Work, including by clearing the premises and surrounding area at
                      the end of every work shift. At the completion of the Work, the Supplier shall remove its tools,
                      construction equipment, machinery, and surplus material, and shall properly dispose of waste
                      materials.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">4.11</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Indemnification. To the fullest extent permitted by law, the Supplier shall indemnify and hold
                      harmless the Trust, its CFMO, and agents and employees of any of them, from and against claims,
                      damages, losses and expenses, including but not limited to attorneys’ fees, arising out of or
                      resulting from performance of the Work, provided that such claim, damage, loss, or expense is
                      attributable to bodily injury, sickness, disease or death, or to injury to or destruction of
                      tangible property (other than the Work itself), but only to the extent caused by the negligent
                      acts or omissions of the Supplier, a subcontractor, or anyone directly or indirectly employed by
                      them or anyone for whose acts they may be liable, regardless of whether or not such claim, damage,
                      loss or expense is caused in part by a party indemnified hereunder.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 5</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Protection of Persons and Property</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">5.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      The Supplier shall be responsible for initiating, maintaining and supervising all safety
                      precautions and programs, including all those required by law in connection with performance of
                      the Contract. The Supplier shall take reasonable precautions to prevent damage, injury, or loss to
                      employees on the Work and other persons who may be affected thereby, the Work and materials and
                      equipment to be incorporated therein, and other property at the site or adjacent thereto. The
                      Supplier shall promptly remedy damage and loss to property caused in whole or in part by the
                      Supplier, or by anyone for whose acts the Supplier may be liable.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">5.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Any Supplier employees or other persons carrying out the Contract Work (for purposes of this
                      Article 5.2 “personnel”) must wear the required Personal Protective Equipment (“PPE”) upon
                      entering the job site and at all times while present at the job site. Appropriate use of PPE will
                      protect personnel from potential hazards to the respiratory system, skin, eyes, face, hands, feet,
                      head, body, and hearing that may be encountered on the job site. All personnel must wear
                      Trust-provided security name tags at all times, thereby informing tenants that such personnel are
                      approved to be inside the facility.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 6</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Correction of Work</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">6.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      6.1 The Supplier shall promptly correct Work rejected by the Trust as failing to conform to the
                      requirements of any Contract Documents. The Supplier shall bear the cost of correcting such
                      rejected Work, including the costs of uncovering, replacement, and additional testing.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">6.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      If the Supplier fails to correct nonconforming Work within a reasonable time, not to exceed 30
                      days following written notice from the Trust, the Trust may correct it in accordance with Article
                      3.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 7</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Miscellaneous Provisions</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">7.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Assignment of Contract. Neither party to the Contract shall assign the Contract as a whole without
                      written consent of the other.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">7.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Governing Law. This Agreement shall be governed by and construed in accordance with the laws of
                      the State of Oklahoma. Exclusive venue for any dispute arising hereunder is the federal or state
                      courts for Oklahoma City, Oklahoma.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">7.3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Electronic Signature/Counterparts. The Parties agree that this Contract, and any documents to be
                      signed in connection herewith, may be electronically signed and the use by a Party of an
                      electronic signature shall, for the purposes of validity, enforceability, and admissibility, be
                      conclusive evidence of that Party’s intention to be legally bound as if such signature had been
                      written by hand, pursuant to the Oklahoma Uniform Electronic Transactions Act. The Party’s agree
                      this Contract may be executed in one or more counterparts, each of which shall be deemed to be an
                      original and all of which together shall constitute one and the same agreement. A counterpart
                      bearing an electronic signature shall satisfy the requirements of this provision. This provision
                      specifically applies to a Party’s use of UHT’s 1CALL Cloud system.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">7.4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      1CALL Cloud System. Supplier agrees to use UHT’s 1CALL Cloud system according to the terms of this
                      Contract. 1CALL Cloud is a cloud-based facility management system used to manage all Trust Title
                      61 Construction projects, Title 74 acquisition and purchasing projects, and facility management
                      requests. A Party’s 1CALL Cloud user account consists of the registrant’s email address and
                      password, serves as the registrant’s official signature for purposes of using the 1CALL system.
                      Once registered, the registrant is responsible and accountable for all documents signed using that
                      email address and password. The Parties agree that no certification authority or other third-party
                      verification is necessary to validate an electronic signature, and that the lack of such
                      certification or third-party verification will not in any way affect the enforceability of an
                      electronic signature or any resulting agreement between a Party and the 1CALL Facility Services or
                      Trust. By selecting the “I Accept” or “Approve” button, a Party is signing and agrees to be bound
                      by any digital documents, to include but not limited to, construction contracts, change orders,
                      service agreements or lease agreements created, managed and stored by 1CALL Cloud. 1CALL Cloud
                      user accounts and emails are required to receive and sign all digital documents. Each 1CALL Cloud
                      user account is created using an email and the password known only to the system account holder.
                      In all cases, the name of the authorized signatory shall be identified as visible on the document.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">7.5</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      1CALL Policies and Guidelines. By signing this Contract, Supplier agrees that it shall adhere to
                      UHT’s Privacy Statement and Terms of Service, incorporated into this Contract by reference, as set
                      forth on the Trust’s website, https://uhat.org. Supplier is responsible for reviewing and relaying
                      this policy to the Supplier’s employees, agents, representatives or subcontractors. Trust reserves
                      the right to amend these policies at any time by posting the amended policy on its website.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mx-0">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Article 8</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-bold lh-20 m-0">Termination of the Contract</p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">8.1</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Termination by the Supplier. If the Trust stops the Work for a period of fourteen (14) days
                      through no fault of the Supplier, the Supplier may, upon seven (7) additional days’ written notice
                      to the Trust, terminate the Contract and recover from the Trust payment for Work executed
                      including reasonable overhead and profit, and costs incurred by reason of such termination.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">8.2</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Termination for Cause by the Trust. The Trust may terminate the Contract if the Supplier: <br />
                      .1 refuses or fails to supply enough properly skilled workers or proper materials; <br />
                      .2 fails to make payment to subcontractors for materials or labor in accordance with the
                      respective agreements between the Supplier and the subcontractors; <br />
                      .3 disregards applicable laws, statutes, ordinances, codes, rules and regulations, or lawful
                      orders of a public authority; <br />
                      .4 fails to complete Work in a timely fashion; or <br />
                      .5 is otherwise guilty of substantial breach of a provision of the Contract.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">8.3</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      When the Trust terminates the Contract for one of the reasons stated in Section 8.2, the Supplier
                      shall not be entitled to receive further payment until the Work is finished.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">8.4</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      If the unpaid balance of the Contract Sum exceeds costs of finishing the Work, such excess shall
                      be paid to the Supplier. If such costs exceed the unpaid balance, the Supplier shall pay the
                      difference to the Trust. This obligation for payment shall survive termination of the Contract.
                    </p>
                  </div>
                </div>

                <div class="row mx-0 mb-4">
                  <div class="col-1 px-0">
                    <p class="dkblue font-weight-semibold lh-20 m-0">8.5</p>
                  </div>
                  <div class="col-11 pr-0">
                    <p class="dkblue font-weight-normal lh-20 m-0">
                      Termination by the Trust for Convenience. The Trust may, at any time, terminate the Contract for
                      the Trust’s convenience and without cause. The Supplier shall be entitled to receive payment for
                      Work executed, and costs incurred by reason of such termination, along with reasonable overhead
                      and profit on the Work not executed.
                    </p>
                  </div>
                </div>
              </section>
            </section>

            <div class="row mx-0 mb-4 d-flex align-items-top">
              <div class="col-1 px-0"></div>
              <div class="col-11 pr-0">
                <p class="dkblue font-weight-normal lh-20 mb-3">
                  This Agreement entered into as of the day and year first written above.
                </p>
              </div>
            </div>

            <section #signatures id="signatures">
              <div class="row mx-0 mb-4 d-flex align-items-top">
                <div class="col-12 px-0">
                  <div class="row m-0 mb-5" *ngIf="[2, 3].indexOf(statusId) > -1">
                    <div class="col-xl-1 px-0 pb-4">
                      <div
                        *ngIf="statusId === 2"
                        class="full-width d-flex align-items-center lh-30 bg-green py-0 px-1 text-wrap pointer"
                        style="border-radius: 15px"
                      >
                        <p class="dkblue font-weight-semibold m-0 mx-auto">Sign</p>
                        <mat-icon matRipple class="white pointer br-50">{{
                          contract?.vendor_signature_text ? 'check_circle' : 'radio_button_unchecked'
                        }}</mat-icon>
                      </div>
                    </div>
                    <div class="{{ statusId === 2 ? 'col-8' : 'col-5' }} d-flex flex-column">
                      <span *ngIf="statusId === 2">
                        <h3 class="dkblue mb-3">Sign Here</h3>
                        <mat-form-field class="full-width mb-3" appearance="outline">
                          <input
                            matInput
                            placeholder="Enter Your Name"
                            [(ngModel)]="contract.vendor_signature_text"
                            [disabled]="!isCurrentSigner"
                          />
                        </mat-form-field>
                        <p class="dkgray terms">
                          This Agreement is legally binding and describes the terms governing your use of the 1CALL
                          Cloud services provided on this website, including content, updates, and releases.
                        </p>
                        <a
                          href="https://uhat.org/terms-of-service"
                          target="_blank"
                          class="ltblue hover-u font-weight-semibold"
                        >
                          Full Terms and Conditions</a
                        >
                      </span>
                      <span [ngClass]="{ 'mt-auto': statusId === 3 }">
                        <div class="full-width border border-gray br-5 p-2 pl-3 d-flex align-items-center mt-3">
                          <span class="">
                            <p class="dkblue font-weight-semibold mb-0 mr-2 lh-18">Proof of Insurance*</p>
                            <p
                              class="dkgray font-weight-normal mb-0 mr-2 lh-18"
                              *ngIf="!contract?.performance_bond_file_id"
                            >
                              Required.
                            </p>
                          </span>
                          <!-- <i class="gray far fa-question-circle mr-auto pointer mr-2"></i> -->
                          <div
                            *ngIf="!contract?.proof_of_insurance"
                            class="ml-auto d-flex flex-column align-items-center justify-content-center"
                          >
                            <button
                              mat-flat-button
                              color="primary"
                              class="lh-32"
                              (click)="uploadProofOfInsurance('proof_of_insurance_file_ids')"
                              [disabled]="statusId !== 2 || !isCurrentSigner"
                              *ngIf="!contract?.proof_of_insurance_files?.length && !contract?.proof_of_insurance"
                            >
                              Attach PDF
                            </button>
                          </div>
                          <mat-chip-list
                            *ngIf="contract?.proof_of_insurance_files?.length === 1 || contract?.proof_of_insurance"
                            class="ml-auto"
                          >
                            <app-file-chip
                              [file]="contract?.proof_of_insurance_files[0] || contract?.proof_of_insurance"
                              [removable]="statusId === BidContractStatus.SentToVendor && isCurrentSigner"
                              (removeEvent)="
                                removeProofOfInsurance(
                                  contract?.proof_of_insurance
                                    ? 'proof_of_insurance_file_id'
                                    : 'proof_of_insurance_file_ids',
                                  contract?.proof_of_insurance ? null : contract?.proof_of_insurance_files[0]?.id
                                )
                              "
                            ></app-file-chip>
                          </mat-chip-list>
                          <button
                            mat-icon-button
                            color="primary"
                            class="lh-32"
                            (click)="uploadProofOfInsurance('proof_of_insurance_file_ids')"
                            [disabled]="statusId !== 2 || !isCurrentSigner"
                            *ngIf="contract?.proof_of_insurance_files?.length === 1"
                          >
                            <mat-icon>attach_file</mat-icon>
                          </button>
                          <button
                            mat-button
                            color="primary"
                            *ngIf="contract?.proof_of_insurance_files?.length > 1"
                            class="lh-30 bg-shade-ltblue pr-2 pl-3"
                            [matMenuTriggerFor]="view_files"
                          >
                            {{ contract?.proof_of_insurance_files?.length }} Files
                            <mat-icon class="d-inline-block align-middle pointer">expand_more</mat-icon>
                          </button>
                          <mat-menu #view_files="matMenu" xPosition="before">
                            <div *ngFor="let file of contract?.proof_of_insurance_files">
                              <button mat-menu-item [matMenuTriggerFor]="attachment_menu">
                                <mat-icon>description</mat-icon>
                                <span>{{ file.name }}</span>
                              </button>
                              <mat-menu #attachment_menu="matMenu" xPosition="before">
                                <button (click)="previewFile(file)" mat-menu-item>
                                  <mat-icon>find_in_page</mat-icon>
                                  <span>View</span>
                                </button>
                                <button (click)="downloadFile(file)" mat-menu-item>
                                  <mat-icon>get_app</mat-icon>
                                  <span class="pr-2">Download</span>
                                </button>
                                <button
                                  (click)="removeProofOfInsurance('proof_of_insurance_file_ids', file.id)"
                                  mat-menu-item
                                >
                                  <mat-icon>delete</mat-icon>
                                  <span class="pr-2">Delete</span>
                                </button>
                              </mat-menu>
                            </div>
                            <button mat-menu-item (click)="uploadProofOfInsurance('proof_of_insurance_file_ids')">
                              <mat-icon>upload_file</mat-icon>
                              <span>Upload File</span>
                            </button>
                          </mat-menu>
                        </div>

                        <div
                          *ngIf="typeId === 3"
                          class="full-width border border-gray br-5 p-2 pl-3 d-flex align-items-center mt-3"
                        >
                          <span class="">
                            <p class="dkblue font-weight-semibold mb-0 mr-2 lh-18">Performance Bond*</p>
                            <p
                              class="dkgray font-weight-normal mb-0 mr-2 lh-18"
                              *ngIf="!contract?.performance_bond_file_id"
                            >
                              Required. Letter of Credit may be used.
                            </p>
                          </span>
                          <!-- <i class="gray far fa-question-circle mr-auto pointer mr-2"></i> -->
                          <div
                            *ngIf="!contract?.performance_bond_file_id"
                            class="ml-auto d-flex flex-column align-items-center justify-content-center"
                          >
                            <button
                              mat-flat-button
                              color="primary"
                              class="lh-32"
                              (click)="uploadProofOfInsurance('performance_bond_file_id')"
                              [disabled]="statusId !== 2 || !isCurrentSigner"
                            >
                              Attach PDF
                            </button>
                          </div>
                          <mat-chip-list *ngIf="contract?.performance_bond_file_id" class="ml-auto">
                            <app-file-chip
                              [file]="contract?.performance_bond"
                              [removable]="statusId === BidContractStatus.SentToVendor && isCurrentSigner"
                              (removeEvent)="removeProofOfInsurance('performance_bond_file_id')"
                            ></app-file-chip>
                          </mat-chip-list>
                        </div>

                        <div
                          *ngIf="typeId === 3"
                          class="full-width border border-gray br-5 p-2 pl-3 d-flex align-items-center mt-3"
                        >
                          <span class="">
                            <p class="dkblue font-weight-semibold mb-0 mr-2 lh-18">Defect Bond*</p>
                            <p class="dkgray font-weight-normal mb-0 mr-2 lh-18" *ngIf="!contract?.defect_bond_file_id">
                              Required. Letter of Credit may be used.
                            </p>
                          </span>
                          <!-- <i class="gray far fa-question-circle mr-auto pointer mr-2"></i> -->
                          <div
                            *ngIf="!contract?.defect_bond_file_id"
                            class="ml-auto d-flex flex-column align-items-center justify-content-center"
                          >
                            <button
                              mat-flat-button
                              color="primary"
                              class="lh-32"
                              (click)="uploadProofOfInsurance('defect_bond_file_id')"
                              [disabled]="statusId !== 2 || !isCurrentSigner"
                            >
                              Attach PDF
                            </button>
                          </div>
                          <mat-chip-list *ngIf="contract?.defect_bond_file_id" class="ml-auto">
                            <app-file-chip
                              [file]="contract?.defect_bond"
                              [removable]="statusId === BidContractStatus.SentToVendor && isCurrentSigner"
                              (removeEvent)="removeProofOfInsurance('defect_bond_file_id')"
                            ></app-file-chip>
                          </mat-chip-list>
                        </div>

                        <div
                          *ngIf="typeId === 3"
                          class="full-width border border-gray br-5 p-2 pl-3 d-flex align-items-center mt-3"
                        >
                          <span class="">
                            <p class="dkblue font-weight-semibold mb-0 mr-2 lh-18">Payment Bond*</p>
                            <p
                              class="dkgray font-weight-normal mb-0 mr-2 lh-18"
                              *ngIf="!contract?.payment_bond_file_id"
                            >
                              Required. Letter of Credit may be used.
                            </p>
                          </span>
                          <!-- <i class="gray far fa-question-circle mr-auto pointer mr-2"></i> -->
                          <div
                            *ngIf="!contract?.payment_bond_file_id"
                            class="ml-auto d-flex flex-column align-items-center justify-content-center"
                          >
                            <button
                              mat-flat-button
                              color="primary"
                              class="lh-32"
                              (click)="uploadProofOfInsurance('payment_bond_file_id')"
                              [disabled]="statusId !== 2 || !isCurrentSigner"
                            >
                              Attach PDF
                            </button>
                          </div>
                          <mat-chip-list *ngIf="contract?.payment_bond_file_id" class="ml-auto">
                            <app-file-chip
                              [file]="contract?.payment_bond"
                              [removable]="statusId === BidContractStatus.SentToVendor && isCurrentSigner"
                              (removeEvent)="removeProofOfInsurance('payment_bond_file_id')"
                            ></app-file-chip>
                          </mat-chip-list>
                        </div>

                        <p
                          class="green font-weight-normal mb-0 lh-18 mt-3"
                          [ngClass]="{ red: !insuranceFilesUploaded }"
                        >
                          <i
                            class="mr-1 fa {{ !insuranceFilesUploaded ? 'fa-exclamation-circle' : 'fa-check-circle' }}"
                          ></i>
                          All attachments must be PDF.
                        </p>
                      </span>
                    </div>
                    <div class="col-1 px-0">
                      <div
                        *ngIf="statusId === 3"
                        class="full-width d-flex align-items-center lh-30 bg-green py-0 px-1 text-wrap pointer"
                        style="border-radius: 15px"
                      >
                        <p class="dkblue font-weight-semibold m-0 mx-auto">Sign</p>
                        <mat-icon matRipple class="white pointer br-50">{{
                          contract?.trust_signature_text ? 'check_circle' : 'radio_button_unchecked'
                        }}</mat-icon>
                      </div>
                    </div>
                    <div class="col">
                      <span *ngIf="statusId === 3">
                        <div class="d-flex mb-3 align-items-bottom">
                          <h3 class="dkblue mb-0 mr-auto">Sign Here</h3>
                          <button
                            mat-button
                            (click)="setTrustSignatureText()"
                            color="accent"
                            type="button"
                            class="lh-22 px-2"
                            [disabled]="!isCurrentSigner"
                          >
                            <mat-icon class="mr-2" style="font-size: 16px; height: 16px; width: 16px"
                              >border_color</mat-icon
                            >
                            <span> Quick Sign </span>
                          </button>
                        </div>
                        <mat-form-field class="full-width mb-3" appearance="outline">
                          <input
                            matInput
                            placeholder="Type Name Here"
                            [(ngModel)]="contract.trust_signature_text"
                            [disabled]="!isCurrentSigner"
                          />
                        </mat-form-field>
                        <p class="dkgray">
                          This Agreement is legally binding and describes the terms governing your use of the 1CALL
                          Cloud services provided on this website, including content, updates, and releases.
                        </p>
                        <a
                          href="https://uhat.org/terms-of-service"
                          target="_blank"
                          class="ltblue hover-u font-weight-semibold mb-3"
                        >
                          Full Terms and Conditions</a
                        >
                      </span>
                    </div>
                  </div>
                  <div class="row m-0 pt-5">
                    <div class="col-xl-1"></div>
                    <div class="col d-flex flex-column align-items-top">
                      <ng-container>
                        <h1
                          class="font-weight-semibold mb-3 pb-0 border-bottom border-dkblue full-width signature lh-26"
                        >
                          <!-- {{ [3, 4, 5].indexOf(statusId) > -1 ? contract.vendor_signature_text : 'x' }} -->
                          {{ contract.vendor_signature_text ? contract.vendor_signature_text : 'x' }}
                        </h1>
                        <h5 class="dkblue mb-0 font-weight-semibold">SUPPLIER SIGNATURE</h5>
                        <p class="dkblue font-italic mb-4">
                          {{ contract.vendor_signed_datetime | date: 'MMMM d YYYY' }}
                        </p>
                        <h5 class="dkblue mb-0">{{ bidContact?.first_name }} {{ bidContact?.last_name }}</h5>
                        <p class="dkblue mb-4">{{ bidCompany?.name }}</p>
                        <label class="main-label text-uppercase mb-2"> Contract Sum </label>
                        <h5 class="dkblue font-weight-semibold mb-0">{{ contractSum | USDollar }}</h5>
                      </ng-container>
                    </div>
                    <div class="col-1"></div>
                    <div class="col">
                      <ng-container>
                        <h1
                          class="font-weight-semibold mb-3 pb-0 border-bottom border-dkblue full-width signature lh-26"
                        >
                          <!-- {{ [4, 5].indexOf(statusId) > -1 ? contract.trust_signature_text : 'x' }} -->
                          {{ contract.trust_signature_text ? contract.trust_signature_text : 'x' }}
                        </h1>
                        <h5 class="dkblue mb-0 font-weight-semibold">TRUST SIGNATURE</h5>
                        <p class="dkblue font-italic mb-4">{{ contract.executed_datetime | date: 'MMMM d YYYY' }}</p>
                        <h5 class="dkblue mb-0">{{ trustSignee?.first_name }} {{ trustSignee?.last_name }}</h5>
                        <p class="dkblue mb-3">{{ trustSignee?.title }}</p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="row d-flex align-items-center pt-2">
  <div class="col d-flex flex-column">
    <ng-container *ngIf="[1, 2, 3, 5].indexOf(statusId) > -1">
      <div class="d-flex mb-2">
        <i
          (click)="scrollToElement('contractSumElement')"
          class="material-icons green pointer mr-2"
          *ngIf="awaitingReview && [1, 2, 3].indexOf(typeId) > -1"
          >{{ contract.contract_sum_confirmed ? 'check_circle' : 'radio_button_unchecked' }}</i
        >
        <i
          (click)="scrollToElement('summary')"
          class="material-icons green pointer mr-2"
          *ngIf="awaitingReview && [1, 2, 3].indexOf(typeId) > -1"
          >{{ contract.summary_completed ? 'check_circle' : 'radio_button_unchecked' }}</i
        >
        <i
          (click)="scrollToElement('allowances')"
          class="material-icons green pointer mr-2"
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          >{{ contract.allowances_completed ? 'check_circle' : 'radio_button_unchecked' }}</i
        >
        <i
          (click)="scrollToElement('unitPrices')"
          class="material-icons green pointer mr-2"
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          >{{ contract.unit_prices_completed ? 'check_circle' : 'radio_button_unchecked' }}</i
        >
        <i
          (click)="scrollToElement('insurance')"
          class="material-icons green pointer mr-2"
          *ngIf="awaitingReview && [2, 3].indexOf(typeId) > -1"
          >{{ contract.insurance_policy_completed ? 'check_circle' : 'radio_button_unchecked' }}</i
        >
        <i (click)="scrollToElement('signatures')" class="material-icons green pointer mr-2" *ngIf="statusId === 2">{{
          contract?.vendor_signature_text ? 'check_circle' : 'radio_button_unchecked'
        }}</i>
        <i (click)="scrollToElement('signatures')" class="material-icons green pointer mr-2" *ngIf="statusId === 2">{{
          insuranceFilesUploaded ? 'check_circle' : 'radio_button_unchecked'
        }}</i>
        <i (click)="scrollToElement('signatures')" class="material-icons green pointer mr-2" *ngIf="statusId === 3">{{
          contract?.trust_signature_text ? 'check_circle' : 'radio_button_unchecked'
        }}</i>
      </div>
      <h6 class="dkblue m-0">{{ completedStepCount }} of {{ totalStepCount }} Steps completed</h6>
    </ng-container>
  </div>
  <div class="col-auto text-right">
    <button (click)="close()" mat-button class="gray ml-4">Cancel</button>
    <button mat-button (click)="exportContract()" color="accent" class="ml-4">Download PDF</button>
    <button
      mat-button
      color="warn"
      class="ml-4"
      *ngIf="[2, 3].includes(statusId) && isCurrentSigner"
      (click)="requestRevision()"
    >
      Request Revision
    </button>
    <!-- TODO: style gold disabled button -->
    <button
      mat-flat-button
      class="ml-4 bg-ltgray"
      [ngClass]="{ 'bg-gold white': isReadyToSubmit }"
      *ngIf="awaitingReview"
      [disabled]="!isReadyToSubmit"
      (click)="sendContract()"
    >
      Send Contract
    </button>
    <button
      mat-flat-button
      class="ml-4 bg-ltgray"
      [ngClass]="{ 'bg-gold white': isReadyToSignByVendor }"
      [disabled]="!this.isReadyToSignByVendor || !isReadyToSubmit"
      *ngIf="statusId === 2 && isCurrentSigner"
      (click)="submitVendorSignature()"
    >
      Submit Contract
    </button>
    <button
      mat-flat-button
      class="ml-4 bg-ltgray"
      [ngClass]="{ 'bg-gold white': isReadyToExecute }"
      [disabled]="!this.isReadyToExecute"
      *ngIf="statusId === 3 && isCurrentSigner"
      (click)="executeContract()"
    >
      Execute Contract
    </button>
  </div>
</mat-dialog-actions>

<app-bid-contract-export
  #pdf
  [contract]="contract"
  [insuranceRequirements]="insuranceRequirements"
></app-bid-contract-export>
