<app-base-dialog [title]="data?.quoteId ? 'Edit Bid' : 'New Bid'" (closeDialog)="close()"></app-base-dialog>
<div class="d-flex flex-column align-items-center p-5 mx-auto" *ngIf="isLoading">
  <mat-spinner color="accent" [diameter]="40"></mat-spinner>
</div>
<div class="project-modal p-0" *ngIf="tenant && !isLoading">
  <mat-dialog-content class="product-group-form">
    <form [formGroup]="quoteFormGroup">
      <section>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Supplier Company*</h6>
            <mat-form-field appearance="outline" class="full-width">
              <input
                type="text"
                placeholder="Select a Supplier Company"
                matInput
                #company_auto_input
                [matAutocomplete]="company_auto"
                [value]="quote?.company?.name"
                (input)="disableContactField($event)"
                [disabled]="data?.quoteId"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #company_auto="matAutocomplete"
                [displayWith]="companyValueMapper"
              >
                <mat-option
                  *ngFor="let c of filteredCompanies || []"
                  [value]="c"
                  (click)="companySelected(c)"
                  [disabled]="!isCompanyAllowed(c)"
                >
                  <p class="m-0 d-flex align-items-center">
                    <span class="mr-auto">
                      {{ c.name }}
                    </span>
                    <small class="ml-3">{{ CompanyVerificationStatus[c.verification_status] }}</small>
                  </p>
                </mat-option>
                <mat-option class="ltblue" (click)="createCompany()">
                  <i class="la la-plus"></i> &nbsp; New Supplier Company
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Supplier Contact</h6>
            <mat-form-field appearance="outline" class="full-width mb-0">
              <mat-select formControlName="companyContactId" placeholder="Choose Supplier Contact">
                <mat-option *ngFor="let contact of selectedCompanyContacts" [value]="contact.id">
                  {{ contact.first_name }} {{ contact.last_name }}
                </mat-option>
                <mat-option class="ltblue" (click)="addNewUser()">
                  <mat-icon>add</mat-icon>
                  <span> New Contact </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">
              Short Description{{ companyUsedInAnotherQuote ? '*' : '' }}
            </h6>
            <mat-form-field class="full-width mb-4" appearance="outline">
              <input placeholder="Short Description" matInput type="text" formControlName="description" />
            </mat-form-field>
          </div>
        </div>
      </section>
      <section>
        <div class="m-portlet m-0">
          <div class="m-portlet__body alt-bg-gray item-no-hover pt-2 pb-2 pl-3 pr-3">
            <div class="row align-items-center">
              <div class="col-4">
                <h6 class="dkblue font-weight-semibold m-0">Product</h6>
              </div>
              <div class="col-1 text-right">
                <h6 class="dkblue font-weight-semibold m-0">Qty</h6>
              </div>
              <div class="col text-right">
                <h6 class="dkblue font-weight-semibold m-0 pr-2">Status*</h6>
              </div>
              <div class="col text-right">
                <h6 class="dkblue font-weight-semibold m-0 pr-2">Price*</h6>
              </div>
              <div class="col text-right">
                <h6 class="dkblue font-weight-semibold m-0 pr-2">Total*</h6>
              </div>
            </div>
          </div>
          <form
            class="m-portlet__body item p-3"
            [ngClass]="{ 'item-last': last }"
            *ngFor="let projectProduct of projectProducts; let last = last"
            [formGroup]="projectProduct.form"
          >
            <div class="row align-items-center" *ngIf="!projectProduct.is_in_stock">
              <div class="col-4">
                <p class="dkblue font-weight-semibold m-0">
                  {{ projectProduct?.product?.name || projectProduct?.name }}
                </p>
                <h6 class="dkblue m-0 font-weight-normal">
                  {{ getProjectProductDetails(projectProduct) }}
                </h6>
              </div>
              <div class="col-1 text-right">
                <p class="dkblue font-weight-semibold m-0">
                  {{ projectProduct.quantity }}
                </p>
              </div>
              <div class="col pl-0 text-right">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select formControlName="status_id" (ngModelChange)="quoteItemStatusChanged(projectProduct)">
                    <mat-option [value]="null"></mat-option>
                    <mat-option *ngFor="let s of quoteItemStatuses" [value]="s.id">{{ s.name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="projectProduct.status_id.errors?.required">Status is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col pl-0 text-right">
                <mat-form-field appearance="outline" class="full-width">
                  <span class="gray" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    formControlName="unit_price"
                    class="text-right"
                    placeholder="0.000"
                    (keyup)="unitPriceChanged(projectProduct)"
                    (blur)="unitPriceBlur(projectProduct)"
                  />
                  <mat-error *ngIf="projectProduct.unit_price.errors?.required">Unit Price is required</mat-error>
                  <mat-error
                    *ngIf="
                      !projectProduct.unit_price.errors?.required && projectProduct.unit_price.errors?.isValidTotal
                    "
                    >Must be more than $0</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col pl-0 text-right">
                <mat-form-field appearance="outline" class="full-width">
                  <span class="gray" matPrefix>$&nbsp;</span>
                  <input
                    matInput
                    formControlName="total_price"
                    class="text-right"
                    placeholder="0.00"
                    (keyup)="totalPriceChanged(projectProduct)"
                    (blur)="totalPriceBlur(projectProduct)"
                  />
                  <mat-error *ngIf="projectProduct.total_price.errors?.required">Total Price is required</mat-error>
                  <mat-error
                    *ngIf="
                      !projectProduct.total_price.errors?.required && projectProduct.total_price.errors?.isValidTotal
                    "
                    >Must be more than $0</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
        <div class="row align-items-top pr-3 pt-4 pb-3">
          <div class="col-6 pr-0">
            <div class="m-portlet">
              <!-- NEED TO LIMIT ONE PER TYPE HERE -->
              <div class="m-portlet__body item d-flex align-items-center pt-2 pb-2 pl-3 pr-3">
                <div class="d-flex flex-column">
                  <p class="m-0 dkblue font-weight-semibold" [ngClass]="{ red: bidFileMissing }">Bid File*</p>
                  <h6 class="m-0 dkgray font-weight-normal">Must be PDF, JPG, or PNG.</h6>
                </div>
                <button
                  *ngIf="!bidFile"
                  mat-flat-button
                  (click)="openUploadModal('bidFile')"
                  color="success"
                  class="ml-auto lh-30 pl-3 pr-3"
                >
                  Add
                </button>
                <mat-chip-list *ngIf="bidFile" class="ml-auto">
                  <app-file-chip
                    [file]="bidFile"
                    [removable]="true"
                    (removeEvent)="removeFile('bidFile', bidFile)"
                  ></app-file-chip>
                </mat-chip-list>
              </div>
              <div class="m-portlet__body item d-flex align-items-center pt-2 pb-2 pl-3 pr-3">
                <p class="m-0 dkblue font-weight-semibold" [ngClass]="{ red: solicitationFileMissing }">
                  Solicitation File
                </p>
                <button
                  *ngIf="!solicitationFile"
                  mat-flat-button
                  (click)="openUploadModal('solicitationFile')"
                  color="success"
                  class="ml-auto lh-30 pl-3 pr-3"
                >
                  Add
                </button>
                <mat-chip-list *ngIf="solicitationFile" class="ml-auto">
                  <app-file-chip
                    [file]="solicitationFile"
                    [removable]="true"
                    (removeEvent)="removeFile('solicitationFile', solicitationFile)"
                  ></app-file-chip>
                </mat-chip-list>
              </div>
              <div class="m-portlet__body item pt-2 pb-2 pl-3 pr-3">
                <div class="row item item-last d-flex align-items-center pt-2 pb-2 pl-3 pr-3">
                  <p class="m-0 dkblue font-weight-semibold">Misc File</p>
                  <button
                    mat-flat-button
                    (click)="openUploadModal('miscFiles')"
                    color="success"
                    class="ml-auto lh-30 pl-3 pr-3"
                  >
                    Add
                  </button>
                </div>
                <mat-chip-list *ngIf="miscFiles.length !== 0" class="ml-auto">
                  <app-file-chip
                    *ngFor="let file of miscFiles"
                    [file]="file"
                    [removable]="true"
                    (removeEvent)="removeFile('miscFiles', file)"
                  ></app-file-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
          <div class="col-4 d-flex flex-column text-right">
            <div class="d-flex align-items-center mb-2" *ngIf="tax.value > 0">
              <h6 class="font-weight-semibold dkblue mb-0 mr-auto pl-3">Tax</h6>
              <mat-form-field appearance="outline" style="width: 120px">
                <span class="gray" matPrefix>$&nbsp;</span>
                <input
                  matInput
                  formControlName="tax"
                  (keyup)="taxChanged()"
                  (blur)="taxBlur()"
                  class="text-right"
                  placeholder="0.00"
                />
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center">
              <h6 class="font-weight-semibold dkblue m-0 pl-3">Total</h6>
              <h5 class="font-weight-semibold dkblue mb-0 ml-auto pr-2" style="line-height: 44px">
                {{ total | currency: 'USD' }}
              </h5>
            </div>
          </div>
        </div>
      </section>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="row pt-4 pb-4">
    <div class="col">
      <button mat-button mat-dialog-close class="gray full-width">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button color="accent" class="full-width" type="button" (click)="saveQuote()">Save Bid</button>
    </div>
  </mat-dialog-actions>
</div>
