<app-base-dialog [title]="dialogTitle" (closeDialog)="cancel()"></app-base-dialog>
<div id="solicitations-dialog" mat-dialog-content class="d-flex flex-column solicitations-dialog">
  <h4 class="primary mb-4">Warning: Solicitation required</h4>
  <h5 class="dkgray font-weight-normal" [innerHTML]="warningText"></h5>
  <span class="full-width border-top border-gray my-3"></span>
  <div class="d-flex flex-column">
    <h6 class="m-0 dkblue">Solicitation Types</h6>
    <p class="dkgray">Select all that apply</p>
    <mat-form-field appearance="outline" class="mb-4">
      <mat-select
        multiple
        (selectionChange)="updateSolicitationTypes($event.value)"
        [value]="selectedSolicitationTypes"
      >
        <mat-option *ngFor="let type of solicitationTypes" [value]="type.id" [disabled]="!isAdmin">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <h6 class="m-0 dkblue">Comment</h6>
    <p class="dkgray">Please include supplier names, dates, and any other information supporting these requirements</p>
    <app-editor class="mb-4" #editor placeholder="Comment" [disabled]="!isAdmin"></app-editor>
    <div class="d-flex px-1">
      <mat-chip-list class="d-inline-block align-middle">
        <button
          mat-stroked-button
          class="bg-white mr-3"
          color="accent"
          (click)="openSolicitationUploadModal(solicitation)"
          *ngIf="isAdmin"
        >
          Attach Files
        </button>
        <app-file-chip
          *ngFor="let file of solicitation?.files || []"
          [file]="file"
          [removable]="isAdmin"
          (removeEvent)="removeFileFromSolicitation(solicitation, file)"
        ></app-file-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <div class="row mb-2" style="margin-top: 0.5rem">
    <div class="col text-right">
      <button mat-button type="button" class="gray" (click)="cancel()">Close</button>
      <button mat-flat-button type="submit" color="success" class="ml-3" (click)="save()" *ngIf="isAdmin">
        Save Solicitation
      </button>
    </div>
  </div>
</div>
