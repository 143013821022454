<app-base-dialog title="Receipt" (closeDialog)="close()"></app-base-dialog>
<div
  id="request-receipt-dialog"
  data-test-id="request-receipt-dialog"
  #mainScreen
  class="request-receipt-dialog project-modal p-0"
>
  <div class="d-flex flex-column text-center mb-4">
    <h5 class="mb-2 dkblue font-weight-semibold">{{ request?.id ? 'Request' : 'Work Order' }} Created</h5>
    <h2 class="mb-2 dkblue font-weight-normal">
      {{ request?.code || workOrder?.code }}
    </h2>
  </div>
  <div class="d-flex flex-column mb-4">
    <h5 class="mb-3 dkblue font-weight-semibold">Summary</h5>
    <div class="d-flex mb-4">
      <mat-icon class="primary mr-4 mt-2">live_help</mat-icon>
      <div class="d-flex flex-column">
        <p class="mb-0 font-weight-semibold dkblue">Topic</p>
        <p class="mb-0 font-weight-normal dkgray">
          {{ request?.topic_group_name || workOrder?.topic_group_name
          }}{{ request?.topic_category_name || workOrder?.topic_category_name ? ' > ' : '' }}
          {{ request?.topic_category_name || workOrder?.topic_category_name
          }}{{ request?.topic_name || workOrder?.topic_name ? ' > ' : '' }}
          {{ request?.topic_name || workOrder?.topic_name }}
        </p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <mat-icon class="primary mr-4 mt-2">add_location_alt</mat-icon>
      <div class="d-flex flex-column">
        <p class="mb-0 font-weight-semibold dkblue">Location</p>
        <p class="mb-0 font-weight-normal dkgray">
          {{ request?.building_name || workOrder?.building_name
          }}{{ request?.floor_code || workOrder?.floor_code ? ' > ' : '' }}
          {{ request?.floor_code || workOrder?.floor_code
          }}{{ request?.department_name || workOrder?.department_name ? ' > ' : '' }}
          {{ request?.department_name || workOrder?.department_name }}
        </p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <mat-icon class="primary mr-4 mt-2">playlist_add_check</mat-icon>
      <div class="d-flex flex-column">
        <p class="mb-0 font-weight-semibold dkblue">Short Description</p>
        <p class="mb-4 font-weight-normal dkgray">
          {{ request?.short_description || workOrder?.title }}
        </p>
        <p class="mb-0 font-weight-semibold dkblue">Comments</p>
        <p class="mb-4 font-weight-normal dkgray" [innerHTML]="request?.summary || workOrder?.summary"></p>
        <p class="mb-0 font-weight-semibold dkblue">Attachments</p>
        <mat-chip-list class="d-inline-block align-middle mb-5">
          <app-file-chip *ngFor="let file of files" [file]="file"></app-file-chip>
        </mat-chip-list>
        <p class="mb-2 font-weight-semibold dkblue">Followers</p>
        <div class="d-flex flex-wrap">
          <!-- REQUESTER -->
          <app-profile-thumbnail
            class="mr-3 mb-3"
            [noBorder]="true"
            [height]="34"
            [width]="34"
            [userId]="currentUserId"
            matTooltip="First Last"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
            [overlayIconOpacity]="1"
            overlayIcon="grade"
            overlayIconColor="white"
            overlayIconBackground="bg-ltblue"
            overlayIconTooltip="Requester"
          >
          </app-profile-thumbnail>
          <!-- ADDITIONAL FOLLOWERS -->
          <app-profile-thumbnail
            *ngFor="let c of contacts"
            class="mr-3 mb-3"
            [noBorder]="true"
            [height]="34"
            [width]="34"
            [userId]="c?.id"
            matTooltip="{{ c?.first_name }} {{ c?.last_name }}"
            matTooltipPosition="above"
            [matTooltipShowDelay]="300"
          ></app-profile-thumbnail>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button mat-flat-button (click)="close(false, true)" type="button" color="success" class="full-width">
        New Request
      </button>
    </div>
    <div class="col">
      <button
        mat-flat-button
        type="button"
        color="primary"
        class="full-width"
        [routerLink]="[request?.id ? '/requests/' + request?.id : '/work-orders/' + workOrder?.id]"
        (click)="close(true)"
      >
        View {{ request?.id ? 'Request' : 'Work Order' }}
      </button>
    </div>
  </div>
</div>
