<div class="row full-width m-0">
  <app-profile-thumbnail
    *ngIf="user?.id"
    [noBorder]="!avatarBorder"
    class="pl-0 pr-3 ps-0 pe-3 col-auto"
    [height]="avatarSize"
    [width]="avatarSize"
    [userId]="user?.id"
    [overlayIconOpacity]="avatarOverlayIcon?.opacity"
    [overlayIcon]="avatarOverlayIcon?.icon"
    [overlayIconColor]="avatarOverlayIcon?.iconColor"
    [overlayIconBackground]="avatarOverlayIcon?.iconBackground"
    [overlayIconTooltip]="avatarOverlayIcon?.iconTooltip"
  ></app-profile-thumbnail>
  <div *ngIf="user?.id" class="col pl-0 pr-2 ps-0 pe-2">
    <p
      *ngIf="user?.first_name || user?.last_name"
      [ngStyle]="{ 'margin-top': avatarSize > 30 ? '2px' : '0px' }"
      class="mb-0 dkblue font-weight-semibold pointer hover-u lh-18 text-truncate"
      (click)="openUserProfileDialog()"
    >
      {{ user.first_name || '' }} {{ user.last_name || '' }}
    </p>
    <p
      [hidden]="!user[field]"
      class="m-0 dkgray lh-18 {{ truncate ? 'text-truncate' : 'text-wrap' }}"
      *ngFor="let field of additionalUserFields"
    >
      {{ user[field] }}
    </p>
    <p *ngIf="date" class="m-0 dkgray lh-18">
      {{ date | date: 'EEE, MMM d yyyy • h:mm aa' }}
    </p>
  </div>
</div>
