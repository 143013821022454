<div class="project-modal p-0">
  <div class="row align-items-center mb-3">
    <div class="col">
      <h5 class="dkblue m-0 font-weight-semibold">Approve Change Order</h5>
    </div>
    <div class="col-auto text-right">
      <a (click)="cancel()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <form [formGroup]="changeOrderApprovalFormGroup">
    <div class="row mt-3">
      <div class="col-12">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Funding Source</mat-label>
          <mat-select matNativeControl formControlName="funding_source">
            <mat-option value="null">-</mat-option>
            <mat-option *ngFor="let fs of fundingSources" [value]="fs">{{ fs.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <h6 class="m-0 mb-3 dkblue">Confirm Timeline Adjustment</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>How Many?</mat-label>
          <input matInput type="number" formControlName="time_change_amount" />
        </mat-form-field>
      </div>
      <div class="col-9">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Duration Type</mat-label>
          <mat-select matNativeControl formControlName="time_change_unit">
            <mat-option value="null">-</mat-option>
            <mat-option value="Day">Days</mat-option>
            <mat-option value="Week">Weeks</mat-option>
            <mat-option value="Month">Months</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5 mb-2" *ngIf="!viewOnly">
      <div class="col-6">
        <button mat-button type="button" color="accent" class="gray full-width" (click)="cancel()">Cancel</button>
      </div>
      <div class="col-6">
        <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">Approve</button>
      </div>
    </div>
    <div class="row mt-5 mb-2" *ngIf="viewOnly">
      <div class="col">
        <button mat-button type="button" color="accent" class="gray full-width" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>
