import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ResourceType } from 'src/app/enums';
import { ArfsService, GeneralLedgerService } from '../../services';
import { ArfInvoiceAmount, CostCode, SubCostCode, SubCostCodeBudget } from '../../types';

@Component({
  selector: 'app-cost-code-selection',
  templateUrl: './cost-code-selection.component.html',
  styleUrls: ['./cost-code-selection.component.scss'],
})
export class CostCodeSelectionComponent implements OnChanges {
  @Input() title: string = 'Cost Codes';
  @Input() parentResourceType: ResourceType;
  @Input() parentId: number;
  @Input() subCostCodeBudgets: SubCostCodeBudget[] = [];
  @Input() arfInvoiceAmounts: ArfInvoiceAmount[] = [];
  @Input() disabled = false;
  @Input() displayArfLink: boolean;
  @Input() allowAdditionalCodes: boolean;
  @Input() moduleId: number;
  @Output() closeDialog = new EventEmitter();

  constructor(private arfService: ArfsService, private generalLedgerService: GeneralLedgerService) {}

  arfLink: string;
  costCodes: CostCode[] = [];

  async ngOnChanges(): Promise<void> {
    this.arfInvoiceAmounts = [...[], ...this.arfInvoiceAmounts];
    this.arfLink =
      (this.displayArfLink && `/purchasing/arfs/${this.parentId}`) || `/projects/${this.parentId}/purchasing`;

    if (this.allowAdditionalCodes && this.moduleId && !this.costCodes?.length) {
      this.costCodes = await this.generalLedgerService.getCostCodesByModuleId(this.moduleId);
      for (const a of this.arfInvoiceAmounts) {
        if (a.sub_cost_code_budget?.sub_cost_code_id) {
          let subCostCode: SubCostCode;
          for (const c of this.costCodes) {
            subCostCode = subCostCode || c.sub_cost_codes.find((s) => s.id === a.sub_cost_code_budget.sub_cost_code_id);
          }
        }
      }
    }
  }

  addArfInvoiceAmount() {
    const arfInvoiceAmount = {
      index: this.arfInvoiceAmounts.length,
      amount: null,
      sub_cost_code_budget_id: null,
    };

    this.subCostCodeBudgets.push({});
    this.arfInvoiceAmounts.push(arfInvoiceAmount);
    this.arfService.arfInvoiceAmountUpdated.emit(arfInvoiceAmount);
  }

  filterCostCodes(subCostCodes: { oldSubCostCode: SubCostCode; newSubCostCode: SubCostCode }): void {
    for (const costCode of this.costCodes) {
      if (costCode.id === subCostCodes.newSubCostCode?.cost_code_id) {
        costCode.sub_cost_codes = costCode.sub_cost_codes.filter((s) => s.id !== subCostCodes.newSubCostCode.id);
      }

      if (costCode.id === subCostCodes.oldSubCostCode?.cost_code_id) {
        costCode.sub_cost_codes.push(subCostCodes.oldSubCostCode);
        costCode.sub_cost_codes = costCode.sub_cost_codes.sort((a, b) => a.code.localeCompare(b.code));
      }
    }
    this.costCodes = [...[], ...this.costCodes];
  }
}
