<app-base-dialog [title]="titleBarText" (closeDialog)="cancel()"></app-base-dialog>
<div id="confirmation-dialog" data-test-id="confirmation-dialog" class="confirmation-dialog pt-4">
  <div mat-dialog-content>
    <div class="row mb-4" *ngIf="headerText">
      <div class="col">
        <h4 class="m-0 dkblue font-weight-semibold">
          {{ headerText }}
        </h4>
      </div>
    </div>
    <!-- Informational Message -->
    <div class="row mb-4" *ngIf="descriptionText && descriptionText.length > 0">
      <div class="col-12">
        <h5
          class="dkgray font-weight-normal m-0"
          style="line-height: 1.8rem; white-space: pre-wrap"
          [innerHTML]="descriptionText"
        ></h5>
      </div>
    </div>
    <div class="row mx-0 mb-4">
      <div class="col-12 p-3 br-5 bg-shade-ltblue" *ngIf="accessoryContent?.length">
        <p class="dkblue lh-18 font-weight-normal m-0" style="white-space: pre-wrap" [innerHTML]="accessoryContent"></p>
      </div>
    </div>
    <app-editor
      [hidden]="!userInput"
      #editor
      placeholder="{{ data?.userInput?.placeholder || 'Any notes?' }}"
      [initialValue]="defaultText"
    ></app-editor>
    <div class="my-4">
      <mat-checkbox
        *ngIf="userInput?.checkboxText"
        [checked]="checkboxState === CheckBoxState.Checked"
        (change)="toggleCheckboxState()"
      >
        <p class="mb-0 dkblue font-weight-semibold">
          {{ userInput.checkboxText }}
        </p>
      </mat-checkbox>
    </div>
    <div class="col-lg-8" *ngIf="allowedFilesCount > 0 || requiredFilesCount > 0">
      <mat-chip-list class="d-inline-block align-middle mb-3">
        <app-file-chip *ngFor="let file of newFiles" [file]="file" [removable]="true" (removeEvent)="removeFile(file)">
        </app-file-chip>
      </mat-chip-list>
      <button
        (click)="openUploadModal()"
        mat-button
        color="accent"
        class="pl-2 pr-3 mr-4"
        *ngIf="newFiles.length < requiredFilesCount"
      >
        <mat-icon matTooltip="Upload File" matTooltipPosition="above" [matTooltipShowDelay]="300">
          attach_file
        </mat-icon>
        <span class="ml-1"> Attach Files </span>
      </button>
      <button
        (click)="openFile()"
        mat-button
        color="accent"
        class="pl-2 pr-3 mr-4"
        *ngIf="newFiles.length < allowedFilesCount"
      >
        <mat-icon matTooltip="Upload File" matTooltipPosition="above" [matTooltipShowDelay]="300">
          attach_file
        </mat-icon>
        <span class="ml-1"> Attach Files </span>
      </button>
    </div>
    <mat-chip-list class="m-2 d-inline-block align-middle" *ngIf="attachedFiles.length">
      <app-file-chip
        *ngFor="let file of attachedFiles"
        [file]="file"
        [downloadable]="true"
        [removable]="false"
      ></app-file-chip>
    </mat-chip-list>
    <mat-chip-list class="m-2 d-inline-block align-middle" *ngIf="linkedFiles.length">
      <app-file-chip
        *ngFor="let file of linkedFiles"
        [file]="file"
        [downloadable]="true"
        [removable]="false"
      ></app-file-chip>
    </mat-chip-list>
  </div>
  <div class="row align-items-center mt-3 mb-2">
    <div class="col" *ngIf="!hideCancelButton">
      <button mat-button (click)="cancel()" *ngIf="cancelButtonText" class="gray full-width">
        {{ cancelButtonText }}
      </button>
    </div>
    <div class="col" *ngIf="!hideConfirmationButton">
      <button mat-flat-button (click)="continue()" class="full-width" [disabled]="!isValidToSubmit()" color="accent">
        {{ confirmationButtonText }}
      </button>
    </div>
  </div>
</div>
