import { Component, OnInit } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { CompanyTypeEnum, CompanyVerificationStatus } from 'src/app/enums';
import { AuthService, CompanyService, ModalService, ProgressIndicatorService } from 'src/app/services';
import { Company, CompanyType, Preferences } from 'src/app/types';
import { PreferenceStorage } from 'src/app/utils';

interface CompanyListPreferences extends Preferences {
  showFilters: boolean;
  verificationStatuses: string[];
  companyTypes: string[];
  showInactive: boolean;
  fieldToSortBy: string;
  sortDirection: SortDirection;
}
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  showFilters = false;
  tableHeight;
  companies: Company[];
  companyTypes: CompanyType[] = [];
  CompanyType = CompanyTypeEnum;
  CompanyVerificationStatus = CompanyVerificationStatus;
  companyFields = [
    'id',
    'name',
    'type{name}',
    'phone',
    'address',
    'website',
    'primary_contact',
    'signator',
    'is_enabled',
    'contact_name',
    'contact_email',
    'verification_status',
  ];
  search;
  showInactive;
  verificationStatuses;
  companyTypeFilter: string[] = [];
  filteredCount = { count: 0 };
  pageSizeOptions: number[] = [5, 10, 20];
  pageSize = 20;
  startIndex = 0;
  endIndex = this.pageSize;
  selectedView;
  public fieldToSortBy: string;
  public sortDirection: SortDirection = 'desc';

  private preferences = new PreferenceStorage<CompanyListPreferences>('preferences_company_list', {
    showFilters: false,
    verificationStatuses: [],
    companyTypes: [],
    showInactive: false,
    sortDirection: 'asc',
    fieldToSortBy: 'name',
    version: 1,
  });

  constructor(
    public authService: AuthService,
    private modalService: ModalService,
    public companyService: CompanyService,
    private progressIndicatorService: ProgressIndicatorService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectedView = this.router.url;
    await this.companyService
      .getCompanyTypes(['id', 'name'])
      .toPromise()
      .then((companyTypes) => (this.companyTypes = companyTypes));
    await this.getCompanies();
    this.showFilters = this.preferences.currentValue.showFilters;
    this.verificationStatuses = this.preferences.currentValue.verificationStatuses;
    this.companyTypeFilter = this.preferences.currentValue.companyTypes;
    this.showInactive = this.preferences.currentValue.showInactive;
    this.sortDirection = this.preferences.currentValue.sortDirection;
    this.fieldToSortBy = this.preferences.currentValue.fieldToSortBy;
  }

  async getCompanies() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Retrieving companies..');
    this.companies = await this.companyService.getCompanies(this.companyFields, null, 2000).toPromise();
    this.progressIndicatorService.close();
  }

  pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    this.updatePreferences();
  }

  updatePreferences() {
    this.preferences.setPartialValue({
      showFilters: this.showFilters,
      verificationStatuses: this.verificationStatuses,
      companyTypes: this.companyTypeFilter,
      showInactive: this.showInactive,
      fieldToSortBy: this.fieldToSortBy,
      sortDirection: this.sortDirection,
    });
  }

  addEditCompany(company?) {
    this.modalService
      .openCompanyDialog({
        company: company,
      })
      .toPromise()
      .then((result) => {
        if (result) {
          this.getCompanies();
        }
      });
  }

  selectionChange(event) {
    this.router.navigateByUrl(event.value);
  }

  resetFilters() {
    this.search = null;
    this.verificationStatuses = null;
    this.companyTypeFilter = null;
    this.showInactive = false;
    this.updatePreferences();
  }

  showSupplierVerification(company: Company) {
    return (
      (this.authService.isAppAdmin || this.authService.isAC) &&
      (company.verification_status == 1 || company.verification_status == 4)
    );
  }

  get filtersApplied(): number {
    return (
      (this.verificationStatuses?.length > 0 ? 1 : 0) +
      (this.companyTypeFilter?.length > 0 ? 1 : 0) +
      (this.showInactive === true ? 1 : 0)
    );
  }
}
