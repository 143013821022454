import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    } else {
      const strippedValue = value.replace(/\D/g, '');
      if (strippedValue.length < 7 || strippedValue.length > 11) {
        return value;
      } else {
        if (strippedValue.length < 8) {
          // if strippedValue is 7 numbers long
          // insert '-' in third from left position
          return `${strippedValue.slice(0, 3)}-${strippedValue.slice(3)}`;
        } else if (strippedValue.length < 11) {
          // if strippedValue is between 8 and 10 numbers long
          // insert '()' around first three numbers
          // insert '-' in sixth from left position
          return `(${strippedValue.slice(0, 3)}) ${strippedValue.slice(3, 6)}-${strippedValue.slice(6)}`;
        } else {
          // if strippedValue is 10 numbers long
          // insert '()' around second through fourth numbers
          // insert '-' in seventh from left position
          return `${strippedValue.slice(0, 1)} (${strippedValue.slice(1, 4)}) ${strippedValue.slice(
            4,
            7
          )}-${strippedValue.slice(7)}`;
        }
      }
    }
  }
}
