import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment/moment';
import { FileAction, InvoiceStatus, ResourceType, TaskReviewStatus, UserType } from '../../enums';
import {
  AuthService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  UpdateReviewerService,
} from '../../services';
import { Arf, Invoice, TaskAccessoryData } from '../../types';
import { ProjectConstruction } from '../../workspaces/construction/types';
import { InvoiceReviewButtonsComponent } from '../invoice-review-buttons/invoice-review-buttons.component';

@Component({
  selector: 'app-invoice-menu',
  templateUrl: './invoice-menu.component.html',
  styleUrls: ['./invoice-menu.component.scss'],
})
export class InvoiceMenuComponent implements OnChanges {
  @Input() invoice: Invoice;
  @Input() arf: Arf;
  @Input() project: ProjectConstruction;
  @Input() display = true;
  @Input() displayInvoiceButton: boolean;
  @Input() alignButtonsRight = false;
  @Input() disableReview: boolean;
  @Output() public updateInvoice = new EventEmitter<Invoice | void>();
  @Output() public changeDialogOpenStatus = new EventEmitter<boolean>();

  @ViewChild('invoiceReviewButton', { static: true })
  public invoiceReviewButtonComponent: InvoiceReviewButtonsComponent;

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private updateReviewersService: UpdateReviewerService
  ) {}

  FileAction = FileAction;
  InvoiceStatus = InvoiceStatus;
  currentUser;

  ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();
  }

  async ngOnChanges(): Promise<void> {}

  get isAdmin(): boolean {
    return (
      this.authService.isAppAdmin ||
      !!this.authService.isProjectAdmin(
        this.project?.id || this.projectService.currentSelectedProjectId,
        this.project?.module_id || this.projectService.currentSelectedProject?.module_id
      ) ||
      this.authService.isUserWorkspaceAdmin(
        this.project?.module_id || this.projectService.currentSelectedProject?.module_id || this.arf?.module_id
      )
    );
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(
      this.project?.module_id || this.projectService.currentSelectedProject?.module_id || this.arf?.module_id
    );
  }
  get showReviewTaskOption(): boolean {
    return !!this.invoice?.approval_task_id && this.isWorkspaceStaff;
  }

  get showExportOption(): boolean {
    return [InvoiceStatus.ReadyForPayment].includes(this.invoice?.status_id);
  }

  get userIsPartOfAwardedCompany(): boolean {
    const currentUser = this.authService.getLoggedInUser();
    return this.invoice?.company_id === currentUser?.company_id;
  }

  get showChangeOption(): boolean {
    return (
      ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(this.invoice?.status_id) &&
        (this.userIsPartOfAwardedCompany ||
          this.invoice?.created_by_id === this.currentUser.id ||
          this.arf?.created_by_id === this.currentUser.id)) ||
      this.isAdmin
    );
  }

  async resetInvoiceReview(invoice: Invoice, accessoryData: TaskAccessoryData): Promise<Invoice> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Resetting Review...');

    const resetResult = await this.updateReviewersService.resetReview(
      invoice.approval_task_id,
      accessoryData,
      'Invoice has been reset'
    );

    this.progressIndicatorService.close();
    this.snackbar.open('Invoice reset!');

    return resetResult.invoice;
  }

  public async resetInvoice(invoice: Invoice): Promise<void> {
    this.changeDialogOpenStatus.emit(true);
    if (invoice?.approval_task_id) {
      let resetReview = false;
      let noneResetFieldChanged;
      let newInvoiceData;
      const newStatus = this.authService.currentUser.user_type_id === UserType.Vendor ? 'NEW' : 'RECEIVED';
      const accessoryData =
        (invoice.approval_task_accessory_data && JSON.parse(invoice.approval_task_accessory_data)) || null;
      const reviewStatus = accessoryData?.reviewChain?.find(
        (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
      );

      const reviewHasNotStarted = accessoryData?.reviewChain[0]?.status === TaskReviewStatus.Pending;
      const updateInvoice =
        !!this.authService.isAC ||
        invoice.status_id === InvoiceStatus.New ||
        reviewStatus?.status === TaskReviewStatus.Rejected ||
        reviewHasNotStarted;
      const res = await this.invoiceReviewButtonComponent.openInvoiceRevisionDialog(updateInvoice);
      const oldInvoice = { ...invoice };
      const newInvoice = { ...res };
      noneResetFieldChanged = res && Number(oldInvoice?.number) !== Number(newInvoice?.number);
      delete oldInvoice.number;
      delete newInvoice?.number;
      let noChangesMade = true;
      const dataToUpdate: Invoice = {};
      if (!updateInvoice) {
        for (const key in newInvoice) {
          if (newInvoice.hasOwnProperty(key)) {
            if (oldInvoice[key] !== newInvoice[key] && (oldInvoice[key] || newInvoice[key])) {
              if (moment(oldInvoice[key]).isValid() && isNaN(oldInvoice[key])) {
                const datesAreEqual = moment(oldInvoice[key]).isSame(newInvoice[key], 'day');
                if (!datesAreEqual) {
                  dataToUpdate[key] = newInvoice[key];
                  noChangesMade = false;
                }
              } else if (key === 'files') {
                if (!noneResetFieldChanged) {
                  noneResetFieldChanged = !!newInvoice[key].find(
                    (file) => !oldInvoice[key]?.find((f) => f.id === file.id)
                  );
                }
              } else {
                dataToUpdate[key] = newInvoice[key];
                noChangesMade = false;
              }
            }
          }
        }
      }
      if (!noChangesMade && !this.authService.isAC) {
        const descriptionText =
          invoice.status_id === InvoiceStatus.ReadyForPayment
            ? 'Please verify that this invoice has not been paid before continuing. Resetting this invoice will set the status back to NEW and allow it to be edited and reviewed again.'
            : `To edit this invoice, the status will be set back to ${newStatus} and the review will start over. Do you want to continue?`;

        resetReview = await this.modalService
          .openConfirmationDialog({
            titleBarText: `Warning`,
            headerText: `Reset Invoice`,
            descriptionText,
            confirmationButtonText: 'Reset Invoice',
          })
          .toPromise();
      } else if (this.authService.isAC && res && !reviewHasNotStarted) {
        resetReview = await this.modalService
          .openConfirmationChoiceDialog({
            title: `Warning`,
            heading: `Reset Invoice Review`,
            subHeading: `Changing one or more fields on this invoice may require it to be reviewed again. Please indicate whether you would like to reset this invoice's review process or save it in its current status.`,
            option1: { text: 'Reset Review', value: 1 },
            option2: { text: 'Save as is', value: 0 },
          })
          .toPromise();
      }
      let updatedInvoice;
      if (resetReview) {
        if (!updateInvoice) {
          await this.projectService.updateInvoice(invoice.id, dataToUpdate).toPromise();
        }
        const results = await this.resetInvoiceReview(invoice, accessoryData);
        if (results && !this.authService.isAC) {
          updatedInvoice = { ...res, ...results };
          delete updatedInvoice.id;
        }
        if (!!invoice.project_id && invoice.project_id > 0) {
          //Reopen Closed Project
          await this.projectService.updateProject(invoice.project_id, { status_id: 1 }, ['status']).toPromise();
        }
      } else if (
        (!updateInvoice && noChangesMade && noneResetFieldChanged) ||
        (this.authService.isAC && res && !reviewHasNotStarted)
      ) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Creating Note...');
        updatedInvoice = { ...invoice, ...res };
        updatedInvoice.revision = (Number(updatedInvoice?.revision) || 0) + 1;
        const invoiceData: Invoice = {
          revision: updatedInvoice?.revision,
        };
        if (noneResetFieldChanged) {
          invoiceData.number = updatedInvoice.number;
        }

        delete invoiceData.files;
        newInvoiceData = await this.projectService.updateInvoice(updatedInvoice?.id, invoiceData).toPromise();

        await this.createInvoiceNote(updatedInvoice);
      }

      if (
        (!noChangesMade && (reviewHasNotStarted || resetReview || (this.authService.isAC && !resetReview))) ||
        noneResetFieldChanged
      ) {
        this.updateInvoice.emit(newInvoiceData);
      }
      this.progressIndicatorService.close();
    } else {
      this.snackbar.open('This invoice can no longer be edited');
    }
    this.changeDialogOpenStatus.emit(false);
  }

  async removeInvoice(invoice: Invoice) {
    let descriptionText = 'Are you sure you want to delete this invoice? The associated task will also be deleted.';
    if (invoice.status_id === InvoiceStatus.ReadyForPayment) {
      descriptionText = `This invoice has been processed. ${descriptionText}`;
    } else if (invoice.status_id === InvoiceStatus.Received) {
      descriptionText = `This invoice is currently in review and has not been rejected. ${descriptionText}`;
    }

    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Invoice',
        headerText: 'Warning',
        confirmationButtonText: 'Delete Invoice',
        descriptionText,
      })
      .toPromise()
      .then(async (isConfirmed): Promise<void> => {
        if (isConfirmed) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Deleting Invoice...');
          await this.projectService
            .deleteInvoice(invoice.id)
            .toPromise()
            .then(() => {
              this.snackbar.open(`Invoice ${invoice.title} has been removed`);
              this.updateInvoice.emit();
            });
        }
      });
  }

  async createInvoiceNote(invoice: Invoice, updateRevision = false) {
    const coverLetter = [await this.invoiceReviewButtonComponent.coverLetter.exportInvoice(invoice, FileAction.Return)];

    const newNote = await this.projectService
      .createNote(
        ResourceType.Task,
        invoice?.approval_task_id,
        `This invoice has been edited by ${this.authService.currentUser?.first_name} ${this.authService.currentUser.last_name}`
      )
      .toPromise();
    if (newNote?.id) {
      await this.fileService.addFilesToNote(newNote, invoice?.approval_task_id, coverLetter, []);

      if (updateRevision) {
        this.projectService.updateInvoice(invoice.id, { revision: invoice.revision + 1 }).toPromise();
      }
    }
  }

  async viewTask(taskId: number): Promise<void> {
    this.changeDialogOpenStatus.emit(true);
    await this.modalService.openViewTaskModal(taskId).toPromise();
    this.changeDialogOpenStatus.emit(false);
  }

  updateInvoiceData(): void {
    this.updateInvoice.emit();
  }
}
