<div #mainScreen (window:resize)="onResize($event)" class="subcomponent ease pl-4 pr-4">
  <div *ngIf="overview" class="row">
    <div class="col">
      <section class="mb-2 pb-1" *ngIf="isWorkspaceStaff">
        <div class="row">
          <div class="col">
            <div class="row align-items-center mb-3">
              <div class="col">
                <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Tasks</h4>
              </div>
              <div class="col-auto text-right">
                <button
                  mat-button
                  class="pl-3 pr-3"
                  color="accent"
                  type="button"
                  style="line-height: 26px"
                  [routerLink]="['/projects/' + projectId + '/tasks']"
                >
                  Go to Tasks
                </button>
              </div>
            </div>
            <div class="m-portlet mb-3">
              <div class="m-portlet__body p-3">
                <div class="row">
                  <div class="col">
                    <div class="row mb-3">
                      <div class="col">
                        <h5 class="m-0 font-weight-semibold dkblue">Task Status</h5>
                      </div>
                      <div class="col-auto text-right">
                        <button
                          mat-button
                          [class.mat-flat-button]="taskStatusFilter === 'mine'"
                          class="ml-2 pl-2 pr-2"
                          color="accent"
                          type="button"
                          style="line-height: 22px"
                          (click)="changeTaskStatusFilter('mine')"
                        >
                          Mine
                        </button>
                        <button
                          mat-button
                          [class.mat-flat-button]="taskStatusFilter === 'all'"
                          class="ml-2 pl-2 pr-2"
                          color="accent"
                          type="button"
                          style="line-height: 22px"
                          (click)="changeTaskStatusFilter('all')"
                        >
                          All
                        </button>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div *ngIf="divWidth > 1375" class="col-4">
                        <div class="full-width" style="height: 215px">
                          <app-donut-chart style="height: 100%" [data]="taskStatusChartData"></app-donut-chart>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col">
                            <div
                              matRipple
                              (click)="openOverviewDialog('To-Do')"
                              class="small-stat d-flex aligh-items-start flex-column bg-gray br-10 full-width pointer"
                            >
                              <p class="mb-auto white font-weight-semibold">To-Do</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.todo }}
                              </h2>
                            </div>
                          </div>
                          <div class="col">
                            <div
                              matRipple
                              (click)="openOverviewDialog('Coming Due')"
                              class="small-stat d-flex aligh-items-start flex-column bg-orange br-10 full-width pointer"
                            >
                              <p class="mb-auto white font-weight-semibold">Coming Due</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.coming_due }}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div
                              matRipple
                              (click)="openOverviewDialog('Overdue')"
                              class="small-stat d-flex aligh-items-start flex-column bg-red br-10 full-width pointer"
                            >
                              <p class="mb-auto white font-weight-semibold">Overdue</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.overdue }}
                              </h2>
                            </div>
                          </div>
                          <div class="col">
                            <div
                              matRipple
                              (click)="openOverviewDialog('Complete')"
                              class="small-stat d-flex aligh-items-start flex-column bg-green br-10 full-width pointer"
                            >
                              <p class="mb-auto white font-weight-semibold">Complete</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.complete }}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col border-right">
                    <div class="row mb-3">
                      <div class="col">
                        <h5 class="m-0 font-weight-semibold dkblue">
                          Active Tasks
                          <span *ngIf="divWidth > 1305"> By User </span>
                        </h5>
                      </div>
                      <div class="col-auto text-right">
                        <button
                          mat-button
                          class="ml-2 pl-2 pr-2"
                          [class.mat-flat-button]="taskUserFilter === 'staff'"
                          color="accent"
                          type="button"
                          style="line-height: 22px"
                          (click)="changeTaskUserFilter('staff')"
                        >
                          Staff
                        </button>
                        <button
                          mat-button
                          class="ml-2 pl-2 pr-2"
                          [class.mat-flat-button]="taskUserFilter === 'tenants'"
                          color="accent"
                          type="button"
                          style="line-height: 22px"
                          (click)="changeTaskUserFilter('tenants')"
                        >
                          Tenants
                        </button>
                        <button
                          mat-button
                          class="ml-2 pl-2 pr-2"
                          [class.mat-flat-button]="taskUserFilter === 'vendors'"
                          color="accent"
                          type="button"
                          style="line-height: 22px"
                          (click)="changeTaskUserFilter('vendors')"
                        >
                          Suppliers
                        </button>
                      </div>
                      <div class="col-auto text-right border-left">
                        <button
                          mat-button
                          (click)="openOverviewDialog(null, true)"
                          class="pl-2 pr-2 gray"
                          type="button"
                          style="line-height: 22px"
                        >
                          Expand
                        </button>
                      </div>
                    </div>
                    <div class="row align-items-top">
                      <div *ngIf="divWidth > 1200" class="col-4">
                        <div class="full-width" style="height: 215px; position: relative">
                          <app-donut-chart [data]="taskUserChartData"></app-donut-chart>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          (click)="openOverviewDialog('To-Do', false, u)"
                          class="row pt-2 pb-2 ml-0 item align-items-center pointer"
                          *ngFor="let u of shownUserTasks; let i = index"
                          [hidden]="i > 3"
                        >
                          <div class="col-auto pl-0">
                            <app-profile-thumbnail class="d-inline-block align-middle mr-3" [userId]="u.id">
                            </app-profile-thumbnail>
                          </div>
                          <div class="col pl-0">
                            <h6 class="m-0 ltblue font-weight-semibold">{{ u.first_name }} {{ u.last_name }}</h6>
                          </div>
                          <div class="col-auto">
                            <h6 class="m-0 mr-2 dkblue font-weight-semibold">
                              {{ u.task_count }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-2 pb-1" *ngIf="!isWorkspaceStaff">
        <div class="row">
          <div class="col" [ngClass]="{ 'col-12': divWidth < 1240 }">
            <div class="row align-items-center mb-3">
              <div class="col">
                <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">My Stats</h4>
              </div>
              <div class="col-auto text-right"></div>
            </div>
            <div class="m-portlet mb-3">
              <div class="m-portlet__body p-3">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col">
                            <div
                              matRipple
                              class="small-stat d-flex aligh-items-start flex-column bg-gray br-10 full-width pointer"
                              [routerLink]="['/projects/' + projectId + '/tasks']"
                            >
                              <p class="mb-auto white font-weight-semibold">Tasks To-Do</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.todo }}
                              </h2>
                            </div>
                          </div>
                          <div class="col">
                            <div
                              matRipple
                              class="small-stat d-flex aligh-items-start flex-column bg-red br-10 full-width pointer"
                              [routerLink]="['/projects/' + projectId + '/tasks']"
                            >
                              <p class="mb-auto white font-weight-semibold">Tasks Overdue</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.overdue }}
                              </h2>
                            </div>
                          </div>
                          <div class="col">
                            <div
                              matRipple
                              class="small-stat d-flex aligh-items-start flex-column bg-green br-10 full-width pointer"
                              [routerLink]="['/projects/' + projectId + '/tasks']"
                            >
                              <p class="mb-auto white font-weight-semibold">Tasks Completed</p>
                              <mat-icon class="white">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 white">
                                {{ shownStatusTasks.complete }}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div *ngIf="isWorkspaceStaff" class="col">
                            <div
                              matRipple
                              class="
                                small-stat
                                d-flex
                                aligh-items-start
                                flex-column
                                border
                                item-last
                                br-10
                                full-width
                                pointer
                              "
                              [routerLink]="['/projects/' + projectId + '/invoices']"
                            >
                              <p class="mb-auto dkblue font-weight-semibold">My Invoices</p>
                              <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                              <h5 class="m-0 ltblue">
                                {{ overview.invoices_billed_amount | USDollar }}
                              </h5>
                            </div>
                          </div>
                          <div *ngIf="isWorkspaceStaff" class="col">
                            <div
                              matRipple
                              class="
                                small-stat
                                d-flex
                                aligh-items-start
                                flex-column
                                border
                                item-last
                                br-10
                                full-width
                                pointer
                              "
                              [routerLink]="['/projects/' + projectId + '/change-orders']"
                            >
                              <p class="mb-auto dkblue font-weight-semibold">My Change Orders</p>
                              <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                              <h5 class="m-0 ltblue">
                                {{ overview.change_order_amount | USDollar }}
                              </h5>
                            </div>
                          </div>
                          <div *ngIf="isWorkspaceStaff" class="col">
                            <div
                              class="border br-10 full-width p-3 pointer"
                              style="height: 85px"
                              [routerLink]="['/projects/' + projectId + '/files']"
                            >
                              <p class="mb-auto dkblue font-weight-semibold">My Files</p>
                              <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                              <h2 class="m-0 ltblue">
                                {{ overview.file_count }}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isSupplier" class="col-4" [ngClass]="{ 'col-5': divWidth < 1430, 'col-12': divWidth < 1240 }">
            <div class="row align-items-center mb-3">
              <div class="col">
                <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Timeline</h4>
              </div>
            </div>
            <div class="m-portlet mb-4">
              <div class="m-portlet__body p-3 no-hover">
                <div class="row mb-3 align-items-center">
                  <div class="col">
                    <div
                      [attr.matRipple]="isNotTenant"
                      class="small-stat d-flex aligh-items-start flex-column border item-last br-10 full-width"
                      [ngClass]="{ pointer: isNotTenant }"
                      [routerLink]="isNotTenant ? ['/projects/' + projectId + '/schedule'] : null"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Status</p>
                      <mat-icon hide="isNotTenant" class="ltblue mr-3">keyboard_arrow_right</mat-icon>
                      <h5 class="m-0" [ngClass]="{ green: overview.on_schedule, red: !overview.on_schedule }">
                        {{ overview.on_schedule ? 'On' : 'Behind' }} Schedule
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      [attr.matRipple]="isNotTenant"
                      class="small-stat d-flex aligh-items-start flex-column border item-last br-10 full-width"
                      [ngClass]="{ pointer: isNotTenant }"
                      [routerLink]="isNotTenant ? ['/projects/' + projectId + '/schedule'] : null"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Est. Construction Start</p>
                      <mat-icon *ngIf="isNotTenant" class="ltblue mr-3">keyboard_arrow_right</mat-icon>
                      <h5 *ngIf="overview.construction_start_date" class="m-0 ltblue">
                        {{ overview.construction_start_date | date: 'MMM dd, yyyy' }}
                      </h5>
                      <h5 *ngIf="!overview.construction_start_date" class="m-0 gray">Not Set</h5>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <div
                      [attr.matRipple]="isNotTenant"
                      class="small-stat d-flex aligh-items-start flex-column border item-last br-10 full-width"
                      [ngClass]="{ pointer: isNotTenant }"
                      (click)="
                        isNotTenant && latestTimeline && !latestTimeline.downloading
                          ? downloadFile(latestTimeline)
                          : null
                      "
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Project Timeline</p>
                      <mat-icon
                        [ngClass]="{ ltblue: latestTimeline, ltgray: !latestTimeline }"
                        *ngIf="isNotTenant && (!latestTimeline || !latestTimeline.downloading)"
                        class="mr-3"
                        >get_app</mat-icon
                      >
                      <mat-spinner
                        *ngIf="latestTimeline && latestTimeline.downloading"
                        color="accent"
                        [diameter]="20"
                      ></mat-spinner>
                      <h5 class="m-0" [ngClass]="{ ltblue: latestTimeline, ltgray: !latestTimeline }">
                        {{ latestTimeline ? 'Download' : 'None' }}
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      [attr.matRipple]="isNotTenant"
                      class="small-stat d-flex aligh-items-start flex-column border item-last br-10 full-width"
                      [ngClass]="{ pointer: isNotTenant }"
                      [routerLink]="isNotTenant ? ['/projects/' + projectId + '/schedule'] : null"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Estimated Completion</p>
                      <mat-icon *ngIf="isNotTenant" class="ltblue mr-3">keyboard_arrow_right</mat-icon>
                      <h5 class="m-0 ltblue">
                        {{ overview.end_date | date: 'MMM dd, yyyy' }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-2 pb-1" *ngIf="isWorkspaceStaff">
        <div class="row">
          <div class="col" [ngClass]="{ 'col-12': divWidth < 1240 }">
            <div class="row align-items-center mb-3">
              <div class="col">
                <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Budget</h4>
              </div>
              <div class="col-auto text-right">
                <button
                  mat-button
                  class="ml-3 pl-3 pr-3"
                  color="accent"
                  type="button"
                  style="line-height: 26px"
                  [routerLink]="['/projects/' + projectId + '/budget']"
                >
                  Go to Budget
                </button>
              </div>
            </div>
            <div class="m-portlet mb-1">
              <div class="m-portlet__body p-3">
                <div class="row">
                  <div *ngIf="divWidth > 1430" class="col-lg-4 col-md-3">
                    <div class="full-width" style="height: 215px">
                      <app-bar-chart [data]="budgetChartData" style="height: 100%"></app-bar-chart>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row mb-3">
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                          [routerLink]="['/projects/' + projectId + '/peb']"
                        >
                          <p class="mb-auto dkblue font-weight-semibold">Prelim Budget</p>
                          <h5 class="m-0 dkblue">
                            {{ overview.peb_amount | USDollar }}
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                          [routerLink]="['/projects/' + projectId + '/change-orders']"
                        >
                          <p class="mb-auto dkblue font-weight-semibold">CO Net</p>
                          <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                          <h5 class="m-0 dkblue">
                            {{ overview.change_order_amount | USDollar }}
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                          [routerLink]="['/projects/' + projectId + '/bids']"
                        >
                          <p class="mb-auto dkblue font-weight-semibold">Total Contract Sum</p>
                          <mat-icon *ngIf="overview.total_contract_cost > overview.peb_amount" class="red"
                            >arrow_upward
                          </mat-icon>
                          <mat-icon *ngIf="overview.total_contract_cost < overview.peb_amount" class="green">
                            arrow_downward</mat-icon
                          >
                          <mat-icon *ngIf="overview.total_contract_cost === overview.peb_amount" class="ltgray">
                            fiber_manual_record</mat-icon
                          >

                          <h6 class="m-0 dkgray" *ngIf="!costPerSF && !overview.square_footage">SF Not Set</h6>
                          <h6 class="m-0 dkgray" *ngIf="!costPerSF && overview.square_footage">No costs</h6>
                          <span class="dkgray" *ngIf="costPerSF"> {{ costPerSF | USDollar }} / SF </span>
                          <h5 class="m-0 dkblue">
                            {{ overview.total_contract_cost | USDollar }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                          [routerLink]="['/projects/' + projectId + '/invoices']"
                        >
                          <p class="mb-auto dkblue font-weight-semibold">Billed-To-Date</p>
                          <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                          <h5 class="m-0 dkblue">
                            {{ overview.invoices_billed_amount | USDollar }}
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                        >
                          <p class="mb-auto dkblue font-weight-semibold">Remaining Contingency</p>
                          <mat-icon class="ltblue"></mat-icon>
                          <h5 class="m-0 dkblue">
                            {{ overview.contingency_remaining_amount | USDollar }}
                          </h5>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          matRipple
                          class="
                            small-stat
                            d-flex
                            aligh-items-start
                            flex-column
                            border
                            br-10
                            full-width
                            item item-last
                            pointer
                          "
                          [routerLink]="['/projects/' + projectId + '/invoices']"
                        >
                          <p class="mb-auto dkblue font-weight-semibold">Invoices</p>
                          <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                          <h6 class="red m-0" *ngIf="overview.invoices_unreceived_count > 0">
                            <b>{{ overview.invoices_unreceived_count }}</b> New
                          </h6>
                          <h5 class="m-0 dkblue">
                            <span>{{ overview.invoices_paid_count }}</span> of
                            <span>{{ overview.invoices_count }}</span>
                            <small>&nbsp;Processed</small>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="mb-4 dkblue font-italic footnote">
              * Total Project Cost includes all awarded bids and any added costs of approved change orders
            </span>
          </div>
          <div *ngIf="!isSupplier" class="col-4" [ngClass]="{ 'col-5': divWidth < 1430, 'col-12': divWidth < 1240 }">
            <div class="row align-items-center mb-3">
              <div class="col">
                <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Timeline</h4>
              </div>
              <div class="col-auto text-right">
                <button
                  mat-button
                  class="ml-3 pl-3 pr-3"
                  color="accent"
                  type="button"
                  style="line-height: 26px"
                  [routerLink]="['/projects/' + projectId + '/schedule']"
                >
                  Go to Timeline
                </button>
              </div>
            </div>
            <div class="m-portlet mb-4">
              <div class="m-portlet__body p-3 no-hover">
                <div class="row mb-3 align-items-center">
                  <div class="col">
                    <div
                      matRipple
                      class="
                        small-stat
                        d-flex
                        aligh-items-start
                        flex-column
                        border
                        item item-last
                        br-10
                        full-width
                        pointer
                      "
                      [routerLink]="['/projects/' + projectId + '/schedule']"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Status</p>
                      <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                      <h5 class="m-0" [ngClass]="{ green: overview.on_schedule, red: !overview.on_schedule }">
                        {{ overview.on_schedule ? 'On' : 'Behind' }} Schedule
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      matRipple
                      class="
                        small-stat
                        d-flex
                        aligh-items-start
                        flex-column
                        border
                        item item-last
                        br-10
                        full-width
                        pointer
                      "
                      [routerLink]="['/projects/' + projectId + '/schedule']"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Est. Construction Start</p>
                      <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                      <h5 *ngIf="overview.construction_start_date" class="m-0 ltblue">
                        {{ overview.construction_start_date | date: 'MMM dd, yyyy' }}
                      </h5>
                      <h5 *ngIf="!overview.construction_start_date" class="m-0 gray">Not Set</h5>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <div
                      matRipple
                      class="
                        small-stat
                        d-flex
                        aligh-items-start
                        flex-column
                        border
                        item item-last
                        br-10
                        full-width
                        pointer
                      "
                      (click)="latestTimeline && !latestTimeline.downloading ? downloadFile(latestTimeline) : null"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Project Timeline</p>
                      <mat-icon
                        [ngClass]="{ ltblue: latestTimeline, ltgray: !latestTimeline }"
                        *ngIf="!latestTimeline || !latestTimeline.downloading"
                        >get_app</mat-icon
                      >
                      <mat-spinner
                        *ngIf="latestTimeline && latestTimeline.downloading"
                        color="accent"
                        [diameter]="20"
                      ></mat-spinner>
                      <h5 class="m-0" [ngClass]="{ ltblue: latestTimeline, ltgray: !latestTimeline }">
                        {{ latestTimeline ? 'Download' : 'None' }}
                      </h5>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      matRipple
                      class="
                        small-stat
                        d-flex
                        aligh-items-start
                        flex-column
                        border
                        item item-last
                        br-10
                        full-width
                        pointer
                      "
                      [routerLink]="['/projects/' + projectId + '/schedule']"
                    >
                      <p class="mb-auto dkblue font-weight-semibold">Estimated Completion</p>
                      <mat-icon class="ltblue">keyboard_arrow_right</mat-icon>
                      <h5 class="m-0 ltblue">
                        {{ overview.end_date | date: 'MMM dd, yyyy' }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-2 pb-1">
        <div class="row mb-3">
          <div class="col">
            <h4 class="m-0 font-weight-bold dkblue pointer d-inline-block align-middle mr-5">Updates</h4>
            <!-- <h4 class="m-0 font-weight-bold ltgray pointer d-inline-block align-middle mr-5">
              Activity
            </h4> -->
          </div>
          <div class="col-auto text-right">
            <button
              *ngIf="isWorkspaceStaff || authService.isTenant"
              (click)="addProjectUpdate()"
              mat-flat-button
              class="ml-3 pl-3 pr-3"
              color="success"
              type="button"
              style="line-height: 26px"
            >
              + Update
            </button>
          </div>
        </div>

        <div *ngFor="let u of projectUpdates" class="project-update m-portlet mb-3">
          <div class="m-portlet__body pt-3 pr-4 pl-4 pb-4">
            <div class="row align-items-top">
              <div class="col d-flex flex-column pl-3">
                <div class="d-flex align-items-center">
                  <app-profile-info
                    class="mr-auto"
                    [user]="{
                      id: u.created_by_id,
                      first_name: u.created_by_first_name,
                      last_name: u.created_by_last_name
                    }"
                    [date]="u.created_datetime"
                  ></app-profile-info>
                  <p
                    class="mb-0 mr-3 font-italic"
                    [ngClass]="{ green: u?.notify_followers === 1, gray: u?.notify_followers === 0 }"
                  >
                    <small>
                      <i *ngIf="u?.notify_followers === 1" class="fa fa-check mr-1"></i>
                      {{ u?.notify_followers === 1 ? 'Notifications Sent' : 'Notifications Not Sent' }}
                    </small>
                  </p>
                  <p
                    *ngIf="u.project_health_type_id || u.is_original_request"
                    class="mb-0 pl-2 pr-2 br-4 mr-2 bg-green white font-weight-semibold d-inline-block align-middle"
                    [ngClass]="{
                      'bg-ltblue': u.is_original_request,
                      'bg-green': u.project_health_type_id === 1,
                      'bg-orange': u.project_health_type_id === 2,
                      'bg-red': u.project_health_type_id === 3
                    }"
                    style="font-size: 11px; line-height: 20px"
                  >
                    {{ u.is_original_request ? 'Original Request' : u.project_health_type_name }}
                  </p>
                  <p
                    *ngIf="u.access.indexOf(2) > -1 && u.access.indexOf(3) > -1"
                    class="
                      mb-0
                      pl-2
                      pr-2
                      br-4
                      mr-2
                      white
                      bg-gray
                      opacity8
                      font-weight-semibold
                      d-inline-block
                      align-middle
                    "
                    style="font-size: 11px; line-height: 20px"
                  >
                    Everyone
                  </p>
                  <p
                    *ngIf="u.access.indexOf(2) === -1 && u.access.indexOf(3) === -1"
                    class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-orange font-weight-semibold d-inline-block align-middle"
                    style="font-size: 11px; line-height: 20px"
                  >
                    UHAT/1Call Only
                  </p>
                  <!-- <p *ngIf="u.access.indexOf(2) > -1 && u.access.indexOf(3) === -1" class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-green font-weight-semibold d-inline-block align-middle"
                    style="font-size: 11px; line-height: 20px;">
                    Tenants
                  </p>
                  <p *ngIf="u.access.indexOf(3) > -1 && u.access.indexOf(2) === -1" class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-ltblue font-weight-semibold d-inline-block align-middle"
                    style="font-size: 0.9rem; line-height: 22px;">
                    Suppliers
                  </p> -->
                  <p
                    *ngIf="isStaff && u.access.indexOf(2) === -1 && u.access.indexOf(3) === -1"
                    class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-orange font-weight-semibold d-inline-block align-middle"
                    style="font-size: 11px; line-height: 20px"
                  >
                    UHAT/1Call Only
                  </p>
                  <button
                    [matMenuTriggerFor]="update_menu"
                    mat-icon-button
                    color="accent"
                    type="button"
                    [disabled]="!u.id || u.created_by_id !== currentUser.id"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #update_menu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      *ngIf="u.id && u.created_by_id === currentUser.id"
                      (click)="editProjectUpdate(u)"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit Update</span>
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="u.id && u.created_by_id === currentUser.id && isWorkspaceStaff"
                      (click)="deleteUpdate(u)"
                    >
                      <mat-icon>delete</mat-icon>
                      <span>Delete Update</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="d-flex flex-column update-content mt-3">
                  <div
                    class="ml-5 dkblue"
                    [innerHTML]="u.message"
                    appTextCollapseExpand
                    maxHeight="100"
                    (heightExceededEvent)="u.showCollapseMessageViewButton = $event"
                    [showMore]="!u.collapseMessageView"
                  ></div>
                  <p
                    id="collapse-detail-view-button"
                    class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2 ml-5"
                    *ngIf="u.showCollapseMessageViewButton === true"
                    (click)="u.collapseMessageView = !u.collapseMessageView"
                  >
                    <i class="fas {{ u.collapseMessageView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
                    {{ u.collapseMessageView === true ? 'Show More' : 'Show Less' }}
                  </p>
                  <app-file-chip-list
                    class="ml-5 pt-3"
                    *ngIf="u?.files?.length"
                    [files]="u.files"
                    [diameter]="40"
                  ></app-file-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="col-auto" [ngClass]="{ 'col-12': divWidth < 1001, 'overview-side': divWidth > 1000 }">
      <section class="mb-2 pb-1" *ngIf="isWorkspaceStaff">
        <div class="row mb-3">
          <div class="col">
            <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Quick Links</h4>
          </div>
          <div class="col-auto text-right">
            <!-- <button mat-button class="ml-3 pl-3 pr-3" color="accent" type="button" style="line-height:26px;">
              Details
            </button> -->
          </div>
        </div>
        <div class="m-portlet br-10 mb-4">
          <div class="m-portlet__body p-3">
            <div class="row mb-2">
              <div class="col-4">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/tasks']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">Task</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 pr-0">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/files']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">File</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/invoices']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">Invoice</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/rfi']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">RFI</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 pl-0 pr-0">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/addendums']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">Addendum</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div
                  matRipple
                  class="p-2 pointer item item-last br-5"
                  [routerLink]="['/projects/' + projectId + '/proposal-requests']"
                >
                  <div class="row">
                    <div class="col">
                      <mat-icon class="ltblue d-inline-block align-middle mr-2"> add_box </mat-icon>
                      <h6 class="m-0 ltblue d-inline-block align-middle font-weight-semibold">PR</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section *ngIf="projectKeyControls?.length && isWorkspaceStaff" class="d-flex flex-column">
        <div class="d-flex mb-2">
          <h5 class="m-0 dkblue font-weight-bold mr-auto">Key Controls</h5>
          <!-- <button (click)="viewKeyControls()" mat-button color="accent" class="lh-28 px-3">
            View
          </button> -->
        </div>
        <div class="m-portlet cursor-pointer">
          <div
            *ngFor="let projectKeyControl of projectKeyControls; let i = index"
            class="m-portlet__body item d-flex align-items-center p-2 pr-3"
            (click)="viewKeyControls(projectKeyControl.id)"
          >
            <app-key-control-icon-badge class="mr-2" [task]="projectKeyControl?.task"></app-key-control-icon-badge>
            <div class="d-flex flex-column mr-auto">
              <p class="m-0 font-weight-semibold dkblue hover-u pointer lh-16">
                {{ projectKeyControl?.key_control_template?.key_control_name }}
              </p>
              <!-- DATE COMPLETED W/ NAME -->
              <p class="dkgray mb-1" *ngIf="projectKeyControl?.task?.status_id === TASK_STATUS.Complete">
                Completed on {{ formatDate(projectKeyControl?.completed_datetime) }} by
                <span
                  class="ltblue hover-u pointer"
                  (click)="$event.stopPropagation(); openUserProfilePreview(projectKeyControl?.completed_by?.id)"
                >
                  {{ projectKeyControl?.completed_by?.first_name }}
                  {{ projectKeyControl?.completed_by?.last_name }}</span
                >
              </p>
            </div>
            <app-profile-thumbnail
              *ngFor="let reviewer of projectKeyControl?.key_control_reviewers"
              height="32"
              width="32"
              class="mr-1"
              [userId]="reviewer.id"
              [overlayIconOpacity]="1"
              [overlayIcon]="+reviewer.status === +TASK_REVIEW_STATUS.Approved ? 'check' : 'sync'"
              [overlayIconColor]="'white'"
              [overlayIconBackground]="+reviewer.status === +TASK_REVIEW_STATUS.Approved ? 'bg-green' : 'bg-red'"
              (click)="$event.stopPropagation(); openUserProfilePreview(reviewer.id)"
              [hidden]="!reviewer.status"
            >
            </app-profile-thumbnail>
          </div>
        </div>
      </section>
      <section class="mb-2 pb-1">
        <div class="row align-items-center mb-3">
          <div class="col">
            <h4 class="m-0 font-weight-bold dkblue d-inline-block align-middle">Project Details</h4>
          </div>
          <div class="col-auto">
            <button
              *ngIf="isWorkspaceStaff"
              mat-button
              class="pl-3 pr-3"
              color="accent"
              type="button"
              style="line-height: 26px"
              (click)="openProjectDetailsDialog()"
            >
              <span *ngIf="isWorkspaceStaff">Edit Details</span>
              <span *ngIf="!isWorkspaceStaff">View Details</span>
            </button>
          </div>
        </div>
        <div class="m-portlet br-10 mb-4">
          <div class="row m-portlet__body p-0">
            <div class="col-12 border-right">
              <div class="row item align-items-top p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Title</p>
                </div>
                <div class="col-9 pl-0">
                  <p class="m-0 font-weight-semibold dkblue">
                    {{ overview.title }}
                  </p>
                </div>
              </div>
              <div class="row item align-items-top p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Scope</p>
                </div>
                <div class="col-9 pl-0">
                  <p [innerHTML]="overview.scope_of_work" class="m-0 font-weight-semibold dkblue"></p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Type</p>
                </div>
                <div class="col-9 pl-0">
                  <p class="m-0 font-weight-semibold dkblue">
                    {{ overview.request_type_name }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Cost Codes</p>
                </div>
                <div class="col-9 pl-0">
                  <p class="m-0 font-weight-semibold dkblue">
                    {{ project?.cost_codes }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">CAPX ID</p>
                </div>
                <div class="col-9 pl-0">
                  <p
                    *ngIf="project?.capx_budget"
                    class="m-0 font-weight-semibold ltblue pointer"
                    (click)="openCAPXpanel(project?.capx_budget, $event)"
                  >
                    {{ project?.capx_budget?.capx_id }} | {{ project?.capx_budget?.description }}
                  </p>
                </div>
              </div>
              <div *ngIf="project?.parent" class="row item align-items-top p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Parent Project</p>
                </div>
                <div class="col-9 pl-0">
                  <p
                    [routerLink]="['/projects/' + project?.parent?.id]"
                    class="m-0 font-weight-semibold ltblue d-inline-block align-middle pointer"
                  >
                    {{ parentProject }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">PM</p>
                </div>
                <div class="col-9 pl-0" (click)="openUserProfilePreview(overview.project_manager.id)">
                  <app-profile-thumbnail
                    [width]="28"
                    [height]="28"
                    class="d-inline-block align-middle mr-2 pointer"
                    [userId]="overview.project_manager.id"
                  ></app-profile-thumbnail>
                  <p class="m-0 font-weight-semibold ltblue d-inline-block align-middle pointer">
                    {{ overview.project_manager.first_name }}
                    {{ overview.project_manager.last_name }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">CM</p>
                </div>
                <div class="col-9 pl-0" (click)="openUserProfilePreview(overview.workspace_manager.id)">
                  <app-profile-thumbnail
                    [width]="28"
                    [height]="28"
                    class="d-inline-block align-middle mr-2 pointer"
                    [userId]="overview.workspace_manager.id"
                  ></app-profile-thumbnail>
                  <p class="m-0 font-weight-semibold d-inline-block align-middle ltblue pointer">
                    {{ overview.workspace_manager.first_name }}
                    {{ overview.workspace_manager.last_name }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Arch</p>
                </div>
                <div class="col-9 pl-0" (click)="openUserProfilePreview(overview.architect.id)">
                  <app-profile-thumbnail
                    [width]="28"
                    [height]="28"
                    class="d-inline-block align-middle mr-2 pointer"
                    [userId]="overview.architect.id"
                    *ngIf="project.is_architect_required"
                  ></app-profile-thumbnail>
                  <p
                    class="m-0 font-weight-semibold d-inline-block align-middle ltblue pointer"
                    *ngIf="project.is_architect_required"
                  >
                    {{ overview.architect.first_name }} {{ overview.architect.last_name }}
                  </p>
                  <p
                    class="m-0 font-weight-semibold d-inline-block align-middle ltblue pointer"
                    *ngIf="!project.is_architect_required"
                  >
                    Not Needed
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">CFMO</p>
                </div>
                <div class="col-9 pl-0" (click)="openUserProfilePreview(overview.cfmo.id)">
                  <app-profile-thumbnail
                    *ngIf="overview.cfmo.id"
                    [width]="28"
                    [height]="28"
                    class="d-inline-block align-middle mr-2 pointer"
                    [userId]="overview.cfmo.id"
                  ></app-profile-thumbnail>
                  <p class="m-0 font-weight-semibold d-inline-block align-middle ltblue pointer">
                    {{ overview.cfmo.first_name }} {{ overview.cfmo.last_name }}
                  </p>
                </div>
              </div>
              <div class="row item item align-items-center p-3" *ngIf="isWorkspaceStaff">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Engineers</p>
                </div>
                <div class="col-9 pl-0">
                  <app-profile-thumbnail
                    *ngFor="let e of overview.engineers"
                    [width]="28"
                    [height]="28"
                    class="d-inline-block align-middle mr-2 pointer"
                    [userId]="e.id"
                    (click)="openUserProfilePreview(e.id)"
                  ></app-profile-thumbnail>
                </div>
              </div>
              <div class="row item align-items-top p-3">
                <div class="col-3">
                  <p class="m-0 font-weight-semibold gray">Request Method</p>
                </div>
                <div class="col-9 pl-0">
                  <p class="m-0 font-weight-semibold dkblue">
                    {{ requestMethod?.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
