<div class="row section">
  <div class="col-12 selection-container">
    <h4 class="dkblue">
      {{ label }}
    </h4>
    <br />
    <mat-radio-group
      [disabled]="disabled"
      class="col-12"
      aria-label="Select an option"
      (change)="selectionChange($event)"
      value="{{ getStartingValue() }}"
    >
      <mat-radio-button value="yes">{{ getValue1Label() }}</mat-radio-button>
      <mat-radio-button value="no">{{ getValue2Label() }}</mat-radio-button>
    </mat-radio-group>
    <br />
    <br />
    <div *ngIf="selectedValue === 'yes' || defaultValue">
      <mat-form-field class="col-12 pb-0" appearance="outline" *ngIf="requireInputOnYes">
        <mat-label>{{ inputPlaceholder }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="detailInputValue"
          placeholder="Explanation"
          [disabled]="disabled"
          (change)="detailTextChange($event)"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
