<div class="project-modal modal-wide">
  <div class="row align-items-center mb-4">
    <div class="col">
      <h6 class="gray m-0 font-weight-semibold">
        {{ data.title || 'Select Departments' }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <a (click)="close()" class="close-modal">
        <i class="la la-times ltgray"></i>
      </a>
    </div>
  </div>
  <div class="row dialog-form">
    <div class="col mb-3">
      <!-- Search form -->
      <mat-form-field appearance="outline" class="full-width">
        <input matInput [(ngModel)]="searchInput" placeholder="Search Departments" />
        <mat-icon *ngIf="searchInput" (click)="searchInput = ''" class="ltgray pointer" matSuffix>cancel</mat-icon>
      </mat-form-field>
      <!-- <input class="form-control light-outline" type="text" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput"> -->
    </div>
    <!-- <div class="row justify-content-end pb-2">
      <div class="col-9 float-right"><span style="float: right">{{getUserSelectionNumberText()}}</span></div>
    </div> -->
  </div>
  <div class="row">
    <div class="col">
      <mat-selection-list *ngIf="allDepartments.length > 0">
        <mat-list-option
          *ngFor="let department of allDepartments | filter: searchInput:['name']"
          [value]="department"
          [selected]="departmentIsSelected(department)"
          (click)="toggleDepartmentSelection(department)"
        >
          <div class="d-inline-block">
            <!--            <app-profile-thumbnail class="d-inline-block" [userId]="company.id"></app-profile-thumbnail>-->
            <span class="ml-3" style="height: 100%; vertical-align: sub">{{ department.name }}</span>
          </div>
        </mat-list-option>
      </mat-selection-list>
      <!-- No Files For Project Panel -->
      <div *ngIf="allDepartments.length <= 0" class="no-items-panel">
        <div class="no-items-panel-content">
          <h1>No Departments Found</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 align-items-center">
    <div class="col">
      <h6 class="m-0 ltblue font-weight-semibold">
        {{ getCompanySelectionNumberText() }}
      </h6>
    </div>
    <div class="col-auto text-right">
      <button mat-button *ngIf="!processing" (click)="close()" class="gray mr-2">Cancel</button>
      <button mat-flat-button color="success" [disabled]="selectedDepartments.length < 1" (click)="submit()">
        Add Departments
      </button>
    </div>
  </div>
</div>
