<main id="department_list" #department_list>
  <div class="d-flex align-items-center mb-3">
    <div>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedView" (selectionChange)="selectionChange($event)">
          <mat-option value="/directory">Accounts</mat-option>
          <mat-option value="/directory/companies">Companies</mat-option>
          <mat-option value="/directory/departments">Departments</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ml-auto">
      <mat-slide-toggle color="accent" [(ngModel)]="showInactive">
        <span class="font-weight-semibold">Show Inactive Departments</span>
      </mat-slide-toggle>
      <mat-form-field appearance="outline" class="m-0 ml-4">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <input
          matInput
          placeholder="Search Departments"
          [(ngModel)]="search"
          (ngModelChange)="paginator.pageIndex = 0"
        />
        <mat-icon *ngIf="search" class="gray pointer" (click)="search = null; paginator.pageIndex = 0" matSuffix
          >cancel
        </mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="m-portlet mb-0">
    <div class="m-portlet__body table-header bg-shade-ltblue p-0 pl-3 pr-3">
      <div class="row align-items-center">
        <div
          class="col-2 p-1 pl-3 pr-3 d-flex header-item align-items-center"
          [ngClass]="{ sortedBy: fieldToSortBy === 'name' }"
        >
          <h6 (click)="updateSortByField('name')" class="m-0 text-truncate header-title mr-auto">Name</h6>
          <i *ngIf="fieldToSortBy === 'name'" matRipple class="material-icons">
            {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
          </i>
        </div>
        <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Buildings</h6>
        </div>
        <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Floors</h6>
        </div>
        <div class="col-3 p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Suites</h6>
        </div>
        <div class="col p-1 pl-3 pr-3 d-flex header-item align-items-center">
          <h6 class="m-0 text-truncate header-title mr-auto">Status</h6>
        </div>
      </div>
    </div>
    <div [style.height]="tableHeight + 'px'" style="overflow: auto">
      <div
        class="m-portlet__body item pointer p-3 pt-4 pb-4"
        data-test-id="work-order-list-item"
        *ngFor="
          let department of departments
            | isEnabled: showInactive
            | filter: search:['name', 'building_name', 'suite_names', 'company_name']:filteredCount
            | orderByField: fieldToSortBy:sortDirection
            | paginator: paginator.pageIndex:paginator.pageSize
        "
      >
        <div class="row pr-0 align-items-center">
          <a class="col-2 pl-3 pr-3 d-flex align-items-center" [routerLink]="'/department/' + department.id">
            <h6 class="primary m-0" [ngClass]="{ gray: department.is_enabled === 0 }">
              {{ department.name }}
            </h6>
          </a>
          <div class="col-9 data-item" [ngClass]="{ gray: department.is_enabled !== 1 }">
            <div
              class="row"
              [ngClass]="{ 'mt-2': department.suite_occupancies[index - 1]?.building.id !== so.building.id }"
              *ngFor="let so of department.suite_occupancies; let index = index"
            >
              <div class="col pl-3 pr-3 mb-2">
                <span
                  class="px-2 py-1 lh-20 br-10 m-1"
                  [ngClass]="so.color"
                  *ngIf="department.suite_occupancies[index - 1]?.building.id !== so.building.id"
                >
                  {{ so.building.name }}
                </span>
              </div>
              <div class="col pl-3 pr-3 mb-2">
                <span
                  class="px-2 py-1 lh-20 br-10 m-1"
                  [ngClass]="so.color"
                  *ngIf="department.suite_occupancies[index - 1]?.suite.floor.id !== so.suite.floor.id"
                >
                  {{ so.suite.floor.name }}
                </span>
              </div>
              <div class="col pl-3 pr-3 mb-2">
                <span class="px-2 py-1 lh-20 br-10 m-1" [ngClass]="so.color">
                  {{ so.suite.name }}
                </span>
              </div>
            </div>
          </div>
          <div class="col pl-3 pr-3 d-flex align-items-center">
            <p class="m-0 lh-20">
              <span class="dkgreen bg-shade-green px-2 py-1 lh-20 br-10" *ngIf="department.is_enabled == 1">
                Active
              </span>
              <span class="gray bg-shade-gray px-2 py-1 lh-20 br-10" *ngIf="department.is_enabled !== 1">
                Inactive
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<mat-paginator
  class="bg-transparent dkblue"
  color="primary"
  #paginator
  [length]="filteredCount?.count"
  pageSize="25"
  (page)="pageChange($event)"
  showFirstLastButtons
>
</mat-paginator>
