<div *ngIf="formLoading">LOADING...</div>
<div *ngIf="!formLoading && !shownForm">Could not load form</div>
<div *ngIf="shownForm">
  <div class="row" *ngIf="!contained">
    <div class="col-lg-6">
      <h2 class="dkblue font-weight-semibold">{{ title || shownForm.name }}</h2>
    </div>
    <div class="col-lg-6 text-right">
      <div class="">
        <div
          class="m-dropdown m-dropdown--inline m-dropdown--small m-dropdown--align-right"
          m-dropdown-toggle="click"
          aria-expanded="true"
        >
          <!-- <button mat-button *ngIf="unsavedChangesExist" color="accent" type="button" class="m-portlet__nav-link" (click)="refresh()">
            Cancel
          </button>
          &nbsp; -->
          <button
            mat-raised-button
            [color]="formIsValid ? 'success' : null"
            class="m-portlet__nav-link"
            [ngClass]="{ 'button-disabled': !formIsValid }"
            (click)="submitForm()"
          >
            Submit
          </button>
          <!-- &nbsp;
          <button mat-raised-button type="button" class="m-portlet__nav-link m-dropdown__toggle m-dropdown__toggle dropdown-toggle">
            Actions
          </button>
          <div class="m-dropdown__wrapper">
            <div class="m-dropdown__inner">
              <div class="m-dropdown__body">
                <div class="m-dropdown__content">
                  <ul class="m-nav">
                    <li class="m-nav__item">
                      <a class="m-nav__link" (click)="exportForm()">
                        <i class="m-nav__link-icon la la-download dkblue"></i>
                        <span class="m-nav__link-text">Export as PDF</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
          &nbsp;
        </div>
      </div>
    </div>
  </div>
  <br *ngIf="!contained" />
  <div [ngClass]="{ 'm-portlet m-portlet--head-sm': !contained }" m-portlet="true" id="m_portlet_tools_3">
    <div [ngClass]="{ 'm-portlet__body': !contained }">
      <div class="row">
        <div class="col-lg-12">
          <div class="row" *ngIf="shownForm && shownForm.content">
            <form [formGroup]="dynamicForm">
              <!-- section -->
              <div
                class="form-section"
                [ngClass]="{ 'm-0': !s.title && !s.subtitle }"
                *ngFor="let s of shownForm.content.sections"
              >
                <form [formGroupName]="s.section_name">
                  <div class="col-lg-12">
                    <h4 class="dkblue section-title font-weight-semibold" *ngIf="s.title">
                      {{ s.title }}
                    </h4>
                    <h6 class="ltblue section-subtitle" *ngIf="s.subtitle">{{ s.subtitle }}</h6>

                    <div class="row">
                      <!-- element -->
                      <div
                        *ngFor="let e of s.elements"
                        [class]="
                          'mb-4' +
                          (e.width
                            ? ' col-xs-' +
                              (e.width.xs || 12) +
                              ' col-sm-' +
                              (e.width.sm || 12) +
                              ' col-md-' +
                              (e.width.md || 12) +
                              ' col-lg-' +
                              (e.width.lg || 12)
                            : '')
                        "
                      >
                        <!-- 1: text -->
                        <div *ngIf="e.type === 1">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field appearance="outline" class="form-element" [hidden]="e.hidden">
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              type="text"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            />
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 2: paragraph -->
                        <div *ngIf="e.type === 2">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field appearance="outline" class="form-element" [hidden]="e.hidden">
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <textarea
                              matInput
                              [required]="e.required"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            ></textarea>
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 3: select -->
                        <div *ngIf="e.type === 3">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field appearance="outline" class="form-element" [hidden]="e.hidden">
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <mat-select
                              matNativeControl
                              [required]="e.required"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            >
                              <mat-option></mat-option>
                              <mat-option *ngFor="let o of e.options" [value]="o">{{ o.label }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 4: search -->
                        <div *ngIf="e.type === 4">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 4"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              type="text"
                              matInput
                              [required]="e.required"
                              [matAutocomplete]="auto"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            />
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="e.valueMapper">
                              <mat-option *ngFor="let o of e.options" [value]="o">
                                <span *ngIf="o">{{ o.label }}</span>
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 5: datepicker -->
                        <div *ngIf="e.type === 5">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 5"
                            (click)="datePicker.open()"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              [matDatepicker]="datePicker"
                              (ngModelChange)="elementChanged(e)"
                              [matDatepickerFilter]="e.datepickerFilter"
                              autocomplete="off"
                              [formControlName]="e.element_name"
                              [placeholder]="e.label"
                            />
                            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                            <mat-datepicker #datePicker></mat-datepicker>
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'matDatepickerFilter')"
                              >{{ e.label }} exceeds allowed range</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 6: checkboxes -->
                        <form
                          [formGroupName]="e.element_name"
                          class="form-element padded"
                          *ngIf="e.type === 6"
                          [hidden]="e.hidden"
                        >
                          <mat-label>{{ e.label }}</mat-label>
                          <div class="row">
                            <div
                              *ngFor="let o of e.options"
                              [ngClass]="'col-lg-' + (e.columns ? math.ceil(12 / e.columns) : '12')"
                            >
                              <mat-checkbox [formControlName]="o.option_name" (ngModelChange)="elementChanged(e)">{{
                                o.label
                              }}</mat-checkbox>
                            </div>
                          </div>
                        </form>

                        <!-- 7: radio buttons -->
                        <div class="form-element padded" *ngIf="e.type === 7" [hidden]="e.hidden">
                          <mat-label class="radio-label">{{ e.label }}<span *ngIf="e.required"> *</span></mat-label>
                          <mat-radio-group
                            [formControlName]="e.element_name"
                            class="row"
                            (ngModelChange)="elementChanged(e)"
                          >
                            <div
                              *ngFor="let o of e.options"
                              [ngClass]="'col-lg-' + (e.columns ? math.ceil(12 / e.columns) : '12')"
                            >
                              <mat-radio-button [value]="o">{{ o.label }}</mat-radio-button>
                            </div>
                          </mat-radio-group>
                        </div>

                        <!-- 8: file upload TODO: dropbox integration -->
                        <div class="form-element padded" *ngIf="e.type === 8" [hidden]="e.hidden">
                          <input
                            mat-raised-button
                            [hidden]="true"
                            #uploader
                            type="file"
                            (change)="uploadFile($event.target.files, e); uploader.value = null"
                            (ngModelChange)="elementChanged(e)"
                          />
                          <button mat-raised-button type="button" (click)="uploader.click()">
                            Upload {{ e.label }}
                          </button>
                          <a
                            (click)="downloadFile((dynamicForm?.get(s.section_name)?.controls)[e.element_name]?.value)"
                            class="ltblue"
                            style="padding-left: 15px"
                            >{{ (dynamicForm?.get(s.section_name)?.controls)[e.element_name]?.value?.name }}</a
                          >
                        </div>

                        <!-- 9: number -->
                        <div *ngIf="e.type === 9">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 9"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              type="number"
                              [min]="e.minimum"
                              [max]="e.maximum"
                              (ngModelChange)="elementChanged(e)"
                              [formControlName]="e.element_name"
                              [placeholder]="e.label"
                            />
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'min')"
                              >Minimum value is {{ e.minimum }}</mat-error
                            >
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'max')"
                              >Maximum value is {{ e.maximum }}</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 10: phone -->
                        <div *ngIf="e.type === 10">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 10"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              type="text"
                              [value]="dynamicForm.get(s.section_name).controls[e.element_name].value | phoneNumber"
                              (ngModelChange)="elementChanged(e)"
                              [formControlName]="e.element_name"
                              [placeholder]="e.label"
                            />
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 11: US state -->
                        <div *ngIf="e.type === 11">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 11"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <mat-select
                              matNativeControl
                              [required]="e.required"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            >
                              <mat-option></mat-option>
                              <mat-option *ngFor="let o of e.options" [value]="o.abbreviation">{{ o.name }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 12: US dollar -->
                        <div *ngIf="e.type === 12">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 12"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              type="text"
                              [value]="dynamicForm.get(s.section_name).controls[e.element_name].value | USDollar"
                              (ngModelChange)="elementChanged(e)"
                              [formControlName]="e.element_name"
                              [placeholder]="e.label"
                            />
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 13: US zip code TODO: validate -->
                        <div *ngIf="e.type === 13">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <mat-form-field
                            appearance="outline"
                            class="form-element"
                            *ngIf="e.type === 13"
                            [hidden]="e.hidden"
                          >
                            <mat-label *ngIf="useMatLabels">{{ e.label }}</mat-label>
                            <input
                              matInput
                              [required]="e.required"
                              type="text"
                              [formControlName]="e.element_name"
                              (ngModelChange)="elementChanged(e)"
                              [placeholder]="e.label"
                            />
                            <mat-error *ngIf="checkValidation(s.section_name, e.element_name, 'required')"
                              >*Required</mat-error
                            >
                          </mat-form-field>
                        </div>

                        <!-- 14: Information -->
                        <h6
                          style="font-weight: 600; padding-bottom: 8px; white-space: pre-wrap"
                          *ngIf="e.type === 14"
                          [innerHTML]="e.label"
                          [hidden]="e.hidden"
                        ></h6>

                        <!-- 15: Acknowledgement -->
                        <div *ngIf="e.type === 15" [hidden]="e.hidden">
                          <div class="acknowledgement-text-container">
                            <h6>{{ e.label }}</h6>
                            <h6 class="acknowledgement-text">{{ e.acknowledgement }}</h6>
                          </div>
                          <div class="acknowledgement-acceptance-container">
                            <mat-checkbox
                              (ngModelChange)="elementChanged(e)"
                              [formControlName]="e.element_name + '_acceptance'"
                              >{{ e.statement }}<span *ngIf="e.required"> *</span></mat-checkbox
                            >
                            <mat-error
                              *ngIf="checkValidation(s.section_name, e.element_name + '_acceptance', 'pattern')"
                              >*Required</mat-error
                            >
                          </div>
                          <div class="acknowledgement-signature-container" *ngIf="e.signature">
                            <mat-form-field appearance="outline" class="form-element">
                              <mat-label *ngIf="useMatLabels">Signature</mat-label>
                              <input
                                matInput
                                (ngModelChange)="elementChanged(e)"
                                [required]="e.required"
                                type="text"
                                [formControlName]="e.element_name + '_signature'"
                              />
                              <mat-error
                                *ngIf="checkValidation(s.section_name, e.element_name + '_signature', 'required')"
                                >*Required</mat-error
                              >
                            </mat-form-field>
                          </div>
                        </div>
                        <div *ngIf="e.type === 16">
                          <h6 *ngIf="!useMatLabels" class="m-0 dkblue font-weight-semibold mb-2">
                            {{ e.label }}{{ e.required ? ' *' : '' }}
                          </h6>
                          <app-auto-complete
                            [control]="dynamicForm.get(s.section_name).controls[e.element_name]"
                            [items]="e.child ? e.filtered_options : e.options"
                            [title]="e.label"
                            [displayFn]="buildingDepartmentDisplayFn"
                            [searchFields]="[e.display_field]"
                            [threshold]="0.4"
                          ></app-auto-complete>
                        </div>
                      </div>
                    </div>

                    <!-- end element -->
                  </div>
                </form>
              </div>
              <!-- end section -->
              <!-- <button mat-raised-button color="success" type="submit" (click)="submitForm()">Submit</button> -->
              <div class="col-lg-12" *ngIf="!contained">
                <button
                  mat-raised-button
                  type="button"
                  [color]="formIsValid ? 'success' : null"
                  class="m-portlet__nav-link btn-submit-bottom"
                  [ngClass]="{ 'button-disabled': !formIsValid }"
                  (click)="submitForm()"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
