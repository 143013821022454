<div id="profile" data-test-id="profile" class="container app-container p-5 profile">
  <div class="row align-items-center mb-5">
    <div class="col">
      <h3 class="font-weight-semibold dkblue m-0">
        {{
          currentUserId === shownUser.id
            ? 'My'
            : shownUser.first_name +
              "'" +
              (shownUser.first_name && shownUser.first_name[shownUser.first_name.length - 1] === 's' ? '' : 's')
        }}
        Profile
      </h3>
    </div>
    <div class="col-auto text-right">
      <!-- <button
        mat-flat-button
        *ngIf="currentUserId !== shownUser.id"
        color="accent"
        (click)="startConversation(shownUser)"
        matTooltip="Message {{ shownUser.first_name }}"
        matTooltipPosition="above"
      >
        <mat-icon>mail</mat-icon>
        &nbsp;
        <span>
          Message
        </span>
      </button> -->
      <!-- <button *ngIf="[8, 14].indexOf(currentUserId) > -1 && currentUserId === shownUser.id" class="ml-4 bg-white"
        mat-stroked-button color="accent" (click)="testEmail()">
        Test Email
      </button> -->
      <button
        mat-button
        class="ml-4"
        color="accent"
        *ngIf="
          !profileEditable &&
          authService.isAppAdmin &&
          (!authService.currentUser || authService.currentUser.id !== shownUser.id)
        "
        type="button"
        (click)="impersonateUser(shownUser)"
      >
        <mat-icon>bug_report</mat-icon>
        Impersonate
      </button>
      <button
        *ngIf="!profileEditable && userIsEditable"
        class="ml-3 bg-white"
        mat-stroked-button
        color="accent"
        type="button"
        (click)="makeProfileEditable()"
      >
        Edit Account
      </button>
      <button
        *ngIf="profileEditable"
        class="ml-3 gray"
        mat-button
        color="accent"
        type="button"
        (click)="cancelUpdate()"
      >
        Cancel
      </button>
      <button
        *ngIf="profileEditable"
        class="ml-3"
        mat-flat-button
        color="success"
        type="button"
        (click)="updateProfile()"
        [disabled]="invalidFirstName || invalidLastName"
      >
        Save Changes
      </button>
    </div>
  </div>
  <div class="m-content">
    <form [formGroup]="profileFormGroup">
      <!-- (ngSubmit)="updateProfile()"> -->
      <div class="row">
        <div class="col mb-5">
          <div id="photo-container" class="d-flex align-items-center m-portlet py-3 px-5 m-0">
            <div class="py-4 mr-4">
              <button
                type="button"
                id="avatar"
                class="align-items-center"
                [ngClass]="{ clickable: profilePictureEditable }"
                *ngIf="shownUser.id"
                [style.background-image]="'url(' + getProfilePictureUrl(shownUser.id) + ')'"
                [disabled]="!profilePictureEditable"
                (click)="profileEditor ? profilePictureFileInput.click() : null"
              >
                <div *ngIf="profilePictureEditable" id="camera" class="pointer">
                  <i class="material-icons white opacity9">photo_camera</i>
                </div>
              </button>
              <input
                type="file"
                hidden
                #profilePictureFileInput
                (change)="changeProfilePicture($event.target.files); profilePictureFileInput.value = null"
                accept="image/jpg,image/jpeg,image/png"
              />
              <mat-icon id="remove-photo" *ngIf="profilePictureEditable" mat-button (click)="resetPhoto()"
                >cancel</mat-icon
              >
            </div>
            <div class="flex-grow-1 flex-column">
              <h2 class="dkblue font-weight-semibold m-0">{{ shownUser.first_name }} {{ shownUser.last_name }}</h2>
              <!-- IF IN HOUSE, SHOW TITLE -->
              <h5 class="dkgray font-weight-normal m-0" *ngIf="shownUser.user_type_id === 1">
                {{ shownUser.title }}
              </h5>
              <h5 class="dkgray font-weight-normal m-0" *ngIf="shownUser.user_type_id === 2">
                {{ shownUser.department_name }}
              </h5>
              <!-- IF supplier, SHOW COMPANY -->
              <h5 class="dkgray font-weight-normal m-0" *ngIf="shownUser.user_type_id === 3">
                {{ shownUser.company_name }}
              </h5>
              <p *ngIf="isAR" class="m-0 mt-3 dkgray font-weight-normal d-flex align-items-center">
                <mat-icon class="mr-1" style="font-size: 17px; height: 17px; width: 17px">verified_user</mat-icon>
                <span> Authorized Requestor </span>
              </p>
              <!-- BELOW: FOR STAFF, SHOW ROLES, FOR VENDORS SHOW PREFERRED TRADES -->
              <mat-chip-list
                class="d-inline-block align-middle mt-4"
                *ngIf="shownUser.user_type_id === 3 && shownUser.trades"
              >
                <mat-chip class="dkblue" *ngFor="let t of shownUser.trades">
                  <span class="pointer">
                    {{ t.name }}
                  </span>
                  <mat-icon
                    matChipRemove
                    *ngIf="currentUserId === shownUser.id && profileEditable"
                    (click)="removeTrade(t)"
                    >cancel</mat-icon
                  >
                  <!--TODO-->
                </mat-chip>
              </mat-chip-list>
              <button
                mat-button
                type="button"
                color="accent"
                class="d-inline-block align-middle"
                *ngIf="currentUserId === shownUser.id && shownUser.user_type_id === 3 && profileEditable"
                (click)="addTrade()"
              >
                <i class="la la-plus"></i>
                Add Trade
              </button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 mb-5" *ngIf="authService?.isAppAdmin || canEditeSettings">
          <div *ngIf="!profileEditable" class="toggle-buttons d-flex flex-column m-portlet mb-0">
            <div class="item d-flex align-items-center p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Account Enabled</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">Account is enabled and visible.</p>
              </div>
              <mat-icon *ngIf="shownUser.is_enabled" class="green ml-3"> check_circle </mat-icon>
              <mat-icon *ngIf="!shownUser.is_enabled" class="red ml-3"> cancel </mat-icon>
            </div>
            <div class="item d-flex align-items-center p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Email Notifications</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">Notifications will be sent via email.</p>
              </div>
              <mat-icon *ngIf="shownUser.is_email_enabled" class="green ml-3"> check_circle </mat-icon>
              <mat-icon *ngIf="!shownUser.is_email_enabled" class="red ml-3"> cancel </mat-icon>
            </div>
            <div class="item d-flex align-items-center item-last p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Can Login</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">This user can login.</p>
              </div>
              <button
                *ngIf="shownUser.is_enabled && !shownUser.is_login_enabled"
                mat-flat-button
                (click)="inviteUser(shownUser)"
                color="success"
                class="lh-28 pl-3 pr-3"
              >
                Send Invite
              </button>
              <mat-icon *ngIf="shownUser.is_login_enabled" class="green ml-3"> check_circle </mat-icon>
              <mat-icon *ngIf="!shownUser.is_login_enabled" class="red ml-3"> cancel </mat-icon>
              <!-- <mat-icon *ngIf="!shownUser.is_login_enabled" class="red ml-3">
                cancel
              </mat-icon> -->
            </div>
          </div>
          <div *ngIf="profileEditable" class="toggle-buttons d-flex flex-column m-portlet mb-0">
            <div class="item d-flex align-items-center p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Account Enabled</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">Account is enabled and visible.</p>
              </div>
              <button
                mat-flat-button
                *ngIf="shownUser.is_enabled"
                (click)="disableAcct()"
                color="warn"
                class="lh-28 pl-3 pr-3"
              >
                Disable Acct
              </button>
              <button
                mat-flat-button
                *ngIf="!shownUser.is_enabled"
                (click)="shownUser.is_enabled = true"
                color="success"
                class="lh-28 pl-3 pr-3"
              >
                Enable Acct
              </button>
              <!-- <mat-slide-toggle color="accent" formControlName="is_enabled">
              </mat-slide-toggle> -->
            </div>
            <div class="item d-flex align-items-center p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Email Notifications</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">Notifications will be sent via email.</p>
              </div>
              <mat-slide-toggle color="accent" formControlName="is_email_enabled"> </mat-slide-toggle>
            </div>
            <div class="item d-flex align-items-center item-last p-3">
              <div class="d-flex flex-column mr-auto">
                <p class="primary font-weight-semibold mb-0 lh-18">Can Login</p>
                <p class="dkgray font-weight-normal mb-0 lh-18">This user can login.</p>
              </div>
              <button
                *ngIf="shownUser.is_enabled && !shownUser.is_login_enabled"
                mat-flat-button
                color="success"
                class="lh-28 pl-3 pr-3"
                [disabled]="profileEditable"
              >
                Invite
              </button>
              <mat-icon *ngIf="shownUser.is_login_enabled" class="green ml-3"> check_circle </mat-icon>
              <mat-icon *ngIf="!shownUser.is_login_enabled" class="red ml-3"> cancel </mat-icon>
              <!-- <mat-slide-toggle class="ml-3" color="accent" formControlName="is_login_enabled">
              </mat-slide-toggle> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="profile-portlet m-portlet m-portlet--mobile p-0" [hidden]="profileEditable">
            <div class="m-portlet__body border-bottom border-gray p-5">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Contact</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Email</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.email }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Cell Phone</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.cell_phone | phoneNumber }}
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Office Phone</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.office_phone | phoneNumber }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__body border-bottom border-gray p-5">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Position</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Company</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.company_name }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3" *ngIf="shownUser.user_type_id !== 2">
                      <h6 class="mb-2 dkblue font-weight-semibold">Manager</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.manager_first_name }} {{ shownUser.manager_last_name }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Title</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.title }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Account Type</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.user_type_id === 1 ? 'UHAT/1CALL' : shownUser.user_type_name }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__body p-5" *ngIf="shownUser.user_type_id !== 3">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Location</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Building</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.building_name }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Floor</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.floor_name }}
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Suite</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.suite_name }}
                      </h5>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Department</h6>
                      <h5 class="m-0 mb-3 dkblue font-weight-normal">
                        {{ shownUser.department_name }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-portlet m-portlet m-portlet--mobile p-0" [hidden]="!profileEditable">
            <div class="m-portlet__body border-bottom border-gray p-5">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Contact</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">
                        Email
                        <small class="gray font-italic ml-1">Cannot change login information</small>
                      </h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input matInput placeholder="Email Address" formControlName="email" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">First Name</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input
                          matInput
                          placeholder="First Name"
                          formControlName="first_name"
                          (change)="trimFirstName()"
                        />
                        <mat-error *ngIf="first_name?.errors?.required">A First Name is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Last Name</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input matInput placeholder="Last Name" formControlName="last_name" (change)="trimLastName()" />
                        <mat-error *ngIf="last_name?.errors?.required">A Last Name is required</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Office Phone</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input matInput placeholder="Office Phone" formControlName="office_phone" />
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Cell Phone</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input matInput placeholder="Cell Phone" formControlName="cell_phone" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__body border-bottom border-gray p-5">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Position</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Company</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select formControlName="company_id">
                          <mat-option [value]="null">-</mat-option>
                          <mat-option *ngFor="let c of companies" [value]="c.id">
                            {{ c.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 py-3" [hidden]="shownUser.user_type_id === 2">
                      <h6 class="mb-2 dkblue font-weight-semibold">Manager</h6>
                      <app-search-user-input
                        #manager
                        [userTypes]="managers"
                        [placeholder]="managerPlaceholder"
                        [initialValueId]="shownUser.manager_id"
                      ></app-search-user-input>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Title</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <input matInput placeholder="Title" formControlName="title" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__body p-5" *ngIf="shownUser.user_type_id !== 3">
              <div class="row">
                <div class="col-lg-3 py-3">
                  <h4 class="font-weight-semibold form-section-heading dkblue">Location</h4>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Building</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select (selectionChange)="getFloors()" formControlName="building_id">
                          <mat-option [value]="null">-</mat-option>
                          <mat-option *ngFor="let b of buildings" [value]="b.id">
                            {{ b.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Floor</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select (selectionChange)="getSuites()" formControlName="floor_id">
                          <mat-option [value]="null">-</mat-option>
                          <mat-option *ngFor="let f of floors" [value]="f.id">
                            {{ f.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Suite</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select (selectionChange)="getDepartments()" formControlName="suite_id">
                          <mat-option [value]="null">-</mat-option>
                          <mat-option *ngFor="let s of suites" [value]="s.id">
                            {{ s.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 py-3">
                      <h6 class="mb-2 dkblue font-weight-semibold">Department</h6>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select formControlName="department_id">
                          <mat-option [value]="null">-</mat-option>
                          <mat-option *ngFor="let d of departments" [value]="d.id">
                            {{ d.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                *ngIf="profileEditable"
                class="ml-4 gray"
                mat-button
                color="accent"
                type="button"
                (click)="cancelUpdate()"
              >
                Cancel
              </button>
              <button
                *ngIf="profileEditable"
                class="ml-4"
                mat-flat-button
                color="success"
                type="button"
                (click)="updateProfile()"
                [disabled]="first_name?.errors?.required || last_name?.errors?.required"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
