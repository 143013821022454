import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sortBy } from 'lodash';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { NewAccountModalComponent } from 'src/app/components';
import { UserType, Workspace } from 'src/app/enums';
import { LocationService, PurchasingBudgetService, UserService } from 'src/app/services';
import { APIFilter, Department, FiscalYear, User } from 'src/app/types';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { ProjectTenantConstruction, TenantType } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.scss'],
})
export class CustomerDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private locationService: LocationService,
    private userService: UserService,
    private projectTenantService: ProjectTenantService,
    private purchasingBudgetService: PurchasingBudgetService
  ) {}

  customerFormGroup: FormGroup = this.fb.group({
    tenantTypeId: [null, [Validators.required]],
    department: [null, [Validators.required]],
    representative: [null, [Validators.required]],
    fiscalYear: +this.data?.moduleId === Workspace.Construction ? [null, [Validators.required]] : [null],
  });

  loaders = {
    customer: false,
  };

  tenantTypes: TenantType[];
  departments: Department[];
  filteredDepartments: Department[];
  users: User[];
  filteredRepresentatives: User[];
  existingTenants: ProjectTenantConstruction[] = [];
  isLoading = true;
  fiscalYears: FiscalYear[] = [];

  get tenantTypeId() {
    return this.customerFormGroup.get('tenantTypeId');
  }
  get department() {
    return this.customerFormGroup.get('department');
  }
  get representative() {
    return this.customerFormGroup.get('representative');
  }
  get fiscalYear() {
    return this.customerFormGroup.get('fiscalYear');
  }
  public get isArCon61Workspace() {
    // this enum is used for title 61 projects
    return +this.data?.moduleId === Workspace.Construction;
  }

  async ngOnInit() {
    const allTenantTypes = await this.projectTenantService.getTenantTypes().toPromise();
    if (this.isArCon61Workspace) {
      this.fiscalYears = await this.purchasingBudgetService.getFiscalYears().toPromise();
    }
    this.tenantTypes = allTenantTypes.filter((t) => t.id !== 3);
    this.tenantTypes = sortBy(this.tenantTypes, (t) => t.sequence);
    this.existingTenants = await this.projectTenantService.getTenantsForProject(this.data.projectId).toPromise();
    this.department.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm && searchTerm.id) {
            // check to see if department is already selected
            return of(null);
          } else {
            if (searchTerm) {
              const departmentFilter: APIFilter[] = [
                { type: 'field', field: 'id', value: '132', match: '!=' },
                { type: 'operator', value: 'AND' },
                { type: 'field', field: 'id', value: '133', match: '!=' },
                { type: 'operator', value: 'AND' },
                { type: 'field', field: 'name', value: searchTerm, match: 'any' },
              ];
              return this.locationService.getDepartments(['id', 'name'], departmentFilter);
            } else {
              return of(this.departments);
            }
          }
        })
      )
      .subscribe((departments: any) => {
        if (departments) {
          this.filteredDepartments = departments;
        } else {
          this.filteredDepartments = [];
        }
      });

    this.representative.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm && searchTerm.id) {
            // check to see if user is already selected
            return of(null);
          } else {
            if (searchTerm) {
              return this.userService.searchUsers([
                { type: 'field', field: 'is_enabled', value: '1' },
                { type: 'operator', value: 'AND' },
                { type: 'operator', value: '(' },
                { type: 'field', field: 'email', value: searchTerm, match: 'any' },
                { type: 'operator', value: 'OR' },
                { type: 'field', field: 'full_name', value: searchTerm, match: 'any' },
                { type: 'operator', value: ')' },
              ]);
            } else {
              return of(this.users);
            }
          }
        })
      )
      .subscribe((users) => {
        if (users) {
          this.filteredRepresentatives = users;
        } else {
          this.filteredRepresentatives = [];
        }
        this.isLoading = false;
      });
  }

  tenantTypeChanged() {
    if (this.tenantTypeId && this.tenantTypeId.value === 3) {
      this.department.setValue(null);
      this.department.setValidators(null);
      this.department.disable();
      this.representative.setValue(null);
      this.representative.setValidators(null);
      this.representative.disable();
    } else {
      this.department.setValidators([Validators.required]);
      this.department.enable();
      this.representative.setValidators([Validators.required]);
      this.representative.enable();
    }
  }

  departmentValueMapper(department) {
    return department ? department.name : null;
  }

  async departmentChanged() {
    if (this.department.value && !this.department.value.id) {
      this.department.setErrors({ invalidDepartment: true });
    }
    if (this.department.value && (await this.isExistingTenant(this.department.value))) {
      this.department.setErrors({ alreadyExists: true });
    }
  }

  // this functions checks if the preloaded list of existing tenants already includes the selected tenant
  private async isExistingTenant(department) {
    return this.existingTenants.map((d) => d.department_id).includes(department.id);
  }

  representativeValueMapper(representative) {
    return representative ? `${representative.first_name} ${representative.last_name}` : null;
  }

  representativeChanged() {
    if (this.representative.value && !this.representative.value.id) {
      this.representative.setErrors({ invalidRepresentative: true });
    }
  }

  async submit() {
    if (this.customerFormGroup.valid && this.data?.projectId) {
      this.loaders.customer = true;
      this.projectTenantService
        .linkTenantToProject(
          this.data.projectId,
          this.department.value ? this.department.value.id : null,
          this.representative.value ? this.representative.value.id : null,
          this.tenantTypeId.value,
          this.isArCon61Workspace && this.fiscalYear?.value ? this.fiscalYear.value : null
        )
        .subscribe((created) => {
          this.snackbar.open(`Tenant added!`);
          this.loaders.customer = false;
          this.dialogRef.close(created);
        });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
  createUser() {
    const dialogRef = this.dialog
      .open(NewAccountModalComponent, {
        width: '540px',
        disableClose: true,
        data: {
          userType: { id: UserType.Tenant },
          userInfo: this.representative.value || null,
          department: this.department.value,
        },
      })
      .afterClosed()
      .subscribe(async (userCreated) => {
        if (userCreated) {
          const addUser = {
            id: userCreated.id,
            email: userCreated.email,
            first_name: userCreated.first_name,
            last_name: userCreated.last_name,
            user_type_id: userCreated.user_type_id,
            company_id: userCreated.company_id,
            department_id: userCreated.department_id,
          };
          this.representative.setValue(addUser);
          this.department.setValue({
            id: userCreated.department_id,
            name: userCreated.department_name,
          });
        }
      });
  }
}
