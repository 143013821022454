import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ViewTaskDialogComponent } from 'src/app/components';
import { ArfStatus, ProjectStatus, TaskStatus } from 'src/app/enums';
import {
  AuthService,
  ModalService,
  ModuleService,
  ProgressIndicatorService,
  ProjectTaskService,
} from 'src/app/services';
import { APIFilter, User } from 'src/app/types';
import { TaskListPreferences } from 'src/app/types/task-list-preferences';
import { PreferenceStorage } from 'src/app/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  @ViewChild('paginator', { static: false }) paginator: any;
  tasks = [];
  taskUrl = `${environment.serviceHost}/api/v1/tasks`;
  taskFields =
    'id,status_id,title,description,due_date,assigned_user,assigned_user_first_name,assigned_user_last_name,module_id,project{module,building,floor},accessory_data,follower_ids,arf{module,status_id},key_controls';
  startIndex = 0;
  pageSize = 30;
  endIndex = this.pageSize;
  sortDirection: SortDirection = 'desc';
  fieldToSortBy: string;
  daysDue: number;
  assignedUsers: User[];
  taskStatus;
  filteredCount = { count: 0 };
  search;
  filteredAssignedUsers: User[] = [];
  currentUser: User;
  workspace;
  workspaces = [];
  loading = true;
  taskType: 'review' | 'action';
  counts: any = {};
  filters: any = {};
  following = false;
  assignedToCurrentUser = false;

  constructor(
    private progressIndicatorService: ProgressIndicatorService,
    private authService: AuthService,
    private projectTaskService: ProjectTaskService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private _moduleService: ModuleService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.authService.currentUser;
    this.workspaces = await this._moduleService.getAllWorkspaces().toPromise();
    const preferences = this.preferences.currentValue;
    this.following = preferences?.following;
    this.assignedToCurrentUser = preferences?.assignedToCurrentUser;
    this.daysDue = preferences?.daysDue;
    this.fieldToSortBy = preferences?.fieldToSortBy;
    this.sortDirection = preferences?.sortDirection;
    this.endIndex = this.pageSize;
    this.taskType = preferences.taskType;
    await this.loadTasks();
    this.assignedUsers = this.filteredAssignedUsers.filter((u) => {
      return preferences?.assignedUserIDs?.indexOf(u.id) >= 0;
    });
    this.workspace = this.workspaces.filter((w) =>
      preferences?.workspace_ids === undefined
        ? w.id === this._moduleService.workspace_id
        : preferences?.workspace_ids.indexOf(w.id) >= 0
    );
    this.refresh();
    const id = +this.route.snapshot.params.id;
    if (id) {
      const task = this.tasks.find((t) => t.id === +this.route.snapshot.params.id);
      if (task) {
        this.openTask(task);
      } else {
        this.modalService.openViewTaskModal(id);
      }
    }
  }

  hasClosedStandaloneARF(task: any) {
    return task.project_id == null && task?.arf?.status_id === ArfStatus.Closed;
  }

  public async getTasks(filters?: APIFilter[]) {
    this.tasks = await this.projectTaskService.getTasks(this.taskFields, filters).toPromise();
    this.tasks = this.tasks
      .filter((t: any) => !this.hasClosedStandaloneARF(t))
      .map((t) => {
        t.isReviewItem = t.accessory_data && JSON.parse(t.accessory_data).isReviewItem ? 'review' : 'action';
        return t;
      });
  }

  async loadTasks() {
    this.loading = true;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading Tasks...');
    const filters: APIFilter[] = [
      { type: 'field', field: 'assigned_user_id', match: '!=', value: null },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'due_date', match: '!=', value: null },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', match: '!=', value: TaskStatus.Complete },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_locked', match: '!=', value: 1 },
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'parent_id', match: '!=', value: null },
      { type: 'operator', value: 'OR' },
      {
        type: 'field',
        field: 'project_status_id',
        value: [ProjectStatus.ACTIVE, ProjectStatus.PLANNED].join('^'),
      },
      { type: 'operator', value: ')' },
    ];

    await this.getTasks(filters);
    this.getUniqueAssignedUsers();
    this.progressIndicatorService.close();
    this.loading = false;
  }

  getDatesFilter(daysDue: number) {
    const initialFilter: APIFilter[] = [
      { type: 'field', field: 'assigned_user_id', match: '!=', value: null },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'due_date', match: '!=', value: null },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'status_id', match: '!=', value: TaskStatus.Complete },
    ];
    if (daysDue < 0) {
      return initialFilter;
    }
    // const now = moment();
    // let startDate = daysDue ? now.startOf('day').format('YYYY/MM/DD') : null,
    //   endDate = daysDue ? now.add(daysDue, 'days').format('YYYY/MM/DD') : now.endOf('day').format('YYYY/MM/DD');
    let dateFilter: APIFilter[] = [];
    // if (startDate) {
    //   dateFilter.push({ type: 'field', field: 'due_date', value: startDate, match: '>=' });
    //   if (endDate) {
    //     dateFilter.push({ type: 'operator', value: 'AND' });
    //     dateFilter.push({ type: 'field', field: 'due_date', value: endDate, match: '<' });
    //   }
    // } else {
    //   if (endDate) {
    //     dateFilter.push({ type: 'field', field: 'due_date', value: endDate, match: '<' });
    //   }
    // }
    const filters =
      dateFilter.length > 0
        ? initialFilter.concat([{ type: 'operator', value: 'AND' }]).concat(dateFilter)
        : initialFilter;
    return filters;
  }

  pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
    const element = document.querySelector('#main-task-list-wrapper');
    element.scrollTo(0, 0);
  }

  assignedUsersChanged() {
    this.refresh();
    this.preferences.setPartialValue({ assignedUserIDs: this.assignedUsers?.map((u) => u.id) || null });
  }

  workspaceChanged() {
    this.refresh();
    this.preferences.setPartialValue({ workspace_ids: this.workspace?.map((w) => w.id) || null });
  }

  taskTypeChanged() {
    this.refresh();
    this.preferences.setPartialValue({ taskType: this.taskType || null });
  }

  followingChanged() {
    this.following = !this.following;
    this.refresh();
    this.preferences.setPartialValue({ following: this.following || null });
  }

  taskAssignmentChanged(task, e) {
    task.assigned_user_id = e.assigned_user_id;
    this.refresh();
  }

  assignedToCurrentUserChanged() {
    this.assignedToCurrentUser = !this.assignedToCurrentUser;
    this.refresh();
    this.preferences.setPartialValue({ assignedToCurrentUser: this.assignedToCurrentUser || null });
  }

  taskUpdate(task, e) {
    task.assigned_user_id = e.assigned_user_id;
    task.due_date = e.due_date;
    task.status_id = e.status_id;
    this.refresh();
  }

  goToFirstPage() {
    this.paginator.firstPage();
    this.startIndex = 0;
  }

  public updateSortByField(field: string) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.fieldToSortBy = field;
    this.preferences.setPartialValue({ sortDirection: this.sortDirection, fieldToSortBy: this.fieldToSortBy });
  }

  private preferences = new PreferenceStorage<TaskListPreferences>('preferences_task_list', {
    fieldToSortBy: 'code',
    sortDirection: 'desc',
    daysDue: -1,
    version: 1,
    following: false,
    assignedToCurrentUser: false,
  });

  openTask(task) {
    let taskChangedSubscription, taskFollowersChangedSubscription;
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: { taskId: task.id },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      if (taskChangedSubscription) {
        taskChangedSubscription.unsubscribe();
      }
      if (taskFollowersChangedSubscription) {
        taskFollowersChangedSubscription.unsubscribe();
      }
    });
    taskChangedSubscription = dialogRef.componentInstance.taskChanged.subscribe((result) => {
      task.due_date = result.due_date;
      task.assigned_user_id = result.assigned_user_id;
      task.status_id = result.status_id;
      this.refresh();
    });

    taskFollowersChangedSubscription = dialogRef.componentInstance.taskFollowingChanged.subscribe((result) => {
      task.follower_ids = result.task.follower_ids;
      this.refresh();
    });
  }

  getUniqueAssignedUsers() {
    const users = {};
    users[this.currentUser.id] = {
      id: this.currentUser.id,
      first_name: 'Assigned To Me',
      last_name: '',
    };
    this.tasks.forEach((u) => {
      if (this.currentUser.id !== u.assigned_user.id) {
        users[u.assigned_user.id] = u.assigned_user;
      }
    });
    this.filteredAssignedUsers = Object.keys(users).map((k) => users[k]);
    this.filteredAssignedUsers = this.filteredAssignedUsers.sort((a, b) => {
      const aname = a.first_name.toLowerCase() + a.last_name.toLowerCase();
      const bname = b.first_name.toLowerCase() + b.last_name.toLowerCase();
      if (a.id === this.currentUser.id) return -1;
      else if (b.id === this.currentUser.id) return 1;
      return aname > bname ? 1 : aname < bname ? -1 : 0;
    });
  }

  refresh() {
    this.filters = {
      daysDue: this.daysDue,
      workspace_ids: this.workspace?.map((w) => w.id),
      assigned_user_ids: this.assignedUsers?.map((u) => u.id),
      is_review_task: this.taskType,
      searchText: this.search,
      following: this.following,
      assignedToCurrentUser: this.assignedToCurrentUser,
      currentUserID: this.currentUser.id,
    };
    this.goToFirstPage();
    this.preferences.setPartialValue({ daysDue: this.daysDue });
  }

  toggleMyTasks() {
    if (this.following === true || this.assignedToCurrentUser === true) {
      this.following = this.assignedToCurrentUser = false;
    } else {
      this.following = this.assignedToCurrentUser = true;
    }
    this.refresh();
    this.preferences.setPartialValue({ assignedToCurrentUser: this.assignedToCurrentUser || null });
    this.preferences.setPartialValue({ following: this.following || null });
  }

  toggleAssignedUsers() {
    this.assignedUsers = this.assignedUsers?.length > 0 ? [] : [...this.filteredAssignedUsers];
    this.refresh();
    this.preferences.setPartialValue({ assignedUserIDs: this.assignedUsers?.map((u) => u.id) || null });
  }
}
