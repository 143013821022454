import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent implements OnInit {
  @Output() clickEvent = new EventEmitter();
  @Input() lineHeight: string;
  @Input() matMenu: MatMenu;
  @Input() buttonText: string;
  @Input() action: any;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit(): void {}

  public callParent() {
    this.clickEvent.emit();
  }
}
