<app-base-dialog [title]="title + ' Topic'" (closeDialog)="cancel()"></app-base-dialog>
<div id="topic-dialog" class="topic-dialog" mat-dialog-content>
  <form [formGroup]="topicFormGroup">
    <div class="row mb-4">
      <div class="col-12">
        <h6 class="primary">Topic Name*</h6>
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" formControlName="name" />
          <mat-error *ngIf="name.errors?.required">Name is required</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <h6 class="primary">Visible To</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select multiple formControlName="visible_to">
            <mat-option [value]="0" (click)="updateVisibleTo(0)">Everyone</mat-option>
            <mat-option *ngFor="let access of topicAccess" [value]="access.id" (click)="updateVisibleTo(access.id)">{{
              access.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <h6 class="primary">Topic Type*</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select formControlName="topic_type_id">
            <mat-option *ngFor="let type of topicTypes" [value]="type.id">{{ type.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="topicTypeId.errors?.required">Topic type is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <h6 class="primary">Topic Priority</h6>
        <mat-form-field class="full-width" appearance="outline">
          <mat-select formControlName="priority_id">
            <mat-option class="gray" [value]="null">-</mat-option>
            <mat-option *ngFor="let priority of workOrderPriorities" [value]="priority.id">{{
              priority.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-12">
        <h6 class="primary mb-0">Topic Tool Tip</h6>
        <p class="mb-2 dkgray">Optional helpful tip for this topic</p>
        <app-editor #editor placeholder="Tool Tip"></app-editor>
      </div>
    </div>
  </form>
  <div id="checklist-template" *ngIf="topicTypeId.value === 2">
    <div class="d-flex align-items-center mb-3">
      <div class="d-flex flex-column">
        <h6 class="primary mb-0">Checklist Template Items</h6>
        <p class="mb-2 dkgray">This checklist will be included when the work order is created</p>
      </div>
      <!-- <span (click)="showChecklistView = !showChecklistView" class="d-flex align-items-center pointer">
        <mat-icon class="dkblue mr-2">{{ showChecklistView ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <h5 class="m-0 dkblue pointer">
          Checklist Template Items
          <span class="ml-2 gray font-weight-semibold">
            {{ topic.checklist_template?.length }}
          </span>
        </h5>
      </span> -->
      <button
        *ngIf="showChecklistView"
        mat-flat-button
        color="success"
        class="ml-2 lh-28 px-2 ml-auto"
        (click)="newChecklistItem()"
      >
        + Item
      </button>
    </div>
    <div *ngIf="showChecklistView" class="m-portlet p-0">
      <div
        *ngIf="(!topic?.checklist_template || topic?.checklist_template?.length === 0) && newItem === null"
        class="m-portlet__body p-4 text-center"
      >
        <p class="m-0 gray font-weight-normal">
          Click <span class="font-weight-semibold">+ Item</span> to Add a Checklist Template Item
        </p>
      </div>
      <div
        class="m-portlet__body p-0 item file"
        [ngClass]="{ 'item-last': last && newItem === null }"
        *ngFor="let item of topic.checklist_template; let last = last"
      >
        <div *ngIf="editingItem !== item" class="d-flex align-items-center p-3">
          <p class="m-0 dkblue font-weight-semibold d-block text-truncate pointer">
            {{ item.title }}
          </p>
          <div class="d-flex align-items-center ml-auto">
            <mat-icon
              (click)="editCheckListItem(item)"
              matTooltip="Edit Item"
              matTooltipPosition="above"
              class="icon-click ml-3"
              >edit</mat-icon
            >
            <mat-icon
              (click)="removeItem(item)"
              matTooltip="Delete Item"
              matTooltipPosition="above"
              class="icon-click ml-3"
              >delete</mat-icon
            >
          </div>
        </div>
        <div *ngIf="editingItem == item" class="d-flex align-items-center p-3">
          <div class="col pr-3 pl-0">
            <mat-form-field appearance="outline" class="full-width mb-0">
              <input matInput [(ngModel)]="item.title" placeholder="What needs to be done?" />
            </mat-form-field>
          </div>
          <div class="col-auto d-flex align-items-center pr-0">
            <mat-icon
              matTooltip="Cancel"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="gray pointer"
              (click)="clearCheckListItem(item)"
              >clear</mat-icon
            >
            <mat-icon
              matTooltip="Save"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="pointer green ml-3"
              (click)="addCheckListItem(item)"
              >check_circle</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="m-portlet__body p-0 item file item-last">
        <div *ngIf="newItem" class="d-flex align-items-center p-3">
          <div class="col pr-0 pl-0">
            <mat-form-field appearance="outline" class="full-width mb-0">
              <input matInput [(ngModel)]="newItem.title" (blur)="blur()" placeholder="What needs to be done?" />
            </mat-form-field>
          </div>
          <div class="col-auto d-flex align-items-center pr-0">
            <mat-icon
              matTooltip="Cancel"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="gray pointer m-1"
              (click)="newItem = null"
              >clear</mat-icon
            >
            <mat-icon
              matTooltip="Save"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              class="pointer green m-1"
              (click)="saveCheckListItem()"
              >check_circle</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <button mat-button type="button" class="full-width gray" color="accent" (click)="cancel()">Cancel</button>
  </div>
  <div class="col">
    <button
      [disabled]="topicFormGroup.invalid"
      mat-flat-button
      type="submit"
      color="accent"
      class="full-width"
      (click)="submit()"
    >
      Save Topic
    </button>
  </div>
</div>
