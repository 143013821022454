import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AuthService, UserService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss'],
})
export class InviteUserDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    public userService: UserService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  // all user ids who have not created their accounts yet
  public users: User[];
  // if defined, these represent the object references in the callback
  public parentId: number;
  public parentTypeId: number;

  // whether to send the invite immediately on confirming, or let the parent component handle it (i.e. to wait for the parent object to be created)
  public sendInvite = true;

  // an object containing the most recent invite for each user
  public recentInvites = [];

  // returns the text for the cancel button (since it differs depending on the parent)
  get cancelButtonText() {
    let ret = '';
    switch (this.parentTypeId) {
      case 7:
        ret = 'Cancel Task Assignment';
        break;
      case 13:
        ret = 'Cancel Sending Message';
        break;
      case 26:
        ret = 'Cancel Assigning RFI';
        break;
      default:
        ret = 'Cancel';
        break;
    }
    return ret;
  }
  // returns the text for the 'Assign & Don't Invite' button (since it differs depending on the parent)
  get dontInviteButtonText() {
    let ret = '';
    switch (this.parentTypeId) {
      case 7:
        ret = "Assign & Don't Invite";
        break;
      case 13:
        ret = "Send & Don't Invite";
        break;
      case 26:
        ret = "Assign & Don't Invite";
        break;
      default:
        break;
    }
    return ret;
  }
  // returns the text for the 'Assign & Invite' button (since it differs depending on the parent)
  get inviteButtonText() {
    let ret = '';
    switch (this.parentTypeId) {
      case 7:
        ret = 'Assign & Send Invite';
        break;
      case 13:
        ret = 'Send & Invite';
        break;
      case 26:
        ret = 'Assign & Send Invite';
        break;
      default:
        ret = 'Send Invite';
        break;
    }
    return ret;
  }

  // returns the first part of the message to the user
  get message1() {
    // TODO add another message if recently invited and only 1 user
    if (!this.parentId && !this.parentTypeId) {
      return `This action will send an invite to the selected person to activate their account. `;
    } else {
      if (this.users.length === 1) {
        return `The person you have selected has not activated their account. If you'd like to continue with this action, please choose whether or not to send another invitiation email below. `;
      } else {
        return `The people you have selected have not activated their accounts. If you'd like to continue with this action, please choose whether or not to send another invitiation email below.`;
      }
    }
    // modify the message based on single or multiple users
  }

  // returns the second part of the message to the user
  get message2() {
    // This part references the callback, which is only valid if we have a parentId
    if (this.parentId && this.parentTypeId) {
      // modify the message based on single or multiple users
      if (this.users.length === 1) {
        return 'If you decide to send an invite, you will also receive a notification when their account is activated.';
      } else {
        return 'If you decide to send an invite, you will also receive a notification when their accounts are activated.';
      }
    } else {
      return 'You will also be notified when this account has been activated.';
    }
  }

  async ngOnInit() {
    if (this.authService.canInviteUser) {
      this.users = (this.data && this.data.users) || [];
      this.users = (this.data && this.data.users) || [];
      this.parentId = (this.data && this.data.parentId) || null;
      this.parentTypeId = (this.data && this.data.parentTypeId) || null;

      if (this.data.hasOwnProperty('sendInvite')) {
        this.sendInvite = this.data.sendInvite;
      }
      this.formatInvites(await this.userService.getLastInvites(this.users.map((u) => +u.id)).toPromise());
    }
  }

  private formatInvites(invites) {
    this.recentInvites = [];
    this.users.forEach((user) => {
      if (invites.hasOwnProperty(user.id)) {
        this.recentInvites.push(
          `${invites[user.id].invited_user_first_name} ${
            invites[user.id].invited_user_last_name
          } was last invited on ${moment(invites[user.id].created_datetime).format('dddd, h:mma (MMM Do)')} by ${
            invites[user.id].sending_user_first_name
          } ${invites[user.id].sending_user_last_name}`
        );
      } else {
        this.recentInvites.push(`${user.first_name} ${user.last_name} has not yet been invited.`);
      }
    });
  }

  // on confirmation, invite all of the passed users
  public async submit(sendInvite: boolean) {
    if (sendInvite) {
      this.userService.sendInvites(this.users, this.parentId, this.parentTypeId);
    }
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
