import { Pipe, PipeTransform } from '@angular/core';
import { isJsonEquivalent } from 'src/app/utils';

@Pipe({
  name: 'isJsonEquivalent',
})
export class IsJsonEquivalentPipe implements PipeTransform {
  transform<T>(obj1: T, obj2: T): boolean {
    return isJsonEquivalent(obj1, obj2);
  }
}
