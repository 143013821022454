import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TopicType, Workspace } from 'src/app/enums';
import { ModuleService, TopicService } from 'src/app/services';
import { Topic, TopicCategory } from 'src/app/types';
import { isTopicVisibleToDispatch } from '../../utils';

@Component({
  selector: 'app-request-category',
  templateUrl: './request-category.component.html',
  styleUrls: ['./request-category.component.scss'],
})
export class RequestCategoryComponent implements OnInit {
  @Input() requestType = false;
  @Input() workOrderType = false;
  @Input() showTitle = true;

  private _fields =
    'id,name,topic_group_id,topic_group{id,name},topics{id,topic_category_id,name,topic_type_id,workspace_id,visible_to}';

  public filteredCategories: Observable<TopicCategory[]>;
  public module_id: number;
  public topic_categories = new FormControl('');

  constructor(private _moduleService: ModuleService, private _topicService: TopicService) {}

  async ngOnInit() {
    await this._loadCategories();
  }

  get resolved_module_id(): number {
    return this.module_id || this._moduleService.workspace_id;
  }

  private async _checkDuplicateCategories() {
    const topic_category_names = this._topicService.categories.map(
      (topic_category: TopicCategory) => topic_category.name
    );

    this._topicService.categories = this._topicService.categories.map((topic_category: TopicCategory) => {
      if (topic_category_names.indexOf(topic_category.name) !== topic_category_names.lastIndexOf(topic_category.name)) {
        return {
          ...topic_category,
          isDuplicate: true,
        };
      }
      return {
        ...topic_category,
        isDuplicate: false,
      };
    });
  }

  private _filter(entry: string): TopicCategory[] {
    return this._topicService?.categories?.filter((category) =>
      category?.name?.toLowerCase()?.includes(entry?.toLowerCase())
    );
  }

  private _filterCategoriesByRequest(categories: TopicCategory[]) {
    return categories
      ?.map((category: TopicCategory) => {
        const topics: Topic[] = category?.topics?.filter((topic: Topic) => +topic.topic_type_id === +TopicType.Request);
        if (topics.length > 0) {
          return { ...category, topics };
        } else {
          return null;
        }
      })
      .filter((category: TopicCategory) => category);
  }

  private _filterCategoriesByWorkOrder(categories: TopicCategory[]) {
    return categories
      ?.map((category: TopicCategory) => {
        const topics: Topic[] = category?.topics?.filter(
          (topic: Topic) => +topic.topic_type_id === +TopicType.WorkOrder
        );
        if (topics.length > 0) {
          return { ...category, topics };
        } else {
          return null;
        }
      })
      .filter((category) => category);
  }

  private async _getAllCategories() {
    this._topicService.categories = await this._topicService.getTopicCategories([this._fields]).toPromise();

    await this._checkDuplicateCategories();
    await this._subscribe();
  }

  private async _getAllRequestCategories() {
    this._topicService.categories = this._filterCategoriesByRequest(
      await this._topicService.getTopicCategories([this._fields]).toPromise()
    ).filter((topicCategory: TopicCategory) =>
      topicCategory.topics.some((topic: Topic) => +topic.topic_type_id === +TopicType.Request)
    );
    await this._subscribe();
  }

  private async _getAllWorkOrderCategories() {
    this._topicService.categories = this._filterCategoriesByWorkOrder(
      await this._topicService.getTopicCategories([this._fields]).toPromise()
    ).filter((topicCategory: TopicCategory) =>
      topicCategory.topics.some((topic: Topic) => {
        if (this.resolved_module_id && +this.resolved_module_id === +Workspace.Dispatch) {
          return +topic.topic_type_id === +TopicType.WorkOrder;
        }
        return +topic.topic_type_id === +TopicType.WorkOrder && +topic.workspace_id === +this.resolved_module_id;
      })
    );
    await this._checkDuplicateCategories();
    await this._subscribe();
  }

  private async _loadCategories() {
    if (this.workOrderType && !this.requestType) {
      await this._getAllWorkOrderCategories();
    } else if (this.requestType && !this.workOrderType) {
      await this._getAllRequestCategories();
    } else {
      await this._getAllCategories();
    }
  }

  private async _subscribe() {
    this.filteredCategories = this.topic_categories.valueChanges.pipe(
      startWith(''),
      map((value: string | TopicCategory) => (typeof value === 'string' ? value : value?.name || '')),
      map((name) =>
        name
          ? this._filter(name)
          : this._topicService?.categories?.filter((category: TopicCategory) => category.is_enabled).slice()
      )
    );
  }

  public categoryMapper(category: TopicCategory): string {
    return category?.name || '';
  }

  public clearInput(event = null) {
    this.topic_categories.setValue('');
    if (event) {
      event.stopPropagation();
    }
  }

  public async populateAllCategories() {
    await this._getAllCategories();
  }

  private addPropertyIsVisibleToDispatch(categories: TopicCategory[]): TopicCategory[] {
    return categories.map((topicCategory: TopicCategory) => {
      const isVisibleToDispatch = topicCategory.topics.some((topic: Topic) => {
        return isTopicVisibleToDispatch(topic);
      });
      return { ...topicCategory, isVisibleToDispatch };
    });
  }

  public async populateCategoriesByGroup(categories: TopicCategory[]) {
    if (this.workOrderType && !this.requestType) {
      this._topicService.categories = this._filterCategoriesByWorkOrder(categories);
    } else if (this.requestType && !this.workOrderType) {
      this._topicService.categories = this._filterCategoriesByRequest(categories);
    } else {
      this._topicService.categories = this.addPropertyIsVisibleToDispatch(categories);
    }
    await this._subscribe();
  }

  public async reloadWorkOrderCategories() {
    await this._getAllWorkOrderCategories();
  }
}
