import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isString } from 'lodash';
import { BidPackageStatus, Workspace } from '../../enums';
import { AuthService, ModalService, ProjectService } from '../../services';
import { ConfirmationDialogInjectionData } from '../../types';
import { BidPackage } from '../../workspaces/construction/types';

@Component({
  selector: 'app-bid-package-invoice-menu',
  templateUrl: './bid-package-invoice-menu.component.html',
  styleUrls: ['./bid-package-invoice-menu.component.scss'],
})
export class BidPackageInvoiceMenuComponent implements OnInit {
  @Input() public currentWorkspace: Workspace;
  @Input() public bidPackage: BidPackage;
  @Output() bidPackageUpdated = new EventEmitter<BidPackage>();

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {}

  readonly Workspace = Workspace;
  readonly BidPackageStatus = BidPackageStatus;

  get isProjectAdmin(): boolean {
    return this.authService.isProjectAdmin(this.bidPackage?.project_id, this.currentWorkspace);
  }
  async updateBidPackageStatus(): Promise<void> {
    const newStatus = this.bidPackage?.status_id === 2 ? 1 : 2;
    const confirmationData: ConfirmationDialogInjectionData = {
      titleBarText: newStatus === BidPackageStatus.CLOSED ? `Close Bid Package` : 'Reopen Bid Package',
      headerText: newStatus === BidPackageStatus.CLOSED ? 'Reason for for Closing' : '',
      descriptionText:
        newStatus === BidPackageStatus.CLOSED
          ? 'Please give a reason for for closing this bid package below.'
          : 'This action will open this bid package and allow additional invoices to be added',
      confirmationButtonText: newStatus === BidPackageStatus.CLOSED ? 'Close' : 'Reopen',
    };

    if (newStatus === BidPackageStatus.CLOSED) {
      confirmationData.userInput = {
        placeholder: 'Reason For Closing',
        defaultText:
          this.bidPackage.awarded_amount ===
          (this.bidPackage.billed_amount || this.bidPackage.billed || this.bidPackage.billedTotal)
            ? 'Budget reached'
            : '',
        required: newStatus === BidPackageStatus.CLOSED,
      };
    }

    const res = await this.modalService.openConfirmationDialog(confirmationData).toPromise();

    if (res) {
      const bidPackageData: BidPackage = {
        status_id: newStatus,
        close_reason: (isString(res) && res) || '',
      };

      this.bidPackage = { ...this.bidPackage, ...bidPackageData };
      this.bidPackageUpdated.emit(this.bidPackage);
      await this.projectService
        .updateBidPackage(this.bidPackage?.id, bidPackageData, ['status_id', 'close_reason'])
        .toPromise();
    }
  }
}
