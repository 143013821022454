<!--TODO change all dashboardService.{} to just {}. This is an artifact of keeping the page state when going back. Needs to be optimized-->

<div
  #mainScreen
  (window:resize)="onResize($event)"
  class="container-fluid app-container p-5"
  [class.navToggleClosed]="isSidebarClosed"
>
  <div class="row mb-2">
    <div class="col-auto">
      <h2
        data-test-id="dashboard-headline"
        data-aos-delay="50"
        data-aos="fade-in"
        data-aos-duration="900"
        class="dkblue font-weight-semibold m-0"
        style="line-height: 2.8rem"
      >
        Good {{ timeOfDay }}, {{ currentUser.first_name }}. <br />
        Here's what's happening
        <span
          class="ltblue bg-white br-5 pointer"
          [matMenuTriggerFor]="happ_menu"
          style="border: 1px solid #ddd; padding: 0.2rem 0.6rem 0.35rem 0.7rem"
        >
          <span class="mr-2 d-inline-block">
            {{ dashboardService.timeframePhrase }}
          </span>
          <mat-icon class="dkblue align-middle" style="margin-top: -3px">arrow_drop_down</mat-icon>
        </span>
        <mat-menu #happ_menu="matMenu" xPosition="after">
          <button mat-menu-item color="primary" (click)="setDays('today')">Today</button>
          <button mat-menu-item color="primary" (click)="setDays('this week')">This Week</button>
          <button mat-menu-item color="primary" (click)="setDays('next week')">Next Week</button>
          <button mat-menu-item color="primary" (click)="setDays('this month')">This Month</button>
        </mat-menu>
      </h2>
    </div>
    <div class="col-12 text-left">
      <h5 class="m-0 gray font-weight-semibold pt-3">
        {{ dashboardService.timeExpression }}
      </h5>
    </div>
  </div>
  <div class="row mt-4 dash-tabs align-items-top">
    <div class="col-lg-3 col-md-6 col-sm-12">
      <div
        (click)="
          dashboardService.showTasksTab = true;
          dashboardService.showProjectsTab = false;
          dashboardService.showMeetingsTab = false;
          dashboardService.showRequestsTab = false
        "
        [ngClass]="{ 'dash-tab-selected': dashboardService.showTasksTab }"
        class="m-portlet mb-2 mt-4 item ease p-0 m-portlet--mobile pointer"
      >
        <div class="m-portlet__body ease p-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <p class="dkblue m-0 font-weight-semibold">Tasks Due</p>
                  <h1 class="ltblue m-0 font-weight-normal">
                    {{ getTasks().length }}
                  </h1>
                </div>
                <!-- <div class="col-auto pr-4">
                  <mat-icon class="green pt-2 pb-2">
                    check_circle
                  </mat-icon>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="getTasks(dashboardService.assignedTo, 'overdue_only').length > 0" class="row pl-3">
        <div class="col">
          <p
            class="red m-0 font-weight-normal d-inline-block mr-2"
            [style.visibility]="getTasks(dashboardService.assignedTo, 'overdue_only').length > 0 ? 'visible' : 'hidden'"
          >
            <i class="red mr-1 fa fa-exclamation-circle"></i>
            <span class="font-weight-bold">
              {{ getTasks(dashboardService.assignedTo, 'overdue_only').length }}
            </span>
            Overdue Tasks
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12">
      <div
        (click)="
          dashboardService.showTasksTab = false;
          dashboardService.showProjectsTab = true;
          dashboardService.showMeetingsTab = false;
          dashboardService.showRequestsTab = false
        "
        [ngClass]="{ 'dash-tab-selected': dashboardService.showProjectsTab }"
        class="m-portlet mb-2 mt-4 item ease p-0 m-portlet--mobile pointer"
      >
        <div class="m-portlet__body ease p-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <p class="dkgray m-0 font-weight-semibold">Current Projects</p>
                  <h1 class="ltblue m-0 font-weight-normal">
                    {{ getActiveProjects().length + getOnHoldProjects().length }}
                  </h1>
                </div>
                <!-- <div class="col-auto pr-4">
                  <mat-icon class="ltblue pt-2 pb-2">
                    assignment_turned_in
                  </mat-icon>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="getOnHoldProjects().length > 0" class="row pl-3">
        <div class="col">
          <p
            class="red m-0 font-weight-normal"
            [style.visibility]="getOnHoldProjects().length > 0 ? 'visible' : 'hidden'"
          >
            <i class="red mr-1 fa fa-exclamation-circle"></i>
            <span class="font-weight-bold">
              {{ getOnHoldProjects().length }}
            </span>
            Projects On Hold
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12">
      <div
        (click)="
          dashboardService.showTasksTab = false;
          dashboardService.showProjectsTab = false;
          dashboardService.showMeetingsTab = true;
          dashboardService.showRequestsTab = false
        "
        [ngClass]="{ 'dash-tab-selected': dashboardService.showMeetingsTab }"
        class="m-portlet mb-2 mt-4 item ease p-0 m-portlet--mobile pointer"
      >
        <div class="m-portlet__body ease p-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <p class="dkgray m-0 font-weight-semibold">Meetings</p>
                  <h1 class="ltblue m-0 font-weight-normal">
                    {{ totalMeetings }}
                  </h1>
                </div>
                <!-- <div class="col-auto pr-4">
                  <mat-icon class="ltblue pt-2 pb-2">
                    today
                  </mat-icon>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="getTodaysMeetings().length > 0" class="row pl-3">
        <div class="col">
          <p
            class="gray m-0 font-weight-normal"
            [style.visibility]="getTodaysMeetings().length > 0 ? 'visible' : 'hidden'"
          >
            <span class="font-weight-bold">
              {{ getTodaysMeetings().length }}
            </span>
            Meetings Today
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="authService.isTenant || authService.isStaffOnAnyModule">
      <div
        (click)="
          dashboardService.showTasksTab = false;
          dashboardService.showProjectsTab = false;
          dashboardService.showMeetingsTab = false;
          dashboardService.showRequestsTab = true
        "
        [ngClass]="{ 'dash-tab-selected': dashboardService.showRequestsTab }"
        class="m-portlet mb-2 mt-4 item ease p-0 m-portlet--mobile pointer"
      >
        <div class="m-portlet__body ease p-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <p class="dkgray m-0 font-weight-semibold">Pending Requests</p>
                  <h1 class="ltblue m-0 font-weight-normal mr-2">
                    {{ getPendingRequests().length }}
                  </h1>
                </div>
                <!-- <div class="col-auto pr-4">
                  <mat-icon class="ltblue pt-2 pb-2">
                    assignment_late
                  </mat-icon>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="getNewRequests().length > 0" class="row pl-3">
        <div class="col">
          <p
            class="green m-0 font-weight-normal"
            [style.visibility]="getNewRequests().length > 0 ? 'visible' : 'hidden'"
          >
            <span class="font-weight-bold">
              {{ getNewRequests().length }}
            </span>
            New Requests
          </p>
        </div>
      </div>
    </div>
  </div>

  <section *ngIf="dashboardService.showTasksTab" class="row mt-5">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="row mb-3 align-items-center">
            <div *ngIf="showDesktop" class="col">
              <div class="d-inline-block mr-5" (click)="changeTab('tasks')">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.myTasks,
                    ltgray: !dashboardService.myTasks
                  }"
                >
                  My Tasks
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  *ngIf="getMyTaskData().length > 0"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.myTasks,
                    'bg-ltblue': dashboardService.myTasks,
                    'bg-red': getMyTaskData('overdue_only').length > 0
                  }"
                >
                  {{ getMyTaskData().length }}
                </span>
                <!-- <span class="font-weight-bold number-badge align-middle white bg-red" *ngIf="getMyTaskData('overdue_only').length > 0">
                  {{getMyTaskData('overdue_only').length}}
                </span> -->
              </div>
              <div class="d-inline-block mr-5" (click)="changeTab('team')">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.myTeam,
                    ltgray: !dashboardService.myTeam
                  }"
                >
                  My Team
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="getTeamData().length > 0"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.myTeam,
                    'bg-ltblue': dashboardService.myTeam,
                    'bg-red': getTeamData('overdue_only').length > 0
                  }"
                >
                  {{ getTeamData().length }}
                </span>
                <!-- <span class="font-weight-bold number-badge align-middle white bg-red" *ngIf="getTeamData('overdue_only').length > 0">
                  {{getTeamData('overdue_only').length}}
                </span> -->
              </div>
              <div class="d-inline-block mr-5" (click)="changeTab('following')">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.following,
                    ltgray: !dashboardService.following
                  }"
                >
                  Following
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="getFollowingData().length > 0"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.following,
                    'bg-ltblue': dashboardService.following,
                    'bg-red': getFollowingData('overdue_only').length > 0
                  }"
                >
                  {{ getFollowingData().length }}
                </span>
                <!-- <span class="font-weight-bold number-badge align-middle white bg-red" *ngIf="getFollowingData('overdue_only').length > 0">
                  {{getFollowingData('overdue_only').length}}
                </span> -->
              </div>
              <div class="d-inline-block mr-5" (click)="changeTab('unassigned')" *ngIf="pmProjectIds.length > 0">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.unassigned,
                    ltgray: !dashboardService.unassigned
                  }"
                >
                  Unassigned
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="getUnassignedData().length > 0"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.unassigned,
                    'bg-ltblue': dashboardService.unassigned,
                    'bg-red': getUnassignedData('overdue_only').length > 0
                  }"
                >
                  {{ getUnassignedData().length }}
                </span>
              </div>
            </div>
            <div class="col" *ngIf="showIpad">
              <div [matMenuTriggerFor]="what_tasks">
                <h4 class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer dkblue">
                  <span *ngIf="dashboardService.myTasks"> My Tasks </span>
                  <span *ngIf="dashboardService.myTeam"> My Team </span>
                  <span *ngIf="dashboardService.following"> Following </span>
                  <span *ngIf="dashboardService.unassigned"> Unassigned </span>
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  *ngIf="dashboardService.myTasks"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.myTasks,
                    'bg-ltblue': dashboardService.myTasks,
                    'bg-red': getMyTaskData('overdue_only').length > 0
                  }"
                >
                  {{ getMyTaskData().length }}
                </span>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="dashboardService.myTeam"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.myTeam,
                    'bg-ltblue': dashboardService.myTeam,
                    'bg-red': getTeamData('overdue_only').length > 0
                  }"
                >
                  {{ getTeamData().length }}
                </span>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="dashboardService.following"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.following,
                    'bg-ltblue': dashboardService.following,
                    'bg-red': getFollowingData('overdue_only').length > 0
                  }"
                >
                  {{ getFollowingData().length }}
                </span>
                <span
                  class="font-weight-bold number-badge align-middle bg-ltgray white"
                  *ngIf="dashboardService.unassigned"
                  [ngClass]="{
                    'bg-ltgray': !dashboardService.unassigned,
                    'bg-ltblue': dashboardService.unassigned,
                    'bg-red': getUnassignedData('overdue_only').length > 0
                  }"
                >
                  {{ getUnassignedData().length }}
                </span>
                <button mat-icon-button color="primary">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
              </div>
              <mat-menu #what_tasks="matMenu" xPosition="after">
                <button mat-menu-item class="font-weight-semibold" color="primary" (click)="changeTab('tasks')">
                  My Tasks
                  <span
                    class="font-weight-bold number-badge align-middle white ml-2"
                    [ngClass]="{
                      'bg-ltgray': !dashboardService.myTasks,
                      'bg-ltblue': dashboardService.myTasks,
                      'bg-red': getMyTaskData('overdue_only').length > 0
                    }"
                  >
                    {{ getMyTaskData().length }}
                  </span>
                </button>
                <button mat-menu-item class="font-weight-semibold" color="primary" (click)="changeTab('team')">
                  My Team
                  <span
                    class="font-weight-bold number-badge align-middle bg-ltgray white ml-2"
                    [ngClass]="{
                      'bg-ltgray': !dashboardService.myTeam,
                      'bg-ltblue': dashboardService.myTeam,
                      'bg-red': getTeamData('overdue_only').length > 0
                    }"
                  >
                    {{ getTeamData().length }}
                  </span>
                </button>
                <button mat-menu-item class="font-weight-semibold" color="primary" (click)="changeTab('following')">
                  Following
                  <span
                    class="font-weight-bold number-badge align-middle bg-ltgray white ml-2"
                    [ngClass]="{
                      'bg-ltgray': !dashboardService.following,
                      'bg-ltblue': dashboardService.following,
                      'bg-red': getFollowingData('overdue_only').length > 0
                    }"
                  >
                    {{ getFollowingData().length }}
                  </span>
                </button>
                <button
                  mat-menu-item
                  class="font-weight-semibold"
                  color="primary"
                  (click)="changeTab('unassigned')"
                  *ngIf="pmProjectIds.length > 0"
                >
                  Unassigned
                  <span
                    class="font-weight-bold number-badge align-middle bg-ltgray white ml-2"
                    [ngClass]="{
                      'bg-ltgray': !dashboardService.unassigned,
                      'bg-ltblue': dashboardService.unassigned,
                      'bg-red': getUnassignedData('overdue_only').length > 0
                    }"
                  >
                    {{ getUnassignedData().length }}
                  </span>
                </button>
              </mat-menu>
            </div>
            <div class="col-auto text-right">
              <!-- <div class="d-inline-block align-middle" style="margin-top:-2px;" *ngIf="byAssignee">
                <app-profile-thumbnail class="d-inline-block align-middle pointer hover-up ease" userId="11" [height]="28"
                  [width]="28" style="margin-right:-5px;"></app-profile-thumbnail>
                <app-profile-thumbnail class="d-inline-block align-middle pointer hover-up ease" userId="12" [height]="28"
                  [width]="28" style="margin-right:-5px;"></app-profile-thumbnail>
                <app-profile-thumbnail class="d-inline-block align-middle pointer hover-up ease" userId="13" [height]="28"
                  [width]="28" style="margin-right:-5px;"></app-profile-thumbnail>
                <app-profile-thumbnail class="d-inline-block align-middle pointer hover-up ease" userId="14" [height]="28"
                  [width]="28" style="margin-right:-5px;"></app-profile-thumbnail>
                <app-profile-thumbnail class="d-inline-block align-middle pointer hover-up ease" userId="17" [height]="28"
                  [width]="28" style="margin-right:-5px;"></app-profile-thumbnail>
              </div> -->
              <button mat-button color="accent" (click)="toggleShowNoDueDate()">
                {{ dashboardService.showNoDueDate ? 'Hide No Due Date' : 'Show No Due Date' }}
              </button>
              <button mat-button color="accent" (click)="toggleShowComplete()">
                {{ dashboardService.showComplete ? 'Hide Complete' : 'Show Complete' }}
              </button>
              <button
                mat-stroked-button
                color="accent"
                [matMenuTriggerFor]="view_menu"
                class="pr-1 pl-3 ml-2 bg-white"
                style="line-height: 32px"
              >
                <span>
                  {{ dashboardService.taskSelectorPhrase }}
                </span>
                <mat-icon> arrow_drop_down </mat-icon>
              </button>
              <mat-menu #view_menu="matMenu" xPosition="before">
                <button mat-menu-item color="primary" (click)="changeTaskFilter('all')">All Tasks</button>
                <button mat-menu-item color="primary" (click)="changeTaskFilter('overdue_only')">Overdue</button>
                <button mat-menu-item color="primary" (click)="dashboardService.taskSelector = 'approvals_only'">
                  Approvals
                </button>
              </mat-menu>
              <button
                mat-stroked-button
                color="accent"
                [matMenuTriggerFor]="sort_menu"
                class="pr-1 pl-3 ml-2 bg-white"
                style="line-height: 32px"
              >
                <span>
                  {{ dashboardService.filterSelectorPhrase }}
                </span>
                <mat-icon> arrow_drop_down </mat-icon>
              </button>
              <mat-menu #sort_menu="matMenu" xPosition="before">
                <button (click)="dashboardService.filterSelector = 'date'" mat-menu-item color="primary">
                  By Date
                </button>
                <button
                  (click)="dashboardService.filterSelector = 'assignee'"
                  mat-menu-item
                  color="primary"
                  *ngIf="dashboardService.assignedTo != 'unassigned'"
                >
                  By Assignee
                </button>
              </mat-menu>
            </div>
          </div>

          <div *ngIf="byDate">
            <!--            if we do not have the dashboard unassigned tab click, show the standard view-->
            <div *ngIf="!dashboardService.unassigned">
              <div class="portlet-table-header p-0" *ngIf="getTasks().length > 0">
                <div class="row m-0">
                  <div class="col-auto pl-0">
                    <label class="main-label">
                      Task
                      <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                        class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
                    </label>
                  </div>
                  <div class="col text-right">
                    <label class="main-label">
                      Project
                      <!-- <span *ngIf="sortProperty === 'due_datetime'">
                                      <i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                    </span> -->
                    </label>
                  </div>
                  <div class="col-auto text-right pr-4" style="width: 200px; padding-right: 0.15rem">
                    <label class="main-label">
                      Due
                      <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                          class="la la-arrthaow-up" *ngIf="sortDirection === 'desc'"></i>
                                      </span> -->
                    </label>
                  </div>
                </div>
              </div>
              <div class="m-portlet m-portlet--mobile">
                <app-dashboard-task
                  *ngFor="let task of getTasks()"
                  [task]="task"
                  (forceReload)="forceReloadByTask()"
                ></app-dashboard-task>
              </div>
            </div>

            <!--            If we have the unassigned tab clicked, show the project tab as well-->
            <div *ngIf="dashboardService.unassigned">
              <app-project-select [projects]="getUnassignedProjectData"></app-project-select>
            </div>
            <h5 class="gray" *ngIf="getTasks().length === 0">No Tasks in this Category</h5>
          </div>

          <!--          TODO figure out how to structure this with the unassigned tab (maybe not allow and force by date?)-->
          <div *ngIf="byAssignee">
            <div class="portlet-table-header p-0" *ngIf="getTasksByAssignee().length > 0">
              <div class="row pl-3 pr-3">
                <div class="col">
                  <label class="main-label">
                    Assignee
                    <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
                  </label>
                </div>
                <div class="col-1">
                  <label class="main-label">
                    Type
                    <!-- <span *ngIf="sortProperty === 'due_datetime'">
                                  <i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                </span> -->
                  </label>
                </div>
                <div class="col-2">
                  <label class="main-label">
                    Projects
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                </span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Progress
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                      class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                  </span> -->
                  </label>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
            <app-dashboard-assignee *ngFor="let user of getTasksByAssignee()" [user]="user"></app-dashboard-assignee>
            <h4 *ngIf="getTasksByAssignee().length === 0">No Users with Assigned Tasks</h4>
            <!--            <div *ngIf="getUnassignedData().length > 0">-->
            <!--              <h4 class="font-weight-semibold">Unassigned Tasks</h4>-->
            <!--              <div class="portlet-table-header p-0">-->
            <!--                <div class="row pl-3 pr-3">-->
            <!--                  <div class="col">-->
            <!--                    <label class="main-label">Task</label>-->
            <!--                  </div>-->
            <!--                  <div class="col">-->
            <!--                    <label class="main-label">Project</label>-->
            <!--                  </div>-->
            <!--                  <div class="col text-right pr-5">-->
            <!--                    <label class="main-label">Due</label>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="m-portlet m-portlet&#45;&#45;mobile mb-4">-->
            <!--                <app-dashboard-task *ngFor="let task of getUnassignedData()" [task]="task"></app-dashboard-task>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="dashboardService.showProjectsTab" class="row mt-5">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="row mb-4 align-items-center">
            <div class="col">
              <div class="d-inline-block mr-5" (click)="dashboardService.showActive = true">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.showActive,
                    ltgray: !dashboardService.showActive
                  }"
                >
                  Active
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': dashboardService.showActive,
                    'bg-ltgray': !dashboardService.showActive
                  }"
                >
                  {{ getActiveProjects().length }}
                </span>
              </div>
              <div class="d-inline-block mr-5" (click)="dashboardService.showActive = false">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: !dashboardService.showActive,
                    ltgray: dashboardService.showActive
                  }"
                >
                  On Hold
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': !dashboardService.showActive,
                    'bg-ltgray': dashboardService.showActive
                  }"
                >
                  {{ getOnHoldProjects().length }}
                </span>
              </div>
            </div>
            <div class="col-auto text-right"></div>
          </div>
          <div class="portlet-table-header p-0" *ngIf="getProjects().length > 0">
            <div class="row pl-3 pr-3">
              <div class="col">
                <label class="main-label">
                  Project
                  <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                              class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
                </label>
              </div>
              <div class="col">
                <label class="main-label">
                  Phase
                  <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                              class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                          </span> -->
                </label>
              </div>
              <div class="col">
                <label class="main-label">
                  Progress
                  <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                            </span> -->
                </label>
              </div>
            </div>
          </div>
          <div class="m-portlet m-portlet--mobile mb-4">
            <app-dashboard-project *ngFor="let project of getProjects()" [project]="project"></app-dashboard-project>
          </div>
          <h6 class="gray" *ngIf="getProjects().length === 0">No Projects in this Category</h6>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="dashboardService.showMeetingsTab" class="row mt-5">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="row mb-4 align-items-center">
            <div class="col">
              <div class="d-inline-block mr-5" (click)="dashboardService.showUpcoming = true">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.showUpcoming,
                    ltgray: !dashboardService.showUpcoming
                  }"
                >
                  Upcoming
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': dashboardService.showUpcoming,
                    'bg-ltgray': !dashboardService.showUpcoming
                  }"
                >
                  {{ getUpcomingMeetings().length }}
                </span>
              </div>
              <div class="d-inline-block mr-5" (click)="dashboardService.showUpcoming = false">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: !dashboardService.showUpcoming,
                    ltgray: dashboardService.showUpcoming
                  }"
                >
                  Recent
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': !dashboardService.showUpcoming,
                    'bg-ltgray': dashboardService.showUpcoming
                  }"
                >
                  {{ getRecentMeetings().length }}
                </span>
              </div>
            </div>
            <div class="col-auto text-right"></div>
          </div>
          <div>
            <div class="portlet-table-header p-0" *ngIf="getMeetings().length > 0">
              <div class="row pl-3 pr-3">
                <div class="col">
                  <label class="main-label">
                    Meeting
                    <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Date/Time
                    <!-- <span *ngIf="sortProperty === 'due_datetime'">
                                  <i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                </span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Attendees
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                </span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Agenda
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                      class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                  </span> -->
                  </label>
                </div>
              </div>
            </div>
            <div class="m-portlet m-portlet--mobile mb-4">
              <app-dashboard-meeting *ngFor="let meeting of getMeetings()" [meeting]="meeting"></app-dashboard-meeting>
            </div>
            <h6 class="gray" *ngIf="getMeetings().length === 0">No Meetings in this Category</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="dashboardService.showRequestsTab" class="row mt-5">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="row mb-4 align-items-center">
            <div class="col">
              <div class="d-inline-block mr-5" (click)="dashboardService.showPending = true">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: dashboardService.showPending,
                    ltgray: !dashboardService.showPending
                  }"
                >
                  Pending
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': dashboardService.showPending,
                    'bg-ltgray': !dashboardService.showPending
                  }"
                >
                  {{ getPendingRequests().length }}
                </span>
              </div>
              <div class="d-inline-block mr-5" (click)="dashboardService.showPending = false">
                <h4
                  class="m-0 mr-2 font-weight-semibold d-inline-block align-middle pointer"
                  [ngClass]="{
                    dkblue: !dashboardService.showPending,
                    ltgray: dashboardService.showPending
                  }"
                >
                  Accepted
                </h4>
                <span
                  class="font-weight-bold number-badge align-middle white"
                  [ngClass]="{
                    'bg-ltblue': !dashboardService.showPending,
                    'bg-ltgray': dashboardService.showPending
                  }"
                >
                  {{ getAcceptedRequests().length }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="portlet-table-header p-0" *ngIf="getRequests().length > 0">
              <div class="row pl-3 pr-3">
                <div class="col">
                  <label class="main-label">
                    Request
                    <!-- <span *ngIf="sortProperty === 'created_datetime'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Submitted
                    <!-- <span *ngIf="sortProperty === 'due_datetime'">
                                <i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                              </span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Requestor
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                              </span> -->
                  </label>
                </div>
                <div class="col">
                  <label class="main-label">
                    Budget
                    <!-- <span *ngIf="sortProperty === 'priority_id'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                                    class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i>
                                </span> -->
                  </label>
                </div>
              </div>
            </div>
            <div class="m-portlet m-portlet--mobile mb-4">
              <app-dashboard-request *ngFor="let request of getRequests()" [request]="request"></app-dashboard-request>
            </div>
            <h6 class="gray" *ngIf="getRequests().length === 0">No Requests in this Category</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
