<div id="kendo-content" class="text-left">
  <kendo-pdf-export #arfFile paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 55 }">
    <!-- ADD ARF-PRINT-PDF IF LAST PAGE -->
    <!-- ADD ARF-PRINT-PDF IF LAST PAGE -->
    <!-- ADD ARF-PRINT-PDF IF LAST PAGE -->
    <div class="form-print-pdf arf-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <!-- ONLY SHOW APPROVALS SECTION ON LAST PAGE -->
          <!-- ONLY SHOW APPROVALS SECTION ON LAST PAGE -->
          <!-- ONLY SHOW APPROVALS SECTION ON LAST PAGE -->
          <section *ngIf="pageNum === totalPages" id="approvals" class="mb-4 px-1">
            <p class="m-0 mb-1 font-weight-semibold">
              ARF Created By: {{ authService.currentUser.first_name }}
              {{ authService.currentUser.last_name }}
            </p>
            <div class="d-flex align-items-center p-1 bg-ltgray">
              <p class="m-0 black">
                $25,000.00+ Requires 3 Supplier Solicitations; $100,000.00+ Requires 5 Supplier Solicitations;
                $250,000.00+ Requires competitive bidding process outlined in Part IV (D) of Policy & Procedures; ICO
                Approval Required for $500.00+ IT related items; $2,500+ non-IT related items;
              </p>
            </div>
            <div class="row form-table-header pb-0 align-items-center">
              <div class="col-1 pr-0 pl-0">
                <label class="main-label m-0 p-0 text-uppercase"> Approvals </label>
              </div>
              <div class="col-3 px-0"></div>
              <div class="col pl-0">
                <label class="main-label m-0 p-0 text-uppercase"> Name </label>
              </div>
              <div class="col pl-0">
                <label class="main-label m-0 p-0 text-uppercase"> Initials / Signature </label>
              </div>
              <div class="col-2 pl-0">
                <label class="main-label m-0 p-0 text-uppercase"> Date </label>
              </div>
            </div>
            <section class="form-bid-package">
              <div class="row form-table-line-item pt-1 pb-1 d-flex align-items-center">
                <div class="col-1 pr-0 pl-0">
                  <p class="m-0">
                    <b>Manager</b>
                  </p>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <i class="fa fa-check-square mr-4 ml-0 pl-0"></i>
                  <p class="m-0">Always Required</p>
                </div>
                <div class="col pl-0">
                  <p class="m-0">
                    <strong>
                      {{ workspaceManager?.first_name }}
                      {{ workspaceManager?.last_name }}
                    </strong>
                    <strong *ngIf="project?.dfs_id"> , {{ cto?.first_name }} {{ cto?.last_name }} </strong>
                  </p>
                </div>
                <div class="col pl-0">
                  <p class="m-0 signature">
                    <span *ngIf="datesSigned[workspaceManager?.id]">
                      {{ workspaceManager?.first_name }}
                      {{ workspaceManager?.last_name }}
                    </span>
                    <span *ngIf="cto?.id && datesSigned[cto?.id]"> , {{ cto?.first_name }} {{ cto?.last_name }} </span>
                  </p>
                </div>
                <div class="col-2 pl-0">
                  <p *ngIf="datesSigned[workspaceManager?.id]" class="m-0">
                    {{ formatSignatureDate(datesSigned[workspaceManager?.id]) }}
                  </p>
                </div>
              </div>
              <div class="row form-table-line-item pt-1 pb-1 d-flex align-items-center">
                <div class="col-1 pr-0 pl-0">
                  <p class="m-0">
                    <span *ngIf="!include?.cfmo"> COO </span>
                    <strong *ngIf="include?.cfmo"> COO </strong>
                  </p>
                </div>
                <div class="col-3 px-0 d-flex align-items-center">
                  <i *ngIf="!include?.cfmo" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i *ngIf="include?.cfmo" class="fa fa-check-square mr-4 ml-0 pl-0"></i>
                  <p class="m-0">Required over $2,500.00</p>
                </div>
                <div class="col pl-0">
                  <p *ngIf="include?.cfmo" class="m-0">
                    <strong>{{ cfmo?.first_name }} {{ cfmo?.last_name }}</strong>
                  </p>
                </div>
                <div class="col pl-0">
                  <p *ngIf="datesSigned[cfmo?.id]" class="m-0 signature">
                    {{ cfmo?.first_name }}
                    {{ cfmo?.last_name }}
                  </p>
                </div>
                <div class="col-2 pl-0">
                  <p *ngIf="datesSigned[cfmo?.id]" class="m-0">
                    {{ formatSignatureDate(datesSigned[cfmo?.id]) }}
                  </p>
                </div>
              </div>
              <div class="row form-table-line-item pt-1 pb-1 d-flex align-items-center">
                <div class="col-1 pr-0 pl-0">
                  <p class="m-0">
                    <span *ngIf="!include?.ceo"> CEO </span>
                    <strong *ngIf="include?.ceo"> CEO </strong>
                  </p>
                </div>
                <div class="col pl-0 d-flex align-items-center">
                  <i *ngIf="!include?.ceo" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i *ngIf="include?.ceo" class="fa fa-check-square mr-4 ml-0 pl-0"></i>
                  <p class="m-0">Required over $10,000.00</p>
                </div>
                <div class="col pl-0">
                  <p *ngIf="include?.ceo" class="m-0">
                    <strong>{{ ceo?.first_name }} {{ ceo?.last_name }}</strong>
                  </p>
                </div>
                <div class="col pl-0">
                  <p *ngIf="datesSigned[ceo?.id]" class="m-0 signature">
                    {{ ceo?.first_name }}
                    {{ ceo?.last_name }}
                  </p>
                </div>
                <div class="col-2 pl-0">
                  <p *ngIf="datesSigned[ceo?.id]" class="m-0">
                    {{ formatSignatureDate(datesSigned[ceo?.id]) }}
                  </p>
                </div>
              </div>
            </section>
          </section>
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4 pt-2">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
            <!-- <img
              *ngIf="project?.module?.id === 4 || project?.module?.id === 5"
              id="headerLogo_1call"
              src="../../assets/img/1call_logo.png"
            /> -->
          </div>
          <!-- <div class="col-8" ngIf="arf">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> ARF </label>
                <p class="text-truncate">{{ arf?.code }} {{ arf?.title }}</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Created By </label>
                <p>
                  {{ arf?.created_by_first_name + ' ' + arf?.created_by_last_name }}
                </p>
              </div>
            </div>
          </div> -->
          <div class="col-8" *ngIf="project">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">{{ project?.code }} {{ project?.title }}</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project?.project_manager_first_name + ' ' + project?.project_manager_last_name }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project?.building_code }}-{{ project?.floor_code }}</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ !project?.end_date ? 'Not Set' : (project?.end_date | date: 'd MMMM yyyy') }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> {{ project?.module?.name }} Manager </label>
                <p>
                  {{ project?.workspace_manager_first_name + ' ' + project?.workspace_manager_last_name }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-8" *ngIf="isStandaloneARF">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> ARF </label>
                <p class="text-truncate">{{ arf?.code }} {{ arf?.title }}</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> ARF Creator </label>
                <p>
                  {{ arf?.created_by?.first_name + ' ' + arf?.created_by?.last_name }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>N/A</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>N/A</p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> {{ arf?.module?.name }} Manager </label>
                <p>
                  {{ workspaceManager?.first_name + ' ' + workspaceManager?.last_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section class="form-title border-bottom-heavy">
          <div class="d-flex flex-column">
            <div class="d-flex">
              <p class="bg-dkgray px-2 white br-4 lh-20 mb-2 mr-2">FY{{ fiscalYear }}</p>
              <p class="bg-gray px-2 white br-4 lh-20 mb-2 mr-2">
                {{ module?.name }}
              </p>
              <p class="bg-dkgray px-2 white br-4 lh-20 mb-2 mr-2">
                <span *ngIf="!isStandaloneARF">T74</span>
                <span *ngIf="isStandaloneARF">ARF</span>
              </p>
            </div>
            <span class="row d-flex align-items-top mb-2">
              <div class="col-6 d-flex flex-column">
                <h1 class="black m-0 mb-0">
                  <b>Acquisition Request Form</b>
                </h1>
                <p *ngIf="selectedTenant?.tenant_name" class="black m-0 my-1">Supplier: {{ company?.name }}</p>
              </div>
              <div class="col d-flex flex-column">
                <label class="main-label mt-0 mb-0"> Purchaser </label>
                <p class="m-0" style="font-size: 10px">
                  <b>
                    {{ selectedTenant?.tenant_name || 'UHAT' }}
                  </b>
                </p>
              </div>
              <div class="col d-flex flex-column">
                <label class="main-label mt-0 mb-0"> Total Cost </label>
                <p class="m-0 black" style="font-size: 10px">
                  <b>
                    {{ total | USDollar: 2:true }}
                  </b>
                </p>
              </div>
            </span>
            <div class="row">
              <div class="col-12">
                <p *ngIf="purchaseReason" class="d-flex align-items-top mb-3">
                  <span class="text-nowrap mr-2"> Description: </span>
                  <span [innerHTML]="purchaseReason"></span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="form-highlights mb-0">
          <div class="row">
            <div class="col">
              <div class="row pt-3 pb-2">
                <div class="col-3 d-flex align-items-center">
                  <i *ngIf="purchaseTypeId !== ArfPurchaseTypeId.Vendor" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i *ngIf="purchaseTypeId === ArfPurchaseTypeId.Vendor" class="fa fa-check-square mr-4 ml-0 pl-0"></i>
                  <p class="m-0 black">Supplier Purchase</p>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <i *ngIf="purchaseTypeId !== ArfPurchaseTypeId.CreditCard" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i
                    *ngIf="purchaseTypeId === ArfPurchaseTypeId.CreditCard"
                    class="fa fa-check-square mr-4 ml-0 pl-0"
                  ></i>
                  <p class="m-0 black">Credit Card</p>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <i *ngIf="purchaseTypeId !== ArfPurchaseTypeId.PurchaseOrder" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i
                    *ngIf="purchaseTypeId === ArfPurchaseTypeId.PurchaseOrder"
                    class="fa fa-check-square mr-4 ml-0 pl-0"
                  ></i>
                  <p class="m-0 black">Purchase Order</p>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <i *ngIf="!assetTagged" class="fa fa-square mr-4 ml-0 pl-0"></i>
                  <i *ngIf="assetTagged" class="fa fa-check-square mr-4 ml-0 pl-0"></i>
                  <p class="m-0 black">Needs Asset Tag</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="form-table mt-0 mb-0">
          <section class="bottom-border p-0">
            <div class="row form-table-header mt-0 mb-0 pt-0 pb-0 align-items-center">
              <div class="col-1 text-center pl-0 pr-0">
                <label class="main-label m-0 p-0 text-uppercase"> Item </label>
              </div>
              <div class="col-4 pr-0">
                <label class="main-label m-0 p-0 text-uppercase"> Product </label>
              </div>
              <div class="col-1 text-center pr-0">
                <label class="main-label m-0 p-0 text-uppercase"> QTY </label>
              </div>
              <div class="col-6 pr-0">
                <div class="row m-0 p-2">
                  <div class="col-5 pr-0 pl-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Supplier </label>
                  </div>
                  <div class="col text-right pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Unit Price </label>
                  </div>
                  <div class="col text-right pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Bid Total </label>
                  </div>
                </div>
              </div>
            </div>
            <section>
              <!-- PRODUCT LINE ITEMS PER GROUP -->
              <div
                *ngFor="let p of products | productFilter: selectedQuote?.id:runProductFilter; let i = index"
                class="row d-flex align-items-stretch"
              >
                <div class="col-12">
                  <div class="row form-table-line-item p-0 pb-2 d-flex align-items-top">
                    <div class="col-1 text-center pl-0 pr-0 pt-2 pb-2">
                      <h6 class="m-0">
                        {{ i + 1 }}
                      </h6>
                    </div>
                    <div class="col-4 d-flex flex-column pr-0 pt-2 pb-2">
                      <h6 class="m-0 d-block text-wrap">
                        <!-- Product Name -->
                        {{ p?.product?.name || p?.name }}
                      </h6>
                      <p class="m-0 d-block text-wrap dkgray mb-1" [innerHTML]="p?.description"></p>
                      <p class="m-0 d-block text-wrap black">
                        {{ p.sub_cost_code_budget?.code }} - {{ p.sub_cost_code_budget?.label }} <br />
                        <small>
                          {{ p.sub_cost_code_budget?.cost_code?.code }} |
                          {{ p.sub_cost_code_budget?.cost_code?.label }}
                        </small>
                      </p>
                    </div>
                    <div class="col-1 text-center pr-0 pt-2 pb-2">
                      <h6 class="m-0 font-weight-bold">
                        {{ p.quantity }}
                      </h6>
                    </div>
                    <div class="col-6 pr-0" *ngIf="!selectedQuote">
                      <div class="row m-0 p-2">
                        <div class="col-5 pr-0 pl-0">
                          <h6 class="m-0 font-weight-bold">
                            {{ company?.name }}
                          </h6>
                        </div>
                        <div class="col text-right pr-0">
                          <h6 class="m-0 font-weight-bold">
                            {{ p.unit_price || 0 | USDollar: 2:true }}
                          </h6>
                        </div>
                        <div class="col text-right pr-0">
                          <h6 class="m-0 font-weight-bold">
                            {{ p.total_price || 0 | USDollar: 2:true }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-0" *ngIf="selectedQuote">
                      <span *ngFor="let quoteItem of p?.quote_items">
                        <div
                          *ngIf="
                            [QuoteItemStatus.BidReceived, QuoteItemStatus.DidNotBid].indexOf(quoteItem.status_id) > -1
                          "
                          class="row border-bottom border-gray m-0 p-2 d-flex align-items-stretch"
                          [ngClass]="{
                            'bg-ltgray': quoteItem?.quote_id === selectedQuote?.id,
                            gray: quoteItem?.quote_id !== selectedQuote?.id
                          }"
                        >
                          <div class="col-5 pl-0 pr-0">
                            <h6 class="m-0 font-weight-bold d-block text-truncate">
                              {{ quoteItem?.quote?.company?.name || quoteItem?.label || 'No Name' }}
                            </h6>
                          </div>
                          <div class="col text-right pr-0">
                            <h6
                              class="m-0"
                              [ngClass]="{
                                'unit-price-hundredths-padding': !isThirdDecimalNonZero(quoteItem.unit_price)
                              }"
                            >
                              {{
                                quoteItem.status_id === QuoteItemStatus.DidNotBid
                                  ? ''
                                  : (quoteItem.unit_price || 0
                                    | USDollar: (isThirdDecimalNonZero(quoteItem.unit_price) ? 3 : 2):true)
                              }}
                            </h6>
                          </div>
                          <div class="col text-right pr-0">
                            <h6 *ngIf="quoteItem?.quote_id !== selectedQuote?.id" class="m-0">
                              {{
                                quoteItem.status_id === QuoteItemStatus.DidNotBid
                                  ? 'Did Not Bid'
                                  : (quoteItem.total_price || 0 | USDollar: 2:true)
                              }}
                            </h6>
                            <h6 *ngIf="quoteItem?.quote_id === selectedQuote?.id" class="m-0">
                              <b>
                                {{
                                  quoteItem.status_id === QuoteItemStatus.DidNotBid
                                    ? 'Did Not Bid'
                                    : (quoteItem.total_price || 0 | USDollar: 2:true)
                                }}
                              </b>
                            </h6>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="this.productService.getSelectedQuoteTaxTotal(selectedTenant, selectedQuote)"
                class="row form-table-line-item p-0 d-flex align-items-stretch"
              >
                <div class="col-6 pr-0"></div>
                <div class="col-6 text-right pr-0">
                  <div class="row m-0 px-2 pt-2 pb-0">
                    <div class="col-5 pl-0"></div>
                    <div class="col text-right">
                      <p class="m-0 black">Subtotal</p>
                    </div>
                    <div class="col text-right pr-0">
                      <h6 class="m-0">
                        {{
                          this.productService.getQuoteAwardedSubtotal(selectedTenant, selectedQuote) || 0
                            | USDollar: 2:true
                        }}
                      </h6>
                    </div>
                  </div>
                  <div
                    class="row border-gray m-0 p-2 d-flex align-items-stretch"
                    *ngIf="this.productService.getSelectedQuoteTaxTotal(selectedTenant, selectedQuote)"
                  >
                    <div class="col-5 pl-0"></div>
                    <div class="col text-right">
                      <p class="m-0 black">Sales Tax</p>
                    </div>
                    <div class="col text-right pr-0">
                      <h6 class="m-0">
                        {{
                          this.productService.getSelectedQuoteTaxTotal(selectedTenant, selectedQuote) | USDollar: 2:true
                        }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <div class="row m-0 pt-2">
            <div class="col pl-0">
              <h6 class="m-0 black" style="font-size: 9pt">
                <strong>Purchase Total</strong>
              </h6>
            </div>
            <div class="col-2 text-right pr-0">
              <h6 class="m-0 black" style="font-size: 9pt">
                <strong>
                  {{ this.total | USDollar: 2:true }}
                </strong>
              </h6>
            </div>
          </div>
        </section>
        <!-- <div *ngIf="1 < PEBsToExport.length - 1" class="page-break"></div> -->
      </div>
    </div>
  </kendo-pdf-export>
</div>
