<div class="container-fluid app-container p-5">
  <div class="row align-items-center">
    <div class="col">
      <h3 class="dkblue font-weight-semibold">User Account Requests</h3>
    </div>
  </div>
  <br />
  <div class="row list-filters align-items-center">
    <div class="col-auto">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [(ngModel)]="selectedUserType" (selectionChange)="refresh()">
          <mat-option [value]="0"> All Requests </mat-option>
          <mat-option *ngFor="let ut of userTypes" [value]="ut.id"> {{ ut.name | titlecase }} Requests </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="outline" class="full-width">
        <input
          matInput
          [(ngModel)]="searchTerm"
          placeholder="Search User Requests"
          (ngModelChange)="filterUserRequests()"
        />
        <mat-icon *ngIf="searchTerm" (click)="searchTerm = ''; filterUserRequests()" class="ltgray pointer" matSuffix
          >cancel</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="col"></div>
  </div>
  <br />
  <div class="portlet-table-header">
    <div class="row">
      <div class="col-1">
        <label class="main-label">
          Authorized
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-1">
        <label class="main-label">
          User Type
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-2">
        <label class="main-label">
          Name
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-2">
        <label class="main-label">
          Email Address
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-2">
        <label class="main-label">
          Company/Department
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-2">
        <label class="main-label">
          Title
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-1">
        <label class="main-label">
          Date
          <!-- <span *ngIf="sortProperty === 'xxx'"><i class="la la-arrow-down" *ngIf="sortDirection === 'asc'"></i><i
                  class="la la-arrow-up" *ngIf="sortDirection === 'desc'"></i></span> -->
        </label>
      </div>
      <div class="col-1"></div>
    </div>
  </div>

  <div class="m-portlet m-portlet--mobile">
    <div class="m-portlet__body request ease" *ngFor="let r of filteredUserRequests">
      <div class="row align-items-center">
        <div class="col-1 text-center">
          <mat-icon *ngIf="r.status_id === 2">done</mat-icon>
        </div>
        <div class="col-1">
          <p class="m-0">
            {{ r.type_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0 font-weight-semibold">
            {{ r.full_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0">
            {{ r.email }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0">
            {{ r.company_name || r.department_name }}
          </p>
        </div>
        <div class="col-2">
          <p class="m-0">
            {{ r.title }}
          </p>
        </div>
        <div class="col-1">
          <p class="m-0">
            {{ r.created_datetime | date: 'MM/dd/yyyy' }}
          </p>
        </div>
        <div class="col-1">
          <button mat-icon-button color="primary" [matMenuTriggerFor]="options_menu" class="pull-right">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #options_menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openAccountReview(r)">
              <mat-icon>edit</mat-icon>
              &nbsp; Review Request
            </button>
            <button mat-menu-item (click)="rejectRequest(r)">
              <mat-icon>delete</mat-icon>
              &nbsp; Reject Request
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <!-- <div class="m-portlet__foot">
          <div class="row">
            <div class="col">
              <mat-paginator #paginator [length]="filesCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="getFiles($event.pageSize)">
              </mat-paginator>
            </div>
          </div>
        </div> -->
  </div>

  <div class="not-found-prompt" [hidden]="filteredUserRequests.length !== 0">
    <div class="row my-5">
      <div class="col-md-5 py-5 mx-auto text-center">
        <h5 class="dkblue lh-24">Take a break, you're all caught up!</h5>
        <p class="dkgray lh-24">
          There are currently no pending
          <span *ngIf="selectedUserType === 0"> User </span>
          <span *ngIf="selectedUserType === 1"> Staff </span>
          <span *ngIf="selectedUserType === 2"> Tenant </span>
          <span *ngIf="selectedUserType === 3"> Supplier </span>
          Account Requests.
        </p>
      </div>
    </div>
  </div>
</div>
