import { ResourceType } from 'src/app/enums';

export class UhatFileBridgeLink {
  id: number;
  resourceType: ResourceType;

  constructor(id: number, resourceType: ResourceType) {
    this.id = id;
    this.resourceType = resourceType;
  }
}
