import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { remove } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ResourceType } from 'src/app/enums';
import {
  AuthService,
  ContinuousQualityImprovementService,
  FileService,
  ModalService,
  ResourceTagsService,
  TopicService,
} from 'src/app/services';
import { ContinuousQualityImprovement, ResourceTag, Topic, TopicCategory, UhatFileReference } from 'src/app/types';

@Component({
  selector: 'app-cqi-dialog',
  templateUrl: './cqi-dialog.component.html',
  styleUrls: ['./cqi-dialog.component.scss'],
})
export class CqiDialogComponent implements OnInit {
  private _topicFields =
    'id,name,topic_category_id,topic_category{id,name,topic_group_id,topic_group{id,name}},topic_type_id,topic_type{id,name},workspace_id,workspace{id,name}';

  public continuousQualityImprovementTopic = new FormControl('', Validators.required);
  public email = new FormControl('', [Validators.required, Validators.email]);
  public files = [];
  public filteredContinuousQualityImprovementTopics$: Observable<Topic[]>;
  public loadingTopics = true;
  public submittingCQI = false;
  public summary = new FormControl('', Validators.required);

  constructor(
    private _authService: AuthService,
    private _continuousQualityImprovementService: ContinuousQualityImprovementService,
    private _dialogRef: MatDialogRef<CqiDialogComponent>,
    private _fileService: FileService,
    private _modalService: ModalService,
    private _resourceTagsService: ResourceTagsService,
    private _router: Router,
    private _topicService: TopicService
  ) {}

  ngOnInit(): void {
    // set the current user email and disable the input field
    const currentUserEmail = this._authService?.currentUser?.email || null;
    if (currentUserEmail) {
      this.email.setValue(currentUserEmail);
      this.email.disable();
    }

    void this._getCQITopics();
  }

  private async _getCQITopics() {
    this.loadingTopics = true;
    // grab all CQI categories, and get their ids
    this._topicService.categories = await this._topicService
      .getTopicCategories(['id,name,topic_group_id'], [{ type: 'field', field: 'name', value: 'CQI' }])
      .toPromise();

    const cqiIds = this._topicService?.categories?.map((category: TopicCategory) => category.id);

    // use their ids to get the CQI topics
    this._topicService.topics = await this._topicService
      .getTopics(
        [this._topicFields],
        [{ type: 'field', field: 'topic_category_id', value: cqiIds.join('^') }],
        null,
        'topic_group_name,name'
      )
      .toPromise();

    this.filteredContinuousQualityImprovementTopics$ = this.continuousQualityImprovementTopic.valueChanges.pipe(
      startWith(''),
      map((value: string | Topic) => (typeof value === 'string' ? value : value?.name || '')),
      map(() => this._topicService.topics)
    );

    this.loadingTopics = false;
  }

  public async openUploadModal(): Promise<void> {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
    };
    const files = await this._modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this.files = [...this.files, ...files.computerFiles];
    }
  }

  public removeFile(file: UhatFileReference): void {
    remove(this.files, (f) => f.name === file.name);
  }

  public async submitCqiSuggestion(): Promise<void> {
    const tags = await this._resourceTagsService
      .getResourceTags([
        { type: 'field', field: `resource_type_ids`, value: ResourceType.WorkOrder, match: 'exact' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: 1 },
      ])
      .toPromise();

    const tag_ids = tags
      .filter((tag: ResourceTag) => tag.name?.toUpperCase() === 'CQI')
      ?.map((tag: ResourceTag) => tag.id);

    // only attempt if all the values exist
    if (
      !this.submittingCQI &&
      this.email?.value &&
      this?.continuousQualityImprovementTopic?.value?.id &&
      this.summary?.value
    ) {
      this.submittingCQI = true;
      const cqiWorkOrderData: ContinuousQualityImprovement = {
        topic_id: this?.continuousQualityImprovementTopic?.value?.id || null,
        module_id: this?.continuousQualityImprovementTopic?.value?.workspace_id || null,
        title: this?.continuousQualityImprovementTopic?.value?.name || '',
        summary: this?.summary?.value || '',
        tag_ids,
      };

      const cqiWorkOrder = await this._continuousQualityImprovementService
        .createContinuousQualityImprovement(cqiWorkOrderData)
        .toPromise();
      if (cqiWorkOrder?.id && this.files?.length) {
        // if any files, create them
        for (const file of this.files) {
          await this._fileService.createFile(file, cqiWorkOrder.id, ResourceType.WorkOrder).toPromise();
        }
      }

      if (cqiWorkOrder?.id) {
        this._modalService
          .openConfirmationDialog({
            titleBarText: 'CQI Suggestion',
            headerText: 'Thank you for your improvement suggestion.',
            descriptionText: `Your improvement suggestion will be reviewed by the appropriate team.`,
            cancelButtonText: 'Close',
            confirmationButtonText: 'View Work Order',
          })
          .subscribe((answer: boolean): void => {
            if (answer) {
              void this._router.navigateByUrl(`/work-orders/${cqiWorkOrder.id}`);
            }
          });
      }

      this.close();
      this.submittingCQI = false;
    }
  }

  public close(): void {
    this._dialogRef.close();
  }
}
