import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatepickerHeaderComponent } from 'src/app/components';
import { COMPARE, ResourceType, UserType } from 'src/app/enums';
import { AuthService, ModuleService, ProjectService, TimeLogService, WorkOrderService } from 'src/app/services';
import { APIFilter, Project, TimeLog, TimeLogActivity, WorkOrder } from 'src/app/types';
import { EditorComponent, SearchUserInputComponent } from '..';

@Component({
  selector: 'app-time-log-dialog',
  templateUrl: './time-log-dialog.component.html',
  styleUrls: ['./time-log-dialog.component.scss'],
})
export class TimeLogDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  @ViewChild('staff_member', { static: true })
  private _searchUserInputComponent: SearchUserInputComponent;
  @ViewChild('parentInput', { static: true }) parentInput: ElementRef<HTMLInputElement>;

  private _parentItemFields = ['id', 'code', 'title', 'module_id'];
  private _projects: Project[] = [];
  private _workOrders: WorkOrder[] = [];

  public activities: TimeLogActivity[] = [];
  public companies: { id: number; name: string }[] = [];
  // this is the way the time log was requested to be setup, with time possibilities setup like this
  public hoursWorked = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  public minutesWorked = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  public searchUserPlaceHolder = 'Staff Member';
  public timeLogForm: FormGroup = this._initialFormFields();
  public userTypes: UserType[] = [UserType.Staff];
  public canEditParent = false;
  public timeLog: TimeLog;
  public isSearching = false;
  public parentTypeId = new FormControl(ResourceType.WorkOrder);
  public parentTypes = [
    { name: 'WO', value: ResourceType.WorkOrder },
    { name: 'PRJ', value: ResourceType.Project },
  ];
  public parentSearchInputField = new FormControl('');
  public initialWorkerId = null;

  constructor(
    public dialogRef: MatDialogRef<TimeLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      timeLog: TimeLog;
      canEditParent?: boolean;
      initialParent?: {
        id: number;
        type_id: number;
        code: string;
        title: string;
        module_id: number;
      };
    },
    private _fb: FormBuilder,
    private _timeLogService: TimeLogService,
    private authService: AuthService,
    private workspaceService: ModuleService,
    private workOrderService: WorkOrderService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar
  ) {}

  public customHeader = DatepickerHeaderComponent;

  async ngOnInit() {
    // set the parent of the entry if set
    if (this.data?.timeLog?.parent_type_id) {
      // edits
      this.parentTypeId.setValue(+this.data.timeLog.parent_type_id);
    } else if (this.data?.initialParent?.type_id) {
      this.parentTypeId.setValue(+this.data.initialParent.type_id);
    }

    // subscribe after setting the value
    this.parentTypeId.valueChanges.subscribe(() => this.parentSearchInputField.reset());

    this.parentSearchInputField.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(async (input) => {
        this.isSearching = true;
        // only do the search if they are atleast 4 charactes
        if (!input?.id && input?.trim()?.length > 3) {
          const searchTerm = input?.trim()?.toLowerCase();
          let workspaceIds: number[] = [];
          if (this.canEditParent) {
            workspaceIds = this.workspaceService.userWorkspaces.map((w) => w.id);
          } else {
            workspaceIds.push(+this.timeLog?.module_id || +this.workspaceService?.workspace_id);
          }

          if (+this.parentTypeId.value === ResourceType.WorkOrder) {
            // search work orders
            this._workOrders = await this.workOrderService
              .getWorkOrders(this._parentItemFields, this._parentSearchFilters(workspaceIds, searchTerm))
              .toPromise();
          } else if (+this.parentTypeId.value === ResourceType.Project) {
            // search projecst
            this._projects = await this.projectService
              .getProjects(this._parentItemFields, this._parentSearchFilters(workspaceIds, searchTerm))
              .toPromise();
          }
        } else {
          // clear work orders to start from scratch
          if (this._workOrders.length) {
            this._workOrders = [];
          }

          // clear projects to start from scratch
          if (this._projects.length) {
            this._projects = [];
          }
        }
        this.isSearching = false;
      });
    if (this.data.canEditParent) {
      this.canEditParent = this.data.canEditParent;
    }
    if (this.data.timeLog) {
      this.timeLog = this.data.timeLog;
    }

    try {
      await this._loadActivities();
      await this._loadCompanies();
    } catch (e) {
      this.snackbar.open('Something went wrong! Please try again!');
    }

    await this._activateStaffMemberField();
    await this._populateFormFields();
    await this._activateNotesField();

    if (!this.canEditParent) {
      this.workspace.disable();
      this.parentSearchInputField.disable();
    }
  }

  get possibleWorkspaces() {
    return this.workspaceService.userWorkspaces;
  }

  get activity(): AbstractControl {
    return this.timeLogForm.get('activity');
  }

  get company(): AbstractControl {
    return this.timeLogForm.get('company');
  }

  get date_worked(): AbstractControl {
    return this.timeLogForm.get('date_worked');
  }

  get hours_worked(): AbstractControl {
    return this.timeLogForm.get('hours_worked');
  }

  get minutes_worked(): AbstractControl {
    return this.timeLogForm.get('minutes_worked');
  }

  get validForm() {
    return this.timeLogForm.valid && this.worker?.value?.id;
  }

  get worker(): AbstractControl {
    return this.timeLogForm.get('worker');
  }

  get notes(): AbstractControl {
    return this.timeLogForm.get('notes');
  }

  get workspace(): AbstractControl {
    return this.timeLogForm.get('workspace');
  }

  get parents(): WorkOrder[] | Project[] {
    if (+this.parentTypeId.value === ResourceType.WorkOrder) {
      return this._workOrders;
    } else if (+this.parentTypeId.value === ResourceType.Project) {
      return this._projects;
    }
    return [];
  }

  private async _activateNotesField() {
    this.timeLogForm.addControl('notes', this._editor_component.content);
    this.worker.setValidators([Validators.required]);
    this.worker.updateValueAndValidity();
  }

  private async _activateStaffMemberField() {
    this.timeLogForm.addControl('worker', this._searchUserInputComponent.searchEntry);
    this.worker.setValidators([Validators.required]);
    this.worker.updateValueAndValidity();
  }

  private _initialFormFields() {
    return this._fb.group({
      activity: [null, [Validators.required]],
      company: [null, [Validators.required]],
      date_worked: [new Date(), [Validators.required]],
      hours_worked: [0, [Validators.required]],
      minutes_worked: [0, [Validators.required]],
      workspace: [{ value: '', disabled: this.canEditParent }, [Validators.required]],
    });
  }

  private async _loadActivities() {
    // load all activities
    this.activities = await this._timeLogService.getTimeLogActivities().toPromise();
  }

  private async _loadCompanies() {
    // load all companies
    this.companies = await this._timeLogService.getTimeLogCompanies().toPromise();
  }

  private async _initialParentSearchValue() {
    if (+this.parentTypeId.value === ResourceType.WorkOrder) {
      // search work orders
      this._workOrders = await this.workOrderService
        .getWorkOrders(this._parentItemFields, [
          { type: 'field', field: 'id', value: `${this.timeLog?.parent_id || this.data?.initialParent?.id}` },
        ])
        .toPromise();
    } else if (+this.parentTypeId.value === ResourceType.Project) {
      // search projects
      this._projects = await this.projectService
        .getProjects(this._parentItemFields, [
          { type: 'field', field: 'id', value: `${this.timeLog?.parent_id || this.data?.initialParent?.id}` },
        ])
        .toPromise();
    }

    // should only be one result or none
    if (this.parents?.length === 1) {
      this.parentSearchInputField.setValue(this.parents[0]);
    }
    if (!this.canEditParent) {
      this.workspace.disable();
      this.parentSearchInputField.disable();
    }
  }

  private async _populateFormFields() {
    if (this.timeLog) {
      if (this.timeLog.activity) {
        this.activity.setValue(this.activities.find((activity) => activity.id === this.timeLog.activity?.id));
      }
      if (this.timeLog.company) {
        this.company.setValue(this.companies.find((company) => +company.id === +this.timeLog?.company?.id));
      }
      if (this.timeLog.date_worked) {
        this.date_worked.setValue(moment(this.timeLog.date_worked).toDate());
      }
      if (this.timeLog.hasOwnProperty('hours_worked')) {
        this.hours_worked.setValue(this.timeLog?.hours_worked);
      }
      if (this.timeLog.hasOwnProperty('minutes_worked')) {
        this.minutes_worked.setValue(this.timeLog?.minutes_worked);
      }
      if (this.timeLog.worker) {
        this.worker.setValue(this.timeLog.worker);
        this.initialWorkerId = this.timeLog.worker_id;
      }
      if (this.timeLog.notes) {
        this._editor_component.content.setValue(this.timeLog?.notes);
      }
      if (this.timeLog.parent_id) {
        this.workspace.setValue(this.possibleWorkspaces.find((w) => +w.id === +this.timeLog?.module_id));
        this._initialParentSearchValue();
      } else if (this.timeLog.module_id || this.timeLog?.placeholder_module_id) {
        this.workspace.setValue(
          this.possibleWorkspaces.find(
            (w) => +w.id === +this.timeLog.module_id || +w.id === +this.timeLog.placeholder_module_id
          )
        );
      }
    } else {
      this.worker.setValue(this.authService.currentUser);
      // <!-- IF FM, EVS, Dispatch, default to 1Call ---- All others default to UHAT  -->
      // <!-- option for each company - UHAT, 1Call, OUMI -->
      if (this.workspaceService.workspace_id && this.companies) {
        if ([2, 4, 5].includes(this.workspaceService.workspace_id)) {
          this.company.setValue(this.companies.find((company) => +company.id === 1));
        } else {
          this.company.setValue(this.companies.find((company) => +company.id === 2));
        }
      }
      if (this.data.initialParent) {
        this.workspace.setValue(this.possibleWorkspaces.find((w) => +w.id === +this.data?.initialParent?.module_id));
        this._initialParentSearchValue();
      } else {
        this.workspace.setValue(this.possibleWorkspaces.find((w) => +w.id === +this.workspaceService.workspace_id));
      }
    }
  }

  private _parentSearchFilters(workspaceIds: number[], searchTerm: string): APIFilter[] {
    return [
      {
        type: 'field',
        field: 'module_id',
        value: workspaceIds.join('^'),
      },
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'id', value: `${searchTerm}` },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'code', value: `${searchTerm}`, match: COMPARE.ANY },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'title', value: `${searchTerm}`, match: COMPARE.ANY },
      { type: 'operator', value: ')' },
    ];
  }

  public toggleParent() {
    if (this.workspace?.value?.id) {
      this.parentSearchInputField.enable();
    } else {
      this.parentSearchInputField.disable();
    }
    if (!this.parents.includes(this.parentSearchInputField.value)) {
      this.parentSearchInputField.reset();
    }
  }

  public setRelativeDateWorked(daysFromToday: number) {
    const newDateWorked = moment().add(daysFromToday, 'd').toDate();
    this.date_worked.setValue(newDateWorked);
  }

  submit() {
    const timeLogEntry: TimeLog = {
      worker: this.worker.value,
      date_worked: moment(this.date_worked.value).format('YYYY-MM-DD HH:mm:ss'),
      activity: this.activity.value,
      company: this.company.value,
      hours_worked: +this.hours_worked.value,
      minutes_worked: +this.minutes_worked.value,
      notes: this.notes.value,
      // only save the parent id and parent type id if it is a wo, since default is WO,
      parent_id: this.parentSearchInputField.value?.id || null,
      parent_type_id: this.parentSearchInputField.value?.id ? this.parentTypeId.value : null,
      module_id: this.parentSearchInputField.value?.module_id || this.workspace.value.id,
      parent_code: this.parentSearchInputField.value?.code,
      parent_title: this.parentSearchInputField.value?.title,
    };

    if (this.workspace.value?.id) {
      timeLogEntry.placeholder_module_id = this.workspace.value.id;
    }
    this.dialogRef.close(timeLogEntry);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  public getParentText(value) {
    return value?.id
      ? `${+this.parentTypeId?.value === ResourceType.Project ? 'PRJ ' : ''}${value?.code} ${
          value?.title ? ' - ' + value.title : ''
        }`.trim()
      : '';
  }

  public clearSearchString() {
    this.parentSearchInputField.reset();
  }
}
