import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CompanyItemStatus, ResourceType } from 'src/app/enums';
import { CompanyService, FileService, ProgressIndicatorService } from 'src/app/services';
import { CompanyItem } from 'src/app/types';
import { FileAttachmentDialogComponent } from '../file-attachment-dialog/file-attachment-dialog.component';

@Component({
  selector: 'app-company-profile-documents-dialog',
  templateUrl: './company-profile-documents-dialog.component.html',
  styleUrls: ['./company-profile-documents-dialog.component.scss'],
})
export class CompanyProfileDocumentsDialogComponent implements OnInit {
  action: 'Add' | 'Edit';
  companyItemStatuses = CompanyItemStatus;
  companyItemStatusValues = Object.values(CompanyItemStatus).filter((value) => typeof value === 'number');
  form = this.fb.group({
    id: [this.data.companyItem?.id],
    company_id: [this.data.company_id, [Validators.required]],
    type_id: [this.data.type_id, [Validators.required]],
    status_id: [this.data.companyItem?.status_id, [Validators.required]],
    description: [this.data.companyItem?.description],
    issued_date: [this.data.companyItem?.issued_date, [Validators.required]],
    expiration_date: [this.data.companyItem?.expiration_date, [Validators.required]],
    file: [this.data.companyItem?.file, [Validators.required]],
    file_name: [this.data.companyItem?.file?.name, [Validators.required]],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CompanyProfileDocumentsDialogComponent>,
    private _dialog: MatDialog,
    private fb: FormBuilder,
    private fileService: FileService,
    private companyService: CompanyService,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  ngOnInit(): void {
    this.action = this.data.action;
    this.form.controls['file'].valueChanges.subscribe((file) => {
      this.form.get('file_name').setValue(file.name);
    });
  }

  public addFile() {
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          skipUpload: true,
          allowSearchFromProject: false,
          hideTags: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.computerFiles?.[0]) {
          this.form.get('file').setValue(result.computerFiles[0]);
        }
      });
  }

  async save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    let companyItem = this.form.value;
    const targetItem: CompanyItem = {
      company_id: companyItem.company_id,
      type_id: companyItem.type_id,
      status_id: companyItem.status_id,
      description: companyItem.description,
      issued_date: moment(companyItem.issued_date).format('YYYY-MM-DD'),
      expiration_date: moment(companyItem.expiration_date).format('YYYY-MM-DD'),
    };
    let companyItemResult: CompanyItem;
    if (companyItem.id) {
      companyItemResult = await this.companyService.updateCompanyItem(companyItem.id, targetItem).toPromise();
    } else {
      companyItemResult = await this.companyService.createCompanyItem(targetItem).toPromise();
    }

    if (!companyItem.file?.id) {
      if (this.data.companyItem?.file?.file_id) {
        await this.fileService.deleteFileWithoutConfirmation({ id: this.data.companyItem.file.file_id }).toPromise();
      }
      const file = await this.fileService
        .createFile(companyItem.file, companyItemResult.id, ResourceType.CompanyItems)
        .toPromise();
      file.file_id = file.id;
      companyItemResult.file = file;
    } else {
      companyItemResult.file = companyItem.file;
    }
    this.dialogRef.close(companyItemResult);
    this.progressIndicatorService.close();
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
