import { Component, Input, OnDestroy, OnChanges, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { INPUT_ACTION } from 'src/app/enums';
import { LocationService } from 'src/app/services';
import { DispatchItem, WorkOrder } from 'src/app/types';
import { UserType } from 'src/app/enums';
import { BuildingComponent, DepartmentComponent, FloorComponent, SuiteComponent } from 'src/app/components';
import { LOCATION_NOT_FOUND } from 'src/app/utils';

import { isEqual } from 'lodash';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('buildings', { static: true }) private _buildings_component: BuildingComponent;
  @ViewChild('departments', { static: true }) private _departments_component: DepartmentComponent;
  @ViewChild('floors', { static: true }) private _floors_component: FloorComponent;
  @ViewChild('suites', { static: true }) private _suites_component: SuiteComponent;
  @Input() options: any;
  @Input() userTypeId: UserType;
  @Input() buildingIds: number[];
  @Input() isDispatch: boolean;
  @Input() showLandmarkField = false;

  private _subscribedToBuilding: Subscription;
  private _subscribedToDepartment: Subscription;
  private _subscribedToFloor: Subscription;
  private _subscribedToSuite: Subscription;

  private _showNotFound = false;

  public locationGroup: FormGroup = this._fb.group({
    missing_building_name: [''],
    missing_department_name: [''],
    missing_floor_name: [''],
    missing_suite_name: [''],
    landmark: [''],
  });

  public required_missing_building_name: boolean;
  public required_missing_department_name: boolean;
  public required_missing_floor_name: boolean;
  public required_missing_suite_name: boolean;

  constructor(private _fb: FormBuilder, private _locationService: LocationService) {}

  async ngOnChanges(changes: SimpleChanges) {
    const currentBuildingIds = changes.buildingIds?.currentValue;
    const previousBuildingIds = changes.buildingIds?.previousValue;
    if (
      changes.hasOwnProperty('buildingIds') &&
      (!isEqual(currentBuildingIds, previousBuildingIds) || changes.buildingIds?.firstChange)
    ) {
      await this._buildings_component.loadAllBuildings();
      if (
        this.building?.value?.id &&
        currentBuildingIds !== null &&
        !currentBuildingIds?.find((b) => b === this.building.value.id)
      ) {
        this._buildings_component.building.setValue('', { emitEvent: false });
        await this._resetFloorInput(INPUT_ACTION.DISABLE);
      }
    }
  }

  async ngOnInit() {
    // run options
    if (typeof this.options === 'object') {
      // get options
      const options = Object.keys(this.options);
      if (options.includes('labels')) {
        this._labels(this.options.labels);
      }

      if (options.includes('notFound')) {
        this._notFound(this.options.notFound);
      }

      // if you dont pass options, defaults happen
      // if for pass in all, they are all required
      // you can also pass in the ones required
      if (options.includes('required')) {
        if (!Array.isArray(this.options.required) && typeof this.options.required === 'object') {
          this._requiredFields(this.options.required);
        }
      }
    }

    this._activateLocationFields();
    this.department.disable({ emitEvent: false });
    this.suite.disable({ emitEvent: false });
    this.floor.disable({ emitEvent: false });
  }

  ngOnDestroy() {
    if (this._subscribedToBuilding) {
      this._subscribedToBuilding.unsubscribe();
    }
    if (this._subscribedToDepartment) {
      this._subscribedToDepartment.unsubscribe();
    }
    if (this._subscribedToFloor) {
      this._subscribedToFloor.unsubscribe();
    }
    if (this._subscribedToSuite) {
      this._subscribedToSuite.unsubscribe();
    }
  }

  private _labels(showLabel: boolean) {
    this._buildings_component.showLabel = showLabel;
    this._departments_component.showLabel = showLabel;
    this._floors_component.showLabel = showLabel;
    this._suites_component.showLabel = showLabel;
  }

  private _notFound(addNotFound: boolean) {
    this._showNotFound = addNotFound;
    this._buildings_component.notFound = addNotFound;
    this._departments_component.notFound = addNotFound;
    this._floors_component.notFound = addNotFound;
    this._suites_component.notFound = addNotFound;
  }

  private _requiredFields(required: { building: string; department: string; floor: string; suite: string }) {
    // reset all to not required
    this._buildings_component.required = false;
    this.required_missing_building_name = false;
    this._departments_component.required = false;
    this.required_missing_department_name = false;
    this._floors_component.required = false;
    this.required_missing_floor_name = false;
    this._suites_component.required = false;
    this.required_missing_suite_name = false;

    // set the passed fields
    for (const [field, require] of Object.entries(required)) {
      switch (field) {
        case 'building':
          this._buildings_component.required = !!require;
          this.required_missing_building_name = !!require;
          break;
        case 'department':
          this._departments_component.required = !!require;
          this.required_missing_department_name = !!require;
          break;
        case 'floor':
          this._floors_component.required = !!require;
          this.required_missing_floor_name = !!require;
          break;
        case 'suite':
          this._suites_component.required = !!require;
          this.required_missing_suite_name = !!require;
          break;
        default:
          return;
      }
    }
  }

  get building(): AbstractControl {
    return this.locationGroup.get('building');
  }

  get department(): AbstractControl {
    return this.locationGroup.get('department');
  }

  get floor(): AbstractControl {
    return this.locationGroup.get('floor');
  }

  get missing_building_name(): AbstractControl {
    return this.locationGroup.get('missing_building_name');
  }

  get missing_department_name(): AbstractControl {
    return this.locationGroup.get('missing_department_name');
  }

  get missing_floor_name(): AbstractControl {
    return this.locationGroup.get('missing_floor_name');
  }

  get missing_suite_name(): AbstractControl {
    return this.locationGroup.get('missing_suite_name');
  }

  get suite(): AbstractControl {
    return this.locationGroup.get('suite');
  }

  get landmark(): AbstractControl {
    return this.locationGroup.get('landmark');
  }

  get userTypeIds() {
    return UserType;
  }

  private _activateLocationFields() {
    this.locationGroup.addControl('department', this._departments_component.department);
    this.locationGroup.addControl('suite', this._suites_component.suite);
    this.locationGroup.addControl('floor', this._floors_component.floor);
    this.locationGroup.addControl('building', this._buildings_component.building);

    // then subscribe to each field
    this._subscribeToLocationFields();
  }

  private async _activateBuildingSubscription() {
    this._subscribedToBuilding = this.building.valueChanges.subscribe(async (value) => {
      // should do nothing here
      if (value == null && this.isDispatch) {
        return;
      }

      // comes from a search selection
      if (value?.id) {
        // Remove validations with not found
        this.missing_building_name?.clearValidators();
        this.missing_building_name?.updateValueAndValidity();
        await this._resetFloorInput(INPUT_ACTION.ENABLE, value.id);

        // take care of missing building if exists
        if (this._showNotFound && this.missing_building_name.value) {
          // thats mean you now found it now clear missing
          this.missing_building_name.reset();
        }
      } else if (value && value.id === null) {
        // its the not found
        if (this.floor.disabled) {
          this.floor.enable();
        }
        this.floor.setValue(LOCATION_NOT_FOUND);
        if (this.building.value && this.required_missing_building_name) {
          this.missing_building_name.setValidators([Validators.required]);
          this.missing_building_name.updateValueAndValidity();
        }
      } else if (typeof value?.trim() === 'string' && value?.trim()) {
        const { exists, id, single } = this._buildings_component.valueExist(value?.trim());
        if (this.floor?.enabled && (!exists || !single)) {
          await this._resetFloorInput(INPUT_ACTION.DISABLE);
        } else if (!this.floor?.value && exists && id) {
          await this._resetFloorInput(INPUT_ACTION.ENABLE, id);
        }
      } else if (!value?.trim()) {
        // clear floors
        await this._resetFloorInput(INPUT_ACTION.DISABLE);

        // take care of missing building if exists
        if (this._showNotFound && this.missing_building_name.value) {
          // thats mean you now found it now clear missing
          this.missing_building_name.clearValidators();
          this.missing_building_name.updateValueAndValidity();
          this.missing_building_name.reset();
        }
      }
    });
  }

  private async _activateDepartmentSubscription() {
    this._subscribedToDepartment = this.department.valueChanges.subscribe(async (value) => {
      // should do nothing here
      if (value == null && this.isDispatch) {
        return;
      }

      if (value?.id) {
        // remove validation
        this.missing_department_name?.clearValidators();
        this.missing_department_name?.updateValueAndValidity();
        // TODO stuff with value
        // take care of missing department if exists
        if (this.missing_department_name?.value) {
          // thats mean you now found it now clear missing
          this.missing_department_name?.reset();
        }
      } else if (value && value.id === null && this.department?.value && this.required_missing_department_name) {
        // its the not found
        // TODO stuff when not found
        this.missing_department_name.setValidators([Validators.required]);
        this.missing_department_name.updateValueAndValidity();
      } else if (value && typeof value !== 'object' && typeof value?.trim() === 'string' && value?.trim()) {
        // this function returns exists, id, single if needed
        this._departments_component.valueExist(value?.trim());
      } else if (typeof value !== 'object' && !value?.trim()) {
        // TODO clean up, since there is no value
        // take care of missing department if exists
        if (this._showNotFound && this.missing_department_name.value) {
          // thats mean you now found it now clear missing
          this.missing_department_name.reset();
        }
      }
    });
  }

  private async _activateFloorSubscription() {
    this._subscribedToFloor = this.floor.valueChanges.subscribe(async (value) => {
      // should do nothing here
      if (value == null && this.isDispatch) {
        return;
      }
      if (value?.id) {
        this.missing_floor_name?.clearValidators();
        this.missing_floor_name?.updateValueAndValidity();
        await this._resetSuiteInput(INPUT_ACTION.ENABLE, value.id);

        // take care of missing floor if exists
        if (this.missing_floor_name?.value) {
          // thats mean you now found it now clear missing
          this.missing_floor_name.reset();
        }
      } else if (value && value.id === null) {
        // its the not found
        if (this.suite.disabled) {
          this.suite.enable();
        }
        this.suite.setValue(LOCATION_NOT_FOUND);
        if (this.floor.value && this.required_missing_floor_name) {
          this.missing_floor_name.setValidators([Validators.required]);
          this.missing_floor_name.updateValueAndValidity();
        }
      } else if (typeof value?.trim() === 'string' && value?.trim()) {
        const { exists, id, single } = this._floors_component.valueExist(value?.trim());
        if (this.floor?.enabled && (!exists || !single)) {
          await this._resetSuiteInput(INPUT_ACTION.DISABLE);
        } else if (!this.floor?.value && exists && id) {
          await this._resetSuiteInput(INPUT_ACTION.ENABLE, id);
        }
      } else if (!value?.trim()) {
        // clear suites
        await this._resetSuiteInput(INPUT_ACTION.DISABLE);
        // take care of missing floor if exists
        if (this._showNotFound && this.missing_floor_name.value) {
          // thats mean you now found it now clear missing
          this.missing_floor_name.reset();
        }
      }
    });
  }

  private async _activateSuiteSubscription() {
    this._subscribedToSuite = this.suite.valueChanges.subscribe(async (value) => {
      // should do nothing here
      if (value == null && this.isDispatch) {
        return;
      }
      if (value?.id) {
        this.missing_suite_name?.clearValidators();
        this.missing_suite_name?.updateValueAndValidity();
        await this._resetDepartmentInput(INPUT_ACTION.ENABLE, value.id);
        // take care of missing suite if exists
        if (this._showNotFound && this.missing_suite_name.value) {
          // thats mean you now found it now clear missing
          this.missing_suite_name.reset();
        }
      } else if (value && value.id === null) {
        // its the not found
        if (this.department.disabled) {
          this.department.enable();
        }
        this.department.setValue(LOCATION_NOT_FOUND);
        if (this.suite.value && this.required_missing_suite_name) {
          this.missing_suite_name.setValidators([Validators.required]);
          this.missing_suite_name.updateValueAndValidity();
        }
      } else if (typeof value?.trim() === 'string' && value?.trim()) {
        const { exists, id, single } = this._suites_component.valueExist(value?.trim());
        if (this.department?.enabled && (!exists || !single)) {
          await this._resetDepartmentInput(INPUT_ACTION.DISABLE);
        } else if (!this.floor?.value && exists && id) {
          await this._resetDepartmentInput(INPUT_ACTION.ENABLE, id);
        }
      } else if (!value?.trim()) {
        if (this.suite?.value?.id !== null) {
          await this._resetDepartmentInput(INPUT_ACTION.DISABLE);
        }
        // take care of missing suite if exists
        if (this._showNotFound && this.missing_suite_name.value) {
          // thats mean you now found it now clear missing
          this.missing_suite_name.reset();
        }
      }
    });
  }

  public async resetBuildingInput() {
    this._buildings_component.clearInput();
    this._buildings_component.loadAllBuildings();
  }

  private async _resetDepartmentInput(action: INPUT_ACTION, suiteId?: number, departmentId?: number) {
    if (action === INPUT_ACTION.DISABLE) {
      // clear department value
      this._departments_component?.clearInput();

      this.department?.disable({ emitEvent: false });
      this._locationService.departments = [];
      // may be reload all, filtering accondingly
    } else if (action === INPUT_ACTION.ENABLE) {
      // get Departments by suite id
      await this._departments_component?.getDepartmentsBySuiteId(+suiteId, +departmentId);
      this._departments_component?.clearInput();
      // check if we have departments
      if (this._locationService.departments.length > 0 && this.suite?.value?.id) {
        if (this.department?.disabled) {
          this.department.enable({ emitEvent: false });
        }
      } else {
        if (this.department?.enabled) {
          this.department.disable({ emitEvent: false });
        }
      }
    }
  }

  private async _resetFloorInput(action: INPUT_ACTION, buildingId?: number, floorId?: number) {
    if (action === INPUT_ACTION.DISABLE) {
      // clear floors
      this._floors_component?.clearInput();
      this.floor?.disable({ emitEvent: false });
      this._locationService.floors = [];
    } else if (action === INPUT_ACTION.ENABLE) {
      // get Floors
      await this._floors_component?.getFloorsByBuildId(+buildingId, +floorId);
      this._floors_component?.clearInput();
      // check if we have floors
      if (this._locationService?.floors?.length > 0 && this.building?.value?.id) {
        // only enable if disabled
        if (this.floor.disabled) {
          this.floor?.enable({ emitEvent: false });
        }
      } else {
        // only disable if enabled
        if (this.floor.enabled) {
          this.floor?.disable({ emitEvent: false });
        }
      }
    }
  }

  private async _resetSuiteInput(action: INPUT_ACTION, floorId?: number, suiteId?: number) {
    if (action === INPUT_ACTION.DISABLE) {
      // clear floors
      this._suites_component?.clearInput();
      if (this.suite?.enabled) {
        this.suite.disable({ emitEvent: false });
      }
      this._locationService.suites = [];
    } else if (action === INPUT_ACTION.ENABLE) {
      // get Suites
      await this._suites_component?.getSuitesByFloorId(+floorId, +suiteId);
      this._suites_component?.clearInput();
      // check if we have suites
      if (this._locationService?.suites?.length > 0 && this.floor?.value?.id) {
        if (this.suite?.disabled) {
          this.suite.enable({ emitEvent: false });
        }
      } else {
        if (this.suite?.enabled) {
          this.suite.disable({ emitEvent: false });
        }
      }
    }
  }

  private async _subscribeToLocationFields() {
    // validation is here, so order matters here
    // Suite controls Department
    // Floor controls Suites
    // Building controls Floor
    await this._activateDepartmentSubscription();
    await this._activateSuiteSubscription();
    await this._activateFloorSubscription();
    await this._activateBuildingSubscription();
  }

  public clear_missing_building_name() {
    this.missing_building_name.setValue('');
  }

  public clear_missing_department_name() {
    this.missing_department_name.setValue('');
  }

  public clear_missing_floor_name() {
    this.missing_floor_name.setValue('');
  }

  public clear_missing_suite_name() {
    this.missing_suite_name.setValue('');
  }

  public clear_landmark() {
    this.landmark.setValue('');
  }
  public async populateLocation(item?: WorkOrder | DispatchItem) {
    if (item?.building) {
      this._buildings_component.initialBuildingId = +item.building.id;
      this.building.setValue(item?.building, { emitEvent: false });
      await this._resetFloorInput(INPUT_ACTION.ENABLE, +item.building.id, +item.floor?.id);
    } else {
      this._buildings_component.initialBuildingId = null;
      this.building.setValue(null, { emitEvent: false });
      this._buildings_component.loadAllBuildings();
      await this._resetFloorInput(INPUT_ACTION.DISABLE);
    }

    if (item?.floor) {
      this.floor.setValue(item?.floor, { emitEvent: false });
      await this._resetSuiteInput(INPUT_ACTION.ENABLE, item.floor.id, item.suite?.id);
    }

    if (item?.suite) {
      this.suite.setValue(item?.suite, { emitEvent: false });
      await this._resetDepartmentInput(INPUT_ACTION.ENABLE, item.suite.id, item.department?.id);
    }

    if (item?.department) {
      this.department.setValue(item?.department, { emitEvent: false });
    }

    if (item?.landmark) {
      this.landmark.setValue(item.landmark, { emitEvent: false });
    }
  }
}
