import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-troubleshoot-login-dialog',
  templateUrl: './troubleshoot-login-dialog.component.html',
  styleUrls: ['./troubleshoot-login-dialog.component.scss'],
})
export class TroubleshootLoginDialogComponent implements OnInit {
  @Input() draggable = true;
  @Input() title = 'Troubleshoot Login';

  public dataLoaded = false;
  public userData;
  public invalidEmail = false;
  private lastTestedEmail;

  public email: string;

  get usersName() {
    return `${this.userData?.first_name} ${this.userData?.last_name}`;
  }
  get accountExists() {
    return !!this.userData?.id;
  }
  get settingsCorrect() {
    return !!(this.userData?.is_login_enabled && this.userData?.is_email_enabled);
  }
  get accountConfirmed() {
    return !this.userData?.confirmation_hash || this.usesAD;
  }
  get usesAD() {
    return !!this.userData?.ldap_id;
  }
  get existsInAD() {
    return !!this.userData?.exists_in_ad;
  }
  get ldapName() {
    return this.userData?.ldap_name || 'N/A';
  }
  get ldapResetLink() {
    return this.userData?.ldap_reset_link || 'N/A';
  }

  constructor(private authService: AuthService, private dialogRef: MatDialogRef<TroubleshootLoginDialogComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  public async submit() {
    if (this.lastTestedEmail && this.lastTestedEmail === this.email) {
      return;
    }

    this.lastTestedEmail = this.email;
    await this.authService
      .troubleshootLogin(this.email)
      .toPromise()
      .then((data) => {
        this.invalidEmail = false;
        this.userData = data;
        this.dataLoaded = true;
      })
      .catch((e) => {
        this.invalidEmail = true;
        this.userData = null;
        this.dataLoaded = false;
      });
  }
}
