<button
  mat-icon-button
  [matMenuTriggerFor]="parent_menu"
  color="primary"
  class="ml-auto"
  *ngIf="currentWorkspace === Workspace.Construction"
>
  <mat-icon *ngIf="bidPackage?.status_id === BidPackageStatus.OPEN || !bidPackage?.status_id">more_vert</mat-icon>
  <mat-icon *ngIf="bidPackage?.status_id === BidPackageStatus.CLOSED">lock</mat-icon>
</button>
<mat-menu #parent_menu="matMenu" xPosition="before">
  <button
    mat-menu-item
    *ngIf="bidPackage?.status_id === BidPackageStatus.OPEN || !bidPackage?.status_id"
    [disabled]="!bidPackage?.can_close || !isProjectAdmin"
    (click)="updateBidPackageStatus()"
  >
    <mat-icon>edit</mat-icon>
    Close Bid Package
  </button>
  <div class="p-4 d-flex flex-column" *ngIf="bidPackage?.status_id === BidPackageStatus.CLOSED">
    <h6 class="primary">Reason for Closing Bid Package</h6>
    <p class="dkgray mb-0" [innerHTML]="bidPackage?.close_reason"></p>
    <span>
      <button mat-flat-button color="warn" class="mt-3" (click)="updateBidPackageStatus()" [disabled]="!isProjectAdmin">
        Reopen
      </button>
    </span>
  </div>
</mat-menu>
