<app-base-dialog title="Review Form" (closeDialog)="cancel()"></app-base-dialog>
<div
  mat-dialog-content
  data-test-id="task-review-dialog"
  class="task-review-dialog bg-shade-secondary p-4"
  id="task-review-dialog"
>
  <div class="flex-row d-flex align-items-stretch m-0 main-row">
    <div class="col-8 d-flex flex-column pl-0">
      <div *ngIf="reviewFiles.length !== 1" class="d-flex full-width align-items-top mb-3">
        <button
          mat-stroked-button
          *ngFor="let file of reviewFiles; let i = index"
          class="mr-2 mb-2"
          color="primary"
          [ngClass]="{
            'bg-ltgray': (selectedFile?.id || selectedFile?.file_id) === (file.id || file.file_id),
            'bg-white': (selectedFile?.id || selectedFile?.file_id) !== (file.id || file.file_id)
          }"
          (click)="updateSelectedFile(file)"
        >
          {{ file.name }}
        </button>
      </div>
      <app-file-preview class="flex-grow-1" *ngIf="reviewFiles?.length" [file]="selectedFile"></app-file-preview>
    </div>
    <div class="col-4 align-self-stretch pr-0 d-flex flex-column sidebar-scroll">
      <div class="d-flex flex-column">
        <app-review-sidebar [task]="task" (closeDialog)="submitApproval($event)"></app-review-sidebar>
      </div>
    </div>
  </div>
</div>
