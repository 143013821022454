import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { flatten, orderBy, remove, sortBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import { ViewTaskDialogComponent } from 'src/app/components';
import { Order, ORDER_FIELD, ProjectStatus, TaskStatus, UserType, WorkOrderStatus } from 'src/app/enums';
import { WorkOrderListFilter } from 'src/app/models';
import {
  AuthService,
  ModuleService,
  NotificationService,
  ProgressIndicatorService,
  SearchService,
  SidenavLinksService,
  SidenavService,
} from 'src/app/services';
import { Project, ProjectUpdate, Task, User, WorkOrder, WorkOrderUpdate } from 'src/app/types';
import { DashboardService } from 'src/app/workspaces/construction/services';

@Component({
  selector: 'app-workspace-dashboard',
  templateUrl: './workspace-dashboard.component.html',
  styleUrls: ['./workspace-dashboard.component.scss'],
})
export class WorkspaceDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', { static: true })
  private _drawer_component: MatSidenav;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('workOrderPaginator') workOrderPaginator: MatPaginator;
  @ViewChild('taskPaginator') taskPaginator: MatPaginator;
  @ViewChild('projectPaginator') projectPaginator: MatPaginator;

  constructor(
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private moduleService: ModuleService,
    public notificationService: NotificationService,
    private progressIndicatorService: ProgressIndicatorService,
    public searchService: SearchService,
    private sidenavLinksService: SidenavLinksService,
    private _sidenavService: SidenavService
  ) {}

  public loading = true;
  public isLoadingLatestUpdates = false;
  public divWidth: number;
  private data: {
    activeWorkOrdersCount: number;
    unassignedWorkOrdersCount: number;
    overdueWorkOrdersCount: number;
    onHoldWorkOrdersCount: number;
    activeProjectsCount: number;
    onHoldProjectsCount: number;
    pendingRequestsCount: number;
    noCommWorkOrdersCount: number;
    workOrders: WorkOrder[];
    tasks: Task[];
    projects: Project[];
  };
  public workOrders: WorkOrder[] = [];
  public tasks: Task[] = [];
  public projects: Project[] = [];
  public filteredWorkOrders: WorkOrder[] = [];
  public filteredTasks: Task[] = [];
  public filteredProjects: Project[] = [];
  public workOrderCardClicked: string;
  public taskCardClicked: string;
  public projectCardClicked: string;
  public globalCounts = {
    activeWorkOrders: 0,
    unassignedWorkOrders: 0,
    overdueWorkOrders: 0,
    onHoldWorkOrders: 0,
    activeProjects: 0,
    onHoldProjects: 0,
    pendingRequests: 0,
    noCommWorkOrders: 0,
  };
  public filteredCounts = {
    activeWorkOrders: 0,
    overdueWorkOrders: 0,
    onHoldWorkOrders: 0,
    unassignedWorkOrders: 0,
    activeTasks: 0,
    overdueTasks: 0,
    onHoldTasks: 0,
    unassignedTasks: 0,
    activeProjects: 0,
    behindScheduleProjects: 0,
    onHoldProjects: 0,
  };
  private shownWorkspaceId: number;
  public pageSize = 10;
  public pageIndexes = {
    workOrderStart: 0,
    workOrderEnd: this.pageSize,
    taskStart: 0,
    taskEnd: this.pageSize,
    projectStart: 0,
    projectEnd: this.pageSize,
  };
  public workOrderAssignees: User[];
  public taskAssignees: User[];
  public projectPMs: User[];
  public allAssignees: User[];
  public allStaffAssignees: User[];
  public selectedWorkOrderAssigneeIds = [];
  public selectedTaskAssigneeIds = [];
  public selectedProjectPMIds = [];
  public filteredUser;
  public allWorkOrderAssigneesSelected = false;
  public allTaskAssigneesSelected = false;
  public allProjectPMsSelected = false;
  public taskSortOptions: { label: string; name: string; getValue: any }[] = [
    { label: 'Due Date', name: 'due_date', getValue: (t) => t.due_date },
    { label: 'Status', name: 'status', getValue: (t) => t.status_id },
    {
      label: 'Assignee',
      name: 'assignee',
      getValue: (t) => `${t.assigned_user_first_name} ${t.assigned_user_last_name}`,
    },
  ];
  public taskSortOption: any;
  public selectedTab = 'workOrders';
  public selectedLatestUpdates: 'allUpdates' | 'projectUpdates' | 'workOrderUpdates' = 'allUpdates';
  public projectAndworkOrderUpdates: ProjectUpdate[] | WorkOrderUpdate[];
  public projectUpdates: ProjectUpdate[] = [];
  public workOrderUpdates: WorkOrderUpdate[] = [];
  private currentSubscription;
  public asideOpen = true;
  public dashboard_project_sort_direction: Order = Order.ASC;
  public dashboard_work_order_sort_direction: Order = Order.ASC;
  public fieldToSortProjectBy: string;
  public fieldToSortWorkOrderBy: string;
  public orderByField = ORDER_FIELD;
  public workOrderFilter: WorkOrderListFilter = new WorkOrderListFilter();

  get statusIds() {
    return WorkOrderStatus;
  }

  get workspace() {
    return this.moduleService.workspace;
  }

  get isSidenavClosed(): boolean {
    return this._sidenavService.isSidenavClosed;
  }

  // Since code is used by the backend, but, we want to use the numeric sorting,
  // We will use a custom variable to sort, if its code
  public get customizedProjectFieldToSortBy(): string {
    if (this.fieldToSortProjectBy === ORDER_FIELD.CODE) {
      return ORDER_FIELD.SORT_CODE;
    }
    return this.fieldToSortProjectBy;
  }

  public get customizedWorkOrderFieldToSortBy(): string {
    if (this.fieldToSortWorkOrderBy === ORDER_FIELD.CODE) {
      return ORDER_FIELD.SORT_CODE;
    }
    return this.fieldToSortWorkOrderBy;
  }

  async ngOnInit() {
    setTimeout(async () => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.dashboard);
    });

    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.fieldToSortProjectBy = preferences?.dashboard_project_sort ?? 'code';
    this.fieldToSortWorkOrderBy = preferences?.dashboard_work_order_sort ?? 'code';
    this.dashboard_project_sort_direction = preferences?.dashboard_project_sort_direction ?? 'asc';
    this.dashboard_work_order_sort_direction = preferences?.dashboard_work_order_sort_direction ?? 'asc';
    this.selectedTab = preferences?.dashboard_selected_tab ?? 'workOrders';
    this.taskSortOption = preferences?.dashboard_task_sort
      ? this.taskSortOptions.find((o) => o.name === preferences.dashboard_task_sort) || this.taskSortOptions[0]
      : this.taskSortOptions[0];

    this._getDivWidth();
    if (this.workspace?.id) {
      await this.refresh();
      this.filterAllByUserId(null);
    }

    // keep track of subscription so we can destroy it when the component is destroyed
    this.currentSubscription = this.moduleService.selectWorkspaceEvent.subscribe(async (workspace) => {
      if (this.shownWorkspaceId !== workspace.id) {
        await this.refresh(false);
        this.filterAllByUserId(null);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  private async _getUpdates() {
    this.isLoadingLatestUpdates = true;
    // reset
    this.projectAndworkOrderUpdates = [];
    this.projectUpdates = [];
    this.workOrderUpdates = [];
    if (this.selectedLatestUpdates === 'allUpdates') {
      // we get the projects and workorder updates
      // the arguments are: module_id: number, getWorkOrders: boolean, getTasks: boolean, getProjects: boolean, getProjectUpdates: boolean = true, getWorkOrderUpdates: boolean = true
      const projectAndworkOrderUpdateData = await this.dashboardService
        .getWorkspaceDashboardData(this.workspace.id, false, false, false, true, true)
        .toPromise();

      const projectAndworkOrderUpdates = orderBy(
        flatten([
          projectAndworkOrderUpdateData?.projectUpdates ?? [],
          projectAndworkOrderUpdateData?.workOrderUpdates ?? [],
        ]),
        (update) => update?.created_datetime,
        ['desc']
      ).map((update: ProjectUpdate | WorkOrderUpdate) => {
        update.collapseMessageView = true;
        update.showCollapseMessageViewButton = false;
        return update;
      });

      this.projectAndworkOrderUpdates = projectAndworkOrderUpdates;
    } else if (this.selectedLatestUpdates === 'projectUpdates') {
      // we get the project updates
      // the arguments are: module_id: number, getWorkOrders: boolean, getTasks: boolean, getProjects: boolean, getProjectUpdates: boolean = true, getWorkOrderUpdates: boolean = false

      const projectUpdateData = await this.dashboardService
        .getWorkspaceDashboardData(this.workspace.id, false, false, false, true)
        .toPromise();

      this.projectUpdates = projectUpdateData?.projectUpdates?.map((update: ProjectUpdate) => {
        update.collapseMessageView = true;
        update.showCollapseMessageViewButton = false;
        return update;
      });
    } else if (this.selectedLatestUpdates === 'workOrderUpdates') {
      // we get the workorder updates
      // the arguments are: module_id: number, getWorkOrders: boolean, getTasks: boolean, getProjects: boolean, getProjectUpdates: boolean = false, getWorkOrderUpdates: boolean = true
      const workOrderUpdateData = await this.dashboardService
        .getWorkspaceDashboardData(this.workspace.id, false, false, false, false, true)
        .toPromise();
      this.workOrderUpdates = workOrderUpdateData?.workOrderUpdates?.map((update: ProjectUpdate) => {
        update.collapseMessageView = true;
        update.showCollapseMessageViewButton = false;
        return update;
      });
    }

    this.isLoadingLatestUpdates = false;
  }

  public async openDrawer(): Promise<void> {
    void this._drawer_component.open();
    await this.updateSelectedUpdateTab('allUpdates');
  }

  public async updateSelectedUpdateTab(
    selectedLatestUpdateTab: 'allUpdates' | 'projectUpdates' | 'workOrderUpdates'
  ): Promise<void> {
    this.selectedLatestUpdates = selectedLatestUpdateTab;
    await this._getUpdates();
  }

  private async refresh(runFilter = true) {
    this.loading = true;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus();
    this.shownWorkspaceId = this.workspace?.id;
    // load the tasks, projects and work orders with only one updates each
    // the arguments are: module_id: number, getWorkOrders: boolean, getTasks: boolean, getProjects: boolean, getProjectUpdates: boolean = false, getWorkOrderUpdates: boolean = false
    this.data = await this.dashboardService.getWorkspaceDashboardData(this.workspace.id, true, true, true).toPromise();
    this.globalCounts.activeWorkOrders = this.data.activeWorkOrdersCount;
    this.globalCounts.unassignedWorkOrders = this.data.unassignedWorkOrdersCount;
    this.globalCounts.overdueWorkOrders = this.data.overdueWorkOrdersCount;
    this.globalCounts.onHoldWorkOrders = this.data.onHoldWorkOrdersCount;
    this.globalCounts.activeProjects = this.data.activeProjectsCount;
    this.globalCounts.onHoldProjects = this.data.onHoldProjectsCount;
    this.globalCounts.pendingRequests = this.data.pendingRequestsCount;
    this.globalCounts.noCommWorkOrders = this.data.noCommWorkOrdersCount;
    this.workOrders = this._processWorkOrders(this.data.workOrders);
    this.tasks = this.data.tasks;
    this.projects = this._processProjects(this.data.projects);

    this.workOrderAssignees = sortBy(
      uniqBy(
        (this.workOrders || []).filter((wo) => wo.assigned_user).map((wo) => wo.assigned_user),
        (a: User) => a.id
      ),
      (u) => `${u.first_name} ${u.last_name}`
    );
    this.taskAssignees = sortBy(
      uniqBy(
        (this.tasks || [])
          .filter((t) => t.assigned_user_id)
          .map((t) => ({
            id: t.assigned_user_id,
            email: t.assigned_user_email,
            user_type_id: t.assigned_user_type_id,
            first_name: t.assigned_user_first_name,
            last_name: t.assigned_user_last_name,
          })),
        (a: User) => a.id
      ),
      (u) => `${u.first_name} ${u.last_name}`
    );
    this.projectPMs = sortBy(
      uniqBy(
        (this.projects || []).filter((p) => p.project_manager).map((p) => p.project_manager),
        (pm: User) => pm.id
      ),
      (u) => `${u.first_name} ${u.last_name}`
    );
    this.allAssignees = sortBy(
      uniqBy([...this.workOrderAssignees, ...this.taskAssignees, ...this.projectPMs], (a: User) => a.id),
      (u) => `${u.first_name} ${u.last_name}`
    );
    this.allStaffAssignees = this.allAssignees.filter((a) => a.user_type_id === UserType.Staff);
    if (runFilter) {
      this.filterWorkOrders();
      this.filterTasks();
      this.filterProjects();
    }

    this.progressIndicatorService.close();
    this.loading = false;
  }

  public async tabChanged(): Promise<void> {
    this.editLocalStoragePreferences('dashboard_selected_tab', this.selectedTab ?? 'workOrders');
  }

  private _getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  private _processProjects(projects: Project[]): Project[] {
    // map through work orders and pick out the latest update
    for (const project of projects) {
      if (project.updates?.length === 1) {
        project.update = project.updates[0];
      } else if (project.updates?.length > 1) {
        // pick the newest
        const orderedUpdates = project.updates.sort((a, b) => {
          return moment(moment(b.created_datetime)).diff(moment(a.created_datetime));
        });
        project.update = orderedUpdates[0];
      }
      // add a sortCode
      const sortCode = Number(project?.code?.replace(/wo/i, '')?.replace(/-/i, ''));
      if (sortCode) {
        project.sortCode = sortCode;
      }
    }
    return projects;
  }

  private _processWorkOrders(workOrders: WorkOrder[]): WorkOrder[] {
    // map through work orders and pick out the latest update
    for (const workOrder of workOrders) {
      if (workOrder.updates.length === 1) {
        workOrder.update = workOrder.updates[0];
      } else if (workOrder.updates.length > 1) {
        // pick the newest
        const orderedUpdates = workOrder.updates.sort((a, b) => {
          return moment(moment(b.created_datetime)).diff(moment(a.created_datetime));
        });
        workOrder.update = orderedUpdates[0];
      }

      // add a sortCode
      const sortCode = Number(workOrder?.code?.replace(/wo/i, '')?.replace(/-/i, ''));
      if (sortCode) {
        workOrder.sortCode = sortCode;
      }
    }
    return workOrders;
  }

  public onResize(event) {
    this._getDivWidth();
  }

  public goToWorkOrders() {
    this.router.navigateByUrl(`/work-orders`);
  }

  public goToRequests() {
    this.router.navigateByUrl(`/requests`);
  }

  public goToProjects() {
    this.router.navigateByUrl(`/projects`);
  }

  // Opens up the selected task using the view task dialog
  public async viewTask(taskId: number) {
    this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
  }

  public taskUpdate(updatedTask: Task) {
    const foundTask = this.tasks.find((t) => t.id === updatedTask.id);
    const foundFilteredTask = this.filteredTasks.find((t) => t.id === updatedTask.id);
    Object.assign(foundTask, updatedTask);
    Object.assign(foundFilteredTask, updatedTask);
    this.filterTasks();
    if (updatedTask.status_id === TaskStatus.Complete) {
      // wait 5 seconds and if the task is still listed as complete, remove it from the home page
      const secondsToWait = 5;
      setTimeout(() => {
        if (updatedTask.status_id === TaskStatus.Complete) {
          remove(this.tasks, (t) => t.id === updatedTask.id);
          remove(this.filteredTasks, (t) => t.id === updatedTask.id);
        }
      }, secondsToWait * 1000);
    }
  }

  public pageChange(event, itemType: string) {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.pageIndexes[`${itemType}Start`] = startIndex;
    this.pageIndexes[`${itemType}End`] = endIndex;
  }

  goToFirstPage(paginator, itemType: string) {
    if (paginator) {
      paginator.firstPage();
      const startIndex = 0;
      const endIndex = this.pageSize;
      this.pageIndexes[`${itemType}Start`] = startIndex;
      this.pageIndexes[`${itemType}End`] = endIndex;
    }
  }

  public isOverdue(date: Date | string): boolean {
    return moment() > moment(date);
  }

  toggleAllWorkOrderAssignees() {
    if (this.allWorkOrderAssigneesSelected) {
      // 0 is Not Assigned
      this.selectedWorkOrderAssigneeIds = [0, ...(this.workOrderAssignees || []).map((a) => a.id)];
    } else {
      this.selectedWorkOrderAssigneeIds = [];
    }
    this.filterWorkOrders();
  }

  toggleAllTaskAssignees() {
    if (this.allTaskAssigneesSelected) {
      // 0 is Not Assigned
      this.selectedTaskAssigneeIds = [0, ...(this.taskAssignees || []).map((a) => a.id)];
    } else {
      this.selectedTaskAssigneeIds = [];
    }
    this.filterTasks();
  }

  toggleAllProjectPMs() {
    if (this.allProjectPMsSelected) {
      // 0 is Not Assigned
      this.selectedProjectPMIds = [0, ...(this.projectPMs || []).map((u) => u.id)];
    } else {
      this.selectedProjectPMIds = [];
    }
    this.checkFilteredUser();
    this.filterProjects();
  }

  public filterWorkOrders(cardClicked?: string) {
    // + 1 accounts for the Not Assigned option
    this.allWorkOrderAssigneesSelected =
      (this.selectedWorkOrderAssigneeIds || []).length === (this.workOrderAssignees || []).length + 1;
    this.filteredWorkOrders = this.workOrders.filter(
      (wo) => this.selectedWorkOrderAssigneeIds.indexOf(wo.assigned_user?.id || 0) > -1
    );

    const activeWorkOrders = this.filteredWorkOrders.filter((wo) => wo.status_id === WorkOrderStatus.ACTIVE);
    this.filteredCounts.activeWorkOrders = activeWorkOrders?.length;
    const overdueWorkOrders = this.filteredWorkOrders.filter((wo) => this.isOverdue(wo.due_date));
    this.filteredCounts.overdueWorkOrders = overdueWorkOrders?.length;
    const onHoldWorkOrders = this.filteredWorkOrders.filter((wo) => wo.status_id === WorkOrderStatus.ON_HOLD);
    this.filteredCounts.onHoldWorkOrders = onHoldWorkOrders?.length;
    const unassignedWorkOrders = this.filteredWorkOrders.filter((wo) => !wo.assigned_user_id);
    this.filteredCounts.unassignedWorkOrders = unassignedWorkOrders?.length;
    // If a card was clicked, set the filter after the counts are calculated so they don't go to 0.
    if (cardClicked && this.workOrderCardClicked !== cardClicked) {
      this.workOrderCardClicked = cardClicked;
      switch (cardClicked) {
        case 'active':
          this.filteredWorkOrders = activeWorkOrders;
          break;
        case 'overdue':
          this.filteredWorkOrders = overdueWorkOrders;
          break;
        case 'on hold':
          this.filteredWorkOrders = onHoldWorkOrders;
          break;
        case 'unassigned':
          this.filteredWorkOrders = unassignedWorkOrders;
          break;
      }
    } else {
      this.workOrderCardClicked = null;
    }
    this.checkFilteredUser();
    this.sortWorkOrders();
    this.goToFirstPage(this.workOrderPaginator, 'workOrder');
  }

  public filterTasks(cardClicked?: string) {
    // + 1 accounts for the Not Assigned option
    this.allTaskAssigneesSelected =
      (this.selectedTaskAssigneeIds || []).length === (this.taskAssignees || []).length + 1;
    this.filteredTasks = this.tasks.filter((t) => this.selectedTaskAssigneeIds.indexOf(t.assigned_user_id || 0) > -1);
    const activeTasks = this.filteredTasks.filter((t) => t.status_id === TaskStatus.Open);
    this.filteredCounts.activeTasks = activeTasks?.length;
    const overdueTasks = this.filteredTasks.filter((t) => this.isOverdue(t.due_date));
    this.filteredCounts.overdueTasks = overdueTasks?.length;
    const onHoldTasks = this.filteredTasks.filter((t) => t.status_id === TaskStatus.OnHold);
    this.filteredCounts.onHoldTasks = onHoldTasks?.length;
    const unassignedTasks = this.filteredTasks.filter((t) => !t.assigned_user_id);
    this.filteredCounts.unassignedTasks = unassignedTasks?.length;
    // If a card was clicked, set the filter after the counts are calculated so they don't go to 0.
    if (cardClicked && this.taskCardClicked !== cardClicked) {
      this.taskCardClicked = cardClicked;
      switch (cardClicked) {
        case 'active':
          this.filteredTasks = activeTasks;
          break;
        case 'overdue':
          this.filteredTasks = overdueTasks;
          break;
        case 'on hold':
          this.filteredTasks = onHoldTasks;
          break;
        case 'unassigned':
          this.filteredTasks = unassignedTasks;
          break;
      }
    } else {
      this.taskCardClicked = null;
    }
    this.checkFilteredUser();
    this.sortTasks();
    this.goToFirstPage(this.taskPaginator, 'task');
  }

  public filterProjects(cardClicked?: string) {
    // + 1 accounts for the Not Assigned option
    this.allProjectPMsSelected = (this.selectedProjectPMIds || []).length === (this.projectPMs || []).length + 1;
    this.filteredProjects = this.projects.filter(
      (p) => this.selectedProjectPMIds.indexOf(p.project_manager?.id || 0) > -1
    );
    const activeProjects = this.filteredProjects.filter((p) => p.status_id === ProjectStatus.ACTIVE);
    this.filteredCounts.activeProjects = activeProjects?.length;
    const behindScheduleProjects = this.filteredProjects.filter((p) => !p.on_schedule);
    this.filteredCounts.behindScheduleProjects = behindScheduleProjects?.length;
    const onHoldProjects = this.filteredProjects.filter((p) => p.status_id === ProjectStatus.ON_HOLD);
    this.filteredCounts.onHoldProjects = onHoldProjects?.length;
    // If a card was clicked, set the filter after the counts are calculated so they don't go to 0.
    if (cardClicked && this.projectCardClicked !== cardClicked) {
      this.projectCardClicked = cardClicked;
      switch (cardClicked) {
        case 'active':
          this.filteredProjects = activeProjects;
          break;
        case 'behind schedule':
          this.filteredProjects = behindScheduleProjects;
          break;
        case 'on hold':
          this.filteredProjects = onHoldProjects;
          break;
      }
    } else {
      this.projectCardClicked = null;
    }
    this.checkFilteredUser();
    this.sortProjects();
    this.goToFirstPage(this.projectPaginator, 'project');
  }

  public sortWorkOrders() {
    if (this.fieldToSortWorkOrderBy?.toLowerCase() === 'assigned_user_first_name') {
      const notAssignedWorkOrders =
        this.filteredWorkOrders?.filter(
          (workOrder: WorkOrder) => !workOrder[this.fieldToSortWorkOrderBy?.split('.')[0]]
        ) || [];
      this.filteredWorkOrders = orderBy(
        this.filteredWorkOrders?.filter(
          (workOrder: WorkOrder) => workOrder[this.fieldToSortWorkOrderBy?.split('.')[0]]
        ),
        [
          (workOrder: WorkOrder) => workOrder.assigned_user?.first_name.toLowerCase(),
          (workOrder: WorkOrder) => workOrder.assigned_user?.last_name.toLowerCase(),
        ],
        [this.dashboard_work_order_sort_direction, this.dashboard_work_order_sort_direction]
      );
      this.filteredWorkOrders.push(...notAssignedWorkOrders);
    } else if (
      this.dashboard_work_order_sort_direction === 'desc' &&
      (this.fieldToSortWorkOrderBy.toLowerCase() === 'priority.id' ||
        this.fieldToSortWorkOrderBy.toLowerCase() === 'due_date' ||
        this.fieldToSortWorkOrderBy?.toLowerCase() === 'update.created_datetime')
    ) {
      const workOrdersWithNoSetProperty =
        this.filteredWorkOrders?.filter(
          (workOrder: WorkOrder) => !workOrder[this.fieldToSortWorkOrderBy.split('.')[0]]
        ) || [];
      this.filteredWorkOrders = orderBy(
        this.filteredWorkOrders?.filter((workOrder: WorkOrder) => workOrder[this.fieldToSortWorkOrderBy.split('.')[0]]),
        this.fieldToSortWorkOrderBy,
        this.dashboard_work_order_sort_direction
      );
      this.filteredWorkOrders.push(...workOrdersWithNoSetProperty);
    } else {
      this.filteredWorkOrders = orderBy(
        this.filteredWorkOrders,
        this.fieldToSortWorkOrderBy?.toLowerCase() === 'location' ||
          this.fieldToSortWorkOrderBy?.toLowerCase() === 'building_code' ||
          this.fieldToSortWorkOrderBy?.toLowerCase() === 'building.code'
          ? ['building.code', 'floor.sequence']
          : this.fieldToSortWorkOrderBy,
        this.fieldToSortWorkOrderBy?.toLowerCase() === 'location' ||
          this.fieldToSortWorkOrderBy?.toLowerCase() === 'building_code' ||
          this.fieldToSortWorkOrderBy?.toLowerCase() === 'building.code'
          ? [this.dashboard_work_order_sort_direction, this.dashboard_work_order_sort_direction]
          : this.dashboard_work_order_sort_direction
      );
    }
  }

  public sortTasks() {
    this.filteredTasks = sortBy(this.filteredTasks, (t) => this.taskSortOption.getValue(t));
    this.editLocalStoragePreferences('dashboard_task_sort', this.taskSortOption.name);
  }

  public sortProjects() {
    this.filteredProjects = orderBy(
      this.filteredProjects,
      this.fieldToSortProjectBy?.toLowerCase() === 'location' ||
        this.fieldToSortProjectBy?.toLowerCase() === 'building_code' ||
        this.fieldToSortProjectBy?.toLowerCase() === 'building.code'
        ? ['building.code', 'floor.sequence']
        : this.fieldToSortProjectBy,
      this.fieldToSortProjectBy?.toLowerCase() === 'location' ||
        this.fieldToSortProjectBy?.toLowerCase() === 'building_code' ||
        this.fieldToSortProjectBy?.toLowerCase() === 'building.code'
        ? [this.dashboard_project_sort_direction, this.dashboard_project_sort_direction]
        : this.dashboard_project_sort_direction
    );
  }

  public filterAllByUserId(userId: number) {
    if (userId) {
      this.filteredUser = this.allAssignees.find((a) => a.id === userId);
      this.selectedWorkOrderAssigneeIds = [userId];
      this.filteredUser.isWorkOrderAssignee = !!this.workOrderAssignees.find((a) => a.id === userId);
      this.selectedTaskAssigneeIds = [userId];
      this.filteredUser.isTaskAssignee = !!this.taskAssignees.find((a) => a.id === userId);
      this.selectedProjectPMIds = [userId];
      this.filteredUser.isProjectPM = !!this.projectPMs.find((a) => a.id === userId);
      this.filterWorkOrders();
      this.filterTasks();
      this.filterProjects();
    } else {
      this.allWorkOrderAssigneesSelected = true;
      this.toggleAllWorkOrderAssignees();
      this.allTaskAssigneesSelected = true;
      this.toggleAllTaskAssignees();
      this.allProjectPMsSelected = true;
      this.toggleAllProjectPMs();
    }
  }

  private checkFilteredUser() {
    if (
      this.selectedWorkOrderAssigneeIds.length === 1 &&
      this.selectedTaskAssigneeIds.length === 1 &&
      this.selectedProjectPMIds.length === 1 &&
      this.selectedWorkOrderAssigneeIds[0] === this.selectedTaskAssigneeIds[0] &&
      this.selectedWorkOrderAssigneeIds[0] === this.selectedProjectPMIds[0]
    ) {
      this.filteredUser = this.allAssignees.find((a) => a.id === this.selectedWorkOrderAssigneeIds[0]);
    } else {
      this.filteredUser = null;
    }
  }

  // public showMoreUpdates() {
  //   this.updateCount += this.updatePageSize;
  // }

  public goToUpdateParent(update) {
    if (update?.work_order?.id) {
      this.router.navigateByUrl(`/work-orders/${update.work_order.id}`);
    } else if (update?.project?.id) {
      this.router.navigateByUrl(`/projects/${update.project.id}`);
    }
  }

  private editLocalStoragePreferences(key: string, value: any) {
    const preferenceString = localStorage.getItem('preferences');
    const preferences = preferenceString ? JSON.parse(preferenceString) || {} : {};
    preferences[key] = value;
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }
  public updateProjectSortByField(field: string) {
    if (field === this.fieldToSortProjectBy) {
      this.dashboard_project_sort_direction =
        this.dashboard_project_sort_direction === Order.DESC ? Order.ASC : Order.DESC;
    } else {
      this.dashboard_project_sort_direction = Order.ASC;
    }
    this.fieldToSortProjectBy = field;
    this.editLocalStoragePreferences('dashboard_project_sort', this.fieldToSortProjectBy ?? 'code');
    this.editLocalStoragePreferences(
      'dashboard_project_sort_direction',
      this.dashboard_project_sort_direction ?? 'asc'
    );
    this.goToFirstPage(this.projectPaginator, 'project');
    this.sortProjects();
  }

  public updateWorkOrderSortByField(field: string) {
    if (field === this.fieldToSortWorkOrderBy) {
      this.dashboard_work_order_sort_direction =
        this.dashboard_work_order_sort_direction === Order.DESC ? Order.ASC : Order.DESC;
    } else {
      this.dashboard_work_order_sort_direction = Order.ASC;
    }
    this.fieldToSortWorkOrderBy = field;
    this.editLocalStoragePreferences('dashboard_work_order_sort', this.fieldToSortWorkOrderBy ?? 'code');
    this.editLocalStoragePreferences(
      'dashboard_work_order_sort_direction',
      this.dashboard_work_order_sort_direction ?? 'asc'
    );
    this.goToFirstPage(this.workOrderPaginator, 'workOrder');
    this.sortWorkOrders();
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }
}
