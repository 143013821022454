import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiFilterService, AuthService, HandleErrorService } from 'src/app/services';
import { AgendaItem, APIFilter, Meeting, Note, ServiceResponse, User } from 'src/app/types';
import { environment } from 'src/environments/environment';
import { ResourceType } from '../enums';
@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService,
    private authService: AuthService
  ) {}

  host: string = environment.serviceHost;
  meetingUrl = `${this.host}/api/v1/meetings`;
  recurringMeetingUrl = `${this.host}/api/v1/recurring-meetings`;
  agendaUrl = `${this.host}/api/v1/agendas`;
  attendeeUrl = `${this.host}/api/v1/attendees`;
  notesUrl = `${this.host}/api/v1/notes`;

  public refreshNeeded$ = new Subject<void>();
  public isZippingNotes = false;

  meetingFields =
    'code,title,purpose,is_mandatory,start_datetime,end_datetime,location,recurring_meeting_id,recurring_meeting_frequency_number,recurring_meeting_frequency_interval,recurring_meeting_end_datetime,agenda_count,attendee_count,files,type_id,is_concluded,concluded_datetime,parent_type_id,parent_id,created_by_id,attendees,workspace_id,workspace_name';
  recurringMeetingFields = 'title';
  agendaFields =
    'description,duration,created_by_id,created_by_first_name,created_by_last_name,parent_type_id,parent_id,parent_name,files,assigned_user_id,assigned_user_first_name,assigned_user_last_name,display_order_hash';
  attendeeFields = 'attendee_type_id,user_id,first_name,last_name,email';
  notesFields =
    'message,created_by_id,created_by_first_name,created_by_last_name,created_item_id_from_agenda,created_item_type_id_from_agenda,created_datetime,files';

  getMeetings(
    apiFilters?: APIFilter[],
    fields?: string[],
    limit?: number,
    order?: string,
    next?: string
  ): Observable<any> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    let url = `${this.meetingUrl}?fields=${fields ? fields.join(',') : this.meetingFields}&limit=${limit || '1000'}${
      !filterString || filterString === '' ? '' : `&${filterString}`
    }`;
    if (order) {
      url += `&order=${order}`;
    }
    if (next) {
      url += `&cursor=${next}`;
    }
    return this.http.get(url).pipe(
      map((result: ServiceResponse) => {
        const meetingData = result;
        return meetingData;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // gets all meetings where the attendees or created by id intersects with the passed user ids
  getMeetingsByUserIds(userIds: number[]): Observable<Meeting[]> {
    const users = userIds.join('^');
    return this.http
      .get(`${this.meetingUrl}?fields=${this.meetingFields}&filter=attendees=${users}|created_by_id=${users}`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.meetings;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getMeetingById(meetingId, checkError = false, errorCaught?): Observable<Meeting> {
    return this.http.get(`${this.meetingUrl}/${meetingId}?fields=${this.meetingFields}`).pipe(
      map((result: ServiceResponse) => {
        const meeting: Meeting = result.data.meeting;
        return meeting[0];
      }),
      catchError((e) => {
        if (checkError && errorCaught(e)) {
          return of(null);
        } else {
          return this.handleErrorService.handleError(e);
        }
      })
    );
  }

  getInitDataMeetingById(meetingId): Observable<any> {
    return this.http.get(`${this.meetingUrl}/meetingData/${meetingId}`).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createMeeting(meeting: Meeting): Observable<Meeting> {
    const body = meeting;
    return this.http.post(`${this.meetingUrl}?fields=${this.meetingFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdMeeting: Meeting = result.data.meeting;
        return createdMeeting;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createRecurringMeeting(recurringMeeting): Observable<any> {
    const body = recurringMeeting;
    return this.http.post(`${this.recurringMeetingUrl}?fields=${this.recurringMeetingFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdRecurringMeeting = result.data['recurring meeting'];
        return createdRecurringMeeting;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateMeeting(meeting: Meeting): Observable<Meeting> {
    const meetingId = meeting.id;
    const body = meeting;
    delete body.id;
    return this.http.put(`${this.meetingUrl}/${meetingId}?fields=${this.meetingFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedMeeting: Meeting = result.data.meeting;
        return updatedMeeting;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // TODO eventually it would be better if this called a new backend endpoint for meetings for conclude meeting, and pass in the email information
  // TODO since the emails will be sent out on the backend, it makes more sense for that to be handled there, rather than called from the front end
  concludeMeeting(meetingId: number): Observable<Meeting> {
    const body = {
      is_concluded: 1,
      concluded_datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    return this.http.put(`${this.meetingUrl}/${meetingId}?fields=${this.meetingFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const updatedMeeting: Meeting = result.data.meeting;
        return updatedMeeting;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateRecurringMeeting(recurringMeeting): Observable<any> {
    const recurringMeetingId = recurringMeeting.id;
    const body = recurringMeeting;
    delete body.id;
    return this.http
      .put(`${this.recurringMeetingUrl}/${recurringMeetingId}?fields=${this.recurringMeetingFields}`, body)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedRecurringMeeting = result.data.recurringMeeting;
          return updatedRecurringMeeting;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getAgendaItemsByMeetingId(meetingId: number): Observable<AgendaItem[]> {
    return this.http.get(`${this.agendaUrl}?filter=meeting_id=${meetingId}&fields=${this.agendaFields}`).pipe(
      map((result: ServiceResponse) => {
        const agendas = result.data.agendas;

        // If the tasks for this milestone have no had their display order hash set, set them (this is to make us not have to manually set them all for the conversion)
        if (
          (this.authService.isAppAdmin || this.authService.isStaffOnAnyModule) &&
          agendas.filter((t) => t.display_order_hash == null).length > 0
        ) {
          let currentMax = 5000000;
          // if we have any current values, grab the maximum to start from (so as to not place tasks in the middle of an existing list)
          if (agendas.filter((t) => t.display_order_hash != null).length > 0) {
            currentMax = Math.max(
              ...agendas.filter((t) => t.display_order_hash != null).map((task) => task.display_order_hash)
            );
          }
          for (let i = 0; i < agendas.length; i++) {
            if (agendas[i].display_order_hash == null) {
              // the storage space for the display hash is 10^11, so 100k space in between supports up to 1m tasks
              agendas[i].display_order_hash = currentMax + i * 100000;
              this.updateAgendaItemDisplayOrderHash(agendas[i].id, agendas[i].display_order_hash).subscribe();
            }
          }
        }

        return agendas;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public updateAgendaItemDisplayOrderHash(agendaId: number, hash: number): Observable<User> {
    // add the task followers to the db
    return this.http.put(`${this.agendaUrl}/${agendaId}`, { display_order_hash: hash }).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  addAgendaItem(agendaItemToAdd): Observable<any> {
    const body = agendaItemToAdd;
    return this.http.post(`${this.agendaUrl}?fields=${this.agendaFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const agendaItem = result.data.agenda;
        return agendaItem;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateAgendaItem(agendaItemId: number, agendaItem) {
    return this.http.put(`${this.agendaUrl}/${agendaItemId}?fields=${this.agendaFields}`, agendaItem).pipe(
      map((result: ServiceResponse) => {
        const updatedAgendaItem = result.data.agenda;
        return updatedAgendaItem;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // makes the api call to move the current node in between the before and after node
  public moveNodeBefore(movedNode, newBeforeNode, newAfterNode) {
    if (movedNode) {
      movedNode = { id: movedNode.id, display_order_hash: movedNode.display_order_hash };
    }
    if (newBeforeNode) {
      newBeforeNode = {
        id: newBeforeNode.id,
        display_order_hash: newBeforeNode.display_order_hash,
      };
    }
    if (newAfterNode) {
      newAfterNode = { id: newAfterNode.id, display_order_hash: newAfterNode.display_order_hash };
    }
    return this.http
      .put(`${this.agendaUrl}/moveNodeBefore`, {
        movedNode,
        newBeforeNode,
        newAfterNode,
      })
      .subscribe();
  }

  deactivateAgendaItem(agendaItemId: number): Observable<void> {
    return this.http.delete(`${this.agendaUrl}/${agendaItemId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getAttendeesByMeetingId(meetingId: number): Observable<any[]> {
    return this.http.get(`${this.attendeeUrl}?filter=meeting_id=${meetingId}&fields=${this.attendeeFields}`).pipe(
      map((result: ServiceResponse) => {
        const attendees = result.data.attendees;
        return attendees;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  addAttendee(attendee): Observable<any> {
    const body = attendee;
    return this.http.post(`${this.attendeeUrl}?fields=${this.attendeeFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdAttendee = result.data.attendee;
        return createdAttendee;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateAttendee(attendeeId: number, attendee) {
    return this.http.put(`${this.attendeeUrl}/${attendeeId}?fields=${this.attendeeFields}`, attendee).pipe(
      map((result: ServiceResponse) => {
        const updatedAttendee = result.data.attendee;
        return updatedAttendee;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  removeAttendee(attendeeId: number): Observable<any> {
    return this.http.delete(`${this.attendeeUrl}/${attendeeId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getAgendaItemNotes(item): Observable<any> {
    return this.http
      .get(`${this.notesUrl}?filter=parent_type_id=11,parent_id=${item.id}&fields=${this.notesFields}&order=asc`)
      .pipe(
        map((result: ServiceResponse) => {
          const notes = result.data.notes;
          return notes;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  addAgendaItemNote(note): Observable<any> {
    const body = note;
    return this.http.post(`${this.notesUrl}?fields=${this.notesFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdNote = result.data.note;
        return createdNote;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createParentNote(parentResourceType: ResourceType, parentId: number, message: string): Observable<Note> {
    const noteToCreate: Note = {
      parent_type_id: parentResourceType,
      parent_id: parentId,
      message,
    };
    return this.http
      .post(
        `${this.notesUrl}?fields=created_by_id,created_datetime,message,created_by_first_name,created_by_last_name`,
        noteToCreate
      )
      .pipe(
        map((result: ServiceResponse) => {
          const noteToReturn = result.data.note;
          return noteToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deactivateAgendaItemNote(noteId: number): Observable<any> {
    return this.http.delete(`${this.notesUrl}/${noteId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateAgendaNote(noteId: number, note: Note, fields?: string[]) {
    return this.http.put(`${this.notesUrl}/${noteId}${fields ? `?fields=${fields.join(',')}` : ''}`, note).pipe(
      map((result: ServiceResponse) => {
        const noteToReturn = result.data.note;
        return noteToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
