import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../services';
import { User } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-select-user-from-list-dialog',
  templateUrl: './select-user-from-list-dialog.component.html',
  styleUrls: ['./select-user-from-list-dialog.component.scss'],
})
export class SelectUserFromListDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SelectUserFromListDialogComponent>,
    private snackbar: MatSnackBar,
    private userService: UserService
  ) {}

  public selectLimit: number;
  public selectedUsers: User[] = [];
  public usersToDisplay: User[];

  async ngOnInit() {
    if (this.data?.users?.length) {
      this.selectLimit = this.data.selectLimit || 1;
      this.usersToDisplay = await this.userService.getUserByIds(this.data.users).toPromise();
    }
  }

  get selectionLimitReached() {
    return this.selectedUsers.length >= this.selectLimit;
  }

  public toggleUser(user) {
    if (!user.is_selected) {
      if (this.selectionLimitReached) {
        this.snackbar.open('exceeds the selection limit');
      } else {
        user.is_selected = true;
        this.selectedUsers.push(user);
      }
    } else {
      user.is_selected = false;
      this.selectedUsers = this.selectedUsers.filter((su) => su.id !== user.id);
    }
  }

  public submit() {
    this.dialogRef.close(this.selectedUsers);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
