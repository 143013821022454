import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, find } from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DatepickerHeaderComponent, EditorComponent, UserSelectModalComponent } from 'src/app/components';
import { ResourceType, UserType, Workspace } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LocationService,
  ModalService,
  ProjectService,
  RequestService,
  UserService,
} from 'src/app/services';
import { APIFilter, Building, Department, Floor, RequestType, RoomType, Suite, User } from 'src/app/types';

@Component({
  selector: 'app-construction-request',
  templateUrl: './construction-request.component.html',
  styleUrls: ['./construction-request.component.scss'],
})
export class ConstructionRequestComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;

  public newSpaceRequestMethod = { id: 1, name: 'Addition' };
  public renovationRequestMethod = { id: 2, name: 'Renovation' };
  public showRequestType = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private locationService: LocationService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private modalService: ModalService,
    private requestService: RequestService,
    private userService: UserService,
    private fileService: FileService,
    private router: Router,
    private snackbar: MatSnackBar,
    public authService: AuthService
  ) {}

  private _requestTypeStepLabel = 'Type';

  currentUser: User;
  requestTypes: RequestType[];
  roomTypes: RoomType[];
  filteredUsers: User[] = [];
  projectBudgets = [];
  startDateMinimum;
  completionDateMinimum;
  startDateMaximum;
  completionDateMaximum;
  attachedFiles: File[] = [];

  loaders = {
    submitRequest: false,
  };

  requestTypeFormGroup: FormGroup = this.fb.group({
    request_type: ['', [Validators.required]],
  });

  locationFormGroup: FormGroup = this.fb.group({
    building: ['', [Validators.required]],
    floor: ['', [Validators.required]],
    suite: [''],
    department: [''],
  });

  detailFormGroup: FormGroup = this.fb.group({
    rooms: this.fb.array([
      this.fb.group({
        room_type: [''],
        quantity: [''],
        comments: [''],
      }),
    ]),
  });

  // budgetFormGroup: FormGroup = this.fb.group({
  //   project_budget: ['', [Validators.required]],
  // });

  timelineFormGroup: FormGroup = this.fb.group({
    lease_term: [''],
    start_date: [''],
    completion_date: ['', [Validators.required]],
  });

  extrasFormGroup: FormGroup = this.fb.group({
    contacts: this.fb.array([]),
    contact_input: [''],
    files: this.fb.array([this.fb.control('')]),
  });

  get request_type_labe() {
    return this._requestTypeStepLabel;
  }

  get request_type() {
    return this.requestTypeFormGroup.get('request_type');
  }
  get building() {
    return this.locationFormGroup.get('building');
  }
  get floor() {
    return this.locationFormGroup.get('floor');
  }
  get suite() {
    return this.locationFormGroup.get('suite');
  }
  get department() {
    return this.locationFormGroup.get('department');
  }
  get summary() {
    return this.detailFormGroup.get('summary');
  }
  get rooms() {
    return this.detailFormGroup.get('rooms') as FormArray;
  }
  // get project_budget() {
  //   return this.budgetFormGroup.get('project_budget');
  // }
  // get budget() { return this.budgetFormGroup.get('budget'); }
  get lease_term() {
    return this.timelineFormGroup.get('lease_term');
  }
  get start_date() {
    return this.timelineFormGroup.get('start_date');
  }
  get completion_date() {
    return this.timelineFormGroup.get('completion_date');
  }
  get contacts() {
    return this.extrasFormGroup.get('contacts') as FormArray;
  }
  get contact_input() {
    return this.extrasFormGroup.get('contact_input');
  }
  get files() {
    return this.extrasFormGroup.get('files') as FormArray;
  }

  get has_rennovation_summary(): boolean {
    return this.request_type.value && this.request_type.value.id === 2;
  }

  buildings: Building[] = [];
  floors: Floor[] = [];
  suites: Suite[] = [];
  departments: Department[] = [];
  customHeader = DatepickerHeaderComponent;

  async ngOnInit() {
    this.requestTypeFormGroup.valueChanges.subscribe(({ request_type: { id: requestTypeId } }) => {
      if (+requestTypeId === 2) {
        this.building.setValidators([Validators.required]);
        this.floor.setValidators([Validators.required]);
      } else {
        this.building.clearValidators();
        this.floor.clearValidators();
      }
      this.building.updateValueAndValidity();
      this.floor.updateValueAndValidity();
    });
    this._activateEditor();
    this.startDateMinimum = moment().add(31, 'days').toDate();
    this.completionDateMinimum = this.startDateMinimum;
    this.startDateMaximum = moment().add(5, 'years');
    this.completionDateMaximum = this.startDateMaximum;

    this.onDateChange();

    this.getBuildings();

    this.locationService.getRoomTypes().subscribe((roomTypes) => {
      this.roomTypes = roomTypes;
    });

    this.projectService.getProjectBudgets().subscribe((projectBudgets) => {
      this.projectBudgets = filter(projectBudgets, (pb) => pb.id !== 4);
    });

    // id, first, last, building_id, floor_id, suite_id, department_id

    this.currentUser = await this.userService
      .getUserById(this.authService.getLoggedInUser()?.id, [
        'first_name',
        'last_name',
        'building_id',
        'floor_id',
        'suite_id',
        'department_id',
      ])
      .toPromise();

    this.requestService.getRequestTypes().subscribe((requestTypes) => {
      this.requestTypes = requestTypes;
    });

    this.extrasFormGroup
      .get('contact_input')
      .valueChanges.pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm) {
            return this.userService.searchUsers([
              { type: 'field', field: 'email', value: searchTerm, match: 'any' },
              { type: 'operator', value: 'OR' },
              { type: 'field', field: 'full_name', value: searchTerm, match: 'any' },
            ]);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((users) => {
        if (users) {
          this.filteredUsers = filter(
            users,
            (u) =>
              !this.contacts ||
              !this.contacts.controls ||
              (this.contacts.controls.map((c) => c.value.id).indexOf(u.id) === -1 && u.id !== this.currentUser.id)
          );
        } else {
          this.filteredUsers = [];
        }
      });

    // check if it's coming with params (when its redirected from new request type)
    // keep this subscription last so it can have all the data it uses
    this.activatedRoute.params.subscribe(async (params: Params) => {
      if (params?.requestTypeId) {
        // if coming from a new request page, skip request type, since it's set
        if (+params.requestTypeId === 1) {
          this.selectRequestType(this.newSpaceRequestMethod);
        } else if (+params.requestTypeId === 2) {
          this.selectRequestType(this.renovationRequestMethod);
        }
      } else {
        // needed to add a time out to avoid after checked error
        setTimeout(() => {
          this.showRequestType = true;
        }, 0);
      }
    });
  }

  private _activateEditor() {
    this.detailFormGroup.addControl('summary', this._editor_component.content);
  }

  onDateChange() {
    this.start_date.valueChanges.subscribe((val) => {
      if (this.start_date.value) {
        this.completionDateMinimum = moment(this.start_date.value).add(1, 'days').toDate();
      } else {
        this.completionDateMinimum = moment().add(31, 'days').toDate();
        this.completionDateMaximum = moment().add(5, 'years');
      }
    });
    this.completion_date.valueChanges.subscribe((val) => {
      if (this.completion_date.value) {
        this.startDateMaximum = this.completion_date.value;
      } else {
        this.startDateMinimum = moment().add(31, 'days').toDate();
        this.startDateMaximum = moment().add(5, 'years');
      }
    });
  }

  selectRequestType(foundRequestType) {
    if (foundRequestType) {
      this.request_type.setValue(foundRequestType);
      this._requestTypeStepLabel = foundRequestType.name;
      if (foundRequestType.id === 1) {
        this.clearLocationData();

        this.summary.setValidators(null);
        this.summary.setValue(null);

        this.lease_term.setValidators([Validators.required]);

        this.start_date.setValidators(null);
        this.start_date.setValue(null);
      } else if (foundRequestType.id === 2) {
        this.populateLocationData(this.currentUser);

        for (let i = this.rooms.length - 1; i >= 0; i--) {
          this.rooms.removeAt(i);
        }
        this.addRoomType();

        this.summary.setValidators([Validators.required]);

        this.lease_term.setValidators(null);
        this.lease_term.setValue(null);

        this.start_date.setValidators([Validators.required]);
      } else {
        this.snackbar.open('Invalid Request Type!');
      }
    }
  }

  getBuildings() {
    const buildingFilters: APIFilter[] = [{ type: 'field', field: 'is_enabled', value: '1' }];

    this.locationService
      .getBuildings(['id', 'name', 'is_enabled'], buildingFilters)
      .subscribe((buildings: Building[]) => {
        this.buildings = buildings;
      });
  }

  getFloors() {
    if (this.building.value) {
      const floorFilters: APIFilter[] = [
        { type: 'field', field: 'building_id', value: this.building.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
        this.floors = floors;
        this.floor.setValue(null);
        this.suites = [];
        this.suite.setValue(null);
        this.departments = [];
        this.department.setValue(null);
      });
    } else {
      [this.floors, this.suites, this.departments] = [null, null, null];
      this.floor.setValue(null);
      this.suites = [];
      this.suite.setValue(null);
      this.departments = [];
      this.department.setValue(null);
    }
  }

  getSuites() {
    if (this.floor.value) {
      const suiteFilters: APIFilter[] = [
        { type: 'field', field: 'floor_id', value: this.floor.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
      ];
      this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
        // this.locationService.getSuitesByFloor(this.floor.value.id).subscribe((suites: Suite[]) => {
        this.suites = suites;
        this.suite.setValue(null);
        this.departments = [];
        this.department.setValue(null);
      });
    } else {
      [this.suites, this.departments] = [null, null];
      this.suite.setValue(null);
      this.departments = [];
      this.department.setValue(null);
    }
  }

  getDepartments() {
    if (this.suite.value) {
      const departmentFilters: APIFilter[] = [
        { type: 'field', field: 'suite_id', value: this.suite.value.id },
        { type: 'operator', value: 'AND' },
        { type: 'operator', value: '(' },
        { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
        { type: 'operator', value: 'AND' },
        { type: 'field', field: 'is_enabled', value: '1' },
        { type: 'operator', value: ')' },
      ];
      this.locationService.getDepartments(['id', 'name'], departmentFilters).subscribe((departments: Department[]) => {
        // this.locationService.getDepartmentsBySuite(this.suite.value.id).subscribe((departments: Department[]) => {
        this.departments = departments;
        this.department.setValue(null);
      });
    } else {
      this.departments = null;
      this.department.setValue(null);
    }
  }

  populateLocationData(user: User) {
    [this.buildings, this.floors] = [
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
      [{ id: null, name: null }],
    ];
    const buildingFilters: APIFilter[] = [{ type: 'field', field: 'is_enabled', value: '1' }];
    this.locationService.getBuildings(['id', 'name'], buildingFilters).subscribe((buildings: Building[]) => {
      this.buildings = buildings;
      const foundBuilding = this.buildings.find((b) => b.id === user.building_id);
      // this.building.setValue(find(this.buildings, { id: user.building_id }));
      if (foundBuilding) {
        this.building.setValue(foundBuilding);
      }
      if (this.building.value && this.building.value.id) {
        const floorFilters: APIFilter[] = [
          { type: 'field', field: 'building_id', value: this.building.value.id },
          { type: 'operator', value: 'AND' },
          { type: 'field', field: 'is_enabled', value: '1' },
        ];
        this.locationService.getFloors(['id', 'name'], floorFilters).subscribe((floors: Floor[]) => {
          this.floors = floors;

          this.floor.setValue(find(this.floors, { id: user.floor_id }));

          if (this.floor.value && this.floor.value.id) {
            const suiteFilters: APIFilter[] = [
              { type: 'field', field: 'floor_id', value: this.floor.value.id },
              { type: 'operator', value: 'AND' },
              { type: 'field', field: 'is_enabled', value: '1' },
            ];
            this.locationService.getSuites(['id', 'name'], suiteFilters).subscribe((suites: Suite[]) => {
              this.suites = suites;
              this.suite.setValue(find(this.suites, { id: user.suite_id }));
              if (this.suite.value && this.suite.value.id) {
                const departmentFilters: APIFilter[] = [
                  { type: 'field', field: 'suite_id', value: this.suite.value.id },
                  { type: 'operator', value: 'AND' },
                  { type: 'operator', value: '(' },
                  { type: 'field', field: 'suite_occupancy_is_enabled', value: '1' },
                  { type: 'operator', value: 'AND' },
                  { type: 'field', field: 'is_enabled', value: '1' },
                  { type: 'operator', value: ')' },
                ];
                this.locationService
                  .getDepartments(['id', 'name'], departmentFilters)
                  .subscribe((departments: Department[]) => {
                    this.departments = departments;
                    this.department.setValue(find(this.departments, { id: user.department_id }));
                  });
              }
            });
          }
        });
      }
    });
  }

  clearLocationData() {
    this.department.setValue(null);
    this.departments = [];
    this.suite.setValue(null);
    this.suites = [];
    this.floor.setValue(null);
    this.floors = [];
    this.building.setValue(null);
  }

  addRoomType() {
    this.rooms.push(
      this.fb.group({
        room_type: [''],
        quantity: [''],
        comments: [''],
      })
    );
  }

  removeRoomType(index) {
    this.rooms.removeAt(index);
  }

  addContact(user) {
    const foundContact = this.contacts?.value?.find((c) => c.id === user.id);
    if (foundContact || user.id === this.currentUser.id) {
      this.snackbar.open(`One or more users have already been added`);
    } else {
      this.contacts.push(
        this.fb.group({
          id: [user.id],
          first_name: [user.first_name],
          last_name: [user.last_name],
          email: [user.email],
        })
      );
    }
    this.filteredUsers = [];
    this.contact_input.setValue(null);
  }

  removeContact(index: number) {
    this.contacts.removeAt(index);
  }

  private _removeContactById(userId: number) {
    const contacts = this.contacts?.value;
    for (let index = 0; index <= contacts?.length; index++) {
      if (contacts[index].id === userId) {
        this.removeContact(index);
        break;
      }
    }
  }

  createContact() {
    // TODO
    this.filteredUsers = [];
    this.contact_input.setValue(null);
  }

  public async openUploadModal() {
    const data = {
      parentResourceType: null,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      skipUpload: true,
      allowSearchFromProject: false,
    };
    const files = await this.modalService.openFileAttachmentDialog(data).toPromise();
    if (files?.computerFiles?.length) {
      this.attachedFiles = [...this.attachedFiles, ...files.computerFiles];
    }
  }

  removeFile(index: number) {
    this.attachedFiles.splice(index, 1);
  }

  async createRequest() {
    this.loaders.submitRequest = true;
    const contactIdsToSubmit = this.contacts && this.contacts.value ? this.contacts.value.map((c) => c.id) : [];

    const request: any = {
      module_id: Workspace.Construction,
      request_type_id: this.request_type?.value?.id ?? null,
      building_id: this.building.value ? this.building.value.id : null,
      floor_id: this.floor.value ? this.floor.value.id : null,
      suite_id: this.suite.value ? this.suite.value.id : null,
      department_id: this.department.value ? this.department.value.id : null,
      // budget_id: this.project_budget.value ? this.project_budget.value.id : null,
      end_date: this.completion_date.value ? moment(this.completion_date.value).format('YYYY-MM-DD hh:mm:ss') : null,
      requester_id: this.currentUser.id,
      contact_ids: JSON.stringify(contactIdsToSubmit),
    };
    if (request.request_type_id === 1) {
      const roomsToSubmit =
        this.rooms && this.rooms.value ? this.rooms.value.filter((r) => r.room_type && r.room_type.id > 0) : [];
      request.rooms = JSON.stringify(roomsToSubmit);
      request.lease_term = this.lease_term.value;
    } else if (request.request_type_id === 2) {
      request.summary = this.summary.value;
      request.start_date = this.start_date.value ? moment(this.start_date.value).format('YYYY-MM-DD hh:mm:ss') : null;
    } else {
      this.snackbar.open('Invalid request type');
    }

    const newRequest = await this.requestService.createRequest(request).toPromise();
    for (const a of this.attachedFiles) {
      await this.fileService.createFile(a, newRequest.id, ResourceType.Request).toPromise();
    }

    this.loaders.submitRequest = false;

    this.snackbar.open('Request Submitted!');

    const requestData = {
      request: newRequest,
      files: this.attachedFiles,
      contacts: contactIdsToSubmit.map((c) => {
        return { id: c };
      }),
    };

    this.modalService
      .openRequestReceiptDialog(requestData)
      .toPromise()
      .then((res) => {
        if (!res?.reroute) {
          this.router.navigateByUrl('/new-request');
        }
      });
  }

  startDatepickerFilter = (d: Date): boolean => {
    const date = moment(d);
    return (
      (!this.startDateMinimum || date > moment(this.startDateMinimum).add(-1, 'days')) &&
      (!this.startDateMaximum || date < moment(this.startDateMaximum))
    );
  };

  completionDatepickerFilter = (d: Date): boolean => {
    const date = moment(d);
    return (
      (!this.completionDateMinimum || date > moment(this.completionDateMinimum).add(-1, 'days')) &&
      (!this.completionDateMaximum || date < moment(this.completionDateMaximum))
    );
  };

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  openBulkSelectModal() {
    this.dialog
      .open(UserSelectModalComponent, {
        disableClose: true,
        data: {
          title: 'Add Contacts',
          createUser: { title: 'Contact', guestUser: false },
          includeGuestUsers: true,
          allowedUserTypeIds: [UserType.Staff, UserType.Tenant],
          preSelectedUsers: this.contacts.value,
          excludeVendors: true,
          defaultUserTypeId: UserType.Everyone,
        },
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (deSelectedUsers?.length) {
          for (const deSelectedUser of deSelectedUsers) {
            this._removeContactById(deSelectedUser.id);
          }
        }

        if (selectedUsers?.length) {
          for (const selectedUser of selectedUsers) {
            this.addContact(selectedUser);
          }
        }
      });
  }
}
