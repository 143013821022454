<app-base-dialog
  [title]="data.addendum && data.addendum.id ? 'Edit Addendum' : 'Add Addendum'"
  (closeDialog)="cancel()"
></app-base-dialog>
<div id="addendum-dialog" data-test-id="addendum-dialog" class="addendum-dialog project-modal p-0">
  <mat-dialog-content>
    <form [formGroup]="addendumFormGroup">
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold mb-2">Title</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput type="text" required="true" formControlName="title" />
            <mat-error *ngIf="title.errors?.required">Title is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold mb-2">Description</h6>
          <app-editor #descriptionEditor placeholder="Description"></app-editor>
          <mat-error *ngIf="description.errors?.required && description.touched">Description is required</mat-error>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold mb-2">Notes</h6>
          <app-editor #noteEditor placeholder="Notes"></app-editor>
          <mat-error *ngIf="notes.errors?.required">Notes are required</mat-error>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="dkblue font-weight-semibold mb-2">Date Posted to iSqFT</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              required="false"
              (click)="isqftDatePicker.open()"
              [matDatepicker]="isqftDatePicker"
              autocomplete="off"
              formControlName="isqft_date"
            />
            <mat-datepicker-toggle matSuffix [for]="isqftDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #isqftDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-error *ngIf="isqft_date.errors?.required">Date Posted to iSqFT is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row">
              <div class="col-12">
                <h6 class="dkgray font-weight-normal mb-3 mt-2">
                  Does this affect the bid due date?
                </h6>
              </div>
            </div> -->
      <div class="row mb-4">
        <div class="col">
          <h6 class="dkblue font-weight-semibold mb-2">Bid Due Date</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input
              matInput
              required="true"
              (click)="bidDueDatePicker.open()"
              [matDatepicker]="bidDueDatePicker"
              autocomplete="off"
              formControlName="bid_due_date"
            />
            <mat-datepicker-toggle matSuffix [for]="bidDueDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #bidDueDatePicker [calendarHeaderComponent]="customHeader"></mat-datepicker>
            <mat-hint class="font-italic">Change if Addendum affects Bid Due Date</mat-hint>
            <mat-error *ngIf="bid_due_date.errors?.required">Bid Due Date is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-3 mb-4">
        <div class="col-12">
          <button class="mb-1" mat-stroked-button color="accent" (click)="openUploadModal()">Upload Files</button>
          &nbsp;
          <mat-chip-list class="d-inline-block align-middle">
            <app-file-chip
              *ngFor="let file of files"
              [file]="file"
              [removable]="true"
              (removeEvent)="removeFile(file)"
            ></app-file-chip>
          </mat-chip-list>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div mat-dialog-actions class="row pt-3 mb-0">
    <div class="col">
      <button mat-button type="button" class="full-width gray" (click)="cancel()">Cancel</button>
    </div>
    <div class="col">
      <button mat-flat-button type="submit" color="success" class="full-width" (click)="submit()">Save Addendum</button>
    </div>
  </div>
</div>
