import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService, NotificationService, SidenavService } from 'src/app/services';
import { Project } from 'src/app/types';
import { ResourceTags, UserType } from '../../enums';

@Component({
  selector: 'app-project-nav',
  templateUrl: './project-nav.component.html',
  styleUrls: ['./project-nav.component.scss'],
})
export class ProjectNavComponent implements OnInit {
  @Input() isClosedProject: boolean;
  @Input() project: Project;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  public allLinks = [
    {
      name: `Overview`,
      icon: 'view_day',
      routerLink: 'overview',
      excludeUserType: [],
      showFor4DX: true,
    },
    {
      name: 'Tasks',
      icon: 'assignment_turned_in',
      routerLink: 'tasks',
      excludeUserType: [],
      showFor4DX: true,
    },
    {
      name: 'Purchasing',
      icon: 'monetization_on',
      routerLink: 'purchasing',
      excludeUserType: [UserType.Tenant, UserType.Vendor],
      showFor4DX: false,
    },
    {
      name: 'Invoices',
      icon: 'request_quote',
      routerLink: 'invoices',
      excludeUserType: [UserType.Tenant],
      showFor4DX: false,
    },
    {
      name: 'Time Log',
      icon: 'add_alarm',
      routerLink: 'time-log',
      excludeUserType: [UserType.Tenant, UserType.Vendor],
      showFor4DX: true,
    },
    {
      name: 'Files',
      icon: 'insert_drive_file',
      routerLink: 'files',
      excludeUserType: [UserType.Tenant, UserType.Vendor],
      showFor4DX: true,
    },
    {
      name: 'Directory',
      icon: 'people',
      routerLink: 'directory',
      excludeUserType: [UserType.Tenant, UserType.Vendor],
      showFor4DX: true,
    },
  ];
  constructor(
    public notificationService: NotificationService,
    private _sidenavService: SidenavService,
    private _route: ActivatedRoute,
    private authService: AuthService
  ) {}

  public subcomponent = '';

  public divWidth: number;

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    setTimeout(() => {
      this.divWidth = this.elementView.nativeElement.offsetWidth;
    }, 300);
  }

  public get isStaff() {
    return this.authService.isUserWorkspaceStaff(this.project?.module_id);
  }

  public get isTenant() {
    return this.authService.isTenant;
  }

  public get currentUserType() {
    return this.isStaff ? UserType.Staff : this.isTenant ? UserType.Tenant : UserType.Vendor;
  }

  public get navLinks() {
    return this.allLinks.filter(
      (link) =>
        !link.excludeUserType.includes(this.currentUserType) &&
        (!this.project?.tags?.find((t) => Number(t.id) === ResourceTags.FourDX) || link.showFor4DX)
    );
  }

  ngOnInit(): void {
    this._route.params.subscribe(async (params: Params) => {
      if (params?.linked_task_id) {
        this.subcomponent = 'tasks';
      } else {
        this.subcomponent = params?.subcomponent || '';
      }
    });
    this.getDivWidth();
  }

  get isSidenavClosed(): boolean {
    return this._sidenavService.isSidenavClosed;
  }

  get projectId(): number {
    return this.project?.id;
  }
}
