import { Pipe, PipeTransform } from '@angular/core';
import { ResourceTag } from 'src/app/types';
import { noTagsTag } from 'src/app/utils/no-tags-tag';

@Pipe({
  name: 'filterBySelectedTags',
})
export class FilterBySelectedTagsPipe implements PipeTransform {
  transform<T extends { tags?: ResourceTag[] }>(array: T[], selectedTagIds: number[] = []): T[] {
    if (selectedTagIds.length) {
      const selectedTagsHasNoTagsTag: boolean = selectedTagIds.includes(noTagsTag.id);

      array = array.filter((obj) => {
        let objTags = [...(obj.tags ?? [])];

        if (objTags.length === 0) {
          return selectedTagsHasNoTagsTag;
        } else {
          if (selectedTagsHasNoTagsTag && selectedTagIds.length > 1) {
            objTags = [noTagsTag, ...objTags];
          }

          return (
            objTags.length >= selectedTagIds.length &&
            selectedTagIds.every((tagId) => objTags.find((tag) => tagId === Number(tag.id)))
          );
        }
      });
    }

    return array;
  }
}
