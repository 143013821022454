const RANK_LENGTH = 6;
const START_POSITION = 'a';
const END_POSITION = 'z';

// expects two strings
export function getRankBetween(firstRankVal?, secondRankVal?, ALPHABET_SIZE = 26): string {
  // dynamic rankLength
  const rankLength =
    firstRankVal?.length && secondRankVal?.length
      ? Math.max(firstRankVal?.length, secondRankVal?.length)
      : firstRankVal?.length || secondRankVal?.length || RANK_LENGTH;

  let firstRank = firstRankVal ?? START_POSITION.repeat(rankLength);
  let secondRank = secondRankVal ?? END_POSITION.repeat(rankLength);

  // We need value values, so we throw an error if invalid values occur here
  if (firstRank > secondRank) {
    throw new Error(`First rank: ${firstRank}, should come second and Second rank: ${secondRank} should come first!`);
  }

  // Make length the same
  while (firstRank.length !== secondRank.length) {
    if (firstRank.length > secondRank.length) {
      secondRank += 'a';
    } else {
      firstRank += 'a';
    }
  }

  // first rank char codes
  const firstPositionCodes = firstRank.split('').map((char) => char.charCodeAt(0));

  // second rank char codes
  const secondPositionCodes = secondRank.split('').map((char) => char.charCodeAt(0));

  let difference = 0;

  for (let index = firstPositionCodes.length - 1; index >= 0; index--) {
    const firstCode = firstPositionCodes[index];
    let secondCode = secondPositionCodes[index];

    // i.e. a < b
    if (secondCode < firstCode) {
      secondCode += ALPHABET_SIZE;
      secondPositionCodes[index - 1] -= 1;
    }

    // formula: x = a * size ^ 0 + b * size ^ 1 + c * size ^ 2
    const powRes = Math.pow(ALPHABET_SIZE, firstPositionCodes.length - index - 1);
    difference += (secondCode - firstCode) * powRes;
  }

  let newElement = '';
  if (difference <= 1) {
    newElement = firstRank + String.fromCharCode('a'.charCodeAt(0) + Math.floor(ALPHABET_SIZE / 2));
  } else {
    difference = Math.floor(difference / 2);

    let offset = 0;
    for (let index = 0; index < firstRank.length; index++) {
      // formula: x = difference / size ^ (place - 1) % size;
      // i.e. difference = 110, size = 10. we want place 2 (middle),
      // then x = 100 / 10 ^ (2 - 1) % 10 = 100 / 10 % 10 = 11 % 10 = 1
      const diffInSymbols = Math.floor((difference / Math.pow(ALPHABET_SIZE, index)) % ALPHABET_SIZE);

      let newElementCode = firstRank.charCodeAt(secondRank.length - index - 1) + diffInSymbols + offset;

      offset = 0;
      // if newEleent is greater than 'z'
      if (newElementCode > 'z'.charCodeAt(0)) {
        offset++;
        newElementCode -= ALPHABET_SIZE;
      }

      newElement += String.fromCharCode(newElementCode);
    }

    newElement = newElement.split('').reverse().join('');
  }
  return newElement;
}

export function getDefaultRanks(
  forNumOfItems = 1000,
  rankLength = RANK_LENGTH,
  startPos = START_POSITION,
  endPos = END_POSITION,
  ALPHABET_SIZE = 26
): string[] {
  const startCode = startPos.charCodeAt(0);
  const endCode = endPos.charCodeAt(0);
  const diffInOneSymb = endCode - startCode;

  // x = a + b * size + c * size ^ 2 + c * size ^ 3
  //   const totalDiff =
  //     diffInOneSymb +
  //     diffInOneSymb * ALPHABET_SIZE +
  //     diffInOneSymb * ALPHABET_SIZE * ALPHABET_SIZE +
  //     diffInOneSymb * ALPHABET_SIZE * ALPHABET_SIZE * ALPHABET_SIZE;
  let totalDiff = 0;
  let count = 0;
  while (count < rankLength) {
    totalDiff += diffInOneSymb * Math.pow(ALPHABET_SIZE, count);
    count++;
  }

  // '~/' - dvision without remainder
  const diffForOneItem = Math.floor(totalDiff / (forNumOfItems + 1));

  // x = difference / size^(place - 1) % size
  //   const diffForSymbols = [
  //     diffForOneItem % ALPHABET_SIZE,
  //     Math.floor(diffForOneItem / ALPHABET_SIZE) % ALPHABET_SIZE,
  //     Math.floor(diffForOneItem / Math.pow(ALPHABET_SIZE, 2)) % ALPHABET_SIZE,
  //     Math.floor(diffForOneItem / Math.pow(ALPHABET_SIZE, 3)) % ALPHABET_SIZE,
  //   ];
  const diffForSymbols = [];
  for (let place = 1; place <= rankLength; place++) {
    diffForSymbols.push(Math.floor(diffForOneItem / Math.pow(ALPHABET_SIZE, place - 1)) % ALPHABET_SIZE);
  }

  const positions = [];
  //   let lastAddedElement = startPos;
  let lastAddedElement = startPos.repeat(rankLength);

  for (let ind = 0; ind < forNumOfItems; ind++) {
    let offset = 0;
    let newElement = '';

    for (let index = 0; index < rankLength; index++) {
      const diffInSymbols = diffForSymbols[index];
      let newElementCode = lastAddedElement.charCodeAt(rankLength - 1 - index) + diffInSymbols;

      if (offset !== 0) {
        newElementCode += 1;
        offset = 0;
      }

      // 'z' code is 122 if 'll be needed
      if (newElementCode > 'z'.charCodeAt(0)) {
        offset += 1;
        newElementCode -= ALPHABET_SIZE;
      }
      const symbol = String.fromCharCode(newElementCode);
      newElement += symbol;
    }

    // reverse element because we are calculating from the end
    newElement = newElement.split('').reverse().join('');
    positions.push(newElement);
    lastAddedElement = newElement;
  }
  positions.sort();
  return positions;
}
