import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getRandomQUote } from 'src/app/utils';
import { ProgressIndicatorComponent } from 'src/app/components';

enum ProgressMode {
  Determinate = 'determinate',
  Indeterminate = 'indeterminate',
}

@Injectable({
  providedIn: 'root',
})
export class ProgressIndicatorService {
  private progressDialog: MatDialogRef<ProgressIndicatorComponent>;

  private openType: ProgressMode;

  constructor(private dialog: MatDialog) {}

  public openProgressIndicatorModal() {
    if (this.progressDialog) {
      return;
    }

    this.openType = ProgressMode.Determinate;
    this.progressDialog = this.dialog.open(ProgressIndicatorComponent, {
      data: {
        progressValue: 0,
        mode: ProgressMode.Determinate,
      },
    });
    this.progressDialog.disableClose = true;
  }

  public openAwaitIndicatorModal() {
    if (this.progressDialog) {
      return;
    }

    this.openType = ProgressMode.Indeterminate;
    this.progressDialog = this.dialog.open(ProgressIndicatorComponent, {
      data: {
        mode: ProgressMode.Indeterminate,
      },
    });
    this.progressDialog.disableClose = true;
  }

  public updateProgress(progressValue: number) {
    if (!this.progressDialog?.componentInstance || this.openType !== ProgressMode.Determinate) {
      return;
    }
    this.progressDialog.componentInstance.progressValue = progressValue;
  }

  public updateStatus(status?: string) {
    if (!this.progressDialog?.componentInstance) {
      return;
    }
    this.progressDialog.componentInstance.status = status || getRandomQUote();
  }

  public close() {
    if (!this.progressDialog) {
      return;
    }
    this.progressDialog.close();
    this.reset();
  }

  private reset() {
    this.progressDialog = null;
    this.openType = null;
  }
}
