<div class="d-flex align-items-center">
  <app-invoice-review-buttons
    #invoiceReviewButton
    [invoice]="invoice"
    [disableReview]="disableReview"
    [display]="display"
    [hideAllButtons]="!displayInvoiceButton"
    [arf]="arf"
    (invoiceChanged)="updateInvoiceData()"
    [ngClass]="{
      'px-4 py-1 col text-right': alignButtonsRight
    }"
  >
  </app-invoice-review-buttons>
  <button
    *ngIf="!invoice?.loadingExport || invoice?.status_id === InvoiceStatus.Approved"
    [disabled]="
      !showReviewTaskOption && !showExportOption && !showChangeOption && invoice?.status_id !== InvoiceStatus.Approved
    "
    mat-icon-button
    [matMenuTriggerFor]="invoice_menu4"
    color="primary"
    class="ml-auto"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #invoice_menu4="matMenu" xPosition="before">
    <button mat-menu-item (click)="resetInvoice(invoice)" *ngIf="showChangeOption">
      <mat-icon>edit</mat-icon>
      Edit Invoice
    </button>
    <button *ngIf="showReviewTaskOption" mat-menu-item (click)="viewTask(invoice?.approval_task_id)">
      <mat-icon>assignment_turned_in</mat-icon>
      View Review Task
    </button>
    <button
      mat-menu-item
      (click)="invoiceReviewButtonComponent.coverLetter.exportInvoice(invoice, FileAction.Download)"
      *ngIf="showExportOption && isWorkspaceStaff"
    >
      <mat-icon>get_app</mat-icon>
      Export Invoice
    </button>
    <button mat-menu-item (click)="removeInvoice(invoice)" *ngIf="showChangeOption">
      <mat-icon>delete</mat-icon>
      Delete Invoice
    </button>
  </mat-menu>
  <mat-spinner
    *ngIf="invoice?.loadingExport && invoice?.status_id !== InvoiceStatus.Approved"
    class="ml-auto p-4"
    color="accent"
    [diameter]="24"
  ></mat-spinner>
</div>
