<div
  id="invoice-review-buttons"
  data-test-id="invoice-review-buttons"
  class="invoice-review-buttons"
  [ngClass]="{ 'invoice-review-buttons-align-right': alignItemsRight }"
  *ngIf="!hideAllButtons"
>
  <div
    *ngIf="display && !loading && statusId === InvoiceStatus.ReadyForPayment"
    class="dkgreen font-weight-semibold m-0"
  >
    <span *ngIf="!invoice?.processed_by_id"><i class="fa fa-check mr-1"></i>Ready For Payment</span>
    <span *ngIf="invoice?.processed_by_id" class="d-flex flex-column">
      <span> <i class="fa fa-check mr-1"></i>Ready For Payment </span>
      <span class="dkgray font-weight-normal font-italic small-text pl-3 ml-1"
        >{{ invoice?.processed_by?.first_name }} {{ invoice?.processed_by?.last_name }} on
        {{ formatDate(invoice?.processed_datetime) }}</span
      >
    </span>
  </div>
  <button
    *ngIf="
      display &&
      !loading &&
      isAdmin &&
      statusId === InvoiceStatus.Approved &&
      reviewStatus === TaskReviewStatus.Approved &&
      invoice.approval_task_id
    "
    (click)="processInvoice()"
    mat-flat-button
    color="primary"
    type="button"
    class="lh-26 full-width px-2"
  >
    Process
  </button>
  <button
    *ngIf="!disableReview && !loading && isAdmin && isCurrentReviewer && statusId === InvoiceStatus.New"
    (click)="openInvoiceReviewDialog()"
    mat-flat-button
    color="success"
    type="button"
    class="lh-26 full-width px-2"
  >
    Receive
  </button>
  <!-- <button
    *ngIf="
      display &&
      !loading &&
      isAdmin &&
      invoiceInReview &&
      reviewStatus === TaskReviewStatus.Rejected
    "
    (click)="openInvoiceRevisionDialog()"
    mat-flat-button
    color="primary"
    type="button"
    class="ml-auto lh-30 px-2"
  >
    Revise
  </button> -->
  <button
    *ngIf="display && !loading && isAdmin && invoiceInReview && reviewStatus === TaskReviewStatus.Rejected"
    (click)="rejectInvoice()"
    mat-flat-button
    color="warn"
    type="button"
    class="lh-26 full-width px-2"
  >
    Reject
  </button>
  <button
    *ngIf="
      !loading &&
      !disableReview &&
      isCurrentReviewer &&
      statusId === InvoiceStatus.Received &&
      reviewStatus === TaskReviewStatus.Pending
    "
    (click)="openInvoiceReviewDialog()"
    mat-flat-button
    color="accent"
    type="button"
    class="lh-26 full-width px-2"
  >
    Review
  </button>
  <button
    *ngIf="
      !loading &&
      !disableReview &&
      !display &&
      isReviewAdmin &&
      !isCurrentReviewer &&
      reviewStatus === TaskReviewStatus.Pending
    "
    (click)="openInvoiceReviewDialog()"
    mat-flat-button
    color="warn"
    type="button"
    class="lh-26 full-width px-2"
  >
    Expedite
  </button>
  <button
    (click)="createInvoiceTask()"
    *ngIf="
      !loading &&
      display &&
      isAdmin &&
      ((!invoice?.approval_task_id && statusId !== InvoiceStatus.Rejected) ||
        (statusId === InvoiceStatus.Approved && reviewStatus !== TaskReviewStatus.Approved))
    "
    mat-flat-button
    color="primary"
    type="button"
    class="full-width lh-26 px-2"
  >
    Create Task
  </button>

  <p
    *ngIf="display && !loading && statusId === InvoiceStatus.Rejected"
    class="red font-weight-semibold m-0 pointer hover-u"
    [matMenuTriggerFor]="invoiceRejected"
  >
    <i class="fa fa-times mr-1"></i>
    Rejected
  </p>
  <mat-menu class="p-3 mt-2" #invoiceRejected="matMenu" xPosition="before">
    <div class="row">
      <div class="col">
        <p class="m-0 dkred font-weight-semibold mb-2">
          <i class="fa fa-times mr-1"></i>
          Rejected
        </p>
        <p class="m-0 dkblue font-weight-normal">
          <span class="font-weight-semibold">Reason given:&nbsp;</span>
          <span [innerHTML]="invoice?.review_comment"></span>
        </p>
      </div>
    </div>
  </mat-menu>
  <p
    class="green font-weight-semibold m-0"
    *ngIf="
      (display && !loading && !isCurrentReviewer && statusId === InvoiceStatus.New && invoice?.approval_task_id) ||
      (!loading && disableReview && statusId === InvoiceStatus.New)
    "
  >
    New
  </p>

  <p
    class="gray font-weight-semibold m-0"
    *ngIf="
      (display &&
        !loading &&
        (disableReview || !isCurrentReviewer) &&
        statusId === InvoiceStatus.Received &&
        reviewStatus !== TaskReviewStatus.Rejected) ||
      (reviewStatus === TaskReviewStatus.Rejected && !isAdmin && statusId !== InvoiceStatus.Rejected)
    "
  >
    Pending Review
  </p>

  <p
    class="green font-weight-semibold m-0"
    *ngIf="
      display &&
      !loading &&
      !isAdmin &&
      reviewStatus === TaskReviewStatus.Approved &&
      statusId === InvoiceStatus.Approved &&
      invoice.approval_task_id
    "
  >
    Approved
  </p>

  <mat-spinner
    *ngIf="loading"
    class="d-inline-block align-middle m-0 ml-auto"
    color="accent"
    [diameter]="24"
  ></mat-spinner>
</div>

<div class="text-left">
  <app-invoice-cover-letter #coverLetter [exportingInvoice]="invoice" [project]="project" [arf]="arf">
  </app-invoice-cover-letter>
</div>
