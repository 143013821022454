import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserProfilePreviewComponent } from 'src/app/components';
import { BidContractStatus, ResourceType, TaskAccessoryType, TaskReviewStatus, UserType } from 'src/app/enums';
import {
  ArfsService,
  AuthService,
  DisplayReviewersService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  UpdateReviewerService,
  UserService,
} from 'src/app/services';
import { ArfCustodyChain, Project, ReviewChain, Task, TaskAccessoryData, User } from 'src/app/types';
import { Bid } from '../../workspaces/construction/types';

@Component({
  selector: 'app-review-progress',
  templateUrl: './review-progress.component.html',
  styleUrls: ['./review-progress.component.scss'],
})
export class ReviewProgressComponent implements OnChanges {
  @Input() public data: TaskAccessoryData;

  @Input() public task: Task;

  @Output() public taskChanged = new EventEmitter();

  public reviewers: ReviewChain[] = [];
  public displayReviewers: ReviewChain[];

  public currentReviewChainItem: ReviewChain;
  public reviewIsRejected: boolean;
  public isOpenReview: boolean;
  public approvals: number;

  public reviewIsEditable: boolean;
  public userCanEdit: boolean;
  public reviewsOpen = false;

  public currentUser: User;
  public currentUserIsReviewAdmin: boolean;
  public isLocked: boolean;
  public bid: Bid;
  public project: Project;
  public retractLoading: boolean;
  public custodyChain: ArfCustodyChain;

  constructor(
    public arfService: ArfsService,
    public displayReviewersService: DisplayReviewersService,
    private projectService: ProjectService,
    private taskService: ProjectTaskService,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private updateReviewersService: UpdateReviewerService
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    this.isLocked = !!this.task.is_locked;
    this.currentUser = await this.authService.getLoggedInUser();
    this.convertJsonToObject();

    this.project =
      this.task.project_id === this.projectService.currentSelectedProjectId
        ? this.projectService.currentSelectedProject
        : this.task.project_id
        ? await this.projectService.getProjectById(this.task.project_id).toPromise()
        : null;

    if (this.data.type === TaskAccessoryType.Arf && !this.project) {
      const arf = await this.arfService
        .getArfById(this.data.parentId, [this.arfService.arfCustodyChainField])
        .toPromise();
      this.custodyChain = arf.custody_chain;
    }
    this.currentUserIsReviewAdmin = this.displayReviewersService.getIfUserIsReviewAdmin(
      this.data?.reviewChain,
      this.project,
      this.custodyChain
    );
    this.isOpenReview = this.authService.isOpenReview(this.data?.type);

    this.refreshValues();

    if (this.showReviewChainData || this.reviewedByVendor || this.canSendContract) {
      await this.reviewProgress();
    }

    if (this.data.type === TaskAccessoryType.BidContract && this.data.parentId) {
      this.bid = await this.projectService
        .getBidById(this.data.parentId, ['project{project_manager_id}', 'bid_contract{status_id}'])
        .toPromise();
    }

    if (this.retractLoading) {
      this.retractLoading = false;
    }
  }

  // emits a review event for the parent
  emitReviewChange() {
    this.taskChanged.emit(true);
  }

  public get canSendContract() {
    return (
      TaskAccessoryType.BidContract === this.data.type &&
      this.bid?.bid_contract?.status_id === BidContractStatus.RevisionRequested &&
      this.currentUser?.id === this.bid?.project?.project_manager_id
    );
  }

  public get showReviewChainData() {
    return (
      (this.currentUser?.user_type_id !== UserType.Vendor &&
        (TaskAccessoryType.BidContract !== this.data.type ||
          (this.bid && this.bid?.bid_contract?.status_id !== BidContractStatus.RevisionRequested) ||
          this.currentUser?.id !== this.project?.project_manager_id)) ||
      (this.data.type === TaskAccessoryType.Submittals && this.isEngineer)
    );
  }

  get isEngineer(): boolean {
    return !!this.currentUser?.scopes?.project && this.currentUser?.scopes?.project[this.task.project_id]?.includes(17);
  }

  public get reviewedByVendor() {
    return this.authService.reviewedByVendor(this.data?.type);
  }

  public get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  public get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  private refreshValues() {
    this.currentUser = this.currentUser || this.authService.getLoggedInUser();
    // Checks to see if there is a rejection in the review process
    this.reviewIsRejected =
      this.data.reviewChain &&
      !!this.data.reviewChain.find((reviewer) => reviewer.status === TaskReviewStatus.Rejected);
    // get the total number of approvals in the review process
    this.approvals = 0;
    if (this.data.reviewChain) {
      this.data.reviewChain.forEach((reviewer) => {
        if ([TaskReviewStatus.Approved, TaskReviewStatus.NoteMarkings].includes(reviewer.status)) {
          this.approvals++;
        }
      });
    }

    const currentUserCreatedTask = this.task.created_by_id === this.currentUser.id;

    const userIsProjectAdmin = this.authService.isProjectAdmin(this.project?.id, this.project?.module_id);

    this.userCanEdit = currentUserCreatedTask || userIsProjectAdmin;

    this.reviewIsEditable = this.authService.reviewIsEditable(this.data?.type);
  }

  public convertJsonToObject() {
    if (typeof this.data === 'string') {
      this.data = JSON.parse(this.data) as TaskAccessoryData;
    }
  }

  private async reviewProgress() {
    if (
      this.isOpenReview &&
      !this.data?.reviewChain.find(
        (reviewer) => reviewer.id === this.currentUser.id || reviewer.status === TaskReviewStatus.Rejected
      )
    ) {
      this.data?.reviewChain.push({ id: this.currentUser.id, status: TaskReviewStatus.Pending });
    }

    const reviewChain = this.data?.reviewChain;
    if (reviewChain) {
      this.reviewers = await this.displayReviewersService.addUserInfoToReviewChain(reviewChain);
      this.data.reviewChain = this.reviewers;

      this.currentReviewChainItem = this.displayReviewersService.getCurrentReviewer(this.reviewers);

      this.getDisplayReviewers();
    }
  }

  private getDisplayReviewers() {
    let currentReviewItem = [this.currentReviewChainItem];
    if (!this.currentReviewChainItem) {
      currentReviewItem =
        this.data?.reviewChain?.length === this.approvals ? [] : [this.reviewers[this.reviewers.length - 1]];
    }
    this.displayReviewers = this.reviewsOpen ? this.reviewers : currentReviewItem;
  }

  public toggleReviewWindow() {
    this.reviewsOpen = !this.reviewsOpen;
    this.getDisplayReviewers();
  }

  public async resetReview() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Review Process',
        descriptionText:
          'Are you sure that you want to reset the approval process on this task? Please enter the reason in the box below.',
        userInput: {
          required: true,
          placeholder: 'Reason For Reset',
        },
        confirmationButtonText: 'Reset Review',
      })
      .subscribe(async (confirmationMessage) => {
        if (confirmationMessage) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Resetting Review..');
          if (this.isOpenReview) {
            await this.updateReviewersService.resetOpenReview(this.task, confirmationMessage);
          } else {
            await this.updateReviewersService.resetReview(this.task?.id, this.data, confirmationMessage);
          }

          await this.taskService.loadTaskActivityLog(this.task);
          this.progressIndicatorService.close();
        }
      });
  }

  async retractReview() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Retract Review',
        headerText: 'Retract Your Review',
        descriptionText:
          'Please add a reason for retracting this review. This will be recorded on the Review Task as a note.',
        userInput: {
          placeholder: 'Reason for retracting review',
          required: true,
        },
        confirmationButtonText: 'Retract Review',
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.retractLoading = true;
          await this.updateReviewersService.reassignToPreviousReviewer(this.task, this.data, false);
          await this.projectService
            .createNote(ResourceType.Task, this.task?.id, `A review has been retracted. Reason given: ${res}`)
            .toPromise();
        }
        await this.taskService.loadTaskActivityLog(this.task);
      });
  }
  openUserProfilePreview(userId) {
    this.dialog.open(UserProfilePreviewComponent, {
      width: '400px',
      data: {
        userId,
      },
    });
  }

  public editReview() {
    this.modalService
      .openRequestTaskReviewDialog({
        task: this.task,
      })
      .subscribe(({ task }) => {
        if (task) {
          this.taskService.updateTask(task);
          this.taskService.selectTaskById(task.id, false).subscribe();
        }
      });
  }
}
