import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

import { DashboardService } from 'src/app/workspaces/construction/services';

import { User, Task } from 'src/app/types';

@Component({
  selector: 'app-dashboard-assignee',
  templateUrl: './dashboard-assignee.component.html',
  styleUrls: ['./dashboard-assignee.component.scss'],
})
export class DashboardAssigneeComponent implements OnInit {
  // the passed user
  @Input() user: User;

  constructor(public dashboardService: DashboardService) {}

  // checks whether a task is complete
  private static isComplete(task: Task) {
    return task.status_name === 'Complete';
  }

  // checks whether a task is overdue
  private static isOverdue(task: Task) {
    return this.getDaysUntilDue(task) < 0;
  }

  // gets how many days until a task is due
  private static getDaysUntilDue(task: Task) {
    if (!task.due_date) {
      return null;
    }
    return moment(task.due_date).diff(moment(), 'days');
  }

  ngOnInit() {}

  // grabs all data related to the project and its tasks, in order to make displaying easy (specifically for the progress bar)
  getProjectAndTaskData(): {
    totalProjects: number;
    tasksComplete: number;
    totalTasks: number;
    tasksOverdue: number;
  } {
    const projectIds = [];
    const completedTaskIds = [];
    const overdueTaskIds = [];
    const otherTaskIds = [];

    this.user.tasks.forEach((task) => {
      if (!projectIds.includes(task.project_id)) {
        projectIds.push(task.project_id);
      }
      if (DashboardAssigneeComponent.isComplete(task)) {
        if (!completedTaskIds.includes(task.id)) {
          completedTaskIds.push(task.id);
        }
      } else if (DashboardAssigneeComponent.isOverdue(task)) {
        if (!overdueTaskIds.includes(task.id)) {
          overdueTaskIds.push(task.id);
        }
      } else {
        if (!otherTaskIds.includes(task.id)) {
          otherTaskIds.push(task.id);
        }
      }
    });

    return {
      totalProjects: projectIds.length,
      tasksComplete: completedTaskIds.length,
      totalTasks: completedTaskIds.length + overdueTaskIds.length + otherTaskIds.length,
      tasksOverdue: overdueTaskIds.length,
    };
  }

  // converts to valid percent for the progress bar (0-100)
  getPercent(num1, num2) {
    return (num1 * 100) / num2;
  }
}
