export enum Workspace {
  Construction = 1,
  Dispatch = 2,
  Energy = 3,
  EVS = 4,
  FM = 5,
  Security = 6,
  Signage = 7,
  Technology = 8,
  DevTeam = 9,
  SpaceManagement = 10,
  UHATInventory = 11,
  Parking = 12,
  ConferenceCenters = 13,
  AudioVideo = 14,
  OneCallAdmin = 15,
  Compliance = 20,
}
