<app-base-dialog [title]="action + ' Suite'" (closeDialog)="close()"> </app-base-dialog>
<div class="suite-modal" [ngStyle]="{ visibility: loading ? 'hidden' : 'visible' }">
  <form [formGroup]="suiteFormGroup">
    <div mat-dialog-content>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="primary mb-2">Suite Number / Name</h6>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput required="true" type="text" formControlName="name" />
            <mat-error *ngIf="name.errors?.required">Suite Number / Name is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h6 class="primary mb-2">Building</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select formControlName="building_id" (selectionChange)="updateFloors()">
              <mat-option></mat-option>
              <mat-option *ngFor="let building of buildings" [value]="building.id">
                {{ building.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4" *ngIf="building_id?.value">
        <div class="col-12">
          <h6 class="primary mb-2">Floor</h6>
          <mat-form-field class="full-width" appearance="outline">
            <mat-select formControlName="floor_id">
              <mat-option *ngFor="let floor of floors" [value]="floor.id">
                {{ floor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4" *ngIf="action == 'Edit'">
        <div class="col-12 slide-container text-center">
          <mat-slide-toggle color="accent" formControlName="is_enabled">
            <span class="font-weight-semibold">Suite is Active</span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button class="full-width" type="button" color="accent" (click)="close()">Cancel</button>
      </div>
      <div class="col">
        <button
          mat-flat-button
          type="submit"
          color="success"
          class="full-width"
          (click)="save()"
          [disabled]="!suiteFormGroup.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
