<div #mainScreen (window:resize)="onResize($event)" class="container-fluid p-4">
  <div class="d-flex align-items-center full-width p-1 br-5 bg-shade-gray mb-4">
    <button
      [routerLink]="['/projects/' + project?.id + '/peb']"
      mat-button
      color="primary"
      class="flex-grow-1 mr-1 bg-white"
    >
      PEB
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/bids']" mat-button color="primary" class="flex-grow-1 mx-1">
      Bid Packages
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/construction-budget']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Construction Budget
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/proposal-requests']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Proposal Requests
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/change-orders']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Change Orders
    </button>
    <button
      [routerLink]="['/projects/' + project?.id + '/invoices']"
      mat-button
      color="primary"
      class="flex-grow-1 mx-1"
    >
      Invoices
    </button>
    <button [routerLink]="['/projects/' + project?.id + '/budget']" mat-button color="primary" class="flex-grow-1 ml-1">
      Budget Overview
    </button>
  </div>
  <div class="row align-items-center component-subheader">
    <div class="col">
      <h4 class="dkblue font-weight-semibold d-inline-block align-bottom">Preliminary Estimated Budget</h4>
      <!-- <button mat-raised-button class="debug" (click)="debug()">DEBUG</button> -->
    </div>
    <div class="col-auto text-right">
      <button mat-button color="success" (click)="openNewCustomerDialog()" type="button">+ Tenant</button>
    </div>
  </div>
  <div *ngIf="projectTenants && projectTenants.length <= 0 && !loadingPEB" class="row mt-5 mb-5">
    <div class="col">
      <h4 class="m-0 font-weight-normal dkgray" style="line-height: 2rem">
        Oops, we don't have any tenants listed for this project. <br />
        To add one, click the button below.
      </h4>
      <!-- TODO -->
      <button mat-flat-button color="success" class="mt-5 mr-2" (click)="openNewCustomerDialog()">+ Tenant</button>
      <button mat-flat-button color="accent" class="mt-5" (click)="addUHATManaged()">+ UHAT Managed</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group
        #tenantTabGroup
        *ngIf="projectTenants && projectTenants.length > 0"
        (selectedTabChange)="changeTenantTabUIOnly ? (changeTenantTabUIOnly = false) : selectTenant(null, $event)"
        animationDuration="0ms"
      >
        <mat-tab *ngFor="let tenant of projectTenants">
          <div class="row">
            <div class="col">
              <ng-template mat-tab-label>
                <span>{{ tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name }}</span>
                <mat-icon class="green ml-3" *ngIf="tenant.approval_process.isFinalized">check_mark</mat-icon>
              </ng-template>
              <div class="row align-items-center mb-4">
                <div *ngFor="let p of filteredTenantPEBs" class="col-lg-3 col-md-4 col-sm-6 mb-2 mt-2">
                  <div
                    class="m-portlet m-portlet--mobile p-0 m-0 pointer item ease"
                    [ngClass]="{
                      'bg-ltltltblue': selectedPEB && p && selectedPEB.id === p.id,
                      'selected-peb': selectedPEB && p && selectedPEB.id === p.id,
                      'finalized-peb': p.id === tenant.selected_peb_id
                    }"
                    (click)="selectPEB(p)"
                  >
                    <div class="m-portlet__body p-3">
                      <div class="row">
                        <div class="col">
                          <label class="m-0 mb-1 font-weight-semibold dkblue main-label">
                            {{ p.title || 'PEB #' + p.sequence }}
                          </label>
                          <h4 class="m-0 font-weight-normal ltblue">
                            {{ p.total_cost | USDollar: 2:true:true:true }}
                          </h4>
                        </div>
                        <mat-icon class="green finalize-check" *ngIf="p.id === tenant.selected_peb_id"
                          >check_circle
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    matTooltip="{{
                      tenant.approval_process.hasFiles &&
                      project.cfmo_id &&
                      !tenant.approval_process.staffCanStart &&
                      !tenant.approval_process.tenantCanStart
                        ? 'Cannot create PEB - refer to message on the right'
                        : 'New PEB'
                    }}"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="300"
                  >
                    <button
                      (click)="addPEB(tenant)"
                      class="mt-3 mb-3"
                      [disabled]="
                        tenant.approval_process.hasFiles &&
                        project.cfmo_id &&
                        !tenant.approval_process.staffCanStart &&
                        !tenant.approval_process.tenantCanStart
                      "
                      mat-mini-fab
                      color="success"
                      type="button"
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div id="peb" class="m-portlet m-portlet--mobile" m-portlet="true">
                    <div
                      class="m-portlet__body peb"
                      *ngIf="selectedPEB && !showCoverLetter"
                      [ngClass]="{ 'border-ltblue': unsavedChangesExist }"
                    >
                      <div class="row dkblue peb-main">
                        <div class="col-12">
                          <div class="row align-items-center">
                            <div class="col">
                              <h2
                                *ngIf="!selectedPEB.editable"
                                class="dkblue m-0 font-weight-semibold d-inline-block align-middle"
                              >
                                {{ selectedPEB.title || 'PEB #' + selectedPEB.sequence }}
                                <small *ngIf="this.isEditing">
                                  <i
                                    (click)="
                                      selectedPEB.draft_title = selectedPEB.title || 'PEB #' + selectedPEB.sequence;
                                      selectedPEB.editable = true
                                    "
                                    class="ltblue la la-pencil pointer"
                                  ></i>
                                </small>
                              </h2>
                              <div *ngIf="selectedPEB.editable" class="peb-item-edit">
                                <mat-form-field class="peb-input full-width" appearance="outline">
                                  <input
                                    matInput
                                    autofocus
                                    [(ngModel)]="selectedPEB.draft_title"
                                    type="text"
                                    (ngModelChange)="unsavedChangesExist = true; selectedPEB.is_modified = true"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row align-items-center mb-3">
                              <div class="col">
                                <h4 class="dkblue font-weight-semibold m-0"></h4>
                              </div>
                              <div
                                class="col text-right pr-0"
                                matTooltip="{{
                                  tenant.selected_peb_id === selectedPEB.id
                                    ? 'This PEB is currently marked as the tenant\'s selection already.'
                                    : 'Mark this PEB as the tenant\' selection'
                                }}"
                                matTooltipPosition="above"
                                [matTooltipShowDelay]="300"
                              ></div>
                              <div class="col-auto text-right pl-0">
                                <button mat-button (click)="exportPEB(selectedPEB)" color="accent" type="button">
                                  <span> Download PDF </span>
                                </button>
                                <button
                                  class="ml-3"
                                  mat-flat-button
                                  color="accent"
                                  (click)="markPEBasSelected(selectedPEB, tenant)"
                                  *ngIf="isProjectAdmin && selectedPEB && tenant.approval_process.canSelectPEB"
                                  [disabled]="tenant.selected_peb_id === selectedPEB.id"
                                >
                                  {{ tenant.selected_peb_id === selectedPEB.id ? 'Selected PEB' : 'Select PEB' }}
                                </button>
                                <button
                                  class="ml-3 bg-white"
                                  mat-stroked-button
                                  color="accent"
                                  (click)="isEditing = true"
                                  *ngIf="!isEditing && isProjectAdmin && selectedPEB"
                                  [disabled]="tenant.approval_process.canReset || tenant.approval_process.isFinalized"
                                >
                                  Edit
                                </button>
                                <button
                                  mat-button
                                  class="gray"
                                  *ngIf="isEditing && isProjectAdmin && selectedPEB"
                                  (click)="isEditing = false; unsavedChangesExist = false; refresh()"
                                >
                                  {{ unsavedChangesExist ? 'Discard' : 'Cancel' }}
                                </button>
                                <button
                                  mat-flat-button
                                  [color]="unsavedChangesExist ? 'success' : null"
                                  (click)="savePEB(); isEditing = false"
                                  [disabled]="!unsavedChangesExist"
                                  *ngIf="isEditing && isProjectAdmin && selectedPEB"
                                >
                                  Save Changes
                                </button>
                                <button
                                  mat-icon-button
                                  [matMenuTriggerFor]="peb_form_menu"
                                  color="primary"
                                  class="ml-2"
                                >
                                  <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #peb_form_menu="matMenu" xPosition="before">
                                  <button
                                    mat-menu-item
                                    color="primary"
                                    (click)="duplicatePEB(); isEditing = false"
                                    [disabled]="
                                      (tenant.approval_process.canReset && !tenant.a) ||
                                      tenant.approval_process.isFinalized
                                    "
                                  >
                                    <mat-icon>file_copy</mat-icon>
                                    <span>Duplicate</span>
                                  </button>
                                  <button
                                    mat-menu-item
                                    color="primary"
                                    (click)="deletePEB(); isEditing = false"
                                    [disabled]="
                                      !tenant.approval_process.canDeletePEB &&
                                      (!tenant.approval_process.isFinalized ||
                                        selectedPEB.id === tenant.selected_peb_id)
                                    "
                                  >
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete PEB</span>
                                  </button>
                                </mat-menu>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <!-- PEB Header -->
                      <div class="row peb-header">
                        <a
                          *ngIf="!hideDetails"
                          (click)="hideDetails = !hideDetails"
                          class="header-hide ltblue opacity5"
                        >
                          Hide Details</a
                        >
                        <a *ngIf="hideDetails" (click)="hideDetails = !hideDetails" class="header-hide ltblue opacity5">
                          Show Details</a
                        >
                        <div class="col-12">
                          <div class="row align-items-center">
                            <div class="col-3">
                              <label class="main-label">Trust Investment</label>
                              <h5 class="dkblue font-weight-semibold">
                                {{ selectedPEB.trustInvestment | USDollar: 2:true:true:true }}
                              </h5>
                            </div>
                            <div class="col-3">
                              <!-- TENANT (DEPT) HERE -->
                              <label *ngIf="tenant.type_id !== 3" class="main-label"
                                >{{ tenant.tenant_name }} Cost</label
                              >
                              <label *ngIf="tenant.type_id === 3" class="main-label">Tenant Cost</label>
                              <h5 *ngIf="tenant.type_id !== 3" class="dkblue font-weight-semibold">
                                {{ selectedPEB.tenantInvestment | USDollar: 2:true:true:true }}
                              </h5>
                              <h5 *ngIf="tenant.type_id === 3" class="dkblue font-weight-semibold">N/A</h5>
                            </div>
                            <div class="col-3">
                              <label class="main-label">Tenant Allowance</label>
                              <h5 class="dkblue font-weight-semibold">
                                {{ selectedPEB.tenant_allowance | USDollar: 2:true:true:true }}
                              </h5>
                            </div>
                            <div class="col-3">
                              <label class="main-label">Total Rent / Yr</label>
                              <h5 class="dkblue font-weight-semibold">
                                {{ selectedPEB.total_rent_per_year | USDollar: 2:true:true:true }}
                              </h5>
                            </div>
                          </div>
                          <div *ngIf="!hideDetails" class="row align-items-center peb-header-lower">
                            <div class="col-md-4">
                              <label class="main-label">Design Timeline</label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Months"
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.design_timeline"
                                  (ngModelChange)="modifyField(selectedPEB)"
                                />
                              </mat-form-field>
                              <label class="main-label">Construction Timeline</label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Months"
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.construction_timeline"
                                  (ngModelChange)="modifyField(selectedPEB)"
                                />
                              </mat-form-field>
                              <label class="main-label">Total Timeline</label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <input
                                  matInput
                                  placeholder="Months"
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.total_timeline"
                                  (ngModelChange)="modifyField(selectedPEB)"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-md-4">
                              <label class="main-label full-width">
                                <div class="row">
                                  <div class="col">Construction SF</div>
                                </div>
                              </label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <input
                                  matInput
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.construction_sqft"
                                  (ngModelChange)="modifyField(selectedPEB)"
                                  type="number"
                                />
                              </mat-form-field>
                              <label class="main-label">Occupied SF</label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <input
                                  matInput
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.occupied_sqft"
                                  (ngModelChange)="modifyField(selectedPEB)"
                                  type="number"
                                />
                              </mat-form-field>
                              <label class="main-label"
                                >Circulation SF ({{ project.building_circulation_factor || 0 }}%)</label
                              >
                              <h6 class="font-weight-semibold dkblue">
                                {{ selectedPEB.circulation_sqft || 0 | number }}
                              </h6>
                            </div>
                            <div class="col-md-4">
                              <label class="main-label">Rental SF w/ Circ</label>
                              <h6 class="font-weight-semibold dkblue">
                                {{ selectedPEB.rental_sqft_w_circ ? (selectedPEB.rental_sqft_w_circ | number) : '' }}
                              </h6>

                              <label class="main-label">Rental Rate</label>
                              <mat-form-field class="peb-input full-width" appearance="outline">
                                <span class="gray" matPrefix>$&nbsp;</span>
                                <input
                                  matInput
                                  [disabled]="!this.isEditing"
                                  [(ngModel)]="selectedPEB.rental_rate"
                                  #rentalRate
                                  (ngModelChange)="modifyField(selectedPEB)"
                                  (blur)="blurUSDollarInput(selectedPEB.rental_rate, selectedPEB, 'rental_rate')"
                                />
                              </mat-form-field>

                              <div class="row mt-1">
                                <div class="col-6">
                                  <label class="main-label">
                                    Tenant Allowance / Circ SF
                                    <i
                                      class="fa fa-question-circle gray ml-2 pointer"
                                      [matMenuTriggerFor]="help_menu"
                                    ></i>
                                    <mat-menu class="p-3 mt-2" #help_menu="matMenu" xPosition="before">
                                      <div class="row mb-3">
                                        <div class="col">
                                          <p class="m-0 dkblue font-weight-semibold mb-2">
                                            <strong> Tenant Allowance / Circ SF </strong>
                                          </p>
                                          <p class="m-0 dkgray font-weight-normal mb-2">
                                            This is the Tenant Allowance per Rental SF (Including Circulation)
                                          </p>
                                          <p class="m-0 dkgray font-weight-normal">
                                            Rental SF w/ Circ x ( Tenant Allowance / Circ SF ) = Total Tenant Allowance
                                          </p>
                                        </div>
                                      </div>
                                    </mat-menu>
                                  </label>
                                  <mat-form-field class="peb-input full-width" appearance="outline">
                                    <span class="gray" matPrefix>$&nbsp;</span>
                                    <input
                                      matInput
                                      [disabled]="!this.isEditing"
                                      [(ngModel)]="selectedPEB.tenant_allowance_per_sqft"
                                      #tenantAllowance
                                      (ngModelChange)="modifyField(selectedPEB, 'tenant_allowance_per_sqft')"
                                      (blur)="
                                        blurUSDollarInput(selectedPEB.tenant_allowance, selectedPEB, 'tenant_allowance')
                                      "
                                    />
                                  </mat-form-field>
                                </div>
                                <div class="col-6">
                                  <label class="main-label">
                                    Total Tenant Allowance
                                    <i
                                      class="fa fa-question-circle gray ml-2 pointer"
                                      [matMenuTriggerFor]="help_menu_2"
                                    ></i>
                                    <mat-menu class="p-3 mt-2" #help_menu_2="matMenu" xPosition="before">
                                      <div class="row mb-3">
                                        <div class="col">
                                          <p class="m-0 dkblue font-weight-semibold mb-2">
                                            <strong> Total Tenant Allowance </strong>
                                          </p>
                                          <p class="m-0 dkgray font-weight-normal mb-2">
                                            This is the Total Tenant Allowance allocated for this tenant.
                                          </p>
                                          <p class="m-0 dkgray font-weight-normal">
                                            Rental SF w/ Circ x Tenant Allowance / Circ SF = ( Total Tenant Allowance )
                                          </p>
                                        </div>
                                      </div>
                                    </mat-menu>
                                  </label>
                                  <mat-form-field class="peb-input full-width" appearance="outline">
                                    <span class="gray" matPrefix>$&nbsp;</span>
                                    <input
                                      matInput
                                      [disabled]="!this.isEditing"
                                      [(ngModel)]="selectedPEB.tenant_allowance"
                                      #tenantAllowance
                                      (ngModelChange)="modifyField(selectedPEB, 'tenant_allowance')"
                                      (blur)="
                                        blurUSDollarInput(selectedPEB.tenant_allowance, selectedPEB, 'tenant_allowance')
                                      "
                                    />
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Line Items -->
                      <div *ngFor="let s of selectedPEB.sections; index as sectionIndex">
                        <!-- Section Header -->
                        <div class="row align-items-center mt-4">
                          <div class="col-12">
                            <div class="row align-items-center peb-section-header">
                              <div class="col pl-0">
                                <h5 class="m-0 dkblue font-weight-semibold">
                                  <b class="mr-2">{{ s.name }}</b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Section Column Titles -->
                        <div class="row">
                          <div class="col-12">
                            <div class="row align-items-center peb-table-header">
                              <div class="col-auto pr-0 pl-0 text-center" style="width: 30px">
                                <label class="main-label font-weight-semibold"> # </label>
                              </div>
                              <div class="col">
                                <label class="main-label font-weight-semibold"> Trade / Costs & Fees </label>
                              </div>
                              <div class="col-auto text-center pl-0 pr-0" style="width: 50px">
                                <label class="main-label font-weight-semibold"> % Cost </label>
                              </div>
                              <div class="col-2 text-right">
                                <label class="main-label font-weight-semibold">
                                  <span
                                    class="ltblue pointer mr-2"
                                    (click)="!this.isEditing ? null : fillConstructionSF(s.owner_type_id)"
                                  >
                                    Refresh
                                  </span>
                                  SF
                                </label>
                              </div>
                              <div class="col-2 text-right">
                                <label class="main-label font-weight-semibold"> Cost / SF </label>
                              </div>
                              <div class="col-2 text-right">
                                <label class="main-label font-weight-semibold"> Subtotal </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Section Line Items -->
                        <div
                          class="row"
                          *ngFor="
                            let i of s.owner_type_id === 1
                              ? selectedPEB.tenantItems
                              : s.owner_type_id === 2
                              ? selectedPEB.trustItems
                              : [];
                            index as elementIndex
                          "
                        >
                          <div class="col-12" *ngIf="i.type_id !== 4 || +i.subtotal !== 0">
                            <div class="row align-items-center item ease peb-line-item">
                              <div class="col-auto pr-0 pl-0 text-center" style="width: 30px">
                                <h6 class="d-inline-block gray pointer">
                                  {{ elementIndex + 1 }}
                                </h6>
                              </div>
                              <div class="col">
                                <div *ngIf="i.editable" class="peb-item-edit">
                                  <mat-form-field
                                    autofocus
                                    appearance="outline"
                                    class="peb-input full-width"
                                    *ngIf="[2, 4].indexOf(i.type_id) > -1"
                                  >
                                    <input
                                      matInput
                                      [(ngModel)]="i.draft_name"
                                      type="text"
                                      (ngModelChange)="modifyField(selectedPEB)"
                                    />
                                  </mat-form-field>
                                  <mat-form-field
                                    autofocus
                                    appearance="outline"
                                    class="peb-input full-width"
                                    *ngIf="i.type_id === 1"
                                  >
                                    <!-- <mat-label>Trade</mat-label> -->
                                    <mat-select
                                      matNativeControl
                                      [(ngModel)]="i.trade_id"
                                      (ngModelChange)="i.trade_name = getTradeName(i.trade_id)"
                                    >
                                      <mat-option [value]="null">-</mat-option>
                                      <mat-option *ngFor="let t of allTrades" [value]="t.id">{{ t.name }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <h6 *ngIf="!i.editable" class="m-0 dkgray font-weight-semibold">
                                  <!-- <span class="mr-2">{{i.trade_id ? ((i.trade_name || 'Unknown Trade') + ' Budget') : i.name}}</span> -->
                                  <span class="mr-2">{{
                                    i.trade_id
                                      ? i.trade_name || 'Unknown Trade'
                                      : i.type_id === 3
                                      ? 'Contingency Fee'
                                      : i.type_id === 4
                                      ? 'Tenant Allowance'
                                      : i.type_id === 5
                                      ? 'General Conditions Fee'
                                      : i.type_id === 6
                                      ? 'Management Fee'
                                      : i.name
                                  }}</span>
                                  <i
                                    *ngIf="this.isEditing && [1, 2].indexOf(i.type_id) > -1"
                                    (click)="
                                      i.draft_name = i.name;
                                      i.draft_trade_id = i.trade_id;
                                      i.draft_trade_name = i.trade_name;
                                      i.editable = true
                                    "
                                    class="ltblue la la-pencil fade pointer"
                                  ></i>
                                </h6>
                              </div>
                              <div class="col-auto text-center pl-0 pr-0" style="width: 80px">
                                <mat-form-field
                                  class="peb-input ltblue text-center full-width"
                                  appearance="outline"
                                  *ngIf="[2, 3, 5, 6].indexOf(i.type_id) > -1"
                                >
                                  <input
                                    matInput
                                    [disabled]="!this.isEditing"
                                    [(ngModel)]="i.percent_cost"
                                    (ngModelChange)="modifyField(i, 'percent_cost')"
                                    type="number"
                                  />
                                  <span class="gray" matSuffix>&nbsp;%</span>
                                </mat-form-field>
                                <h6 class="ltblue" *ngIf="[1].indexOf(i.type_id) > -1">
                                  {{ i.percent_cost / 100 | percent }}
                                </h6>
                              </div>
                              <div class="col-2 text-right">
                                <mat-form-field
                                  class="peb-input text-right full-width"
                                  appearance="outline"
                                  *ngIf="i.type_id === 1"
                                >
                                  <input
                                    matInput
                                    placeholder="0.00"
                                    [disabled]="!this.isEditing"
                                    [(ngModel)]="i.sqft"
                                    (ngModelChange)="modifyField(i, 'sqft')"
                                    type="number"
                                  />
                                </mat-form-field>
                                <span *ngIf="i.type_id !== 1">-</span>
                              </div>
                              <div class="col-2 text-right">
                                <mat-form-field
                                  class="peb-input text-right full-width"
                                  appearance="outline"
                                  *ngIf="i.type_id === 1"
                                >
                                  <span class="gray" matPrefix>$&nbsp;</span>
                                  <input
                                    matInput
                                    placeholder="0.00"
                                    [disabled]="!this.isEditing"
                                    [(ngModel)]="i.cost_per_sqft"
                                    (ngModelChange)="modifyField(i, 'cost_per_sqft')"
                                    (blur)="blurUSDollarInput(i.cost_per_sqft, i, 'cost_per_sqft')"
                                  />
                                </mat-form-field>
                                <span *ngIf="i.type_id !== 1">-</span>
                              </div>
                              <div class="col-2 text-right">
                                <mat-form-field class="peb-input text-right full-width" appearance="outline">
                                  <span class="gray" matPrefix>$&nbsp;</span>
                                  <input
                                    matInput
                                    placeholder="0.00"
                                    [disabled]="!this.isEditing || [4, 6].indexOf(i.type_id) > -1"
                                    [(ngModel)]="i.subtotal"
                                    (ngModelChange)="modifyField(i, 'subtotal')"
                                    (blur)="blurUSDollarInput(i.subtotal, i, 'subtotal')"
                                  />
                                </mat-form-field>
                              </div>
                              <i
                                *ngIf="this.isEditing && [1, 2].indexOf(i.type_id) > -1"
                                matTooltip="Delete Row"
                                matTooltipPosition="above"
                                [matTooltipShowDelay]="300"
                                class="line-item-delete fa fa-trash ease gray pointer"
                                (click)="deleteLineItem(i)"
                              ></i>
                            </div>
                          </div>
                        </div>

                        <!-- New Line Item -->
                        <div class="row align-items-center pt-2">
                          <div class="col">
                            <button
                              mat-icon-button
                              *ngIf="this.isEditing"
                              [matMenuTriggerFor]="menu_1"
                              class="green mr-2 d-inline-block align-middle"
                            >
                              <mat-icon>add_circle</mat-icon>
                            </button>
                            <mat-menu #menu_1="matMenu">
                              <button mat-menu-item color="primary" (click)="addLineItem(1, s.owner_type_id)">
                                <mat-icon> playlist_add </mat-icon>
                                <span> Add Cost Item ($/SF) </span>
                              </button>
                              <button mat-menu-item color="primary" (click)="addLineItem(2, s.owner_type_id)">
                                <mat-icon> playlist_add </mat-icon>
                                <span> Add Fee Item (%) </span>
                              </button>
                            </mat-menu>
                            <h5 class="m-0 d-inline-block d-inline-block align-middle ltgray font-weight-semibold">
                              Subtotal: {{ s.name }}
                            </h5>
                          </div>
                          <div class="col-auto pl-0 text-right">
                            <h5 class="m-0 dkblue opacity7">
                              <b>{{
                                (s.owner_type_id === 1
                                  ? selectedPEB.tenantInvestment
                                  : s.owner_type_id === 2
                                  ? selectedPEB.trustInvestment
                                  : 0
                                ) | USDollar
                              }}</b>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <!-- PEB Footer -->
                      <div class="row align-items-center pt-4">
                        <div class="col">
                          <h4 class="m-0 dkblue font-weight-bold">Total Construction Cost</h4>
                        </div>
                        <div class="col-auto text-right">
                          <h5 class="m-0 dkblue font-weight-bold">
                            {{ selectedPEB.total_cost_per_sqft | USDollar }}
                          </h5>
                        </div>
                        <div class="col-2 pl-0 text-right">
                          <h5 class="m-0 dkblue font-weight-bold">
                            {{ selectedPEB.total_cost | USDollar }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto" style="width: 400px">
              <div class="mb-5 mt-4">
                <div class="row align-items-center mb-2">
                  <div class="col text-center">
                    <h5
                      *ngIf="
                        filteredTenantPEBs.length > 0 ||
                        (!filteredTenantPEBs.length && (!tenant.bubble_drawing_ids.length || !project.cfmo_id))
                      "
                      class="m-0 font-weight-bold dkblue"
                    >
                      {{ getPebStatusText(tenant) }}
                    </h5>
                    <h5
                      *ngIf="!filteredTenantPEBs.length && tenant.bubble_drawing_ids.length && project.cfmo_id"
                      class="m-0 font-weight-bold dkblue"
                    >
                      Add a PEB to continue.
                    </h5>
                    <button
                      *ngIf="!project.cfmo_id"
                      mat-button
                      [routerLink]="['/projects/' + project.id + '/details']"
                      color="accent"
                      class="mt-2 pl-2 pr-2"
                      style="line-height: 24px"
                    >
                      Go to Project Details
                    </button>
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <div class="row align-items-center mb-2">
                  <div class="col">
                    <h5 class="dkblue m-0 font-weight-semibold">Reviews</h5>
                  </div>
                  <div class="col-auto">
                    <button
                      mat-button
                      style="line-height: 24px"
                      class="pl-2 pr-2"
                      color="accent"
                      type="button"
                      (click)="resetReview(tenant, true)"
                      *ngIf="tenant.approval_process && tenant.approval_process.canReset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div class="m-portlet m-0 br-10">
                  <div class="m-portlet__body p-3 item">
                    <div class="row align-items-center">
                      <div class="col">
                        <mat-icon
                          *ngIf="tenant.approval_process && !tenant.approval_process.isPending"
                          class="mr-3 align-middle"
                          [ngStyle]="{ color: tenant.approval_process.staffApprovalColor }"
                        >
                          {{ tenant.approval_process.staffApprovalIcon }}
                        </mat-icon>
                        <app-profile-thumbnail
                          *ngIf="tenant.approval_process && tenant.approval_process.isPending"
                          [noBorder]="true"
                          [matTooltip]="
                            tenant.approval_process.currentStaffReviewer.firstName +
                            ' ' +
                            tenant.approval_process.currentStaffReviewer.lastName
                          "
                          class="mr-3 d-inline-block align-middle"
                          [height]="26"
                          [width]="26"
                          [userId]="tenant.approval_process.currentStaffReviewer.id"
                        ></app-profile-thumbnail>
                        <h6
                          *ngIf="!tenant.saved_peb_approval_task_id && !tenant.peb_approval_task_id"
                          class="m-0 dkblue d-inline-block align-middle font-weight-semibold pointer"
                        >
                          Internal Review
                        </h6>
                        <h6
                          *ngIf="tenant.saved_peb_approval_task_id || tenant.peb_approval_task_id"
                          [routerLink]="
                            '/projects/' +
                            project.id +
                            '/tasks/' +
                            (tenant.saved_peb_approval_task_id || tenant.peb_approval_task_id)
                          "
                          class="m-0 dkblue d-inline-block align-middle font-weight-semibold pointer"
                        >
                          Internal Review
                        </h6>
                      </div>
                      <div class="col-auto text-right">
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="submitApproval(tenant, true)"
                          *ngIf="
                            tenant.approval_process.staffCanStart &&
                            !tenant.saved_peb_approval_task_id &&
                            filteredTenantPEBs.length &&
                            isProjectAdmin
                          "
                        >
                          Start
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="submitApproval(tenant, true)"
                          *ngIf="
                            tenant.approval_process.staffCanStart && tenant.saved_peb_approval_task_id && isProjectAdmin
                          "
                        >
                          Submit Revision
                        </button>
                        <button
                          mat-button
                          color="accent"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="viewTask(tenant.peb_approval_task_id || tenant.saved_peb_approval_task_id)"
                          *ngIf="
                            tenant.approval_process.hasStaffTask ||
                            (!isProjectAdmin && tenant.saved_peb_approval_task_id)
                          "
                        >
                          View
                        </button>
                        <button
                          mat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="resetReview(tenant, false)"
                          *ngIf="tenant.approval_process.staffCanAddNewRevision && isProjectAdmin"
                        >
                          + Revision
                        </button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="tenant.approval_process.needsTenantApproval" class="m-portlet__body p-3 item">
                    <div class="row align-items-center">
                      <div class="col">
                        <app-profile-thumbnail
                          *ngIf="
                            tenant.approval_process._tenantReview &&
                            tenant.approval_process._tenantReview.approvalStatus === 1
                          "
                          [noBorder]="true"
                          [matTooltip]="
                            tenant.approval_process.tenantReviewAssignedUser.firstName +
                            ' ' +
                            tenant.approval_process.tenantReviewAssignedUser.lastName
                          "
                          class="mr-3 d-inline-block align-middle"
                          [height]="26"
                          [width]="26"
                          [userId]="tenant.approval_process.tenantReviewAssignedUser.id"
                        ></app-profile-thumbnail>
                        <mat-icon
                          *ngIf="
                            tenant.approval_process._tenantReview &&
                            tenant.approval_process._tenantReview.approvalStatus !== 1
                          "
                          class="mr-3 align-middle"
                          [ngStyle]="{ color: tenant.approval_process.tenantApprovalColor }"
                        >
                          {{ tenant.approval_process.tenantApprovalIcon }}
                        </mat-icon>
                        <!--                        `/projects/${this.getTask.project_id}/tasks/${this.getTask.id}`-->
                        <h6
                          *ngIf="!tenant.saved_tenant_approval_task_id && !tenant.tenant_approval_task_id"
                          class="m-0 dkblue d-inline-block align-middle font-weight-semibold pointer"
                        >
                          Tenant Review
                        </h6>
                        <h6
                          *ngIf="tenant.saved_tenant_approval_task_id || tenant.tenant_approval_task_id"
                          [routerLink]="
                            '/projects/' +
                            project.id +
                            '/tasks/' +
                            (tenant.saved_tenant_approval_task_id || tenant.tenant_approval_task_id)
                          "
                          class="m-0 dkblue d-inline-block align-middle font-weight-semibold pointer"
                        >
                          Tenant Review
                        </h6>
                      </div>
                      <div class="col-auto text-right">
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="submitApproval(tenant, false)"
                          *ngIf="
                            tenant.approval_process.tenantCanStart &&
                            !tenant.saved_tenant_approval_task_id &&
                            filteredTenantPEBs.length &&
                            isProjectAdmin
                          "
                        >
                          Start
                        </button>
                        <button
                          mat-flat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="submitApproval(tenant, false)"
                          *ngIf="
                            tenant.approval_process.tenantCanStart &&
                            tenant.saved_tenant_approval_task_id &&
                            isProjectAdmin
                          "
                        >
                          Submit Revision
                        </button>
                        <button
                          mat-button
                          color="accent"
                          type="button"
                          (click)="viewTask(tenant.tenant_approval_task_id || tenant.saved_tenant_approval_task_id)"
                          style="line-height: 28px"
                          *ngIf="
                            (tenant.approval_process && tenant.approval_process.hasTenantTask) ||
                            (!isProjectAdmin && tenant.saved_tenant_approval_task_id)
                          "
                        >
                          View
                        </button>
                        <button
                          mat-button
                          color="success"
                          type="button"
                          class="pl-3 pr-3"
                          style="line-height: 28px"
                          (click)="resetReview(tenant, false)"
                          *ngIf="tenant.approval_process.tenantCanAddNewRevision && isProjectAdmin"
                        >
                          + Revision
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <button
                      mat-flat-button
                      class="full-width"
                      color="accent"
                      type="button"
                      (click)="finalizePEB(tenant)"
                      *ngIf="tenant.approval_process"
                      [disabled]="!tenant.approval_process.canFinalize"
                    >
                      Finalize this PEB
                    </button>
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <div class="row align-items-center mb-2">
                  <div class="col">
                    <h5 class="dkblue m-0 font-weight-semibold">Supporting Documents</h5>
                  </div>
                  <div class="col-auto">
                    <button mat-button (click)="getPdfPackage(tenant)" color="accent" class="pl-2 pr-2 lh-24">
                      <i class="material-icons" style="font-size: 18px; height: 18px; width: 18px">get_app</i>
                      PEB Package
                    </button>
                  </div>
                </div>
                <div class="m-portlet m-0 br-10">
                  <div class="m-portlet__body p-3 item">
                    <div class="d-flex align-items-center">
                      <p class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Cover Letter</p>
                      <!-- <button
                              mat-icon-button
                              color="accent"
                              class="ml-3"
                              matTooltip="Download"
                              matTooltipPosition="above"
                              (click)="exportCoverLetter()"
                              [matTooltipShowDelay]="300"
                            >
                              <mat-icon>get_app</mat-icon>
                            </button> -->
                      <button
                        (click)="openCoverLetter()"
                        mat-button
                        type="button"
                        color="accent"
                        style="line-height: 28px"
                        class="pl-3 pr-3 ml-3"
                        [disabled]="!allowAdditionalFormEdit(tenant)"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div class="m-portlet__body p-3 item">
                    <div class="d-flex flex-wrap align-items-center text-truncate">
                      <p class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Bubble Drawing</p>
                      <button
                        (click)="attachBubbleDrawingFile(tenant)"
                        *ngIf="tenant.bubble_drawing_ids.length < 1"
                        mat-flat-button
                        type="button"
                        color="success"
                        style="line-height: 28px"
                        class="pl-2 pr-2"
                      >
                        Add
                      </button>
                      <mat-chip-list
                        *ngFor="let file of tenant.bubble_drawing_ids; let last = last"
                        class="ml-auto"
                        [ngClass]="{ 'mb-1': tenant.bubble_drawing_ids.length > 1 && !last }"
                      >
                        <app-file-chip
                          [file]="{ id: file }"
                          [removable]="allowAdditionalFormEdit(tenant)"
                          (removeEvent)="removeBubbleDrawing(tenant, file)"
                        ></app-file-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                  <div class="m-portlet__body p-3 item">
                    <div class="d-flex align-items-center">
                      <p class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Reimbursement Agreement</p>
                      <button
                        (click)="attachReimbursementFile(tenant)"
                        *ngIf="allowAdditionalFormEdit(tenant) && tenant.reimbursement_file_id === null"
                        mat-flat-button
                        type="button"
                        color="success"
                        style="line-height: 28px"
                        class="pl-2 pr-2"
                      >
                        Add
                      </button>
                      <mat-chip-list *ngIf="tenant.reimbursement_file_id != null" class="ml-auto">
                        <app-file-chip
                          [file]="{
                            name: tenant.reimbursement_file_name,
                            id: tenant.reimbursement_file_id
                          }"
                          [removable]="allowAdditionalFormEdit(tenant)"
                          (removeEvent)="removeReimbursementFromTenant(tenant)"
                        ></app-file-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                  <div class="m-portlet__body p-3 item">
                    <div class="d-flex align-items-center">
                      <p class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Exhibit B</p>
                      <button
                        (click)="attachExhibitB(tenant)"
                        *ngIf="allowAdditionalFormEdit(tenant) && tenant.exhibit_b_file_id === null"
                        mat-flat-button
                        type="button"
                        color="success"
                        style="line-height: 28px"
                        class="pl-2 pr-2"
                      >
                        Add
                      </button>
                      <mat-chip-list *ngIf="tenant.exhibit_b_file_id != null" class="ml-auto">
                        <app-file-chip
                          [file]="{
                            name: tenant.exhibit_b_file_name,
                            id: tenant.exhibit_b_file_id
                          }"
                          [removable]="allowAdditionalFormEdit(tenant)"
                          (removeEvent)="removeExhibitBFromTenant(tenant)"
                        ></app-file-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                  <div class="m-portlet__body p-3 item">
                    <div class="d-flex align-items-center">
                      <p class="m-0 dkblue font-weight-semibold mr-auto text-truncate">Sublease Agreement</p>
                      <button
                        (click)="attachSubleaseContract(tenant)"
                        *ngIf="allowAdditionalFormEdit(tenant) && tenant.sublease_contract_file_id === null"
                        mat-flat-button
                        type="button"
                        color="success"
                        style="line-height: 28px"
                        class="pl-2 pr-2"
                      >
                        Add
                      </button>
                      <mat-chip-list *ngIf="tenant.sublease_contract_file_id != null" class="ml-auto">
                        <app-file-chip
                          [file]="{
                            name: tenant.sublease_contract_file_name,
                            id: tenant.sublease_contract_file_id
                          }"
                          [removable]="allowAdditionalFormEdit(tenant)"
                          (removeEvent)="removeSubleaseContract(tenant)"
                        ></app-file-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!------------------------------------- PEB / COVER LETTER EXPORT ------------------------------------->

<!-- <div id="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{bottom: 50}">
    <app-peb-export-cover-letter></app-peb-export-cover-letter>
  </kendo-pdf-export>
</div> -->

<div id="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ getCurrentProject().code }} | {{ getCurrentProject().building_code }}-{{
                    getCurrentProject().floor_code
                  }}
                  | {{ getCurrentProject().title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ getCurrentProject().building_code }}-{{ getCurrentProject().floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ getCurrentProject().project_manager_first_name }}
                  {{ getCurrentProject().project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0"> Total SF </label>
                <p>
                  {{
                    getCurrentProject().square_footage
                      ? numberWithCommas(getCurrentProject().square_footage)
                      : 'Not Given'
                  }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ getCurrentProject().end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ getCurrentProject().architect_first_name }}
                  {{ getCurrentProject().architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-3 mr-auto">
            <!-- REPEAT ROWS FOR EACH REVIEWER -->
            <div class="row mb-4" *ngFor="let reviewer of currentReviewers">
              <div class="col">
                <label class="main-label mt-0">
                  {{ (reviewer.status && reviewer.dateCreated) || 'Pending Review' }}
                </label>
                <p class="m-0">
                  <b>{{ reviewer.firstName }} {{ reviewer.lastName }}</b
                  ><br />
                  <span>{{ reviewer.title }}</span
                  ><br />
                  <span *ngIf="reviewer.status === 1" class="green font-weight-semibold mt-2 mb-2"> Approved </span>
                  <!-- <br />
                  <span class="dkgray">
                    {{ reviewer.comment }}
                  </span> -->
                </p>
              </div>
            </div>
          </div>
          <div class="col-9 ml-auto pr-5">
            <div class="row mb-4 mt-2">
              <div class="col">
                <h4 class="m-0 font-weight-bold">Preliminary Estimated Budget</h4>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <label class="main-label mt-0"> Prepared For </label>
                <p class="m-0">
                  <b>{{ currentTenantInfo.name }}</b
                  ><br />
                  <span>{{ currentTenantInfo.title }}</span
                  ><br />
                  <span>{{ currentTenantInfo.department }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <p class="m-0">
                  <!-- TENANT REP FIRST NAME -->
                  {{ currentTenantInfo.first_name }}, <br /><br />
                  <span [innerHTML]="coverLetterDesc" style="white-space: pre-wrap"> </span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: PROJECT MANAGER -->
                <p class="m-0">
                  <b>{{ pmInfo.name }}</b
                  ><br />
                  <span>{{ pmInfo.title }}</span
                  ><br />
                  <span>{{ pmInfo.email }}</span>
                  <br />
                  <span>{{ pmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: WORKSPACE MANAGER -->
                <p class="m-0" *ngIf="wmInfo">
                  <b>{{ wmInfo.name }}</b
                  ><br />
                  <span>{{ wmInfo.title }}</span
                  ><br />
                  <span>{{ wmInfo.email }}</span>
                  <br />
                  <span>{{ wmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <!-- PROJECT: ARCHITECT -->
                <p class="m-0" *ngIf="architectInfo">
                  <!-- MIGHT PUT AN NGIF ON THE ARCHITECT TO ADD TIM'S CREDENTIALS?? -->
                  <b>{{ architectInfo.name }}</b
                  ><br />
                  <span>{{ architectInfo.title }}</span
                  ><br />
                  <span>{{ architectInfo.email }}</span>
                  <br />
                  <span>{{ architectInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div *ngIf="exportingPEB != null" class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0 text-truncate">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ getCurrentProject().code }} | {{ getCurrentProject().building_code }}-{{
                    getCurrentProject().floor_code
                  }}
                  | {{ getCurrentProject().title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ getCurrentProject().building_code }}-{{ getCurrentProject().floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ getCurrentProject().project_manager_first_name }}
                  {{ getCurrentProject().project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> PEB Construction SF </label>
                <p>
                  {{ numberWithCommas(exportingPEB.construction_sqft) }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ getCurrentProject().end_date | date: 'd MMMM yyyy' }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Architect </label>
                <p>
                  {{ getCurrentProject().architect_first_name }}
                  {{ getCurrentProject().architect_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-approval mb-5">
        <div class="row align-items-top">
          <div class="col-3">
            <label class="main-label m-0">Your Review</label>
            <div class="mt-2">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Approve this Budget</p>
            </div>
            <div class="mt-3">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Request a Change</p>
            </div>
          </div>
          <div class="col">
            <label class="main-label m-0">Your Name (Print)</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Your Signature</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
          <div class="col">
            <label class="main-label m-0">Funding Source</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Signature Date</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
        </div>
      </section>
      <div>
        <section class="form-title">
          <div class="row">
            <div class="col">
              <h1 class="black font-weight-bold m-0">
                PEB &nbsp;
                <small>
                  {{
                    exportingPEB.title ? exportingPEB.title : 'Preliminary Estimated Budget #' + exportingPEB.sequence
                  }}
                </small>
              </h1>
              <!-- <p class="m-0">
                Prepared for:
                <b>
                  {{
                    exportingTenant
                      ? exportingTenant.type_id === 3
                        ? 'UHAT'
                        : exportingTenant.tenant_name
                      : ''
                  }}
                </b>
              </p> -->
            </div>
          </div>
        </section>
        <section class="form-highlights">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <label class="main-label mt-0">Total Project</label>
                  <p class="m-0 dkgray">
                    {{ exportingPEB.total_cost | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Trust Investment</label>
                  <p class="m-0 dkgray">
                    {{ exportingPEB.trustInvestment | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Tenant Allowance</label>
                  <p class="m-0 dkgray">
                    {{ exportingPEB.tenant_allowance | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Tenant Investment</label>
                  <p class="m-0 font-weight-bold">
                    {{ exportingPEB.tenantInvestment | USDollar: 2:true:true:true }}
                  </p>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Total Rent/ Yr</label>
                  <p class="m-0 font-weight-bold">
                    {{ exportingPEB.total_rent_per_year | USDollar: 2:true:true:true }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row form-border-divider"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="main-label mt-0">Design</label>
                  <h6 class="m-0">
                    {{ exportingPEB.design_timeline }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Construction</label>
                  <h6 class="m-0">
                    {{ exportingPEB.construction_timeline }}
                  </h6>
                </div>
                <!-- <div class="col">
                      <label class="main-label mt-0">Total Time</label>
                      <h6 class="m-0">
                        {{exportingPEB.total_timeline}}
                      </h6>
                    </div> -->
                <div class="col">
                  <label class="main-label mt-0">Constr. SF</label>
                  <h6 class="m-0">
                    {{ numberWithCommas(exportingPEB.construction_sqft) }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Occupied SF</label>
                  <h6 class="m-0">
                    {{ numberWithCommas(exportingPEB.occupied_sqft) }}
                  </h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Circulation</label>
                  <h6 class="m-0">{{ project.building_circulation_factor }}%</h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Rental SF</label>
                  <h6 class="m-0">{{ exportingPEB.rental_sqft_w_circ }} SF</h6>
                </div>
                <div class="col">
                  <label class="main-label mt-0">Rental Rate</label>
                  <h6 class="m-0">
                    {{ exportingPEB.rental_rate | USDollar: 2:true:true:true }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="form-table mb-4 mt-3"
          *ngFor="let s of exportingPEB.sections; index as sectionIndex"
          [hidden]="
            (s.owner_type_id === 1 && exportingPEB.hideTenantSection) ||
            (s.owner_type_id === 2 && exportingPEB.hideTrustSection)
          "
        >
          <div class="row form-section-title">
            <div class="col">
              <p class="black m-0 font-weight-bold">
                {{ s.name }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="mdgray m-0">
                {{ s.owner_type_id === 2 ? 'Trust Investment' : 'Tenant Investment' }}
              </p>
            </div>
          </div>
          <div class="row m-0 form-table-header align-items-center">
            <div class="col-1 pl-0 pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Item </label>
            </div>
            <div class="col-4">
              <label class="main-label m-0 p-0 text-uppercase"> Trade / Cost </label>
            </div>
            <div class="col-1 text-center">
              <label class="main-label m-0 p-0 text-uppercase"> % </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> SF </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Cost / SF </label>
            </div>
            <div class="col-2 text-right pr-0">
              <label class="main-label m-0 p-0 text-uppercase"> Total </label>
            </div>
          </div>
          <div
            class="row"
            *ngFor="
              let item of s.owner_type_id === 1
                ? exportingPEB.tenantItems
                : s.owner_type_id === 2
                ? exportingPEB.trustItems
                : [];
              index as elementIndex
            "
            [hidden]="+item.subtotal === 0"
          >
            <div class="col-12" *ngIf="item.type_id !== 4 || +item.subtotal !== 0">
              <div class="row m-0 form-table-line-item">
                <div class="col-1 pl-0 pr-0">
                  <p class="mdgray m-0 manage-content">
                    {{ elementIndex + 1 }}
                  </p>
                </div>
                <div class="col-4">
                  <p class="m-0 manage-content">
                    {{
                      item.trade_id
                        ? item.trade_name || 'Unknown Trade'
                        : item.type_id === 3
                        ? 'Contingency Fee'
                        : item.type_id === 4
                        ? 'Tenant Allowance'
                        : item.type_id === 5
                        ? 'General Conditions Fee'
                        : item.type_id === 6
                        ? 'Management Fee'
                        : item.name
                    }}
                  </p>
                </div>
                <div class="col-1 text-center">
                  <p class="mdgray m-0">
                    {{ item.percent_cost / 100 | percent }}
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0">
                    <span *ngIf="item.type_id === 1">{{ numberWithCommas(item.sqft) }}</span>
                    <span *ngIf="item.type_id !== 1">-</span>
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0">
                    <span *ngIf="item.type_id === 1">{{ item.cost_per_sqft | USDollar: 2:true:true:true }}</span>
                    <span *ngIf="item.type_id !== 1">-</span>
                  </p>
                </div>
                <div class="col-2 text-right pr-0">
                  <p class="m-0">
                    {{ item.subtotal | USDollar: 2:true:true:true }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div class="row m-0 mt-2 form-section-footer">
              <div class="col pl-0">
                <p class="m-0 font-weight-semibold">Subtotal: {{ s.name }}</p>
              </div>
              <div class="col-2 text-right pr-0"></div>
              <div class="col-2 text-right pr-0">
                <p class="m-0 font-weight-bold">
                  {{
                    (s.owner_type_id === 1
                      ? exportingPEB.tenantInvestment
                      : s.owner_type_id === 2
                      ? exportingPEB.trustInvestment
                      : 0
                    ) | USDollar: 2:true:true:true
                  }}
                </p>
              </div>
            </div>
          </section>
        </section>
        <section class="form-totals">
          <div class="row m-0">
            <div class="col pl-0">
              <p class="m-0 black font-weight-bold">Total Project Budget</p>
            </div>
            <div class="col-2 text-right pr-0">
              <p class="m-0 black font-weight-bold">
                {{ exportingPEB.total_cost_per_sqft | USDollar: 2:true:true:true }}
              </p>
            </div>
            <div class="col-2 pr-0 text-right">
              <p style="font-size: 0.7rem; margin-right: -1px !important" class="m-0 black font-weight-bold">
                {{ exportingPEB.total_cost | USDollar: 2:true:true:true }}
              </p>
            </div>
          </div>
        </section>
        <section id="comments" class="mt-5 mb-5">
          <div class="row">
            <div class="col">
              <label class="main-label m-0">Comments</label>
              <div class="row m-0 border-bottom border-dark pb-3"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
            </div>
          </div>
        </section>
        <!-- <div *ngIf="1 < PEBsToExport.length - 1" class="page-break"></div> -->
      </div>
    </div>
  </kendo-pdf-export>
</div>
