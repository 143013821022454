<div #mainScreen (window:resize)="onResize($event)" data-test-id="tasks" id="tasks" class="subcomponent ease tasks">
  <section
    style="z-index: 800"
    id="aos_trigger"
    class="ease task-list p-4"
    [ngClass]="{ 'task-list-flex': taskPanelOpen && !overlay_task_panel }"
  >
    <div class="row align-items-center">
      <div class="col-auto pr-0 phase-nav">
        <h4 *ngIf="!(authService.isAppAdmin || isModuleAdmin || isModuleStaff)" class="m-0 dkblue font-weight-semibold">
          My Tasks
        </h4>
        <h4
          *ngIf="phases?.length === 1 && (authService.isAppAdmin || isModuleAdmin || isModuleStaff)"
          class="m-0 dkblue font-weight-semibold"
        >
          Tasks
        </h4>
        <div *ngIf="phases?.length > 1 && (authService.isAppAdmin || isModuleAdmin || isModuleStaff)">
          <button class="phase-nav-first ease" (click)="selectPhaseInDirection('left')" [disabled]="!allowedToMoveLeft">
            <i class="fa fa-angle-left"></i>
          </button>
          <button [matMenuTriggerFor]="phase_menu" class="phase-nav-middle ease" [ngClass]="{ pulse: pulse }">
            {{ phase ? phase.name : '' }} Phase
          </button>
          <mat-menu #phase_menu="matMenu" xPosition="after" class="mat-menu-sm">
            <button mat-menu-item class="dkblue" *ngFor="let p of phases" (click)="selectPhase(p.id)">
              {{ p.name }}
            </button>
          </mat-menu>
          <button
            class="phase-nav-last ease"
            (click)="selectPhaseInDirection('right')"
            [disabled]="!allowedToMoveRight"
          >
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
      <span *ngIf="phases?.length > 1">
        <div
          *ngIf="canChangePhase && phase && phase.name != projectService.currentSelectedProject.current_phase_name"
          class="col-auto"
        >
          <button (click)="setPhase(phase)" mat-button color="accent" type="button">Activate Phase</button>
        </div>
        <div
          *ngIf="
            canChangePhase &&
            phase &&
            phase.name === projectService.currentSelectedProject.current_phase_name &&
            !isLastPhase
          "
          class="col-auto"
        >
          <button (click)="completePhase(phase)" mat-stroked-button class="bg-white" color="accent" type="button">
            Complete Phase
          </button>
        </div>
      </span>
      <div class="col pl-0 text-right">
        <div class="tasks-actions ease d-flex justify-content-end">
          <app-user-select-dropdown
            #userSelectDropDown
            *ngIf="isModuleStaff"
            [assignedUsers]="assignedUsers"
            (addUserIdTasksFilter)="addUserIdTasksFilter($event)"
            (removeUserIdTasksFilter)="removeUserIdTasksFilter()"
          ></app-user-select-dropdown>

          <mat-form-field
            appearance="outline"
            class="ease-fast ml-3"
            [ngClass]="{ 'small-search': !showSearch, 'med-search': showSearch }"
          >
            <input
              matInput
              (focus)="showSearch = true"
              (blur)="showSearch = false"
              class="ease-fast"
              [(ngModel)]="searchTerm"
              autocomplete="off"
              (input)="updateSearchTerm()"
            />
            <mat-icon *ngIf="!searchTerm" class="gray" matSuffix>search</mat-icon>
            <mat-icon
              *ngIf="searchTerm"
              matSuffix
              aria-label="Clear"
              class="ltgray pointer"
              (click)="updateSearchTerm(true)"
              >cancel</mat-icon
            >
          </mat-form-field>
          <button
            mat-button
            (click)="toggleTaskFilters()"
            color="accent"
            class="ml-3 px-3"
            [ngClass]="{ gray: !showFilters, 'task-action-on': showFilters || filterIndicator() }"
            [matBadge]="filterIndicator()"
            matBadgeOverlap="true"
            [matBadgeHidden]="showFilters || !filterIndicator()"
            matBadgePosition="before"
            matBadgeColor="accent"
            matTooltip="Filters"
            matTooltipPosition="below"
            [matTooltipShowDelay]="300"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button
            mat-icon-button
            [matMenuTriggerFor]="add_menu"
            class="ml-3 bg-green white"
            *ngIf="isModuleStaff"
            matTooltip="Add New"
            matTooltipPosition="below"
            [matTooltipShowDelay]="300"
          >
            <mat-icon style="margin-top: -1px; margin-right: -1px">add</mat-icon>
          </button>
          <mat-menu #add_menu="matMenu" class="mt-4" xPosition="before">
            <!-- TODO ADD CREATE TASK DIALOG  -->
            <button (click)="createTask()" mat-menu-item>
              <mat-icon>assignment_turned_in</mat-icon>
              <span>Create Task</span>
            </button>
            <button (click)="openAddMilestoneDialog()" mat-menu-item>
              <mat-icon>add_box</mat-icon>
              <span>Create Milestone</span>
            </button>
            <!-- TODO ADD UPLOAD FILE DIALOG  -->
            <button (click)="uploadFile()" mat-menu-item>
              <mat-icon>cloud_upload</mat-icon>
              <span>Upload File</span>
            </button>
            <button
              *ngIf="authService.isAppAdmin || isModuleAdmin || isModuleStaff"
              mat-menu-item
              (click)="exportData()"
            >
              <mat-icon>file_download</mat-icon>
              <span>Export Task Data</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div *ngIf="phases?.length > 1 || showFilters || editTasks" class="row quick-links align-items-center mt-2 mb-3">
      <div *ngIf="phases?.length > 1" class="col-auto">
        <span *ngIf="authService.isAppAdmin || isModuleAdmin || isModuleStaff">
          <h6 class="gray m-0 mb-1 mr-3 d-inline-block align-middle font-weight-semibold">Quick Links:</h6>
          <span *ngFor="let f of forms">
            <button
              mat-button
              class="pl-3 pr-3"
              color="accent"
              type="button"
              style="line-height: 28px"
              *ngIf="phase && f.phase_name === phase.name && projectService.currentSelectedProjectId"
              [routerLink]="['/projects/' + projectService.currentSelectedProjectId + f.route]"
            >
              {{ f.name }}
            </button>
          </span>
        </span>
      </div>
      <div *ngIf="showFilters" class="col task-filters text-right">
        <mat-slide-toggle class="ml-5" [checked]="approvalsOnly" (change)="toggleApprovalsOnlyFilter()" color="accent">
          <span class="ltblue font-weight-semibold ml-2" style="font-size: 1rem"> Reviews Only </span>
        </mat-slide-toggle>
        <mat-slide-toggle class="ml-5" [checked]="hideComplete" (change)="toggleHideCompleteFilter()" color="accent">
          <span class="ltblue font-weight-semibold ml-2" style="font-size: 1rem"> Hide Complete </span>
        </mat-slide-toggle>
      </div>
      <div class="col text-right task-filters ease" *ngIf="editTasks">
        <!--        TODO add back in 'reorder Milestones and Tasks' when milestone reordering is enabled again - Two other locations below, removed static booleans-->
        <h6 class="m-0 ltblue font-weight-normal d-inline-block align-middle font-italic">
          You can now reorder Tasks.
        </h6>

        <button
          mat-flat-button
          class="ml-3 pl-3 pr-3"
          color="accent"
          style="line-height: 28px"
          (click)="toggleTaskEdit()"
          *ngIf="authService.isAppAdmin || isModuleAdmin || isModuleStaff"
        >
          Done
        </button>
      </div>
    </div>
    <div
      class="m-portlet mt-4 bg-transparent"
      *ngIf="!loadingMilestones && (!milestones?.length || milestones.length === 0)"
    >
      <div class="m-portlet__body p-4 d-flex flex-column text-center">
        <h5 class="m-0 dkgray font-weight-normal lh-24 mb-2">
          There haven't been any Milestones or Tasks added to this
          {{ moduleId === 1 ? 'phase' : 'project' }} yet. To add some, use the add button above.
        </h5>
        <p class="m-0 dkgray font-weight-normal lh-20">
          <strong>Milestone</strong>: a group of tasks that are needed to accomplish a specific stage or phase of a
          project.
        </p>
        <p class="m-0 dkgray font-weight-normal lh-20">
          <strong>Task</strong>: a specific action item assigned to a specific person. Tasks are assembled to
          collectively fulfill a Project Milestone.
        </p>
      </div>
    </div>
    <div cdkDropList [cdkDropListData]="milestones" (cdkDropListDropped)="dropMilestone($event)" class="row mt-2">
      <div cdkDropListGroup class="col">
        <div
          cdkDrag
          [cdkDragDisabled]="isUpdatingTaskRank"
          ata-aos-duration="500"
          data-aos-delay="200"
          data-aos-anchor="#aos_trigger"
          class="bg-main"
          *ngFor="let milestone of milestones"
          [ngClass]="{
            'not-allowed': isUpdatingTaskRank
          }"
        >
          <!-- <button
            (click)="closeTaskContainer(taskContainer)"
            cdkDragHandle
            mat-icon-button
            color="primary"
            type="button"
            class="gray draggable position-absolute"
            style="margin-left: -25px"
          >
            <mat-icon class="indicator-icon">drag_indicator</mat-icon>
          </button> -->
          <app-milestone-task-container
            #taskContainer
            [taskSelectedFromEmitter]="currentLinkedTask"
            [milestoneData]="milestone"
            (milestoneDeactivateEvent)="removeMilestone($event)"
            [editTasks]="editTasks"
            (phaseUpdated)="phaseUpdated.emit(null)"
            (taskClicked)="taskClicked($event)"
            [projectManagerId]="projectService?.currentSelectedProject?.project_manager_id"
            (isUpdatingTaskRank)="handleUpdatingTaskRanks($event)"
            [taskRanksAreUpdating]="taskRanksAreUpdating"
          ></app-milestone-task-container>
          <!-- <div class="milestone-placeholder ease" *cdkDragPlaceholder></div> -->
        </div>
      </div>
    </div>
  </section>
  <section
    style="z-index: 1000"
    class="easeTask"
    [ngClass]="{
      'side-task-panel-open': taskPanelOpen,
      'side-task-panel-closed': !taskPanelOpen && showDesktop,
      'side-task-panel-open-ipad': taskPanelOpen && overlay_task_panel,
      'side-task-panel-closed-ipad': !taskPanelOpen && overlay_task_panel
    }"
  >
    <app-project-task-panel
      [hidden]="isLinkedWOTask"
      (closeDialog)="drawerClosed()"
      (convertedToLinkedTask)="convertedWOLinkedTask($event)"
    ></app-project-task-panel>
    <app-work-order-details-panel
      [hidden]="!isLinkedWOTask"
      [workOrder]="currentLinkedTask?.work_order"
      [taskData]="{
        project_id: currentLinkedTask?.project_id,
        created_by_id: currentLinkedTask?.created_by_id
      }"
      (closeDialog)="drawerClosed()"
      (deleteLinkedTask)="deleteLinkedTask(currentLinkedTask)"
    ></app-work-order-details-panel>
  </section>
  <div
    style="z-index: 980"
    *ngIf="taskPanelOpen && overlay_task_panel"
    (click)="taskPanelOpen = false"
    class="task-overlay"
  ></div>
</div>
