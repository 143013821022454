import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import Fuse from 'fuse.js';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent {
  @Input() title: string;
  @Input() items: any[] = [];
  @Input() searchFields: string[] = [];
  @Input() description: string;
  @Input() threshold: number = 0;
  @Input() control = new FormControl(null);
  @Input() displayFn;
  filteredOptions: Observable<any[]>;
  fuse: Fuse<any>;
  constructor() {}

  ngOnChanges(): void {
    if (!this.items) return;
    const fuseOptions = {
      shouldSort: false,
      keys: this.searchFields,
      threshold: this.threshold,
      ignoreLocation: true,
    };
    this.fuse = new Fuse(this.items, fuseOptions);
    this.control.addValidators(this.validItem());
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: any): any[] {
    if (value) {
      if (typeof value === 'object') {
        return [value];
      } else {
        return value.trim() ? this.fuse.search(value.trim()).map((i) => i.item) : this.items;
      }
    } else {
      return this.items;
    }
  }

  validItem(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = control.value && typeof control.value !== 'object';
      return invalid ? { 'not found': { value: control.value } } : null;
    };
  }

  errorMessage() {
    return this.control.errors ? Object.keys(this.control.errors).join(', ') : null;
  }

  clear(e) {
    e.stopPropagation();
    this.control.setValue(null);
    this.control.markAsDirty();
  }
}
