import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from 'src/app/enums';
import { User } from 'src/app/types';

@Pipe({
  name: 'userList',
})
export class UserListPipe implements PipeTransform {
  transform(users: User[], excludeUserType: UserType): User[] {
    if (excludeUserType && excludeUserType === UserType.Vendor) {
      return users.filter((user: User) => user.user_type_id !== excludeUserType);
    }
    return users;
  }
}
